
import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd';

import { Icon } from '@ant-design/compatible'

import './SelectImageMaterialModal.less'

const PAGE_COUNT = 8

export default function SelectImageMaterialModal (props) {

    const { visible, hideModal, imageSelect, queryRunner } = props

    const [ imageMaterials, setImageMaterials ] = useState()
    const [ currentImage, setCurrentImage ] = useState()
    const [ hasNextPage, setHasNextPage ] = useState()
    const [ queryTimes, setQueryTimes ] = useState(0)

    useEffect(() => {
        (async () => {
            const images = await loadImages(queryRunner, { first: PAGE_COUNT })
            setImageMaterials(images)
            setHasNextPage(images.pageInfo.hasNextPage)
            setQueryTimes(queryTimes + 1)
        })()
    }, [])

    function selected(imageItem) {
        setCurrentImage(imageItem)
    }

    function cancel() {
        setCurrentImage(undefined)
        hideModal()
    }

    function confirm() {
        imageSelect(currentImage.node.mediaId)
        setCurrentImage(undefined)
        hideModal()
    }

    const footer = (
        <div>
          <Button onClick={() => cancel()}>取消</Button>
          <Button type="primary" onClick={() => confirm()} disabled={!currentImage}>确认</Button>
        </div>
    )

    function loadMore (e) {
        const { scrollTop, scrollHeight, clientHeight } = e.target
        if(scrollHeight - (scrollTop + clientHeight) < 30 && hasNextPage ) {
            (async () => {
                const after = queryTimes * PAGE_COUNT
                const images = await loadImages(queryRunner, { first: PAGE_COUNT, after: after.toString() })
                setImageMaterials({
                    ...imageMaterials,
                    edges: [ ...imageMaterials.edges, ...images.edges ]
                })
                setHasNextPage(images.pageInfo.hasNextPage)
                setQueryTimes(queryTimes + 1)
            })()
            console.log('loadmore')
        }
    }

    return (
        <Modal
            title="选择图片"
            footer={footer}
            onCancel={() => hideModal()}
            open={visible}
            width={860}
        >
            <div className="imageMaterials-content" onScroll={e => loadMore(e)}>
                {
                    imageMaterials && imageMaterials.edges.length > 0 ? (
                        imageMaterials.edges.map(image => {
                            return (
                                <div key={image.node.mediaId} className="item" onClick={() => selected(image)}>
                                    <img src={image.node.imageUrl.replace('http://mmbiz.qpic.cn', '')}/>
                                    {
                                        currentImage && currentImage.node.id === image.node.id ? (
                                            <div className="selected-mask" >
                                                <Icon type="check"/>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            )
                        })
                    ) : <div>暂无图片素材</div>
                }
            </div>
        </Modal>
    )
}

const QueryWxMpMaterials = `
    query (
        $first: Float
        $after: String
    ) {
        listWxMpEntityImageMaterial(first: $first, after: $after, sort: [{ field: "createdAt", order: DESC }]) {
            count
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    mediaId
                    imageUrl
                    name
                    createdAt
                }
            }
        }
    }
`

async function loadImages(queryRunner, { first, after }) {
    const resp = await queryRunner(QueryWxMpMaterials)({
        first,
        after
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityImageMaterial
    }
}
