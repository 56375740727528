// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var EditingState$BwaxAdmin = require("./EditingState.bs.js");

function WidgetWrapper$PlainWrapper(Props) {
  var widget = Props.widget;
  var params = Props.params;
  var commonWidgetProps = Props.commonWidgetProps;
  return React.createElement(widget, Object.assign({
                  params: params
                }, commonWidgetProps));
}

var PlainWrapper = {
  make: WidgetWrapper$PlainWrapper
};

function WidgetWrapper$InputWrapper(Props) {
  var widget = Props.widget;
  var value = Props.value;
  var error = Props.error;
  var onChange = Props.onChange;
  var disabled = Props.disabled;
  var params = Props.params;
  var commonWidgetProps = Props.commonWidgetProps;
  return React.createElement(widget, Object.assign({
                  params: params,
                  value: value,
                  error: error,
                  onChange: onChange,
                  disabled: disabled
                }, commonWidgetProps));
}

var InputWrapper = {
  make: WidgetWrapper$InputWrapper
};

function extractInputParams(params, editKey, commonWidgetProps) {
  var editingState = commonWidgetProps.editingState;
  var updateEditing = commonWidgetProps.updateEditing;
  var editing = EditingState$BwaxAdmin.dictToOptionAssoc(editingState.editing);
  var errors = EditingState$BwaxAdmin.dictToOptionAssoc(editingState.errors);
  var match = params.fixedValue;
  var value = match !== undefined ? Caml_option.valFromOption(match) : EditingState$BwaxAdmin.get(editKey, editing);
  var error = EditingState$BwaxAdmin.get(editKey, errors);
  var onChange = function (newValue) {
    if (Caml_obj.caml_equal(newValue, value)) {
      return /* () */0;
    } else {
      return Curry._1(updateEditing, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      editKey,
                      newValue
                    ],
                    /* [] */0
                  ]));
    }
  };
  var disabled = params.fixedValue !== undefined;
  return /* tuple */[
          value,
          onChange,
          error,
          disabled
        ];
}

function WidgetWrapper(Props) {
  var widget = Props.widget;
  var params = Props.params;
  var commonWidgetProps = Props.commonWidgetProps;
  var match = params.editKey;
  if (match !== undefined) {
    var editKey = match;
    var match$1 = extractInputParams(params, editKey, commonWidgetProps);
    return React.createElement(WidgetWrapper$InputWrapper, {
                widget: widget,
                value: match$1[0],
                error: match$1[2],
                onChange: match$1[1],
                disabled: match$1[3],
                params: params,
                commonWidgetProps: commonWidgetProps
              });
  } else {
    return React.createElement(WidgetWrapper$PlainWrapper, {
                widget: widget,
                params: params,
                commonWidgetProps: commonWidgetProps
              });
  }
}

var make = WidgetWrapper;

exports.PlainWrapper = PlainWrapper;
exports.InputWrapper = InputWrapper;
exports.extractInputParams = extractInputParams;
exports.make = make;
/* react Not a pure module */
