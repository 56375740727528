

const period = "PERIOD"

function expandDataTypeTree({ fields = [], virtualFields = [], links = [] }, allDataTypes) {
  if (fields.length === 0 && virtualFields.length === 0) {
    return period
  }

  const allFields = [ ...fields, ...virtualFields, ...links ]

  return allFields.reduce((acc, field) => {
    const determineSubTree = () => {
      if (field.type === "Link") {
        return { id: period }
      } else {
        const dataType = allDataTypes.find(dt => dt.key === field.type)
        return expandDataTypeTree(dataType.settings || {}, allDataTypes)
      }
    }
    return {
      ...acc,
      [field.key]: determineSubTree()
    }
  }, {})
}

export default function buildSelectionTree({ queries, expressions, allDataTypes, onlyLogical = false }) {

  const paths = expressions.reduce((acc, { references }) => {
    return [...acc, ...references.map(({ path }) => path)]
  }, []).filter(l => l.length > 0 && l[0] !== 'NOT_FOUND') // TODO 在后面 NOT_FOUND 应该移除

  const queryTree = paths.reduce((acc, current) => {
    // it is a path
    function buildSubTree(remainingPath, parentTree, parent) {

      if (remainingPath.length === 0) {
        if (onlyLogical) {
          return parentTree || period
        }

        if (parent && parent.type) {
          if (parent.type.key === "Link") {
            return {
              ...(parentTree || {}),
              id: period
            }
          } else {
            // get dataType , if the dataType has fields, the expand the first level

            /// TODO 加上 parentTree
            return expandDataTypeTree(parent.type?.settings || {}, allDataTypes)
          }
        }
        return parentTree || period
      } else {
        const part = remainingPath[0]

        const key = onlyLogical ? part.name : part.key

        const tree = (parentTree && parentTree !== period) ? parentTree : {}
        return {
          ...tree,
          [key]: buildSubTree(remainingPath.slice(1), tree[key], part)
        }
      }
    }
    const tree = buildSubTree(current, acc)

    return tree

  }, {})

  const notIncluded = Object.keys(queries).filter(k => !queryTree[k])

  return notIncluded.reduce((acc, k) => {
    const query = queries[k]
    if (query.type === "count") {
      return {
        ...acc,
        [k]: {}
      }
    } else {
      // console.warn("unused query", query)
      return acc
    }
  }, queryTree)
}