
import React, { useState, useEffect, useRef } from 'react'

import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

import { getGlobalEventBus } from 'Client/js/studioApp/EventBus';

export default function DependencyEntitySelectRoot(props) {

    const { facade, baseCriteria: givenBaseCriteria, renderSelectorNode, itemNode } = props;

    const { ["多租户"]:_, ...baseCriteria} = givenBaseCriteria;

    const baseCriteriaRef = useRef();
    baseCriteriaRef.current = baseCriteria;

    const [queriedData, setQueriedData] = useState(undefined);

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("updateItemData", ({ itemType, itemData }) => {
            // update the current item, opened items, and tell the upper layer:
            console.log("Is any update needed?", itemType, itemData)
        });
        return () => {
            unsubscribe();
        }
    }, [])
    

    async function queryData(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;

        const queryObj = {
            entityName: "应用依赖",
            condition: mergeCriteria(baseCriteria, criteria),
            pageSize: 1000,
            offset: 0,
            fieldPaths: [
                "依赖",
                "系统应用",
                "公开的实体",
                "公开的页面组件"
            ],
        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            setQueriedData(result);
        }
    }
    

    useEffect(() => {
        queryData({});
    }, [ JSON.stringify(baseCriteria) ]);


    async function reloadItemList (itemType, parentItem) {        
        if(parentItem.itemType === "dependencies") {
            // 刷新整个列表
            queryData({});
        }

    }

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("reloadItemList", ({ itemType, parentItem }) => {
            reloadItemList(itemType, parentItem);
        });
        return () => {
            unsubscribe();
        }
    }, []) 

    if (queriedData === undefined) {
        return null 
    }

    const subNodes = queriedData.data.map(dep => {

        // not
        const exposedEntities = dep["公开的实体"] || [];

        const entitiyNodes = exposedEntities.map(e => {
            // 显示字段，虚拟字段，自定义接口，反向关联字段
            const children = [
                ...e.fields.filter(f => {
                    return [
                        "创建时间", "创建者", "修改时间", "修改者"
                    ].indexOf(f.name) === -1                    
                }).map(f => 
                    itemNode({ itemType: "imported-field", name: f.name, data: f })
                ),
                ...e.virtualFields.map(f => (
                    itemNode({ itemType: "imported-virtual-field", name: f.name, data: f })
                )),

                ...e.interfaces.map(itf => (
                    itemNode({ itemType: "imported-data-interface", name: itf.name, data: itf })
                )),
            ]

            return itemNode({ name: e.name, itemType: "imported-entity", data: e }, children)
            
        });


        return itemNode({ name: dep["依赖"], itemType: "dependency", data: dep }, entitiyNodes)

    });

    return renderSelectorNode(itemNode({ name: "依赖应用", itemType: "dependencies" },  subNodes), 0)
}
