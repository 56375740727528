// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Dateformat = require("dateformat");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Timeline = require("antd/lib/timeline");
var GetImageURL = require("bwax-ui/getImageURL");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var TimelineItem = require("antd/lib/timeline/TimelineItem");

import './Display_RecordHistory.less'
;

function Display_RecordHistory(Props) {
  var params = Props.params;
  var allEntities = Props.allEntities;
  var items = Belt_Option.getWithDefault(params.items, /* array */[]);
  var entityKey = params.entityKey;
  var entity = Belt_Array.getBy(allEntities, (function (e) {
          return e.key === entityKey;
        }));
  var renderedItems = Belt_Array.mapWithIndex(items, (function (idx, it) {
          var userName = Belt_Option.getWithDefault(it.userName, "【系统】");
          var userAvatar = it.userAvatar;
          var journalType = it.journalType;
          var time = Dateformat(it.time, "yyyy-mm-dd HH:MM");
          var content = it.content;
          var inList = function (value, list) {
            return Belt_List.some(list, (function (e) {
                          return e === value;
                        }));
          };
          var fields = Belt_Array.keep(Belt_Array.keepMap(Belt_Option.getWithDefault(Caml_option.null_to_opt(content.nValues), /* array */[]), (function (fv) {
                      var key = fv.key;
                      var keyEndsWithId = key.endsWith("Id");
                      var realKey = keyEndsWithId ? key.slice(0, key.length - 2 | 0) : key;
                      if (entity !== undefined) {
                        return Belt_Array.getBy(Caml_option.valFromOption(entity).fields, (function (f) {
                                      return f.key === realKey;
                                    }));
                      }
                      
                    })), (function (f) {
                  return !inList(f.name, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                "创建者",
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    "创建时间",
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        "修改者",
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            "修改时间",
                                            /* [] */0
                                          ])
                                      ])
                                  ])
                              ]));
                }));
          if (fields.length !== 0) {
            var fieldsText = Belt_Array.mapWithIndex(Belt_Array.map(fields, (function (f) {
                        return f.name;
                      })), (function (idx, fn) {
                    return React.createElement("span", {
                                key: String(idx),
                                className: "field-name"
                              }, fn);
                  }));
            var avatarEl = userAvatar !== undefined ? React.createElement("div", {
                    className: "avatar"
                  }, React.createElement("img", {
                        src: GetImageURL.default(Caml_option.valFromOption(userAvatar))
                      })) : null;
            var tmp;
            switch (journalType) {
              case "RecordAdded" :
                  tmp = "添加了";
                  break;
              case "RecordUpdated" :
                  tmp = "修改了";
                  break;
              default:
                tmp = "";
            }
            return React.createElement(TimelineItem.default, {
                        key: String(idx),
                        className: "history-item",
                        dot: avatarEl,
                        children: null
                      }, React.createElement("div", {
                            className: "event"
                          }, React.createElement("div", {
                                className: "operator"
                              }, React.createElement("div", {
                                    className: ""
                                  }, userName)), React.createElement("span", {
                                className: "event-desc"
                              }, tmp), fieldsText), React.createElement("div", {
                            className: "time"
                          }, time));
          } else {
            return null;
          }
        }));
  return React.createElement(Timeline.default, {
              className: "admin--record-history",
              children: renderedItems
            });
}

var make = Display_RecordHistory;

exports.make = make;
/*  Not a pure module */
