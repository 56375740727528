// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var $$String = require("bs-platform/lib/js/string.js");
var BwaxUi = require("bwax-ui");

function getCssColor(string) {
  var hexColor = BwaxUi.color(string);
  return Css.hex($$String.sub(hexColor, 1, hexColor.length - 1 | 0));
}

exports.getCssColor = getCssColor;
/* Css Not a pure module */
