
import React from 'react';

// const Input_YQYLPoster = require('./widgets/Input_YQYLPoster.bs').make
// const Display_YQYLPoster = require("./widgets/Display_YQYLPoster.bs").make

export function asPath(refName, field, suffix) {
    let joined = [refName, field && field.name, suffix].filter(x => !!x).join(".");
    return `\${${joined}}`;
}


/// 这里的相互依赖需要改进：TODO
import EntityDataPage from 'Client/js/pages/EntityDataPage';

export default {
    menu: {
        name: "邀请有礼",
        icon: "red-envelope",
        items: [
            {
                name: "活动列表",
                path: "promotions",  // 前面会自动加上 /ext/:extKey/
                ////  !TODO 需要更完善的权限管理 
                //isAdminOnly: true
                /// the following are for routes

                comp: (props) => {
                    return <EntityDataPage entityKey={"YQYLEntityPromotion"} {...props} />
                }

            },
            {
                name: "邀请海报",
                path: "invitations",  // 前面会自动加上 /ext/:extKey/
                comp: (props) => {
                    return <EntityDataPage entityKey={"YQYLEntityInvitation"} {...props} />
                }
            },
            // {
            //     name: "邀请统计",
            //     path: "stats",  // 前面会自动加上 /ext/:extKey/
            //     comp: (props) => {
            //         return <EntityDataPage entityKey={"YQYLEntityInvitationStatistics"} {...props}/>
            //     }
            // },
            // {
            //     name: "邀请记录",
            //     path: "records",  // 前面会自动加上 /ext/:extKey/
            //     comp: (props) => {
            //         return <EntityDataPage entityKey={"YQYLEntityInvitationRecord"} {...props}/>
            //     }
            // }
        ],
    },

    /// 前面会自动加上 "admin::<extKey>::"
    widgets: {
        // Input_YQYLPoster,
        // Display_YQYLPoster
    },

    dataTypeWidgets: {
        inputs: {},
        displays: {
            YQYLTypePosterSetting: {
                type: "admin::YQYL::Display_YQYLPoster",
                id: "${id}",
                previewAvatar: "${当前用户.头像}",
                previewNickname: "${当前用户.昵称}"
            }
        }
    },

    dataDisplays: {
        YQYLTypePosterSetting: {
            
        }
    }

}