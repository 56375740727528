
import './Card.less'

import React from 'react';

import Loading from 'Client/js/components/Loading'
import admin_widget_helper from 'Client/ml/ui/helpers/admin_widget_helper.bs'

import Tooltip from 'bwax-ui/components/Tooltip';
import { InfoCircleOutlined } from "@ant-design/icons"

export default function Card ({ data, config }) {

    const { fields, format, color } = config;
    const valueFieldName = fields && fields.value;
    const desc = fields && fields.desc;

    function getValue () {
        if(data && data[0]) {
            if(valueFieldName) {
                return data[0][valueFieldName]
            } else {
                // get the first number value 
                return Object.values(data[0]).find(v => typeof(v) == 'number')
            }
        } else {
            return undefined
        }
    }

    function getValueStyle(value) {
        if(color) {
            const c = admin_widget_helper.apply_float_to_color(color, value);
            if(c) {
                return { color: c };
            }
        } 
        return {}
    }

    function formatValue (value) {
        // TODO
        if(typeof(value) !== "number") {
            // 不用 format
            return value
        }

        if(format) {
            return admin_widget_helper.apply_float_to_string(format, value)
        }
        return value;
    }

    // TODO tooltip

    function renderBody () {
        if(data === undefined) {
            // TOOD 考虑更高级的 loading
            return <Loading />
        }    
        const label = valueFieldName || "指定数据";
        const value = getValue();


        const wrappedLabel = desc ? 
            (<Tooltip text={desc}>
                <div>
                    <span>{label}</span>
                    <InfoCircleOutlined style={{ marginLeft: "0.125rem", fontSize: 12, opacity: 0.6 }} />
                </div>
            </Tooltip>) : label
        ;

        return (
            <>
                <div className="card-value" style={getValueStyle(value)}>
                    { value === undefined || value === null ? "--" : formatValue(value) }
                </div>
                <div className="card-label">
                    { wrappedLabel }
                </div>
            </>
        )


    }

    return (
        <div className="admin-chart-card admin-widget">
            { renderBody() }
        </div>
    )
}
