import React from 'react'

import { Button, Row, Col } from 'antd'
import { replace } from 'bwax/list'

import DatePicker from 'rsuite/DatePicker';

import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import './DatePickerInput.less'

export default class DatePickerInput extends React.Component {

    renderMultiDatePickeInput(inputProps, value, onChange) {
        const { showTime } = this.props
        const hasDate = (value || []).some(n => n !== undefined)
        const dates = value && value.length > 0 ? value : [null]

        const addDatePickerInput = () => {
            onChange([...(value || []), null])
        }

        const delDatePickerInput = (idx) => {
            onChange(
                (value || []).filter((n, index) => index !== idx)
            )
        }

        const updateEdit = (changeValue, idx) => {
            onChange(replace((value || []), changeValue, idx))
        }

        // 应该统一使用 DraggableInput
        return (
            <div className="edit-date-input">
                {
                    dates.map((n, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`date-input-item ${showTime ? 'date-time-input-item' : ''}`}
                            >
                                <div className="date-input-col">
                                    {
                                        this.renderSingleDatePickeInput(
                                            inputProps,
                                            n,
                                            (date) => updateEdit(date, idx)
                                        )
                                    }
                                </div>
                                {
                                    idx !== 0 ?
                                        <div
                                            className="close-icon-col"
                                            onClick={() => delDatePickerInput(idx)}
                                        >
                                            <MinusOutlined />
                                        </div> : null
                                }
                            </div>
                        )
                    })
                }
                {
                    hasDate ?
                        <Button
                            icon={<PlusOutlined />}
                            size="small"
                            type="dashed"
                            className="add-date-input-btn"
                            onClick={addDatePickerInput}
                        /> : null
                }
            </div>
        )
    }

    renderSingleDatePickeInput(inputProps, value, onChange) {
        return (
            <DatePicker
                {...{
                    ...inputProps,
                    style: { "width":  "100%"},
                    value, onChange,
                    preventOverflow: true,
                    placeholder: " "
                }}
            />
        )
    }

    render() {
        const { value, onChange, multivalued, ...inputProps } = this.props;

        if (multivalued) {
            return this.renderMultiDatePickeInput(inputProps, value, onChange)
        } else {
            return this.renderSingleDatePickeInput(
                inputProps,
                value,
                onChange
            )
        }
    }
}
