// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Lang_eval$BwaxAdmin = require("../../lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../runtime_common.bs.js");

function pack_random(f) {
  return /* tuple */[
          /* V_tagged_number */Caml_chrome_debugger.variant("V_tagged_number", 9, [
              "Random",
              f
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

var module_name = "Random";

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function func(args) {
  if (args) {
    var match = args[1];
    if (match) {
      var match$1 = match[1];
      if (match$1 && !match$1[1]) {
        return /* tuple */[
                /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                    "Cmd_Random_int",
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        args[0],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            match[0],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                match$1[0],
                                /* [] */0
                              ])
                          ])
                      ])
                  ]),
                Runtime_common$BwaxAdmin.nt
              ];
      } else {
        return fail("init");
      }
    } else {
      return fail("init");
    }
  } else {
    return fail("init");
  }
}

var externals_vals_000 = /* tuple */[
  "int",
  Runtime_common$BwaxAdmin.pack_func(3, func)
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "get",
      Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
              if (param && !param[1]) {
                return /* tuple */[
                        /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                            "Cmd_Random_get",
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                param[0],
                                /* [] */0
                              ])
                          ]),
                        Runtime_common$BwaxAdmin.nt
                      ];
              } else {
                return fail("init");
              }
            }))
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "toFloat",
          Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                  if (param) {
                    var match = param[0][0];
                    if (typeof match === "number" || !(match.tag === /* V_tagged_number */9 && match[0] === "Random" && !param[1])) {
                      return fail("init");
                    } else {
                      return /* tuple */[
                              /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Float */Caml_chrome_debugger.variant("Float", 1, [match[1]])]),
                              Runtime_common$BwaxAdmin.nt
                            ];
                    }
                  } else {
                    return fail("init");
                  }
                }))
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "toInt",
              Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
                      if (param) {
                        var match = param[0][0];
                        if (typeof match === "number" || match.tag !== /* V_literal */5) {
                          return fail("init");
                        } else {
                          var match$1 = match[0];
                          if (typeof match$1 === "number" || match$1.tag) {
                            return fail("init");
                          } else {
                            var match$2 = param[1];
                            if (match$2) {
                              var match$3 = match$2[0][0];
                              if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                                return fail("init");
                              } else {
                                var match$4 = match$3[0];
                                if (typeof match$4 === "number" || match$4.tag) {
                                  return fail("init");
                                } else {
                                  var match$5 = match$2[1];
                                  if (match$5) {
                                    var match$6 = match$5[0][0];
                                    if (typeof match$6 === "number" || match$6.tag !== /* V_tagged_number */9) {
                                      return fail("init");
                                    } else {
                                      var s = match$1[0];
                                      if (match$6[0] === "Random" && !match$5[1]) {
                                        var i = s + (Math.round((match$4[0] - s | 0) * match$6[1]) | 0) | 0;
                                        return /* tuple */[
                                                /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [i])]),
                                                Runtime_common$BwaxAdmin.nt
                                              ];
                                      } else {
                                        return fail("init");
                                      }
                                    }
                                  } else {
                                    return fail("init");
                                  }
                                }
                              }
                            } else {
                              return fail("init");
                            }
                          }
                        }
                      } else {
                        return fail("init");
                      }
                    }))
            ],
            /* [] */0
          ])
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

function random_int_command(_context, param, on_trunk_msgs, _on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || cmd.tag !== /* V_tagged */6) {
    return false;
  } else {
    switch (cmd[0]) {
      case "Cmd_Random_get" :
          var match = cmd[1];
          if (match && !match[1]) {
            var f = Math.random();
            var msg = Lang_eval$BwaxAdmin.apply_value(undefined, match[0], /* tuple */[
                  /* V_tagged_number */Caml_chrome_debugger.variant("V_tagged_number", 9, [
                      "Random",
                      f
                    ]),
                  Runtime_common$BwaxAdmin.nt
                ], Runtime_common$BwaxAdmin.nt);
            Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        msg,
                        /* [] */0
                      ])]));
            return true;
          } else {
            return false;
          }
      case "Cmd_Random_int" :
          var match$1 = cmd[1];
          if (match$1) {
            var match$2 = match$1[1];
            if (match$2) {
              var match$3 = match$2[0][0];
              if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                return false;
              } else {
                var match$4 = match$3[0];
                if (typeof match$4 === "number" || match$4.tag) {
                  return false;
                } else {
                  var match$5 = match$2[1];
                  if (match$5) {
                    var match$6 = match$5[0][0];
                    if (typeof match$6 === "number" || match$6.tag !== /* V_literal */5) {
                      return false;
                    } else {
                      var match$7 = match$6[0];
                      if (typeof match$7 === "number" || match$7.tag || match$5[1]) {
                        return false;
                      } else {
                        var v = Js_math.random_int(match$4[0], match$7[0]);
                        var msg$1 = Lang_eval$BwaxAdmin.apply_value(undefined, match$1[0], /* tuple */[
                              /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [v])]),
                              Runtime_common$BwaxAdmin.nt
                            ], Runtime_common$BwaxAdmin.nt);
                        Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    msg$1,
                                    /* [] */0
                                  ])]));
                        return true;
                      }
                    }
                  } else {
                    return false;
                  }
                }
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
      default:
        return false;
    }
  }
}

var command_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    random_int_command,
    /* [] */0
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\n\nmodule Random;\n\n-- Innernally it is simply a float.\ntype Random = opaque;\n\n-- Random number in [min,max)\nint: (Int -> msg) -> Int -> Int -> Cmd msg = external;\n\nget: (Random -> msg) -> Cmd msg = external;\n\n-- Get the float in [0, 1)\ntoFloat: Random -> Float = external;\n\n-- Get the number in [min, max)\ntoInt: Int -> Int -> Random -> Int = external;\n\n";

exports.pack_random = pack_random;
exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.random_int_command = random_int_command;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
