import React from 'react'

import WxInput from './WxInput';

export default function WxWorkTypeMsgAttachmentInput(props) {

    const attachmentTypes = [
        { label: "图片", value: "image" },
        { label: "链接", value: "link" },
        { label: "视频", value: "video" },
        { label: "文件", value: "file" }
    ]

    return (
        <WxInput {...props} attachmentTypes={attachmentTypes} maxCount={9}></WxInput>
    )
}