
import React, { useEffect, useState } from 'react'

import getImageURL from 'bwax/util/getImageURL';

import TextInput from 'bwax-ui/components/inputs/TextInput';
import Checkbox from 'bwax-ui/components/inputs/Checkbox';

import { runDataQuery } from 'bwax/query/runClientQuery'

import Modal from 'bwax-ui/components/Modal'

import Button, { Pressable } from 'bwax-ui/components/Button';
import setTokenCookie from 'bwax-ui/setTokenCookie'

import RenderWithData from 'bwax-ui/store/RenderWithData';

import ClipLoader from 'react-spinners/ClipLoader';

import RichText from 'bwax-ui/basic/RichText';
import WeChatLoginQrCode from 'bwax-ui/components/WeChatLoginQrCode'

import { useTrack } from 'bwax-ui/track';

// 改为必须微信登录先

/*
    PC： 
    1. 扫码登录 -> 2. 手机号 -> 
    2a.（如果手机没有使用）验证码/推荐码 ->  3. 使用
    2b.（如果手机号已经注册）问是否要绑定  验证码 -> 3. 使用    

    微信端：
    1. 自动登录 -> 2. 手机号 ->
    2a. （如果手机没有使用）验证码/推荐码 ->  3. 使用
    2b.（如果手机号已经注册）问是否要绑定  验证码 -> 3. 使用

    (那就统一了)


    在代码中就要假设两种情况：当前有用户，当前没用户
    第一种情况：当前没用户  （手机号/验证码/推荐码
    
        1. 输入手机号/验证码
        2. 尝试通过手机登录（自动登录注册）
        3. 注册/等候后，得到当前用户，刷新当前用户
        3a. 刷新后，如果该用户已经有个人班申请，则自动载入，
        3b. 如果没有，则继续“申请”
        3b.1. 输入推荐码，校验完成后，提交个人版申请

    第二种情况：当前有用户
        0. 检查用户是否已经有个人版申请，自动载入，如果没有，则继续

        1. 输入手机号/验证码（如果当前用户有相关的手机用户，则自动输入；但通常是微信用户）

        1a. 检测手机号用户已经存在，如果存在而且不是跟当前用户有关的，问是否要合并账号？
        1a.1. 合并账号（目前本质上是把当前用户的登录验证信息转入目标用户）
        1a.2. 刷新当前用户

        1b. 如果对应的手机用户不存在，则直接为当前用户号创建相关的手机号用户

        2. 填入推荐码，校验完成后， 提交个人版申请


    整理合并的步骤：
        0. 如果有当前用户，则直接尝试加载个人版申请，如果有则跳至 3, 没有，则查看当前用户有没有相应的手机用户，如果有，则跳至 2，如果没有则进入 1

        1. 输入手机号和验证码：         
        1a. 如果没有当前用户，就是一个自动注册/登录的步骤 
        1a.1 注册完之后，设置 token，并将新的当前用户保存到临时状态中（后面用这个用户
        
        1b. 如果有当前用户，则是绑定手机登录方式，或者合并手机用户的方式
        1b.1 根据手机号和验证码查询是不是已有用户
        1b.2 如果不是，直接绑定手机号（bindMobile）
        1b.3 如果已有用户，则显示“该手机号已经被注册，你是否要用该账号登录？”： 用户如果点击确认，则合并账号（bindMobile）
        1b.3.1 设置 token，并将新的当前用户保存到临时状态中（后面用这个用户

        1b.3.2 此时用该用户的 userId 加载个人版申请，如果有，直接跳至 3

        （此时已经有当前的用户，和该用户的手机号）

        2. 填写昵称和推荐码，提交申请  
            （推荐码不是必须的，但可以提醒对方使用推荐码申请可以奖励会员 N 天）
            （自动修改用户昵称）
                    
        3. 显示申请的状态，（如果已经开通，则直接跳转对应的后台首页）

*/

// 

export default function SignUp_inbot({ data, events, slots, viewEnv, facade }) {

    const { logoURL, currentUser, currentApp, giftDays, siteName } = data;

    const { routeTo } = viewEnv;

    // { userId, nickName, phoneNumber }
    const [phoneUser, setPhoneUser] = useState(_ => {
        if (currentUser && currentUser.phoneUser) {
            return {
                userId: currentUser.id, nickName: currentUser.nickName,
                phoneNumber: currentUser.phoneUser.phoneNumber
            }
        }
        // undefined
    });

    const [existingApp, setExistingApp] = useState(_ => {
        return currentApp;
    });


    const [isFinding, setIsFinding] = useState(false);
    async function findAppSubmittedByUser(userId) {
        setIsFinding(true);
        const [result, error] = await facade.findOne({
            entityName: "个人版申请", condition: [[{ field: "创建者.id", op: "eq", value: userId }]],
            fieldPaths: ["状态", "url"]
        });
        setIsFinding(false);
        if (!error && result) {
            setExistingApp(result);
        }
    }

    useEffect(() => {
        if (phoneUser && phoneUser.userId) {
            // 如果 phoneUser 是刚找到的用户，这个时候要查一下 application 
            if (!currentUser || (currentUser.id !== phoneUser.userId)) {
                findAppSubmittedByUser(phoneUser.userId);
            }
        }
    }, [phoneUser]);


    return (
        <div className="w-full h-full flex flex-col items-center sm:justify-center sm:bg-[var(--mauve2)] pt-8 sm:pt-0 sm:pb-12 font-size-14">
            <div className="sm:shadow-xl rounded-lg flex flex-col py-12 px-6 sm:px-8 bg-[var(--lc-color-bg-3)] gap-10 w-full max-w-sm transition-all">
                <div className="flex flex-col gap-2 min-h-[60px]">
                    <img className="w-[240px] self-center" src={getImageURL(logoURL, "medium")}>
                    </img>
                    {/* <div className="text-[var(--gray11)] px-1">
                        个人账号注册
                    </div> */}
                </div>
                <div className="flex flex-col gap-6">
                    {
                        existingApp ? (
                            // step 3
                            <ApplicationDisplay facade={facade} application={existingApp} routeTo={routeTo} />
                        ) : (
                            phoneUser ? (
                                // step 2
                                isFinding ? (
                                    <div className="flex justify-center items-center w-full h-[180px]">
                                        <ClipLoader color="var(--gray10)" />
                                    </div>
                                ) : (
                                    <ApplicationSubmit
                                        phoneUser={phoneUser}
                                        giftDays={giftDays}
                                        facade={facade}
                                        onApplicationReady={application => setExistingApp(application)}
                                    />
                                )
                            ) : (
                                // step 1
                                currentUser ? (
                                    <PhoneUserDetermined
                                        currentUser={currentUser}
                                        onPhoneUserReady={phoneUser => setPhoneUser(phoneUser)}
                                        facade={facade}
                                    />
                                ) : (
                                    <div className="flex flex-col items-center py-1 pb-2">
                                        <WeChatLoginQrCode {...{
                                            size: 164,
                                            loadingElement: (
                                                <div className="h-[198px] w-[160px]">
                                                </div>
                                            ),
                                            showCountDown: true,
                                            tip: "请用微信扫码确认",
                                            onSuccess: _ => {
                                                // onSuccess(user)
                                                window.location.reload()
                                            },
                                        }} />
                                    </div>
                                )
                            )
                        )
                    }

                </div>
            </div>
        </div>
    )
}


// 第一步
function PhoneUserDetermined({ onPhoneUserReady, currentUser, facade }) {

    const track = useTrack();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [veriCode, setVeriCode] = useState("");

    const [veriCodeSentForPhone, setVeriCodeSentForPhone] = useState({});

    const [veriCodeCountDown, setVerCodeCountDown] = useState(0);

    useEffect(() => {
        if (veriCodeCountDown > 0) {
            setTimeout(() => setVerCodeCountDown(prev => prev - 1), 1000)
        }
    }, [veriCodeCountDown])

    const [agreementChecked, setAgreementChecked] = useState(false);

    const isValidPhoneNumber = phoneNumber && /^\d{11}$/.test(phoneNumber);
    const isValidCode = !!(veriCode && /^\d{6}$/.test(veriCode));

    const isVeriCodeSent = veriCodeSentForPhone[phoneNumber] || true;

    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        setErrorMessage();
    }, [phoneNumber, veriCode]);

    const [veriCodeSending, setVeriCodeSending] = useState(false)
    async function sendVeriCode() {
        if (veriCodeCountDown > 0 || !isValidPhoneNumber) return null;

        setVeriCodeSending(true);
        const [result, error] = await facade.add({
            entityName: "短信-发送验证码请求",
            formData: {
                手机号: phoneNumber
            },
            fieldPaths: []
        });
        setVeriCodeSending(false);
        if (!error && result) {
            setVeriCodeSentForPhone(prev => ({
                ...prev, [phoneNumber]: true
            }));
            setVerCodeCountDown(60);

        } else {
            // TODO error handling
        }
    }

    const [isSigningIn, setIsSigningIn] = useState(false)
    async function tryToRegisterOrSignIn() {
        // 没有当前用户：
        setIsSigningIn(true);
        const [result, error] = await mobileLogin({ dlc: facade.dlc, phoneNumber, verificationCode: veriCode, autoRegister: true });
        setIsSigningIn(false);

        if (!error && result) {
            const { errMessage, authSession } = result;

            if (errMessage) {
                setErrorMessage(errMessage);
            } else if (authSession) {
                const { authUser, token } = authSession;
                setTokenCookie(token, facade.dlc);
                onPhoneUserReady({
                    userId: authUser.id,
                    nickName: authUser.nickName,
                    phoneNumber,
                })
            }
            //
        } else {
            // 
            // TODO error handling
        }
    }

    function convertErrorMessage(errorMessage) {
        if (errorMessage == "验证码已过期" || errorMessage == "验证码未发送" || errorMessage == "短信验证码已过期" || errorMessage == "短信验证码未发送") {
            return "验证码不正确"
        } else {
            return errorMessage
        }

    }


    const [staticContentToShow, setStaticContentToShow] = useState();
    function renderModalLink(text, code) {
        return (
            <Pressable id={"link-to-" + code} onPress={_ => {
                // open modal
                track("signup_click_agreement_link", { "content_code": code });

                setStaticContentToShow(code);

            }}>
                <span className="text-[var(--violet11)] cursor-pointer hover:underline mx-0.5">{text}</span>
            </Pressable>
        )
    }


    // 
    const [userToMerge, setUserToMerge] = useState();


    async function bindMobileOrConfirmMerge() {
        // 1. check if the phone user exists
        setIsSigningIn(true);
        const [result, error] = await facade.customQuery({
            entityName: "个人版申请",
            interfaceName: "查询手机用户",
            args: [
                phoneNumber, veriCode,
            ],
            outputFieldPaths: [["手机号用户", ["手机号", "用户.昵称"]]]
        });
        if (error) {
            setIsSigningIn(false);
            setErrorMessage(error);
            return
        }

        // 2. 如果不存在，则直接 bindMobile;  如果存在，则弹出确认 modal
        if (result) {
            setIsSigningIn(false)
            setUserToMerge(result);
        } else {
            const [result, error] = await bindMobile({ autoMigration: false, phoneNumber, verificationCode: veriCode, dlc: facade.dlc });
            setIsSigningIn(false);
            if (error) {
                setErrorMessage(error);
            } else if (result) {
                if (result.errMessage) {
                    setErrorMessage(result.errMessage)
                } else {
                    // bound
                    onPhoneUserReady({
                        userId: currentUser.id,
                        nickName: currentUser.nickName,
                        phoneNumber: phoneNumber,
                    })

                }
            }

        }
    }
    const [isMigrating, setIsMigrating] = useState(false);
    const [migrationError, setMigrationError] = useState("")
    function renderMergeConfirmation() {
        return userToMerge ? (
            <Modal className="max-w-sm" isOpen={!!userToMerge} onOpenChange={open => { if (!open) setUserToMerge() }} isMain={false}>
                {close => (
                    <div className="flex flex-col gap-4 py-6 px-8" >
                        <div>
                            {userToMerge.手机号} 已经注册为 SimplifyAI 账号 {userToMerge.用户.昵称 ? <span className="font-medium">{userToMerge.用户.昵称}</span> : null}，你要用该账号登录吗？
                        </div>
                        <div className="-mt-2">
                            {migrationError ? (
                                <div className="text-[var(--tomato9)] font-size-13">{migrationError}</div>
                            ) : null}
                        </div>
                        <div className="flex justify-end gap-3 px-2">
                            <Button onPress={_ => close()}>取消</Button>
                            <Button appearance="primary" color={"violet"} isLoading={isMigrating} onPress={async _ => {
                                setIsMigrating(true);

                                const [result, error] = await bindMobile({ autoMigration: true, phoneNumber, verificationCode: veriCode, dlc: facade.dlc })
                                setIsMigrating(false);

                                console.log(">>> errr", result, error);
                                if (error) {
                                    setMigrationError(error);

                                } else if (result) {
                                    if (result.errMessage) {
                                        setMigrationError(result.errMessage)
                                    } else {
                                        // migrated
                                        onPhoneUserReady({
                                            userId: userToMerge.用户.id,
                                            nickName: userToMerge.用户.昵称 || currentUser.nickName,
                                            phoneNumber: userToMerge.手机号,
                                        })

                                    }
                                }

                            }}>是的</Button>
                        </div>
                    </div>
                )}

            </Modal>
        ) : null
    }

    return (
        <>
            {/* 手机号，验证码，同意协议 */}
            <div className="flex flex-col gap-6">
                <TextInput styled={true} color={"violet"} value={phoneNumber} onChange={setPhoneNumber} placeholder="输入您的手机号" maxLength={11} autoFocus />
                <div className="flex gap-4 items-center">
                    <div className="grow">
                        <TextInput styled={true} color={"violet"} value={veriCode} onChange={setVeriCode} placeholder="输入验证码" maxLength={6} />
                    </div>
                    <div className="h-full py-[0.5px]">
                        <Button className="!h-full !w-[108px] px-1" color={"violet"}
                            id="btn-send-vericode"
                            isDisabled={!isValidPhoneNumber || veriCodeCountDown > 0}
                            isLoading={veriCodeSending}
                            onPress={_ => {
                                track("signup_send_vericode");
                                sendVeriCode()
                            }}
                        >
                            {veriCodeCountDown > 0 ? `${veriCodeCountDown} 秒` : "获取验证码"}
                        </Button>
                    </div>
                </div>
                <div className="h-[22px] -mt-2">
                    {errorMessage ? (
                        <div className="text-[var(--tomato9)] px-2 font-size-13">{convertErrorMessage(errorMessage)}</div>
                    ) : null}
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex gap-1.5">
                    <div className="translate-y-1 sm:translate-y-[2px]">
                        <Checkbox color="violet" checked={agreementChecked} onChange={checked => {
                            setAgreementChecked(checked);
                        }}></Checkbox>
                    </div>
                    <div className="font-size-13">我已阅读和同意 SimplifyAI {renderModalLink("服务协议", "terms-of-service")}和{renderModalLink("隐私政策", "privacy-policy")}</div>
                </div>
                <div className="flex flex-col gap-2">
                    {currentUser ? null : <div className="font-size-13 text-[var(--gray11)] pl-6">如果您的手机号已注册，会自动登录</div>}
                    <Button appearance="primary" color="violet" size="xlarge" className="w-full"
                        isLoading={isSigningIn}
                        isDisabled={!(isValidPhoneNumber && isValidCode && agreementChecked && isVeriCodeSent) || errorMessage}
                        id="btn-register-phone"
                        onPress={_ => {
                            // try to register      
                            track("signup_register_phone");

                            if (currentUser) {
                                // check if the phone exists
                                console.log("check if the phone exists");
                                bindMobileOrConfirmMerge();

                            } else {
                                // try to register/sign-in
                                // 
                                tryToRegisterOrSignIn();

                            }

                        }}
                    >{currentUser ? "登记手机号" : "注册用户"} </Button>
                </div>

            </div>
            {renderMergeConfirmation()}
            {staticContentToShow ? <StaticContentModal code={staticContentToShow} facade={facade} onClose={_ => setStaticContentToShow()} /> : null}
        </>
    )
}



function ApplicationSubmit(props) {

    const track = useTrack();


    const { phoneUser, giftDays, facade, onApplicationReady } = props;

    const [nickName, setNickName] = useState(_ => phoneUser.nickName || "");

    const [referalCode, setReferalCode] = useState("");
    const [referalCodeChecked, setReferalCodeChecked] = useState(false);


    // find 
    async function checkReferalCode(code) {
        // Cmd_推荐码.findOne { criteria = And { 内容 = { eq = String.toUpper c } } }
        const [result, error] = await facade.findOne({
            entityName: "推荐码", condition: [[{ field: "内容", op: "eq", value: code.toUpperCase() }]]
        });
        if (!error && result) {
            setReferalCodeChecked(true);
        }

    }

    useEffect(() => {
        const isValidCode = !!(referalCode && /^[ABCDEabcde\d]{5}$/.test(referalCode));
        if (isValidCode) {

            checkReferalCode(referalCode)

        } else {
            setReferalCodeChecked(false)
        }

    }, [referalCode])


    const [isSubmitting, setIsSubmitting] = useState(false);

    async function submitApplication(phoneNumber, referalCode) {

        setIsSubmitting(true);
        if (phoneUser.nickName !== nickName) {
            await facade.update({
                entityName: "用户", id: phoneUser.userId,
                formData: { 昵称: nickName },
                fieldPaths: ["昵称"]
            });
            //  doesn't matter
        }

        // Cmd_个人版申请.提交 (phoneNumber, maybeReferreCode) |> done GotError Submitted
        const [result, error] = await facade.customMutation({
            entityName: "个人版申请",
            interfaceName: "提交",
            args: [phoneNumber, referalCode],
            outputFieldPaths: [
                ["个人版申请", ["状态", "url"]]
            ]
        });
        setIsSubmitting(false)
        if (!error && result) {
            onApplicationReady(result)
        }
    }

    return (
        <>
            {/* 昵称，推荐码 */}
            <div className="flex flex-col gap-6">
                <TextInput styled={true} color={"violet"} value={nickName} onChange={setNickName} placeholder="输入昵称" autoFocus />
                <div className="flex flex-col gap-4">
                    <div className="relative">
                        <TextInput styled={true} color={"violet"} value={referalCode} onChange={v => {

                            setReferalCode(v ? v.toUpperCase() : v);

                        }} placeholder="输入推荐码" maxLength={5} />
                        {referalCodeChecked ? (
                            <i className='bx bxs-check-circle absolute top-[14px] right-3 text-[var(--grass9)] font-size-16'></i>
                        ) : null}
                    </div>
                    {giftDays && giftDays > 0 ?
                        (<div className="flex gap-1 text-[var(--gray11)] ">
                            <i className='bx bx-gift text-[var(--gray10)] translate-y-1'></i>
                            <div>
                                推荐码不是必须，但如果您提供有效的推荐码，将会自动获得 <span className="font-medium">{giftDays}</span> 天会员资格。
                            </div>
                        </div>) : null
                    }
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <Button id="btn-open-account" appearance="primary" color="violet" size="xlarge" className="w-full"
                    isLoading={isSubmitting}
                    isDisabled={!nickName}
                    onPress={_ => {
                        // submit the application   
                        track("signup_open_account", referalCode ? { "referal-code": referalCode } : {});

                        submitApplication(phoneUser.phoneNumber, referalCode)

                    }}
                >开通个人版</Button>
            </div>
        </>
    )
}


function ApplicationDisplay(props) {

    const track = useTrack();

    const { application, routeTo } = props;

    // 如果是刚绑定的，要跳转去租户页面再次同步绑定手机号

    const isReady = application.状态 == "已通过";

    useEffect(() => {
        if (isReady) {
            // console.log(">>>> OPEN", application.url);

            const url = new URL(application.url);

            track("signup_existing_account_redirect");

            // window.open(url.pathname);

            setTimeout(() => {
                // console.log(">>> url", url);
                if (typeof (document) !== 'undefined') {
                    const { protocol, host } = document.location;
                    const sameHostUrl = protocol + "//" + host + url.pathname

                    routeTo(sameHostUrl);
                }
            }, 64)

        }
    }, [application.状态]);

    if (isReady) {
        return (
            <div className="flex flex-col gap-2 px-4">
                <div>个人版已开通，自动为您跳转</div>
                {/* <div className="text-[var(--gray11)]">自动为你跳转</div> */}
            </div>
        )
    } else {
        return (
            <div className="px-1">还未开通，请稍等</div>
        )

    }


}



// aggreement modal 
function StaticContentModal({ code, onClose, facade }) {

    async function loadData() {

        const [result, error] = await facade.findOne({
            entityName: "静态内容", condition: [[{ field: "code", op: "eq", value: code }]],
            fieldPaths: ["标题", "内容"]
        });

        return result;

    }


    return (
        <RenderWithData
            Component={StaticContentModalInner}
            loadData={loadData}
            dataKey={"StaticContent::" + code}
            fallback={null}
            dlc={facade.dlc}
            {...{ onClose }}
        />
    )


}

function StaticContentModalInner({ onClose, data }) {

    function renderContent() {
        return (
            <>
                <h1 className="font-size-18 font-medium">{data.标题}</h1>
                <div>
                    <RichText params={{ value: data.内容 }} />
                </div>
            </>
        )

    }



    return (
        <Modal className="max-w-4xl" isOpen={true} onOpenChange={open => {
            if (!open) { onClose() }
        }}>
            <div className="py-8 px-8 sm:h-[80vh] flex flex-col gap-8">
                {data ? renderContent() : "没找到内容"}
            </div>
        </Modal>
    )
}




function extractError(error) {
    if (Array.isArray(error) && error.length > 0 && error[0] && error[0].message) {
        return error[0].message
    } else {
        return error
    }
}

async function mobileLogin({ phoneNumber, verificationCode, autoRegister = true, dlc }) {

    const runQuery = runDataQuery(dlc);

    const queryText = `
      mutation ($input: MobileLoginInput!) {
        mobileLogin(input: $input) {
          errMessage
          authSession {
            token
            authUser {
              id
              nickName

            }
          }
        }
      }      
    `
    const result = await runQuery(queryText)({
        input: {
            phoneNumber,
            verificationCode,
            autoRegister,
            clientMutationId: `${Date.now()}`
        }
    });

    const { errors, data } = JSON.parse(result);

    // console.log(">>> data", data)


    return [data && data.mobileLogin, extractError(errors)];

}


async function bindMobile({ phoneNumber, verificationCode, autoMigration = true, dlc }) {

    const runQuery = runDataQuery(dlc);

    const queryText = `
      mutation ($input: BindMobileInput!) {
        bindMobile(input: $input) {
          errMessage
          hasMigration
        }
      }      
    `
    const result = await runQuery(queryText)({
        input: {
            phoneNumber,
            verificationCode,
            autoMigration,
            clientMutationId: `${Date.now()}`
        }
    });

    const { errors, data } = JSON.parse(result);

    // console.log(">>> data", data)
    return [data && data.bindMobile, extractError(errors)];

}