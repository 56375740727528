// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_types = require("bs-platform/lib/js/js_types.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReWidgets$BwaxAdmin = require("../ReWidgets.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var BaseWidgets$BwaxAdmin = require("../BaseWidgets.bs.js");
var WidgetWrapper$BwaxAdmin = require("./WidgetWrapper.bs.js");
var DataBook = require("bwax/core/store/DataBook");
var ResolveOrTraverse = require("bwax-ui/legacy/page/resolveOrTraverse");

var isDomElement = ( (e) => typeof(e.type) === "string" );

function preprocessParams(params, prepareActions) {
  return ResolveOrTraverse.default(params, (function (value) {
                var markDone = function (v) {
                  return {
                          done: true,
                          value: v
                        };
                };
                var notDone = function (v) {
                  return {
                          done: false,
                          value: v
                        };
                };
                var match = Js_types.classify(value);
                if (typeof match === "number" || match.tag !== /* JSObject */3) {
                  return notDone(value);
                } else if (DataBook.isNodeHolder(value)) {
                  return markDone(value);
                } else {
                  var match$1 = value.action;
                  if (match$1 !== undefined && DataBook.isNodeHolder(Caml_option.valFromOption(match$1))) {
                    return markDone(Curry._1(prepareActions, value.action));
                  } else {
                    return notDone(value);
                  }
                }
              }));
}

function $$default(widgets) {
  return (function (def, commonWidgetProps, key) {
      var prepareActions = commonWidgetProps.prepareActions;
      var theme = commonWidgetProps.theme;
      var pc = commonWidgetProps.pc;
      var reloadUser = commonWidgetProps.reloadUser;
      var renderWidget = commonWidgetProps.renderWidget;
      var scrollCtnr_000 = /* el */commonWidgetProps.scrollCtnr.el;
      var scrollCtnr_001 = /* setEl */commonWidgetProps.scrollCtnr.setEl;
      var scrollCtnr = /* record */Caml_chrome_debugger.record([
          "el",
          "setEl"
        ], [
          scrollCtnr_000,
          scrollCtnr_001
        ]);
      var rawModal = commonWidgetProps.modal;
      var modal_000 = /* modalContent */rawModal.modalContent;
      var modal_001 = /* modalVisible */rawModal.modalVisible;
      var modal_002 = /* showModalContent */rawModal.showModalContent;
      var modal_003 = /* modalKey */rawModal.modalKey;
      var modal_004 = /* showModal */rawModal.showModal;
      var modal_005 = /* hideModal */rawModal.hideModal;
      var modal = /* record */Caml_chrome_debugger.record([
          "modalContent",
          "modalVisible",
          "showModalContent",
          "modalKey",
          "showModal",
          "hideModal"
        ], [
          modal_000,
          modal_001,
          modal_002,
          modal_003,
          modal_004,
          modal_005
        ]);
      var rawDialogBox = commonWidgetProps.dialogBox;
      var dialogBox_000 = /* dialog */rawDialogBox.dialog;
      var dialogBox_001 = /* showDialog */rawDialogBox.showDialog;
      var dialogBox_002 = /* clearDialog */rawDialogBox.clearDialog;
      var dialogBox = /* record */Caml_chrome_debugger.record([
          "dialog",
          "showDialog",
          "clearDialog"
        ], [
          dialogBox_000,
          dialogBox_001,
          dialogBox_002
        ]);
      var routeTo = commonWidgetProps.routeTo;
      var $$location = /* record */Caml_chrome_debugger.record(["pathname"], [commonWidgetProps.location.pathname]);
      var widgetEnv = /* record */Caml_chrome_debugger.record([
          "renderWidget",
          "scrollCtnr",
          "modal",
          "dialogBox",
          "location",
          "routeTo",
          "reloadUser"
        ], [
          renderWidget,
          scrollCtnr,
          modal,
          dialogBox,
          $$location,
          routeTo,
          reloadUser
        ]);
      var renderDef = function (d, key) {
        var type_ = d.type_;
        var hidden = d.hidden;
        var params = d.params;
        var widget = /* record */Caml_chrome_debugger.record([
            "type_",
            "hidden",
            "params"
          ], [
            type_,
            hidden,
            params
          ]);
        var widget$1 = widget;
        var key$1 = key;
        if (widget$1[/* hidden */1]) {
          return null;
        } else {
          var params$1 = widget$1[/* params */2];
          var type_$1 = widget$1[/* type_ */0];
          var reWidgetDict = Js_dict.fromList(BaseWidgets$BwaxAdmin.baseWidgets);
          var reInputWidgetDict = Js_dict.fromList(BaseWidgets$BwaxAdmin.baseInputWidgets);
          var match = Js_dict.get(reWidgetDict, type_$1);
          var match$1 = Js_dict.get(reInputWidgetDict, type_$1);
          var match$2 = Js_dict.get(widgets, type_$1);
          if (match !== undefined) {
            var render = match;
            var key$2 = key$1;
            var params$2 = params$1;
            var styles = params$2.styles;
            var paramValue = preprocessParams(params$2, prepareActions);
            return Curry._6(render, paramValue, styles, Belt_Option.getWithDefault(key$2, "no-key"), theme, widgetEnv, Belt_Option.getWithDefault(pc, /* box */4898315));
          } else if (match$1 !== undefined) {
            var renderInput = match$1;
            var key$3 = key$1;
            var params$3 = params$1;
            var styles$1 = params$3.styles;
            var editKey = params$3.editKey;
            var paramValue$1 = preprocessParams(params$3, prepareActions);
            var match$3 = WidgetWrapper$BwaxAdmin.extractInputParams(params$3, editKey, commonWidgetProps);
            return Curry.app(renderInput, [
                        paramValue$1,
                        match$3[0],
                        match$3[1],
                        match$3[2],
                        match$3[3],
                        styles$1,
                        Belt_Option.getWithDefault(key$3, "no-key"),
                        theme,
                        widgetEnv,
                        Belt_Option.getWithDefault(pc, /* column */-963948842)
                      ]);
          } else if (match$2 !== undefined) {
            var widgetComp = match$2;
            var key$4 = key$1;
            var params$4 = params$1;
            var prepareActions$1 = commonWidgetProps.prepareActions;
            var paramValue$2 = preprocessParams(params$4, prepareActions$1);
            return React.createElement(WidgetWrapper$BwaxAdmin.make, {
                        widget: widgetComp,
                        params: paramValue$2,
                        commonWidgetProps: commonWidgetProps,
                        key: Belt_Option.getWithDefault(key$4, "no-key-c")
                      });
          } else {
            return type_$1;
          }
        }
      };
      var defList = Belt_List.fromArray(def);
      if (defList) {
        if (defList[1]) {
          return Belt_List.toArray(Belt_List.mapWithIndex(defList, (function (index, d) {
                            return renderDef(d, String(index));
                          })));
        } else {
          return renderDef(defList[0], key);
        }
      } else {
        return null;
      }
    });
}

var reWidgets = ReWidgets$BwaxAdmin.registry;

var reInputWidgets = ReWidgets$BwaxAdmin.inputRegistry;

exports.isDomElement = isDomElement;
exports.preprocessParams = preprocessParams;
exports.reWidgets = reWidgets;
exports.reInputWidgets = reInputWidgets;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* isDomElement Not a pure module */
