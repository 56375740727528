
import React, { useState, useContext, useEffect, useRef } from 'react'

import IconButton from 'Client/js/components/IconButton';

import { Table, Input } from '@arco-design/web-react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const SortingContext = React.createContext();

export const NEW_RECORD_PLACEHOLDER = "__placeholder___";
const NEW_RECORD = { key: NEW_RECORD_PLACEHOLDER };

import { IconDragDotVertical, IconPlus } from '@arco-design/web-react/icon';
import ClampedText from 'bwax-ui/components/ClampedText';


import './LightTable.less';

// a new table used for form management:
// default width, and resizeable
// suppports
// - drag to sort
// - inline-edit
// - expand record to check
// - new record line


const WIDTHS = {
    "Select": _ => 120,
    "File": _ => 200,
    "RichText": _ => 300,
    "Bool": _ => 80,
    "JSON": _ => 300,
    "JSONB": _ => 300,
    "ShortText": _ => 160,
    "Link": _ => 140,
    "Text": _ => 280,
    "Integer": _ => 90,
    "Number": _ => 120,
    "Date": _ => 120,
    "DateTime": _ => 160,
    "Boolean": _ => 100,
    "Image": _ => 120,
}

export default function LightTable({
    columns: givenColumns,
    data: givenData = [],
    totalCount,
    className,
    options = {},
    noDataElement, onRow,

    scroll = {},

}) {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const columnsWithWidth = givenColumns.map(c => {
        // 加上固定的 width
        const width = c.width || (WIDTHS[c.type] || (_ => 120))(c.typeOptions);

        return {
            ...c,
            width,
            title: (
                <ClampedText text={c.title} />
            ),

            align: (c.type == "Number" || c.type == "Integer") ? "right" : undefined
        }
    })

    function insertPaddingColumn (cols) {
        // 要在最后一个非 fixed 之前塞一个空 column;
        const emptyColumn = {
            title: "",
            dataIndex: "__",
            render: _ => {
                return ""
            }
        }
        if(cols.length == 0) {
            return [ emptyColumn ];
        }
        return cols.reverse().reduce(([acc, inserted], c) => {            
            if (inserted) {
                return [[c, ...acc], inserted]
            } else {
                // not processed
                if (c.fixed === undefined) {                
                    return [[c, emptyColumn, ...acc], true]
                } else {
                    return [[c, ...acc], false]
                }
            }
        }, [[], false])[0];
    }

    const columns = insertPaddingColumn(columnsWithWidth);

    const {
        dragRecordToSort = false,
        onDragRecordEnd = _ => { },

        newRecordLine = {
            enabled: false,
            addNewRecord: _ => { }
        }

    } = options;

    const data = newRecordLine.enabled ? [...givenData, NEW_RECORD] : givenData

    const opCell = el => {
        return (
            <td className="arco-table-td arco-table-operation">
                <div className='arco-table-cell'>{el}</div>
            </td>
        )
    }

    const addNewRowIcon = (
        <div style={{ display: "flex", width: "100%", padding: "6px 0px", justifyContent: "center" }}>
            <IconButton {...{
                icon: <IconPlus />,
                onClick: _ => {
                    newRecordLine.addNewRecord();
                }
            }} />
        </div>
    )

    // 
    const components = {
        header: {
            operations: ({ selectionNode }) => [
                dragRecordToSort ? {
                    node: <th />,
                    width: 40,
                } : null,
            ].filter(x => !!x),
        },
        body: {
            operations: ({ selectionNode, expandNode }) => {
                return [
                    dragRecordToSort ? {
                        node: record => {
                            return opCell(record.key == NEW_RECORD_PLACEHOLDER ? addNewRowIcon : <DragHandle />)
                        },
                        width: 40,
                    } : null,
                ].filter(x => !!x)
            },
            tbody: DraggableContainer,
            row: DraggableRow,

            // wrapper: BodyWrapper,
        },
    };

    const footer = _ => {
        return (
            <div className="table-footer">
                { totalCount ? (
                    <div>共 { totalCount } 条数据</div>
                    ) : <div></div> 
                }
            </div>
        )
    };


    const tableRef = useRef();

    useEffect(() => {
        if(tableRef.current && scroll.y) {
            const bodyWrapper = tableRef.current.getRootDomElement().querySelector(".arco-table-body");
            
            if(bodyWrapper) {
                bodyWrapper.style.height = (scroll.y - 8) + "px";
            }
            
        }

    }, [ scroll.y ]);

    return (
        <SortingContext.Provider value={{ onSortEnd: onDragRecordEnd }}>
            <Table {...{
                ref: tableRef,
                className: [className, "lc-light-table"].filter(x => !!x),
                data, columns,
                components,
                pagination: false,

                noDataElement, onRow,

                scroll: {
                    x: scroll.x || true, 
                    y: scroll.y || true,
                },

                bodyCellStyle: {
                    height: (scroll.y ? scroll.y - 8: undefined)
                },

                footer,

                rowSelection: {
                    type: "checkbox",
                    selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                    onSelect: (selected, record, selectedRows) => {

                    },
                    // checkboxProps: (record) => {
                    //     return {
                    //         disabled: record.id === '4',
                    //     };
                    // },
                }
            }} />
        </SortingContext.Provider>
    )
}


const DragHandle = SortableHandle(() => (
    <IconDragDotVertical
        style={{
            cursor: 'move',
            color: '#555',
        }}
    />
));


const SortableWrapper = SortableContainer((props) => {
    return <tbody {...props} />;
});

const SortableItem = SortableElement((props) => {
    return <tr {...props} />;
});


const DraggableRow = (props) => {
    const { record, index, ...rest } = props;

    return <SortableItem index={index} {...rest} />;
};

const DraggableContainer = (props) => {

    const sortingContext = useContext(SortingContext);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (sortingContext && sortingContext.onSortEnd) {
            sortingContext.onSortEnd({ oldIndex, newIndex })
        }
    }
    return (
        <SortableWrapper
            useDragHandle
            onSortEnd={onSortEnd}
            helperContainer={() => document.querySelector('.drag-table-001 table tbody')}
            updateBeforeSortStart={({ node }) => {
                const tds = node.querySelectorAll('td');
                tds.forEach((td) => {
                    td.style.width = td.clientWidth + 'px';
                });
            }}
            {...props}
        />
    )
};