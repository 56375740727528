// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

function html_to_value(v) {
  return /* tuple */[
          /* V_tagged_raw */Caml_chrome_debugger.variant("V_tagged_raw", 8, [
              "Html",
              v
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

function attribute_to_value(attr) {
  return /* tuple */[
          /* V_tagged_raw */Caml_chrome_debugger.variant("V_tagged_raw", 8, [
              "Html_Attribute",
              attr
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

function pack_html_func(arity, f) {
  return Runtime_common$BwaxAdmin.pack_func(arity, (function (args) {
                return html_to_value(Curry._1(f, args));
              }));
}

function pack_attribute_func(arity, f) {
  return Runtime_common$BwaxAdmin.pack_func(arity, (function (args) {
                return attribute_to_value(Curry._1(f, args));
              }));
}

exports.html_to_value = html_to_value;
exports.attribute_to_value = attribute_to_value;
exports.pack_html_func = pack_html_func;
exports.pack_attribute_func = pack_attribute_func;
/* Runtime_common-BwaxAdmin Not a pure module */
