
import React from 'react'

import { CgDisplaySpacing } from 'react-icons/cg'
import Tooltip from 'bwax-ui/components/Tooltip';
import FormGallery from './FormGallery'
import Display from './layout/Display'
import FlexChild from './flexChild/FlexChild'
import Size from './size/Size'
import Borders from './borders/Borders'
import Spacing from './spacing/Spacing'
import Backgrounds from './backgrounds/Backgrounds';
import Typography from './typography/Typography';
import Effects from './Effects/Effects';

import './StyleForm.less'

import { activeStyles } from './activeStyles'

export const transparentImgUrl = "https://bw-dev.static.qunfengshe.com/public/cb-bwax/design-resources/transparent.png";
export const colorRgbRegx = /^[rR][gG][bB]a?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})(?:, ?(\d+(?:\.\d+)?))?\)$/
export const colorHexRegx = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/

export default function StyleForm({ value, onChange, blockNode }) {

    // value 是所有的 attribute 的值

    function centerElemnt(e) {
        e.stopPropagation()
        if (value['margin']) {
            const marginArr = value['margin'].split(" ")
            if (marginArr.length > 2) {
                const topValue = marginArr[0]
                const bottomValue = marginArr[2]
                onChange({ margin: `${topValue} auto ${bottomValue} auto` })
            } else {
                onChange({ margin: `${value['margin']} auto ${value['margin']} auto` })
            }
        } else {
            onChange({ margin: `0px auto 0px auto` })
        }
    }

    const spacingTitleIcon = (
        <Tooltip text={"Center element horizontally. Requires fixed width."}>
            <div className='title-icon' onClick={e => centerElemnt(e)}><CgDisplaySpacing style={{ fontSize: 16 }} /></div>
        </Tooltip>

    )

    function isAttributeGroupActive(groupName) {
        return Object.keys(value || {}).some(k => {
            const { lengthAttrs, commonAttrs } = activeStyles[groupName]
            if(!!lengthAttrs.find(la => la.attr === k)) {
                const findedLengthAttr = lengthAttrs.find(la => la.attr === k)
                // 如果是 lengthAttr 则判断是不是默认值
                if(value[k] || value[k] === 0) {
                    return !findedLengthAttr.defaultRegx.exec(value[k])
                } else {
                    return false
                }
            } else if(!!commonAttrs.find(ca => ca === k)) {
                // 如果是 commonAttr 就只判断有没有值
                return !!value[k]
            }
    
            return false
        })
    }

    const forms = [{
        name: "Layout",
        element: <Display blockNode={blockNode} attributes={value} onChange={attributes => onChange(attributes)} />,
        isActive: isAttributeGroupActive('layout')
    }, {
        name: "Spacing",
        titleIcon: spacingTitleIcon,
        element: <Spacing attributes={value} onChange={attributes => onChange(attributes)} />,
        isActive: isAttributeGroupActive('spacing')
    }, {
        name: "Size",
        element: <Size attributes={value} onChange={attributes => onChange(attributes)} />,
        isActive: isAttributeGroupActive('size')
    },
    { 
        name: "Typography",
        element: <Typography blockNode={blockNode} attributes={value} onChange={attributes => onChange(attributes)}/>,
        isActive: isAttributeGroupActive('typography')
    }, 
    {
        name: "Background",
        element: <Backgrounds attributes={value} onChange={attributes => onChange(attributes)} />,
        isActive: isAttributeGroupActive('background')
    }, {
        name: "Borders",
        element: <Borders attributes={value} onChange={attributes => onChange(attributes)} />,
        isActive: isAttributeGroupActive('border')
    }
    // , {
    //     name: "Effects",
    //     element: <Effects attributes={value} onChange={attributes => onChange(attributes)} />,
    //     isActive: isAttributeGroupActive('effects')
    // }
    ]

    function isFlexChild () {
        if (blockNode && typeof (window) !== 'undefined') {
            return window.getComputedStyle(blockNode.parentNode)["display"] === "flex";
        } else {
            return false
        }
    }
    const flexChild = {
        name: "Flex Child",
        element: <FlexChild attributes={value} blockNode={blockNode} onChange={attributes => onChange(attributes)} />,
        isActive: isAttributeGroupActive('flexChild')
    }

    return (isFlexChild() ? [flexChild, ...forms] : forms).map(form => {
        return <FormGallery key={form.name} form={form} />
    })
}
