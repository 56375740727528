import React from 'react'

import Tooltip from 'bwax-ui/components/Tooltip';

function findHyperlinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'HYPERLINK'
      );
    },
    callback
  );
}

const Hyperlink = (props) => {

  const {url} = props.contentState.getEntity(props.entityKey).getData();

  // TODO: click to edit

  return (
    <Tooltip
        placement="bottom"
        text={url}
        getPopupContainer={triggleNode => triggleNode}>
      <a>
        {props.children}
      </a>
    </Tooltip>
  );
};



export default (config = {}) => {
  return {
    decorators: [{
      strategy: findHyperlinkEntities,
      component: Hyperlink,
    }],

    // the code below doesn't work because the draft-convert treat the "br" as special case
    // convertFromHTML: {
    //   htmlToBlock:(nodeName, node) => {
    //     const blockName = Object.keys(EMPTY_BLOCKS).find(k => {
    //       return EMPTY_BLOCKS[k].type == nodeName
    //     })
    //     if(blockName && node.innerHTML == "<br>") {
    //       // TODO more conditions may be added
    //       console.log("return the mepty block", node)
    //       return {
    //         type: blockName,
    //         text: "ABC"
    //       }
    //     }
    //   }
    // },
  };
};
