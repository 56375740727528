import React, { useState, useEffect, useContext } from 'react'

import { runDataQuery } from 'bwax/query/runClientQuery'

import buildFieldSummary from 'Client/js/builders/buildFieldSummary'
import buildFieldInput from 'Client/js/builders/buildFieldInput';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import Message from 'Client/js/ui/Message';

import './ExtensionSettingEdit.less'

import { processInputValue } from 'bwax/query/QueryBuilder'

const QueryContainer = require("bwax-ui/re/legacy/QueryContainer.bs").make;

export default function ExtensionSettingEdit(props) {

    const { match, extensions, facade, loadingComp : Loading } = props

    const allEntities = facade.entities;
    const allDataTypes = facade.dataTypes;

    const { params } = match
    const { key } = params

    /// setting fields

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext)

    /// hook state: setting values 
    const [settingValues, setSettingValues] = useState(undefined)
    /// ========

    const ext = extensions.find(e => e.key === key)
    useEffect(() => {
        if(ext) {
            loadAndReset()
        }
    }, [ext])

    if (ext === undefined) {
        return <div>扩展 {key} 不存在</div>
    }

    const { settings } = ext

    /// hook effects:

    /// load its setting
    const runQuery = runDataQuery({ sessionToken, sandbox, tenantCode })
    async function loadAndReset() {
        
        const { ok, data } = await loadExtSettingValues(key, settings, runQuery, { allEntities, allDataTypes })
        if (ok) {
            setSettingValues(data)
        }
    }

    if (settingValues === undefined) {
        return <Loading />
    }


    const onSaveSetting = async (formData) => {
        const inputValues = settings.reduce((acc, f) => {
            const { key } = f
            if(formData[key] !== undefined) {
                return {
                    ...acc,
                    [key]: processInputValue(f, formData[key])
                }
            } else {
                return acc
            }
        }, {})
        
        const { ok, data } = await saveExtSettingValues(key, settings, {
            ...(settingValues || {}),
            ...inputValues
        }, runQuery, { allEntities, allDataTypes })
        if (ok) {
            setSettingValues(data)
        }
    }

    const onSave = async (params, env) => {
        const { formData } = params
        await onSaveSetting(formData)
    }

    const refName = 'data'
 
    const content =  {
        type: 'admin::Form',
        params: {
            onSave: {
                action:  [{
                    type: 'admin::BridgeAction', 
                    to: onSave,
                    formData: settings.reduce((acc, f) => {
                        return {
                            ...acc,
                            [f.key]: `\${input.validated.${f.name}}`
                        }
                    }, {})
                }, {
                    type: "MarkSaved"
                }]
            },
            items: settings.map(f => {
                const { name, desc, key } = f;

                const params = f.type === 'Image' ? {
                    width: 120,
                    height: 120,
                    processor: 'medium'
                } : {}
                return {
                    name,
                    editKey: name,
                    field: f,
                    tip: (desc && desc !== name) ? desc : null,
                    display: buildFieldSummary(
                        f,
                        `${refName}.${key}`,
                        { allDataTypes, allEntities, params }
                    ),
                    editable: true,
                    input: buildFieldInput(
                        f,
                        name,
                        { 
                            initialValue: `\${${refName}.${key}}`,      /// initial value
                            fixedValue: undefined,      /// fixed value

                            allDataTypes, 
                            allEntities, 
                            params 
                        }
                    ),
                }
            })
        }
    }

    const def = {
        view: {
            type_: "admin::templates::PageContent",
            content
        }
    }

    let qcProps = {
        baseData: {
            data: settingValues || {}
        },
        ...props,
        match_: match
    }
    
    return (
        <QueryContainer 
            key={"settings-edit-" + key}
            def={def}
            { ...qcProps }
        />
    )

}


function convertLinkData (data, settings, { allEntities, allDataTypes }) {
    // convert the data fields - which are links (only one layer)

    return Object.keys(data).reduce((acc, key) => {

        function convert (values) {
            const fieldType = settings.find(s => s.key == key);
            if(fieldType.type == "Link") {  
                const options = fieldType.options || {}
                const entity = allEntities.find(e => e.name == options.entity || e.key == options.entity)
                if(entity) {
                    const allFields = [ ...entity.fields, ...entity.virtualFields ]

                    // 只转换一层，以后再完善 TODO
                    return Object.keys(values).reduce((acc, key) => {
                        const f = allFields.find(f => f.key == key); 
                        const newKey = f ? f.name : key;
                        return {
                            ...acc,
                            [newKey]: values[key]
                        }
                    }, {})
                }
            }    

            return values
        }
        
        return {
            ...acc,
            [key]: convert(data[key])
        }        
    }, {});

}   

async function loadExtSettingValues(extKey, settings, runQuery, { allEntities, allDataTypes }) {
    const queryText = QueryLoadExtSettings(extKey, settings,  { allEntities, allDataTypes })
    const result = await runQuery(queryText)()
    const { errors, data } = JSON.parse(result)
    if (errors) {
        Message.error(`加载数据出错 ${errors.map(e => e.message).join(";")}`)
        return { ok: false, errors }
    }
    return {
        ok: true, data: convertLinkData(data[`load${extKey}Setting`], settings, { allEntities, allDataTypes })
    }
}

async function saveExtSettingValues(extKey, settings, settingValues, runQuery, { allEntities, allDataTypes }) {
    const queryText = MutSaveExtSettings(extKey, settings, { allEntities, allDataTypes })
    const result = await runQuery(queryText)({
        input: {
            ...(settingValues || {}),
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(result)


    if (errors) {
        Message.error(`保存数据出错 ${errors.map(e => e.message).join(";")}`)
        return { ok: false, errors }
    }
    return {
        ok: true, data: convertLinkData(data[`save${extKey}Setting`].settings, settings, { allEntities, allDataTypes })
    }
}



function buildSelections(settings, { allEntities, allDataTypes }) {

    return settings.reduce((acc, current) => {
        const { key, type } = current

        if (type === 'File' || type === 'Image') {
            return acc + '\r\n' + key + ' ' +
                `{
                    expireTime
                    title
                    path
                    size
                    contentType
                    url
                }`
        } else if (type === "Link") {

            // copy 了 buildRecordSummary 的逻辑
            const options = current.options || {};
            const entity = allEntities.find(e => e.name == options.entity || e.key == options.entity);

            const allFields = [ ...entity.fields, ...entity.virtualFields ].sort((a, b) => {
                // 给 field 排序
                /// 根据 displayWeight 从大到下排列
                const getWeight = f => f.displayWeight || 0
                // 重的在前面
                return getWeight(b) - getWeight(a)
            })
                
            const f = allFields.find(f => (f.type === 'ShortText' || f.type === "Text"))
            if(f){
                let fKey = f.key;
                return  acc + '\r\n' + key + ` { id ${fKey} } `
            } else {
                return acc + '\r\n' + key + ` { id } `
            }

            
        } else {
            return acc + '\r\n' + key
        }
    }, '')
}

function QueryLoadExtSettings(extKey, settings, { allEntities, allDataTypes }) {
    const selections = buildSelections(settings, { allEntities, allDataTypes })

    const queryText = `
        query {
            load${extKey}Setting {
                ${selections}
            }
        }
    `
    return queryText
}

function MutSaveExtSettings(extKey, settings, { allEntities, allDataTypes }) {
    const selections = buildSelections(settings, { allEntities, allDataTypes })

    const queryText = (
        `mutation ($input: Save${extKey}SettingInput!) {
           save${extKey}Setting(input: $input) {
             settings {
               ${selections}
             }
           }
        }`
    )

    return queryText

}