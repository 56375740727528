// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");
var IconButton$BwaxAdmin = require("../components/IconButton.bs.js");

function IconButtonWidget(Props) {
  var params = Props.params;
  var icon = Plate$BwaxAdmin.$$Option.with_default("border", params.icon);
  var color = Plate$BwaxAdmin.$$Option.with_default("N80", params.color);
  var match = params.size;
  var size;
  if (match !== undefined) {
    switch (match) {
      case "large" :
          size = /* large */-272944197;
          break;
      case "small" :
          size = /* small */-9768761;
          break;
      default:
        size = /* medium */-20425611;
    }
  } else {
    size = /* medium */-20425611;
  }
  var tip = params.tip;
  var onClick = Plate$BwaxAdmin.$$Option.with_default((function (param) {
          console.log("On Clicked");
          return /* () */0;
        }), params.onClick);
  return React.createElement(IconButton$BwaxAdmin.make, {
              icon: icon,
              size: size,
              onClick: onClick,
              color: color,
              tip: tip
            });
}

var make = IconButtonWidget;

exports.make = make;
/* react Not a pure module */
