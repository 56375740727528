// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../BaseUI.bs.js");
var QrCode$BwaxAdmin = require("./QrCode.bs.js");
var DomUtils$BwaxAdmin = require("../utils/DomUtils.bs.js");
var Spinners$BwaxAdmin = require("./Spinners.bs.js");
var JsonUtils$BwaxAdmin = require("../../../bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryRunner$BwaxAdmin = require("../../../bwax-js/re/binding/QueryRunner.bs.js");
var SetTokenCookie = require("bwax-ui/setTokenCookie");
var DataLoaderContext = require("bwax-ui/store/DataLoaderContext");

var CurrentUser = { };

var mutGenToken = "\n  mutation GenLoginTokenMutation (\n      $input: GenLoginTokenInput!\n    ) {\n       genLoginToken(input: $input) {         \n         loginToken {\n           id\n           token\n           status\n         }\n       }\n    }\n";

function genToken(dlc) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(QueryRunner$BwaxAdmin.toEnv(dlc)), mutGenToken, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "input",
                            {
                              clientMutationId: new Date()
                            }
                          ],
                          /* [] */0
                        ]))), undefined, /* () */0).then((function (text) {
                return Promise.resolve(Belt_Option.flatMap(Belt_Option.flatMap(JsonUtils$BwaxAdmin.parse(text), (function (j) {
                                      return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "data",
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        "genLoginToken",
                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            "loginToken",
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                "token",
                                                                /* [] */0
                                                              ])
                                                          ])
                                                      ])
                                                  ]));
                                    })), Js_json.decodeString));
              }));
}

var qLoginTokenStatus = "\n    query($token: String!) {\n      loginTokenStatus(token: $token)\n    }\n";

function checkTokenStatus(token, dlc) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(dlc), qLoginTokenStatus, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "token",
                            token
                          ],
                          /* [] */0
                        ]))), undefined, /* () */0).then((function (text) {
                return Promise.resolve(Belt_Option.flatMap(Belt_Option.flatMap(JsonUtils$BwaxAdmin.parse(text), (function (j) {
                                      return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "data",
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        "loginTokenStatus",
                                                        /* [] */0
                                                      ])
                                                  ]));
                                    })), Js_json.decodeString));
              }));
}

var mutAuthWithLoginToken = "\n  mutation AuthWithLoginTokenMutation (\n      $input: AuthWithLoginTokenInput!\n    ) {\n       authWithLoginToken(input: $input) {\n         authSession {\n           id\n           authUser {\n             id\n             systemRoles\n           }\n           token\n         }\n       }\n    }\n";

function autWithLoginToken(token, dlc) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(dlc), mutAuthWithLoginToken, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "input",
                            {
                              clientMutationId: new Date(),
                              token: token
                            }
                          ],
                          /* [] */0
                        ]))), undefined, /* () */0).then((function (text) {
                return Promise.resolve(Belt_Option.flatMap(Belt_Option.flatMap(JsonUtils$BwaxAdmin.parse(text), (function (j) {
                                      return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "data",
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        "authWithLoginToken",
                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            "authSession",
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                "token",
                                                                /* [] */0
                                                              ])
                                                          ])
                                                      ])
                                                  ]));
                                    })), Js_json.decodeString));
              }));
}

function getProtocolAndHost(param) {
  return Belt_Option.flatMap(DomUtils$BwaxAdmin.$$document, (function (doc) {
                var configuredOne = Belt_Option.flatMap(Belt_Option.flatMap(doc.userenv, (function (ue) {
                            return ue.mobileHosts;
                          })), (function (mh) {
                        return Belt_Array.get(mh, 0);
                      }));
                var maybeHost = configuredOne !== undefined ? configuredOne : Belt_Option.flatMap(doc.location, (function (loc) {
                          return loc.host;
                        }));
                var maybeProtocol = "https:";
                if (maybeProtocol !== undefined && maybeHost !== undefined) {
                  return /* tuple */[
                          maybeProtocol,
                          maybeHost
                        ];
                }
                
              }));
}

function LoginQrCode$Comp(Props) {
  var size = Props.size;
  var pc = Props.pc;
  var match = Props.rules;
  var rules = match !== undefined ? match : /* [] */0;
  var theme = Props.theme;
  var onSuccess = Props.onSuccess;
  var match$1 = Props.onCountDown;
  var onCountDown = match$1 !== undefined ? match$1 : (function (param) {
        return /* () */0;
      });
  var match$2 = Props.reloadUser;
  var reloadUser = match$2 !== undefined ? match$2 : (function (param) {
        return Promise.resolve(null);
      });
  var match$3 = Props.showCountDown;
  var showCountDown = match$3 !== undefined ? match$3 : false;
  var match$4 = Props.tip;
  var tip = match$4 !== undefined ? match$4 : "请用手机微信扫描登录 ";
  var dlc = React.useContext(DataLoaderContext.default);
  var env = QueryRunner$BwaxAdmin.toEnv(dlc);
  var match$5 = React.useState((function () {
          return ;
        }));
  var setLoginToken = match$5[1];
  var loginToken = match$5[0];
  var match$6 = React.useState((function () {
          return 90;
        }));
  var setCount = match$6[1];
  var count = match$6[0];
  var maybeProtocolAndHost = getProtocolAndHost(/* () */0);
  var confirmLoginSuccess = function (sessionToken) {
    SetTokenCookie.default(sessionToken, Caml_option.some(dlc));
    Curry._1(reloadUser, /* () */0).then((function (_user) {
            Curry._1(onSuccess, /* () */0);
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var updateLoginToken = function (param) {
    genToken(env).then((function (token) {
            Curry._1(setLoginToken, (function (param) {
                    return token;
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var countRef = React.useRef(count);
  countRef.current = count;
  React.useEffect((function () {
          updateLoginToken(/* () */0);
          var timerId = setInterval((function (param) {
                  var count = countRef.current;
                  Curry._1(onCountDown, count - 1 | 0);
                  if (count > 1) {
                    return Curry._1(setCount, (function (prev) {
                                  return prev - 1 | 0;
                                }));
                  } else {
                    Curry._1(setCount, (function (param) {
                            return 90;
                          }));
                    return updateLoginToken(/* () */0);
                  }
                }), 1000);
          return (function (param) {
                    clearInterval(timerId);
                    return /* () */0;
                  });
        }), ([]));
  React.useEffect((function () {
          if (loginToken !== undefined) {
            var t = loginToken;
            var timerId = setInterval((function (param) {
                    checkTokenStatus(t, env).then((function (s) {
                            if (Caml_obj.caml_equal(s, "Confirmed")) {
                              autWithLoginToken(t, env).then((function (mst) {
                                      if (mst !== undefined) {
                                        confirmLoginSuccess(mst);
                                      }
                                      return Promise.resolve(/* () */0);
                                    }));
                            }
                            return Promise.resolve(/* () */0);
                          }));
                    return /* () */0;
                  }), 1500);
            return (function (param) {
                      clearInterval(timerId);
                      return /* () */0;
                    });
          }
          
        }), /* array */[loginToken]);
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.marginTop(Css.rem(1.0)),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.alignSelf(/* center */98248149),
              /* [] */0
            ])
        ]));
  var renderCountDown = showCountDown ? React.createElement("div", {
          className: className
        }, tip + (" " + (String(count) + " s"))) : null;
  var inner;
  if (loginToken !== undefined) {
    if (maybeProtocolAndHost !== undefined) {
      var match$7 = maybeProtocolAndHost;
      var match$8 = dlc.sandbox;
      var prefix = (
        match$8 ? "sandbox/" : ""
      ) + (Plate$BwaxAdmin.$$Option.with_default("", Plate$BwaxAdmin.$$Option.map((function (c) {
                    return "-/" + (c + "/");
                  }), dlc.tenantCode)) + "ext/auth/confirm-token");
      var url = "" + (String(match$7[0]) + ("//" + (String(match$7[1]) + ("/" + (String(prefix) + ("/" + (String(loginToken) + "")))))));
      inner = (function (param, param$1, param$2, param$3) {
          return React.createElement(React.Fragment, undefined, React.createElement(QrCode$BwaxAdmin.make, {
                          value: url,
                          size: size
                        }), renderCountDown);
        });
    } else {
      var partial_arg = "未能获取地址信息";
      inner = (function (param, param$1, param$2, param$3) {
          return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg, param, param$1, param$2, param$3);
        });
    }
  } else {
    var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* centerX */434500835,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* centerY */434500836,
            /* [] */0
          ])
      ]);
    inner = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.box(partial_arg$1, (function (param, param$1, param$2, param$3) {
                      return React.createElement(Spinners$BwaxAdmin.ClipLoader.make, {
                                  color: /* N60 */3890952,
                                  loading: true,
                                  theme: theme
                                });
                    }), param, param$1, param$2, param$3);
      });
  }
  return BaseUI$BwaxAdmin.box(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* `px */Caml_chrome_debugger.polyVar("px", [
                        25096,
                        size
                      ])
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `height */Caml_chrome_debugger.polyVar("height", [
                        38537191,
                        /* `px */Caml_chrome_debugger.polyVar("px", [
                            25096,
                            size
                          ])
                      ]),
                    rules
                  ])
              ]), inner, theme, pc, /* [] */0, "nokey");
}

var Comp = {
  make: LoginQrCode$Comp
};

function create(key, pc, theme, size, rules, onCountDown, onSuccess) {
  return React.createElement(LoginQrCode$Comp, {
              size: size,
              pc: pc,
              rules: rules,
              theme: theme,
              onSuccess: onSuccess,
              onCountDown: onCountDown,
              key: key
            });
}

var $$document = DomUtils$BwaxAdmin.$$document;

var init_count = 90;

exports.CurrentUser = CurrentUser;
exports.mutGenToken = mutGenToken;
exports.genToken = genToken;
exports.qLoginTokenStatus = qLoginTokenStatus;
exports.checkTokenStatus = checkTokenStatus;
exports.mutAuthWithLoginToken = mutAuthWithLoginToken;
exports.autWithLoginToken = autWithLoginToken;
exports.$$document = $$document;
exports.getProtocolAndHost = getProtocolAndHost;
exports.init_count = init_count;
exports.Comp = Comp;
exports.create = create;
/* Css Not a pure module */
