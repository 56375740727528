import React, { useState, useEffect, useContext } from 'react'

import AutoComplete from 'Client/js/ui/components/AutoComplete'

import { evaluateWithQuery } from 'bwax'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import { useFieldUsedValues } from 'Client/js/queries/getFieldUsedValues'

import "./TextAutoComplete.less"
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

import QueryTargetContext from 'bwax-ui/legacy/store/QueryTargetContext';
import FacadeContext from 'bwax/util/FacadeContext';

export default function TextAutoComplete (props) {

    const {
        value,
        onChange,

        allEntities,
        allDataTypes,

        params
    } = props

    const queryTarget = useContext(QueryTargetContext) || "data";

    const { facade } = useContext(FacadeContext) || {};

    const {
        showEmptyOptions = false,
        showValidationOptions = false,
        showUsedValuesOptions = true,

        autoFocus,
        size,

        placeholder,
        disabled,
        onKeyUp,
        onBlur,

        entityName,
        field = {},
        contextEntityName,
        contextRecordId,

    } = params

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)

    // Validation 相关的代码可能要跟 ValidationSourcing 合并一下 TODO
    const [refDS, setRefDS] = useState([])
    const [lookupDS, setLookupDS] = useState([])
    const [listDS, setListDS] = useState([])

    const [keyword, setKeyword] = useState("")

    const debouncedKeyword = useDebounce(keyword, 400);

    const usedValues = useFieldUsedValues({ 
        entityName, fieldName: field.name, keyword: debouncedKeyword,
        applicationCode: facade && facade.applicationCode,
     })

    useEffect(() => {
        if (value !== keyword) {
            setKeyword(value)
        }
    }, [value])


    const evaluate = (def, baseData) => {
        return evaluateWithQuery(
            def,
            baseData,
            {
                allDataTypes, allEntities, queryTarget,
                sessionToken,
                tenantCode,
                sandbox
            }
        )
    }

    const validation =  field && field.options && field.options.validation ? field.options.validation : {};

    useEffect(() => {
        if(showValidationOptions) {
            if(validation.list) {
                setListDS(validation.list);
                
                // clear the other validation ds
                setLookupDS([]);
                setRefDS([]);
            }

            if(validation.ref && contextEntityName && contextRecordId) {
                const refArr = validation.ref.split(".")
                const entity = allEntities.find(e => e.name === entityName)
                if (entity && refArr.length > 0) {
                    const refField = entity.fields.find(f => f.name === refArr[0])
                    if (refField.type === "Link" && refField.options && refField.options.entity === contextEntityName) {
                        const targetDef = buildDefToFindRecordValue({
                            entityName: contextEntityName,
                            id: contextRecordId,
                            fieldPath: refArr.slice(1).join(".")
                        });
                        (async () => {
                            const result = await evaluate(targetDef, {})
                            if (result) {
                                setRefDS(result)
                            }
                        })()
                    }
                }

                // clear the other validation ds
                setLookupDS([]);
                setListDS([]);

            }
            
            if(validation.lookup) {
                const targetDef = buildDefToFindLookupValue(validation.lookup);
                (async () => {
                    const result  = await evaluate(targetDef, {});
                    if(result) {
                        setLookupDS(result);
                    }
                })()

                // clear other validation ds
                setRefDS([])
                setListDS([])

            }
        }
    }, [field])

    const emptyDS = !field.required && showEmptyOptions ? ["（空）", "（非空）"] : []

    const validationDS = showValidationOptions ? [...refDS, ...listDS, ...lookupDS ] : []

    const hasValidationDS = validationDS.length > 0

    const usedValuesDS = !hasValidationDS && showUsedValuesOptions ? (usedValues || []).map(uv => uv.value) : []

    const dataSource = [
        ...emptyDS,
        ...validationDS,
        ...usedValuesDS
    ]


    return (
        <div
            className="text-auto-complete-box" 
            onKeyUp={onKeyUp}>
            <AutoComplete
                autoFocus={autoFocus}
                value={value}
                onChange={v => {
                    setKeyword(v)
                    onChange(v)
                }}
                onBlur={onBlur}
                disabled={disabled}
                placeholder={placeholder}
                data={dataSource}
                size={size}
                />
        </div>
    )
}

function buildDefToFindRecordValue({ entityName, id, fieldPath }) {
    return {
        __query__: {
            data: {
                entityName,
                type: "findOne",
                criteria: {
                    id
                },
            },
            __for__: `\${data.${fieldPath}}`
        }
    }
}

function buildDefToFindLookupValue( cateoryName ) {
    return {
        __query__: {
            data: {
                entityName: "选项文本值",
                type: "findOne",
                criteria: {
                    "类别.名称": cateoryName
                }
            },
            __for__: `\${data.值}`
        }
    }
}


