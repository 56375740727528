
import React, { useEffect, useState } from 'react'

import { 
    BoxIcon, CornersIcon, BorderTopIcon, BorderRightIcon, BorderBottomIcon, BorderLeftIcon, BorderAllIcon, Cross2Icon,
    BorderSolidIcon, BorderDottedIcon, BorderDashedIcon
} from '@radix-ui/react-icons'
import { 
    AiOutlineRadiusBottomleft, AiOutlineRadiusBottomright, AiOutlineRadiusUpleft, AiOutlineRadiusUpright 
} from 'react-icons/ai'

import { SketchPicker } from 'react-color';

import { Input, Select, Slider } from '@arco-design/web-react';
const Option = Select.Option;

import Tooltip from 'bwax-ui/components/Tooltip';
import Popover from 'bwax-ui/components/legacy/Popover'
import { RGBtoHex } from 'bwax-ui/color/hsv'
import ResetableLabel from '../components/ResetableLabel';
import { colorRgbRegx, colorHexRegx } from '../StyleForm';

import './Borders.less'

export default function Borders({ attributes, onChange }) {

    const defaultBorderProps = {
        width: { value: 0, unit: 'px'},
        style: 'none',
        color: 'black'
    }

    const defaultRadiusEach = { 
        borderTopLeftRadius: { value: 0, unit: 'px' },
        borderTopRightRadius: { value: 0, unit: 'px' },
        borderBottomRightRadius: { value: 0, unit: 'px' },
        borderBottomLeftRadius: { value: 0, unit: 'px' }
    }

    const [editAllCorners, setEditAllCorners] = useState(true) // false: 分别编辑各 radius 模式
    const [radius, setRadius] = useState(defaultRadiusEach)
    const [allCornersRadius, setAllCornersRadius] = useState({ value: 0, unit: 'px' })
    const [border, setBorder] = useState({
        borderAll: defaultBorderProps,
        borderTop: defaultBorderProps,
        borderRight: defaultBorderProps,
        borderBottom: defaultBorderProps,
        borderLeft: defaultBorderProps
    })
    const [activeBorderSides, setActiveBorderSides] = useState([])
    const [selectedBorderSide, setSelectedBorderSide] = useState('borderAll')
    const [colorPickerVisible, setColorPickerVisible] = useState(false)

    function isRadiusAllSame (allRadiusObj) {
        return Object.values(allRadiusObj).every(valueObj => {
            return valueObj.value === allRadiusObj.borderTopLeftRadius.value && valueObj.unit === allRadiusObj.borderTopLeftRadius.unit
        })
    }

    function isRadiusAllValuesSame (allRadiusObj) {
        return Object.values(allRadiusObj).every(valueObj => {
            return valueObj.value === allRadiusObj.borderTopLeftRadius.value
        })
    }

    function isRadiusAllUnitsSame (allRadiusObj) {
        return Object.values(allRadiusObj).every(valueObj => {
            return valueObj.unit === allRadiusObj.borderTopLeftRadius.unit
        })
    }

    const singleValueRegx = /^(\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?$/

    useEffect(() => {
        let radiusEach = {...defaultRadiusEach}
        if(attributes['borderRadius']) {
            const radiusArr = attributes['borderRadius'].split(" ")
            radiusArr.map((radius, index) => {
                const matchResult = radius.match(singleValueRegx)
                if(matchResult) {
                    const valueObj = { value: matchResult[1], unit: matchResult[2] ? matchResult[2] : 'px' }
                    radiusEach = {
                        ...radiusEach,
                        borderTopLeftRadius: index === 0 ? valueObj : radiusEach.borderTopLeftRadius, 
                        borderTopRightRadius: index === 0 || index === 1 ? valueObj : radiusEach.borderTopRightRadius,
                        borderBottomRightRadius: index === 0 || index === 2 ? valueObj : radiusEach.borderBottomRightRadius,
                        borderBottomLeftRadius: index === 0 || index === 1 || index === 3 ? valueObj : radiusEach.borderBottomLeftRadius
                    }
                }
            })

            setRadius({ ...radius, ...radiusEach })
            if(!isRadiusAllSame(radiusEach)) {
                setEditAllCorners(false)
                setAllCornersRadius({ value: undefined, unit: 'px'})
            } else {
                let newAllCornersRadius = { ...allCornersRadius }
                const { value, unit } = radiusEach.borderTopLeftRadius
                if(isRadiusAllUnitsSame(radiusEach)) {
                    newAllCornersRadius = {
                        ...newAllCornersRadius,
                        unit
                    }
                }

                if(isRadiusAllValuesSame(radiusEach)) {
                    newAllCornersRadius = {
                        ...newAllCornersRadius,
                        value
                    }
                }

                setAllCornersRadius(newAllCornersRadius)
            }
        } else {
            setRadius({ ...radius, ...radiusEach })
            setEditAllCorners(true)
            setAllCornersRadius({ value: 0, unit: 'px'})
        }

        let matchedBorders = {}
        Object.keys(border).map(b => {
            if(attributes[b]) {
                const borderValueArr = attributes[b].split(" ")
                const borderWidthMatchResult = borderValueArr[0].match(singleValueRegx)
                matchedBorders = {
                    ...matchedBorders,
                    [b]: {
                        width: { value: `${borderWidthMatchResult[1]}`, unit: `${borderWidthMatchResult[2]}` },
                        style: borderValueArr[1],
                        color: borderValueArr[2]
                    }
                }
            }
        })

        setBorder({
            ...border,
            ...matchedBorders
        })
        setActiveBorderSides(Object.keys(matchedBorders))

        const borderKeys = ["borderTop", "borderRight", "borderBottom", "borderLeft"] 
        const borderAttributes = Object.keys(attributes).filter(attr => borderKeys.find(b => b === attr && !!attributes[b]))
        const isAllBorderActive = borderAttributes.length === 4 && borderKeys.every(b => attributes[b] === attributes.borderTop)

        if(isAllBorderActive) {
            const borderValueArr = attributes['borderTop'].split(" ")
            const borderWidthMatchResult = borderValueArr[0].match(singleValueRegx)
            setActiveBorderSides(['borderAll'])
            setBorder({
                ...border,
                ...matchedBorders,
                borderAll: {
                    width: { value: `${borderWidthMatchResult[1]}`, unit: `${borderWidthMatchResult[2]}` },
                    style: borderValueArr[1],
                    color: borderValueArr[2]
                }
            })
        }

    }, [attributes])

    const radiusUnits = ['px', '%', 'em', 'rem', 'ch', 'vw', 'vh']

    function getNewRadiusObj (styleKey, newRadius) {

        function getValueWithUnitStr () {
            return  Object.keys(radius).reduce((acc, current, currentIndex) => {
                const { value, unit } = radius[current]
                const valueWithUnitStr = styleKey ? (current === styleKey ? newRadius : `${value}${value === '0' ? '' : unit}`) : newRadius
                return acc + (currentIndex === 0 ? valueWithUnitStr : ' ' + valueWithUnitStr)
            }, "")
        }
        
        const radiusStr = getValueWithUnitStr()
        return { borderRadius: radiusStr ? radiusStr : undefined }
    }

    function setAllRadius (newRadius) {
        const { value, unit } = newRadius
        let radiusEach = {...radius}
        Object.keys(radiusEach).map(radiusKey => {
            radiusEach = {
                ...radiusEach,
                [radiusKey]: {
                    ...radiusEach[radiusKey],
                    value,
                    unit
                }
            }
        })
        setRadius({ ...radius, ...radiusEach })
    }

    function updateAllValueOrUnit (value, unit) {
        let radiusEach = {...radius}
        Object.keys(radiusEach).map(radiusKey => {
            radiusEach = {
                ...radiusEach,
                [radiusKey]: {
                    ...radiusEach[radiusKey],
                    value: value ? value : radiusEach[radiusKey].value,
                    unit: unit ? unit : radiusEach[radiusKey].unit
                }
            }
        })
        setRadius({ ...radius, ...radiusEach })

        function getValueWithUnitStr () {
            return  Object.keys(radius).reduce((acc, current, currentIndex) => {
                const actualValue = value ? value : radius[current].value
                const valueWithUnitStr = `${actualValue}${actualValue === '0' ? '' : (unit ? unit : radius[current].unit)}`
                return acc + (currentIndex === 0 ? valueWithUnitStr : ' ' + valueWithUnitStr)
            }, "")
        }
        
        const radiusStr = getValueWithUnitStr()
        return onChange({ borderRadius: radiusStr ? radiusStr : undefined })
    }

    function renderRadiusUnitSelect (styleKey) {

        const activeValue = styleKey ? radius[styleKey].unit : allCornersRadius.unit
        
        return (
            <Select value={activeValue} size={'mini'} arrowIcon={null} className="unit-select" triggerProps={{ autoAlignPopupWidth: false }} 
                onChange={unit => {
                    if(styleKey) {
                        setRadius({ ...radius, [styleKey]: {...radius[styleKey], unit } })
                        onChange(getNewRadiusObj(styleKey, `${radius[styleKey].value ? radius[styleKey].value : '0'}${radius[styleKey].value === 0 ? '' : unit}`))
                    } else {
                        setAllCornersRadius({ ...allCornersRadius, unit })
                        if(!editAllCorners) {
                            // 在分别编辑各 radius 的模式下修改所有的 unit，保留各自的 value 不变。
                            updateAllValueOrUnit(null, unit)
                        } else {
                            if(allCornersRadius.value && allCornersRadius.value !== '0') {
                                const { borderTopLeftRadius } = radius
                                const { value } = borderTopLeftRadius
                                setAllRadius({ value, unit })
                                onChange(getNewRadiusObj(null, `${value}${value === '0' ? '' : unit}`))
                            }
                        }
                        
                    }
                }} 
            >
                {radiusUnits.map(r => (
                    <Option key={r} value={r} className='unit-option'>
                        {r.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
    }

    function updateRadius (value) {
        if(!editAllCorners) {
            // 在分别编辑各 radius 的模式下修改所有的 value，保留各自的 unit 不变。
            updateAllValueOrUnit(value, null)

        } else {
            const { borderTopLeftRadius } = radius
            const { unit } = borderTopLeftRadius
            setAllRadius({ value, unit })
            onChange(getNewRadiusObj(null, `${value}${value === '0' ? '' : unit}`))
        }
    }

    function inputRadius (styleKey, value) {
        if(styleKey) {
            setRadius({ ...radius, [styleKey]: { ...radius[styleKey], value }})

        } else {
            setAllCornersRadius({ ...allCornersRadius, value })
        }
        
    }

    function inputRadiusConfirm (styleKey) {
        if(styleKey) {
            const { value, unit } = radius[styleKey]
            const matchedResult = value ? value.match(singleValueRegx) : null

            if(matchedResult) {
                const matchedValue = matchedResult[1]
                const matchedUnit = matchedValue === '0' ? '' : (matchedResult[2] ? matchedResult[2] : unit)
                setRadius({ ...radius, [styleKey]: { ...radius[styleKey], value: matchedValue, unit: matchedUnit }})
                onChange(getNewRadiusObj(styleKey, `${matchedValue}${matchedUnit}`))
            }
        } else {
            const { value, unit } = allCornersRadius
            const matchedResult = value ? value.match(singleValueRegx) : null
            const currentUnit = !editAllCorners && !isRadiusAllUnitsSame(radius) ? 'px' : unit

            if(matchedResult) {
                const matchedValue = matchedResult[1]
                const matchedUnit = matchedValue === '0' ? '' : (matchedResult[2] ? matchedResult[2] : currentUnit)
                setAllRadius({ value: matchedValue, unit: matchedUnit })
                setAllCornersRadius({ ...allCornersRadius, value: matchedValue, unit: currentUnit })
                onChange(getNewRadiusObj(null, `${matchedValue}${matchedUnit}`))
            }
        }
    }

    const borderStyles = [{
        value: "none", icon: <Cross2Icon/>, tooltip: 'None'
    }, {
        value: "solid", icon: <BorderSolidIcon/>, tooltip: "Solid"
    }, {
        value: "dashed", icon: <BorderDashedIcon/>, tooltip: "Dashed"
    }, {
        value: "dotted", icon: <BorderDottedIcon/>, tooltip: "Dotted"
    }]

    const borderWidthUnits = ['px', 'em', 'rem', 'ch', 'vw', 'vh']

    function borderWidthUnitSelect () {

        return (
            <Select value={border[selectedBorderSide].width.unit} size={'mini'} arrowIcon={null} className="unit-select" triggerProps={{ autoAlignPopupWidth: false }} 
                onChange={unit => {
                    if(selectedBorderSide === "borderAll") {
                        const commonBorderValue = `${border.borderAll.width.value}${unit} ${border.borderAll.style} ${border.borderAll.color}`
                        onChange({ 
                            borderTop: commonBorderValue,
                            borderRight: commonBorderValue,
                            borderBottom: commonBorderValue,
                            borderLeft: commonBorderValue
                        })
                    } else {
                        onChange({ 
                            [selectedBorderSide]: `${border[selectedBorderSide].width.value}${unit} ${border[selectedBorderSide].style} ${border[selectedBorderSide].color}`,
                        })
                    }
                }} 
            >
                {borderWidthUnits.map(r => (
                    <Option key={r} value={r} className='unit-option'>
                        {r.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
        
    }

    function updateColor (color) {
        if(selectedBorderSide === "borderAll") {
            const commonBorderValue = `${border.borderAll.width.value}${border.borderAll.width.unit} ${border.borderAll.style} ${color}`
            onChange({ 
                borderTop: commonBorderValue,
                borderRight: commonBorderValue,
                borderBottom: commonBorderValue,
                borderLeft: commonBorderValue
            })
        } else {
            onChange({ 
                [selectedBorderSide]: `${border[selectedBorderSide].width.value}${border.borderAll.width.unit} ${border[selectedBorderSide].style} ${color}`,
            })
        }
    }

    function renderBorderColor () {
        const currentColor = border[selectedBorderSide].color

        function renderColorPicker () {
            function onChangeComplete (color) {
                const { hex } = color
                updateColor(hex)
            }
        
            return (
                <SketchPicker
                    color={currentColor}
                    onChangeComplete={onChangeComplete} 
                />
            )
        }

        return (
            <Popover content={renderColorPicker()} visible={colorPickerVisible} className="borders-color-picker-popover"  onClickOutside={_ => {
                setColorPickerVisible(false)
            }}>
                <div className='border-color' style={{ backgroundColor: border[selectedBorderSide].color }} onClick={() => setColorPickerVisible(true)}/>
            </Popover>
           
        )
    }

    const bordersSelectIcons = [{
        key: "borderTop", icon: <BorderTopIcon/>
    }, {
        key: "borderLeft", icon: <BorderLeftIcon/>
    }, {
        key: "borderAll", icon: <BorderAllIcon/>
    }, {
        key: 'borderRight', icon: <BorderRightIcon/>
    }, {
        key: 'borderBottom', icon: <BorderBottomIcon/>
    }]

    function updateBorderStyle (value) {
        if(selectedBorderSide === "borderAll") {
            const commonBorderValue = `${border.borderAll.width.value}${border.borderAll.width.unit} ${value} ${border.borderAll.color}`
            onChange({ 
                borderTop: commonBorderValue,
                borderRight: commonBorderValue,
                borderBottom: commonBorderValue,
                borderLeft: commonBorderValue
            })
        } else {
            onChange({ 
                [selectedBorderSide]: `${border[selectedBorderSide].width.value}${border[selectedBorderSide].width.unit} ${value} ${border[selectedBorderSide].color}`,
            })
        }
    }

    function inputBorderWidth (value, newUnit) {
        if(selectedBorderSide === "borderAll") {
            let borderEach = { ...border }
            Object.keys(border).map(borderSide => {
                borderEach = {
                    ...borderEach,
                    [borderSide]: {
                        ...border[borderSide],
                        width: {
                            ...border[borderSide].width,
                            value,
                            unit: newUnit ? newUnit : border[borderSide].width.unit
                        }
                    }
                }
            })
            setBorder({
                ...border,
                ...borderEach
            })
        } else {
            setBorder({
                ...border,
                [selectedBorderSide]: {
                    ...border[selectedBorderSide],
                    width: {
                        ...border[selectedBorderSide].width,
                        value,
                        unit: newUnit ? newUnit : border[selectedBorderSide].width.unit
                    }
                }
            })
        }
    }

    function inputBorderWidthConfirm() {
        const borderWidthRegx = /^(\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh)?$/
        const { value, unit } = border[selectedBorderSide].width
        const matchedResult = value ? value.match(borderWidthRegx) : null
        if(matchedResult) {
            const matchedValue = matchedResult[1]
            const matchedUnit = matchedResult[2] ? matchedResult[2] : unit
            inputBorderWidth(matchedValue, matchedUnit)
            if(selectedBorderSide === "borderAll") {
                const commonBorderValue = `${matchedValue}${matchedUnit} ${border.borderAll.style} ${border.borderAll.color}`
                onChange({ 
                    borderTop: commonBorderValue,
                    borderRight: commonBorderValue,
                    borderBottom: commonBorderValue,
                    borderLeft: commonBorderValue
                })
            } else {
                onChange({ 
                    [selectedBorderSide]: `${matchedValue}${matchedUnit} ${border[selectedBorderSide].style} ${border[selectedBorderSide].color}`,
                })
            }
        }
        
    }

    function inputBorderColor (value) {
        setBorder({
            ...border,
            [selectedBorderSide]: {
                ...border[selectedBorderSide],
                color: value
            }
        })
    }

    function inputBorderColorBlur () {
        const { color } = border[selectedBorderSide]

        if(color.startsWith("rgb")) {
            // 去掉 rgb 中的空格
            if(color.match(colorRgbRegx)) {
                const matchRgbResult = color.match(colorRgbRegx)
                const convertedColor = RGBtoHex({r: parseInt(matchRgbResult[1]), g: parseInt(matchRgbResult[2]), b: parseInt(matchRgbResult[3])})
                updateColor(`#${convertedColor}`)
            }
        } else if(color.startsWith('#')) {
            if(color.match(colorHexRegx)) {
                updateColor(color)
            }
        }
    }
    
    const borderRadiusEachEls = [{
        styleKey: 'borderTopLeftRadius',
        icon: <AiOutlineRadiusUpleft/>,
    }, {
        styleKey: 'borderTopRightRadius',
        icon: <AiOutlineRadiusUpright/>
    }, {
        styleKey: 'borderBottomLeftRadius',
        icon: <AiOutlineRadiusBottomleft/>
    }, {
        styleKey: 'borderBottomRightRadius',
        icon: <AiOutlineRadiusBottomright/>
    }]

    return (
        <div className='borders-container'>
            <div className='radius-container'>
                <div className='radius-edit-all'>
                    <ResetableLabel allAttributes={attributes} label={"Radius"} attributesGroup={"border"} 
                        attribute={"borderRadius"} onChange={onChange}
                    />
                    <div className='corner-select-icons'>
                        <Tooltip text={"All corners"}>
                            <BoxIcon style={{ color: editAllCorners ? '#27282d' : '#c8cacc' }} onClick={() => setEditAllCorners(true)}/>
                        </Tooltip>
                        <Tooltip text={"Individual corners"}>
                            <CornersIcon style={{ marginLeft: 2, color: editAllCorners ? '#c8cacc' : '#27282d' }} 
                                onClick={() => setEditAllCorners(false)}
                            />
                        </Tooltip>
                    </div>
                    <Slider value={allCornersRadius.value ? parseFloat(allCornersRadius.value) : 0} size="mini"
                        onChange={value => updateRadius(value.toString())} style={{ width: 80, margin: '0 4px' }}/>
                    <Input value={allCornersRadius.value} size="mini" 
                        suffix={renderRadiusUnitSelect(null)} style={{ width: 64 }}
                        onChange={value => inputRadius(null, value)}
                        onBlur={() => inputRadiusConfirm(null)}
                        onPressEnter={() => inputRadiusConfirm(null)}
                    />
                </div>
                {
                    !editAllCorners ? (
                        <div className='radius-edit-individual'>
                            {
                                borderRadiusEachEls.map(borderRadiusEl => {
                                    const { styleKey, icon } = borderRadiusEl
                                    return (
                                        <div key={styleKey} className={`radius-single ${styleKey}`}>
                                            { icon }
                                            <Input value={radius[styleKey].value} size="mini" 
                                                suffix={renderRadiusUnitSelect(styleKey)} style={{ width: 64 }} 
                                                onChange={value => inputRadius(styleKey, value)}
                                                onBlur={() => inputRadiusConfirm(styleKey)}
                                                onPressEnter={() => inputRadiusConfirm(styleKey)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : null
                }
            </div>
            <div className='borders-edit-container'>
                <ResetableLabel allAttributes={attributes} label={"Borders"} attributesGroup={"border"} 
                    attribute={['borderTop', 'borderRight', 'borderBottom', 'borderLeft']} onChange={onChange}
                />
                <div className='borders-edit-area'>
                    <div className='borders-select-icons'>
                        {
                            bordersSelectIcons.map(i => {
                                const { key, icon } = i
                                return (
                                    <div key={key} className={`border ${key} ${selectedBorderSide === key ? 'selected' : 
                                        activeBorderSides.some(b => b === key) ? 'active' : ''
                                    }`} onClick={() => setSelectedBorderSide(key)}>
                                        {icon}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='border-input'>
                        <div className='style-with-options borderstyle-options'>
                            <div>Style</div>
                            <div className='options'>
                                {
                                    borderStyles.map((op, index) => {
                                        const { value, icon, tooltip } = op
                                        const isActive = border[selectedBorderSide].style === value

                                        return (
                                            <Tooltip key={index} text={tooltip}>
                                                <div className={`option-box ${isActive ? 'active' : ''}`}
                                                    onClick={() => updateBorderStyle(value) }
                                                >
                                                    {icon}
                                                </div>
                                            </Tooltip>
                                        )
                                    })
                                }
                                
                            </div>
                        </div>
                        <div className='border-width'>
                            <div>Width</div>
                            <Input value={border[selectedBorderSide].width.value} size="mini" suffix={borderWidthUnitSelect()}
                                onChange={value => inputBorderWidth(value)}
                                onBlur={() => inputBorderWidthConfirm()}
                                onPressEnter={() => inputBorderWidthConfirm()}
                            />
                        </div>
                        <div className='border-color-container'>
                            <div>Color</div>
                            <Input value={border[selectedBorderSide].color} size="mini" prefix={renderBorderColor()}
                                onChange={value => inputBorderColor(value)}  onBlur={() => inputBorderColorBlur()}
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
