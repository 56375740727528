
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react';

import { mapListOrNestedList } from '../listUtils';

import { extractStyleAttrs } from '../html/HtmlPageDesigner';

import prettyPrintCss from 'bwax/ml/printer/prettyPrintCss';
import prettyPrintLang from 'bwax/ml/printer/prettyPrintLang';
import { valueForCode } from './exprToValue';


const nonStyleAttrs = [
    "onClick", "onInput"
]

const pointerEvents = [
    "onClick", "onDoubleClick"
]

const generateOutput = getAttributeSetting => model => {

    const blocks = Object.values(model.contentDict);
    const rootBlock = blocks.find(block => {
        return blocks.every(b => !b.hasChild(block.id))
    });

    if (!rootBlock) {
        return { view: "view = div [] [];", styles: "" } ;
    }

    function convertModelDictToTree(rootID, dict) {
        const block = dict[rootID];
        const { childIds } = block;
        return {
            block,
            children: mapListOrNestedList(childIds, cid => convertModelDictToTree(cid, dict))
        }
    }

    const tree = convertModelDictToTree(rootBlock.id, model.contentDict);

    /**     
      view = 
        div 
            [ classList [("dass", True)] ] 
            [ div [ classList [ ("asdd", True )] ] []
            , div [ classList [ ("asss", True )] ] []
            ]

    */

    let styleDefs = {};

    function buildView(n) {

        const { block, children } = n;
        const { attributes, blockType } = block;
        const [styleAttrs, otherAttrs, ] = extractStyleAttrs(attributes);

        function buildAttributes() {
            const className = (() => {
                
                if(Object.keys(otherAttrs).some(name => pointerEvents.indexOf(name) !== -1)) {
                    // it handles pointer events, thus make it no select
                    styleAttrs["userSelect"] = "none";
                }

                if (Object.keys(styleAttrs).length > 0) {
                    const cssObj = css(styleAttrs);
                    styleDefs[cssObj.name] = cssObj;
                    return `className "lc-${cssObj.name}"`
                }}
            )();
            
            const otherAttributeCodes = Object.keys(otherAttrs).map(name => {
                // 只有 html attributes 才需要
                if(nonStyleAttrs.indexOf(name) != -1 && otherAttrs[name] !== undefined) {
                    const value = attributeValueToCode(otherAttrs[name], attributes[name]);

                    const valueCode = valueForCode(value, { attribute: getAttributeSetting(blockType, name)});

                    return `${name} ${valueCode}`
                }
            });
            const codes = [ className, ...otherAttributeCodes ].filter(x => x).join(", ");
            return `[ ${codes}]`;
        }

        function buildChildList(childList) {
            if (childList.length === 0) {
                return "[]"
            } else {
                return `[ ${childList.map(c => buildView(c)).join(", ")} ] `
            }
        }
        function buildChildren() {
            if (Array.isArray(children[0])) {
                return children.map(cl => buildChildList(cl).join(" "))
            } else {
                return buildChildList(children)
            }
        }
        if(blockType.toCode) {
            return blockType.toCode(attributes, buildAttributes(), children, buildChildren())
        } else {
            return `${blockType.name} ${buildAttributes()} ${buildChildren()}`;
        }
       
    }

    const viewSource = "view (model, webEnv): (Model, UI.WebEnv) -> a = " + buildView(tree);
    const viewFormatted = prettyPrintLang(80, viewSource);

    const styleSource = Object.keys(styleDefs).map(k => {
        return `.lc-${k}{${styleDefs[k].styles}}`;
    }).join(" ");

    const styleFormatted = prettyPrintCss(40, styleSource);

    return {
        view: viewFormatted.trim(),
        styles: styleFormatted.trim(),
    }
}


export default generateOutput;


// helpers
function attributeValueToCode(value, setting) {

    function processExpr(expr) {
        return `(${expr})`  // pretty print will remove unnecessary brackets
    }
    
    if(setting.useExpression) {
        if(value.$expr) {
            return processExpr(value.$expr)
        } else {
            // 这里其实还是要考虑它是什么类型
            return value
        }
    }
    return value

}