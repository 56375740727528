import React from 'react'

import './AttachmentView.less'

import { Modal } from 'antd'

import Tooltip from 'bwax-ui/components/Tooltip';

import AutoImage from 'Client/js/components/AutoImage'

import { Icon } from '@ant-design/compatible';

import { documentIcons, commonMimeTypeMap } from 'Client/js/utils/document'
import getImageURL from 'bwax-ui/getImageURL'

import Popover from 'bwax-ui/components/legacy/Popover';
import { Button } from 'rsuite';


const downloadAttachment = (attachment) => {
    var link = document.createElement('a')
    link.href = attachment.url
    link.download = attachment.title
    link.dispatchEvent(new MouseEvent('click'))
}

const viewPDF = attachment => {
    const encodeURL = encodeURIComponent(attachment.url)
    const targetURL = `/pdf/reader?file=${encodeURL}`
    window.open(targetURL, "_blank")
}

export default class AttachmentView extends React.Component {

    constructor() {
        super()
        this.state = {
            previewVisible: false,
            previewImgSrc: null
        }
    }

    static defaultProps = {
        imgPreview: true
    }

    readAttachment(attachment) {

        const contentType = attachment.contentType || "unknown"
        const isPDF = contentType.match(/pdf/)
        if (isPDF) {
            console.warn("PDF read not supported")

        } else {
            downloadAttachment(attachment)
        }
    }

    renderAttachmentItem(attachment) {
        const { readOnly, imgPreview } = this.props

        const contentType = attachment.contentType || "unknown";

        const isImageType = contentType.startsWith('image')
        const attachmentType = commonMimeTypeMap[contentType] || 'unknown'

        const handleClickAttachment = () => {
            if (readOnly) {
                if (isImageType && imgPreview) {
                    this.setState({
                        previewVisible: true,
                        previewImgSrc: attachment.url
                    })
                } else {
                    this.readAttachment(attachment)
                }
            }
        }

        const imageSize = 18;

        const inner = (
            <>
                <div>
                    {
                        isImageType ?
                            <AutoImage
                                width={imageSize}
                                height={imageSize}
                                src={getImageURL(attachment.url, 'thumbnail')} /> :
                            <img
                                src={documentIcons[attachmentType]}
                                style={{ width: imageSize, minWidth: imageSize, verticalAlign: 'middle', paddingTop: 4 }}
                            />
                    }

                </div>
                <div style={{ marginLeft: 8, display: "flex", alignItems: "center" }}>
                    <a>
                        {attachment.title}
                    </a>
                </div>
            </>
        )

        // TODO 2022-11-16 Van: 不知道这里为什么要区分 readOnly
        if (readOnly) {
            if (isImageType && imgPreview) {
                return (
                    <div
                        key={attachment.title}
                        style={{
                            cursor: 'pointer',
                            display: "flex"
                        }}
                        onClick={handleClickAttachment} >
                        {inner}
                    </div>
                )
            } else {

                const contentType = attachment.contentType || "unknown"
                const isPDF = !!contentType.match(/pdf/);

                const close = () => {
                    if(this.popoverVisibleSetter) {
                        this.popoverVisibleSetter(false)
                    }
                }
                const content = (
                    <div style={{ padding: "0.5rem" }}>
                        {isPDF ?
                            <Button style={{ marginRight: "0.5rem" }} size="xs"
                                onClick={_ => {
                                    viewPDF(attachment);
                                    close();
                                }}>预览
                            </Button> : null}
                        <Button size="sm" onClick={_ => {
                            downloadAttachment(attachment);
                            close();
                        }}>下载</Button>
                    </div>
                )

                return (
                    <Popover content={content} bindVisibleSetter={setter => this.popoverVisibleSetter = setter}>
                        <div
                            key={attachment.title}
                            style={{
                                cursor: 'pointer',
                                display: "flex"
                            }}
                            onClick={handleClickAttachment} >
                            {inner}
                        </div>
                    </Popover>
                )
            }

        } else {

            return (
                <div
                    className="attachment-view"
                    key={attachment.url}>
                    {inner}
                </div>
            )
        }

    }

    renderAttachment() {
        const { attachment } = this.props

        if (!attachment) {
            //TODO no attachment handler
            return null
        } else {
            if (typeof (attachment) !== 'object') {
                console.error("Attachment should be object or array!");
            } else {
                if (Array.isArray(attachment)) {
                    return attachment.map(attachment => {
                        return this.renderAttachmentItem(attachment)
                    })
                } else {
                    return this.renderAttachmentItem(attachment)
                }
            }
        }
    }

    render() {
        const { readOnly, onDelete } = this.props
        const { previewVisible, previewImgSrc } = this.state
        return (
            <>
                {
                    readOnly ?
                        this.renderAttachment() :
                        <Tooltip
                            content={
                                <Icon type="delete"
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={onDelete}
                                />
                            }>
                            {this.renderAttachment()}
                        </Tooltip>
                }
                <Modal
                    title="图片预览"
                    open={previewVisible}
                    onCancel={() => this.setState({ previewVisible: false })}
                    afterClose={() => this.setState({ previewImgSrc: null })}
                    bodyStyle={{ textAlign: 'center' }}
                    footer={null}
                >
                    <img
                        src={previewImgSrc}
                        style={{ width: '100%' }} />
                </Modal>
            </>
        )
    }
}
