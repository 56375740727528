import React from 'react'

export default function CommonLoading(props) {
    const { width, height } = props
    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "top",
                width: width || "100%",
                height: height || "18px",
                backgroundColor: "#F0F3F5"
            }}>
        </div>
    )
}
