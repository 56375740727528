
import entitySummaries from './entitySummaries'

export default function buildRecordSummary (
        valueName, entity, multivalued,  
        { allEntities, withLink = true } 
    ) {
    
    const summary = entitySummaries[entity.key]
    if (summary) {
        if(!multivalued) {
            return summary(valueName, entity, { allEntities, withLink })
        }
        /// multivalued == true
        return {
            __apply__: {
                list: `\${${valueName}}`,
                map:  summary(`value`, entity, { allEntities, withLink })
            }
        }
    } else {
        const builder = vn => {
            const allFields = [ ...entity.fields, ...entity.virtualFields ].sort((a, b) => {
                // 给 field 排序
                /// 根据 displayWeight 从大到下排列
                const getWeight = f => f.displayWeight || 0
                // 重的在前面
                return getWeight(b) - getWeight(a)
            })
                    
            const f = allFields.find(f => (f.type === 'ShortText' || f.type === "Text"))
            const link = `/entities/${entity.key}/records/\${${vn}.id}`
            const text = f ? `\${${vn}.${f.name}}` : `\${${vn}.id}`
            if(withLink === false) {
                return { text }
            } else {
                return {
                    link, text
                }
            }
        }

        const getValue = () => {
            if(!multivalued) {
                return builder(valueName)
            }
            /// multivalued == true
            return {
                __apply__: {
                    list: `\${${valueName}}`,
                    map: builder(`value`)
                }
            }
        }
        return {
            type: "admin::display::Plain",
            params: {
                value: getValue()
            }
        }

    }
}