

import React from 'react';
import { CompactPicker, BlockPicker, SketchPicker } from 'react-color';

import { applyCustomStyle } from '../inlineStyle'

/// some helper function from plugins

const preventBubblingUp = (event) => { event.preventDefault(); }

const STYLE_KEY = "backgroundColor"


export default function BgColorButton (props) {


  const {getEditorState, setEditorState, onOverrideContent } = props


  const prefix = STYLE_KEY + "-"
  const currentBgColor = (getEditorState().getCurrentInlineStyle().find((v) => {
    return v.startsWith(prefix)
  }) || "").substring(prefix.length)


  const toggleCustomColor = (value) => {

    const nextEditorState = applyCustomStyle(
      getEditorState(),
      STYLE_KEY,
      value
    )
    setEditorState(nextEditorState)
  }

  const onChangeComplete = (color)=> {
    toggleCustomColor(color.hex, currentBgColor)
    onOverrideContent(null)
  }

  const picker = (props) => (
    <SketchPicker color={currentBgColor}  onChangeComplete={onChangeComplete} />
  )

  const style = currentBgColor ? {
    backgroundColor: currentBgColor,
    width: "100%",
    height: "3px"
  } : null

  return (
    <div onMouseDown={preventBubblingUp}>
    <button className={"editor-button"} onClick={() => {
      onOverrideContent({
        meta : {
          outerClassName: "no-arrow"
        },
        persistent: true,
        component: picker
      })
    }} >
      <i className={"fa fa-pencil"} aria-hidden="true"></i>
      <div style={style}>
      </div>
    </button>
    </div>
  )

}
