import React from 'react'

import mentionStrategy from './mentionStrategy'
import mentionSuggestionsStrategy from './mentionSuggestionsStrategy'
import Mention from './Mention'
import MentionSuggestionsPortal from './MentionSuggestionsPortal'

import defaultRegExp from './defaultRegExp'

export default (config = {}) => {

    let editorStateListeners = []
    let keyBindingListeners = []
    let returnListeners = []

    const store = {
        getEditorState: undefined,
        setEditorState: undefined,
        editorStateListenerChange: (onEditorStateChange, listenerId) => {

            if (editorStateListeners.some(l => l.id === listenerId)) {
                editorStateListeners = editorStateListeners.filter(l => l.id !== listenerId)
            }

            const listener = {
                id: listenerId,
                callback: onEditorStateChange,
                remove: (id) => {
                    editorStateListeners = editorStateListeners.filter(l => l.id !== id)
                }
            }
            editorStateListeners.push(listener)

            return listener
        },
        registerKeyBindingListeners: (keyBindingFn, listenerId) => {
            if (keyBindingListeners.some(l => l.id === listenerId)) {
                keyBindingListeners = keyBindingListeners.filter(l => l.id !== listenerId)
            }
            const listener = {
                id: listenerId,
                callback: keyBindingFn
            }
            keyBindingListeners.push(listener)
        },
        removeKeyBindingListeners: (listenerId) => {
            keyBindingListeners = keyBindingListeners.filter(l => l.id !== listenerId)
        },
        registerReturnListeners: (handleReturn, listenerId) => {
            if (returnListeners.some(l => l.id === listenerId)) {
                returnListeners = returnListeners.filter(l => l.id !== listenerId)
            }
            const listener = {
                id: listenerId,
                callback: handleReturn
            }
            returnListeners.push(listener)
        },
        removeReturnListeners: (listenerId) => {
            returnListeners = returnListeners.filter(l => l.id !== listenerId)
        }
    }

    const {
        mentionTrigger = '@',
        mentionRegExp = defaultRegExp
    } = config

    const DecoratedMentionSuggestionPortal = (props) => {
        return (
            <MentionSuggestionsPortal
                {...props}
                store={store}
                mentionTrigger={mentionTrigger} />
        )
    }

    return {
        decorators: [
            {
                strategy: mentionStrategy(mentionTrigger),
                component: Mention,
            },
            {
                strategy: mentionSuggestionsStrategy(
                    mentionTrigger,
                    mentionRegExp
                ),
                component: DecoratedMentionSuggestionPortal,
            },
        ],

        initialize: ({ getEditorState, setEditorState }) => {
            store.getEditorState = getEditorState
            store.setEditorState = setEditorState
        },

        keyBindingFn: keyboardEvent => {
            const listener = keyBindingListeners[0]
            if (listener) {
                return listener.callback(keyboardEvent)
            }
        },

        handleReturn: keyboardEvent => {
            const listener = returnListeners[0]
            if (listener) {
                return listener.callback(keyboardEvent)
            }
            return 'not-handled'
        },

        onChange: editorState => {
            editorStateListeners.forEach(l => l.callback(editorState))
            return editorState
        }
    }
}