// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var EditingState$BwaxAdmin = require("./EditingState.bs.js");
var PageRenderer$BwaxAdmin = require("./PageRenderer.bs.js");
var DataBook = require("bwax/core/store/DataBook");
var UseDebounce = require("bwax-ui/legacy/page/hooks/useDebounce");

function EditingContainer(Props) {
  var stateKey = Props.stateKey;
  var setting = Props.setting;
  var extraState = Props.extraState;
  var setInputState = Props.setInputState;
  var basePrepareActions = Props.basePrepareActions;
  var produceWidget = Props.produceWidget;
  var routeTo = Props.routeTo;
  var theme = Props.theme;
  var allKeys = /* array */[];
  var initialValues = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var fixedValues = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var defaultValues = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var push = function (key, v, r) {
    var l = r[0];
    r[0] = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          key,
          Caml_option.some(v)
        ],
        l
      ]);
    return /* () */0;
  };
  DataBook.traverseNodeValue(setting, "root", (function (value) {
          Belt_Option.map((value == null) ? undefined : Caml_option.some(value), (function (v) {
                  var match = v.editKey;
                  if (match !== undefined) {
                    var key = match;
                    allKeys.push(key);
                    var match$1 = v.initialValue;
                    if (match$1 !== undefined) {
                      push(key, Caml_option.valFromOption(match$1), initialValues);
                    }
                    var match$2 = v.fixedValue;
                    if (match$2 !== undefined) {
                      push(key, Caml_option.valFromOption(match$2), fixedValues);
                    }
                    var match$3 = v.defaultValue;
                    if (match$3 !== undefined) {
                      return push(key, Caml_option.valFromOption(match$3), defaultValues);
                    } else {
                      return /* () */0;
                    }
                  } else {
                    return /* () */0;
                  }
                }));
          return /* () */0;
        }));
  var match = EditingState$BwaxAdmin.useEditingState(Belt_List.fromArray(allKeys), defaultValues[0], initialValues[0], /* [] */0, fixedValues[0], stateKey, false);
  var clearEditing = match[/* clearEditing */9];
  var rollback = match[/* rollback */8];
  var markSaved = match[/* markSaved */7];
  var setErrors = match[/* setErrors */6];
  var updateEditing = match[/* updateEditing */5];
  var lastSaved = match[/* lastSaved */4];
  var editing = match[/* editing */1];
  var editingState = {
    errors: EditingState$BwaxAdmin.optionAssocToDict(match[/* errors */0]),
    validated: EditingState$BwaxAdmin.optionAssocToDict(match[/* validated */2]),
    editing: EditingState$BwaxAdmin.optionAssocToDict(editing),
    lastSaved: EditingState$BwaxAdmin.optionAssocToDict(lastSaved),
    dirtyValues: EditingState$BwaxAdmin.optionAssocToDict(match[/* dirtyValues */3])
  };
  var debouncedEditing = UseDebounce.default(editing, 300);
  React.useEffect((function () {
          if (allKeys.length !== 0) {
            Curry._1(setInputState, editingState);
          }
          return ;
        }), /* tuple */[
        debouncedEditing,
        lastSaved
      ]);
  var nextPrepareActions = function (env) {
    return Curry._1(basePrepareActions, Object.assign({
                    rollback: rollback,
                    markSaved: markSaved,
                    clearEditing: clearEditing,
                    updateEditingValue: (function (key, value) {
                        return Curry._1(updateEditing, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        key,
                                        value
                                      ],
                                      /* [] */0
                                    ]));
                      })
                  }, env));
  };
  var theProduceWidget = function (def, commonWidgetProps, key) {
    return Curry._3(produceWidget, def, Object.assign(commonWidgetProps, {
                    editingState: editingState,
                    updateEditing: updateEditing,
                    setErrors: (function (es) {
                        return Curry._1(setErrors, EditingState$BwaxAdmin.dictToOptionAssoc(es));
                      })
                  }), key);
  };
  return React.createElement(PageRenderer$BwaxAdmin.make, {
              theme: theme,
              setting: setting,
              pageState: extraState,
              editingState: editingState,
              basePrepareActions: nextPrepareActions,
              routeTo: routeTo,
              produceWidget: theProduceWidget
            });
}

var make = EditingContainer;

exports.make = make;
/* react Not a pure module */
