
import React from 'react'

// import { getFieldInput } from 'Client/js/builders/input/fieldInputs';

import getInput from './getInput';

import './FormData.less';


export default function FormData(props) {

    const { form, editing, updateEditing, initialValues, fixedValues, validated, facade, usedValues } = props;

    const fields = form.字段;

    // 以后可能要可配置 layout 

    function renderFieldInput(field) {
        const { name, type, typeOptions, multivalued } = field
        const value = fixedValues[name] || editing[name];
        const onChange = v => {
            if (v !== value) {
                updateEditing({ [name]: v })
            }
        }

        const input = getInput({ type, typeOptions, multivalued, facade });

        return (
            <div className="field-item" key={field.name}>
                <div className={`item-label ${field.required ? 'item-require' : ''}`}>
                    {name}
                </div>
                <div className="item-input">
                    {/* { getInput({field, value, onChange, initialValues, fixedValues})} */}

                    {input({
                        autoFocus: false,
                        value, onChange,
                        usedValues: usedValues[name],
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className="lc-form-data">
            {
                fields.map(renderFieldInput)
            }
        </div>
    )
}
