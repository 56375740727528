// deprecated
// import React from 'react'
//
// import createBlockTypeButton from './util/createBlockTypeButton';
//
// export default createBlockTypeButton({
//   blockType: 'code-block',
//   children: (
//     <i className={"fa fa-code"} aria-hidden="true"></i>
//   ),
// });

import React from 'react'
import { toggleCodeBlockType, isCodeBlock } from '../code'
import { RichUtils } from 'draft-js'

export default ({setEditorState, getEditorState}) =>  {

  const className = isCodeBlock(getEditorState()) ? " active" : ""

  return (
    <button className={"editor-button" + className} onClick={(event) => {
      event.preventDefault()
      setEditorState(toggleCodeBlockType(getEditorState(), 'code'))
    }}>
      <i className={"fa fa-code"} aria-hidden="true"></i>
    </button>
  )
}
