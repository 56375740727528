import {
  EditorState,
  Modifier,
  genKey,
  BlockMapBuilder,
  ContentBlock,
  CharacterMetadata
} from 'draft-js'

import { List, Repeat } from 'immutable'

import { getSelectedBlocksMap } from '../../util/EditorUtil'

import { detectNewline } from '../../util/line'

export function isCodeBlock(editorState) {
  const content = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  const currentBlock = content.getBlockForKey(startKey)
  return currentBlock.getType() === 'code'
}

export function toggleCodeBlockType(editorState, blockType) {

  const selection = editorState.getSelection()
  const content = editorState.getCurrentContent()
  const startKey = selection.getStartKey()
  const endKey = selection.getEndKey()

  const startOffset = selection.getStartOffset()
  const anchorOffset = selection.getAnchorOffset()
  const anchorKeyBlockLength = content.getBlockForKey(selection.getAnchorKey()).getLength()
  const focusKeyBlockLength = content.getBlockForKey(selection.getFocusKey()).getLength()

  //reset selection
  const targetSelection = selection.merge({
    anchorOffset: startOffset !== anchorOffset ? anchorKeyBlockLength : 0,
    focusOffset: startOffset !== anchorOffset ? 0 : focusKeyBlockLength
  })

  const currentBlock = content.getBlockForKey(startKey)
  const alreadyCodeBlock = currentBlock.getType() === blockType
  const charData = CharacterMetadata.create()
  if(alreadyCodeBlock) {
    const splitBlockTexts = currentBlock.getText().split('\n')
    if(splitBlockTexts.length > 1) {
      const outputBlocks = splitBlockTexts.map(text => {
        return new ContentBlock({
          key: genKey(),
          type: 'unstyled',
          text: text,
          characterList: List(Repeat(charData, text.length))
        })
      })

      const newContent = Modifier.replaceWithFragment(
        content,
        targetSelection,
        BlockMapBuilder.createFromArray(outputBlocks)
      )

      return EditorState.push(
        editorState,
        newContent,
        'insert-fragment'
      )
    } else {
      return EditorState.push(
        editorState,
        Modifier.setBlockType(content, selection, 'unstyled'),
        'change-block-type'
      )
    }
  } else {
    if(startKey === endKey) {
      return EditorState.push(
        editorState,
        Modifier.setBlockType(content, selection, blockType),
        'change-block-type'
      )
    } else {

      //get all selected block
      const allSelectedBlock = getSelectedBlocksMap(editorState)
      const selectedBlockText = allSelectedBlock.reduce((acc, block, key) => {
        const isLastKey = allSelectedBlock.last().getKey() === key
        return acc + block.get('text') + (!isLastKey ? '\n' : '')
      }, '')

      //instant a new code block
      const charData = CharacterMetadata.create()
      const codeBlock = new ContentBlock({
        key: genKey(),
        type: blockType,
        text: selectedBlockText,
        characterList: List(Repeat(charData, selectedBlockText.length))
      })
      const emptyBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled'
      })
      const fragment = BlockMapBuilder.createFromArray([codeBlock, emptyBlock])

      //replace select range
      const newContent = Modifier.replaceWithFragment(
        content,
        targetSelection,
        fragment //blockMap
      )

      return EditorState.push(
        editorState,
        newContent,
        'insert-fragment'
      )
    }
  }
}

export function insertNewLine(editorState) {
  const content = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  const startOffset = selection.getStartOffset()
  const currentBlock = content.getBlockForKey(startKey)
  const blockText = currentBlock.getText()

  let newContent
  const newLine = getNewLine(blockText)
  if (selection.isCollapsed()) {
    // Create line to insert with right indentation
    const lines = getLines(blockText, newLine);
    const currentLineIndex = getLineAnchorForOffset(
      blockText,
      startOffset,
      newLine
    ).line

    const lineToInsert = newLine

    newContent = Modifier.insertText(
      content,
      selection,
      lineToInsert
    )
  } else {
    newContent = Modifier.replaceText(
      content,
      selection,
      newLine
    )
  }

  return EditorState.push(
    editorState,
    newContent,
    'insert-characters'
  )
}

function getLines(text, sep) {
  sep = sep || getNewLine(text);
  return List(text.split(sep));
}

function getNewLine(text) {
  return detectNewline(text) || '\n'
}

function getLineAnchorForOffset(text, offset, sep) {
  let lineIndex = 0
  let nextLineIndex = 0
  let lastLineIndex = 0

  while (nextLineIndex >= 0 && nextLineIndex < offset) {
    lineIndex++
    lastLineIndex = nextLineIndex
    nextLineIndex = text.indexOf(sep, nextLineIndex + sep.length)
  }

  return {
    line: lineIndex - 1,
    offset: offset - lastLineIndex
  }
}
