// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Message = require("antd/lib/message");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryRunner$BwaxAdmin = require("../../../../lib/bwax-js/re/binding/QueryRunner.bs.js");
var RunClientQuery = require("bwax/query/runClientQuery");
var DataLoaderContext = require("bwax-ui/store/DataLoaderContext");
var Editor = require("bwax-ui/auxiliary/richtext/editor");
var Renderer = require("bwax-ui/auxiliary/richtext/renderer");
var BasicEditor = require("bwax-ui/auxiliary/richtext/BasicEditor");
var UploadFile = require("bwax-ui/legacy/page/actions/UploadFile");

import './Input_RichTextLite.less'
;

function Input_RichTextLite(Props) {
  var value = Props.value;
  var params = Props.params;
  var onChange = Props.onChange;
  var uploadFor = params.uploadFor;
  var placeholder = params.placeholder;
  var dlc = React.useContext(DataLoaderContext.default);
  var queryEnv = QueryRunner$BwaxAdmin.toEnv(dlc);
  var partial_arg = Caml_option.some(queryEnv);
  var queryRunner = function (param, param$1, param$2) {
    return RunClientQuery.runDataQuery_re(partial_arg, param, param$1, param$2);
  };
  var match = React.useState((function () {
          return Editor.deserializeContent(value);
        }));
  var setEditorState = match[1];
  var editorState = match[0];
  React.useEffect((function () {
          if (!Editor.isContentEqual(editorState, Editor.deserializeContent(value))) {
            Curry._1(setEditorState, (function (param) {
                    return Editor.deserializeContent(value);
                  }));
          }
          return ;
        }), /* array */[value]);
  var doUpload = function (file, cb) {
    var actionEnv = {
      queryRunner: queryRunner
    };
    var params = {
      file: file,
      isPublic: true,
      uploadFor: uploadFor
    };
    var pm = Curry._1(UploadFile.default(actionEnv), params);
    return pm.then((function (v) {
                  if (v !== undefined) {
                    Curry._2(cb, undefined, Caml_option.valFromOption(v).url);
                  } else {
                    Message.error("图片上传失败", undefined, undefined);
                  }
                  return Promise.resolve(/* () */0);
                }));
  };
  var onStateChange = function (state) {
    var serialized = Editor.serializeContent(state);
    Curry._1(setEditorState, (function (param) {
            return state;
          }));
    if (!(value === undefined && Renderer.contentToPlain(serialized).length === 0)) {
      Curry._1(onChange, serialized);
    }
    return /* () */0;
  };
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.unsafe("alignSelf", "stretch"),
          /* [] */0
        ]));
  return React.createElement(BasicEditor.default, {
              editorState: editorState,
              onChange: onStateChange,
              placeholder: placeholder,
              uploadImage: doUpload,
              className: className
            });
}

var make = Input_RichTextLite;

exports.make = make;
/*  Not a pure module */
