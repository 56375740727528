
export function distanceToArea(point, area) {
    // distance from a point to and area

    const { x, y } = point;
    const { top, left, right, bottom } = area;
    // if it is inside the area, return 0;
    if ((top <= y && y <= bottom) && (left <= x && x <= right)) {
        return 0
    }

    // if the shortest path between them is perpendicular to one side of the area,
    // which means either y is bettwen top and bottom, or x is bettwen left and right, 
    // return the length of the shortest path
    if (top <= y && y <= bottom) {
        return x < left ? left - x : x - right
    } else if (left <= x && x <= right) {
        return y < top ? top - y : y - bottom
    }

    // otherwise, find the nearst corner, return the distance from the point to the corner
    function findNearestCorner() {
        if (y < top) {
            return x < left ? [left, top] : [right, top]
        } else {
            return x < left ? [left, bottom] : [right, bottom]
        }
    }
    const corner = findNearestCorner();
    return Math.sqrt((Math.pow(x - corner[0], 2) + Math.pow(y - corner[1], 2)))

}

export function isRectInsideArea(obj, area) {
    return (obj.top >= area.top && obj.bottom <= area.bottom) && (obj.left >= area.left && obj.right <= area.right)
}


export function isPointInsideArea({x, y}, area) {
    return (y >= area.top && y <= area.bottom) && (x >= area.left && x <= area.right)
}




export function distanceToCenter(point, area) {


}