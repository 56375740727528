import React, { useState, useContext } from 'react'
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js'
import { message } from 'antd'

import { ArticleEditor } from './editor'
import { contentToReact } from './renderer'
import { serializeContent, deserializeContent, findAllMention } from './editor'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';

import blocks from './testBlocks'

export default function NewEditorTest() {

    const dummyContent = {
        blocks,
        entityMap: {}
    }

    const dummyEditorState = EditorState.createWithContent(convertFromRaw(dummyContent))

    const [editorState, setEditorState] = useState(deserializeContent(""))

    const env = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a(env)


    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "EntityGeshou"
        })

        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }

    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "EntityGeshou",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }
    // console.log("content >>> ", convertToRaw(editorState.getCurrentContent()))
    return (
        <div style={{ height: "100%", margin: 20, overflow: 'auto' }}>
            <div style={{ width: "50%", float: "left" }}>
                <h4 style={{ marginBottom: 10 }}>RichText Editor</h4>
                <ArticleEditor
                    style={{
                        height: "100%",
                        minHeight: 500,
                        borderRight: "1px solid #eee",
                        background: "rgb(252, 185, 0, .2)"
                    }}
                    editorState={editorState}
                    uploadImage={doUpload}
                    uploadVideo={uploadVideo}
                    onChange={editorState => {
                        // console.log("artilceEditor onChange >>>", editorState.getDecorator() && editorState.getDecorator().decorators.toJS())
                        setEditorState(editorState)
                        findAllMention(serializeContent(editorState))
                    }} />
            </div>
            <div style={{ width: "48%", float: 'right' }}>
                <h4 style={{ marginBottom: 41 }}>Convert To React</h4>
                <div style={{ background: "#fff", borderBottom: "1px solid #eee", minHeight: 500, padding: "0.5rem" }}>
                    {contentToReact(serializeContent(editorState))}
                </div>
            </div>
        </div>
    )
}
