
import { CommonTokenStream, InputStream, error } from 'antlr4/index'
import { DefaultErrorStrategy } from 'antlr4/error/ErrorStrategy.js'
import { BwaxLexer } from "./generated/BwaxLexer"
import { BwaxParser } from "./generated/BwaxParser"

class ConsoleErrorListener extends error.ErrorListener {
    syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
        console.log("ERROR " + msg);
    }
}

export class Error {
    constructor(msg, startIndex, endIndex) {
        this.msg = msg;
        this.startIndex = startIndex;
        this.endIndex = endIndex;        
    }
}

class CollectorErrorListener extends error.ErrorListener {
    constructor(errors) {
        super()
        this.errors = errors
    }

    syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
        
        let startIndex = offendingSymbol.start - 1;
        let endIndex = offendingSymbol.stop > startIndex ? 
                            offendingSymbol.stop : startIndex + 1;

        this.errors.push(new Error(msg, startIndex, endIndex));
    }

}


export function createLexer(input) {
    const chars = new InputStream(input);
    const lexer = new BwaxLexer(chars);
    lexer.strictMode = false;
    return lexer;
}
export function getTokens(input) {
    return createLexer(input).getAllTokens()
}
export function createParser(input) {
    const lexer = createLexer(input);
    return createParserFromLexer(lexer);
}
function createParserFromLexer(lexer) {
    const tokens = new CommonTokenStream(lexer);
    let parser = new BwaxParser(tokens);
    parser.addErrorListener(new ConsoleErrorListener());

    return parser;
}

function enrichNode( node, parser, input) {
    //  let symbolName = parser.symbolicNames[node.symbol.type];
    // let ruleName = parser.ruleNames[node.ruleIndex]
    // let contextName = node.constructor.name.replace("Context", "");

    // visit and add enrich name:
    // - isLeaf
    // - symbolName     (for leaf)
    // - contextName    (for non-leaf)
    // - ruleName       (for non-leaf)
    // - startIndex
    // - endIndex
    let isLeaf = (node.children === undefined || node.children === null) && node.symbol;
    node.isLeaf = isLeaf;
    if(isLeaf) {
        let symbolName = parser.symbolicNames[node.symbol.type];
        if(symbolName) {
            node.symbolName = symbolName
        } else {
            node.symbolName = ""
        }
        node.innerText = node.symbol.text; 

        node.startIndex = node.symbol.start;
        node.endIndex = node.symbol.stop + 1;
    } else {
        node.ruleName = parser.ruleNames[node.ruleIndex]
        let contextName = node.constructor.name.replace("Context", "");
        /// un-capitalized the first letter to match the Bwax.g4:
        node.contextName = contextName.substring(0, 1).toLowerCase() + contextName.substring(1);

        node.startIndex = node.start.start;
        // 如果整个tree解析错误，是有可能出现没有 stop 的情况
        node.endIndex = node.stop ? node.stop.stop + 1 : -1;
        
        node.innerText = input.substring(node.startIndex, node.endIndex)

        node.children = node.children ?    
            node.children.map(c => enrichNode(c, parser, input)) : [];
        
    }
    return node
}


export function parseExpr(input) {
    const parser = createParser(input);
    let root = parser.expr();
    return enrichNode(root, parser, input)
}

export function parseTypeDecl(input) {
    const parser = createParser(input);
    let root = parser.typeDecl();
    return enrichNode(root, parser, input)
}


//** This is the one there we normally use */
export function parseDefs(input) {
    
    let errors = [];
    
    const lexer = createLexer(input);
    lexer.removeErrorListeners();

    const tokens = new CommonTokenStream(lexer);
    let parser = new BwaxParser(tokens);
    parser.removeErrorListeners();
    parser.addErrorListener(new CollectorErrorListener(errors));
    parser._errHandler = new DefaultErrorStrategy();

    let root = parser.defs();

    let error = errors.length > 0 ? errors[0] : undefined;

    return [enrichNode(root, parser, input), error]
}


export function validate(input)  {
    let errors = [];

    const lexer = createLexer(input);
    lexer.removeErrorListeners();
    lexer.addErrorListener(new ConsoleErrorListener());

    const parser = createParserFromLexer(lexer);
    parser.removeErrorListeners();
    parser.addErrorListener(new CollectorErrorListener(errors));
    parser._errHandler = new DefaultErrorStrategy();

    const _ = parser.exprs();

    return errors;
}