
import React from 'react'

import { CheckOutlined } from "@ant-design/icons"


export default function Boolean(props) {
    const { params } = props
    const { value } = params
    
    return value ? <div style={{ opacity: 0.7 }}><CheckOutlined /></div> : null
}