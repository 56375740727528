
import React, { useEffect, useState, useContext } from 'react';

import { deserializeContent, BasicEditor, ArticleEditor, isContentEqual, serializeContent } from 'bwax-ui/auxiliary/richtext/editor'
import { contentToPlain } from 'bwax-ui/auxiliary/richtext/renderer'

import SlateEditor from 'bwax-ui/auxiliary/richtext_slate/editor/SlateEditor';

import convertDraftToSlate from 'bwax-ui/auxiliary/richtext_slate/editor/convertDraftToSlate';
import RichText from 'bwax-ui/auxiliary/richtext_slate/RichTextRenderer'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';


import './TestEditor.less';


function getLocalItem (key) {
    if(typeof(localStorage) !== 'undefined') {
        return localStorage.getItem(key)
    } else {
        return undefined
    }
}
function setLocalItem (key, value) {
    if(typeof(localStorage) !== 'undefined') {
        return localStorage.setItem(key, value);
    }
}


export default function TestEditor(props) {

    const [ draftValue, setDraftValue ] = useState(getLocalItem("test-editor-draft-value"));

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ sessionToken, sandbox, tenantCode })

    function changeDraftValue(value) {
        setDraftValue(value)
        setLocalItem("test-editor-draft-value", value);
    }

    const [ slateValue, setSlateValue ] = useState(getLocalItem("test-editor-slate-value"));

    function changeSlateValue(value) {
        setSlateValue(value)
        // setLocalItem("test-editor-slate-value", value);
    }

    useEffect(() => {
        const slateValue = convertDraftToSlate(draftValue);
        // const valueWithMetadata = [{ type: "metadata", children: [{ text: "" }], fontSize: 16 }, ...slateValue]
        setSlateValue(slateValue);


    }, [ draftValue ]);

    const [ refreshing, setRefresing ] = useState(false);
    

    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "EntityGeshou"
        })
    
        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }
    
    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "EntityGeshou",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }

    console.log("> SLATE VALUE", slateValue);

    return (
        <div className="test-editor">
            <div className="draft-side side" 
                // style={{ height: "80%" }}
            >
                <DraftEditor value={draftValue} onChange={changeDraftValue} />
                {/* <div style={{ backgroundColor: "#f0f3f5", padding: 4, height: "20%", overflowY: "scroll", whiteSpace: 'pre' }}>
                    { contentToPlain(draftValue || '')}
                </div> */}
            </div>
            <div className="slate-side side">
                { (!slateValue) ? null : 
                    <SlateEditor value={slateValue} uploadImage={doUpload}
                        uploadVideo={uploadVideo} onChange={changeSlateValue}
                    /> 
                }
            </div>
            <div className="side" style={{
                    // overflow: auto;
                    // height: 100%;
                    // padding: 0 0.5rem;
                overflow: "auto",
                height: "100%",
                padding: "0 0.5rem",
            }}>
                <RichText value={slateValue}/>
                {/* <div style={{ backgroundColor: "#f0f3f5", padding: 4, whiteSpace: 'pre' }}>
                    { slateContentToPlain(slateValue) }
                </div> */}
            </div>
        </div>
    )
}



// 
function DraftEditor(props) {

    const { value, onChange } = props;

    const [ editorState, setEditorState ] = useState(deserializeContent(value || ''))

    const { sessionToken, sandbox } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ sessionToken, sandbox })

    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "EntityGeshou"
        })
    
        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }
    
    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "EntityGeshou",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }

    const defaultToolbar = {
        buttons: [
            // 'h1', 
            'h2', 'h3', 'blockquote', 'code-block', 'ordered-list', 'unordered-list', 'hr', 'seperator',
            'image', 'media', 
            'seperator',
            'undo', 'redo'
        ]
    }
    
    const defaultInlineToolbar = {
        buttons: [
            'bold', 'italic', 'underline', 'superscript', 'subscript', 'color', 
            'seperator',
            'align-left', 'align-center', 'align-right', 'align-justify', 
            'seperator',
            'format-clear', 'format-brush', 'hyperlink'
        ]
    }


    return (
        <ArticleEditor
            inlineToolbar={defaultInlineToolbar}
            toolbar={defaultToolbar}
            uploadImage={doUpload}
            uploadVideo={uploadVideo}
            style={{ minHeight: 200, width: "100%" }}
            onChange={editorState => {
                const serialized = serializeContent(editorState)
                setEditorState(editorState)
                if (contentToPlain(serialized).length === 0 && (value === undefined || value === null)) {
                    /// 一开始的状态，不调用 onChange
                } else {
                    onChange(serializeContent(editorState))
                }
            }}
            editorState={editorState}
        />
    )
}
