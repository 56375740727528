import React from 'react'

/// 这里的相互依赖需要改进：TODO
import EntityDataPage from 'Client/js/pages/EntityDataPage';

export default {
  menu: {
    name: '蜂蜡系统',
    icon: 'folder',
    items: [
      {
        name: '域',
        path: 'domains',  // 前面会自动加上 /ext/:extKey/
        comp: (props) => {
          return <EntityDataPage entityKey={'BwaxDomain'} {...props}/>
        }
      },
      {
        name: '域扩展',
        path: 'domainExtensions',  // 前面会自动加上 /ext/:extKey/
        comp: (props) => {
          return <EntityDataPage entityKey={'BwaxDomainExtension'} {...props}/>
        }
      },
      {
        name: '数据源',
        path: 'dataSources',  // 前面会自动加上 /ext/:extKey/
        comp: (props) => {
          return <EntityDataPage entityKey={'BwaxDataSource'} {...props}/>
        }
      }
    ],
  }
}