

import React, { useState } from 'react'

import BlockPicker from './BlockPicker';

import './BlockGallery.less'

import { 
    MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowLeft,
} from 'react-icons/md';

export default function BlockGallery({ blockTypes, setOperatingState }) {

    const [collapsed, setCollapsed] = useState({});

    const defaultCategory = "其他";

    const blockTypesByCategory = blockTypes.reduce((acc, blockType) => {
        const { category = defaultCategory } = blockType;
        const list = acc[category] || [];
        return {
            ...acc,
            [category]: [...list, blockType]
        }
    }, {});

    const categories = Object.keys(blockTypesByCategory);

    return (
        <div className="lc-block-gallery">
            {categories.map(category => {
                return (
                    <div className="gallery-section" key={category}>
                        <div className="section-title" onClick={_ => {
                            setCollapsed(prev => {
                                const c = prev[category];
                                return {
                                    ...prev,
                                    [category]: !c
                                }
                            })
                        }}>
                            <span>{category}</span>
                            <div className="op-icon">
                                {collapsed[category] ? <MdOutlineKeyboardArrowLeft /> : <MdOutlineKeyboardArrowDown />}
                            </div>
                        </div>
                        <div className={"block-list" + (collapsed[category] ? " hidden" : "")}>
                            {blockTypesByCategory[category].map(bt => {
                                return <BlockPicker blockType={bt} key={bt.name} setOperatingState={setOperatingState} />
                            })}
                        </div>
                    </div>
                )
            })}


        </div>
    )
}
