import React, { useState, useEffect } from 'react'

import invariant from 'invariant'

import './EditBox.less'

import { Icon } from '@ant-design/compatible';

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import useQueriedValueWithoutState from 'bwax-ui/legacy/page/hooks/useQueriedValueWithoutState'
import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

function getDataDef({
    entityName, recordId, field, dependedFields = [],
    allEntities, allDataTypes
}) {

    const dataEntity = allEntities.find(e => e.name == entityName);

    // 暂时只支持一层的 field
    const allFields = [ ...dataEntity.fields, ...dataEntity.virtualFields ];
    const theFields = dependedFields.map(name => {
        return allFields.find(f => f.name === name);
    })

    return {
        __query__: {
            data: {
                entityName,
                type: "findOne",
                criteria: {
                    id: recordId
                }
            },
            __for__: {
                fieldValue: (() => {
                    // 针对每一个 field， 举个 FieldDisplay 得出所需的值
                    const { valueDef } = getFieldDisplay(field);
                    return valueDef("data", field, { allEntities, allDataTypes })
                })(),
                record: theFields.reduce((acc, f) => {
                    const { valueDef } = getFieldDisplay(f);
                    return {
                        ...acc,
                        [f.name]: valueDef("data", f, { allEntities, allDataTypes })
                    }
                }, {})
            }

        }
    }
}

export default function EditBox(props) {

    const {
        params,
        ...env
    } = props

    const {
        allEntities,
        allDataTypes,
        baseData,
        queryTarget
    } = env;

    const {
        recordId,
        entityName,
        fieldName,
        inputWidget,
        dependedFields,
        prepareInputParams,
        prepareInitialValue,
        prepareFormData,
        noSave = false,
        actions
    } = params;

    const dataEntity = allEntities.find(e => e.name == entityName);
    const field = dataEntity.fields.find(f => f.name == fieldName);

    invariant(field,  "Field `" + fieldName + "` is not found");

    // the def to load data:
    const dataDef = getDataDef({
        entityName,
        recordId,
        dependedFields,
        field,
        allEntities, allDataTypes
    });

    // state begin
    const {
        value: data,
        reload,
    } = useQueriedValueWithoutState(dataDef, {
        allDataTypes,
        allEntities,
        baseData,
        roots: {
            "当前用户": {
                entityName: "用户"
            }
        },
        queryTarget,
    })
    // state end

    const editKey = "editing";
    const usedEditKeys = [editKey]

    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        rollback,
        clearEditing,
        reset
    } = EditingState.useEditingStateAsJs([editKey], {}, {}, {}, [], "no-key", false)

    useEffect(() => {
        const initialValues = data !== undefined && prepareInitialValue ? 
            { [editKey]: prepareInitialValue(data.fieldValue, data.record) } : {};

        reset({}, initialValues, {})

    }, [JSON.stringify(data !== undefined ? data : "___none__")])


    const [isMaximum, setIsMaximum] = useState(false)
    /// states ends ....

    const className = "admin--edit-box"

    let cn = [
        className,
        // isActuallyEditing ? "editing" : "",
        isMaximum ? "maximum" : "",
    ].join(" ")

    function renderInput() {
        const value = editing[editKey];
        const error = errors[editKey];
        
        if(!data || (data && value === undefined)) {
            return null
        }
        const onChange = v => {
            if (v != value) {
                updateEditing({ [editKey]: v })
            }
        }
        // 在这里自己组合 input widget
        const produceInput = () => {
            if (typeof (inputWidget) === 'function') {
                return inputWidget(
                    value,
                    onChange,
                    data && data.values[index],
                    env
                );
            }

            const { widgetFactory } = env;
            const { allWidgets } = widgetFactory;

            const inputWidgetName = (() => {
                if(typeof(inputWidget) == "string") {
                    return inputWidget
                } else {
                    return inputWidget.type_ || inputWidget.type
                }
            })();

            const W = allWidgets[inputWidgetName];
            if (!W) {
                return inputWidgetName
            }
            const params = {
                ...(inputWidget.params || {}),
                ...(prepareInputParams ? prepareInputParams(data.record, value) : {}),
                // in case the wiget depends on the initial value
                ...(prepareInitialValue ? { initialValue: prepareInitialValue(data.fieldValue, data.record)} : {})
            }
            return (
                <W params={params} {...env} value={value} onChange={onChange} />
            )
        };

        return (
            <>
                { produceInput() }
                {
                    error ?
                        <p className="error-message-cot">
                            {error}
                        </p> : null
                }
            </>
        )
    }


    const onSave = async () => {
        await actions.updateRecord({
            entityName,
            id: recordId,
            formData: prepareFormData(dirtyValues[editKey])
        });

        await actions.adminMessage({
            level: 'success',
            text: '修改成功',
        });

        reload();
    };

    return (
        <div onClick={e => { e.stopPropagation() }}
            className={cn}
            onKeyDown={e => {
                if (e.metaKey == true && e.key == "s") {
                    e.preventDefault();
                    const isDirty = Object.keys(dirtyValues).filter(
                        k => usedEditKeys.indexOf(k) !== -1
                    ).length > 0;

                    const hasError = Object.keys(errors).some(e => !!errors[e])
                    if (isDirty && !hasError && !noSave) {
                        onSave();
                    }
                }

            }}
        >
            {
                renderInput()
            }
            {
                renderToolbar({
                    noSave,
                    editable: true,
                    isEditing: true,
                    errors, validated, lastSaved, dirtyValues,
                    setIsEditing: () => {},
                    onSave,
                    usedEditKeys,
                    isMaximum,
                    setIsMaximum
                })
            }
        </div>
    )

};


function renderToolbar({
    editable, isEditing, errors, dirtyValues, setIsEditing, onSave, usedEditKeys, isMaximum, setIsMaximum, noSave
}) {
    const isDirty = Object.keys(dirtyValues).filter(
        k => usedEditKeys.indexOf(k) !== -1
    ).length > 0

    const hasError = Object.keys(errors).some(e => !!errors[e])

    const confirmEditing = async () => {
        if (isDirty && !hasError && onSave && !noSave) {
            await onSave()
            setIsEditing(false)
        }
    }

    const cancelEdit = () => {
        setIsEditing(false)
    }

    return editable ?
        <div className="edit-btn-box">
            {
                isEditing ?
                    <>
                        {
                            isMaximum ?
                                (
                                    <div className="button cancel-button" onClick={() => setIsMaximum(false)}
                                    >
                                        <Icon type="fullscreen-exit" />
                                    </div>
                                ) :
                                (
                                    <div className="button cancel-button" onClick={() => setIsMaximum(true)}
                                    >
                                        <Icon type="fullscreen" />
                                    </div>
                                )
                        }
                        <div
                            onClick={confirmEditing}
                            className={`button confirm-button${isDirty && !hasError && !noSave ? "" : " disabled"}`}
                        >
                            <Icon type="check" />
                        </div>
                        <div key="cancel" className="button cancel-button" onClick={cancelEdit}
                        >
                            <Icon type="close" />
                        </div>

                    </> :
                    <div className="button edit-button" onClick={() => setIsEditing(true)}>
                        <Icon type="edit" />
                    </div>
            }
        </div> : null
}