import React, { useState, useEffect, useContext } from 'react'

import ApplicationContext from 'bwax/util/ApplicationContext';

import { getItemEntityName } from '../getItemHandler';

import FieldInput from '../editors/FieldInput';

import { getGlobalEventBus } from '../EventBus';

import Modal from 'bwax-ui/ml/widget/impl/misc/Modal';
import { Button } from 'rsuite';

import Message from 'Client/js/ui/Message';
import ExtendingEntityNameInput from './ExtendingEntityNameInput';

// 
export default function AddItemDialog({ addItemConfig, setAddItemConfig, facade, reloadList, openItem, multitenantType, domainSettings = {} }) {

    const [isAdding, setIsAdding] = useState(false);
    // 
    

    const applicationContext = useContext(ApplicationContext) || {};
    const { currentApplication, } = applicationContext;

    const applicationSpecifiedItems = [
        "entity", "page-component", "page-fragment", "page", "extending-entity",
        "admin-page", "general-setting",
        "page-redirect",
        "event-handler",
        "scheduled-event-handler",
    ];
    const isApplicationSpecified = itemType => applicationSpecifiedItems.indexOf(itemType) !== -1;
    const applicationValue = { "应用": currentApplication.id };

    const { itemType, parentItem } = addItemConfig;

    const entityName = getItemEntityName(itemType);
    const entity = facade.entities.find(e => e.name === entityName);
    // if (!entityName || !entity) {
    //     return (
    //         <div style={{ padding: 16, cursor: "pointer" }} onClick={_ => {
    //             setAddItemConfig(undefined);
    //         }}>
    //             没有对应“{itemType}”的数据实体，点击关闭
    //         </div>
    //     );
    // }

    const {
        specifiedFieldNames = ["描述"],
        defaultValues,
        hiddenFieldValues = {},
        fixedValues: baseFixedValues = {},

        customInputs = {}

    } = getAddItemSettings(addItemConfig, { currentApplication });

    const fieldsToAdd = entity.fields.filter(f => {
        const specified = f.required || specifiedFieldNames.indexOf(f.name) !== -1;
        const notHidden = hiddenFieldValues[f.name] === undefined;
        const isTag = f.name === "标签";
        return f.initializable && notHidden && (specified || isTag);
    }).filter(f => {
        if(!domainSettings.multitenancy) {
            return f.name !== "多租户"
        } 
        return true
    });

    const fixedValues = {
        ...hiddenFieldValues, ...baseFixedValues,
        ...(isApplicationSpecified(addItemConfig.itemType) ? applicationValue : {}),
        ...(multitenantType && fieldsToAdd.find(c => c.name == "多租户") ? { "多租户": (multitenantType == "tenant"? true : false)} : {})
    }

    const [editingValue, setEditingValue] = useState({...fixedValues, ...defaultValues});


    const canBeSaved = () => {
        return fieldsToAdd.every(f => {
            return f.required ? (editingValue[f.name] !== undefined && editingValue[f.name] !== null) : true
        })
    }

    function reanderHeader() {
        return (
            <div className="dialog-head">
                <div className="title">
                    <span>新增 | {entityName}</span>
                </div>
            </div>
        )
    }

    function renderContent() {
        return (
            <div className="dialog-form">
                {fieldsToAdd.map(field => {
                    
                    const input = customInputs[field.name];
                    return (
                        <div className="form-item" key={field.name}>
                            <div className="label">{field.name}</div>
                            <div className="value">
                                {
                                    input ? 
                                        input({ 
                                            value: editingValue[field.name], 
                                            facade,
                                            onChange: v => {
                                                setEditingValue(prev => {
                                                    return ({
                                                        ...prev,
                                                        [field.name]: v
                                                    })
                                                })
                                            } 
                                        }) : (
                                        <FieldInput {...{
                                            field, entity, facade,
                                            value: editingValue,
                                            onChange: setEditingValue,
                                            fixedValues,
        
                                            // 只用于 backlink field name
                                            hostEntityName: (parentItem ? parentItem.getName() : undefined)
                                        }} />
                                    )
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    function renderFooter() {
        return (
            <div className="dialog-button-bar">
                <Button appearance="primary" loading={isAdding} disabled={!canBeSaved()} onClick={async () => {

                    setIsAdding(true);
                    const [_result, error] = await facade.add({
                        entityName, formData: editingValue
                    });
                    ///
                    getGlobalEventBus().dispatch("newItem", { itemType, itemData: editingValue });

                    if (error) {
                        Message.error(error);
                    } else {
                        // reload
                        await reloadList(itemType, parentItem);
                        // open ? 

                        Message.success("添加成功");

                        setAddItemConfig(undefined);
                        setEditingValue({});
                    }
                    setIsAdding(false);


                }}>
                    确定
                </Button>
                <Button onClick={() => {
                    setAddItemConfig(undefined);
                }}>
                    取消
                </Button>
            </div>
        )
    }

    return (
        <Modal {...{
            visible: true,
            containerStyle: {
                borderRadius: 4,
                width: 500,
                minHeight: 300,
            },
            header: reanderHeader(),
            footer: renderFooter(),
            modalContent: renderContent()
        }} />
    )


}



const getAddItemSettings = ({ itemType, parentItem, fixedValues }, { currentApplication } = {}) => {
    const addItemSettings = {
        "entity": _ => {
            return {
                hiddenFieldValues: {
                    "是否内置实体的扩展": false,
                    // "应用": (currentApplication ? currentApplication.id: undefined)
                },
            };
        },
        "extending-entity": _ => {
            return {
                hiddenFieldValues: {
                    "是否内置实体的扩展": true,
                    // "应用": (currentApplication ? currentApplication.id: undefined)
                },
                customInputs: {
                    "名称": ({ value, onChange, facade }) => {                        
                        return (
                            <ExtendingEntityNameInput {...{
                                value, 
                                onChange,        
                                facade,
                                currentApplication
                            }} />
                        )
                    }
                }
            };
        },

        "field": parentItem => {
            const specifiedFieldNames = ["名称", "描述", "字段类型", "字段选项", "必填", "多值", "默认值", "数据实体", "可初始化", "可更改"];
            if (parentItem) {
                return {
                    fixedValues: { "数据实体": parentItem.data.id }, specifiedFieldNames
                }
            } else {
                return { specifiedFieldNames }
            }
        },
        "virtual-field": parentItem => {
            const specifiedFieldNames = ["名称", "描述", "字段类型", "字段选项", "数据实体"];
            if (parentItem) {
                return { fixedValues: { "数据实体": parentItem.data.id }, specifiedFieldNames }
            } else {
                return { specifiedFieldNames }
            }
        },
        "data-interface": parentItem => {
            if (parentItem) {
                return { fixedValues: { "关联实体": parentItem.data.id } }
            } else {
                return {}
            }
        },
        "backlink-field": parentItem => {
            if (parentItem) {
                return { fixedValues: { "数据实体": parentItem.data.id } }
            } else {
                return {}
            }
        },
        "event-handler": _ => ({ specifiedFieldNames: ["名称", "实体名", "事件类型", "监听字段"] }),
    }

    const applyFixedValues = settings => ({
        ...settings,
        fixedValues: fixedValues ? {
            ...(settings.fixedValues || {}),
            ...fixedValues
        } : settings.fixedValues
    })

    const settings = applyFixedValues((addItemSettings[itemType] || (_ => ({})))(parentItem));


    // add default values for tag:
    if (parentItem && parentItem.itemType === itemType + "-tag-folder") {
        return {
            ...settings,
            defaultValues: {
                ...(settings.defaultValues || {}),
                "标签": [parentItem.getName()]
            }
        }

    } else {
        return settings;
    }

}
