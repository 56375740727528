

export default function groupByTags(objs) {

    return objs.reduce(([groups, untagged], e) => {
        const tags = e["标签"];
        if(tags && tags.length > 0) {
            const firstTag = tags[0];
            const group = groups[firstTag];
            if(group) {
                // 直接改
                groups[firstTag] = [...group, e];                
            } else {
                groups[firstTag] = [e];
            }
            return [ groups, untagged ];

        } else {
            return [ groups, [ ...untagged, e ]]
        }
    }, [{}, []]);
}


export function buildTagFolderNodes(taggedGroups, untagged, folderItemType, itemNode, buildLeafNode ) {

    const tagFolderNodes = Object.keys(taggedGroups).map(tag => {
        return itemNode({ name: tag, itemType: folderItemType }, taggedGroups[tag].map(buildLeafNode));
    });
    
    const untaggedNodes = untagged.map(buildLeafNode);

    return [ ...tagFolderNodes, ...untaggedNodes ];

}