
import React, { useEffect, useRef, useState } from 'react'

import Link from 'bwax-ui/page/Link'

import classNames from 'classnames';
import WindowScrollHelper from '../impl/misc/WindowScrollHelper';

import getImageURL from 'bwax-ui/getImageURL'

import Button, { Pressable } from 'bwax-ui/components/Button'

import { useTrack } from 'bwax-ui/track';

import queryString from 'query-string'

// import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';
import DropdownMenu from 'bwax-ui/components/DropdownMenu';

export default function Layout001(props) {

    const { data, events, slots, viewEnv } = props;

    const track = useTrack();

    const {
        logoPC, logoMobile,

        logoPCForDark,

        homeUrl = "/home",

        tryButtonLabel = "立即体验",
        tryButtonOpenNewWindow = true,

        tryUrl = "/signup",
        siteName,

        mainColor = "violet",

        navItems = [
            {
                label: "SimplifyAI 可以做什么",
                url: "/home"
            },
            {
                label: "如何使用",
                url: "/learn"
            },
        ]
    } = data;


    const { mainContent, footer } = slots;
    const { webEnv, routeTo } = viewEnv;

    // console.log("NMav Items", navItems);

    // 主要是一个 Header
    const [scrolledUp, setScrolledUp] = useState(false);
    const scrolledUpRef = useRef();
    scrolledUpRef.current = scrolledUp;

    const scrollHelperRef = useRef();

    useEffect(() => {

        scrollHelperRef.current = new WindowScrollHelper({
            onScrolled: status => {
                const THRESHOLD = 24;
                const scrolledUp = scrolledUpRef.current;
                if (status.y < THRESHOLD && scrolledUp) {
                    setScrolledUp(false)
                } else if (status.y >= THRESHOLD && !scrolledUp) {
                    setScrolledUp(true)
                }
            }
        })
        return _ => {
            scrollHelperRef.current.cleanUp();
        }

    }, []);


    // 如果有 ur 则存在 loadStorage
    useEffect(() => {
        if(typeof(window) !== "undefined") {
            // TODO 页面级别的 params 应该预先存在 webEnv 里面

            const search = queryString.parse(window.location.search);
            if(search.ur) {
                localStorage.setItem("user-ref", search.ur);
            }
            if(search.cr) {
                localStorage.setItem("channel-ref", search.cr);
            }
        }

    }, []);


    function isActive(url) {
        return webEnv.currentURLPath == url;
    }


    function gotoTry() {
        // routeTo(tryUrl);
        track("home_try")

        if (tryButtonOpenNewWindow) {
            const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
            window.open(url);
        } else {
            routeTo(tryUrl)
        }
    }


    function renderWide() {
        return (
            <div className="h-full hidden md:flex items-center px-6 gap-6 justify-between w-full max-w-6xl">
                <Link to={homeUrl || "/"} className="flex gap-1 items-center">
                    <img className={classNames("transition-all duration-450 light-only", {
                        "h-10": !scrolledUp && !siteName,
                        "h-8": scrolledUp && !siteName,
                        "h-[34px]": !scrolledUp && siteName,
                        "h-[29px]": scrolledUp && siteName,

                    })} src={getImageURL(logoPC, "medium")} />
                    <img className={classNames("transition-all duration-450 dark-only", {
                        "h-10": !scrolledUp && !siteName,
                        "h-8": scrolledUp && !siteName,
                        "h-[34px]": !scrolledUp && siteName,
                        "h-[29px]": scrolledUp && siteName,

                    })} src={getImageURL(logoPCForDark, "medium")} />
                    {siteName ? (
                        <div className={classNames(
                            "font-medium break-keep", {
                            "font-size-18": !scrolledUp,
                            "font-size-16": scrolledUp
                        }
                        )}>{siteName}</div>
                    ) : null}
                </Link>
                <div className="flex items-center gap-4">
                    <div className={classNames("flex transition-all duration-300", scrolledUp ? "font-size-14" : "font-size-15")}>
                        {navItems.map(item => {
                            const { label, url, subItems, openWithNewTab } = item;
                            if (url) {
                                return (
                                    <Link to={url} key={label} className="trk-topnav trk-topnav-wide" openWithNewTab={openWithNewTab}>
                                        <div className={classNames(
                                            "px-4 py-2 rounded",
                                            {
                                                "hover:bg-[var(--mauve3)] cursor-pointer": true,
                                                "font-medium": isActive(url),
                                            }
                                        )}>
                                            {item.label}
                                        </div>
                                    </Link>
                                )
                            } else if (subItems) {
                                const items = subItems.map(({ label, url, openWithNewTab }) => {
                                    return {
                                        label, onSelect: _ => {
                                            if (openWithNewTab) {
                                                window.open(url);
                                            } else {
                                                routeTo(tryUrl)
                                            }
                                        }
                                    }
                                });
                                return (
                                    <DropdownMenu key={label} items={items} className={"!px-3 !py-2.5 font-size-14"}>
                                        <div className={classNames(
                                            "pl-4 pr-3 py-2 rounded flex gap-1.5 items-center",
                                            {
                                                "hover:bg-[var(--mauve3)] cursor-pointer": true,
                                                "font-medium": isActive(url),
                                            }
                                        )}>
                                            {item.label}
                                            <i className='bx bx-chevron-down'></i>
                                        </div>
                                    </DropdownMenu>
                                )

                            } else {
                                return null
                            }
                        })}
                    </div>
                    <div>
                        <Button
                            id="btn-try-at-wide-topnav"
                            size={scrolledUp ? "small" : "normal"}
                            onPress={gotoTry}
                            className={classNames(
                                "transition-all duration-300",
                                "trk-btn-try",
                                {
                                    "!px-3": scrolledUp
                                }
                            )}
                            appearance="primary" color={mainColor}>{tryButtonLabel}</Button>
                    </div>
                </div>
            </div>
        )
    }

    const [narrowMenuShown, setNarrowMenuShown] = useState(false);

    const defaultOverflowRef = useRef();
    useEffect(() => {
        if (typeof (document) !== "undefined") {
            if (narrowMenuShown) {
                defaultOverflowRef.current = document.body.style["overflow"];
                document.body.style["overflow"] = "hidden";
            } else {
                document.body.style["overflow"] = defaultOverflowRef.current;
            }
        }

    }, [narrowMenuShown]);

    function renderNarrow() {
        return (
            <>
                <div className={classNames(
                    "absolute top-0 w-full transition-all h-screen opacity-30 bg-[var(--mauve11)]",
                    {
                        "hidden": !narrowMenuShown,
                    }
                )} onClick={_ => setNarrowMenuShown(false)} />
                <div className={classNames("h-full flex md:hidden items-center w-full relative", {
                    "bg-[var(--lc-color-bg-1)]": narrowMenuShown
                })}>
                    <div className={classNames("flex items-center pl-3 pr-4 gap-6 justify-between w-full")}>
                        <Link to={homeUrl || "/"} className="flex gap-1 items-center">
                            <img className={classNames("transition-all duration-300", {
                                "h-9": !scrolledUp || narrowMenuShown,
                                "h-8": scrolledUp && !narrowMenuShown
                            })} src={getImageURL(logoMobile || logoPC, "medium")} />

                            {siteName ? (
                                <div className={classNames(
                                    "font-medium break-keep", {
                                    "font-size-18": !scrolledUp,
                                    "font-size-16": scrolledUp
                                }
                                )}>{siteName}</div>
                            ) : null}
                        </Link>
                        <div className="flex items-center gap-6">
                            {navItems && navItems.length > 0 ? (
                                <div className="flex flex-col justify-between h-5 w-6 py-0.5 overflow-hidden" onClick={_ => {
                                    setNarrowMenuShown(prev => !prev);
                                }}>
                                    {[1, 2, 3].map(i => {
                                        return (
                                            <div key={i} className={classNames(
                                                "h-0.5 bg-[var(--mauve11)] transition-all w-[200%]",
                                                narrowMenuShown ? {
                                                    "-translate-x-[2px] -rotate-45": i == 1,
                                                    "opacity-0": i == 2,
                                                    "-translate-x-[2px] rotate-45": i == 3,
                                                } : null
                                            )} />
                                        )
                                    })}
                                </div>
                            ) : null}
                            <Button
                                id="btn-try-at-narrow-topnav"
                                size={scrolledUp ? "small" : "normal"}
                                onPress={gotoTry}
                                className={classNames(
                                    "transition-all duration-300",
                                    "trk-btn-try",
                                    {
                                        "!px-3": scrolledUp && !narrowMenuShown
                                    }
                                )}
                                appearance="primary" color={mainColor}>{tryButtonLabel}</Button>
                        </div>
                    </div>
                    <div className={classNames(
                        "absolute top-[100%] w-full transition-all origin-top-left px-4 pt-6 pb-12",
                        "flex flex-col bg-[var(--lc-color-bg-1)] shadow-md gap-4",
                        {
                            "scale-y-0": !narrowMenuShown,
                            "scale-y-100": narrowMenuShown
                        }
                    )}>
                        {navItems.map(item => {
                            const { label, url, subItems } = item;
                            if (url) {
                                return (
                                    <Link to={url} key={label} className="trk-topnav trk-topnav-narrow">
                                        <div className={classNames(
                                            "px-4 py-2 rounded",
                                            {
                                                "hover:bg-[var(--mauve3)] cursor-pointer": true,
                                                "font-medium": isActive(url),
                                            }
                                        )} onClick={e => {
                                            setNarrowMenuShown(false);
                                        }}>
                                            {label}
                                        </div>
                                    </Link>
                                )
                            } else if (subItems) {
                                return (
                                    <SubMenu key={label} label={label} subItems={subItems} closeMenu={_ => setNarrowMenuShown(false)} isActive={isActive} />
                                )
                            }
                            return null;
                        })}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="w-full flex flex-col font-size-14 min-h-full">
            {mainContent || null}
            {footer || null}
            <div className={classNames(
                "w-full fixed border-b-[1px] transition-all flex justify-center",
                "hover:bg-[var(--lc-color-bg-1)] hover:border-b-[(var(--gray3)",
                {
                    "bg-transparent h-20 sm:h-24 !border-b-[transparent]": !scrolledUp || narrowMenuShown,
                    "bg-[var(--lc-color-bg-1)] h-14 border-b-[(var(--gray3)]": scrolledUp && !narrowMenuShown,
                }
            )}>
                {renderWide()}
                {renderNarrow()}
            </div>
        </div>
    )
}


function SubMenu({ subItems, label, closeMenu, isActive }) {

    const [open, setOpen] = useState(true);

    return (
        <div className="w-full h-full flex flex-col">
            <div key={label} className={classNames(
                "px-4 py-2 rounded justify-between flex items-center",
                {
                    // "hover:bg-[var(--mauve3)] cursor-pointer": true,
                    // "font-medium": isActive(url),
                }
            )} onClick={e => {
                // setNarrowMenuShown(false);
                setOpen(prev => !prev);
            }}>
                {label}
                {open ? (
                    <i className='bx bx-chevron-up'></i>
                ) : (
                    <i className='bx bx-chevron-down'></i>
                )}
            </div>
            {open ? (
                <div className="w-full pt-4 px-4">
                    {subItems.map(({ label, url, openWithNewTab }) => {
                        return (
                            <Link to={url} key={label} openWithNewTab={openWithNewTab} className={classNames(
                                "px-4 py-2 rounded justify-between flex items-center",
                                {
                                    "hover:bg-[var(--mauve3)] cursor-pointer": true,
                                    "font-medium": isActive(url),
                                }
                            )} onClick={e => {
                                closeMenu();
                            }}>
                                {label}
                            </Link>
                        )

                    })}
                </div>
            ) : null}
        </div>
    )

} 