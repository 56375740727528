
import React, { useState, useRef, useEffect } from 'react';
import { getBezierPath, getMarkerEnd } from 'react-flow-renderer';

export default function ExprEdge(props) {


    const { id,
        // sourceX,
        // sourceY,
        targetX,
        targetY,
        // sourcePosition,
        // targetPosition,
        // style = {},
        data,
        arrowHeadType,
        markerEndId,
        // source, 
        // selected
    } = props;


    // console.log(">>> ", data);
    const { sourceNodeSetting, sourceNode } = data;

    const typeCategory = sourceNode.value_type_category;

    
    function getSourceSetting() {

        const { position, size } = sourceNodeSetting;
        if (!position || !size) {
            return {}
        }
        const radius = size.height / 2;

        const leftEnd = { x: position.x + radius, y: position.y + radius };
        const rightEnd = { x: position.x + size.width - radius, y: position.y + radius };

        return {
            radius, leftEnd, rightEnd,
        }
    }

    const { radius, leftEnd, rightEnd } = getSourceSetting();

    function getSourcePoint () {
        if(!radius) {
            return null
        }
        if(targetX <= leftEnd.x) {
            return leftEnd
        } else if(targetX < rightEnd.x) {
            return { x: targetX, y: leftEnd.y }
        } else {
            return rightEnd
        }
    }


    // 测试：
    function renderSourceDot() {
        const point = getSourcePoint();
        if(!point) {
            return null;
        }

        return <circle cx={point.x} cy={point.y} r="2" fill="#FF4760" style={{
            zIndex: 100,
            opacity: 0.6
        }} />;

    }

    const sourcePoint = getSourcePoint();

    function getEdgePath () {
        if(sourcePoint) {
            const dx = targetX - sourcePoint.x
            const dy = targetY - sourcePoint.y
            const edgeStart = `M${sourcePoint.x} ${sourcePoint.y}`
            const min_vEdgeHeight = 16 // target 位于 source 的水平或者上方时，target 上面固定的竖线长度
            const min_hEdgeWidth = 32 // target 位于 source 的水平或者上方时，与 source 相连的横线的长度
            const commonArcRadius = min_hEdgeWidth / 2
            const leftSecondVEdgeX = sourcePoint.x - min_hEdgeWidth - commonArcRadius // 左边 secondVEdge 的 x 坐标
            const rightSecondVEdgeX = sourcePoint.x + min_hEdgeWidth + commonArcRadius
            const arcEndY = targetY - min_vEdgeHeight // 最后一个弧线结束的 y 坐标
            const edgeTopY = arcEndY - commonArcRadius // 整个 edge 最高点的 y 坐标
            const gap = 8 // target 距离 secondVEdge 8px 时，edge 变换，避免出现重合
            const arcRadius = Math.min(Math.abs(dx), Math.abs(dy)) / 2; // target 位于下方时弧线的半径

            let startHEdge = "" // 初始的横线
            let finalVEdge = `L ${targetX} ${targetY}` // 最终的竖线
            let firstArcEdge = ""
            let secondVEdge = "" // target 位于 source 的水平或者上方时，第一次拐弯后的竖线
            let secondArcEdge = ""
            let secondHEdge = "" // target 位于 source 的水平或者上方时，第二次拐弯后的横线
            let thirdArcEdge = ""

            function renderTopEdge (isLeft, secondVEdgeX, showSecondHEdge = true, secondHEdgeEndX, thirdArcEdgeSide) {
                startHEdge = `L ${sourcePoint.x + (isLeft ? -min_hEdgeWidth : min_hEdgeWidth)} ${sourcePoint.y}`
                firstArcEdge = `A ${commonArcRadius} ${commonArcRadius} 0 0 ${isLeft ? "1" : "0"} ${secondVEdgeX} ${sourcePoint.y - commonArcRadius}`
                secondVEdge = `L ${secondVEdgeX} ${arcEndY}`
                secondArcEdge = `A ${commonArcRadius} ${commonArcRadius} 0 0 ${isLeft ? "1" : "0"} ${sourcePoint.x + (isLeft ? -min_hEdgeWidth : min_hEdgeWidth)} ${edgeTopY}`
                secondHEdge = showSecondHEdge ? `L ${secondHEdgeEndX ? secondHEdgeEndX : targetX + (isLeft ? -commonArcRadius : commonArcRadius)} ${edgeTopY}` : ""
                thirdArcEdge = `A ${commonArcRadius} ${commonArcRadius} 0 0 ${thirdArcEdgeSide ? thirdArcEdgeSide : (isLeft ? "1" : "0")} ${targetX} ${arcEndY}`
            }

            function getAboveEdge () {
                const half = (rightEnd.x - leftEnd.x) / 2

                function handleTargetXNearSecondVEdgeX (isLeft, secondVEdgeX) {
                    // 处理 targetX 靠近 secondVEdgeX 的情况
                    const startHEdgeX = isLeft ? (sourcePoint.x - min_hEdgeWidth + targetX - leftSecondVEdgeX - gap) :
                        (sourcePoint.x + min_hEdgeWidth + targetX - rightSecondVEdgeX + gap)
                        
                    startHEdge = `L ${startHEdgeX} ${sourcePoint.y}`
                    firstArcEdge = `A ${commonArcRadius} ${commonArcRadius} 0 0 ${isLeft ? "1" : "0"} ${targetX + (isLeft ? -gap : gap)} ${sourcePoint.y - commonArcRadius}`
                    secondVEdge = `L ${secondVEdgeX} ${arcEndY}`
                    secondArcEdge = `Q ${secondVEdgeX} ${edgeTopY}, ${secondVEdgeX + (isLeft ? (targetX - secondVEdgeX) / 2 : -(secondVEdgeX - targetX) / 2)} ${edgeTopY}`
                    secondHEdge = ""
                    thirdArcEdge = `Q ${targetX} ${edgeTopY}, ${targetX} ${arcEndY}`
                }

                function handleTargetMoving(isLeft, originalSecondVEdgeX, dynamicSecondVEdgeX, critical1, critical2) {
                    if(critical1) {
                        handleTargetXNearSecondVEdgeX(isLeft, dynamicSecondVEdgeX)
                   
                    } else if(critical2) {
                        renderTopEdge(isLeft, originalSecondVEdgeX, false)
                        const secondArcEdgeX = originalSecondVEdgeX + (isLeft ? (targetX - originalSecondVEdgeX) / 2 : -(originalSecondVEdgeX - targetX) / 2)
                        secondArcEdge = `Q ${originalSecondVEdgeX} ${edgeTopY}, ${secondArcEdgeX} ${edgeTopY}`
                        thirdArcEdge = `Q ${targetX} ${edgeTopY}, ${targetX} ${arcEndY}`

                    } else {
                        renderTopEdge(isLeft, originalSecondVEdgeX)
                    }
                }

                if(dx === 0) {
                    if (sourcePoint.x < leftEnd.x + half) {
                        renderTopEdge(true, leftSecondVEdgeX)
                    } else {
                        renderTopEdge(false, rightSecondVEdgeX)
                    }
                } else if (dx > 0) {
                    const dynamicSecondVEdgeX = targetX + gap > rightSecondVEdgeX ? targetX + gap : rightSecondVEdgeX
                    const critical1 = targetX > rightSecondVEdgeX && targetX < rightSecondVEdgeX + gap || 
                        targetX < rightSecondVEdgeX && targetX > rightSecondVEdgeX - gap
                    const critical2 = targetX < rightSecondVEdgeX - gap && targetX > sourcePoint.x + min_hEdgeWidth - commonArcRadius

                    handleTargetMoving(false, rightSecondVEdgeX, dynamicSecondVEdgeX, critical1, critical2)
                } else {
                    const dynamicSecondVEdgeX = targetX - gap < leftSecondVEdgeX ? targetX - gap : leftSecondVEdgeX
                    const critical1 = targetX < leftSecondVEdgeX && targetX > leftSecondVEdgeX - gap || 
                        targetX > leftSecondVEdgeX && targetX < leftSecondVEdgeX + gap
                    const critical2 = targetX > leftSecondVEdgeX + gap && targetX < sourcePoint.x - min_hEdgeWidth + commonArcRadius

                    handleTargetMoving(true, leftSecondVEdgeX, dynamicSecondVEdgeX, critical1, critical2)
                }

                return [ edgeStart, startHEdge, firstArcEdge, secondVEdge, secondArcEdge, secondHEdge, thirdArcEdge, finalVEdge ].join(" ")
            }

            function renderNearByEdge (isLeft, firstArcX, firstArcY, edgeTopX) {
                // when target is near source
                startHEdge = `L ${sourcePoint.x + (isLeft ? -radius : radius)} ${sourcePoint.y}`
                firstArcEdge = `Q ${firstArcX} ${sourcePoint.y}, ${firstArcX} ${firstArcY}`
                secondArcEdge = `Q ${firstArcX} ${edgeTopY}, ${edgeTopX} ${edgeTopY}`
                thirdArcEdge = `Q ${targetX} ${edgeTopY}, ${targetX} ${arcEndY}`
            }

            function getEdgeWithArcs (isTargetXFarAway, isLeft, secondArcSweepFlag, secondVEdgeX) {
                if(dy >= min_vEdgeHeight + arcRadius) {
                    firstArcEdge = `A ${arcRadius} ${arcRadius} 0 0 ${isLeft ? "0" : "1"} ${targetX} ${sourcePoint.y + arcRadius}`
                    
                    return [ edgeStart, startHEdge, firstArcEdge, finalVEdge ].join(" ")
                } else {
                    renderTopEdge (isLeft, secondVEdgeX, true, targetX + (isLeft ? commonArcRadius : -commonArcRadius), isLeft ? "0" : "1")
                    
                    if(dy >= min_vEdgeHeight - commonArcRadius) {
                        if(isTargetXFarAway) {
                            firstArcEdge = `C ${secondVEdgeX} ${sourcePoint.y}, ${secondVEdgeX} ${edgeTopY}, ${sourcePoint.x + (isLeft ? -min_hEdgeWidth * 2 : min_hEdgeWidth * 2)} ${edgeTopY}`
                        
                            return [ edgeStart, startHEdge, firstArcEdge, secondHEdge, thirdArcEdge, finalVEdge ].join(" ")
                        } else {
                            const dynamicRadius = isLeft ? (leftEnd.x - radius - targetX) / 3 : (targetX - rightEnd.x - radius) / 3
                            const edgeTopX = sourcePoint.x + (isLeft ? -(radius + dynamicRadius * 2) : (radius + dynamicRadius * 2)) // 整个 edge 最高点的 x 坐标
                            const firstArcY = sourcePoint.y - (sourcePoint.y - targetY + min_vEdgeHeight + commonArcRadius) / 2 // 第一个弧到达的 y 坐标
                            const firstArcX = sourcePoint.x + (isLeft ? -(radius + dynamicRadius): (radius + dynamicRadius)) // 第一个弧到达的 x 坐标
    
                            renderNearByEdge(isLeft, firstArcX, firstArcY, edgeTopX)
    
                            return [ edgeStart, startHEdge, firstArcEdge, secondArcEdge, thirdArcEdge, finalVEdge ].join(" ")
                        }
                    } else {
                        firstArcEdge = `A ${commonArcRadius} ${commonArcRadius} 0 0 ${isLeft ? "1" : "0"} ${secondVEdgeX} ${sourcePoint.y - commonArcRadius}`
                        secondVEdge = `L ${secondVEdgeX} ${arcEndY}`
    
                        if (!isTargetXFarAway) {
                            if(isLeft ? targetX < secondVEdgeX - gap : targetX > secondVEdgeX + gap) {
    
                                secondArcEdge = `Q ${secondVEdgeX} ${edgeTopY}, ${secondVEdgeX + (targetX - secondVEdgeX) / 2} ${edgeTopY}`
                                thirdArcEdge = `Q ${targetX} ${edgeTopY}, ${targetX} ${arcEndY}`
    
                                return [ edgeStart, startHEdge, firstArcEdge, secondVEdge, secondArcEdge, thirdArcEdge, finalVEdge ].join(" ")
                            } else {
                                return getAboveEdge()
                            }
                        } else {
                            secondArcEdge = `A ${commonArcRadius} ${commonArcRadius} 0 0 ${secondArcSweepFlag} ${sourcePoint.x + (isLeft ? -2 * min_hEdgeWidth : 2 * min_hEdgeWidth)} ${edgeTopY}`
                        
                            return [ edgeStart, startHEdge, firstArcEdge, secondVEdge, secondArcEdge, secondHEdge, thirdArcEdge, finalVEdge ].join(" ")
                        }
                    }
                }
                
            }

            if(dx > 0) {
                if(dx > radius) {
                    startHEdge = `L ${targetX - arcRadius} ${sourcePoint.y}`
                }

                const isTargetXFarAway = targetX > sourcePoint.x + min_hEdgeWidth + commonArcRadius * 3

                return getEdgeWithArcs(isTargetXFarAway, false, 1, rightSecondVEdgeX)

            } else if (dx < 0) {
                if(dx < -radius) {
                    startHEdge = `L ${targetX + arcRadius} ${sourcePoint.y}`
                }

                const isTargetXFarAway = targetX < sourcePoint.x - min_hEdgeWidth - commonArcRadius * 3

                return getEdgeWithArcs(isTargetXFarAway, true, 0, leftSecondVEdgeX)

            } else {
                if (dy >= min_vEdgeHeight + arcRadius) {
                    return [ edgeStart, finalVEdge ].join(" ")
                } else {
                    return getAboveEdge()
                }
                
            }

        } else {
            return ``
        }
    }

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    // 根据类型补充 className

    const className = "react-flow__edge-path" + (typeCategory ? " " + typeCategory.toLowerCase() : ""); 

    return (
        <>
            {/* { renderSourcePath() }             */}
            <path id={id} style={{}} className={className} d={getEdgePath()} markerEnd={markerEnd} />
            {/* { renderSourceDot() } */}
            {/* <text>
                <textPath href={`#${id}`} style={{ fontSize: '12px' }} startOffset="50%" textAnchor="middle">
                    {data.text}
                </textPath>
            </text> */}
        </>
    );
}





// function getSourcePathSetting () {
//     if(!sourceNode) {
//         return {}
//     }
//     const { position, size } = sourceNode;
//     if(!position || !size) {
//         return {}
//     }
//     const radius = size.height / 2;

//     const leftEnd = { x: position.x, y: position.y + size.height / 2 };
//     const leftArcEnd = { x: leftEnd.x + radius, y: leftEnd.y + radius };
//     const rightEnd = { x: position.x + size.width, y: position.y + size.height / 2 };
//     const rightArcEnd = { x: rightEnd.x - radius, y: rightEnd.y + radius };

//     return {
//         radius, leftEnd, leftArcEnd, rightEnd, rightArcEnd
//     }

// }


// function renderSourcePath () {
//     const { radius, leftEnd, leftArcEnd, rightEnd, rightArcEnd } = getSourcePathSetting();
//     if(!radius) {
//         return null
//     }
//     const startingPoint = "M " + leftEnd.x + " " + leftEnd.y;

//     const firstArc = "A " + radius + " " + radius + " 0 0 0 " + leftArcEnd.x + " " + leftArcEnd.y;
//     const moveToRight = "L " + rightArcEnd.x + " " + rightArcEnd.y;
//     const secondArc = "A " + radius + " " + radius + " 0 0 0 " + rightEnd.x + " " + rightEnd.y;

//     const d = [ startingPoint, firstArc, moveToRight, secondArc ].join(" ");

//     return <path id={id + "_source"} className="react-flow__edge-path" d={d} ref={soucePathRef} />

// }

// function getPointAtSourcePath(x) {
//     const { radius, leftEnd, leftArcEnd, rightEnd, rightArcEnd } = getSourcePathSetting();
//     console.log(getSourcePathSetting());
//     if(!radius) {
//         return null
//     }
//     if(x <= leftEnd.x) {
//         return leftEnd
//     } else if(x < leftArcEnd.x) {
//         const deltaX = x - leftEnd.x;
//         const trangleX = radius - deltaX;
//         const trangleY = Math.sqrt(Math.pow(radius, 2) - Math.pow(trangleX, 2));
//         const y = leftEnd.y + trangleY;
//         console.log(">>>>", x, deltaX, trangleX, trangleY, y, leftEnd.y);
//         return { x, y }
//     } else if(x <= rightArcEnd.x) {
//         return { x, y: leftArcEnd.y }
//     } else if(x < rightEnd.x) {
//         const deltaX = rightEnd.x - x;
//         const trangleX = radius - deltaX;
//         const trangleY = Math.sqrt(Math.pow(radius, 2) - Math.pow(trangleX, 2));
//         const y = rightEnd.y + trangleY;
//         return { x, y }
//     } else {
//         return rightEnd
//     }
// }

// // 用于测试 getPointAtSourcePath
// function renderSourceDot() {

//     const point = getPointAtSourcePath(targetX);

//     if(!point) {
//         return null;
//     }

//     return <circle cx={point.x} cy={point.y} r="2" fill="#FFA947" />;

// }