

import React, { useEffect, useRef } from 'react';
import getImageURL from 'bwax-ui/getImageURL';

import AutoPlayVideo from "bwax-ui/components/AutoPlayVideo";
import Button from "bwax-ui/components/Button";
import Link from 'bwax-ui/page/Link'


import classNames from 'classnames';

import { useTrack } from 'bwax-ui/track';

/**
 * 1. Banner： slogon + illustration
 * 2. How it works: 使用视频  （这个可以不要
 * 3. 功能列表：1. 私人知识助手， 2. 辅助编辑，3. AI 辅助功能
 */

export default function HomePage_inbot({ data, slots, viewEnv }) {

    const track = useTrack();
    
    const {
        illustrationPC, illustrationMobile, features, videoDemo,
        tryButtonLabel = "立即体验", tryUrl = "/signup",
    } = data;

    const { routeTo, webEnv } = viewEnv;

    const { articleGrid } = slots;

    const colorText = (t, color = "violet") => {
        return <span className={`text-[var(--${color}11)]`}>{t}</span>
    }

    const renderVideoDemo = (video, caption) => {
        if (video) {
            return (
                <div className="flex justify-center items-center bg-[var(--mauve2)] w-full h-full shadow-md rounded overflow-hidden">
                    <AutoPlayVideo src={video.url} alt={caption} />
                </div>
            )
        }
        return (
            <div className="flex justify-center items-center bg-[var(--mauve2)] w-full h-full">
                <div className="text-[var(--mauve11)]">{caption}</div>
            </div>
        )
    }


    function renderSloganAndBanner() {

        // const slogan = <>{colorText("大模型")}时代的{colorText("生产力工具")}</>;
        // const slogan = <>每一个{colorText("知识工作者")}</>
        const slogan = <>使用 {colorText("AI")}，跟你的{colorText("知识")}直接{colorText("对话")}</>
        const captions = (
            <>
                <div>{colorText("每个人")}都是知识工作者</div>
                <div>都需要自己的{colorText("知识库")}</div>
                <div>和基于这个知识库的 {colorText("AI 助手")}</div>
                {/* <div>帮你{colorText("提速")}，激发你的{colorText("创造力")}</div> */}
            </>
        )

        return (
            <>
                {/* wide */}
                <div className="hidden md:flex gap-4 justify-center items-center h-[480px] w-full max-w-6xl px-6">
                    <div className="w-2/5 flex flex-col gap-12 h-full justify-center items-center min-w-[328px]">
                        <div className="flex flex-col gap-16">
                            <div className="flex flex-col gap-8">
                                <h2 className="text-[24px] font-medium leading-normal">{slogan}</h2>
                                <div className="font-size-16 flex flex-col gap-3 px-1">
                                    {captions}
                                </div>
                            </div>
                            <div className="px-1">
                                <Button id="btn-try-at-banner-wide"
                                    appearance="primary" size="large" className="!px-20" color="violet" onPress={_ => {
                                        // routeTo(tryUrl);
                                        track("home_try", { method: "banner_wide" })
                                        const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
                                        window.open(url);
                                    }}>{tryButtonLabel}</Button>
                            </div>
                        </div>
                    </div>
                    <div className="w-3/5 pr-2">
                        <img className="w-full max-w-[640px]" src={getImageURL(illustrationPC, "large")} />
                    </div>
                </div>
                {/* narrow */}
                <div className="flex md:hidden flex-col gap-4 justify-center items-center w-full max-w-6xl px-6 py-4">
                    <div className="w-full flex flex-col gap-16 justify-center items-center py-12">
                        <div className="flex flex-col gap-6">
                            <h2 className="text-[22px] font-medium leading-normal">{slogan}</h2>
                            <div className="font-size-15 flex flex-col gap-3 items-center">
                                {captions}
                            </div>
                        </div>
                        <div>
                            <Button id="btn-try-at-banner-narrow" onPress={_ => {
                                track("home_try", { method: "banner_narrow" })
                                const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
                                window.open(url);
                            }}
                                appearance="primary" size="large" className="!px-20" color="violet">{tryButtonLabel}</Button>
                        </div>
                    </div>
                    <div className="py-12">
                        <img className="w-full max-w-[480px]" src={getImageURL(illustrationPC, "large")} />
                    </div>
                </div>
            </>

        )

    }



    return (
        <div className="pt-28 w-full flex flex-col items-center">
            {renderSloganAndBanner()}
            {/* <div className="w-full max-w-3xl py-8 px-4">
                {renderVideoDemo(videoDemo, "这是多个 Demo 组成的视频")}
            </div> */}
            <div className="w-full py-12 sm:py-16 flex flex-col bg-[var(--lc-color-bg-2)] items-center">
                <h2 className="font-medium px-6 sm:px-8 self-center text-[22px]">你可以用 {colorText("SimplifyAI")} 做什么</h2>
                <div className="py-10 sm:py-16 flex flex-col gap-10 sm:gap-16 max-w-5xl px-5 sm:px-8 ">
                    {features.map((f, index) => {
                        function clickMoreLink(className) {
                            return (
                                f.detailUrl ?
                                    <Link className={classNames(
                                        // "py-1.5 pl-3.5 pr-2 rounded ml-1 bg-[var(--mauve3)] !text-[var(--mauve11)] hover:bg-[var(--mauve4)]",  // button style
                                        " !text-[var(--mauve11)] ml-0.5 px-0.5 hover:!text-[var(--violet11)] hover:underline",  // link style
                                        "trk-link-feature-more",
                                        className
                                    )} to={f.detailUrl}>查看详情<i className='bx bx-chevrons-right font-size-15 translate-y-px ml-1'></i></Link>
                                    : null
                            )
                        }

                        return (
                            <React.Fragment key={index}>
                                {/* wide */}
                                <div className={classNames("py-3 hidden sm:flex gap-12 items-start", { "flex-row-reverse": index % 2 !== 0 })}>
                                    <div className="flex flex-col w-1/3 justify-center items-center">
                                        <div className="flex flex-col gap-6">
                                            <h3 className="text-xl font-medium">{f.title}</h3>
                                            <ul className="text-[var(--gray11)] font-size-14 whitespace-pre-line leading-relaxed flex flex-col items-start gap-3 px-1 mb-0">
                                                {f.points.map(p => (<li key={p}>{p}</li>))}
                                            </ul>
                                            {clickMoreLink("self-start")}
                                        </div>
                                    </div>
                                    <div className={classNames("w-2/3 p-2", { "h-[320px]": !f.video })}>
                                        {renderVideoDemo(f.video, f.videoAlt)}
                                    </div>
                                </div>
                                {/* narrow */}
                                <div className={classNames("py-3 flex flex-col sm:hidden gap-8")}>
                                    <div className="flex flex-col w-full justify-center items-center">
                                        <div className="flex flex-col gap-5 px-1">
                                            <h3 className="text-lg font-medium">{f.title}</h3>
                                            <ul className="text-[var(--gray11)] font-size-14 whitespace-pre-line leading-relaxed flex flex-col items-start gap-3 px-1 mb-0">
                                                {f.points.map(p => (<li key={p}>{p}</li>))}
                                            </ul>
                                            {clickMoreLink("self-start")}
                                        </div>
                                    </div>
                                    <div className={classNames("w-full", { "h-[320px]": !f.video })}>
                                        {renderVideoDemo(f.video, f.videoAlt)}
                                    </div>
                                </div>
                            </React.Fragment>


                        )
                    })}
                </div>
            </div>
            {/* <div className="w-full py-8 sm:py-16 flex flex-col bg-[var(--mauve2)] px-4">
                <h2 className="text-[22px] px-1 sm:text-2xl font-medium sm:self-center">看看有{colorText("哪些使用场景")}</h2>
                <div className="py-8 sm:py-16 flex flex-col sm:px-4 max-w-4xl w-full self-center">
                    {articleGrid || null}
                </div>
            </div> */}
        </div>
    )
}


