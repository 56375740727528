
import React from 'react'

import './ItemActionMenu.less';


// possible actions
const actionConfigs = {
    "open": { label: "打开编辑" },

    "new-entity": { label: "添加数据实体" },
    "new-extending-entity": { label: "添加扩展实体" },

    "new-page": { label: "添加页面" },
    "new-page-component": { label: "添加页面组件" },
    "new-page-fragment": { label: "添加页面片段" },
    "new-admin-page": { label: "添加管理页面" },
    "new-page-redirect": { label: "添加页面跳转" },

    "new-scheduled-event-handler": { label: "添加定时事件处理" },
    "new-event-handler": { label: "添加事件处理" },


    "new-nav-bar": { label: "设置后台导航栏" },
    "new-general-setting": { label: "添加后台设置项" },

    "new-field": { label: "添加字段" },
    "new-virtual-field": { label: "添加虚拟字段" },
    "new-data-interface": { label: "添加数据接口" },
    "new-backlink-field": { label: "添加反向关联字段" },


    "extend-field": { label: "添加字段" },
    "extend-virtual-field": { label: "添加虚拟字段" },
    "extend-data-interface": { label: "添加数据接口" },
    "extend-backlink-field": { label: "添加反向关联字段" },


    "edit-list-page": { label: "编辑列表页" },
    "edit-detail-page": { label: "编辑详情页" },

    "remove": { label: "删除" },

    "refresh": { label: "刷新" },

    "add-dependency": { label: "添加依赖应用" }
};


export function getActionGroups(item) {

    const defaultMenu = _ => {
        if(item.data.内置 == true) {
            return [];
        }

        return [["open"], ["remove"]]
    };

    const itemMenus = {
        "entity": _ => [
            ["open"],
            ["new-field", "new-virtual-field", "new-data-interface", "new-backlink-field"],
            ["edit-list-page", "edit-detail-page"],
            ["remove"]
        ],
        "page-folder": _ => [["new-page"], ["refresh"]],
        "page-component-folder": _ => [["new-page-component"], ["refresh"]],
        "page-fragment-folder": _ => [["new-page-fragment"], ["refresh"]],
        "page-redirect-folder": _ => [["new-page-redirect"], ["refresh"]],
        "admin-page-folder": _ => [["new-admin-page"], ["refresh"]],
        "entities": _ => [["new-entity", "new-tag-folder"], ["refresh"]],
        // "entity-tag-folder": _ => [["new-entity"],["refresh"]],

        "extending-entities": _ => [["new-extending-entity"], ["refresh"]],

        "event-handler-folder": _ => [["new-event-handler"]],
        "scheduled-event-handler-folder": _ => [["new-scheduled-event-handler"]],

        "admin-setting-folder": _ => [["new-nav-bar", "new-general-setting"],  ["refresh"]],

        "dependencies": _ => [
            ["add-dependency"], ["refresh"]
        ],

        "dependency": item => {
            if(item && item.data && item.data["系统应用"]) {
                // 不能删除系统应用
                return []
            } else {
                return [
                    ["remove"]
                ]
            }
        },

        "imported-entity": _ => [
            [
                "extend-field", "extend-virtual-field",
                "extend-data-interface", "extend-backlink-field"
            ]
        ]


    }

    const handleTagFolder = itemType => {
        const suffix = "-tag-folder";
        if (itemType && itemType.endsWith(suffix)) {
            const elementItemType = itemType.substring(0, itemType.length - suffix.length);
            return [["new-" + elementItemType], ["refresh"]]
        }
        return null
    }

    const groups = handleTagFolder(item.itemType) || (itemMenus[item.itemType] || defaultMenu)(item);

    return groups;

}




export default function ItemActionMenu({ item, onAction }) {

    function fireAction(actionName) {
        const actionObj = { actionName, item };
        if (onAction) {
            onAction(actionObj)
        }
        // 
    }

    const groups = getActionGroups(item);


    return groups && groups.length > 0 ? (
        <div className="studio-item-action-menu">
            {
                groups.map((group, index) => {
                    return (
                        <div className="action-menu-group" key={index}>
                            {
                                group.map(actionName => {
                                    const action = actionConfigs[actionName];
                                    if (action) {
                                        return (
                                            <div className="action-menu-item" key={actionName} onClick={
                                                _ => fireAction(actionName)
                                            }>
                                                <div className="label">
                                                    {action.label}
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }

                                })
                            }
                            {index < groups.length - 1 ? <div className="seperator"></div> : null}
                        </div>
                    )
                })
            }
        </div>
    ) : null
}
