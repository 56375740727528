

export default function extractParseTree(treeNode, codeText) {
    return {
        name: treeNode.name,
        text: getText(treeNode, codeText),
        from: treeNode.from,
        to: treeNode.to,
        children: getChildren(treeNode).map(child => extractParseTree(child, codeText))
    }
}

// handle syntax nodes
function getChildren(node) {
    let children = [];
    const firstChild = node.firstChild;
    if (firstChild) {
        children.push(firstChild);
        let nextChild = firstChild.nextSibling;
        while (nextChild) {
            children.push(nextChild);
            nextChild = nextChild.nextSibling;
        }
    }
    return children
}

function getText(node, codeText) {
    return codeText.substring(node.from, node.to);
}



