

const pu = " " // pad unit

export default function buildQuerySelection(
    { isEntity, name, fields = [], virtualFields = [] }, // data entity or data type
    allEntities,
    allDataTypes, 
    { queryDepth, includingLinks, excludedFields = []}, 
    depth) {


  /// queryDepth is 0 1 2 indicating the level to query
  let pad = ""
  for(let i = 0; i < depth; i++) {
    pad += pu
  }

  if(queryDepth > 0) {

    const allFields = includingLinks ? [...fields, ...(virtualFields ||[])] : fields

    return allFields.reduce((acc, field) => {
      if(field.hidden || excludedFields.indexOf(field.name) != -1) {
        return acc
      }

      if(field.type === "Link") {
        const keyDict = allEntities.reduce( (acc, entity) => ({
          ...acc, [entity.key] : entity
        }), {})
        const nameDict = allEntities.reduce( (acc, entity) => ({
          ...acc, [entity.name] : entity
        }), {})
        const linkedEntity = keyDict[field.options.entity] || nameDict[field.options.entity]

        //// TODO 不同的 outputStructure 要做不同的处理
        if(field.options.outputStructure === "Connection") {
          throw new Error("not yet supported for connnection in query builder")
        }

        if(!linkedEntity) {
          // link 指向的实体找不到。这可能是某些没有被暴露出来的实体。 TODO
          /// 暂时我完全忽略这个 field
          console.warn("linked entity not found for ", field.name, "of entity", name)
          return acc  
        }

        return acc + pad + field.key + "{\n" +
          buildQuerySelection(
            {
              isEntity: true,
              ...linkedEntity
            }, 
            allEntities,
            allDataTypes,
            {
              queryDepth: queryDepth - 1, 
              includingLinks,  
            },
            depth + 1
          ) +
          pad + "}\n"
        

      } else  {

        const dataType = allDataTypes.find( d => d.key === field.type)

        const allFields = [...(dataType.settings?.fields || []), ...(dataType.settings?.virtualFields || [])]

        if(allFields.length > 0) {

          return acc + pad + field.key + "{\n" +
            buildQuerySelection(
              {
                ...(dataType.settings || {}),
                isEntity: false
              }, 
              allEntities,
              allDataTypes,
              {
                queryDepth: queryDepth, // 是不是不用减？
                includingLinks: true,  // Data Types always have virtual fields 
              },
              depth + 1
            ) +
            pad + "}\n"

        } else {
          return acc + pad + field.key + "\n"
        }


        // return acc + pad + field.key + "{\n" + 
        //              pad + pu + "contentType\n" + 
        //              pad + pu + "size\n" + 
        //              pad + pu + "url\n" +
        //              pad + pu + "id\n" +
        //              pad + "}\n"
        //  /// 其他类型可能也要特殊考虑      
      } 

    }, isEntity ? pad + "id\n" : "")
  }

  /// asusuming queryDepth is 0 or null:


  return pad + "id\n"

}