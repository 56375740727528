
// import custom from './custom'

// import cards from './cards'
// import banners from './banners'

// import templates from './templates'

// import notifications from './notifications'

// import layout from './layout'
// import tabs from './tabs'
// import footers from './footers'
// import panes from './panes'

// import modals from './modals'

// import inputs from './inputs'
// import buttons from './buttons'


// /// basic ones:

// import Icon from 'bwax-ui/basic/Icon';
// import Heading from 'bwax-ui/basic/Heading';
// import RichText from 'bwax-ui/basic/RichText';

// import Image from 'bwax-ui/basic/Image';
// import CoverImage from 'bwax-ui/basic/CoverImage';

// import FluidHeading from 'bwax-ui/basic/FluidHeading';
// import FluidText from 'bwax-ui/basic/FluidText';

// import Column from 'bwax-ui/basic/Column';
// import Row from 'bwax-ui/basic/Row';
// import Grid from 'bwax-ui/basic/Grid';
// import Box from 'bwax-ui/basic/Box';


// import Text from 'bwax-ui/basic/Text';
// import Button from 'bwax-ui/basic/Button';

// import Video from 'bwax-ui/basic/media/Video';

//// ReasonML widgets:
// import Layout_LeftNavOneColumn from "bwax-ui/re/widgets/layout/Layout_LeftNavOneColumn.bs";

const reWidgets = {
    /// used for backward compatibility
    // Layout_LeftNavOneColumn,
    // "layout::LeftNavOneColumn": Layout_LeftNavOneColumn,
}

const widgets = {
    // basic ones
    // Button,
    // Text,  // 这两个在 ConfirmToken 里面用到了


    // Icon,
    // Heading,
    // RichText,
    // Image,
    // CoverImage,

    // FluidHeading,
    // FluidText,

    // Column,
    // Row,
    // Box,
    // Grid,



    // Video,

    // // others
    // ...custom,
    // ...cards,
    // ...banners,
    // //...legacy,

    // ...templates,
    // ...headers,

    // ...notifications,
    // ...layout,

    // ...tabs,
    // ...footers,

    // ...panes,
    // ...modals,

    // ...inputs,
    // ...buttons,

}

const combined = Object.keys(reWidgets).reduce((acc, name) => {

    const widgetName = name
    const widget = reWidgets[name]
    return {
        ...acc,
        [widgetName]: widget.make
    }

}, widgets)

export default combined