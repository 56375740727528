
function liftOnlyLhs(ast, nextCalc, otherwise) {
    const lhs = ast.children.lhs
    const rhs = ast.children.rhs
    if (!rhs || rhs.length === 0) {
        // lift - 直接只处理唯一操作数
        return nextCalc(lhs[0])
    } else {
        const { lhs, rhs, ...operatorDict } = ast.children
        const operators = Object.values(operatorDict)[0] // only one operator
        return otherwise(lhs, rhs, operators)
    }
}

function getPositionFromPostionArr(positions) {
    return positions.reduce((acc, next) => {

        if(acc.startOffset > next.startOffset) {
            acc.startOffset = next.startOffset
        }

        if(acc.endOffset < next.endOffset) {
            acc.endOffset = next.endOffset
        }

        return acc
    })
}

export default class AstPrinter  {

    constructor() {
        this.calcFunc = {
            'logicExp': this.calcLogicExp,
            'boolExp': this.calcBoolExp,
            'unaryLogicExp': this.calcUnaryLogicExp,
            'comparisonExp': this.calcComparisonExp,
            'additionExp': this.calcAdditionExp,
            'multiplicationExp': this.calcMultiplicationExp,
            'atomicExp': this.calcAtomicExp,
            'callExp': this.calcCallExp,
            'parenthesisExp': this.calcParenthesisExp,
            'qualifiedName': this.calcQualifiedName
        }
    }

    calcCallExp = (ast) => {
        const funcStartOffset = ast.children.func[0].position.startOffset
        const rParenEndOffset = ast.children.RParen[0].position.endOffset
        return {
            startOffset: funcStartOffset,
            endOffset: rParenEndOffset
        }
    }

    calcParenthesisExp = (ast) => {
        return getPositionFromPostionArr([
            {...ast.children.LParen[0].position},
            {...ast.children.RParen[0].position},
            this.calExpression(ast.children.expression[0])
        ])
    }

    calcQualifiedName = (ast) => {
        return getPositionFromPostionArr([
            ...(ast.children.Dot || []).map(n => n.position),
            ...ast.children.Identifier.map(n => n.position)
        ])
    }

    calcAtomicExp = (ast) => {

        function calcNumberLiteral(ast) {
            return {...ast.position}
        }

        function calcBoolLiteral(ast) {
            return {...ast.position}
        }

        function calcStringLiteral(ast) {
            return {...ast.position}
        }

        if (ast.children.callExp) {
            return this.calcCallExp(ast.children.callExp[0])

        } else if (ast.children.parenthesisExp) {
            return this.calcParenthesisExp(ast.children.parenthesisExp[0])

        } else if (ast.children.qualifiedName) {

            return this.calcQualifiedName(ast.children.qualifiedName[0])

        } else if (ast.children.NumberLiteral) {

            return calcNumberLiteral(ast.children.NumberLiteral[0])

        } else if (ast.children.BoolLiteral) {

            return calcBoolLiteral(ast.children.BoolLiteral[0])

        } else if (ast.children.StringLiteral) {

            return calcStringLiteral(ast.children.StringLiteral[0])

        } else {
            // TODO to support many more:
            console.warn("unsupported exp", ast)
        }
    }

    calcMultiplicationExp = (ast) => {
        return liftOnlyLhs(ast, this.calcAtomicExp, (lhs, rhs, operators) => {
            return getPositionFromPostionArr([
                this.calcAtomicExp(lhs[0]),
                ...operators.map(n => n.position),
                ...rhs.map(n => this.calcAtomicExp(n))
            ])
        })
    }

    calcAdditionExp = (ast) => {
        return liftOnlyLhs(ast, this.calcMultiplicationExp, (lhs, rhs, operators) => {
            return getPositionFromPostionArr([
                this.calcMultiplicationExp(lhs[0]),
                ...operators.map(n => n.position),
                ...rhs.map(n => this.calcMultiplicationExp(n))
            ])
        })
    }

    calcComparisonExp = (ast) => {
        return liftOnlyLhs(ast, this.calcAdditionExp, (lhs, rhs, operators) => {
            return getPositionFromPostionArr([
                this.calcAdditionExp(lhs[0]),
                ...operators.map(n => n.position),
                this.calcAdditionExp(rhs[0])
            ])
        })
    }

    calcUnaryLogicExp = (ast) => {

        const operator = ast.children.UnaryLogicOperator[0]
        const operand = ast.children.comparisonExp[0]

        if (operator.tokenType === "Not") {
            return getPositionFromPostionArr([
                operator.position,
                this.calcComparisonExp(operand)
            ])
        } else {
            invariant(false, `Unsupported unary logic operator ${operator.tokenType}`)
        }
    }

    calcBoolExp = (ast) => {

        if (ast.children.unaryLogicExp) {

            return this.calcUnaryLogicExp(ast.children.unaryLogicExp[0])

        } else if (ast.children.comparisonExp) {

            return this.calcComparisonExp(ast.children.comparisonExp[0])

        } else {
            invariant(false, "logicExp's only child must either be unaryLogicExp or binaryLogicExp")
        }
    }

    calcLogicExp = (ast) => {
        return liftOnlyLhs(ast, this.calcBoolExp, (lhs, rhs, operators) => {
            return getPositionFromPostionArr([
                this.calcBoolExp(lhs[0]),
                ...operators.map(n => n.position),
                ...rhs.map(n => this.calcBoolExp(n))
            ])
        })
    }

    calExpression = (ast) => {
        if(ast && ast.children.logicExp) {
            return this.calcLogicExp(ast.children.logicExp[0])
        }
    }

    calcAstPosition = (ast) => {
        if(!ast.name && ast.image) {
            return {...ast.position}
        } else {
            const f = this.calcFunc[ast.name]
            return f(ast)
        }
    }

    printExpression = (expressionAst, originText) => {
        const position = this.calcAstPosition(expressionAst)
        return originText.substring(position.startOffset, position.endOffset + 1)
    }
}
