// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Modal = require("./misc/Modal");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../../re/BaseUI.bs.js");
var MiscUI$BwaxAdmin = require("../../../re/MiscUI.bs.js");
var Swiper$BwaxAdmin = require("../../../re/auxiliary/Swiper.bs.js");
var MDIconBridge = require("./misc/MDIconBridge");
var BoxIconBridge = require("./misc/BoxIconBridge");
var PagedDataList = require("./misc/PagedDataList");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var LoginQrCode$BwaxAdmin = require("../../../re/auxiliary/LoginQrCode.bs.js");
var TypeWriterEffect = require("./misc/TypeWriterEffect");
var Widget_common$BwaxAdmin = require("../../mod/widget/widget_common.bs.js");
var OnePageLayout001$BwaxAdmin = require("../../../re/auxiliary/layout/OnePageLayout001.bs.js");
var Widget_mod_misc_ui$BwaxAdmin = require("../../mod/widget/widget_mod_misc_ui.bs.js");
var Widget_impl_data_ui$BwaxAdmin = require("./widget_impl_data_ui.bs.js");
var AutoPlayVideo = require("bwax-ui/components/AutoPlayVideo");

function pagedDataList(prim) {
  return PagedDataList.create(prim);
}

function mdIcon(prim) {
  return MDIconBridge.create(prim);
}

function boxIcon(prim, prim$1, prim$2) {
  return BoxIconBridge.create(prim, prim$1, prim$2);
}

function modal(prim) {
  return Modal.create(prim);
}

function typeWriterEffect(prim) {
  return TypeWriterEffect.create(prim);
}

function to_el(react_el, param, param$1, param$2, param$3) {
  return react_el;
}

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent) {
  switch (rawContent.tag | 0) {
    case /* MD_Icon */0 :
        var rules = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                    -481689226,
                    /* N50 */3890729
                  ]),
                /* [] */0
              ]), Curry._1(convertRules, rawContent[0]));
        var partial_arg = MDIconBridge.create(rawContent[1]);
        var icon_el = function (param, param$1, param$2, param$3) {
          return partial_arg;
        };
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxAdmin.boxWithClassName("icon", rules, icon_el, param, param$1, param$2, param$3);
          });
    case /* Box_Icon */1 :
        var rules$1 = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                    -481689226,
                    /* N70 */3891175
                  ]),
                /* [] */0
              ]), Curry._1(convertRules, rawContent[0]));
        var partial_arg$1 = BoxIconBridge.create(Curry._1(Plate$BwaxAdmin.List.to_array, rawContent[1]), rawContent[2], rawContent[3]);
        var icon_el$1 = function (param, param$1, param$2, param$3) {
          return partial_arg$1;
        };
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxAdmin.boxWithClassName("icon", rules$1, icon_el$1, param, param$1, param$2, param$3);
          });
    case /* UI_newsflash */2 :
        var duration = rawContent[1];
        var rules$2 = Curry._1(convertRules, rawContent[0]);
        var partial_arg$2 = Plate$BwaxAdmin.List.map(doRender, rawContent[2]);
        return (function (param, param$1, param$2, param$3) {
            return MiscUI$BwaxAdmin.newsflash(rules$2, duration, partial_arg$2, param, param$1, param$2, param$3);
          });
    case /* UI_wechatLoginCode */3 :
        var onSuccess = rawContent[3];
        var m_onCountDown = rawContent[2];
        var m_size = rawContent[1];
        var mrs = rawContent[0];
        return (function (theme, pc, param, key) {
            return LoginQrCode$BwaxAdmin.create(key, pc, theme, Plate$BwaxAdmin.$$Option.with_default(128, m_size), Curry._1(convertRules, mrs), (function (counter) {
                          if (m_onCountDown !== undefined) {
                            return Curry._1(onMsg, Curry._1(m_onCountDown, counter));
                          } else {
                            return /* () */0;
                          }
                        }), (function (param) {
                          return Curry._1(onMsg, onSuccess);
                        }));
          });
    case /* UI_onePageLayout001 */4 :
        var on_scrolled_to_section = rawContent[5];
        var build_nav_bar = rawContent[4];
        var footer_el = rawContent[3];
        var sections = rawContent[2];
        var welcome_el = rawContent[1];
        var mrs$1 = rawContent[0];
        return (function (theme, pc, param, key) {
            return OnePageLayout001$BwaxAdmin.create(key, Curry._1(convertRules, mrs$1), theme, pc, Curry._1(doRender, welcome_el), Plate$BwaxAdmin.List.map((function (param) {
                              return /* tuple */[
                                      Curry._1(doRender, param[0]),
                                      Curry._1(doRender, param[1])
                                    ];
                            }), sections), Curry._1(doRender, footer_el), (function (els) {
                          var elements = Plate$BwaxAdmin.List.map((function (el) {
                                  return /* Rendered */Caml_chrome_debugger.variant("Rendered", 1, [el]);
                                }), els);
                          return Curry._1(doRender, Curry._1(build_nav_bar, elements));
                        }), (function (index) {
                          if (on_scrolled_to_section !== undefined) {
                            return Curry._1(onMsg, Curry._1(on_scrolled_to_section, index));
                          } else {
                            return /* () */0;
                          }
                        }));
          });
    case /* UI_swiper */5 :
        var options = rawContent[3];
        var slides = rawContent[2];
        var config = rawContent[1];
        var mrs$2 = rawContent[0];
        return (function (theme, pc, param, key) {
            var rules = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `zIndex */Caml_chrome_debugger.polyVar("zIndex", [
                        416026680,
                        0
                      ]),
                    /* [] */0
                  ]), Curry._1(convertRules, mrs$2));
            var match = config[/* onActiveIndexChange */1];
            var tmp;
            if (match !== undefined) {
              var f = match;
              tmp = (function (index) {
                  return Curry._1(onMsg, Curry._1(f, index));
                });
            } else {
              tmp = undefined;
            }
            return Swiper$BwaxAdmin.create(key, rules, theme, pc, config[/* activeIndex */0], tmp, Plate$BwaxAdmin.List.map((function (builder, json) {
                              return Curry._1(doRender, Curry._1(builder, json));
                            }), slides), options);
          });
    case /* UI_modal */6 :
        var content = rawContent[2];
        var config$1 = rawContent[1];
        var rules$3 = Curry._1(convertRules, rawContent[0]);
        return (function (theme, pc, prs, key) {
            var modalContent = BaseUI$BwaxAdmin.boxWithClassName("modal-content-wrapper", rules$3, Curry._1(doRender, content), theme, pc, prs, key);
            var handle_background_click = Plate$BwaxAdmin.$$Option.map((function (msg, param) {
                    return Curry._1(onMsg, msg);
                  }), config$1[/* on_background_click */1]);
            var handle_close = Plate$BwaxAdmin.$$Option.map((function (msg, param) {
                    return Curry._1(onMsg, msg);
                  }), config$1[/* on_close */3]);
            var title = Plate$BwaxAdmin.$$Option.map((function (el) {
                    return Curry._5(doRender, el, theme, /* row */5693978, /* [] */0, "title");
                  }), config$1[/* title */4]);
            var footer = Plate$BwaxAdmin.$$Option.map((function (el) {
                    return Curry._5(doRender, el, theme, /* row */5693978, /* [] */0, "footer");
                  }), config$1[/* footer */5]);
            return Modal.create({
                        modalContent: modalContent,
                        key: key,
                        visible: config$1[/* is_open */0],
                        onBackgroundClick: handle_background_click,
                        onClose: handle_close,
                        size: config$1[/* size */2],
                        title: title,
                        footer: footer,
                        compact: config$1[/* compact */6]
                      });
          });
    case /* Paged_data_list */7 :
        var render_fn = rawContent[3];
        var match = rawContent[2];
        var header_el = match[3];
        var pageSize = match[2];
        var loading_el = match[1];
        var isLazy = match[0];
        var query_config = rawContent[1];
        var rules$4 = Curry._1(convertRules, rawContent[0]);
        return (function (theme, pc, prs, key) {
            var wrapWithContainer = function (show_header, pagination, page_list, footer, fixed_pagination) {
              var header = show_header ? Plate$BwaxAdmin.$$Option.with_default(BaseUI$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map(doRender, header_el)) : BaseUI$BwaxAdmin.empty;
              var pagination_el = function (param, param$1, param$2, param$3) {
                return pagination;
              };
              var page_list_el = function (param, param$1, param$2, param$3) {
                return page_list;
              };
              var footer_el = function (param, param$1, param$2, param$3) {
                return footer;
              };
              var inner = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (idx, el) {
                          return Curry._4(el, theme, /* column */-963948842, rules$4, String(idx));
                        }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          header,
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              pagination_el,
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  page_list_el,
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      footer_el,
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          (function (param, param$1, param$2, param$3) {
                                              return fixed_pagination;
                                            }),
                                          /* [] */0
                                        ])
                                    ])
                                ])
                            ])
                        ])));
              var toCss = function (r) {
                return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_column */2, pc, prs);
              };
              return BaseUI$BwaxAdmin.renderEl("paged-data-list column ctnr", rules$4, key, theme, pc, toCss, inner);
            };
            var toElement = function (entity_dict, data_type_dict, entity_name, json) {
              return Widget_impl_data_ui$BwaxAdmin.to_element(entity_dict, data_type_dict, entity_name, json, render_fn);
            };
            var makePage = function (id, el_array) {
              var els = Plate$BwaxAdmin.List.map(doRender, Curry._1(Plate$BwaxAdmin.List.from_array, el_array));
              var inner = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (idx, el) {
                          return Curry._4(el, theme, /* column */-963948842, /* [] */0, String(idx));
                        }), els));
              var toCss = function (r) {
                return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_column */2, /* column */-963948842, /* [] */0);
              };
              return BaseUI$BwaxAdmin.renderEl("paged-data-list-page column ctnr", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* `id */Caml_chrome_debugger.polyVar("id", [
                                23515,
                                id
                              ]),
                            /* [] */0
                          ]), "no-key", theme, pc, toCss, inner);
            };
            var loading = Plate$BwaxAdmin.$$Option.map((function (e) {
                    return Curry._5(doRender, e, theme, pc, prs, key);
                  }), loading_el);
            var get_depended_paths = function (entity_dict, data_type_dict, dts, entity_name) {
              return Widget_impl_data_ui$BwaxAdmin.get_depended_paths_from_fn(entity_dict, data_type_dict, dts, entity_name, render_fn);
            };
            return PagedDataList.create({
                        query_config: query_config,
                        get_depended_paths: get_depended_paths,
                        key: key,
                        isLazy: isLazy,
                        loading: loading,
                        pageSize: pageSize,
                        toElement: toElement,
                        wrapWithContainer: wrapWithContainer,
                        makePage: makePage,
                        facade: facade
                      });
          });
    case /* Type_writer_effect */8 :
        var v_effects = rawContent[2];
        var config$2 = rawContent[1];
        var rules$5 = Curry._1(convertRules, rawContent[0]);
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxAdmin.boxWithClassName("", rules$5, (function (theme, pc, prs, key) {
                          var effects = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.keep_map((function (param) {
                                      var exit = 0;
                                      var cmd;
                                      var rs;
                                      var s;
                                      var cmd$1;
                                      var s$1;
                                      var match = param[0];
                                      if (typeof match === "number" || match.tag !== /* V_tagged */6) {
                                        return ;
                                      } else {
                                        var cmd$2 = match[0];
                                        switch (cmd$2) {
                                          case "ChangeDelay" :
                                              var match$1 = match[1];
                                              if (match$1) {
                                                var match$2 = match$1[0][0];
                                                if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                                                  return ;
                                                } else {
                                                  var match$3 = match$2[0];
                                                  if (typeof match$3 === "number" || match$3.tag || match$1[1]) {
                                                    return ;
                                                  } else {
                                                    cmd$1 = cmd$2;
                                                    s$1 = match$3[0];
                                                    exit = 2;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          case "ChangeDeleteSpeed" :
                                              var match$4 = match[1];
                                              if (match$4) {
                                                var match$5 = match$4[0][0];
                                                if (typeof match$5 === "number" || match$5.tag !== /* V_literal */5) {
                                                  return ;
                                                } else {
                                                  var match$6 = match$5[0];
                                                  if (typeof match$6 === "number" || match$6.tag || match$4[1]) {
                                                    return ;
                                                  } else {
                                                    cmd$1 = cmd$2;
                                                    s$1 = match$6[0];
                                                    exit = 2;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          case "DeleteAll" :
                                              var match$7 = match[1];
                                              if (match$7) {
                                                var match$8 = match$7[0][0];
                                                if (typeof match$8 === "number" || match$8.tag !== /* V_literal */5) {
                                                  return ;
                                                } else {
                                                  var match$9 = match$8[0];
                                                  if (typeof match$9 === "number" || match$9.tag || match$7[1]) {
                                                    return ;
                                                  } else {
                                                    cmd$1 = cmd$2;
                                                    s$1 = match$9[0];
                                                    exit = 2;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          case "DeleteChars" :
                                              var match$10 = match[1];
                                              if (match$10) {
                                                var match$11 = match$10[0][0];
                                                if (typeof match$11 === "number" || match$11.tag !== /* V_literal */5) {
                                                  return ;
                                                } else {
                                                  var match$12 = match$11[0];
                                                  if (typeof match$12 === "number" || match$12.tag || match$10[1]) {
                                                    return ;
                                                  } else {
                                                    cmd$1 = cmd$2;
                                                    s$1 = match$12[0];
                                                    exit = 2;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          case "PasteString" :
                                              var match$13 = match[1];
                                              if (match$13) {
                                                var match$14 = match$13[0][0];
                                                if (typeof match$14 === "number" || match$14.tag !== /* V_list */1) {
                                                  return ;
                                                } else {
                                                  var match$15 = match$13[1];
                                                  if (match$15) {
                                                    var match$16 = match$15[0][0];
                                                    if (typeof match$16 === "number" || match$16.tag !== /* V_literal */5) {
                                                      return ;
                                                    } else {
                                                      var match$17 = match$16[0];
                                                      if (typeof match$17 === "number" || !(match$17.tag === /* String */2 && !match$15[1])) {
                                                        return ;
                                                      } else {
                                                        cmd = cmd$2;
                                                        rs = match$14[0];
                                                        s = match$17[0];
                                                        exit = 1;
                                                      }
                                                    }
                                                  } else {
                                                    return ;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          case "PauseFor" :
                                              var match$18 = match[1];
                                              if (match$18) {
                                                var match$19 = match$18[0][0];
                                                if (typeof match$19 === "number" || match$19.tag !== /* V_literal */5) {
                                                  return ;
                                                } else {
                                                  var match$20 = match$19[0];
                                                  if (typeof match$20 === "number" || match$20.tag || match$18[1]) {
                                                    return ;
                                                  } else {
                                                    cmd$1 = cmd$2;
                                                    s$1 = match$20[0];
                                                    exit = 2;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          case "TypeString" :
                                              var match$21 = match[1];
                                              if (match$21) {
                                                var match$22 = match$21[0][0];
                                                if (typeof match$22 === "number" || match$22.tag !== /* V_list */1) {
                                                  return ;
                                                } else {
                                                  var match$23 = match$21[1];
                                                  if (match$23) {
                                                    var match$24 = match$23[0][0];
                                                    if (typeof match$24 === "number" || match$24.tag !== /* V_literal */5) {
                                                      return ;
                                                    } else {
                                                      var match$25 = match$24[0];
                                                      if (typeof match$25 === "number" || !(match$25.tag === /* String */2 && !match$23[1])) {
                                                        return ;
                                                      } else {
                                                        cmd = cmd$2;
                                                        rs = match$22[0];
                                                        s = match$25[0];
                                                        exit = 1;
                                                      }
                                                    }
                                                  } else {
                                                    return ;
                                                  }
                                                }
                                              } else {
                                                return ;
                                              }
                                              break;
                                          default:
                                            return ;
                                        }
                                      }
                                      switch (exit) {
                                        case 1 :
                                            var rs$1 = Curry._1(convertRules, Widget_common$BwaxAdmin.raw_to_rules(rs));
                                            return /* tuple */[
                                                    cmd,
                                                    Curry._1(Plate$BwaxAdmin.List.to_array, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            rs$1,
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                s,
                                                                /* [] */0
                                                              ])
                                                          ]))
                                                  ];
                                        case 2 :
                                            return /* tuple */[
                                                    cmd$1,
                                                    s$1
                                                  ];
                                        
                                      }
                                    }), v_effects));
                          return TypeWriterEffect.create({
                                      effects: effects,
                                      config: config$2
                                    });
                        }), param, param$1, param$2, param$3);
          });
    case /* Auto_paly_video */9 :
        var alt = rawContent[2];
        var src = rawContent[1];
        var rules$6 = Curry._1(convertRules, rawContent[0]);
        return (function (theme, pc, prs, key) {
            var toCss = function (r) {
              return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_box */3, pc, prs);
            };
            var className = BaseUI$BwaxAdmin.buildClassName("", rules$6, toCss);
            return AutoPlayVideo.create({
                        src: src,
                        key: key,
                        alt: alt,
                        className: className
                      });
          });
    
  }
}

var module_name = Widget_mod_misc_ui$BwaxAdmin.module_name;

exports.pagedDataList = pagedDataList;
exports.mdIcon = mdIcon;
exports.boxIcon = boxIcon;
exports.modal = modal;
exports.typeWriterEffect = typeWriterEffect;
exports.module_name = module_name;
exports.to_el = to_el;
exports.render_element = render_element;
/* ./misc/Modal Not a pure module */
