
import React from 'react'

import { mapObj } from 'bwax/utils'

export default function resolveOrTraverse(o, func) {
    if (!o) return o
    const { done, value } = func(o)
    if (done) {
        return value
    } else {
        // console.log("Go inside", o)
        if(React.isValidElement(o)){
            return o
        } else if (Array.isArray(o)) {
            return o.map(e => resolveOrTraverse(e, func))

        } else if (typeof (o) === 'object') {
            return mapObj(o, v => resolveOrTraverse(v, func))
        } else {
            return o
        }
    }
}