

// constrolled the RecordFilterInput

import React, { useEffect, useState } from 'react';

import filter_condition_helper from "Client/ml/helpers/filter_condition_helper.bs"

import RecordFilterInput from './RecordFilterInput';

import isEqual from "lodash/isEqual";

export default function FilterConditionInput (props) {

    const { params, value, onChange, allEntities, allDataTypes } = props;

    const { conditionFields, entityName } = params;

    const [ controlled, setControlled ] =  useState(
        filter_condition_helper.fromFilterCondition(value)
    )
    const [ updatedValue, setUpdatedValue ] = useState(value);

    useEffect(() => {
        if(!isEqual(value, updatedValue) || value === undefined) {
            setUpdatedValue(value);
            setControlled(filter_condition_helper.fromFilterCondition(value));
        }
    }, [value] )

    const doChange = v => {
        const updatedValue = filter_condition_helper.toFilterCondition(v);
        setControlled(v);
        setUpdatedValue(updatedValue)

        if(!isEqual(value, updatedValue)) {
            onChange(updatedValue)
        }
       
    }

    return (
        <RecordFilterInput {...{
            params: { conditionFields, entityName },
            value: controlled,
            onChange: doChange,
            allEntities, allDataTypes
        }} />
    )



}