
import React from 'react'

/// prepare the widget values: => return undefined | {}
const prepare = d => {
    //// type : string?
    //// element : react-element?
    //// params: object
    //// hidden: true / false
    //// component: react-component?
    if (d === undefined || d === null) {
        return undefined
    }

    if (typeof (d) === 'string') {
        return {
            type_: d,
            params: {},
            hidden: false
        }
    }

    const actualDef = d.widget ? d.widget : d

    if (typeof actualDef === 'string') {
        const type_ = actualDef
        return {
            type_,
            hidden: false,
            params: {}
        }
    }

    const { type, type_, hidden, ...remaining } = actualDef
    const params = remaining.params ? remaining.params : remaining

    return {
        type_: (type_ || type), hidden, params
    }
}

export default function normalizeWidgetDef (widget) {
    return (
        Array.isArray(widget) ?
            widget.filter(d => !!d).map(prepare) :
            [ prepare(widget) ]
    ).filter(x => !!x)
} 