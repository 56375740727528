// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BaseUI$BwaxAdmin = require("../../../../lib/bwax-ui/re/BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var PosterCanvas$BwaxAdmin = require("../../components/PosterCanvas.bs.js");
var PosterPreviewBtn$BwaxAdmin = require("../../components/PosterPreviewBtn.bs.js");

import './Display_PosterSetting.less' 
;

function Display_PosterSetting(Props) {
  var params = Props.params;
  var theme = Props.theme;
  var previewAvatar = params.previewAvatar;
  var previewNickname = params.previewNickname;
  var value = params.value;
  var id = params.id;
  var noDecode = function (j) {
    return Caml_option.some(j);
  };
  var backgroundImage = PosterCanvas$BwaxAdmin.fieldValue(value, "backgroundImage", noDecode);
  var avatarOptions = PosterCanvas$BwaxAdmin.fieldValue(value, "avatarOptions", noDecode);
  var qrCodeOptions = PosterCanvas$BwaxAdmin.fieldValue(value, "qrCodeOptions", noDecode);
  var nicknameOptions = PosterCanvas$BwaxAdmin.fieldValue(value, "nickNameOptions", noDecode);
  return BaseUI$BwaxAdmin.column(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                    297481091,
                    /* `rem */Caml_chrome_debugger.polyVar("rem", [
                        5691738,
                        1.0
                      ])
                  ]),
                /* [] */0
              ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                (function (param, param$1, param$2, key) {
                    return React.createElement(PosterCanvas$BwaxAdmin.make, {
                                backgroundImage: backgroundImage,
                                avatarOptions: avatarOptions,
                                nicknameOptions: nicknameOptions,
                                qrCodeOptions: qrCodeOptions,
                                editingArea: undefined,
                                previewAvatar: previewAvatar,
                                previewNickname: previewNickname,
                                onAreaSelected: (function (param) {
                                    return /* () */0;
                                  }),
                                width: 240.0,
                                key: key
                              });
                  }),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    (function (param, param$1, param$2, key) {
                        return React.createElement(PosterPreviewBtn$BwaxAdmin.make, {
                                    setting: value,
                                    id: id,
                                    key: key
                                  });
                      }),
                    /* [] */0
                  ])
              ]), theme, /* row */5693978, /* [] */0, "nokey");
}

var make = Display_PosterSetting;

exports.make = make;
/*  Not a pure module */
