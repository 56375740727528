
import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import invariant from 'invariant';

import page_entry_slim from 'bwax-ui/ml/page_entry_slim.bs'

import queryUser from "bwax/query/queyUser"

import { resolveDependenciesAndPrepareData } from 'bwax/BwaxExecHelper';

import { setupDataQueryRunner } from 'bwax/query/runClientQuery';

import setupQueryCache from 'bwax/query/setupQueryCache';

// for general setting value:
export default async function evaluateGeneralValue(
        { domainEnv, webEnv, dlc, entity_dict, data_type_dict, base_env, dts, ast: [n, defs]},
    ) {

    // prepare user and then combine the paramters.

    const getUser = async userDeps => {
        const [_tree, paths, selectionText, _deps] = userDeps;
        if (!paths || paths.length == 0) {
            return {}
        }
        const user = await queryUser(dlc, selectionText);
        if (!user) {
            console.error("User is not logged in");
        }

        return user;
    }

    const getRecord = () => { invariant(false, "Invalid get record here") };

    const [dependedData, error] = await resolveDependenciesAndPrepareData([n, defs], {
        getRecord, getUser,
        entity_dict, data_type_dict, domainEnv, dts
    })
    // 
    invariant(!error, "Error happened during data preparation: " + error);

    const env = lang_entry_slim.evaluate_defs(base_env, [n, defs]);;

    const params = lang_entry_slim.array_to_list([
        // lang_entry_slim.transfrom_json_to_assoc_string_list_value(match.params || {}),
        dependedData
    ]);

    return new Promise((resolve, reject) => {
        page_entry_slim.execute_to_view(
            setupDataQueryRunner(dlc),
            setupQueryCache(dlc.appData), // no cache
            domainEnv,
            webEnv,
            entity_dict,
            data_type_dict,
            dts,
            env,
            params,
            (result, error) => {
                resolve([result, error]);
            }        
        )
    })

}


