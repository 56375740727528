
import memoizeOne from 'memoize-one';
import React, { createElement, PureComponent, cloneElement } from 'react';
import { cancelTimeout, requestTimeout } from './timer';

import { ConsoleErrorListener } from 'antlr4/error/ErrorListener';

// ScrollToAlign  -> 'auto' | 'center' | 'start' | 'end'
// Direction  -> 'horizontal' | 'vertical'
// ScrollDirection -> 'forward' | 'backward'

/**
 * props: Props = {
    children: RenderComponent<T>,
    className?: string,
    direction: Direction,
    height: number | string,
    initialScrollOffset?: number,
    innerRef?: any,
    innerElementType?: React$ElementType,
    innerTagName?: string, // deprecated
    itemCount: number,
    itemData: T,
    itemKey?: (index: number, data: T) => any,
    itemSize: itemSize,
    onItemsRendered?: onItemsRenderedCallback,
    onScroll?: onScrollCallback,
    outerRef?: any,
    outerElementType?: React$ElementType,
    outerTagName?: string, // deprecated
    overscanCount: number,
    style?: Object,
    useIsScrolling: boolean,
    width: number | string,
};
**/

const IS_SCROLLING_DEBOUNCE_INTERVAL = 150;

export const defaultItemKey = (index, data) => index;

export default function createListComponent({
    getItemOffset,
    getEstimatedTotalSize,
    getItemSize,
    getOffsetForIndexAndAlignment,
    getStartIndexForOffset,
    getStopIndexForStartIndex,
    initInstanceProps,
    shouldResetStyleCacheOnItemSizeChange,
    validateProps,
}) {
    return class List extends PureComponent {
        _instanceProps = initInstanceProps(this.props, this);
        _resetIsScrollingTimeoutId = null;

        static defaultProps = {
            direction: 'vertical',
            itemData: undefined,
            overscanCount: 2,
            useIsScrolling: false,
        };

        state = {
            isScrolling: false,
            scrollDirection: 'forward',
            scrollOffset:
                typeof this.props.initialScrollOffset === 'number'
                    ? this.props.initialScrollOffset
                    : 0,
            scrollUpdateWasRequested: false,
        };

        // Always use explicit constructor for React components.
        // It produces less code after transpilation. (#26)
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);
        }

        static getDerivedStateFromProps(
            props,
            state
        ) {
            validateSharedProps(props);
            validateProps(props);
            return null;
        }

        scrollTo(scrollOffset) {
            this.setState(
                prevState => ({
                    scrollDirection:
                        prevState.scrollOffset < scrollOffset ? 'forward' : 'backward',
                    scrollOffset: scrollOffset,
                    scrollUpdateWasRequested: true,
                }),
                this._resetIsScrollingDebounced
            );
        }

        scrollToItem(index, align = 'auto') {
            const { scrollOffset } = this.state;
            const nextScrollOffset = getOffsetForIndexAndAlignment(
                this.props,
                index,
                align,
                scrollOffset,
                this._instanceProps
            )
            this.scrollTo(Math.max(nextScrollOffset, 0));
        }

        componentDidMount() {
            const { initialScrollOffset, direction } = this.props;

            if (typeof initialScrollOffset === 'number' && this._outerRef !== null) {
                const element = this._outerRef
                if (direction === 'horizontal') {
                    element.scrollLeft = initialScrollOffset;
                } else {
                    element.scrollTop = initialScrollOffset;
                }
            }

            this._callPropsCallbacks();
            // console.log("Commit hook called when mounted")
            this._commitHook();

        }

        componentDidUpdate() {
            const { direction } = this.props;
            const { scrollOffset, scrollUpdateWasRequested } = this.state;
            if (scrollUpdateWasRequested && this._outerRef !== null) {
                const element = this._outerRef
                if (direction === 'horizontal') {
                    element.scrollLeft = scrollOffset;
                } else {
                    element.scrollTop = scrollOffset;
                }
            }

            this._callPropsCallbacks();

            const estimatedTotalSize = getEstimatedTotalSize(
                this.props,
                this._instanceProps
            );

            // console.log("Commit hook called when updated", estimatedTotalSize)
            this._commitHook();
        }

        componentWillUnmount() {
            if (this._resetIsScrollingTimeoutId !== null) {
                cancelTimeout(this._resetIsScrollingTimeoutId);
            }

            this._unmountHook();
        }

        render() {
            const {
                className,
                direction,
                height,
                innerRef,
                innerElementType,
                innerTagName,
                outerElementType,
                outerTagName,
                style,
                width,
                fixedLeftRow,
                fixedRightRow,
                fixedLeftRef,
                fixedRightRef,
                fixedLeftStyle,
                fixedRightStyle
            } = this.props;
            const { isScrolling } = this.state;

            const onScroll =
                direction === 'vertical'
                    ? this._onScrollVertical
                    : this._onScrollHorizontal;

            const items = this._renderItems();
            const fixedLeftItems = this._renderFixedItems(fixedLeftRow)
            const fixedRightItems = this._renderFixedItems(fixedRightRow)
            // Read this value AFTER items have been created,
            // So their actual sizes (if variable) are taken into consideration.

            const estimatedTotalSize = getEstimatedTotalSize(
                this.props,
                this._instanceProps
            );
            // console.log("ESIT", estimatedTotalSize);

            const heightStyle = { height } // typeof(height) === 'number' ? { height } : {};

            const getNoFixedItemsStyle = (fixedItems) => {
                return fixedItems.length > 0 ? {} : { width: 0, height: 0, opacity: 0 }
            }

            // console.log(">>", {
            //     className,
            //     onScroll,
            //     ref: this._outerRefSetter,
            //     style: {
            //         ...heightStyle,
            //         width,
            //         overflow: 'auto',
            //         position: 'relative',
            //         WebkitOverflowScrolling: 'touch',
            //         willChange: 'transform',
            //         ...style,
            //     },
            // }, style)


            // console.log("1", {
            //     innerElementType,
            //     innerTagName,
            //     fixItems: fixedLeftItems,
            //     direction,
            //     isScrolling,
            //     estimatedTotalSize,
            //     className: "fixed-left-items",
            //     style: {
            //         left: 0,
            //         ...fixedLeftStyle,
            //         willChange: 'transform',
            //         ...getNoFixedItemsStyle(fixedLeftItems)
            //     }
            // });


            // console.log("2", {
            //     innerElementType,
            //     innerTagName,
            //     fixItems: fixedRightItems,
            //     direction,
            //     isScrolling,
            //     estimatedTotalSize,
            //     style: {
            //         right: 0,
            //         ...fixedRightStyle,
            //         willChange: 'transform',
            //         ...getNoFixedItemsStyle(fixedRightItems)
            //     }
            // })


            return (
                <div style={{ position: 'relative' }}>
                    {
                        createElement(
                            outerElementType || outerTagName || 'div',
                            {
                                className,
                                onScroll,
                                ref: this._outerRefSetter,
                                style: {
                                    ...heightStyle,
                                    width,
                                    overflow: 'auto',
                                    position: 'relative',
                                    WebkitOverflowScrolling: 'touch',
                                    willChange: 'transform',
                                    ...style,
                                },
                            },
                            createElement(innerElementType || innerTagName || 'div', {
                                children: items,
                                ref: innerRef,
                                style: {
                                    height: direction === 'horizontal' ? '100%' : estimatedTotalSize,
                                    pointerEvents: isScrolling ? 'none' : '',
                                    width: direction === 'horizontal' ? estimatedTotalSize : '100%',
                                },
                            })
                        )
                    }
                    <FixedComponent
                        fixedRefSetter={ref => this._fixedRefSetter(ref, fixedLeftRef)}
                        {...{
                            innerElementType,
                            innerTagName,
                            fixItems: fixedLeftItems,
                            direction,
                            isScrolling,
                            estimatedTotalSize,
                            className: "fixed-left-items",
                            style: {
                                left: 0,
                                ...fixedLeftStyle,
                                willChange: 'transform',
                                ...getNoFixedItemsStyle(fixedLeftItems)
                            }
                        }}
                    />
                    <FixedComponent
                        fixedRefSetter={ref => this._fixedRefSetter(ref, fixedRightRef)}
                        {...{
                            innerElementType,
                            innerTagName,
                            fixItems: fixedRightItems,
                            direction,
                            isScrolling,
                            estimatedTotalSize,
                            style: {
                                right: 0,
                                ...fixedRightStyle,
                                willChange: 'transform',
                                ...getNoFixedItemsStyle(fixedRightItems)
                            }
                        }}
                    />
                </div>
            )
        }

        _callOnItemsRendered = memoizeOne(
            (
                overscanStartIndex,
                overscanStopIndex,
                visibleStartIndex,
                visibleStopIndex
            ) =>
                this.props.onItemsRendered({
                    overscanStartIndex,
                    overscanStopIndex,
                    visibleStartIndex,
                    visibleStopIndex,
                })
        );

        _callOnScroll = memoizeOne(
            (
                scrollDirection,
                scrollOffset,
                scrollUpdateWasRequested
            ) =>
                this.props.onScroll({
                    scrollDirection,
                    scrollOffset,
                    scrollUpdateWasRequested,
                })
        );

        _callPropsCallbacks() {
            if (typeof this.props.onItemsRendered === 'function') {
                const { itemCount } = this.props;
                if (itemCount > 0) {
                    const [
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex,
                    ] = this._getRangeToRender();
                    this._callOnItemsRendered(
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex
                    );
                }
            }

            if (typeof this.props.onScroll === 'function') {
                const {
                    scrollDirection,
                    scrollOffset,
                    scrollUpdateWasRequested,
                } = this.state;
                this._callOnScroll(
                    scrollDirection,
                    scrollOffset,
                    scrollUpdateWasRequested
                );
            }
        }

        // This method is called after mount and update.
        // List implementations can override this method to be notified.
        _commitHook() { }

        // This method is called before unmounting.
        // List implementations can override this method to be notified.
        _unmountHook() { }

        // Lazily create and cache item styles while scrolling,
        // So that pure component sCU will prevent re-renders.
        // We maintain this cache, and pass a style prop rather than index,
        // So that List can clear cached styles and force item re-render if necessary.

        get deleteItemStyleCache() {
            return this._deleteItemStyleCache;
        }
        set deleteItemStyleCache(value) {
            this._deleteItemStyleCache = value;
        }

        _deleteItemStyleCache = (index, key) => {
            // const cacheKey = index + "::" + key;
            const cacheKey = index;
            delete this._itemStyleCache[cacheKey];
        };

        _getItemStyle = (index, key, data) => {
            const { direction, itemSize } = this.props;

            // const cacheKey = index + "::" + key;
            const cacheKey = index;

            const itemStyleCache = this._getItemStyleCache(
                shouldResetStyleCacheOnItemSizeChange && itemSize,
                shouldResetStyleCacheOnItemSizeChange && direction
            );
            let style;
            if (itemStyleCache.hasOwnProperty(cacheKey)) {
                style = itemStyleCache[cacheKey];
            } else {
                const offset = getItemOffset(this.props, index, this._instanceProps);
                const size = getItemSize(this.props, index, this._instanceProps);

                itemStyleCache[cacheKey] = style = {
                    position: 'absolute',
                    left: direction === 'horizontal' ? offset : 0,
                    top: direction === 'vertical' ? offset : 0,
                    height: direction === 'vertical' ? size : '100%',
                    width: direction === 'horizontal' ? size : '100%',
                };
            }

            return style;
        };

        // TODO This memoized getter doesn't make much sense.
        // If all that's really needed is for the impl to be able to reset the cache,
        // Then we could expose a better API for that.
        _getItemStyleCache = memoizeOne((_, __) => {
            this._itemStyleCache = {};

            return this._itemStyleCache;
        });

        _getRangeToRender() {
            const { itemCount, overscanCount } = this.props;
            const { isScrolling, scrollDirection, scrollOffset } = this.state;

            if (itemCount === 0) {
                return [0, 0, 0, 0];
            }

            const startIndex = getStartIndexForOffset(
                this.props,
                scrollOffset,
                this._instanceProps
            );
            const stopIndex = getStopIndexForStartIndex(
                this.props,
                startIndex,
                scrollOffset,
                this._instanceProps
            );

            // Overscan by one item in each direction so that tab/focus works.
            // If there isn't at least one extra item, tab loops back around.
            const overscanBackward =
                !isScrolling || scrollDirection === 'backward'
                    ? Math.max(1, overscanCount)
                    : 1;
            const overscanForward =
                !isScrolling || scrollDirection === 'forward'
                    ? Math.max(1, overscanCount)
                    : 1;

            return [
                Math.max(0, startIndex - overscanBackward),
                Math.max(0, Math.min(itemCount - 1, stopIndex + overscanForward)),
                startIndex,
                stopIndex,
            ];
        }

        _renderItems() {
            const {
                children,
                itemCount,
                itemData,
                itemKey = defaultItemKey,
                useIsScrolling,
            } = this.props;
            const { isScrolling } = this.state;

            const [startIndex, stopIndex] = this._getRangeToRender();

            const items = [];
            if (itemCount > 0) {
                for (let index = startIndex; index <= stopIndex; index++) {
                    items.push(
                        cloneElement(children, {
                            data: itemData,
                            key: itemKey(index, itemData),
                            index,
                            isScrolling: useIsScrolling ? isScrolling : undefined,
                            style: this._getItemStyle(index, itemKey(index, itemData), itemData),
                        })
                    );
                }
            }
            return items;
        }

        _renderFixedItems() {
            return []
        }

        _onScrollHorizontal = (event) => {
            const { scrollLeft } = event.currentTarget;
            console.log("On Scroll Horizontal")
            this.setState(prevState => {
                if (prevState.scrollOffset === scrollLeft) {
                    // Scroll position may have been updated by cDM/cDU,
                    // In which case we don't need to trigger another render,
                    // And we don't want to update state.isScrolling.
                    return null;
                }

                return {
                    isScrolling: true,
                    scrollDirection:
                        prevState.scrollOffset < scrollLeft ? 'forward' : 'backward',
                    scrollOffset: scrollLeft,
                    scrollUpdateWasRequested: false,
                };
            }, this._resetIsScrollingDebounced);
        };

        _onScrollVertical = (event) => {
            const { scrollTop } = event.currentTarget;
            // console.log("On Scroll Vertical", scrollTop);
            this.setState(prevState => {
                if (prevState.scrollOffset === scrollTop) {
                    // Scroll position may have been updated by cDM/cDU,
                    // In which case we don't need to trigger another render,
                    // And we don't want to update state.isScrolling.
                    return null;
                }

                return {
                    isScrolling: true,
                    scrollDirection:
                        prevState.scrollOffset < scrollTop ? 'forward' : 'backward',
                    scrollOffset: scrollTop,
                    scrollUpdateWasRequested: false,
                };
            }, this._resetIsScrollingDebounced);
        };

        _outerRefSetter = (ref) => {
            const { outerRef } = this.props;

            this._outerRef = ref

            if (typeof outerRef === 'function') {
                outerRef(ref);
            } else if (
                outerRef != null &&
                typeof outerRef === 'object' &&
                outerRef.hasOwnProperty('current')
            ) {
                outerRef.current = ref;
            }
        };

        _fixedRefSetter = (ref, fixedRef) => {
            if (typeof fixedRef === 'function') {
                fixedRef(ref);
            } else if (
                fixedRef != null &&
                typeof fixedRef === 'object' &&
                fixedRef.hasOwnProperty('current')
            ) {
                fixedRef.current = ref;
            }
        }

        _resetIsScrollingDebounced = () => {
            if (this._resetIsScrollingTimeoutId !== null) {
                cancelTimeout(this._resetIsScrollingTimeoutId);
            }

            this._resetIsScrollingTimeoutId = requestTimeout(
                this._resetIsScrolling,
                IS_SCROLLING_DEBOUNCE_INTERVAL
            );
        };

        _resetIsScrolling = () => {
            this._resetIsScrollingTimeoutId = null;

            this.setState({ isScrolling: false }, () => {
                // Clear style cache after state update has been committed.
                // This way we don't break pure sCU for items that don't use isScrolling param.
                this._getItemStyleCache(-1, null);
            });
        };

        // Intentionally placed after all other instance properties have been initialized,
        // So that DynamicSizeList can override the render behavior.
        _instanceProps = initInstanceProps(this.props, this);
    };
}

class FixedComponent extends React.PureComponent {
    
    render() {
        const {
            fixedRefSetter,
            innerElementType,
            innerTagName,
            fixItems,
            direction,
            isScrolling,
            estimatedTotalSize,
            style,
            className,
        } = this.props
        return (
            <div
                ref={fixedRefSetter}
                className={`fixed-items ${className || ""}`}
 
                style={{
                    position: 'absolute',
                    top: 0,
                    background: 'white',
                    overflow: 'auto',
                    ...style
                }}>
                {
                    fixItems.length > 0 ? createElement(innerElementType || innerTagName || 'div', {
                        children: fixItems,
                        style: {
                            height: direction === 'horizontal' ? '100%' : estimatedTotalSize,
                            pointerEvents: isScrolling ? 'none' : '',
                            width: direction === 'horizontal' ? estimatedTotalSize : '100%',
                        },
                    }) : null
                }
            </div>
        )
    }
}

// NOTE: I considered further wrapping individual items with a pure ListItem component.
// This would avoid ever calling the render function for the same index more than once,
// But it would also add the overhead of a lot of components/fibers.
// I assume people already do this (render function returning a class component),
// So my doing it would just unnecessarily double the wrappers.

const validateSharedProps = ({
    children,
    direction,
    height,
    innerTagName,
    outerTagName,
    width,
}) => {
    if (process.env.NODE_ENV !== 'production') {
        if (innerTagName != null || outerTagName != null) {
            console.warn(
                'The innerTagName and outerTagName props have been deprecated. ' +
                'Please use the innerElementType and outerElementType props instead.'
            );
        }

        if (direction !== 'horizontal' && direction !== 'vertical') {
            throw Error(
                'An invalid "direction" prop has been specified. ' +
                'Value should be either "horizontal" or "vertical". ' +
                `"${direction}" was specified.`
            );
        }

        if (children == null) {
            throw Error(
                'An invalid "children" prop has been specified. ' +
                'Value should be a React component. ' +
                `"${children === null ? 'null' : typeof children}" was specified.`
            );
        }

        if (direction === 'horizontal' && typeof width !== 'number') {
            throw Error(
                'An invalid "width" prop has been specified. ' +
                'Horizontal lists must specify a number for width. ' +
                `"${width === null ? 'null' : typeof width}" was specified.`
            );
        } else if (direction === 'vertical' && (typeof height !== 'number' && typeof height !== "string" )) {
            throw Error(
                'An invalid "height" prop has been specified. ' +
                'Vertical lists must specify a number for height. ' +
                `"${height === null ? 'null' : typeof height}" was specified.`
            );
        }
    }
};
