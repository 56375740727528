// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var WithRecord = require("./dataui/WithRecord");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var WithRecordList = require("./dataui/WithRecordList");
var Query_builder$BwaxAdmin = require("../../../../bwax-js/ml/query/query_builder.bs.js");
var Widget_common$BwaxAdmin = require("../../mod/widget/widget_common.bs.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Widget_mod_data_ui$BwaxAdmin = require("../../mod/widget/widget_mod_data_ui.bs.js");
var Record_dependency_resolver$BwaxAdmin = require("../../../../bwax-js/ml/lang/record_dependency_resolver.bs.js");
var RecordListWithPullUpLoadMore = require("./dataui/RecordListWithPullUpLoadMore");

function withRecord(prim) {
  return WithRecord.create(prim);
}

function withRecordList(prim) {
  return WithRecordList.create(prim);
}

function recordListWithPullUpLoadMore(prim) {
  return RecordListWithPullUpLoadMore.create(prim);
}

function transform_record(entity_dict, data_type_dict, entity_name, json) {
  var found_entity = Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.find((function (param) {
              return param[0] === entity_name;
            }), entity_dict));
  if (found_entity !== undefined) {
    var entity = found_entity;
    var param = entity_dict;
    var param$1 = data_type_dict;
    var param$2 = Query_builder$BwaxAdmin.default_transform_option;
    var param$3 = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
    var param$4 = json;
    return Query_builder$BwaxAdmin.transform_record_value((function (transform, field, linked_entity, json) {
                  var match = Js_json.classify(json);
                  if (typeof match !== "number") {
                    switch (match.tag | 0) {
                      case /* JSONString */0 :
                          return Runtime_common$BwaxAdmin.pack_string(match[0]);
                      case /* JSONObject */2 :
                          var all_fields = Pervasives.$at(linked_entity[/* fields */2], linked_entity[/* virtual_fields */3]);
                          return Curry._3(transform, Query_builder$BwaxAdmin.default_transform_option, all_fields, json);
                      default:
                        
                    }
                  }
                  var all_fields$1 = Pervasives.$at(linked_entity[/* fields */2], linked_entity[/* virtual_fields */3]);
                  return Curry._3(transform, Query_builder$BwaxAdmin.default_transform_option, all_fields$1, json);
                }), param, param$1, param$2, param$3, param$4);
  } else {
    throw [
          Widget_common$BwaxAdmin.Widget_exn,
          "I can't find entity `" + (entity_name + "`")
        ];
  }
}

function to_element(entity_dict, data_type_dict, entity_name, json, render_fn) {
  var match = transform_record(entity_dict, data_type_dict, entity_name, json);
  var record;
  if (match !== undefined) {
    record = match;
  } else {
    throw [
          Widget_common$BwaxAdmin.Widget_exn,
          "Cant transform record"
        ];
  }
  var v = Lang_eval$BwaxAdmin.apply_value(undefined, render_fn, record, /* No_term */0);
  var match$1 = v[0];
  if (typeof match$1 === "number") {
    throw [
          Widget_common$BwaxAdmin.Widget_exn,
          "Render func does not yield element"
        ];
  } else if (match$1.tag === /* V_raw */7) {
    return match$1[0];
  } else {
    throw [
          Widget_common$BwaxAdmin.Widget_exn,
          "Render func does not yield element"
        ];
  }
}

function get_depended_paths_from_fn(entity_dict, data_type_dict, dts, entity_name, fn) {
  var deps = Record_dependency_resolver$BwaxAdmin.determine_function_dependencies(entity_dict, data_type_dict, dts, entity_name, fn);
  return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.hit((function (param) {
                    if (param !== undefined) {
                      var match = param;
                      if (match.tag) {
                        return ;
                      } else {
                        return Caml_option.some(match[1][1]);
                      }
                    }
                    
                  }), deps));
}

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent) {
  switch (rawContent.tag | 0) {
    case /* With_record */0 :
        var render_fn = rawContent[2];
        var match = rawContent[1];
        var not_found_el = match[2];
        var loading_el = match[1];
        var isLazy = match[0];
        var query_config = rawContent[0];
        return (function (theme, pc, prs, key) {
            var toReactElement = function (entity_dict, data_type_dict, entity_name, json) {
              var el = to_element(entity_dict, data_type_dict, entity_name, json, render_fn);
              return Curry._5(doRender, el, theme, pc, prs, key);
            };
            var get_depended_paths = function (entity_dict, data_type_dict, dts, entity_name) {
              return get_depended_paths_from_fn(entity_dict, data_type_dict, dts, entity_name, render_fn);
            };
            var loading = Plate$BwaxAdmin.$$Option.map((function (e) {
                    return Curry._5(doRender, e, theme, pc, prs, key);
                  }), loading_el);
            var notFound = Plate$BwaxAdmin.$$Option.map((function (e) {
                    return Curry._5(doRender, e, theme, pc, prs, key);
                  }), not_found_el);
            return WithRecord.create({
                        query_config: query_config,
                        get_depended_paths: get_depended_paths,
                        key: key,
                        isLazy: isLazy,
                        loading: loading,
                        notFound: notFound,
                        toReactElement: toReactElement,
                        facade: facade
                      });
          });
    case /* With_record_list */1 :
        var render_fn$1 = rawContent[3];
        var container_fn = rawContent[2];
        var match$1 = rawContent[1];
        var repeat = match$1[4];
        var offset = match$1[3];
        var pageSize = match$1[2];
        var loading_el$1 = match$1[1];
        var isLazy$1 = match$1[0];
        var query_config$1 = rawContent[0];
        return (function (theme, pc, prs, key) {
            var get_depended_paths = function (entity_dict, data_type_dict, dts, entity_name) {
              return get_depended_paths_from_fn(entity_dict, data_type_dict, dts, entity_name, render_fn$1);
            };
            var loading = Plate$BwaxAdmin.$$Option.map((function (e) {
                    return Curry._5(doRender, e, theme, pc, prs, key);
                  }), loading_el$1);
            var wrapWithContainer = function (el_array) {
              var l = Plate$BwaxAdmin.List.map((function (e) {
                      return /* tuple */[
                              /* V_raw */Caml_chrome_debugger.variant("V_raw", 7, [e]),
                              /* No_term */0
                            ];
                    }), Curry._1(Plate$BwaxAdmin.List.from_array, el_array));
              var els_000 = /* V_list */Caml_chrome_debugger.variant("V_list", 1, [l]);
              var els = /* tuple */[
                els_000,
                /* No_term */0
              ];
              var match = Lang_eval$BwaxAdmin.apply_value(undefined, container_fn, els, /* No_term */0);
              var match$1 = match[0];
              if (typeof match$1 === "number") {
                throw [
                      Widget_common$BwaxAdmin.Widget_exn,
                      "Container fn doesn't return a valid element"
                    ];
              } else if (match$1.tag === /* V_raw */7) {
                var el = Curry._1(doRender, match$1[0]);
                return Curry._4(el, theme, pc, prs, key);
              } else {
                throw [
                      Widget_common$BwaxAdmin.Widget_exn,
                      "Container fn doesn't return a valid element"
                    ];
              }
            };
            var toElement = function (entity_dict, data_type_dict, entity_name, json) {
              return to_element(entity_dict, data_type_dict, entity_name, json, render_fn$1);
            };
            return WithRecordList.create({
                        query_config: query_config$1,
                        get_depended_paths: get_depended_paths,
                        key: key,
                        isLazy: isLazy$1,
                        loading: loading,
                        pageSize: pageSize,
                        offset: offset,
                        repeat: repeat,
                        toElement: toElement,
                        wrapWithContainer: wrapWithContainer,
                        facade: facade
                      });
          });
    case /* Record_list_with_pull_up_load_more */2 :
        var render_fn$2 = rawContent[3];
        var container_fn$1 = rawContent[2];
        var match$2 = rawContent[1];
        var pageSize$1 = match$2[2];
        var loading_el$2 = match$2[1];
        var isLazy$2 = match$2[0];
        var query_config$2 = rawContent[0];
        return (function (theme, pc, prs, key) {
            var get_depended_paths = function (entity_dict, data_type_dict, dts, entity_name) {
              return get_depended_paths_from_fn(entity_dict, data_type_dict, dts, entity_name, render_fn$2);
            };
            var loading = Plate$BwaxAdmin.$$Option.map((function (e) {
                    return Curry._5(doRender, e, theme, pc, prs, key);
                  }), loading_el$2);
            var wrapWithContainer = function (el_array) {
              var l = Plate$BwaxAdmin.List.map((function (e) {
                      return /* tuple */[
                              /* V_raw */Caml_chrome_debugger.variant("V_raw", 7, [e]),
                              /* No_term */0
                            ];
                    }), Curry._1(Plate$BwaxAdmin.List.from_array, el_array));
              var els_000 = /* V_list */Caml_chrome_debugger.variant("V_list", 1, [l]);
              var els = /* tuple */[
                els_000,
                /* No_term */0
              ];
              var match = Lang_eval$BwaxAdmin.apply_value(undefined, container_fn$1, els, /* No_term */0);
              var match$1 = match[0];
              if (typeof match$1 === "number") {
                throw [
                      Widget_common$BwaxAdmin.Widget_exn,
                      "Container fn doesn't return a valid element"
                    ];
              } else if (match$1.tag === /* V_raw */7) {
                var el = Curry._1(doRender, match$1[0]);
                return Curry._4(el, theme, pc, prs, key);
              } else {
                throw [
                      Widget_common$BwaxAdmin.Widget_exn,
                      "Container fn doesn't return a valid element"
                    ];
              }
            };
            var toElement = function (entity_dict, data_type_dict, entity_name, json) {
              return to_element(entity_dict, data_type_dict, entity_name, json, render_fn$2);
            };
            return RecordListWithPullUpLoadMore.create({
                        query_config: query_config$2,
                        get_depended_paths: get_depended_paths,
                        key: key,
                        isLazy: isLazy$2,
                        loading: loading,
                        pageSize: pageSize$1,
                        toElement: toElement,
                        wrapWithContainer: wrapWithContainer,
                        loading_el: loading_el$2,
                        facade: facade
                      });
          });
    
  }
}

var module_name = Widget_mod_data_ui$BwaxAdmin.module_name;

exports.module_name = module_name;
exports.withRecord = withRecord;
exports.withRecordList = withRecordList;
exports.recordListWithPullUpLoadMore = recordListWithPullUpLoadMore;
exports.transform_record = transform_record;
exports.to_element = to_element;
exports.get_depended_paths_from_fn = get_depended_paths_from_fn;
exports.render_element = render_element;
/* Plate-BwaxAdmin Not a pure module */
