
import React, { useContext, useEffect, useState } from 'react';

import Loading from 'Client/js/components/Loading';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import BreadcrumbSimple from 'Client/js/breadcrumb/BreadcrumbSimple'
import EditTags from 'Client/js/components/form/baseInputs/EditTags';

import UpdateRecord from 'bwax-ui/legacy/page/actions/UpdateRecord';

import { runDataQuery_a } from 'bwax/query/runClientQuery';
// import 

import { evaluateWithQuery } from 'bwax';

import './LookupValueEdit.less'

const loadAllLookupTextValuesDef = {
    __query__: {
        data: {
            entityName: "选项文本值",
            criteria: {
            },
            type: "listAll"
        },
        __for__: {
            __apply__: {
                list: "${data}",
                map: {
                    category: "${value.类别.名称}",
                    values: "${value.值}",
                    id: "${value.id}"
                }
            }
        }
    }
};


export default function LookupValueEdit (props) {
    
    const breadcrumb = <BreadcrumbSimple {...{
        ...props,
        pathname: props.location.pathname
    }} />;

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext)

    const { lookupValueSettings, queryTarget, facade } = props;

    const allEntities = facade.entities;
    const allDataTypes = facade.dataTypes;

    const evaluate = (def, baseData) => {
        return evaluateWithQuery(
            def,
            baseData,
            { allDataTypes, allEntities, queryTarget, sessionToken, sandbox, tenantCode }
        )
    }

    const queryRunner = runDataQuery_a({ sessionToken, sandbox, tenantCode });

    const [ values, setValues ] = useState(undefined);

    useEffect(() => {
        (async () => {
            const result = await evaluate(loadAllLookupTextValuesDef, {});
            // 
            const newValues = result.reduce((acc, { category, values, id}) => {
                return {
                    ...acc,
                    [category]: [id, values]
                }
            }, {})

            setValues(prev => {
                return {
                    ...prev,
                    ...newValues
                }
            })

        })()

    }, [])

    function updateValues(category, id, values) {
        // 1. 更新本地 state
        // 2. 更新后台的 lookup value
        setValues(prev => ({ ...prev, [category]: [id, values] }));

        UpdateRecord({ allDataTypes, allEntities, queryRunner})({
            entityName: "选项文本值",
            id,
            formData: {
                值: values
            }
        })

    }

    function renderLookupValueEdit (lookupValueSetting) {
        const { category, valueType, multivalued } = lookupValueSetting;

        const categoryValues = values[category];

        if(valueType == "文本" && categoryValues) {

            const [ id, vs ] = categoryValues;

            return (
                <div key={category} className="category">
                    <div className="category-title">{ category }</div>
                    <EditTags 
                        value={vs}
                        onChange={vs => updateValues(category, id, vs)}
                    />
                </div>
            )
        } else {
            // 目前只支持文本
            null
        }
    }



    return (
        <div className={"admin--page-content"}>
            { breadcrumb }
            <div className={`page-content-body lookup-value-edit`}>            
                { values ? lookupValueSettings.map(renderLookupValueEdit ) : <Loading /> }
            </div>
        </div>
    )

}

