

import Number from './Number'
import ShortText from './ShortText'
import Text from './Text'
import Select from './Select'
import RecordSelect from './RecordSelect'
import Image from './Image'
import DatePicker from './DatePicker'
import RichText from './RichText'
import Switch from './Bool'
import File from './File'

import JSONInput from './JSONInput'

import RolePermissionInput from './RolePermissionInput'
const Input_RolePermission = require('Client/re/widgets/input/Input_RolePermission.bs').make;


import RecordFilterDisplayControl from './RecordFilterDisplayControl'

import FilterConditionInput from './FilterConditionInput'

const widgets = {

    Number,
    ShortText,
    Text,
    Select,
    RecordSelect,
    Image,
    DatePicker,
    RichText,
    Switch,
    File,
    
    RolePermissionInput,
    Input_RolePermission,

    RecordFilterDisplayControl,

    FilterConditionInput,

    JSONInput,
}


/// add prefix - admin
const PREFIX = "input"
const prefixed = Object.keys(widgets).reduce((acc, key) => {
    return { 
        ...acc,
        [`${PREFIX}::${key}`]: widgets[key]
    }
}, {}) 
export default prefixed