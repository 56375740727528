
import React, { useState, useContext, useEffect } from 'react';

import FacadeContext from 'bwax/util/FacadeContext';

import getImageURL from 'bwax-ui/getImageURL'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import Drawer from 'rsuite/Drawer';
import Button from 'rsuite/Button';

import { Image, Popconfirm } from "@arco-design/web-react";

import { Upload } from 'antd';

import { splitToChunks } from 'bwax/utils';

import { MdOutlineFileUpload, MdRemoveRedEye, MdContentCopy, MdDeleteOutline } from "react-icons/md"

import BeatLoader from "react-spinners/BeatLoader";

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import { setupDefinitionQueryRunner } from 'bwax/query/runClientQuery'

import file_upload from 'bwax-ui/ml/utils/file_upload.bs';

import "./ResourcePanel.less";

import Message from 'Client/js/ui/Message';

// import Popconfirm from 'bwax-ui/components/Popconfirm'

export default function ResourcePanel({ allowCopy = true, onSelect = _ = {} }) {

    const dlc = useContext(DataLoaderContext);

    // 先 upload 一个 file 得到 attachment；
    // 然后在把 attachment 塞到 resource 里去添加：

    const facadeInContext = useContext(FacadeContext);

    const [ facade, setFacade ] = useState(facadeInContext ? facadeInContext.facade : null);

    useEffect(() => {
        if(!facade) {
            const BwaxFacade = require("bwax/facade").default;        
            (async () => {
                const facade = new BwaxFacade({
                    queryRunner, dlc,
                    isDesignMode: true,
                    applicationCode: "Design",
                });
                await facade.init();
                await facade.prepareAll();
                setFacade(facade);
            })()
        }
    }, []);

    const queryRunner = setupDefinitionQueryRunner(dlc);

    const [uploading, setUploading] = useState(false);

    const [count, setCount] = useState(undefined);
    const [resources, setResources] = useState([]);

    const [previewImage, setPreviewImage] = useState(null);

    const loadData = async () => {

        const [ result, error ] = await facade.listAll({
            entityName: "素材",
            condition: [[
                { field: "类型", op: "eq", value: "图片" },
                { field: "归档", op: "ne", value: true }
            ]],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            fieldPaths: [
                "类型", "标签", "文件"
            ],
            pageSize: 50
        });
        if(error) {
            Message.error("加载数据出错");
        } else {

            setCount(result.count);
            setResources(result.data);
        }
 
    }

    useEffect(() => {
        if(facade) {
            (async () => {
                await facade.prepare(["素材"]);
                loadData();
            })();
        }
    }, [ facade ])

    const archiveFile = async id => {
        const [ _result, error ] = await facade.update({
            entityName: "素材",
            id,
            formData: {
                归档: true
            }
        })
        if(error) {
            Message.error("归档素材失败")
        } else {
            // reload images
            loadData()
        }
    }

    const uploadFile = async (file) => {
        setUploading(true)
        // preserve file name
        const [attachment, error] = await file_upload.upload_file(queryRunner, file, true, "design-resources", true);
        if (error) {
            Message.error("上传失败")
        } else {
            const [ result, error ] = await facade.add({
                entityName: "素材",
                formData: {
                    文件: attachment,
                    类型: "图片",
                    标签: []
                }
            })
            if(error) {
                Message.error("添加素材失败")
            } else {
                // reload images
                loadData()
            }
        }
        setUploading(false);

    }


    const getUploadButton = () => {
        return (
            <Upload.Dragger beforeUpload={(file, fileList) => {
                uploadFile(file);
                return false
            }} showUploadList={false} accept="image/*">
                <div style={{ opacity: 0.5, fontSize: 24, margin: "0.25rem 0rem" }} >
                    {uploading ?
                        <BeatLoader speedMultiplier={0.8} size={8} /> :
                        <MdOutlineFileUpload />
                    }
                </div>
            </Upload.Dragger>
        )
    };


    // 
    const rows = splitToChunks(["add", ...resources], 3, { fillWith: null });


    function renderResource(resource, i) {

        const renderInner = () => {
            if(resource === "add") {
                return (
                    <div className="cell">
                        { getUploadButton() }
                    </div>
                )
            }

            if(resource) {
                const code = `imgURL = "${resource["文件"].url}";`
                return (
                    <div className="cell" style={{
                        backgroundImage: `url(${getImageURL(resource["文件"], "small")})`
                    }}>
                        <div className="mask" onClick={e => {
                            if(e.target == e.currentTarget){
                                e.stopPropagation();
                                onSelect(resource);
                            }                        
                        }}>
                            {
                                allowCopy ? (
                                    <CopyToClipboard text={code} onCopy={() => {
                                        Message.success(`已复制 ${code}`)
                                    }}>
                                        <MdContentCopy />
                                    </CopyToClipboard>
                                ) : null
                            }
                            
                            <MdRemoveRedEye onClick={e => {
                                e.stopPropagation();
                                setPreviewImage(resource["文件"]);
                            }} />
                            <Popconfirm title="隐藏该素材？" okText="确定" cancelText="取消" 
                                onOk={() => {
                                    archiveFile(resource.id);
                                }}
                            >
                                <span style={{ fontSize: 13, display: "inline-flex"}}><MdDeleteOutline /></span>
                            </Popconfirm>
                        
                        </div>
                    </div>
                )

            } else {
                return (
                    <div className="cell">
                    </div>
                )
            }    
        }

        return (
            <div key={resource ? (resource.id || "add") : i} className="cell-wrapper">
                { renderInner() }
            </div>
        )

    }

    return (
        <div className="design--resource-panel">
            {/* <div className="toolbar">
            {getUploadButton()}
            </div> */}
            <div className="resource-list">
                { rows.map ((r, i) => (
                    <div className="row" key={i}>
                        { r.map ((c, i) => renderResource(c, i))}
                    </div>
                ))}
            </div>
            {
                previewImage ? (
                    <Image.Preview
                        src={getImageURL(previewImage, "medium")}
                        visible={true}
                        onVisibleChange={v => {
                            if(!v) { setPreviewImage(null) }
                        }}
                    />
                ) : null
            }
        </div>
    )
}

export function ResourcePanelButton() {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Button onClick={_ => setOpen(true)}>素材库</Button>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <Drawer.Body>
                    <ResourcePanel />
                </Drawer.Body>
            </Drawer>
        </>
    )
}

