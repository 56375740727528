// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("./BaseUI.bs.js");
var Select$BwaxAdmin = require("./Select.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

import './MiscUI.less'
;

function MiscUI$Newsflash(Props) {
  var els = Props.els;
  var duration = Props.duration;
  var theme = Props.theme;
  var pc = Props.pc;
  var prs = Props.prs;
  var match = React.useState((function () {
          return 0;
        }));
  var setIndex = match[1];
  React.useEffect((function () {
          var timerId = setInterval((function (param) {
                  return Curry._1(setIndex, (function (prev) {
                                if (prev >= (Belt_List.length(els) - 1 | 0)) {
                                  return 0;
                                } else {
                                  return prev + 1 | 0;
                                }
                              }));
                }), duration);
          return (function (param) {
                    clearInterval(timerId);
                    return /* () */0;
                  });
        }), ([]));
  var inner = Belt_List.toArray(Belt_List.mapWithIndex(els, (function (idx, el) {
              return Curry._4(el, theme, /* column */-963948842, /* [] */0, String(idx));
            })));
  var toCss = function (r) {
    return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_column */2, pc, prs);
  };
  var rawClassName = BaseUI$BwaxAdmin.buildClassName("column ctnr", /* [] */0, toCss);
  var txClassName = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.selector("> *", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.transform(Css.translateY(Css.pct(-100.0 * match[0]))),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.transitionDuration(300),
                      /* [] */0
                    ])
                ])),
          /* [] */0
        ]));
  var className = Css.merge(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          rawClassName,
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              txClassName,
              /* [] */0
            ])
        ]));
  return BaseUI$BwaxAdmin.renderWithClassName(className, inner, /* [] */0, theme, pc, "nokey");
}

var Newsflash = {
  make: MiscUI$Newsflash
};

function newsflash(rules, duration, els, theme, pc, prs, key) {
  var toCss = function (r) {
    return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_column */2, pc, prs);
  };
  var inner = React.createElement(MiscUI$Newsflash, {
        els: els,
        duration: duration,
        theme: theme,
        pc: /* box */4898315,
        prs: rules
      });
  return BaseUI$BwaxAdmin.renderEl("newsflash ctnr", rules, key, theme, pc, toCss, inner);
}

function select(rules, options, selected, onChange, disabled, placeholder, theme, pc, prs, key) {
  var jsOptions = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (param) {
              return {
                      label: param[0],
                      value: param[1],
                      isDisabled: param[2]
                    };
            }), options));
  var selectedValue = Plate$BwaxAdmin.List.find((function (param) {
          return Caml_obj.caml_equal(param[1], selected);
        }), options);
  var placeholder$1 = Belt_Option.getWithDefault(placeholder, "请选择");
  var rendered = React.createElement(Select$BwaxAdmin.make, {
        rules: rules,
        options: jsOptions,
        selected: Plate$BwaxAdmin.$$Option.map((function (sv) {
                return {
                        label: sv[0],
                        value: sv[1],
                        isDisabled: sv[2]
                      };
              }), selectedValue),
        onChange: (function (mv) {
            return Curry._1(onChange, Plate$BwaxAdmin.$$Option.map((function (v) {
                              return v.value;
                            }), mv));
          }),
        disabled: disabled,
        placeholder: placeholder$1,
        theme: theme,
        pc: pc,
        prs: prs,
        key: key
      });
  return BaseUI$BwaxAdmin.wrapForAlignment(rendered, rules[/* containerRules */0], pc, key);
}

function toDomEl(theRef) {
  return Caml_option.nullable_to_opt(theRef.current);
}

function MiscUI$ChooseFile(Props) {
  var rules = Props.rules;
  var onChoose = Props.onChoose;
  var el = Props.el;
  var accept = Props.accept;
  var theme = Props.theme;
  var pc = Props.pc;
  var prs = Props.prs;
  var inner = Curry._4(el, theme, /* box */4898315, /* [] */0, "no-key");
  var inputRef = React.useRef(null);
  var toCss = function (r) {
    return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var allRules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* pointer */-786317123,
      rules
    ]);
  var className = BaseUI$BwaxAdmin.buildClassName("choose-file ctnr", allRules, toCss);
  var selectFile = function (e) {
    var match = inputRef.current;
    if (match == null) {
      return /* () */0;
    } else {
      return match.click();
    }
  };
  var fileSelected = function (e) {
    var target = e.target;
    var files = target.files;
    if (files.length > 0) {
      return Curry._1(onChoose, Caml_option.some(Belt_Array.get(files, 0)));
    } else {
      return Curry._1(onChoose, undefined);
    }
  };
  var rendered = React.createElement("div", {
        className: className,
        onClick: selectFile
      }, inner, accept !== undefined ? React.createElement("input", {
              ref: inputRef,
              className: "hidden-input",
              accept: accept,
              type: "file",
              onChange: fileSelected
            }) : React.createElement("input", {
              ref: inputRef,
              className: "hidden-input",
              type: "file",
              onChange: fileSelected
            }));
  return BaseUI$BwaxAdmin.wrapForAlignment(rendered, rules, pc, "no-key");
}

var ChooseFile = {
  make: MiscUI$ChooseFile
};

function chooseFile(rules, onChoose, accept, el, theme, pc, prs, key) {
  return React.createElement(MiscUI$ChooseFile, {
              rules: rules,
              onChoose: onChoose,
              el: el,
              accept: accept,
              theme: theme,
              pc: pc,
              prs: rules,
              key: key
            });
}

exports.Newsflash = Newsflash;
exports.newsflash = newsflash;
exports.select = select;
exports.toDomEl = toDomEl;
exports.ChooseFile = ChooseFile;
exports.chooseFile = chooseFile;
/*  Not a pure module */
