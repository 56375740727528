// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var BwaxUi = require("bwax-ui");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../../lib/bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var ColorTypes$BwaxAdmin = require("../../../../lib/bwax-ui/re/ColorTypes.bs.js");
var Lang_mod_json$BwaxAdmin = require("../../../../lib/bwax-js/ml/lang/mod/builtin/lang_mod_json.bs.js");
var Widget_common$BwaxAdmin = require("../../../../lib/bwax-ui/ml/mod/widget/widget_common.bs.js");
var Fragment_helper$BwaxAdmin = require("../../../../lib/bwax-js/ml/utils/fragment_helper.bs.js");
var Lang_entry_slim$BwaxAdmin = require("../../../../lib/bwax-js/ml/lang/lang_entry_slim.bs.js");
var Base_query_types$BwaxAdmin = require("../../../../lib/bwax-js/ml/base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("../../../../lib/bwax-js/ml/lang/lang_typing_base.bs.js");
var Record_dependency_resolver$BwaxAdmin = require("../../../../lib/bwax-js/ml/lang/record_dependency_resolver.bs.js");

function color_to_string(v) {
  return Plate$BwaxAdmin.$$Option.map(ColorTypes$BwaxAdmin.toString, v);
}

function apply_float_to_string(f, n) {
  var v_000 = /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Float */Caml_chrome_debugger.variant("Float", 1, [n])]);
  var v = /* tuple */[
    v_000,
    /* No_term */0
  ];
  return Lang_eval$BwaxAdmin.as_string(Lang_eval$BwaxAdmin.apply_value(undefined, f, v, /* No_term */0));
}

function apply_float_to_color(f, n) {
  var v_000 = /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Float */Caml_chrome_debugger.variant("Float", 1, [n])]);
  var v = /* tuple */[
    v_000,
    /* No_term */0
  ];
  var c = Lang_eval$BwaxAdmin.apply_value(undefined, f, v, /* No_term */0);
  return BwaxUi.themeColor(undefined, ColorTypes$BwaxAdmin.toString(Widget_common$BwaxAdmin.make_color(c)));
}

function apply_string_to_string(f, s) {
  var v_000 = /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [s])]);
  var v = /* tuple */[
    v_000,
    /* No_term */0
  ];
  var r = Lang_eval$BwaxAdmin.apply_value(undefined, f, v, /* No_term */0);
  var match = r[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return ;
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
      return ;
    } else {
      return match$1[0];
    }
  }
}

function apply_fn(f, value) {
  return Lang_eval$BwaxAdmin.apply_value(undefined, f, value, /* No_term */0);
}

function apply_value_to_bool(f, value) {
  var match = apply_fn(f, value);
  var match$1 = match[0];
  if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
    return false;
  } else {
    var match$2 = match$1[0];
    if (typeof match$2 === "number" || match$2.tag !== /* Bool */4) {
      return false;
    } else {
      return match$2[0];
    }
  }
}

function apply_value_to_js(f, value) {
  return Lang_mod_json$BwaxAdmin.any_value_to_plain_json(apply_fn(f, value));
}

function apply_value_to_element(f, value) {
  var match = apply_fn(f, value);
  var match$1 = match[0];
  if (typeof match$1 === "number" || match$1.tag !== /* V_raw */7) {
    return ;
  } else {
    return match$1[0];
  }
}

function get_return_type(f) {
  var t = f[1];
  if (typeof t === "number" || t.tag || t[0] !== "Arrow") {
    return ;
  } else {
    var match = t[1];
    if (match) {
      var match$1 = match[1];
      if (match$1 && !match$1[1]) {
        var st = Lang_typing_base$BwaxAdmin.shorten(match$1[0]);
        if (typeof st === "number" || st.tag !== /* Var */2) {
          return st;
        } else {
          var match$2 = st[0][/* var_type */0];
          if (typeof match$2 === "number" || match$2.tag !== /* Opaqued */8) {
            return st;
          } else {
            var match$3 = match$2[0];
            if (match$3) {
              return match$3[0];
            } else {
              return st;
            }
          }
        }
      } else {
        return ;
      }
    } else {
      return ;
    }
  }
}

var nt = /* No_term */0;

var determine_function_dependencies_as_js = Record_dependency_resolver$BwaxAdmin.determine_function_dependencies_as_js;

var transform_record = Lang_entry_slim$BwaxAdmin.transform_record;

var normalize_field_name = Base_query_types$BwaxAdmin.normalize_field_name;

var to_type_metadata = Fragment_helper$BwaxAdmin.to_type_metadata;

exports.color_to_string = color_to_string;
exports.nt = nt;
exports.apply_float_to_string = apply_float_to_string;
exports.apply_float_to_color = apply_float_to_color;
exports.apply_string_to_string = apply_string_to_string;
exports.determine_function_dependencies_as_js = determine_function_dependencies_as_js;
exports.transform_record = transform_record;
exports.apply_fn = apply_fn;
exports.apply_value_to_bool = apply_value_to_bool;
exports.apply_value_to_js = apply_value_to_js;
exports.apply_value_to_element = apply_value_to_element;
exports.normalize_field_name = normalize_field_name;
exports.get_return_type = get_return_type;
exports.to_type_metadata = to_type_metadata;
/* bwax-ui Not a pure module */
