// Generated from Bwax.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');
var BwaxListener = require('./BwaxListener').BwaxListener;
var BwaxVisitor = require('./BwaxVisitor').BwaxVisitor;

var grammarFileName = "Bwax.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003P\u0285\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004",
    "\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f\u0004",
    "\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010\t\u0010\u0004",
    "\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013\u0004\u0014\t",
    "\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017\t\u0017\u0004",
    "\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a\u0004\u001b\t",
    "\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e\t\u001e\u0004",
    "\u001f\t\u001f\u0004 \t \u0003\u0002\u0003\u0002\u0006\u0002C\n\u0002",
    "\r\u0002\u000e\u0002D\u0007\u0002G\n\u0002\f\u0002\u000e\u0002J\u000b",
    "\u0002\u0003\u0002\u0005\u0002M\n\u0002\u0003\u0002\u0003\u0002\u0003",
    "\u0003\u0003\u0003\u0006\u0003S\n\u0003\r\u0003\u000e\u0003T\u0005\u0003",
    "W\n\u0003\u0003\u0003\u0003\u0003\u0006\u0003[\n\u0003\r\u0003\u000e",
    "\u0003\\\u0007\u0003_\n\u0003\f\u0003\u000e\u0003b\u000b\u0003\u0003",
    "\u0003\u0003\u0003\u0006\u0003f\n\u0003\r\u0003\u000e\u0003g\u0007\u0003",
    "j\n\u0003\f\u0003\u000e\u0003m\u000b\u0003\u0003\u0003\u0003\u0003\u0007",
    "\u0003q\n\u0003\f\u0003\u000e\u0003t\u000b\u0003\u0005\u0003v\n\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0003\u0004\u0007\u0004\u0081\n\u0004\f\u0004",
    "\u000e\u0004\u0084\u000b\u0004\u0003\u0004\u0003\u0004\u0005\u0004\u0088",
    "\n\u0004\u0003\u0005\u0003\u0005\u0003\u0005\u0007\u0005\u008d\n\u0005",
    "\f\u0005\u000e\u0005\u0090\u000b\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0007\u0005\u0097\n\u0005\f\u0005\u000e",
    "\u0005\u009a\u000b\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005",
    "\u0003\u0005\u0007\u0005\u00a1\n\u0005\f\u0005\u000e\u0005\u00a4\u000b",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0007\u0005\u00ad\n\u0005\f\u0005\u000e\u0005\u00b0",
    "\u000b\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0005\u0005",
    "\u00b6\n\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0005\u0006\u00be\n\u0006\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0006\u0007",
    "\u00c7\n\u0007\r\u0007\u000e\u0007\u00c8\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0007\u0007\u00d1\n\u0007",
    "\f\u0007\u000e\u0007\u00d4\u000b\u0007\u0005\u0007\u00d6\n\u0007\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0007",
    "\u0007\u00de\n\u0007\f\u0007\u000e\u0007\u00e1\u000b\u0007\u0005\u0007",
    "\u00e3\n\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0007\u0007\u00ea\n\u0007\f\u0007\u000e\u0007\u00ed\u000b\u0007",
    "\u0005\u0007\u00ef\n\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0007\u0007\u00f5\n\u0007\f\u0007\u000e\u0007\u00f8\u000b\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0007\u0007\u00fd\n\u0007\f\u0007",
    "\u000e\u0007\u0100\u000b\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0007\u0007\u0108\n\u0007\f\u0007\u000e",
    "\u0007\u010b\u000b\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0006\u0007",
    "\u0122\n\u0007\r\u0007\u000e\u0007\u0123\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0006",
    "\u0007\u0134\n\u0007\r\u0007\u000e\u0007\u0135\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0007\u0007\u013c\n\u0007\f\u0007\u000e\u0007",
    "\u013f\u000b\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0005\u0007\u0146\n\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0007\u0007\u0172\n\u0007\f\u0007",
    "\u000e\u0007\u0175\u000b\u0007\u0003\b\u0003\b\u0003\t\u0003\t\u0003",
    "\t\u0003\t\u0003\t\u0003\n\u0003\n\u0003\n\u0003\n\u0005\n\u0182\n\n",
    "\u0003\u000b\u0003\u000b\u0003\u000b\u0005\u000b\u0187\n\u000b\u0003",
    "\f\u0003\f\u0003\f\u0003\f\u0003\f\u0003\f\u0003\f\u0003\f\u0003\f\u0003",
    "\f\u0003\f\u0003\f\u0003\f\u0005\f\u0196\n\f\u0003\r\u0003\r\u0003\u000e",
    "\u0003\u000e\u0003\u000e\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u0010",
    "\u0003\u0010\u0003\u0010\u0003\u0010\u0006\u0010\u01a4\n\u0010\r\u0010",
    "\u000e\u0010\u01a5\u0003\u0010\u0003\u0010\u0003\u0011\u0003\u0011\u0003",
    "\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0007\u0011\u01b0\n\u0011",
    "\f\u0011\u000e\u0011\u01b3\u000b\u0011\u0003\u0011\u0003\u0011\u0003",
    "\u0012\u0003\u0012\u0003\u0012\u0003\u0012\u0007\u0012\u01bb\n\u0012",
    "\f\u0012\u000e\u0012\u01be\u000b\u0012\u0005\u0012\u01c0\n\u0012\u0003",
    "\u0012\u0003\u0012\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0013\u0005",
    "\u0013\u01c8\n\u0013\u0003\u0014\u0003\u0014\u0003\u0014\u0003\u0014",
    "\u0005\u0014\u01ce\n\u0014\u0003\u0015\u0003\u0015\u0003\u0015\u0003",
    "\u0015\u0005\u0015\u01d4\n\u0015\u0003\u0016\u0003\u0016\u0003\u0017",
    "\u0003\u0017\u0003\u0017\u0003\u0017\u0003\u0018\u0003\u0018\u0007\u0018",
    "\u01de\n\u0018\f\u0018\u000e\u0018\u01e1\u000b\u0018\u0003\u0019\u0003",
    "\u0019\u0007\u0019\u01e5\n\u0019\f\u0019\u000e\u0019\u01e8\u000b\u0019",
    "\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019",
    "\u0003\u0019\u0003\u0019\u0003\u0019\u0006\u0019\u01f3\n\u0019\r\u0019",
    "\u000e\u0019\u01f4\u0003\u001a\u0003\u001a\u0005\u001a\u01f9\n\u001a",
    "\u0003\u001b\u0003\u001b\u0003\u001b\u0003\u001c\u0003\u001c\u0005\u001c",
    "\u0200\n\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0003",
    "\u001c\u0006\u001c\u0207\n\u001c\r\u001c\u000e\u001c\u0208\u0003\u001c",
    "\u0005\u001c\u020c\n\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0003",
    "\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0007\u001c\u0215\n\u001c",
    "\f\u001c\u000e\u001c\u0218\u000b\u001c\u0003\u001c\u0003\u001c\u0003",
    "\u001c\u0003\u001c\u0003\u001c\u0007\u001c\u021f\n\u001c\f\u001c\u000e",
    "\u001c\u0222\u000b\u001c\u0003\u001c\u0003\u001c\u0005\u001c\u0226\n",
    "\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0007\u001c\u022b\n\u001c",
    "\f\u001c\u000e\u001c\u022e\u000b\u001c\u0003\u001c\u0003\u001c\u0003",
    "\u001c\u0007\u001c\u0233\n\u001c\f\u001c\u000e\u001c\u0236\u000b\u001c",
    "\u0003\u001c\u0003\u001c\u0005\u001c\u023a\n\u001c\u0003\u001d\u0003",
    "\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003",
    "\u001d\u0007\u001d\u0244\n\u001d\f\u001d\u000e\u001d\u0247\u000b\u001d",
    "\u0003\u001d\u0007\u001d\u024a\n\u001d\f\u001d\u000e\u001d\u024d\u000b",
    "\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003",
    "\u001d\u0003\u001d\u0006\u001d\u0256\n\u001d\r\u001d\u000e\u001d\u0257",
    "\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d",
    "\u0003\u001d\u0003\u001d\u0007\u001d\u0262\n\u001d\f\u001d\u000e\u001d",
    "\u0265\u000b\u001d\u0005\u001d\u0267\n\u001d\u0003\u001d\u0003\u001d",
    "\u0005\u001d\u026b\n\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0007",
    "\u001d\u0270\n\u001d\f\u001d\u000e\u001d\u0273\u000b\u001d\u0003\u001e",
    "\u0003\u001e\u0003\u001f\u0003\u001f\u0003\u001f\u0003\u001f\u0003\u001f",
    "\u0003\u001f\u0003\u001f\u0005\u001f\u027e\n\u001f\u0003 \u0003 \u0003",
    " \u0005 \u0283\n \u0003 \u0002\u0004\f8!\u0002\u0004\u0006\b\n\f\u000e",
    "\u0010\u0012\u0014\u0016\u0018\u001a\u001c\u001e \"$&(*,.02468:<>\u0002",
    "\t\u0004\u0002\u0004\u0004!!\u0004\u0002,-00\u0003\u0002./\u0004\u0002",
    "5689\u0004\u000277::\u0003\u0002!#\u0005\u0002!#+05@\u0002\u02ed\u0002",
    "H\u0003\u0002\u0002\u0002\u0004V\u0003\u0002\u0002\u0002\u0006y\u0003",
    "\u0002\u0002\u0002\b\u00b5\u0003\u0002\u0002\u0002\n\u00bd\u0003\u0002",
    "\u0002\u0002\f\u0145\u0003\u0002\u0002\u0002\u000e\u0176\u0003\u0002",
    "\u0002\u0002\u0010\u0178\u0003\u0002\u0002\u0002\u0012\u017d\u0003\u0002",
    "\u0002\u0002\u0014\u0183\u0003\u0002\u0002\u0002\u0016\u0195\u0003\u0002",
    "\u0002\u0002\u0018\u0197\u0003\u0002\u0002\u0002\u001a\u0199\u0003\u0002",
    "\u0002\u0002\u001c\u019c\u0003\u0002\u0002\u0002\u001e\u019f\u0003\u0002",
    "\u0002\u0002 \u01a9\u0003\u0002\u0002\u0002\"\u01b6\u0003\u0002\u0002",
    "\u0002$\u01c3\u0003\u0002\u0002\u0002&\u01c9\u0003\u0002\u0002\u0002",
    "(\u01cf\u0003\u0002\u0002\u0002*\u01d5\u0003\u0002\u0002\u0002,\u01d7",
    "\u0003\u0002\u0002\u0002.\u01db\u0003\u0002\u0002\u00020\u01e2\u0003",
    "\u0002\u0002\u00022\u01f8\u0003\u0002\u0002\u00024\u01fa\u0003\u0002",
    "\u0002\u00026\u0239\u0003\u0002\u0002\u00028\u026a\u0003\u0002\u0002",
    "\u0002:\u0274\u0003\u0002\u0002\u0002<\u027d\u0003\u0002\u0002\u0002",
    ">\u027f\u0003\u0002\u0002\u0002@B\u0005\f\u0007\u0002AC\u00071\u0002",
    "\u0002BA\u0003\u0002\u0002\u0002CD\u0003\u0002\u0002\u0002DB\u0003\u0002",
    "\u0002\u0002DE\u0003\u0002\u0002\u0002EG\u0003\u0002\u0002\u0002F@\u0003",
    "\u0002\u0002\u0002GJ\u0003\u0002\u0002\u0002HF\u0003\u0002\u0002\u0002",
    "HI\u0003\u0002\u0002\u0002IL\u0003\u0002\u0002\u0002JH\u0003\u0002\u0002",
    "\u0002KM\u0005\f\u0007\u0002LK\u0003\u0002\u0002\u0002LM\u0003\u0002",
    "\u0002\u0002MN\u0003\u0002\u0002\u0002NO\u0007\u0002\u0002\u0003O\u0003",
    "\u0003\u0002\u0002\u0002PR\u0005\u0006\u0004\u0002QS\u00071\u0002\u0002",
    "RQ\u0003\u0002\u0002\u0002ST\u0003\u0002\u0002\u0002TR\u0003\u0002\u0002",
    "\u0002TU\u0003\u0002\u0002\u0002UW\u0003\u0002\u0002\u0002VP\u0003\u0002",
    "\u0002\u0002VW\u0003\u0002\u0002\u0002W`\u0003\u0002\u0002\u0002XZ\u0005",
    "\b\u0005\u0002Y[\u00071\u0002\u0002ZY\u0003\u0002\u0002\u0002[\\\u0003",
    "\u0002\u0002\u0002\\Z\u0003\u0002\u0002\u0002\\]\u0003\u0002\u0002\u0002",
    "]_\u0003\u0002\u0002\u0002^X\u0003\u0002\u0002\u0002_b\u0003\u0002\u0002",
    "\u0002`^\u0003\u0002\u0002\u0002`a\u0003\u0002\u0002\u0002ak\u0003\u0002",
    "\u0002\u0002b`\u0003\u0002\u0002\u0002ce\u00056\u001c\u0002df\u0007",
    "1\u0002\u0002ed\u0003\u0002\u0002\u0002fg\u0003\u0002\u0002\u0002ge",
    "\u0003\u0002\u0002\u0002gh\u0003\u0002\u0002\u0002hj\u0003\u0002\u0002",
    "\u0002ic\u0003\u0002\u0002\u0002jm\u0003\u0002\u0002\u0002ki\u0003\u0002",
    "\u0002\u0002kl\u0003\u0002\u0002\u0002lu\u0003\u0002\u0002\u0002mk\u0003",
    "\u0002\u0002\u0002nr\u00056\u001c\u0002oq\u00071\u0002\u0002po\u0003",
    "\u0002\u0002\u0002qt\u0003\u0002\u0002\u0002rp\u0003\u0002\u0002\u0002",
    "rs\u0003\u0002\u0002\u0002sv\u0003\u0002\u0002\u0002tr\u0003\u0002\u0002",
    "\u0002un\u0003\u0002\u0002\u0002uv\u0003\u0002\u0002\u0002vw\u0003\u0002",
    "\u0002\u0002wx\u0007\u0002\u0002\u0003x\u0005\u0003\u0002\u0002\u0002",
    "yz\u0007 \u0002\u0002z\u0087\u0007K\u0002\u0002{|\u0007\u001f\u0002",
    "\u0002|}\u0007\u0003\u0002\u0002}\u0082\u0005\n\u0006\u0002~\u007f\u0007",
    "\u0004\u0002\u0002\u007f\u0081\u0005\n\u0006\u0002\u0080~\u0003\u0002",
    "\u0002\u0002\u0081\u0084\u0003\u0002\u0002\u0002\u0082\u0080\u0003\u0002",
    "\u0002\u0002\u0082\u0083\u0003\u0002\u0002\u0002\u0083\u0085\u0003\u0002",
    "\u0002\u0002\u0084\u0082\u0003\u0002\u0002\u0002\u0085\u0086\u0007\u0005",
    "\u0002\u0002\u0086\u0088\u0003\u0002\u0002\u0002\u0087{\u0003\u0002",
    "\u0002\u0002\u0087\u0088\u0003\u0002\u0002\u0002\u0088\u0007\u0003\u0002",
    "\u0002\u0002\u0089\u008a\u0007\u001d\u0002\u0002\u008a\u008e\u0007K",
    "\u0002\u0002\u008b\u008d\u0007H\u0002\u0002\u008c\u008b\u0003\u0002",
    "\u0002\u0002\u008d\u0090\u0003\u0002\u0002\u0002\u008e\u008c\u0003\u0002",
    "\u0002\u0002\u008e\u008f\u0003\u0002\u0002\u0002\u008f\u0091\u0003\u0002",
    "\u0002\u0002\u0090\u008e\u0003\u0002\u0002\u0002\u0091\u0092\u0007\u001f",
    "\u0002\u0002\u0092\u0093\u0007\u0003\u0002\u0002\u0093\u0098\u0005\n",
    "\u0006\u0002\u0094\u0095\u0007\u0004\u0002\u0002\u0095\u0097\u0005\n",
    "\u0006\u0002\u0096\u0094\u0003\u0002\u0002\u0002\u0097\u009a\u0003\u0002",
    "\u0002\u0002\u0098\u0096\u0003\u0002\u0002\u0002\u0098\u0099\u0003\u0002",
    "\u0002\u0002\u0099\u009b\u0003\u0002\u0002\u0002\u009a\u0098\u0003\u0002",
    "\u0002\u0002\u009b\u009c\u0007\u0005\u0002\u0002\u009c\u00b6\u0003\u0002",
    "\u0002\u0002\u009d\u009e\u0007\u001d\u0002\u0002\u009e\u00a2\u0007K",
    "\u0002\u0002\u009f\u00a1\u0007H\u0002\u0002\u00a0\u009f\u0003\u0002",
    "\u0002\u0002\u00a1\u00a4\u0003\u0002\u0002\u0002\u00a2\u00a0\u0003\u0002",
    "\u0002\u0002\u00a2\u00a3\u0003\u0002\u0002\u0002\u00a3\u00a5\u0003\u0002",
    "\u0002\u0002\u00a4\u00a2\u0003\u0002\u0002\u0002\u00a5\u00a6\u0007\u001f",
    "\u0002\u0002\u00a6\u00a7\u0007\u0003\u0002\u0002\u00a7\u00a8\u00072",
    "\u0002\u0002\u00a8\u00b6\u0007\u0005\u0002\u0002\u00a9\u00aa\u0007\u001d",
    "\u0002\u0002\u00aa\u00ae\u0007K\u0002\u0002\u00ab\u00ad\u0007H\u0002",
    "\u0002\u00ac\u00ab\u0003\u0002\u0002\u0002\u00ad\u00b0\u0003\u0002\u0002",
    "\u0002\u00ae\u00ac\u0003\u0002\u0002\u0002\u00ae\u00af\u0003\u0002\u0002",
    "\u0002\u00af\u00b1\u0003\u0002\u0002\u0002\u00b0\u00ae\u0003\u0002\u0002",
    "\u0002\u00b1\u00b2\u0007I\u0002\u0002\u00b2\u00b3\u0003\u0002\u0002",
    "\u0002\u00b3\u00b4\u0007\u001c\u0002\u0002\u00b4\u00b6\u0007J\u0002",
    "\u0002\u00b5\u0089\u0003\u0002\u0002\u0002\u00b5\u009d\u0003\u0002\u0002",
    "\u0002\u00b5\u00a9\u0003\u0002\u0002\u0002\u00b6\t\u0003\u0002\u0002",
    "\u0002\u00b7\u00b8\u0007K\u0002\u0002\u00b8\u00b9\u0007\u0003\u0002",
    "\u0002\u00b9\u00ba\u00072\u0002\u0002\u00ba\u00be\u0007\u0005\u0002",
    "\u0002\u00bb\u00be\u0007K\u0002\u0002\u00bc\u00be\u0007J\u0002\u0002",
    "\u00bd\u00b7\u0003\u0002\u0002\u0002\u00bd\u00bb\u0003\u0002\u0002\u0002",
    "\u00bd\u00bc\u0003\u0002\u0002\u0002\u00be\u000b\u0003\u0002\u0002\u0002",
    "\u00bf\u00c0\b\u0007\u0001\u0002\u00c0\u00c1\u0007\u0003\u0002\u0002",
    "\u00c1\u0146\u0007\u0005\u0002\u0002\u00c2\u00c3\u0007\u0003\u0002\u0002",
    "\u00c3\u00c6\u0005\f\u0007\u0002\u00c4\u00c5\u0007\u0004\u0002\u0002",
    "\u00c5\u00c7\u0005\f\u0007\u0002\u00c6\u00c4\u0003\u0002\u0002\u0002",
    "\u00c7\u00c8\u0003\u0002\u0002\u0002\u00c8\u00c6\u0003\u0002\u0002\u0002",
    "\u00c8\u00c9\u0003\u0002\u0002\u0002\u00c9\u00ca\u0003\u0002\u0002\u0002",
    "\u00ca\u00cb\u0007\u0005\u0002\u0002\u00cb\u0146\u0003\u0002\u0002\u0002",
    "\u00cc\u00d5\u0007\u0006\u0002\u0002\u00cd\u00d2\u0005\f\u0007\u0002",
    "\u00ce\u00cf\u0007\u0004\u0002\u0002\u00cf\u00d1\u0005\f\u0007\u0002",
    "\u00d0\u00ce\u0003\u0002\u0002\u0002\u00d1\u00d4\u0003\u0002\u0002\u0002",
    "\u00d2\u00d0\u0003\u0002\u0002\u0002\u00d2\u00d3\u0003\u0002\u0002\u0002",
    "\u00d3\u00d6\u0003\u0002\u0002\u0002\u00d4\u00d2\u0003\u0002\u0002\u0002",
    "\u00d5\u00cd\u0003\u0002\u0002\u0002\u00d5\u00d6\u0003\u0002\u0002\u0002",
    "\u00d6\u00d7\u0003\u0002\u0002\u0002\u00d7\u0146\u0007\u0007\u0002\u0002",
    "\u00d8\u00d9\u00074\u0002\u0002\u00d9\u00e2\u0007\u0006\u0002\u0002",
    "\u00da\u00df\u0005\f\u0007\u0002\u00db\u00dc\u0007\u0004\u0002\u0002",
    "\u00dc\u00de\u0005\f\u0007\u0002\u00dd\u00db\u0003\u0002\u0002\u0002",
    "\u00de\u00e1\u0003\u0002\u0002\u0002\u00df\u00dd\u0003\u0002\u0002\u0002",
    "\u00df\u00e0\u0003\u0002\u0002\u0002\u00e0\u00e3\u0003\u0002\u0002\u0002",
    "\u00e1\u00df\u0003\u0002\u0002\u0002\u00e2\u00da\u0003\u0002\u0002\u0002",
    "\u00e2\u00e3\u0003\u0002\u0002\u0002\u00e3\u00e4\u0003\u0002\u0002\u0002",
    "\u00e4\u0146\u0007\u0007\u0002\u0002\u00e5\u00ee\u0007\b\u0002\u0002",
    "\u00e6\u00eb\u0005> \u0002\u00e7\u00e8\u0007\u0004\u0002\u0002\u00e8",
    "\u00ea\u0005> \u0002\u00e9\u00e7\u0003\u0002\u0002\u0002\u00ea\u00ed",
    "\u0003\u0002\u0002\u0002\u00eb\u00e9\u0003\u0002\u0002\u0002\u00eb\u00ec",
    "\u0003\u0002\u0002\u0002\u00ec\u00ef\u0003\u0002\u0002\u0002\u00ed\u00eb",
    "\u0003\u0002\u0002\u0002\u00ee\u00e6\u0003\u0002\u0002\u0002\u00ee\u00ef",
    "\u0003\u0002\u0002\u0002\u00ef\u00f0\u0003\u0002\u0002\u0002\u00f0\u0146",
    "\u0007\t\u0002\u0002\u00f1\u0146\u0007\'\u0002\u0002\u00f2\u00f6\u0007",
    "K\u0002\u0002\u00f3\u00f5\u0007H\u0002\u0002\u00f4\u00f3\u0003\u0002",
    "\u0002\u0002\u00f5\u00f8\u0003\u0002\u0002\u0002\u00f6\u00f4\u0003\u0002",
    "\u0002\u0002\u00f6\u00f7\u0003\u0002\u0002\u0002\u00f7\u00f9\u0003\u0002",
    "\u0002\u0002\u00f8\u00f6\u0003\u0002\u0002\u0002\u00f9\u0146\u0007I",
    "\u0002\u0002\u00fa\u00fe\u0007K\u0002\u0002\u00fb\u00fd\u0007H\u0002",
    "\u0002\u00fc\u00fb\u0003\u0002\u0002\u0002\u00fd\u0100\u0003\u0002\u0002",
    "\u0002\u00fe\u00fc\u0003\u0002\u0002\u0002\u00fe\u00ff\u0003\u0002\u0002",
    "\u0002\u00ff\u0146\u0003\u0002\u0002\u0002\u0100\u00fe\u0003\u0002\u0002",
    "\u0002\u0101\u0102\u0007\b\u0002\u0002\u0102\u0103\u0007J\u0002\u0002",
    "\u0103\u0104\u0007\n\u0002\u0002\u0104\u0109\u0005> \u0002\u0105\u0106",
    "\u0007\u0004\u0002\u0002\u0106\u0108\u0005> \u0002\u0107\u0105\u0003",
    "\u0002\u0002\u0002\u0108\u010b\u0003\u0002\u0002\u0002\u0109\u0107\u0003",
    "\u0002\u0002\u0002\u0109\u010a\u0003\u0002\u0002\u0002\u010a\u010c\u0003",
    "\u0002\u0002\u0002\u010b\u0109\u0003\u0002\u0002\u0002\u010c\u010d\u0007",
    "\t\u0002\u0002\u010d\u0146\u0003\u0002\u0002\u0002\u010e\u010f\u0007",
    "\u0003\u0002\u0002\u010f\u0110\u0007I\u0002\u0002\u0110\u0146\u0007",
    "\u0005\u0002\u0002\u0111\u0112\u0007\u0003\u0002\u0002\u0112\u0113\u0005",
    "\u000e\b\u0002\u0113\u0114\u0007\u0005\u0002\u0002\u0114\u0146\u0003",
    "\u0002\u0002\u0002\u0115\u0116\u0007\u0003\u0002\u0002\u0116\u0117\u0005",
    "\f\u0007\u0002\u0117\u0118\u0007\u0005\u0002\u0002\u0118\u0146\u0003",
    "\u0002\u0002\u0002\u0119\u011a\u0007$\u0002\u0002\u011a\u0146\u0005",
    "\f\u0007\u0012\u011b\u011c\u0007\u000b\u0002\u0002\u011c\u011d\u0007",
    "J\u0002\u0002\u011d\u011e\u0007\f\u0002\u0002\u011e\u0146\u0005\f\u0007",
    "\n\u011f\u0121\u0007\r\u0002\u0002\u0120\u0122\u00058\u001d\u0002\u0121",
    "\u0120\u0003\u0002\u0002\u0002\u0122\u0123\u0003\u0002\u0002\u0002\u0123",
    "\u0121\u0003\u0002\u0002\u0002\u0123\u0124\u0003\u0002\u0002\u0002\u0124",
    "\u0125\u0003\u0002\u0002\u0002\u0125\u0126\u0007\f\u0002\u0002\u0126",
    "\u0127\u0005\f\u0007\t\u0127\u0146\u0003\u0002\u0002\u0002\u0128\u0129",
    "\u0007\u0012\u0002\u0002\u0129\u012a\u0005\f\u0007\u0002\u012a\u012b",
    "\u0007\u0013\u0002\u0002\u012b\u012c\u0005\f\u0007\u0002\u012c\u012d",
    "\u0007\u0014\u0002\u0002\u012d\u012e\u0005\f\u0007\b\u012e\u0146\u0003",
    "\u0002\u0002\u0002\u012f\u0130\u0007\u0015\u0002\u0002\u0130\u0131\u0005",
    "\f\u0007\u0002\u0131\u0133\u0007\u0016\u0002\u0002\u0132\u0134\u0005",
    "\u0010\t\u0002\u0133\u0132\u0003\u0002\u0002\u0002\u0134\u0135\u0003",
    "\u0002\u0002\u0002\u0135\u0133\u0003\u0002\u0002\u0002\u0135\u0136\u0003",
    "\u0002\u0002\u0002\u0136\u0146\u0003\u0002\u0002\u0002\u0137\u0138\u0007",
    "\u0017\u0002\u0002\u0138\u013d\u00056\u001c\u0002\u0139\u013a\t\u0002",
    "\u0002\u0002\u013a\u013c\u00056\u001c\u0002\u013b\u0139\u0003\u0002",
    "\u0002\u0002\u013c\u013f\u0003\u0002\u0002\u0002\u013d\u013b\u0003\u0002",
    "\u0002\u0002\u013d\u013e\u0003\u0002\u0002\u0002\u013e\u0140\u0003\u0002",
    "\u0002\u0002\u013f\u013d\u0003\u0002\u0002\u0002\u0140\u0141\u0007\u0019",
    "\u0002\u0002\u0141\u0142\u0005\f\u0007\u0006\u0142\u0146\u0003\u0002",
    "\u0002\u0002\u0143\u0146\u0005<\u001f\u0002\u0144\u0146\u0005:\u001e",
    "\u0002\u0145\u00bf\u0003\u0002\u0002\u0002\u0145\u00c2\u0003\u0002\u0002",
    "\u0002\u0145\u00cc\u0003\u0002\u0002\u0002\u0145\u00d8\u0003\u0002\u0002",
    "\u0002\u0145\u00e5\u0003\u0002\u0002\u0002\u0145\u00f1\u0003\u0002\u0002",
    "\u0002\u0145\u00f2\u0003\u0002\u0002\u0002\u0145\u00fa\u0003\u0002\u0002",
    "\u0002\u0145\u0101\u0003\u0002\u0002\u0002\u0145\u010e\u0003\u0002\u0002",
    "\u0002\u0145\u0111\u0003\u0002\u0002\u0002\u0145\u0115\u0003\u0002\u0002",
    "\u0002\u0145\u0119\u0003\u0002\u0002\u0002\u0145\u011b\u0003\u0002\u0002",
    "\u0002\u0145\u011f\u0003\u0002\u0002\u0002\u0145\u0128\u0003\u0002\u0002",
    "\u0002\u0145\u012f\u0003\u0002\u0002\u0002\u0145\u0137\u0003\u0002\u0002",
    "\u0002\u0145\u0143\u0003\u0002\u0002\u0002\u0145\u0144\u0003\u0002\u0002",
    "\u0002\u0146\u0173\u0003\u0002\u0002\u0002\u0147\u0148\f\u0018\u0002",
    "\u0002\u0148\u0172\u0005\f\u0007\u0019\u0149\u014a\f\u0017\u0002\u0002",
    "\u014a\u014b\u0007+\u0002\u0002\u014b\u0172\u0005\f\u0007\u0017\u014c",
    "\u014d\f\u0016\u0002\u0002\u014d\u014e\t\u0003\u0002\u0002\u014e\u0172",
    "\u0005\f\u0007\u0017\u014f\u0150\f\u0015\u0002\u0002\u0150\u0151\t\u0004",
    "\u0002\u0002\u0151\u0172\u0005\f\u0007\u0016\u0152\u0153\f\u0014\u0002",
    "\u0002\u0153\u0154\t\u0005\u0002\u0002\u0154\u0172\u0005\f\u0007\u0015",
    "\u0155\u0156\f\u0013\u0002\u0002\u0156\u0157\t\u0006\u0002\u0002\u0157",
    "\u0172\u0005\f\u0007\u0014\u0158\u0159\f\u0011\u0002\u0002\u0159\u015a",
    "\t\u0007\u0002\u0002\u015a\u0172\u0005\f\u0007\u0012\u015b\u015c\f\u0010",
    "\u0002\u0002\u015c\u015d\u0007;\u0002\u0002\u015d\u0172\u0005\f\u0007",
    "\u0010\u015e\u015f\f\u000f\u0002\u0002\u015f\u0160\u0007<\u0002\u0002",
    "\u0160\u0172\u0005\f\u0007\u000f\u0161\u0162\f\u000e\u0002\u0002\u0162",
    "\u0163\u0007>\u0002\u0002\u0163\u0172\u0005\f\u0007\u000f\u0164\u0165",
    "\f\r\u0002\u0002\u0165\u0166\u0007=\u0002\u0002\u0166\u0172\u0005\f",
    "\u0007\r\u0167\u0168\f\f\u0002\u0002\u0168\u0169\u0007@\u0002\u0002",
    "\u0169\u0172\u0005\f\u0007\r\u016a\u016b\f\u000b\u0002\u0002\u016b\u016c",
    "\u0007?\u0002\u0002\u016c\u0172\u0005\f\u0007\u000b\u016d\u016e\f\u001a",
    "\u0002\u0002\u016e\u0172\u0007I\u0002\u0002\u016f\u0170\f\u0003\u0002",
    "\u0002\u0170\u0172\u00054\u001b\u0002\u0171\u0147\u0003\u0002\u0002",
    "\u0002\u0171\u0149\u0003\u0002\u0002\u0002\u0171\u014c\u0003\u0002\u0002",
    "\u0002\u0171\u014f\u0003\u0002\u0002\u0002\u0171\u0152\u0003\u0002\u0002",
    "\u0002\u0171\u0155\u0003\u0002\u0002\u0002\u0171\u0158\u0003\u0002\u0002",
    "\u0002\u0171\u015b\u0003\u0002\u0002\u0002\u0171\u015e\u0003\u0002\u0002",
    "\u0002\u0171\u0161\u0003\u0002\u0002\u0002\u0171\u0164\u0003\u0002\u0002",
    "\u0002\u0171\u0167\u0003\u0002\u0002\u0002\u0171\u016a\u0003\u0002\u0002",
    "\u0002\u0171\u016d\u0003\u0002\u0002\u0002\u0171\u016f\u0003\u0002\u0002",
    "\u0002\u0172\u0175\u0003\u0002\u0002\u0002\u0173\u0171\u0003\u0002\u0002",
    "\u0002\u0173\u0174\u0003\u0002\u0002\u0002\u0174\r\u0003\u0002\u0002",
    "\u0002\u0175\u0173\u0003\u0002\u0002\u0002\u0176\u0177\t\b\u0002\u0002",
    "\u0177\u000f\u0003\u0002\u0002\u0002\u0178\u0179\u0007\n\u0002\u0002",
    "\u0179\u017a\u00058\u001d\u0002\u017a\u017b\u0007\f\u0002\u0002\u017b",
    "\u017c\u0005\f\u0007\u0002\u017c\u0011\u0003\u0002\u0002\u0002\u017d",
    "\u017e\u0007J\u0002\u0002\u017e\u017f\u0007\u000e\u0002\u0002\u017f",
    "\u0181\u0005\u0014\u000b\u0002\u0180\u0182\u0007\u000f\u0002\u0002\u0181",
    "\u0180\u0003\u0002\u0002\u0002\u0181\u0182\u0003\u0002\u0002\u0002\u0182",
    "\u0013\u0003\u0002\u0002\u0002\u0183\u0186\u0005\u0016\f\u0002\u0184",
    "\u0185\u0007\f\u0002\u0002\u0185\u0187\u0005\u0014\u000b\u0002\u0186",
    "\u0184\u0003\u0002\u0002\u0002\u0186\u0187\u0003\u0002\u0002\u0002\u0187",
    "\u0015\u0003\u0002\u0002\u0002\u0188\u0196\u0005\u0018\r\u0002\u0189",
    "\u0196\u0005\u001a\u000e\u0002\u018a\u0196\u0005\u001c\u000f\u0002\u018b",
    "\u0196\u0005\u001e\u0010\u0002\u018c\u0196\u0005\"\u0012\u0002\u018d",
    "\u0196\u0005 \u0011\u0002\u018e\u0196\u0005$\u0013\u0002\u018f\u0196",
    "\u0005&\u0014\u0002\u0190\u0196\u0005(\u0015\u0002\u0191\u0196\u0005",
    ",\u0017\u0002\u0192\u0196\u00050\u0019\u0002\u0193\u0196\u0005.\u0018",
    "\u0002\u0194\u0196\u0005*\u0016\u0002\u0195\u0188\u0003\u0002\u0002",
    "\u0002\u0195\u0189\u0003\u0002\u0002\u0002\u0195\u018a\u0003\u0002\u0002",
    "\u0002\u0195\u018b\u0003\u0002\u0002\u0002\u0195\u018c\u0003\u0002\u0002",
    "\u0002\u0195\u018d\u0003\u0002\u0002\u0002\u0195\u018e\u0003\u0002\u0002",
    "\u0002\u0195\u018f\u0003\u0002\u0002\u0002\u0195\u0190\u0003\u0002\u0002",
    "\u0002\u0195\u0191\u0003\u0002\u0002\u0002\u0195\u0192\u0003\u0002\u0002",
    "\u0002\u0195\u0193\u0003\u0002\u0002\u0002\u0195\u0194\u0003\u0002\u0002",
    "\u0002\u0196\u0017\u0003\u0002\u0002\u0002\u0197\u0198\u0007J\u0002",
    "\u0002\u0198\u0019\u0003\u0002\u0002\u0002\u0199\u019a\u00073\u0002",
    "\u0002\u019a\u019b\u0007J\u0002\u0002\u019b\u001b\u0003\u0002\u0002",
    "\u0002\u019c\u019d\u0007\u0003\u0002\u0002\u019d\u019e\u0007\u0005\u0002",
    "\u0002\u019e\u001d\u0003\u0002\u0002\u0002\u019f\u01a0\u0007\u0003\u0002",
    "\u0002\u01a0\u01a3\u0005\u0014\u000b\u0002\u01a1\u01a2\u0007\u0004\u0002",
    "\u0002\u01a2\u01a4\u0005\u0014\u000b\u0002\u01a3\u01a1\u0003\u0002\u0002",
    "\u0002\u01a4\u01a5\u0003\u0002\u0002\u0002\u01a5\u01a3\u0003\u0002\u0002",
    "\u0002\u01a5\u01a6\u0003\u0002\u0002\u0002\u01a6\u01a7\u0003\u0002\u0002",
    "\u0002\u01a7\u01a8\u0007\u0005\u0002\u0002\u01a8\u001f\u0003\u0002\u0002",
    "\u0002\u01a9\u01aa\u0007\b\u0002\u0002\u01aa\u01ab\u0007J\u0002\u0002",
    "\u01ab\u01ac\u0007\n\u0002\u0002\u01ac\u01b1\u0005\u0012\n\u0002\u01ad",
    "\u01ae\u0007\u0004\u0002\u0002\u01ae\u01b0\u0005\u0012\n\u0002\u01af",
    "\u01ad\u0003\u0002\u0002\u0002\u01b0\u01b3\u0003\u0002\u0002\u0002\u01b1",
    "\u01af\u0003\u0002\u0002\u0002\u01b1\u01b2\u0003\u0002\u0002\u0002\u01b2",
    "\u01b4\u0003\u0002\u0002\u0002\u01b3\u01b1\u0003\u0002\u0002\u0002\u01b4",
    "\u01b5\u0007\t\u0002\u0002\u01b5!\u0003\u0002\u0002\u0002\u01b6\u01bf",
    "\u0007\b\u0002\u0002\u01b7\u01bc\u0005\u0012\n\u0002\u01b8\u01b9\u0007",
    "\u0004\u0002\u0002\u01b9\u01bb\u0005\u0012\n\u0002\u01ba\u01b8\u0003",
    "\u0002\u0002\u0002\u01bb\u01be\u0003\u0002\u0002\u0002\u01bc\u01ba\u0003",
    "\u0002\u0002\u0002\u01bc\u01bd\u0003\u0002\u0002\u0002\u01bd\u01c0\u0003",
    "\u0002\u0002\u0002\u01be\u01bc\u0003\u0002\u0002\u0002\u01bf\u01b7\u0003",
    "\u0002\u0002\u0002\u01bf\u01c0\u0003\u0002\u0002\u0002\u01c0\u01c1\u0003",
    "\u0002\u0002\u0002\u01c1\u01c2\u0007\t\u0002\u0002\u01c2#\u0003\u0002",
    "\u0002\u0002\u01c3\u01c7\u00073\u0002\u0002\u01c4\u01c8\u0005.\u0018",
    "\u0002\u01c5\u01c8\u00050\u0019\u0002\u01c6\u01c8\u0005\"\u0012\u0002",
    "\u01c7\u01c4\u0003\u0002\u0002\u0002\u01c7\u01c5\u0003\u0002\u0002\u0002",
    "\u01c7\u01c6\u0003\u0002\u0002\u0002\u01c8%\u0003\u0002\u0002\u0002",
    "\u01c9\u01ca\u00074\u0002\u0002\u01ca\u01cd\u00074\u0002\u0002\u01cb",
    "\u01ce\u0005.\u0018\u0002\u01cc\u01ce\u00050\u0019\u0002\u01cd\u01cb",
    "\u0003\u0002\u0002\u0002\u01cd\u01cc\u0003\u0002\u0002\u0002\u01ce\'",
    "\u0003\u0002\u0002\u0002\u01cf\u01d3\u00074\u0002\u0002\u01d0\u01d4",
    "\u0005.\u0018\u0002\u01d1\u01d4\u00050\u0019\u0002\u01d2\u01d4\u0005",
    "\"\u0012\u0002\u01d3\u01d0\u0003\u0002\u0002\u0002\u01d3\u01d1\u0003",
    "\u0002\u0002\u0002\u01d3\u01d2\u0003\u0002\u0002\u0002\u01d4)\u0003",
    "\u0002\u0002\u0002\u01d5\u01d6\u00074\u0002\u0002\u01d6+\u0003\u0002",
    "\u0002\u0002\u01d7\u01d8\u0007\u0003\u0002\u0002\u01d8\u01d9\u0005\u0014",
    "\u000b\u0002\u01d9\u01da\u0007\u0005\u0002\u0002\u01da-\u0003\u0002",
    "\u0002\u0002\u01db\u01df\u0007K\u0002\u0002\u01dc\u01de\u0007H\u0002",
    "\u0002\u01dd\u01dc\u0003\u0002\u0002\u0002\u01de\u01e1\u0003\u0002\u0002",
    "\u0002\u01df\u01dd\u0003\u0002\u0002\u0002\u01df\u01e0\u0003\u0002\u0002",
    "\u0002\u01e0/\u0003\u0002\u0002\u0002\u01e1\u01df\u0003\u0002\u0002",
    "\u0002\u01e2\u01e6\u0007K\u0002\u0002\u01e3\u01e5\u0007H\u0002\u0002",
    "\u01e4\u01e3\u0003\u0002\u0002\u0002\u01e5\u01e8\u0003\u0002\u0002\u0002",
    "\u01e6\u01e4\u0003\u0002\u0002\u0002\u01e6\u01e7\u0003\u0002\u0002\u0002",
    "\u01e7\u01f2\u0003\u0002\u0002\u0002\u01e8\u01e6\u0003\u0002\u0002\u0002",
    "\u01e9\u01f3\u0005\u0018\r\u0002\u01ea\u01f3\u0005\u001e\u0010\u0002",
    "\u01eb\u01f3\u0005 \u0011\u0002\u01ec\u01f3\u0005\"\u0012\u0002\u01ed",
    "\u01f3\u0005.\u0018\u0002\u01ee\u01f3\u0005,\u0017\u0002\u01ef\u01f3",
    "\u0005$\u0013\u0002\u01f0\u01f3\u0005(\u0015\u0002\u01f1\u01f3\u0005",
    "*\u0016\u0002\u01f2\u01e9\u0003\u0002\u0002\u0002\u01f2\u01ea\u0003",
    "\u0002\u0002\u0002\u01f2\u01eb\u0003\u0002\u0002\u0002\u01f2\u01ec\u0003",
    "\u0002\u0002\u0002\u01f2\u01ed\u0003\u0002\u0002\u0002\u01f2\u01ee\u0003",
    "\u0002\u0002\u0002\u01f2\u01ef\u0003\u0002\u0002\u0002\u01f2\u01f0\u0003",
    "\u0002\u0002\u0002\u01f2\u01f1\u0003\u0002\u0002\u0002\u01f3\u01f4\u0003",
    "\u0002\u0002\u0002\u01f4\u01f2\u0003\u0002\u0002\u0002\u01f4\u01f5\u0003",
    "\u0002\u0002\u0002\u01f51\u0003\u0002\u0002\u0002\u01f6\u01f9\u0005",
    ".\u0018\u0002\u01f7\u01f9\u00050\u0019\u0002\u01f8\u01f6\u0003\u0002",
    "\u0002\u0002\u01f8\u01f7\u0003\u0002\u0002\u0002\u01f93\u0003\u0002",
    "\u0002\u0002\u01fa\u01fb\u0007\u000e\u0002\u0002\u01fb\u01fc\u0005\u0014",
    "\u000b\u0002\u01fc5\u0003\u0002\u0002\u0002\u01fd\u01ff\u00058\u001d",
    "\u0002\u01fe\u0200\u00054\u001b\u0002\u01ff\u01fe\u0003\u0002\u0002",
    "\u0002\u01ff\u0200\u0003\u0002\u0002\u0002\u0200\u0201\u0003\u0002\u0002",
    "\u0002\u0201\u0202\u0007\u0010\u0002\u0002\u0202\u0203\u0005\f\u0007",
    "\u0002\u0203\u023a\u0003\u0002\u0002\u0002\u0204\u0206\u0007J\u0002",
    "\u0002\u0205\u0207\u00058\u001d\u0002\u0206\u0205\u0003\u0002\u0002",
    "\u0002\u0207\u0208\u0003\u0002\u0002\u0002\u0208\u0206\u0003\u0002\u0002",
    "\u0002\u0208\u0209\u0003\u0002\u0002\u0002\u0209\u020b\u0003\u0002\u0002",
    "\u0002\u020a\u020c\u00054\u001b\u0002\u020b\u020a\u0003\u0002\u0002",
    "\u0002\u020b\u020c\u0003\u0002\u0002\u0002\u020c\u020d\u0003\u0002\u0002",
    "\u0002\u020d\u020e\u0007\u0010\u0002\u0002\u020e\u020f\u0005\f\u0007",
    "\u0002\u020f\u023a\u0003\u0002\u0002\u0002\u0210\u0211\u0007\u001a\u0002",
    "\u0002\u0211\u0212\u0007\u001b\u0002\u0002\u0212\u0216\u0007K\u0002",
    "\u0002\u0213\u0215\u0007J\u0002\u0002\u0214\u0213\u0003\u0002\u0002",
    "\u0002\u0215\u0218\u0003\u0002\u0002\u0002\u0216\u0214\u0003\u0002\u0002",
    "\u0002\u0216\u0217\u0003\u0002\u0002\u0002\u0217\u0219\u0003\u0002\u0002",
    "\u0002\u0218\u0216\u0003\u0002\u0002\u0002\u0219\u021a\u0007\u0010\u0002",
    "\u0002\u021a\u023a\u0005\u0014\u000b\u0002\u021b\u021c\u0007\u001a\u0002",
    "\u0002\u021c\u0220\u0007K\u0002\u0002\u021d\u021f\u0007J\u0002\u0002",
    "\u021e\u021d\u0003\u0002\u0002\u0002\u021f\u0222\u0003\u0002\u0002\u0002",
    "\u0220\u021e\u0003\u0002\u0002\u0002\u0220\u0221\u0003\u0002\u0002\u0002",
    "\u0221\u0223\u0003\u0002\u0002\u0002\u0222\u0220\u0003\u0002\u0002\u0002",
    "\u0223\u0225\u0007\u0010\u0002\u0002\u0224\u0226\u0007\n\u0002\u0002",
    "\u0225\u0224\u0003\u0002\u0002\u0002\u0225\u0226\u0003\u0002\u0002\u0002",
    "\u0226\u0227\u0003\u0002\u0002\u0002\u0227\u022c\u00052\u001a\u0002",
    "\u0228\u0229\u0007\n\u0002\u0002\u0229\u022b\u00052\u001a\u0002\u022a",
    "\u0228\u0003\u0002\u0002\u0002\u022b\u022e\u0003\u0002\u0002\u0002\u022c",
    "\u022a\u0003\u0002\u0002\u0002\u022c\u022d\u0003\u0002\u0002\u0002\u022d",
    "\u023a\u0003\u0002\u0002\u0002\u022e\u022c\u0003\u0002\u0002\u0002\u022f",
    "\u0230\u0007\u001a\u0002\u0002\u0230\u0234\u0007K\u0002\u0002\u0231",
    "\u0233\u0007J\u0002\u0002\u0232\u0231\u0003\u0002\u0002\u0002\u0233",
    "\u0236\u0003\u0002\u0002\u0002\u0234\u0232\u0003\u0002\u0002\u0002\u0234",
    "\u0235\u0003\u0002\u0002\u0002\u0235\u0237\u0003\u0002\u0002\u0002\u0236",
    "\u0234\u0003\u0002\u0002\u0002\u0237\u0238\u0007\u0010\u0002\u0002\u0238",
    "\u023a\u0007(\u0002\u0002\u0239\u01fd\u0003\u0002\u0002\u0002\u0239",
    "\u0204\u0003\u0002\u0002\u0002\u0239\u0210\u0003\u0002\u0002\u0002\u0239",
    "\u021b\u0003\u0002\u0002\u0002\u0239\u022f\u0003\u0002\u0002\u0002\u023a",
    "7\u0003\u0002\u0002\u0002\u023b\u023c\b\u001d\u0001\u0002\u023c\u023d",
    "\u0007\u0003\u0002\u0002\u023d\u023e\u00058\u001d\u0002\u023e\u023f",
    "\u0007\u0005\u0002\u0002\u023f\u026b\u0003\u0002\u0002\u0002\u0240\u026b",
    "\u0007J\u0002\u0002\u0241\u0245\u0007K\u0002\u0002\u0242\u0244\u0007",
    "H\u0002\u0002\u0243\u0242\u0003\u0002\u0002\u0002\u0244\u0247\u0003",
    "\u0002\u0002\u0002\u0245\u0243\u0003\u0002\u0002\u0002\u0245\u0246\u0003",
    "\u0002\u0002\u0002\u0246\u024b\u0003\u0002\u0002\u0002\u0247\u0245\u0003",
    "\u0002\u0002\u0002\u0248\u024a\u00058\u001d\u0002\u0249\u0248\u0003",
    "\u0002\u0002\u0002\u024a\u024d\u0003\u0002\u0002\u0002\u024b\u0249\u0003",
    "\u0002\u0002\u0002\u024b\u024c\u0003\u0002\u0002\u0002\u024c\u026b\u0003",
    "\u0002\u0002\u0002\u024d\u024b\u0003\u0002\u0002\u0002\u024e\u026b\u0007",
    "\u0011\u0002\u0002\u024f\u0250\u0007\u0003\u0002\u0002\u0250\u026b\u0007",
    "\u0005\u0002\u0002\u0251\u0252\u0007\u0003\u0002\u0002\u0252\u0255\u0005",
    "8\u001d\u0002\u0253\u0254\u0007\u0004\u0002\u0002\u0254\u0256\u0005",
    "8\u001d\u0002\u0255\u0253\u0003\u0002\u0002\u0002\u0256\u0257\u0003",
    "\u0002\u0002\u0002\u0257\u0255\u0003\u0002\u0002\u0002\u0257\u0258\u0003",
    "\u0002\u0002\u0002\u0258\u0259\u0003\u0002\u0002\u0002\u0259\u025a\u0007",
    "\u0005\u0002\u0002\u025a\u026b\u0003\u0002\u0002\u0002\u025b\u025c\u0007",
    "\u0006\u0002\u0002\u025c\u026b\u0007\u0007\u0002\u0002\u025d\u0266\u0007",
    "\b\u0002\u0002\u025e\u0263\u0007J\u0002\u0002\u025f\u0260\u0007\u0004",
    "\u0002\u0002\u0260\u0262\u0007J\u0002\u0002\u0261\u025f\u0003\u0002",
    "\u0002\u0002\u0262\u0265\u0003\u0002\u0002\u0002\u0263\u0261\u0003\u0002",
    "\u0002\u0002\u0263\u0264\u0003\u0002\u0002\u0002\u0264\u0267\u0003\u0002",
    "\u0002\u0002\u0265\u0263\u0003\u0002\u0002\u0002\u0266\u025e\u0003\u0002",
    "\u0002\u0002\u0266\u0267\u0003\u0002\u0002\u0002\u0267\u0268\u0003\u0002",
    "\u0002\u0002\u0268\u026b\u0007\t\u0002\u0002\u0269\u026b\u0005<\u001f",
    "\u0002\u026a\u023b\u0003\u0002\u0002\u0002\u026a\u0240\u0003\u0002\u0002",
    "\u0002\u026a\u0241\u0003\u0002\u0002\u0002\u026a\u024e\u0003\u0002\u0002",
    "\u0002\u026a\u024f\u0003\u0002\u0002\u0002\u026a\u0251\u0003\u0002\u0002",
    "\u0002\u026a\u025b\u0003\u0002\u0002\u0002\u026a\u025d\u0003\u0002\u0002",
    "\u0002\u026a\u0269\u0003\u0002\u0002\u0002\u026b\u0271\u0003\u0002\u0002",
    "\u0002\u026c\u026d\f\u0005\u0002\u0002\u026d\u026e\u0007;\u0002\u0002",
    "\u026e\u0270\u00058\u001d\u0005\u026f\u026c\u0003\u0002\u0002\u0002",
    "\u0270\u0273\u0003\u0002\u0002\u0002\u0271\u026f\u0003\u0002\u0002\u0002",
    "\u0271\u0272\u0003\u0002\u0002\u0002\u02729\u0003\u0002\u0002\u0002",
    "\u0273\u0271\u0003\u0002\u0002\u0002\u0274\u0275\u0007J\u0002\u0002",
    "\u0275;\u0003\u0002\u0002\u0002\u0276\u027e\u0007M\u0002\u0002\u0277",
    "\u027e\u0007L\u0002\u0002\u0278\u027e\u0007N\u0002\u0002\u0279\u027e",
    "\u0007A\u0002\u0002\u027a\u027e\u0007F\u0002\u0002\u027b\u027e\u0007",
    "%\u0002\u0002\u027c\u027e\u0007&\u0002\u0002\u027d\u0276\u0003\u0002",
    "\u0002\u0002\u027d\u0277\u0003\u0002\u0002\u0002\u027d\u0278\u0003\u0002",
    "\u0002\u0002\u027d\u0279\u0003\u0002\u0002\u0002\u027d\u027a\u0003\u0002",
    "\u0002\u0002\u027d\u027b\u0003\u0002\u0002\u0002\u027d\u027c\u0003\u0002",
    "\u0002\u0002\u027e=\u0003\u0002\u0002\u0002\u027f\u0282\u0007J\u0002",
    "\u0002\u0280\u0281\u0007\u0010\u0002\u0002\u0281\u0283\u0005\f\u0007",
    "\u0002\u0282\u0280\u0003\u0002\u0002\u0002\u0282\u0283\u0003\u0002\u0002",
    "\u0002\u0283?\u0003\u0002\u0002\u0002FDHLTV\\`gkru\u0082\u0087\u008e",
    "\u0098\u00a2\u00ae\u00b5\u00bd\u00c8\u00d2\u00d5\u00df\u00e2\u00eb\u00ee",
    "\u00f6\u00fe\u0109\u0123\u0135\u013d\u0145\u0171\u0173\u0181\u0186\u0195",
    "\u01a5\u01b1\u01bc\u01bf\u01c7\u01cd\u01d3\u01df\u01e6\u01f2\u01f4\u01f8",
    "\u01ff\u0208\u020b\u0216\u0220\u0225\u022c\u0234\u0239\u0245\u024b\u0257",
    "\u0263\u0266\u026a\u0271\u027d\u0282"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'('", "','", "')'", "'['", "']'", "'{'", "'}'", 
                     "'|'", "'\\cc'", "'->'", "'\\'", "':'", "'!'", "'='", 
                     "'_'", "'if'", "'then'", "'else'", "'case'", "'of'", 
                     "'let'", "'rec'", "'in'", "'type'", "'alias'", "'as'", 
                     "'import'", "'export'", "'exposing'", "'module'", null, 
                     null, "'xor'", "'not'", "'True'", "'False'", "'external'", 
                     "'opaque'", null, null, "'^'", "'*'", "'/'", "'+'", 
                     "'-'", "'%'", "';'", "'..'", "'#'", "'~'", "'>'", "'>='", 
                     "'=='", "'<'", "'<='", null, "'::'", "'++'", "'<|'", 
                     "'|>'", "'<<'", "'>>'" ];

var symbolicNames = [ null, null, null, null, null, null, null, null, null, 
                      null, null, null, null, null, null, null, "IF", "THEN", 
                      "ELSE", "CASE", "OF", "LET", "REC", "IN", "TYPE", 
                      "ALIAS", "AS", "IMPORT", "EXPORT", "EXPOSING", "MODULE", 
                      "AND", "OR", "XOR", "NOT", "TRUE", "FALSE", "EXTERNAL", 
                      "OPAQUE", "MultiLineComment", "SingleLineComment", 
                      "POW", "MUL", "DIV", "ADD", "SUB", "MOD", "SEMICOLON", 
                      "ELLIPSIS", "SHARP", "WAVE", "GT", "GE", "EQ", "LT", 
                      "LE", "NE", "CONS", "APPEND", "PIPE_FROM", "PIPE_TO", 
                      "COMP_FROM", "COMP_TO", "INTEGER", "DECIMAL_INTEGER", 
                      "OCT_INTEGER", "HEX_INTEGER", "BIN_INTEGER", "FLOAT_NUMBER", 
                      "IMAG_NUMBER", "DOT_CAP_NAME", "DOT_NAME", "NAME", 
                      "CAP_NAME", "STRING", "CHAR", "LONG_STRING", "NL", 
                      "WS" ];

var ruleNames =  [ "exprs", "defs", "moduleDecl", "importStmt", "exposedName", 
                   "expr", "binOp", "caseBranch", "fieldTypeDecl", "typeDecl", 
                   "typeDeclAlt", "varTypeDecl", "roVarTypeDecl", "unitTypeDecl", 
                   "tupleTypeDecl", "recordExtTypeDecl", "recordTypeDecl", 
                   "roRecordTypeDecl", "woOptionalRecordTypeDecl", "woRecordTypeDecl", 
                   "opaquedTypeDecl", "typeDeclBracket", "constr0TypeDecl", 
                   "constrTypeDecl", "constrDecl", "typeAnnot", "def", "pattern", 
                   "ref", "literal", "recBind" ];

function BwaxParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

BwaxParser.prototype = Object.create(antlr4.Parser.prototype);
BwaxParser.prototype.constructor = BwaxParser;

Object.defineProperty(BwaxParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

BwaxParser.EOF = antlr4.Token.EOF;
BwaxParser.T__0 = 1;
BwaxParser.T__1 = 2;
BwaxParser.T__2 = 3;
BwaxParser.T__3 = 4;
BwaxParser.T__4 = 5;
BwaxParser.T__5 = 6;
BwaxParser.T__6 = 7;
BwaxParser.T__7 = 8;
BwaxParser.T__8 = 9;
BwaxParser.T__9 = 10;
BwaxParser.T__10 = 11;
BwaxParser.T__11 = 12;
BwaxParser.T__12 = 13;
BwaxParser.T__13 = 14;
BwaxParser.T__14 = 15;
BwaxParser.IF = 16;
BwaxParser.THEN = 17;
BwaxParser.ELSE = 18;
BwaxParser.CASE = 19;
BwaxParser.OF = 20;
BwaxParser.LET = 21;
BwaxParser.REC = 22;
BwaxParser.IN = 23;
BwaxParser.TYPE = 24;
BwaxParser.ALIAS = 25;
BwaxParser.AS = 26;
BwaxParser.IMPORT = 27;
BwaxParser.EXPORT = 28;
BwaxParser.EXPOSING = 29;
BwaxParser.MODULE = 30;
BwaxParser.AND = 31;
BwaxParser.OR = 32;
BwaxParser.XOR = 33;
BwaxParser.NOT = 34;
BwaxParser.TRUE = 35;
BwaxParser.FALSE = 36;
BwaxParser.EXTERNAL = 37;
BwaxParser.OPAQUE = 38;
BwaxParser.MultiLineComment = 39;
BwaxParser.SingleLineComment = 40;
BwaxParser.POW = 41;
BwaxParser.MUL = 42;
BwaxParser.DIV = 43;
BwaxParser.ADD = 44;
BwaxParser.SUB = 45;
BwaxParser.MOD = 46;
BwaxParser.SEMICOLON = 47;
BwaxParser.ELLIPSIS = 48;
BwaxParser.SHARP = 49;
BwaxParser.WAVE = 50;
BwaxParser.GT = 51;
BwaxParser.GE = 52;
BwaxParser.EQ = 53;
BwaxParser.LT = 54;
BwaxParser.LE = 55;
BwaxParser.NE = 56;
BwaxParser.CONS = 57;
BwaxParser.APPEND = 58;
BwaxParser.PIPE_FROM = 59;
BwaxParser.PIPE_TO = 60;
BwaxParser.COMP_FROM = 61;
BwaxParser.COMP_TO = 62;
BwaxParser.INTEGER = 63;
BwaxParser.DECIMAL_INTEGER = 64;
BwaxParser.OCT_INTEGER = 65;
BwaxParser.HEX_INTEGER = 66;
BwaxParser.BIN_INTEGER = 67;
BwaxParser.FLOAT_NUMBER = 68;
BwaxParser.IMAG_NUMBER = 69;
BwaxParser.DOT_CAP_NAME = 70;
BwaxParser.DOT_NAME = 71;
BwaxParser.NAME = 72;
BwaxParser.CAP_NAME = 73;
BwaxParser.STRING = 74;
BwaxParser.CHAR = 75;
BwaxParser.LONG_STRING = 76;
BwaxParser.NL = 77;
BwaxParser.WS = 78;

BwaxParser.RULE_exprs = 0;
BwaxParser.RULE_defs = 1;
BwaxParser.RULE_moduleDecl = 2;
BwaxParser.RULE_importStmt = 3;
BwaxParser.RULE_exposedName = 4;
BwaxParser.RULE_expr = 5;
BwaxParser.RULE_binOp = 6;
BwaxParser.RULE_caseBranch = 7;
BwaxParser.RULE_fieldTypeDecl = 8;
BwaxParser.RULE_typeDecl = 9;
BwaxParser.RULE_typeDeclAlt = 10;
BwaxParser.RULE_varTypeDecl = 11;
BwaxParser.RULE_roVarTypeDecl = 12;
BwaxParser.RULE_unitTypeDecl = 13;
BwaxParser.RULE_tupleTypeDecl = 14;
BwaxParser.RULE_recordExtTypeDecl = 15;
BwaxParser.RULE_recordTypeDecl = 16;
BwaxParser.RULE_roRecordTypeDecl = 17;
BwaxParser.RULE_woOptionalRecordTypeDecl = 18;
BwaxParser.RULE_woRecordTypeDecl = 19;
BwaxParser.RULE_opaquedTypeDecl = 20;
BwaxParser.RULE_typeDeclBracket = 21;
BwaxParser.RULE_constr0TypeDecl = 22;
BwaxParser.RULE_constrTypeDecl = 23;
BwaxParser.RULE_constrDecl = 24;
BwaxParser.RULE_typeAnnot = 25;
BwaxParser.RULE_def = 26;
BwaxParser.RULE_pattern = 27;
BwaxParser.RULE_ref = 28;
BwaxParser.RULE_literal = 29;
BwaxParser.RULE_recBind = 30;


function ExprsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_exprs;
    return this;
}

ExprsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprsContext.prototype.constructor = ExprsContext;

ExprsContext.prototype.EOF = function() {
    return this.getToken(BwaxParser.EOF, 0);
};

ExprsContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

ExprsContext.prototype.SEMICOLON = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.SEMICOLON);
    } else {
        return this.getToken(BwaxParser.SEMICOLON, i);
    }
};


ExprsContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterExprs(this);
	}
};

ExprsContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitExprs(this);
	}
};

ExprsContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitExprs(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.ExprsContext = ExprsContext;

BwaxParser.prototype.exprs = function() {

    var localctx = new ExprsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, BwaxParser.RULE_exprs);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 70;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,1,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 62;
                this.expr(0);
                this.state = 64; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                do {
                    this.state = 63;
                    this.match(BwaxParser.SEMICOLON);
                    this.state = 66; 
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                } while(_la===BwaxParser.SEMICOLON); 
            }
            this.state = 72;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,1,this._ctx);
        }

        this.state = 74;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << BwaxParser.T__0) | (1 << BwaxParser.T__3) | (1 << BwaxParser.T__5) | (1 << BwaxParser.T__8) | (1 << BwaxParser.T__10) | (1 << BwaxParser.IF) | (1 << BwaxParser.CASE) | (1 << BwaxParser.LET))) !== 0) || ((((_la - 34)) & ~0x1f) == 0 && ((1 << (_la - 34)) & ((1 << (BwaxParser.NOT - 34)) | (1 << (BwaxParser.TRUE - 34)) | (1 << (BwaxParser.FALSE - 34)) | (1 << (BwaxParser.EXTERNAL - 34)) | (1 << (BwaxParser.WAVE - 34)) | (1 << (BwaxParser.INTEGER - 34)))) !== 0) || ((((_la - 68)) & ~0x1f) == 0 && ((1 << (_la - 68)) & ((1 << (BwaxParser.FLOAT_NUMBER - 68)) | (1 << (BwaxParser.NAME - 68)) | (1 << (BwaxParser.CAP_NAME - 68)) | (1 << (BwaxParser.STRING - 68)) | (1 << (BwaxParser.CHAR - 68)) | (1 << (BwaxParser.LONG_STRING - 68)))) !== 0)) {
            this.state = 73;
            this.expr(0);
        }

        this.state = 76;
        this.match(BwaxParser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function DefsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_defs;
    return this;
}

DefsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DefsContext.prototype.constructor = DefsContext;

DefsContext.prototype.EOF = function() {
    return this.getToken(BwaxParser.EOF, 0);
};

DefsContext.prototype.moduleDecl = function() {
    return this.getTypedRuleContext(ModuleDeclContext,0);
};

DefsContext.prototype.importStmt = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ImportStmtContext);
    } else {
        return this.getTypedRuleContext(ImportStmtContext,i);
    }
};

DefsContext.prototype.def = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(DefContext);
    } else {
        return this.getTypedRuleContext(DefContext,i);
    }
};

DefsContext.prototype.SEMICOLON = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.SEMICOLON);
    } else {
        return this.getToken(BwaxParser.SEMICOLON, i);
    }
};


DefsContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterDefs(this);
	}
};

DefsContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitDefs(this);
	}
};

DefsContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitDefs(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.DefsContext = DefsContext;

BwaxParser.prototype.defs = function() {

    var localctx = new DefsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, BwaxParser.RULE_defs);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 84;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===BwaxParser.MODULE) {
            this.state = 78;
            this.moduleDecl();
            this.state = 80; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 79;
                this.match(BwaxParser.SEMICOLON);
                this.state = 82; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===BwaxParser.SEMICOLON);
        }

        this.state = 94;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===BwaxParser.IMPORT) {
            this.state = 86;
            this.importStmt();
            this.state = 88; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 87;
                this.match(BwaxParser.SEMICOLON);
                this.state = 90; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===BwaxParser.SEMICOLON);
            this.state = 96;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 105;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,8,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 97;
                this.def();
                this.state = 99; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                do {
                    this.state = 98;
                    this.match(BwaxParser.SEMICOLON);
                    this.state = 101; 
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                } while(_la===BwaxParser.SEMICOLON); 
            }
            this.state = 107;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,8,this._ctx);
        }

        this.state = 115;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << BwaxParser.T__0) | (1 << BwaxParser.T__3) | (1 << BwaxParser.T__5) | (1 << BwaxParser.T__14) | (1 << BwaxParser.TYPE))) !== 0) || ((((_la - 35)) & ~0x1f) == 0 && ((1 << (_la - 35)) & ((1 << (BwaxParser.TRUE - 35)) | (1 << (BwaxParser.FALSE - 35)) | (1 << (BwaxParser.INTEGER - 35)))) !== 0) || ((((_la - 68)) & ~0x1f) == 0 && ((1 << (_la - 68)) & ((1 << (BwaxParser.FLOAT_NUMBER - 68)) | (1 << (BwaxParser.NAME - 68)) | (1 << (BwaxParser.CAP_NAME - 68)) | (1 << (BwaxParser.STRING - 68)) | (1 << (BwaxParser.CHAR - 68)) | (1 << (BwaxParser.LONG_STRING - 68)))) !== 0)) {
            this.state = 108;
            this.def();
            this.state = 112;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.SEMICOLON) {
                this.state = 109;
                this.match(BwaxParser.SEMICOLON);
                this.state = 114;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
        }

        this.state = 117;
        this.match(BwaxParser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ModuleDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_moduleDecl;
    return this;
}

ModuleDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ModuleDeclContext.prototype.constructor = ModuleDeclContext;

ModuleDeclContext.prototype.MODULE = function() {
    return this.getToken(BwaxParser.MODULE, 0);
};

ModuleDeclContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ModuleDeclContext.prototype.EXPOSING = function() {
    return this.getToken(BwaxParser.EXPOSING, 0);
};

ModuleDeclContext.prototype.exposedName = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExposedNameContext);
    } else {
        return this.getTypedRuleContext(ExposedNameContext,i);
    }
};

ModuleDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterModuleDecl(this);
	}
};

ModuleDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitModuleDecl(this);
	}
};

ModuleDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitModuleDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.ModuleDeclContext = ModuleDeclContext;

BwaxParser.prototype.moduleDecl = function() {

    var localctx = new ModuleDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, BwaxParser.RULE_moduleDecl);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 119;
        this.match(BwaxParser.MODULE);
        this.state = 120;
        this.match(BwaxParser.CAP_NAME);
        this.state = 133;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===BwaxParser.EXPOSING) {
            this.state = 121;
            this.match(BwaxParser.EXPOSING);
            this.state = 122;
            this.match(BwaxParser.T__0);
            this.state = 123;
            this.exposedName();
            this.state = 128;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.T__1) {
                this.state = 124;
                this.match(BwaxParser.T__1);
                this.state = 125;
                this.exposedName();
                this.state = 130;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 131;
            this.match(BwaxParser.T__2);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ImportStmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_importStmt;
    return this;
}

ImportStmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ImportStmtContext.prototype.constructor = ImportStmtContext;


 
ImportStmtContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function ImportExposingContext(parser, ctx) {
	ImportStmtContext.call(this, parser);
    ImportStmtContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ImportExposingContext.prototype = Object.create(ImportStmtContext.prototype);
ImportExposingContext.prototype.constructor = ImportExposingContext;

BwaxParser.ImportExposingContext = ImportExposingContext;

ImportExposingContext.prototype.IMPORT = function() {
    return this.getToken(BwaxParser.IMPORT, 0);
};

ImportExposingContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ImportExposingContext.prototype.EXPOSING = function() {
    return this.getToken(BwaxParser.EXPOSING, 0);
};

ImportExposingContext.prototype.exposedName = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExposedNameContext);
    } else {
        return this.getTypedRuleContext(ExposedNameContext,i);
    }
};

ImportExposingContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};

ImportExposingContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterImportExposing(this);
	}
};

ImportExposingContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitImportExposing(this);
	}
};

ImportExposingContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitImportExposing(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ImportExposingAllContext(parser, ctx) {
	ImportStmtContext.call(this, parser);
    ImportStmtContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ImportExposingAllContext.prototype = Object.create(ImportStmtContext.prototype);
ImportExposingAllContext.prototype.constructor = ImportExposingAllContext;

BwaxParser.ImportExposingAllContext = ImportExposingAllContext;

ImportExposingAllContext.prototype.IMPORT = function() {
    return this.getToken(BwaxParser.IMPORT, 0);
};

ImportExposingAllContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ImportExposingAllContext.prototype.EXPOSING = function() {
    return this.getToken(BwaxParser.EXPOSING, 0);
};

ImportExposingAllContext.prototype.ELLIPSIS = function() {
    return this.getToken(BwaxParser.ELLIPSIS, 0);
};

ImportExposingAllContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};

ImportExposingAllContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterImportExposingAll(this);
	}
};

ImportExposingAllContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitImportExposingAll(this);
	}
};

ImportExposingAllContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitImportExposingAll(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ImportNameContext(parser, ctx) {
	ImportStmtContext.call(this, parser);
    ImportStmtContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ImportNameContext.prototype = Object.create(ImportStmtContext.prototype);
ImportNameContext.prototype.constructor = ImportNameContext;

BwaxParser.ImportNameContext = ImportNameContext;

ImportNameContext.prototype.IMPORT = function() {
    return this.getToken(BwaxParser.IMPORT, 0);
};

ImportNameContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ImportNameContext.prototype.AS = function() {
    return this.getToken(BwaxParser.AS, 0);
};

ImportNameContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

ImportNameContext.prototype.DOT_NAME = function() {
    return this.getToken(BwaxParser.DOT_NAME, 0);
};

ImportNameContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};

ImportNameContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterImportName(this);
	}
};

ImportNameContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitImportName(this);
	}
};

ImportNameContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitImportName(this);
    } else {
        return visitor.visitChildren(this);
    }
};



BwaxParser.ImportStmtContext = ImportStmtContext;

BwaxParser.prototype.importStmt = function() {

    var localctx = new ImportStmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, BwaxParser.RULE_importStmt);
    var _la = 0; // Token type
    try {
        this.state = 179;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,17,this._ctx);
        switch(la_) {
        case 1:
            localctx = new ImportExposingContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 135;
            this.match(BwaxParser.IMPORT);
            this.state = 136;
            this.match(BwaxParser.CAP_NAME);
            this.state = 140;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.DOT_CAP_NAME) {
                this.state = 137;
                this.match(BwaxParser.DOT_CAP_NAME);
                this.state = 142;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 143;
            this.match(BwaxParser.EXPOSING);
            this.state = 144;
            this.match(BwaxParser.T__0);
            this.state = 145;
            this.exposedName();
            this.state = 150;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.T__1) {
                this.state = 146;
                this.match(BwaxParser.T__1);
                this.state = 147;
                this.exposedName();
                this.state = 152;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 153;
            this.match(BwaxParser.T__2);
            break;

        case 2:
            localctx = new ImportExposingAllContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 155;
            this.match(BwaxParser.IMPORT);
            this.state = 156;
            this.match(BwaxParser.CAP_NAME);
            this.state = 160;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.DOT_CAP_NAME) {
                this.state = 157;
                this.match(BwaxParser.DOT_CAP_NAME);
                this.state = 162;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 163;
            this.match(BwaxParser.EXPOSING);
            this.state = 164;
            this.match(BwaxParser.T__0);
            this.state = 165;
            this.match(BwaxParser.ELLIPSIS);
            this.state = 166;
            this.match(BwaxParser.T__2);
            break;

        case 3:
            localctx = new ImportNameContext(this, localctx);
            this.enterOuterAlt(localctx, 3);
            this.state = 167;
            this.match(BwaxParser.IMPORT);
            this.state = 168;
            this.match(BwaxParser.CAP_NAME);

            this.state = 172;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.DOT_CAP_NAME) {
                this.state = 169;
                this.match(BwaxParser.DOT_CAP_NAME);
                this.state = 174;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 175;
            this.match(BwaxParser.DOT_NAME);
            this.state = 177;
            this.match(BwaxParser.AS);
            this.state = 178;
            this.match(BwaxParser.NAME);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ExposedNameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_exposedName;
    return this;
}

ExposedNameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExposedNameContext.prototype.constructor = ExposedNameContext;

ExposedNameContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ExposedNameContext.prototype.ELLIPSIS = function() {
    return this.getToken(BwaxParser.ELLIPSIS, 0);
};

ExposedNameContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

ExposedNameContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterExposedName(this);
	}
};

ExposedNameContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitExposedName(this);
	}
};

ExposedNameContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitExposedName(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.ExposedNameContext = ExposedNameContext;

BwaxParser.prototype.exposedName = function() {

    var localctx = new ExposedNameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, BwaxParser.RULE_exposedName);
    try {
        this.state = 187;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,18,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 181;
            this.match(BwaxParser.CAP_NAME);
            this.state = 182;
            this.match(BwaxParser.T__0);
            this.state = 183;
            this.match(BwaxParser.ELLIPSIS);
            this.state = 184;
            this.match(BwaxParser.T__2);
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 185;
            this.match(BwaxParser.CAP_NAME);
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 186;
            this.match(BwaxParser.NAME);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_expr;
    return this;
}

ExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprContext.prototype.constructor = ExprContext;


 
ExprContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function PowOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

PowOpContext.prototype = Object.create(ExprContext.prototype);
PowOpContext.prototype.constructor = PowOpContext;

BwaxParser.PowOpContext = PowOpContext;

PowOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

PowOpContext.prototype.POW = function() {
    return this.getToken(BwaxParser.POW, 0);
};
PowOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterPowOp(this);
	}
};

PowOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitPowOp(this);
	}
};

PowOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitPowOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function GetterExprContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GetterExprContext.prototype = Object.create(ExprContext.prototype);
GetterExprContext.prototype.constructor = GetterExprContext;

BwaxParser.GetterExprContext = GetterExprContext;

GetterExprContext.prototype.DOT_NAME = function() {
    return this.getToken(BwaxParser.DOT_NAME, 0);
};
GetterExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterGetterExpr(this);
	}
};

GetterExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitGetterExpr(this);
	}
};

GetterExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitGetterExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function BinOpExprContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

BinOpExprContext.prototype = Object.create(ExprContext.prototype);
BinOpExprContext.prototype.constructor = BinOpExprContext;

BwaxParser.BinOpExprContext = BinOpExprContext;

BinOpExprContext.prototype.binOp = function() {
    return this.getTypedRuleContext(BinOpContext,0);
};
BinOpExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterBinOpExpr(this);
	}
};

BinOpExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitBinOpExpr(this);
	}
};

BinOpExprContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitBinOpExpr(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function CallWithCCContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

CallWithCCContext.prototype = Object.create(ExprContext.prototype);
CallWithCCContext.prototype.constructor = CallWithCCContext;

BwaxParser.CallWithCCContext = CallWithCCContext;

CallWithCCContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

CallWithCCContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};
CallWithCCContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCallWithCC(this);
	}
};

CallWithCCContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCallWithCC(this);
	}
};

CallWithCCContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCallWithCC(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function RefExpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

RefExpContext.prototype = Object.create(ExprContext.prototype);
RefExpContext.prototype.constructor = RefExpContext;

BwaxParser.RefExpContext = RefExpContext;

RefExpContext.prototype.ref = function() {
    return this.getTypedRuleContext(RefContext,0);
};
RefExpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRefExp(this);
	}
};

RefExpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRefExp(this);
	}
};

RefExpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRefExp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ConsOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ConsOpContext.prototype = Object.create(ExprContext.prototype);
ConsOpContext.prototype.constructor = ConsOpContext;

BwaxParser.ConsOpContext = ConsOpContext;

ConsOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

ConsOpContext.prototype.CONS = function() {
    return this.getToken(BwaxParser.CONS, 0);
};
ConsOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConsOp(this);
	}
};

ConsOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConsOp(this);
	}
};

ConsOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConsOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function TupleContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TupleContext.prototype = Object.create(ExprContext.prototype);
TupleContext.prototype.constructor = TupleContext;

BwaxParser.TupleContext = TupleContext;

TupleContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};
TupleContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTuple(this);
	}
};

TupleContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTuple(this);
	}
};

TupleContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTuple(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LambdaContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LambdaContext.prototype = Object.create(ExprContext.prototype);
LambdaContext.prototype.constructor = LambdaContext;

BwaxParser.LambdaContext = LambdaContext;

LambdaContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

LambdaContext.prototype.pattern = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PatternContext);
    } else {
        return this.getTypedRuleContext(PatternContext,i);
    }
};
LambdaContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLambda(this);
	}
};

LambdaContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLambda(this);
	}
};

LambdaContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLambda(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LiteralExpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LiteralExpContext.prototype = Object.create(ExprContext.prototype);
LiteralExpContext.prototype.constructor = LiteralExpContext;

BwaxParser.LiteralExpContext = LiteralExpContext;

LiteralExpContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};
LiteralExpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLiteralExp(this);
	}
};

LiteralExpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLiteralExp(this);
	}
};

LiteralExpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLiteralExp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function PipeToContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

PipeToContext.prototype = Object.create(ExprContext.prototype);
PipeToContext.prototype.constructor = PipeToContext;

BwaxParser.PipeToContext = PipeToContext;

PipeToContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

PipeToContext.prototype.PIPE_TO = function() {
    return this.getToken(BwaxParser.PIPE_TO, 0);
};
PipeToContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterPipeTo(this);
	}
};

PipeToContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitPipeTo(this);
	}
};

PipeToContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitPipeTo(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function QualRefContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

QualRefContext.prototype = Object.create(ExprContext.prototype);
QualRefContext.prototype.constructor = QualRefContext;

BwaxParser.QualRefContext = QualRefContext;

QualRefContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

QualRefContext.prototype.DOT_NAME = function() {
    return this.getToken(BwaxParser.DOT_NAME, 0);
};

QualRefContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};

QualRefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterQualRef(this);
	}
};

QualRefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitQualRef(this);
	}
};

QualRefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitQualRef(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function CompToContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

CompToContext.prototype = Object.create(ExprContext.prototype);
CompToContext.prototype.constructor = CompToContext;

BwaxParser.CompToContext = CompToContext;

CompToContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

CompToContext.prototype.COMP_TO = function() {
    return this.getToken(BwaxParser.COMP_TO, 0);
};
CompToContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCompTo(this);
	}
};

CompToContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCompTo(this);
	}
};

CompToContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCompTo(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function RecordContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

RecordContext.prototype = Object.create(ExprContext.prototype);
RecordContext.prototype.constructor = RecordContext;

BwaxParser.RecordContext = RecordContext;

RecordContext.prototype.recBind = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(RecBindContext);
    } else {
        return this.getTypedRuleContext(RecBindContext,i);
    }
};
RecordContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecord(this);
	}
};

RecordContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecord(this);
	}
};

RecordContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecord(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function BracketContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

BracketContext.prototype = Object.create(ExprContext.prototype);
BracketContext.prototype.constructor = BracketContext;

BwaxParser.BracketContext = BracketContext;

BracketContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};
BracketContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterBracket(this);
	}
};

BracketContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitBracket(this);
	}
};

BracketContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitBracket(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LetContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LetContext.prototype = Object.create(ExprContext.prototype);
LetContext.prototype.constructor = LetContext;

BwaxParser.LetContext = LetContext;

LetContext.prototype.LET = function() {
    return this.getToken(BwaxParser.LET, 0);
};

LetContext.prototype.def = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(DefContext);
    } else {
        return this.getTypedRuleContext(DefContext,i);
    }
};

LetContext.prototype.IN = function() {
    return this.getToken(BwaxParser.IN, 0);
};

LetContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

LetContext.prototype.AND = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.AND);
    } else {
        return this.getToken(BwaxParser.AND, i);
    }
};

LetContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLet(this);
	}
};

LetContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLet(this);
	}
};

LetContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLet(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function IfContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

IfContext.prototype = Object.create(ExprContext.prototype);
IfContext.prototype.constructor = IfContext;

BwaxParser.IfContext = IfContext;

IfContext.prototype.IF = function() {
    return this.getToken(BwaxParser.IF, 0);
};

IfContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

IfContext.prototype.THEN = function() {
    return this.getToken(BwaxParser.THEN, 0);
};

IfContext.prototype.ELSE = function() {
    return this.getToken(BwaxParser.ELSE, 0);
};
IfContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterIf(this);
	}
};

IfContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitIf(this);
	}
};

IfContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitIf(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function WoRecordListContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

WoRecordListContext.prototype = Object.create(ExprContext.prototype);
WoRecordListContext.prototype.constructor = WoRecordListContext;

BwaxParser.WoRecordListContext = WoRecordListContext;

WoRecordListContext.prototype.WAVE = function() {
    return this.getToken(BwaxParser.WAVE, 0);
};

WoRecordListContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};
WoRecordListContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterWoRecordList(this);
	}
};

WoRecordListContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitWoRecordList(this);
	}
};

WoRecordListContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitWoRecordList(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LogicNotContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LogicNotContext.prototype = Object.create(ExprContext.prototype);
LogicNotContext.prototype.constructor = LogicNotContext;

BwaxParser.LogicNotContext = LogicNotContext;

LogicNotContext.prototype.NOT = function() {
    return this.getToken(BwaxParser.NOT, 0);
};

LogicNotContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};
LogicNotContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLogicNot(this);
	}
};

LogicNotContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLogicNot(this);
	}
};

LogicNotContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLogicNot(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function CaseContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

CaseContext.prototype = Object.create(ExprContext.prototype);
CaseContext.prototype.constructor = CaseContext;

BwaxParser.CaseContext = CaseContext;

CaseContext.prototype.CASE = function() {
    return this.getToken(BwaxParser.CASE, 0);
};

CaseContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

CaseContext.prototype.OF = function() {
    return this.getToken(BwaxParser.OF, 0);
};

CaseContext.prototype.caseBranch = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(CaseBranchContext);
    } else {
        return this.getTypedRuleContext(CaseBranchContext,i);
    }
};
CaseContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCase(this);
	}
};

CaseContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCase(this);
	}
};

CaseContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCase(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function TypeAnnoatedExpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TypeAnnoatedExpContext.prototype = Object.create(ExprContext.prototype);
TypeAnnoatedExpContext.prototype.constructor = TypeAnnoatedExpContext;

BwaxParser.TypeAnnoatedExpContext = TypeAnnoatedExpContext;

TypeAnnoatedExpContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

TypeAnnoatedExpContext.prototype.typeAnnot = function() {
    return this.getTypedRuleContext(TypeAnnotContext,0);
};
TypeAnnoatedExpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeAnnoatedExp(this);
	}
};

TypeAnnoatedExpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeAnnoatedExp(this);
	}
};

TypeAnnoatedExpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeAnnoatedExp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ApplyContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ApplyContext.prototype = Object.create(ExprContext.prototype);
ApplyContext.prototype.constructor = ApplyContext;

BwaxParser.ApplyContext = ApplyContext;

ApplyContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};
ApplyContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterApply(this);
	}
};

ApplyContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitApply(this);
	}
};

ApplyContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitApply(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function EqualityOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

EqualityOpContext.prototype = Object.create(ExprContext.prototype);
EqualityOpContext.prototype.constructor = EqualityOpContext;

BwaxParser.EqualityOpContext = EqualityOpContext;

EqualityOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

EqualityOpContext.prototype.NE = function() {
    return this.getToken(BwaxParser.NE, 0);
};

EqualityOpContext.prototype.EQ = function() {
    return this.getToken(BwaxParser.EQ, 0);
};
EqualityOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterEqualityOp(this);
	}
};

EqualityOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitEqualityOp(this);
	}
};

EqualityOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitEqualityOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function AddOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

AddOpContext.prototype = Object.create(ExprContext.prototype);
AddOpContext.prototype.constructor = AddOpContext;

BwaxParser.AddOpContext = AddOpContext;

AddOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

AddOpContext.prototype.ADD = function() {
    return this.getToken(BwaxParser.ADD, 0);
};

AddOpContext.prototype.SUB = function() {
    return this.getToken(BwaxParser.SUB, 0);
};
AddOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterAddOp(this);
	}
};

AddOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitAddOp(this);
	}
};

AddOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitAddOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ConstructorContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ConstructorContext.prototype = Object.create(ExprContext.prototype);
ConstructorContext.prototype.constructor = ConstructorContext;

BwaxParser.ConstructorContext = ConstructorContext;

ConstructorContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ConstructorContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};

ConstructorContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConstructor(this);
	}
};

ConstructorContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConstructor(this);
	}
};

ConstructorContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConstructor(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ListContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ListContext.prototype = Object.create(ExprContext.prototype);
ListContext.prototype.constructor = ListContext;

BwaxParser.ListContext = ListContext;

ListContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};
ListContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterList(this);
	}
};

ListContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitList(this);
	}
};

ListContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitList(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function CompareOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

CompareOpContext.prototype = Object.create(ExprContext.prototype);
CompareOpContext.prototype.constructor = CompareOpContext;

BwaxParser.CompareOpContext = CompareOpContext;

CompareOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

CompareOpContext.prototype.GT = function() {
    return this.getToken(BwaxParser.GT, 0);
};

CompareOpContext.prototype.GE = function() {
    return this.getToken(BwaxParser.GE, 0);
};

CompareOpContext.prototype.LE = function() {
    return this.getToken(BwaxParser.LE, 0);
};

CompareOpContext.prototype.LT = function() {
    return this.getToken(BwaxParser.LT, 0);
};
CompareOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCompareOp(this);
	}
};

CompareOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCompareOp(this);
	}
};

CompareOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCompareOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function CompFromContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

CompFromContext.prototype = Object.create(ExprContext.prototype);
CompFromContext.prototype.constructor = CompFromContext;

BwaxParser.CompFromContext = CompFromContext;

CompFromContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

CompFromContext.prototype.COMP_FROM = function() {
    return this.getToken(BwaxParser.COMP_FROM, 0);
};
CompFromContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCompFrom(this);
	}
};

CompFromContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCompFrom(this);
	}
};

CompFromContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCompFrom(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function UnitContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

UnitContext.prototype = Object.create(ExprContext.prototype);
UnitContext.prototype.constructor = UnitContext;

BwaxParser.UnitContext = UnitContext;

UnitContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterUnit(this);
	}
};

UnitContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitUnit(this);
	}
};

UnitContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitUnit(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ExternalContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ExternalContext.prototype = Object.create(ExprContext.prototype);
ExternalContext.prototype.constructor = ExternalContext;

BwaxParser.ExternalContext = ExternalContext;

ExternalContext.prototype.EXTERNAL = function() {
    return this.getToken(BwaxParser.EXTERNAL, 0);
};
ExternalContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterExternal(this);
	}
};

ExternalContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitExternal(this);
	}
};

ExternalContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitExternal(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function RecordGetContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

RecordGetContext.prototype = Object.create(ExprContext.prototype);
RecordGetContext.prototype.constructor = RecordGetContext;

BwaxParser.RecordGetContext = RecordGetContext;

RecordGetContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

RecordGetContext.prototype.DOT_NAME = function() {
    return this.getToken(BwaxParser.DOT_NAME, 0);
};
RecordGetContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecordGet(this);
	}
};

RecordGetContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecordGet(this);
	}
};

RecordGetContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecordGet(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function MulOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

MulOpContext.prototype = Object.create(ExprContext.prototype);
MulOpContext.prototype.constructor = MulOpContext;

BwaxParser.MulOpContext = MulOpContext;

MulOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

MulOpContext.prototype.MUL = function() {
    return this.getToken(BwaxParser.MUL, 0);
};

MulOpContext.prototype.DIV = function() {
    return this.getToken(BwaxParser.DIV, 0);
};

MulOpContext.prototype.MOD = function() {
    return this.getToken(BwaxParser.MOD, 0);
};
MulOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterMulOp(this);
	}
};

MulOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitMulOp(this);
	}
};

MulOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitMulOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function AppendOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

AppendOpContext.prototype = Object.create(ExprContext.prototype);
AppendOpContext.prototype.constructor = AppendOpContext;

BwaxParser.AppendOpContext = AppendOpContext;

AppendOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

AppendOpContext.prototype.APPEND = function() {
    return this.getToken(BwaxParser.APPEND, 0);
};
AppendOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterAppendOp(this);
	}
};

AppendOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitAppendOp(this);
	}
};

AppendOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitAppendOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LogicOpContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LogicOpContext.prototype = Object.create(ExprContext.prototype);
LogicOpContext.prototype.constructor = LogicOpContext;

BwaxParser.LogicOpContext = LogicOpContext;

LogicOpContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

LogicOpContext.prototype.AND = function() {
    return this.getToken(BwaxParser.AND, 0);
};

LogicOpContext.prototype.OR = function() {
    return this.getToken(BwaxParser.OR, 0);
};

LogicOpContext.prototype.XOR = function() {
    return this.getToken(BwaxParser.XOR, 0);
};
LogicOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLogicOp(this);
	}
};

LogicOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLogicOp(this);
	}
};

LogicOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLogicOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function RecordUpdateContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

RecordUpdateContext.prototype = Object.create(ExprContext.prototype);
RecordUpdateContext.prototype.constructor = RecordUpdateContext;

BwaxParser.RecordUpdateContext = RecordUpdateContext;

RecordUpdateContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

RecordUpdateContext.prototype.recBind = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(RecBindContext);
    } else {
        return this.getTypedRuleContext(RecBindContext,i);
    }
};
RecordUpdateContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecordUpdate(this);
	}
};

RecordUpdateContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecordUpdate(this);
	}
};

RecordUpdateContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecordUpdate(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function PipeFromContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

PipeFromContext.prototype = Object.create(ExprContext.prototype);
PipeFromContext.prototype.constructor = PipeFromContext;

BwaxParser.PipeFromContext = PipeFromContext;

PipeFromContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

PipeFromContext.prototype.PIPE_FROM = function() {
    return this.getToken(BwaxParser.PIPE_FROM, 0);
};
PipeFromContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterPipeFrom(this);
	}
};

PipeFromContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitPipeFrom(this);
	}
};

PipeFromContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitPipeFrom(this);
    } else {
        return visitor.visitChildren(this);
    }
};



BwaxParser.prototype.expr = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new ExprContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 10;
    this.enterRecursionRule(localctx, 10, BwaxParser.RULE_expr, _p);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 323;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,32,this._ctx);
        switch(la_) {
        case 1:
            localctx = new UnitContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;

            this.state = 190;
            this.match(BwaxParser.T__0);
            this.state = 191;
            this.match(BwaxParser.T__2);
            break;

        case 2:
            localctx = new TupleContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 192;
            this.match(BwaxParser.T__0);
            this.state = 193;
            this.expr(0);
            this.state = 196; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 194;
                this.match(BwaxParser.T__1);
                this.state = 195;
                this.expr(0);
                this.state = 198; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===BwaxParser.T__1);
            this.state = 200;
            this.match(BwaxParser.T__2);
            break;

        case 3:
            localctx = new ListContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 202;
            this.match(BwaxParser.T__3);
            this.state = 211;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << BwaxParser.T__0) | (1 << BwaxParser.T__3) | (1 << BwaxParser.T__5) | (1 << BwaxParser.T__8) | (1 << BwaxParser.T__10) | (1 << BwaxParser.IF) | (1 << BwaxParser.CASE) | (1 << BwaxParser.LET))) !== 0) || ((((_la - 34)) & ~0x1f) == 0 && ((1 << (_la - 34)) & ((1 << (BwaxParser.NOT - 34)) | (1 << (BwaxParser.TRUE - 34)) | (1 << (BwaxParser.FALSE - 34)) | (1 << (BwaxParser.EXTERNAL - 34)) | (1 << (BwaxParser.WAVE - 34)) | (1 << (BwaxParser.INTEGER - 34)))) !== 0) || ((((_la - 68)) & ~0x1f) == 0 && ((1 << (_la - 68)) & ((1 << (BwaxParser.FLOAT_NUMBER - 68)) | (1 << (BwaxParser.NAME - 68)) | (1 << (BwaxParser.CAP_NAME - 68)) | (1 << (BwaxParser.STRING - 68)) | (1 << (BwaxParser.CHAR - 68)) | (1 << (BwaxParser.LONG_STRING - 68)))) !== 0)) {
                this.state = 203;
                this.expr(0);
                this.state = 208;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                while(_la===BwaxParser.T__1) {
                    this.state = 204;
                    this.match(BwaxParser.T__1);
                    this.state = 205;
                    this.expr(0);
                    this.state = 210;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                }
            }

            this.state = 213;
            this.match(BwaxParser.T__4);
            break;

        case 4:
            localctx = new WoRecordListContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 214;
            this.match(BwaxParser.WAVE);
            this.state = 215;
            this.match(BwaxParser.T__3);
            this.state = 224;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << BwaxParser.T__0) | (1 << BwaxParser.T__3) | (1 << BwaxParser.T__5) | (1 << BwaxParser.T__8) | (1 << BwaxParser.T__10) | (1 << BwaxParser.IF) | (1 << BwaxParser.CASE) | (1 << BwaxParser.LET))) !== 0) || ((((_la - 34)) & ~0x1f) == 0 && ((1 << (_la - 34)) & ((1 << (BwaxParser.NOT - 34)) | (1 << (BwaxParser.TRUE - 34)) | (1 << (BwaxParser.FALSE - 34)) | (1 << (BwaxParser.EXTERNAL - 34)) | (1 << (BwaxParser.WAVE - 34)) | (1 << (BwaxParser.INTEGER - 34)))) !== 0) || ((((_la - 68)) & ~0x1f) == 0 && ((1 << (_la - 68)) & ((1 << (BwaxParser.FLOAT_NUMBER - 68)) | (1 << (BwaxParser.NAME - 68)) | (1 << (BwaxParser.CAP_NAME - 68)) | (1 << (BwaxParser.STRING - 68)) | (1 << (BwaxParser.CHAR - 68)) | (1 << (BwaxParser.LONG_STRING - 68)))) !== 0)) {
                this.state = 216;
                this.expr(0);
                this.state = 221;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                while(_la===BwaxParser.T__1) {
                    this.state = 217;
                    this.match(BwaxParser.T__1);
                    this.state = 218;
                    this.expr(0);
                    this.state = 223;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                }
            }

            this.state = 226;
            this.match(BwaxParser.T__4);
            break;

        case 5:
            localctx = new RecordContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 227;
            this.match(BwaxParser.T__5);
            this.state = 236;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===BwaxParser.NAME) {
                this.state = 228;
                this.recBind();
                this.state = 233;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                while(_la===BwaxParser.T__1) {
                    this.state = 229;
                    this.match(BwaxParser.T__1);
                    this.state = 230;
                    this.recBind();
                    this.state = 235;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                }
            }

            this.state = 238;
            this.match(BwaxParser.T__6);
            break;

        case 6:
            localctx = new ExternalContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 239;
            this.match(BwaxParser.EXTERNAL);
            break;

        case 7:
            localctx = new QualRefContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 240;
            this.match(BwaxParser.CAP_NAME);

            this.state = 244;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.DOT_CAP_NAME) {
                this.state = 241;
                this.match(BwaxParser.DOT_CAP_NAME);
                this.state = 246;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 247;
            this.match(BwaxParser.DOT_NAME);
            break;

        case 8:
            localctx = new ConstructorContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 248;
            this.match(BwaxParser.CAP_NAME);
            this.state = 252;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,27,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 249;
                    this.match(BwaxParser.DOT_CAP_NAME); 
                }
                this.state = 254;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,27,this._ctx);
            }

            break;

        case 9:
            localctx = new RecordUpdateContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 255;
            this.match(BwaxParser.T__5);
            this.state = 256;
            this.match(BwaxParser.NAME);
            this.state = 257;
            this.match(BwaxParser.T__7);
            this.state = 258;
            this.recBind();
            this.state = 263;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.T__1) {
                this.state = 259;
                this.match(BwaxParser.T__1);
                this.state = 260;
                this.recBind();
                this.state = 265;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 266;
            this.match(BwaxParser.T__6);
            break;

        case 10:
            localctx = new GetterExprContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 268;
            this.match(BwaxParser.T__0);
            this.state = 269;
            this.match(BwaxParser.DOT_NAME);
            this.state = 270;
            this.match(BwaxParser.T__2);
            break;

        case 11:
            localctx = new BinOpExprContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 271;
            this.match(BwaxParser.T__0);
            this.state = 272;
            this.binOp();
            this.state = 273;
            this.match(BwaxParser.T__2);
            break;

        case 12:
            localctx = new BracketContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 275;
            this.match(BwaxParser.T__0);
            this.state = 276;
            this.expr(0);
            this.state = 277;
            this.match(BwaxParser.T__2);
            break;

        case 13:
            localctx = new LogicNotContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 279;
            this.match(BwaxParser.NOT);
            this.state = 280;
            this.expr(16);
            break;

        case 14:
            localctx = new CallWithCCContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 281;
            this.match(BwaxParser.T__8);
            this.state = 282;
            this.match(BwaxParser.NAME);
            this.state = 283;
            this.match(BwaxParser.T__9);
            this.state = 284;
            this.expr(8);
            break;

        case 15:
            localctx = new LambdaContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 285;
            this.match(BwaxParser.T__10);
            this.state = 287; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 286;
                this.pattern(0);
                this.state = 289; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << BwaxParser.T__0) | (1 << BwaxParser.T__3) | (1 << BwaxParser.T__5) | (1 << BwaxParser.T__14))) !== 0) || ((((_la - 35)) & ~0x1f) == 0 && ((1 << (_la - 35)) & ((1 << (BwaxParser.TRUE - 35)) | (1 << (BwaxParser.FALSE - 35)) | (1 << (BwaxParser.INTEGER - 35)))) !== 0) || ((((_la - 68)) & ~0x1f) == 0 && ((1 << (_la - 68)) & ((1 << (BwaxParser.FLOAT_NUMBER - 68)) | (1 << (BwaxParser.NAME - 68)) | (1 << (BwaxParser.CAP_NAME - 68)) | (1 << (BwaxParser.STRING - 68)) | (1 << (BwaxParser.CHAR - 68)) | (1 << (BwaxParser.LONG_STRING - 68)))) !== 0));
            this.state = 291;
            this.match(BwaxParser.T__9);
            this.state = 292;
            this.expr(7);
            break;

        case 16:
            localctx = new IfContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 294;
            this.match(BwaxParser.IF);
            this.state = 295;
            this.expr(0);
            this.state = 296;
            this.match(BwaxParser.THEN);
            this.state = 297;
            this.expr(0);
            this.state = 298;
            this.match(BwaxParser.ELSE);
            this.state = 299;
            this.expr(6);
            break;

        case 17:
            localctx = new CaseContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 301;
            this.match(BwaxParser.CASE);
            this.state = 302;
            this.expr(0);
            this.state = 303;
            this.match(BwaxParser.OF);
            this.state = 305; 
            this._errHandler.sync(this);
            var _alt = 1;
            do {
            	switch (_alt) {
            	case 1:
            		this.state = 304;
            		this.caseBranch();
            		break;
            	default:
            		throw new antlr4.error.NoViableAltException(this);
            	}
            	this.state = 307; 
            	this._errHandler.sync(this);
            	_alt = this._interp.adaptivePredict(this._input,30, this._ctx);
            } while ( _alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER );
            break;

        case 18:
            localctx = new LetContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 309;
            this.match(BwaxParser.LET);
            this.state = 310;
            this.def();
            this.state = 315;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.T__1 || _la===BwaxParser.AND) {
                this.state = 311;
                _la = this._input.LA(1);
                if(!(_la===BwaxParser.T__1 || _la===BwaxParser.AND)) {
                this._errHandler.recoverInline(this);
                }
                else {
                	this._errHandler.reportMatch(this);
                    this.consume();
                }
                this.state = 312;
                this.def();
                this.state = 317;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 318;
            this.match(BwaxParser.IN);
            this.state = 319;
            this.expr(4);
            break;

        case 19:
            localctx = new LiteralExpContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 321;
            this.literal();
            break;

        case 20:
            localctx = new RefExpContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 322;
            this.ref();
            break;

        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 369;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,34,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                this.state = 367;
                this._errHandler.sync(this);
                var la_ = this._interp.adaptivePredict(this._input,33,this._ctx);
                switch(la_) {
                case 1:
                    localctx = new ApplyContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 325;
                    if (!( this.precpred(this._ctx, 22))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 22)");
                    }
                    this.state = 326;
                    this.expr(23);
                    break;

                case 2:
                    localctx = new PowOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 327;
                    if (!( this.precpred(this._ctx, 21))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 21)");
                    }
                    this.state = 328;
                    this.match(BwaxParser.POW);
                    this.state = 329;
                    this.expr(21);
                    break;

                case 3:
                    localctx = new MulOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 330;
                    if (!( this.precpred(this._ctx, 20))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 20)");
                    }
                    this.state = 331;
                    _la = this._input.LA(1);
                    if(!(((((_la - 42)) & ~0x1f) == 0 && ((1 << (_la - 42)) & ((1 << (BwaxParser.MUL - 42)) | (1 << (BwaxParser.DIV - 42)) | (1 << (BwaxParser.MOD - 42)))) !== 0))) {
                    this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 332;
                    this.expr(21);
                    break;

                case 4:
                    localctx = new AddOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 333;
                    if (!( this.precpred(this._ctx, 19))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 19)");
                    }
                    this.state = 334;
                    _la = this._input.LA(1);
                    if(!(_la===BwaxParser.ADD || _la===BwaxParser.SUB)) {
                    this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 335;
                    this.expr(20);
                    break;

                case 5:
                    localctx = new CompareOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 336;
                    if (!( this.precpred(this._ctx, 18))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 18)");
                    }
                    this.state = 337;
                    _la = this._input.LA(1);
                    if(!(((((_la - 51)) & ~0x1f) == 0 && ((1 << (_la - 51)) & ((1 << (BwaxParser.GT - 51)) | (1 << (BwaxParser.GE - 51)) | (1 << (BwaxParser.LT - 51)) | (1 << (BwaxParser.LE - 51)))) !== 0))) {
                    this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 338;
                    this.expr(19);
                    break;

                case 6:
                    localctx = new EqualityOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 339;
                    if (!( this.precpred(this._ctx, 17))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 17)");
                    }
                    this.state = 340;
                    _la = this._input.LA(1);
                    if(!(_la===BwaxParser.EQ || _la===BwaxParser.NE)) {
                    this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 341;
                    this.expr(18);
                    break;

                case 7:
                    localctx = new LogicOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 342;
                    if (!( this.precpred(this._ctx, 15))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 15)");
                    }
                    this.state = 343;
                    _la = this._input.LA(1);
                    if(!(((((_la - 31)) & ~0x1f) == 0 && ((1 << (_la - 31)) & ((1 << (BwaxParser.AND - 31)) | (1 << (BwaxParser.OR - 31)) | (1 << (BwaxParser.XOR - 31)))) !== 0))) {
                    this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 344;
                    this.expr(16);
                    break;

                case 8:
                    localctx = new ConsOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 345;
                    if (!( this.precpred(this._ctx, 14))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 14)");
                    }
                    this.state = 346;
                    this.match(BwaxParser.CONS);
                    this.state = 347;
                    this.expr(14);
                    break;

                case 9:
                    localctx = new AppendOpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 348;
                    if (!( this.precpred(this._ctx, 13))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 13)");
                    }
                    this.state = 349;
                    this.match(BwaxParser.APPEND);
                    this.state = 350;
                    this.expr(13);
                    break;

                case 10:
                    localctx = new PipeToContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 351;
                    if (!( this.precpred(this._ctx, 12))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 12)");
                    }
                    this.state = 352;
                    this.match(BwaxParser.PIPE_TO);
                    this.state = 353;
                    this.expr(13);
                    break;

                case 11:
                    localctx = new PipeFromContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 354;
                    if (!( this.precpred(this._ctx, 11))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 11)");
                    }
                    this.state = 355;
                    this.match(BwaxParser.PIPE_FROM);
                    this.state = 356;
                    this.expr(11);
                    break;

                case 12:
                    localctx = new CompToContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 357;
                    if (!( this.precpred(this._ctx, 10))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 10)");
                    }
                    this.state = 358;
                    this.match(BwaxParser.COMP_TO);
                    this.state = 359;
                    this.expr(11);
                    break;

                case 13:
                    localctx = new CompFromContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 360;
                    if (!( this.precpred(this._ctx, 9))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
                    }
                    this.state = 361;
                    this.match(BwaxParser.COMP_FROM);
                    this.state = 362;
                    this.expr(9);
                    break;

                case 14:
                    localctx = new RecordGetContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 363;
                    if (!( this.precpred(this._ctx, 24))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 24)");
                    }
                    this.state = 364;
                    this.match(BwaxParser.DOT_NAME);
                    break;

                case 15:
                    localctx = new TypeAnnoatedExpContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_expr);
                    this.state = 365;
                    if (!( this.precpred(this._ctx, 1))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 1)");
                    }
                    this.state = 366;
                    this.typeAnnot();
                    break;

                } 
            }
            this.state = 371;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,34,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function BinOpContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_binOp;
    return this;
}

BinOpContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
BinOpContext.prototype.constructor = BinOpContext;

BinOpContext.prototype.POW = function() {
    return this.getToken(BwaxParser.POW, 0);
};

BinOpContext.prototype.MUL = function() {
    return this.getToken(BwaxParser.MUL, 0);
};

BinOpContext.prototype.DIV = function() {
    return this.getToken(BwaxParser.DIV, 0);
};

BinOpContext.prototype.MOD = function() {
    return this.getToken(BwaxParser.MOD, 0);
};

BinOpContext.prototype.ADD = function() {
    return this.getToken(BwaxParser.ADD, 0);
};

BinOpContext.prototype.SUB = function() {
    return this.getToken(BwaxParser.SUB, 0);
};

BinOpContext.prototype.GT = function() {
    return this.getToken(BwaxParser.GT, 0);
};

BinOpContext.prototype.GE = function() {
    return this.getToken(BwaxParser.GE, 0);
};

BinOpContext.prototype.LE = function() {
    return this.getToken(BwaxParser.LE, 0);
};

BinOpContext.prototype.LT = function() {
    return this.getToken(BwaxParser.LT, 0);
};

BinOpContext.prototype.NE = function() {
    return this.getToken(BwaxParser.NE, 0);
};

BinOpContext.prototype.EQ = function() {
    return this.getToken(BwaxParser.EQ, 0);
};

BinOpContext.prototype.AND = function() {
    return this.getToken(BwaxParser.AND, 0);
};

BinOpContext.prototype.OR = function() {
    return this.getToken(BwaxParser.OR, 0);
};

BinOpContext.prototype.XOR = function() {
    return this.getToken(BwaxParser.XOR, 0);
};

BinOpContext.prototype.CONS = function() {
    return this.getToken(BwaxParser.CONS, 0);
};

BinOpContext.prototype.APPEND = function() {
    return this.getToken(BwaxParser.APPEND, 0);
};

BinOpContext.prototype.PIPE_TO = function() {
    return this.getToken(BwaxParser.PIPE_TO, 0);
};

BinOpContext.prototype.PIPE_FROM = function() {
    return this.getToken(BwaxParser.PIPE_FROM, 0);
};

BinOpContext.prototype.COMP_FROM = function() {
    return this.getToken(BwaxParser.COMP_FROM, 0);
};

BinOpContext.prototype.COMP_TO = function() {
    return this.getToken(BwaxParser.COMP_TO, 0);
};

BinOpContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterBinOp(this);
	}
};

BinOpContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitBinOp(this);
	}
};

BinOpContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitBinOp(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.BinOpContext = BinOpContext;

BwaxParser.prototype.binOp = function() {

    var localctx = new BinOpContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, BwaxParser.RULE_binOp);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 372;
        _la = this._input.LA(1);
        if(!(((((_la - 31)) & ~0x1f) == 0 && ((1 << (_la - 31)) & ((1 << (BwaxParser.AND - 31)) | (1 << (BwaxParser.OR - 31)) | (1 << (BwaxParser.XOR - 31)) | (1 << (BwaxParser.POW - 31)) | (1 << (BwaxParser.MUL - 31)) | (1 << (BwaxParser.DIV - 31)) | (1 << (BwaxParser.ADD - 31)) | (1 << (BwaxParser.SUB - 31)) | (1 << (BwaxParser.MOD - 31)) | (1 << (BwaxParser.GT - 31)) | (1 << (BwaxParser.GE - 31)) | (1 << (BwaxParser.EQ - 31)) | (1 << (BwaxParser.LT - 31)) | (1 << (BwaxParser.LE - 31)) | (1 << (BwaxParser.NE - 31)) | (1 << (BwaxParser.CONS - 31)) | (1 << (BwaxParser.APPEND - 31)) | (1 << (BwaxParser.PIPE_FROM - 31)) | (1 << (BwaxParser.PIPE_TO - 31)) | (1 << (BwaxParser.COMP_FROM - 31)) | (1 << (BwaxParser.COMP_TO - 31)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function CaseBranchContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_caseBranch;
    return this;
}

CaseBranchContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
CaseBranchContext.prototype.constructor = CaseBranchContext;

CaseBranchContext.prototype.pattern = function() {
    return this.getTypedRuleContext(PatternContext,0);
};

CaseBranchContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

CaseBranchContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCaseBranch(this);
	}
};

CaseBranchContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCaseBranch(this);
	}
};

CaseBranchContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCaseBranch(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.CaseBranchContext = CaseBranchContext;

BwaxParser.prototype.caseBranch = function() {

    var localctx = new CaseBranchContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, BwaxParser.RULE_caseBranch);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 374;
        this.match(BwaxParser.T__7);
        this.state = 375;
        this.pattern(0);
        this.state = 376;
        this.match(BwaxParser.T__9);
        this.state = 377;
        this.expr(0);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function FieldTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_fieldTypeDecl;
    return this;
}

FieldTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FieldTypeDeclContext.prototype.constructor = FieldTypeDeclContext;

FieldTypeDeclContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

FieldTypeDeclContext.prototype.typeDecl = function() {
    return this.getTypedRuleContext(TypeDeclContext,0);
};

FieldTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterFieldTypeDecl(this);
	}
};

FieldTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitFieldTypeDecl(this);
	}
};

FieldTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitFieldTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.FieldTypeDeclContext = FieldTypeDeclContext;

BwaxParser.prototype.fieldTypeDecl = function() {

    var localctx = new FieldTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, BwaxParser.RULE_fieldTypeDecl);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 379;
        this.match(BwaxParser.NAME);
        this.state = 380;
        this.match(BwaxParser.T__11);
        this.state = 381;
        this.typeDecl();
        this.state = 383;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===BwaxParser.T__12) {
            this.state = 382;
            this.match(BwaxParser.T__12);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function TypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_typeDecl;
    return this;
}

TypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TypeDeclContext.prototype.constructor = TypeDeclContext;

TypeDeclContext.prototype.typeDeclAlt = function() {
    return this.getTypedRuleContext(TypeDeclAltContext,0);
};

TypeDeclContext.prototype.typeDecl = function() {
    return this.getTypedRuleContext(TypeDeclContext,0);
};

TypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeDecl(this);
	}
};

TypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeDecl(this);
	}
};

TypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.TypeDeclContext = TypeDeclContext;

BwaxParser.prototype.typeDecl = function() {

    var localctx = new TypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 18, BwaxParser.RULE_typeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 385;
        this.typeDeclAlt();
        this.state = 388;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,36,this._ctx);
        if(la_===1) {
            this.state = 386;
            this.match(BwaxParser.T__9);
            this.state = 387;
            this.typeDecl();

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function TypeDeclAltContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_typeDeclAlt;
    return this;
}

TypeDeclAltContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TypeDeclAltContext.prototype.constructor = TypeDeclAltContext;

TypeDeclAltContext.prototype.varTypeDecl = function() {
    return this.getTypedRuleContext(VarTypeDeclContext,0);
};

TypeDeclAltContext.prototype.roVarTypeDecl = function() {
    return this.getTypedRuleContext(RoVarTypeDeclContext,0);
};

TypeDeclAltContext.prototype.unitTypeDecl = function() {
    return this.getTypedRuleContext(UnitTypeDeclContext,0);
};

TypeDeclAltContext.prototype.tupleTypeDecl = function() {
    return this.getTypedRuleContext(TupleTypeDeclContext,0);
};

TypeDeclAltContext.prototype.recordTypeDecl = function() {
    return this.getTypedRuleContext(RecordTypeDeclContext,0);
};

TypeDeclAltContext.prototype.recordExtTypeDecl = function() {
    return this.getTypedRuleContext(RecordExtTypeDeclContext,0);
};

TypeDeclAltContext.prototype.roRecordTypeDecl = function() {
    return this.getTypedRuleContext(RoRecordTypeDeclContext,0);
};

TypeDeclAltContext.prototype.woOptionalRecordTypeDecl = function() {
    return this.getTypedRuleContext(WoOptionalRecordTypeDeclContext,0);
};

TypeDeclAltContext.prototype.woRecordTypeDecl = function() {
    return this.getTypedRuleContext(WoRecordTypeDeclContext,0);
};

TypeDeclAltContext.prototype.typeDeclBracket = function() {
    return this.getTypedRuleContext(TypeDeclBracketContext,0);
};

TypeDeclAltContext.prototype.constrTypeDecl = function() {
    return this.getTypedRuleContext(ConstrTypeDeclContext,0);
};

TypeDeclAltContext.prototype.constr0TypeDecl = function() {
    return this.getTypedRuleContext(Constr0TypeDeclContext,0);
};

TypeDeclAltContext.prototype.opaquedTypeDecl = function() {
    return this.getTypedRuleContext(OpaquedTypeDeclContext,0);
};

TypeDeclAltContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeDeclAlt(this);
	}
};

TypeDeclAltContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeDeclAlt(this);
	}
};

TypeDeclAltContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeDeclAlt(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.TypeDeclAltContext = TypeDeclAltContext;

BwaxParser.prototype.typeDeclAlt = function() {

    var localctx = new TypeDeclAltContext(this, this._ctx, this.state);
    this.enterRule(localctx, 20, BwaxParser.RULE_typeDeclAlt);
    try {
        this.state = 403;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,37,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 390;
            this.varTypeDecl();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 391;
            this.roVarTypeDecl();
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 392;
            this.unitTypeDecl();
            break;

        case 4:
            this.enterOuterAlt(localctx, 4);
            this.state = 393;
            this.tupleTypeDecl();
            break;

        case 5:
            this.enterOuterAlt(localctx, 5);
            this.state = 394;
            this.recordTypeDecl();
            break;

        case 6:
            this.enterOuterAlt(localctx, 6);
            this.state = 395;
            this.recordExtTypeDecl();
            break;

        case 7:
            this.enterOuterAlt(localctx, 7);
            this.state = 396;
            this.roRecordTypeDecl();
            break;

        case 8:
            this.enterOuterAlt(localctx, 8);
            this.state = 397;
            this.woOptionalRecordTypeDecl();
            break;

        case 9:
            this.enterOuterAlt(localctx, 9);
            this.state = 398;
            this.woRecordTypeDecl();
            break;

        case 10:
            this.enterOuterAlt(localctx, 10);
            this.state = 399;
            this.typeDeclBracket();
            break;

        case 11:
            this.enterOuterAlt(localctx, 11);
            this.state = 400;
            this.constrTypeDecl();
            break;

        case 12:
            this.enterOuterAlt(localctx, 12);
            this.state = 401;
            this.constr0TypeDecl();
            break;

        case 13:
            this.enterOuterAlt(localctx, 13);
            this.state = 402;
            this.opaquedTypeDecl();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function VarTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_varTypeDecl;
    return this;
}

VarTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
VarTypeDeclContext.prototype.constructor = VarTypeDeclContext;

VarTypeDeclContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

VarTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterVarTypeDecl(this);
	}
};

VarTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitVarTypeDecl(this);
	}
};

VarTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitVarTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.VarTypeDeclContext = VarTypeDeclContext;

BwaxParser.prototype.varTypeDecl = function() {

    var localctx = new VarTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 22, BwaxParser.RULE_varTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 405;
        this.match(BwaxParser.NAME);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RoVarTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_roVarTypeDecl;
    return this;
}

RoVarTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RoVarTypeDeclContext.prototype.constructor = RoVarTypeDeclContext;

RoVarTypeDeclContext.prototype.SHARP = function() {
    return this.getToken(BwaxParser.SHARP, 0);
};

RoVarTypeDeclContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

RoVarTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRoVarTypeDecl(this);
	}
};

RoVarTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRoVarTypeDecl(this);
	}
};

RoVarTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRoVarTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.RoVarTypeDeclContext = RoVarTypeDeclContext;

BwaxParser.prototype.roVarTypeDecl = function() {

    var localctx = new RoVarTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 24, BwaxParser.RULE_roVarTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 407;
        this.match(BwaxParser.SHARP);
        this.state = 408;
        this.match(BwaxParser.NAME);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function UnitTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_unitTypeDecl;
    return this;
}

UnitTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
UnitTypeDeclContext.prototype.constructor = UnitTypeDeclContext;


UnitTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterUnitTypeDecl(this);
	}
};

UnitTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitUnitTypeDecl(this);
	}
};

UnitTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitUnitTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.UnitTypeDeclContext = UnitTypeDeclContext;

BwaxParser.prototype.unitTypeDecl = function() {

    var localctx = new UnitTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 26, BwaxParser.RULE_unitTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 410;
        this.match(BwaxParser.T__0);
        this.state = 411;
        this.match(BwaxParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function TupleTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_tupleTypeDecl;
    return this;
}

TupleTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TupleTypeDeclContext.prototype.constructor = TupleTypeDeclContext;

TupleTypeDeclContext.prototype.typeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TypeDeclContext);
    } else {
        return this.getTypedRuleContext(TypeDeclContext,i);
    }
};

TupleTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTupleTypeDecl(this);
	}
};

TupleTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTupleTypeDecl(this);
	}
};

TupleTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTupleTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.TupleTypeDeclContext = TupleTypeDeclContext;

BwaxParser.prototype.tupleTypeDecl = function() {

    var localctx = new TupleTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 28, BwaxParser.RULE_tupleTypeDecl);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 413;
        this.match(BwaxParser.T__0);
        this.state = 414;
        this.typeDecl();
        this.state = 417; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 415;
            this.match(BwaxParser.T__1);
            this.state = 416;
            this.typeDecl();
            this.state = 419; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===BwaxParser.T__1);
        this.state = 421;
        this.match(BwaxParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RecordExtTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_recordExtTypeDecl;
    return this;
}

RecordExtTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RecordExtTypeDeclContext.prototype.constructor = RecordExtTypeDeclContext;

RecordExtTypeDeclContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

RecordExtTypeDeclContext.prototype.fieldTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(FieldTypeDeclContext);
    } else {
        return this.getTypedRuleContext(FieldTypeDeclContext,i);
    }
};

RecordExtTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecordExtTypeDecl(this);
	}
};

RecordExtTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecordExtTypeDecl(this);
	}
};

RecordExtTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecordExtTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.RecordExtTypeDeclContext = RecordExtTypeDeclContext;

BwaxParser.prototype.recordExtTypeDecl = function() {

    var localctx = new RecordExtTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 30, BwaxParser.RULE_recordExtTypeDecl);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 423;
        this.match(BwaxParser.T__5);
        this.state = 424;
        this.match(BwaxParser.NAME);
        this.state = 425;
        this.match(BwaxParser.T__7);
        this.state = 426;
        this.fieldTypeDecl();
        this.state = 431;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===BwaxParser.T__1) {
            this.state = 427;
            this.match(BwaxParser.T__1);
            this.state = 428;
            this.fieldTypeDecl();
            this.state = 433;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 434;
        this.match(BwaxParser.T__6);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RecordTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_recordTypeDecl;
    return this;
}

RecordTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RecordTypeDeclContext.prototype.constructor = RecordTypeDeclContext;

RecordTypeDeclContext.prototype.fieldTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(FieldTypeDeclContext);
    } else {
        return this.getTypedRuleContext(FieldTypeDeclContext,i);
    }
};

RecordTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecordTypeDecl(this);
	}
};

RecordTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecordTypeDecl(this);
	}
};

RecordTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecordTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.RecordTypeDeclContext = RecordTypeDeclContext;

BwaxParser.prototype.recordTypeDecl = function() {

    var localctx = new RecordTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 32, BwaxParser.RULE_recordTypeDecl);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 436;
        this.match(BwaxParser.T__5);
        this.state = 445;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===BwaxParser.NAME) {
            this.state = 437;
            this.fieldTypeDecl();
            this.state = 442;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.T__1) {
                this.state = 438;
                this.match(BwaxParser.T__1);
                this.state = 439;
                this.fieldTypeDecl();
                this.state = 444;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
        }

        this.state = 447;
        this.match(BwaxParser.T__6);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RoRecordTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_roRecordTypeDecl;
    return this;
}

RoRecordTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RoRecordTypeDeclContext.prototype.constructor = RoRecordTypeDeclContext;

RoRecordTypeDeclContext.prototype.SHARP = function() {
    return this.getToken(BwaxParser.SHARP, 0);
};

RoRecordTypeDeclContext.prototype.constr0TypeDecl = function() {
    return this.getTypedRuleContext(Constr0TypeDeclContext,0);
};

RoRecordTypeDeclContext.prototype.constrTypeDecl = function() {
    return this.getTypedRuleContext(ConstrTypeDeclContext,0);
};

RoRecordTypeDeclContext.prototype.recordTypeDecl = function() {
    return this.getTypedRuleContext(RecordTypeDeclContext,0);
};

RoRecordTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRoRecordTypeDecl(this);
	}
};

RoRecordTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRoRecordTypeDecl(this);
	}
};

RoRecordTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRoRecordTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.RoRecordTypeDeclContext = RoRecordTypeDeclContext;

BwaxParser.prototype.roRecordTypeDecl = function() {

    var localctx = new RoRecordTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 34, BwaxParser.RULE_roRecordTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 449;
        this.match(BwaxParser.SHARP);
        this.state = 453;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,42,this._ctx);
        switch(la_) {
        case 1:
            this.state = 450;
            this.constr0TypeDecl();
            break;

        case 2:
            this.state = 451;
            this.constrTypeDecl();
            break;

        case 3:
            this.state = 452;
            this.recordTypeDecl();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function WoOptionalRecordTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_woOptionalRecordTypeDecl;
    return this;
}

WoOptionalRecordTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
WoOptionalRecordTypeDeclContext.prototype.constructor = WoOptionalRecordTypeDeclContext;

WoOptionalRecordTypeDeclContext.prototype.WAVE = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.WAVE);
    } else {
        return this.getToken(BwaxParser.WAVE, i);
    }
};


WoOptionalRecordTypeDeclContext.prototype.constr0TypeDecl = function() {
    return this.getTypedRuleContext(Constr0TypeDeclContext,0);
};

WoOptionalRecordTypeDeclContext.prototype.constrTypeDecl = function() {
    return this.getTypedRuleContext(ConstrTypeDeclContext,0);
};

WoOptionalRecordTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterWoOptionalRecordTypeDecl(this);
	}
};

WoOptionalRecordTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitWoOptionalRecordTypeDecl(this);
	}
};

WoOptionalRecordTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitWoOptionalRecordTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.WoOptionalRecordTypeDeclContext = WoOptionalRecordTypeDeclContext;

BwaxParser.prototype.woOptionalRecordTypeDecl = function() {

    var localctx = new WoOptionalRecordTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 36, BwaxParser.RULE_woOptionalRecordTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 455;
        this.match(BwaxParser.WAVE);
        this.state = 456;
        this.match(BwaxParser.WAVE);
        this.state = 459;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,43,this._ctx);
        switch(la_) {
        case 1:
            this.state = 457;
            this.constr0TypeDecl();
            break;

        case 2:
            this.state = 458;
            this.constrTypeDecl();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function WoRecordTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_woRecordTypeDecl;
    return this;
}

WoRecordTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
WoRecordTypeDeclContext.prototype.constructor = WoRecordTypeDeclContext;

WoRecordTypeDeclContext.prototype.WAVE = function() {
    return this.getToken(BwaxParser.WAVE, 0);
};

WoRecordTypeDeclContext.prototype.constr0TypeDecl = function() {
    return this.getTypedRuleContext(Constr0TypeDeclContext,0);
};

WoRecordTypeDeclContext.prototype.constrTypeDecl = function() {
    return this.getTypedRuleContext(ConstrTypeDeclContext,0);
};

WoRecordTypeDeclContext.prototype.recordTypeDecl = function() {
    return this.getTypedRuleContext(RecordTypeDeclContext,0);
};

WoRecordTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterWoRecordTypeDecl(this);
	}
};

WoRecordTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitWoRecordTypeDecl(this);
	}
};

WoRecordTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitWoRecordTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.WoRecordTypeDeclContext = WoRecordTypeDeclContext;

BwaxParser.prototype.woRecordTypeDecl = function() {

    var localctx = new WoRecordTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 38, BwaxParser.RULE_woRecordTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 461;
        this.match(BwaxParser.WAVE);
        this.state = 465;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,44,this._ctx);
        switch(la_) {
        case 1:
            this.state = 462;
            this.constr0TypeDecl();
            break;

        case 2:
            this.state = 463;
            this.constrTypeDecl();
            break;

        case 3:
            this.state = 464;
            this.recordTypeDecl();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function OpaquedTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_opaquedTypeDecl;
    return this;
}

OpaquedTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
OpaquedTypeDeclContext.prototype.constructor = OpaquedTypeDeclContext;

OpaquedTypeDeclContext.prototype.WAVE = function() {
    return this.getToken(BwaxParser.WAVE, 0);
};

OpaquedTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterOpaquedTypeDecl(this);
	}
};

OpaquedTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitOpaquedTypeDecl(this);
	}
};

OpaquedTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitOpaquedTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.OpaquedTypeDeclContext = OpaquedTypeDeclContext;

BwaxParser.prototype.opaquedTypeDecl = function() {

    var localctx = new OpaquedTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 40, BwaxParser.RULE_opaquedTypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 467;
        this.match(BwaxParser.WAVE);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function TypeDeclBracketContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_typeDeclBracket;
    return this;
}

TypeDeclBracketContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TypeDeclBracketContext.prototype.constructor = TypeDeclBracketContext;

TypeDeclBracketContext.prototype.typeDecl = function() {
    return this.getTypedRuleContext(TypeDeclContext,0);
};

TypeDeclBracketContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeDeclBracket(this);
	}
};

TypeDeclBracketContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeDeclBracket(this);
	}
};

TypeDeclBracketContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeDeclBracket(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.TypeDeclBracketContext = TypeDeclBracketContext;

BwaxParser.prototype.typeDeclBracket = function() {

    var localctx = new TypeDeclBracketContext(this, this._ctx, this.state);
    this.enterRule(localctx, 42, BwaxParser.RULE_typeDeclBracket);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 469;
        this.match(BwaxParser.T__0);
        this.state = 470;
        this.typeDecl();
        this.state = 471;
        this.match(BwaxParser.T__2);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function Constr0TypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_constr0TypeDecl;
    return this;
}

Constr0TypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Constr0TypeDeclContext.prototype.constructor = Constr0TypeDeclContext;

Constr0TypeDeclContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

Constr0TypeDeclContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};


Constr0TypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConstr0TypeDecl(this);
	}
};

Constr0TypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConstr0TypeDecl(this);
	}
};

Constr0TypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConstr0TypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.Constr0TypeDeclContext = Constr0TypeDeclContext;

BwaxParser.prototype.constr0TypeDecl = function() {

    var localctx = new Constr0TypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 44, BwaxParser.RULE_constr0TypeDecl);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 473;
        this.match(BwaxParser.CAP_NAME);
        this.state = 477;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,45,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 474;
                this.match(BwaxParser.DOT_CAP_NAME); 
            }
            this.state = 479;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,45,this._ctx);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ConstrTypeDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_constrTypeDecl;
    return this;
}

ConstrTypeDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ConstrTypeDeclContext.prototype.constructor = ConstrTypeDeclContext;

ConstrTypeDeclContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ConstrTypeDeclContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};


ConstrTypeDeclContext.prototype.varTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(VarTypeDeclContext);
    } else {
        return this.getTypedRuleContext(VarTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.tupleTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TupleTypeDeclContext);
    } else {
        return this.getTypedRuleContext(TupleTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.recordExtTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(RecordExtTypeDeclContext);
    } else {
        return this.getTypedRuleContext(RecordExtTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.recordTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(RecordTypeDeclContext);
    } else {
        return this.getTypedRuleContext(RecordTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.constr0TypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Constr0TypeDeclContext);
    } else {
        return this.getTypedRuleContext(Constr0TypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.typeDeclBracket = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TypeDeclBracketContext);
    } else {
        return this.getTypedRuleContext(TypeDeclBracketContext,i);
    }
};

ConstrTypeDeclContext.prototype.roRecordTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(RoRecordTypeDeclContext);
    } else {
        return this.getTypedRuleContext(RoRecordTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.woRecordTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(WoRecordTypeDeclContext);
    } else {
        return this.getTypedRuleContext(WoRecordTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.opaquedTypeDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(OpaquedTypeDeclContext);
    } else {
        return this.getTypedRuleContext(OpaquedTypeDeclContext,i);
    }
};

ConstrTypeDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConstrTypeDecl(this);
	}
};

ConstrTypeDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConstrTypeDecl(this);
	}
};

ConstrTypeDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConstrTypeDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.ConstrTypeDeclContext = ConstrTypeDeclContext;

BwaxParser.prototype.constrTypeDecl = function() {

    var localctx = new ConstrTypeDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 46, BwaxParser.RULE_constrTypeDecl);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 480;
        this.match(BwaxParser.CAP_NAME);
        this.state = 484;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===BwaxParser.DOT_CAP_NAME) {
            this.state = 481;
            this.match(BwaxParser.DOT_CAP_NAME);
            this.state = 486;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 496; 
        this._errHandler.sync(this);
        var _alt = 1;
        do {
        	switch (_alt) {
        	case 1:
        		this.state = 496;
        		this._errHandler.sync(this);
        		var la_ = this._interp.adaptivePredict(this._input,47,this._ctx);
        		switch(la_) {
        		case 1:
        		    this.state = 487;
        		    this.varTypeDecl();
        		    break;

        		case 2:
        		    this.state = 488;
        		    this.tupleTypeDecl();
        		    break;

        		case 3:
        		    this.state = 489;
        		    this.recordExtTypeDecl();
        		    break;

        		case 4:
        		    this.state = 490;
        		    this.recordTypeDecl();
        		    break;

        		case 5:
        		    this.state = 491;
        		    this.constr0TypeDecl();
        		    break;

        		case 6:
        		    this.state = 492;
        		    this.typeDeclBracket();
        		    break;

        		case 7:
        		    this.state = 493;
        		    this.roRecordTypeDecl();
        		    break;

        		case 8:
        		    this.state = 494;
        		    this.woRecordTypeDecl();
        		    break;

        		case 9:
        		    this.state = 495;
        		    this.opaquedTypeDecl();
        		    break;

        		}
        		break;
        	default:
        		throw new antlr4.error.NoViableAltException(this);
        	}
        	this.state = 498; 
        	this._errHandler.sync(this);
        	_alt = this._interp.adaptivePredict(this._input,48, this._ctx);
        } while ( _alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER );
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ConstrDeclContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_constrDecl;
    return this;
}

ConstrDeclContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ConstrDeclContext.prototype.constructor = ConstrDeclContext;

ConstrDeclContext.prototype.constr0TypeDecl = function() {
    return this.getTypedRuleContext(Constr0TypeDeclContext,0);
};

ConstrDeclContext.prototype.constrTypeDecl = function() {
    return this.getTypedRuleContext(ConstrTypeDeclContext,0);
};

ConstrDeclContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConstrDecl(this);
	}
};

ConstrDeclContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConstrDecl(this);
	}
};

ConstrDeclContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConstrDecl(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.ConstrDeclContext = ConstrDeclContext;

BwaxParser.prototype.constrDecl = function() {

    var localctx = new ConstrDeclContext(this, this._ctx, this.state);
    this.enterRule(localctx, 48, BwaxParser.RULE_constrDecl);
    try {
        this.state = 502;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,49,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 500;
            this.constr0TypeDecl();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 501;
            this.constrTypeDecl();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function TypeAnnotContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_typeAnnot;
    return this;
}

TypeAnnotContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TypeAnnotContext.prototype.constructor = TypeAnnotContext;

TypeAnnotContext.prototype.typeDecl = function() {
    return this.getTypedRuleContext(TypeDeclContext,0);
};

TypeAnnotContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeAnnot(this);
	}
};

TypeAnnotContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeAnnot(this);
	}
};

TypeAnnotContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeAnnot(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.TypeAnnotContext = TypeAnnotContext;

BwaxParser.prototype.typeAnnot = function() {

    var localctx = new TypeAnnotContext(this, this._ctx, this.state);
    this.enterRule(localctx, 50, BwaxParser.RULE_typeAnnot);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 504;
        this.match(BwaxParser.T__11);
        this.state = 505;
        this.typeDecl();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function DefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_def;
    return this;
}

DefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DefContext.prototype.constructor = DefContext;


 
DefContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function TypeAliasDefContext(parser, ctx) {
	DefContext.call(this, parser);
    DefContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TypeAliasDefContext.prototype = Object.create(DefContext.prototype);
TypeAliasDefContext.prototype.constructor = TypeAliasDefContext;

BwaxParser.TypeAliasDefContext = TypeAliasDefContext;

TypeAliasDefContext.prototype.TYPE = function() {
    return this.getToken(BwaxParser.TYPE, 0);
};

TypeAliasDefContext.prototype.ALIAS = function() {
    return this.getToken(BwaxParser.ALIAS, 0);
};

TypeAliasDefContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

TypeAliasDefContext.prototype.typeDecl = function() {
    return this.getTypedRuleContext(TypeDeclContext,0);
};

TypeAliasDefContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.NAME);
    } else {
        return this.getToken(BwaxParser.NAME, i);
    }
};

TypeAliasDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeAliasDef(this);
	}
};

TypeAliasDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeAliasDef(this);
	}
};

TypeAliasDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeAliasDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ValueDefContext(parser, ctx) {
	DefContext.call(this, parser);
    DefContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ValueDefContext.prototype = Object.create(DefContext.prototype);
ValueDefContext.prototype.constructor = ValueDefContext;

BwaxParser.ValueDefContext = ValueDefContext;

ValueDefContext.prototype.pattern = function() {
    return this.getTypedRuleContext(PatternContext,0);
};

ValueDefContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

ValueDefContext.prototype.typeAnnot = function() {
    return this.getTypedRuleContext(TypeAnnotContext,0);
};
ValueDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterValueDef(this);
	}
};

ValueDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitValueDef(this);
	}
};

ValueDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitValueDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function FunctionDefContext(parser, ctx) {
	DefContext.call(this, parser);
    DefContext.prototype.copyFrom.call(this, ctx);
    return this;
}

FunctionDefContext.prototype = Object.create(DefContext.prototype);
FunctionDefContext.prototype.constructor = FunctionDefContext;

BwaxParser.FunctionDefContext = FunctionDefContext;

FunctionDefContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

FunctionDefContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

FunctionDefContext.prototype.pattern = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PatternContext);
    } else {
        return this.getTypedRuleContext(PatternContext,i);
    }
};

FunctionDefContext.prototype.typeAnnot = function() {
    return this.getTypedRuleContext(TypeAnnotContext,0);
};
FunctionDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterFunctionDef(this);
	}
};

FunctionDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitFunctionDef(this);
	}
};

FunctionDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitFunctionDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function TypeDefContext(parser, ctx) {
	DefContext.call(this, parser);
    DefContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TypeDefContext.prototype = Object.create(DefContext.prototype);
TypeDefContext.prototype.constructor = TypeDefContext;

BwaxParser.TypeDefContext = TypeDefContext;

TypeDefContext.prototype.TYPE = function() {
    return this.getToken(BwaxParser.TYPE, 0);
};

TypeDefContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

TypeDefContext.prototype.constrDecl = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ConstrDeclContext);
    } else {
        return this.getTypedRuleContext(ConstrDeclContext,i);
    }
};

TypeDefContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.NAME);
    } else {
        return this.getToken(BwaxParser.NAME, i);
    }
};

TypeDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTypeDef(this);
	}
};

TypeDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTypeDef(this);
	}
};

TypeDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTypeDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function OpaqueTypeDefContext(parser, ctx) {
	DefContext.call(this, parser);
    DefContext.prototype.copyFrom.call(this, ctx);
    return this;
}

OpaqueTypeDefContext.prototype = Object.create(DefContext.prototype);
OpaqueTypeDefContext.prototype.constructor = OpaqueTypeDefContext;

BwaxParser.OpaqueTypeDefContext = OpaqueTypeDefContext;

OpaqueTypeDefContext.prototype.TYPE = function() {
    return this.getToken(BwaxParser.TYPE, 0);
};

OpaqueTypeDefContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

OpaqueTypeDefContext.prototype.OPAQUE = function() {
    return this.getToken(BwaxParser.OPAQUE, 0);
};

OpaqueTypeDefContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.NAME);
    } else {
        return this.getToken(BwaxParser.NAME, i);
    }
};

OpaqueTypeDefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterOpaqueTypeDef(this);
	}
};

OpaqueTypeDefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitOpaqueTypeDef(this);
	}
};

OpaqueTypeDefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitOpaqueTypeDef(this);
    } else {
        return visitor.visitChildren(this);
    }
};



BwaxParser.DefContext = DefContext;

BwaxParser.prototype.def = function() {

    var localctx = new DefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 52, BwaxParser.RULE_def);
    var _la = 0; // Token type
    try {
        this.state = 567;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,58,this._ctx);
        switch(la_) {
        case 1:
            localctx = new ValueDefContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 507;
            this.pattern(0);
            this.state = 509;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===BwaxParser.T__11) {
                this.state = 508;
                this.typeAnnot();
            }

            this.state = 511;
            this.match(BwaxParser.T__13);
            this.state = 512;
            this.expr(0);
            break;

        case 2:
            localctx = new FunctionDefContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 514;
            this.match(BwaxParser.NAME);
            this.state = 516; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 515;
                this.pattern(0);
                this.state = 518; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << BwaxParser.T__0) | (1 << BwaxParser.T__3) | (1 << BwaxParser.T__5) | (1 << BwaxParser.T__14))) !== 0) || ((((_la - 35)) & ~0x1f) == 0 && ((1 << (_la - 35)) & ((1 << (BwaxParser.TRUE - 35)) | (1 << (BwaxParser.FALSE - 35)) | (1 << (BwaxParser.INTEGER - 35)))) !== 0) || ((((_la - 68)) & ~0x1f) == 0 && ((1 << (_la - 68)) & ((1 << (BwaxParser.FLOAT_NUMBER - 68)) | (1 << (BwaxParser.NAME - 68)) | (1 << (BwaxParser.CAP_NAME - 68)) | (1 << (BwaxParser.STRING - 68)) | (1 << (BwaxParser.CHAR - 68)) | (1 << (BwaxParser.LONG_STRING - 68)))) !== 0));
            this.state = 521;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===BwaxParser.T__11) {
                this.state = 520;
                this.typeAnnot();
            }

            this.state = 523;
            this.match(BwaxParser.T__13);
            this.state = 524;
            this.expr(0);
            break;

        case 3:
            localctx = new TypeAliasDefContext(this, localctx);
            this.enterOuterAlt(localctx, 3);
            this.state = 526;
            this.match(BwaxParser.TYPE);
            this.state = 527;
            this.match(BwaxParser.ALIAS);
            this.state = 528;
            this.match(BwaxParser.CAP_NAME);
            this.state = 532;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.NAME) {
                this.state = 529;
                this.match(BwaxParser.NAME);
                this.state = 534;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 535;
            this.match(BwaxParser.T__13);
            this.state = 536;
            this.typeDecl();
            break;

        case 4:
            localctx = new TypeDefContext(this, localctx);
            this.enterOuterAlt(localctx, 4);
            this.state = 537;
            this.match(BwaxParser.TYPE);
            this.state = 538;
            this.match(BwaxParser.CAP_NAME);
            this.state = 542;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.NAME) {
                this.state = 539;
                this.match(BwaxParser.NAME);
                this.state = 544;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 545;
            this.match(BwaxParser.T__13);
            this.state = 547;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===BwaxParser.T__7) {
                this.state = 546;
                this.match(BwaxParser.T__7);
            }

            this.state = 549;
            this.constrDecl();
            this.state = 554;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.T__7) {
                this.state = 550;
                this.match(BwaxParser.T__7);
                this.state = 551;
                this.constrDecl();
                this.state = 556;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            break;

        case 5:
            localctx = new OpaqueTypeDefContext(this, localctx);
            this.enterOuterAlt(localctx, 5);
            this.state = 557;
            this.match(BwaxParser.TYPE);
            this.state = 558;
            this.match(BwaxParser.CAP_NAME);
            this.state = 562;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===BwaxParser.NAME) {
                this.state = 559;
                this.match(BwaxParser.NAME);
                this.state = 564;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 565;
            this.match(BwaxParser.T__13);
            this.state = 566;
            this.match(BwaxParser.OPAQUE);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function PatternContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_pattern;
    return this;
}

PatternContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PatternContext.prototype.constructor = PatternContext;


 
PatternContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function RecordPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

RecordPtnContext.prototype = Object.create(PatternContext.prototype);
RecordPtnContext.prototype.constructor = RecordPtnContext;

BwaxParser.RecordPtnContext = RecordPtnContext;

RecordPtnContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.NAME);
    } else {
        return this.getToken(BwaxParser.NAME, i);
    }
};

RecordPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecordPtn(this);
	}
};

RecordPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecordPtn(this);
	}
};

RecordPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecordPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function BracketForPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

BracketForPtnContext.prototype = Object.create(PatternContext.prototype);
BracketForPtnContext.prototype.constructor = BracketForPtnContext;

BwaxParser.BracketForPtnContext = BracketForPtnContext;

BracketForPtnContext.prototype.pattern = function() {
    return this.getTypedRuleContext(PatternContext,0);
};
BracketForPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterBracketForPtn(this);
	}
};

BracketForPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitBracketForPtn(this);
	}
};

BracketForPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitBracketForPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function TuplePtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TuplePtnContext.prototype = Object.create(PatternContext.prototype);
TuplePtnContext.prototype.constructor = TuplePtnContext;

BwaxParser.TuplePtnContext = TuplePtnContext;

TuplePtnContext.prototype.pattern = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PatternContext);
    } else {
        return this.getTypedRuleContext(PatternContext,i);
    }
};
TuplePtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTuplePtn(this);
	}
};

TuplePtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTuplePtn(this);
	}
};

TuplePtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTuplePtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ConsListPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ConsListPtnContext.prototype = Object.create(PatternContext.prototype);
ConsListPtnContext.prototype.constructor = ConsListPtnContext;

BwaxParser.ConsListPtnContext = ConsListPtnContext;

ConsListPtnContext.prototype.pattern = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PatternContext);
    } else {
        return this.getTypedRuleContext(PatternContext,i);
    }
};

ConsListPtnContext.prototype.CONS = function() {
    return this.getToken(BwaxParser.CONS, 0);
};
ConsListPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConsListPtn(this);
	}
};

ConsListPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConsListPtn(this);
	}
};

ConsListPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConsListPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function VarPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

VarPtnContext.prototype = Object.create(PatternContext.prototype);
VarPtnContext.prototype.constructor = VarPtnContext;

BwaxParser.VarPtnContext = VarPtnContext;

VarPtnContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};
VarPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterVarPtn(this);
	}
};

VarPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitVarPtn(this);
	}
};

VarPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitVarPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function WildcardPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

WildcardPtnContext.prototype = Object.create(PatternContext.prototype);
WildcardPtnContext.prototype.constructor = WildcardPtnContext;

BwaxParser.WildcardPtnContext = WildcardPtnContext;

WildcardPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterWildcardPtn(this);
	}
};

WildcardPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitWildcardPtn(this);
	}
};

WildcardPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitWildcardPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LiteralPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LiteralPtnContext.prototype = Object.create(PatternContext.prototype);
LiteralPtnContext.prototype.constructor = LiteralPtnContext;

BwaxParser.LiteralPtnContext = LiteralPtnContext;

LiteralPtnContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};
LiteralPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLiteralPtn(this);
	}
};

LiteralPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLiteralPtn(this);
	}
};

LiteralPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLiteralPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function NilListPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

NilListPtnContext.prototype = Object.create(PatternContext.prototype);
NilListPtnContext.prototype.constructor = NilListPtnContext;

BwaxParser.NilListPtnContext = NilListPtnContext;

NilListPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterNilListPtn(this);
	}
};

NilListPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitNilListPtn(this);
	}
};

NilListPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitNilListPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ConstructorPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ConstructorPtnContext.prototype = Object.create(PatternContext.prototype);
ConstructorPtnContext.prototype.constructor = ConstructorPtnContext;

BwaxParser.ConstructorPtnContext = ConstructorPtnContext;

ConstructorPtnContext.prototype.CAP_NAME = function() {
    return this.getToken(BwaxParser.CAP_NAME, 0);
};

ConstructorPtnContext.prototype.DOT_CAP_NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(BwaxParser.DOT_CAP_NAME);
    } else {
        return this.getToken(BwaxParser.DOT_CAP_NAME, i);
    }
};


ConstructorPtnContext.prototype.pattern = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PatternContext);
    } else {
        return this.getTypedRuleContext(PatternContext,i);
    }
};
ConstructorPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterConstructorPtn(this);
	}
};

ConstructorPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitConstructorPtn(this);
	}
};

ConstructorPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitConstructorPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function UnitPtnContext(parser, ctx) {
	PatternContext.call(this, parser);
    PatternContext.prototype.copyFrom.call(this, ctx);
    return this;
}

UnitPtnContext.prototype = Object.create(PatternContext.prototype);
UnitPtnContext.prototype.constructor = UnitPtnContext;

BwaxParser.UnitPtnContext = UnitPtnContext;

UnitPtnContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterUnitPtn(this);
	}
};

UnitPtnContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitUnitPtn(this);
	}
};

UnitPtnContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitUnitPtn(this);
    } else {
        return visitor.visitChildren(this);
    }
};



BwaxParser.prototype.pattern = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new PatternContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 54;
    this.enterRecursionRule(localctx, 54, BwaxParser.RULE_pattern, _p);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 616;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,64,this._ctx);
        switch(la_) {
        case 1:
            localctx = new BracketForPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;

            this.state = 570;
            this.match(BwaxParser.T__0);
            this.state = 571;
            this.pattern(0);
            this.state = 572;
            this.match(BwaxParser.T__2);
            break;

        case 2:
            localctx = new VarPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 574;
            this.match(BwaxParser.NAME);
            break;

        case 3:
            localctx = new ConstructorPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 575;
            this.match(BwaxParser.CAP_NAME);
            this.state = 579;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,59,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 576;
                    this.match(BwaxParser.DOT_CAP_NAME); 
                }
                this.state = 581;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,59,this._ctx);
            }

            this.state = 585;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,60,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 582;
                    this.pattern(0); 
                }
                this.state = 587;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,60,this._ctx);
            }

            break;

        case 4:
            localctx = new WildcardPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 588;
            this.match(BwaxParser.T__14);
            break;

        case 5:
            localctx = new UnitPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 589;
            this.match(BwaxParser.T__0);
            this.state = 590;
            this.match(BwaxParser.T__2);
            break;

        case 6:
            localctx = new TuplePtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 591;
            this.match(BwaxParser.T__0);
            this.state = 592;
            this.pattern(0);
            this.state = 595; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 593;
                this.match(BwaxParser.T__1);
                this.state = 594;
                this.pattern(0);
                this.state = 597; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===BwaxParser.T__1);
            this.state = 599;
            this.match(BwaxParser.T__2);
            break;

        case 7:
            localctx = new NilListPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 601;
            this.match(BwaxParser.T__3);
            this.state = 602;
            this.match(BwaxParser.T__4);
            break;

        case 8:
            localctx = new RecordPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 603;
            this.match(BwaxParser.T__5);
            this.state = 612;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===BwaxParser.NAME) {
                this.state = 604;
                this.match(BwaxParser.NAME);
                this.state = 609;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                while(_la===BwaxParser.T__1) {
                    this.state = 605;
                    this.match(BwaxParser.T__1);
                    this.state = 606;
                    this.match(BwaxParser.NAME);
                    this.state = 611;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                }
            }

            this.state = 614;
            this.match(BwaxParser.T__6);
            break;

        case 9:
            localctx = new LiteralPtnContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 615;
            this.literal();
            break;

        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 623;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,65,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                localctx = new ConsListPtnContext(this, new PatternContext(this, _parentctx, _parentState));
                this.pushNewRecursionContext(localctx, _startState, BwaxParser.RULE_pattern);
                this.state = 618;
                if (!( this.precpred(this._ctx, 3))) {
                    throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
                }
                this.state = 619;
                this.match(BwaxParser.CONS);
                this.state = 620;
                this.pattern(3); 
            }
            this.state = 625;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,65,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function RefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_ref;
    return this;
}

RefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RefContext.prototype.constructor = RefContext;


 
RefContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function VarRefContext(parser, ctx) {
	RefContext.call(this, parser);
    RefContext.prototype.copyFrom.call(this, ctx);
    return this;
}

VarRefContext.prototype = Object.create(RefContext.prototype);
VarRefContext.prototype.constructor = VarRefContext;

BwaxParser.VarRefContext = VarRefContext;

VarRefContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};
VarRefContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterVarRef(this);
	}
};

VarRefContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitVarRef(this);
	}
};

VarRefContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitVarRef(this);
    } else {
        return visitor.visitChildren(this);
    }
};



BwaxParser.RefContext = RefContext;

BwaxParser.prototype.ref = function() {

    var localctx = new RefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 56, BwaxParser.RULE_ref);
    try {
        localctx = new VarRefContext(this, localctx);
        this.enterOuterAlt(localctx, 1);
        this.state = 626;
        this.match(BwaxParser.NAME);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function LiteralContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_literal;
    return this;
}

LiteralContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LiteralContext.prototype.constructor = LiteralContext;


 
LiteralContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function FalseLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

FalseLiteralContext.prototype = Object.create(LiteralContext.prototype);
FalseLiteralContext.prototype.constructor = FalseLiteralContext;

BwaxParser.FalseLiteralContext = FalseLiteralContext;

FalseLiteralContext.prototype.FALSE = function() {
    return this.getToken(BwaxParser.FALSE, 0);
};
FalseLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterFalseLiteral(this);
	}
};

FalseLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitFalseLiteral(this);
	}
};

FalseLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitFalseLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function StringLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

StringLiteralContext.prototype = Object.create(LiteralContext.prototype);
StringLiteralContext.prototype.constructor = StringLiteralContext;

BwaxParser.StringLiteralContext = StringLiteralContext;

StringLiteralContext.prototype.STRING = function() {
    return this.getToken(BwaxParser.STRING, 0);
};
StringLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterStringLiteral(this);
	}
};

StringLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitStringLiteral(this);
	}
};

StringLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitStringLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function LongStringLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LongStringLiteralContext.prototype = Object.create(LiteralContext.prototype);
LongStringLiteralContext.prototype.constructor = LongStringLiteralContext;

BwaxParser.LongStringLiteralContext = LongStringLiteralContext;

LongStringLiteralContext.prototype.LONG_STRING = function() {
    return this.getToken(BwaxParser.LONG_STRING, 0);
};
LongStringLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterLongStringLiteral(this);
	}
};

LongStringLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitLongStringLiteral(this);
	}
};

LongStringLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitLongStringLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function IntegerLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

IntegerLiteralContext.prototype = Object.create(LiteralContext.prototype);
IntegerLiteralContext.prototype.constructor = IntegerLiteralContext;

BwaxParser.IntegerLiteralContext = IntegerLiteralContext;

IntegerLiteralContext.prototype.INTEGER = function() {
    return this.getToken(BwaxParser.INTEGER, 0);
};
IntegerLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterIntegerLiteral(this);
	}
};

IntegerLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitIntegerLiteral(this);
	}
};

IntegerLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitIntegerLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function FloatLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

FloatLiteralContext.prototype = Object.create(LiteralContext.prototype);
FloatLiteralContext.prototype.constructor = FloatLiteralContext;

BwaxParser.FloatLiteralContext = FloatLiteralContext;

FloatLiteralContext.prototype.FLOAT_NUMBER = function() {
    return this.getToken(BwaxParser.FLOAT_NUMBER, 0);
};
FloatLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterFloatLiteral(this);
	}
};

FloatLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitFloatLiteral(this);
	}
};

FloatLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitFloatLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function CharLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

CharLiteralContext.prototype = Object.create(LiteralContext.prototype);
CharLiteralContext.prototype.constructor = CharLiteralContext;

BwaxParser.CharLiteralContext = CharLiteralContext;

CharLiteralContext.prototype.CHAR = function() {
    return this.getToken(BwaxParser.CHAR, 0);
};
CharLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterCharLiteral(this);
	}
};

CharLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitCharLiteral(this);
	}
};

CharLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitCharLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function TrueLiteralContext(parser, ctx) {
	LiteralContext.call(this, parser);
    LiteralContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TrueLiteralContext.prototype = Object.create(LiteralContext.prototype);
TrueLiteralContext.prototype.constructor = TrueLiteralContext;

BwaxParser.TrueLiteralContext = TrueLiteralContext;

TrueLiteralContext.prototype.TRUE = function() {
    return this.getToken(BwaxParser.TRUE, 0);
};
TrueLiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterTrueLiteral(this);
	}
};

TrueLiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitTrueLiteral(this);
	}
};

TrueLiteralContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitTrueLiteral(this);
    } else {
        return visitor.visitChildren(this);
    }
};



BwaxParser.LiteralContext = LiteralContext;

BwaxParser.prototype.literal = function() {

    var localctx = new LiteralContext(this, this._ctx, this.state);
    this.enterRule(localctx, 58, BwaxParser.RULE_literal);
    try {
        this.state = 635;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case BwaxParser.CHAR:
            localctx = new CharLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 628;
            this.match(BwaxParser.CHAR);
            break;
        case BwaxParser.STRING:
            localctx = new StringLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 629;
            this.match(BwaxParser.STRING);
            break;
        case BwaxParser.LONG_STRING:
            localctx = new LongStringLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 3);
            this.state = 630;
            this.match(BwaxParser.LONG_STRING);
            break;
        case BwaxParser.INTEGER:
            localctx = new IntegerLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 4);
            this.state = 631;
            this.match(BwaxParser.INTEGER);
            break;
        case BwaxParser.FLOAT_NUMBER:
            localctx = new FloatLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 5);
            this.state = 632;
            this.match(BwaxParser.FLOAT_NUMBER);
            break;
        case BwaxParser.TRUE:
            localctx = new TrueLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 6);
            this.state = 633;
            this.match(BwaxParser.TRUE);
            break;
        case BwaxParser.FALSE:
            localctx = new FalseLiteralContext(this, localctx);
            this.enterOuterAlt(localctx, 7);
            this.state = 634;
            this.match(BwaxParser.FALSE);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RecBindContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = BwaxParser.RULE_recBind;
    return this;
}

RecBindContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RecBindContext.prototype.constructor = RecBindContext;

RecBindContext.prototype.NAME = function() {
    return this.getToken(BwaxParser.NAME, 0);
};

RecBindContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

RecBindContext.prototype.enterRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.enterRecBind(this);
	}
};

RecBindContext.prototype.exitRule = function(listener) {
    if(listener instanceof BwaxListener ) {
        listener.exitRecBind(this);
	}
};

RecBindContext.prototype.accept = function(visitor) {
    if ( visitor instanceof BwaxVisitor ) {
        return visitor.visitRecBind(this);
    } else {
        return visitor.visitChildren(this);
    }
};




BwaxParser.RecBindContext = RecBindContext;

BwaxParser.prototype.recBind = function() {

    var localctx = new RecBindContext(this, this._ctx, this.state);
    this.enterRule(localctx, 60, BwaxParser.RULE_recBind);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 637;
        this.match(BwaxParser.NAME);
        this.state = 640;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===BwaxParser.T__13) {
            this.state = 638;
            this.match(BwaxParser.T__13);
            this.state = 639;
            this.expr(0);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


BwaxParser.prototype.sempred = function(localctx, ruleIndex, predIndex) {
	switch(ruleIndex) {
	case 5:
			return this.expr_sempred(localctx, predIndex);
	case 27:
			return this.pattern_sempred(localctx, predIndex);
    default:
        throw "No predicate with index:" + ruleIndex;
   }
};

BwaxParser.prototype.expr_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 0:
			return this.precpred(this._ctx, 22);
		case 1:
			return this.precpred(this._ctx, 21);
		case 2:
			return this.precpred(this._ctx, 20);
		case 3:
			return this.precpred(this._ctx, 19);
		case 4:
			return this.precpred(this._ctx, 18);
		case 5:
			return this.precpred(this._ctx, 17);
		case 6:
			return this.precpred(this._ctx, 15);
		case 7:
			return this.precpred(this._ctx, 14);
		case 8:
			return this.precpred(this._ctx, 13);
		case 9:
			return this.precpred(this._ctx, 12);
		case 10:
			return this.precpred(this._ctx, 11);
		case 11:
			return this.precpred(this._ctx, 10);
		case 12:
			return this.precpred(this._ctx, 9);
		case 13:
			return this.precpred(this._ctx, 24);
		case 14:
			return this.precpred(this._ctx, 1);
		default:
			throw "No predicate with index:" + predIndex;
	}
};

BwaxParser.prototype.pattern_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 15:
			return this.precpred(this._ctx, 3);
		default:
			throw "No predicate with index:" + predIndex;
	}
};


exports.BwaxParser = BwaxParser;
