// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Defs_remat$BwaxAdmin = require("./defs_remat.bs.js");
var Runtime_common$BwaxAdmin = require("../lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");

function pack_cmdm_func(arity, name, prefix, entity) {
  var cmd_name = "CmdM_" + (prefix + ("_" + name));
  return Runtime_common$BwaxAdmin.pack_func(arity, (function (params) {
                var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Runtime_common$BwaxAdmin.pack_string(entity[/* name */0]),
                    params
                  ]);
                var cmd_val = /* V_executable */Caml_chrome_debugger.variant("V_executable", 14, [
                    cmd_name,
                    cmd_val_001,
                    /* [] */0
                  ]);
                return /* tuple */[
                        cmd_val,
                        Runtime_common$BwaxAdmin.nt
                      ];
              }));
}

function build_query_aggregate(entity) {
  return Runtime_common$BwaxAdmin.pack_func(1, (function (params) {
                var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Runtime_common$BwaxAdmin.pack_string(entity[/* name */0]),
                    params
                  ]);
                var cmd_val = /* V_executable */Caml_chrome_debugger.variant("V_executable", 14, [
                    "CmdM_aggregate",
                    cmd_val_001,
                    /* [] */0
                  ]);
                return /* tuple */[
                        cmd_val,
                        /* No_term */0
                      ];
              }));
}

function build_custom_interface($$interface) {
  var interface_type = $$interface[/* interface_type */2];
  var name = $$interface[/* name */0];
  var fname = Plate$BwaxAdmin.Str.decap(name);
  var arity = (
    Plate$BwaxAdmin.List.length($$interface[/* input_types */3]) > 0 ? 1 : 0
  ) + (
    $$interface[/* is_targeting_record */5] ? 1 : 0
  ) | 0;
  return /* tuple */[
          fname,
          (function (entity) {
              return Runtime_common$BwaxAdmin.pack_func(arity, (function (args) {
                            var cmd_name = interface_type ? "CmdM_custom_operation" : "CmdM_custom_query";
                            var cmd_params_000 = Runtime_common$BwaxAdmin.pack_string(entity[/* name */0]);
                            var cmd_params_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Runtime_common$BwaxAdmin.pack_string(name),
                                args
                              ]);
                            var cmd_params = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                cmd_params_000,
                                cmd_params_001
                              ]);
                            return /* tuple */[
                                    /* V_executable */Caml_chrome_debugger.variant("V_executable", 14, [
                                        cmd_name,
                                        cmd_params,
                                        /* [] */0
                                      ]),
                                    Runtime_common$BwaxAdmin.nt
                                  ];
                          }));
            })
        ];
}

function get_cmd_module_externals(entity) {
  var name = Base_query_types$BwaxAdmin.normalize_name(entity[/* name */0]);
  var module_name = "Cmd_" + name;
  var custom_interfaces = Plate$BwaxAdmin.List.map(build_custom_interface, entity[/* interfaces */4]);
  var standard_query = "standard_query";
  var standard_mut = "standard_mut";
  var common_funcs = Plate$BwaxAdmin.List.map((function (param) {
          var arity = param[2];
          var prefix = param[1];
          var fname = param[0];
          return /* tuple */[
                  fname,
                  (function (param) {
                      return pack_cmdm_func(arity, fname, prefix, param);
                    })
                ];
        }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "findOne",
            standard_query,
            1
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "findByID",
                standard_query,
                1
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "list",
                    standard_query,
                    1
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "listAll",
                        standard_query,
                        1
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "count",
                            standard_query,
                            1
                          ],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "add",
                                standard_mut,
                                1
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "update",
                                    standard_mut,
                                    2
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "delete",
                                        standard_mut,
                                        1
                                      ],
                                      /* [] */0
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]));
  return /* tuple */[
          module_name,
          Plate$BwaxAdmin.List.assoc_map((function (f) {
                  return Curry._1(f, entity);
                }), Pervasives.$at(common_funcs, Pervasives.$at(Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "aggregate",
                                build_query_aggregate
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Defs_remat$BwaxAdmin.build_remat_cmdm,
                                  /* [] */0
                                ])
                            ])), custom_interfaces)))
        ];
}

function get_cmd_externals(entity_dict) {
  return Plate$BwaxAdmin.List.map(get_cmd_module_externals, Plate$BwaxAdmin.List.assoc_vals(entity_dict));
}

exports.pack_cmdm_func = pack_cmdm_func;
exports.build_query_aggregate = build_query_aggregate;
exports.build_custom_interface = build_custom_interface;
exports.get_cmd_module_externals = get_cmd_module_externals;
exports.get_cmd_externals = get_cmd_externals;
/* Plate-BwaxAdmin Not a pure module */
