
import React, { useEffect, useState, useContext, useRef } from 'react';

import './StudioApp.less';

import useDataLoader from "bwax-ui/legacy/store/useDataLoader";
import { runDefinitionQuery } from 'bwax/query/runClientQuery';

import Loading from 'Client/js/components/Loading';

import { FacadeContextProvider } from 'bwax/util/FacadeContext'
import { ApplicationContextProvider } from 'bwax/util/ApplicationContext'

import { QueryTargetContextProvider } from 'bwax-ui/legacy/store/QueryTargetContext'

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import useCurrentUser from 'bwax-ui/auth/useCurrentUser';

import { Helmet } from 'react-helmet-async';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext';

import { setupDefinitionQueryRunner } from 'bwax/query/runClientQuery';

import StudioEditorArea from './StudioEditorArea';

import StudioTopBar from './StudioTopBar';

import StudioExplorer from './StudioExplorer.js';

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import Modal from 'bwax-ui/ml/widget/impl/misc/Modal';

import { Button } from 'rsuite';

import Message from 'Client/js/ui/Message';

import StudioItem from './StudioItem';
import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

import { getGlobalEventBus } from './EventBus';

import ResizableSplitPanels from 'Client/js/components/ResizableSplitPanels';

export default function StudioApp(props) {

    const { history, match } = props;
    const { currentUser } = useCurrentUser({ queryTarget: "definition" });
    const baseDlc = useContext(DataLoaderContext);

    const dlc = {
        ...baseDlc,
        isDesignMode: true,
    }

    // effects:
    useEffect(() => {
        /// check user login at interval, TODO DDD
        checkUser();
    }, [currentUser])


    useEffect(() => {
        if (typeof (document) !== 'undefined') {
            document.body.className = "bw-admin studio-root";
        }
    }, [])

    function checkUser() {
        const isNotLoggedIn = currentUser === null
        const noAccess = currentUser && !currentUser.systemRoles.some(r => {
            return r === "Designer"
        })
        if (isNotLoggedIn) {
            history.replace(addQueryParam('rt', location.pathname, '/login'))
        }
        if (noAccess) {
            history.replace("/no-permission")
        }
    }

    // The single facade use for design
    const [facade, setFacade] = useState(undefined);

    const [applications, setApplications] = useState([]);

    const domainSettings = useDomainSettings();

    useEffect(() => {
        const BwaxFacade = require("bwax/facade").default;
        const queryRunner = setupDefinitionQueryRunner(dlc);
        (async () => {
            const facade = new BwaxFacade({
                queryRunner, dlc,
                isDesignMode: true,

                applicationCode: "Design",
            });
            await facade.init();
            await facade.prepareAll();

            const queryObj = {
                entityName: "应用",
                // condition: mergeCriteria(baseCriteria, criteria),
                pageSize: 1000,
                offset: 0,
                fieldPaths: [ "应用名", "代号" ],
            };
            const [result, error] = await facade.listAll(queryObj, {});
            if(error) {
                Message.error(error);
                return 
            }

            // TODO error handling
            const applications = result.data.map(app => {
                return {
                    name: app["应用名"],
                    code: app["代号"],
                    id: app.id,
                }
            });

            setFacade(facade);
            setApplications(applications);

        })();
    }, []);


    function renderBody() {
        if (facade === undefined || domainSettings == undefined) {
            return <Loading></Loading>
        }

        const { appcode } = match.params;
        const currentApplication = applications.find(app => app.code.toLowerCase() === (appcode || "default").toLowerCase());

        return (
            <StudioAppBody key={currentApplication.id} {...{
                currentUser, facade, history, dlc, applications, currentApplication, domainSettings
            }} />
        )
    }

    return (
        <QueryTargetContextProvider queryTarget="definition">
            <Helmet><title>LeanCode Studio</title></Helmet>
            {renderBody()}
        </QueryTargetContextProvider>
    )
}

function StudioAppBody({ currentUser, history, dlc, facade, applications, currentApplication, domainSettings }) {

    const [switchConfirmation, setSwitchConfirmation] = useState();

    function switchToApp(app, openWithNewWindow) {
        const { code } = app;
        // const path = location.pathname.replace(/\/design\/[^\/]+\//, `/design/${code.toLowerCase()}/`);
        // 不应该保留原来的 路径后缀，应该直接进入该 app 的默认页面：
        const path = `/studio/${code.toLowerCase()}`;
        if(openWithNewWindow) {
            window.open(path, "_blank");
        } else {
            history.push(path);
        }
    }

    const appId = currentApplication.id;

    const [currentItem, setCurrentItem] = useStateWithLocalCache(
        "studio-stored-item::" + appId, undefined, { parse: StudioItem.fromString, stringify: item => item.toString() }
    );

    const [openedItems, setOpenedItems] = useStateWithLocalCache(
        "studio-stored-openned-items::" + appId, [], { parse: StudioItem.fromStringToList, stringify: StudioItem.listToString }
    );

    const saverRefs = useRef({});

    const [dirtyMarks, setDirtyMarks] = useState({}) // key is itemKey

    function openItem(newItem) {
        setCurrentItem(newItem);
        setOpenedItems(prev => {
            if (prev.find(item => item.itemType == newItem.itemType && item.data.id == newItem.data.id)) {
                return prev
            } else {
                return [...prev, newItem]
            }
        })
    }

    function isActive(item) {
        return item.equalsTo(currentItem);
    }

    function closeItem(item) {
        if (!openedItems.some(i => i.equalsTo(item))) {
            // 没有打开
            return null // do nothing
        }

        if (isActive(item)) {
            // remove the item, and find the next one to be currentItem
            const index = openedItems.findIndex(i => (i.equalsTo(item)));

            const nextIndex = (() => {
                if (index === -1) {
                    // 正常不会出现
                    return undefined
                } else if (index < openedItems.length - 1) {
                    return index + 1
                } else if (index > 0) {
                    return index - 1
                } else {
                    // index is 0 and openItems.length == 1
                    return undefined
                }
            })();

            const nextCurrentItem = openedItems[nextIndex];

            setCurrentItem(nextCurrentItem);
            setOpenedItems(prev => {
                return prev.filter(i => !i.equalsTo(item))
            })
        } else {
            setOpenedItems(prev => {
                return prev.filter(i => !i.equalsTo(item))
            })
        }
        // 移除 saver:
        const itemKey = item.itemKey();
        const { [itemKey]: _, ...remainingSavers } = saverRefs.current;
        saverRefs.current = remainingSavers;

        // 移除 dirty mark;
        const { [itemKey]: _not_used, ...remamingDirtyMarks } = dirtyMarks;
        setDirtyMarks(remamingDirtyMarks);
    }

    useEffect(() => {

        const unsubscribe = getGlobalEventBus().on("updateItemData", ({ itemType, itemData }) => {

            // update the current item, opened items, and tell the upper layer:
            console.log(">>> update date", itemType, itemData, currentItem, openedItems);

            setCurrentItem(prev => {
                return prev ? prev.updateData(itemData) : prev
            });

            setOpenedItems(prev => {
                return prev.map(i => {
                    return i.data.id === itemData.id ? i.updateData(itemData) : i
                })
            })

        });
        return () => {
            unsubscribe();
        }
    }, [])

    return (
        <DndProvider backend={HTML5Backend}>
             <Helmet><title>LeanCode Studio | { currentApplication.name }</title></Helmet>
            <ApplicationContextProvider
                applications={applications}
                currentApplication={currentApplication}
                mode="design"
            >
                <FacadeContextProvider facade={facade}>
                    <div className="studio-app">
                        <div className="top-area">
                            <StudioTopBar {...{
                                currentUser, applications, currentApplication, history,
                                switchApplication: app => {
                                    const hasDirty = () => {
                                        return Object.keys(dirtyMarks).some(k => dirtyMarks[k]);
                                    }
                                    if(hasDirty()) {
                                        setSwitchConfirmation(app)
                                    } else {
                                        switchToApp(app)
                                    }

                                }
                            }} />
                        </div>
                        <ResizableSplitPanels {...{
                            className: "main-area-wrapper",
                            mainAreaClassName: "main-area",
                            cacheKey: "studio-side-panel-width",
                            sidePanelContent: setMinimized => (
                                <StudioExplorer {...{
                                    facade,
                                    currentApplication,

                                    domainSettings,

                                    openItem,
                                    closeItem,
                                    currentItem, 

                                    setMinimized,

                                }}></StudioExplorer>
                            ),
                            mainAreaContent: (
                                <StudioEditorArea {...{
                                    dlc, facade,

                                    currentApplication,

                                    domainSettings,

                                    currentItem, openedItems, openItem, closeItem, isActive, setCurrentItem,
                                    dirtyMarks, setDirtyMarks,

                                    bindSaver: (itemKey, saver) => {
                                        saverRefs.current = {
                                            ...saverRefs.current,
                                            [itemKey]: saver
                                        }
                                    },
                                    saverRefs,
                                }} />
                            )
                        }} />
                    </div>
                    <SwitchConfirmDialog {...{
                        switchConfirmation, setSwitchConfirmation, switchToApp
                    }} />
                </FacadeContextProvider>
            </ApplicationContextProvider>
        </DndProvider>
    )
}


function SwitchConfirmDialog({switchConfirmation, setSwitchConfirmation, switchToApp}) {
    function renderContent() {
        if (!switchConfirmation) return null;

        return (
            <div className="studio-dialog">
                <div className="dialog-section">
                    <div className="prompt-message">
                        <span>如果在当前窗口切换应用，会丢弃当前未保存的修改。你确定要切换到应用“{switchConfirmation.name}”？(也可以新窗口打开）</span>
                    </div>

                </div>
                <div className="dialog-section">
                    <Button appearance="primary" color="red" onClick={async () => {                    
                        setSwitchConfirmation(undefined);
                        switchToApp(switchConfirmation);

                    }}>
                        切换
                    </Button>
                    <Button onClick={async () => {                    
                        setSwitchConfirmation(undefined);
                        switchToApp(switchConfirmation, true);

                    }}>
                        新窗口打开
                    </Button>
                </div>
                <div className="dialog-section">
                    <Button onClick={() => {
                        setSwitchConfirmation(undefined);
                    }}>
                        取消
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Modal {...{
            visible: !!switchConfirmation,
            containerStyle: {
                borderRadius: 4,
                width: 250,
            },
            modalContent: renderContent()
        }} />
    )

}


function loadDomainSettings() {
    return async (env) => {
        //// the entity
        const queryText = `query { definition { settings { multitenancy } } }`
        const result = await runDefinitionQuery(env)(queryText)()
        const { data, errors } = JSON.parse(result)
        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.definition ? data.definition.settings : null
        }
    }
}

function useDomainSettings () {
    const key = `definition::settings`
    const { getData } = useDataLoader({[key]: loadDomainSettings()})
    const [ domainSettings ] = getData(key)
    return domainSettings
}
