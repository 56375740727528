
import React, { useRef, useState } from 'react';

import Button from './Button';

import Popover from 'bwax-ui/components/legacy/Popover';

import "./RecordOpActionButton.less";
import RecordSelector from './RecordSelector';

import setupOperationHandlers from '../components/setupOperationHanlders';


export default function RecordOpActionButton(props) {

    const visibleSetterRef = useRef(null);

    const { buttonConfig, actionConfig, context, dataEntity, reload } = props;

    // 固定有一个
    const { title, op, recordQuery }  = actionConfig[0];

    const { bwax, route_to } = context;

    const OperationHandlers = setupOperationHandlers({ bwax, entity: dataEntity, reload, route_to });

    const [ selected, setSelected ] = useState(undefined);

    function close() {
        if (visibleSetterRef.current) {
            visibleSetterRef.current(false)
        }
    }

    const button = (
        <Button {...{
            ...buttonConfig,
            onClick: () => {
                console.log("Clicked")
            }
        }} />
    );
    

    function renderContent() {

        const handler = OperationHandlers[op.__t__];
        const handleAction = async id => {
            if (handler) {
                const succeeded = await handler(op.__v__, { id });
                if(succeeded) {
                    close();
                }
            } else {
                console.log("No handler", op);
            };
        }

        return (
            <div className="admin--record-op-panel">
                {title ? (
                    <div className="title">
                        { title }
                    </div>
                ) : null}
                <div className="record-selector">
                    <RecordSelector {...{
                        dataEntity,
                        recordQuery,
                        facade: context.bwax,

                        onChange: v => {
                            setSelected(v);
                        }
                    }} />
                </div>
                <div className="button-bar">
                    <Button {...{
                        label: "取消",
                        buttonType: "subtle",
                        onClick: () => {
                            setSelected(undefined);
                            close();
                        }
                    }} />
                    <Button {...{
                        label: "确定",
                        buttonType: "primary",
                        disabled: !selected,
                        onClick: () => {
                            handleAction(selected);
                        }
                    }} />
                </div>
            </div>
        )
    };

    return (
        <Popover content={renderContent()} trigger={"click"} positions={["left"]} align="start" padding={8} {...{
            bindVisibleSetter: setter => {
                visibleSetterRef.current = setter;
            },
        }}>
            <Button {...{
                ...buttonConfig,
                onClick: () => {
                    console.log("Clicked")
                }
            }} />
        </Popover>
    )

    return button;

}