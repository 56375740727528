
import React from 'react'

import PicturesWallComp from 'Client/js/components/PicturesWall';

export default function PicturesWall({ params }) {
    const { value, width, height, processor } = params

    // return <div>HELLO WORLD</div>;

    return (
        // <Suspense fallback={null}>
            <PicturesWallComp
                value={value}
                width={width}
                height={height}
                processor={processor}
            />
        // </Suspense>
    )
}
