
import React, { createElement, cloneElement, useState, useRef, useEffect } from 'react';

import ReactDOM from 'react-dom';

import { withMeasurer } from "./ItemMeasurer";

import debounce from 'lodash/debounce';

// 暂时不 virtualized，先顶替掉 react-window 的版本：

export default React.forwardRef(function VirtualList(props, ref) {

    const { 
        children: baseChild, innerElementType, itemCount, itemKey, outerRef,
        fixedLeftRef, fixedLeftRow, fixedLeftStyle, fixedLeftColumns,
        fixedRightRef, fixedRightRow, fixedRightStyle, fixedRightColumns,
        height, initializing,
    } = props;

    // 
    const bufferedRowHeights = useRef({});
    const [ rowHeights, setRowHeights ] = useState({});

    const batchUpdates = debounce(ReactDOM.unstable_batchedUpdates, 64);

    const startIndex = 0;
    const stopIndex = itemCount;

    // Mesuare 每一行的 body 高度，然后传给对应的其他 fixedLeft, fixedRight
    function handleItemMeasured(index, size, isCommitPhase) {

        // 这里可能要 batch 一下：
        // 使用 ref 来收集，
        // 用 throttledBatchUpdates 来改它：
        if(bufferedRowHeights.current[index] !== size) {
            // console.log("Here we change buffer rowHeight", index, size);
            bufferedRowHeights.current[index] = size;
            
            batchUpdates(() => {
                // console.log("Update row heights");
                setRowHeights(bufferedRowHeights.current);
            })
        }
    }

    function renderItems() {
        let items = [];
        for (let index = startIndex; index < stopIndex; index++) {
            const key = itemKey(index);
            items.push(withMeasurer(
                cloneElement(baseChild, {
                    key,
                    index,
                    // isScrolling: useIsScrolling ? isScrolling : undefined,
                    // style: this._getItemStyle(index, itemKey(index, itemData), itemData),
                }),
                key,
                index, 
                handleItemMeasured
            ))
        }
        return items;
    }

    const renderFixedItems = (fixedRow, side) => {
        // console.log("Render Fixed Items", fixedColumns, fixedRow);
        const items = [];
        if (itemCount > 0) {
            for (let index = startIndex; index < stopIndex; index++) {
                // const { size } = getItemMetadata(
                //     instance.props,
                //     index,
                //     instanceProps
                // );
                items.push(
                    cloneElement(fixedRow, {
                        key: itemKey(index),
                        index,
                        // isScrolling: useIsScrolling ? isScrolling : undefined,

                        style: {
                            // ...instance._getItemStyle(index, itemKey(index, itemData), itemData),
                            height: rowHeights[index]
                        },
                    })
                );
            }
        }
        return items;
    }

    const items = renderItems();

    const table = createElement(innerElementType || 'div', {
        children: items,
        // ref: innerRef,
        // style: {
        //     height: direction === 'horizontal' ? '100%' : estimatedTotalSize,
        //     pointerEvents: isScrolling ? 'none' : '',
        //     width: direction === 'horizontal' ? estimatedTotalSize : '100%',
        // },
    })


    return (
        <div style={{ position: 'relative' }} ref={ref}>
            <div {...{
                ref: outerRef, 
                style: {
                    width: "100%",
                    height,
                    overflow: 'auto',
                    position: 'relative',
                    WebkitOverflowScrolling: 'touch',
                    willChange: 'transform',
                },
                onScroll: e => {
                    // console.log("E", e);
                }
            }}>
                {table}
            </div>
            <FixedComponent
                fixedRefSetter={fixedLeftRef}
                {...{
                    innerElementType,
                    fixItems: renderFixedItems(fixedLeftRow, "left"),
                    fixedColumns: fixedLeftColumns,
                    direction: "virtical",
                    // isScrolling,
                    // estimatedTotalSize,
                    className: "fixed-left-items",

                    initializing,

                    style: {
                        left: 0,
                        ...fixedLeftStyle,
                        willChange: 'transform',
                        // ...getNoFixedItemsStyle(fixedLeftItems)
                    }
                }}
            />

            <FixedComponent
                fixedRefSetter={fixedRightRef}
                {...{
                    innerElementType,
                    fixItems: renderFixedItems(fixedRightRow, "right"),
                    fixedColumns: fixedRightColumns,
                    direction: "virtical",
                    // isScrolling,
                    // estimatedTotalSize,
                    // className: "fixed-right-items",

                    initializing,

                    style: {
                        right: 0,
                        ...fixedRightStyle,
                        willChange: 'transform',
                        // ...getNoFixedItemsStyle(fixedLeftItems)
                    }
                }}
            />            
        </div>
    );
})


function FixedComponent(props) {
    const {
        fixedRefSetter,
        innerElementType,
        fixItems,
        fixedColumns,
        style,
        className,

        initializing,
    } = props

    return (
    <div
            ref={fixedRefSetter}
            className={`fixed-items ${className || ""}`}
            style={{
                position: 'absolute',
                top: 0,
                background: 'white',
                overflow: 'auto',
                ...style
            }}>
            {
                initializing || fixItems.length > 0 ? createElement(innerElementType || 'div', {
                    children: fixItems,
                    fixedColumns,

                    style: {
                        // height: direction === 'horizontal' ? '100%' : estimatedTotalSize,
                        // pointerEvents: isScrolling ? 'none' : '',
                        // width: direction === 'horizontal' ? estimatedTotalSize : '100%',
                    },
                }) : null
            }
        </div>
    )
}