import React, { useEffect, useState } from 'react'

import { Checkbox } from 'antd'
import omit from 'lodash/omit'

import "./RolePermissionInput.less"

export default function RolePermissionInput(props) {

    const { allEntities, onChange, value } = props

    const [rolePermissions, setRolePermissions] = useState(value ? value : {})
    const [selectedEntity, setSelectedEntity] = useState(null) // 选中的 entity
    const [selectedPermission, setSelectedPermission] = useState([])

    useEffect(() => {
        if(!value) {
            console.log("clear selectedEntity and selectedPermission");
            setSelectedEntity(null)
            setSelectedPermission([])
            setRolePermissions({})
        }
    }, [ value ])

    const defaultEntity = [{ name: "*" }]

    const defaultPermissions = ["*", "单个查询", "列表查询", "添加", "修改", "删除", "导出"]

    const getConvertPermissions = () => {
        if(selectedEntity && selectedEntity !== '*') {
            const selectedEntityInterFaces = allEntities.find(e => e.name === selectedEntity).interfaces
            const convertInterfaces = selectedEntityInterFaces.length > 0 ? selectedEntityInterFaces.map(i => i.name) : []
            return [...defaultPermissions, ...convertInterfaces]
        } else {
            return defaultPermissions
        }
    }

    const changeSelectedPermissions = value => {
        setSelectedPermission(value)

        const newRolePermissions = value.length === 0 ? omit(rolePermissions, selectedEntity) : {
            ...rolePermissions,
            [selectedEntity]: {
                ops: value
            }
        }

        setRolePermissions(newRolePermissions)
        onChange(newRolePermissions)
    }


    return (
        <div className="role-permission-input-container">
            <div className="entity-column">
                {
                    defaultEntity.concat(allEntities).map((entity, idx) => {
                        return (
                            <div key={idx}
                                className={
                                    `entity-item 
                                    ${Object.keys(value ? value : {}).some(key => key === entity.name) ? "focused" : ""}
                                    ${entity.name === selectedEntity ? "selected" : ""}
                                    `
                                }
                                onClick={() =>{
                                    setSelectedEntity(entity.name)
                                    setSelectedPermission(rolePermissions && rolePermissions[entity.name] ? rolePermissions[entity.name].ops : []) 
                                }}
                            >
                                {entity.name}
                            </div>
                        )
                    })
                }
            </div>
            <div className="permission-column">
            {
                selectedEntity ? (
                    <Checkbox.Group 
                        value={selectedPermission} 
                        onChange={(value) => changeSelectedPermissions(value)}
                    >
                        {
                            getConvertPermissions().map((permission, idx) => {
                                return (
                                    <Checkbox
                                        key={idx}
                                        value={permission}
                                    >
                                        {permission}
                                    </Checkbox>
                                )
                            })
                        }
                    </Checkbox.Group>
                ) : null
            }
            </div>
        </div>
    )
}
