import useDataLoader from "bwax-ui/legacy/store/useDataLoader";
import loadDefinitions, { getEntitiesAndDataTypes } from "bwax-ui/legacy/store/loaders/loadDefinitions"

export default function useDefinitions(queryData = "data", sandboxAlways = false) {

    /// 同一个页面不可能出现即是 sandbox 又是 production 的数据
    const key = `definitions-${queryData}-${sandboxAlways}` 

    const { getData } = useDataLoader({ [key]: loadDefinitions(queryData, sandboxAlways) })
    const [ definitions ] = getData(key)

    if(definitions === undefined) {
        return {} // 还是 undefined ?
    }

    const { dataTypes, extensions, theme } = definitions

    const { entities, allDataTypes, allEntities, lookupValueSettings } = getEntitiesAndDataTypes(definitions)
    ////// refresh functions

    //////

    return {
        entities, 
        dataTypes, 
        extensions, 
        allDataTypes, 
        allEntities, 
        theme,
        lookupValueSettings,
    }
}