
import React from 'react';

import { make as ElementRenderer} from "bwax-ui/ml/widget/ElementRenderer.bs";

import AdminWidget from './AdminWidget';

import "./ElementView.less";

export default function ElementView (props) {

    const { context, config, element, facade } = props;

    const {
        onMsg,
        viewEnv,
    } = context;

    const { title, description } = config;

    return (
        <AdminWidget {...{
            className: "admin--element-view", title, description
        }}>
            <ElementRenderer
                {...{
                    element,
                    onMsg,
                    wrapperClass: "element-view-box",
                    facade,
                    viewEnv,
                }}
            />
        </AdminWidget>
    )


}


export function create(props) {
    return <ElementView {...props} />
}
