// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BaseUI$BwaxAdmin = require("../../../../lib/bwax-ui/re/BaseUI.bs.js");
var Popover = require("antd/lib/popover");
var JsonUtils$BwaxAdmin = require("../../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var ColorTypes$BwaxAdmin = require("../../../../lib/bwax-ui/re/ColorTypes.bs.js");
var StringUtils$BwaxAdmin = require("../../../../lib/bwax-js/re/utils/StringUtils.bs.js");
var PosterCanvas$BwaxAdmin = require("../../components/PosterCanvas.bs.js");
var Sketch = require("react-color/lib/Sketch");
var PosterPreviewBtn$BwaxAdmin = require("../../components/PosterPreviewBtn.bs.js");
var $$Image = require("client/js/ui/widgets/input/Image");

var $$Image$1 = { };

function Input_PosterSetting(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var params = Props.params;
  var theme = Props.theme;
  var match = React.useState((function () {
          return ;
        }));
  var setEditingArea = match[1];
  var editingArea = match[0];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setCustomPreviewNickname = match$1[1];
  var customPreviewNickname = match$1[0];
  var previewAvatar = params.previewAvatar;
  var previewNickname = params.previewNickname;
  var id = params.id;
  var nicknameOptionsKey = "nickNameOptions";
  var noDecode = function (j) {
    return Caml_option.some(j);
  };
  var backgroundImage = PosterCanvas$BwaxAdmin.fieldValue(value, "backgroundImage", noDecode);
  var avatarOptions = PosterCanvas$BwaxAdmin.fieldValue(value, "avatarOptions", noDecode);
  var qrCodeOptions = PosterCanvas$BwaxAdmin.fieldValue(value, "qrCodeOptions", noDecode);
  var nicknameOptions = PosterCanvas$BwaxAdmin.fieldValue(value, nicknameOptionsKey, noDecode);
  var onBackgroundImageChange = function (image) {
    var v = image !== undefined ? JsonUtils$BwaxAdmin.setField(value, "backgroundImage", Caml_option.valFromOption(image)) : JsonUtils$BwaxAdmin.removeField(value, "backgroundImage");
    return Curry._1(onChange, Caml_option.some(v));
  };
  var partial_arg = "背景图";
  var partial_arg_000 = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg, param, param$1, param$2, param$3);
  };
  var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      (function (param, param$1, param$2, key) {
          return React.createElement($$Image.default, {
                      value: backgroundImage,
                      onChange: onBackgroundImageChange,
                      params: {
                        width: 120,
                        height: 120,
                        processor: "medium",
                        isPublic: true,
                        uploadFor: "YQYLPoster-backgroundImage"
                      },
                      key: key
                    });
        }),
      /* [] */0
    ]);
  var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      partial_arg_000,
      partial_arg_001
    ]);
  var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
          297481091,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.5
            ])
        ]),
      /* [] */0
    ]);
  var imageUploadBox = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.column(partial_arg$2, partial_arg$1, param, param$1, param$2, param$3);
  };
  var partial_arg$3 = "位置大小设定";
  var partial_arg$4 = "选中后右图划定区域";
  var partial_arg$5 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
          -481689226,
          /* lightText */-971527005
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `textSize */Caml_chrome_debugger.polyVar("textSize", [
              -816909298,
              /* `px */Caml_chrome_debugger.polyVar("px", [
                  25096,
                  11
                ])
            ]),
          /* [] */0
        ])
    ]);
  var partial_arg_000$1 = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.text(partial_arg$5, partial_arg$4, param, param$1, param$2, param$3);
  };
  var partial_arg$6 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      partial_arg_000$1,
      /* [] */0
    ]);
  var partial_arg_002 = function (v) {
    return Curry._1(setEditingArea, (function (param) {
                  return v;
                }));
  };
  var partial_arg_003 = /* options : :: */Caml_chrome_debugger.simpleVariant("::", [
      /* record */Caml_chrome_debugger.record([
          "value",
          "label"
        ], [
          0,
          "头像"
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* record */Caml_chrome_debugger.record([
              "value",
              "label"
            ], [
              1,
              "二维码"
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* record */Caml_chrome_debugger.record([
                  "value",
                  "label"
                ], [
                  2,
                  "昵称"
                ]),
              /* [] */0
            ])
        ])
    ]);
  var partial_arg_005 = /* color *//* B60 */3294204;
  var partial_arg$7 = /* record */Caml_chrome_debugger.record([
      "align",
      "selected",
      "onChange",
      "options",
      "disabled",
      "color"
    ], [
      -963948842,
      editingArea,
      partial_arg_002,
      partial_arg_003,
      false,
      partial_arg_005
    ]);
  var partial_arg$8 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
          297481091,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.25
            ])
        ]),
      /* [] */0
    ]);
  var partial_arg_000$2 = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg$3, param, param$1, param$2, param$3);
  };
  var partial_arg_001$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      (function (param, param$1, param$2, param$3) {
          return BaseUI$BwaxAdmin.paragraph(/* [] */0, partial_arg$6, param, param$1, param$2, param$3);
        }),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.inputRadio(partial_arg$8, /* [] */0, partial_arg$7, param, param$1, param$2, param$3);
            }),
          /* [] */0
        ])
    ]);
  var partial_arg$9 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      partial_arg_000$2,
      partial_arg_001$1
    ]);
  var partial_arg$10 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
          297481091,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.5
            ])
        ]),
      /* [] */0
    ]);
  var areaSelectSwitch = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.column(partial_arg$10, partial_arg$9, param, param$1, param$2, param$3);
  };
  var shape = PosterCanvas$BwaxAdmin.pathValue(avatarOptions, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          "shape",
          /* [] */0
        ]), Js_json.decodeString);
  var onAvatarShapeSelected = function (mv) {
    if (mv !== undefined) {
      var ao = JsonUtils$BwaxAdmin.setField(avatarOptions, "shape", mv);
      return Curry._1(onChange, Caml_option.some(JsonUtils$BwaxAdmin.setField(value, "avatarOptions", ao)));
    } else {
      return /* () */0;
    }
  };
  var partial_arg$11 = "头像形式";
  var partial_arg_003$1 = /* options : :: */Caml_chrome_debugger.simpleVariant("::", [
      /* record */Caml_chrome_debugger.record([
          "value",
          "label"
        ], [
          "Circle",
          "圆形"
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* record */Caml_chrome_debugger.record([
              "value",
              "label"
            ], [
              "Square",
              "方形"
            ]),
          /* [] */0
        ])
    ]);
  var partial_arg_005$1 = /* color *//* B60 */3294204;
  var partial_arg$12 = /* record */Caml_chrome_debugger.record([
      "align",
      "selected",
      "onChange",
      "options",
      "disabled",
      "color"
    ], [
      -963948842,
      shape,
      onAvatarShapeSelected,
      partial_arg_003$1,
      false,
      partial_arg_005$1
    ]);
  var partial_arg$13 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
          297481091,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.25
            ])
        ]),
      /* [] */0
    ]);
  var partial_arg_000$3 = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg$11, param, param$1, param$2, param$3);
  };
  var partial_arg_001$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      (function (param, param$1, param$2, param$3) {
          return BaseUI$BwaxAdmin.inputRadio(partial_arg$13, /* [] */0, partial_arg$12, param, param$1, param$2, param$3);
        }),
      /* [] */0
    ]);
  var partial_arg$14 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      partial_arg_000$3,
      partial_arg_001$2
    ]);
  var partial_arg$15 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
          297481091,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.5
            ])
        ]),
      /* [] */0
    ]);
  var avatarShapeSelect = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.column(partial_arg$15, partial_arg$14, param, param$1, param$2, param$3);
  };
  var nickNameFontSize = PosterCanvas$BwaxAdmin.fieldValue(nicknameOptions, "fontSize", Js_json.decodeNumber);
  var onNickNameFontSizeChange = function (mv) {
    var newNicknameOptions;
    if (mv !== undefined) {
      var match = StringUtils$BwaxAdmin.toFloat(mv);
      newNicknameOptions = match !== undefined ? Caml_option.some(JsonUtils$BwaxAdmin.setField(nicknameOptions, "fontSize", match)) : Caml_option.some(JsonUtils$BwaxAdmin.removeField(nicknameOptions, "fontSize"));
    } else {
      newNicknameOptions = Caml_option.some(JsonUtils$BwaxAdmin.removeField(nicknameOptions, "fontSize"));
    }
    if (newNicknameOptions !== undefined) {
      return Curry._1(onChange, Caml_option.some(JsonUtils$BwaxAdmin.setField(value, nicknameOptionsKey, Caml_option.valFromOption(newNicknameOptions))));
    } else {
      return /* () */0;
    }
  };
  var nickNameColor = PosterCanvas$BwaxAdmin.fieldValue(nicknameOptions, "color", Js_json.decodeString);
  var partial_arg$16 = "昵称尺寸和颜色";
  var value$1 = Belt_Option.map(nickNameFontSize, (function (s) {
          return s.toString();
        }));
  var partial_arg$17 = BaseUI$BwaxAdmin.inputText(/* record */Caml_chrome_debugger.record(["maxLength"], [undefined]), value$1, onNickNameFontSizeChange, "文字尺寸（px）");
  var onChange$1 = function (mv) {
    return Curry._1(setCustomPreviewNickname, (function (param) {
                  return mv;
                }));
  };
  var partial_arg$18 = BaseUI$BwaxAdmin.inputText(/* record */Caml_chrome_debugger.record(["maxLength"], [undefined]), customPreviewNickname, onChange$1, "用于示例的昵称");
  var partial_arg_000$4 = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg$16, param, param$1, param$2, param$3);
  };
  var partial_arg_001$3 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      (function (param, param$1, param$2, param$3) {
          return partial_arg$17(false, /* [] */0, param, param$1, param$2, param$3);
        }),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          (function (param, param$1, param$2, key) {
              var picker = React.createElement(Sketch.default, {
                    color: nickNameColor,
                    onChangeComplete: (function (changedValue) {
                        var v = changedValue.hex;
                        var no = JsonUtils$BwaxAdmin.setField(nicknameOptions, "color", v);
                        return Curry._1(onChange, Caml_option.some(JsonUtils$BwaxAdmin.setField(value, nicknameOptionsKey, no)));
                      }),
                    key: key
                  });
              var textColor = BaseUI$BwaxAdmin.themeColor(theme, ColorTypes$BwaxAdmin.toString(/* N40 */3890506));
              var buttonStyle = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.width(Css.pct(100.0)),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.borderRadius(Css.px(2)),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.backgroundColor(Css.hex(StringUtils$BwaxAdmin.after(Belt_Option.getWithDefault(nickNameColor, PosterCanvas$BwaxAdmin.defaultNickNameColor), 1))),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.unsafe("color", textColor),
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Css.fontSize(Css.rem(0.75)),
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Css.padding(Css.rem(0.25)),
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              Css.textAlign(/* center */98248149),
                                              /* [] */0
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]));
              return React.createElement(Popover.default, {
                          key: key,
                          content: picker,
                          children: React.createElement("div", {
                                className: buttonStyle,
                                role: "button",
                                tabIndex: 0
                              }, "选择颜色")
                        });
            }),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              (function (param, param$1, param$2, param$3) {
                  return partial_arg$18(false, /* [] */0, param, param$1, param$2, param$3);
                }),
              /* [] */0
            ])
        ])
    ]);
  var partial_arg$19 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      partial_arg_000$4,
      partial_arg_001$3
    ]);
  var partial_arg$20 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
          297481091,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.5
            ])
        ]),
      /* [] */0
    ]);
  var nickNameStyleInput = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.column(partial_arg$20, partial_arg$19, param, param$1, param$2, param$3);
  };
  var previewButton = function (param, param$1, param$2, key) {
    return React.createElement(PosterPreviewBtn$BwaxAdmin.make, {
                setting: value,
                id: id,
                key: key
              });
  };
  var partial_arg_001$4 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      areaSelectSwitch,
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          avatarShapeSelect,
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              nickNameStyleInput,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  previewButton,
                  /* [] */0
                ])
            ])
        ])
    ]);
  var partial_arg$21 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      imageUploadBox,
      partial_arg_001$4
    ]);
  var partial_arg$22 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `width */Caml_chrome_debugger.polyVar("width", [
          -899500538,
          /* fill */-1011102077
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
              297481091,
              /* `rem */Caml_chrome_debugger.polyVar("rem", [
                  5691738,
                  1.5
                ])
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `height */Caml_chrome_debugger.polyVar("height", [
                  38537191,
                  /* fill */-1011102077
                ]),
              /* [] */0
            ])
        ])
    ]);
  var controlBox = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.column(partial_arg$22, partial_arg$21, param, param$1, param$2, param$3);
  };
  var onAreaSelected = function (area) {
    var nv = Belt_Option.map(editingArea, (function (ea) {
            switch (ea) {
              case /* Avatar */0 :
                  var mdw = PosterCanvas$BwaxAdmin.fieldValue(Caml_option.some(area), "dw", Js_json.decodeNumber);
                  var mdh = PosterCanvas$BwaxAdmin.fieldValue(Caml_option.some(area), "dh", Js_json.decodeNumber);
                  var newAreaJson;
                  if (mdw !== undefined && mdh !== undefined) {
                    var dh = mdh;
                    var dw = mdw;
                    var match = dw < dh;
                    var smaller = match ? dw : dh;
                    newAreaJson = JsonUtils$BwaxAdmin.setField(Caml_option.some(JsonUtils$BwaxAdmin.setField(Caml_option.some(area), "dw", smaller)), "dh", smaller);
                  } else {
                    newAreaJson = area;
                  }
                  return JsonUtils$BwaxAdmin.setField(value, "avatarOptions", JsonUtils$BwaxAdmin.absorb(avatarOptions, newAreaJson));
              case /* QrCode */1 :
                  return JsonUtils$BwaxAdmin.setField(value, "qrCodeOptions", JsonUtils$BwaxAdmin.absorb(qrCodeOptions, area));
              case /* NickName */2 :
                  return JsonUtils$BwaxAdmin.setField(value, nicknameOptionsKey, JsonUtils$BwaxAdmin.absorb(nicknameOptions, JsonUtils$BwaxAdmin.removeField(Caml_option.some(area), "dh")));
              
            }
          }));
    if (nv !== undefined) {
      return Curry._1(onChange, nv);
    } else {
      return /* () */0;
    }
  };
  var partial_arg_000$5 = function (param, param$1, param$2, key) {
    return React.createElement(PosterCanvas$BwaxAdmin.make, {
                backgroundImage: backgroundImage,
                avatarOptions: avatarOptions,
                nicknameOptions: nicknameOptions,
                qrCodeOptions: qrCodeOptions,
                editingArea: editingArea,
                previewAvatar: previewAvatar,
                previewNickname: Belt_Option.getWithDefault(customPreviewNickname, previewNickname),
                onAreaSelected: onAreaSelected,
                key: key
              });
  };
  var partial_arg$23 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      partial_arg_000$5,
      /* [] */0
    ]);
  var canvasBox = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.column(/* [] */0, partial_arg$23, param, param$1, param$2, param$3);
  };
  return BaseUI$BwaxAdmin.row(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* fill */-1011102077
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                        297481091,
                        /* `rem */Caml_chrome_debugger.polyVar("rem", [
                            5691738,
                            1.0
                          ])
                      ]),
                    /* [] */0
                  ])
              ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                controlBox,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    canvasBox,
                    /* [] */0
                  ])
              ]), theme, /* row */5693978, /* [] */0, "nokey");
}

var make = Input_PosterSetting;

exports.$$Image = $$Image$1;
exports.make = make;
/* Css Not a pure module */
