// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var NivoLazyJs = require("./NivoLazy.js");
var BaseUI$BwaxAdmin = require("../BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var make = NivoLazyJs.default;

var NivoLazy = {
  make: make
};

function Nivo(Props) {
  var pc = Props.pc;
  var match = Props.rules;
  var rules = match !== undefined ? match : /* [] */0;
  var theme = Props.theme;
  var name = Props.name;
  var width = Props.width;
  var height = Props.height;
  var config = Props.config;
  var inner = function (param, param$1, param$2, param$3) {
    return React.createElement(make, {
                name: name,
                config: Js_dict.fromList(config)
              });
  };
  return BaseUI$BwaxAdmin.box(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* `pxFloat */Caml_chrome_debugger.polyVar("pxFloat", [
                        -1040484748,
                        width
                      ])
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `height */Caml_chrome_debugger.polyVar("height", [
                        38537191,
                        /* `pxFloat */Caml_chrome_debugger.polyVar("pxFloat", [
                            -1040484748,
                            height
                          ])
                      ]),
                    rules
                  ])
              ]), inner, theme, pc, /* [] */0, "nokey");
}

var make$1 = Nivo;

exports.NivoLazy = NivoLazy;
exports.make = make$1;
/* make Not a pure module */
