
import langEntrySlim from 'bwax/ml/lang/lang_entry_slim.bs'

import adminPageTemplate from './runtimeTemplates/adminPageTemplate';

/** different contexts:
 * - page
 * - virtual-field ( <entityName> )
 * - event-handler ( <entityName>, added / updated / deleted )
 * - basic
 */

export function getCodeTemplate (profile) {

    const { name } = profile;

    const templates = {
      "page": pageTemplate,
      "page-fragment": pageFragmentTemplate,
      "page-component": pageComponentTemplate,
      "virtual-field": virtualFieldTemplate,
      "event-handler": eventHandlerTemplate,
      "scheduled-event-handler": scheduleEventHandlerTemplate,
      "data-interface": dataInterfaceTemplate,
      "admin-page": adminPageTemplate,
      "general-setting": generalSettingTemplate,
    }

    const template = templates[name];

    if (template) {
      return template(profile)
  
    } else {
        throw new Error("Not supported profile: " + name);
    }

}


// page template
let pageTemplate = () => {
    return `

-- 以下为生成的代码（请删除这一行）

import Element exposing (..);

type alias User = # E_用户;

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String
  }
;

init params (user, now): List (String, String) -> (Maybe User, Date) -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)

  | UpdateModel f -> 
      (f model, none)
  
  | _ -> (model, none)
;

ready model: Model -> (Model, Cmd Msg) = 
  (model, none)
;

dispose model: Model -> Cmd Msg =
  none
;

reInit model params: Model -> List (String, String) -> (Model, Cmd Msg) = 
  (model, none)
;


view (model, webEnv): (Model, UI.WebEnv) -> a = 
  { layout = 
      page 
        []
        []
        []
  }
;
`
}




// fragment templates
let pageFragmentTemplate = ({fragmentName}) => {
  let name = langEntrySlim.normalize_name(fragmentName);
  return `

-- 以下为生成的代码（请删除这一行）

module Fr_${name};

import Element exposing (..);

-- init-update-view 主要是用于测试的，不建议复用；如果要复用组件，使用 PageComponent
type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String 
  }
;

init _: p -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)


  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;

view model: Model -> Element Msg = 
  empty
;
`
}



// fragment templates
let pageComponentTemplate = () => {
  // let name = langEntrySlim.normalize_name(fragmentName);
  return `

-- 以下为生成的代码（请删除这一行）

import Element exposing (..);

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String
  }
;

init _ (now, domainEnv): p -> (Date, Cmd.DomainEnv) -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)

  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;

ready model: Model -> (Model, Cmd Msg) = 
  (model, none)
;

dispose model: Model -> Cmd Msg =
  none
;

reInit model _: Model -> p -> (Model, Cmd Msg) = 
  (model, none)
;

view (model, webEnv): (Model, UI.WebEnv)-> Element Msg = 
  empty
;
`
}



// General settings template
// 参数只有 current user.
let generalSettingTemplate = () => {
  return `

-- 以下为生成的代码（请删除这一行）

type alias User = # E_用户;

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String
  }
;

init user: User -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;


update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)
  | _ -> (model, none)
;

view model: Model-> a = 
  model.error
;
`
}





// virtual field template
let virtualFieldTemplate = ({entityName}) => {
  let name = langEntrySlim.normalize_name(entityName);


  return `
-- 以下为生成的代码（请删除这一行）

type alias R_${name} = # E_${name};
type alias User = # E_用户;

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String 
  , record: R_${name}
  }
;

init (record, user): (R_${name}, Maybe User) -> (Model, Cmd Msg) = 
  ({ error = Nothing, record }, none )   
;


update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error }, none)

  | UpdateModel f -> 
      (f model, none)
  
  | _ -> (model, none)
;


view model: Model-> Result String a = 
  case model.error of 
  | Just error -> Err error 
  | Nothing -> Ok <| 
      0
;

`
}


// event handler template 
let eventHandlerTemplate = ({entityName, eventType}) => {
    let name = langEntrySlim.normalize_name(entityName);

    // 新增记录
    
    if (eventType == "RecordAdded" || eventType == "记录新增") {
        return `
-- 以下为生成的代码（请删除这一行）

type alias R_${name} = # E_${name};

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String
  , record: R_${name} 
  }
;

init (record, now, domainEnv): (R_${name}, Date, Cmd.DomainEnv) -> (Model, Cmd Msg) = 
  ({ error = Nothing, record }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error }, none)

  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;


view model: Model-> Result String a = 
  case model.error of 
  | Just error -> Err error 
  | Nothing -> Ok <| 
      0
;

`

    //  记录被更新

    } else if (eventType == "RecordUpdated" || eventType == "记录更新") {

        return `
-- 以下为生成的代码（请删除这一行）

type alias R_${name} = # E_${name};

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String 
  , record: R_${name}
  }
;

init updates (record, now, domainEnv):  Updates_of_${name} -> (R_${name}, Date, Cmd.DomainEnv) -> (Model, Cmd Msg) = 
  ({ error = Nothing, record }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error }, none)

  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;


view model: Model-> Result String a = 
  case model.error of 
  | Just error -> Err error 
  | Nothing -> Ok <|
      0
;

`        
    } else {

return `

-- 以下为生成的代码（请删除这一行）

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String 
  }
;

init deletedRecord (now, domainEnv): Raw_${name} ->  (Date, Cmd.DomainEnv) -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error }, none)

  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;

view model: Model-> Result String a = 
  case model.error of 
  | Just error -> Err error 
  | Nothing -> Ok <|
      0
;

`
  }
  
}

const dataInterfaceTemplate = (config) => {

  const { entityName, interfaceType } = config;
  const name = langEntrySlim.normalize_name(entityName);

  const isOp = interfaceType === "操作";

  return `

-- 以下为生成的代码（请删除这一行）

type alias User = # E_用户;

type alias R_${name} = # E_${name};

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String
  , record: R_${name} 
  }
;

-- 第一个参数是自定义 API 的参数，如果要支持多个数值，则第一个参数可以做成 Tuple 类型
-- 第二个参数包括自定义 API 所针对的特定记录，以及调用 API 的用户；
-- 如果 API 不需要针对特定记录，第二个参数可以改成单独的 Maybe PR_用户或者其他可以 prepared 的值
-- 如果没有额外的参数，第一个参数可以删去
init _ (record, maybeUser, now): a -> (R_${name}, Maybe User, Date) -> (Model, Cmd Msg) = 
  ({ error = Nothing, record }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error }, none)

  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;


view model: Model-> Result String a = 
  case model.error of 
  | Just error -> Err error 
  | Nothing -> Ok <|
      ${isOp ? "model.record" : "0"}
;

`
}

const scheduleEventHandlerTemplate = () => {

  return `

-- 以下为生成的代码（请删除这一行）

type Msg = 
  | GotError String
  | UpdateModel (Model -> Model)
;

type alias Model = 
  { error: Maybe String
  , now: Date
  , domainEnv: Cmd.DomainEnv
  }
;



-- 没有操作参数，只有环境参数
init (now, domainEnv): (Date, Cmd.DomainEnv) -> (Model, Cmd Msg) = 
  ({ error = Nothing, now, domainEnv }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error }, none)

  | UpdateModel f -> 
      (f model, none)

  | _ -> (model, none)
;

view model: Model-> a = 
  case model.error of 
  | Just error -> Err error 
  | Nothing -> Ok <|
      0
;
`

}