
import React from 'react'

import './FluidText.less'

import { color } from 'bwax-ui'
import useScreenSize from 'bwax-ui/auxiliary/useScreenSize'

/**
 * type:    p, helper, quote, display, big
 * maxLine:    
 * color
 */
export default function FluidText (props) {
    const { params } = props

    /// type = p or helper or display
    /// align = left/center/right or { S: left/center/right, L: left/center/right }
    const { text, type = 'p', maxLine,  color: colorName, align } = params

    const screenSize = useScreenSize()

    function getAlignStyle(align) {
        if(!align) return {}
        if(typeof(align) === 'string') {
            return { textAlign: align }
        } else if(align[screenSize]) {
            return { textAlign: align[screenSize] }
        }
        return {}
    }

    const style = {
        ...(colorName ? { color: color(colorName) } : {}),
        ...getAlignStyle(align)
    }
    
    const className = `fluid-text fluid-text-${type}`

    const LINE_HEIGHT = screenSize === 'L' ?  {
        'p': 1.75,
        'helper': 1.5,
        'quote': 1.75,
        'display': 4,
        'big': 3
    } : {
        'p': 1.5,
        'helper': 1.25,
        'quote': 1.5,
        'display': 3,
        'big': 2,
    }

    const lineHeight = LINE_HEIGHT[type]

    if(maxLine === undefined) {
        return (
            <p 
                className={className} 
                style={{ ...style, lineHeight: `${lineHeight}rem` }}
            >
                { text }
            </p>
        )
    }

    const ellipsisStyle = {
        ...style,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: `${maxLine}`,
        lineHeight: `${lineHeight}rem`,
        height: `${maxLine * lineHeight}rem`,
        overflow: 'hidden'
    }

    return (
        <p 
            className={className} 
            style={ellipsisStyle}
        >
            {text}
        </p>
    )



}