// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../re/BaseUI.bs.js");
var UIRules$BwaxAdmin = require("../../re/UIRules.bs.js");
var Lang_eval$BwaxAdmin = require("../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function string_of_value(value) {
  var string_of_element = function (e) {
    var str;
    if (typeof e === "number") {
      str = "";
    } else {
      switch (e.tag | 0) {
        case /* Lifted */0 :
            str = "Lifted::" + (string_of_element(e[1]) + "");
            break;
        case /* Rendered */1 :
            str = "Rendered";
            break;
        default:
          str = "";
      }
    }
    return "#element(" + (str + ")");
  };
  var match = value[0];
  if (typeof match === "number") {
    return Lang_eval$BwaxAdmin.string_of_value(value);
  } else {
    switch (match.tag | 0) {
      case /* V_tuple */0 :
          return Lang_eval$BwaxAdmin.string_of_tuple(Plate$BwaxAdmin.List.map(string_of_value, match[0]));
      case /* V_list */1 :
          return Lang_eval$BwaxAdmin.string_of_list(Plate$BwaxAdmin.List.map(string_of_value, match[0]));
      case /* V_raw */7 :
          var match$1 = value[1];
          if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "Element") {
            return Lang_eval$BwaxAdmin.string_of_value(value);
          } else {
            return string_of_element(match[0]);
          }
      default:
        return Lang_eval$BwaxAdmin.string_of_value(value);
    }
  }
}

function get_text_style_rules(rules) {
  var get_from_basic_rule = function (brs) {
    return Plate$BwaxAdmin.List.filter((function (param) {
                    if (typeof param === "number") {
                      if (param >= -345586203) {
                        if (param >= 1019979362) {
                          if (param !== 1028960125 && param !== 1063496351) {
                            return param < 1019979363;
                          } else {
                            return true;
                          }
                        } else if (param !== 201352959) {
                          return param === 233447657;
                        } else {
                          return true;
                        }
                      } else if (param >= -772109033) {
                        if (param !== -550118877 && param >= -772109032) {
                          return param >= -345586204;
                        } else {
                          return true;
                        }
                      } else if (param !== -1005136686) {
                        return param === -937384257;
                      } else {
                        return true;
                      }
                    } else {
                      var variant = param[0];
                      if (variant >= -688651524) {
                        if (variant >= 128270817) {
                          if (variant !== 528541484) {
                            return variant === 904663632;
                          } else {
                            return true;
                          }
                        } else if (variant !== -481689226) {
                          return variant >= 128270816;
                        } else {
                          return true;
                        }
                      } else if (variant >= -891056902) {
                        if (variant !== -816909298) {
                          return variant >= -688651525;
                        } else {
                          return true;
                        }
                      } else if (variant !== -958002182) {
                        return variant >= -891056903;
                      } else {
                        return true;
                      }
                    }
                  }))(brs);
  };
  var get_from_composite_rule = function (crs) {
    return Plate$BwaxAdmin.List.keep_map((function (tr) {
                  if (typeof tr === "number") {
                    if (tr >= -345586203) {
                      if (tr >= 1019979362) {
                        if (tr !== 1028960125 && tr !== 1063496351 && tr >= 1019979363) {
                          return ;
                        } else {
                          return tr;
                        }
                      } else if (tr !== 201352959 && tr !== 233447657) {
                        return ;
                      } else {
                        return tr;
                      }
                    } else if (tr >= -772109033) {
                      if (tr !== -550118877 && tr >= -772109032 && tr < -345586204) {
                        return ;
                      } else {
                        return tr;
                      }
                    } else if (tr !== -1005136686 && tr !== -937384257) {
                      return ;
                    } else {
                      return tr;
                    }
                  } else {
                    var variant = tr[0];
                    if (variant >= -481689225) {
                      if (variant >= 528541484) {
                        if (variant !== 728032700) {
                          if (variant !== 904663632 && variant >= 528541485) {
                            return ;
                          } else {
                            return tr;
                          }
                        } else {
                          return /* `hover */Caml_chrome_debugger.polyVar("hover", [
                                    728032700,
                                    get_from_basic_rule(tr[1])
                                  ]);
                        }
                      } else if (variant !== 128270816) {
                        if (variant !== 373703110) {
                          return ;
                        } else {
                          return /* `hover */Caml_chrome_debugger.polyVar("hover", [
                                    728032700,
                                    get_from_basic_rule(tr[1])
                                  ]);
                        }
                      } else {
                        return tr;
                      }
                    } else if (variant >= -816909298) {
                      if (variant !== -688651525 && variant >= -816909297 && variant < -481689226) {
                        return ;
                      } else {
                        return tr;
                      }
                    } else if (variant !== -958002182 && variant !== -891056903) {
                      return ;
                    } else {
                      return tr;
                    }
                  }
                }), crs);
  };
  return Plate$BwaxAdmin.List.keep_map((function (tr) {
                if (typeof tr === "number") {
                  if (tr >= -345586203) {
                    if (tr >= 1019979362) {
                      if (tr !== 1028960125 && tr !== 1063496351 && tr >= 1019979363) {
                        return ;
                      } else {
                        return tr;
                      }
                    } else if (tr !== 201352959 && tr !== 233447657) {
                      return ;
                    } else {
                      return tr;
                    }
                  } else if (tr >= -772109033) {
                    if (tr !== -550118877 && tr >= -772109032 && tr < -345586204) {
                      return ;
                    } else {
                      return tr;
                    }
                  } else if (tr !== -1005136686 && tr !== -937384257) {
                    return ;
                  } else {
                    return tr;
                  }
                } else {
                  var variant = tr[0];
                  if (variant >= -481689225) {
                    if (variant >= 373703111) {
                      if (variant !== 528541484) {
                        if (variant !== 728032700) {
                          if (variant !== 904663632) {
                            return ;
                          } else {
                            return tr;
                          }
                        } else {
                          return /* `hover */Caml_chrome_debugger.polyVar("hover", [
                                    728032700,
                                    get_from_basic_rule(tr[1])
                                  ]);
                        }
                      } else {
                        return tr;
                      }
                    } else if (variant !== -265706864) {
                      if (variant !== 128270816) {
                        if (variant >= 373703110) {
                          return /* `hover */Caml_chrome_debugger.polyVar("hover", [
                                    728032700,
                                    get_from_basic_rule(tr[1])
                                  ]);
                        } else {
                          return ;
                        }
                      } else {
                        return tr;
                      }
                    } else {
                      return /* `ifWide */Caml_chrome_debugger.polyVar("ifWide", [
                                -265706864,
                                get_from_composite_rule(tr[1])
                              ]);
                    }
                  } else if (variant >= -816909297) {
                    if (variant !== -688651525) {
                      if (variant !== -593248680) {
                        if (variant >= -481689226) {
                          return tr;
                        } else {
                          return ;
                        }
                      } else {
                        return /* `ifWide */Caml_chrome_debugger.polyVar("ifWide", [
                                  -265706864,
                                  get_from_composite_rule(tr[1])
                                ]);
                      }
                    } else {
                      return tr;
                    }
                  } else if (variant !== -958002182 && variant !== -891056903 && variant < -816909298) {
                    return ;
                  } else {
                    return tr;
                  }
                }
              }), rules);
}

function get_text_size_rules(rules) {
  var get_from_basic_rule = function (brs) {
    return Plate$BwaxAdmin.List.filter((function (param) {
                    if (typeof param === "number") {
                      return false;
                    } else {
                      var variant = param[0];
                      if (variant !== -816909298) {
                        return variant === -688651525;
                      } else {
                        return true;
                      }
                    }
                  }))(brs);
  };
  var get_from_composite_rule = function (crs) {
    return Plate$BwaxAdmin.List.keep_map((function (r) {
                  if (typeof r === "number") {
                    return ;
                  } else {
                    var variant = r[0];
                    if (variant >= -688651524) {
                      if (variant !== 373703110 && variant !== 728032700) {
                        return ;
                      } else {
                        return /* `hover */Caml_chrome_debugger.polyVar("hover", [
                                  728032700,
                                  get_from_basic_rule(r[1])
                                ]);
                      }
                    } else if (variant !== -816909298 && variant < -688651525) {
                      return ;
                    } else {
                      return r;
                    }
                  }
                }), crs);
  };
  return Plate$BwaxAdmin.List.keep_map((function (r) {
                if (typeof r === "number") {
                  return ;
                } else {
                  var variant = r[0];
                  if (variant >= -593248679) {
                    if (variant !== -265706864) {
                      if (variant !== 373703110 && variant !== 728032700) {
                        return ;
                      } else {
                        return /* `hover */Caml_chrome_debugger.polyVar("hover", [
                                  728032700,
                                  get_from_basic_rule(r[1])
                                ]);
                      }
                    } else {
                      return /* `ifWide */Caml_chrome_debugger.polyVar("ifWide", [
                                -265706864,
                                get_from_composite_rule(r[1])
                              ]);
                    }
                  } else if (variant !== -816909298 && variant !== -688651525) {
                    if (variant >= -593248680) {
                      return /* `ifWide */Caml_chrome_debugger.polyVar("ifWide", [
                                -265706864,
                                get_from_composite_rule(r[1])
                              ]);
                    } else {
                      return ;
                    }
                  } else {
                    return r;
                  }
                }
              }), rules);
}

function rules_to_classname(rules) {
  if (Plate$BwaxAdmin.List.length(rules) === 0) {
    return "";
  } else {
    var toCss = function (r) {
      return UIRules$BwaxAdmin.ruleToCss(r, undefined, /* Item_box */3, /* box */4898315, /* [] */0);
    };
    return BaseUI$BwaxAdmin.buildClassName("", rules, toCss);
  }
}

exports.string_of_value = string_of_value;
exports.get_text_style_rules = get_text_style_rules;
exports.get_text_size_rules = get_text_size_rules;
exports.rules_to_classname = rules_to_classname;
/* Plate-BwaxAdmin Not a pure module */
