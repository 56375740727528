
import { parser } from 'bwax/lang/ore.parser';

import lang_parser_lezer from 'bwax/ml/lang/lang_parser_lezer.bs';
import lang_entry from 'bwax/ml/lang/lang_entry.bs'
import lang_entry_base from 'bwax/ml/lang/lang_entry_base.bs'

export default function makeTenvWithDefs(defs, base_tenv, base_dts) {
    const tree = parser.parse(defs);
    
    const [result, parseError] = lang_parser_lezer.convert_defs_entry(defs, tree);
    const [ast, _] = result;

    const [_typed_ast, _slim_ast, tyenv, dts, _external_names_with_types, error] =
        lang_entry.type_defs_to_js(base_dts, base_tenv, ast);
    if(error) {
        console.error(error);
    } else {
        return [ lang_entry_base.merge_env(base_tenv, tyenv), lang_entry_base.merge_dts(base_dts, dts) ]
    }
}