// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Pretty_printer$BwaxAdmin = require("./pretty_printer.bs.js");
var Pretty_printer_common$BwaxAdmin = require("./pretty_printer_common.bs.js");

function extract_comments(list) {
  return Curry._2(Plate$BwaxAdmin.List.partition, (function (c) {
                return c[/* name */0] !== "LineComment";
              }), list);
}

function collect_pairs_and_comments(list) {
  var _pairs = /* [] */0;
  var _param = /* tuple */[
    undefined,
    undefined,
    /* [] */0
  ];
  var _list = list;
  while(true) {
    var param = _param;
    var list$1 = _list;
    var pairs = _pairs;
    if (list$1) {
      var tail = list$1[1];
      var h = list$1[0];
      var comment_list = param[2];
      var maybe_expr = param[1];
      var maybe_symbol = param[0];
      if (tail) {
        if (h[/* name */0] === "LineComment") {
          _list = tail;
          _param = /* tuple */[
            maybe_symbol,
            maybe_expr,
            Pervasives.$at(comment_list, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    h,
                    /* [] */0
                  ]))
          ];
          continue ;
        } else if (maybe_symbol !== undefined) {
          if (maybe_expr !== undefined) {
            var new_pairs = Pervasives.$at(pairs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      maybe_symbol,
                      maybe_expr,
                      comment_list
                    ],
                    /* [] */0
                  ]));
            _list = tail;
            _param = /* tuple */[
              h,
              undefined,
              /* [] */0
            ];
            _pairs = new_pairs;
            continue ;
          } else {
            _list = tail;
            _param = /* tuple */[
              maybe_symbol,
              h,
              comment_list
            ];
            continue ;
          }
        } else {
          _list = tail;
          _param = /* tuple */[
            h,
            undefined,
            comment_list
          ];
          continue ;
        }
      } else if (h[/* name */0] === "LineComment") {
        if (maybe_symbol !== undefined) {
          var symbol = maybe_symbol;
          if (maybe_expr !== undefined) {
            return /* tuple */[
                    Pervasives.$at(pairs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              symbol,
                              maybe_expr,
                              Pervasives.$at(comment_list, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      h,
                                      /* [] */0
                                    ]))
                            ],
                            /* [] */0
                          ])),
                    undefined,
                    undefined
                  ];
          } else {
            return /* tuple */[
                    pairs,
                    h,
                    /* tuple */[
                      symbol,
                      Pervasives.$at(comment_list, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              h,
                              /* [] */0
                            ]))
                    ]
                  ];
          }
        } else {
          return /* tuple */[
                  pairs,
                  undefined,
                  undefined
                ];
        }
      } else if (h[/* src */1] === "}" || h[/* src */1] === "]" || h[/* src */1] === ")") {
        if (maybe_symbol !== undefined) {
          var symbol$1 = maybe_symbol;
          if (maybe_expr !== undefined) {
            return /* tuple */[
                    Pervasives.$at(pairs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              symbol$1,
                              maybe_expr,
                              comment_list
                            ],
                            /* [] */0
                          ])),
                    h,
                    undefined
                  ];
          } else {
            return /* tuple */[
                    pairs,
                    h,
                    /* tuple */[
                      symbol$1,
                      comment_list
                    ]
                  ];
          }
        } else if (Plate$BwaxAdmin.List.is_empty(pairs)) {
          return /* tuple */[
                  /* [] */0,
                  undefined,
                  /* tuple */[
                    h,
                    comment_list
                  ]
                ];
        } else {
          return /* tuple */[
                  pairs,
                  h,
                  undefined
                ];
        }
      } else if (maybe_symbol !== undefined) {
        var symbol$2 = maybe_symbol;
        if (maybe_expr !== undefined) {
          return /* tuple */[
                  Pervasives.$at(pairs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            symbol$2,
                            maybe_expr,
                            comment_list
                          ],
                          /* [] */0
                        ])),
                  h,
                  undefined
                ];
        } else {
          return /* tuple */[
                  Pervasives.$at(pairs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            symbol$2,
                            h,
                            comment_list
                          ],
                          /* [] */0
                        ])),
                  undefined,
                  undefined
                ];
        }
      } else if (Plate$BwaxAdmin.List.is_empty(pairs)) {
        return /* tuple */[
                /* [] */0,
                undefined,
                /* tuple */[
                  h,
                  comment_list
                ]
              ];
      } else {
        return /* tuple */[
                pairs,
                h,
                undefined
              ];
      }
    } else {
      return /* tuple */[
              pairs,
              undefined,
              undefined
            ];
    }
  };
}

function seperate_binop_ops(list) {
  return Plate$BwaxAdmin.List.foldl((function (param, current) {
                var other_comments = param[2];
                var op_and_right = param[1];
                var left_and_comments = param[0];
                if (current[/* name */0] === "LineComment") {
                  if (Plate$BwaxAdmin.List.is_empty(op_and_right)) {
                    return /* tuple */[
                            Pervasives.$at(left_and_comments, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    current,
                                    /* [] */0
                                  ])),
                            op_and_right,
                            other_comments
                          ];
                  } else {
                    return /* tuple */[
                            left_and_comments,
                            op_and_right,
                            Pervasives.$at(other_comments, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    current,
                                    /* [] */0
                                  ]))
                          ];
                  }
                } else if (Plate$BwaxAdmin.List.is_empty(left_and_comments)) {
                  return /* tuple */[
                          Pervasives.$at(left_and_comments, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  current,
                                  /* [] */0
                                ])),
                          op_and_right,
                          other_comments
                        ];
                } else {
                  return /* tuple */[
                          left_and_comments,
                          Pervasives.$at(op_and_right, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  current,
                                  /* [] */0
                                ])),
                          other_comments
                        ];
                }
              }), /* tuple */[
              /* [] */0,
              /* [] */0,
              /* [] */0
            ], list);
}

function lang_doc(option, depth, lang_tree) {
  var children = lang_tree[/* children */5];
  var src = lang_tree[/* src */1];
  var name = lang_tree[/* name */0];
  var partial_arg = /* record */Caml_chrome_debugger.record([
      "start_with_newline_if_necessary",
      "preceeding_break",
      "preserve_trailing_empty_lines"
    ], [
      false,
      " ",
      false
    ]);
  var doc_at_same_line = function (param, param$1) {
    return lang_doc(partial_arg, param, param$1);
  };
  var join_pairs = function (first_delimiter, process_result, depth, pairs) {
    return Pretty_printer$BwaxAdmin.group(Curry._1(process_result, Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.List.reduce((function (acc, param) {
                              var symbol = param[0];
                              var new_doc = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(symbol[/* name */0]), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, param[1]), follow_up(depth, param[2]))));
                              return Pretty_printer$BwaxAdmin.$caret$caret(acc, Pretty_printer_common$BwaxAdmin.indent_with(symbol[/* name */0] === "|" ? " " : "", depth, new_doc));
                            }), (function (param) {
                              return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(param[0][/* name */0]), Pretty_printer$BwaxAdmin.$caret$caret(Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.delimit(" ", undefined), first_delimiter), Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, param[1]), follow_up(depth, param[2]))));
                            }), pairs))));
  };
  var handle_left_associative_binop = function (depth, children) {
    var match = seperate_binop_ops(children);
    var op_and_right = match[1];
    var left_and_comments = match[0];
    var left_and_comments_doc = left_and_comments ? Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth, left_and_comments[0]), follow_up(depth + 1 | 0, left_and_comments[1]))) : Pretty_printer$BwaxAdmin.empty;
    var op_and_right_doc;
    if (op_and_right) {
      var match$1 = op_and_right[1];
      if (match$1 && !match$1[1]) {
        var op = op_and_right[0];
        var right_indent = Plate$BwaxAdmin.Str.length(op[/* src */1]) === 1 ? depth + 2 | 0 : depth + 3 | 0;
        op_and_right_doc = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, op), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), Curry._2(doc_at_same_line, right_indent, match$1[0]))));
      } else {
        op_and_right_doc = Pretty_printer$BwaxAdmin.empty;
      }
    } else {
      op_and_right_doc = Pretty_printer$BwaxAdmin.empty;
    }
    return Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(left_and_comments_doc, Pretty_printer_common$BwaxAdmin.indent(depth + 1 | 0, Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(op_and_right_doc), follow_up(depth, match[2]))))));
  };
  var handle_pairs = function (depth, children) {
    var match = collect_pairs_and_comments(children);
    var maybe_dangling_start_symbol = match[2];
    var maybe_end_symbol = match[1];
    var should_compress = Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (s) {
                return s[/* name */0] !== "}";
              }), maybe_end_symbol));
    var ending = Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map((function (n) {
                return Pretty_printer_common$BwaxAdmin.indent_with(should_compress ? "" : " ", depth, Pretty_printer$BwaxAdmin.text(n[/* name */0]));
              }), maybe_end_symbol));
    var simple_ending = Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map((function (n) {
                return Pretty_printer$BwaxAdmin.text(n[/* name */0]);
              }), maybe_end_symbol));
    if (maybe_dangling_start_symbol !== undefined) {
      var match$1 = maybe_dangling_start_symbol;
      return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(match$1[0][/* name */0]), Pretty_printer$BwaxAdmin.$caret$caret(follow_up(depth - 1 | 0, match$1[1]), Pretty_printer_common$BwaxAdmin.indent_with("", depth, simple_ending)));
    } else {
      return join_pairs(should_compress ? Pretty_printer$BwaxAdmin.delimit(" ", "") : undefined, (function (d) {
                    return Pretty_printer$BwaxAdmin.$caret$caret(d, ending);
                  }), depth, match[0]);
    }
  };
  var unpruned;
  var exit = 0;
  switch (name) {
    case "LineComment" :
    case "ModuleDecl" :
        unpruned = Pretty_printer$BwaxAdmin.text(src);
        break;
    default:
      if (children) {
        switch (name) {
          case "ApplyExpr" :
              var match = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (index, param) {
                      return index === (Plate$BwaxAdmin.List.length(children) - 1 | 0);
                    }), children);
              var partial_arg$1 = depth + 1 | 0;
              var partial_arg$2 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer_common$BwaxAdmin.pile_up(Curry._1(doc_at_same_line, depth), (function (param) {
                              var param$1 = partial_arg$1;
                              var param$2 = param;
                              return lang_doc(partial_arg$2, param$1, param$2);
                            }), Pretty_printer$BwaxAdmin.group, match[0]), follow_up(depth, match[1])));
              break;
          case "CaseBranch" :
              var match$1 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (index, param) {
                      return index === (Plate$BwaxAdmin.List.length(children) - 1 | 0);
                    }), children);
              var match$2 = extract_comments(match$1[0]);
              var matching_line_doc = straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$2[0]);
              var comments_doc = follow_up(depth + 1 | 0, match$2[1]);
              var body_doc = Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map(Pretty_printer$BwaxAdmin.group, Plate$BwaxAdmin.$$Option.map((function (body) {
                              var partial_arg = /* record */Caml_chrome_debugger.record([
                                  "start_with_newline_if_necessary",
                                  "preceeding_break",
                                  "preserve_trailing_empty_lines"
                                ], [
                                  true,
                                  " ",
                                  false
                                ]);
                              return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.must_break, (function (param, param$1) {
                                              return lang_doc(partial_arg, param, param$1);
                                            })(depth + 2 | 0, body));
                            }), Plate$BwaxAdmin.List.head(match$1[1]))));
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.must_break, Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer_common$BwaxAdmin.indent(depth, Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(matching_line_doc, comments_doc))), body_doc));
              break;
          case "CaseExpr" :
              var match$3 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, c) {
                      return c[/* name */0] === "CaseBranch";
                    }), children);
              var match$4 = extract_comments(match$3[0]);
              var case_line_doc = straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$4[0]);
              var case_line_comments_doc = follow_up(depth - 1 | 0, match$4[1]);
              var partial_arg$3 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  false,
                  " ",
                  true
                ]);
              var partial_arg$4 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  true
                ]);
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(case_line_doc, case_line_comments_doc)), Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                          return lang_doc(partial_arg$3, depth, param);
                        }), (function (param) {
                          return lang_doc(partial_arg$4, depth, param);
                        }), Plate$BwaxAdmin.identity, match$3[1]));
              break;
          case "IfExpr" :
              var match$5 = Pretty_printer_common$BwaxAdmin.collect_elements_until((function (param, c) {
                      return c[/* name */0] === "then";
                    }), children);
              var match$6 = extract_comments(match$5[0]);
              var handle_element = function (node) {
                if (node[/* name */0] === "then" || node[/* name */0] === "else") {
                  var partial_arg = /* record */Caml_chrome_debugger.record([
                      "start_with_newline_if_necessary",
                      "preceeding_break",
                      "preserve_trailing_empty_lines"
                    ], [
                      true,
                      " ",
                      false
                    ]);
                  return (function (param, param$1) {
                              return lang_doc(partial_arg, param, param$1);
                            })(depth, node);
                } else {
                  var partial_arg$1 = /* record */Caml_chrome_debugger.record([
                      "start_with_newline_if_necessary",
                      "preceeding_break",
                      "preserve_trailing_empty_lines"
                    ], [
                      true,
                      " ",
                      false
                    ]);
                  return (function (param, param$1) {
                              return lang_doc(partial_arg$1, param, param$1);
                            })(depth + 1 | 0, node);
                }
              };
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$6[0]), Pretty_printer$BwaxAdmin.$caret$caret(follow_up(depth, match$6[1]), Pretty_printer_common$BwaxAdmin.pile_up(handle_element, handle_element, Plate$BwaxAdmin.identity, match$5[1])));
              break;
          case "Lambda" :
              var match$7 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                      return n[/* src */1] === "->";
                    }), children);
              var match$8 = extract_comments(match$7[0]);
              var partial_arg$5 = depth + 2 | 0;
              var partial_arg$6 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              var patterns_doc = Pretty_printer_common$BwaxAdmin.pile_up((function (n) {
                      return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text("\\"), Curry._2(doc_at_same_line, depth, n));
                    }), (function (param) {
                      var param$1 = partial_arg$5;
                      var param$2 = param;
                      return lang_doc(partial_arg$6, param$1, param$2);
                    }), Pretty_printer$BwaxAdmin.group, match$8[0]);
              var comments_after_patterns_doc = follow_up(depth + 1 | 0, match$8[1]);
              var match$9 = Pretty_printer_common$BwaxAdmin.collect_elements_until((function (param, n) {
                      return n[/* name */0] === "->";
                    }), match$7[1]);
              var partial_arg$7 = depth + 1 | 0;
              var partial_arg$8 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              var partial_arg$9 = depth + 1 | 0;
              var partial_arg$10 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              var arrow_sign_doc = Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                      var param$1 = partial_arg$7;
                      var param$2 = param;
                      return lang_doc(partial_arg$8, param$1, param$2);
                    }), (function (param) {
                      var param$1 = partial_arg$9;
                      var param$2 = param;
                      return lang_doc(partial_arg$10, param$1, param$2);
                    }), Plate$BwaxAdmin.identity, match$9[0]);
              var comments_and_expr_doc = follow_up(depth, match$9[1]);
              unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(patterns_doc, Pretty_printer$BwaxAdmin.$caret$caret(comments_after_patterns_doc, arrow_sign_doc))), comments_and_expr_doc));
              break;
          case "LetExpr" :
              var match$10 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                      if (n[/* src */1] === ",") {
                        return true;
                      } else {
                        return n[/* src */1] === "in";
                      }
                    }), children);
              var let_and_first_def = match$10[0];
              var match$11;
              if (let_and_first_def) {
                var partial_arg$11 = depth + 1 | 0;
                var partial_arg$12 = /* record */Caml_chrome_debugger.record([
                    "start_with_newline_if_necessary",
                    "preceeding_break",
                    "preserve_trailing_empty_lines"
                  ], [
                    true,
                    " ",
                    false
                  ]);
                match$11 = /* tuple */[
                  Pretty_printer$BwaxAdmin.text(let_and_first_def[0][/* src */1]),
                  Pretty_printer_common$BwaxAdmin.pile_up(Curry._1(doc_at_same_line, depth + 1 | 0), (function (param) {
                          var param$1 = partial_arg$11;
                          var param$2 = param;
                          return lang_doc(partial_arg$12, param$1, param$2);
                        }), Plate$BwaxAdmin.identity, let_and_first_def[1])
                ];
              } else {
                match$11 = /* tuple */[
                  Pretty_printer$BwaxAdmin.empty,
                  Pretty_printer$BwaxAdmin.empty
                ];
              }
              var first_def_and_comments_doc = match$11[1];
              var match$12 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                      return n[/* src */1] === "in";
                    }), match$10[1]);
              var match$13 = collect_pairs_and_comments(match$12[0]);
              var pairs = match$13[0];
              var defs_doc = Plate$BwaxAdmin.List.length(pairs) > 0 ? Pretty_printer$BwaxAdmin.group(join_pairs(undefined, (function (n) {
                            return Pretty_printer$BwaxAdmin.$caret$caret(first_def_and_comments_doc, Pretty_printer_common$BwaxAdmin.indent_with("", depth, n));
                          }), depth, pairs)) : first_def_and_comments_doc;
              var partial_arg$13 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              var partial_arg$14 = depth + 1 | 0;
              var partial_arg$15 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(match$11[0], Pretty_printer$BwaxAdmin.$caret$pipe(Pretty_printer_common$BwaxAdmin.indent(depth + 1 | 0, defs_doc), Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                              var param$1 = depth;
                              var param$2 = param;
                              return lang_doc(partial_arg$13, param$1, param$2);
                            }), (function (param) {
                              var param$1 = partial_arg$14;
                              var param$2 = param;
                              return lang_doc(partial_arg$15, param$1, param$2);
                            }), Plate$BwaxAdmin.identity, match$12[1])));
              break;
          case "ListRange" :
              var match$14 = extract_comments(children);
              var actual = match$14[0];
              var opening = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text("["), Pretty_printer$BwaxAdmin.delimit(" ", ""));
              var doc;
              if (actual) {
                var match$15 = actual[1];
                if (match$15) {
                  var match$16 = match$15[1];
                  if (match$16) {
                    var match$17 = match$16[1];
                    if (match$17) {
                      var match$18 = match$17[1];
                      var range_start = match$17[0];
                      var range_start$1 = match$15[0];
                      if (match$18) {
                        var match$19 = match$18[1];
                        if (match$19) {
                          var match$20 = match$19[1];
                          if (match$20) {
                            if (match$20[1]) {
                              doc = Pretty_printer$BwaxAdmin.text(src);
                            } else {
                              var partial_arg$16 = /* record */Caml_chrome_debugger.record([
                                  "start_with_newline_if_necessary",
                                  "preceeding_break",
                                  "preserve_trailing_empty_lines"
                                ], [
                                  true,
                                  "",
                                  false
                                ]);
                              doc = Pretty_printer$BwaxAdmin.$caret$caret(opening, Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, range_start$1), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer_common$BwaxAdmin.indent_with("", depth, Pretty_printer$BwaxAdmin.text(",")), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, range_start), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(".."), (function (param, param$1) {
                                                                    return lang_doc(partial_arg$16, param, param$1);
                                                                  })(depth + 2 | 0, match$19[0])))), Pretty_printer_common$BwaxAdmin.indent_with("", depth, Pretty_printer$BwaxAdmin.text("]")))))));
                            }
                          } else {
                            var partial_arg$17 = /* record */Caml_chrome_debugger.record([
                                "start_with_newline_if_necessary",
                                "preceeding_break",
                                "preserve_trailing_empty_lines"
                              ], [
                                true,
                                "",
                                false
                              ]);
                            doc = Pretty_printer$BwaxAdmin.$caret$caret(opening, Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, range_start$1), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer_common$BwaxAdmin.indent_with("", depth, Pretty_printer$BwaxAdmin.text(",")), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth, range_start), (function (param, param$1) {
                                                              return lang_doc(partial_arg$17, param, param$1);
                                                            })(depth + 2 | 0, match$18[0]))), Pretty_printer_common$BwaxAdmin.indent_with("", depth, Pretty_printer$BwaxAdmin.text("]")))))));
                          }
                        } else {
                          var partial_arg$18 = /* record */Caml_chrome_debugger.record([
                              "start_with_newline_if_necessary",
                              "preceeding_break",
                              "preserve_trailing_empty_lines"
                            ], [
                              true,
                              "",
                              false
                            ]);
                          doc = Pretty_printer$BwaxAdmin.$caret$caret(opening, Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth + 1 | 0, range_start$1), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(".."), Pretty_printer$BwaxAdmin.$caret$caret((function (param, param$1) {
                                                return lang_doc(partial_arg$18, param, param$1);
                                              })(depth + 2 | 0, range_start), Pretty_printer_common$BwaxAdmin.indent_with("", depth, Pretty_printer$BwaxAdmin.text("]"))))));
                        }
                      } else {
                        var partial_arg$19 = /* record */Caml_chrome_debugger.record([
                            "start_with_newline_if_necessary",
                            "preceeding_break",
                            "preserve_trailing_empty_lines"
                          ], [
                            true,
                            "",
                            false
                          ]);
                        doc = Pretty_printer$BwaxAdmin.$caret$caret(opening, Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth, range_start$1), Pretty_printer$BwaxAdmin.$caret$caret((function (param, param$1) {
                                          return lang_doc(partial_arg$19, param, param$1);
                                        })(depth + 2 | 0, match$16[0]), Pretty_printer_common$BwaxAdmin.indent_with("", depth, Pretty_printer$BwaxAdmin.text("]")))));
                      }
                    } else {
                      doc = Pretty_printer$BwaxAdmin.text(src);
                    }
                  } else {
                    doc = Pretty_printer$BwaxAdmin.text(src);
                  }
                } else {
                  doc = Pretty_printer$BwaxAdmin.text(src);
                }
              } else {
                doc = Pretty_printer$BwaxAdmin.text(src);
              }
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(doc), follow_up(depth - 1 | 0, match$14[1]));
              break;
          case "LogicNotOp" :
              var match$21 = extract_comments(children);
              unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$21[0]), follow_up(depth, match$21[1])));
              break;
          case "PatternConstructor" :
              var match$22 = extract_comments(children);
              var comment_nodes = match$22[1];
              if (Plate$BwaxAdmin.List.length(comment_nodes) > 0) {
                console.log("warning: comment is ignored", Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (n) {
                                return n[/* src */1];
                              }), comment_nodes)));
              }
              unpruned = straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$22[0]);
              break;
          case "AddOp" :
          case "AppendOp" :
          case "CompFromOp" :
          case "CompToOp" :
          case "CompareOp" :
          case "ConsOp" :
          case "EqualityOp" :
          case "LogicOp" :
          case "MonadBindOp" :
          case "MonadNextOp" :
          case "MulOp" :
          case "PipeFromOp" :
          case "PipeToOp" :
          case "PowOp" :
              unpruned = handle_left_associative_binop(depth, children);
              break;
          case "GetterExpr" :
          case "ImportExposing" :
          case "ImportExposingAll" :
          case "ImportName" :
          case "QualRef" :
              unpruned = Pretty_printer$BwaxAdmin.text(src);
              break;
          case "FieldTypeDecl" :
          case "RecBind" :
              exit = 5;
              break;
          case "RecordGet" :
              var match$23 = extract_comments(children);
              var exprs_doc = straight_up_line(Pretty_printer$BwaxAdmin.$caret$caret, Plate$BwaxAdmin.identity, depth, match$23[0]);
              var comments_doc$1 = follow_up(depth + 1 | 0, match$23[1]);
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(exprs_doc, comments_doc$1);
              break;
          case "RecordGetOptional" :
              var match$24 = extract_comments(children);
              var exprs_doc$1 = straight_up_line(Pretty_printer$BwaxAdmin.$caret$caret, Plate$BwaxAdmin.identity, depth, match$24[0]);
              var comments_doc$2 = follow_up(depth + 1 | 0, match$24[1]);
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(exprs_doc$1, comments_doc$2);
              break;
          case "List" :
          case "ListComprehension" :
          case "PatternTuple" :
          case "Record" :
          case "RecordExtTypeDecl" :
          case "RecordTypeDecl" :
          case "RecordUpdate" :
          case "Tuple" :
          case "TupleTypeDecl" :
              unpruned = handle_pairs(depth, children);
              break;
          case "Bracket" :
          case "TypeDeclBracket" :
              exit = 3;
              break;
          case "FunctionDef" :
          case "ValueDef" :
              exit = 4;
              break;
          case "ValueAssignment" :
          case "ValueExtract" :
              exit = 1;
              break;
          case "DoExpr" :
          case "WoRecordList" :
              exit = 2;
              break;
          default:
            if (children[1]) {
              switch (name) {
                case "File" :
                    var process_children = function (_acc, _maybe_prev_node, _list) {
                      while(true) {
                        var list = _list;
                        var acc = _acc;
                        var partial_arg = /* record */Caml_chrome_debugger.record([
                            "start_with_newline_if_necessary",
                            "preceeding_break",
                            "preserve_trailing_empty_lines"
                          ], [
                            true,
                            " ",
                            true
                          ]);
                        var next_doc = (function(partial_arg){
                        return function next_doc(param, param$1) {
                          return lang_doc(partial_arg, param, param$1);
                        }
                        }(partial_arg));
                        if (list) {
                          var tail = list[1];
                          var first = list[0];
                          if (tail) {
                            var second = tail[0];
                            var new_doc = first[/* name */0] === "LineComment" && second[/* name */0] === "Semicolon" ? Curry._2(next_doc, depth + 1 | 0, first) : Curry._2(next_doc, depth, first);
                            var new_acc = Pretty_printer$BwaxAdmin.$caret$caret(acc, new_doc);
                            _list = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                second,
                                tail[1]
                              ]);
                            _maybe_prev_node = first;
                            _acc = new_acc;
                            continue ;
                          } else {
                            var new_doc$1 = Curry._2(next_doc, depth, first);
                            var new_acc$1 = Pretty_printer$BwaxAdmin.$caret$caret(acc, new_doc$1);
                            _list = tail;
                            _maybe_prev_node = first;
                            _acc = new_acc$1;
                            continue ;
                          }
                        } else {
                          return acc;
                        }
                      };
                    };
                    unpruned = children ? process_children(lang_doc(/* record */Caml_chrome_debugger.record([
                                  "start_with_newline_if_necessary",
                                  "preceeding_break",
                                  "preserve_trailing_empty_lines"
                                ], [
                                  option[/* start_with_newline_if_necessary */0],
                                  option[/* preceeding_break */1],
                                  true
                                ]), depth, children[0]), undefined, children[1]) : Pretty_printer$BwaxAdmin.empty;
                    break;
                case "Constr0TypeDecl" :
                case "ConstrTypeDecl" :
                case "RoRecordTypeDecl" :
                case "RoVarTypeDecl" :
                    exit = 6;
                    break;
                case "TypeAliasDef" :
                    var match$25 = Pretty_printer_common$BwaxAdmin.collect_elements_until((function (param, n) {
                            return n[/* src */1] === "=";
                          }), children);
                    var match$26 = extract_comments(match$25[0]);
                    var name_and_eq_doc = straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$26[0]);
                    var comments_doc$3 = follow_up(depth, match$26[1]);
                    unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(name_and_eq_doc, Pretty_printer$BwaxAdmin.$caret$caret(comments_doc$3, follow_up(depth, match$25[1]))));
                    break;
                case "TypeAnnot" :
                    var match$27 = extract_comments(children);
                    var actual$1 = match$27[0];
                    unpruned = actual$1 ? Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(": "), Pretty_printer$BwaxAdmin.$caret$caret(start_up(depth, actual$1[1]), follow_up(depth, match$27[1]))) : Pretty_printer$BwaxAdmin.empty;
                    break;
                case "TypeAnnotExpr" :
                    var match$28 = extract_comments(children);
                    var decl_doc = start_up(depth - 1 | 0, match$28[0]);
                    var comments_doc$4 = follow_up(depth - 2 | 0, match$28[1]);
                    unpruned = Pretty_printer$BwaxAdmin.$caret$caret(decl_doc, comments_doc$4);
                    break;
                case "TypeDecl" :
                    var exit$1 = 0;
                    if (children && !children[1]) {
                      unpruned = Curry._2(doc_at_same_line, depth, children[0]);
                    } else {
                      exit$1 = 7;
                    }
                    if (exit$1 === 7) {
                      var match$29 = seperate_binop_ops(children);
                      var op_and_right = match$29[1];
                      var left_and_comments = match$29[0];
                      var left_and_comments_doc = left_and_comments ? Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth, left_and_comments[0]), follow_up(depth + 1 | 0, left_and_comments[1]))) : Pretty_printer$BwaxAdmin.empty;
                      var is_arrow_decl = function (node) {
                        if (node[/* name */0] === "TypeDecl") {
                          return Plate$BwaxAdmin.List.length(node[/* children */5]) > 1;
                        } else {
                          return false;
                        }
                      };
                      var op_and_right_doc;
                      if (op_and_right) {
                        var match$30 = op_and_right[1];
                        if (match$30 && !match$30[1]) {
                          var right = match$30[0];
                          var right_depth = is_arrow_decl(right) ? depth : depth + 2 | 0;
                          op_and_right_doc = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Curry._2(doc_at_same_line, depth, op_and_right[0]), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), Curry._2(doc_at_same_line, right_depth, right))));
                        } else {
                          op_and_right_doc = Pretty_printer$BwaxAdmin.empty;
                        }
                      } else {
                        op_and_right_doc = Pretty_printer$BwaxAdmin.empty;
                      }
                      unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(left_and_comments_doc, Pretty_printer_common$BwaxAdmin.indent(depth, Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(op_and_right_doc), follow_up(depth + 3 | 0, match$29[2]))))));
                    }
                    break;
                case "TypeDef" :
                    var match$31 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                            return n[/* src */1] === "|";
                          }), children);
                    var match$32 = extract_comments(match$31[0]);
                    var name_and_eq_doc$1 = straight_up_line(undefined, Plate$BwaxAdmin.identity, depth, match$32[0]);
                    var comments_doc$5 = follow_up(depth, match$32[1]);
                    var match$33 = collect_pairs_and_comments(match$31[1]);
                    var partial_arg$20 = depth + 1 | 0;
                    var partial_arg$21 = function (param) {
                      return Pretty_printer_common$BwaxAdmin.indent(partial_arg$20, param);
                    };
                    unpruned = Pretty_printer$BwaxAdmin.$caret$caret(name_and_eq_doc$1, Pretty_printer$BwaxAdmin.$caret$caret(comments_doc$5, join_pairs(undefined, (function (param) {
                                    return Plate$BwaxAdmin.$at$great(Pretty_printer$BwaxAdmin.group, partial_arg$21, param);
                                  }), depth + 1 | 0, match$33[0])));
                    break;
                case "UnitTypeDecl" :
                    var match$34 = extract_comments(children);
                    var comments_doc$6 = follow_up(depth - 1 | 0, match$34[1]);
                    unpruned = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text("()"), comments_doc$6);
                    break;
                case "WoOptionalRecordTypeDecl" :
                    var match$35 = extract_comments(children);
                    var decl = match$35[0];
                    var tmp;
                    if (decl) {
                      var match$36 = decl[1];
                      tmp = match$36 ? Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text("~~"), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), start_up(depth, match$36[1]))) : Pretty_printer$BwaxAdmin.empty;
                    } else {
                      tmp = Pretty_printer$BwaxAdmin.empty;
                    }
                    unpruned = Pretty_printer$BwaxAdmin.$caret$caret(tmp, follow_up(depth, match$35[1]));
                    break;
                case "WoRecordTypeDecl" :
                    var match$37 = extract_comments(children);
                    var decl$1 = match$37[0];
                    unpruned = Pretty_printer$BwaxAdmin.$caret$caret(decl$1 ? Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text("~"), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), start_up(depth, decl$1[1]))) : Pretty_printer$BwaxAdmin.empty, follow_up(depth, match$37[1]));
                    break;
                default:
                  unpruned = Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.List.reduce((function (acc, c) {
                              return Pretty_printer$BwaxAdmin.$caret$caret(acc, Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.must_break, c));
                            }), Plate$BwaxAdmin.identity, Plate$BwaxAdmin.List.indexed_map((function (i, c) {
                                  if (i === 0) {
                                    return lang_doc(option, depth, c);
                                  } else {
                                    var partial_arg = /* record */Caml_chrome_debugger.record([
                                        "start_with_newline_if_necessary",
                                        "preceeding_break",
                                        "preserve_trailing_empty_lines"
                                      ], [
                                        true,
                                        " ",
                                        false
                                      ]);
                                    return (function (param, param$1) {
                                                return lang_doc(partial_arg, param, param$1);
                                              })(depth, c);
                                  }
                                }), children)));
              }
            } else {
              unpruned = Curry._2(doc_at_same_line, depth, children[0]);
            }
        }
      } else {
        unpruned = Pretty_printer$BwaxAdmin.text(src);
      }
  }
  switch (exit) {
    case 1 :
        var match$38 = extract_comments(children);
        var actual$2 = match$38[0];
        var doc$1;
        if (actual$2) {
          var match$39 = actual$2[1];
          if (match$39) {
            var match$40 = match$39[1];
            if (match$40 && !match$40[1]) {
              var partial_arg$22 = /* record */Caml_chrome_debugger.record([
                  "start_with_newline_if_necessary",
                  "preceeding_break",
                  "preserve_trailing_empty_lines"
                ], [
                  true,
                  " ",
                  false
                ]);
              doc$1 = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(actual$2[0][/* src */1]), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(match$39[0][/* src */1]), (function (param, param$1) {
                                return lang_doc(partial_arg$22, param, param$1);
                              })(depth + 1 | 0, match$40[0]))));
            } else {
              doc$1 = Pretty_printer$BwaxAdmin.text(src);
            }
          } else {
            doc$1 = Pretty_printer$BwaxAdmin.text(src);
          }
        } else {
          doc$1 = Pretty_printer$BwaxAdmin.text(src);
        }
        unpruned = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(doc$1), follow_up(depth - 1 | 0, match$38[1]));
        break;
    case 2 :
        if (children) {
          var op = children[0];
          var match$41 = collect_pairs_and_comments(children[1]);
          var maybe_dangling_start_symbol = match$41[2];
          var maybe_end_symbol = match$41[1];
          var ending = Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map((function (n) {
                      return Pretty_printer_common$BwaxAdmin.indent(depth, Pretty_printer$BwaxAdmin.text(n[/* name */0]));
                    }), maybe_end_symbol));
          var simple_ending = Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map((function (n) {
                      return Pretty_printer$BwaxAdmin.text(n[/* name */0]);
                    }), maybe_end_symbol));
          var v;
          if (maybe_dangling_start_symbol !== undefined) {
            var match$42 = maybe_dangling_start_symbol;
            v = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(match$42[0][/* name */0]), Pretty_printer$BwaxAdmin.$caret$caret(simple_ending, follow_up(depth - 1 | 0, match$42[1])));
          } else {
            v = join_pairs(op[/* src */1] === "do" ? undefined : Pretty_printer$BwaxAdmin.delimit("", " "), (function (d) {
                    return Pretty_printer$BwaxAdmin.$caret$caret(d, ending);
                  }), depth, match$41[0]);
          }
          var s = op[/* src */1];
          unpruned = s === "do" ? Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(s), Pretty_printer_common$BwaxAdmin.indent_with(" ", depth, v)) : Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(s), v);
        } else {
          unpruned = Pretty_printer$BwaxAdmin.empty;
        }
        break;
    case 3 :
        var match$43 = extract_comments(children);
        var comments = match$43[1];
        var actual$3 = match$43[0];
        var should_drill_down_to;
        if (actual$3) {
          var match$44 = actual$3[1];
          if (match$44) {
            var match$45 = match$44[1];
            if (match$45 && !(match$45[1] || comments)) {
              var h = match$44[0];
              var match$46 = h[/* name */0];
              switch (match$46) {
                case "BinOpExpr" :
                case "Bracket" :
                case "Constr0TypeDecl" :
                case "ConstructorExpr" :
                case "DoExpr" :
                case "External" :
                case "GetterExpr" :
                case "List" :
                case "ListComprehension" :
                case "ListRange" :
                case "Literal" :
                case "OpaquedTypeDecl" :
                case "QualRef" :
                case "Record" :
                case "RecordExtTypeDecl" :
                case "RecordGet" :
                case "RecordGetOptional" :
                case "RecordTypeDecl" :
                case "RecordUpdate" :
                case "Ref" :
                case "RoRecordTypeDecl" :
                case "Tuple" :
                case "TupleTypeDecl" :
                case "TypeDeclBracket" :
                case "Unit" :
                case "UnitTypeDecl" :
                case "VarTypeDecl" :
                case "WoOptionalRecordTypeDecl" :
                case "WoRecordList" :
                case "WoRecordTypeDecl" :
                    should_drill_down_to = h;
                    break;
                default:
                  should_drill_down_to = undefined;
              }
            } else {
              should_drill_down_to = undefined;
            }
          } else {
            should_drill_down_to = undefined;
          }
        } else {
          should_drill_down_to = undefined;
        }
        if (should_drill_down_to !== undefined) {
          unpruned = lang_doc(option, depth, should_drill_down_to);
        } else {
          var $$process = function (first_one, list) {
            if (list) {
              var match = list[1];
              var symbol = list[0];
              if (match) {
                return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(symbol[/* name */0]), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", first_one ? "" : undefined), Curry._2(doc_at_same_line, depth + 1 | 0, match[0]))), Pretty_printer_common$BwaxAdmin.indent_with("", depth, $$process(false, match[1])));
              } else {
                return Pretty_printer$BwaxAdmin.text(symbol[/* name */0]);
              }
            } else {
              return Pretty_printer$BwaxAdmin.empty;
            }
          };
          unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group($$process(true, actual$3)), follow_up(depth, comments)));
        }
        break;
    case 4 :
        var match$47 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                if (n[/* name */0] === "TypeAnnot") {
                  return true;
                } else {
                  return n[/* name */0] === "=";
                }
              }), children);
        var match$48 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                return n[/* name */0] === "=";
              }), match$47[1]);
        var match$49 = Pretty_printer_common$BwaxAdmin.collect_elements_until((function (param, n) {
                return n[/* name */0] === "=";
              }), match$48[1]);
        var match$50 = extract_comments(match$47[0]);
        var partial_arg$23 = depth + 2 | 0;
        var partial_arg$24 = /* record */Caml_chrome_debugger.record([
            "start_with_newline_if_necessary",
            "preceeding_break",
            "preserve_trailing_empty_lines"
          ], [
            true,
            " ",
            false
          ]);
        var name_and_patterns_doc = Pretty_printer_common$BwaxAdmin.pile_up(Curry._1(doc_at_same_line, depth), (function (param) {
                var param$1 = partial_arg$23;
                var param$2 = param;
                return lang_doc(partial_arg$24, param$1, param$2);
              }), Pretty_printer$BwaxAdmin.group, match$50[0]);
        var comments_after_patterns_doc$1 = follow_up(depth + 1 | 0, match$50[1]);
        var partial_arg$25 = depth + 2 | 0;
        var partial_arg$26 = /* record */Caml_chrome_debugger.record([
            "start_with_newline_if_necessary",
            "preceeding_break",
            "preserve_trailing_empty_lines"
          ], [
            true,
            " ",
            false
          ]);
        var partial_arg$27 = depth + 2 | 0;
        var partial_arg$28 = function (param) {
          return Pretty_printer_common$BwaxAdmin.indent_with(" ", partial_arg$27, param);
        };
        var annotations_doc = Pretty_printer_common$BwaxAdmin.pile_up(Curry._1(doc_at_same_line, depth + 1 | 0), (function (param) {
                var param$1 = partial_arg$25;
                var param$2 = param;
                return lang_doc(partial_arg$26, param$1, param$2);
              }), (function (param) {
                return Plate$BwaxAdmin.$at$great(Pretty_printer$BwaxAdmin.group, partial_arg$28, param);
              }), match$48[0]);
        var top_level = Caml_obj.caml_equal(lang_tree[/* path */7], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "File",
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "Def",
                    /* [] */0
                  ])
              ]));
        var partial_arg$29 = depth + 1 | 0;
        var partial_arg$30 = /* record */Caml_chrome_debugger.record([
            "start_with_newline_if_necessary",
            "preceeding_break",
            "preserve_trailing_empty_lines"
          ], [
            true,
            " ",
            false
          ]);
        var handle_equal_sign = function (param) {
          var param$1 = partial_arg$29;
          var param$2 = param;
          return lang_doc(partial_arg$30, param$1, param$2);
        };
        var equal_sign_doc = Pretty_printer_common$BwaxAdmin.pile_up(handle_equal_sign, handle_equal_sign, Plate$BwaxAdmin.identity, match$49[0]);
        var comments_and_expr_doc$1 = follow_up(depth, match$49[1]);
        var append_expr = function (prev_doc) {
          if (top_level) {
            return Pretty_printer$BwaxAdmin.$caret$caret(prev_doc, Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.must_break, comments_and_expr_doc$1));
          } else {
            return Pretty_printer$BwaxAdmin.$caret$caret(prev_doc, comments_and_expr_doc$1);
          }
        };
        unpruned = Pretty_printer$BwaxAdmin.group(append_expr(Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(name_and_patterns_doc, Pretty_printer$BwaxAdmin.$caret$caret(comments_after_patterns_doc$1, Pretty_printer$BwaxAdmin.$caret$caret(annotations_doc, equal_sign_doc))))));
        break;
    case 5 :
        var match$51 = extract_comments(children);
        var comments$1 = match$51[1];
        var exprs = match$51[0];
        var join_assignment = function (name, assign_symbol, expr) {
          var name_eq_doc = assign_symbol[/* src */1] === ":" ? Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(name[/* src */1]), Pretty_printer$BwaxAdmin.text(assign_symbol[/* src */1])) : Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(name[/* src */1]), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(" "), Pretty_printer$BwaxAdmin.text(assign_symbol[/* src */1])));
          var partial_arg = /* record */Caml_chrome_debugger.record([
              "start_with_newline_if_necessary",
              "preceeding_break",
              "preserve_trailing_empty_lines"
            ], [
              true,
              " ",
              false
            ]);
          var expr_doc = (function (param, param$1) {
                return lang_doc(partial_arg, param, param$1);
              })(depth + 1 | 0, expr);
          if (Plate$BwaxAdmin.List.length(comments$1) === 0) {
            return Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(name_eq_doc, expr_doc));
          } else {
            return Pretty_printer$BwaxAdmin.$caret$caret(name_eq_doc, Pretty_printer$BwaxAdmin.$caret$caret(follow_up(depth, comments$1), expr_doc));
          }
        };
        if (exprs) {
          var match$52 = exprs[1];
          var name$1 = exprs[0];
          if (match$52) {
            var match$53 = match$52[1];
            if (match$53) {
              var match$54 = match$53[1];
              var expr = match$53[0];
              var assign_symbol = match$52[0];
              unpruned = match$54 ? (
                  match$54[1] ? Pretty_printer$BwaxAdmin.empty : Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$pipe(join_assignment(name$1, assign_symbol, expr), Pretty_printer$BwaxAdmin.text(match$54[0][/* src */1])))
                ) : Pretty_printer$BwaxAdmin.group(join_assignment(name$1, assign_symbol, expr));
            } else {
              unpruned = Pretty_printer$BwaxAdmin.empty;
            }
          } else {
            unpruned = Pretty_printer$BwaxAdmin.text(name$1[/* src */1]);
          }
        } else {
          unpruned = Pretty_printer$BwaxAdmin.empty;
        }
        break;
    case 6 :
        var match$55 = extract_comments(children);
        var decl_doc$1 = start_up(depth, match$55[0]);
        var comments_doc$7 = follow_up(depth - 1 | 0, match$55[1]);
        unpruned = Pretty_printer$BwaxAdmin.$caret$caret(decl_doc$1, comments_doc$7);
        break;
    
  }
  var doc$2 = Pretty_printer$BwaxAdmin.prune_nil(unpruned);
  var is_nest = function (_param) {
    while(true) {
      var param = _param;
      if (typeof param === "number") {
        return false;
      } else {
        switch (param.tag | 0) {
          case /* Doc_cons */0 :
              _param = param[0];
              continue ;
          case /* Doc_nest */2 :
              return true;
          default:
            return false;
        }
      }
    };
  };
  var added_starting;
  var exit$2 = 0;
  if (typeof doc$2 === "number" && doc$2 === 0) {
    added_starting = doc$2;
  } else {
    exit$2 = 1;
  }
  if (exit$2 === 1) {
    added_starting = depth > 0 && option[/* start_with_newline_if_necessary */0] && !is_nest(doc$2) && !Pretty_printer$BwaxAdmin.start_with_must_break(doc$2) ? Pretty_printer_common$BwaxAdmin.indent_with(option[/* preceeding_break */1], depth, doc$2) : doc$2;
  }
  var should_preserve = option[/* preserve_trailing_empty_lines */2];
  var is_line_comment = lang_tree[/* name */0] === "LineComment";
  var is_semicolon = lang_tree[/* name */0] === "Semicolon";
  if (should_preserve || is_line_comment) {
    var count = Pretty_printer_common$BwaxAdmin.count_trailing_linebreaks(lang_tree[/* to_ */3] - 1 | 0, lang_tree[/* code_text */4]);
    if (count > 1) {
      var _count = is_line_comment && !should_preserve ? count - 1 | 0 : count;
      var _acc = Pretty_printer$BwaxAdmin.$caret$caret(added_starting, Pretty_printer$BwaxAdmin.must_break);
      while(true) {
        var acc = _acc;
        var count$1 = _count;
        if (count$1 <= 0) {
          return acc;
        } else {
          _acc = Pretty_printer$BwaxAdmin.$caret$caret(acc, Pretty_printer$BwaxAdmin.nest((depth << 1), Pretty_printer$BwaxAdmin.$$break));
          _count = count$1 - 1 | 0;
          continue ;
        }
      };
    } else if (count === 1 || count === 0 && is_semicolon) {
      return Pretty_printer$BwaxAdmin.$caret$caret(added_starting, Pretty_printer$BwaxAdmin.must_break);
    } else {
      return added_starting;
    }
  } else {
    return added_starting;
  }
}

function follow_up(depth, child_nodes) {
  var partial_arg = depth + 1 | 0;
  var partial_arg$1 = depth + 1 | 0;
  var partial_arg$2 = Pretty_printer_common$BwaxAdmin.next_line_option(" ");
  var partial_arg$3 = depth + 1 | 0;
  var partial_arg$4 = function (param) {
    return Pretty_printer_common$BwaxAdmin.indent(partial_arg$3, param);
  };
  return Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                return lang_doc(Pretty_printer_common$BwaxAdmin.same_line_option, partial_arg, param);
              }), (function (param) {
                return lang_doc(partial_arg$2, partial_arg$1, param);
              }), (function (param) {
                return Plate$BwaxAdmin.$at$great(Pretty_printer$BwaxAdmin.group, partial_arg$4, param);
              }), child_nodes);
}

function start_up(depth, child_nodes) {
  var partial_arg = depth + 1 | 0;
  var partial_arg$1 = depth + 1 | 0;
  var partial_arg$2 = Pretty_printer_common$BwaxAdmin.next_line_option(" ");
  return Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                return lang_doc(Pretty_printer_common$BwaxAdmin.same_line_option, partial_arg, param);
              }), (function (param) {
                return lang_doc(partial_arg$2, partial_arg$1, param);
              }), Pretty_printer$BwaxAdmin.group, child_nodes);
}

function straight_up_line(maybe_join, process_result, depth, list) {
  return Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map(process_result, Plate$BwaxAdmin.$$Option.map(Pretty_printer$BwaxAdmin.group, Plate$BwaxAdmin.List.reduce((function (acc, c) {
                            var new_doc = lang_doc(Pretty_printer_common$BwaxAdmin.same_line_option, depth + 1 | 0, c);
                            if (maybe_join !== undefined) {
                              return Curry._2(maybe_join, acc, new_doc);
                            } else {
                              return Pretty_printer$BwaxAdmin.$caret$caret(acc, Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.delimit(" ", undefined), new_doc));
                            }
                          }), (function (head) {
                            return Pretty_printer$BwaxAdmin.text(head[/* src */1]);
                          }), list))));
}

var follow_up_comments = follow_up;

function pretty_print_lang(width, code_text, lang_tree_js) {
  var base_doc = lang_doc(Pretty_printer_common$BwaxAdmin.same_line_option, 0, Pretty_printer_common$BwaxAdmin.lang_tree_from_js(code_text, /* [] */0, lang_tree_js));
  return Pretty_printer$BwaxAdmin.pretty(width, base_doc);
}

function church_succ(n, s, z) {
  return Curry._1(s, Curry._2(n, s, z));
}

function f(a) {
  return a;
}

exports.extract_comments = extract_comments;
exports.collect_pairs_and_comments = collect_pairs_and_comments;
exports.seperate_binop_ops = seperate_binop_ops;
exports.lang_doc = lang_doc;
exports.start_up = start_up;
exports.follow_up = follow_up;
exports.follow_up_comments = follow_up_comments;
exports.straight_up_line = straight_up_line;
exports.pretty_print_lang = pretty_print_lang;
exports.church_succ = church_succ;
exports.f = f;
/* Plate-BwaxAdmin Not a pure module */
