import React, { useState, } from 'react'

import './Form.less'

import { Card } from 'antd'

import Tooltip from 'bwax-ui/components/Tooltip';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons"

import { deserializeContent } from 'bwax-ui/auxiliary/richtext/editor'


// condition resolver
// 暂时只支持 eq, ne, lt, gt, lte, gte,  条件值可以为 null
function resolveCondition(currentValue, conditionValue) {

    let compare = (target, op, v) => {
        let opFuncs = {
            "eq": v => {
                if (v === null || v === undefined) {
                    return target === null || target === undefined
                } else {
                    return target === v
                }
            },
            "ne": v => {
                if (v === null || v === undefined) {
                    return target !== null && target !== undefined
                } else {
                    return target !== v
                }
            },
            "gt": v => target > v,
            "gte": v => target >= v,
            "lte": v => target <= v,
            "lt": v => target < v,
        }

        let func = opFuncs[op]
        if (!func) {
            console.error("Unknown compare op", op);
            return opFuncs["eq"](v)
        } else {
            return func(v)
        }
    }

    function performCheck(op, v) {
        if (Array.isArray(currentValue)) {
            // TODO 可能也要考虑到本身数据类型就是 array 的情况
            return currentValue.some(target => compare(target, op, v));
        } else {
            return compare(currentValue, op, v);
        }
    }

    // 只要 conditionValue 是 object，就当做是有 op 的
    if (typeof (conditionValue) == 'object' && conditionValue !== null) {
        return Object.keys(conditionValue).every(op => {
            let v = conditionValue[op];
            return performCheck(op, v)
        })
    } else {
        return performCheck("eq", conditionValue)
    }
}



/*
// TODO 可能需要定义一些 classes / structs
Form -> Item { 
    name -> string (用于显示 和作为 key)
    display -> {
        /// 完整的 display 结构，见 basics/ValueDisplay.js
    }, 
    editable -> 是否能够编辑 (可以 on the fly 改吗？),
    input: <InputWidget>
}
*/

export default function Form(props) {

    const {
        params,
        editingState,
        renderWidget,
    } = props

    const {
        title,
        items,

        editableCondition,
        editable = true,
        onSave,
        onCancel,
        //// 
        layout = 'horizontal',
        hideLabel = false,
    } = params

    const { errors, validated, lastSaved, dirtyValues } = editingState

    /// process the items
    /// states start ...
    const [isEditing, setIsEditing] = useState(false);

    /// states ends ....

    const editFormClassName = "admin--form"

    const usedEditKeys = items.map(i => i.editKey).filter(x => x !== undefined)

    function checkItemEditable(name) {
        if (!editableCondition) {
            return true
        } else {
            // 有 editable 条件
            let condition = editableCondition[name];
            if (condition === true) {
                return true
            } else if (typeof (condition) == 'object' && condition !== null) {
                // it should be object of <other-field-name> <required-matched-value>
                return Object.keys(condition).every(cn => {
                    let currentValue = validated[cn];
                    let conditionValue = condition[cn];
                    return resolveCondition(currentValue, conditionValue);
                })
            }
            return false
        }

    }
    // 

    let someIsEditable = items.some(({ name, editable }) => {
        const isItemEditable = editable && checkItemEditable(name)
        return isItemEditable
    })


    function preprocess(item) {

        const { name, display, editable, input, tip, field, editKey } = item;



        /// 如果没有设置 display，则直接显示当前输入的数据
        const displayValue = display || validated[editKey]
        const error = errors[editKey]

        const isItemEditable =
            editable && checkItemEditable(name)


        function renderValue() {
            if (isEditing && isItemEditable && input) {
                /// 
                return (
                    <React.Fragment>
                        { input && input.$$typeof ? input : renderWidget(input)}
                        {
                            error ?
                                <p className="error-message-cot">
                                    {error}
                                </p> : null
                        }
                    </React.Fragment>
                )
            } else {
                return displayValue && displayValue.$$typeof ? displayValue : renderWidget(displayValue) 
            }
        }

        const labelBox = hideLabel ? null : (
            <React.Fragment>
                <label>{name}</label>
                {
                    tip ? (
                        <Tooltip text={tip}>
                            <InfoCircleOutlined style={{ marginLeft: 8, opacity: 0.7 }} />
                        </Tooltip>
                    ) : null
                }
            </React.Fragment>
        )

        const valueStyle = field && field.type.match(/RichText/) ? { overflowX: "visible" } : {};


        return { name, renderValue, valueStyle, labelBox };
    }

    function renderItem(item) {

        const { name, renderValue, valueStyle, labelBox } = preprocess(item);

        return (
            <div key={name} className={`form-item-${layout}`}>
                <div className="label-box">
                    {labelBox}
                </div>
                <div
                    className="value-box"
                    style={valueStyle} >
                    {renderValue()}
                </div>
            </div>
        )

    }

    function renderItems() {
        return items.map(renderItem)
    }

    function renderSingleRichText(item) {
        const { renderValue } = preprocess(item);
        return <div className="single-value rich-text">{renderValue()}</div>
    }

    function isOnlyOneRichText() {
        if (items.length == 1) {
            const firstItem = items[0];
            return firstItem.field.type == "RichText"
        } else {
            return false
        }
    }

    const className = 
        (isEditing ? `${editFormClassName} editing` : `${editFormClassName}`)
            + (isOnlyOneRichText() ? " full-height" : "")

    return (
        <Card onClick={e => { e.stopPropagation() }} title={title} hoverable={true} bordered={false}
            className={className}
            onKeyDown={e => {
                if (e.metaKey == true && e.key == "s") {
                    e.preventDefault();

                    const isDirty = Object.keys(dirtyValues).filter(
                        k => usedEditKeys.indexOf(k) !== -1
                    ).length > 0;
                    if (isDirty) {
                        onSave();
                    }
                }

            }}>
            {
                isOnlyOneRichText() ? renderSingleRichText(items[0]) : renderItems()
            }
            {
                someIsEditable ?
                    renderToolbar({
                        editable, isEditing,
                        errors, validated, lastSaved, dirtyValues,
                        usedEditKeys,
                        setIsEditing,
                        onSave,
                        onCancel,
                        items,
                    }) : null
            }
        </Card>
    )

}

function checkRichtextUploadingImg(dirtyValues, items) {

    const richtextKeys = Object.keys(dirtyValues).filter(
        k => items.some(
            i => i.editKey === k && i.field && i.field.type.match(/RichText/)
        )
    )

    if (richtextKeys.length === 0) {
        return false
    }

    const richtextDirtyValues = richtextKeys.map(k => deserializeContent(dirtyValues[k] || ''))

    function checkUpload(editorState) {
        const contentState = editorState.getCurrentContent()
        const blockMap = contentState.getBlockMap()
        return blockMap.some((v, k) => {
            return v.get('type') === 'atomic' && !!v.get('data') && !!v.get('data').get('uploading')
        })
    }

    return richtextDirtyValues.some(e => checkUpload(e))
}


function renderToolbar({
    usedEditKeys, editable, isEditing, errors, dirtyValues, setIsEditing, onSave, onCancel, items
}) {

    const isDirty = Object.keys(dirtyValues).filter(
        k => dirtyValues[k] !== undefined && usedEditKeys.indexOf(k) !== -1
    ).length > 0


    const isRichtextUploadingImg = checkRichtextUploadingImg(dirtyValues, items)

    const hasError = Object.keys(errors).some(e => !!errors[e])

    const confirmEditing = async () => {
        if (isDirty && !hasError && !isRichtextUploadingImg && onSave) {
            await onSave()
            setIsEditing(false)
        }
    }

    const cancelEdit = () => {
        if (onCancel) onCancel();
        setIsEditing(false)
    }

    return editable ?
        <div className="edit-btn-box" >
            {
                isEditing ?
                    <React.Fragment>
                        <div
                            onClick={confirmEditing}
                            className={`button confirm-button${isDirty && !hasError && !isRichtextUploadingImg ? "" : " disabled"}`}
                        >
                            <CheckOutlined />
                        </div>
                        <div key="cancel" className="button cancel-button" onClick={cancelEdit}
                        >
                            <CloseOutlined />
                        </div>
                    </React.Fragment> :
                    <div className="button edit-button" onClick={() => setIsEditing(true)}>
                        <EditOutlined />
                    </div>
            }
        </div> : null
}

Form.defaultProps = {
    editable: true,
    showEditIcon: true
}
