

import React, { useState } from 'react'

import './Tabs.less'

import UrlPattern from 'url-pattern'

import { Tabs as AntTabs, Menu, Dropdown } from 'antd'

const TabPane = AntTabs.TabPane;

//// The tabs tempates needs URL pattern with trailiing /:tab?
export default function Tabs(props) {
    const { params, renderWidget, match_, routeTo } = props

    const {
        tabs = [],
        addAction,
    } = params


    /// tab 要的是: 
    // { name: string, content: widgetDef, default: bool?, closeable: bool?, contextMenu: ?}

    const defaultTab = (tabs.find(t => t.default == true) || tabs[0] || {}).name
    const [activeTab, setActiveTab] = useState(defaultTab)

    // :tab? handling:
    const shouldUseLocationForTab = match_.path.endsWith('/:tab?')

    function getActiveTab() {
        if (shouldUseLocationForTab) {
            const { tab } = match_.params
            const hitted = tabs.find(t => t.name === tab)
            return hitted ? hitted.name : defaultTab
        }
        /// use "ActiveTab" in state
        return activeTab
    }
    function updateActiveTab(tab) {
        if (shouldUseLocationForTab) {
            const pattern = new UrlPattern(match_.path)
            const to = pattern.stringify({
                ...match_.params,
                tab
            })
            // setActiveTab(tab)
            routeTo(to, { modal: false })
        } else {
            setActiveTab(tab)
        }

    }

    function renderTab(name, content) {

        if (getActiveTab() !== name) {
            return null
        }

        return renderWidget(content);
    }

    function renderTabs() {

        return (
            <AntTabs type="editable-card" className={"admin--tabs"} activeKey={getActiveTab()}
                onEdit={(targetKey, action) => {
                    if (action === 'add') {
                        if (addAction) addAction()
                    } else {
                        console.warn("Un-implemented action", action)
                    }

                }}
                onChange={value => updateActiveTab(value)}
                hideAdd={addAction ? false : true}
                items={tabs.map(tab => {
                    const { name, contextMenu, content, closable = false, noPadding } = tab

                    function getNameBox() {
                        const className = 'tab-name-box no-select'
                        if (contextMenu) {
                            /// context menu is like 
                            // [ { title, onClick }]
                            const cm = Array.isArray(contextMenu) ? contextMenu : [contextMenu]
                            const menu = (
                                <Menu>
                                    {
                                        cm.map(({ title, onClick }, index) => {
                                            const doClick = e => {
                                                e.domEvent.preventDefault()
                                                e.domEvent.stopPropagation()
                                                if (onClick) {
                                                    onClick()
                                                }
                                            }
                                            return (
                                                <Menu.Item key={index} onClick={doClick}>
                                                    {title}
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </Menu>
                            )

                            return (
                                <Dropdown overlay={menu} trigger={['contextMenu']}>
                                    <div className={className}>
                                        {name}
                                    </div>
                                </Dropdown>
                            )
                        }
                        return (
                            <div className={className} onContextMenu={e => e.preventDefault()}>
                                {name}
                            </div>
                        )
                    }

                    return {
                        label: getNameBox(),
                        key: name,
                        closable,
                        children: renderTab(name, content)
                    }



                    // return (
                    //     <TabPane tab={getNameBox()} key={name} closable={closable === true}
                    //         style={{
                    //             // height: "96%",
                    //             overflow: "auto",
                    //             // display: 'flex',
                    //             // flexDirection: 'column',
                    //             ...(noPadding ? { padding: 0 } : {})
                    //         }}
                    //     >
                    //         {renderTab(name, content)}
                    //     </TabPane>
                    // )
                })}
            />
        )
    }

    return renderTabs(tabs)
}