
import React, { useRef, useEffect, useState } from 'react'

import { EditorState, EditorSelection } from "@codemirror/state";
import { EditorView, dropCursor, hoverTooltip } from "@codemirror/view";

import lang_parser_lezer from 'bwax/ml/lang/lang_parser_lezer.bs';

import './CodePlayground.less';
import './CodeMirrorEditor.less';

import './DefsCodeEditor.less';

import lang_entry from 'bwax/ml/lang/lang_entry.bs'
import lang_entry_base from 'bwax/ml/lang/lang_entry_base.bs'

import page_entry from 'bwax-ui/ml/page_entry.bs'

import { nextDiagnostic } from "@codemirror/lint"

import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';
import { packV4, tag, untag } from 'bwax/lang/LangHelper'

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

import {
    isInvolvingDynamicData,
    isInvolvingTargetRecord,
} from 'bwax/BwaxExecHelper';

import setupCodeEditor from './setupCodeEditor';

import CodeOutline from './CodeOutline';
import uniq from 'lodash/uniq';

import prettyPrintLang from 'bwax/ml/printer/prettyPrintLang';

import page_entry_slim from 'bwax-ui/ml/page_entry_slim.bs'

import pageRuntimeModules from 'bwax-ui/gen/page_runtime_modules.json';
import { Button } from '@arco-design/web-react';

import extractParseTree from 'bwax/ml/printer/extractParseTree';
import Message from '../ui/Message';

// import { Button } from 'rsuite';
// import backendRuntimeModules from 'bwax/gen/backend_runtime_modules.json';

const gmod = untag(pageRuntimeModules);
// const backendGmod = untag(backendRuntimeModules);

export default function CodePlayground(props) {

    const {
        targetEntityName,
        bindCommands,  // 
        cacheKey = ""
    } = props;

    const [sourceCode, setSourceCode] = useStateWithLocalCache("code-playground" + (cacheKey ? "-" + cacheKey : ""));

    const [packagedCode, setPackagedCode] = useState();

    // prepare the typing env 
    // [ tenv, dts, entity_dict, data_type_dict ] // 后面两个都是 0
    const [typingEnv, setTypingEnv] = useState();

    const [evalEnv, setEvalEnv] = useState();

    const [resultedTypingEnv, setResultedTypingEnv] = useState();

    const [error, setError] = useState();
    const markError = error => setError(error);

    const loadTypingEnv = async () => {

        // 如果 sandbox definitions 有更新的话

        console.log("Start preparation");
        const t0 = performance.now();

        // prepare base typing env
        const baseTypingEnv = lang_entry.prepare_base_typing_env();
        const t1 = performance.now();
        console.log("Preparation base env uses", t1 - t0);

        setTypingEnv([...baseTypingEnv, 0, 0])

        const t2_0 = performance.now();
        const pageTypingEnv = page_entry.prepare_ui_typing_env(baseTypingEnv);
        const t2 = performance.now();

        console.log("Preparation page env uses", t2 - t2_0);

        console.log(">>> page typing env", pageTypingEnv);

        setTypingEnv([...pageTypingEnv, 0, 0])
    }

    const loadEvalEnv = () => {
        let evalEnv = page_entry_slim.prepare_eval_env(
            gmod, 
            0, // this.entity_dict, 
            0, // this.data_type_dict, 
            [], // this.pageComponents || [], 
            [], // this.adminPages || []
        );
        setEvalEnv(evalEnv);
    }


    useEffect(() => {
        loadTypingEnv();
        loadEvalEnv();
    }, [])


    // 在 linter 里面访问：
    const typingEnvRef = useRef(typingEnv);
    typingEnvRef.current = typingEnv;

    const editorRef = useRef();
    const viewRef = useRef();

    const dropAtRef = useRef();

    // { tree, codeText }
    const [syntaxTree, setSyntaxTree] = useState();

    const [typedTree, setTypedTree] = useState();

    const [evalResult, setEvalResult] = useState();

    function updateCodeText(codeText) {
        if (viewRef.current && viewRef.current.state.doc) {
            const currentDoc = viewRef.current.state.doc;
            const docText = currentDoc.toJSON().join("\n");
            if (docText != codeText) {
                viewRef.current.dispatch({
                    changes: {
                        from: 0,
                        to: currentDoc.length,
                        insert: codeText,
                    }
                })
            }
        }
    }

    // given back
    if (bindCommands) {
        bindCommands({
            focus: () => {
                viewRef.current.focus();
            },

            jumpToError: () => nextDiagnostic(viewRef.current),
            // get specical code range:
            getSyntaxTree: () => {
                return syntaxTree
            },
            updateCodeText
        })
    }

    useEffect(() => {

        const [dropCursorPos, drawDropCursor] = dropCursor();

        const afterLinter = (codeText, tree) => {

            const typingEnv = typingEnvRef.current;
            const t0 = performance.now();
            // parse and type:
            const [result, parseError] = lang_parser_lezer.convert_defs_entry(codeText, tree);
            const t00 = performance.now();
            console.log("Conversion uses", t00 - t0);

            console.log(">>> tree ", extractParseTree(tree.topNode, codeText))

            setSourceCode(codeText);

            function handleError([message, startIndex, endIndex]) {
                const [from, to] = (() => {
                    if (startIndex == endIndex) {
                        if (startIndex > 0) {
                            return [startIndex - 1, endIndex]
                        } else if (endIndex < codeText.length) {
                            return [startIndex, endIndex + 1]
                        }
                    }
                    return [startIndex, endIndex]
                })();
                const errors = [{ message, from, to, severity: "error" }];
                return errors
            }

            if (parseError) {
                return handleError(parseError);

            } else if (typingEnv) {
                const [base_tenv, base_dts, entity_dict, data_type_dict] = typingEnv
                const [ast, _] = result;

                const t1 = performance.now();

                const [typed_ast, slim_ast, tyenv, dts, external_names_with_types, error] =
                    lang_entry.type_defs_to_js(base_dts, base_tenv, ast);

                const t1_1 = performance.now();

                console.log("Typing uses", t1_1 - t1);

                if (error) {
                    return handleError(error);
                } else {

                    const external_names = (external_names_with_types || []).map(e => e[0]);

                    // 从 Query_xxx 或者 Cmd_xxx 获得
                    const entity_names_from_external_names = external_names.map(name => {
                        const matched = /^(Query|Cmd)_([^\.]+)/.exec(name);
                        if (matched) {
                            const matchedName = matched[2];
                            return fragment_helper.entity_name_for_normalized_name(entity_dict, matchedName);
                        } else {
                            return null
                        }
                    }).filter(x => !!x);


                    // 另外还要检测一下所有的 dts 找到 E_Xxxx
                    // 这个也不一定全面
                    // const entity_names_from_dts = lang_entry_base.list_to_array(lang_entry.get_entity_names_from_dts(entity_dict, dts));

                    // 这个应该就比较全了：
                    const entity_names_from_ast = lang_entry_base.list_to_array(lang_entry.get_entity_names_from_typed_ast(entity_dict, typed_ast));

                    const used_entity_names = uniq([
                        // ...entity_names_from_dts, 
                        ...entity_names_from_external_names,
                        ...entity_names_from_ast
                    ])

                    const [name, defs] = slim_ast;
                    const compiled = packV4({
                        name, defs, dts, entityNames: used_entity_names, externalNames: external_names,
                    })

                    console.log("Compiled string length", compiled.length);

                    function makePreviewData() {

                        const usedSigs = fragment_helper.get_fragment_function_sigs_as_js(dts, external_names_with_types);
                        const involvingDynamicData = isInvolvingDynamicData(slim_ast, {
                            targetEntityName,
                            entity_dict, data_type_dict, dts
                        })

                        const ioTypeMetas = (() => {
                            const funcs = fragment_helper.get_func_types_as_js(defs);

                            const initType = funcs["init"];
                            const viewType = funcs["view"];

                            if (initType && viewType) {
                                // handle the meta data types
                                const initParamTypes = fragment_helper.get_interface_param_types_as_js(initType);

                                const initParamTypeMetas = initParamTypes.map(t => {
                                    const typeMeta = fragment_helper.to_type_metadata(entity_dict, data_type_dict, dts, t);
                                    return typeMeta
                                })

                                const viewParamTypes = fragment_helper.get_view_param_types_as_js(viewType);
                                const viewParamTypeMetas = viewParamTypes.map(t => {
                                    return fragment_helper.to_type_metadata(entity_dict, data_type_dict, dts, t);
                                })

                                const viewReturnTypes = fragment_helper.get_interface_return_types_as_js(viewType);
                                const viewReturnTypeMetas = viewReturnTypes.map(t => {
                                    return fragment_helper.to_type_metadata(entity_dict, data_type_dict, dts, t);
                                })

                                const isTargetingRecord = targetEntityName ?
                                    isInvolvingTargetRecord(slim_ast, {
                                        entity_dict, data_type_dict, dts, targetEntityName
                                    }) : false;

                                return {
                                    initParamTypes: tag(initParamTypes),
                                    initParamTypeMetas,

                                    ...(viewParamTypes && viewParamTypes.length > 0 ? {
                                        viewParamTypes: tag(viewParamTypes),
                                        viewParamTypeMetas,
                                    } : {}),

                                    viewReturnTypes: tag(viewReturnTypes),
                                    viewReturnTypeMetas,
                                    isTargetingRecord
                                };
                            }
                            // undefined
                        })();


                        return {
                            usedSigs, // 目前只用于检测 Page 对 PageFragment 的兼容性

                            involvingDynamicData, // 这个只是目前用于虚拟字段

                            externalNames: external_names,  // 记录依赖的外部函数，用于或者 depended fragments

                            ioTypeMetas, // 用于虚拟字段或者数据接口

                            // 用于 design 端的整合 fragments 的 tyenv； 以及在其他地方快速检测该代码声明的各个值（和函数）的类型
                            tyenv: JSON.stringify(tag(
                                lang_entry.only_exposed_tyenv(tyenv)
                                // tyenv
                            ))

                            // testInputParams 是在 CompositeEditor 里保存的
                        }

                    }

                    const previewData = makePreviewData();

                    setPackagedCode({
                        src: codeText,
                        compiled,
                        previewData,
                    })

                    if (setResultedTypingEnv) {
                        setResultedTypingEnv([
                            lang_entry_base.merge_env(base_tenv, tyenv),
                            lang_entry_base.merge_dts(base_dts, dts),
                            entity_dict,
                            data_type_dict,
                        ])
                    }

                    setTypedTree(typed_ast);

                    setTimeout(() => {
                        setSyntaxTree({ tree, codeText });
                    }, 1)

                    return []
                }
            }
            return []

        };

        const state = EditorState.create({
            // doc: value || storedItem || "",
            // doc: storedItem || "",
            doc: sourceCode || "",
            extensions: [
                ...setupCodeEditor({
                    afterLinter: (codeText, tree) => {
                        const errors = afterLinter(codeText, tree);
                        // send back to the upper layer:
                        markError(errors && errors.length > 0 ? errors : undefined)
                        return errors;

                    }
                }),

                [dropCursorPos, drawDropCursor],

                hoverTooltip((view, pos, side) => {
                    // console.log(">>> HOVER", view, pos, side)

                    // 1. 看看当前 hover 的是否一个 name
                    // 2. 确定当前 name 的类型
                    // 3. 显示类型（通过 React 构建, 直接 render 到一个新的 div 中，再返回）

                }),

                EditorView.updateListener.of(update => {
                    if (update.state.field(dropCursorPos) !== null) {
                        dropAtRef.current = view.state.field(dropCursorPos);
                    }

                }),
            ]
        });

        const view = new EditorView({ state, parent: editorRef.current });
        viewRef.current = view;

        return () => {
            view.destroy();
            // editorRef.current.removeEventListener("input", log);
        };
    }, []);


    function formatDocument(view, prettyPrint) {
        const state = view.state;

        const currentSelection = state.selection;
        if (currentSelection && currentSelection.ranges.length > 0) {
            const { from, to } = currentSelection.ranges[0];
            if (from < to) {
                // only format the selection
                const part = state.doc.sliceString(from, to);
                // console.log(">> format the part", part);

                const formatted = prettyPrintLang(80, part);
            }
        }
        // format the entire document
        // console.log(">>> format the entire document")
    }

    return (
        <div className="code-playground" onKeyDown={e => {
            if(e.metaKey == true && e.key == "s") {
                e.preventDefault();
                Message.info("Nothing to save");
            }            
        }}>
            <div className="defs-code-editor">
                <div className="code-mirror-editor" ref={editorRef}
                    onKeyDown={e => {
                        if (e.shiftKey && e.altKey && e.code == "KeyF") {
                            e.preventDefault();

                            const view = viewRef.current;
                            const state = view.state;

                            formatDocument(view, prettyPrintLang);

                            // view.dispatch({
                            //     changes: {
                            //         from: 0,
                            //         to: state.doc.length,
                            //         insert: "---- Demo"   
                            //     }
                            // })
                            // viewRef.current.dispatch({
                            //     newDoc: viewRef.current.state.toText("---- Demo")
                            // })

                        }
                    }}
                />
                {syntaxTree ? <CodeOutline tree={syntaxTree.tree} codeText={syntaxTree.codeText} onClickAt={node => {

                    // const docLine = editorState.doc.lineAt(at);
                    if (node) {
                        // 代码参照 @codemirror/search
                        // 文档见 https://codemirror.net/docs/ref/#state.Transaction
                        // https://codemirror.net/docs/ref/#view.EditorView^scrollIntoView

                        const nextSelection = EditorSelection.cursor(node.from);

                        // 在 select 代码块的首行
                        viewRef.current.dispatch({
                            // selection: EditorSelection.cursor(node.from),
                            // scrollIntoView: true
                            selection: nextSelection,
                            effects: [
                                EditorView.scrollIntoView(nextSelection, { y: "center" })
                            ]
                        });
                    }

                }} /> : null}
            </div>
            <div className="eval-area">
                <div className="tool-bar">
                    <Button size="small" disabled={error || !evalEnv || !typedTree} onClick={_ => {
                        const freshEnv = lang_entry.eval_defs_to_js(
                            evalEnv,
                            typedTree,
                        );
                        setEvalResult(freshEnv);

                    }}>运行</Button>
                </div>
                <EvalResult result={evalResult} />
            </div>
        </div>
    );

}

function EvalResult({result = {}}) {
    
    function displayValue(v) {
        return lang_entry_base.string_of_value(v);
    }


    return (
        <div className="eval-result">
            <pre>
                { Object.keys(result).map(name => {
                    return (
                        <div key={name}>
                            { name } = {displayValue(result[name])}
                        </div>
                    )    
                }) 
                }
            </pre>
        </div>
    )
}

