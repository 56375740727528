
import './BreadcrumbSimple.less'

import React, { useState, useEffect, useContext } from 'react'
import { matchPath } from 'react-router'

import { Breadcrumb as AntdBreadcrumb } from 'antd'

import { Icon } from '@ant-design/compatible';
import * as MDIcons from 'react-icons/md';

import { getMarkedNav } from './breadcrumbHelper'
import { routeDef as adminRoutes } from 'Client/js/adminApp/adminRouteDef'

import { paths as designRoutes } from 'Client/js/designApp/DesignAppRoutes'

import { evaluateWithQuery } from 'bwax'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import ClampedText from 'bwax-ui/components/ClampedText'


// 固定的层级路径。。大概如下
// 1）任何当前页面都有自己的 “名称“。列表直接使用 <实体名称>（或者管理后台别名），详情页则使用 <实体名称 [记录显示名]> （记录的显示名就是排序最高的短文本字段）
// 2）记录导航栏点击动作，存在 sessionStorage 中。
//      Breadcrumb 固定显示最近的导航栏点击所代表的路径。
//      如果当前页面正是该导航路径的终点，则只显示该导航路径。如果不在该导航路径中，则在导航路径后面加上当前页面的”名称”。
// 3）如果当前没有任何导航栏点击动作。
//      检查当前页面是否在导航栏中，如果在的话，显示第一个满足条件的导航路径。
//      如果不在，则直接显示该页面的名称。

// http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/383


export default function BreadcrumbSimple(props) {

    const { routeTo, pathname, allEntities, allDataTypes, queryTarget = "data", additional } = props;

    const nav = getMarkedNav();

    const [ inspected, setInspected ] = useState(undefined);
    const [ pageName, setPageName] = useState(undefined);

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)

    const evaluate = (def, baseData) => {
        return evaluateWithQuery(
            def,
            baseData,
            {
                allDataTypes, allEntities, queryTarget, 
                sessionToken, tenantCode, sandbox
            }
        )
    }

    useEffect(() => {
        const inspected = inspectPath(pathname);
        if (inspected) {
            (async () => {

                const { pathWithoutTab, params, entityKey, id } = inspected;

                setInspected(inspected)
                // 如果 nav 最后一个已经是它了，则直接 set none
                if(nav && nav.length > 0 && nav[nav.length - 1].path === pathWithoutTab) {
                    setPageName(undefined)
                    return
                } 


                if(entityKey && !(id || params.name) ) {
                    /// Record List
                    const entity = allEntities.find(e => e.key == entityKey || e.name == entityKey); 
                    if(!entity) {
                        setPageName("<未知数据实体>")
                    } else {
                        const aliasName = entity.adminDisplayOptions ? entity.adminDisplayOptions.aliasName : undefined;
                        setPageName(aliasName || entity.name)    
                    }

                } else if(entityKey && (id || params.name) ) {
                   
                    const criteria = id ? { id } : { 名称: params.name };

                    const def = buildGetRecordDef(entityKey, criteria, allEntities);

                    const recordName = (await evaluate(def, {})) || id || params.name;

                    const entity = allEntities.find(e => e.key == entityKey); 
                    const aliasName = entity.adminDisplayOptions ? entity.adminDisplayOptions.aliasName : undefined;

                    const entityName = aliasName || entity.name

                    const fullName = `【${entityName}】${recordName}`
                    setPageName(fullName)

                } else if(params.pageName) {
                    setPageName(params.pageName) 

                } else {

                    /// TODO for other type of page

                }
               
            })()
        }

    }, [pathname])


    function buildCrumbs() {
        const currentPage = (pageName ? [{name: pageName} ] : [])
        if(nav) {
            return [...nav, ...currentPage];
        } else {

            const fullNav = additional && additional.menuGroups && additional.menuGroups[1] || []

            // iterate to find the first matched nav
            if(inspected) {
                for(let i in fullNav) {
                    const group = fullNav[i]
                    if(group.path && group.path == inspected.pathWithoutTab) {
                        return [group]
                    }
                    if(group.items) {
                        for(let j in group.items) {
                            const item = group.items[j]
                            if(item.path == inspected.pathWithoutTab) {
                                return [group, item]
                            }
                        }
                    }
                }
            }
            /// otherwise return the current:
            return currentPage
        }
    }
    
    const realCrumbs = buildCrumbs()

    const crumbs = [ ...realCrumbs ];

    function getIconElement(iconName) {
        if(!iconName) {
            return null
        }
        const MDIcon = MDIcons["Md" + iconName];
        if(MDIcon) {
            return <span style={{
                // fontSize: "14px",
                // marginRight: "10px",
                opacity: 0.8,
                transform: "translateY(1.5px)",
                // alignItems: "center",
                // paddingBottom: "1px",
            }}><MDIcon /></span>
        } else {
            return <span><Icon type={iconName} /></span>
        }

    }


    return (
        <AntdBreadcrumb className="breadcrumb" separator=">">
            {
                crumbs.map((crumb, idx) => {

                    // 如果是第一个 breadcrumb，没有 icon 也要给他一个 icon。
                    const iconName = crumb.icon || (idx === 0 ? "profile": undefined);
                    const icon = getIconElement(iconName)

                    return (
                        <AntdBreadcrumb.Item
                            key={idx}
                            onClick={() => {
                                if (crumb.path) {
                                    routeTo(crumb.path, { modal: false })
                                }
                            }}
                            style={{ cursor: crumb.path ? 'pointer' : 'default', display: "inline-flex", alignItems: "center" }} >
                            { icon }
                            {/* <span>{crumb.name}</span> */}
                            <ClampedText text={crumb.name} style={{ 
                                maxWidth: 240, marginLeft: icon ? 6 : 0
                            }} tipEnabled={false} />
                        </AntdBreadcrumb.Item>
                    )
                })
            }
        </AntdBreadcrumb>
    )
}



/* 找出三个值，
    name - RecordList / RecordDetail
    pathWithoutTab - path wihout tab
    params - like entityKey, id, key ...
*/

export function inspectPath(pathname) {

    const packup = (m, route) => {
        let pathWithoutTab = pathname;
        const { params } = m;
        const { id, tab } = params;
        // tab 默认在最后面;
        if (tab && !id) {
            pathWithoutTab = pathname.slice(0, pathname.length - ("/" + tab).length)
        } else if(tab && id) {
            pathWithoutTab = pathname.slice(0, pathname.length - ("/" + id + "/" + tab).length)
        }
        const entityKey = params.entityKey || route.entityKey;

        return {
            name: route.name,
            pathWithoutTab,
            entityKey,
            id,
            params
        }
    }
    for (let p in adminRoutes) {
        const appPath = "/app/:appcode" + p;
        const m = matchPath(pathname, {
            path: appPath
        })
        if (m) {            
            return packup(m, adminRoutes[p])
        }
    }
    // if not is matched above, try the designRouteDef
    for (let p in designRoutes) {
        const m = matchPath(pathname, { path: p})
        if(m) {
            return packup(m, designRoutes[p])
        }
    }

}



function buildGetRecordDef(entityKey, criteria, allEntities) {
    
    const entity = (allEntities || []).find(e => e.key === entityKey)
    // 直接可以假定 entity 存在：
    const allFields = [ ...entity.fields, ...entity.virtualFields ].sort((a, b) => {
        // 给 field 排序
        /// 根据 displayWeight 从大到下排列
        const getWeight = f => f.displayWeight || 0
        // 重的在前面
        return getWeight(b) - getWeight(a)
    });
    const field = allFields.find(f => f.type === 'ShortText' || f.type == 'Text')

    if(entity) {
        return {
            __query__: {
                data: {
                    entityName: entity.name,
                    type: 'findOne',
                    criteria
                },
                __for__: field ? `\${data.${field.name}}` : '${data.id}'
            }
        }
    }
    return {}
}
