import React from 'react'

import PicturesWall from 'Client/js/components/PicturesWall'

//value <-> [{value}]
export default function Image(props) {
    const { value, onChange, params } = props
    const { uploadFor, isPublic, multivalued, width, height, processor } = params
    return (
        <PicturesWall
            type="Image"
            editing={true}
            value={value}
            onChange={onChange}
            uploadFor={uploadFor}
            isPublic={isPublic}
            multivalued={multivalued}
            width={width}
            height={height}
            processor={processor}
        />
    )
}