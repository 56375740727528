
import React, { useRef, useEffect, useState } from 'react'

import { EditorState } from "@codemirror/state";
import { EditorView, dropCursor } from "@codemirror/view";

import lang_parser_lezer from 'bwax/ml/lang/lang_parser_lezer.bs';

import './CodeMirrorEditor.less';

import lang_entry from 'bwax/ml/lang/lang_entry.bs'

import setupCodeEditor from './setupCodeEditor';

import { forceLinting } from "@codemirror/lint";


export default function ExprCodeEditor(props) {

    const {
        sourceCode,
        typingEnv, // [ tenv, dts, entity_dict, data_type_dict ]
        onChange, 
        onError, 
        expectedType, // it is the lang term

        bindSetter, 
    } = props;


    const editorRef = useRef();
    const viewRef = useRef();

    // testing = true;
    const dropAtRef = useRef()

        // 在 linter 里面访问：
    const typingEnvRef = useRef();
    typingEnvRef.current = typingEnv;

    const expectedTypeRef = useRef();
    expectedTypeRef.current = expectedType;

    function updateCodeText(codeText) {
        if (viewRef.current && viewRef.current.state.doc) {
            const currentDoc = viewRef.current.state.doc;
            const docText = currentDoc.toJSON().join("\n");
            if (docText != codeText) {
                viewRef.current.dispatch({
                    changes: {
                        from: 0,
                        to: currentDoc.length,
                        insert: codeText,
                    }
                })
            }
        }
    }
    if(bindSetter){
        bindSetter(updateCodeText)
    }

    useEffect(() => {
        // typing env has changed
        if(viewRef.current) {
            // forceLinting(viewRef.current);
            // 下面这一段是为了 force linting
            if (viewRef.current.state.doc) {
                const currentDoc = viewRef.current.state.doc;
                const docText = currentDoc.toJSON().join("\n");
                viewRef.current.dispatch({
                    changes: {
                        from: 0,
                        to: currentDoc.length,
                        insert: docText,
                    }
                })
            }
        }
    }, [typingEnv])

    useEffect(() => {

        const [dropCursorPos, drawDropCursor] = dropCursor();

        const afterLinter = (codeText, tree) => {

            const typingEnv = typingEnvRef.current;
            const expectedType = expectedTypeRef.current;

            function markError([message, startIndex, endIndex]) {
                const [from, to] = (() => {
                    if (startIndex == endIndex) {
                        if (startIndex > 0) {
                            return [startIndex - 1, endIndex]
                        } else if (endIndex < codeText.length) {
                            return [startIndex, endIndex + 1]
                        }
                    }
                    return [startIndex, endIndex]
                })();
                return [{ message, from, to, severity: "error" }]
            }

            // 1. convert it to SimpleExpr;            
            const [result, parseError] = lang_parser_lezer.convert_expr_entry(codeText, tree);

            // 2. type the expr ast;
            if (parseError) {

                // 如果 code text 是空字符串，则不返回错误
                if(!codeText || codeText.trim().length === 0) {
                    onChange(undefined, "");
                    return []
                }

                return markError(parseError);
            } else if (typingEnv) {

                const [base_tenv, base_dts, _entity_dict, _data_type_dict] = typingEnv;

                const [raw_ast, _] = result;

                const t1 = performance.now();
                const [ast, error] = lang_entry.type_expr_with_expected_type(base_dts, base_tenv, undefined, expectedType, raw_ast)
                const t1_1 = performance.now();
               
                console.log("Typing uses", t1_1 - t1, "ms");

                if (error) {

                    onError(error[0], codeText);

                    return markError(error);
                } else {
                    
                   // 3. pass by the ast and the code;
                    onChange(ast, codeText);
                    return [];
                }
            } else {
                return [];
            };

        };

        const state = EditorState.create({
            // doc: value || storedItem || "",
            // doc: storedItem || "",
            doc: sourceCode || "",
            extensions: [
                ...setupCodeEditor({ syntaxTop: "SingleExpr", afterLinter, compact: true }),

                [dropCursorPos, drawDropCursor],

                EditorView.updateListener.of(update => {
                    if (update.state.field(dropCursorPos) !== null) {
                        dropAtRef.current = view.state.field(dropCursorPos);
                    }

                }),
            ]
        });

        const view = new EditorView({ state, parent: editorRef.current });
        viewRef.current = view;

        return () => {
            view.destroy();
            // editorRef.current.removeEventListener("input", log);
        };
    }, []);



    return (
        <div className="code-mirror-editor" ref={editorRef}></div>
    );

}





