
import React, { useEffect, useRef, useState } from 'react';

import lang_entry from 'bwax/ml/lang/lang_entry.bs'

import Editor from '@monaco-editor/react';
import { getMonaco } from 'Client/js/designApp/pages/MonacoUtils'

import './TestLanguage.less';

import { MdExpandMore, MdExpandLess } from "react-icons/md";

import LocalPagedTable from 'Client/js/newTable/LocalPagedTable';

import Nav from 'rsuite/Nav';
import LanguageVis from './visualization/LanguageVis';

const key = "test-language-editor-code"
const initialCode = localStorage.getItem(key) || ""

const modeKey = "test-language-editor-mode"
const initialMode = localStorage.getItem(modeKey) || "代码解析"



export default function TestLanguage(props) {

    const [editor, setEditor] = useState(undefined);

    const [code, setCode] = useState(initialCode);

    // const [parsingTime, setParsingTime] = useState(undefined);
    const [error, setError] = useState(undefined);

    // 不同模式 = 
    // 代码解析, 类型合成, 程序运算
    const [mode, setMode] = useState(initialMode);

    // 代码解析的结果
    const [parseTree, setParseTree] = useState(null);

    // 类型合成的结果, ast
    const [syntaxTree, setSyntaxTree] = useState(null);

    // 程序运行的结果, freshEnv
    const [freshEnv, setFreshEnv] = useState(null);

    const [isEnvReady, setIsEnvReady] = useState(false);
    const [globalTenv, setGlobalTenv] = useState(null);   // 变量类型表   ocaml: Dict.String.empty ()
    const [globalDTS, setGlobalDTS] = useState(0);       // 预定义类型   ocaml: []
    const [globalEnv, setGlobalEnv] = useState(null);

    // 
    useEffect(() => {

        fetch("/external-site?url=" + encodeURIComponent("https://www.qq.com")).then(res => res.text()).then(text => console.log(text));

        (async () => {
            const [globalTenv, globalDTS, globalEnv] = lang_entry.prepare_global_tenv();
            setGlobalTenv(globalTenv);
            setGlobalDTS(globalDTS);
            setGlobalEnv(globalEnv);
            setIsEnvReady(true);
        })();
    }, [])


    const views = {
        "代码解析": (
            parseTree ? <ParseTreeNode key={"root"} node={parseTree.rootNode} /> : null
        ),
        "类型合成": (
            syntaxTree && !error ? <TypedSyntaxTree tree={syntaxTree} /> : null
        ),
        "程序运算": (
            freshEnv ? <EvaluationResult freshEnv={freshEnv} /> : null
        ),
        // "可视化": (
        //     syntaxTree && !error ? <LanguageVis tree={syntaxTree} /> : null
        // )
    }

    function handleError(error) {
        let monacoErrors = [];
        const model = editor.getModel();
        const mnc = getMonaco();

        if (error && model) {
            const [message, startIndex, endIndex] = error;

            const startPos = model.getPositionAt(startIndex);
            const endPos = model.getPositionAt(endIndex);

            monacoErrors.push({
                startLineNumber: startPos.lineNumber,
                startColumn: startPos.column,
                endLineNumber: endPos.lineNumber,
                endColumn: endPos.column,
                message,
                severity: mnc.MarkerSeverity.Error
            });
        };
        mnc.editor.setModelMarkers(model, "owner", monacoErrors);

        setError(error);
    }

    // 
    const codeHandlers = {
        "代码解析": async code => {
            const [result, error] = lang_entry.parse_code(code);

            if(error) {
                handleError(error);
            } else {            
                const [_rawSyntaxTree, parseTree] = result;
                setParseTree(parseTree);
                handleError(null);
            }
           
        },
        "类型合成": async code => {
            // 解析，并且 typing
            const [result, error] = lang_entry.parse_code(code);            
            if (error) {
                handleError(error);
            } else {
                // perform the typing:
                const [rawSyntaxTree, parseTree] = result;

                setParseTree(parseTree);
                
                const [typedSyntaxTree, error] = lang_entry.synthize_type(
                    globalTenv,
                    globalDTS,
                    rawSyntaxTree
                );

                if (error) {
                    handleError(error)
                } else {
                    setSyntaxTree(typedSyntaxTree);

                    // clear error 
                    handleError(null)
                }

            }
            // perform the typing:
        },
        "程序运算": async code => {
            // 
            const [[rawSyntaxTree, parseTree], error] = lang_entry.parse_code(code);

            setParseTree(parseTree);
            if (error) {
                handleError(error);
            } else {

                const [typedSyntaxTree, error] = lang_entry.synthize_type(
                    globalTenv,
                    globalDTS,
                    rawSyntaxTree
                );
                if (error) {
                    handleError(error)
                } else {
                    setSyntaxTree(typedSyntaxTree);

                    // perform the evaluation:
                    const freshEnv = lang_entry.eval_defs_to_js(
                        globalEnv,
                        typedSyntaxTree,
                    );

                    setFreshEnv(freshEnv);

                    // clear error 
                    handleError(null)
                }

            }
        },
        "可视化": async code => {
            // 解析，并且 typing
            const [[rawSyntaxTree, parseTree], error] = lang_entry.parse_code(code);

            setParseTree(parseTree);
            if (error) {
                handleError(error);
            } else {
                // perform the typing:
                const [typedSyntaxTree, error] = lang_entry.synthize_type(
                    globalTenv,
                    globalDTS,
                    rawSyntaxTree
                );

                if (error) {
                    handleError(error)
                } else {
                    setSyntaxTree(typedSyntaxTree);


                    // 进行可视化尝试
                    // TODO


                    // clear error 
                    handleError(null)
                }

            }
            // perform the typing:
        },
    };

    useEffect(() => {
        (async () => {
            await initializer;
            if (editor) {
                codeHandlers[mode](code);
            }
        })();
    }, [editor])

    useEffect(() => {
        if (isEnvReady) {
            codeHandlers[mode](code);
        }
    }, [code, mode, isEnvReady])



    const editorDidMount = (editor, monaco) => {
        let model = editor.getModel();
        if (model) {
            model.updateOptions({
                tabSize: 2
            })
        }
        setEditor(editor);
        editor.onDidChangeModelContent(e => {
            const code = editor.getValue();
            localStorage.setItem(key, code);
            setCode(code);
        })
    }

    return (
        <div className="test-language">
            <div className="main-area">
                <div className="code-editor">
                    <Editor
                        language="bwax"
                        value={initialCode}
                        theme="bwax-light"
                        onMount={editorDidMount}
                        options={{
                            fontSize: 11
                        }}
                    />
                </div>
                <div className="language-analyzer">
                    <Nav appearance={"subtle"} activeKey={mode} onSelect={m => {
                        localStorage.setItem(modeKey, m)
                        setMode(m)
                    }}>
                        {Object.keys(views).map(k => {
                            return <Nav.Item key={k} eventKey={k}>{k}</Nav.Item>
                        })}
                    </Nav>
                    <div className="analyzer-panel">
                        {views[mode] || null}
                    </div>
                </div>
            </div>
            <div className="status-bar">
                {/* { parsingTime !== undefined ? "Parsing used " + parsingTime + "ms" : null}
                <div className="error">
                    { error ? "Error at " + error : null }
                </div> */}
            </div>
        </div>
    )
}





function ParseTreeNode({ node, depth = 0 }) {

    if (!node) {
        return null
    }

    const [collapsed, setCollapsed] = useState(depth >= 3 ? true : false);

    const pos = ({ row, column }) => `[${row}, ${column}]`;

    const name = node.type == "name" ? ` [${node.text}]` : ""
    const summary = `${node.type}${name} ${pos(node.startPosition)} - ${pos(node.endPosition)}`;

    const renderIcon = () => {
        if (node.childCount !== 0) {
            return collapsed ? <MdExpandMore /> : null // <MdExpandLess />
        } else {
            return null
        }
    }


    return (
        <div className="syntax-node" key={key}>
            <div className="node-summary" style={{
                paddingLeft: 16 * depth
            }} onClick={() => {
                // Toggle collapse state
                setCollapsed(prev => !prev);

            }}>
                <div className="summary-text">{summary}</div>
                <div className="op-icon">{renderIcon()} </div>
            </div>
            <div className="node-chidren">
                {collapsed ? null : node.children.map(c => {
                    return <ParseTreeNode key={c.id} node={c} depth={depth + 1} />
                })}
            </div>
        </div>
    )

}



function TypedSyntaxTree({ tree }) {

    // to js tree { __node__, __ty name, value, type, children }
    // const [ name, defs ] = tree;
    const treeJs = lang_entry.defs_to_tree_js(tree);
    return treeJs.map((n, i) => <SyntaxTreeNode node={n} depth={0} key={i} />)
}

function SyntaxTreeNode({ fieldName, node, depth = 0 }) {

    const [collapsed, setCollapsed] = useState(depth >= 10 ? true : false);

    if (!node) {
        return null
    }


    if (!node.__node) {
        // 这个 object 不是 node
        // 那就是一个 key -> node mapping 或者是一个 array，或者是一个 string
        if (Array.isArray(node)) {
            return node.map((n, i) => {
                return <SyntaxTreeNode key={i} node={n} depth={depth} />
            })
        } else if (typeof (node) == "string") {
            return (
                <div className="node-summary" style={{
                    paddingLeft: 16 * depth
                }}>
                    <span className="subtle">{node} </span>
                </div>
            )
        } else {
            return Object.keys(node).map(k => {
                return (
                    <SyntaxTreeNode key={k} fieldName={k} node={node[k]} depth={depth} />
                )
            })
        }


    }

    const { __type, name, value, meta, children } = node;
    const { type_str } = meta;
    const summary = (
        <>
            {fieldName ? <span className="leading">{`${fieldName} => `}</span> : null}
            <span className="subtle">{__type} {__type !== name ? ` [${name}]` : ""} </span>
            <span className="subtle">{`${value ? value : ""} :`}</span>
            <span>{type_str}</span>
        </>
    )

    const renderIcon = () => {
        if (children && (!Array.isArray(children) || children.length !== 0)) {
            return collapsed ? <MdExpandMore /> : null // <MdExpandLess />
        } else {
            return null
        }
    }


    function renderChildren(children) {
        // children 可能是单个 node，可能是 array 也可能是 key -> node mapping

        // 如果是 array'
        if (Array.isArray(children)) {
            return node.children.map((c, i) => {
                return <SyntaxTreeNode key={i} node={c} depth={depth + 1} />
            })
        } else {
            // 是 node 还是其他，则在里面确定：
            return <SyntaxTreeNode node={children} depth={depth + 1} />
        }
    }

    return (
        <div className="syntax-node" key={key}>
            <div className="node-summary" style={{
                paddingLeft: 16 * depth
            }} onClick={() => {
                // Toggle collapse state
                setCollapsed(prev => !prev);

            }}>
                <div className="summary-text">{summary}</div>
                <div className="op-icon">{renderIcon()} </div>
            </div>
            <div className="node-chidren">
                {collapsed ? null : renderChildren(children)}
            </div>
        </div>
    )
}

function EvaluationResult({ freshEnv }) {


    const columns = [{
        title: "变量",
        dataIndex: "name",
        key: "name",
        width: 120,
    }, {
        title: "值",
        dataIndex: "value",
        key: "value"
    }];

    const data = Object.keys(freshEnv || {}).map(name => ({
        name,
        value: freshEnv[name]
    }))

    return (
        // <div className="right-tab">
        //     <div className="button-bar">
        //         <Button disabled={!compiledStr || !facade}
        //             onClick={onRun}>运算</Button>
        //     </div>
        <LocalPagedTable rowKey="name" columns={columns} dataSource={data}
            style={{ height: "calc(100% - 0px)" }}
            pagination={{ pageSize: 20 }} size="small" />
        // </div>
    )

}