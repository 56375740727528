
import React from 'react';

import { Icon } from '@ant-design/compatible';

import OperationButtonBox from './OperationButtonBox';

export default function NotificationsButton(props) {
    return (
        <OperationButtonBox
            buttonContent={
                <Icon type="notification" />
            }
            panel={
                <div className="operation-panel empty-state">
                    没有未处理的消息
                </div>
            }
        />
    )
}
