
import React, { useEffect, useState, useContext } from 'react';


import Modal from 'bwax-ui/ml/widget/impl/misc/Modal';

import { runDefinitionQuery } from 'bwax/query/runClientQuery'

import ApplicationContext from 'bwax/util/ApplicationContext';

import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

import './AddDependencyDialog.less';
import Loading from 'Client/js/components/Loading';

import Message from 'Client/js/ui/Message';

const QueryText = `query { domainApplications { name code isSystemApp isLibrary } }`

export default function AddDependencyDialog({ config, setConfig, facade, reloadList }) {
    // 

    // { customApps: [], libraryApps }
    const [ apps, setApps ] = useState({ customApps: [], libraryApps: [] });
    const [ dependencies, setDependencies] = useState();

    const applicationContext = useContext(ApplicationContext) || {};
    const { currentApplication, mode = "app" /* or design */ } = applicationContext;

    useEffect(() => {
        if(config) {
            /// load the current dependency and load the all applications in domain

            (async () => {
                const result = await runDefinitionQuery(facade.dlc)(QueryText)();

                const { data, errors } = JSON.parse(result);
                if(errors) {
                    // handle errors
                } else {
                    const allApps = data.domainApplications;

                    const customApps = allApps.filter(a => !a.isLibrary);
                    const libraryApps = allApps.filter(a => a.isLibrary);

                    setApps({
                        customApps, libraryApps
                    })
                }

            })();

            (async () => {
                const baseCriteria = {
                    应用: currentApplication.id
                };
                
                const queryObj = {
                    entityName: "应用依赖",
                    condition: mergeCriteria(baseCriteria, {}),
                    pageSize: 1000,
                    offset: 0,
                    fieldPaths: [
                        "依赖",
                        // "系统应用",
                        // "公开的实体",
                        // "公开的页面组件"
                    ],
                };
                const [result, error] = await facade.listAll(queryObj, {});
        
                if (error) {
                    // error handling
                    console.log("ERROR", error);
                } else {

                    setDependencies(result.data.map(d => d["依赖"]))
                }

            })()
        }

    }, [config]);


    // 
    async function addDependency(dependency) {

        console.log(">>> ADD DEPENDENCY", dependency, currentApplication);
        const [_result, error] = await facade.add({
            entityName: "应用依赖", formData: {
                "应用": currentApplication.id,
                "依赖": dependency,
            }
        });

        if(error){
            console.log(">>> TODO HANDLE RROR", error)
        } else {

            await reloadList("dependency", config.parentItem);

            Message.success("添加成功");
            setConfig(); // undefined

        }

    }

    function renderContent() {


        const { customApps, libraryApps } = apps;

        const allApps = [ ...customApps, ...libraryApps ].filter(a => {
            return (dependencies || []).indexOf(a.name) === -1 && a.name !== currentApplication.name
        });
    

        return (
            <div className="studio-dialog add-dependency-dialog">
                <div style={{ marginBottom: 16 }}>
                    点击添加应用依赖
                </div>
                <div className="app-gallery">
                    { dependencies ?
                        allApps.map(a => {
                            
                            return (
                                <div key={a.name} className="app-box" style={{
                                    backgroundColor: !a.isLibrary ? "papayawhip" : "aliceblue"
                                }} onClick={_ => {
                                    addDependency(a.name);
                                }}>
                                    { a.name }
                                </div>
                            )
                        }) : <Loading />                        
                    }
                </div>
            </div>
        )
    }

    return (
        <Modal {...{
            visible: !!config,
            containerStyle: {
                borderRadius: 4,
                width: "36rem",
            },
            modalContent: renderContent(),
            onBackgroundClick: _ => {
                setConfig() // undefined
            }
        }} />
    )
}



