
import React from 'react';

import { 
    MdFolder, MdOutlineUpdate, MdTableView, 
    MdOutlineEscalator, MdPivotTableChart, MdPerson, MdGrid3X3,
    MdOutlineTextSnippet, MdTextSnippet, MdOutlineTextFields, 
    MdManageSearch, MdOutlineSettings,
    MdTab, MdTabletAndroid, MdTask, MdWebAsset,
    MdInput, 
} from 'react-icons/md';


import { 
    BiTable, BiColumns, BiWindows, BiWindow, BiText, BiTime, BiData,
    BiMinusFront, BiImage, BiFile, BiTask, BiLayout, BiWindowOpen,
    BiFoodMenu, BiCabinet, BiColorFill,

    BiCube, BiOutline, BiWalletAlt
} from 'react-icons/bi';

import { GrCube, GrCubes } from 'react-icons/gr';


import { RiLayoutLeftLine } from 'react-icons/ri';

import { IoMdArrowDropdownCircle, IoMdCalendar } from 'react-icons/io';


function namedIcon (name, Icon) {
    return function ({ size = "x-small", style }) {
        return (
            <div className={size + "-icon " +  name + "-icon"} style={style}>
                <Icon />
            </div>
        )
    }
}

export const PlatformResourceIcon = namedIcon("entity", BiCube);
export const AppResourceIcon = namedIcon("entity", BiCube);

function renderNamedIcon (name, Icon) {
    const C = namedIcon(name, Icon);
    return <C />
}


export const FolderIcon = namedIcon("folder", MdFolder);

export const UserInterfaceIcon = namedIcon("user-interface", BiWindow);

export const ProcessIcon = namedIcon("process", MdOutlineEscalator);

export const EventHandlerIcon = namedIcon("process", MdTask);

export const ScheduledEventHandlerIcon = namedIcon("process", BiTask);

export const DataModelIcon = namedIcon("entity", () => (
    <MdTableView style={{
        transform: "scaleX(-1)"
    }} />
))

export const DependencyIcon = namedIcon("dependency", BiWalletAlt);

export const EntityIcon = namedIcon("entity", BiTable);

export const PageIcon = namedIcon("page", MdTabletAndroid);

export const PageComponentIcon = namedIcon("page", MdWebAsset);

export const PageFragmentIcon = namedIcon("page", MdTab);

export const PageRedirectIcon = namedIcon("page", MdInput);

export const AdminPageIcon = namedIcon("page", RiLayoutLeftLine);


const fieldTypeIcons = {
    "短文本": MdOutlineTextFields,  // facade return 回来的是 options 的 label  这个可以调整一下
    "ShortText": MdOutlineTextFields, // definition 版本的是原来的值

    "文本": MdOutlineTextSnippet,
    "Text": MdOutlineTextSnippet,

    "富文本": MdTextSnippet,
    "RichText": MdTextSnippet,

    "日期": IoMdCalendar,
    "Date": IoMdCalendar,

    "日期时间": BiTime,
    "DateTime": BiTime,

    "数字": MdGrid3X3,
    "Number": MdGrid3X3,

    "整数": MdGrid3X3,
    "Integer": MdGrid3X3,

    "布尔": BiMinusFront,
    "Boolean": BiMinusFront,

    "图片": BiImage,
    "Image": BiImage,

    "文件": BiFile,
    "File": BiFile,

    "颜色": BiColorFill,
    "Color": BiColorFill,

    "选择": IoMdArrowDropdownCircle,
    "Select": IoMdArrowDropdownCircle,


};

const getIconForFieldTypeAndOptions = (fieldType, fieldOptions) => {
    if(fieldType == "关联数据" || fieldType === "Link") {
        if(fieldOptions && fieldOptions.entity && (fieldOptions.entity.indexOf("用户") != -1 || fieldOptions.entity.indexOf("User") != -1)) {
            return MdPerson
        } else {
            return MdPivotTableChart
        }
    }
    return undefined
};

const getFieldTypeIcon = (fieldType, fieldOptions) => {
    const SpecifiedIcon = getIconForFieldTypeAndOptions(fieldType, fieldOptions) || fieldTypeIcons[fieldType];
    if(!SpecifiedIcon) {
        // console.log("No specified icon for", fieldType, fieldOptions);
    }
    return SpecifiedIcon || BiColumns;
}


export const FieldIcon = function ({ size = "x-small", fieldType, fieldOptions, style }) {
    const Icon = getFieldTypeIcon(fieldType, fieldOptions);

    return namedIcon("field", Icon)({size, style});
}

export const VirtualFieldIcon = function ({ size = "x-small", fieldType, fieldOptions, style}) {
    const Icon = getFieldTypeIcon(fieldType, fieldOptions);
    return namedIcon("virtual-field", Icon)({size, style});
}

export const BacklinkFieldIcon = function ({ size = "x-small", fieldType, fieldOptions, style }) {
    const Icon = getFieldTypeIcon(fieldType, fieldOptions);
    return namedIcon("backlink-field", Icon)({size, style});

}

export const DataInterfaceQueryIcon = namedIcon("data-interface-query", MdManageSearch);

export const DataInterfaceOperationIcon = namedIcon("data-interface-operation", MdOutlineUpdate);

export const GeneralSettingIcon = namedIcon("entity", MdOutlineSettings);


// 
export function getItemIcon (item) {

    const { itemType, data } = item;

    const fieldIcon = (fieldType, fieldOptions) => <FieldIcon fieldType={fieldType} fieldOptions={fieldOptions} />;
    const virtualFieldIcon = (fieldType, fieldOptions) => <VirtualFieldIcon fieldType={fieldType} fieldOptions={fieldOptions} />;
    const backlinkFieldIcon = (fieldType, fieldOptions) => <BacklinkFieldIcon fieldType={fieldType} fieldOptions={fieldOptions} />;

    const dataInterfaceQueryIcon = <DataInterfaceQueryIcon />;
    const dataInterfaceOperationIcon = <DataInterfaceOperationIcon />;

    const icons = {
        "page": _ => <PageIcon />,
        "page-component": _ => <PageComponentIcon />,
        "page-fragment": _ => <PageFragmentIcon />,
        "page-redirect": _ => <PageRedirectIcon />,
        "admin-page": _ => <AdminPageIcon />,

        "entity-list-page": _ => <AdminPageIcon />,
        "entity-detail-page": _ => <AdminPageIcon />,

        "entity": _ =>  <EntityIcon />,
        "field": f => {
            return fieldIcon(f["字段类型"], f["字段选项"])
        },
        "virtual-field": f => {
            return virtualFieldIcon(f["字段类型"], f["字段选项"])
        },
        "backlink-field": f => {
            return backlinkFieldIcon("关联数据", { entity: f["关联实体名"]})
        },
        "data-interface": data => {
            if(data["类型"] == "查询") {
                return dataInterfaceQueryIcon
            } else {
                return dataInterfaceOperationIcon
            }
        },
        "event-handler": _ => <EventHandlerIcon />,
        "scheduled-event-handler": _ => <ScheduledEventHandlerIcon />,

        "general-setting": _ => <GeneralSettingIcon />,

        // other auxiliary icons
        "entities": _ => <DataModelIcon />,

        "extending-entities": _ => <DataModelIcon />,

        "folder": _ => <FolderIcon />,

        "dependency": d => {
            const name = d["系统应用"] ? "system-dependency" : "dependency";
            return renderNamedIcon(name, BiFoodMenu)
        },

        "dependencies": _ => renderNamedIcon("dependency", BiCabinet),

        "imported-entity": _ => <EntityIcon />,

        "imported-field": f => {
            return fieldIcon(f.type, f.options)
        },
        "imported-virtual-field": f => {
            return virtualFieldIcon(f.type, f.options)
        },
        // "imported-backlink-field": f => {
        //     return backlinkFieldIcon("关联数据", { entity: f["关联实体名"]})
        // },
        "imported-data-interface": itf => {
            if(itf.type == "查询") {
                return dataInterfaceQueryIcon
            } else {
                return dataInterfaceOperationIcon
            }
        },

    }

    const getIcon = icons[itemType];
    if(getIcon) {
        return getIcon(data)
    } else {
        if(itemType.indexOf("-folder") !== -1) {
            return <FolderIcon />
        }
        return <FolderIcon />
    }
}