// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Set$BwaxAdmin = require("../set.bs.js");
var Dict$BwaxAdmin = require("../dict.bs.js");
var Json$BwaxAdmin = require("./json.bs.js");
var Graph$BwaxAdmin = require("../graph.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("../lang/lang_typing_base.bs.js");
var Lang_typing_print$BwaxAdmin = require("../lang/lang_typing_print.bs.js");

function get_func_types_as_js(defs) {
  return Js_dict.fromList(Plate$BwaxAdmin.List.keep_map((function (param) {
                    var match = param[0];
                    if (match.tag === /* Def_fun */1) {
                      return /* tuple */[
                              match[0],
                              Lang_typing_base$BwaxAdmin.completely_shrink(param[1])
                            ];
                    }
                    
                  }), defs));
}

function get_param_types(t) {
  if (typeof t === "number" || t.tag || t[0] !== "Definition") {
    return /* [] */0;
  } else {
    var match = t[1];
    if (match && !match[1]) {
      var gather = function (_acc, _ret) {
        while(true) {
          var ret = _ret;
          var acc = _acc;
          if (typeof ret === "number" || ret.tag || ret[0] !== "Arrow") {
            return acc;
          } else {
            var match = ret[1];
            if (match) {
              var match$1 = match[1];
              if (match$1 && !match$1[1]) {
                _ret = match$1[0];
                _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    match[0],
                    acc
                  ]);
                continue ;
              } else {
                return acc;
              }
            } else {
              return acc;
            }
          }
        };
      };
      return Plate$BwaxAdmin.List.rev(gather(/* [] */0, match[0]));
    } else {
      return /* [] */0;
    }
  }
}

function get_param_types_as_js(t) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, get_param_types(t));
}

function get_term_name(t) {
  if (typeof t === "number" || t.tag) {
    return ;
  } else {
    return t[0];
  }
}

function is_last_param_model(t) {
  var types = get_param_types(t);
  var last_one = Plate$BwaxAdmin.$$Option.and_then(Plate$BwaxAdmin.List.head, Plate$BwaxAdmin.List.drop(Plate$BwaxAdmin.List.length(types) - 1 | 0, types));
  if (last_one !== undefined) {
    var match = last_one;
    if (typeof match === "number" || match.tag) {
      return false;
    } else {
      var tname = match[0];
      if (tname === "Model") {
        return true;
      } else {
        return Plate$BwaxAdmin.Str.ends_with(".Model", tname);
      }
    }
  } else {
    return false;
  }
}

function get_param_types_without_last_one(t) {
  var types = get_param_types(t);
  return Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.List.take(Plate$BwaxAdmin.List.length(types) - 1 | 0, types));
}

function get_param_types_without_last_one_as_js(t) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, get_param_types_without_last_one(t));
}

function get_fragment_names_as_js(names_arr) {
  var partial_arg = Plate$BwaxAdmin.Str.starts_with;
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.unique(Plate$BwaxAdmin.List.map((function (name) {
                        return Plate$BwaxAdmin.Str.drop_left(3, name);
                      }), Plate$BwaxAdmin.List.filter((function (param) {
                              return partial_arg("Fr_", param);
                            }))(Plate$BwaxAdmin.List.keep_map((function (name) {
                                return Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.Str.split(".", name));
                              }), Curry._1(Plate$BwaxAdmin.List.from_array, names_arr))))));
}

function get_fragment_function_sigs_as_js(dts, external_usages) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (param) {
                    return /* tuple */[
                            param[0],
                            Lang_typing_print$BwaxAdmin.string_of_scheme(dts, Lang_typing_base$BwaxAdmin.simplify_vars(param[1]))
                          ];
                  }), Plate$BwaxAdmin.List.filter((function (param) {
                          return Plate$BwaxAdmin.Str.starts_with("Fr_", param[0]);
                        }))(Curry._1(Plate$BwaxAdmin.List.from_array, external_usages))));
}

function get_tyenv_as_js(dts, tyenv) {
  return Dict$BwaxAdmin.$$String.to_array(Dict$BwaxAdmin.$$String.keep_map((function (k, param) {
                    if (param[1]) {
                      return Lang_typing_print$BwaxAdmin.string_of_scheme(dts, Lang_typing_base$BwaxAdmin.simplify_vars(param[0]));
                    }
                    
                  }), tyenv));
}

function build_dependency_graph(deps) {
  var deps_list = Curry._1(Plate$BwaxAdmin.List.from_array, deps);
  var graph_with_only_vertices = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var name = param[0];
          return Graph$BwaxAdmin.set_vertex(name, name, acc);
        }), Graph$BwaxAdmin.$$null(true), deps_list);
  var edges = Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map((function (param) {
              var name = param[0];
              return Plate$BwaxAdmin.List.map((function (to_key) {
                            return /* tuple */[
                                    name,
                                    to_key
                                  ];
                          }), Curry._1(Plate$BwaxAdmin.List.from_array, param[1]));
            }), deps_list));
  return Plate$BwaxAdmin.List.foldl((function (acc, param) {
                return Graph$BwaxAdmin.add_edge(param[0], param[1], acc);
              }), graph_with_only_vertices, edges);
}

function build_fragment_graph(fragments) {
  var fragment_list = Curry._1(Plate$BwaxAdmin.List.from_array, fragments);
  var graph_with_only_vertices = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          return Graph$BwaxAdmin.set_vertex(param[0], param[1], acc);
        }), Graph$BwaxAdmin.$$null(true), fragment_list);
  var edges = Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map((function (param) {
              var name = param[0];
              return Plate$BwaxAdmin.List.map((function (from_key) {
                            return /* tuple */[
                                    from_key,
                                    name
                                  ];
                          }), Curry._1(Plate$BwaxAdmin.List.from_array, param[2]));
            }), fragment_list));
  return Plate$BwaxAdmin.List.foldl((function (acc, param) {
                return Graph$BwaxAdmin.add_edge(param[0], param[1], acc);
              }), graph_with_only_vertices, edges);
}

function top_sort_as_js(graph) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, Graph$BwaxAdmin.linearize(graph));
}

function explore_graph_as_js(fragment_graph, key) {
  var deps = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var match = Graph$BwaxAdmin.get_vertex(key, fragment_graph);
  if (match !== undefined) {
    Graph$BwaxAdmin.explore((function (param, param$1) {
            return /* () */0;
          }), (function (k, param) {
            deps[0] = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                k,
                deps[0]
              ]);
            return /* () */0;
          }), fragment_graph, Set$BwaxAdmin.$$String.empty, key, match);
    return Curry._1(Plate$BwaxAdmin.List.to_array, deps[0]);
  } else {
    return /* array */[];
  }
}

function print_tenv(tenv) {
  return Plate$BwaxAdmin.List.for_each((function (param) {
                console.log(param[0], ": ", Lang_typing_print$BwaxAdmin.string_of_scheme(/* [] */0, param[1]));
                return /* () */0;
              }), Dict$BwaxAdmin.$$String.to_list(tenv));
}

function pack_type_metadata(type_name, required, multivalued, paged, options) {
  return Json$BwaxAdmin.insert_object("options", Js_dict.fromList(options), Json$BwaxAdmin.insert_object("paged", paged, Json$BwaxAdmin.insert_object("multivalued", multivalued, Json$BwaxAdmin.insert_object("required", required, Json$BwaxAdmin.create_object("type", type_name)))));
}

function to_type_metadata(entity_dict, data_type_dict, dts, original_t) {
  var t = Lang_typing_base$BwaxAdmin.completely_shrink(original_t);
  var _param = /* tuple */[
    undefined,
    undefined,
    undefined
  ];
  var _t = t;
  while(true) {
    var param = _param;
    var t$1 = _t;
    var m_paged = param[2];
    var m_multivalued = param[1];
    var m_required = param[0];
    var packup = (function(m_required,m_multivalued,m_paged){
    return function packup(type_name, options) {
      var required = Plate$BwaxAdmin.$$Option.with_default(true, m_required);
      var multivalued = Plate$BwaxAdmin.$$Option.with_default(false, m_multivalued);
      var paged = Plate$BwaxAdmin.$$Option.with_default(false, m_paged);
      return pack_type_metadata(type_name, required, multivalued, paged, options);
    }
    }(m_required,m_multivalued,m_paged));
    if (typeof t$1 === "number") {
      return packup("JSON", /* [] */0);
    } else {
      switch (t$1.tag | 0) {
        case /* Term */0 :
            var tname = t$1[0];
            switch (tname) {
              case "Bool" :
                  return packup("Boolean", /* [] */0);
              case "Criteria" :
                  var match = t$1[1];
                  if (match) {
                    var match$1 = match[0];
                    if (typeof match$1 !== "number" && match$1.tag === /* Var */2) {
                      var match$2 = match$1[0][/* var_type */0];
                      if (typeof match$2 !== "number" && match$2.tag === /* Record_writeonly */4 && !match[1]) {
                        var prefix = "Criteria_";
                        var match$3 = Base_query_types$BwaxAdmin.check_prefixed_entity_name(entity_dict, match$2[0], prefix);
                        if (match$3 !== undefined) {
                          return packup(prefix + match$3[/* name */0], /* [] */0);
                        } else {
                          return packup("JSON", /* [] */0);
                        }
                      }
                      
                    }
                    
                  }
                  break;
              case "Date" :
                  return packup("DateTime", /* [] */0);
              case "Element" :
              case "Element.Element" :
                  return packup("Element", /* [] */0);
              case "File" :
                  return packup("File", /* [] */0);
              case "Float" :
                  return packup("Number", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "decimals",
                                  2
                                ],
                                /* [] */0
                              ]));
              case "Html" :
              case "Html.Html" :
                  return packup("Html", /* [] */0);
              case "Image" :
                  return packup("Image", /* [] */0);
              case "Int" :
                  return packup("Integer", /* [] */0);
              case "List" :
                  var match$4 = t$1[1];
                  if (match$4 && !match$4[1]) {
                    _t = match$4[0];
                    _param = /* tuple */[
                      m_required,
                      true,
                      m_paged
                    ];
                    continue ;
                  }
                  break;
              case "Maybe" :
                  var match$5 = t$1[1];
                  if (match$5 && !match$5[1]) {
                    _t = match$5[0];
                    _param = /* tuple */[
                      false,
                      m_multivalued,
                      m_paged
                    ];
                    continue ;
                  }
                  break;
              case "PagedList" :
                  var match$6 = t$1[1];
                  if (match$6 && !match$6[1]) {
                    _t = match$6[0];
                    _param = /* tuple */[
                      m_required,
                      true,
                      true
                    ];
                    continue ;
                  }
                  break;
              case "RichText" :
                  return packup("RichText", /* [] */0);
              case "String" :
                  return packup("Text", /* [] */0);
              case "Unit" :
                  if (!t$1[1]) {
                    return packup("UNIT", /* [] */0);
                  }
                  break;
              default:
                
            }
            var alias_to_entity = function (tname) {
              var match = Plate$BwaxAdmin.List.assoc(tname, dts);
              if (match !== undefined) {
                var match$1 = match;
                if (typeof match$1 === "number" || match$1.tag !== /* Alias */1) {
                  return ;
                } else {
                  var match$2 = match$1[0][1];
                  if (typeof match$2 === "number" || match$2.tag !== /* Var */2) {
                    return ;
                  } else {
                    var match$3 = match$2[0][/* var_type */0];
                    if (typeof match$3 === "number" || match$3.tag !== /* Record_readonly */1) {
                      return ;
                    } else {
                      var target_tname = match$3[0];
                      return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.hit((function (param) {
                                        return Base_query_types$BwaxAdmin.check_prefixed_entity_name(entity_dict, target_tname, param);
                                      }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        "E_",
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            "PE_",
                                            /* [] */0
                                          ])
                                      ])));
                    }
                  }
                }
              }
              
            };
            var match$7 = alias_to_entity(tname);
            if (match$7 !== undefined) {
              return packup("Link", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "entity",
                              match$7[/* name */0]
                            ],
                            /* [] */0
                          ]));
            } else {
              var match$8 = Plate$BwaxAdmin.List.hit((function(tname){
                  return function (param) {
                    return Base_query_types$BwaxAdmin.check_prefixed_data_type_name(data_type_dict, tname, param);
                  }
                  }(tname)), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "DT_",
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          "Input_",
                          /* [] */0
                        ])
                    ]));
              if (match$8 !== undefined) {
                return packup(match$8[1][/* key */1], /* [] */0);
              } else {
                return packup("JSON", /* [] */0);
              }
            }
            break;
        case /* Term_record */1 :
            return packup("JSON", /* [] */0);
        case /* Var */2 :
            var match$9 = t$1[0][/* var_type */0];
            if (typeof match$9 === "number") {
              if (match$9 === /* Number */1) {
                return packup("Integer", /* [] */0);
              } else {
                return packup("JSON", /* [] */0);
              }
            } else {
              switch (match$9.tag | 0) {
                case /* Recordvar */0 :
                    return packup("JSON", /* [] */0);
                case /* Record_readonly */1 :
                    var tname$1 = match$9[0];
                    var match$10 = Plate$BwaxAdmin.List.hit((function(tname$1){
                        return function (param) {
                          return Base_query_types$BwaxAdmin.check_prefixed_entity_name(entity_dict, tname$1, param);
                        }
                        }(tname$1)), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "E_",
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                "PE_",
                                /* [] */0
                              ])
                          ]));
                    if (match$10 !== undefined) {
                      return packup("Link", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "entity",
                                      match$10[1][/* name */0]
                                    ],
                                    /* [] */0
                                  ]));
                    } else {
                      return packup("JSON", /* [] */0);
                    }
                case /* Record_writeonly */4 :
                case /* Record_writeonly_optional */7 :
                    break;
                default:
                  return packup("JSON", /* [] */0);
              }
            }
            var target_tname = match$9[0];
            var match$11 = Plate$BwaxAdmin.List.hit((function(target_tname){
                return function (param) {
                  return Base_query_types$BwaxAdmin.check_prefixed_entity_name(entity_dict, target_tname, param);
                }
                }(target_tname)), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "Sorting_",
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "Adding_",
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "Updating_",
                            /* [] */0
                          ])
                      ])
                  ]));
            if (match$11 !== undefined) {
              var match$12 = match$11;
              return packup(match$12[0] + match$12[1][/* name */0], /* [] */0);
            } else {
              var match$13 = Plate$BwaxAdmin.List.hit((function(target_tname){
                  return function (param) {
                    return Base_query_types$BwaxAdmin.check_prefixed_data_type_name(data_type_dict, target_tname, param);
                  }
                  }(target_tname)), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "DT_",
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          "Input_",
                          /* [] */0
                        ])
                    ]));
              if (match$13 !== undefined) {
                return packup(match$13[1][/* key */1], /* [] */0);
              } else {
                return packup("JSON", /* [] */0);
              }
            }
            break;
        
      }
    }
  };
}

function type_meta_to_term(tm) {
  var type_name = tm.type;
  var required = Plate$BwaxAdmin.$$Option.with_default(true, tm.required);
  var multivalued = Plate$BwaxAdmin.$$Option.with_default(false, tm.multivalued);
  var paged = Plate$BwaxAdmin.$$Option.with_default(false, tm.paged);
  var options = Plate$BwaxAdmin.$$Option.with_default(Json$BwaxAdmin.json_object(/* [] */0), Caml_option.nullable_to_opt(tm.options));
  var base_term;
  switch (type_name) {
    case "Boolean" :
        base_term = Lang_typing_base$BwaxAdmin.name_term("Bool");
        break;
    case "Element" :
    case "Element.Element" :
        base_term = Lang_typing_base$BwaxAdmin.wrap_term("Element.Element", Lang_typing_base$BwaxAdmin.any(0));
        break;
    case "Html" :
    case "Html.Html" :
        base_term = Lang_typing_base$BwaxAdmin.wrap_term("Html.Html", Lang_typing_base$BwaxAdmin.any(0));
        break;
    case "File" :
    case "Image" :
        base_term = Lang_typing_base$BwaxAdmin.name_term(type_name);
        break;
    case "JSON" :
        base_term = Lang_typing_base$BwaxAdmin.name_term("JSON");
        break;
    case "Msg" :
        base_term = Lang_typing_base$BwaxAdmin.name_term("Msg");
        break;
    case "Number" :
        var decimals = Plate$BwaxAdmin.$$Option.with_default(0, Plate$BwaxAdmin.$$Option.map((function (prim) {
                    return prim | 0;
                  }), Json$BwaxAdmin.get_value("decimals", Js_json.decodeNumber, options)));
        base_term = decimals === 0 ? Lang_typing_base$BwaxAdmin.name_term("Int") : Lang_typing_base$BwaxAdmin.name_term("Float");
        break;
    case "StringMsg" :
        base_term = /* Term */Caml_chrome_debugger.variant("Term", 0, [
            "Arrow",
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "String",
                    /* [] */0
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* Term */Caml_chrome_debugger.variant("Term", 0, [
                        "Msg",
                        /* [] */0
                      ]),
                    /* [] */0
                  ])
              ])
          ]);
        break;
    case "ShortText" :
    case "Text" :
        base_term = Lang_typing_base$BwaxAdmin.name_term("String");
        break;
    default:
      base_term = /* Unknown */1;
  }
  var handle_multivalued = function (t) {
    if (multivalued) {
      return Lang_typing_base$BwaxAdmin.wrap_term("List", t);
    } else {
      return t;
    }
  };
  var handle_paged = function (t) {
    if (paged) {
      return Lang_typing_base$BwaxAdmin.wrap_term("PagedList", t);
    } else {
      return t;
    }
  };
  var handled_required = function (t) {
    if (required) {
      return t;
    } else {
      return Lang_typing_base$BwaxAdmin.wrap_term("Maybe", t);
    }
  };
  if (typeof base_term === "number" && base_term !== 0) {
    return ;
  } else {
    return handled_required(handle_paged(handle_multivalued(base_term)));
  }
}

function get_view_param_types(t) {
  if (typeof t === "number" || t.tag || t[0] !== "Definition") {
    return /* [] */0;
  } else {
    var match = t[1];
    if (match) {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "Arrow") {
        return /* [] */0;
      } else {
        var match$2 = match$1[1];
        if (match$2) {
          var match$3 = match$2[1];
          if (match$3 && !(match$3[1] || match[1])) {
            var rt = match$3[0];
            if (typeof rt === "number" || rt.tag || rt[0] !== "Arrow") {
              return /* [] */0;
            } else {
              var match$4 = rt[1];
              if (match$4) {
                var match$5 = match$4[1];
                if (match$5 && !match$5[1]) {
                  var pt = match$4[0];
                  if (typeof pt === "number" || pt.tag || pt[0] !== "Tuple") {
                    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              pt,
                              /* [] */0
                            ]);
                  } else {
                    return pt[1];
                  }
                } else {
                  return /* [] */0;
                }
              } else {
                return /* [] */0;
              }
            }
          } else {
            return /* [] */0;
          }
        } else {
          return /* [] */0;
        }
      }
    } else {
      return /* [] */0;
    }
  }
}

function get_view_param_types_as_js(t) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, get_view_param_types(t));
}

function get_interface_param_types(t) {
  if (typeof t === "number" || t.tag || t[0] !== "Definition") {
    return /* [] */0;
  } else {
    var match = t[1];
    if (match) {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "Arrow") {
        return /* [] */0;
      } else {
        var match$2 = match$1[1];
        if (match$2) {
          var match$3 = match$2[1];
          if (match$3 && !(match$3[1] || match[1])) {
            var rt = match$3[0];
            var pt = match$2[0];
            if (typeof rt === "number" || rt.tag || rt[0] !== "Arrow") {
              return /* [] */0;
            } else if (typeof pt === "number" || pt.tag || pt[0] !== "Tuple") {
              return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        pt,
                        /* [] */0
                      ]);
            } else {
              return pt[1];
            }
          } else {
            return /* [] */0;
          }
        } else {
          return /* [] */0;
        }
      }
    } else {
      return /* [] */0;
    }
  }
}

function is_interface_targeting_record(t) {
  if (typeof t === "number" || t.tag || t[0] !== "Definition") {
    return false;
  } else {
    var match = t[1];
    if (match) {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "Arrow") {
        return false;
      } else {
        var match$2 = match$1[1];
        if (match$2) {
          var match$3 = match$2[1];
          if (match$3 && !(match$3[1] || match[1])) {
            var rt = match$3[0];
            var pt = match$2[0];
            var context_t;
            if (typeof rt === "number" || rt.tag || rt[0] !== "Arrow") {
              context_t = pt;
            } else {
              var match$4 = rt[1];
              if (match$4) {
                var match$5 = match$4[1];
                context_t = match$5 && !match$5[1] ? match$4[0] : pt;
              } else {
                context_t = pt;
              }
            }
            if (typeof context_t === "number" || context_t.tag || context_t[0] !== "Tuple") {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
}

function get_interface_param_types_as_js(t) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, get_interface_param_types(t));
}

function get_interface_return_types(t) {
  if (typeof t === "number" || t.tag || t[0] !== "Definition") {
    return /* [] */0;
  } else {
    var match = t[1];
    if (match && !match[1]) {
      var _ret = match[0];
      while(true) {
        var ret = _ret;
        if (typeof ret !== "number" && !ret.tag) {
          var exit = 0;
          switch (ret[0]) {
            case "Arrow" :
            case "Result" :
                exit = 2;
                break;
            default:
              
          }
          if (exit === 2) {
            var match$1 = ret[1];
            if (match$1) {
              var match$2 = match$1[1];
              if (match$2 && !match$2[1]) {
                _ret = match$2[0];
                continue ;
              }
              
            }
            
          }
          
        }
        if (typeof ret === "number" || ret.tag || ret[0] !== "Tuple") {
          return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    ret,
                    /* [] */0
                  ]);
        } else {
          return ret[1];
        }
      };
    } else {
      return /* [] */0;
    }
  }
}

function get_interface_return_types_as_js(t) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, get_interface_return_types(t));
}

var entity_name_for_normalized_name = Base_query_types$BwaxAdmin.entity_name_for_normalized_name;

exports.get_func_types_as_js = get_func_types_as_js;
exports.get_param_types = get_param_types;
exports.get_param_types_as_js = get_param_types_as_js;
exports.get_term_name = get_term_name;
exports.is_last_param_model = is_last_param_model;
exports.get_param_types_without_last_one = get_param_types_without_last_one;
exports.get_param_types_without_last_one_as_js = get_param_types_without_last_one_as_js;
exports.get_fragment_names_as_js = get_fragment_names_as_js;
exports.get_fragment_function_sigs_as_js = get_fragment_function_sigs_as_js;
exports.get_tyenv_as_js = get_tyenv_as_js;
exports.build_dependency_graph = build_dependency_graph;
exports.build_fragment_graph = build_fragment_graph;
exports.top_sort_as_js = top_sort_as_js;
exports.explore_graph_as_js = explore_graph_as_js;
exports.print_tenv = print_tenv;
exports.pack_type_metadata = pack_type_metadata;
exports.to_type_metadata = to_type_metadata;
exports.type_meta_to_term = type_meta_to_term;
exports.get_view_param_types = get_view_param_types;
exports.get_view_param_types_as_js = get_view_param_types_as_js;
exports.get_interface_param_types = get_interface_param_types;
exports.is_interface_targeting_record = is_interface_targeting_record;
exports.get_interface_param_types_as_js = get_interface_param_types_as_js;
exports.get_interface_return_types = get_interface_return_types;
exports.get_interface_return_types_as_js = get_interface_return_types_as_js;
exports.entity_name_for_normalized_name = entity_name_for_normalized_name;
/* Set-BwaxAdmin Not a pure module */
