// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Exec_helper = require("./exec_helper");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Query_commands$BwaxAdmin = require("../query/query_commands.bs.js");
var Runtime_common$BwaxAdmin = require("./mod/runtime_common.bs.js");
var Runtime_mod_cmd$BwaxAdmin = require("./mod/runtime/runtime_mod_cmd.bs.js");
var Runtime_mod_cmdm$BwaxAdmin = require("./mod/runtime/runtime_mod_cmdm.bs.js");
var Runtime_mod_date$BwaxAdmin = require("./mod/runtime/runtime_mod_date.bs.js");
var Runtime_mod_timer$BwaxAdmin = require("./mod/runtime/runtime_mod_timer.bs.js");
var Command_m_executor$BwaxAdmin = require("./command_m_executor.bs.js");
var Runtime_mod_random$BwaxAdmin = require("./mod/runtime/runtime_mod_random.bs.js");

function invalid_args(name) {
  throw [
        Runtime_common$BwaxAdmin.Runtime_exn,
        name + ": Invalid Arguments"
      ];
}

function handle_builtin_command(context, param, on_trunk_msgs, _on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || !(cmd.tag === /* V_tagged */6 && cmd[0] === "Cmd_none")) {
    return false;
  } else {
    Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
    return true;
  }
}

function execute_in_parallel(tasks, on_trunk_msgs, on_branch_msgs) {
  var rs_ref = /* record */Caml_chrome_debugger.record(["contents"], [Plate$BwaxAdmin.List.fill(Plate$BwaxAdmin.List.length(tasks), undefined)]);
  Plate$BwaxAdmin.List.indexed_map((function (i, t) {
          return Curry._2(t, (function (param) {
                        var i$1 = i;
                        var r = param;
                        var rs = rs_ref[0];
                        var nrs = Plate$BwaxAdmin.List.put_at(i$1, r, rs);
                        rs_ref[0] = nrs;
                        if (Plate$BwaxAdmin.List.every(Plate$BwaxAdmin.$$Option.is_some, nrs)) {
                          var all_msgs = Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.keep_map((function (param) {
                                      if (param !== undefined) {
                                        var match = param;
                                        if (match.tag) {
                                          return /* [] */0;
                                        } else {
                                          return match[0];
                                        }
                                      }
                                      
                                    }), nrs));
                          var first_error = Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.keep_map((function (param) {
                                      if (param !== undefined) {
                                        var match = param;
                                        if (match.tag) {
                                          return match[0];
                                        } else {
                                          return ;
                                        }
                                      }
                                      
                                    }), nrs));
                          if (first_error !== undefined) {
                            return Curry._1(on_trunk_msgs, /* Error */Caml_chrome_debugger.variant("Error", 1, [first_error]));
                          } else {
                            return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [all_msgs]));
                          }
                        } else {
                          return /* () */0;
                        }
                      }), on_branch_msgs);
        }), tasks);
  return /* () */0;
}

function get_builtin_command_handlers(param) {
  return Plate$BwaxAdmin.List.flat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    handle_builtin_command,
                    /* [] */0
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Runtime_mod_cmd$BwaxAdmin.command_handlers,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Runtime_mod_cmdm$BwaxAdmin.command_handlers,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Runtime_mod_random$BwaxAdmin.command_handlers,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Runtime_mod_timer$BwaxAdmin.command_handlers,
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Runtime_mod_date$BwaxAdmin.command_handlers,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        Query_commands$BwaxAdmin.command_handlers,
                                        /* [] */0
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ]));
}

function runTasksOneByOne(prim, prim$1) {
  Exec_helper.runTasksOneByOne(prim, prim$1);
  return /* () */0;
}

function execute_command(command_handlers, context, _param, _on_trunk_msgs, _on_branch_msgs) {
  while(true) {
    var param = _param;
    var on_branch_msgs = _on_branch_msgs;
    var on_trunk_msgs = _on_trunk_msgs;
    var inf = param[1];
    var cmd = param[0];
    var try_handlers = (function(cmd,inf,on_trunk_msgs,on_branch_msgs){
    return function try_handlers(_remaining) {
      while(true) {
        var remaining = _remaining;
        if (remaining) {
          var served = Curry._4(remaining[0], context, /* tuple */[
                cmd,
                inf
              ], on_trunk_msgs, on_branch_msgs);
          if (served) {
            return true;
          } else {
            _remaining = remaining[1];
            continue ;
          }
        } else {
          return false;
        }
      };
    }
    }(cmd,inf,on_trunk_msgs,on_branch_msgs));
    var served = try_handlers(command_handlers);
    var run_cmds_one_by_one = (function(on_trunk_msgs,on_branch_msgs){
    return function run_cmds_one_by_one(cmd_tasks) {
      var perform_task = function (cmd_task, callback) {
        return Curry._2(cmd_task, callback, on_branch_msgs);
      };
      var ts = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(perform_task, cmd_tasks));
      Exec_helper.runTasksOneByOne(ts, (function (result_array) {
              var results = Curry._1(Plate$BwaxAdmin.List.from_array, result_array);
              var maybe_error = Plate$BwaxAdmin.List.find((function (param) {
                      if (param.tag) {
                        return true;
                      } else {
                        return false;
                      }
                    }), results);
              if (maybe_error !== undefined) {
                return Curry._1(on_trunk_msgs, maybe_error);
              } else {
                var rs = Plate$BwaxAdmin.List.flat_map((function (param) {
                        if (param.tag) {
                          return /* [] */0;
                        } else {
                          return param[0];
                        }
                      }), results);
                return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [rs]));
              }
            }));
      return /* () */0;
    }
    }(on_trunk_msgs,on_branch_msgs));
    var execute_command_func = function (param, param$1, param$2) {
      return execute_command(command_handlers, context, param, param$1, param$2);
    };
    if (served) {
      return /* () */0;
    } else {
      if (typeof cmd !== "number" && cmd.tag === /* V_tagged */6) {
        switch (cmd[0]) {
          case "CmdM_done" :
              var match = cmd[1];
              if (match) {
                var match$1 = match[1];
                if (match$1) {
                  var match$2 = match$1[1];
                  if (match$2 && !match$2[1]) {
                    var data_tagger = match$1[0];
                    var error_tagger = match[0];
                    var binds = Plate$BwaxAdmin.List.map((function (maybe_binds) {
                            return Plate$BwaxAdmin.$$Option.map((function (param) {
                                          var entity = Query_commands$BwaxAdmin.get_entity_by_tname(context[/* entity_dict */0], param[0]);
                                          return /* tuple */[
                                                  entity[/* name */0],
                                                  param[1]
                                                ];
                                        }), maybe_binds);
                          }), Query_commands$BwaxAdmin.get_all_binds(context[/* dts */2], data_tagger));
                    return Command_m_executor$BwaxAdmin.execute_command_m(context, execute_command_func, binds, match$2[0], (function(on_trunk_msgs,error_tagger,data_tagger){
                              return function (result) {
                                var msg;
                                msg = result.tag ? Lang_eval$BwaxAdmin.apply_value(undefined, error_tagger, result[0], Runtime_common$BwaxAdmin.nt) : Lang_eval$BwaxAdmin.apply_value(undefined, data_tagger, result[0], Runtime_common$BwaxAdmin.nt);
                                return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  msg,
                                                  /* [] */0
                                                ])]));
                              }
                              }(on_trunk_msgs,error_tagger,data_tagger)));
                  }
                  
                }
                
              }
              break;
          case "CmdM_end" :
              var match$3 = cmd[1];
              if (match$3) {
                var match$4 = match$3[1];
                if (match$4 && !match$4[1]) {
                  var error_tagger$1 = match$3[0];
                  return Command_m_executor$BwaxAdmin.execute_command_m(context, execute_command_func, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                undefined,
                                /* [] */0
                              ]), match$4[0], (function(on_trunk_msgs,error_tagger$1){
                            return function (result) {
                              if (result.tag) {
                                var msg = Lang_eval$BwaxAdmin.apply_value(undefined, error_tagger$1, result[0], Runtime_common$BwaxAdmin.nt);
                                return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  msg,
                                                  /* [] */0
                                                ])]));
                              } else {
                                return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
                              }
                            }
                            }(on_trunk_msgs,error_tagger$1)));
                }
                
              }
              break;
          case "Cmd_batch" :
              var cmds = cmd[1];
              if (Plate$BwaxAdmin.List.length(cmds) === 0) {
                return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
              } else {
                var collected_queries = Plate$BwaxAdmin.List.keep_map((function (param) {
                        return Query_commands$BwaxAdmin.get_query_from_cmd(context, param);
                      }), cmds);
                var match$5 = Plate$BwaxAdmin.List.unzip(collected_queries);
                var transforms = match$5[1];
                var queries = match$5[0];
                var batch_query_task = Plate$BwaxAdmin.List.length(queries) > 0 ? (function(queries,transforms){
                  return function (handle_trunk_msgs, _on_branch_msgs) {
                    return Query_commands$BwaxAdmin.perform_batch_query(context, queries, (function (param) {
                                  if (param.tag) {
                                    return Curry._1(handle_trunk_msgs, /* Error */Caml_chrome_debugger.variant("Error", 1, [param[0]]));
                                  } else {
                                    var msgs = Plate$BwaxAdmin.List.map((function (param) {
                                            return Curry._1(param[0], param[1]);
                                          }), Plate$BwaxAdmin.List.zip(/* tuple */[
                                              transforms,
                                              param[0]
                                            ]));
                                    return Curry._1(handle_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [msgs]));
                                  }
                                }));
                  }
                  }(queries,transforms)) : undefined;
                var otherCmds = Plate$BwaxAdmin.List.filter((function (c) {
                          return Curry._1(Plate$BwaxAdmin.$$Option.is_none, Query_commands$BwaxAdmin.get_query_from_cmd(context, c));
                        }))(cmds);
                return run_cmds_one_by_one(Pervasives.$at(Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      batch_query_task,
                                      /* [] */0
                                    ])), Plate$BwaxAdmin.List.map((function (cmd) {
                                      return (function (param, param$1) {
                                          return execute_command(command_handlers, context, cmd, param, param$1);
                                        });
                                    }), otherCmds)));
              }
          case "Cmd_lifted" :
              var match$6 = cmd[1];
              if (match$6) {
                var match$7 = match$6[1];
                if (match$7 && !match$7[1]) {
                  var f = match$6[0];
                  _on_branch_msgs = (function(on_trunk_msgs,f){
                  return function (r) {
                    var transform = function (msg) {
                      return Lang_eval$BwaxAdmin.apply_value(undefined, f, msg, Runtime_common$BwaxAdmin.nt);
                    };
                    var tmp;
                    if (r.tag) {
                      tmp = /* Error */Caml_chrome_debugger.variant("Error", 1, [r[0]]);
                    } else {
                      var new_msgs = Plate$BwaxAdmin.List.map(transform, r[0]);
                      tmp = /* Ok */Caml_chrome_debugger.variant("Ok", 0, [new_msgs]);
                    }
                    return Curry._1(on_trunk_msgs, tmp);
                  }
                  }(on_trunk_msgs,f));
                  _on_trunk_msgs = (function(on_trunk_msgs,f){
                  return function (r) {
                    var transform = function (msg) {
                      return Lang_eval$BwaxAdmin.apply_value(undefined, f, msg, Runtime_common$BwaxAdmin.nt);
                    };
                    var tmp;
                    if (r.tag) {
                      tmp = /* Error */Caml_chrome_debugger.variant("Error", 1, [r[0]]);
                    } else {
                      var new_msgs = Plate$BwaxAdmin.List.map(transform, r[0]);
                      tmp = /* Ok */Caml_chrome_debugger.variant("Ok", 0, [new_msgs]);
                    }
                    return Curry._1(on_trunk_msgs, tmp);
                  }
                  }(on_trunk_msgs,f));
                  _param = match$7[0];
                  continue ;
                }
                
              }
              break;
          case "Cmd_serial" :
              return run_cmds_one_by_one(Plate$BwaxAdmin.List.map((function (cmd) {
                                return (function (param, param$1) {
                                    return execute_command(command_handlers, context, cmd, param, param$1);
                                  });
                              }), cmd[1]));
          default:
            
        }
      }
      throw [
            Runtime_common$BwaxAdmin.Runtime_exn,
            "Unsupported command: " + Lang_eval$BwaxAdmin.string_of_value(/* tuple */[
                  cmd,
                  inf
                ])
          ];
    }
  };
}

exports.invalid_args = invalid_args;
exports.handle_builtin_command = handle_builtin_command;
exports.execute_in_parallel = execute_in_parallel;
exports.get_builtin_command_handlers = get_builtin_command_handlers;
exports.runTasksOneByOne = runTasksOneByOne;
exports.execute_command = execute_command;
/* ./exec_helper Not a pure module */
