


export function mapListOrNestedList(list, f) {
    return (
        Array.isArray(list[0]) ? 
            list.map(nestedList => nestedList.map(v => f(v))) :
            list.map(v => f(v))
    )
}


export function filterListOrNestedList(list, f) {
    return (
        Array.isArray(list[0]) ? 
            list.map(nestedList => nestedList.filter(v => f(v))) :
            list.filter(v => f(v))
    )
}


export function flattenListOrNestedList(list, f) {
    // flatten the nested list
    return (
        Array.isArray(list[0]) ?
            list.reduce((acc, l) => [...acc, ...l], []) :
            list
    )
}