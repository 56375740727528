// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Input = require("antd/lib/input");
var Button = require("antd/lib/button");
var AntdBinding = require("Client/js/antd-binding");

var Popover = { };

var Popconfirm = { };

var Tooltip = { };

var IconBridge = { };

function Antd$Icon(Props) {
  var type_ = Props.type_;
  var onClick = Props.onClick;
  var onMouseEnter = Props.onMouseEnter;
  var onContextMenu = Props.onContextMenu;
  var className = Props.className;
  return React.createElement(AntdBinding.Icon, {
              type: type_,
              onClick: onClick,
              onMouseEnter: onMouseEnter,
              onContextMenu: onContextMenu,
              className: className
            });
}

var Icon = {
  IconBridge: IconBridge,
  make: Antd$Icon
};

var Bridge = { };

function Antd$Button(Props) {
  var icon = Props.icon;
  var type_ = Props.type_;
  var className = Props.className;
  var disabled = Props.disabled;
  var shape = Props.shape;
  var size = Props.size;
  var style = Props.style;
  var loading = Props.loading;
  var onClick = Props.onClick;
  var onMouseEnter = Props.onMouseEnter;
  var onContextMenu = Props.onContextMenu;
  var ghost = Props.ghost;
  var children = Props.children;
  return React.createElement(Button.default, {
              icon: icon,
              type: type_,
              className: className,
              disabled: disabled,
              shape: shape,
              size: size,
              loading: loading,
              ghost: ghost,
              onClick: onClick,
              onMouseEnter: onMouseEnter,
              onContextMenu: onContextMenu,
              style: style,
              children: Belt_Option.getWithDefault(children, null)
            });
}

var Button$1 = {
  Bridge: Bridge,
  make: Antd$Button
};

var Message = { };

var Item = { };

var Timeline = {
  Item: Item
};

var Item$1 = { };

var Menu = {
  Item: Item$1
};

var Dropdown = { };

var Bridge$1 = { };

function Antd$Input(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var className = Props.className;
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var style = Props.style;
  var _type = Props.type;
  return React.createElement(Input.default, {
              value: value,
              placeholder: placeholder,
              onChange: (function (e) {
                  var target = e.target;
                  return Curry._1(onChange, target.value);
                }),
              className: className,
              disabled: disabled,
              style: style,
              type: _type
            });
}

var Input$1 = {
  Bridge: Bridge$1,
  make: Antd$Input
};

var InputGroup = { };

var TextArea = { };

var Row = { };

var Col = { };

var Modal = { };

var Switch = { };

var RangePicker = { };

exports.Popover = Popover;
exports.Popconfirm = Popconfirm;
exports.Tooltip = Tooltip;
exports.Icon = Icon;
exports.Button = Button$1;
exports.Message = Message;
exports.Timeline = Timeline;
exports.Menu = Menu;
exports.Dropdown = Dropdown;
exports.Input = Input$1;
exports.InputGroup = InputGroup;
exports.TextArea = TextArea;
exports.Row = Row;
exports.Col = Col;
exports.Modal = Modal;
exports.Switch = Switch;
exports.RangePicker = RangePicker;
/* react Not a pure module */
