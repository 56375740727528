
import React, { useState } from 'react';

import RecordFilterInput from '../ui/widgets/input/RecordFilterInput';

// Uncontrolled the record filter value 

export default function RecordFilter(props) {

    const { 
        conditionFields, entityName, contextEntityName, contextRecordId,
        value,

        allDataTypes, allEntities,
        
        onChange,

    } = props;

    return (
        <RecordFilterInput
            params={{
                conditionFields,
                entityName,
                contextEntityName,
                contextRecordId
            }}
            value={value}
            onChange={v => {
                onChange(v)
            }}
            {...{
                allDataTypes, allEntities
            }} />
    )

}


