
import AdminExt from 'bwax-ext/AdminExt'

import { getFieldsFromFieldItems } from './builderUtils'

import React from 'react';

const Input_PosterSetting = require('Client/re/widgets/input/Input_PosterSetting.bs').make

export default function buildFieldInput(
    field, editKey, {
        initialValue, fixedValue, defaultValue, 
        allEntities, allDataTypes, dataEntity, params,
    }
) {

    const customInputWidgets = Object.keys(AdminExt).reduce((acc, key) => {
        const ext = AdminExt[key]

        if (ext && ext.dataTypeWidgets && ext.dataTypeWidgets.inputs) {
            return {
                ...acc,
                ...ext.dataTypeWidgets.inputs
            }
        } else {
            return acc
        }

    }, {})

    const { type, required, name } = field
    const w = {
        ...customInputWidgets,
        ...inputWidgets
    }[type]

    if (!w) {
        const dataType = allDataTypes.find(d => d.key === type)
        //console.log(type, dataType);
        console.warn(name, " input type ", type, " has no widget corresponding")
        if (dataType && dataType.fields && dataType.fields.length > 0) {
            return {
                type_: "Composit Input " + type
            }
        } else {

            console.log("No input for: ", field);

            return {
                type_: "<No input for: `" + type + "`>"
            }
        }

    } else {
        const widget = typeof (w) == 'function' ?
            w(field, { allDataTypes, allEntities, dataEntity }) : { type: w }


        if(typeof(widget) == 'function') {
            return widget
        }

        return {
            ...widget,
            params: {
                ...widget.params,
                editKey,
                initialValue,
                defaultValue,
                required,
                fixedValue,
                ...params
            }
        }

    }

}

function getAttachmentWidget(type, field, dataEntity) {
    const { multivalued, options } = field
    return {
        type: `admin::input::${type}`,
        params: {
            multivalued,
            uploadFor: [
                (dataEntity && dataEntity.key) ? dataEntity.key : 'no-entity',
                field.key
            ].join('-'),
            isPublic: !options ? true : options.public
        }
    }
}



/// fieldType -> inputType ( default )
const inputWidgets = {
    Address: 'Address',
    Boolean: 'admin::input::Switch',
    Date: (field) => {
        const { multivalued } = field
        return {
            type: 'admin::input::DatePicker',
            params: {
                multivalued
            }
        }
    },
    DateTime: (field) => {
        const { multivalued } = field
        return {
            type: 'admin::input::DatePicker',
            params: {
                multivalued,
                format: "YYYY-MM-DD HH:mm",
                showTime: true
            }
        }
    },
    File: (field, { dataEntity }) => {
        return getAttachmentWidget('File', field, dataEntity)
    },
    Image: (field, { dataEntity }) => {
        return getAttachmentWidget('Image', field, dataEntity)
    },
    JSON: 'admin::input::JSONInput',
    JSONB: 'admin::input::JSONInput',
    Link: (field, { allEntities }) => {
        const { options, multivalued } = field
        const entityKey = options.entity
        const entity = allEntities.find(e => e.key === entityKey || e.name === entityKey)

        if (!entity) {
            console.warn(entity, "Entity not found for key:", entityKey)
            return ""
        }

        return {
            type: 'admin::input::RecordSelect',
            params: {
                entityName: entity.name,
                multivalued,
            }
        }
    },
    Number: (field) => {
        const { options, multivalued } = field
        const { decimal } = options || {}

        return {
            type: 'admin::input::Number',
            params: {
                multivalued,
                decimal,
            }
        }

    },

    Integer: (field) => {
        const { multivalued } = field

        return {
            type: 'admin::input::Number',
            params: {
                multivalued,
                decimal: 0,
            }
        }

    },
    
    
    Place: 'Place',
    RichText: (field, { dataEntity },) => {
        return {
            type: 'admin::input::RichText',
            params: {
                uploadFor: [
                    dataEntity.key,
                    field.key
                ].join('-')
            }
        }
    },
    ShortText: (field,  { dataEntity }) => {
        const { multivalued } = field
        return {
            type: 'admin::input::ShortText',
            params: {
                multivalued,
                field, 
                dataEntity,
            }
        }
    },
    Text: (field) => {
        const { multivalued } = field
        return {
            type: "admin::input::Text",
            params: {
                multivalued,
                // autoSize: true,
            }
        }
    },
    Select: (field) => {
        const { options, multivalued } = field
        return {
            type: "admin::input::Select",
            params: {
                options: options.options,
                multivalued,
            }
        }
    },
    /// ... others, TODO 考虑 extension 里的 DataType 的编辑性
    VirtualFieldDef: "admin::Input_JSONCode",
    EventHandlerDef: "admin::Input_JSONCode",

    FilterCondition: (field, { allEntities, allDataTypes }) => {

        // const targetEntity = 
        // console.log("The field is", field);
        const entityKeyOrName = 
            field.options ? field.options.entity : null;


        const specifiedFields = 
            field.options ? field.options.specifiedFields : null;

        const entity = allEntities.find(e => e.name == entityKeyOrName || e.key == entityKeyOrName);

        function getConditionFields () {
            
            let  allFields = [ ...entity.fields, ...entity.virtualFields ].sort((a, b) => {
                // 给 field 排序
                /// 根据 displayWeight 从大到下排列
                const getWeight = f => f.displayWeight || 0
                // 重的在前面
                return getWeight(b) - getWeight(a)
            });

            if (specifiedFields) {

                let fieldItems = specifiedFields.reduce((acc, path) => {

                    const split = path.split("as").map(s => s.trim());

                    if(split.length == 2) {
                        const [ path, name ] = split;
                        return {
                            ...acc,
                            [name]: path
                        }
                    } else if(split.length == 1) {
                        return {
                            ...acc,
                            [path]: path
                        }
                    } else {
                        return acc 
                    };
                }, {})

                allFields = getFieldsFromFieldItems(fieldItems, entity, allEntities, allDataTypes)
                
            }

            const sortableFields = 
                allFields.filter(
                    f => f.filterable 
                ).filter(
                    f =>                 (
                        f.type === 'ShortText' ||
                        f.type === 'Select' ||
                        f.type === 'Link' ||
                        f.type === 'Text'
                    ) || (
                        (f.type === 'Boolean' ||
                            f.type === 'Date' ||
                            f.type === 'DateTime' ||
                            f.type === 'Number') && !f.multivalued
                    )
                );

            return sortableFields
        };


        const conditionFields = getConditionFields();

        return {
            type: "admin::input::FilterConditionInput",
            params: {
                conditionFields,
                entityName: entity.name
            }
        }

    }, 

    // "AuthTypeRolePermission": "admin::input::RolePermissionInput"
    "AuthTypeRolePermission": "admin::Input_RolePermission",



    PosterSetting: (_) => {
        // return {
        //     type: "admin::YQYL::Input_YQYLPoster",
        //     // type: "admin::YQYL::Input_YQYLPoster",
        //     params: {
        //         id: "${id}",
        //         previewAvatar: "${当前用户.头像}",
        //         previewNickname: "${当前用户.昵称}"
        //     }
        // }

        return (value, onChange, params, env) => {

            if(params){
                return (
                    <Input_PosterSetting
                        params={params}
                        value={value}
                        onChange={onChange}
                    />
                )
            } else {
                return null
            }
        }

    }

}
