// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dateformat = require("dateformat");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../../../lib/bwax-ui/re/BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var AttachmentView = require("Client/js/components/AttachmentView");

var AttachmentView$1 = { };

function Display_ExportJob(Props) {
  var params = Props.params;
  var theme = Props.theme;
  var file = params.file;
  var progress = Plate$BwaxAdmin.$$Option.with_default(0, Caml_option.nullable_to_opt(params.progress));
  var createdAt = params.createdAt;
  var status = params.status;
  var errMsg = params.errMsg;
  var desc = params.desc;
  var count = params.count;
  var count$1 = (count == null) ? undefined : Caml_option.some(count);
  var refresh = params.refresh;
  React.useEffect((function () {
          var timerId = setInterval((function (param) {
                  if (status === "Progressing" || status === "Pending") {
                    return Curry._1(refresh, /* () */0);
                  } else {
                    return 0;
                  }
                }), 1000);
          return (function (param) {
                    clearInterval(timerId);
                    return /* () */0;
                  });
        }), /* array */[status]);
  var mainBox;
  if (file == null) {
    switch (status) {
      case "Completed" :
          var partial_arg = "已完成";
          mainBox = (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg, param, param$1, param$2, param$3);
            });
          break;
      case "Failed" :
          var partial_arg$1 = "导出失败: " + (String(errMsg) + "");
          var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                  -481689226,
                  /* R60 */4089868
                ]),
              /* [] */0
            ]);
          mainBox = (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.text(partial_arg$2, partial_arg$1, param, param$1, param$2, param$3);
            });
          break;
      case "Pending" :
          var partial_arg$3 = "正在等待导出";
          var partial_arg$4 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                  -481689226,
                  /* Y60 */4437971
                ]),
              /* [] */0
            ]);
          mainBox = (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.text(partial_arg$4, partial_arg$3, param, param$1, param$2, param$3);
            });
          break;
      case "Progressing" :
          var partial_arg$5 = "正在导出: " + (String(progress) + "%");
          mainBox = (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg$5, param, param$1, param$2, param$3);
            });
          break;
      default:
        mainBox = BaseUI$BwaxAdmin.empty;
    }
  } else {
    mainBox = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.box(/* [] */0, (function (param, param$1, param$2, param$3) {
                      return React.createElement(AttachmentView.default, {
                                  readOnly: true,
                                  attachment: file
                                });
                    }), param, param$1, param$2, param$3);
      });
  }
  var partial_arg$6 = Dateformat(createdAt, "mm-dd HH:MM");
  var partial_arg$7 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
          -481689226,
          /* N70 */3891175
        ]),
      /* [] */0
    ]);
  var timeBox = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.text(partial_arg$7, partial_arg$6, param, param$1, param$2, param$3);
  };
  var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      timeBox,
      /* [] */0
    ]);
  var partial_arg$8 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      mainBox,
      partial_arg_001
    ]);
  var partial_arg$9 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `width */Caml_chrome_debugger.polyVar("width", [
          -899500538,
          /* fill */-1011102077
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* spaceEvenly */-1016061555,
          /* [] */0
        ])
    ]);
  var titleBox = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.row(partial_arg$9, partial_arg$8, param, param$1, param$2, param$3);
  };
  var summaryBox;
  if (status === "Completed" && Curry._1(Plate$BwaxAdmin.$$Option.is_some, count$1)) {
    var partial_arg$10 = "共 " + (String(count$1) + " 条数据");
    var partial_arg$11 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
            -481689226,
            /* N60 */3890952
          ]),
        /* [] */0
      ]);
    summaryBox = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.text(partial_arg$11, partial_arg$10, param, param$1, param$2, param$3);
      });
  } else {
    summaryBox = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.box(/* [] */0, BaseUI$BwaxAdmin.empty, param, param$1, param$2, param$3);
      });
  }
  var descBox;
  if (desc == null) {
    descBox = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.box(/* [] */0, BaseUI$BwaxAdmin.empty, param, param$1, param$2, param$3);
      });
  } else {
    var partial_arg$12 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `textLineClamp */Caml_chrome_debugger.polyVar("textLineClamp", [
            -958002182,
            1
          ]),
        /* [] */0
      ]);
    var partial_arg_000 = function (param, param$1, param$2, param$3) {
      return BaseUI$BwaxAdmin.text(partial_arg$12, desc, param, param$1, param$2, param$3);
    };
    var partial_arg$13 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000,
        /* [] */0
      ]);
    var partial_arg$14 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `width */Caml_chrome_debugger.polyVar("width", [
            -899500538,
            /* fill */-1011102077
          ]),
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                -481689226,
                /* N60 */3890952
              ]),
            /* [] */0
          ])
      ]);
    descBox = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.paragraph(partial_arg$14, partial_arg$13, param, param$1, param$2, param$3);
      });
  }
  var partial_arg_001$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      summaryBox,
      /* [] */0
    ]);
  var partial_arg$15 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      descBox,
      partial_arg_001$1
    ]);
  var partial_arg$16 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* spaceEvenly */-1016061555,
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `width */Caml_chrome_debugger.polyVar("width", [
              -899500538,
              /* fill */-1011102077
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                  297481091,
                  /* `rem */Caml_chrome_debugger.polyVar("rem", [
                      5691738,
                      1.0
                    ])
                ]),
              /* [] */0
            ])
        ])
    ]);
  var infoBox = function (param, param$1, param$2, param$3) {
    return BaseUI$BwaxAdmin.row(partial_arg$16, partial_arg$15, param, param$1, param$2, param$3);
  };
  return BaseUI$BwaxAdmin.column(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* fill */-1011102077
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                        297481091,
                        /* `rem */Caml_chrome_debugger.polyVar("rem", [
                            5691738,
                            0.25
                          ])
                      ]),
                    /* [] */0
                  ])
              ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                titleBox,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    infoBox,
                    /* [] */0
                  ])
              ]), theme, /* column */-963948842, /* [] */0, "no-key");
}

var make = Display_ExportJob;

exports.AttachmentView = AttachmentView$1;
exports.make = make;
/* react Not a pure module */
