// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var WxApiHelper = require("./WxApiHelper");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function getWx(prim) {
  return WxApiHelper.getWx();
}

function config(config$1, debug) {
  var c = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "debug",
            debug
          ],
          Belt_List.fromArray(Js_dict.entries(config$1))
        ]));
  var match = WxApiHelper.getWx();
  if (match !== undefined) {
    return Caml_option.valFromOption(match).config(c);
  } else {
    return /* () */0;
  }
}

function ready(onReady) {
  var match = WxApiHelper.getWx();
  if (match !== undefined) {
    return Caml_option.valFromOption(match).ready(onReady);
  } else {
    return /* () */0;
  }
}

function error(onError) {
  var match = WxApiHelper.getWx();
  if (match !== undefined) {
    return Caml_option.valFromOption(match).error(onError);
  } else {
    return /* () */0;
  }
}

function chooseWXPay(jsPayArgs, onSuccess) {
  var match = WxApiHelper.getWx();
  if (match !== undefined) {
    return Caml_option.valFromOption(match).chooseWXPay(Object.assign(jsPayArgs, {
                    success: (function (param) {
                        console.log("Success");
                        return Curry._1(onSuccess, /* () */0);
                      }),
                    fail: (function (err) {
                        console.log("Fail", err);
                        return /* () */0;
                      })
                  }));
  } else {
    return /* () */0;
  }
}

exports.getWx = getWx;
exports.config = config;
exports.ready = ready;
exports.error = error;
exports.chooseWXPay = chooseWXPay;
/* ./WxApiHelper Not a pure module */
