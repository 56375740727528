
import React from 'react';

import parser from 'cron-parser';

import dayjs from 'dayjs';

import './CrontabSamples.less'

export function CrontabSamples({ value }) {

    if (typeof (value) == "string" && value.split(" ").length >= 5) {

        try {
            const interval = parser.parseExpression(value);

            let dates = [];
            for (let i = 0; i < 5; i++) {
                dates.push(interval.next());
            }

            return (
                <div className="crontab-samples">
                    {dates.map(d => {
                        return <div key={d.toString()}>{dayjs(d).format("YYYY-MM-DD HH:mm:ss")}</div>
                    })}
                </div>
            )
        } catch (err) {
            return <div className="crontab-samples error">Error: {err.message}</div>
        }


    } else {
        return ""
    }

}