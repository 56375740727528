
import Message from 'Client/js/ui/Message';

import admin_widget_helper from 'Client/ml/ui/helpers/admin_widget_helper.bs'

export default function setupOperationHandlers ({bwax, entity, reload, route_to}) {

    const entityName = entity.name;
    const OperationHandlers = {
        "GotoDetail": ([config], { id }) => {
            const { tab, url: getUrl } = config;
            let url = "/entities/" + entity.key + "/records/" + id;
            if(getUrl) {
                url = admin_widget_helper.apply_string_to_string(getUrl, id);
            } else if (tab) {
                // url 和 tab 不兼容
                url = url + "/" + tab;
            }

            const fullUrl = "/app/" + bwax.applicationCode + url;

            // goto
            if (route_to) {
                route_to(fullUrl);
                return true;
            } else {
                console.log("Route to", fullUrl);
                return false;
            }
        },
        "UpdateRecord": async ([[entityName, formData]], { id }) => {

            const mutationObj = {
                entityName,
                formData,
                id,
                fieldPaths: []
            }
            const [result, error] = await bwax.update(mutationObj);
            // TOOD error handling.
            if(!error) {
                Message.success("操作成功");
                reload();
                return true
            } else {
                Message.error(error);
                return false
            }

        },

        "DeleteRecord": async ([], { id }) => {

            const mutationObj = {
                entityName,
                id
            }
            const [result, error] = await bwax.delete(mutationObj);
            // TOOD error handling.

            // TOOD error handling.
            if(!error) {
                Message.success("操作成功");
                reload();
                return true
            } else {
                Message.error(error);
                return false
            }

        },

        "Custom": async ([[entityName, interfaceName, args]], { id }) => {

            const mutationObj = {
                entityName,
                interfaceName,
                args,
                id,
                outputFieldPaths: []
            }
            const [result, error] = await bwax.customMutation(mutationObj);

            // TOOD error handling.
            if(!error) {
                Message.success("操作成功");
                reload();
                return true
            } else {

                if(error){
                    Message.error(error);
                }

                return false
            }

        }
    }
    return OperationHandlers;
}