import React from 'react'
import CommonLoading from './CommonLoading'

import "./ButtonLoading.less"

export default function Button() {
    return (
        <div className="loading-button-box">
            <CommonLoading width={50} height={18} />
        </div>
    )
}
