
import React, { useState } from 'react'
import CodeCanvas from './visual/CodeCanavs';

import PageVisualEditor from './visual/PageVisualEditor';

const defaultConfig = {
    toEditorValue: ({ visual}) => visual,
    fromEditorValue: v => ({ visual: v }),
}

export const editors = {
    "page": PageVisualEditor,
    "data-interface": CodeCanvas,
    "virtual-field": CodeCanvas,
}

export default function VisualEditor(props) {

    const { item, runtimeProfile, value, onChange, saveValue, typingEnv, codeEditorCommands } = props;

    // 不同的 item / runtimeProfile 有不同的 editor

    // 现在只展示 Page Canvas
    const Editor = editors[runtimeProfile.name];

    if(Editor) {
        return (
            <Editor {...{ value, onChange, saveValue, typingEnv, codeEditorCommands }} />
        )            
    } else {
        return (
            <div style={{ padding: "1rem"}}>no visual editor for {runtimeProfile.name}</div>
        )
    }


}
