
import React, { useState } from 'react'

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import Modal from 'Client/ml/ui/components/Modal';
import Button from 'Client/ml/ui/components/Button';

import { getFieldInput } from 'Client/js/builders/input/fieldInputs';

import './ExcelToFormModal.less';

import Message from 'Client/js/ui/Message';

export default function ExcelToFormModal(props) {

    const { visible, setVisible, onFormAdded, facade } = props;

    // prepare
    const entityName = "表单导入";
    const dataEntity = facade.entities.find(e => e.name == entityName);
    
    const initialValues = {};
    const fixedValues = {};
    const defaultValues = {};

    const fieldsToUse = dataEntity.fields.filter(f => f.initializable);

    const allKeys = fieldsToUse.map(f => f.name);

    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, defaultValues, [], "no-key", false)

    const involvedEditKeys = fieldsToUse.filter(
        f => fixedValues[f.name] === undefined
    ).map(f => f.name)

    const hasDirtyValue = involvedEditKeys.some(key => {
        return dirtyValues[key] !== undefined && dirtyValues[key] !== defaultValues[key]
    });

    // all the require field must be there:
    const requiredFields = fieldsToUse.filter(
        f => f.required
    )
    const requiredEditKeys = requiredFields.map(f => f.name);
    const isMissingRequiredFields = requiredEditKeys.some(key => validated[key] === undefined)

    const noValues = involvedEditKeys.every(key => dirtyValues[key] === undefined)

    const cancelButton = {
        key: "cancel",
        label: (hasDirtyValue ? "撤销" : "关闭"),
        onClick: _ => {
            if (hasDirtyValue) {
                // 撤销要用 reset，
                // 因为 rollback 的实现没有考虑 defaultValues 
                reset(defaultValues, initialValues, fixedValues)
                // rollback(involvedEditKeys);
            } else {
                setVisible(false);
            }
        }
    };

    const confirmButton = {
        key: "confirm",
        label: "导入",
        buttonType: "primary",
        disabled: isMissingRequiredFields || noValues,
        onClick: async _ => {
            if (hasDirtyValue) {
                const formData = dirtyValues;
                console.log(">>>", dirtyValues);

                const mutationObj = {
                    entityName,
                    formData,
                    fieldPaths: ["表单"]
                }

                const [result, error] = await facade.add(mutationObj, {
                    // additionalQueryParams: {
                    //     formId: form.id
                    // }
                });
                clearEditing(involvedEditKeys);

                if(result && result["表单"]) {
                    Message.success("导入成功");
                    if(onFormAdded) {
                        // onFormAdded()
                        onFormAdded(result["表单"])

                    }
                    
                    setVisible(false);
                } else {
                    // 失败？

                    Message.error("导入失败");
                }

            }
        }
    }

    const footer = [cancelButton, confirmButton].map(params => <Button {...params} />)

    return (
        <Modal visible={visible} title="从 Excel 导入表单" footer={footer} destroyOnClose={true}
            onCancel={() => {
                // 如果 dirty 的话，需要提示：
                if (hasDirtyValue) {
                    Modal.confirm({
                        title: '您有未保存的内容',
                        content: '确定要关闭吗？',
                        okText: '关闭',
                        cancelText: '等等',
                        className: "admin--edit-modal-confirm",
                        onOk: () => {
                            rollback(involvedEditKeys);
                            setVisible(false);
                        }
                    });
                } else {
                    setVisible(false)
                }
            }}>
            <div className="lc-excel-to-form">
                { fieldsToUse.map(field => {
                    return (
                        <FieldItem key={field.name} {...{
                            field, validated, editing, updateEditing, facade, dataEntity,
                            fixedValues, initialValues, errors, 
                        }} />
                    )

                })}
            </div>
        </Modal>
    )
}


// 从 addRecordButton 复制过来再简化的版本
function FieldItem ({ 
    field, validated, editing, updateEditing,
    facade, dataEntity, 
    fixedValues = {}, 
    initialValues = {}, 
    errors = {}, 
    queryTarget = "data",
}) {

    const allEntities = facade.entities;
    const allDataTypes = facade.dataTypes;

    const { name, desc, required } = field;
    /// add value, onChange
    const value = fixedValues[name] || editing[name];

    const onChange = v => {
        if (v !== value) {
            updateEditing({ [name]: v })
        }
    }

    function getInput() {

        const editKey = name;

        const ips = {
            initialValue: initialValues[name],
            fixedValue: fixedValues[name],
        };

        let inputWidget = getFieldInput(
            field,
            editKey,
            {
                allDataTypes, allEntities, dataEntity,
                queryTarget
            }
        );


        return inputWidget(
            value,
            onChange,           
            ips,
            validated
        );

    }

    // render input field below:
    const label = name;
    const tip = (desc && desc !== name) ? desc : null;
    const editKey = field.name;

    const input = getInput();

    const error = (errors || {})[editKey]

    return (
        <div className="form-item" key={editKey}>
            {/* <div className={`item-label ${required ? 'item-require' : ''}`}>
                {label}
                <FieldTip tip={tip} />
            </div> */}
            <div className="item-input">
                { input }
                {/* TODO: special error handle */}
                {
                    error ?
                        <p className="error-message-cot">
                            {error}
                        </p> : null
                }
            </div>

        </div>
    )

}
 


