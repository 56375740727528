

import React, { useState, useEffect } from 'react'

import {
    CgAlignTop, CgAlignLeft, CgAlignCenter, CgAlignMiddle, CgAlignRight, CgAlignBottom, CgArrowsExchangeAlt
} from 'react-icons/cg';
import {
    MdAlignHorizontalCenter, MdAlignVerticalCenter, MdLock, MdLockOpen
} from 'react-icons/md'
import {
    AlignLeftIcon, AlignCenterHorizontallyIcon, AlignRightIcon, AlignTopIcon, AlignCenterVerticallyIcon, AlignBottomIcon,
    StretchHorizontallyIcon, StretchVerticallyIcon, SpaceBetweenHorizontallyIcon, SpaceBetweenVerticallyIcon, SpaceEvenlyHorizontallyIcon, SpaceEvenlyVerticallyIcon
} from '@radix-ui/react-icons'

import { Input, Select } from '@arco-design/web-react';
const Option = Select.Option;

import Tooltip from 'bwax-ui/components/Tooltip';
import ResetableLabel from '../components/ResetableLabel';

import './FlexAttributes.less'

const defaultGap = {
    row: { value: 0, unit: 'px' },
    column: { value: 0, unit: 'px'}
}

export default function FlexAttributes({ attributes, onChange }) {
    const [direction, setDirection] = useState(null)
    const [isReverse, setIsReverse] = useState(null)
    const [gap, setGap] = useState(defaultGap)
    const [gapLock, setGapLock] = useState(true)
    const [wrap, setWrap] = useState(false)
    
    const [wrapReverse, setWrapReverse] = useState(false)

    const gapRegx = /^(\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?(?: (\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?)?$/

    useEffect(() => {
        const directionRegx = /(row|column)(-reverse)?/

        if (attributes['flexDirection'] && attributes['flexDirection'].match(directionRegx)) {
            const directionMatchResult = attributes['flexDirection'].match(directionRegx)
            setDirection(directionMatchResult[1])
            if (directionMatchResult[2]) {
                setIsReverse(true)
            }
        }

        if (attributes['gap'] && attributes['gap'].match(gapRegx)) {
            const gapMatchResult = attributes['gap'].match(gapRegx)
            const rg = gapMatchResult[1]
            const rgUnit = gapMatchResult[2] ? gapMatchResult[2] : 'px'
            const cg = gapMatchResult[3]
            const cgUnit = gapMatchResult[4] ? gapMatchResult[4] : 'px'
            let newGap = {...gap}
            newGap = {
                ...newGap,
                row: { ...gap.row, value: rg, unit: rgUnit }
            }

            if (cg) {
                newGap = {
                    ...newGap,
                    column: { ...gap.column, value: cg, unit: cgUnit }
                }

                if(cg === rg && cgUnit === rgUnit) {
                    setGapLock(true)
                } else {
                    setGapLock(false)
                }
            } else {
                newGap = {
                    ...gap,
                    column: { ...gap.column, value: rg, unit: rgUnit }
                }
                setGapLock(true)
            }

            setGap(newGap)
        } else {
            setGap(defaultGap)
        }

        if(attributes['flexWrap']) {
            const noWrap = attributes['flexWrap'].startsWith('no')
            const wrapReverse = attributes['flexWrap'].endsWith('reverse')
            setWrap(!noWrap)
            setWrapReverse(wrapReverse)
        }
    }, [attributes])

    const flexAttributes = [{
        label: "Align",
        key: 'alignItems',
        options: [{
            value: "flex-start", icon: direction === "row" ? <CgAlignTop/> : <CgAlignLeft/>, tooltip: "flex-start"
        }, {
            value: "center", icon: direction === "row" ? <CgAlignMiddle/> : <CgAlignCenter/>, tooltip: "center"
        }, {
            value: "flex-end", icon: direction === "row" ? <CgAlignBottom/> : <CgAlignRight/>, tooltip: "flex-end"
        }, {
            value: "stretch", icon: direction === "row" ? <StretchVerticallyIcon /> : <StretchHorizontallyIcon />, tooltip: "stretch"
        }, {
            value: "baseline", icon: direction === "row" ? <MdAlignHorizontalCenter/> : <MdAlignVerticalCenter/>, tooltip: "baseline"
        }]
    }, {
        label: "Justify",
        key: 'justifyContent',
        options: [{
            value: "flex-start", icon: direction === "row" ? <AlignLeftIcon /> : <AlignTopIcon />, tooltip: "flex-start"
        }, {
            value: "center", icon: direction === "row" ? <AlignCenterHorizontallyIcon /> : <AlignCenterVerticallyIcon />, tooltip: "center"
        }, {
            value: "flex-end", icon: direction === "row" ? <AlignRightIcon /> : <AlignBottomIcon />, tooltip: "flex-end"
        }, {
            value: "space-between", icon: direction === "row" ? <SpaceBetweenHorizontallyIcon /> : <SpaceBetweenVerticallyIcon />, tooltip: "space-between"
        }, {
            value: "space-around", icon: direction === "row" ? <SpaceEvenlyHorizontallyIcon /> : <SpaceEvenlyVerticallyIcon />, tooltip: "space-around"
        }]
    }]

    function clickDirection(d) {
        setDirection(d)
        onChange({ flexDirection: `${d}${isReverse ? '-reverse' : ''}` })
    }

    function clickReverse(reverse) {
        setIsReverse(reverse)
        onChange({ flexDirection: `${direction}${reverse ? '-reverse' : ''}` })
    }

    function clickGapLock() {
        setGapLock(!gapLock)
        // 若 lock 时 column 和 row 不相等，将 row 的数值和单位与 column 的统一
        const { row, column } = gap
        if(!gapLock && (row.unit !== column.unit || row.value !== column.value)) {
            setGap({
                ...gap,
                row: { value: column.value, unit: column.unit }
            })
            onChange({ gap: `${column.value}${column.unit} ${column.value}${column.unit}`})
        } 
    }

    const gapUnits = ['px', 'em', 'rem', 'ch', 'vw', 'vh', '%']
    const singleValueRegx = /^(\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?$/

    function renderGapUnitSelect (side) {

        function selectGapUnit(unit) {
            setGap({
                ...gap,
                [side]: {
                    ...gap[side],
                    unit
                }
            })
            
            if(gapLock) {
                setGap({
                    row: { ...gap[side], unit },
                    column: { ...gap[side], unit }
                })
            }

            const sideGapStr = `${gap[side].value ? gap[side].value : 0}${gap[side].value === 0 ? '' : unit}`
            if(gapLock) {
                onChange({ gap: `${sideGapStr} ${sideGapStr}`})
            } else {
                const gapValueStr = Object.keys(gap).reduce((acc, current, currentIndex) => {
                    const { value } = gap[current]
                    const valueWithUnitStr = current === side ? sideGapStr : 
                        `${value}${value === 0 ? '' : gap[current].unit}`
                    return acc + (currentIndex === 0 ? valueWithUnitStr : ' ' + valueWithUnitStr)
                }, "")
                onChange({ gap: gapValueStr })
            }
        }

        return (
            <Select value={gap[side].unit} className="unit-select" size={'mini'} arrowIcon={null} triggerProps={{ autoAlignPopupWidth: false }}  
                onChange={unit => selectGapUnit(unit)}>
                {gapUnits.map(option => (
                    <Option key={option} value={option} className='unit-option'>
                        {option.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
    }

    function inputGapValue (side, value) {
        setGap({
            ...gap,
            [side]: {
                ...gap[side],
                value
            }
        })
    }

    function inputGapValueConfirm (side) {
        const { value, unit } = gap[side]
        const matchedResult = value ? value.match(singleValueRegx) : null

        if(matchedResult) {
            const matchedValue = matchedResult[1]
            const matchedUnit = matchedValue === '0' ? '' : (matchedResult[2] ? matchedResult[2] : unit)

            const sideGapStr = `${matchedValue}${matchedUnit}`
            if(gapLock) {
                onChange({ gap: `${sideGapStr} ${sideGapStr}`})
                setGap({
                    row: { ...gap[side], matchedValue },
                    column: { ...gap[side], matchedValue }
                })
            } else {
                const gapValueStr = Object.keys(gap).reduce((acc, current, currentIndex) => {
                    const { value, unit } = gap[current]
                    const valueWithUnitStr = current === side ? sideGapStr : 
                        `${value}${value === '0' ? '' : unit}`
                    return acc + (currentIndex === 0 ? valueWithUnitStr : ' ' + valueWithUnitStr)
                }, "")
                onChange({ gap: gapValueStr })
            }
        }
    }

    function wrapChildren (isWrap) {
        setWrap(isWrap)
        onChange({ flexWrap: `${isWrap ? (wrapReverse ? 'wrap-reverse' : 'wrap') : 'noWrap'}`})
    }

    function clickWrapReverse (isReverse) {
        if(wrap) {
            setWrapReverse(isReverse)
            onChange({ flexWrap: `${isReverse ? 'wrap-reverse' : 'wrap'}`})
        }
    }

    const contentAligns = [{
        value: "flex-start", icon: direction === "row" ? (wrapReverse ? <AlignBottomIcon/> : <AlignTopIcon />) : (wrapReverse ? <AlignRightIcon/> : <AlignLeftIcon />), 
        tooltip: direction === "row" ? "Align Rows: Flex Start" : "Align Columns: Flex Start"
    }, {
        value: "center", icon: direction === "row" ? <AlignCenterVerticallyIcon /> : <AlignCenterHorizontallyIcon />, 
        tooltip: direction === "row" ? "Align Rows: Center" : "Align Columns: Center"
    }, {
        value: "flex-end", icon: direction === "row" ? (wrapReverse ? <AlignTopIcon/> : <AlignBottomIcon />) : (wrapReverse ? <AlignLeftIcon/> : <AlignRightIcon />), 
        tooltip: direction === "row" ? "Align Rows: Flex End" : "Align Columns: Flex End"
    }, {
        value: 'stretch', icon: direction === "row" ? <StretchVerticallyIcon /> : <StretchHorizontallyIcon />, 
        tooltip: direction === "row" ? "Align Rows: Stretch" : "Align Columns: Stretch"
    }, {
        value: "space-between", icon: direction === "row" ? <SpaceBetweenVerticallyIcon /> : <SpaceBetweenHorizontallyIcon />, 
        tooltip: direction === "row" ? "Align Rows: Space Between" : "Align Columns: Space Between"
    }, {
        value: "space-around", icon: direction === "row" ? <SpaceEvenlyVerticallyIcon /> : <SpaceEvenlyHorizontallyIcon />, 
        tooltip: direction === "row" ? "Align Rows: Space Around" : "Align Columns: Space Around"
    }]

    return (
        <div className='flex-attributes-container'>
            <div className='style-with-options'>
                <ResetableLabel allAttributes={attributes} label={"Direction"} attributesGroup={"layout"} attribute={'flexDirection'} onChange={onChange}/>
                <div className='options directions'>
                    <div className='directions-options'>
                        <div className={`option-box ${direction === "row" ? 'active' : ''}`} onClick={() => clickDirection("row")}>Horizontal</div>
                        <div className={`option-box ${direction === "column" ? 'active' : ''}`} onClick={() => clickDirection("column")}>Vertical</div>
                    </div>
                    <div className={`option-box ${isReverse ? 'active' : ''}`} onClick={() => clickReverse(!isReverse)}><CgArrowsExchangeAlt /></div>
                </div>
            </div>
            {
                flexAttributes.map(attribute => {
                    const { label, key, options } = attribute
                    return (
                        <div key={key} className='style-with-options'>
                            <ResetableLabel allAttributes={attributes} label={label} attributesGroup={"layout"} 
                                attribute={key} onChange={onChange}
                            />
                            <div className='options' style={{ fontSize: 16 }}>
                                {
                                    options.map((op, index) => {
                                        const { value, icon, tooltip } = op
                                        const isActive = Object.keys(attributes).some(k => k === key) && attributes[key] === value
                                        return (
                                            <Tooltip key={index} text={tooltip}>
                                                <div className={`option-box ${isActive ? 'active' : ''}`}
                                                    onClick={() => onChange({ [key]: value })}
                                                >
                                                    {icon}
                                                </div>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
            <div className='gap-container'>
                <ResetableLabel allAttributes={attributes} label={"Gap"} attributesGroup={"layout"} 
                    attribute={"gap"} onChange={onChange}
                />
                <div className='gap-input'>
                    <Input className='input' size="mini" suffix={renderGapUnitSelect('column')} value={gap.column.value} 
                        onChange={value => inputGapValue('column', value)} onBlur={() => inputGapValueConfirm('column')}
                        onPressEnter={() => inputGapValueConfirm('column')}
                    />
                    <div className={`gap-lock ${gapLock ? 'locked' : ''}`} onClick={() => clickGapLock()}>
                        {gapLock ? <MdLock style={{ fontSize: 16 }} /> : <MdLockOpen style={{ fontSize: 16 }} />}
                    </div>
                    <Input className='input' size="mini" suffix={renderGapUnitSelect('row')} value={gap.row.value} 
                        onChange={value => inputGapValue('row', value)} onBlur={() => inputGapValueConfirm('row')}
                        onPressEnter={() => inputGapValueConfirm('row')}
                    />
                    <div className='gap-label'>Columns</div>
                    <div className='gap-label'>Rows</div>
                </div>
            </div>
            <div className='style-with-options'>
                <ResetableLabel allAttributes={attributes} label={"Children"} attributesGroup={"layout"} 
                    attribute={"flexWrap"} onChange={onChange}
                />
                <div className='options directions'>
                    <div className='directions-options'>
                        <div className={`option-box ${!!attributes['flexWrap'] && !wrap ? 'active' : ''}`} onClick={() => wrapChildren(false)}>Don't wrap</div>
                        <div className={`option-box ${!!attributes['flexWrap'] && wrap ? 'active' : ''}`} onClick={() => wrapChildren(true)}>Wrap</div>
                    </div>
                    <Tooltip text={"Reverse wrap"}>
                        <div className={`option-box ${wrapReverse ? 'active' : (!wrap ? 'disabled' : '')}`} style={{ fontSize: 16 }}
                            onClick={() => clickWrapReverse(!wrapReverse)}
                        >
                            <CgArrowsExchangeAlt />
                        </div>
                    </Tooltip>
                    
                </div>
            </div>
            {
                !!attributes['flexWrap'] && wrap ? (
                    <div className='style-with-options'>
                        <ResetableLabel allAttributes={attributes} label={"Align"} attributesGroup={"layout"} 
                            attribute={"alignContent"} onChange={onChange}
                        />
                        <div className='options'>
                            {
                                contentAligns.map((op, index) => {
                                    const { value, icon, tooltip } = op
                                    const isActive = Object.keys(attributes).some(k => k === 'alignContent') && attributes['alignContent'] === value
                                    return (
                                        <Tooltip key={index} text={tooltip}>
                                            <div className={`option-box ${isActive ? 'active' : ''}`}
                                                onClick={() => onChange({ alignContent: value })}
                                            >
                                                {icon}
                                            </div>
                                        </Tooltip>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}
