
import React from 'react'

import TextInput from "Client/js/ui/widgets/input/Text";
import ShortTextInput from "Client/js/ui/widgets/input/ShortText";
import Number from "Client/js/ui/widgets/input/Number";
import Select from "Client/js/ui/widgets/input/Select";

import { Switch } from 'antd'

import { MdDelete } from 'react-icons/md';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import './FieldDefaultValueInput.less';

export default function FieldDefaultValueInput(props) {

    const { params: { dataType, dataOptions, dataMultivalued }, value, onChange } = props;

    const noValue = value === undefined || value === null;

    const inputEls = {
        "Number": _ => (
            <Number {...{
                value, onChange,
                params: {
                    multivalued: dataMultivalued,
                    decimals: dataOptions && dataOptions.decimals,
                }
            }} />
        ),
        "ShortText": _ => (
            <ShortTextInput {...{
                value, onChange,
                params: {
                    multivalued: dataMultivalued,
                    limitedValues: dataOptions && (dataOptions.validation || dataOptions.list)
                }
            }} />
        ),
        "Text": _ => (
            <TextInput {...{
                value, onChange, params: { multivalued: dataMultivalued }
            }} />
        ),
        "Select": _ => (
            <Select {...{
                value, onChange,
                params: {
                    multivalued: dataMultivalued,
                    options: dataOptions && dataOptions.options,
                }
            }} />
        ),
        "Boolean": _ => {
            return (
                <Switch
                    size="small"
                    checked={value === undefined ? null : value}
                    onChange={onChange}
                    checkedChildren={<div style={{ fontSize: 8 }}><CheckOutlined /></div>}
                    unCheckedChildren={value === false ? <div style={{ fontSize: 8 }}><CloseOutlined /></div> : null}
                />
            )
        }
    }




    const inputEl = (inputEls[dataType] || (_ => null))();

    return (
        <div className="field-default-value-input">
            {inputEl}
            {noValue ? null : (
                <div className="remove-icon" onClick={_ => {
                    // set null
                    onChange(null)
                }}>
                    <MdDelete />
                </div>
            )
            }
        </div>
    )
}

