// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Pretty_printer$BwaxAdmin = require("./pretty_printer.bs.js");

function lang_tree_from_js(code_text, path, lang_tree_js) {
  var name = lang_tree_js.name;
  var partial_arg = Pervasives.$at(path, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          name,
          /* [] */0
        ]));
  return /* record */Caml_chrome_debugger.record([
            "name",
            "src",
            "from",
            "to_",
            "code_text",
            "children",
            "raw",
            "path"
          ], [
            name,
            lang_tree_js.text,
            lang_tree_js.from,
            lang_tree_js.to,
            code_text,
            Plate$BwaxAdmin.List.map((function (param) {
                    return lang_tree_from_js(code_text, partial_arg, param);
                  }), Curry._1(Plate$BwaxAdmin.List.from_array, lang_tree_js.children)),
            lang_tree_js,
            path
          ]);
}

function prev_nonspace_char(index, source_code) {
  var _index = index - 1 | 0;
  while(true) {
    var index$1 = _index;
    var maybe_char = Plate$BwaxAdmin.Str.char_at(index$1, source_code);
    if (maybe_char !== undefined) {
      var c = maybe_char;
      if (c === /* " " */32 || c === /* "\t" */9) {
        _index = index$1 - 1 | 0;
        continue ;
      } else {
        return c;
      }
    } else {
      return ;
    }
  };
}

function next_nonspace_chars(count, index, source_code) {
  var _index = index + 1 | 0;
  var _collected = /* [] */0;
  while(true) {
    var collected = _collected;
    var index$1 = _index;
    if (Plate$BwaxAdmin.List.length(collected) >= count) {
      return collected;
    } else {
      var maybe_char = Plate$BwaxAdmin.Str.char_at(index$1, source_code);
      if (maybe_char !== undefined) {
        var c = maybe_char;
        if (c === /* " " */32 || c === /* "\t" */9) {
          _index = index$1 + 1 | 0;
          continue ;
        } else {
          _collected = Pervasives.$at(collected, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  c,
                  /* [] */0
                ]));
          _index = index$1 + 1 | 0;
          continue ;
        }
      } else {
        return collected;
      }
    }
  };
}

function count_trailing_linebreaks(index, source_code) {
  var _index = index + 1 | 0;
  var _count = 0;
  while(true) {
    var count = _count;
    var index$1 = _index;
    var maybe_char = Plate$BwaxAdmin.Str.char_at(index$1, source_code);
    if (maybe_char !== undefined) {
      var c = maybe_char;
      if (c === /* " " */32 || c === /* "\t" */9) {
        _index = index$1 + 1 | 0;
        continue ;
      } else if (c === /* "\n" */10) {
        _count = count + 1 | 0;
        _index = index$1 + 1 | 0;
        continue ;
      } else {
        return count;
      }
    } else {
      return count;
    }
  };
}

function next_line_option(preceeding_break) {
  return /* record */Caml_chrome_debugger.record([
            "start_with_newline_if_necessary",
            "preceeding_break",
            "preserve_trailing_empty_lines"
          ], [
            true,
            preceeding_break,
            false
          ]);
}

function collect_elements_before(f, list) {
  var _index = 0;
  var _acc = /* [] */0;
  var _param = list;
  while(true) {
    var param = _param;
    var acc = _acc;
    var index = _index;
    if (param) {
      var rest = param[1];
      var h = param[0];
      if (Curry._2(f, index, h)) {
        return /* tuple */[
                acc,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    h,
                    rest
                  ])
              ];
      } else {
        _param = rest;
        _acc = Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                h,
                /* [] */0
              ]));
        _index = index + 1 | 0;
        continue ;
      }
    } else {
      return /* tuple */[
              acc,
              /* [] */0
            ];
    }
  };
}

function collect_elements_until(f, list) {
  var _index = 0;
  var _acc = /* [] */0;
  var _param = list;
  while(true) {
    var param = _param;
    var acc = _acc;
    var index = _index;
    if (param) {
      var rest = param[1];
      var h = param[0];
      if (Curry._2(f, index, h)) {
        return /* tuple */[
                Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        h,
                        /* [] */0
                      ])),
                rest
              ];
      } else {
        _param = rest;
        _acc = Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                h,
                /* [] */0
              ]));
        _index = index + 1 | 0;
        continue ;
      }
    } else {
      return /* tuple */[
              acc,
              /* [] */0
            ];
    }
  };
}

function indent(depth, doc) {
  return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.nest((depth << 1), Pretty_printer$BwaxAdmin.$$break), doc);
}

function indent_with(s, depth, doc) {
  return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.nest((depth << 1), Pretty_printer$BwaxAdmin.break_with(s)), doc);
}

function pile_up(handle_first, handle_other, handle_result, child_nodes) {
  return Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.$$Option.map(handle_result, Plate$BwaxAdmin.List.reduce((function (acc, c) {
                        return Pretty_printer$BwaxAdmin.$caret$pipe(acc, Curry._1(handle_other, c));
                      }), handle_first, child_nodes)));
}

var unit = 2;

var same_line_option = /* record */Caml_chrome_debugger.record([
    "start_with_newline_if_necessary",
    "preceeding_break",
    "preserve_trailing_empty_lines"
  ], [
    false,
    "",
    false
  ]);

exports.lang_tree_from_js = lang_tree_from_js;
exports.prev_nonspace_char = prev_nonspace_char;
exports.next_nonspace_chars = next_nonspace_chars;
exports.count_trailing_linebreaks = count_trailing_linebreaks;
exports.unit = unit;
exports.next_line_option = next_line_option;
exports.same_line_option = same_line_option;
exports.collect_elements_before = collect_elements_before;
exports.collect_elements_until = collect_elements_until;
exports.indent = indent;
exports.indent_with = indent_with;
exports.pile_up = pile_up;
/* Plate-BwaxAdmin Not a pure module */
