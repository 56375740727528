
import React, { useState, useContext } from 'react'

import { Modal } from 'antd';
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import Video from "bwax-ui/auxiliary/video/Video"

import './WxAttachmentDisplay.less'

export default function WxAttachmentDisplay({ value }) {

    const [previewImageURL, setPreviewImageURL] = useState(null)

    function renderLinkItem (label, value, valueType = 'text') {
        return (
            <div>
                <span>{label}</span>
                {
                    valueType === 'url' ? (
                        <a target="blank" href={value} >{value}</a>
                    ) : valueType === 'image' ? (
                        <img src={value} onClick={() => setPreviewImageURL(value)} />
                    ) : (
                        <div>{value}</div>
                    )
                }
               
            </div>
        )
    }

    function renderAttachment(value) {
        const { type, text, image, file, link, news, video } = value
        if (type === "text") {
            return (
                <div className='wx-text'>{text}</div>
            )
        } else if (type === "image") {
            return (
                <img src={image.url} onClick={() => setPreviewImageURL(image.url)} />
            )
        } else if (type === "file") {
            return (
                <a href={file.url} download="附件">{file.title}</a>
            )
        } else if (type === "link" || type === "news") {
            return (
                <div className='link'>
                    { renderLinkItem("链接:", link && link.url || news && news.url, 'url') }
                    { renderLinkItem("标题:", link && link.title || news && news.title) }
                    { link && link.desc || news && news.description ? 
                        renderLinkItem("描述:", link && link.desc || news && news.description) : null 
                    }
                    { link && link.pic && link.pic.url || news && news.picurl ? 
                        renderLinkItem("封面:", link && link.pic && link.pic.url || news && news.picurl, 'image') : null 
                    }
                </div>
            )
        } else if (type === "video") {
            return (
                <div className='video'>
                    <Video
                        options={{
                            sources: [{
                                src: video.url,
                                type: 'video/mp4'
                            }]
                        }}
                    />
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div className='wx-attachment-container'>
            {
                value && value.length > 0 ? (
                    value.map((value, index) => {
                        return (
                            <div key={index} className="wx-attachment">
                                {renderAttachment(value)}
                            </div>
                        )
                    })
                ) : null
            }
            <Modal
                title="图片预览"
                open={!!previewImageURL}
                onCancel={() => setPreviewImageURL(null)}
                bodyStyle={{ textAlign: 'center' }}
                footer={null}
            >
                <img
                    src={previewImageURL}
                    style={{ width: '100%' }} />
            </Modal>
        </div>
    )
}
