
import BaseStrategy from "./BaseStrategy";

import { sendToPreviewChannel, setUpPreviewChannel } from '../editorUtils';

import get from 'lodash/get'

export default class FrontendItemStrategy extends BaseStrategy {

    constructor() {
        super();

        this.running = null;

        this.sentPreview = null;

        this.channel = setUpPreviewChannel(channel => {
            console.log(">>> Sent Preview", this.sentPreview);
            if(this.sentPreview) {
                sendToPreviewChannel(channel, this.sentPreview);
            }
        });;
    }

    // 默认基本上是用于 admin 的 detail page
    getTestRecordOptions(value, { codeFieldName, runtimeProfile }) {
        if(get(value, codeFieldName + ".previewData.ioTypeMetas.isTargetingRecord") && runtimeProfile.entityName ) {
            return {
                entityName: runtimeProfile.entityName
            }
        } else {
            return null
        }
    }


    dispose() {
        this.disposed = true;
        this.channel.close();
    }

    isRunnable () {
        return true
    }

    stopRunning (runningData) {
        this.sentPreview = null;
    }

    async startRunning (code, { previewFacade, entityName, dlc, multitenantType }) {
        // 
        return {
            type: "continuous-preview",
            needToStop: true,
            postPreview: (code, { runtimeProfile, testRecordId, }) => {

                if(this.disposed) {
                    console.warn("Channel is already closed");
                    return 
                }
                
                const fragments = [];
                
                // 
                const preview = { 
                    editing: code, runtimeProfile, fragments, testRecordId,
                    applicationCode: previewFacade.applicationCode,
                    dlc: previewFacade.dlc,
                    multitenantType,
                }
                this.sentPreview = preview;

                console.log("> Sent Preview", preview);

                sendToPreviewChannel(this.channel, preview);
                
            },
            // data: {
            //     predata,
            //     result
            // }
        }
    }

}