
import React from 'react'

import { color as c } from 'bwax-ui/color/Palette'

export default function Label ({params}) {

    const { color, label } = params

    const style = color ? { color: c(color)} : {}
    return <span style={style}>{label || ""}</span>

}
