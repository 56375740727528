

import React from 'react'

import { COMPONENTS } from '../buttons'

export default class Toolbar extends React.Component {

    state = {
        isVisible: false,
        position: undefined,

        selection: null,
        selectionRect: null,
        /**
         * If this is set, the toolbar will render this instead of the regular
         * structure and will also be shown when the editor loses focus.
         * @type {Component}
         */
        overrideContent: undefined
    }


    getEditorState = () => {
        return this.props.getEditorState()
    }
    setEditorState = (editorState) => {
        return this.props.setEditorState(editorState)
    }


    handleToolbarRef = (node) => {
        this.toolbar = node;
    };

    onOverrideContent = (overrideContent) => {

        this.setState({ overrideContent }, () => {
            const { position, selectionRect } = this.resolvePosition(this.getEditorState().getSelection())
            //console.log("New Position: ", position)
            this.setState({
                position, selectionRect
            })
        });
    }


    render() {
        //console.log(getSelectedBlocksMap(this.getEditorState()).toJS())

        const { store, config } = this.props
        const { overrideContent } = this.state

        // console.log("Position: ", this.state.position, overrideContent, this.getStyle())

        const childrenProps = {
            getEditorState: this.getEditorState,
            setEditorState: this.setEditorState,
            onOverrideContent: this.onOverrideContent,
            ...this.props.childrenProps
        }

        const className = overrideContent && overrideContent.meta && overrideContent.meta.outerClassName ?
            " " + overrideContent.meta.outerClassName : ""


        const components = ((config && config.buttons) ||
            ['bold', 'italic', 'underline', 'color', 'seperator', 'format-clear', 'hyperlink'])
            .map(c => COMPONENTS[c]).filter(x => !!x)

        return (
            <div className="editor-toolbar" ref={this.handleToolbarRef}>
                <div className={"toolbar-content" + className}>
                    {/* Handle the override content */}
                    {/* TODO */}
                    <div className="editor-button-group">
                        {components.map((Component, i) => (
                            <div key={i} onMouseDown={e => e.stopPropagation()}>
                                <Component {...childrenProps} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )

    }


}
