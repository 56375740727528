
import React, { useState, useEffect, useRef } from 'react';

import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

import groupByTags, { buildTagFolderNodes } from './groupByTag';

import { getGlobalEventBus } from 'Client/js/studioApp/EventBus';

export function ProcessSelectPanel(props) {

    const { facade, renderSelectorNode, itemNode, baseCriteria, multitenantType } = props;

    // const appId = currentApplication ? currentApplication.id : null;
    // const appValue = {
    //     应用: appId
    // };
    // const baseCriteria = {
    //     ...appValue
    // };
    const baseCriteriaRef = useRef();
    baseCriteriaRef.current = baseCriteria;
    
    // 

    const [ eventHandlers, setEventHandlers ] = useState([]);
    const [ scheduledEventHandlers, setScheduledEventHandlers ] = useState([]);

    async function queryEventHandlers(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "事件处理",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: [
                "名称",
                "描述",
                "标签",
                "显示权重", "创建时间",
            ],
        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            setEventHandlers(result.data);
        }
    }

    async function queryScheduledEventHandlers(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "定时事件处理",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: [ "名称", "描述",  "显示权重", "创建时间", "标签"],
            
        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            setScheduledEventHandlers(result.data);
        }
    }

    useEffect(() => {
        queryEventHandlers({});
        queryScheduledEventHandlers({});
    }, [ JSON.stringify(baseCriteria) ]);

    async function reloadItemList (itemType) {
        const itemReloaders = {
            "event-handler": async _ => await queryEventHandlers({}, { forceRefreshing: true }),
            "scheduled-event-handler": async _ => await queryScheduledEventHandlers({}, { forceRefreshing: true }),
        };
        await (itemReloaders[itemType] || (async _ => {}))()
    }

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("reloadItemList", ({ itemType, parentItem }) => {
            reloadItemList(itemType, parentItem);
        });
        return () => {
            unsubscribe();
        }

    }, [] )

    const sorting = (a, b) => {
        // 给 field 排序, 根据 displayWeight 从大到下排列
        const getWeight = f => f["显示权重"] || 0
        const weightOrder = getWeight(b) - getWeight(a);

        if(weightOrder === 0) {
            const getTime = f => (new Date(f["创建时间"])).getTime();
            return getTime(b) - getTime(a);

        } else {
            return weightOrder
        }
    }


    function buildSubNodes (objs, itemType) {
        const [ taggedGroups, untagged ] = groupByTags(objs);

        return buildTagFolderNodes(taggedGroups, untagged, itemType + "-tag-folder", itemNode, 
            p => itemNode({ itemType, data: p, multitenantType})
        );
    }

    const nodes = [
        itemNode(
            { name: "数据变更事件", itemType: "event-handler-folder", multitenantType},
            buildSubNodes(eventHandlers.sort(sorting), "event-handler")
        ),
        itemNode(
            { name: "定时事件", itemType: "scheduled-event-handler-folder", multitenantType}, 
            buildSubNodes(scheduledEventHandlers.sort(sorting), "scheduled-event-handler")
        )
    ];

    return (
        <div className="panel-content">
            {nodes.map(n => renderSelectorNode(n, 0))}
        </div>
    )
}

export default React.memo(ProcessSelectPanel);


