

/** 用于抖音的 tracking */
export default () => async (params) => {
    const { eventType, convertId } = params
    if (typeof(meteor) != 'undefined' && typeof(meteor.track) == 'function') {
        console.log("Track DY", eventType, convertId);
        meteor.track(eventType, {convert_id: convertId })
    }
}
