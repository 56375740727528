
import React, { useEffect, useState, useContext, useRef } from 'react'
import { Button, Input, Tag } from 'antd'

import Popconfirm from 'bwax-ui/components/Popconfirm'

import IconButton from 'rsuite/IconButton'
import Table from 'rsuite/Table'
const { Column, HeaderCell, Cell } = Table

import Message from 'Client/js/ui/Message';

import { Icon } from '@ant-design/compatible';
const Search = Input.Search

import get from 'lodash/get'
import debounce from 'lodash/debounce'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery';

import './MpKeywordReply.less'

export default function MpKeyWordReply(props) {

    const { widgetProps } = props

    const { queryTarget, routeTo, extensions } = widgetProps

    const isYQYLEnabled = (extensions || []).some(e => e.key == "YQYL");

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)
    const queryRunner = {
        'data': runDataQuery({ tenantCode, sessionToken, sandbox }),
        'definition': runDefinitionQuery({ tenantCode, sessionToken })
    }[queryTarget]

    const [listReplySetting, setListReplySetting] = useState([])
    const [imageMaterials, setImageMaterials] = useState()
    const [newsMaterials, setNewsMaterials] = useState()
    const [filterValue, setFilterValue] = useState()
    const [searchValue, setSearchValue] = useState()
    const [tableScrollHeight, setTableScrollHeight] = useState(0)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])

    const containerRef = useRef(null)

    useEffect(() => {
        (async () => {
            const operation = localStorage.getItem('operation')
            if (!operation || operation === 'add') {
                const replySetting = await loadListReplySetting(queryRunner, undefined, isYQYLEnabled)
                const replySettingEdges = get(replySetting, 'edges', [])
                setListReplySetting(replySettingEdges)

                let imageCondition = []
                let newsCondition = []
                replySettingEdges.map(replySetting => {
                    const { replies } = replySetting.node
                    replies.map(reply => {
                        if (reply.type === 'mpImage') {
                            imageCondition.push({
                                "field": "素材ID",
                                "op": "eq",
                                "value": reply.mpImage
                            })
                        } else if (reply.type === 'mpNews') {
                            newsCondition.push({
                                "field": "素材ID",
                                "op": "eq",
                                "value": reply.mpNews
                            })
                        }
                    })
                })
                const imageMaterials = await loadImages(queryRunner, imageCondition)
                const newsMaterials = await loadNews(queryRunner, newsCondition)
                setImageMaterials(imageMaterials)
                setNewsMaterials(newsMaterials)

                localStorage.setItem('operation', '')
            }
        })()
    }, [localStorage.getItem('operation')])

    useEffect(() => {
        const currentHeight = get(containerRef, "current.clientHeight", 0)
        setTableScrollHeight(currentHeight)

        if (typeof (window) !== "undefined") {

            window.addEventListener('resize', debounce(handleResizeTableCot, 150))

            return () => {
                window.removeEventListener('resize', handleResizeTableCot)
            }
        }

    }, [])

    function handleResizeTableCot() {
        const height = get(containerRef, "current.clientHeight", 0)
        setTableScrollHeight(prevTableScrollHeight => {
            return height && height !== prevTableScrollHeight ? height : prevTableScrollHeight
        })
    }

    function deleteReplySetting(id) {
        (async () => {
            const result = await deleteWxMpEntityKeywordReplySetting(queryRunner, id)

            if (result) {
                Message.success('删除成功')
                const newListReplySetting = await loadListReplySetting(queryRunner, undefined, isYQYLEnabled)
                setListReplySetting(get(newListReplySetting, 'edges', []))
            }
        })()
    }

    function onTableFilterChange(filterValue) {
        (async () => {
            const condition = filterValue && filterValue.length !== 0 ? [[{
                field: "规则名称",
                op: "substring",
                value: filterValue,
            }], [{
                field: "关键字匹配模式",
                op: "strLike",
                value: `%${filterValue}%`
            }]] : undefined
            const newListReplySetting = await loadListReplySetting(queryRunner, condition, isYQYLEnabled)

            if (newListReplySetting) {
                setListReplySetting(get(newListReplySetting, 'edges', []))
            }
        })()
    }

    // const columns = [
    // {
    //     title: '规则名称',
    //     width: 200,
    //     dataKey: 'name',
    //     renderContent: () => (
    //         <Cell dataKey="name"></Cell>
    //     )
    // }, {
    //     title: '关键字',
    //     width: 250,
    //     render: keywordPatterns => {
    //         return keywordPatterns.reduce((acc, current) => {
    //             const { keyword } = current
    //             return `${acc}${acc !== '' ? ', ' : ''}${keyword}`
    //         }, '')
    //     }
    // }, {
    //     title: '回复内容',
    //     dataIndex: 'replies',
    //     width: 300,
    //     render: replies => {
    //         if (replies && replies.length > 0) {
    //             const textCount = replies.filter(r => r.type === 'text').length
    //             const imageCount = replies.filter(r => r.type === 'mpImage').length
    //             const yqylCount = replies.filter(r => r.type === 'yqylPromotion').length
    //             const newsCount = replies.filter(r => r.type === 'mpNews').length

    //             return `${textCount ? `${textCount}文本` : ''} ${imageCount ? `${imageCount}图片` : ''} ${yqylCount ? `${yqylCount}活动` : ''} ${newsCount ? `${newsCount}图文` : ''}`
    //         } else {
    //             return null
    //         }
    //     }
    // }, {
    //     title: '操作',
    //     key: 'action',
    //     width: 200,
    //     render: (record) => {

    //         return (
    //             <div>
    //                 <Button style={{ marginRight: 8 }} onClick={() => routeTo(`edit/${record.id}`)}>
    //                     {'编辑'}
    //                 </Button>

    //                 <Popconfirm
    //                     title={'确定要删除规则吗？'}
    //                     okText="确定"
    //                     cancelText="取消"
    //                     onConfirm={() => deleteReplySetting(record.id)}>
    //                     <Button>
    //                         {'删除'}
    //                     </Button>
    //                 </Popconfirm>
    //             </div>
    //         )

    //     }
    // }]

    function renderDetails(record) {
        const MATCH_TYPE = {
            'MatchPart': '半匹配',
            'MatchAll': '全匹配'
        }
        return (
            <div className="details">
                <div>
                    <label>关键词</label>
                    {
                        record.keywordPatterns.map((k, idx) => {
                            return <span key={idx}>{k.keyword}<span>{`(${MATCH_TYPE[k.pattern]})`}</span></span>
                        })
                    }
                </div>
                <div>
                    <label>回复内容</label>
                    <div>
                        {
                            (record.replies || []).map((reply, idx) => {
                                const imageEl = reply.type === 'mpImage' && imageMaterials ? imageMaterials.edges.find(i => i.node.mediaId === reply.mpImage) : null
                                const newsEl = reply.type === 'mpNews' && newsMaterials ? newsMaterials.edges.find(n => n.node.mediaId === reply.mpNews) : null
                                const newsThumbUrl = newsEl && newsEl.node.thumbUrl ? newsEl.node.thumbUrl : ''
                                return (
                                    <div key={idx}>
                                        {
                                            reply.type === 'text' ? <span>文本：{reply.text}</span> :
                                                reply.type === 'mpImage' && imageEl ? <div style={{
                                                    backgroundImage: `url(${imageEl && imageEl.node.imageUrl.replace('http://mmbiz.qpic.cn', '')})`
                                                }} /> :
                                                    reply.type === 'mpNews' ? (
                                                        <div className="news">
                                                            <span>{newsEl ? newsEl.node.title : undefined}</span>
                                                            <img src={newsThumbUrl ? newsThumbUrl.replace('http://mmbiz.qpic.cn', '') : undefined} />
                                                            <span>{newsEl ? newsEl.node.digest : undefined}</span>
                                                        </div>
                                                    ) :
                                                        reply.type === 'yqylPromotion' ? <span>活动：{reply.yqylPromotion.name}</span> : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    function clearFilter() {
        setFilterValue(null)
        setSearchValue(null)
        onTableFilterChange(null)

    }

    function handleExpanded(rowData, dataKey) {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach((key) => {
            if (key === rowData['id']) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData['id']);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    }

    const ExpandCell = ({ rowData, expandedRowKeys, onChange, ...props }) => {
        return (
            <Cell {...props}>
                <IconButton
                    classPrefix={"btn"}
                    size="xs"
                    appearance="subtle"
                    onClick={() => {
                        onChange(rowData);
                    }}
                    icon={
                        expandedRowKeys.some((key) => key === rowData['id']) ? <Icon type="minusSquare" /> : <Icon type="plusSquare" />
                    }
                />
            </Cell>
        )
    }

    const KeywordPatternsCell = ({ rowData, ...props }) => {
        const { keywordPatterns } = rowData
        const content = keywordPatterns.reduce((acc, current) => {
            const { keyword } = current
            return `${acc}${acc !== '' ? ', ' : ''}${keyword}`
        }, '')
        return (
            <Cell {...props}>
                <div>{content}</div>
            </Cell>
        )
    }

    const RepliesCell = ({ rowData, ...props}) => {
        const { replies } = rowData
        let content = ''
        if (replies && replies.length > 0) {
            const textCount = replies.filter(r => r.type === 'text').length
            const imageCount = replies.filter(r => r.type === 'mpImage').length
            const yqylCount = replies.filter(r => r.type === 'yqylPromotion').length
            const newsCount = replies.filter(r => r.type === 'mpNews').length

            content = `${textCount ? `${textCount}文本` : ''} ${imageCount ? `${imageCount}图片` : ''} ${yqylCount ? `${yqylCount}活动` : ''} ${newsCount ? `${newsCount}图文` : ''}`
        }
        return (
            <Cell {...props}>
                <div>{content}</div>
            </Cell>
        )
    }

    const ActionCell = ({ rowData, ...props }) => {
        const { id } = rowData
        return (
            <Cell {...props}>
                <Button style={{ marginRight: 8 }} onClick={() => routeTo(`edit/${id}`)}>
                    {'编辑'}
                </Button>
                <Popconfirm
                    title={'确定要删除规则吗？'}
                    okText="确定"
                    cancelText="取消"
                    onConfirm={() => deleteReplySetting(id)}>
                    <Button>
                        {'删除'}
                    </Button>
                </Popconfirm>
            </Cell>
        )
    }

    return (
        <div className="wxmp-keywordReply-container">
            <div className="kp-table-operation">
                <div>
                    <Search
                        placeholder={'搜索关键字/规则名称'}
                        style={{ width: 288, marginRight: 8 }}
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        onSearch={value => {
                            console.log("search value: ", value, value.trim().length);
                            if (value.trim().length > 0) {
                                setFilterValue(value)
                                onTableFilterChange(value)
                            } else {
                                clearFilter()
                            }
                        }}
                    />
                    {filterValue && filterValue.length > 0 ? (
                        <div style={{ marginTop: 24 }}>
                            <Tag onClick={(e) => clearFilter()}>
                                {filterValue}
                                <Icon type="close" />
                            </Tag>
                            <a style={{ fontSize: 12, marginLeft: 4, textDecoration: 'none' }} onClick={() => clearFilter()} >
                                清除
                            </a>
                        </div>
                    ) : null
                    }
                </div>
                <Button type="primary" onClick={() => routeTo(`edit`)}>添加回复</Button>
            </div>
            <div ref={containerRef} className="kp-table-container">
                {/* 改用 resuit Table */}
                {/* <Table
                    dataSource={listReplySetting.map(r => ({ key: r.node.id, ...r.node }))}
                    columns={columns} 
                    scroll={{ 
                        x: true, 
                        y: tableScrollHeight
                    }}
                    expandedRowRender={record => renderDetails(record)}
                /> */}
                <Table
                    height={tableScrollHeight}
                    data={listReplySetting.map(r => ({ key: r.node.id, ...r.node }))}
                    rowKey={"id"}
                    expandedRowKeys={expandedRowKeys}
                    renderRowExpanded={record => renderDetails(record)}
                    shouldUpdateScroll={false}
                >
                    <Column width={70} align="center">
                        <HeaderCell></HeaderCell>
                        <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
                    </Column>
                    <Column width={200}>
                        <HeaderCell>{"规则名称"}</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column width={250}>
                        <HeaderCell>{"关键字"}</HeaderCell>
                        <KeywordPatternsCell dataKey="keywordPatterns"></KeywordPatternsCell>
                    </Column>
                    <Column width={250}>
                        <HeaderCell>{"回复内容"}</HeaderCell>
                        <RepliesCell dataKey="replies"></RepliesCell>
                    </Column>
                    <Column width={200}>
                        <HeaderCell>{"操作"}</HeaderCell>
                        <ActionCell></ActionCell>
                    </Column>
                    {
                        // columns.map((column, index) => {
                        //     const { width, title, dataKey, ...rest } = column
                        //     return (
                        //         <Column key={index} width={width} {...rest} >
                        //             <HeaderCell>{title}</HeaderCell>
                        //             {column.renderContent({ dataKey: dataKey, expandedRowKeys, onChange: handleExpanded })}
                        //         </Column>
                        //     )
                        // })
                    }
                </Table>
            </div>
        </div>
    )
}

async function loadListReplySetting(queryRunner, condition, isYQYLEnabled) {
    const resp = await queryRunner(QueryReplySetting(isYQYLEnabled))({
        condition
    })
    const { errors, data } = JSON.parse(resp)
    if (errors) {
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityKeywordReplySetting
    }
}


async function deleteWxMpEntityKeywordReplySetting(queryRunner, id) {
    const resp = await queryRunner(MutDeleteWxMpEntityKeywordReplySetting)({
        input: {
            id,
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(resp)
    if (errors) {
        console.log(errors)
        return undefined
    } else {
        return data.deleteWxMpEntityKeywordReplySetting
    }
}

const QueryWxMpImageMaterials = `
    query (
        $condition: [[ListWxMpEntityImageMaterialConditionInput]]
    ) {
        listWxMpEntityImageMaterial(condition: $condition ) {
            edges {
                node {
                    id
                    mediaId
                    imageUrl
                    name
                    updatedAt
                }
            }
        }
    }
`

async function loadImages(queryRunner, condition) {
    const resp = await queryRunner(QueryWxMpImageMaterials)({
        condition
    })
    const { errors, data } = JSON.parse(resp)
    if (errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityImageMaterial
    }
}

const QueryWxMpNewsMaterials = `
    query (
        $condition: [[ListWxMpEntityNewsMaterialConditionInput]]
    ) {
        listWxMpEntityNewsMaterial(condition: $condition) {
            edges {
                node {
                    id
                    mediaId
                    title
                    digest
                    contentSourceUrl
                    thumbUrl
                    updatedAt
                }
            }
        }
    }
`

async function loadNews(queryRunner, condition) {
    const resp = await queryRunner(QueryWxMpNewsMaterials)({
        condition
    })
    const { errors, data } = JSON.parse(resp)
    if (errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityNewsMaterial
    }
}

//// GraphQL Text:
const QueryReplySetting = (isYQYLEnabled) => `
    query (
        $condition: [[ListWxMpEntityKeywordReplySettingConditionInput]]
    ) {
        listWxMpEntityKeywordReplySetting(condition: $condition, sort: [{ field: "createdAt", order: DESC }]) {
            edges {
                node {
                    id
                    name
                    keywordPatterns {
                        keyword
                        pattern
                    }
                    priority
                    replies {
                        type
                        text
                        mpImage
                        mpNews
                        ${isYQYLEnabled ? `
                                yqylPromotion {
                                    id
                                    name
                                }
                            ` : ""
    }
                    }
                    replyType
                    transferCustomerService
                }
            }
        }
    }
`

const MutDeleteWxMpEntityKeywordReplySetting = `
    mutation DeleteWxMpEntityKeywordReplySettingMutation(
        $input: DeleteWxMpEntityKeywordReplySettingInput!
    ) {
        deleteWxMpEntityKeywordReplySetting(input: $input) {
            deletedWxMpEntityKeywordReplySetting {
                id
            }
        }
    }
`
