

import React from 'react'
import ClampedText from 'bwax-ui/components/ClampedText';

import { Popover } from '@arco-design/web-react';

import './MultiTextDisplay.less';

// 
export function TipPopover({ text, children }) {
    return (
        <Popover
            trigger='hover'
            content={
                <div className="lc-multi-text-popover">
                    {text}
                </div>
            }
        >
            {children}
        </Popover>
    )
}


export default function MultiTextDisplay({ texts }) {

    return (
        <div className="lc-multi-text-display">
            {texts.map((t, index) => {
                return (
                    <div className='text-part' key={index}>
                        <ClampedText text={t} lines={2}
                            tipComponent={TipPopover}
                        />
                    </div>
                )
            })}
        </div>
    )
}
