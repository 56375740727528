// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_entry_slim$BwaxAdmin = require("../../../lib/bwax-js/ml/lang/lang_entry_slim.bs.js");
var Code_editing_common$BwaxAdmin = require("./code_editing_common.bs.js");
var Pretty_printer_lang$BwaxAdmin = require("../../../lib/bwax-js/ml/printer/pretty_printer_lang.bs.js");
var Pretty_printer_common$BwaxAdmin = require("../../../lib/bwax-js/ml/printer/pretty_printer_common.bs.js");

function check_type_alias(f, n) {
  var match = n[/* children */5];
  if (match) {
    var h = match[0];
    if (h[/* name */0] === "TypeAliasDef") {
      return Curry._1(f, h);
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function get_alias_name(n) {
  return Plate$BwaxAdmin.$$Option.foldl((function (param, c) {
                return c[/* src */1];
              }), "", Plate$BwaxAdmin.List.find((function (c) {
                    return c[/* name */0] === "CapName";
                  }), n[/* children */5]));
}

function get_lang_tree(code_text, lang_tree_js) {
  return Pretty_printer_common$BwaxAdmin.lang_tree_from_js(code_text, /* [] */0, lang_tree_js);
}

function add_type_alias_for_entity(entity_name, query_type, lang_tree) {
  var children = lang_tree[/* children */5];
  var iter = function (_pprev, _prev, _param) {
    while(true) {
      var param = _param;
      var prev = _prev;
      var pprev = _pprev;
      if (param) {
        var h = param[0];
        if (h[/* name */0] !== "ImportStmt" && h[/* name */0] !== "Semicolon" && !check_type_alias((function (c) {
                  return get_alias_name(c) !== "Model";
                }), h)) {
          return /* tuple */[
                  pprev,
                  prev
                ];
        } else {
          _param = param[1];
          _prev = h;
          _pprev = prev;
          continue ;
        }
      } else {
        return /* tuple */[
                pprev,
                prev
              ];
      }
    };
  };
  var match = iter(undefined, undefined, children);
  var match$1 = match[0];
  var match$2;
  if (match[1] !== undefined) {
    if (match$1 !== undefined) {
      var node_to_follow = match[1];
      check_type_alias((function (param) {
              return true;
            }), match$1);
      match$2 = /* tuple */[
        node_to_follow[/* to_ */3],
        "\n"
      ];
    } else {
      match$2 = /* tuple */[
        match[1][/* to_ */3],
        "\n\n"
      ];
    }
  } else {
    match$2 = /* tuple */[
      0,
      "\n\n"
    ];
  }
  var name = Lang_entry_slim$BwaxAdmin.normalize_name(entity_name);
  var tmp;
  switch (query_type) {
    case "list" :
        tmp = "_forList";
        break;
    case "paged" :
        tmp = "_forPagedList";
        break;
    default:
      tmp = "";
  }
  var base_alias_name = "R_" + (name + tmp);
  var try_name = function (_count) {
    while(true) {
      var count = _count;
      var alias_name = base_alias_name + (
        count > 1 ? "_" + String(count) : ""
      );
      if (Plate$BwaxAdmin.List.some((function(alias_name){
            return function (param) {
              return check_type_alias((function (c) {
                            return get_alias_name(c) === alias_name;
                          }), param);
            }
            }(alias_name)), children)) {
        _count = count + 1 | 0;
        continue ;
      } else {
        return alias_name;
      }
    };
  };
  var alias_name = try_name(1);
  var text = match$2[1] + ("type alias " + (alias_name + (" = # E_" + (name + ";"))));
  return /* tuple */[
          text,
          match$2[0],
          alias_name
        ];
}

function add_model_field(entity_name, alias_name, query_type, lang_tree) {
  var found = Plate$BwaxAdmin.List.find((function (param) {
          return check_type_alias((function (c) {
                        return get_alias_name(c) === "Model";
                      }), param);
        }), lang_tree[/* children */5]);
  var type_alias_children_rev = Plate$BwaxAdmin.$$Option.map((function (n) {
          return Plate$BwaxAdmin.List.rev(n[/* children */5]);
        }), Plate$BwaxAdmin.$$Option.and_then((function (n) {
              return Plate$BwaxAdmin.List.head(n[/* children */5]);
            }), Plate$BwaxAdmin.$$Option.and_then((function (n) {
                  return Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(n[/* children */5]));
                }), Plate$BwaxAdmin.$$Option.and_then((function (n) {
                      return Plate$BwaxAdmin.List.head(n[/* children */5]);
                    }), found))));
  var position;
  if (type_alias_children_rev !== undefined) {
    var match = type_alias_children_rev;
    if (match) {
      var match$1 = match[1];
      position = match$1 ? match$1[0][/* to_ */3] : 0;
    } else {
      position = 0;
    }
  } else {
    position = 0;
  }
  var tmp;
  switch (query_type) {
    case "count" :
        tmp = "_count";
        break;
    case "list" :
        tmp = "_list";
        break;
    case "paged" :
        tmp = "_pagedList";
        break;
    default:
      tmp = "";
  }
  var base_name = Plate$BwaxAdmin.Str.decap(Lang_entry_slim$BwaxAdmin.normalize_name(entity_name)) + tmp;
  var try_field_name = function (_count) {
    while(true) {
      var count = _count;
      var field_name = base_name + (
        count > 1 ? "_" + String(count) : ""
      );
      var existing = Plate$BwaxAdmin.List.some((function(field_name){
          return function (d) {
            return Caml_obj.caml_equal(Plate$BwaxAdmin.$$Option.map((function (c) {
                              return c[/* src */1];
                            }), Plate$BwaxAdmin.List.head(d[/* children */5])), field_name);
          }
          }(field_name)), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, type_alias_children_rev));
      if (existing) {
        _count = count + 1 | 0;
        continue ;
      } else {
        return /* tuple */[
                field_name,
                count
              ];
      }
    };
  };
  var match$2 = try_field_name(1);
  var field_name = match$2[0];
  var wrap_type = function (name) {
    switch (query_type) {
      case "count" :
          return "Int";
      case "list" :
          return "List " + name;
      case "paged" :
          return "Maybe (PagedList " + (name + ")");
      default:
        return "Maybe " + name;
    }
  };
  var init_value;
  switch (query_type) {
    case "count" :
        init_value = "0";
        break;
    case "list" :
        init_value = "[]";
        break;
    case "paged" :
        init_value = "Nothing";
        break;
    default:
      init_value = "Nothing";
  }
  var field_decl = "\n  , " + (field_name + (": " + wrap_type(alias_name)));
  return /* tuple */[
          field_decl,
          position,
          field_name,
          init_value,
          match$2[1]
        ];
}

function get_position_to_insert_cmd_func(lang_tree) {
  var find_noncomment_node_before_init_func = function (_found, _param) {
    while(true) {
      var param = _param;
      var found = _found;
      if (param) {
        var rest = param[1];
        var h = param[0];
        if (Code_editing_common$BwaxAdmin.is_function_with_name("init", h)) {
          return found;
        } else {
          _param = rest;
          if (h[/* name */0] === "LineComment" || h[/* name */0] === "BlockComment") {
            continue ;
          } else {
            _found = h;
            continue ;
          }
        }
      } else {
        return found;
      }
    };
  };
  var node_to_append = find_noncomment_node_before_init_func(undefined, lang_tree[/* children */5]);
  return Plate$BwaxAdmin.$$Option.map((function (c) {
                return c[/* to_ */3];
              }), node_to_append);
}

function update_init_to_add_field_and_cmd(field_name, init_value, cmd_func_name, lang_tree) {
  var maybe_init_func = Code_editing_common$BwaxAdmin.get_func_by_name("init", lang_tree);
  var find_last_expr = function (expr_node) {
    return Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.List.filter((function (c) {
                            if (c[/* name */0] !== ")") {
                              return c[/* name */0] !== "]";
                            } else {
                              return false;
                            }
                          }))(Plate$BwaxAdmin.fst(Pretty_printer_lang$BwaxAdmin.extract_comments(expr_node[/* children */5])))));
  };
  var find_last_tuple = function (expr_node) {
    var match = expr_node[/* name */0];
    if (match === "Tuple") {
      return expr_node;
    } else {
      return Plate$BwaxAdmin.$$Option.and_then(find_last_tuple, find_last_expr(expr_node));
    }
  };
  if (maybe_init_func !== undefined) {
    var init_func = maybe_init_func;
    var maybe_last_tuple = find_last_tuple(init_func);
    var change_last_tuple = function (last_tuple) {
      var match = Plate$BwaxAdmin.fst(Pretty_printer_lang$BwaxAdmin.extract_comments(last_tuple[/* children */5]));
      if (match) {
        var match$1 = match[1];
        if (match$1) {
          var match$2 = match$1[1];
          if (match$2) {
            var match$3 = match$2[1];
            if (match$3) {
              var exp = match$3[0];
              var record_change = Plate$BwaxAdmin.$$Option.map((function (c) {
                      return /* tuple */[
                              ", " + (field_name + (" = " + (init_value + " "))),
                              c[/* from */2],
                              c[/* from */2]
                            ];
                    }), Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.fst(Pretty_printer_lang$BwaxAdmin.extract_comments(match$1[0][/* children */5])))));
              var wrap_with_batch = function (exp) {
                var text = "Cmd.batch [ " + (exp[/* src */1] + (", " + (cmd_func_name + " ]")));
                return /* tuple */[
                        text,
                        exp[/* from */2],
                        exp[/* to_ */3]
                      ];
              };
              var match$4 = exp[/* name */0];
              var match$5 = exp[/* src */1];
              var cmd_change;
              if (match$5 === "none") {
                cmd_change = /* tuple */[
                  cmd_func_name,
                  exp[/* from */2],
                  exp[/* to_ */3]
                ];
              } else if (match$4 === "ApplyExpr") {
                var match$6 = exp[/* children */5];
                if (match$6) {
                  var match$7 = match$6[1];
                  if (match$7 && !match$7[1]) {
                    var a = match$7[0];
                    cmd_change = match$6[0][/* src */1] === "Cmd.batch" && a[/* name */0] === "List" ? Plate$BwaxAdmin.$$Option.map((function (c) {
                              return /* tuple */[
                                      ", " + cmd_func_name,
                                      c[/* from */2],
                                      c[/* from */2]
                                    ];
                            }), Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.fst(Pretty_printer_lang$BwaxAdmin.extract_comments(a[/* children */5]))))) : wrap_with_batch(exp);
                  } else {
                    cmd_change = wrap_with_batch(exp);
                  }
                } else {
                  cmd_change = wrap_with_batch(exp);
                }
              } else {
                cmd_change = wrap_with_batch(exp);
              }
              return Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            record_change,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                cmd_change,
                                /* [] */0
                              ])
                          ]));
            } else {
              return ;
            }
          } else {
            return ;
          }
        } else {
          return ;
        }
      }
      
    };
    var match = Plate$BwaxAdmin.$$Option.and_then(change_last_tuple, maybe_last_tuple);
    if (match !== undefined) {
      var change_init_func = function (_offset, _acc, _param) {
        while(true) {
          var param = _param;
          var acc = _acc;
          var offset = _offset;
          if (param) {
            var change = param[0];
            var to_ = change[2];
            var from = change[1];
            var text = change[0];
            var start = from - offset | 0;
            var end_ = to_ - offset | 0;
            var replaced = Plate$BwaxAdmin.Str.sub(0, start, acc) + (text + Plate$BwaxAdmin.Str.sub(end_, Plate$BwaxAdmin.Str.length(acc), acc));
            var new_offset = offset - (Plate$BwaxAdmin.Str.length(text) - (to_ - from | 0) | 0) | 0;
            _param = param[1];
            _acc = replaced;
            _offset = new_offset;
            continue ;
          } else {
            return acc;
          }
        };
      };
      var result_text = change_init_func(init_func[/* from */2], init_func[/* src */1], match);
      return /* tuple */[
              result_text,
              init_func[/* from */2],
              init_func[/* to_ */3]
            ];
    } else {
      return ;
    }
  }
  
}

exports.check_type_alias = check_type_alias;
exports.get_alias_name = get_alias_name;
exports.get_lang_tree = get_lang_tree;
exports.add_type_alias_for_entity = add_type_alias_for_entity;
exports.add_model_field = add_model_field;
exports.get_position_to_insert_cmd_func = get_position_to_insert_cmd_func;
exports.update_init_to_add_field_and_cmd = update_init_to_add_field_and_cmd;
/* Plate-BwaxAdmin Not a pure module */
