

import React from 'react'

import { Input } from 'antd'

import EditTags from 'Client/js/components/form/baseInputs/EditTags'

const Nil = "__none__"

import InputSelect from 'Client/js/ui/components/InputSelect';

import TextAutoComplete from 'Client/js/components/TextAutoComplete'

export default function ShortText(props) {

    const { params, value, onChange, disabled } = props
    const {
        multivalued, placeholder, limitedValues, fixedValue, required,
        field, dataEntity = {}
    } = params

    // 如果 field.unique !== true， input 支持基于 usedValues 的 autocomplete，根据 keyword 变化而变化；

    if(!field) {
        return (
            multivalued ?
            <EditTags
                value={value}
                disabled={disabled}
                onChange={onChange}
                autoComplete={false}
                // entityName={dataEntity.name}
                field={field}
            /> :
            <Input
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
            />
        )   
    }

    if (limitedValues) {

        const getValue = (value) => {
            if (multivalued) {
                return value || []
            } else {
                return value
            }
        }
        const options =
            [
                ...(limitedValues.map(v => ({ value: v, label: v })))
            ];

        return (
            <InputSelect {...{
                style: { width: "100%" },
                isDisabled: fixedValue !== undefined, isMulti: multivalued,
                value: getValue(value),
                onChange,
                options,
            }} />
        )

    } else {
        return multivalued ?
            <EditTags
                value={value}
                disabled={disabled}
                onChange={onChange}
                autoComplete={field.unique !== true}
                entityName={dataEntity.name}
                field={field}
            /> :
            (
                field.unique !== true && dataEntity.name ?
                    <TextAutoComplete
                        value={value}
                        onChange={onChange}
                        params={{
                            placeholder,
                            disabled,
                            entityName: dataEntity.name,
                            field
                        }}
                    /> :
                    <Input
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={e => onChange(e.target.value)}
                    />
            )

    }
}
