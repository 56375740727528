
import React, { useState, useContext } from 'react';

import SlateEditor from 'bwax-ui/auxiliary/richtext_slate/editor/SlateEditor';

import buttonComponents from 'bwax-ui/auxiliary/richtext_slate/editor/buttons/buttonsLegacy';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';

import convertSlateToMarkdown from 'bwax-ui/auxiliary/richtext_slate/editor/convertSlateToMarkdown'

import './TestEditor.less';


function getLocalItem (key) {
    if(typeof(localStorage) !== 'undefined') {
        return JSON.parse(localStorage.getItem(key)) || [
            {
                type: 'paragraph',
                children: [{ text: '' }],
            },
        ]
    } else {
        return null
    }
}

const key = 'test-editor-slate-value-3'

export default function TestEditorAI(props) {

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ sessionToken, sandbox, tenantCode })

    const [ slateValue, setSlateValue ] = useState(getLocalItem(key));

    const [ markownValue, setMarkdownValue ] = useState();

    function changeSlateValue(value) {
        setSlateValue(value);        
        if(typeof(localStorage) !== 'undefined') {
            return localStorage.setItem(key, JSON.stringify(value));
        }
    }

    useState(() => {
        if(slateValue) {
            setMarkdownValue(convertSlateToMarkdown(slateValue))
        }
    }, [ slateValue ])


    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "EntityGeshou"
        })
    
        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }
    
    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "EntityGeshou",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }

    return (
        <div className="test-editor test-editor-ai">
            <div className="side">
                { (!slateValue) ? null : 
                    <SlateEditor value={slateValue} uploadImage={doUpload}
                        uploadVideo={uploadVideo} onChange={changeSlateValue}
                        aiEnabled={true}
                        buttonComponents={buttonComponents}
                    /> 
                }
            </div>
            <div className="side markdown-side">
                <pre>
                    { markownValue }                
                </pre>
            </div>
        </div>
    )
}