// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");

function no_need_remat(entity) {
  var count_of_materialized_fields = Plate$BwaxAdmin.List.length(Plate$BwaxAdmin.List.filter((function (f) {
                return f[/* materialized */10];
              }))(entity[/* virtual_fields */3]));
  return count_of_materialized_fields === 0;
}

function remat_field_srcs(entity) {
  var declare_field = function (f) {
    if (f[/* materialized */10]) {
      return Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]) + ": Bool";
    }
    
  };
  var lines = Plate$BwaxAdmin.List.keep_map(declare_field, entity[/* virtual_fields */3]);
  var remat_fields = " { " + (Plate$BwaxAdmin.Str.join(", ", lines) + " }");
  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
            "criteria: Criteria ~" + Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "\n              , remat: " + remat_fields,
                /* [] */0
              ])
          ]);
}

function maybe_remat_func_declaration(entity) {
  if (no_need_remat(entity)) {
    return ;
  } else {
    var remat_params = Plate$BwaxAdmin.Str.join("", remat_field_srcs(entity));
    return "-- 返回的是查询到的记录数.\nremat:      ~ { " + (String(remat_params) + " \n              }\n          -> (Int -> msg) -> (String -> msg) \n          -> Cmd msg = external\n");
  }
}

function build_remat(module_name, entity) {
  var func = function (args) {
    if (args) {
      var match = args[1];
      if (match) {
        var match$1 = match[1];
        if (match$1) {
          if (match$1[1]) {
            return Runtime_common$BwaxAdmin.invalid_args(module_name + ".remat");
          } else {
            var tname = Plate$BwaxAdmin.Str.replace("Query_", "E_", module_name);
            var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [tname])]),
                  /* No_term */0
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    args[0],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        match[0],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            match$1[0],
                            /* [] */0
                          ])
                      ])
                  ])
              ]);
            var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                "Cmd_Query_remat",
                cmd_val_001
              ]);
            return /* tuple */[
                    cmd_val,
                    /* No_term */0
                  ];
          }
        } else {
          return Runtime_common$BwaxAdmin.invalid_args(module_name + ".remat");
        }
      } else {
        return Runtime_common$BwaxAdmin.invalid_args(module_name + ".remat");
      }
    } else {
      return Runtime_common$BwaxAdmin.invalid_args(module_name + ".remat");
    }
  };
  var f_000 = /* V_native_fn */Caml_chrome_debugger.variant("V_native_fn", 4, [
      3,
      func,
      /* [] */0
    ]);
  var f = /* tuple */[
    f_000,
    /* No_term */0
  ];
  return /* tuple */[
          "remat",
          f
        ];
}

var build_remat_cmdm = /* tuple */[
  "remat",
  (function (entity) {
      var func = function (args) {
        var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            Runtime_common$BwaxAdmin.pack_string(entity[/* name */0]),
            args
          ]);
        var cmd_val = /* V_executable */Caml_chrome_debugger.variant("V_executable", 14, [
            "CmdM_remat",
            cmd_val_001,
            /* [] */0
          ]);
        return /* tuple */[
                cmd_val,
                /* No_term */0
              ];
      };
      return Runtime_common$BwaxAdmin.pack_func(1, func);
    })
];

exports.no_need_remat = no_need_remat;
exports.remat_field_srcs = remat_field_srcs;
exports.maybe_remat_func_declaration = maybe_remat_func_declaration;
exports.build_remat = build_remat;
exports.build_remat_cmdm = build_remat_cmdm;
/* Plate-BwaxAdmin Not a pure module */
