import React, { useEffect, useState, useContext } from 'react'

import { Input } from 'antd'

import DraggableInput from 'Client/js/components/form/baseInputs/DraggableInput'
import PopoverContext from 'bwax-ui/components/legacy/PopoverContext';

import './Text.less'

function BaseTextAreaInput(props) {
    const { onChange, rows = 2, value } = props

    const pc = useContext(PopoverContext);

    const [mounted, setMounted ] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])

    if(!mounted && pc) {
        return null
    }

    return (
        <Input.TextArea
            {...{ 
                value, 
                autoSize: { minRows: rows, maxRows: 12 } 
            }}
            onChange={e => onChange(e.target.value)}
        />
    )
}

export function TextInput (props) {

    const { value: passedInValue, onChange,  multivalued, rows } = props


    let value = passedInValue;
    if (passedInValue !== null && passedInValue !== undefined && multivalued && !Array.isArray(value)) {
        value = [passedInValue];
    }
    if(multivalued) {
        return (
            <DraggableInput
                className="edit-text-input"
                value={value}
                onChange={onChange}
                multivalued={multivalued}
                baseInput={
                    <BaseTextAreaInput rows={rows} />
                }
            />
        )
    } else {
        return (
            <BaseTextAreaInput {...{
                value, onChange, rows
            }} />
        )

    }



}

export default function Text(props) {

    const { value, onChange, params } = props
    const { multivalued, autoSize, rows } = params

    return <TextInput value={value} onChange={onChange} multivalued={multivalued} rows={rows} />

}
