import React,  { useState } from 'react';

import ReactDOM from 'react-dom';

import { Dropdown, Menu } from 'antd'

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const min_columnWidth = 100 // 列的最小宽度, 若初始宽度小于这个值，则不允许修改宽度。

export default function TH (props)  {

    const {

        title, 
        columnKey, 
        fixed,
        width,

        sortable,

        showCtxMenu,
        
        style,

        orders,
        setOrders,
        onSort, 


        tableConfig,
        onTableConfigChange,

        isFixedTH,
        grabbing, grabbingOther,
        onGrab,
    } = props

    const [ctxMenuVisible, setCtxMenuVisible] = useState(false)
    const [thHoverd, setThHoverd] = useState(false)
    const [showResizeBar, setShowResizeBar] = useState(false)


    const onSorterChange = ({ order }) => {

        const { [columnKey]: _, ...remaining } = orders
        const newOrders = (() => {
            if (orders[columnKey]) {
                return order === null ? remaining : 
                    {
                        ...remaining,
                        [columnKey]: order
                    }
            } else {
                return {
                    ...remaining,
                    [columnKey]: order
                }
            }
        })();

        setOrders(newOrders);
        onSort && onSort({ orders: newOrders, sortingKey: columnKey });

    }

    const renderSorter = () => {
        if (sortable) {
            const orderArr = Object.keys(orders)
            const latestOrderKey = orderArr[orderArr.length - 1]
            return (
                <TableSorter
                    isLatestOrder={latestOrderKey === columnKey}
                    onChange={onSorterChange} />
            )
        } else {
            return null
        }
    }

    const fixedLeftColumns = tableConfig && tableConfig.fixedLeftColumns ? tableConfig.fixedLeftColumns : []

    const getFixedMenuItems = () => {
        if (fixed === 'left') {
            return [{
                label: "取消固定",
                onClick: e => onTableConfigChange({
                    ...tableConfig || {},
                    fixedLeftColumns: fixedLeftColumns.filter(k => k !== columnKey)
                })
            }]
        }
        if (fixed === undefined) {
            return [{
                label: "固定在左侧",
                onClick: e => onTableConfigChange({
                    ...tableConfig || {},
                    fixedLeftColumns: [
                        ...fixedLeftColumns,
                        columnKey
                    ]
                })
            }]
        }
        return []
    }

    const items = [
        ...getFixedMenuItems()
    ]

    const menu = (
        <Menu>
            {
                items.map(item => {
                    return (
                        <Menu.Item key={item.label}>
                            <div onClick={() => {
                                item.onClick && item.onClick()
                                setCtxMenuVisible(false)
                            }}>
                                {item.label}
                            </div>
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )

    const focusStyle = ctxMenuVisible || thHoverd ? {
        backgroundColor: "#F0F3F5"
    } : {}

    const renderResizeBar = () => {

        // TODO 升级 React 18 后，拖动会报错，暂时屏蔽先
        if (showResizeBar) {
            return (
                <div className="th-resizebar"
                    style={{
                        cursor: grabbing ? "grabbing" : "grab",
                    }}
                    onMouseDown={(e) => {
                        onGrab(e.clientX)
                    }}
                />
            )
        } else {
            return null
        }
    }

    const resizeBarValid = onTableConfigChange && typeof (onTableConfigChange) === 'function' &&
        width >= min_columnWidth && !(fixed === "right")

    return showCtxMenu ?
        <Dropdown
            overlay={menu}
            trigger={["contextMenu"]}
            onOpenChange={open => setCtxMenuVisible(open)} >
            <th
                onMouseEnter={() => {
                    setThHoverd(true)
                    setShowResizeBar(prev => resizeBarValid ? true : prev)
                }}
                onMouseLeave={() => {
                    setThHoverd(false)
                    setShowResizeBar(false)
                }}
                style={{
                    ...focusStyle,
                    ...style
                }} >
                {title}
                {renderSorter()}
                {renderResizeBar()}
            </th>
        </Dropdown> :
        <th
            style={{ ...style }}
            onMouseEnter={() => setShowResizeBar(resizeBarValid ? (!grabbingOther ? true : false) : false)}
            onMouseLeave={() => setShowResizeBar(false)}
        >
            {title}
            {renderSorter()}
            {renderResizeBar()}
        </th>
}


const TableSorter = ({ onChange, isLatestOrder }) => {
    //sortOrder: "ascend" | "descend"
    const ASC = "ASC"
    const DESC = "DESC"

    const [sortOrder, setSortOrder] = useState(null)
    const activeStyle = { color: "#1890ff" }
    const getActiveStyle = (sOrder) => {
        return sortOrder === sOrder ? activeStyle : {}
    }

    const latestOrderStyle = isLatestOrder ? { opacity: 1 } : { opacity: .5 }

    return (
        <div className="table-sorter">
            <CaretUpOutlined
                style={{
                    ...getActiveStyle(ASC),
                    ...latestOrderStyle
                }}
                onClick={() => {
                    const order = sortOrder !== ASC ? ASC : null
                    setSortOrder(order)
                    onChange && onChange({ order })
                }} />
            <CaretDownOutlined
                style={{
                    ...getActiveStyle(DESC),
                    ...latestOrderStyle
                }}
                onClick={() => {
                    const order = sortOrder !== DESC ? DESC : null
                    setSortOrder(order)
                    onChange && onChange({ order })
                }} />
        </div>
    )
}