
import React, { useState, useEffect } from 'react';

import { make as ElementRenderer} from "bwax-ui/ml/widget/ElementRenderer.bs";

import {
    getFunctionDependedFieldPaths,
} from './helpers/getFieldsToUse';

import admin_widget_helper from './helpers/admin_widget_helper.bs'
import invariant from 'invariant';

import AdminWidget from './AdminWidget';

export default function RecordView (props) {

    const { context, query_config, viewFn, config } = props;

    const {
        page_id, dts, route_to, bwax, refreshedAt, refreshPage,
        contextRecordId, contextEntityName,

        onMsg,
        
    } = context;

    const { title, description } = config;
    
    const allEntities = bwax.entities;
    const [entityName, _] = query_config;
    const entity = allEntities.find(e => e.name == entityName);

    const paths = getFunctionDependedFieldPaths(viewFn, { bwax, entityName, dts });

    const [ recordValue, setRecordValue ] = useState(undefined);

    async function queryData(options, callbackGuard) {
        const [entityName] = query_config;

        const queryObj = {
            entityName,
            query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort
            // 其他参数
            fieldPaths: [...paths ]

        };
        const [result, error] = await bwax.findOne(queryObj, options);

        // TODO error handling
        if (!error) {
            if(callbackGuard === undefined || callbackGuard()) {

                if(result) {
                    const recordValue = admin_widget_helper.transform_record(
                        bwax.entity_dict, bwax.data_type_dict, entityName, result
                    )
                    setRecordValue(recordValue);
                } else {
                    setRecordValue(null);
                }
            }
        }

    }

    useEffect(() => {
        let isActive = true;
        queryData({}, () => {     
            return isActive
        } );
        return () => { isActive = false }
    }, [
        bwax, JSON.stringify(query_config), refreshedAt,
        paths.join(";") 
    ]);

    if(recordValue === undefined) {
        return "Loading..."
    } else if(recordValue === null) {
        return "Not found."
    } else {

        const element = admin_widget_helper.apply_value_to_element(viewFn, recordValue);

        invariant(element, "ViewFn should return an elemnt");

        return (
            <AdminWidget {...{
                className: "admin--record-view", title, description
            }}>
                <ElementRenderer
                    {...{
                        element,
                        onMsg
                    }}
                />
            </AdminWidget>
        )
    }


}


export function create(props) {
    return <RecordView {...props} />
}
