// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var JsonUtils$BwaxAdmin = require("../../../bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function get(k, l) {
  var match = Plate$BwaxAdmin.List.assoc(k, l);
  if (match !== undefined) {
    return Caml_option.valFromOption(match);
  }
  
}

function set(k, v, l) {
  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              k,
              v
            ],
            l
          ]);
}

var keys = Plate$BwaxAdmin.List.assoc_keys;

function dictToOptionAssoc(d) {
  return Plate$BwaxAdmin.List.map((function (param) {
                return /* tuple */[
                        param[0],
                        Caml_option.some(param[1])
                      ];
              }), Curry._1(Plate$BwaxAdmin.List.from_array, Js_dict.entries(d)));
}

function optionAssocToDict(al) {
  return Js_dict.fromList(Plate$BwaxAdmin.List.keep_map((function (param) {
                    var k = param[0];
                    return Plate$BwaxAdmin.$$Option.map((function (v) {
                                  return /* tuple */[
                                          k,
                                          v
                                        ];
                                }), param[1]);
                  }), Plate$BwaxAdmin.List.rev(al)));
}

var mergeWith = Belt_List.concat;

function takeInExcluding(obj, excludingKeys, subj) {
  return Plate$BwaxAdmin.List.foldl((function (acc, param) {
                var k = param[0];
                if (Plate$BwaxAdmin.List.is_in((function (a, b) {
                          return a === b;
                        }), excludingKeys, k)) {
                  return acc;
                } else {
                  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              k,
                              param[1]
                            ],
                            acc
                          ]);
                }
              }), subj, obj);
}

function toDict(al) {
  return Js_dict.fromList(Plate$BwaxAdmin.List.rev(al));
}

function deriveDirtyValues(validated, lastSaved) {
  return Plate$BwaxAdmin.List.foldl((function (acc, key) {
                var v = get(key, validated);
                var s = get(key, lastSaved);
                var dirty = v !== undefined ? (
                    s !== undefined ? !JsonUtils$BwaxAdmin.isJsonEqual(Caml_option.valFromOption(v), Caml_option.valFromOption(s)) : true
                  ) : s !== undefined;
                if (dirty) {
                  return set(key, v, acc);
                } else {
                  return acc;
                }
              }), /* [] */0, keys(validated));
}

function buildInitialState(defaultValues, initialValues, fixedValues) {
  var first = Belt_List.concat(defaultValues, initialValues);
  var actualInitialValues = Belt_List.concat(first, fixedValues);
  var partial_arg = Plate$BwaxAdmin.$$Option.and_then;
  return /* record */Caml_chrome_debugger.record([
            "actualInitialValues",
            "errors",
            "editing",
            "validated",
            "dirtyValues",
            "lastSaved"
          ], [
            actualInitialValues,
            Plate$BwaxAdmin.List.assoc_map((function (param) {
                    return partial_arg(Js_json.decodeString, param);
                  }), /* [] */0),
            actualInitialValues,
            actualInitialValues,
            Belt_List.concat(defaultValues, fixedValues),
            initialValues
          ]);
}

function reducer(model, action) {
  switch (action.tag | 0) {
    case /* UpdateEditing */0 :
        var theKeys = action[0];
        var editing = takeInExcluding(model[/* editing */2], theKeys, action[1]);
        var errors = takeInExcluding(model[/* errors */1], theKeys, action[2]);
        var validated = takeInExcluding(model[/* validated */3], theKeys, action[3]);
        var dv = deriveDirtyValues(validated, model[/* lastSaved */5]);
        var dirtyValues = takeInExcluding(model[/* dirtyValues */4], theKeys, dv);
        return /* record */Caml_chrome_debugger.record([
                  "actualInitialValues",
                  "errors",
                  "editing",
                  "validated",
                  "dirtyValues",
                  "lastSaved"
                ], [
                  model[/* actualInitialValues */0],
                  errors,
                  editing,
                  validated,
                  dirtyValues,
                  model[/* lastSaved */5]
                ]);
    case /* ClearEditing */1 :
        var cleared = action[1];
        var theKeys$1 = action[0];
        var editing$1 = takeInExcluding(model[/* editing */2], theKeys$1, cleared);
        var validated$1 = takeInExcluding(model[/* validated */3], theKeys$1, cleared);
        var errors$1 = takeInExcluding(model[/* errors */1], theKeys$1, /* [] */0);
        var dv$1 = deriveDirtyValues(cleared, model[/* lastSaved */5]);
        var dirtyValues$1 = takeInExcluding(model[/* dirtyValues */4], theKeys$1, dv$1);
        return /* record */Caml_chrome_debugger.record([
                  "actualInitialValues",
                  "errors",
                  "editing",
                  "validated",
                  "dirtyValues",
                  "lastSaved"
                ], [
                  model[/* actualInitialValues */0],
                  errors$1,
                  editing$1,
                  validated$1,
                  dirtyValues$1,
                  model[/* lastSaved */5]
                ]);
    case /* Rollback */2 :
        var theKeys$2 = action[0];
        var toRollback = Belt_List.reduce(theKeys$2, /* [] */0, (function (acc, key) {
                var v = get(key, model[/* lastSaved */5]);
                return set(key, v, acc);
              }));
        var editing$2 = takeInExcluding(model[/* editing */2], theKeys$2, toRollback);
        var validated$2 = takeInExcluding(model[/* validated */3], theKeys$2, toRollback);
        var errors$2 = takeInExcluding(model[/* errors */1], theKeys$2, /* [] */0);
        var dirtyValues$2 = takeInExcluding(model[/* dirtyValues */4], theKeys$2, /* [] */0);
        return /* record */Caml_chrome_debugger.record([
                  "actualInitialValues",
                  "errors",
                  "editing",
                  "validated",
                  "dirtyValues",
                  "lastSaved"
                ], [
                  model[/* actualInitialValues */0],
                  errors$2,
                  editing$2,
                  validated$2,
                  dirtyValues$2,
                  model[/* lastSaved */5]
                ]);
    case /* MarkSaved */3 :
        var theKeys$3 = action[0];
        var toMark = Belt_List.reduce(theKeys$3, /* [] */0, (function (acc, key) {
                var v = get(key, model[/* validated */3]);
                return set(key, v, acc);
              }));
        var lastSaved = takeInExcluding(model[/* lastSaved */5], theKeys$3, toMark);
        var dirtyValues$3 = takeInExcluding(model[/* dirtyValues */4], theKeys$3, /* [] */0);
        return /* record */Caml_chrome_debugger.record([
                  "actualInitialValues",
                  "errors",
                  "editing",
                  "validated",
                  "dirtyValues",
                  "lastSaved"
                ], [
                  model[/* actualInitialValues */0],
                  model[/* errors */1],
                  model[/* editing */2],
                  model[/* validated */3],
                  dirtyValues$3,
                  lastSaved
                ]);
    case /* Reset */4 :
        return buildInitialState(action[0], action[1], action[2]);
    case /* SetErrors */5 :
        return /* record */Caml_chrome_debugger.record([
                  "actualInitialValues",
                  "errors",
                  "editing",
                  "validated",
                  "dirtyValues",
                  "lastSaved"
                ], [
                  model[/* actualInitialValues */0],
                  takeInExcluding(model[/* errors */1], action[0], action[1]),
                  model[/* editing */2],
                  model[/* validated */3],
                  model[/* dirtyValues */4],
                  model[/* lastSaved */5]
                ]);
    
  }
}

function useEditingState(allKeys, defaultValues, initialValues, validators, fixedValues, stateKey, cacheEnabled) {
  var match = React.useReducer(reducer, buildInitialState(defaultValues, initialValues, fixedValues));
  var dispatch = match[1];
  var state = match[0];
  var getValidator = function (key) {
    var alwaysValid = function (v) {
      return /* record */Caml_chrome_debugger.record([
                "error",
                "goodValue"
              ], [
                undefined,
                v
              ]);
    };
    var match = get(key, validators);
    if (match !== undefined) {
      return match;
    } else {
      return alwaysValid;
    }
  };
  var limitKeys = function (keys) {
    if (keys !== undefined) {
      return Belt_List.keep(keys, (function (k) {
                    return Belt_List.some(allKeys, (function (v) {
                                  return v === k;
                                }));
                  }));
    } else {
      return allKeys;
    }
  };
  var updateEditing = function (values) {
    var theKeys = limitKeys(keys(values));
    var match = Belt_List.reduce(theKeys, /* tuple */[
          /* [] */0,
          /* [] */0
        ], (function (acc, key) {
            var validated = acc[1];
            var errors = acc[0];
            var value = get(key, values);
            var match = Curry._1(getValidator(key), value);
            var error = match[/* error */0];
            if (error !== undefined) {
              return /* tuple */[
                      set(key, error, errors),
                      validated
                    ];
            } else {
              return /* tuple */[
                      errors,
                      set(key, match[/* goodValue */1], validated)
                    ];
            }
          }));
    return Curry._1(dispatch, /* UpdateEditing */Caml_chrome_debugger.variant("UpdateEditing", 0, [
                  theKeys,
                  values,
                  match[0],
                  match[1]
                ]));
  };
  var setErrors = function (errors) {
    var theKeys = limitKeys(keys(errors));
    var errorsInScope = Belt_List.reduce(theKeys, /* [] */0, (function (acc, key) {
            var error = get(key, errors);
            return set(key, error, acc);
          }));
    return Curry._1(dispatch, /* SetErrors */Caml_chrome_debugger.variant("SetErrors", 5, [
                  theKeys,
                  errorsInScope
                ]));
  };
  var clearEditing = function (keys) {
    var theKeys = limitKeys(Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, keys));
    var cleared = Belt_List.reduce(theKeys, /* [] */0, (function (acc, key) {
            var ivo = get(key, state[/* actualInitialValues */0]);
            return set(key, ivo, acc);
          }));
    return Curry._1(dispatch, /* ClearEditing */Caml_chrome_debugger.variant("ClearEditing", 1, [
                  theKeys,
                  cleared
                ]));
  };
  var rollback = function (keys) {
    var theKeys = limitKeys(Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, keys));
    return Curry._1(dispatch, /* Rollback */Caml_chrome_debugger.variant("Rollback", 2, [theKeys]));
  };
  var markSaved = function (keys) {
    var theKeys = limitKeys(Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, keys));
    return Curry._1(dispatch, /* MarkSaved */Caml_chrome_debugger.variant("MarkSaved", 3, [theKeys]));
  };
  var reset = function (defaultValues, initialValues, fixedValues) {
    return Curry._1(dispatch, /* Reset */Caml_chrome_debugger.variant("Reset", 4, [
                  defaultValues,
                  initialValues,
                  fixedValues
                ]));
  };
  return /* record */Caml_chrome_debugger.record([
            "errors",
            "editing",
            "validated",
            "dirtyValues",
            "lastSaved",
            "updateEditing",
            "setErrors",
            "markSaved",
            "rollback",
            "clearEditing",
            "reset"
          ], [
            state[/* errors */1],
            state[/* editing */2],
            state[/* validated */3],
            state[/* dirtyValues */4],
            state[/* lastSaved */5],
            updateEditing,
            setErrors,
            markSaved,
            rollback,
            clearEditing,
            reset
          ]);
}

function useEditingStateAsJs(allKeys, initialValues, fixedValues, defaultValues, validators, stateKey, cacheEnabled) {
  var match = useEditingState(Belt_List.fromArray(allKeys), dictToOptionAssoc(defaultValues), dictToOptionAssoc(initialValues), Curry._1(Plate$BwaxAdmin.List.from_array, validators), dictToOptionAssoc(fixedValues), stateKey, cacheEnabled);
  var reset = match[/* reset */10];
  var setErrors = match[/* setErrors */6];
  var updateEditing = match[/* updateEditing */5];
  return {
          errors: optionAssocToDict(match[/* errors */0]),
          validated: optionAssocToDict(match[/* validated */2]),
          editing: optionAssocToDict(match[/* editing */1]),
          lastSaved: optionAssocToDict(match[/* lastSaved */4]),
          dirtyValues: optionAssocToDict(match[/* dirtyValues */3]),
          updateEditing: (function (v) {
              return Curry._1(updateEditing, dictToOptionAssoc(v));
            }),
          setErrors: (function (es) {
              return Curry._1(setErrors, dictToOptionAssoc(es));
            }),
          markSaved: match[/* markSaved */7],
          rollback: match[/* rollback */8],
          clearEditing: match[/* clearEditing */9],
          reset: (function (defaultValues, initialValues, fixedValues) {
              return Curry._3(reset, dictToOptionAssoc(defaultValues), dictToOptionAssoc(initialValues), dictToOptionAssoc(fixedValues));
            })
        };
}

exports.get = get;
exports.set = set;
exports.keys = keys;
exports.dictToOptionAssoc = dictToOptionAssoc;
exports.optionAssocToDict = optionAssocToDict;
exports.mergeWith = mergeWith;
exports.takeInExcluding = takeInExcluding;
exports.toDict = toDict;
exports.deriveDirtyValues = deriveDirtyValues;
exports.buildInitialState = buildInitialState;
exports.reducer = reducer;
exports.useEditingState = useEditingState;
exports.useEditingStateAsJs = useEditingStateAsJs;
/* react Not a pure module */
