
import React, { useState, useEffect, useRef } from 'react';

import Loading from 'Client/js/components/Loading';

import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

import groupByTags, { buildTagFolderNodes } from './groupByTag';

import { getGlobalEventBus } from 'Client/js/studioApp/EventBus';
import isEqual from 'lodash/isEqual';

export function UISelectPanel(props) {

    const { facade, renderSelectorNode, itemNode, baseCriteria, multitenantType } = props;

    const baseCriteriaRef = useRef();
    baseCriteriaRef.current = baseCriteria;
    // 

    const [pages, setPages] = useState([]);
    const [pageComponents, setPageComponents] = useState([]);
    const [pageFragments, setPageFragments] = useState([]);
    const [pageRedirects, setPageRedirects] = useState([]);


    const itemStateSetters = {
        "page": setPages,
        "page-component": setPageComponents,
        "page-fragment": setPageFragments,
        "page-redirect": setPageRedirects,
    };


    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("updateItemData", ({ itemType, itemData }) => {
            // update the current item, opened items, and tell the upper layer:
            const setDataState = itemStateSetters[itemType];
            if(setDataState) {
                setDataState(prev => {
                    return  prev.map(e => e.id === itemData.id ? { ...e, ...itemData } : e )                    
                })
            }
           
        });
        return () => unsubscribe()

    }, [])


    function updateData(criteria, result, setState) {
        
        setState(prev => {

            if (criteria && criteria.标签 && result && result.data && prev) {
                // 只是刷新标签，TODO 暂时不考虑“删除的情况”
                return [
                    ...prev.map(e => {
                        const newOne = result.data.find(ne => ne.id === e.id);
                        if (newOne) {
                            return { ...e, ...newOne }
                        } else {
                            return e;
                        }
                    }),
                    ...result.data.filter(e => {
                        return prev.every(oe => oe.id !== e.id)
                    })
                ]
            } else {
                return result.data;
            }
        });
    }

    async function queryPages(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "页面",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: [
                "名称",
                "描述",
                "显示权重", "创建时间", "标签",
                "多租户",
            ],
        };
        const [result, error] = await facade.listAll(queryObj, options);
        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {

            updateData(criteria, result, setPages);
            // setPages(result.data);

        }
    }

    async function queryPageComponents(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "页面组件",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: ["名称", "标签", "描述", "显示权重", "创建时间", "公开", "多租户"],

        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            // setPageComponents(result.data);
            updateData(criteria, result, setPageComponents);
        }
    }

    async function queryPageFragments(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "页面片段",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: ["名称", "标签", "描述", "显示权重", "创建时间", "多租户"],

        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            // setPageFragments(result.data);
            updateData(criteria, result, setPageFragments);
        }
    }


    async function queryPageRedirects(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "页面跳转",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: ["名称", "页面路径", "目标路径", "创建时间", "多租户"],

        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            // setPageRedirects(result.data);
            updateData(criteria, result, setPageRedirects);
        }

    }


    useEffect(() => {
        queryPages({});
        queryPageComponents({});
        queryPageFragments({});
        queryPageRedirects({});
    }, [ JSON.stringify(baseCriteria) ]);

    async function reloadItemList(itemType, parentItem) {

        const itemFolderReloaders = {
            "page": criteria => queryPages(criteria, { forceRefreshing: true }),
            "page-component": criteria => queryPageComponents(criteria, { forceRefreshing: true }),
            "page-fragment": criteria => queryPageFragments(criteria, { forceRefreshing: true }),
            "page-redirect": criteria => queryPageRedirects(criteria, { forceRefreshing: true }),
        };
        if (parentItem && parentItem.itemType) {
            const parentItemType = parentItem.itemType;
            if (parentItemType.endsWith("-tag-folder")) {
                const folderType = parentItem.itemType.replace("-tag-folder", "");
                const tagName = parentItem.getName();
                await (itemFolderReloaders[folderType] || (async _ => { }))({ 标签: { contains: tagName } })


            } else if (parentItemType.endsWith("-folder")) {
                const folderType = parentItem.itemType.replace("-folder", "");
                await (itemFolderReloaders[folderType] || (async _ => { }))({})
            }


        }
    }

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("reloadItemList", ({ itemType, parentItem }) => {
            reloadItemList(itemType, parentItem);
        });
        return () => {
            unsubscribe();
        }
    }, [] ) 


    if (pages === undefined
        || pageComponents === undefined
        || pageFragments === undefined) {
        return <Loading />
    }

    // build nodes;

    const sorting = (a, b) => {
        // 给 field 排序, 根据 displayWeight 从大到下排列
        const getWeight = f => f["显示权重"] || 0
        const weightOrder = getWeight(b) - getWeight(a);

        if (weightOrder === 0) {
            const getTime = f => (new Date(f["创建时间"])).getTime();
            return getTime(b) - getTime(a);

        } else {
            return weightOrder
        }
    }

    function buildSubNodes(objs, itemType) {
        const [taggedGroups, untagged] = groupByTags(objs);

        return buildTagFolderNodes(taggedGroups, untagged, itemType + "-tag-folder", itemNode,
            p => itemNode({ itemType, data: p, multitenantType })
        );
    }


    const nodes = [
        itemNode(
            { name: "客户端页面", itemType: "page-folder", multitenantType },
            buildSubNodes(pages, "page")
        ),
        itemNode(
            { name: "页面组件", itemType: "page-component-folder", multitenantType },
            buildSubNodes(pageComponents, "page-component"),
        ),
        itemNode(
            { name: "页面片段", itemType: "page-fragment-folder", multitenantType },
            buildSubNodes(pageFragments, "page-fragment"),
        ),
        itemNode(
            { name: "页面跳转", itemType: "page-redirect-folder", multitenantType },
            buildSubNodes(pageRedirects, "page-redirect"),
        )
    ];


    return (
        <div className="panel-content">
            {nodes.map(n => renderSelectorNode(n, 0))}
        </div>
    )
}

export default React.memo(UISelectPanel, (prevProps, nextProps) => {
    return isEqual(prevProps.baseCriteria, nextProps.baseCriteria) && isEqual(prevProps.currentItem, nextProps.currentItem);
});


