import React from 'react'
import queryString from 'query-string'

import VirtualizedPDFReader from 'bwax-ui/lazy/VirtualizedPDFReader'

export default function PDFReader(props) {

    const { location } = props
    if (!location) {
        return null
    }

    const parsed = queryString.parse(location.search)
    const { file } = parsed

    if (!file) {
        return (
            <h3>
                Oop～ 没找到对应的文件
            </h3>
        )
    }
    return (
        <VirtualizedPDFReader src={file} />
    )
}
