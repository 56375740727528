
import React, { Suspense } from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import { isQQPlayURL, compatibleWithQQURL } from './mediaTypes'
import { defaultLOCALVideoOptions } from './mediaPluginHelpers'
import { removeBlock, mergeBlockData } from 'bwax-ui/auxiliary/richtext/util/EditorUtil'
import { ratioInnerStyle, ratioOuterStyle, ratioLOCALOuterStyle, iframeStyle } from './styles'

const Video = React.lazy(() => import('bwax-ui/auxiliary/video/Video'))

// import Video from "bwax-ui/auxiliary/video/Video";

function VideoBlock(props) {

    const { className, onClick, block, blockProps, offsetKey } = props

    function renderQQVideo(playURL) {
        return (
            <iframe
                className="video_iframe"
                style={{
                    ...ratioInnerStyle,
                    ...iframeStyle
                }}
                src={compatibleWithQQURL(playURL)}
                frameBorder={0}
                allowFullScreen={true} />
        )
    }

    function renderLOCALVideo(playURL) {
        return (
            <Suspense fallback={null}>
                <Video
                    options={{
                        ...defaultLOCALVideoOptions,
                        poster: playURL + '?x-oss-process=video/snapshot,t_10000,m_fast,ar_auto'
                    }}>
                    <source src={playURL} type="video/mp4"></source>
                    <source src={playURL} type="video/ogg"></source>
                    <source src={playURL} type="video/webm"></source>
                    <p className="vjs-no-js">
                        观看该视频请先允许下载脚本JavaScript，然后保证你的浏览器
                        <a href="http://videojs.com/html5-video-support/" target="_blank">
                            支持html5视频
                        </a>
                    </p>
                </Video>
            </Suspense>
        )
    }

    function renderVideo(playURL) {
        if (isQQPlayURL(playURL)) {
            return renderQQVideo(playURL)
        } else {
            return renderLOCALVideo(playURL)
        }
    }

    const playURL = block.getData().get("playURL")

    return (
        <div style={isQQPlayURL(playURL) ? { ...ratioOuterStyle } : { ...ratioLOCALOuterStyle }}
            className={"editor-atomic-block editor-video-block " + className}
            onClick={onClick}
            data-offset-key={offsetKey}
        >
            {renderVideo(playURL)}
            {/* <div className="editor-video-block-scarf"/> */}
            <div className="editor-video-block-tool">
                {createRemoveBlockButton(block)(blockProps)}
                <span className="seperator"></span>
                {createCopyLinkButton(block)(blockProps)}
            </div>
        </div>
    )
}

function AudioBlock(props) {
    const { block, onClick, offsetKey, className, direction } = props
    const playURL = block.getData().get("playURL")
    const align = block.getData().get("align")

    return (
        <div
            onClick={onClick}
            data-offset-key={offsetKey}
            direction={direction}
            style={{ textAlign: align || 'left' }}
            className={`editor-atomic-block editor-audio-block ${className}`}>
            <audio style={{ verticalAlign: 'middle' }} controls >
                <source src={playURL} type="audio/mpeg" />
                <source src={playURL} type="audio/wav" />
                <source src={playURL} type="audio/ogg" />
                <p>暂不支持播放该音频，你可以<a href={playURL}>下载</a>这个音频文件。</p>
            </audio>
        </div>
    )
}

export default (config = {}) => {

    const VideoComponent = config.decorator ? config.decorator(VideoBlock) : VideoBlock
    const AudioComponent = config.decorator ? config.decorator(AudioBlock) : AudioBlock

    return {
        blockRendererFn: (block, { getEditorState, setEditorState }) => {
            if (block.getType() === 'atomic') {
                const type = block.getData().get("type")
                if (type === "VIDEO" || type === "AUDIO") {
                    return {
                        component: type === "VIDEO" ? VideoComponent : AudioComponent,
                        editable: false,
                        props: {
                            getEditorState,
                            setEditorState
                        }
                    }
                }
            }
            return null
        },

        buttonsForBlock: (block) => {
            if (block.getType() === 'atomic' && block.getData().get('type') === 'AUDIO') {
                return [
                    createAlignButton('left', 'fa fa-long-arrow-left'),
                    createAlignButton('center', 'fa fa-laptop'),
                    createAlignButton('right', 'fa fa-long-arrow-right'),
                    'seperator',
                    createRemoveBlockButton(block),
                    'seperator',
                    createCopyLinkButton(block)
                ]
            }
        }
    };
};

function preventBubbleUp(e) {
    e.preventDefault()
    e.stopPropagation()
}

function createCopyLinkButton(block) {
    return (props) => {
        const info = block.getData().get('src')
        return (
            <button className="editor-button">
                {
                    CopyToClipboard ?
                        <CopyToClipboard text={info} onCopy={() => alert('复制视频链接成功')}>
                            <i className="fa fa-clone" aria-hidden="true"></i>
                        </CopyToClipboard> :
                        <i className="fa fa-clone" aria-hidden="true"></i>
                }
            </button>
        )
    }
}

function createRemoveBlockButton(block) {
    return (props) => {
        const { setEditorState, getEditorState } = props
        return (
            <button className="editor-button" onClick={(e) => {
                preventBubbleUp(e)
                setEditorState(
                    removeBlock(getEditorState(), block.getKey())
                )
            }}>
                <i className={'fa fa-trash'} aria-hidden="true"></i>
            </button>
        )
    }
}

function createAlignButton(align, iconClass) {
    return (props) => {

        const { setEditorState, getEditorState } = props
        return (
            <button className="editor-button" onClick={() => {
                setEditorState(
                    mergeBlockData(getEditorState(), null, { align })
                )
            }}>
                <i className={iconClass} aria-hidden="true"></i>
            </button>
        )
    }
}
