
import React from 'react'

import { EditorState, SelectionState } from 'draft-js'

import {
    getSelectedInlineStyles
} from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

import {
    applyAllStyles
} from '../inlineStyle'

export default class FormatBrushButton extends React.Component {

    componentDidMount() {
        this.currentEditorState = this.props.getEditorState()
    }

    componentWillReceiveProps(nextProps) {

        const { updateSupplementClassNames, setEditorState, getEditorState,
            forceInlineToolbarVisible } = nextProps

        const currentSelection = this.currentEditorState.getSelection()
        const nextSelection = nextProps.getEditorState().getSelection()

        // console.log("RP: ", currentSelection.equals(nextSelection), Date.now(), currentSelection.toJS(), nextSelection.toJS())
        if (!currentSelection.equals(nextSelection) && this.brushingActivated) {

            setEditorState(
                applyAllStyles(getEditorState(), this.brushingActivated)
            )

            this.brushingActivated = null
            forceInlineToolbarVisible(null)

            updateSupplementClassNames('brushing', false)
        }

        this.currentEditorState = nextProps.getEditorState()

    }

    render() {
        const { updateSupplementClassNames, getEditorState, setEditorState,
            forceInlineToolbarVisible
        } = this.props

        const icon = <i className={'fa fa-paint-brush'} aria-hidden="true"></i>

        const activateBrushing = () => {

            const editorState = getEditorState()
            const allStyles = getSelectedInlineStyles(editorState)

            forceInlineToolbarVisible(false)

            updateSupplementClassNames('brushing', true)

            this.brushingActivated = allStyles

        }

        return (
            <button className={"editor-button"} onClick={activateBrushing}>
                {icon}
            </button>
        )
    }

}
