import React from 'react'

import FileUploader from 'Client/js/components/FileUploader'

//value <-> [{value}]

export default function File(props) {
    const { value, onChange, params } = props
    const { multivalued, uploadFor, isPublic } = params
    
    return (
        <FileUploader
            value={value}
            onChange={onChange}
            isPublic={isPublic}
            multivalued={multivalued}
            uploadFor={uploadFor}
        />
    )
}
