import invariant from 'invariant'

import buildQuerySelection from './buildQuerySelection'

const pu = " " // pad unit

export function processInputValue(field, inputValue) {
    if (inputValue === undefined || inputValue === null) {
        return inputValue
    }

    const attachmentProcessor = inputValue => {
        const getRemainingAttachment = attachment => {
            const { id, url, ...remaining } = attachment
            return remaining
        }
        if (Array.isArray(inputValue)) {
            return inputValue.map(iv => {
                return getRemainingAttachment(iv)
            })
        } else {
            return getRemainingAttachment(inputValue)
        }
    }

    const posterProcessor = inputValue => {
        const { backgroundImage } = inputValue
        const { id, url, ...remaining } = backgroundImage || {}
        return {
            ...inputValue,
            backgroundImage: remaining
        }
    }

    const processors = {
        File: attachmentProcessor,
        Image: attachmentProcessor,
        PosterSetting: posterProcessor,
        Link: inputValue => {
            if (Array.isArray(inputValue)) {
                return inputValue.map(iv => iv.id)
            } else {
                const { id } = inputValue
                return id ? id : inputValue
            }
        }
    }

    const defaultProcessor = v => v
    const p = processors[field.type] || defaultProcessor

    return p(inputValue)
}


export function processInputData (entity, inputData) {
    return Object.keys(inputData).reduce((acc, name) => {
        const field = entity.fields.find(f => f.name === name)
        invariant(field, `not found field ${name} for entity ${entity.name}`)
        /// invariant(field.initializable, `field is not initializable ${field.name}`)

        const inputName = (field.type === "Link" && !field.multivalued ? `${field.key}Id` : field.key)
        return { ...acc, [inputName]: processInputValue(field, inputData[name]) }
    }, {})
}

const addInputBuilder = entity => (inputData, clientMutationId) => {

    let input =  processInputData(entity, inputData)
    return {
        ...input,
        clientMutationId: clientMutationId || "mutation_" + Date.now()
    }

}

export function buildAddRecordMutation(entity, allEntities, allDataTypes, options = {}) {

    const { key } = entity

    const queryText = `mutation ($input: Add${key}Input!) {\n`
        + pu + `add${key} (input: $input) {\n`
        + pu + pu + `clientMutationId\n`
        + pu + pu + `added${key}Edge {\n`
        + pu + pu + pu + "node {\n id"
        // + buildQuerySelection(
        //     { isEntity: true, ...entity },
        //     allEntities, allDataTypes, options, 4)
        + pu + pu + pu + "}\n"
        + pu + pu + "}\n"
        + pu + "}\n"
        + "}\n"

    return {
        queryText,
        buildInput: addInputBuilder(entity),
        extractResult: (text) => {
            return JSON.parse(text).data[`add${key}`][`added${key}Edge`].node
        }
    }
}

const updateInputBuilder = entity => (id, inputData, clientMutationId) => {

    const values = processInputData(entity, inputData)

    return {
        clientMutationId: clientMutationId || "mutation_" + Date.now(),
        values,
        id
    }
}


export function buildUpdateRecordMutation(entity, allEntities, allDataTypes, options = {}) {

    const { key } = entity

    const queryText = `mutation ($input: Update${key}Input!) {\n`
        + pu + `update${key} (input: $input) {\n`
        + pu + pu + `clientMutationId\n`
        + pu + pu + `changed${key} { id `
        // + buildQuerySelection(
        //     { isEntity: true, ...entity }, allEntities, allDataTypes, options, 3)
        + pu + pu + "}\n"
        + pu + "}\n"
        + "}\n"

    return {
        queryText,
        buildInput: updateInputBuilder(entity),
        extractResult: (text) => {
            return JSON.parse(text).data[`update${key}`][`changed${key}`]
        }
    }
}


const deleteInputBuilder = _ => (id, clientMutationId) => {

    return {
        clientMutationId: clientMutationId || "mutation_" + Date.now(),
        id
    }
}

export function buildDeleteRecordMutation(entity, allEntities, allDataTypes, options = {}) {
    const { key } = entity
    const queryText = `mutation ($input: Delete${key}Input!) {\n`
        + pu + `delete${key} (input: $input) {\n`
        + pu + pu + `clientMutationId\n`
        + pu + pu + `deleted${key} {\n`
        + buildQuerySelection(
            { isEntity: true, ...entity }, allEntities, allDataTypes, options, 3)
        + pu + pu + "}\n"
        + pu + "}\n"
        + "}\n"

    return {
        queryText,
        buildInput: deleteInputBuilder(entity),
        extractResult: (text) => {
            return JSON.parse(text).data[`delete${key}`][`deleted${key}`]
        }
    }

}


export function buildListRecordQuery(entity, allEntities, allDataTypes, options = {}) {

    const { variables, alias } = options

    const { key } = entity

    /// variables only support first, after and condition for now:
    const variableNames = Object.keys(variables)
    const variableMapping = {
        after: "String",
        first: "Float",
        condition: `[List${key}ConditionInput!]`,
        keywords: `List${key}KeywordsInput`
    }

    const buildVariableText = () => {
        if (!variables || Object.keys(variables) === 0) {
            return ""
        }

        const text = variableNames.reduce((acc, current) => {
            const mapped = variableMapping[current]
            if (mapped) {
                return acc + (acc.length === 0 ? "" : ", ") + `$${current}: ${mapped}`
            } else {
                return acc
            }
        }, "")
        return (text.length > 0 ? `(${text})` : "")
    }

    const variableText = buildVariableText()
    const aliasText = alias ? `${alias}: ` : ""

    const input = variableNames.reduce((acc, current) => {
        const mapped = variableMapping[current]
        if (mapped) {
            return acc + (acc.length === 0 ? "" : ", ") + `${current}: $${current}`
        } else {
            return acc
        }
    }, "")

    const inputText = (input.length > 0 ? `(${input})` : "")

    const queryText = `query ${variableText} {\n`
        + pu + `${aliasText}list${key} ${inputText} {\n`
        + pu + pu + `count\n`
        + pu + pu + `edges {\n`
        + pu + pu + pu + "cursor\n"
        + pu + pu + pu + "node {\n"
        + buildQuerySelection(
            { isEntity: true, ...entity }, allEntities, allDataTypes, options, 4)
        + pu + pu + pu + "}\n"
        + pu + pu + "}\n"
        + pu + "}\n"
        + "}\n"

    return {
        queryText,
        extractResult: (text) => {
            // error handling TODO
            return JSON.parse(text).data[`list${key}`]
        }
    }
}