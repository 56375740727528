
import React, { useEffect, useState, useContext, useRef, createRef } from 'react'

import { Input, Radio, Button, Modal } from 'antd'

import Message from 'Client/js/ui/Message';

import { Icon } from '@ant-design/compatible';

const RadioGroup = Radio.Group;
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery } from 'bwax/query/runClientQuery';
import BreadcrumbSimple from 'Client/js/breadcrumb/BreadcrumbSimple';
import Popover from 'bwax-ui/components/legacy/Popover'

import Page from "bwax-ui/layout/Page"

import './MenuEdit.less'
import WxMp_View from './WxMp_View'
import Replies from './Replies'
import YqylPromotion from './YqylPromotion'

export default function MenuEdit (props) {

    const { extensions, facade } = props
    
    // 判断是否开启 YQYL 
    const isYQYLEnabled = (extensions || []).some(e => e.key == "YQYL");

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)
    // const queryRunner = {
    //     'data': runDataQuery({tenantCode, sessionToken, sandbox}),
    //     'definition': runDefinitionQuery({tenantCode, sessionToken})
    // }[queryTarget]

    // 这个几乎不会 query design
    const queryRunner = runDataQuery({tenantCode, sessionToken, sandbox});


    const [ originalMenu, setOriginalMenu ] = useState() 
    const [ menu, setMenu ] = useState()
    const [ currentButtonIdx, setCurrentButtonIdx ] = useState()
    const [ currentSubButtonIdx, setCurrentSubButtonIdx ] = useState()
    const [ focusButton, setFocusButton ] = useState(false)
    const [ currentButton, setCurrentButton ] = useState()
    const [ name, setName ] = useState('')
    const [ contentType, setContentType ] = useState('click')
    const [ modalVisible, setModalVisible ] = useState(false)
    const [ modalType, setModalType ] = useState()
    const [ publishFault, setPublishFault ] = useState()
    const [ rankingMode, setRankingMode ] = useState(false)

    useEffect(() => {
        /// load once:
        (async () => {
            const m = await loadWxMenu(queryRunner, isYQYLEnabled)
            const originalMenu = cloneDeep(m)
            setOriginalMenu(originalMenu)
            setMenu(m)
        })()
    }, [])

    useEffect(() => {
        facade.prepare(["邀请有礼-活动"]);
    });

    // console.log("Menu is ", menu)
    const buttonArr = menu && menu.button ? menu.button : []

    const buttonRefs = buttonArr.map(b => React.createRef())
    const subButtonRefs = buttonArr[currentButtonIdx] ? buttonArr[currentButtonIdx].sub_button.map(b => React.createRef()) : []

    const isMainButton = currentButton && currentButton.sub_button

    function noButtons () {
        return (
            <div className="button-item no-button" onClick={() => addButton()}>
                <Icon type="plus" />
                <span>添加菜单</span>
            </div>
        )
    }

    function addButton () {
        const newButton = {
            type: 'click',
            name: '菜单名称',
            url: null,
            media_id: null,
            replySetting: {
                replyType: "ReplyAll", 
                transferCustomerService: false, // 转入客服默认为 false 
                replies: []
            },
            sub_button: []
        }
        setMenu({...menu, button: [...buttonArr, newButton]})
        setFocusButton(true)
        setCurrentButtonIdx(buttonArr.length)
        setCurrentButton(newButton)
        setName(newButton.name)
        setContentType('click')
        setPublishFault(false)
    }

    function addSubButton (button) {
        let buttons = [...buttonArr]
        const { sub_button } = button
        const newSubButton = {
            type: 'click',
            name: '子菜单名称',
            url: null,
            media_id: null,
            replySetting: {
                replyType: 'ReplyAll',
                transferCustomerService: false,
                replies: []
            }
        }
        const updatedButton = {
            ...button, 
            type: null,
            url: null,
            replySetting: null, 
            sub_button: [...sub_button, newSubButton]
        }

        buttons.splice(currentButtonIdx, 1, updatedButton)
        setMenu({...menu, button: buttons})
        setCurrentSubButtonIdx(sub_button.length)
        setFocusButton(false)
        setCurrentButton(newSubButton)
        setName(newSubButton.name)
        setContentType('click')
        setModalType(undefined)
        setModalVisible(undefined)
        setPublishFault(false)
    }

    function clickButton (button, idx) {
        const { replySetting } = button
        const replies = replySetting ? replySetting.replies : []
        
        setCurrentButtonIdx(button.sub_button ? idx : currentButtonIdx)
        setFocusButton(button.sub_button ? true : false)
        setCurrentSubButtonIdx(button.sub_button ? undefined : idx)
        setCurrentButton(button)
        setName(button.name)
        setContentType(button.type === 'view' ? 'view' : replies.find(r => r.type === 'yqylPromotion') ? 'yqylPromotion' : 'click')
        setPublishFault(false)
    }

    function getTransferCustomerService(button) {
        const { replySetting } = button
        if(button.type === 'click' && replySetting) {
            return replySetting.transferCustomerService
        } else {
            return false
        }
    }

    function rankButtonWithKeyDown (keyCode) {
        let buttons = [...buttonArr]
        let { sub_button } = buttonArr[currentButtonIdx]

        switch (keyCode) {
            case 37:
                moveButton('left', !(isMainButton && currentButtonIdx > 0))
                break;
            case 38:
                moveSubButton('up', isMainButton || currentSubButtonIdx === 0)
                break;
            case 39:
                moveButton('right', !(isMainButton && currentButtonIdx < buttons.length - 1))
                break;
            case 40:
                moveSubButton('down', isMainButton || currentSubButtonIdx === sub_button.length - 1)
                break;
            default:
                break;
        }
    }

    function renderButtons (buttons) {

        const currentButtonWidth = buttons.length < 3 ? 300 / ( buttons.length + 1 ) : 100

        function renderSubButtons(button) {
            const { sub_button } = button

            function showAddSubButtonModal () {
                setModalType('addSub_button')
                setModalVisible(true)
            }

            function shouldAddSubButton(button){
                // console.log('button: ', button)
                const { replySetting } = button
                const replies = replySetting ? replySetting.replies : []
                const transferCustomerService = replySetting ? replySetting.transferCustomerService : false

                if(!button.type) {
                    return true
                } else if(button.type === 'view' && !button.url) {
                    return true
                } else if(button.type === 'click') {
                    return !replies.some(r => r[r.type]) && !transferCustomerService
                } else {
                    return false
                }
            }

            return (
                <div>
                {
                    sub_button.map((subButton, idx) => {
                        return (
                            <div
                                key={idx}
                                ref={subButtonRefs[idx]}
                                className="sub_button-item"
                                style={currentSubButtonIdx === idx ?
                                      { width: currentButtonWidth, borderColor: "#3e8ecd", color: "#3e8ecd" } :
                                      { width: currentButtonWidth }}
                                onClick={() => clickButton(subButton, idx)}
                                tabIndex="0"
                                onKeyDown={e => !rankingMode ? null : rankButtonWithKeyDown(e.keyCode)}
                            >
                                {subButton.name}
                            </div>
                        )
                    })
                }
                {
                    currentButtonIdx !== undefined && sub_button.length < 5 ? (
                        <div className="sub_button-item" style={{ width: currentButtonWidth }}
                            onClick={() => shouldAddSubButton(button) ? addSubButton(button) : showAddSubButtonModal()}
                        >
                            <Icon type="plus" />
                        </div>
                    ) : null
                }
                </div>
            )
        }

        return (
            <div>
            {
                buttons.map((button, idx) => {

                    return (
                        <Popover key={idx} content={renderSubButtons(button)} visible={currentButtonIdx === idx}>
                            <div 
                                ref={buttonRefs[idx]} className="button-item"
                                style={ currentButtonIdx === idx && focusButton ? { 
                                    borderColor: "#3e8ecd", color: "#3e8ecd" 
                                } : null }
                                tabIndex="0"
                                onClick={() => clickButton(button, idx)}
                                onKeyDown={e => !rankingMode ? null : rankButtonWithKeyDown(e.keyCode)}
                                onBlur={() => {
                                    if(rankingMode) {
                                        buttonRefs[idx] && buttonRefs[idx].current && buttonRefs[idx].current.focus()
                                    }
                                    
                                }}
                            >
                                {button.name}
                            </div>
                        </Popover>
                    )
                })
            }
            {
                buttons.length < 3 ? (
                  <div className="button-item" onClick={() => addButton()}>
                      <Icon type="plus" />
                  </div>
                ) : null
            }
            </div>
        )
    }

    function deleteButton () {
        setModalType('delete')
        setModalVisible(true)
    }

    function deleteButtonConfirm () {
        let buttons = [...buttonArr]
        if(!currentButton.sub_button) {
            let { sub_button } = buttonArr[currentButtonIdx]
            sub_button.splice(currentSubButtonIdx, 1)
            let updatedButton
            if(sub_button.length === 0) {
                updatedButton = {
                    ...buttonArr[currentButtonIdx], 
                    type: 'click',
                    url: null,
                    sub_button: [],
                    replySetting: {
                        replyType: "ReplyAll",
                        transferCustomerService: false,
                        replies: []
                    }
                }
            } else {
                updatedButton = {...buttonArr[currentButtonIdx], sub_button: sub_button}
            }
            buttons.splice(currentButtonIdx, 1, updatedButton)
        } else {
            buttons.splice(currentButtonIdx, 1)
        }

        setMenu({...menu, button: buttons})
        setFocusButton(false)
        setCurrentButton(undefined)
        setCurrentButtonIdx(undefined)
        setCurrentSubButtonIdx(undefined)
        setModalType(undefined)
        setModalVisible(false)
        setPublishFault(false)
    }

    function nameValid (type) {
        // const name = currentButton && currentButton.name
        if(!name || name.trim() === "") {
            return {
                error: '请输入菜单名称',
            }
        }
        else {
            let length = 0
            for (let i = 0; i < name.length; i++) {
                if(name[i].match(/[A-Za-z0-9\-\+\.\& ]/)) {
                    length ++;
                } else {
                    length += 2
                }
            }
            if(name.match(/  /) || (name.match(/ /g) && name.match(/ /g).length > 2)) {
                return { error: '空格至多两个且不可连续使用' }
            }
            if((type === 'button' && length > 8) || (type === 'sub_button' && length > 16)){
                return { error: '字数超过上限' }
            } else {
                return { error: undefined }
            }
        }
    }

    function nameInputBlur() {
        const { error } = currentButton.sub_button ? nameValid( "button") : nameValid("sub_button")
        if(error){
            return
        } else {
            updateButtons({...currentButton, name: name})
        }
    }

    function updateButtons(updatedButton) {
        let buttons = [...buttonArr]
        if(!currentButton.sub_button) {
            let { sub_button } = buttonArr[currentButtonIdx]
            sub_button.splice(currentSubButtonIdx, 1, updatedButton)
            const updatedMainButton = {...buttonArr[currentButtonIdx], sub_button: sub_button}
            buttons.splice(currentButtonIdx, 1, updatedMainButton)
        } else {
            buttons.splice(currentButtonIdx, 1, updatedButton)
        }
        setCurrentButton(updatedButton)
        setMenu({...menu, button: buttons})
        setPublishFault(false)
    }

    const { error } = isMainButton ? nameValid("button") : nameValid("sub_button")

    function renderError() {
        return isMainButton && currentButton.sub_button.length >= 0 ? (
            <div className="error">
                <span>{error}</span>
                <span>仅支持中英文和数字,字数不超过4个汉字或8个字母</span>
            </div>
        ) : (
            <div className="error">
                <span >{error}</span>
                <span>仅支持中英文和数字,字数不超过8个汉字或16个字母</span>
            </div>
        )
    }

    function changeContentType(value) {
        setContentType(value)
        if(value !== 'yqylPromotion') {
            updateButtons({
                ...currentButton,
                type: value
            })
        } else {
            updateButtons({
                ...currentButton,
                type: 'click'
            })
        }
    }

    function validateUrl (url) {
        if(!url) {
            return null
        } else {
            const urlRegx = 'https?://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]' // 后台的跳转网页校验规则
        
            if(!url.match(/^https?:\/\//)) {
                return "请输入 http:// 或 https:// 开头的链接"
            } else if(!url.match(urlRegx)) {
                return `无效的 URL`
            } else {
                return null
            }
        }
        
    }

    function renderButtonContent() {
        if(currentButton){
            const { replySetting } = currentButton
            switch (contentType) {
                case "click": 
                    return (
                        <Replies 
                            replies={replySetting ? replySetting.replies : []} 
                            updateReplies={replies => updateReplies(replies)}
                            queryRunner={queryRunner}
                            publishFault={publishFault}
                        />
                    )
                case "view":
                    return (
                        <WxMp_View 
                            url={currentButton.url} 
                            urlInput={url => urlInput(url)} 
                            publishFault={publishFault}
                            validateUrl={url => validateUrl(url)}
                        />
                    )
                case "yqylPromotion":
                    return (
                        <YqylPromotion
                            replies={replySetting ? replySetting.replies : []}
                            updateReplies={replies => updateReplies(replies)} 
                            facade={facade}
                            publishFault={publishFault}
                        />
                    )
                default:
                    return null
            }
        }
    }

    function updateReplies(replies) {
        updateButtons({
            ...currentButton, 
            replySetting: {
                ...currentButton.replySetting,
                replies
            }
        })
    }
  
    function urlInput(url) {
        updateButtons({
            ...currentButton,
            url,
        })
    }

    const hasSubButtons = isMainButton && currentButton.sub_button.length > 0

    function renderContent() {
        const { replySetting } = currentButton
        function toggleCustomerService(value) {
            updateButtons({
                ...currentButton,
                replySetting: {
                    ...currentButton.replySetting,
                    transferCustomerService: value
                }
            })

        }

        function addContent (type) {
            const replies = replySetting ? replySetting.replies : []
            // console.log("ADD CONTENT", type, replies)
            const addContent = isYQYLEnabled ? {
                type,
                text: null,
                mpImage: null,
                mpNews: null,
                yqylPromotion: null
            } : {
                type,
                text: null,
                mpImage: null,
                mpNews: null
            }
            updateButtons({
                ...currentButton,
                replySetting: {
                    ...currentButton.replySetting,
                    replies: replies.concat(addContent)
                }
            })
        }

        const transferCustomerService = replySetting ? replySetting.transferCustomerService : false

        return hasSubButtons ? null : (
            <div>
                {
                        <div className="radio">
                            <label>是否转入客服</label>
                            <RadioGroup 
                                disabled={contentType === 'view' ? true : false}
                                onChange={e => toggleCustomerService(e.target.value)}
                                value={ transferCustomerService }
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </RadioGroup>
                        </div>
                }
                <div className="radio">
                    <label>菜单内容</label>
                    <div>
                        <RadioGroup onChange={e => changeContentType(e.target.value)} value={ contentType }>
                            <Radio value="click">回复消息</Radio>
                            <Radio value="view">跳转网页</Radio>
                            {
                                isYQYLEnabled ? (
                                    <Radio value="yqylPromotion">获取邀请有礼活动海报</Radio>
                                ) : null
                            }
                        </RadioGroup>
                    </div>
                </div>
                { renderButtonContent() }
                {
                    contentType === 'click' ? (
                        <div className="content-footer">
                            <Button onClick={() => addContent('text')}>添加文本消息</Button>
                            <Button onClick={() => addContent('mpImage')}>添加图片消息</Button>
                            <Button onClick={() => addContent('mpNews')}>添加图文消息</Button>
                        </div>
                    ) : contentType === 'yqylPromotion' ? (
                        <div className="content-footer">
                            <Button onClick={() => addContent('yqylPromotion')}>添加邀请有礼活动</Button>
                        </div>
                    ) : null
                }
                
            </div>
        )
    }

    function isDirty () {

        if(isEqual(originalMenu, menu)) {
            return false
        } else {
            return true
        }
    } 

    function hasEmptyContent(button) {
        if(button.type === 'view' && !button.url) {
            return { 
                empty: true, 
                contentType: 'view'
            }
        } else if(button.type === 'click') {
            const { replySetting } = button
            const replies = replySetting ? replySetting.replies : []
            const TYPE = {
                'text': 'click',
                'mpImage': 'click',
                'mpNews': 'click',
                'yqylPromotion': 'yqylPromotion'
            }

            return {
                empty: !replies || 
                    (!getTransferCustomerService(button) && replies.length === 0) || 
                    (replies.length > 0 && replies.some(r => !r[r.type])),
                contentType: !replies || replies.length === 0 ? contentType : 
                    replies.some(r => !r[r.type]) ? TYPE[replies.find(r => !r[r.type]).type] : contentType
            }
        } else {
            return {
                empty: false,
                contentType: undefined
            }
        }
    }

    function showPublishModal() {
        if(hasPublishFault()) {
            return
        } else {
            setModalType('publish')
            setModalVisible(true)
        }
    }

    function hasPublishFault() {
        let publishFaultFlag = false
        buttonArr.map((button, idx) => {
            const { empty, contentType } = hasEmptyContent(button)
            if(empty && button.sub_button.length === 0) {
                setCurrentButton(button)
                setCurrentButtonIdx(idx)
                setContentType(contentType)
                setFocusButton(true)
                setPublishFault(true)
                setName(button.name)
                publishFaultFlag = true
            } else {
                button.sub_button.map((subButton, index) => {
                    const { empty, contentType } = hasEmptyContent(subButton)
                    if(empty) {
                        setCurrentButton(subButton)
                        setPublishFault(true)
                        setCurrentButtonIdx(idx)
                        setCurrentSubButtonIdx(index)
                        setContentType(contentType)
                        setFocusButton(false)
                        setName(subButton.name)
                        publishFaultFlag = true
                    }
                })
            }
        })

        return publishFaultFlag
    }

    function convertReplies (button) {
        const { replySetting } = button
        const replies = replySetting ? replySetting.replies : []
        const convertReplies = replies.map(reply => {
            return isYQYLEnabled ? {
                ...reply,
                yqylPromotion: reply.type === 'yqylPromotion' && reply.yqylPromotion ? reply.yqylPromotion.id : null
            } : {
                ...reply
            }
        })

        return {
            ...button,
            replySetting: {
                ...button.replySetting,
                replies: convertReplies
            }
        }
    }

    function publish () {
        (async () => {
            const finalButtons = menu.button.map(button => {
                if(button.sub_button && button.sub_button.length === 0) {
                    return convertReplies(button)
                } else {
                    const { sub_button } = button
                    const convertSubButtons = sub_button.map(subButton => {
                        return convertReplies(subButton)
                    })

                    return {
                        ...button,
                        sub_button: convertSubButtons
                    }
                }
            })
            const result = await saveWxMenu(queryRunner, finalButtons, isYQYLEnabled)
            
            if(result) {
                Message.success('保存成功')
                setModalVisible(false)
                setOriginalMenu({button: result.createWxMpMenu.button})
                setPublishFault(false)
            }
        })()
    }

    function startRanking () {
        setRankingMode(true)
        buttonRefs[currentButtonIdx] && buttonRefs[currentButtonIdx].current && buttonRefs[currentButtonIdx].current.focus()
    }

    function confirmRanking () {
        setRankingMode(false)
    }

    function moveButton (direction, disabled = false) {
        if(disabled) {
            return
        }

        let buttons = [...buttonArr]

        if(direction === 'left') {
            buttons.splice(currentButtonIdx, 1, ...buttons.splice(currentButtonIdx - 1, 1, buttons[currentButtonIdx]))
            setCurrentButtonIdx(currentButtonIdx - 1)
        } else {
            buttons.splice(currentButtonIdx, 1, ...buttons.splice(currentButtonIdx + 1, 1, buttons[currentButtonIdx]))
            setCurrentButtonIdx(currentButtonIdx + 1)
        }
        setMenu({...menu, button: buttons})
    }

    function moveSubButton (direction, disabled = false) {
        if(disabled) {
            return
        }

        let buttons = [...buttonArr]
        let { sub_button } = buttonArr[currentButtonIdx]

        if(direction === 'up') {
            sub_button.splice(currentSubButtonIdx, 1, ...sub_button.splice(currentSubButtonIdx - 1, 1, sub_button[currentSubButtonIdx]))
            setCurrentSubButtonIdx(currentSubButtonIdx - 1)
        } else {
            sub_button.splice(currentSubButtonIdx, 1, ...sub_button.splice(currentSubButtonIdx + 1, 1, sub_button[currentSubButtonIdx]))
            setCurrentSubButtonIdx(currentSubButtonIdx + 1)
        }
        const updatedMainButton = {...buttonArr[currentButtonIdx], sub_button: sub_button}
        buttons.splice(currentButtonIdx, 1, updatedMainButton)
        setMenu({...menu, button: buttons})
    }

    function renderRankingIcon () {
        let buttons = [...buttonArr]
        let { sub_button } = buttonArr[currentButtonIdx]

        function renderIcon (direction, onClick, disabled) {

            return (
                <Icon 
                    type={direction} 
                    onClick={() => disabled ? null : onClick(direction)} 
                    className={disabled ? 'icon-disabled' : ''}
                    style={{ color: disabled ? "#f5f5f5" : "#3e8ecd" }}
                /> 
            )
        }

        return (
            <>
                { renderIcon('left', moveButton, !(isMainButton && currentButtonIdx > 0))}
                { renderIcon('right', moveButton, !(isMainButton && currentButtonIdx < buttons.length - 1))}
                { renderIcon('up', moveSubButton, isMainButton || currentSubButtonIdx === 0)}
                { renderIcon('down', moveSubButton, isMainButton || currentSubButtonIdx === sub_button.length - 1)}
            </>
        )
    }

    function hasInvalidUrl () {
        if(currentButton && currentButton.type === "view" && currentButton.url) {
            return !!validateUrl(currentButton.url)
        } else {
            return false
        }
    }

    // 可能要加上 Breadcrumb，但是你不用管：
    return (
        <Page>
            <div className="wxmp-container">
                <div className="wxmp-breadcrumb-container">
                    <BreadcrumbSimple {...{
                        ...props,
                        pathname: props.location.pathname
                    }} />
                </div>
                <div className="wxmp-menuedit-container">
                    <div className="menu-edit">
                        <div>
                            <div className="mobile">
                                <div>菜单管理</div>
                                {
                                    buttonArr && buttonArr.length !== 0 ? renderButtons(buttonArr) : noButtons()
                                }
                            </div>
                            <div className="info">
                                {
                                    rankingMode ? (
                                        <>
                                            <span>菜单顺序调整中 </span>
                                            <span>点击 “完成” 返回原来的编辑页面</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>菜单编辑中 </span>
                                            <span>菜单未发布，请确认菜单编辑完成后点击“保存并发布”同步到手机。</span>
                                        </>
                                    )
                                }
                            </div>
                            {
                                rankingMode ? (
                                    <Button type="primary" onClick={() => confirmRanking()}>完成</Button>
                                ) : (
                                    <Button type="primary" onClick={() => startRanking()}>菜单排序</Button>
                                ) 
                            }
                        </div>
                        {
                            rankingMode && currentButton ? (
                                <div className="ranking">
                                    { renderRankingIcon() }
                                    点击方向按钮或者使用键盘方向键进行排序
                                </div>
                            ) : (
                                buttonArr && buttonArr.length === 0 ? null : (
                                    currentButton ? (
                                        <div className="edit-page">
                                            <div className="top">
                                                <span>{currentButton ? currentButton.name : '菜单名称'}</span>
                                                <span onClick={() => deleteButton()}>
                                                    {isMainButton ? '删除菜单' : '删除子菜单'}
                                                </span>
                                            </div>
                                            {
                                                hasSubButtons ? (
                                                    <div className="edit-info">已添加子菜单，仅可设置菜单名称。</div>
                                                ) : null
                                            }
                                            <div className="name">
                                                <label>菜单名称</label>
                                                <div>
                                                    <Input
                                                    style={{ width: 300 }}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    onBlur={() => nameInputBlur()}
                                                    />
                                                    { renderError() }
                                                </div>
                                            </div>
                                            { hasSubButtons ? null : renderContent() }
                                        </div>
                                    ) : (
                                        <div className="no-currentButton">
                                            点击左侧菜单进行编辑操作
                                        </div>
                                    )
                                )
                            )
                            
                        }
                    </div>
                    {
                        buttonArr.length > 0 ? (
                            <div className="publish">
                                <Button type="primary" onClick={() => showPublishModal()} 
                                    disabled={!isDirty() || rankingMode || hasInvalidUrl()}
                                >保存并发布</Button>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <Modal
                title="温馨提示"
                open={modalVisible}
                onOk={() => modalType === 'delete' ? 
                    deleteButtonConfirm() : modalType === 'publish' ? 
                    publish() : addSubButton(buttonArr[currentButtonIdx])
                }
                onCancel={() => {
                    setModalVisible(false)
                    setModalType(undefined)
                }}
                width={720}
            >
                <div className="modal-content">
                    <Icon type="exclamation-circle" className="alarm" />
                    {
                        modalType === 'delete' ? (
                            <div>
                                <p>删除确认</p>
                                <p>{`删除后"${name}"菜单下设置的内容将被删除`}</p>
                            </div>
                        ) : (
                            modalType === 'publish' ? (
                                <div>
                                    <p>发布确认</p>
                                    <p style={{ color: '#aaa' }}>发布成功后会覆盖原版本，且将在24小时内对所有用户生效，确认发布？</p>
                                </div>
                            ) : (
                                <div>
                                    <p>子菜单确认</p>
                                    <p style={{ color: '#aaa' }}>添加子菜单后，一级菜单的内容将被清除。确定添加子菜单？</p>
                                </div>
                            )
                        )
                    }
                </div>
            </Modal>
        </Page>
    )
}



/// Query Menu
async function loadWxMenu(queryRunner, isYQYLEnabled) {
    // 第一个参数是 query text，第二个参数是 input
    const resp = await queryRunner(QueryWxMpMenu(isYQYLEnabled))()
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.wxMpMenu
    }
}

async function saveWxMenu(queryRunner, button, isYQYLEnabled) {
    const resp = await queryRunner(MutCreateWxMpMenu(isYQYLEnabled))({
        input: {
            button,
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data
    }
}


const FragmentReplySetting = isYQYLEnabled => `
    replySetting {
        replyType
        transferCustomerService
        replies {
            type
            text
            mpNews
            mpImage
            ${
                isYQYLEnabled ?
                `yqylPromotion {
                    id
                }` : ""
            }
        }
    }
`

const FragmentButton = isYQYLEnabled => `
    button {
        name
        type
        url
        media_id
        ${FragmentReplySetting(isYQYLEnabled)}
        sub_button {
            name
            type
            url
            media_id
            ${FragmentReplySetting(isYQYLEnabled)}
        }
    }
`

//// GraphQL Text:
const QueryWxMpMenu = (isYQYLEnabled) => `
query {
    wxMpMenu {
        ${FragmentButton(isYQYLEnabled)}
    }
}
`

const MutCreateWxMpMenu = (isYQYLEnabled) => `
    mutation CreateWxMpMenuMutation(
        $input: CreateWxMpMenuInput!
    ) {
        createWxMpMenu(input: $input) {
            ${FragmentButton(isYQYLEnabled)}
        }
    }

`
