// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../../lib/bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function buildConditionList(field, ops) {
  return Plate$BwaxAdmin.List.map((function (param) {
                return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "field",
                                field
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "op",
                                    param[0]
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "value",
                                        param[1]
                                      ],
                                      /* [] */0
                                    ])
                                ])
                            ]));
              }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(ops)))));
}

function wrapJsonArr(v) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                v,
                /* [] */0
              ]));
}

function toFilterCondition(o) {
  return wrapJsonArr(Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.foldl((function (acc, param) {
                        return Pervasives.$at(acc, buildConditionList(param[0], param[1]));
                      }), /* [] */0, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(o)))))));
}

function extractCondition(json) {
  var match = Json$BwaxAdmin.get_value("field", Js_json.decodeString, json);
  var match$1 = Json$BwaxAdmin.get_value("op", Js_json.decodeString, json);
  var match$2 = Json$BwaxAdmin.get_field("value", json);
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
    return /* tuple */[
            match,
            match$1,
            Caml_option.valFromOption(match$2)
          ];
  }
  
}

function fromFilterCondition(mj) {
  var buildFilter = function (l) {
    return Js_dict.fromList(Plate$BwaxAdmin.List.foldl((function (acc, json) {
                      var match = extractCondition(json);
                      if (match !== undefined) {
                        var match$1 = match;
                        var value = match$1[2];
                        var op = match$1[1];
                        var field = match$1[0];
                        var match$2 = Plate$BwaxAdmin.List.assoc(field, acc);
                        if (match$2 !== undefined) {
                          var updated = Json$BwaxAdmin.insert_object(op, value, Caml_option.valFromOption(match$2));
                          return Plate$BwaxAdmin.List.map((function (param) {
                                        var k = param[0];
                                        if (k === field) {
                                          return /* tuple */[
                                                  k,
                                                  updated
                                                ];
                                        } else {
                                          return /* tuple */[
                                                  k,
                                                  param[1]
                                                ];
                                        }
                                      }), acc);
                        } else {
                          var newO = Json$BwaxAdmin.create_object(op, value);
                          return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        /* tuple */[
                                          field,
                                          newO
                                        ],
                                        /* [] */0
                                      ]));
                        }
                      } else {
                        return acc;
                      }
                    }), /* [] */0, l));
  };
  return Plate$BwaxAdmin.$$Option.map(buildFilter, Plate$BwaxAdmin.$$Option.and_then(Json$BwaxAdmin.decode_list, Plate$BwaxAdmin.$$Option.and_then(Plate$BwaxAdmin.List.head, Plate$BwaxAdmin.$$Option.and_then(Json$BwaxAdmin.decode_list, mj))));
}

function op_text(op) {
  switch (op) {
    case "eq" :
        return "=";
    case "gt" :
        return ">";
    case "gte" :
        return ">=";
    case "le" :
        return "<";
    case "like" :
        return "~=";
    case "lte" :
        return "<=";
    case "ne" :
        return "!=";
    default:
      return op;
  }
}

function to_string(fieldAliases, json) {
  var partial_arg = Plate$BwaxAdmin.List.from_array;
  var aliases = Plate$BwaxAdmin.List.keep_map((function (param) {
          var k = param[0];
          return Plate$BwaxAdmin.$$Option.map((function (v) {
                        return /* tuple */[
                                k,
                                v
                              ];
                      }), Js_json.decodeString(param[1]));
        }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (param) {
                  return Plate$BwaxAdmin.$at$great(Js_dict.entries, partial_arg, param);
                }), Js_json.decodeObject(fieldAliases))));
  var field_text = function (field) {
    var match = Plate$BwaxAdmin.List.find((function (param) {
            return param[1] === field;
          }), aliases);
    if (match !== undefined) {
      return match[0];
    } else {
      return field;
    }
  };
  var build_ands = function (l) {
    return Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (param) {
                      return field_text(param[0]) + (" " + (op_text(param[1]) + (" " + (JSON.stringify(param[2]) + ";"))));
                    }), Plate$BwaxAdmin.List.keep_map(extractCondition, l)));
  };
  var partial_arg$1 = Plate$BwaxAdmin.$$Option.with_default;
  var partial_arg$2 = function (param) {
    return partial_arg$1(/* [] */0, param);
  };
  var partial_arg$3 = function (param) {
    return Plate$BwaxAdmin.$at$great(partial_arg$2, build_ands, param);
  };
  var ors = Plate$BwaxAdmin.List.map((function (param) {
          return Plate$BwaxAdmin.$at$great(Json$BwaxAdmin.decode_list, partial_arg$3, param);
        }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Json$BwaxAdmin.decode_list(json)));
  if (Plate$BwaxAdmin.List.length(ors) > 1) {
    return Plate$BwaxAdmin.Str.join("\n  \xe6\x88\x96 \n ", ors);
  } else {
    return Plate$BwaxAdmin.Str.join("", ors);
  }
}

exports.buildConditionList = buildConditionList;
exports.wrapJsonArr = wrapJsonArr;
exports.toFilterCondition = toFilterCondition;
exports.extractCondition = extractCondition;
exports.fromFilterCondition = fromFilterCondition;
exports.op_text = op_text;
exports.to_string = to_string;
/* Json-BwaxAdmin Not a pure module */
