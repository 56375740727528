

import React, { useEffect, useState } from 'react'

import { CgMathPlus } from 'react-icons/cg'
import { AiFillEye, AiFillEyeInvisible, AiOutlineDelete } from 'react-icons/ai'
import { MdImage, MdGradient, MdLensBlur } from 'react-icons/md'
import { FaSquare } from 'react-icons/fa'

import { Input, Select } from '@arco-design/web-react';
const Option = Select.Option;

import { SketchPicker } from 'react-color';

import Tooltip from 'bwax-ui/components/Tooltip';
import Popover from 'bwax-ui/components/legacy/Popover'
import { RGBtoHex } from 'bwax-ui/color/hsv'
import ImageEdit from './ImageEdit'
import OverlayColorEdit from './OverlayColorEdit';
import LinearGradientEdit from './LinearGradientEdit'
import RadialGradientEdit from './RadialGradientEdit'
import ResetableLabel from '../components/ResetableLabel'
import { transparentImgUrl, colorRgbRegx, colorHexRegx } from '../StyleForm';

import './Backgrounds.less'

const clipOptons =  [{ 
    value: 'border-box',
    label: 'None'
}, {
    value: 'padding-box',
    label: 'Clip background to padding'
}, {
    value: "content-box",
    label: "Clip background to content"
}, {
    value: "text",
    label: "Clip background to text"
}]

const placeholderImageUrl = 'https://bw-dev.static.qunfengshe.com/public/cb-bwax/design-resources/placeholder-image.webp'
const backgroundInfoKeys = ['backgroundSize', 'backgroundPosition', 'backgroundRepeat', 'backgroundAttachment']

export default function Backgrounds({ attributes, onChange }) {

    const [bgColor, setBgColor] = useState('transparent')
    const [colorPickerVisible, setColorPickerVisible] = useState(false)
    const [bgClip, setBgClip] = useState('None')
    const [images, setImages] = useState([])
    const [activeImageItem, setActiveImageItem] = useState(null)
    const [activeImageIndex, setActiveImageIndex] = useState(null)
    const [resourcesModalOpen, setResourcesModalOpen] = useState(false)
    const [colorPickerInPopoverVisible, setColorPickerInPopoverVisible] = useState(false)
    const [bgInfosArr, setBgInfosArr] = useState({
        backgroundSize: [],
        backgroundPosition: [],
        backgroundRepeat: [],
        backgroundAttachment: []
    })

    const linearGradientRegx = /linear-gradient\((?:\d+(?:\.\d+)?(?:deg|rad|grad|turn), )?(.*)\)/
    const radialGradientRegx = /radial-gradient\(circle.*at \d+(?:\.\d+)?% \d+(?:\.\d+)?%, (.*)\)/

    useEffect(() => {

        if(attributes['backgroundColor']) {
            setBgColor(attributes['backgroundColor'])
        } else {
            setBgColor('transparent')
        }

        if(attributes['backgroundClip']) {
            setBgClip(clipOptons.find(c => c.value === attributes['backgroundClip']).label)
        } else {
            setBgClip('None')
        }

    }, [attributes])

    useEffect(() => {
        const bgInfoKeysArr = Object.keys(attributes).filter(attr => backgroundInfoKeys.find(bi => bi === attr) && !!attributes[attr])
        if(bgInfoKeysArr.length > 0){
            let newBgInfosArr = {...bgInfosArr}
            bgInfoKeysArr.map(bgKey => {
                const bgInfoValueArr = attributes[bgKey].split(', ')
                newBgInfosArr = {
                    ...newBgInfosArr,
                    [bgKey]: bgInfoValueArr.map(v => {
                        return {
                            value: v,
                            visible: true
                        }
                    })
                }
            })
            setBgInfosArr(newBgInfosArr)
        }

        if(attributes['backgroundImage']) {
            const visibleImages = attributes['backgroundImage'].map(bgImage => {
                if(bgImage.file) {
                    let bgImageObj = { ...bgImage, type: 'image', visible: true }
                    return bgImageObj
                } else if (bgImage.match(/linear-gradient/)) {

                    const gradientRGBColorRegx = /rgba?\(\d{1,3}, ?\d{1,3}, ?\d{1,3}(?:, ?\d+(?:\.\d+)?)?\)/g
                    const gradientMatched = [...bgImage.matchAll(gradientRGBColorRegx)]

                    if(gradientMatched.length > 0) {
                        if(gradientMatched.length === 2 && gradientMatched[0][0] === gradientMatched[1][0]){
                            return {
                                type: 'colorOverlay',
                                title: gradientMatched[0][0],
                                gradient: bgImage,
                                visible: true
                            }
                        } else {
                            return {
                                type: 'linearGradient',
                                title: 'Linear gradient',
                                gradient: bgImage,
                                visible: true
                            }
                        }
                    } else {
                        const matchedResult = bgImage.match(linearGradientRegx)
                        const keyWordsArr = matchedResult[1].split(/, ?/)
                        if(keyWordsArr.length === 2 && (keyWordsArr[0].split(" "))[0] === (keyWordsArr[1].split(" "))[0]){
                            return {
                                type: 'colorOverlay',
                                title: (keyWordsArr[0].split(" "))[0],
                                gradient: bgImage,
                                visible: true
                            }
                        } else {
                            return {
                                type: 'linearGradient',
                                title: 'Linear gradient',
                                gradient: bgImage,
                                visible: true
                            }
                        }
                    }
                } else if (bgImage.match(/radial-gradient/)) {
                    return {
                        type: 'radialGradient',
                        title: 'Radial gradient',
                        gradient: bgImage,
                        visible: true
                    }
                }
            })

            setImages(visibleImages)
        } else {
            setImages([])
        }
    }, [attributes])

    function updateColor (color) {
        onChange({ backgroundColor: color })
    }

    function renderBgColor () {

        function renderColorPicker () {
            function onChangeComplete (color) {
                const { hex } = color
                updateColor(hex)
            }
        
            return (
                <SketchPicker
                    color={bgColor}
                    onChangeComplete={onChangeComplete} 
                />
            )
        }

        return (
            <Popover content={renderColorPicker()} visible={colorPickerVisible} positions={["bottom", "top"]} reposition={true} 
                onClickOutside={_ => {
                    setColorPickerVisible(false)
                }}
            >
                <div className='bg-color' style={{ 
                    backgroundImage: [`linear-gradient(180deg, ${bgColor} 0%, ${bgColor} 100%)`, `url(${transparentImgUrl})`] 
                }} onClick={() => setColorPickerVisible(true)}/>
            </Popover>
           
        )
    }

    function inputBgColor (color) {
        setBgColor(color)
    }

    function inputBgColorBlur () {
        if(bgColor === "transparent") {
            updateColor(`transparent`)
        } else if(bgColor.startsWith("rgb")) {
            if(bgColor.match(colorRgbRegx)) {
                const matchRgbResult = bgColor.match(colorRgbRegx)
                const convertedColor = RGBtoHex({r: parseInt(matchRgbResult[1]), g: parseInt(matchRgbResult[2]), b: parseInt(matchRgbResult[3])})
                updateColor(`#${convertedColor}`)
            }
        } else if(bgColor.startsWith('#')) {
            if(bgColor.match(colorHexRegx)) {
                updateColor(bgColor)
            }
        }
    }

    function selectClip (clip) {
        setBgClip(clip.label)
        onChange({ backgroundClip: clip.value })
    }

    function addImageOrGradient () {
        // 新增 image 时，不更新 background-image 属性，只更新 backgroundSize、backgroundPosition... 这些属性
        const newImage = {
            type: 'image',
            visible: true,
            isPlaceholder: true,
            file: {
                url: placeholderImageUrl,
                title: 'placeholder_image.webp',
                size: 3522,
            }
        }

        setImages([newImage, ...images])
        const { backgroundSize, backgroundPosition, backgroundRepeat, backgroundAttachment } = bgInfosArr
        const newBgInfosArr = {
            backgroundSize: [{value: 'auto', isPlaceholder: true, visible: true }, ...backgroundSize],
            backgroundPosition: [{ value: '0px 0px', isPlaceholder: true, visible: true }, ...backgroundPosition],
            backgroundRepeat: [{ value: 'repeat', isPlaceholder: true, visible: true }, ...backgroundRepeat],
            backgroundAttachment: [{ value: 'scroll', isPlaceholder: true, visible: true }, ...backgroundAttachment]
        }
        setBgInfosArr(newBgInfosArr)
        
    }

    function updateImages(newImages) {
        const changedImages = newImages.filter(img => img.visible).map(image => {
            return image.file ? { file: image.file } : image.gradient
        })
        
        onChange({ backgroundImage: changedImages.length > 0 ? changedImages : undefined })
        
    }

    function renderImageEdit () {

        if(!activeImageItem) {
            return null
        }

        const typeOptions = [{
            type: "image",
            tooltip: "Image",
            icon: <MdImage/>
        }, {
            type: "linearGradient",
            tooltip: "Linear gradient",
            icon: <MdGradient/>
        }, {
            type: "radialGradient",
            tooltip: "Radial gradient",
            icon: <MdLensBlur/>
        }, {
            type: "colorOverlay",
            tooltip: "Color overlay",
            icon: <FaSquare style={{ fontSize: 12 }}/>
        }]

        function updateActiveImageItem (newImage) {
            setActiveImageItem(newImage)
            const newImages = images.map((image, idx) => {
                return idx === activeImageIndex ? {
                    ...image,
                    ...newImage
                } : image
            })
            setImages(newImages)
        }

        function updateBgInfos (bgInfos) {

            let newBgInfosArr = {...bgInfosArr}
            Object.keys(bgInfosArr).map(bgInfoKey => {
                newBgInfosArr = {
                    ...newBgInfosArr,
                    [bgInfoKey]: bgInfosArr[bgInfoKey].map((i, index) => index === activeImageIndex ? { ...i, ...bgInfos[bgInfoKey] } : i)
                }
            })

            setBgInfosArr(newBgInfosArr)

            if(Object.values(bgInfos).every(bgInfo => !bgInfo.isPlaceholder)) {
                function getNewBgInfoValueStr (styleKey) {
                    return newBgInfosArr[styleKey].filter(bgInfo => !bgInfo.isPlaceholder).map((bgInfo, index) => {
                        if(index === activeImageIndex) {
                            return bgInfos[styleKey].value
                        } else {
                            return bgInfo.value
                        }
                    }).join(', ')
                }
    
                let newBgInfos = {}
                Object.keys(bgInfos).map(bgInfoKey => {
                    newBgInfos = {
                        ...newBgInfos,
                        [bgInfoKey]: getNewBgInfoValueStr(bgInfoKey) ? getNewBgInfoValueStr(bgInfoKey) : undefined
                    }
                })
    
                onChange(newBgInfos)
            }
        }

        function changeImageType (changedImage) {
            // 更改类型的同时，将 bgInfoArr 中每个属性数组的对应项的 isPlaceholder 改为 false(如果有的话)
            const changedImages = images.map((image, index) => {
                if(index === activeImageIndex) {
                    return {
                        ...changedImage,
                        visible: image.visible
                    }
                } else {
                    return image
                }
            })
            setImages(changedImages)
            updateImages(changedImages)

            let newBgInfos = {}
            Object.keys(bgInfosArr).map(bgInfoKey => {
                newBgInfos = {
                    ...newBgInfos,
                    [bgInfoKey]: { 
                        ...bgInfosArr[bgInfoKey][activeImageIndex], 
                        value: bgInfosArr[bgInfoKey][activeImageIndex].value, 
                        isPlaceholder: false 
                    }
                }
            })
            updateBgInfos(newBgInfos)
        }

        return (
            <div className='image-edit-area'>
                <div className='style-with-options'>
                    <div>Type</div>
                    <div className='options'>
                        {
                            typeOptions.map((op, index) => {
                                const { type, icon, tooltip } = op
                                const isActive = activeImageItem.type === type
                                return (
                                    <Tooltip key={index} text={tooltip}>
                                        <div className={`option-box ${isActive ? 'active' : ''}`}
                                            onClick={() => {
                                                if(!isActive) {
                                                    let changedImage = {}
                                                    if(type === 'image') {
                                                        changedImage = {
                                                            width: 1200,
                                                            height: 800,
                                                            gradient: null,
                                                            type,
                                                            file: {
                                                                url: placeholderImageUrl,
                                                                title: "placeholder_image.webp",
                                                                size: 3522,
                                                            }
                                                        }
                                                    } else if (type === "linearGradient") {
                                                        let linearGradient = ""
                                                        if(activeImageItem.gradient && activeImageItem.gradient.match(/radial-gradient/)) {
                                                            const gradientMatchResult = activeImageItem.gradient.match(radialGradientRegx)
                                                            linearGradient = `linear-gradient(180deg, ${gradientMatchResult[1]})`
                                                        } else {
                                                            linearGradient = `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%)`
                                                        }
                                                        changedImage = {
                                                            type,
                                                            title: 'Linear gradient',
                                                            gradient: linearGradient
                                                        }
                                                    } else if (type === "colorOverlay") {
                                                        const defaultOverlayColor = 'rgba(0,0,0,0.5)'
                                                        const linearGradient = `linear-gradient(${defaultOverlayColor} 0%, ${defaultOverlayColor} 100%)`
                                                        changedImage = {
                                                            type,
                                                            title: defaultOverlayColor,
                                                            gradient: linearGradient
                                                        }
                                                    } else if (type === "radialGradient") {
                                                        let radialGradient = `radial-gradient(circle farthest-corner at 50% 50%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%)`
                                                        if(activeImageItem.gradient) {
                                                            const gradientMatchResult = activeImageItem.gradient.match(linearGradientRegx)
                                                            const isTransparent = gradientMatchResult[1].split(', ').every(g => g.startsWith("transparent"))
                                                            if(!isTransparent) {
                                                                radialGradient = `radial-gradient(circle farthest-corner at 50% 50%, ${gradientMatchResult[1]})`
                                                            }
                                                        }
                                                        changedImage = {
                                                            type,
                                                            title: 'Radial gradient',
                                                            gradient: radialGradient
                                                        }
                                                    }

                                                    setActiveImageItem(changedImage)
                                                    changeImageType(changedImage)
                                                }
                                            }}
                                        >
                                            {icon}
                                        </div>
                                    </Tooltip>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    activeImageItem.type === "image" ? (
                        <ImageEdit 
                            bgInfosArr={bgInfosArr} images={images} activeImageItem={activeImageItem} onChange={onChange} 
                            resourcesModalOpen={resourcesModalOpen} setResourcesModalOpen={setResourcesModalOpen}
                            updateActiveImageItem={updateActiveImageItem} activeImageIndex={activeImageIndex} updateBgInfos={updateBgInfos}
                        />
                    ) : activeImageItem.type === "linearGradient" ? (
                        <LinearGradientEdit attributes={attributes} activeImageItem={activeImageItem} onChange={onChange} 
                            updateActiveImageItem={updateActiveImageItem} activeImageIndex={activeImageIndex}
                            setColorPickerInPopoverVisible={setColorPickerInPopoverVisible}
                        />
                    ) : activeImageItem.type === "radialGradient" ? (
                        <RadialGradientEdit attributes={attributes} activeImageItem={activeImageItem} onChange={onChange} 
                            updateActiveImageItem={updateActiveImageItem} activeImageIndex={activeImageIndex}
                            setColorPickerInPopoverVisible={setColorPickerInPopoverVisible}
                        />
                    ) : (
                        <OverlayColorEdit attributes={attributes} activeImageItem={activeImageItem} onChange={onChange}
                            setColorPickerInPopoverVisible={setColorPickerInPopoverVisible}
                            updateActiveImageItem={updateActiveImageItem}
                            activeImageIndex={activeImageIndex}
                        />
                    )
                    
                }
            </div>
        )
    }

    function toggleImageVisible (e, hideIndex) {
        e.stopPropagation()

        function getChangedArr (arr) {
            return arr.map((attr, idx) => {
                return idx === hideIndex ? {
                    ...attr,
                    visible: !attr.visible
                } : attr
            })
        }

        setImages(getChangedArr(images))
        updateImages(getChangedArr(images))

        let newBgInfosArr = {...bgInfosArr}
        Object.keys(bgInfosArr).map(bgInfoKey => {
            newBgInfosArr = {
                ...newBgInfosArr,
                [bgInfoKey]: getChangedArr(bgInfosArr[bgInfoKey])
            }
        })

        setBgInfosArr(newBgInfosArr)

        function getAttributeString (attributeArr) {

            const visibleArr = attributeArr.filter(attr => attr.visible && !attr.isPlaceholder)
            return visibleArr.reduce((acc, current, currentIndex) => {
                return acc + (currentIndex === visibleArr.length - 1 ? current.value : `${current.value}, `)
            }, '') 
        }

        const bgSizeStr = getAttributeString(getChangedArr(bgInfosArr.backgroundSize))
        const bgPositionStr = getAttributeString(getChangedArr(bgInfosArr.backgroundPosition))
        const bgAttachmentStr = getAttributeString(getChangedArr(bgInfosArr.backgroundAttachment))
        const bgRepeatStr = getAttributeString(getChangedArr(bgInfosArr.backgroundRepeat))

        onChange({ 
            backgroundSize: bgSizeStr ? bgSizeStr : undefined,
            backgroundPosition: bgPositionStr ? bgPositionStr: undefined,
            backgroundAttachment: bgAttachmentStr ? bgAttachmentStr : undefined,
            backgroundRepeat: bgRepeatStr ? bgRepeatStr : undefined
        })
    }

    function removeImage (e, removeIndex) {
        e.stopPropagation()

        function getChangedArr (arr) {
            return arr.filter((_, idx) => idx !== removeIndex)
        }
        setImages(getChangedArr(images))
        updateImages(getChangedArr(images))

        function getAttributeString (attributeArr) {

            return attributeArr.reduce((acc, current, currentIndex) => {
                return acc + (currentIndex === attributeArr.length - 1 ? current.value : `${current.value}, `)
            }, '') 
        }

        let newBgInfosArr = {...bgInfosArr}
        Object.keys(bgInfosArr).map(bgInfoKey => {
            newBgInfosArr = {
                ...newBgInfosArr,
                [bgInfoKey]: getChangedArr(bgInfosArr[bgInfoKey])
            }
        })

        setBgInfosArr(newBgInfosArr)

        const bgSizeStr = getAttributeString(getChangedArr(bgInfosArr.backgroundSize))
        const bgPositionStr = getAttributeString(getChangedArr(bgInfosArr.backgroundPosition))
        const bgAttachmentStr = getAttributeString(getChangedArr(bgInfosArr.backgroundAttachment))
        const bgRepeatStr = getAttributeString(getChangedArr(bgInfosArr.backgroundRepeat))

        onChange({ 
            backgroundSize: bgSizeStr ? bgSizeStr : undefined,
            backgroundPosition: bgPositionStr ? bgPositionStr: undefined,
            backgroundAttachment: bgAttachmentStr ? bgAttachmentStr : undefined,
            backgroundRepeat: bgRepeatStr ? bgRepeatStr : undefined
        })
    }

    return (
        <div className='backgrounds-container'>
            <div className='images-container'>
                <div className='images-header'>
                    <ResetableLabel allAttributes={attributes} label={"Image & gradient"} attributesGroup={"background"} 
                        attribute={['backgroundImage', 'backgroundPosition', 'backgroundSize','backgroundRepeat', 'backgroundAttachment' ]} 
                        onChange={onChange}
                    />
                    <Tooltip text={"Add a background image, gradient, or overlay."}>
                        <div className={`icon-add ${activeImageIndex || activeImageIndex === 0 ? 'hide' : ''}`} onClick={() => addImageOrGradient()}>
                            <CgMathPlus/>
                        </div>
                    </Tooltip>
                </div>
                {
                    images.length > 0 ? (
                        <div className='images-list'>
                            {
                                images.map((image, index) => {
                                    return (
                                        <Popover key={index} content={renderImageEdit()} positions={["bottom", "top"]} reposition={true} 
                                            visible={index === activeImageIndex} onClickOutside={_ => {
                                                if(!resourcesModalOpen && !colorPickerInPopoverVisible) {
                                                    setActiveImageIndex(null)
                                                }
                                        }}>
                                            <div className='image-item' style={{ opacity: image.visible ? 1 : 0.5 }} onClick={() => {
                                                setActiveImageItem(image)
                                                setActiveImageIndex(index)
                                            }}>
                                                <div className='gradient-display' 
                                                    style={{ 
                                                        backgroundImage: [image.file ? `url(${image.file.url})` : image.gradient, `url(${transparentImgUrl})`],
                                                        backgroundSize: 'cover'
                                                    }}
                                                />
                                                { image.file ? image.file.title : image.title } 
                                                <div className={`actions ${activeImageIndex || activeImageIndex === 0 ? 'hide' : ''}`}>
                                                    <Tooltip text={"Hide"}>
                                                        <div className='visible-toggle' onClick={e => toggleImageVisible(e, index)}>
                                                            { image.visible ? <AiFillEye /> : <AiFillEyeInvisible/> }
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip text={"Remove"}>
                                                        <div className='remove' onClick={e => removeImage(e, index)}>
                                                            <AiOutlineDelete/>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Popover>
                                    )
                                })
                            }
                        </div>
                    ) : null
                }
                
            </div>
            <div className='color-container'>
                <ResetableLabel allAttributes={attributes} label={"Color"} attributesGroup={"background"} 
                    attribute={"backgroundColor"} onChange={onChange}
                />
                <Input value={bgColor} size="mini" prefix={renderBgColor()} onChange={value => inputBgColor(value)} 
                    onBlur={() => inputBgColorBlur()}
                />
            </div>
            <div className='clip-container'>
                <ResetableLabel allAttributes={attributes} label={"Clipping"} attributesGroup={"background"} 
                    attribute={"backgroundClip"} onChange={onChange}
                />
                <Select value={bgClip} size={'mini'}
                    onChange={clip => selectClip(clip)} style={{ width: "100%" }}
                >
                    {clipOptons.map(clip => (
                        <Option key={clip.value} value={clip} style={{ fontSize: 12 }}>
                            {clip.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}
