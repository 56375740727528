import React, { useEffect, useState } from 'react'
import { Input, Tag, Button } from 'antd'

import Tooltip from 'bwax-ui/components/Tooltip';

import { PlusOutlined } from '@ant-design/icons';

import { replace } from 'bwax/list'

import TextAutoComplete from 'Client/js/components/TextAutoComplete'

import "./EditTags.less"

export default function EditTags (props) {
    const { value, tips, onChange, autoComplete, entityName, field } = props;    

    // the states
    const [ editingNewTag, setEditingNewTag ] = useState(false);
    const [ hoverTag, setHoverTag ] = useState(null);
    const [ editingTagIdx, setEditingTagIdx ] = useState(null);
    const [ tags, setTags ] = useState(_ => {
        if(value && !Array.isArray(value)) {
            return [value]
        } else {
            return value || []
        }        
    });



    useEffect(() => {
        // if not equal change it
        if(Array.isArray(value) && Array.isArray(tags)) {
            const isEqual = value.every((v, index) => v == tags[index])
            if(!isEqual) {
                setTags(value)
            }
        } else if(value !== tags) {
            setTags(value || [])
        } 

    }, [value]);

    const delTag = tag => {
        if (onChange) {
            const newTags = (tags || []).filter(t => t !== tag)
            onChange(newTags)
            setTags(newTags);
        }
    }

    const onHoverTag = hoverTag => {
        setHoverTag(hoverTag)
    }

    const onLeaveTag = () => {
        setHoverTag(null)
    }

    const addNewTag = () => {
        setEditingNewTag(true);
        setEditingTagIdx(tags.length);
        setTags([
            ...tags,
            ''
        ])
    }

    const updateTags = (value, idx) => {
        setTags(replace(tags, value, idx))
    }

    const confirmEditTag = (e, startNewEditing) => {
        e && e.preventDefault();
        const newTags = tags.filter(tag => tag.trim() !== '')

        if(startNewEditing) {
            setTags([...newTags, '']);
            setEditingTagIdx(newTags.length)
            if(!editingNewTag) {
                setEditingNewTag(true)
            }
        } else {
            setTags(newTags);
            setEditingNewTag(false);
            setEditingTagIdx(null)
        }

        onChange(newTags)
    }

    const stopEditing = () => {
        setEditingTagIdx(null);
        setTags(value || []);
        setEditingNewTag(false);
    }

    function ensureArray (v) {
        if(v && !Array.isArray(v)) {
            return [v]
        } else {
            return v || []
        }
    }

    return (
        <div>
            <div className="edit-tags">
                {
                    ensureArray(tags).map((tag, idx) => {

                        const isLongTag = tag.length > 8

                        const tagEle = (
                            <Tag
                                style={{ cursor: 'pointer' }}
                                key={tag}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditingTagIdx(idx);
                                }}
                                onMouseEnter={() => onHoverTag(tag)}
                                onMouseLeave={onLeaveTag}
                                closable={hoverTag === tag}
                                onClose={() => delTag(tag)}
                                className="tag"
                            >
                                {isLongTag ? `${tag.slice(0, 8)}...` : tag}
                            </Tag>
                        )

                        if (editingTagIdx === idx) {
                            if (autoComplete && entityName && field) {
                                return (
                                    <TextAutoComplete
                                        key={idx}
                                        value={tag}
                                        onChange={(value) => updateTags(value, idx)}
                                        params={{
                                            autoFocus: true,
                                            onBlur: e => {
                                                // on blur 就可以了
                                                confirmEditTag(e, false);
                                                // stopEditing
                                            },
                                            size: "small",
                                            onKeyUp: e => {
                                                if (e.key === "Enter" || e.key === "Tab") {
                                                    confirmEditTag(e, true)
                                                }
                                            },
                                            entityName,
                                            field
                                        }}
                                    />
                                )
                            } else {
                                return (
                                    <Input
                                        autoFocus
                                        key={idx}
                                        type="text"
                                        size="small"
                                        style={{ width: 130, marginRight: 10 }}
                                        value={tag}
                                        onPressEnter={e => confirmEditTag(e, true)}
                                        onBlur={ e => {
                                            // on blur 就可以了
                                            confirmEditTag(e, false);
                                            // stopEditing
                                        }}
                                        onChange={(e) => updateTags(e.target.value, idx)} />
                                )
                            }
                        }

                        return isLongTag ?
                            <Tooltip
                                text={tag}
                                key={tag}>
                                {tagEle}
                            </Tooltip> : tagEle
                    })
                }

                {
                    !editingNewTag ?
                        <Button
                            size="small"
                            type="dashed"
                            icon={<PlusOutlined />}
                            onClick={addNewTag}
                        /> : null
                }
            </div>
            {
                editingNewTag && tips ? (
                    <span
                        style={{
                            position: 'absolute',
                            bottom: -10,
                            fontSize: 8,
                            opacity: .65
                        }}
                    >
                        {tips}
                    </span>
                ) : null
            }
        </div>
    )
};
