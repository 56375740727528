

import BaseStrategy from "./BaseStrategy";

import { prepareData, execute } from '../editorUtils';

import { unpack } from 'bwax/lang/LangHelper'

import get from 'lodash/get'

export default class BackendItemStrategy extends BaseStrategy {

    constructor(itemType) {
        super();
        this.itemType = itemType;
    }


    // 默认基本上是用于 virtual field 和 data interface 的
    getTestRecordOptions(value, { codeFieldName, runtimeProfile }) {
        if(get(value, codeFieldName + ".previewData.ioTypeMetas.isTargetingRecord")) {
            return {
                entityName: runtimeProfile.entityName,
            }
        } else {
            return null
        }
    }

    isRunnable () {
        return true
    }

    async startRunning (code, { previewFacade, entityName, testRecord, dlc }) {
        // 
        const unpacked = unpack(code.compiled);

        const predata = await prepareData(unpacked, testRecord && testRecord.id, { facade: previewFacade, entityName })

        const result = await execute(unpacked, { 
            preparedData: predata, facade: previewFacade, previewData: code.previewData,
            itemType: this.itemType
        });

        return {
            type: "one-time-execution",
            needToStop: false,
            data: {
                predata,
                result
            }
        }

    }

}