
import React from 'react';

// action buttons:
import AddRecordButton from './components/AddRecordButton';
import UpdateRecordButton from './components/UpdateRecordButton';

import RecordOpActionButton from './components/RecordOpActionButton';

import ExtActionButton from './components/ExtActionButton';



const actionButtons = {
    "AddRecordAction": AddRecordButton,
    UpdateRecordButton,
    "RecordOpAction": RecordOpActionButton,
    "ExtAction": ExtActionButton,
};

export default actionButtons;

export function renderActionButton (b, { reload, context, dataEntity }) {
    const { action } = b;
    const { __t__, __v__ } = action;

    const Button = actionButtons[__t__];
    if (Button) {
        // TODO 处理 applciable 和 disabled:
        const {
            applicable, disabled,
            label, buttonType, buttonColor
        } = b;
        // button 的配置原则是可以参照 BwButton.

        return (
            <Button
                key={label}
                {...{
                    buttonConfig: { label, buttonType, buttonColor },
                    actionConfig: __v__,
                    dataEntity,
                    reload,
                    context
                }}
            />
        )
    }
    return null
}
