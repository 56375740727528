


import React from 'react'

const QueryTargetContext = React.createContext();

export default QueryTargetContext;

export const QueryTargetContextProvider = props => {

    const { 
        queryTarget,
        children 
    } = props

    return (
        <QueryTargetContext.Provider value={queryTarget}>
            { children }
        </QueryTargetContext.Provider>
    );
}
