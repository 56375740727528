
const period = "PERIOD"
const pu = " "

export default function printQuerySelection(tree, depth) {
    if (tree === period) {
        return ""
    }

    let pad = ""
    for (let i = 0; i < depth; i++) {
        pad += pu
    }

    /// for each root:
    const keys = Object.keys(tree || {}).sort() // sort the keys
    return keys.reduce((acc, name) => {
        const subTree = tree[name]
        if (subTree === "PERIOD") {
            return acc + "\n" + pad + name
        } else {
            return acc + "\n" +
                pad + name + " { " +
                printQuerySelection(subTree, depth + 1) + "\n" +
                pad + "} "
        }
    }, "")
}