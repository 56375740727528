

import React, { useEffect, useState, useRef } from 'react'

import ReactDOM from 'react-dom';

import useOutsideClickAware from 'Client/js/topbar/useOutsideClickAware';

import useResizeObserver from '@react-hook/resize-observer'

import './ContextPopup.less';

export default function ContextPopup({ contextPosition, children, onVisible, bindCloser = _ => {} }) {

    const [visible, setVisible] = useState(false);

    function updateVisible (visible) {
        setVisible(visible);
        if(onVisible) {
            onVisible(visible)
        }
    }

    const panelRef = useRef(null);

    bindCloser(_ => updateVisible(false));

    const [size, setSize]= useState();
    useOutsideClickAware(panelRef, () => {
        updateVisible(false);
    })


    useEffect(() => {
        // new context position, render the context menu
        if (contextPosition) {
            updateVisible(true);
        }
    }, [contextPosition]);

    useResizeObserver(panelRef, entry => {
        setSize({
            height: entry.contentRect.height,
            width: entry.contentRect.width
        });
    });

    const getPosition = () => {

        if(size && size.height && size.width && contextPosition && visible) {
            // by default it is to the right and bottom
            const toLeft = size.width + contextPosition.clientX > contextPosition.viewWidth;
            const toTop = size.height + contextPosition.clientY > contextPosition.viewHeight;

            return {
                top:  contextPosition.clientY - (toTop ? size.height : 0),
                left: contextPosition.clientX - (toLeft ? size.width : 0),
            }


        } else {
            return {top: -10000, left: -10000}
        }
    }

    function renderContextMenu() {
        return (
            <div className={"studio-context-popup" + (contextPosition && visible ? " visible" : "")}  ref={panelRef} 
                style={getPosition()}
            >
                { children }
            </div>
        )
        
    }

    // const [ready, setReady] = useState(false)
    // // portal 只在 client 端添加，为了避免 SSR hydration 时的不一致，使用 useEffect 来控制：
    // // 这样也可以让元素都加在最后面
    // useEffect(() => {
    //     if (typeof (document) !== "undefined") {
    //         setReady(true);
    //     }
    // }, [])

    if (typeof (document) !== "undefined") {
        let rootEle = document.getElementById("bw-admin-dialogs-container");
        return ReactDOM.createPortal(
            renderContextMenu(),
            rootEle
        );
    } else {
        return null
    }



}
