
export default ({routeTo}) => (params) => {

    return new Promise((resolve) => {

        const { url } = params
        resolve()

        if(url) {
            setTimeout(() => {
                routeTo(params.url)
            }, 0)
        } 
    })
}