
import React from 'react'


export function create_div(props, children) {
    return (
        <div {...props}>
            { children}
        </div>
    )
}

