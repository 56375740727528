
import React from 'react';

// a bridge to Button;
import admin_widget_helper from '../helpers/admin_widget_helper.bs'

import Popconfirm from 'bwax-ui/components/Popconfirm'

import ButtonComp from 'rsuite/Button';

import './Button.less'

export default React.forwardRef((props, ref) => {

    const {
        label: givenLabel,
        confirm,
        buttonType,
        color: buttonColor,
        disabled,
        loading,
        onClick,
        inMenu,
        compact: passedInCompact,

        children,
    } = props;

    // const label = givenLabel || children || "点击";

    const compact = passedInCompact ? passedInCompact : (typeof (givenLabel) == "string" && givenLabel.length > 2 ? true : false);

    const label = !compact && typeof (givenLabel) == "string" ? givenLabel.split("").join(" ") : givenLabel;

    function processButtonType() {

        if (typeof (buttonType) === "string") {
            return buttonType.toLowerCase();
        } else if (buttonType && buttonType.__t__) {
            return buttonType.__t__.toLowerCase()
        } else {
            return undefined
        }
    }

    function processColor() {
        // rsuite button 只支持 "red","orange","yellow","green","cyan","blue", "violet"
        if (buttonColor) {
            const colorStr = admin_widget_helper.color_to_string(buttonColor);
            const firstLetter = colorStr.substring(0, 1).toLowerCase();
            const color = ["red", "orange", "yellow", "green", "cyan", "blue", "violet"].find(c => c.startsWith(firstLetter));
            return color
        } else {
            return undefined
        }
    }

    // 
    function renderButton(onClick) {

        function prepareStyles() {
            let appearance = processButtonType();

            // 如果 in menu，那么不是 primary 就只能是 subtle,
            if(inMenu && appearance !== "primary") {
                appearance = "subtle";
            }

            if(appearance == "danger") {
                return {
                    appearance: "ghost",
                    color: "red"
                }
            } else {
                return {
                    appearance,
                    color: processColor(), // 只用于 primary
                }
            }

        }

        return (
            <ButtonComp {...{
                // classPrefix: "bw-admin--btn",
                ...prepareStyles(),
                
                ref,

                onClick,
                disabled,
                loading,
            }}>{label}</ButtonComp>
        )
    }

    function renderWithConfirm() {
        if (confirm) {
            return (
                <Popconfirm trigger="click" {...{
                    okText: confirm.okText || "确定",
                    cancelText: confirm.cancelText || "取消",
                    title: confirm.title || "您确定？",
                    onConfirm: onClick
                }}
                >
                    {renderButton()}
                </Popconfirm>
            )
        } else {
            return renderButton(onClick)
        }
    }

    if (inMenu) {
        return <div className="bw-admin--menu-btn">{renderWithConfirm()}</div>
    } else {
        return renderWithConfirm();
    }

})