// Generated from Bwax.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by BwaxParser.
function BwaxListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

BwaxListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
BwaxListener.prototype.constructor = BwaxListener;

// Enter a parse tree produced by BwaxParser#exprs.
BwaxListener.prototype.enterExprs = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#exprs.
BwaxListener.prototype.exitExprs = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#defs.
BwaxListener.prototype.enterDefs = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#defs.
BwaxListener.prototype.exitDefs = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#moduleDecl.
BwaxListener.prototype.enterModuleDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#moduleDecl.
BwaxListener.prototype.exitModuleDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#importExposing.
BwaxListener.prototype.enterImportExposing = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#importExposing.
BwaxListener.prototype.exitImportExposing = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#importExposingAll.
BwaxListener.prototype.enterImportExposingAll = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#importExposingAll.
BwaxListener.prototype.exitImportExposingAll = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#importName.
BwaxListener.prototype.enterImportName = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#importName.
BwaxListener.prototype.exitImportName = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#exposedName.
BwaxListener.prototype.enterExposedName = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#exposedName.
BwaxListener.prototype.exitExposedName = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#powOp.
BwaxListener.prototype.enterPowOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#powOp.
BwaxListener.prototype.exitPowOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#getterExpr.
BwaxListener.prototype.enterGetterExpr = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#getterExpr.
BwaxListener.prototype.exitGetterExpr = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#binOpExpr.
BwaxListener.prototype.enterBinOpExpr = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#binOpExpr.
BwaxListener.prototype.exitBinOpExpr = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#callWithCC.
BwaxListener.prototype.enterCallWithCC = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#callWithCC.
BwaxListener.prototype.exitCallWithCC = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#refExp.
BwaxListener.prototype.enterRefExp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#refExp.
BwaxListener.prototype.exitRefExp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#consOp.
BwaxListener.prototype.enterConsOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#consOp.
BwaxListener.prototype.exitConsOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#tuple.
BwaxListener.prototype.enterTuple = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#tuple.
BwaxListener.prototype.exitTuple = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#lambda.
BwaxListener.prototype.enterLambda = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#lambda.
BwaxListener.prototype.exitLambda = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#literalExp.
BwaxListener.prototype.enterLiteralExp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#literalExp.
BwaxListener.prototype.exitLiteralExp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#pipeTo.
BwaxListener.prototype.enterPipeTo = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#pipeTo.
BwaxListener.prototype.exitPipeTo = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#qualRef.
BwaxListener.prototype.enterQualRef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#qualRef.
BwaxListener.prototype.exitQualRef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#compTo.
BwaxListener.prototype.enterCompTo = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#compTo.
BwaxListener.prototype.exitCompTo = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#record.
BwaxListener.prototype.enterRecord = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#record.
BwaxListener.prototype.exitRecord = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#bracket.
BwaxListener.prototype.enterBracket = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#bracket.
BwaxListener.prototype.exitBracket = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#let.
BwaxListener.prototype.enterLet = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#let.
BwaxListener.prototype.exitLet = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#if.
BwaxListener.prototype.enterIf = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#if.
BwaxListener.prototype.exitIf = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#woRecordList.
BwaxListener.prototype.enterWoRecordList = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#woRecordList.
BwaxListener.prototype.exitWoRecordList = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#logicNot.
BwaxListener.prototype.enterLogicNot = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#logicNot.
BwaxListener.prototype.exitLogicNot = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#case.
BwaxListener.prototype.enterCase = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#case.
BwaxListener.prototype.exitCase = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeAnnoatedExp.
BwaxListener.prototype.enterTypeAnnoatedExp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeAnnoatedExp.
BwaxListener.prototype.exitTypeAnnoatedExp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#apply.
BwaxListener.prototype.enterApply = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#apply.
BwaxListener.prototype.exitApply = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#equalityOp.
BwaxListener.prototype.enterEqualityOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#equalityOp.
BwaxListener.prototype.exitEqualityOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#addOp.
BwaxListener.prototype.enterAddOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#addOp.
BwaxListener.prototype.exitAddOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#constructor.
BwaxListener.prototype.enterConstructor = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#constructor.
BwaxListener.prototype.exitConstructor = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#list.
BwaxListener.prototype.enterList = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#list.
BwaxListener.prototype.exitList = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#compareOp.
BwaxListener.prototype.enterCompareOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#compareOp.
BwaxListener.prototype.exitCompareOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#compFrom.
BwaxListener.prototype.enterCompFrom = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#compFrom.
BwaxListener.prototype.exitCompFrom = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#unit.
BwaxListener.prototype.enterUnit = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#unit.
BwaxListener.prototype.exitUnit = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#external.
BwaxListener.prototype.enterExternal = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#external.
BwaxListener.prototype.exitExternal = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#recordGet.
BwaxListener.prototype.enterRecordGet = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#recordGet.
BwaxListener.prototype.exitRecordGet = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#mulOp.
BwaxListener.prototype.enterMulOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#mulOp.
BwaxListener.prototype.exitMulOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#appendOp.
BwaxListener.prototype.enterAppendOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#appendOp.
BwaxListener.prototype.exitAppendOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#logicOp.
BwaxListener.prototype.enterLogicOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#logicOp.
BwaxListener.prototype.exitLogicOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#recordUpdate.
BwaxListener.prototype.enterRecordUpdate = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#recordUpdate.
BwaxListener.prototype.exitRecordUpdate = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#pipeFrom.
BwaxListener.prototype.enterPipeFrom = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#pipeFrom.
BwaxListener.prototype.exitPipeFrom = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#binOp.
BwaxListener.prototype.enterBinOp = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#binOp.
BwaxListener.prototype.exitBinOp = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#caseBranch.
BwaxListener.prototype.enterCaseBranch = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#caseBranch.
BwaxListener.prototype.exitCaseBranch = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#fieldTypeDecl.
BwaxListener.prototype.enterFieldTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#fieldTypeDecl.
BwaxListener.prototype.exitFieldTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeDecl.
BwaxListener.prototype.enterTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeDecl.
BwaxListener.prototype.exitTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeDeclAlt.
BwaxListener.prototype.enterTypeDeclAlt = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeDeclAlt.
BwaxListener.prototype.exitTypeDeclAlt = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#varTypeDecl.
BwaxListener.prototype.enterVarTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#varTypeDecl.
BwaxListener.prototype.exitVarTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#roVarTypeDecl.
BwaxListener.prototype.enterRoVarTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#roVarTypeDecl.
BwaxListener.prototype.exitRoVarTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#unitTypeDecl.
BwaxListener.prototype.enterUnitTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#unitTypeDecl.
BwaxListener.prototype.exitUnitTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#tupleTypeDecl.
BwaxListener.prototype.enterTupleTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#tupleTypeDecl.
BwaxListener.prototype.exitTupleTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#recordExtTypeDecl.
BwaxListener.prototype.enterRecordExtTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#recordExtTypeDecl.
BwaxListener.prototype.exitRecordExtTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#recordTypeDecl.
BwaxListener.prototype.enterRecordTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#recordTypeDecl.
BwaxListener.prototype.exitRecordTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#roRecordTypeDecl.
BwaxListener.prototype.enterRoRecordTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#roRecordTypeDecl.
BwaxListener.prototype.exitRoRecordTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#woOptionalRecordTypeDecl.
BwaxListener.prototype.enterWoOptionalRecordTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#woOptionalRecordTypeDecl.
BwaxListener.prototype.exitWoOptionalRecordTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#woRecordTypeDecl.
BwaxListener.prototype.enterWoRecordTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#woRecordTypeDecl.
BwaxListener.prototype.exitWoRecordTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#opaquedTypeDecl.
BwaxListener.prototype.enterOpaquedTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#opaquedTypeDecl.
BwaxListener.prototype.exitOpaquedTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeDeclBracket.
BwaxListener.prototype.enterTypeDeclBracket = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeDeclBracket.
BwaxListener.prototype.exitTypeDeclBracket = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#constr0TypeDecl.
BwaxListener.prototype.enterConstr0TypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#constr0TypeDecl.
BwaxListener.prototype.exitConstr0TypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#constrTypeDecl.
BwaxListener.prototype.enterConstrTypeDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#constrTypeDecl.
BwaxListener.prototype.exitConstrTypeDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#constrDecl.
BwaxListener.prototype.enterConstrDecl = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#constrDecl.
BwaxListener.prototype.exitConstrDecl = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeAnnot.
BwaxListener.prototype.enterTypeAnnot = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeAnnot.
BwaxListener.prototype.exitTypeAnnot = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#valueDef.
BwaxListener.prototype.enterValueDef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#valueDef.
BwaxListener.prototype.exitValueDef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#functionDef.
BwaxListener.prototype.enterFunctionDef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#functionDef.
BwaxListener.prototype.exitFunctionDef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeAliasDef.
BwaxListener.prototype.enterTypeAliasDef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeAliasDef.
BwaxListener.prototype.exitTypeAliasDef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#typeDef.
BwaxListener.prototype.enterTypeDef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#typeDef.
BwaxListener.prototype.exitTypeDef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#opaqueTypeDef.
BwaxListener.prototype.enterOpaqueTypeDef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#opaqueTypeDef.
BwaxListener.prototype.exitOpaqueTypeDef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#recordPtn.
BwaxListener.prototype.enterRecordPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#recordPtn.
BwaxListener.prototype.exitRecordPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#bracketForPtn.
BwaxListener.prototype.enterBracketForPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#bracketForPtn.
BwaxListener.prototype.exitBracketForPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#tuplePtn.
BwaxListener.prototype.enterTuplePtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#tuplePtn.
BwaxListener.prototype.exitTuplePtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#consListPtn.
BwaxListener.prototype.enterConsListPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#consListPtn.
BwaxListener.prototype.exitConsListPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#varPtn.
BwaxListener.prototype.enterVarPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#varPtn.
BwaxListener.prototype.exitVarPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#wildcardPtn.
BwaxListener.prototype.enterWildcardPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#wildcardPtn.
BwaxListener.prototype.exitWildcardPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#literalPtn.
BwaxListener.prototype.enterLiteralPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#literalPtn.
BwaxListener.prototype.exitLiteralPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#nilListPtn.
BwaxListener.prototype.enterNilListPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#nilListPtn.
BwaxListener.prototype.exitNilListPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#constructorPtn.
BwaxListener.prototype.enterConstructorPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#constructorPtn.
BwaxListener.prototype.exitConstructorPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#unitPtn.
BwaxListener.prototype.enterUnitPtn = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#unitPtn.
BwaxListener.prototype.exitUnitPtn = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#varRef.
BwaxListener.prototype.enterVarRef = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#varRef.
BwaxListener.prototype.exitVarRef = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#charLiteral.
BwaxListener.prototype.enterCharLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#charLiteral.
BwaxListener.prototype.exitCharLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#stringLiteral.
BwaxListener.prototype.enterStringLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#stringLiteral.
BwaxListener.prototype.exitStringLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#longStringLiteral.
BwaxListener.prototype.enterLongStringLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#longStringLiteral.
BwaxListener.prototype.exitLongStringLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#integerLiteral.
BwaxListener.prototype.enterIntegerLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#integerLiteral.
BwaxListener.prototype.exitIntegerLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#floatLiteral.
BwaxListener.prototype.enterFloatLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#floatLiteral.
BwaxListener.prototype.exitFloatLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#trueLiteral.
BwaxListener.prototype.enterTrueLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#trueLiteral.
BwaxListener.prototype.exitTrueLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#falseLiteral.
BwaxListener.prototype.enterFalseLiteral = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#falseLiteral.
BwaxListener.prototype.exitFalseLiteral = function(ctx) {
};


// Enter a parse tree produced by BwaxParser#recBind.
BwaxListener.prototype.enterRecBind = function(ctx) {
};

// Exit a parse tree produced by BwaxParser#recBind.
BwaxListener.prototype.exitRecBind = function(ctx) {
};



exports.BwaxListener = BwaxListener;