
import React from 'react'

import { BiPaperPlane } from "react-icons/bi";

import { make as PublishPage } from "Client/re/pages/PublishPage.bs";

import Drawer from 'rsuite/Drawer';

export default function PublishButton({ className, style = {} }) {

    const [open, setOpen] = React.useState(false);

    return (
        <>
            <div className={className} style={style} onClick={_ => {
                setOpen(true);
            }}>
                <BiPaperPlane />
            </div>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <Drawer.Body>
                    <PublishPage />
                </Drawer.Body>
            </Drawer>
        </>
    )
}
