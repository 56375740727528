// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Utils = require("bwax/utils");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var DictUtils$BwaxAdmin = require("../../../bwax-js/re/utils/DictUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryRunner$BwaxAdmin = require("../../../bwax-js/re/binding/QueryRunner.bs.js");
var RunClientQuery = require("bwax/query/runClientQuery");
var EditingContainer$BwaxAdmin = require("./EditingContainer.bs.js");
var CreateRouteTo = require("bwax-ui/page/createRouteTo");
var UseCurrentUser = require("bwax-ui/auth/useCurrentUser");
var DataLoaderContext = require("bwax-ui/store/DataLoaderContext");
var IsNodeValueEqual = require("bwax-ui/legacy/page/isNodeValueEqual");
var SetUpActionPreparer = require("bwax-ui/legacy/page/setUpActionPreparer");
var UseQueriedValue = require("bwax-ui/legacy/page/hooks/useQueriedValue");

var CurrentUser = { };

function QueryContainer(Props) {
  var def = Props.def;
  var match = Props.baseData;
  var baseData = match !== undefined ? Caml_option.valFromOption(match) : { };
  var allDataTypes = Props.allDataTypes;
  var allEntities = Props.allEntities;
  var extensions = Props.extensions;
  var loadingComp = Props.loadingComp;
  var routeTo = Props.routeTo;
  var history = Props.history;
  var $$location = Props.location;
  var match__ = Props.match_;
  var theme = Props.theme;
  var toast = Props.toast;
  var widgetFactory = Props.widgetFactory;
  var match$1 = Props.usingLastPage;
  var usingLastPage = match$1 !== undefined ? match$1 : false;
  var queryTarget = Props.queryTarget;
  var match$2 = Props.customActions;
  var customActions = match$2 !== undefined ? Caml_option.valFromOption(match$2) : { };
  var additional = Props.additional;
  var dlc = React.useContext(DataLoaderContext.default);
  var queryEnv = QueryRunner$BwaxAdmin.toEnv(dlc);
  var queryRunner;
  if (queryTarget === "definition") {
    var partial_arg = Caml_option.some(queryEnv);
    queryRunner = (function (param, param$1, param$2) {
        return RunClientQuery.runDefinitionQuery_re(partial_arg, param, param$1, param$2);
      });
  } else {
    var partial_arg$1 = Caml_option.some(queryEnv);
    queryRunner = (function (param, param$1, param$2) {
        return RunClientQuery.runDataQuery_re(partial_arg$1, param, param$1, param$2);
      });
  }
  var routeToFunc = routeTo !== undefined ? routeTo : CreateRouteTo.default(history, /* array */["/ext/pay"], {
          sandbox: dlc.sandbox
        });
  var ucu = UseCurrentUser.default({
        allEntities: allEntities,
        allDataTypes: allDataTypes,
        queryTarget: queryTarget
      });
  var currentUser = ucu.currentUser;
  var currentUserNameKey = "当前用户";
  var roots = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            currentUserNameKey,
            {
              entityName: "用户"
            }
          ],
          /* [] */0
        ]));
  var match$3 = React.useState((function () {
          return ;
        }));
  var setLastPage = match$3[1];
  var lastPage = match$3[0];
  var qv = UseQueriedValue.default(def, {
        baseData: DictUtils$BwaxAdmin.mergeWith(baseData, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      currentUserNameKey,
                      currentUser
                    ],
                    /* [] */0
                  ]))),
        allDataTypes: allDataTypes,
        allEntities: allEntities,
        roots: roots,
        queryTarget: queryTarget
      });
  var value = qv.value;
  var reload = qv.reload;
  var loadMore = qv.loadMore;
  var extraState = qv.extraState;
  var updateExtraState = qv.updateExtraState;
  var setInputState = qv.setInputState;
  React.useEffect((function () {
          return Belt_Option.map(value, (function (v) {
                        var cacheLastPage = function (param) {
                          return Curry._1(setLastPage, (function (param) {
                                        return /* record */Caml_chrome_debugger.record([
                                                  "setting",
                                                  "pageID",
                                                  "def"
                                                ], [
                                                  v,
                                                  $$location.pathname,
                                                  def
                                                ]);
                                      }));
                        };
                        if (usingLastPage) {
                          if (lastPage !== undefined && lastPage[/* pageID */1] !== $$location.pathname) {
                            window.scrollTo(0, 0);
                          }
                          
                        }
                        if (lastPage !== undefined) {
                          var lp = lastPage;
                          if (lp[/* pageID */1] !== $$location.pathname) {
                            if (IsNodeValueEqual.default(lp[/* setting */0], v)) {
                              
                            } else {
                              cacheLastPage(/* () */0);
                            }
                          }
                          
                        } else {
                          cacheLastPage(/* () */0);
                        }
                        return (function (param) {
                            return /* () */0;
                          });
                      }));
        }), /* array */[value]);
  var page;
  if (usingLastPage) {
    if (value !== undefined) {
      var v = Caml_option.valFromOption(value);
      if (lastPage !== undefined) {
        var lp = lastPage;
        page = IsNodeValueEqual.default(lp[/* setting */0], v) ? lp : /* record */Caml_chrome_debugger.record([
              "setting",
              "pageID",
              "def"
            ], [
              v,
              $$location.pathname,
              def
            ]);
      } else {
        page = /* record */Caml_chrome_debugger.record([
            "setting",
            "pageID",
            "def"
          ], [
            v,
            $$location.pathname,
            def
          ]);
      }
    } else {
      page = lastPage !== undefined ? lastPage : undefined;
    }
  } else {
    page = Belt_Option.map(value, (function (v) {
            return /* record */Caml_chrome_debugger.record([
                      "setting",
                      "pageID",
                      "def"
                    ], [
                      v,
                      $$location.pathname,
                      def
                    ]);
          }));
  }
  var reloadUser = ucu.forceReload;
  var produceWidget = function (def, commonWidgetProps, key) {
    return widgetFactory.produceWidget(def, Object.assign(commonWidgetProps, {
                    theme: theme,
                    allEntities: allEntities,
                    allDataTypes: allDataTypes,
                    extensions: extensions,
                    queryTarget: queryTarget,
                    toast: toast,
                    baseData: baseData,
                    history: history,
                    location: $$location,
                    match_: match__,
                    widgetFactory: widgetFactory,
                    customActions: customActions,
                    loadingComp: loadingComp,
                    routeTo: routeToFunc,
                    reloadUser: reloadUser,
                    additional: additional
                  }), key);
  };
  var basePrepareActions = function (env) {
    var r = function (args) {
      var queryNames = args.queryNames;
      var partialVariables = args.partialVariables;
      if (partialVariables !== undefined) {
        return Curry._1(reload, Caml_option.valFromOption(partialVariables));
      } else if (queryNames !== undefined) {
        return Curry._1(reload, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            queryNames,
                            { }
                          ],
                          /* [] */0
                        ])));
      } else {
        return Curry._1(reload, { });
      }
    };
    return SetUpActionPreparer.default(Object.assign(Object.assign({
                        history: history,
                        routeTo: routeToFunc,
                        updateState: updateExtraState,
                        loadMore: loadMore,
                        refetch: r,
                        reloadUser: reloadUser,
                        allEntities: allEntities,
                        allDataTypes: allDataTypes,
                        queryRunner: queryRunner,
                        toast: toast
                      }, env), queryEnv), customActions);
  };
  if (page !== undefined) {
    var p = page;
    var key = String(Utils.hashCode(JSON.stringify(p[/* def */2])));
    return React.createElement(EditingContainer$BwaxAdmin.make, {
                stateKey: key,
                setting: p[/* setting */0],
                extraState: extraState,
                setInputState: setInputState,
                basePrepareActions: basePrepareActions,
                produceWidget: produceWidget,
                routeTo: routeToFunc,
                theme: theme,
                key: key
              });
  } else {
    return React.createElement(loadingComp, {
                theme: theme
              });
  }
}

var make = QueryContainer;

exports.CurrentUser = CurrentUser;
exports.make = make;
/* react Not a pure module */
