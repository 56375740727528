// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Lang_entry_slim$BwaxAdmin = require("../../../bwax-js/ml/lang/lang_entry_slim.bs.js");

function pack_page_params(params) {
  return Runtime_common$BwaxAdmin.pack_list(Plate$BwaxAdmin.List.map((function (param) {
                    return /* tuple */[
                            /* V_tuple */Caml_chrome_debugger.variant("V_tuple", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Runtime_common$BwaxAdmin.pack_string(param[0]),
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        Runtime_common$BwaxAdmin.pack_string(param[1]),
                                        /* [] */0
                                      ])
                                  ])]),
                            Runtime_common$BwaxAdmin.nt
                          ];
                  }), Plate$BwaxAdmin.List.keep_map((function (param) {
                        var k = param[0];
                        return Plate$BwaxAdmin.$$Option.map((function (s) {
                                      return /* tuple */[
                                              k,
                                              s
                                            ];
                                    }), Js_json.decodeString(param[1]));
                      }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(params)))))));
}

function need_prepared_data(param) {
  var arity = Lang_entry_slim$BwaxAdmin.get_init_arity(/* tuple */[
        param[0],
        param[1]
      ]);
  if (arity !== undefined) {
    return arity >= 2;
  } else {
    return false;
  }
}

function get_string(name, binds) {
  var match = Plate$BwaxAdmin.List.assoc(name, binds);
  if (match !== undefined) {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return ;
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag !== /* String */2) {
        return ;
      } else {
        return match$2[0];
      }
    }
  }
  
}

function get_head(binds) {
  var match = Plate$BwaxAdmin.List.assoc("head", binds);
  if (match !== undefined) {
    return ;
  }
  
}

function get_page_def(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    return ;
  } else {
    var binds = match[0];
    var match$1 = Plate$BwaxAdmin.List.assoc("layout", binds);
    if (match$1 !== undefined) {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag !== /* V_raw */7) {
        return ;
      } else {
        get_head(binds);
        return /* tuple */[
                match$2[0],
                undefined,
                undefined
              ];
      }
    } else {
      return ;
    }
  }
}

exports.pack_page_params = pack_page_params;
exports.need_prepared_data = need_prepared_data;
exports.get_string = get_string;
exports.get_head = get_head;
exports.get_page_def = get_page_def;
/* Plate-BwaxAdmin Not a pure module */
