

import React, { useEffect, useState } from 'react'

import {
    TbArrowBarUp, TbArrowBarRight, TbArrowBarDown, TbArrowBarLeft, TbArrowBarToUp, TbArrowBarToRight, TbArrowBarToDown, TbArrowBarToLeft
} from 'react-icons/tb'
import { ResetIcon } from '@radix-ui/react-icons'

import { Input, Select, Slider } from '@arco-design/web-react';
const Option = Select.Option;

import Popover from 'bwax-ui/components/legacy/Popover'

import './Spacing.less'

const styleKeyCursor = {
    marginTop: 'n-resize',
    marginRight: 'e-resize',
    marginBottom: 's-resize',
    marginLeft: 'w-resize',
    paddingTop: 's-resize',
    paddingRight: 'w-resize',
    paddingBottom: 'n-resize',
    paddingLeft: 'e-resize'
}

export default function Spacing({ attributes, onChange }) {

    const [margin, setMargin] = useState({
        marginTop: { value: 0, unit: 'px' },
        marginRight: { value: 0, unit: 'px' },
        marginBottom: { value: 0, unit: 'px' },
        marginLeft: { value: 0, unit: 'px' }
    })

    const [padding, setPadding] = useState({
        paddingTop: { value: 0, unit: 'px' },
        paddingRight: { value: 0, unit: 'px' },
        paddingBottom: { value: 0, unit: 'px' },
        paddingLeft: { value: 0, unit: 'px' }
    })

    const [inputSpacing, setInputSpacing] = useState({ value: 0, unit: 'px' })

    const [activeMoveStyleKey, setActiveMoveStyleKey] = useState(null)
    const [activeEditStyleKey, setActiveEditStyleKey] = useState(null)
    const singleValueRegx = /^((-?\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?|[aA]uto)$/

    useEffect(() => {
        if(attributes['margin']) {
            const marginArr = attributes['margin'].split(" ")
            let marginEach = {...margin}
            marginArr.map((m, index) => {
                const matchResult = m.match(singleValueRegx)
                if(matchResult) {
                    // 只有数字的话，当成"数字+px"
                    const valueObj = matchResult[1] === 'auto' || matchResult[1] === 'Auto' ? { value: 'Auto', unit: "-" } : 
                        { value: matchResult[2], unit: matchResult[3] ? matchResult[3] : 'px' }
                    marginEach = { 
                        ...marginEach, 
                        marginTop: index === 0 ? valueObj : marginEach.marginTop, 
                        marginRight: index === 0 || index === 1 ? valueObj : marginEach.marginRight,
                        marginBottom: index === 0 || index === 2 ? valueObj : marginEach.marginBottom,
                        marginLeft: index === 0 || index === 1 || index === 3 ? valueObj : marginEach.marginLeft
                    }
                }
            })
            setMargin({...margin, ...marginEach})
        }

        if(attributes['padding']){
            const paddingArr = attributes['padding'].split(" ")
            let paddingEach = {...padding}
            paddingArr.map((p, index) => {
                const matchResult = p.match(singleValueRegx)
                if(matchResult) {
                    const valueObj = { value: matchResult[2], unit: matchResult[3] ? matchResult[3] : 'px' }
                    paddingEach = {
                        ...paddingEach,
                        paddingTop: index === 0 ? valueObj : paddingEach.paddingTop, 
                        paddingRight: index === 0 || index === 1 ? valueObj : paddingEach.paddingRight,
                        paddingBottom: index === 0 || index === 2 ? valueObj : paddingEach.paddingBottom,
                        paddingLeft: index === 0 || index === 1 || index === 3 ? valueObj : paddingEach.paddingLeft
                    }
                }
            })
            setPadding({...padding, ...paddingEach})
        }
    }, [attributes])

    function getNewSpacingObj (styleKey, newSpacing) {
        const isMargin = styleKey.startsWith("margin")
        function getValueWithUnitStr (originalSpacing) {
            return  Object.keys(originalSpacing).reduce((acc, current, currentIndex) => {
                const { value, unit } = originalSpacing[current]
                const valueWithUnitStr = current === styleKey ? newSpacing : 
                    `${value}${unit === "-" || value === '0' ? '' : unit}`
                return acc + (currentIndex === 0 ? valueWithUnitStr : ' ' + valueWithUnitStr)
            }, "")
        }

        if(isMargin) {
            const marginStr = getValueWithUnitStr(margin)
            return { margin: marginStr ? marginStr : undefined }
        } else {
            const paddingStr = getValueWithUnitStr(padding)
            return { padding: paddingStr ? paddingStr : undefined }
        }
    }

    function updateSpacing(value, styleKey) {
        const isMargin = styleKey.startsWith("margin")
        const { unit } = isMargin ? margin[styleKey] : padding[styleKey]
        const matchedResult = value ? value.match(singleValueRegx) : null
        if (matchedResult) {
            const matchedValue = matchedResult[2] ? matchedResult[2] : matchedResult[1]
            const matchedUnit = matchedResult[3] ? matchedResult[3] : (unit === '-' ? 'px' : unit)
            setInputSpacing({
                ...inputSpacing,
                value: matchedValue
            })
            if(isMargin) {
                setMargin({
                    ...margin,
                    [styleKey]: {
                        value: matchedValue,
                        unit: matchedUnit
                    }
                })
            } else {
                setPadding({
                    ...padding,
                    [styleKey]: {
                        value: matchedValue,
                        unit: matchedUnit
                    }
                })
            }
            if(isMargin) {
                const newSpacing = `${matchedValue}${matchedValue === 'Auto' || matchedValue === 'auto' || matchedValue === '0' ? '' : matchedUnit}`
                onChange(getNewSpacingObj(styleKey, newSpacing))
            } else {
                const newSpacing = `${matchedValue}${matchedValue === '0' ? '' : matchedUnit}`
                onChange(getNewSpacingObj(styleKey, newSpacing))
            } 
        }
    }

    function changeValue (value) {
        setInputSpacing({
            ...inputSpacing,
            value
        })
    }

    function changeValueConfirm (styleKey) {
        const isMargin = styleKey.startsWith("margin")
        const { value, unit } = inputSpacing
        const matchedResult = value ? value.match(singleValueRegx) : null
        if (matchedResult) {
            const matchedValue = matchedResult[2] ? matchedResult[2] : matchedResult[1]
            const matchedUnit = matchedResult[3] ? matchedResult[3] : (unit === '-' ? 'px' : unit)
            if(isMargin) {
                setMargin({
                    ...margin,
                    [styleKey]: {
                        ...margin[styleKey],
                        value: matchedValue
                    }
                })
            } else {
                setPadding({
                    ...padding,
                    [styleKey]: {
                        ...padding[styleKey],
                        value: matchedValue
                    }
                })
            }
            setInputSpacing({
                ...inputSpacing,
                value: matchedValue,
                unit: matchedUnit
            })
            if(isMargin) {
                const newSpacing = `${matchedValue}${matchedValue === 'Auto' || matchedValue === 'auto' || matchedValue === '0' ? '' : matchedUnit}`
                onChange(getNewSpacingObj(styleKey, newSpacing))
            } else if (!matchedValue.startsWith('-')) {
                // padding 不能为负数
                const newSpacing = `${matchedValue}${matchedValue === '0' ? '' : matchedUnit}`
                onChange(getNewSpacingObj(styleKey, newSpacing))
            } else {
                // 重置不合法的值
                setInputSpacing({
                    ...inputSpacing,
                    value: isMargin ? margin[styleKey].value : padding[styleKey].value
                })
            }
        }

    }

    function renderEditContent (isMargin, position) {

        const styleKey = `${isMargin ? 'margin' : 'padding'}${position}`

        const editContentIcons = {
            marginTop: <TbArrowBarUp/>,
            marginRight: <TbArrowBarRight/>,
            marginBottom: <TbArrowBarDown/>,
            marginLeft: <TbArrowBarLeft/>,
            paddingTop: <TbArrowBarToUp/>,
            paddingRight: <TbArrowBarToRight/>,
            paddingBottom: <TbArrowBarToDown/>,
            paddingLeft: <TbArrowBarToLeft/>
        }
        
        const units = ['px', 'em', 'rem', 'ch', 'vw', 'vh', '%']

        function selectUnit(unit) {
            if (unit === 'auto' && isMargin) {
                setInputSpacing({
                    ...inputSpacing,
                    value: 'Auto',
                    unit: '-'
                })
                onChange(getNewSpacingObj(styleKey, 'auto'))
            } else {
                const changedValue = isMargin ? (margin[styleKey].value === "Auto" || margin[styleKey].value === "auto" ? 0 : margin[styleKey].value) : padding[styleKey].value
                const newSpacing = `${changedValue}${changedValue === 0 ? '' : unit}`
                setInputSpacing({
                    ...inputSpacing,
                    value: changedValue,
                    unit
                })
                onChange(getNewSpacingObj(styleKey, newSpacing))
            }
        }

        const unitSelect = (
            <Select value={inputSpacing.unit} size={'mini'} getPopupContainer={node => node}
                className="unit-select" arrowIcon={null} triggerProps={{ autoAlignPopupWidth: false }}
                onChange={unit => selectUnit(unit)}
            >
                {(isMargin ? [...units, 'auto'] : units).map(option => (
                    <Option key={option} value={option} className='unit-option'>
                        {option.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )

        const valueOptions = [{
            units: ['px', 'ch', '-'],
            options: ['0', '10', '20', '40', '60', '100', '140', '220']
        }, {
            units: ['%', 'vh', 'vw'],
            options: ['0', '5', '10', '15', '25', '50', '75', '100']
        }, {
            units: ['em', 'rem'],
            options: ['0', '0.125', '0.25', '0.5', '1', '2', '4', '8']
        }]

        const ranges = [{
            units: ['px', 'vh', 'vw', '-'],
            max: 200,
            min: -200
        }, {
            units: ['ch', '%'],
            max: 100,
            min: -100
        }, {
            units: ['rem', 'em'],
            max: 50,
            min: -50
        }]

        const options = valueOptions.find(op => op.units.some(u => u === (isMargin ? margin[styleKey].unit : padding[styleKey].unit)))
        const marginRange = isMargin ? ranges.find(r => r.units.some(u => u === margin[styleKey].unit)) : {}

        return (
            <div className='spacing-value-edit-container'>
                <div className='value-input-container'>
                    <div className='icon-box'>{editContentIcons[styleKey]}</div>
                    {
                        isMargin ? (
                            <Slider value={parseFloat(inputSpacing.value) ? parseFloat(inputSpacing.value) : 0} 
                                onChange={value => updateSpacing(value.toString(), styleKey)} style={{ margin: '0 12px' }} 
                                max={marginRange.max} min={marginRange.min}
                            />
                        ) : (
                            <Slider value={parseFloat(inputSpacing.value) ? parseFloat(inputSpacing.value) : 0} 
                                onChange={value => updateSpacing(value.toString(), styleKey)} style={{ margin: '0 12px' }} />
                        )
                    }
                    <Input value={inputSpacing.value} size="mini" suffix={unitSelect} style={{ width: 72, flexShrink: 0 }}
                        onChange={value => changeValue(value, styleKey)} 
                        onBlur={() => changeValueConfirm(styleKey)}
                        onPressEnter={() => changeValueConfirm(styleKey)}
                    />
                </div>
                <div className={`value-select-container ${isMargin ? 'margin' : 'padding'}`}>
                    {
                        isMargin ? (
                            <div className='margin-auto' onClick={() => updateSpacing('auto', styleKey)}>
                                Auto
                            </div>
                        ) : null
                    }
                    {
                        options.options.map(o => {
                            return (
                                <div key={o} className='option' onClick={() => updateSpacing(o, styleKey)}>
                                    {o}
                                </div>
                            )
                        })
                        
                    }
                </div>
                {
                    inputSpacing.value !== '0' ? (
                        <div className='reset-container'>
                            <div className='icon-box' onClick={() => updateSpacing('0px', styleKey)}><ResetIcon/></div>
                            <div className='tip'>Resetting will revert to initial value.</div>
                        </div>
                    ) : null
                }
            </div>
        )
    }

    function renderEditableValue (isMargin, position) {

        const styleKey = `${isMargin ? 'margin' : 'padding'}${position}`

        function getDisplayValueWithUnit () {
            if(isMargin) {
                return margin[styleKey].unit === 'px' ? 
                    margin[styleKey].value : `${margin[styleKey].value}${margin[styleKey].unit === "-" ? "" : margin[styleKey].unit}`
            } else {
                return padding[styleKey].unit === 'px' ? padding[styleKey].value : `${padding[styleKey].value}${padding[styleKey].unit}`
            }
        }

        function isActive () {
            if(isMargin) {
                return !(parseFloat(margin[styleKey].value) === 0)
            } else {
                return !(parseFloat(padding[styleKey].value) === 0)
            }
        }

        return (
            <Popover content={renderEditContent(isMargin, position)} positions={["bottom"]} visible={activeEditStyleKey === styleKey} 
                onClickOutside={_ => {
                    setActiveEditStyleKey(null)
                }}
            >
                <div className={`value-label ${position} ${isActive() ? 'active' : ''}`} 
                    onClick={() => {
                        setInputSpacing(isMargin ? margin[styleKey] : padding[styleKey])
                        if(!activeEditStyleKey) {
                            setActiveEditStyleKey(styleKey)
                        } else {
                            setActiveEditStyleKey(null)
                        }
                    }}
                >
                    {getDisplayValueWithUnit()}
                </div>
            </Popover>
        )
    }

    function mouseDown(e, styleKey) {
        setActiveMoveStyleKey(styleKey)
        const originalPoint = { x: e.clientX, y: e.clientY }

        if(typeof (document) !== "undefined") {

            document.body.style.cursor = styleKeyCursor[styleKey]
            function mouseMove(e) {

                function updateValue (delta, position) {
                    let originalValue = 0
                    const isMargin = styleKey.startsWith('margin')
                    if(isMargin) {
                        originalValue = parseInt(margin[styleKey].value) ? parseInt(margin[styleKey].value) : 0
                    } else {
                        originalValue = parseInt(padding[styleKey].value) ? parseInt(padding[styleKey].value) : 0
                    }
                    const changedValue = isMargin ? originalValue + delta : (originalValue + delta >= 0 ? originalValue + delta : 0)
                    updateSpacing(changedValue.toString(), styleKey)
                }

                if(styleKey === "marginTop") {
                    const delta = originalPoint.y - e.clientY
                    updateValue(delta, 'Top')
                } else if(styleKey === "marginRight") {
                    const delta = e.clientX - originalPoint.x
                    updateValue(delta, 'Right')
                } else if(styleKey === "marginBottom") {
                    const delta = e.clientY - originalPoint.y
                    updateValue(delta, 'Bottom')
                } else if(styleKey === "marginLeft") {
                    const delta = originalPoint.x - e.clientX
                    updateValue(delta, 'Left')
                } else if(styleKey === "paddingTop") {
                    const delta = originalPoint.y - e.clientY
                    updateValue(delta, 'Top')
                } else if(styleKey === "paddingRight") {
                    const delta = e.clientX - originalPoint.x
                    updateValue(delta, 'Right')
                } else if(styleKey === "paddingBottom") {
                    const delta = e.clientY - originalPoint.y
                    updateValue(delta, 'Bottom')
                } else {
                    const delta = originalPoint.x - e.clientX
                    updateValue(delta, 'Left')
                }
            }
    
            function mouseUp() {
                setActiveMoveStyleKey(null)
                document.body.style.cursor = ""
                document.removeEventListener('mousemove', mouseMove, false);
                document.removeEventListener('mouseup', mouseUp, false);
            }
    
            document.addEventListener('mousemove', mouseMove, false);
            document.addEventListener('mouseup', mouseUp, false)
        }
    };

    return (
        <div className='spacing-container'>  
            <div className='margin-box'>
                <svg width="250" height="128">
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "n-resize"} mode="delta" fill="currentColor" d="m0,0 h250 l-36,24 h-178 l-36,-24z" style={{ color: '#e8ebed' }}
                        onMouseDown={e => mouseDown(e, 'marginTop')}
                    ></path>
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "e-resize"} mode="delta" fill="currentColor" d="m250,0 v128 l-36,-24 v-80 l36,-24z" style={{ color: '#f0f3f5' }}
                        onMouseDown={e => mouseDown(e, 'marginRight')}
                    ></path>
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "s-resize"} mode="delta" fill="currentColor" d="m0,128 h250 l-36,-24 h-178 l-36,24z" style={{ color: '#e8ebed' }}
                        onMouseDown={e => mouseDown(e, 'marginBottom')}
                    ></path>
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "w-resize"} mode="delta" fill="currentColor" d="m0,0 v128 l36,-24 v-80 l-36,-24z" style={{ color: '#f0f3f5' }}
                        onMouseDown={e => mouseDown(e, 'marginLeft')}
                    ></path>
                </svg>
                { renderEditableValue(true, 'Top') }
                { renderEditableValue(true, 'Right') }
                { renderEditableValue(true, 'Bottom') }
                { renderEditableValue(true, 'Left') }
            </div>
            <div className='padding-box'>
                <svg width="170" height="72">
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "s-resize"} mode="delta" fill="currentColor" d="m 0,0 h170 l-36,24 h-98 l-36,-24z" style={{ color: '#e8ebed' }}
                        onMouseDown={e => mouseDown(e, 'paddingTop')}
                    ></path>
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "w-resize"} mode="delta" fill="currentColor" d="m 170,0 v72 l-36,-24 v-24 l36,-24z" style={{ color: '#f0f3f5' }}
                        onMouseDown={e => mouseDown(e, 'paddingRight')}
                    ></path>
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "n-resize"} mode="delta" fill="currentColor" d="m 0,72 h170 l-36,-24 h-98 l-36,24z" style={{ color: '#e8ebed' }}
                        onMouseDown={e => mouseDown(e, 'paddingBottom')}
                    ></path>
                    <path cursor={activeMoveStyleKey ? styleKeyCursor[activeMoveStyleKey] : "e-resize"} mode="delta" fill="currentColor" d="m 0,0 v72 l36,-24 v-24 l-36,-24z" style={{ color: '#f0f3f5' }}
                        onMouseDown={e => mouseDown(e, 'paddingLeft')}
                    ></path>
                </svg>
                { renderEditableValue(false, 'Top') }
                { renderEditableValue(false, 'Right') }
                { renderEditableValue(false, 'Bottom') }
                { renderEditableValue(false, 'Left') }
            </div>
            <div className='label margin'>MARGIN</div>  
            <div className='label padding'>PADDING</div>   
        </div>
    )
}
