
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

export default createBlockTypeButton({
  blockType: 'header-four',
  children: (
    "H4"
  ),
});
