

// used to commucate event between the different elements of Studio App:

import { guid } from 'bwax/utils';


export default class EventBus {

    constructor() {
        this.subscribers = [];
    }

    on(messageName, handler ) {

        const id = guid ();

        const subscriber = { id, messageName, handler };

        this.subscribers = [ ...this.subscribers, subscriber ];

        // clear 
        return () => {
            this.subscribers = this.subscribers.filter(s => s.id !== id);
        }
    }

    dispatch(name, data) {

        this.subscribers.forEach(s => {
            if(s.messageName === name) {
                s.handler(data);
            }
        })
    }
}

export const singleton = new EventBus();
export function getGlobalEventBus () {
    return singleton;
}
