// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");

function var_of_name(next_int, name) {
  var vf = Plate$BwaxAdmin.Str.starts_with("number", name) ? Lang_typing_base$BwaxAdmin.number : (
      Plate$BwaxAdmin.Str.starts_with("appendable", name) ? Lang_typing_base$BwaxAdmin.appendable : (
          Plate$BwaxAdmin.Str.starts_with("comparable", name) || Plate$BwaxAdmin.Str.starts_with("compappend", name) ? Lang_typing_base$BwaxAdmin.comparable : Lang_typing_base$BwaxAdmin.any
        )
    );
  return Curry._1(vf, Curry._1(next_int, /* () */0));
}

function ensure_no_duplicate(i, binds) {
  Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var k = param[0];
          if (Plate$BwaxAdmin.List.contains(k, acc)) {
            return Lang_typing_base$BwaxAdmin.fail_typing(i, "Field name `" + (k + "` is used more than once."));
          } else {
            return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      k,
                      acc
                    ]);
          }
        }), /* [] */0, binds);
  return /* () */0;
}

function type_of(e) {
  return e[1][1];
}

function unique_vars(t) {
  return Plate$BwaxAdmin.List.unique(Plate$BwaxAdmin.List.filter((function (v) {
                      var tmp = v[/* var_type */0];
                      if (typeof tmp === "number") {
                        return true;
                      } else {
                        switch (tmp.tag | 0) {
                          case /* Recordvar */0 :
                          case /* Record_readonly */1 :
                          case /* Record_readonly_of */2 :
                          case /* Record_limited */3 :
                          case /* Record_writeonly */4 :
                          case /* Record_writeonly_of */5 :
                          case /* Record_writeonly_optional */7 :
                              return false;
                          default:
                            return true;
                        }
                      }
                    }))(Lang_typing_base$BwaxAdmin.get_vars(t)));
}

function arity_of(t) {
  return Plate$BwaxAdmin.List.length(unique_vars(t));
}

function arity_of_scheme(param) {
  return Plate$BwaxAdmin.List.length(param[0]);
}

function arity_of_type_info(tf) {
  if (typeof tf === "number") {
    return 0;
  } else {
    switch (tf.tag | 0) {
      case /* Just */0 :
      case /* Alias */1 :
          return arity_of_scheme(tf[0]);
      case /* Opaque */2 :
          return Plate$BwaxAdmin.List.length(tf[0]);
      
    }
  }
}

function make_annot_term(next_int, dts, is_new_var_allowed, module_name, venv, ant) {
  var i = ant[1];
  var alt = ant[0];
  var make_term = function (param, param$1) {
    return make_annot_term(next_int, dts, is_new_var_allowed, module_name, param, param$1);
  };
  var make_list = function (venv, l) {
    return Plate$BwaxAdmin.List.foldr((function (ant, param) {
                  var match = make_term(param[0], ant);
                  return /* tuple */[
                          match[0],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              match[1],
                              param[1]
                            ])
                        ];
                }), l, /* tuple */[
                venv,
                /* [] */0
              ]);
  };
  var make_assoc_list = function (venv, l) {
    return Plate$BwaxAdmin.List.foldr((function (param, param$1) {
                  var match = make_term(param$1[0], param[1]);
                  return /* tuple */[
                          match[0],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                param[0],
                                match[1]
                              ],
                              param$1[1]
                            ])
                        ];
                }), l, /* tuple */[
                venv,
                /* [] */0
              ]);
  };
  var make_assoc_tri_list = function (venv, l) {
    return Plate$BwaxAdmin.List.foldr((function (param, param$1) {
                  var match = make_term(param$1[0], param[1]);
                  return /* tuple */[
                          match[0],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                param[0],
                                match[1],
                                param[2]
                              ],
                              param$1[1]
                            ])
                        ];
                }), l, /* tuple */[
                venv,
                /* [] */0
              ]);
  };
  var must_in_dts = function (name) {
    var match = Lang_typing_base$BwaxAdmin.ti_of_name(dts, module_name, name);
    if (match !== undefined) {
      return match;
    } else {
      throw [
            Lang_typing_base$BwaxAdmin.Type_exn,
            "I don\'t know a `" + (String(name) + "` type"),
            i,
            0
          ];
    }
  };
  if (typeof alt === "number") {
    if (alt === /* Unit_type */0) {
      var tant_001 = /* tuple */[
        i,
        Lang_typing_base$BwaxAdmin.unit
      ];
      var tant = /* tuple */[
        /* Unit_type */0,
        tant_001
      ];
      return /* tuple */[
              venv,
              tant
            ];
    } else {
      var t = Lang_typing_base$BwaxAdmin.var_of(/* Opaqued */Caml_chrome_debugger.variant("Opaqued", 8, [/* [] */0]), Curry._1(next_int, /* () */0));
      return /* tuple */[
              venv,
              /* tuple */[
                /* Opaqued_type */1,
                /* tuple */[
                  i,
                  t
                ]
              ]
            ];
    }
  } else {
    switch (alt.tag | 0) {
      case /* Var_type */0 :
          var vname = alt[0];
          var match = Plate$BwaxAdmin.List.assoc(vname, venv);
          if (match !== undefined) {
            return /* tuple */[
                    venv,
                    /* tuple */[
                      /* Var_type */Caml_chrome_debugger.variant("Var_type", 0, [vname]),
                      /* tuple */[
                        i,
                        match
                      ]
                    ]
                  ];
          } else if (is_new_var_allowed) {
            var t$1 = var_of_name(next_int, vname);
            return /* tuple */[
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          vname,
                          t$1
                        ],
                        venv
                      ]),
                    /* tuple */[
                      /* Var_type */Caml_chrome_debugger.variant("Var_type", 0, [vname]),
                      /* tuple */[
                        i,
                        t$1
                      ]
                    ]
                  ];
          } else {
            var param = "Variable `" + (String(vname) + "` is not defined in scope");
            return Lang_typing_base$BwaxAdmin.fail_typing(i, param);
          }
      case /* Constr_type */1 :
          var ants = alt[1];
          var tname = alt[0];
          var ti = must_in_dts(tname);
          var arity = arity_of_type_info(ti);
          var anum = Plate$BwaxAdmin.List.length(ants);
          if (arity !== anum) {
            var param$1 = "`" + (String(tname) + ("` needs " + (String(arity) + (" arguments, but " + (String(anum) + " is given.")))));
            return Lang_typing_base$BwaxAdmin.fail_typing(i, param$1);
          } else {
            var match$1 = make_list(venv, ants);
            var tants = match$1[1];
            var sons = Plate$BwaxAdmin.List.map(type_of, tants);
            var t$2 = Lang_typing_base$BwaxAdmin.term_of_name(dts, module_name, tname, sons);
            var tant_000 = /* Constr_type */Caml_chrome_debugger.variant("Constr_type", 1, [
                tname,
                tants
              ]);
            var tant_001$1 = /* tuple */[
              i,
              t$2
            ];
            var tant$1 = /* tuple */[
              tant_000,
              tant_001$1
            ];
            return /* tuple */[
                    match$1[0],
                    tant$1
                  ];
          }
      case /* Tuple_type */2 :
          var match$2 = make_list(venv, alt[0]);
          var tants$1 = match$2[1];
          var sons$1 = Plate$BwaxAdmin.List.map(type_of, tants$1);
          var t$3 = Lang_typing_base$BwaxAdmin.tuple(sons$1);
          var tant_000$1 = /* Tuple_type */Caml_chrome_debugger.variant("Tuple_type", 2, [tants$1]);
          var tant_001$2 = /* tuple */[
            i,
            t$3
          ];
          var tant$2 = /* tuple */[
            tant_000$1,
            tant_001$2
          ];
          return /* tuple */[
                  match$2[0],
                  tant$2
                ];
      case /* Arrow_type */3 :
          var match$3 = make_term(venv, alt[1]);
          var rt = match$3[1];
          var match$4 = make_term(match$3[0], alt[0]);
          var lt = match$4[1];
          var t$4 = Lang_typing_base$BwaxAdmin.arrow(type_of(lt), type_of(rt));
          var tant_000$2 = /* Arrow_type */Caml_chrome_debugger.variant("Arrow_type", 3, [
              lt,
              rt
            ]);
          var tant_001$3 = /* tuple */[
            i,
            t$4
          ];
          var tant$3 = /* tuple */[
            tant_000$2,
            tant_001$3
          ];
          return /* tuple */[
                  match$4[0],
                  tant$3
                ];
      case /* Record_type */4 :
          var binds = alt[0];
          ensure_no_duplicate(i, Plate$BwaxAdmin.List.map((function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    }), binds));
          var match$5 = make_assoc_tri_list(venv, binds);
          var assocs = match$5[1];
          var bts = Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          type_of(param[1]),
                          param[2]
                        ];
                }), assocs);
          var t$5 = Lang_typing_base$BwaxAdmin.record(bts);
          var tant_000$3 = /* Record_type */Caml_chrome_debugger.variant("Record_type", 4, [assocs]);
          var tant_001$4 = /* tuple */[
            i,
            t$5
          ];
          var tant$4 = /* tuple */[
            tant_000$3,
            tant_001$4
          ];
          return /* tuple */[
                  match$5[0],
                  tant$4
                ];
      case /* Record_ext_type */5 :
          var binds$1 = alt[0];
          ensure_no_duplicate(i, binds$1);
          var match$6 = make_assoc_list(venv, binds$1);
          var assocs$1 = match$6[1];
          var bts$1 = Plate$BwaxAdmin.List.assoc_map(type_of, assocs$1);
          var t$6 = Lang_typing_base$BwaxAdmin.recordvar(Curry._1(next_int, /* () */0), bts$1);
          var tant_000$4 = /* Record_ext_type */Caml_chrome_debugger.variant("Record_ext_type", 5, [assocs$1]);
          var tant_001$5 = /* tuple */[
            i,
            t$6
          ];
          var tant$5 = /* tuple */[
            tant_000$4,
            tant_001$5
          ];
          return /* tuple */[
                  match$6[0],
                  tant$5
                ];
      case /* Ro_record_type */6 :
          var ants$1 = alt[1];
          var name = alt[0];
          var ti$1 = must_in_dts(name);
          var arity$1 = arity_of_type_info(ti$1);
          var anum$1 = Plate$BwaxAdmin.List.length(ants$1);
          if (arity$1 !== anum$1) {
            var param$2 = "`" + (String(name) + ("` needs " + (String(arity$1) + (" arguments, but " + (String(anum$1) + " is given.")))));
            return Lang_typing_base$BwaxAdmin.fail_typing(i, param$2);
          } else {
            var match$7 = make_list(venv, ants$1);
            var tants$2 = match$7[1];
            Plate$BwaxAdmin.List.map(type_of, tants$2);
            var tname$1 = Lang_typing_base$BwaxAdmin.term_name(dts, module_name, name);
            var var_type = /* Record_readonly */Caml_chrome_debugger.variant("Record_readonly", 1, [
                tname$1,
                /* [] */0
              ]);
            var idx = Curry._1(next_int, /* () */0);
            var t$7 = Lang_typing_base$BwaxAdmin.var_of(var_type, idx);
            var tant_000$5 = /* Ro_record_type */Caml_chrome_debugger.variant("Ro_record_type", 6, [
                tname$1,
                tants$2
              ]);
            var tant_001$6 = /* tuple */[
              i,
              t$7
            ];
            var tant$6 = /* tuple */[
              tant_000$5,
              tant_001$6
            ];
            return /* tuple */[
                    match$7[0],
                    tant$6
                  ];
          }
      case /* Ro_record_of */7 :
          var binds$2 = alt[0];
          ensure_no_duplicate(i, Plate$BwaxAdmin.List.map((function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    }), binds$2));
          var match$8 = make_assoc_tri_list(venv, binds$2);
          var assocs$2 = match$8[1];
          var bts$2 = Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          type_of(param[1]),
                          param[2]
                        ];
                }), assocs$2);
          var var_type$1 = /* Record_readonly_of */Caml_chrome_debugger.variant("Record_readonly_of", 2, [
              bts$2,
              /* [] */0
            ]);
          var idx$1 = Curry._1(next_int, /* () */0);
          var t$8 = Lang_typing_base$BwaxAdmin.var_of(var_type$1, idx$1);
          var tant_000$6 = /* Ro_record_of */Caml_chrome_debugger.variant("Ro_record_of", 7, [assocs$2]);
          var tant_001$7 = /* tuple */[
            i,
            t$8
          ];
          var tant$7 = /* tuple */[
            tant_000$6,
            tant_001$7
          ];
          return /* tuple */[
                  match$8[0],
                  tant$7
                ];
      case /* Wo_record_type */8 :
          var ants$2 = alt[1];
          var name$1 = alt[0];
          var ti$2 = must_in_dts(name$1);
          var arity$2 = arity_of_type_info(ti$2);
          var anum$2 = Plate$BwaxAdmin.List.length(ants$2);
          if (arity$2 !== anum$2) {
            var param$3 = "`" + (String(name$1) + ("` needs " + (String(arity$2) + (" arguments, but " + (String(anum$2) + " is given.")))));
            return Lang_typing_base$BwaxAdmin.fail_typing(i, param$3);
          } else {
            var match$9 = make_list(venv, ants$2);
            var tants$3 = match$9[1];
            var sons$2 = Plate$BwaxAdmin.List.map(type_of, tants$3);
            var tname$2 = Lang_typing_base$BwaxAdmin.term_name(dts, module_name, name$1);
            var var_type$2 = /* Record_writeonly */Caml_chrome_debugger.variant("Record_writeonly", 4, [
                tname$2,
                sons$2
              ]);
            var idx$2 = Curry._1(next_int, /* () */0);
            var t$9 = Lang_typing_base$BwaxAdmin.var_of(var_type$2, idx$2);
            var tant_000$7 = /* Wo_record_type */Caml_chrome_debugger.variant("Wo_record_type", 8, [
                tname$2,
                tants$3
              ]);
            var tant_001$8 = /* tuple */[
              i,
              t$9
            ];
            var tant$8 = /* tuple */[
              tant_000$7,
              tant_001$8
            ];
            return /* tuple */[
                    match$9[0],
                    tant$8
                  ];
          }
      case /* Wo_record_of */9 :
          var binds$3 = alt[0];
          ensure_no_duplicate(i, Plate$BwaxAdmin.List.map((function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    }), binds$3));
          var match$10 = make_assoc_tri_list(venv, binds$3);
          var assocs$3 = match$10[1];
          var bts$3 = Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          type_of(param[1]),
                          param[2]
                        ];
                }), assocs$3);
          var var_type$3 = /* Record_writeonly_of */Caml_chrome_debugger.variant("Record_writeonly_of", 5, [bts$3]);
          var idx$3 = Curry._1(next_int, /* () */0);
          var t$10 = Lang_typing_base$BwaxAdmin.var_of(var_type$3, idx$3);
          var tant_000$8 = /* Wo_record_of */Caml_chrome_debugger.variant("Wo_record_of", 9, [assocs$3]);
          var tant_001$9 = /* tuple */[
            i,
            t$10
          ];
          var tant$9 = /* tuple */[
            tant_000$8,
            tant_001$9
          ];
          return /* tuple */[
                  match$10[0],
                  tant$9
                ];
      case /* Ro_var_type */10 :
          var vname$1 = alt[0];
          var match$11 = Plate$BwaxAdmin.List.assoc(vname$1, venv);
          var match$12;
          if (match$11 !== undefined) {
            match$12 = /* tuple */[
              venv,
              match$11
            ];
          } else if (is_new_var_allowed) {
            var t$11 = var_of_name(next_int, vname$1);
            match$12 = /* tuple */[
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    vname$1,
                    t$11
                  ],
                  venv
                ]),
              t$11
            ];
          } else {
            var param$4 = "Variable `" + (String(vname$1) + "` is not defined in scope");
            match$12 = Lang_typing_base$BwaxAdmin.fail_typing(i, param$4);
          }
          var t$12 = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                  "var_type",
                  "n",
                  "t"
                ], [
                  Caml_chrome_debugger.variant("Any_record_readonly", 6, [
                      match$12[1],
                      /* [] */0
                    ]),
                  Curry._1(next_int, /* () */0),
                  1
                ])]);
          var tant_000$9 = /* Ro_var_type */Caml_chrome_debugger.variant("Ro_var_type", 10, [vname$1]);
          var tant_001$10 = /* tuple */[
            i,
            t$12
          ];
          var tant$10 = /* tuple */[
            tant_000$9,
            tant_001$10
          ];
          return /* tuple */[
                  match$12[0],
                  tant$10
                ];
      case /* Wo_optional_record_type */11 :
          var ants$3 = alt[1];
          var name$2 = alt[0];
          var ti$3 = must_in_dts(name$2);
          var arity$3 = arity_of_type_info(ti$3);
          var anum$3 = Plate$BwaxAdmin.List.length(ants$3);
          if (arity$3 !== anum$3) {
            var param$5 = "`" + (String(name$2) + ("` needs " + (String(arity$3) + (" arguments, but " + (String(anum$3) + " is given.")))));
            return Lang_typing_base$BwaxAdmin.fail_typing(i, param$5);
          } else {
            var match$13 = make_list(venv, ants$3);
            var tants$4 = match$13[1];
            var sons$3 = Plate$BwaxAdmin.List.map(type_of, tants$4);
            var tname$3 = Lang_typing_base$BwaxAdmin.term_name(dts, module_name, name$2);
            var var_type$4 = /* Record_writeonly_optional */Caml_chrome_debugger.variant("Record_writeonly_optional", 7, [
                tname$3,
                sons$3
              ]);
            var idx$4 = Curry._1(next_int, /* () */0);
            var t$13 = Lang_typing_base$BwaxAdmin.var_of(var_type$4, idx$4);
            var tant_000$10 = /* Wo_optional_record_type */Caml_chrome_debugger.variant("Wo_optional_record_type", 11, [
                tname$3,
                tants$4
              ]);
            var tant_001$11 = /* tuple */[
              i,
              t$13
            ];
            var tant$11 = /* tuple */[
              tant_000$10,
              tant_001$11
            ];
            return /* tuple */[
                    match$13[0],
                    tant$11
                  ];
          }
      
    }
  }
}

function make_term_if_annotated(next_int, dts, is_new_var_allowed, module_name, venv, mant) {
  if (mant !== undefined) {
    var match = make_annot_term(next_int, dts, is_new_var_allowed, module_name, venv, mant);
    return /* tuple */[
            match[0],
            type_of(match[1])
          ];
  } else {
    return /* tuple */[
            venv,
            Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0))
          ];
  }
}

exports.var_of_name = var_of_name;
exports.ensure_no_duplicate = ensure_no_duplicate;
exports.type_of = type_of;
exports.unique_vars = unique_vars;
exports.arity_of = arity_of;
exports.arity_of_scheme = arity_of_scheme;
exports.arity_of_type_info = arity_of_type_info;
exports.make_annot_term = make_annot_term;
exports.make_term_if_annotated = make_term_if_annotated;
/* Plate-BwaxAdmin Not a pure module */
