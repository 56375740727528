
// The adhoc port dispatcher component and registry

import React from "react";

import PortTest from './ports/PortTest';
import WxWorkMessage from './ports/WxWorkMessage';
import HideContentSection from './ports/HideContentSection';
import ContentWithScrollFadePages from "./ports/ContentWithScrollFadePages";
import RongChuan_Navigation from './ports/RongChuan_Navigation'
import BackgroundVideo from './ports/BackgroundVideo'
import DynamicLogo from './ports/DynamicLogo'
import SwiperWithBreakpoints from "./ports/SwiperWithBreakpoints";
import NavigationBar001 from './ports/NavigationBar001'

import Layout_inbot from "./ports/inbot/Layout_inbot";
import Layout_menuAndMain from "./ports/inbot/Layout_menuAndMain";

import ChatView from "./ports/inbot/ChatView";
import NoteEdit  from './ports/inbot/NoteEdit';
import NoteManagement from './ports/inbot/NoteManagement';

import DocumentList from "./ports/inbot/DocumentList";
import BookmarkList from "./ports/inbot/BookmarkList";

import TaskManagement from './ports/inbot/TaskManagement';
import DocumentManagement from "./ports/inbot/DocumentManagement";

import BookmarkManagement from "./ports/inbot/BookmarkManagement";

import DocumentChatTest from './ports/DocumentChatTest';

import SignUp_inbot from "./ports/inbot/SignUp_inbot";

import SubscriptionPayment_inbot from './ports/inbot/SubscriptionPayment_inbot';

import ReferralCodePage from "./ports/inbot/ReferralCodePage";
import PersonaSharingChatView from "./ports/inbot/PersonaSharingChatView";

import HomePage_inbot from "./ports/inbot/HomePage_inbot";
import Pricing_inbot from "./ports/inbot/Pricing_inbot";

import Layout001 from "./ports/Layout001";

import LogOn from "./ports/LogOn";

import Stat_inbot from './ports/inbot/stat/Stat_inbot';


// translate related:
import Layout_translate from "./ports/translate/Layout_translate";

import Receipt_translate from "./ports/translate/Receipt_translate";

import Translate from "./ports/translate/Translate";
import TranslationList from "./ports/translate/TranslationList";
import Pay_translate from "./ports/translate/Pay_translate";
import TranslationShare from "./ports/translate/TranslationShare";

import AdminTranslationList from "./ports/translate/AdminTranslationList";
import AdminTranslationStat from "./ports/translate/AdminTranslationStat";
import AdminTranslationRedeemCodes from "./ports/translate/AdminTranslationRedeemCodes";

import Translate_sample from "./ports/translate/Translate_sample";

import Translate_glossary from './ports/translate/Translate_glossary';

/// cb ports:
import VipTabs_cb from "./ports/cb/VipTabs_cb";
import PipelineProjectList_cb from "./ports/cb/PipelineProjectList_cb";
import PipelineProjectDetail_cb from "./ports/cb/PipelineProjectDetail_cb";


const registry = {
    PortTest,
    // others:
    WxWorkMessage,
    HideContentSection,
    ContentWithScrollFadePages,
    RongChuan_Navigation,
    BackgroundVideo,
    DynamicLogo,
    SwiperWithBreakpoints,
    NavigationBar001,


    Layout001,

    LogOn,

    // cb related:
    VipTabs_cb,
    PipelineProjectList_cb,
    PipelineProjectDetail_cb,

    // inbot related:

    SignUp_inbot,

    Layout_inbot,
    Layout_menuAndMain,
    
    ChatView, NoteEdit, NoteManagement, DocumentList, BookmarkList,
    SubscriptionPayment_inbot,

    TaskManagement,

    DocumentManagement,
    BookmarkManagement,
    
    ReferralCodePage,
    PersonaSharingChatView,

    HomePage_inbot,
    Pricing_inbot,

    Stat_inbot,

    DocumentChatTest,

    // translate
    Layout_translate,
    Translate,
    TranslationList,
    
    Pay_translate,
    Receipt_translate,

    Translate_sample,

    Translate_glossary,

    AdminTranslationList,
    AdminTranslationStat,

    AdminTranslationRedeemCodes,



    TranslationShare,

}

export default function AdhocPort (props) {

    const { name, data, slots, events, facade, pageID, viewEnv } = props;

    const Comp = registry[name];
    if(!Comp) {
        return (
            <div style={{ padding: "1rem"}}>I can't find ported element named `{name}`</div>
        )
    } else {
        return <Comp {...{data, slots, events, facade, pageID, viewEnv }} />

    }

}

