// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Js_math = require("bs-platform/lib/js/js_math.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Utils = require("bwax/utils");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_undefined = require("bs-platform/lib/js/js_undefined.js");
var Json$BwaxAdmin = require("../utils/json.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../lang/mod/runtime_common.bs.js");
var Lang_value_dict$BwaxAdmin = require("../lang/lang_value_dict.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");

function runtime_to_query_context(rc) {
  return /* record */Caml_chrome_debugger.record([
            "entity_dict",
            "data_type_dict",
            "query_runner",
            "cache"
          ], [
            rc[/* entity_dict */0],
            rc[/* data_type_dict */1],
            rc[/* query_runner */4],
            rc[/* query_cache */5]
          ]);
}

function selection_text_for_entity(entity_dict, data_type_dict, name_or_key, selection) {
  var entity = Base_query_types$BwaxAdmin.get_entity(entity_dict, name_or_key);
  return selection_text_for_fields(entity_dict, data_type_dict, true, entity[/* name */0], Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]), selection);
}

function selection_text_for_data_type(entity_dict, data_type_dict, dtname, selection, include_all) {
  var data_type = Base_query_types$BwaxAdmin.get_data_type(data_type_dict, dtname);
  var actual_sel;
  if (include_all) {
    var match = data_type[/* trait */2];
    if (typeof match === "number" || match.tag !== /* C_record */1) {
      actual_sel = selection;
    } else {
      var sels = Plate$BwaxAdmin.List.map((function (f) {
              return /* tuple */[
                      Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]),
                      /* No_selection */0
                    ];
            }), Pervasives.$at(match[0], match[1]));
      actual_sel = /* Selections */Caml_chrome_debugger.variant("Selections", 0, [sels]);
    }
  } else {
    actual_sel = selection;
  }
  var match$1 = data_type[/* trait */2];
  var all_fields;
  all_fields = typeof match$1 === "number" || match$1.tag !== /* C_record */1 ? /* [] */0 : Pervasives.$at(match$1[0], match$1[1]);
  return selection_text_for_fields(entity_dict, data_type_dict, false, data_type[/* name */0], all_fields, actual_sel);
}

function selection_text_for_field(entity_dict, data_type_dict, field, selection) {
  var fkey = field[/* key */1];
  var match = field[/* dtype */2];
  if (typeof match === "number") {
    switch (match) {
      case /* Atom_image */7 :
          return fkey + (" " + selection_text_for_data_type(entity_dict, data_type_dict, "图片", /* No_selection */0, true));
      case /* Atom_file */8 :
          return fkey + (" " + selection_text_for_data_type(entity_dict, data_type_dict, "文件", /* No_selection */0, true));
      default:
        return fkey;
    }
  } else {
    switch (match.tag | 0) {
      case /* Entity_link */1 :
          return fkey + (" " + selection_text_for_entity(entity_dict, data_type_dict, match[0], selection));
      case /* Entity_link_opaque */2 :
          return fkey + " { id } ";
      case /* Custom_type */3 :
          var dtname = match[0];
          var selection_text;
          selection_text = typeof selection === "number" || !(selection.tag || selection[0]) ? selection_text_for_data_type(entity_dict, data_type_dict, dtname, /* No_selection */0, true) : selection_text_for_data_type(entity_dict, data_type_dict, dtname, selection, false);
          return fkey + (" " + selection_text);
      default:
        return fkey;
    }
  }
}

function selection_text_for_fields(entity_dict, data_type_dict, with_id, parent_name, fields, selection) {
  var build = function (param) {
    var name = param[0];
    var find_field_by_name = function (f) {
      return Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]) === name;
    };
    var match = Plate$BwaxAdmin.List.find(find_field_by_name, fields);
    var field;
    if (match !== undefined) {
      field = match;
    } else {
      throw [
            Base_query_types$BwaxAdmin.Builder_exn,
            "I can\'t find field `" + (String(name) + ("` in " + (String(parent_name) + "")))
          ];
    }
    return selection_text_for_field(entity_dict, data_type_dict, field, param[1]);
  };
  var inner;
  inner = typeof selection === "number" ? "" : (
      selection.tag ? selection[0] : Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map(build, Plate$BwaxAdmin.List.filter((function (param) {
                          return param[0] !== "id";
                        }))(selection[0])))
    );
  return "{ " + ((
            with_id ? "id " : ""
          ) + (inner + " }"));
}

function build_selection_text(entity_dict, data_type_dict, entity, selection) {
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  return selection_text_for_fields(entity_dict, data_type_dict, true, entity[/* name */0], all_fields, selection);
}

function print_selection(sel) {
  if (typeof sel === "number") {
    return "";
  } else if (sel.tag) {
    return sel[0];
  } else {
    var inner = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (param) {
                return param[0] + (" " + print_selection(param[1]));
              }), sel[0]));
    return "{ " + (inner + "}");
  }
}

function process_interface_input(entity_dict, data_type_dict, ivt, json) {
  var $$process = function (param, param$1) {
    return process_interface_input(entity_dict, data_type_dict, param, param$1);
  };
  if (typeof ivt === "number") {
    return json;
  } else {
    switch (ivt.tag | 0) {
      case /* Intf_list */0 :
          var evt = ivt[0];
          var match = Js_json.decodeArray(json);
          if (match !== undefined) {
            return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (param) {
                              return $$process(evt, param);
                            }), Curry._1(Plate$BwaxAdmin.List.from_array, match)));
          } else {
            return json;
          }
      case /* Intf_maybe */1 :
          var evt$1 = ivt[0];
          var match$1 = Js_json.classify(json);
          if (typeof match$1 === "number" && match$1 >= 2) {
            return null;
          } else {
            return $$process(evt$1, json);
          }
      default:
        return json;
    }
  }
}

function drop_none(l) {
  return Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l);
}

function aggregate_var_def_texts(entity_key, qname) {
  var criteria_def = function (param) {
    return "$" + (qname + ("Condition: [[Aggregate" + (entity_key + "ConditionInput]]")));
  };
  var group_def = function (param) {
    return "$" + (qname + ("Group: [Aggregate" + (entity_key + "GroupInput]")));
  };
  var aggregate_def = function (param) {
    return "$" + (qname + ("Aggregate: [Aggregate" + (entity_key + "AggregateInput]!")));
  };
  var having_def = function (param) {
    return "$" + (qname + ("Having: [[Aggregate" + (entity_key + "HavingInput]]")));
  };
  var sort_def = function (param) {
    return "$" + (qname + ("Sort: [Aggregate" + (entity_key + "SortInput]")));
  };
  var offset_def = function (param) {
    return "$" + (qname + "Offset: Float");
  };
  var limit_def = function (param) {
    return "$" + (qname + "Limit: Float");
  };
  return (function (param) {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map(criteria_def, param[0]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map(group_def, param[1]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Plate$BwaxAdmin.$$Option.map(aggregate_def, param[2]),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Plate$BwaxAdmin.$$Option.map(having_def, param[3]),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Plate$BwaxAdmin.$$Option.map(sort_def, param[4]),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Plate$BwaxAdmin.$$Option.map(offset_def, param[5]),
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        Plate$BwaxAdmin.$$Option.map(limit_def, param[6]),
                                        /* [] */0
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ]);
    });
}

function query_var_def_text(qname, query) {
  var criteria_def = function (entity, param) {
    return "$" + (qname + ("Condition: [[List" + (entity[/* key */1] + "ConditionInput]]")));
  };
  var sort_def = function (entity, param) {
    return "$" + (qname + ("Sort: [List" + (entity[/* key */1] + "SortInput]")));
  };
  var search_def = function (entity, param) {
    return "$" + (qname + ("Search: List" + (entity[/* key */1] + "SearchInput")));
  };
  var offset_def = function (param) {
    return "$" + (qname + "Offset: Float");
  };
  var of_pagination = function (mp) {
    return Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (p) {
                      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                "$" + (qname + "First: Float"),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Plate$BwaxAdmin.$$Option.map(offset_def, p[/* offset */1]),
                                    /* [] */0
                                  ])
                              ]);
                    }), mp));
  };
  var l;
  var exit = 0;
  switch (query.tag | 0) {
    case /* Q_find_one */0 :
        var match = query[1];
        var e = query[0];
        l = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map((function (param) {
                        return criteria_def(e, param);
                      }), match[0]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map((function (param) {
                            return sort_def(e, param);
                          }), match[1]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Plate$BwaxAdmin.$$Option.map((function (param) {
                                return search_def(e, param);
                              }), match[2]),
                        /* [] */0
                      ])
                  ])
              ]), of_pagination(/* record */Caml_chrome_debugger.record([
                    "page_size",
                    "offset"
                  ], [
                    1,
                    undefined
                  ])));
        break;
    case /* Q_list */1 :
    case /* Q_listAll */2 :
        exit = 1;
        break;
    case /* Q_count */3 :
        var match$1 = query[1];
        var e$1 = query[0];
        l = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            Plate$BwaxAdmin.$$Option.map((function (param) {
                    return criteria_def(e$1, param);
                  }), match$1[0]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map((function (param) {
                        return search_def(e$1, param);
                      }), match$1[1]),
                /* [] */0
              ])
          ]);
        break;
    case /* Q_aggregate */4 :
        var match$2 = query[1];
        l = aggregate_var_def_texts(query[0][/* key */1], qname)(/* tuple */[
              match$2[0],
              match$2[1],
              match$2[2],
              match$2[3],
              match$2[4],
              match$2[5],
              match$2[6]
            ]);
        break;
    case /* Q_get_current_user */5 :
        l = /* [] */0;
        break;
    case /* Q_custom_query */6 :
        var $$interface = query[0];
        var to_var_def_text = function (index, param) {
          var vt = param[0];
          var general_input_type = function (param) {
            return Plate$BwaxAdmin.Str.cap($$interface[/* key */1]) + ("In" + (String(index) + "Input"));
          };
          var to_type_text = function (_vt) {
            while(true) {
              var vt = _vt;
              if (typeof vt === "number") {
                switch (vt) {
                  case /* Intf_bool */0 :
                      return "Boolean";
                  case /* Intf_int */1 :
                  case /* Intf_float */2 :
                      return "Float";
                  case /* Intf_json */4 :
                      return "Json";
                  case /* Intf_string */3 :
                  case /* Intf_richtext */5 :
                      return "String";
                  case /* Intf_datetime */6 :
                      return "Date";
                  default:
                    return "Json";
                }
              } else {
                switch (vt.tag | 0) {
                  case /* Intf_list */0 :
                      return "[" + (to_type_text(vt[0]) + "]");
                  case /* Intf_maybe */1 :
                      _vt = vt[0];
                      continue ;
                  case /* Intf_record */2 :
                      return "Json";
                  case /* Intf_criteria */7 :
                      return "[[" + (general_input_type(/* () */0) + "]]");
                  case /* Intf_sorting */8 :
                      return "[" + (general_input_type(/* () */0) + "]");
                  default:
                    return "Json";
                }
              }
            };
          };
          var type_text = to_type_text(vt);
          var wrapped;
          wrapped = typeof vt === "number" ? type_text + "!" : (
              vt.tag === /* Intf_maybe */1 ? type_text : type_text + "!"
            );
          return "$" + (qname + ("in" + (String(index) + (": " + wrapped))));
        };
        var id_var_def = query[1] !== undefined ? "$" + (qname + ("id : " + ($$interface[/* entity_key */6] + "Id!"))) : undefined;
        l = Pervasives.$at(Plate$BwaxAdmin.List.indexed_map(to_var_def_text, query[2]), Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    id_var_def,
                    /* [] */0
                  ]), /* [] */0));
        break;
    
  }
  if (exit === 1) {
    var match$3 = query[1];
    var e$2 = query[0];
    l = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            Plate$BwaxAdmin.$$Option.map((function (param) {
                    return criteria_def(e$2, param);
                  }), match$3[0]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map((function (param) {
                        return sort_def(e$2, param);
                      }), match$3[1]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map((function (param) {
                            return search_def(e$2, param);
                          }), match$3[2]),
                    /* [] */0
                  ])
              ])
          ]), of_pagination(match$3[3]));
  }
  return Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l);
}

function aggregate_var_texts(qname, param) {
  var criteria_var = function (param) {
    return "condition: $" + (qname + "Condition");
  };
  var group_var = function (param) {
    return "group: $" + (qname + "Group");
  };
  var aggregate_var = function (param) {
    return "aggregate: $" + (qname + "Aggregate");
  };
  var having_var = function (param) {
    return "having: $" + (qname + "Having");
  };
  var sort_var = function (param) {
    return "sort: $" + (qname + "Sort");
  };
  var offset_var = function (param) {
    return "offset: $" + (qname + "Offset");
  };
  var limit_var = function (param) {
    return "limit: $" + (qname + "Limit");
  };
  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
            Plate$BwaxAdmin.$$Option.map(criteria_var, param[0]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map(group_var, param[1]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map(aggregate_var, param[2]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Plate$BwaxAdmin.$$Option.map(having_var, param[3]),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Plate$BwaxAdmin.$$Option.map(sort_var, param[4]),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Plate$BwaxAdmin.$$Option.map(offset_var, param[5]),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Plate$BwaxAdmin.$$Option.map(limit_var, param[6]),
                                    /* [] */0
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ]);
}

function query_var_text(additional_query_params, qname, query) {
  var criteria_var = function (param) {
    return "condition: $" + (qname + "Condition");
  };
  var sort_var = function (param) {
    return "sort: $" + (qname + "Sort");
  };
  var search_var = function (param) {
    return "search: $" + (qname + "Search");
  };
  var of_pagination = function (mp) {
    if (mp !== undefined) {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "first: $" + (qname + "First"),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map((function (param) {
                            return "offset: $" + (qname + "Offset");
                          }), mp[/* offset */1]),
                    /* [] */0
                  ])
              ]);
    } else {
      return /* [] */0;
    }
  };
  var var_texts;
  var exit = 0;
  switch (query.tag | 0) {
    case /* Q_find_one */0 :
        var match = query[1];
        var_texts = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map(criteria_var, match[0]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map(sort_var, match[1]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Plate$BwaxAdmin.$$Option.map(search_var, match[2]),
                        /* [] */0
                      ])
                  ])
              ]), of_pagination(/* record */Caml_chrome_debugger.record([
                    "page_size",
                    "offset"
                  ], [
                    1,
                    undefined
                  ])));
        break;
    case /* Q_list */1 :
    case /* Q_listAll */2 :
        exit = 1;
        break;
    case /* Q_count */3 :
        var match$1 = query[1];
        var_texts = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            Plate$BwaxAdmin.$$Option.map(criteria_var, match$1[0]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map(search_var, match$1[1]),
                /* [] */0
              ])
          ]);
        break;
    case /* Q_aggregate */4 :
        var match$2 = query[1];
        var_texts = aggregate_var_texts(qname, /* tuple */[
              match$2[0],
              match$2[1],
              match$2[2],
              match$2[3],
              match$2[4],
              match$2[5],
              match$2[6]
            ]);
        break;
    case /* Q_get_current_user */5 :
        var_texts = /* [] */0;
        break;
    case /* Q_custom_query */6 :
        var to_var_text = function (index, param) {
          var idx = "in" + String(index);
          return idx + (": $" + (qname + idx));
        };
        var id_var_text = Plate$BwaxAdmin.$$Option.map((function (param) {
                return "id: $" + (qname + "id");
              }), query[1]);
        var_texts = Pervasives.$at(Plate$BwaxAdmin.List.indexed_map(to_var_text, query[2]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                id_var_text,
                /* [] */0
              ]));
        break;
    
  }
  if (exit === 1) {
    var match$3 = query[1];
    var_texts = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            Plate$BwaxAdmin.$$Option.map(criteria_var, match$3[0]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Plate$BwaxAdmin.$$Option.map(sort_var, match$3[1]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Plate$BwaxAdmin.$$Option.map(search_var, match$3[2]),
                    /* [] */0
                  ])
              ])
          ]), of_pagination(match$3[3]));
  }
  return Plate$BwaxAdmin.Str.join(", ", Pervasives.$at(Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, var_texts), Plate$BwaxAdmin.List.map((function (param) {
                        return param[0] + (": \"" + (param[1] + "\""));
                      }), additional_query_params)));
}

function build_selection_text_from_outputs(entity_dict, data_type_dict, outputs) {
  var build_output_selection = function (index, param) {
    var maybe_sels = param[1];
    var to_selection_text = function (_vt) {
      while(true) {
        var vt = _vt;
        if (typeof vt === "number") {
          switch (vt) {
            case /* Intf_image */7 :
                return selection_text_for_data_type(entity_dict, data_type_dict, "图片", /* No_selection */0, true);
            case /* Intf_file */8 :
                return selection_text_for_data_type(entity_dict, data_type_dict, "文件", /* No_selection */0, true);
            default:
              return "";
          }
        } else {
          switch (vt.tag | 0) {
            case /* Intf_list */0 :
            case /* Intf_maybe */1 :
                _vt = vt[0];
                continue ;
            case /* Intf_paged */5 :
                return "{ data " + (to_selection_text(vt[0]) + " offset pageSize count }");
            case /* Intf_entity */6 :
                if (maybe_sels !== undefined) {
                  return selection_text_for_entity(entity_dict, data_type_dict, vt[0], maybe_sels[1]);
                } else {
                  return "{ id }";
                }
            default:
              return "";
          }
        }
      };
    };
    var selection_text = to_selection_text(param[0]);
    return "out" + (String(index) + (
              Plate$BwaxAdmin.Str.length(selection_text) > 0 ? " " + selection_text : ""
            ));
  };
  return Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.indexed_map(build_output_selection, outputs));
}

function build_query_text(entity_dict, data_type_dict, additional_query_params, named_queries) {
  var single_query_text = function (param) {
    var query = param[1];
    var qname = param[0];
    var br = function (text) {
      if (Plate$BwaxAdmin.Str.length(text) > 0) {
        return "( " + (text + " )");
      } else {
        return text;
      }
    };
    var vars = br(query_var_text(additional_query_params, qname, query));
    var to_selection_text = function (entity) {
      return (function (param) {
          return build_selection_text(entity_dict, data_type_dict, entity, param);
        });
    };
    var wrap_up = function (query_name, vars, selection) {
      return qname + (":" + (query_name + ((
                    vars === "" ? "" : " " + vars
                  ) + (" { " + (selection + " }")))));
    };
    switch (query.tag | 0) {
      case /* Q_find_one */0 :
      case /* Q_list */1 :
          break;
      case /* Q_listAll */2 :
          var entity = query[0];
          var partial_arg = "list" + entity[/* key */1];
          return Plate$BwaxAdmin.$less$pipe((function (param) {
                        return wrap_up(partial_arg, vars, param);
                      }), "edges { node " + (to_selection_text(entity)(query[2]) + " } count"));
      case /* Q_count */3 :
          return wrap_up("list" + query[0][/* key */1], vars, "count");
      case /* Q_aggregate */4 :
          return wrap_up("aggregate" + query[0][/* key */1], vars, "result");
      case /* Q_get_current_user */5 :
          var entity$1 = Base_query_types$BwaxAdmin.get_entity(entity_dict, "用户");
          return qname + (": me { authUser " + (to_selection_text(entity$1)(query[0]) + " }"));
      case /* Q_custom_query */6 :
          return wrap_up(query[0][/* key */1], vars, build_selection_text_from_outputs(entity_dict, data_type_dict, query[3]));
      
    }
    var entity$2 = query[0];
    var partial_arg$1 = "list" + entity$2[/* key */1];
    return Plate$BwaxAdmin.$less$pipe((function (param) {
                  return wrap_up(partial_arg$1, vars, param);
                }), "edges { node " + (to_selection_text(entity$2)(query[2]) + " } "));
  };
  var def_list = Plate$BwaxAdmin.List.flat_map((function (param) {
          return query_var_def_text(param[0], param[1]);
        }), named_queries);
  var all_vars_def_text = Plate$BwaxAdmin.List.length(def_list) > 0 ? " ( " + (Plate$BwaxAdmin.Str.join(", ", def_list) + " )") : "";
  var all_query_text = Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map(single_query_text, named_queries));
  return "query " + (all_vars_def_text + (" { " + (all_query_text + "} ")));
}

function build_variables(named_queries) {
  var build_query_variable = function (param) {
    var query_option = param[1];
    var name = param[0];
    var criteria_cond_v = function (input_name, name, mc) {
      return Plate$BwaxAdmin.$$Option.map((function (v) {
                    return /* tuple */[
                            name + input_name,
                            v
                          ];
                  }), Base_query_types$BwaxAdmin.pack_criteria(mc));
    };
    var sorting_v = function (ms) {
      var mj = Base_query_types$BwaxAdmin.pack_sorting(ms);
      return Plate$BwaxAdmin.$$Option.map((function (v) {
                    return /* tuple */[
                            name + "Sort",
                            v
                          ];
                  }), mj);
    };
    var search_v = function (msc) {
      var mj = Base_query_types$BwaxAdmin.pack_search(msc);
      return Plate$BwaxAdmin.$$Option.map((function (v) {
                    return /* tuple */[
                            name + "Search",
                            v
                          ];
                  }), mj);
    };
    var pagination_v = function (mp) {
      if (mp !== undefined) {
        var p = mp;
        var m_offset_v = Plate$BwaxAdmin.$$Option.map((function (o) {
                return /* tuple */[
                        name + "Offset",
                        Json$BwaxAdmin.json_int(o)
                      ];
              }), p[/* offset */1]);
        var m_page_size = /* tuple */[
          name + "First",
          Json$BwaxAdmin.json_int(p[/* page_size */0])
        ];
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  m_page_size,
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      m_offset_v,
                      /* [] */0
                    ])
                ]);
      } else {
        return /* [] */0;
      }
    };
    var pack_agg_group_input = function (param) {
      var params = param[2];
      var func = param[1];
      var tmp;
      if (func !== undefined) {
        var fn = func;
        tmp = fn === "EXACTLY" ? undefined : /* tuple */[
            "func",
            Json$BwaxAdmin.json_str(fn)
          ];
      } else {
        tmp = undefined;
      }
      var l_000 = /* tuple */[
        "field",
        Json$BwaxAdmin.json_str(param[0])
      ];
      var l_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          tmp,
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Plate$BwaxAdmin.List.length(params) > 0 ? /* tuple */[
                  "params",
                  Json$BwaxAdmin.json_arr(params)
                ] : undefined,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Plate$BwaxAdmin.$$Option.map((function (alias_name) {
                          return /* tuple */[
                                  "as",
                                  Json$BwaxAdmin.json_str(alias_name)
                                ];
                        }), param[3]),
                  /* [] */0
                ])
            ])
        ]);
      var l = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          l_000,
          l_001
        ]);
      return Json$BwaxAdmin.json_object(Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l));
    };
    var pack_inputs = function (l) {
      return Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map(pack_agg_group_input, l));
    };
    var partial_arg = Plate$BwaxAdmin.$$Option.map;
    var aggregate_v = function (param) {
      return partial_arg((function (a) {
                    return /* tuple */[
                            name + "Aggregate",
                            pack_inputs(a)
                          ];
                  }), param);
    };
    var partial_arg$1 = Plate$BwaxAdmin.$$Option.map;
    var group_v = function (param) {
      return partial_arg$1((function (g) {
                    return /* tuple */[
                            name + "Group",
                            pack_inputs(g)
                          ];
                  }), param);
    };
    var partial_arg$2 = Plate$BwaxAdmin.$$Option.map;
    var offset_v = function (param) {
      return partial_arg$2((function (offset) {
                    return /* tuple */[
                            name + "Offset",
                            Json$BwaxAdmin.json_int(offset)
                          ];
                  }), param);
    };
    var partial_arg$3 = Plate$BwaxAdmin.$$Option.map;
    var limit_v = function (param) {
      return partial_arg$3((function (limit) {
                    return /* tuple */[
                            name + "Limit",
                            Json$BwaxAdmin.json_int(limit)
                          ];
                  }), param);
    };
    var having_criteria_v = function (mh) {
      return Plate$BwaxAdmin.$$Option.and_then((function (conditions) {
                    return criteria_cond_v("Having", name, /* C_and */Caml_chrome_debugger.variant("C_and", 0, [Plate$BwaxAdmin.List.concat(conditions)]));
                  }), mh);
    };
    switch (query_option.tag | 0) {
      case /* Q_find_one */0 :
          var match = query_option[1];
          var l_000 = /* tuple */[
            name + "First",
            Json$BwaxAdmin.json_int(1)
          ];
          var l_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              criteria_cond_v("Condition", name, match[0]),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  sorting_v(match[1]),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      search_v(match[2]),
                      /* [] */0
                    ])
                ])
            ]);
          var l = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              l_000,
              l_001
            ]);
          return Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l);
      case /* Q_list */1 :
      case /* Q_listAll */2 :
          break;
      case /* Q_count */3 :
          var match$1 = query_option[1];
          var l_000$1 = criteria_cond_v("Condition", name, match$1[0]);
          var l_001$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              search_v(match$1[1]),
              /* [] */0
            ]);
          var l$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              l_000$1,
              l_001$1
            ]);
          return Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l$1);
      case /* Q_aggregate */4 :
          var match$2 = query_option[1];
          var l_000$2 = criteria_cond_v("Condition", name, match$2[0]);
          var l_001$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Curry._1(group_v, match$2[1]),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Curry._1(aggregate_v, match$2[2]),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      having_criteria_v(match$2[3]),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          sorting_v(match$2[4]),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Curry._1(offset_v, match$2[5]),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Curry._1(limit_v, match$2[6]),
                                  /* [] */0
                                ])
                            ])
                        ])
                    ])
                ])
            ]);
          var l$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              l_000$2,
              l_001$2
            ]);
          return Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l$2);
      case /* Q_get_current_user */5 :
          return /* [] */0;
      case /* Q_custom_query */6 :
          var to_variables = function (index, param) {
            var vname = name + ("in" + String(index));
            return /* tuple */[
                    vname,
                    param[1]
                  ];
          };
          var id_vars = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (id) {
                      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  name + "id",
                                  id
                                ],
                                /* [] */0
                              ]);
                    }), query_option[1]));
          return Pervasives.$at(Plate$BwaxAdmin.List.indexed_map(to_variables, query_option[2]), id_vars);
      
    }
    var match$3 = query_option[1];
    var l_000$3 = criteria_cond_v("Condition", name, match$3[0]);
    var l_001$3 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        sorting_v(match$3[1]),
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            search_v(match$3[2]),
            pagination_v(match$3[3])
          ])
      ]);
    var l$3 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        l_000$3,
        l_001$3
      ]);
    return Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l$3);
  };
  return Plate$BwaxAdmin.List.flat_map(build_query_variable, named_queries);
}

function field_by_key_or_name_exn(all_fields, key_or_name) {
  var found = Plate$BwaxAdmin.List.find((function (f) {
          if (f[/* key */1] === key_or_name || f[/* name */0] === key_or_name) {
            return true;
          } else {
            return Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]) === key_or_name;
          }
        }), all_fields);
  if (found !== undefined) {
    return found;
  } else {
    console.log("All fields are", Curry._1(Plate$BwaxAdmin.List.to_array, all_fields));
    throw [
          Base_query_types$BwaxAdmin.Builder_exn,
          "Field not found for `" + (String(key_or_name) + "`")
        ];
  }
}

var default_transform_option = /* record */Caml_chrome_debugger.record([
    "should_process_select",
    "should_process_composite_data"
  ], [
    true,
    true
  ]);

function transform_record_value(entity_expander, entity_dict, data_type_dict, transform_option, fields, json) {
  var m_record = Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(json)));
  var partial_arg = Plate$BwaxAdmin.List.keep_map;
  var transform = function (param) {
    return partial_arg((function (param) {
                  var j = param[1];
                  var k = param[0];
                  if (k === "id") {
                    return Plate$BwaxAdmin.$$Option.map((function (v) {
                                  return /* tuple */[
                                          "id",
                                          v
                                        ];
                                }), Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Js_json.decodeString(j)));
                  } else {
                    var field = field_by_key_or_name_exn(fields, k);
                    var mv = transform_field_value(entity_expander, entity_dict, data_type_dict, transform_option, field, j);
                    return Plate$BwaxAdmin.$$Option.map((function (v) {
                                  return /* tuple */[
                                          Base_query_types$BwaxAdmin.normalize_field_name(field[/* name */0]),
                                          v
                                        ];
                                }), mv);
                  }
                }), param);
  };
  var match = Plate$BwaxAdmin.$$Option.map(transform, m_record);
  if (match !== undefined) {
    return /* tuple */[
            /* V_record */Caml_chrome_debugger.variant("V_record", 2, [match]),
            Runtime_common$BwaxAdmin.nt
          ];
  }
  
}

function transform_field_value(entity_expander, entity_dict, data_type_dict, transform_option, field, json) {
  var transform_single = function (j) {
    var match = field[/* dtype */2];
    if (typeof match === "number") {
      switch (match) {
        case /* Atom_int */0 :
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_int, Plate$BwaxAdmin.$$Option.map((function (prim) {
                              return prim | 0;
                            }), Js_json.decodeNumber(j)));
        case /* Atom_float */1 :
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_float, Js_json.decodeNumber(j));
        case /* Atom_bool */2 :
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_bool, Js_json.decodeBoolean(j));
        case /* Atom_date */4 :
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_date, Plate$BwaxAdmin.$$Option.map((function (prim) {
                              return prim.valueOf();
                            }), Plate$BwaxAdmin.$$Option.map((function (prim) {
                                  return new Date(prim);
                                }), Js_json.decodeString(j))));
        case /* Atom_json */5 :
            return Runtime_common$BwaxAdmin.pack_json(j);
        case /* Atom_string */3 :
        case /* Atom_richtext */6 :
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Js_json.decodeString(j));
        case /* Atom_image */7 :
            return Runtime_common$BwaxAdmin.pack_image(j);
        case /* Atom_file */8 :
            return Runtime_common$BwaxAdmin.pack_file(j);
        case /* Atom_color */9 :
            return Plate$BwaxAdmin.$$Option.map((function (s) {
                          return Runtime_common$BwaxAdmin.pack_tagged_string("Color", s);
                        }), Js_json.decodeString(j));
        
      }
    } else {
      switch (match.tag | 0) {
        case /* Atom_select */0 :
            var options = match[0];
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Plate$BwaxAdmin.$$Option.map((function (value) {
                              if (transform_option[/* should_process_select */0]) {
                                var found = Plate$BwaxAdmin.List.find((function (param) {
                                        return param[1] === value;
                                      }), options);
                                return Plate$BwaxAdmin.$$Option.with_default(value, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.fst, found));
                              } else {
                                return value;
                              }
                            }), Js_json.decodeString(j)));
        case /* Entity_link */1 :
            var entity = Base_query_types$BwaxAdmin.get_entity(entity_dict, match[0]);
            return Curry._4(entity_expander, (function (param, param$1, param$2) {
                          return transform_record_value(entity_expander, entity_dict, data_type_dict, param, param$1, param$2);
                        }), field, entity, j);
        case /* Entity_link_opaque */2 :
            return Plate$BwaxAdmin.$$Option.map((function (id) {
                          return Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        /* tuple */[
                                          "id",
                                          id
                                        ],
                                        /* [] */0
                                      ]));
                        }), Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Plate$BwaxAdmin.$$Option.and_then((function (d) {
                                      return Js_dict.get(d, "id");
                                    }), Js_json.decodeObject(j)))));
        case /* Custom_type */3 :
            var name_or_key = match[0];
            var data_type = Base_query_types$BwaxAdmin.get_data_type(data_type_dict, name_or_key);
            var match$1 = data_type[/* trait */2];
            var all_fields;
            all_fields = typeof match$1 === "number" || match$1.tag !== /* C_record */1 ? /* [] */0 : Pervasives.$at(match$1[0], match$1[1]);
            if (name_or_key === "DesignTypeSource") {
              return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Js_json.decodeString(j));
            } else if (transform_option[/* should_process_composite_data */1]) {
              return transform_record_value(entity_expander, entity_dict, data_type_dict, transform_option, all_fields, j);
            } else {
              return Runtime_common$BwaxAdmin.pack_json(j);
            }
        case /* Criteria */4 :
            return Runtime_common$BwaxAdmin.pack_tagged_raw("Criteria", j);
        
      }
    }
  };
  var with_multivalued = function (transform, json) {
    if (field[/* multivalued */4]) {
      var partial_arg = Plate$BwaxAdmin.List.keep_map;
      return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_list, Plate$BwaxAdmin.$$Option.map((function (param) {
                        return partial_arg(transform, param);
                      }), Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Js_json.decodeArray(json))));
    } else {
      return Curry._1(transform, json);
    }
  };
  var transform = function (param) {
    return with_multivalued(transform_single, param);
  };
  var json$1 = json;
  if (field[/* required */3]) {
    return Curry._1(transform, json$1);
  } else {
    var match = Js_json.classify(json$1);
    if (typeof match === "number" && match >= 2) {
      return Runtime_common$BwaxAdmin.pack_nothing;
    } else {
      return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_just, Curry._1(transform, json$1));
    }
  }
}

function transform_record(entity_dict, data_type_dict, should_process_select, fields, json) {
  return transform_record_value((function (transform, _field, linked_entity, json) {
                var all_fields = Pervasives.$at(linked_entity[/* fields */2], linked_entity[/* virtual_fields */3]);
                return Curry._3(transform, should_process_select, all_fields, json);
              }), entity_dict, data_type_dict, should_process_select, fields, json);
}

function js_to_input_record(entity_dict, data_type_dict, entity_name, form_data) {
  var raw_values = Plate$BwaxAdmin.List.filter((function (param) {
            return !Js_undefined.testAny(param[1]);
          }))(Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(form_data)))));
  var entity = Base_query_types$BwaxAdmin.get_entity(entity_dict, entity_name);
  var to_value = function (name, j) {
    var match = Plate$BwaxAdmin.List.find((function (f) {
            return f[/* name */0] === name;
          }), entity[/* fields */2]);
    var field;
    if (match !== undefined) {
      field = match;
    } else {
      throw [
            Runtime_common$BwaxAdmin.Runtime_exn,
            entity[/* name */0] + (" doesn't have field " + name)
          ];
    }
    var mv = transform_field_value((function (param, param$1, param$2, j) {
            var param$3 = Json$BwaxAdmin.get_value("id", Js_json.decodeString, j);
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, param$3 !== undefined ? param$3 : Js_json.decodeString(j));
          }), entity_dict, data_type_dict, /* record */Caml_chrome_debugger.record([
            "should_process_select",
            "should_process_composite_data"
          ], [
            false,
            true
          ]), field, j);
    if (mv !== undefined) {
      return mv;
    } else {
      return Runtime_common$BwaxAdmin.pack_nothing;
    }
  };
  return Runtime_common$BwaxAdmin.pack_object(Plate$BwaxAdmin.List.map((function (param) {
                    var name = param[0];
                    return /* tuple */[
                            name,
                            to_value(name, param[1])
                          ];
                  }), raw_values));
}

function transform_interface_value(entity_dict, data_type_dict, interface_value_type, maybe_sels, transform_option, j) {
  var to_interface_value = function (param, param$1, param$2, param$3) {
    return transform_interface_value(entity_dict, data_type_dict, param, param$1, param$2, param$3);
  };
  if (typeof interface_value_type === "number") {
    switch (interface_value_type) {
      case /* Intf_bool */0 :
          return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_bool, Js_json.decodeBoolean(j));
      case /* Intf_int */1 :
          return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_int, Plate$BwaxAdmin.$$Option.map((function (prim) {
                            return prim | 0;
                          }), Js_json.decodeNumber(j)));
      case /* Intf_float */2 :
          return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_float, Js_json.decodeNumber(j));
      case /* Intf_json */4 :
          return Runtime_common$BwaxAdmin.pack_json(j);
      case /* Intf_string */3 :
      case /* Intf_richtext */5 :
          return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Js_json.decodeString(j));
      case /* Intf_datetime */6 :
          return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_date, Plate$BwaxAdmin.$$Option.map((function (prim) {
                            return prim.valueOf();
                          }), Plate$BwaxAdmin.$$Option.map((function (prim) {
                                return new Date(prim);
                              }), Js_json.decodeString(j))));
      case /* Intf_image */7 :
          return Runtime_common$BwaxAdmin.pack_image(j);
      case /* Intf_file */8 :
          return Runtime_common$BwaxAdmin.pack_file(j);
      
    }
  } else {
    switch (interface_value_type.tag | 0) {
      case /* Intf_list */0 :
          var vt = interface_value_type[0];
          var to_values = function (vl) {
            var vs = Plate$BwaxAdmin.List.keep_map((function (param) {
                    return to_interface_value(vt, maybe_sels, transform_option, param);
                  }), vl);
            if (Plate$BwaxAdmin.List.length(vl) !== Plate$BwaxAdmin.List.length(vs)) {
              return ;
            } else {
              return Runtime_common$BwaxAdmin.pack_list(vs);
            }
          };
          return Plate$BwaxAdmin.$$Option.and_then(to_values, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Js_json.decodeArray(j)));
      case /* Intf_maybe */1 :
          var match = Js_json.classify(j);
          var exit = 0;
          if (typeof match === "number" && match >= 2) {
            return Runtime_common$BwaxAdmin.pack_nothing;
          } else {
            exit = 2;
          }
          if (exit === 2) {
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_just, to_interface_value(interface_value_type[0], maybe_sels, transform_option, j));
          }
          break;
      case /* Intf_record */2 :
          var binds = Plate$BwaxAdmin.List.keep_map((function (param) {
                  var vt = param[1];
                  var k = param[0];
                  return Plate$BwaxAdmin.$$Option.map((function (v) {
                                return /* tuple */[
                                        k,
                                        v
                                      ];
                              }), Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                    return to_interface_value(vt, maybe_sels, transform_option, param);
                                  }), Json$BwaxAdmin.get_field(k, j)));
                }), interface_value_type[0]);
          return Runtime_common$BwaxAdmin.pack_object(binds);
      case /* Intf_tuple */3 :
          var vs = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Js_json.decodeArray(j)));
          var pairs = Plate$BwaxAdmin.List.zip(/* tuple */[
                interface_value_type[0],
                vs
              ]);
          return Runtime_common$BwaxAdmin.pack_tuple(Plate$BwaxAdmin.List.keep_map((function (param) {
                            return to_interface_value(param[0], maybe_sels, transform_option, param[1]);
                          }), pairs));
      case /* Intf_dict */4 :
          var vt$1 = interface_value_type[1];
          var kt = interface_value_type[0];
          var partial_arg = Plate$BwaxAdmin.List.from_array;
          return Runtime_common$BwaxAdmin.pack_dict(Lang_value_dict$BwaxAdmin.from_list(Plate$BwaxAdmin.List.keep_map((function (param) {
                                var v = param[1];
                                return Plate$BwaxAdmin.$$Option.and_then((function (k) {
                                              return Plate$BwaxAdmin.$$Option.map((function (v) {
                                                            return /* tuple */[
                                                                    k,
                                                                    v
                                                                  ];
                                                          }), to_interface_value(vt$1, maybe_sels, transform_option, v));
                                            }), to_interface_value(kt, maybe_sels, transform_option, param[0]));
                              }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (param) {
                                        return Plate$BwaxAdmin.$at$great(Js_dict.entries, partial_arg, param);
                                      }), Js_json.decodeObject(j))))));
      case /* Intf_paged */5 :
          var vt$2 = interface_value_type[0];
          var decode_to_int = function (json) {
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_int, Plate$BwaxAdmin.$$Option.map((function (prim) {
                              return prim | 0;
                            }), Js_json.decodeNumber(json)));
          };
          var m_count = Json$BwaxAdmin.get_value("count", decode_to_int, j);
          var m_pageSize = Json$BwaxAdmin.get_value("pageSize", decode_to_int, j);
          var m_offset = Json$BwaxAdmin.get_value("offset", decode_to_int, j);
          var data = Runtime_common$BwaxAdmin.pack_list(Plate$BwaxAdmin.List.keep_map((function (param) {
                      return to_interface_value(vt$2, maybe_sels, transform_option, param);
                    }), Curry._1(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.with_default(/* array */[], Json$BwaxAdmin.get_value("data", Js_json.decodeArray, j)))));
          if (m_count !== undefined && m_pageSize !== undefined && m_offset !== undefined) {
            return Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "data",
                            data
                          ],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "count",
                                m_count
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "pageSize",
                                    m_pageSize
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "offset",
                                        m_offset
                                      ],
                                      /* [] */0
                                    ])
                                ])
                            ])
                        ]));
          } else {
            return ;
          }
      case /* Intf_entity */6 :
          var entity = Base_query_types$BwaxAdmin.get_entity(entity_dict, interface_value_type[0]);
          var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
          return transform_record(entity_dict, data_type_dict, transform_option, all_fields, j);
      case /* Intf_criteria */7 :
      case /* Intf_sorting */8 :
          return ;
      case /* Intf_adding */9 :
      case /* Intf_updating */10 :
          break;
      
    }
  }
  var entity$1 = Base_query_types$BwaxAdmin.get_entity(entity_dict, interface_value_type[0]);
  return js_to_input_record(entity_dict, data_type_dict, entity$1[/* name */0], j);
}

function wrap_list(l) {
  return Plate$BwaxAdmin.$$Option.map((function (v) {
                return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          v,
                          /* [] */0
                        ]);
              }), l);
}

function build_query_result(entity_dict, data_type_dict, query_option, transform_option, json) {
  var match = Js_json.classify(json);
  if (typeof match === "number" && match >= 2) {
    return ;
  }
  var get_count = function (param) {
    return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_int, Plate$BwaxAdmin.$$Option.map((function (prim) {
                      return prim | 0;
                    }), Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeNumber, Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                              "count",
                              /* [] */0
                            ]), json))));
  };
  var get_data = function (entity) {
    var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
    return Plate$BwaxAdmin.$$Option.map((function (l) {
                  return Runtime_common$BwaxAdmin.pack_list(Plate$BwaxAdmin.List.keep_map((function (param) {
                                    return transform_record(entity_dict, data_type_dict, transform_option, all_fields, param);
                                  }), l));
                }), Plate$BwaxAdmin.$$Option.map((function (l) {
                      var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          "node",
                          /* [] */0
                        ]);
                      return Plate$BwaxAdmin.List.keep_map((function (param) {
                                    return Json$BwaxAdmin.get_by_path(partial_arg, param);
                                  }), l);
                    }), Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeArray, Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  "edges",
                                  /* [] */0
                                ]), json)))));
  };
  switch (query_option.tag | 0) {
    case /* Q_find_one */0 :
        var entity = query_option[0];
        var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
        return Runtime_common$BwaxAdmin.pack_maybe_directly(Plate$BwaxAdmin.$$Option.and_then((function (param) {
                          return transform_record(entity_dict, data_type_dict, transform_option, all_fields, param);
                        }), Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                              "edges",
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  "0",
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      "node",
                                      /* [] */0
                                    ])
                                ])
                            ]), json)));
    case /* Q_list */1 :
        return get_data(query_option[0]);
    case /* Q_listAll */2 :
        var mp = query_option[1][3];
        var count = get_count(/* () */0);
        var data = get_data(query_option[0]);
        if (data !== undefined && count !== undefined) {
          var match$1;
          if (mp !== undefined) {
            var match$2 = mp;
            match$1 = /* tuple */[
              match$2[/* page_size */0],
              Plate$BwaxAdmin.$$Option.with_default(0, match$2[/* offset */1])
            ];
          } else {
            match$1 = /* tuple */[
              Base_query_types$BwaxAdmin.default_page_size,
              0
            ];
          }
          return Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "data",
                          data
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "count",
                              count
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "pageSize",
                                  Runtime_common$BwaxAdmin.pack_int(match$1[0])
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "offset",
                                      Runtime_common$BwaxAdmin.pack_int(match$1[1])
                                    ],
                                    /* [] */0
                                  ])
                              ])
                          ])
                      ]));
        } else {
          return ;
        }
    case /* Q_count */3 :
        return get_count(/* () */0);
    case /* Q_aggregate */4 :
        return Plate$BwaxAdmin.$$Option.map((function (l) {
                      return Runtime_common$BwaxAdmin.pack_list(Plate$BwaxAdmin.List.map((function (j) {
                                        return /* tuple */[
                                                /* V_tagged_raw */Caml_chrome_debugger.variant("V_tagged_raw", 8, [
                                                    "JSON",
                                                    j
                                                  ]),
                                                Runtime_common$BwaxAdmin.nt
                                              ];
                                      }), l));
                    }), Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeArray, Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  "result",
                                  /* [] */0
                                ]), json))));
    case /* Q_get_current_user */5 :
        var entity$1 = Base_query_types$BwaxAdmin.get_entity(entity_dict, "用户");
        var all_fields$1 = Pervasives.$at(entity$1[/* fields */2], entity$1[/* virtual_fields */3]);
        return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                      return transform_record(entity_dict, data_type_dict, transform_option, all_fields$1, param);
                    }), Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          "authUser",
                          /* [] */0
                        ]), json));
    case /* Q_custom_query */6 :
        var outputs = query_option[3];
        var get_result = function (index, param) {
          var maybe_sels = param[1];
          var output_type = param[0];
          var key = "out" + String(index);
          return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                        return transform_interface_value(entity_dict, data_type_dict, output_type, maybe_sels, transform_option, param);
                      }), Json$BwaxAdmin.get_field(key, json));
        };
        var values = Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, Plate$BwaxAdmin.List.indexed_map(get_result, outputs));
        if (Plate$BwaxAdmin.List.length(values) !== Plate$BwaxAdmin.List.length(outputs)) {
          return ;
        } else if (Plate$BwaxAdmin.List.length(outputs) > 1) {
          return Runtime_common$BwaxAdmin.pack_tuple(values);
        } else if (values) {
          return values[0];
        } else {
          return ;
        }
    
  }
}

function clear_cache(context) {
  var match = context[/* cache */3];
  if (match !== undefined) {
    return Curry._1(match[/* clearCache */4], /* () */0);
  } else {
    return /* () */0;
  }
}

function set_cache_data(context, k, v) {
  var match = context[/* cache */3];
  if (match !== undefined) {
    return Curry._2(match[/* setData */1], k, v);
  } else {
    return /* () */0;
  }
}

function get_cache_data(context, k) {
  var match = context[/* cache */3];
  if (match !== undefined) {
    return Curry._1(match[/* getData */0], k);
  }
  
}

function set_cache_loading(context, k, p) {
  var match = context[/* cache */3];
  if (match !== undefined) {
    return Curry._2(match[/* setLoading */2], k, p);
  } else {
    return /* () */0;
  }
}

function hash_code(s) {
  return String(Utils.hashCode(s));
}

function resolve_and_run_queries_exn(context, queries, option, cont) {
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var named_queries = Plate$BwaxAdmin.List.indexed_map((function (i, q) {
          var qname = "q" + String(i);
          return /* tuple */[
                  qname,
                  q
                ];
        }), queries);
  var query_text = build_query_text(entity_dict, data_type_dict, option[/* additional_query_params */3], named_queries);
  var variables = build_variables(named_queries);
  var variableDict = Js_dict.fromList(variables);
  var s = JSON.stringify(variableDict);
  var query_key = "bw_q_" + (String(Utils.hashCode(query_text)) + ("_" + String(Utils.hashCode(s))));
  var handle_result = function (json) {
    try {
      var query_results = Plate$BwaxAdmin.List.map((function (param) {
              var query = param[1];
              var qname = param[0];
              var partial_arg_000 = /* should_process_select */option[/* process_select_value */1];
              var partial_arg_001 = /* should_process_composite_data */option[/* process_composite_data */2];
              var partial_arg = /* record */Caml_chrome_debugger.record([
                  "should_process_select",
                  "should_process_composite_data"
                ], [
                  partial_arg_000,
                  partial_arg_001
                ]);
              var build_result = function (param) {
                return build_query_result(entity_dict, data_type_dict, query, partial_arg, param);
              };
              var partial_arg_001$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  qname,
                  /* [] */0
                ]);
              var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "data",
                  partial_arg_001$1
                ]);
              var value = Plate$BwaxAdmin.$$Option.and_then(build_result, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                          return Json$BwaxAdmin.get_by_path(partial_arg$1, param);
                        }), json));
              var error = Plate$BwaxAdmin.$$Option.and_then((function (j) {
                      console.log("Found error for qname", qname, j);
                      return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Json$BwaxAdmin.get_value("message", Js_json.decodeString, j));
                    }), Plate$BwaxAdmin.$$Option.and_then((function (l) {
                          return Plate$BwaxAdmin.List.find((function (j) {
                                        var v = Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "path",
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        "0",
                                                        /* [] */0
                                                      ])
                                                  ]), j));
                                        if (v !== undefined) {
                                          return v === qname;
                                        } else {
                                          return false;
                                        }
                                      }), l);
                        }), Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeArray, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                      return Json$BwaxAdmin.get_field("errors", param);
                                    }), json)))));
              return /* tuple */[
                      value,
                      error
                    ];
            }), named_queries);
      Curry._1(cont, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [query_results]));
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      Curry._1(cont, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
    }
    return Promise.resolve(/* () */0);
  };
  var cached_data = option[/* force_refreshing */0] ? undefined : get_cache_data(context, query_key);
  if (cached_data !== undefined) {
    handle_result(Caml_option.some(Caml_option.valFromOption(cached_data)));
  } else {
    var p = Curry._3(context[/* query_runner */2], query_text, Js_dict.fromList(variables), undefined).then((function (text) {
            return Promise.resolve(Json$BwaxAdmin.parse(text));
          }));
    set_cache_loading(context, query_key, p);
    p.then((function (json) {
            if (json !== undefined) {
              set_cache_data(context, query_key, Caml_option.valFromOption(json));
            }
            return handle_result(json);
          }));
  }
  return /* () */0;
}

function resolve_and_run_queries(context, queries, option, cont) {
  try {
    return resolve_and_run_queries_exn(context, queries, option, cont);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    return Curry._1(cont, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
  }
}

function build_mutation_text(entity_dict, data_type_dict, transform_option, mutation) {
  var m = "m0";
  var build_record_result = function (entity, json) {
    var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
    return Runtime_common$BwaxAdmin.pack_maybe_directly(transform_record(entity_dict, data_type_dict, transform_option, all_fields, json));
  };
  var to_selection_text = function (param, param$1) {
    return build_selection_text(entity_dict, data_type_dict, param, param$1);
  };
  switch (mutation.tag | 0) {
    case /* M_add */0 :
        var entity = mutation[0];
        var r_name = "added" + (entity[/* key */1] + "Edge");
        var selection_text = to_selection_text(entity, mutation[2]);
        var query_text = "mutation ($input: Add" + (entity[/* key */1] + ("Input!) { " + (m + (": add" + (entity[/* key */1] + (" (input: $input)  { clientMutationId " + (r_name + (" { node " + (selection_text + " } } }")))))))));
        var build_result = function (json) {
          var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              m,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  r_name,
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "node",
                      /* [] */0
                    ])
                ])
            ]);
          var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              "data",
              partial_arg_001
            ]);
          return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                        return build_record_result(entity, param);
                      }), Plate$BwaxAdmin.$$Option.and_then((function (param) {
                            return Json$BwaxAdmin.get_by_path(partial_arg, param);
                          }), json));
        };
        return /* tuple */[
                query_text,
                build_result
              ];
    case /* M_update */1 :
        var entity$1 = mutation[0];
        var r_name$1 = "changed" + entity$1[/* key */1];
        var selection_text$1 = to_selection_text(entity$1, mutation[2]);
        var query_text$1 = "mutation ($input: Update" + (entity$1[/* key */1] + ("Input!) { " + (m + (": update" + (entity$1[/* key */1] + (" (input: $input)  { clientMutationId " + (r_name$1 + (" " + (selection_text$1 + " } }")))))))));
        var build_result$1 = function (json) {
          var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              m,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  r_name$1,
                  /* [] */0
                ])
            ]);
          var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              "data",
              partial_arg_001
            ]);
          return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                        return build_record_result(entity$1, param);
                      }), Plate$BwaxAdmin.$$Option.and_then((function (param) {
                            return Json$BwaxAdmin.get_by_path(partial_arg, param);
                          }), json));
        };
        return /* tuple */[
                query_text$1,
                build_result$1
              ];
    case /* M_delete */2 :
        var entity$2 = mutation[0];
        var r_name$2 = "deleted" + entity$2[/* key */1];
        var query_text$2 = "mutation ($input: Delete" + (entity$2[/* key */1] + ("Input!) { " + (m + (": delete" + (entity$2[/* key */1] + (" (input: $input)  { clientMutationId " + (r_name$2 + " { id } } }")))))));
        var build_result$2 = function (json) {
          var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              m,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  r_name$2,
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "id",
                      /* [] */0
                    ])
                ])
            ]);
          var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              "data",
              partial_arg_001
            ]);
          var param = Plate$BwaxAdmin.$$Option.and_then((function (param) {
                  return Json$BwaxAdmin.get_by_path(partial_arg, param);
                }), json);
          if (param !== undefined) {
            return Runtime_common$BwaxAdmin.pack_bool(true);
          } else {
            return Runtime_common$BwaxAdmin.pack_bool(false);
          }
        };
        return /* tuple */[
                query_text$2,
                build_result$2
              ];
    case /* M_remat */3 :
        var entity$3 = mutation[0];
        var r_name$3 = "remat" + entity$3[/* key */1];
        var query_text$3 = "mutation ($input: Remat" + (entity$3[/* key */1] + ("Input!) { " + (m + (": " + (r_name$3 + " (input: $input)  { clientMutationId count } }")))));
        var build_result$3 = function (json) {
          var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              m,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "count",
                  /* [] */0
                ])
            ]);
          var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              "data",
              partial_arg_001
            ]);
          var param = Plate$BwaxAdmin.$$Option.and_then((function (param) {
                  return Json$BwaxAdmin.get_by_path(partial_arg, param);
                }), json);
          if (param !== undefined) {
            return Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_int, Plate$BwaxAdmin.$$Option.map(Js_math.floor_int, Js_json.decodeNumber(Caml_option.valFromOption(param))));
          }
          
        };
        return /* tuple */[
                query_text$3,
                build_result$3
              ];
    case /* M_custom_operation */4 :
        var outputs = mutation[3];
        var $$interface = mutation[0];
        var input_type_name = Plate$BwaxAdmin.Str.cap($$interface[/* key */1]) + "Input!";
        var mutation_name = $$interface[/* key */1];
        var selection_text$2 = build_selection_text_from_outputs(entity_dict, data_type_dict, outputs);
        var query_text$4 = "mutation ($input: " + (input_type_name + (") { " + (m + (": " + (mutation_name + (" (input: $input)  { " + (selection_text$2 + " clientMutationId } }")))))));
        var get_result = function (json) {
          var get_result$1 = function (index, param) {
            var maybe_sels = param[1];
            var output_type = param[0];
            var key = "out" + String(index);
            return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                          return transform_interface_value(entity_dict, data_type_dict, output_type, maybe_sels, default_transform_option, param);
                        }), Json$BwaxAdmin.get_field(key, json));
          };
          var values = Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, Plate$BwaxAdmin.List.indexed_map(get_result$1, outputs));
          if (Plate$BwaxAdmin.List.length(values) !== Plate$BwaxAdmin.List.length(outputs)) {
            return ;
          } else if (Plate$BwaxAdmin.List.length(outputs) > 1) {
            return Runtime_common$BwaxAdmin.pack_tuple(values);
          } else if (values) {
            return values[0];
          } else {
            return ;
          }
        };
        var build_result$4 = function (j) {
          return Plate$BwaxAdmin.$$Option.and_then(get_result, Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "data",
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                "m0",
                                /* [] */0
                              ])
                          ]), j));
        };
        var partial_arg = Plate$BwaxAdmin.$$Option.and_then;
        return /* tuple */[
                query_text$4,
                (function (param) {
                    return partial_arg(build_result$4, param);
                  })
              ];
    
  }
}

function build_field_input(entity_dict, data_type_dict, fields, inside_data_type, param) {
  var value = param[1];
  var name = param[0];
  var found = Plate$BwaxAdmin.List.find((function (f) {
          if (name === Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0])) {
            return true;
          } else {
            return name === f[/* name */0];
          }
        }), fields);
  var field;
  if (found !== undefined) {
    field = found;
  } else {
    throw [
          Base_query_types$BwaxAdmin.Builder_exn,
          "I can\'t find field `" + (String(name) + "`")
        ];
  }
  var match = field[/* dtype */2];
  var match$1 = field[/* multivalued */4];
  var input_key;
  var exit = 0;
  if (typeof match === "number") {
    input_key = field[/* key */1];
  } else {
    switch (match.tag | 0) {
      case /* Entity_link */1 :
      case /* Entity_link_opaque */2 :
          exit = 1;
          break;
      default:
        input_key = field[/* key */1];
    }
  }
  if (exit === 1) {
    input_key = match$1 || inside_data_type ? field[/* key */1] : field[/* key */1] + "Id";
  }
  var match$2 = field[/* dtype */2];
  var v;
  if (typeof match$2 === "number") {
    v = value;
  } else {
    switch (match$2.tag | 0) {
      case /* Atom_select */0 :
          var match$3 = Js_json.decodeString(value);
          if (match$3 !== undefined) {
            var match$4 = Plate$BwaxAdmin.List.assoc(match$3, match$2[0]);
            v = match$4 !== undefined ? match$4 : value;
          } else {
            v = value;
          }
          break;
      case /* Custom_type */3 :
          var data_type = Base_query_types$BwaxAdmin.get_data_type(data_type_dict, match$2[0]);
          var match$5 = data_type[/* trait */2];
          if (typeof match$5 === "number" || match$5.tag !== /* C_record */1) {
            v = value;
          } else {
            var fields$1 = match$5[0];
            var transform = function (o) {
              var partial_arg = Plate$BwaxAdmin.List.map;
              var result = Plate$BwaxAdmin.$$Option.map(Js_dict.fromList, Plate$BwaxAdmin.$$Option.map((function (param) {
                          return partial_arg((function (param) {
                                        return build_field_input(entity_dict, data_type_dict, fields$1, true, param);
                                      }), param);
                        }), Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(o)))));
              if (result !== undefined) {
                return Caml_option.valFromOption(result);
              } else {
                return null;
              }
            };
            if (field[/* multivalued */4]) {
              var partial_arg = Plate$BwaxAdmin.List.map;
              v = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (param) {
                              return partial_arg(transform, param);
                            }), Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Js_json.decodeArray(value)))));
            } else {
              v = transform(value);
            }
          }
          break;
      default:
        v = value;
    }
  }
  return /* tuple */[
          input_key,
          v
        ];
}

function build_mutation_variables(entity_dict, data_type_dict, mutation) {
  var clientMutationId = new Date().getTime().toString();
  var build_entity_input = function (entity, params) {
    if (params.tag) {
      return Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.map(Js_dict.entries, Js_json.decodeObject(params[0]))));
    } else {
      var fields = entity[/* fields */2];
      return Plate$BwaxAdmin.List.map((function (param) {
                    return build_field_input(entity_dict, data_type_dict, fields, false, param);
                  }), params[0]);
    }
  };
  switch (mutation.tag | 0) {
    case /* M_add */0 :
        var input = build_entity_input(mutation[0], mutation[1]);
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "input",
                    Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "clientMutationId",
                              clientMutationId
                            ],
                            input
                          ]))
                  ],
                  /* [] */0
                ]);
    case /* M_update */1 :
        var match = mutation[1];
        var values = build_entity_input(mutation[0], match[0]);
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "input",
                    Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "clientMutationId",
                              clientMutationId
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "values",
                                  Js_dict.fromList(values)
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "id",
                                      match[1]
                                    ],
                                    /* [] */0
                                  ])
                              ])
                          ]))
                  ],
                  /* [] */0
                ]);
    case /* M_delete */2 :
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "input",
                    Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "clientMutationId",
                              clientMutationId
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "id",
                                  mutation[1]
                                ],
                                /* [] */0
                              ])
                          ]))
                  ],
                  /* [] */0
                ]);
    case /* M_remat */3 :
        var match$1 = mutation[1];
        var l_000 = /* tuple */[
          "clientMutationId",
          clientMutationId
        ];
        var l_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            Plate$BwaxAdmin.$$Option.map((function (c) {
                    return /* tuple */[
                            "condition",
                            c
                          ];
                  }), Base_query_types$BwaxAdmin.pack_criteria(match$1[0])),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "rematFields",
                  Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map((function (prim) {
                              return prim;
                            }), match$1[1]))
                ],
                /* [] */0
              ])
          ]);
        var l = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            l_000,
            l_001
          ]);
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "input",
                    Js_dict.fromList(Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, l))
                  ],
                  /* [] */0
                ]);
    case /* M_custom_operation */4 :
        var build_input_value = function (index, param) {
          var j = process_interface_input(entity_dict, data_type_dict, param[0], param[1]);
          return /* tuple */[
                  "in" + String(index),
                  j
                ];
        };
        var id_inputs = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (id) {
                    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "id",
                                id
                              ],
                              /* [] */0
                            ]);
                  }), mutation[1]));
        var input_values = Pervasives.$at(Plate$BwaxAdmin.List.indexed_map(build_input_value, mutation[2]), id_inputs);
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "input",
                    Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "clientMutationId",
                              clientMutationId
                            ],
                            input_values
                          ]))
                  ],
                  /* [] */0
                ]);
    
  }
}

function resolve_and_run_mutation_exn(context, mutation, option, cont) {
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var transform_option_000 = /* should_process_select */option[/* process_select_value */1];
  var transform_option_001 = /* should_process_composite_data */option[/* process_composite_data */2];
  var transform_option = /* record */Caml_chrome_debugger.record([
      "should_process_select",
      "should_process_composite_data"
    ], [
      transform_option_000,
      transform_option_001
    ]);
  var match = build_mutation_text(entity_dict, data_type_dict, transform_option, mutation);
  var build_result = match[1];
  var variables = build_mutation_variables(entity_dict, data_type_dict, mutation);
  var handle_result = function (text) {
    clear_cache(context);
    try {
      var json = Json$BwaxAdmin.parse(text);
      var value = Curry._1(build_result, json);
      var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          "errors",
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              "0",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "message",
                  /* [] */0
                ])
            ])
        ]);
      var error = Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.pack_string, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                      return Json$BwaxAdmin.get_by_path(partial_arg, param);
                    }), json)));
      Curry._1(cont, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* tuple */[
                value,
                error
              ]]));
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      Curry._1(cont, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
    }
    return Promise.resolve(/* () */0);
  };
  Curry._3(context[/* query_runner */2], match[0], Js_dict.fromList(variables), undefined).then(handle_result);
  return /* () */0;
}

function resolve_and_run_mutation(context, mutation, option, cont) {
  try {
    return resolve_and_run_mutation_exn(context, mutation, option, cont);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    return Curry._1(cont, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
  }
}

var default_query_runner_option = /* record */Caml_chrome_debugger.record([
    "force_refreshing",
    "process_select_value",
    "process_composite_data",
    "additional_query_params"
  ], [
    false,
    true,
    true,
    0
  ]);

exports.runtime_to_query_context = runtime_to_query_context;
exports.selection_text_for_entity = selection_text_for_entity;
exports.selection_text_for_data_type = selection_text_for_data_type;
exports.selection_text_for_field = selection_text_for_field;
exports.selection_text_for_fields = selection_text_for_fields;
exports.build_selection_text = build_selection_text;
exports.print_selection = print_selection;
exports.process_interface_input = process_interface_input;
exports.drop_none = drop_none;
exports.aggregate_var_def_texts = aggregate_var_def_texts;
exports.query_var_def_text = query_var_def_text;
exports.aggregate_var_texts = aggregate_var_texts;
exports.query_var_text = query_var_text;
exports.build_selection_text_from_outputs = build_selection_text_from_outputs;
exports.build_query_text = build_query_text;
exports.build_variables = build_variables;
exports.field_by_key_or_name_exn = field_by_key_or_name_exn;
exports.default_transform_option = default_transform_option;
exports.transform_record_value = transform_record_value;
exports.transform_field_value = transform_field_value;
exports.transform_record = transform_record;
exports.js_to_input_record = js_to_input_record;
exports.transform_interface_value = transform_interface_value;
exports.wrap_list = wrap_list;
exports.build_query_result = build_query_result;
exports.clear_cache = clear_cache;
exports.set_cache_data = set_cache_data;
exports.get_cache_data = get_cache_data;
exports.set_cache_loading = set_cache_loading;
exports.hash_code = hash_code;
exports.default_query_runner_option = default_query_runner_option;
exports.resolve_and_run_queries_exn = resolve_and_run_queries_exn;
exports.resolve_and_run_queries = resolve_and_run_queries;
exports.build_mutation_text = build_mutation_text;
exports.build_field_input = build_field_input;
exports.build_mutation_variables = build_mutation_variables;
exports.resolve_and_run_mutation_exn = resolve_and_run_mutation_exn;
exports.resolve_and_run_mutation = resolve_and_run_mutation;
/* bwax/utils Not a pure module */
