
import React from 'react'

import Tooltip from 'bwax-ui/components/Tooltip';
import { InfoCircleOutlined } from "@ant-design/icons"

import './EditForm.less'


/**
 *  params: {
 *      items -> [{
 *          label,
 *          tip,
 *          input: Widget    
 *      }]
 *          
 *  }
 */
export default function EditForm(props) {

    const { params, editingState, renderWidget } = props
    const { prefix, items, applicableIf } = params

    const { errors, validated } = editingState

    function checkApplicable (name) {
        if(!applicableIf) {
            return true
        } else {
            // 有 editable 条件
            let condition = applicableIf[name];
            
            if (condition === true || condition === undefined) {
                return true
            } else if (typeof(condition) == 'object' &&  condition !== null) {
                // it should be object of <other-field-name> <required-matched-value>
                return Object.keys(condition).every(cn => {
                    let editKey = prefix ? prefix + "_" + cn : cn
                    
                    let currentValue = validated[editKey];
                    let targetValue = condition[cn];

                    if (currentValue == targetValue) {
                        return true
                    } else if (Array.isArray(currentValue)) {
                        // TODO 可能也要考虑到本身数据类型就是 array 的情况
                        return currentValue.some(v => v == targetValue);
                    } else {
                        return false
                    }
                })
            }
            return false
        }

    }

    return (
        <div className="admin--edit-form-legacy">
            {
                items.map((item, index) => {
                    const { label, tip, input } = item

                    const { required, editKey } = (() => {
                        // 之后等全部迁到新的用法时，就不应该判断两种逻辑了：
                        if(input.$$typeof) {
                            return item   
                        } else {
                            const { params } = input.widget ? input.widget : input
                            return params || {}
                        }

                    })()

                    const error = (errors || {})[editKey]
                    
                    if (!checkApplicable(label)) {
                        return null
                    }


                    return (
                        <div className="form-item" key={index}>
                            <div className={`form-item-label ${required ? 'item-require' : ''}`}>
                                {label}
                                {
                                    tip ? (
                                        <Tooltip text={tip}>
                                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    ) : null
                                }
                            </div>
                            <div className="form-item-input">
                                {input.$$typeof || (!renderWidget) ? input:  renderWidget(input)}
                                {/* TODO: special error handle */}
                                {
                                    error ?
                                        <p className="error-message-cot">
                                            {error}
                                        </p> : null
                                }
                            </div>

                        </div>
                    )
                }).filter(x => !!x)
            }
        </div>
    )
}

