// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");
var Lang_typing_print$BwaxAdmin = require("./lang_typing_print.bs.js");
var Lang_typing_instantiation$BwaxAdmin = require("./lang_typing_instantiation.bs.js");

var Unify_exc = Caml_exceptions.create("Lang_typing_unification-BwaxAdmin.Unify_exc");

function occurs(v, t) {
  return Plate$BwaxAdmin.List.member((function (param) {
                return Caml_obj.caml_equal(param[0], param[1]);
              }), v, Lang_typing_base$BwaxAdmin.get_vars(t));
}

function any_permitting(param) {
  return true;
}

function number_permitting(param) {
  if (typeof param === "number" || param.tag || param[1]) {
    return false;
  } else {
    var name = param[0];
    if (name === Lang_typing_base$BwaxAdmin.int_name) {
      return true;
    } else {
      return name === Lang_typing_base$BwaxAdmin.float_name;
    }
  }
}

function appendable_permitting(param) {
  if (typeof param === "number" || param.tag) {
    return false;
  } else {
    var name = param[0];
    if (name === Lang_typing_base$BwaxAdmin.string_name) {
      return true;
    } else {
      return name === Lang_typing_base$BwaxAdmin.list_name;
    }
  }
}

function comparable_permitting(param) {
  if (typeof param === "number") {
    return false;
  } else {
    switch (param.tag | 0) {
      case /* Term */0 :
          var name = param[0];
          if (name === Lang_typing_base$BwaxAdmin.int_name || name === Lang_typing_base$BwaxAdmin.float_name || name === Lang_typing_base$BwaxAdmin.char_name || name === Lang_typing_base$BwaxAdmin.string_name) {
            return true;
          } else {
            var sons = param[1];
            if (name === Lang_typing_base$BwaxAdmin.list_name || name === Lang_typing_base$BwaxAdmin.tuple_name) {
              return Plate$BwaxAdmin.List.every(comparable_permitting, sons);
            } else {
              return false;
            }
          }
      case /* Var */2 :
          var match = param[0][/* var_type */0];
          if (typeof match === "number") {
            return match === 1;
          } else {
            return false;
          }
      default:
        return false;
    }
  }
}

function compappend_permitting(_param) {
  while(true) {
    var param = _param;
    if (typeof param === "number" || param.tag) {
      return false;
    } else {
      var match = param[1];
      var name = param[0];
      if (name === Lang_typing_base$BwaxAdmin.string_name) {
        return true;
      } else if (match && !(match[1] || name !== Lang_typing_base$BwaxAdmin.list_name)) {
        _param = match[0];
        continue ;
      } else {
        return false;
      }
    }
  };
}

function recordvar_permitting(param) {
  if (typeof param === "number" || param.tag !== /* Term_record */1) {
    return false;
  } else {
    return true;
  }
}

function var_permitting(v) {
  var match = v[/* var_type */0];
  if (typeof match === "number") {
    switch (match) {
      case /* Any */0 :
          return any_permitting;
      case /* Number */1 :
          return number_permitting;
      case /* Appendable */2 :
          return appendable_permitting;
      case /* Comparable */3 :
          return comparable_permitting;
      case /* Compappend */4 :
          return compappend_permitting;
      
    }
  } else {
    switch (match.tag | 0) {
      case /* Recordvar */0 :
          console.log("Shouldn't be here (recordvar)");
          return (function (param) {
              return false;
            });
      case /* Record_readonly */1 :
          console.log("Shouldn't be here (record readonly)");
          return (function (param) {
              return false;
            });
      case /* Record_readonly_of */2 :
          console.log("Shouldn't be here (record subset of)");
          return (function (param) {
              return false;
            });
      case /* Record_limited */3 :
          return (function (t) {
              return false;
            });
      case /* Record_writeonly_of */5 :
          console.log("Shouldn't be here (record writeonly of)");
          return (function (param) {
              return false;
            });
      case /* Any_record_readonly */6 :
          return (function (t) {
              console.log("Don't know how to implement it yet >>", Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, t));
              return false;
            });
      case /* Record_writeonly */4 :
      case /* Record_writeonly_optional */7 :
          break;
      case /* Opaqued */8 :
          return (function (param) {
              return false;
            });
      
    }
  }
  console.log("Shouldn't be here (record writeonly)");
  return (function (param) {
      return false;
    });
}

function not_permitted(dts, v, t) {
  throw [
        Unify_exc,
        Lang_typing_print$BwaxAdmin.string_of_term(dts, t) + (" is not a " + Lang_typing_print$BwaxAdmin.string_of_var(dts, /* record */Caml_chrome_debugger.record([
                  "var_type",
                  "n",
                  "t"
                ], [
                  v[/* var_type */0],
                  0,
                  v[/* t */2]
                ])))
      ];
}

function not_the_same(dts, t1, t2) {
  throw [
        Unify_exc,
        Lang_typing_print$BwaxAdmin.string_of_term(dts, t1) + (" and " + (Lang_typing_print$BwaxAdmin.string_of_term(dts, t2) + " are not the same."))
      ];
}

function unify(next_int, dts, pair) {
  var is_alias = function (name) {
    var match = Plate$BwaxAdmin.List.assoc(name, dts);
    if (match !== undefined && !(typeof match === "number" || match.tag !== /* Alias */1)) {
      return true;
    } else {
      return false;
    }
  };
  var is_alias_to_record = function (_name) {
    while(true) {
      var name = _name;
      var match = Plate$BwaxAdmin.List.assoc(name, dts);
      if (match !== undefined) {
        var match$1 = match;
        if (typeof match$1 === "number" || match$1.tag !== /* Alias */1) {
          return false;
        } else {
          var t = match$1[0][1];
          if (typeof t === "number") {
            return false;
          } else {
            switch (t.tag | 0) {
              case /* Term */0 :
                  _name = t[0];
                  continue ;
              case /* Term_record */1 :
                  return true;
              default:
                return false;
            }
          }
        }
      } else {
        return false;
      }
    };
  };
  var do_resolve_alias = function (t, stop_case) {
    return Lang_typing_instantiation$BwaxAdmin.resolve_alias(next_int, dts, stop_case, t);
  };
  var dont_stop = function (param) {
    return false;
  };
  var maybe_record_alias = function (t) {
    if (typeof t === "number" || t.tag) {
      return ;
    } else {
      var tname = t[0];
      if (is_alias(tname)) {
        var match = do_resolve_alias(t, dont_stop);
        if (typeof match === "number" || match.tag !== /* Term_record */1) {
          return ;
        } else {
          return tname;
        }
      } else {
        return ;
      }
    }
  };
  var a = Lang_typing_base$BwaxAdmin.shorten(pair[0]);
  var b = Lang_typing_base$BwaxAdmin.shorten(pair[1]);
  var unify_readonly_subset = function (not_permitted_subset, holder, pack_subset, maybe_tname, constraints, subset, t) {
    var resolved_t = do_resolve_alias(t, (function (n) {
            return Caml_obj.caml_equal(maybe_tname, n);
          }));
    var must_be_same_alias = function (n) {
      if (Caml_obj.caml_equal(maybe_tname, n)) {
        return /* () */0;
      } else {
        var str = Lang_typing_print$BwaxAdmin.string_of_term(dts, resolved_t);
        if (maybe_tname !== undefined) {
          var msg = "`" + (String(str) + ("` is not compatible with `" + (String(maybe_tname) + "`")));
          throw [
                Unify_exc,
                msg
              ];
        } else {
          var msg$1 = "`" + (String(str) + "` is not compatible with given subrecord ");
          throw [
                Unify_exc,
                msg$1
              ];
        }
      }
    };
    var collect_binds = function (binds, check_required) {
      var constraint_al = Plate$BwaxAdmin.List.map((function (param) {
              return /* tuple */[
                      param[0],
                      /* tuple */[
                        param[1],
                        param[2]
                      ]
                    ];
            }), constraints);
      var candidates = Plate$BwaxAdmin.List.foldl((function (acc, param) {
              var t1 = param[1];
              var k = param[0];
              var match = Plate$BwaxAdmin.List.assoc(k, constraint_al);
              if (match !== undefined) {
                var t2 = match[0];
                var match$1 = maybe_record_alias(t2);
                var other_t = match$1 !== undefined ? /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                          "var_type",
                          "n",
                          "t"
                        ], [
                          Caml_chrome_debugger.variant("Record_readonly", 1, [
                              match$1,
                              /* [] */0
                            ]),
                          Curry._1(next_int, /* () */0),
                          1
                        ])]) : (
                    typeof t2 === "number" || t2.tag !== /* Term_record */1 ? t2 : /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                              "var_type",
                              "n",
                              "t"
                            ], [
                              Caml_chrome_debugger.variant("Record_readonly_of", 2, [
                                  t2[0],
                                  /* [] */0
                                ]),
                              Curry._1(next_int, /* () */0),
                              1
                            ])])
                  );
                unify(next_int, dts, /* tuple */[
                      t1,
                      other_t
                    ]);
                var nt;
                var exit = 0;
                var exit$1 = 0;
                var exit$2 = 0;
                if (typeof t1 === "number" || t1.tag !== /* Var */2) {
                  exit$2 = 3;
                } else {
                  var tmp = t1[0][/* var_type */0];
                  if (typeof tmp === "number" || tmp.tag !== /* Record_readonly */1) {
                    exit$2 = 3;
                  } else {
                    nt = t1;
                  }
                }
                if (exit$2 === 3) {
                  if (typeof other_t === "number" || other_t.tag !== /* Var */2) {
                    exit$1 = 2;
                  } else {
                    var tmp$1 = other_t[0][/* var_type */0];
                    if (typeof tmp$1 === "number" || tmp$1.tag !== /* Record_readonly */1) {
                      exit$1 = 2;
                    } else {
                      nt = other_t;
                    }
                  }
                }
                if (exit$1 === 2) {
                  if (typeof t1 === "number" || t1.tag) {
                    exit = 1;
                  } else {
                    var match$2 = t1[1];
                    if (match$2) {
                      var match$3 = match$2[0];
                      if (typeof match$3 === "number" || match$3.tag !== /* Var */2) {
                        exit = 1;
                      } else {
                        var tmp$2 = match$3[0][/* var_type */0];
                        if (typeof tmp$2 === "number" || !(tmp$2.tag === /* Record_readonly */1 && !match$2[1])) {
                          exit = 1;
                        } else {
                          nt = t1;
                        }
                      }
                    } else {
                      exit = 1;
                    }
                  }
                }
                if (exit === 1) {
                  if (typeof other_t === "number" || other_t.tag) {
                    nt = t1;
                  } else {
                    var match$4 = other_t[1];
                    if (match$4) {
                      var match$5 = match$4[0];
                      if (typeof match$5 === "number" || match$5.tag !== /* Var */2) {
                        nt = t1;
                      } else {
                        var tmp$3 = match$5[0][/* var_type */0];
                        nt = typeof tmp$3 === "number" || !(tmp$3.tag === /* Record_readonly */1 && !match$4[1]) ? t1 : other_t;
                      }
                    } else {
                      nt = t1;
                    }
                  }
                }
                return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            k,
                            nt
                          ],
                          acc
                        ]);
              } else {
                throw [
                      Unify_exc,
                      "Not allowed field - " + k
                    ];
              }
            }), /* [] */0, binds);
      if (check_required) {
        Plate$BwaxAdmin.List.map((function (param) {
                var k = param[0];
                var match = Plate$BwaxAdmin.List.assoc(k, binds);
                if (match !== undefined) {
                  return /* () */0;
                } else {
                  throw [
                        Unify_exc,
                        "Field `" + (k + "` is required")
                      ];
                }
              }), Plate$BwaxAdmin.List.filter((function (param) {
                      return param[1][1];
                    }))(constraint_al));
      }
      var determine_type = function (candidate_t, subset_t) {
        var match = Lang_typing_base$BwaxAdmin.shorten(candidate_t);
        var match$1 = Lang_typing_base$BwaxAdmin.shorten(subset_t);
        var exit = 0;
        var rs;
        var rv;
        if (typeof match === "number") {
          exit = 1;
        } else {
          switch (match.tag | 0) {
            case /* Term */0 :
                if (typeof match$1 === "number") {
                  return subset_t;
                } else {
                  switch (match$1.tag | 0) {
                    case /* Term */0 :
                        var nsons = Plate$BwaxAdmin.List.map((function (param) {
                                return determine_type(param[0], param[1]);
                              }), Plate$BwaxAdmin.List.combine(match[1], match$1[1]));
                        return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                                  match[0],
                                  nsons
                                ]);
                    case /* Var */2 :
                        exit = 1;
                        break;
                    default:
                      return subset_t;
                  }
                }
                break;
            case /* Var */2 :
                var v1 = match[0];
                var match$2 = v1[/* var_type */0];
                if (typeof match$2 === "number") {
                  if (match$2 === /* Any */0) {
                    return match$1;
                  } else {
                    exit = 1;
                  }
                } else {
                  switch (match$2.tag | 0) {
                    case /* Recordvar */0 :
                        if (typeof match$1 === "number" || match$1.tag !== /* Var */2) {
                          return subset_t;
                        } else {
                          var match$3 = match$1[0][/* var_type */0];
                          if (typeof match$3 === "number") {
                            if (match$3 === /* Any */0) {
                              exit = 1;
                            } else {
                              return subset_t;
                            }
                          } else {
                            switch (match$3.tag | 0) {
                              case /* Recordvar */0 :
                                  return /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                                                "var_type",
                                                "n",
                                                "t"
                                              ], [
                                                Caml_chrome_debugger.variant("Recordvar", 0, [Pervasives.$at(match$2[0], match$3[0])]),
                                                Curry._1(next_int, /* () */0),
                                                1
                                              ])]);
                              case /* Record_readonly */1 :
                                  rs = match$1;
                                  rv = match;
                                  exit = 2;
                                  break;
                              default:
                                return subset_t;
                            }
                          }
                        }
                        break;
                    case /* Record_readonly */1 :
                        var binds1 = match$2[1];
                        if (typeof match$1 === "number" || match$1.tag !== /* Var */2) {
                          return subset_t;
                        } else {
                          var v2 = match$1[0];
                          var match$4 = v2[/* var_type */0];
                          if (typeof match$4 === "number") {
                            if (match$4 === /* Any */0) {
                              exit = 1;
                            } else {
                              return subset_t;
                            }
                          } else {
                            switch (match$4.tag | 0) {
                              case /* Recordvar */0 :
                                  rs = match;
                                  rv = match$1;
                                  exit = 2;
                                  break;
                              case /* Record_readonly */1 :
                                  var binds2 = match$4[1];
                                  if (Caml_obj.caml_notequal(v1, v2)) {
                                    console.log("TAKE-A-LOOK: both sides are subrecord");
                                    console.log(Lang_typing_print$BwaxAdmin.string_of_var(dts, v1));
                                    console.log(Lang_typing_print$BwaxAdmin.string_of_var(dts, v2));
                                  }
                                  var binds1_merged_binds2 = Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.List.foldl((function (acc, param) {
                                              var t1 = param[1];
                                              var k = param[0];
                                              var match = Plate$BwaxAdmin.List.assoc(k, binds2);
                                              if (match !== undefined) {
                                                unify(next_int, dts, /* tuple */[
                                                      t1,
                                                      match
                                                    ]);
                                                return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          /* tuple */[
                                                            k,
                                                            t1
                                                          ],
                                                          acc
                                                        ]);
                                              } else {
                                                return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          /* tuple */[
                                                            k,
                                                            t1
                                                          ],
                                                          acc
                                                        ]);
                                              }
                                            }), /* [] */0, binds1));
                                  var binds2_rest = Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.List.foldl((function (acc, param) {
                                              var k = param[0];
                                              var match = Plate$BwaxAdmin.List.assoc(k, binds1);
                                              if (match !== undefined) {
                                                return acc;
                                              } else {
                                                return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          /* tuple */[
                                                            k,
                                                            param[1]
                                                          ],
                                                          acc
                                                        ]);
                                              }
                                            }), /* [] */0, binds2));
                                  var merged = Pervasives.$at(binds1_merged_binds2, binds2_rest);
                                  var nt = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                                          "var_type",
                                          "n",
                                          "t"
                                        ], [
                                          Caml_chrome_debugger.variant("Record_readonly", 1, [
                                              match$2[0],
                                              merged
                                            ]),
                                          Curry._1(next_int, /* () */0),
                                          1
                                        ])]);
                                  v1[/* t */2] = nt;
                                  v2[/* t */2] = nt;
                                  return nt;
                              default:
                                return subset_t;
                            }
                          }
                        }
                        break;
                    default:
                      exit = 1;
                  }
                }
                break;
            default:
              exit = 1;
          }
        }
        switch (exit) {
          case 1 :
              if (typeof match$1 === "number" || match$1.tag !== /* Var */2) {
                return subset_t;
              } else {
                var match$5 = match$1[0][/* var_type */0];
                if (typeof match$5 === "number" && match$5 === 0) {
                  return match;
                } else {
                  return subset_t;
                }
              }
          case 2 :
              console.log("TAKE-A-LOOK: recordvar and record subset");
              console.log("    ", Lang_typing_print$BwaxAdmin.string_of_term(dts, rv));
              console.log("    ", Lang_typing_print$BwaxAdmin.string_of_term(dts, rs));
              return rs;
          
        }
      };
      var merged = Plate$BwaxAdmin.List.map((function (param) {
              var t = param[1];
              var k = param[0];
              var match = Plate$BwaxAdmin.List.assoc(k, subset);
              if (match !== undefined) {
                var nt = determine_type(t, match);
                return /* tuple */[
                        k,
                        nt
                      ];
              } else {
                return /* tuple */[
                        k,
                        t
                      ];
              }
            }), candidates);
      var inact = Plate$BwaxAdmin.List.filter((function (param) {
                var match = Plate$BwaxAdmin.List.assoc(param[0], merged);
                return match === undefined;
              }))(subset);
      if (Plate$BwaxAdmin.List.length(candidates) > 0) {
        holder[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [Curry._1(pack_subset, Pervasives.$at(inact, merged))]);
        return /* () */0;
      } else {
        return /* () */0;
      }
    };
    var shortened_t = Lang_typing_base$BwaxAdmin.shorten(resolved_t);
    if (typeof shortened_t !== "number") {
      switch (shortened_t.tag | 0) {
        case /* Term */0 :
            return must_be_same_alias(shortened_t[0]);
        case /* Var */2 :
            var vt = shortened_t[0];
            var match = vt[/* var_type */0];
            if (typeof match === "number") {
              if (match === /* Any */0) {
                vt[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]);
                return /* () */0;
              }
              
            } else {
              switch (match.tag | 0) {
                case /* Recordvar */0 :
                    collect_binds(match[0], false);
                    if (!Caml_obj.caml_equal(shortened_t, /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]))) {
                      vt[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]);
                    }
                    return /* () */0;
                case /* Record_readonly */1 :
                    must_be_same_alias(match[0]);
                    collect_binds(match[1], false);
                    if (Caml_obj.caml_equal(shortened_t, /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]))) {
                      return /* () */0;
                    } else {
                      vt[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]);
                      return /* () */0;
                    }
                case /* Record_readonly_of */2 :
                    var constrs_to_be_checked = Plate$BwaxAdmin.List.filter((function (param) {
                              var k = param[0];
                              if (param[2]) {
                                return true;
                              } else {
                                return Plate$BwaxAdmin.List.some((function (param) {
                                              return k === param[0];
                                            }), constraints);
                              }
                            }))(match[0]);
                    return collect_binds(Plate$BwaxAdmin.List.map((function (param) {
                                      return /* tuple */[
                                              param[0],
                                              param[1]
                                            ];
                                    }), constrs_to_be_checked), false);
                case /* Any_record_readonly */6 :
                    console.log("Don't know how to implement yet", Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, match[0]));
                    return /* () */0;
                default:
                  
              }
            }
            break;
        default:
          
      }
    }
    console.log("Not permitted", Lang_typing_print$BwaxAdmin.string_of_term(dts, t), Lang_typing_print$BwaxAdmin.string_of_term(dts, shortened_t));
    return Curry._1(not_permitted_subset, /* () */0);
  };
  var unify_record_readonly = function (holder, tname, subset, t) {
    var new_n = Curry._1(next_int, /* () */0);
    var the_record_type = do_resolve_alias(/* Term */Caml_chrome_debugger.variant("Term", 0, [
            tname,
            /* [] */0
          ]), dont_stop);
    var record_binds;
    var exit = 0;
    if (typeof the_record_type === "number" || the_record_type.tag !== /* Term_record */1) {
      exit = 1;
    } else {
      record_binds = the_record_type[0];
    }
    if (exit === 1) {
      var msg = "`" + (String(tname) + "` should be a record");
      throw [
            Unify_exc,
            msg
          ];
    }
    var not_permitted_subset = function (param) {
      var rsv = /* record */Caml_chrome_debugger.record([
          "var_type",
          "n",
          "t"
        ], [
          Caml_chrome_debugger.variant("Record_readonly", 1, [
              tname,
              subset
            ]),
          new_n,
          1
        ]);
      return not_permitted(dts, rsv, t);
    };
    var pack_subset = function (new_subset) {
      return /* record */Caml_chrome_debugger.record([
                "var_type",
                "n",
                "t"
              ], [
                Caml_chrome_debugger.variant("Record_readonly", 1, [
                    tname,
                    new_subset
                  ]),
                new_n,
                1
              ]);
    };
    return unify_readonly_subset(not_permitted_subset, holder, pack_subset, tname, record_binds, subset, t);
  };
  var unify_record_readonly_of = function (holder, constraints, subset, n, t) {
    var rsv = /* record */Caml_chrome_debugger.record([
        "var_type",
        "n",
        "t"
      ], [
        Caml_chrome_debugger.variant("Record_readonly_of", 2, [
            constraints,
            subset
          ]),
        n,
        1
      ]);
    var not_permitted_subset = function (param) {
      return not_permitted(dts, rsv, t);
    };
    var pack_subset = function (new_subset) {
      return /* record */Caml_chrome_debugger.record([
                "var_type",
                "n",
                "t"
              ], [
                Caml_chrome_debugger.variant("Record_readonly_of", 2, [
                    constraints,
                    new_subset
                  ]),
                n,
                1
              ]);
    };
    return unify_readonly_subset(not_permitted_subset, holder, pack_subset, undefined, constraints, subset, t);
  };
  var unify_superset_and_binds = function (superset, given_binds) {
    Plate$BwaxAdmin.List.for_each((function (param) {
            var k = param[0];
            var match = Plate$BwaxAdmin.List.assoc(k, given_binds);
            if (match !== undefined) {
              var transform = function (t) {
                var transform_bind = function (param) {
                  return /* tuple */[
                          param[0],
                          transform(param[1]),
                          param[2]
                        ];
                };
                var partial_arg = Plate$BwaxAdmin.List.map;
                var transform_binds = function (param) {
                  return partial_arg(transform_bind, param);
                };
                if (typeof t === "number") {
                  return t;
                } else {
                  switch (t.tag | 0) {
                    case /* Term */0 :
                        var name = t[0];
                        if (name === "Arrow") {
                          return t;
                        } else {
                          var transformed_ts = Plate$BwaxAdmin.List.map(transform, t[1]);
                          if (is_alias_to_record(name)) {
                            return Lang_typing_base$BwaxAdmin.record_writeonly(Curry._1(next_int, /* () */0), name, transformed_ts);
                          } else {
                            return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                                      name,
                                      transformed_ts
                                    ]);
                          }
                        }
                    case /* Term_record */1 :
                        return Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), Curry._1(transform_binds, t[0]));
                    default:
                      return t;
                  }
                }
              };
              return unify(next_int, dts, /* tuple */[
                          transform(param[1]),
                          match
                        ]);
            } else if (param[2]) {
              var msg = "`" + (String(k) + "` must be given.");
              throw [
                    Unify_exc,
                    msg
                  ];
            } else {
              return 0;
            }
          }), superset);
    return Plate$BwaxAdmin.List.for_each((function (param) {
                  var k = param[0];
                  if (Plate$BwaxAdmin.List.some((function (param) {
                            return Caml_obj.caml_equal(param[0], k);
                          }), superset)) {
                    return /* () */0;
                  } else {
                    var msg = "`" + (String(k) + "` is not allowed.");
                    throw [
                          Unify_exc,
                          msg
                        ];
                  }
                }), given_binds);
  };
  var unify_with_superset = function (original_var, superset, other_t) {
    if (typeof other_t === "number") {
      return not_permitted(dts, original_var, other_t);
    } else {
      switch (other_t.tag | 0) {
        case /* Term_record */1 :
            var al = Plate$BwaxAdmin.List.map((function (param) {
                    return /* tuple */[
                            param[0],
                            param[1]
                          ];
                  }), other_t[0]);
            return unify_superset_and_binds(superset, al);
        case /* Var */2 :
            var holder = other_t[0];
            var match = holder[/* var_type */0];
            if (typeof match === "number") {
              if (match === /* Any */0) {
                holder[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [original_var]);
                return /* () */0;
              } else {
                return not_permitted(dts, original_var, other_t);
              }
            } else {
              switch (match.tag | 0) {
                case /* Record_limited */3 :
                    return Plate$BwaxAdmin.List.for_each((function (t) {
                                  var resolved_t = do_resolve_alias(Lang_typing_base$BwaxAdmin.shorten(t), dont_stop);
                                  var binds;
                                  if (typeof resolved_t === "number") {
                                    binds = /* [] */0;
                                  } else {
                                    switch (resolved_t.tag | 0) {
                                      case /* Term_record */1 :
                                          binds = Plate$BwaxAdmin.List.map((function (param) {
                                                  return /* tuple */[
                                                          param[0],
                                                          param[1]
                                                        ];
                                                }), resolved_t[0]);
                                          break;
                                      case /* Var */2 :
                                          var match = resolved_t[0][/* var_type */0];
                                          binds = typeof match === "number" || match.tag ? /* [] */0 : match[0];
                                          break;
                                      default:
                                        binds = /* [] */0;
                                    }
                                  }
                                  return unify_superset_and_binds(superset, binds);
                                }), match[0]);
                case /* Record_writeonly_of */5 :
                    var constrs1 = superset;
                    var constrs2 = match[0];
                    var sort_by_key = function (l) {
                      var by_key = function (param, param$1) {
                        if (Caml_obj.caml_lessthan(param[0], param$1[0])) {
                          return -1;
                        } else {
                          return 1;
                        }
                      };
                      return Plate$BwaxAdmin.List.sort(by_key, l);
                    };
                    return Plate$BwaxAdmin.List.for_each((function (param) {
                                  var match = param[1];
                                  var n2 = match[0];
                                  var match$1 = param[0];
                                  var n1 = match$1[0];
                                  if (Caml_obj.caml_notequal(n1, n2)) {
                                    var msg = "`" + (String(n1) + ("` is not `" + (String(n2) + "`")));
                                    throw [
                                          Unify_exc,
                                          msg
                                        ];
                                  } else if (Caml_obj.caml_notequal(match$1[2], match[2])) {
                                    var msg$1 = "`" + (String(n1) + "`s should be both required. ");
                                    throw [
                                          Unify_exc,
                                          msg$1
                                        ];
                                  } else {
                                    return unify(next_int, dts, /* tuple */[
                                                match$1[1],
                                                match[1]
                                              ]);
                                  }
                                }), Plate$BwaxAdmin.List.combine(sort_by_key(constrs1), sort_by_key(constrs2)));
                default:
                  return not_permitted(dts, original_var, other_t);
              }
            }
        default:
          return not_permitted(dts, original_var, other_t);
      }
    }
  };
  var unify_record_writeonly_optional = function (original_var, tname, sons, other_t) {
    var match = do_resolve_alias(/* Term */Caml_chrome_debugger.variant("Term", 0, [
            tname,
            sons
          ]), dont_stop);
    var superset;
    var exit = 0;
    if (typeof match === "number" || match.tag !== /* Term_record */1) {
      exit = 1;
    } else {
      superset = match[0];
    }
    if (exit === 1) {
      var msg = "`" + (String(tname) + "` is not a record type alias.");
      throw [
            Unify_exc,
            msg
          ];
    }
    var superset_all_optional = Plate$BwaxAdmin.List.map((function (param) {
            return /* tuple */[
                    param[0],
                    param[1],
                    false
                  ];
          }), superset);
    return unify_with_superset(original_var, superset_all_optional, other_t);
  };
  var unify_record_writeonly = function (original_var, tname, sons, other_t) {
    var match = do_resolve_alias(/* Term */Caml_chrome_debugger.variant("Term", 0, [
            tname,
            sons
          ]), dont_stop);
    var superset;
    var exit = 0;
    if (typeof match === "number" || match.tag !== /* Term_record */1) {
      exit = 1;
    } else {
      superset = match[0];
    }
    if (exit === 1) {
      var msg = "`" + (String(tname) + "` is not a record type alias.");
      throw [
            Unify_exc,
            msg
          ];
    }
    if (typeof other_t === "number" || other_t.tag !== /* Var */2) {
      return unify_with_superset(original_var, superset, other_t);
    } else {
      var match$1 = other_t[0][/* var_type */0];
      if (typeof match$1 === "number" || match$1.tag !== /* Record_writeonly */4) {
        return unify_with_superset(original_var, superset, other_t);
      } else {
        var other_tname = match$1[0];
        var match$2 = do_resolve_alias(/* Term */Caml_chrome_debugger.variant("Term", 0, [
                other_tname,
                sons
              ]), dont_stop);
        var alias_to_same_type;
        alias_to_same_type = typeof match$2 === "number" || match$2.tag !== /* Term_record */1 ? false : Caml_obj.caml_equal(match$2[0], superset);
        if (tname !== other_tname && !alias_to_same_type) {
          return not_permitted(dts, original_var, other_t);
        } else {
          return Plate$BwaxAdmin.List.for_each((function (param) {
                        return unify(next_int, dts, param);
                      }), Plate$BwaxAdmin.List.zip(/* tuple */[
                          sons,
                          match$1[1]
                        ]));
        }
      }
    }
  };
  if (Caml_obj.caml_equal(a, b)) {
    return /* () */0;
  } else {
    var ra = Lang_typing_base$BwaxAdmin.shorten(do_resolve_alias(a, dont_stop));
    var rb = Lang_typing_base$BwaxAdmin.shorten(do_resolve_alias(b, dont_stop));
    var unify_any_record_readonly = function (vt, binds, holder, t, original_t) {
      var actual_vt = Lang_typing_base$BwaxAdmin.shorten(do_resolve_alias(vt, dont_stop));
      var constraints;
      var exit = 0;
      var binds$1;
      if (typeof actual_vt === "number") {
        constraints = undefined;
      } else {
        switch (actual_vt.tag | 0) {
          case /* Term_record */1 :
              binds$1 = actual_vt[0];
              exit = 1;
              break;
          case /* Var */2 :
              var match = actual_vt[0][/* var_type */0];
              if (typeof match === "number") {
                constraints = undefined;
              } else {
                switch (match.tag | 0) {
                  case /* Record_readonly_of */2 :
                      binds$1 = match[0];
                      exit = 1;
                      break;
                  case /* Any_record_readonly */6 :
                      constraints = match[1];
                      break;
                  default:
                    constraints = undefined;
                }
              }
              break;
          default:
            constraints = undefined;
        }
      }
      if (exit === 1) {
        constraints = Plate$BwaxAdmin.List.map((function (param) {
                return /* tuple */[
                        param[0],
                        param[1]
                      ];
              }), binds$1);
      }
      var get_binds = function (t) {
        var binds;
        if (typeof t === "number") {
          return ;
        } else {
          switch (t.tag | 0) {
            case /* Term_record */1 :
                binds = t[0];
                break;
            case /* Var */2 :
                var match = t[0][/* var_type */0];
                if (typeof match === "number") {
                  return ;
                } else {
                  switch (match.tag | 0) {
                    case /* Recordvar */0 :
                        return match[0];
                    case /* Record_readonly_of */2 :
                        binds = match[0];
                        break;
                    case /* Any_record_readonly */6 :
                        return match[1];
                    default:
                      return ;
                  }
                }
                break;
            default:
              return ;
          }
        }
        return Plate$BwaxAdmin.List.map((function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    }), binds);
      };
      var maybe_t_binds = get_binds(t);
      var unify_binds = function (param) {
        if (constraints !== undefined) {
          if (maybe_t_binds !== undefined) {
            unify_constraints(next_int, dts, constraints, maybe_t_binds);
            return /* () */0;
          }
          
        } else if (maybe_t_binds !== undefined) {
          var t_binds = maybe_t_binds;
          var exit = 0;
          if (typeof actual_vt === "number" || actual_vt.tag !== /* Var */2) {
            exit = 2;
          } else {
            var vt_holder = actual_vt[0];
            var match = vt_holder[/* var_type */0];
            if (typeof match === "number") {
              if (match === /* Any */0) {
                var exit$1 = 0;
                if (typeof t === "number" || t.tag !== /* Var */2) {
                  exit$1 = 3;
                } else {
                  vt_holder[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                          "var_type",
                          "n",
                          "t"
                        ], [
                          Caml_chrome_debugger.variant("Recordvar", 0, [t_binds]),
                          Curry._1(next_int, /* () */0),
                          1
                        ])]);
                  return /* () */0;
                }
                if (exit$1 === 3) {
                  console.log("Should I set original t", Lang_typing_print$BwaxAdmin.string_of_term(dts, original_t));
                  vt_holder[/* t */2] = t;
                  return /* () */0;
                }
                
              } else {
                exit = 2;
              }
            } else if (match.tag) {
              exit = 2;
            } else {
              var merged_binds = unify_intersection_and_merge(next_int, dts, match[0], t_binds);
              var exit$2 = 0;
              if (typeof t === "number" || t.tag !== /* Var */2) {
                exit$2 = 3;
              } else {
                vt_holder[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                        "var_type",
                        "n",
                        "t"
                      ], [
                        Caml_chrome_debugger.variant("Recordvar", 0, [merged_binds]),
                        Curry._1(next_int, /* () */0),
                        1
                      ])]);
                return /* () */0;
              }
              if (exit$2 === 3) {
                console.log("Should I set original t", Lang_typing_print$BwaxAdmin.string_of_term(dts, original_t));
                vt_holder[/* t */2] = t;
                return /* () */0;
              }
              
            }
          }
          if (exit === 2) {
            var msg = Lang_typing_print$BwaxAdmin.string_of_term(dts, vt) + (" is not unified with # at " + Lang_typing_print$BwaxAdmin.string_of_term(dts, t));
            throw [
                  Unify_exc,
                  msg
                ];
          }
          
        }
        if (typeof t === "number" || t.tag !== /* Var */2) {
          return not_the_same(dts, ra, rb);
        } else {
          var match$1 = t[0][/* var_type */0];
          if (typeof match$1 === "number" && match$1 === 0) {
            return /* () */0;
          } else {
            return not_the_same(dts, ra, rb);
          }
        }
      };
      unify_binds(/* () */0);
      var t_binds = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, maybe_t_binds);
      var merged_binds = unify_intersection_and_merge(next_int, dts, binds, t_binds);
      var new_t = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
              "var_type",
              "n",
              "t"
            ], [
              Caml_chrome_debugger.variant("Any_record_readonly", 6, [
                  vt,
                  merged_binds
                ]),
              Curry._1(next_int, /* () */0),
              1
            ])]);
      holder[/* t */2] = new_t;
      if (typeof t === "number" || t.tag !== /* Var */2) {
        return /* () */0;
      } else {
        var in_scope_t = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                "var_type",
                "n",
                "t"
              ], [
                Caml_chrome_debugger.variant("Any_record_readonly", 6, [
                    vt,
                    t_binds
                  ]),
                Curry._1(next_int, /* () */0),
                1
              ])]);
        t[0][/* t */2] = in_scope_t;
        return /* () */0;
      }
    };
    if (Caml_obj.caml_equal(ra, rb)) {
      return /* () */0;
    } else {
      var v;
      var t;
      var exit = 0;
      var exit$1 = 0;
      var exit$2 = 0;
      var exit$3 = 0;
      var exit$4 = 0;
      var exit$5 = 0;
      var exit$6 = 0;
      var exit$7 = 0;
      var exit$8 = 0;
      var exit$9 = 0;
      var exit$10 = 0;
      var exit$11 = 0;
      var exit$12 = 0;
      var exit$13 = 0;
      var exit$14 = 0;
      if (typeof ra === "number") {
        exit$14 = 16;
      } else {
        switch (ra.tag | 0) {
          case /* Term */0 :
              var s1 = ra[1];
              if (typeof rb === "number") {
                return not_the_same(dts, ra, rb);
              } else {
                switch (rb.tag | 0) {
                  case /* Term */0 :
                      var s2 = rb[1];
                      if (ra[0] === rb[0] && Plate$BwaxAdmin.List.length(s1) === Plate$BwaxAdmin.List.length(s2)) {
                        return Plate$BwaxAdmin.List.for_each((function (param) {
                                      return unify(next_int, dts, param);
                                    }), Plate$BwaxAdmin.List.combine(s1, s2));
                      } else {
                        return not_the_same(dts, ra, rb);
                      }
                  case /* Var */2 :
                      exit$14 = 16;
                      break;
                  default:
                    return not_the_same(dts, ra, rb);
                }
              }
              break;
          case /* Term_record */1 :
              if (typeof rb === "number") {
                return not_the_same(dts, ra, rb);
              } else {
                switch (rb.tag | 0) {
                  case /* Term_record */1 :
                      var al1 = Plate$BwaxAdmin.List.map((function (param) {
                              return /* tuple */[
                                      param[0],
                                      param[1]
                                    ];
                            }), ra[0]);
                      var al2 = Plate$BwaxAdmin.List.map((function (param) {
                              return /* tuple */[
                                      param[0],
                                      param[1]
                                    ];
                            }), rb[0]);
                      if (Plate$BwaxAdmin.List.length(al1) === Plate$BwaxAdmin.List.length(al2)) {
                        return unify_bindings(next_int, dts, al1, al2);
                      } else {
                        var match = Plate$BwaxAdmin.List.length(al1) > Plate$BwaxAdmin.List.length(al2) ? /* tuple */[
                            al1,
                            al2
                          ] : /* tuple */[
                            al2,
                            al1
                          ];
                        var match$1 = Plate$BwaxAdmin.List.foldl((function (param, param$1) {
                                var k = param$1[0];
                                var ms = param[1];
                                var rs = param[0];
                                if (Plate$BwaxAdmin.List.contains(k, rs)) {
                                  return /* tuple */[
                                          Plate$BwaxAdmin.List.filter((function (e) {
                                                    return e !== k;
                                                  }))(rs),
                                          ms
                                        ];
                                } else {
                                  return /* tuple */[
                                          rs,
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              k,
                                              ms
                                            ])
                                        ];
                                }
                              }), /* tuple */[
                              Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.fst, match[0]),
                              /* [] */0
                            ], match[1]);
                        if (Plate$BwaxAdmin.List.length(match$1[1]) === 0) {
                          var msg = "Missing " + (Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (s) {
                                        return "`" + (s + "`");
                                      }), match$1[0])) + " fields.");
                          throw [
                                Unify_exc,
                                msg
                              ];
                        } else {
                          return not_the_same(dts, ra, rb);
                        }
                      }
                  case /* Var */2 :
                      exit$14 = 16;
                      break;
                  default:
                    return not_the_same(dts, ra, rb);
                }
              }
              break;
          case /* Var */2 :
              var holder = ra[0];
              var match$2 = holder[/* var_type */0];
              if (typeof match$2 === "number" || match$2.tag !== /* Record_readonly */1) {
                exit$14 = 16;
              } else {
                return unify_record_readonly(holder, match$2[0], match$2[1], b);
              }
              break;
          
        }
      }
      if (exit$14 === 16) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$13 = 15;
        } else {
          var holder$1 = rb[0];
          var match$3 = holder$1[/* var_type */0];
          if (typeof match$3 === "number" || match$3.tag !== /* Record_readonly */1) {
            exit$13 = 15;
          } else {
            return unify_record_readonly(holder$1, match$3[0], match$3[1], a);
          }
        }
      }
      if (exit$13 === 15) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit$12 = 14;
        } else {
          var holder$2 = ra[0];
          var match$4 = holder$2[/* var_type */0];
          if (typeof match$4 === "number" || match$4.tag !== /* Record_readonly_of */2) {
            exit$12 = 14;
          } else {
            return unify_record_readonly_of(holder$2, match$4[0], match$4[1], holder$2[/* n */1], b);
          }
        }
      }
      if (exit$12 === 14) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$11 = 13;
        } else {
          var holder$3 = rb[0];
          var match$5 = holder$3[/* var_type */0];
          if (typeof match$5 === "number" || match$5.tag !== /* Record_readonly_of */2) {
            exit$11 = 13;
          } else {
            return unify_record_readonly_of(holder$3, match$5[0], match$5[1], holder$3[/* n */1], a);
          }
        }
      }
      if (exit$11 === 13) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit$10 = 12;
        } else {
          var holder$4 = ra[0];
          var match$6 = holder$4[/* var_type */0];
          if (typeof match$6 === "number" || match$6.tag !== /* Any_record_readonly */6) {
            exit$10 = 12;
          } else {
            return unify_any_record_readonly(match$6[0], match$6[1], holder$4, rb, b);
          }
        }
      }
      if (exit$10 === 12) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$9 = 11;
        } else {
          var holder$5 = rb[0];
          var match$7 = holder$5[/* var_type */0];
          if (typeof match$7 === "number" || match$7.tag !== /* Any_record_readonly */6) {
            exit$9 = 11;
          } else {
            return unify_any_record_readonly(match$7[0], match$7[1], holder$5, ra, a);
          }
        }
      }
      if (exit$9 === 11) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit$8 = 10;
        } else {
          var original_var = ra[0];
          var match$8 = original_var[/* var_type */0];
          if (typeof match$8 === "number" || match$8.tag !== /* Record_writeonly */4) {
            exit$8 = 10;
          } else {
            return unify_record_writeonly(original_var, match$8[0], match$8[1], rb);
          }
        }
      }
      if (exit$8 === 10) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$7 = 9;
        } else {
          var original_var$1 = rb[0];
          var match$9 = original_var$1[/* var_type */0];
          if (typeof match$9 === "number" || match$9.tag !== /* Record_writeonly */4) {
            exit$7 = 9;
          } else {
            return unify_record_writeonly(original_var$1, match$9[0], match$9[1], ra);
          }
        }
      }
      if (exit$7 === 9) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit$6 = 8;
        } else {
          var original_var$2 = ra[0];
          var match$10 = original_var$2[/* var_type */0];
          if (typeof match$10 === "number" || match$10.tag !== /* Record_writeonly_optional */7) {
            exit$6 = 8;
          } else {
            return unify_record_writeonly_optional(original_var$2, match$10[0], match$10[1], rb);
          }
        }
      }
      if (exit$6 === 8) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$5 = 7;
        } else {
          var original_var$3 = rb[0];
          var match$11 = original_var$3[/* var_type */0];
          if (typeof match$11 === "number" || match$11.tag !== /* Record_writeonly_optional */7) {
            exit$5 = 7;
          } else {
            return unify_record_writeonly_optional(original_var$3, match$11[0], match$11[1], ra);
          }
        }
      }
      if (exit$5 === 7) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit$4 = 6;
        } else {
          var original_var$4 = ra[0];
          var match$12 = original_var$4[/* var_type */0];
          if (typeof match$12 === "number" || match$12.tag !== /* Record_writeonly_of */5) {
            exit$4 = 6;
          } else {
            return unify_with_superset(original_var$4, match$12[0], rb);
          }
        }
      }
      if (exit$4 === 6) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$3 = 5;
        } else {
          var original_var$5 = rb[0];
          var match$13 = original_var$5[/* var_type */0];
          if (typeof match$13 === "number" || match$13.tag !== /* Record_writeonly_of */5) {
            exit$3 = 5;
          } else {
            return unify_with_superset(original_var$5, match$13[0], ra);
          }
        }
      }
      if (exit$3 === 5) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit$2 = 4;
        } else {
          var holder$6 = ra[0];
          var match$14 = holder$6[/* var_type */0];
          if (typeof match$14 === "number" || match$14.tag) {
            exit$2 = 4;
          } else {
            return unify_record_var(next_int, dts, holder$6, match$14[0], rb);
          }
        }
      }
      if (exit$2 === 4) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          exit$1 = 3;
        } else {
          var holder$7 = rb[0];
          var match$15 = holder$7[/* var_type */0];
          if (typeof match$15 === "number" || match$15.tag) {
            exit$1 = 3;
          } else {
            return unify_record_var(next_int, dts, holder$7, match$15[0], ra);
          }
        }
      }
      if (exit$1 === 3) {
        if (typeof ra === "number" || ra.tag !== /* Var */2) {
          exit = 2;
        } else {
          var v1 = ra[0];
          if (typeof rb === "number" || rb.tag !== /* Var */2) {
            v = v1;
            t = rb;
          } else {
            return unify_vars(next_int, dts, v1, rb[0]);
          }
        }
      }
      if (exit === 2) {
        if (typeof rb === "number" || rb.tag !== /* Var */2) {
          return not_the_same(dts, ra, rb);
        } else {
          v = rb[0];
          t = ra;
        }
      }
      if (occurs(v, t)) {
        return /* () */0;
      } else if (var_permitting(v)(t)) {
        v[/* t */2] = t;
        return /* () */0;
      } else {
        return not_permitted(dts, v, t);
      }
    }
  }
}

function unify_record_var(next_int, dts, holder, constraints, t) {
  if (typeof t === "number") {
    return not_permitted(dts, holder, t);
  } else {
    switch (t.tag | 0) {
      case /* Term_record */1 :
          var binds = t[0];
          var al = Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          param[1]
                        ];
                }), binds);
          unify_constraints(next_int, dts, al, constraints);
          holder[/* t */2] = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds]);
          return /* () */0;
      case /* Var */2 :
          var ov = t[0];
          var match = ov[/* var_type */0];
          if (typeof match === "number") {
            if (match === /* Any */0) {
              var ht = /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]);
              if (occurs(ov, ht)) {
                return /* () */0;
              } else {
                ov[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [holder]);
                return /* () */0;
              }
            } else {
              return not_permitted(dts, holder, t);
            }
          } else if (match.tag) {
            return not_permitted(dts, holder, t);
          } else {
            var match$1 = Plate$BwaxAdmin.List.assoc_intersect(constraints, match[0]);
            var match$2 = match$1[0];
            var c1 = match$2[0];
            unify_bindings(next_int, dts, c1, match$2[1]);
            var new_recordvar = Lang_typing_base$BwaxAdmin.recordvar(Curry._1(next_int, /* () */0), Pervasives.$at(c1, Pervasives.$at(match$1[1], match$1[2])));
            holder[/* t */2] = new_recordvar;
            ov[/* t */2] = new_recordvar;
            return /* () */0;
          }
      default:
        return not_permitted(dts, holder, t);
    }
  }
}

function unify_intersection_and_merge(next_int, dts, a_binds, b_binds) {
  var a_unified_b = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var at = param[1];
          var k = param[0];
          var match = Plate$BwaxAdmin.List.assoc(k, b_binds);
          if (match !== undefined) {
            unify(next_int, dts, /* tuple */[
                  at,
                  match
                ]);
            return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            k,
                            at
                          ],
                          /* [] */0
                        ]));
          } else {
            return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            k,
                            at
                          ],
                          /* [] */0
                        ]));
          }
        }), /* [] */0, a_binds);
  var b_minus_a = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var k = param[0];
          var match = Plate$BwaxAdmin.List.assoc(k, a_binds);
          if (match !== undefined) {
            return acc;
          } else {
            return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            k,
                            param[1]
                          ],
                          /* [] */0
                        ]));
          }
        }), /* [] */0, b_binds);
  return Pervasives.$at(a_unified_b, b_minus_a);
}

function unify_constraints(next_int, dts, binds, constraints) {
  Plate$BwaxAdmin.List.for_each((function (param) {
          var k = param[0];
          var match = Plate$BwaxAdmin.List.assoc(k, binds);
          if (match !== undefined) {
            return unify(next_int, dts, /* tuple */[
                        param[1],
                        match
                      ]);
          } else {
            throw [
                  Unify_exc,
                  "It needs `" + (k + "` field.")
                ];
          }
        }), constraints);
  return Curry._2(Plate$BwaxAdmin.List.partition, (function (param) {
                  return Curry._1(Plate$BwaxAdmin.$$Option.is_some, Plate$BwaxAdmin.List.assoc(param[0], constraints));
                }), binds)[1];
}

function unify_bindings(next_int, dts, binds1, binds2) {
  return Plate$BwaxAdmin.List.for_each((function (param) {
                var match = param[1];
                var n2 = match[0];
                var match$1 = param[0];
                var n1 = match$1[0];
                if (n1 !== n2) {
                  throw [
                        Unify_exc,
                        "`" + (String(n1) + ("` is not `" + (String(n2) + "`")))
                      ];
                }
                return unify(next_int, dts, /* tuple */[
                            match$1[1],
                            match[1]
                          ]);
              }), Plate$BwaxAdmin.List.combine(Plate$BwaxAdmin.List.sort_by_key(binds1), Plate$BwaxAdmin.List.sort_by_key(binds2)));
}

function unify_vars(next_int, dts, a, b) {
  if (Caml_obj.caml_equal(a, b)) {
    return /* () */0;
  } else {
    var avt = a[/* var_type */0];
    var bvt = b[/* var_type */0];
    if (Caml_obj.caml_equal(avt, bvt)) {
      a[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [b]);
      return /* () */0;
    } else {
      var exit = 0;
      if (typeof avt === "number") {
        switch (avt) {
          case /* Any */0 :
              a[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [b]);
              return /* () */0;
          case /* Number */1 :
              if (typeof bvt === "number") {
                if (bvt !== 3) {
                  if (bvt === 0) {
                    exit = 2;
                  }
                  
                } else {
                  b[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [a]);
                  return /* () */0;
                }
              }
              break;
          case /* Comparable */3 :
              if (typeof bvt === "number") {
                if (bvt !== 1) {
                  if (bvt === 0) {
                    exit = 2;
                  }
                  
                } else {
                  a[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [b]);
                  return /* () */0;
                }
              }
              break;
          default:
            exit = 2;
        }
      } else {
        switch (avt.tag | 0) {
          case /* Record_limited */3 :
              if (typeof bvt === "number") {
                if (bvt === /* Any */0) {
                  exit = 2;
                }
                
              } else if (bvt.tag === /* Record_limited */3) {
                var combined = Pervasives.$at(avt[0], bvt[0]);
                var t = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                        "var_type",
                        "n",
                        "t"
                      ], [
                        Caml_chrome_debugger.variant("Record_limited", 3, [combined]),
                        Curry._1(next_int, /* () */0),
                        1
                      ])]);
                a[/* t */2] = t;
                b[/* t */2] = t;
                return /* () */0;
              }
              break;
          case /* Opaqued */8 :
              if (typeof bvt === "number") {
                if (bvt === /* Any */0) {
                  exit = 2;
                }
                
              } else if (bvt.tag === /* Opaqued */8) {
                return /* () */0;
              }
              break;
          default:
            exit = 2;
        }
      }
      if (exit === 2 && typeof bvt === "number" && bvt === 0) {
        b[/* t */2] = /* Var */Caml_chrome_debugger.variant("Var", 2, [a]);
        return /* () */0;
      }
      throw [
            Unify_exc,
            "Incompatible vars: " + (Lang_typing_print$BwaxAdmin.string_of_var(dts, a) + (" and " + Lang_typing_print$BwaxAdmin.string_of_var(dts, b)))
          ];
    }
  }
}

var print = Lang_typing_print$BwaxAdmin.string_of_term;

exports.Unify_exc = Unify_exc;
exports.occurs = occurs;
exports.any_permitting = any_permitting;
exports.number_permitting = number_permitting;
exports.appendable_permitting = appendable_permitting;
exports.comparable_permitting = comparable_permitting;
exports.compappend_permitting = compappend_permitting;
exports.recordvar_permitting = recordvar_permitting;
exports.var_permitting = var_permitting;
exports.print = print;
exports.not_permitted = not_permitted;
exports.not_the_same = not_the_same;
exports.unify = unify;
exports.unify_record_var = unify_record_var;
exports.unify_intersection_and_merge = unify_intersection_and_merge;
exports.unify_constraints = unify_constraints;
exports.unify_bindings = unify_bindings;
exports.unify_vars = unify_vars;
/* Plate-BwaxAdmin Not a pure module */
