
import React, { useState, useContext, useEffect } from 'react'


import FieldOptionsInput from 'Client/js/ui/widgets/input/FieldOptionsInput';

import FieldDefaultValueInput from 'Client/js/ui/widgets/input/FieldDefaultValueInput';

import './AddRecordButton.less'

import ValidationSourcing from 'Client/js/ui/widgets/ValidationSourcing'

import getFieldsToUse, { getFunctionDeps } from '../helpers/getFieldsToUse';

import admin_widget_helper from '../helpers/admin_widget_helper.bs'

import Modal from './Modal';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import { getFieldInput } from 'Client/js/builders/input/fieldInputs';

import QueryTargetContext from 'bwax-ui/legacy/store/QueryTargetContext';

import Button from './Button';

import applyBoolFn from '../helpers/applyBoolFn';

import FieldTip from 'Client/js/components/FieldTip';

export default function AddRecordButton(props) {

    const { buttonConfig, actionConfig, dataEntity, reload, context } = props;

    const { bwax, dts } = context;
    const allEntities = bwax.entities;
    const allDataTypes = bwax.dataTypes;

    const queryTarget = useContext(QueryTargetContext) || "data";

    const entityName = dataEntity.name;

    const { label } = buttonConfig;

    const { fields } = dataEntity


    const {
        initialValues: passedInitialValues,
        fixedValues: passedInFixedValues,
        defaultValues: passedInDefaultValues,

        excludedFields,

        items,

        // popupType -- 暂时只支持 Modal 先

    } = (actionConfig || [{}])[0];

    const getValuesFromDataForm = form => form ? form[1]: {};

    const fixedValues = getValuesFromDataForm(passedInFixedValues);
    const baseDefaulValues = getValuesFromDataForm(passedInDefaultValues);
    const initialValues = getValuesFromDataForm(passedInitialValues);

    /// fieldsToUse; 考虑的优先级是 fieldItems > includedFields > excludedFields.

    const rawFieldsToUse = getFieldsToUse(dataEntity, { fieldItems: items, excludedFields, allEntities, allDataTypes }).filter(f => f.initializable);

    const missingRequiredFields = fields.filter(f => {
        if (f.required && f.initializable && (f.options.default == undefined || f.defaultValue == undefined)) {
            return fixedValues[f.name] === undefined && !rawFieldsToUse.some(
                rf => rf.name == f.name
            )
        } else {
            return false
        }
    });

    const fieldsToUse = [...missingRequiredFields, ...rawFieldsToUse];

    /* --- 关于 validation reference 到 关联字段
        遍历 fieldsToUse 收集到所有的这种 validation 源
            对于创建的表单，由于关联数据还不存在，
            因此我们需要 1）由专门的 Widget 来根据 Input 值去后台获取 validation 源的值；
                    2）放进特定的 PageState 里
                    3) 这样

            对于编辑的表单，因为关联数据可能不会进入编辑状态，那么专门的 Widget 也需要根据当前记录去获取专门的 validation 源的值
                也许不需要他去获取，只要在初始阶段展开 Query 获取到对应的 validation 源的值就好
    
    */

    const allValidations = fieldsToUse.filter(f => f.options && f.options.validation).map(f => {
        return [f.options.validation, f]
    });

    // 使用 name 而不是 cname
    const allKeys = fieldsToUse.map(f => f.name);


    const defaultValues = {
        ...(fieldsToUse.reduce((acc, f) => {
            const { name, defaultValue } = f;
            if (defaultValue !== undefined && defaultValue !== null) {
                return {
                    ...acc,
                    [name]: defaultValue
                }
            } else {
                return acc
            }
        }, {})),
        ...baseDefaulValues
    }

    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, defaultValues, [], "no-key", false)

    const [validationValues, setValidationValues] = useState({});

    const formItems = fieldsToUse.map(f => {
        return <FieldItem key={f.name} { 
            ...{ field: f, editing, validated, initialValues, fixedValues, defaultValues, updateEditing, errors,
                validationValues, bwax, dts, dataEntity, queryTarget,
            }
        } />
    })

    const [visible, setVisible] = useState(false)

    const [submitting, setSubmitting] = useState(false);

    // const [actionMenuVisible, setActionMenuVisible] = useState(false)

    const button = (
        <Button {...{
            ...buttonConfig,
            onClick: () => {
                setVisible(true)
            }
        }} />
    );

    let form = (
        <div className="add-record-button-form">
            { formItems }
        </div>
    )

    const validationSourcing = (
        <ValidationSourcing
            params={{
                validations: allValidations,
                entityName,
                updateValidationValues: values => {
                    setValidationValues(prev => ({
                        ...prev,
                        ...values
                    }))
                },
                formValues: validated
            }}
            allEntities={allEntities}
            allDataTypes={allDataTypes}
        />
    )


    const involvedEditKeys = fieldsToUse.filter(
        /// TODO 关于 fixedValue 的处理，应该做一个更好的抽象：
        /// 目前，fixedValue 在如下四个地方皆有处理：
        ///     1. 外部检测 dirtyValue
        ///     2. useEditingValue
        f => fixedValues[f.name] === undefined
    ).map(f => f.name)

    const hasDirtyValue = involvedEditKeys.some(key => {
        return dirtyValues[key] !== undefined && dirtyValues[key] !== defaultValues[key]
    })

    // all the require field must be there:
    const requiredFields = fieldsToUse.filter(
        f => f.required
    )
    const requiredEditKeys = requiredFields.map(f => f.name);

    const isMissingRequiredFields = requiredEditKeys.some(key => validated[key] === undefined)

    const noValues = involvedEditKeys.every(key => dirtyValues[key] === undefined)

    const content = (
        <Modal
            title={label}
            size={"normal"}
            visible={visible}
            // onOk={() => {
            //     setEditModalVisible(false)
            // }}
            destroyOnClose={true}
            onCancel={() => {
                // 如果 dirty 的话，需要提示：
                if (hasDirtyValue) {
                    Modal.confirm({
                        title: '您有未保存的内容',
                        content: '确定要关闭吗？',
                        okText: '关闭',
                        cancelText: '等等',
                        className: "admin--edit-modal-confirm",
                        onOk: () => {
                            rollback(involvedEditKeys);
                            setVisible(false);
                        }
                    });
                } else {
                    setVisible(false)
                }
            }}
            footer={[
                {
                    // 关闭或取消
                    label: (hasDirtyValue ? "撤销" : "关闭"),
                    // buttonType: ,
                    // disabled,
                    onClick: () => {
                        if (hasDirtyValue) {
                            // 撤销要用 reset，
                            // 因为 rollback 的实现没有考虑 defaultValues 
                            reset(defaultValues, initialValues, fixedValues)
                            // rollback(involvedEditKeys);
                        } else {
                            setVisible(false);
                        }

                    }
                    // 
                },
                {
                    // 保存按钮
                    label: "保存",
                    buttonType: "primary",
                    disabled: isMissingRequiredFields || noValues,
                    loading: submitting,
                    onClick: async () => {

                        if (hasDirtyValue) {

                            // 这里可能要考虑借助 Bwax 的那些 entity
                            const formData = fieldsToUse.reduce((acc, f) => {
                                const { name } = f
                                const v = dirtyValues[name];
                                return {
                                    ...acc,
                                    ...(v !== undefined ? { [name]: v } : {})
                                } 
                            }, {});

                            const mutationObj = {
                                entityName: dataEntity.name,
                                formData: {
                                    ...formData,
                                    ...fixedValues,
                                },
                                fieldPaths: []
                            }

                            setSubmitting(true)

                            const [result, error] = await bwax.add(mutationObj);
                        
                            // reload

                            setSubmitting(false)
            
                            await reload();
            
                            setVisible(false);
                            clearEditing(involvedEditKeys);
            
                            // 3秒钟之后，再 reload 一次 
                            setTimeout(() => {
                                reload();
                            }, 3000)

                            // reload


                        }

                        setVisible(false)
                    }
                }
            ].map(params => <Button key={params.label} {...params} />)}

        >    
            {validationSourcing}
            <div style={{
                padding: "24px 24px 64px",
            }}>
                {form}
            </div>
        </Modal>
    )

    return <>{button} {content}</>

}


function FieldItem ({ 
    field, validated, editing, fixedValues, initialValues, errors, validationValues, updateEditing,
    bwax, dts, 
    dataEntity, queryTarget,
}) {

    const allEntities = bwax.entities;
    const allDataTypes = bwax.dataTypes;

    const { cname: _, name, key, desc, config = {}, options = {} } = field;

    const entityName = dataEntity.name ;

    /// add value, onChange
    const value = fixedValues[name] || editing[name];

    const onChange = v => {
        if (v !== value) {
            updateEditing({ [name]: v })
        }
    }


    const [ isApplicable, setIsApplicable ] = useState(() => {
        const { applicable } = config;
        if(applicable) {
            // 如果有 applicable 函数则初始为 false
            return false 
        } else {
            return true
        }
    })

    // 
    function updateApplicable(isApplicable) {
        setIsApplicable(isApplicable)
        if(!isApplicable) {
            onChange(undefined);
        }
    }


    useEffect(() => {
        const { applicable } = config;
        if(applicable) {

            (async () => {
                // 1. 先确定 applicable 依赖的记录字段：
                //  如果它依赖于 id，则直接判定为 False （这就意味着 id 是否被依赖需要明确）
                // 如果依赖的是虚拟字段（或者不可初始化的字段），也直接判定为 False
                //  如果它依赖于某个必填字段（包括 id），而这个字段还没有被填入，则直接判定为 False
                //  如果它依赖于某个非必填字段，而这个字段还没有被填入，则使用 Nothing 代替
                //  如果依赖于展开的 Link，那么我应该通过自动发送请求获得对应的 Link，并塞进记录里

                // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/689
    
                const deps = getFunctionDeps(applicable, { entityName, bwax, dts })
    
                // get the depedency tree: 
                const tree = deps && deps[0] && deps[0].dep ? deps[0].dep[0] : {};

                // 1) 如果它依赖于 id，则直接判定为 False 
                if(tree["id"]) {
                    console.log("ID is not satifiable")
                    updateApplicable(false);
                    return 
                } 

                // 2) 如果依赖的是虚拟字段（或者不可初始化的字段），也直接判定为 False

                const { id: _, ...others } = tree;
                // 只有 initializable 的 field 才 valid
                const [ fields, invalidNames ] = Object.keys(others).reduce(([ acc, invalids], key) => {
                    const field = dataEntity.fields.find(f => admin_widget_helper.normalize_field_name(f.name) == key );
                    if(field && field.initializable ) {
                        return [ [ ...acc, field], invalids ]
                    } else {
                        return [ acc,  [ ...invalids, key ]]
                    }
                }, [[], []]);
                
                if(invalidNames.length > 0) {
                    console.log("These fields are not satisfiable:", invalidNames);
                    updateApplicable(false)
                    return 
                } 
                
                // 3) 如果它依赖于某个必填字段，而这个字段还没有被填入，则直接判定为 False
                for(const field of fields) {
                    if(field.required && validated[field.name] === undefined) {
                        updateApplicable(false)
                        return 
                    }
                }

                // TODO 暂时没支持 Link：
                for(const field of fields) {
                    if(field.type === "Link") {
                        updateApplicable(false)
                        console.warn("还没支持 applicable 里面的 Link")
                        return 
                    }
                }

                // 4) 如果它依赖于某个非必填字段，而这个字段还没有被填入，则使用 Nothing 代替
                // 5) 如果依赖于展开的 Link，那么我应该通过自动发送请求获得对应的 Link，并塞进记录里
                let rawValue = {}
                for(const field of fields) {
                    const v = validated[field.name];
                    rawValue[field.name] = v !== undefined ? v : null;
                }

                const result = applyBoolFn(applicable, rawValue,  { bwax, entityName });

                updateApplicable(result);

            })()

        }

    }, [ JSON.stringify(validated)])


    const customInput = (() => {
        // 这里是硬写的。 TODO 根据相关值编辑， 需要有一个更理想的解决方案
        if (name == "字段选项" && key == "options") {
            return (value, onChange, params) => (
                <FieldOptionsInput
                    {...{
                        dataType: validated.字段类型, 
                        value, onChange
                    }}
                />
            )

        } else if (name == "默认值" && key == "defaultValue") {

            return (value, onChange, params) => (
                <FieldDefaultValueInput
                    params={{
                        ...params,
                        dataType: validated.字段类型,
                        dataOptions: validated.字段选项,
                        dataMultivalued: validated.多值,
                    }}
                    value={value}
                    onChange={onChange}
                />
            );
        } else if (config.customInput) {
            return config.customInput
        }

    })();

    function prepareValidation() {
        if (options.validation) {
            // 还有 list, ref, lookup, ...
            const { list } = options.validation
            function getLimitedValues() {
                if (list) {
                    return list
                } else {
                    return validationValues[field.name]
                }
            }
            return {
                limitedValues: getLimitedValues()
            }

        } else {
            return {}
        }
    };

    const specificTypeParams = {
        "Image": {
            width: 120,
            height: 120,
            processor: 'medium'
        }
    }


    function getInput() {

        const editKey = name;

        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/542
        const inputParams = {
            ...(config && config.inputParams ? config.inputParams : {}),
            ...(prepareValidation())
        };

        const ips = {
            initialValue: initialValues[name],
            fixedValue: fixedValues[name],
            ...(specificTypeParams[field.type] || {}),
            ...inputParams

        };

        let inputWidget = customInput || getFieldInput(
            field,
            editKey,
            {
                allDataTypes, allEntities, dataEntity,
                queryTarget
            }
        );


        return inputWidget(
            value,
            onChange,           
            ips,
            validated
        );

    }

    // render input field below:
    const label = name;
    const tip = (desc && desc !== name) ? desc : null;
    const editKey = field.name;
    const required = field.required;


    const input = getInput();

    const error = (errors || {})[editKey]
    
    if (!isApplicable) {
        return null
    }

    return (
        <div className="form-item" key={editKey}>
            <div className={`form-item-label ${required ? 'item-require' : ''}`}>
                {label}
                <FieldTip tip={tip} />
            </div>
            <div className="form-item-input">
                { input }
                {/* TODO: special error handle */}
                {
                    error ?
                        <p className="error-message-cot">
                            {error}
                        </p> : null
                }
            </div>

        </div>
    )

}
 


