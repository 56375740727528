
// load sandbox definition from design endpoint.

import { runDefinitionQuery } from 'bwax/query/runClientQuery'

const QueryText = `
query a($code: String!, $multitenantType: SandboxDefinitionMultitenantTypeInput) {
    sandboxDefinition(multitenantType: $multitenantType) {
      application(code: $code) {
        entities
        dataTypes
        lookupValueSettings
        pageComponents {
          name
          ioTypeMetas
        }
        adminPages {
          name
          ioTypeMetas
        }
      }
    }
}
`

export default function loadSandboxDefinitions(applicationCode, multitenantType) {
    return async ({ tenantCode, endpoints, sessionToken }) => {

        const runner = runDefinitionQuery;

        const resultText = await runner({
          tenantCode, endpoints, sessionToken,
        })(QueryText)({ code: applicationCode, multitenantType })

        const result = JSON.parse(resultText);
        const { data, errors } = result;
        if (errors) {
            /// error handling
            return {}
        } else {

            const { entities, dataTypes, adminPages, pageComponents } = data.sandboxDefinition.application;

            return {
                allEntities: entities, allDataTypes: dataTypes, adminPages, pageComponents
            }
        }


    }
}
