// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Palette = require("bwax-ui/color/Palette");
var Widget_common$BwaxAdmin = require("./widget_common.bs.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var HtmlColors = require("bwax-ui/color/htmlColors");
var LightOrDark = require("bwax-ui/color/lightOrDark");

function is_light(s) {
  return LightOrDark.default(s) === "light";
}

function is_dark(s) {
  return LightOrDark.default(s) !== "light";
}

var all_codes = Plate$BwaxAdmin.List.map((function (param) {
        var prefix = param[3];
        var lc = Plate$BwaxAdmin.Str.to_lower(prefix);
        return Plate$BwaxAdmin.List.map((function (l) {
                      var append = function (p) {
                        return p + (Plate$BwaxAdmin.Str.from_int(l) + "0");
                      };
                      return /* tuple */[
                              append(lc),
                              append(prefix)
                            ];
                    }), Curry._1(Plate$BwaxAdmin.List.from_array, Palette.levels));
      }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          undefined,
          undefined,
          undefined,
          "N"
        ],
        Curry._1(Plate$BwaxAdmin.List.from_array, Palette.baseColorSetting)
      ]));

var code_src = Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.map((function (codes) {
            return Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (param) {
                              return param[0] + (" = raw \"" + (param[1] + "\";"));
                            }), codes));
          }), all_codes));

var html_colors = Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (param) {
            return "-- " + (Plate$BwaxAdmin.Str.cap(param[0]) + (" colors\n" + (Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (c) {
                                    return Plate$BwaxAdmin.Str.decap(c) + " = external;";
                                  }), Curry._1(Plate$BwaxAdmin.List.from_array, param[1]))) + "\n")));
          }), Curry._1(Plate$BwaxAdmin.List.from_array, Js_dict.entries(HtmlColors.default()))));

var cs = Plate$BwaxAdmin.List.flat_map(Plate$BwaxAdmin.List.from_array, Curry._1(Plate$BwaxAdmin.List.from_array, Js_dict.values(HtmlColors.default())));

var c_srcs = Plate$BwaxAdmin.List.map((function (c) {
        return "(\"" + (c + ("\", " + (Plate$BwaxAdmin.Str.decap(c) + ")")));
      }), cs);

var html_color_list = "htmlColors = \n  [" + (Plate$BwaxAdmin.Str.join("\n  ,", c_srcs) + "\n  ];");

var module_name = "Color";

var src = "\nmodule " + (String(module_name) + (";\n\n-- 暂时全用这个\nraw: String -> Color = external;\n\nrgba: Int -> Int -> Int -> Float -> Color = external;\n\n\nbrighten:  Int -> Color -> Color = external;\nlighten: Int -> Color -> Color = external;\ndarken: Int -> Color -> Color = external;\ndesaturate: Int -> Color -> Color = external;\n\nisDark: Color -> Bool = external;\nisLight: Color -> Bool = external;\n\n\n-- Html Color Names:\n\n" + (String(html_colors) + ("\n\n\n-- 仅用于测试\n\n" + (String(html_color_list) + ("\n\n-- Builtin colors:\n\n" + (String(code_src) + "\n\n")))))));

function pack_color(s) {
  return /* tuple */[
          /* V_tagged_string */Caml_chrome_debugger.variant("V_tagged_string", 10, [
              "Color",
              s
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function int_to_color_to_color(proc, name) {
  return Runtime_common$BwaxAdmin.pack_func(2, (function (param) {
                if (param) {
                  var match = param[0][0];
                  if (typeof match === "number" || match.tag !== /* V_literal */5) {
                    return fail(name);
                  } else {
                    var match$1 = match[0];
                    if (typeof match$1 === "number" || match$1.tag) {
                      return fail(name);
                    } else {
                      var match$2 = param[1];
                      if (match$2) {
                        var match$3 = match$2[0][0];
                        if (typeof match$3 === "number" || !(match$3.tag === /* V_tagged_string */10 && match$3[0] === "Color" && !match$2[1])) {
                          return fail(name);
                        } else {
                          return pack_color(Curry._2(proc, match$1[0], match$3[1]));
                        }
                      } else {
                        return fail(name);
                      }
                    }
                  }
                } else {
                  return fail(name);
                }
              }));
}

function color_to_bool(proc, name) {
  return Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                if (param) {
                  var match = param[0][0];
                  if (typeof match === "number" || !(match.tag === /* V_tagged_string */10 && match[0] === "Color" && !param[1])) {
                    return fail(name);
                  } else {
                    return Runtime_common$BwaxAdmin.pack_bool(Curry._1(proc, match[1]));
                  }
                } else {
                  return fail(name);
                }
              }));
}

function func(param) {
  if (param) {
    var match = param[0][0];
    if (typeof match === "number" || match.tag !== /* V_literal */5) {
      return fail("rgba");
    } else {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag) {
        return fail("rgba");
      } else {
        var match$2 = param[1];
        if (match$2) {
          var match$3 = match$2[0][0];
          if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
            return fail("rgba");
          } else {
            var match$4 = match$3[0];
            if (typeof match$4 === "number" || match$4.tag) {
              return fail("rgba");
            } else {
              var match$5 = match$2[1];
              if (match$5) {
                var match$6 = match$5[0][0];
                if (typeof match$6 === "number" || match$6.tag !== /* V_literal */5) {
                  return fail("rgba");
                } else {
                  var match$7 = match$6[0];
                  if (typeof match$7 === "number" || match$7.tag) {
                    return fail("rgba");
                  } else {
                    var match$8 = match$5[1];
                    if (match$8) {
                      var match$9 = match$8[0][0];
                      if (typeof match$9 === "number" || !(match$9.tag === /* V_literal */5 && !match$8[1])) {
                        return fail("rgba");
                      } else {
                        var value_a = match$9[0];
                        var a;
                        if (typeof value_a === "number") {
                          a = 1.0;
                        } else {
                          switch (value_a.tag | 0) {
                            case /* Int */0 :
                                a = value_a[0];
                                break;
                            case /* Float */1 :
                                a = value_a[0];
                                break;
                            default:
                              a = 1.0;
                          }
                        }
                        var r = match$1[0];
                        var g = match$4[0];
                        var b = match$7[0];
                        var a$1 = a;
                        return /* tuple */[
                                /* V_tagged_string */Caml_chrome_debugger.variant("V_tagged_string", 10, [
                                    "Color",
                                    "rgba(" + (String(r) + (", " + (String(g) + (", " + (String(b) + (", " + (String(a$1) + ")")))))))
                                  ]),
                                Widget_common$BwaxAdmin.no_type
                              ];
                      }
                    } else {
                      return fail("rgba");
                    }
                  }
                }
              } else {
                return fail("rgba");
              }
            }
          }
        } else {
          return fail("rgba");
        }
      }
    }
  } else {
    return fail("rgba");
  }
}

var externals_vals = Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "raw",
          Widget_common$BwaxAdmin.string_to_tagged_string("Color", "raw")
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "rgba",
              Runtime_common$BwaxAdmin.pack_func(4, func)
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "brighten",
                  int_to_color_to_color((function (i, c) {
                          return Palette.brighten(c, i);
                        }), "brighten")
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "lighten",
                      int_to_color_to_color((function (i, c) {
                              return Palette.lighten(c, i);
                            }), "lighten")
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "darken",
                          int_to_color_to_color((function (i, c) {
                                  return Palette.darken(c, i);
                                }), "darken")
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "desaturate",
                              int_to_color_to_color((function (i, c) {
                                      return Palette.desaturate(c, i);
                                    }), "desaturate")
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "isDark",
                                  color_to_bool(is_dark, "isDark")
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "isLight",
                                      color_to_bool(is_light, "isLight")
                                    ],
                                    /* [] */0
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ])
      ]), Plate$BwaxAdmin.List.map((function (c) {
            return /* tuple */[
                    Plate$BwaxAdmin.Str.decap(c),
                    pack_color(Plate$BwaxAdmin.Str.to_lower(c))
                  ];
          }), Plate$BwaxAdmin.List.flat_map(Plate$BwaxAdmin.List.from_array, Curry._1(Plate$BwaxAdmin.List.from_array, Js_dict.values(HtmlColors.default())))));

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

exports.is_light = is_light;
exports.is_dark = is_dark;
exports.all_codes = all_codes;
exports.code_src = code_src;
exports.html_colors = html_colors;
exports.html_color_list = html_color_list;
exports.module_name = module_name;
exports.src = src;
exports.pack_color = pack_color;
exports.fail = fail;
exports.int_to_color_to_color = int_to_color_to_color;
exports.color_to_bool = color_to_bool;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* all_codes Not a pure module */
