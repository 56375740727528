
import React, { useState, useEffect } from 'react'

import { Modal } from 'antd'

import RecordColOverflow from 'Client/js/components/RecordColOverflow'

import RichText from 'bwax-ui/basic/RichText'

export default function RichTextPreview({ params }) {
    const { value } = params

    /// state
    const [ modalVisible, setModalVisible ] = useState(false)

     useEffect(() => {
        import('bwax-ui/auxiliary/video/utils/setup').then(function ({manualSetupVideo}) {
            manualSetupVideo()
        })
     }, [])
    
    if(typeof(value) !== 'string') {
        return null
    }

    const richTextContent = <RichText params={{ value }} />;
    const showContent = () => {
        setModalVisible(true)
    }
    return (
        <React.Fragment>
            <RecordColOverflow
                onClick={(e) => showContent()} className="rich-media">
                {richTextContent}
            </RecordColOverflow>
            <Modal width={900} footer={null}
                open={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
            >
                <div style={{
                    maxHeight: 600,
                    overflow: 'auto',
                    padding: "10px 0"
                }}>
                    { richTextContent }
                </div>
            </Modal>
        </React.Fragment>
      
    )
}



