
import React from 'react';

import { message } from 'antd';


message.config({
    // top: 100,
    duration: 2,
    // maxCount: 3,
    // rtl: true,
    // prefixCls: 'my-message',
  });

export default message;


// import Message from 'rsuite/Message';
// import toaster from 'rsuite/toaster';

// export function success(text, duration) {
//     showMessage("success", text, duration);
// }

// export function error(text, duration) {
//     showMessage("error", text, duration);
// }

// export function warning(text, duration) {
//     showMessage("warning", text, duration);
// }

// export function info(text, duration) {
//     showMessage("info", text, duration);
// }

// export function showMessage(type, text, duration) {

//     const message = (
//         <Message showIcon type={type} duration={duration}>
//             { text }
//         </Message>  
//     );
//     toaster.push(message, { placement: "topCenter" })
// }

// export default { success, error, warning, info, showMessage };