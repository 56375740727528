


import React from 'react'

const FacadeContext = React.createContext();

export default FacadeContext;


export const FacadeContextProvider = props => {

    const { children, facade } = props

    return (
        <FacadeContext.Provider value={{
            facade
        }}>
            { children }
        </FacadeContext.Provider>
    );
}
