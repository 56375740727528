// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Query_builder$BwaxAdmin = require("../../../query/query_builder.bs.js");
var Runtime_common$BwaxAdmin = require("../runtime_common.bs.js");

var $$window = (
  (typeof(window) !== "undefined") ? window : undefined
);

var module_name = "Timer";

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function func(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_Timer_later",
              args
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

function func$1(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_Timer_defer",
              args
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

var externals_vals_000 = /* tuple */[
  "later",
  Runtime_common$BwaxAdmin.pack_func(2, func)
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "defer",
      Runtime_common$BwaxAdmin.pack_func(3, func$1)
    ],
    /* [] */0
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

function later_command(context, param, on_trunk_msgs, on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || cmd.tag !== /* V_tagged */6) {
    return false;
  } else {
    switch (cmd[0]) {
      case "Cmd_Timer_defer" :
          var match = cmd[1];
          if (match) {
            var match$1 = match[1];
            if (match$1) {
              var match$2 = match$1[1];
              if (match$2) {
                var match$3 = match$2[0][0];
                if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                  return false;
                } else {
                  var match$4 = match$3[0];
                  if (typeof match$4 === "number" || match$4.tag || match$2[1]) {
                    return false;
                  } else {
                    var msg = match$1[0];
                    var tagger = match[0];
                    var callback = function (param) {
                      Query_builder$BwaxAdmin.clear_cache(Query_builder$BwaxAdmin.runtime_to_query_context(context));
                      return Curry._1(on_branch_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        msg,
                                        /* [] */0
                                      ])]));
                    };
                    var match$5 = context[/* timers */6];
                    var exit = 0;
                    if ($$window !== undefined && match$5 !== undefined) {
                      var timers_ref = match$5;
                      var id = setTimeout(callback, match$4[0]);
                      var m = Lang_eval$BwaxAdmin.apply_value(undefined, tagger, /* tuple */[
                            /* V_raw */Caml_chrome_debugger.variant("V_raw", 7, [id]),
                            Runtime_common$BwaxAdmin.nt
                          ], Runtime_common$BwaxAdmin.nt);
                      Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  m,
                                  /* [] */0
                                ])]));
                      var match$6 = timers_ref[0];
                      timers_ref[0] = /* tuple */[
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            id,
                            match$6[0]
                          ]),
                        match$6[1]
                      ];
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      var m$1 = Lang_eval$BwaxAdmin.apply_value(undefined, tagger, /* tuple */[
                            /* V_raw */Caml_chrome_debugger.variant("V_raw", 7, [42]),
                            Runtime_common$BwaxAdmin.nt
                          ], Runtime_common$BwaxAdmin.nt);
                      Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  m$1,
                                  /* [] */0
                                ])]));
                    }
                    return true;
                  }
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
      case "Cmd_Timer_later" :
          var match$7 = cmd[1];
          if (match$7) {
            var match$8 = match$7[1];
            if (match$8) {
              var match$9 = match$8[0][0];
              if (typeof match$9 === "number" || match$9.tag !== /* V_literal */5) {
                return false;
              } else {
                var match$10 = match$9[0];
                if (typeof match$10 === "number" || match$10.tag || match$8[1]) {
                  return false;
                } else {
                  var msg$1 = match$7[0];
                  Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
                  var match$11 = context[/* timers */6];
                  if ($$window !== undefined && match$11 !== undefined) {
                    var r = match$11;
                    var timeoutId_ref = /* record */Caml_chrome_debugger.record(["contents"], [undefined]);
                    var callback$1 = function (param) {
                      Query_builder$BwaxAdmin.clear_cache(Query_builder$BwaxAdmin.runtime_to_query_context(context));
                      var match = context[/* timers */6];
                      if (match !== undefined) {
                        var r = match;
                        var match$1 = r[0];
                        var match$2 = timeoutId_ref[0];
                        if (match$2 !== undefined) {
                          var timeoutId = Caml_option.valFromOption(match$2);
                          r[0] = /* tuple */[
                            Plate$BwaxAdmin.List.filter((function (id) {
                                      return Caml_obj.caml_notequal(timeoutId, id);
                                    }))(match$1[0]),
                            match$1[1]
                          ];
                        }
                        
                      }
                      return Curry._1(on_branch_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        msg$1,
                                        /* [] */0
                                      ])]));
                    };
                    var timeoutId = setTimeout(callback$1, match$10[0]);
                    timeoutId_ref[0] = Caml_option.some(timeoutId);
                    var match$12 = r[0];
                    r[0] = /* tuple */[
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          timeoutId,
                          match$12[0]
                        ]),
                      match$12[1]
                    ];
                  }
                  return true;
                }
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
      default:
        return false;
    }
  }
}

var command_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    later_command,
    /* [] */0
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\nmodule Timer;\n\n-- 这个不能用在 init 的 cmd 串里，所以最好不要杂用\nlater: msg -> Int -> Cmd msg = external;\n\n-- Scheduled msg;  later msg\n\n-- type ID = opaque;\n\ndefer: (TimerID -> msg) -> msg -> Int -> Cmd msg = external;\n\n";

exports.$$window = $$window;
exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.later_command = later_command;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* window Not a pure module */
