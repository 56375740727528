// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dateformat = require("dateformat");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Menu = require("antd/lib/menu");
var Antd$BwaxAdmin = require("../../binding/antd/Antd.bs.js");
var Dropdown = require("antd/lib/dropdown");
var GetImageURL = require("bwax-ui/getImageURL");
var IconButton$BwaxAdmin = require("../../components/IconButton.bs.js");
var MenuItem = require("antd/lib/menu/MenuItem");
var Display_RichText$BwaxAdmin = require("./Display_RichText.bs.js");
var Input_RichTextLite$BwaxAdmin = require("../input/Input_RichTextLite.bs.js");

import './Display_Comment.less'
;

function Display_Comment(Props) {
  var params = Props.params;
  var value = Props.value;
  var onChange = Props.onChange;
  var content = params.content;
  var avatar = params.avatar;
  var author = params.author;
  var createdAt = params.createdAt;
  var updatedAt = params.updatedAt;
  var editable = Belt_Option.getWithDefault(params.editable, false);
  var canSave = Belt_Option.getWithDefault(params.canSave, false);
  var onSave = Belt_Option.getWithDefault(params.onSave, (function (param) {
          return /* () */0;
        }));
  var editKey = Belt_Option.getWithDefault(params.editKey, "no-key");
  var match = React.useState((function () {
          return false;
        }));
  var setEditing = match[1];
  var menu = React.createElement(Menu.default, {
        children: React.createElement(MenuItem.default, {
              onClick: (function (param) {
                  return Curry._1(setEditing, (function (param) {
                                return true;
                              }));
                }),
              children: "编辑"
            })
      });
  var edited = updatedAt !== createdAt;
  var footer;
  if (edited) {
    var udt = Dateformat(updatedAt, "mm-dd HH:MM");
    footer = React.createElement("div", {
          className: "content-footer"
        }, "在 " + (String(udt) + " 编辑过"));
  } else {
    footer = null;
  }
  var commentEdit = React.createElement("div", {
        className: "edit-box"
      }, React.createElement(Input_RichTextLite$BwaxAdmin.make, {
            value: value,
            params: {
              editKey: editKey,
              placeholder: undefined,
              uploadFor: "record-comment"
            },
            onChange: onChange
          }), React.createElement("div", {
            className: "edit-toolbar"
          }, React.createElement(IconButton$BwaxAdmin.make, {
                icon: "close",
                size: /* small */-9768761,
                onClick: (function (param) {
                    return Curry._1(setEditing, (function (param) {
                                  return false;
                                }));
                  })
              }), React.createElement(IconButton$BwaxAdmin.make, {
                icon: "check",
                size: /* small */-9768761,
                onClick: (function (param) {
                    Curry._1(onSave, /* () */0);
                    return Curry._1(setEditing, (function (param) {
                                  return false;
                                }));
                  }),
                color: "G60",
                disabled: !canSave
              })));
  var commentDisplay = React.createElement(Display_RichText$BwaxAdmin.make, {
        params: {
          value: content
        }
      });
  return React.createElement("div", {
              className: "admin--display-comment"
            }, React.createElement("div", {
                  className: "avatar"
                }, React.createElement("img", {
                      src: GetImageURL.default(avatar)
                    })), React.createElement("div", {
                  className: "content"
                }, React.createElement("div", {
                      className: "content-header"
                    }, React.createElement("div", {
                          className: "author"
                        }, author), React.createElement("div", {
                          className: "control-bar"
                        }, React.createElement("div", {
                              className: "time"
                            }, Dateformat(createdAt, "mm-dd HH:MM")), React.createElement("div", undefined, editable ? React.createElement(Dropdown.default, {
                                    overlay: menu,
                                    children: React.createElement(Antd$BwaxAdmin.Icon.make, {
                                          type_: "more"
                                        })
                                  }) : React.createElement(Antd$BwaxAdmin.Icon.make, {
                                    type_: "more"
                                  })))), React.createElement("div", {
                      className: "content-body"
                    }, match[0] ? commentEdit : commentDisplay), footer));
}

var make = Display_Comment;

exports.make = make;
/*  Not a pure module */
