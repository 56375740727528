// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var BaseUI$BwaxAdmin = require("../BaseUI.bs.js");
var SpinnerBridgeJs = require("./SpinnerBridge.js");

var make = SpinnerBridgeJs.default;

var SpinnerBridge = {
  make: make
};

function Spinner(Props) {
  var pc = Props.pc;
  var match = Props.rules;
  var rules = match !== undefined ? match : /* [] */0;
  var theme = Props.theme;
  var name = Props.name;
  var config = Props.config;
  var color = Props.color;
  var loading = Props.loading;
  var inner = function (param, param$1, param$2, param$3) {
    return React.createElement(make, {
                name: name,
                config: config,
                color: color,
                loading: loading
              });
  };
  return BaseUI$BwaxAdmin.box(rules, inner, theme, pc, /* [] */0, "nokey");
}

var make$1 = Spinner;

exports.SpinnerBridge = SpinnerBridge;
exports.make = make$1;
/* make Not a pure module */
