

import React, { useEffect, useRef, useState } from 'react'

// new, simpler inputs:
import { Input, Select, Switch, InputTag, AutoComplete, InputNumber } from '@arco-design/web-react';

const Option = Select.Option;

export default function getInput({ type, typeOptions, multivalued, facade }) {

    return ({ value, onChange, onEnd, autoFocus = false, usedValues }) => {
        const onKeyDown = e => {
            if (!e.shiftKey && e.key === "Enter" && onEnd) {
                e.preventDefault();
                onEnd();
            }
        }

        const inputs = {
            "ShortText": _ => {
                const inputElement = (() => {
                    if(multivalued) {
                        const inputProps = {
                            onBlur: onEnd, onChange, value,                            
                            autoFocus,
                            dragToSort: true
                        }
                        return  <InputTag {...inputProps} />;
                    } else {
    
                        return (
                            <Input {...{
                                onBlur: onEnd, onChange, value,
                                onKeyDown, autoFocus
                            }} />
                        )
    
                    }
                })();
                if(usedValues && usedValues.length > 0) {

                    function processValue(value) {
                        if(value && typeof(value) == 'string' && multivalued && value.match(/^\[(\"([^\"]+)\",?)+\]$/)) {
                            // it is like ["蓝色","灰色","红色","棕色"]
                            const r = value.matchAll(/\"([^\"]+)\"/g);
                            let values = [];
                            for(const v of r) {
                                values.push(v[1])
                            }
                            // onChange(values);

                            return values;

                        } else {
                            return value
                        }
                    }

                    const uv = usedValues.flatMap(v => {
                        const vs = processValue(v);
                        return Array.isArray(vs) ? vs : [vs];
                    })

                    const v = processValue(value);

                    return (
                        <AutoComplete 
                            value={v}
                            data={uv} triggerElement={inputElement} 
                            defaultActiveFirstOption={true}
                            onFocus={_ => {
                                if(v !== value) {
                                    onChange(v);
                                }
                            }}
                            filterOption={(value, option) => {
                                if(multivalued) {
                                    if(Array.isArray(value)) {
                                        return value.indexOf(option.props.value) === -1
                                    } else {
                                        return true
                                    }
                                    
                                } else {
                                    return option.props.value.indexOf(value) !== -1
                                }
                            }} 
                            onSelect={(v, option) => {
                                console.log(">>> seletc", v, option);
                            }}
                            onChange={(v) => {
                                if(multivalued && !Array.isArray(v)) {
                                    console.log(">>> v", value, v);
                                    onChange([...(value ||[]), v])
                                } else {
                                    onChange(v)
                                }
                               
                            }}
                        />
                    )
                } else {
                    return inputElement
                }

            },

            "Text": _ => (
                <TextArea {...{
                    onBlur: onEnd, onChange, value,
                    onKeyDown,
                    autoFocus,
                    autoSize: { minRow: 2, maxRow: 6 }
                }} />
            ),

            "FieldType": _ => {
                const allowedTypes = ["整数", "数字", "短文本", "文本", "选择", "图片", "文件", "日期", "日期时间", "布尔"];
                const options = allowedTypes.map(name => {
                    return { name, key: facade.dataTypes.find(dt => dt.name == name).key }
                })

                const Sel = autoFocus ? AutoFucusSelect : Select;
                return (
                    <Sel value={value} onChange={onChange} autoFocus={autoFocus} onBlur={onEnd}>
                        {options.map(({ name, key }) => (
                            <Option key={key} value={key}>
                                { name }
                            </Option>
                        ))}
                    </Sel>
                )
            },
            "Bool": _ => {
                return (
                    <AutoFucusEl component={Switch} size='small' type='line' checked={value} onChange={onChange} onBlur={onEnd} />
                )
            },
            "Number": _ => {
                return (
                    <InputNumber {...{
                        onBlur: onEnd, onChange, value,
                        onKeyDown,
                        autoFocus,
                        // autoSize: { minRow: 2, maxRow: 6 }
                    }} />
                )
            }   

        }
        return (inputs[type] || (_ => value))();
    }

}

function AutoFucusEl({ component, ...props}) {
    const ref = useRef();

    useEffect(() => {
        if(ref.current) {
            ref.current.focus();
        }
    }, [ref.current]);

    const C = component;

    return (
        <C {...props} ref={ref} />
    )
}


function AutoFucusSelect (props) {
    return (
        <AutoFucusEl component={Select} {...props} />
    )

}

function TextArea(props) {

    const { autoSize, value, ...rest } = props;
    const [ mounted, setMounted ] = useState(false);

    useEffect(() => {
        setMounted(true)
    }, []);

    const propsToUse = {
        ...rest,
        ...(mounted ? { autoSize, value } : { value: (value || "") + "\n" })
    }

    return (
        <Input.TextArea {...propsToUse} />
    )

}