// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxAdmin = require("../../lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../runtime_common.bs.js");

var module_name = "Cmd";

var cmd_none_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
    "Cmd_none",
    /* [] */0
  ]);

var cmd_none = /* tuple */[
  cmd_none_000,
  Runtime_common$BwaxAdmin.nt
];

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        "Cmd." + (name + ": Invalid Arguments")
      ];
}

function func(args) {
  if (args) {
    var match = args[0][0];
    if (typeof match === "number" || !(match.tag === /* V_list */1 && !args[1])) {
      return fail("batch");
    } else {
      return /* tuple */[
              /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "Cmd_batch",
                  match[0]
                ]),
              /* No_term */0
            ];
    }
  } else {
    return fail("batch");
  }
}

function func$1(args) {
  if (args) {
    var match = args[0][0];
    if (typeof match === "number" || !(match.tag === /* V_list */1 && !args[1])) {
      return fail("serial");
    } else {
      return /* tuple */[
              /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "Cmd_serial",
                  match[0]
                ]),
              /* No_term */0
            ];
    }
  } else {
    return fail("serial");
  }
}

function func$2(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_eval_js",
              args
            ]),
          /* No_term */0
        ];
}

function func$3(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_getDomainEnv",
              args
            ]),
          /* No_term */0
        ];
}

function func$4(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_UI_goto",
              args
            ]),
          /* No_term */0
        ];
}

function func$5(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_UI_copy",
              args
            ]),
          /* No_term */0
        ];
}

function func$6(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_UI_alert",
              args
            ]),
          /* No_term */0
        ];
}

var externals_vals_000 = /* tuple */[
  "map",
  Runtime_common$BwaxAdmin.pack_func(2, (function (param) {
          if (param) {
            var match = param[1];
            if (match && !match[1]) {
              return /* tuple */[
                      /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                          "Cmd_lifted",
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              param[0],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  match[0],
                                  /* [] */0
                                ])
                            ])
                        ]),
                      /* No_term */0
                    ];
            } else {
              return fail("map");
            }
          } else {
            return fail("map");
          }
        }))
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "batch",
      Runtime_common$BwaxAdmin.pack_func(1, func)
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "serial",
          Runtime_common$BwaxAdmin.pack_func(1, func$1)
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "return",
              Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                      if (param && !param[1]) {
                        return /* tuple */[
                                /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                                    "Cmd_return",
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        param[0],
                                        /* [] */0
                                      ])
                                  ]),
                                /* No_term */0
                              ];
                      } else {
                        return fail("serial");
                      }
                    }))
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "evalJs",
                  Runtime_common$BwaxAdmin.pack_func(2, func$2)
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "getDomainEnv",
                      Runtime_common$BwaxAdmin.pack_func(1, func$3)
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "goto",
                          Runtime_common$BwaxAdmin.pack_func(1, func$4)
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "copy",
                              Runtime_common$BwaxAdmin.pack_func(1, func$5)
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "alert",
                                  Runtime_common$BwaxAdmin.pack_func(1, func$6)
                                ],
                                /* [] */0
                              ])
                          ])
                      ])
                  ])
              ])
          ])
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

function commands(context, param, on_trunk_msgs, _on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || cmd.tag !== /* V_tagged */6) {
    return false;
  } else {
    switch (cmd[0]) {
      case "Cmd_eval_js" :
          var match = cmd[1];
          if (match) {
            var match$1 = match[0][0];
            if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
              return false;
            } else {
              var match$2 = match$1[0];
              if (typeof match$2 === "number" || match$2.tag !== /* String */2) {
                return false;
              } else {
                var match$3 = match[1];
                if (match$3 && !match$3[1]) {
                  try {
                    eval(match$2[0]);
                    Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
                  }
                  catch (raw_e){
                    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                    console.log("执行 JS 代码出错", e);
                    var error = Lang_eval$BwaxAdmin.apply_value(undefined, match$3[0], /* tuple */[
                          /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, ["执行 JS 代码出错"])]),
                          Runtime_common$BwaxAdmin.nt
                        ], Runtime_common$BwaxAdmin.nt);
                    Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                error,
                                /* [] */0
                              ])]));
                  }
                  return true;
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
      case "Cmd_getDomainEnv" :
          var match$4 = cmd[1];
          if (match$4 && !match$4[1]) {
            var value = Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "mobileHost",
                      Runtime_common$BwaxAdmin.pack_string(context[/* domain_env */7][/* mobileHost */0])
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "isSandbox",
                          Runtime_common$BwaxAdmin.pack_bool(context[/* domain_env */7][/* isSandbox */1])
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "protocol",
                              Runtime_common$BwaxAdmin.pack_string(context[/* domain_env */7][/* protocol */2])
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "tenantCode",
                                  Runtime_common$BwaxAdmin.pack_maybe(Runtime_common$BwaxAdmin.pack_string, context[/* domain_env */7][/* tenantCode */3])
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "urlPrefix",
                                      Runtime_common$BwaxAdmin.pack_string(context[/* domain_env */7][/* urlPrefix */4])
                                    ],
                                    /* [] */0
                                  ])
                              ])
                          ])
                      ])
                  ]));
            var msg = Lang_eval$BwaxAdmin.apply_value(undefined, match$4[0], value, Runtime_common$BwaxAdmin.nt);
            Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        msg,
                        /* [] */0
                      ])]));
            return true;
          } else {
            return false;
          }
      case "Cmd_return" :
          var match$5 = cmd[1];
          if (match$5 && !match$5[1]) {
            Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        match$5[0],
                        /* [] */0
                      ])]));
            return true;
          } else {
            return false;
          }
      default:
        return false;
    }
  }
}

var command_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    commands,
    /* [] */0
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\n\nmodule Cmd;\n\nnone = Cmd_none;\n\n-- Transform the messages produced by a command.\nmap: (a -> msg) -> Cmd a -> Cmd msg = external;\n\n\nbatch: List (Cmd msg) -> Cmd msg = external;\n\nserial: List (Cmd msg) -> Cmd msg = external;\n\nreturn: msg -> Cmd msg = external;\n\n\n--- 这里也会放一些通用的 cmd\n\n-- Evaluate arbitrary javascript\nevalJs: String -> (String -> msg) -> Cmd msg = external;\n\n-- @Deprecated. 使用 UI.goto\n-- goto: String -> Cmd msg = external;\n\n-- @Deprecated. 使用 UI.copy\n-- copy: String -> Cmd msg = external;\n\n-- @Deprecated. 使用 UI.alert\n-- alert: a -> Cmd msg = external;\n\n\ntype alias DomainEnv = \n  { mobileHost: String\n  , isSandbox: Bool\n  , protocol: String\n  , tenantCode: Maybe String\n  , urlPrefix: String\n  }\n;\n\ngetDomainEnv: (DomainEnv -> msg) -> Cmd msg = external;\n\n\n-- Some helpers:\nurlPrefix domainEnv: DomainEnv -> String = \n  let \n    { protocol, mobileHost, isSandbox } = domainEnv \n  in \n    protocol ++ \"://\" ++ mobileHost ++ (if isSandbox then \"/sandbox\" else \"\")\n;\n\n\n\n";

exports.module_name = module_name;
exports.cmd_none = cmd_none;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.commands = commands;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
