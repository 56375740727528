
import {RichUtils, Modifier, EditorState, DefaultDraftInlineStyle} from 'draft-js';
import {getSelectedInlineStyles} from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

const STYLE_CONFIG = {
  color: "color-(.*)",
  backgroundColor: "backgroundColor-(.*)",
  // fontSize: /fontSize-(\d*(px|em|pt|%)?)/  // 不支持导入的字体大小
}

export {STYLE_CONFIG}

export function resolveStyles(styles) {
  return styles.reduce((acc, style) => {
    for(let name in STYLE_CONFIG) {
      const match = style.match(STYLE_CONFIG[name])
      if(match) {
        // accumulate the style
        return {
          ...acc,
          [name]: match[1]
        }
      }
    }
    return acc
  }, {})

}

export function removeAllInlineStyles(editorState) {

  const currentStyle = editorState.getCurrentInlineStyle()
  const currentContent = editorState.getCurrentContent()
  const selection = editorState.getSelection()

  const nextContentState = [...getSelectedInlineStyles(editorState)].reduce((contentState, style) => {
    return  Modifier.removeInlineStyle(
      contentState,
      selection,
      style
    );
  }, currentContent);

  let nextEditorState = EditorState.push(
    editorState,
    nextContentState,
    'change-inline-style'
  )
  return nextEditorState

}

export function applyAllStyles(editorState, styles) {

  let nextEditorState = removeAllInlineStyles(editorState)

  const newContentState = styles.reduce((contentState, style) => {
    return Modifier.applyInlineStyle(contentState, editorState.getSelection(), style);
  }, nextEditorState.getCurrentContent());

  return EditorState.push(
    editorState,
    newContentState,
    'change-inline-style'
  )

}

export function applyCustomStyle(editorState, styleKey, value) {

  const targetStyle = styleKey + "-" + value

  const selection = editorState.getSelection()

  // avoid conflict styles:
  const nextContentState = getSelectedInlineStyles(editorState).reduce((contentState, style) => {
    if(style.startsWith(styleKey)) {
      return Modifier.removeInlineStyle(contentState, selection, style)
    } else {
      return contentState
    }
  }, editorState.getCurrentContent())


  /// apply the style and push to the editor state:

  return EditorState.push(
    editorState,
    Modifier.applyInlineStyle(nextContentState, selection, targetStyle),
    'change-inline-style'
  )

}
