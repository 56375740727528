import React from 'react'

import { Editor, Transforms } from 'slate'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { MdContentCopy } from 'react-icons/md'

export default function CopyUrlButton(props) {
    const { editor } = props
    const { selection } = editor

    const [parentNode, parentPath] = Editor.parent(
        editor,
        selection.focus?.path
    );
    
    return (
        <button className="editor-button">
            {
                CopyToClipboard ?
                    <CopyToClipboard text={parentNode.url} onCopy={() => alert('复制音频链接成功')}>
                        <MdContentCopy style={{ fontSize: 14 }} />
                    </CopyToClipboard> :
                    <MdContentCopy style={{ fontSize: 14 }} />
            }
        </button>
    )
}
