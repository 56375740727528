

import admin_widget_helper from './admin_widget_helper.bs'

export default function applyBoolBn (fn, rawValue, { bwax, entityName }) {

    return admin_widget_helper.apply_value_to_bool(
        fn,
        admin_widget_helper.transform_record(
            bwax.entity_dict, bwax.data_type_dict, entityName, rawValue
        )
    )
}