

import { getFieldsFromFieldItems } from './builderUtils'

export default function buildRecordPane(recordId, entityName, options, { allEntities, allDataTypes, queryTarget, criteria }) {

    const {
        excludedFields,
        includedFields,
        fieldItems,
        operations,
        editableCondition,
        customFieldInputs = {},
        // customFieldDisplays = {},
        deletionAllowed = true,
    } = options;

    let excluded = [
        "修改者", "修改时间",
        ...(Array.isArray(excludedFields) ? excludedFields : [])
    ];

    const dataEntity = allEntities.find(e => e.name == entityName);

    /// fieldsToUse; 考虑的优先级是 fieldItems > includedFields > excludedFields.
    function getFieldsToUse() {
        if (fieldItems) {
            return getFieldsFromFieldItems(fieldItems, dataEntity, allEntities, allDataTypes)
        } else {

            const allFields = [ ...dataEntity.fields, ...dataEntity.virtualFields ];

            return (
                allFields.filter(
                    /// 如果指定了 excludedFields， 那么不能是其中一个
                    f => excluded === undefined || excluded.length === 0 || excluded.indexOf(f.name) === -1
                ).filter(
                    /// 如果指定了 includedFields， 那么必须是其中一个
                    f => includedFields === undefined || includedFields.length === 0 || includedFields.indexOf(f.name) !== -1
                ).sort((a, b) => {
                    // 给 field 排序
                    /// 根据 displayWeight 从大到下排列
                    const getWeight = f => f.displayWeight || 0
                    // 重的在前面
                    return getWeight(b) - getWeight(a)
                })
            )
        }
    }

    const fieldsToUse = getFieldsToUse();

    return {
        layout: {
            styles: [
                "scrollY"
            ],
            content: [{
                type: 'column',
                params: {
                    styles: [
                        ["paddingXY", "0", "0.5rem"],
                        ["maxWidth", "66rem"],
                        ["spacing", "1.5rem"],
                        ["width", "fill"],
                        ...((fieldsToUse.length === 1 && fieldsToUse[0].type === "RichText") ? [["height", "fill"]] : [])
                    ],
                    content: [
                        {
                            type: "admin::RecordForm",
                            params: {
                                entityName,
                                recordId,
                                criteria,
                                operations,
                                fieldsToUse,
                                editableCondition,
                                deletionAllowed,
                                actions: {
                                    goTo: {
                                        action: {
                                            type: "GoTo"
                                        }
                                    },
                                    updateRecord: {
                                        action: {
                                            type: "UpdateRecord"
                                        }
                                    },
                                    addRecord: {
                                        action: {
                                            type: "AddRecord"
                                        }
                                    },
                                    deleteRecord: {
                                        action: {
                                            type: "DeleteRecord"
                                        }
                                    },              
                                    goBack: {
                                        action: {
                                            type: "GoBack"
                                        }
                                    },              
                                    adminMessage: {
                                        action: {
                                            type: "admin::Message",
                                        }
                                    }
                                },

                                customFieldInputs
                            }
                        }
                    ]
                }
            }]
        }
    }

}