
import React, { useContext } from 'react'

import Upload from 'bwax-ui/auxiliary/uikit/Upload'

import { MdOutlineImage } from "react-icons/md"

import { insertImage } from '../utils/ImageHelper';

export default function ImageButton(props) {

    const { editor, uploadImage } = props


    const uploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: (f) => {
            insertImage(f.file, uploadImage, editor)
        }
    }

    return (
        <Upload {...uploadProps}>
            <button className={"editor-button"}>
                <MdOutlineImage style={{ fontSize: 16 }}/>
            </button>
        </Upload>
    )

}
