
import React from 'react'

import './FieldOptions.less';

export default function FieldOptions (props) {
    const { params } = props

    const { value, dataType } = params

    // Number: decimals
    // ShortText, Text: validation
    // FilterCondition: entity, specifiedFields
    // Link: entity
    // Select: options

    if(value === null || value === undefined || Object.keys(value).length === 0) {
        return null
    }

    const labeledValue = (label, value, f) => {
        if(value === undefined) {
            return null
        }
        return <div><span style={{marginRight: "0.25rem", fontSize: "smaller"}}>{label}</span>{f ? f(value) : value}</div>
    }

    const forText = () => {
        const { validation } = value 
        if(!validation) {
            return null
        } else {
            const { ref, list, lookup } = validation
            return (
                <>
                    { labeledValue("ref 引用:", ref) }
                    { list ? labeledValue("list 枚举:", list.join(" | ")) : null }
                    { labeledValue("lookup 选项:", lookup)}
                </>
            )
        }
    };

    const isPublic = () => {
        return labeledValue("公开", value["public"], v => v ? "是" : "否");
    }

    const forDateTime = () => {
        const { format } = value;
        return labeledValue("格式:", format)
    };

    const displays = {
        "Number": () => {
            const { decimals, format } = value;
            return (
                <>
                    { labeledValue("小数位:", decimals) }
                    { labeledValue("格式:", format) }
                </>
            )
        },
        "Select": () => {
            let { options = [] } = value;
            return ( 
                <>
                    { options.map(({name, value}) => {
                        if(name == value) {
                            return name 
                        } else {
                            return name + "(" + value + ")"
                        }
                    }).join(" | ") }
                </>
            )
        },

        "ShortText": forText,
        "Text": forText,

        "Image": isPublic,
        "File": isPublic,

        "Date": forDateTime,
        "DateTime": forDateTime,


        "Link": () => {
            return <>【{ value.entity }】</>
        }
    }

    const display = displays[dataType]
    if(display) {
        return <div>{display()}</div>
    } else {
        return <pre>{ JSON.stringify(value, null, 2)}</pre> 
    }


}