
import React from 'react';

import './IconButton.less'

import { Popconfirm } from '@arco-design/web-react';

// 
export default function IconButton(props) {

    const { icon, onClick, disabled, style, confirm } = props;


    const element = (
        <div className={"admin--icon-button" + (disabled ? " disabled" : "")} style={style} onClick={_ => {
            if (!disabled && !confirm) {
                onClick();
            }
        }}>
            {icon}
        </div>
    )

    if(confirm && !disabled) {
        return (
            <Popconfirm 
                onOk={() => {
                    onClick()
                }}
                title={confirm.title}
                okText={confirm.okText}
                cancelText={confirm.cancelText}
            >
                { element }
            </Popconfirm>
        )
    } else {
        return element
    }
}