
import Node from './core/Node';
import { lookupValue, NodeHolder } from './core/store/DataBook';
import resolveAndRunQuery from './query/resolveAndRunQuery';

import { runDataQuery, runDefinitionQuery } from "bwax/query/runClientQuery"



/// 返回所有从 roots 延伸下来的 path；
export function getAllPaths(target, { allDataTypes, allEntities, roots = {} }) {
    const node = (
        (target instanceof Node)
            ? target
            : Node.buildWithTypes(target, roots, { allEntities, allDataTypes })
    )

    return node.getAllPathsIncluded()
}


export function evaluate(target, data,
    { 
        allEntities, 
        allDataTypes, 
        preservedNodes = [],
        qNameValueResolver,
        Functions,
        Operators,
        roots = {},
    } = {}) {

    /// build node
    const node = (
        (target instanceof Node)
            ? target
            : Node.buildWithTypes(target, roots, { allEntities, allDataTypes })
    )

    const ret = lookupValue(node, data, preservedNodes, {
        qNameValueResolver, Functions, Operators
    })

    return ret

}

/// 递归地处理所有的带有 __query__ 的结构，得到最终的值：
export async function evaluateWithQuery(target, baseData, { 
        allEntities, allDataTypes, queryRunner, 
        preservedNodes = [], queryTarget,
        tenantCode, sessionToken, sandbox,
        roots = {},
        Functions,
        Operators,
        qNameValueResolver,
    } = {}) {

    const node = (
        (target instanceof Node)
            ? target
            : Node.buildWithTypes(target, roots, { allEntities, allDataTypes })
    )
    const startingValue = lookupValue(node, baseData, preservedNodes, {
        Functions,
        Operators,
        qNameValueResolver,
    })

    //////
    const __query__ = '__query__'
    const __for__ = '__for__'

    async function resolveWithQuery(holder, upperQueries) {

        const { node, value, localData } = holder

        const queries = Object.keys(value).reduce((acc, name) => {
            if (name === '__for__') {
                return acc
            } else {
                return {
                    ...acc,
                    [name]: value[name]
                }
            }
        }, {})

        // const targetNode = node.children.find( n => n.name === '__for__')
        const targetDef = node.nodeDef[__for__]

        const combinedQueries = {
            ...upperQueries,
            ...queries
        }

        const targetNode = Node.buildWithTypes(
            targetDef, 
            combinedQueries, /// rules.
            { allEntities, allDataTypes }
        )

        const queryRunners = {
            'data': runDataQuery({tenantCode, sessionToken, sandbox}),
            'definition': runDefinitionQuery({tenantCode, sessionToken})
        }

        const __queryRunner__ = queryTarget ? queryRunners[queryTarget] : queryRunner

        const queryData = await resolveAndRunQuery(
            queries, targetNode, {}, {
                allEntities, 
                allDataTypes, 
                queryRunner: __queryRunner__
            }
        )

        const targetValue = lookupValue(targetNode, {
            ...localData,
            ...queryData
        }, preservedNodes,  {
            qNameValueResolver, Functions, Operators
        })

        return await resolve(targetValue, combinedQueries)
    }

    async function resolve(value, upperQueries = {}) {

        /// if it is a __query__: NodeHolder 
        if (typeof (value) === 'object' && value !== null && value[__query__] instanceof NodeHolder) {

            return resolveWithQuery(value[__query__], upperQueries)

        } else if (value instanceof NodeHolder) {
            /// othertype of NodeHolder: don't process it
            return value

        } else {

            if (Array.isArray(value)) {
                /// async map:
                let ret = []
                for (let i = 0; i < value.length; i++) {
                    ret.push(await resolve(value[i], upperQueries))
                }
                return ret

            } else if (value !== null && typeof (value) === 'object') {
                /// async reduce:
                let ret = {}
                const keys = Object.keys(value)
                for (let i = 0; i < keys.length; i++) {
                    const k = keys[i]
                    const v = await resolve(value[k], upperQueries)
                    ret[k] = v
                }
                return ret

            } else {
                return value
            }
        }
    }

    const ultimateValue = await resolve(startingValue, {})

    return ultimateValue
}
