

import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import invariant from 'invariant'

import { mapObj, filterObj, hashCode } from 'bwax/utils'
import Node from 'bwax/core/Node';
import { lookupValue, NodeHolder } from 'bwax/core/store/DataBook'
import {
    printQuery, prepareVariables
} from 'bwax/query/resolveAndRunQuery'
import loadQueryData from 'bwax-ui/legacy/store/loaders/loadQueryData';
import useDataLoader from 'bwax-ui/legacy/store/useDataLoader';

const __query__ = '__query__'
const __for__ = '__for__'

const PRESERVED = ['action', 'timer']

function extractQueries(def) {
    return Object.keys(def).reduce((acc, name) => (
        (name === __for__) ? acc : {
            ...acc,
            [name]: def[name]
        }
    ), {})
}


/// 只考虑一层的 __query__
export default function useQueriedValueWithoutState(def,
    {
        roots,
        baseData,
        allEntities,
        allDataTypes,
        queryTarget = 'data' /// 'data' or 'definition', or 'sandbox'
    }
) {

    // 不同的 partialVariables 可能对应不同的 queryData
    // 也可能因为 pagination 有不同的其他 page 的key
    // { key } = data

    // 在外面处理 last page
    // const [lastQueryData, setLastQueryData] = useState(undefined)
    const [currentPartialVariables, setCurrentPartialVariables] = useState({})

    
    /// state end =====

    function preProcess() {

        const n = Node.buildWithTypes(def, roots, { allEntities, allDataTypes })

        const startingValue = lookupValue(n, {
            ...baseData, 
        }, PRESERVED)
        invariant(startingValue, "No starting value")
        if (!(startingValue[__query__] instanceof NodeHolder)) {
            /// no query:
            return { localData: {}, targetNode: n }
        }

        //// handle query:
        const { node, value, localData } = startingValue[__query__]
        const queries = extractQueries(value)
        const targetDef = node.nodeDef[__for__]

        const targetNode = Node.buildWithTypes(targetDef, {
            ...queries, ...roots
        }, { allEntities, allDataTypes })

        return {
            localData,
            queries,
            targetNode
        }
    }

    ///// load the query data with queryNames + partialVariables
    ///// useDataLoader: if the queryData is there, then go ahead and use it, 
    ////                 if there no query data, simply wait for its availability

    function buildQueryKey(targetNode, queriesToLoad, partialVariables) {


        const queryText = printQuery(
            queriesToLoad, targetNode, { allDataTypes, allEntities }
        )

        const variables = prepareVariables(
            queriesToLoad, allEntities, partialVariables
        )

        const key = `q_${queryTarget}_${hashCode(queryText)}_${hashCode(variables)}`

        return key
    }

    function buildDataLoader(partialVariables) {

        let t0 = performance.now();

        const { queries, targetNode } = preProcess()

        if (queries === undefined) {
            return { key: "NoData", loader: async () => ({}) }
        }
        const changedQueries = determineQueriesToLoad(queries, partialVariables)

        const queriesToLoad = {
            ...queries,
            ...changedQueries
        }

        const key = buildQueryKey(targetNode, queriesToLoad, partialVariables)

        const loader = loadQueryData(queriesToLoad, targetNode, partialVariables, {
            allDataTypes, allEntities, queryTarget
        })
        
        let t1 = performance.now();
        // console.log("BuildDataLoader used", t1 - t0);

        return { key, loader }
    }

    const { key, loader } = buildDataLoader(currentPartialVariables)


    const { getData, forceLoad } = useDataLoader({ [key]: loader })

    const queryData = (() => {
        const [currentData] = getData(key)

        // if not yet loaded try to use last data
        // 这是因为更新了 partial variables 导致数据还没有获取时，我们还需要页面上暂时使用
        // 之前的数据，以避免页面整体被刷新。
        return currentData // === undefined ? lastQueryData : currentData
    })()

    function evaluate() {

        let t0 = performance.now();
        const { localData, targetNode, queries } = preProcess()

        if (queryData === undefined && queries !== undefined) {
            /// need query data, do evaluate for now:
            return undefined
        }

        const ret = lookupValue(targetNode, {
            ...baseData,
            ...localData,
            ...queryData
        }, PRESERVED)

        let t1 = performance.now();
        console.log("Evaluate used", t1 - t0);
        // console.log(JSON.stringify(def, 0, 2))

        return ret
    }


    const [value, setValue] = useState(undefined);

    useEffect(() => {

        /// TODO improve the performance please:
        // const t0 = parseInt(Date.now())
        const value = evaluate()
        // const t1 = parseInt(Date.now())
        // console.log("Elapsed:", t1 - t0, value)
        // console.log(value)

        setValue(value)
        

    }, [ queryData ])



    const reload = async (partialVariables = {}) => {
        // pre processed value

        let currentPVs = currentPartialVariables || {}
        function merge() {
            if (Object.keys(partialVariables).length == 0) {
                return currentPVs
            }
            return Object.keys(partialVariables).reduce((acc, key) => {
                if (currentPVs[key]) {
                    // merge the two
                    const merged = {
                        ...currentPVs[key],
                        ...partialVariables[key]
                    }
                    return {
                        ...acc,
                        [key]: merged
                    }

                } else {
                    return {
                        ...acc,
                        [key]: partialVariables[key]
                    }
                }

            }, {})
        }

        const pv = merge();

        // 如果 partialVariables 是空的，那么就表示 query 的 first 都要设成现有的 size
        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/519


        function setPageSize(rawPv) {

            // 重新用回了分页，就不需要这个了。
            // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/431
            return rawPv;
        }

        const { key, loader } = buildDataLoader(
            Object.keys(partialVariables).length === 0 ? setPageSize(pv) : pv
        )

        /// 需要 await 吗？
        await forceLoad({ [key]: loader })

        /// TODO 这里有可能出现 set unmount component state 的错误 

        ReactDOM.unstable_batchedUpdates(() => {
            /// 有 race condition 吗？
            // setLastQueryData(queryData)
            setCurrentPartialVariables(pv)

        })

    }

    return {
        value,
        queryData,

        reload
    }
}


//// helper funcitons
function determineQueriesToLoad(allQueries, partialVariables) {
    const specifiedNames = Object.keys(partialVariables)
    const queryNames = specifiedNames.length > 0 ? specifiedNames : Object.keys(allQueries)
    const queriesToLoad = filterObj(allQueries, (_, n) => queryNames.indexOf(n) !== -1)
    return queriesToLoad
}