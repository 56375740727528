// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Plate$BwaxAdmin = require("../../lib/bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../lib/bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var AdminLayout$BwaxAdmin = require("./AdminLayout.bs.js");
var Admin_widget$BwaxAdmin = require("./admin_widget.bs.js");
var Lang_runtime$BwaxAdmin = require("../../lib/bwax-js/ml/lang/lang_runtime.bs.js");
var Facade_helper$BwaxAdmin = require("../../lib/bwax-js/ml/helper/facade_helper.bs.js");
var Runtime_common$BwaxAdmin = require("../../lib/bwax-js/ml/lang/mod/runtime_common.bs.js");
var Page_entry_slim$BwaxAdmin = require("../../lib/bwax-ui/ml/page_entry_slim.bs.js");
var Admin_page_common$BwaxAdmin = require("../../lib/bwax-ui/ml/admin/admin_page_common.bs.js");

function print_msgs(msgs) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(Lang_eval$BwaxAdmin.string_of_value, msgs));
}

function make_domain_env(domainEnv) {
  return /* record */Caml_chrome_debugger.record([
            "mobileHost",
            "isSandbox",
            "protocol",
            "tenantCode",
            "urlPrefix"
          ], [
            domainEnv.mobileHost,
            domainEnv.isSandbox,
            domainEnv.protocol,
            domainEnv.tenantCode,
            domainEnv.urlPrefix
          ]);
}

function make_web_env(webEnv) {
  return /* record */Caml_chrome_debugger.record([
            "protocol",
            "host",
            "currentURL",
            "currentURLPath",
            "originalURL",
            "isSandbox",
            "isIOS",
            "isWeChat",
            "isMobile"
          ], [
            webEnv.protocol,
            webEnv.host,
            webEnv.currentURL,
            webEnv.currentURLPath,
            webEnv.originalURL,
            webEnv.isSandbox,
            webEnv.isIOS,
            webEnv.isWeChat,
            webEnv.isMobile
          ]);
}

function runInit(domainEnv, webEnv, route_to, bwax, dts, ast, paramsValue, maybePreparedData) {
  var command_handlers = Page_entry_slim$BwaxAdmin.get_command_handlers(/* () */0);
  var queryRunner = Facade_helper$BwaxAdmin.getQueryRunner(bwax);
  var queryCache = Facade_helper$BwaxAdmin.getQueryCache(bwax);
  var match = Facade_helper$BwaxAdmin.getDefinition(bwax);
  var base_env = Facade_helper$BwaxAdmin.getBaseEnv(bwax);
  var match$1 = Lang_eval$BwaxAdmin.evaluate_defs(base_env, ast);
  var env = match$1[0];
  var domain_env = make_domain_env(domainEnv);
  var web_env = make_web_env(webEnv);
  var context_000 = /* entity_dict */match[0];
  var context_001 = /* data_type_dict */match[1];
  var context_005 = /* query_cache */Runtime_common$BwaxAdmin.to_query_cache(queryCache);
  var context = /* record */Caml_chrome_debugger.record([
      "entity_dict",
      "data_type_dict",
      "dts",
      "env",
      "query_runner",
      "query_cache",
      "timers",
      "domain_env",
      "route_to",
      "web_env"
    ], [
      context_000,
      context_001,
      dts,
      env,
      queryRunner,
      context_005,
      undefined,
      domain_env,
      route_to,
      web_env
    ]);
  var args = maybePreparedData !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
        paramsValue,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            maybePreparedData,
            /* [] */0
          ])
      ]) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
        paramsValue,
        /* [] */0
      ]);
  return new Promise((function (resolve, param) {
                return Lang_runtime$BwaxAdmin.run_init_to_model(command_handlers, context, args, (function (result) {
                              if (result.tag) {
                                var e = result[0];
                                console.log("Un handled error", e);
                                return resolve(/* tuple */[
                                            undefined,
                                            e
                                          ]);
                              } else {
                                var match = result[0];
                                return resolve(/* tuple */[
                                            /* record */Caml_chrome_debugger.record([
                                                "env",
                                                "model",
                                                "commands",
                                                "msgs"
                                              ], [
                                                env,
                                                match[0],
                                                match[1],
                                                match[2]
                                              ]),
                                            undefined
                                          ]);
                              }
                            }), (function (result) {
                              if (result.tag) {
                                return resolve(/* tuple */[
                                            undefined,
                                            result[0]
                                          ]);
                              } else {
                                console.log("Abandon with the branch msgs", print_msgs(result[0]));
                                return /* () */0;
                              }
                            }));
              }));
}

function AdminPage(Props) {
  var value = Props.value;
  var onMsg = Props.onMsg;
  var pageID = Props.pageID;
  var dts = Props.dts;
  var queryRunner = Props.queryRunner;
  var route_to = Props.route_to;
  var bwax = Props.bwax;
  var contextRecordId = Props.contextRecordId;
  var contextEntityName = Props.contextEntityName;
  var renderLoadingPage = Props.renderLoadingPage;
  var activeTabName = Props.activeTabName;
  var viewEnv = Props.viewEnv;
  var refresh = Props.refresh;
  var refreshedAt = Props.refreshedAt;
  var onTabNamesReady = Props.onTabNamesReady;
  var page = Admin_page_common$BwaxAdmin.make_page(value);
  var tabNames;
  tabNames = typeof page === "number" || page.tag !== /* Multi */1 ? /* [] */0 : Plate$BwaxAdmin.List.assoc_keys(page[0]);
  React.useEffect((function () {
          if (onTabNamesReady !== undefined) {
            Curry._1(onTabNamesReady, Curry._1(Plate$BwaxAdmin.List.to_array, tabNames));
          }
          return ;
        }), /* array */[Plate$BwaxAdmin.Str.join(";", tabNames)]);
  var context = /* record */Caml_chrome_debugger.record([
      "page_id",
      "dts",
      "query_runner",
      "route_to",
      "bwax",
      "refreshPage",
      "refreshedAt",
      "contextRecordId",
      "contextEntityName",
      "onMsg",
      "viewEnv"
    ], [
      pageID,
      dts,
      queryRunner,
      route_to,
      bwax,
      refresh,
      refreshedAt,
      contextRecordId,
      contextEntityName,
      onMsg,
      viewEnv
    ]);
  var match = React.useState((function () {
          return false;
        }));
  var setReady = match[1];
  React.useEffect((function () {
          var all_entity_names = Curry._1(Plate$BwaxAdmin.List.to_array, Admin_page_common$BwaxAdmin.gather_entity_names(page));
          Facade_helper$BwaxAdmin.prepare(all_entity_names, bwax).then((function (param) {
                  Curry._1(setReady, (function (param) {
                          return true;
                        }));
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), ([]));
  var renderLayout = function (layout) {
    switch (layout.tag | 0) {
      case /* Wide_and_narrow */0 :
          return React.createElement(AdminLayout$BwaxAdmin.WideAndNarrow.make, {
                      context: context,
                      wideList: layout[0],
                      narrowList: layout[1]
                    });
      case /* Full */1 :
          return React.createElement(AdminLayout$BwaxAdmin.Full.make, {
                      context: context,
                      topList: layout[0],
                      wideList: layout[1],
                      narrowList: layout[2]
                    });
      case /* Widget_and_bar */2 :
          return React.createElement(AdminLayout$BwaxAdmin.WidgetAndBar.make, {
                      context: context,
                      side: layout[0],
                      maybeWidth: layout[1],
                      widget: layout[2],
                      bar: layout[3]
                    });
      case /* Custom_layout */3 :
          return Admin_widget$BwaxAdmin.render_custom_page(context, "non-key", layout[0]);
      
    }
  };
  var renderSingleWidget = function (widget) {
    return React.createElement(AdminLayout$BwaxAdmin.OneWidget.make, {
                context: context,
                widget: widget
              });
  };
  if (match[0]) {
    if (typeof page === "number") {
      return "Not implemented";
    } else {
      switch (page.tag | 0) {
        case /* Single */0 :
            return renderLayout(page[0]);
        case /* Multi */1 :
            var tabs = page[0];
            var tab = Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                    return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.head(tabs));
                  }), Plate$BwaxAdmin.$$Option.and_then((function (tabName) {
                        return Plate$BwaxAdmin.List.assoc(tabName, tabs);
                      }), activeTabName));
            if (tab !== undefined) {
              var match$1 = tab;
              if (match$1.tag) {
                return renderSingleWidget(match$1[0]);
              } else {
                return renderLayout(match$1[0]);
              }
            } else {
              return "No tab";
            }
        case /* OneWidget */2 :
            return renderSingleWidget(page[0]);
        
      }
    }
  } else {
    return Curry._1(renderLoadingPage, /* () */0);
  }
}

var make = AdminPage;

exports.print_msgs = print_msgs;
exports.make_domain_env = make_domain_env;
exports.make_web_env = make_web_env;
exports.runInit = runInit;
exports.make = make;
/* react Not a pure module */
