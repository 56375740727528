
import React from 'react'

import { getFieldLoading, getLoadingParams } from 'Client/js/builders/display/fieldLoadings'

import TableComp from 'Client/js/newTable/Table';

import './DataTable.less'

import isEqual from "lodash/isEqual";

export const MemoTable = React.memo(props => {

    const {
        data, dataName, tableConfig, currentPage, getRowKey,
        pageSize, pageSizeOptions, onPageSizeChange,
        pagination, tableColumns, operationColumn,
        setTableConfig, onSort, onPageChange,

    } = props

    const actualData = data ? data.rows : [];

    const estimatedRowHeight = getEstimatedRowHeight(tableColumns, operationColumn !== undefined);

    return (
        <TableComp
            rowKey="id"
            className="admin--data-table"
            columns={tableColumns}
            dataSource={actualData}
            getRowKey={getRowKey}
            virtualized={true}
            initializing={data === undefined}
            estimatedRowHeight={estimatedRowHeight}
            initializingBody={<InitializingBody pageSize={pageSize} hasOperationColumn={operationColumn !== undefined} />}
            tableConfig={tableConfig}
            onTableConfigChange={({ hiddenColumns, fixedLeftColumns, columnWidths }) => { // table column customization
                // console.log("fixedLeftColumns >>> ",fixedLeftColumns)
                setTableConfig({
                    hiddenColumns,
                    fixedLeftColumns,
                    columnWidths
                })
            }}
            onSort={onSort}
            totalCount={data ? data.totalCount : 0}
            pagination={{
                ...pagination,
                currentPage,
                onPageChange,
                pageSize,
                pageSizeOptions,
                onPageSizeChange,
            }}
        />
    )

}, (prevProps, currentProps) => {

    const propsToCheck = ["data", "tableConfig", "currentPage", "pageSize", "pageSizeOptions"];

    return propsToCheck.every(name => {
        // console.log(">>>>>");
        const t0 = performance.now();

        // TODO 后面要考虑一下这个性能问题：
        // const result = JSON.stringify(prevProps[name]) == JSON.stringify(currentProps[name]);

        const result = isEqual(prevProps[name], currentProps[name]);
        // const result =  prevProps[name] == currentProps[name];

        const t1 = performance.now();
        // console.log(name, result, "used", t1 - t0);
        return result
    })

    // return (
    //     prevProps.data === currentProps.data
    //     && prevProps.tableConfig === currentProps.tableConfig
    //     && prevProps.currentPage === currentProps.currentPage
    // );
})



function getLastUnFixedColIdx(cols) {
    const firstFixedRightCotIdx = cols.findIndex(c => c.fixed && c.fixed === 'right')
    if (firstFixedRightCotIdx === -1) {
        return cols.length - 1
    } else {
        return firstFixedRightCotIdx - 1
    }
}

function InitializingBodyRow({ columns, customLoadingParams, style = {} }) {

    return (
        <div
            style={{
                borderBottom: "1px solid #f4f4f4",
                display: "flex",
                alignItems: 'center',
                ...style
            }}>
            {
                columns.map((c, idx) => {
                    const lastUnFixedColIdx = getLastUnFixedColIdx(columns)
                    const lastUnFixedColStyle = idx === lastUnFixedColIdx ? { flexGrow: 1 } : {}
                    return (
                        <div
                            key={c.key}
                            style={{
                                width: c.width || "auto",
                                textAlign: c.align || "left",
                                // padding: "1rem",
                                padding: "0.75rem",
                                flexShrink: 0,
                                boxSizing: "border-box",
                                overflow: "hidden",
                                ...lastUnFixedColStyle
                            }}>
                            {getFieldLoading({ type: c.dataType }, customLoadingParams)}
                        </div>
                    )
                })
            }
        </div>
    )
}

const customLoadingParams = {
    "Image": {
        width: 50,
        height: 50
    }
}


//每一行的高度都是相同的，通过field loading的高度以及每行的padding以及border计算行的高度
const getEstimatedRowHeight = (tableColumns, hasOperationColumn) => {
    const rowBorderHeight = 1
    const operationButtonLoadingHeight = 28
    // const rowPaddingHeight = typeof (document) !== "undefined" ? parseFloat(getComputedStyle(document.documentElement).fontSize) : 16

    const remSize = typeof (document) !== "undefined" ? parseFloat(getComputedStyle(document.documentElement).fontSize) : 16;
    const rowPaddingHeight = 0.5 * remSize;

    const getMaxFieldLoadingHeight = (columns) => {
        return columns.reduce((acc, current) => {
            const fieldLoadingParams = customLoadingParams[current.dataType] || getLoadingParams()[current.dataType]
            if (fieldLoadingParams !== undefined) {
                return Math.max(acc, (fieldLoadingParams.height || 0))
            }
            return acc
        }, 0)
    }

    const maxFieldLoadingHeight = getMaxFieldLoadingHeight(tableColumns);
    const estimatedContentHeight = hasOperationColumn ? Math.max(maxFieldLoadingHeight, operationButtonLoadingHeight) : maxFieldLoadingHeight;
    const minRowHeight = 44;

    return Math.max(2 * rowPaddingHeight + estimatedContentHeight + rowBorderHeight, minRowHeight)
}

function InitializingBody({ tableColumns, fixedColumns, pageSize = 10, hasOperationColumn }) {

    const columns = fixedColumns || tableColumns;

    // console.log("rowHeights", rowHeights);

    const processedColumns = columns.map(c => {
        if (c.key === "操作") {
            return {
                ...c,
                dataType: "Button"
            }
        }
        return c
    })

    const normalColumns = processedColumns.slice(0, 10)

    const rowsCount = pageSize - 2 // loading 少两行吧
    const bodyList = []

    const commonRowEleProps = {
        customLoadingParams,
        style: {
            height: getEstimatedRowHeight(tableColumns, hasOperationColumn)
        }
    }

    for (let i = 0; i < rowsCount; i++) {

        const rowEle = React.cloneElement(
            <InitializingBodyRow />,
            {
                key: `row${i}`,
                columns: normalColumns,
                ...commonRowEleProps
            }
        )
        bodyList.push(rowEle)
    }


    return (
        <div className="initializing-body-container">
            <div>
                {bodyList}
            </div>
        </div>
    )
}


