
import React, { useRef } from 'react';

import CommonPopup from './CommonPopup';
import Button from 'rsuite/Button'

import { MdError } from "react-icons/md";

import './Popconfirm.less'

export default function Popconfirm (props) {

    const visibleSetterRef = useRef(null);

    const { 
        className, arrowSize = 4, arrowColor = "white", 
        okText = "Yes",
        cancelText = "No",
        title,

        onConfirm,
        onCancel,

        children,
        
        ...remaining 
    } = props;

    function close () {
        if(visibleSetterRef.current) {
            visibleSetterRef.current(false)
        }
    }

    function cancel () {
        if(onCancel) {
            onCancel()
        } 

        close()

    }

    function confirm () {
        if(onConfirm) {
            onConfirm()
        } 

        close();

    }

    const content = (
        <div>
            <div className="bw-popconfirm-message">
                <MdError color={"#faad14"}/>
                <p>{title}</p>
            </div>
            <div className="bw-popconfirm-buttons">
                <Button onClick={() => cancel()}>{cancelText}</Button>
                <Button appearance="primary" onClick={() => confirm()}>{okText}</Button>
            </div>
        </div>
    )

    return (
        <CommonPopup 
            className={"bw-popconfirm" + (className ? " " + className : "")} 
            { ...{
                arrowColor, arrowSize,

                content,

                bindVisibleSetter: setter => {
                    visibleSetterRef.current = setter;
                },

                // ...placementMap[placement],
                ...remaining
            }
            }
        >
            { children }
        </CommonPopup>

    )
}

export function create (props) {
    return <Popconfirm {...props} />
}