// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var JsonUtils$BwaxAdmin = require("../../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var React$1 = require("@monaco-editor/react");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var AdminDisplayOptions$BwaxAdmin = require("../../AdminDisplayOptions.bs.js");

function Input_AdminDisplayOption(Props) {
  Props.params;
  var value = Props.value;
  var onChange = Props.onChange;
  var editor = React.useRef(undefined);
  var match = React.useState((function () {
          var json = Caml_obj.caml_equal(Plate$BwaxAdmin.$$Option.with_default(null, value), null) ? Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "shownInMenu",
                      true
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "listTabs",
                          AdminDisplayOptions$BwaxAdmin.TabPage.defaultListTabJsons
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "detailTabs",
                              AdminDisplayOptions$BwaxAdmin.TabPage.defaultDetailTabJsons
                            ],
                            /* [] */0
                          ])
                      ])
                  ])) : Plate$BwaxAdmin.$$Option.with_default(null, value);
          return JSON.stringify(json, null, 2);
        }));
  var editorDidMount = function (param, e) {
    editor.current = Caml_option.some(e);
    return e.onDidChangeModelContent((function (param) {
                  var value = e.getValue();
                  var match = JsonUtils$BwaxAdmin.parse(value);
                  if (match !== undefined) {
                    return Curry._1(onChange, Caml_option.valFromOption(match));
                  } else {
                    return /* () */0;
                  }
                }));
  };
  return React.createElement("div", {
              className: Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.width(/* `percent */Caml_chrome_debugger.polyVar("percent", [
                              -119887163,
                              100.0
                            ])),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.height(Css.pct(100.0)),
                          /* [] */0
                        ])
                    ]))
            }, React.createElement(React$1.default, {
                  value: match[0],
                  theme: "vs",
                  language: "json",
                  editorDidMount: editorDidMount,
                  options: {
                    fontSize: 13,
                    autoIndent: "brackets"
                  }
                }));
}

var make = Input_AdminDisplayOption;

exports.make = make;
/* Css Not a pure module */
