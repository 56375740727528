
import React, { useEffect, useState } from 'react';

import * as MdIcons from "react-icons/md";

import { CopyToClipboard } from 'react-copy-to-clipboard'

import ColoredTag from './ui/components/ColoredTag';

import './TestIcons.less';

import { CompactPicker, TwitterPicker, SketchPicker } from 'react-color';

import Popover from 'bwax-ui/components/legacy/Popover';


import Input from 'rsuite/Input';
import Button from 'rsuite/Button';

import { Helmet } from 'react-helmet-async';

import Message from 'Client/js/ui/Message';

export default function () {

    const [tags, setTags] = useState([]);

    const [selectedTags, setSelectedTags] = useState([]);

    const [keyword, setKeyword] = useState("");

    const [color, setColor] = useState("#5f6368");


    const [loadedMore, setLoadedMore] = useState(false);

    useEffect(() => {
        let wordCounts = {};
        Object.keys(MdIcons).forEach(iconName => {
            const words = iconName.substring(2).replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(" ");
            words.forEach(w => {
                const count = wordCounts[w];
                if (count) {
                    wordCounts[w] = count + 1;
                } else {
                    wordCounts[w] = 1
                }
            })
        });
        const wordAndCounts = Object.keys(wordCounts).map(w => [w, wordCounts[w]]).sort((a, b) => {
            return b[1] - a[1];
        });

        setTags(wordAndCounts.slice(0, 12).map(([n, _]) => n));

        setTimeout(() => {
            setLoadedMore(true)
        }, 100)

    }, []);


    const candidates = Object.keys(MdIcons).filter(iconName => {
        if (selectedTags.length === 0) {
            return true
        } else {
            return iconName.indexOf(selectedTags[0]) !== -1;
        }

    }).filter(iconName => {
        if (keyword.trim().length === 0) {
            return true
        } else {
            return iconName.toLowerCase().indexOf(keyword.trim().toLowerCase()) !== -1;
        }
    });

    const toRendered = loadedMore ? candidates : candidates.slice(0, 100);


    return (
        <div className="test-icons">
            <Helmet>
                <title>{ "ICON 查看" }</title>
            </Helmet>
            <div className="top-tool-bar">
                <div className="tags-filter">
                    {tags.map(t => {

                        const selected = selectedTags.indexOf(t) !== -1;
                        const color = selected ? "B20" : "N20";

                        const onClick = _ => {
                            if (selected) {
                                setSelectedTags([])
                            } else {
                                setSelectedTags([t])
                            }
                        }

                        return (
                            <ColoredTag {...{ key: t, text: t, color, onClick }} />
                        )
                    })}
                </div>
                <div className="input-filter">
                    <Input value={keyword} placeholder={"输入关键词查询"} onChange={v => {
                        setKeyword(v);
                        setLoadedMore(false);
                        setTimeout(v => {
                            setLoadedMore(true)
                        }, 100)
                    }} />
                </div>
                <div className="color-selector">
                    <Popover
                        positions={["bottom", "left"]}
                        trigger="click"
                        align="end"
                        style={{
                            // padding: "1rem"
                            margin: "8px"
                        }}
                        content={<TwitterPicker {...{
                            triangle: "hide",
                            color,
                            onChange: c => {
                                setColor(c && c.hex)
                            }

                        }}/>}>
                        <Button>选择颜色</Button>
                    </Popover>
                    
                </div>
            </div>
            <div className="icon-list-wrapper">
                <div className="icon-list">
                    { toRendered.map(iconName => {
                        const Icon = MdIcons[iconName];
                        const displayName = iconName.substring(2).replace(/([a-zA-Z])([A-Z0-9])/g, '$1 $2');

                        const code = color && color !== "#5f6368" ? 
                            `mdIcon [ textSize (Color.raw "${color}")] "${iconName.substring(2)}"` : 
                            `mdIcon [] "${iconName.substring(2)}"`;

                        const onCopy = () => {
                            Message.success(`已复制 ${code}`, 1)
                        };

                        return (
                            <CopyToClipboard text={code} key={iconName} onCopy={onCopy}>
                                <div className="icon-cell" key={iconName}>
                                    <div className="icon" style={{
                                        color
                                    }}>
                                        <Icon />
                                    </div>
                                    <div className="name">
                                        {displayName}
                                    </div>
                                </div>
                            </CopyToClipboard>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}