// encapsulate all the block related operations

import { flattenListOrNestedList, mapListOrNestedList, filterListOrNestedList } from "./listUtils";


function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
const createId = () => s4() + s4();


/// helpers:
function insertAt(index, value, array) {
    return [
        ...array.slice(0, index), value, ...array.slice(index)
    ]
}
function removeValue(value, array) {
    return array.filter(v => v != value);
}

function updateValueAt(index, value, array) {
    return [
        ...array.slice(0, index), value, ...array.slice(index + 1)
    ]
}

// helpers ends


// it is not mutable
export default class Block {
    constructor({ id, type, childIds, attributes, blockTypes = [] }) {
        this.id = id || createId(); // if id is not given, create a new one.
        

        const blockType = blockTypes.find(bt => bt.name == type || bt.legacyName == type);
        if(!blockType) {
            console.log("Unknown blockType", this.type, blockTypes);
            throw new Error("Unknown block type:" + this.type);
        }

        this.type = blockType.name;

        this.childIds = childIds || (blockType.slots ? Array(blockType.slots.length).fill([]) : [])
        this.attributes = attributes || {};
       

        this._blockType = blockType;
        this._blockTypes = blockTypes;
    }

    toJSON() {
        return {
            id: this.id, type: this.type, childIds: this.childIds, attributes: this.attributes
        }
    }

    get blockType () {
        return this._blockType
    }

    getAncestors(blockDict) {
        function collectParentBlocks(block, parents){
            const parent = Object.values(blockDict).find(b => flattenListOrNestedList(b.childIds).indexOf(block.id) != -1);
            if(!parent) {
                return parents
            } else {
                return collectParentBlocks(parent, [ ...parents, parent ]);
            }
        }
        return collectParentBlocks(this, []);
    }

    getDescendants(blockDict) {
        function getDescendantsById(blockId) {
            const block = blockDict[blockId];
            return flattenListOrNestedList(block.childIds).reduce((acc, cid) => {
                return [...acc, blockDict[cid], ...getDescendantsById(cid)]
            }, [])
        }
        return getDescendantsById(this.id)

    }

    cloneWith({ id, type, childIds, attributes}) {
        return new Block({
            id: id || this.id,
            type: type || this.type,
            childIds: childIds || this.childIds,
            attributes: attributes || this.attributes,

            blockTypes: this._blockTypes,
        })
    }

    removeChild(childId) {
        return this.cloneWith({       
            childIds: filterListOrNestedList(this.childIds, id => id !== childId)
        })
    }

    hasChild(childId) {
        return flattenListOrNestedList(this.childIds).indexOf(childId) !== -1;
    }

    findSlotContaining(childId) {
        if(Array.isArray(this.childIds[0])) {
            // muiltiple slot
            const slot = this.childIds.find(a => a.indexOf(childId) !== -1);
            return slot
        } else {
            if(this.childIds.indexOf(childId) !== -1) {
                return this.childIds
            } 
        }
        // or return undefined
    }


    getChildId({ slotIndex, index }) {
        if(Array.isArray(this.childIds[0])) {
            // muiltiple slot
            return (this.childIds[slotIndex] || [])[index]
        } else {
            return this.childIds[index]
        }
    }


    getNextSiblingOf(childId) {
        // find there the child is in
        function getNextOne(id, arr) {
            const baseIndex = arr.indexOf(id);
            if(baseIndex !== -1 && baseIndex !== arr.length - 1) {
                // exists and not the last one
                return arr[baseIndex + 1]
            }   
        }
        const arr = this.findSlotContaining(childId) || [];
        return getNextOne(childId, arr);
    }

    getPrevSiblingOf(childId) {
        function getPrevOne(id, arr) {
            const baseIndex = arr.indexOf(id);
            if(baseIndex !== -1 && baseIndex !== 0) {
                // exists and not the last one
                return arr[baseIndex - 1]
            }   
        }
        const arr = this.findSlotContaining(childId) || [];
        return getPrevOne(childId, arr);
    }



    duplicate(baseDict) {
        const blockTypes = this._blockTypes;
        function copyBlock (block) {
            const { id: _, childIds, ...toCopy } = block;
            if(childIds && childIds.length > 0) {
                const childAndDescendants = mapListOrNestedList(childIds, childId => copyBlock(baseDict[childId]));
                
                const newChildIds = mapListOrNestedList(childAndDescendants, ({ newBlock }) => newBlock.id);
                const newBlock = new Block({ ...toCopy, childIds: newChildIds, blockTypes });
                const allDescendants = flattenListOrNestedList(childAndDescendants).reduce((acc, { newDict }) => {
                    return { ...acc, ...newDict }
                }, {});
                return {
                    newBlock,
                    newDict: { [newBlock.id]: newBlock, ...allDescendants }
                }
    
            } else {
                const newBlock = new Block({ ...toCopy, childIds: [], blockTypes  });
                return {
                    newBlock,
                    newDict: { [newBlock.id]: newBlock }
                }
            }
        }
        return copyBlock(this);
    }

    everyChild(f) {
        return flattenListOrNestedList(this.childIds).every(f);
    }

    mapChildren(f, slotIndex) {
        if(slotIndex === undefined) {
            // this assume there only one slot
            return flattenListOrNestedList(this.childIds).map(f)
        } else {
            const ids = this.childIds[slotIndex] || [];
            return ids.map(f)
        }
    }

    insertChild(childId, targetIndex, slotIndex = 0) {
        const newChildIds = (() => {
            if(Array.isArray(this.childIds[0])) {
                const existingList = this.childIds[slotIndex];           
                return updateValueAt(
                    slotIndex,
                    insertAt(targetIndex, childId, existingList),
                    this.childIds,
                )
            } else {
                return insertAt(targetIndex, childId, this.childIds)
            }
        })();
        return this.cloneWith({ childIds: newChildIds });
    }

    // return { index, slotIndex }
    indexOfChild(blockId) {
        if(Array.isArray(this.childIds[0])) {
            for(let slotIndex in this.childIds) {
                const index = this.childIds[slotIndex].indexOf(blockId);
                if(index !== -1) {
                    return { index, slotIndex: parseInt(slotIndex) }
                }
            }
        } else {
            const index = this.childIds.indexOf(blockId);
            if(index !== -1) {
                return { index }
            }
        }
        return null
    }

    // return { index, slotIndex }
    lastIndex () {
        if(Array.isArray(this.childIds[0])) {
            const slotIndex = this.childIds.length - 1;
            const index = this.childIds[slotIndex].length - 1;
            return { slotIndex, index }
        } else {
           return { index: this.childIds.length - 1 }
        }
    }

}

