

export default function buildTestTage() {

    const setting = {
        // timer: [{
        //     cond: `\${true}`,
        //     interval: 2000,
        //     action: {
        //         type: "Log",
        //         text: "Cool"
        //     }
        // }],
        // redirect: {
        //     toUrl: "https://www.qunfengshe.com"
        // },
        __query__: {
            et: {
                entityName: "数据实体",
                type: "findOne",
            },
            pg: {
                entityName: "页面",
                type: "findOne",
                excluded: "${!exists(ivk)}"
            },
            __for__: {
                head: {
                    title: "测试网页"
                },
                layout: {
                    content: {
                        type_: "box",
                        content: {
                            type_: "text",
                            text: "HELLO WORLD ${et.名称} ${pg.名称}"
                        }
                    }
                }
            }
        }
    }

    return setting
}
