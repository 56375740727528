

import React, { useEffect, useState } from 'react'
import { runDataQuery } from 'bwax/query/runClientQuery';

import { Select } from '@arco-design/web-react';
const Option = Select.Option;

export default function TenantSelect({ tenant, setTenant }) {
    //
    const [ tenants, setTenants ] = useState();

    async function loadTenants () {    
        const result = await runDataQuery({ sandbox: true })(QueryText)({
        });
        const { error, data } = JSON.parse(result);
        if(data && data.searchTenant) {
            setTenants(data.searchTenant)
        }
    }

    useEffect(() => {
        loadTenants();
    }, [])


    return (
        <Select
            placeholder='选择租户'
            size="mini"
            style={{ width: 108 }}
            value={tenant && tenant.code}
            onChange={(value) =>{
                setTenant(tenants.find(t => t.code === value))
            }}
        >
            {(tenants || []).map((tenant) => (
                <Option key={tenant.code} value={tenant.code}>
                    {tenant.name} / { tenant.code}
                </Option>
            ))}
        </Select>
    )
}

const QueryText = `
query($keyword: String){
    searchTenant(keyword: $keyword, limit: 10){
      name
      code
    }
}
`
