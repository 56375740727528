// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../../lib/bwax-ui/re/BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var ColorTypes$BwaxAdmin = require("../../../lib/bwax-ui/re/ColorTypes.bs.js");
var Color_helper$BwaxAdmin = require("../../../lib/bwax-ui/ml/color_helper.bs.js");

function BwColoredButton(Props) {
  var type_ = Props.type_;
  var match = Props.ghost;
  var ghost = match !== undefined ? match : false;
  var disabled = Props.disabled;
  Props.size;
  var label = Props.label;
  Props.loading;
  var match$1 = Props.compact;
  var compact = match$1 !== undefined ? match$1 : false;
  var color = Props.color;
  var match$2 = Props.inMenu;
  var inMenu = match$2 !== undefined ? match$2 : false;
  var domRef = Props.domRef;
  var onClick = Props.onClick;
  var labelText = compact ? label : Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.Str.split("", label));
  var lightGray = /* `raw */Caml_chrome_debugger.polyVar("raw", [
      5690856,
      "#d9d9d9"
    ]);
  var colorValue = type_ === "primary" ? /* `raw */Caml_chrome_debugger.polyVar("raw", [
        5690856,
        "#3e8ecd"
      ]) : (
      type_ === "danger" ? /* `raw */Caml_chrome_debugger.polyVar("raw", [
            5690856,
            "#ff4d4f"
          ]) : Plate$BwaxAdmin.$$Option.and_then(ColorTypes$BwaxAdmin.toColor, color)
    );
  var defaultColorRules_000 = /* `borderWidth */Caml_chrome_debugger.polyVar("borderWidth", [
      -690257542,
      /* `px */Caml_chrome_debugger.polyVar("px", [
          25096,
          1
        ])
    ]);
  var defaultColorRules_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
          -690668329,
          lightGray
        ]),
      /* [] */0
    ]);
  var defaultColorRules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      defaultColorRules_000,
      defaultColorRules_001
    ]);
  var buildColorRules = function (c, original) {
    if (ghost || inMenu) {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                    -481689226,
                    c
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
                        -690668329,
                        c
                      ]),
                    /* [] */0
                  ])
              ]);
    } else {
      var match = Color_helper$BwaxAdmin.is_color_dark(original);
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
                    222776981,
                    c
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
                        -690668329,
                        c
                      ]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                            -481689226,
                            match ? /* inverseText */642505853 : /* `rgba */Caml_chrome_debugger.polyVar("rgba", [
                                  -878128972,
                                  /* tuple */[
                                    0,
                                    0,
                                    0,
                                    0.65
                                  ]
                                ])
                          ]),
                        /* [] */0
                      ])
                  ])
              ]);
    }
  };
  var colorRules;
  if (colorValue !== undefined) {
    var c = colorValue;
    colorRules = buildColorRules(c, c);
  } else {
    colorRules = /* [] */0;
  }
  var hoverRules;
  if (disabled) {
    hoverRules = /* [] */0;
  } else {
    var rules;
    if (colorValue !== undefined) {
      var c$1 = colorValue;
      rules = buildColorRules(Color_helper$BwaxAdmin.lighten(10.0, c$1), c$1);
    } else {
      rules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
              -690668329,
              Color_helper$BwaxAdmin.darken(10.0, lightGray)
            ]),
          /* [] */0
        ]);
    }
    hoverRules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `hover */Caml_chrome_debugger.polyVar("hover", [
            728032700,
            rules
          ]),
        /* [] */0
      ]);
  }
  var activeRules;
  if (disabled) {
    activeRules = /* [] */0;
  } else {
    var rules$1;
    if (colorValue !== undefined) {
      var c$2 = colorValue;
      rules$1 = buildColorRules(Color_helper$BwaxAdmin.darken(10.0, c$2), c$2);
    } else {
      var param = Color_helper$BwaxAdmin.darken(10.0, lightGray);
      rules$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
              -690668329,
              Color_helper$BwaxAdmin.darken(10.0, param)
            ]),
          /* [] */0
        ]);
    }
    activeRules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `active */Caml_chrome_debugger.polyVar("active", [
            373703110,
            rules$1
          ]),
        /* [] */0
      ]);
  }
  var disabledRules = disabled ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* notAllowed */939907157,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                -481689226,
                lightGray
              ]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
                    -690668329,
                    lightGray
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
                        222776981,
                        /* `raw */Caml_chrome_debugger.polyVar("raw", [
                            5690856,
                            "#fafafa"
                          ])
                      ]),
                    /* [] */0
                  ])
              ])
          ])
      ]) : /* [] */0;
  var inMenuRules;
  if (inMenu) {
    var hoverRules$1 = disabled ? /* [] */0 : /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `hover */Caml_chrome_debugger.polyVar("hover", [
              728032700,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
                      222776981,
                      /* N10 */3889837
                    ]),
                  /* [] */0
                ])
            ]),
          /* [] */0
        ]);
    inMenuRules = Plate$BwaxAdmin.List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `borderWidth */Caml_chrome_debugger.polyVar("borderWidth", [
                    -690257542,
                    /* `px */Caml_chrome_debugger.polyVar("px", [
                        25096,
                        0
                      ])
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `paddingXY */Caml_chrome_debugger.polyVar("paddingXY", [
                        -303792878,
                        /* tuple */[
                          /* `rem */Caml_chrome_debugger.polyVar("rem", [
                              5691738,
                              2.0
                            ]),
                          /* `rem */Caml_chrome_debugger.polyVar("rem", [
                              5691738,
                              0.375
                            ])
                        ]
                      ]),
                    /* [] */0
                  ])
              ]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                hoverRules$1,
                /* [] */0
              ])
          ]));
  } else {
    inMenuRules = /* [] */0;
  }
  var domRefRules = domRef !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `domRef */Caml_chrome_debugger.polyVar("domRef", [
            762661873,
            Caml_option.valFromOption(domRef)
          ]),
        /* [] */0
      ]) : /* [] */0;
  return BaseUI$BwaxAdmin.button(Plate$BwaxAdmin.List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* `paddingXY */Caml_chrome_debugger.polyVar("paddingXY", [
                            -303792878,
                            /* tuple */[
                              /* `rem */Caml_chrome_debugger.polyVar("rem", [
                                  5691738,
                                  1.0
                                ]),
                              /* `rem */Caml_chrome_debugger.polyVar("rem", [
                                  5691738,
                                  0.25
                                ])
                            ]
                          ]),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* `borderRounded */Caml_chrome_debugger.polyVar("borderRounded", [
                                -1041690463,
                                /* `px */Caml_chrome_debugger.polyVar("px", [
                                    25096,
                                    2
                                  ])
                              ]),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* noSelect */977475101,
                                /* [] */0
                              ])
                          ])
                      ]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        defaultColorRules,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            colorRules,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                hoverRules,
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    activeRules,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        disabledRules,
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            inMenuRules,
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                domRefRules,
                                                /* [] */0
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])), /* record */Caml_chrome_debugger.record([
                "onPress",
                "disabled",
                "unstyled"
              ], [
                (function (param) {
                    Curry._1(onClick, /* () */0);
                    return Promise.resolve(/* () */0);
                  }),
                disabled,
                true
              ]), (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxAdmin.text(/* [] */0, labelText, param, param$1, param$2, param$3);
              }), undefined, /* column */-963948842, /* [] */0, "key");
}

var make = BwColoredButton;

exports.make = make;
/* Plate-BwaxAdmin Not a pure module */
