
import React from 'react'

import Message from 'Client/js/ui/Message';

import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Icon } from '@ant-design/compatible';

export default function Copy (props) {
    const { style, text } = props
    return (
        <CopyToClipboard text={text} onCopy={() => Message.success("复制成功~")}>
            <Icon
                type="copy"
                size="small"
                style={ style || {} }>
            </Icon>
        </CopyToClipboard>
    )
}