
import invariant from "invariant"

import { buildDeleteRecordMutation } from 'bwax/query/QueryBuilder'

import { invalidateCache } from 'bwax-ui/legacy/store/useDataLoader'

import Message from "Client/js/ui/actions/Message";

export default function DeleteRecord ({ allEntities, allDataTypes, queryRunner }) {

    return async function (params) {

        const { id, entityName } = params

        const entity = allEntities.find(e => e.name === entityName)

        invariant(entity, "Entity not found: " + entityName)

        const { 
            queryText, buildInput, extractResult 
        } = buildDeleteRecordMutation(
            entity, allEntities, allDataTypes, { queryDepth: 1 }
        )

        const input = buildInput(id)
        const result = await queryRunner(queryText, {
            input
        })

        /// TODO error handling
        const { errors } = JSON.parse(result);
        if(errors && errors.length > 0) {
            let message = errors[0].message;
            Message()({text: message, level: "error"});
            console.error("删除出错", errors);
            throw Error(message);
        } else {
            /// invalidate cache if succeeded
            invalidateCache()
            const data = extractResult(result)
            return data
        }


    }
}