
import addPrefix from 'bwax-ui/addPrefix'

import design from './design'

import ID from './ID'

import Plain from './Plain'
import Boolean from './Boolean'
import DateTime from './DateTime'
import Select from './Select'

import Number from './Number'

import FieldOptions from './FieldOptions'

import PicturesWall from './PicturesWall'
import RichTextPreview from './RichTextPreview'
import AttachmentsPreview from './AttachmentsPreview'

import RolePermissionDisplay from './RolePermissionDisplay'


export default addPrefix('display', {

    ...design,

    ID,
    Plain,
    Boolean,
    Number,
    Select,
    DateTime,
    PicturesWall,
    RichTextPreview,
    AttachmentsPreview,

    FieldOptions,

    RolePermissionDisplay,


})