
import React from 'react'
import {getSelectedBlock, mergeCurrentBlockData} from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

export default function (props) {

  const {getEditorState, setEditorState, alignment, iconClass} = props
  const selectedBlock = getSelectedBlock(getEditorState())
  const currentAlignment = selectedBlock ? selectedBlock.getData().get("textAlign") : null

  const toggleAlignment = (event) => {

    event.preventDefault();
    const data = (currentAlignment === alignment ?
      { 'textAlign': undefined } : { 'textAlign': alignment })

    setEditorState(
      mergeCurrentBlockData(getEditorState(), data)
    )
  }

  return (
    <button className={"editor-button" + (currentAlignment === alignment ? " active" : "")}
            onClick={toggleAlignment}>
      <i className={iconClass} aria-hidden="true"></i>
    </button>
  )

}
