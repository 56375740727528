
import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'
import extractParseTree from 'bwax/ml/printer/extractParseTree';

import code_editing_helper from 'Client/ml/helpers/code_editing_helper.bs';
import prettyPrintLang from 'bwax/ml/printer/prettyPrintLang';


// 

export default function insertEntityQueryToModel({ 
    entity, syntaxTree,
    queryType = "one" // one list paged count
}) {
    const parseTree = extractParseTree(syntaxTree.tree.topNode, syntaxTree.codeText);
    const langTree = code_editing_helper.get_lang_tree(syntaxTree.codeText, parseTree);

    // 1. Add type alias
    // 2. add model field 
    // 3. add function that query - it should be pretty printed
    // 4. update initial model in init function and add function call to init function - they should be pretty printed

    const entityName = entity.name;

    const normalizedName = lang_entry_slim.normalize_name(entityName);

    const [typeAliasText, typeAliasPosition, aliasName] = code_editing_helper.add_type_alias_for_entity(entityName, queryType, langTree);
    // const { update: addTypeAlias, aliasName } = addTypeAliasForEntity(entityName, parseTree);

    const [
        modelFieldText, modelFieldPosition, fieldName, initValue, nameIndex
    ] = code_editing_helper.add_model_field(entityName, aliasName, queryType, langTree);

    // insert command function
    const [ commandFuncName, commandFuncText ] = (() => {
        if(queryType == "list") {
            return CodeBlock_list(normalizedName, fieldName, nameIndex)
        } else if(queryType == "paged") {
            return CodeBlock_listAll(normalizedName, fieldName, nameIndex)
        } else if(queryType == "count") {
            return CodeBlock_count(normalizedName, fieldName, nameIndex)
        } else {
            return CodeBlock_findOne(normalizedName, fieldName, nameIndex)
        }
        
    })();

    // insert before the init
    const positionToInsertCmdFunc = code_editing_helper.get_position_to_insert_cmd_func(langTree);

    const insertCmdFunc = { 
        from: positionToInsertCmdFunc, 
        codeText: "\n\n" + prettyPrintLang(120, commandFuncText)
    }

    const maybeInitFunctionChange = code_editing_helper.update_init_to_add_field_and_cmd(
        fieldName, initValue, commandFuncName, langTree
    );

    console.log(">>> init function change", maybeInitFunctionChange);

    const initFunctionChange = (() => {
        if (maybeInitFunctionChange) {
            const [codeText, from, to] = maybeInitFunctionChange;

            console.log(">>> code", codeText, prettyPrintLang(120, codeText));

            return { codeText: prettyPrintLang(120, codeText), from, to }
        }
    })();

    return [
        queryType == "count" ? null : {
            from: typeAliasPosition, codeText: typeAliasText
        }, {
            from: modelFieldPosition, codeText: modelFieldText
        },
        insertCmdFunc,
        initFunctionChange
    ].filter(x => !!x);
}


function CodeBlock_list(targetName, fieldName, nameIndex) {
    const funcName = `list_${targetName}` + (nameIndex > 1 ? `_${nameIndex}` : "");
    const funcText = `${funcName} = 
        Cmd_${targetName}.list { }
          |> done GotError
            (\\${fieldName} -> UpdateModel (\\model -> { model | ${fieldName} }))\n;`;
    
    return [ funcName, funcText ] 
}


function CodeBlock_listAll(targetName, fieldName, nameIndex) {
    const funcName = `listAll_${targetName}` + (nameIndex > 1 ? `_${nameIndex}` : "");
    const funcText = `${funcName} = 
        Cmd_${targetName}.listAll { }
          |> done GotError
            (\\${fieldName} -> UpdateModel (\\model -> { model | ${fieldName} = Just ${fieldName} }))\n;`;
    
    return [ funcName, funcText ] 
}

function CodeBlock_count(targetName, fieldName, nameIndex) {
    const funcName = `count_${targetName}` + (nameIndex > 1 ? `_${nameIndex}` : "");
    const funcText = `${funcName} = 
        Cmd_${targetName}.count { }
          |> done GotError
            (\\${fieldName} -> UpdateModel (\\model -> { model | ${fieldName} }))\n;`;
    
    return [ funcName, funcText ] 
}



function CodeBlock_findOne(targetName, fieldName, nameIndex) {
    const funcName = `find_${targetName}` + (nameIndex > 1 ? `_${nameIndex}` : "");
    const funcText = `${funcName} = 
        Cmd_${targetName}.findOne
            { }
        |> done GotError
            (\\${fieldName} -> UpdateModel (\\model -> { model | ${fieldName} }))\n;`;
    
    return [ funcName, funcText ] 



}
