// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function takeIn(subject, target) {
  Belt_Array.forEach(Object.keys(target), (function (key) {
          var match = Js_dict.get(target, key);
          if (match !== undefined) {
            subject[key] = Caml_option.valFromOption(match);
            return /* () */0;
          } else {
            return /* () */0;
          }
        }));
  return subject;
}

function takeInExcluding(subject, target, excludingKeys) {
  Belt_List.forEach(Belt_List.fromArray(Object.keys(target)), (function (key) {
          if (Belt_List.every(excludingKeys, (function (k) {
                    return k !== key;
                  }))) {
            var match = Js_dict.get(target, key);
            if (match !== undefined) {
              subject[key] = Caml_option.valFromOption(match);
              return /* () */0;
            } else {
              return /* () */0;
            }
          } else {
            return 0;
          }
        }));
  return subject;
}

function mergeWith(first, second) {
  return takeIn(takeIn({ }, first), second);
}

function add(dict, key, value) {
  return mergeWith(dict, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      key,
                      value
                    ],
                    /* [] */0
                  ])));
}

function remove(dict, key) {
  return takeInExcluding({ }, dict, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                key,
                /* [] */0
              ]));
}

exports.takeIn = takeIn;
exports.takeInExcluding = takeInExcluding;
exports.mergeWith = mergeWith;
exports.add = add;
exports.remove = remove;
/* No side effect */
