
import React, { useState } from 'react'


import { make as Button } from 'Client/re/widgets/Button.bs'

import FieldOptionsInput from './input/FieldOptionsInput';

import FieldDefaultValueInput from 'Client/js/ui/widgets/input/FieldDefaultValueInput';

import Drawer from 'Client/js/ui/widgets/Drawer';
import EditForm from 'Client/js/ui/widgets/EditForm';
import ValidationSourcing from 'Client/js/ui/widgets/ValidationSourcing'


import { getFieldsFromFieldItems } from 'Client/js/builders/builderUtils';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import { getFieldInput } from 'Client/js/builders/input/fieldInputs';


// const template = `你现在是一名企业的知识助手，你的公司是派勒公司。对于你不知道的知识，请不要编造。请用跟问题一样的语言回答。尽可能简洁一点。

// 问题：{{question}}
// `

export default function AddRecordButton(props) {

    // 一个按钮，一个抽屉

    const { params, ...env } = props;

    const { entityName, disabled, label, condition, options } = params;

    const { allEntities, allDataTypes, queryTarget } = env;

    const dataEntity = allEntities.find(e => e.name === entityName)
    const { fields } = dataEntity


    const {
        initialValues = {},
        fixedValues = {},
        defaultValues: passedInDefaultValues = { },

        excludedFields,
        includedFields,

        fieldItems,

        applicableIf,
        inputParams,

        customFieldInputs = {},

        reload,
        actions: { addRecord },

    } = options;

    /// fieldsToUse; 考虑的优先级是 fieldItems > includedFields > excludedFields.
    function getFieldsToUse() {
        if (fieldItems) {
            return getFieldsFromFieldItems(fieldItems, dataEntity, allEntities, allDataTypes, true)
        } else {
            return (
                fields.filter(
                    f => f.initializable
                ).filter(
                    /// 如果指定了 excludedFields， 那么不能是其中一个
                    f => excludedFields === undefined || excludedFields.length === 0 || excludedFields.indexOf(f.name) === -1
                ).filter(
                    /// 如果指定了 includedFields， 那么必须是其中一个
                    f => includedFields === undefined || includedFields.length === 0 || includedFields.indexOf(f.name) !== -1
                ).sort((a, b) => {
                    // 给 field 排序
                    /// 根据 displayWeight 从大到下排列
                    const getWeight = f => f.displayWeight || 0
                    // 重的在前面
                    return getWeight(b) - getWeight(a)
                }
                )
            )
        }
    }

    const rawFieldsToUse = getFieldsToUse().filter(f => f.initializable);

    const missingRequiredFields = fields.filter(f => {
        if (f.required && f.initializable && (f.options.default == undefined || f.defaultValue == undefined)) {
            return fixedValues[f.name] === undefined && !rawFieldsToUse.some(rf => rf.name == f.name)
        } else {
            return false
        }
    });

    const fieldsToUse = [...missingRequiredFields, ...rawFieldsToUse];


    /* --- 关于 validation reference 到 关联字段
        遍历 fieldsToUse 收集到所有的这种 validation 源
            对于创建的表单，由于关联数据还不存在，
            因此我们需要 1）由专门的 Widget 来根据 Input 值去后台获取 validation 源的值；
                    2）放进特定的 PageState 里
                    3) 这样

            对于编辑的表单，因为关联数据可能不会进入编辑状态，那么专门的 Widget 也需要根据当前记录去获取专门的 validation 源的值
                也许不需要他去获取，只要在初始阶段展开 Query 获取到对应的 validation 源的值就好
    
    */

    const allValidations = fieldsToUse.filter(f => f.options && f.options.validation).map(f => {
        return [f.options.validation, f]
    });

    // 使用 name 而不是 cname
    const allKeys = fieldsToUse.map(f => f.name);


    const defaultValues = {
        ...{
            ...(fieldsToUse.reduce((acc, f) => {
                const { name, defaultValue } = f;
                if (defaultValue !== undefined && defaultValue !== null) {
                    return {
                        ...acc,
                        [name]: defaultValue
                    }
                } else {
                    return acc
                }
            }, {})),
            ...passedInDefaultValues
        },
        // ...(
        //     fieldsToUse.some(f => f.name == "消息模板") ? {
        //         "消息模板": template
        //     } : {}
        // )
    }



    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, defaultValues, [], "no-key", false)

    const [validationValues, setValidationValues] = useState({});

    const items = fieldsToUse.map(f => {
        const { cname, name, key, desc, config = {}, options = {} } = f

        const customInput = (() => {

            // 这里是硬写的。 TODO 根据相关值编辑， 需要有一个更理想的解决方案
            if (name == "字段选项" && key == "options") {
                return (value, onChange, params) => (
                    <FieldOptionsInput
                        {...{
                            dataType: validated.字段类型, 
                            value, onChange
                        }}
                    />
                )

                // }

            } else if (name == "默认值" && key == "defaultValue") {

                return (value, onChange, params) => (
                    <FieldDefaultValueInput
                        params={{
                            ...params,
                            dataType: validated.字段类型,
                            dataOptions: validated.字段选项,
                            dataMultivalued: validated.多值,
                        }}
                        value={value}
                        onChange={onChange}
                    />
                );
            } else if (config.customInput) {
                return config.customInput
            } else {
                return customFieldInputs[cname || name]
            }


        })();

        function prepareValidation() {
            if (options.validation) {
                // 还有 list, ref, lookup, ...
                const { list } = options.validation
                function getLimitedValues() {
                    if (list) {
                        return list
                    } else {
                        return validationValues[f.name]
                    }
                }
                return {
                    limitedValues: getLimitedValues()
                }

            } else {
                return {}
            }
        };


        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/542
        const mergedParams = {
            ...((inputParams && inputParams[name]) ? inputParams[name] : {}), // 正式设计的时候，只留下其中之一
            ...(config.inputParams ? config.inputParams : {}),
            ...(prepareValidation()),
        }

        const specificTypeParams = {
            "Image": {
                width: 120,
                height: 120,
                processor: 'medium'
            }
        }
        /// add value, onChange
        const value = fixedValues[name] || editing[name];

        const onChange = v => {
            if (v !== value) {
                updateEditing({ [name]: v })
            }
        }

        function getInput() {

            const editKey = name;

            const inputParams = {
                ...(config && config.inputParams ? config.inputParams : {}),
                ...(prepareValidation())
            };

            const ips = {
                initialValue: initialValues[name],
                fixedValue: fixedValues[name],
                ...(specificTypeParams[f.type] || {}),
                ...mergedParams

            };

            let inputWidget = customInput || getFieldInput(
                f,
                editKey,
                {
                    allDataTypes, allEntities, dataEntity,
                    queryTarget
                }
            );

            function resolveFormValue(value) {
                if (typeof (value) === 'string') {
                    let m = /^form\[(.*)\]$/.exec(value);
                    if (m) {
                        const editKey = m[1];
                        return validated[editKey];
                    } else {
                        return value
                    }
                } else {
                    return value
                }
            }

            function processFormValue(ips) {
                return Object.keys(ips).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: resolveFormValue(ips[key])
                    }
                }, {})
            }


            return inputWidget(
                value,
                onChange,
                // params                    
                {
                    // ...((data[name] && typeof (data[name] == 'object')) ? data[name] : {}),
                    ...processFormValue(ips)
                },
                validated
            );

        }


        return {
            label: name,
            tip: (desc && desc !== name) ? desc : null,
            input: getInput(),
            editKey: f.name,
            required: f.required
        }
    })


    const [drawerVisible, setDrawerVisible] = useState(false)

    const [actionMenuVisible, setActionMenuVisible] = useState(false)

    if (condition === false) {
        return null
    }

    let button = (
        <Button params={{
            label: label || "新增",
            buttonType: "primary",
            disabled,
            onClick: () => {
                setDrawerVisible(true)
            }

        }}  {...env} />
    );


    let form = (
        <EditForm
            params={{
                prefix: "",
                applicableIf,
                items
            }}
            {...env}
            editingState={{ errors, validated }}
        />
    )


    function getTopActions() {

        const involvedEditKeys = fieldsToUse.filter(
            /// TODO 关于 fixedValue 的处理，应该做一个更好的抽象：
            /// 目前，fixedValue 在如下四个地方皆有处理：
            ///     1. 外部检测 dirtyValue
            ///     2. useEditingValue
            f => fixedValues[f.name] === undefined
        ).map(f => f.name)

        const hasDirtyValue = involvedEditKeys.some(key => dirtyValues[key] !== undefined)


        // all the require field must be there:
        const requiredFields = fieldsToUse.filter(
            f => f.required
        )
        const requiredEditKeys = requiredFields.map(f => f.name);

        const missingRequiredFields = requiredEditKeys.some(key => validated[key] === undefined || validated[key] === null)


        const noValues = involvedEditKeys.every(key => dirtyValues[key] === undefined)

        let cancelButton = hasDirtyValue ? {
            label: "撤销",
            onClick: () => rollback(involvedEditKeys)
        } : {
                label: "关闭",
                onClick: () => setDrawerVisible(false)
            };

        let confirmButton = {
            label: "保存",
            buttonType: 'primary',
            disabled: missingRequiredFields || noValues,
            onClick: async () => {

                const formData = fieldsToUse.reduce((acc, f) => {
                    const { name } = f
                    return {
                        ...acc,
                        [name]: dirtyValues[name]
                    }
                }, {})

                await addRecord({
                    entityName,
                    formData: {
                        ...formData,
                        ...fixedValues, // enforce fixed values.
                    }
                });

                await reload();

                setDrawerVisible(false);
                clearEditing(involvedEditKeys);

                // 3秒钟之后，再 reload 一次 
                setTimeout(() => {
                    reload();
                }, 3000)

            }

        }

        return [cancelButton, confirmButton]
    }

    const validationSourcing = (
        <ValidationSourcing
            params={{
                validations: allValidations,
                entityName,
                updateValidationValues: values => {
                    setValidationValues(prev => ({
                        ...prev,
                        ...values
                    }))
                },
                formValues: validated
            }}
            {...env}
        />
    )

    const drawer = (
        <Drawer
            params={{
                visible: drawerVisible,
                size: "medium",
                hide: () => setDrawerVisible(false),

                showActionMenu: () => setActionMenuVisible(true),
                hideActionMenu: () => setActionMenuVisible(false),

                actionMenuVisible,
                moreActionControls: [

                ],

                topActionButtons: getTopActions(),
                content: form
            }}
            {...env}
        />
    );


    return <>{button} {validationSourcing} {drawer}</>

}


