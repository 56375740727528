import './RecordCard.less';

import React, { useState, useEffect, useContext, useRef } from 'react';

import ReactDOM from 'react-dom';

import { make as ElementRenderer } from "bwax-ui/ml/widget/ElementRenderer.bs";

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import getFieldsToUse, {
    getFunctionDependedFieldPaths,
    transformForFieldDisplay,
} from './helpers/getFieldsToUse';

import admin_widget_helper from './helpers/admin_widget_helper.bs'

import Modal from './components/Modal';

import { make as IconButton } from "Client/re/components/IconButton.bs"

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';
import { getFieldLoading } from 'Client/js/builders/display/fieldLoadings';


import Button from './components/Button';


import { renderHead } from './AdminWidget';

import { getFieldInput } from 'Client/js/builders/input/fieldInputs';

import ValidationSourcing from 'Client/js/ui/widgets/ValidationSourcing'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import setupOperationHandlers from './components/setupOperationHanlders';

import { renderActionButton } from './actionButtons';
import PopoverMenu from './components/PopoverMenu';

import FieldForm from './components/FieldForm';


export default function RecordCard(props) {

    const { context, query_config, config, isMain } = props;
    const { bwax, refreshPage, refreshedAt, route_to, dts, onMsg } = context;

    const allEntities = bwax.entities;
    const allDataTypes = bwax.dataTypes;

    const dlc = useContext(DataLoaderContext);

    const [isEditing, setIsEditing] = useState(false);

    const {
        // hideEmptyValues = true 
        layout = { __t__: "Horizontal" }, // Vertical, Inline
        itemLists: givenItemLists,
        items,

        excludedFields = ["修改时间", "修改者"],
        title, description,

        noLabel = false, editInPlace = true,

        operations = [],
        menuOperations = [],

        buttonForEmptyState,

    } = config;

    // 1. 生成所有的 path selection
    const [entityName, _] = query_config;

    const entity = allEntities.find(e => e.name == entityName);

    const itemLists = givenItemLists ? givenItemLists : (items ? [items] : undefined);

    const getPathsForFunction = f => getFunctionDependedFieldPaths(f, { bwax, entityName, dts })
    const fieldLists = itemLists ?
        itemLists.map(l => getFieldsToUse(entity, { fieldItems: l, allDataTypes, allEntities, getPathsForFunction })) :
        [getFieldsToUse(entity, { excludedFields, allDataTypes, allEntities, getPathsForFunction })];

    const allFields = fieldLists.reduce((acc, l) => [...acc, ...l], []);

    const singleFieldMode = allFields.length == 1 && noLabel;

    const fieldPaths = allFields.reduce((acc, f) => {
        return [
            ...acc,
            ...f.fieldPaths
        ]
    }, []);


    // get all the operations's disabled / applicableIf functions's dependencies:
    const operationFieldPaths = [...operations, ...menuOperations].reduce((acc, o) => {
        const { disabled: disableFn, applicable: applicableFn } = o;

        const dependedFieldPaths = [disableFn, applicableFn].filter(f => !!f).reduce((acc, f) => {
            const paths = getFunctionDependedFieldPaths(f, { bwax, entityName, dts });
            return [...acc, ...paths]
        }, []);

        return [
            ...acc,
            ...dependedFieldPaths
        ]
    }, []);

    const [queriedData, setQueriedData] = useState(undefined);


    const [langRecord, setLangRecord] = useState(undefined);            // 用于调用 fn
    const [displayRecord, setDisplayRecord] = useState(undefined);      // 用于显示

    const isCustomQuery = bwax.isCustomQuery(query_config);

    const applyBoolFn = (fn, langRecord, defaultValue) => {
        if (!langRecord || !fn) {
            return defaultValue
        }
        return admin_widget_helper.apply_value_to_bool(fn, langRecord);
    }

    // TODO 这里其实不能仅仅使用 langRecord，对于编辑模式，应该考虑当前正在编辑的 editing
    const checkEditable = config => {
        const { editable: editableFn } = config;
        return applyBoolFn(editableFn, langRecord, true)
    };

    const checkApplicable = config => {
        const { applicable: applicableFn } = config;
        return applyBoolFn(applicableFn, langRecord, true)
    }

    async function queryData(options, callbackGuard) {
        const [entityName] = query_config;

        // 支持两种不同的 query_config:
        const allFieldPaths = [...fieldPaths, ...operationFieldPaths];

        async function doQuery() {
            if (isCustomQuery) {
                // do custom query
                const queryObj = {
                    query_config,
                    pageSize: 1,
                    offset: 0,
                    outputFieldPaths: [
                        [entityName, allFieldPaths]
                    ]
                }
                const [result, error] = await bwax.customQuery(queryObj, options);

                if (result && result.data && result.data.length > 0) {
                    return result.data[0]
                } else {
                    return null
                }

            } else {
                // do standard query
                const queryObj = {
                    entityName,
                    query_config,
                    // 其他参数
                    fieldPaths: allFieldPaths,

                    queryType: "listAll", // 用于 getQueryVars

                };
                const [result, error] = await bwax.findOne(queryObj, options);
                return result
            }
        }

        const result = await doQuery();

        // TODO error handling
        if (callbackGuard === undefined || callbackGuard()) {

            const displayRecord = transformForFieldDisplay(result, allFields, {
                entity_dict: bwax.entity_dict, data_type_dict: bwax.data_type_dict, entityName: entity.name
            })

            const langRecord = admin_widget_helper.transform_record(
                bwax.entity_dict, bwax.data_type_dict, entity.name, result
            )


            ReactDOM.unstable_batchedUpdates(() => {
                setQueriedData(result);
                setDisplayRecord(displayRecord);
                setLangRecord(langRecord)
            })


        }

    }

    const reload = () => {
        if (refreshPage) {
            refreshPage();
        }
        // queryData({ 
        //     // forceRefreshing: true
        //  });
    };

    useEffect(() => {
        let isActive = true;
        queryData({ processCompositeData: false }, () => isActive);
        return () => { isActive = false }
    }, [bwax, JSON.stringify(query_config), refreshedAt]);

    // if any field is editable
    const editable = !!queriedData && allFields.some(f => f.updatable && checkEditable(f.config));

    const OperationHandlers = setupOperationHandlers({ bwax, entity, reload, route_to });

    function renderTopRightButtons() {
        if (!queriedData) {
            return null
        }
        const buildOperationButton = inMenu => operation => {
            const {
                label, confirm, buttonType, color,
                disabled: disabledFn,
                op
            } = operation;

            const applicable = checkApplicable(operation);
            if (!applicable) {
                return undefined;
            }

            const disabled = disabledFn ? applyBoolFn(disabledFn, langRecord) : false;

            const handler = OperationHandlers[op.__t__];
            const onClick = () => {
                if (handler) {
                    handler(op.__v__, { id: queriedData.id })
                } else {
                    console.log("No handler", op);
                };
            }
            const props = {
                label, confirm, buttonType,
                color, disabled, onClick, inMenu
            }
            return [label, <Button key={label} {...props} />];
        }

        const operationButtons = operations.map(buildOperationButton(false)).filter(x => !!x);

        const menuOperationButtons = menuOperations.map(buildOperationButton(true)).filter(x => !!x);

        const getIcon = () => {
            return <PopoverMenu items={menuOperationButtons} style={{ marginLeft: 0 }} />
        }
        return (
            <>
                {editable ?
                    <IconButton
                        icon="edit"
                        className="edit-button"
                        onClick={() => {
                            if (editable) {
                                // make sure it is not selection operation:
                                const selection = window && window.getSelection();
                                if (selection && selection.toString().length > 0) {
                                    // it is a selection
                                } else {
                                    setIsEditing(true);
                                }
                            }
                        }}
                    />
                    : null
                }
                {operationButtons.map(([_, b]) => b)}
                {getIcon()}
            </>
        );
    }

    const withHead = !!title;

    const modeClassName = (
        (singleFieldMode ? " single-field" : "") + (isMain ? " is-main" : "")
    )

    function renderCard(content, editForm) {
        return (
            <div className={
                "admin--record-card admin-widget"
                + (editable ? " editable" : "") + (withHead ? " with-head" : "")
                + modeClassName
            }>
                {renderHead(title, description, renderTopRightButtons, !(editInPlace && isEditing))}
                <div className="record-card-body" style={
                    editInPlace && isEditing ? { display: "none" } : {}
                }>
                    {content}
                </div>
                {editForm}
            </div>
        )
    };

    function renderValue(data) {
        const lists = fieldLists.map(l => l.map((f, index) => {
            const { cname, name, desc, config = {} } = f

            const applicable = langRecord ? checkApplicable(config, langRecord) : true;
            if (!applicable) {
                return null;
            }

            const label = cname || name;

            const specificTypeParams = {
                "Image": {
                    width: 120,
                    height: 120,
                    processor: 'medium'
                }
            }

            const getDisplay = () => {
                if (data == undefined) {
                    const loading = getFieldLoading(f)
                    return loading
                }
                const value = data[label];

                if ((value === null || value === undefined) && f.config.defaultView) {
                    // 
                    const element = admin_widget_helper.apply_value_to_element(f.config.defaultView, langRecord);

                    return (
                        <ElementRenderer
                            {...{
                                element,
                                onMsg,
                                wrapperClass: "element-view-box"
                            }}
                        />
                    )
                } else {

                    const display = getFieldDisplay(f);

                    const configParams = f.config && f.config.displayParams ? f.config.displayParams : {};

                    const customParams = {
                        ...(specificTypeParams[f.type] || {}),
                        ...configParams
                    }

                    const Component = display.component;
                    return <Component value={value} customParams={customParams} field={f} env={{
                        allEntities, allDataTypes
                    }} />
                }


            }

            return {
                name: label,
                tip: (desc && desc !== name) ? desc : null,
                display: getDisplay(),
                field: f,
                // input: getInput()
            }
        }).filter(x => !!x))

        // render the items
        const width = lists.length > 0 ? (100 / lists.length) + "%" : "100%";


        return (
            (singleFieldMode ?
                (() => {
                    const item = lists[0][0];
                    const { display } = item;
                    return display
                })()
                : (
                    <FieldForm {...{
                        layout: layout.__t__.toLowerCase(),
                        noLabel, width,
                        itemLists: lists.map(l => l.map(item => {
                            const { name, tip, display } = item;
                            return { name, tip, value: display }
                        }))
                    }} />
                )
            )
        )
    }

    if (queriedData === undefined) {
        // render the loading dta
        return renderCard(renderValue(undefined))
    }

    if (queriedData === null) {

        // 如果有 addRecordConfig，则用它来添加记录
        function renderEmptyState() {
            if (buttonForEmptyState) {
                return (
                    <div style={{
                        opacity: .65,
                    }}>
                        {renderActionButton(buttonForEmptyState, { dataEntity: entity, context, reload })}
                    </div>
                )
            } else {
                return (
                    <div style={{
                        opacity: .35,
                    }}>
                        没找到数据
                    </div>
                )
            }
        }

        return renderCard(
            <div style={{
                width: "100%",
                height: "100%",
                paddingBottom: withHead ? "1rem" : 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {renderEmptyState()}
            </div>
        )
    }



    function renderEdit() {
        if (!isEditing) {
            return null;
        }
        return (
            <EditForm {...{
                data: displayRecord, fieldLists, allFields, singleFieldMode,

                modeClassName,

                checkEditable, checkApplicable,

                dataEntity: entity,
                context,
                layout, title, description, noLabel, editInPlace,
                isEditing, setIsEditing,
                reload,
                dlc,
            }} />
        )

    }

    return renderCard(renderValue(displayRecord), renderEdit(displayRecord))

}


function getInitialValues(data, allFields, allKeys) {
    return data ? allFields.reduce((acc, f) => {
        const { cname, name } = f;
        const targetValue = data[cname || name];
        const editable = allKeys.indexOf(name) !== -1;
        if (!editable) {
            return acc
        }
        return {
            ...acc,
            [name]: targetValue && (targetValue.value !== undefined) ? targetValue.value : targetValue
        }
    }, {}) : {};
};

// this is used for edit, the edit state is maintained here.
function EditForm({
    data, fieldLists, allFields, singleFieldMode,

    checkEditable, checkApplicable,  // 用于做各种判断

    dataEntity, context,
    layout, title, description, noLabel, editInPlace,
    isEditing, setIsEditing,
    reload,
    dlc,
}) {

    const { bwax } = context;
    const allEntities = bwax.entities;
    const allDataTypes = bwax.dataTypes;

    const allKeys = allFields.filter(f => {
        const { updatable, config = {} } = f;
        const editable = updatable === true && checkEditable(config);
        return editable;
    }).map(f => f.name);

    // --- 初始化 Editing
    const initialValues = getInitialValues(data, allFields, allKeys);

    const {
        // errors,
        validated,
        editing,
        // lastSaved,
        dirtyValues,
        updateEditing,
        // markSaved,
        rollback,
        // clearEditing,
        reset
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, {}, {}, [], "no-key", false)

    const firstRoundRef = useRef(true);

    useEffect(() => {
        if (firstRoundRef.current) {
            // already initialized, do nothing
            firstRoundRef.current = false;

        } else {
            const initialValues = getInitialValues(data, allFields, allKeys);
            reset({}, initialValues, {});
        }


    }, [JSON.stringify(data ? data : "")])

    const [validationValues, setValidationValues] = useState({});

    const allValidations = allFields.filter(f => f.options && f.options.validation).map(f => {
        return [f.options.validation, f]
    });


    const validationSourcing = (
        <ValidationSourcing
            params={{
                validations: allValidations,
                entityName: dataEntity.name,
                updateValidationValues: values => {
                    setValidationValues(prev => ({
                        ...prev,
                        ...values
                    }))
                },
                formValues: validated
            }}
            allEntities={allEntities}
            allDataTypes={allDataTypes}
            dlc={dlc}
        />
    )

    const lists = fieldLists.map(l => l.map((f, index) => {
        const { cname, name, desc, config = {}, options, } = f
        // multiple

        const label = cname || name;

        const editable = allKeys.indexOf(name) !== -1;
        const specificTypeParams = {
            "Image": {
                width: 120,
                height: 120,
                processor: 'medium'
            }
        }

        const getDisplay = () => {
            if (data == undefined) {
                const loading = getFieldLoading(f)
                return loading
            }

            const value = data[label];
            const display = getFieldDisplay(f);

            const configParams = f.config && f.config.displayParams ? f.config.displayParams : {};

            const customParams = {
                ...(specificTypeParams[f.type] || {}),
                ...configParams
            }

            const Component = display.component;
            return <Component value={value} customParams={customParams} field={f} env={{
                allEntities, allDataTypes
            }} />
        }


        function prepareValidation() {
            if (options && options.validation) {
                // 还有 list, dict, ...
                const { list } = options.validation
                function getLimitedValues() {
                    if (list) {
                        return list
                    } else {
                        return validationValues[f.name]
                    }
                }
                return {
                    limitedValues: getLimitedValues()
                }

            } else {
                return {}
            }
        };

        function getInput() {

            const editKey = name;

            const inputParams = {
                ...(config && config.inputParams ? config.inputParams : {}),
                ...(prepareValidation())
            };

            const ips = {
                ...(specificTypeParams[f.type] || {}),
                ...inputParams
            };

            /// add value, onChange
            const value = editing[name] // fixedValues[name] || editing[name];

            const onChange = v => {
                if (v !== value) {
                    updateEditing({ [name]: v })
                }
            }

            let inputWidget = getFieldInput(
                f,
                editKey,
                {
                    initialValue: data && data[name],
                    allDataTypes, allEntities, dataEntity,
                    queryTarget: "data"
                },
            );

            return inputWidget(
                value,
                onChange,
                // params                    
                {
                    // ...((data[name] && typeof (data[name] == 'object')) ? data[name] : {}),
                    ...ips
                }
            );
        }

        return {
            name: label,
            tip: (desc && desc !== name) ? desc : null,
            display: getDisplay(),
            input: getInput(),
            editKey: name,
            editable,
            field: f,
        }
    }))


    // render the items
    const width = lists.length > 0 ? (100 / lists.length) + "%" : "100%";

    const layoutStyle = layout.__t__.toLowerCase() == "horizontal" && !editInPlace ? {
        paddingRight: lists.length > 1 ? "5rem" : "1rem"
    } : {
        paddingRight: lists.length > 1 ? "1.5rem" : "0rem"
    };


    const content = (

        singleFieldMode ?
            (() => {
                const item = lists[0][0];
                const {input, editable, display } = item;
                return editable ? input : display
            })() : (
                <FieldForm {...{
                    layout: layout.__t__.toLowerCase(),
                    noLabel, width,
                    style: layoutStyle,
                    itemLists: lists.map(l => l.map(item => {
                        const { name, tip, input, editable, display } = item;
                        return { name, tip, value: editable ? input : display }
                    }))
                }} />
            )
    )

    // editing states 
    const isDirty = allKeys.some(key => dirtyValues[key] !== undefined);
    //    

    const buttons = [
        {
            // 关闭或取消
            label: (isDirty ? "撤销" : "关闭"),
            buttonType: "subtle",
            // disabled,
            onClick: () => {
                if (isDirty) {
                    rollback(allKeys);
                } else {
                    setIsEditing(false);
                }

            }
            // 
        },
        {
            // 保存按钮
            label: "保存",
            buttonType: "primary",
            disabled: !isDirty,
            onClick: async () => {
                if (isDirty) {
                    const mutationObj = {
                        entityName: dataEntity.name,
                        formData: dirtyValues,
                        id: data.id,
                        fieldPaths: []
                    }
                    const [result, error] = await bwax.update(mutationObj);
                    reload();
                }
                setIsEditing(false)
            }
        }
    ].map(params => {

        return (
            <Button key={params.label} {...params} />
        )

    })

    if (editInPlace && isEditing) {
        return (
            <>
                {renderHead(title, description, () => buttons, true)}
                <div className={"record-card-body"}>
                    {validationSourcing} {content}
                </div>
            </>
        )

    } else {
        return (
            <Modal
                title={title ? title + " | 编辑" : "数据编辑"}
                size={lists.length > 1 ? "wide" : "normal"}
                visible={isEditing}
                maskClosable={false}
                onCancel={() => {
                    // 如果 dirty 的话，需要提示：
                    if (isDirty) {
                        Modal.confirm({
                            title: '您有未保存的修改',
                            content: '确定要关闭吗？',
                            okText: '关闭',
                            cancelText: '先不关闭',
                            className: "admin--edit-modal-confirm",
                            onOk: () => {
                                rollback(allKeys);
                                setIsEditing(false);
                            }
                        });
                    } else {
                        setIsEditing(false)
                    }
                }}
                footer={buttons}

            >
                {validationSourcing}
                <div className={"admin--record-card admin-widget" + modeClassName} >
                    {content}
                </div>
            </Modal>
        )

    }



}


export function create(props) {
    return <RecordCard {...props} />
}

