// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Dict$BwaxAdmin = require("../../../../bwax-js/ml/dict.bs.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var LangHelper = require("bwax/lang/LangHelper");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxAdmin = require("../../../../bwax-js/ml/base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_typing_base.bs.js");

var module_name = "Custom_AdminPage";

function term_of_admin_page(adminPage) {
  var name = adminPage.name;
  var nname = Base_query_types$BwaxAdmin.normalize_field_name(name);
  return Plate$BwaxAdmin.$$Option.and_then((function (ioTypeMetas) {
                var return_type_list = Curry._1(Plate$BwaxAdmin.List.from_array, LangHelper.untag(ioTypeMetas.viewReturnTypes));
                var return_type = return_type_list ? (
                    return_type_list[1] ? /* Term */Caml_chrome_debugger.variant("Term", 0, [
                          "Tuple",
                          return_type_list
                        ]) : return_type_list[0]
                  ) : /* Term */Caml_chrome_debugger.variant("Term", 0, [
                      "Unit",
                      /* [] */0
                    ]);
                var msg = Lang_typing_base$BwaxAdmin.any(0);
                var supported_return_term;
                if (typeof return_type === "number" || return_type.tag) {
                  supported_return_term = undefined;
                } else {
                  switch (return_type[0]) {
                    case "AdminPage.PageLayout" :
                    case "PageLayout" :
                        supported_return_term = Lang_typing_base$BwaxAdmin.wrap_term("AdminPage.PageLayout", msg);
                        break;
                    case "AdminPage.Widget" :
                    case "Widget" :
                        supported_return_term = Lang_typing_base$BwaxAdmin.wrap_term("AdminPage.Widget", msg);
                        break;
                    default:
                      supported_return_term = undefined;
                  }
                }
                return Plate$BwaxAdmin.$$Option.map((function (rt) {
                              return /* tuple */[
                                      nname,
                                      rt
                                    ];
                            }), supported_return_term);
              }), Caml_option.nullable_to_opt(adminPage.ioTypeMetas));
}

function build_typing_env(adminPages) {
  var empty = Dict$BwaxAdmin.$$String.empty(/* () */0);
  var tenv = Plate$BwaxAdmin.List.foldl((function (acc, pc) {
          var match = term_of_admin_page(pc);
          if (match !== undefined) {
            var match$1 = match;
            return Dict$BwaxAdmin.$$String.insert(module_name + ("." + match$1[0]), /* tuple */[
                        Lang_typing_base$BwaxAdmin.generalize(empty, match$1[1]),
                        true,
                        undefined
                      ], acc);
          } else {
            return acc;
          }
        }), empty, Curry._1(Plate$BwaxAdmin.List.from_array, adminPages));
  return /* tuple */[
          tenv,
          /* [] */0
        ];
}

function build_src(adminPages) {
  var build_page_decl = function (adminPage) {
    var name = adminPage.name;
    var nname = Base_query_types$BwaxAdmin.normalize_field_name(name);
    return Plate$BwaxAdmin.$$Option.and_then((function (ioTypeMetas) {
                  var return_type_list = Curry._1(Plate$BwaxAdmin.List.from_array, LangHelper.untag(ioTypeMetas.viewReturnTypes));
                  var return_type = return_type_list ? (
                      return_type_list[1] ? /* Term */Caml_chrome_debugger.variant("Term", 0, [
                            "Tuple",
                            return_type_list
                          ]) : return_type_list[0]
                    ) : /* Term */Caml_chrome_debugger.variant("Term", 0, [
                        "Unit",
                        /* [] */0
                      ]);
                  var supported_return_type;
                  if (typeof return_type === "number" || return_type.tag) {
                    supported_return_type = undefined;
                  } else {
                    switch (return_type[0]) {
                      case "AdminPage.PageLayout" :
                      case "PageLayout" :
                          supported_return_type = "PageLayout msg";
                          break;
                      case "AdminPage.Widget" :
                      case "Widget" :
                          supported_return_type = "Widget msg";
                          break;
                      default:
                        supported_return_type = undefined;
                    }
                  }
                  return Plate$BwaxAdmin.$$Option.map((function (rt) {
                                return nname + (": " + (rt + " = external;"));
                              }), supported_return_type);
                }), Caml_option.nullable_to_opt(adminPage.ioTypeMetas));
  };
  var pageDecls = Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.keep_map(build_page_decl, Curry._1(Plate$BwaxAdmin.List.from_array, adminPages)));
  return "\nmodule " + (String(module_name) + (";\n\nimport AdminPage exposing (Widget, PageLayout);\n\n" + (String(pageDecls) + "\n  \n")));
}

function build_externals(adminPages) {
  var build = function (adminPage) {
    var name = adminPage.name;
    var nname = Base_query_types$BwaxAdmin.normalize_field_name(name);
    return Plate$BwaxAdmin.$$Option.and_then((function (ioTypeMetas) {
                  var return_type_list = Curry._1(Plate$BwaxAdmin.List.from_array, LangHelper.untag(ioTypeMetas.viewReturnTypes));
                  var return_type = return_type_list ? (
                      return_type_list[1] ? /* Term */Caml_chrome_debugger.variant("Term", 0, [
                            "Tuple",
                            return_type_list
                          ]) : return_type_list[0]
                    ) : /* Term */Caml_chrome_debugger.variant("Term", 0, [
                        "Unit",
                        /* [] */0
                      ]);
                  if (typeof return_type === "number" || return_type.tag) {
                    return ;
                  } else {
                    switch (return_type[0]) {
                      case "AdminPage.PageLayout" :
                      case "PageLayout" :
                          return /* tuple */[
                                  nname,
                                  Runtime_common$BwaxAdmin.pack_string(name)
                                ];
                      case "AdminPage.Widget" :
                      case "Widget" :
                          return /* tuple */[
                                  nname,
                                  Runtime_common$BwaxAdmin.pack_raw(/* Custom_widget */Caml_chrome_debugger.variant("Custom_widget", 7, [name]))
                                ];
                      default:
                        return ;
                    }
                  }
                }), Caml_option.nullable_to_opt(adminPage.ioTypeMetas));
  };
  var externals = Plate$BwaxAdmin.List.keep_map(build, Curry._1(Plate$BwaxAdmin.List.from_array, adminPages));
  return /* tuple */[
          module_name,
          externals
        ];
}

exports.module_name = module_name;
exports.term_of_admin_page = term_of_admin_page;
exports.build_typing_env = build_typing_env;
exports.build_src = build_src;
exports.build_externals = build_externals;
/* Dict-BwaxAdmin Not a pure module */
