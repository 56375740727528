import { EditorState } from "@codemirror/state";

import { keymap, highlightActiveLine, rectangularSelection, lineNumbers, highlightActiveLineGutter } from "@codemirror/view";

import { Language, foldGutter, foldKeymap, codeFolding } from '@codemirror/language'

import { linter, lintKeymap } from "@codemirror/lint"

import languageSupports, { getOreLanguageSupport, lightStyle } from './languageSupports';

import { search, searchKeymap, highlightSelectionMatches } from "@codemirror/search"

import { defaultKeymap, indentWithTab, history, historyKeymap } from "@codemirror/commands";

// import { commentKeymap } from "@codemirror/comment";

import { autocompletion } from "@codemirror/autocomplete";

const lineSeparator = "\n";

// return an array of extensions
export default function setupCodeEditor({syntaxTop, afterLinter = _ => [], showLineNumbers = true, compact = false }) {

    // const getOreLanguageSupport = languageSupports[lang];
    const languageSupport = getOreLanguageSupport(syntaxTop);


    return [
        // EditorState.allowMultipleSelections.of(true),        

        ...autocompletion({
            override: [myCompletions]
        }),

        keymap.of(searchKeymap),
        keymap.of(defaultKeymap),
        keymap.of([indentWithTab]),
        keymap.of(foldKeymap),
        keymap.of(historyKeymap),
        keymap.of(lintKeymap),
        
        ...(
            compact ? [] : [
                showLineNumbers ? lineNumbers() : null,
                highlightActiveLine(),
                highlightActiveLineGutter(),
                foldGutter(),
            ]
        ),

        // lintGutter(),
        codeFolding(),
        history(),

        highlightSelectionMatches(),

        EditorState.lineSeparator.of(lineSeparator),

        rectangularSelection(),

        linter(view => {

            const editorState = view.state;
            const codeText = editorState.doc.toJSON().join(lineSeparator);
            // if(testing) localStorage.setItem(storedItemKey, codeText);
           
            const languageState = editorState.field(Language.state);
            const tree = languageState.tree;

            // 判断 tree 是不是已经 parse 完了：
            if(codeText.length === languageState.context.treeLen) {
                return afterLinter(codeText, tree);
            }

            return []
           

        }, { delay: 700 }),

        languageSupport.extension,
        lightStyle,
        search({
            // TODO 定制 search panel
        }),

    ].filter(x => !!x)

}




// auto complete

function myCompletions(context) {

    const word = context.matchBefore(/[^\s]*/);

    if (word.from == word.to && !context.explicit) {
        return null
    }

    const codeText = context.state.doc.toJSON().join(lineSeparator);

    // collect all the 
    const tree = context.state.field(Language.state).tree;
    const topNode = tree.topNode;

    function collectLeafTexts(node) {
        let children = [];
        const firstChild = node.firstChild;
        if (firstChild) {
            children.push(firstChild);
            let nextChild = firstChild.nextSibling;
            while (nextChild) {
                children.push(nextChild);
                nextChild = nextChild.nextSibling;
            }
        }

        if(children.length == 0) {
            // 不包括 comment
            const { from, to } = node;
            if(node.name === "LineComment") {
                return []
            } else {
                const text = codeText.substring(from, to);
                if(node.name === "DotName") {
                    return [ { text, from, to }, { text: text.substring(1), from: from + 1, to } ];
                } 

                return [{text, from, to }]
            }

        } else {
            return children.flatMap(n => collectLeafTexts(n));
        } 

    }

    const collectedWords = collectLeafTexts(topNode);

    const words = collectedWords.filter(({ text, from, to }) => {
        return text.trim().length > 1 && !(from <= context.pos && context.pos < to)
    }).map(({text}) => text).reduce((acc, w) => {
        if (acc[w]) {
            return {
                ...acc, [w]: acc[w] + 1
            }
        } else {
            return {
                ...acc, [w]: 1
            }
        }
    }, {});

    // const words = context.state.doc.toJSON.flatmap()

    const candidates = Object.keys(words).sort((a, b) => words[b] - words[a]);

    // console.log(candidates, candidates.find(c => c.startsWith(word.text)), word.text);
    // console.log("Candidates", collectedWords, candidates, context, word);

    if(candidates.find(c => c.startsWith(word.text) && c !== word.text)) {
        return {
            from: word.from,
            // options: [
            //     { label: "match", type: "keyword" },
            //     { label: "hello", type: "variable", info: "(World)" },
            //     { label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro" }
            // ]
            options: candidates.map(text => {
                return { label: text, type: "text"}
            })
        }
    } else {
        return null
    }
 

}


