
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react';

import React, { useState } from 'react'


import PageDesigner from '../PageDesigner';
import ResourceMananger from '../inputs/ResourceMananger';

import getImageURL from 'bwax-ui/getImageURL'
import generateOutput from './generateOutput';

import { commonAttributes as htmlCommonAttributes, extractStyleAttrs } from '../html/HtmlPageDesigner';
import StyleForm from '../html/form/StyleForm';

import text from './blocks/text';
import inputText from './blocks/inputText';

import {
    MdOutlineViewWeek, MdOutlineViewStream, MdCrop32, MdCrop169, MdOutlineTextSnippet,
    MdOutlineInsertLink, MdTextFields, MdOutlineSmartDisplay, MdOutlineInsertPhoto,
    MdShortText, MdWrapText, MdRadioButtonChecked, MdOutlineArrowDropDownCircle, MdOutlineUploadFile,
    MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowLeft,

} from 'react-icons/md';

import {
    RiArticleLine, RiCheckboxLine, RiCheckboxMultipleLine,
} from 'react-icons/ri';

import {
    TiSortNumerically
} from 'react-icons/ti';


const commonAttributes = {
    style: htmlCommonAttributes.style,

    interaction: {
        onClick: {
            // label: "点击时",
            type: "Msg",
            useExpression: true
        },
    }
}

// common functions


// 

// text, paragraph, block (div), h1 ... h6, 基本元素
// image, video
// input text, input file, 
// 


const link = {
    name: "link",
    label: "链接容器",
    icon: <MdOutlineInsertLink />
}


const button = {
    name: "button",
    label: "按钮",
    icon: <MdCrop169 />
}


const richtext = {
    name: "richtext",
    label: "富文本",
    icon: <RiArticleLine />
}




const video = {
    name: "video",
    label: "视频",
    icon: <MdOutlineSmartDisplay />
}


const inputNumber = {
    name: "inputNumber",
    label: "数字框",
    icon: <TiSortNumerically />
}


const textArea = {
    name: "textArea",
    label: "多行文本",
    icon: <MdWrapText />
}

const inputFile = {
    name: "inputFile",
    label: "上传文件",
    icon: <MdOutlineUploadFile />
}

const radio = {
    name: "radio",
    label: "单选按钮",
    icon: <MdRadioButtonChecked />
}

const dropdown = {
    name: "dropdown",
    label: "下拉选择",
    icon: <MdOutlineArrowDropDownCircle />
}

const checkbox = {
    name: "checkbox",
    label: "单选按钮",
    icon: <RiCheckboxLine />
}

const multiSelect = {
    name: "multiSelect",
    label: "单选按钮",
    icon: <RiCheckboxMultipleLine />
}


const div = {
    name: "div",
    legacyName: "block",

    label: "容器",
    isContainer: true,

}


const image = {
    name: "image",
    label: "图片",
    attributes: {
        image: {
            label: "Image",
            type: "图片",
        }
    },
    icon: <MdOutlineInsertPhoto />,

    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {

        // process style 

        const [styleAttrs, { image }] = extractStyleAttrs(attributes);

        const [open, setOpen] = useState(false);

        function renderEle() {
            if (image && image.file) {
                const { file, processor = "large" } = image
                return (
                    <img src={getImageURL(file, processor)} ref={ref} {...otherProps} className={className} style={{
                        width: "100%", // 默认是 100%;
                        ...styleAttrs,
                        // ...(children ? {} : { minHeight: 100, minWidth: 150})
                    }} onDoubleClick={_ => {
                        setOpen(true)
                    }} />
                )
            } else {
                return (
                    <div ref={ref} {...otherProps} className={className} style={{
                        ...styleAttrs,
                        backgroundImage: `url("https://bw-dev.static.qunfengshe.com/public/cb-bwax/design-resources/placeholder-image.webp")`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        minHeight: 100, minWidth: 150,
                        display: "flex",
                    }}>
                    </div>
                )
            }
        }
        return (
            <>
                {renderEle()}
                <ResourceMananger open={open} setOpen={setOpen} onFileSelect={file => {
                    updateBlockAttributes({ image: { file } });
                }} />
            </>
        )
    })
}


const blockTypesByCategory = {
    "基础控件": [text, div, link, button, richtext],
    "多媒体": [image, video],
    "输入控件": [
        inputText, textArea, inputNumber, inputFile,
        radio, dropdown, checkbox, multiSelect,
    ],
}

const blockTypes = Object.keys(blockTypesByCategory).reduce((acc, category) => {
    const list = blockTypesByCategory[category].map(bt => {
        return {
            ...bt,
            category,
            attributeForms: {
                style: StyleForm
            },

            // preprocess the DesignView : designview  =
            DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {

                const [styleAttrs, _] = extractStyleAttrs(attributes);
                const cssObj = css(styleAttrs);

                function renderView() {
                    const View = bt.DesignView;
                    const cn = className + " css-" + cssObj.name

                    if (View) {
                        return <View ref={ref} {...{
                            attributes, updateBlockAttributes, ...otherProps, className: cn
                        }}>{children}</View>
                    } else {
                        if(bt.isContainer) {
                            return <div ref={ref} {...otherProps} className={cn}>{children}</div>
                        } else {
                            return <div ref={ref} {...otherProps} className={cn} />
                        }
                    }

                };

                return (
                    <>
                        <Global styles={css`.css-${cssObj.name}{ ${cssObj.styles} }`}></Global>
                        { renderView() }
                    </>
                )

            })

        }

    })
    return [...acc, ...list]
}, [])


function LeanPageDesigner({ value, onChange, saveValue, extendedOptions }) {
    return (
        <PageDesigner
            value={value} onChange={onChange} blockTypes={blockTypes} commonAttributes={commonAttributes}
            saveValue={saveValue}
            extendedOptions={extendedOptions}
        ></PageDesigner>
    )
}

function getAttributeSetting (blockType, name) {   
    const { attributes = {} } = blockType;
    if(attributes[name]) {
        return attributes[name]
    } else {
        const { settings = {}, style = {}, interaction = {} } = commonAttributes;
        const allCommonAttributes = { ...settings, ...style, ...interaction };
        return allCommonAttributes[name]
    }
}

LeanPageDesigner.blockTypes = blockTypes;
LeanPageDesigner.commonAttributes = commonAttributes;
LeanPageDesigner.getAttributeSetting = getAttributeSetting;
LeanPageDesigner.generateOutput = generateOutput(getAttributeSetting);


export default LeanPageDesigner;
