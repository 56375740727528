// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Json$BwaxAdmin = require("../../../../lib/bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../../../lib/bwax-ui/re/BaseUI.bs.js");
var DomUtils$BwaxAdmin = require("../../../../lib/bwax-ui/re/utils/DomUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var IconButton$BwaxAdmin = require("../../components/IconButton.bs.js");
var Popover = require("bwax-ui/components/legacy/Popover");

var hiddenColumnsKey = "hiddenColumns";

function storageKey(tab) {
  return tab + ".hiddenColumns";
}

function getStoredHiddenColumns(tabID) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.keep_map(Js_json.decodeString, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeArray, Plate$BwaxAdmin.$$Option.and_then(Json$BwaxAdmin.parse, Plate$BwaxAdmin.$$Option.and_then(DomUtils$BwaxAdmin.getFromLocalStorage, Plate$BwaxAdmin.$$Option.map(storageKey, tabID))))))));
}

function Input_TableColumnConfig(Props) {
  var params = Props.params;
  var value = Props.value;
  var onChange = Props.onChange;
  var theme = Props.theme;
  var match = params.size;
  var size;
  if (match !== undefined) {
    switch (match) {
      case "large" :
          size = /* large */-272944197;
          break;
      case "small" :
          size = /* small */-9768761;
          break;
      default:
        size = /* medium */-20425611;
    }
  } else {
    size = /* medium */-20425611;
  }
  var tip = params.tip;
  var tabID = params.tabID;
  var hiddenColumns = Plate$BwaxAdmin.List.keep_map(Js_json.decodeString, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                      return Json$BwaxAdmin.get_value(hiddenColumnsKey, Js_json.decodeArray, param);
                    }), value))));
  var isColumnHidden = function (name) {
    return Plate$BwaxAdmin.List.some((function (c) {
                  return c === name;
                }), hiddenColumns);
  };
  var fieldsToUse = Curry._1(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.with_default(/* array */[], params.fieldsToUse));
  var hasHiddenColumn = Plate$BwaxAdmin.List.some((function (f) {
          return isColumnHidden(Plate$BwaxAdmin.$$Option.with_default(f.name, f.cname));
        }), fieldsToUse);
  var fieldSelectors = Plate$BwaxAdmin.List.map((function (f) {
          var name = Plate$BwaxAdmin.$$Option.with_default(f.name, f.cname);
          var textStyles = isColumnHidden(name) ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* textStrike */-937384257,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
                        -481689226,
                        /* N50 */3890729
                      ]),
                    /* [] */0
                  ])
              ]) : /* [] */0;
          var partial_arg_000 = /* `width */Caml_chrome_debugger.polyVar("width", [
              -899500538,
              /* fill */-1011102077
            ]);
          var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* pointer */-786317123,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `onClick */Caml_chrome_debugger.polyVar("onClick", [
                      -560194903,
                      (function (param) {
                          var name$1 = name;
                          var newHidden = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (prim) {
                                      return prim;
                                    }), isColumnHidden(name$1) ? Plate$BwaxAdmin.List.filter((function (c) {
                                              return c !== name$1;
                                            }))(hiddenColumns) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        name$1,
                                        hiddenColumns
                                      ])));
                          if (tabID !== undefined) {
                            DomUtils$BwaxAdmin.saveToLocalStorage(storageKey(tabID), JSON.stringify(newHidden));
                          }
                          var newHidden$1 = newHidden;
                          return Curry._1(onChange, Json$BwaxAdmin.create_or_insert(hiddenColumnsKey, newHidden$1, value));
                        })
                    ]),
                  textStyles
                ])
            ]);
          var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              partial_arg_000,
              partial_arg_001
            ]);
          return (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.text(partial_arg, name, param, param$1, param$2, param$3);
            });
        }), fieldsToUse);
  var partial_arg = "选择要隐藏的列";
  var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* textLight */233447657,
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `textSize */Caml_chrome_debugger.polyVar("textSize", [
              -816909298,
              /* `px */Caml_chrome_debugger.polyVar("px", [
                  25096,
                  10
                ])
            ]),
          /* [] */0
        ])
    ]);
  var content = React.createElement("div", {
        className: "bw-ui lc-base"
      }, BaseUI$BwaxAdmin.column(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                  297481091,
                  /* `rem */Caml_chrome_debugger.polyVar("rem", [
                      5691738,
                      0.5
                    ])
                ]),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `paddingXY */Caml_chrome_debugger.polyVar("paddingXY", [
                      -303792878,
                      /* tuple */[
                        /* `rem */Caml_chrome_debugger.polyVar("rem", [
                            5691738,
                            1.0
                          ]),
                        /* `rem */Caml_chrome_debugger.polyVar("rem", [
                            5691738,
                            1.0
                          ])
                      ]
                    ]),
                  /* [] */0
                ])
            ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
              (function (param, param$1, param$2, param$3) {
                  return BaseUI$BwaxAdmin.text(partial_arg$1, partial_arg, param, param$1, param$2, param$3);
                }),
              fieldSelectors
            ]), theme, /* column */-963948842, /* [] */0, "no-key"));
  var color = hasHiddenColumn ? "B60" : "N80";
  return Popover.create({
              trigger: "click",
              content: content,
              positions: /* array */["bottom"],
              align: "end",
              padding: 8,
              children: React.createElement("div", undefined, React.createElement(IconButton$BwaxAdmin.make, {
                        icon: "setting",
                        size: size,
                        color: color,
                        tip: tip
                      }))
            });
}

var make = Input_TableColumnConfig;

exports.hiddenColumnsKey = hiddenColumnsKey;
exports.storageKey = storageKey;
exports.getStoredHiddenColumns = getStoredHiddenColumns;
exports.make = make;
/* react Not a pure module */
