// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var BwaxUi = require("bwax-ui");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var AdminChart = require("./ui/AdminChart");
var RecordCard = require("./ui/RecordCard");
var RecordView = require("./ui/RecordView");
var ElementView = require("./ui/ElementView");
var RecordTable = require("./ui/RecordTable");
var React_el$BwaxAdmin = require("../../lib/bwax-ui/ml/react_el.bs.js");
var RecordListView = require("./ui/RecordListView");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var ColorTypes$BwaxAdmin = require("../../lib/bwax-ui/re/ColorTypes.bs.js");
var CustomAdminPage = require("Client/js/pages/CustomAdminPage");

function recordTable(prim) {
  return RecordTable.create(prim);
}

function recordCard(prim) {
  return RecordCard.create(prim);
}

function recordView(prim) {
  return RecordView.create(prim);
}

function elementView(prim) {
  return ElementView.create(prim);
}

function recordListView(prim) {
  return RecordListView.create(prim);
}

function createAdminChart(prim) {
  return AdminChart.create(prim);
}

function context_to_obj(context) {
  return {
          page_id: context[/* page_id */0],
          dts: context[/* dts */1],
          query_runner: context[/* query_runner */2],
          route_to: context[/* route_to */3],
          bwax: context[/* bwax */4],
          refreshPage: context[/* refreshPage */5],
          refreshedAt: context[/* refreshedAt */6],
          contextRecordId: context[/* contextRecordId */7],
          contextEntityName: context[/* contextEntityName */8],
          onMsg: context[/* onMsg */9],
          viewEnv: context[/* viewEnv */10]
        };
}

function getColor(color) {
  return BwaxUi.themeColor(undefined, ColorTypes$BwaxAdmin.toString(color));
}

function make_record_widget(key, context, query_config, config, isMain, f) {
  return Curry._1(f, {
              context: context_to_obj(context),
              query_config: query_config,
              config: config,
              key: key,
              isMain: isMain
            });
}

function make_chart(key, context, name, aggregate_config, config) {
  return AdminChart.create({
              context: context_to_obj(context),
              aggregate_config: aggregate_config,
              config: config,
              key: key,
              name: name
            });
}

function render_custom_page(context, key, pageName) {
  return CustomAdminPage.create({
              context: context_to_obj(context),
              mlContext: context,
              key: key,
              pageName: pageName
            });
}

function render(context, path, index, isMain, widget) {
  var key = context[/* page_id */0] + ("::" + String(index));
  switch (widget.tag | 0) {
    case /* Dummy_widget */0 :
        var className = "dummy " + Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                Css.width(Css.pct(100.0)),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Css.height(Css.pct(100.0)),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Css.minHeight(Css.px(120)),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Css.overflow(Css.auto),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Css.borderRadius(Css.px(4)),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Css.unsafe("backgroundColor", BwaxUi.themeColor(undefined, ColorTypes$BwaxAdmin.toString(widget[0]))),
                                    /* [] */0
                                  ])
                              ])
                          ])
                      ])
                  ])
              ]));
        return React_el$BwaxAdmin.div(className, key, Caml_option.some(null), /* () */0);
    case /* Element_view */1 :
        return ElementView.create({
                    context: context_to_obj(context),
                    element: widget[1],
                    config: widget[0],
                    key: key,
                    facade: context[/* bwax */4]
                  });
    case /* Record_table */2 :
        return make_record_widget(key, context, widget[0], widget[1], isMain, recordTable);
    case /* Record_card */3 :
        return make_record_widget(key, context, widget[0], widget[1], isMain, recordCard);
    case /* Record_view */4 :
        return RecordView.create({
                    context: context_to_obj(context),
                    query_config: widget[0],
                    viewFn: widget[2],
                    config: widget[1],
                    key: key
                  });
    case /* Record_list_view */5 :
        return RecordListView.create({
                    context: context_to_obj(context),
                    query_config: widget[0],
                    viewFn: widget[2],
                    config: widget[1],
                    key: key
                  });
    case /* Chart */6 :
        return make_chart(key, context, widget[0], widget[1], widget[2]);
    case /* Custom_widget */7 :
        return render_custom_page(context, key, widget[0]);
    
  }
}

exports.recordTable = recordTable;
exports.recordCard = recordCard;
exports.recordView = recordView;
exports.elementView = elementView;
exports.recordListView = recordListView;
exports.createAdminChart = createAdminChart;
exports.context_to_obj = context_to_obj;
exports.getColor = getColor;
exports.make_record_widget = make_record_widget;
exports.make_chart = make_chart;
exports.render_custom_page = render_custom_page;
exports.render = render;
/* Css Not a pure module */
