
import React, { useState } from 'react'

import { Form, Input, Checkbox, Button, Radio } from '@arco-design/web-react';

import Message from 'Client/js/ui/Message';

export default function PasswordLogin({ runQuery, afterLogin }) {

    const [ userName, setUserName ] = useState();
    const [ password, setPassword ] = useState();

    const [ submitting, setSubmitting ] = useState(false);

    async function submit () {
        console.log(">>> ", userName, password);

        const result = await runQuery(PasswordLoginText)({
            input: {
                userName, password, clientMutationId: Date.now() + ""
            }
        })

        const { data } = JSON.parse(result);

        if(data && data.passwordLogin) {
            const { errMessage, authSession } = data.passwordLogin;
            if(errMessage) {
                Message.error(errMessage);
            } else if(authSession && authSession.token) {
                console.log(">>>", authSession.token);
                afterLogin(authSession.token, authSession.authUser)
            }
        }
        // TODO other error handling?

    }

    return (
        <div className="password-login-form">
            <div className="item">
                <Input placeholder='用户名' onChange={v => setUserName(v)} size="large" />
            </div>
            <div className="item">
                <Input.Password placeholder='密码' onChange={v => setPassword(v)} size="large"/>
            </div>
            <div className="submit-button">
                <Button type="primary" onClick={_ => submit()} size="large">登 录</Button>
            </div>
        </div>
    )
}


const PasswordLoginText = `
mutation ($input: PasswordLoginInput!){
    passwordLogin(input:$input){
      errMessage
      authSession{
        token
        authUser {
           id
           systemRoles
        }
      }
    }
  }
`