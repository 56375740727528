// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Antd$BwaxAdmin = require("./binding/antd/Antd.bs.js");
var Json$BwaxAdmin = require("../../lib/bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryRunner$BwaxAdmin = require("../../lib/bwax-js/re/binding/QueryRunner.bs.js");
var DataLoaderContext = require("bwax-ui/store/DataLoaderContext");

var text = "\n        query {\n            sandboxDefinitionChanges {\n                hasChange\n            }\n        }\n    ";

var QuerySandboxDefinitionChanges = {
  text: text
};

function reducer(state, action) {
  return state;
}

function PublishButton(Props) {
  var routeTo = Props.routeTo;
  var button = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.position(Css.absolute),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.bottom(Css.rem(1)),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.left(Css.rem(1)),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.zIndex(600),
                      /* [] */0
                    ])
                ])
            ])
        ]));
  var match = React.useState((function () {
          return false;
        }));
  var setChecking = match[1];
  var checking = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setHasChange = match$1[1];
  React.useState((function () {
          return false;
        }));
  var dlc = React.useContext(DataLoaderContext.default);
  var env = QueryRunner$BwaxAdmin.toEnv(dlc);
  var queryChange = function (param) {
    Curry._1(setChecking, (function (param) {
            return true;
          }));
    return QueryRunner$BwaxAdmin.queryDefinition(Caml_option.some(env), text, undefined, undefined, /* () */0).then((function (value) {
                  var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "data",
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          "sandboxDefinitionChanges",
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              "hasChange",
                              /* [] */0
                            ])
                        ])
                    ]);
                  var hasChange = Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeBoolean, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                  return Json$BwaxAdmin.get_by_path(partial_arg, param);
                                }), Json$BwaxAdmin.parse(value))));
                  Curry._1(setChecking, (function (param) {
                          return false;
                        }));
                  Curry._1(setHasChange, (function (param) {
                          return hasChange;
                        }));
                  return Promise.resolve(/* () */0);
                }));
  };
  var publish = function (param) {
    return Curry._1(routeTo, "/publish");
  };
  var mouseEnter = function (param) {
    if (checking) {
      return 0;
    } else {
      queryChange(/* () */0).then((function (param) {
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    }
  };
  return React.createElement("div", {
              className: "publish-button " + button,
              onMouseEnter: mouseEnter
            }, React.createElement(Antd$BwaxAdmin.Button.make, {
                  icon: "P",
                  type_: "primary",
                  disabled: !match$1[0],
                  shape: "circle",
                  size: "large",
                  onClick: publish
                }));
}

var make = PublishButton;

exports.QuerySandboxDefinitionChanges = QuerySandboxDefinitionChanges;
exports.reducer = reducer;
exports.make = make;
/* Css Not a pure module */
