
import invariant from 'invariant'


export default class ExpressionAnalyzer {

    constructor(operators, functions) {
        this.operators = operators
        this.functions = functions
    }

    analyzeExpression(expressionAst) {

        const { multiplication, addition, comparison } = this.operators

        const functions = this.functions

        function liftOnlyLhs(ast, nextAnalyzing, otherwise) {
            const lhs = ast.children.lhs
            const rhs = ast.children.rhs
            if (!rhs || rhs.length === 0) {
                // lift - 直接只处理唯一操作数
                return nextAnalyzing(lhs[0])
            } else {
                const { lhs, rhs, ...operatorDict } = ast.children
                const operator = Object.values(operatorDict)[0] // only one operator
                return otherwise(lhs, rhs, operator)
            }
        }

        function setQNameSupposedToBe(analyzeExpArr, supposeType) {
            return analyzeExpArr.map(n => {
                if(!n.supposedToBe) {
                    return {
                        ...n,
                        supposedToBe: supposeType
                    }
                } else {
                    return n
                }
            })
        }

        function analyzeCallExp(ast) {

            const func = ast.children.func[0].image
            const analyzeArgs = ast.children.args.reduce((acc, next) => {
                return [
                    ...acc,
                    ...analyzeExp(next)
                ]
            }, [])
            const f = functions[func]
            invariant(f, "unsupported function " + func)
            const argsTypes = Object.keys(f.argsType).reduce((acc, next) => {
                return [
                    ...acc,
                    f.argsType[next]
                ]
            }, [])

            return [
                ...analyzeArgs.map((n, idx) => {
                    return {
                        ...n,
                        supposedToBe: argsTypes[idx]
                    }
                }),
                {
                    ast,
                    type: f.returnType,
                    supposedToBe: f.returnType
                }
            ]
        }

        function analyzeParenthesisExp(ast) {
            const expression = ast.children.expression[0]
            // TODO: should analyze parenthesisExp?
            return analyzeLogicExp(expression.children.logicExp[0])
        }

        function analyzeQualifiedName(ast) {
            //qualifiedName is tag not a type
            return [{
                ast,
                type: 'Unknown'
            }]
        }

        function analyzeBoolLiteral(ast) {

            return [{
                ast,
                type: 'Boolean'
            }]
        }

        function analyzeNumberLiteral(ast) {
            return [{
                ast,
                type: "Number"
            }]
        }

        function analyzeStringLiteral(ast) {
            return [{
                ast,
                type: "String"
            }]
        }


        function analyzeAtomicExp(ast) {
            if (ast.children.callExp) {
                return analyzeCallExp(ast.children.callExp[0])

            } else if (ast.children.parenthesisExp) {
                return analyzeParenthesisExp(ast.children.parenthesisExp[0])

            } else if (ast.children.qualifiedName) {
                return analyzeQualifiedName(ast.children.qualifiedName[0])

            } else if (ast.children.NumberLiteral) {
                return analyzeNumberLiteral(ast.children.NumberLiteral[0])

            } else if (ast.children.BoolLiteral) {
                return analyzeBoolLiteral(ast.children.BoolLiteral[0])

            } else if (ast.children.StringLiteral) {
                return analyzeStringLiteral(ast.children.StringLiteral[0])

            } else {
                // TODO to support many more:
                console.warn("unsupported exp", ast)
            }
        }

        function analyzeMulticationExp(ast) {
            const multiplicationExpAnalyze = liftOnlyLhs(ast, analyzeAtomicExp, (lhs, rhs, operator) => {
                return [
                    ...setQNameSupposedToBe(analyzeAtomicExp(lhs[0]), 'Number'),
                    ...rhs.reduce((acc, next) => {
                        return [
                            ...acc,
                            ...setQNameSupposedToBe(analyzeAtomicExp(next), 'Number')
                        ]
                    }, []),
                    {
                        ast,
                        type: 'Number',
                        supposedToBe: 'Number'
                    }
                ]
            })
            return multiplicationExpAnalyze
        }

        function analyzeAdditionExp(ast) {
            const additionExpAnalyze = liftOnlyLhs(ast, analyzeMulticationExp, (lhs, rhs, operator) => {
                const multiplicationExpAnalyze =  [
                    ...setQNameSupposedToBe(analyzeMulticationExp(lhs[0]), 'Number'),
                    ...rhs.reduce((acc, next) => {
                        return [
                            ...acc,
                            ...setQNameSupposedToBe(analyzeMulticationExp(next), 'Number')
                        ]
                    }, [])
                ]

                return [
                    ...multiplicationExpAnalyze,
                    {
                        ast,
                        type: multiplicationExpAnalyze.some(n => n.type === 'String') ? 'String' : 'Number',
                        supposedToBe: 'Number'
                    }
                ]
            })
            return additionExpAnalyze
        }

        function analyzeUnaryLogicExp(ast) {

            const operator = ast.children.UnaryLogicOperator[0]
            const operand = ast.children.comparisonExp[0]

            if (operator.tokenType === "Not") {
                return [
                    ...setQNameSupposedToBe(analyzeComparisonExp(operand), 'Boolean'),
                    {
                        ast,
                        type: 'Boolean',
                        supposedToBe: 'Boolean'
                    }
                ]
            } else {
                invariant(false, `Unsupported unary logic operator ${operator.tokenType}`)
            }
        }

        function analyzeComparisonExp(ast) {
            return liftOnlyLhs(ast, analyzeAdditionExp, (lhs, rhs, operator) => {
                if(comparison[operator[0].tokenType]) {
                    const lhsAnalyses = analyzeAdditionExp(lhs[0])
                    const rhsAnalyses = analyzeAdditionExp(rhs[0])

                    if(lhsAnalyses.length === 1 && lhsAnalyses[0].ast.name === 'qualifiedName') {
                        const lastRhsAnalyze = rhsAnalyses[rhsAnalyses.length - 1]
                        if(lastRhsAnalyze.type !== 'Unknown') {
                            lhsAnalyses[0].supposedToBe = lastRhsAnalyze.type
                        }
                    }

                    if(rhsAnalyses.length === 1) {
                        const lastLhsAnalyze = lhsAnalyses[lhsAnalyses.length - 1]
                        if(lastLhsAnalyze.type !== 'Unknown') {
                            rhsAnalyses[0].supposedToBe = lastLhsAnalyze.type
                        }
                    }

                    return [
                        ...lhsAnalyses,
                        ...rhsAnalyses,
                        {
                            ast,
                            type: 'Boolean',
                            supposedToBe: 'Boolean'
                        }
                    ]
                }
            })
        }

        function analyzeBoolExp(ast) {

            if (ast.children.unaryLogicExp) {

                return analyzeUnaryLogicExp(ast.children.unaryLogicExp[0])

            } else if (ast.children.comparisonExp) {

                return analyzeComparisonExp(ast.children.comparisonExp[0])

            } else {
                invariant(false, "logicExp's only child must either be unaryLogicExp or binaryLogicExp")
            }
        }

        function analyzeLogicExp(ast) {
            const logicExpAnalyze = liftOnlyLhs(ast, analyzeBoolExp, (lhs, rhs, operator) => {
                return [
                    ...setQNameSupposedToBe(analyzeBoolExp(lhs[0]), 'Boolean'),
                    ...rhs.reduce((acc, next) => {
                        return [
                            ...acc,
                            ...setQNameSupposedToBe(analyzeBoolExp(next), 'Boolean')
                        ]
                    }, []),
                    {
                        ast,
                        type: 'Boolean',
                        supposedToBe: 'Boolean'
                    }
                ]
            })

            return logicExpAnalyze
        }

        function analyzeExp(ast) {
            if(ast && ast.children && ast.children.logicExp) {
                const logicAnalyze = analyzeLogicExp(ast.children.logicExp[0])
                return logicAnalyze
            } else {
                return {
                    ast: null,
                    type: 'Unknown'
                }
            }
        }

        return analyzeExp(expressionAst)
    }
}


//// ===== test =====
function doTheTests() {
    // const p = new ExpressionParser()
    function analyzeTest(exp) {
        const ExpressionParser = require('./ExpressionParser').ExpressionParser
        const p = new ExpressionParser()

        const Operators = require('./Operators').default
        const Functions = require('./Functions').default

        const analyzer = new ExpressionAnalyzer(Operators, Functions)

        const AstPrinter = require('./AstPrinter').default
        const astPrinter = new AstPrinter()

        const parsedExp = p.parse(exp)
        const expressionAnalysis = analyzer.analyzeExpression(parsedExp)

        console.log(`Analyze Exp   ${exp}  is`, expressionAnalysis.map(n => {
            return {
                exp: astPrinter.printExpression(n.ast, exp),
                ...n
            }
        }))

    }

    // analyzeTest(`!true`)
    // analyzeTest("false")
    // analyzeTest("true && true && false")
    // analyzeTest("true && true && a")
    // analyzeTest(`id == "HLKKKF=="`)
    // analyzeTest("2 == id")
    // analyzeTest("a == (2 + 1)")
    // analyzeTest("1 < 2")
    // analyzeTest("(2 + 10) > (3 * a)")
    // analyzeTest("42")
    // analyzeTest("1 + 2 + 10")
    // analyzeTest('1 + 2 + "helloworld"')
    // analyzeTest("155 + 2 * 3 * 6 + 10")
    // analyzeTest("10 - 9")
    // analyzeTest("0 - 1")
    // analyzeTest("10 * 12")
    // analyzeTest("10 / 2")
    // analyzeTest("10 / 3 + 3")
    // analyzeTest("true / (3 + 2)")
    // analyzeTest("产品记录.已购买人数 + 1")
    // analyzeTest("产品记录.已购买人数 > add(1 + 100, 2)")
    // analyzeTest(`"SUCCESS" != null`)
    // analyzeTest(`5 % 2`)
    // analyzeTest("inc(1)")
    // analyzeTest("add(1 + 100, 2)")
    // analyzeTest("add(c, d)")
    // analyzeTest("isNull(a)")
    // analyzeTest("isNull(b)")
    // analyzeTest("exists(a)")
    // analyzeTest('exists("b")')

}

doTheTests()
