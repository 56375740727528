

import React from 'react'

import "./NodePropertyEdit.less";

import Toggle from 'rsuite/Toggle';

export default function NodePropertyEdit({ node, value = {}, onChange, graphSettings, updateGraphSettings }) {

    const { data, id } = node;
    const { node_type, nodes, edges } = data;

    const inputNodes = edges.filter(e => e.target_id === id).map(e => {
        return nodes.find(n => n.id === e.source_id)
    }).filter(x => !!x);

    /// 增加写详情信息

    // 1. PipeTo 显示， 
    // 2, 是否 collapsed

    const properties = {

        // to view
        "节点 id": {
            element: <div>{node.id}</div>
        },

        "节点类型": {
            element: <div>{node.data.node_type}</div>
        },
        "数据类型": {
            element: <div>{node.data.value_type_category}</div>
        },

        // to edit

        "尾参数在前": {
            element: (
                <Toggle size="sm" checked={value["pipeToStyle"] || false} onChange={v => onChange({
                    ...value, pipeToStyle: v,
                })} />
            ),
            applicable: _ => {
                return node.data.node_type == "apply_on_name";
            }
        },

        "不独立显示": {
            element: (
                <Toggle size="sm" checked={value["collapsed"] || false} onChange={v => onChange({
                    ...value, collapsed: v,
                })} />
            ),
            applicable: _ => {
                // 是 literal，同时有且只有一个 edges 的 source 是它：
                return node_type === "literal" && edges.filter(e => e.source_id === id).length === 1
            }
        }
    }

    function getInputNodeProperties(node, nodeSetting) {

        return {

            "节点 id": {
                element: <div>{node.id}</div>
            },
    
            "节点类型": {
                element: <div>{node.node_type}</div>
            },
            "数据类型": {
                element: <div>{node.value_type_category}</div>
            },

            "不独立显示": {
                element: (
                    <Toggle size="sm" checked={nodeSetting["collapsed"] || false}
                        onChange={v => updateGraphSettings(node.id, {
                            ...nodeSetting, collapsed: v,
                        })
                        } />
                ),
                applicable: _ => {
                    // 是 literal，同时有且只有一个 edges 的 source 是它：
                    return node.node_type === "literal" && edges.filter(e => e.source_id === id).length === 1
                }
            }
        }
    }

    function renderProperties(title, key, properties) {

        const applicableProperties = Object.keys(properties).filter(name => {
            const p = properties[name];
            return p.applicable ? p.applicable() : true
        });

        if(applicableProperties.length === 0) {
            return null
        }
        
        return (
            <div className="property-group" key={key}>
                { title ? <div className="title">{ title }</div> : null }
                {
                    applicableProperties.map(name => {
                        const p = properties[name];                    
                        return (
                            <div className="property-line" key={name}>
                                <div className="label">
                                    {name}
                                </div>
                                <div className="input-control">
                                    {p.element}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }


    function renderInputNodePropeties(node, index) {    
        const nodeSetting = graphSettings[node.id];

        const properties = getInputNodeProperties(node, nodeSetting);

        return renderProperties("输入节点" + (index + 1), node.id, properties);
    }



    return (
        <div className="node-property-edit">
            { renderProperties(undefined, "main", properties) }
            { inputNodes.map(renderInputNodePropeties) }
        </div>
    )
}
