

import { getItemIcon } from './studioIcons';

export default class StudioItem  {

    constructor({ itemType, name, data = {}, multitenantType }) {

        this.itemType = itemType;

        this._name = name;
        this.data = data;
        this.icon = getItemIcon({ itemType, data });

        this._parentItem = undefined;

        this._multitenantType = multitenantType;
    }

    getName () {
        // 
        if(this._name) {
            return this._name
        }
        const name = this.data["名称"];
        const namer = {
            "entity-list-page": _ => name + " | 列表页",
            "entity-detail-page": _ => name + " | 详情页",
        }[this.itemType];
        return namer ? namer() : name;
    }


    getDisplayName () {
        let suffix = ""
        const fieldItemTypes = ["field", "virtual-field", "imported-field", "imported-virtual-field"];
        if((fieldItemTypes.indexOf(this.itemType) != -1) && this.data) {
            if(this.data["必填"] || this.data.required) {
                suffix += " *"
            }
            if(this.data["多值"] || this.data.multivalued) {
                suffix += " []"
            }
        }
        return this.getName() + suffix;
    }

    setParentItem(parentItem) {
        this._parentItem = parentItem;
    }

    // getter
    get name() {
        return this.getName()
    }
    get parentItem () {
        return this._parentItem;
    }


    updateData (itemData) {
        if(itemData.id === this.data.id) {
            this.data = {
                ...this.data,
                ...itemData,
            }
        }
        return this;
    }


    itemKey() {
        return this.itemType + "_" + (this.data.id || this.name)
    }

    toString(){
        return JSON.stringify({ 
            itemType: this.itemType, name: this._name, data: this.data,
            multitenantType: this._multitenantType,
        });
    }


    strong() {
        return this.data["公开"]
    }

    get multitenantType() {
        return this._multitenantType;
    }


    static fromString(str){
        try {
            return new StudioItem(JSON.parse(str));
        } catch (e) {
            return null
        }       
    }

    static listToString(items) {
        return JSON.stringify(items.map(item => (
            { 
                itemType: item.itemType, name: item._name, data: item.data,
                multitenantType: item._multitenantType,
            }
        )));
    }

    static fromStringToList(str){
        try {
            const configList = JSON.parse(str);
            if(Array.isArray(configList)) {
                return configList.map(c => new StudioItem(c));
            } else {
                return [];
            }

        } catch (e) {
            return []
        }       
    }

    equalsTo(other) {
        return other && (this.itemType == other.itemType && this.data.id === other.data.id);
    }

    


}