// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Facade = require("../../facade");

function prepare(prim, prim$1) {
  return Facade.prepare(prim, prim$1);
}

function getQueryRunner(prim) {
  return Facade.getQueryRunner(prim);
}

function getQueryCache(prim) {
  return Facade.getQueryCache(prim);
}

function getDefinition(prim) {
  return Facade.getDefinition(prim);
}

function getBaseEnv(prim) {
  return Facade.getBaseEnv(prim);
}

exports.prepare = prepare;
exports.getQueryRunner = getQueryRunner;
exports.getQueryCache = getQueryCache;
exports.getDefinition = getDefinition;
exports.getBaseEnv = getBaseEnv;
/* ../../facade Not a pure module */
