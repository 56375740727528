
import React from 'react'

import { removeAllInlineStyles } from '../inlineStyle'

export default function FormatClearButton (props) {

  const {setEditorState, getEditorState} = props

  return (
    <button className="editor-button" onClick={() => {
      setEditorState(
        removeAllInlineStyles(getEditorState())
      )
    }}>
      <i className={"fa fa-eraser"} aria-hidden="true"></i>
    </button>
  )
}
