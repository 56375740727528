
import React from 'react';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { getColorScheme } from './chartUtils';

import './BarChart.less'

function BarChartComponent({ data, config }) {

    const { fields, colorScheme = "bwax" } = config;

    const COLORS = getColorScheme(colorScheme);

    function renderBars () {

        const { value, legend } = fields;

        if(!legend) {
            // there's no legend:
            return <Bar dataKey={value} fill={COLORS[0]}></Bar>
        } else {
            return []
        }
    }

    return (
        <div className="admin-widget admin--bar-chart" style={{
            minHeight: 320
        }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={fields.axis} />
                    <YAxis dataKey={fields.value} />
                    <Tooltip cursor={{fill: `rgba(234, 234, 234, 0.7)`}}/>
                    <Legend />
                    { renderBars() }
                </BarChart>
            </ResponsiveContainer>
        </div>
    )


}


export default BarChartComponent

// export default NivoBarChart