
import React, { useEffect, useState } from 'react'

import makeTenvWithDefs from 'Client/js/pageDesigner/lean/makeTenvWithDefs';

import LeanPageDesigner from 'Client/js/pageDesigner/lean/LeanPageDesigner';
import Block from 'Client/js/pageDesigner/Block';

import extractParseTree from 'bwax/ml/printer/extractParseTree';

export default function PageVisualEditor(props) {

    const { value, onChange, saveValue, typingEnv, codeEditorCommands } = props;

    const [editingValue, setEditingValue] = useState(_ => {
        return toEditorValue(value);
    });

    // basic env plus the  "view (model, webEnv): (Model, UI.WebEnv) -> a"
    const [viewTypingEnv, setViewTypingEnv] = useState();
    const [msgUpdateTypingEnv, setMsgUpdateTypingEnv] = useState();

    useEffect(() => {
        // 
        if(typingEnv) {
            const [ base_tenv, base_dts, entity_dict, data_type_dict ] = typingEnv;
            const viewTenv = makeViewTenv(base_tenv, base_dts);
            const msgUpdateTenv = makeUpdateTenv(base_tenv, base_dts)
            setViewTypingEnv([...viewTenv, entity_dict, data_type_dict]);
            setMsgUpdateTypingEnv([...msgUpdateTenv, entity_dict, data_type_dict]);
        }
    }, [typingEnv]);

    // used to test ExprValueSelect

    return (
        <>
            {/* { testExprValueSelect() } */}
            <LeanPageDesigner {...props}
                value={editingValue}
                saveValue={saveValue}
                extendedOptions={{
                    typingEnv,
                    viewTypingEnv,
                    msgUpdateTypingEnv,
                    syntaxTree: codeEditorCommands.getSyntaxTree(),

                    updateCodeRanges: codeEditorCommands.updateCodeRanges,

                }}
                onChange={v => {
                    setEditingValue(v)
                    if (v.model && v.model !== editingValue.model && v.model.contentDict !== editingValue.model.contentDict) {

                        // const sourceCode = value.src;
                        // const parseTree = parser.parse(sourceCode).topNode;

                        const st = codeEditorCommands.getSyntaxTree() || {};

                        const sourceCode = st.codeText;
                        const parseTree  = st.tree;

                        const tree = extractParseTree(parseTree.topNode, sourceCode);

                        const viewRange = getFunctionRange(tree, "view");                        

                        const { view, styles } = LeanPageDesigner.generateOutput(v.model);

                        const newSoureCode = updateSourceCode(sourceCode, viewRange, view);

                        // source code update should used different way:
                        if(sourceCode != newSoureCode) {
                            codeEditorCommands.updateCodeText(newSoureCode);
                        }
                        
                        onChange({
                            ...fromEditorValue(v),
                            styles: {
                                ...(value.styles || {}),
                                generated: styles,
                            }
                        })
                    }
                }} />
        </>
    )
}


function toEditorValue({ visual = {} }) {
    const rawBlocks = visual ? (visual.blocks || []) : [];
    // initialize blocks

    const blocks = rawBlocks.map(b => {
        return new Block({
            ...b,
            blockTypes: LeanPageDesigner.blockTypes,
        })
    });

    return {
        model: {
            contentDict: blocks.reduce((acc, b) => {
                return {
                    ...acc,
                    [b.id]: b
                }
            }, {}),
            selected: null
        }
    }
};

function fromEditorValue({ model }) {
    const blocks = Object.values(model.contentDict).map(b => b.toJSON());

    if (!blocks || blocks.length == 0) {
        return {}
    }
    return {
        visual: {
            blocks
        }
    }
}


function updateSourceCode(sourceCode, range, newFragment) {
    if (range) {
        // insert
        return sourceCode.substring(0, range.from) + newFragment + sourceCode.substring(range.to);

    } else {
        // append
        return sourceCode + "\n" + newFragment
    }
}


// 
function getFunctionRange(tree, functionName) {

    // first level is File, the second level is the defs;

    // FunctionDef /* precedence: left 0 */ {
    //     Name MultiNewLine? (Pattern MultiNewLine?)+ TypeAnnot? "=" expr
    //   }

    const viewDef = tree.children.find(c => {
        return c.name == "Def" && c.children[0].name === "FunctionDef" && c.children[0].children[0].text === functionName
    });

    if (viewDef) {
        return {
            from: viewDef.from,
            to: viewDef.to
        }
    } else {
        return undefined
    }


}


// coupled with the generateOutput

// view params declarations:
const viewParamsDecl = `
model: Model = external;
webEnv: UI.WebEnv = external;
`

function makeViewTenv(base_tenv, base_dts) {
    return makeTenvWithDefs(viewParamsDecl, base_tenv, base_dts);
}

const updateParamsDecl = `
msg: Msg = external;
model: Model = external;
`

function makeUpdateTenv(base_tenv, base_dts) {
    return makeTenvWithDefs(updateParamsDecl, base_tenv, base_dts);
}





