
import React from 'react';

import { getFieldsFromFieldItems } from '../builderUtils'

const Input_PosterSetting = require('Client/re/widgets/input/Input_PosterSetting.bs').make

// const Input_JSON = require('Client/re/widgets/input/Input_JSON.bs').make
const Input_JSONCode = require('Client/re/widgets/input/Input_JSONCode.bs').make


import JSONInput from 'Client/js/ui/widgets/input/JSONInput';

import FilterConditionInput from 'Client/js/ui/widgets/input/FilterConditionInput';

import Bool from 'Client/js/ui/widgets/input/Bool';
import DatePicker from 'Client/js/ui/widgets/input/DatePicker';

import File from 'Client/js/ui/widgets/input/File';
import Image from 'Client/js/ui/widgets/input/Image';
import Number from 'Client/js/ui/widgets/input/Number';
import RichText from 'Client/js/ui/widgets/input/RichText';
import ShortText from 'Client/js/ui/widgets/input/ShortText';
import Text from 'Client/js/ui/widgets/input/Text';

import ColorInput from 'Client/js/ui/widgets/input/ColorInput';

import Select from 'Client/js/ui/widgets/input/Select';

import RecordSelect from 'Client/js/ui/widgets/input/RecordSelect';

import RolePermissionInput from 'Client/js/ui/widgets/input/RolePermissionInput';

import WxWorkTypeMsgAttachmentInput from 'Client/js/ui/widgets/input/WxWorkTypeMsgAttachmentInput'
import WxMpTypeReplyInput from 'Client/js/ui/widgets/input/WxMpTypeReplyInput'


import { Input } from 'antd'
import { CrontabSamples } from '../../components/CrontabSamples';
import invariant from 'invariant';
import PasswordInput from 'Client/js/ui/widgets/input/PasswordInput';


export function getFieldInput(
    field, editKey, {
        allEntities, allDataTypes, dataEntity
    }
) {

    const { type } = field
    const w = fieldInputs[type]

    if (!w) {
        return () => {
            return "<No input for: `" + type + "`>"
        }
    } else {
        return w(field, { dataEntity, allEntities, allDataTypes });
    }

}

function getAttachmentWidget(Component, field, dataEntity) {
    const { multivalued, options } = field
    return (value, onChange, params) => {
        return (
            <Component
                params={{
                    ...params,
                    multivalued,
                    uploadFor: [
                        (dataEntity && dataEntity.key) ? dataEntity.key : 'no-entity',
                        field.key
                    ].join('-'),
                    isPublic: !options ? true : options.public

                }}
                value={value}
                onChange={onChange}
            />
        )
    }
}


const adaptTo = (Component) => {
    return () => {
        // if(!params) {
        //     return null
        // }
        return (value, onChange, params) => {

            return (
                <Component
                    params={params}
                    value={value}
                    onChange={onChange}
                />
            )
        }
    }
}


const fieldInputs = {
    // Address: 'Address',
    PosterSetting: adaptTo(Input_PosterSetting),
    Boolean: adaptTo(Bool),


    Color: field => {
        return (value, onChange) => {
            return (
                <ColorInput {...{
                    value, onChange, allowedColors: field.options && field.options.allowedColors
                }} />
            )
        }
    },

    Date: (field) => {
        const { multivalued } = field
        return (value, onChange, params) => (
            <DatePicker
                params={{
                    ...params,
                    multivalued
                }}
                value={value}
                onChange={onChange}
            />
        )
    },

    DateTime: (field) => {
        const { multivalued } = field
        return (value, onChange, params) => (
            <DatePicker
                params={{
                    ...params,
                    multivalued,
                    format: "yyyy-MM-dd HH:mm",
                }}
                value={value}
                onChange={onChange}
            />
        )
    },
    File: (field, { dataEntity }) => {
        return getAttachmentWidget(File, field, dataEntity)
    },
    Image: (field, { dataEntity }) => {
        return getAttachmentWidget(Image, field, dataEntity)
    },
    JSON: adaptTo(JSONInput),
    JSONB: adaptTo(JSONInput),

    Link: (field, { allEntities, allDataTypes, queryTarget = "data" }) => {
        const { options, multivalued, required, } = field
        const entityKey = options.entity
        const entity = allEntities.find(e => e.key === entityKey || e.name === entityKey)

        if (!entity) {
            console.warn(entity, "Entity not found for key:", entityKey)
            return ""
        }

        return (value, onChange, params) => {

            return (
                <RecordSelect
                    params={{
                        ...params,
                        entityName: entity.name,
                        multivalued,
                        required,
                    }}
                    value={value}
                    onChange={onChange}
                    queryTarget={queryTarget}
                    allEntities={allEntities}
                    allDataTypes={allDataTypes}
                />
            )
        }
    },
    Number: (field) => {
        const { options, multivalued } = field;
        const { decimal } = options || {};

        return (value, onChange, params) => (
            <Number
                params={{
                    ...params,
                    multivalued, decimal
                }}
                value={value}
                onChange={onChange}
            />
        )

    },

    Integer: (field) => {
        const { multivalued } = field;

        return (value, onChange, params) => (
            <Number
                params={{
                    ...params,
                    multivalued, decimal: 0
                }}
                value={value}
                onChange={onChange}
            />
        )

    },


    RichText: (field, { dataEntity },) => {
        return (value, onChange, params) => (
            <RichText
                params={{
                    ...params,
                    uploadFor: [
                        dataEntity.key,
                        field.key
                    ].join('-')
                }}
                value={value}
                onChange={onChange}
            />
        )

    },
    ShortText: (field, { dataEntity }) => {
        const { multivalued } = field;
        return (value, onChange, params) => (
            <ShortText
                params={{
                    ...params,
                    multivalued,
                    field,
                    dataEntity,
                }}
                value={value}
                onChange={onChange}
            />
        )
    },

    Password: (field, { }) => {
        // const { multivalued } = field;
        return (value, onChange, params) => (
            <PasswordInput
                value={value}
                onChange={onChange}
            />
        )
    },

    Text: (field) => {
        const { multivalued } = field
        return (value, onChange, params) => (
            <Text
                params={{
                    ...params,
                    multivalued
                }}
                value={value}
                onChange={onChange}
            />
        )
    },
    Select: (field) => {
        const { options, multivalued, required } = field
        return (value, onChange, params) => (
            <Select
                params={{
                    ...params,
                    options: options.options,
                    multivalued,
                    required
                }}
                value={value}
                onChange={onChange}
            />
        )
    },
    /// ... others, TODO 考虑 extension 里的 DataType 的编辑性
    VirtualFieldDef: adaptTo(JSONInput),
    EventHandlerDef: adaptTo(JSONInput),

    FilterCondition: (field, { allEntities, allDataTypes }) => {

        const entityKeyOrName =
            field.options ? field.options.entity : null;

        if (!entityKeyOrName) {

            return (value, onChange, params) => (
                <div>Can't input since there's no predefined entity</div>
            )

        }


        const specifiedFields =
            field.options ? field.options.specifiedFields : null;

        const entity = allEntities.find(e => e.name == entityKeyOrName || e.key == entityKeyOrName);

        invariant(entity, "Entity " + entityKeyOrName + " is not found.")

        function getConditionFields() {

            let allFields = [...entity.fields, ...entity.virtualFields].sort((a, b) => {
                // 给 field 排序
                /// 根据 displayWeight 从大到下排列
                const getWeight = f => f.displayWeight || 0
                // 重的在前面
                return getWeight(b) - getWeight(a)
            });

            if (specifiedFields) {

                let fieldItems = specifiedFields.reduce((acc, path) => {

                    const split = path.split("as").map(s => s.trim());

                    if (split.length == 2) {
                        const [path, name] = split;
                        return {
                            ...acc,
                            [name]: path
                        }
                    } else if (split.length == 1) {
                        return {
                            ...acc,
                            [path]: path
                        }
                    } else {
                        return acc
                    };
                }, {})

                allFields = getFieldsFromFieldItems(fieldItems, entity, allEntities, allDataTypes)

            }

            const sortableFields =
                allFields.filter(
                    f => f.filterable
                ).filter(
                    f => (
                        f.type === 'ShortText' ||
                        f.type === 'Select' ||
                        f.type === 'Link' ||
                        f.type === 'Text'
                    ) || (
                            (f.type === 'Boolean' ||
                                f.type === 'Date' ||
                                f.type === 'DateTime' ||
                                f.type === 'Number') && !f.multivalued
                        )
                );

            return sortableFields
        };


        const conditionFields = getConditionFields();

        return (value, onChange, params) => (
            <FilterConditionInput
                params={{
                    ...params,
                    conditionFields,
                    entityName: entity.name
                }}
                value={value}
                onChange={onChange}
                allEntities={allEntities}
                allDataTypes={allDataTypes}
            />
        )


    },


    "WxWorkTypeMsgAttachment": (field, { dataEntity, allEntities, allDataTypes }) => {

        return (value, onChange, params) => (
            <WxWorkTypeMsgAttachmentInput
                value={value}
                onChange={onChange}
                field={field}
                params={params}
                dataEntity={dataEntity}
            />
        )
    },

    "WxMpTypeReply": (field, { dataEntity }) => {

        return (value, onChange, params) => (
            <WxMpTypeReplyInput
                value={value}
                onChange={onChange}
                field={field}
                params={params}
                dataEntity={dataEntity}
            />
        )
    },

    "AuthTypeRolePermission": (field, { allEntities, allDataTypes }) => {

        return (value, onChange, params) => (
            <RolePermissionInput
                params={params}
                value={value}
                onChange={onChange}
                allEntities={allEntities}
                allDataTypes={allDataTypes}
            />
        )

    },


    // ad hoc input
    Crontab: (f, { }) => {
        return (value, onChange) => {

            return (
                <div>
                    <Input
                        value={value}
                        onChange={e => onChange(e.target.value)}
                    />
                    <CrontabSamples value={value} />
                </div>

            )

        }

    }



    // adaptTo(RolePermissionInput),

}
