
import React, { useEffect, useState, useRef } from 'react'

import ExprCodeEditor from 'Client/js/codeEditor/ExprCodeEditor';
import PartialDefCodeEditor from 'Client/js/codeEditor/PartialDefCodeEditor';

import expression_input_helper from 'Client/ml/helpers/expression_input_helper.bs';
import extractParseTree from 'bwax/ml/printer/extractParseTree';

import { parser } from 'bwax/lang/ore.parser';

import prettyPrintLang from 'bwax/ml/printer/prettyPrintLang';

import './MsgSelect.less';

import { Select, Modal } from '@arco-design/web-react';

import ClipLoader from "react-spinners/ClipLoader";

const Option = Select.Option;

export default function MsgSelect({ value, onChange, type, disabled, extendedOptions }) {

    const { typingEnv, msgUpdateTypingEnv, syntaxTree, updateCodeRanges } = extendedOptions;

    // type: Msg, StringMsg

    const [tenv, dts,] = typingEnv;

    // // get all msg tagger:
    const msgTaggers = expression_input_helper.get_all_msg_taggers(dts);

    // 根据 type = Msg | StringMsg 等筛选 msg：
    const options = expression_input_helper.filter_msg_tagger_by_type(tenv, msgTaggers, type);

    const [boundLogic, setBoundLogic] = useState();
    // 
    function getBindingLogic(syntaxTree, value) {

        const sourceCode = syntaxTree.codeText;

        const p = parser.configure({
            top: "SingleExpr"
        })
        const valueTree = extractParseTree(p.parse(value).topNode, value);
        const taggerName = expression_input_helper.get_msg_tagger_name(value, valueTree);

        const codeTree = extractParseTree(syntaxTree.tree.topNode, sourceCode);
        // find out update function 
        // and then find the branch of the "case msg of", which is for the given tagger
        
        if(taggerName) {

            // 一下这两步如果返回值为 undefined，都说明代码需要相应的生成：
            const updateFunc = expression_input_helper.get_update_function_tree(sourceCode, codeTree);
            const caseOfExpr = expression_input_helper.get_case_msg_of_expr(updateFunc);        

            const branch = expression_input_helper.get_msg_update_branch(caseOfExpr, taggerName);

            if(branch) {
                return { branch }
            } else {
                // no handling logic, resolve the tagger and the code to insert
    
                // the tagger should be like [ constructorName: string, paramters: term list ]
                // code to insert is the line before | _ ->  or after all other branches.
                const tagger = expression_input_helper.get_msg_tagger_setting(tenv, taggerName);

                const insertAt = expression_input_helper.get_new_branch_position(caseOfExpr);

                return { 
                    tagger,
                    insertAt
                }
    
            }

        } else {
            return {}
        }        

    }

    useEffect(() => {
        if (syntaxTree && value) {
            const msgLogicTree = getBindingLogic(syntaxTree, value);
            setBoundLogic(msgLogicTree);
        }

    }, [value, syntaxTree]);

    const [modalVisible, setModalVisible] = useState();

    function renderBoundLogicLink() {
        if (!boundLogic || !value) { return null }

        if (boundLogic.branch) {
            // 
            return (
                <div className="edit-logic-link" onClick={e => {
                    e.stopPropagation();
                    setModalVisible(true);
                }}>
                    编辑处理逻辑
                </div>
            )
        } else if (boundLogic.tagger) {
            return (
                <div className="add-logic-link" onClick={e => {
                    e.stopPropagation();
                    setModalVisible(true);
                }}>
                    添加处理逻辑
                </div>
            )
        } else {
            return (
                <div className="not-a-link">
                    无法识别事件
                </div>
            )
        }
    }

    function renderBoundLogicModal() {
        if (!boundLogic || !value) { return null }

        return (
            <MsgUpdateInputModal {...{
                onChange: v => {
                    console.log("Changed", v)
                },
                msgUpdateTypingEnv,

                msg: value,

                boundLogic,

                visible: modalVisible,
                setVisible: setModalVisible,

                updateCodeRanges,
            }} />
        )
    }


    return (
        <div className="lc-msg-select">
            <Select size="small" disabled={disabled} onChange={v => {
                onChange(v);
            }} value={value}>
                {options.map(([option, matched], index) => (
                    <Option key={option} value={option} disabled={!matched}>
                        {option}
                    </Option>
                ))}
            </Select>
            {renderBoundLogicLink()}
            {renderBoundLogicModal()}
        </div>
    )
}

function PatternEditor({ pattern, typingEnv, onChange, onError, bindSetter }) {
    return (
        <div style={{ display: "inline-block" }}>
            <PartialDefCodeEditor {...{
                typingEnv,
                sourceCode: pattern,
                prefix: "",
                suffix: " = msg;",

                syntaxTop: "SinglePattern",

                onChange, onError,
                bindSetter,
            }} />
        </div>
    )

}



function MsgUpdateInputModal({ msg, boundLogic, msgUpdateTypingEnv, visible, setVisible, updateCodeRanges }) {

    function getCode(boundLogic) {
        if (boundLogic.branch) {
            const pattern = expression_input_helper.get_pattern_code(boundLogic.branch);
            const updateLogic = expression_input_helper.get_last_expr(boundLogic.branch);
            const expr = prettyPrintLang(64, expression_input_helper.get_code(updateLogic), "SingleExpr")
            return {
                pattern, expr
            }
        } else {
            // 表示添加
            // 根据 msg tagger 的样式生成对应的 pattern
            const { tagger } = boundLogic;

            const pattern = expression_input_helper.generate_pattern_by_tagger(tagger)

            return {
                pattern, expr: "(model, none)"
            }
        }
    }

    // { pattern: string, expr: string }
    const [givenCode, setGivenCode] = useState(_ => {
        return getCode(boundLogic)
    })
    const [editing, setEditing] = useState(givenCode);
    const [error, setError] = useState();

    useEffect(() => {
        if (boundLogic) {
            const code = getCode(boundLogic);
            setEditing(code);
            setGivenCode(code);
        }
    }, [boundLogic])


    return (
        <Modal
            title='页面事件的响应逻辑'
            visible={visible}
            className="lc-msg-update-input-modal"
            okButtonProps={{
                disabled: !!error || (editing === undefined) || !editing.pattern || !editing.expr ||
                    (editing.pattern === givenCode.pattern && editing.expr === givenCode.expr)
            }}
            onOk={() => {
                // onChange(editing);
                if(boundLogic.branch) {
                    const patternIndex = expression_input_helper.get_pattern_index(boundLogic.branch);
                    const exprIndex = expression_input_helper.get_last_expr_index(boundLogic.branch);
    
                    function indentExpr(expr) {
                        // 
                        const formatted = prettyPrintLang(64, expr, "SingleExpr");

                        const indent = "      ";
                        return formatted.split("\n").map((line, index) => {
                            return index === 0 ? line : indent + line
                        }).join("\n")
                    }
    
                    const updates = [
                        editing.pattern == givenCode.pattern ? null : { from: patternIndex[0], to: patternIndex[1], codeText: editing.pattern },
                        editing.expr == givenCode.expr ? null : { from: exprIndex[0], to: exprIndex[1], codeText: indentExpr(editing.expr) },
                    ].filter(x => !!x);
    
                    updateCodeRanges(updates);

                } else if(boundLogic.insertAt) {
                    // add code 
                    const { insertAt } = boundLogic;

                    function indentExpr(expr) {
                        const formatted = prettyPrintLang(64, expr, "SingleExpr");
                        const indent = "      ";
                        return formatted.split("\n").map((line, index) => {
                            return indent + line
                        }).join("\n")
                    }

                    const codeText = `\n\n  | ${editing.pattern} ->\n` + indentExpr(editing.expr);
                    
                    updateCodeRanges([{
                        from: insertAt, to: insertAt, codeText
                    }]);

                }


                setVisible(false);
            }}
            onCancel={() => {
                // rollback
                const c = getCode(boundLogic);
                setEditing(c);
                setVisible(false)
            }}
        >
            <MsgUpdateInput {...{
                msg,
                msgUpdateTypingEnv,
                value: editing,
                onChange: v => {
                    setEditing(v);
                    setError();
                },
                onError: err => {
                    setError(err);
                },
                expectedType: expression_input_helper.the_update_return_type,

            }} />
        </Modal>
    )

}



function MsgUpdateInput({ msg, value, onChange, onError, msgUpdateTypingEnv, bindCodeSetter, expectedType }) {

    // return (Model, Cmd Msg) 

    const [resultedTypingEnv, setResultedTypingEnv] = useState();

    const [editing, setEditing] = useState(value);

    const { pattern, expr } = editing;

    const [errors, setErrors] = useState({ pattern: undefined, expr: undefined });

    useEffect(() => {
        if (errors.expr || errors.pattern) {
            // do nothing
        } else {
            onChange(editing);
        }
    }, [editing])

    const setPatternCodeTextRef = useRef();
    const setExprCodeTextRef = useRef();

    function updateCode(v) {
        const { pattern, expr } = v;
        if (setPatternCodeTextRef.current) {
            setPatternCodeTextRef.current(pattern)
        }
        if (setExprCodeTextRef.current) {
            setExprCodeTextRef.current(expr)
        }
    }

    useEffect(() => {
        if (editing !== value) {
            setEditing(value);
            updateCode(value);
        }
    }, [value])



    if (bindCodeSetter) {
        // { pattern, expr }
        bindCodeSetter(v => updateCode(v))
    }

    const loading = (
        <div className="lc-loading-container">
            <ClipLoader />
        </div>
    )

    return (
        <div className="lc-msg-update-input">
            <div className="caption">
                <div>当前交互触发的页面事件为 <pre>{msg}</pre></div>
                <div>
                    对 <PatternEditor {...{
                        pattern, typingEnv: msgUpdateTypingEnv,
                        onChange: (_ast, code, { resultedTypingEnv }) => {
                            setErrors(prev => ({ ...prev, pattern: undefined }));
                            setEditing(prev => ({ ...prev, pattern: code }));
                            setResultedTypingEnv(resultedTypingEnv)
                        },
                        onError: error => {
                            setErrors(prev => ({ ...prev, pattern: error }));
                            onError(error)
                        },
                        bindSetter: setCodeText => {
                            setPatternCodeTextRef.current = setCodeText;
                        }
                    }} />
                    的响应逻辑如下：
                </div>
            </div>
            {resultedTypingEnv ?
                (
                    <div className="expr-editor">
                        <ExprCodeEditor {...{
                            typingEnv: resultedTypingEnv,
                            expectedType,
                            sourceCode: expr,
                            onChange: (_ast, code) => {
                                setErrors(prev => ({ ...prev, expr: undefined }));
                                setEditing(prev => ({ ...prev, expr: code }));
                            },
                            onError: error => {
                                setErrors(prev => ({ ...prev, expr: error }));
                                onError(error)
                            },
                            bindSetter: setCodeText => {
                                setExprCodeTextRef.current = setCodeText;
                            }
                        }} />
                    </div>
                ) : loading
            }
        </div>
    )
}

