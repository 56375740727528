import { NodeHolder } from 'bwax/core/store/DataBook';

/// this will compare the value after the "evaluation":
export default function isNodeValueEqual (a, b) {

    // TODO 没有处理 circular （除了 NodeHolder 之外）
    
    if(a === b) {
        return true
    }

    if (a && b && typeof a == 'object' && typeof b == 'object') {
        if (a.constructor !== b.constructor) {
            return false;
        }

        if(a instanceof NodeHolder) {
            // both are NodeHolder
            return isNodeValueEqual(a.value, b.value)
        }

        // array
        if(Array.isArray(a) && Array.isArray(b)) {
            if( a.length !== b.length) {
                return false;
            }
            for(let i = 0; i < a.length; i++ ){
                let elementEqual = isNodeValueEqual(a[i], b[i])
                if(!elementEqual) {
                    return false;
                }
            }
            return true
        }

        // object
        let aKeys = Object.keys(a);
        let bKeys = Object.keys(b);
        if(aKeys.length !== bKeys.length) {
            return false;
        }
        for(let i = 0; i < aKeys.length; i++ ){
            let key = aKeys[i];
            let elementEqual = isNodeValueEqual(a[key], b[key])
            if(!elementEqual) {
                return false;
            }
        }
        return true
    }

    //
    // true if both NaN, false otherwise
    return a!== a && b!== b;

}
