
import React, { useState, useEffect } from 'react'

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs'
import { CgMoreAlt } from 'react-icons/cg';

import { Input, Select } from '@arco-design/web-react';
const Option = Select.Option;

import Tooltip from 'bwax-ui/components/Tooltip';
import Popover from 'bwax-ui/components/legacy/Popover'
import PositionEdit from '../components/PositionEdit';
import ResetableLabel from '../components/ResetableLabel';
import './Size.less'

const defaultSizeEach = {
    width: { value: "Auto", unit: '-' },
    height: { value: "Auto", unit: '-' },
    minWidth: { value: 0, unit: 'px' },
    minHeight: { value: 0, unit: 'px' },
    maxWidth: { value: 'None', unit: '-' },
    maxHeight: { value: 'None', unit: '-' }
}

export default function Size({ attributes, onChange }) {

    const [size, setSize] = useState(defaultSizeEach)

    const valueRegx = /(\d+(?:\.\d+)?)/;
    const sizeRegx = /^((\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?|auto|none)$/

    useEffect(() => {
        let matchedSize = {...defaultSizeEach}
        Object.keys(size).map(s => {
            const value = attributes[s];
            // value 可能是 10px, 12rem, 或者 100
            if (sizeRegx.exec(value)) {
                const matchResult = sizeRegx.exec(value)
                if (matchResult) {
                    matchedSize = {
                        ...matchedSize,
                        [s]: matchResult[2] ? { value: matchResult[2], unit: matchResult[3] ? matchResult[3] : 'px' } :
                            { value: s.startsWith('max') ? 'None' : 'Auto', unit: '-' }
                    }
                }
            } else {
                matchedSize = {
                    ...matchedSize,
                    [s]: defaultSizeEach[s]
                }
            }
        })
        setSize({ ...size, ...matchedSize })

    }, [attributes])

    const units = ['px', 'em', 'rem', 'ch', 'vw', 'vh', '%', 'auto']
    const maxUnits = ['px', 'em', 'rem', 'ch', 'vw', 'vh', '%', 'none']

    function selectUnit(styleKey, unit) {
        if (unit === 'auto' || unit === "none") {
            setSize({
                ...size,
                [styleKey]: { value: unit === "auto" ? "Auto" : "None", unit: '-' }
            })
            onChange({ [styleKey]: unit === "auto" ? "auto" : "none" })
        } else {
            setSize({ ...size, [styleKey]: { ...size[styleKey], unit } })
            if (size[styleKey].value === 'Auto' || size[styleKey].value === 'None') {
                setSize({ ...size, [styleKey]: { value: 0, unit } })
                onChange({ [styleKey]: `${0}` })
            } else {
                const v = size[styleKey].value
                if (v.match(valueRegx)) {
                    onChange({ [styleKey]: `${v}${v === '0' ? '' : unit}` })
                } else {
                    onChange({ [styleKey]: `0` })
                }
            }
        }
    }

    function renderUnitSelect(styleKey, unitsArr) {
        return (
            <Select value={size[styleKey].unit} size={'mini'} className="unit-select" arrowIcon={null} triggerProps={{ autoAlignPopupWidth: false }}
                onChange={unit => selectUnit(styleKey, unit)}
            >
                {unitsArr.map(option => (
                    <Option key={option} value={option} className='unit-option'>
                        {option.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
    }

    function inputSizeValue(styleKey, value) {
        setSize({ ...size, [styleKey]: { ...size[styleKey], value } })
    }

    function inputSizeValueConfirm(styleKey) {
        const { value } = size[styleKey]
        const matchResult = value ? value.match(sizeRegx) : null
        if (matchResult) {
            const matchedValue = matchResult[2] ? matchResult[2] : matchResult[1]
            const attrValue = matchedValue === "none" && !styleKey.startsWith("max") ? 'auto' : 
                matchedValue === "auto" && styleKey.startsWith("max") ? "none" : matchedValue
            const matchedUnit = matchResult[3] ? matchResult[3] : (size[styleKey].unit === '-' ? 'px' : size[styleKey].unit)
            setSize({ ...size, [styleKey]: { ...size[styleKey], value: attrValue, unit: matchedUnit }})
            onChange({ [styleKey]: `${attrValue}${attrValue === '0' || attrValue === "auto" || attrValue === "none" ? "" : matchedUnit}` })
        }
    }

    const overflow = [{
        value: 'visible', icon: <AiFillEye />, tooltip: 'Visible: shows content that overflows its container'
    }, {
        value: 'hidden', icon: <AiFillEyeInvisible />, tooltip: "Hidden: hide overflowing contents without adding a scrollbar"
    }, {
        value: 'scroll', icon: <BsReverseLayoutSidebarInsetReverse />, tooltip: "Scroll: always displays a sidebar for overflowing content"
    }, {
        value: 'auto', text: 'Auto', tooltip: "Auto: only display a scrollbar when content overflows"
    }]

    const fit = [{
        value: 'fill', label: 'Fill'
    }, {
        value: 'contain', label: 'Contain'
    }, {
        value: 'cover', label: 'Cover'
    }, {
        value: 'none', label: 'none'
    }, {
        value: 'scale-down', label: 'Scale Down'
    }]

    function renderObjectPositionEdit() {

        const positionUnit = ['px', '%', 'em', 'vw', 'vh']

        return (
            <div className='objectPosition-edit'>
                <ResetableLabel allAttributes={attributes} label={"Position"} attributesGroup={"size"} 
                    attribute={'objectPosition'} onChange={onChange}
                />
                <PositionEdit attribute={attributes['objectPosition']} unitsArr={positionUnit}
                    onChange={value => onChange({ objectPosition: value })}
                />
            </div>
        )
    }

    return (
        <div className='size-container'>
            {
                Object.keys(size).map(s => {
                    const label = s.startsWith('min') ? `Min ${s[3].toUpperCase()}` :
                        s.startsWith('max') ? `Max ${s[3].toUpperCase()}` : s.charAt(0).toUpperCase() + s.slice(1)
                    const unitsArr = s.startsWith('max') ? maxUnits : units

                    return [
                        <ResetableLabel key={'label'} allAttributes={attributes} label={label} attributesGroup={"size"} 
                            attribute={s} onChange={onChange}
                        />,
                        <Input key={'input'} size="mini" value={size[s].value} suffix={renderUnitSelect(s, unitsArr)}
                            onChange={value => inputSizeValue(s, value)}
                            onPressEnter={() => inputSizeValueConfirm(s)}
                            onBlur={() => inputSizeValueConfirm(s)}
                        />
                    ]
                })
            }
            <div className='style-with-options overflow-options'>
                <ResetableLabel allAttributes={attributes} label={"Overflow"} attributesGroup={"size"} 
                    attribute={"overflow"} onChange={onChange}
                />
                <div className='options'>
                    {
                        overflow.map((op, index) => {
                            const { value, icon, text, tooltip } = op
                            const isActive = Object.keys(attributes).some(k => k === 'overflow') && attributes['overflow'] === value
                            return (
                                <Tooltip key={index} text={tooltip}>
                                    <div className={`option-box ${isActive ? 'active' : ''}`}
                                        onClick={() => onChange({ 'overflow': value })}
                                    >
                                        {icon ? icon : text}
                                    </div>
                                </Tooltip>
                            )
                        })
                    }

                </div>
            </div>
            <div className='fit-container'>
                <Tooltip text={"Specifies how an image should be resized to fit its container"}>
                    <div>
                        <ResetableLabel allAttributes={attributes} label={"Fit"} attributesGroup={"size"} 
                            attribute={"objectFit"} onChange={onChange}
                        />
                    </div>
                </Tooltip>
                <div className='fit-edit'>
                    <Select value={attributes['objectFit'] || 'fill'} size={'mini'} onChange={f => onChange({ 'objectFit': f })} >
                        {fit.map(f => (
                            <Option key={f.value} value={f.value}>
                                {f.label}
                            </Option>
                        ))}
                    </Select>
                    <Tooltip text="Object positions settings">
                        <div className={`edit-objectPosition-toggle ${attributes['objectPosition'] && attributes['objectPosition'] !== '50% 50%' ? 'active' : ''}`}>
                            <Popover content={renderObjectPositionEdit()} positions={["bottom", "top"]} reposition={true}>
                                <div><CgMoreAlt style={{ fontSize: 16 }}/></div>
                            </Popover>
                        </div>
                    </Tooltip>
                    
                </div>
            </div>
        </div>
    )
}
