import React from 'react';

import Drawer from 'rsuite/Drawer';

import getImageURL from 'bwax-ui/getImageURL'


export default function AppSelectorDrawer({
    logo, siteName, visible, onClose = () => { },
    applications, currentApplication, onApplicationSelected,

    appSelectorItems

}) {

    // 总是有应用的：
    // const defaultName = "<< 默认应用 >>";
    // const defaultCode = "default";

    const currentApp = currentApplication ? currentApplication.name : "默认应用";


    // 把 default 的放在最上面
    const apps = [
        // { name: defaultName, code: defaultCode },
        ...applications.filter(a => a.isLibrary !== true)
    ].sort((a, b) => {
        if(a.code.toLowerCase() === "default") {
            return -1
        } else if(b.code.toLowerCase() === "default") {
            return 1
        } else {
            return 0
        }
    });

    return (
        <Drawer
            placement="left"
            // closable={false}
            onClose={onClose}
            open={visible}
            className="app-selector-drawer"
            size="xs"
        //   getContainer={false}
        //   style={{ position: 'absolute' }}
        >
            <div className="app-selector-head">
                {logo ?
                    <div className="site-logo" style={{
                        backgroundImage: `url(${getImageURL(logo, "thumbnail")})`,
                    }}>
                    </div> :
                    null
                }
                <div className="site-name">
                    {siteName}
                </div>
            </div>
            <div className="app-selector-caption">
                选择应用
            </div>
            <div className="app-list no-select">
                {apps.map(app => {
                    const { name, code } = app;
                    return (
                        <div key={name} className={"app-item" + (currentApp == name ? " current" : "")}
                            onClick={() => {
                                onApplicationSelected && onApplicationSelected(app);
                                onClose();
                            }}
                        >
                            {name == "默认应用" ? "<<默认应用>>" : name }
                        </div>
                    )
                })}
                {
                    appSelectorItems.length > 0 ?
                        (
                            <>
                                <div className="app-selector-divider">
                                </div>
                                {
                                    appSelectorItems.map(item => {
                                        const { label, onClick } = item;
                                        return (
                                            <div key={label} className={"app-item"} onClick={() => {
                                                onClick && onClick();
                                                onClose();
                                            }}>
                                                {label}
                                            </div>
                                        )
                                    })
                                }

                            </>
                        ) : null
                }
            </div>
        </Drawer>
    )

}
