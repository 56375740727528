// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Json$BwaxAdmin = require("../../../../lib/bwax-js/ml/utils/json.bs.js");
var QrCode$BwaxAdmin = require("../../../../lib/bwax-ui/re/auxiliary/QrCode.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Display_QRCode(Props) {
  var params = Props.params;
  var value = params.value;
  var render = function (info, maybeIcon) {
    return React.createElement(QrCode$BwaxAdmin.make, {
                value: info,
                size: 128
              });
  };
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.overflowX(Css.auto),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.overflowY(Css.auto),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.height(Css.px(128)),
                  /* [] */0
                ])
            ])
        ]));
  var inner;
  if (value !== null) {
    var maybeInfo = Json$BwaxAdmin.get_value("info", Js_json.decodeString, value);
    var maybeIcon = Json$BwaxAdmin.get_field("icon", value);
    inner = maybeInfo !== undefined ? render(maybeInfo, maybeIcon) : null;
  } else {
    inner = null;
  }
  return React.createElement("div", {
              className: className
            }, inner);
}

var make = Display_QRCode;

exports.make = make;
/* Css Not a pure module */
