
import React from 'react'

import { convertToRaw, convertFromRaw, ContentState, EditorState } from 'draft-js'
import { convertFromHTML, convertToHTML } from './3rd/draft-convert/src'


/// all the converts that are supposed to be used:

import * as blockAlignment from './plugins/blockAlignment/converter'
import * as emptyBlock from './plugins/emptyBlock/converter'
import * as hyperlink from './plugins/hyperlink/converter'
import * as image from './plugins/image/converter'
import * as inlineStyle from './plugins/inlineStyle/converter'
import * as media from './plugins/media/converter'
import * as code from './plugins/code/converter'

import * as hr from './plugins/hr/converter'
import * as mention from './plugins/mention/converter'

export const defaultConverters = {
    emptyBlock,
    blockAlignment,
    hyperlink,
    image,
    inlineStyle,
    media,
    hr,
    code,
    mention
}

export default function initConverters(converters) {

    const allConverters = Object.assign(defaultConverters, converters, {})
    //console.log(' allConverters : ',  allConverters);

    const ps = Object.keys(allConverters).map(key => allConverters[key])

    const importers = ps.map(p => {
        if (p.convertFromHTML) {
            return p.convertFromHTML
        } else {
            return null
        }
    }).filter(c => c !== null)

    const exporters = ps.map(p => {
        if (p.convertToHTML) {
            return p.convertToHTML
        } else {
            return null
        }
    }).filter(c => c !== null)



    function htmlToDraftContent(html) {
        const extensions = {
            htmlToStyle: (nodeName, node, currentStyle) => {
                const ret = importers.reduce((accStyle, current) => {

                    const { htmlToStyle } = current
                    if (htmlToStyle) {
                        return htmlToStyle(nodeName, node, accStyle)
                    } else {
                        return accStyle
                    }

                }, currentStyle)

                return ret

            },

            textToEntity: (text, createEntity) => {
                const ret = importers.reduce((acc, current) => {
                    const { textToEntity } = current
                    if (textToEntity) {
                        return [...acc, ...textToEntity(text, createEntity)]
                    } else {
                        return acc
                    }
                }, [])

                return ret

            },

            htmlToBlock: (nodeName, node) => {

                for (let i = 0; i < importers.length; i++) {
                    const { htmlToBlock } = importers[i]
                    if (htmlToBlock) {
                        const ret = htmlToBlock(nodeName, node)
                        if (ret) {
                            return ret
                        }
                    }
                }
                return undefined
            },

            htmlToEntity: (nodeName, node, createEntity) => {
                for (let i = 0; i < importers.length; i++) {
                    const { htmlToEntity } = importers[i]
                    if (htmlToEntity) {
                        const ret = htmlToEntity(nodeName, node, createEntity)
                        if (ret) {
                            return ret
                        }
                    }

                }
                return undefined
            }
        }
        return convertFromHTML(extensions)(html)
    }

    return {
        htmlToDraftContent,

        htmlToDraft: (html, decorator) => {
            return EditorState.createWithContent(htmlToDraftContent(html), decorator)
        },

        draftToHTML: (state) => {
            const extensions = {
                styleToHTML: (style) => {
                    for (let i = 0; i < exporters.length; i++) {
                        const { styleToHTML } = exporters[i]
                        if (styleToHTML) {
                            const ret = styleToHTML(style)
                            if (ret) {
                                return ret
                            }
                        }
                    }
                    return undefined
                },
                blockToHTML: (block) => {
                    for (let i = 0; i < exporters.length; i++) {
                        const { blockToHTML } = exporters[i]
                        if (blockToHTML) {
                            const ret = blockToHTML(block)
                            if (ret) {
                                return ret
                            }
                        }
                    }
                    return undefined

                },
                entityToHTML: (entity, originalText) => {
                    for (let i = 0; i < exporters.length; i++) {
                        const { entityToHTML } = exporters[i]
                        if (entityToHTML) {
                            const ret = entityToHTML(entity, originalText)
                            if (ret) {
                                return ret
                            }
                        }
                    }
                    return originalText;
                }
            }

            return convertToHTML(extensions)(
                state.getCurrentContent ? state.getCurrentContent() : state
            )
        },


    }

}
