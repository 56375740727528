import React, { useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'

import ExecutionContext from 'bwax-ui/page/ExecutionContext'
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import ApplicationContext from 'bwax/util/ApplicationContext';
import Popover from 'bwax-ui/components/legacy/Popover';

import Button from 'rsuite/Button'

export default function ModalLink(props) {

    const { to, sourceApp, children, ...other } = props

    const ec = useContext(ExecutionContext);

    const dlc = useContext(DataLoaderContext);

    const history = useHistory();

    const applicationContext = useContext(ApplicationContext) || {};
    const { currentApplication, mode = "app" /* or design */, applications } = applicationContext;


    const popoverVisibleSetterRef = useRef();

    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1070
    // 检测应用是否当前可访问应用（非系统应用
    function isTargetAppInScope(sourceApp) {
        return applications.some(a => a.code == sourceApp.code)
    }

    function wrapPath(path) {
        if (!path.startsWith(`/${mode}/`) && !path.startsWith("http")) {

            const currentCode =  (currentApplication ? currentApplication.code.toLowerCase() : "default");;

            // 只有当 sourceApp 是当前可访问应用（非系统应用）的情况下，在修改 prefix
            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1070
            const appCode = sourceApp && isTargetAppInScope(sourceApp) ? sourceApp.code.toLowerCase() : currentCode;

            return `/${mode}/` + appCode + path
        } else {
            return path
        }
    }

    function getTo(to) {
        if (typeof (to) === 'object') {
            const { pathname, state } = to;
            return {
                ...to,
                pathname: wrapPath(pathname),
                state: { ...state, modal: true }
            }
        }
        return {
            pathname: wrapPath(to),
            state: { modal: true }
        }
    }
    if (ec && ec.designMode) {
        return (
            <a {...other} onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                ec.routeTo(to, false);
            }} href={to}>{children}</a>
        )
    } else if (!to) {
        return children  // TODO should we wrap a p?
    } else if (to && to.startsWith('http')) {
        return <a {...other} href={to} onClick={e => {
            e.stopPropagation();
        }}>{children}</a>
    } else {

        // 处理跳转应用的情况 https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1063
        if (sourceApp && sourceApp.code !== currentApplication.code && isTargetAppInScope(sourceApp)) {
            const { pathname, state } = getTo(to);

            const tip = (
                <div style={{ padding: "1rem", display: "flex", flexDirection: "column", width: "18rem" }}>
                    <div style={{
                        fontSize: "12px", marginBottom: "1rem", opacity: 0.8
                    }}>目标页面属于【{sourceApp.name}】，是切换到该应用，还是使用新窗口打开？</div>
                    <div style={{
                        display: "flex", justifyContent: "flex-end"
                    }}>
                        <Button style={{ marginRight: "1rem" }} onClick={() => {
                            function withPrefix(n) {
                                return dlc.sandbox ? "/sandbox" + n : n;
                            }
                            window.open(withPrefix(pathname), "_blank");
                            popoverVisibleSetterRef.current(false)

                        }}>使用新窗口打开</Button>
                        <Button  appearance="primary" onClick={() => {
                            history.push(pathname, state);
                            popoverVisibleSetterRef.current(false)
                        }}>
                            切换到{sourceApp.name}                            
                        </Button>
                    </div>
                </div>
            )

            return (
                <Popover content={tip} bindVisibleSetter={setter => {
                    popoverVisibleSetterRef.current = setter;
                }}>
                    <a {...other}>
                        {children}
                    </a>
                </Popover>

            )
        } else {
            return (
                <Link {...other} to={getTo(to)} onClick={e => {
                    e.stopPropagation();
                }}>
                    {children}
                </Link>
            )
        }



    }

}
