
import { entitySelections, commonSelections, fieldOnlySelection } from './commonSelections'




export default {

    sandboxText: `
    query  {
        sandboxDefinition {
            extensions {
                name
                key
                dataTypes 
                entities
                lookupValueSettings
            }

            pageComponents {
                name
                ioTypeMetas
            }

            adminPages {
                name
                ioTypeMetas
            }
        }
    }  
    `,
    extractSandboxResult: result => {
        // TODO ERROR handling:
        return JSON.parse(result).data.sandboxDefinition
    },

    text: `
    query  {
        definition {
            extensions {
                name
                key
                dataTypes 
                entities
                settings
                lookupValueSettings
            }
            mainTheme
            adminPages {
                name
                ioTypeMetas
            }

        }
    }  
    `,

    extractResult: result => {
        // TODO ERROR handling:

        return JSON.parse(result).data.definition
    }

}


export const FindDataEntity = {
    text: `
    query($id: DataEntityId) {
        findDataEntity(id: $id) {
        ${entitySelections}
        }
    }
  `,

}


export const MutAddDataEntity = {
    text: `
        mutation($input: AddDataEntityInput!) {
            addDataEntity(input: $input) {
                addedDataEntityEdge {
                    node {
                        ${entitySelections}
                    }
                }
            }
        }
    `,
    extractResult: result => {
        // TODO ERROR handling:
        const { data, errors } = JSON.parse(result)

        const entity = (
            data.addDataEntity && data.addDataEntity.addedDataEntityEdge
                ? data.addDataEntity.addedDataEntityEdge.node
                : null
        )
        return {
            entity, errors
        }

    }

}
