
import invariant from 'invariant'

import { buildUpdateRecordMutation } from 'bwax/query/QueryBuilder'

import { invalidateCache } from 'bwax-ui/legacy/store/useDataLoader'

export default function UpdateRecord ({ allEntities, allDataTypes, queryRunner }) {


    return async function (params) {

        const { formData, id, entityName } = params

        const entity = allEntities.find(e => e.name === entityName)

        invariant(entity, "Entity not found: " + entityName, allEntities)

        const { 
            queryText, buildInput, extractResult 
        } = buildUpdateRecordMutation(
            entity, allEntities, allDataTypes, { queryDepth: 1 }
        )

        const input = buildInput(id, formData)
        /// TODO 检查输入是否合理

        const result = await queryRunner(queryText, {input})

        /// TODO error handling


        /// invalidate cache if succeeded
        invalidateCache()


        const data = extractResult(result)

        return data
    }
}