
export default function setupQueryCache (appData) {

    return {
        getData: (key) => {
            if(!appData) {
                return undefined;
            }
            const d = appData[key];
            const cacheInvalidatedAt = appData.__cacheInvalidatedAt__ || 0;
            if(d && typeof(d) == 'object' && d.data && d.loadedAt > cacheInvalidatedAt) {
                return d.data
            } else {
                return undefined
            }
        },

        setData: (key, data) => {
            if(!appData) {
                return
            }
            let d = { data, loadedAt: Date.now() };
            appData[key] = d;

        },
    
        setLoading: (key, promise) => {
            if(!appData){ 
                return 
            }
            appData[key] = promise;
        },

        removeData: (key) => {
            if(!appData) {
                return
            }
            delete appData[key];
        },
    
        clearCache: () => {
            if(!appData) {
                return
            }
            appData.__cacheInvalidatedAt__ = Date.now()
        }
    }
}