
import React, { useState, useRef, useEffect } from 'react';

import { MdChevronRight, MdExpandMore } from 'react-icons/md';

import ClampedText from 'bwax-ui/components/ClampedText';

import { useDrag } from 'react-dnd'

import './SelectorNode.less';

import ContextPopup from '../ContextPopup';

import { getGlobalEventBus } from '../EventBus';

//  node contains: icon, name, onContextMenu, onSelect, onDoubleClick, childNodes = []
export default function SelectorNode(props) {

    const {
        node, depth = 0,
        onDoubleClick,
        renderContextMenu, 
        currentItem,

        onExpandTo,

    } = props;

    const { item, childNodes = [] } = node;

    const { icon, itemType = "unknown", data } = item

    const [collapsed, setCollapsed] = useState(
        // true
        depth >= 1 && childNodes.length > 0 ? true : false
    );

    const nodeRef = useRef();
    nodeRef.current = node;

    const elRef = useRef();

    useEffect(() => {

        const unsubscribe = getGlobalEventBus().on("expandToItem", ({ item }) => {

            // is descendant
            function isDescendant (n) {
                if(n.item.itemKey() === item.itemKey()) {
                    return true;
                } else if(n.childNodes.length === 0) {
                    return false
                } else {
                    return n.childNodes.some(c => isDescendant(c))
                }
            }
            const node = nodeRef.current;

            if(isDescendant(node) && node.childNodes.length > 0) {
                setCollapsed(false)
            }
            if(node.item.itemKey() === item.itemKey()) {
                // make it self scroll in view

                onExpandTo(node.item);

                if(elRef) {
                    setTimeout(() => {
                        elRef.current.scrollIntoView({
                            block: "center",
                            behavior: "smooth",
                        })
                    }, 200)
                }               

            }

        });
        return () => {
            unsubscribe();
        }
    }, []);

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: itemType,
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        item,

        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));

    const renderOpIcon = () => {
        if (node.childNodes && node.childNodes.length !== 0) {
            return collapsed ? <MdChevronRight /> : <MdExpandMore />
        } else {
            return null
        }
    }

    const [ contextPosition, setContextPosition ] = useState();
    const [ contextPopupVisible, setContextPopupVisible] = useState(false);

    const contextPopupCloserRef = useRef();

    const isActive = !!currentItem && currentItem.itemKey() === node.item.itemKey();

    return (

        <div className="selector-node" ref={elRef}>
            <div className={"node-summary-wrapper node-" + itemType + ( contextPopupVisible ? " context-visible" : "" ) + (isActive ? " active" : "")}
                style={
                    node.item && node.item.data && node.item.data.内置 ? { opacity: 0.6 } : {}
                }
                onContextMenu={e => {
                    e.preventDefault();
                    setContextPosition({
                        clientX: e.clientX,
                        clientY: e.clientY,
                        viewHeight: window.innerHeight,
                        viewWidth: window.innerWidth,
                    })
                }}
            >
                <ContextPopup contextPosition={contextPosition}
                    onVisible={visible => setContextPopupVisible(visible)}
                    bindCloser={closer => contextPopupCloserRef.current = closer}
                >
                    { renderContextMenu(item, contextPopupCloserRef.current ) }
                </ContextPopup>
                <div className={"node-summary"} ref={drag} onDoubleClick={_ => {
                    onDoubleClick(item)
                }} style={{
                    paddingLeft: 16 * depth,
                    opacity: isDragging ? 0.4 : 1
                }} onClick={() => {
                    // Toggle collapse state
                    if (childNodes.length > 0) {
                        setCollapsed(prev => !prev);
                    }
                }}>

                    <div className="summary-line">
                        <div className="icon-group">
                            <div className="op-icon">{renderOpIcon()} </div>
                            {icon}
                        </div>
                        <ClampedText text={item.getDisplayName()} style={{
                            ...(item.strong() ? { fontWeight: "bold", color: "#34495E"  } : {})
                        }} />
                    </div>

                </div>
            </div>
            <div className={"node-chidren"} style={{
                display: collapsed ? "none" : "block"
            }}>
                {childNodes.map(c => {
                    return <SelectorNode key={c.item.itemKey()} {...{
                        node: c,
                        depth: depth + 1,
                        onDoubleClick,
                        renderContextMenu,
                        currentItem,

                        onExpandTo
                    }}/>
                })}
            </div>
        </div>
    )

}
