
import React, { useEffect, useState } from 'react';

import { useDrop } from 'react-dnd'

import EditorContainer from './EditorContainer';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'
import lang_entry from 'bwax/ml/lang/lang_entry.bs'

import page_entry from 'bwax-ui/ml/page_entry.bs'

import loadSandboxDefinitions from 'bwax-ui/legacy/store/loaders/loadSandboxDefinitions'

import { untag, unpack } from 'bwax/lang/LangHelper'

import './StudioEditorArea.less';

import { evaluateWithQuery } from 'bwax'

import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';

import { getGlobalEventBus } from './EventBus';

import EditorTabs from './EditorTabs';


export default function StudioEditorArea(props) {

    const { 
        dlc, facade, 
        currentApplication,

        domainSettings,

        // related to items
        currentItem, openedItems, openItem, setCurrentItem, closeItem, isActive, 
        
        dirtyMarks, setDirtyMarks,
        bindSaver, saverRefs,

    } = props;

    const [errors, setErrors] = useState({}) //

    const [baseTypingEnv, setBaseTypingEnv] = useState();
    const [pageTypingEnv, setPageTypingEnv] = useState();

    // tenant / platform 
    // { entityTypingEnv, pageComponentTypingEnv, adminPageTypingEnv, entityAndDataTypeDicts, sandboxDefinition }
    const [platformEnvs, setPlatformEnvs] = useState();
    const [tenantEnvs, setTenantEnvs] = useState();

    // const [entityTypingEnv, setEntityTypingEnv] = useState();
    
    // const [pageComponentTypingEnv, setPageComponentTypingEnv] = useState();
    // const [adminPageTypingEnv, setAdminPageTypingEnv] = useState();

    // const [entityAndDataTypeDicts, setEntityAndDataTypeDicts] = useState([0, 0]);  // ocaml: []

    // const [sandboxDefinition, setSandboxDefinition] = useState();

    // page fragment is supposed to be shared:
    const [pageFragmentTypingEnv, setPageFragmentTypingEnv] = useState();

    function mergeTypingEnvs(envs) {

        return envs.reduce((acc, current) => {
            return [
                lang_entry_slim.merge_env(acc[0], current[0]),
                lang_entry_slim.merge_dts(acc[1], current[1]),
            ]
        }, [null, /* ocaml: Dict.String.empty () */, 0 /* ocaml: [] */])
    }

    const loadFragmentTypingEnv = async () => {
        // 1. 先找到所有的 Fragments，这里使用 design 层面的 entities 和 data types
        async function getFragment() {
            // 
            const fragments = await findFragments({
                allDataTypes: facade.dataTypes,
                allEntities: facade.entities,
                applicationId: currentApplication.id,
                ...dlc
            });
            // 1. 首先，根据 fragments 的相互依赖关系，创建一个 graph，相互关系作为 edge， A 被 B 使用则 A -> B
            //    name, fragment data, names of fragments used by it
            const fragmentSettings = fragments.filter(f => f.code).map(f => {
                const name = f.name;
                const fragmentData = f;
                const { externalNames } = f.code.previewData || {}
                const fragmentsUsed = fragment_helper.get_fragment_names_as_js(externalNames || []);

                return [name, fragmentData, fragmentsUsed]
            })
            const fragmentGraph = fragment_helper.build_fragment_graph(fragmentSettings);

            // 2. 进行拓扑排序 （linearization）
            const sorted = fragment_helper.top_sort_as_js(fragmentGraph);
            return sorted
        }

        const fragments = await getFragment();

        // 拆分 fragments 的关键数据
        const tuples = fragments.map(f => {
            if (f.code && f.code.compiled && f.code.previewData) {
                // 
                const { previewData, compiled } = f.code;
                const unpacked = unpack(compiled);
                const [n, defs, dts] = unpacked;

                // return dts, tyenv, module
                // parse tyenv if it is a string                
                const taggedTyenv = typeof (previewData.tyenv) == "string" ?
                    JSON.parse(previewData.tyenv) : previewData.tyenv;

                return [
                    [f.name, dts],
                    untag(taggedTyenv),
                ];

            } else {
                return null
            }
        }).filter(x => !!x);

        const additional_dtss = tuples.map(t => t[0]).filter(x => !!x);
        const additional_tyenvs = tuples.map(t => t[1]).filter(x => !!x);

        // add additional tyenvs
        const combined_tenv = lang_entry_slim.merge_envs(null, lang_entry_slim.array_to_list(additional_tyenvs));
        const combined_dts = lang_entry_slim.merge_dts_list(0, lang_entry_slim.array_to_list(additional_dtss.map(([n, dts]) => dts)));

        return [combined_tenv, combined_dts];

    };


    const loadTypingEnv = async () => {

        // 如果 sandbox definitions 有更新的话

        // prepare base typing env
        const baseTypingEnv = lang_entry.prepare_base_typing_env();
        const pageTypingEnv = page_entry.prepare_ui_typing_env(baseTypingEnv);

        ///
        async function loadEnvs (multitenantType) {
            const loadedDefinitions = await loadSandboxDefinitions(currentApplication.code, multitenantType)(dlc)
            const { allEntities, allDataTypes, adminPages, pageComponents } = loadedDefinitions;
            const [entity_dict, data_type_dict] = lang_entry_slim.build_definition(allEntities, allDataTypes);
            const entityTypingEnv = lang_entry.prepare_typing_env_for_entities(entity_dict, data_type_dict);
            const pageComponentTypingEnv = page_entry.prepare_page_component_typing_env(pageTypingEnv, pageComponents);
            const adminPageTypingEnv = page_entry.prepare_custom_admin_page_typing_env(adminPages);

            return {
                entityTypingEnv, pageComponentTypingEnv, adminPageTypingEnv, 
                entityAndDataTypeDicts: [entity_dict, data_type_dict],
                sandboxDefinition: loadedDefinitions
            }
        }

        const pageFragmentTypingEnv = await loadFragmentTypingEnv();

        setPageFragmentTypingEnv(pageFragmentTypingEnv);

        setBaseTypingEnv(baseTypingEnv);
        setPageTypingEnv(pageTypingEnv);

        const platformEnvs = await loadEnvs("platform");
        setPlatformEnvs(platformEnvs)
        if(domainSettings.multitenancy) {
            const tenantEnvs = await loadEnvs("tenant");
            setTenantEnvs(tenantEnvs);
        }

    }

    // 如果有 entity 变化，要重新 load
    useEffect(() => {
        loadTypingEnv();
    }, [currentApplication.code]);


    const typingEnvRelatedFields = {
        "entity": ["名称"],
        "field": ["名称", "字段类型", "字段选项", "唯一", "必填", "多值", "可初始化", "可更改", "数据实体"],
        "virtual-field": ["名称", "字段类型", "字段选项", "必填", "多值", "缓存", "数据实体", "代码"],
        "backlink-field": ["名称", "关联实体名", "多值", "数据实体"],

        "data-interface": ["名称", "类型", "代码"],

        "page-component": ["名称", "代码"],
        "page-fragment": ["名称", "代码"],

        "admin-page": ["名称", "代码"],
    }

    function itemChangeAffectTypingEnv(itemType, itemData) {

        const relatedFields = typingEnvRelatedFields[itemType];
        if (relatedFields) {
            return relatedFields.some(fieldName => itemData[fieldName] !== undefined);
        }
        return false

    }

    useEffect(() => {

        const unsubscribe = getGlobalEventBus().on("updateItemData", ({ itemType, itemData }) => {

            if (itemChangeAffectTypingEnv(itemType, itemData)) {
                setTimeout(() => {
                    loadTypingEnv();
                }, 50)
            }

        });

        const unsubscribeNewItem = getGlobalEventBus().on("newItem", ({ itemType }) => {
            if (typingEnvRelatedFields[itemType] !== undefined) {
                setTimeout(() => {
                    loadTypingEnv();
                }, 10)
            }
        })

        return () => {
            unsubscribe();
            unsubscribeNewItem();
        }

    }, [])


    const getTypingEnv = item => {        
        const { multitenantType, itemType } = item;
        
        if(multitenantType == "tenant" && !tenantEnvs) {
            return undefined;
        }
        if(!platformEnvs) {
            return undefined;
        }

        const { entityTypingEnv, pageComponentTypingEnv, adminPageTypingEnv } = (() => {
            return multitenantType == "tenant" ? tenantEnvs : platformEnvs
        })();
            
        if (itemType === "page" || itemType === "page-component") {
            // TODO page component 之间要考虑依赖关系：
            return mergeTypingEnvs([pageTypingEnv, entityTypingEnv, pageFragmentTypingEnv, pageComponentTypingEnv]);
        } else if (itemType === "page-fragment") {
            return mergeTypingEnvs([pageTypingEnv, entityTypingEnv]);
        } else if (itemType === "admin-page" || itemType === "entity-list-page" || itemType === "entity-detail-page" || itemType == "general-setting") {
            // TODO admin page 之间也要考虑依赖关系
            return mergeTypingEnvs([pageTypingEnv, entityTypingEnv, adminPageTypingEnv]);
        } else {
            // TODO backend 的 data-interfaces / virtual fields 之间也要考虑依赖关系
            return mergeTypingEnvs([baseTypingEnv, entityTypingEnv]);
        }
    };

    function renderEditor(item) {

        const itemKey = item.itemKey();
        const typingEnv = getTypingEnv(item);

        const { multitenantType } = item;

        const isEditorActive = isActive(item);

        function getEnvs () {
            return multitenantType == "tenant" ? (tenantEnvs || {}) : (platformEnvs || {})
        }

        return (
            <div className={"editor-wrapper" + (isEditorActive ? "" : " inactive")} key={itemKey}>
                <EditorContainer {...{
                    isEditorActive,

                    domainSettings,
                    
                    item, facade, dlc,
                    typingEnv: typingEnv ? [
                        ...typingEnv,
                        ...(getEnvs ()).entityAndDataTypeDicts,
                    ] : undefined,

                    closeSelf: _ => {
                        closeItem(item)
                    },

                    bindSaver: saver => {
                        bindSaver(itemKey, saver)
                    },

                    isDirty: dirtyMarks[itemKey],
                    hasError: !!errors[itemKey],

                    sandboxDefinition: getEnvs().sandboxDefinition,

                    markDirty: dirty => {
                        setDirtyMarks(prev => ({
                            ...prev,
                            [itemKey]: dirty
                        }))
                    },

                    markError: error => {
                        setErrors(prev => ({
                            ...prev,
                            [itemKey]: error
                        }))
                    }

                    // callback                            
                }} />
            </div>
        )
    }

    if (currentItem) {
        return (
            <>
                <EditorTabs {...{
                    currentItem, openedItems, openItem, setCurrentItem, closeItem, dirtyMarks, errors,
                    saveItem: async item => {
                        const itemKey = item.itemKey();
                        const saveIt = saverRefs.current[itemKey];
                        if (saveIt) {
                            await saveIt();
                        }
                    }
                }} />
                <div className="studio-editor-area">
                    {openedItems.map(renderEditor)}
                </div>
            </>
        )
    } else {
        return (
            <EmptyCanvas {...{ openItem }} />
        )
    }

}

function EmptyCanvas(props) {

    const { openItem } = props;

    const [collectedProps, drop] = useDrop(() => ({
        accept: [
            "entity",
            "field",
            "virtual-field",
            "backlink-field",
            "data-interface",
            "admin-page",
            "page",
            "page-component",
            "page-fragment",

            "page-redirect",
            "event-handler",
            "scheduled-event-handler",


            "imported-entity"
        ],
        drop: item => {
            openItem(item);
        },
        // canDrop: () => {

        // }
    }))

    return (
        <div className="empty-canvas" ref={drop}>
            在左边资源选择器，选择你要编辑的，或者把它拖进来
        </div>
    )
}



// 
// find 
async function findFragments({
    allEntities, allDataTypes, sessionToken, sandbox,
    applicationId
}) {

    const evaluate = (def, baseData) => {
        return evaluateWithQuery(
            def,
            baseData,
            {
                allDataTypes,
                allEntities,
                queryTarget: "definition",
                sessionToken,
                sandbox
            }
        )
    }

    const def = {
        __query__: {
            data: {
                type: "listAll",
                entityName: "页面片段",
                criteria: {
                    "应用": applicationId
                }
            },
            __for__: {
                __apply__: {
                    list: `\${data}`,
                    map: {
                        name: `\${value.名称}`,
                        code: `\${value.代码}`,
                    }
                }
            }
        }
    }
    return evaluate(def, {})
}

