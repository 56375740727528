// This is not a widget

import React, { useState, useEffect } from 'react'

import { Icon } from '@ant-design/compatible';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'
import buildFieldInput from 'Client/js/builders/buildFieldInput'
import ValidationSourcing from 'Client/js/ui/widgets/ValidationSourcing'

export default function SimpleFieldEdit(props) {
    const {
        data,
        record,
        field,
        initialValue,
        closeEdit,

        actions,
        reload,
        contextEntityName,
        dataEntity,
        ...env
    } = props

    const [id, _values, _ops] = record
    const { name, config = {}, options } = field
    const { allDataTypes, allEntities, widgetFactory } = env

    const allKeys = [name]

    //state
    const [validationValues, setValidationValues] = useState({})
    const {
        errors,
        validated,
        editing,
        dirtyValues,
        updateEditing,
        markSaved,
        reset
    } = EditingState.useEditingStateAsJs(allKeys, { [name]: initialValue }, {}, {}, [], "no-key", false)
    
    function prepareValidation() {
        if (options && options.validation) {
            // 还有 list, dict, ...
            const { ref, list } = options.validation
            function getLimitedValues() {
                if(list) {
                    return list
                } else {
                    return validationValues[name]
                }
            }
            return {
                limitedValues: getLimitedValues()
            }

        } else {
            return {}
        }
    }

    const inputParams = {
        ...(config && config.inputParams ? config.inputParams : {}),
        ...(prepareValidation())
    }

    const inputWidget = buildFieldInput(
        field,
        name,
        {
            initialValue,
            allDataTypes,
            allEntities,
            dataEntity,
            params: inputParams
        }
    )
    const { allWidgets } = widgetFactory;
    const W = allWidgets[inputWidget.type_ || inputWidget.type]

    if (!W) {
        return (inputWidget.type_ || inputWidget.type)
    }

    /// add value, onChange
    const value = editing[name] // fixedValues[name] || editing[name];

    const onChange = v => {
        if (v != value) {
            updateEditing({ [name]: v })
        }
    }

    const isDirty = Object.keys(dirtyValues).filter(
        k => dirtyValues[k] !== undefined
    ).length > 0

    async function confirmEdit() {

        await actions.updateRecord({
            entityName: dataEntity.name,
            id,
            formData: { [name]: dirtyValues[name] }
        })

        await actions.adminMessage({
            level: 'success',
            text: '修改成功',
        })
        markSaved()
        reload()
        closeEdit && closeEdit()
    }

    const allValidations = [field].filter(f => f.options && f.options.validation).map(f => {
        return [f.options.validation, f]
    })

    let validationSourcing = (
        <ValidationSourcing
            params={{
                validations: allValidations,
                entityName: dataEntity.name,
                recordId: id,
                updateValidationValues: values => {
                    setValidationValues(prev => ({
                        ...prev,
                        ...values
                    }))
                },
                formValues: {}
            }}
            {...env}
        />
    )

    return (
        <div className="small-field-edit-box">
            <div className="edit-box-body">
                <W
                    value={value}
                    onChange={onChange}
                    params={{
                        ...(inputWidget.params ? inputWidget.params : inputWidget),
                    }}
                    {...env}
                />
            </div>
            <div className="edit-box-actions">
                <Icon
                    type="check"
                    className={`confirm-button ${isDirty ? "" : "disabled"}`}
                    onClick={confirmEdit}
                />
            </div>
            {validationSourcing}
        </div>
    )

}
