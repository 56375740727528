// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Select$BwaxAdmin = require("../../../../re/Select.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function PaginationSelect(Props) {
  var options = Props.options;
  var selected = Props.selected;
  var onChange = Props.onChange;
  var control = Props.control;
  var theme = Props.theme;
  var rules_003 = /* optionListRules : :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `maxHeight */Caml_chrome_debugger.polyVar("maxHeight", [
          836180395,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              20.0
            ])
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* scrollY */819475372,
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `maxWidth */Caml_chrome_debugger.polyVar("maxWidth", [
                  -192935742,
                  /* `rem */Caml_chrome_debugger.polyVar("rem", [
                      5691738,
                      8.0
                    ])
                ]),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `borderRoundEach */Caml_chrome_debugger.polyVar("borderRoundEach", [
                      -979470621,
                      /* record */Caml_chrome_debugger.record([
                          "tl",
                          "tr",
                          "br",
                          "bl"
                        ], [
                          -789508312,
                          -789508312,
                          Caml_chrome_debugger.polyVar("rem", [
                              5691738,
                              0.25
                            ]),
                          Caml_chrome_debugger.polyVar("rem", [
                              5691738,
                              0.25
                            ])
                        ])
                    ]),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* `boxShadow */Caml_chrome_debugger.polyVar("boxShadow", [
                          -586247093,
                          /* record */Caml_chrome_debugger.record([
                              "offset",
                              "size",
                              "blur",
                              "color"
                            ], [
                              [
                                0.0,
                                5.0
                              ],
                              0.0,
                              10.0,
                              Caml_chrome_debugger.polyVar("rgba", [
                                  -878128972,
                                  /* tuple */[
                                    0,
                                    0,
                                    0,
                                    0.1
                                  ]
                                ])
                            ])
                        ]),
                      /* [] */0
                    ])
                ])
            ])
        ])
    ]);
  var rules_004 = function (label, value, isSelected, isDisabled) {
    return /* [] */0;
  };
  var rules_005 = function (label, value) {
    return /* [] */0;
  };
  var rules_007 = function (label, value, isSelected) {
    return /* [] */0;
  };
  var rules = /* record */Caml_chrome_debugger.record([
      "containerRules",
      "controlRules",
      "menuRules",
      "optionListRules",
      "optionRules",
      "singleValueRules",
      "controlFocusedRules",
      "optionFocusedRules"
    ], [
      0,
      0,
      0,
      rules_003,
      rules_004,
      rules_005,
      0,
      rules_007
    ]);
  return React.createElement(Select$BwaxAdmin.make, {
              rules: rules,
              options: options,
              selected: selected,
              onChange: onChange,
              disabled: false,
              placeholder: "请选择",
              control: control,
              theme: theme,
              pc: /* row */5693978,
              prs: /* [] */0
            });
}

var make = PaginationSelect;

exports.make = make;
/* react Not a pure module */
