

import React, { useEffect, useState } from 'react'

import { Icon } from '@ant-design/compatible';

import './WxMp_Materials.less'
import SelectNewsMaterialModal from './SelectNewsMaterialModal'

export default function WxMp_News (props){

    const { mpNews, newsInput, deleteReplies, queryRunner } = props

    const [ modalVisible, setModalVisible ] = useState(false)
    const [ defaultNews, setDefaultNews ] = useState(null)

    useEffect(() => {
        (async () => {
            if(mpNews) {
                const condition = [[{
                    "field": "素材ID",
                    "op": "eq",
                    "value": mpNews
                }]]
                const initNews = await loadInitNews(queryRunner, condition)
                const initNewsEdges = initNews ? initNews.edges : []
                if(initNewsEdges.length > 0) {
                    setDefaultNews(initNewsEdges[0].node)
                }
            }
        })()
    }, [ mpNews ])

    function showModal () {
        setModalVisible(true)
    }

    function hideModal(){
        setModalVisible(false)
    }

    function deleteNews () {
        newsInput(null)
    }

    const imageUrl = defaultNews && defaultNews.thumbUrl ? defaultNews.thumbUrl : ''

    return (
        <div className="wxmp-materials">
            <div className="materials-box">
                <label>图文消息</label>
                {
                    mpNews ? (
                        <div className="wxmp-news">
                            <div>
                                <div style={{ 
                                    backgroundImage: `url(${imageUrl ? imageUrl.replace('http://mmbiz.qpic.cn', '') : undefined })`
                                }}/>
                                <p>{defaultNews && defaultNews.title}</p>
                            </div>
                            <div className="delete-news" onClick={() => deleteNews()}>
                                删除
                            </div>
                        </div>
                    ) : (
                        <div className="wxmp-select" onClick={() => showModal()}>
                            <Icon type="folder"/>
                            从素材库中选择
                        </div>
                    )
                }
                <div className="delete" onClick={() => deleteReplies()}>
                    <Icon type="close" />
                </div>
            </div>
            <SelectNewsMaterialModal
                visible={modalVisible}
                hideModal={() => hideModal()}
                queryRunner={queryRunner}
                newsSelect={news => newsInput(news)}
            />
        </div>
    )
}

const QueryWxMpMaterials = `
    query (
        $condition: [[ListWxMpEntityNewsMaterialConditionInput]]
    ) {
        listWxMpEntityNewsMaterial(condition: $condition) {
            edges {
                node {
                    id
                    mediaId
                    title
                    digest
                    contentSourceUrl
                    thumbUrl
                    updatedAt
                }
            }
        }
    }
`

async function loadInitNews(queryRunner, condition) {
    const resp = await queryRunner(QueryWxMpMaterials)({
        condition
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityNewsMaterial
    }
}