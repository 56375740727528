
import React, { useState, useContext } from 'react';

import { Button } from 'antd';

import Popconfirm from 'bwax-ui/components/Popconfirm';
import './ExcelExport.less'


import { make as Display_ExportJob } from 'Client/re/widgets/display/Display_ExportJob.bs';

import AddRecord from 'bwax-ui/legacy/page/actions/AddRecord';

import useQueriedValueWithoutState from 'bwax-ui/legacy/page/hooks/useQueriedValueWithoutState'
import QueryTargetContext from 'bwax-ui/legacy/store/QueryTargetContext';
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a, runDefinitionQuery_a } from 'bwax/query/runClientQuery';

export default function ExcelExport(props) {

    // 从 QC_ExcelExport 重构：
    const { params, allEntities, allDataTypes } = props;

    const {
        entityName,
        scene,
        desc,
        conditions,
        sorting,
        fieldNames, // 
    } = params;
    
    const queryTarget = useContext(QueryTargetContext) || "data";

    // 1. build the query def;
    const dataDef = getDataDef({ scene });


    // 2. execute the query
    // state begin
    const {
        value: data,
        reload,
    } = useQueriedValueWithoutState(dataDef, {
        allDataTypes,
        allEntities,
        baseData: {},
        roots: {
            "当前用户": {
                entityName: "用户" 
            }
        },
        queryTarget,
    })

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext);

    const queryRunner = ({
        'data': runDataQuery_a({sessionToken, sandbox, tenantCode}),
        'definition': runDefinitionQuery_a({sessionToken, tenantCode})
    }[queryTarget || 'data'])

    return (
        <div className="admin--excel-export">
            <div className="top-toolbar">
                <Button type="default" onClick={() => {
                    reload();
                }}>
                    刷新进度
                </Button>
                <Popconfirm title="如果数据量很大，建议避开业务高峰期。现在导出？"
                    onConfirm={async _ => {
                        await AddRecord({allEntities, allDataTypes, queryRunner})({
                            entityName: "导出实体数据任务",
                            formData: {
                                场景: scene,
                                描述: desc,
                                实体名: entityName,
                                条件: conditions,
                                排序: sorting,
                                字段: fieldNames
                            }
                        })
                        reload();
                        
                    }}
                    okText="是"
                    cancelText="否"
                    positions={["bottom"]}
                    align={"end"}
                >
                    <Button type="primary">
                        导出数据
                    </Button>
                </Popconfirm>

            </div>
            <div className="item-list">
                {
                    (data || []).map(d => {
                        return (
                            <Display_ExportJob
                                key={d.createdAt}
                                params={{
                                    ...d,
                                    refresh: () => {
                                        reload();
                                    }
                                }}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

function getDataDef({ scene }) {
    return {
        __query__: {
            data: {
                entityName: "导出实体数据任务",
                type: "listAll",
                criteria: {
                    场景: scene,
                },
                sortBy: {
                    创建时间: "DESC"
                },
                pagination: {
                    pageSize: 10
                }
            },
            __for__: {
                __apply__: {
                    list: "${data}",
                    map: {
                        file: "${value.数据文件}",
                        progress: "${value.进度}",
                        createdAt: "${value.创建时间}",
                        errMsg: "${value.失败原因}",
                        count: "${value.导出数据条数}",
                        status: "${value.状态}",
                        desc: "${value.描述}",
                    }
                }
            }
        }
    }
}

