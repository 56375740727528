import React, { useEffect, useContext, useState, useRef } from 'react';

import CompositeEditor from './editors/CompositeEditor';

import getFacadeForPreview from 'Client/js/designApp/preview/getFacadeForPreview';

import Loading from 'Client/js/components/Loading';

import Message from 'Client/js/ui/Message';

import getItemHandler from './getItemHandler';

import admin_widget_helper from 'Client/ml/ui/helpers/admin_widget_helper.bs';

import { getGlobalEventBus } from './EventBus';

import ApplicationContext from 'bwax/util/ApplicationContext';

import { isEqual } from 'lodash';

export default function EditorContainer(props) {

    const {
        isEditorActive,
        item, facade, typingEnv, dlc, sandboxDefinition,
        isDirty, hasError,
        markError, markDirty,
        bindSaver,

        closeSelf,

        domainSettings
    } = props

    const { itemType } = item;

    const { loadValue, updateValue, entity, fieldNamesToRead, fieldNamesToWrite } = getItemHandler(item.itemType, facade, domainSettings);

    const [editingValue, setEditingValue] = useState();

    // 用于延迟合并一些衍生出的值 https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1023
    // 只有在 save 时，才会合并
    const [derivedValue, setDerivedValue] = useState();
   
    const [savedValue, setSavedValue] = useState();

    const [previewFacade, setPreviewFacade] = useState();

    const { currentApplication } = useContext(ApplicationContext);

    // const [ instanceID, setInstanceID ] = useState(_ => {
    //     console.log("Make intance ID");
    //     return guid();
    // });


    useEffect(() => {
        if (sandboxDefinition) {
            // const remove the sessionToken 
            // TODO 
            // The session token from frontend preview is handled at the preview page
            // But how to get the session for those backend execution?

            const { sessionToken, ...givenDLC } = dlc;

            const previewDLC = {
                ...givenDLC,
                sandbox: true,
            };

            (async () => {
                const previewFacade = await getFacadeForPreview({
                    runtimeProfile: { name: itemType },
                    dlc: previewDLC,
                    entities: sandboxDefinition.allEntities,
                    dataTypes: sandboxDefinition.allDataTypes,

                    applicationCode: currentApplication.code,
                });
                setPreviewFacade(previewFacade);
            })();
        }

    }, [sandboxDefinition, item.itemType])

    // process value
    const processRecordValue = result => {
        const allFields = [...entity.fields, ...entity.virtualFields];

        // changed the normalized field name back the original field name
        return Object.keys(result).reduce((acc, fname) => {
            const field = allFields.find(f => admin_widget_helper.normalize_field_name(f.name) === fname);
            if (field) {
                return { ...acc, [field.name]: result[fname] }
            } else {
                // id 
                return { ...acc, [fname]: result[fname] }
            }
        }, {});
    }


    const saveValue = () => {
        if (hasError) {
            Message.warning("当前内容有错误，不能保存");
        } else if (isDirty) {

            (async () => {

                // 合并 derived value
                // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1023
                const mergedValue = derivedValue ? {
                    ...editingValue, ...derivedValue
                } : editingValue;

                const formToSave = Object.keys(mergedValue).reduce((acc, key) => {
                    if (isEqual(mergedValue[key], savedValue[key])) {
                        return acc
                    } else {
                        return {
                            ...acc,
                            [key]: mergedValue[key]
                        }
                    }
                }, {});

                const [result, error] = await updateValue(item.data.id, formToSave);

                if (!error) {
                    Message.success("保存成功");
                    markDirty(false);

                    const record = processRecordValue(result);

                    const changedValue = Object.keys(record).reduce((acc, key) => {
                        if (isEqual(record[key], savedValue[key])) {
                            return acc
                        } else {
                            return {
                                ...acc,
                                [key]: record[key]
                            }
                        }
                    }, { id: record.id })

                    getGlobalEventBus().dispatch("updateItemData", { itemType, itemData: changedValue });

                    setSavedValue(mergedValue);
                    if(editingValue !== mergedValue) {
                        setEditingValue(mergedValue)
                    }                   
                    //

                } else {
                    Message.error(error);
                }

            })();

        }
    };
    if (bindSaver) {
        bindSaver(saveValue);
    }


    useEffect(() => {

        (async () => {
            setPreviewFacade(previewFacade);

            if (loadValue) {
                const [result, error] = await loadValue(item.data.id);
                if (error) {
                    console.error(error)
                } else {
                    if (result === null) {
                        // report 
                        closeSelf();
                    } else {

                        // changed the normalized field name back the original field name
                        const initialValue = processRecordValue(result);

                        setEditingValue(initialValue);
                        setSavedValue(initialValue);
                    }
                }
            } else {
                console.log(">>> NO LOAD VALUE FUNCTION FOR ITEM:", item);
            }
        })();

    }, [item.data.id])

    const isDirtyRef = useRef(isDirty);
    isDirtyRef.current = isDirty;
    const onChange = value => {
        
        function haveSameKeys(a, b) {
            return Object.keys(a).every(k => {
                return Object.keys(b).indexOf(k) != -1 || a[k] === undefined
            })
        }

        function isValueEqual(a, b) {

            if(a && b && typeof(a) === 'object' && typeof(b) === 'object') {


                // 测试 dirty value 时， code 里面的 previewData 只考虑 testInputParams，因为其他东西都是衍生出来的
                if((a.testInputParams || b.testInputParams) || (a.ioTypeMetas || b.ioTypeMetas)) {
                    const r =  isEqual(a && a.testInputParams, b && b.testInputParams);
                    if(!r) {
                        // console.log(">>>> not equal >>>> test input params", a, b);
                    }
                    return r
                } else if(!(haveSameKeys(a, b) && haveSameKeys(b, a))) {
                    // make share all keys are in both 
                    // console.log(">> key not equal", a, b);
                    return false
                } else {                    
                    return Object.keys(a).every(k => {
                        const r = isValueEqual(a[k], b[k])
                        // if(!r) {
                        //     console.log(">>>> not equal >>>>", k, a[k], b[k]);

                        //     if(k == "compiled") {
                        //         // unpack 出来看看差别：
                                
                        //         const x = a[k];
                        //         const y = b[k];

                        //         const unpackedX = unpack(x);
                        //         const unpackedY = unpack(y);

                        //         unpackedX.forEach((v, i) => {     
                                                                        
                        //             const u = unpackedY[i];
                        //             if(!isEqual(v, u)) {
                        //                 console.log("Not equal", i, v, u);
                        //             }

                        //             if(i == 2 && !isEqual(v, u)) {
                        //                 // DTS

                        //                 console.log(">>> v");
                        //                 console.log(lang_entry.print_dts(v));

                        //                 console.log(">>> u");
                        //                 console.log(lang_entry.print_dts(u));
                        //             }

                        //         })
                        //     }

                        // }
                        return r
                    })
                }     

            } else {
                return isEqual(a, b);
            }

        }

        if (isValueEqual(value, savedValue)) {
            if (isDirtyRef.current) {
                markDirty(false)
            }
        } else {
            if (!isDirtyRef.current) {
                markDirty(true)
            }
        }
        setEditingValue(value);
    };



    return editingValue === undefined ? <Loading /> : <CompositeEditor {...{
        isEditorActive,
        item, facade,
        typingEnv,
        value: editingValue,
        previewFacade,

        entity, fieldNamesToRead, fieldNamesToWrite,

        onChange, 
        onDerivedValueChange: setDerivedValue,


        saveValue,
        markError,
        hasError,
    }} />
}