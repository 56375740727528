
import React from 'react';

const QueryContainer = require("bwax-ui/re/legacy/QueryContainer.bs").make;

export default function EntityDataTabs (props) {

    const { id, currentUser, tabs, entity, location } = props;

    const deletionAlwaysAllowed = currentUser && currentUser.systemRoles.indexOf("Admin") != -1
        && location.search.indexOf("__let_me_delete_it__") != -1;

    const baseData = {
        id,
        "当前用户": currentUser
    }

    const tabDefs = Object.keys(tabs).map((name, idx) => {

        const tab = tabs[name];

        function tabConfig() {
            if(deletionAlwaysAllowed) {
                return {
                    ...tab, 
                    options: {
                        ...(tab.options || {}),
                        deletionAllowed: true
                    }
                }
            } else {
                return tab
            }
        }

        return {
            name, 
            default: idx == 0,
            content: {
                // type_: "admin::AdminDisplayPage",
                type_: "admin::QC_AdminDisplayPage",
                params: {
                    config: tabConfig(),
                    baseData,
                    maybeId: id,
                    entityName: entity.name,
                    name
                }
            }
        }
    });

    const def = {
        view: {
            "type": "admin::templates::PageContent",
            "noBackground": true,
            "noPadding": true,
            "content": {
              "type": "admin::templates::Tabs",
              "params": {
                "tabs": tabDefs,
              },
            },            
        }
    }

    return (
        <QueryContainer
            {...props}
            def={def}
            baseData={baseData}
        />
    )

}