// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var PlateImpl = require("./PlateImpl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_string = require("bs-platform/lib/js/caml_string.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var Unexpected = Caml_exceptions.create("Plate-BwaxAdmin.Unexpected");

function identity(x) {
  return x;
}

function eq(param) {
  return Caml_obj.caml_equal(param[0], param[1]);
}

function fst(param) {
  return param[0];
}

function snd(param) {
  return param[1];
}

function $at$plus(f, g, x) {
  return Curry._1(f, Curry._1(g, x));
}

function $at$great(f, g, x) {
  return Curry._1(g, Curry._1(f, x));
}

function $less$pipe(f, a) {
  return Curry._1(f, a);
}

function cons(a, l) {
  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
            a,
            l
          ]);
}

function mod_by(m, n) {
  var pm = m < 0 ? -m | 0 : m;
  if (n >= 0) {
    return Caml_int32.mod_(n, pm);
  } else {
    var r = Caml_int32.mod_(-n | 0, pm);
    if (r === 0) {
      return 0;
    } else {
      return pm - r | 0;
    }
  }
}

function map(f, o) {
  return Belt_Option.map(o, f);
}

function with_default(v, o) {
  return Belt_Option.getWithDefault(o, v);
}

function and_then(f, o) {
  return Belt_Option.flatMap(o, f);
}

function otherwise(f, o) {
  if (o !== undefined) {
    return o;
  } else {
    return Curry._1(f, /* () */0);
  }
}

function foldl(f, initial, o) {
  var o$1 = Belt_Option.map(o, (function (v) {
          return Curry._2(f, initial, v);
        }));
  return Belt_Option.getWithDefault(o$1, initial);
}

var $$Option = {
  map: map,
  with_default: with_default,
  and_then: and_then,
  otherwise: otherwise,
  is_some: Belt_Option.isSome,
  is_none: Belt_Option.isNone,
  force: Belt_Option.getExn,
  foldl: foldl
};

var length = Belt_List.length;

function is_empty(l) {
  return Belt_List.length(l) === 0;
}

var append = Belt_List.concat;

var rev = Belt_List.reverse;

function sum(l) {
  return Belt_List.reduce(l, 0, (function (prim, prim$1) {
                return prim + prim$1 | 0;
              }));
}

function product(l) {
  return Belt_List.reduce(l, 1, Caml_int32.imul);
}

function map$1(f, l) {
  return Belt_List.map(l, f);
}

function for_each(f, l) {
  return Belt_List.forEach(l, f);
}

function indexed_map(f, l) {
  return Belt_List.mapWithIndex(l, f);
}

function keep_map(f, l) {
  return Belt_List.keepMap(l, f);
}

function keep(f, l) {
  return Belt_List.keep(l, f);
}

var flat = Belt_List.flatten;

function flat_map(f, l) {
  return Belt_List.flatten(Belt_List.map(l, f));
}

function clean(l) {
  return Belt_List.keepMap(l, (function (x) {
                return x;
              }));
}

function foldr(f, l, init) {
  return Belt_List.reduceReverse(l, init, (function (acc, e) {
                return Curry._2(f, e, acc);
              }));
}

function foldl$1(f, acc, l) {
  return Belt_List.reduce(l, acc, f);
}

function reduce(f, init_head, l) {
  if (l) {
    var acc = Curry._1(init_head, l[0]);
    return Caml_option.some(Belt_List.reduce(l[1], acc, f));
  }
  
}

function filter(test) {
  var partial_arg = function (param) {
    return List.partition(test, param);
  };
  return (function (param) {
      return fst(Curry._1(partial_arg, param));
    });
}

function find(test, l) {
  try {
    return Caml_option.some(List.find(test, l));
  }
  catch (exn){
    return ;
  }
}

function index_of(test, l) {
  var _index = 0;
  var _remaining = l;
  while(true) {
    var remaining = _remaining;
    var index = _index;
    if (remaining) {
      if (Curry._1(test, remaining[0])) {
        return index;
      } else {
        _remaining = remaining[1];
        _index = index + 1 | 0;
        continue ;
      }
    } else {
      return -1;
    }
  };
}

function hit(f, l) {
  var _rest = l;
  while(true) {
    var rest = _rest;
    if (rest) {
      var head = rest[0];
      var match = Curry._1(f, head);
      if (match !== undefined) {
        return /* tuple */[
                head,
                Caml_option.valFromOption(match)
              ];
      } else {
        _rest = rest[1];
        continue ;
      }
    } else {
      return ;
    }
  };
}

function member(equiv, e, _list) {
  while(true) {
    var list = _list;
    if (list) {
      if (Curry._1(equiv, /* tuple */[
              list[0],
              e
            ])) {
        return true;
      } else {
        _list = list[1];
        continue ;
      }
    } else {
      return false;
    }
  };
}

function rem_from_list(equiv, e, list) {
  if (list) {
    var hd = list[0];
    var l$prime = rem_from_list(equiv, e, list[1]);
    if (Curry._1(equiv, /* tuple */[
            hd,
            e
          ])) {
      return l$prime;
    } else {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                hd,
                l$prime
              ]);
    }
  } else {
    return /* [] */0;
  }
}

function make_set(equiv, list) {
  if (list) {
    var hd = list[0];
    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
              hd,
              make_set(equiv, rem_from_list(equiv, hd, list[1]))
            ]);
  } else {
    return /* [] */0;
  }
}

function add_to_set(equiv, e, l) {
  if (member(equiv, e, l)) {
    return l;
  } else {
    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
              e,
              l
            ]);
  }
}

function union(equiv, param) {
  return foldr((function (param, param$1) {
                return add_to_set(equiv, param, param$1);
              }), param[0], param[1]);
}

function inter(equiv, param) {
  var l2 = param[1];
  return filter((function (x) {
                  return member(equiv, x, l2);
                }))(param[0]);
}

var head = Belt_List.head;

function assoc_intersect(l1, l2) {
  var _param = /* tuple */[
    /* tuple */[
      /* [] */0,
      /* [] */0
    ],
    /* [] */0,
    /* [] */0
  ];
  var _l1 = l1;
  var _l2 = l2;
  while(true) {
    var param = _param;
    var l2$1 = _l2;
    var l1$1 = _l1;
    var d2 = param[2];
    var d1 = param[1];
    var match = param[0];
    var c2 = match[1];
    var c1 = match[0];
    if (l1$1) {
      if (l2$1) {
        var match$1 = l1$1[0];
        var v1 = match$1[1];
        var n1 = match$1[0];
        var match$2 = List.partition((function(n1){
            return function (param) {
              return Caml_obj.caml_equal(param[0], n1);
            }
            }(n1)), l2$1);
        var hit2 = match$2[0];
        var acc;
        if (hit2) {
          var match$3 = hit2[0];
          acc = /* tuple */[
            /* tuple */[
              Pervasives.$at(c1, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        n1,
                        v1
                      ],
                      /* [] */0
                    ])),
              Pervasives.$at(c2, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        match$3[0],
                        match$3[1]
                      ],
                      /* [] */0
                    ]))
            ],
            d1,
            d2
          ];
        } else {
          acc = /* tuple */[
            /* tuple */[
              c1,
              c2
            ],
            Pervasives.$at(d1, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      n1,
                      v1
                    ],
                    /* [] */0
                  ])),
            d2
          ];
        }
        _l2 = match$2[1];
        _l1 = l1$1[1];
        _param = acc;
        continue ;
      } else {
        return /* tuple */[
                /* tuple */[
                  c1,
                  c2
                ],
                Pervasives.$at(d1, l1$1),
                d2
              ];
      }
    } else {
      return /* tuple */[
              /* tuple */[
                c1,
                c2
              ],
              d1,
              Pervasives.$at(d2, l2$1)
            ];
    }
  };
}

function substract(l1, l2) {
  return snd(List.partition((function (e) {
                    return member(eq, e, l2);
                  }), l1));
}

function unique(list) {
  return make_set(eq, list);
}

function assoc(k, l) {
  try {
    return Caml_option.some(List.assoc(k, l));
  }
  catch (exn){
    return ;
  }
}

function assoc_keys(l) {
  return List.map(fst, l);
}

function assoc_vals(l) {
  return List.map(snd, l);
}

function assoc_map(f, l) {
  return List.map((function (param) {
                return /* tuple */[
                        param[0],
                        Curry._1(f, param[1])
                      ];
              }), l);
}

function assoc_replace(n, v, l) {
  return List.map((function (param) {
                var name = param[0];
                if (name === n) {
                  return /* tuple */[
                          name,
                          v
                        ];
                } else {
                  return /* tuple */[
                          name,
                          param[1]
                        ];
                }
              }), l);
}

function assoc_merge(l1, l2) {
  return Belt_List.reduce(l1, l2, (function (acc, param) {
                var k = param[0];
                var match = assoc(k, acc);
                if (match !== undefined) {
                  return acc;
                } else {
                  return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  k,
                                  param[1]
                                ],
                                /* [] */0
                              ]));
                }
              }));
}

var combine = Belt_List.zip;

function zip(param) {
  return List.combine(param[0], param[1]);
}

function unzip(l) {
  return foldr((function (param, param$1) {
                return /* tuple */[
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            param[0],
                            param$1[0]
                          ]),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            param[1],
                            param$1[1]
                          ])
                      ];
              }), l, /* tuple */[
              /* [] */0,
              /* [] */0
            ]);
}

function map2(f, l1, l2) {
  return List.map((function (param) {
                return Curry._2(f, param[0], param[1]);
              }), Belt_List.zip(l1, l2));
}

var fill = Belt_List.make;

function take(i, l) {
  return Belt_List.take(l, i);
}

function drop(i, l) {
  return Belt_List.drop(l, i);
}

function _rangeImp(prim, prim$1, prim$2) {
  return PlateImpl.range(prim, prim$1, prim$2);
}

function range(l, h) {
  return $$Array.to_list(PlateImpl.range(l, h, undefined));
}

function range_by_step(l, h, step) {
  return $$Array.to_list(PlateImpl.range(l, h, step));
}

function _repeatAndForget(prim, prim$1) {
  PlateImpl.repeatAndForget(prim, prim$1);
  return /* () */0;
}

function repeat_and_forget(i, f) {
  PlateImpl.repeatAndForget(i, f);
  return /* () */0;
}

function split_at(i, l) {
  var match = Belt_List.splitAt(l, i);
  if (match !== undefined) {
    var match$1 = match;
    return /* tuple */[
            match$1[0],
            match$1[1]
          ];
  } else {
    return /* tuple */[
            l,
            /* [] */0
          ];
  }
}

function batches(i, l) {
  var _acc = /* [] */0;
  var _remaining = l;
  while(true) {
    var remaining = _remaining;
    var acc = _acc;
    if (Belt_List.length(remaining) === 0) {
      return acc;
    } else {
      var match = split_at(i, remaining);
      _remaining = match[1];
      _acc = Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
              match[0],
              /* [] */0
            ]));
      continue ;
    }
  };
}

function put_at(i, e, l) {
  var match = split_at(i, l);
  var right = match[1];
  var left = match[0];
  if (right) {
    return Pervasives.$at(left, Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      e,
                      /* [] */0
                    ]), right[1]));
  } else {
    return Pervasives.$at(left, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  e,
                  /* [] */0
                ]));
  }
}

function stuff_in_at(i, e, l) {
  var match = split_at(i, l);
  return Pervasives.$at(match[0], Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    e,
                    /* [] */0
                  ]), match[1]));
}

function sort(by, l) {
  return Belt_List.sort(l, by);
}

function sort_by_key(l) {
  var by_key = function (param, param$1) {
    if (Caml_obj.caml_lessthan(param[0], param$1[0])) {
      return -1;
    } else {
      return 1;
    }
  };
  return Belt_List.sort(l, by_key);
}

function every(f, l) {
  return Belt_List.every(l, f);
}

function some(f, l) {
  return Belt_List.some(l, f);
}

function has(equiv, e, l) {
  return Belt_List.has(l, e, equiv);
}

function is_in(equiv, l, e) {
  return Belt_List.has(l, e, equiv);
}

function contains(e, l) {
  return Belt_List.has(l, e, Caml_obj.caml_equal);
}

function concat(l) {
  return Belt_List.concatMany($$Array.of_list(l));
}

var List$1 = {
  from_array: $$Array.to_list,
  to_array: $$Array.of_list,
  length: length,
  is_empty: is_empty,
  append: append,
  rev: rev,
  sum: sum,
  product: product,
  map: map$1,
  for_each: for_each,
  indexed_map: indexed_map,
  keep_map: keep_map,
  keep: keep,
  flat: flat,
  flat_map: flat_map,
  clean: clean,
  foldr: foldr,
  foldl: foldl$1,
  reduce: reduce,
  partition: List.partition,
  filter: filter,
  find: find,
  index_of: index_of,
  hit: hit,
  member: member,
  rem_from_list: rem_from_list,
  make_set: make_set,
  add_to_set: add_to_set,
  union: union,
  inter: inter,
  head: head,
  assoc_intersect: assoc_intersect,
  substract: substract,
  unique: unique,
  assoc: assoc,
  assoc_keys: assoc_keys,
  assoc_vals: assoc_vals,
  assoc_map: assoc_map,
  assoc_replace: assoc_replace,
  assoc_merge: assoc_merge,
  combine: combine,
  zip: zip,
  unzip: unzip,
  map2: map2,
  fill: fill,
  take: take,
  drop: drop,
  _rangeImp: _rangeImp,
  range: range,
  range_by_step: range_by_step,
  _repeatAndForget: _repeatAndForget,
  repeat_and_forget: repeat_and_forget,
  split_at: split_at,
  batches: batches,
  put_at: put_at,
  stuff_in_at: stuff_in_at,
  sort: sort,
  sort_by_key: sort_by_key,
  every: every,
  some: some,
  has: has,
  is_in: is_in,
  contains: contains,
  concat: concat
};

function get(n, s) {
  return Caml_string.get(s, n);
}

var join = $$String.concat;

function length$1(prim) {
  return prim.length;
}

function index(c, l) {
  return $$String.index(l, c);
}

function sub(offset, len, s) {
  return s.substring(offset, offset + len | 0);
}

function after(offset, s) {
  return sub(offset, s.length - offset | 0, s);
}

function occur_times(c, str) {
  try {
    var idx = $$String.index(str, c);
    return 1 + occur_times(c, after(idx + 1 | 0, str)) | 0;
  }
  catch (exn){
    return 0;
  }
}

function split_by(c, str) {
  try {
    var idx = $$String.index(str, c);
    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
              sub(0, idx, str),
              split_by(c, after(idx + 1 | 0, str))
            ]);
  }
  catch (exn){
    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
              str,
              /* [] */0
            ]);
  }
}

function repeat(num, p) {
  var _acc = "";
  var _counter = num;
  while(true) {
    var counter = _counter;
    var acc = _acc;
    if (counter <= 0) {
      return acc;
    } else {
      _counter = counter - 1 | 0;
      _acc = acc + p;
      continue ;
    }
  };
}

function split(s, str) {
  return $$Array.to_list(str.split(s));
}

function replace(o, n, str) {
  return str.replace(o, n);
}

function decap(s) {
  var first = sub(0, 1, s);
  var trailing = after(1, s);
  return first.toLowerCase() + trailing;
}

var cap = $$String.capitalize;

function slice(start, end_, str) {
  var length = str.length;
  var s = start < 0 ? start + length | 0 : start;
  var e = end_ < 0 ? end_ + length | 0 : end_;
  if (0 <= s && s <= e && e < length) {
    var len = e - s | 0;
    return sub(s, len, str);
  } else {
    return "";
  }
}

function left(num, str) {
  var length = str.length;
  var len = num < length ? num : length;
  return sub(0, len, str);
}

function right(num, str) {
  var length = str.length;
  var s = num < length ? length - num | 0 : 0;
  var len = num < length ? num : length;
  return sub(s, len, str);
}

function drop_left(num, str) {
  var length = str.length;
  var s = num < length ? num : length;
  var len = num < length ? length - num | 0 : 0;
  return sub(s, len, str);
}

function drop_right(num, str) {
  var length = str.length;
  var len = num < length ? length - num | 0 : 0;
  return sub(0, len, str);
}

function starts_with(prefix, str) {
  var prefix_len = prefix.length;
  return prefix === left(prefix_len, str);
}

function ends_with(suffix, str) {
  var suffix_len = suffix.length;
  return suffix === right(suffix_len, str);
}

function contains$1(inner, s) {
  return s.indexOf(inner) !== -1;
}

function indexes(inner, str) {
  var len = inner.length;
  var _acc = /* [] */0;
  var _remaining = str;
  var _offset = 0;
  while(true) {
    var offset = _offset;
    var remaining = _remaining;
    var acc = _acc;
    var idx = remaining.indexOf(inner);
    if (idx === -1) {
      return Belt_List.reverse(acc);
    } else {
      var actual_idx = offset + idx | 0;
      var next_offset = (offset + idx | 0) + len | 0;
      _offset = next_offset;
      _remaining = drop_left(idx + len | 0, remaining);
      _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          actual_idx,
          acc
        ]);
      continue ;
    }
  };
}

function to_int(s) {
  try {
    return Caml_format.caml_int_of_string(s);
  }
  catch (exn){
    return ;
  }
}

function to_float(s) {
  try {
    return Caml_format.caml_float_of_string(s);
  }
  catch (exn){
    return ;
  }
}

function from_int(prim) {
  return String(prim);
}

function from_float(prim) {
  return prim.toString();
}

function from_char(c) {
  return String.fromCharCode(c);
}

function cons$1(c, str) {
  return String.fromCharCode(c) + str;
}

function char_at(i, str) {
  if (i < 0 || i >= str.length) {
    return ;
  } else {
    return Caml_string.get(str, i);
  }
}

function uncons(str) {
  var match = char_at(0, str);
  if (match !== undefined) {
    var rest = drop_left(1, str);
    return /* tuple */[
            match,
            rest
          ];
  }
  
}

function is_digit(c) {
  if (c === /* "0" */48 || c === /* "1" */49 || c === /* "2" */50 || c === /* "3" */51 || c === /* "4" */52 || c === /* "5" */53 || c === /* "6" */54 || c === /* "7" */55 || c === /* "8" */56) {
    return true;
  } else {
    return c === /* "9" */57;
  }
}

function from_list(cs) {
  var l = Belt_List.map(cs, from_char);
  return $$String.concat("", l);
}

function to_list(str) {
  var _acc = /* [] */0;
  var _remaining = str;
  while(true) {
    var remaining = _remaining;
    var acc = _acc;
    var match = uncons(remaining);
    if (match !== undefined) {
      var match$1 = match;
      var next_acc = Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
              match$1[0],
              /* [] */0
            ]));
      _remaining = match$1[1];
      _acc = next_acc;
      continue ;
    } else {
      return acc;
    }
  };
}

function to_upper(str) {
  return str.toUpperCase();
}

function to_lower(str) {
  return str.toLowerCase();
}

function pad(i, c, s) {
  var len = s.length;
  if (len >= i) {
    return s;
  } else {
    var num = i - len | 0;
    var rs = num / 2 | 0;
    var ls = num % 2 + rs | 0;
    var p = String.fromCharCode(c);
    return repeat(ls, p) + (s + repeat(rs, p));
  }
}

function pad_left(i, c, s) {
  var len = s.length;
  if (len >= i) {
    return s;
  } else {
    var num = i - len | 0;
    var p = String.fromCharCode(c);
    return repeat(num, p) + s;
  }
}

function pad_right(i, c, s) {
  var len = s.length;
  if (len >= i) {
    return s;
  } else {
    var num = i - len | 0;
    var p = String.fromCharCode(c);
    return s + repeat(num, p);
  }
}

function is_whitespace(c) {
  if (c === /* " " */32 || c === /* "\012" */12 || c === /* "\n" */10 || c === /* "\r" */13) {
    return true;
  } else {
    return c === /* "\t" */9;
  }
}

var trim = $$String.trim;

function trim_left(_s) {
  while(true) {
    var s = _s;
    var match = uncons(s);
    if (match !== undefined) {
      var match$1 = match;
      if (is_whitespace(match$1[0])) {
        _s = match$1[1];
        continue ;
      } else {
        return s;
      }
    } else {
      return s;
    }
  };
}

function reverse(str) {
  return from_list(Belt_List.reverse(to_list(str)));
}

function trim_right(s) {
  var str = trim_left(from_list(Belt_List.reverse(to_list(s))));
  return from_list(Belt_List.reverse(to_list(str)));
}

var Str = {
  get: get,
  join: join,
  length: length$1,
  index: index,
  sub: sub,
  after: after,
  occur_times: occur_times,
  split_by: split_by,
  repeat: repeat,
  split: split,
  replace: replace,
  decap: decap,
  cap: cap,
  slice: slice,
  left: left,
  right: right,
  drop_left: drop_left,
  drop_right: drop_right,
  starts_with: starts_with,
  ends_with: ends_with,
  contains: contains$1,
  indexes: indexes,
  to_int: to_int,
  to_float: to_float,
  from_int: from_int,
  from_float: from_float,
  from_char: from_char,
  cons: cons$1,
  char_at: char_at,
  uncons: uncons,
  is_digit: is_digit,
  from_list: from_list,
  to_list: to_list,
  to_upper: to_upper,
  to_lower: to_lower,
  pad: pad,
  pad_left: pad_left,
  pad_right: pad_right,
  is_whitespace: is_whitespace,
  trim: trim,
  trim_left: trim_left,
  reverse: reverse,
  trim_right: trim_right
};

function resolve(prim) {
  return Promise.resolve(prim);
}

var $$Promise$1 = {
  resolve: resolve
};

exports.Unexpected = Unexpected;
exports.identity = identity;
exports.eq = eq;
exports.fst = fst;
exports.snd = snd;
exports.$at$plus = $at$plus;
exports.$at$great = $at$great;
exports.$less$pipe = $less$pipe;
exports.cons = cons;
exports.mod_by = mod_by;
exports.$$Option = $$Option;
exports.List = List$1;
exports.Str = Str;
exports.$$Promise = $$Promise$1;
/* ./PlateImpl Not a pure module */
