
// opens a new resource mananger panel
import React, { useState } from 'react'

import { Button, Radio, } from '@arco-design/web-react';
import ResourceMananger from './ResourceMananger';

import {
    IconDelete,
  } from '@arco-design/web-react/icon';

const RadioGroup = Radio.Group;
const ButtonGroup = Button.Group;

import "./ImageSelect.less"

import getImageURL from 'bwax-ui/getImageURL'

export default function ImageSelect({ value, onChange }) {

    const [open, setOpen] = useState(false);

    const renderImage = (file, processor) => {
        return (
            <>
                <div className="thumbnail-box">
                    <img className="thumbnail" src={getImageURL(file, "small")}>
                    </img>
                </div>
                <div className="size-select">
                    <RadioGroup
                        type='button'
                        size="mini"
                        value={processor}
                        onChange={processor => {
                            onChange({ ...value, processor })
                        }}
                    >
                        {["thumbnail", "small", "medium", "large", "xlarge"].map(o => <Radio value={o} key={o}>{o}</Radio>)}
                    </RadioGroup>
                </div>
            </>
        )
    }

    function renderSelectImage() {
        return (
            <Button type='default' size="mini" onClick={_ => {
                setOpen(true);
            }}>选择图片</Button>
        )
    }

    function renderChangeImage() {
        return (
            <ButtonGroup>
                <Button type='default' size="mini" onClick={_ => {
                    setOpen(true);
                }}>重新选择图片</Button>
                <Button type='default' size="mini" icon={<IconDelete />} onClick={_ => onChange(undefined)} />
            </ButtonGroup>
        )
    }


    return (
        <div className="lc-image-select">
            {
                value && value.file ? renderChangeImage() : renderSelectImage()
            }
            {
                value && value.file ? renderImage(value.file, value.processor) : null
            }
            <ResourceMananger open={open} setOpen={setOpen} onFileSelect={file => {
                onChange({ ...(value || {}), file });
            }} />
        </div>
    )
}
