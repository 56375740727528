
import React, { useState, useEffect } from 'react'

import { CgMenuGridR, CgClose } from 'react-icons/cg'
import { BiDotsHorizontal, BiDotsVertical } from 'react-icons/bi'

import { Input, Select, Button, Checkbox } from '@arco-design/web-react';
const Option = Select.Option;

import Tooltip from 'bwax-ui/components/Tooltip';

import ResourceMananger from '../../../inputs/ResourceMananger';
import PositionEdit from '../components/PositionEdit'

import './Backgrounds.less'

const defualtCustomSize = {
    width: { value: 'Auto', unit: '-' },
    height: { value: 'Auto', unit: '-' }
}

export default function ImageEdit({ bgInfosArr, images, activeImageItem, onChange, resourcesModalOpen, setResourcesModalOpen,
    updateActiveImageItem, activeImageIndex, updateBgInfos
}) {

    const [customSize, setCustomSize] = useState(defualtCustomSize)
    const [isHalfSize, setIsHalfSize] = useState(false)
    const [sizeImage, setSizeImage] = useState({...activeImageItem, width: 0, height: 0 })
    const [bgInfos, setBgInfos] = useState({
        backgroundSize: { value: "auto" },
        backgroundPosition: { value: '0px 0px' },
        backgroundRepeat: { value: 'repeat' },
        backgroundAttachment: { value: 'scroll' }
    })

    useEffect(() => {
        const bgInfoKeysArr = Object.keys(bgInfosArr)
        let currentBgSize = bgInfos.backgroundSize.value
        let newBgInfos = {...bgInfos}
        bgInfoKeysArr.map(bgKey => {
            const bgInfoValueArr = bgInfosArr[bgKey]
            newBgInfos = {
                ...newBgInfos,
                [bgKey]: bgInfoValueArr[activeImageIndex] 
            }
            if(bgKey === "backgroundSize") {
                currentBgSize = bgInfoValueArr[activeImageIndex].value
            }
        })
        setBgInfos(newBgInfos)

        let img = new Image()
        img.src = activeImageItem.file.url
        img.onload = function(){
            setSizeImage({
                ...sizeImage,
                width: img.width,
                height: img.height
            })

            if(currentBgSize !== 'cover' && currentBgSize !== "contain") {
                const sizeRegx = /^((\d+(?:\.\d+)?)(px|em|rem|vw|vh|%)?|auto) ((\d+(?:\.\d+)?)(px|em|rem|vw|vh|%)?|auto)$/
                const matchResult = currentBgSize.match(sizeRegx)
                if(matchResult) {
                    setCustomSize({
                        width: matchResult[2] ? { value: matchResult[2], unit: matchResult[3] ? matchResult[3] : 'px'} : { value: "Auto", unit: '-' },
                        height: matchResult[5] ? { value: matchResult[5], unit: matchResult[6] ? matchResult[6] : 'px'} : { value: "Auto", unit: '-' },
                    })

                    if(matchResult[1] && matchResult[1] === `${img.width / 2}px` && matchResult[4] && matchResult[4] === `${img.height / 2}px`) {
                        setIsHalfSize(true)
                    } else {
                        setIsHalfSize(false)
                    }
                }
            }
        }
    }, [bgInfosArr, activeImageItem])

    function renderImagePx () {
        const { width, height } = sizeImage
        return `${width} x ${height}`
    }

    function renderImageSize (size) {
        if(size > 1048576) {
            // Mb
            return `${(size / 1024 / 1024).toFixed(1)}MB`
        } else {
            return `${(size / 1024).toFixed(1)}KB`
        }
    }

    const sizeOption = ['custom', 'cover', 'contain']
    const units = ['px', 'em', 'rem', 'vw', 'vh', '%', 'auto'] 
    const positionUnit = ['px', 'vw', 'vh', '%'] 
    const tiles = [{
        value: "repeat",
        icon: <CgMenuGridR/>,
        tooltip: "Horizontally and vertically"
    }, {
        value: "repeat-x",
        icon: <BiDotsHorizontal/>,
        tooltip: "Horizontally"
    }, {
        value: "repeat-y",
        icon: <BiDotsVertical/>,
        tooltip: "Vertically"
    }, {
        value: "no-repeat",
        icon: <CgClose/>,
        tooltip: "Don’t tile"
    }]
    const fixedOptions = [{
        value: "fixed",
        text: "Fixed"
    }, {
        value: 'scroll',
        text: 'Not fixed'
    }]

    function changeBgInfos (styleKey, newValue) {
        const newBgInfos = {
            ...bgInfos,
            [styleKey]: { 
                ...bgInfos[styleKey],
                value: newValue 
            }
        }
        setBgInfos(newBgInfos)
        updateBgInfos(newBgInfos)
    }

    function renderUnitSelect (side, disabled) {
        const { width, height } = customSize
        const widthValue = `${width.value === "Auto" || width.value === "auto" ? "auto" : width.value}${width.unit === "-" ? '' : width.unit}`
        const heightValue = `${height.value === "Auto" || height.value === "auto" ? "auto" : height.value}${height.unit === '-' ? '' : height.unit}`

        function selectUnit (side, unit) {
            let newBgSize = `${widthValue} ${heightValue}`
            if (unit === 'auto') {
                setCustomSize({
                    ...customSize,
                    [side]: {
                        ...customSize[side],
                        unit: '-'
                    }
                })
                newBgSize = side === "width" ? `auto ${heightValue}` : `${widthValue} auto`
            } else {
                setCustomSize({
                    ...customSize,
                    [side]: {
                        ...customSize[side],
                        unit
                    }
                })
                if (customSize[side].value === 'Auto') {
                    setCustomSize({
                        ...customSize,
                        [side]: {
                            value: 0,
                            unit
                        }
                    })
                    newBgSize = side === "width" ? `${0}${unit} ${heightValue}` : `${widthValue} ${0}${unit}`
                } else {
                    const valueRegx = /(\d+(?:\.\d+)?)/
                    const v = customSize[side].value
                    if(v.match(valueRegx)) {
                        newBgSize = side === "width" ? `${v}${v === '0' ? '' : unit} ${heightValue}` : `${widthValue} ${v}${v === '0' ? '' : unit}`
                    } else {
                        newBgSize = side === "width" ? `${0} ${heightValue}` : `${widthValue} ${0}`
                    }
                }
            }
            changeBgInfos("backgroundSize", newBgSize)
        }

        return (
            <Select value={customSize[side].unit} size={'mini'} className="unit-select" arrowIcon={null} disabled={disabled}
                getPopupContainer={node => node} triggerProps={{ autoAlignPopupWidth: false }} onChange={unit => selectUnit(side, unit)}
            >
                {units.map(option => (
                    <Option key={option} value={option} className='unit-option'>
                        {option.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
    }

    function inputSizeValue (side, value) {
        setCustomSize({
            ...customSize,
            [side]: {
                ...customSize[side],
                value,
            }
        })
    }

    function inputSizeValueConfirm (side) {
        const { width, height } = customSize
        const { value, unit } = customSize[side]
        const valueRegx = /^((\d+(?:\.\d+)?)(px|em|rem|vw|vh|%)?|auto)?$/
        const matchedResult = value ? value.match(valueRegx) : null
        
        if(matchedResult) {
            const matchedValue = matchedResult[2] ? matchedResult[2] : matchedResult[1]
            const matchedUnit = matchedResult[3] ? matchedResult[3] : (matchedValue.match(/^[aA]uto$/) ? '' : (unit === '-' ? 'px' : unit))
            setCustomSize({
                ...customSize,
                [side]: {
                    ...customSize[side],
                    value: matchedValue,
                    unit: matchedUnit
                }
            })
            const widthValue = `${width.value}${width.unit === "-" ? '' : width.unit}`
            const heightValue = `${height.value}${height.unit === '-' ? '' : height.unit}`
            const newBgSize = side === "width" ? `${matchedValue}${matchedUnit} ${heightValue}` : `${widthValue} ${matchedValue}${matchedUnit}`
            changeBgInfos("backgroundSize", newBgSize)
        }
        
    }

    const sizeInputDisabled = bgInfos.backgroundSize.value === "cover" || bgInfos.backgroundSize.value === "contain"

    return (
        <div className='image-container'>
            <div className='image-info'>
                <div>Image</div>
                <div className='image-info-container'>
                    <div className='image-info-box'>
                        <div className='image-display' style={{ backgroundImage: `url(${activeImageItem.file.url})` }} />
                        <div>
                            <div className='title'>{activeImageItem.file.title}</div>
                            <div className='size'>
                                {renderImagePx()}
                                <br />
                                {renderImageSize(activeImageItem.file.size)}
                            </div>
                            <div className='half-size'>
                                <Checkbox checked={isHalfSize} onChange={checked => {
                                    setIsHalfSize(checked)
                                    const newBgSize = isHalfSize ? `auto auto` : `${sizeImage.width / 2}px ${sizeImage.height / 2}px`
                                    changeBgInfos("backgroundSize", newBgSize)
                                }} />
                                @2X
                            </div>
                        </div>
                    </div>
                    <Button type='default' size="mini" style={{ width: '100%' }} onClick={_ => {
                        setResourcesModalOpen(true);
                    }}>
                        选择图片
                    </Button>
                    <ResourceMananger open={resourcesModalOpen} setOpen={setResourcesModalOpen} onFileSelect={file => {
                        let img = new Image()
                        img.src = file.url
                        img.onload = function () {
                            const newImage = {
                                ...activeImageItem,
                                file,
                                width: img.width,
                                height: img.height
                            }
                            updateActiveImageItem(newImage)
                            setSizeImage(newImage)
                        }

                        // 修改 image，同时将 bgInfo 的 isPlaceholder 改为 false
                        const newBgImages = images.map((image, index) => {
                            if(index === activeImageIndex) {
                                return { file } 
                            } else {
                                return image
                            }
                        }).filter((image, index) => {
                            return !image.isPlaceholder || (image.isPlaceholder && index === activeImageIndex)
                        })

                        onChange({ backgroundImage: newBgImages })

                        let newBgInfos = {}
                        Object.keys(bgInfos).map(bgInfoKey => {
                            newBgInfos = {
                                ...newBgInfos,
                                [bgInfoKey]: { ...bgInfos[bgInfoKey], isPlaceholder: false }
                            }
                        })
                        updateBgInfos(newBgInfos)
                    }} />
                </div>
            </div>
            <div className='image-size'>
                <div>Size</div>
                <div className='size-edit-area'>
                    <div className='size-options options'>
                        {
                            sizeOption.map(op => {
                                function isActive() {
                                    if (op === "cover" || op === "contain") {
                                        return bgInfos.backgroundSize.value === op
                                    } else {
                                        return bgInfos.backgroundSize.value !== "cover" && bgInfos.backgroundSize.value !== 'contain'
                                    }
                                }

                                return (
                                    <div key={op} className={`option-box ${isActive() ? 'active' : ''}`}
                                        onClick={() => {
                                            let newBgSize = 'auto'

                                            if (op !== 'custom') {
                                                newBgSize = op
                                                setCustomSize(defualtCustomSize)
                                            }

                                            changeBgInfos("backgroundSize", newBgSize)
                                            
                                        }}
                                    >
                                        {`${op[0].toUpperCase()}${op.slice(1)}`}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='size-input'>
                        <div className='width'>
                            <Input size="mini" value={customSize.width.value} suffix={renderUnitSelect('width', sizeInputDisabled)}
                                onChange={value => inputSizeValue('width', value)} disabled={sizeInputDisabled}
                                onBlur={() => inputSizeValueConfirm('width')}
                                onPressEnter={() => inputSizeValueConfirm('width')}
                            />
                            <div className='label'>Width</div>
                        </div>
                        <div className='height'>
                            <Input size="mini" value={customSize.height.value} suffix={renderUnitSelect('height', sizeInputDisabled)}
                                onChange={value => inputSizeValue('height', value)} disabled={sizeInputDisabled}
                                onBlur={() => inputSizeValueConfirm('height')}
                                onPressEnter={() => inputSizeValueConfirm('height')}
                            />
                            <div className='label'>Height</div>
                        </div>

                    </div>
                </div>

            </div>
            <div className='image-position'>
                <div>Position</div>
                <PositionEdit attribute={bgInfos.backgroundPosition.value} unitsArr={positionUnit}
                    onChange={value => changeBgInfos("backgroundPosition", value)}
                />
            </div>
            <div className='image-tile-attachment'>
                <div className='style-with-options'>
                    <div>Tile</div>
                    <div className='options'>
                        {
                            tiles.map((op, index) => {
                                const { value, icon, tooltip } = op
                                const isActive = bgInfos.backgroundRepeat.value === value
                                return (
                                    <Tooltip key={index} text={tooltip}>
                                        <div className={`option-box ${isActive ? 'active' : ''}`}
                                            onClick={() => changeBgInfos("backgroundRepeat", value)}
                                        >
                                            {icon}
                                        </div>
                                    </Tooltip>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='style-with-options'>
                    <div>Fixed</div>
                    <div className='options'>
                        {
                            fixedOptions.map((op, index) => {
                                const { value, text } = op
                                const isActive = bgInfos.backgroundAttachment.value === value
                                return (
                                    <div key={index} className={`option-box text ${isActive ? 'active' : ''}`}
                                        onClick={() => changeBgInfos("backgroundAttachment", value) }
                                    >
                                        {text}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
