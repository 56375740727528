
import "./AdminPage.less";

import React, { useContext } from "react";

import useDataLoader from 'bwax-ui/legacy/store/useDataLoader'
import { runDataQuery } from 'bwax/query/runClientQuery';


import { Helmet } from 'react-helmet-async';

import AdminPageContainer from "Client/ml/AdminPageContainer";

import ApplicationContext from 'bwax/util/ApplicationContext';


function loadAdminPage(name, appCode) {
    
    return async env => {
        //// the entity

        let queryText = `query { definition { application (code: "${appCode}" ) { adminPage (name: "${name}") { compiled } } } }`

        const result = await runDataQuery(env)(queryText)()

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.definition ? data.definition.application.adminPage : null
        }
    }
}

export function useAdminPage(name) {

    const { currentApplication } = useContext(ApplicationContext);

    const appCode = currentApplication.code;

    const key = `definitions::${appCode}::AdminPage::${name}`;
    const { getData, forceLoad } = useDataLoader({ [key]: loadAdminPage(name, appCode) })
    const [adminPage] = getData(key)

    async function forceReload() {
        let dataDict = await forceLoad({ [key]: loadAdminPage(name, appCode) })
        return dataDict[key]
    }

    return { adminPage, forceReload }

};

function ErrorPage({ children }) {
    return (
        <div style={{ padding: "24px 32px", width: "100%" }}>
            { children }
        </div>            
    )
}

export default function AdminPage(props) {

    const { match, loadingComp: Loading, routeTo, facade, history, location } = props;
    const { pageName } = match.params;

    const { adminPage } = useAdminPage(pageName);

    function renderAdminPage() {
        if (adminPage === null) {
            return (
                <ErrorPage>
                    未找到页面“{pageName}”
                </ErrorPage>
            )
        } else if (adminPage === undefined) {
            return <Loading />
        } else if (!adminPage.compiled) { 
            return (
                <ErrorPage>
                    页面“{pageName}”还没实现
                </ErrorPage>
            )
        } else {
            return (
                <AdminPageContainer key={pageName} {...{
                    compiled: adminPage.compiled,
                    facade, 
                    match,
                    location,
                    routeTo,
                    pageName,
                }} />
            )
        }
    }

    return (
        <>
            <Helmet>
                <title>{pageName}</title>
            </Helmet>
            { renderAdminPage() }
        </>
    )
}
