import React, { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'

// import QrCode from 'Client/js/components/QrCode'
import QrCode from 'bwax-ui/lazy/QrCode'

const COUNT = 90

export default function LoginQrCode(props) {

    const { runQuery, afterLogin, sandbox, tenantCode } = props;

    const [token, setToken] = useState(null);
    const [count, setCount] = useState(COUNT);

    const genToken = async () => {
        const result = await runQuery(GenLoginTokenText)({ input: { clientMutationId: Date.now() + "" } });
        const { data } = JSON.parse(result);
        const { token } = data.genLoginToken.loginToken;
        setToken(token);
        setCount(COUNT);
    }

    async function authWithLoginToken(token) {
        const result = await runQuery(AuthWithLoginTokenText)({ input: { token, clientMutationId: Date.now() + "" } });
        const { data } = JSON.parse(result);

        const { authUser } = data.authWithLoginToken.authSession
        const accessToken = data.authWithLoginToken.authSession.token
        if (authUser) {
            afterLogin(accessToken, authUser);
        }
    }


    const checkTokenStatus = async token => {
        const result = await runQuery(GetLoginTokenStatusText)({ token });
        const { data } = JSON.parse(result);
        const status = data.loginTokenStatus;

        if (status == "Confirmed") {
            authWithLoginToken(token);
        }
    }

    const tokenRef = useRef();
    tokenRef.current = token;
    const countRef = useRef();
    countRef.current = count;
    useEffect(() => {
        genToken();
        const countDownTimer = setInterval(() => {
            if (tokenRef.current) {
                const newCount = countRef.current - 1;
                setCount(newCount > 0 ? newCount : 0)
                if (newCount === 1) {
                    genToken();
                }
            }
        }, 1000);

        const checkTokenStatusTimer = setInterval(() => {
            checkTokenStatus(tokenRef.current);
        }, 2000)

        return () => {
            clearInterval(countDownTimer);
            clearInterval(checkTokenStatusTimer);
        }
    }, [])

    let hostname = document && document.userenv.mobileHosts ? document.userenv.mobileHosts[0] : null

    if (document.dev) {
        // to test qianmao
        // hostname = "www.qianmaoxt.com";

        // to test cainiao
        // hostname = "wealthbird.qunfengshe.com"

        // to test rongchuan
        // hostname = "rongchuan.qunfengshe.com";

        // to test cb
        hostname = "cb.qunfengshe.com";

        // to test fengcanmou
        // hostname = "fcm.qunfengshe.com";

        // to test dti
        // hostname = "leancode.cn";

        // to test mt
        // hostname = "dev.mt.tikicrowd.com";

        // hostname = "simplifyai.cn";

        // hostname = "translate.simplifyai.cn"
    }

    function getPrefix(sandbox, tenantCode) {
        // TODO 也要考虑用域名区分 tenant 的情况
        return (sandbox ? "/sandbox" : "") + (tenantCode ? "/-/" + tenantCode : "");
    }
    const prefix = getPrefix(sandbox, tenantCode);

    let base = `https://${hostname}${prefix}`;

    if (base == "https://translate.simplifyai.cn/sandbox") {
        base = "https://translate-sandbox.simplifyai.cn"
    } else {

    }

    const loginQrCodeURL = token && hostname ? `${base}/ext/auth/confirm-token/${token}` : null

    console.log(">>> loginQrCodeURL", base, loginQrCodeURL)

    const onGeneratedQrcode = () => {
    }

    return (
        <div className="qr-code-box">
            {
                loginQrCodeURL ?
                    <QrCode
                        info={loginQrCodeURL}
                        size={168}
                        onGenerated={onGeneratedQrcode} /> :
                    <div className="qr-code-gen-loading">
                        初始化...
                    </div>
            }
            <div className="qr-code-tips">
                请用手机微信扫描登录
                <span className="count-down">{` ${count}s`}</span>
            </div>
        </div>
    )
}




const GenLoginTokenText = `
mutation GenLoginTokenMutation (
    $input: GenLoginTokenInput!
  ) {
     genLoginToken(input: $input) {
       loginToken {
         id
         token
         status
       }
     }
  }
`

const GetLoginTokenStatusText = `
query($token: String!) {
    loginTokenStatus(token: $token)
}
`


const AuthWithLoginTokenText = `
mutation AuthWithLoginTokenMutation (
    $input: AuthWithLoginTokenInput!
  ) {
     authWithLoginToken(input: $input) {
       authSession {
         id
         authUser {
           id
           systemRoles
           nickName
           avatar {
            expireTime
            title
            size
            contentType
            url
            path
          }
         }
         token
       }
     }
  }

`