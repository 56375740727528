// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Dict$BwaxAdmin = require("../dict.bs.js");
var Json$BwaxAdmin = require("../utils/json.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Query_env$BwaxAdmin = require("../query/query_env.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_typing$BwaxAdmin = require("./lang_typing.bs.js");
var Performance$BwaxAdmin = require("../performance.bs.js");
var Lang_builtin$BwaxAdmin = require("./lang_builtin.bs.js");
var Lang_mod_set$BwaxAdmin = require("./mod/builtin/lang_mod_set.bs.js");
var Lang_runtime$BwaxAdmin = require("./lang_runtime.bs.js");
var Lang_mod_char$BwaxAdmin = require("./mod/builtin/lang_mod_char.bs.js");
var Lang_mod_dict$BwaxAdmin = require("./mod/builtin/lang_mod_dict.bs.js");
var Lang_mod_file$BwaxAdmin = require("./mod/builtin/lang_mod_file.bs.js");
var Lang_mod_json$BwaxAdmin = require("./mod/builtin/lang_mod_json.bs.js");
var Lang_mod_list$BwaxAdmin = require("./mod/builtin/lang_mod_list.bs.js");
var Lang_mod_maybe$BwaxAdmin = require("./mod/builtin/lang_mod_maybe.bs.js");
var Query_commands$BwaxAdmin = require("../query/query_commands.bs.js");
var Query_env_tenv$BwaxAdmin = require("../query/query_env_tenv.bs.js");
var Lang_entry_slim$BwaxAdmin = require("./lang_entry_slim.bs.js");
var Lang_mod_basics$BwaxAdmin = require("./mod/builtin/lang_mod_basics.bs.js");
var Lang_mod_crypto$BwaxAdmin = require("./mod/builtin/lang_mod_crypto.bs.js");
var Lang_mod_number$BwaxAdmin = require("./mod/builtin/lang_mod_number.bs.js");
var Lang_mod_preset$BwaxAdmin = require("./mod/builtin/lang_mod_preset.bs.js");
var Lang_mod_string$BwaxAdmin = require("./mod/builtin/lang_mod_string.bs.js");
var Runtime_mod_cmd$BwaxAdmin = require("./mod/runtime/runtime_mod_cmd.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");
var Runtime_mod_cmdm$BwaxAdmin = require("./mod/runtime/runtime_mod_cmdm.bs.js");
var Runtime_mod_date$BwaxAdmin = require("./mod/runtime/runtime_mod_date.bs.js");
var Lang_parser_lezer$BwaxAdmin = require("./lang_parser_lezer.bs.js");
var Lang_typing_print$BwaxAdmin = require("./lang_typing_print.bs.js");
var Runtime_mod_timer$BwaxAdmin = require("./mod/runtime/runtime_mod_timer.bs.js");
var Command_m_executor$BwaxAdmin = require("./command_m_executor.bs.js");
var Runtime_mod_random$BwaxAdmin = require("./mod/runtime/runtime_mod_random.bs.js");
var Lang_typing_annotation$BwaxAdmin = require("./lang_typing_annotation.bs.js");
var Lang_typing_unification$BwaxAdmin = require("./lang_typing_unification.bs.js");
var Record_dependency_resolver$BwaxAdmin = require("./record_dependency_resolver.bs.js");

var parse_code = Lang_parser_lezer$BwaxAdmin.parse;

function synthize_type(fixed_tenv, given_dts, param) {
  var n = param[0];
  try {
    var match = Lang_typing$BwaxAdmin.type_defs(Lang_typing_base$BwaxAdmin.make_counter(/* () */0), fixed_tenv, given_dts, Dict$BwaxAdmin.$$String.empty(/* () */0), /* [] */0, /* tuple */[
          n,
          param[1]
        ]);
    return /* tuple */[
            /* tuple */[
              n,
              match[3]
            ],
            undefined
          ];
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
      var match$1 = exn[2];
      var error_000 = exn[1];
      var error_001 = match$1[/* start_index */2];
      var error_002 = match$1[/* end_index */3];
      var error = /* tuple */[
        error_000,
        error_001,
        error_002
      ];
      return /* tuple */[
              undefined,
              error
            ];
    } else {
      throw exn;
    }
  }
}

function eval_defs_to_js(base_env, param) {
  var match = Lang_eval$BwaxAdmin.evaluate_defs(base_env, /* tuple */[
        param[0],
        param[1]
      ]);
  return Js_dict.fromList(Dict$BwaxAdmin.$$String.to_list(Dict$BwaxAdmin.$$String.keep_map((function (param, r) {
                        var match = r[0];
                        if (match[1] === undefined) {
                          return match[0];
                        }
                        
                      }), match[1])));
}

function defs_to_tree_js(param) {
  var meta_to_js = function (m) {
    var t = m[1];
    var match = m[0];
    var type_str;
    if (typeof t === "number" || t.tag || t[0] !== "Definition") {
      type_str = Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, t);
    } else {
      var match$1 = t[1];
      type_str = match$1 && !match$1[1] ? Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, match$1[0]) : Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, t);
    }
    return {
            term: t,
            type_str: type_str,
            text: match[/* text */1],
            start_index: match[/* start_index */2],
            end_index: match[/* end_index */3]
          };
  };
  return Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map((function (param) {
                    return Lang_eval$BwaxAdmin.def_to_js(meta_to_js, param);
                  }), param[1]));
}

function type_env_to_js(dts, tenv) {
  return Js_dict.fromList(Plate$BwaxAdmin.List.keep_map((function (param) {
                    var match = param[1];
                    if (match[1]) {
                      return /* tuple */[
                              param[0],
                              Lang_typing_print$BwaxAdmin.string_of_scheme(dts, match[0])
                            ];
                    }
                    
                  }), Dict$BwaxAdmin.$$String.to_list(tenv)));
}

function mock_types(param) {
  var mock = function (param) {
    return /* No_term */0;
  };
  var mocked = Plate$BwaxAdmin.List.map((function (param) {
          return Lang_eval$BwaxAdmin.trav_def(mock, param);
        }), param[1]);
  return /* tuple */[
          param[0],
          mocked
        ];
}

function parse_and_mock_types(m_src) {
  return mock_types(Plate$BwaxAdmin.fst(Curry._1(Plate$BwaxAdmin.$$Option.force, Plate$BwaxAdmin.fst(Lang_parser_lezer$BwaxAdmin.parse(m_src)))));
}

function build_backend_runtime_modules(param) {
  return Plate$BwaxAdmin.List.map(parse_and_mock_types, Pervasives.$at(Lang_runtime$BwaxAdmin.common_runtime_modules, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Query_env$BwaxAdmin.preset_module,
                    /* [] */0
                  ])));
}

function parse_and_prepare_env(param, src_modules, externals) {
  var base_modules = param[0];
  var t0 = Performance$BwaxAdmin.now(/* () */0);
  var modules = Plate$BwaxAdmin.List.foldr((function (module_src, acc_modules) {
          var match = Plate$BwaxAdmin.fst(Curry._1(Plate$BwaxAdmin.$$Option.force, Plate$BwaxAdmin.fst(Lang_parser_lezer$BwaxAdmin.parse(module_src))));
          return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      match[0],
                      match[1]
                    ],
                    acc_modules
                  ]);
        }), src_modules, /* [] */0);
  var t1 = Performance$BwaxAdmin.now(/* () */0);
  console.log("Parsing uses", t1 - t0);
  var match = Lang_builtin$BwaxAdmin.build_gtenv(base_modules);
  var t1_1 = Performance$BwaxAdmin.now(/* () */0);
  console.log("Parsing bases uses", t1_1 - t1);
  var t11 = Performance$BwaxAdmin.now(/* () */0);
  var match$1 = Plate$BwaxAdmin.List.foldr((function (m, param) {
          var acc_env = param[0];
          var match = Lang_builtin$BwaxAdmin.type_defs_and_acc(param[1], acc_env, m);
          var next_typed_modules_000 = /* tuple */[
            match[0],
            match[3]
          ];
          var next_typed_modules_001 = param[2];
          var next_typed_modules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              next_typed_modules_000,
              next_typed_modules_001
            ]);
          var next_env = Dict$BwaxAdmin.$$String.union(match[2], acc_env);
          return /* tuple */[
                  next_env,
                  match[1],
                  next_typed_modules
                ];
        }), modules, /* tuple */[
        match[0],
        match[1],
        /* [] */0
      ]);
  var t2 = Performance$BwaxAdmin.now(/* () */0);
  console.log("Typing uses", t2 - t11);
  var slim_modules = Plate$BwaxAdmin.List.map((function (param) {
          var f = function (param) {
            return param[1];
          };
          return /* tuple */[
                  param[0],
                  Plate$BwaxAdmin.List.map((function (param) {
                          return Lang_eval$BwaxAdmin.trav_def(f, param);
                        }), param[1])
                ];
        }), match$1[2]);
  var genv = Lang_builtin$BwaxAdmin.build_genv(Plate$BwaxAdmin.List.map(parse_and_mock_types, base_modules), param[1]);
  var env = Lang_builtin$BwaxAdmin.build_env(genv, slim_modules, externals);
  return /* tuple */[
          match$1[0],
          match$1[1],
          env,
          slim_modules
        ];
}

function prepare_global_tenv(param) {
  var match = parse_and_prepare_env(/* tuple */[
        Pervasives.$at(Lang_runtime$BwaxAdmin.common_runtime_modules, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Query_env$BwaxAdmin.preset_module,
                /* [] */0
              ])),
        Lang_runtime$BwaxAdmin.common_runtime_externals
      ], /* [] */0, /* [] */0);
  return /* tuple */[
          match[0],
          match[1],
          match[2]
        ];
}

function prepare_base_typing_env(param) {
  var base_modules = Pervasives.$at(Lang_runtime$BwaxAdmin.common_runtime_modules, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          Query_env$BwaxAdmin.preset_module,
          /* [] */0
        ]));
  var match = Lang_builtin$BwaxAdmin.build_tenv(Lang_typing_base$BwaxAdmin.builtin_dts, Dict$BwaxAdmin.$$String.empty(/* () */0), base_modules);
  return /* tuple */[
          match[0],
          match[1]
        ];
}

var prepare_typing_env_for_entities = Query_env_tenv$BwaxAdmin.get_entity_tenv_and_dts;

function check_expr_with_expected_type(original_dts, expected_t, typed_expr) {
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var base_dts = Lang_typing_base$BwaxAdmin.clone_dts(original_dts);
  var inferred = Lang_typing_annotation$BwaxAdmin.type_of(typed_expr);
  try {
    Lang_typing_unification$BwaxAdmin.unify(next_int, base_dts, /* tuple */[
          expected_t,
          inferred
        ]);
    return /* tuple */[
            true,
            undefined
          ];
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Lang_typing_unification$BwaxAdmin.Unify_exc) {
      return /* tuple */[
              false,
              exn[1]
            ];
    } else {
      throw exn;
    }
  }
}

function do_type_expr_with_expected_type(next_int, original_dts, original_tenv, maybe_module, expected_t, expr) {
  var base_dts = Lang_typing_base$BwaxAdmin.clone_dts(original_dts);
  var base_tenv = Lang_typing_base$BwaxAdmin.clone_tenv(original_tenv);
  var do_typing = function (expr) {
    var match = Lang_typing$BwaxAdmin.type_expr(next_int, base_tenv, base_dts, maybe_module, /* record */Caml_chrome_debugger.record([
            "applying_terms",
            "expected_term"
          ], [
            0,
            expected_t
          ]), Dict$BwaxAdmin.$$String.empty(/* () */0), /* [] */0, expr);
    var match$1 = match[0];
    var match$2 = match$1[1];
    var inferred = match$2[1];
    var src_meta = match$2[0];
    var t = expected_t !== undefined ? expected_t : Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
    try {
      Lang_typing_unification$BwaxAdmin.unify(next_int, base_dts, /* tuple */[
            t,
            inferred
          ]);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn[0] === Lang_typing_unification$BwaxAdmin.Unify_exc) {
        throw [
              Lang_typing_base$BwaxAdmin.Type_exn,
              exn[1],
              src_meta,
              0
            ];
      }
      throw exn;
    }
    return /* tuple */[
            match$1[0],
            /* tuple */[
              src_meta,
              inferred
            ]
          ];
  };
  try {
    var ast = do_typing(expr);
    return /* tuple */[
            ast,
            undefined
          ];
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
      var match = e[2];
      var msg = e[1];
      var error_001 = match[/* start_index */2];
      var error_002 = match[/* end_index */3];
      var error = /* tuple */[
        msg,
        error_001,
        error_002
      ];
      console.log("Error", msg);
      return /* tuple */[
              undefined,
              error
            ];
    } else {
      console.log("Unexpected error", e);
      return /* tuple */[
              undefined,
              /* tuple */[
                "Unexpected error",
                0,
                0
              ]
            ];
    }
  }
}

function type_expr_with_expected_type(original_dts, original_tenv, maybe_module, expected_t, expr) {
  return do_type_expr_with_expected_type(Lang_typing_base$BwaxAdmin.make_counter(/* () */0), original_dts, original_tenv, maybe_module, expected_t, expr);
}

var Never_be_here = Caml_exceptions.create("Lang_entry-BwaxAdmin.Never_be_here");

function type_defs_to_js(original_dts, original_tenv, param) {
  var defs = param[1];
  var n = param[0];
  try {
    var t1 = Performance$BwaxAdmin.now(/* () */0);
    var dts = Lang_typing_base$BwaxAdmin.clone_dts(original_dts);
    var tenv = Lang_typing_base$BwaxAdmin.clone_tenv(original_tenv);
    var t1_1 = Performance$BwaxAdmin.now(/* () */0);
    console.log("Clone used", t1_1 - t1, "ms");
    var perform_defs_typing = function (dts, tenv, defs_expr) {
      return Lang_typing$BwaxAdmin.type_defs(Lang_typing_base$BwaxAdmin.make_counter(/* () */0), tenv, dts, Dict$BwaxAdmin.$$String.empty(/* () */0), /* [] */0, defs_expr);
    };
    var match = perform_defs_typing(dts, tenv, /* tuple */[
          n,
          defs
        ]);
    var t2 = Performance$BwaxAdmin.now(/* () */0);
    console.log("Typing used", t2 - t1_1, "ms", Plate$BwaxAdmin.List.length(defs));
    var f = function (param) {
      return param[1];
    };
    var only_typed_defs = Plate$BwaxAdmin.List.map((function (param) {
            return Lang_eval$BwaxAdmin.trav_def(f, param);
          }), match[3]);
    var f$1 = function (t) {
      var st = Lang_typing_base$BwaxAdmin.completely_shrink(t);
      if (Query_commands$BwaxAdmin.if_term_matters(st) || Record_dependency_resolver$BwaxAdmin.if_term_matters(st) || Lang_runtime$BwaxAdmin.is_msg_tagger(st) || Command_m_executor$BwaxAdmin.if_term_matters(st)) {
        return st;
      } else {
        return /* No_term */0;
      }
    };
    var slim_typed_defs = Plate$BwaxAdmin.List.map((function (param) {
            return Lang_eval$BwaxAdmin.trav_def(f$1, param);
          }), only_typed_defs);
    var slim_dts = Plate$BwaxAdmin.List.assoc_map((function (ti) {
            if (typeof ti === "number") {
              return ti;
            } else {
              switch (ti.tag | 0) {
                case /* Just */0 :
                    return /* Just */Caml_chrome_debugger.variant("Just", 0, [
                              Lang_typing_base$BwaxAdmin.simplify_vars(ti[0]),
                              ti[1]
                            ]);
                case /* Alias */1 :
                    return /* Alias */Caml_chrome_debugger.variant("Alias", 1, [Lang_typing_base$BwaxAdmin.simplify_vars(ti[0])]);
                default:
                  return ti;
              }
            }
          }), match[1]);
    return /* tuple */[
            /* tuple */[
              n,
              only_typed_defs
            ],
            /* tuple */[
              n,
              slim_typed_defs
            ],
            Caml_option.some(match[2]),
            slim_dts,
            Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.unique(match[4][/* external_names */0])),
            undefined
          ];
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Lang_parser_lezer$BwaxAdmin.Convert_exn) {
      var match$1 = e[3];
      var error_001 = match$1[/* start_index */2];
      var error_002 = match$1[/* end_index */3];
      var error = /* tuple */[
        "It is not supposed to be here",
        error_001,
        error_002
      ];
      return /* tuple */[
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              error
            ];
    } else if (e[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
      var match$2 = e[2];
      var error_000 = e[1];
      var error_001$1 = match$2[/* start_index */2];
      var error_002$1 = match$2[/* end_index */3];
      var error$1 = /* tuple */[
        error_000,
        error_001$1,
        error_002$1
      ];
      return /* tuple */[
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              error$1
            ];
    } else {
      console.log("Unexpected error", e);
      return /* tuple */[
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              /* tuple */[
                "Unexpected error",
                0,
                0
              ]
            ];
    }
  }
}

function parse_and_type_defs_to_js(original_dts, original_tenv, input) {
  var t0 = Performance$BwaxAdmin.now(/* () */0);
  var match = Lang_parser_lezer$BwaxAdmin.parse(input);
  var match$1 = match[0];
  if (match$1 !== undefined) {
    var match$2 = match$1[0];
    var t1 = Performance$BwaxAdmin.now(/* () */0);
    console.log("Parsing used", t1 - t0, "ms");
    return type_defs_to_js(original_dts, original_tenv, /* tuple */[
                match$2[0],
                match$2[1]
              ]);
  } else if (match[1] !== undefined) {
    return /* tuple */[
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            match[1]
          ];
  } else {
    return /* tuple */[
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            /* tuple */[
              "Unexpected error",
              0,
              0
            ]
          ];
  }
}

function only_exposed_tyenv(tyenv) {
  return Dict$BwaxAdmin.$$String.filter((function (_k, param) {
                return param[1];
              }), tyenv);
}

var runtime_srcs_js = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "(preset)",
          Lang_mod_preset$BwaxAdmin.src
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "Maybe",
              Lang_mod_maybe$BwaxAdmin.src
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "Basics",
                  Lang_mod_basics$BwaxAdmin.src
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "Char",
                      Lang_mod_char$BwaxAdmin.src(/* () */0)
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "Number",
                          Lang_mod_number$BwaxAdmin.src
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "List",
                              Lang_mod_list$BwaxAdmin.src
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "Array",
                                  Lang_mod_dict$BwaxAdmin.src
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "Dict",
                                      Lang_mod_dict$BwaxAdmin.src
                                    ],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        /* tuple */[
                                          "Set",
                                          Lang_mod_set$BwaxAdmin.src
                                        ],
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            /* tuple */[
                                              "String",
                                              Lang_mod_string$BwaxAdmin.src
                                            ],
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                /* tuple */[
                                                  "Json",
                                                  Lang_mod_json$BwaxAdmin.src
                                                ],
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    /* tuple */[
                                                      "File",
                                                      Lang_mod_file$BwaxAdmin.src
                                                    ],
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        /* tuple */[
                                                          "Crypto",
                                                          Lang_mod_crypto$BwaxAdmin.src
                                                        ],
                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            /* tuple */[
                                                              "Cmd",
                                                              Runtime_mod_cmd$BwaxAdmin.src
                                                            ],
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                /* tuple */[
                                                                  "CmdM",
                                                                  Runtime_mod_cmdm$BwaxAdmin.src
                                                                ],
                                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                    /* tuple */[
                                                                      "Timer",
                                                                      Runtime_mod_timer$BwaxAdmin.src
                                                                    ],
                                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                        /* tuple */[
                                                                          "Random",
                                                                          Runtime_mod_random$BwaxAdmin.src
                                                                        ],
                                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                            /* tuple */[
                                                                              "Date",
                                                                              Runtime_mod_date$BwaxAdmin.src
                                                                            ],
                                                                            /* [] */0
                                                                          ])
                                                                      ])
                                                                  ])
                                                              ])
                                                          ])
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ])
      ]));

function get_query_srcs_js(allEntities, allDataTypes) {
  var match = Query_env$BwaxAdmin.build_definition(allEntities, allDataTypes);
  var entity_dict = match[0];
  var dts = Query_env_tenv$BwaxAdmin.get_entity_dts(entity_dict, match[1]);
  var adt_src = Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.map(Lang_typing_print$BwaxAdmin.print_type_definition, dts));
  var user_related_query_src = Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.map(Lang_typing_print$BwaxAdmin.print_func_definition, Query_env_tenv$BwaxAdmin.user_related_term_list));
  var entity_query_modules = Plate$BwaxAdmin.List.map((function (entity) {
          var ename = Base_query_types$BwaxAdmin.normalize_name(entity[/* name */0]);
          var qname = "Query_" + ename;
          var ts = Query_env_tenv$BwaxAdmin.get_entity_query_tenv_list(entity);
          var src = Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.map(Lang_typing_print$BwaxAdmin.print_func_definition, ts));
          return /* tuple */[
                  qname,
                  "\n\n" + src
                ];
        }), Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.List.assoc_vals(entity_dict)));
  var entity_cmd_modules = Plate$BwaxAdmin.List.map((function (entity) {
          var ename = Base_query_types$BwaxAdmin.normalize_name(entity[/* name */0]);
          var qname = "Cmd_" + ename;
          var ts = Query_env_tenv$BwaxAdmin.get_entity_cmd_tenv_list(entity);
          var src = Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.map(Lang_typing_print$BwaxAdmin.print_func_definition, ts));
          return /* tuple */[
                  qname,
                  "\n\n" + src
                ];
        }), Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.List.assoc_vals(entity_dict)));
  var adt_modules_000 = /* tuple */[
    "(query preset)",
    Query_env$BwaxAdmin.preset_module
  ];
  var adt_modules_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* tuple */[
        "(query adt)",
        "\n\n" + adt_src
      ],
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "(common_query)",
            "\n\n" + user_related_query_src
          ],
          /* [] */0
        ])
    ]);
  var adt_modules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      adt_modules_000,
      adt_modules_001
    ]);
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(Js_dict.fromList, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    adt_modules,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        entity_query_modules,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            entity_cmd_modules,
                            /* [] */0
                          ])
                      ])
                  ])));
}

var value_to_js = Lang_mod_json$BwaxAdmin.any_value_to_plain_json;

function print_dts_alias(dts) {
  return Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.keep_map((function (param) {
                    var dt = param[1];
                    if (typeof dt === "number" || dt.tag !== /* Alias */1) {
                      return ;
                    } else {
                      var scheme = dt[0];
                      return param[0] + (": " + (Lang_typing_print$BwaxAdmin.string_of_scheme(/* [] */0, scheme) + (" " + String(Plate$BwaxAdmin.List.length(scheme[0])))));
                    }
                  }), dts));
}

function get_entity_names_from_typed_ast(entity_dict, param) {
  var entity_names = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var f = function (t) {
    var get_entity_name = function (_t) {
      while(true) {
        var t = _t;
        var name;
        if (typeof t === "number") {
          return ;
        } else {
          switch (t.tag | 0) {
            case /* Term */0 :
                var name$1 = t[0];
                var exit = 0;
                switch (name$1) {
                  case "List" :
                  case "Maybe" :
                  case "PagedList" :
                      exit = 2;
                      break;
                  default:
                    name = name$1;
                }
                if (exit === 2) {
                  var match = t[1];
                  if (match && !match[1]) {
                    _t = match[0];
                    continue ;
                  } else {
                    name = name$1;
                  }
                }
                break;
            case /* Var */2 :
                var match$1 = t[0][/* var_type */0];
                if (typeof match$1 === "number" || match$1.tag !== /* Record_readonly */1) {
                  return ;
                } else {
                  name = match$1[0];
                }
                break;
            default:
              return ;
          }
        }
        return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.fst, Plate$BwaxAdmin.List.find((function(name){
                      return function (param) {
                        return Base_query_types$BwaxAdmin.tname_of(param[0]) === name;
                      }
                      }(name)), entity_dict));
      };
    };
    var match = get_entity_name(t);
    if (match !== undefined) {
      entity_names[0] = /* :: */Caml_chrome_debugger.simpleVariant("::", [
          match,
          entity_names[0]
        ]);
    }
    return t;
  };
  Plate$BwaxAdmin.List.map((function (param) {
          return Lang_eval$BwaxAdmin.trav_def(f, param);
        }), param[1]);
  return entity_names[0];
}

function get_entity_names_from_dts(entity_dict, dts) {
  return Plate$BwaxAdmin.List.keep_map((function (param) {
                var match = param[1];
                if (typeof match === "number" || match.tag !== /* Alias */1) {
                  return ;
                } else {
                  var t = match[0][1];
                  var name;
                  if (typeof t === "number") {
                    return ;
                  } else {
                    switch (t.tag | 0) {
                      case /* Term */0 :
                          name = t[0];
                          break;
                      case /* Var */2 :
                          var match$1 = t[0][/* var_type */0];
                          if (typeof match$1 === "number" || match$1.tag !== /* Record_readonly */1) {
                            return ;
                          } else {
                            name = match$1[0];
                          }
                          break;
                      default:
                        return ;
                    }
                  }
                  return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.fst, Plate$BwaxAdmin.List.find((function (param) {
                                    return Base_query_types$BwaxAdmin.tname_of(param[0]) === name;
                                  }), entity_dict));
                }
              }), dts);
}

function print_dts(dts) {
  return Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (param) {
                    var dt = param[1];
                    var s;
                    if (typeof dt === "number") {
                      s = dt === /* Special */0 ? "special" : "dummy";
                    } else {
                      switch (dt.tag | 0) {
                        case /* Just */0 :
                            var scheme = dt[0];
                            s = Lang_typing_print$BwaxAdmin.string_of_scheme(/* [] */0, scheme) + (" " + Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                                          return Lang_typing_print$BwaxAdmin.string_of_var(/* [] */0, param);
                                        }), scheme[0])));
                            break;
                        case /* Alias */1 :
                            var scheme$1 = dt[0];
                            s = Lang_typing_print$BwaxAdmin.string_of_scheme(/* [] */0, scheme$1) + (" " + Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                                          return Lang_typing_print$BwaxAdmin.string_of_var(/* [] */0, param);
                                        }), scheme$1[0])));
                            break;
                        case /* Opaque */2 :
                            s = "opaque";
                            break;
                        
                      }
                    }
                    return param[0] + (": " + s);
                  }), dts));
}

var is_defs_equal = Lang_eval$BwaxAdmin.is_defs_equal;

var determine_updated_event_dependencies = Record_dependency_resolver$BwaxAdmin.determine_updated_event_dependencies;

var determine_init_dependencies_as_js = Record_dependency_resolver$BwaxAdmin.determine_init_dependencies_as_js;

var string_of_value = Lang_eval$BwaxAdmin.string_of_value;

var prepare_eval_env = Lang_entry_slim$BwaxAdmin.prepare_eval_env;

var evaluate_defs = Lang_entry_slim$BwaxAdmin.evaluate_defs;

var execute_init_to_model_v2 = Lang_entry_slim$BwaxAdmin.execute_init_to_model_v2;

var evaluate_view_v2 = Lang_entry_slim$BwaxAdmin.evaluate_view_v2;

var transform_record = Lang_entry_slim$BwaxAdmin.transform_record;

var transform_plain_record = Lang_entry_slim$BwaxAdmin.transform_plain_record;

var transform_optional_plain_record = Lang_entry_slim$BwaxAdmin.transform_optional_plain_record;

var transform_updates = Lang_entry_slim$BwaxAdmin.transform_updates;

var transform_raw_record = Lang_entry_slim$BwaxAdmin.transform_raw_record;

var pack_tuple_from_array = Lang_entry_slim$BwaxAdmin.pack_tuple_from_array;

exports.parse_code = parse_code;
exports.synthize_type = synthize_type;
exports.eval_defs_to_js = eval_defs_to_js;
exports.defs_to_tree_js = defs_to_tree_js;
exports.is_defs_equal = is_defs_equal;
exports.type_env_to_js = type_env_to_js;
exports.mock_types = mock_types;
exports.parse_and_mock_types = parse_and_mock_types;
exports.build_backend_runtime_modules = build_backend_runtime_modules;
exports.parse_and_prepare_env = parse_and_prepare_env;
exports.prepare_global_tenv = prepare_global_tenv;
exports.prepare_base_typing_env = prepare_base_typing_env;
exports.prepare_typing_env_for_entities = prepare_typing_env_for_entities;
exports.determine_updated_event_dependencies = determine_updated_event_dependencies;
exports.determine_init_dependencies_as_js = determine_init_dependencies_as_js;
exports.string_of_value = string_of_value;
exports.check_expr_with_expected_type = check_expr_with_expected_type;
exports.do_type_expr_with_expected_type = do_type_expr_with_expected_type;
exports.type_expr_with_expected_type = type_expr_with_expected_type;
exports.Never_be_here = Never_be_here;
exports.type_defs_to_js = type_defs_to_js;
exports.parse_and_type_defs_to_js = parse_and_type_defs_to_js;
exports.only_exposed_tyenv = only_exposed_tyenv;
exports.runtime_srcs_js = runtime_srcs_js;
exports.prepare_eval_env = prepare_eval_env;
exports.get_query_srcs_js = get_query_srcs_js;
exports.evaluate_defs = evaluate_defs;
exports.execute_init_to_model_v2 = execute_init_to_model_v2;
exports.evaluate_view_v2 = evaluate_view_v2;
exports.value_to_js = value_to_js;
exports.print_dts_alias = print_dts_alias;
exports.transform_record = transform_record;
exports.transform_plain_record = transform_plain_record;
exports.transform_optional_plain_record = transform_optional_plain_record;
exports.transform_updates = transform_updates;
exports.transform_raw_record = transform_raw_record;
exports.pack_tuple_from_array = pack_tuple_from_array;
exports.get_entity_names_from_typed_ast = get_entity_names_from_typed_ast;
exports.get_entity_names_from_dts = get_entity_names_from_dts;
exports.print_dts = print_dts;
/* runtime_srcs_js Not a pure module */
