// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("./BaseUI.bs.js");
var QrCode$BwaxAdmin = require("./auxiliary/QrCode.bs.js");
var UIRules$BwaxAdmin = require("./UIRules.bs.js");
var Link = require("bwax-ui/page/Link");
var $$Text = require("bwax-ui/basic/Text");
var IconTypes$BwaxAdmin = require("./IconTypes.bs.js");
var GetImageURL = require("bwax-ui/getImageURL");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var ColorTypes$BwaxAdmin = require("./ColorTypes.bs.js");
var Heading = require("bwax-ui/basic/Heading");
var FluidText = require("bwax-ui/basic/FluidText");
var UseScreenSize = require("bwax-ui/auxiliary/useScreenSize");

function jsonToRawRule(json) {
  var match = Js_json.decodeString(json);
  if (match !== undefined) {
    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
              match,
              /* [] */0
            ]);
  } else {
    var match$1 = Js_json.decodeArray(json);
    if (match$1 !== undefined) {
      var l = Belt_List.fromArray(match$1);
      if (l) {
        var match$2 = Js_json.decodeString(l[0]);
        if (match$2 === "backgroundImage") {
          return Belt_Option.flatMap(Belt_List.head(l[1]), (function (i) {
                        return Belt_Option.map(GetImageURL.default(i, "xlarge"), (function (url) {
                                      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                "backgroundImage",
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    url,
                                                    /* [] */0
                                                  ])
                                              ]);
                                    }));
                      }));
        } else {
          return Belt_List.keepMap(l, Js_json.decodeString);
        }
      } else {
        return Belt_List.keepMap(l, Js_json.decodeString);
      }
    } else {
      return ;
    }
  }
}

function extractRules(maybeStyles) {
  if (maybeStyles !== undefined) {
    return Belt_List.keepMap(Belt_List.keepMap(Belt_List.fromArray(maybeStyles), jsonToRawRule), BaseUI$BwaxAdmin.rawToRule);
  } else {
    return /* [] */0;
  }
}

var Widget = { };

var InputWidget = { };

function extractNearby(params, env) {
  return Belt_Option.getWithDefault(Belt_Option.map(params.nearby, (function (nb) {
                    return Belt_List.keepMap(Belt_List.fromArray(Js_dict.entries(nb)), (function (param) {
                                  var widget = param[1];
                                  var el = function (param, pc, param$1, key) {
                                    return Curry._3(env[/* renderWidget */0], widget, pc, key);
                                  };
                                  switch (param[0]) {
                                    case "above" :
                                        return /* `above */Caml_chrome_debugger.polyVar("above", [
                                                  452579101,
                                                  el
                                                ]);
                                    case "behind" :
                                        return /* `behind */Caml_chrome_debugger.polyVar("behind", [
                                                  461385914,
                                                  el
                                                ]);
                                    case "below" :
                                        return /* `below */Caml_chrome_debugger.polyVar("below", [
                                                  811186865,
                                                  el
                                                ]);
                                    case "inFront" :
                                        return /* `inFront */Caml_chrome_debugger.polyVar("inFront", [
                                                  592113284,
                                                  el
                                                ]);
                                    case "onLeft" :
                                        return /* `onLeft */Caml_chrome_debugger.polyVar("onLeft", [
                                                  -365293370,
                                                  el
                                                ]);
                                    case "onRight" :
                                        return /* `onRight */Caml_chrome_debugger.polyVar("onRight", [
                                                  -6182339,
                                                  el
                                                ]);
                                    default:
                                      return ;
                                  }
                                }));
                  })), /* [] */0);
}

function emptyW(params, styles, key, theme, env, pc) {
  return BaseUI$BwaxAdmin.empty(theme, pc, /* [] */0, key);
}

function boxW(params, styles, key, theme, env, pc) {
  var maybeContent = params.content;
  var nearby = extractNearby(params, env);
  var rules = extractRules(styles);
  var inner;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    inner = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    inner = BaseUI$BwaxAdmin.empty;
  }
  if (nearby) {
    return BaseUI$BwaxAdmin.boxWithNearby(rules, inner, nearby, theme, pc, /* [] */0, key);
  } else {
    return BaseUI$BwaxAdmin.box(rules, inner, theme, pc, /* [] */0, key);
  }
}

function pageW(params, styles, key, theme, env, pc) {
  var maybeContent = params.content;
  var nearby = extractNearby(params, env);
  var rules = extractRules(styles);
  var tmp;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    tmp = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    tmp = BaseUI$BwaxAdmin.empty;
  }
  var inner = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      tmp,
      /* [] */0
    ]);
  return BaseUI$BwaxAdmin.page(rules, inner, nearby, theme, pc, /* [] */0, key);
}

function rowW(params, styles, key, theme, env, pc) {
  var maybeContent = params.content;
  var rules = extractRules(styles);
  var tmp;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    tmp = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    tmp = BaseUI$BwaxAdmin.empty;
  }
  var inner = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      tmp,
      /* [] */0
    ]);
  return BaseUI$BwaxAdmin.row(rules, inner, theme, pc, /* [] */0, key);
}

function wrappedRowW(params, styles, key, theme, env, pc) {
  var maybeContent = params.content;
  var rules = extractRules(styles);
  var tmp;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    tmp = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    tmp = BaseUI$BwaxAdmin.empty;
  }
  var inner = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      tmp,
      /* [] */0
    ]);
  return BaseUI$BwaxAdmin.wrappedRow(rules, inner, theme, pc, /* [] */0, key);
}

function columnW(params, styles, key, theme, env, pc) {
  var maybeContent = params.content;
  var rules = extractRules(styles);
  var tmp;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    tmp = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    tmp = BaseUI$BwaxAdmin.empty;
  }
  var inner = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      tmp,
      /* [] */0
    ]);
  return BaseUI$BwaxAdmin.column(rules, inner, theme, pc, /* [] */0, key);
}

function paragraphW(params, styles, key, theme, env, pc) {
  var maybeContent = params.content;
  var rules = extractRules(styles);
  var tmp;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    tmp = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    tmp = BaseUI$BwaxAdmin.empty;
  }
  var inner = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      tmp,
      /* [] */0
    ]);
  return BaseUI$BwaxAdmin.paragraph(rules, inner, theme, pc, /* [] */0, key);
}

function textW(params, styles, key, theme, env, pc) {
  var rules = extractRules(styles);
  var value = Belt_Option.getWithDefault(params.text, "");
  var tpl = params.tpl;
  var typeRules = Belt_Option.getWithDefault(Belt_Option.map(tpl, (function (t) {
              switch (t) {
                case "body" :
                    return BaseUI$BwaxAdmin.TextTpl.body;
                case "bodyEm" :
                    return BaseUI$BwaxAdmin.TextTpl.bodyEm;
                case "helper" :
                    return BaseUI$BwaxAdmin.TextTpl.helper;
                case "quote" :
                    return BaseUI$BwaxAdmin.TextTpl.quote;
                case "small" :
                    return BaseUI$BwaxAdmin.TextTpl.small;
                case "smallEm" :
                    return BaseUI$BwaxAdmin.TextTpl.smallEm;
                default:
                  return /* [] */0;
              }
            })), /* [] */0);
  return BaseUI$BwaxAdmin.text(Belt_List.concat(typeRules, rules), value, theme, pc, /* [] */0, key);
}

function fTextW(params, styles, key, theme, env, pc) {
  var rules = extractRules(styles);
  var value = Belt_Option.getWithDefault(params.text, "");
  var tpl = params.tpl;
  var typeRules = Belt_Option.getWithDefault(Belt_Option.map(tpl, (function (t) {
              switch (t) {
                case "display" :
                    return BaseUI$BwaxAdmin.TextTpl.fluidDisplay;
                case "helper" :
                    return BaseUI$BwaxAdmin.TextTpl.fluidHelper;
                case "quote" :
                    return BaseUI$BwaxAdmin.TextTpl.fluidQuote;
                default:
                  return BaseUI$BwaxAdmin.TextTpl.fluid;
              }
            })), /* [] */0);
  return BaseUI$BwaxAdmin.text(Belt_List.concat(typeRules, rules), value, theme, pc, /* [] */0, key);
}

function imageW(params, styles, key, theme, env, pc) {
  var rules = extractRules(styles);
  var value = params.image;
  var size = Belt_Option.getWithDefault(params.size, "large");
  var description = params.description;
  var maybeSrc = GetImageURL.default(value, size);
  if (maybeSrc !== undefined) {
    return BaseUI$BwaxAdmin.image(rules, /* record */Caml_chrome_debugger.record([
                  "src",
                  "description"
                ], [
                  maybeSrc,
                  description
                ]), theme, pc, /* [] */0, key);
  } else {
    return BaseUI$BwaxAdmin.image(rules, /* record */Caml_chrome_debugger.record([
                  "src",
                  "description"
                ], [
                  "",
                  description
                ]), theme, pc, /* [] */0, key);
  }
}

function headingW(params, styles, key, theme, env, pc) {
  var level = Belt_Option.getWithDefault(params.level, 4);
  var unbold = Belt_Option.getWithDefault(params.unbold, false);
  var value = Belt_Option.getWithDefault(params.text, "");
  var boldRules = unbold ? /* [] */0 : /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* textBold */-1005136686,
        /* [] */0
      ]);
  var rules = extractRules(styles);
  var combinedRules = Belt_List.concat(Belt_List.concat(BaseUI$BwaxAdmin.TextTpl.heading(level), boldRules), rules);
  return BaseUI$BwaxAdmin.text(combinedRules, value, theme, pc, /* [] */0, key);
}

function fHeadingW(params, styles, key, theme, env, pc) {
  var level = Belt_Option.getWithDefault(params.level, 4);
  var unbold = Belt_Option.getWithDefault(params.unbold, false);
  var value = Belt_Option.getWithDefault(params.text, "");
  var boldRules = unbold ? /* [] */0 : /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* textBold */-1005136686,
        /* [] */0
      ]);
  var rules = extractRules(styles);
  var combinedRules = Belt_List.concat(Belt_List.concat(BaseUI$BwaxAdmin.TextTpl.fHeading(level), rules), boldRules);
  return BaseUI$BwaxAdmin.text(combinedRules, value, theme, pc, /* [] */0, key);
}

function buttonW(params, styles, key, theme, env, pc) {
  var onPress = Belt_Option.getWithDefault(params.onPress, (function (param) {
          return Promise.resolve(/* () */0);
        }));
  var maybeContent = params.content;
  var maybeLabel = params.label;
  var disabled = Belt_Option.getWithDefault(params.disabled, false);
  var unstyled = Belt_Option.getWithDefault(params.unstyled, false);
  var defaultRules_000 = disabled ? /* `opacity */Caml_chrome_debugger.polyVar("opacity", [
        -345678389,
        0.4
      ]) : /* `opacity */Caml_chrome_debugger.polyVar("opacity", [
        -345678389,
        1.0
      ]);
  var defaultRules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      defaultRules_000,
      /* [] */0
    ]);
  var rules = Belt_List.concat(defaultRules, extractRules(styles));
  var inner;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    inner = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    var partial_arg = Belt_Option.getWithDefault(maybeLabel, "点击");
    var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* centerY */434500836,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* textCenter */1019979362,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* centerX */434500835,
                /* [] */0
              ])
          ])
      ]);
    inner = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.text(partial_arg$1, partial_arg, param, param$1, param$2, param$3);
      });
  }
  return BaseUI$BwaxAdmin.button(rules, /* record */Caml_chrome_debugger.record([
                "onPress",
                "disabled",
                "unstyled"
              ], [
                onPress,
                disabled,
                unstyled
              ]), inner, theme, pc, /* [] */0, key);
}

function linkW(params, styles, key, theme, env, pc) {
  var to_ = params.to_;
  var maybeContent = params.content;
  var rules = Belt_List.concat(/* [] */0, extractRules(styles));
  var inner;
  if (maybeContent !== undefined) {
    var content = Caml_option.valFromOption(maybeContent);
    inner = (function (param, pc, param$1, key) {
        return Curry._3(env[/* renderWidget */0], content, pc, key);
      });
  } else {
    inner = BaseUI$BwaxAdmin.empty;
  }
  return BaseUI$BwaxAdmin.link(rules, /* record */Caml_chrome_debugger.record([
                "to_",
                "openWithNewTab"
              ], [
                to_,
                false
              ]), inner, theme, pc, /* [] */0, key);
}

function BaseWidgets$RightAwayBridge(Props) {
  var onReady = Props.onReady;
  React.useEffect((function () {
          Curry._1(onReady, /* () */0);
          return ;
        }), ([]));
  return null;
}

var RightAwayBridge = {
  make: BaseWidgets$RightAwayBridge
};

function rightAwayW(params, styles, key, theme, env, pc) {
  var onReady = Belt_Option.getWithDefault(params.onReady, (function (param) {
          return /* () */0;
        }));
  return React.createElement(BaseWidgets$RightAwayBridge, {
              onReady: onReady,
              key: key
            });
}

function BaseWidgets$SideEffectBridge(Props) {
  var onFire = Props.onFire;
  var trigger = Props.trigger;
  React.useEffect((function () {
          Curry._1(onFire, /* () */0);
          return ;
        }), /* array */[trigger]);
  return null;
}

var SideEffectBridge = {
  make: BaseWidgets$SideEffectBridge
};

function sideEffectW(params, styles, key, theme, env, pc) {
  var onFire = Belt_Option.getWithDefault(params.onFire, (function (param) {
          return /* () */0;
        }));
  var trigger = Belt_Option.map(params.trigger, (function (prim) {
          return JSON.stringify(prim);
        }));
  return React.createElement(BaseWidgets$SideEffectBridge, {
              onFire: onFire,
              trigger: trigger,
              key: key
            });
}

function qrcodeW(params, styles, key, theme, env, pc) {
  var rules = extractRules(styles);
  var toCss = function (r) {
    return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_box */3, pc, /* [] */0);
  };
  var className = BaseUI$BwaxAdmin.buildClassName("", rules, toCss);
  var text = Belt_Option.getWithDefault(params.text, "none");
  var size = Belt_Option.getWithDefault(params.qrSize, 128);
  return React.createElement(QrCode$BwaxAdmin.make, {
              value: text,
              size: size,
              className: className,
              key: key
            });
}

function iconW(params, styles, key, theme, env, pc) {
  var rules = extractRules(styles);
  var color = Belt_Option.flatMap(params.color, ColorTypes$BwaxAdmin.toColor);
  var iconType = IconTypes$BwaxAdmin.toIconType(params.iconType);
  if (iconType !== undefined) {
    var ic = iconType;
    return BaseUI$BwaxAdmin.box(rules, (function (param, param$1, param$2, param$3) {
                  return BaseUI$BwaxAdmin.icon(undefined, ic, color, param, param$1, param$2, param$3);
                }), theme, pc, /* [] */0, key);
  } else {
    console.log("Warning: the icon type is not recognized", params.iconType);
    return BaseUI$BwaxAdmin.empty(theme, pc, /* [] */0, key);
  }
}

var baseWidgets_000 = /* tuple */[
  "none",
  emptyW
];

var baseWidgets_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "box",
      boxW
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "page",
          pageW
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "row",
              rowW
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "wrappedRow",
                  wrappedRowW
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "column",
                      columnW
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "paragraph",
                          paragraphW
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "text",
                              textW
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  "fText",
                                  fTextW
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      "image",
                                      imageW
                                    ],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        /* tuple */[
                                          "heading",
                                          headingW
                                        ],
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            /* tuple */[
                                              "fHeading",
                                              fHeadingW
                                            ],
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                /* tuple */[
                                                  "button",
                                                  buttonW
                                                ],
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    /* tuple */[
                                                      "link",
                                                      linkW
                                                    ],
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        /* tuple */[
                                                          "rightAway",
                                                          rightAwayW
                                                        ],
                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            /* tuple */[
                                                              "sideEffect",
                                                              sideEffectW
                                                            ],
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                /* tuple */[
                                                                  "qrcode",
                                                                  qrcodeW
                                                                ],
                                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                    /* tuple */[
                                                                      "icon",
                                                                      iconW
                                                                    ],
                                                                    /* [] */0
                                                                  ])
                                                              ])
                                                          ])
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ])
      ])
  ]);

var baseWidgets = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    baseWidgets_000,
    baseWidgets_001
  ]);

var partial_arg = Plate$BwaxAdmin.$$Option.and_then;

function jsonToStr(param) {
  return partial_arg(Js_json.decodeString, param);
}

var partial_arg$1 = Plate$BwaxAdmin.$$Option.map;

function strToJson(param) {
  return partial_arg$1((function (prim) {
                return prim;
              }), param);
}

function jsonToInt(j) {
  return Plate$BwaxAdmin.$$Option.map((function (prim) {
                return prim | 0;
              }), Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeNumber, j));
}

function intToJson(i) {
  return Plate$BwaxAdmin.$$Option.map((function (prim) {
                return prim;
              }), Plate$BwaxAdmin.$$Option.map((function (prim) {
                    return prim;
                  }), i));
}

var partial_arg$2 = Plate$BwaxAdmin.$$Option.and_then;

function jsonToFloat(param) {
  return partial_arg$2(Js_json.decodeNumber, param);
}

var partial_arg$3 = Plate$BwaxAdmin.$$Option.map;

function floatToJson(param) {
  return partial_arg$3((function (prim) {
                return prim;
              }), param);
}

function inputTextW(params, value, onChange, error, disabled, styles, key, theme, env, pc) {
  var placeholder = params.placeholder;
  var rules = extractRules(styles);
  var maxLength = params.maxLength;
  var config = /* record */Caml_chrome_debugger.record(["maxLength"], [maxLength]);
  return BaseUI$BwaxAdmin.inputText(config, jsonToStr(value), (function (j) {
                  return Curry._1(onChange, strToJson(j));
                }), placeholder)(false, rules, theme, pc, /* [] */0, key);
}

function inputNumberW(params, value, onChange, error, disabled, styles, key, theme, env, pc) {
  var placeholder = params.placeholder;
  var rules = extractRules(styles);
  return BaseUI$BwaxAdmin.inputNumber(jsonToInt(value), (function (j) {
                  return Curry._1(onChange, intToJson(j));
                }), placeholder)(false, rules, theme, pc, /* [] */0, key);
}

function inputDecimalW(params, value, onChange, error, disabled, styles, key, theme, env, pc) {
  var placeholder = params.placeholder;
  var rules = extractRules(styles);
  return BaseUI$BwaxAdmin.inputDecimal(jsonToFloat(value), (function (j) {
                  return Curry._1(onChange, floatToJson(j));
                }), placeholder)(false, rules, theme, pc, /* [] */0, key);
}

function inputRadioW(params, value, onChange, error, disabled, styles, key, theme, env, pc) {
  var options = Belt_List.fromArray(params.options);
  var match = params.align;
  var align = match === "row" ? /* row */5693978 : /* column */-963948842;
  var rules = extractRules(styles);
  var optionRules = extractRules(params.optionStyles);
  var config_003 = /* options */Belt_List.map(options, (function (o) {
          return BaseUI$BwaxAdmin.option(o.value, o.label);
        }));
  var config_005 = /* color */Belt_Option.flatMap(params.color, ColorTypes$BwaxAdmin.toColor);
  var config = /* record */Caml_chrome_debugger.record([
      "align",
      "selected",
      "onChange",
      "options",
      "disabled",
      "color"
    ], [
      align,
      value,
      onChange,
      config_003,
      disabled,
      config_005
    ]);
  return BaseUI$BwaxAdmin.inputRadio(rules, optionRules, config, theme, pc, /* [] */0, key);
}

function inputCheckboxW(params, value, onChange, error, disabled, styles, key, theme, env, pc) {
  var rules = extractRules(styles);
  var checked = Belt_Option.getWithDefault(Belt_Option.flatMap(value, Js_json.decodeBoolean), false);
  var config_001 = function (c) {
    return Curry._1(onChange, Caml_option.some(c));
  };
  var config_003 = /* color */Belt_Option.flatMap(params.color, ColorTypes$BwaxAdmin.toColor);
  var config = /* record */Caml_chrome_debugger.record([
      "checked",
      "onChange",
      "disabled",
      "color"
    ], [
      checked,
      config_001,
      disabled,
      config_003
    ]);
  return BaseUI$BwaxAdmin.inputCheckbox(rules, config, theme, pc, /* [] */0, key);
}

function inputCheckboxesW(params, value, onChange, error, disabled, styles, key, theme, env, pc) {
  var options = Belt_List.fromArray(params.options);
  var match = params.align;
  var align = match === "row" ? /* row */5693978 : /* column */-963948842;
  var rules = extractRules(styles);
  var optionRules = extractRules(params.optionStyles);
  var config_001 = /* selected */Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(value, Js_json.decodeArray), Belt_List.fromArray), /* [] */0);
  var config_002 = function (l) {
    return Curry._1(onChange, Caml_option.some(Belt_List.toArray(l)));
  };
  var config_003 = /* options */Belt_List.map(options, (function (o) {
          return BaseUI$BwaxAdmin.option(o.value, o.label);
        }));
  var config_005 = /* color */Belt_Option.flatMap(params.color, ColorTypes$BwaxAdmin.toColor);
  var config = /* record */Caml_chrome_debugger.record([
      "align",
      "selected",
      "onChange",
      "options",
      "disabled",
      "color"
    ], [
      align,
      config_001,
      config_002,
      config_003,
      disabled,
      config_005
    ]);
  return BaseUI$BwaxAdmin.inputCheckboxes(rules, optionRules, config, theme, pc, /* [] */0, key);
}

var baseInputWidgets_000 = /* tuple */[
  "inputRadio",
  inputRadioW
];

var baseInputWidgets_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "inputText",
      inputTextW
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "inputNumber",
          inputNumberW
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "inputDecimal",
              inputDecimalW
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "inputCheckbox",
                  inputCheckboxW
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "inputCheckboxes",
                      inputCheckboxesW
                    ],
                    /* [] */0
                  ])
              ])
          ])
      ])
  ]);

var baseInputWidgets = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    baseInputWidgets_000,
    baseInputWidgets_001
  ]);

function useScreenSize(param) {
  var size = UseScreenSize.default();
  if (size === "S") {
    return /* small */-9768761;
  } else {
    return /* large */-272944197;
  }
}

var ScreenSize = {
  useScreenSize: useScreenSize
};

var Bridge = { };

function BaseWidgets$Link(Props) {
  var to_ = Props.to_;
  var styles = Props.styles;
  var theme = Props.theme;
  var parentContainer = Props.parentContainer;
  var children = Props.children;
  var className = Css.merge(Belt_List.map(styles, (function (r) {
              return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_box */3, Belt_Option.getWithDefault(parentContainer, /* row */5693978), /* [] */0);
            })));
  return React.createElement(Link.default, {
              to_: to_,
              className: className,
              children: children
            });
}

var Link$1 = {
  Bridge: Bridge,
  make: BaseWidgets$Link
};

function BaseWidgets$Avatar(Props) {
  var image = Props.image;
  Props.theme;
  var option = Props.option;
  var imageUrl = GetImageURL.default(image, "medium");
  var match = option[/* style */0];
  var className = "bw-avatar " + Css.style(Belt_List.concat(Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.backgroundSize(/* cover */-899416265),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.backgroundRepeat(/* noRepeat */-695430532),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.backgroundPosition(/* center */98248149),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              UIRules$BwaxAdmin.LimitedCss.width(option[/* width */2]),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  UIRules$BwaxAdmin.LimitedCss.height(option[/* height */1]),
                                  /* [] */0
                                ])
                            ])
                        ])
                    ])
                ]), imageUrl !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Css.backgroundImage(Css.url(imageUrl)),
                    /* [] */0
                  ]) : /* [] */0), match >= -655228771 ? /* [] */0 : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Css.borderRadius(Css.pct(50.0)),
                /* [] */0
              ])));
  return React.createElement("div", {
              className: className
            });
}

var Avatar = {
  make: BaseWidgets$Avatar
};

function BaseWidgets$CoverImage(Props) {
  var cover = Props.cover;
  var width = Props.width;
  var heightRatio = Props.heightRatio;
  var match = Props.scaleOnHover;
  var scaleOnHover = match !== undefined ? match : 1.0;
  var children = Props.children;
  var match$1 = Props.radius;
  var radius = match$1 !== undefined ? match$1 : 0.25;
  var screen = useScreenSize(/* () */0);
  var coverUrl = GetImageURL.default(cover, "xlarge");
  var w;
  w = width.tag && screen < -9768761 ? width[1] : width[0];
  var imageBox = "image-box " + Css.style(Belt_List.concat(Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.width(Css.pct(100.0)),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.height(Css.pct(100.0)),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.backgroundSize(/* cover */-899416265),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.backgroundRepeat(/* noRepeat */-695430532),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.hover(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Css.transform(Css.scale(scaleOnHover, scaleOnHover)),
                                          /* [] */0
                                        ])),
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Css.transition(700, undefined, undefined, "all"),
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Css.backgroundPosition(/* center */98248149),
                                          /* [] */0
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]), coverUrl !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Css.backgroundImage(Css.url(coverUrl)),
                    /* [] */0
                  ]) : /* [] */0), Belt_List.keepMap(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Belt_Option.map(heightRatio, (function (hr) {
                          return Css.paddingBottom(Css.pct(hr * 100.0));
                        })),
                  /* [] */0
                ]), (function (x) {
                  return x;
                }))));
  var coverImage = "cover-image " + Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.borderRadius(Css.rem(radius)),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.overflow(/* hidden */-862584982),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.width(w),
                  /* [] */0
                ])
            ])
        ]));
  var imageBox$1 = children !== undefined ? React.createElement("div", {
          className: imageBox
        }, Caml_option.valFromOption(children)) : React.createElement("div", {
          className: imageBox
        });
  return React.createElement("div", {
              className: coverImage
            }, imageBox$1);
}

var CoverImage = {
  make: BaseWidgets$CoverImage
};

var Bridge$1 = { };

function toString(v) {
  if (v >= -571333828) {
    if (v >= 659075814) {
      if (v >= 1064508101) {
        return "body-small";
      } else {
        return "caption";
      }
    } else if (v >= 72252782) {
      return "helper";
    } else {
      return "quote";
    }
  } else if (v !== -696036723) {
    if (v >= -622255190) {
      return "body-em";
    } else {
      return "body";
    }
  } else {
    return "body-small-em";
  }
}

var TextType = {
  toString: toString
};

function BaseWidgets$Text(Props) {
  var maxLine = Props.maxLine;
  var type_ = Props.type_;
  var color = Props.color;
  var text = Props.text;
  var theme = Props.theme;
  return React.createElement($$Text.default, {
              params: {
                maxLine: maxLine,
                type: Belt_Option.map(type_, toString),
                color: Belt_Option.map(color, ColorTypes$BwaxAdmin.toString),
                text: text
              },
              theme: theme
            });
}

var $$Text$1 = {
  Bridge: Bridge$1,
  TextType: TextType,
  make: BaseWidgets$Text
};

var Bridge$2 = { };

function toString$1(v) {
  if (v !== 72252782) {
    if (v >= 994716226) {
      return "display";
    } else {
      return "p";
    }
  } else {
    return "helper";
  }
}

var TextType$1 = {
  toString: toString$1
};

function toString$2(v) {
  if (v !== 98248149) {
    if (v >= 563173061) {
      return "align";
    } else {
      return "left";
    }
  } else {
    return "center";
  }
}

var AlignType = {
  toString: toString$2
};

function BaseWidgets$FluidText(Props) {
  var maxLine = Props.maxLine;
  var type_ = Props.type_;
  var color = Props.color;
  var align = Props.align;
  var text = Props.text;
  var theme = Props.theme;
  return React.createElement(FluidText.default, {
              params: {
                maxLine: maxLine,
                type: Belt_Option.map(type_, toString$1),
                color: Belt_Option.map(color, ColorTypes$BwaxAdmin.toString),
                align: Belt_Option.map(align, toString$2),
                text: text
              },
              theme: theme
            });
}

var FluidText$1 = {
  Bridge: Bridge$2,
  TextType: TextType$1,
  AlignType: AlignType,
  make: BaseWidgets$FluidText
};

var Bridge$3 = { };

function BaseWidgets$Heading(Props) {
  var text = Props.text;
  var color = Props.color;
  var level = Props.level;
  var maxLine = Props.maxLine;
  var theme = Props.theme;
  return React.createElement(Heading.default, {
              params: {
                maxLine: maxLine,
                color: Belt_Option.map(color, ColorTypes$BwaxAdmin.toString),
                level: level,
                text: text
              },
              theme: theme
            });
}

var Heading$1 = {
  Bridge: Bridge$3,
  make: BaseWidgets$Heading
};

exports.jsonToRawRule = jsonToRawRule;
exports.extractRules = extractRules;
exports.Widget = Widget;
exports.InputWidget = InputWidget;
exports.extractNearby = extractNearby;
exports.emptyW = emptyW;
exports.boxW = boxW;
exports.pageW = pageW;
exports.rowW = rowW;
exports.wrappedRowW = wrappedRowW;
exports.columnW = columnW;
exports.paragraphW = paragraphW;
exports.textW = textW;
exports.fTextW = fTextW;
exports.imageW = imageW;
exports.headingW = headingW;
exports.fHeadingW = fHeadingW;
exports.buttonW = buttonW;
exports.linkW = linkW;
exports.RightAwayBridge = RightAwayBridge;
exports.rightAwayW = rightAwayW;
exports.SideEffectBridge = SideEffectBridge;
exports.sideEffectW = sideEffectW;
exports.qrcodeW = qrcodeW;
exports.iconW = iconW;
exports.baseWidgets = baseWidgets;
exports.jsonToStr = jsonToStr;
exports.strToJson = strToJson;
exports.jsonToInt = jsonToInt;
exports.intToJson = intToJson;
exports.jsonToFloat = jsonToFloat;
exports.floatToJson = floatToJson;
exports.inputTextW = inputTextW;
exports.inputNumberW = inputNumberW;
exports.inputDecimalW = inputDecimalW;
exports.inputRadioW = inputRadioW;
exports.inputCheckboxW = inputCheckboxW;
exports.inputCheckboxesW = inputCheckboxesW;
exports.baseInputWidgets = baseInputWidgets;
exports.ScreenSize = ScreenSize;
exports.Link = Link$1;
exports.Avatar = Avatar;
exports.CoverImage = CoverImage;
exports.$$Text = $$Text$1;
exports.FluidText = FluidText$1;
exports.Heading = Heading$1;
/* Css Not a pure module */
