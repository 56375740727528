

import React from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { getColorScheme, autoFormatValue, autoFillValues, autoSortValues } from './chartUtils';


export default function LineChartComponent({ data: passedInData, config, fieldSettings = {} }) {

    const { fields, colorScheme = "bwax", title, autoFill, atMost } = config;

    const COLORS = getColorScheme(colorScheme);

    function renderHead() {
        if (title) {
            return (
                <div className="widget-head">
                    <div className="widget-title">
                        { title }
                    </div>
                </div>
            )
        }
        return null
    }

    // process data: format, reverse 
    function processData() {
        const { axis } = fields;
        const fieldSetting = fieldSettings[axis];
        
        function fillTheGaps(data) {
            if(autoFill && fieldSettings) {
                return autoFillValues(data, autoFill, axis, fieldSettings);
            } else {
                return data
            }
        }

        // format axis
        function formatAxis(data) {
            if(fieldSetting) {
                if(data) {
                    return data.map(d => {
                        return {
                            ...d,
                            [axis]: autoFormatValue(d[axis], fieldSetting)
                        }
                    })
                }
            } else {
                return data
            }
        }

        function sortByAxis(data) {
            if(fieldSetting) {
                return autoSortValues(data, fieldSetting, axis);
            } else {
                return data;
            }
        }

        const sortedData = sortByAxis(formatAxis(fillTheGaps(passedInData)));
        if(atMost) {
            const toBeTrim = sortedData.length > atMost ? sortedData.length - atMost : 0;

            return sortedData.slice(toBeTrim);

        } else {
            return sortedData
        }
    }

    const data = processData();
    const withHead = !!title ;

    return (
        <div className={"admin-widget admin-chart admin--line-chart" + (withHead ? " with-head" : "")} style={{
            minHeight: withHead ? 400 : 320
        }}>
            { renderHead()}
            <div className="widget-body">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={fields.axis} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={fields.value} stroke={COLORS[0]} dot={false} />
                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </LineChart>
            </ResponsiveContainer>
            </div>
        </div>
    )
}
