
import React, { useEffect, useContext, useState } from "react";

import loadSandboxDefinitions from 'bwax-ui/legacy/store/loaders/loadSandboxDefinitions'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import ApplicationContext from 'bwax/util/ApplicationContext';

import InputSelect from 'Client/js/ui/components/InputSelect';

export default function EventFieldNameInput (props) {

    const { entityName, multivalued, value, onChange } = props;

    const [ entities, setEntities ] = useState([]);

    const dlc = useContext(DataLoaderContext);
    const env = { sessionToken: dlc.sessionToken, sandbox: true };

    const { currentApplication } = useContext(ApplicationContext);

    useEffect(() => {
        (async () => {
            const { allEntities } = await loadSandboxDefinitions(currentApplication.code)(env);
            setEntities(allEntities);
        })()
    }, [ currentApplication.code ]);


    const entity = entities.find(e => e.name === entityName);
    const excludedFieldNames = ["创建时间", "修改时间", "创建者", "修改者"];

    const fieldNames = (() => {
        if(entity) {
            const { fields, virtualFields } = entity;
            const fieldNames = fields.map(f => f.name).filter(n => excludedFieldNames.indexOf(n) === -1);
            const matFieldNames = virtualFields.filter(f => f.materialized).map(f => f.name);
            return [...fieldNames, ...matFieldNames];
        } else {
            return []
        }
    })();

    return (
        <InputSelect {...{
            value, onChange, style: { width: "100%" },
            isMulti: multivalued, isSearchable: true,

            options: fieldNames,
        }} />
    )



}
