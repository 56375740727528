import React, { useState, useContext, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Upload, Modal } from 'antd'

import Message from 'Client/js/ui/Message';

import AutoImage from 'Client/js/components/AutoImage'

import { Icon } from '@ant-design/compatible';

import './PicturesWall.less'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery'
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile'

import getImageURL from 'bwax-ui/getImageURL'
import { moveTo } from 'bwax/list'

const defaultAccpet = "image/png, image/jpeg, image/gif, image/bmp"

export default function PicturesWall(props) {

    const {
        value, editing = false, itemStyle,
        width = 120, height = 120,
        processor, accept,
        isPublic, uploadFor, onChange, multivalued
    } = props

    const images = Array.isArray(value) ? value : (
        value ? [value] : []
    )

    //// status
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState(null)
    const [uploadStatus, setUploadStatus] = useState('success')
    /// status ends

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)

    const queryRunner = runDataQuery_a({ tenantCode, sessionToken, sandbox })

    async function handleBeforeUpload(file) {

        if (file.type.match(/image/)) {

            const result = await UploadFile({ queryRunner })({
                file,
                isPublic,
                uploadFor,
            })

            if (!result) {
                /// TODO error handling
                setUploadStatus('failed')
                Message.error("上传出错")
            } else {
                setUploadStatus('success')
                const { id, ...changeAttachment } = result
                const newValue = multivalued ? [
                    ...(value || []),
                    changeAttachment
                ] : changeAttachment
                onChange(newValue)
            }

        } else {
            Message.error("文件格式不对")
        }
    }

    function handleCancel() {
        setPreviewVisible(false)
    }

    function handleDrop(item, toIndex) {
        const fromIndex = images.findIndex(i => getImageURL(i, processor) === item.src)
        const img = images.find(i => getImageURL(i, processor) === item.src)
        const newImages = moveTo(images, img, fromIndex, toIndex)
        onChange(newImages)
    }

    function previewImg(picture) {
        ReactDOM.unstable_batchedUpdates(() => {
            setPreviewImage(picture.url);
            setPreviewVisible(true)
        })
    }

    function delImg(picture) {
        onChange(
            multivalued ? value.filter(n => n.url !== picture.url) : null
        )
    }


    function renderUploadStatus() {
        const uploadButtonStyle = {
            width,
            height,
            ...(uploadStatus === 'failed' ? { fontSize: '0.75rem' } : {})
        }

        const statusView = {
            'uploading': '上传中',
            'failed': (
                <React.Fragment>
                    <p>上传失败</p>
                    <p>请重新上传</p>
                </React.Fragment>
            ),
            'success': <Icon type="plus" className="upload-icon" />
        }

        return (
            <div className={`upload-button ${images.length === 0 ? 'no-img' : ''}`} style={uploadButtonStyle}>
                {statusView[uploadStatus]}
            </div>
        )
    }



    function renderEditMode() {

        return (
            <div className="pictures-wall">
                <div className="layout-row">
                    <DndProvider backend={HTML5Backend} >
                        <ul className="pictures-list edit-mode">
                            {
                                multivalued && images.length > 0 ?
                                    <ImageDrop
                                        width={width}
                                        height={height}
                                        onDrop={item => handleDrop(item, 0)} /> : null
                            }
                            {
                                images.map((picture, idx) => {
                                    const ImageItem = multivalued ? DragImage : EditImageItem
                                    return [
                                        <ImageItem
                                            key={idx}
                                            width={width}
                                            height={height}
                                            src={getImageURL(picture, processor)}
                                            onPreview={() => previewImg(picture)}
                                            onDelete={() => delImg(picture)}
                                        />,
                                        (idx !== images.length - 1) && multivalued ?
                                            <ImageDrop
                                                key={`img${idx}`}
                                                width={width}
                                                height={height}
                                                onDrop={item => handleDrop(item, idx + 1)} /> : null
                                    ]
                                })
                            }
                            {
                                multivalued && images.length > 0 ?
                                    <ImageDrop
                                        width={width}
                                        height={height}
                                        onDrop={item => handleDrop(item, images.length)} /> : null
                            }
                            {
                                multivalued || (!multivalued && images.length === 0) ?
                                    <Upload
                                        accept={accept || defaultAccpet}
                                        showUploadList={false}
                                        beforeUpload={(file) => {
                                            handleBeforeUpload(file)
                                            return false
                                        }}
                                        onChange={() => {
                                            // console.log("Not implement onchange")
                                        }}
                                        disabled={uploadStatus === 'uploading'}
                                    >
                                        {renderUploadStatus(uploadStatus)}
                                    </Upload> : null
                            }
                        </ul>
                    </DndProvider>
                </div>
                {renderPreview()}
            </div>
        )
    }

    function renderPreview() {
        return (
            <Modal open={previewVisible} footer={null}
                onCancel={() => handleCancel()}
            >
                <img style={{ width: '100%' }} src={previewImage} />
            </Modal>
        )
    }


    if (editing) {
        return renderEditMode(value)
    }

    return (
        <div className="pictures-wall">
            <ul className="pictures-list not-edit-mode">
                {
                    images.map((picture, idx) => (
                        <li
                            key={idx}
                            className="picture-item"
                            style={{ ...itemStyle }}
                            onClick={() => previewImg(picture)}
                        >
                            <AutoImage
                                width={width || defaultSize}
                                height={height || defaultSize}
                                src={getImageURL(picture, processor)} />
                        </li>
                    ))
                }
            </ul>
            {renderPreview()}
        </div>
    )
}

const ItemsTypes = {
    DragImage: "DragImage"
}

const EditImageItem = React.forwardRef((props, ref) => {
    const { width, height, src, onDelete, onPreview, style } = props
    const [hovering, setHovering] = useState(false)
    return (
        <li
            ref={ref}
            style={style}
            className={`picture-item ${ hovering ? 'hovering' : ''}`}
            onMouseOver={() => setHovering(true)}
            onMouseOut={() => setHovering(false)} >
            <AutoImage
                width={width}
                height={height}
                src={src} />
            <div className="item-action-scarf">
                <Icon
                    type="eye-o"
                    className="icon"
                    onClick={onPreview}
                />
                <Icon
                    type="delete"
                    className="icon"
                    onClick={onDelete}
                />
            </div>
        </li>
    )
})

function DragImage(props) {

    const { width, height, src, onPreview, onDelete } = props

    const [{ isDragging }, drag] = useDrag({
        type: ItemsTypes.DragImage,
        item: {            
            src
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    })

    const draggingStyle = isDragging ? { display: 'none' } : {}

    return (
        <EditImageItem
            ref={drag}
            style={draggingStyle}
            width={width}
            height={height}
            src={src}
            onPreview={onPreview}
            onDelete={onDelete} />
    )
}

function ImageDrop(props) {

    const { width, height, onDrop } = props

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemsTypes.DragImage,
        drop: (item) => onDrop(item),
        collect: monitor => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }
        }
    })

    const droppingStyle = canDrop && isOver ? {
        backgroundColor: "#e6f7ff",
        borderColor: "#3e8ecd",
        margin: "0 0.5rem 0.5rem 0.5rem",
        width, height
    } : { width: "1rem", height }

    return (
        <li
            ref={drop}
            style={{
                ...droppingStyle,
                float: 'left',
                position: 'relative'
            }}>
        </li>
    )
} 