// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../../bwax-js/ml/plate.bs.js");
var ColorTypes$BwaxAdmin = require("../re/ColorTypes.bs.js");
var Palette = require("bwax-ui/color/Palette");
var LightOrDark = require("bwax-ui/color/lightOrDark");

function is_color_dark(color) {
  return LightOrDark.default(ColorTypes$BwaxAdmin.toString(color)) === "dark";
}

function is_color_light(color) {
  return !is_color_dark(color);
}

function to_color(c) {
  return Plate$BwaxAdmin.$$Option.with_default(/* N20 */3890060, ColorTypes$BwaxAdmin.toColor(c));
}

function lighten(rate, color) {
  return to_color(Palette.lighten(ColorTypes$BwaxAdmin.toString(color), rate));
}

function brighten(rate, color) {
  return to_color(Palette.brighten(ColorTypes$BwaxAdmin.toString(color), rate));
}

function darken(rate, color) {
  return to_color(Palette.darken(ColorTypes$BwaxAdmin.toString(color), rate));
}

function desaturate(rate, color) {
  return to_color(Palette.desaturate(ColorTypes$BwaxAdmin.toString(color), rate));
}

exports.is_color_dark = is_color_dark;
exports.is_color_light = is_color_light;
exports.to_color = to_color;
exports.lighten = lighten;
exports.brighten = brighten;
exports.darken = darken;
exports.desaturate = desaturate;
/* Plate-BwaxAdmin Not a pure module */
