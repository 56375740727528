
import React from 'react'
import { EditorState } from 'draft-js'
import { insertAtomicBlockWithData } from '../../util/EditorUtil'

export function insertMedia(editorState, selection, { mediaType, ...extraData }) {
    const targetSelection = selection ? selection : editorState.getSelection()

    const forcedSelection = EditorState.forceSelection(
        editorState,
        targetSelection
    )

    return insertAtomicBlockWithData(
        forcedSelection, null, ' ',
        {
            type: mediaType,
            ...extraData,
        }
    )
}

export const defaultLOCALVideoOptions = {
    preload: 'auto',
    language: 'zh-cn',
    controls: true,
    aspectRatio: "16:9",
    controlBar: {
        volumePanel: { inline: false }
    }
}
