import React from 'react'

import InputSelect from 'Client/js/ui/components/InputSelect';

const Nil = "__none__"

export default function Select(props) {
    const { value, onChange, params } = props

    const {
        options,
        multivalued,
        fixedValue,
        required,

        excludedValues = [],

    } = params || {};

    const selectOptions = Array.isArray(options) ? [
        ...(options || []).filter(o => excludedValues.indexOf(o.value) === -1),
    ] : []

    const getValue = (value) => {
        if (multivalued) {
            return (value || []).filter(v => excludedValues.indexOf(v) === -1)
        } else {
            return value
        }
    }

    const data = selectOptions.map((o) => ({ label: o.name, value: o.value }))

    return (
        <InputSelect {...{
            options: data,
            value: getValue(value),

            isMulti: multivalued,
            
            isClearable: !required,
            isDisabled: fixedValue !== undefined,

            style: {
                width: "100%"
            },

            onChange: value => {
                onChange(value === Nil ? null : value)
            }
        }} />
    )
}
