

import React, { useState, useRef, useEffect, useContext } from 'react'

import Link from 'bwax-ui/page/Link'

import Avatar from 'bwax-ui/components/Avatar';

import ClampedText from 'bwax-ui/components/ClampedText';

import { MdTranslate, MdOutlineLayersClear } from 'react-icons/md';

import getImageURL from 'bwax-ui/getImageURL'

import { Pressable } from 'bwax-ui/components/Button';

import DropdownMenu from 'bwax-ui/components/DropdownMenu';
import { useTrack } from 'bwax-ui/track';
import LayoutBase from 'bwax-ui/components/LayoutBase';
import classNames from 'classnames';
import BalanceContext, { BalanceContextProvider } from './BalanceContext';

import NotificationNav from '../inbot/components/NotificationNav';
import TranslationInvitation from './TranslationInvitation';

import { addQueryParams } from 'bwax/ml/lang/mod/builtin/StringHelper';

import { logout } from 'bwax-ui/ml/FrontEndHelper';

import Modal, { ModalTrigger } from 'bwax-ui/components/Modal'
import Pay_translate from './Pay_translate';

import { TbSquareLetterA } from "react-icons/tb";

import ContactUs from '../inbot/components/help/ContactUs';

export default function Layout_translate({ data, events, slots, facade, viewEnv }) {

    const { 
        logo, siteName, siteURL, currentUser, balance, mainColor = "violet", supportQrCode,
        preference,
    } = data;
    const { mainContent, menuButtonsRight, } = slots;

    const { reloadPreference } = events;

    function renderSideMenu({ collapsed, isInDrawer }) {
        return (
            <SideMenu {...{
                collapsed, isInDrawer, viewEnv, currentUser, logo, siteName, siteURL, mainColor, facade, supportQrCode,
                preference, reloadPreference
            }} />
        )

    }

    return (
        <BalanceRefresher {...{
            initialValue: balance, currentUserId: currentUser.id, facade, viewEnv,
        }}>
            <LayoutBase {...{
                mainContent, menuButtonsRight,

                menuLeft: (
                    <div className="flex gap-1 px-2">
                        <img className="h-7" src={getImageURL(logo, "thumbnail")} />
                        <ClampedText className="font-medium font-size-16" tipEnabled={false}>{siteName}</ClampedText>
                    </div>
                ),

                renderSideMenu,
            }} />
        </BalanceRefresher>

    )
}


function SideMenu({ 
    collapsed, isInDrawer, currentUser, viewEnv, logo, siteName, siteURL, mainColor, facade, supportQrCode, 
    preference, reloadPreference, 
}) {

    const { webEnv, routeTo } = viewEnv;
    const { currentURLPath } = webEnv;

    const [isPayShown, setIsPayShown] = useState(false);

    const { balance, reloadBalance } = useContext(BalanceContext);

    const [helpModalOpen, setHelpModalOpen] = useState();

    const HelpComponent = {
        "contact": ContactUs,
        "referral": TranslationInvitation
    }[helpModalOpen || "---"]

    function renderHelpModal() {
        return !helpModalOpen || !HelpComponent ? null : (
            <Modal className="max-w-xl px-6 pt-4 pb-8" isMain={true} isOpen={true} contentClassName={"h-full"} onOpenChange={open => {
                if (!open) {
                    setHelpModalOpen();
                }
            }}>
                {
                    closeModal => (
                        <HelpComponent {...{
                            currentUserId: currentUser.id, facade, closeModal, supportQrCode, viewEnv,
                            currentUser,

                            preference, reloadPreference, 
                        }} />
                    )
                }
            </Modal>
        )
    }





    const navItems = [
        { name: "translate", label: "翻译", icon: <MdTranslate />, url: "/u/task" },
        { name: "history", label: "历史", icon: <i className='bx bx-history'></i>, url: "/u/history" },
        viewEnv.domainEnv.isSandbox || true ? { name: "glossary", label: "术语", icon: <TbSquareLetterA />, url: "/u/glossary" } : null,
    ].filter(x => !!x);

    const bottomNavItems = [ 
        { 
            name: "referral", label: "邀请", icon: <i className='bx bx-gift'></i>, onPress: _ => {
                setHelpModalOpen("referral")
            },
            hasUpdates: !(preference && preference.hasReadReferralCode)
        },
        { name: "notifications", label: "消息", icon: <i className='bx bx-bell'></i>, component: NotificationNav }, 
        { 
            name: "contact", label: "客服", icon: <i className='bx bx-user-plus'></i>, onPress: _ => {
                setHelpModalOpen("contact")
            } 
        },
        {
            label: `积分`, name: "pay-in", icon: <i className='bx bx-wallet'></i>, onPress: () => {

                track("layout_start_paying");
                if (webEnv.isWeChat) {

                    const url = addQueryParams({
                        rt: webEnv.currentURL,

                    }, "/ext/pay/checkout");

                    routeTo(url);

                } else {
                    setIsPayShown(true);
                }
               
            }
        },
    ]

    const track = useTrack();

    const meItems = [
        {
            label: `发票`, name: "receipt", icon: <i className='bx bx-receipt'></i>, onSelect: () => {
                routeTo("/u/receipt");
            }
        },
        {
            label: "登出", name: "logout",
            icon: <i className='bx bx-exit'></i>,
            onSelect: () => {
                track("layout_logout");
                const dlc = facade.dlc;
                const { tenantCode, sandbox } = dlc;

                logout(tenantCode, sandbox);
                routeTo("/");
            }
        }
    ];

    const phoneNumber = currentUser.phoneNumber || "";
    const nickName = currentUser.nickName || phoneNumber.slice(-6) || "用户";
    const avatar = <Avatar nickName={nickName} avatar={currentUser.avatar} size={22} />;

    function renderItem({ icon, label, url, onPress, menuItems, hasUpdates, className, component: Component, }) {

        if(Component) {
            return (
                <Component key={label} {...{
                    label, icon, facade,
                    currentUserId: currentUser.id,
                    collapsedMode: collapsed,
                    ModalTrigger,
                }} />
            )
        }

        const isActive = url && currentURLPath.startsWith(url);

        const inner = (
            <div key={label} className={
                classNames("flex gap-2 items-center w-full py-2 hover:bg-[var(--sand4)] rounded cursor-pointer", className, {
                    "justify-center": collapsed,
                    "px-4": !collapsed,
                    "bg-[var(--sand3)]": isActive
                })
            }>
                <div className="flex w-8 flex justify-center relative">
                    {icon}
                    {hasUpdates ?
                        <div className="absolute rounded-[50%] bg-[var(--tomato9)] h-1.5 w-1.5 right-[2px] top-[-1px]" /> : null
                    }
                </div>
                {collapsed ? null : (
                    <div className="flex gap-1 items-center grow">
                        <ClampedText tipEnabled={false}>{label}</ClampedText>
                        {menuItems ? <i className='bx bx-chevron-right'></i> : null}
                    </div>

                )}
            </div>
        )        

        if (url) {
            return (
                <Link to={url} key={label}>{inner}</Link>
            )
        } else if(onPress) { 
            return (
                <Pressable key={label} onPress={onPress}>
                    { inner }
                </Pressable>
            )
        } else if (menuItems) {
            return (
                <DropdownMenu items={menuItems} key={label} placement={isInDrawer ? "top" : "right top"}>
                    {inner}
                </DropdownMenu>
            )
        } else {
            return inner
        }
    }

    return (
        <>
            <div className="h-full flex flex-col justify-between py-2 bg-[var(--sand1)]">
                <div className="flex flex-col gap-2 px-1.5">
                    <Link to={siteURL} openWithNewTab={true} className={
                        classNames("flex gap-1 items-center w-full py-2", {
                            "justify-center": collapsed,
                            "px-3.5": !collapsed,
                        })
                    }>
                        <img className="h-7" src={getImageURL(logo, "thumbnail")} />
                        {collapsed ? null : (
                            <ClampedText className="font-medium font-size-16" tipEnabled={false}>{siteName}</ClampedText>
                        )}
                    </Link>
                    <div className="flex flex-col gap-0.5 py-2">
                        {navItems.map(renderItem)}
                    </div>
                </div>

                <div className="flex flex-col gap-1 py-3 px-1.5">
                    {/* {renderItem(<i className="bx bx-wallet" />, "余额: ￥10")} */}
                    {bottomNavItems.map(renderItem)}
                    {renderItem({ icon: avatar, label: nickName, menuItems: meItems })}
                </div>
            </div>

            {!isPayShown ? null : (
                <Modal className="max-w-xl px-2 pt-2 pb-2" isMain={true} isOpen={true} contentClassName={"h-full"} onOpenChange={open => {
                    if (!open) {
                        setIsPayShown(false);
                    }
                }}>
                    {
                        closeModal => (
                            <Pay_translate {...{
                                data: { currentUserId: currentUser.id, currentUserNickName: currentUser.nickName, balance },
                                events: { reloadBalance },
                                facade, viewEnv,
                                closeModal
                            }} />
                        )
                    }
                </Modal>
            )}
            {renderHelpModal()}
        </>


    )
}



function BalanceRefresher({ initialValue, currentUserId, facade, viewEnv, children }) {
    // 
    const [balance, setBalance] = useState(initialValue || 0); // 0

    const balanceRef = useRef();
    balanceRef.current = balance;

    async function loadData() {
        const [result, error] = await facade.findOne({
            entityName: "账户余额", condition: [[{ field: "用户.id", op: "eq", value: currentUserId }]],
            fieldPaths: ["剩余数量"]
        }, { forceRefreshing: true })

        if (error) {
            // error todo:
        }
        if (result) {
            setBalance(result.剩余数量)
            return result.剩余数量
        }

    }

    useEffect(() => {
        const interval = viewEnv.domainEnv.isSandbox ? 60000 : 5000;

        // 一上来要先 load 一下
        loadData();
        const timerId = setInterval(() => {
            loadData()
        }, interval);

        return () => {
            clearInterval(timerId);
        }
    }, []);

    function reloadBalance() {
        return loadData();
    }

    return (
        <BalanceContextProvider {...{
            balance, reloadBalance
        }}>
            {children}
        </BalanceContextProvider>
    )

}

