import React, { useState, useEffect, useRef } from 'react'
import { Menu } from 'antd'

import { getSearchText, decodeOffsetKey, addMention } from './mentionPluginHelper'
import { evaluateWithQuery } from 'bwax'
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

export default function MentionSuggestion(props) {

    const {
        store,
        mentionTrigger,
        offsetKey,

        allEntities,
        allDataTypes,
        tenantCode,
        sessionToken,
        sandbox,

        suggestionVisible,
        onSuggestionVisibleChange,

        ...MenuProps
    } = props

    const lastAllEntities = useRef(allEntities)

    let stateListener

    const [focusSuggestionIndex, setFocusSuggestionIndex] = useState(() => {
        stateListener = store.editorStateListenerChange(onEditorStateChange, offsetKey)
        return 0
    })
    const [suggestions, setSuggestions] = useState([])
    const [keyword, setKeyword] = useState(null)
    const [selectionIsInsideWord, setSelectionIsInsideWord] = useState(true)

    const debouncedKeyword = useDebounce(keyword, 200)

    useEffect(() => {
        return () => {
            if (stateListener) {
                stateListener.remove(stateListener.id)
            }
            removeListeners()
        }
    }, [])

    useEffect(() => {
        if (debouncedKeyword !== null) {
            handleSearch(debouncedKeyword)
        }
    }, [debouncedKeyword])

    useEffect(() => {
        if (!lastAllEntities.current) {
            lastAllEntities.current = allEntities
            stateListener = store.editorStateListenerChange(onEditorStateChange, offsetKey)
        }
    }, [allEntities])

    function registerListeners() {
        store.registerKeyBindingListeners(suggestionKeyBindingFn, offsetKey)
        store.registerReturnListeners(enterToCommitMention, offsetKey)
    }

    function removeListeners() {
        store.removeKeyBindingListeners(offsetKey)
        store.removeReturnListeners(offsetKey)
    }

    function onEditorStateChange(editorState) {

        const selection = editorState.getSelection()
        const anchorKey = selection.getAnchorKey()
        const anchorOffset = selection.getAnchorOffset()

        const { decoratorKey } = decodeOffsetKey(offsetKey)

        // Checks that the cursor is after the @ character but still somewhere inthe word (search term).
        const currentDecorator = editorState.getBlockTree(anchorKey).getIn([decoratorKey])
        if (currentDecorator) {
            const start = currentDecorator.get('start')
            const end = currentDecorator.get('end')
            const decoratorKey = currentDecorator.get('decoratorKey')

            if (decoratorKey === null) {
                removeListeners()
                return
            }

            const selectionIsInsideWord = anchorOffset >= start + mentionTrigger.length &&
                anchorOffset <= end

            if (!selectionIsInsideWord) {
                setSelectionIsInsideWord(false)
                removeListeners()
            }

            if (selectionIsInsideWord) {
                setSelectionIsInsideWord(true)
                const { matchingString: searchValue } = getSearchText(
                    editorState,
                    selection,
                    mentionTrigger
                )

                setKeyword(searchValue)

                registerListeners()
            }
        }
    }

    function handleSearch(searchValue) {
        if (allEntities) {
            const entity = allEntities.find(n => n.key === 'AuthEntityUser')
            const buildDef = setUpSearchDefBuilder(entity)
            const targetDef = buildDef(searchValue)
            findAdminUser(targetDef, setSuggestions, {
                allDataTypes, allEntities, queryTarget: "data",

                sessionToken,
                tenantCode,
                sandbox
            })
        }
    }

    //HACK：在closure里面通过setState的方式获得最新的状态，这里setState并不会重新render
    const getStateFromClosure = (setFn) => {
        let v
        setFn(prev => {
            v = prev
            return prev
        })
        return v
    }

    function enterToCommitMention() {
        const suggestions = getStateFromClosure(setSuggestions)
        const focusSuggestionIndex = getStateFromClosure(setFocusSuggestionIndex)
        if (suggestions !== undefined && focusSuggestionIndex !== undefined) {
            const suggestion = suggestions[focusSuggestionIndex]
            commitMention(suggestion)
            return 'handled'
        } else {
            return 'not-handled'
        }
    }

    function suggestionKeyBindingFn(event) {

        const key = event.which || event.keyCode
        const suggestions = getStateFromClosure(setSuggestions)
        if (key === 40) {
            event.preventDefault()
            setFocusSuggestionIndex(preIdx => {
                const newIndex = preIdx + 1
                return newIndex > suggestions.length - 1 ? 0 : newIndex
            })
        }

        if (key === 38) {
            event.preventDefault()
            setFocusSuggestionIndex(preIdx => {
                const newIndex = preIdx - 1
                return newIndex < 0 ? suggestions.length - 1 : newIndex
            })
        }
    }

    function commitMention(suggestion) {
        if (!suggestion) {
            return
        }
        const newEditorState = addMention(
            store.getEditorState(),
            suggestion,
            '@',
            mentionTrigger,
            'Segmented'
        )

        // HACK: 这里的setTimeout用来解决editorState被覆盖的问题
        // https://draftjs.org/docs/advanced-topics-editorstate-race-conditions/
        setTimeout(() => {
            store.setEditorState(newEditorState)
        }, 30);
    }

    return (
        <Menu
            {...MenuProps}
            contentEditable="false"
            suppressContentEditableWarning={true}
            onClick={(item) => {
                const suggestion = suggestions.find(n => n.key === item.key)
                onSuggestionVisibleChange(false)
                commitMention(suggestion)
            }}>
            {
                (selectionIsInsideWord ? suggestions : []).map((n, idx) => {
                    return (
                        <Menu.Item
                            key={n.key}
                            style={idx === focusSuggestionIndex ? { backgroundColor: "#f0fbff" } : {}}
                            contentEditable="false"
                            suppressContentEditableWarning={true} >
                            <p
                                contentEditable="false"
                                suppressContentEditableWarning={true}>
                                {n.name}
                            </p>
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )
}

async function findAdminUser(def, setSuggestions, {
    allDataTypes, allEntities, queryTarget,

    sessionToken,
    tenantCode,
    sandbox
}) {

    //TODO: error handle
    const result = await evaluateWithQuery(
        def,
        {},
        {
            allDataTypes, allEntities, queryTarget,
            sessionToken,
            tenantCode,
            sandbox
        }
    )

    if (result) {
        setSuggestions(() => result.adminUsers || [])
    }
}

function setUpSearchDefBuilder(entity) {
    return (keyword, pageSize = 6) => {
        return {
            __query__: {
                data: {
                    type: "listAll",
                    entityName: entity.name,
                    pagination: {
                        pageSize,
                    },
                    criteria: [[
                        {
                            "昵称": { like: `%${keyword}%` }
                        },
                        {
                            "系统角色": { contains: "Admin" }
                        }
                    ], [
                        {
                            "昵称": { like: `%${keyword}%` }
                        },
                        {
                            "系统角色": { contains: "Operator" }
                        }
                    ]],
                    sortBy: {
                        创建时间: "DESC"
                    }
                },
                __for__: {
                    data: `\${data}`,
                    adminUsers: {
                        __apply__: {
                            list: `\${data}`,
                            map: {
                                key: `\${value.id}`,
                                name: `\${value.昵称}`
                            }
                        }
                    }
                }
            }
        }
    }
}