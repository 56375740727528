

import './styles/richtext.less'
import './styles/editor.less'

export { default as ArticleEditor } from './ArticleEditor'
export { default as SimpleEditor } from './SimpleEditor'
export { default as BasicEditor } from './BasicEditor'

import { convertToRaw, convertFromRaw, ContentState, EditorState } from 'draft-js'

import initConverters from './initConverters'
import resolveContentType from './resolveContentType'

import { mentionEntityType } from './plugins/mention/mentionPluginHelper'

const { htmlToDraft } = initConverters()

const DraftJS = "DraftJS"
const HTML = "HTML"


export function serializeContent(state) {
    if (!state) return ""

    const contentState = state.getCurrentContent ? state.getCurrentContent() : state

    const content = JSON.stringify(convertToRaw(contentState))

    return [
        JSON.stringify({
            contentType: "DraftJS",
            version: 1
        }),
        content
    ].join('\n')
}

export function findAllMention(str) {
    const editorState = deserializeContent(str)
    const contentState = editorState.getCurrentContent()
    const { entityMap } = convertToRaw(contentState)
    const entities = Object.keys(entityMap).map(k => entityMap[k])
    return entities.filter(e => e.type === mentionEntityType).map(n => n.data.mention)
}

export function deserializeContent(str, baseEditorState) {
    // baseEditorState is used to preserve the artifacts like decorator
    const decorator = baseEditorState && baseEditorState.getDecorator()

    if (!str || typeof (str) !== 'string') {

        if (!decorator) {
            return EditorState.createEmpty()
        } else {
            return EditorState.createEmpty(decorator)
        }
    }

    const { contentType, version, content } = resolveContentType(str)

    if (contentType === HTML) {
        return htmlToDraft(content, decorator)

    } else if (contentType === DraftJS) {
        try {
            return EditorState.createWithContent(
                convertFromRaw(JSON.parse(content)),
                decorator
            )
        } catch (e) {
            return EditorState.createWithContent(
                ContentState.createFromText('<<JSON 解析出错>>'),
                decorator
            )
        }
    } else {
        // unknown type
        return EditorState.createEmpty(decorator)
    }
}

export function isContentEqual(first, second) {
    if (first === second) {
        return true
    }

    if (first && second) {
        const firstContent = first.getCurrentContent()
        const secondContent = second.getCurrentContent()

        return firstContent.getBlockMap().equals(secondContent.getBlockMap())

    } else {
        return false
    }
}
