import React, { useState, useEffect } from 'react'

const historyStackKey = "history-stack"

function getStoredHistoryStack() {
    const historyStackStr = sessionStorage.getItem(historyStackKey)
    if (historyStackStr) {
        try {
            return JSON.parse(historyStackStr)
        } catch (error) {
            console.warn(error.message)
            return null
        }
    }
    return undefined
}

function storeHistoryStack(historyStack) {
    const historyStackStr = JSON.stringify(historyStack)
    sessionStorage.setItem(historyStackKey, historyStackStr)
}

function updateHistoryStack(currentLocation, action) {
    const historyStack = getStoredHistoryStack()
    if (!historyStack) {
        return {
            histories: [currentLocation],
            cursor: 0
        }
    } else {
        const { histories, cursor } = historyStack
        //history action: PUSH 、REPLACE、POP
        if (action === 'PUSH') {
            const remainHistories = cursor === histories.length - 1 ?
                histories : histories.slice(0, cursor + 1)
            const lastRemainHistory = remainHistories[remainHistories.length - 1]
            const updatedHistories = lastRemainHistory.pathname === currentLocation.pathname ? remainHistories : [
                ...remainHistories,
                currentLocation
            ]
            return {
                histories: updatedHistories,
                cursor: updatedHistories.length - 1
            }
        } else if (action === 'POP') {
            //pop并不改变history, 而是移动cursor
            for (let i = histories.length - 1; i >= 0; i--) {
                if (currentLocation.key === histories[i].key) {
                    return {
                        histories,
                        cursor: i
                    }
                }
            }
            return historyStack
        } else if (action === 'REPLACE') {
            return {
                histories: [
                    ...histories.slice(0, cursor),
                    currentLocation,
                    ...histories.slice(cursor + 1)
                ],
                cursor
            }
        } else {
            //action is undefined
            return historyStack
        }
    }
}

export default function useHistoryStack(history) {
    //初始化historyStack
    //state begin
    const [historyStack, setHistoryStack] = useState(() => {
        const initialHistoryStack = updateHistoryStack(history.location, history.action)
        storeHistoryStack(initialHistoryStack)
        return initialHistoryStack
    })
    //state end

    useEffect(() => {

        function handleAction(currentLocation, action) {

            const updatedHistoryStack = updateHistoryStack(currentLocation, action)
            storeHistoryStack(updatedHistoryStack)
            setHistoryStack(updatedHistoryStack)
        }


        history.listen((currentLocation, action) => {

            handleAction(currentLocation, action)
           
        })
    }, [])

    return { historyStack }
}
