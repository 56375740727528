
import { RichUtils, EditorState, SelectionState, Modifier } from 'draft-js'

import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey'

import { detectIndent } from './util/indent'
import { removeBlock } from './util/EditorUtil'
import { toggleCodeBlockType, isCodeBlock } from './plugins/code'

const KeyCommandUtil = {

    onTab: function (editorState, setEditorState) {
        const DEFAULT_INDENTATION = isCodeBlock(editorState) ? "  " : "       "
        const content = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const currentBlock = content.getBlockForKey(selection.getStartKey())

        const indentation = detectIndent(currentBlock.getText()).indent || DEFAULT_INDENTATION
        //const indentation = DEFAULT_INDENTATION

        let newContent
        if (selection.isCollapsed()) {
            newContent = Modifier.insertText(
                content,
                selection,
                indentation
            )
        } else {
            newContent = Modifier.replaceText(
                content,
                selection,
                indentation
            )
        }

        setEditorState(
            EditorState.push(
                editorState,
                newContent,
                'insert-characters'
            )
        )
        return 'handled'
    },

    delete: function (editorState, setEditorState) {
        const startKey = editorState.getSelection().getStartKey()
        const block = editorState.getCurrentContent().getBlockForKey(startKey)

        /// remove current block if it is atomic
        if (block.getType() === 'atomic') {
            setEditorState(
                removeBlock(editorState, startKey)
            )
            return 'handled'
        }

        return null
    },

    code: function (editorState, setEditorState) {
        setEditorState(toggleCodeBlockType(editorState, 'code'))
        return 'handled'
    },

    backspace: function (editorState, setEditorState) {
        const selection = editorState.getSelection()
        const content = editorState.getCurrentContent()
        const startKey = selection.getStartKey()

        //handle delete firstblock when content has more than two blocks
        const firstBlock = content.getFirstBlock()
        const firstBlockKey = firstBlock.get('key')
        const blockMap = content.getBlockMap()

        if (firstBlockKey === startKey && !firstBlock.getText().trim() && blockMap.size > 1) {

            const newBlockMap = blockMap.remove(firstBlock.get('key'))
            let newContentState = content.merge({
                blockMap: newBlockMap
            })

            const newFirstBlock = newContentState.getFirstBlock()
            const newSelection = new SelectionState({
                anchorKey: newFirstBlock.get("key"),
                anchorOffset: 0,
                focusKey: newFirstBlock.get("key"),
                focusOffset: 0,
                isBackward: false
            })

            newContentState = newContentState.merge({
                selectionBefore: newSelection,
                selectionAfter: newSelection
            })

            setEditorState(
                EditorState.push(
                    editorState,
                    newContentState,
                    'remove-range'
                )
            )

            return 'handled'
        }

        //handle delete empty block under a atomic block
        const selectionAtStartOfBlock = selection.isCollapsed() && selection.getAnchorOffset() === 0
            && selection.getFocusOffset() === 0
        const beforeKey = content.getKeyBefore(startKey)
        const beforeBlock = content.getBlockForKey(beforeKey)
        const block = content.getBlockForKey(startKey)

        if (selectionAtStartOfBlock && beforeBlock && beforeBlock.getType() === 'atomic') {
            // set the native selection to the node so the caret is not in the text and
            // the selectionState matches the native selection
            // borrowed from draft-js-focus-plugin
            const offsetKey = DraftOffsetKey.encode(beforeBlock.getKey(), 0, 0)
            const node = document.querySelectorAll(`[data-offset-key="${offsetKey}"]`)[0]
            const selection = window.getSelection()
            const range = document.createRange()
            range.setStart(node, 0)
            range.setEnd(node, 0)
            selection.removeAllRanges()
            selection.addRange(range)

            event.preventDefault()

            // removeblock or forceSelection
            if (!block.getText().trim()) {
                setEditorState(
                    removeBlock(editorState, startKey)
                )
                return 'handled'
            } else if (block.getText()) {
                const newSelection = new SelectionState({
                    anchorKey: beforeKey,
                    anchorOffset: beforeBlock.getLength(),
                    focusKey: beforeKey,
                    focusOffset: beforeBlock.getLength(),
                    isBackward: false
                })

                setEditorState(
                    EditorState.forceSelection(editorState, newSelection)
                )
                return 'handled'
            }
        }

        return null
    }
}

export default function ({ setEditorState, handler }) {

    return (command, editorState) => {
        //external key command handler
        if (handler) {
            const result = handler(command, editorState, setEditorState)
            if (result === 'handled') {
                return 'handled'
            }
        }

        //internal key command handler
        const DefaultKeyCommandHandler = KeyCommandUtil[command]
        if (DefaultKeyCommandHandler && typeof (DefaultKeyCommandHandler) === 'function') {
            const result = DefaultKeyCommandHandler(editorState, setEditorState)
            if (result === 'handled') {
                return 'handled'
            }
        }

        // Draft.js key command handler
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            setEditorState(newState);
            return 'handled'
        }

        return 'not-handled'
    }
}
