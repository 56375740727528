
import React, { useEffect } from 'react';

import { useAdminPage } from 'Client/js/pages/AdminPage';

export default function DefaultPage(props) {

    // load the admin page
    const { history, location, menuGroups } = props;

    const { pathname } = location;

    function getFirstPath (menuGroups) {
        for(const { path, items } of menuGroups[1]) {
            if(path) {
                return path
            }
            if(items) {
                for(const { path } of items) {
                    return path
                }
            }
        }
    }

    useEffect(() => {
        // 为了兼容之前的非 app 开头的 URL
        if (!pathname.startsWith("/app")) {
            history.replace("/app/default" + pathname);
        } else {
            // 跳转到第一个页面
            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1039
            const firstPath = getFirstPath(menuGroups)
            if(firstPath) {
                history.replace(firstPath)
            }
            
            // const p = /\/app\/([^\/]+)(\/(.)*)?/;
            // const s = location.pathname;
            // const path = s.replace(p, "/app/$1/pages/总览");
            // history.replace(path);
        }
    }, [ pathname ] )

    return null;  // 暂时为空吧

}