

import React from 'react';
/// some helper function from plugins
import {insertHyperlink, isHyperlinkable, removeHyperlink, findCurrentHyperlink} from '../hyperlink'

const preventBubblingUp = (event) => {
  event.preventDefault();
}

class HyperlinkInput extends React.Component {

  state = {
    editing: ""
  }
  
  componentDidMount () {
    this.setState({
      editing: this.props.value
    })
  }

  dismiss = () => {
    const {onOverrideContent} = this.props
    if(onOverrideContent) {
      onOverrideContent(null)
    }
  }


  render() {

    return (
      <div className="toolbar-override layout-row" onClick={preventBubblingUp}>
        <input placeholder={"请输入你要链接的网址..."}
          autoFocus={true}
          value={this.state.editing}
          onChange={e => {
            this.setState({editing: e.target.value})
          }}
          onClick={e => e.stopPropagation()}
          onKeyDown={e => {
            if(e.key === 'Enter') {
              preventBubblingUp(e)
              this.props.onEndInput(this.state.editing)
              this.dismiss()
            }
          }}
          />
        <button className="editor-button">
          <i className={"fa fa-close"} aria-hidden="true" onClick={this.dismiss} />
        </button>
      </div>
    )
  }
}


export default function HyperlinkButton (props) {


  const {getEditorState, setEditorState, onOverrideContent } = props

  const { currentURL, atomic } = findCurrentHyperlink(getEditorState())

  const className = currentURL ? " active" : ""

  const component = props => {
    return <HyperlinkInput value={currentURL || ""} {...props} onEndInput={value => {
      setEditorState(
        insertHyperlink(getEditorState(), null, value)
      )
    }} />
  }

  return (
    <div onMouseDown={preventBubblingUp}>
    <button className={"editor-button" + className}
      type="button"
      disabled={!isHyperlinkable(getEditorState())}
      onClick={e => {
       preventBubblingUp(e)
        /// need to enter the Hyperlink
       if(currentURL && !atomic) {
          /// remove Hyperlink
          setEditorState(
            removeHyperlink(getEditorState(), null)
          )

        } else {
          onOverrideContent({
            component,
            persistent: true
          })
        }
      }} >
      <i className={"fa fa-link"} aria-hidden="true"></i>
    </button>
    </div>
  )

}
