import React, { useEffect, useState } from 'react'

import { InputNumber } from 'antd'

import DraggableInput from 'Client/js/components/form/baseInputs/DraggableInput'

import "./Number.less"

function BaseNumberInput(props) {
    const { step, precision, ...remainProps } = props
    return (
        <InputNumber
            {...remainProps}
            className="num-input-control"
            step={step ? step : precision ? (precision / Math.pow(10, precision)) : 1}
            formatter={value => {
                /// 去掉所有不是 "-" 数字 或者小数点的字符
                return typeof (value) === 'string' ? value.replace(/[^-\d\.]/g, '') : value
            }}
        />
    )
}

export default function Number(props) {

    const { value: passedInValue, onChange, params } = props
    const { decimals, multivalued, placeholder } = params || {}
    const precision = (decimals === undefined || decimals === null) ? null : decimals;

    
    let value = passedInValue;
    if (passedInValue !== null && passedInValue !== undefined && multivalued && !Array.isArray(value)) {
        value = [passedInValue];
    }

    const [ editingValue, setEditingValue ] = useState(value);

    useEffect(() => {
        if(value != editingValue) {
            setEditingValue(value)
        }
    }, [value])

    const doChange = v => {
        setEditingValue(v)
        onChange(v)
    }   

    return (
        <DraggableInput
            className="edit-num-input"
            value={editingValue}
            onChange={v => {                
                if (multivalued) {
                    if(Array.isArray(v)) {
                        doChange(v.map(parseFloat).filter(f => !isNaN(f)))
                    } else {
                        doChange(null)
                    }

                } else {
                    const f = parseFloat(v);
                    if(!isNaN(f)) {
                        doChange(f)
                    } else {
                        doChange(null)
                    }
                }
            }}
            multivalued={multivalued}
            draggable={true}
            baseInput={
                <BaseNumberInput
                    precision={precision}
                    placeholder={placeholder}
                />
            }
        />
    )
}
