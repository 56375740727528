

import React, { useEffect, useState } from 'react'

import { unpack, untag } from 'bwax/lang/LangHelper'

import LanguageVis from 'Client/js/visualization/LanguageVis';

import get from 'lodash/get';

import { hashCode } from 'bwax/utils';

export default function CodeCanavs(props) {

    const { typedTree, setPropertyPanelContent, propertyPanelContent } = props;

    // const [ editing, setEditing ] = useState({}); // compiled and ast

    // const compiled = get(value || {}, "代码.compiled");

    // useEffect(() => {
    //     if(compiled && compiled !== editing.compild) {

    //         // onst [n, defs, dts, entityNames] = unpacked;
    //         const [n, defs] = unpack(compiled);            
    //         setEditing({
    //             compiled,
    //             ast: [n, defs],
    //         })
    //     }

    // }, [ compiled ] )

    // if(!editing.ast){
    //     return null
    // }

    if (!typedTree) {
        return null
    }

    /// positions to cache;
    const treeId = hashCode(JSON.stringify(typedTree));

    return (
        <div style={{
            height: "100%",
        }}>
            <LanguageVis tree={typedTree} key={treeId} treeId={treeId}
                setPropertyPanelContent={setPropertyPanelContent}
                propertyPanelContent={propertyPanelContent}
            />
        </div>
    )
}
