
import React, { useEffect, useState } from 'react'

import { Tag } from 'antd'

import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce';

import './RecordFilterDisplayControl.less'

export default function RecordFilterDisplayControl(props) {

    const { value: originalValue, onChange, params, renderWidget } = props
    /// value can only be "JSON", it cannot have things like { "foo": undefined }

    const { conditionFields, applyChange } = params

    const [changedValue, setChangedValue] = useState('__NIL__')

    //// 只有一个等 editing 完成 300 毫秒后，才 re-evaluate
    const debouncedValue = useDebounce(originalValue, 300)
    useEffect(() => {
        /// 只有当修改确实来自于自己，才进行 apply-change
        if (debouncedValue === changedValue && applyChange) {
            applyChange()
        }
    }, [debouncedValue])

    function doChange(newValue) {
        setChangedValue(newValue)
        onChange(newValue)
    }

    const value = originalValue || {}

    const onCloseFilter = key => {

        const newValue = Object.keys(value).reduce((acc, k) => {
            return k === key ? acc : {
                ...acc,
                [k]: value[k]
            }
        }, {})

        doChange(newValue)
    }

    const clearAllFilter = () => {
        doChange({})
    }

    const renderFilterValue = (name, condition) => {
        const field = conditionFields.find(cf => cf.name === name)
        if (!field) return

        const { type, multivalued, options } = field

        const { val } = extractOpValue(condition)
        
        const getOptionName = (value) => {
            const option = ((options && options.options) || []).find(o => o.value === value)
            return option && option.name
        }

        if (type === 'Link') {
            if (Array.isArray(val)) {
                console.warn("Cannot be shown")
                return ""
            } else {
                if(val.display) {
                    return renderWidget(val.display)
                }
            }
        } else {
            const displayVal = (() => {
                if(type === 'Select') {
                    if(val === null) {
                        return '(空)'
                    }
                    return getOptionName(val)
                } else {
                    return val
                }
            })()

            return displayVal
        }
    }

    const hasFilter = Object.keys(value).some(k => value[k] !== undefined)
    if (!hasFilter) {
        return null
    }

    return (
        <div className="record-filter-display-control">
            {
                Object.keys(value).map(key => {
                    const condition = value[key]
                    return (
                        <Tag
                            closable
                            className="filter-display-item"
                            key={key}
                            onClose={() => onCloseFilter(key)}>
                            {renderFilterValue(key, condition)}
                        </Tag>
                    )
                })
            }
            <a className="filter-clear-all" onClick={clearAllFilter}>
                清除所有
            </a>
        </div>
    )
}

/// copied from RecordFilterEditor,  should be made re-used function TODO
function extractOpValue(condition) {
    if (condition === undefined || Object.keys(condition).length === 0) {
        return { op: undefined, val: undefined }
    }
    const op = Object.keys(condition)[0]
    const val = Object.values(condition)[0]
    if (op === 'like' && val !== undefined && val.startsWith('%')) {
        return {
            op,
            val: val.substring(1, val.length - 1)
        }
    }
    return { op, val }
}