import { Modifier, EditorState } from 'draft-js'

export const mentionEntityType = "MENTION"

/**
 * Return tail end of the string matching trigger upto the position.
 */
export const getSearchTextAt = (blockText, position, trigger) => {
    const str = blockText.substr(0, position);
    const begin = trigger.length === 0 ? 0 : str.lastIndexOf(trigger);
    const matchingString =
        trigger.length === 0 ? str : str.slice(begin + trigger.length);
    const end = str.length;

    return {
        begin,
        end,
        matchingString,
    };
};

export const getSearchText = (editorState, selection, trigger, anchorOffset) => {
    const anchorKey = selection.getAnchorKey();
    const position = anchorOffset !== undefined ? anchorOffset : selection.getAnchorOffset();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    return getSearchTextAt(blockText, position, trigger);
};

export const decodeOffsetKey = offsetKey => {
    const [blockKey, decoratorKey, leafKey] = offsetKey.split('-');
    return {
        blockKey,
        decoratorKey: parseInt(decoratorKey, 10),
        leafKey: parseInt(leafKey, 10),
    };
};

export const addMention = (
    editorState,
    mention,
    mentionPrefix,
    mentionTrigger,
    entityMutability
) => {
    const contentStateWithEntity = editorState
        .getCurrentContent()
        .createEntity(mentionEntityType, entityMutability, {
            mention,
        });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const currentSelectionState = editorState.getSelection();
    const { begin, end } = getSearchText(
        editorState,
        currentSelectionState,
        mentionTrigger
    );

    // get selection of the @mention search text
    const mentionTextSelection = currentSelectionState.merge({
        anchorOffset: begin,
        focusOffset: end,
    });

    let mentionReplacedContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        mentionTextSelection,
        `${mentionPrefix}${mention.name}`,
        null, // no inline style needed
        entityKey
    );

    // If the mention is inserted at the end, a space is appended right after for
    // a smooth writing experience.
    const blockKey = mentionTextSelection.getAnchorKey();
    const blockSize = editorState
        .getCurrentContent()
        .getBlockForKey(blockKey)
        .getLength();
    if (blockSize === end) {
        mentionReplacedContent = Modifier.insertText(
            mentionReplacedContent,
            mentionReplacedContent.getSelectionAfter(),
            ' '
        );
    }

    const newEditorState = EditorState.push(
        editorState,
        mentionReplacedContent,
        'insert-characters'
    );
    
    return EditorState.forceSelection(
        newEditorState,
        mentionReplacedContent.getSelectionAfter()
    );
};