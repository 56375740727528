// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var ReactHelmetAsync = require("react-helmet-async");
var Lang_eval$BwaxAdmin = require("../../../bwax-js/ml/lang/lang_eval.bs.js");
var Lang_mod_json$BwaxAdmin = require("../../../bwax-js/ml/lang/mod/builtin/lang_mod_json.bs.js");
var ElementRenderer$BwaxAdmin = require("./ElementRenderer.bs.js");
var WechatConfig = require("bwax-ui/legacy/page/WechatConfig");

function ViewRenderer$WechatConfig(Props) {
  var title = Props.title;
  var image = Props.image;
  var desc = Props.desc;
  var shareUrl = Props.shareUrl;
  var hidden = Props.hidden;
  var wxWorkEnabled = Props.wxWorkEnabled;
  var dlc = Props.dlc;
  var pageID = Props.pageID;
  var paramsStr = Props.paramsStr;
  var setup = function (param) {
    var obj = {
      title: title,
      imageURL: image,
      desc: desc,
      shareURL: shareUrl,
      hidden: hidden,
      wxWorkEnabled: wxWorkEnabled
    };
    WechatConfig.setupWechatShare(obj, dlc);
    return /* () */0;
  };
  React.useEffect((function () {
          setTimeout(setup, 2000);
          return ;
        }), ([]));
  React.useEffect((function () {
          setTimeout(setup, 128);
          return ;
        }), /* array */[Plate$BwaxAdmin.$$Option.with_default("-", title) + (Plate$BwaxAdmin.$$Option.with_default("-", desc) + (Plate$BwaxAdmin.$$Option.with_default("-", shareUrl) + (Plate$BwaxAdmin.$$Option.with_default("-", image) + ((
                  hidden ? ":hidden" : ""
                ) + ((
                    wxWorkEnabled ? ":wxWorkEnabled" : ""
                  ) + ("@" + (pageID + ("_" + paramsStr))))))))]);
  return null;
}

var WechatConfig$1 = {
  make: ViewRenderer$WechatConfig
};

function ViewRenderer(Props) {
  var value = Props.value;
  var dlc = Props.dlc;
  var onMsg = Props.onMsg;
  var getInstanceID = Props.getInstanceID;
  var facade = Props.facade;
  var viewEnv = Props.viewEnv;
  var pageID = Props.pageID;
  var paramsStr = Props.paramsStr;
  var match = value[0];
  var maybePageDef;
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    maybePageDef = undefined;
  } else {
    var binds = match[0];
    var match$1 = Plate$BwaxAdmin.List.assoc("layout", binds);
    if (match$1 !== undefined) {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag !== /* V_raw */7) {
        maybePageDef = undefined;
      } else {
        var head = Plate$BwaxAdmin.List.assoc("head", binds);
        var wechat = Plate$BwaxAdmin.List.assoc("wechat", binds);
        var fontFaces = Plate$BwaxAdmin.List.assoc("fontFaces", binds);
        maybePageDef = /* tuple */[
          match$2[0],
          head,
          wechat,
          fontFaces
        ];
      }
    } else {
      maybePageDef = undefined;
    }
  }
  if (maybePageDef !== undefined) {
    var match$3 = maybePageDef;
    var wechat$1 = match$3[2];
    var head$1 = match$3[1];
    var title = Lang_eval$BwaxAdmin.get_string("title", head$1);
    var keywords = Lang_eval$BwaxAdmin.get_string_list("keywords", head$1);
    var description = Lang_eval$BwaxAdmin.get_string("description", head$1);
    var favicon = Lang_eval$BwaxAdmin.get_string("favicon", head$1);
    var scripts = Lang_eval$BwaxAdmin.get_string_list("scripts", head$1);
    var noscripts = Lang_eval$BwaxAdmin.get_string_list("noscripts", head$1);
    var wechatTitle = Lang_eval$BwaxAdmin.get_string("title", wechat$1);
    var wechatDesc = Lang_eval$BwaxAdmin.get_string("desc", wechat$1);
    var wechatImage = Lang_eval$BwaxAdmin.get_string("image", wechat$1);
    var match$4 = Lang_eval$BwaxAdmin.get_string("shareUrl", wechat$1);
    var wechatShareURL = match$4 !== undefined ? match$4 : Lang_eval$BwaxAdmin.get_string("url", wechat$1);
    var wechatHidden = Plate$BwaxAdmin.$$Option.with_default(false, Lang_eval$BwaxAdmin.get_bool("hidden", wechat$1));
    var wxWorkEnabled = Plate$BwaxAdmin.$$Option.with_default(false, Lang_eval$BwaxAdmin.get_bool("wxWorkEnabled", wechat$1));
    var scalable = Lang_eval$BwaxAdmin.get_bool("scalable", head$1);
    var initialScale = Plate$BwaxAdmin.$$Option.with_default(1.0, Lang_eval$BwaxAdmin.get_float("initialScale", head$1)).toString();
    var orNull = function (proc, mv) {
      if (mv !== undefined) {
        return Curry._1(proc, Caml_option.valFromOption(mv));
      } else {
        return null;
      }
    };
    var tmp;
    var exit = 0;
    if (scalable !== undefined && scalable) {
      tmp = React.createElement("meta", {
            content: "width=device-width,initial-scale=" + (String(initialScale) + ",user-scalable=yes"),
            name: "viewport"
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement("meta", {
            content: "width=device-width,initial-scale=1,maximum-scale=1",
            name: "viewport"
          });
    }
    return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmetAsync.Helmet, {
                    children: null
                  }, orNull((function (t) {
                          return React.createElement("title", undefined, t);
                        }), title), orNull((function (url) {
                          return React.createElement("link", {
                                      href: url,
                                      rel: "shortcut icon",
                                      type: "image/png"
                                    });
                        }), favicon), orNull((function (keywords) {
                          var content = Plate$BwaxAdmin.Str.join(",", keywords);
                          return React.createElement("meta", {
                                      content: content,
                                      name: "keywords"
                                    });
                        }), keywords), orNull((function (content) {
                          return React.createElement("meta", {
                                      content: content,
                                      name: "description"
                                    });
                        }), description), Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (i, s) {
                              return React.createElement("script", {
                                          key: "s" + String(i)
                                        }, s);
                            }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, scripts))), Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (i, s) {
                              return React.createElement("noscript", {
                                          key: "ns" + String(i)
                                        }, s);
                            }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, noscripts))), tmp), React.createElement(ViewRenderer$WechatConfig, {
                    title: wechatTitle,
                    image: wechatImage,
                    desc: wechatDesc,
                    shareUrl: wechatShareURL,
                    hidden: wechatHidden,
                    wxWorkEnabled: wxWorkEnabled,
                    dlc: dlc,
                    pageID: pageID,
                    paramsStr: paramsStr
                  }), React.createElement(ElementRenderer$BwaxAdmin.make, {
                    element: match$3[0],
                    onMsg: onMsg,
                    getInstanceID: getInstanceID,
                    facade: facade,
                    viewEnv: viewEnv,
                    fontFaces: Plate$BwaxAdmin.$$Option.and_then(Lang_mod_json$BwaxAdmin.any_value_to_plain_json, match$3[3]),
                    pageID: pageID
                  }));
  } else {
    return Lang_eval$BwaxAdmin.string_of_value(value);
  }
}

var make = ViewRenderer;

exports.WechatConfig = WechatConfig$1;
exports.make = make;
/* react Not a pure module */
