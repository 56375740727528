

export const fieldOnlySelection =`initializable
    updatable
`

export const commonSelections = `name
    key
    desc
    type
    required
    multivalued
    options
`

export const entitySelections = `
  name
  key
  moduleName
  adminDisplayOptions
  fields { 
    ${commonSelections} 
    ${fieldOnlySelection}
    hidden
    builtIn
    displayWeight
    interfaces {
      name
      key
      interfaceType
      ioTypeMetas
    }
  }
  virtualFields { 
    ${commonSelections} 
    hidden
    displayWeight
    materialized
  }

`