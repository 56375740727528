// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

function eval_error(msg) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        msg
      ];
}

var widget_to_value = Runtime_common$BwaxAdmin.pack_raw;

function gather_entity_names(page) {
  var gather_from_widget = function (param) {
    switch (param.tag | 0) {
      case /* Chart */6 :
          return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    param[1][0],
                    /* [] */0
                  ]);
      case /* Dummy_widget */0 :
      case /* Element_view */1 :
      case /* Custom_widget */7 :
          return /* [] */0;
      default:
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  param[0][0],
                  /* [] */0
                ]);
    }
  };
  var gather_from_widget_box_list = function (box_list) {
    return Plate$BwaxAdmin.List.flat_map((function (param) {
                  return Plate$BwaxAdmin.List.flat_map(gather_from_widget, param[0]);
                }), box_list);
  };
  var gather_from_wide = function (param) {
    if (param.tag) {
      return Plate$BwaxAdmin.List.flat_map(gather_from_widget_box_list, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    param[0],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        param[1],
                        /* [] */0
                      ])
                  ]));
    } else {
      return gather_from_widget_box_list(param[0]);
    }
  };
  var gather_all = function (wide_lists, box_lists) {
    var partial_arg = Plate$BwaxAdmin.List.flat_map;
    return Pervasives.$at(Plate$BwaxAdmin.List.flat_map((function (param) {
                      return partial_arg(gather_from_wide, param);
                    }), wide_lists), Plate$BwaxAdmin.List.flat_map(gather_from_widget_box_list, box_lists));
  };
  var gather_from_layout = function (layout) {
    switch (layout.tag | 0) {
      case /* Wide_and_narrow */0 :
          return gather_all(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        layout[0],
                        /* [] */0
                      ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        layout[1],
                        /* [] */0
                      ]));
      case /* Full */1 :
          return gather_all(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        layout[1],
                        /* [] */0
                      ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        layout[0],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            layout[2],
                            /* [] */0
                          ])
                      ]));
      case /* Widget_and_bar */2 :
          return gather_from_widget_box_list(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              layout[2],
                              /* [] */0
                            ]),
                          undefined
                        ],
                        layout[3]
                      ]));
      case /* Custom_layout */3 :
          return /* [] */0;
      
    }
  };
  var tmp;
  if (typeof page === "number") {
    tmp = /* [] */0;
  } else {
    switch (page.tag | 0) {
      case /* Single */0 :
          tmp = gather_from_layout(page[0]);
          break;
      case /* Multi */1 :
          tmp = Plate$BwaxAdmin.List.flat_map((function (param) {
                  var tab = param[1];
                  if (tab.tag) {
                    return gather_from_widget(tab[0]);
                  } else {
                    return gather_from_layout(tab[0]);
                  }
                }), page[0]);
          break;
      case /* OneWidget */2 :
          tmp = gather_from_widget(page[0]);
          break;
      
    }
  }
  return Plate$BwaxAdmin.List.unique(tmp);
}

function raw_to_widget(v) {
  var match = v[0];
  if (typeof match !== "number" && match.tag === /* V_raw */7) {
    return match[0];
  }
  var msg = "Not a widget: " + Lang_eval$BwaxAdmin.string_of_value(v);
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        msg
      ];
}

function make_widget_box(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    var msg = "Not a widget box: " + Lang_eval$BwaxAdmin.string_of_value(value);
    throw [
          Lang_eval$BwaxAdmin.Eval_exn,
          msg
        ];
  } else {
    var binds = match[0];
    var widgets = Plate$BwaxAdmin.List.map(raw_to_widget, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_list, Plate$BwaxAdmin.List.assoc("widgets", binds))));
    var m_height = Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_int, Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_option, Plate$BwaxAdmin.List.assoc("height", binds)));
    return /* tuple */[
            widgets,
            m_height
          ];
  }
}

function make_widget_box_list(v) {
  var match = v[0];
  if (typeof match !== "number" && match.tag === /* V_list */1) {
    return Plate$BwaxAdmin.List.map(make_widget_box, match[0]);
  }
  var msg = "Not a widget box list: " + Lang_eval$BwaxAdmin.string_of_value(v);
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        msg
      ];
}

function make_wide(value) {
  var not_a_wide = function (param) {
    var msg = "Not a wide container" + Lang_eval$BwaxAdmin.string_of_value(value);
    throw [
          Lang_eval$BwaxAdmin.Eval_exn,
          msg
        ];
  };
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_tagged */6) {
    return not_a_wide(/* () */0);
  } else {
    var sons = match[1];
    switch (match[0]) {
      case "OneColumn" :
          if (sons && !sons[1]) {
            return /* One_column */Caml_chrome_debugger.variant("One_column", 0, [make_widget_box_list(sons[0])]);
          } else {
            return not_a_wide(/* () */0);
          }
      case "TwoColumns" :
          if (sons) {
            var match$1 = sons[1];
            if (match$1 && !match$1[1]) {
              return /* Two_columns */Caml_chrome_debugger.variant("Two_columns", 1, [
                        make_widget_box_list(sons[0]),
                        make_widget_box_list(match$1[0])
                      ]);
            } else {
              return not_a_wide(/* () */0);
            }
          } else {
            return not_a_wide(/* () */0);
          }
      default:
        return not_a_wide(/* () */0);
    }
  }
}

function make_wide_list(v) {
  var match = v[0];
  if (typeof match !== "number" && match.tag === /* V_list */1) {
    return Plate$BwaxAdmin.List.map(make_wide, match[0]);
  }
  var msg = "Not a wide list: " + Lang_eval$BwaxAdmin.string_of_value(v);
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        msg
      ];
}

function make_layout_optional(value) {
  var match = value[0];
  if (typeof match === "number") {
    return ;
  } else {
    switch (match.tag | 0) {
      case /* V_literal */5 :
          var match$1 = match[0];
          if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
            return ;
          } else {
            return /* Custom_layout */Caml_chrome_debugger.variant("Custom_layout", 3, [match$1[0]]);
          }
      case /* V_tagged */6 :
          var sons = match[1];
          switch (match[0]) {
            case "Full" :
                if (sons) {
                  var match$2 = sons[1];
                  if (match$2) {
                    var match$3 = match$2[1];
                    if (match$3 && !match$3[1]) {
                      return /* Full */Caml_chrome_debugger.variant("Full", 1, [
                                make_widget_box_list(sons[0]),
                                make_wide_list(match$2[0]),
                                make_widget_box_list(match$3[0])
                              ]);
                    } else {
                      return ;
                    }
                  } else {
                    return ;
                  }
                } else {
                  return ;
                }
            case "WideAndNarrow" :
                if (sons) {
                  var match$4 = sons[1];
                  if (match$4 && !match$4[1]) {
                    return /* Wide_and_narrow */Caml_chrome_debugger.variant("Wide_and_narrow", 0, [
                              make_wide_list(sons[0]),
                              make_widget_box_list(match$4[0])
                            ]);
                  } else {
                    return ;
                  }
                } else {
                  return ;
                }
            case "WidgetAndBar" :
                if (sons) {
                  var match$5 = sons[1];
                  if (match$5) {
                    var match$6 = match$5[1];
                    if (match$6) {
                      var match$7 = match$6[1];
                      if (match$7 && !match$7[1]) {
                        var match$8 = sons[0][0];
                        var side;
                        side = typeof match$8 === "number" || !(match$8.tag === /* V_tagged */6 && match$8[0] === "Left") ? /* Right */1 : /* Left */0;
                        var width = Plate$BwaxAdmin.$$Option.map(Runtime_common$BwaxAdmin.extract_float, Lang_eval$BwaxAdmin.extract_from_maybe(match$5[0]));
                        return /* Widget_and_bar */Caml_chrome_debugger.variant("Widget_and_bar", 2, [
                                  side,
                                  width,
                                  raw_to_widget(match$6[0]),
                                  make_widget_box_list(match$7[0])
                                ]);
                      } else {
                        return ;
                      }
                    } else {
                      return ;
                    }
                  } else {
                    return ;
                  }
                } else {
                  return ;
                }
            default:
              return ;
          }
      default:
        return ;
    }
  }
}

function make_layout(value) {
  var match = make_layout_optional(value);
  if (match !== undefined) {
    return match;
  } else {
    var msg = "Not a page layout: " + Lang_eval$BwaxAdmin.string_of_value(value);
    throw [
          Lang_eval$BwaxAdmin.Eval_exn,
          msg
        ];
  }
}

function make_page(value) {
  var tuples;
  var match = value[0];
  var exit = 0;
  if (typeof match === "number") {
    exit = 2;
  } else {
    switch (match.tag | 0) {
      case /* V_list */1 :
          tuples = match[0];
          break;
      case /* V_tagged */6 :
          switch (match[0]) {
            case "Multi" :
                var match$1 = match[1];
                if (match$1) {
                  var match$2 = match$1[0][0];
                  if (typeof match$2 === "number" || !(match$2.tag === /* V_list */1 && !match$1[1])) {
                    exit = 2;
                  } else {
                    tuples = match$2[0];
                  }
                } else {
                  exit = 2;
                }
                break;
            case "OneWidget" :
                var match$3 = match[1];
                if (match$3 && !match$3[1]) {
                  return /* OneWidget */Caml_chrome_debugger.variant("OneWidget", 2, [raw_to_widget(match$3[0])]);
                } else {
                  exit = 2;
                }
                break;
            case "Single" :
                var match$4 = match[1];
                if (match$4 && !match$4[1]) {
                  return /* Single */Caml_chrome_debugger.variant("Single", 0, [make_layout(match$4[0])]);
                } else {
                  exit = 2;
                }
                break;
            default:
              exit = 2;
          }
          break;
      default:
        exit = 2;
    }
  }
  if (exit === 2) {
    var match$5 = make_layout_optional(value);
    if (match$5 !== undefined) {
      return /* Single */Caml_chrome_debugger.variant("Single", 0, [match$5]);
    } else {
      return /* OneWidget */Caml_chrome_debugger.variant("OneWidget", 2, [raw_to_widget(value)]);
    }
  }
  var tabs = Plate$BwaxAdmin.List.keep_map((function (param) {
          var match = param[0];
          if (typeof match === "number" || match.tag) {
            return ;
          } else {
            var match$1 = match[0];
            if (match$1) {
              var match$2 = match$1[0][0];
              if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                return ;
              } else {
                var match$3 = match$2[0];
                if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                  return ;
                } else {
                  var match$4 = match$1[1];
                  if (match$4) {
                    var match$5 = match$4[0][0];
                    if (typeof match$5 === "number" || match$5.tag !== /* V_tagged */6) {
                      return ;
                    } else {
                      var match$6 = match$5[1];
                      if (match$6 && !(match$6[1] || match$4[1])) {
                        var v = match$6[0];
                        var tab_type = match$5[0];
                        var name = match$3[0];
                        if (tab_type === "Layout") {
                          return /* tuple */[
                                  name,
                                  /* Layout */Caml_chrome_debugger.variant("Layout", 0, [make_layout(v)])
                                ];
                        } else if (tab_type === "Single") {
                          return /* tuple */[
                                  name,
                                  /* Single */Caml_chrome_debugger.variant("Single", 1, [raw_to_widget(v)])
                                ];
                        } else {
                          return ;
                        }
                      } else {
                        return ;
                      }
                    }
                  } else {
                    return ;
                  }
                }
              }
            } else {
              return ;
            }
          }
        }), tuples);
  return /* Multi */Caml_chrome_debugger.variant("Multi", 1, [tabs]);
}

exports.eval_error = eval_error;
exports.widget_to_value = widget_to_value;
exports.gather_entity_names = gather_entity_names;
exports.raw_to_widget = raw_to_widget;
exports.make_widget_box = make_widget_box;
exports.make_widget_box_list = make_widget_box_list;
exports.make_wide = make_wide;
exports.make_wide_list = make_wide_list;
exports.make_layout_optional = make_layout_optional;
exports.make_layout = make_layout;
exports.make_page = make_page;
/* Plate-BwaxAdmin Not a pure module */
