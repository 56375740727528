// Generated from Bwax.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002P\u0283\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004",
    "\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t",
    "\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017",
    "\t\u0017\u0004\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a",
    "\u0004\u001b\t\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e",
    "\t\u001e\u0004\u001f\t\u001f\u0004 \t \u0004!\t!\u0004\"\t\"\u0004#",
    "\t#\u0004$\t$\u0004%\t%\u0004&\t&\u0004\'\t\'\u0004(\t(\u0004)\t)\u0004",
    "*\t*\u0004+\t+\u0004,\t,\u0004-\t-\u0004.\t.\u0004/\t/\u00040\t0\u0004",
    "1\t1\u00042\t2\u00043\t3\u00044\t4\u00045\t5\u00046\t6\u00047\t7\u0004",
    "8\t8\u00049\t9\u0004:\t:\u0004;\t;\u0004<\t<\u0004=\t=\u0004>\t>\u0004",
    "?\t?\u0004@\t@\u0004A\tA\u0004B\tB\u0004C\tC\u0004D\tD\u0004E\tE\u0004",
    "F\tF\u0004G\tG\u0004H\tH\u0004I\tI\u0004J\tJ\u0004K\tK\u0004L\tL\u0004",
    "M\tM\u0004N\tN\u0004O\tO\u0004P\tP\u0004Q\tQ\u0004R\tR\u0004S\tS\u0004",
    "T\tT\u0004U\tU\u0004V\tV\u0004W\tW\u0004X\tX\u0004Y\tY\u0004Z\tZ\u0004",
    "[\t[\u0004\\\t\\\u0004]\t]\u0004^\t^\u0004_\t_\u0004`\t`\u0003\u0002",
    "\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0005",
    "\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0007\u0003\u0007\u0003\b",
    "\u0003\b\u0003\t\u0003\t\u0003\n\u0003\n\u0003\n\u0003\n\u0003\u000b",
    "\u0003\u000b\u0003\u000b\u0003\f\u0003\f\u0003\r\u0003\r\u0003\u000e",
    "\u0003\u000e\u0003\u000f\u0003\u000f\u0003\u0010\u0003\u0010\u0003\u0011",
    "\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003\u0012\u0003\u0012",
    "\u0003\u0012\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0013",
    "\u0003\u0014\u0003\u0014\u0003\u0014\u0003\u0014\u0003\u0014\u0003\u0015",
    "\u0003\u0015\u0003\u0015\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0016",
    "\u0003\u0017\u0003\u0017\u0003\u0017\u0003\u0017\u0003\u0018\u0003\u0018",
    "\u0003\u0018\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019",
    "\u0003\u001a\u0003\u001a\u0003\u001a\u0003\u001a\u0003\u001a\u0003\u001a",
    "\u0003\u001b\u0003\u001b\u0003\u001b\u0003\u001c\u0003\u001c\u0003\u001c",
    "\u0003\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0003\u001d\u0003\u001d",
    "\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001e",
    "\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e",
    "\u0003\u001e\u0003\u001e\u0003\u001f\u0003\u001f\u0003\u001f\u0003\u001f",
    "\u0003\u001f\u0003\u001f\u0003\u001f\u0003 \u0003 \u0003 \u0003 \u0003",
    " \u0005 \u0134\n \u0003!\u0003!\u0003!\u0003!\u0005!\u013a\n!\u0003",
    "\"\u0003\"\u0003\"\u0003\"\u0003#\u0003#\u0003#\u0003#\u0003$\u0003",
    "$\u0003$\u0003$\u0003$\u0003%\u0003%\u0003%\u0003%\u0003%\u0003%\u0003",
    "&\u0003&\u0003&\u0003&\u0003&\u0003&\u0003&\u0003&\u0003&\u0003\'\u0003",
    "\'\u0003\'\u0003\'\u0003\'\u0003\'\u0003\'\u0003(\u0003(\u0003(\u0003",
    "(\u0007(\u0163\n(\f(\u000e(\u0166\u000b(\u0003(\u0003(\u0003(\u0003",
    "(\u0003(\u0003)\u0003)\u0003)\u0003)\u0007)\u0171\n)\f)\u000e)\u0174",
    "\u000b)\u0003)\u0003)\u0003*\u0003*\u0003+\u0003+\u0003,\u0003,\u0003",
    "-\u0003-\u0003.\u0003.\u0003/\u0003/\u00030\u00030\u00031\u00031\u0003",
    "1\u00032\u00032\u00033\u00033\u00034\u00034\u00035\u00035\u00035\u0003",
    "6\u00036\u00036\u00037\u00037\u00038\u00038\u00038\u00039\u00039\u0003",
    "9\u00039\u00059\u019e\n9\u0003:\u0003:\u0003:\u0003;\u0003;\u0003;\u0003",
    "<\u0003<\u0003<\u0003=\u0003=\u0003=\u0003>\u0003>\u0003>\u0003?\u0003",
    "?\u0003?\u0003@\u0003@\u0003@\u0003@\u0005@\u01b6\n@\u0003A\u0005A\u01b9",
    "\nA\u0003A\u0003A\u0007A\u01bd\nA\fA\u000eA\u01c0\u000bA\u0003A\u0006",
    "A\u01c3\nA\rA\u000eA\u01c4\u0005A\u01c7\nA\u0003B\u0005B\u01ca\nB\u0003",
    "B\u0003B\u0003B\u0006B\u01cf\nB\rB\u000eB\u01d0\u0003C\u0005C\u01d4",
    "\nC\u0003C\u0003C\u0003C\u0006C\u01d9\nC\rC\u000eC\u01da\u0003D\u0005",
    "D\u01de\nD\u0003D\u0003D\u0003D\u0006D\u01e3\nD\rD\u000eD\u01e4\u0003",
    "E\u0005E\u01e8\nE\u0003E\u0003E\u0005E\u01ec\nE\u0003E\u0005E\u01ef",
    "\nE\u0003F\u0003F\u0005F\u01f3\nF\u0003F\u0003F\u0003G\u0003G\u0003",
    "G\u0003H\u0003H\u0003H\u0003I\u0003I\u0007I\u01ff\nI\fI\u000eI\u0202",
    "\u000bI\u0003J\u0003J\u0007J\u0206\nJ\fJ\u000eJ\u0209\u000bJ\u0003K",
    "\u0003K\u0003K\u0007K\u020e\nK\fK\u000eK\u0211\u000bK\u0003K\u0003K",
    "\u0003L\u0003L\u0003L\u0005L\u0218\nL\u0003L\u0003L\u0003M\u0003M\u0003",
    "M\u0003M\u0003M\u0007M\u0221\nM\fM\u000eM\u0224\u000bM\u0003M\u0003",
    "M\u0003M\u0003M\u0003N\u0003N\u0005N\u022c\nN\u0003O\u0003O\u0003P\u0003",
    "P\u0003P\u0003P\u0005P\u0234\nP\u0003Q\u0005Q\u0237\nQ\u0003Q\u0003",
    "Q\u0005Q\u023b\nQ\u0003Q\u0005Q\u023e\nQ\u0003Q\u0003Q\u0003R\u0006",
    "R\u0243\nR\rR\u000eR\u0244\u0003S\u0003S\u0003T\u0003T\u0003U\u0003",
    "U\u0003V\u0003V\u0003W\u0003W\u0003X\u0005X\u0252\nX\u0003X\u0003X\u0003",
    "X\u0003X\u0005X\u0258\nX\u0003Y\u0003Y\u0005Y\u025c\nY\u0003Y\u0003",
    "Y\u0003Z\u0006Z\u0261\nZ\rZ\u000eZ\u0262\u0003[\u0003[\u0006[\u0267",
    "\n[\r[\u000e[\u0268\u0003\\\u0003\\\u0005\\\u026d\n\\\u0003\\\u0006",
    "\\\u0270\n\\\r\\\u000e\\\u0271\u0003]\u0003]\u0003^\u0005^\u0277\n^",
    "\u0003_\u0003_\u0005_\u027b\n_\u0003`\u0006`\u027e\n`\r`\u000e`\u027f",
    "\u0003`\u0003`\u0004\u0164\u0222\u0002a\u0003\u0003\u0005\u0004\u0007",
    "\u0005\t\u0006\u000b\u0007\r\b\u000f\t\u0011\n\u0013\u000b\u0015\f\u0017",
    "\r\u0019\u000e\u001b\u000f\u001d\u0010\u001f\u0011!\u0012#\u0013%\u0014",
    "\'\u0015)\u0016+\u0017-\u0018/\u00191\u001a3\u001b5\u001c7\u001d9\u001e",
    ";\u001f= ?!A\"C#E$G%I&K\'M(O)Q*S+U,W-Y.[/]0_1a2c3e4g5i6k7m8o9q:s;u<",
    "w=y>{?}@\u007fA\u0081B\u0083C\u0085D\u0087E\u0089F\u008bG\u008dH\u008f",
    "I\u0091J\u0093K\u0095L\u0097M\u0099N\u009b\u0002\u009d\u0002\u009f\u0002",
    "\u00a1O\u00a3\u0002\u00a5\u0002\u00a7\u0002\u00a9\u0002\u00ab\u0002",
    "\u00ad\u0002\u00af\u0002\u00b1\u0002\u00b3\u0002\u00b5\u0002\u00b7\u0002",
    "\u00b9\u0002\u00bb\u0002\u00bd\u0002\u00bfP\u0003\u0002\u0015\u0005",
    "\u0002\f\f\u000f\u000f\u202a\u202b\u0004\u0002--//\u0004\u0002QQqq\u0004",
    "\u0002ZZzz\u0004\u0002DDdd\u0004\u0002LLll\u0006\u0002\f\f\u000e\u000f",
    "$$^^\u0006\u0002\f\f\u000e\u000f))^^\u0003\u0002^^\u0004\u0002\u000b",
    "\u000b\"\"\u0003\u00023;\u0003\u00022;\u0003\u000229\u0005\u00022;C",
    "Hch\u0003\u000223\u0004\u0002GGgg\u0003\u0002C\\\u0128\u0002aac|\u00ac",
    "\u00ac\u00b7\u00b7\u00bc\u00bc\u00c2\u00d8\u00da\u00f8\u00fa\u0243\u0252",
    "\u02c3\u02c8\u02d3\u02e2\u02e6\u02f0\u02f0\u037c\u037c\u0388\u0388\u038a",
    "\u038c\u038e\u038e\u0390\u03a3\u03a5\u03d0\u03d2\u03f7\u03f9\u0483\u048c",
    "\u04d0\u04d2\u04fb\u0502\u0511\u0533\u0558\u055b\u055b\u0563\u0589\u05d2",
    "\u05ec\u05f2\u05f4\u0623\u063c\u0642\u064c\u0670\u0671\u0673\u06d5\u06d7",
    "\u06d7\u06e7\u06e8\u06f0\u06f1\u06fc\u06fe\u0701\u0701\u0712\u0712\u0714",
    "\u0731\u074f\u076f\u0782\u07a7\u07b3\u07b3\u0906\u093b\u093f\u093f\u0952",
    "\u0952\u095a\u0963\u097f\u097f\u0987\u098e\u0991\u0992\u0995\u09aa\u09ac",
    "\u09b2\u09b4\u09b4\u09b8\u09bb\u09bf\u09bf\u09d0\u09d0\u09de\u09df\u09e1",
    "\u09e3\u09f2\u09f3\u0a07\u0a0c\u0a11\u0a12\u0a15\u0a2a\u0a2c\u0a32\u0a34",
    "\u0a35\u0a37\u0a38\u0a3a\u0a3b\u0a5b\u0a5e\u0a60\u0a60\u0a74\u0a76\u0a87",
    "\u0a8f\u0a91\u0a93\u0a95\u0aaa\u0aac\u0ab2\u0ab4\u0ab5\u0ab7\u0abb\u0abf",
    "\u0abf\u0ad2\u0ad2\u0ae2\u0ae3\u0b07\u0b0e\u0b11\u0b12\u0b15\u0b2a\u0b2c",
    "\u0b32\u0b34\u0b35\u0b37\u0b3b\u0b3f\u0b3f\u0b5e\u0b5f\u0b61\u0b63\u0b73",
    "\u0b73\u0b85\u0b85\u0b87\u0b8c\u0b90\u0b92\u0b94\u0b97\u0b9b\u0b9c\u0b9e",
    "\u0b9e\u0ba0\u0ba1\u0ba5\u0ba6\u0baa\u0bac\u0bb0\u0bbb\u0c07\u0c0e\u0c10",
    "\u0c12\u0c14\u0c2a\u0c2c\u0c35\u0c37\u0c3b\u0c62\u0c63\u0c87\u0c8e\u0c90",
    "\u0c92\u0c94\u0caa\u0cac\u0cb5\u0cb7\u0cbb\u0cbf\u0cbf\u0ce0\u0ce0\u0ce2",
    "\u0ce3\u0d07\u0d0e\u0d10\u0d12\u0d14\u0d2a\u0d2c\u0d3b\u0d62\u0d63\u0d87",
    "\u0d98\u0d9c\u0db3\u0db5\u0dbd\u0dbf\u0dbf\u0dc2\u0dc8\u0e03\u0e32\u0e34",
    "\u0e35\u0e42\u0e48\u0e83\u0e84\u0e86\u0e86\u0e89\u0e8a\u0e8c\u0e8c\u0e8f",
    "\u0e8f\u0e96\u0e99\u0e9b\u0ea1\u0ea3\u0ea5\u0ea7\u0ea7\u0ea9\u0ea9\u0eac",
    "\u0ead\u0eaf\u0eb2\u0eb4\u0eb5\u0ebf\u0ebf\u0ec2\u0ec6\u0ec8\u0ec8\u0ede",
    "\u0edf\u0f02\u0f02\u0f42\u0f49\u0f4b\u0f6c\u0f8a\u0f8d\u1002\u1023\u1025",
    "\u1029\u102b\u102c\u1052\u1057\u10a2\u10c7\u10d2\u10fc\u10fe\u10fe\u1102",
    "\u115b\u1161\u11a4\u11aa\u11fb\u1202\u124a\u124c\u124f\u1252\u1258\u125a",
    "\u125a\u125c\u125f\u1262\u128a\u128c\u128f\u1292\u12b2\u12b4\u12b7\u12ba",
    "\u12c0\u12c2\u12c2\u12c4\u12c7\u12ca\u12d8\u12da\u1312\u1314\u1317\u131a",
    "\u135c\u1382\u1391\u13a2\u13f6\u1403\u166e\u1671\u1678\u1683\u169c\u16a2",
    "\u16ec\u16f0\u16f2\u1702\u170e\u1710\u1713\u1722\u1733\u1742\u1753\u1762",
    "\u176e\u1770\u1772\u1782\u17b5\u17d9\u17d9\u17de\u17de\u1822\u1879\u1882",
    "\u18aa\u1902\u191e\u1952\u196f\u1972\u1976\u1982\u19ab\u19c3\u19c9\u1a02",
    "\u1a18\u1d02\u1dc1\u1e02\u1e9d\u1ea2\u1efb\u1f02\u1f17\u1f1a\u1f1f\u1f22",
    "\u1f47\u1f4a\u1f4f\u1f52\u1f59\u1f5b\u1f5b\u1f5d\u1f5d\u1f5f\u1f5f\u1f61",
    "\u1f7f\u1f82\u1fb6\u1fb8\u1fbe\u1fc0\u1fc0\u1fc4\u1fc6\u1fc8\u1fce\u1fd2",
    "\u1fd5\u1fd8\u1fdd\u1fe2\u1fee\u1ff4\u1ff6\u1ff8\u1ffe\u2073\u2073\u2081",
    "\u2081\u2092\u2096\u2104\u2104\u2109\u2109\u210c\u2115\u2117\u2117\u211a",
    "\u211f\u2126\u2126\u2128\u2128\u212a\u212a\u212c\u2133\u2135\u213b\u213e",
    "\u2141\u2147\u214b\u2162\u2185\u2c02\u2c30\u2c32\u2c60\u2c82\u2ce6\u2d02",
    "\u2d27\u2d32\u2d67\u2d71\u2d71\u2d82\u2d98\u2da2\u2da8\u2daa\u2db0\u2db2",
    "\u2db8\u2dba\u2dc0\u2dc2\u2dc8\u2dca\u2dd0\u2dd2\u2dd8\u2dda\u2de0\u3007",
    "\u3009\u3023\u302b\u3033\u3037\u303a\u303e\u3043\u3098\u309d\u30a1\u30a3",
    "\u30fc\u30fe\u3101\u3107\u312e\u3133\u3190\u31a2\u31b9\u31f2\u3201\u3402",
    "\u4db7\u4e02\u9fbd\ua002\ua48e\ua802\ua803\ua805\ua807\ua809\ua80c\ua80e",
    "\ua824\uac02\ud7a5\uf902\ufa2f\ufa32\ufa6c\ufa72\ufadb\ufb02\ufb08\ufb15",
    "\ufb19\ufb1f\ufb1f\ufb21\ufb2a\ufb2c\ufb38\ufb3a\ufb3e\ufb40\ufb40\ufb42",
    "\ufb43\ufb45\ufb46\ufb48\ufbb3\ufbd5\ufd3f\ufd52\ufd91\ufd94\ufdc9\ufdf2",
    "\ufdfd\ufe72\ufe76\ufe78\ufefe\uff23\uff3c\uff43\uff5c\uff68\uffc0\uffc4",
    "\uffc9\uffcc\uffd1\uffd4\uffd9\uffdc\uffde\u0097\u00022;C\\\u0302\u0371",
    "\u0485\u0488\u0593\u05bb\u05bd\u05bf\u05c1\u05c1\u05c3\u05c4\u05c6\u05c7",
    "\u05c9\u05c9\u0612\u0617\u064d\u0660\u0662\u066b\u0672\u0672\u06d8\u06de",
    "\u06e1\u06e6\u06e9\u06ea\u06ec\u06ef\u06f2\u06fb\u0713\u0713\u0732\u074c",
    "\u07a8\u07b2\u0903\u0905\u093e\u093e\u0940\u094f\u0953\u0956\u0964\u0965",
    "\u0968\u0971\u0983\u0985\u09be\u09be\u09c0\u09c6\u09c9\u09ca\u09cd\u09cf",
    "\u09d9\u09d9\u09e4\u09e5\u09e8\u09f1\u0a03\u0a05\u0a3e\u0a3e\u0a40\u0a44",
    "\u0a49\u0a4a\u0a4d\u0a4f\u0a68\u0a73\u0a83\u0a85\u0abe\u0abe\u0ac0\u0ac7",
    "\u0ac9\u0acb\u0acd\u0acf\u0ae4\u0ae5\u0ae8\u0af1\u0b03\u0b05\u0b3e\u0b3e",
    "\u0b40\u0b45\u0b49\u0b4a\u0b4d\u0b4f\u0b58\u0b59\u0b68\u0b71\u0b84\u0b84",
    "\u0bc0\u0bc4\u0bc8\u0bca\u0bcc\u0bcf\u0bd9\u0bd9\u0be8\u0bf1\u0c03\u0c05",
    "\u0c40\u0c46\u0c48\u0c4a\u0c4c\u0c4f\u0c57\u0c58\u0c68\u0c71\u0c84\u0c85",
    "\u0cbe\u0cbe\u0cc0\u0cc6\u0cc8\u0cca\u0ccc\u0ccf\u0cd7\u0cd8\u0ce8\u0cf1",
    "\u0d04\u0d05\u0d40\u0d45\u0d48\u0d4a\u0d4c\u0d4f\u0d59\u0d59\u0d68\u0d71",
    "\u0d84\u0d85\u0dcc\u0dcc\u0dd1\u0dd6\u0dd8\u0dd8\u0dda\u0de1\u0df4\u0df5",
    "\u0e33\u0e33\u0e36\u0e3c\u0e49\u0e50\u0e52\u0e5b\u0eb3\u0eb3\u0eb6\u0ebb",
    "\u0ebd\u0ebe\u0eca\u0ecf\u0ed2\u0edb\u0f1a\u0f1b\u0f22\u0f2b\u0f37\u0f37",
    "\u0f39\u0f39\u0f3b\u0f3b\u0f40\u0f41\u0f73\u0f86\u0f88\u0f89\u0f92\u0f99",
    "\u0f9b\u0fbe\u0fc8\u0fc8\u102e\u1034\u1038\u103b\u1042\u104b\u1058\u105b",
    "\u1361\u1361\u136b\u1373\u1714\u1716\u1734\u1736\u1754\u1755\u1774\u1775",
    "\u17b8\u17d5\u17df\u17df\u17e2\u17eb\u180d\u180f\u1812\u181b\u18ab\u18ab",
    "\u1922\u192d\u1932\u193d\u1948\u1951\u19b2\u19c2\u19ca\u19cb\u19d2\u19db",
    "\u1a19\u1a1d\u1dc2\u1dc5\u2041\u2042\u2056\u2056\u20d2\u20de\u20e3\u20e3",
    "\u20e7\u20ed\u302c\u3031\u309b\u309c\ua804\ua804\ua808\ua808\ua80d\ua80d",
    "\ua825\ua829\ufb20\ufb20\ufe02\ufe11\ufe22\ufe25\ufe35\ufe36\ufe4f\ufe51",
    "\uff12\uff1b\uff41\uff41\u0002\u029c\u0002\u0003\u0003\u0002\u0002\u0002",
    "\u0002\u0005\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002",
    "\u0002\t\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002",
    "\u0002\r\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002",
    "\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002",
    "\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002",
    "\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002\u0002\u0002",
    "\u0002\u001d\u0003\u0002\u0002\u0002\u0002\u001f\u0003\u0002\u0002\u0002",
    "\u0002!\u0003\u0002\u0002\u0002\u0002#\u0003\u0002\u0002\u0002\u0002",
    "%\u0003\u0002\u0002\u0002\u0002\'\u0003\u0002\u0002\u0002\u0002)\u0003",
    "\u0002\u0002\u0002\u0002+\u0003\u0002\u0002\u0002\u0002-\u0003\u0002",
    "\u0002\u0002\u0002/\u0003\u0002\u0002\u0002\u00021\u0003\u0002\u0002",
    "\u0002\u00023\u0003\u0002\u0002\u0002\u00025\u0003\u0002\u0002\u0002",
    "\u00027\u0003\u0002\u0002\u0002\u00029\u0003\u0002\u0002\u0002\u0002",
    ";\u0003\u0002\u0002\u0002\u0002=\u0003\u0002\u0002\u0002\u0002?\u0003",
    "\u0002\u0002\u0002\u0002A\u0003\u0002\u0002\u0002\u0002C\u0003\u0002",
    "\u0002\u0002\u0002E\u0003\u0002\u0002\u0002\u0002G\u0003\u0002\u0002",
    "\u0002\u0002I\u0003\u0002\u0002\u0002\u0002K\u0003\u0002\u0002\u0002",
    "\u0002M\u0003\u0002\u0002\u0002\u0002O\u0003\u0002\u0002\u0002\u0002",
    "Q\u0003\u0002\u0002\u0002\u0002S\u0003\u0002\u0002\u0002\u0002U\u0003",
    "\u0002\u0002\u0002\u0002W\u0003\u0002\u0002\u0002\u0002Y\u0003\u0002",
    "\u0002\u0002\u0002[\u0003\u0002\u0002\u0002\u0002]\u0003\u0002\u0002",
    "\u0002\u0002_\u0003\u0002\u0002\u0002\u0002a\u0003\u0002\u0002\u0002",
    "\u0002c\u0003\u0002\u0002\u0002\u0002e\u0003\u0002\u0002\u0002\u0002",
    "g\u0003\u0002\u0002\u0002\u0002i\u0003\u0002\u0002\u0002\u0002k\u0003",
    "\u0002\u0002\u0002\u0002m\u0003\u0002\u0002\u0002\u0002o\u0003\u0002",
    "\u0002\u0002\u0002q\u0003\u0002\u0002\u0002\u0002s\u0003\u0002\u0002",
    "\u0002\u0002u\u0003\u0002\u0002\u0002\u0002w\u0003\u0002\u0002\u0002",
    "\u0002y\u0003\u0002\u0002\u0002\u0002{\u0003\u0002\u0002\u0002\u0002",
    "}\u0003\u0002\u0002\u0002\u0002\u007f\u0003\u0002\u0002\u0002\u0002",
    "\u0081\u0003\u0002\u0002\u0002\u0002\u0083\u0003\u0002\u0002\u0002\u0002",
    "\u0085\u0003\u0002\u0002\u0002\u0002\u0087\u0003\u0002\u0002\u0002\u0002",
    "\u0089\u0003\u0002\u0002\u0002\u0002\u008b\u0003\u0002\u0002\u0002\u0002",
    "\u008d\u0003\u0002\u0002\u0002\u0002\u008f\u0003\u0002\u0002\u0002\u0002",
    "\u0091\u0003\u0002\u0002\u0002\u0002\u0093\u0003\u0002\u0002\u0002\u0002",
    "\u0095\u0003\u0002\u0002\u0002\u0002\u0097\u0003\u0002\u0002\u0002\u0002",
    "\u0099\u0003\u0002\u0002\u0002\u0002\u00a1\u0003\u0002\u0002\u0002\u0002",
    "\u00bf\u0003\u0002\u0002\u0002\u0003\u00c1\u0003\u0002\u0002\u0002\u0005",
    "\u00c3\u0003\u0002\u0002\u0002\u0007\u00c5\u0003\u0002\u0002\u0002\t",
    "\u00c7\u0003\u0002\u0002\u0002\u000b\u00c9\u0003\u0002\u0002\u0002\r",
    "\u00cb\u0003\u0002\u0002\u0002\u000f\u00cd\u0003\u0002\u0002\u0002\u0011",
    "\u00cf\u0003\u0002\u0002\u0002\u0013\u00d1\u0003\u0002\u0002\u0002\u0015",
    "\u00d5\u0003\u0002\u0002\u0002\u0017\u00d8\u0003\u0002\u0002\u0002\u0019",
    "\u00da\u0003\u0002\u0002\u0002\u001b\u00dc\u0003\u0002\u0002\u0002\u001d",
    "\u00de\u0003\u0002\u0002\u0002\u001f\u00e0\u0003\u0002\u0002\u0002!",
    "\u00e2\u0003\u0002\u0002\u0002#\u00e5\u0003\u0002\u0002\u0002%\u00ea",
    "\u0003\u0002\u0002\u0002\'\u00ef\u0003\u0002\u0002\u0002)\u00f4\u0003",
    "\u0002\u0002\u0002+\u00f7\u0003\u0002\u0002\u0002-\u00fb\u0003\u0002",
    "\u0002\u0002/\u00ff\u0003\u0002\u0002\u00021\u0102\u0003\u0002\u0002",
    "\u00023\u0107\u0003\u0002\u0002\u00025\u010d\u0003\u0002\u0002\u0002",
    "7\u0110\u0003\u0002\u0002\u00029\u0117\u0003\u0002\u0002\u0002;\u011e",
    "\u0003\u0002\u0002\u0002=\u0127\u0003\u0002\u0002\u0002?\u0133\u0003",
    "\u0002\u0002\u0002A\u0139\u0003\u0002\u0002\u0002C\u013b\u0003\u0002",
    "\u0002\u0002E\u013f\u0003\u0002\u0002\u0002G\u0143\u0003\u0002\u0002",
    "\u0002I\u0148\u0003\u0002\u0002\u0002K\u014e\u0003\u0002\u0002\u0002",
    "M\u0157\u0003\u0002\u0002\u0002O\u015e\u0003\u0002\u0002\u0002Q\u016c",
    "\u0003\u0002\u0002\u0002S\u0177\u0003\u0002\u0002\u0002U\u0179\u0003",
    "\u0002\u0002\u0002W\u017b\u0003\u0002\u0002\u0002Y\u017d\u0003\u0002",
    "\u0002\u0002[\u017f\u0003\u0002\u0002\u0002]\u0181\u0003\u0002\u0002",
    "\u0002_\u0183\u0003\u0002\u0002\u0002a\u0185\u0003\u0002\u0002\u0002",
    "c\u0188\u0003\u0002\u0002\u0002e\u018a\u0003\u0002\u0002\u0002g\u018c",
    "\u0003\u0002\u0002\u0002i\u018e\u0003\u0002\u0002\u0002k\u0191\u0003",
    "\u0002\u0002\u0002m\u0194\u0003\u0002\u0002\u0002o\u0196\u0003\u0002",
    "\u0002\u0002q\u019d\u0003\u0002\u0002\u0002s\u019f\u0003\u0002\u0002",
    "\u0002u\u01a2\u0003\u0002\u0002\u0002w\u01a5\u0003\u0002\u0002\u0002",
    "y\u01a8\u0003\u0002\u0002\u0002{\u01ab\u0003\u0002\u0002\u0002}\u01ae",
    "\u0003\u0002\u0002\u0002\u007f\u01b5\u0003\u0002\u0002\u0002\u0081\u01c6",
    "\u0003\u0002\u0002\u0002\u0083\u01c9\u0003\u0002\u0002\u0002\u0085\u01d3",
    "\u0003\u0002\u0002\u0002\u0087\u01dd\u0003\u0002\u0002\u0002\u0089\u01ee",
    "\u0003\u0002\u0002\u0002\u008b\u01f2\u0003\u0002\u0002\u0002\u008d\u01f6",
    "\u0003\u0002\u0002\u0002\u008f\u01f9\u0003\u0002\u0002\u0002\u0091\u01fc",
    "\u0003\u0002\u0002\u0002\u0093\u0203\u0003\u0002\u0002\u0002\u0095\u020a",
    "\u0003\u0002\u0002\u0002\u0097\u0214\u0003\u0002\u0002\u0002\u0099\u021b",
    "\u0003\u0002\u0002\u0002\u009b\u022b\u0003\u0002\u0002\u0002\u009d\u022d",
    "\u0003\u0002\u0002\u0002\u009f\u0233\u0003\u0002\u0002\u0002\u00a1\u023a",
    "\u0003\u0002\u0002\u0002\u00a3\u0242\u0003\u0002\u0002\u0002\u00a5\u0246",
    "\u0003\u0002\u0002\u0002\u00a7\u0248\u0003\u0002\u0002\u0002\u00a9\u024a",
    "\u0003\u0002\u0002\u0002\u00ab\u024c\u0003\u0002\u0002\u0002\u00ad\u024e",
    "\u0003\u0002\u0002\u0002\u00af\u0257\u0003\u0002\u0002\u0002\u00b1\u025b",
    "\u0003\u0002\u0002\u0002\u00b3\u0260\u0003\u0002\u0002\u0002\u00b5\u0264",
    "\u0003\u0002\u0002\u0002\u00b7\u026a\u0003\u0002\u0002\u0002\u00b9\u0273",
    "\u0003\u0002\u0002\u0002\u00bb\u0276\u0003\u0002\u0002\u0002\u00bd\u027a",
    "\u0003\u0002\u0002\u0002\u00bf\u027d\u0003\u0002\u0002\u0002\u00c1\u00c2",
    "\u0007*\u0002\u0002\u00c2\u0004\u0003\u0002\u0002\u0002\u00c3\u00c4",
    "\u0007.\u0002\u0002\u00c4\u0006\u0003\u0002\u0002\u0002\u00c5\u00c6",
    "\u0007+\u0002\u0002\u00c6\b\u0003\u0002\u0002\u0002\u00c7\u00c8\u0007",
    "]\u0002\u0002\u00c8\n\u0003\u0002\u0002\u0002\u00c9\u00ca\u0007_\u0002",
    "\u0002\u00ca\f\u0003\u0002\u0002\u0002\u00cb\u00cc\u0007}\u0002\u0002",
    "\u00cc\u000e\u0003\u0002\u0002\u0002\u00cd\u00ce\u0007\u007f\u0002\u0002",
    "\u00ce\u0010\u0003\u0002\u0002\u0002\u00cf\u00d0\u0007~\u0002\u0002",
    "\u00d0\u0012\u0003\u0002\u0002\u0002\u00d1\u00d2\u0007^\u0002\u0002",
    "\u00d2\u00d3\u0007e\u0002\u0002\u00d3\u00d4\u0007e\u0002\u0002\u00d4",
    "\u0014\u0003\u0002\u0002\u0002\u00d5\u00d6\u0007/\u0002\u0002\u00d6",
    "\u00d7\u0007@\u0002\u0002\u00d7\u0016\u0003\u0002\u0002\u0002\u00d8",
    "\u00d9\u0007^\u0002\u0002\u00d9\u0018\u0003\u0002\u0002\u0002\u00da",
    "\u00db\u0007<\u0002\u0002\u00db\u001a\u0003\u0002\u0002\u0002\u00dc",
    "\u00dd\u0007#\u0002\u0002\u00dd\u001c\u0003\u0002\u0002\u0002\u00de",
    "\u00df\u0007?\u0002\u0002\u00df\u001e\u0003\u0002\u0002\u0002\u00e0",
    "\u00e1\u0007a\u0002\u0002\u00e1 \u0003\u0002\u0002\u0002\u00e2\u00e3",
    "\u0007k\u0002\u0002\u00e3\u00e4\u0007h\u0002\u0002\u00e4\"\u0003\u0002",
    "\u0002\u0002\u00e5\u00e6\u0007v\u0002\u0002\u00e6\u00e7\u0007j\u0002",
    "\u0002\u00e7\u00e8\u0007g\u0002\u0002\u00e8\u00e9\u0007p\u0002\u0002",
    "\u00e9$\u0003\u0002\u0002\u0002\u00ea\u00eb\u0007g\u0002\u0002\u00eb",
    "\u00ec\u0007n\u0002\u0002\u00ec\u00ed\u0007u\u0002\u0002\u00ed\u00ee",
    "\u0007g\u0002\u0002\u00ee&\u0003\u0002\u0002\u0002\u00ef\u00f0\u0007",
    "e\u0002\u0002\u00f0\u00f1\u0007c\u0002\u0002\u00f1\u00f2\u0007u\u0002",
    "\u0002\u00f2\u00f3\u0007g\u0002\u0002\u00f3(\u0003\u0002\u0002\u0002",
    "\u00f4\u00f5\u0007q\u0002\u0002\u00f5\u00f6\u0007h\u0002\u0002\u00f6",
    "*\u0003\u0002\u0002\u0002\u00f7\u00f8\u0007n\u0002\u0002\u00f8\u00f9",
    "\u0007g\u0002\u0002\u00f9\u00fa\u0007v\u0002\u0002\u00fa,\u0003\u0002",
    "\u0002\u0002\u00fb\u00fc\u0007t\u0002\u0002\u00fc\u00fd\u0007g\u0002",
    "\u0002\u00fd\u00fe\u0007e\u0002\u0002\u00fe.\u0003\u0002\u0002\u0002",
    "\u00ff\u0100\u0007k\u0002\u0002\u0100\u0101\u0007p\u0002\u0002\u0101",
    "0\u0003\u0002\u0002\u0002\u0102\u0103\u0007v\u0002\u0002\u0103\u0104",
    "\u0007{\u0002\u0002\u0104\u0105\u0007r\u0002\u0002\u0105\u0106\u0007",
    "g\u0002\u0002\u01062\u0003\u0002\u0002\u0002\u0107\u0108\u0007c\u0002",
    "\u0002\u0108\u0109\u0007n\u0002\u0002\u0109\u010a\u0007k\u0002\u0002",
    "\u010a\u010b\u0007c\u0002\u0002\u010b\u010c\u0007u\u0002\u0002\u010c",
    "4\u0003\u0002\u0002\u0002\u010d\u010e\u0007c\u0002\u0002\u010e\u010f",
    "\u0007u\u0002\u0002\u010f6\u0003\u0002\u0002\u0002\u0110\u0111\u0007",
    "k\u0002\u0002\u0111\u0112\u0007o\u0002\u0002\u0112\u0113\u0007r\u0002",
    "\u0002\u0113\u0114\u0007q\u0002\u0002\u0114\u0115\u0007t\u0002\u0002",
    "\u0115\u0116\u0007v\u0002\u0002\u01168\u0003\u0002\u0002\u0002\u0117",
    "\u0118\u0007g\u0002\u0002\u0118\u0119\u0007z\u0002\u0002\u0119\u011a",
    "\u0007r\u0002\u0002\u011a\u011b\u0007q\u0002\u0002\u011b\u011c\u0007",
    "t\u0002\u0002\u011c\u011d\u0007v\u0002\u0002\u011d:\u0003\u0002\u0002",
    "\u0002\u011e\u011f\u0007g\u0002\u0002\u011f\u0120\u0007z\u0002\u0002",
    "\u0120\u0121\u0007r\u0002\u0002\u0121\u0122\u0007q\u0002\u0002\u0122",
    "\u0123\u0007u\u0002\u0002\u0123\u0124\u0007k\u0002\u0002\u0124\u0125",
    "\u0007p\u0002\u0002\u0125\u0126\u0007i\u0002\u0002\u0126<\u0003\u0002",
    "\u0002\u0002\u0127\u0128\u0007o\u0002\u0002\u0128\u0129\u0007q\u0002",
    "\u0002\u0129\u012a\u0007f\u0002\u0002\u012a\u012b\u0007w\u0002\u0002",
    "\u012b\u012c\u0007n\u0002\u0002\u012c\u012d\u0007g\u0002\u0002\u012d",
    ">\u0003\u0002\u0002\u0002\u012e\u012f\u0007c\u0002\u0002\u012f\u0130",
    "\u0007p\u0002\u0002\u0130\u0134\u0007f\u0002\u0002\u0131\u0132\u0007",
    "(\u0002\u0002\u0132\u0134\u0007(\u0002\u0002\u0133\u012e\u0003\u0002",
    "\u0002\u0002\u0133\u0131\u0003\u0002\u0002\u0002\u0134@\u0003\u0002",
    "\u0002\u0002\u0135\u0136\u0007q\u0002\u0002\u0136\u013a\u0007t\u0002",
    "\u0002\u0137\u0138\u0007~\u0002\u0002\u0138\u013a\u0007~\u0002\u0002",
    "\u0139\u0135\u0003\u0002\u0002\u0002\u0139\u0137\u0003\u0002\u0002\u0002",
    "\u013aB\u0003\u0002\u0002\u0002\u013b\u013c\u0007z\u0002\u0002\u013c",
    "\u013d\u0007q\u0002\u0002\u013d\u013e\u0007t\u0002\u0002\u013eD\u0003",
    "\u0002\u0002\u0002\u013f\u0140\u0007p\u0002\u0002\u0140\u0141\u0007",
    "q\u0002\u0002\u0141\u0142\u0007v\u0002\u0002\u0142F\u0003\u0002\u0002",
    "\u0002\u0143\u0144\u0007V\u0002\u0002\u0144\u0145\u0007t\u0002\u0002",
    "\u0145\u0146\u0007w\u0002\u0002\u0146\u0147\u0007g\u0002\u0002\u0147",
    "H\u0003\u0002\u0002\u0002\u0148\u0149\u0007H\u0002\u0002\u0149\u014a",
    "\u0007c\u0002\u0002\u014a\u014b\u0007n\u0002\u0002\u014b\u014c\u0007",
    "u\u0002\u0002\u014c\u014d\u0007g\u0002\u0002\u014dJ\u0003\u0002\u0002",
    "\u0002\u014e\u014f\u0007g\u0002\u0002\u014f\u0150\u0007z\u0002\u0002",
    "\u0150\u0151\u0007v\u0002\u0002\u0151\u0152\u0007g\u0002\u0002\u0152",
    "\u0153\u0007t\u0002\u0002\u0153\u0154\u0007p\u0002\u0002\u0154\u0155",
    "\u0007c\u0002\u0002\u0155\u0156\u0007n\u0002\u0002\u0156L\u0003\u0002",
    "\u0002\u0002\u0157\u0158\u0007q\u0002\u0002\u0158\u0159\u0007r\u0002",
    "\u0002\u0159\u015a\u0007c\u0002\u0002\u015a\u015b\u0007s\u0002\u0002",
    "\u015b\u015c\u0007w\u0002\u0002\u015c\u015d\u0007g\u0002\u0002\u015d",
    "N\u0003\u0002\u0002\u0002\u015e\u015f\u0007}\u0002\u0002\u015f\u0160",
    "\u0007/\u0002\u0002\u0160\u0164\u0003\u0002\u0002\u0002\u0161\u0163",
    "\u000b\u0002\u0002\u0002\u0162\u0161\u0003\u0002\u0002\u0002\u0163\u0166",
    "\u0003\u0002\u0002\u0002\u0164\u0165\u0003\u0002\u0002\u0002\u0164\u0162",
    "\u0003\u0002\u0002\u0002\u0165\u0167\u0003\u0002\u0002\u0002\u0166\u0164",
    "\u0003\u0002\u0002\u0002\u0167\u0168\u0007/\u0002\u0002\u0168\u0169",
    "\u0007\u007f\u0002\u0002\u0169\u016a\u0003\u0002\u0002\u0002\u016a\u016b",
    "\b(\u0002\u0002\u016bP\u0003\u0002\u0002\u0002\u016c\u016d\u0007/\u0002",
    "\u0002\u016d\u016e\u0007/\u0002\u0002\u016e\u0172\u0003\u0002\u0002",
    "\u0002\u016f\u0171\n\u0002\u0002\u0002\u0170\u016f\u0003\u0002\u0002",
    "\u0002\u0171\u0174\u0003\u0002\u0002\u0002\u0172\u0170\u0003\u0002\u0002",
    "\u0002\u0172\u0173\u0003\u0002\u0002\u0002\u0173\u0175\u0003\u0002\u0002",
    "\u0002\u0174\u0172\u0003\u0002\u0002\u0002\u0175\u0176\b)\u0002\u0002",
    "\u0176R\u0003\u0002\u0002\u0002\u0177\u0178\u0007`\u0002\u0002\u0178",
    "T\u0003\u0002\u0002\u0002\u0179\u017a\u0007,\u0002\u0002\u017aV\u0003",
    "\u0002\u0002\u0002\u017b\u017c\u00071\u0002\u0002\u017cX\u0003\u0002",
    "\u0002\u0002\u017d\u017e\u0007-\u0002\u0002\u017eZ\u0003\u0002\u0002",
    "\u0002\u017f\u0180\u0007/\u0002\u0002\u0180\\\u0003\u0002\u0002\u0002",
    "\u0181\u0182\u0007\'\u0002\u0002\u0182^\u0003\u0002\u0002\u0002\u0183",
    "\u0184\u0007=\u0002\u0002\u0184`\u0003\u0002\u0002\u0002\u0185\u0186",
    "\u00070\u0002\u0002\u0186\u0187\u00070\u0002\u0002\u0187b\u0003\u0002",
    "\u0002\u0002\u0188\u0189\u0007%\u0002\u0002\u0189d\u0003\u0002\u0002",
    "\u0002\u018a\u018b\u0007\u0080\u0002\u0002\u018bf\u0003\u0002\u0002",
    "\u0002\u018c\u018d\u0007@\u0002\u0002\u018dh\u0003\u0002\u0002\u0002",
    "\u018e\u018f\u0007@\u0002\u0002\u018f\u0190\u0007?\u0002\u0002\u0190",
    "j\u0003\u0002\u0002\u0002\u0191\u0192\u0007?\u0002\u0002\u0192\u0193",
    "\u0007?\u0002\u0002\u0193l\u0003\u0002\u0002\u0002\u0194\u0195\u0007",
    ">\u0002\u0002\u0195n\u0003\u0002\u0002\u0002\u0196\u0197\u0007>\u0002",
    "\u0002\u0197\u0198\u0007?\u0002\u0002\u0198p\u0003\u0002\u0002\u0002",
    "\u0199\u019a\u0007>\u0002\u0002\u019a\u019e\u0007@\u0002\u0002\u019b",
    "\u019c\u0007#\u0002\u0002\u019c\u019e\u0007?\u0002\u0002\u019d\u0199",
    "\u0003\u0002\u0002\u0002\u019d\u019b\u0003\u0002\u0002\u0002\u019er",
    "\u0003\u0002\u0002\u0002\u019f\u01a0\u0007<\u0002\u0002\u01a0\u01a1",
    "\u0007<\u0002\u0002\u01a1t\u0003\u0002\u0002\u0002\u01a2\u01a3\u0007",
    "-\u0002\u0002\u01a3\u01a4\u0007-\u0002\u0002\u01a4v\u0003\u0002\u0002",
    "\u0002\u01a5\u01a6\u0007>\u0002\u0002\u01a6\u01a7\u0007~\u0002\u0002",
    "\u01a7x\u0003\u0002\u0002\u0002\u01a8\u01a9\u0007~\u0002\u0002\u01a9",
    "\u01aa\u0007@\u0002\u0002\u01aaz\u0003\u0002\u0002\u0002\u01ab\u01ac",
    "\u0007>\u0002\u0002\u01ac\u01ad\u0007>\u0002\u0002\u01ad|\u0003\u0002",
    "\u0002\u0002\u01ae\u01af\u0007@\u0002\u0002\u01af\u01b0\u0007@\u0002",
    "\u0002\u01b0~\u0003\u0002\u0002\u0002\u01b1\u01b6\u0005\u0081A\u0002",
    "\u01b2\u01b6\u0005\u0083B\u0002\u01b3\u01b6\u0005\u0085C\u0002\u01b4",
    "\u01b6\u0005\u0087D\u0002\u01b5\u01b1\u0003\u0002\u0002\u0002\u01b5",
    "\u01b2\u0003\u0002\u0002\u0002\u01b5\u01b3\u0003\u0002\u0002\u0002\u01b5",
    "\u01b4\u0003\u0002\u0002\u0002\u01b6\u0080\u0003\u0002\u0002\u0002\u01b7",
    "\u01b9\t\u0003\u0002\u0002\u01b8\u01b7\u0003\u0002\u0002\u0002\u01b8",
    "\u01b9\u0003\u0002\u0002\u0002\u01b9\u01ba\u0003\u0002\u0002\u0002\u01ba",
    "\u01be\u0005\u00a5S\u0002\u01bb\u01bd\u0005\u00a7T\u0002\u01bc\u01bb",
    "\u0003\u0002\u0002\u0002\u01bd\u01c0\u0003\u0002\u0002\u0002\u01be\u01bc",
    "\u0003\u0002\u0002\u0002\u01be\u01bf\u0003\u0002\u0002\u0002\u01bf\u01c7",
    "\u0003\u0002\u0002\u0002\u01c0\u01be\u0003\u0002\u0002\u0002\u01c1\u01c3",
    "\u00072\u0002\u0002\u01c2\u01c1\u0003\u0002\u0002\u0002\u01c3\u01c4",
    "\u0003\u0002\u0002\u0002\u01c4\u01c2\u0003\u0002\u0002\u0002\u01c4\u01c5",
    "\u0003\u0002\u0002\u0002\u01c5\u01c7\u0003\u0002\u0002\u0002\u01c6\u01b8",
    "\u0003\u0002\u0002\u0002\u01c6\u01c2\u0003\u0002\u0002\u0002\u01c7\u0082",
    "\u0003\u0002\u0002\u0002\u01c8\u01ca\t\u0003\u0002\u0002\u01c9\u01c8",
    "\u0003\u0002\u0002\u0002\u01c9\u01ca\u0003\u0002\u0002\u0002\u01ca\u01cb",
    "\u0003\u0002\u0002\u0002\u01cb\u01cc\u00072\u0002\u0002\u01cc\u01ce",
    "\t\u0004\u0002\u0002\u01cd\u01cf\u0005\u00a9U\u0002\u01ce\u01cd\u0003",
    "\u0002\u0002\u0002\u01cf\u01d0\u0003\u0002\u0002\u0002\u01d0\u01ce\u0003",
    "\u0002\u0002\u0002\u01d0\u01d1\u0003\u0002\u0002\u0002\u01d1\u0084\u0003",
    "\u0002\u0002\u0002\u01d2\u01d4\t\u0003\u0002\u0002\u01d3\u01d2\u0003",
    "\u0002\u0002\u0002\u01d3\u01d4\u0003\u0002\u0002\u0002\u01d4\u01d5\u0003",
    "\u0002\u0002\u0002\u01d5\u01d6\u00072\u0002\u0002\u01d6\u01d8\t\u0005",
    "\u0002\u0002\u01d7\u01d9\u0005\u00abV\u0002\u01d8\u01d7\u0003\u0002",
    "\u0002\u0002\u01d9\u01da\u0003\u0002\u0002\u0002\u01da\u01d8\u0003\u0002",
    "\u0002\u0002\u01da\u01db\u0003\u0002\u0002\u0002\u01db\u0086\u0003\u0002",
    "\u0002\u0002\u01dc\u01de\t\u0003\u0002\u0002\u01dd\u01dc\u0003\u0002",
    "\u0002\u0002\u01dd\u01de\u0003\u0002\u0002\u0002\u01de\u01df\u0003\u0002",
    "\u0002\u0002\u01df\u01e0\u00072\u0002\u0002\u01e0\u01e2\t\u0006\u0002",
    "\u0002\u01e1\u01e3\u0005\u00adW\u0002\u01e2\u01e1\u0003\u0002\u0002",
    "\u0002\u01e3\u01e4\u0003\u0002\u0002\u0002\u01e4\u01e2\u0003\u0002\u0002",
    "\u0002\u01e4\u01e5\u0003\u0002\u0002\u0002\u01e5\u0088\u0003\u0002\u0002",
    "\u0002\u01e6\u01e8\t\u0003\u0002\u0002\u01e7\u01e6\u0003\u0002\u0002",
    "\u0002\u01e7\u01e8\u0003\u0002\u0002\u0002\u01e8\u01e9\u0003\u0002\u0002",
    "\u0002\u01e9\u01ef\u0005\u00afX\u0002\u01ea\u01ec\t\u0003\u0002\u0002",
    "\u01eb\u01ea\u0003\u0002\u0002\u0002\u01eb\u01ec\u0003\u0002\u0002\u0002",
    "\u01ec\u01ed\u0003\u0002\u0002\u0002\u01ed\u01ef\u0005\u00b1Y\u0002",
    "\u01ee\u01e7\u0003\u0002\u0002\u0002\u01ee\u01eb\u0003\u0002\u0002\u0002",
    "\u01ef\u008a\u0003\u0002\u0002\u0002\u01f0\u01f3\u0005\u0089E\u0002",
    "\u01f1\u01f3\u0005\u00b3Z\u0002\u01f2\u01f0\u0003\u0002\u0002\u0002",
    "\u01f2\u01f1\u0003\u0002\u0002\u0002\u01f3\u01f4\u0003\u0002\u0002\u0002",
    "\u01f4\u01f5\t\u0007\u0002\u0002\u01f5\u008c\u0003\u0002\u0002\u0002",
    "\u01f6\u01f7\u00070\u0002\u0002\u01f7\u01f8\u0005\u0093J\u0002\u01f8",
    "\u008e\u0003\u0002\u0002\u0002\u01f9\u01fa\u00070\u0002\u0002\u01fa",
    "\u01fb\u0005\u0091I\u0002\u01fb\u0090\u0003\u0002\u0002\u0002\u01fc",
    "\u0200\u0005\u00bb^\u0002\u01fd\u01ff\u0005\u00bd_\u0002\u01fe\u01fd",
    "\u0003\u0002\u0002\u0002\u01ff\u0202\u0003\u0002\u0002\u0002\u0200\u01fe",
    "\u0003\u0002\u0002\u0002\u0200\u0201\u0003\u0002\u0002\u0002\u0201\u0092",
    "\u0003\u0002\u0002\u0002\u0202\u0200\u0003\u0002\u0002\u0002\u0203\u0207",
    "\u0005\u00b9]\u0002\u0204\u0206\u0005\u00bd_\u0002\u0205\u0204\u0003",
    "\u0002\u0002\u0002\u0206\u0209\u0003\u0002\u0002\u0002\u0207\u0205\u0003",
    "\u0002\u0002\u0002\u0207\u0208\u0003\u0002\u0002\u0002\u0208\u0094\u0003",
    "\u0002\u0002\u0002\u0209\u0207\u0003\u0002\u0002\u0002\u020a\u020f\u0007",
    "$\u0002\u0002\u020b\u020e\u0005\u009fP\u0002\u020c\u020e\n\b\u0002\u0002",
    "\u020d\u020b\u0003\u0002\u0002\u0002\u020d\u020c\u0003\u0002\u0002\u0002",
    "\u020e\u0211\u0003\u0002\u0002\u0002\u020f\u020d\u0003\u0002\u0002\u0002",
    "\u020f\u0210\u0003\u0002\u0002\u0002\u0210\u0212\u0003\u0002\u0002\u0002",
    "\u0211\u020f\u0003\u0002\u0002\u0002\u0212\u0213\u0007$\u0002\u0002",
    "\u0213\u0096\u0003\u0002\u0002\u0002\u0214\u0217\u0007)\u0002\u0002",
    "\u0215\u0218\u0005\u009fP\u0002\u0216\u0218\n\t\u0002\u0002\u0217\u0215",
    "\u0003\u0002\u0002\u0002\u0217\u0216\u0003\u0002\u0002\u0002\u0218\u0219",
    "\u0003\u0002\u0002\u0002\u0219\u021a\u0007)\u0002\u0002\u021a\u0098",
    "\u0003\u0002\u0002\u0002\u021b\u021c\u0007$\u0002\u0002\u021c\u021d",
    "\u0007$\u0002\u0002\u021d\u021e\u0007$\u0002\u0002\u021e\u0222\u0003",
    "\u0002\u0002\u0002\u021f\u0221\u0005\u009bN\u0002\u0220\u021f\u0003",
    "\u0002\u0002\u0002\u0221\u0224\u0003\u0002\u0002\u0002\u0222\u0223\u0003",
    "\u0002\u0002\u0002\u0222\u0220\u0003\u0002\u0002\u0002\u0223\u0225\u0003",
    "\u0002\u0002\u0002\u0224\u0222\u0003\u0002\u0002\u0002\u0225\u0226\u0007",
    "$\u0002\u0002\u0226\u0227\u0007$\u0002\u0002\u0227\u0228\u0007$\u0002",
    "\u0002\u0228\u009a\u0003\u0002\u0002\u0002\u0229\u022c\u0005\u009dO",
    "\u0002\u022a\u022c\u0005\u009fP\u0002\u022b\u0229\u0003\u0002\u0002",
    "\u0002\u022b\u022a\u0003\u0002\u0002\u0002\u022c\u009c\u0003\u0002\u0002",
    "\u0002\u022d\u022e\n\n\u0002\u0002\u022e\u009e\u0003\u0002\u0002\u0002",
    "\u022f\u0230\u0007^\u0002\u0002\u0230\u0234\u000b\u0002\u0002\u0002",
    "\u0231\u0232\u0007^\u0002\u0002\u0232\u0234\u0005\u00a1Q\u0002\u0233",
    "\u022f\u0003\u0002\u0002\u0002\u0233\u0231\u0003\u0002\u0002\u0002\u0234",
    "\u00a0\u0003\u0002\u0002\u0002\u0235\u0237\u0007\u000f\u0002\u0002\u0236",
    "\u0235\u0003\u0002\u0002\u0002\u0236\u0237\u0003\u0002\u0002\u0002\u0237",
    "\u0238\u0003\u0002\u0002\u0002\u0238\u023b\u0007\f\u0002\u0002\u0239",
    "\u023b\u0004\u000e\u000f\u0002\u023a\u0236\u0003\u0002\u0002\u0002\u023a",
    "\u0239\u0003\u0002\u0002\u0002\u023b\u023d\u0003\u0002\u0002\u0002\u023c",
    "\u023e\u0005\u00a3R\u0002\u023d\u023c\u0003\u0002\u0002\u0002\u023d",
    "\u023e\u0003\u0002\u0002\u0002\u023e\u023f\u0003\u0002\u0002\u0002\u023f",
    "\u0240\bQ\u0003\u0002\u0240\u00a2\u0003\u0002\u0002\u0002\u0241\u0243",
    "\t\u000b\u0002\u0002\u0242\u0241\u0003\u0002\u0002\u0002\u0243\u0244",
    "\u0003\u0002\u0002\u0002\u0244\u0242\u0003\u0002\u0002\u0002\u0244\u0245",
    "\u0003\u0002\u0002\u0002\u0245\u00a4\u0003\u0002\u0002\u0002\u0246\u0247",
    "\t\f\u0002\u0002\u0247\u00a6\u0003\u0002\u0002\u0002\u0248\u0249\t\r",
    "\u0002\u0002\u0249\u00a8\u0003\u0002\u0002\u0002\u024a\u024b\t\u000e",
    "\u0002\u0002\u024b\u00aa\u0003\u0002\u0002\u0002\u024c\u024d\t\u000f",
    "\u0002\u0002\u024d\u00ac\u0003\u0002\u0002\u0002\u024e\u024f\t\u0010",
    "\u0002\u0002\u024f\u00ae\u0003\u0002\u0002\u0002\u0250\u0252\u0005\u00b3",
    "Z\u0002\u0251\u0250\u0003\u0002\u0002\u0002\u0251\u0252\u0003\u0002",
    "\u0002\u0002\u0252\u0253\u0003\u0002\u0002\u0002\u0253\u0258\u0005\u00b5",
    "[\u0002\u0254\u0255\u0005\u00b3Z\u0002\u0255\u0256\u00070\u0002\u0002",
    "\u0256\u0258\u0003\u0002\u0002\u0002\u0257\u0251\u0003\u0002\u0002\u0002",
    "\u0257\u0254\u0003\u0002\u0002\u0002\u0258\u00b0\u0003\u0002\u0002\u0002",
    "\u0259\u025c\u0005\u00b3Z\u0002\u025a\u025c\u0005\u00afX\u0002\u025b",
    "\u0259\u0003\u0002\u0002\u0002\u025b\u025a\u0003\u0002\u0002\u0002\u025c",
    "\u025d\u0003\u0002\u0002\u0002\u025d\u025e\u0005\u00b7\\\u0002\u025e",
    "\u00b2\u0003\u0002\u0002\u0002\u025f\u0261\u0005\u00a7T\u0002\u0260",
    "\u025f\u0003\u0002\u0002\u0002\u0261\u0262\u0003\u0002\u0002\u0002\u0262",
    "\u0260\u0003\u0002\u0002\u0002\u0262\u0263\u0003\u0002\u0002\u0002\u0263",
    "\u00b4\u0003\u0002\u0002\u0002\u0264\u0266\u00070\u0002\u0002\u0265",
    "\u0267\u0005\u00a7T\u0002\u0266\u0265\u0003\u0002\u0002\u0002\u0267",
    "\u0268\u0003\u0002\u0002\u0002\u0268\u0266\u0003\u0002\u0002\u0002\u0268",
    "\u0269\u0003\u0002\u0002\u0002\u0269\u00b6\u0003\u0002\u0002\u0002\u026a",
    "\u026c\t\u0011\u0002\u0002\u026b\u026d\t\u0003\u0002\u0002\u026c\u026b",
    "\u0003\u0002\u0002\u0002\u026c\u026d\u0003\u0002\u0002\u0002\u026d\u026f",
    "\u0003\u0002\u0002\u0002\u026e\u0270\u0005\u00a7T\u0002\u026f\u026e",
    "\u0003\u0002\u0002\u0002\u0270\u0271\u0003\u0002\u0002\u0002\u0271\u026f",
    "\u0003\u0002\u0002\u0002\u0271\u0272\u0003\u0002\u0002\u0002\u0272\u00b8",
    "\u0003\u0002\u0002\u0002\u0273\u0274\t\u0012\u0002\u0002\u0274\u00ba",
    "\u0003\u0002\u0002\u0002\u0275\u0277\t\u0013\u0002\u0002\u0276\u0275",
    "\u0003\u0002\u0002\u0002\u0277\u00bc\u0003\u0002\u0002\u0002\u0278\u027b",
    "\u0005\u00bb^\u0002\u0279\u027b\t\u0014\u0002\u0002\u027a\u0278\u0003",
    "\u0002\u0002\u0002\u027a\u0279\u0003\u0002\u0002\u0002\u027b\u00be\u0003",
    "\u0002\u0002\u0002\u027c\u027e\t\u000b\u0002\u0002\u027d\u027c\u0003",
    "\u0002\u0002\u0002\u027e\u027f\u0003\u0002\u0002\u0002\u027f\u027d\u0003",
    "\u0002\u0002\u0002\u027f\u0280\u0003\u0002\u0002\u0002\u0280\u0281\u0003",
    "\u0002\u0002\u0002\u0281\u0282\b`\u0003\u0002\u0282\u00c0\u0003\u0002",
    "\u0002\u0002-\u0002\u0133\u0139\u0164\u0172\u019d\u01b5\u01b8\u01be",
    "\u01c4\u01c6\u01c9\u01d0\u01d3\u01da\u01dd\u01e4\u01e7\u01eb\u01ee\u01f2",
    "\u0200\u0207\u020d\u020f\u0217\u0222\u022b\u0233\u0236\u023a\u023d\u0244",
    "\u0251\u0257\u025b\u0262\u0268\u026c\u0271\u0276\u027a\u027f\u0004\u0002",
    "\u0003\u0002\b\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function BwaxLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

BwaxLexer.prototype = Object.create(antlr4.Lexer.prototype);
BwaxLexer.prototype.constructor = BwaxLexer;

Object.defineProperty(BwaxLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

BwaxLexer.EOF = antlr4.Token.EOF;
BwaxLexer.T__0 = 1;
BwaxLexer.T__1 = 2;
BwaxLexer.T__2 = 3;
BwaxLexer.T__3 = 4;
BwaxLexer.T__4 = 5;
BwaxLexer.T__5 = 6;
BwaxLexer.T__6 = 7;
BwaxLexer.T__7 = 8;
BwaxLexer.T__8 = 9;
BwaxLexer.T__9 = 10;
BwaxLexer.T__10 = 11;
BwaxLexer.T__11 = 12;
BwaxLexer.T__12 = 13;
BwaxLexer.T__13 = 14;
BwaxLexer.T__14 = 15;
BwaxLexer.IF = 16;
BwaxLexer.THEN = 17;
BwaxLexer.ELSE = 18;
BwaxLexer.CASE = 19;
BwaxLexer.OF = 20;
BwaxLexer.LET = 21;
BwaxLexer.REC = 22;
BwaxLexer.IN = 23;
BwaxLexer.TYPE = 24;
BwaxLexer.ALIAS = 25;
BwaxLexer.AS = 26;
BwaxLexer.IMPORT = 27;
BwaxLexer.EXPORT = 28;
BwaxLexer.EXPOSING = 29;
BwaxLexer.MODULE = 30;
BwaxLexer.AND = 31;
BwaxLexer.OR = 32;
BwaxLexer.XOR = 33;
BwaxLexer.NOT = 34;
BwaxLexer.TRUE = 35;
BwaxLexer.FALSE = 36;
BwaxLexer.EXTERNAL = 37;
BwaxLexer.OPAQUE = 38;
BwaxLexer.MultiLineComment = 39;
BwaxLexer.SingleLineComment = 40;
BwaxLexer.POW = 41;
BwaxLexer.MUL = 42;
BwaxLexer.DIV = 43;
BwaxLexer.ADD = 44;
BwaxLexer.SUB = 45;
BwaxLexer.MOD = 46;
BwaxLexer.SEMICOLON = 47;
BwaxLexer.ELLIPSIS = 48;
BwaxLexer.SHARP = 49;
BwaxLexer.WAVE = 50;
BwaxLexer.GT = 51;
BwaxLexer.GE = 52;
BwaxLexer.EQ = 53;
BwaxLexer.LT = 54;
BwaxLexer.LE = 55;
BwaxLexer.NE = 56;
BwaxLexer.CONS = 57;
BwaxLexer.APPEND = 58;
BwaxLexer.PIPE_FROM = 59;
BwaxLexer.PIPE_TO = 60;
BwaxLexer.COMP_FROM = 61;
BwaxLexer.COMP_TO = 62;
BwaxLexer.INTEGER = 63;
BwaxLexer.DECIMAL_INTEGER = 64;
BwaxLexer.OCT_INTEGER = 65;
BwaxLexer.HEX_INTEGER = 66;
BwaxLexer.BIN_INTEGER = 67;
BwaxLexer.FLOAT_NUMBER = 68;
BwaxLexer.IMAG_NUMBER = 69;
BwaxLexer.DOT_CAP_NAME = 70;
BwaxLexer.DOT_NAME = 71;
BwaxLexer.NAME = 72;
BwaxLexer.CAP_NAME = 73;
BwaxLexer.STRING = 74;
BwaxLexer.CHAR = 75;
BwaxLexer.LONG_STRING = 76;
BwaxLexer.NL = 77;
BwaxLexer.WS = 78;

BwaxLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

BwaxLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

BwaxLexer.prototype.literalNames = [ null, "'('", "','", "')'", "'['", "']'", 
                                     "'{'", "'}'", "'|'", "'\\cc'", "'->'", 
                                     "'\\'", "':'", "'!'", "'='", "'_'", 
                                     "'if'", "'then'", "'else'", "'case'", 
                                     "'of'", "'let'", "'rec'", "'in'", "'type'", 
                                     "'alias'", "'as'", "'import'", "'export'", 
                                     "'exposing'", "'module'", null, null, 
                                     "'xor'", "'not'", "'True'", "'False'", 
                                     "'external'", "'opaque'", null, null, 
                                     "'^'", "'*'", "'/'", "'+'", "'-'", 
                                     "'%'", "';'", "'..'", "'#'", "'~'", 
                                     "'>'", "'>='", "'=='", "'<'", "'<='", 
                                     null, "'::'", "'++'", "'<|'", "'|>'", 
                                     "'<<'", "'>>'" ];

BwaxLexer.prototype.symbolicNames = [ null, null, null, null, null, null, 
                                      null, null, null, null, null, null, 
                                      null, null, null, null, "IF", "THEN", 
                                      "ELSE", "CASE", "OF", "LET", "REC", 
                                      "IN", "TYPE", "ALIAS", "AS", "IMPORT", 
                                      "EXPORT", "EXPOSING", "MODULE", "AND", 
                                      "OR", "XOR", "NOT", "TRUE", "FALSE", 
                                      "EXTERNAL", "OPAQUE", "MultiLineComment", 
                                      "SingleLineComment", "POW", "MUL", 
                                      "DIV", "ADD", "SUB", "MOD", "SEMICOLON", 
                                      "ELLIPSIS", "SHARP", "WAVE", "GT", 
                                      "GE", "EQ", "LT", "LE", "NE", "CONS", 
                                      "APPEND", "PIPE_FROM", "PIPE_TO", 
                                      "COMP_FROM", "COMP_TO", "INTEGER", 
                                      "DECIMAL_INTEGER", "OCT_INTEGER", 
                                      "HEX_INTEGER", "BIN_INTEGER", "FLOAT_NUMBER", 
                                      "IMAG_NUMBER", "DOT_CAP_NAME", "DOT_NAME", 
                                      "NAME", "CAP_NAME", "STRING", "CHAR", 
                                      "LONG_STRING", "NL", "WS" ];

BwaxLexer.prototype.ruleNames = [ "T__0", "T__1", "T__2", "T__3", "T__4", 
                                  "T__5", "T__6", "T__7", "T__8", "T__9", 
                                  "T__10", "T__11", "T__12", "T__13", "T__14", 
                                  "IF", "THEN", "ELSE", "CASE", "OF", "LET", 
                                  "REC", "IN", "TYPE", "ALIAS", "AS", "IMPORT", 
                                  "EXPORT", "EXPOSING", "MODULE", "AND", 
                                  "OR", "XOR", "NOT", "TRUE", "FALSE", "EXTERNAL", 
                                  "OPAQUE", "MultiLineComment", "SingleLineComment", 
                                  "POW", "MUL", "DIV", "ADD", "SUB", "MOD", 
                                  "SEMICOLON", "ELLIPSIS", "SHARP", "WAVE", 
                                  "GT", "GE", "EQ", "LT", "LE", "NE", "CONS", 
                                  "APPEND", "PIPE_FROM", "PIPE_TO", "COMP_FROM", 
                                  "COMP_TO", "INTEGER", "DECIMAL_INTEGER", 
                                  "OCT_INTEGER", "HEX_INTEGER", "BIN_INTEGER", 
                                  "FLOAT_NUMBER", "IMAG_NUMBER", "DOT_CAP_NAME", 
                                  "DOT_NAME", "NAME", "CAP_NAME", "STRING", 
                                  "CHAR", "LONG_STRING", "LONG_STRING_ITEM", 
                                  "LONG_STRING_CHAR", "STRING_ESCAPE_SEQ", 
                                  "NL", "SPACES", "NON_ZERO_DIGIT", "DIGIT", 
                                  "OCT_DIGIT", "HEX_DIGIT", "BIN_DIGIT", 
                                  "POINT_FLOAT", "EXPONENT_FLOAT", "INT_PART", 
                                  "FRACTION", "EXPONENT", "UPPER", "LOWER_ID_START", 
                                  "ID_CONTINUE", "WS" ];

BwaxLexer.prototype.grammarFileName = "Bwax.g4";


exports.BwaxLexer = BwaxLexer;

