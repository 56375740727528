
import React, { Suspense, useEffect, useRef, useState } from 'react';


import Select from 'react-select'

import './TestClient.less';

// import 'rsuite/dist/rsuite.min.css';

import InputPicker from 'rsuite/InputPicker';
import DatePicker from 'rsuite/DatePicker';
import Cascader from 'rsuite/Cascader';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Placeholder from 'rsuite/Placeholder';

import Popover from 'rsuite/Popover';
import Whisper from 'rsuite/Whisper';

import ButtonToolbar from 'rsuite/ButtonToolbar';

import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';
import Dropdown from 'rsuite/Dropdown'

import Table from 'rsuite/Table';

import IconButton from 'rsuite/IconButton';

// import * as antIcons from '@ant-design/icons';


import { Setting, Dashboard, Tools, Peoples, Plus, Minus } from '@rsuite/icons';

import Drawer from 'rsuite/Drawer';

const Paragraph = Placeholder.Paragraph;

// or
import Notification from 'rsuite/Notification';
import toaster from 'rsuite/toaster';


export default function TestClent(props) {

    // console.log("ANT ICONS", antIcons, Object.keys(antIcons).length);

    return (
        <div className="test-client">
            <div className="side-bar">
                <Sidenav defaultOpenKeys={['3', '4']} activeKey="1">
                    <Sidenav.Body>
                        <Nav>
                            <Nav.Item eventKey="1" icon={<Dashboard />}>
                                Dashboard
                            </Nav.Item>
                            <Nav.Item eventKey="2" icon={<Peoples />}>
                                User Group
                            </Nav.Item>
                            <Dropdown eventKey="3" title="Advanced" icon={<Tools />}>
                                <Dropdown.Item eventKey="3-1">Geo</Dropdown.Item>
                                <Dropdown.Item eventKey="3-2">Devices</Dropdown.Item>
                                <Dropdown.Item eventKey="3-3">Loyalty</Dropdown.Item>
                                <Dropdown.Item eventKey="3-4">Visit Depth</Dropdown.Item>
                            </Dropdown>
                            <Dropdown eventKey="4" title="Settings" icon={<Setting />}>
                                <Dropdown.Item eventKey="4-1">Applications</Dropdown.Item>
                                <Dropdown.Item eventKey="4-2">Channels</Dropdown.Item>
                                <Dropdown.Item eventKey="4-3">Versions</Dropdown.Item>
                                <Dropdown.Menu eventKey="4-5" title="Custom Action">
                                    <Dropdown.Item eventKey="4-5-1">Action Name</Dropdown.Item>
                                    <Dropdown.Item eventKey="4-5-2">Action Params</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div>
            {/*  */}
            <div className="main">
                {/* <div style={{width: "1rem", height: "600px"}}></div> */}
                <TestInputPicker />
                <DatePicker placeholder="Large" />
                <TestCascade />
                <TestNotification />
                <TestModal />
                <TestDrawer />
                <TestSelect />
                <TestPopover />
                <TestTable />
            </div>
        </div>
    )
}

function TestNotification(props) {
    const onClick = () => {
        toaster.push(<Notification>message</Notification>, {
            placement: 'topEnd'
          });
    };

    return (
        <Button onClick={onClick} color="red" appearance="primary">Click to Notify</Button>
    )

}


function TestTable(props) {

    const rowKey = 'id';
    const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
        <Cell {...props}>
            <IconButton
                size="xs"
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    expandedRowKeys.some((key) => key === rowData[rowKey]) ? <Minus /> : <Plus />
                }
            />
        </Cell>
    );

    const renderRowExpanded = (rowData) => {
        return (
            <div>
                <div
                    style={{
                        width: 60,
                        height: 60,
                        float: 'left',
                        marginRight: 10,
                        background: '#eee',
                    }}
                >
                    <img src={rowData.avartar} style={{ width: 60 }} />
                </div>
                <p>{rowData.email}</p>
                <p>{rowData.date}</p>
            </div>
        );
    };


    const data = users.filter((v, i) => i < 5);
    const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

    const handleExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach((key) => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const { Column, HeaderCell, Cell } = Table;

    return (
        <Table
            height={300}
            data={data}
            rowKey={rowKey}
            expandedRowKeys={expandedRowKeys}
            onRowClick={(data) => {
                console.log(data);
            }}
            renderRowExpanded={renderRowExpanded}
        >
            <Column width={70} align="center">
                <HeaderCell>#</HeaderCell>
                <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
            </Column>

            <Column width={130}>
                <HeaderCell>First Name</HeaderCell>
                <Cell dataKey="firstName" />
            </Column>

            <Column width={130}>
                <HeaderCell>Last Name</HeaderCell>
                <Cell dataKey="lastName" />
            </Column>

            <Column width={200}>
                <HeaderCell>City</HeaderCell>
                <Cell dataKey="city" />
            </Column>

            <Column width={200}>
                <HeaderCell>Street</HeaderCell>
                <Cell dataKey="street" />
            </Column>
        </Table>
    );

}

function TestPopover(props) {

    const speaker = (
        <Popover title="Title">
            <p>This is a default Popover </p>
            <p>Content</p>
            <p>
                <a>link</a>
            </p>
        </Popover>
    );

    return (
        <Whisper placement="top" trigger="click" controlId="control-id-click" speaker={speaker}>
            <Button>Click to Popover</Button>
        </Whisper>
    )

}

function TestDrawer(props) {
    const [open, setOpen] = React.useState(false);
    const [openWithHeader, setOpenWithHeader] = React.useState(false);

    return (
        <div>
            <ButtonToolbar>
                <Button onClick={() => setOpen(true)}>Open</Button>
                <Button onClick={() => setOpenWithHeader(true)}>Open with header</Button>
            </ButtonToolbar>

            <Drawer open={open} onClose={() => setOpen(false)}>
                <Drawer.Body>
                    <Paragraph />
                </Drawer.Body>
            </Drawer>

            <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
                <Drawer.Header>
                    <Drawer.Title>Drawer Title</Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
                        <Button onClick={() => setOpenWithHeader(false)} appearance="primary">
                            Confirm
                        </Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <Paragraph />
                </Drawer.Body>
            </Drawer>
        </div>
    );
}

function TestInputPicker(props) {

    const containerRef = useRef(null);

    return (
        <div ref={containerRef}>
            <InputPicker data={options} block container={containerRef.current} />
        </div>
    )
}


function TestModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
    };
    return (
        <div className="modal-container">
            {/* <ButtonToolbar> */}
            <Button onClick={handleOpen}> Open</Button>
            {/* </ButtonToolbar> */}

            <Modal open={open} onClose={handleClose} backdrop={true}>
                <div>Hello Modal</div>
                {/* <Modal.Header>
                    <Modal.Title>Modal Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Hello Modal</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
}


function TestSelect(props) {

    const [step, setStep] = useState(0);

    const options = step % 2 === 0 ? [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'mocha', label: 'Mocha' }
    ] : [
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'mocha', label: 'Mocha' }
    ]

    useEffect(() => {
        const timer = setInterval(() => {
            setStep(prev => prev + 1);
        }, 1000);
        return () => {
            clearInterval(timer)
        }
    }, [])

    function onChange(value) {
        console.log(`selected`, value);
    }

    function onInputChange(newValue, actionMeta) {
        console.log("input changed", newValue, actionMeta);
    }

    return <Select options={options} {...{
        isSearchable: true,
        onChange, onInputChange,
    }} />
}


function TestCascade() {
    const [value, setValue] = useState("1-1-1");

    console.log("Value", value);

    return (
        <Cascader data={casData} style={{ width: 224 }} {...{
            value, searchable: false,
            onSelect: (item, selectedPaths) => {
                console.log("ON SELECT", item, selectedPaths);
            }
        }} />
    )
}

const casData = [
    {
        "value": "1",
        "label": "四川",
        "children": [
            {
                "label": "成都市",
                "value": "1-1",
                "children": [
                    {
                        "value": "1-1-1",
                        "label": "锦江区"
                    },
                    {
                        "value": "1-1-2",
                        "label": "青羊区"
                    },
                    {
                        "value": "1-1-3",
                        "label": "金牛区"
                    },
                    {
                        "value": "1-1-4",
                        "label": "武侯区"
                    },
                    {
                        "value": "1-1-5",
                        "label": "成华区"
                    }
                ]
            },
            {
                "value": "1-2",
                "label": "自贡市",
                "children": [
                    {
                        "value": "1-2-1",
                        "label": "自流井区"
                    },
                    {
                        "value": "1-2-2",
                        "label": "贡井区"
                    },
                    {
                        "value": "1-2-3",
                        "label": "大安区"
                    },
                    {
                        "value": "1-2-4",
                        "label": "沿滩区"
                    },
                    {
                        "value": "1-2-5",
                        "label": "荣　县"
                    },
                    {
                        "value": "1-2-6",
                        "label": "富顺县"
                    }
                ]
            },
            {
                "value": "1-3",
                "label": "攀枝花市",
                "children": [
                    {
                        "value": "1-3-1",
                        "label": "东　区"
                    },
                    {
                        "value": "1-3-2",
                        "label": "西　区"
                    },
                    {
                        "value": "1-3-3",
                        "label": "仁和区"
                    },
                    {
                        "value": "1-3-4",
                        "label": "米易县"
                    },
                    {
                        "value": "1-3-5",
                        "label": "盐边县"
                    }
                ]
            }
        ]
    },
    {
        "label": "贵州",
        "value": "2",
        "children": [
            {
                "value": "2-1",
                "label": "贵阳市",
                "children": [
                    {
                        "value": "2-1-1",
                        "label": "南明区"
                    },
                    {
                        "value": "2-1-2",
                        "label": "云岩区"
                    },
                    {
                        "value": "2-1-3",
                        "label": "花溪区"
                    },
                    {
                        "value": "2-1-4",
                        "label": "乌当区"
                    },
                    {
                        "value": "2-1-5",
                        "label": "白云区"
                    },
                    {
                        "value": "2-1-6",
                        "label": "小河区"
                    },
                    {
                        "value": "2-1-7",
                        "label": "开阳县"
                    },
                    {
                        "value": "2-1-8",
                        "label": "息烽县"
                    },
                    {
                        "value": "2-1-9",
                        "label": "修文县"
                    },
                    {
                        "value": "2-1-10",
                        "label": "清镇市"
                    }
                ]
            },
            {
                "value": "2-2",
                "label": "六盘水市",
                "children": [
                    {
                        "value": "2-2-1",
                        "label": "钟山区"
                    },
                    {
                        "value": "2-2-2",
                        "label": "六枝特区"
                    },
                    {
                        "value": "2-2-3",
                        "label": "水城县"
                    },
                    {
                        "value": "2-2-4",
                        "label": "盘　县"
                    }
                ]
            },
            {
                "value": "2-3",
                "label": "遵义市",
                "children": [
                    {
                        "value": "2-3-1",
                        "label": "红花岗区"
                    },
                    {
                        "value": "2-3-2",
                        "label": "汇川区"
                    },
                    {
                        "value": "2-3-3",
                        "label": "遵义县"
                    },
                    {
                        "value": "2-3-4",
                        "label": "桐梓县"
                    },
                    {
                        "value": "2-3-5",
                        "label": "绥阳县"
                    },
                    {
                        "value": "2-3-6",
                        "label": "正安县"
                    },
                    {
                        "value": "2-3-7",
                        "label": "道真仡佬族苗族自治县"
                    },
                    {
                        "value": "2-3-8",
                        "label": "务川仡佬族苗族自治县"
                    },
                    {
                        "value": "2-3-9",
                        "label": "凤冈县"
                    },
                    {
                        "value": "2-3-10",
                        "label": "湄潭县"
                    },
                    {
                        "value": "2-3-11",
                        "label": "余庆县"
                    },
                    {
                        "value": "2-3-12",
                        "label": "习水县"
                    },
                    {
                        "value": "2-3-13",
                        "label": "赤水市"
                    },
                    {
                        "value": "2-3-14",
                        "label": "仁怀市"
                    }
                ]
            },
            {
                "value": "2-4",
                "label": "安顺市",
                "children": [
                    {
                        "value": "2-4-1",
                        "label": "西秀区"
                    },
                    {
                        "value": "2-4-2",
                        "label": "平坝县"
                    },
                    {
                        "value": "2-4-3",
                        "label": "普定县"
                    },
                    {
                        "value": "2-4-4",
                        "label": "镇宁布依族苗族自治县"
                    },
                    {
                        "value": "2-4-5",
                        "label": "关岭布依族苗族自治县"
                    },
                    {
                        "value": "2-4-6",
                        "label": "紫云苗族布依族自治县"
                    }
                ]
            }
        ]
    }
];




const options = [
    {
        "label": "Eugenia",
        "value": "Eugenia",
        "role": "Master"
    },
    {
        "label": "Kariane",
        "value": "Kariane",
        "role": "Master"
    },
    {
        "label": "Louisa",
        "value": "Louisa",
        "role": "Master"
    },
    {
        "label": "Marty",
        "value": "Marty",
        "role": "Master"
    },
    {
        "label": "Kenya",
        "value": "Kenya",
        "role": "Master"
    },
    {
        "label": "Hal",
        "value": "Hal",
        "role": "Developer"
    },
    {
        "label": "Julius",
        "value": "Julius",
        "role": "Developer"
    },
    {
        "label": "Travon",
        "value": "Travon",
        "role": "Developer"
    },
    {
        "label": "Vincenza",
        "value": "Vincenza",
        "role": "Developer"
    },
];



const users = [
    {
        "id": 1,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=E",
        "city": "New Amieshire",
        "email": "Leora13@yahoo.com",
        "firstName": "Ernest Schuppe SchuppeSchuppeSchuppeSchuppeSchuppeSchuppe Schuppe",
        "lastName": "Schuppe",
        "street": "Ratke Port",
        "zipCode": "17026-3154",
        "date": "2016-09-23T07:57:40.195Z",
        "bs": "global drive functionalities",
        "catchPhrase": "Intuitive impactful software",
        "companyName": "Lebsack - Nicolas",
        "words": "saepe et omnis",
        "sentence": "Quos aut sunt id nihil qui.",
        "stars": 820,
        "followers": 70
    },
    {
        "id": 2,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=J",
        "city": "New Gust",
        "email": "Mose_Gerhold51@yahoo.com",
        "firstName": "Janis",
        "lastName": "Vandervort",
        "street": "Dickinson Keys",
        "zipCode": "43767",
        "date": "2017-03-06T09:59:12.551Z",
        "bs": "e-business maximize bandwidth",
        "catchPhrase": "De-engineered discrete secured line",
        "companyName": "Glover - Hermiston",
        "words": "deleniti dolor nihil",
        "sentence": "Illo quidem libero corporis laborum.",
        "stars": 1200,
        "followers": 170
    },
    {
        "id": 3,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=M",
        "city": "Lefflerstad",
        "email": "Frieda.Sauer61@gmail.com",
        "firstName": "Makenzie",
        "lastName": "Bode",
        "street": "Legros Divide",
        "zipCode": "54812",
        "date": "2016-12-08T13:44:26.557Z",
        "bs": "plug-and-play e-enable content",
        "catchPhrase": "Ergonomic 6th generation challenge",
        "companyName": "Williamson - Kassulke",
        "words": "quidem earum magnam",
        "sentence": "Nam qui perferendis ut rem vitae saepe.",
        "stars": 610,
        "followers": 170
    },
    {
        "id": 4,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=C",
        "city": "East Catalina",
        "email": "Eloisa.OHara@hotmail.com",
        "firstName": "Ciara",
        "lastName": "Towne",
        "street": "Schimmel Ramp",
        "zipCode": "76315-2246",
        "date": "2016-07-19T12:54:30.994Z",
        "bs": "extensible innovate e-business",
        "catchPhrase": "Upgradable local model",
        "companyName": "Hilpert, Eichmann and Brown",
        "words": "exercitationem rerum sit",
        "sentence": "Qui rerum ipsa atque qui.",
        "stars": 5322,
        "followers": 170
    },
    {
        "id": 5,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=S",
        "city": "Ritchieborough",
        "email": "Brisa46@hotmail.com",
        "firstName": "Suzanne",
        "lastName": "Wolff",
        "street": "Lemuel Radial",
        "zipCode": "88870-3897",
        "date": "2017-02-23T17:11:53.875Z",
        "bs": "back-end orchestrate networks",
        "catchPhrase": "Exclusive human-resource knowledge base",
        "companyName": "Mayer - Considine",
        "words": "voluptatum tempore at",
        "sentence": "Enim quia deleniti molestiae aut.",
        "stars": 852,
        "followers": 770
    },
    {
        "id": 6,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=A",
        "city": "Lake Emery",
        "email": "Cody.Schultz56@gmail.com",
        "firstName": "Alessandra",
        "lastName": "Feeney",
        "street": "Mosciski Estate",
        "zipCode": "81514",
        "date": "2016-06-30T05:23:18.734Z",
        "bs": "sexy evolve technologies",
        "catchPhrase": "Virtual hybrid throughput",
        "companyName": "Nikolaus and Sons",
        "words": "alias minus repudiandae",
        "sentence": "Sed qui eius excepturi sunt voluptates.",
        "stars": 3209,
        "followers": 2780
    },
    {
        "id": 7,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=M",
        "city": "East Dejuan",
        "email": "Enrico_Beer@yahoo.com",
        "firstName": "Margret",
        "lastName": "Heller",
        "street": "Gunner Drive",
        "zipCode": "17423-9317",
        "date": "2017-03-13T21:09:47.253Z",
        "bs": "wireless morph synergies",
        "catchPhrase": "Profit-focused radical help-desk",
        "companyName": "Corwin, Maggio and Wintheiser",
        "words": "temporibus possimus neque",
        "sentence": "Eum amet ea non natus qui assumenda illo officia qui.",
        "stars": 9920,
        "followers": 570
    },
    {
        "id": 8,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=E",
        "city": "Schummtown",
        "email": "Mitchel.Herman@yahoo.com",
        "firstName": "Emiliano",
        "lastName": "Moore",
        "street": "Maria Junctions",
        "zipCode": "33930-7081",
        "date": "2016-03-27T07:26:57.345Z",
        "bs": "customized integrate e-tailers",
        "catchPhrase": "Total system-worthy contingency",
        "companyName": "Gulgowski - Botsford",
        "words": "deleniti ipsa hic",
        "sentence": "Ducimus id quaerat neque.",
        "stars": 3820,
        "followers": 880
    },
    {
        "id": 9,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=A",
        "city": "Gilberthaven",
        "email": "Gaylord_Reichel16@yahoo.com",
        "firstName": "Alessandra",
        "lastName": "Smith",
        "street": "Kali Spurs",
        "zipCode": "01370",
        "date": "2017-01-24T22:11:53.835Z",
        "bs": "extensible repurpose action-items",
        "catchPhrase": "Virtual dedicated definition",
        "companyName": "Maggio LLC",
        "words": "libero unde est",
        "sentence": "Non adipisci hic laboriosam et qui laudantium aspernatur.",
        "stars": 330,
        "followers": 590
    },
    {
        "id": 10,
        "avartar": "https://via.placeholder.com/40x40/3498ff/FFFFFF?text=E",
        "city": "Felicitychester",
        "email": "Eileen48@gmail.com",
        "firstName": "Eldridge",
        "lastName": "Bins",
        "street": "Casper Squares",
        "zipCode": "80025-1552",
        "date": "2016-07-20T05:59:45.630Z",
        "bs": "cutting-edge expedite partnerships",
        "catchPhrase": "Organic user-facing functionalities",
        "companyName": "Leffler, Cummerata and Price",
        "words": "sed exercitationem quas",
        "sentence": "Voluptas dolorem quasi aspernatur.",
        "stars": 923,
        "followers": 704
    }];