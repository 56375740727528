
import dagre from 'dagre';

export function getLayoutedElements(uiNodes, uiEdges, direction = 'TB') {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({
        rankdir: direction, marginx: 48, marginy: 48,
        nodesep: 36, ranksep: 46
    });

    uiNodes.forEach(el => {
        const nodeWidth = el.size.width;
        const nodeHeight = el.size.height;
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    });

    uiEdges.forEach(el => {
        dagreGraph.setEdge(el.source, el.target);
    })

    dagre.layout(dagreGraph);

    return uiNodes.map(el => {
        const nodeWidth = el.size.width;
        const nodeHeight = el.size.height;

        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? 'left' : 'top';
        el.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
            x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return el
    });
};