import React from 'react'

import { mergeBlockData, removeBlock } from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

import { imageStyle, imageBlockStyle, insertImage, processImageURL } from './imagePluginHelpers'

function trimPercentage(width) {
    if (!isNaN(width)) {
        return parseInt(width)
    } else {
        if (width.endsWith('%')) {
            const trimmed = width.substring(0, width.length - 1)
            if (isNaN(width)) {
                return parseInt(width)
            } else {
                return width
            }
        }
    }
}

class ImageBlock extends React.Component {

    render() {
        const {
            block, // eslint-disable-line no-unused-vars
            blockStyleFn,
            blockProps, // eslint-disable-line no-unused-vars
            customStyleMap, // eslint-disable-line no-unused-vars
            customStyleFn, // eslint-disable-line no-unused-vars
            decorator, // eslint-disable-line no-unused-vars
            forceSelection, // eslint-disable-line no-unused-vars
            offsetKey, // eslint-disable-line no-unused-vars
            selection, // eslint-disable-line no-unused-vars
            tree, // eslint-disable-line no-unused-vars
            contentState, // eslint-disable-line no-unused-vars
            preventScroll,
            ...elementProps
        } = this.props

        const blockStyle = {
            ...imageBlockStyle({
                width: block.getData().get('width'),
                align: block.getData().get('align')
            }),
            ...elementProps.style
        }

        const uploading = block.getData().get('uploading')
        const src = block.getData().get("src")
        return (
            <div
                {...elementProps}
                className={"editor-atomic-block editor-image-block" + " " + elementProps.className + `${uploading ? ' uploading' : ''}`}
                style={blockStyle}>
                <img src={processImageURL(src)} style={imageStyle()} />
            </div>
        )
    }
}


const createImagePlugin = (config = {}) => {
    const component = config.decorator ? config.decorator(ImageBlock) : ImageBlock;
    return {

        handlePastedFiles: (files, { setEditorState, getEditorState, getProps }) => {

            const { uploadImage } = getProps()

            if (files && files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    const f = files[i]
                    /// if the file is an image
                    if (f && f.type.match(/image.*/)) {
                        insertImage(f, null, uploadImage, { setEditorState, getEditorState })
                        return 'handled'
                    }
                }
            }
            return 'handled'
        },

        handleDroppedFiles: (selection, files, { setEditorState, getEditorState, getProps }) => {

            const { uploadImage } = getProps()

            if (files && files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    const f = files[i]
                    /// if the file is an image
                    if (f && f.type.match(/image.*/)) {
                        /// insert image
                        insertImage(f, selection, uploadImage, { setEditorState, getEditorState })
                        // only support on of the image:
                        return "handled"
                    }
                }
            }
            return 'not-handled'
        },

        blockRendererFn: (block, { getEditorState, setEditorState }) => {
            if (block.getType() === 'atomic' &&
                block.getData().get("type") === "IMAGE") {

                const width = block.getData().get('width')

                return {
                    component,
                    editable: false,
                    props: {
                        getEditorState,
                        setEditorState,
                        resizeData: {
                            // FIXME this is coupled with Resizable
                            width: width ? trimPercentage(width) : undefined
                        }
                    }
                }
            }
        },

        buttonsForBlock: (block, { getEditorState, setEditorState }) => {
            if (block.getType() === 'atomic' && block.getData().get('type') === 'IMAGE') {
                return [
                    createImageAlignButton('left', 'fa fa-long-arrow-left'),
                    createImageAlignButton('center', 'fa fa-laptop'),
                    createImageAlignButton('right', 'fa fa-long-arrow-right'),
                    'seperator',
                    'hyperlink',
                    'seperator',
                    createRemoveBlockButton(block),

                ]
            }
        }

    };
};

/// buttons
function createImageAlignButton(align, iconClass) {
    return (props) => {

        const { setEditorState, getEditorState } = props
        return (
            <button className="editor-button" onClick={() => {
                setEditorState(
                    mergeBlockData(getEditorState(), null, { align })
                )
            }}>
                <i className={iconClass} aria-hidden="true"></i>
            </button>
        )
    }
}

function createRemoveBlockButton(block) {
    return (props) => {
        const { setEditorState, getEditorState } = props
        return (
            <button className="editor-button" onClick={() => {
                setEditorState(
                    removeBlock(getEditorState(), block.getKey())
                )
            }}>
                <i className={'fa fa-trash'} aria-hidden="true"></i>
            </button>
        )
    }
}


export default createImagePlugin;
