// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("./mod/runtime_common.bs.js");
var Exec_helper = require("bwax/ml/lang/exec_helper");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");
var Query_command_m_handlers$BwaxAdmin = require("../query/query_command_m_handlers.bs.js");
var Record_dependency_resolver$BwaxAdmin = require("./record_dependency_resolver.bs.js");

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        name + ": Invalid Arguments"
      ];
}

function builtin_handler(context, execute_cmdm, execute_cmd, param, maybe_binds, maybe_processor, on_result) {
  var config = param[1];
  var get_actual_binds = function (determine_dependency) {
    if (maybe_processor !== undefined) {
      var deps = Curry._4(determine_dependency, context[/* entity_dict */0], context[/* data_type_dict */1], context[/* dts */2], maybe_processor);
      return Plate$BwaxAdmin.List.map((function (maybe_dep) {
                    return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                  if (param.tag) {
                                    return ;
                                  } else {
                                    return /* tuple */[
                                            param[0],
                                            param[1][3]
                                          ];
                                  }
                                }), maybe_dep);
                  }), deps);
    } else {
      return Plate$BwaxAdmin.$$Option.with_default(/* [] */0, maybe_binds);
    }
  };
  var make_data_tagger_type = function (actual_binds) {
    var make_type_from_binds = function (binds, param) {
      if (param !== undefined) {
        var match = param;
        var tname = Base_query_types$BwaxAdmin.tname_of(match[0]);
        return /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                      "var_type",
                      "n",
                      "t"
                    ], [
                      Caml_chrome_debugger.variant("Record_readonly", 1, [
                          tname,
                          match[1]
                        ]),
                      0,
                      1
                    ])]);
      } else {
        return Runtime_common$BwaxAdmin.nt;
      }
    };
    var pt;
    if (actual_binds) {
      if (actual_binds[1]) {
        var ts = Plate$BwaxAdmin.List.indexed_map(make_type_from_binds, actual_binds);
        pt = /* Term */Caml_chrome_debugger.variant("Term", 0, [
            "Tuple",
            ts
          ]);
      } else {
        pt = make_type_from_binds(0, actual_binds[0]);
      }
    } else {
      pt = Runtime_common$BwaxAdmin.nt;
    }
    return Lang_typing_base$BwaxAdmin.arrow(pt, Runtime_common$BwaxAdmin.nt);
  };
  switch (param[0]) {
    case "fail" :
        if (config && !config[1]) {
          Curry._1(on_result, /* Fail */Caml_chrome_debugger.variant("Fail", 1, [config[0]]));
          return true;
        } else {
          return false;
        }
    case "return" :
        if (config && !config[1]) {
          Curry._1(on_result, /* Return */Caml_chrome_debugger.variant("Return", 0, [config[0]]));
          return true;
        } else {
          return false;
        }
    case "sequence" :
        if (config) {
          var match = config[0][0];
          if (typeof match === "number" || !(match.tag === /* V_list */1 && !config[1])) {
            return false;
          } else {
            var actual_binds = get_actual_binds(Record_dependency_resolver$BwaxAdmin.determine_sequence_cmdm_processor_dependencies);
            var execute_one = function (cmd, cont) {
              return Curry._3(execute_cmdm, actual_binds, cmd, cont);
            };
            var tasks = Plate$BwaxAdmin.List.map(execute_one, match[0]);
            Exec_helper.runTasksOneByOne(Curry._1(Plate$BwaxAdmin.List.to_array, tasks), (function (result_arr) {
                    var results = Curry._1(Plate$BwaxAdmin.List.from_array, result_arr);
                    var is_error = function (param) {
                      if (param.tag) {
                        return true;
                      } else {
                        return false;
                      }
                    };
                    var extract_value = function (param) {
                      if (param.tag) {
                        return ;
                      } else {
                        return Caml_option.some(param[0]);
                      }
                    };
                    var match = Plate$BwaxAdmin.List.find(is_error, results);
                    return Curry._1(on_result, match !== undefined ? match : /* Return */Caml_chrome_debugger.variant("Return", 0, [Runtime_common$BwaxAdmin.pack_list(Plate$BwaxAdmin.List.keep_map(extract_value, results))]));
                  }));
            return true;
          }
        } else {
          return false;
        }
    case "wrap" :
        if (config && !config[1]) {
          var tagger = Runtime_common$BwaxAdmin.pack_func_with_type(1, (function (param) {
                  if (param && !param[1]) {
                    return Runtime_common$BwaxAdmin.pack_tagged("__CmdM_wrap__value__", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  param[0],
                                  /* [] */0
                                ]));
                  } else {
                    return fail("tagger for CmdM.wrap");
                  }
                }), make_data_tagger_type(get_actual_binds(Record_dependency_resolver$BwaxAdmin.determine_cmdm_processor_dependencies)));
          var cmd = Lang_eval$BwaxAdmin.apply_value(undefined, config[0], tagger, Runtime_common$BwaxAdmin.nt);
          Curry._3(execute_cmd, cmd, (function (r) {
                  if (!r.tag) {
                    var match = r[0];
                    if (match) {
                      var match$1 = match[0][0];
                      if (typeof match$1 !== "number" && match$1.tag === /* V_tagged */6 && match$1[0] === "__CmdM_wrap__value__") {
                        var match$2 = match$1[1];
                        if (match$2 && !match$2[1] && !match[1]) {
                          return Curry._1(on_result, /* Return */Caml_chrome_debugger.variant("Return", 0, [match$2[0]]));
                        }
                        
                      }
                      
                    }
                    
                  }
                  console.log("Warning: not handled result", r);
                  return /* () */0;
                }), (function (param) {
                  return /* () */0;
                }));
          return true;
        } else {
          return false;
        }
    case "wrap2" :
        if (config && !config[1]) {
          var data_tagger = Runtime_common$BwaxAdmin.pack_func_with_type(1, (function (param) {
                  if (param && !param[1]) {
                    return Runtime_common$BwaxAdmin.pack_tagged("__CmdM_wrap2__value__", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  param[0],
                                  /* [] */0
                                ]));
                  } else {
                    return fail("data tagger for CmdM.wrap2");
                  }
                }), make_data_tagger_type(get_actual_binds(Record_dependency_resolver$BwaxAdmin.determine_cmdm_processor_dependencies)));
          var error_tagger = Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                  if (param && !param[1]) {
                    return Runtime_common$BwaxAdmin.pack_tagged("__CmdM_wrap2__error__", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  param[0],
                                  /* [] */0
                                ]));
                  } else {
                    return fail("error tagger for CmdM.wrap2");
                  }
                }));
          var cmd$1 = Lang_eval$BwaxAdmin.apply_value_args(undefined, config[0], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  data_tagger,
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      error_tagger,
                      /* [] */0
                    ])
                ]), Runtime_common$BwaxAdmin.nt);
          Curry._3(execute_cmd, cmd$1, (function (r) {
                  if (!r.tag) {
                    var match = r[0];
                    if (match) {
                      var match$1 = match[0][0];
                      if (typeof match$1 !== "number" && match$1.tag === /* V_tagged */6) {
                        switch (match$1[0]) {
                          case "__CmdM_wrap2__error__" :
                              var match$2 = match$1[1];
                              if (match$2 && !match$2[1] && !match[1]) {
                                return Curry._1(on_result, /* Fail */Caml_chrome_debugger.variant("Fail", 1, [match$2[0]]));
                              }
                              break;
                          case "__CmdM_wrap2__value__" :
                              var match$3 = match$1[1];
                              if (match$3 && !match$3[1] && !match[1]) {
                                return Curry._1(on_result, /* Return */Caml_chrome_debugger.variant("Return", 0, [match$3[0]]));
                              }
                              break;
                          default:
                            
                        }
                      }
                      
                    }
                    
                  }
                  console.log("Warning: not handled result", r);
                  return /* () */0;
                }), (function (param) {
                  return /* () */0;
                }));
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
}

var command_m_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    builtin_handler,
    Query_command_m_handlers$BwaxAdmin.handlers
  ]);

function if_term_matters(t) {
  if (typeof t === "number" || t.tag) {
    return false;
  } else {
    switch (t[0]) {
      case "Arrow" :
          var match = t[1];
          if (match) {
            var match$1 = match[1];
            if (match$1) {
              var match$2 = match$1[0];
              if (typeof match$2 === "number" || match$2.tag || !(match$2[0] === "CmdM" && !match$1[1])) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
      case "CmdM" :
          return true;
      default:
        return false;
    }
  }
}

function execute_command_m(context, execute_cmd, all_binds, cmdm, on_result) {
  var execute_comand_m_func = function (param, param$1, param$2) {
    return execute_command_m(context, execute_cmd, param, param$1, param$2);
  };
  var do_execute = function (param, cont) {
    var processors = param[2];
    var config = param[1];
    var name = param[0];
    var try_handlers = function (_remaining) {
      while(true) {
        var remaining = _remaining;
        if (remaining) {
          var served = Curry._7(remaining[0], context, execute_comand_m_func, execute_cmd, /* tuple */[
                name,
                config
              ], all_binds, Plate$BwaxAdmin.List.head(processors), (function (r) {
                  return post_process(processors, r, cont);
                }));
          if (served) {
            return true;
          } else {
            _remaining = remaining[1];
            continue ;
          }
        } else {
          return false;
        }
      };
    };
    var served = try_handlers(command_m_handlers);
    if (served) {
      return /* () */0;
    } else {
      console.log("Warning: cmd plan is not executed", /* tuple */[
            name,
            config
          ]);
      return /* () */0;
    }
  };
  var post_process = function (_processors, _r, cont) {
    while(true) {
      var r = _r;
      var processors = _processors;
      if (r.tag) {
        return Curry._1(cont, /* Fail */Caml_chrome_debugger.variant("Fail", 1, [r[0]]));
      } else if (processors) {
        var tl = processors[1];
        var new_result = Lang_eval$BwaxAdmin.apply_value(undefined, processors[0], r[0], Runtime_common$BwaxAdmin.nt);
        var match = new_result[0];
        if (typeof match === "number") {
          _r = /* Return */Caml_chrome_debugger.variant("Return", 0, [new_result]);
          _processors = tl;
          continue ;
        } else if (match.tag === /* V_executable */14) {
          return do_execute(/* tuple */[
                      match[0],
                      match[1],
                      match[2]
                    ], (function(tl){
                    return function (r) {
                      return post_process(tl, r, cont);
                    }
                    }(tl)));
        } else {
          _r = /* Return */Caml_chrome_debugger.variant("Return", 0, [new_result]);
          _processors = tl;
          continue ;
        }
      } else {
        return Curry._1(cont, r);
      }
    };
  };
  var match = cmdm[0];
  if (typeof match === "number" || match.tag !== /* V_executable */14) {
    console.log("not a command plan", cmdm);
    return /* () */0;
  } else {
    return do_execute(/* tuple */[
                match[0],
                match[1],
                match[2]
              ], on_result);
  }
}

exports.fail = fail;
exports.builtin_handler = builtin_handler;
exports.command_m_handlers = command_m_handlers;
exports.if_term_matters = if_term_matters;
exports.execute_command_m = execute_command_m;
/* Plate-BwaxAdmin Not a pure module */
