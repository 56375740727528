
import React, { useEffect, useRef } from 'react'

import { useDrag } from 'react-dnd'

import './BlockView.less';

export default function BlockView({ 
    block, contentDict, 
    operatingState, setOperatingState, 
    executeCommand 
}) {

    const { 
        id, type, childIds = [], attributes = {}, blockType 
    } = block;

    const elRef = useRef(null);

    // iniside, oudside, left, right, top, bottom

    if (!blockType) {
        console.warn("unknow block type", type, block);
        return null;
    }

    const { isContainer } = blockType;

    function isInside(slotIndex) {
        // not inside any child
        return (
            operatingState.dropTarget && operatingState.dropTarget.parent === id && isContainer
            && (slotIndex !== undefined ? operatingState.dropTarget.slotIndex === slotIndex : true)
        )
    }

    const dragItem = {
        blockType,
        id,
        isNew: false
    }

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: blockType.name,
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        item: dragItem,

        collect: (monitor) => {
            return {
                 isDragging: monitor.isDragging(),                    
            }
        },

        end: _ => {
            setOperatingState(prev => ({...prev, beingDragged: null }));
        }

    }));

    useEffect(() => {
        if(isDragging) {
            setOperatingState(prev => ({ ...prev, beingDragged: dragItem }))
        }
        
    }, [ isDragging ])


    const className = "lc-design-block" +
        (isInside() ? " drop-parent": "")
        // + (operatingState.hovered === id ? " hovered": "")
        // + (operatingState.selected === id ? " selected": "")
        + (isDragging ? " being-dragged": "")
        + (operatingState.hovered == id ? " hovered" : "")
    ;

    function setRef(ref) {
        elRef.current = ref;
        drag(ref);
    }

    function renderChildren() {
        if (!isContainer) {
            return null;
        }

        const children = childIds.map(cid => contentDict[cid]);

        if(children.length === 0 && isContainer) {
            return (
                <>
                   <PlaceholderView className={isInside() ? "drop-highlight" : "" } />
                </>
            )
        }
        return (
            <>
                {/* droppable zone */}
                {children.map((c, index) => {
                    return (
                        <React.Fragment key={c.id}>
                            <BlockView block={c} contentDict={contentDict} 
                                operatingState={operatingState}
                                setOperatingState={setOperatingState}
                                executeCommand={executeCommand}
                            />
                        </React.Fragment>
                    )
                })}
            </>
        )
    }

    function renderPlaceholder(slotIndex) {
        return (<PlaceholderView className={isInside(slotIndex) ? "drop-highlight" : "" } />)
    }

    function renderChild(c) {
        return (
            <BlockView block={c} contentDict={contentDict} key={c.id}
                operatingState={operatingState} 
                setOperatingState={setOperatingState}
                executeCommand={executeCommand}
            />
        )
    }

    const updateBlockAttributes = attributes => {
        executeCommand(
            "updateBlockAttributes",
            { blockId: id, attributes }
        )
    }

    const props = { 
        id, className, ref: setRef, "data-type": blockType.name, position: "relative"
    }

    function renderBlock () {
        const View = blockType.DesignView || blockType.View;

        if(View) {            
            if(blockType.slots){
                // multiple slots
                // childIds's length must be the same as slots' length
                const childrenLists = childIds.map(idList => idList.map(cid => contentDict[cid]))

                // slot values
                const slots = childrenLists.map((childrenList, slotIndex) => {
                    if(childrenList.length > 0) {
                        return childrenList.map(renderChild)
                    } else {
                        return renderPlaceholder(slotIndex)
                    }
                })

                return (
                    <View {...{...props, attributes, slots, updateBlockAttributes }}/>    
                )
            }

            return (
                <View {...{...props, attributes, updateBlockAttributes  }}>{ renderChildren() }</View>
            )
        } else {
            return <div {...props}>{renderChildren()} </div>
        }
    }
    return renderBlock()

}


function PlaceholderView ({ className}) {

    const ref = useRef();

    // function getStyle() {
    //     if(ref.parentNode && type.wid)
    // };

    return (
        <div className={"placeholder-view" + (className ? " " + className : "")} ref={ref} style={{
            
        }}>
            SLOT
        </div>
    )
}