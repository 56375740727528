import React from 'react'

const HR = (props) => <hr />

const createPlugin = (config = {}) => {
  const component = config.decorator ? config.decorator(HR) : HR;
  return {
    blockRendererFn: (block, { getEditorState, setEditorState }) => {
      if (block.getType() === 'hr' ){

        return {
          component,
          editable: false,
          props: {}
        }
      }
    },

    buttonsForBlock: (block, { getEditorState, setEditorState }) => {
      if(block.getType() === 'hr' ) {
        return [
          createRemoveBlockButton(block)
        ]
      }
    }
  };
};


function createRemoveBlockButton (block) {
  return (props) => {
    const { setEditorState, getEditorState } = props
    return (
      <button className="editor-button" onClick={() => {
        setEditorState(
          removeBlock(getEditorState(), block.getKey())
        )
      }}>
        <i className={'fa fa-trash'} aria-hidden="true"></i>
      </button>
    )
  }
}


export default createPlugin
