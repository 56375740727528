
import React from "react";

import ModalLink from 'Client/js/components/ModalLink'

import "./EntityCard.less"

import { Card as AntCard } from 'antd'

import Tooltip from 'bwax-ui/components/Tooltip';

import { InfoCircleOutlined } from "@ant-design/icons"

export default function EntityCard(props) {

    const { params } = props;

    const {
        desc,
        name,
        title,
        link,
        size,

        // 如下用于 extension 里面的 entity 显示：
        fields: passedInFields,
        virtualFields: passedInVirtualFields,
        interfaces: passedInInterfaces,

        allSandboxEntities = [],
        allSandboxDataTypes = [],

        margin = 0,

    } = params

    const excludedFields = ["创建者", "创建时间", "修改者", "修改时间"];
    function renderBody() {

        const targetEntity = allSandboxEntities.find(e => e.name == name);

        function getFields() {
            if (passedInFields) {
                return passedInFields
            }
            // find fields from sandbox entity:
            return targetEntity && targetEntity.fields || [];
        };
        function getVirtualFields() {
            if (passedInVirtualFields) {
                return passedInVirtualFields
            }
            // find virtualFields from sandbox entity:
            return targetEntity && targetEntity.virtualFields || [];
        }
        function getInterfaces() {
            if(passedInInterfaces) {
                return passedInInterfaces;
            }
            // find interfaces from sandbox entity:
            return targetEntity && targetEntity.interfaces || []
        }

    

        function renderField({name, type, options = {}, required, multivalued}) {

            const dataType = allSandboxDataTypes.find(dt => dt.name == type || dt.key == type);
            const typeName = dataType ? dataType.name : type;

            function renderSingleType() {
                if (typeName == "关联数据" || typeName == "关联实体") {
                    return "#" + options.entity

                } else if (typeName == "选择") {

                    const opts = (options.options || []);
                    if (opts.length == 0) {
                        return "<无选择项>"
                    }

                    return opts.map(o => {
                        const { name, value } = o;

                        const prefix = multivalued ? "|| " : "| "
                        if (name == value) {
                            return <div key={name}>{prefix + name}</div>
                        } else {
                            return <div key={name}>{prefix + name} ({value})</div>
                        }
                    })

                }
                return typeName;
            }

            function renderType() {
                const t = renderSingleType();
                if (multivalued && typeName != "选择") {
                    return "[ " + t + " ]"
                } else {
                    return t
                }
            }
            return (
                <div className="info-line" key={name}>
                    <div className="info-name">{name + (required ? " *" : "")}</div>
                    <div className="info-type">{renderType()}</div>
                </div>
            )
        };

        function renderFieldList(fields) {
            return fields.filter(({name}) => excludedFields.indexOf(name) == -1).map(
                renderField
            )
        }

        function renderInterfaces(interfaces) {
            return interfaces.map(({name, interfaceType}) => {
                return (
                    <div className="info-line" key={name}>
                        <div className="info-name long">{name}</div>
                        <div className="info-type">{interfaceType}</div>
                    </div>
                )
            })
        }


        return (
            <div className="info-list">
                { getFields().length > 0 ? <div className="caption">字段</div> : null }
                { renderFieldList(getFields())}
                { getVirtualFields().length > 0 ? <div className="caption">虚拟字段</div> : null }
                { renderFieldList(getVirtualFields())} 
                { getInterfaces().length > 0 ? <div className="caption">自定义接口</div> : null}
                { renderInterfaces(getInterfaces())}
            </div>
        )

    }

    const defaultSize = {
        width: 180,
        // height: 180 
    }
    const sizeStyle = {
        'wide': { width: 280, height: 120 },
    }[size] || defaultSize

    const cardStyle = {
        ...sizeStyle,
        margin
    }


    function renderTitle() {
        return (
            <div className="title-line">
                {title }
                <span>
                    {desc ?
                        <Tooltip text={desc}>
                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                        </Tooltip> : null
                    }
                </span>
            </div>
        )
    }

    return (
        <ModalLink to={link}>
            <AntCard title={renderTitle()} hoverable={true} style={cardStyle} className="admin--entity-card"
            >
                <div className="card-body">{renderBody()}</div>
            </AntCard>
        </ModalLink>
    )
}