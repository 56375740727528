// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Dict$BwaxAdmin = require("../dict.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Query_env$BwaxAdmin = require("./query_env.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Defs_remat$BwaxAdmin = require("./defs_remat.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("../lang/lang_typing_base.bs.js");

function insert_to_tenv(with_module_name, base, al) {
  var empty = Dict$BwaxAdmin.$$String.empty(/* () */0);
  return Plate$BwaxAdmin.List.foldl((function (acc, param) {
                return Dict$BwaxAdmin.$$String.insert(Curry._1(with_module_name, param[0]), /* tuple */[
                            Lang_typing_base$BwaxAdmin.generalize(empty, param[1]),
                            true,
                            undefined
                          ], acc);
              }), base, al);
}

function dtype_to_term(handle_entity_link, handle_entity_link_opaque, handle_custom_type, handle_criteria, dtype) {
  if (typeof dtype === "number") {
    switch (dtype) {
      case /* Atom_int */0 :
          return Lang_typing_base$BwaxAdmin.name_term("Int");
      case /* Atom_float */1 :
          return Lang_typing_base$BwaxAdmin.name_term("Float");
      case /* Atom_bool */2 :
          return Lang_typing_base$BwaxAdmin.name_term("Bool");
      case /* Atom_string */3 :
          return Lang_typing_base$BwaxAdmin.name_term("String");
      case /* Atom_date */4 :
          return Lang_typing_base$BwaxAdmin.name_term("Date");
      case /* Atom_json */5 :
          return Lang_typing_base$BwaxAdmin.name_term("JSON");
      case /* Atom_richtext */6 :
          return Lang_typing_base$BwaxAdmin.name_term("RichText");
      case /* Atom_image */7 :
          return Lang_typing_base$BwaxAdmin.name_term("Image");
      case /* Atom_file */8 :
          return Lang_typing_base$BwaxAdmin.name_term("File");
      case /* Atom_color */9 :
          return Lang_typing_base$BwaxAdmin.name_term("Color");
      
    }
  } else {
    switch (dtype.tag | 0) {
      case /* Atom_select */0 :
          return Lang_typing_base$BwaxAdmin.name_term("String");
      case /* Entity_link */1 :
          return Curry._1(handle_entity_link, dtype[0]);
      case /* Entity_link_opaque */2 :
          return Curry._2(handle_entity_link_opaque, dtype[0], dtype[1]);
      case /* Custom_type */3 :
          return Curry._1(handle_custom_type, dtype[0]);
      case /* Criteria */4 :
          return Curry._1(handle_criteria, dtype[0]);
      
    }
  }
}

function dtype_to_term_common(next_int, entity_dict, data_type_dict, dtype) {
  return dtype_to_term((function (name) {
                return Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, Base_query_types$BwaxAdmin.tname_of(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, name)));
              }), (function (name, app_name) {
                return Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.opaque_entity_type_name(name, app_name));
              }), (function (name) {
                return Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, Base_query_types$BwaxAdmin.dtname_of(Base_query_types$BwaxAdmin.get_data_type(data_type_dict, name)));
              }), (function (name) {
                var ctname = Base_query_types$BwaxAdmin.criteria_tname(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, name));
                var $$var = Lang_typing_base$BwaxAdmin.record_writeonly_term((function (param) {
                        return 1;
                      }), ctname);
                return Lang_typing_base$BwaxAdmin.wrap_term("Criteria", $$var);
              }), dtype);
}

function dtype_to_term_input(entity_dict, data_type_dict, dtype) {
  return dtype_to_term((function (param) {
                return Lang_typing_base$BwaxAdmin.name_term("String");
              }), (function (param, param$1) {
                return Lang_typing_base$BwaxAdmin.name_term("String");
              }), (function (name) {
                return Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.input_data_type_name(Base_query_types$BwaxAdmin.get_data_type_name(data_type_dict, name)));
              }), (function (name) {
                var ctname = Base_query_types$BwaxAdmin.criteria_tname(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, name));
                var $$var = Lang_typing_base$BwaxAdmin.record_writeonly_term((function (param) {
                        return 1;
                      }), ctname);
                return Lang_typing_base$BwaxAdmin.wrap_term("Criteria", $$var);
              }), dtype);
}

function dtype_to_term_plain(entity_dict, data_type_dict, dtype) {
  return dtype_to_term((function (param) {
                return Lang_typing_base$BwaxAdmin.name_term("String");
              }), (function (param, param$1) {
                return Lang_typing_base$BwaxAdmin.name_term("String");
              }), (function (name) {
                return Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.plain_data_type_tname_of(Base_query_types$BwaxAdmin.get_data_type_name(data_type_dict, name)));
              }), (function (name) {
                var ctname = Base_query_types$BwaxAdmin.criteria_tname(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, name));
                var $$var = Lang_typing_base$BwaxAdmin.record_writeonly_term((function (param) {
                        return 1;
                      }), ctname);
                return Lang_typing_base$BwaxAdmin.wrap_term("Criteria", $$var);
              }), dtype);
}

function bind_field(next_int, entity_dict, data_type_dict, f) {
  var name = Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]);
  var base_term = dtype_to_term_common(next_int, entity_dict, data_type_dict, f[/* dtype */2]);
  var match = f[/* multivalued */4];
  var plural_term = match ? Lang_typing_base$BwaxAdmin.wrap_term("List", base_term) : base_term;
  var match$1 = f[/* required */3];
  var term = match$1 ? plural_term : Lang_typing_base$BwaxAdmin.wrap_term("Maybe", plural_term);
  return /* tuple */[
          name,
          term,
          false
        ];
}

function bind_plain_field(entity_dict, data_type_dict, f) {
  var name = Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]);
  var base_term = dtype_to_term_plain(entity_dict, data_type_dict, f[/* dtype */2]);
  var match = f[/* multivalued */4];
  var plural_term = match ? Lang_typing_base$BwaxAdmin.wrap_term("List", base_term) : base_term;
  var match$1 = f[/* required */3];
  var term = match$1 ? plural_term : Lang_typing_base$BwaxAdmin.wrap_term("Maybe", plural_term);
  return /* tuple */[
          name,
          term,
          false
        ];
}

function bind_input_field(entity_dict, data_type_dict, use_required_flag, f) {
  var name = Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]);
  var base_term = dtype_to_term_input(entity_dict, data_type_dict, f[/* dtype */2]);
  var match = f[/* multivalued */4];
  var plural_term = match ? Lang_typing_base$BwaxAdmin.wrap_term("List", base_term) : base_term;
  var match$1 = f[/* required */3];
  var term = match$1 ? plural_term : Lang_typing_base$BwaxAdmin.wrap_term("Maybe", plural_term);
  var required;
  if (use_required_flag) {
    var match$2 = f[/* required */3];
    var match$3 = f[/* default_value */11];
    required = match$2 ? match$3 === undefined : false;
  } else {
    required = false;
  }
  return /* tuple */[
          name,
          term,
          required
        ];
}

function pack_dt(term) {
  var scheme = /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
      /* [] */0,
      term
    ]);
  return /* Alias */Caml_chrome_debugger.variant("Alias", 1, [scheme]);
}

var id_field = /* tuple */[
  "id",
  /* Term */Caml_chrome_debugger.variant("Term", 0, [
      "String",
      /* [] */0
    ]),
  false
];

function dt_of_entity(entity_dict, data_type_dict, entity) {
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var binds = Plate$BwaxAdmin.List.map((function (param) {
          return bind_field(next_int, entity_dict, data_type_dict, param);
        }), all_fields);
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          id_field,
          binds
        ])]);
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_update_of_entity(entity_dict, data_type_dict, entity) {
  var all_fields = Base_query_types$BwaxAdmin.materialized_fields(entity);
  var bind_updated_field = function (field) {
    var match = bind_plain_field(entity_dict, data_type_dict, field);
    return /* tuple */[
            match[0],
            Lang_typing_base$BwaxAdmin.wrap_term("UpdateStatus", match[1]),
            false
          ];
  };
  var binds = Plate$BwaxAdmin.List.map(bind_updated_field, all_fields);
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          id_field,
          binds
        ])]);
  var tname = Base_query_types$BwaxAdmin.updates_of_entity_tname_of(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_raw_entity(entity_dict, data_type_dict, entity) {
  var all_fields = Base_query_types$BwaxAdmin.materialized_fields(entity);
  var binds = Plate$BwaxAdmin.List.map((function (param) {
          return bind_plain_field(entity_dict, data_type_dict, param);
        }), all_fields);
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          id_field,
          binds
        ])]);
  var tname = Base_query_types$BwaxAdmin.raw_entity_tname_of(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_data_type(field_to_bind, make_tname, data_type) {
  var match = data_type[/* trait */2];
  if (typeof match === "number" || match.tag !== /* C_record */1) {
    return ;
  } else {
    var tname = Curry._1(make_tname, data_type);
    var all_fields = Pervasives.$at(match[0], match[1]);
    var binds = Plate$BwaxAdmin.List.map(field_to_bind, all_fields);
    var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds]);
    return /* tuple */[
            tname,
            pack_dt(t)
          ];
  }
}

function dt_of_entity_criteria(entity_dict, data_type_dict, entity) {
  var bind_condition_field = function (field) {
    var condition_term = function (tname) {
      if (field[/* multivalued */4]) {
        return Lang_typing_base$BwaxAdmin.name_term(tname + "PluralCondition");
      } else {
        return Lang_typing_base$BwaxAdmin.name_term(tname + "Condition");
      }
    };
    if (field[/* filterable */5]) {
      var fname = Base_query_types$BwaxAdmin.normalize_field_name(field[/* name */0]);
      var match = field[/* dtype */2];
      var term;
      var exit = 0;
      if (typeof match === "number") {
        switch (match) {
          case /* Atom_int */0 :
              term = condition_term("Int");
              break;
          case /* Atom_float */1 :
              term = condition_term("Float");
              break;
          case /* Atom_bool */2 :
              term = condition_term("Bool");
              break;
          case /* Atom_string */3 :
              term = condition_term("String");
              break;
          case /* Atom_date */4 :
              term = condition_term("Date");
              break;
          case /* Atom_json */5 :
              term = condition_term("JSON");
              break;
          default:
            exit = 1;
        }
      } else {
        switch (match.tag | 0) {
          case /* Atom_select */0 :
              term = condition_term("String");
              break;
          case /* Entity_link */1 :
              if (field[/* multivalued */4]) {
                term = Lang_typing_base$BwaxAdmin.name_term("StringPluralCondition");
              } else {
                var ename = Base_query_types$BwaxAdmin.normalize_name(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, match[0]));
                term = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.criteria_tname(ename));
              }
              break;
          case /* Entity_link_opaque */2 :
              exit = 1;
              break;
          default:
            exit = 1;
        }
      }
      if (exit === 1) {
        term = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "exists",
                  Lang_typing_base$BwaxAdmin.name_term("Bool"),
                  false
                ],
                /* [] */0
              ])]);
      }
      return /* tuple */[
              fname,
              term,
              false
            ];
    }
    
  };
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  var binds = Plate$BwaxAdmin.List.keep_map(bind_condition_field, all_fields);
  var id_condition_term = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "eq",
            Lang_typing_base$BwaxAdmin.name_term("String"),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "ne",
                Lang_typing_base$BwaxAdmin.name_term("String"),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "exists",
                    Lang_typing_base$BwaxAdmin.name_term("Bool"),
                    false
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "isIn",
                        Lang_typing_base$BwaxAdmin.wrap_term("List", Lang_typing_base$BwaxAdmin.name_term("String")),
                        false
                      ],
                      /* [] */0
                    ])
                ])
            ])
        ])]);
  var id_field = /* tuple */[
    "id",
    id_condition_term,
    false
  ];
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          id_field,
          binds
        ])]);
  var tname = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_entity_search(entity_dict, _data_type_dict, entity) {
  var bind_search_field = function (field) {
    if (field[/* filterable */5]) {
      var fname = Base_query_types$BwaxAdmin.normalize_field_name(field[/* name */0]);
      var match = field[/* dtype */2];
      var maybe_term;
      if (typeof match === "number") {
        maybe_term = match === /* Atom_string */3 ? Lang_typing_base$BwaxAdmin.name_term("Float") : undefined;
      } else {
        switch (match.tag | 0) {
          case /* Atom_select */0 :
              maybe_term = Lang_typing_base$BwaxAdmin.name_term("Float");
              break;
          case /* Entity_link */1 :
              if (field[/* multivalued */4]) {
                maybe_term = undefined;
              } else {
                var ename = Base_query_types$BwaxAdmin.normalize_name(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, match[0]));
                maybe_term = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.search_fields_tname(ename));
              }
              break;
          case /* Entity_link_opaque */2 :
              maybe_term = undefined;
              break;
          default:
            maybe_term = undefined;
        }
      }
      return Plate$BwaxAdmin.$$Option.map((function (term) {
                    return /* tuple */[
                            fname,
                            term,
                            false
                          ];
                  }), maybe_term);
    }
    
  };
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  var binds = Plate$BwaxAdmin.List.keep_map(bind_search_field, all_fields);
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds]);
  var tname = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_sorting(entity_dict, data_type_dict, entity) {
  var bind_sorting_field = function (field) {
    if (field[/* sortable */6]) {
      var fname = Base_query_types$BwaxAdmin.normalize_field_name(field[/* name */0]);
      var match = field[/* dtype */2];
      var maybe_term;
      if (typeof match === "number") {
        switch (match) {
          case /* Atom_int */0 :
          case /* Atom_float */1 :
          case /* Atom_bool */2 :
          case /* Atom_string */3 :
          case /* Atom_date */4 :
              maybe_term = Lang_typing_base$BwaxAdmin.name_term("SortingDirection");
              break;
          default:
            maybe_term = undefined;
        }
      } else {
        switch (match.tag | 0) {
          case /* Atom_select */0 :
              maybe_term = Lang_typing_base$BwaxAdmin.name_term("SortingDirection");
              break;
          case /* Entity_link */1 :
              if (field[/* multivalued */4]) {
                maybe_term = undefined;
              } else {
                var ename = Base_query_types$BwaxAdmin.normalize_name(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, match[0]));
                maybe_term = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.sorting_tname(ename));
              }
              break;
          case /* Entity_link_opaque */2 :
              maybe_term = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "id",
                        Lang_typing_base$BwaxAdmin.name_term("SortingDirection"),
                        false
                      ],
                      /* [] */0
                    ])]);
              break;
          default:
            maybe_term = undefined;
        }
      }
      return Plate$BwaxAdmin.$$Option.map((function (term) {
                    return /* tuple */[
                            fname,
                            term,
                            false
                          ];
                  }), maybe_term);
    }
    
  };
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  var binds = Plate$BwaxAdmin.List.keep_map(bind_sorting_field, all_fields);
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds]);
  var tname = Base_query_types$BwaxAdmin.sorting_tname(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_adding(entity_dict, data_type_dict, entity) {
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  var binds = Plate$BwaxAdmin.List.map((function (param) {
          return bind_input_field(entity_dict, data_type_dict, true, param);
        }), Plate$BwaxAdmin.List.filter((function (f) {
                return f[/* initializable */7];
              }))(all_fields));
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds]);
  var tname = Base_query_types$BwaxAdmin.add_param_type(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_updating(entity_dict, data_type_dict, entity) {
  var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
  var binds = Plate$BwaxAdmin.List.map((function (param) {
          return bind_input_field(entity_dict, data_type_dict, false, param);
        }), Plate$BwaxAdmin.List.filter((function (f) {
                return f[/* updatable */8];
              }))(all_fields));
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds]);
  var tname = Base_query_types$BwaxAdmin.update_param_type(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_entity_id(_entity_dict, _data_type_dict, entity) {
  var tname = Base_query_types$BwaxAdmin.id_type(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(Lang_typing_base$BwaxAdmin.name_term("String"))
        ];
}

function dt_of_group_type(entity_dict, data_type_dict, entity) {
  var bind_group_field = function (field) {
    var term;
    if (field[/* multivalued */4]) {
      term = Lang_typing_base$BwaxAdmin.name_term("AnyGrouping");
    } else {
      var match = field[/* dtype */2];
      if (typeof match === "number") {
        switch (match) {
          case /* Atom_int */0 :
          case /* Atom_float */1 :
              term = Lang_typing_base$BwaxAdmin.name_term("NumberGrouping");
              break;
          case /* Atom_bool */2 :
              term = Lang_typing_base$BwaxAdmin.name_term("BooleanGrouping");
              break;
          case /* Atom_string */3 :
              term = Lang_typing_base$BwaxAdmin.name_term("StringGrouping");
              break;
          case /* Atom_date */4 :
              term = Lang_typing_base$BwaxAdmin.name_term("DateGrouping");
              break;
          default:
            term = undefined;
        }
      } else {
        switch (match.tag | 0) {
          case /* Atom_select */0 :
              term = Lang_typing_base$BwaxAdmin.name_term("StringGrouping");
              break;
          case /* Entity_link */1 :
              var ename = Base_query_types$BwaxAdmin.normalize_name(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, match[0]));
              term = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.group_tname(ename));
              break;
          case /* Entity_link_opaque */2 :
              term = Lang_typing_base$BwaxAdmin.name_term("IDGrouping");
              break;
          default:
            term = undefined;
        }
      }
    }
    var fname = Base_query_types$BwaxAdmin.normalize_field_name(field[/* name */0]);
    return Plate$BwaxAdmin.$$Option.map((function (t) {
                  return /* tuple */[
                          fname,
                          t,
                          false
                        ];
                }), term);
  };
  var binds = Plate$BwaxAdmin.List.keep_map(bind_group_field, Base_query_types$BwaxAdmin.materialized_fields(entity));
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "id",
            Lang_typing_base$BwaxAdmin.name_term("IDGrouping"),
            false
          ],
          binds
        ])]);
  var tname = Base_query_types$BwaxAdmin.group_tname(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function dt_of_aggregate_type(entity_dict, data_type_dict, entity) {
  var bind_aggregate_field = function (field) {
    var term;
    if (field[/* multivalued */4]) {
      term = Lang_typing_base$BwaxAdmin.name_term("AnyAggregation");
    } else {
      var match = field[/* dtype */2];
      if (typeof match === "number") {
        switch (match) {
          case /* Atom_int */0 :
          case /* Atom_float */1 :
              term = Lang_typing_base$BwaxAdmin.name_term("NumberAggregation");
              break;
          case /* Atom_bool */2 :
              term = Lang_typing_base$BwaxAdmin.name_term("BooleanAggregation");
              break;
          case /* Atom_string */3 :
              term = Lang_typing_base$BwaxAdmin.name_term("StringAggregation");
              break;
          case /* Atom_date */4 :
              term = Lang_typing_base$BwaxAdmin.name_term("DateAggregation");
              break;
          default:
            term = undefined;
        }
      } else {
        switch (match.tag | 0) {
          case /* Atom_select */0 :
              term = Lang_typing_base$BwaxAdmin.name_term("StringAggregation");
              break;
          case /* Entity_link */1 :
              var ename = Base_query_types$BwaxAdmin.normalize_name(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, match[0]));
              term = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.aggregate_tname(ename));
              break;
          case /* Entity_link_opaque */2 :
              term = Lang_typing_base$BwaxAdmin.name_term("IDAggregation");
              break;
          default:
            term = undefined;
        }
      }
    }
    var fname = Base_query_types$BwaxAdmin.normalize_field_name(field[/* name */0]);
    return Plate$BwaxAdmin.$$Option.map((function (t) {
                  return /* tuple */[
                          fname,
                          t,
                          false
                        ];
                }), term);
  };
  var binds = Plate$BwaxAdmin.List.keep_map(bind_aggregate_field, Base_query_types$BwaxAdmin.materialized_fields(entity));
  var t = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "id",
            Lang_typing_base$BwaxAdmin.name_term("IDAggregation"),
            false
          ],
          binds
        ])]);
  var tname = Base_query_types$BwaxAdmin.aggregate_tname(entity[/* name */0]);
  return /* tuple */[
          tname,
          pack_dt(t)
        ];
}

function get_entity_query_dts(entity_dict, data_type_dict, entity) {
  return Plate$BwaxAdmin.List.map((function (f) {
                return Curry._3(f, entity_dict, data_type_dict, entity);
              }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                dt_of_entity_criteria,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    dt_of_entity_search,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        dt_of_sorting,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            dt_of_adding,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                dt_of_updating,
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    dt_of_entity_id,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        dt_of_group_type,
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            dt_of_aggregate_type,
                                            /* [] */0
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ]));
}

function get_entity_dts(entity_dict, data_type_dict) {
  var entities = Plate$BwaxAdmin.List.assoc_vals(entity_dict);
  var data_types = Plate$BwaxAdmin.List.assoc_vals(data_type_dict);
  var dts_entities = Plate$BwaxAdmin.List.map((function (param) {
          return dt_of_entity(entity_dict, data_type_dict, param);
        }), entities);
  var dts_data_types = Plate$BwaxAdmin.List.keep_map((function (data_type) {
          var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
          return dt_of_data_type((function (param) {
                        return bind_field(next_int, entity_dict, data_type_dict, param);
                      }), Base_query_types$BwaxAdmin.dtname_of, data_type);
        }), data_types);
  var dts_input_data_types = Plate$BwaxAdmin.List.keep_map((function (param) {
          return dt_of_data_type((function (param) {
                        return bind_plain_field(entity_dict, data_type_dict, param);
                      }), (function (dt) {
                        return Base_query_types$BwaxAdmin.plain_data_type_tname_of(dt[/* name */0]);
                      }), param);
        }), data_types);
  var dts_plain_data_types = Plate$BwaxAdmin.List.keep_map((function (param) {
          return dt_of_data_type((function (param) {
                        return bind_input_field(entity_dict, data_type_dict, true, param);
                      }), (function (dt) {
                        return Base_query_types$BwaxAdmin.input_data_type_name(dt[/* name */0]);
                      }), param);
        }), data_types);
  var dts_udpates_of_entity = Plate$BwaxAdmin.List.map((function (param) {
          return dt_of_update_of_entity(entity_dict, data_type_dict, param);
        }), entities);
  var dts_of_raw_entity = Plate$BwaxAdmin.List.map((function (param) {
          return dt_of_raw_entity(entity_dict, data_type_dict, param);
        }), entities);
  var dts_entity_query_types = Plate$BwaxAdmin.List.flat_map((function (param) {
          return get_entity_query_dts(entity_dict, data_type_dict, param);
        }), entities);
  var linked_opaque_entity_names = Base_query_types$BwaxAdmin.get_linked_opaque_entity_names(entity_dict, data_type_dict);
  var dts_linked_opaque_entities = Plate$BwaxAdmin.List.map((function (tname) {
          return /* tuple */[
                  tname,
                  /* Opaque */Caml_chrome_debugger.variant("Opaque", 2, [/* [] */0])
                ];
        }), Plate$BwaxAdmin.List.map((function (param) {
              return Base_query_types$BwaxAdmin.opaque_entity_type_name(param[0], param[1]);
            }), linked_opaque_entity_names));
  return Pervasives.$at(dts_linked_opaque_entities, Pervasives.$at(dts_entities, Pervasives.$at(dts_data_types, Pervasives.$at(dts_input_data_types, Pervasives.$at(dts_plain_data_types, Pervasives.$at(dts_udpates_of_entity, Pervasives.$at(dts_of_raw_entity, dts_entity_query_types)))))));
}

function find_one_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var sorting = Base_query_types$BwaxAdmin.sorting_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "sorting",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, sorting),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "search",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                    false
                  ],
                  /* [] */0
                ])
            ])
        ]));
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname)), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  return /* tuple */[
          "findOne",
          Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)))
        ];
}

function find_by_id_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname)), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(id_t, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)));
  return /* tuple */[
          "findByID",
          term
        ];
}

function list_term(fname, container_tname, entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var sorting = Base_query_types$BwaxAdmin.sorting_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "sorting",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, sorting),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "search",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                    false
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "pageSize",
                        Lang_typing_base$BwaxAdmin.name_term("Int"),
                        false
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "offset",
                            Lang_typing_base$BwaxAdmin.name_term("Int"),
                            false
                          ],
                          /* [] */0
                        ])
                    ])
                ])
            ])
        ]));
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term(container_tname, Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname)), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)));
  return /* tuple */[
          fname,
          term
        ];
}

function count_term(entity) {
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "search",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                false
              ],
              /* [] */0
            ])
        ]));
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.$$int, msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)));
  return /* tuple */[
          "count",
          term
        ];
}

function aggregate_term(entity) {
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var group = Base_query_types$BwaxAdmin.group_tname(entity[/* name */0]);
  var aggregate = Base_query_types$BwaxAdmin.aggregate_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "group",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, group),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "aggregate",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, aggregate),
                    false
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "limit",
                        Lang_typing_base$BwaxAdmin.name_term("Int"),
                        false
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "offset",
                            Lang_typing_base$BwaxAdmin.name_term("Int"),
                            false
                          ],
                          /* [] */0
                        ])
                    ])
                ])
            ])
        ]));
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("List", Lang_typing_base$BwaxAdmin.name_term("JSON")), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var return_term = Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd));
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, return_term);
  return /* tuple */[
          "aggregate",
          term
        ];
}

function add_term(entity) {
  var add_param_tname = Base_query_types$BwaxAdmin.add_param_type(entity[/* name */0]);
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, add_param_tname);
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname)), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)));
  return /* tuple */[
          "add",
          term
        ];
}

function update_term(entity) {
  var update_param_tname = Base_query_types$BwaxAdmin.update_param_type(entity[/* name */0]);
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, update_param_tname);
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname)), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(id_t, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd))));
  return /* tuple */[
          "update",
          term
        ];
}

function delete_term(entity) {
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.bool), msg_t);
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(id_t, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)));
  return /* tuple */[
          "delete",
          term
        ];
}

function remat_term(entity) {
  if (Defs_remat$BwaxAdmin.no_need_remat(entity)) {
    return ;
  } else {
    var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
    var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
    var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
    var remat_binds = Plate$BwaxAdmin.List.map((function (f) {
            return /* tuple */[
                    Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]),
                    Lang_typing_base$BwaxAdmin.name_term("Bool"),
                    false
                  ];
          }), Plate$BwaxAdmin.List.filter((function (f) {
                  return f[/* materialized */10];
                }))(entity[/* virtual_fields */3]));
    var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "criteria",
              Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
              false
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "remat",
                  /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [remat_binds]),
                  false
                ],
                /* [] */0
              ])
          ]));
    var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.$$int, msg_t);
    var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
    var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
    var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(data_tagger, Lang_typing_base$BwaxAdmin.arrow(error_tagger, cmd)));
    return /* tuple */[
            "remat",
            term
          ];
  }
}

function config_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var sorting = Base_query_types$BwaxAdmin.sorting_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "sorting",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, sorting),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "search",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                    false
                  ],
                  /* [] */0
                ])
            ])
        ]));
  var return_term = Lang_typing_base$BwaxAdmin.wrap_term("QueryConfig", Lang_typing_base$BwaxAdmin.name_term(tname));
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, return_term);
  return /* tuple */[
          "config",
          term
        ];
}

function by_id_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var return_term = Lang_typing_base$BwaxAdmin.wrap_term("QueryConfig", Lang_typing_base$BwaxAdmin.name_term(tname));
  var term = Lang_typing_base$BwaxAdmin.arrow(id_t, return_term);
  return /* tuple */[
          "byID",
          term
        ];
}

function aggregate_config_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var group = Base_query_types$BwaxAdmin.group_tname(entity[/* name */0]);
  var aggregate = Base_query_types$BwaxAdmin.aggregate_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "group",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, group),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "aggregate",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, aggregate),
                    false
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "limit",
                        Lang_typing_base$BwaxAdmin.name_term("Int"),
                        false
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "offset",
                            Lang_typing_base$BwaxAdmin.name_term("Int"),
                            false
                          ],
                          /* [] */0
                        ])
                    ])
                ])
            ])
        ]));
  var return_term = Lang_typing_base$BwaxAdmin.wrap_term("AggregateConfig", Lang_typing_base$BwaxAdmin.name_term(tname));
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, return_term);
  return /* tuple */[
          "aggregateConfig",
          term
        ];
}

function condition_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var arg = Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria));
  var return_term = Lang_typing_base$BwaxAdmin.wrap_term("Condition", Lang_typing_base$BwaxAdmin.name_term(tname));
  var term = Lang_typing_base$BwaxAdmin.arrow(arg, return_term);
  return /* tuple */[
          "condition",
          term
        ];
}

function adding_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var add_param_tname = Base_query_types$BwaxAdmin.add_param_type(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var arg = Lang_typing_base$BwaxAdmin.record_writeonly_optional(Curry._1(next_int, /* () */0), add_param_tname, /* [] */0);
  var return_term = Lang_typing_base$BwaxAdmin.wrap_term("AddingForm", Lang_typing_base$BwaxAdmin.name_term(tname));
  var term = Lang_typing_base$BwaxAdmin.arrow(arg, return_term);
  return /* tuple */[
          "adding",
          term
        ];
}

function updating_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var update_param_tname = Base_query_types$BwaxAdmin.update_param_type(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var arg = Lang_typing_base$BwaxAdmin.record_writeonly_optional(Curry._1(next_int, /* () */0), update_param_tname, /* [] */0);
  var return_term = Lang_typing_base$BwaxAdmin.wrap_term("UpdatingForm", Lang_typing_base$BwaxAdmin.name_term(tname));
  var term = Lang_typing_base$BwaxAdmin.arrow(arg, return_term);
  return /* tuple */[
          "updating",
          term
        ];
}

function value_type_term(next_int, is_input, value_type) {
  var get_term = function (param) {
    return value_type_term(next_int, is_input, param);
  };
  if (typeof value_type === "number") {
    switch (value_type) {
      case /* Intf_bool */0 :
          return Lang_typing_base$BwaxAdmin.bool;
      case /* Intf_int */1 :
          return Lang_typing_base$BwaxAdmin.$$int;
      case /* Intf_float */2 :
          return Lang_typing_base$BwaxAdmin.$$float;
      case /* Intf_string */3 :
          return Lang_typing_base$BwaxAdmin.string;
      case /* Intf_json */4 :
          return Lang_typing_base$BwaxAdmin.name_term("JSON");
      case /* Intf_richtext */5 :
          return Lang_typing_base$BwaxAdmin.name_term("RichText");
      case /* Intf_datetime */6 :
          return Lang_typing_base$BwaxAdmin.name_term("Date");
      case /* Intf_image */7 :
          return Lang_typing_base$BwaxAdmin.name_term("Image");
      case /* Intf_file */8 :
          return Lang_typing_base$BwaxAdmin.name_term("File");
      
    }
  } else {
    switch (value_type.tag | 0) {
      case /* Intf_list */0 :
          return Lang_typing_base$BwaxAdmin.wrap_term("List", value_type_term(next_int, is_input, value_type[0]));
      case /* Intf_maybe */1 :
          return Lang_typing_base$BwaxAdmin.wrap_term("Maybe", value_type_term(next_int, is_input, value_type[0]));
      case /* Intf_record */2 :
          var term_binds = Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          value_type_term(next_int, is_input, param[1]),
                          param[2]
                        ];
                }), value_type[0]);
          if (is_input) {
            return Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), term_binds);
          } else {
            return /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [term_binds]);
          }
      case /* Intf_tuple */3 :
          var ts = Plate$BwaxAdmin.List.map(get_term, value_type[0]);
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Tuple",
                    ts
                  ]);
      case /* Intf_dict */4 :
          var kt = value_type_term(next_int, is_input, value_type[0]);
          var vt = value_type_term(next_int, is_input, value_type[1]);
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Dict",
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        kt,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            vt,
                            /* [] */0
                          ])
                      ])
                  ]);
      case /* Intf_paged */5 :
          return Lang_typing_base$BwaxAdmin.wrap_term("PagedList", value_type_term(next_int, is_input, value_type[0]));
      case /* Intf_entity */6 :
          var name = value_type[0];
          if (is_input) {
            console.log("Warning: " + (name + (" has Entity_" + (name + " input"))));
          }
          return Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, Base_query_types$BwaxAdmin.tname_of(name));
      case /* Intf_criteria */7 :
          var name$1 = value_type[0];
          if (!is_input) {
            console.log("Warning: " + (name$1 + (" has Criteria_" + (name$1 + " output"))));
          }
          return Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, Base_query_types$BwaxAdmin.criteria_tname(name$1)));
      case /* Intf_sorting */8 :
          var name$2 = value_type[0];
          if (!is_input) {
            console.log("Warning: " + (name$2 + (" has Sorting_" + (name$2 + " output"))));
          }
          return Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, Base_query_types$BwaxAdmin.sorting_tname(name$2));
      case /* Intf_adding */9 :
          var name$3 = value_type[0];
          if (!is_input) {
            console.log("Warning: " + (name$3 + (" has Adding_" + (name$3 + " output"))));
          }
          return Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, Base_query_types$BwaxAdmin.add_param_type(name$3));
      case /* Intf_updating */10 :
          var name$4 = value_type[0];
          if (!is_input) {
            console.log("Warning: " + (name$4 + (" has Updating_" + (name$4 + " output"))));
          }
          return Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, Base_query_types$BwaxAdmin.update_param_type(name$4));
      
    }
  }
}

function interface_term($$interface, entity) {
  var input_types = $$interface[/* input_types */3];
  var fname = Plate$BwaxAdmin.Str.decap(Base_query_types$BwaxAdmin.normalize_name($$interface[/* name */0]));
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var msg_t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
  var maybe_params_tuple = input_types ? (
      input_types[1] ? Lang_typing_base$BwaxAdmin.tuple(Plate$BwaxAdmin.List.map((function (param) {
                    return value_type_term(next_int, true, param);
                  }), input_types)) : value_type_term(next_int, true, input_types[0])
    ) : undefined;
  var maybe_id = $$interface[/* is_targeting_record */5] ? Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0])) : undefined;
  var output_terms = Plate$BwaxAdmin.List.map((function (param) {
          return value_type_term(next_int, false, param);
        }), $$interface[/* output_types */4]);
  var data_tagger = output_terms ? (
      output_terms[1] ? Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.tuple(output_terms), msg_t) : Lang_typing_base$BwaxAdmin.arrow(output_terms[0], msg_t)
    ) : msg_t;
  var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.string, msg_t);
  var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);
  var args = Pervasives.$at(Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              maybe_params_tuple,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  maybe_id,
                  /* [] */0
                ])
            ])), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          data_tagger,
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              error_tagger,
              /* [] */0
            ])
        ]));
  var term = Lang_typing_base$BwaxAdmin.arrow_chain(args, cmd);
  return /* tuple */[
          fname,
          term
        ];
}

function interface_config_obj_term($$interface, entity) {
  if (Query_env$BwaxAdmin.is_normal_entity_query(entity[/* name */0], $$interface[/* interface_type */2], $$interface[/* output_types */4])) {
    var non_pagination_inputs = Query_env$BwaxAdmin.get_non_pagination_inputs($$interface[/* input_types */3]);
    var fname = "to_" + Plate$BwaxAdmin.Str.decap(Base_query_types$BwaxAdmin.normalize_name($$interface[/* name */0]));
    var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
    var maybe_params_tuple = non_pagination_inputs ? (
        non_pagination_inputs[1] ? Lang_typing_base$BwaxAdmin.tuple(Plate$BwaxAdmin.List.map((function (param) {
                      return value_type_term(next_int, true, param);
                    }), non_pagination_inputs)) : value_type_term(next_int, true, non_pagination_inputs[0])
      ) : undefined;
    var maybe_id = $$interface[/* is_targeting_record */5] ? Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0])) : undefined;
    var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
    var return_term = Lang_typing_base$BwaxAdmin.wrap_term("QueryConfig", Lang_typing_base$BwaxAdmin.name_term(tname));
    var args = Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            maybe_params_tuple,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                maybe_id,
                /* [] */0
              ])
          ]));
    var term = Lang_typing_base$BwaxAdmin.arrow_chain(args, return_term);
    return /* tuple */[
            fname,
            term
          ];
  }
  
}

function record_operation_term($$interface, entity) {
  var input_types = $$interface[/* input_types */3];
  var is_operation = $$interface[/* interface_type */2] ? true : false;
  if (!$$interface[/* is_targeting_record */5] || !is_operation) {
    return ;
  } else {
    var fname = "to_" + Plate$BwaxAdmin.Str.decap(Base_query_types$BwaxAdmin.normalize_name($$interface[/* name */0]));
    var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
    var maybe_params_tuple = input_types ? (
        input_types[1] ? Lang_typing_base$BwaxAdmin.tuple(Plate$BwaxAdmin.List.map((function (param) {
                      return value_type_term(next_int, true, param);
                    }), input_types)) : value_type_term(next_int, true, input_types[0])
      ) : undefined;
    var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
    var return_term = Lang_typing_base$BwaxAdmin.wrap_term("RecordOperation", Lang_typing_base$BwaxAdmin.name_term(tname));
    var args = Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            maybe_params_tuple,
            /* [] */0
          ]));
    var term = Lang_typing_base$BwaxAdmin.arrow_chain(args, return_term);
    return /* tuple */[
            fname,
            term
          ];
  }
}

function get_entity_query_tenv_list(entity) {
  return Plate$BwaxAdmin.List.keep_map((function (f) {
                return Curry._1(f, entity);
              }), Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    find_one_term,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        find_by_id_term,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            (function (param) {
                                return list_term("list", "List", param);
                              }),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                (function (param) {
                                    return list_term("listAll", "PagedList", param);
                                  }),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    count_term,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        aggregate_term,
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            add_term,
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                update_term,
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    delete_term,
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        remat_term,
                                                        /* [] */0
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ]), Pervasives.$at(Plate$BwaxAdmin.List.map(interface_term, entity[/* interfaces */4]), Pervasives.$at(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            config_term,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                by_id_term,
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    aggregate_config_term,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        condition_term,
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            adding_term,
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                updating_term,
                                                /* [] */0
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ]), Pervasives.$at(Plate$BwaxAdmin.List.map(interface_config_obj_term, entity[/* interfaces */4]), Plate$BwaxAdmin.List.map(record_operation_term, entity[/* interfaces */4]))))));
}

function get_entity_query_tenv(acc_tenv, entity) {
  var ename = Base_query_types$BwaxAdmin.normalize_name(entity[/* name */0]);
  var qname = "Query_" + ename;
  var with_module_name = function (name) {
    return qname + ("." + name);
  };
  var l = get_entity_query_tenv_list(entity);
  return insert_to_tenv(with_module_name, acc_tenv, l);
}

var error_t = Lang_typing_base$BwaxAdmin.name_term("String");

function cmd_find_one_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var sorting = Base_query_types$BwaxAdmin.sorting_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "sorting",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, sorting),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "search",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                    false
                  ],
                  /* [] */0
                ])
            ])
        ]));
  var data_t = Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  return /* tuple */[
          "findOne",
          Lang_typing_base$BwaxAdmin.arrow(main_arg, ret_t)
        ];
}

function cmd_find_by_id_term(entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var data_t = Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(id_t, ret_t);
  return /* tuple */[
          "findByID",
          term
        ];
}

function cmd_list_term(fname, container_tname, entity) {
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var sorting = Base_query_types$BwaxAdmin.sorting_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "sorting",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, sorting),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "search",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                    false
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "pageSize",
                        Lang_typing_base$BwaxAdmin.name_term("Int"),
                        false
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "offset",
                            Lang_typing_base$BwaxAdmin.name_term("Int"),
                            false
                          ],
                          /* [] */0
                        ])
                    ])
                ])
            ])
        ]));
  var data_t = Lang_typing_base$BwaxAdmin.wrap_term(container_tname, Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, ret_t);
  return /* tuple */[
          fname,
          term
        ];
}

function cmd_count_term(entity) {
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var searchFields = Base_query_types$BwaxAdmin.search_fields_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "search",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, searchFields),
                false
              ],
              /* [] */0
            ])
        ]));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, Lang_typing_base$BwaxAdmin.$$int);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, ret_t);
  return /* tuple */[
          "count",
          term
        ];
}

function cmd_aggregate_term(entity) {
  var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
  var group = Base_query_types$BwaxAdmin.group_tname(entity[/* name */0]);
  var aggregate = Base_query_types$BwaxAdmin.aggregate_tname(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "criteria",
            Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
            false
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "group",
                Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, group),
                false
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "aggregate",
                    Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, aggregate),
                    false
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "limit",
                        Lang_typing_base$BwaxAdmin.name_term("Int"),
                        false
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "offset",
                            Lang_typing_base$BwaxAdmin.name_term("Int"),
                            false
                          ],
                          /* [] */0
                        ])
                    ])
                ])
            ])
        ]));
  var data_t = Lang_typing_base$BwaxAdmin.wrap_term("List", Lang_typing_base$BwaxAdmin.name_term("JSON"));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, ret_t);
  return /* tuple */[
          "aggregate",
          term
        ];
}

function cmd_add_term(entity) {
  var add_param_tname = Base_query_types$BwaxAdmin.add_param_type(entity[/* name */0]);
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, add_param_tname);
  var data_t = Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, ret_t);
  return /* tuple */[
          "add",
          term
        ];
}

function cmd_update_term(entity) {
  var update_param_tname = Base_query_types$BwaxAdmin.update_param_type(entity[/* name */0]);
  var tname = Base_query_types$BwaxAdmin.tname_of(entity[/* name */0]);
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, update_param_tname);
  var data_t = Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.record_readonly_term(next_int, tname));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, Lang_typing_base$BwaxAdmin.arrow(id_t, ret_t));
  return /* tuple */[
          "update",
          term
        ];
}

function cmd_delete_term(entity) {
  var id_t = Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0]));
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, Lang_typing_base$BwaxAdmin.bool);
  var term = Lang_typing_base$BwaxAdmin.arrow(id_t, ret_t);
  return /* tuple */[
          "delete",
          term
        ];
}

function cmd_remat_term(entity) {
  if (Defs_remat$BwaxAdmin.no_need_remat(entity)) {
    return ;
  } else {
    var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
    var criteria = Base_query_types$BwaxAdmin.criteria_tname(entity[/* name */0]);
    var remat_binds = Plate$BwaxAdmin.List.map((function (f) {
            return /* tuple */[
                    Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]),
                    Lang_typing_base$BwaxAdmin.name_term("Bool"),
                    false
                  ];
          }), Plate$BwaxAdmin.List.filter((function (f) {
                  return f[/* materialized */10];
                }))(entity[/* virtual_fields */3]));
    var main_arg = Lang_typing_base$BwaxAdmin.record_writeonly_of(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "criteria",
              Lang_typing_base$BwaxAdmin.wrap_term("Criteria", Lang_typing_base$BwaxAdmin.record_writeonly_term(next_int, criteria)),
              false
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "remat",
                  /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [remat_binds]),
                  false
                ],
                /* [] */0
              ])
          ]));
    var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, Lang_typing_base$BwaxAdmin.$$int);
    var term = Lang_typing_base$BwaxAdmin.arrow(main_arg, ret_t);
    return /* tuple */[
            "remat",
            term
          ];
  }
}

function cmd_interface_term($$interface, entity) {
  var input_types = $$interface[/* input_types */3];
  var fname = Plate$BwaxAdmin.Str.decap(Base_query_types$BwaxAdmin.normalize_name($$interface[/* name */0]));
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var maybe_params_tuple = input_types ? (
      input_types[1] ? Lang_typing_base$BwaxAdmin.tuple(Plate$BwaxAdmin.List.map((function (param) {
                    return value_type_term(next_int, true, param);
                  }), input_types)) : value_type_term(next_int, true, input_types[0])
    ) : undefined;
  var maybe_id = $$interface[/* is_targeting_record */5] ? Lang_typing_base$BwaxAdmin.name_term(Base_query_types$BwaxAdmin.id_type(entity[/* name */0])) : undefined;
  var output_terms = Plate$BwaxAdmin.List.map((function (param) {
          return value_type_term(next_int, false, param);
        }), $$interface[/* output_types */4]);
  var data_t = output_terms ? (
      output_terms[1] ? Lang_typing_base$BwaxAdmin.tuple(output_terms) : output_terms[0]
    ) : Lang_typing_base$BwaxAdmin.unit;
  var ret_t = Lang_typing_base$BwaxAdmin.wrap_term_2("CmdM", error_t, data_t);
  var args = Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          maybe_params_tuple,
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              maybe_id,
              /* [] */0
            ])
        ]));
  var term = Lang_typing_base$BwaxAdmin.arrow_chain(args, ret_t);
  return /* tuple */[
          fname,
          term
        ];
}

function get_entity_cmd_tenv_list(entity) {
  return Plate$BwaxAdmin.List.keep_map((function (f) {
                return Curry._1(f, entity);
              }), Pervasives.$at(Plate$BwaxAdmin.List.map(cmd_interface_term, entity[/* interfaces */4]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    cmd_find_one_term,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        cmd_find_by_id_term,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            (function (param) {
                                return cmd_list_term("list", "List", param);
                              }),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                (function (param) {
                                    return cmd_list_term("listAll", "PagedList", param);
                                  }),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    cmd_count_term,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        cmd_aggregate_term,
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            cmd_add_term,
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                cmd_update_term,
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    cmd_delete_term,
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        cmd_remat_term,
                                                        /* [] */0
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])));
}

function get_entity_cmd_tenv(acc_tenv, entity) {
  var ename = Base_query_types$BwaxAdmin.normalize_name(entity[/* name */0]);
  var qname = "Cmd_" + ename;
  var with_module_name = function (name) {
    return qname + ("." + name);
  };
  var l = get_entity_cmd_tenv_list(entity);
  return insert_to_tenv(with_module_name, acc_tenv, l);
}

var tname_user = "E_用户";

var msg_t = Lang_typing_base$BwaxAdmin.any(0);

var error_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.name_term("String"), msg_t);

var maybe_user_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.name_term(tname_user)), msg_t);

var user_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.name_term(tname_user), msg_t);

var cmd = Lang_typing_base$BwaxAdmin.wrap_term("Cmd", msg_t);

var term = Lang_typing_base$BwaxAdmin.arrow_chain(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        maybe_user_tagger,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            error_tagger,
            /* [] */0
          ])
      ]), cmd);

var get_current_user = /* tuple */[
  "getCurrentUser",
  term
];

var term$1 = Lang_typing_base$BwaxAdmin.arrow_chain(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        user_tagger,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            error_tagger,
            /* [] */0
          ])
      ]), cmd);

var requireCurrentUser = /* tuple */[
  "requireCurrentUser",
  term$1
];

var maybe_string = Lang_typing_base$BwaxAdmin.wrap_term("Maybe", Lang_typing_base$BwaxAdmin.string);

var term$2 = Lang_typing_base$BwaxAdmin.arrow_chain(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        maybe_string,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            user_tagger,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                error_tagger,
                /* [] */0
              ])
          ])
      ]), cmd);

var requireUser = /* tuple */[
  "requireUser",
  term$2
];

var term$3 = Lang_typing_base$BwaxAdmin.arrow_chain(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        maybe_user_tagger,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            error_tagger,
            /* [] */0
          ])
      ]), cmd);

var niceToHaveCurrentUser = /* tuple */[
  "niceToHaveCurrentUser",
  term$3
];

var data_tagger = Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.bool, msg_t);

var term$4 = Lang_typing_base$BwaxAdmin.arrow_chain(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        Lang_typing_base$BwaxAdmin.string,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            data_tagger,
            /* [] */0
          ])
      ]), cmd);

var requestAuthSession = /* tuple */[
  "requestAuthSession",
  term$4
];

var user_related_term_list_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    requireCurrentUser,
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        requireUser,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            niceToHaveCurrentUser,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                requestAuthSession,
                /* [] */0
              ])
          ])
      ])
  ]);

var user_related_term_list = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    get_current_user,
    user_related_term_list_001
  ]);

var empty = Dict$BwaxAdmin.$$String.empty(/* () */0);

var user_related_tenv = insert_to_tenv((function (n) {
        return n;
      }), empty, user_related_term_list);

function get_entity_tenv_and_dts(entity_dict, data_type_dict) {
  var dts = get_entity_dts(entity_dict, data_type_dict);
  var entities = Plate$BwaxAdmin.List.assoc_vals(entity_dict);
  var tenv = Plate$BwaxAdmin.List.foldl((function (acc_tenv, entity) {
          var env = get_entity_query_tenv(acc_tenv, entity);
          return get_entity_cmd_tenv(env, entity);
        }), user_related_tenv, entities);
  return /* tuple */[
          tenv,
          dts
        ];
}

exports.insert_to_tenv = insert_to_tenv;
exports.dtype_to_term = dtype_to_term;
exports.dtype_to_term_common = dtype_to_term_common;
exports.dtype_to_term_input = dtype_to_term_input;
exports.dtype_to_term_plain = dtype_to_term_plain;
exports.bind_field = bind_field;
exports.bind_plain_field = bind_plain_field;
exports.bind_input_field = bind_input_field;
exports.pack_dt = pack_dt;
exports.id_field = id_field;
exports.dt_of_entity = dt_of_entity;
exports.dt_of_update_of_entity = dt_of_update_of_entity;
exports.dt_of_raw_entity = dt_of_raw_entity;
exports.dt_of_data_type = dt_of_data_type;
exports.dt_of_entity_criteria = dt_of_entity_criteria;
exports.dt_of_entity_search = dt_of_entity_search;
exports.dt_of_sorting = dt_of_sorting;
exports.dt_of_adding = dt_of_adding;
exports.dt_of_updating = dt_of_updating;
exports.dt_of_entity_id = dt_of_entity_id;
exports.dt_of_group_type = dt_of_group_type;
exports.dt_of_aggregate_type = dt_of_aggregate_type;
exports.get_entity_query_dts = get_entity_query_dts;
exports.get_entity_dts = get_entity_dts;
exports.find_one_term = find_one_term;
exports.find_by_id_term = find_by_id_term;
exports.list_term = list_term;
exports.count_term = count_term;
exports.aggregate_term = aggregate_term;
exports.add_term = add_term;
exports.update_term = update_term;
exports.delete_term = delete_term;
exports.remat_term = remat_term;
exports.config_term = config_term;
exports.by_id_term = by_id_term;
exports.aggregate_config_term = aggregate_config_term;
exports.condition_term = condition_term;
exports.adding_term = adding_term;
exports.updating_term = updating_term;
exports.value_type_term = value_type_term;
exports.interface_term = interface_term;
exports.interface_config_obj_term = interface_config_obj_term;
exports.record_operation_term = record_operation_term;
exports.get_entity_query_tenv_list = get_entity_query_tenv_list;
exports.get_entity_query_tenv = get_entity_query_tenv;
exports.error_t = error_t;
exports.cmd_find_one_term = cmd_find_one_term;
exports.cmd_find_by_id_term = cmd_find_by_id_term;
exports.cmd_list_term = cmd_list_term;
exports.cmd_count_term = cmd_count_term;
exports.cmd_aggregate_term = cmd_aggregate_term;
exports.cmd_add_term = cmd_add_term;
exports.cmd_update_term = cmd_update_term;
exports.cmd_delete_term = cmd_delete_term;
exports.cmd_remat_term = cmd_remat_term;
exports.cmd_interface_term = cmd_interface_term;
exports.get_entity_cmd_tenv_list = get_entity_cmd_tenv_list;
exports.get_entity_cmd_tenv = get_entity_cmd_tenv;
exports.tname_user = tname_user;
exports.user_related_term_list = user_related_term_list;
exports.user_related_tenv = user_related_tenv;
exports.get_entity_tenv_and_dts = get_entity_tenv_and_dts;
/* error_t Not a pure module */
