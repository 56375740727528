// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var Antd$BwaxAdmin = require("../binding/antd/Antd.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

import './ModalCot.less' 
;

function ModalCot(Props) {
  var visible = Props.visible;
  var onClose = Props.onClose;
  var children = Props.children;
  var goBack = Props.goBack;
  var prevLocationIsBgLocation = Props.prevLocationIsBgLocation;
  var match = Props.sideCollapsed;
  var sideCollapsed = match !== undefined ? match : false;
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowContent = match$1[1];
  var modalIsClosing = React.useRef(false);
  React.useEffect((function () {
          if (visible) {
            Curry._1(setShowContent, (function (param) {
                    return true;
                  }));
          } else {
            setTimeout((function (param) {
                    modalIsClosing.current = false;
                    return Curry._1(setShowContent, (function (param) {
                                  return false;
                                }));
                  }), 150);
          }
          return ;
        }), /* array */[visible]);
  var displayClassName = visible ? "visible" : "hidden";
  var modal = React.createElement("div", {
        className: "modal-cot " + displayClassName
      }, React.createElement("div", {
            className: "modal-mask"
          }), React.createElement("div", {
            className: "modal-wrap"
          }, React.createElement("div", {
                className: "modal-side" + (
                  sideCollapsed ? " side-collapsed" : ""
                )
              }, prevLocationIsBgLocation ? null : React.createElement("div", {
                      className: "modal-back",
                      onClick: (function (e) {
                          if (modalIsClosing.current === false) {
                            return Curry._1(goBack, e);
                          } else {
                            return 0;
                          }
                        })
                    }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                          type_: "arrow-left",
                          className: "modal-back-icon"
                        })), React.createElement("div", {
                    className: "modal-close " + (
                      prevLocationIsBgLocation ? "no-modal-back" : "has-modal-back"
                    ),
                    onClick: (function (e) {
                        if (modalIsClosing.current === false) {
                          Curry._1(onClose, e);
                          modalIsClosing.current = true;
                          return /* () */0;
                        } else {
                          return 0;
                        }
                      })
                  }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                        type_: "close",
                        className: "modal-close-icon"
                      }))), React.createElement("div", {
                className: "modal-body"
              }, match$1[0] ? children : null)));
  var rootEle = document.getElementById("app");
  var tmp;
  if (rootEle == null) {
    throw Caml_builtin_exceptions.not_found;
  } else {
    tmp = rootEle;
  }
  return ReactDom.createPortal(modal, tmp);
}

var make = ModalCot;

exports.make = make;
/*  Not a pure module */
