import React, { useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom';

import BlockView from './BlockView'

import './PageCanvas.less';

import { distanceToArea, isRectInsideArea } from './utils/distance';

import { useDrop } from 'react-dnd';

import ActiveToolBar from './canvas/ActiveToolBar';

import { flattenListOrNestedList } from './listUtils';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import useResizeObserver from '@react-hook/resize-observer'

export default function PageCanvas({ 
    model, editor, doc, blockTypes, 
    bindBlockNodeGetter, 
    outlineShown, 

    operatingState,
    setOperatingState,

    dropBlock,

    saveValue, // handle it in the keyboard event

}) {

    const operatingStateRef = useRef();
    operatingStateRef.current = operatingState;

    const contentDictRef = useRef()
    contentDictRef.current = model.contentDict;

    const modelRef = useRef();
    modelRef.current = model;


    const { executeCommand } = editor;

    const blockNodesRef = useRef({});  // blockId -> node    
    const slotNodesRef = useRef({});   // blockId -> [ node ]

    function getBlockNode(blockId) {
        return blockNodesRef.current[blockId]
    }
    if(bindBlockNodeGetter) {
        bindBlockNodeGetter(getBlockNode)
    }

    useEffect(() => {
        // onChange(editingModel);
        // rebuild the blockNodes
        setTimeout(() => {
            const blockNodeList = Array.from(doc.querySelectorAll(".lc-design-block"));

            let slotNodes = {};
            const blockNodes = blockNodeList.reduce((acc, node) => {
                // 
                const slotList = Array.from(node.querySelectorAll(`[data-slot-for="${node.id}"]`));
                if(slotList && slotList.length > 0) {
                    slotNodes[node.id] = slotList;
                }                
                return {
                    ...acc,
                    [node.id]: node
                }
            }, {});

            blockNodesRef.current = blockNodes;
            slotNodesRef.current = slotNodes;

        }, 16);

    }, [ model ]);

    function updateDropTarget(dropTarget) {
        const operatingState = operatingStateRef.current;
        const existingTarget = operatingState.dropTarget;
        if (existingTarget === dropTarget) {
            return
        } else if (existingTarget && dropTarget
            && existingTarget.parent === dropTarget.parent && existingTarget.index === dropTarget.index
            && existingTarget.slotIndex === dropTarget.slotIndex
        ) {
            return
        }

        setOperatingState(prev => ({ ...prev, dropTarget }))
    }

    const toolsElRef = useRef();

    const saveValueRef = useRef();
    saveValueRef.current = saveValue;
    useEffect(() => {
        /// -- tools ---
        const t = doc.createElement('div');
        t.className = 'lc-design-tools';

        toolsElRef.current = t;
        doc.body.appendChild(t);

        // delete event
        function handleKeyDownEvents (e) {
            const model = modelRef.current;
            if(e.key === "Backspace" || e.key === "Delete") {
                // delete the selected block;
                if(model.selected) {
                    executeCommand("deleteBlock", { blockId: model.selected });
                } 
            }
            if(e.metaKey == true && e.key == "s" && saveValueRef.current) {
                e.preventDefault();
                saveValueRef.current();
            }

        }
        doc.addEventListener("keydown", handleKeyDownEvents)

        return _ => {
            doc.removeEventListener("keydown", handleKeyDownEvents);
        }

    }, [doc]);

    const [{ canDrop, isDragOver }, drop] = useDrop(() => ({
        accept: [
            // "row", "column", "page", "text"
            ...(blockTypes.map(b => b.name))
        ],

        hover(item, monitor) {
            resolveDropTarget(
                item,
                monitor,
                updateDropTarget,
                contentDictRef.current, 
                blockNodesRef.current, 
                slotNodesRef.current,
            );
        },

        collect(monitor, props) {
            return {
                canDrop: monitor.canDrop(),
                isDragOver: monitor.isOver()
            }
        },
        drop(item) {
            const { dropTarget } = operatingStateRef.current;

            dropBlock(item, dropTarget);
            updateDropTarget(null);

        },
    }));

    useEffect(() => {
        if (!isDragOver) {
            updateDropTarget(null)
        }

    }, [isDragOver])


    const canvasRef = useRef();
    const [ reloadTime, setReloadTime ] = useState();
    useResizeObserver(canvasRef, _ => {
        setReloadTime(Date.now());
    });

    function updateHoverStatus(hoverStatus) {

        // check if any block is hovered while no child is also hovered.
        function isHovered(id) {
            const block = model.contentDict[id];
            const { childIds } = block;
            return hoverStatus[id] && flattenListOrNestedList(childIds).every(cid => !hoverStatus[cid]);
        }

        let hovered = null;
        for (const id in hoverStatus) {
            if (isHovered(id)) {
                hovered = id;
                break
            }
        }
        const prev = operatingStateRef.current;
        if(prev.hovered === hovered) {
            return 
        }

        setOperatingState(prev => {
            return { ...prev, hovered }
        })
    }

    function resolveHoverStatus(pointer, blockNodes) {
        // 
        const x = pointer.clientX //  doc.documentElement.scrollLeft;
        const y = pointer.clientY // doc.documentElement.scrollTop;     

        // 如果过 blockNodes 里面有全部 0 ，说明还没有初始化好
        const notReady = Object.values(blockNodes).some(node => {
            const rect = node.getBoundingClientRect();
            return rect.top === 0 && rect.left === 0 && rect.width === 0 && rect.height === 0
        });
        if(notReady) {
            return;
        }

        const newHoverStatus = Object.keys(blockNodes).reduce((acc, id) => {
            const rect = blockNodes[id].getBoundingClientRect();
            function inRect(rect) {
                return rect && (rect.top <= y && y <= rect.bottom) && (rect.left <= x && x <= rect.right)
            }
            return {
                ...acc,
                [id]: inRect(rect)
            }
        }, {});
        updateHoverStatus(newHoverStatus);
    }


    const events = {
        onMouseLeave: e => {
            updateHoverStatus({});
        },
        onMouseMove: e => {
            if (isDragOver) return; // 正在 drop
            // resolve hover status
            // TODO throttle:
            resolveHoverStatus(e, blockNodesRef.current)

        },
        onClick: e => {
            if (operatingState.hovered) {
                if(modelRef.current.selected !== operatingState.hovered) {
                    executeCommand("selectBlock", { blockId: operatingState.hovered })
                } else {
                    executeCommand("selectBlock", { blockId: null })
                }
                
            }
        },
    }

    function renderTools() {
        if (!toolsElRef.current) return null

        const { hovered, dropTarget } = operatingState;

        const blockNodes = blockNodesRef.current;
        function renderHoverTools() {
            if (!hovered) return null;
            const hoveredBlockNode = blockNodes[hovered];
            if (!hoveredBlockNode) return null;

            const rect = hoveredBlockNode.getBoundingClientRect();
            return (
                <div className="hover-tools" style={{
                    top: rect.top + doc.documentElement.scrollTop,
                    left: rect.left + doc.documentElement.scrollLeft,
                    width: rect.width, height: rect.height
                }}>
                </div>
            )
        }
        function renderActiveTools() {
            if (!model.selected || dropTarget) return null;  // 正在 drop 的时候也不显示
            const selectedBlockNode = blockNodes[model.selected];
            if (!selectedBlockNode) return null;

            const rect = selectedBlockNode.getBoundingClientRect();

            const top = rect.top + doc.documentElement.scrollTop;
            const left = rect.left + doc.documentElement.scrollLeft;

            const selectedBlock = model.contentDict[model.selected];           

            const barsAtBottom = rect.top < 24;
            const barsOnLeft = rect.right < 100;

            return (
                <div className="active-tools" style={{
                    top, left, 
                    width: rect.width, height: rect.height
                }}>
                    <ActiveToolBar {...{ 
                        barsAtBottom, 
                        barsOnLeft, model, selectedBlock, executeCommand, blockTypes
                    }} />
                </div>
            )
        }

        function renderDropPlacer() {
            if (!dropTarget) return null;

            const { nearestId, isNearestBefore, parent, slotIndex } = dropTarget
            if (!nearestId) return null;

            const nearestRect = blockNodesRef.current[nearestId].getBoundingClientRect()

            const containerNode = (() => {
                if(slotIndex !== undefined) {
                    return slotNodesRef.current[parent][slotIndex]
                } else {
                    return blockNodesRef.current[parent];
                }
            })();
            if(!containerNode) {
                console.warn("Container is not found", dropTarget);
            }

            const style = window.getComputedStyle(containerNode);
            const isRow = style["display"] === "flex" && (style["flexDirection"] !== "column");

            const position = isRow ?
                (isNearestBefore ? "right" : "left") :
                (isNearestBefore ? "below" : "above")

            return (
                <div className="lc-design-placer" style={markPlacerEl(nearestRect, position, doc)} />
            )
        }

        return ReactDOM.createPortal(
            (
                <>
                    {renderHoverTools()}
                    {renderActiveTools()}
                    {renderDropPlacer()}
                </>
            ),
            toolsElRef.current
        )
    }

    const blocks = Object.values(model.contentDict);

    const rootBlocks = blocks.filter(block => {
        return blocks.every(b => !b.hasChild(block.id))
    });

    const cssCacheRef = useRef(createCache({
        container: doc.head
    }));

    return (
        <CacheProvider value={cssCacheRef.current}>
            <div className={"lc-design-page" + (outlineShown ? " outline-shown" : "")} ref={ref => {
                drop(ref);
                canvasRef.current = ref;
            }} {...events}>
                { rootBlocks.map(rootBlock => {
                    return (
                        <BlockView key={rootBlock.id} 
                            block={rootBlock}
                            contentDict={model.contentDict}
                            operatingState={operatingState}
                            setOperatingState={setOperatingState}
                            executeCommand={executeCommand}
                        />
                    )  
                })}
            </div>
            {renderTools()}
        </CacheProvider>

    )
}


////////
// handle placer position
function dismissPlacer(el) {
    el.style["top"] = "-1000px";
    el.style["left"] = "-1000px";
    el.style["display"] = "none";

}


function markPlacerEl(rect, position, doc) {
    // position is above / left / right / below
    if (!rect) {
        // make it disappear
        return dismissPlacer()
    };

    const baseTop = rect.top + doc.documentElement.scrollTop;
    const baseBottom = rect.bottom + doc.documentElement.scrollTop;

    if (position === "below") {
        return {
            top: baseBottom - 1,
            left: rect.left,
            width: rect.right - rect.left,
            height: 3,
            display: "block"
        }

    } else if (position === "above") {

        return {
            top: baseTop - 1,
            left: rect.left,
            width: rect.right - rect.left,
            height: 3,
            display: "block"
        }


    } else if (position === "left") {

        return {
            top: baseTop,
            left: rect.left - 1,
            height: baseBottom - baseTop,
            width: 3,
            display: "block"
        }

    } else if (position === "right") {

        return {
            top: baseTop,
            left: rect.right - 1,
            height: baseBottom - baseTop,
            width: 3,
            display: "block"
        }

    } else {
        // not implemented
        console.warn("Not implemented", position)
    }
}

//  resolve drop target
function _resolveDropTarget(item, monitor, updateDropTarget, contentDict, blockNodes, slotNodes) {

    const pointerOffset = monitor.getClientOffset();
    if (pointerOffset) {

        // 用鼠标的位置为中点，画一个 8px 的方形；
        // 如果这个方形全在当前 el 里面，而且当前是个 container，那么就是当前为 parent；
        // 如果方形有一部分在在 el 里面，但切了左边边线，则应该放在 el 的左边
        // 右边、下边、上边同理
        const pointerRect = {
            left: pointerOffset.x - 3,
            top: pointerOffset.y - 3,
            right: pointerOffset.x + 4,
            bottom: pointerOffset.y + 4,
        }

        const dragOverStatus = Object.keys(blockNodes).reduce((acc, bid) => {
            const area = blockNodes[bid].getBoundingClientRect()
            if (!area) {
                return acc
            }
            return {
                ...acc,
                // A block cannot be dropped inside itself.                
                [bid]: isRectInsideArea(pointerRect, area) && item.id !== bid
            }
        }, {});

        // find a block, where the pointer is inside it, but not inside any child of it.
        let dropTarget = null;
        for (const bid in dragOverStatus) {
            const block = contentDict[bid];
            const { childIds } = block;
            function inside(id) {
                const block = contentDict[id];
                if(!block) {
                    console.log(">>> Not found block", block, id, contentDict, dragOverStatus, blockNodes);
                }

                const { blockType } = block;
                const { isContainer } = blockType;
                return dragOverStatus[id] && isContainer
            }

            // 这里要处理多个 slot 的情况

            if (inside(bid) && block.everyChild(cid => !inside(cid))) {

                const currentNode = blockNodes[bid];

                function resolveSlot() {
                    // 
                    const slotNodeList = slotNodes[bid] || [];

                    // 找到最近的 slotIndex
                    const distances = slotNodeList.map((slotNode, index) => {
                        const area = slotNode.getBoundingClientRect();
                        const d = distanceToArea(pointerOffset, area);
                        return [d, index, slotNode]
                    });

                    if (distances.length === 0) {
                        return undefined
                    }
                    const [_, index, node ] = distances.reduce((min, current) => {
                        return (min && min[0] < current[0]) ? min : current
                    }, null)
                    return { index, node }                    
                }

                const slot = resolveSlot();
                function resolveIndex(slot) {

                    // 暂时只考虑 display: flex 和 flex-direction: row
                    
                    const style = window.getComputedStyle(slot ? slot.node : currentNode);
                    const isRow = style["display"] === "flex" && (style["flexDirection"] !== "column");

                    // 1. find the child nearest to the pointer
                    // 2. check if center of nearest child is "before" the pointer of "after" the pointer
                    //    in a row, "before" means "to the left"; in a column, "before" means "above"

                    const distances = block.mapChildren((cid, index) => {
                        const area = blockNodes[cid].getBoundingClientRect();
                        const d = distanceToArea(pointerOffset, area);
                        return [cid, d, index]
                    }, slot && slot.index);

                    if (distances.length === 0) {
                        return { index: 0 }
                    }

                    const [nearestId, _, nearestIndex] = distances.reduce((min, current) => {
                        return (min && min[1] < current[1]) ? min : current
                    }, null)

                    const rect = blockNodes[nearestId].getBoundingClientRect();

                    function isBefore() {
                        const center = [rect.left + rect.width / 2, rect.top + rect.height / 2];
                        if (isRow) {
                            // "before" means "to the left"
                            return center[0] < pointerOffset.x
                        } else {
                            // "before" means "above"
                            return center[1] < pointerOffset.y
                        }
                    }

                    // if the nearest block is before the pointer, return index + 1;
                    // otherwise return the index of nearest block
                    const isNearestBefore = isBefore();
                    const index = isNearestBefore ? nearestIndex + 1 : nearestIndex;

                    return { index, nearestId: nearestId, isNearestBefore }
                }

                dropTarget = {
                    parent: bid,
                    slotIndex: slot && slot.index,
                    ...resolveIndex(slot)
                    
                };

                break
            }
        }

        updateDropTarget(dropTarget)
    }

}

const resolveDropTarget = _resolveDropTarget // throttle(_resolveDropTarget, 100);
