
export default function BridgeAction (env) {
    return async function (params) {
        const { to } = params
        if(to && (typeof(to) === 'function')) {
            return to(params, env)
        } else {
            console.warn("There's no bridge to")
            return null
        }

    }
}