
import React from 'react'

export default (props) => {

  const {type, outlined, htmlType, className, children, ...otherProps} = props

  const resolveClassName = () => {
    return 'btn' +
      (type ? ` btn-${type}` : ' btn-default') +
      (className ? ` ${className}`: '') +
      (outlined ? ` btn-outlined` : '')
  }

  return (
    <button type={htmlType || "button"}
      className={resolveClassName()}
      {...otherProps}
      >
      { children }
    </button>
  )

}
