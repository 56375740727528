import React, { useEffect, useState, useContext } from 'react'

import './AdminApp.less';

import useDataLoader from "bwax-ui/legacy/store/useDataLoader";
import { runDataQuery } from 'bwax/query/runClientQuery'

import Loading from 'Client/js/components/Loading'
import SandboxIndicator from './SandboxIndicator'
import AdminAppRoutes from './AdminAppRoutes'

import AdminMenuBar from './AdminMenuBar'

import AdminTopBar from './AdminTopBar'

import getImageURL from 'bwax-ui/getImageURL'

import useBaseSettings from 'bwax-ui/legacy/store/useBaseSettings';

import useCurrentUser from 'bwax-ui/auth/useCurrentUser';

import useModalView from 'Client/js/hooks/useModalView'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import { FacadeContextProvider } from 'bwax/util/FacadeContext'
import { ApplicationContextProvider } from 'bwax/util/ApplicationContext'


import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';
import useExtensions from './hooks/useExtensions'

import page_entry_slim from 'bwax-ui/ml/page_entry_slim.bs'

import pageRuntimeModules from 'bwax-ui/gen/page_runtime_modules.json';
import { untag } from 'bwax/lang/LangHelper'
import { isEqual } from 'lodash';
import { useGeneralSettingValueJs } from './GeneralSettingView';

const gmod = untag(pageRuntimeModules);

const collapseKey = "admin-app-menu-collapsed";
function getLocalItem(key) {
    if (typeof (localStorage) !== 'undefined') {
        return localStorage.getItem(key)
    } else {
        return undefined
    }
}
function setLocalItem(key, value) {
    if (typeof (localStorage) !== 'undefined') {
        return localStorage.setItem(key, value);
    }
}

const v = getLocalItem(collapseKey);
const storedCollapsed = v ? JSON.parse(v) : false;


export default function AdminApp(props) {
    // props used
    const { location, history, widgetFactory, match } = props

    const dlc = useContext(DataLoaderContext);

    const baseSettings = useBaseSettings();


    const { extensions } = useExtensions();

    const { currentUser, forceReload: reloadUser } = useCurrentUser()

    const allApplications = useApplications();
    const { appcode } = match.params;

    useEffect(() => {
        checkUser()
    }, [currentUser])

    useEffect(() => {
        if (document && document.dev) {
            // do nothing
        } else {
            setInterval(() => {
                // 每三秒 reload 一次 用户；
                if (typeof (document) !== 'undefined' && !document.hidden) {
                    reloadUser()
                }
            }, 3000);
        }
    }, []);

    useEffect(() => {
        if (baseSettings && typeof (document) !== 'undefined') {
            document.baseSettings = baseSettings;

            if(baseSettings.adminPageScripts) {
                baseSettings.adminPageScripts.forEach(script => {
                    (0, eval)(script);
                });
            }


        }
    }, [baseSettings])


    function checkUser() {
        const isNotLoggedIn = currentUser === null;
        const noAccess = currentUser && !(currentUser.systemRoles || []).some(r => {
            return r === "Admin" || r === "Operator"
        })
        if (isNotLoggedIn) {
            history.replace(addQueryParam('rt', location.pathname, '/login'))
        }
        if (noAccess) {
            history.replace("/no-permission")
        }
    }

    if (!allApplications || !extensions || !baseSettings) {
        return (
            <Loading />
        )
    }

    const applications = allApplications.filter(a => !a.isLibrary);
    const currentApplication = applications.find(a => {
        return a.code.toLowerCase() === appcode.toLowerCase();
    })

    if(!currentApplication) {
        return "没有对应的应用：" + appcode;
    }



    return (
        <ApplicationContextProvider
            applications={applications}
            currentApplication={currentApplication}
            model="app">
            <AdminAppBody {...{
                location, history, widgetFactory, dlc,
                currentApplication, applications, extensions, currentUser, baseSettings,
            }} />
        </ApplicationContextProvider>
    )

}

const AdminAppBody = React.memo(props => {

    // props used
    const { 
        location, history, widgetFactory, dlc,
        currentApplication, applications, extensions, currentUser, baseSettings,
    } = props

    const { withModalView } = useModalView(location, history)

    const [menuGroups, setMenuGroups] = useState(undefined);

    const [collapsed, setCollapsed] = useState(storedCollapsed)
    /// states ends.

    // console.log("RENDER Admin App", baseSettings);

    ///
    const [facade, setFacade] = useState(undefined);

    useEffect(() => {
        setFacade(undefined);
        const BwaxFacade = require("bwax/facade").default;
        (async () => {
            const facade = new BwaxFacade({
                dlc, isAdmin: true, gmod, prepareEnv: page_entry_slim.prepare_eval_env,
                applicationCode: currentApplication.code,
            });

            await facade.init();
            // prepare 一些肯定会用到的 entity
            await facade.prepare(["用户", "验证用户", "选项类别", "选项文本值", "数据记录评论", "数据更改日志", "导出实体数据任务"])

            setFacade(facade);
        })()
    }, [currentApplication.code]);

    function getLogoURL() {
        if (typeof (document) !== "undefined") {
            if (document && document.baseSettings && document.baseSettings.logo) {
                return getImageURL(document.baseSettings.logo, "thumbnail")
            } else {
                return null
            }
        } else {
            return null
        }
    }
    const logoURL = getLogoURL();

    function renderBody() {
        const renderWithLocation = (location) => {
            if (!facade || menuGroups === undefined 
                    // 为了在切换 App 时保持一致
                    || menuGroups[0] !== currentApplication.code)  {
                return (
                    <Loading />
                )
            }

            return (
                <AdminAppRoutes
                    lookupValueSettings={currentApplication.lookupValueSettings}
                    extensions={extensions}
                    location={location}
                    widgetFactory={widgetFactory}
                    loadingComp={Loading}
                    currentUser={currentUser}
                    additional={{
                        menuGroups,
                        facade,
                    }}
                    facade={facade}
                    routeTo={(path, { modal = true } = {}) => {
                        function wrapPath(path) {
                            if (!path.startsWith("/app") && !path.startsWith("http") && facade && path.startsWith("/")) {
                                const { currentApplication } = facade;
                                return "/app/" + (currentApplication ? currentApplication.code.toLowerCase() : "default") + path
                            } else {
                                return path
                            }
                        }
                        history.push(wrapPath(path), { modal })
                    }}

                />
            )
        }

        const renderedModalView = withModalView(renderWithLocation, collapsed)

        const toggleCollapsed = () => setCollapsed(prev => {
            const collapsed = !prev;
            setLocalItem(collapseKey, JSON.stringify(collapsed));
            return collapsed
        })

        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: "hidden" }}>
                    <AdminTopBar {...{
                        currentUser, baseSettings, history, location,
                        applications, currentApplication,

                        tenantCode: dlc.tenantCode,

                        dlc, facade, 
                    }} />
                    {!facade ? <Loading /> :
                        (
                            <FacadeContextProvider facade={facade}>
                                <div style={{ flex: 1, display: 'flex', overflow: "hidden" }}>
                                    <AdminMenuBar
                                        {...{
                                            location, extensions, history,
                                            currentUser,
                                            setMenuGroups,
                                            collapsed, toggleCollapsed,
                                            facade,
                                            currentApplication,

                                            tenantCode: dlc.tenantCode,
                                        }}
                                    />
                                    <div className="admin-main" style={{
                                        width: collapsed ? "calc(100% - 48px)" : "calc(100% - 200px)"
                                    }}>
                                        {renderedModalView}
                                    </div>                               
                                </div>
                            </FacadeContextProvider>
                        )
                    }
                </div>
                <SandboxIndicator />
            </div>
        )
    }

    return (
        <React.Fragment>
            {renderBody()}
        </React.Fragment>
    )

}, (prevProps, nextProps) => {

    // const { 
    //     history, widgetFactory, dlc, extensions , applications, baseSettings ,

    //     location, currentApplication, currentUser, 
    // } = nextProps;

    const keys = ["location", "currentApplication", "currentUser"];

    return keys.every(k => {
        return isEqual(prevProps[k], nextProps[k]);
    })

})





function loadApplications() {
    return async (env) => {
        //// the entity
        const queryText = `query { definition { applications { name code isLibrary lookupValueSettings } } }`
        const result = await runDataQuery(env)(queryText)()
        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.definition ? data.definition.applications : null
        }
    }
}

function useApplications() {

    const key = `definitions::applications`;
    const { getData } = useDataLoader({ [key]: loadApplications() })
    const [applications] = getData(key);
    return applications;

}