
import React, { useState, useEffect, useRef } from 'react';

import Loading from 'Client/js/components/Loading';

import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

import groupByTags, { buildTagFolderNodes } from './groupByTag';

import { getGlobalEventBus } from 'Client/js/studioApp/EventBus';

import isEqual from 'lodash/isEqual';

export function AdminUISelectPanel(props) {

    const { facade, baseCriteria, renderSelectorNode, itemNode, multitenantType } = props;


    const baseCriteriaRef = useRef();
    baseCriteriaRef.current = baseCriteria;
    // 

    const [adminPages, setAdminPages] = useState([]);
    const [generalSettings, setGeneralSettings] = useState([]);


    const itemStateSetters = {
        "admin-page": setAdminPages,
        "general-setting": setGeneralSettings,
    };


    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("updateItemData", ({ itemType, itemData }) => {
            // update the current item, opened items, and tell the upper layer:
            const setDataState = itemStateSetters[itemType];
            if(setDataState) {
                setDataState(prev => {
                    return  prev.map(e => e.id === itemData.id ? { ...e, ...itemData } : e )                    
                })
            }
        });
        return () => unsubscribe()

    }, [])


    function updateData(criteria, result, setState) {
        
        setState(prev => {

            if (criteria && criteria.标签 && result && result.data && prev) {
                // 只是刷新标签，TODO 暂时不考虑“删除的情况”
                return [
                    ...prev.map(e => {
                        const newOne = result.data.find(ne => ne.id === e.id);
                        if (newOne) {
                            return { ...e, ...newOne }
                        } else {
                            return e;
                        }
                    }),
                    ...result.data.filter(e => {
                        return prev.every(oe => oe.id !== e.id)
                    })
                ]
            } else {
                return result.data;
            }
        });
    }


    async function queryAdminPages(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "管理页面",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: ["名称", "标签", "描述", "显示权重", "创建时间", "多租户"],

        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            // setAdminPages(result.data);
            updateData(criteria, result, setAdminPages);
        }
    }

    async function queryGeneralSettings(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;
        const queryObj = {
            entityName: "通用设定",
            // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
            condition: mergeCriteria(baseCriteria, criteria),
            // 其他参数
            pageSize: 1000, offset: 0, queryType: "listAll",
            fieldPaths: ["名称", "描述", "创建时间", "多租户"],
        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            // setGeneralSettings(result.data);
            updateData(criteria, result, setGeneralSettings);
            
        }
    }

    useEffect(() => {
        queryAdminPages({});
        queryGeneralSettings({});
    }, [ JSON.stringify(baseCriteria) ]);

    async function reloadItemList(itemType, parentItem) {

        const itemFolderReloaders = {
            "admin-page": criteria => queryAdminPages(criteria, { forceRefreshing: true }),
            // not general-setting because here we only handle admin-setting by definition
            "admin-setting": criteria => queryGeneralSettings(criteria, { forceRefreshing: true }),
        };
        if (parentItem && parentItem.itemType) {
            const parentItemType = parentItem.itemType;
            if (parentItemType.endsWith("-tag-folder")) {
                const folderType = parentItem.itemType.replace("-tag-folder", "");
                const tagName = parentItem.getName();
                await (itemFolderReloaders[folderType] || (async _ => { }))({ 标签: { contains: tagName } })


            } else if (parentItemType.endsWith("-folder")) {
                const folderType = parentItem.itemType.replace("-folder", "");
                await (itemFolderReloaders[folderType] || (async _ => { }))({})
            }


        }
    }

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("reloadItemList", ({ itemType, parentItem }) => {
            reloadItemList(itemType, parentItem);
        });
        return () => {
            unsubscribe();
        }
    }, [] ) 


    if (adminPages === undefined
        || generalSettings === undefined) {
        return <Loading />
    }


    // tree:
    // 实体关系图 []
    // 数据实体 

    // build nodes;

    const sorting = (a, b) => {
        // 给 field 排序, 根据 displayWeight 从大到下排列
        const getWeight = f => f["显示权重"] || 0
        const weightOrder = getWeight(b) - getWeight(a);

        if (weightOrder === 0) {
            const getTime = f => (new Date(f["创建时间"])).getTime();
            return getTime(b) - getTime(a);

        } else {
            return weightOrder
        }
    }

    function buildSubNodes(objs, itemType) {
        const [taggedGroups, untagged] = groupByTags(objs);

        return buildTagFolderNodes(taggedGroups, untagged, itemType + "-tag-folder", itemNode,
            p => itemNode({ itemType, data: p, multitenantType })
        );
    }

    const nodes = [
        itemNode(
            { name: "管理后台页面", itemType: "admin-page-folder" },
            buildSubNodes(adminPages, "admin-page"),

        ),
        itemNode({ name: "管理后台设置", itemType: "admin-setting-folder" }, generalSettings.sort(sorting).map(p => {
            const itemType = "general-setting";
            return itemNode({ itemType, data: p })
        })),
    ];


    return (
        <div className="panel-content">
            {nodes.map(n => renderSelectorNode(n, 0))}
        </div>
    )
}

export default React.memo(AdminUISelectPanel, (prevProps, nextProps) => {
    return isEqual(prevProps.baseCriteria, nextProps.baseCriteria);
});


