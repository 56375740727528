

import React from 'react';
import { CompactPicker, BlockPicker, SketchPicker } from 'react-color';

import { applyCustomStyle } from '../inlineStyle'

/// some helper function from plugins

const preventBubblingUp = (event) => { event.preventDefault(); }

const STYLE_KEY = "color"

const presetColors = [
  '#F95630', //cb primary color
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF'
]

export default function ColorButton (props) {

  const {getEditorState, setEditorState, onOverrideContent } = props

  const prefix = STYLE_KEY + "-"
  const currentColor = (getEditorState().getCurrentInlineStyle().findLast((v) => {
    return v.startsWith(prefix)
  }) || "").substring(prefix.length)

  // console.log("currentColor: ", currentColor);

  const toggleCustomColor = (value) => {

    const nextEditorState = applyCustomStyle(
      getEditorState(),
      STYLE_KEY,
      value
    )
    setEditorState(nextEditorState)
  }

  const onChangeComplete = (color)=> {
    // console.log("onChangeComplete :", color);
    const { rgb, hex } = color
    const colorRgb = rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` : null
    toggleCustomColor(colorRgb || hex, currentColor)
    onOverrideContent(null)
  }

  const picker = (props) => (
    <SketchPicker
      color={currentColor}
      presetColors={presetColors}
      onChangeComplete={onChangeComplete} />
  )

  const style = currentColor ? {
    backgroundColor: currentColor,
    height: "3px",
    width: "100%"
  } : null

  return (
    <div onMouseDown={preventBubblingUp}>
      <button className={"editor-button"} type="button" onClick={e => {
        // console.log(e.target, e.target.type)
        preventBubblingUp(e)
        onOverrideContent({
          meta : {
            outerClassName: "no-arrow"
          },
          persistent: true,
          component: picker
        })
      }} >
        <i className={"fa fa-font"} aria-hidden="true"></i>
        <div style={style}>
        </div>
      </button>
    </div>
  )

}
