import React, { useState, useEffect, useRef } from 'react'

import Loading from 'Client/js/components/Loading'

import {
    BiCodeAlt, BiUndo, BiRedo, BiX, BiInfoCircle, BiDetail, BiError, BiBlanket,
    BiCodeCurly, BiPackage
} from 'react-icons/bi';

import {
    MdDevices
} from 'react-icons/md'

import { IoPlayOutline, IoStopOutline, IoExitOutline } from 'react-icons/io5';

import VisualEditor from './VisualEditor';

import DefsCodeEditor from 'Client/js/codeEditor/DefsCodeEditor';

import StylesEditor from './StylesEditor';

import ToolbarButton from './ToolbarButton';

import InputParamsPanel from './InputParamsPanel';

import { mergePreviewData } from './editorUtils';

import { unpack } from 'bwax/lang/LangHelper'

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import ResultPanel from 'Client/js/studioApp/components/ResultPanel'
import IOMetaPanel from 'Client/js/studioApp/components/IOMetaPanel';

import { getCodeTemplate } from 'Client/js/designApp/pages/RuntimeContextCodeTemplates';

import get from 'lodash/get';

import BaseStrategy from './strategies/BaseStrategy';
import VirtualFieldStrategy from './strategies/VirtualFieldStrategy';
import BackendItemStrategy from './strategies/BackendItemStrategy';
import EventHandlerStrategy from './strategies/EventHandlerStrategy';
import FrontendItemStrategy from './strategies/FrontendItemStrategy';

import FieldFormEditor from './FieldFormEditor';

import DropDialog from './DropDialog';

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';
import ResourceMananger from '../../pageDesigner/inputs/ResourceMananger';

export default function CompositeEditor(props) {

    const {
        isEditorActive,
        typingEnv, previewFacade,
        item, value,
        onChange, onDerivedValueChange,

        saveValue, markError,

        hasError,

        // for property editing
        entity, fieldNamesToRead, fieldNamesToWrite, facade,
    } = props;


    //[ tenv, dts, entity_dict, data_type_dict ]
    const [resultedTypingEnv, setResultedTypingEnv] = useState(); // the typing env resulted by typing of current code.

    const codeEditorCommandsRef = useRef({});

    // 找到第一个 DesignTypeCode 代码，就作为当前的 codeFieldName,
    const codeFieldName = fieldNamesToWrite.find(name => {
        const field = entity.fields.find(f => f.name === name);
        return field && field.type === "DesignTypeCode"
    });

    const hasFieldToView = fieldNamesToRead.some(name => name !== codeFieldName);

    const isUI = ["page", "page-component", "admin-page", "entity-list-page", "entity-detail-page"].indexOf(item.itemType) !== -1;

    const supportedModes = [
        ...(codeFieldName ? ["visual", "code"] : []),
        ...(isUI ? ["styles"] : []),
        ...(hasFieldToView ? ["form"] : []),

    ];

    const editorModeKey = "studio-editor-mode-" + item.itemType + "_" + item.data.id;

    const saveValueRef = useRef();
    saveValueRef.current = saveValue;

    const isEditorActiveRef = useRef();
    isEditorActiveRef.current = isEditorActive;
    useEffect(() => {
        // delete event
        function handleKeyDownEvents(e) {
            if (e.metaKey == true && e.key == "s") {
                e.preventDefault();

                // only when current editor is active:
                if (isEditorActiveRef.current) {
                    saveValueRef.current();
                }
            }
        }
        document.addEventListener("keydown", handleKeyDownEvents)

        return _ => {
            document.removeEventListener("keydown", handleKeyDownEvents);
        }

    }, [])


    // mode: visual | code | form
    const [mode, setMode] = useStateWithLocalCache(editorModeKey, (
        supportedModes.indexOf("code") !== -1 ? "code" : supportedModes[0]
    ));

    const [typedTree, setTypedTree] = useState();

    const [strategy] = useState(() => {

        const strategies = {
            "virtual-field": itemType => new VirtualFieldStrategy(itemType),
            "event-handler": itemType => new EventHandlerStrategy(itemType),
            "scheduled-event-handler": itemType => new BackendItemStrategy(itemType),
            "data-interface": itemType => new BackendItemStrategy(itemType),
            "page": itemType => new FrontendItemStrategy(itemType),
            "page-component": itemType => new FrontendItemStrategy(itemType),
            "admin-page": itemType => new FrontendItemStrategy(itemType),
            "entity-list-page": itemType => new FrontendItemStrategy(itemType),
            "entity-detail-page": itemType => new FrontendItemStrategy(itemType),
        }
        const defaultOne = itemType => new BaseStrategy();
        return (strategies[item.itemType] || defaultOne)(item.itemType);
    })

    useEffect(() => {
        return () => {
            if (strategy) strategy.dispose();
        }
    }, [strategy])



    // make runtime profile to use legacy codes:
    const itemType = item.itemType;
    const runtimeProfileData = ({
        "virtual-field": _ => ({
            entityName: get(value, "数据实体.名称")
        }),
        "event-handler": _ => ({
            entityName: value["实体名"],
            eventType: value["事件类型"]
        }),
        "scheduled-event-handler": _ => ({}),
        "data-interface": _ => ({
            interfaceType: value["类型"],
            entityName: value["关联实体"]["名称"]
        }),
        "page": _ => ({
            urlPattern: value["URL路径"],
            dataName: value['名称'],
        }),
        "page-component": _ => ({
            dataName: value["名称"]
        }),
        "page-fragment": _ => ({
            fragmentName: value["名称"],
        }),
        "admin-page": _ => ({}),
        "general-setting": _ => ({}),

        "entity-list-page": _ => ({
            name: "admin-page", pageType: "recordList", entityName: value["名称"]
        }),
        "entity-detail-page": _ => ({
            name: "admin-page", pageType: "recordDetail", entityName: value["名称"]
        }),

    }[itemType] || (_ => ({})))();

    const runtimeProfile = ({ name: itemType, ...runtimeProfileData });

    const entityName = runtimeProfile.entityName;

    const defaultCode = codeFieldName ? getCodeTemplate(runtimeProfile) : "";

    // changeCode 可能会被 close
    const valueRef = useRef(value);
    valueRef.current = value;

    const changeCode = values => {
        // preview data should be merged:
        const value = valueRef.current;

        const oldCode = value[codeFieldName] || {};
        const code = {
            ...oldCode,
            ...values,
            previewData: mergePreviewData(oldCode.previewData, values.previewData)
        };

        onChange({
            ...value,
            [codeFieldName]: code,
        })
        const derivedValue = strategy.deriveChangesFromPreviewData(value, codeFieldName, code.previewData);

        // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1023
        // 用于延迟合并 derived values
        if (derivedValue && Object.keys(derivedValue).length > 0) {
            onDerivedValueChange(derivedValue);
        }

    }

    const inputPanelCloserRef = useRef();

    const [testRecord, setTestRecord] = useState();
    const [resultPanelVisible, setResultPanelVisible] = useState(false);
    const [running, setRuning] = useState();

    // 用于 continuous preview
    useEffect(() => {
        // Keep sending the messages to preview page if not stopped
        if (running && running.type === "continuous-preview" && isEditorActive) {
            running.postPreview(value[codeFieldName], { runtimeProfile, testRecordId: testRecord && testRecord.id })
        }

    }, [codeFieldName && value[codeFieldName], running, isEditorActive])

    async function startRunning() {
        // 1. 如果没有 test input params，则显示 paramInputPanel；
        // 2. 如果，则直接 run 并且 preview （open preview 页面）
        // setRunning(true)
        console.log("Start running", testRecord, value, codeFieldName);

        if (!codeFieldName) return;

        setResultPanelVisible(true);
        setRuning(undefined);
        // prepare data，and then execute the result

        const code = value[codeFieldName];
        const { multitenantType } = item;

        const running = await strategy.startRunning(code, {
            testRecord, previewFacade, entityName, value, multitenantType
        })

        setRuning(running)

    }

    function stopRunning() {
        setRuning(strategy.stopRunning())
    }

    const getParamInputPanel = () => {

        if (codeFieldName && value[codeFieldName] && previewFacade) {
            return (
                <InputParamsPanel {...{
                    code: value[codeFieldName], changeCode,

                    typingEnv: resultedTypingEnv || typingEnv,
                    previewFacade,

                    testRecord, setTestRecord,
                    testRecordOptions: strategy.getTestRecordOptions(value, { previewFacade, runtimeProfile, codeFieldName }),

                    onConfirm: () => {
                        startRunning();
                        if (inputPanelCloserRef.current) {
                            inputPanelCloserRef.current();
                        }
                    }
                }} />
            )
        } else {
            return null
        }
    }

    function renderIconButton(props) {
        return (
            <ToolbarButton {...props} />
        )
    }

    function renderTypeInfoIcon() {
        if (!codeFieldName) {
            return null;
        }
        const { previewData, compiled } = value[codeFieldName] || {};

        if (previewData && compiled) {
            const unpacked = unpack(compiled);
            const panel = <IOMetaPanel ioTypeMetas={previewData.ioTypeMetas} unpacked={unpacked} />

            return renderIconButton({
                icon: <BiInfoCircle />,
                panel,
                pressToShowMore: true,
                // showMore: _ => 
                // panelCloser: closer => inputPanelCloserRef.current = closer,
            });
        }

        return null
    }

    const [ resourcePanelOpen, setResourcePanelOpen ] = useState(false);
    function renderResourceManagerIcon() {
        return (
            <>
                {renderIconButton({
                    icon: <BiBlanket />,
                    onPress: _ => {
                        setResourcePanelOpen(true);
                    }
                })}
                <ResourceMananger
                    open={resourcePanelOpen} setOpen={setResourcePanelOpen}
                    onFileSelect={file => {
                        console.log("Select file", file);
                    }}
                />
            </>
        )
    }


    const editModeIcons = {
        "visual": <MdDevices />, //<BiMobileAlt />,
        "code": <BiCodeAlt />,
        "form": <BiDetail />,
        "styles": <BiCodeCurly />
    }

    const toolbar = (
        <>
            {supportedModes.map(
                m => renderIconButton({ key: m, icon: editModeIcons[m], active: mode == m, onPress: () => setMode(m) })
            )}
            <div className="separator"></div>
            {renderIconButton({ icon: <BiUndo />, onPress: () => { } })}
            {renderIconButton({ icon: <BiRedo />, disabled: true, onPress: () => { } })}
            {strategy.isRunnable() ?
                (
                    <>
                        <div className="separator"></div>
                        {renderIconButton({
                            icon: <IoPlayOutline />,
                            onPress: startRunning,
                            disabled: running && running.needToStop,
                            panel: getParamInputPanel(),
                            pressToShowMore: true,
                            // showMore: _ => 
                            panelCloser: closer => inputPanelCloserRef.current = closer,
                        })}
                        {running && running.type == "continuous-preview" ?
                            (
                                <>
                                    {renderIconButton({ icon: <IoStopOutline />, disabled: !running, onPress: stopRunning })}
                                    {renderIconButton({
                                        icon: <IoExitOutline />, onPress: () => {
                                            window.open("/preview", '_blank');
                                        }
                                    })}
                                </>
                            ) : null}
                    </>
                ) : null
            }

            < div className="separator"></div>
            {renderTypeInfoIcon()}
            {renderResourceManagerIcon()}
        </>
    )

    const [statusPanelWidth, setStatusPanelWidth] = useState(300);

    const [propertyPanelWidth, setPropertyPanelWith] = useState(300);
    const [propertyPanelWidthResizeActive, setPropertyPanelResizerActive] = useState(false);

    const [propertyPanelContent, setPropertyPanelContent] = useState()


    function updatePanelWidth(e, leftToRight, update) {
        update(prev => {
            const newSize = leftToRight ? prev + e.movementX : prev - e.movementX;
            // updateLocalItem(rightPanelWidthKey, newSize)            
            return newSize < 100 ? 100 : newSize // (newSize > 600  ? 600 : newSize);
        })
    }

    function renderResultPanel({ predata, result }) {
        return (
            <>
                {predata ?
                    <pre style={{
                        backgroundColor: "rgba(0, 0, 0, 0.04)", borderRadius: 4, padding: 8,
                        minHeight: 100, maxHeight: 260, overflow: "auto"
                    }}>
                        {JSON.stringify(lang_entry_slim.value_to_js(predata), null, 2)}
                    </pre>
                    : null}
                {result ? <ResultPanel result={result} /> : null}
            </>
        )
    }

    const shouldDisplayStatusPanel = resultPanelVisible && running && running.type === "one-time-execution";

    const codeEditorCommands = codeEditorCommandsRef.current;
    /// editor renderers

    const codeValue = codeFieldName && value && value[codeFieldName] || {};

    const editModeRenderers = {
        "form": _ => (
            <FieldFormEditor {...{
                entity, fieldNamesToRead, fieldNamesToWrite, facade,
                value, onChange,
            }} />
        ),
        "visual": _ => value === undefined || resultedTypingEnv === undefined ? <Loading /> : <VisualEditor {...{
            value: codeValue,
            onChange: changeCode,
            item, runtimeProfile,

            typingEnv: resultedTypingEnv || typingEnv,
            markError,

            onTypedTreeChange: setTypedTree,
            typedTree,

            saveValue,

            setPropertyPanelContent, propertyPanelContent,

            codeEditorCommands

        }} />,
        "code": _ => (
            value === undefined || typingEnv === undefined ? <Loading /> :
                <DefsCodeEditor {...{
                    typingEnv,

                    setResultedTypingEnv,

                    targetEntityName: entityName,
                    sourceCode: codeValue.src || defaultCode,
                    onChange: changeCode,
                    markError,

                    onTypedTreeChange: t => {
                        setTypedTree(t);
                    },
                    typedTree,

                    bindCommands: commands => codeEditorCommandsRef.current = commands,

                    renderDropDialog: props => {
                        return (
                            <DropDialog {...{
                                ...props,
                                runtimeProfile,
                                previewFacade,
                                codeEditorCommands: codeEditorCommandsRef.current
                            }} />
                        )
                    }
                }} />
        ),
        "styles": _ => (
            value === undefined ? <Loading /> :
                <StylesEditor {...{
                    value: value[codeFieldName] ? value[codeFieldName].styles : undefined,
                    onChange: styles => {
                        changeCode({ styles })
                    }
                }} />
        )
    }

    const totalPanelWidth = (shouldDisplayStatusPanel ? statusPanelWidth : 0)
        + (propertyPanelContent ? propertyPanelWidth : 0);

    return (
        <>
            <div className="editor-bar">
                <div className="left-group">
                    {toolbar}
                </div>
                <div className="right-group">
                    {
                        hasError && codeEditorCommands && codeEditorCommands.jumpToError ? renderIconButton({
                            icon: <BiError />, onPress: () => {
                                // 要先 focus 到 code editor
                                codeEditorCommands.focus()
                                codeEditorCommands.jumpToError()
                            }
                        }) : null
                    }
                </div>
            </div>
            <div className="editor-main" {...{
                onMouseMove: e => {
                    if (propertyPanelWidthResizeActive) {
                        updatePanelWidth(e, false, setPropertyPanelWith)
                    }
                },
                onMouseUp: _ => {
                    if (propertyPanelWidthResizeActive) {
                        setPropertyPanelResizerActive(false);
                    }
                }
            }}>
                <div className="editing-panel" style={{
                    width: (`calc(100% - ${totalPanelWidth}px`)
                }}>
                    {
                        supportedModes.map(m => (
                            <div className={"editor-mode " + (mode == m ? " visible" : "")} key={m}>
                                {editModeRenderers[m] ? editModeRenderers[m]() : null}
                            </div>
                        ))
                    }
                </div>
                {propertyPanelContent ?
                    <div className="property-panel" style={{ width: propertyPanelWidth, display: "flex", height: "100%" }}>
                        <div className="drag-line" onMouseDown={() => {
                            setPropertyPanelResizerActive(true);
                        }} />
                        {/* 这里是用于里面的代码进行 portal rendering 的 */}
                        <div id="property-panel-portal" style={{ width: "100%" }}></div>
                    </div>
                    : null
                }
                {shouldDisplayStatusPanel ? (
                    <div className="status-panel" style={{ width: statusPanelWidth }}>
                        <div className="status-panel-toolbar">
                            <div className="left-group"></div>
                            <div className="right-group">
                                <div className="small-icon-button" onClick={() => {
                                    setResultPanelVisible(false)
                                }}>
                                    <BiX />
                                </div>
                            </div>
                        </div>
                        <div className="status-panel-body">
                            {renderResultPanel(running.data)}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )
}



