

import React, { useState } from 'react'

import {
    MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight,
} from 'react-icons/md';

import './FormGallery.less'

export default function FormGallery({ form }) {
    const { name, element, titleIcon, isActive } = form

    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className="form-gallery">
            <div className="section-title" onClick={_ => {
                setCollapsed(!collapsed)
            }}>
                <div>
                    <div className="op-icon">
                        {collapsed ? <MdOutlineKeyboardArrowRight /> : <MdOutlineKeyboardArrowDown />}
                    </div>
                    <span>{name}</span>
                    {
                        isActive ? (
                            <div className='active-circle'/>
                        ) : null
                    }
                </div>
                { collapsed ? null : titleIcon }
            </div>
            <div className={`styles-container ${collapsed ? 'hidden' : ''}`}>
                { element }
            </div>
        </div>
    )
}
