// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function is_html(param) {
  var match = param[0];
  if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "Html")) {
    return false;
  } else {
    return true;
  }
}

function render_html(render_element, render_null, render_text, render_custom_element, on_msg, v) {
  var convert_to_props = function (attributes) {
    var style = Js_dict.fromList(Plate$BwaxAdmin.List.keep_map((function (param) {
                if (param.tag === /* Attribute_style */3) {
                  return /* tuple */[
                          param[0],
                          param[1]
                        ];
                }
                
              }), attributes));
    var other_props = Plate$BwaxAdmin.List.keep_map((function (param) {
            switch (param.tag | 0) {
              case /* Attribute_int */1 :
                  return /* tuple */[
                          param[0],
                          param[1]
                        ];
              case /* Attribute_string */0 :
              case /* Attribute_bool */2 :
                  return /* tuple */[
                          param[0],
                          param[1]
                        ];
              case /* Attribute_event_msg */4 :
                  var msg = param[1];
                  return /* tuple */[
                          param[0],
                          (function (param) {
                              return Curry._1(on_msg, msg);
                            })
                        ];
              case /* Attribute_event_string_msg */5 :
                  var transform_msg = param[1];
                  return /* tuple */[
                          param[0],
                          (function (s) {
                              return Curry._1(on_msg, Curry._1(transform_msg, s));
                            })
                        ];
              case /* Attribute_style */3 :
              case /* Attribute_event_int_msg */6 :
                  return ;
              
            }
          }), attributes);
    return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "style",
                    style
                  ],
                  other_props
                ]));
  };
  var render_html_node = function (html_node, index) {
    if (typeof html_node === "number") {
      return Curry._1(render_null, /* () */0);
    } else if (html_node.tag) {
      var children = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (index, c) {
                  return render_html_node(c, index);
                }), html_node[2]));
      var props = convert_to_props(html_node[1]);
      return Curry._4(render_element, html_node[0], props, children, index);
    } else {
      return Curry._1(render_text, html_node[0]);
    }
  };
  var match = v[0];
  if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "Html")) {
    return Curry._1(render_null, /* () */0);
  } else {
    return render_html_node(match[1], 0);
  }
}

var as_string = Lang_eval$BwaxAdmin.as_string;

exports.as_string = as_string;
exports.is_html = is_html;
exports.render_html = render_html;
/* Plate-BwaxAdmin Not a pure module */
