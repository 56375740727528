
import React from 'react'

import { EditorState, RichUtils, AtomicBlockUtils, Modifier } from 'draft-js'

import { getSelectedBlock, mergeBlockData } from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

import HyperlinkPlugin from './HyperlinkPlugin'

export function findCurrentHyperlink(editorState, selectionState) {
  const selection = selectionState ? selectionState : editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  // const endKey = selectionState.getEndKey(); // endKey is supposed to be the same as startKey
  const block = contentState.getBlockMap().get(startKey);

  if(block.getType() === 'atomic') {

    const link = block.getData().get('link')
    if(link) {
      return {
        currentURL: link,
        atomic: true
      }
    } else {
      return {
        currentURL: null,
        atomic: true
      }
    }
  }


  ////
  let foundHyperlinks = []
  block.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'HYPERLINK'
      );
    },
    (start, end) => {

      if(selection.getStartOffset() < end && selection.getEndOffset() > start ) {
         foundHyperlinks.push(
           contentState.getEntity(block.getEntityAt(start))
         )
      }
    }
  )
  const urls = foundHyperlinks.map(e => e.getData().url)
  /// we only take the first one into account
  return {
    currentURL:  urls.length > 0 ? urls[0] : null,
    atomic: false
  }

}

export function isHyperlinkable(editorState, selectionState) {
  const selection = selectionState ? selectionState : editorState.getSelection()
  // console.log("selection is", selection.isCollapsed(), selection.getStartKey(), selection.getEndKey())

  const block = getSelectedBlock(editorState, selectionState)
  // console.log(block.getType())

  return (block.getType() === 'atomic') ||
    (!selection.isCollapsed() && selection.getStartKey() === selection.getEndKey())

}


export function insertHyperlink(editorState, selectionState, url) {

  const selection = selectionState ? selectionState : editorState.getSelection()

  const block = getSelectedBlock(editorState, selection)

  // console.log("Insert Hyperlink", block.toJS(), selection.toJS())
  const adjustedURL = (url.startsWith("http://") || url.startsWith("https://")) ? url : "http://" + url

  if(block.getType() === 'atomic') {

    // add link to the atomic block:

    return mergeBlockData(editorState, selection, { link: adjustedURL })


  } else if(selection.isCollapsed()) {
    // Selection is collapsed, thus no Hyperlink is inserted
    console.warn("Selection is collapsed, thus no Hyperlink is inserted")

  } else if(selection.getStartKey() !== selection.getEndKey()) {
    // Hyperlink can not be inserted for text across blocks
    console.warn("Hyperlink can not be inserted for text across blocks")

  } else {

    // insert an entity denoting the Hyperlink
    // insert an entity denoting the Hyperlink
    const contentState = editorState.getCurrentContent();


    const contentStateWithEntity = contentState.createEntity(
      'HYPERLINK',
      'MUTABLE',
      {url: adjustedURL}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

    return RichUtils.toggleLink(
      newEditorState,
      selection,
      entityKey
    )
  }
}

export function removeHyperlink(editorState, selectionState) {
  const selection = selectionState ? selectionState : editorState.getSelection()

  return RichUtils.toggleLink(
    editorState,
    selection,
    null
  )
}


export default HyperlinkPlugin
