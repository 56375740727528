

import React, { useRef, useEffect } from 'react'

import { useDrag } from 'react-dnd'

import { MdCrop32 } from 'react-icons/md';

export default function BlockPicker({ blockType, setOperatingState }) {

    const elRef = useRef(null);

    const dragItem = {
        blockType,
        isNew: true,
    }

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: blockType.name,
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.

        item: dragItem,

        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),

        end: _ => {
            setOperatingState(prev => ({...prev, beingDragged: null }));
        }
    }));

    function setRef(ref) {
        elRef.current = ref;
        drag(ref)
    }

    useEffect(() => {
        if(isDragging) {
            setOperatingState(prev => ({ ...prev, beingDragged: dragItem }))
        }
        
    }, [ isDragging ])



    const { label, icon } = blockType;
    return (
        <div className="block-picker" ref={setRef}>
            <div className="icon">{icon || <MdCrop32 />}</div>
            <div className="label">{label}</div>
        </div>
    )

}
