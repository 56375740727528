
import React from 'react'

import ColoredTag from 'Client/js/ui/components/ColoredTag';
import FlexTags from 'Client/js/ui/components/FlexTags';

export default function Select(props) {
    const { params } = props
    const { options, value, colors, excludedValues = [] } = params;

    function getOptionName(val) {
        const option = (options || []).find(o => o.value === val)
        return !!option ? option.name : val
    }

    function renderText(v, defaultColor = "N20") {
        if(!v) return null;
        const text = getOptionName(v);
        function getColor() {
            return colors && colors[text]
        }
    
        const color = getColor() || defaultColor;
        if (color) {
            return <div style={{display: "flex"}}><ColoredTag {...{ text, color, textClamped: true }} /></div>;
        } else {
            return text
        }
    }

    if (Array.isArray(value)) {
        const texts = value.filter(
            v => excludedValues.indexOf(v) === -1
        ).map(v => ({ text: getOptionName(v), color: "N20"}));
        
        return (
            <div style={{display: "inline-block"}}>
                <FlexTags texts={texts} />
            </div>
        )
    } else {
        return renderText(value)
    }


}

