import React, { useState, useRef, useEffect } from 'react';

import './StudioExplorer.less';

import DataModelSelectPanel from './selectPanels/DataModelSelectPanel';
import UISelectPanel from './selectPanels/UISelectPanel';
import AdminUISelectPanel from './selectPanels/AdminUISelectPanel';
import ProcessSelectPanel from './selectPanels/ProcessSelectPanel';

import StudioItem from './StudioItem';

import AddItemDialog from './dialogs/AddItemDialog'
import DeletionDialog from './dialogs/DeletionDialog';
import AddDependencyDialog from './dialogs/AddDependencyDialog';

import {
    UserInterfaceIcon, ProcessIcon, AdminPageIcon, AppResourceIcon, DependencyIcon
} from './studioIcons';

import SelectorNode from './selectPanels/SelectorNode'
import ItemActionMenu, { getActionGroups } from './ItemActionMenu';


import { getGlobalEventBus } from './EventBus';
import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';


const storedSelectedNavKey = "item-select-stored-selected-nav";

export default function StudioExplorer(props) {

    const {
        facade,
        openItem,
        closeItem,
        currentApplication,
        currentItem,

        setMinimized,

        domainSettings = {},


    } = props;

    // Category
    // - 模型: Entities, EntityDiagram

    const [activePanel, setActivePanel] = useStateWithLocalCache(storedSelectedNavKey, "数据模型");

    const inactiveIconStyle = { opacity: 0.6 };

    const uiIcon = style => <UserInterfaceIcon size="small" style={style} />;
    const processIcon = style => <ProcessIcon size="small" style={style} />;
    const adminUiIcon = style => <AdminPageIcon size="small" style={style} />;

    const appResourceIcon = style => <AppResourceIcon size="small" style={style} />;

    const panels = [
        ["数据模型", appResourceIcon, DataModelSelectPanel ],
        // ["数据模型", dataModelIcon, DataModelSelectPanel],
        ["用户界面", uiIcon, UISelectPanel],
        ["后台界面", adminUiIcon, AdminUISelectPanel ],
        ["业务流程", processIcon, ProcessSelectPanel],
        // ["依赖应用", dependencyIcon, DependencySelectPanel],
    ]

    const [itemToDelete, setItemToDelete] = useState();

    const [addItemConfig, setAddItemConfig] = useState();

    const [addDependencyConfig, setAddDependencyConfig] = useState();

    const [multitenantType, setMultitenantType] = useState(
        domainSettings.multitenancy ? "tenant": "platform"
    ); // tenant, platform

    // const listReloadersRef = useRef({});

    //
    const addItemHandler = itemType => (item, fixedValues) => {
        return setAddItemConfig({ itemType, parentItem: item, fixedValues })
    }

    async function reloadList(itemType, parentItem) {
        // call all the reloaders:
        // for (let reload of (Object.values(listReloadersRef.current))) {
        //     await reload(itemType, parentItem);
        // }
        getGlobalEventBus().dispatch("reloadItemList", { itemType, parentItem })

    }

    function handleAction({ actionName, item }) {
        if (actionName === "open") {
            openItem(item)
        } else if (actionName === "remove") {
            setItemToDelete(item);
        } else if (actionName.startsWith("new-")) {

            const itemType = actionName.substring(4);
            if (itemType == "nav-bar") {
                addItemHandler("general-setting")(item, { 名称: "导航栏" });

            } else if (itemType == "admin-top-left") {
                addItemHandler("general-setting")(item, { 名称: "后台左上角" }); 
            } else {
                addItemHandler(itemType)(item, {});
            }

        } else if (actionName === "edit-list-page") {
            // make up a new item
            const listPageItem = new StudioItem({ itemType: "entity-list-page", data: item.data, multitenantType });
            openItem(listPageItem);

        } else if (actionName === "edit-detail-page") {
            // make up a new item
            const newItem = new StudioItem({ itemType: "entity-detail-page", data: item.data, multitenantType });
            openItem(newItem);

        } else if (actionName === "refresh") {
            reloadList(undefined, item);

        } else if (actionName === "add-dependency") {
            setAddDependencyConfig({ parentItem: item })

        } else {
            console.log("No handler for", actionName, item)
        }
    };

    function renderSelectorNode(node, depth, panel, multitenantType) {
        return (
            <SelectorNode key={node.item.itemKey()} {...{
                node, depth,

                currentItem,
                
                onExpandTo: _ => {                    
                    if(multitenantType) {
                        setMultitenantType(multitenantType);
                    }
                    setMinimized(null),
                    setActivePanel(panel);
                },

                onDoubleClick: item => {

                    const actionGroups = getActionGroups(item);
                    const isOpenAllowed = actionGroups.some(g => g.indexOf("open") != -1);
                    if(isOpenAllowed) {
                        handleAction({
                            actionName: "open",
                            item
                        })
                    } else {
                        console.log("Hi, It can't be opened")
                    }

                },
                renderContextMenu: (item, menuCloser) => {
                    return (
                        <ItemActionMenu {...{
                            item,
                            onAction: action => {
                                handleAction(action);
                                if (menuCloser) {
                                    menuCloser()
                                }
                            }
                        }} />
                    )

                }

            }} />
        )
    }

    function itemNode({ itemType, name, data = {}, multitenantType }, childNodes = [] ) {
        const item = new StudioItem({ itemType, name, data, multitenantType });

        // set parent items:
        childNodes.forEach(n => {
            n.item.setParentItem(item);
        });

        return { item, childNodes }
    }



    // handle the expandtoItem
    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("expandToItem", ({ item }) => {
            console.log("Itenm", item, domainSettings);
            if(domainSettings.multitenancy) {
                setMultitenantType(item.multitenantType);
            }
        });
        return () => {
            unsubscribe();
        }
    }, [] ) 



    return (
        <div className="studio-explorer">
            <div className="nav-bar">
                {panels.map(p => {
                    const [name, icon, _] = p;
                    const isActive = name == activePanel;
                    return (
                        <div key={name} className={"explorer-nav" + (isActive ? " active" : "")} onClick={_ => {

                            if (!isActive) {
                                setActivePanel(name);
                                setMinimized(null);
                            } else {
                                // 
                                setMinimized(40);
                                setActivePanel(null);
                            }
                        }}>
                            {icon(isActive ? {} : inactiveIconStyle)}
                            {/* <div>{name}</div> */}
                        </div>
                    )

                })}
            </div>
            <div className="explorer-body-wrapper">
                {panels.map(p => {
                    const [name, _, Panel] = p;
                    const isActive = name == activePanel;


                    const appId = currentApplication ? currentApplication.id : null;
                    const appValue = {
                        "应用": appId
                    };
                    const baseCriteria = {
                        ...(multitenantType ? {
                            "多租户": (multitenantType == "tenant" ? true : false)
                        } : {}),
                        ...appValue
                    };

                    function renderPanel (multitenantType) {
                        return (
                            <Panel {...{
                                facade,
                                itemNode,
                                currentItem,
                                baseCriteria,
                                multitenantType,
                                renderSelectorNode: (node, depth) => {
                                    return renderSelectorNode(node, depth, name, multitenantType)
                                }
    
                            }} />
                        )
                    }

                    function renderPanelForMT() {
                        if(domainSettings.multitenancy) {
                            return (
                                <>
                                    <div className="multitenancy-tab-nav">
                                        { 
                                            [["tenant", "租户"], ["platform", "平台"]].map(([mt, label]) => {
                                                return (
                                                    <div key={mt} className={(multitenantType == mt ? "active" : "")} onClick={_ => {
                                                        if(multitenantType !== mt) {
                                                            setMultitenantType(mt);
                                                        }
                                                    }}>{label}</div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        ["tenant", "platform"].map(mt => (
                                            <div key={mt} className={"multitenancy-tab" + (multitenantType == mt ? " active" : "")}>
                                                { renderPanel(mt) }
                                            </div>
                                        ))
                                    }
                                </>
                            )

                        } else {
                            return renderPanel()
                        }
                    }

                    return (
                        <div key={name} className={"explorer-body" + (isActive ? " active" : "")}>
                            { renderPanelForMT() }
                        </div>
                    )
                })}
            </div>

            <DeletionDialog {...{ facade, itemToDelete, setItemToDelete, closeItem, reloadList }} />
            { addItemConfig ? <AddItemDialog {...{ facade, addItemConfig, setAddItemConfig, openItem, reloadList, multitenantType, domainSettings }} /> : null }

            <AddDependencyDialog {...{ 
                config: addDependencyConfig, setConfig: setAddDependencyConfig, 
                facade, reloadList 
            }} />
        </div>
    )
}


// 

