
import React from 'react'

import {getSelectedInlineStyles} from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

import {STYLE_CONFIG, resolveStyles} from './inlineStyleHelpers'
import {scriptStyle} from './styles'

export default (config = {}) => {
  return {
    customStyleFn: (styles, block) => {
      return resolveStyles(styles)
    },
    customStyleMap: {
      'SUPERSCRIPT': {
        verticalAlign: 'super',
        ...scriptStyle
      },
      'SUBSCRIPT': {
        verticalAlign: 'sub',
        ...scriptStyle
      }
    }
  };
};


/// helper functions:
