

import React from 'react'

import range from 'lodash/range';

import { Handle, Position } from 'react-flow-renderer';

import { BsPlay } from 'react-icons/bs';

const ExprNode = React.forwardRef((props, ref) => {
    const { data } = props;
    const { id, label, node_type, input_num, nodes, edges, setting = {}, inputNodeSettings = {} } = data;

    const { pipeToStyle } = setting;

    function renderTargetHandle(index) {

        // 找到指向当前 handle 的 edge，进而找到源头的 node；

        const edge = edges.find(e => e.target_id === id && e.target_handle_idx === index);

        const sourceNode = edge ? nodes.find(n => n.id === edge.source_id) : undefined;

        const typeCategory = sourceNode.value_type_category;
        const className = typeCategory ? typeCategory.toLowerCase() : "";

        const sourceNodeSetting = inputNodeSettings[sourceNode.id];

        if (sourceNodeSetting && sourceNodeSetting.collapsed) {
            return (
                <Handle className={className + " collapsed"} key={"" + index} type="target" id={"" + index} isConnectable={false}>
                    {sourceNode && sourceNode.label ? <div>{sourceNode.label}</div> : null}
                </Handle>
            )

        } else {
            return (
                <Handle className={className} key={"" + index} type="target" id={"" + index} isConnectable={false}>
                    {sourceNode && sourceNode.name ? <div>{sourceNode.name}</div> : null}
                </Handle>
            )
        }


    }

    const nodeRenferers = {
        "binop": () => {
            return (
                <>
                    <div className="node-content">
                        {renderTargetHandle(0)}
                        <div className="label-box">{label}</div>
                        {renderTargetHandle(1)}
                    </div>
                    <Handle type="source" position={Position.Bottom}
                    />
                </>
            )
        },

        "output": () => {

            const edge = edges.find(e => e.target_id === id);
            const sourceNode = edge ? nodes.find(n => n.id === edge.source_id) : undefined;
            const typeCategory = sourceNode.value_type_category;
            const className = typeCategory ? typeCategory.toLowerCase() : "";

            return (
                <div className="node-content">
                    <Handle type="target" className={className} isConnectable={false} />
                </div>
            )
        },

        "if": () => {
            return (
                <>
                    <div className="node-content">
                        <div className="label-box">if</div>
                        {renderTargetHandle(0)}
                        <div className="label-box">then</div>
                        {renderTargetHandle(1)}
                        <div className="label-box">else</div>
                        {renderTargetHandle(2)}
                    </div>
                    <Handle type="source" position={Position.Bottom} />
                </>
            )
        },

        "record_get": () => {
            return (
                <>
                    <div className="node-content">
                        {renderTargetHandle(0)}                        
                        <div style={{ marginLeft: -4}}>{"." + label}</div>
                    </div>
                    <Handle type="source" position={Position.Bottom} />
                </>
            )
        },

        "apply_on_name": () => {
            if (pipeToStyle) {
                // TODO 假定肯定有 1 个以上：
                const lastIndex = input_num - 1;
                return (
                    <>
                        <div className="node-content">
                            {renderTargetHandle(lastIndex)}
                            <div style={{ display: "flex", alignItems: "center", marginRight: "0.125rem", marginLeft: "-0.25rem" }}>
                                <BsPlay />
                            </div>
                            {label ? <div className="label-box">{label}</div> : null}
                            {range(0, lastIndex, 1).map(i => renderTargetHandle(i))}
                        </div>
                        <Handle type="source" position={Position.Bottom} />
                    </>
                )
            } else {
                return defaultRenderer();
            }
        }

    }

    const defaultRenderer = () => {
        return (
            <>
                <div className="node-content">
                    {label ? <div className="label-box">{label}</div> : null}
                    {range(0, input_num, 1).map(i => renderTargetHandle(i))}
                </div>
                <Handle type="source" position={Position.Bottom} />
            </>
        )
    }

    const typeCategory = data.value_type_category;
    const typeClassName = typeCategory ? " " + typeCategory.toLowerCase() : "";

    return (
        <div className={"expr-node node-" + node_type + typeClassName} ref={ref} id={id}>
            {(nodeRenferers[node_type] || defaultRenderer)()}
        </div>
    )
})

export default React.memo(ExprNode);