
import React from 'react';

import logout from 'Client/js/logout'

import './UserBox.less'

import OperationButtonBox from './OperationButtonBox';

import Avatar from 'bwax-ui/components/Avatar';

export default function UserBox({ currentUser, dlc }) {

    if (!currentUser) {
        return null
    }

    const panel = (
        <div className="top-bar-user-panel no-select">
            <div className="user-info">
                {/* <div className="user-avatar" style={{
                    backgroundImage: `url(${getImageURL(currentUser.avatar, "thumbnail")})`,
                    height: "3rem",
                    width: "3rem",
                    minWidth: "3rem",
                    marginRight: "1rem"
                }}>
                </div> */}
                <Avatar avatar={currentUser.avatar} nickName={currentUser.nickName} size={48} style={{ marginRight: "1rem" }} />
                <div className="user-details">
                    <div className="user-name">
                        {currentUser.nickName}
                    </div>
                    <div className="user-tags">
                        {
                            (currentUser.systemRoles || []).map(name => (
                                <div key={name} className="info-tag">
                                    {name}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="user-operations">
                <div className="user-operation-button" onClick={_ => {
                    logout(dlc)
                }}>
                    登出
                </div>
            </div>
        </div>
    )


    return (
        <OperationButtonBox
            className="admin-user-box"
            buttonContent={
               <Avatar avatar={currentUser.avatar} nickName={currentUser.nickName} size={24} />
            }
            panel={panel}
        />
    )
}
