

import React, { useEffect, useState, useContext } from 'react'
import { Input, Button, Row } from 'antd'

import Message from 'Client/js/ui/Message';

import { Icon } from '@ant-design/compatible';

import get from 'lodash/get'
import omit from 'lodash/omit'

import InputSelect from 'Client/js/ui/components/InputSelect';
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery';
import Page from "bwax-ui/layout/Page"
import BreadcrumbSimple from 'Client/js/breadcrumb/BreadcrumbSimple';

import './ReplyEditPage.less'

import CommonReplyEdit from './CommonReplyEdit'

export default function ReplyEditPage (props) {

    const { queryTarget, history, extensions } = props
    const isYQYLEnabled = (extensions || []).some(e => e.key == "YQYL");

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)
    const queryRunner = {
        'data': runDataQuery({tenantCode, sessionToken, sandbox}),
        'definition': runDefinitionQuery({tenantCode, sessionToken})
    }[queryTarget]

    const replyId = get(props, 'match_.params.id', undefined)

    const [ currentReplySetting, setCurrentReplySetting ] = useState({
        keywordPatterns: [{
            keyword: undefined,
            pattern: 'MatchPart'
        }],
        replyType: 'ReplyAll',
        transferCustomerService: false

    })
    const [ publishFault, setPublishFault ] = useState({})
    const [ contentType, setContentType ] = useState('message')

    useEffect(() => {
        (async () => {
            if(replyId) {
                const condition = [{
                    field: "id",
                    op: "eq",
                    value: replyId
                }]

                const listReplySetting = await loadListReplySetting(queryRunner, condition, isYQYLEnabled)
                const replySetting = listReplySetting.edges.find(r => r.node.id === replyId)
                const { replies } = replySetting.node
                setCurrentReplySetting(replySetting.node)
                setContentType(replies && replies.some(r => r.type === 'yqylPromotion') ? 'yqylPromotion' : 'message')
            }
        })()
    }, [])

    function addContent (type) {
        const replies = currentReplySetting ? currentReplySetting.replies : []
        const addContent = isYQYLEnabled ? {
            type,
            text: null,
            mpImage: null,
            mpNews: null,
            yqylPromotion: null
        } : {
            type,
            text: null,
            mpImage: null,
            mpNews: null
        }
        setCurrentReplySetting({
            ...currentReplySetting,
            replies: (replies || []).concat(addContent)
        })
    }

    function updateReplySetting(value) {
        setCurrentReplySetting({
            ...currentReplySetting,
            ...value
        })
    }

    function save () {
        const name = currentReplySetting && currentReplySetting.name
        const keywordPatterns = currentReplySetting ? currentReplySetting.keywordPatterns : []
        const replies = currentReplySetting ? currentReplySetting.replies : []
        const transferCustomerService = currentReplySetting && currentReplySetting.transferCustomerService

        if(!name || name.length > 60) {
            setPublishFault({
                ...publishFault,
                'name': '规则名不能为空且最多60个字',
            })
        } else if (keywordPatterns.length === 0 || keywordPatterns.find(({ keyword, pattern }) => !pattern || !keyword || keyword.length > 30)) {
            setPublishFault({
                ...publishFault,
                'keyword': '匹配方式，关键词不能为空且最多30个字',
            })
        } else if (!replies || (!transferCustomerService && replies.length === 0) || (replies.length > 0 && replies.some(r => !r[r.type]))) {
            const TYPE = {
                'text': 'message',
                'mpImage': 'message',
                'mpNews': 'message',
                'yqylPromotion': 'yqylPromotion'
            }
            setContentType(
                !replies || replies.length === 0 ? contentType : 
                replies.some(r => !r[r.type]) ? TYPE[replies.find(r => !r[r.type]).type] : contentType
            )
            setPublishFault({
                ...publishFault,
                'replies': true
            })
        } else {
            (async () => {
                const { replies } = currentReplySetting
                const convertReplies = (replies || []).map(reply => {
                    return isYQYLEnabled ? {
                        ...reply,
                        yqylPromotion: reply.type === 'yqylPromotion' && reply.yqylPromotion ? reply.yqylPromotion.id : null
                    } : {
                        ...reply
                    }
                })
                const convertReplySetting = {
                    ...currentReplySetting,
                    replies: convertReplies
                }
                const result = currentReplySetting.id ? 
                    await updateWxMpEntityKeywordReplySetting(queryRunner, convertReplySetting, isYQYLEnabled) : 
                    await addWxMpEntityKeywordReplySetting(queryRunner, convertReplySetting, isYQYLEnabled)
    
                if(result) {
                    Message.success('保存成功')
                    localStorage.setItem('operation', 'add')
                    history.goBack()
                }
            })()
        }
    }

    function addReply (type) {
        setPublishFault({
            ...publishFault,
            'replies': false
        })
        addContent(type)
    }

    let { keywordPatterns } = currentReplySetting

    function addKeyword () {
        setCurrentReplySetting({
            ...currentReplySetting,
            keywordPatterns: keywordPatterns.concat({
                keyword: undefined,
                pattern: 'MatchPart'
            })
        })
        setPublishFault({
            ...publishFault,
            'keyword': undefined
        })
    }

    function deleteKeyword (idx) {
        keywordPatterns.splice(idx, 1)
        setCurrentReplySetting({
            ...currentReplySetting,
            keywordPatterns
        })
        setPublishFault({
            ...publishFault,
            'keyword': undefined
        })
    }

    function updateKeywordPatterns (idx, value) {
        keywordPatterns.splice(idx, 1, {
            ...keywordPatterns[idx],
            ...value
        })
        setCurrentReplySetting({
            ...currentReplySetting,
            keywordPatterns
        })
    }

    function changeContentType (value) {
        setContentType(value)
        setPublishFault({
            ...publishFault,
            'replies': false
        })
    }

    function renderKeywordRow (idx = 0, pattern = 'MatchPart', keyword = '') {
        const selectErrorStyle = !!publishFault['keyword'] && (!pattern || !keyword || keyword.length > 30) ? {
            border: "1px solid #f00"
        } : {}
        return (
            <Row key={idx} type="flex">
                {
                    idx === 0 ? <label>关键词</label> : null
                }
                <div>
                    <div className="input">
                        <InputSelect
                            // className={`keyword-select ${!!publishFault['keyword'] && (!keyword || keyword.length > 30) ? 'error' : ''}`}
                            style={{ width: "7.5rem", ...selectErrorStyle }}
                            value={pattern} 
                            onChange={value => updateKeywordPatterns(idx, { pattern: value })}
                            options={[{ label: "半匹配", value: "MatchPart" }, { label: "全匹配", value: "MatchAll" }]}
                        />
                        <Input 
                            style={{ 
                                width: 380, 
                                border: `1px solid ${!!publishFault['keyword'] && (!keyword || keyword.length > 30) ? '#f00' : '#d9d9d9'}` 
                            }}
                            value={keyword}
                            onChange={e => {
                                setPublishFault({ ...publishFault, 'keyword': null })
                                updateKeywordPatterns(idx, { keyword: e.target.value })
                            }}
                        />
                        {
                            idx === keywordPatterns.length - 1 && idx < 9 ? (
                                <span onClick={() => addKeyword()}>
                                    <Icon type="plus" />
                                </span>
                                
                            ) : null
                        }
                        {
                            idx > 0 ? (
                                <span onClick={() => deleteKeyword(idx)}>
                                    <Icon type="minus" />
                                </span>
                            ) : null
                        }
                    </div>
                    <div className="publish-fault">
                        {!!publishFault['keyword'] && (!pattern || !keyword || keyword.length > 30) ? publishFault['keyword'] : null}
                    </div>
                </div>
            </Row>
        )
    }

    return (
        <Page>
            <div className="wxmp-container">
                <div className="wxmp-breadcrumb-container">
                    <BreadcrumbSimple {...{
                        ...props,
                        pathname: props.location.pathname
                    }} />
                </div>
                <div className="reply-edit-page">
                    <div className="top">
                        <span>自动回复</span>
                        <span>通过编辑内容或关键词规则，快速进行自动回复设置。</span>
                    </div>
                    <Row type="flex" >
                        <label>规则名称</label>
                        <div>
                            <Input 
                                style={{ width: 500, border: `1px solid ${!!publishFault['name'] ? '#f00' : '#d9d9d9'}` }} 
                                value={currentReplySetting && currentReplySetting.name}
                                onChange={e => {
                                    setPublishFault({ ...publishFault, 'name': null })
                                    updateReplySetting({ name: e.target.value })
                                }}
                            />
                            <div className="publish-fault">{publishFault['name']}</div>
                        </div>
                    </Row>
                    <div className="keyword-pattern">
                    {
                        !keywordPatterns || keywordPatterns.length === 0 ? (
                            renderKeywordRow()
                        ) : (
                            keywordPatterns.map(({pattern, keyword}, idx) => renderKeywordRow(idx, pattern, keyword))
                        )
                    }
                    </div>
                    <CommonReplyEdit
                        updateReplySetting={value => updateReplySetting(value)}
                        currentReplySetting={currentReplySetting}
                        showYqyl={isYQYLEnabled}
                        contentType={contentType}
                        changeContentType={value => changeContentType(value)}
                        setPublishFault={value => setPublishFault(value)}
                        queryRunner={queryRunner}
                        publishFault={publishFault}
                        addReply={type => addReply(type)}
                        widgetProps={props}
                    />
                    <Row type="flex" justify="center" className="buttons">
                        <Button type="primary" onClick={() => save()}>保存</Button>
                        <Button onClick={() => history.goBack()}>取消</Button>
                    </Row>
                </div>
            </div>
        </Page>
    )
}

async function updateWxMpEntityKeywordReplySetting(queryRunner, replySetting, isYQYLEnabled) {
    const resp = await queryRunner(MutUpdateWxMpEntityKeywordReplySetting(isYQYLEnabled))({
        input: {
            id: replySetting.id,
            values: {
                ...omit(replySetting, ['id', 'key'])
            },
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data.updateWxMpEntityKeywordReplySetting
    }
}


async function addWxMpEntityKeywordReplySetting(queryRunner, replySetting, isYQYLEnabled) {
    const resp = await queryRunner(MutAddWxMpEntityKeywordReplySetting(isYQYLEnabled))({
        input: {
            ...replySetting,
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.log(errors)
        return undefined
    } else {
        return data.addWxMpEntityKeywordReplySetting
    }
}

async function loadListReplySetting(queryRunner, condition, isYQYLEnabled) {
    const resp = await queryRunner(QueryReplySetting(isYQYLEnabled))({
        condition
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityKeywordReplySetting
    }
}

const FragmentReplySetting = (isYQYLEnabled) => `
    id
    name
    keywordPatterns {
        keyword
        pattern
    }
    priority
    replies {
        type
        text
        mpImage
        mpNews
        ${
            isYQYLEnabled ? `
                yqylPromotion {
                    id
                }
            ` : ""
        }
    }
    replyType
    transferCustomerService
`

const QueryReplySetting = isYQYLEnabled => `
    query (
        $condition: [[ListWxMpEntityKeywordReplySettingConditionInput]]
    ) {
        listWxMpEntityKeywordReplySetting(condition: $condition) {
            edges {
                node {
                    ${FragmentReplySetting(isYQYLEnabled)}
                }
            }
        }
    }
`

const MutUpdateWxMpEntityKeywordReplySetting = isYQYLEnabled => `
    mutation UpdateWxMpEntityKeywordReplySettingMutation(
        $input: UpdateWxMpEntityKeywordReplySettingInput!
    ) {
        updateWxMpEntityKeywordReplySetting(input: $input) {
            changedWxMpEntityKeywordReplySetting {
                ${FragmentReplySetting(isYQYLEnabled)}
            }
        }
    }
`

const MutAddWxMpEntityKeywordReplySetting = isYQYLEnabled =>`
    mutation AddWxMpEntityKeywordReplySettingMutation(
        $input: AddWxMpEntityKeywordReplySettingInput!
    ) {
        addWxMpEntityKeywordReplySetting(input: $input) {
            addedWxMpEntityKeywordReplySettingEdge {
                node {
                    ${FragmentReplySetting(isYQYLEnabled)}
                }
            }
        }
    }
`
