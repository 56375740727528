
import React, { useRef, useEffect, useState } from 'react'

import { EditorState } from "@codemirror/state";
import { EditorView, dropCursor } from "@codemirror/view";

import lang_parser_lezer from 'bwax/ml/lang/lang_parser_lezer.bs';

import { parser } from 'bwax/lang/ore.parser';

import './CodeMirrorEditor.less';

import lang_entry from 'bwax/ml/lang/lang_entry.bs'
import lang_entry_base from 'bwax/ml/lang/lang_entry_base.bs'

import setupCodeEditor from './setupCodeEditor';


/**
 * 给定一个 expr，和用它补充成一个完成代码的 prefix / suffix
 * 补充完整后再用于 parse 和 typing 
 * 但提示错误的地方，需要调整对应的位置
 */

export default function PartialDefCodeEditor(props) {

    const {
        sourceCode,
        
        prefix,
        suffix,

        syntaxTop = "SingleExpr",

        typingEnv, // [ tenv, dts, entity_dict, data_type_dict ]
        onChange, 
        onError, 
        expectedType, // it is the lang term option

        bindSetter, 
    } = props;


    const editorRef = useRef();
    const viewRef = useRef();

    // testing = true;
    const dropAtRef = useRef()

        // 在 linter 里面访问：
    const typingEnvRef = useRef();
    typingEnvRef.current = typingEnv;

    const expectedTypeRef = useRef();
    expectedTypeRef.current = expectedType;

    function updateCodeText(codeText) {
        if (viewRef.current && viewRef.current.state.doc) {
            const currentDoc = viewRef.current.state.doc;
            const docText = currentDoc.toJSON().join("\n");
            if (docText != codeText) {
                viewRef.current.dispatch({
                    changes: {
                        from: 0,
                        to: currentDoc.length,
                        insert: codeText,
                    }
                })
            }
        }
    }
    if(bindSetter){
        bindSetter(updateCodeText)
    }

    // useEffect(() => {
    //     console.log(">>> source code changed", sourceCode);
    //     updateCodeText(sourceCode)
    // }, [sourceCode])

    useEffect(() => {

        const [dropCursorPos, drawDropCursor] = dropCursor();

        const afterLinter = (codeText, tree) => {

            const typingEnv = typingEnvRef.current;
            const expectedType = expectedTypeRef.current;

            function markError([message, startIndex, endIndex]) {

                // 统一往后移 prefix.length
                const startShifted = startIndex - prefix.length;
                const endShifted = endIndex - prefix.length;

                // 如果 start < 0 要变成 0； 如果 end > codeText.length 要变成 codeText.length
                const start = startShifted < 0 ? 0 : startShifted;
                const end = endShifted > codeText.length ? codeText.length : endShifted;

                // 常规处理
                const [from, to] = (() => {
                    if (start == end) {
                        if (start > 0) {
                            return [start - 1, end]
                        } else if (end < codeText.length) {
                            return [start, end + 1]
                        }
                    }
                    return [start, end]
                })();
                return [{ message, from, to, severity: "error" }]
            }

            const defs = prefix + codeText + suffix;

            // 1. parse and type
            const parseTree = parser.parse(defs);
    
            const [result, parseError] = lang_parser_lezer.convert_defs_entry(defs, parseTree);
            // const [ast, _] = result;
            
            // const [result, parseError] = lang_parser_lezer.convert_expr_entry(codeText, tree);

            // 2. type the expr ast;
            if (parseError) {

                // 如果 code text 是空字符串，则不返回错误
                if(!codeText || codeText.trim().length === 0) {
                    onChange(undefined, "");
                    return []
                }

                return markError(parseError);
            } else if (typingEnv) {

                const [base_tenv, base_dts, entity_dict, data_type_dict] = typingEnv;

                const [raw_ast, _] = result;

                const [_typed_ast, slim_ast, tyenv, dts, _external_names_with_types, error] =
                    lang_entry.type_defs_to_js(base_dts, base_tenv, raw_ast);

                if (error) {
                    onError(error[0], codeText);
                    return markError(error);
                } else {
                    
                   // 3. pass by the ast and the code;
                    onChange(slim_ast, codeText, { 
                      resultedTypingEnv: [
                        lang_entry_base.merge_env(base_tenv, tyenv),
                        lang_entry_base.merge_dts(base_dts, dts),                           
                        entity_dict,
                        data_type_dict,
                    ]  
                    });
                    return [];
                }
            } else {
                return [];
            };

        };

        const state = EditorState.create({
            // doc: value || storedItem || "",
            // doc: storedItem || "",
            doc: sourceCode || "",
            extensions: [
                ...setupCodeEditor({ syntaxTop, afterLinter, compact: true }),

                [dropCursorPos, drawDropCursor],

                EditorView.updateListener.of(update => {
                    if (update.state.field(dropCursorPos) !== null) {
                        dropAtRef.current = view.state.field(dropCursorPos);
                    }

                }),
            ]
        });

        const view = new EditorView({ state, parent: editorRef.current });
        viewRef.current = view;

        return () => {
            view.destroy();
            // editorRef.current.removeEventListener("input", log);
        };
    }, []);



    return (
        <div className="code-mirror-editor" ref={editorRef}></div>
    );

}





