import React, { useEffect, useState } from 'react'

import { SketchPicker, GithubPicker } from 'react-color';

import lightOrDark from 'bwax-ui/color/lightOrDark';

import { MdDelete } from 'react-icons/md';

import { Popover, Button, Space } from '@arco-design/web-react';

import './ColorInput.less';

export default function ColorInput(props) {
    const { value, onChange } = props

    const [editing, setEditing] = useState(value ? { hex: value } : undefined);
    const noValue = value === undefined || value === null;

    useEffect(() => {
        // 如果 value 不等于 editing 的值，则重新设置 editing
        if ((value && !editing) || (editing && value !== editing.hex)) {
            setEditing(value ? { hex: value } : undefined)
        }

    }, [value])

    const pickerProps = {
        color: editing,

        onChangeComplete: c => {
            setEditing(c)
            onChange(c.hex)
        },

        styles: {
            picker: {
                boxShadow: "none"
            }
        }
    }

    const picker = (
        <SketchPicker {...pickerProps} />
    )

    function renderColor() {
        const color = value;
        const placeholderText = "选择颜色";
        return (
            <div className="color-content" style={{
                backgroundColor: color ? color : "#F0F3F5",
            }}>
                {
                    color ? (
                        <div className="color-value" style={lightOrDark(color) == "light" ? {} : { color: "white " }}>
                            {color}
                        </div>
                    ) : placeholderText
                }
            </div>
        )
    };

    return (
        <div className="lc-color-input">
            <Popover content={picker} trigger='click'>
                {renderColor()}
            </Popover>
            {
                noValue ? null : (
                    <div className="remove-icon" onClick={_ => {
                        // set null
                        onChange(undefined)
                    }}>
                        <MdDelete />
                    </div>
                )
            }
        </div>
    )

}
