
const dateFormat = require('dateformat')
export default {
    DateFormat: (value, { format }) => {
        return dateFormat(value, format)
    },

    Slice: (value, { beginIndex, endIndex}) => {
        if(!value || typeof(value.slice) !== 'function') {
            return value || ""
        } 
        return value.slice(beginIndex || 0, endIndex || value.length)
    },

    Dict: (value, { dict }) => {
        return dict[value]
    }


}
