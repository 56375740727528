
/* eslint-disable react/no-children-prop */
import React, { Component } from 'react';
import { RichUtils } from 'draft-js';

export default ({ blockType, children }) => (
  class BlockTypeButton extends Component {

    toggleType = (event) => {
      event.preventDefault();
      this.props.setEditorState(
        RichUtils.toggleBlockType(
          this.props.getEditorState(),
          blockType
        )
      );
    }

    preventBubblingUp = (event) => { event.preventDefault(); }

    blockTypeIsActive = () => {
      // if the button is rendered before the editor
      if (!this.props.getEditorState) {
        return false;
      }

      const editorState = this.props.getEditorState();

      const currentContent = editorState.getCurrentContent()
      const selectionStartKey =  editorState.getSelection().getStartKey()
      const selectedBlock = currentContent.getBlockForKey(selectionStartKey)
      const type = selectedBlock.getType()

      return type === blockType

    }

    render() {


      const { theme } = this.props;
      const className = this.blockTypeIsActive() ? " active" : ""

      return (
        <button className={"editor-button" + className} onClick={this.toggleType}
          >{children}</button>
      );
    }
  }
);
