import React, { useEffect, useState, useRef } from 'react'

import PageDesignFrame from './PageDesignFrame';

import AttributesEditor from './AttributesEditor';

import { HTML5Backend } from 'react-dnd-html5-backend'

import { DndProvider } from 'react-dnd'

import { Tabs } from '@arco-design/web-react';
const TabPane = Tabs.TabPane;

import {
    BiCategory, BiPoll,
} from 'react-icons/bi';

import { MdOutlineColorize, MdOutlineSettings, MdOutlineAdsClick } from 'react-icons/md'

import './PageDesigner.less';

/**
 * lc-page designer
 * 默认是包括所有的组件：比如左边的 Block 管理和页面结构，中间的 Canvas，右边的 Property Panel，
 * 上面的 Toolbar：包括 Viewport 选择，redo，undo
 */

import BlockGallery from './BlockGallery';

import builtinCommands from './commands';
import OutlineView from './OutlineView';

import PageCanvas from './PageCanvas';
import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

export default function PageDesigner({
    blockTypes, value, onChange, saveValue,
    commonAttributes = { style: {}, settings: {}, interaction: {} },
    extendedOptions = {},
}) {

    // here we manage the model, and all the commands:
    // commands: 
    //  1) insertBlock, 2) moveBlock  4) selectBlock; 3)
    //  ....
    // 

    const commands = builtinCommands;

    // { contentDict: <id> -> <block>, content: <tree>, selected: <id> }

    // a block: { id: string, type: string, attributes: {}, childIds: [[string]] or [string] }

    // { model: <model>, commandHistory: <commandHistory> }
    // command stack
    // { lastCommands: [ [ { name, params}, <editing model before the command> ] ]
    // , undoneCommands: [ [ { name, params}, <editing model after the command ]]
    // }
    const [editingState, setEditingState] = useState(
        _ => ({ model: value.model, commandHistory: { lastCommands: [], undoneCommands: [] } })
    )

    const editingStateRef = useRef();
    editingStateRef.current = editingState;

    // 
    const [operatingState, setOperatingState] = useState({
        beingDragged: null,
        dropTarget: {}, // target, index
        hovered: null, // id
    });

    useEffect(() => {
        onChange({ model: editingState.model });
    }, [editingState.model])

    // pending command should be stacked and executed in sequence.

    function executeCommand(name, params) {

        const f = commands[name]
        if (f) {
            setEditingState(prevState => {
                const newModel = f(prevState.model, params, { blockTypes })

                // no matter if editing model was changed.
                const newHistory = {
                    undoneCommands: [], // clear the forward commands
                    lastCommands: [
                        [{ name, params }, prevState.model], // model before the command
                        ...prevState.commandHistory.lastCommands
                    ]
                }

                if (newModel !== prevState.model) {
                    // check identity equality only.
                    // do some clearings
                    function fix(model) {
                        if (model.selected && !model.contentDict[model.selected]) {
                            return { ...model, selected: null }
                        } else {
                            return model
                        }
                    }
                    return { model: fix(newModel), commandHistory: newHistory }
                } else {
                    return { model: newModel, commandHistory: newHistory }
                }
            })

        }
    }

    // a collection of helper function
    const editor = {
        canUndo: () => {
            return editingStateRef.current.commandHistory.lastCommands.length > 0
        },
        canRedo: () => {
            return editingStateRef.current.commandHistory.undoneCommands.length > 0
        },
        undo: () => {
            // 1. find the last one, use its' old editing model
            // 2. set the command to the undoneCommands with the current model
            setEditingState(prevState => {
                const { lastCommands, undoneCommands } = prevState.commandHistory;
                const currentModel = prevState.model;
                if (lastCommands.length > 0) {
                    const [[lastCommand, prevModel], ...remaining] = lastCommands
                    return {
                        model: prevModel,
                        commandHistory: {
                            lastCommands: remaining,
                            undoneCommands: [
                                [lastCommand, currentModel], // model after the command
                                ...undoneCommands,
                            ]
                        }
                    }
                }
            })

        },
        redo: () => {
            /// 
            setEditingState(prevState => {
                const { lastCommands, undoneCommands } = prevState.commandHistory;
                const currentModel = prevState.model;
                if (undoneCommands.length > 0) {
                    const [[nextCommand, nextModel], ...remaining] = undoneCommands;

                    return {
                        model: nextModel,
                        commandHistory: {
                            lastCommands: [
                                [nextCommand, currentModel], // model before the command
                                ...lastCommands,
                            ],
                            undoneCommands: remaining
                        }
                    }
                }

            });
        },
        executeCommand
    }

    // 
    function dropBlock(droppedItem, dropTarget) {
        const model = editingStateRef.current.model;
        const { isNew, id, blockType } = droppedItem;

        if (!dropTarget) {
            if (Object.values(model.contentDict).length === 0) {
                executeCommand("createFirstBlock", {
                    blockTypeName: blockType.name
                });
            }
        } else if (dropTarget.parent) {
            if (isNew) {
                // create a new block, and place it to the corresponding position
                executeCommand("insertBlock", {
                    blockTypeName: blockType.name,
                    targetParent: dropTarget.parent,
                    targetIndex: dropTarget.index,
                    slotIndex: dropTarget.slotIndex,
                });

            } else if (id) {
                executeCommand("moveBlock", {
                    blockId: id,
                    targetParent: dropTarget.parent,
                    targetIndex: dropTarget.index,
                    slotIndex: dropTarget.slotIndex,
                })

            } else {
                // do nothing
            }
        }
    }

    const selectedBlock = editingState.model.selected && editingState.model.contentDict[editingState.model.selected];

    const getBlockNodeRef = useRef();

    const [activePropertyTab, setActivePropertyTab] = useStateWithLocalCache("page-designer-active-property-tab")
    const [activeResourceTab, setActiveResourceTab] = useStateWithLocalCache("page-designer-active-resource-tab")

    const [justClickedToResourceTab, setJustClickedToResourceTab] = useState(false);

    useEffect(() => {
        if(editingState.model.selected) {
            // 选中了
            setJustClickedToResourceTab(false);
        }
    }, [editingState.model.selected])

    function renderPropertyPanel() {
        if (operatingState.beingDragged) {
            return (
                <div className="property-panel">
                    <OutlineView
                        model={editingState.model}
                        selected={editingState.model.selected}

                        operatingState={operatingState}
                        setOperatingState={setOperatingState}

                        blockTypes={blockTypes}

                        onSelect={selected => {
                            executeCommand("selectBlock", { blockId: selected })
                        }}
                        dropBlock={dropBlock}
                    />
                </div>
            )
        }

        function renderAttributeEditorPane(key, title, attributes, EditForm) {
            if (!attributes) {
                return null
            }

            const value = (selectedBlock && selectedBlock.attributes) || {};
            const onChange = attributes => {
                if (selectedBlock) {
                    executeCommand(
                        "updateBlockAttributes",
                        { blockId: selectedBlock.id, attributes }
                    )
                }
            }

            return (
                <TabPane title={title} disabled={!selectedBlock} 
                    key={key} style={EditForm ? {} : { padding: "0.5rem" }}>
                    {selectedBlock ? (EditForm ?
                        <EditForm
                            value={value} onChange={onChange} key={selectedBlock.id}
                            blockNode={getBlockNodeRef.current ? getBlockNodeRef.current(selectedBlock.id) : null}
                        /> :
                        <AttributesEditor
                            attributes={attributes}
                            value={value}
                            onChange={onChange}
                            blockId={selectedBlock.id}
                            key={selectedBlock.id}
                            blockNode={getBlockNodeRef.current ? getBlockNodeRef.current(selectedBlock.id) : null}

                            extendedOptions={extendedOptions}
                        />) : null
                    }
                </TabPane>
            )
        }

        const { attributes = {}, attributeGroups = {}, attributeForms = {} } = selectedBlock ? selectedBlock.blockType : {};

        const groups = [
            ["settings", <MdOutlineSettings />, attributes], // 参数
            ["style", <MdOutlineColorize />, {}],  // 样式
            ["interaction", <MdOutlineAdsClick />, {}]
        ]

        function renderIcon (icon) {
            return <div style={{ padding: "0.25rem 0.25rem", display: "flex", fontSize: 12 }}>{icon}</div>
        }

        // style, settings, interaction
        return (
            <div className="property-panel">
                <Tabs size="mini" style={{ height: "100%", overflow: "auto" }}
                    // defaultActiveTab='outline-view'
                    activeTab={ (selectedBlock && !justClickedToResourceTab) ? activePropertyTab : activeResourceTab }
                    onChange={ p => {
                        if(groups.some(g => g[0] == p)) {
                            setJustClickedToResourceTab(false)
                            setActivePropertyTab(p)                            
                        } else {
                            setJustClickedToResourceTab(true)
                            setActiveResourceTab(p)
                        }
                    }}
                >
                    <TabPane title={renderIcon(<BiCategory />)} key={"block-gallery"} style={{ padding: "0rem" }}>
                        <BlockGallery blockTypes={blockTypes} setOperatingState={setOperatingState} />
                    </TabPane>
                    <TabPane title={renderIcon(<BiPoll />)} key={"outline-view"} style={{ padding: "0rem" }}>
                        <OutlineView
                            model={editingState.model}
                            selected={editingState.model.selected}

                            operatingState={operatingState}
                            setOperatingState={setOperatingState}

                            onSelect={selected => {
                                executeCommand("selectBlock", { blockId: selected })
                            }}

                            blockTypes={blockTypes}
                            dropBlock={dropBlock}
                        />
                    </TabPane>
                    {groups.map(([key, icon, additionalAttrs]) => {
                        return renderAttributeEditorPane(
                            key,
                            renderIcon(icon),
                            { ...(commonAttributes[key] || {}), ...(attributeGroups[key] || {}), ...additionalAttrs },
                            attributeForms[key]
                        )
                    })}
                </Tabs>
            </div>
        )

    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="lc-page-designer">
               
                <div className="design-panel">
                    <PageDesignFrame
                        editor={editor}
                        renderCanvas={({ doc, outlineShown }) => {
                            return (
                                <PageCanvas
                                    model={editingState.model}
                                    blockTypes={blockTypes}
                                    saveValue={saveValue}
                                    doc={doc}
                                    editor={editor}
                                    bindBlockNodeGetter={getBlockNode => getBlockNodeRef.current = getBlockNode}
                                    outlineShown={outlineShown}
                                    operatingState={operatingState}
                                    setOperatingState={setOperatingState}
                                    dropBlock={dropBlock}
                                />
                            )
                        }}

                    />
                </div>
                {renderPropertyPanel()}
            </div>
        </DndProvider>
    )
}

