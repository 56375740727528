import React from 'react'

import { Tag as AntdTag } from 'antd'

import Tooltip from 'bwax-ui/components/Tooltip';

export default class Tag extends React.Component {

    state = {
        tagStyle: {}
    }

    setTagRef = (e) => {
        if (e && e.clientWidth > 160) {
            this.setState({
                tagStyle: {
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            })
        }
    }

    render() {
        const { style, children, ...tagProps } = this.props
        const { tagStyle } = this.state
        if(children === null || children === undefined || children === "") {
            return null
        } 
        const tagEle = (
            <AntdTag
                {...tagProps}
                style={{
                    ...tagStyle,
                    ...style
                }}>
                {children}
            </AntdTag>
        )
        return (
            <div
                ref={this.setTagRef}
                style={{ display: 'inline-block', verticalAlign: 'top' }}>
                {
                    tagStyle && tagStyle.maxWidth ?
                        <Tooltip text={children}>
                            {tagEle}
                        </Tooltip> :
                        tagEle
                }
            </div>
        )
    }
}