// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Widget_mod_ui$BwaxAdmin = require("../mod/widget/widget_mod_ui.bs.js");
var Widget_mod_html$BwaxAdmin = require("../mod/widget/widget_mod_html.bs.js");
var Widget_mod_nivo$BwaxAdmin = require("../mod/widget/widget_mod_nivo.bs.js");
var Widget_mod_color$BwaxAdmin = require("../mod/widget/widget_mod_color.bs.js");
var Widget_mod_image$BwaxAdmin = require("../mod/widget/widget_mod_image.bs.js");
var Widget_mod_input$BwaxAdmin = require("../mod/widget/widget_mod_input.bs.js");
var Widget_mod_wxpay$BwaxAdmin = require("../mod/widget/widget_mod_wxpay.bs.js");
var Widget_mod_alipay$BwaxAdmin = require("../mod/widget/widget_mod_alipay.bs.js");
var Widget_mod_wechat$BwaxAdmin = require("../mod/widget/widget_mod_wechat.bs.js");
var Widget_mod_data_ui$BwaxAdmin = require("../mod/widget/widget_mod_data_ui.bs.js");
var Widget_mod_element$BwaxAdmin = require("../mod/widget/widget_mod_element.bs.js");
var Widget_mod_misc_ui$BwaxAdmin = require("../mod/widget/widget_mod_misc_ui.bs.js");
var Widget_mod_spinner$BwaxAdmin = require("../mod/widget/widget_mod_spinner.bs.js");
var Widget_mod_adhoc_ui$BwaxAdmin = require("../mod/widget/widget_mod_adhoc_ui.bs.js");
var Widget_mod_styled_ui$BwaxAdmin = require("../mod/widget/widget_mod_styled_ui.bs.js");
var Widget_mod_admin_page$BwaxAdmin = require("../mod/widget/widget_mod_admin_page.bs.js");
var Widget_mod_local_file$BwaxAdmin = require("../mod/widget/widget_mod_local_file.bs.js");
var Widget_mod_admin_chart$BwaxAdmin = require("../mod/widget/widget_mod_admin_chart.bs.js");
var Widget_mod_html_events$BwaxAdmin = require("../mod/widget/widget_mod_html_events.bs.js");
var Widget_mod_html_attributes$BwaxAdmin = require("../mod/widget/widget_mod_html_attributes.bs.js");

var base_ui_src = "\n\n-- 只用于本地浏览器选择的文件\ntype LocalFile = opaque;\n\n\n-- Some common variants related to UI:\n\ntype Size = Thumbnail | Small | Medium | Large | XLarge | XXLarge;\n\ntype Side = Top | Right | Bottom | Left;\ntype Corner = TopLeft | TopRight | BottomRight | BottomLeft;\n\n\n\n";

var externals = /* tuple */[
  undefined,
  /* [] */0
];

function build_widget_base(param) {
  return /* tuple */[
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "(base)",
                base_ui_src
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "Element",
                    Widget_mod_element$BwaxAdmin.src
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "Input",
                        Widget_mod_input$BwaxAdmin.src
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "Image",
                            Widget_mod_image$BwaxAdmin.src
                          ],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "Color",
                                Widget_mod_color$BwaxAdmin.src
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "LocalFile",
                                    Widget_mod_local_file$BwaxAdmin.src
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "UI",
                                        Widget_mod_ui$BwaxAdmin.src(/* () */0)
                                      ],
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          /* tuple */[
                                            "WeChat",
                                            Widget_mod_wechat$BwaxAdmin.src
                                          ],
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              /* tuple */[
                                                "AliPay",
                                                Widget_mod_alipay$BwaxAdmin.src
                                              ],
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  /* tuple */[
                                                    "WxPay",
                                                    Widget_mod_wxpay$BwaxAdmin.src
                                                  ],
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      /* tuple */[
                                                        "Spinner",
                                                        Widget_mod_spinner$BwaxAdmin.src
                                                      ],
                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          /* tuple */[
                                                            "Nivo",
                                                            Widget_mod_nivo$BwaxAdmin.src
                                                          ],
                                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                              /* tuple */[
                                                                "DataUI",
                                                                Widget_mod_data_ui$BwaxAdmin.src
                                                              ],
                                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                  /* tuple */[
                                                                    "MiscUI",
                                                                    Widget_mod_misc_ui$BwaxAdmin.src
                                                                  ],
                                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                      /* tuple */[
                                                                        "StyledUI",
                                                                        Widget_mod_styled_ui$BwaxAdmin.src
                                                                      ],
                                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                          /* tuple */[
                                                                            "AdhocUI",
                                                                            Widget_mod_adhoc_ui$BwaxAdmin.src
                                                                          ],
                                                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                              /* tuple */[
                                                                                "AdminPage",
                                                                                Widget_mod_admin_page$BwaxAdmin.src
                                                                              ],
                                                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                  /* tuple */[
                                                                                    "AdminChart",
                                                                                    Widget_mod_admin_chart$BwaxAdmin.src
                                                                                  ],
                                                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                      /* tuple */[
                                                                                        "Html",
                                                                                        Widget_mod_html$BwaxAdmin.src
                                                                                      ],
                                                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                          /* tuple */[
                                                                                            "Html.Attributes",
                                                                                            Widget_mod_html_attributes$BwaxAdmin.src
                                                                                          ],
                                                                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                              /* tuple */[
                                                                                                "Html.Events",
                                                                                                Widget_mod_html_events$BwaxAdmin.src
                                                                                              ],
                                                                                              /* [] */0
                                                                                            ])
                                                                                        ])
                                                                                    ])
                                                                                ])
                                                                            ])
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              externals,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Widget_mod_element$BwaxAdmin.externals,
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Widget_mod_input$BwaxAdmin.externals,
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Widget_mod_image$BwaxAdmin.externals,
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Widget_mod_color$BwaxAdmin.externals,
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Widget_mod_local_file$BwaxAdmin.externals,
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Widget_mod_ui$BwaxAdmin.externals,
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Widget_mod_wechat$BwaxAdmin.externals,
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              Widget_mod_alipay$BwaxAdmin.externals,
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  Widget_mod_wxpay$BwaxAdmin.externals,
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      Widget_mod_spinner$BwaxAdmin.externals,
                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          Widget_mod_nivo$BwaxAdmin.externals,
                                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                              Widget_mod_data_ui$BwaxAdmin.externals,
                                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                  Widget_mod_misc_ui$BwaxAdmin.externals,
                                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                      Widget_mod_styled_ui$BwaxAdmin.externals,
                                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                          Widget_mod_adhoc_ui$BwaxAdmin.externals,
                                                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                              Widget_mod_admin_page$BwaxAdmin.externals,
                                                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                  Widget_mod_admin_chart$BwaxAdmin.externals,
                                                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                      Widget_mod_html$BwaxAdmin.externals,
                                                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                          Widget_mod_html_attributes$BwaxAdmin.externals,
                                                                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                                              Widget_mod_html_events$BwaxAdmin.externals,
                                                                                              /* [] */0
                                                                                            ])
                                                                                        ])
                                                                                    ])
                                                                                ])
                                                                            ])
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ];
}

var external_vals = /* [] */0;

exports.base_ui_src = base_ui_src;
exports.external_vals = external_vals;
exports.externals = externals;
exports.build_widget_base = build_widget_base;
/* Widget_mod_ui-BwaxAdmin Not a pure module */
