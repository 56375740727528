// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Display_JSON(Props) {
  var params = Props.params;
  var value = params.value;
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.overflowX(Css.auto),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.overflowY(Css.auto),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.maxHeight(Css.rem(15.0)),
                  /* [] */0
                ])
            ])
        ]));
  var inner = value !== null ? React.createElement("pre", undefined, JSON.stringify(value, null, 2)) : null;
  return React.createElement("div", {
              className: className
            }, inner);
}

var make = Display_JSON;

exports.make = make;
/* Css Not a pure module */
