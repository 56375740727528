
import React, { useState, useEffect } from 'react'
import { InputNumber, Select, Slider } from '@arco-design/web-react';
const Option = Select.Option;
const OptGroup = Select.OptGroup;

import { CgMathPlus } from 'react-icons/cg';

import ResetableLabel from '../components/ResetableLabel';
import ShadowList from '../components/ShadowList';
import Tooltip from 'bwax-ui/components/Tooltip';

import './Effects.less'

const cursors = [{
    category: 'General',
    keywords: ['auto', 'default', 'none']
}, {
    category: 'Links & status',
    keywords: ['context-menu', 'help', 'pointer', 'progress', 'wait']
}, {
    category: 'Selection',
    keywords: ['cell', 'crosshair', 'text', 'vertical-text']
}, {
    category: 'Drag & drop',
    keywords: ['alias', 'copy', 'move', 'no-drop', 'not-allowed', 'grab', 'grabbing']
}, {
    category: 'Resizing & scrolling',
    keywords: ['all-scroll', 'col-resize', 'row-resize', 'n-resize', 'e-resize', 's-resize', 'w-resize', 'ne-resize', 'nw-resize',
        'se-resize', 'sw-resize', 'ew-resize', 'ns-resize', 'nesw-resize', 'nwse-resize'
    ]
}, {
    category: 'Zooming',
    keywords: ['zoom-in', 'zoom-out']
}]

const defaultBoxShadow = {
    visible: true,
    color: 'black',
    angle: { value: 135, unit: 'deg' },
    distance: { value: 1, unit: 'px' },
    blur: { value: 3, unit: 'px' },
    size: { value: 0, unit: 'px' },
    inset: false
}

export default function Effects({ attributes, onChange }) {
    console.log("attributes: ", attributes);

    const [opacity, setOpacity] = useState(100)
    const [shadows, setShadows] = useState([])
    const [activeShadowIndex, setActiveShadowIndex] = useState(null)

    useEffect(() => {
        if(attributes['opacity'] || attributes['opacity'] === 0) {
            const percent = parseInt(attributes['opacity'] * 100)
            setOpacity(percent)
        } else {
            setOpacity(100)
        }
    }, [attributes])

    useEffect(() => {
        const shadowLengthRegx = "(-?\\d+(?:\\.\\d+)?)(px|em|rem|ch|vw|vh)?"
        const shadowRegx = new RegExp(`^(.*) ${shadowLengthRegx} ${shadowLengthRegx} ${shadowLengthRegx} ${shadowLengthRegx}$`)
        if(attributes['boxShadow']) {
            const shadowArr = attributes['boxShadow'].split(", ")
            const newShadows = shadowArr.map(shadow => {
                const matchedResult = shadow.match(shadowRegx)
                if(matchedResult) {
                    const offsetX = Math.ceil(matchedResult[2])
                    const offsetY = Math.ceil(matchedResult[4])
                    return {
                        visible: true,
                        color: matchedResult[1],
                        angle: { value: parseInt(180 / Math.PI * Math.atan2(offsetY, offsetX)) + 90, unit: 'deg' },
                        distance: { value: Math.ceil(Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2))), unit: 'px' },
                        blur: { value: matchedResult[6], unit: matchedResult[7] ? matchedResult[7] : 'px' },
                        size: { value: matchedResult[8], unit: matchedResult[9] ? matchedResult[9] : 'px' }
                    }
                } else {
                    return defaultBoxShadow
                }
                
            })

            setShadows(newShadows)
        }
    }, [])

    const opacityUnit = (
        <div style={{ padding: '0 4px', fontSize: 10 }}>%</div>
    )

    function updateOpacity (value) {
        if(value || value === 0) {
            onChange({ opacity: value / 100 })
        } else {
            setOpacity(parseInt(attributes['opacity'] * 100))
        }
    }

    function updateShadows (newShadows) {
        let shadowsStr = "" 
        const visibleShadows = newShadows.filter(shadow => shadow.visible)
        if(visibleShadows.length > 0) {
            shadowsStr = visibleShadows.map(shadow => {
                const { color, angle, distance, blur, size, inset } = shadow
                const { value } = angle // deg
                const radians = value * (Math.PI / 180) // convert deg to radian
                const offsetX = Math.sin(radians) * distance.value
                const offsetY = -Math.cos(radians) * distance.value
                
                return `${color} ${Math.ceil(offsetX)}px ${Math.ceil(offsetY)}px ${blur.value}${blur.unit} ${size.value}${size.unit}${inset ? ' inset' : ''}`
            }).join(', ')
        } else {
            shadowsStr = undefined
        }

        onChange({ boxShadow: shadowsStr })
    }

    function addShadow () {
        const newShadows = [defaultBoxShadow, ...shadows]
        setShadows(newShadows)
        updateShadows(newShadows)
    }

    function renderShadowInfoStr (index) {
        const activeShadow = shadows[index]
        const { distance, blur, size, inset } = activeShadow

        return `${inset ? 'Inner' : 'Outer'} shadow: ${distance.value}${distance.unit}, ${blur.value}${blur.unit}, ${size.value}${size.unit}`
    }

    function changeShadows (newShadows) {
        setShadows(newShadows)
        updateShadows(newShadows)
    }

    return (
        <div className='effects-container'>
            <div className='opacity-edit-container'>
                <ResetableLabel allAttributes={attributes} label={"Opacity"} attributesGroup={"effects"} 
                    attribute={'opacity'} onChange={onChange}
                />
                <div className='slider-input'>
                    <Slider value={parseInt(attributes['opacity'] * 100)} size="mini" onChange={value => updateOpacity(value)} style={{ margin: "0 6px" }}/>
                    <InputNumber value={opacity} size="mini" suffix={opacityUnit} style={{ width: 72, flexShrink: 0 }}
                        onChange={value => setOpacity(value)} hideControl
                        onBlur={() => updateOpacity(opacity)}
                        onKeyDown={e => {
                            if(e.code === 'Enter') {
                                updateOpacity(opacity)
                            }
                        }}
                    />
                </div>
            </div>
            <div className='cursor-edit-container'>
                <ResetableLabel allAttributes={attributes} label={"Cursor"} attributesGroup={"effects"} 
                    attribute={'cursor'} onChange={onChange}
                />
                <Select value={attributes['cursor'] || 'auto'} size={'mini'} dropdownMenuClassName="cursor-select-dropdown"
                    onChange={c => onChange({ cursor: c })}
                >
                    {cursors.map(optionGrop => {
                        return (
                            <OptGroup label={optionGrop.category} key={optionGrop.category} className="cursor-category">
                            {
                                optionGrop.keywords.map(c => (
                                    <Option key={c} value={c} style={{ cursor: c }}>
                                    {c}
                                    </Option>
                                ))
                            }
                            </OptGroup>
                        );
                    })}
                </Select>
            </div>
            <div className='boxShadow-header'>
                <ResetableLabel allAttributes={attributes} label={"Box shadows"} attributesGroup={"effects"} 
                    attribute={'boxShadow'} onChange={attributes => {
                        setShadows([])
                        onChange(attributes)
                    }}
                />
                <Tooltip text={"Add drop shadow, inner or outer glow, or highlights"}>
                    <div className={`icon-add ${activeShadowIndex || activeShadowIndex === 0 ? 'hide' : ''}`} onClick={() => addShadow()}>
                        <CgMathPlus/>
                    </div>
                </Tooltip>
            </div>
            {
                shadows.length > 0 ? (
                    <ShadowList isBoxShadow={true} shadows={shadows} activeShadowIndex={activeShadowIndex} setActiveShadowIndex={setActiveShadowIndex}
                        renderShadowInfoStr={renderShadowInfoStr}  onChange={newShadows => changeShadows(newShadows)}
                    />
                ) : null
            }
        </div>
    )
}
