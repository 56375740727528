
import React from 'react'

import { themeColor } from 'bwax-ui'

import './Text.less'

/**
 * type:    body, body-small, body-em, body-small-em, helper, caption, quote
 * maxLine:   number?
 * text:    ...
 * color:   font color
 */
export default function Text (props) {

    const { params, theme } = props
    const { text, maxLine, type = 'body', color : colorName } = params

    // TODO 考虑 theme color
    const style = colorName ? { color: themeColor(theme, colorName) } : {}
    const className = `bw-text bw-text-${type}`

    const LINE_HEIGHT = {
        'body': 1.75,
        'body-em': 1.75,
        'helper': 1.125 // 原来是 1.25 但我发现在设计稿里 helper 通常体现为高度 18px，见我的资产 -> 文档 (van)
    }

    const lineHeight = LINE_HEIGHT[type] || 1.5 // default is 1.5
    
    if(maxLine) {
        /// use line limiting

        const ellipsisStyle = {
            ...style,
            lineHeight: `${lineHeight}rem`,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: `${maxLine}`,
            overflow: 'hidden',
            height: `${maxLine * lineHeight}rem`,
        }
        
        return (
            <p 
                className={className} 
                style={ellipsisStyle}
            >
                {text}
            </p>
        )
    } else {
        return (
            <p 
                className={className} 
                style={{ ...style, lineHeight: `${lineHeight}rem` }}
            >
                { text }
            </p>
        )
    }
}

