import React, { useState } from 'react'

import { Tag, Card, Row, Input } from 'antd'

import Message from 'Client/js/ui/Message';

import { Icon } from '@ant-design/compatible';

import './Tags.less'

/// tags 
export default function Tags(props) {

    const { params, editingState, value, onChange, refetch } = props

    const { editKey, initialValue, onSubmit, editable } = params

    /// states begins
    const [isEditing, setIsEditing] = useState(false)
    /// states ends


    const { editing, dirtyValues } = editingState
    const editIsDirty = dirtyValues[editKey] !== undefined

    async function onSaveTags() {
        onSubmit()
        setIsEditing(false)
        Message.success('修改成功！')
    }

    function renderDisplayTags(value) {
        return value.map(tagValue => {
            return (
                <Tag key={tagValue}>
                    {tagValue}
                </Tag>
            )
        })
    }

    if (!editable) {
        return renderDisplayTags(value)
    }

    const tagsCotClassName = "tags-edit-card"

    return (
        <Card
            className={isEditing ? `${tagsCotClassName} editing` : tagsCotClassName}
            bordered={false}
            hoverable={true}>
            {
                isEditing ?
                    <EditTags
                        value={editing[editKey]}
                        onChange={(value) => {
                            onChange([
                                ...value
                            ]
                            )
                        }}
                    /> :
                    renderDisplayTags(initialValue)
            }
            {
                isEditing ?
                    <Row type="flex" className="editing-actions">
                        <Icon
                            className={!editIsDirty ? 'check-disabled' : ''}
                            type="check"
                            onClick={!editIsDirty ? () => { } : () => onSaveTags()} />
                        <Icon
                            type="close"
                            onClick={() => setIsEditing(false)} />
                    </Row> :
                    <Icon
                        type="edit"
                        onClick={() => {
                            setIsEditing(true)
                            onChange([
                                ...initialValue,
                                value
                            ])
                        }}
                    />
            }
        </Card>
    )
}

function EditTags(props) {

    const [isEditingTag, setIsEditingTag] = useState(false)
    const [tagInputValue, setTagInputValue] = useState(null)

    const { value, onChange } = props

    function onTagClose(tag) {
        if (onChange) {
            onChange(
                value.filter(t => t !== tag)
            )
        }
    }

    function confirmInput(inputValue) {
        if (inputValue.length > 0 && (value || []).indexOf(inputValue) === -1) {
            const newTags = [...(value || []), inputValue]
            if (onChange) {
                onChange(newTags)
            }
            setTagInputValue(null)

        }
    }

    function renderTagEditInput() {
        return (
            <Input
                autoFocus
                type="text"
                size="small"
                style={{ width: 88 }}
                value={tagInputValue}
                onChange={(e) => setTagInputValue(e.target.value)}
                onBlur={() => setIsEditingTag(false)}
                onPressEnter={() => confirmInput(tagInputValue)}
            />
        )

    }

    const showPlusIcon = !isEditingTag

    return (
        <React.Fragment>
            {
                (value || []).map((tagValue) => {
                    return (
                        <Tag
                            key={tagValue}
                            closable={true}
                            onClose={() => onTagClose(tagValue)}
                        >
                            {tagValue}
                        </Tag>
                    )
                })
            }
            {isEditingTag ? renderTagEditInput() : null}
            {
                showPlusIcon ?
                    <Icon
                        type="plus"
                        onClick={() => setIsEditingTag(true)}
                    /> : null
            }
        </React.Fragment>
    )
}
