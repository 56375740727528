
import React from 'react';


import BasicEditor from './BasicEditor'


export default class SimpleEditor extends React.Component  {


  render () {
    const {editorState, inlineToolbarConfig, style, className, onChange} = this.props

    return (
      <div className={"simple-editor " + (className ? " " + className : "")}
          style={style}
      >
        <BasicEditor editorState={editorState}
          style={{height: "100%"}}
          onChange={onChange}
          uploadImage={this.props.uploadImage}
          inlineToolbarConfig={inlineToolbarConfig}
        />
      </div>
    )
  }

}
