// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var CryptoHelper = require("./CryptoHelper");
var Lang_eval$BwaxAdmin = require("../../lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../runtime_common.bs.js");

function sha1(prim) {
  return CryptoHelper.sha1(prim);
}

function hex(prim) {
  return CryptoHelper.hex(prim);
}

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        "String." + (name + ": Invalid Arguments")
      ];
}

var externals_vals_000 = /* tuple */[
  "sha1",
  Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return Runtime_common$BwaxAdmin.invalid_args("sha1");
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || !(match$1.tag === /* String */2 && !param[1])) {
                return Runtime_common$BwaxAdmin.invalid_args("sha1");
              } else {
                return Runtime_common$BwaxAdmin.pack_tagged_raw("WordArray", CryptoHelper.sha1(match$1[0]));
              }
            }
          } else {
            return Runtime_common$BwaxAdmin.invalid_args("sha1");
          }
        }))
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "hex",
      Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
              if (param) {
                var match = param[0][0];
                if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "WordArray" && !param[1])) {
                  return Runtime_common$BwaxAdmin.invalid_args("hex");
                } else {
                  return Runtime_common$BwaxAdmin.pack_string(CryptoHelper.hex(match[1]));
                }
              } else {
                return Runtime_common$BwaxAdmin.invalid_args("hex");
              }
            }))
    ],
    /* [] */0
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

var externals_000 = "Crypto";

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\nmodule Crypto;\n\ntype WordArray = opaque;\n\n-- hashing\nsha1: String -> WordArray = external;\n\n-- encode\nhex: WordArray -> String = external;\n\n\n";

exports.src = src;
exports.sha1 = sha1;
exports.hex = hex;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* externals_vals Not a pure module */
