
import React, { useEffect, useContext, useState, cloneElement } from "react";

import loadSandboxDefinitions from 'bwax-ui/legacy/store/loaders/loadSandboxDefinitions'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import ApplicationContext from 'bwax/util/ApplicationContext';

import InputSelect from 'Client/js/ui/components/InputSelect';

import EditTags from 'Client/js/components/form/baseInputs/EditTags'

const Input_JSON = require('Client/re/widgets/input/Input_JSON.bs').make

import './FIeldOptionsInput.less';

import Input from 'rsuite/Input';
import Toggle from 'rsuite/Toggle'

import { TextInput } from "./Text";

export default function FieldOptionsInput(props) {

    const { dataType, value, onChange } = props;

    const [entities, setEntities] = useState([]);

    const dlc = useContext(DataLoaderContext);
    const env = { sessionToken: dlc.sessionToken, sandbox: true };

    const { currentApplication } = useContext(ApplicationContext);

    useEffect(() => {
        if(dataType == "Link") {
            (async () => {
                const { allEntities } = await loadSandboxDefinitions(currentApplication.code)(env);
                setEntities(allEntities.reverse());
            })()
        }
    }, [ currentApplication.code, dataType ]);


    const removeField = (obj, k) => {
        const { [k]: _, ...rest } = obj || {};
        return rest
    }

    const getUpdatedValue = (k, mv, transform) => {
        if (mv === undefined || mv === null || mv === '') {
            return removeField(value, k)
        } else {
            const v = transform ? transform(mv): mv;
            return { ...value, [k]: v }
        }
    }
    const doChangeOption = (k, mv, transform) => {
        const updated = getUpdatedValue(k, mv, transform);
        onChange(updated);
    };

    const optionGroup = (label, valueEl) => {
        return (
            <div className="option-group">
                <div className="label">{label}</div>
                <div className="value-input">{valueEl}</div>
            </div>
        )
    }

    const valueChanger = (name, { transform, defaultValue } = {}) => {

        const v = (value || {})[name];
        return {
            value: v !== undefined ? v : defaultValue || '',
            onChange: v => {
                doChangeOption(name, v, transform)
            } 
        }
    }

    const decimals = optionGroup(
        "小数位：",
        <Input {...{
            type: "number",
            ...valueChanger("decimals", { transform: parseInt })
        }} />

    );

    const format = optionGroup(
        "格式：", <Input {...valueChanger("format")} />
    )

    const validation = optionGroup(
        "校验设置：", <Input_JSON {...valueChanger("validation", { defaultValue : {}})} />
    )

    const entitySelect = optionGroup(
        "实体：", <InputSelect {...{
            ...valueChanger("entity"),
            isSearchable: true,
            isClearable: false,
            options: entities.map(e => e.name),
        }} />
    )

    const options = optionGroup(
        "可选项：", (
            <TextInput {...{
                autoSize: { minRows: 1 }, multivalued: true,
                value: ((value || {}).options || []).map(o => o.value),
                onChange: values => {
                    const options = (values || []).map(v => ({name: v, value: v}));
                    doChangeOption("options", options);
                }
            }}
            />
        )
    )

    const colors = optionGroup(
        "颜色设置：", (() => {
            const optionValues = ((value || {}).options || []).map(o => o.value);
            const existingColors = ((value || {}).colors) || {};
            const colorInputs = optionValues.map(v => {
                return (
                    <div className="color-input" key={v}>
                        <div className="for-option">{v}</div>
                        <Input {...{
                            value: existingColors[v] || "",
                            onChange: c => {
                                const newColors = c ? { ...existingColors, [v]: c } : removeField(existingColors, v);
                                doChangeOption("colors", newColors);
                            }
                        }} />
                    </div>
                )

            });
            return (
                <div className="color-inputs">
                    { colorInputs}
                </div>
            )

        })()
    )

    const isPublic = defaultValue => {
        return optionGroup(
            "公开：",
            <Toggle {...{
                size: "sm",
                checked: (
                    value && value.public !== undefined ? value.public : defaultValue
                ),
                onChange: checked => {
                    doChangeOption("public", checked)
                }
            }} />,
        )
    }


    const allowedColors = optionGroup(
        "可选颜色",
        <EditTags
            value={value && value.allowedColors}
            onChange={colors => {
                doChangeOption("allowedColors", colors)
            }}
        />
    )

    const sensitivity = optionGroup(
        "敏感信息：",
        <Toggle {...{
            size: "sm",
            checked: (
                value && value.sensitive !== undefined ? value.sensitive : false
            ),
            onChange: checked => {
                doChangeOption("sensitive", checked)
            }
        }} />,
    )
    


    const specifiedFields = optionGroup(
        "指定字段：",
        <TextInput {...{
            autoSize: { minRows: 1 }, multivalued: true,
            ...valueChanger("specifiedFields", { defaultValue: [] })
        }} />
    )


    const inputs = {
        "Number": [decimals, format],
        "Integer": [format],
        "Date": [format],
        "DateTime": [format],
        "ShortText": [validation, sensitivity],
        "Text": [validation],
        "Link": [entitySelect],
        "Select": [options, colors],
        "File": [isPublic(false)],
        "Image": [isPublic(true)],
        "FilterCondition": [entitySelect, specifiedFields],
        "Color": [allowedColors]
    }

    return (
        <div className="admin--field-options-input">
            {(inputs[dataType] || []).map((e, i) => cloneElement(e, { key: i }))}
        </div>
    )


}
