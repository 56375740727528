
import DefContainer from './DefContainer'

const widgets = {
    DefContainer
}

/// add prefix
const PREFIX = "containers"
const prefixed = Object.keys(widgets).reduce((acc, key) => {
    return { 
        ...acc,
        [`${PREFIX}::${key}`]: widgets[key]
    }
}, {}) 

export default prefixed