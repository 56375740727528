
import React, { useState } from 'react'

import { Button, Menu } from 'antd'

import Drawer from 'rsuite/Drawer';

import './Drawer.less'


// 这个可以作为一个通用的功能， 或者 BwaxUI 的 Button 应该相应地完善协议爱
const ButtonWithState = (props) => {

    const { buttonType, disabled, label, onClick, style, className } = props;

    const [progressing, setProgressing] = useState(false)

    return (
        <Button 
            type={buttonType} disabled={disabled} key={label} 
            style={style} className={className}
            loading={progressing}
            onClick={async () => {
                setProgressing(true)
                await onClick();
                setProgressing(false)
            }}>{label}</Button>
    )
}

export default function (props) {

    const { params, renderWidget } = props

    const {
        topActionButtons, content, size = 'small',
        visible, hide, nohead = false,
        moreActionControls,
        actionMenuVisible,
        hideActionMenu,
        showActionMenu
    } = params

    const width = {
        'small': 500,
        'medium': 660,
        'large': 880,
    }[size];


    let menu = (() => {
        if (moreActionControls && moreActionControls.length > 0) {
            return (
                <Menu>
                    { moreActionControls.map((c, i) =>
                        <Menu.Item key={i} >
                            <div style={{ padding: "0px 8px" }}>{c}</div>
                        </Menu.Item>
                    )}
                </Menu>
            )
        } else {
            return (
                <Menu>
                    <Menu.Item key="0" disabled>
                        没有可用的选项
                    </Menu.Item>
                </Menu>
            )
        }
    })();


    return (
        <Drawer
            style={{ padding: 0, height: "100%" }}
            placement="right"
            width={width}
            onClose={hide}
            open={visible}
        >
            <div className='drawer'>
                {nohead ?
                    null :
                    <div className="drawer-head">
                        <div className="left-buttons">
                            {
                                (topActionButtons || []).map(b => {
                                    const { label, onClick, buttonType, disabled } = b
                                    return (
                                        <ButtonWithState key={label}
                                            style={{ width: 80 }}
                                            buttonType={buttonType} onClick={onClick}
                                            disabled={disabled} label={label}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div>
                        </div>
                    </div>
                }
                <div className={"bw-ui lc-base  drawer-body" + (nohead ? " nohead" : "")} >
                    {content.$$typeof ? content : renderWidget(content)}
                </div>
            </div>
        </Drawer>
    )

}