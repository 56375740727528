

import React, { useEffect, useRef } from 'react'

// new, simpler inputs:
import { Input, Select, Switch } from '@arco-design/web-react';
import { IconCheck } from '@arco-design/web-react/icon';

const Option = Select.Option;

export default function getDisplay({ type, typeOptions, facade }) {

    return (value) => {
        const displays = {
            // "ShortText": _ => (
            //     <Input {...{
            //         onBlur: onEnd, onChange, value,
            //         onKeyDown, autoFocus
            //     }} />
            // ),

            // "Text": _ => (
            //     <Input.TextArea autoFocus {...{
            //         onBlur: onEnd, onChange, value,
            //         onKeyDown
            //     }} />
            // ),

            "FieldType": _ => {
                const dataType = facade.dataTypes.find(dt => dt.key == value);
                return dataType ? dataType.name : value
            },
            "Bool": _ => {
                if (value) {
                    return <IconCheck />
                } else {
                    return ""
                }
            }
        }
        return (displays[type] || (_ => value))();
    }

}
