// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Layout_Row(Props) {
  var params = Props.params;
  var renderWidget = Props.renderWidget;
  var children = Props.children;
  var configuredStyle = Belt_List.keepMap(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Belt_Option.map(params.gapSize, (function (gz) {
                  return Css.selector("> :not(:last-child)", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Css.marginRight(Css.rem(gz)),
                                /* [] */0
                              ]));
                })),
          /* [] */0
        ]), (function (x) {
          return x;
        }));
  var fixedStyle_000 = Css.flexWrap(/* wrap */-822134326);
  var fixedStyle_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      Css.width(Css.pct(100.0)),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.overflow(/* auto */-1065951377),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.maxHeight(Css.pct(100.0)),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.display(/* flex */-1010954439),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.flexDirection(/* row */5693978),
                      /* [] */0
                    ])
                ])
            ])
        ])
    ]);
  var fixedStyle = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      fixedStyle_000,
      fixedStyle_001
    ]);
  var row = Css.style(Belt_List.concat(configuredStyle, fixedStyle));
  var tmp;
  if (renderWidget !== undefined) {
    var match = params.content;
    tmp = match !== undefined ? Curry._3(renderWidget, Caml_option.valFromOption(match), /* row */5693978, undefined) : null;
  } else {
    tmp = children;
  }
  return React.createElement("div", {
              className: "admin--row " + row
            }, tmp);
}

var make = Layout_Row;

exports.make = make;
/* Css Not a pure module */
