

import React, { useEffect, useState } from 'react'

import Modal from 'bwax-ui/ml/widget/impl/misc/Modal';

import { Button } from 'rsuite';
import genCodeBlock from './genCodeBlock';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'
import StudioItem from '../StudioItem';
import insertEntityQueryToModel from 'Client/js/codeEditor/generationUtils/insertEntityQueryToModel';

function convertImportedItem(item) {

    if(item.itemType === "imported-entity") {
        return new StudioItem({ 
            itemType: "entity", name: item.name, 
            // data: item.data 只需要用到名称
        })
    }

    return item;
}


// profile to itemType
function getDropHandlers({ view, currentDropped, runtimeProfile, previewFacade, codeEditorCommands }) {

    const { item: originalItem, dropAt } = currentDropped;

    // 
    const item = convertImportedItem(originalItem);


    // determine the indents
    // 
    function determineCurrentIndents() {
        return 0
    }
    const indents = determineCurrentIndents();

    function insertCodeBlock(codeBlock) {
        if (dropAt !== undefined && view) {
            view.dispatch({
                changes: { from: dropAt, insert: codeBlock }
            })
        }
    }

    const [target, targetCodeName] = ({
        "entity": _ => {
            const entity = previewFacade.entities.find(e => e.name === item.getName());
            const entityName = entity.name;
            const nname = lang_entry_slim.normalize_name(entityName);
            return [entity, nname];

        }
    }[item.itemType] || (_ => []))();


    function clickToInsertCode(block) {
        return () => {

            if (typeof (block) === "string") {
                const codeBlock = genCodeBlock(block, target, targetCodeName, indents);
                insertCodeBlock(codeBlock);
            } else if (typeof (block) == "function") {
                const codeBlock = block(target, targetCodeName, indents);
                insertCodeBlock(codeBlock);
            }

        }
    }


    function insertEntityQuery(queryType) {
        return () => {
            const updates = insertEntityQueryToModel({ 
                entity: target, 
                syntaxTree: codeEditorCommands.getSyntaxTree (),
                queryType
            });
            if(updates.length > 0) {
                codeEditorCommands.updateCodeRanges(updates)
            }
        }
    }


    const handlers = {

        // admin-page
        "表格组件": {
            action: clickToInsertCode("admin-record-table"),
            applicable: ["entity", ["admin-page"]]  // the first is item type, the second is runtime profile name
        },
        "卡片组件": {
            action: clickToInsertCode("admin-record-card"),
            applicable: ["entity", ["admin-page"]]
        },

        // 
        "数据查询或操作": {
            action: {
                // "查询单个": clickToInsertCode("entity_query_find_one"),
                // "查询多个": clickToInsertCode("entity_query_list"),
                "添加记录": clickToInsertCode("entity_query_add"),
                "修改记录": clickToInsertCode("entity_query_update"),
                "删除记录": clickToInsertCode("entity_query_delete"),
            },
            applicable: ["entity", ["admin-page", "page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]]
        },


        "自定义操作对象": {
            action: clickToInsertCode(_ => {

                const opName = item.name;

                const { parentItem } = item;
                const entityName = parentItem ? parentItem.name : "";

                const normalizedEntityname = lang_entry_slim.normalize_name(entityName);
                const normalizedOpName = lang_entry_slim.normalize_name(opName);

                return `Custom Query_${normalizedEntityname}.to_${normalizedOpName}`;
            }),
            applicable: _ => {
                if(item.itemType == "data-interface") {
                    return true
                }
            }
        },

        "查询数据并保存到 Model ": {

            action: {
                "查询单个": insertEntityQuery("one"),
                "查询多个": insertEntityQuery("list"),
                "查询数量": insertEntityQuery("count"),
                "查询多个和数量": insertEntityQuery("paged"),
            },
            applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        },

        // page
        // "Type Alias 声明": {
        //     action: clickToInsertCode(_ => `type alias R_${targetCodeName} = # E_${targetCodeName};`),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        // },
        // "GotOne Msg": {
        //     action: clickToInsertCode(_ => `| GotOne_${targetCodeName} (Maybe R_${targetCodeName})`),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        // },
        // "GotList Msg": {
        //     action: clickToInsertCode(_ => `| GotList_${targetCodeName} (List R_${targetCodeName})`),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        // },
        // "Model 字段": {
        //     action: clickToInsertCode(_ => `, ${targetCodeName}: Maybe R_${targetCodeName}`),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        // },
        // "Model List 字段": {
        //     action: clickToInsertCode(_ => `, ${targetCodeName}_list: List R_${targetCodeName}`),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        // },
        // "处理 GotOne Msg": {
        //     action: clickToInsertCode("entity_update_case_for_got_one"),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]],
        // },
        // "处理 GotList Msg": {
        //     action: clickToInsertCode("entity_update_case_for_got_list"),
        //     applicable: ["entity", ["page", "page-component", "virtual-field", "data-interface", "event-handler", "scheduled-event-handler"]]
        // }


        //

    };


    const applicableHandlers = Object.keys(handlers).reduce((acc, name) => {
        const { action, applicable } = handlers[name];

        function isApplicable() {
            if (Array.isArray(applicable)) {
                return item.itemType === applicable[0] && (applicable[1] || []).indexOf(runtimeProfile.name) != -1
            }
            if (typeof (applicable) === "function") {
                return applicable();
            }
            // 没有提供 applicable 设定
            return false
        }
        if (isApplicable()) {
            return {
                ...acc,
                [name]: action
            }
        } else {
            return acc
        };

    }, {});


    const defaultHandler = () => {
        console.log("No handler", item, runtimeProfile);
        insertCodeBlock(item.getName())
    };

    const handlerCount = Object.keys(applicableHandlers).length;
    if (handlerCount > 0) {
        return applicableHandlers
    } else {
        return defaultHandler
    }

}


export default function DropDialog({ view, currentDropped, setCurrentDropped, runtimeProfile, previewFacade, codeEditorCommands }) {

    const [handlers, setHandlers] = useState();
    
    useEffect(() => {
        console.log("Current curren dropped", currentDropped);
        if (currentDropped) {
            const handlers = getDropHandlers({ view, currentDropped, runtimeProfile, previewFacade, codeEditorCommands });

            if (typeof (handlers) === "function") {
                // 直接执行
                handlers();
                setCurrentDropped(null);
                setHandlers(null);
            } else {
                // 显示 dialog
                setHandlers(handlers)
            }

        } else {
            setHandlers(null)
        }

    }, [currentDropped]);

    function renderContent() {
        if (!handlers) {
            return null
        }
        return (
            <div className="studio-dialog">
                <div className="dialog-head">
                    <div className="title">
                        <span>请选择你要插入的内容</span>
                    </div>
                </div>
                <div className="dialog-section" style={{ marginTop: "1rem" }}>
                    {
                        Object.keys(handlers).map(label => {
                            return (
                                <Button key={label} onClick={() => {
                                    const handler = handlers[label];

                                    if (typeof (handler) === "function") {
                                        handler();
                                        setCurrentDropped(null);
                                    } else if (typeof (handler) === "object") {
                                        // 表示有下一级选项
                                        setHandlers(handler)
                                    }


                                }}>
                                    {label}
                                </Button>
                            )
                        })
                    }
                </div>
                <div className="dialog-section">
                    <Button onClick={() => {
                        setCurrentDropped(null);
                    }}>
                        取消
                    </Button>
                </div>
            </div>
        )

    }


    return (
        <Modal {...{
            visible: !!handlers,
            containerStyle: {
                borderRadius: 4,
                width: 320,
            },
            modalContent: renderContent()
        }} />
    )
}