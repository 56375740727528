
import React from 'react';

export default function AdminDisplayPage (props) {

    console.log(props);

    // 使用 RecordTable 和 RecordCard

    return (
        <div> ADMIN DISPLAY PAGE </div>
    )
}