// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Widget_common$BwaxAdmin = require("./widget_common.bs.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "StyledUI";

var src = "\n\n-- 提供一系列预设的样式，或者带有样式的组件\n\nmodule " + (String(module_name) + ";\n\nimport Element exposing (Element, Rule);\n\ntype Appearance = Primary | Default | Outline;\ntype Size = Mini | Small | Medium | Large | XLarge;\n\n--- Radix colors\ntype ColorScale = \n  | Tomato | Red | Crimson | Pink | Plum | Purple | Violet | Indigo | Blue | Cyan | Teal | Green | Grass | Orange | Brown \n  | Sky | Mint | Lime | Yellow | Amber \n  | Gray | Mauve | Slate | Sage | Olive | Sand \n;\n\ncolor: ColorScale -> Int -> Color = external;\n\nbutton: \n    List (Rule msg)\n  -> ~{ onPress: msg, isDisabled: Bool, isLoading: Bool, appearance: Appearance\n      , color: ColorScale, size: Size\n      }\n  ->  Element msg \n  ->  Element msg = external;\n\n\ndialog: \n    List (Rule msg)\n  -> ~{ open: Bool, onOpenChange: Bool -> msg\n      , isImportant: Bool, isMain: Bool\n      }\n  -> Element msg -- content\n  -> Element msg = external;\n\n\npopover: \n    List (Rule msg)\n  -> ~{ content: Element msg!\n      }\n  -> Element msg -- content\n  -> Element msg = external;\n\n");

function pack_element(module_element) {
  return /* Delegated */Caml_chrome_debugger.variant("Delegated", 23, [
            module_name,
            module_element
          ]);
}

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

var externals_vals_000 = /* tuple */[
  "button",
  Widget_common$BwaxAdmin.pack_element_func(3, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_list */1) {
              return Runtime_common$BwaxAdmin.invalid_args("button");
            } else {
              var match$1 = param[1];
              if (match$1) {
                var match$2 = match$1[0][0];
                if (typeof match$2 === "number" || match$2.tag !== /* V_record */2) {
                  return Runtime_common$BwaxAdmin.invalid_args("button");
                } else {
                  var match$3 = match$1[1];
                  if (match$3) {
                    var match$4 = match$3[0][0];
                    if (typeof match$4 === "number" || !(match$4.tag === /* V_raw */7 && !match$3[1])) {
                      return Runtime_common$BwaxAdmin.invalid_args("button");
                    } else {
                      var config_v = match$2[0];
                      var rules = Widget_common$BwaxAdmin.raw_to_rules(match[0]);
                      var get_bool = function (name) {
                        return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_bool, Plate$BwaxAdmin.List.assoc(name, config_v)));
                      };
                      var get_tag_name = function (name) {
                        return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                      var match = param[0];
                                      if (typeof match === "number" || match.tag !== /* V_tagged */6) {
                                        return ;
                                      } else {
                                        return Plate$BwaxAdmin.Str.to_lower(match[0]);
                                      }
                                    }), Plate$BwaxAdmin.List.assoc(name, config_v));
                      };
                      var isDisabled = get_bool("isDisabled");
                      var isLoading = get_bool("isLoading");
                      var onPress = Plate$BwaxAdmin.List.assoc("onPress", config_v);
                      var appearance = get_tag_name("appearance");
                      var size = get_tag_name("size");
                      var color = get_tag_name("color");
                      var modal_config = /* record */Caml_chrome_debugger.record([
                          "onPress",
                          "isDisabled",
                          "isLoading",
                          "appearance",
                          "color",
                          "size"
                        ], [
                          onPress,
                          isDisabled,
                          isLoading,
                          appearance,
                          color,
                          size
                        ]);
                      return /* Delegated */Caml_chrome_debugger.variant("Delegated", 23, [
                                module_name,
                                /* Style_button */Caml_chrome_debugger.variant("Style_button", 0, [
                                    rules,
                                    modal_config,
                                    match$4[0]
                                  ])
                              ]);
                    }
                  } else {
                    return Runtime_common$BwaxAdmin.invalid_args("button");
                  }
                }
              } else {
                return Runtime_common$BwaxAdmin.invalid_args("button");
              }
            }
          } else {
            return Runtime_common$BwaxAdmin.invalid_args("button");
          }
        }))
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "dialog",
      Widget_common$BwaxAdmin.pack_element_func(3, (function (param) {
              if (param) {
                var match = param[0][0];
                if (typeof match === "number" || match.tag !== /* V_list */1) {
                  return Runtime_common$BwaxAdmin.invalid_args("dialog");
                } else {
                  var match$1 = param[1];
                  if (match$1) {
                    var match$2 = match$1[0][0];
                    if (typeof match$2 === "number" || match$2.tag !== /* V_record */2) {
                      return Runtime_common$BwaxAdmin.invalid_args("dialog");
                    } else {
                      var match$3 = match$1[1];
                      if (match$3) {
                        var match$4 = match$3[0][0];
                        if (typeof match$4 === "number" || !(match$4.tag === /* V_raw */7 && !match$3[1])) {
                          return Runtime_common$BwaxAdmin.invalid_args("dialog");
                        } else {
                          var config_v = match$2[0];
                          var rules = Widget_common$BwaxAdmin.raw_to_rules(match[0]);
                          var get_bool = function (name) {
                            return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_bool, Plate$BwaxAdmin.List.assoc(name, config_v)));
                          };
                          var isOpen = get_bool("open");
                          var isImportant = get_bool("isImportant");
                          var isMain = get_bool("isMain");
                          var match$5 = Plate$BwaxAdmin.List.assoc("onOpenChange", config_v);
                          var onOpenChange;
                          if (match$5 !== undefined) {
                            var tagger = match$5;
                            onOpenChange = (function (isOpen) {
                                return Lang_eval$BwaxAdmin.apply_value(undefined, tagger, Runtime_common$BwaxAdmin.pack_bool(isOpen), Runtime_common$BwaxAdmin.nt);
                              });
                          } else {
                            onOpenChange = undefined;
                          }
                          var config = /* record */Caml_chrome_debugger.record([
                              "isOpen",
                              "onOpenChange",
                              "isImportant",
                              "isMain"
                            ], [
                              isOpen,
                              onOpenChange,
                              isImportant,
                              isMain
                            ]);
                          return /* Delegated */Caml_chrome_debugger.variant("Delegated", 23, [
                                    module_name,
                                    /* Style_dialog */Caml_chrome_debugger.variant("Style_dialog", 1, [
                                        rules,
                                        config,
                                        match$4[0]
                                      ])
                                  ]);
                        }
                      } else {
                        return Runtime_common$BwaxAdmin.invalid_args("dialog");
                      }
                    }
                  } else {
                    return Runtime_common$BwaxAdmin.invalid_args("dialog");
                  }
                }
              } else {
                return Runtime_common$BwaxAdmin.invalid_args("dialog");
              }
            }))
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "popover",
          Widget_common$BwaxAdmin.pack_element_func(3, (function (param) {
                  if (param) {
                    var match = param[0][0];
                    if (typeof match === "number" || match.tag !== /* V_list */1) {
                      return Runtime_common$BwaxAdmin.invalid_args("button");
                    } else {
                      var match$1 = param[1];
                      if (match$1) {
                        var match$2 = match$1[0][0];
                        if (typeof match$2 === "number" || match$2.tag !== /* V_record */2) {
                          return Runtime_common$BwaxAdmin.invalid_args("button");
                        } else {
                          var match$3 = match$1[1];
                          if (match$3) {
                            var match$4 = match$3[0][0];
                            if (typeof match$4 === "number" || !(match$4.tag === /* V_raw */7 && !match$3[1])) {
                              return Runtime_common$BwaxAdmin.invalid_args("button");
                            } else {
                              var config_v = match$2[0];
                              var rules = Widget_common$BwaxAdmin.raw_to_rules(match[0]);
                              var get_element = function (name) {
                                return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                              var match = param[0];
                                              if (typeof match === "number" || match.tag !== /* V_raw */7) {
                                                return ;
                                              } else {
                                                return match[0];
                                              }
                                            }), Plate$BwaxAdmin.List.assoc(name, config_v));
                              };
                              var match$5 = get_element("content");
                              var content = match$5 !== undefined ? match$5 : Runtime_common$BwaxAdmin.invalid_args("popover");
                              var config = /* record */Caml_chrome_debugger.record(["content"], [content]);
                              return /* Delegated */Caml_chrome_debugger.variant("Delegated", 23, [
                                        module_name,
                                        /* Style_popover */Caml_chrome_debugger.variant("Style_popover", 2, [
                                            rules,
                                            config,
                                            match$4[0]
                                          ])
                                      ]);
                            }
                          } else {
                            return Runtime_common$BwaxAdmin.invalid_args("button");
                          }
                        }
                      } else {
                        return Runtime_common$BwaxAdmin.invalid_args("button");
                      }
                    }
                  } else {
                    return Runtime_common$BwaxAdmin.invalid_args("button");
                  }
                }))
        ],
        /* [] */0
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var command_handlers = /* [] */0;

exports.module_name = module_name;
exports.src = src;
exports.pack_element = pack_element;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* src Not a pure module */
