
import React from 'react'

import FavoritesButton from '../topbar/FavoritesButton';
import NotificationsButton from '../topbar/NotificationsButton';

import UserBox from '../topbar/UserBox';

import TopBar from '../topbar/TopBar';

import { useGeneralSettingValueJs } from './GeneralSettingView';


export default function AdminTopBar(props) {

    const { currentUser, baseSettings, history, applications, currentApplication, tenantCode, dlc, facade } = props;

    const siteTitle = useGeneralSettingValueJs({ name: "后台标题", appCode: currentApplication.code, dlc, facade });
    const logoURL = useGeneralSettingValueJs({ name: "logo", appCode: currentApplication.code, dlc, facade });

    const onApplicationSelected = application => {

        const { code } = application;

        // const path = location.pathname.replace(/\/app\/[^\/]+\//, `/app/${code.toLowerCase()}/`);
        // 不应该保留原来的 路径后缀，应该直接进入该 app 的默认页面：

        const path = `/app/${code.toLowerCase()}`;
        history.push(path);
    };

    const rightActions = (
        <>
            <NotificationsButton />
            <FavoritesButton />
            <UserBox currentUser={currentUser} dlc={dlc} />
        </>
    )

    const isPlatform = !(tenantCode || true);

    const style = (
        isPlatform ? {} :
        {
            boxShadow: "none",
            backgroundColor: "white",
            borderBottom: "0.5px solid rgb(229, 230, 235)",
            color: "#1d2129"
        }
    )

    return (
        <TopBar {...{ 
            currentUser, baseSettings, rightActions, 
            applications, currentApplication, onApplicationSelected,
            size: "small", siteTitle, logoURL,
            style
        }} ></TopBar>
    )
}