import Block from "./Block";

const commands = (_ => {
    return {
        createFirstBlock, insertBlock, deleteBlock, moveBlock, duplicateBlock, selectBlock,
        updateBlockAttributes
    }
})();

export default commands;

/// helpers:
function insertAt(index, value, array) {
    return [
        ...array.slice(0, index), value, ...array.slice(index)
    ]
}
function removeValue(value, array) {
    return array.filter(v => v != value);
}

// helpers ends

// commands
function insertBlock(model, { blockTypeName, targetParent, targetIndex, slotIndex }, { blockTypes }) {
    const { contentDict } = model;

    const newBlock = new Block({
        type: blockTypeName,
        blockTypes,
    })

    const parentBlock = contentDict[targetParent];

    return {
        ...model,
        contentDict: {
            ...contentDict,
            [targetParent]: parentBlock.insertChild(newBlock.id, targetIndex, slotIndex),
            [newBlock.id]: newBlock
        },
        selected: newBlock.id
    }
}


function createFirstBlock(model, { blockTypeName }, { blockTypes }) {
    const newBlock = new Block({
        type: blockTypeName,
        blockTypes,
    })

    return {
        ...model,
        contentDict: {
            [newBlock.id]: newBlock
        },
        selected: newBlock.id
    }
}

function moveBlock(model, { blockId, targetParent, targetIndex, slotIndex }) {

    // not a new item
    const { contentDict } = model;
    const targetParentBlock = contentDict[targetParent];

    const originalParentBlock = Object.values(contentDict).find(b => {
        return b.hasChild(blockId)
    })

    const originalIndex = originalParentBlock.indexOfChild(blockId);
    if (!originalParentBlock || !targetParentBlock || !originalIndex) {
        return model
    }

    function getBlockUpdates() {
        const sameParent = originalParentBlock.id === targetParentBlock.id;
        const sameSlot = (originalIndex.slotIndex === undefined && slotIndex === undefined) || (originalIndex.slotIndex === slotIndex);

        if (!sameParent) {
            // different parent or same parent different slot
            return {
                [originalParentBlock.id]: originalParentBlock.removeChild(blockId),
                [targetParentBlock.id]: targetParentBlock.insertChild(
                    blockId, targetIndex, slotIndex
                )
            }

        } else if (!sameSlot) {
            // same parent different slot
            return {
                [targetParentBlock.id]: targetParentBlock.removeChild(blockId).insertChild(
                    blockId, targetIndex, slotIndex
                )
            }
        } else {
            // same parent, same slot:
            const sameIndex = originalIndex.index == targetIndex || originalIndex.index + 1 == targetIndex;
            if (sameIndex) {
                // console.log("Do nothing")
                return {}
            } else if (targetIndex < originalIndex) {
                // move backwards
                console.log("Backwards")
                return {
                    [targetParentBlock.id]: targetParentBlock.removeChild(blockId).insertChild(
                        blockId, targetIndex, slotIndex
                    )
                }

            } else {
                // move forwards
                // remove it, and the place it at the drop target index minus 1
                console.log("Forwards")
                return {
                    [targetParentBlock.id]: targetParentBlock.removeChild(blockId).insertChild(
                        blockId, targetIndex - 1, slotIndex
                    )
                }
            }
        }
    }

    return {
        ...model,
        contentDict: {
            ...contentDict,
            ...getBlockUpdates()
        },
        selected: blockId
    }

}

function deleteBlock(model, { blockId }) {

    const descendantIds = model.contentDict[blockId].getDescendants(model.contentDict).map(b => b.id);

    let nextSelected = null;
    const newContentDict = Object.keys(model.contentDict).reduce((acc, bid) => {
        // 1. remove the id from its parent's childIds
        // 2. remove the block and all its descendant.
        const block = model.contentDict[bid];
        if (block.hasChild(blockId)) {

            // resolve the next selected: next sibling > prev sibing > parent
            const nextSibling = block.getNextSiblingOf(blockId);
            const prevSibling = block.getPrevSiblingOf(blockId);
            nextSelected = nextSibling || prevSibling || block.id;

            return {
                ...acc,
                [bid]: block.removeChild(blockId)
            }
        } else if (blockId === bid || descendantIds.indexOf(bid) !== -1) {
            // remove it
            return acc
        } else {
            return { ...acc, [bid]: block }
        }
    }, {})

    return {
        ...model, contentDict: newContentDict, selected: nextSelected

    }

}

function selectBlock(model, { blockId }) {
    if(model.selected === blockId) {
        return model
    } else {
        return {
            ...model,
            selected: blockId
        }
    }
    
}

function duplicateBlock(model, { blockId }) {

    const { contentDict } = model;

    const blocks = Object.values(contentDict);
    // 1.先找到它的 parent
    const parent = blocks.find(b => b.hasChild(blockId));
    if(!parent) {
        return model
    }
    const currentIndex = parent.indexOfChild(blockId);
    if (!blockId || !parent || !currentIndex) {
        return model
    }

    // 2. 从上到下复制每一个 block
    const { newBlock, newDict } = contentDict[blockId].duplicate(contentDict);

    const newContentDict = {
        ...contentDict,
        ...newDict,
        [parent.id]: parent.insertChild(newBlock.id, currentIndex.index + 1, currentIndex.slotIndex)
    }

    return {
        ...model, contentDict: newContentDict
    }
}


function updateBlockAttributes(model, { blockId, attributes = {} }) {
    const { contentDict } = model;
    const block = contentDict[blockId];

    if (!block) {
        return model
    }
    const newContentDict = {
        ...contentDict,
        [blockId]: block.cloneWith({
            attributes: {
                ...(block.attributes || {}),
                ...attributes
            }
        })
    }
    return {
        ...model, contentDict: newContentDict
    }
}





