import React, { Suspense, useContext, useState } from 'react'
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import { 
    buildCacheKey, getInstance,
    USER_DATA, USER_RUNTIME
} from "bwax/store/DataCache";


import RenderWithData from 'bwax-ui/store/RenderWithData'

import BreadcrumbSimple from 'Client/js/breadcrumb/BreadcrumbSimple'

import TabBar from "Client/js/components/TabBar";

import { make as AdminPage, runInit } from 'Client/ml/AdminPage.bs';

import PageRuntime from 'bwax-ui/ml/widget/PageRuntime'

import { runDataQuery } from 'bwax/query/runClientQuery';

import queryUser from "bwax/query/queyUser"

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import invariant from 'invariant';

import Loading from 'Client/js/components/Loading';

import {  unpack } from 'bwax/lang/LangHelper'

import { resolveDependenciesAndPrepareData } from 'bwax/BwaxExecHelper';

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary';

import { hashCode } from 'bwax/utils';

/**
 * 参照 PageContainer
 * 1. 在 loadData 中，调用 AdminPage 的对应函数 初始化 model
 * 2. 在 React component 中，
 *      组建 renderView 函数：调用 AdminPage 对应函数组装参数；返回 AdminPage
 *      使用 SmartRenderer，传入 renderView
 */


export default function AdminPageContainer (props) {

    const { 
        routeTo, facade, match, compiled, location,
        entityName, recordId,
        pageName, 

        // 
        webEnv : givenWebEnv, domainEnv: givenDomainEnv,

        ...rest
    } = props

    const { pageName: _, tab, ...params } = match.params;

    const route_to = (path, shouldReplace) => {
        routeTo(path);
    }    

    const dlc = useContext(DataLoaderContext);
    const { userenv } = dlc;

    const dataCache = getInstance(dlc);
    
    const domainEnv = givenDomainEnv || {
        mobileHost: userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        protocol: "https",
    }

    const { pathname, search } = location;
    const currentURL = pathname + search; // hash 不支持
    const currentURLPath = pathname;

    const initParams = lang_entry_slim.transfrom_json_to_assoc_string_list_value(params || {});
    
    const webEnv = givenWebEnv || {
        protocol: "https", 
        host: userenv.mobileHosts[0], 
        isSandbox: dlc.sandbox,
        isIOS: userenv.isIOS,
        isWeChat: userenv.isWeChat,
        isWxWork: userenv.isWxWork,
        isMobile: userenv.isMobile,
        currentURL,
        currentURLPath,
        originalURL: userenv.originalURL,        
    }

    const loadData = async () => {

        try {
            const t0 = performance.now();

            const unpacked = unpack(compiled);

            const t0_1 = performance.now();

            // console.log("AdminPageContainer unpack", t0_1 - t0);
            
            if(unpacked.length === 5) {
                console.log(":> ");
               throw "旧版本的页面现在已经不支持";
            }

            const [ n, defs, dts, entityNames ] = unpacked;

            await facade.prepare(entityName ? [entityName, ...entityNames] : entityNames);
            const entity_dict = facade.entity_dict;
            const data_type_dict = facade.data_type_dict;

            const dependedData = await (async () => {
                const getUser = async userDeps => {
                    const [_tree, paths, selectionText, _deps] = userDeps;
                    if (!paths || paths.length == 0) {
                        return {}
                    }
                                                                    
                    const dataKey = buildCacheKey(USER_DATA, "GetCurrentUser_" + hashCode(selectionText), dlc);
                    let user = dataCache.get(dataKey);
                    if(!user) {
                        user = await queryUser(dlc, selectionText);
                        if(user) {
                            dataCache.set(dataKey, user);
                        } 
                    }
                    if (!user) {
                        console.error("User is not logged in");
                    }

                    return user;
                }
        
                const getRecord = async (entityName, id, recordDep) => {
                    const [_tree, paths, selectionText, _deps ] = recordDep;
                    if (!paths || paths.length == 0) {
                        return {}
                    }
                    const entity = facade.entities.find(e => e.name == entityName);
                    const recordData = await queryOne(dlc, entity, id, selectionText);             
                    return recordData
                }
        
                const [dependedData, error] = await resolveDependenciesAndPrepareData([n, defs], {
                    getRecord, getUser,

                    targetEntityName: entityName,
                    targetRecordId: recordId,

                    entity_dict, data_type_dict, dts, domainEnv
                })
                // 
                invariant(!error, "Error happened during data preparation: " + error);

                return dependedData

            })();

            const [ initResult, error ] = await runInit(
                domainEnv, webEnv, route_to, facade,
                dts, [n, defs], initParams, dependedData
            )
            
            const t1 = performance.now();

            // console.log("AdminPageContainer prepare", t1 - t0);

            return [ [ initResult, dts, [n, defs], dependedData ], error ];


        } catch (error) {
            return [undefined, error]
        }
        
    };

    return (
        <Suspense fallback={<Loading />}>
            <RenderWithData {...{

                domainEnv, webEnv, dlc, match, facade, location,
                loadData, route_to, routeTo,

                contextEntityName: entityName, contextRecordId: recordId,

                cacheType: USER_RUNTIME,
                dataKey: "AdminPageInitModel_" + pageName, 
                Component: Inner,

                pageName,

                initParams,

                refreshingEnabled: true,

                noSuspense: true,

                ...rest,
            }} />
        </Suspense>
    )





}

function printError(error) {
    if (error.stack) {
        return error.stack;
    } else if (Array.isArray(error)) {
        // bucklescript error
        const [_, msg] = error;
        return msg
    } else {
        return error;
    }
}

function Inner (props) {

    const { 
        data, facade, additional, route_to, routeTo, match, webEnv, domainEnv, dlc, location,
        contextRecordId, 
        contextEntityName,
        pageName,

        initParams,

        refresh, refreshedAt, 

        changeTab, // 只是用于 preview container 的测试：

    } = props;

    const { pageName: _, tab, ...params } = match.params;

    const [ values, error ] = data;

    const [tabNames, setTabNames] = useState([]);

    const pageID = "Admin::" + pageName + (tab ? "::" + tab : "");


    if(error) {
        return (
            <div style={{ padding: "8px 16px", width: "100%" }}>
                <h4>出错了</h4>
                <div style={{ padding: "8px 0", width: "100%" }}>
                    <pre style={{ width: "100%", overflow: "auto" }}>
                        {printError(error)}
                    </pre>
                </div>
            </div>
        )
    } 

    const [ initResult, dts, ast, preparedData ] = values;

    const { queryCache, queryRunner, entity_dict, data_type_dict } = facade;
    

    const viewEnv = { routeTo: route_to, webEnv, domainEnv };

    return (
        <div className="admin-page">
            <div className="admin-page-head">
                <BreadcrumbSimple {...{
                    allEntities: facade.entities,
                    allDatatypes: facade.dataTypes, 
                    additional,
                    routeTo,
                    pathname: location.pathname,
                }} />
                <TabBar {...{ match, routeTo, tabs: tabNames, changeTab }} />
                <div />
            </div>
            <div className="admin-page-container">
            <ErrorBoundary>

                <PageRuntime
                    pageID={pageID}
                    domainEnv={domainEnv}
                    queryCache={queryCache}
                    queryRunner={queryRunner}
                    webEnv={webEnv}
                    initParams={initParams}
                    dlc={dlc}
                    entity_dict={entity_dict}
                    data_type_dict={data_type_dict}
                    dts={dts}
                    ast={ast}
                    route_to={route_to}
                    init_result={initResult}
                    preparedData={preparedData}
                    renderView={(value, onMsg, _getInstanceID, _model) => {  
                    
                        return (
                            <AdminPage {
                                ...{
                                    value, 
                                    onMsg,

                                    refresh, refreshedAt,

                                    pageID,
                                    dts,
                                    queryRunner,
                                    route_to,
                                    bwax: facade,
                                    contextRecordId,
                                    contextEntityName,
                                    renderLoadingPage: () => {
                                        return <Loading />
                                    },
                                    activeTabName: tab,
                                    onTabNamesReady: names => {
                                        setTabNames(names)
                                    },

                                    viewEnv,
                                }
                            } />
                        )
                    }}
            />
            </ErrorBoundary>
            </div>
        </div>
    )

}



// user cache as well
export async function queryOne(dlc, entity, id, selectionText) {
    const runQuery = runDataQuery(dlc)

    let queryText = `
        query { q: list${entity.key} ( condition: [[{ field: "id", op: eq, value: "${id}" }]]) {
            edges { node ${selectionText} }
        }} `

    const result = await runQuery(queryText)()
    const { errors, data } = JSON.parse(result)

    if (data && data.q && data.q.edges && data.q.edges.length > 0) {
        return data.q.edges[0].node
    } else {
        return undefined
    }

}
