import React, { useState, useContext } from 'react'
import ReactDOM from 'react-dom'

import { Row, Button } from 'antd'
import RecordSelect from 'Client/js/ui/widgets/input/RecordSelect'

import Message from 'Client/js/ui/Message';

import Popover from 'bwax-ui/components/legacy/Popover'

import { runDataQuery } from 'bwax/query/runClientQuery'
import { buildUpdateRecordMutation } from 'bwax/query/QueryBuilder'
import { systemRoles } from './authHelper'

import InputSelect from 'Client/js/ui/components/InputSelect'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'


export default function AddAdminUser(props) {

    const dlc = useContext(DataLoaderContext)

    console.log(">> add");

    const { params, allEntities, allDataTypes, queryTarget } = props

    const { onSave } = params;

    const dataEntity = allEntities.find(e => e.name === '用户')

    //states begin: 
    const [adminUserInput, setAdminUserInput] = useState({})
    const [popoverVisible, setPopoverVisible] = useState(false)
    //states end      

    async function confirmAddAdminUser(adminUserInput) {
        const { errors, data } = await addAdminUser(dlc, adminUserInput, { entity: dataEntity, allDataTypes, allEntities })
        if (!errors) {
            Message.success("添加成功！")
            ReactDOM.unstable_batchedUpdates(() => {
                setAdminUserInput({})
                setPopoverVisible(false)
            })
            if(onSave) {
                onSave()
            }
        } else {
            const error = (errors || []).map(e => {
                return e.message
            }).join("；")
            console.error(error);
        }
    }

    return (
        <Popover
            visible={popoverVisible}
            // onClickOutside={() => 
            //     // setPopoverVisible(false)
            // }
            content={(
                <div style={{ width: 250, padding: "2rem 1rem" }}>
                    <RecordSelect
                        value={adminUserInput.user}
                        onChange={value => setAdminUserInput({
                            ...adminUserInput,
                            user: value
                        })}
                        allDataTypes={allDataTypes}
                        allEntities={allEntities}
                        queryTarget={queryTarget}
                        params={{
                            entityName: '用户',
                            pageSize: 6,
                            fieldsToSearch: ['昵称'],
                            fieldsToReturn: {
                                systemRoles: `\${value.系统角色}`
                            },
                            itemInList: 
                                item => (
                                    item.systemRoles && 
                                    item.systemRoles.some(n => n === 'Admin' || n === 'Operator')
                                )
                        }}
                    />
                    <InputSelect {...{
                        isMulti: true,
                        style: { width: "100%", marginTop: 20 },
                        value: adminUserInput.systemRoles,
                        onChange: (value) => setAdminUserInput({
                            ...adminUserInput,
                            systemRoles: value
                        }),
                        options: systemRoles,
                    }} />
                    <Row type="flex"
                        style={{ marginTop: 20 }}
                        justify="space-between">
                        <Button
                            size="small"
                            onClick={() => setPopoverVisible(false)}>
                            取消
                        </Button>
                        <Button
                            size="small"
                            type="primary"
                            disabled={!(adminUserInput.user && adminUserInput.systemRoles
                                && adminUserInput.systemRoles.length > 0)}
                            onClick={() => confirmAddAdminUser(adminUserInput)}>
                            确定
                        </Button>
                    </Row>
                </div>
            )}
            positions={["bottom"]}
            align="end"
        >
            <Button type="primary" onClick={() => setPopoverVisible(true)}>
                新增
            </Button>
        </Popover>
    )
}

async function addAdminUser(dlc, adminUserInput, { entity, allDataTypes, allEntities}) {
    const { user, systemRoles } = adminUserInput
    const {
        queryText, extractResult
    } = buildUpdateRecordMutation(
        entity, allEntities, allDataTypes, { queryDepth: 1 }
    )

    const result = await runDataQuery(dlc)(queryText)({
        input: {
            id: user.id,
            values: {
                systemRoles: [
                    ...(user.systemRoles || []),
                    ...systemRoles
                ]
            },
            clientMutationId: "mutation_" + Date.now()
        }
    })
    const data = extractResult(result)
    return data
}