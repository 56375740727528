import React from 'react'

import { Switch } from 'antd'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


import { MdDelete } from 'react-icons/md';

import './Bool.less';

export default function Bool(props) {
    const { value, onChange, params } = props

    const { fixedValue } = params;

    const noValue = value === undefined || value === null;

    function isChecked() {
        if(fixedValue !== undefined) {
            return fixedValue
        } else {
            return value === undefined ? null: value
        }
    }

    const checked = isChecked();

    return (
        <div className="bool-input" style={{
            opacity: noValue ? 0.6 : 1
        }}>
            <Switch
                size="small"
                checked={checked}
                onChange={onChange}
                disabled={fixedValue !== undefined}
                checkedChildren={<div style={{ fontSize: 8 }}><CheckOutlined /></div>}
                unCheckedChildren={checked === false ? <div style={{ fontSize: 8 }}><CloseOutlined /></div> : null}
            />
            {
                noValue ? null : (
                    <div className="remove-icon" onClick={_ => {
                        // set null
                        onChange(undefined)
                    }}>
                        <MdDelete />
                    </div>
                )
            }
        </div>
    )
}
