import React, { useEffect, useState } from 'react'

import ExtensionSettingEdit from './extension/ExtensionSettingEdit'

import LookupValueEdit from './LookupValueEdit';
import AdminPage from 'Client/js/pages/AdminPage';

import EntityDataPage from 'Client/js/pages/EntityDataPage';

import FormManangement from './forms/FormManagement';

const WrappedEntityDataPage =  (props) => {
    const { match_, facade, loadingComp: Loading } = props;
    const { params } = match_;
    const { id, entityKey } = params;

    const [ prepared, setPrepared ] = useState(false);
    useEffect(() => {   
        if(!facade.getEntity(entityKey)){
            setPrepared(false);
            (async () => {
                await facade.prepare([entityKey]);
                setPrepared(true);
            })();
        } else {
            if(!prepared) {
                setPrepared(true)
            }            
        }
    }, [ entityKey ])

    if(!prepared) {
        return <Loading />
    }
    
    const entity = facade.getEntity(entityKey);

    if(!entity) {
        return <div style={{padding: "1rem"}}>404: 数据实体【{entityKey}】没找到</div>
    }

    return (
        <EntityDataPage {...{
            id, entity,
            ...props
        }} />
    )
}

/// route definition
// name 目前被用在了 BreadcrumbSimple 里面
export const routeDef = {
    "/pages/:pageName/:id?/:tab?": {
        Comp: AdminPage
    },

    "/entities/:entityKey/records/:id/:tab?": {
        Comp: WrappedEntityDataPage
    },
    "/entities/:entityKey/:tab?": {
        Comp: WrappedEntityDataPage
    },
    "/ext/:key/settings": {
        Comp: (props) => {

            const { match } = props;
            const { params } = match
            const { key } = params
            // 这里的 key 是为了让 Component 强制 re-mount.
            return <ExtensionSettingEdit key={key} {...props} />
        }
    },
    "/lookup-values": {
        Comp: LookupValueEdit
    },
    "/dataforms/:name?": {
        Comp: FormManangement
    }
}