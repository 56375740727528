// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var CamlinternalOO = require("bs-platform/lib/js/camlinternalOO.js");
var Plate$BwaxAdmin = require("../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var AdminLayout$BwaxAdmin = require("./AdminLayout.bs.js");
var Facade_helper$BwaxAdmin = require("../../lib/bwax-js/ml/helper/facade_helper.bs.js");
var Lang_mod_json$BwaxAdmin = require("../../lib/bwax-js/ml/lang/mod/builtin/lang_mod_json.bs.js");
var Lang_entry_slim$BwaxAdmin = require("../../lib/bwax-js/ml/lang/lang_entry_slim.bs.js");
var Admin_page_common$BwaxAdmin = require("../../lib/bwax-ui/ml/admin/admin_page_common.bs.js");

function AdminPageComponentRenderer$AdminPageWithBwaxPrepared(Props) {
  var page = Props.page;
  var context = Props.context;
  var renderLoadingPage = Props.renderLoadingPage;
  var activeTabName = Props.activeTabName;
  var match = React.useState((function () {
          return false;
        }));
  var setReady = match[1];
  React.useEffect((function () {
          var all_entity_names = Curry._1(Plate$BwaxAdmin.List.to_array, Admin_page_common$BwaxAdmin.gather_entity_names(page));
          Facade_helper$BwaxAdmin.prepare(all_entity_names, context[/* bwax */4]).then((function (param) {
                  Curry._1(setReady, (function (param) {
                          return true;
                        }));
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), ([]));
  var renderLayout = function (layout) {
    switch (layout.tag | 0) {
      case /* Wide_and_narrow */0 :
          return React.createElement(AdminLayout$BwaxAdmin.WideAndNarrow.make, {
                      context: context,
                      wideList: layout[0],
                      narrowList: layout[1]
                    });
      case /* Full */1 :
          return React.createElement(AdminLayout$BwaxAdmin.Full.make, {
                      context: context,
                      topList: layout[0],
                      wideList: layout[1],
                      narrowList: layout[2]
                    });
      case /* Widget_and_bar */2 :
          return React.createElement(AdminLayout$BwaxAdmin.WidgetAndBar.make, {
                      context: context,
                      side: layout[0],
                      maybeWidth: layout[1],
                      widget: layout[2],
                      bar: layout[3]
                    });
      case /* Custom_layout */3 :
          return "Other layout";
      
    }
  };
  if (match[0]) {
    if (typeof page === "number") {
      return "Not implemented";
    } else {
      switch (page.tag | 0) {
        case /* Single */0 :
            return renderLayout(page[0]);
        case /* Multi */1 :
            var tabs = page[0];
            var tab = Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                    return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.head(tabs));
                  }), Plate$BwaxAdmin.$$Option.and_then((function (tabName) {
                        return Plate$BwaxAdmin.List.assoc(tabName, tabs);
                      }), activeTabName));
            if (tab !== undefined) {
              var match$1 = tab;
              if (match$1.tag) {
                return AdminLayout$BwaxAdmin.renderSingleWidget(context, match$1[0]);
              } else {
                return renderLayout(match$1[0]);
              }
            } else {
              return "No tab";
            }
        case /* OneWidget */2 :
            return AdminLayout$BwaxAdmin.renderSingleWidget(context, page[0]);
        
      }
    }
  } else {
    return Curry._1(renderLoadingPage, /* () */0);
  }
}

var AdminPageWithBwaxPrepared = {
  make: AdminPageComponentRenderer$AdminPageWithBwaxPrepared
};

var class_tables = [
  0,
  0,
  0
];

function AdminPageComponentRenderer(Props) {
  var domainEnv = Props.domainEnv;
  var queryRunner = Props.queryRunner;
  var queryCache = Props.queryCache;
  var dts = Props.dts;
  var env = Props.env;
  var params = Props.params;
  var loadingComp = Props.loadingComp;
  var activeTabName = Props.activeTabName;
  var context = Props.context;
  var match = React.useState((function () {
          return /* record */Caml_chrome_debugger.record([
                    "model",
                    "page",
                    "commands",
                    "msgs",
                    "error"
                  ], [
                    undefined,
                    undefined,
                    0,
                    0,
                    undefined
                  ]);
        }));
  var setState = match[1];
  var match$1 = Facade_helper$BwaxAdmin.getDefinition(context[/* bwax */4]);
  var data_type_dict = match$1[1];
  var entity_dict = match$1[0];
  var renderLoadingPage = function (param) {
    if (!class_tables[0]) {
      var $$class = CamlinternalOO.create_table(0);
      var env = CamlinternalOO.new_variable($$class, "");
      var env_init = function (env$1) {
        var self = CamlinternalOO.create_object_opt(0, $$class);
        self[env] = env$1;
        return self;
      };
      CamlinternalOO.init_class($$class);
      class_tables[0] = env_init;
    }
    return React.createElement(loadingComp, Curry._1(class_tables[0], 0));
  };
  var paramStr = Plate$BwaxAdmin.Str.join(";;", Plate$BwaxAdmin.List.map((function (prim) {
              return JSON.stringify(prim);
            }), Plate$BwaxAdmin.List.keep_map(Lang_mod_json$BwaxAdmin.any_value_to_plain_json, params)));
  React.useEffect((function () {
          Lang_entry_slim$BwaxAdmin.execute_init_to_model_with_multiparams(queryRunner, queryCache, domainEnv, entity_dict, data_type_dict, dts, env, params, (function (model, cmds, msgs, error) {
                  var page = Plate$BwaxAdmin.$$Option.map((function (m) {
                          return Admin_page_common$BwaxAdmin.make_page(Lang_entry_slim$BwaxAdmin.evaluate_view_v2(queryRunner, queryCache, domainEnv, entity_dict, data_type_dict, dts, env, m));
                        }), model);
                  return Curry._1(setState, (function (param) {
                                return /* record */Caml_chrome_debugger.record([
                                          "model",
                                          "page",
                                          "commands",
                                          "msgs",
                                          "error"
                                        ], [
                                          model,
                                          page,
                                          Curry._1(Plate$BwaxAdmin.List.from_array, cmds),
                                          Curry._1(Plate$BwaxAdmin.List.from_array, msgs),
                                          error
                                        ]);
                              }));
                }));
          return ;
        }), /* array */[paramStr]);
  var match$2 = match[0][/* page */1];
  if (match$2 !== undefined) {
    return React.createElement(AdminPageComponentRenderer$AdminPageWithBwaxPrepared, {
                page: match$2,
                context: context,
                renderLoadingPage: renderLoadingPage,
                activeTabName: activeTabName
              });
  } else {
    return renderLoadingPage(/* () */0);
  }
}

var make = AdminPageComponentRenderer;

exports.AdminPageWithBwaxPrepared = AdminPageWithBwaxPrepared;
exports.make = make;
/* react Not a pure module */
