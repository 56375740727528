
//// javascripts:

/// see https://stackoverflow.com/questions/623172/how-to-get-image-size-height-width-using-javascript
export function getImageSize(url) {
    return new Promise((resolve, reject) => {
        const img = new Image()
    
        // the following handler will fire on image's successful parsing
        img.onload = () => {
            const { naturalWidth: width, naturalHeight: height } = img
            resolve([width, height])
        }
    
        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            reject('There was some problem with the image.')
        }
        img.src = url
    })
}



export function drawImage(domEl, url, { x = 0, y = 0, width, height, sx = 0, sy = 0, swidth, sheight}) {

    return new Promise((resolve, reject) => {
        const img = new Image()
    
        // the following handler will fire on image's successful parsing
        img.onload = () => {
            const { naturalWidth: nwidth, naturalHeight: nheight } = img

            const ctx = domEl.getContext("2d");


            let sw = swidth || nwidth;
            let sh = sheight || nheight;

            let w = width || nwidth;
            let h = height || nheight;

            ctx.drawImage(img, sx, sy, sw, sh, x, y, w, h);
            
            resolve();
        }
    
        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            reject('There was some problem with the image.')
        }
        img.src = url
    })


    
}
