
import React, { useEffect, useState } from 'react'

import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';
import ExprCodeEditor from 'Client/js/codeEditor/ExprCodeEditor';

import Button from 'rsuite/Button';

import lang_entry from 'bwax/ml/lang/lang_entry.bs'

import RecordSelect from 'Client/js/ui/widgets/input/RecordSelect'

import { untag, unpack, tag } from 'bwax/lang/LangHelper'

import './InputParamsPanel.less';
import { QueryTargetContextProvider } from 'bwax-ui/legacy/store/QueryTargetContext';

export default function InputParamsPanel (props) {

    const { code, typingEnv, previewFacade } = props;

    const { src, compiled, previewData } = code;

    if (!compiled || !typingEnv || !previewFacade) {
        return null
    }

    const [n, defs, dts, entityNames] = unpack(compiled);
    
    const funcs = fragment_helper.get_func_types_as_js(defs);

    const initFuncType = funcs["init"];
    const viewFuncType = funcs["view"];
    
    if(!initFuncType || !viewFuncType) {
        return null
    }


    return (
        <InputParamsPanelGroup {...{
            initFuncType, viewFuncType, previewData,
            ...props,
        }} />
    )
}




function InputParamsPanelGroup({
    changeCode, typingEnv, onConfirm,
    previewFacade,

    initFuncType, viewFuncType, previewData,

    testRecord, setTestRecord, testRecordOptions

}) {

    const testInputParams = previewData && previewData.testInputParams ? untag(previewData.testInputParams) : {};
    // for init
    
    const initFuncAllParamTypes = fragment_helper.get_param_types_as_js(initFuncType);
    // 后面的参数是不需要的， 因为它通常是 prepared data
    // 除非是页面上只有 List (String, String) 的情况
    const initParamTypes = (() => {
        if (initFuncAllParamTypes.length === 1 && fragment_helper.get_term_name(initFuncAllParamTypes[0]) === "List") {
            return [initFuncAllParamTypes[0]]
        } else {
            return initFuncAllParamTypes.slice(0, initFuncAllParamTypes.length - 1);
        }
    })();

    // for view
   
    const viewFuncAllParamTypes = fragment_helper.get_param_types_as_js(viewFuncType);
    // 第一个参数不需要，因为它是 model
    const viewParamTypes = viewFuncAllParamTypes.slice(1);

    const panelSettings = [
        { funcName: "init", paramTypes: initParamTypes },
        { funcName: "view", paramTypes: viewParamTypes }
    ];

    // 
    const [editingParams, setEditingParams] = useState(_ => {
        return panelSettings.reduce((acc, { funcName, paramTypes }) => {

            const inputParams = testInputParams[funcName] || [];
            return {
                ...acc,
                [funcName]: paramTypes.map((pt, index) => {
                    if (inputParams[index]) {
                        const { ast, code } = inputParams[index];
                        const [_, dts] = typingEnv;
                        const [matched, _error_msg] = lang_entry.check_expr_with_expected_type(dts, pt, ast);
                        if (matched) {
                            return inputParams[index]
                        } else {
                            return { ast: undefined, code }
                        }
                    }
                    return null
                })
            }
        }, {})

    })

    function isEditingParamValid(editingParams) {

        return Object.values(editingParams).every(params => (
            params.every(p => p && p.ast)
        ))

    }

    function isInputValid() {
        return isEditingParamValid(editingParams) && (testRecordOptions ? !!(testRecord && testRecord.id) : true);
    }

    useEffect(() => {
        // if editing params are valid
        if(isEditingParamValid(editingParams)){
            changeCode({
                previewData: {
                    testInputParams: tag(editingParams)
                }
            })
        }

    }, [ editingParams ]);

    
    const panels = panelSettings.map (({funcName, paramTypes}) => {

        const params = editingParams[funcName] || [];

        return (
            <InnerInputParamsPanel key={funcName} {...{
                funcName, paramTypes, 
                params,
                typingEnv,
                updateParams: params => {
                    setEditingParams(prev => ({
                        ...prev,
                        [funcName]: params
                    }))
                }
                // setInputParams: ips => {
                //     // 更新 previewData 里的 init 的 input params
                //     // setInitInputParams(ips);
                //     changeCode({
                //         previewData: {
                //             testInputParams: tag({
                //                 funcName: ips
                //             })
                //         }
                //     })
                // }
            }} />
        )
    })


    return (
        <div className="input-params-panel-group">
            { panels }
            {
                testRecordOptions ? (
                    <div className="record-select-group">
                        <QueryTargetContextProvider queryTarget="data">
                            <RecordSelect
                                value={testRecord}
                                onChange={value => {
                                    setTestRecord(value);
                                }}
                                allDataTypes={previewFacade.dataTypes}
                                allEntities={previewFacade.entities}
                                params={{ ...testRecordOptions, pageSize: 6, isSandbox: true }}
                            />
                        </QueryTargetContextProvider>
                    </div>
                ) : null
            }
            <div className="button-group">
                <Button onClick={_ => {
                    onConfirm();
                }} disabled={!isInputValid()}>
                    确定
                </Button>
            </div>
        </div>
    )

}


function InnerInputParamsPanel({
    funcName, paramTypes,
    params, updateParams, typingEnv,
}) {

    if(paramTypes.length == 0) {
        return null
    }

    return (
        <div className="input-params-panel">
            <div className="func-params-group">
                <div className="caption">请输入 {funcName} 测试参数</div>
                <div className="input-panel">
                    {
                        paramTypes.map((expectedType, index) => {
                            const code = (() => {
                                if (params && params[index]) {
                                    const { code } = params[index];
                                    return code
                                } else {
                                    return "" // 或者根据输入类型提供默认值
                                }
                            })();
                            return (
                                <ExprCodeEditor key={index} {...{
                                    sourceCode: code, 
                                    expectedType, 
                                    typingEnv,
                                    onChange: (ast, code) => {
                                        if(ast) {
                                            let newParams = [...params];
                                            newParams[index] = { ast, code };
                                            updateParams(newParams);
                                        }
                                    },
                                    onError: (error, code) => {
                                        let newParams = [...params];
                                        newParams[index] = { ast: undefined, code };
                                        updateParams(newParams);
                                    }
                                }} />
                            )
                        })
                    }
                </div>
            </div>
   
        </div>
    )

}



