

export default function (entityKey, recordId) {

    const entityName = "数据记录评论"
    return {
        __query__: {
            data: {
                entityName,
                criteria: {
                    "实体KEY": entityKey,
                    "记录ID": recordId,
                },
                pagination: {
                    pageSize: 1000
                },
                type: "listAll",
                sortBy: {
                    创建时间: "DESC",
                }
            },
            __for__: {
                layout: {
                    styles: [
                        ["padding", "1rem"],
                        ["spacing", "1rem"],
                        ["width", "fill"],
                        "scroll"
                    ],
                    content: {
                        __concat__: [
                            {
                                type: "admin::Input_RichTextLite",
                                params: {
                                    editKey: "commentText",
                                    placeholder: "您有什么要说明或者补充的？",
                                }
                            },
                            {
                                type: "row",
                                params: {
                                    styles: [ 
                                        [ "spacing", "0.5rem" ]
                                    ],  
                                    content: [
                                        {
                                            type: "admin::Button",
                                            buttonType: "default",
                                            label: "撤销",
                                            size: "small",
                                            disabled: `\${isNull(input.dirtyValues.commentText)}`,
                                            onClick: {
                                                action: {
                                                    type: "ClearEditing",
                                                    keys: [ "commentText" ],
                                                }
                                            }
                                        },
                                        {
                                            type: "admin::Button",
                                            buttonType: "primary",
                                            label: "提交",
                                            size: "small",
                                            disabled: `\${isNull(input.dirtyValues.commentText)}`,
                                            onClick: {
                                                action: [{
                                                    type: "AddRecord",
                                                    entityName: "数据记录评论",
                                                    formData: {
                                                        "记录ID": recordId,
                                                        "内容": `\${input.dirtyValues.commentText)}`,
                                                    }
                                                }, {
                                                    type: "ClearEditing",
                                                    keys: [ "commentText"]
                                                }]
                                            }
                                        },
                                    ]
                                }
                            },
                            
                            {
                                __apply__: {
                                    list: `\${data}`,
                                    map: {
                                        type: "admin::Display_Comment",
                                        params: {
                                            __def__: {
                                                editKey: `comment-\${value.id}`,
                                                dirtyValue: {
                                                    __getValue__: {
                                                        target: `\${input.dirtyValues}`,
                                                        path: `\${editKey}`,
                                                    }
                                                },
                                                __for__: {
                                                    content: `\${value.内容}`,
                                                    author: `\${value.创建者.昵称}`,
                                                    avatar: `\${value.创建者.头像}`,
                                                    createdAt: `\${value.创建时间}`,
                                                    updatedAt: `\${value.修改时间}`,
                                                    attachments: `\${value.附件}`,
        
                                                    editable: `\${value.创建者.id == 当前用户.id}`,
                                                    editKey: `\${editKey}`,
                                                    initialValue: `\${value.内容}`,
                                                    lastEditedAt: `\${value.修改时间}`,
                                                    
                                                    //// TODO 这种组合应该有优化的空间
                                                    canSave: `\${!isNull(dirtyValue)}`,
                                                    dv: `\${dirtyValue}`,
                                                    dvs: `\${input.dirtyValues}`,
                                                    onSave: {
                                                        action: [{
                                                            type: "UpdateRecord",
                                                            entityName: "数据记录评论",
                                                            id: `\${value.id}`,
                                                            formData: {
                                                                "内容": `\${dirtyValue)}`,
                                                            }
                                                        }, {
                                                            type: "MarkSaved",
                                                            keys: [ `\${editKey}`]
                                                        }]
                                                    }
        
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            }
        }
    }
}