export default {
    multiplication: {
        Multi: (a, b) => a * b,
        Div: (a, b) => a / b,
        Mod: (a, b) => a % b,
    },

    addition: {
        Plus: (a, b) => a + b,
        Minus: (a, b) => {
            return a - b
        }
    },

    comparison: {
        GT: (a, b) => a > b,
        GE: (a, b) => a >= b,
        EQ: (a, b) => {
            return a === b
        },
        LE: (a, b) => a <= b,
        LT: (a, b) => a < b,
        NE: (a, b) => {
            return a !== b
        }
    },

    logic: {
        And: (a, b) => a && b,
        Or: (a, b) => a || b,
        Not: a => {
            return  !a
        }
    }
}

function areAllNumbers(...values) {
    return values.every(v => typeof (v) === 'number')
}

function areAllBooleans(...values) {
    return values.every(v => typeof(v) === 'boolean')
}


const joinOperands = (a, b, op) => {
    ///// multi operands:
    /// the first one could be an aggregation
    if (a && a[op]) {
        return {
            [op]: [...a[op], b]
        }
    }
    return {
        [op]: [a, b]
    }
}

export const jsonOps = {
    multiplication: {
        Multi: (a, b) => {
            if (areAllNumbers(a, b)) {
                return a * b
            }
            return joinOperands(a, b, 'Multi')
        },
        Div: (a, b) => {
            if (areAllNumbers(a, b)) {
                return a / b
            }
            return joinOperands(a, b, 'Div')
        },
        Mod: (a, b) => {
            if (areAllNumbers(a, b)) {
                return a % b
            }
            return joinOperands(a, b, 'Mod')
        },
    },

    addition: {
        Plus: (a, b) => {
            if (areAllNumbers(a, b)) {
                return a + b
            }
            ///// multi operands:
            /// the first one could be an aggregation
            return joinOperands(a, b, 'Plus')
        },
        Minus: (a, b) => {
            if (areAllNumbers(a, b)) {
                return a - b
            }
            return joinOperands(a, b, 'Minus')
        }
    },

    comparison: {
        GT: (a, b) => areAllNumbers(a, b) ? (a > b) : joinOperands(a, b, 'GT'),
        GE: (a, b) => areAllNumbers(a, b) ? (a >= b) : joinOperands(a, b, 'GE'),
        EQ: (a, b) => areAllNumbers(a, b) ? (a == b) : joinOperands(a, b, 'EQ'),
        LE: (a, b) => areAllNumbers(a, b) ? (a <= b) : joinOperands(a, b, 'LE'),
        LT: (a, b) => areAllNumbers(a, b) ? (a < b) : joinOperands(a, b, 'LT'),
        NE: (a, b) => areAllNumbers(a, b) ? (a != b) : joinOperands(a, b, 'NE'),
    },
    logic: {
        And: (a, b) => areAllBooleans(a, b) ? (a && b) : joinOperands(a, b, 'And'),
        Or: (a, b) => areAllBooleans(a, b) ? (a || b) : joinOperands(a, b, 'Or'),
        Not: a => areAllBooleans(a) ? !a : { Not: a }
    },

}

