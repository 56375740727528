
import React, { Suspense } from 'react'

const Inner = React.lazy(() => import ('../auxiliary/pdfReader/VirtualizedPDF'))

export default function (props) {
    return (
        <Suspense fallback={null}>
            <Inner {...props} />
        </Suspense>
    )
}