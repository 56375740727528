

import React, { useEffect, useState } from 'react'

import SelectInput from 'bwax-ui/components/inputs/SelectInput';
import Toggle from 'bwax-ui/components/inputs/Toggle';

import { useTrack } from 'bwax-ui/track';

export default function ChatModelInput({value, onChange}) {

    // gpt-3.5, gpt-4
    const track = useTrack();

    const gpt4 = "gpt-4";
    const gpt35 = "gpt-3.5";

    // 因为现在只有两种，所以改成 GPT-4 toggle 先
    const [gpt4Enabled, setGpt4Enabled] = useState(value == gpt4);

    useEffect(() => {
        if(gpt4Enabled && value !== gpt4) {
            setGpt4Enabled(false)
        } else if(!gpt4Enabled && value == gpt4) {
            setGpt4Enabled(true)
        }
    }, [value]);


    function doChange(v) {
        track("ui_chat_model_change", { value: v });
        onChange(v);
    }

    return (
        <Toggle label="GPT-4" value={gpt4Enabled} onChange={gpt4Enabled => {
            setGpt4Enabled(gpt4Enabled);
            if(gpt4Enabled && value !== gpt4) {
                doChange(gpt4)
            } else if(!gpt4Enabled && value == gpt4) {
                doChange(gpt35)
            }

        }} color={"violet"}></Toggle>
    )




    // const items = [
    //     { label: <span style={{ color: "var(--mauve11)"}}>普通模型</span>, value: "gpt-3.5" },
    //     { label: <span style={{ color: "var(--violet11)"}}>GPT-4</span>, value: "gpt-4", caption: "用量消耗会大幅增加" },
    // ]

    // return (
    //     <SelectInput
    //         selected={value || "gpt-3.5"}
    //         className="font-size-13"
    //         listBoxClassName={"font-size-13"}
    //         itemUnstyled={true}
    //         onSelect={v => {
    //             track("ui_chat_model_change", { value: v });
    //             onChange(v);
    //         }}
    //         items={items}
    //         style={{
    //             // width: "12rem"
    //         }}
    //     />
    // )
}
