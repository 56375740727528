import React from 'react'
import CommonLoading from 'Client/js/ui/widgets/loading/CommonLoading'
import ButtonLoading from 'Client/js/ui/widgets/loading/ButtonLoading'

export const getLoadingParams = () => ({
    "File": {
        width: 200,
        height: 22
    },
    "Image": {
        width: 120,
        height: 120
    },
    "RichText": {
        height: 100
    },
    "ShortText": {
        width: 100,
        height: 18
    },
    "Text": {
        width: 160,
        height: 18
    },
    "Number": {
        width: 80,
        height: 18
    },
    "Boolean": {
        width: 22,
        height: 18
    },
    "JSON": {
        height: 60
    },
    "JSONB": {
        height: 60
    },
    "Select": {
        width: 100
    },
    "Date": {
        width: 66,
        height: 18
    },
    "DateTime": {
        width: 102,
        height: 18
    },
    "Link": {
        width: 140,
        height: 20
    },
    "YQYLTypePosterSetting": {
        width: 200,
        height: 100
    }
})

const preDefinedLoading = {
    "Button": ButtonLoading
}

export function getFieldLoading(field, customParams = {}) {
    const fieldType = field && field.type
    const LoadingComponent = preDefinedLoading[fieldType]
    const loadingParam = customParams[fieldType] || getLoadingParams()[fieldType]
    if(LoadingComponent !== undefined) {
        return <LoadingComponent {...(loadingParam || {})} />
    }
    return <CommonLoading {...(loadingParam || {})} />
}