// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Dict$BwaxAdmin = require("../../../lib/bwax-js/ml/dict.bs.js");
var Json$BwaxAdmin = require("../../../lib/bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");
var Lang_typing_base$BwaxAdmin = require("../../../lib/bwax-js/ml/lang/lang_typing_base.bs.js");
var Pretty_printer_lang$BwaxAdmin = require("../../../lib/bwax-js/ml/printer/pretty_printer_lang.bs.js");
var Pretty_printer_common$BwaxAdmin = require("../../../lib/bwax-js/ml/printer/pretty_printer_common.bs.js");

function get_model_type_js(dts) {
  var type_node_js = function (name, t) {
    var get_children = function (param) {
      if (typeof param === "number" || param.tag !== /* Term_record */1) {
        return /* [] */0;
      } else {
        return Plate$BwaxAdmin.List.map((function (param) {
                      return type_node_js(param[0], param[1]);
                    }), param[0]);
      }
    };
    var children;
    if (typeof t === "number" || t.tag || t[0] !== "Maybe") {
      children = get_children(t);
    } else {
      var match = t[1];
      children = match && !match[1] ? get_children(match[0]) : get_children(t);
    }
    var t$1 = t;
    var name$1 = name;
    var children$1 = children;
    return Json$BwaxAdmin.insert_object("children", Json$BwaxAdmin.json_arr(children$1), Json$BwaxAdmin.insert_object("name", name$1, Json$BwaxAdmin.create_object("type", t$1)));
  };
  var match = Plate$BwaxAdmin.List.assoc("Model", dts);
  if (match !== undefined) {
    var match$1 = match;
    if (typeof match$1 === "number" || match$1.tag !== /* Alias */1) {
      return ;
    } else {
      return Caml_option.some(type_node_js("model", match$1[0][1]));
    }
  }
  
}

function is_maybe(param) {
  if (typeof param === "number" || param.tag || param[0] !== "Maybe") {
    return false;
  } else {
    return true;
  }
}

function get_all_msg_taggers(dts) {
  var match = Plate$BwaxAdmin.List.assoc("Msg", dts);
  var tmp;
  if (match !== undefined) {
    var match$1 = match;
    if (typeof match$1 === "number" || match$1.tag) {
      tmp = /* [] */0;
    } else {
      var match$2 = match$1[1];
      tmp = typeof match$2 === "number" || !match$2.tag ? /* [] */0 : Plate$BwaxAdmin.List.assoc_keys(match$2[0]);
    }
  } else {
    tmp = /* [] */0;
  }
  return Curry._1(Plate$BwaxAdmin.List.to_array, tmp);
}

function filter_msg_tagger_by_type(tenv, name_arr, tagger_type) {
  return $$Array.map((function (name) {
                var match = Dict$BwaxAdmin.$$String.get(name, tenv);
                if (match !== undefined) {
                  var match$1 = Lang_typing_base$BwaxAdmin.shorten(match[0][1]);
                  var matched;
                  switch (tagger_type) {
                    case "Msg" :
                        matched = typeof match$1 === "number" || match$1.tag || match$1[0] !== "Msg" ? false : true;
                        break;
                    case "StringMsg" :
                        if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "Arrow") {
                          matched = false;
                        } else {
                          var match$2 = match$1[1];
                          if (match$2) {
                            var match$3 = match$2[0];
                            if (typeof match$3 === "number" || match$3.tag || match$3[0] !== "String") {
                              matched = false;
                            } else {
                              var match$4 = match$2[1];
                              if (match$4) {
                                var match$5 = match$4[0];
                                matched = typeof match$5 === "number" || match$5.tag || !(match$5[0] === "Msg" && !match$4[1]) ? false : true;
                              } else {
                                matched = false;
                              }
                            }
                          } else {
                            matched = false;
                          }
                        }
                        break;
                    default:
                      matched = false;
                  }
                  return /* tuple */[
                          name,
                          matched
                        ];
                } else {
                  return /* tuple */[
                          name,
                          false
                        ];
                }
              }), name_arr);
}

function get_msg_tagger_setting(tenv, name) {
  var match = Dict$BwaxAdmin.$$String.get(name, tenv);
  if (match !== undefined) {
    return /* tuple */[
            name,
            Lang_typing_base$BwaxAdmin.get_all_argument_types(match[0][1])
          ];
  }
  
}

function generate_pattern_by_tagger(param) {
  var variables = Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.indexed_map((function (index, param) {
              return "v" + (
                      index > 0 ? String(index) : ""
                    );
            }), param[1]));
  return param[0] + (
          Plate$BwaxAdmin.Str.length(variables) > 0 ? " " + variables : ""
        );
}

function is_msg_type(mt) {
  if (mt !== undefined) {
    var match = Lang_typing_base$BwaxAdmin.get_final_return_type(Lang_typing_base$BwaxAdmin.shorten(mt));
    if (typeof match === "number" || match.tag || match[0] !== "Msg") {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function is_string_type(mt) {
  if (mt !== undefined) {
    var match = Lang_typing_base$BwaxAdmin.shorten(mt);
    if (typeof match === "number" || match.tag || match[0] !== "String") {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function find_descendant_node(f, lang_tree_node) {
  if (Curry._1(f, lang_tree_node)) {
    return lang_tree_node;
  } else {
    var _param = lang_tree_node[/* children */5];
    while(true) {
      var param = _param;
      if (param) {
        var match = find_descendant_node(f, param[0]);
        if (match !== undefined) {
          return match;
        } else {
          _param = param[1];
          continue ;
        }
      } else {
        return ;
      }
    };
  }
}

function get_msg_tagger_name(msg_code, msg_tree_js) {
  var msg_tree = Pretty_printer_common$BwaxAdmin.lang_tree_from_js(msg_code, /* [] */0, msg_tree_js);
  var iter = function (_n) {
    while(true) {
      var n = _n;
      var match = n[/* name */0];
      var match$1 = n[/* children */5];
      var exit = 0;
      switch (match) {
        case "ApplyExpr" :
            if (match$1) {
              var match$2 = match$1[1];
              if (match$2 && !match$2[1]) {
                _n = match$1[0];
                continue ;
              } else {
                return "";
              }
            } else {
              return "";
            }
        case "ConstructorExpr" :
            return n[/* src */1];
        case "IfExpr" :
        case "Lambda" :
            exit = 2;
            break;
        case "Bracket" :
        case "SingleExpr" :
            exit = 1;
            break;
        default:
          return "";
      }
      switch (exit) {
        case 1 :
            if (match$1 && !match$1[1]) {
              _n = match$1[0];
              continue ;
            } else {
              return "";
            }
        case 2 :
            var match$3 = Pretty_printer_lang$BwaxAdmin.extract_comments(n[/* children */5]);
            return Plate$BwaxAdmin.$$Option.with_default("", Plate$BwaxAdmin.$$Option.map(iter, Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(match$3[0]))));
        
      }
    };
  };
  return iter(msg_tree);
}

function get_update_function_tree(code_text, defs_tree_js) {
  var defs_tree = Pretty_printer_common$BwaxAdmin.lang_tree_from_js(code_text, /* [] */0, defs_tree_js);
  return Plate$BwaxAdmin.List.find((function (c) {
                if (c[/* name */0] === "FunctionDef") {
                  return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (h) {
                                    return h[/* src */1] === "update";
                                  }), Plate$BwaxAdmin.List.head(c[/* children */5])));
                } else {
                  return false;
                }
              }), Plate$BwaxAdmin.List.keep_map((function (c) {
                    if (c[/* name */0] === "Def") {
                      return Plate$BwaxAdmin.List.head(c[/* children */5]);
                    }
                    
                  }), defs_tree[/* children */5]));
}

function get_case_msg_of_expr(update_function_tree) {
  var match = Pretty_printer_lang$BwaxAdmin.extract_comments(update_function_tree[/* children */5]);
  return Plate$BwaxAdmin.$$Option.and_then((function (param) {
                return find_descendant_node((function (n) {
                              var match = n[/* name */0];
                              if (match === "CaseExpr") {
                                var match$1 = Pretty_printer_lang$BwaxAdmin.extract_comments(n[/* children */5]);
                                var children = match$1[0];
                                if (children) {
                                  var match$2 = children[1];
                                  if (match$2) {
                                    return match$2[0][/* src */1] === "msg";
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return false;
                                }
                              } else {
                                return false;
                              }
                            }), param);
              }), Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(match[0])));
}

function get_msg_update_branch(case_of_expr, msg_tagger_name) {
  var branches = Plate$BwaxAdmin.List.filter((function (n) {
            return n[/* name */0] === "CaseBranch";
          }))(case_of_expr[/* children */5]);
  return Plate$BwaxAdmin.List.find((function (n) {
                var p = Plate$BwaxAdmin.List.find((function (c) {
                        return c[/* name */0] === "Pattern";
                      }), n[/* children */5]);
                return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (n) {
                                  if (n[/* name */0] === "PatternConstructor") {
                                    return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (c) {
                                                      return c[/* src */1] === msg_tagger_name;
                                                    }), Plate$BwaxAdmin.List.head(n[/* children */5])));
                                  } else {
                                    return false;
                                  }
                                }), Plate$BwaxAdmin.$$Option.and_then((function (p) {
                                      return Plate$BwaxAdmin.List.head(p[/* children */5]);
                                    }), p)));
              }), branches);
}

function get_new_branch_position(case_of_expr) {
  var branches = Plate$BwaxAdmin.List.filter((function (n) {
            return n[/* name */0] === "CaseBranch";
          }))(case_of_expr[/* children */5]);
  var is_wildcard_branch = function (b) {
    return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (c) {
                      return c[/* src */1] === "_";
                    }), Plate$BwaxAdmin.List.find((function (c) {
                          return c[/* name */0] === "Pattern";
                        }), b[/* children */5])));
  };
  var match = Plate$BwaxAdmin.List.rev(branches);
  if (match) {
    var match$1 = match[1];
    var last = match[0];
    if (match$1) {
      if (is_wildcard_branch(last)) {
        return match$1[0][/* to_ */3];
      } else {
        return case_of_expr[/* to_ */3];
      }
    } else if (is_wildcard_branch(last)) {
      return last[/* from */2] - 1 | 0;
    } else {
      return case_of_expr[/* to_ */3];
    }
  } else {
    return case_of_expr[/* to_ */3];
  }
}

function get_last_expr(lang_tree) {
  var match = Pretty_printer_lang$BwaxAdmin.extract_comments(lang_tree[/* children */5]);
  return Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(match[0]));
}

function get_code(lang_tree) {
  return lang_tree[/* src */1];
}

function get_pattern_code(lang_tree) {
  return Plate$BwaxAdmin.$$Option.map((function (c) {
                return c[/* src */1];
              }), Plate$BwaxAdmin.List.find((function (c) {
                    return c[/* name */0] === "Pattern";
                  }), lang_tree[/* children */5]));
}

function get_last_expr_index(lang_tree) {
  var match = Pretty_printer_lang$BwaxAdmin.extract_comments(lang_tree[/* children */5]);
  return Plate$BwaxAdmin.$$Option.map((function (n) {
                return /* tuple */[
                        n[/* from */2],
                        n[/* to_ */3]
                      ];
              }), Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(match[0])));
}

function get_pattern_index(lang_tree) {
  return Plate$BwaxAdmin.$$Option.map((function (n) {
                return /* tuple */[
                        n[/* from */2],
                        n[/* to_ */3]
                      ];
              }), Plate$BwaxAdmin.List.find((function (c) {
                    return c[/* name */0] === "Pattern";
                  }), lang_tree[/* children */5]));
}

var the_update_return_type = Lang_typing_base$BwaxAdmin.wrap_term_2("Tuple", Lang_typing_base$BwaxAdmin.name_term("Model"), Lang_typing_base$BwaxAdmin.wrap_term("Cmd", Lang_typing_base$BwaxAdmin.name_term("Msg")));

exports.get_model_type_js = get_model_type_js;
exports.is_maybe = is_maybe;
exports.get_all_msg_taggers = get_all_msg_taggers;
exports.filter_msg_tagger_by_type = filter_msg_tagger_by_type;
exports.get_msg_tagger_setting = get_msg_tagger_setting;
exports.generate_pattern_by_tagger = generate_pattern_by_tagger;
exports.is_msg_type = is_msg_type;
exports.is_string_type = is_string_type;
exports.find_descendant_node = find_descendant_node;
exports.get_msg_tagger_name = get_msg_tagger_name;
exports.get_update_function_tree = get_update_function_tree;
exports.get_case_msg_of_expr = get_case_msg_of_expr;
exports.get_msg_update_branch = get_msg_update_branch;
exports.get_new_branch_position = get_new_branch_position;
exports.get_last_expr = get_last_expr;
exports.get_code = get_code;
exports.get_pattern_code = get_pattern_code;
exports.get_last_expr_index = get_last_expr_index;
exports.get_pattern_index = get_pattern_index;
exports.the_update_return_type = the_update_return_type;
/* the_update_return_type Not a pure module */
