import "./RecordForm.less"

import React, { useEffect, useState, useRef } from 'react'

import Form from 'Client/js/ui/widgets/Form';

import { conditionToExpr } from 'Client/js/builders/builderUtils'

import FieldDefaultValueInput from 'Client/js/ui/widgets/input/FieldDefaultValueInput';

import FieldOptionsInput from "./input/FieldOptionsInput";


import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';
import { getFieldLoading } from 'Client/js/builders/display/fieldLoadings';

import { getFieldInput } from 'Client/js/builders/input/fieldInputs';

import useQueriedValueWithoutState from 'bwax-ui/legacy/page/hooks/useQueriedValueWithoutState'

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import ValidationSourcing from 'Client/js/ui/widgets/ValidationSourcing'

import { make as Button } from 'Client/re/widgets/Button.bs'

function getDataDef({
    entityName, dataEntity, recordId, fieldsToUse, criteria,
    operations = [],
    deletionAllowed,
    allEntities, allDataTypes
}) {

    function buildDeletionDef() {
        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/545
        if (deletionAllowed === true) {
            return {
                condition: true,
                disabled: false
            }
        } else if (typeof (deletionAllowed) === 'object') {
            const op = deletionAllowed;
            return {
                condition: op.condition ? conditionToExpr("data", op.condition, dataEntity) : true,
                disabled: op.disabled ? conditionToExpr("data", op.disabled, dataEntity) : false,
            }

        } else {
            return {
                condition: false,
                disabled: false,
            }
        }
    }

    return {
        __query__: {
            data: {
                entityName,
                type: "findOne",
                criteria: {
                    id: recordId
                }
            },
            __for__: {
                operations: Object.keys(operations).map(key => {
                    const op = operations[key]
                    return {
                        condition: op.condition ? conditionToExpr("data", op.condition, dataEntity) : true,
                        disabled: op.disabled ? conditionToExpr("data", op.disabled, dataEntity) : false,
                    }
                }),
                deletion: buildDeletionDef(),
                values: fieldsToUse.map(f => {
                    // 针对每一个 field， 举个 FieldDisplay 得出所需的值
                    const { valueDef } = getFieldDisplay(f);
                    return valueDef("data", f, { allEntities, allDataTypes })
                })
            }
        }
    }
}


export default function RecordForm(props) {

    // 在这里，要构建一个完整的 Form，包括
    // 1) 可编辑 Form 本身
    // 2) 加载数据的能力
    // 3) 根据 operation 得到的功能性按钮

    const { params, ...env } = props;

    const {
        entityName,
        recordId,
        fieldsToUse,
        operations = [],
        deletionAllowed,
        editableCondition,
        actions,
        customFieldInputs = {}
    } = params;


    const { allDataTypes, allEntities, baseData, queryTarget } = env;

    const dataEntity = allEntities.find(e => e.name == entityName);

    //// ---- query record data

    // the def to load data:
    const dataDef = getDataDef({
        entityName, dataEntity,
        recordId,
        fieldsToUse,
        operations,
        deletionAllowed,
        allEntities, allDataTypes
    });


    // state begin
    const {
        value: data,
        reload,
    } = useQueriedValueWithoutState(dataDef, {
        allDataTypes,
        allEntities,
        baseData,
        roots: {
            "当前用户": {
                entityName: "用户"
            }
        },
        queryTarget,
    })
    // state end

    const dontHaveToReload = useRef(true);

    useEffect(() => {
        if (data !== undefined) {
            // console.log("是否要 reload", dontHaveToReload.current);
            if (dontHaveToReload.current === true) {
                dontHaveToReload.current = false; // 只 reload 一次就好了。
                setTimeout(() => {
                    reload()
                }, 1);
            }
        } else {
            /// 遇到了 undefined，这意味着当前是新家在的数据，不需要 reload
            dontHaveToReload.current = false
        }
    }, [data])

    //// editing state
    // 使用 name 而不是 cname
    const allKeys = fieldsToUse.map(f => f.name);

    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        rollback,
        clearEditing,
        reset
    } = EditingState.useEditingStateAsJs(allKeys, {}, {}, {}, [], "no-key", false)


    useEffect(() => {
        const initialValues = data ? fieldsToUse.reduce((acc, f, index) => {
            const { name } = f;

            const targetValue = data.values[index];

            return {
                ...acc,
                [name]: targetValue && (targetValue.value !== undefined) ? targetValue.value : targetValue
            }
        }, {}) : {};

        reset({}, initialValues, {})

    }, [JSON.stringify(data ? data.values : "")])

    const [validationValues, setValidationValues] = useState({});

    //// Design 里面一些硬写的 input -- TODO 应该要有更好的实现

    const onSave = async () => {
        await actions.updateRecord({
            entityName,
            id: recordId,
            formData: fieldsToUse.reduce((acc, f) => {
                const { name, updatable } = f
                if (!updatable) {
                    return acc
                }
                return {
                    ...acc,
                    [name]: dirtyValues[name]
                }
            }, {})
        });

        await actions.adminMessage({
            level: 'success',
            text: '修改成功',
        });

        reload();
    }

    const onCancel = () => {
        rollback(allKeys)
    }

    const items = fieldsToUse.map((f, index) => {

        const { cname, name, desc, updatable, config = {}, options, key } = f
        // multiple

        const editable = updatable === true && !config.editingProhibited

        function prepareValidation() {
            if (options && options.validation) {
                // 还有 list, dict, ...
                const { list } = options.validation
                function getLimitedValues() {
                    if(list) {
                        return list
                    } else {
                        return validationValues[f.name]
                    }
                }
                return {
                    limitedValues: getLimitedValues()
                }

            } else {
                return {}
            }
        };

        const specificTypeParams = {
            "Image": {
                width: 120,
                height: 120,
                processor: 'medium'
            }
        }


        const getDisplay = () => {

            if (data == undefined) {
                const loading = getFieldLoading(f)
                return loading
            }

            const value = data.values[index];

            const configParams = f.config && f.config.displayParams ? f.config.displayParams : {};

            const display = configParams.forwardTo ? getFieldDisplay({
                ...f,
                type: configParams.forwardTo
            }) : getFieldDisplay(f);

            const customParams = {
                ...(specificTypeParams[f.type] || {}),
                ...configParams
            }

            const Component = display.component;
            return <Component value={value} customParams={customParams} field={f} env={{
                ...env
            }} />
        }

        const customInput = (() => {

            // 这里是硬写的。 TODO 根据相关值编辑， 需要有一个更理想的解决方案
            if (name == "字段选项" && key == "options") {
                return (value, onChange, params, validated) => (
                    <FieldOptionsInput
                        {...{
                            dataType: validated.字段类型, 
                            value, onChange
                        }}
                    />
                )

                // }

            } else if (name == "默认值" && key == "defaultValue") {

                return (value, onChange, params, validated) => (
                    <FieldDefaultValueInput
                        params={{
                            ...params,
                            dataType: validated.字段类型,
                            dataOptions: validated.字段选项,
                            dataMultivalued: validated.多值,
                        }}
                        value={value}
                        onChange={onChange}
                    />
                );

            } else if (config.customInput) {
                return config.customInput
            } else {
                return customFieldInputs[cname || name]
            }


        })();


        function getInput() {

            const editKey = name;

            const inputParams = {
                ...(config && config.inputParams ? config.inputParams : {}),
                ...(prepareValidation())
            };

            const ips = {
                ...(specificTypeParams[f.type] || {}),
                ...inputParams
            };

            /// add value, onChange
            const value = editing[name] // fixedValues[name] || editing[name];

            const onChange = v => {
                if (v !== value) {
                    updateEditing({ [name]: v })
                }
            }

            let inputWidget = customInput || getFieldInput(
                (inputParams.forwardTo ? { type: inputParams.forwardTo } : f),
                editKey,
                {
                    initialValue: data && data[name],
                    allDataTypes, allEntities, dataEntity,
                    queryTarget
                },
            );


            return inputWidget(
                value,
                onChange,
                {
                    // ...((data[name] && typeof (data[name] == 'object')) ? data[name] : {}),
                    ...ips
                },
                validated,
            );

        }

        return {
            name: cname || name,
            tip: (desc && desc !== name) ? desc : null,
            display: getDisplay(),
            editKey: name,
            editable,
            field: f,
            input: getInput()
        }
    })

    let form = (
        <Form
            params={{
                onSave,
                onCancel,
                editableCondition,
                items
            }}
            {...env}
            editingState={{
                errors, validated, lastSaved, dirtyValues
            }}
        />
    )
    const allValidations = fieldsToUse.filter(f => f.options && f.options.validation).map(f => {
        return [f.options.validation, f]
    });


    let validationSourcing = (
        <ValidationSourcing
            params={{
                validations: allValidations,
                entityName,
                recordId,
                updateValidationValues: values => {

                    setValidationValues(prev => ({
                        ...prev,
                        ...values
                    }))
                },
                formValues: validated
            }}
            {...env}
        />
    )


    // handle operations

    const actionConverters = {
        "UpdateRecord": op => {
            return async () => {

                await actions.updateRecord({
                    entityName,
                    id: recordId,
                    formData: (op.updateValue || {})
                });

                await actions.adminMessage({
                    level: "success",
                    text: op.successMessage || '操作成功',
                });

                await reload({});
            }
        }
    }

    function barCtnr(leftBtns, rightBtns) {
        return (
            <div className="record-form-bar" style={{
                display: "flex",
                width: "100%",
                // flexDirection: "row-reverse",
                justifyContent: "space-between",
                padding: "0 0.5rem"
            }}>
                <div>
                    {leftBtns}
                </div>
                <div>
                    {rightBtns}
                </div>
            </div>
        )
    }

    function topBar() {

        if (data) {
            const buttons = Object.keys(operations).map((label, index) => {
                const { condition, disabled } = data.operations[index];

                if (condition == false) {
                    return null
                } else {

                    const op = operations[label];

                    const confirm =
                        op.confirm ?
                            (typeof (op.confirm) == "string" ? { title: op.confirm } : op.confirm) :
                            undefined;

                    const getAction = actionConverters[op.type] || (() => { return () => console.log("No Action") })

                    return (
                        <Button key={label} params={{
                            label,
                            buttonType: op.buttonType,
                            ghostButton: op.ghostButton,
                            disabled,
                            onClick: getAction(op),
                            confirm
                        }}  {...env} />
                    )

                }

            }).filter(x => !!x);

            return buttons.length > 0 ? barCtnr([], buttons) : null


        } else {
            return null
        }
    };

    function bottomBar() {
        if (data && data.deletion.condition) {
            let btn = (
                <Button key="no-key" params={{
                    label: "删除",
                    buttonType: "danger",
                    disabled: data.deletion.disabled,
                    onClick: async () => {

                        await actions.deleteRecord({
                            entityName,
                            id: recordId
                        });

                        await actions.goBack()

                    },
                    confirm: {
                        title: '您确定要删除当前数据？',
                    }

                }}  {...env} />
            )

            return barCtnr([], [btn]);

        } else {
            return null
        }

    }

    return <>{topBar()} {validationSourcing} {form} {bottomBar()} </>
}

