
import React from 'react'
import {EditorState} from 'draft-js'


export default function UndoButton (props) {
  const {setEditorState, getEditorState} = props
  return (
    <button className={"editor-button"}
        onClick={() => {
          setEditorState(EditorState.undo(getEditorState()))
        }}
        disabled={
          getEditorState().getUndoStack().isEmpty()
        }>
      <i className={'fa fa-undo'} aria-hidden="true"></i>
    </button>
  )

}
