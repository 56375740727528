
/// here we list all the built-in actions

import Alert from './Alert'
import Log from './Log'
import GoTo from './GoTo'
import GoBack from './GoBack'

import Refetch from './Refetch'
import ReloadUser from './ReloadUser'
import LoadMore from './LoadMore'
import UpdateState from './UpdateState'

import AddRecord from './AddRecord'
import AddRecordWithReturn from './AddRecordWithReturn'
import UpdateRecord from './UpdateRecord'
import DeleteRecord from './DeleteRecord'

import UploadFile from './UploadFile'

// ui related:
import ShowModalContent from './ShowModalContent'
import ShowModal from './ShowModal'
import HideModal from './HideModal'

/// edit related
import Rollback from './Rollback'
import ClearEditing from './ClearEditing'
import MarkSaved from './MarkSaved'

import UpdateEditing from './UpdateEditing'

/// special actions - 应该通过插件注册的方式放进来
// import InitiatePayment from './InitiatePayment'
// import InitiateXETOrder from './InitiateXETOrder'

/// 3rd actions
import DY_Track from './Track_DY'

import Track_DY from './Track_DY'
import Track_GDT from './Track_GDT'

// import EvalScript from './EvalScript'

export default {

    Alert,
    Log,
    GoTo,
    GoBack,

    Refetch,
    ReloadUser,
    LoadMore,
    UpdateState,

    AddRecord,
    AddRecordWithReturn,
    UpdateRecord,
    DeleteRecord,

    UploadFile,

    ShowModalContent,
    ShowModal,
    HideModal,

    Rollback,
    ClearEditing,
    MarkSaved,
    UpdateEditing,

    // InitiatePayment,
    // InitiateXETOrder,

    DY_Track,

    Track_DY,
    Track_GDT,

    // EvalScript
    
}
