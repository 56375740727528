// @flow

import { node } from 'prop-types';
import { cloneElement, Component, useEffect, useRef } from 'react';

import useResizeObserver from '@react-hook/resize-observer'

/**
 * class DOMRectReadOnly {
    +x: number;
    +y: number;
    +width: number;
    +height: number;
    +top: number;
    +right: number;
    +bottom: number;
    +left: number;
    }
 */

let findDOMNodeWarningsSet = null;
if (process.env.NODE_ENV !== 'production') {
    findDOMNodeWarningsSet = new Set();
}

function ItemMeasurer (props) {

    const { item, index } = props;

    const nodeRef = useRef(null);

    const lastIndexRef = useRef(undefined);

    function _refSetter (ref ) {
        // console.log("Set ref", ref instanceof HTMLElement, ref);
        if(ref instanceof HTMLElement) {
            nodeRef.current = ref;
        } else {
            // 一般是 null，如果是其他类型就有点问题：
            if(ref !== null) {
                console.warn("ItemMeasure: unrecognized ref", ref);
            } else {
                nodeRef.current = null;
            }
        }
    }

    function _measureItem (props, isCommitPhase) {
        const {
            direction,
            handleNewMeasurements,
            index,
            size: oldSize,
        } = props;

        const node = nodeRef.current;        

        if (
            node &&
            node.ownerDocument &&
            node.ownerDocument.defaultView &&
            node instanceof node.ownerDocument.defaultView.HTMLElement
        ) {
            const newSize =
                direction === 'horizontal'
                    ? Math.ceil(node.offsetWidth)
                    : Math.ceil(node.offsetHeight);

            // console.log(">>> measured", oldSize , newSize, index, isCommitPhase);
            if (oldSize !== newSize || true) {
                handleNewMeasurements(index, newSize, isCommitPhase);
            }
        }
    }


    useEffect(() => {
        if(nodeRef.current) {
            // console.log("Node Ref ready")
            _measureItem(props, true);
        }

    }, [ nodeRef.current ])

    useEffect(() => {
        if(lastIndexRef.current !== undefined && lastIndexRef.current !== index) {
            // console.log("Index Changed", index, nodeRef.current);
            _measureItem(props, false);
        }
        lastIndexRef.current = index;
    }, [ index ])

    useResizeObserver(nodeRef, _ => {
        _measureItem(props, false);
    });


    return cloneElement(item, {
        measureRef: _refSetter,
    });

}



class ItemMeasurerOLD extends Component {
    _didProvideValidRef = false;
    _node = null;
    _resizeObserver = null;

    componentDidMount() {
        if (process.env.NODE_ENV !== 'production') {
            if (!this._didProvideValidRef) {
                const { item } = this.props;

                const displayName =
                    item && item.type
                        ? item.type.displayName || item.type.name || '(unknown)'
                        : '(unknown)';

                if (!findDOMNodeWarningsSet.has(displayName)) {
                    findDOMNodeWarningsSet.add(displayName);

                    console.warn(
                        'DynamicSizeList item renderers should attach a ref to the topmost HTMLElement they render. ' +
                        `The item renderer "${displayName}" did not attach a ref to a valid HTMLElement. ` +
                        'findDOMNode() will be used as a fallback, but is slower and more error prone than using a ref.\n\n' +
                        'Learn more about ref forwarding: ' +
                        'https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-to-dom-components'
                    );
                }
            }
        }
        console.log("On Mounted new row");

        // Force sync measure for the initial mount.
        // This is necessary to support the DynamicSizeList layout logic.
        this._measureItem(true, this.props);

        if (typeof ResizeObserver !== 'undefined') {
            // Watch for resizes due to changed content,
            // Or changes in the size of the parent container.
            this._resizeObserver = new ResizeObserver(this._onResize);
            if (this._node !== null) {
                this._resizeObserver.observe(this._node);
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        if(this.props.index !== nextProps.index) {
            console.log("Index has changed")
            this._measureItem(false, nextProps);
        }
    }

    componentWillUnmount() {
        if (this._resizeObserver !== null) {
            this._resizeObserver.disconnect();
        }
    }

    render() {
        return cloneElement(this.props.item, {
            measureRef: this._refSetter,
        });
    }

    _measureItem = (isCommitPhase, props) => {
        const {
            direction,
            handleNewMeasurements,
            index,
            size: oldSize,
        } = props;

        const node = this._node;        

        if (
            node &&
            node.ownerDocument &&
            node.ownerDocument.defaultView &&
            node instanceof node.ownerDocument.defaultView.HTMLElement
        ) {
            const newSize =
                direction === 'horizontal'
                    ? Math.ceil(node.offsetWidth)
                    : Math.ceil(node.offsetHeight);

            // console.log(">>> measured", oldSize , newSize, index, isCommitPhase);
            if (oldSize !== newSize || true) {
                handleNewMeasurements(index, newSize, isCommitPhase);
            }
        }
    };

    _refSetter = (ref) => {
        if (this._resizeObserver !== null && this._node !== null) {
            this._resizeObserver.unobserve(this._node);
        }

        if (ref instanceof HTMLElement) {
            this._didProvideValidRef = true;
            this._node = ref;
        } else if (ref !== null) {
            // this._node = findDOMNode(ref);
        }

        if (this._resizeObserver !== null && this._node !== null) {
            this._resizeObserver.observe(this._node);
        }
    };

    _onResize = () => {
        console.log("IM RESIZED");
        this._measureItem(false, this.props);
    };
}

export default ItemMeasurerOLD;

// export default ItemMeasurer;