

import React from 'react'

import { Card } from 'antd'

import { Icon } from '@ant-design/compatible';

export default function CardWithIcon ({ params }) {
    const { 
        iconType, /// "ant-design-icon"
        size,
        onClick,
        height,
    } = params

    const defaultSize = { width: 160, height: 180 }
    const sizeStyle = {
        'wide': { width: 280, height: 120 },
    }[size] || defaultSize

    const cardStyle = { ...sizeStyle, margin: 8, height } // TODO could be customized

    return (
        <Card hoverable={true} style={{
            ...cardStyle,
            backgroundColor: "#F0F3F5",
        }} onClick={ onClick }>
            <div style={{
                display:'flex', flexDirection: 'column', height: '100%',
                alignItems: "center", justifyContent: "center",
            }}>
                <Icon type={iconType} style={{
                    fontSize: 48, opacity: 0.4
                }}/>
            </div>
        </Card>
    )
}


