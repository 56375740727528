import React, { useEffect } from 'react'
import { format as formatFn } from 'date-fns'

import DatePickerInput from 'Client/js/components/form/baseInputs/DatePickerInput'
const defaultFormat = "yyyy-MM-dd"

export default function DatePicker(props) {
    
    const { value, onChange, params } = props
    const { multivalued, format = defaultFormat } = params


    // useEffect(() => {
    //     console.log("Value changed", value);
    // }, [ value ])
    
    const transToDate = (value) => {

        const toDate = (value) => {
            if(value && typeof(value) === 'string') {
                return new Date(value)
            } else {
                return value
            }
        }
        const d = multivalued ? (value || []).map(n => toDate(n)) : toDate(value);
        return d || null;
    }

    
    const transToDateStr = (value) => {
        

        const getValue = (value) => {
            if(!value || typeof(value) === 'string') {
                return value
            } else {
                return formatFn(value, format);
            }
        }

        return multivalued ? (value || []).map(n => getValue(n)) : getValue(value)
    }

    return (
        <DatePickerInput
            value={transToDate(value)}
            onChange={value => onChange(transToDateStr(value))}
            multivalued={multivalued}
            format={format}
        />
    )
}
