// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var JsonUtils$BwaxAdmin = require("../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var BuildRecordPane = require("Client/js/builders/buildRecordPane");
var BuildRecordListTableView = require("Client/js/builders/buildRecordListTableView");

function decode(v) {
  return Belt_Option.flatMap(Js_json.decodeObject(v), (function (dict) {
                var match = Belt_Option.flatMap(Js_dict.get(dict, "type"), Js_json.decodeString);
                if (match !== undefined) {
                  switch (match) {
                    case "AnyWidget" :
                        return /* AnyWidget */Caml_chrome_debugger.variant("AnyWidget", 2, [Belt_Option.getWithDefault(Js_dict.get(dict, "content"), { })]);
                    case "RecordEdit" :
                        return /* RecordEdit */Caml_chrome_debugger.variant("RecordEdit", 1, [
                                  JsonUtils$BwaxAdmin.Decoder.decodeFromEntryFlat(dict, "recordId", Js_json.decodeString),
                                  JsonUtils$BwaxAdmin.Decoder.decodeFromEntryFlat(dict, "entityName", Js_json.decodeString),
                                  Belt_Option.getWithDefault(Js_dict.get(dict, "options"), { })
                                ]);
                    case "RecordList" :
                        return /* RecordList */Caml_chrome_debugger.variant("RecordList", 0, [
                                  JsonUtils$BwaxAdmin.Decoder.decodeFromEntryFlat(dict, "entityName", Js_json.decodeString),
                                  Belt_Option.getWithDefault(Js_dict.get(dict, "options"), { })
                                ]);
                    default:
                      return ;
                  }
                }
                
              }));
}

function decodeFromString(str) {
  var maybeJson;
  try {
    maybeJson = Caml_option.some(JSON.parse(str));
  }
  catch (exn){
    console.log("解析 JSON 出错", str);
    maybeJson = undefined;
  }
  return Belt_Option.flatMap(maybeJson, decode);
}

var defaultRecordEditJson = {
  type: "RecordEdit",
  options: {
    excludedFields: /* array */[]
  }
};

var defaultDetailTabJsons = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "基本信息",
          defaultRecordEditJson
        ],
        /* [] */0
      ]));

var defaultRecordListJson = {
  type: "RecordList",
  options: {
    criteria: { },
    sortBy: Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "创建时间",
              "DESC"
            ],
            /* [] */0
          ])),
    excludedFields: /* array */[
      "修改者",
      "创建者",
      "修改时间"
    ],
    fieldColumns: undefined,
    operations: undefined,
    allowedActions: /* array */[{
        type: "AddRecord",
        excludedFields: /* array */[],
        defaultValues: { },
        fixedValues: { },
        initialValues: { }
      }]
  }
};

var defaultListTabJsons = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "列表",
          defaultRecordListJson
        ],
        /* [] */0
      ]));

function buildDef(maybeId, entityName, tabPage, allEntities, allDataTypes, queryTarget) {
  var env = {
    allEntities: allEntities,
    allDataTypes: allDataTypes,
    queryTarget: queryTarget
  };
  switch (tabPage.tag | 0) {
    case /* RecordList */0 :
        var actualEntityName = Belt_Option.getWithDefault(tabPage[0], entityName);
        return Caml_option.some(BuildRecordListTableView.default(actualEntityName, entityName, tabPage[1], env));
    case /* RecordEdit */1 :
        var options = tabPage[2];
        var maybeEntityName = tabPage[1];
        var maybeRecordId = tabPage[0];
        return Belt_Option.map(maybeId, (function (recordId) {
                      var actualRecordId = Belt_Option.getWithDefault(maybeRecordId, recordId);
                      var actualEntityName = Belt_Option.getWithDefault(maybeEntityName, entityName);
                      return BuildRecordPane.default(actualRecordId, actualEntityName, options, env);
                    }));
    case /* AnyWidget */2 :
        var viewDef = {
          layout: {
            content: tabPage[0]
          }
        };
        var inner = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "__for__",
                  viewDef
                ],
                /* [] */0
              ]));
        return Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "__query__",
                            inner
                          ],
                          /* [] */0
                        ])));
    
  }
}

var TabPage = {
  decode: decode,
  decodeFromString: decodeFromString,
  defaultRecordEditJson: defaultRecordEditJson,
  defaultDetailTabJsons: defaultDetailTabJsons,
  defaultRecordListJson: defaultRecordListJson,
  defaultListTabJsons: defaultListTabJsons,
  buildDef: buildDef
};

exports.TabPage = TabPage;
/* defaultDetailTabJsons Not a pure module */
