// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var QrCode$BwaxAdmin = require("./QrCode.bs.js");
var DomUtils$BwaxAdmin = require("../utils/DomUtils.bs.js");
var JsonUtils$BwaxAdmin = require("../../../bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryRunner$BwaxAdmin = require("../../../bwax-js/re/binding/QueryRunner.bs.js");
var SetTokenCookie = require("bwax-ui/setTokenCookie");
var DataLoaderContext = require("bwax-ui/store/DataLoaderContext");

var CurrentUser = { };

var mutGenToken = "\n  mutation GenLoginTokenMutation (\n      $input: GenLoginTokenInput!\n    ) {\n       genLoginToken(input: $input) {\n         qrCodeUrl\n         loginToken {\n           id\n           token\n           status\n         }\n       }\n    }\n";

function genToken(dlc) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(QueryRunner$BwaxAdmin.toEnv(dlc)), mutGenToken, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "input",
                            {
                              clientMutationId: new Date()
                            }
                          ],
                          /* [] */0
                        ]))), undefined, /* () */0).then((function (text) {
                var json = JsonUtils$BwaxAdmin.parse(text);
                var qrCodeUrl = Belt_Option.flatMap(Belt_Option.flatMap(json, (function (j) {
                            return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          "data",
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              "genLoginToken",
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  "qrCodeUrl",
                                                  /* [] */0
                                                ])
                                            ])
                                        ]));
                          })), Js_json.decodeString);
                var token = Belt_Option.flatMap(Belt_Option.flatMap(json, (function (j) {
                            return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          "data",
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              "genLoginToken",
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  "loginToken",
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      "token",
                                                      /* [] */0
                                                    ])
                                                ])
                                            ])
                                        ]));
                          })), Js_json.decodeString);
                return Promise.resolve(/* tuple */[
                            token,
                            qrCodeUrl
                          ]);
              }));
}

var qLoginTokenStatus = "\n    query($token: String!) {\n      loginTokenStatus(token: $token)\n    }\n";

function checkTokenStatus(token, dlc) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(dlc), qLoginTokenStatus, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "token",
                            token
                          ],
                          /* [] */0
                        ]))), undefined, /* () */0).then((function (text) {
                return Promise.resolve(Belt_Option.flatMap(Belt_Option.flatMap(JsonUtils$BwaxAdmin.parse(text), (function (j) {
                                      return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "data",
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        "loginTokenStatus",
                                                        /* [] */0
                                                      ])
                                                  ]));
                                    })), Js_json.decodeString));
              }));
}

var mutAuthWithLoginToken = "\n  mutation AuthWithLoginTokenMutation (\n      $input: AuthWithLoginTokenInput!\n    ) {\n       authWithLoginToken(input: $input) {\n         authSession {\n           id\n           authUser {\n             id\n             systemRoles\n           }\n           token\n         }\n       }\n    }\n";

function autWithLoginToken(token, dlc) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(dlc), mutAuthWithLoginToken, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "input",
                            {
                              clientMutationId: new Date(),
                              token: token
                            }
                          ],
                          /* [] */0
                        ]))), undefined, /* () */0).then((function (text) {
                return Promise.resolve(Belt_Option.flatMap(Belt_Option.flatMap(JsonUtils$BwaxAdmin.parse(text), (function (j) {
                                      return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "data",
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        "authWithLoginToken",
                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            "authSession",
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                "token",
                                                                /* [] */0
                                                              ])
                                                          ])
                                                      ])
                                                  ]));
                                    })), Js_json.decodeString));
              }));
}

function getProtocolAndHost(param) {
  return Belt_Option.flatMap(DomUtils$BwaxAdmin.$$document, (function (doc) {
                var configuredOne = Belt_Option.flatMap(Belt_Option.flatMap(doc.userenv, (function (ue) {
                            return ue.mobileHosts;
                          })), (function (mh) {
                        return Belt_Array.get(mh, 0);
                      }));
                var maybeHost = configuredOne !== undefined ? configuredOne : Belt_Option.flatMap(doc.location, (function (loc) {
                          return loc.host;
                        }));
                var maybeProtocol = "https:";
                if (maybeProtocol !== undefined && maybeHost !== undefined) {
                  return /* tuple */[
                          maybeProtocol,
                          maybeHost
                        ];
                }
                
              }));
}

function LoginQrCodeAlt(Props) {
  var size = Props.size;
  var onSuccess = Props.onSuccess;
  var match = Props.onCountDown;
  var onCountDown = match !== undefined ? match : (function (param) {
        return /* () */0;
      });
  var loadingElement = Props.loadingElement;
  var match$1 = Props.reloadUser;
  var reloadUser = match$1 !== undefined ? match$1 : (function (param) {
        return Promise.resolve(null);
      });
  var match$2 = Props.showCountDown;
  var showCountDown = match$2 !== undefined ? match$2 : false;
  var match$3 = Props.tip;
  var tip = match$3 !== undefined ? match$3 : "请用手机微信扫码登录 ";
  var dlc = React.useContext(DataLoaderContext.default);
  var env = QueryRunner$BwaxAdmin.toEnv(dlc);
  var match$4 = React.useState((function () {
          return ;
        }));
  var setLoginToken = match$4[1];
  var loginToken = match$4[0];
  var match$5 = React.useState((function () {
          return 90;
        }));
  var setCount = match$5[1];
  var count = match$5[0];
  var maybeProtocolAndHost = getProtocolAndHost(/* () */0);
  var confirmLoginSuccess = function (sessionToken) {
    SetTokenCookie.default(sessionToken, Caml_option.some(dlc));
    Curry._1(reloadUser, /* () */0).then((function (user) {
            Curry._1(onSuccess, user);
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var updateLoginToken = function (param) {
    genToken(env).then((function (param) {
            var qrCodeUrl = param[1];
            var token = param[0];
            Curry._1(setLoginToken, (function (param) {
                    return Belt_Option.map(token, (function (t) {
                                  return /* tuple */[
                                          t,
                                          qrCodeUrl
                                        ];
                                }));
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var countRef = React.useRef(count);
  countRef.current = count;
  React.useEffect((function () {
          updateLoginToken(/* () */0);
          var timerId = setInterval((function (param) {
                  var count = countRef.current;
                  Curry._1(onCountDown, count - 1 | 0);
                  if (count > 1) {
                    return Curry._1(setCount, (function (prev) {
                                  return prev - 1 | 0;
                                }));
                  } else {
                    Curry._1(setCount, (function (param) {
                            return 90;
                          }));
                    return updateLoginToken(/* () */0);
                  }
                }), 1000);
          return (function (param) {
                    clearInterval(timerId);
                    return /* () */0;
                  });
        }), ([]));
  React.useEffect((function () {
          if (loginToken !== undefined) {
            var t = loginToken[0];
            var timerId = setInterval((function (param) {
                    checkTokenStatus(t, env).then((function (s) {
                            if (Caml_obj.caml_equal(s, "Confirmed")) {
                              autWithLoginToken(t, env).then((function (mst) {
                                      if (mst !== undefined) {
                                        confirmLoginSuccess(mst);
                                      }
                                      return Promise.resolve(/* () */0);
                                    }));
                            }
                            return Promise.resolve(/* () */0);
                          }));
                    return /* () */0;
                  }), 1500);
            return (function (param) {
                      clearInterval(timerId);
                      return /* () */0;
                    });
          }
          
        }), /* array */[loginToken]);
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.marginTop(Css.rem(1.25)),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.alignSelf(/* center */98248149),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.opacity(0.8),
                  /* [] */0
                ])
            ])
        ]));
  var renderCountDown = showCountDown ? React.createElement("div", {
          className: className
        }, tip + (" " + (String(count) + " s"))) : null;
  if (loginToken !== undefined) {
    if (maybeProtocolAndHost !== undefined) {
      var match$6 = maybeProtocolAndHost;
      var match$7 = loginToken;
      var maybeQrCode = match$7[1];
      var url;
      if (maybeQrCode !== undefined) {
        url = maybeQrCode;
      } else {
        var match$8 = dlc.sandbox;
        var prefix = (
          match$8 ? "sandbox/" : ""
        ) + (Plate$BwaxAdmin.$$Option.with_default("", Plate$BwaxAdmin.$$Option.map((function (c) {
                      return "-/" + (c + "/");
                    }), dlc.tenantCode)) + "ext/auth/confirm-token");
        url = "" + (String(match$6[0]) + ("//" + (String(match$6[1]) + ("/" + (String(prefix) + ("/" + (String(match$7[0]) + "")))))));
      }
      return React.createElement(React.Fragment, undefined, React.createElement(QrCode$BwaxAdmin.make, {
                      value: url,
                      size: size
                    }), renderCountDown);
    } else {
      return "未能获取地址信息";
    }
  } else {
    return Plate$BwaxAdmin.$$Option.with_default(null, loadingElement);
  }
}

var $$document = DomUtils$BwaxAdmin.$$document;

var init_count = 90;

var make = LoginQrCodeAlt;

exports.CurrentUser = CurrentUser;
exports.mutGenToken = mutGenToken;
exports.genToken = genToken;
exports.qLoginTokenStatus = qLoginTokenStatus;
exports.checkTokenStatus = checkTokenStatus;
exports.mutAuthWithLoginToken = mutAuthWithLoginToken;
exports.autWithLoginToken = autWithLoginToken;
exports.$$document = $$document;
exports.getProtocolAndHost = getProtocolAndHost;
exports.init_count = init_count;
exports.make = make;
/* Css Not a pure module */
