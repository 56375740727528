
import React from 'react'

import './AttributesEditor.less'

import { ExpressionInputIcon } from './lean/ExpressionInput';

// import "@arco-design/web-react/dist/css/arco.css";
import "../../../node_modules/@arco-design/web-react/dist/css/index.less";

import {
    InputNumber, Input, Radio
} from '@arco-design/web-react';

import ColorInput from './inputs/ColorInput';
import ImageSelect from './inputs/ImageSelect';

import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';

import exprToValue, { valueToExpr, valueForInput } from './lean/exprToValue';

import MsgSelect from './lean/MsgSelect';

const RadioGroup = Radio.Group;

const TextArea = Input.TextArea;

export default function AttributesEditor(props) {

    const { attributes, value: values = {}, onChange, blockId, blockNode, extendedOptions } = props;

    function renderForm() {
        return (
            <div className="attributes-form">
                {
                    Object.keys(attributes || {}).map(name => {

                        // expression 相关的要做成插件 TODO
                        const attribute = attributes[name];
                        const {
                            label, type, applicableIf,
                            useExpression = false,
                            ...otherProps
                        } = attribute;
                        const Input = inputs[type];
                        if (!type || !Input) {
                            return null
                        }

                        if (applicableIf && !applicableIf(values, blockNode)) {
                            return null
                        }

                        const value = values[name];

                        const expressionBound = value && value.$expr;
                        const expectedType = fragment_helper.type_meta_to_term(attribute) // { type options required multivalued ... }

                        return (
                            <div className="attribute-item" key={name}>
                                <div className="label">{label || name}</div>
                                <div className={"input" + (useExpression ? " with-expression" : "")}>
                                    <Input
                                        value={valueForInput(value, { attribute })}
                                        onChange={v => {
                                            onChange({ ...values, [name]: v })
                                        }}
                                        {...otherProps}
                                        disabled={expressionBound}
                                        extendedOptions={extendedOptions}
                                    />
                                    {useExpression ? (
                                        <ExpressionInputIcon className={expressionBound ? "active" : ""}
                                            expectedType={expectedType}
                                            typingEnv={extendedOptions.viewTypingEnv}
                                            value={valueToExpr(value, { attribute })}  
                                            onChange={expr => {
                                                const newValue = exprToValue(expr, { preValue: value });
                                                onChange({
                                                    ...values,
                                                    [name]: newValue
                                                })
                                            }}
                                        />
                                    ) : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    return (
        <div className="attributes-editor">
            {/* <div style={{ fontWeight: "bold", marginBottom: 8 }}>
                {blockId}
            </div> */}
            {renderForm()}
        </div>
    )
}

const inputs = {
    Number: ({ value, onChange, disabled }) => {
        return (
            <InputNumber
                placeholder='Please enter'
                className="lc-attribute-input"
                size={"small"}
                value={value}
                onChange={v => {
                    onChange(v)
                }}
                disabled={disabled}
            />
        )
    },

    ShortText: ({ value, onChange, disabled }) => {
        return (
            <Input
                placeholder='Please enter'
                size={"small"}
                value={value}
                allowClear
                onChange={v => {
                    onChange(v)
                }}
                disabled={disabled}
            />
        )
    },
    Color: ({ value, onChange, disabled }) => {
        return (
            // <Input value={value} allowClear placeholder='Please enter' onChange={onChange} size={"small"} />
            <ColorInput value={value} onChange={onChange} disabled={disabled} />

        )
    },
    Text: ({ value, onChange, disabled }) => {
        // 换成富文本编辑器?
        return (
            <TextArea style={{ fontSize: 12 }}
                value={value} onChange={onChange} size={"small"} disabled={disabled} />
        )
    },
    

    Select: ({ value, onChange, options, disabled }) => {
        return (
            <RadioGroup
                type='button'
                size="mini"
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                {options.map(o => <Radio value={o} key={o}>{o}</Radio>)}
            </RadioGroup>
        )
    },
    Image: ({ value, onChange, disabled }) => {
        return (
            <ImageSelect value={value} onChange={onChange} disabled={disabled} />
        )
    },

    Msg: ({ value, onChange, disabled, extendedOptions }) => {

        return (
            <MsgSelect {...{
                value, onChange, disabled, type: "Msg",
                extendedOptions
            }} />
        )
    },

    StringMsg: ({ value, onChange, disabled, extendedOptions }) => {
        return (
            <MsgSelect {...{
                value, onChange, disabled, type: "StringMsg",
                extendedOptions
            }} />
        )
    },

}




