
import React from 'react';
import createInlineStyleButton from './util/createInlineStyleButton';

export default createInlineStyleButton({
  style: 'UNDERLINE',
  children: (
    <i className={"fa fa-underline"} aria-hidden="true"></i>
  ),
});
