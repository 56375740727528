
// This is not a widget
import React, { useState, useEffect, useRef } from 'react'

import Message from 'Client/js/ui/Message';

import { MdCheck } from 'react-icons/md';

import { BiUndo } from "react-icons/bi";

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'
import ValidationSourcing from 'Client/js/ui/widgets/ValidationSourcing'
import { getFieldInput } from 'Client/js/builders/input/fieldInputs';

import "./SmallEditForm.less"

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary';
import IconButton from 'Client/js/components/IconButton';

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

export default React.memo(props => {
    return <SmallEditForm {...props} />
}, (prev, current) => {

    const fieldNames = props => props.fields.map(f => f.name).join(";");
    // const initialValueStr = props => JSON.stringify(props.initialValues);
    return (
        prev.id == current.id
        && prev.dataEntity.name == current.dataEntity.name
        && fieldNames(prev) == fieldNames(current)
        // initialValueStr(prev) == initialValueStr(current)
    )
})

// export default SmallEditForm;

function SmallEditForm(props) {
    const {
        id,
        fields,
        initialValues,
        closeEdit,

        reload,
        dataEntity,

        bwax,
        allDataTypes,
        allEntities,

    } = props

    const allKeys = fields.map(f => f.name);

    const t0 = performance.now();

    const [ cachedEditing, setCachedEditing ] = useStateWithLocalCache("small-form-cached-" + id + ":" + allKeys.join(";"), {});

    //state
    const [validationValues, setValidationValues] = useState({})
    const {
        errors,
        validated,
        editing,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, {}, {}, [], "no-key", false)

    const [ initialized, setInitialized ] = useState(false);
    useEffect(() => {        
        if(cachedEditing && Object.keys(cachedEditing).length === 1) {
            updateEditing(cachedEditing);        
            setInitialized(true)
        } else {
            setInitialized(true)
        }   
    }, []);

    
    if(!initialized) {
        // 这是为了保证初始的 input value 就包含了 cachedEditing 得到的是
        return null
    }


    function prepareValidation(f) {
        const { options } = f;
        if (options && options.validation) {
            // 还有 list, dict, ...
            const { list } = options.validation
            function getLimitedValues() {
                if (list) {
                    return list
                } else {
                    // ref 和 lookup 都通过 ValidationSourcing 提供
                    return validationValues[f.name]
                }
            }
            return {
                limitedValues: getLimitedValues()
            }

        } else {
            return {}
        }
    }

    function getInput(f) {
        const { name, config = {} } = f;
        const editKey = name;
        const inputParams = {
            ...(config && config.inputParams ? config.inputParams : {}),
            ...(prepareValidation(f))
        };

        const specificTypeParams = {
            "Image": {
                width: 120,
                height: 120,
                processor: 'medium'
            }
        }

        const ips = {
            ...(specificTypeParams[f.type] || {}),
            ...inputParams,

            // 如果是 Text，给予 rows = 12 // 为什么要这样？暂时改回 2
            ...(f.type == "Text" ? { rows: 4 } : {})

        };

        /// add value, onChange
        const value = editing[name] // fixedValues[name] || editing[name];

        const onChange = v => {
            if (v !== value) {
                updateEditing({ [name]: v })

                setCachedEditing(prev => {
                    return {
                        ...prev, [name]: v
                    }
                })

            }
        }
        const inputWidget = getFieldInput(
            f,
            editKey,
            {
                initialValue: initialValues[editKey],
                allDataTypes, allEntities, dataEntity,
                queryTarget: "data"
            },
        );

        return inputWidget(
            value,
            onChange,
            // params                    
            ips
        );

    }


    const isDirty = Object.keys(dirtyValues).filter(
        k => dirtyValues[k] !== undefined
    ).length > 0

    async function confirmEdit() {
        const mutationObj = {
            entityName: dataEntity.name,
            formData: dirtyValues,
            id,
            fieldPaths: []
        }

        const [result, error] = await bwax.update(mutationObj);

        Message.success("修改成功");

        markSaved()

        setCachedEditing({});

        reload()
        closeEdit && closeEdit()
    }

    const allValidations = fields.filter(f => f.options && f.options.validation).map(f => {
        return [f.options.validation, f]
    })

    const validationSourcing = (
        <ValidationSourcing
            params={{
                validations: allValidations,
                entityName: dataEntity.name,
                recordId: id,
                updateValidationValues: values => {
                    setValidationValues(prev => ({
                        ...prev,
                        ...values
                    }))
                },
                formValues: {}
            }}
            allEntities={allEntities}
            allDataTypes={allDataTypes}
        />
    )

    function renderForm() {
        if (fields.length === 1) {
            const field = fields[0];
            return getInput(field);
        } else {
            return fields.map(f => {
                return (
                    <div key={f.name} className="field-item">
                        <div className="item-label">
                            {f.name}
                        </div>
                        <div className="item-Value">
                            {getInput(f)}
                        </div>
                    </div>
                )
            })
        }
    }

    const t1 = performance.now();
    // console.log("SMALL EDIT FORM", allKeys, t1 - t0);

    const minWidth = (() => {
        const minWidthSettings = {
            "Text": 320,
        };

        return fields.reduce((acc, current) => {
            const mw = minWidthSettings[current.type];
            if (mw && (acc === undefined || mw > acc)) {
                return mw
            }
        }, undefined)

    })();


    return (
        <ErrorBoundary>
            <div className={"small-edit-form" + (fields.length === 1 ? " single" : "")}>
                <div className="edit-box-body" style={{
                    minWidth
                }}>
                    {renderForm()}
                </div>
                <div className="edit-box-actions">
                    {/* <Icon
                        type="check"
                        className={`confirm-button ${isDirty ? "" : "disabled"}`}
                        onClick={confirmEdit}
                    /> */}

                    <IconButton {...{
                        icon: <BiUndo />,
                        onClick: _ => {
                            // rollback
                            rollback(allKeys);

                            setCachedEditing({});

                        },
                        disabled: !isDirty,
                        style: {
                            // color: "#39CC87"
                        }
                    }} />
                    <IconButton {...{
                        icon: <MdCheck />,
                        onClick: confirmEdit,
                        disabled: !isDirty,
                        style: {
                            color: "#39CC87"
                        }
                    }} />

                </div>
                {validationSourcing}
            </div>
        </ErrorBoundary>
    )

}
