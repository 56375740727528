

import React from 'react'

import FavoritesButton from '../topbar/FavoritesButton';
import NotificationsButton from '../topbar/NotificationsButton';

import UserBox from '../topbar/UserBox';

import TopBar from '../topbar/TopBar';

import { markNav } from 'Client/js/breadcrumb/breadcrumbHelper'

const defaultCode = "default";

export default function DesignTopBar(props) {
    const { currentUser, baseSettings, history, location, applications, currentApplication, compact } = props;

    const currentCode = currentApplication ? currentApplication.code.toLowerCase(): defaultCode;

    const onApplicationSelected = application => {

        const { code } = application;

        const path = location.pathname.replace(/\/design\/[^\/]+\//, `/design/${code.toLowerCase()}/`);
        // 不应该保留原来的 路径后缀，应该直接进入该 app 的默认页面：

        // const path = `/design/${code.toLowerCase()}`;

        history.push(path);
    };

    const rightActions = (
        <>
            <NotificationsButton />
            <FavoritesButton />
        </>
    )

    return (
        <TopBar {...{ 
            currentUser, baseSettings, rightActions, 
            applications, currentApplication, onApplicationSelected,
            size: "small",

            compact,

            noLogo: true,

            alwaysDisplayAppPicker: true,

            style: {
                // backgroundColor: "#313030",
                // backgroundColor: "#646566",
                // backgroundColor: "#3C3D3D",
                // backgroundColor: "#585A5F",

                backgroundColor: "#425A7A",

                // backgroundColor: "#646566",
                // backgroundColor: "#22517A",
                // backgroundColor: "#173652",
                
                boxShadow: "0 1px 4px 0px rgb(0 0 0 / 25%)"
            },

            appSelectorItems: [
                {
                    label: "管理应用",
                    onClick: () => {
                        const path = `/design/${currentCode}/applications`;
                        markNav([{
                            icon: "appstore",
                            name: "应用管理",
                            path
                        }])
                        history.push(path);
                    }
                }
            ]

        }} ></TopBar>
    )
}