// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Filter_condition_helper$BwaxAdmin = require("../../../ml/helpers/filter_condition_helper.bs.js");

function Display_FilterCondition(Props) {
  var params = Props.params;
  Props.allEntities;
  Props.allDataTypes;
  Props.renderWidget;
  return React.createElement("pre", undefined, Filter_condition_helper$BwaxAdmin.to_string(params.fieldItems, params.value));
}

var make = Display_FilterCondition;

exports.make = make;
/* react Not a pure module */
