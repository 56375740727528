
import React, { useState, useRef } from 'react';

import useOutsideClickAware from './useOutsideClickAware';

import './OperationButtonBox.less';

export default function OperationButtonBox(props) {
    const { buttonContent, panel, className } = props

    const [panelVisible, setPanelVisible] = useState(false);
    const panelRef = useRef(null);
    useOutsideClickAware(panelRef, () => {
        setPanelVisible(false);
    })

    return (
        <div className={"operation-button-box " + (className || "")} ref={panelRef}>
            <div className={"top-bar-button" + (panelVisible ? " active" : "")} onClick={_ => {
                setPanelVisible(prev => !prev);
            }}>
                {buttonContent}
            </div>
            {panelVisible ?
                <div className="button-panel">
                    {panel}
                </div>
                : null}
        </div>
    )
};