


import { parser } from 'bwax/lang/ore.parser';

import { pretty_print_lang } from "./pretty_printer_lang.bs";

import extractParseTree from './extractParseTree';

export default function prettyPrintLang (width, sourceCode, top) {

    const t0 = performance.now();
    const p = parser.configure({
        top
    })
    const parseTree = p.parse(sourceCode).topNode;
    const t1 = performance.now();

    // console.log(">>> Parsing uses", t1 - t0, "ms");
    // TODO if there's any error, stop;

    const tree = extractParseTree(parseTree, sourceCode);

    const t2 = performance.now();
    // console.log(">>> Extracting uses", t2 - t1, "ms", tree);

    const formatted = pretty_print_lang(width, sourceCode, tree);

    const t3 = performance.now();

    // console.log(">>>  Formatting uses", t3 - t2,  "ms");

    return formatted;

}