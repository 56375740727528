
import React, { Suspense, useEffect, useState } from 'react';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'
import pageEntrySlim from 'bwax-ui/ml/page_entry_slim.bs'

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary';
import { ExecutionContextProvider } from 'bwax-ui/page/ExecutionContext';
import { DataLoaderProvider } from 'bwax-ui/store/DataLoaderContext'
import { QueryTargetContextProvider } from 'bwax-ui/legacy/store/QueryTargetContext'

import PageComponentEnvContainer from 'bwax-ui/page/PageComponentEnvContainer';

import { DataPromiseProvider } from 'bwax-ui/store/RenderWithData'

import { hashCode } from 'bwax/utils'

import setupPageEnv from 'bwax-ui/page/setupPageEnv';

import { Helmet } from 'react-helmet-async';

const promiseHolder = {};

export default function PageComponentPreview(props) {

    const { 
        initParamExprs, viewParamExprs, facade, compiledStr, dlc, runtimeProfile, renderLoading, route_to 
    } = props

    async function buildParams(initParamExprs, env, toFinalParams) {
        // 要在对象的 env 里面 evaluate 参数：       
        const paramArr = initParamExprs.map(
            p => lang_entry_slim.evaluate(env, undefined, p)
        );

        return toFinalParams(paramArr);
    }

    const [ paramsValue, setParamsValue ] = useState(undefined);

    useEffect(() => {
        (async () => {
            const [ ast, dts, entity_dict, data_type_dict, baseEnv ] = await setupPageEnv(compiledStr, facade);
            const [ env, _ ] = pageEntrySlim.evaluate_defs(baseEnv, ast);

            const initParams = await buildParams(initParamExprs, env, paramArr => {
                // 如果 paramArr 的数量为 0, 则转为 Unit
                // 如果 paramArr 的数量为 1, 则 paramArr[0]
                // 如果 paramArr 的数量为 2，则用 tuple 把 paramArr 装起来
                if (paramArr.length === 0) {            
                    return lang_entry_slim.unit;
    
                } else if (paramArr.length === 1) {
                    return paramArr[0]
                } else {
                    let param = lang_entry.pack_tuple_from_array(paramArr);
                    return param
                }                
            });

            const maybeViewParams = await buildParams(viewParamExprs, env, paramArr => {
                if(paramArr.length === 0) {
                    return undefined
                } else if(paramArr.length === 1) {
                    return paramArr[0]
                } else {
                    let param = lang_entry.pack_tuple_from_array(paramArr);
                    return param
                }
            })

            setParamsValue([initParams, maybeViewParams]);
        })();

    }, [initParamExprs, viewParamExprs, JSON.stringify(runtimeProfile)]);

    useEffect(() => {
        if(typeof(document) !== 'undefined') {
            document.body.className = "preview-page";
        }
    }, [])


    if(paramsValue === undefined) {
        return renderLoading();
    }

    const pageComponent = {
        compiled: compiledStr,
    };

    const { userenv } = dlc;

    const domainEnv = {
        protocol: "https",
        mobileHost: userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        tenantCode: dlc.tenantCode,
    }

    const currentURL = "/testing-component"
    const currentURLPath = "/testing-component";

    const webEnv = {
        protocol: "https",
        host: userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        isIOS: userenv.isIOS,
        isWeChat: userenv.isWeChat,
        isWxWork: userenv.isWxWork,
        isMobile: userenv.isMobile,
        currentURL,
        currentURLPath,
        originalURL: userenv.originalURL,
        tenantCode: dlc.tenantCode,
    }

    const viewEnv = { routeTo: route_to, webEnv, domainEnv };

    const testingComponentName = runtimeProfile.dataName + "_" + hashCode(JSON.stringify(compiledStr))

    const [initParams, maybeViewParams] = paramsValue;

    const rendered = (
        <QueryTargetContextProvider queryTarget="data">
            <Helmet>
                <title>{`[组件] ${runtimeProfile.dataName} `}</title>
            </Helmet>
            <ErrorBoundary envKey={testingComponentName}>
                <ExecutionContextProvider designMode={true} routeTo={route_to}>
                    <DataLoaderProvider {...dlc}>
                        <DataPromiseProvider holder={promiseHolder}>
                            <Suspense fallback={null}>
                                <PageComponentEnvContainer {...{
                                    pageComponent, 
                                    initParams, maybeViewParams,
                                    facade, viewEnv,
                                    dlc,
                                    componentName: testingComponentName,
                                    isLazy: true,
                                }} />
                            </Suspense>
                        </DataPromiseProvider>
                    </DataLoaderProvider>
                </ExecutionContextProvider>
            </ErrorBoundary>
        </QueryTargetContextProvider>
    );
    return rendered;


}