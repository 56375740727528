import React, { useState, useContext, useEffect } from 'react'

import { Upload, Row, Col, Progress } from 'antd'
import Loading from 'Client/js/components/Loading'
import { make as IconButton } from "Client/re/components/IconButton.bs"

import Message from 'Client/js/ui/Message';

import { Icon } from '@ant-design/compatible';

import './FileUploader.less'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';

export default function FileUploader(props) {

    const { value, onChange, isPublic, uploadFor, maximum, multivalued } = props

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ tenantCode, sessionToken, sandbox })

    const givenFiles = Array.isArray(value) ? value : (value ? [value] : []);
    /// states
    const [status, setStatus] = useState('initial')
    const [percent, setPercent] = useState(0)
    const [files, setFiles] = useState(givenFiles)
    /// states ends

    const givenFilesStr = JSON.stringify(givenFiles);

    useEffect(() => {
        if(givenFilesStr !== JSON.stringify(files)) {
            setFiles(givenFiles);
        }
 
    }, [givenFilesStr])

    const statusView = {
        'uploading': (
            <div className="ant-upload-hint" style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Loading />
            </div>
        ),
        'uploadFail': (
            <p className="ant-upload-drag-icon">
                上传失败，请重新上传
            </p>
        ),
        'initial': (
            <p className="ant-upload-drag-icon">
                {
                    multivalued ?
                        <Icon type="file-add" style={{ fontSize: 42 }} /> :
                        <Icon type="inbox" />
                }
            </p>
        )
    }


    function readFile(file) {
        const isPDF = !!file.contentType.match(/pdf/)
        if (isPDF) {
            const encodeURL = encodeURIComponent(file.url)
            const targetURL = `/pdf/reader?file=${encodeURL}`
            window.open(targetURL, "_blank")
        } else {
            var link = document.createElement('a')
            link.href = file.url
            link.download = file.title
            link.dispatchEvent(new MouseEvent('click'))
        }
    }

    function delFile(idx) {
        setFiles(multivalued ? (files || []).filter((n, i) => i !== idx) : [])
        onChange(multivalued ? (value || []).filter((n, i) => i !== idx) : null)
        setPercent(0)
    }

    function updateUploadPercent(loaded, total) {
        if (total > 0) {

            //client side to server side
            const ctsPercent = parseInt(loaded / total) * 50

            if (ctsPercent === 50) {
                //simulate server side to server side
                let percentInterval = 10
                function dummyProcess() {
                    let dummyPercent = 0
                    setPercent((prevPercent) => {
                        if (prevPercent >= 100) {
                            dummyPercent = 100
                            return 100
                        }
                        const startPercent = prevPercent < 50 ? 50 : prevPercent
                        percentInterval = (100 - startPercent) / 10
                        return startPercent + percentInterval
                    })
                    if (dummyPercent !== 100) {
                        setTimeout(dummyProcess, 50)
                    }
                }
                dummyProcess()
            } else {
                setPercent(ctsPercent)
            }
        }
    }

    async function doUpload(file) {
        if (file) {
            setStatus('uploading')
            setPercent(0)
            const updateFiles = multivalued ? [
                ...files,
                { title: file.name }
            ] : [{ title: file.name }]
            setFiles(updateFiles)

            const result = await UploadFile({ queryRunner })({
                file,
                isPublic,
                uploadFor,
                onUploadProgress: (evt) => {
                    updateUploadPercent(evt.loaded, evt.total)
                }
            })

            if (!result) {
                setStatus('uploadFail')
                Message.error("上传出错")
            } else {
                setStatus('initial')
                setPercent(100)
                const { id, ...changeAttachment } = result
                const updateValues = multivalued ? [
                    ...(value || []),
                    changeAttachment
                ] : changeAttachment
                onChange(updateValues)
                setFiles(Array.isArray(updateValues) ? updateValues : [updateValues])
            }
        }
    }

    return (
        <div className="file-uploader">
            <Upload.Dragger
                disabled={files.length === maximum}
                showUploadList={false}
                beforeUpload={file => {
                    doUpload(file)
                    return false
                }}
            >
                {statusView[status]}
            </Upload.Dragger>
            <div className="file-list">
                {
                    (files || []).map((file, idx) => {
                        return (
                            <div key={idx}>
                                <Row
                                    key={idx}
                                    type="flex"
                                    justify="space-between"
                                    className="file-list-item"
                                >
                                    <Col
                                        className="file-name-col"
                                        onClick={() => readFile(file)}
                                    >
                                        <Icon
                                            type="paper-clip"
                                            className="paper-clip-icon"
                                        />
                                        <span className="file-name">
                                            {file.title}
                                        </span>
                                    </Col>
                                    <Col className="file-close-col">
                                        <IconButton
                                            icon="close"
                                            className="close-icon"
                                            onClick={() => delFile(idx)} />
                                    </Col>
                                </Row>
                                {
                                    percent > 0 && percent <= 100 && !file.url ?
                                        <div className="file-upload-progress">
                                            <Progress
                                                percent={percent}
                                                strokeColor="#1890ff"
                                                strokeWidth={4}
                                                showInfo={false} />
                                        </div> : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}