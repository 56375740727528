// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json$BwaxAdmin = require("../../../../bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_mod_json$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/builtin/lang_mod_json.bs.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var WechatConfig = require("bwax-ui/legacy/page/WechatConfig");

var module_name = "WeChat";

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        "Cmd." + (name + ": Invalid Arguments")
      ];
}

var externals_vals_000 = /* tuple */[
  "configShare",
  Runtime_common$BwaxAdmin.pack_func(1, (function (args) {
          return Runtime_common$BwaxAdmin.pack_tagged("Cmd_WeChat_configShare", args);
        }))
];

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    /* [] */0
  ]);

function handle_command(context, param, on_trunk_msgs, on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || !(cmd.tag === /* V_tagged */6 && cmd[0] === "Cmd_WeChat_configShare")) {
    return false;
  } else {
    var match = cmd[1];
    if (match && !match[1]) {
      var options = Plate$BwaxAdmin.$$Option.with_default(Json$BwaxAdmin.json_object(/* [] */0), Lang_mod_json$BwaxAdmin.any_value_to_plain_json(match[0]));
      WechatConfig.setupWechatShare(options, undefined);
      return true;
    } else {
      return false;
    }
  }
}

var command_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    handle_command,
    /* [] */0
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\n\nmodule WeChat;\n\n--- 微信 API （主要是调用 JS SDK)\n\n-- 如果是传入，则使用默认的（从 document 里面取）\nconfigShare: \n    ~ { title: String, desc: String\n      , imageURL: String, shareURL: String\n      , hidden: Bool\n      }\n  -> Cmd msg = external;\n\n";

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.handle_command = handle_command;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
