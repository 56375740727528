// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Set$BwaxAdmin = require("./set.bs.js");
var Dict$BwaxAdmin = require("./dict.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxAdmin = require("./plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var Invalid_argument = Caml_exceptions.create("Graph-BwaxAdmin.Invalid_argument");

function $$null(is_directed) {
  return /* tuple */[
          Dict$BwaxAdmin.$$String.empty(/* () */0),
          is_directed
        ];
}

function set_vertex(key, info, graph) {
  var vertices = graph[0];
  var match = Dict$BwaxAdmin.$$String.get(key, vertices);
  var vertex = match !== undefined ? /* record */Caml_chrome_debugger.record([
        "info",
        "nexts"
      ], [
        info,
        match[/* nexts */1]
      ]) : /* record */Caml_chrome_debugger.record([
        "info",
        "nexts"
      ], [
        info,
        0
      ]);
  return /* tuple */[
          Dict$BwaxAdmin.$$String.insert(key, vertex, vertices),
          graph[1]
        ];
}

function no_vertex(fname, key) {
  throw [
        Invalid_argument,
        fname,
        "cannot find vertex for `" + (key + "`")
      ];
}

function _update_vertex_nexts(key, nexts, graph) {
  var vertices = graph[0];
  var match = Dict$BwaxAdmin.$$String.get(key, vertices);
  if (match !== undefined) {
    var vertex_000 = /* info */match[/* info */0];
    var vertex = /* record */Caml_chrome_debugger.record([
        "info",
        "nexts"
      ], [
        vertex_000,
        nexts
      ]);
    return /* tuple */[
            Dict$BwaxAdmin.$$String.insert(key, vertex, vertices),
            graph[1]
          ];
  } else {
    return no_vertex("_update_vertex_nexts", key);
  }
}

function add_edge(from_key, to_key, graph) {
  var vertices = graph[0];
  var from_v = Dict$BwaxAdmin.$$String.get(from_key, vertices);
  var to_v = Dict$BwaxAdmin.$$String.get(to_key, vertices);
  if (from_v !== undefined) {
    if (to_v !== undefined) {
      var from_nexts = from_v[/* nexts */1];
      if (graph[1]) {
        var new_from_nexts = Plate$BwaxAdmin.List.unique(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                to_key,
                from_nexts
              ]));
        return _update_vertex_nexts(from_key, new_from_nexts, graph);
      } else {
        var new_from_nexts$1 = Plate$BwaxAdmin.List.unique(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                to_key,
                from_nexts
              ]));
        var new_to_nexts = Plate$BwaxAdmin.List.unique(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                from_key,
                to_v[/* nexts */1]
              ]));
        return _update_vertex_nexts(to_key, new_to_nexts, _update_vertex_nexts(from_key, new_from_nexts$1, graph));
      }
    } else {
      return no_vertex("add_edge", to_key);
    }
  } else {
    return no_vertex("add_edge", from_key);
  }
}

function remove_edge(from_key, to_key, graph) {
  var vertices = graph[0];
  var from_v = Dict$BwaxAdmin.$$String.get(from_key, vertices);
  var to_v = Dict$BwaxAdmin.$$String.get(to_key, vertices);
  if (from_v !== undefined) {
    if (to_v !== undefined) {
      var from_nexts = from_v[/* nexts */1];
      if (graph[1]) {
        var new_from_nexts = Plate$BwaxAdmin.List.filter((function (n) {
                  return n !== to_key;
                }))(from_nexts);
        return _update_vertex_nexts(from_key, new_from_nexts, graph);
      } else {
        var new_from_nexts$1 = Plate$BwaxAdmin.List.filter((function (n) {
                  return n !== to_key;
                }))(from_nexts);
        var new_to_nexts = Plate$BwaxAdmin.List.filter((function (n) {
                  return n !== from_key;
                }))(to_v[/* nexts */1]);
        return _update_vertex_nexts(to_key, new_to_nexts, _update_vertex_nexts(from_key, new_from_nexts$1, graph));
      }
    } else {
      return no_vertex("remove_edge", to_key);
    }
  } else {
    return no_vertex("remove_edge", from_key);
  }
}

var get_vertices = Plate$BwaxAdmin.fst;

function get_vertex(key, graph) {
  return Dict$BwaxAdmin.$$String.get(key, Plate$BwaxAdmin.fst(graph));
}

var is_directed = Plate$BwaxAdmin.snd;

function get_edges(graph) {
  return Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map((function (param) {
                    var k = param[0];
                    return Plate$BwaxAdmin.List.map((function (to_key) {
                                  return /* tuple */[
                                          k,
                                          to_key
                                        ];
                                }), param[1][/* nexts */1]);
                  }), Dict$BwaxAdmin.$$String.to_list(Plate$BwaxAdmin.fst(graph))));
}

function explore(pre_visit, post_visit, graph, visited, key, vertex) {
  var vertices = graph[0];
  var next_visited = Set$BwaxAdmin.$$String.insert(key, visited);
  var info = vertex[/* info */0];
  Curry._2(pre_visit, key, info);
  var final_visited = Plate$BwaxAdmin.List.foldl((function (acc_visited, param) {
          var k = param[0];
          if (Set$BwaxAdmin.$$String.has(k, acc_visited)) {
            return acc_visited;
          } else {
            var param$1 = acc_visited;
            var param$2 = k;
            var param$3 = param[1];
            return explore(pre_visit, post_visit, graph, param$1, param$2, param$3);
          }
        }), next_visited, Plate$BwaxAdmin.List.keep_map((function (to_k) {
              return Plate$BwaxAdmin.$$Option.map((function (v) {
                            return /* tuple */[
                                    to_k,
                                    v
                                  ];
                          }), Dict$BwaxAdmin.$$String.get(to_k, vertices));
            }), vertex[/* nexts */1]));
  Curry._2(post_visit, key, info);
  return final_visited;
}

function dfs(pre_visit, post_visit, graph) {
  Dict$BwaxAdmin.$$String.foldl((function (acc_visited, k, v) {
          if (Set$BwaxAdmin.$$String.has(k, acc_visited)) {
            return acc_visited;
          } else {
            return explore(pre_visit, post_visit, graph, acc_visited, k, v);
          }
        }), Set$BwaxAdmin.$$String.empty, graph[0]);
  return graph;
}

function df_numbering(graph) {
  var pre_numbers = /* record */Caml_chrome_debugger.record(["contents"], [Dict$BwaxAdmin.$$String.empty(/* () */0)]);
  var post_numbers = /* record */Caml_chrome_debugger.record(["contents"], [Dict$BwaxAdmin.$$String.empty(/* () */0)]);
  var current = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var pre_visit = function (key, param) {
    var num = current[0] + 1 | 0;
    var pnums = Dict$BwaxAdmin.$$String.insert(key, num, pre_numbers[0]);
    pre_numbers[0] = pnums;
    current[0] = num;
    return /* () */0;
  };
  var post_visit = function (key, param) {
    var num = current[0] + 1 | 0;
    var pnums = Dict$BwaxAdmin.$$String.insert(key, num, pre_numbers[0]);
    post_numbers[0] = pnums;
    current[0] = num;
    return /* () */0;
  };
  dfs(pre_visit, post_visit, graph);
  return Dict$BwaxAdmin.$$String.keep_map((function (k, pre_num) {
                return Plate$BwaxAdmin.$$Option.map((function (post_num) {
                              return /* tuple */[
                                      pre_num,
                                      post_num
                                    ];
                            }), Dict$BwaxAdmin.$$String.get(k, post_numbers[0]));
              }), pre_numbers[0]);
}

function linearize(graph) {
  var stack = /* record */Caml_chrome_debugger.record(["contents"], [0]);
  var pre_visit = function (param, param$1) {
    return /* () */0;
  };
  var post_visit = function (param, info) {
    stack[0] = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        info,
        stack[0]
      ]);
    return /* () */0;
  };
  dfs(pre_visit, post_visit, graph);
  return stack[0];
}

function has_cycle(graph) {
  var all_edges = get_edges(graph);
  if (graph[1]) {
    var numbers = df_numbering(graph);
    return Plate$BwaxAdmin.List.some((function (param) {
                  var post_num = function (key) {
                    return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Dict$BwaxAdmin.$$String.get(key, numbers));
                  };
                  var match = post_num(param[0]);
                  var match$1 = post_num(param[1]);
                  if (match !== undefined && match$1 !== undefined) {
                    return match <= match$1;
                  } else {
                    return false;
                  }
                }), all_edges);
  } else {
    return Plate$BwaxAdmin.List.length(all_edges) > 0;
  }
}

function direct_add_edge(from_key, to_key, graph) {
  return add_edge(from_key, to_key, set_vertex(to_key, to_key, set_vertex(from_key, from_key, graph)));
}

function build_graph_with_edges(is_directed, list) {
  return Plate$BwaxAdmin.List.foldl((function (acc, param) {
                return direct_add_edge(param[0], param[1], acc);
              }), $$null(is_directed), list);
}

function test_dfs_01(param) {
  var graph = set_vertex("F", "F", build_graph_with_edges(false, /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "A",
                "B"
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "A",
                    "E"
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "E",
                        "I"
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "I",
                            "J"
                          ],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "C",
                                "D"
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "C",
                                    "G"
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "C",
                                        "H"
                                      ],
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          /* tuple */[
                                            "G",
                                            "H"
                                          ],
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              /* tuple */[
                                                "G",
                                                "K"
                                              ],
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  /* tuple */[
                                                    "H",
                                                    "K"
                                                  ],
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      /* tuple */[
                                                        "D",
                                                        "H"
                                                      ],
                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          /* tuple */[
                                                            "H",
                                                            "L"
                                                          ],
                                                          /* [] */0
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])));
  var count = /* record */Caml_chrome_debugger.record(["contents"], [1]);
  return dfs((function (k, param) {
                var c = count[0];
                count[0] = c + 1 | 0;
                console.log("Pre", k, c);
                return /* () */0;
              }), (function (k, param) {
                var c = count[0];
                count[0] = c + 1 | 0;
                console.log("Post", k, c);
                return /* () */0;
              }), graph);
}

exports.Invalid_argument = Invalid_argument;
exports.$$null = $$null;
exports.set_vertex = set_vertex;
exports.no_vertex = no_vertex;
exports._update_vertex_nexts = _update_vertex_nexts;
exports.add_edge = add_edge;
exports.remove_edge = remove_edge;
exports.get_vertices = get_vertices;
exports.get_vertex = get_vertex;
exports.is_directed = is_directed;
exports.get_edges = get_edges;
exports.explore = explore;
exports.dfs = dfs;
exports.df_numbering = df_numbering;
exports.linearize = linearize;
exports.has_cycle = has_cycle;
exports.direct_add_edge = direct_add_edge;
exports.build_graph_with_edges = build_graph_with_edges;
exports.test_dfs_01 = test_dfs_01;
/* Set-BwaxAdmin Not a pure module */
