
import React, { useEffect, useContext, useState } from 'react'

import './Login.less'

import qs from 'query-string';

import getImageURL from 'bwax-ui/getImageURL'

import { runDataQuery } from 'bwax/query/runClientQuery'
import QueryCurrentUser from 'bwax/query/static/QueryCurrentUser'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import useBaseSettings from 'bwax-ui/legacy/store/useBaseSettings'

// import LogoURL from "./logo.png"

import LoginQrCode from './LoginQrCode'
import { ClipLoader } from 'react-spinners';
import PasswordLogin from './PasswordLogin';

import setTokenCookie from 'bwax-ui/setTokenCookie'

export default function Login(props) {

    const { history } = props

    const dlc = useContext(DataLoaderContext);

    const { sandbox, tenantCode } = dlc;
    const runQuery = runDataQuery(dlc);

    // TODO 这里可能要根据 tenant 的信息加载：

    // 另外，tenant 的后台与 platform 的后台的样式要有所区别

    const baseSettings = useBaseSettings();

    const [ mode, setModel ] = useState("qrcode") // qrcode, password, sms

    const params = qs.parse(history.location.search);

    function getLogoURL() {
        if (baseSettings && baseSettings.logo) {
            return getImageURL(baseSettings.logo, "small")
        } else {
            return null
        }
    }

    const defaultLogoURL = getImageURL("https://bw-dev.static.qunfengshe.com/public/cb-bwax/design-resources/tikicrowd_logo_1.png", "small")

    const logoURL = getLogoURL() || defaultLogoURL;

    function getSiteName() {
        if (baseSettings && baseSettings.name) {
            return baseSettings.name
        } else {
            return null
        }
    }

    const siteName = getSiteName();

    useEffect(() => {
        async function init() {

            const result = await runQuery(QueryCurrentUser.text)()
            const currentUser = QueryCurrentUser.extractResult(result)

            const isAdminOrOperator = r => r === "Admin" || r === "Operator"
            const isDesigner = r => r === "Designer"

            if (currentUser && currentUser.systemRoles) {
                if (currentUser.systemRoles.some(isAdminOrOperator)) {
                    history.replace('/')
                } else if (currentUser.systemRoles.some(isDesigner)) {
                    history.replace('/design')
                }
            }
        }
        init()
    }, []);

    if(baseSettings === undefined) {
        return (
            <div className="login-loading">
                <ClipLoader />
            </div>
        )
    }

   
    const forward = () => {
        const returnTo = (sandbox ? "/sandbox" : "") + (tenantCode ? "/-/" + tenantCode : "") + (params.rt || "/");
        location.href = returnTo;
    }

    function afterLogin(accessToken, authUser) {
        
        if (authUser.systemRoles && authUser.systemRoles.some(n =>
            n === 'Admin' || n === 'Operator'
        )) {
            setTokenCookie(accessToken, dlc);
            forward();
        } else if (authUser.systemRoles && authUser.systemRoles.some(n =>
            n === 'Designer'
        )) {
            setTokenCookie(accessToken, dlc);
            forward();
        } else {
            alert && alert('你没有登录后台的权限')
        }
    }

    function render() {
        if(mode == "qrcode") {
            return (
                <LoginQrCode afterLogin={afterLogin} runQuery={runQuery} {...{ sandbox, tenantCode }}/>
            )
        } else if(mode == "password") {
            return (
                <PasswordLogin afterLogin={afterLogin} runQuery={runQuery} />                
            )

        } else if(mode == "sms") {
            return (
                <div>SMS</div>
            )
        } else {
            return null
        }
    }

    return (
        <div className="login-page">
            <div className="form">
                <div className="login-logo">
                    <img alt={'logo'} src={logoURL} />
                    { siteName ? <div className="title">{siteName}管理后台</div> : null }
                </div>
                <div className="login-box">
                    { render() }
                </div>
                <div className="mode-select">
                    <div className="select-button"onClick={_ => setModel("qrcode")}>
                        二维码
                    </div>
                    {/* <div className="seperator">|</div>
                    <div className="select-button">
                        验证码
                    </div> */}
                    <div className="separator">|</div>
                    <div className="select-button" onClick={_ => setModel("password")}>
                        使用密码
                    </div>                                        
                </div>
            </div>
        </div>
    )
}




