import React, { useEffect, useState } from 'react';

import Card from './charts/Card';
import BarChart from './charts/BarChart';
import LineChart from './charts/LineChart';
import PieChart from './charts/PieChart';

const Charts = {
    "card": Card, 
    "bar": BarChart,
    "line": LineChart,
    "pie": PieChart
}

export default function AdminChart (props) {

    const { context, name, aggregate_config, config } = props;

    // 执行 aggregate 请求，把结果和 config 传给实际的 chart

    const { bwax, refreshedAt } = context;

    // 要识别每个 field 的类型和对应的 aggregate / grouping 函数
    const [ queriedData, setQueriedData ] = useState(undefined);
    const [ dataFields, setDataFields ] = useState(undefined);

    async function queryData (options) {

        const [ entityName ] = aggregate_config;

        const queryObj = {
            entityName,
            aggregate_config
        };
        const [ result, error ] = await bwax.aggregate(queryObj, options);

        const dataFields = await bwax.getAggregateDataFields(queryObj);

        // TODO error handling
        if(!error) {
            setQueriedData(result);
            setDataFields(dataFields)
        }
        
    }

    useEffect(() => {
        queryData();
    }, [ bwax, JSON.stringify(aggregate_config), refreshedAt ]);

    const Chart = Charts[name];
    if(!Chart) {
        return "Un supported chart `" + name + "`";
    } else {
        return (
            <Chart
                data={queriedData || []}
                config={config}
                fieldSettings={dataFields}
            />
        )
    }
}

export function create (props) {
    return <AdminChart { ...props } />
}
