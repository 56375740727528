

import React from 'react';

import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';

import { getColorScheme } from './chartUtils';

// import COLORS from './colors';

export default function PieChartComp({ data, config }) {

    const { fields, colorScheme = "bwax" } = config;

    const COLORS = getColorScheme(colorScheme);

    return (
        <div className="admin-widget admin-chart admin--line-chart" style={{
            minHeight: 320
        }}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data}
                        // cx={120}
                        // cy={200}
                        // innerRadius={60}
                        // outerRadius={80}
                        // fill="#8884d8"
                        // paddingAngle={5}
                        dataKey={fields.value}
                        nameKey={fields.legend}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}
