

import { getFieldsFromFieldItems } from './builderUtils'


export default function buildRecordListTableView(entityName, contextEntityName, options = {}, { allEntities, allDataTypes, queryTarget }) {

    const {
        excludedFields = [],
        identifyingField,
        allowedActions = [],
        // includedFields = [], // 这个 field 优先于 excludedFields
        fieldColumns, // 这个 field 优先于 excludedFields
        operations: passedInOperations, // right side operations
        criteria,
        sortBy,
        pagination = { pageSize: 20 },
        exportAsExcel = {},

        pullUpLoading = false,

        noFilter = false,
    } = options;

    const dataEntity = allEntities.find(e => e.name === entityName)

    if(!dataEntity) {
        return "未找到数据实体【" + entityName + "】"
    }

    const { name, fields, virtualFields } = dataEntity

    // get fields to use: a field is 
    // { cname?, name, type, options, multivalued, required, desc, key, displayParams, filterable }
    // key 只是用在 condition 那里判断虚拟字段
    function getFieldsToUse() {
        if (fieldColumns) {
            return getFieldsFromFieldItems(fieldColumns, dataEntity, allEntities, allDataTypes);
        }
        return (
            [...fields, ...virtualFields].filter(
                f => [...(Array.isArray(excludedFields) ? excludedFields : [])].indexOf(f.name) === -1
            ).sort((a, b) => {
                // 给 field 排序, 根据 displayWeight 从大到下排列
                const getWeight = f => f.displayWeight || 0
                return getWeight(b) - getWeight(a)
            })
        )

    };

    const fieldsToUse = getFieldsToUse();


    function getSiteName() {
        if (typeof (document) !== "undefined") {
            if (document && document.baseSettings && document.baseSettings.name) {
                return document.baseSettings.name
            } else {
                return null
            }
        } else {
            return null
        }
    }

    function getTitle() {
        if (queryTarget == "definition") {
            return `设计后台 - ${name}列表`
        } else {
            const siteName = getSiteName();
            if (siteName) {
                return siteName + ` | 管理后台 - ${name}列表`
            } else {
                return `管理后台 - ${name}列表`
            }
        }
    }

    const operations = passedInOperations || {
        "查看": {
            type: "GotoDetail"
        }
    };

    const ret = {

        head: {
            title: getTitle()
        },
        layout: {
            content: [
                {
                    type: "admin::TableView",
                    params: {
                        fields: fieldsToUse,  
                        defaultColumnWidth: 200,
                        
                        pullUpLoading,

                        noFilter,

                        allowedActions,

                        pagination,
                        entityName,
                        contextEntityName,
                        contextRecordId: "${id}",
                        showRowNum: true,
                        identifyingField,
                        operations,
                        // 这里等于是 action templates
                        actions: {
                            goTo: {
                                action: {
                                    type: "GoTo"
                                }
                            },
                            updateRecord: {
                                action: {
                                    type: "UpdateRecord"
                                }
                            },
                            deleteRecord: {
                                action: {
                                    type: "DeleteRecord"
                                }
                            },
                            addRecord: {
                                action: {
                                    type: "AddRecord"
                                }
                            },                            
                            adminMessage: {
                                action: {
                                    type: "admin::Message",
                                }
                            }
                        },

                        /// 按钮


                        // export excel
                        criteria, sortBy, exportAsExcel,

                        tabID: "${tabID}",
                    }
                }
            ]
        }

    }

    return ret
}