
import React from 'react'

import './ColorDisplay.less';

import lightOrDark from 'bwax-ui/color/lightOrDark';

import { CopyToClipboard } from 'react-copy-to-clipboard'

import Message from 'Client/js/ui/Message';

export default function ColorDisplay(props) {
    const { params } = props
   
    const { value } = params;

    return value ? (
        <CopyToClipboard text={value} onCopy={_ => {
            Message.success("已复制 " + value);
        }}>
            <ColorContent color={value} />
        </CopyToClipboard>
    ) : null
}


export const ColorContent = React.forwardRef((props, ref) => {

    const { color, placeholderText = "", onClick = _ => {} } = props;
    return (
        <div className="admin--color-content" onClick={onClick} style={{
            backgroundColor: color ? color : "#F0F3F5"
        }} ref={ref}>
            {
                color ? (
                    <div className="color-value" style={lightOrDark(color) == "light" ? {} : { color: "white " }}>
                        {color}
                    </div>
                ) : placeholderText
            }
        </div>
    )
})


