
import React from 'react'

import ModalLink from 'Client/js/components/ModalLink'

import { Card as AntCard } from 'antd'

import './Card.less'

export default function Card ({ params, renderWidget }) {
    const { 
        title, 
        body, 
        footer, 
        link,
        size,
    } = params

    const defaultSize = { width: 160, height: 180 }
    const sizeStyle = {
        'wide': { width: 280, height: 120 },
    }[size] || defaultSize

    const cardStyle = { ...sizeStyle, margin: 8 } 

    function renderContent(name, content, always) {
        return content || always ? <div className={`card-${name}`}>{ renderWidget(content) }</div> : null
    }

    return (
        <ModalLink to={link}>
            <AntCard title={ title } hoverable={true} style={cardStyle}
            >
                <div className='admin--card'>
                    { renderContent('body', body, true) }
                    { renderContent('footer', footer) }
                </div>
            </AntCard>
        </ModalLink>
    )
}


