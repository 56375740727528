

import React from 'react'
import AttachmentView from 'Client/js/components/AttachmentView';

export default function AttachmentPreview ({ params }) {
    const { value } = params

    return (
        <AttachmentView
            readOnly
            attachment={value} 
        />
    )
}



