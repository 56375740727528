

import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Editor, Transforms, Text } from 'slate'

import { MdFormatColorText } from 'react-icons/md'

const presetColors = [
  '#F95630', //cb primary color
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF'
]

function RenderSketchPicker (props) {

    const { editor, currentColor } = props

    const [color, setColor] = useState(currentColor)

    function onColorChange (c) {
        const { rgb } = c
        const { r, g, b, a } = rgb
        const colorRgb = `rgba(${r}, ${g}, ${b}, ${a})`
        setColor(colorRgb)
        Transforms.setNodes(
            editor,
            { color: colorRgb },
            { match: Text.isText, split: true }
        )

    }

    return (
        <SketchPicker
            color={color}
            presetColors={presetColors}
            onChangeComplete={onColorChange} 
        />
    )
}

export default function ColorButton(props) {

    const { editor, bindOverrideContent } = props

    function preventBubblingUp (event) { 
        event.preventDefault(); 
        event.stopPropagation()
    }

    const marks = Editor.marks(editor)
    const currentColor = marks && marks.color ? marks.color : ""

    const component = (
        <RenderSketchPicker editor={editor} currentColor={currentColor} />
    )

    return (
        <div onClick={e => preventBubblingUp(e)}>
            <button className={"editor-button"} type="button" onClick={e => {
                preventBubblingUp(e)
                bindOverrideContent({
                  outerClassName: "no-arrow",
                  component
                })
            }} >
                <MdFormatColorText style={{ color: currentColor, fontSize: 16 }}/>
            </button>
        </div>
    )

}
