

export default {
  text: `
    query {
      me {
        authUser {
          id
          uid
          avatar {
            expireTime
            title
            size
            contentType
            url
            path
          }
          nickName
          systemRoles
        }
      }
    }
  `,
  extractResult: result => {
    // TODO ERROR handling:
    const data = JSON.parse(result).data
    return data && data.me && data.me.authUser
  },
  hasError: result => {
    const { errors } = JSON.parse(result) 
    if(errors !== undefined) {
      console.warn("Errors", errors)
      return true
    } else {
      return false
    }
  }


}