

//// use for testing
const resolveLeaf = (expStr, resolveWithLocal) => {
    const n =  new Node({
        name, parent,
        category: 'leaf',
        dataDefs: dataDefs || {},
        nodeDef
    })
    return resolveWithLocal(n, {})
}

//// 
export default {
    map: (list, node, resolveWithLocal) => {
        const predicate = (value, index) => {
            const ret = resolveWithLocal(node, { value, index })
            return ret
        }

        if (Array.isArray(list)) {
            const ret = list.map(predicate)

            return ret
        // } else if (typeof (list) === 'object' && list !== null) {

        //     return Object.keys(list).reduce((acc, k) => {
        //         return {
        //             ...acc,
        //             /// for object: the value is value, the key is index
        //             [k]: predicate(list[k], k)
        //         }
        //     }, {})

        } else {
            /// direcly operate on the value
            return predicate(list, 0)
        }

    },
    find: (list, node, resolveWithLocal) => {

        const predicate = (value, index) => resolveWithLocal(node, { value, index })

        if (Array.isArray(list)) {
            const ret = list.find(predicate)
            return ret === undefined ? null : ret

        } else if (typeof (list) === 'object' && list !== null) {

            let ret = Object.keys(list).find(k => {
                /// for object: the value is value, the key is index
                return predicate(list[k], k)
            })
            return ret === undefined ? null : ret /// 对于 object 里面的 find，我们返回 key

        } else {
            console.error("cannot execute 'find' on ", list)
            return null
        }
    },

    some: (list, node, resolveWithLocal) => {

        const predicate = (value, index) => resolveWithLocal(node, { value, index })
        if(Array.isArray(list)) {
            return list.some(predicate)
        } else if(typeof(list) === 'object' && list !== null) {
            return Object.keys(list).reduce((acc, k) => {
                return acc || predicate(list[k], k)
            }, false)
        }
        return false
    },

    every: (list, node, resolveWithLocal) => {
        const predicate = (value, index) => resolveWithLocal(node, { value, index })
        if(Array.isArray(list)) {
            return list.every(predicate)
        } else if(typeof(list) === 'object' && list !== null) {
            return Object.keys(list).reduce((acc, k) => {
                return acc && predicate(list[k], k)
            }, true)
        }
        
        return true
    },

    filter: (list, node, resolveWithLocal) => {
        const predicate = (value, index) => resolveWithLocal(node, { value, index })
        if (Array.isArray(list)) {
            return list.filter(predicate)

        } else if (typeof (list) === 'object' && list !== null) {
            const ret = Object.keys(list).reduce((acc, k) => (
                predicate(list[k], k) ? { ...acc, [k]: list[k] } : acc
            ), {})
            return ret

        } else {
            console.error("cannot execute 'filter' on ", list)
            return null
        }
    },
    count: (list, node, resolveWithLocal) => {
        const predicate = (value, index) => resolveWithLocal(node, { value, index })

        if (Array.isArray(list)) {
            return list.filter(predicate).length

        } else if (typeof (list) === 'object' && list !== null) {

            const filtered = Object.keys(list).filter(k => {
                return predicate(list[k], k)
            })
            return filtered.length

        } else {
            console.error("cannot execute 'filter' on ", list)
            return null
        }

    },

    sum: (list, node, resolveWithLocal) => {
        const predicate = (value, index) => resolveWithLocal(node, { value, index })

        if (Array.isArray(list)) {

            let ret =  list.map(predicate).reduce((acc, value) => {
                // the value must be a number:
                return acc + value
            }, 0)

            return ret

        } else if (typeof (list) === 'object' && list !== null) {

            const transformed = Object.keys(list).map(k => {
                return predicate(list[k], k)
            })
            
            return transformed.reduce((acc, value) => {
                // the value must be a number:
                return acc + value
            }, 0)
        } else {
            console.error("cannot execute 'filter' on ", list)
            return null
        }

    },

    sort: (list, node, resolveWithLocal) => {
        const compare = (a, b) => {
            const ret = resolveWithLocal(node, { a, b })
            return ret
        }
        
        if (Array.isArray(list)) {
            const ret = list.sort(compare)
            return ret

        } else if (typeof (list) === 'object' && list !== null) {

            const sortedKeys = Object.keys(list).sort((a, b) => {
                return compare(list[a], list[b])
            })

            return sortedKeys.reduce((acc, current) => {
                return {
                    ...acc,
                    [current]: list[current],
                }
            }, {})

        } else {
            console.error("cannot execute 'sort' on ", list)
            return null
        }

    },

    groupBy: (list, node, resolveWithLocal) => {

        const groupKey = resolveWithLocal(node, {})

        ////// <groupKey-value> => [ grouped values ]
        const groups = list.reduce((acc, v) => {
            const keyValue = v[groupKey]
            if(keyValue !== null && keyValue !== undefined) {
                const existing = acc[keyValue] || []
                return {
                    ...acc,
                    [keyValue]: [ ...existing,  v ]
                }
            }
            /// ignore the ones with nill key value
            return acc
        }, {})

        const groupWithNilKey = list.filter( 
            v => v[groupKey] === null || v[groupKey] === undefined
        )

        return [ ...Object.values(groups), groupWithNilKey ]
    },

    uniq: (list, node, resolveWithLocal) => {
        const ret = list.reduce((acc, value, index) => {
            const resolved = resolveWithLocal(node, { value, index })
            if(acc.some(e => e === resolved)) {  /// TODO better equality checker
                return acc
            }
            return [ ...acc, resolved ]
        }, [])
        return ret

    }

}