


export default function HideModal(props) {

    return async params => {
        const { modal } = props
        if( modal && modal.hideModal) {
            modal.hideModal()
        }
    }
}