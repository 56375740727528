
import { parser } from './ore.parser';

// full parse
export function parse(code) {

    // const t0 = performance.now();
    const partialState = parser.startParse(code);
    partialState.stopAt(code.length); 
    let tree = null;
    // let counter = 0;
    for (;;) {
        // console.log("Advancing")
        // counter++;
        tree = partialState.advance();
        if(tree) {
            break
        }
    }
    // const t1 = performance.now();

    // console.log("Parsed", t1 - t0, code.substring(0, 50));
    return tree

}