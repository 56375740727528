// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

import './Layout_TopbarMain.less'
;

function Layout_TopbarMain(Props) {
  var params = Props.params;
  var renderWidget = Props.renderWidget;
  var topLeft = params.topLeft;
  var topRight = params.topRight;
  var mainContent = params.mainContent;
  return React.createElement("div", {
              className: "admin--topbar-main"
            }, React.createElement("div", {
                  className: "topbar"
                }, React.createElement("div", {
                      className: "top-left-bar"
                    }, Curry._3(renderWidget, topLeft, undefined, "tlb")), React.createElement("div", {
                      className: "top-right-bar"
                    }, Curry._3(renderWidget, topRight, undefined, "trb"))), Curry._3(renderWidget, mainContent, undefined, undefined));
}

var make = Layout_TopbarMain;

exports.make = make;
/*  Not a pure module */
