


import { runDataQuery } from 'bwax/query/runClientQuery'

export default function loadGeneralSetting(name, appCode) {
    return async ({ tenantCode, endpoints, sandbox = false, headers, sessionToken }) => {

        const QueryText = `query ($name: String!) { definition { application (code: "${appCode}") { generalSetting(name: $name) { compiled } } } }`
        const result = await runDataQuery({
            tenantCode,
            headers,
            endpoints,
            sessionToken,
            sandbox
        })(QueryText)({
            name,
        })

        const setting = JSON.parse(result).data.definition.application.generalSetting
        return setting
    }
}


