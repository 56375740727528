
// 一般是一个 Widget 或者 Layout；

import "./AdminPage.less";

import React, { useEffect, useState, useContext } from "react";

import invariant from 'invariant';

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary';

import useDataLoader from 'bwax-ui/legacy/store/useDataLoader'
import { runDataQuery } from 'bwax/query/runClientQuery';
import pageRuntimeModules from 'bwax-ui/gen/page_runtime_modules.json';

import { untag, unpack } from 'bwax/lang/LangHelper'

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'
import page_entry_slim from 'bwax-ui/ml/page_entry_slim.bs'


import { make as AdminPageComponentRenderer } from 'Client/ml/AdminPageComponentRenderer.bs';

import { resolveDependenciesAndPrepareData } from 'bwax/BwaxExecHelper';

import queryUser from "bwax/query/queyUser"

import Loading from 'Client/js/components/Loading'

const gmod = untag(pageRuntimeModules);

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

function loadAdminPage(name) {
    return async env => {
        const queryText = `query { definition { adminPage (name: "${name}") { compiled } } }`
        const result = await runDataQuery(env)(queryText)()

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.definition ? data.definition.adminPage : null
        }
    }
}

export function useAdminPage(name) {

    const key = 'definitions::AdminPage::' + name;
    const { getData, forceLoad } = useDataLoader({ [key]: loadAdminPage(name) })
    const [adminPage] = getData(key)

    async function forceReload() {
        let dataDict = await forceLoad({ [key]: loadAdminPage(name) })
        return dataDict[key]
    }
    return { adminPage, forceReload }
};

export default function CustomAdminPage(props) {

    const { 
        context,
        mlContext,
        pageName,
        pageType, // Widget or PageLayout

    } = props;

    const facade = context.bwax;

    const route_to = (path, shouldReplace) => {
        routeTo(path);
    }

    // load the admin page
    const { adminPage } = useAdminPage(pageName);

    // cache runtime of admin page;
    const [ runtime, setRuntime ] = useState(undefined);

    const [ error, setError ] = useState(undefined);

    const dlc = useContext(DataLoaderContext);

    const domainEnv = {
        mobileHost: document.userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        protocol: "https",
        tenantCode: dlc.tenantCode,
    }

    async function makeParams({ entity_dict, data_type_dict, dts, n, defs }) {

        const getUser = async userDeps => {
            const [_tree, paths, selectionText, _deps] = userDeps;
            if (!paths || paths.length == 0) {
                return {}
            }
            const user = await queryUser(dlc, selectionText);
            if (!user) {
                console.error("User is not logged in");
            }
            return user;
        }

        const getRecord = () => { invariant(false, "Invalid get record here") };


        const [dependedData, error] = await resolveDependenciesAndPrepareData([n, defs], {
            getRecord, getUser,
            entity_dict, data_type_dict, dts, domainEnv
        })
        // 
        invariant(!error, "Error happened during data preparation: " + error);
        
        // 要判断它接不接受 List (String, String)
        const initArity = lang_entry_slim.get_init_arity([n, defs]);

        if (initArity > 1) {
            // 如果 dependedData 是 undefined,
            // 则意味着该 init 函数没有声明依赖: 所以把 undefined filter 掉
            return lang_entry_slim.array_to_list([
                lang_entry_slim.transfrom_json_to_assoc_string_list_value(
                    // match.params || {}
                    {}
                ),
                dependedData
            ])
        } else {
            return lang_entry_slim.array_to_list([
                dependedData
            ])
        }

    }

    async function refreshParams() {
        if (!runtime) {
            return
        }
        const { dts, n, defs } = runtime;
        const params = await makeParams({ entity_dict: bwax.entity_dict, data_type_dict: bwax.data_type_dict, dts, n, defs });
        setRuntime({
            ...runtime,
            params
        })
    }


    // prepare the env
    useEffect(() => {
        // 
        if (adminPage && adminPage.compiled) {

            setRuntime(undefined);

            (async () => {

                let t0 = performance.now();
                const unpacked = unpack(adminPage.compiled);

                // 这里只支持新版本：
                const [ n, defs, dts, entityNames ] = unpacked;

                await facade.prepare(entityNames);
                const entity_dict = facade.entity_dict;
                const data_type_dict = facade.data_type_dict;

    
                // 先确定 page 依赖的 entity / datatype， 然后 prepare 它们：                
    
                // 在这里可以通过 defs 去找打所有的 viewXxxx
    
                let t1 = performance.now();
                console.log("Deserialization used", t1 - t0, "ms");
    
                let t2 = performance.now();
    
                console.log("Combine used", t2 - t1, "ms");
    
                const base_env = page_entry_slim.prepare_eval_env(
                    gmod, entity_dict, data_type_dict, 
                    facade.adminPages || []
                );
    
                try {
                    setError(undefined);
    
                    const env = lang_entry_slim.evaluate_defs(base_env, [n, defs]);;
    
                    // prepare user and then combine the paramters.
                    // get dependency
    
                    // 直接 prepare 
                    (async () => {
    
                        const params = await makeParams({ entity_dict, data_type_dict, dts, n, defs })
    
                        setRuntime({
                            domainEnv,
                            dlc,
                            dts,
                            env,
                            params,
                            loadingComp: LoadingComp,
                            n, defs,
                        })
                    })();
    
                } catch (error) {
                    console.log("Here we got error", error);
                    setError(error)
                }

            })();


        }

    }, [adminPage])

    function printError() {
        if (error.stack) {
            return error.stack;
        } else if (Array.isArray(error)) {
            // bucklescript error
            const [_, msg] = error;
            return msg
        } else {
            return error;
        }
    }



    function renderAdminPage() {
        if (adminPage === null) {
            return (
                <div style={{ padding: "8px 16px", width: "100%" }}>
                    404 未找到 {pageName}
                </div>
            )
        } else if (error) {
            return (
                <div style={{ padding: "8px 16px", width: "100%" }}>
                    <h4>出错了</h4>
                    <div style={{ padding: "8px 0", width: "100%" }}>
                        <pre style={{ width: "100%", overflow: "auto" }}>
                            {printError()}
                        </pre>
                    </div>
                </div>
            )
        } else if (adminPage === undefined) {
            return <LoadingComp />;
        } else if (runtime == undefined) {
            return <LoadingComp />;
        } else {
            try {
                return (
                    <ErrorBoundary>
                        <AdminPageComponentRenderer
                            {...runtime}
                            bwax={facade}
                            route_to={route_to}
                            refreshParams={refreshParams}
                            context={mlContext}
                            onTabNamesReady={names => {
                                console.log(names);
                            }}
                        />
                    </ErrorBoundary>
                )
            } catch (e) {
                console.log("Here we got error", e)
                return <div>ERROR</div>
            }
        }
    };

    return renderAdminPage();

}

function LoadingComp () {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "15rem"
        }}>
            <Loading />
        </div>
    )
}



export function create(props) {
    return <CustomAdminPage {...props} />
}


