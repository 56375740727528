// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Pretty_printer$BwaxAdmin = require("./pretty_printer.bs.js");
var Pretty_printer_common$BwaxAdmin = require("./pretty_printer_common.bs.js");

function extract_comments(list) {
  return Curry._2(Plate$BwaxAdmin.List.partition, (function (c) {
                return c[/* name */0] !== "Comment";
              }), list);
}

function css_doc(starting_option, depth, lang_tree) {
  var children = lang_tree[/* children */5];
  var unpruned;
  var exit = 0;
  var exit$1 = 0;
  if (children) {
    switch (lang_tree[/* name */0]) {
      case "Declaration" :
          var match = extract_comments(children);
          var match$1 = Pretty_printer_common$BwaxAdmin.collect_elements_before((function (param, n) {
                  return n[/* src */1] === ":";
                }), match[0]);
          var match$2 = Pretty_printer_common$BwaxAdmin.collect_elements_until((function (param, n) {
                  return n[/* src */1] === ":";
                }), match$1[1]);
          var simple_join = function (l) {
            return Plate$BwaxAdmin.$$Option.with_default(Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.List.reduce(Pretty_printer$BwaxAdmin.$caret$pipe, Plate$BwaxAdmin.identity, Plate$BwaxAdmin.List.map((function (n) {
                                  return Pretty_printer$BwaxAdmin.text(n[/* src */1]);
                                }), l)));
          };
          var property_name_doc = simple_join(match$1[0]);
          unpruned = Pretty_printer$BwaxAdmin.group(Pretty_printer$BwaxAdmin.$caret$caret(property_name_doc, Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(": "), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(simple_join(match$2[1])), follow_up(depth, match[1])))));
          break;
      case "RuleSet" :
          var match$3 = children[1];
          if (match$3) {
            if (match$3[1]) {
              exit = 1;
            } else {
              unpruned = Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(children[0][/* src */1]), Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.group(Plate$BwaxAdmin.List.foldl(Pretty_printer$BwaxAdmin.$caret$caret, Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.List.map((function (c) {
                                      var match = c[/* name */0];
                                      switch (match) {
                                        case ";" :
                                            return Pretty_printer$BwaxAdmin.text(";");
                                        case "{" :
                                            return Pretty_printer$BwaxAdmin.$caret$caret(Pretty_printer$BwaxAdmin.text(" "), Pretty_printer$BwaxAdmin.text("{"));
                                        case "}" :
                                            return Pretty_printer_common$BwaxAdmin.indent(depth, Pretty_printer$BwaxAdmin.text("}"));
                                        default:
                                          return css_doc(/* With_newline */0, depth + 1 | 0, c);
                                      }
                                    }), match$3[0][/* children */5]))), Pretty_printer$BwaxAdmin.$$break));
            }
          } else {
            exit$1 = 2;
          }
          break;
      default:
        exit$1 = 2;
    }
  } else {
    unpruned = Pretty_printer$BwaxAdmin.text(lang_tree[/* src */1]);
  }
  if (exit$1 === 2) {
    if (children[1]) {
      exit = 1;
    } else {
      unpruned = css_doc(starting_option, depth, children[0]);
    }
  }
  if (exit === 1) {
    unpruned = Plate$BwaxAdmin.List.foldl(Pretty_printer$BwaxAdmin.$caret$caret, Pretty_printer$BwaxAdmin.empty, Plate$BwaxAdmin.List.map((function (c) {
                return css_doc(/* With_newline */0, depth, c);
              }), children));
  }
  var doc = Pretty_printer$BwaxAdmin.prune_nil(unpruned);
  var is_nest = function (_param) {
    while(true) {
      var param = _param;
      if (typeof param === "number") {
        return false;
      } else {
        switch (param.tag | 0) {
          case /* Doc_cons */0 :
              _param = param[0];
              continue ;
          case /* Doc_nest */2 :
              return true;
          default:
            return false;
        }
      }
    };
  };
  if (typeof doc === "number" && doc === 0) {
    return doc;
  }
  if (depth > 0 && starting_option === /* With_newline */0 && !is_nest(doc) && !Pretty_printer$BwaxAdmin.start_with_must_break(doc)) {
    return Pretty_printer_common$BwaxAdmin.indent(depth, doc);
  } else {
    return doc;
  }
}

function follow_up(depth, child_nodes) {
  var partial_arg = depth + 1 | 0;
  var partial_arg$1 = depth + 1 | 0;
  var partial_arg$2 = depth + 1 | 0;
  var partial_arg$3 = function (param) {
    return Pretty_printer_common$BwaxAdmin.indent(partial_arg$2, param);
  };
  return Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                return css_doc(/* Same_line */1, partial_arg, param);
              }), (function (param) {
                return css_doc(/* With_newline */0, partial_arg$1, param);
              }), (function (param) {
                return Plate$BwaxAdmin.$at$great(Pretty_printer$BwaxAdmin.group, partial_arg$3, param);
              }), child_nodes);
}

function start_up(depth, child_nodes) {
  var partial_arg = depth + 1 | 0;
  var partial_arg$1 = depth + 1 | 0;
  return Pretty_printer_common$BwaxAdmin.pile_up((function (param) {
                return css_doc(/* Same_line */1, partial_arg, param);
              }), (function (param) {
                return css_doc(/* With_newline */0, partial_arg$1, param);
              }), Pretty_printer$BwaxAdmin.group, child_nodes);
}

function pretty_print_css(width, code_text, css_js) {
  var base_doc = css_doc(/* Same_line */1, 0, Pretty_printer_common$BwaxAdmin.lang_tree_from_js(code_text, /* [] */0, css_js));
  return Pretty_printer$BwaxAdmin.pretty(width, base_doc);
}

exports.extract_comments = extract_comments;
exports.css_doc = css_doc;
exports.start_up = start_up;
exports.follow_up = follow_up;
exports.pretty_print_css = pretty_print_css;
/* Plate-BwaxAdmin Not a pure module */
