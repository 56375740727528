
import React from 'react';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

export default function ResultPanel (props) {

    const { result } = props;

    function printError(error) {
        console.log(error);
        if (error.stack) {
            return error.stack;
        } else if (Array.isArray(error)) {
            // bucklescript error
            const [_, msg] = error;
            if(msg && msg.message) {
                return msg.message
            }
            return msg || "unknown message"
        } else {
            return error;
        }
    }

    if (result) {

        if (result.error) {
            return (
                <div className="result-section">
                    <div className="result-title">执行出错:</div>
                    <div style={{ padding: "16px", width: "100%" }}>
                        <pre style={{ width: "100%", overflow: "auto" }}>
                            {printError(result.error)}
                        </pre>
                    </div>
                </div>
            )
        }
        const [values, errorStr] = result.view;

        function jsonPrint(v) {
            return v !== undefined ? JSON.stringify(v, null, 2) : "null"
        }

        return (
            <div className="result-panel">
                <div className="result-section">
                    <div className="result-title">View: {errorStr ? "(Error)" : ""} </div>
                    {
                        errorStr ?
                            <pre> {errorStr} </pre>
                            :
                            (
                                values ? 
                                    ( values.length == 1 ?
                                        <pre> {jsonPrint(values[0])} </pre> :
                                        <pre> {jsonPrint(values)} </pre>
                                    )
                                : <pre>null</pre>
                            )
                    }
                </div>
                <div className="result-section">
                    <div className="result-title">Msgs:</div>
                    {
                        result.msgs.map(
                            (msg, i) => <div key={i}>{lang_entry_slim.string_of_value(msg)}</div>
                        )
                    }
                </div>
                <div className="result-section">
                    <div className="result-title">Cmds:</div>
                    {
                        result.cmds.map(
                            (cmd, i) => <div key={i}>{lang_entry_slim.string_of_value(cmd)}</div>
                        )
                    }
                </div>

            </div>
        )
    } else {
        return null
    }
}
