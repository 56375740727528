

import React, { useRef, useState, useEffect } from 'react'
import { MdQrCode } from 'react-icons/md';

import QrCode from 'bwax-ui/auxiliary/QrCode'
import ClampedText from 'bwax-ui/components/ClampedText';

import PopoverC from "bwax-ui/components/legacy/Popover"

import './TextDisplay.less'

import MultiTextDisplay, { TipPopover } from 'Client/js/ui/components/MultiTextDisplay';

/**
 * value - it could be 
 *          1) a string
 *          3) an array of string
 * 
 */
export default function TextDisplay({ params }) {
    const { value, maxLines } = params;

    function render() {
        if (Array.isArray(value)) {
            const texts = (value || []).filter(x => !!x);
            return <MultiTextDisplay texts={texts} />
            // return texts.map((t, i) => <div key={i} className="display-text-part">{t}</div>)

        } else {
            if (!value) return null;
            return displayWithQrIcon(value, maxLines);
        }
    }
    return <div className="admin--display-text">{render()}</div>
}


export function displayWithQrIcon(text, maxLines) {
    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    if (text && isValidHttpUrl(text)) {
        return (
            <div>{text}
                <PopoverC trigger="hover" content={
                    <div style={{
                        padding: 16
                    }}>
                        <QrCode
                            info={text}
                            size={96}
                            onGenerated={() => {
                                console.log("Generated", text)
                            }}
                        />
                    </div>
                }>
                    <div style={{
                        display: "inline-block",
                        padding: 2,
                        transform: "translateY(1.5px)",
                        marginLeft: 3,
                        cursor: "pointer",
                        opacity: 0.65
                    }}><MdQrCode />
                    </div>
                </PopoverC></div>
        )
    } else {
        // maxLines
        if (maxLines && maxLines > 0) {
            return (
                <ClampedText text={text} lines={maxLines}
                    tipComponent={TipPopover}
                />
            )
        }
        return text
    }

}
