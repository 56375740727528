
import React from 'react';

import { Icon } from '@ant-design/compatible';

import OperationButtonBox from './OperationButtonBox';

export default function FavoritesButton(props) {

    return (
        <OperationButtonBox
            buttonContent={
                <Icon type="book" />
            }
            panel={
                <div className="favorites-panel operation-panel empty-state">
                    暂时没有收藏页面
                </div>
            }
        />
    )
}
