
import React from 'react'

import { Modal } from '@arco-design/web-react'

import ResourcePanel from 'Client/js/designApp/pages/ResourcePanel';

import './ResourceMananger.less';

// it is a modal
export default function ResourceMananger({ open, setOpen, onFileSelect }) {
    // 暂时直接使用原来的 resource panel

    const onSelect = r => {
        if(r && r["文件"]) {
            const file = r["文件"];
            onFileSelect(file);
            setOpen(false);
        }
    }

    return (
        <Modal
            title={null}
            visible={open}
            onCancel={() => {
                setOpen(false);
            }}
            // onOk={() => {
            //     setOpen(false);
            // }}
            footer={null}
            wrapClassName="lc-resource-manager"
        >
            <ResourcePanel allowCopy={true} onSelect={onSelect} />
        </Modal>

    )
}
