import React from 'react'
import { Spin, Row } from 'antd'

export default function Loading(props) {

  const sizes = {
    xlarge : 900,
    large : 600,
    middle: 300,
    small: 100
  }

  const style = props.size ? {
    height: (sizes[props.size] || 300)
  } : {}


  return (
    <Row
      className="full"
      type="flex"
      justify="center"
      align="middle"
      style={style}
    >
      <Spin size="large" />
    </Row>
  )
}
