

import React, { useEffect, useState } from 'react'

import Tooltip from 'bwax-ui/components/Tooltip';

import PositionEdit from '../components/PositionEdit'
import GradientEdit from '../components/GradientEdit';

export default function RadialGradientEdit({ attributes, activeImageItem, onChange, setColorPickerInPopoverVisible, updateActiveImageItem, activeImageIndex }) {


    const [position, setPosition] = useState('50% 50%')
    const [size, setSize] = useState("farthest-corner")

    const positionRegx = /radial-gradient\(circle (?:.* )?at (\d+(?:\.\d+)?(?:px|vw|vh|%) \d+(?:\.\d+)?(?:px|vw|vh|%)).*\)$/
    const sizeRegx = /radial-gradient\(circle (.*) at.*\)$/
    const unitsArr = ['px', '%', 'vw', 'vh']
    const sizes = [{
        value: 'closest-side',
        text: 'CS',
        tooltip: "Closest side extends the gradient from the defined position to the closest side."
    }, {
        value: 'closest-corner',
        text: 'CC',
        tooltip: "Closest corner extends the gradient from the defined position to the closest corner."
    }, {
        value: 'farthest-side',
        text: 'FS',
        tooltip: "Farthest side extends the gradient from the defined position to the farthest side."
    }, {
        value: 'farthest-corner',
        text: 'FC',
        tooltip: "Farthest corner extends the gradient from the defined position to the farthest corner."
    }]

    useEffect(() => {
        const positionMatchedResult = activeImageItem.gradient.match(positionRegx)
        const sizeMatchedResult = activeImageItem.gradient.match(sizeRegx)

        if (positionMatchedResult) {
            setPosition(positionMatchedResult[1])
        }

        if(sizeMatchedResult) {
            setSize(sizeMatchedResult[1])
        }

    }, [])

    function updateBackgroundImage (gradient) {
        const newBgImages = attributes['backgroundImage'].map((image, index) => {
            if(index === activeImageIndex) {
                return gradient 
            } else {
                return image
            }
        })
        updateActiveImageItem({ ...activeImageItem, gradient })
        onChange({ backgroundImage: newBgImages })
    }

    function updatePosition(positionValue) {
        setPosition(positionValue)

        const originalPosition = activeImageItem.gradient.match(positionRegx)[1]
        const newBgImage = activeImageItem.gradient.replace(originalPosition, positionValue)

        updateBackgroundImage(newBgImage)
    }

    function updateSize(sizevValue) {
        setSize(sizevValue)

        const originalSize = activeImageItem.gradient.match(sizeRegx)[1]
        const newBgImage = activeImageItem.gradient.replace(originalSize, sizevValue)

        updateBackgroundImage(newBgImage)
    }

    return (
        <div className='radial-gradient-container'>
            <div className='gradient-position'>
                <div>Position</div>
                <PositionEdit attribute={position} unitsArr={unitsArr}
                    onChange={value => updatePosition(value)}
                />
            </div>
            <div className='size-edit'>
                <div className='style-with-options'>
                    <div>Size</div>
                    <div className='options'>
                        {
                            sizes.map((op, index) => {
                                const { value, text, tooltip } = op
                                const isActive = size === value
                                return (
                                    <Tooltip key={index} text={tooltip} className="limited-width-tooltip">
                                        <div className={`option-box text ${isActive ? 'active' : ''}`}
                                            onClick={() => updateSize(value)}
                                        >
                                            {text}
                                        </div>
                                    </Tooltip>
                                    
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <GradientEdit activeImageItem={activeImageItem} updateBackgroundImage={updateBackgroundImage} 
                setColorPickerInPopoverVisible={setColorPickerInPopoverVisible}
            />
        </div>
    )
}
