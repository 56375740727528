// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../re/BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Widget_impl_input$BwaxAdmin = require("./impl/widget_impl_input.bs.js");
var Widget_impl_data_ui$BwaxAdmin = require("./impl/widget_impl_data_ui.bs.js");
var Widget_impl_misc_ui$BwaxAdmin = require("./impl/widget_impl_misc_ui.bs.js");
var Widget_impl_styled_ui$BwaxAdmin = require("./impl/widget_impl_styled_ui.bs.js");
var Widget_impl_custom_page_component$BwaxAdmin = require("./impl/widget_impl_custom_page_component.bs.js");

var renderers_000 = /* tuple */[
  Widget_impl_input$BwaxAdmin.module_name,
  Widget_impl_input$BwaxAdmin.render_element
];

var renderers_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      Widget_impl_data_ui$BwaxAdmin.module_name,
      Widget_impl_data_ui$BwaxAdmin.render_element
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          Widget_impl_misc_ui$BwaxAdmin.module_name,
          Widget_impl_misc_ui$BwaxAdmin.render_element
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              Widget_impl_styled_ui$BwaxAdmin.module_name,
              Widget_impl_styled_ui$BwaxAdmin.render_element
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  Widget_impl_custom_page_component$BwaxAdmin.module_name,
                  Widget_impl_custom_page_component$BwaxAdmin.render_element
                ],
                /* [] */0
              ])
          ])
      ])
  ]);

var renderers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    renderers_000,
    renderers_001
  ]);

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, name, rawContent) {
  var match = Plate$BwaxAdmin.List.assoc(name, renderers);
  if (match !== undefined) {
    return Curry._7(match, doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent);
  } else {
    var partial_arg = "No renderer for " + name;
    return (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg, param, param$1, param$2, param$3);
      });
  }
}

exports.renderers = renderers;
exports.render_element = render_element;
/* Plate-BwaxAdmin Not a pure module */
