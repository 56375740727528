import { 
    foldNodeProp, foldInside, indentNodeProp, LRLanguage, LanguageSupport,
    HighlightStyle, syntaxHighlighting,
} from "@codemirror/language"

import { styleTags, tags as t } from "@lezer/highlight"

import { parser } from 'bwax/lang/ore.parser';

export function getOreLanguageSupport(top) {

    const parserWithMetadata = parser.configure({
        top, // could be undefined
        props: [
            styleTags({
                LineComment: t.lineComment,
                BlockComment: t.blockComment,
                Boolean: t.bool,
                "Pow Mul Div Mod Add Sub Gt Ge Le Lt Ne Eq And Or Xor Not and or xor not Cons Append PipeTo PipeFrom CompFrom CompTo MonadBind MonadNext ExtractFrom": t.operator,
                "CapName DotCapName": t.typeName,
                "ConstrDecl/ConstrTypeDecl/CapName ConstrDecl/Constr0TypeDecl/CapName PatternConstructor/CapName True False": t.tagName,
                "PatternVar/Name": t.variableName,
                "String String/OpenQuote String/RegularStringPart  String/CloseQuote Char": t.string,
                "Integer FloatNumber": t.number,
                "IntegerRangeStart": t.number,
                "TwoDots": t.number,
                "FunctionDef/Name ApplyExpr/Ref/Name": t.function(t.variableName),
                "VarTypeDecl/Name TypeDef/Name": t.labelName,
                "ImportExposingAll/CapName QualRef/CapName ImportExposing/CapName": t.namespace,
                "ImportExposingAll/DotCapName QualRef/DotCapName ImportExposing/DotCapName": t.namespace,
                "RecBind/Name FieldTypeDecl/Name": t.propertyName,
                "( ) Unit": t.paren,
                "[ ]": t.squareBracket,
                "{ }": t.brace,
                "< >": t.angleBracket,
                "= | Semicolon : Ellipsis , Sharp Wave ->": t.paren, // 跟 paren 一样颜色好了
                // keywords:
                "module exposing import as if then else case of let in type alias opaque external do": t.keyword
            }),
            indentNodeProp.add({
                "Expr LetExpr": context => {                   
                    return context.column(context.node.from) + context.unit;
                },
                "List Record WoRecordList Tuple CaseExpr DoExpr": context => {
                    // console.log(context)
                    return context.column(context.node.from)
                },
                Def: context => context.column(context.node.from) + context.unit
            }),
            foldNodeProp.add({
                "List Record Tuple WoRecordList": foldInside,
                "ValueDef FunctionDef": node => {                
                    return { from: node.lastChild.prevSibling.from, to: node.lastChild.to }
                }
            })
        ]
    })

    const language = LRLanguage.define({
        parser: parserWithMetadata,
        languageData: {
            commentTokens: { line: "--" }
        }
    })

    const languageSupport = new LanguageSupport(language, [])

    return languageSupport;
}

export default {
    "ore": _ => getOreLanguageSupport(),
    "ore-expr": _ => getOreLanguageSupport("SingleExpr")
}



// styles:
export const lightStyle = syntaxHighlighting(HighlightStyle.define([
    {
        tag: t.link,
        textDecoration: "underline"
    },
    {
        tag: t.heading,
        textDecoration: "underline",
        fontWeight: "bold"
    },
    {
        tag: t.emphasis,
        fontStyle: "italic"
    },
    {
        tag: t.strong,
        fontWeight: "bold"
    },
    {
        tag: t.strikethrough,
        textDecoration: "line-through"
    },

    {
        tag: [t.atom, t.bool, t.url, t.contentSeparator ],
        color: "#219"
    },
    {
        tag: [t.literal, t.inserted],
        color: "#164"
    },
    {
        tag: [t.string, t.deleted],
        // color: "#a11"
        color: "#EF5211"
        // color: "#FF8C00"
    },
    {
        tag: [t.regexp, t.escape, /*@__PURE__*/t.special(t.string)],
        color: "#e40"
    },
    {
        tag: /*@__PURE__*/t.definition(t.variableName),
        color: "#00f"
    },
    {
        tag: /*@__PURE__*/t.local(t.variableName),
        color: "#30a"
    },

    {
        tag: t.className,
        color: "#167"
    },
    {
        tag: [/*@__PURE__*/t.special(t.variableName), t.macroName],
        color: "#256"
    },
    {
        tag: /*@__PURE__*/t.definition(t.propertyName),
        color: "#00c"
    },

    {
        tag: t.meta,
        color: "#7a757a"
    },
    {
        tag: t.invalid,
        color: "#f00"
    },
    {
        tag: t.operator,
        color: "#7B7D7D"
    },
    {
        tag: [ t.paren, t.angleBracket, t.squareBracket, t.brace ],
        color: "#A6ACAF"
    },
    {
        tag: t.keyword,
        // color: "#708"
        color: "#c800a4"
    },
    {
        tag: t.namespace,
        // color: "#2471A3"
        // color: "#3F51B5"
        color: "#673AB7"
    },
    {
        tag: t.typeName,
        // color: "#085"
        // color: "#2E86C1"
        // color: "#00838F",
        color: "#0288D1"
        // fontWeight: 500
    },
    {
        tag: [t.tagName ],
        // color: "#388E3C"
        // color: "#43A047"
        color: "#0097A7"
       
        // color: "#558B2F"
    },
    {
        tag: [ t.labelName ],
        color: "#9E9E9E"
    },
    {
        tag: t.comment,
        // color: "#940"
        // color: "#4CAF50"
        color: "#8BC34A"
        // color: "#9CCC65"
    },
    // {
    //     tag: t.function(t.variableName),
    //     color: "#117A65",
    // }
]));