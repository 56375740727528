import React, { useState, useEffect, useContext, useRef } from 'react'


import RecordSelectSearch from 'Client/js/ui/components/RecordSelectSearch'
import RecordSelectCascader from './RecordSelectCascader'

import { evaluateWithQuery } from 'bwax'

import QueryTargetContext from 'bwax-ui/legacy/store/QueryTargetContext';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'


export default function RecordSelect(props) {

    const { params, allEntities, allDataTypes } = props;
    const { cascading, entityName, isSandbox, multivalued } = params;


    const queryTarget = useContext(QueryTargetContext) || "data";

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)

    const entity = allEntities.find(e => e.name === entityName)

    function getCascadingPath(cascading) {
        // 返回数组，每个包含 { field, targetEntity }
        if (!cascading) {
            return undefined
        }
        const split = cascading.split(".");
        let path = [];

        let currentEntity = entity;
        for (let i in split) {
            const fieldName = split[i];

            const field = [...currentEntity.fields, currentEntity.virtualFields].find(f => f.name == fieldName);
            if (!field) {
                console.warn("Cascading", cascading, ": field `" + fieldName + "` is not found");
                return undefined
            }
            if (field.type == "Link" && field.options.entity) {
                const targetEntity = allEntities.find(e => e.name == field.options.entity || e.key == field.options.entity);
                if (!targetEntity) {
                    console.warn("Cascading", cascading, ": entity `" + field.options.entity + "` is not found");
                    return undefined
                }
                path = [{ field, targetEntity }, ...path]

                currentEntity = targetEntity
            } else {
                console.warn("Cascading", cascading, ": field `" + fieldName + "` is not a Link");
                return undefined
            }
        }
        return path
    }

    const evaluate = (def, baseData) => {
        return evaluateWithQuery(
            def,
            baseData,
            {
                allDataTypes, allEntities, queryTarget, sessionToken, tenantCode,
                sandbox: (isSandbox === undefined ? sandbox : isSandbox)
            }
        )
    }

    // 如果 cascading 是 valid 的，则使用 cascader，否则使用 RecordSelectSearch
    const cascadingPath = getCascadingPath(cascading);

    // 另外不支持 multivalued
    if (!multivalued && cascadingPath && cascadingPath.length > 0 && true) {
        return (
            <RecordSelectCascader
                cascadingPath={cascadingPath}
                evaluate={evaluate}
                entity={entity}
                {...props}
            />
        )
    } else {
        return <RecordSelectSearch {...props} evaluate={evaluate} />
    }

}

