
import React, { useState, useEffect } from 'react'

import { Input, Popover } from '@arco-design/web-react';
import { SketchPicker } from 'react-color';
import { transparentImgUrl, colorRgbRegx, colorHexRegx } from '../StyleForm';

import './Backgrounds.less'

export default function OverlayColor({ attributes, activeImageItem, onChange, setColorPickerInPopoverVisible, updateActiveImageItem, activeImageIndex}) {

    const [colorPickerVisible, setColorPickerVisible] = useState(false)
    const [changedColor, setChangedColor] = useState('rgba(0,0,0,0.5)')

    useEffect(() => {
        setChangedColor(activeImageItem.title)
    }, [activeImageItem])

    function inputOverlayColor (color) {
        setChangedColor(color)
    }

    function updateBackgroundImage (gradient) {
        const newBgImages = attributes['backgroundImage'].map((image, index) => {
            if(index === activeImageIndex) {
                return gradient 
            } else {
                return image
            }
        })
        updateActiveImageItem({ ...activeImageItem, gradient, title: changedColor })
        onChange({ backgroundImage: newBgImages })
    }

    function inputOverlayColorBlur () {
        if(changedColor === "transparent") {
            updateBackgroundImage(`linear-gradient(180deg, transparent 0%, transparent 100%)`)
        } else if(changedColor.startsWith("rgb")) {
            if(changedColor.match(colorRgbRegx)) {
                const matchRgbResult = changedColor.match(colorRgbRegx)
                const convertedColor = `rgba(${matchRgbResult[1]},${matchRgbResult[2]},${matchRgbResult[3]},${matchRgbResult[4] ? matchRgbResult[4] : 1})`
                updateBackgroundImage(`linear-gradient(180deg, ${convertedColor} 0%, ${convertedColor} 100%)`)
            }
        } else if(changedColor.startsWith('#')) {
            if(changedColor.match(colorHexRegx)) {
                const matchedResult = changedColor.match(colorHexRegx)
                const colorStr = matchedResult[1]
                let r = 0
                let g = 0
                let b = 0
                if(colorStr.length === 3) {
                    r = parseInt(`0x${colorStr[0]}${colorStr[0]}`)
                    g = parseInt(`0x${colorStr[1]}${colorStr[1]}`)
                    b = parseInt(`0x${colorStr[2]}${colorStr[2]}`)
                } else {
                    r = parseInt(`0x${colorStr[0]}${colorStr[1]}`)
                    g = parseInt(`0x${colorStr[2]}${colorStr[3]}`)
                    b = parseInt(`0x${colorStr[4]}${colorStr[5]}`)
                }
                updateBackgroundImage(`linear-gradient(180deg, rgb(${r},${g},${b}) 0%, rgb(${r},${g},${b}) 100%)`)
            }
        }
    }

    function renderGradientColor () {

        function renderColorPicker () {

            function onChangeComplete (color) {
                const { r, g, b, a } = color.rgb
                const c = `rgba(${r}, ${g}, ${b}, ${a})`
                setChangedColor(c)
                updateBackgroundImage(`linear-gradient(${c}, ${c})`)
            }
        
            return (
                <SketchPicker
                    color={changedColor}
                    onChangeComplete={onChangeComplete} 
                />
            )
        }

        return (
            <Popover content={renderColorPicker()} position="bottom" trigger="click" popupVisible={colorPickerVisible} className="overlay-color-picker-popover" 
                onVisibleChange={visible => {
                    setColorPickerVisible(visible)
                    setColorPickerInPopoverVisible(visible)
                }}>
                <div className='bg-color' style={{ 
                    backgroundImage: [`linear-gradient(180deg, ${changedColor} 0%, ${changedColor} 100%)`, `url(${transparentImgUrl})`] 
                }} />
            </Popover>
           
        )
    }

    return (
        <div className="color-overlay-container">
            <div>Color</div>
            <Input value={changedColor} size="mini" prefix={renderGradientColor()} onChange={value => inputOverlayColor(value)}
                onBlur={() => inputOverlayColorBlur()} onPressEnter={() => inputOverlayColorBlur()}
            />
        </div>
    )
}
