

import React, { useState, useRef, useEffect } from 'react'

import { FaUndo, FaRedo } from 'react-icons/fa'
import isEqual from 'lodash/isEqual'
import { Input, Select } from '@arco-design/web-react';
const Option = Select.Option;

import './AngleEdit.less'

export default function AngleEdit({ angle, onChange }) {

    const [editAngle, setEditAngle] = useState({ value: 180, unit: 'deg' })
    const circleRef = useRef(null)

    useEffect(() => {
        if(!isEqual(angle, editAngle)) {
            setEditAngle({
                value: angle.value,
                unit: angle.unit
            })
        }
    }, [angle])

    function mouseDown(e) {

        if(circleRef && circleRef.current && typeof (document) !== "undefined") {

            const rect = circleRef.current.getBoundingClientRect()
            // 圆心坐标
            const centerX = rect.x + rect.width / 2
            const centerY = rect.y + rect.height / 2

            if(e.clientX !== centerX || e.clientY !== centerY) {
                const radian = Math.atan2(e.clientY - centerY, e.clientX - centerX); // 返回来的是弧度
                const angle = parseInt(180 / Math.PI * radian); // 根据弧度计算角度
                onChange({ ...editAngle, value: angle < -90 ? angle + 450 : angle + 90 })
            }

            function mouseMove(e) {
                if(circleRef && circleRef.current) {
        
                    if(e.clientX !== centerX || e.clientY !== centerY) {
                        const radian = Math.atan2(e.clientY - centerY, e.clientX - centerX)
                        const angle = parseInt(180 / Math.PI * radian)
                        onChange({ ...editAngle, value: angle < -90 ? angle + 450 : angle + 90 })
                    }
                }
            }

            function mouseUp() {
                document.removeEventListener('mousemove', mouseMove, false);
                document.removeEventListener('mouseup', mouseUp, false);
            }

            document.addEventListener('mousemove', mouseMove, false);
            document.addEventListener('mouseup', mouseUp, false)
        }
    };

    function rotateRight() {
        const changedAngleValue = (parseInt(editAngle.value) + 45) % 360
        onChange({ ...editAngle, value: changedAngleValue })
    }

    function rotateLeft() {
        const changedAngleValue = (360 + parseInt(editAngle.value) - 45) % 360
        onChange({ ...editAngle, value: changedAngleValue })
    }

    /**
     * deg: 度，一个完整的圆是 360deg.
     * grad: 百分度，一个完整的圆是 400grad.
     * rad: 弧度，一个完整的圆是 2π 弧度，约等于 6.2832rad。
     * turn: 圈数，一个完整的圆是 1turn
     */
    const units = ['deg', 'rad', 'grad', 'turn']

    const unitsSelect = (
        <Select value={editAngle.unit} size={'mini'} arrowIcon={null} className="unit-select" 
            getPopupContainer={node => node} triggerProps={{ autoAlignPopupWidth: false }} 
            onChange={unit => {
                onChange({ ...editAngle, unit: unit })
            }} 
        >
            {units.map(unit => (
                <Option key={unit} value={unit} className='unit-option'>
                    {unit.toUpperCase()}
                </Option>
            ))}
        </Select>
    )

    return (
        <div className='angle-edit-container'>
            Angle
            <div className='angle-edit'>
                <div>
                    <svg ref={circleRef} width="22" height="22" viewBox="0 0 22 22" style={{ transform: `rotate(${editAngle.value}${editAngle.unit})` }}
                        onMouseDown={e => mouseDown(e)}
                    >
                        <circle cx="11" cy="11" r="11" fill="currentColor"></circle>
                        <circle cx="11" cy="4" r="2" fill="#EEE"></circle>
                    </svg>
                    <FaUndo onClick={() => rotateLeft()}/>
                    <FaRedo onClick={() => rotateRight()}/>
                </div>

                <Input size="mini" value={editAngle.value} suffix={unitsSelect}
                    onChange={value => updateAngleValue(value)} style={{ width: '4.5rem' }}
                />
            </div>
        </div>
    )
}
