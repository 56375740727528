import BwaxManager from './extensions/BwaxManager/admin'

import WxMp from './extensions/WxMp/admin'
import Auth from './extensions/Auth/admin'
import YQYL from './extensions/YQYL/admin'
import XET from './extensions/XET/admin'

export default {
    BwaxManager,
    WxMp,
    Auth,
    YQYL,
    XET,
}
