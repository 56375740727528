

import { Input } from '@arco-design/web-react';
import React, { useEffect, useState } from 'react'

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

import prettyPrintLang from 'bwax/ml/printer/prettyPrintLang';

import prettyPrintCss from 'bwax/ml/printer/prettyPrintCss';

import queryString from 'query-string'

const TextArea = Input.TextArea;

const initialOne = `
view (model, webEnv): (Model, UI.WebEnv) -> a = 
  node 
    "div"
    [ attribute "className" "wrapper"
    , style "display" "flex"
    , style "flexDirection" "column"
    , style "alignItems" "start"
    , style "cursor" "pointer"
    , style "userSelect" "none"
    , onClick Pressed
    ]
    [ node 
        "div" 
        [ style "padding" "1rem 2rem", style "backgroundColor" "LightSalmon" ] 
        [ text <|
            case model.name of 
            | Just name -> "Hello, " ++ name 
            | Nothing -> "Hello HTML!" 
        ]

    , node
        "input"
        [ onInput ChangedName ]
        []
    ]
;
`

export default function TestPrettyPrint({ location }) {

    const [ source, setSource ] = useStateWithLocalCache("test-code", initialOne);
    const [ formatted, setFormatted ] = useState("");

    const params = queryString.parse(location.search);


    useEffect(() => {
        setFormatted(prettyPrintLang(40, source, params.top));
    }, [ source ]);

    return (
        <div style={{
            display: "flex",
            width: "100%",
            height: "100%",
        }}>
            <TextArea style={{
                flex: "1 1 0%",
                height: "100%",
            }} value={source} onChange={v => {
                setSource(v)
            }}>
            </TextArea>
            <pre style={{
                flex: "1 1 0%",
                height: "100%",
                overflow: "auto",
                padding: "1rem"
            }}>
                { formatted }
            </pre>
        </div>
    )
}

