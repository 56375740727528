

import React from 'react'

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import FormData from './FormData';

import Modal from 'Client/ml/ui/components/Modal';
import Button from 'Client/ml/ui/components/Button';

import "./FormDataAdd.less";

// it is a modal
export default function DataFormAdd(props) {

    const { form, visible, setVisible, facade, onAdded, initialValues = {}, usedValues } = props;

    const fixedValues = {};
    const defaultValues = {};

    const fieldsToUse = form.字段;
    const allKeys = fieldsToUse.map(f => f.name);

    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, defaultValues, [], "no-key", false)


    const involvedEditKeys = fieldsToUse.filter(
        /// TODO 关于 fixedValue 的处理，应该做一个更好的抽象：
        /// 目前，fixedValue 在如下四个地方皆有处理：
        ///     1. 外部检测 dirtyValue
        ///     2. useEditingValue
        f => fixedValues[f.name] === undefined
    ).map(f => f.name)

    const hasDirtyValue = involvedEditKeys.some(key => {
        return dirtyValues[key] !== undefined && dirtyValues[key] !== defaultValues[key]
    });

    // all the require field must be there:
    const requiredFields = fieldsToUse.filter(
        f => f.required
    )
    const requiredEditKeys = requiredFields.map(f => f.name);
    const isMissingRequiredFields = requiredEditKeys.some(key => validated[key] === undefined)

    const noValues = involvedEditKeys.every(key => dirtyValues[key] === undefined)

    const cancelButton = {
        key: "cancel",
        label: (hasDirtyValue ? "撤销" : "关闭"),
        onClick: _ => {
            if (hasDirtyValue) {
                // 撤销要用 reset，
                // 因为 rollback 的实现没有考虑 defaultValues 
                reset(defaultValues, initialValues, fixedValues)
                // rollback(involvedEditKeys);
            } else {
                setVisible(false);
            }
        }
    };

    const confirmButton = {
        key: "confirm",
        label: "确定添加",
        buttonType: "primary",
        disabled: isMissingRequiredFields || noValues,
        onClick: async _ => {
            if (hasDirtyValue) {
                const formData = fieldsToUse.reduce((acc, f) => {
                    const { name, key } = f
                    const v = dirtyValues[name] || initialValues[name];
                    return {
                        ...acc,
                        ...(v !== undefined ? { [key]: v } : {})
                    } 
                }, {});

                const mutationObj = {
                    entityName: "表单数据记录",
                    formData: {
                        表单: form.id,
                        数据: formData
                    },
                    fieldPaths: []
                }

                const [result, error] = await facade.add(mutationObj, {
                    // additionalQueryParams: {
                    //     formId: form.id
                    // }
                });
                clearEditing(involvedEditKeys);
                if(onAdded) { onAdded() }
                
                setVisible(false);
            }
        }
    }

    const footer = [cancelButton, confirmButton].map(params => <Button {...params} />)

    return (
        <Modal visible={visible} title="添加数据" footer={footer} destroyOnClose={true}
            onCancel={() => {
                // 如果 dirty 的话，需要提示：
                if (hasDirtyValue) {
                    Modal.confirm({
                        title: '您有未保存的内容',
                        content: '确定要关闭吗？',
                        okText: '关闭',
                        cancelText: '等等',
                        className: "admin--edit-modal-confirm",
                        onOk: () => {
                            rollback(involvedEditKeys);
                            setVisible(false);
                        }
                    });
                } else {
                    setVisible(false)
                }
            }}>
            <div className="lc-form-data-add">
                <FormData {...{
                    form, editing, updateEditing, validated,
                    initialValues, fixedValues, 
                    facade,
                    usedValues,
                }} />
            </div>
        </Modal>
    )
}


