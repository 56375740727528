
import React from 'react'

import Upload from 'bwax-ui/auxiliary/uikit/Upload'

import {insertImage} from '../image'

export default function ImageButton(props) {

  const {uploadImage, setEditorState, getEditorState} = props


  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    customRequest: (f) => {

      console.log("To insert:", uploadImage)

      insertImage(f.file, null, uploadImage, {
        setEditorState, getEditorState
      })
    }
  }

  return (
    <Upload {...uploadProps}>
      <button className={"editor-button"}>
        <i className={'fa fa-image'} aria-hidden="true"></i>
      </button>
    </Upload>
  )

}
