

import React from 'react'

import numeral from 'numeral'

export default function Number(props) {
    const { params } = props
    const { format: passedInFormat, value, decimals = 2 } = params

    const format = passedInFormat || buildFormat(decimals);

    // console.log(">>> ", value, decimals, format);
    let fm = v => v === undefined || v === null ? "" : 
        (format ? numeral(v).format(format): v);

    if(Array.isArray(value)) {
        return value.map((v, i) => {
            return fm(v)
        }).join("; ")
    }
    // return <span style={{textAlign: "right"}}>{fm(value)}</span>

    return fm(value)
}

function buildFormat (decimals) {
    if(decimals === undefined) {
        return null
    }
    let dec = (typeof(decimals) == 'string' ? parseInt(decimals): decimals);

    let format = dec > 0 ? "0,0." : "0,0";
    for(let i = 0; i < dec; i++) {
        format = format + '0'
    };
    return format;
}


