
import React, { useState } from 'react'

import "./TopBar.less"

import { Helmet } from 'react-helmet-async';

import getImageURL from 'bwax-ui/getImageURL'

import AppSelectorDrawer from './AppSelectorDrawer';

import { AiOutlineAppstore } from 'react-icons/ai';

export default function TopBar(props) {

    const {
        baseSettings,
        rightActions, style = {}, className,
        size = "medium" /* or large */,
        applications, currentApplication, onApplicationSelected,

        compact,

        siteTitle, logoURL,


        noLogo = false,

        alwaysDisplayAppPicker = false,

        appSelectorItems = [],
    } = props;

    const { name, logo: logoInBaseSetting } = baseSettings || {};

    const [appSelectorVisible, setAppSelectorVisible] = useState(false);

    const siteName = siteTitle === undefined ? null : (siteTitle || name || "管理后台");

    const logo = logoURL === undefined ? null : (logoURL || logoInBaseSetting);

    const left = (
        <div className="left-group item-group">
            { applications.length > 1 || alwaysDisplayAppPicker ? (
                    <div className="app-selector-button top-bar-button" onClick={() => {
                        setAppSelectorVisible(prev => !prev);
                    }}>
                        <AiOutlineAppstore />
                    </div>
                ) : <div style={{ width: "1rem" }} />
            }
            {
                noLogo ? null : (
                    <>
                        {logo ?
                            <div className="site-logo-wrapper" style={{ marginRight: 4 }}>
                                <div className="site-logo" style={{
                                    backgroundImage: `url(${getImageURL(logo, "thumbnail")})`,
                                }}>
                                </div>
                            </div> :
                            null
                        }
                        <div className="site-name">
                            {siteName}
                        </div>
                    </>
                )
            }
            {
                currentApplication && (currentApplication.name != "默认应用" || noLogo) ?
                    <>
                        { noLogo ? null : <div style={{ margin: "0 0.25rem" }}>|</div> }
                        <div className="application-name">
                            {currentApplication.name}
                        </div>
                    </> : null
            }
        </div>
    )

    const right = compact ? null : (
        <div className="right-group item-group">
            {rightActions}
        </div>
    )

    return (
        <>
            <SiteHead {...{ siteTitle, logoURL: getImageURL(logo, "thumbnail") }} />
            <div className={"top-bar " + size + (className ? " " + className : "")} style={style}>
                {/* HELLO WORLD */}            
                {left}
                {right}
                <AppSelectorDrawer
                    {...{
                        logo,
                        siteName,
                        visible: appSelectorVisible,
                        onClose: () => {
                            setAppSelectorVisible(false)
                        },
                        applications,
                        currentApplication,
                        onApplicationSelected,

                        appSelectorItems,
                    }}
                />
            </div>
        </>

    )
}

function SiteHead({ siteTitle, logoURL }) {

    function getSiteName() {
        if (typeof (document) !== "undefined") {
            if (document && document.baseSettings && document.baseSettings.name) {
                return document.baseSettings.name
            } else {
                return null
            }
        } else {
            return null
        }
    }


    function getTitle() {
        if(siteTitle) {
            return siteTitle
        }
        const siteName = getSiteName();
        if (siteName) {
            return siteName + " | 管理后台"
        } else {
            return "管理后台"
        }
    }

    if(siteTitle === undefined) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{getTitle()}</title>
                {logoURL ? <link rel="icon" type="image/png" href={logoURL} /> : null}
            </Helmet>
        </>
    )
}