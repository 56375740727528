import React, { useEffect, useState, useRef } from 'react'

import Message from 'Client/js/ui/Message';

import { Icon } from '@ant-design/compatible';

import SelectImageMaterialModal from './SelectImageMaterialModal'


import './WxMp_Materials.less'

export default function WxMp_Image (props) {

    const { queryRunner, mpImage, mpImageInput, deleteReplies } = props

    const [ defaultImageUrl, setDefaultImageUrl ] = useState()
    const [ modalVisible, setModalVisible ] = useState(false)

    const inputFileRef = useRef(null)

    useEffect(() => {
        (async () => {
            if(mpImage) {
                const condition = [[{
                    "field": "素材ID",
                    "op": "eq",
                    "value": mpImage
                }]]
                const initImages = await loadInitImage(queryRunner, condition)
                const initImagesEdges = initImages ? initImages.edges : []
                console.log("initImages: ", initImages);
                if(initImagesEdges.length > 0) {
                    setDefaultImageUrl(initImagesEdges[0].node.imageUrl.replace('http://mmbiz.qpic.cn', ''))
                }
            }
        })()
    }, [ mpImage ])

    function showModal () {
        setModalVisible(true)
    }

    function hideModal(){
        setModalVisible(false)
    }

    function changeImage () {
        setModalVisible(true)
    }

    function selectFile () {
        inputFileRef && inputFileRef.current && inputFileRef.current.click()
    }

    function selectImage (e) {
        if(e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const formValid = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/jpeg'
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!formValid) {
                Message.error('图片格式错误，请上传文件后缀名为 png，gif 或者 jpg 的图片');
                return
            } else if (!isLt2M) {
                Message.error('图片大小不能超过2MB!');
                return
            } else {
                (async () => {
                    const result = await uploadImage(queryRunner, file)
                    if(result && result.addedMaterial && result.addedMaterial.media_id) {
                        mpImageInput(result.addedMaterial.media_id)
                    }
                })()
            }
        }
    }

    return (
        <div className="wxmp-materials">
            <div className="materials-box">
                <label>图片消息</label>
                {
                    mpImage ? (
                        <div className="show-image">
                            <img src={defaultImageUrl}/>
                            <div className="swap" onClick={() => changeImage()}>
                                <Icon type="swap" style={{ marginRight: 4 }}/>
                                替换素材
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="wxmp-select" onClick={() => showModal()}>
                                <Icon type="folder"/>
                                从素材库中选择
                            </div>
                            <div className="wxmp-upload" onClick={() => selectFile()}>
                                <Icon type="picture"/>
                                上传图片
                                <input type="file" ref={inputFileRef} onChange={(e) => selectImage(e)}/>
                            </div>
                        </>
                    )
                }
                <div className="delete" onClick={() => deleteReplies()}>
                    <Icon type="close" />
                </div>
            </div>
            <SelectImageMaterialModal
                visible={modalVisible}
                hideModal={() => hideModal()}
                imageSelect={(image) => mpImageInput(image)}
                queryRunner={queryRunner}
            />
        </div>
    )
}

const QueryWxMpMaterials = `
    query (
        $condition: [[ListWxMpEntityImageMaterialConditionInput]]
    ) {
        listWxMpEntityImageMaterial(condition: $condition ) {
            edges {
                node {
                    id
                    mediaId
                    imageUrl
                    name
                    updatedAt
                }
            }
        }
    }
`

async function loadInitImage(queryRunner, condition) {
    const resp = await queryRunner(QueryWxMpMaterials)({
        condition
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityImageMaterial
    }
}

async function uploadImage(queryRunner, file) {
    const resp = await queryRunner(MutUploadImage)({
        input: {
            clientMutationId: `${Date.now()}`
        }
    }, file)
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data.uploadWxMpImageMaterial
    }
}

const MutUploadImage = `
    mutation UploadWxMpImageMaterialMutation(
        $input: UploadWxMpImageMaterialInput!
    ) {
        uploadWxMpImageMaterial(input: $input) {
            addedMaterial
        }
    }
`
