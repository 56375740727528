

import { useState, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'

import invariant from 'invariant'

import { mapObj, filterObj, hashCode } from 'bwax/utils'
import Node from 'bwax/core/Node';
import { lookupValue, NodeHolder } from 'bwax/core/store/DataBook'
import {
    printQuery, prepareVariables
} from 'bwax/query/resolveAndRunQuery'
import loadQueryData from 'bwax-ui/legacy/store/loaders/loadQueryData';
import useDataLoader from 'bwax-ui/legacy/store/useDataLoader';

const __query__ = '__query__'
const __for__ = '__for__'

const PRESERVED = ['action', 'timer']


function extractQueries(def) {
    return Object.keys(def).reduce((acc, name) => (
        (name === __for__) ? acc : {
            ...acc,
            [name]: def[name]
        }
    ), {})
}


/// 只考虑一层的 __query__
export default function useQueriedValue(def,
    {
        roots,
        baseData,
        allEntities,
        allDataTypes,
        queryTarget = 'data' /// 'data' or 'definition', or 'sandbox'
    }
) {

    /// state start ====
    const [extraState, setExtraState] = useState({})
    const [inputState, setInputState] = useState(null)

    // internal states
    /// load-more data  { <queryName> : [ { key, data} ]} 
    /// key 只是用来从服务器那东西的
    const [moreDataKeys, setMoreDataKeys] = useState({})

    // 不同的 partialVariables 可能对应不同的 queryData
    // 也可能因为 pagination 有不同的其他 page 的key
    // { key } = data

    const [lastQueryData, setLastQueryData] = useState(undefined)
    const [currentPartialVariables, setCurrentPartialVariables] = useState({})
    /// state end =====

    function preProcess() {

        const n = Node.buildWithTypes(def, roots, { allEntities, allDataTypes })

        const startingValue = lookupValue(n, {
            ...baseData, ...extraState,
            "input": inputState
        }, PRESERVED)
        invariant(startingValue, "No starting value")
        if (!(startingValue[__query__] instanceof NodeHolder)) {
            /// no query:
            return { localData: {}, targetNode: n }
        }

        //// handle query:
        const { node, value, localData } = startingValue[__query__]
        const queries = extractQueries(value)
        const targetDef = node.nodeDef[__for__]

        const targetNode = Node.buildWithTypes(targetDef, {
            ...queries, ...roots
        }, { allEntities, allDataTypes })

        return {
            localData,
            queries,
            targetNode
        }
    }

    ///// load the query data with queryNames + partialVariables
    ///// useDataLoader: if the queryData is there, then go ahead and use it, 
    ////                 if there no query data, simply wait for its availability

    function buildQueryKey(targetNode, queriesToLoad, partialVariables) {


        const queryText = printQuery(
            queriesToLoad, targetNode, { allDataTypes, allEntities }
        )

        const variables = prepareVariables(
            queriesToLoad, allEntities, partialVariables
        )

        const key = `q_${queryTarget}_${hashCode(queryText)}_${hashCode(variables)}`

        return key
    }

    function buildDataLoader(partialVariables) {
        const { queries, targetNode } = preProcess()

        if (queries === undefined) {
            return { key: "NoData", loader: async () => ({}) }
        }
        const queriesToLoad = determineQueriesToLoad(queries, partialVariables)

        const key = buildQueryKey(targetNode, queriesToLoad, partialVariables)

        const loader = loadQueryData(queriesToLoad, targetNode, partialVariables, {
            allDataTypes, allEntities, queryTarget
        })

        return { key, loader }
    }

    const { key, loader } = buildDataLoader(currentPartialVariables)

    // //// 
    // useEffect(() => {
    //     if(key !== "NoData") {
    //         setMoreDataKeys({})
    //     }
    // }, [ key ]) /// if query key changes, clear the more data

    const { getData, forceLoad } = useDataLoader({ [key]: loader })

    const queryData = (() => {
        const [currentData] = getData(key)

        // if not yet loaded try to use last data
        // 这是因为更新了 partial variables 导致数据还没有获取时，我们还需要页面上暂时使用
        // 之前的数据，以避免页面整体被刷新。
        return currentData === undefined ? lastQueryData : currentData
    })()


    function mergeQueryData() {
        /// load-more data  { <queryName> : [ { key, data} ]} 
        return mapObj(queryData, (base, queryName) => {
            const keyList = moreDataKeys[queryName]

            if (keyList && keyList.length > 0) {
                return keyList.reduce((acc, key) => {
                    const [data] = getData(key)
                    if (data === undefined) {
                        return acc
                    }
                    return mergeData(acc, data[queryName])
                }, base)
            }

            return base
        })
    }
    const allQueryData = mergeQueryData()


    const reload = async (partialVariables = {}) => {
        // pre processed value

        let currentPVs = currentPartialVariables || {}
        function merge() {
            if (Object.keys(partialVariables).length == 0) {
                return currentPVs
            }
            return Object.keys(partialVariables).reduce((acc, key) => {
                if (currentPVs[key]) {
                    // merge the two
                    const merged = {
                        ...currentPVs[key],
                        ...partialVariables[key]
                    }
                    return {
                        ...acc,
                        [key]: merged
                    }

                } else {
                    return {
                        ...acc,
                        [key]: partialVariables[key]
                    }
                }

            }, {})
        }

        const pv = merge();

        // 如果 partialVariables 是空的，那么就表示 query 的 first 都要设成现有的 size
        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/519


        function setPageSize(rawPv) {

            // 重新用回了分页，就不需要这个了。
            // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/431

            return rawPv;
            // const queriedData = allQueryData || {}
            // return Object.keys(queriedData).reduce((acc, current) => {
            //     if(queriedData[current] && queriedData[current].edges) {
            //         let fetchedSize = queriedData[current].edges.length
    
            //         const v = {
            //             ...(rawPv[current] || {}),
            //             first: fetchedSize
            //         };
    
            //         return {
            //             ...acc,
            //             [current]: v
            //         }
            //     } else {
            //         return acc
            //     }
    
            // }, rawPv);
        }

        const { key, loader } = buildDataLoader(
            Object.keys(partialVariables).length === 0 ? setPageSize(pv) : pv
        )

        /// 需要 await 吗？
        await forceLoad({ [key]: loader })

        /// TODO 这里有可能出现 set unmount component state 的错误 

        ReactDOM.unstable_batchedUpdates(() => {
            /// 有 race condition 吗？
            setLastQueryData(queryData)
            setCurrentPartialVariables(pv)


            setMoreDataKeys(prev => {
                // // 每次重新加载，都清掉原来的 more data
                // if(Object.keys(partialVariables).length === 0) {
                //     // 整体刷新数据
                //     return {}
                // }
                return Object.keys(pv).reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: []
                    }
                }, prev);
            })
        })

    }




    const hasMore = mapObj(allQueryData || {}, data => {
        return data && data.pageInfo && data.pageInfo.hasNextPage
    })

    function getExtraState() {
        return {
            loadingMore: {}, /// 这只是默认值。真正的值在 extraState 里
            ...extraState,
            hasMore,
        }
    }


    const loadMore = async (queryName, pageSize) => {
        if (!hasMore[queryName]) {
            console.log("No more data to load for", queryName)
            return
        }
        if (getExtraState().loadingMore && getExtraState().loadingMore[queryName]) {
            console.log("is currently loading more")
            return
        }

        const existingData = allQueryData[queryName]

        /// 如果有 hasMore ，那么不可能 preProcess 返回 null
        const { queries, targetNode } = preProcess()
        if (queries === undefined) {
            /// do nothing
            return
        }

        /// only one query
        const { edges } = existingData
        /// const lastCursor
        const lastCursor = edges[edges.length - 1].cursor

        const query = queries[queryName]
        const newQueries = {
            [queryName]: {
                ...query,
                pagination: {
                    ...query.pagination,
                    ...(pageSize !== undefined ? { pageSize } : {}),
                    __after__: lastCursor
                }
            }
        }

        /// update state to indicate the loading more;
        function updateLoadingMore(value) {
            setExtraState(prev => {
                const { loadingMore } = prev
                return {
                    ...prev,
                    loadingMore: {
                        ...loadingMore,
                        [queryName]: value
                    }
                }
            })
        }
        updateLoadingMore(true)

        const queriesToLoad = filterObj(newQueries, (_, n) => n === queryName)

        const pv = currentPartialVariables || {}
        const key = buildQueryKey(targetNode, queriesToLoad, pv)
        const loader = loadQueryData(queriesToLoad, targetNode, pv, {
            allDataTypes, allEntities, queryTarget
        })


        await forceLoad({ [key]: loader })

        /// update state:
        ReactDOM.unstable_batchedUpdates(() => {
            updateLoadingMore(false)
            setMoreDataKeys(prev => {
                const theKeyList = prev[queryName] || []
                const newList = [
                    ...theKeyList,
                    key,
                ]
                return {
                    ...prev,
                    [queryName]: newList
                }
            })

        })
    }

    function evaluate() {

        // let t0 = performance.now();
        const { localData, targetNode, queries } = preProcess()

        if (queryData === undefined && queries !== undefined) {
            /// need query data, do evaluate for now:
            return undefined
        }

        const allQueryData = mergeQueryData()

        const ret = lookupValue(targetNode, {
            ...baseData,
            ...localData,
            ...allQueryData,
            ...getExtraState(),
            input: inputState,
        }, PRESERVED)


        // let t1 = performance.now();
        // console.log("Evaluate used", t1 - t0);
        // console.log(JSON.stringify(def, 0, 2))

        return ret
    }


    /// TODO improve the performance please:
    // const t0 = parseInt(Date.now())
    const value = evaluate()
    // const t1 = parseInt(Date.now())
    // console.log("Elapsed:", t1 - t0, 
    //     value && value.view && value.view.params && value.view.params.dataEntityName)
    // console.log(value)


    return {
        value,
        queryData,

        reload,
        loadMore,

        extraState: getExtraState(),
        updateExtraState: values => {
            setExtraState(prev => ({
                ...prev,
                ...values
            }))
        },
        setInputState,
    }
}

function mergeData(existing, incoming) {

    /// merge the connection
    const mergedData = {
        count: incoming.count,
        pageInfo: {  /// 不考虑中间有空隙的情况:
            startCursor: existing.pageInfo.startCursor,
            endCursor: incoming.pageInfo.endCursor,
            hasPreviousPage: existing.pageInfo.hasPreviousPage,
            hasNextPage: incoming.pageInfo.hasNextPage,
        },
        edges: [
            ...existing.edges,
            ...incoming.edges
        ]
    }

    return mergedData

}




//// helper funcitons
function determineQueriesToLoad(allQueries, partialVariables) {
    const specifiedNames = Object.keys(partialVariables)
    const queryNames = specifiedNames.length > 0 ? specifiedNames : Object.keys(allQueries)
    const queriesToLoad = filterObj(allQueries, (_, n) => queryNames.indexOf(n) !== -1)
    return queriesToLoad
}