// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function then_(p, f) {
  return p.then(Curry.__1(f));
}

function resolve(v) {
  return Promise.resolve(v);
}

exports.then_ = then_;
exports.resolve = resolve;
/* No side effect */
