import React from 'react'

export default function Mention(props) {
    
    const {
        entityKey,
        contentState,
        children
    } = props

    const { mention } = contentState.getEntity(entityKey).getData()
    // console.log("mention >>> ", mention)

    return (
        <a>
            {children}
        </a>
    )
}
