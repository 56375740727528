
import React, { useState, useEffect, useRef } from 'react'

import { useDrop } from 'react-dnd';

import { MdChevronRight, MdExpandMore, MdOutlineCropSquare } from 'react-icons/md';

import { distanceToArea, isPointInsideArea } from './utils/distance';

import './OutlineView.less';

import { mapListOrNestedList } from './listUtils';

const PAD = 16;

export default function OutlineView({ model, selected, onSelect, operatingState, setOperatingState, blockTypes, dropBlock }) {

    const blocks = Object.values(model.contentDict);
    const rootBlock = blocks.find(block => {
        return blocks.every(b => !b.hasChild(block.id))
    });

    const modelRef = useRef();
    modelRef.current = model;

    const operatingStateRef = useRef();
    operatingStateRef.current = operatingState;

    const ref = useRef();

    const blockNodesRef = useRef({});
    const slotNodesRef = useRef({});

    const placeholderRef = useRef({});

    function loadNodes() {
        if(!ref.current) {
            return;
        }
        const blockNodeList = Array.from(ref.current.querySelectorAll(".summary-wrapper"));

        let slotNodes = {};
        const blockNodes = blockNodeList.reduce((acc, node) => {
            const id = node.getAttribute("data-block-id");
            if (id) {
                const slotList = Array.from(ref.current.querySelectorAll(`[data-slot-for="${id}"]`));
                if(slotList && slotList.length > 0) {
                    slotNodes[id] = slotList;
                } 
                return { ...acc, [id]: node }
            } else {
                return acc
            }
        }, {});
        blockNodesRef.current = blockNodes;

        slotNodesRef.current = slotNodes;

        const placeholderNode = Array.from(ref.current.querySelectorAll(".summary-wrapper.placeholder"))[0];
        placeholderRef.current = placeholderNode;

    }


    useEffect(() => {
        setTimeout(() => {
            loadNodes()
        }, 16);
    }, [model]);

    function updateDropTarget(dropTarget) {
        const operatingState = operatingStateRef.current;
        const existingTarget = operatingState.dropTarget;
        if (existingTarget === dropTarget) {
            return
        } else if (existingTarget && dropTarget
            && existingTarget.parent === dropTarget.parent && existingTarget.index === dropTarget.index
            && existingTarget.slotIndex === dropTarget.slotIndex
        ) {
            return
        }
        setOperatingState(prev => ({ ...prev, dropTarget }))
    }


    useEffect(() => {
        loadNodes();
    }, [operatingState.dropTarget])

    const [{ canDrop, isDragOver }, drop] = useDrop(() => ({
        accept: [
            // "row", "column", "page", "text"
            ...(blockTypes.map(b => b.name))
        ],
        hover(item, monitor) {

            // console.log(">>> hover", item);
            resolveDropTarget(
                item,
                monitor,
                operatingStateRef.current.dropTarget,
                updateDropTarget,
                modelRef.current.contentDict,
                blockNodesRef.current,
                slotNodesRef.current,
                placeholderRef.current
            );
        },

        collect(monitor, props) {
            return {
                canDrop: monitor.canDrop(),
                isDragOver: monitor.isOver()
            }
        },
        drop(item) {
            const { dropTarget } = operatingStateRef.current;
            dropBlock(item, dropTarget);
            updateDropTarget(null)
        },
    }));

    useEffect(() => {
        if (!isDragOver) {
            updateDropTarget(null)
        }
        loadNodes();

    }, [isDragOver])



    if (!rootBlock) {
        return null
    }

    function convertModelDictToTreeData(rootID, dict) {
        const { childIds, id, blockType } = dict[rootID];
        return {
            id,
            title: blockType.label,
            children: mapListOrNestedList(childIds, cid => convertModelDictToTreeData(cid, dict))
        }
    }

    const treeData = convertModelDictToTreeData(rootBlock.id, model.contentDict);

    return (
        <div className="lc-outline-view" ref={drop(ref)}>
            <TreeNode node={treeData} depth={0} onHover={hovered => {
                setOperatingState(prev => ({ ...prev, hovered }));
            }}
                onSelect={onSelect} selected={selected}
                operatingState={operatingState}
                blockDict={model.contentDict}
            />
        </div>
    )
}


function TreeNodePlaceholder({ blockType, hasChild = false, depth, id }) {
    const title = blockType.label;
    const renderOpIcon = () => {
        if (hasChild) {
            return <MdChevronRight />
        } else {
            return <MdOutlineCropSquare />
        }
    }
    return (
        <div className={"tree-node"}>
            <div className={"summary-wrapper placeholder"} data-depth={depth}>
                <div className={"summary"} style={{
                    paddingLeft: PAD * depth,
                }}>
                    <div className="op-icon-box">
                        {renderOpIcon()}
                    </div>
                    <div className="title">{title}
                        {id ? <span style={{ fontSize: 10, opacity: 0.6, marginLeft: 12 }}>#{id}</span> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

function renderPlacholder(beingDragged, depth, blockDict) {
    if (!beingDragged) {
        return null;
    }
    const { blockType, id } = beingDragged;

    const hasChild = id && blockDict[id] && blockDict[id].childIds.length > 0;

    return <TreeNodePlaceholder blockType={blockType} depth={depth} key={"placeholder"} hasChild={hasChild} id={id} />
}


function SlotNode({ parentId, slotIndex, slotChildren, depth, onHover, onSelect, selected, operatingState, blockDict }) {

    const [collapsed, setCollapsed] = useState(false);

    const children = slotChildren;

    const renderOpIcon = () => {
        if (children && children.length !== 0) {
            return collapsed ? <MdChevronRight /> : <MdExpandMore />
        } else {
            return <MdOutlineCropSquare />
        }
    }

    const isDropTarget = operatingState.dropTarget &&
        operatingState.dropTarget.parent === parentId &&
        operatingState.dropTarget.slotIndex === slotIndex;

    function renderChildren() {
        return (
            <>
                {
                    isDropTarget && operatingState.dropTarget.index === 0 ?
                        renderPlacholder(operatingState.beingDragged, depth + 1, blockDict) : null
                }
                {
                    slotChildren.map((n, index) => {
                        const c = (
                            <TreeNode node={n} key={n.id} depth={depth + 1}
                                onHover={onHover} onSelect={onSelect} selected={selected}
                                operatingState={operatingState}
                                blockDict={blockDict}
                            />
                        )
                        if (isDropTarget) {
                            const dropIndex = operatingState.dropTarget.index;
                            if (dropIndex == index + 1) {
                                return (
                                    <React.Fragment key={index}>
                                        {c}
                                        {renderPlacholder(operatingState.beingDragged, depth + 1, blockDict)}
                                    </React.Fragment>
                                )
                            }
                        }
                        return c
                    })
                }
            </>
        )
    }

    return (
        <div className={"tree-node"}>
            <div data-slot-for={parentId} className={"slot-summary-wrapper summary-wrapper"}>
                <div className={"summary"} style={{
                    paddingLeft: PAD * depth,
                }}>
                    <div className="op-icon-box" onClick={_ => {
                        if (children && children.length > 0) {
                            setCollapsed(prev => !prev);
                        }
                    }}>
                        {renderOpIcon()}
                    </div>
                    <div className="title">{slotIndex}</div>
                </div>
            </div>
            <div className="child-list" style={{
                display: collapsed ? "none" : "block"
            }}>
                {renderChildren()}
            </div>
        </div>
    )
}

function TreeNode({ node, depth, onHover, onSelect, selected, operatingState, blockDict }) {
    const { id, title, children } = node;

    const [collapsed, setCollapsed] = useState(false);

    const isActive = id == selected && !operatingState.dropTarget;
    const isDropTarget = operatingState.dropTarget && operatingState.dropTarget.parent == id;
    const isBeingDragged = operatingState.beingDragged && operatingState.beingDragged.id == id;

    const actuallyCollapsed = collapsed || isBeingDragged;

    const renderOpIcon = () => {
        if (children && children.length !== 0) {
            return actuallyCollapsed ? <MdChevronRight /> : <MdExpandMore />
        } else {
            return <MdOutlineCropSquare />
        }
    }

    const isMultipleSlots = Array.isArray(children[0]);

    function renderChildren() {
        if (isMultipleSlots) {
            return children.map((slotChildren, slotIndex) => {
                return (
                    <SlotNode key={slotIndex} {...{
                        parentId: id, slotIndex, slotChildren, depth: depth + 1, onHover, onSelect, selected, operatingState,
                        blockDict
                    }} />
                )
            })
        } else {
            return (
                <>
                    {
                        isDropTarget && operatingState.dropTarget.index === 0 ?
                            renderPlacholder(operatingState.beingDragged, depth + 1, blockDict) : null
                    }
                    {
                        children.map((n, index) => {
                            const c = (
                                <TreeNode node={n} key={n.id} depth={depth + 1}
                                    onHover={onHover} onSelect={onSelect} selected={selected}
                                    operatingState={operatingState}
                                    blockDict={blockDict}
                                />
                            )
                            if (isDropTarget) {
                                const dropIndex = operatingState.dropTarget.index;
                                if (dropIndex == index + 1) {
                                    return (
                                        <React.Fragment key={index}>
                                            {c}
                                            {renderPlacholder(operatingState.beingDragged, depth + 1, blockDict)}
                                        </React.Fragment>
                                    )
                                }
                            }
                            return c
                        })
                    }
                </>
            )
        }
    }

    return (
        <div className={"tree-node"}>
            <div data-block-id={id} className={"summary-wrapper" +
                (isActive ? " active" : "") +
                (isDropTarget ? " drop-target" : "") +
                (isBeingDragged ? " being-dragged" : "")
            } onMouseEnter={_ => {
                // enter
                onHover(id)

            }} onMouseLeave={_ => {
                // leave
                onHover(null)

            }} onClick={_ => {
                if (id == selected) {
                    onSelect(null)
                } else {
                    onSelect(id)
                }
            }}>
                <div className={"summary"} style={{
                    paddingLeft: PAD * depth,
                }}>
                    <div className="op-icon-box" onClick={_ => {
                        if (children && children.length > 0) {
                            setCollapsed(prev => !prev);
                        }
                    }}>
                        {renderOpIcon()}
                    </div>
                    <div className="title">{title}<span style={{ fontSize: 10, opacity: 0.6, marginLeft: 12 }}>#{id}</span></div>
                </div>
            </div>
            <div className="child-list" style={{
                display: actuallyCollapsed ? "none" : "block"
            }}>
                {renderChildren()}
            </div>
        </div>
    )

}



// ===

//  resolve drop target
function resolveDropTarget(
    item, monitor, currentDropTarget, updateDropTarget, contentDict,
    blockNodes, slotNodes, placeholderNode
) {

    const pointerOffset = monitor.getClientOffset();
    if (pointerOffset) {

        // 用鼠标的位置为中点，画一个 8px 的方形；

        // a.
        // 1. 找到鼠标所在的 block
        // 2. 如果它在偏上方，那么它跟这个 block 同级，作为上一个 sibling 
        // 3. 如果它在偏下方，
        // 3.1  如果它是 container，则作为它的第一个 child 
        // 3.2  如果它不是 container，则跟他同级作为下一个 sibling

        // b. 如果鼠标所在 block 是 placeholder
        // 
        let dropTarget = null; // { nearestId, isNearestBefore, parent, slotIndex, index }

        const containingBlock = Object.entries(blockNodes).find(([id, n]) => {
            const area = n.getBoundingClientRect();
            return isPointInsideArea(pointerOffset, area);
        });

        const containingSlot = Object.entries(slotNodes).flatMap(([id, slots]) => {
            return slots.map((slotNode, slotIndex) => [id, slotIndex, slotNode])
        }).find(([_id, _slotIndex, n]) => {
            const area = n.getBoundingClientRect();
            return isPointInsideArea(pointerOffset, area);
        })

        if (containingBlock) {
            // 
            const [blockId, n] = containingBlock;
            // 鼠标是不是在上方
            const area = n.getBoundingClientRect()
            const isUpper = pointerOffset.y <= (area.top + area.bottom) / 2.0;

            if (isUpper) {
                const parentBlock = Object.values(contentDict).find(b => b.hasChild(blockId));
                if (parentBlock) {
                    const { index, slotIndex } = parentBlock.indexOfChild(blockId);
                    dropTarget = {
                        nearestId: blockId,
                        isNearestBefore: false,
                        parent: parentBlock.id,
                        index,
                        slotIndex
                    }
                }

            } else {

                const { blockType, childIds, } = contentDict[blockId];
                const { isContainer } = blockType;
                if (isContainer && blockId !== item.id) { // 是 container 而且不是自己
                    if (Array.isArray(childIds[0])) {
                        dropTarget = {
                            nearestId: childIds[0][0],
                            isNearestBefore: false,
                            parent: blockId,
                            slotIndex: 0,
                            index: 0
                        }
                    } else {
                        dropTarget = {
                            nearestId: childIds[0],
                            isNearestBefore: false,
                            parent: blockId,
                            index: 0
                        }
                    }
                } else {
                    // 如果它不是 container，则跟它同级作为下一个 sibling

                    const parentBlock = Object.values(contentDict).find(b => b.hasChild(blockId));
                    if (parentBlock) {
                        const { index, slotIndex } = parentBlock.indexOfChild(blockId);
                        dropTarget = {
                            nearestId: blockId,
                            isNearestBefore: true,
                            parent: parentBlock.id,
                            index: index + 1,
                            slotIndex
                        }
                    }
                }
            }

            updateDropTarget(dropTarget)

        } else if(containingSlot) {
            // 作为这个 slot 的第一个 child
            const [ parentId, slotIndex, _ ] = containingSlot;

            const parentBlock = contentDict[parentId];
            const nearestId = parentBlock.getChildId({ slotIndex, index: 0 });

            updateDropTarget({
                nearestId,
                isNearestBefore: false,
                parent: parentId,
                index: 0,
                slotIndex: slotIndex
            }) 


        } else {
            // 没有 containing block，通常意味着此时跟 placeholder 或者 slot 重合
            if (placeholderNode && currentDropTarget) {
                const area = placeholderNode.getBoundingClientRect();
                if (isPointInsideArea(pointerOffset, area)) {
                    // 如果跟 placeholder 重合
                    const depth = parseFloat(placeholderNode.getAttribute("data-depth"));
                    // 
                    if (pointerOffset.x - area.left < depth * PAD) {
                        // 如果是当前的最后一个 index, 则可以往上升级：
                        const targetParentBlock = contentDict[currentDropTarget.parent];

                        const { slotIndex, index } = targetParentBlock.lastIndex();
                        if (currentDropTarget.slotIndex === slotIndex && currentDropTarget.index > index) {

                            // raise it
                            const parentOfParent = Object.values(contentDict).find(block => {
                                return block.hasChild(targetParentBlock.id);
                            })
                            if(parentOfParent) {
                                const parentIndex = parentOfParent.indexOfChild(targetParentBlock.id);
                                updateDropTarget({
                                    nearestId: targetParentBlock.id,
                                    isNearestBefore: true,
                                    parent: parentOfParent.id,
                                    index: parentIndex.index + 1,
                                    slotIndex: parentIndex.slotIndex
                                }) 
                            }
       
                        }
                    } else if(pointerOffset.x - area.left > (depth + 1) * PAD) {

                        // 如果上一个 sibiling 是 container，则缩进成为它的 last child
                        const targetParentBlock = contentDict[currentDropTarget.parent];
                        
                        if(currentDropTarget.index > 0) {
                            const prevSiblingId = targetParentBlock.getChildId({ 
                                slotIndex: currentDropTarget.slotIndex,
                                index: currentDropTarget.index - 1
                            });

                            if(prevSiblingId && contentDict[prevSiblingId] && contentDict[prevSiblingId].blockType.isContainer) {

                                const newParentBlock = contentDict[prevSiblingId];

                                const lastIndex = newParentBlock.lastIndex();
                                const lastChildId = newParentBlock.getChildId(lastIndex);

                                updateDropTarget({
                                    nearestId: lastChildId,
                                    isNearestBefore: true,
                                    parent: newParentBlock.id,
                                    index: lastIndex.index + 1,
                                    slotIndex: lastIndex.slotIndex
                                })  

                            }
                        
                        }
                    }
                }

            }


        }

    }

}
