import React, { useState } from 'react'
import { Input } from 'antd'

import "./RolePermissionDisplay.less"

export default function RolePermissionDisplay(props) {

    const { params } = props

    const { value } = params

    const [searchValue, setSearchValue] = useState("")

    const filterValue = Object.keys(value).filter(entity => entity.includes(searchValue) || (value[entity].ops || []).toString().includes(searchValue))

    return (
        <div className="role-permission-display-container">
            <Input
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            {
                filterValue.map(v => {
                    return (
                        <div key={v} className="role-permission-item">
                            <span>{v}</span>
                            <span>{` :: ${(value[v].ops || []).join(", ")}\n`}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}
