
import React from 'react';

import CommonPopup from '../CommonPopup';

import './Popover.less'

import PopoverContext from './PopoverContext'

export default function Popover (props) {

    const { className, arrowSize = 4, arrowColor = "white", ...remaining } = props;

    return (
        <PopoverContext.Provider value={{}}>
            <CommonPopup 
                className={"bw-popover" + (className ? " " + className : "")} 
                { ...{
                    arrowColor, arrowSize,
                    ...remaining
                }
                }
            />
        </PopoverContext.Provider>
    )
}

export function create (props) {
    return <Popover {...props} />
}