import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import "./DesignSideBar.less"

//components
import { Menu } from 'antd'

import { Icon } from '@ant-design/compatible';
import * as MDIcons from 'react-icons/md';

const SubMenu = Menu.SubMenu

import { markNav } from 'Client/js/breadcrumb/breadcrumbHelper'
import { useEffect } from 'react'

export default function SideBar(props) {

    const { history, currentApplication } = props
    const { pathname } = history.location

    const menus = buildMenus();

    const applicationCode = currentApplication ? currentApplication.code.toLowerCase() : "default";

    const [ openKeys, setOpenKeys ] = useState([]);
    const [ selectedKeys, setSelectedKeys ] = useState([]);

    function getIconElement(iconName) {
        const MDIcon = MDIcons["Md" + iconName];
        if(MDIcon) {
            return <span style={{
                display: "flex",

                fontSize: "14px",
                marginRight: "10px",
                opacity: 0.8,
                alignItems: "center",
                paddingBottom: "1px",
            }}><MDIcon /></span>
        } else {
            return <Icon type={iconName} />
        }

    }

    function wrapPath(item) {
        const { items, path } = item; 
        if(items) {
            return {
                ...item,
                items: items.map(wrapPath)
            }
        } else if (path) {
            return {
                ...item,
                path: `/design/${applicationCode}/${path}`
            }
        } else {
            return item
        }
    }

    const menuGroups = Object.keys(menus).map(name => {

        const groups = menus[name]
        const { items, to, icon } = groups

        if (items) {
            return {
                name,
                icon,
                items: items.map(({ name, to }) => ({ name, path: to }))
            }
        } else {
            return {
                name,
                icon,
                path: to
            }
        }
    }).map(wrapPath);

    function initializeMenuStatus() {
        if (!menuGroups) return {}

        const activeGroup = menuGroups.find(
            (group) => {
                const { path, items } = group;
                if (path) {
                    return pathname.startsWith(path)
                } else {
                    return items.some(item => pathname.startsWith(item.path))
                }

            }
        )
        // return {
        //     defaultOpenKeys: activeGroup ? [activeGroup.name] : [],
        //     defaultSelectedKeys: [pathname]
        // }
        setOpenKeys(activeGroup ? [activeGroup.name] : []);
        setSelectedKeys([pathname]);
    }

    useEffect(() => {
        initializeMenuStatus();
    }, []);

    useEffect(() => {
        setSelectedKeys([pathname]);
    }, [ pathname ])


    const theme = "dark";
    // const theme = "light";

    //
    return (
        <Menu theme={theme} className="app-menu" mode="inline" {...{ openKeys, selectedKeys }} 
            onOpenChange={openKeys => setOpenKeys(openKeys)}
            // onSelect={({key}) => { setSelectedKeys([key])}}
        >
            {
                menuGroups.map((group, index) => {
                    const { name, icon = 'folder', items, path } = group;

                    const iconElement = getIconElement(icon);

                    if (path) {
                        return (
                            <Menu.Item key={path} 
                                onClick={() => {
                                    markNav([group])
                                }}
                            >
                                <Link to={path} style={{display: "flex", alignItems: "center"}}>
                                    { iconElement }
                                    <span>{name}</span>
                                </Link>
                            </Menu.Item>
                        )
                    } else {
                        return (
                            <SubMenu key={name} title={
                                <span style={{display: "flex", alignItems: "center"}}>
                                    { iconElement }
                                    <span>{name}</span>
                                </span>
                            }>
                                {
                                    items.map(item => {
                                        const { name, path } = item;
                                        return (
                                            <Menu.Item key={path} onClick={() => {
                                                markNav([group, item])
                                            }}>
                                                <Link to={path}>{name}</Link>
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </SubMenu>
                        )
                    }
                })
            }
        </Menu>
    )
}

const buildMenus = () => {
    const menus = {
        // '模型设计': {
        //     icon: "TableView",
        //     to: "model",
        // }, 
        // '数据实体': {
        //     icon: 'database',
        //     to: `entities`
        // },
        // '事件处理': {
        //     icon: 'reconciliation',
        //     items: [{
        //         name: "数据事件",
        //         to: `events`
        //     }, {
        //         name: "定时事件",
        //         to: `scheduled-events`
        //     }]
        // },
        // "自定义H5": {
        //     icon: 'mobile',
        //     items: [{
        //         name: "页面管理",
        //         to: `pages`
        //     }, {
        //         name: "页面片段",
        //         to: `page-fragments`
        //     }, {
        //         name: "页面组件",
        //         to: `page-components`
        //     }]
        // },
        // "后台页面": {
        //     icon: "layout",
        //     to: `admin-pages`
        // },
        '选项设定': {
            icon: 'unordered-list',
            to: `lookups`
        },
        '兼容检查': {
            icon: "alert",
            to: `incompatible`
        },
        // '通用设定': {
        //     icon: 'setting',
        //     to: `general-settings`
        // },

        '颜色系统': {
            icon: 'bg-colors',
            to: `color`
        },
        '代码搜索': {
            icon: "search",
            to: `code-search`
        },
        // '代码测试': {
        //     icon: "codepen",
        //     to: `code-playground`
        // },
        '内置函数': {
            icon: "snippets",
            to: `code-presets`
        }
    }
    return menus
}