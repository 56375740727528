
import React, { useEffect } from 'react'

import { Icon } from '@ant-design/compatible';

import './YqylPromotion.less'

import RecordSelect from 'Client/js/ui/widgets/input/RecordSelect'

export default function YqylPromotion (props){

    const { replies, updateReplies, facade, publishFault } = props
    // useEffect(() => {
    //     facade.prepare(["邀请有礼-活动"]);
    // });

    const entity = facade.getEntity("邀请有礼-活动");
    if(!entity) {
        return null // loading
    }

    const allDataTypes = facade.dataTypes;
    const allEntities = facade.entities;


    function deleteReplies (idx) {
        replies.splice(idx, 1)
        updateReplies(replies)
    }

    function yqylPromotionInput (value, idx) {
        replies.splice(idx, 1, {
            ...replies[idx],
            type: 'yqylPromotion',
            yqylPromotion: value ? {
                id: value.id
            } : null
        })
        updateReplies(replies)
    }

    return (
        <div className="wxmp-yqylPromotion-container">
        {
            replies.map((reply, idx) => {
                const hasFault = publishFault && reply.type === 'yqylPromotion' && !reply['yqylPromotion'] ? true : false
                return reply.type === 'yqylPromotion' ? (
                    <div key={idx} style={hasFault ? {border: '1px solid #f00'} : null}>
                        <div className="yqyl-promotion">
                            <label>邀请有礼活动</label>
                            <RecordSelect
                                value={reply.yqylPromotion}
                                onChange={value => yqylPromotionInput(value, idx)}
                                allDataTypes={allDataTypes}
                                allEntities={allEntities}
                                params={{
                                    entityName: '邀请有礼-活动',
                                    pageSize: 6,
                                    fieldsToSearch: ['名称'],
                                    itemInList: item => {
                                        return replies.some(r => r.type === 'yqylPromotion' && r['yqylPromotion'] && r['yqylPromotion'].id === item.id)
                                    }
                                }}
                            />
                            <div className="delete" onClick={() => deleteReplies(idx)}>
                                <Icon type="close" />
                            </div>
                        </div>
                        {
                            hasFault ? (
                                <p style={{ color: "#f00" }}>请设置此回复内容</p>
                            ) : null
                        }
                    </div>
                ) : null
            })
        }
        {
            publishFault && replies.length === 0 ? <p style={{ color: "#f00", paddingTop: 10 }}>请设置回复内容</p> : null
        }
        </div>
    )
}