
import React, { useEffect, useContext, useState } from "react";

import loadSandboxDefinitions from 'bwax-ui/legacy/store/loaders/loadSandboxDefinitions'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import ApplicationContext from 'bwax/util/ApplicationContext';

import InputSelect from 'Client/js/ui/components/InputSelect';

export default function EntityNameInput (props) {

    const { entityFilter, required, value, onChange } = props;

    const [ entities, setEntities ] = useState([]);

    const dlc = useContext(DataLoaderContext);
    const env = { sessionToken: dlc.sessionToken, sandbox: true };

    const { currentApplication } = useContext(ApplicationContext);

    useEffect(() => {
        (async () => {
            const { allEntities } = await loadSandboxDefinitions(currentApplication.code)(env);
            const entities = entityFilter ? allEntities.filter(entityFilter) : allEntities;
            setEntities(entities.reverse());

        })()
    }, [ currentApplication.code ]);

    return (
        <InputSelect {...{
            value, onChange,
            style: { width: "100%" },
            isSearchable: true,
            isClearable: !required,
            options: entities.map(e => e.name)

        }} />
    )


}