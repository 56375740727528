// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var JsonUtils$BwaxAdmin = require("../../re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var mutRequestAuthSession = "\n    mutation RequestAuthSession($input: RequestAuthSessionInput!) {\n      requestAuthSession(input: $input) {\n        authSession {\n          id\n          token\n        }\n      }\n    }\n  ";

function requestSessionToken(queryRunner, code) {
  return Curry._3(queryRunner, mutRequestAuthSession, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "input",
                        {
                          clientMutationId: new Date(),
                          code: code
                        }
                      ],
                      /* [] */0
                    ])), undefined).then((function (text) {
                var result = JsonUtils$BwaxAdmin.parse(text);
                var sessionToken = JsonUtils$BwaxAdmin.valueOfPath(result, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "data",
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "requestAuthSession",
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                "authSession",
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    "token",
                                    /* [] */0
                                  ])
                              ])
                          ])
                      ]), Js_json.decodeString);
                if (sessionToken !== undefined) {
                  return Promise.resolve(sessionToken);
                } else {
                  return Promise.reject(Js_exn.raiseError("Cannot request token"));
                }
              }));
}

exports.mutRequestAuthSession = mutRequestAuthSession;
exports.requestSessionToken = requestSessionToken;
/* JsonUtils-BwaxAdmin Not a pure module */
