

import React from 'react'

import { Input } from 'antd';

export default function PasswordInput({ value, onChange, disabled }) {
    return (
        <Input.Password disabled={disabled} value={value} onChange={e => {
            onChange(e.target.value);
        }} />
    )
}
