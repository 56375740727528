
import React, { useEffect, useState } from 'react'

import { Input, Select, Slider, Popover as ArcoPopover } from '@arco-design/web-react';
const Option = Select.Option;

import { SketchPicker } from 'react-color';

import isEqual from 'lodash/isEqual'

import AngleEdit from '../components/AngleEdit'
import { transparentImgUrl, colorRgbRegx, colorHexRegx } from '../StyleForm';
import './ShadowEdit.less'

export default function ShadowEdit({ isBoxShadow, shadow, onChange }) {

    const { angle } = shadow
    const [editShadow, setEditShadow] = useState({ ...shadow })

    useEffect(() => {
        if(!isEqual(shadow, editShadow)) {
            setEditShadow({ ...shadow })
        }
    }, [shadow])

    function renderAttrWithSlider (label, styleKey) {
        const { value, unit } = editShadow[styleKey]
        const unitsArr = ['px', 'em', 'rem', 'vh', 'vw']

        function renderColumnAttrUnitSelect() {

            function updateUnit (unit) {
                onChange({
                    ...editShadow,
                    [styleKey]: {
                        ...editShadow[styleKey],
                        unit
                    }
                })
            }
    

            return (
                <Select value={unit} size={'mini'} className="unit-select" arrowIcon={null} getPopupContainer={node => node}
                    triggerProps={{ autoAlignPopupWidth: false }} onChange={unit => updateUnit(unit)}
                >
                    {(styleKey === "size" ? [...unitsArr, 'ch'] : unitsArr).map(option => (
                        <Option key={option} value={option} className='unit-option'>
                            {option.toUpperCase()}
                        </Option>
                    ))}
                </Select>
            )
        }

        function updateValue (value) {
            onChange({
                ...editShadow,
                [styleKey]: {
                    ...editShadow[styleKey],
                    value
                }
            })
        }

        function inputValue (value) {
            setEditShadow({
                ...editShadow,
                [styleKey]: {
                    ...editShadow[styleKey],
                    value
                }
            })
        }


        function inputValueConfirm () {
            const { value, unit } = editShadow[styleKey]
            const lengthRegx = /^(\d+(?:\.\d+)?)(px|em|rem|vw|vh)?$/
            const matchedResult = value ? lengthRegx.exec(value) : null

            if(matchedResult) {
                const matchedValue = matchedResult[1]
                const matchedUnit = matchedResult[2] ? matchedResult[2] : unit
                const newShadow = {
                    ...editShadow,
                    [styleKey]: {
                        ...editShadow[styleKey],
                        value: matchedValue,
                        unit: matchedUnit
                    }
                }
                setEditShadow(newShadow)
                onChange(newShadow)
            } else {
                setEditShadow({
                    ...editShadow,
                    [styleKey]: {
                        ...editShadow[styleKey],
                        value, unit
                    }
                })
            }
        }

        return (
            <div className='attr-slider-container'>
                <div className='attr-slider-label'>{label}</div>
                <div className='attr-slider-input'>
                    <Slider value={value ? parseFloat(value) : 0} size="mini"
                        onChange={value => updateValue(value.toString())} style={{ margin: '0 6px' }}
                        max={styleKey === "size" ? 20 : 100} min={styleKey === 'size' ? -20 : 0}
                    />
                    <Input value={value} size="mini" suffix={renderColumnAttrUnitSelect()} style={{ width: 72, flexShrink: 0 }}
                        onChange={value => inputValue(value)} onBlur={() => inputValueConfirm()}
                        onPressEnter={() => inputValueConfirm()}
                    />
                </div>
            </div>
        )
    }
    
    function updateAngle (newAngle) {
        onChange({
            ...editShadow,
            angle: { ...newAngle }
        })
    }

    function renderColorPicker () {

        const { color } = editShadow

        function renderColorPicker () {
        
            return (
                <SketchPicker
                    color={color}
                    onChangeComplete={color => {
                        const { r, g, b, a } = color.rgb
                        const c = `rgba(${r},${g},${b},${a})`
                        onChange({ ...editShadow, color: c })
                    }} 
                />
            )
        }

        return (
            <ArcoPopover content={renderColorPicker()} position="bottom" trigger="click" getPopupContainer={node => node}
            >
                <div className='color-display' style={{ 
                    backgroundImage: [`linear-gradient(180deg, ${color} 0%, ${color} 100%)`, `url(${transparentImgUrl})`] 
                }}></div>
            </ArcoPopover>
        )
    }

    function inputColorConfirm () {
        const { color } = editShadow

        if(color.startsWith("rgb") && color.match(colorRgbRegx)) {
            const matchRgbResult = color.match(colorRgbRegx)
            const convertedColor = `rgba(${matchRgbResult[1]},${matchRgbResult[2]},${matchRgbResult[3]},${matchRgbResult[4] ? matchRgbResult[4] : 1})`
            onChange({ ...editShadow, color: convertedColor })
        } else if (color.startsWith('#') && color.match(colorHexRegx)) {
            onChange({ ...editShadow, color })
        } else {
            setEditShadow({ ...editShadow, color: shadow.color }) // 恢复初始的 color
        }
    }

    const shadowTypes = ['Outer', 'Inside']

    return (
        <div className='shadow-edit-container'>
            {
                isBoxShadow ? (
                    <div className='shadow-type-container'>
                        <div className='attr-slider-label'>Type</div>
                        <div className='options'>
                            {
                                shadowTypes.map(type => {
                                    const isActive = editShadow.inset ? type === "Inside" : type === "Outer"
                                    return (
                                        <div key={type} className={`option-box ${isActive ? 'active' : ''}`}
                                            onClick={() => onChange({ ...editShadow, inset: type === "Inside" ? true : false }) }
                                        >
                                            {type}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : null
            }
            <AngleEdit angle={angle} onChange={angle => updateAngle(angle)}/>
            {renderAttrWithSlider('Distance', 'distance')}
            {renderAttrWithSlider('Blur', 'blur')}
            {
                isBoxShadow ? (
                    renderAttrWithSlider('Size', 'size')
                ) : null
            }
            <div className='shadow-color-container'>
                <div className='attr-slider-label'>Color</div>
                <Input value={editShadow.color} size="mini" addBefore={renderColorPicker()} beforeStyle={{ padding: 0 }}
                    onChange={color => setEditShadow({ ...editShadow, color })} 
                    onBlur={() => inputColorConfirm()}
                    onPressEnter={() => inputColorConfirm()}
                />
            </div>
        </div>
    )
}
