// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Antd$BwaxAdmin = require("../binding/antd/Antd.bs.js");
var Modal = require("antd/lib/modal");
var JsonUtils$BwaxAdmin = require("../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryRunner$BwaxAdmin = require("../../../lib/bwax-js/re/binding/QueryRunner.bs.js");
var DataLoaderContext = require("bwax-ui/store/DataLoaderContext");

import './PosterPreviewBtn.less' 
;

var qPreview = "\n  query ($setting: PosterSettingInput!, $promotionId: YQYLEntityPromotionId!) {\n    yqylPosterPreview( setting: $setting, promotionId: $promotionId ) {\n      posterImage {\n        url\n      }\n    }\n  }\n";

function queryPreview(env, setting, promotionId) {
  return QueryRunner$BwaxAdmin.queryData(Caml_option.some(env), qPreview, Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "setting",
                            setting
                          ],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "promotionId",
                                promotionId
                              ],
                              /* [] */0
                            ])
                        ]))), undefined, /* () */0).then((function (text) {
                var url = Belt_Option.flatMap(JsonUtils$BwaxAdmin.parse(text), (function (j) {
                        return JsonUtils$BwaxAdmin.getByPath(j, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      "data",
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          "yqylPosterPreview",
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              "posterImage",
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  "url",
                                                  /* [] */0
                                                ])
                                            ])
                                        ])
                                    ]));
                      }));
                return Promise.resolve(Belt_Option.flatMap(url, Js_json.decodeString));
              }));
}

function PosterPreviewBtn(Props) {
  var setting = Props.setting;
  var id = Props.id;
  var dlc = React.useContext(DataLoaderContext.default);
  var env = QueryRunner$BwaxAdmin.toEnv(dlc);
  var processedSetting = Belt_Option.map(Belt_Option.flatMap(setting, (function (s) {
              var match = Caml_obj.caml_equal(s, null);
              if (match) {
                return ;
              } else {
                return Caml_option.some(s);
              }
            })), (function (s) {
          return {
                  backgroundImage: Belt_Option.map(Caml_option.nullable_to_opt(s.backgroundImage), (function (b) {
                          return {
                                  expireTime: b.expireTime,
                                  title: b.title,
                                  path: b.path,
                                  size: b.size,
                                  contentType: b.contentType
                                };
                        })),
                  qrCodeOptions: s.qrCodeOptions,
                  avatarOptions: s.avatarOptions,
                  nickNameOptions: s.nickNameOptions
                };
        }));
  var match = React.useState((function () {
          return false;
        }));
  var setPreviewVisible = match[1];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setPreviewImage = match$1[1];
  var previewImage = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setLoading = match$2[1];
  var onClick = function (param) {
    queryPreview(env, processedSetting, id).then((function (maybeUrl) {
            Curry._1(setPreviewImage, (function (param) {
                    return maybeUrl;
                  }));
            Curry._1(setPreviewVisible, (function (param) {
                    return true;
                  }));
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            return Promise.resolve(/* () */0);
          }));
    return Curry._1(setLoading, (function (param) {
                  return true;
                }));
  };
  var disabled = Belt_Option.isNone(processedSetting);
  return React.createElement("div", {
              className: "poster-preview-btn"
            }, React.createElement(Antd$BwaxAdmin.Button.make, {
                  disabled: disabled,
                  loading: match$2[0],
                  onClick: onClick,
                  children: "预览"
                }), React.createElement(Modal.default, {
                  visible: match[0],
                  className: "poster-preview-modal",
                  onCancel: (function (param) {
                      return Curry._1(setPreviewVisible, (function (param) {
                                    return false;
                                  }));
                    }),
                  onOk: (function (param) {
                      return /* () */0;
                    }),
                  children: React.createElement("div", {
                        className: "poster-preview-cot"
                      }, previewImage !== undefined ? React.createElement("div", {
                              className: "preview-bg"
                            }, React.createElement("img", {
                                  src: previewImage
                                })) : "Cannot load preview image"),
                  footer: null
                }));
}

var make = PosterPreviewBtn;

exports.qPreview = qPreview;
exports.queryPreview = queryPreview;
exports.make = make;
/*  Not a pure module */
