
import React, { useContext, useState } from 'react';

import { runDefinitionQuery } from 'bwax/query/runClientQuery'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import Search from 'Client/ml/ui/components/Search';
import Button from 'Client/ml/ui/components/Button';

import './CodeSearch.less';
import LocalPagedTable from '../newTable/LocalPagedTable';


const detailRoutes = {
    "数据实体_详情页设置": "/entity/:id/详情页设置",
    "数据实体_列表页设置": "/entity/:id/列表页设置",
    "虚拟字段_代码": "/virtual-fields/:id/代码编辑",
    "管理页面_代码": "/admin-page/:id/代码编辑",
    "数据接口_代码": "/data-interface/:id/代码编辑",
    "事件处理_代码": "/event/:id/代码编辑",
    "定时事件处理_代码": "/scheduled-event/:id/代码编辑",
    "通用设定_代码": "/general-setting/:id/代码编辑",
    "页面_代码": "/page/:id/代码编辑",
    "页面组件_代码": "/page-component/:id/代码编辑",
    "页面片段_代码": "/page-fragment/:id/代码编辑",
};

export default function CodeSearch(props) {

    const { currentApplication, routeTo } = props;

    const dlc = useContext(DataLoaderContext)

    const [keyword, setKeyword] = useState(undefined);

    const [data, setData] = useState([]);

    async function doSearch() {
        if (keyword) {
            (async () => {
                const resultText = await runDefinitionQuery(dlc)(QueryText)({ keyword });
                const result = JSON.parse(resultText);
                if (result.data && result.data.searchDefinitionCode) {

                    console.log(result.data.searchDefinitionCode);

                    setData(result.data.searchDefinitionCode);
                }

            })();
        }
    };

    const columns = [{
        title: "所在实体类型",
        width: 100,
        key: "entityName"
    }, {
        title: "所在记录名称",
        key: "recordName",
        width: 160
    }, {
        title: "代码字段名",
        key: "codeFieldName",
        width: 100
    }, {
        title: "相关内容",
        key: "hits",
        renderValue: hits => {
            if (hits) {
                return (
                    <div className="search-hits">
                        { hits.map((hit, index) => <pre key={index}>{hit.code}</pre>) }
                    </div>
                )
            }
            return null
        }
    }, {
        title: "操作",
        fixed: 'right',
        align: 'center',
        width: 100,
        getValue: r => r,
        renderValue: r => {

            const urlPattern = detailRoutes[r.entityName + "_" + r.codeFieldName];

            if (urlPattern && r.recordId) {
                const url = urlPattern.replace(":id", r.recordId);

                return (
                    <Button onClick={_ => routeTo(url)} label={"查看"}></Button>
                )

            } else {
                return null
            }

            return r.recordId
        }
    }
    ];

    return (
        <div className="bw-admin--code-search ">
            <div className="toolbar">
                <Search onKeywordChange={setKeyword} />
                <Button onClick={doSearch} disabled={!keyword} buttonType="primary" />
            </div>
            <LocalPagedTable rowKey="recordId" columns={columns} dataSource={data}
                style={{ height: "calc(100% - 49px)" }}
                pagination={{ pageSize: 20 }}
            />
        </div>
    )
}

const QueryText = `
query ($keyword: String!){
    searchDefinitionCode(keyword:$keyword) {
          entityName
      recordId
      recordName
      codeFieldName
      hits {
          line
          code
      }
    }
  }
`