
import React from 'react'

import ModalLink from 'Client/js/components/ModalLink'

import './AvatarAndLabel.less'

import Avatar from 'bwax-ui/components/Avatar';

export default function AvatarAndLabel(props) {
    const { params } = props;
    const { avatar, label, link, nickName, phoneNumber } = params;

    const nameToUse = nickName || (phoneNumber ? phoneNumber.slice(-6) : "") || label;

    let inner = 
        <>
            <Avatar avatar={avatar} nickName={nameToUse} size={18} style={{ marginRight: "0.25rem" }} />
            <span>{nameToUse} </span>
        </>

    return (
        <div className="avatar-and-label" onClick={e => {
            if(link) {
                e.stopPropagation();
            }            
        }}>
            {
                link ?
                    <ModalLink to={link}>{inner}</ModalLink> :
                    inner
            }
        </div>
    )

}