
import {EditorState, Modifier, ContentBlock} from 'draft-js'
import { isCodeBlock } from './plugins/code'

export default function (editor, htmlToDraftContent, {getEditorState, setEditorState}) {
  return (text, html)  => {
    /// convert the html to the state
    //console.log("text: ", text);
    // console.log("html: ", html);
    // console.log("DOMParser html: ", new DOMParser().parseFromString(html, "text/xml"));

    // check if the content is copied from the current editor content
    const editorKey = editor.getEditorKey()
    const internalClipboard = editor.getClipboard()

    if(
      (html && html.indexOf(editorKey) !== -1) ||
      // if the copy cross multiple blocks with content, editorKey should present
      (internalClipboard &&
        internalClipboard.size === 1 &&
        internalClipboard.first().getText() === text)
    ) {
      // let the default case handle it
      return 'not-handled'
    }

    // if alreadyCodeBlock just pasted text into codeBlock
    if(isCodeBlock(getEditorState()) && text) {

      const editorState = getEditorState()
      const content = editorState.getCurrentContent()
      const selection = editorState.getSelection()

      setEditorState(
        EditorState.push(
          editorState,
          Modifier.replaceText(
            content,
            selection,
            text
          ),
          "insert-characters"
        )
      )
      return 'handled'
    }

    /// otherwise convert the html to the content blocks using the converter
    if(html && html != text  ) {
      const contentState = htmlToDraftContent(html)
      const editorState = getEditorState()

      setEditorState(
        EditorState.push(
          editorState,
          Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            contentState.getBlockMap()
          ),
          "insert-fragment"
        )
      )
      return "handled"
    }
  }
}
