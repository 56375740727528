import React, { useEffect, useState } from 'react'

import ReactDOM from 'react-dom';

export default function AutoImage (props) {

    const { width, height, src, style } = props;

    const [ imgWidth, setImgWidth ] = useState(width);
    const [ imgHeight, setImgHeight ] = useState(height);

    const [ backgroundImage, setBackgroundImage ] = useState(null);

    const backgroundPosition = "center";

    const calculateImgSize = (src, width, height, guard) => {
        if(!src) {
            console.warn("Image must have src!")
            return 
        }
        const img = new Image();
        img.src = src;
        img.onload = () => {

            const ratio = img.width / img.height
            let [ targetWidth, targetHeight ] = [ width, height ]
            // let backgroundPosition = 'cover'

            // //如果宽高相差五倍以上，截取中间显示
            // if(ratio >= 5 || ratio <= 0.2) {
            //     backgroundPosition = 'center'
            // }

            //根据宽度自适应，高度不超过宽度的5倍,不小于宽度的1/5
            if(width && !height) {
                targetHeight = width / ratio
                if(targetHeight > 5 * width) {
                    targetHeight = 5 * width
                }
                if(targetHeight < (width / 5)) {
                    targetHeight = width / 5
                }
            }

            //根据高度自适应，宽度不超过高度的5倍，不小于高度的1/5
            if(!width && height) {
                targetWidth = height * ratio
                if(targetWidth > 5 * height) {
                    targetWidth = 5 * height
                }
                if(targetWidth < (height / 5)) {
                    targetWidth = targetHeight / 5
                }
            }
            //TODO:没有宽度、高度默认使用的高度？
            // if(!width && !height) {
            //     targetWidth = targetHeight = defaultWidth
            // }

            ReactDOM.unstable_batchedUpdates(() => {

                if(guard()) {
                    setImgWidth(targetWidth);
                    setImgHeight(targetHeight);                    
                    setBackgroundImage(`url(${src})`);
                }

            })

        }
    }


    useEffect(() => {
        let active = true;
        calculateImgSize(src, width, height, () => active);
        return () => {
            active = false
        }
    }, [ src ]);

    return (
        <div
        style={{
            backgroundImage,
            backgroundRepeat: 'no-repeat',
            width: imgWidth,
            height: imgHeight,
            minWidth: imgWidth,
            backgroundSize: 'cover',
            backgroundPosition,
            ...style
        }}>
    </div>
    )
}
