

import React from 'react';

import "./CustomDateRangePicker.less";

import DatePicker from 'rsuite/DatePicker'

//   ~value: (option('a), option('a)), /// start, end
//   ~showTime: bool=?,
//   ~allowClear: bool=?,
//   ~format: string=?,
//   ~onChange: ((option('a), option('a))) => unit,
//   ~placeholder: option(array(string))=?,

export default function CustomDateRangePicker (props) {

    const { value, showTime, format, onChange, placeholder = [] } = props;

    const className = showTime ? "time-picker" : "date-picker";

    console.log(format);

    const [ startDate, endDate ] = value || [];

    const updateStart = v => onChange([v, endDate]);
    const updateEnd = v => onChange([startDate, v]);

    return (
        <div className={"custom-date-range-picker " + className}>
            <DatePicker
                className="left-picker"
                value={startDate}
                size={"sm"}
                onChange={(date => updateStart(date))}
                format={format}
                placeholder={placeholder[0]}
            />
            <span className="range-connector">~</span>
            <DatePicker
                className="right-picker"
                value={endDate}
                size={"sm"}
                onChange={date => updateEnd(date)}
                format={format}
                placeholder={placeholder[1]}                
            />
        </div>
    )
}