// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_math = require("bs-platform/lib/js/js_math.js");
var Lang_eval$BwaxAdmin = require("../../lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../runtime_common.bs.js");
var Lang_typing_base$BwaxAdmin = require("../../lang_typing_base.bs.js");

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        "Basics." + (name + ": Invalid Arguments")
      ];
}

function func(args) {
  if (args) {
    var match = args[0][0];
    if (typeof match === "number" || match.tag !== /* V_literal */5) {
      return fail("toFloat");
    } else {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag || args[1]) {
        return fail("toFloat");
      } else {
        return /* tuple */[
                /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Float */Caml_chrome_debugger.variant("Float", 1, [match$1[0]])]),
                Lang_typing_base$BwaxAdmin.$$float
              ];
      }
    }
  } else {
    return fail("toFloat");
  }
}

function func$1(args) {
  if (args) {
    var match = args[0];
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return fail("round");
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number") {
        return fail("round");
      } else {
        switch (match$2.tag | 0) {
          case /* Int */0 :
              if (args[1]) {
                return fail("round");
              } else {
                return /* tuple */[
                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [match$2[0]])]),
                        match[1]
                      ];
              }
          case /* Float */1 :
              if (args[1]) {
                return fail("round");
              } else {
                return /* tuple */[
                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [Math.round(match$2[0]) | 0])]),
                        Lang_typing_base$BwaxAdmin.$$int
                      ];
              }
          default:
            return fail("round");
        }
      }
    }
  } else {
    return fail("round");
  }
}

function func$2(args) {
  if (args) {
    var match = args[0];
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return fail("floor");
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number") {
        return fail("floor");
      } else {
        switch (match$2.tag | 0) {
          case /* Int */0 :
              if (args[1]) {
                return fail("floor");
              } else {
                return /* tuple */[
                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [match$2[0]])]),
                        match[1]
                      ];
              }
          case /* Float */1 :
              if (args[1]) {
                return fail("floor");
              } else {
                return /* tuple */[
                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [Js_math.floor(match$2[0])])]),
                        Lang_typing_base$BwaxAdmin.$$int
                      ];
              }
          default:
            return fail("floor");
        }
      }
    }
  } else {
    return fail("floor");
  }
}

function func$3(args) {
  if (args) {
    var match = args[0];
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return fail("ceiling");
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number") {
        return fail("ceiling");
      } else {
        switch (match$2.tag | 0) {
          case /* Int */0 :
              if (args[1]) {
                return fail("ceiling");
              } else {
                return /* tuple */[
                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [match$2[0]])]),
                        match[1]
                      ];
              }
          case /* Float */1 :
              if (args[1]) {
                return fail("ceiling");
              } else {
                return /* tuple */[
                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* Int */Caml_chrome_debugger.variant("Int", 0, [Js_math.ceil(match$2[0])])]),
                        Lang_typing_base$BwaxAdmin.$$int
                      ];
              }
          default:
            return fail("ceiling");
        }
      }
    }
  } else {
    return fail("ceiling");
  }
}

var externals_vals_000 = /* tuple */[
  "toFloat",
  Runtime_common$BwaxAdmin.pack_func(1, func)
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "round",
      Runtime_common$BwaxAdmin.pack_func(1, func$1)
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "floor",
          Runtime_common$BwaxAdmin.pack_func(1, func$2)
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "ceiling",
              Runtime_common$BwaxAdmin.pack_func(1, func$3)
            ],
            /* [] */0
          ])
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

var externals_000 = "Basics";

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\nmodule Basics;\n\n-- Given a value, returns exactly the same value. This is called the identity function.\nidentity v: a -> a = v;\n\n-- 如下的函数，建议使用 Number 模块的版本\n-- int float conversion\ntoFloat: Int -> Float = external;\n\nround: Float -> Int = external;\n\nfloor: Float -> Int = external;\n\nceiling: Float -> Int = external;\n\ntruncate f: Float -> Int =\n  if f < 0 then\n    ceiling f\n  else \n    floor f\n;\n\n-- commonly used  \nisDigit c =  \n  c == \'1\' || c == \'2\' || c == \'3\' || c == \'4\' || c == \'5\' ||\n  c == \'6\' || c == \'7\' || c == \'8\' || c == \'9\' || c == \'0\'\n;\n\n\n\n\n";

exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* externals_vals Not a pure module */
