
import React from 'react'

import { Pagination as PaginationComp } from 'antd';

import InputSelect from "Client/js/ui/components/InputSelect";

import './Pagination.less';


export default function Pagination (props) {

    const {
        onPageChange,
        hideOnSinglePage = false,
        pageSize,
        pageSizeOptions,
        onPageSizeChange,
        currentPage,
        totalCount,
    } = props

    const showPageSizeSelection = pageSizeOptions && pageSizeOptions.length > 1;

    const selectionWidth = (pageSizeOptions || []).some(v => v >= 100) ? "3.75rem" : "3.25rem";

    return (
        <div className="admin--pagination">
            <PaginationComp
                total={totalCount}
                pageSize={pageSize}
                size={"small"}
                current={currentPage}
                onChange={onPageChange}
                showSizeChanger={false}
                showLessItems={true}
                hideOnSinglePage={hideOnSinglePage} />
            {
                showPageSizeSelection ?
                    <InputSelect
                        className="pagination-size"
                        style={{
                            width: selectionWidth
                        }}
                        size="small"
                        value={pageSize}
                        dropdownStyle={{
                            textAlign: "right"
                        }}
                        isClearable={false}
                        onChange={value => onPageSizeChange && onPageSizeChange(value)}
                        options={pageSizeOptions.map(o => ({ label: o, value: o }))}
                        menuPlacement={"top"}
                    />
                    : null
            }
        </div>
    )
}