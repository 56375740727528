

import React, { useEffect } from "react";

import { Helmet } from 'react-helmet-async';

import AdminPageContainer from "Client/ml/AdminPageContainer";

export default function RecordDataPage (props) {

    const { page, pageType, id, entity, match, facade, routeTo, history, location, } = props;

    const pageName = entity.name + "::" + pageType + (id ? "::" + id : "");
    

    useEffect(() => {
        console.log("Mounted");
    }, [])

    const entityName = entity.name;
    
    function renderAdminPage() {

        return (
            <AdminPageContainer key={pageName} {...{
                compiled: page.compiled,
                facade, 
                match,
                location,
                routeTo,
                pageName,

                entityName,
                recordId: id,
            }} />
        )
        
    }

    return (
        <>
            <Helmet>
                <title>{ entityName } | { id ? "详情" : "列表" }</title>
            </Helmet>  
            { renderAdminPage() }       
        </>
    )

}
