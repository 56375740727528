
import useDataLoader from 'bwax-ui/legacy/store/useDataLoader'
import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery';
import QueryTargetContext from 'bwax-ui/legacy/store/QueryTargetContext';
import { useContext } from 'react';

import { hashCode } from 'bwax/utils';

const getQueryText = (entityName, fieldName, keyword, ) => {
    if(keyword === undefined) {
        return `query { getFieldUsedValues( entity: "${entityName}", field: "${fieldName}") 
        { value count } } `
    } else {
        return `query { getFieldUsedValues( entity: "${entityName}", field: "${fieldName}", keyword: "${keyword}") 
        { value count } } `
    }
}

const QueryText = `
query($entityName: String!, $fieldName: String!, $keyword: String, $condition:[[GetFieldUsedValuesConditionInput]], $offset: Float, $limit: Float, $appCode: String!) {
  getFieldUsedValues(entity: $entityName, field: $fieldName, keyword: $keyword, condition: $condition, offset: $offset, limit: $limit, appCode: $appCode ) {
      value count
  }
}
`

export default function getFieldUsedValues({applicationCode, entityName, fieldName, keyword, condition, limit, offset, queryTarget = 'data'}) {

    return async env => {        

        const runner = queryTarget == 'definition' ? runDefinitionQuery : runDataQuery;

        const result = await runner(env)(QueryText)({
            entityName, fieldName, keyword, condition, limit, offset,
            appCode: applicationCode
        })

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.getFieldUsedValues ? data.getFieldUsedValues: null
        }
    }
}


export function useFieldUsedValues ({ applicationCode, entityName, fieldName, keyword, condition, limit, offset, alwaysQuery } = {}) {

    const queryTarget = useContext(QueryTargetContext) || "data";

    const key = 'useFieldUsedValues::' + entityName + '::' + fieldName + '::' + hashCode(JSON.stringify({ keyword, condition, limit, offset })) + queryTarget;
    
    // 只有 keyword 被输入值之后才查：
    // TODO 后面可能要增加一些配置项，某些字段，即使是空字符也 autocomplete.
    const shouldQuery = alwaysQuery || (keyword && keyword.trim().length > 0);

    const loaders = shouldQuery ? { [key]: getFieldUsedValues({ 
        entityName, fieldName, keyword, condition, limit, offset,
        applicationCode,
        queryTarget 
    })} : {}

    const { getData, forceLoad } = useDataLoader(loaders, { noNProgress: true })

    if(shouldQuery) {
        const [ values ] = getData(key)
        return values
    } else {
        return []
    }
    
}
