
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

export default createBlockTypeButton({
  blockType: 'header-three',
  children: (
    "H3"
  ),
});
