
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/react';

import React, { useState } from 'react'

import { BiSquare } from 'react-icons/bi';

import PageDesigner from '../PageDesigner';
import ResourceMananger from '../inputs/ResourceMananger';

import getImageURL from 'bwax-ui/getImageURL'
import StyleForm from './form/StyleForm';

export const commonAttributes = {
    style: {
        padding: {
            label: "Padding",
            type: "ShortText"
        },
        margin: {
            label: "Margin",
            type: "ShortText"
        },

        width: {
            label: "Width", type: "Number",
        },
        height: {
            label: "Height", type: "Number",
        },

        display: {
            label: "Display",
            type: "Select",
            options: ["block", "flex", "inline-block", "inline"]
        },
        // 
        flexDirection: {
            label: "Direction",
            type: "Select",
            options: ["row", "column", "row-reverse", "column-reverse"],
            // 
            applicableIf: attributes => (attributes.display == "flex")
        },

        flex: {
            label: "Flex",
            type: "ShortText",
            applicableIf: (attributes, blockNode) => {
                // if it is a flex child
                if (blockNode && blockNode.parentNode && typeof (window) !== 'undefined') {
                    const display = window.getComputedStyle(blockNode.parentNode)["display"];
                    if (display === "flex" || display === "inline-flex") {
                        return true
                    }
                }
                return false
            }
        },

        backgroundColor: {
            label: "Background Color",
            type: "Color",
        },
        backgroundImage: {
            label: "Background Image",
            type: "Image",

            // image is  { file: <file>, processor: <string> }
            // backgroundImage is [ image | gradient ]
            transform: (value, attributes) => {
                
                const values = Array.isArray(value) ? value : (value ? [value] : []);
                return values.map(bgImage => {
                    if(typeof(bgImage) === 'string') {
                        return bgImage
                    } else {
                        const { file, processor = "large" } = bgImage
                        return `url("${getImageURL(file, processor)}")`
                    }
                })
            }
        },

        // define them so that they are considered style attributes:

        // layout
        alignItems: {},
        justifyContent: {},
        gap: {},
        flexWrap: {},
        alignContent: {},
        alignSelf: {},
        order: {},
        overflow: {},
        objectFit: {},
        objectPosition: {},

        //size
        maxWidth: {},
        minWidth: {},
        maxHeight: {},
        minHeight: {},

        //border
        borderRadius: {},
        borderTop: {},
        borderRight: {},
        borderBottom: {},
        borderLeft: {},

        //spacing
        // marginTop: {},
        // marginRight: {},
        // marginBottom: {},
        // marginLeft: {},
        // paddingTop: {},
        // paddingRight: {},
        // paddingBottom: {},
        // paddingLeft: {},

        // backgrounds
        backgroundClip: {},
        backgroundRepeat: {},
        backgroundAttachment: {},
        backgroundPosition: {},
        backgroundSize: {},

        // typography
        fontFamily: {},
        fontWeight: {},
        fontSize: {},
        lineHeight: {},
        color: {},
        textAlign: {},
        fontStyle: {},
        letterSpacing: {},
        textIndent: {},
        textTransform: {},
        direction: {},
        whiteSpace: {},
        textShadow: {},
        textDecoration: {},
        columnCount: {},
        columnGap: {},
        columnRule: {},
        columnSpan: {},

        // effects
        opacity: {},
        cursor: {},
        boxShadow: {},

    }
};

// extract the style attributes defined in the common attributes:
// return the style attributes and other attributes;
export function extractStyleAttrs(attributes) {
    return Object.keys(attributes).reduce(([accStyle, accOther], key) => {
        const styleAttrConfig = commonAttributes.style[key];
        if (styleAttrConfig) {
            const value = styleAttrConfig.transform && attributes[key] ?
                styleAttrConfig.transform(attributes[key], attributes) : attributes[key];

            if(value !== undefined && value !== null) {
                return [{ ...accStyle, [key]: value }, accOther]
            } else {
                return [{ ...accStyle }, accOther]
            }             
            
        } else {
            const value = attributes[key];
            return [accStyle, { ...accOther, [key]: value }]
        }
    }, [{}, {}]);
};

const textAttributes = {
    text: {
        label: "Text",
        type: "Text"
    }
}

// text, paragraph, block (div), h1 ... h6, 基本元素
// image, video
// input text, input file, 
// 
const text = {
    name: "text",
    label: "Text",
    attributes: textAttributes,
    icon: <BiSquare />,

    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {
        const [styleAttrs, { text }] = extractStyleAttrs(attributes);
        return (
            <div ref={ref} {...otherProps} className={className} style={{
                ...styleAttrs,
            }}>{text || <span style={{ opacity: 0.5 }}>Please enter some text</span>}</div>
        )
    }),

};

const paragraph = {
    name: "paragraph",
    label: "Paragraph",
    attributes: textAttributes,
    icon: <BiSquare />,
    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {
        const [styleAttrs, { text }] = extractStyleAttrs(attributes);
        return (
            <div ref={ref} {...otherProps} className={className} style={{
                ...styleAttrs,
            }}>{text || <span style={{ opacity: 0.5 }}>Please enter some text</span>}</div>
        )
    }),
}

const block = {
    name: "block",
    label: "Block",
    isContainer: true,
    icon: <BiSquare />,

    // 
    attributeForms: {
        style: StyleForm
    },

    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {

        // const [styleAttrs, _] = extractStyleAttrs(attributes);
        // return (
        //     <div ref={ref} {...otherProps} className={className} style={{
        //         ...styleAttrs,
        //         // ...(children ? {} : { minHeight: 100, minWidth: 150})

        //     }}>{children}</div>

        // )
        const [styleAttrs, _] = extractStyleAttrs(attributes);
        const cssObj = css(styleAttrs);

        /**
         * backgroudImage 是 array 情况下，emotion 返回的 style string 中会有多个 background-image
         * 这里将 background-image 合并
         * */ 
        const bgImageValueRegx = /background-image:(.*)/
        const stylesArr = cssObj.styles.split(";")
        const bgImageArr = stylesArr.filter(style => style.startsWith("background-image"))
        let bgImageStr = ""
        if(bgImageArr.length > 0) {
            bgImageStr = bgImageArr.reduce((acc, current, currentIndex) => {
                const matchResult = current.match(bgImageValueRegx)
                return acc + (currentIndex === 0 ? matchResult[1] : "," + matchResult[1])
            }, "background-image:")
        }

        const handledBgImageStylesStr = stylesArr.filter(style => !style.startsWith("background-image")).join(";") + bgImageStr


        return (
            <>
            <Global styles={css`.css-${cssObj.name}{ ${handledBgImageStylesStr} }`}></Global>
            <div ref={ref} {...otherProps} className={className + " css-" + cssObj.name} style={{
                // ...(children ? {} : { minHeight: 100, minWidth: 150})
                // padding: 12,

            }}>{children}</div>
            </>
        )
    }),

}


const image = {
    name: "image",
    label: "Image",
    attributes: {
        image: {
            label: "Image",
            type: "Image",
        }
    },
    icon: <BiSquare />,

    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {
        const [styleAttrs, { image }] = extractStyleAttrs(attributes);

        const [open, setOpen] = useState(false);


        function renderEle() {
            if (image && image.file) {
                const { file, processor = "large" } = image
                return (
                    <img src={getImageURL(file, processor)} ref={ref} {...otherProps} className={className} style={{
                        width: "100%", // 默认是 100%;
                        ...styleAttrs,
                        // ...(children ? {} : { minHeight: 100, minWidth: 150})
                    }} onDoubleClick={_ => {
                        setOpen(true)
                    }} />
                )
            } else {
                return (
                    <div ref={ref} {...otherProps} className={className} style={{
                        ...styleAttrs,
                        backgroundImage: `url("https://bw-dev.static.qunfengshe.com/public/cb-bwax/design-resources/placeholder-image.webp")`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        minHeight: 100, minWidth: 150,
                        display: "flex",
                    }}>
                    </div>
                )
            }
        }
        return (
            <>
                {renderEle()}
                <ResourceMananger open={open} setOpen={setOpen} onFileSelect={file => {
                    updateBlockAttributes({ image: { file } });
                }} />
            </>
        )
    })
}

const video = {
    name: "video",
    label: "Video",
    icon: <BiSquare />
}

const inputText = {
    name: "inputText",
    label: "Input Text",
    icon: <BiSquare />
}

const inputFile = {
    name: "inputFile",
    label: "Input Text",
    icon: <BiSquare />
}

const blockTypesByCategory = {
    "Basic": [text, paragraph, block],
    "Media": [image, video],
    "Input": [inputText, inputFile],
}

const blockTypes = Object.keys(blockTypesByCategory).reduce((acc, category) => {
    const list = blockTypesByCategory[category].map(bt => ({ ...bt, category }))
    return [...acc, ...list]
}, [])


function HtmlPageDesigner({ value, onChange }) {
    return (
        <PageDesigner
            value={value} onChange={onChange} blockTypes={blockTypes} commonAttributes={commonAttributes}
        ></PageDesigner>
    )
}

HtmlPageDesigner.blockTypes = blockTypes;
HtmlPageDesigner.commonAttributes = commonAttributes;

export default HtmlPageDesigner;
