import {insertNewLine, isCodeBlock} from './plugins/code'
import {
  Modifier,
  EditorState,
  genKey,
  ContentBlock,
  BlockMapBuilder
} from 'draft-js'

export default function({setEditorState}) {
  return (event, editorState) => {
    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()
    const focusKey = selection.getFocusKey()
    const anchorKey = selection.getAnchorKey()

    //选择的block是 code block
    if(isCodeBlock(editorState)) {
      setEditorState(insertNewLine(editorState))
      return 'handled'
    }

    //选择的是一个block 并且是atomic block时
    if(focusKey === anchorKey) {
      const currentBlock = content.getBlockForKey(focusKey)

      if(currentBlock.getType() === 'atomic') {
        const emptyBlock = new ContentBlock({
          key: genKey(),
          type: 'unstyled'
        })
        setEditorState(
          EditorState.push(
            editorState,
            Modifier.replaceWithFragment(
              content,
              selection,
              BlockMapBuilder.createFromArray([currentBlock, emptyBlock]) //blockMap
            ),
            'insert-fragment'
          )
        )
        return 'handled'
      }
    }

    return 'not-handled'
  }
}
