
import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

// admin page template
// 支持页面参数，有 current user.
export default ({ entityName, pageType }) => {

    // console.log(entityName, pageType);
  
      const name = entityName ? lang_entry_slim.normalize_name(entityName) : undefined;
  
      // const initTypeSignature = name && pageType == "recordDetail" ?
      //     `` :
      //     "params user: List (String, String) -> User"; 

      if(pageType == "recordDetail") {

        return `
-- 以下为生成的代码（请删除这一行）

import AdminPage exposing (..);

type alias User = # E_用户;
type alias R_${name} = # E_${name};

type Msg = 
  | GotError String
;

type alias Model = 
  { error: Maybe String
  , record: R_${name}
  }
;

init params (record, user): List (String, String) -> (R_${name}, User) -> (Model, Cmd Msg) = 
  ({ error = Nothing , record }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)
  | _ -> (model, none)
;

ready model: Model -> (Model, Cmd Msg) = 
  (model, none)
;

dispose model: Model -> Cmd Msg =
  none
;

card model = 
  recordCard 
    ( Query_${name}.config { criteria = And { id = { eq = model.record.id } } } )
    { title = "基本信息"
    }
;

-- Admin Page 返回 PageLayout, List (String, TabPage), 或者 Widget
view model: Model-> PageLayout msg = 
  WideAndNarrow
    [ OneColumn 
        [ card model |> widgetBox (Just 600)
        ]
    ] 
    [] 
  
  -- Full 
  --  []
  --  []
  --  []
;
        `

      } else if (pageType == "recordList") {
        
        return `
-- 以下为生成的代码（请删除这一行）
import AdminPage exposing (..);

type alias User = # E_用户;

type Msg = 
  | GotError String
;

type alias Model = 
  { error: Maybe String
  }
;

init params user: List (String, String) -> User -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;


update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)
  | _ -> (model, none)
;

ready model: Model -> (Model, Cmd Msg) = 
  (model, none)
;

dispose model: Model -> Cmd Msg =
  none
;

list = 
  recordTable
    ( Query_${name}.config { sorting = { 创建时间 = DESC } } )
    { title = "${entityName}列表", id = "${entityName}列表"
    , operations = 
        ~[{ label = "查看", op = GotoDetail {}
          }
        ]
    , topRightButtons = 
        ~[{ label = "新增", buttonType = Primary, action = AddRecordAction {}      
          }
        ]
    }
    
;

-- Admin Page 返回 PageLayout, List (String, TabPage), 或者 Widget
view model: Model-> a = 
  -- WideAndNarrow
  --   [ OneColumn 
  --       [ list |> widgetBox (Just 600)
  --       ]
  --   ] 
  --   [] 
  list

`

      } else {

        return `
-- 以下为生成的代码（请删除这一行）
import AdminPage exposing (..);

type alias User = # E_用户;

type Msg = 
  | GotError String
;

type alias Model = 
  { error: Maybe String
  }
;

init params user: List (String, String) -> User -> (Model, Cmd Msg) = 
  ({ error = Nothing }, none )   
;

update msg model: Msg -> Model -> (Model, Cmd Msg) = 
  case msg of
  | GotError error ->  
      ({ model | error = Just error } , none)
  | _ -> (model, none)
;

ready model: Model -> (Model, Cmd Msg) = 
  (model, none)
;

dispose model: Model -> Cmd Msg =
  none
;

-- Admin Page 返回 PageLayout, List (String, TabPage), 或者 Widget
view model: Model-> a = 
  -- WideAndNarrow
  --  [] 
  --  [] 
  
  Full 
    []
    []
    []

`
      }

  }
