
import React, { useState, useRef, useEffect } from 'react'

import { parser } from "Client/js/codeEditor/json/json.parser";
import { continuedIndent, indentNodeProp, foldNodeProp, foldInside, LRLanguage, LanguageSupport } from "@codemirror/language"

import { lightStyle } from "Client/js/codeEditor/languageSupports";

import { styleTags, tags as t } from "@lezer/highlight"

import { EditorState } from "@codemirror/state";
import { EditorView, dropCursor } from "@codemirror/view";
import { keymap, highlightActiveLine, rectangularSelection, lineNumbers, highlightActiveLineGutter } from "@codemirror/view";
import { Language, foldGutter, foldKeymap, codeFolding } from '@codemirror/language'
import { linter, lintKeymap } from "@codemirror/lint"

import { defaultKeymap, indentWithTab, history, historyKeymap } from "@codemirror/commands";

// import { json, jsonLanguage, jsonParseLinter } from "@codemirror/lang-json";

// import { commentKeymap } from "@codemirror/comment";

import '../../../codeEditor/CodeMirrorEditor.less';


export default function JSONInput(props) {

    const { value, onChange } = props;

    const editorRef = useRef();
    const viewRef = useRef();

    const [sourceCode, setSourceCode] = useState(_ => {
        return JSON.stringify(value || {}, null, 2);
    });




    function updateCodeText(codeText) {
        if (viewRef.current && viewRef.current.state.doc) {
            const currentDoc = viewRef.current.state.doc;
            const docText = currentDoc.toJSON().join("\n");
            if (docText != codeText) {
                viewRef.current.dispatch({
                    changes: {
                        from: 0,
                        to: currentDoc.length,
                        insert: codeText,
                    }
                })
            }
        }
    }


    useEffect(() => {

        // const [dropCursorPos, drawDropCursor] = dropCursor();

        const afterLinter = (codeText, tree) => {

            console.log(">>> ", codeText, tree);
            return []
        };

        const state = EditorState.create({
            // doc: value || storedItem || "",
            // doc: storedItem || "",
            doc: sourceCode || "",
            extensions: [
                // keymap.of(searchKeymap),
                keymap.of(defaultKeymap),
                keymap.of([indentWithTab]),
                keymap.of(foldKeymap),
                keymap.of(historyKeymap),
                keymap.of(lintKeymap),

                // [dropCursorPos, drawDropCursor],
                // EditorView.updateListener.of(update => {
                //     if (update.state.field(dropCursorPos) !== null) {
                //         dropAtRef.current = view.state.field(dropCursorPos);
                //     }

                // }),
                json().extension,

                lightStyle,


                linter(view => {
                    let newValue;
                    try {
                        newValue = JSON.parse(view.state.doc.toString())
                    } catch (e) {
                        if (!(e instanceof SyntaxError)) throw e
                        const pos = getErrorPosition(e, view.state.doc)
                        return [{
                            from: pos,
                            message: e.message,
                            severity: 'error',
                            to: pos
                        }]
                    }

                    // 没有错
                    onChange(newValue)

                    return []


                }, { delay: 700 }),

                // jsonHighlighting,

                // jsonLanguage.extension,

                // jsonParseLinter(),

            ]
        });

        const view = new EditorView({ state, parent: editorRef.current });
        viewRef.current = view;

        return () => {
            view.destroy();
            // editorRef.current.removeEventListener("input", log);
        };
    }, []);

    return (
        <div className="code-mirror-editor w-full min-h-[6rem] px-1 py-1" ref={editorRef}></div>
    );
}




/// A language provider that provides JSON parsing.
const jsonLanguage = LRLanguage.define({
    name: "json",
    parser: parser.configure({
        props: [
            // jsonHighlighting,
            styleTags({
                String: t.string,
                Number: t.number,
                "True False": t.bool,
                PropertyName: t.propertyName,
                Null: t.null,
                ",": t.separator,
                "[ ]": t.squareBracket,
                "{ }": t.brace
            }),
            indentNodeProp.add({
                Object: continuedIndent({ except: /^\s*\}/ }),
                Array: continuedIndent({ except: /^\s*\]/ })
            }),
            foldNodeProp.add({
                "Object Array": foldInside
            })
        ]
    }),
    languageData: {
        closeBrackets: { brackets: ["[", "{", '"'] },
        indentOnInput: /^\s*[\}\]]$/
    }
})

/// JSON language support.
function json() {
    return new LanguageSupport(jsonLanguage)
}

function getErrorPosition(error, doc) {
    let m
    if (m = error.message.match(/at position (\d+)/))
        return Math.min(+m[1], doc.length)
    if (m = error.message.match(/at line (\d+) column (\d+)/))
        return Math.min(doc.line(+m[1]).from + (+m[2]) - 1, doc.length)
    return 0
}