

import EntityDataTabs from 'Client/js/pages/EntityDataTabs';

import React, { useState, useEffect } from 'react'

import AddAdminUser from './AddAdminUser'

export default {

    /// 其他自定义的 routes， 不包括 menu 里面得到。
    /// 前面会自动加上 /ext/:extKey 
    routes: [{
        path: "admin-users",
        comp: props => {
            let tabs = {
                "后台用户": {
                    type: "RecordList",
                    options: {
                        criteria: [
                            [
                                {
                                    '系统角色': {
                                        contains: 'Operator'
                                    }
                                }
                            ],
                            [
                                {
                                    '系统角色': {
                                        contains: 'Admin'
                                    }
                                }
                            ]
                        ],
                        fieldColumns: {
                            "昵称": {},
                            "头像": {},
                            "系统角色": {
                                "editingAllowed": true,
                                "displayParams": {
                                    "excludedValues": ["Designer",  "Maintainer", "Tester"]
                                },
                                "inputParams":  {
                                    "excludedValues": ["Designer",  "Maintainer", "Tester"]
                                }
                            },
                            "权限角色": {
                                "editingAllowed": true,
                                "inputParams":  {
                                    "fixedCriteria": 
                                        {
                                            "名称": {
                                                "notLike": "%登录用户%"
                                            }
                                        }                                            
                                }
                            }
                        },
                        allowedActions: [
                            {
                                type: "AnyWidget",
                                name: "admin::Auth::AddAdminUser",
                                params: {
                                }
                            }
                        ]
                    }
                }
            };
            const { facade, loadingComp: Loading  } = props;

            const entityKey = "AuthEntityUser";

            const [ prepared, setPrepared ] = useState(false);
            useEffect(() => {   
                if(!facade.getEntity(entityKey)){
                    setPrepared(false);
                    (async () => {
                        await facade.prepare([entityKey]);
                        setPrepared(true);
                    })();
                } else {
                    if(!prepared) {
                        setPrepared(true)
                    }            
                }
            }, [ entityKey ])
        
            if(!prepared) {
                return <Loading />
            }
    
            const entity = facade.getEntity(entityKey);

            if(!entity) {
                return <div style={{padding: "1rem"}}>404: 数据实体【{entityKey}】没找到</div>
            }

            return <EntityDataTabs entity={entity} tabs={tabs} {...props} />
        }
    }],


    /// 前面会自动加上 "admin::<extKey>::"
    widgets: {
        AddAdminUser
    },



}