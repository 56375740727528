
import invariant from 'invariant'
import evaluateExpression from '../expression/evaluateExpression';
import ExpressionAnalyzer from '../expression/ExpressionAnalyzer';
import Operators from '../expression/Operators'
import Functions from '../expression/Functions'

import operationHandlers from './operationHandlers'

import React from 'react'

export class Placeholder {
    toString() {
        return "TO BE EVALUATED"
    }
}

export class NodeHolder {
    constructor(value, node, localData) {
        this.value = value
        this.node = node
        this.localData = localData
    }
}

export function isNodeHolder(o) {
    return o instanceof NodeHolder
}

export default class DataBook {
    // place holder
}

export function traverseNodeValue(value, name, visitValue, visitNodeHolder) {

    if(React.isValidElement(value)) {
        return
    }

    if (value instanceof NodeHolder) {
        let done = false
        if (visitNodeHolder) {
            done = visitNodeHolder(value, name)
        }
        if (done !== true) {
            traverseNodeValue(
                value.value, value.node.name, visitValue, visitNodeHolder
            )
        }

    } else {

        let done = visitValue(value, name)

        if (done !== true) {

            if (Array.isArray(value)) {
                value.forEach((v, index) =>
                    traverseNodeValue(
                        v, index, visitValue, visitNodeHolder
                    )

                )
            } else if (value !== null && typeof (value) === 'object') {
                Object.keys(value).forEach(
                    k => traverseNodeValue(
                        value[k], k, visitValue, visitNodeHolder
                    )
                )
            } else {
                /// stop
            }
        }

    }

}

///// default strategy methods:
function defaultQNameValueResolver(data) {

    function getValue(acc, n) {
        // 可能需要特殊处理
        return acc[n]
    }

    return (exp) => (qName) => {
        const join = qName.join(".")
        // first I need to find out the qName with type info:
        const reference = exp.references.find(r => r.qName.join(".") === join)
        invariant(reference, "the reference must exist for " + join)


        const { path } = reference
        const keyPath = (() => {
            if ((path.length === 0) || (path.length === 1 && path[0] === 'NOT_FOUND')) {
                // it means the qName should directly be used as the path
                // TODO path.length === 0 这个可能要测试一下
                return qName
            } else {
                /// 考虑到 localData 的情况：
                const pathToEvaluate = path.slice(path.length - (qName.length - 1))
                return [
                    qName[0],
                    ...pathToEvaluate.map(p => p.key || p),
                ]
            }
        })()

        const ret = keyPath.reduce((acc, n) => {
            // 若中间任意一个 section 是 null，那么整个 qName 都是 undefined
            if (acc === undefined || acc === null) {
                // return null
                // 改为返回 undefined ，用于适配 ReasonML 的 None
                return undefined
            } else {
                return getValue(acc, n)
            }
        }, data)

        return ret
    }
}
const defaultOperaters = Operators
const defaultFunctions = Functions


/// a new function to bridge the 
export function lookupValueFromNode(
    node, /// the node
    data, // the data "Js.Dict.t(Js.Json.t)"
    input, // the input state: "Js.tJ(s.Dict.t(Js.Json.t)))"
    preserved = [],
) {
    return lookupValue(node, {
        ...data, 
        input
    }, preserved)
}

/////////

export function lookupValue(node, data, 
        preserved = [], 
        { 
            qNameValueResolver = defaultQNameValueResolver,
            Operators = defaultOperaters,
            Functions = defaultFunctions,
        } = {} 
    ) {

    const preservedNodes = [...preserved, "__query__"]


    function resolveValue(node, data) {

        if (node === null || node === undefined) {
            return null
        }

        let ret = null

        if(React.isValidElement(node.nodeDef)) {

            // 如果是一个 React 元素
            /// 直接返回该元素
            return node.nodeDef


        } else if (node.category === 'object') {

            const firstChild = node.children[0]

            // 特殊处理的 operation，如 __apply__, __switch__, __def__
            if (firstChild && operationHandlers[firstChild.name]) {

                const resolveWithLocal = (node, newLocal) => {
                    return resolveValue(node, {
                        ...data, ...newLocal
                    })
                }

                ret = operationHandlers[firstChild.name](firstChild, resolveWithLocal, data)

            } else {

                ret = node.children.reduce((acc, c) => {
                    return {
                        ...acc,
                        [c.name]: resolveValue(c, data)
                    }
                }, {})
            }

        } else if (node.category === 'leaf') {

            ret = resolveValueFromLeaf(node, data)

        } else if (node.category === 'array') {

            ret = node.children.map(c => resolveValue(c, data))

        } else {

            ret = "UNKNOWN"
        }

        if (preservedNodes && preservedNodes.indexOf(node.name) !== -1) {
            return new NodeHolder(ret, node, data)

        } else {

            return ret
        }

    }

    function resolveValueFromLeaf(node, data) {

        const expressions = node.getExpressions()

        if (expressions && expressions.length > 0) {

            // 1. 处理不需要拼装的内容
            if (expressions.length === 1) {
                const theOnly = expressions[0]
                if (theOnly.position[0] === 0 && theOnly.position[1] === node.nodeDef.length) {
                    // 只需 evaluate 这个 expression 就可以了：
                    if (!theOnly.ast) {
                        console.warn(theOnly, "is not parsed to AST successfully")
                    }

                    const resolveQNameValue = qNameValueResolver(data)(theOnly)
                    const evaluated = evaluateExpression(theOnly.ast, 
                        { 
                            Operators, Functions, resolveQNameValue
                        }
                    )
                    return evaluated
                }
            }

            const nodeDef = node.nodeDef

            /// 2. 其他是需要拼装的，只要有拼装，那么最后就是 string:


            const { result, offset } = expressions.reduce((acc, e) => {

                const { position, ast } = e

                if (!ast) {
                    console.warn(e, "is not parsed to AST successfully")
                }
                const resolveQNameValue = qNameValueResolver(data)(e)
                const evaluated = evaluateExpression(
                    ast, 
                    { Operators, Functions, resolveQNameValue }
                )
                const prefix = nodeDef.slice(acc.offset, position[0])
            

                return {
                    result: acc.result + prefix + evaluated,
                    offset: position[1]  // 跨过整个 expression
                }
            }, {
                    result: "",  // 拼装的结果
                    offset: 0    // 当前处理到的 nodeDef 位置
                }
            )

            return result + nodeDef.slice(offset)

        } else {
            return node.nodeDef
        }
    }


    return resolveValue(node, data)


}
