import React, { useState } from 'react'

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

// 暂时只实现 panel 在左边的版本
export default function ResizableSplitPanels({ className, sidePanelClassName, mainAreaClassName, cacheKey, sidePanelContent, mainAreaContent }) {

    // "studio-side-panel-width"
    const [sidePanelWidth, setSidePanelWidth] = useStateWithLocalCache(cacheKey, 300);

    const [sidePanelResizerActive, setSidePanelResizerActive] = useState(false);

    const [sidePanelMinimized, setSidePanelMinimized ] = useStateWithLocalCache(cacheKey + "::minimized", false); 

    function updateSidePanelWidth(e) {
        setSidePanelWidth(prev => {
            const newSize = prev + e.movementX;
            return newSize < 100 ? 100 : newSize;
        })
    }

    function getSidePanelWidth () {
        return sidePanelMinimized || sidePanelWidth
    }

    return (
        <div className={className} style={{ display: "flex" }}
            onMouseMove={e => {
                if (sidePanelResizerActive) {
                    updateSidePanelWidth(e)
                }
            }}
            onMouseUp={() => {
                if (sidePanelResizerActive) {
                    setSidePanelResizerActive(false);
                }
            }}
        >
            <div className={sidePanelClassName} style={{
                width: getSidePanelWidth(),
                minWidth: getSidePanelWidth(),
                height: "100%",
                ...(sidePanelMinimized ? { transition: "width 0.16s ease-in-out" } : {})
            }}>
                { (typeof(sidePanelContent) == 'function') ? sidePanelContent(setSidePanelMinimized) : sidePanelContent }
            </div>
            <div style={{
                borderLeft: "1px solid #f0f0f0",
                height: "100%",
                cursor: "col-resize",
                zIndex: 100,
            }} onMouseDown={() => {
                setSidePanelResizerActive(true);
            }}
            />
            <div className={mainAreaClassName} style={{
                flexGrow: 100,
            }}>
                {mainAreaContent}
            </div>
        </div >
    )
}
