
import React, { useEffect, useState } from 'react';

import './ColoredTag.less';

import lightOrDark from 'bwax-ui/color/lightOrDark';
import { themeColor } from 'bwax-ui/defaultTheme'

import Tooltip from 'bwax-ui/components/Tooltip';

import useResizeObserver from '@react-hook/resize-observer'

export default function ColoredTag ({ text, color, style: givenStyle, textClamped = false, onClick }) {
    
    const c = themeColor(undefined, color);

    const isDark = lightOrDark(c) == "dark";

    const ref = React.useRef(null);

    const [ tipShown, setTipShown ] = useState(false);

    const style = {
        ...givenStyle,
        backgroundColor: c,
        color: isDark ? "#FFFFFF" : undefined,

        ...(textClamped ? {
            display: "-webkit-inline-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 1;
            overflow: "hidden",
        } : {})

    }

    const handleResize = el => {
        if (el) {
            if (el.scrollHeight > el.offsetHeight) {
                setTipShown(true)
            } else {
                // 不用改回去了。
            }
        }
    }

    useResizeObserver(ref, _ => {
        if(ref.current) {
            handleResize(ref.current);
        }
       
    });


    const inner = (
        <div style={style} className="colored-tag" ref={ref} onClick={onClick}>
            { text }
        </div>
    )

    return tipShown ? (
        <Tooltip text={text}>
            { inner }
        </Tooltip>
    ) : inner
}