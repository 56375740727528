import React, { useEffect, useState, useContext, memo } from 'react'

import WxMp_Text from './WxMp_Text'
import WxMp_Image from './WxMp_Image'
import WxMp_News from './WxMp_News'
import './Replies.less'

export default function Replies (props){

    const { replies, updateReplies, queryRunner, publishFault } = props

    function setReplieContent (type, content, idx) {
        replies.splice(idx, 1, {
            ...replies[idx],
            ...content,
            type,

        })
        updateReplies(replies)
    }

    function deleteReplies(idx) {
        replies.splice(idx, 1)
        updateReplies(replies)
    }



    return (
        <div className="wxmp-replies">
        {
            replies.filter(r => !!r).map((reply, idx) => {
                const hasFault = publishFault && 
                    (reply.type === 'text' || reply.type === 'mpImage' || reply.type === 'mpNews') &&
                    !reply[reply.type] ? true : false;

                return (
                    <React.Fragment key={idx}>
                        <div style={hasFault ? {border: '1px solid #f00'} : null}>
                        {
                            reply.type === 'text' ? (
                                <WxMp_Text 
                                    text={reply.text} 
                                    textInput={text => setReplieContent('text', { text }, idx)}
                                    deleteReplies={() => deleteReplies(idx)}
                                />
                            ) : reply.type === 'mpImage' ? (
                                <WxMp_Image 
                                    mpImage={reply.mpImage}
                                    mpImageInput={mpImage => setReplieContent('mpImage', { mpImage }, idx)}
                                    deleteReplies={() => deleteReplies(idx)}
                                    queryRunner={queryRunner}
                                />
                            ) : reply.type === 'mpNews' ? (
                                <WxMp_News
                                    mpNews={reply.mpNews}
                                    newsInput={mpNews => setReplieContent('mpNews', { mpNews }, idx)}
                                    deleteReplies={() => deleteReplies(idx)}
                                    queryRunner={queryRunner}
                                />
                            ) : null
                        }
                        {
                            hasFault ? (
                                <p style={{ color: "#f00" }}>请设置此回复内容</p>
                            ) : null
                        }
                        </div>
                    </React.Fragment>
                )
            })
        }
        {
            publishFault && replies.length === 0 ? <p style={{ color: "#f00", paddingTop: 10 }}>请设置回复内容</p> : null
        }
        </div>
    )
}
