
import React, { useEffect, useRef } from 'react'

import './StylesEditor.less';

import { EditorState, EditorSelection } from "@codemirror/state";
import { EditorView, dropCursor, hoverTooltip } from "@codemirror/view";

import { css, cssLanguage } from '@codemirror/lang-css'

import { keymap, highlightActiveLine, rectangularSelection, lineNumbers, highlightActiveLineGutter } from "@codemirror/view";

import {
    foldGutter, foldKeymap, codeFolding, Language,
} from "@codemirror/language"


import { linter, lintKeymap } from "@codemirror/lint"

import { lightStyle } from 'Client/js/codeEditor/languageSupports';

import { search, searchKeymap, highlightSelectionMatches } from "@codemirror/search"

import { defaultKeymap, indentWithTab, history, historyKeymap } from "@codemirror/commands";

const lineSeparator = "\n";

export default function StylesEditor({ value, onChange }) {

    const editorRef = useRef();
    const viewRef = useRef();

    // const valueRef = useRef();
    // valueRef.c
    useEffect(() => {
        const state = EditorState.create({
            // doc: value || storedItem || "",
            // doc: storedItem || "",
            doc: value ? value.manual: "",

            extensions: [

                keymap.of(searchKeymap),
                keymap.of(defaultKeymap),
                keymap.of([indentWithTab]),
                keymap.of(foldKeymap),
                keymap.of(historyKeymap),
                keymap.of(lintKeymap),

                lineNumbers(),
                highlightActiveLine(),
                highlightActiveLineGutter(),
                foldGutter(),

                codeFolding(),
                history(),
                highlightSelectionMatches(),
                EditorState.lineSeparator.of(lineSeparator),
                rectangularSelection(),

                css().extension,
                cssLanguage.extension,

                linter(view => {

                    const editorState = view.state;
                    const codeText = editorState.doc.toJSON().join(lineSeparator);
                    // if(testing) localStorage.setItem(storedItemKey, codeText);

                    const languageState = editorState.field(Language.state);
                    const tree = languageState.tree;

                    // 判断 tree 是不是已经 parse 完了：
                    if (codeText.length === languageState.context.treeLen) {

                        if(codeText === "" && !value) {
                            // do nothing
                        } else {
                            onChange({
                                ...(value || {}),
                                manual: codeText
                            });
                        }

                    }

                    return []


                }, { delay: 700 }),

                // syntaxHighlighting(HighlightStyle.define([]))
                lightStyle,

            ]
        });

        const view = new EditorView({ state, parent: editorRef.current });
        viewRef.current = view;
        // setEditorState(state);

        return () => {
            view.destroy();
            // editorRef.current.removeEventListener("input", log);
        };

    }, [])

    return (
        <div className="studio-styles-editor">
            <div className="code-mirror-editor" ref={editorRef}></div>
        </div>
    )
}
