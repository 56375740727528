
import React, { useEffect, useState } from 'react';


import AdminExt from 'bwax-ext/AdminExt'

import Number from 'Client/js/ui/widgets/display/Number'
import Boolean from 'Client/js/ui/widgets/display/Boolean'

import DateTime from 'Client/js/ui/widgets/display/DateTime'

import ColorDisplay from 'Client/js/ui/widgets/display/ColorDisplay'


import PicturesWall from 'Client/js/ui/widgets/display/PicturesWall'  // 这一行导致什么 overArgs 出错
                                                                      // 后面它有导致过打包后的 antlr4 出错（本质上是打包出错）
                                                                      // 我把里面的实现改成 Lazy


import RichTextPreview from 'Client/js/ui/widgets/display/RichTextPreview'
import AttachmentsPreview from 'Client/js/ui/widgets/display/AttachmentsPreview'

import Select from 'Client/js/ui/widgets/display/Select'
import Plain from 'Client/js/ui/widgets/display/Plain'

import ShortText from 'Client/js/ui/widgets/display/ShortText';
import TextDisplay from 'Client/js/ui/widgets/display/TextDisplay';


import AvatarAndLabel from 'Client/js/ui/widgets/AvatarAndLabel'

import { make as Display_JSON } from 'Client/re/widgets/display/Display_JSON.bs';

import { make as Display_QRCode } from 'Client/re/widgets/display/Display_QRCode.bs';

import { make as Display_FilterCondition } from 'Client/re/widgets/display/Display_FilterCondition.bs';
import FieldOptions from 'Client/js/ui/widgets/display/FieldOptions';

import { CrontabSamples } from 'Client/js/components/CrontabSamples';

import WxAttachmentDisplay from 'Client/js/ui/components/WxAttachmentDisplay'


// import RolePermissionDisplay from 'Client/js/ui/widgets/display/RolePermissionDisplay'
const Display_RolePermission = require('Client/re/widgets/display/Display_RolePermission.bs').make;


const Display_PosterSetting = require('Client/re/widgets/display/Display_PosterSetting.bs').make


import { make as ElementRenderer} from "bwax-ui/ml/widget/ElementRenderer.bs";

function asPath(refName, field, suffix) {
    let joined = [refName, field && field.name, suffix].filter(x => !!x).join(".");
    return `\${${joined}}`;

}

function forwardTo(Comp, specifiedParams) {
    return {
        component: ({ value, customParams, field, env }) => {
            const params = {
                value,
                ...(specifiedParams || {}),
                ...customParams,
                fieldOptions: field.options
            }
            return <Comp params={params} {...env} />
        }
    }
}

const displayDateTime = defaultParams => {
    return  {
        component: ({ value, customParams, field, env }) => {
            const format = field.options && field.options.format;
            const params = {
                value,
                ...(defaultParams || {}),
                ...(format ? { format } : {}),
                ...customParams,
                
            }
            return <DateTime params={params} {...env} />
        }
    }
}


const predefined = {

    Number: {
        valueDef: (refName, field) => asPath(refName, field),   // it is by default
        component: ({ value, customParams, field, env }) => {
            const format = field.options && field.options.format;
            const params = {
                value,
                decimals: field.options ? field.options.decimals : undefined,
                ...(format ? { format } : {}),
                ...customParams
            };
            return <Number params={params} {...env} />
        }
    },

    Integer: {
        valueDef: (refName, field) => asPath(refName, field),   // it is by default
        component: ({ value, customParams, field, env }) => {
            const format = field.options && field.options.format;
            const params = {
                value,
                decimals: 0,
                ...(format ? { format } : {}),
                ...customParams
            };
            return <Number params={params} {...env} />
        }
    },    

    Boolean: forwardTo(Boolean),
    DateTime: displayDateTime({ format: "YYYY-MM-DD HH:mm" }),
    Date: displayDateTime({ format: "YYYY-MM-DD" }),

    ShortText: forwardTo(ShortText),
    Text: forwardTo(TextDisplay),

    // Image: forwardTo(PicturesWall),
    Image: {
        component: ({ value, customParams, field, env }) => {
            return (
                <PicturesWall
                    params={{
                        value,
                        ...customParams
                    }}
                />
            )
        }
    },

    RichText: forwardTo(RichTextPreview),
    File: forwardTo(AttachmentsPreview),
    JSON: forwardTo(Display_JSON),
    JSONB: forwardTo(Display_JSON),

    Color: forwardTo(ColorDisplay),

    Select: {
        component: ({ value, customParams, field, env }) => {
            const params = {
                value,
                options: field.options ? field.options.options : [],
                colors: field.options ? field.options.colors : {},
                ...customParams
            };
            return <Select params={params} {...env} />
        }
    },


    // 
    FieldOptions: {
        valueDef: (refName, field) => {
            return {
                value: asPath(refName, field),   // it is by default,
                dataType: asPath(refName, null, "字段类型")
            }
        },
        component: ({ value, customParams, field, env }) => {
            return <FieldOptions params={{
                ...customParams,
                value: value.value,
                dataType: value.dataType,
            }} {...env} />
        }
    },


    QRCode: forwardTo(Display_QRCode),

    VirtualFieldDef: forwardTo(Display_JSON),
    EventHandlerDef: forwardTo(Display_JSON),

    FilterCondition: {
        component: ({ value, customParams, field, env }) => {
            const specifiedFields =
                field.options ? field.options.specifiedFields : null;

            const fieldItems = specifiedFields ? specifiedFields.reduce((acc, path) => {
                const split = path.split("as").map(s => s.trim());
                if (split.length == 2) {
                    const [path, name] = split;
                    return {
                        ...acc,
                        [name]: path
                    }
                } else if (split.length == 1) {
                    return {
                        ...acc,
                        [path]: path
                    }
                } else {
                    return acc
                };
            }, {}) : {};

            const params = {
                value,
                fieldItems,
                ...customParams
            }

            return <Display_FilterCondition params={params} {...env} />
        }
    },

    AuthTypeRolePermission: forwardTo(Display_RolePermission),


    Link: (() => {

        const valueDef = (refName, field, { allEntities }) => {
    
            const targetEntity = allEntities.find(e => e.name == field.options.entity || e.key == field.options.entity);
            const entityDisplay = entityDisplays[targetEntity.key];

            if(!targetEntity) {
                return {
                    id: asPath(refName, field, "id"),
                    text: asPath(refName, field, "id"),
                }
            }

            if (entityDisplay) {
                return entityDisplay.valueDef(refName, field, { allEntities });
            } else {

                const allFields = [...targetEntity.fields, ...targetEntity.virtualFields].sort((a, b) => {
                    // 给 field 排序
                    /// 根据 displayWeight 从大到下排列
                    const getWeight = f => f.displayWeight || 0
                    // 重的在前面
                    return getWeight(b) - getWeight(a)
                })

                const f = allFields.find(f => (f.type === 'ShortText' || f.type === "Text"))
                if (field.multivalued) {
                    return {
                        __apply__: {
                            list: asPath(refName, field),
                            map: {
                                id: asPath("value", null, "id"),
                                text: asPath("value", null, f ? f.name : "id")
                            }
                        }
                    }
                }
                return {
                    id: asPath(refName, field, "id"),
                    text: asPath(refName, field, f ? f.name : "id")
                }
            }
        };

        const fieldsToValue = (field, { allEntities, allDataTypes }) => {
            const targetEntity = allEntities.find(e => e.name == field.options.entity || e.key == field.options.entity);

            if(!targetEntity) {
                return {
                    id: "id",
                    text: "id"
                }
            }

            const entityDisplay = entityDisplays[targetEntity.key];
            if (entityDisplay && entityDisplay.fieldsToValue) {
                return entityDisplay.fieldsToValue(field, { allEntities, allDataTypes });
            } else {

                const allFields = [...targetEntity.fields, ...targetEntity.virtualFields].sort((a, b) => {
                    // 给 field 排序
                    /// 根据 displayWeight 从大到下排列
                    const getWeight = f => f.displayWeight || 0
                    // 重的在前面
                    return getWeight(b) - getWeight(a)
                })

                const f = allFields.find(f => (f.type === 'ShortText' || f.type === "Text"))
                if(!f) {
                    console.log("No text field", targetEntity.name);
                }
                return {
                    id: "id",
                    text: f ? f.name : "id"
                }
            }
        };

        const component = ({ value: givenValue, customParams, field, env }) => {
    

            const { sourceApp } = field.options


            const { allEntities, allDataTypes, bwax } = env;
            const targetEntity = allEntities.find(e => e.name == field.options.entity || e.key == field.options.entity);
            const entityDisplay = targetEntity && entityDisplays[targetEntity.key];

            const [ value, setValue ] = useState(givenValue);

            useEffect(() => {
                // 如果 givenValue 不够值，可以主动发出请求，获得足够的值：
                if(givenValue && givenValue.id && bwax && targetEntity) {

                    const neededValues = fieldsToValue(field, { allDataTypes, allEntities });
    
                    const missingValues = Object.keys(neededValues).reduce((acc, key) => {
                        if(givenValue[key] === undefined) {
                            return {
                                ...acc,
                                [key]: neededValues[key]
                            }
                        } else {
                            return acc
                        }
                    }, {});

                    if(Object.keys(missingValues).length > 0) {

                        (async function () {
                            const queryObj = {
                                entityName: targetEntity.name,
                                fieldPaths: Object.values(missingValues).filter(x => x !== "id")
                            };
                            const [ result, error ] = await bwax.findById(value.id, queryObj, {});
                            if(result) {
                                // transform and combine
                                const combinedValue = Object.keys(missingValues).reduce((acc, key) => {
                                    const name = missingValues[key];
                                    return {
                                        ...acc,
                                        [key]: result[name]
                                    }
                                }, givenValue)
                                setValue(combinedValue);
                            }
                        })();
                    } else {
                        // 
                        if(JSON.stringify(value) !== JSON.stringify(givenValue)) {
                            setValue(givenValue);
                        }
                    }

                } else {
                    setValue(givenValue);
                }

            }, [ JSON.stringify(givenValue) ]);

            if (entityDisplay) {
                const Comp = entityDisplay.component;
                return (
                    <Comp 
                        {...{
                            value, customParams, field, env 
                        }}
                    />
                )
            } else {
                const { withLink } = customParams;
                const addLink = v => {
                    if (!v || !targetEntity) {
                        return v
                    } else {
                        return {
                            ...v,
                            link: withLink == false ?
                                undefined :
                                `/entities/${targetEntity.key}/records/${v.id}`  // 如果是在 design 这个应该不一样的
                        }
                    }
                }

                const process = v => {
                    // 为了避免 { text: undefined, link: ... } 导致显示组件认为它是个 JSON
                    if(!v || v.text === undefined) {
                        return ""
                    } else {
                        return addLink(v)
                    }
                }

                const params = {
                    value: Array.isArray(value) ? value.map(process) : process(value),
                    ...customParams
                }


                // 增加的 sourceApp 是为了处理应用跳转的情况
                // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1063
                return <Plain params={params} {...env} sourceApp={sourceApp} />
            }
        }

        return {
            valueDef,
            fieldsToValue,
            component
        }
    })(),


    // WxWorkTypeMsgAttachment: {
    //     component: ({value, customParams, field, env}) => {
    //         return <WxWorkTypeMsgAttachment value={value} />
    //     }
    // },

    // WxMpTypeReply: {
    //     component: ({value}) => {
    //         return <WxMpTypeReply value={value}/>
    //     }
    // },

    WxWorkTypeMsgAttachment: {
        component: ({ value }) => {
            return <WxAttachmentDisplay value={value}/>
        }
    },

    WxMpTypeReply: {
        component: ({ value }) => {
            return <WxAttachmentDisplay value={value}/>
        }
    },


    PosterSetting: {
        valueDef: (refName, field, { allEntities }) => {
            return {
                id: "${id}",
                previewAvatar: "${当前用户.头像}",
                previewNickname: "${当前用户.昵称}",
                value: asPath(refName, field)
            }
        },
        component: ({ value, customParams, field, env }) => {
            return (
                <Display_PosterSetting params={value} />
            )
        }
    },


    // Ad Hoc type
    Crontab: {
        component: ({ value, customParams, field, env }) => {
            return (
                <div>
                    <div>{ value }</div>
                    <CrontabSamples value={value} />
                </div>
            )
        }
    },


    Element: {
        component: ({ value, customParams, field, env = {} }) => {

            const { onMsg } = env;

            return (
                <ElementRenderer
                    {...{
                        element: value,
                        onMsg: onMsg || (_ => {})
                    }}
                />
            )
        }
    }

}


export function getFieldDisplay(field) {

    const customDisplays = Object.keys(AdminExt).reduce((acc, key) => {
        const ext = AdminExt[key]
        if (ext && ext.dataDisplays) {
            return {
                ...acc,
                ...ext.dataDisplays
            }
        } else {
            return acc
        }

    }, {});

    const defaultDisplay = forwardTo(Plain);
    // const defaultDisplay = forwardTo(Display_JSON);

    const display = { ...predefined, ...customDisplays }[field.type] || defaultDisplay;

    return {
        valueDef: (refName, field) => asPath(refName, field),   // it is by default
        fieldsToValue: _ => ({}), // it is by default
        ...display
    }

}


// custom entity displays:
const entityDisplays = {
    AuthEntityUser: {
        valueDef: (refName, field) => {
            if (field.multivalued) {
                return {
                    __apply__: {
                        list: asPath(refName, field),
                        map: {
                            id: asPath("value", null, "id"),
                            nickName: asPath("value", null, "昵称"),
                            avatar: asPath("value", null, "头像"),
                            phoneNumber: asPath("value", null, "手机号用户.手机号")
                        }
                    }
                }
            }
            return {
                id: asPath(refName, field, "id"),
                nickName: asPath(refName, field, "昵称"),
                avatar: asPath(refName, field, "头像"),
                phoneNumber: asPath(refName, field, "手机号用户.手机号")
            }
        },
        fieldsToValue: _ => ({
            id: "id",
            nickName: "昵称",
            avatar: "头像",
            phoneNumber: "手机号用户.手机号"
        }),
        component: ({ value, customParams, field, env }) => {

            const { withLink } = customParams;

            const addLink = v => {
                if (!v) {
                    return v
                } else {
                    return {
                        ...v,
                        link: withLink == false ? undefined : `/entities/AuthEntityUser/records/${v.id}`
                    }
                }
            }

            const render = (v, index) => {
                if (!(v && v.id)) return null;

                const label = v.nickName || v.id.slice(-6)

                const params = {
                    ...(addLink(v)),
                    label,
                    ...customParams
                }

                return <AvatarAndLabel params={params} {...env} key={index} />
            }

            if (Array.isArray(value)) {
                return value.map(render);
            } else {
                return render(value, "no-key")
            }

        }
    }
}