

import React from 'react'

import 'PropertyEditor.less';

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays'
import FieldInput from './FieldInput';


export default function PropertyEditor(props) {

    const { entity, fieldNamesToRead, fieldNamesToWrite, facade, value, onChange } = props;

    const notExcluded = name => ["代码", "应用", "唯一标识"].indexOf(name) == -1;
    
    const allFields = [...entity.fields, entity.virtualFields].map(f => {
        const toRead = notExcluded(f.name) && fieldNamesToRead.some(name => {
            return name.split(".")[0] === f.name
        });
        const toWrite = notExcluded(f.name) && f.updatable && fieldNamesToWrite.some(name => {
            return name.split(".")[0] === f.name
        });
        return [f, toRead, toWrite]
    }).filter(([_, toRead, toWrite]) => toRead || toWrite);

    const allEntities = facade.entities;
    const allDataTypes = facade.dataTypes;
    function renderFieldValue(field, toWrite) {
        if (toWrite) {
            return (
                <FieldInput {...{ field, entity, facade, value, onChange }} />
            )

        } else {
            const { component: Component } = getFieldDisplay(field)

            const v = value[field.name];
            // todo entity link should be customize, click to open a new tab
            // 可能要重新定义一组 fieldDisplays

            return (
                <Component value={v} customParams={{}} field={field} env={{
                    allEntities, allDataTypes, bwax: facade
                }} />
            )
        }
    }

    const renderedFields = allFields.map(([field, _, toWrite]) => {

        return (
            <div className="property-section" key={field.name}>
                <div className="property-name">{field.name}</div>
                <div className="property-value">
                    {renderFieldValue(field, toWrite)}
                </div>
            </div>
        )

    });


    return (
        <div className="studio-property-editor" tabIndex={-1}>
            {renderedFields}
        </div>
    )
}

