

import React, { useState } from 'react';

import getImageURL from 'bwax-ui/getImageURL'

import './StudioTopBar.less';

import PublishButton from './PublishButton';

import { AiOutlineAppstore } from 'react-icons/ai';

import AppSelectorDrawer from '../topbar/AppSelectorDrawer';

import { Helmet } from 'react-helmet-async';

export default function StudioTopBar (props) {

    const { currentUser, currentApplication, applications, switchApplication } = props;

    const rawLogoURL = "https://bw-prod.oss-cn-shenzhen.aliyuncs.com/public/tikicrowd_logo.png";
    // const rawLogoURL =  "https://bw-dti-public.oss-cn-shenzhen.aliyuncs.com/dti-bwax/design-resources/34f0102e-227b-41dc-999a-7535153c4039.png";
    const logoURL = getImageURL(rawLogoURL, "small");

    const [ appSelectorVisible, setAppSelectorVisible ] = useState(false);

    return (
        <div className="studio-top-bar">
            <Helmet>
                <link rel="shortcut icon" type_="image/png" href={logoURL} />
            </Helmet>
            <div className="item-group">
                <div className="top-bar-button" onClick={() => {
                    setAppSelectorVisible(prev => !prev);
                }}>
                    <AiOutlineAppstore />
                </div>
                <div className="logo-and-name">
                    <img className="studio-logo" src={rawLogoURL}  style={{
                        // backgroundImage: `url(${logoURL})`
                    }}>
                    </img>
                    <div style={{ margin: "0 0 0 0.5rem"}}>
                        LeanCode Studio
                    </div>
                    {
                        currentApplication && currentApplication.name !== "默认应用" ? 
                            <>
                                <div style={{ margin: "0 0.375rem"}}>|</div>
                                <div title="HELLO">{ currentApplication.name }</div>
                            </> : 
                            null
                    }

                </div>
            </div>
            <div className="item-group">
                <PublishButton className="top-bar-button" style={{
                    color: "lightseagreen"
                }} />
            </div>
            <AppSelectorDrawer
                {...{
                    logo: logoURL,
                    siteName: "LeanCode Studio",
                    visible: appSelectorVisible,
                    onClose: () => {
                        setAppSelectorVisible(false)
                    },
                    applications: applications,
                    currentApplication: currentApplication,
                    onApplicationSelected: app => {
                        
                        switchApplication(app)

                    },
                    appSelectorItems:  [
                        {
                            label: "管理应用",
                            onClick: () => {
                                console.log("Manage Applications")
                            }
                        }
                    ],
                }}
            />
        </div>
    )
}