import React from 'react'

import WxInput from './WxInput';


export default function WxMpTypeReplyInput(props) {
    const attachmentTypes = [
        { label: "文本", value: "text" },
        { label: "图片", value: "image" },
        { label: "链接", value: "news" },
        // { label: "视频", value: "videoId" }
    ]

    return (
        <WxInput {...props} attachmentTypes={attachmentTypes} maxCount={3}></WxInput>
    )
}