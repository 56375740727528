
import isEqual from "lodash/isEqual";

import { notification } from 'antd';

import BackendItemStrategy from "./BackendItemStrategy";
import get from "lodash/get";

export default class VirtualFieldStrategy extends BackendItemStrategy  {

    constructor() {
        super();
        this.noMaterializationTipShown = false
    }

    deriveChangesFromPreviewData (value, codeFieldName, previewData) {
        
        const oldPreviewData = value[codeFieldName] ? value[codeFieldName].previewData : undefined;

        if (!isEqual(previewData, oldPreviewData)) {
            function handleMetaTypeChange() {
                function getReturnTypeMetas(pd) {
                    return pd && pd.ioTypeMetas ? pd.ioTypeMetas.viewReturnTypeMetas : undefined
                }
                const oldReturnTypeMetas = getReturnTypeMetas(oldPreviewData);
                const newReturnTypeMetas = getReturnTypeMetas(previewData);

                const typeOptions = value["字段选项"] ? value["字段选项"] : {};
                const typeName = value["字段类型"];

                if (!isEqual(oldReturnTypeMetas, newReturnTypeMetas) && newReturnTypeMetas && newReturnTypeMetas.length > 0) {
                    const meta = newReturnTypeMetas[0];
                    function processType() {
                        if (meta.type == "Text") {
                            // 只要 typeName 是 ShortText, Text, Select 就不用更改;
                            if (typeName == "ShortText" || typeName == "Text" || typeName == "Select") {
                                return {}
                            } else {
                                return { "字段类型": "ShortText" }
                            }
                        } else if (meta.type == "DateTime") {
                           if(typeName == "Date" || typeName == "DateTime") {
                                // 不用改
                                return {}
                           } else {
                                return { "字段类型": meta.type };
                           }
                            
                        } else if (meta.type.startsWith("Entity_")) {
                            const entityName = meta.type.replace("Entity_", "");
                            if (typeName == "Link") {
                                return {
                                    "字段选项": { ...meta.options, "entity": entityName }
                                }
                            } else {
                                return {
                                    "字段类型": "Link",
                                    "字段选项": { ...meta.options, "entity": entityName }
                                }
                            }
                        } else {
                            // 如果类型相等，就 merge options，否则，直接取代
                            console.log(">>> type name", typeName, meta.type, typeOptions, meta.options);
                            if (typeName == meta.type) {
                                if(typeName == "Link") {
                                    return {
                                        "字段选项": { ...meta.options }
                                    }
                                } else {
                                    return {
                                        "字段选项": { ...typeOptions, ...meta.options }
                                    }
                                }

                            } else {
                                return { "字段类型": meta.type, "字段选项": meta.options }
                            }
                        }
                    }

                    return {
                        ...processType(),
                        "必填": meta.required,
                        "分页": meta.paged,
                        "多值": meta.multivalued,
                    }

                } else {
                    return {}
                }
            }
            function handleInvolvingDynamicData() {
                if (previewData.involvingDynamicData && value["缓存"]) {
                    if (!this.noMaterializationTipShown) {
                        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/642
                        notification.open({
                            message: "提示",
                            description: (
                                <div style={{ wordBreak: "break-all" }}>
                                    虚拟字段用到了动态数据（如当前用户，当前时间，伪随机数），现在保存后，字段将不再被缓存
                                </div>
                            ),
                            duration: 2
                        });
                        this.noMaterializationTipShown = true
                    };
                    return {
                        "缓存": false
                    }
                }
                return {};
            }
            return {
                ...handleInvolvingDynamicData(),
                ...handleMetaTypeChange()
            }
        } else {
            return {}
        }
    }

}