
import inlineStyle from './InlineStyle'

export {
  resolveStyles,
  applyCustomStyle,
  applyAllStyles,
  removeAllInlineStyles
} from './inlineStyleHelpers'

// export {resolveStyles, applyCustomStyle, applyAllStyles, removeAllInlineStyles}

export default inlineStyle
