import { mentionEntityType } from './mentionPluginHelper'

const findMentionEntities = trigger => (
    contentBlock,
    callback,
    contentState
) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === mentionEntityType
        );
    }, callback);
};

export default findMentionEntities;
