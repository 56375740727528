import React from 'react'

export const HistoryStackContext = React.createContext()

export const HistoryStackProvider = props => {

    const { historyStack, children } = props

    return (
        <HistoryStackContext.Provider value={historyStack}>
            { children }
        </HistoryStackContext.Provider>
    )
}
