

import React, { useState, useRef, useEffect, useContext } from 'react'

import './FieldManagement.less';

import Modal from 'Client/ml/ui/components/Modal';
import Button from 'Client/ml/ui/components/Button';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import { Table, Input } from '@arco-design/web-react';

import LightTable, { NEW_RECORD_PLACEHOLDER } from 'Client/js/components/LightTable';

import { IconDelete, IconDragDotVertical, IconPlus } from '@arco-design/web-react/icon';

import getInput from './getInput';

import getDisplay from './getDisplay';

import IconButton from 'Client/js/components/IconButton';


const NEW_RECORD_KEY_PREFIX = "__new_record__";

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}



export default function FieldManagement({ value, onChange, facade }) {

    const [editingCell, setEditingCell] = useState() // { recordKey, dataIndex };

    const lastClickedCell = useRef(); // { recordKey, dataIndex };

    const data = value;

    // add new record
    function addNewField(dataIndexToEdit = "name") {
        function makeNewName() {
            for(let i = 0; ; i++) {
                const nameToTry = "新字段" + (i == 0 ? "" : i);
                if(!data.find(r => r.name === nameToTry)) {
                    return nameToTry
                } 
            }
        }
        const name = makeNewName();
        const type = "ShortText";
        const key = NEW_RECORD_KEY_PREFIX + s4();
        const newField = {
            key, name, type
        }
        onChange([...data, newField]);
        setEditingCell({ recordKey: key, dataIndex: dataIndexToEdit })
        lastClickedCell.current = { recordKey: key, dataIndex: dataIndexToEdit };
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            type: "ShortText",
        },
        {
            title: '描述',
            dataIndex: 'desc',
            type: "Text",
        },
        {
            title: '字段类型',
            dataIndex: 'type',
            type: "FieldType"
        },
        // {
        //     title: 'zoidua',
        //     dataIndex: 'type',
        //     type: "FieldType"
        // },
        {
            title: '关键字段',
            dataIndex: 'isKeyField',
            type: "Bool",
        },
        {
            title: '必填',
            dataIndex: 'required',
            type: "Bool",
        },
        {
            title: '唯一',
            dataIndex: 'unique',
            type: "Bool",
        },
        {
            title: "多值",
            dataIndex: "multivalued",
            type: "Bool",
        },
        {
            title: "操作",
            dataIndex: "op",
            width: 64,
            fixed: "right",
            render: (_, record) => {
                if (record.key == NEW_RECORD_PLACEHOLDER) {
                    return null
                }
                return (
                    <div className="operation-box">
                        {/* <Button {...{
                            label: "删除",
                            // compact: true,
                            onClick: _ => {
                                console.log(">>> remove", record);
                            }
                            }} 
                        /> */}
                        <IconButton {...{
                            icon: <IconDelete />,
                            onClick: _ => {
                                // console.log(">>> delete")
                                const newData = data.filter(r => r.key !== record.key);
                                onChange(newData);
                            }
                        }} />
                    </div>
                )
            }
        }
    ].map(c => {
        const { type, typeOptions, dataIndex, render: givenRender, ...rest } = c
        const input = getInput({ type, typeOptions, facade });
        const display = getDisplay({ type, typeOptions, facade }) || (v => v);
        return {
            ...rest, dataIndex, type, typeOptions,
            render: (v, record, index) => {

                if (givenRender) {
                    return givenRender(v, record, index);
                }

                if (Input && editingCell && editingCell.dataIndex == dataIndex && editingCell.recordKey == record.key) {
                    const changeValue = nv => {
                        const newData = data.map(d => {
                            if (d.key == record.key) {
                                return { ...d, [dataIndex]: nv }
                            } else {
                                return d
                            }
                        })
                        onChange(newData);

                    }
                    const onEnd = _ => {
                        console.log(">>> On End");
                        setTimeout(() => {
                            if (lastClickedCell.current && lastClickedCell.current.dataIndex == dataIndex
                                && lastClickedCell.current.recordKey == record.key) {
                                setEditingCell();
                            }
                        }, 2);
                    }

                    return (
                        <div className="cell-value-input">
                            {
                                input({
                                    autoFocus: true,
                                    value: v, onChange: changeValue, onEnd
                                })
                            }
                        </div>
                    )
                }
                return (
                    <div className="cell-value-display" onClick={_ => {
                        if(record.key === NEW_RECORD_PLACEHOLDER) {
                            // add a new record, and the edit the new record's current field.
                            addNewField(dataIndex)

                        } else {
                            lastClickedCell.current = { dataIndex, recordKey: record.key };
                            setEditingCell(lastClickedCell.current)
                        }
                        
                    }}>
                        {display(v, record, index)}
                    </div>
                )
            }
        }
    });

    function onSortEnd({ oldIndex, newIndex }) {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter((el) => !!el);
            onChange(newData);
        }
    }


    return (
        <div className="lc-form-field-management">
            <LightTable
                className='drag-table-001'
                columns={columns}
                data={data}
                options={{
                    dragRecordToSort: true,
                    onDragRecordEnd: onSortEnd,

                    newRecordLine: {
                        enabled: true,
                        addNewRecord: addNewField,
                    }
                }}
            />
        </div>
    );

}

// helpers
const arrayMoveMutate = (array, from, to) => {
    const startIndex = to < 0 ? array.length + to : to;
    if (startIndex >= 0 && startIndex < array.length) {
        const item = array.splice(from, 1)[0];
        array.splice(startIndex, 0, item);
    }
};

const arrayMove = (array, from, to) => {
    array = [...array];
    arrayMoveMutate(array, from, to);
    return array;
};








export function FieldManagementModal(props) {

    // 只修改“字段”字段
    const { form, visible, setVisible, facade, onFormUpdated } = props;

    const allKeys = ["字段"];

    const initialValues = {
        ["字段"]: form.字段
    }

    const fixedValues = {};

    // --- 初始化 Editing
    const {
        // errors,
        validated,
        editing,
        // lastSaved,
        dirtyValues,
        updateEditing,
        // markSaved,
        rollback,
        // clearEditing,
        reset
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, {}, [], "no-key", false)

    const involvedEditKeys = allKeys;

    const hasDirtyValue = involvedEditKeys.some(key => {
        return dirtyValues[key] !== undefined
    });

    const cancelButton = {
        key: "cancel",
        label: (hasDirtyValue ? "撤销" : "关闭"),
        onClick: _ => {
            if (hasDirtyValue) {
                rollback(involvedEditKeys);
            } else {
                setVisible(false);
            }
        }
    };

    const confirmButton = {
        key: "confirm",
        label: "保存",
        buttonType: "primary",
        disabled: !hasDirtyValue,
        onClick: async _ => {
            if (hasDirtyValue) {
                const formData = {
                    ["字段"]: (dirtyValues["字段"] || []).map(f => {
                        const { key, ...props } = f;
                        if(key.startsWith(NEW_RECORD_KEY_PREFIX)) {
                            return props
                        } else {
                            return f
                        }
                    })
                };

                const mutationObj = {
                    entityName: "表单",
                    formData,
                    id: form.id,
                    fieldPaths: []
                }

                const [result, error] = await facade.update(mutationObj, {
                });
                if (onFormUpdated) {
                    onFormUpdated({
                        id: form.id,
                        ...dirtyValues
                    })
                }
                setVisible(false);
            }
        }
    }

    const footer = [cancelButton, confirmButton].map(params => <Button {...params} />)

    return (
        <Modal visible={visible} title="编辑表单字段" footer={footer} destroyOnClose={true}
            size={"wide"}
            onCancel={() => {
                // 如果 dirty 的话，需要提示：
                if (hasDirtyValue) {
                    Modal.confirm({
                        title: '您有未保存的内容',
                        content: '确定要关闭吗？',
                        okText: '关闭',
                        cancelText: '等等',
                        className: "admin--edit-modal-confirm",
                        onOk: () => {
                            rollback(involvedEditKeys);
                            setVisible(false);
                        }
                    });
                } else {
                    setVisible(false)
                }
            }}>
            <div className="lc-form-field-management-modal">
                <FieldManagement {...{
                    value: editing["字段"],
                    onChange: v => {
                        updateEditing({ "字段": v })
                    },
                    facade
                }} />
            </div>
        </Modal>
    )

}






