
import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

// block type: 
// - admin-record-table
// - admin-record-card
// - query-config

export default function genCodeBlock(blockType, target, targetCodeName, currentIndents) {

    const generators = {
        codeType_admin_record_table,
        codeType_admin_record_card,
        codeType_entity_update_case_for_got_one,
        codeType_entity_update_case_for_got_list,

        codeType_entity_query_list,
        codeType_entity_query_find_one,
        codeType_entity_query_add,
        codeType_entity_query_update,
        codeType_entity_query_delete,
    }


    const generatorName = Object.keys(generators).find(name => name === "codeType_" + (blockType.replace(/\-/g, "_")));

    return (generators[generatorName] || (_ => target.name))(target, targetCodeName,  currentIndents);

}


// entity query related:

function codeType_entity_update_case_for_got_one(_, targetCodeName) {
    return `| GotOne_${targetCodeName} ${targetCodeName} ->
    ({ model | ${targetCodeName}}, none)`
}


function codeType_entity_update_case_for_got_list(_, targetCodeName) {
    return `| GotList_${targetCodeName} ${targetCodeName}_list ->
    ({ model | ${targetCodeName}_list }, none)`
}

function codeType_entity_query_list(_, targetCodeName) {
    return `Query_${targetCodeName}.list 
    { criteria = And {}
    , sorting = { 创建时间 = DESC }
    --, pageSize = 20
    }
    GotList_${targetCodeName}
    GotError`
}


function codeType_entity_query_find_one(_, targetCodeName) {
    return `Query_${targetCodeName}.findOne
    { criteria = And { id = { eq = id } }
    --, sorting = { 创建时间 = DESC }
    --, pageSize = 20
    }
    GotOne_${targetCodeName}
    GotError`
}

function codeType_entity_query_add(target, targetCodeName) {

    const fieldsToAdd = target.fields.filter(f => f.initializable).map(f => {
        const fieldName = lang_entry_slim.normalize_name(f.name);
        return `${fieldName} = _`
    }).join("\n    , ");

    return `Query_${targetCodeName}.add 
    { ${fieldsToAdd}
    }
    Created_${targetCodeName}
    GotError`    

}

function codeType_entity_query_update(target, targetCodeName) {

    const fieldsToUpdate = target.fields.filter(f => f.updatable).map(f => {
        const fieldName = lang_entry_slim.normalize_name(f.name);
        return `${fieldName} = _`
    }).join("\n    , ");

    return `Query_${targetCodeName}.update
    { ${fieldsToUpdate}
    }
    recordId
    Updated_${targetCodeName}
    GotError`    

}


function codeType_entity_query_delete(target, targetCodeName) {

    return `Query_${targetCodeName}.delete
    recordId
    Deleted_${targetCodeName}
    GotError`    

}



// admin-record-table
function codeType_admin_record_table(entity) {

    const entityName = entity.name;
    const nname = lang_entry_slim.normalize_name(entityName);

    const fieldsToDisplay = [...entity.fields, ...entity.virtualFields].filter(f => 
        [
            // "创建时间", 
            "创建者", "修改时间", "修改者"
        ].indexOf(f.name) === -1
    ).sort((a, b) => {
        // 给 field 排序, 根据 displayWeight 从大到下排列
        const getWeight = f => f.displayWeight || 0
        return getWeight(b) - getWeight(a)
    });


    const fieldColumns = fieldsToDisplay.map(f => {
        return `{ name = "${f.name}" }`;
    }).join("\n            , ");


    return `
      recordTable
        ( Query_${nname}.config 
            { criteria = And {}
            , sorting = { 创建时间 = DESC }
            } 
        )
        { title = "${entityName + "列表"}"
        , id = "${entityName + "列表"}"
        -- , pageSizeOptions = [ 20, 50 ]

        , fieldColumns = 
            ~[${fieldColumns}
            ]

        , operations = 
            ~[{ label = "查看", op = GotoDetail {} }]

        , topRightButtons = 
            ~[{ label = "新增", buttonType = Primary
              , action = AddRecordAction { }
              }
            ]
        }
    `

}

// admin-record-card
function codeType_admin_record_card(entity) {

    const entityName = entity.name;
    const nname = lang_entry_slim.normalize_name(entityName);

    const fieldsToDisplay = [...entity.fields, ...entity.virtualFields].filter(f => 
        [
            // "创建时间", 
            "创建者", "修改时间", "修改者"
        ].indexOf(f.name) === -1
    ).sort((a, b) => {
        // 给 field 排序, 根据 displayWeight 从大到下排列
        const getWeight = f => f.displayWeight || 0
        return getWeight(b) - getWeight(a)
    });


    const items = fieldsToDisplay.map(f => {
        return `{ name = "${f.name}" }`;
    }).join("\n              , ");


    return `
      recordCard 
        ( Query_${nname}.config { criteria = And { id = { eq = model.record.id } } } )
        { title = "基本信息"
        -- , description = ""        
        , editInPlace = True
        -- , noLabel = False


        , itemLists =
            [ ~[${items}
              ]
            ]
        -- , operations = 
        --     ~[{ label = "发布", buttonType = Primary
        --       , op = ...
        --       , disabled = ...
        --       , applicable = ...
        --       }
        --     ]
        }
    `

}



