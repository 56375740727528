import AntdButton from './AntdButton'
import Button from './Button'
import Checkbox from './Checkbox'
import Icon from './Icon'
import Input from './Input'
import message from './message'
import Modal from './Modal'
import Popconfirm from './Popconfirm'

import TextArea from './TextArea'

import Popover from 'bwax-ui/components/legacy/Popover';
import Tooltip from 'bwax-ui/components/Tooltip';

import Upload from './Upload'

export {
  AntdButton,
  Button,
  Checkbox,
  Icon,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  TextArea,
  Tooltip,
  Upload
}
