
import React, { useState, useRef, useEffect } from 'react'

import useOutsideClickAware from 'Client/js/topbar/useOutsideClickAware';

import { BiChevronDown } from 'react-icons/bi';

import "./ToolbarButton.less";

export default function ToolbarButton({ 
    icon, active = false, pressableWhenActive = false,
    disabled = false, panel, onPress, pressToShowMore = false, panelCloser
}) {

    const [panelVisible, setPanelVisible] = useState(false);

    const panelRef = useRef(null);
    useOutsideClickAware(panelRef, () => {
        setPanelVisible(false);
    })


    useEffect(() => {
        if(panelCloser) {
            panelCloser(() => {
                setPanelVisible(false);
            })
        }
    }, [ panelCloser ]);

    const withShowMoreButton = !!panel && !pressToShowMore;

    return (
        <div className="toolbar-button-wrapper">
            <div className={
                "icon-button" + (active ? " active" : "") + (disabled ? " disabled" : "") +
                (!disabled && (!active || pressableWhenActive) ? " pressable" : "") +
                (withShowMoreButton ? " with-show-more-button" : "")
            } onClick={() => {
                if (!disabled && (!active || pressableWhenActive)) {
                    if (pressToShowMore) {
                        setPanelVisible(true)
                    } else {
                        if(onPress) {
                            onPress();
                        }                       
                    }
                }
            }}>
                {icon}
            </div>
            {withShowMoreButton ? <div className={"show-more-button" + (disabled ? " disabled" : "")} onClick={_ => {
                if (!disabled && !active) {
                    setPanelVisible(prev => !prev)
                }
            }}><BiChevronDown /></div> : null}
            {panel ? (
                <div className={"button-panel" + (panelVisible ? " visible" : "")} ref={panelRef}>
                    {panel}
                </div>
            ) : null}
        </div>

    )
}
