
import React from 'react'

// buttons
import BoldButton from './BoldButton'
import ItalicButton from './ItalicButton'
import UnderlineButton from './UnderlineButton'
import ColorButton from './ColorButton'
import BgColorButton from './BgColorButton'
import SuperscriptButton from './SuperscriptButton'
import SubscriptButton from './SubscriptButton'

import TextAlignmentButton from './TextAlignmentButton'

import HyperlinkButton from './HyperlinkButton'
import FormatClearButton from './FormatClearButton'

import HeaderOneButton from './HeaderOneButton'
import HeaderTwoButton from './HeaderTwoButton'
import HeaderThreeButton from './HeaderThreeButton'
import HeaderFourButton from './HeaderFourButton'
import HeaderFiveButton from './HeaderFiveButton'

import BlockquoteButton from './BlockquoteButton'
import OrderedListButton from './OrderedListButton'
import UnorderedListButton from './UnorderedListButton'
import CodeButton from './CodeButton'

import ImageButton from './ImageButton'
import MediaButton from './MediaButton'

import FormatBrushButton from './FormatBrushButton'
import UndoButton from './UndoButton'
import RedoButton from './RedoButton'


import HRButton from './HRButton'

import Seperator from './Seperator'


const COMPONENTS = {
  'bold' : (props) => <BoldButton {...props} />,
  'italic' : (props) => <ItalicButton {...props} />,
  'underline' : (props) => <UnderlineButton {...props} />,
  'superscript' : (props) => <SuperscriptButton {...props}/>,
  'subscript' : (props) => <SubscriptButton {...props}/>,
  'color' : (props) => <ColorButton {...props} />,
  'bgcolor' : (props) => <BgColorButton {...props} />,
  'seperator' : (props) => <Seperator />,
  'align-left' : (props) => <TextAlignmentButton alignment={"left"} iconClass={"fa fa-align-left"} {...props} />,
  'align-center' : (props) => <TextAlignmentButton alignment={"center"} iconClass={"fa fa-align-center"} {...props} />,
  'align-right' : (props) => <TextAlignmentButton alignment={"right"} iconClass={"fa fa-align-right"} {...props} />,
  'align-justify' : (props) => <TextAlignmentButton alignment={"justify"} iconClass={"fa fa-align-justify"} {...props} />,
  'format-clear' : (props) => <FormatClearButton {...props} />,
  'hyperlink' : (props) => <HyperlinkButton {...props} />,

  'image': (props) => <ImageButton {...props} />,

  //兼容以前的类型'video', 同样使用MediaButton
  'video': (props) => <MediaButton {...props} />,
  'media': (props) => <MediaButton {...props} />,

  'h1': (props) => <HeaderOneButton {...props} />,
  'h2': (props) => <HeaderTwoButton {...props} />,
  'h3': (props) => <HeaderThreeButton {...props} />,
  'h4': (props) => <HeaderFourButton {...props} />,
  'h5': (props) => <HeaderFiveButton {...props} />,

  'blockquote': (props) => <BlockquoteButton {...props} />,
  'ordered-list': (props) => <OrderedListButton {...props} />,
  'unordered-list': (props) => <UnorderedListButton {...props} />,
  'code-block': (props) => <CodeButton {...props} />,

  'format-brush': (props) => <FormatBrushButton {...props} />,
  'undo': (props) => <UndoButton {...props} />,
  'redo': (props) => <RedoButton {...props} />,

  'hr': (props) => <HRButton {...props} />
}


export {
  COMPONENTS
}
