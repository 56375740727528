
import React from 'react'
import { Radio, Button, Row } from 'antd'
const RadioGroup = Radio.Group;


import Replies from './Replies'
import YqylPromotion from './YqylPromotion'

export default function CommonReplyEdit (props) {

    const { updateReplySetting, currentReplySetting, showYqyl, contentType, changeContentType, setPublishFault, 
        queryRunner, publishFault = {}, addReply, widgetProps } = props

    const { queryTarget, facade } = widgetProps ? widgetProps : {}
    
    return (
        <>
            <Row type="flex">
                <label>转入客服</label>
                <RadioGroup 
                    onChange={e => updateReplySetting({ transferCustomerService: e.target.value })} 
                    value={ currentReplySetting ? currentReplySetting.transferCustomerService : false }
                >
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </RadioGroup>
            </Row>
            <Row type="flex">
                <label>回复类型</label>
                <RadioGroup value={ contentType } onChange={e => changeContentType(e.target.value)}>
                    <Radio value='message'>回复消息</Radio>
                    {
                        showYqyl ? (
                            <Radio value="yqylPromotion">获取邀请有礼活动海报</Radio>
                        ) : null
                    }
                </RadioGroup>
            </Row>
            <Row type="flex">
                <label>回复内容</label>
                <div className="replies-box">
                    {
                        contentType === 'message' ? (
                            <>
                                <Replies 
                                    replies={currentReplySetting && currentReplySetting.replies ? currentReplySetting.replies : []} 
                                    updateReplies={replies => {
                                        setPublishFault({ ...publishFault, 'replies': null })
                                        updateReplySetting({ replies: replies })
                                    }}
                                    queryRunner={queryRunner}
                                    publishFault={!!publishFault['replies']}
                                />
                                <Button onClick={() => addReply('text')}>添加文本消息</Button>
                                <Button onClick={() => addReply('mpImage')}>添加图片消息</Button>
                                <Button onClick={() => addReply('mpNews')}>添加图文消息</Button>
                            </>
                        ) : (
                            <>
                                <YqylPromotion
                                    replies={currentReplySetting && currentReplySetting.replies ? currentReplySetting.replies : []}
                                    updateReplies={replies => {
                                        setPublishFault({ ...publishFault, 'replies': null })
                                        updateReplySetting({ replies: replies })
                                    }} 
                                    facade={facade}
                                    queryTarget={queryTarget}
                                    publishFault={!!publishFault['replies']}
                                />
                                <Button onClick={() => addReply('yqylPromotion')}>添加邀请有礼活动</Button>
                            </>
                        )
                    }
                    
                </div>
            </Row>
            <Row type="flex">
                <label/>
                <RadioGroup 
                    onChange={e => updateReplySetting({ replyType: e.target.value })} 
                    value={ currentReplySetting ? currentReplySetting.replyType : "ReplyAll" }
                >
                    <Radio value="ReplyAll">回复全部</Radio>
                    <Radio value="ReplyOne">随机回复一条</Radio>
                </RadioGroup>
            </Row>
        </>
    )
}
