

import { cssLanguage } from '@codemirror/lang-css'

import { pretty_print_css } from "./pretty_printer_css.bs";

import extractParseTree from './extractParseTree';

export default function prettyPrintCss (width, sourceCode) {
    const parseTree = extractParseTree(cssLanguage.parser.parse(sourceCode).topNode, sourceCode);

    return pretty_print_css(width, sourceCode, parseTree);
}