
// 使用 InputSelect 来实现 AutoComplete 

import React, { useEffect, useState } from 'react';

import ReactSelect from 'react-select'

import './InputSelect'; // input the less

// text, single value
export default function AutoComplete (props) {

    const { 
        data, value, onChange, placeholder = "", disabled, size, styles,
        
        autoFocus,
        onFocus, onBlur, onMenuScrollToBottom,
        isOptionDisabled, 
    } = props

    const [ currentInputValue, setCurrentInputValue ] = useState(value || "");

    

    useEffect(() => {
        // 22-01-12 添加 currentInputValue !== undefined 是为了 fix 
        // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/932
        if(value !== currentInputValue && currentInputValue !== undefined) {
            setCurrentInputValue(value || "");
        }
    }, [value])

    const onInputChange = (v, { action }) => {
        if(action == "input-change") {
            setCurrentInputValue(v);
            onChange(v);
        }
    }


    const options = [ value, ...(data.filter(d => d !== value))].filter(x => !!x).map(d => ({ value: d, label: d}));

    const processedValue = value === undefined || value === null ? null : ({value, label: value})

    // const filterOption = onSearch ? () => {
    //     // 让外部来处理 search
    //     return true
    // } : undefined;


    return (
        <ReactSelect className={"bw-select" + (size ? (" " + size) : "")} classNamePrefix="bw-select" {...{
            options, components: {
                ClearIndicator: _ => null,
                DropdownIndicator: _ => null,
                IndicatorSeparator: _ => null,
            }, // menuPlacement: "auto",

            isClearable: false, isSearchable: true, isMulti: false, 
            isDisabled: disabled, isLoading: false,

            inputValue: currentInputValue,
            value: processedValue, 
            
            onChange: v => {
                setCurrentInputValue(undefined);
                onChange(v.value);
            }, 
            
            autoFocus,

            onInputChange,

            onFocus, 

            onMenuOpen: () => {

                if(currentInputValue === undefined) {
                    setCurrentInputValue(value);
                }                
            },
            onBlur,
            onMenuScrollToBottom,

            isOptionDisabled, placeholder,

            menuShouldScrollIntoView: true,
            
            noOptionsMessage: _ => null,

            menuPortalTarget: (typeof(document) === "undefined" ? undefined : document.body),

            styles: { 
                ...styles, 
                menuPortal: (base) => ({ ...base, zIndex: 9999 })
            }, 
        }} />
    )
}