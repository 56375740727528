// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Dict$BwaxAdmin = require("../dict.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");
var Lang_typing_print$BwaxAdmin = require("./lang_typing_print.bs.js");
var Lang_typing_annotation$BwaxAdmin = require("./lang_typing_annotation.bs.js");
var Lang_typing_unification$BwaxAdmin = require("./lang_typing_unification.bs.js");
var Lang_typing_instantiation$BwaxAdmin = require("./lang_typing_instantiation.bs.js");

function subst_but(v, _param) {
  while(true) {
    var param = _param;
    if (param) {
      var subst = param[1];
      var match = param[0];
      var v1 = match[0];
      if (Caml_obj.caml_equal(v1, v)) {
        _param = subst;
        continue ;
      } else {
        return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    v1,
                    match[1]
                  ],
                  subst_but(v, subst)
                ]);
      }
    } else {
      return /* [] */0;
    }
  };
}

function subst_minus(_subst, _vars) {
  while(true) {
    var vars = _vars;
    var subst = _subst;
    if (vars) {
      _vars = vars[1];
      _subst = subst_but(vars[0], subst);
      continue ;
    } else {
      return subst;
    }
  };
}

function print_env(dts, tenv) {
  console.log(Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (param) {
                  return "  " + (param[0] + ("   -> \n                   " + (Lang_typing_print$BwaxAdmin.string_of_scheme(dts, param[1][0]) + "\n")));
                }), Dict$BwaxAdmin.$$String.to_list(tenv))));
  return /* () */0;
}

function type_literal(next_int, l) {
  var i = Curry._1(next_int, /* () */0);
  if (typeof l === "number") {
    return Lang_typing_base$BwaxAdmin.$$float;
  } else {
    switch (l.tag | 0) {
      case /* Int */0 :
          return Lang_typing_base$BwaxAdmin.number(i);
      case /* Float */1 :
          return Lang_typing_base$BwaxAdmin.$$float;
      case /* String */2 :
          return Lang_typing_base$BwaxAdmin.string;
      case /* Char */3 :
          return Lang_typing_base$BwaxAdmin.$$char;
      case /* Bool */4 :
          return Lang_typing_base$BwaxAdmin.bool;
      
    }
  }
}

var zero = /* record */Caml_chrome_debugger.record(["external_names"], [0]);

function with_external(name) {
  return /* record */Caml_chrome_debugger.record(["external_names"], [Caml_chrome_debugger.simpleVariant("::", [
                name,
                /* [] */0
              ])]);
}

function add(a, b) {
  return /* record */Caml_chrome_debugger.record(["external_names"], [Pervasives.$at(a[/* external_names */0], b[/* external_names */0])]);
}

function sum(stats) {
  return /* record */Caml_chrome_debugger.record(["external_names"], [Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map((function (s) {
                        return s[/* external_names */0];
                      }), stats))]);
}

var Stat = {
  zero: zero,
  with_external: with_external,
  add: add,
  sum: sum
};

function lookup_from_env(external_tenv, tenv, name) {
  var match = Dict$BwaxAdmin.$$String.get(name, tenv);
  if (match !== undefined) {
    var match$1 = match;
    return /* tuple */[
            match$1[0],
            match$1[2]
          ];
  } else {
    var match$2 = Dict$BwaxAdmin.$$String.get(name, external_tenv);
    if (match$2 !== undefined) {
      var match$3 = match$2;
      if (match$3[1]) {
        return /* tuple */[
                match$3[0],
                Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                        return name;
                      }), match$3[2])
              ];
      } else {
        return ;
      }
    } else {
      return ;
    }
  }
}

function is_tagger(k) {
  return Plate$BwaxAdmin.Str.decap(k) !== k;
}

function get_tagger_final_type_name(k, tagger, info) {
  var get_final_type_name = function (_t) {
    while(true) {
      var t = _t;
      if (typeof t === "number") {
        throw Lang_typing_base$BwaxAdmin.fail_typing(info, "Invalid type for tagger " + k);
      } else if (t.tag) {
        throw Lang_typing_base$BwaxAdmin.fail_typing(info, "Invalid type for tagger " + k);
      } else {
        var name = t[0];
        if (name === "Arrow") {
          var match = t[1];
          if (match) {
            var match$1 = match[1];
            if (match$1 && !match$1[1]) {
              _t = match$1[0];
              continue ;
            } else {
              return name;
            }
          } else {
            return name;
          }
        } else {
          return name;
        }
      }
    };
  };
  var full_tname = get_final_type_name(tagger[1]);
  return Plate$BwaxAdmin.$$Option.with_default(full_tname, Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.Str.split(".", full_tname))));
}

var empty_context = /* record */Caml_chrome_debugger.record([
    "applying_terms",
    "expected_term"
  ], [
    0,
    undefined
  ]);

function add_applying_term(t, typing_context) {
  return /* record */Caml_chrome_debugger.record([
            "applying_terms",
            "expected_term"
          ], [
            Caml_chrome_debugger.simpleVariant("::", [
                t,
                typing_context[/* applying_terms */0]
              ]),
            typing_context[/* expected_term */1]
          ]);
}

function set_expected_term(t, typing_context) {
  return /* record */Caml_chrome_debugger.record([
            "applying_terms",
            "expected_term"
          ], [
            typing_context[/* applying_terms */0],
            t
          ]);
}

function desugar_action_statments(info, stmts) {
  var _acc = Plate$BwaxAdmin.identity;
  var _param = stmts;
  while(true) {
    var param = _param;
    var acc = _acc;
    if (param) {
      var rest = param[1];
      var h = param[0];
      if (rest) {
        switch (h.tag | 0) {
          case /* Value_assignment */0 :
              var inf = h[2];
              var expr = h[1];
              var ptn = h[0];
              var new_acc = (function(acc,ptn,expr,inf){
              return function new_acc(next_acts) {
                return Curry._1(acc, /* tuple */[
                            /* Let */Caml_chrome_debugger.variant("Let", 14, [
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      /* Def_val */Caml_chrome_debugger.variant("Def_val", 0, [
                                          ptn,
                                          expr,
                                          undefined
                                        ]),
                                      inf
                                    ],
                                    /* [] */0
                                  ]),
                                next_acts
                              ]),
                            inf
                          ]);
              }
              }(acc,ptn,expr,inf));
              _param = rest;
              _acc = new_acc;
              continue ;
          case /* Value_extract */1 :
              var inf$1 = h[2];
              var expr$1 = h[1];
              var ptn$1 = h[0];
              var new_acc$1 = (function(acc,ptn$1,expr$1,inf$1){
              return function new_acc$1(next_acts) {
                var lambda = Lang_eval$BwaxAdmin.build_lambda(inf$1, ptn$1, next_acts);
                return Curry._1(acc, Lang_eval$BwaxAdmin.build_monad_bind(inf$1, expr$1, lambda));
              }
              }(acc,ptn$1,expr$1,inf$1));
              _param = rest;
              _acc = new_acc$1;
              continue ;
          case /* One_expr */2 :
              var expr$2 = h[0];
              var new_acc$2 = (function(acc,expr$2){
              return function new_acc$2(next_acts) {
                return Curry._1(acc, Lang_eval$BwaxAdmin.build_monad_next(Plate$BwaxAdmin.snd(expr$2), expr$2, next_acts));
              }
              }(acc,expr$2));
              _param = rest;
              _acc = new_acc$2;
              continue ;
          
        }
      } else {
        switch (h.tag | 0) {
          case /* Value_assignment */0 :
          case /* Value_extract */1 :
              return Lang_typing_base$BwaxAdmin.fail_typing(info, "The last statement in a 'do' block must be an expression");
          case /* One_expr */2 :
              return Curry._1(acc, h[0]);
          
        }
      }
    } else {
      return Lang_typing_base$BwaxAdmin.fail_typing(info, "'do' block should at least have one statement");
    }
  };
}

function type_expr(next_int, fixed_tenv, dts, maybe_module_name, typing_context, tenv, venv, expr) {
  var info = expr[1];
  var alt = expr[0];
  var type_it = function (param, param$1, param$2) {
    return type_expr(next_int, fixed_tenv, dts, maybe_module_name, empty_context, param, param$1, param$2);
  };
  var type_with_context = function (param, param$1, param$2, param$3) {
    return type_expr(next_int, fixed_tenv, dts, maybe_module_name, param, param$1, param$2, param$3);
  };
  var binop_type = function (next_int, op) {
    var i = Curry._1(next_int, /* () */0);
    if (typeof op === "number") {
      if (op === /* Cons */0) {
        var a = Lang_typing_base$BwaxAdmin.any(i);
        return /* tuple */[
                a,
                Lang_typing_base$BwaxAdmin.list(a),
                Lang_typing_base$BwaxAdmin.list(a)
              ];
      } else {
        var a$1 = Lang_typing_base$BwaxAdmin.appendable(i);
        return /* tuple */[
                a$1,
                a$1,
                a$1
              ];
      }
    } else {
      switch (op.tag | 0) {
        case /* Arith */0 :
            var a$2 = Lang_typing_base$BwaxAdmin.number(i);
            return /* tuple */[
                    a$2,
                    a$2,
                    a$2
                  ];
        case /* Equality */1 :
            var a$3 = Lang_typing_base$BwaxAdmin.any(i);
            return /* tuple */[
                    a$3,
                    a$3,
                    Lang_typing_base$BwaxAdmin.bool
                  ];
        case /* Compare */2 :
            var a$4 = Lang_typing_base$BwaxAdmin.comparable(i);
            return /* tuple */[
                    a$4,
                    a$4,
                    Lang_typing_base$BwaxAdmin.bool
                  ];
        case /* Logic */3 :
            return /* tuple */[
                    Lang_typing_base$BwaxAdmin.bool,
                    Lang_typing_base$BwaxAdmin.bool,
                    Lang_typing_base$BwaxAdmin.bool
                  ];
        case /* Custom */4 :
            return Lang_typing_base$BwaxAdmin.fail_typing(info, "not impl custom binop");
        
      }
    }
  };
  var getter_type = function (_tenv, fname) {
    var tr = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
    var ta = Lang_typing_base$BwaxAdmin.recordvar(Curry._1(next_int, /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              fname,
              tr
            ],
            /* [] */0
          ]));
    return /* tuple */[
            ta,
            tr
          ];
  };
  var type_binds = function (binds) {
    var iter = function (bind) {
      var match = type_it(tenv, venv, bind[1]);
      return /* tuple */[
              /* tuple */[
                bind[0],
                match[0]
              ],
              match[1]
            ];
    };
    var match = Plate$BwaxAdmin.List.unzip(Plate$BwaxAdmin.List.map(iter, binds));
    return /* tuple */[
            match[0],
            sum(match[1])
          ];
  };
  var get_prefix_for_term = function (t) {
    var match = Lang_typing_base$BwaxAdmin.shorten(t);
    if (typeof match === "number" || match.tag) {
      return ;
    } else {
      var tname = match[0];
      if (tname === "Arrow") {
        return ;
      } else {
        var match$1 = Plate$BwaxAdmin.Str.split(".", tname);
        var tmp;
        if (match$1) {
          var match$2 = match$1[1];
          if (match$2 && !match$2[1]) {
            var tn = match$2[0];
            tmp = match$1[0] === tn ? tn : tname;
          } else {
            tmp = tname;
          }
        } else {
          tmp = tname;
        }
        return tmp;
      }
    }
  };
  var auto_prefix_ref = function (possible_prefixes, name, info) {
    var match;
    if (Plate$BwaxAdmin.List.length(possible_prefixes) > 0) {
      var match$1 = lookup_from_env(fixed_tenv, tenv, name);
      var exit = 0;
      if (match$1 !== undefined && match$1[1] === undefined) {
        match = /* tuple */[
          name,
          false
        ];
      } else {
        exit = 1;
      }
      if (exit === 1) {
        var prefixed_names = Plate$BwaxAdmin.List.map((function (prefix) {
                return prefix + ("." + name);
              }), possible_prefixes);
        var match$2 = Plate$BwaxAdmin.List.find((function (n) {
                return lookup_from_env(fixed_tenv, tenv, n) !== undefined;
              }), prefixed_names);
        match = match$2 !== undefined ? /* tuple */[
            match$2,
            true
          ] : /* tuple */[
            name,
            false
          ];
      }
      
    } else {
      match = /* tuple */[
        name,
        false
      ];
    }
    var fname = match[0];
    var match$3 = lookup_from_env(fixed_tenv, tenv, fname);
    if (match$3 !== undefined) {
      var match$4 = match$3;
      var original_name = match$4[1];
      var gt = match$4[0];
      var t = Lang_typing_instantiation$BwaxAdmin.instance(next_int, gt);
      return /* tuple */[
              /* tuple */[
                /* Ref */Caml_chrome_debugger.variant("Ref", 16, [match[1] ? fname : name]),
                /* tuple */[
                  info,
                  t
                ]
              ],
              original_name !== undefined ? /* record */Caml_chrome_debugger.record(["external_names"], [Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          original_name,
                          gt
                        ],
                        /* [] */0
                      ])]) : zero
            ];
    } else {
      var msg;
      if (Plate$BwaxAdmin.List.length(possible_prefixes) === 0) {
        msg = "I can\'t find `" + (String(name) + "`.");
      } else {
        var possible_names = Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (prefix) {
                    return prefix + ("." + name);
                  }), possible_prefixes));
        msg = "I can\'t find `" + (String(name) + ("` or `" + (String(possible_names) + "`.")));
      }
      return Lang_typing_base$BwaxAdmin.fail_typing(info, msg);
    }
  };
  var typing_exprs_with_same_expected_term = function (expected_term, exprs_with_tenvs) {
    var first_round = function (_tried_exprs, _param) {
      while(true) {
        var param = _param;
        var tried_exprs = _tried_exprs;
        if (param) {
          var match = param[0];
          var h = match[1];
          var tenv = match[0];
          var tried_result;
          try {
            var match$1 = type_with_context(set_expected_term(expected_term, typing_context), tenv, venv, h);
            var typed_expr = match$1[0];
            var t = Lang_typing_annotation$BwaxAdmin.type_of(typed_expr);
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  t,
                  expected_term
                ]);
            tried_result = /* tuple */[
              typed_expr,
              match$1[1]
            ];
          }
          catch (raw_exn){
            var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
            if (exn[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
              tried_result = undefined;
            } else {
              throw exn;
            }
          }
          _param = param[1];
          _tried_exprs = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                /* tuple */[
                  tenv,
                  h
                ],
                tried_result
              ],
              tried_exprs
            ]);
          continue ;
        } else {
          return Plate$BwaxAdmin.List.rev(tried_exprs);
        }
      };
    };
    var tried_result = first_round(/* [] */0, exprs_with_tenvs);
    var _acc = /* [] */0;
    var _param = tried_result;
    while(true) {
      var param = _param;
      var acc = _acc;
      if (param) {
        var match = param[0];
        var match$1 = match[1];
        var match$2 = match[0];
        if (match$1 !== undefined) {
          _param = param[1];
          _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              match$1,
              acc
            ]);
          continue ;
        } else {
          var match$3 = type_with_context(set_expected_term(expected_term, typing_context), match$2[0], venv, match$2[1]);
          var typed_expr = match$3[0];
          var t = Lang_typing_annotation$BwaxAdmin.type_of(typed_expr);
          Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                t,
                expected_term
              ]);
          _param = param[1];
          _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                typed_expr,
                match$3[1]
              ],
              acc
            ]);
          continue ;
        }
      } else {
        return Plate$BwaxAdmin.List.rev(acc);
      }
    };
  };
  try {
    if (typeof alt === "number") {
      if (alt === /* Unit */0) {
        return /* tuple */[
                /* tuple */[
                  /* Unit */0,
                  /* tuple */[
                    info,
                    Lang_typing_base$BwaxAdmin.unit
                  ]
                ],
                zero
              ];
      } else {
        var t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
        return /* tuple */[
                /* tuple */[
                  /* External */1,
                  /* tuple */[
                    info,
                    t
                  ]
                ],
                zero
              ];
      }
    } else {
      switch (alt.tag | 0) {
        case /* Tuple */0 :
            var match = Plate$BwaxAdmin.List.unzip(Plate$BwaxAdmin.List.map((function (param) {
                        return type_it(tenv, venv, param);
                      }), alt[0]));
            var es = match[0];
            var final_t = Lang_typing_base$BwaxAdmin.tuple(Plate$BwaxAdmin.List.map(Lang_typing_annotation$BwaxAdmin.type_of, es));
            return /* tuple */[
                    /* tuple */[
                      /* Tuple */Caml_chrome_debugger.variant("Tuple", 0, [es]),
                      /* tuple */[
                        info,
                        final_t
                      ]
                    ],
                    sum(match[1])
                  ];
        case /* List */1 :
            var iter = function (param, e) {
              var maybe_t = param[0];
              var match = type_it(tenv, venv, e);
              var typed_e = match[0];
              var t = Lang_typing_annotation$BwaxAdmin.type_of(typed_e);
              var met_t;
              if (maybe_t !== undefined) {
                var t0 = maybe_t;
                Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                      t,
                      t0
                    ]);
                met_t = t0;
              } else {
                met_t = t;
              }
              var new_es = Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      typed_e,
                      /* [] */0
                    ]));
              var new_stat = add(param[2], match[1]);
              return /* tuple */[
                      met_t,
                      new_es,
                      new_stat
                    ];
            };
            var match$1 = Plate$BwaxAdmin.List.foldl(iter, /* tuple */[
                  undefined,
                  /* [] */0,
                  zero
                ], alt[0]);
            var maybe_t = match$1[0];
            var t$1 = maybe_t !== undefined ? maybe_t : Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
            return /* tuple */[
                    /* tuple */[
                      /* List */Caml_chrome_debugger.variant("List", 1, [match$1[1]]),
                      /* tuple */[
                        info,
                        Lang_typing_base$BwaxAdmin.list(t$1)
                      ]
                    ],
                    match$1[2]
                  ];
        case /* Record */2 :
            var binds = alt[0];
            Lang_typing_annotation$BwaxAdmin.ensure_no_duplicate(info, binds);
            var match$2 = type_binds(binds);
            var typed_binds = match$2[0];
            var final_t$1 = Lang_typing_base$BwaxAdmin.record(Plate$BwaxAdmin.List.map((function (param) {
                        return /* tuple */[
                                param[0],
                                param[1],
                                false
                              ];
                      }), Plate$BwaxAdmin.List.assoc_map(Lang_typing_annotation$BwaxAdmin.type_of, typed_binds)));
            var final_e_000 = /* Record */Caml_chrome_debugger.variant("Record", 2, [typed_binds]);
            var final_e_001 = /* tuple */[
              info,
              final_t$1
            ];
            var final_e = /* tuple */[
              final_e_000,
              final_e_001
            ];
            return /* tuple */[
                    final_e,
                    match$2[1]
                  ];
        case /* Record_upd */3 :
            var binds$1 = alt[1];
            var name = alt[0];
            Lang_typing_annotation$BwaxAdmin.ensure_no_duplicate(info, binds$1);
            var match$3 = lookup_from_env(fixed_tenv, tenv, name);
            if (match$3 !== undefined) {
              var match$4 = match$3;
              var original_name = match$4[1];
              var gt = match$4[0];
              var it = Lang_typing_instantiation$BwaxAdmin.instance(next_int, gt);
              var match$5 = type_binds(binds$1);
              var typed_binds$1 = match$5[0];
              var t$2 = Lang_typing_base$BwaxAdmin.recordvar(Curry._1(next_int, /* () */0), Plate$BwaxAdmin.List.assoc_map(Lang_typing_annotation$BwaxAdmin.type_of, typed_binds$1));
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                    it,
                    t$2
                  ]);
              return /* tuple */[
                      /* tuple */[
                        /* Record_upd */Caml_chrome_debugger.variant("Record_upd", 3, [
                            name,
                            typed_binds$1
                          ]),
                        /* tuple */[
                          info,
                          t$2
                        ]
                      ],
                      add(match$5[1], original_name !== undefined ? /* record */Caml_chrome_debugger.record(["external_names"], [Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      original_name,
                                      gt
                                    ],
                                    /* [] */0
                                  ])]) : zero)
                    ];
            } else {
              return Lang_typing_base$BwaxAdmin.fail_typing(info, "I can\'t find a `" + (String(name) + "` variable."));
            }
        case /* Record_get */4 :
            var name$1 = alt[1];
            var match$6 = getter_type(tenv, name$1);
            var match$7 = type_it(tenv, venv, alt[0]);
            var typed_e = match$7[0];
            var te = Lang_typing_annotation$BwaxAdmin.type_of(typed_e);
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  te,
                  match$6[0]
                ]);
            return /* tuple */[
                    /* tuple */[
                      /* Record_get */Caml_chrome_debugger.variant("Record_get", 4, [
                          typed_e,
                          name$1
                        ]),
                      /* tuple */[
                        info,
                        match$6[1]
                      ]
                    ],
                    match$7[1]
                  ];
        case /* Getter */5 :
            var name$2 = alt[0];
            var match$8 = getter_type(tenv, name$2);
            return /* tuple */[
                    /* tuple */[
                      /* Getter */Caml_chrome_debugger.variant("Getter", 5, [name$2]),
                      /* tuple */[
                        info,
                        Lang_typing_base$BwaxAdmin.arrow(match$8[0], match$8[1])
                      ]
                    ],
                    zero
                  ];
        case /* Constructor */6 :
            var name$3 = alt[0];
            var match$9 = lookup_from_env(fixed_tenv, tenv, name$3);
            if (match$9 !== undefined) {
              var match$10 = match$9;
              var original_name$1 = match$10[1];
              var gt$1 = match$10[0];
              return /* tuple */[
                      /* tuple */[
                        /* Constructor */Caml_chrome_debugger.variant("Constructor", 6, [name$3]),
                        /* tuple */[
                          info,
                          Lang_typing_instantiation$BwaxAdmin.instance(next_int, gt$1)
                        ]
                      ],
                      original_name$1 !== undefined ? /* record */Caml_chrome_debugger.record(["external_names"], [Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  original_name$1,
                                  gt$1
                                ],
                                /* [] */0
                              ])]) : zero
                    ];
            } else {
              return Lang_typing_base$BwaxAdmin.fail_typing(info, "I can\'t find a `" + (String(name$3) + "` variant. "));
            }
        case /* Apply */7 :
            var arg_e = alt[1];
            var fn_e = alt[0];
            var to_string = function (mt) {
              return Plate$BwaxAdmin.$$Option.map((function (param) {
                            return Lang_typing_print$BwaxAdmin.string_of_term(dts, param);
                          }), mt);
            };
            var unify_expected_func_type = function (maybe_expected_return_term, applying_terms, tf) {
              var match = Plate$BwaxAdmin.$$Option.map((function (t) {
                      return Lang_typing_base$BwaxAdmin.arrow(Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0)), t);
                    }), maybe_expected_return_term);
              if (match !== undefined) {
                Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                      tf,
                      match
                    ]);
              }
              if (Plate$BwaxAdmin.List.length(applying_terms) > 0) {
                var expected_func_term = Lang_typing_base$BwaxAdmin.arrow_chain(applying_terms, Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0)));
                return Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                            tf,
                            expected_func_term
                          ]);
              } else {
                return /* () */0;
              }
            };
            var type_function_with_terms = function (applying_terms, fn_e) {
              var final_return_term = Plate$BwaxAdmin.$$Option.map(Lang_typing_base$BwaxAdmin.get_final_return_type, typing_context[/* expected_term */1]);
              var match = fn_e[0];
              if (typeof match !== "number" && match.tag === /* Ref */16) {
                var name = match[0];
                var last_applying_term_prefix = Plate$BwaxAdmin.$$Option.and_then(get_prefix_for_term, Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.rev(applying_terms)));
                var expected_term_prefix = Plate$BwaxAdmin.$$Option.and_then(get_prefix_for_term, final_return_term);
                var return_type_prefix_of_flat_map_first_argument = name === "flatMap" || name === "andThen" ? Plate$BwaxAdmin.$$Option.and_then(get_prefix_for_term, Plate$BwaxAdmin.$$Option.and_then((function (t) {
                              var match = Lang_typing_base$BwaxAdmin.shorten(t);
                              if (typeof match === "number" || match.tag || match[0] !== "Arrow") {
                                return ;
                              } else {
                                var match$1 = match[1];
                                if (match$1) {
                                  var match$2 = match$1[1];
                                  if (match$2 && !match$2[1]) {
                                    return match$2[0];
                                  } else {
                                    return ;
                                  }
                                } else {
                                  return ;
                                }
                              }
                            }), Plate$BwaxAdmin.List.head(applying_terms))) : undefined;
                var prefix_candidates_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    last_applying_term_prefix,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        return_type_prefix_of_flat_map_first_argument,
                        /* [] */0
                      ])
                  ]);
                var prefix_candidates = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    expected_term_prefix,
                    prefix_candidates_001
                  ]);
                var possible_prefixes = Plate$BwaxAdmin.List.unique(Plate$BwaxAdmin.List.clean(prefix_candidates));
                return auto_prefix_ref(possible_prefixes, name, fn_e[1]);
              }
              var partial_arg = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
              return type_with_context(/* record */Caml_chrome_debugger.record([
                            "applying_terms",
                            "expected_term"
                          ], [
                            applying_terms,
                            Plate$BwaxAdmin.$$Option.map((function (param) {
                                    return Lang_typing_base$BwaxAdmin.arrow(partial_arg, param);
                                  }), typing_context[/* expected_term */1])
                          ]), tenv, venv, fn_e);
            };
            var is_meaningful_term = function (t) {
              var match = Lang_typing_base$BwaxAdmin.shorten(t);
              if (typeof match === "number" || match.tag !== /* Var */2) {
                return true;
              } else {
                var match$1 = match[0][/* var_type */0];
                if (typeof match$1 === "number") {
                  return match$1 !== 0;
                } else {
                  return true;
                }
              }
            };
            var try_fn_then_arg = function (param) {
              var existing_applying_terms;
              if (Plate$BwaxAdmin.List.length(typing_context[/* applying_terms */0]) > 0) {
                existing_applying_terms = typing_context[/* applying_terms */0];
              } else {
                var match = Plate$BwaxAdmin.$$Option.map(Lang_typing_base$BwaxAdmin.shorten, typing_context[/* expected_term */1]);
                if (match !== undefined) {
                  var match$1 = match;
                  if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "Arrow") {
                    existing_applying_terms = /* [] */0;
                  } else {
                    var match$2 = match$1[1];
                    if (match$2) {
                      var match$3 = match$2[1];
                      if (match$3 && !match$3[1]) {
                        var t = match$2[0];
                        existing_applying_terms = is_meaningful_term(t) ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              t,
                              /* [] */0
                            ]) : /* [] */0;
                      } else {
                        existing_applying_terms = /* [] */0;
                      }
                    } else {
                      existing_applying_terms = /* [] */0;
                    }
                  }
                } else {
                  existing_applying_terms = /* [] */0;
                }
              }
              var ta_placeholder = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
              var applying_terms = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  ta_placeholder,
                  existing_applying_terms
                ]);
              var match$4 = type_function_with_terms(applying_terms, fn_e);
              var typed_fn_e = match$4[0];
              var tf = Lang_typing_annotation$BwaxAdmin.type_of(typed_fn_e);
              unify_expected_func_type(typing_context[/* expected_term */1], applying_terms, tf);
              var alpha = Lang_typing_base$BwaxAdmin.var_of(/* Any */0, Curry._1(next_int, /* () */0));
              var match$5 = Lang_typing_base$BwaxAdmin.shorten(tf);
              var tmp;
              if (typeof match$5 === "number" || match$5.tag || match$5[0] !== "Arrow") {
                tmp = empty_context;
              } else {
                var match$6 = match$5[1];
                if (match$6) {
                  var match$7 = match$6[1];
                  tmp = match$7 && !match$7[1] ? set_expected_term(match$6[0], empty_context) : empty_context;
                } else {
                  tmp = empty_context;
                }
              }
              var match$8 = type_with_context(tmp, tenv, venv, arg_e);
              var typed_arg_e = match$8[0];
              var ta = Lang_typing_annotation$BwaxAdmin.type_of(typed_arg_e);
              var param_001 = Lang_typing_base$BwaxAdmin.arrow(ta, alpha);
              var param$1 = /* tuple */[
                tf,
                param_001
              ];
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, param$1);
              var alpha$1 = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
              var param_001$1 = Lang_typing_base$BwaxAdmin.arrow(ta, alpha$1);
              var param$2 = /* tuple */[
                tf,
                param_001$1
              ];
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, param$2);
              return /* tuple */[
                      /* tuple */[
                        /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                            typed_fn_e,
                            typed_arg_e
                          ]),
                        /* tuple */[
                          info,
                          alpha$1
                        ]
                      ],
                      add(match$4[1], match$8[1])
                    ];
            };
            var try_arg_and_then_fn = function (param) {
              var match = type_it(tenv, venv, arg_e);
              var typed_arg_e = match[0];
              var ta = Lang_typing_annotation$BwaxAdmin.type_of(typed_arg_e);
              var applying_terms_001 = typing_context[/* applying_terms */0];
              var applying_terms = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  ta,
                  applying_terms_001
                ]);
              var match$1 = type_function_with_terms(applying_terms, fn_e);
              var typed_fn_e = match$1[0];
              var tf = Lang_typing_annotation$BwaxAdmin.type_of(typed_fn_e);
              unify_expected_func_type(typing_context[/* expected_term */1], applying_terms, tf);
              var alpha = Lang_typing_base$BwaxAdmin.var_of(/* Any */0, Curry._1(next_int, /* () */0));
              var param_001 = Lang_typing_base$BwaxAdmin.arrow(ta, alpha);
              var param$1 = /* tuple */[
                tf,
                param_001
              ];
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, param$1);
              return /* tuple */[
                      /* tuple */[
                        /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                            typed_fn_e,
                            typed_arg_e
                          ]),
                        /* tuple */[
                          info,
                          alpha
                        ]
                      ],
                      add(match$1[1], match[1])
                    ];
            };
            var match$11 = Plate$BwaxAdmin.List.some(is_meaningful_term, typing_context[/* applying_terms */0]) || Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map(is_meaningful_term, typing_context[/* expected_term */1])) ? /* tuple */[
                try_fn_then_arg,
                try_arg_and_then_fn
              ] : /* tuple */[
                try_arg_and_then_fn,
                try_fn_then_arg
              ];
            try {
              return Curry._1(match$11[0], /* () */0);
            }
            catch (raw_e){
              var e = Caml_js_exceptions.internalToOCamlException(raw_e);
              if (e[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
                var depth = e[3];
                var inf = e[2];
                var msg = e[1];
                console.log("Type_exn", depth, msg, inf[/* text */1]);
                console.log("try back for:", info[/* text */1]);
                console.log("expecting", to_string(typing_context[/* expected_term */1]), Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (param) {
                                return Lang_typing_print$BwaxAdmin.string_of_term(dts, param);
                              }), typing_context[/* applying_terms */0])));
                if (depth > 3) {
                  throw e;
                }
                try {
                  return Curry._1(match$11[1], /* () */0);
                }
                catch (raw_exn){
                  var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                  if (exn[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
                    throw [
                          Lang_typing_base$BwaxAdmin.Type_exn,
                          msg,
                          inf,
                          depth + 1 | 0
                        ];
                  }
                  throw exn;
                }
              } else {
                throw e;
              }
            }
        case /* Unop */8 :
            var match$12 = type_it(tenv, venv, alt[1]);
            var typed_e$1 = match$12[0];
            var t$3 = Lang_typing_annotation$BwaxAdmin.type_of(typed_e$1);
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  t$3,
                  Lang_typing_base$BwaxAdmin.bool
                ]);
            return /* tuple */[
                    /* tuple */[
                      /* Unop */Caml_chrome_debugger.variant("Unop", 8, [
                          /* Not */0,
                          typed_e$1
                        ]),
                      /* tuple */[
                        info,
                        Lang_typing_base$BwaxAdmin.bool
                      ]
                    ],
                    match$12[1]
                  ];
        case /* Binop */9 :
            var e2 = alt[2];
            var e1 = alt[1];
            var bop = alt[0];
            var match$13 = binop_type(next_int, bop);
            var tr = match$13[2];
            var ta2 = match$13[1];
            var ta1 = match$13[0];
            var try_each = function (first, second) {
              var match = type_it(tenv, venv, first);
              var typed_first = match[0];
              var t_first = Lang_typing_annotation$BwaxAdmin.type_of(typed_first);
              var expected_term;
              if (typeof t_first === "number" || t_first.tag) {
                expected_term = undefined;
              } else {
                switch (t_first[0]) {
                  case "Bool" :
                  case "String" :
                      expected_term = t_first;
                      break;
                  default:
                    expected_term = undefined;
                }
              }
              var match$1 = type_with_context(/* record */Caml_chrome_debugger.record([
                      "applying_terms",
                      "expected_term"
                    ], [
                      0,
                      expected_term
                    ]), tenv, venv, second);
              var typed_second = match$1[0];
              var t_second = Lang_typing_annotation$BwaxAdmin.type_of(typed_second);
              return /* tuple */[
                      /* tuple */[
                        typed_first,
                        match[1],
                        t_first
                      ],
                      /* tuple */[
                        typed_second,
                        match$1[1],
                        t_second
                      ]
                    ];
            };
            var match$14;
            if (Caml_obj.caml_equal(ta1, ta2)) {
              try {
                match$14 = try_each(e1, e2);
              }
              catch (raw_e$1){
                var e$1 = Caml_js_exceptions.internalToOCamlException(raw_e$1);
                if (e$1[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
                  try {
                    var match$15 = try_each(e2, e1);
                    match$14 = /* tuple */[
                      match$15[1],
                      match$15[0]
                    ];
                  }
                  catch (raw_exn$1){
                    var exn$1 = Caml_js_exceptions.internalToOCamlException(raw_exn$1);
                    if (exn$1[0] === Lang_typing_base$BwaxAdmin.Type_exn) {
                      throw e$1;
                    }
                    throw exn$1;
                  }
                } else {
                  throw e$1;
                }
              }
            } else {
              var match$16 = type_it(tenv, venv, e1);
              var typed_e1 = match$16[0];
              var t1 = Lang_typing_annotation$BwaxAdmin.type_of(typed_e1);
              var match$17 = type_it(tenv, venv, e2);
              var typed_e2 = match$17[0];
              var t2 = Lang_typing_annotation$BwaxAdmin.type_of(typed_e2);
              match$14 = /* tuple */[
                /* tuple */[
                  typed_e1,
                  match$16[1],
                  t1
                ],
                /* tuple */[
                  typed_e2,
                  match$17[1],
                  t2
                ]
              ];
            }
            var match$18 = match$14[1];
            var t2$1 = match$18[2];
            var match$19 = match$14[0];
            var t1$1 = match$19[2];
            var unify_as_usual = function (param) {
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                    t1$1,
                    ta1
                  ]);
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                    t2$1,
                    ta2
                  ]);
              return tr;
            };
            var match$20 = Lang_typing_base$BwaxAdmin.shorten(t1$1);
            var match$21 = Lang_typing_base$BwaxAdmin.shorten(t2$1);
            var final_tr;
            if (typeof match$20 === "number" || match$20.tag) {
              final_tr = unify_as_usual(/* () */0);
            } else {
              switch (match$20[0]) {
                case "Float" :
                    final_tr = typeof match$21 === "number" || match$21.tag || match$21[0] !== "Int" ? unify_as_usual(/* () */0) : /* Term */Caml_chrome_debugger.variant("Term", 0, [
                          "Float",
                          /* [] */0
                        ]);
                    break;
                case "Int" :
                    final_tr = typeof match$21 === "number" || match$21.tag || match$21[0] !== "Float" ? unify_as_usual(/* () */0) : /* Term */Caml_chrome_debugger.variant("Term", 0, [
                          "Float",
                          /* [] */0
                        ]);
                    break;
                case "List" :
                    var match$22 = match$20[1];
                    if (match$22 && !(match$22[1] || typeof match$21 === "number" || match$21.tag || match$21[0] !== "List")) {
                      var match$23 = match$21[1];
                      if (match$23 && !match$23[1]) {
                        var match$24 = Lang_typing_base$BwaxAdmin.shorten(match$22[0]);
                        var match$25 = Lang_typing_base$BwaxAdmin.shorten(match$23[0]);
                        var exit = 0;
                        var binds$2;
                        var terms;
                        if (typeof match$24 === "number") {
                          final_tr = unify_as_usual(/* () */0);
                        } else {
                          switch (match$24.tag | 0) {
                            case /* Term_record */1 :
                                if (typeof match$25 === "number" || match$25.tag !== /* Var */2) {
                                  final_tr = unify_as_usual(/* () */0);
                                } else {
                                  var match$26 = match$25[0][/* var_type */0];
                                  if (typeof match$26 === "number" || match$26.tag !== /* Record_limited */3) {
                                    final_tr = unify_as_usual(/* () */0);
                                  } else {
                                    binds$2 = match$24[0];
                                    terms = match$26[0];
                                    exit = 1;
                                  }
                                }
                                break;
                            case /* Var */2 :
                                var match$27 = match$24[0][/* var_type */0];
                                if (typeof match$27 === "number" || !(match$27.tag === /* Record_limited */3 && !(typeof match$25 === "number" || match$25.tag !== /* Term_record */1))) {
                                  final_tr = unify_as_usual(/* () */0);
                                } else {
                                  binds$2 = match$25[0];
                                  terms = match$27[0];
                                  exit = 1;
                                }
                                break;
                            default:
                              final_tr = unify_as_usual(/* () */0);
                          }
                        }
                        if (exit === 1) {
                          var combined_000 = /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [binds$2]);
                          var combined = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              combined_000,
                              terms
                            ]);
                          var t$4 = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                                  "var_type",
                                  "n",
                                  "t"
                                ], [
                                  Caml_chrome_debugger.variant("Record_limited", 3, [combined]),
                                  Curry._1(next_int, /* () */0),
                                  1
                                ])]);
                          final_tr = /* Term */Caml_chrome_debugger.variant("Term", 0, [
                              "List",
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  t$4,
                                  /* [] */0
                                ])
                            ]);
                        }
                        
                      } else {
                        final_tr = unify_as_usual(/* () */0);
                      }
                    } else {
                      final_tr = unify_as_usual(/* () */0);
                    }
                    break;
                default:
                  final_tr = unify_as_usual(/* () */0);
              }
            }
            return /* tuple */[
                    /* tuple */[
                      /* Binop */Caml_chrome_debugger.variant("Binop", 9, [
                          bop,
                          match$19[0],
                          match$18[0]
                        ]),
                      /* tuple */[
                        info,
                        final_tr
                      ]
                    ],
                    add(match$19[1], match$18[1])
                  ];
        case /* If */10 :
            var match$28 = type_it(tenv, venv, alt[0]);
            var typed_e1$1 = match$28[0];
            var t1$2 = Lang_typing_annotation$BwaxAdmin.type_of(typed_e1$1);
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  t1$2,
                  Lang_typing_base$BwaxAdmin.bool
                ]);
            var match$29 = typing_exprs_with_same_expected_term(Plate$BwaxAdmin.$$Option.with_default(Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0)), typing_context[/* expected_term */1]), Plate$BwaxAdmin.List.map((function (e) {
                        return /* tuple */[
                                tenv,
                                e
                              ];
                      }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        alt[1],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            alt[2],
                            /* [] */0
                          ])
                      ])));
            if (match$29) {
              var match$30 = match$29[1];
              if (match$30 && !match$30[1]) {
                var match$31 = match$30[0];
                var typed_e3 = match$31[0];
                var match$32 = match$29[0];
                var t3 = Lang_typing_annotation$BwaxAdmin.type_of(typed_e3);
                return /* tuple */[
                        /* tuple */[
                          /* If */Caml_chrome_debugger.variant("If", 10, [
                              typed_e1$1,
                              match$32[0],
                              typed_e3
                            ]),
                          /* tuple */[
                            info,
                            t3
                          ]
                        ],
                        sum(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                match$28[1],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match$32[1],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        match$31[1],
                                        /* [] */0
                                      ])
                                  ])
                              ]))
                      ];
              }
              
            }
            throw [
                  Caml_builtin_exceptions.match_failure,
                  /* tuple */[
                    "lang_typing.ml",
                    856,
                    12
                  ]
                ];
        case /* Case */11 :
            var branches = alt[1];
            var match$33 = type_it(tenv, venv, alt[0]);
            var typed_target = match$33[0];
            var t0 = Lang_typing_annotation$BwaxAdmin.type_of(typed_target);
            var ptns = Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.fst, branches);
            var bodies = Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.snd, branches);
            var typed_ptns_and_vars = Plate$BwaxAdmin.List.map((function (ptn) {
                    var match = make_ptn_type(next_int, dts, fixed_tenv, tenv, ptn);
                    var typed_ptn = match[0];
                    var tp = Lang_typing_annotation$BwaxAdmin.type_of(typed_ptn);
                    Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                          tp,
                          t0
                        ]);
                    return /* tuple */[
                            /* tuple */[
                              typed_ptn,
                              match[2]
                            ],
                            match[1]
                          ];
                  }), ptns);
            var vars_and_bodies = Plate$BwaxAdmin.List.combine(Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.snd, typed_ptns_and_vars), bodies);
            var expected_term = Plate$BwaxAdmin.$$Option.with_default(Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0)), typing_context[/* expected_term */1]);
            var typed_bodies = typing_exprs_with_same_expected_term(expected_term, Plate$BwaxAdmin.List.map((function (param) {
                        var var_env = Dict$BwaxAdmin.$$String.from_list(Plate$BwaxAdmin.List.map((function (param) {
                                    return /* tuple */[
                                            param[0],
                                            /* tuple */[
                                              /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                                                  /* [] */0,
                                                  param[1]
                                                ]),
                                              true,
                                              undefined
                                            ]
                                          ];
                                  }), param[0]));
                        return /* tuple */[
                                Dict$BwaxAdmin.$$String.union(var_env, tenv),
                                param[1]
                              ];
                      }), vars_and_bodies));
            var summed_stat_ptns = sum(Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.fst, typed_ptns_and_vars)));
            var summed_stat_bodies = sum(Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.snd, typed_bodies));
            var stat = sum(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    match$33[1],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        summed_stat_ptns,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            summed_stat_bodies,
                            /* [] */0
                          ])
                      ])
                  ]));
            var typed_branches = Plate$BwaxAdmin.List.combine(Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.fst, Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.fst, typed_ptns_and_vars)), Plate$BwaxAdmin.List.map(Plate$BwaxAdmin.fst, typed_bodies));
            return /* tuple */[
                    /* tuple */[
                      /* Case */Caml_chrome_debugger.variant("Case", 11, [
                          typed_target,
                          typed_branches
                        ]),
                      /* tuple */[
                        info,
                        expected_term
                      ]
                    ],
                    stat
                  ];
        case /* Call_cc */12 :
            return Lang_typing_base$BwaxAdmin.fail_typing(info, "not impl");
        case /* Lambda */13 :
            var match$34 = type_func(next_int, fixed_tenv, dts, maybe_module_name, typing_context[/* expected_term */1], tenv, venv, alt[0], alt[1]);
            return /* tuple */[
                    /* tuple */[
                      /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
                          match$34[0],
                          match$34[1]
                        ]),
                      /* tuple */[
                        info,
                        match$34[2]
                      ]
                    ],
                    match$34[3]
                  ];
        case /* Let */14 :
            var match$35 = type_defs(next_int, fixed_tenv, dts, tenv, venv, /* tuple */[
                  undefined,
                  alt[0]
                ]);
            var match$36 = type_with_context(typing_context, match$35[0], venv, alt[1]);
            var typed_body = match$36[0];
            var final_e_000$1 = /* Let */Caml_chrome_debugger.variant("Let", 14, [
                match$35[3],
                typed_body
              ]);
            var final_e_001$1 = /* tuple */[
              info,
              Lang_typing_annotation$BwaxAdmin.type_of(typed_body)
            ];
            var final_e$1 = /* tuple */[
              final_e_000$1,
              final_e_001$1
            ];
            return /* tuple */[
                    final_e$1,
                    add(match$35[4], match$36[1])
                  ];
        case /* Literal */15 :
            var l = alt[0];
            var final_t$2 = type_literal(next_int, l);
            return /* tuple */[
                    /* tuple */[
                      /* Literal */Caml_chrome_debugger.variant("Literal", 15, [l]),
                      /* tuple */[
                        info,
                        final_t$2
                      ]
                    ],
                    zero
                  ];
        case /* Ref */16 :
            var match$37 = Plate$BwaxAdmin.$$Option.map(Lang_typing_base$BwaxAdmin.shorten, typing_context[/* expected_term */1]);
            var possible_prefixes;
            if (match$37 !== undefined) {
              var match$38 = match$37;
              if (typeof match$38 === "number" || match$38.tag || match$38[0] !== "Arrow") {
                possible_prefixes = /* [] */0;
              } else {
                var match$39 = match$38[1];
                if (match$39) {
                  var match$40 = match$39[1];
                  if (match$40 && !match$40[1]) {
                    var match$41 = Lang_typing_base$BwaxAdmin.get_last_argument_and_return_type(match$39[0], match$40[0]);
                    possible_prefixes = Plate$BwaxAdmin.List.clean(Plate$BwaxAdmin.List.map(get_prefix_for_term, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                match$41[0],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match$41[1],
                                    /* [] */0
                                  ])
                              ])));
                  } else {
                    possible_prefixes = /* [] */0;
                  }
                } else {
                  possible_prefixes = /* [] */0;
                }
              }
            } else {
              possible_prefixes = /* [] */0;
            }
            return auto_prefix_ref(possible_prefixes, alt[0], info);
        case /* List_wo_record */17 :
            var iter$1 = function (param, e) {
              var match = type_it(tenv, venv, e);
              var new_es_000 = match[0];
              var new_es_001 = param[0];
              var new_es = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  new_es_000,
                  new_es_001
                ]);
              var new_stat = add(param[1], match[1]);
              return /* tuple */[
                      new_es,
                      new_stat
                    ];
            };
            var match$42 = Plate$BwaxAdmin.List.foldl(iter$1, /* tuple */[
                  /* [] */0,
                  zero
                ], alt[0]);
            var es$1 = Plate$BwaxAdmin.List.rev(match$42[0]);
            var ts = Plate$BwaxAdmin.List.map(Lang_typing_annotation$BwaxAdmin.type_of, es$1);
            var t$5 = Lang_typing_base$BwaxAdmin.var_of(/* Record_limited */Caml_chrome_debugger.variant("Record_limited", 3, [ts]), Curry._1(next_int, /* () */0));
            return /* tuple */[
                    /* tuple */[
                      /* List_wo_record */Caml_chrome_debugger.variant("List_wo_record", 17, [es$1]),
                      /* tuple */[
                        info,
                        Lang_typing_base$BwaxAdmin.list(t$5)
                      ]
                    ],
                    match$42[1]
                  ];
        case /* Type_annoted_expr */18 :
            var match$43 = Lang_typing_annotation$BwaxAdmin.make_annot_term(next_int, dts, true, maybe_module_name, venv, alt[1]);
            var tant = match$43[1];
            var at = Lang_typing_annotation$BwaxAdmin.type_of(tant);
            var match$44 = type_it(tenv, match$43[0], alt[0]);
            var exp_stat = match$44[1];
            var typed_exp = match$44[0];
            var it$1 = Lang_typing_annotation$BwaxAdmin.type_of(typed_exp);
            if (typeof at !== "number" && at.tag === /* Var */2) {
              var v = at[0];
              var tmp = v[/* var_type */0];
              if (typeof tmp !== "number" && tmp.tag === /* Opaqued */8) {
                v[/* t */2] = Lang_typing_base$BwaxAdmin.var_of(/* Opaqued */Caml_chrome_debugger.variant("Opaqued", 8, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            it$1,
                            /* [] */0
                          ])]), Curry._1(next_int, /* () */0));
                return /* tuple */[
                        /* tuple */[
                          /* Type_annoted_expr */Caml_chrome_debugger.variant("Type_annoted_expr", 18, [
                              typed_exp,
                              tant
                            ]),
                          /* tuple */[
                            info,
                            at
                          ]
                        ],
                        exp_stat
                      ];
              }
              
            }
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  it$1,
                  at
                ]);
            var tp = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  at,
                  tp
                ]);
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  it$1,
                  tp
                ]);
            return /* tuple */[
                    /* tuple */[
                      /* Type_annoted_expr */Caml_chrome_debugger.variant("Type_annoted_expr", 18, [
                          typed_exp,
                          tant
                        ]),
                      /* tuple */[
                        info,
                        tp
                      ]
                    ],
                    exp_stat
                  ];
        case /* Record_get_optional */19 :
            var name$4 = alt[1];
            var maybe_record_expr = alt[0];
            var match$45 = getter_type(tenv, name$4);
            var match$46 = type_it(tenv, venv, maybe_record_expr);
            var te$1 = Lang_typing_annotation$BwaxAdmin.type_of(match$46[0]);
            Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                  te$1,
                  /* Term */Caml_chrome_debugger.variant("Term", 0, [
                      "Maybe",
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          match$45[0],
                          /* [] */0
                        ])
                    ])
                ]);
            var match$47 = Lang_typing_base$BwaxAdmin.shorten(match$45[1]);
            var fname;
            fname = typeof match$47 === "number" || match$47.tag || match$47[0] !== "Maybe" ? "map" : "andThen";
            var new_expr_000 = /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                /* tuple */[
                  /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                      /* tuple */[
                        /* Ref */Caml_chrome_debugger.variant("Ref", 16, [fname]),
                        info
                      ],
                      /* tuple */[
                        /* Getter */Caml_chrome_debugger.variant("Getter", 5, [name$4]),
                        info
                      ]
                    ]),
                  info
                ],
                maybe_record_expr
              ]);
            var new_expr = /* tuple */[
              new_expr_000,
              info
            ];
            return type_it(tenv, venv, new_expr);
        case /* List_range */20 :
            var last = alt[2];
            var maybe_second = alt[1];
            var first = alt[0];
            var unify_expr_with_term = function (expr, target_term) {
              var match = type_it(tenv, venv, expr);
              var infered = Lang_typing_annotation$BwaxAdmin.type_of(match[0]);
              return Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                          infered,
                          target_term
                        ]);
            };
            unify_expr_with_term(first, Lang_typing_base$BwaxAdmin.$$int);
            unify_expr_with_term(last, Lang_typing_base$BwaxAdmin.$$int);
            if (maybe_second !== undefined) {
              unify_expr_with_term(maybe_second, Lang_typing_base$BwaxAdmin.$$int);
            }
            var chain_applies = function (f, args) {
              var _acc = f;
              var _param = args;
              while(true) {
                var param = _param;
                var acc = _acc;
                if (param) {
                  _param = param[1];
                  _acc = /* tuple */[
                    /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                        acc,
                        param[0]
                      ]),
                    info
                  ];
                  continue ;
                } else {
                  return acc;
                }
              };
            };
            var new_expr$1 = maybe_second !== undefined ? chain_applies(/* tuple */[
                    /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["List.rangeByStep"]),
                    info
                  ], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      first,
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          last,
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                /* Binop */Caml_chrome_debugger.variant("Binop", 9, [
                                    /* Arith */Caml_chrome_debugger.variant("Arith", 0, [/* Sub */5]),
                                    maybe_second,
                                    first
                                  ]),
                                info
                              ],
                              /* [] */0
                            ])
                        ])
                    ])) : chain_applies(/* tuple */[
                    /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["List.range"]),
                    info
                  ], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      first,
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          last,
                          /* [] */0
                        ])
                    ]));
            return type_it(tenv, venv, new_expr$1);
        case /* List_comprehension */21 :
            var applying_func = function (name, expr) {
              var inf = Plate$BwaxAdmin.snd(expr);
              return /* tuple */[
                      /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                          /* tuple */[
                            /* Ref */Caml_chrome_debugger.variant("Ref", 16, [name]),
                            inf
                          ],
                          expr
                        ]),
                      inf
                    ];
            };
            var l$1 = Plate$BwaxAdmin.List.map((function (s) {
                    switch (s.tag | 0) {
                      case /* Value_assignment */0 :
                      case /* Value_extract */1 :
                          return s;
                      case /* One_expr */2 :
                          return /* One_expr */Caml_chrome_debugger.variant("One_expr", 2, [applying_func("guard", s[0])]);
                      
                    }
                  }), alt[1]);
            var all_stmts = Pervasives.$at(l$1, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* One_expr */Caml_chrome_debugger.variant("One_expr", 2, [applying_func("return", alt[0])]),
                    /* [] */0
                  ]));
            var new_expr$2 = desugar_action_statments(info, all_stmts);
            return type_with_context(typing_context, tenv, venv, new_expr$2);
        case /* Do_expr */22 :
            var new_expr$3 = desugar_action_statments(info, alt[0]);
            return type_with_context(typing_context, tenv, venv, new_expr$3);
        
      }
    }
  }
  catch (raw_exn$2){
    var exn$2 = Caml_js_exceptions.internalToOCamlException(raw_exn$2);
    if (exn$2[0] === Lang_typing_unification$BwaxAdmin.Unify_exc) {
      return Lang_typing_base$BwaxAdmin.fail_typing(info, exn$2[1]);
    } else {
      throw exn$2;
    }
  }
}

function type_defs(next_int, fixed_tenv, given_dts, base_env, venv, param) {
  var defs = param[1];
  var maybe_module = param[0];
  var push = function (info, acc, name) {
    var t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
    return Plate$BwaxAdmin.$less$pipe((function (param) {
                  var info$1 = info;
                  var acc$1 = acc;
                  var name$1 = name;
                  var t = param;
                  var match = Dict$BwaxAdmin.$$String.get(name$1, acc$1);
                  if (match !== undefined) {
                    return Lang_typing_base$BwaxAdmin.fail_typing(info$1, "`" + (String(name$1) + "` is defined more than once"));
                  } else {
                    return Dict$BwaxAdmin.$$String.insert(name$1, t, acc$1);
                  }
                }), /* tuple */[
                /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                    /* [] */0,
                    t
                  ]),
                true,
                undefined
              ]);
  };
  var forward_env = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var info = param[1];
          var def = param[0];
          switch (def.tag | 0) {
            case /* Def_val */0 :
                var match = def[1][0];
                if (typeof match === "number" && match !== 0) {
                  var names = get_names_from_ptn(def[0]);
                  return Plate$BwaxAdmin.List.foldr((function (name, acc_env) {
                                return push(info, acc_env, name);
                              }), names, acc);
                } else {
                  return acc;
                }
            case /* Def_fun */1 :
                return push(info, acc, def[0]);
            default:
              return acc;
          }
        }), Dict$BwaxAdmin.$$String.empty(/* () */0), defs);
  var with_module = function (name) {
    if (maybe_module !== undefined) {
      return maybe_module + ("." + name);
    } else {
      return name;
    }
  };
  var type_placeholders = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var def = param[0];
          if (def.tag === /* Def_type_alias */3) {
            var gvars = Plate$BwaxAdmin.List.keep_map((function (param) {
                    if (typeof param === "number" || param.tag !== /* Var */2) {
                      return ;
                    } else {
                      return param[0];
                    }
                  }), Plate$BwaxAdmin.List.map((function (vn) {
                        return Lang_typing_annotation$BwaxAdmin.var_of_name(next_int, vn);
                      }), def[1]));
            var t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
            return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        with_module(def[0]),
                        /* Alias */Caml_chrome_debugger.variant("Alias", 1, [/* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                                gvars,
                                t
                              ])])
                      ],
                      acc
                    ]);
          } else {
            return acc;
          }
        }), /* [] */0, defs);
  var type_the_def = function (param, param$1) {
    var info = param$1[1];
    var def = param$1[0];
    var acc_stat = param[3];
    var typed_defs = param[2];
    var fresh_env = param[1];
    var fresh_dts = param[0];
    var aggr_to_env = function (dts, last_fresh_env, name, t) {
      var full_env = Dict$BwaxAdmin.$$String.union(last_fresh_env, base_env);
      var gt = Lang_typing_base$BwaxAdmin.generalize(full_env, t);
      var match = Dict$BwaxAdmin.$$String.get(name, forward_env);
      if (match !== undefined) {
        var match$1 = match;
        Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
              match$1[0][1],
              t
            ]);
        return Dict$BwaxAdmin.$$String.insert(name, /* tuple */[
                    gt,
                    match$1[1],
                    match$1[2]
                  ], last_fresh_env);
      } else {
        return Dict$BwaxAdmin.$$String.insert(name, /* tuple */[
                    gt,
                    true,
                    undefined
                  ], last_fresh_env);
      }
    };
    try {
      switch (def.tag | 0) {
        case /* Def_val */0 :
            var ptn = def[0];
            var full_dts = Pervasives.$at(fresh_dts, given_dts);
            var match = Lang_typing_annotation$BwaxAdmin.make_term_if_annotated(next_int, full_dts, true, maybe_module, venv, def[2]);
            var at = match[1];
            var starting_env = Dict$BwaxAdmin.$$String.union(fresh_env, base_env);
            var match$1 = type_expr(next_int, fixed_tenv, full_dts, maybe_module, set_expected_term(at, empty_context), starting_env, match[0], def[1]);
            var typed_expr = match$1[0];
            var inferred_t = Lang_typing_annotation$BwaxAdmin.type_of(typed_expr);
            var match$2 = ptn[0];
            var match$3;
            var exit = 0;
            if (typeof match$2 === "number" || match$2.tag || typeof at === "number" || at.tag !== /* Var */2) {
              exit = 1;
            } else {
              var v = at[0];
              var tmp = v[/* var_type */0];
              if (typeof tmp === "number" || tmp.tag !== /* Opaqued */8) {
                exit = 1;
              } else {
                var name = match$2[0];
                v[/* t */2] = Lang_typing_base$BwaxAdmin.var_of(/* Opaqued */Caml_chrome_debugger.variant("Opaqued", 8, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            inferred_t,
                            /* [] */0
                          ])]), Curry._1(next_int, /* () */0));
                match$3 = /* tuple */[
                  /* tuple */[
                    /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, [name]),
                    /* tuple */[
                      ptn[1],
                      at
                    ]
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        name,
                        at
                      ],
                      /* [] */0
                    ]),
                  zero
                ];
              }
            }
            if (exit === 1) {
              Lang_typing_unification$BwaxAdmin.unify(next_int, full_dts, /* tuple */[
                    inferred_t,
                    at
                  ]);
              var match$4 = make_ptn_type(next_int, full_dts, fixed_tenv, starting_env, ptn);
              var typed_ptn = match$4[0];
              var tp = Lang_typing_annotation$BwaxAdmin.type_of(typed_ptn);
              Lang_typing_unification$BwaxAdmin.unify(next_int, full_dts, /* tuple */[
                    at,
                    tp
                  ]);
              Lang_typing_unification$BwaxAdmin.unify(next_int, full_dts, /* tuple */[
                    inferred_t,
                    tp
                  ]);
              match$3 = /* tuple */[
                typed_ptn,
                match$4[1],
                match$4[2]
              ];
            }
            var new_fresh_env = Plate$BwaxAdmin.List.foldl((function (acc_env, param) {
                    var t = param[1];
                    var n = param[0];
                    var match = Dict$BwaxAdmin.$$String.get(n, acc_env);
                    if (match !== undefined) {
                      return Lang_typing_base$BwaxAdmin.fail_typing(info, "`" + (String(n) + "` is already defined in current scope"));
                    } else {
                      var match$1 = Dict$BwaxAdmin.$$String.get(n, forward_env);
                      if (match$1 !== undefined) {
                        var match$2 = match$1;
                        Lang_typing_unification$BwaxAdmin.unify(next_int, full_dts, /* tuple */[
                              t,
                              match$2[0][1]
                            ]);
                        var gt = Lang_typing_base$BwaxAdmin.generalize(base_env, t);
                        return Dict$BwaxAdmin.$$String.insert(n, /* tuple */[
                                    gt,
                                    match$2[1],
                                    match$2[2]
                                  ], acc_env);
                      } else {
                        var gt$1 = /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                            /* [] */0,
                            t
                          ]);
                        return Dict$BwaxAdmin.$$String.insert(n, /* tuple */[
                                    gt$1,
                                    true,
                                    undefined
                                  ], acc_env);
                      }
                    }
                  }), fresh_env, match$3[1]);
            var typed_def_000 = /* Def_val */Caml_chrome_debugger.variant("Def_val", 0, [
                match$3[0],
                typed_expr,
                undefined
              ]);
            var typed_def_001 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(at)
            ];
            var typed_def = /* tuple */[
              typed_def_000,
              typed_def_001
            ];
            var new_typed_defs = Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    typed_def,
                    /* [] */0
                  ]));
            return /* tuple */[
                    fresh_dts,
                    new_fresh_env,
                    new_typed_defs,
                    sum(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            acc_stat,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                match$1[1],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match$3[2],
                                    /* [] */0
                                  ])
                              ])
                          ]))
                  ];
        case /* Def_fun */1 :
            var name$1 = def[0];
            var full_dts$1 = Pervasives.$at(fresh_dts, given_dts);
            var match$5 = Lang_typing_annotation$BwaxAdmin.make_term_if_annotated(next_int, full_dts$1, true, maybe_module, venv, def[3]);
            var at$1 = match$5[1];
            var starting_env$1 = Dict$BwaxAdmin.$$String.union(fresh_env, Dict$BwaxAdmin.$$String.union(forward_env, base_env));
            var match$6 = type_func(next_int, fixed_tenv, full_dts$1, maybe_module, at$1, starting_env$1, match$5[0], def[1], def[2]);
            Lang_typing_unification$BwaxAdmin.unify(next_int, full_dts$1, /* tuple */[
                  match$6[2],
                  at$1
                ]);
            var final_f_env = aggr_to_env(full_dts$1, fresh_env, name$1, at$1);
            var typed_def_000$1 = /* Def_fun */Caml_chrome_debugger.variant("Def_fun", 1, [
                name$1,
                match$6[0],
                match$6[1],
                undefined
              ]);
            var typed_def_001$1 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(at$1)
            ];
            var typed_def$1 = /* tuple */[
              typed_def_000$1,
              typed_def_001$1
            ];
            var new_typed_defs$1 = Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    typed_def$1,
                    /* [] */0
                  ]));
            return /* tuple */[
                    fresh_dts,
                    final_f_env,
                    new_typed_defs$1,
                    add(acc_stat, match$6[3])
                  ];
        case /* Def_type */2 :
            var variants = def[2];
            var vars = def[1];
            var name$2 = def[0];
            var tname = with_module(name$2);
            var collect_type_var = function (name, acc) {
              return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          name,
                          Lang_typing_annotation$BwaxAdmin.var_of_name(next_int, name)
                        ],
                        acc
                      ]);
            };
            var vars_dict = Plate$BwaxAdmin.List.foldr(collect_type_var, vars, /* [] */0);
            var shape;
            var exit$1 = 0;
            if (variants && !variants[1]) {
              var match$7 = variants[0];
              shape = /* Product */Caml_chrome_debugger.variant("Product", 0, [
                  match$7[0],
                  Plate$BwaxAdmin.List.length(match$7[1])
                ]);
            } else {
              exit$1 = 1;
            }
            if (exit$1 === 1) {
              shape = /* Sum */Caml_chrome_debugger.variant("Sum", 1, [Plate$BwaxAdmin.List.map((function (param) {
                          return /* tuple */[
                                  param[0],
                                  Plate$BwaxAdmin.List.length(param[1])
                                ];
                        }), variants)]);
            }
            var nt_001 = Plate$BwaxAdmin.List.assoc_vals(vars_dict);
            var nt = /* Term */Caml_chrome_debugger.variant("Term", 0, [
                tname,
                nt_001
              ]);
            var new_dt_000 = Lang_typing_base$BwaxAdmin.generalize(Dict$BwaxAdmin.$$String.empty(/* () */0), nt);
            var new_dt = /* Just */Caml_chrome_debugger.variant("Just", 0, [
                new_dt_000,
                shape
              ]);
            var new_venv = Pervasives.$at(vars_dict, venv);
            var match$8 = Plate$BwaxAdmin.List.assoc(tname, fresh_dts);
            var new_fresh_dts = match$8 !== undefined ? Plate$BwaxAdmin.List.map((function (param) {
                      var k = param[0];
                      if (k === tname) {
                        return /* tuple */[
                                k,
                                new_dt
                              ];
                      } else {
                        return /* tuple */[
                                k,
                                param[1]
                              ];
                      }
                    }), fresh_dts) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    tname,
                    new_dt
                  ],
                  fresh_dts
                ]);
            var new_full_dts = Pervasives.$at(new_fresh_dts, given_dts);
            var match$9 = Plate$BwaxAdmin.List.foldl((function (param, param$1) {
                    var name = param$1[0];
                    var match = type_variant(next_int, new_full_dts, maybe_module, new_venv, /* tuple */[
                          tname,
                          new_dt
                        ], vars_dict, param$1[1]);
                    var t = match[1];
                    var n_f_env = aggr_to_env(new_full_dts, param[0], name, t);
                    var typed_variant_001 = match[2];
                    var typed_variant_002 = /* tuple */[
                      param$1[2],
                      t
                    ];
                    var typed_variant = /* tuple */[
                      name,
                      typed_variant_001,
                      typed_variant_002
                    ];
                    var new_typed_variants = Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            typed_variant,
                            /* [] */0
                          ]));
                    return /* tuple */[
                            n_f_env,
                            new_typed_variants
                          ];
                  }), /* tuple */[
                  fresh_env,
                  /* [] */0
                ], variants);
            var typed_def_000$2 = /* Def_type */Caml_chrome_debugger.variant("Def_type", 2, [
                name$2,
                vars,
                match$9[1]
              ]);
            var typed_def_001$2 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(Lang_typing_base$BwaxAdmin.term_of_name(new_full_dts, maybe_module, tname, /* [] */0))
            ];
            var typed_def$2 = /* tuple */[
              typed_def_000$2,
              typed_def_001$2
            ];
            var new_typed_defs$2 = Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    typed_def$2,
                    /* [] */0
                  ]));
            return /* tuple */[
                    new_fresh_dts,
                    match$9[0],
                    new_typed_defs$2,
                    acc_stat
                  ];
        case /* Def_type_alias */3 :
            var vars$1 = def[1];
            var name$3 = def[0];
            var tname$1 = with_module(name$3);
            var local_venv = Plate$BwaxAdmin.List.map((function (vname) {
                    return /* tuple */[
                            vname,
                            Lang_typing_annotation$BwaxAdmin.var_of_name(next_int, vname)
                          ];
                  }), vars$1);
            var new_venv$1 = Pervasives.$at(local_venv, venv);
            var full_dts$2 = Pervasives.$at(fresh_dts, given_dts);
            var match$10 = Lang_typing_annotation$BwaxAdmin.make_annot_term(next_int, full_dts$2, false, maybe_module, new_venv$1, def[2]);
            var tant = match$10[1];
            var t = Lang_typing_annotation$BwaxAdmin.type_of(tant);
            var gvars = Plate$BwaxAdmin.List.keep_map((function (param) {
                    if (typeof param === "number" || param.tag !== /* Var */2) {
                      return ;
                    } else {
                      return param[0];
                    }
                  }), Plate$BwaxAdmin.List.assoc_vals(local_venv));
            var scheme = /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                gvars,
                t
              ]);
            var new_dt$1 = /* Alias */Caml_chrome_debugger.variant("Alias", 1, [scheme]);
            var match$11 = Plate$BwaxAdmin.List.assoc(tname$1, fresh_dts);
            var new_fresh_dts$1 = match$11 !== undefined ? Plate$BwaxAdmin.List.map((function (param) {
                      var k = param[0];
                      if (k === tname$1) {
                        return /* tuple */[
                                k,
                                new_dt$1
                              ];
                      } else {
                        return /* tuple */[
                                k,
                                param[1]
                              ];
                      }
                    }), fresh_dts) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    tname$1,
                    new_dt$1
                  ],
                  fresh_dts
                ]);
            var full_dts$3 = Pervasives.$at(new_fresh_dts$1, given_dts);
            var typed_def_000$3 = /* Def_type_alias */Caml_chrome_debugger.variant("Def_type_alias", 3, [
                name$3,
                vars$1,
                tant
              ]);
            var typed_def_001$3 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(Lang_typing_base$BwaxAdmin.term_of_name(full_dts$3, maybe_module, tname$1, /* [] */0))
            ];
            var typed_def$3 = /* tuple */[
              typed_def_000$3,
              typed_def_001$3
            ];
            var new_typed_defs$3 = Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    typed_def$3,
                    /* [] */0
                  ]));
            return /* tuple */[
                    new_fresh_dts$1,
                    fresh_env,
                    new_typed_defs$3,
                    acc_stat
                  ];
        case /* Def_type_opaque */4 :
            var vars$2 = def[1];
            var name$4 = def[0];
            var tname$2 = with_module(name$4);
            var new_dt$2 = /* Opaque */Caml_chrome_debugger.variant("Opaque", 2, [vars$2]);
            var match$12 = Plate$BwaxAdmin.List.assoc(tname$2, fresh_dts);
            var new_fresh_dts$2 = match$12 !== undefined ? Plate$BwaxAdmin.List.map((function (param) {
                      var k = param[0];
                      if (k === tname$2) {
                        return /* tuple */[
                                k,
                                new_dt$2
                              ];
                      } else {
                        return /* tuple */[
                                k,
                                param[1]
                              ];
                      }
                    }), fresh_dts) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    tname$2,
                    new_dt$2
                  ],
                  fresh_dts
                ]);
            var full_dts$4 = Pervasives.$at(new_fresh_dts$2, given_dts);
            var typed_def_000$4 = /* Def_type_opaque */Caml_chrome_debugger.variant("Def_type_opaque", 4, [
                name$4,
                vars$2
              ]);
            var typed_def_001$4 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(Lang_typing_base$BwaxAdmin.term_of_name(full_dts$4, maybe_module, tname$2, /* [] */0))
            ];
            var typed_def$4 = /* tuple */[
              typed_def_000$4,
              typed_def_001$4
            ];
            var new_typed_defs$4 = Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    typed_def$4,
                    /* [] */0
                  ]));
            return /* tuple */[
                    new_fresh_dts$2,
                    fresh_env,
                    new_typed_defs$4,
                    acc_stat
                  ];
        case /* Def_import */5 :
            var imp = def[0];
            var match$13;
            switch (imp.tag | 0) {
              case /* Import_exposing */0 :
                  var mod_name = imp[0];
                  var with_prefix = function (n) {
                    return mod_name + ("." + n);
                  };
                  var match$14 = Plate$BwaxAdmin.List.foldl((function (param, param$1) {
                          var i = param$1[1];
                          var imp = param$1[0];
                          var acc_dts = param[1];
                          var acc_env = param[0];
                          if (imp.tag) {
                            var n = imp[0];
                            var tname = with_prefix(n);
                            var match = Plate$BwaxAdmin.List.assoc(tname, given_dts);
                            if (match !== undefined) {
                              var match$1 = match;
                              if (typeof match$1 !== "number" && !match$1.tag) {
                                var match$2 = match$1[1];
                                if (typeof match$2 !== "number" && match$2.tag) {
                                  var next_env = Plate$BwaxAdmin.List.foldl((function (acc_env, param) {
                                          var name = param[0];
                                          var tagger_name = with_prefix(name);
                                          var match = Dict$BwaxAdmin.$$String.get(tagger_name, fixed_tenv);
                                          if (match !== undefined) {
                                            var match$1 = match;
                                            if (match$1[1]) {
                                              return Dict$BwaxAdmin.$$String.insert(name, /* tuple */[
                                                          match$1[0],
                                                          false,
                                                          Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                                                                  return tagger_name;
                                                                }), match$1[2])
                                                        ], acc_env);
                                            } else {
                                              return acc_env;
                                            }
                                          } else {
                                            return acc_env;
                                          }
                                        }), acc_env, match$2[0]);
                                  var next_dts_000 = /* tuple */[
                                    with_module(n),
                                    /* Alias */Caml_chrome_debugger.variant("Alias", 1, [match$1[0]])
                                  ];
                                  var next_dts = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      next_dts_000,
                                      acc_dts
                                    ]);
                                  return /* tuple */[
                                          next_env,
                                          next_dts
                                        ];
                                }
                                
                              }
                              
                            }
                            return Plate$BwaxAdmin.$less$pipe((function (param) {
                                          return Lang_typing_base$BwaxAdmin.fail_typing(i, param);
                                        }), "I cannot find the sum type `" + (tname + "`  to import."));
                          } else {
                            var n$1 = imp[0];
                            var full_name = with_prefix(n$1);
                            var match$3 = Dict$BwaxAdmin.$$String.get(full_name, fixed_tenv);
                            var exit = 0;
                            if (match$3 !== undefined) {
                              var match$4 = match$3;
                              if (match$4[1]) {
                                return /* tuple */[
                                        Dict$BwaxAdmin.$$String.insert(n$1, /* tuple */[
                                              match$4[0],
                                              false,
                                              Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                                                      return full_name;
                                                    }), match$4[2])
                                            ], acc_env),
                                        acc_dts
                                      ];
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                            if (exit === 1) {
                              var t = Plate$BwaxAdmin.List.assoc(full_name, given_dts);
                              if (t !== undefined) {
                                var match$5 = t;
                                var exit$1 = 0;
                                if (typeof match$5 === "number") {
                                  match$5 === /* Special */0;
                                } else {
                                  switch (match$5.tag | 0) {
                                    case /* Just */0 :
                                    case /* Alias */1 :
                                        exit$1 = 3;
                                        break;
                                    case /* Opaque */2 :
                                        var sons = Plate$BwaxAdmin.List.map((function (vname) {
                                                return Lang_typing_annotation$BwaxAdmin.var_of_name(next_int, vname);
                                              }), match$5[0]);
                                        var gvars = Plate$BwaxAdmin.List.keep_map((function (param) {
                                                if (typeof param === "number" || param.tag !== /* Var */2) {
                                                  return ;
                                                } else {
                                                  return param[0];
                                                }
                                              }), sons);
                                        var next_dts_000$1 = /* tuple */[
                                          with_module(n$1),
                                          /* Alias */Caml_chrome_debugger.variant("Alias", 1, [/* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                                                  gvars,
                                                  /* Term */Caml_chrome_debugger.variant("Term", 0, [
                                                      full_name,
                                                      sons
                                                    ])
                                                ])])
                                        ];
                                        var next_dts$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            next_dts_000$1,
                                            acc_dts
                                          ]);
                                        return /* tuple */[
                                                acc_env,
                                                next_dts$1
                                              ];
                                    
                                  }
                                }
                                if (exit$1 === 3) {
                                  var next_dts_000$2 = /* tuple */[
                                    with_module(n$1),
                                    /* Alias */Caml_chrome_debugger.variant("Alias", 1, [match$5[0]])
                                  ];
                                  var next_dts$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      next_dts_000$2,
                                      acc_dts
                                    ]);
                                  return /* tuple */[
                                          acc_env,
                                          next_dts$2
                                        ];
                                }
                                
                              }
                              return Plate$BwaxAdmin.$less$pipe((function (param) {
                                            return Lang_typing_base$BwaxAdmin.fail_typing(i, param);
                                          }), "I cannot find `" + (full_name + "` to import."));
                            }
                            
                          }
                        }), /* tuple */[
                        fresh_env,
                        fresh_dts
                      ], imp[1]);
                  match$13 = /* tuple */[
                    match$14[1],
                    match$14[0]
                  ];
                  break;
              case /* Import_all */1 :
                  var module_name = imp[0];
                  var vars_and_taggers = Dict$BwaxAdmin.$$String.to_list(Dict$BwaxAdmin.$$String.filter((function (k, param) {
                              if (param[1]) {
                                return Plate$BwaxAdmin.Str.starts_with(module_name + ".", k);
                              } else {
                                return false;
                              }
                            }), fixed_tenv));
                  var dts = Plate$BwaxAdmin.List.filter((function (param) {
                            return Plate$BwaxAdmin.Str.starts_with(module_name + ".", param[0]);
                          }))(given_dts);
                  if (Plate$BwaxAdmin.List.length(vars_and_taggers) === 0 && Plate$BwaxAdmin.List.length(dts) === 0) {
                    match$13 = Plate$BwaxAdmin.$less$pipe((function (param) {
                            return Lang_typing_base$BwaxAdmin.fail_typing(info, param);
                          }), "There nothing to import");
                  } else {
                    var next_fresh_env = Plate$BwaxAdmin.List.foldl((function (acc, param) {
                            var match = param[1];
                            var k = param[0];
                            var name = Plate$BwaxAdmin.Str.drop_left(Plate$BwaxAdmin.Str.length(module_name) + 1 | 0, k);
                            var the_original_name = Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                                    return k;
                                  }), match[2]);
                            return Dict$BwaxAdmin.$$String.insert(name, /* tuple */[
                                        match[0],
                                        false,
                                        the_original_name
                                      ], acc);
                          }), fresh_env, vars_and_taggers);
                    var next_fresh_dts = Plate$BwaxAdmin.List.foldr((function (param, acc) {
                            var dt = param[1];
                            var k = param[0];
                            var name = Plate$BwaxAdmin.Str.drop_left(Plate$BwaxAdmin.Str.length(module_name) + 1 | 0, k);
                            if (typeof dt === "number") {
                              return acc;
                            } else {
                              switch (dt.tag | 0) {
                                case /* Just */0 :
                                case /* Alias */1 :
                                    break;
                                case /* Opaque */2 :
                                    var sons = Plate$BwaxAdmin.List.map((function (vname) {
                                            return Lang_typing_annotation$BwaxAdmin.var_of_name(next_int, vname);
                                          }), dt[0]);
                                    var gvars = Plate$BwaxAdmin.List.keep_map((function (param) {
                                            if (typeof param === "number" || param.tag !== /* Var */2) {
                                              return ;
                                            } else {
                                              return param[0];
                                            }
                                          }), sons);
                                    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              /* tuple */[
                                                with_module(name),
                                                /* Alias */Caml_chrome_debugger.variant("Alias", 1, [/* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                                                        gvars,
                                                        /* Term */Caml_chrome_debugger.variant("Term", 0, [
                                                            k,
                                                            sons
                                                          ])
                                                      ])])
                                              ],
                                              acc
                                            ]);
                                
                              }
                            }
                            return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        with_module(name),
                                        /* Alias */Caml_chrome_debugger.variant("Alias", 1, [dt[0]])
                                      ],
                                      acc
                                    ]);
                          }), dts, fresh_dts);
                    match$13 = /* tuple */[
                      next_fresh_dts,
                      next_fresh_env
                    ];
                  }
                  break;
              case /* Import_as */2 :
                  var full_name = imp[0];
                  var match$15 = Dict$BwaxAdmin.$$String.get(full_name, fixed_tenv);
                  var next_fresh_env$1;
                  var exit$2 = 0;
                  if (match$15 !== undefined) {
                    var match$16 = match$15;
                    if (match$16[1]) {
                      next_fresh_env$1 = Dict$BwaxAdmin.$$String.insert(imp[1], /* tuple */[
                            match$16[0],
                            false,
                            Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                                    return full_name;
                                  }), match$16[2])
                          ], fresh_env);
                    } else {
                      exit$2 = 1;
                    }
                  } else {
                    exit$2 = 1;
                  }
                  if (exit$2 === 1) {
                    next_fresh_env$1 = Plate$BwaxAdmin.$less$pipe((function (param) {
                            return Lang_typing_base$BwaxAdmin.fail_typing(info, param);
                          }), "I cannot find `" + (full_name + "`  to import."));
                  }
                  match$13 = /* tuple */[
                    fresh_dts,
                    next_fresh_env$1
                  ];
                  break;
              
            }
            var typed_imp;
            switch (imp.tag | 0) {
              case /* Import_exposing */0 :
                  typed_imp = /* Import_exposing */Caml_chrome_debugger.variant("Import_exposing", 0, [
                      imp[0],
                      Plate$BwaxAdmin.List.map((function (param) {
                              return /* tuple */[
                                      param[0],
                                      /* tuple */[
                                        param[1],
                                        /* No_term */0
                                      ]
                                    ];
                            }), imp[1])
                    ]);
                  break;
              case /* Import_all */1 :
                  typed_imp = /* Import_all */Caml_chrome_debugger.variant("Import_all", 1, [imp[0]]);
                  break;
              case /* Import_as */2 :
                  typed_imp = /* Import_as */Caml_chrome_debugger.variant("Import_as", 2, [
                      imp[0],
                      imp[1]
                    ]);
                  break;
              
            }
            var typed_def_000$5 = /* Def_import */Caml_chrome_debugger.variant("Def_import", 5, [typed_imp]);
            var typed_def_001$5 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(/* No_term */0)
            ];
            var typed_def$5 = /* tuple */[
              typed_def_000$5,
              typed_def_001$5
            ];
            return /* tuple */[
                    match$13[0],
                    match$13[1],
                    Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            typed_def$5,
                            /* [] */0
                          ])),
                    acc_stat
                  ];
        case /* Def_exposing */6 :
            var exposed = def[0];
            Plate$BwaxAdmin.List.for_each((function (param) {
                    var i = param[1];
                    var exp = param[0];
                    if (exp.tag) {
                      var name = exp[0];
                      var fail_it = function (param) {
                        return Lang_typing_base$BwaxAdmin.fail_typing(i, "Type " + (name + " is not defined here."));
                      };
                      var match = Plate$BwaxAdmin.List.assoc(name, fresh_dts);
                      if (match !== undefined) {
                        return /* () */0;
                      } else if (maybe_module !== undefined) {
                        var match$1 = Plate$BwaxAdmin.List.assoc(maybe_module + ("." + name), fresh_dts);
                        if (match$1 !== undefined) {
                          return /* () */0;
                        } else {
                          return fail_it(/* () */0);
                        }
                      } else {
                        return fail_it(/* () */0);
                      }
                    } else {
                      var name$1 = exp[0];
                      var match$2 = Dict$BwaxAdmin.$$String.get(name$1, fresh_env);
                      if (match$2 !== undefined) {
                        if (match$2[1]) {
                          return /* () */0;
                        } else {
                          return Lang_typing_base$BwaxAdmin.fail_typing(i, name$1 + " is not defined here.");
                        }
                      } else {
                        return Lang_typing_base$BwaxAdmin.fail_typing(i, name$1 + " is not defined here.");
                      }
                    }
                  }), exposed);
            var next_fresh_env$2 = Dict$BwaxAdmin.$$String.map((function (k, param) {
                    var original_name = param[2];
                    var t = param[0];
                    var is_name_exposed = function (n) {
                      return Plate$BwaxAdmin.List.some((function (param) {
                                    var match = param[0];
                                    if (match.tag) {
                                      return false;
                                    } else {
                                      return match[0] === n;
                                    }
                                  }), exposed);
                    };
                    var is_variants_exposed = function (tname) {
                      return Plate$BwaxAdmin.List.some((function (param) {
                                    var match = param[0];
                                    if (match.tag) {
                                      return tname === match[0];
                                    } else {
                                      return false;
                                    }
                                  }), exposed);
                    };
                    if (is_tagger(k)) {
                      return /* tuple */[
                              t,
                              is_name_exposed(k) || is_variants_exposed(get_tagger_final_type_name(k, t, info)),
                              original_name
                            ];
                    } else {
                      return /* tuple */[
                              t,
                              is_name_exposed(k),
                              original_name
                            ];
                    }
                  }), fresh_env);
            var typed_exposed = Plate$BwaxAdmin.List.map((function (param) {
                    return /* tuple */[
                            param[0],
                            /* tuple */[
                              param[1],
                              /* No_term */0
                            ]
                          ];
                  }), exposed);
            var typed_def_000$6 = /* Def_exposing */Caml_chrome_debugger.variant("Def_exposing", 6, [typed_exposed]);
            var typed_def_001$6 = /* tuple */[
              info,
              Lang_typing_base$BwaxAdmin.definition(/* No_term */0)
            ];
            var typed_def$6 = /* tuple */[
              typed_def_000$6,
              typed_def_001$6
            ];
            var next_typed_defs = Pervasives.$at(typed_defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    typed_def$6,
                    /* [] */0
                  ]));
            return /* tuple */[
                    fresh_dts,
                    next_fresh_env$2,
                    next_typed_defs,
                    acc_stat
                  ];
        
      }
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn[0] === Lang_typing_unification$BwaxAdmin.Unify_exc) {
        throw Lang_typing_base$BwaxAdmin.fail_typing(info, exn[1]);
      }
      if (exn[0] === Plate$BwaxAdmin.Unexpected) {
        throw Lang_typing_base$BwaxAdmin.fail_typing(info, exn[1]);
      }
      throw exn;
    }
  };
  var match = Plate$BwaxAdmin.List.foldl(type_the_def, /* tuple */[
        type_placeholders,
        Dict$BwaxAdmin.$$String.empty(/* () */0),
        /* [] */0,
        zero
      ], defs);
  var raw_fresh_env = match[1];
  var all_gvars = Plate$BwaxAdmin.List.unique(Dict$BwaxAdmin.$$String.foldl((function (acc, param, param$1) {
              return Pervasives.$at(acc, param$1[0][0]);
            }), /* [] */0, raw_fresh_env));
  var fresh_env = Dict$BwaxAdmin.$$String.map((function (k, param) {
          var original_t = param[0][1];
          var vs = Plate$BwaxAdmin.List.unique(Lang_typing_base$BwaxAdmin.get_vars(original_t));
          var gvars = Plate$BwaxAdmin.List.filter((function (v) {
                    return Plate$BwaxAdmin.List.some((function (gv) {
                                  return Caml_obj.caml_equal(Lang_typing_base$BwaxAdmin.completely_shrink(/* Var */Caml_chrome_debugger.variant("Var", 2, [gv])), Lang_typing_base$BwaxAdmin.completely_shrink(/* Var */Caml_chrome_debugger.variant("Var", 2, [v])));
                                }), all_gvars);
                  }))(vs);
          return /* tuple */[
                  /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                      gvars,
                      original_t
                    ]),
                  param[1],
                  param[2]
                ];
        }), raw_fresh_env);
  var prefixed_env;
  if (maybe_module !== undefined) {
    var name = maybe_module;
    prefixed_env = Dict$BwaxAdmin.$$String.foldl((function (acc, k, v) {
            return Dict$BwaxAdmin.$$String.insert(name + ("." + k), v, acc);
          }), Dict$BwaxAdmin.$$String.empty(/* () */0), fresh_env);
  } else {
    prefixed_env = fresh_env;
  }
  var combined_env = Dict$BwaxAdmin.$$String.union(prefixed_env, base_env);
  var shrink_type = function (param) {
    return /* tuple */[
            param[0],
            Lang_typing_base$BwaxAdmin.completely_shrink(param[1])
          ];
  };
  var shortened_typed_defs = Plate$BwaxAdmin.List.map((function (param) {
          return Lang_eval$BwaxAdmin.trav_def(shrink_type, param);
        }), match[2]);
  return /* tuple */[
          combined_env,
          match[0],
          prefixed_env,
          shortened_typed_defs,
          match[3]
        ];
}

function get_names_from_ptn(ptn) {
  var p = ptn[0];
  if (typeof p === "number") {
    return /* [] */0;
  } else {
    switch (p.tag | 0) {
      case /* Ptn_var */0 :
          return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    p[0],
                    /* [] */0
                  ]);
      case /* Ptn_constructor */1 :
          return Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map(get_names_from_ptn, p[1]));
      case /* Ptn_tuple */2 :
          return Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map(get_names_from_ptn, p[0]));
      case /* Ptn_cons_list */4 :
          return Pervasives.$at(get_names_from_ptn(p[0]), get_names_from_ptn(p[1]));
      case /* Ptn_record */5 :
          return p[0];
      default:
        return /* [] */0;
    }
  }
}

function make_ptn_type(next_int, dts, fixed_tenv, tenv, ptn) {
  var info = ptn[1];
  var p = ptn[0];
  if (typeof p === "number") {
    switch (p) {
      case /* Ptn_wildcard */0 :
          var t = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_wildcard */0,
                    /* tuple */[
                      info,
                      t
                    ]
                  ],
                  /* [] */0,
                  zero
                ];
      case /* Ptn_unit */1 :
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_unit */1,
                    /* tuple */[
                      info,
                      Lang_typing_base$BwaxAdmin.unit
                    ]
                  ],
                  /* [] */0,
                  zero
                ];
      case /* Ptn_nil_list */2 :
          var vt = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
          var t$1 = Lang_typing_base$BwaxAdmin.list(vt);
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_nil_list */2,
                    /* tuple */[
                      info,
                      t$1
                    ]
                  ],
                  /* [] */0,
                  zero
                ];
      
    }
  } else {
    switch (p.tag | 0) {
      case /* Ptn_var */0 :
          var name = p[0];
          var t$2 = Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0));
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, [name]),
                    /* tuple */[
                      info,
                      t$2
                    ]
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        name,
                        t$2
                      ],
                      /* [] */0
                    ]),
                  zero
                ];
      case /* Ptn_constructor */1 :
          var ptns = p[1];
          var name$1 = p[0];
          var type_of_constructor = function (constr_t) {
            var aggr = function (param, p) {
              var match = make_ptn_type(next_int, dts, fixed_tenv, tenv, p);
              var typed_ptn = match[0];
              var t = Lang_typing_annotation$BwaxAdmin.type_of(typed_ptn);
              var alpha = Lang_typing_base$BwaxAdmin.var_of(/* Any */0, Curry._1(next_int, /* () */0));
              Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                    param[1],
                    Lang_typing_base$BwaxAdmin.arrow(t, alpha)
                  ]);
              var new_typed_ptns = Pervasives.$at(param[2], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      typed_ptn,
                      /* [] */0
                    ]));
              return /* tuple */[
                      Pervasives.$at(param[0], match[1]),
                      alpha,
                      new_typed_ptns,
                      add(param[3], match[2])
                    ];
            };
            var match = Plate$BwaxAdmin.List.foldl(aggr, /* tuple */[
                  /* [] */0,
                  constr_t,
                  /* [] */0,
                  zero
                ], ptns);
            var final_ptn_000 = /* Ptn_constructor */Caml_chrome_debugger.variant("Ptn_constructor", 1, [
                name$1,
                match[2]
              ]);
            var final_ptn_001 = /* tuple */[
              info,
              match[1]
            ];
            var final_ptn = /* tuple */[
              final_ptn_000,
              final_ptn_001
            ];
            return /* tuple */[
                    final_ptn,
                    match[0],
                    match[3]
                  ];
          };
          var match = lookup_from_env(fixed_tenv, tenv, name$1);
          if (match !== undefined) {
            var match$1 = match;
            var t$3 = match$1[0];
            var match$2 = type_of_constructor(Lang_typing_instantiation$BwaxAdmin.instance(next_int, t$3));
            var stat = match$2[2];
            var final_stat = match$1[1] !== undefined ? add(stat, /* record */Caml_chrome_debugger.record(["external_names"], [Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            name$1,
                            t$3
                          ],
                          /* [] */0
                        ])])) : stat;
            return /* tuple */[
                    match$2[0],
                    match$2[1],
                    final_stat
                  ];
          } else {
            return Lang_typing_base$BwaxAdmin.fail_typing(info, "I cannot find `" + (String(name$1) + "` variant"));
          }
      case /* Ptn_tuple */2 :
          var aggr = function (param, p) {
            var match = make_ptn_type(next_int, dts, fixed_tenv, tenv, p);
            return /* tuple */[
                    Pervasives.$at(param[0], match[1]),
                    Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            match[0],
                            /* [] */0
                          ])),
                    add(param[2], match[2])
                  ];
          };
          var match$3 = Plate$BwaxAdmin.List.foldl(aggr, /* tuple */[
                /* [] */0,
                /* [] */0,
                zero
              ], p[0]);
          var typed_ptns = match$3[1];
          var t$4 = Lang_typing_base$BwaxAdmin.tuple(Plate$BwaxAdmin.List.map(Lang_typing_annotation$BwaxAdmin.type_of, typed_ptns));
          var final_ptn_000 = /* Ptn_tuple */Caml_chrome_debugger.variant("Ptn_tuple", 2, [typed_ptns]);
          var final_ptn_001 = /* tuple */[
            info,
            t$4
          ];
          var final_ptn = /* tuple */[
            final_ptn_000,
            final_ptn_001
          ];
          return /* tuple */[
                  final_ptn,
                  match$3[0],
                  match$3[2]
                ];
      case /* Ptn_literal */3 :
          var l = p[0];
          var t$5 = type_literal(next_int, l);
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_literal */Caml_chrome_debugger.variant("Ptn_literal", 3, [l]),
                    /* tuple */[
                      info,
                      t$5
                    ]
                  ],
                  /* [] */0,
                  zero
                ];
      case /* Ptn_cons_list */4 :
          var match$4 = make_ptn_type(next_int, dts, fixed_tenv, tenv, p[0]);
          var typed_ph = match$4[0];
          var match$5 = make_ptn_type(next_int, dts, fixed_tenv, tenv, p[1]);
          var typed_ptl = match$5[0];
          var t_h = Lang_typing_annotation$BwaxAdmin.type_of(typed_ph);
          var t$6 = Lang_typing_annotation$BwaxAdmin.type_of(typed_ptl);
          Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                Lang_typing_base$BwaxAdmin.list(t_h),
                t$6
              ]);
          var vts = Pervasives.$at(match$4[1], match$5[1]);
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_cons_list */Caml_chrome_debugger.variant("Ptn_cons_list", 4, [
                        typed_ph,
                        typed_ptl
                      ]),
                    /* tuple */[
                      info,
                      t$6
                    ]
                  ],
                  vts,
                  add(match$4[2], match$5[2])
                ];
      case /* Ptn_record */5 :
          var names = p[0];
          var vts$1 = Plate$BwaxAdmin.List.map((function (n) {
                  return /* tuple */[
                          n,
                          Lang_typing_base$BwaxAdmin.any(Curry._1(next_int, /* () */0))
                        ];
                }), names);
          var t$7 = Lang_typing_base$BwaxAdmin.recordvar(Curry._1(next_int, /* () */0), vts$1);
          return /* tuple */[
                  /* tuple */[
                    /* Ptn_record */Caml_chrome_debugger.variant("Ptn_record", 5, [names]),
                    /* tuple */[
                      info,
                      t$7
                    ]
                  ],
                  vts$1,
                  zero
                ];
      
    }
  }
}

function type_func(next_int, fixed_tenv, dts, maybe_module_name, maybe_expected_term, tenv, venv, params, body) {
  var iter = function (param, ptn) {
    var match = make_ptn_type(next_int, dts, fixed_tenv, tenv, ptn);
    var new_typed_ptns = Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
            match[0],
            /* [] */0
          ]));
    var var_env = Plate$BwaxAdmin.List.map((function (param) {
            return /* tuple */[
                    param[0],
                    /* Forall */Caml_chrome_debugger.simpleVariant("Forall", [
                        /* [] */0,
                        param[1]
                      ])
                  ];
          }), match[1]);
    var new_env = Plate$BwaxAdmin.List.foldr((function (param, acc) {
            var n = param[0];
            var match = Dict$BwaxAdmin.$$String.get(n, acc);
            if (match !== undefined) {
              return Lang_typing_base$BwaxAdmin.fail_typing(ptn[1], "`" + (String(n) + "` is used more than once"));
            } else {
              return Dict$BwaxAdmin.$$String.insert(n, /* tuple */[
                          param[1],
                          true,
                          undefined
                        ], acc);
            }
          }), var_env, param[0]);
    return /* tuple */[
            new_env,
            new_typed_ptns,
            add(param[2], match[2])
          ];
  };
  var match = Plate$BwaxAdmin.List.foldl(iter, /* tuple */[
        Dict$BwaxAdmin.$$String.empty(/* () */0),
        /* [] */0,
        zero
      ], params);
  var typed_params = match[1];
  var get_return_type = function (params_count, term) {
    var _count_down = params_count;
    var _current = Lang_typing_base$BwaxAdmin.shorten(term);
    while(true) {
      var current = _current;
      var count_down = _count_down;
      if (count_down === 0) {
        return current;
      } else if (typeof current === "number" || current.tag || current[0] !== "Arrow") {
        return ;
      } else {
        var match = current[1];
        if (match) {
          var match$1 = match[1];
          if (match$1 && !match$1[1]) {
            _current = match$1[0];
            _count_down = count_down - 1 | 0;
            continue ;
          } else {
            return ;
          }
        } else {
          return ;
        }
      }
    };
  };
  var iter$1 = function (_acc, _param) {
    while(true) {
      var param = _param;
      var acc = _acc;
      if (typeof param === "number" || param.tag || param[0] !== "Arrow") {
        return acc;
      } else {
        var match = param[1];
        if (match) {
          var match$1 = match[1];
          if (match$1 && !match$1[1]) {
            _param = match$1[0];
            _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                match[0],
                acc
              ]);
            continue ;
          } else {
            return acc;
          }
        } else {
          return acc;
        }
      }
    };
  };
  var possible_param_types = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (term) {
              return Plate$BwaxAdmin.List.rev(iter$1(/* [] */0, Lang_typing_base$BwaxAdmin.shorten(term)));
            }), maybe_expected_term));
  Plate$BwaxAdmin.List.for_each((function (param) {
          return Lang_typing_unification$BwaxAdmin.unify(next_int, dts, /* tuple */[
                      param[0],
                      param[1]
                    ]);
        }), Plate$BwaxAdmin.List.combine(Plate$BwaxAdmin.List.map(Lang_typing_annotation$BwaxAdmin.type_of, typed_params), possible_param_types));
  var new_env = Dict$BwaxAdmin.$$String.union(match[0], tenv);
  var tmp;
  if (maybe_expected_term !== undefined) {
    var rt = get_return_type(Plate$BwaxAdmin.List.length(params), maybe_expected_term);
    tmp = rt !== undefined ? set_expected_term(rt, empty_context) : empty_context;
  } else {
    tmp = empty_context;
  }
  var match$1 = type_expr(next_int, fixed_tenv, dts, maybe_module_name, tmp, new_env, venv, body);
  var typed_body = match$1[0];
  var ts = Plate$BwaxAdmin.List.map(Lang_typing_annotation$BwaxAdmin.type_of, typed_params);
  var t = Lang_typing_annotation$BwaxAdmin.type_of(typed_body);
  var final_t = Plate$BwaxAdmin.List.foldr(Lang_typing_base$BwaxAdmin.arrow, ts, t);
  return /* tuple */[
          typed_params,
          typed_body,
          final_t,
          add(match[2], match$1[1])
        ];
}

function type_variant(next_int, dts, maybe_module, venv, dt, vars_dict, ants) {
  var target_type = Lang_typing_base$BwaxAdmin.term_of_name(dts, maybe_module, dt[0], Plate$BwaxAdmin.List.map((function (param) {
              return param[1];
            }), vars_dict));
  var match = Plate$BwaxAdmin.List.length(ants) === 0 ? /* tuple */[
      target_type,
      /* [] */0
    ] : Plate$BwaxAdmin.List.foldr((function (ant, param) {
            var match = Lang_typing_annotation$BwaxAdmin.make_annot_term(next_int, dts, false, maybe_module, venv, ant);
            var tant = match[1];
            var vt = Lang_typing_annotation$BwaxAdmin.type_of(tant);
            return /* tuple */[
                    Lang_typing_base$BwaxAdmin.arrow(vt, param[0]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        tant,
                        param[1]
                      ])
                  ];
          }), ants, /* tuple */[
          target_type,
          /* [] */0
        ]);
  return /* tuple */[
          /* [] */0,
          match[0],
          match[1]
        ];
}

exports.subst_but = subst_but;
exports.subst_minus = subst_minus;
exports.print_env = print_env;
exports.type_literal = type_literal;
exports.Stat = Stat;
exports.lookup_from_env = lookup_from_env;
exports.is_tagger = is_tagger;
exports.get_tagger_final_type_name = get_tagger_final_type_name;
exports.empty_context = empty_context;
exports.add_applying_term = add_applying_term;
exports.set_expected_term = set_expected_term;
exports.desugar_action_statments = desugar_action_statments;
exports.type_expr = type_expr;
exports.type_defs = type_defs;
exports.get_names_from_ptn = get_names_from_ptn;
exports.make_ptn_type = make_ptn_type;
exports.type_func = type_func;
exports.type_variant = type_variant;
/* Dict-BwaxAdmin Not a pure module */
