import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import Cascader from 'rsuite/Cascader';

import { setUpSearchDefBuilder } from 'Client/js/ui/components/RecordSelectSearch'

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

function cascadingDef(cascadingPath, entity, id) {
    const selection = [...cascadingPath].reverse().reduce((acc, { field }) => {
        const { prefix, result } = acc;
        const path = prefix + "." + field.name;
        return {
            prefix: path,
            result: [
                `\${${path}.id}`,
                ...result,
            ]
        }

    }, { prefix: "data", result: [] })
    return {
        __query__: {
            data: {
                type: "findOne",
                entityName: entity.name,
                criteria: {
                    id
                }
            },
            __for__: selection.result
        }
    }
}

export default function RecordSelectCascader(props) {

    const {
        params,
        allEntities,
        allDataTypes,

        cascadingPath,
        entity,
        evaluate,

        value: passedInValue,
        onChange

    } = props;

    const {
        fixedValue,
        /// 
        fixedCriteria, //只用于第一级
        fieldsToReturn,
    } = params;

    const lastValue = useRef(passedInValue);

    const [options, setOptions] = useState([]);

    function resultToOptions(result, cascadingIndex, targetEntity, isLeaf) {

        const mockField = { type: "Link", options: { entity: targetEntity.key } }
        const fieldDisplay = getFieldDisplay(mockField);

        function renderValue(valueToUse) {
            if (valueToUse) {
                const Comp = fieldDisplay.component;
                return (
                    <Comp {...{
                        value: valueToUse, customParams: { withLink: false }, field: mockField,
                        env: { allEntities, allDataTypes }
                    }} />
                );
            } else {
                return null
            }
        }
        return (result || []).map(({ value, valueToUse }) => ({
            value,
            label: renderValue(valueToUse),
            cascadingIndex,
            isLeaf,
            ...(!isLeaf ? { children: [] } : {})
        }));
    }

    const [selected, setSelected] = useState(passedInValue && passedInValue.id);

    async function loadOptions(targetEntity, cascadingIndex, isLeaf, { criteria, sortBy }) {
        const buildSearchDef = setUpSearchDefBuilder(
            targetEntity,
            { allEntities, allDataTypes, fieldsToReturn, fixedCriteria: criteria, sortBy }
        );
        const targetDef = buildSearchDef("", 100);

        const { result } = await evaluate(targetDef, {});

        return resultToOptions(result, cascadingIndex, targetEntity, isLeaf)
    }

    // 初始化 value 和 选项
    useEffect(() => {
        const init = (async () => {
            // initialize with the passd-in value
            // 如果有 passed-in value，则根据 cascading path 查询所需的关联值

            const resolvedValue = await (async () => {
                if (!passedInValue || !passedInValue.id) {
                    return []
                }
                const { id } = passedInValue;

                const result = await evaluate(cascadingDef(cascadingPath, entity, id));
                return [...result, id]

            })()

            // 初始化第一级的选项；
            const { targetEntity } = cascadingPath[0]

            // 根据 value 找到所有涉及到的 options;
            const rootOptions = await loadOptions(targetEntity, 0, false, { fixedCriteria },);

            if (resolvedValue.length > 0) {
                let currentOptions = rootOptions;
                for (let i = 0; i < resolvedValue.length - 1; i++) {
                    // 
                    const targetEntity = cascadingPath[i + 1] ? cascadingPath[i + 1].targetEntity : entity;

                    
                    // find the current Option;
                    const currentOption = currentOptions.find(o => o.value == resolvedValue[i]);
                    // find the children of it:
                    if (currentOption) {
                        const { field } = cascadingPath[i];
                        const nextOptions = await loadOptions(
                            targetEntity,
                            i + 1,
                            (i == resolvedValue.length - 2), // is leaf
                            {
                                criteria: { [field.name]: currentOption.value },
                                sortBy: { "创建时间": "ASC" }
                            }
                        );
                        currentOption.children = nextOptions
                        currentOption.loading = false;
                        currentOptions = nextOptions;

                    } else {
                        console.warn("Cannot find current options", resolvedValue[i]);
                        break;
                    }
                }
            }

            ReactDOM.unstable_batchedUpdates(() => {
                setSelected(() => passedInValue && passedInValue.id)
                setOptions(() => rootOptions)
            })

        });

        if (passedInValue !== lastValue.current) {
            init();
        }

    }, [passedInValue,])

    const loadData = async (targetOption) => {

        const { cascadingIndex } = targetOption;

        // 继续 expand 
        const nextEntity = cascadingIndex + 1 == cascadingPath.length ? entity : cascadingPath[cascadingIndex + 1].targetEntity;

        const pathNode = cascadingPath[cascadingIndex];
        const isLeaf = cascadingIndex + 1 == cascadingPath.length;

        // mutable 改法：
        const children = await loadOptions(
            nextEntity,
            cascadingIndex + 1,
            isLeaf,
            {
                criteria: {
                    [pathNode.field.name]: targetOption.value
                },
                sortBy: {
                    创建时间: "ASC"
                }
            }
        )
        return children
    };

    return (
        <Cascader {...{
            searchable: false, style: { width: "100%" },
            data: options,
            value: selected,
            onChange: v => {  
                const nv = v ? { id : v } : v;  // or null

                lastValue.current = nv;
                onChange(nv);

            },
            getChildren: async item => {
                const children = await loadData(item);
                return children;
            }
        }} />
    )
}

