
import React from 'react';

import useQueriedValueWithoutState from 'bwax-ui/legacy/page/hooks/useQueriedValueWithoutState'

import ModalLink from 'Client/js/components/ModalLink'

import { themeColor } from 'bwax-ui/defaultTheme';
import lightOrDark from 'bwax-ui/color/lightOrDark';

import { untag } from 'bwax/lang/LangHelper'

import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';


import './OutdatedPageList.less';
import { Button } from 'antd';

// : 把所有的页面和片段都拿出来，把每个页面或者片段所依赖的 API，跟对应片段函数签名进行比对。
export default function OutdatedPagList(props) {
    const { allDataTypes, allEntities, queryTarget, loadingComp: Loading } = props;

    const dataDef = getDataDef();

    // state begin
    const {
        value: data,
        reload
    } = useQueriedValueWithoutState(dataDef, {
        allDataTypes,
        allEntities,
        baseData: {},
        roots: {
            "当前用户": {
                entityName: "用户"
            }
        },
        queryTarget,
    })


    if (!data) {
        return <Loading />
    }

    // console.log(data);
    const { pages, fragments } = data;

    // 1）收集所有 fragments 的 declared function signature.
    const allDeclaredSigsArr = fragments.reduce((acc, [_id, _name, previewData]) => {

        const taggedTyenv = typeof(previewData.tyenv) == "string" ? 
            JSON.parse(previewData.tyenv) : previewData.tyenv;

        const tyenv = untag(taggedTyenv);
        const declaredSigs = fragment_helper.get_tyenv_as_js(0, tyenv);

        return [
            ...acc,
            ...(declaredSigs || [])
        ]
    }, [])

    const allDeclaredSigs = allDeclaredSigsArr.reduce((acc, [name, sig]) => {
        return {
            ...acc,
            [name]: sig
        }
    }, {})

    const NOT_EXIST = "NOT_EXIST";
    const DIFERENT = "DIFERENT";

    // 2）逐一检查每个 fragments 和 pages 所用的 function，看是否存在，而且 signature 是否一致；
    function findIncompatible(values) {
        return values.map(p => {
            const [id, name, previewData] = p;
            const usedSigs = previewData && previewData.usedSigs || [];
            const incompatible = usedSigs.reduce((acc, [name, sig]) => {
                const declaredOne = allDeclaredSigs[name];
                if (declaredOne === undefined) {
                    return {
                        ...acc,
                        [name]: [NOT_EXIST]
                    }
                } else if (declaredOne != sig) {
                    return {
                        ...acc,
                        [name]: [DIFERENT, [sig, declaredOne]]
                    }
                } else {
                    return acc
                }
            }, {})

            if (Object.keys(incompatible).length > 0) {
                return {
                    id, name, incompatible
                }
            } else {
                return null
            }

        }).filter(x => !!x);
    }

    const incompatiblePages = findIncompatible(pages);
    const incompatibleFragments = findIncompatible(fragments);

    // console.log(incompatiblePages);

    // console.log(incompatibleFragments);

    // 这种有颜色的 tag 可以做成标准 widget。
    function tag(label, color) {
        const actualColor = themeColor(null, color);

        const fontColor = lightOrDark(actualColor) == "light" ? "inherit" : "white";

        return (
            <span style={{
                background: actualColor, color: fontColor, fontSize: 10,
                padding: "2px 4px", borderRadius: 2
            }}> { label}</span>
        )
    }

    function renderIncompatible(title, incompatibleList, buildLink) {

        if (incompatibleList.length === 0) {
            return null
        }

        function renderItem(name, incompatibleStatus) {
            const [status, details] = incompatibleStatus;

            if (status === NOT_EXIST) {
                return <><span className="fn-name">{name}</span>{tag("不存在", "R50")}</>
            } if (status === DIFERENT) {
                return (
                    <div style={{ display: "flex", flexDirection: "column"}}>
                        <div><span className="fn-name">{name}</span>{tag("不兼容", "Y50")}</div>
                        <div className="od-details">
                            <pre>
                                { details.join("\n")}
                            </pre>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        }

        return (
            <div key={title} className="od-container">
                <div className="od-title">{title} </div>
                <div className="od-content">
                    {
                        incompatibleList.map(({ id, name, incompatible }) => {
                            return (
                                <div className="od-row" key={id}>
                                    <div className="od-name">
                                        <ModalLink key={id} to={buildLink(id)}>{name}</ModalLink>
                                    </div>
                                    <div className="od-inc-list">
                                        {
                                            Object.keys(incompatible).map(name => {
                                                return (
                                                    <div className="od-item" key={name}>
                                                        { renderItem(name, incompatible[name])}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }


    return (
        <div className="outdated-page-list">
            <Button key="button" onClick={() => reload()} style={{width: "80px", alignSelf:"flex-end"}}>刷新</Button>
            { renderIncompatible("页面", incompatiblePages, id => "/page/" + id)}
            { renderIncompatible("页面片段", incompatibleFragments, id => "/page-fragment/" + id)}
        </div>
    )
}



//
function getDataDef() {

    const targetDef = {
        __query__: {
            pages: {
                entityName: "页面",
                type: 'listAll',
                pagination: {
                    pageSize: 1000
                },
                sortBy: {
                    // 创建时间: 'DESC'
                },
                criteria: {}
            },
            fragments: {
                entityName: "页面片段",
                type: 'listAll',
                pagination: {
                    pageSize: 1000
                },
                sortBy: {
                    // 创建时间: 'DESC'
                },
                criteria: {}
            },
            __for__: {
                pages: {
                    __apply__: {
                        list: "${pages}",
                        map: [
                            "${value.id}",
                            "${value.名称}",
                            `\${get(value.代码, "previewData")}`
                        ]
                    }
                },
                fragments: {
                    __apply__: {
                        list: "${fragments}",
                        map: [
                            "${value.id}",
                            "${value.名称}",
                            `\${get(value.代码, "previewData")}`
                        ]
                    }
                },
            }
        }
    }
    return targetDef;
}
