
import React from 'react'

import "./PageContent.less"

import BreadcrumbSimple from 'Client/js/breadcrumb/BreadcrumbSimple'

export default function PageContent(props) {

    const { params = {}, children, ...env } = props
    const { renderWidget } = env
    const { noPadding, noBackground, content, noBreadcrumb } = params

    const cn = `admin--page-content` +
        (noPadding ? ' no-padding' : '') +
        (noBackground ? ' no-background' : '')

    let breadcrumb = null;
    if(!noBreadcrumb) {
        breadcrumb = (
            <BreadcrumbSimple {...{
                ...env,
                pathname: env.location.pathname
            }} />
        )
    }

    return (
        <div className={cn}>
            { breadcrumb }
            <div className={`page-content-body`}>
                {/* "Placeholder" */}
                {renderWidget && content ? renderWidget(content) : children }
            </div>
        </div>
    )

}
