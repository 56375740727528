import React, { useEffect, useState, useContext } from 'react'

import './PageDesignFrame.less';

import ToolbarButton from 'Client/js/studioApp/editors/ToolbarButton';

import {
    IoPhonePortraitOutline, IoTabletPortraitOutline, IoLaptopOutline
} from "react-icons/io5";

import { BiUndo, BiRedo } from 'react-icons/bi';

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

import InputNumber from 'rsuite/InputNumber';
import Slider from 'rsuite/Slider';

import { Checkbox, Tag, Space, Typography } from '@arco-design/web-react';

import { DndProvider, DndContext } from 'react-dnd';
// import Frame, { FrameContext, FrameContextConsumer } from 'react-frame-component';

import Frame from './react-frame-component/Frame';
import { FrameContext, FrameContextConsumer } from './react-frame-component/Context';
// import { TouchBackend } from 'react-dnd-touch-backend'

import { HTML5Backend } from 'react-dnd-html5-backend';

import ClipLoader from "react-spinners/ClipLoader";

export default function PageDesignPanel(props) {

    const { 
        item, // this is the editing object.
        editor, 
        renderCanvas,
    } = props;

    // mobile, tablet, desktop, mobile-landscape
    const [mode, setMode] = useStateWithLocalCache("studio-page-canvas-mode-" + (item ? item.itemKey() : "sample"), "mobile");

    const supportedModes = {
        "mobile": {
            icon: <IoPhonePortraitOutline style={{ fontSize: 12 }} />,
            defaultWidth: 375
        },
        "tablet": {
            icon: <IoTabletPortraitOutline style={{ fontSize: 13 }} />,
            defaultWidth: 768
        },
        "desktop": {
            icon: <IoLaptopOutline style={{ fontSize: 16 }} />,
            defaultWidth: 1436
        },
        // "mobile-landscape": {
        //     icon: <IoPhoneLandscapeOutline />,
        //     defaultWidth: 844,
        //     fixedHeight: 390,
        // }
    }

    const [canvasWidth, setCanvasWidth] = useState(supportedModes[mode].defaultWidth)

    const [scale, setScale] = useState(100);

    function updateMode(mode) {
        setMode(mode);
        setCanvasWidth(supportedModes[mode].defaultWidth);
    }

    function renderIconButton(props) {
        return (
            <ToolbarButton {...props} />
        )
    }

    const [outlineShown, setOutlineShown] = useState(false);

    function renderOutlineSwicher() {
        return (
            <div style={{ display: "flex", alignItems: "center", opacity: 0.75}}>
                <Checkbox.Group onChange={items => {
                    if(items.length === 0) {
                        setOutlineShown(false)
                    } else {
                        setOutlineShown(true)
                    }
                }}>
                    <Checkbox key={"outline-shown"} value={"outline-shown"}>
                        {({ checked }) => {
                            return (
                                <Tag color={checked ? 'arcoblue' : ''} size={"small"}>
                                    显示边框
                                </Tag>
                            );
                        }}
                    </Checkbox>
                </Checkbox.Group>
            </div>
        )
    }

    return (
        <div className="page-design-frame">
            <div className="canvas-tool-bar">
                {
                    Object.keys(supportedModes).map(m => renderIconButton({
                        key: m,
                        icon: supportedModes[m].icon,
                        active: m == mode,
                        onPress: _ => updateMode(m)
                    }))
                }
                <div className="input-box">
                    <InputNumber postfix={"px"} size="xs" onChange={n => setCanvasWidth(n)} value={canvasWidth}></InputNumber>
                </div>
                {/* <div className="input-box scale-input">
                    <Slider style={{ width: 80 }} value={scale} onChange={setScale} min={50} max={150} />
                    <InputNumber postfix={"%"} size="xs" onChange={n => {
                        const v = typeof (n) === "string" ? parseInt(n) : n
                        if (!isNaN(v)) {
                            setScale(v);
                        }
                    }} value={scale} min={50} max={150} step={1}></InputNumber>
                </div> */}
                {renderOutlineSwicher()}
                {renderIconButton({ icon: <BiUndo />, disabled: !editor.canUndo(), onPress: () => editor.undo() })}
                {renderIconButton({ icon: <BiRedo />, disabled: !editor.canRedo(), onPress: () => editor.redo() })}
            </div>
            <div className="canvas-main">
                <div className="screen-wrapper" style={{
                    transform: `scale(${scale / 100.0})`
                }}>
                    <div className="canvas-screen" style={{
                        width: canvasWidth,
                        height: supportedModes[mode].fixedHeight,
                        userSelect: "none"
                    }}>
                        <ContentFrame className="canvas-frame">
                            <FrameContextConsumer>
                                {
                                    ({ document }) => {
                                        return renderCanvas({ doc: document, outlineShown })
                                    }
                                }
                            </FrameContextConsumer>
                        </ContentFrame>
                    </div>
                </div>
            </div>
        </div>
    )
}

// 
function ContentFrame({ children, ...props }) {


    const [initialContent, setInitialContent] = useState(null);

    useEffect(() => {

        const links = document.querySelectorAll('head > link');

        const prefix = document.location.protocol + "//" + document.location.hostname
            + (document.location.port ? ":" + document.location.port : "");

        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        // find app-XXX.css
        const cssNamePtn = new RegExp(escapeRegExp(prefix) + "(/app(-\\w+)?\\.css)");

        const linksToInclude = Array.from(links).map(l => {

            const matched = l.href.match(cssNamePtn);
            if (matched && matched[1]) {
                return `<link rel="stylesheet" href="${matched[1]}">`
            }
            return (new RegExp(cssNamePtn)).test(l.href)
        }).filter(x => !!x).join("");
        let headText = linksToInclude;
        // headText = document.head.innerHTML

        setInitialContent(`<!DOCTYPE html><html><head>${headText}</head><body></html>`);


    }, []);

    const loading = (
        <div className="lc-loading-container">
            <ClipLoader />
        </div>
    )

    if (!initialContent) {
        return loading
    }

    return (
        <DndProvider backend={HTML5Backend} options={{
            // enableMouseEvents: true
        }}>
            <Frame {...props} initialContent={initialContent} mountTarget="body" loading={loading}>
                <DndFrame>
                    {children}
                </DndFrame>
            </Frame>
        </DndProvider>
    )

}



const DndFrame = ({ children }) => {
    const { dragDropManager } = useContext(DndContext);
    const { window } = useContext(FrameContext);

    useEffect(() => {
        dragDropManager.getBackend().addEventListeners(window);
    });

    return children;
};
