
import React from 'react'

import { RiFileCopyLine, RiDeleteBin7Line } from 'react-icons/ri'

import 'ActiveToolBar.less';

export default function ActiveToolBar({ barsAtBottom, barsOnLeft, selectedBlock, model, executeCommand }) {

    function ActionButton({ onClick, children, style }) {
        return (
            <div className="action-button" style={style} onClick={onClick}>
                {children}
            </div>
        )
    }

    function removeBlock(blockId) {
        executeCommand("deleteBlock", {
            blockId
        })
    }

    function duplicateBlock(blockId) {
        executeCommand("duplicateBlock", { blockId });
    }



    const parents = selectedBlock.getAncestors(model.contentDict);  // 

    function displayBlockTitle (block) {
        const { blockType } = block;
        return blockType.label;
    }

    return (
        <div className={
            "active-tools-bar" + (barsAtBottom ? " bar-at-bottom" : " bar-on-top")
            + (barsOnLeft ? " bar-on-left" : " bar-on-right")
        }>
            <div className="action-button-group" style={{ padding: 0 }}>
                <div className="dropdown-wrapper">
                    <div className="dropdown-trigger-button">
                        {displayBlockTitle(selectedBlock)}
                    </div>
                    <div className="dropdown-container">
                        {
                            parents.map(p => (
                                <div className="dropdown-button" onClick={() => executeCommand("selectBlock", { blockId: p.id })} key={p.id}>
                                    {displayBlockTitle(p)}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="action-button-group">
                <ActionButton onClick={_ => duplicateBlock(model.selected)}><RiFileCopyLine /></ActionButton>
                <ActionButton onClick={_ => removeBlock(model.selected)}><RiDeleteBin7Line /></ActionButton>
            </div>
        </div>
    )
}
