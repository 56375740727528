

import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery'
import QueryDataEntities from 'bwax/query/static/QueryDataEntities'

export default function loadDefinitions(queryTarget = "data", sandboxAlways = false) {
    return async ({tenantCode, endpoints, sessionToken, sandbox = false}) => {

        const runner = queryTarget === "data" ? runDataQuery : runDefinitionQuery;

        const result = await runner({
            tenantCode, endpoints, sessionToken, 
            sandbox: (sandboxAlways ? true: sandbox)
        })(QueryDataEntities.text)()

        const data = QueryDataEntities.extractResult(result)
        /// TODO error handling:

        const { dataEntities, extensions, dataTypes, mainTheme, lookupValueSettings } = data

        const theme = mainTheme && mainTheme.theme ? yaml.safeLoad(mainTheme.theme) : {} 
        // TODO 需要更为保守的错误处理

        return {
            dataEntities, extensions, dataTypes, theme, lookupValueSettings
        }
    }
}


// for the reason ml:
export function loadDataDefinitions(env) {
    return loadDefinitions("data", false)(env)
}

export function getEntitiesAndDataTypes(definitions) {
    const { extensions, adminPages } = definitions

    const allEntities = extensions.reduce((acc, c) => {
        if(c.name === '设计') {
            return acc
        }
        return [...acc, ...(c.entities || [])]
    }, [])

    const entities = extensions.reduce((acc, c) => {
        if(c.name == '自定义') {
            return [...acc, ...(c.entities || [])]
        }
        return acc;
    }, [])

    const lookupValueSettings = extensions.reduce((acc, c) => {
        if(c.name == '自定义') {
            return [...acc, ...(c.lookupValueSettings || [])]
        }
        return acc;
    }, [])

    // const allEntities = [
    //     ...allExtensionEntities.filter(e => {
    //         /// 在 dataEntities 没有被 extend 的
    //         return !dataEntities.some(de => de.extending == true && de.name == e.name)
    //     })
    // ]

    const allDataTypes = extensions.reduce((acc, c) => {
        if(c.key === 'Design') {
            return acc
        }

        return [...acc, ...(c.dataTypes || [])]
    }, [])

    return { entities, allEntities, allDataTypes, lookupValueSettings, adminPages }
}
