// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("./mod/runtime_common.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");
var Lang_typing_print$BwaxAdmin = require("./lang_typing_print.bs.js");

function tree_to_paths(tree) {
  var match = Js_json.classify(tree);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return ;
  } else {
    return Plate$BwaxAdmin.List.foldl((function (acc, param) {
                  var k = param[0];
                  var suffix = tree_to_paths(param[1]);
                  if (suffix !== undefined) {
                    var l = suffix;
                    if (Plate$BwaxAdmin.List.length(l) > 0) {
                      return Pervasives.$at(acc, Plate$BwaxAdmin.List.map((function (s) {
                                        return k + ("." + s);
                                      }), l));
                    } else {
                      return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    k,
                                    /* [] */0
                                  ]));
                    }
                  } else {
                    return Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  k,
                                  /* [] */0
                                ]));
                  }
                }), /* [] */0, Curry._1(Plate$BwaxAdmin.List.from_array, Js_dict.entries(match[0])));
  }
}

function get_entity_and_subset(entity_dict, _data_type_dict, dts, t) {
  var process_subset = function (target_tname, subset, required, multivalued) {
    return Plate$BwaxAdmin.$$Option.map((function (param) {
                  return /* Entity_subset */Caml_chrome_debugger.variant("Entity_subset", 0, [
                            param[1],
                            subset,
                            /* record */Caml_chrome_debugger.record([
                                "required",
                                "multivalued"
                              ], [
                                required,
                                multivalued
                              ])
                          ]);
                }), Plate$BwaxAdmin.List.find((function (param) {
                      var n = param[0];
                      if (Base_query_types$BwaxAdmin.plain_entity_tname_of(n) === target_tname) {
                        return true;
                      } else {
                        return Base_query_types$BwaxAdmin.tname_of(n) === target_tname;
                      }
                    }), entity_dict));
  };
  var _t = t;
  var _required = true;
  var _multivalued = false;
  while(true) {
    var multivalued = _multivalued;
    var required = _required;
    var t$1 = _t;
    var match = Lang_typing_base$BwaxAdmin.shorten(t$1);
    if (typeof match !== "number") {
      switch (match.tag | 0) {
        case /* Term */0 :
            var tname = match[0];
            var exit = 0;
            switch (tname) {
              case "List" :
                  var match$1 = match[1];
                  if (match$1) {
                    if (!match$1[1]) {
                      _multivalued = true;
                      _t = match$1[0];
                      continue ;
                    }
                    
                  } else {
                    exit = 2;
                  }
                  break;
              case "Maybe" :
                  var match$2 = match[1];
                  if (match$2) {
                    if (!match$2[1]) {
                      _required = false;
                      _t = match$2[0];
                      continue ;
                    }
                    
                  } else {
                    exit = 2;
                  }
                  break;
              default:
                exit = 2;
            }
            if (exit === 2 && !match[1]) {
              var tname$1 = tname;
              var required$1 = required;
              var multivalued$1 = multivalued;
              var match$3 = Plate$BwaxAdmin.List.assoc(tname$1, dts);
              if (match$3 !== undefined) {
                var match$4 = match$3;
                if (typeof match$4 !== "number" && match$4.tag === /* Alias */1) {
                  var match$5 = Lang_typing_base$BwaxAdmin.shorten(match$4[0][1]);
                  var exit$1 = 0;
                  if (typeof match$5 === "number" || match$5.tag !== /* Var */2) {
                    exit$1 = 2;
                  } else {
                    var match$6 = match$5[0][/* var_type */0];
                    if (typeof match$6 === "number" || match$6.tag !== /* Record_readonly */1) {
                      exit$1 = 2;
                    } else {
                      return process_subset(match$6[0], match$6[1], required$1, multivalued$1);
                    }
                  }
                  if (exit$1 === 2) {
                    return /* T_name */Caml_chrome_debugger.variant("T_name", 2, [
                              tname$1,
                              /* record */Caml_chrome_debugger.record([
                                  "required",
                                  "multivalued"
                                ], [
                                  required$1,
                                  multivalued$1
                                ])
                            ]);
                  }
                  
                }
                
              }
              return /* T_name */Caml_chrome_debugger.variant("T_name", 2, [
                        tname$1,
                        /* record */Caml_chrome_debugger.record([
                            "required",
                            "multivalued"
                          ], [
                            required$1,
                            multivalued$1
                          ])
                      ]);
            }
            break;
        case /* Var */2 :
            var match$7 = match[0][/* var_type */0];
            if (typeof match$7 !== "number") {
              switch (match$7.tag | 0) {
                case /* Recordvar */0 :
                    return /* Any_subset */Caml_chrome_debugger.variant("Any_subset", 1, [
                              match$7[0],
                              /* record */Caml_chrome_debugger.record([
                                  "required",
                                  "multivalued"
                                ], [
                                  required,
                                  multivalued
                                ])
                            ]);
                case /* Record_readonly */1 :
                    return process_subset(match$7[0], match$7[1], required, multivalued);
                case /* Any_record_readonly */6 :
                    return /* Any_subset */Caml_chrome_debugger.variant("Any_subset", 1, [
                              match$7[1],
                              /* record */Caml_chrome_debugger.record([
                                  "required",
                                  "multivalued"
                                ], [
                                  required,
                                  multivalued
                                ])
                            ]);
                default:
                  
              }
            }
            break;
        default:
          
      }
    }
    console.log("Not handled", Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, t$1));
    return ;
  };
}

function convert_atom_dtype_to_term(dtype) {
  if (typeof dtype === "number") {
    switch (dtype) {
      case /* Atom_int */0 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Int",
                    /* [] */0
                  ]);
      case /* Atom_float */1 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Float",
                    /* [] */0
                  ]);
      case /* Atom_bool */2 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Bool",
                    /* [] */0
                  ]);
      case /* Atom_string */3 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "String",
                    /* [] */0
                  ]);
      case /* Atom_date */4 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Date",
                    /* [] */0
                  ]);
      case /* Atom_json */5 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "JSON",
                    /* [] */0
                  ]);
      case /* Atom_richtext */6 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "RichText",
                    /* [] */0
                  ]);
      case /* Atom_image */7 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Image",
                    /* [] */0
                  ]);
      case /* Atom_file */8 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "File",
                    /* [] */0
                  ]);
      case /* Atom_color */9 :
          return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                    "Color",
                    /* [] */0
                  ]);
      
    }
  } else if (dtype.tag) {
    return ;
  } else {
    return /* Term */Caml_chrome_debugger.variant("Term", 0, [
              "String",
              /* [] */0
            ]);
  }
}

function traverse(entity_dict, data_type_dict, dts, expand_image_or_file, current_field, f, g, init_value, fields, binds) {
  var do_trav = function (param, param$1, param$2, param$3, param$4, param$5) {
    return traverse(entity_dict, data_type_dict, dts, expand_image_or_file, param, param$1, param$2, param$3, param$4, param$5);
  };
  var expand_all_materialized_field_of_datatype = function (dtname) {
    var data_type = Base_query_types$BwaxAdmin.get_data_type(data_type_dict, dtname);
    var match = data_type[/* trait */2];
    if (typeof match === "number" || match.tag !== /* C_record */1) {
      return /* tuple */[
              /* [] */0,
              /* [] */0
            ];
    } else {
      var the_fields = Pervasives.$at(match[0], match[1]);
      var binds = Plate$BwaxAdmin.List.keep_map((function (f) {
              var k = Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]);
              return Plate$BwaxAdmin.$$Option.map((function (t) {
                            return /* tuple */[
                                    k,
                                    t
                                  ];
                          }), convert_atom_dtype_to_term(f[/* dtype */2]));
            }), the_fields);
      return /* tuple */[
              the_fields,
              binds
            ];
    }
  };
  var at_least_id = function (param) {
    if (Plate$BwaxAdmin.List.length(binds) > 0) {
      return binds;
    } else {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "id",
                  /* Term */Caml_chrome_debugger.variant("Term", 0, [
                      "String",
                      /* [] */0
                    ])
                ],
                binds
              ]);
    }
  };
  var match = Plate$BwaxAdmin.$$Option.map((function (f) {
          return f[/* dtype */2];
        }), current_field);
  var enhanced;
  if (match !== undefined) {
    if (typeof match === "number") {
      enhanced = binds;
    } else {
      switch (match.tag | 0) {
        case /* Entity_link */1 :
        case /* Entity_link_opaque */2 :
            enhanced = at_least_id(/* () */0);
            break;
        default:
          enhanced = binds;
      }
    }
  } else {
    enhanced = at_least_id(/* () */0);
  }
  return Curry._1(g, Plate$BwaxAdmin.List.map((function (param) {
                    var k = param[0];
                    var maybe_field = Plate$BwaxAdmin.List.find((function (f) {
                            return k === Base_query_types$BwaxAdmin.normalize_field_name(f[/* name */0]);
                          }), fields);
                    var _t = param[1];
                    while(true) {
                      var t = _t;
                      var match = Lang_typing_base$BwaxAdmin.shorten(t);
                      var inner_binds;
                      if (typeof match === "number") {
                        return Curry._3(f, maybe_field, k, init_value);
                      } else {
                        switch (match.tag | 0) {
                          case /* Term */0 :
                              var exit = 0;
                              switch (match[0]) {
                                case "Criteria" :
                                    return Curry._3(f, maybe_field, k, init_value);
                                case "File" :
                                    if (match[1]) {
                                      exit = 2;
                                    } else if (expand_image_or_file) {
                                      var match$1 = expand_all_materialized_field_of_datatype("文件");
                                      return Curry._3(f, maybe_field, k, do_trav(maybe_field, f, g, init_value, match$1[0], match$1[1]));
                                    } else {
                                      return Curry._3(f, maybe_field, k, do_trav(maybe_field, f, g, init_value, /* [] */0, /* [] */0));
                                    }
                                    break;
                                case "Image" :
                                    if (match[1]) {
                                      exit = 2;
                                    } else if (expand_image_or_file) {
                                      var match$2 = expand_all_materialized_field_of_datatype("图片");
                                      return Curry._3(f, maybe_field, k, do_trav(maybe_field, f, g, init_value, match$2[0], match$2[1]));
                                    } else {
                                      return Curry._3(f, maybe_field, k, do_trav(maybe_field, f, g, init_value, /* [] */0, /* [] */0));
                                    }
                                    break;
                                default:
                                  exit = 2;
                              }
                              if (exit === 2) {
                                var match$3 = match[1];
                                if (match$3 && !match$3[1]) {
                                  _t = match$3[0];
                                  continue ;
                                } else {
                                  return Curry._3(f, maybe_field, k, init_value);
                                }
                              }
                              break;
                          case /* Var */2 :
                              var match$4 = match[0][/* var_type */0];
                              if (typeof match$4 === "number") {
                                return Curry._3(f, maybe_field, k, init_value);
                              } else {
                                switch (match$4.tag | 0) {
                                  case /* Recordvar */0 :
                                      inner_binds = match$4[0];
                                      break;
                                  case /* Record_readonly */1 :
                                      inner_binds = match$4[1];
                                      break;
                                  default:
                                    return Curry._3(f, maybe_field, k, init_value);
                                }
                              }
                              break;
                          default:
                            return Curry._3(f, maybe_field, k, init_value);
                        }
                      }
                      var next_fields = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (f) {
                                  var match = f[/* dtype */2];
                                  if (typeof match === "number") {
                                    return /* [] */0;
                                  } else {
                                    switch (match.tag | 0) {
                                      case /* Entity_link */1 :
                                          var entity = Base_query_types$BwaxAdmin.get_entity(entity_dict, match[0]);
                                          return Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
                                      case /* Custom_type */3 :
                                          var data_type = Base_query_types$BwaxAdmin.get_data_type(data_type_dict, match[0]);
                                          var match$1 = data_type[/* trait */2];
                                          if (typeof match$1 === "number" || match$1.tag !== /* C_record */1) {
                                            return /* [] */0;
                                          } else {
                                            return Pervasives.$at(match$1[0], match$1[1]);
                                          }
                                      default:
                                        return /* [] */0;
                                    }
                                  }
                                }), maybe_field));
                      return Curry._3(f, maybe_field, k, do_trav(maybe_field, f, g, init_value, next_fields, inner_binds));
                    };
                  }), enhanced));
}

function to_json(entity_dict, data_type_dict, dts) {
  var f = function (maybe_field, k, json) {
    if (maybe_field !== undefined) {
      return /* tuple */[
              maybe_field[/* name */0],
              json
            ];
    } else {
      return /* tuple */[
              k,
              json
            ];
    }
  };
  var g = function (param) {
    return Plate$BwaxAdmin.$at$great(Js_dict.fromList, (function (prim) {
                  return prim;
                }), param);
  };
  return (function (param, param$1) {
      return traverse(entity_dict, data_type_dict, dts, false, undefined, f, g, true, param, param$1);
    });
}

function to_selection(entity_dict, data_type_dict, dts) {
  var f = function (maybe_field, k, str) {
    var append = function (p) {
      if (str === "") {
        return p;
      } else {
        return p + (" " + str);
      }
    };
    if (maybe_field !== undefined) {
      return append(maybe_field[/* key */1]);
    } else {
      return append(k);
    }
  };
  var g = function (lines) {
    return "{ " + (Plate$BwaxAdmin.Str.join(" ", lines) + " }");
  };
  return (function (param, param$1) {
      return traverse(entity_dict, data_type_dict, dts, true, undefined, f, g, "", param, param$1);
    });
}

function make_result(entity_dict, data_type_dict, dts, param) {
  var subset = param[1];
  var e = param[0];
  var all_fields = Pervasives.$at(e[/* fields */2], e[/* virtual_fields */3]);
  var tree = to_json(entity_dict, data_type_dict, dts)(all_fields, subset);
  var paths = Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.to_array, tree_to_paths(tree));
  var selection_text = to_selection(entity_dict, data_type_dict, dts)(all_fields, subset);
  return /* tuple */[
          tree,
          paths,
          selection_text,
          subset
        ];
}

function get_func_t(fname, defs) {
  return Plate$BwaxAdmin.List.head(Plate$BwaxAdmin.List.keep_map((function (param) {
                    var match = param[0];
                    if (match.tag === /* Def_fun */1) {
                      var match$1 = param[1];
                      if (typeof match$1 === "number" || match$1.tag) {
                        return ;
                      } else {
                        var match$2 = match$1[1];
                        if (match$2 && !(match$2[1] || match[0] !== fname)) {
                          return match$2[0];
                        } else {
                          return ;
                        }
                      }
                    }
                    
                  }), defs));
}

function get_init_t(defs) {
  return get_func_t("init", defs);
}

function is_empty_record_dep(param) {
  return param[3] === /* [] */0;
}

function dep_as_js(maybe_dep) {
  return Plate$BwaxAdmin.$$Option.map((function (param) {
                if (param.tag) {
                  var match = param[1];
                  return {
                          tag: "Data",
                          dataTypeName: param[0],
                          required: match[/* required */0],
                          multivalued: match[/* multivalued */1]
                        };
                } else {
                  var match$1 = param[2];
                  return {
                          tag: "Record",
                          entityName: param[0],
                          dep: param[1],
                          required: match$1[/* required */0],
                          multivalued: match$1[/* multivalued */1]
                        };
                }
              }), maybe_dep);
}

function is_old_event_handler(param) {
  var maybe_init_t = get_func_t("init", param[1]);
  return Plate$BwaxAdmin.$$Option.with_default(true, Plate$BwaxAdmin.$$Option.map((function (param) {
                    if (typeof param === "number" || param.tag || param[0] !== "Arrow") {
                      return true;
                    } else {
                      var match = param[1];
                      if (match) {
                        var match$1 = match[1];
                        if (match$1) {
                          var match$2 = match$1[0];
                          if (typeof match$2 === "number" || match$2.tag || match$2[0] !== "Arrow") {
                            return true;
                          } else {
                            var match$3 = match$2[1];
                            if (match$3) {
                              var match$4 = match$3[1];
                              if (match$4 && !(match$4[1] || match$1[1])) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          }
                        } else {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    }
                  }), maybe_init_t));
}

function determine_updated_event_dependencies(entity_dict, data_type_dict, dts, param) {
  var maybe_init_t = get_func_t("init", param[1]);
  var entity_and_subset = Plate$BwaxAdmin.$$Option.and_then((function (param) {
          if (typeof param === "number" || param.tag) {
            return ;
          } else {
            var match = param[1];
            if (match) {
              var match$1 = match[0];
              if (typeof match$1 === "number" || match$1.tag) {
                return ;
              } else {
                var match$2 = match$1[1];
                if (match$2) {
                  var match$3 = match$2[1];
                  if (match$3 && !match$3[1]) {
                    var match$4 = match[1];
                    if (match$4 && !(match$4[1] || match$1[0] !== Lang_typing_base$BwaxAdmin.tuple_name)) {
                      var param$1 = match$2[0];
                      return get_entity_and_subset(entity_dict, data_type_dict, dts, param$1);
                    } else {
                      return ;
                    }
                  } else {
                    return ;
                  }
                } else {
                  return ;
                }
              }
            } else {
              return ;
            }
          }
        }), maybe_init_t);
  var as_result = function (param) {
    switch (param.tag | 0) {
      case /* Entity_subset */0 :
          var entity = param[0];
          var record_dep = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity,
                param[1]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity[/* name */0],
                    record_dep,
                    param[2]
                  ]);
      case /* Any_subset */1 :
          throw [
                Runtime_common$BwaxAdmin.Runtime_exn,
                "Not supported"
              ];
      case /* T_name */2 :
          return /* Data_dep */Caml_chrome_debugger.variant("Data_dep", 1, [
                    param[0],
                    param[1]
                  ]);
      
    }
  };
  return dep_as_js(Plate$BwaxAdmin.$$Option.map(as_result, entity_and_subset));
}

function get_all_parameter_types(_acc, _t) {
  while(true) {
    var t = _t;
    var acc = _acc;
    if (typeof t === "number" || t.tag) {
      return acc;
    } else {
      var match = t[1];
      if (match) {
        var match$1 = match[1];
        if (match$1 && !(match$1[1] || t[0] !== Lang_typing_base$BwaxAdmin.arrow_name)) {
          _t = match$1[0];
          _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              match[0],
              acc
            ]);
          continue ;
        } else {
          return acc;
        }
      } else {
        return acc;
      }
    }
  };
}

function determine_init_dependencies(entity_dict, data_type_dict, dts, param) {
  var maybe_init_t = get_func_t("init", param[1]);
  var get_subset = function (t) {
    return get_entity_and_subset(entity_dict, data_type_dict, dts, t);
  };
  var as_result = function (param) {
    switch (param.tag | 0) {
      case /* Entity_subset */0 :
          var entity = param[0];
          var record_dep = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity,
                param[1]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity[/* name */0],
                    record_dep,
                    param[2]
                  ]);
      case /* Any_subset */1 :
          throw [
                Runtime_common$BwaxAdmin.Runtime_exn,
                "Not supported"
              ];
      case /* T_name */2 :
          return /* Data_dep */Caml_chrome_debugger.variant("Data_dep", 1, [
                    param[0],
                    param[1]
                  ]);
      
    }
  };
  var get_subset_as_result = function (t) {
    return Plate$BwaxAdmin.$$Option.map(as_result, get_subset(t));
  };
  var extract_entity_and_subset = function (t) {
    var dep_t;
    if (typeof t === "number" || t.tag || t[0] !== "Arrow") {
      return /* [] */0;
    } else {
      var match = t[1];
      if (match) {
        var match$1 = match[1];
        if (match$1) {
          var match$2 = match$1[0];
          var exit = 0;
          if (typeof match$2 === "number" || match$2.tag || match$2[0] !== "Arrow") {
            exit = 2;
          } else {
            var match$3 = match$2[1];
            if (match$3) {
              var match$4 = match$3[1];
              if (match$4 && !match$4[1]) {
                if (match$1[1]) {
                  return /* [] */0;
                } else {
                  dep_t = match$3[0];
                }
              } else {
                exit = 2;
              }
            } else {
              exit = 2;
            }
          }
          if (exit === 2) {
            if (match$1[1]) {
              return /* [] */0;
            } else {
              dep_t = match[0];
            }
          }
          
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    }
    if (typeof dep_t === "number" || dep_t.tag || dep_t[0] !== "Tuple") {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                get_subset_as_result(dep_t),
                /* [] */0
              ]);
    } else {
      return Plate$BwaxAdmin.List.map(get_subset_as_result, dep_t[1]);
    }
  };
  return Plate$BwaxAdmin.$$Option.map(extract_entity_and_subset, maybe_init_t);
}

function determine_view_dependencies(entity_dict, data_type_dict, dts, param) {
  var maybe_view_t = get_func_t("view", param[1]);
  var get_subset = function (t) {
    return get_entity_and_subset(entity_dict, data_type_dict, dts, t);
  };
  var as_result = function (param) {
    switch (param.tag | 0) {
      case /* Entity_subset */0 :
          var entity = param[0];
          var record_dep = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity,
                param[1]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity[/* name */0],
                    record_dep,
                    param[2]
                  ]);
      case /* Any_subset */1 :
          throw [
                Runtime_common$BwaxAdmin.Runtime_exn,
                "Not supported"
              ];
      case /* T_name */2 :
          return /* Data_dep */Caml_chrome_debugger.variant("Data_dep", 1, [
                    param[0],
                    param[1]
                  ]);
      
    }
  };
  var get_subset_as_result = function (t) {
    return Plate$BwaxAdmin.$$Option.map(as_result, get_subset(t));
  };
  var extract_entity_and_subset = function (t) {
    if (typeof t === "number" || t.tag || t[0] !== "Arrow") {
      return /* [] */0;
    } else {
      var match = t[1];
      if (match) {
        var match$1 = match[1];
        if (match$1 && !match$1[1]) {
          var dep_t = match[0];
          if (typeof dep_t === "number" || dep_t.tag || dep_t[0] !== "Tuple") {
            return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      get_subset_as_result(dep_t),
                      /* [] */0
                    ]);
          } else {
            return Plate$BwaxAdmin.List.map(get_subset_as_result, dep_t[1]);
          }
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    }
  };
  return Plate$BwaxAdmin.$$Option.map(extract_entity_and_subset, maybe_view_t);
}

function determine_init_dependencies_as_js(entity_dict, data_type_dict, dts, param) {
  var deps = determine_init_dependencies(entity_dict, data_type_dict, dts, /* tuple */[
        param[0],
        param[1]
      ]);
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(dep_as_js, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, deps)));
}

function determine_view_dependencies_as_js(entity_dict, data_type_dict, dts, param) {
  var deps = determine_view_dependencies(entity_dict, data_type_dict, dts, /* tuple */[
        param[0],
        param[1]
      ]);
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(dep_as_js, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, deps)));
}

function determine_function_dependencies(entity_dict, data_type_dict, dts, entity_name, typed_val) {
  var all_pts = get_all_parameter_types(/* [] */0, typed_val[1]);
  var get_subset = function (param) {
    return get_entity_and_subset(entity_dict, data_type_dict, dts, param);
  };
  var as_result = function (param) {
    switch (param.tag | 0) {
      case /* Entity_subset */0 :
          var entity = param[0];
          var record_dep = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity,
                param[1]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity[/* name */0],
                    record_dep,
                    param[2]
                  ]);
      case /* Any_subset */1 :
          var entity$1 = Base_query_types$BwaxAdmin.get_entity(entity_dict, entity_name);
          var record_dep$1 = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity$1,
                param[0]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity$1[/* name */0],
                    record_dep$1,
                    param[1]
                  ]);
      case /* T_name */2 :
          return /* Data_dep */Caml_chrome_debugger.variant("Data_dep", 1, [
                    param[0],
                    param[1]
                  ]);
      
    }
  };
  var get_subset_as_result = function (t) {
    return Plate$BwaxAdmin.$$Option.map(as_result, get_subset(t));
  };
  return Plate$BwaxAdmin.List.map(get_subset_as_result, all_pts);
}

function determine_cmdm_processor_dependencies(entity_dict, data_type_dict, dts, typed_val) {
  var t = typed_val[1];
  var all_pts;
  if (typeof t === "number" || t.tag || t[0] !== "Arrow") {
    all_pts = /* [] */0;
  } else {
    var match = t[1];
    if (match) {
      var p = match[0];
      var exit = 0;
      if (typeof p === "number" || p.tag || p[0] !== "Tuple") {
        exit = 1;
      } else {
        var match$1 = match[1];
        all_pts = match$1 && !match$1[1] ? p[1] : /* [] */0;
      }
      if (exit === 1) {
        var match$2 = match[1];
        all_pts = match$2 && !match$2[1] ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
              p,
              /* [] */0
            ]) : /* [] */0;
      }
      
    } else {
      all_pts = /* [] */0;
    }
  }
  var get_subset = function (param) {
    return get_entity_and_subset(entity_dict, data_type_dict, dts, param);
  };
  var as_result = function (param) {
    switch (param.tag | 0) {
      case /* Entity_subset */0 :
          var entity = param[0];
          var record_dep = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity,
                param[1]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity[/* name */0],
                    record_dep,
                    param[2]
                  ]);
      case /* Any_subset */1 :
          console.log("No handle any usbset", param[0]);
          return ;
      case /* T_name */2 :
          return /* Data_dep */Caml_chrome_debugger.variant("Data_dep", 1, [
                    param[0],
                    param[1]
                  ]);
      
    }
  };
  var get_subset_as_result = function (t) {
    return Plate$BwaxAdmin.$$Option.and_then(as_result, get_subset(t));
  };
  return Plate$BwaxAdmin.List.map(get_subset_as_result, all_pts);
}

function determine_sequence_cmdm_processor_dependencies(entity_dict, data_type_dict, dts, typed_val) {
  var t = typed_val[1];
  var all_pts;
  if (typeof t === "number" || t.tag || t[0] !== "Arrow") {
    all_pts = /* [] */0;
  } else {
    var match = t[1];
    if (match) {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag || match$1[0] !== "List") {
        all_pts = /* [] */0;
      } else {
        var match$2 = match$1[1];
        if (match$2) {
          var p = match$2[0];
          var exit = 0;
          if (typeof p === "number" || p.tag || p[0] !== "Tuple") {
            exit = 1;
          } else if (match$2[1]) {
            all_pts = /* [] */0;
          } else {
            var match$3 = match[1];
            all_pts = match$3 && !match$3[1] ? p[1] : /* [] */0;
          }
          if (exit === 1) {
            if (match$2[1]) {
              all_pts = /* [] */0;
            } else {
              var match$4 = match[1];
              all_pts = match$4 && !match$4[1] ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    p,
                    /* [] */0
                  ]) : /* [] */0;
            }
          }
          
        } else {
          all_pts = /* [] */0;
        }
      }
    } else {
      all_pts = /* [] */0;
    }
  }
  var get_subset = function (param) {
    return get_entity_and_subset(entity_dict, data_type_dict, dts, param);
  };
  var as_result = function (param) {
    switch (param.tag | 0) {
      case /* Entity_subset */0 :
          var entity = param[0];
          var record_dep = make_result(entity_dict, data_type_dict, dts, /* tuple */[
                entity,
                param[1]
              ]);
          return /* Record_dep */Caml_chrome_debugger.variant("Record_dep", 0, [
                    entity[/* name */0],
                    record_dep,
                    param[2]
                  ]);
      case /* Any_subset */1 :
          console.log("No handle any usbset", param[0]);
          return ;
      case /* T_name */2 :
          return /* Data_dep */Caml_chrome_debugger.variant("Data_dep", 1, [
                    param[0],
                    param[1]
                  ]);
      
    }
  };
  var get_subset_as_result = function (t) {
    return Plate$BwaxAdmin.$$Option.and_then(as_result, get_subset(t));
  };
  return Plate$BwaxAdmin.List.map(get_subset_as_result, all_pts);
}

function determine_function_dependencies_as_js(entity_dict, data_type_dict, dts, entity_name, typed_val) {
  var deps = determine_function_dependencies(entity_dict, data_type_dict, dts, entity_name, typed_val);
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(dep_as_js, deps));
}

function if_term_matters(t) {
  var is_readonly = function (_t) {
    while(true) {
      var t = _t;
      if (typeof t === "number") {
        return false;
      } else {
        switch (t.tag | 0) {
          case /* Term */0 :
              switch (t[0]) {
                case "List" :
                case "Maybe" :
                    break;
                default:
                  return false;
              }
              var match = t[1];
              if (match && !match[1]) {
                _t = match[0];
                continue ;
              } else {
                return false;
              }
              break;
          case /* Var */2 :
              var tmp = t[0][/* var_type */0];
              if (typeof tmp === "number") {
                return false;
              } else {
                switch (tmp.tag | 0) {
                  case /* Recordvar */0 :
                  case /* Record_readonly */1 :
                  case /* Record_readonly_of */2 :
                  case /* Any_record_readonly */6 :
                      return true;
                  default:
                    return false;
                }
              }
          default:
            return false;
        }
      }
    };
  };
  if (typeof t === "number" || t.tag) {
    return false;
  } else {
    var match = t[1];
    var d = t[0];
    if (d === Lang_typing_base$BwaxAdmin.definition_name) {
      return true;
    } else if (match) {
      var match$1 = match[1];
      if (match$1 && !(match$1[1] || d !== Lang_typing_base$BwaxAdmin.arrow_name)) {
        var all_pts = get_all_parameter_types(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                match[0],
                /* [] */0
              ]), match$1[0]);
        return Plate$BwaxAdmin.List.some(is_readonly, all_pts);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

exports.tree_to_paths = tree_to_paths;
exports.get_entity_and_subset = get_entity_and_subset;
exports.convert_atom_dtype_to_term = convert_atom_dtype_to_term;
exports.traverse = traverse;
exports.to_json = to_json;
exports.to_selection = to_selection;
exports.make_result = make_result;
exports.get_func_t = get_func_t;
exports.get_init_t = get_init_t;
exports.is_empty_record_dep = is_empty_record_dep;
exports.dep_as_js = dep_as_js;
exports.is_old_event_handler = is_old_event_handler;
exports.determine_updated_event_dependencies = determine_updated_event_dependencies;
exports.get_all_parameter_types = get_all_parameter_types;
exports.determine_init_dependencies = determine_init_dependencies;
exports.determine_view_dependencies = determine_view_dependencies;
exports.determine_init_dependencies_as_js = determine_init_dependencies_as_js;
exports.determine_view_dependencies_as_js = determine_view_dependencies_as_js;
exports.determine_function_dependencies = determine_function_dependencies;
exports.determine_cmdm_processor_dependencies = determine_cmdm_processor_dependencies;
exports.determine_sequence_cmdm_processor_dependencies = determine_sequence_cmdm_processor_dependencies;
exports.determine_function_dependencies_as_js = determine_function_dependencies_as_js;
exports.if_term_matters = if_term_matters;
/* Plate-BwaxAdmin Not a pure module */
