

import React, { useState, useEffect } from 'react'

import useDataLoader from "bwax-ui/legacy/store/useDataLoader";
import loadGeneralSetting from 'bwax-ui/legacy/store/loaders/loadGeneralSetting'
import setupPageEnv from 'bwax-ui/page/setupPageEnv';
import evaluateGeneralValue from 'Client/js/adminApp/evaluateGeneralValue';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

export function useGeneralSettingValue({name, appCode, dlc, facade}) {

    // 暂时用 useDataLoader:
    const key = `definitions::${appCode}::GeneralSetting::${name}`;
    const { getData } = useDataLoader({ [key]: loadGeneralSetting(name, appCode) })
    const [ setting ] = getData(key);

    const [ value, setValue ] = useState();

    useEffect(() => {

        if (setting === undefined || facade === undefined) {
            return
        }

        if (setting === null) {
            setValue(null)
            return
        }

        (async () => {
            const domainEnv = {
                mobileHost: document.userenv.mobileHosts[0],
                isSandbox: dlc.sandbox,
                protocol: "https",
                tenantCode: dlc.tenantCode,
            }

            const { host, pathname, hash, search } = document.location;
            const webEnv = {
                protocol: "https",
                host,
                currentURLPathL: pathname + hash + search,
                isSandbox: dlc.sandbox,
                isIOS: dlc.userenv.isIOS,
                isWeChat: dlc.userenv.isWeChat,
                isMobile: dlc.userenv.isMobile,
                tenantCode: dlc.tenantCode,
            }

            const [ast, dts, entity_dict, data_type_dict, env, externalNames, ] = await setupPageEnv(setting.compiled, facade)

            const [r, e] = await evaluateGeneralValue(
                { domainEnv, webEnv, dlc, entity_dict, data_type_dict, base_env: env, dts, ast },
            );

            // console.log("Execution uses", end - start, "ms");
            if (e) {
                console.warn(e);
            }
            if (r && !e) {
                setValue(r);
            }

        })()

    }, [setting, facade])    

    return value
}


export function useGeneralSettingValueJs({name, appCode, dlc, facade}) {

    const value = useGeneralSettingValue({name, appCode, dlc, facade});
    if(value !== undefined && value !== null) { 
        return lang_entry_slim.value_to_js(value);
    }
    return value
    
}


export default function GeneralSettingView({ name, appCode, viewComponent: View }) {

    const value = useGeneralSettingValue({ name, appCode });

    return (
        <View value={value} />
    )
}
