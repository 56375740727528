

import React, { useEffect, useState } from 'react'

import { CgFormatLeft, CgFormatCenter, CgFormatRight, CgFormatJustify, CgClose, CgChevronDown, CgChevronUp,
    CgMoreAlt, CgBorderStyleSolid, CgBorderStyleDashed, CgBorderStyleDotted, CgMathPlus
} from 'react-icons/cg';
import { FontRomanIcon, FontItalicIcon, UnderlineIcon, StrikethroughIcon, OverlineIcon, LetterCaseUppercaseIcon,
    LetterCaseCapitalizeIcon, LetterCaseLowercaseIcon
} from '@radix-ui/react-icons'
import { MdFormatTextdirectionLToR, MdFormatTextdirectionRToL } from 'react-icons/md'
import { AiFillEye, AiFillEyeInvisible, AiOutlineDelete } from 'react-icons/ai'

import { Input, Select, Slider, Popover as ArcoPopover } from '@arco-design/web-react';
const Option = Select.Option;

import { SketchPicker } from 'react-color';

import Tooltip from 'bwax-ui/components/Tooltip';
import Popover from 'bwax-ui/components/legacy/Popover'
import ResetableLabel from '../components/ResetableLabel';
import { transparentImgUrl, colorRgbRegx, colorHexRegx } from '../StyleForm';
import ShadowEdit from '../components/ShadowEdit';
import ShadowList from '../components/ShadowList';

import './Typography.less'

const defaultTypographStyle = {
    fontSize: '12px',
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.85)'
}

const defaultTextShadow = {
    visible: true,
    color: 'black',
    angle: { value: 135, unit: 'deg' },
    distance: { value: 1, unit: 'px' },
    blur: { value: 6, unit: 'px' }
}

export default function Typography({ blockNode, attributes, onChange,  }) {
    const lengthStyleRegx = /^((\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?|normal)$/
    const columnGapRegx = /^(\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)?$/
    const columnRuleWidthRegx = /^(\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh)?$/
    const textShadowLengthRegx = "(-?\\d+(?:\\.\\d+)?)(px|em|rem|vw|vh)?"
    const textShadowRegx = new RegExp(`^(.*) ${textShadowLengthRegx} ${textShadowLengthRegx} ${textShadowLengthRegx}$`)

    function getBodyTypographStyle(styleKey) {
        if(typeof (window) !== "undefined") {
            return window.getComputedStyle(window.document.body)[styleKey]
        } else {
            return defaultTypographStyle[styleKey]
        }
    }

    const bodyFontSizeMatchedResult = getBodyTypographStyle('fontSize').match(lengthStyleRegx)
    const bodyLineHeightMatchedResult = getBodyTypographStyle('lineHeight').match(lengthStyleRegx)
    const defaultLengthStyles = {
        fontSize: { value: bodyFontSizeMatchedResult[2], unit: bodyFontSizeMatchedResult[3] },
        lineHeight:  { value: bodyLineHeightMatchedResult[2], unit: bodyLineHeightMatchedResult[3] ? bodyLineHeightMatchedResult[3] : '-' },
        letterSpacing: { value: 'Normal', unit: '-' },
        textIndent: { value: '0', unit: 'px' },
    }
    const defualtColumRule = {
        width: { value: 0, unit: 'px' },
        style: 'none',
        color: 'transparent'
    }
    const defaultSelectStyles = {
        fontWeight: '400',
        whiteSpace: 'normal'
    }

    const [lengthStyles, setLenghStyles] = useState(defaultLengthStyles)
    const [color, setColor] = useState(getBodyTypographStyle('color'))
    const [moreTypeEditVisible, setMoreTypeEditVisible] = useState(false)
    const [columnCount, setColumnCount] = useState('Auto')
    const [columnEditVisible, setColumnEditVisible] = useState(false)
    const [columnGap, setColumnGap] = useState({ value: 0, unit: 'px' })
    const [columnRule, setColumnRule] = useState(defualtColumRule)
    const [colorPickerAttribute, setColorPickerAttribute] = useState(null)
    const [selectStyles, setSelectStyles] = useState(defaultSelectStyles)
    const [activeShadowIndex, setActiveShadowIndex] = useState(null)
    const [shadows, setShadows] = useState([])

    useEffect(() => {
        let newLengthStyles = {...defaultLengthStyles}
        Object.keys(newLengthStyles).map(styleKey => {
            if(attributes[styleKey]) {
                const matchedResult = attributes[styleKey].match(lengthStyleRegx)
                if(matchedResult) {
                    newLengthStyles = {
                        ...newLengthStyles,
                        [styleKey]: { 
                            value: matchedResult[2] ? matchedResult[2] : matchedResult[1], 
                            unit: matchedResult[3] ? matchedResult[3] : (styleKey === 'lineHeight' || styleKey === "letterSpacing" ? '-' : 'px') 
                        }
                    }
                }
            }
        })
        
        setLenghStyles(newLengthStyles)

        let newSelectStyles = {...defaultSelectStyles}
        Object.keys(newSelectStyles).map(styleKey => {
            if(!!attributes[styleKey]) {
                newSelectStyles = {
                    ...newSelectStyles,
                    [styleKey]: attributes[styleKey]
                }
            }
        })
        
        setSelectStyles(newSelectStyles)

        if(attributes['color']) {
            setColor(attributes['color'])
        } else {
            setColor(getBodyTypographStyle('color'))
        }

        if(attributes['columnCount']) {
            setColumnCount(attributes['columnCount'])
        } else {
            setColumnCount('Auto')
        }

        if(attributes['columnGap']) {
            const matchedResult = attributes['columnGap'].match(columnGapRegx)
            if(matchedResult) {
                setColumnGap({
                    ...columnGap,
                    value: matchedResult[1],
                    unit: matchedResult[2] ? matchedResult[2] : 'px'
                })
            }
        }

        if(attributes['columnRule']) {
            const columnRuleArr = attributes['columnRule'].split(" ")
            const columnRuleWidthMatchResult = columnRuleArr[0].match(columnRuleWidthRegx)
            const newColumnRule = {
                width: { value: columnRuleWidthMatchResult[1], unit: columnRuleWidthMatchResult[2] ? columnRuleWidthMatchResult[2] : 'px' },
                style: columnRuleArr[1],
                color: columnRuleArr[2]
            }
            setColumnRule(newColumnRule)
        }

    }, [attributes])

    useEffect(() => {
        if(attributes['textShadow']) {
            const shadowArr = attributes['textShadow'].split(", ")
            const newShadows = shadowArr.map(shadow => {
                const matchedResult = shadow.match(textShadowRegx)
                if(matchedResult) {
                    const offsetX = Math.ceil(matchedResult[2])
                    const offsetY = Math.ceil(matchedResult[4])
                    return {
                        visible: true,
                        color: matchedResult[1],
                        angle: { value: parseInt(180 / Math.PI * Math.atan2(offsetY, offsetX)) + 90, unit: 'deg' },
                        distance: { value: Math.ceil(Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2))), unit: 'px' },
                        blur: { value: matchedResult[6], unit: matchedResult[7] ? matchedResult[7] : 'px' }
                    }
                } else {
                    return defaultTextShadow
                }
                
            })

            setShadows(newShadows)
        }
    }, [])

    const units = ['px', 'em', 'rem', 'vw', 'vh']
    const commonUnits = [...units, '%', 'ch']
    const lineHeightUnits = [...units, '%', '-']
    const letterSpacingUnits = [...units, 'ch', 'normal']
    const columnRuleWidthUnits = [...units, '%']

    function renderUnitSelect(styleKey, unitsArr) {

        function selectUnit(styleKey, unit) {
            const { value } = lengthStyles[styleKey]
            if (unit === '-') {
                setLenghStyles({
                    ...lengthStyles,
                    [styleKey]: { ...lengthStyles[styleKey], unit: '-' }
                })
                onChange({ [styleKey]: value ? value : 0 })
            } else {
                setLenghStyles({ ...lengthStyles, [styleKey]: { ...lengthStyles[styleKey], unit } })
                onChange({ [styleKey]: `${value && value !== 'normal' ? value : 0}${!value || value === '0' || value === 'normal' ? '' : unit}` })
            }
        }

        return (
            <Select value={lengthStyles[styleKey].unit} size={'mini'} className="unit-select" arrowIcon={null} 
                triggerProps={{ autoAlignPopupWidth: false }} onChange={unit => selectUnit(styleKey, unit)}
            >
                {unitsArr.map(option => (
                    <Option key={option} value={option} className='unit-option'>
                        {option.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
    }

    function updateLengthStyleValue (styleKey, value) {
        setLenghStyles({
            ...lengthStyles,
            [styleKey]: {
                ...lengthStyles[styleKey],
                value
            }
        })
       
    }

    function updateLengthStyleValueConfirm (styleKey) {
        const { value, unit } = lengthStyles[styleKey]
        const matchedResult = value ? value.match(lengthStyleRegx) : null
        if (matchedResult) {
            const matchedValue = matchedResult[2] ? matchedResult[2] : matchedResult[1]
            const matchedUnit = matchedResult[3] ? matchedResult[3] : unit
            setLenghStyles({
                ...lengthStyles,
                [styleKey]: {
                    ...lengthStyles[styleKey],
                    value: matchedValue,
                    unit: matchedUnit
                }
            })
            onChange({ [styleKey]: `${matchedValue}${matchedValue === 'normal' || matchedUnit === '-' ? '' : matchedUnit}` })
        }
    }
    
    function inputColorConfirm () {
        let validColor = getBodyTypographStyle('color')
        if((color.startsWith("rgb") && color.match(colorRgbRegx)) || (color.startsWith('#') && color.match(colorHexRegx))) {
            validColor = color
        }

        onChange({ color: validColor})
    }

    function textColorSelect (color) {
        const { r, g, b, a } = color.rgb
        const c = `rgba(${r},${g},${b},${a})`
        setColor(c)
        onChange({ color: c })
    }

    function renderColorEdit(attribute, colorValue, onValueChange, onValueConfirm, onColorSelect) {

        function renderColorPicker () {

            function renderColorPicker () {
            
                return (
                    <SketchPicker
                        color={colorValue}
                        onChangeComplete={onColorSelect} 
                    />
                )
            }
    
            return (
                <ArcoPopover content={renderColorPicker()} position="bottom" popupVisible={colorPickerAttribute === attribute} trigger="click"
                    onVisibleChange={visible => {
                        if(visible) {
                            setColorPickerAttribute(attribute)
                        } else {
                            setColorPickerAttribute(null)
                        }
                        
                    }}
                >
                    <div className='color-display' style={{ 
                        backgroundImage: [`linear-gradient(180deg, ${colorValue} 0%, ${colorValue} 100%)`, `url(${transparentImgUrl})`] 
                    }}></div>
                </ArcoPopover>
                
            )
        }

        return (
            <Input value={colorValue} size="mini" addBefore={renderColorPicker()} beforeStyle={{ padding: 0 }}
                onChange={value => onValueChange(value)} 
                onBlur={() => onValueConfirm()}
                onPressEnter={() => onValueConfirm()}
            />
        )
    }

    const alignOptions = [{
        value: 'left', icon: <CgFormatLeft/>, tooltip: "Left"
    }, {
        value: 'center', icon: <CgFormatCenter/>, tooltip: "Center"
    }, {
        value: "right", icon: <CgFormatRight/>, tooltip: "Right"
    }, {
        value: "justify", icon: <CgFormatJustify/>, tooltip: "Justify"
    }]

    const italicOptions = [{
        value: 'normal', icon: <FontRomanIcon/>, tooltip: "Regular"
    }, {
        value: "italic", icon: <FontItalicIcon/>,
        tooltip: "Italic — note that if your typeface doesn‘t actually have italics, the browser will fake them. Often in a rather gross way."
    }]

    const decorationOptions = [{
        value: "none", icon: <CgClose/>, tooltip: "None"
    }, {
        value: "line-through", icon: <StrikethroughIcon/>, tooltip: "Strikethrough"
    }, {
        value: "underline", icon: <UnderlineIcon/>, tooltip: "Underline"        
    }, {
        value: "overline", icon: <OverlineIcon/>, tooltip: "Overline"
    }]

    function renderOptions (options, styleKey) {
        return (
            <div className='options'>
                {
                    options.map((op, index) => {
                        const { value, icon, tooltip } = op
                        const isActive = attributes[styleKey] === value
                        return (
                            <Tooltip key={index} text={tooltip} className="limited-width-tooltip">
                                <div className={`option-box ${isActive ? 'active' : ''}`}
                                    onClick={() => onChange({ [styleKey]: value }) }
                                >
                                    {icon}
                                </div>
                            </Tooltip>
                        )
                    })
                }
            </div>
        )
    }

    function updateColumnCount () {
        const columnCountRegx = /^\d+|[aA]uto/
        if(columnCount.match(columnCountRegx)) {
            onChange({ columnCount })
        } else {
            setColumnCount('Auto')
        }
    }

    function rendreColumnEdit () {

        function updateColumnGap (value) {
            onChange({ columnGap: `${value}${value === '0' ? '' : columnGap.unit}`})
        }

        function updateColumnGapConfirm () {
            const { value, unit } = columnGap
            const matchedResult = value ? value.match(columnGapRegx) : null
            if(matchedResult) {
                const matchedValue = matchedResult[1]
                const matchedUnit = matchedResult[2] ? matchedResult[2] : unit
                setColumnGap({ value: matchedValue, unit: matchedUnit })
                onChange({ columnGap: `${matchedValue}${matchedValue === '0' ? '' : matchedUnit}`})
            } else {
                setColumnGap({ value: 0, unit })
            }
        }

        function selectColumnGapUnit(unit) {
            const { value } = columnGap
            setColumnGap({ ...columnGap, unit })
            onChange({ columnGap: `${value ? value : 0}${!value || value === '0' ? '' : unit}` })
        }

        const columnStylesDisabled = !!columnCount.match(/^[aA]uto$/)

        const ruleStyleOptions = [{
            value: 'none', icon: <CgClose/>, tooltip: 'No rule',
        }, {
            value: 'solid', icon: <CgBorderStyleSolid/>, tooltip: 'Solid'
        }, {
            value: 'dashed', icon: <CgBorderStyleDashed/>, tooltip: 'Dashed'
        }, {
            value: 'dotted', icon: <CgBorderStyleDotted/>, tooltip: 'Dotted'
        }]

        function updateColumnRuleStyle (style) {
            const { width, color } = columnRule
            const { value, unit } = width
            
            onChange({ columnRule: `${value}${value === '0' ? '' : unit} ${style} ${color}`})
        }

        function updateColumnRuleWidthValue (value) {
            const { width, style, color } = columnRule
            const { unit } = width
            onChange({ columnRule: `${value}${value === '0' ? '' : unit} ${style} ${color}`})
        }

        function selectColumnRuleWidthUnit(unit) {
            const { value } = columnRule.width
            const { style, color } = columnRule
            onChange({ columnRule: `${value}${value === '0' ? '' : unit} ${style} ${color}`})
        }

        function updateColumnRuleWidthConfirm() {
            const { value, unit } = columnRule.width
            const matchedResult = value ? value.match(columnRuleWidthRegx) : null
            if(matchedResult) {
                const matchedValue = matchedResult[1]
                const matchedUnit = matchedResult[2] ? matchedResult[2] : unit
                setColumnRule({ ...columnRule, width: { value: matchedValue, unit: matchedUnit }})
                const { style, color } = columnRule
                onChange({ columnRule: `${matchedValue}${matchedValue === '0' ? '' : matchedUnit} ${style} ${color}`})
            } else {
                setColumnRule({ ...columnRule, width: { value: 0, unit }})
            }
        }

        function changeColumnRuleWidthValue (widthObj) {
            setColumnRule({
                ...columnRule,
                width: { ...widthObj }
            })
        }

        function renderColumnAttrInput (label, attrObj, unitsArr, onValueChange, onValueChangeConfirm, onUnitSelect, updateValue) {

            function renderColumnAttrUnitSelect() {
    
                return (
                    <Select value={attrObj.unit} size={'mini'} className="unit-select" arrowIcon={null} 
                        triggerProps={{ autoAlignPopupWidth: false }} onChange={unit => onUnitSelect(unit)}
                    >
                        {unitsArr.map(option => (
                            <Option key={option} value={option} className='unit-option'>
                                {option.toUpperCase()}
                            </Option>
                        ))}
                    </Select>
                )
            }

            return (
                <div className='attr-slider-container'>
                    <div className='attr-slider-label'>{label}</div>
                    <Tooltip text={`${columnStylesDisabled ? "Set a column count before adjusting the column's layout setting" : ''}`}>
                        <div className='attr-slider-input'>
                            <Slider value={attrObj.value ? parseFloat(attrObj.value) : 0} size="mini" disabled={columnStylesDisabled}
                                onChange={value => updateValue(value.toString())} style={{ margin: "0 6px" }}/>
                            <Input value={attrObj.value} size="mini" suffix={renderColumnAttrUnitSelect()} style={{ width: 72, flexShrink: 0 }}
                                onChange={value => onValueChange({ ...attrObj, value })} onBlur={() => onValueChangeConfirm()}
                                onPressEnter={() => onValueChangeConfirm()} disabled={columnStylesDisabled}
                            />
                        </div>
                    </Tooltip>
                </div>
            )
        }

        function inputColumnRuleColor (color) {
            setColumnRule({
                ...columnRule,
                color
            })
        }

        function columnRuleColorSelect (color) {
            const { r, g, b, a } = color.rgb
            const c = `rgba(${r},${g},${b},${a})`
            const { width, style } = columnRule
            const { value, unit } = width
            onChange({ columnRule: `${value}${value === '0' ? '' : unit} ${style} ${c}`})
        }

        function inputcolumnRuleColorConfirm () {
            const { color } = columnRule
            let validColor = `transparent`
            if((color.startsWith("rgb") && color.match(colorRgbRegx)) || (color.startsWith('#') && color.match(colorHexRegx))) {
                if(color.startsWith("rgb")) {
                    const matchRgbResult = color.match(colorRgbRegx)
                    console.log("matchRgbResult: ", matchRgbResult);
                    const convertedColor = `rgba(${matchRgbResult[1]},${matchRgbResult[2]},${matchRgbResult[3]},${matchRgbResult[4] ? matchRgbResult[4] : 1})`
                    validColor = convertedColor
                } else {
                    validColor = color
                }
            }
            const { width, style } = columnRule
            const { value, unit } = width
            onChange({ columnRule: `${value}${value === '0' ? '' : unit} ${style} ${validColor}`})
        }

        function columnSpanDisabled() {
            if (blockNode && typeof (window) !== 'undefined') {
                const parentColumnCount = window.getComputedStyle(blockNode.parentNode)["columnCount"];
                return !!parentColumnCount.match(/^[aA]uto$/)
            } else {
                return false
            }
        }

        const columnSpanOptions = [{
            value: "none", text: "None"
        }, {
            value: 'all', text: "All"
        }]

        return (
            <div className='column-properties-container'>
                { renderColumnAttrInput("Gap", columnGap, commonUnits, setColumnGap, 
                    updateColumnGapConfirm, selectColumnGapUnit, updateColumnGap) 
                }
                <div className='divider-settings-container'>
                    <div className='title'>Divider settings</div>
                    <div className='style-with-options'>
                        <div>Style</div>
                        <Tooltip text={`${columnStylesDisabled ? "Set a column count before adjusting the column's layout setting" : ''}`}>
                            <div className="options">
                                {
                                    ruleStyleOptions.map((op, index) => {
                                        const { value, icon, tooltip } = op
                                        const isActive = columnRule.style === value

                                        return (
                                            <Tooltip key={index} text={columnStylesDisabled ? '' : tooltip}>
                                                <div className={`option-box ${columnStylesDisabled ? 'disabled' : (isActive ? 'active' : '')}`}
                                                    onClick={() => columnStylesDisabled ? null : updateColumnRuleStyle(value) }
                                                >
                                                    {icon}
                                                </div>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </div>
                        </Tooltip>
                    </div>
                    { renderColumnAttrInput("Width", columnRule.width, columnRuleWidthUnits, changeColumnRuleWidthValue, 
                        updateColumnRuleWidthConfirm, selectColumnRuleWidthUnit, updateColumnRuleWidthValue) 
                    }
                    <div className='columnRule-color-container'>
                        <div className='attr-slider-label'>Color</div>
                        { renderColorEdit('columnRule', columnRule.color, inputColumnRuleColor, inputcolumnRuleColorConfirm, columnRuleColorSelect) }
                    </div>
                </div>
                <div className='column-child-container'>
                    <div className='title'>Column Child</div>
                    <div className='column-span-container'>
                        <div className='attr-slider-label'>Span</div>
                        <Tooltip className="limited-width-tooltip"
                            text={`${columnSpanDisabled() ? "This block needs to be inside a multi-column parent to span across columns " : ''}`}
                        >
                            <div className="options" style={{ width: '100%' }}>
                                {
                                    columnSpanOptions.map(op => {
                                        const { value, text } = op
                                        const isActive = attributes['columnSpan'] === value

                                        return (
                                            <div key={text} className={`option-box ${columnSpanDisabled() ? 'disabled' : (isActive ? 'active' : '')}`}
                                                onClick={() => columnSpanDisabled() ? null : onChange({ columnSpan: value }) }
                                            >
                                                {text}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }

    const transformOptions = [{
        value: 'none', icon: <CgClose/>, tooltip: "None"
    }, {
        value: 'uppercase', icon: <LetterCaseUppercaseIcon/>, tooltip: "All Caps"
    }, {
        value: 'capitalize', icon: <LetterCaseCapitalizeIcon/>, tooltip: "Capitalize Every Word"
    }, {
        value: 'lowercase', icon: <LetterCaseLowercaseIcon/>, tooltip: "Lowercase"
    }]

    const directionOptions = [{
        value: 'ltr', icon: <MdFormatTextdirectionLToR/>, tooltip: "Left to right"
    }, {
        value: 'rtl', icon: <MdFormatTextdirectionRToL/>, tooltip: "Right to left"
    }]

    const fontWeightKeywords = ['Thin', 'Extra Light', 'Light', 'Normal', 'Medium', 'Semi Bold', 'Bold', 'Extra Bold', 'black']
    const whiteSpaceKeywords = ['Normal', 'Nowrap', 'Pre', 'Pre-wrap', 'Pre-line', 'Break-spaces']

    function renderAttrSelect (label, attribute) {

        const options = {
            fontWeight: fontWeightKeywords.map((w, index) => {
                return {
                    value: `${index + 1}00`,
                    label: `${index + 1}00 - ${w}`
                }
            }),
            whiteSpace: whiteSpaceKeywords.map(ws => {
                return {
                    value: ws,
                    label: ws
                }
            })
        }

        function selectAttrValue (value) {
            onChange({ [attribute]: value })

        }

        return (
            <div className='select-attr-container'>
                <ResetableLabel allAttributes={attributes} label={label} attributesGroup={"typography"} 
                    attribute={attribute} onChange={onChange}
                />
                <Select value={attributes[attribute] ? attributes[attribute] : selectStyles[attribute]} size={'mini'} arrowIcon={null} 
                    onChange={value => selectAttrValue(value)}
                >
                    {options[attribute].map(option=> (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </div>
            
        )
    }

    function updateShadows (newShadows) {
        let shadowsStr = "" 
        const visibleShadows = newShadows.filter(shadow => shadow.visible)
        if(visibleShadows.length > 0) {
            shadowsStr = visibleShadows.map(shadow => {
                const { color, angle, distance, blur } = shadow
                const { value } = angle // deg
                const radians = value * (Math.PI / 180) // convert deg to radian
                const offsetX = Math.sin(radians) * distance.value
                const offsetY = -Math.cos(radians) * distance.value
                
                return `${color} ${Math.ceil(offsetX)}px ${Math.ceil(offsetY)}px ${blur.value}${blur.unit}`
            }).join(', ')
        } else {
            shadowsStr = undefined
        }

        onChange({ textShadow: shadowsStr })
    }

    function addShadow () {
        const newShadows = [defaultTextShadow, ...shadows]
        setShadows(newShadows)
        updateShadows(newShadows)
    }

    function renderShadowInfoStr (index) {
        const activeShadow = shadows[index]
        const { distance, blur } = activeShadow

        return `Text shadow: ${distance.value}${distance.unit}, ${blur.value}${blur.unit}`
    }

    function changeShadows (newShadows) {
        setShadows(newShadows)
        updateShadows(newShadows)
    }

    return (
        <div className='typography-container'>
            { renderAttrSelect('Weight', 'fontWeight')}
            <div className='size'>
                <ResetableLabel allAttributes={attributes} label={"Size"} attributesGroup={"typography"} 
                    attribute={'fontSize'} onChange={onChange}
                />
                <Input size="mini" value={lengthStyles.fontSize.value} suffix={renderUnitSelect('fontSize', commonUnits)}
                    onChange={value => updateLengthStyleValue("fontSize", value)}
                    onBlur={() => updateLengthStyleValueConfirm("fontSize")}
                    onPressEnter={() => updateLengthStyleValueConfirm("fontSize")}
                />
                <ResetableLabel allAttributes={attributes} label={"Height"} attributesGroup={"typography"} 
                    attribute={'lineHeight'} onChange={onChange}
                />
                <Input size="mini" value={lengthStyles.lineHeight.value} suffix={renderUnitSelect('lineHeight', lineHeightUnits)}
                    onChange={value => updateLengthStyleValue("lineHeight", value)}
                    onBlur={() => updateLengthStyleValueConfirm("lineHeight")}
                    onPressEnter={() => updateLengthStyleValueConfirm("lineHeight")}
                />
            </div>
            <div className='color'>
                <ResetableLabel allAttributes={attributes} label={"Color"} attributesGroup={"typography"} 
                    attribute={'color'} onChange={onChange}
                />
                { renderColorEdit('color', color, setColor, inputColorConfirm, textColorSelect) }
            </div>
            <div className='style-with-options'>
                <ResetableLabel allAttributes={attributes} label={"Align"} attributesGroup={"typography"} 
                    attribute={'textAlign'} onChange={onChange}
                />
                { renderOptions(alignOptions, 'textAlign') }
            </div>
            <div className='style'>
                <div>Style</div>
                <div className='italic-options-container'>
                    { renderOptions(italicOptions, 'fontStyle') }
                    <ResetableLabel allAttributes={attributes} label={"Italicize"} attributesGroup={"typography"} 
                        attribute={'fontStyle'} onChange={onChange}
                    />
                </div>
                <div className='decoration-options-container'>
                    { renderOptions(decorationOptions, 'textDecoration') }
                    <ResetableLabel allAttributes={attributes} label={"Decoration"} attributesGroup={"typography"} 
                        attribute={'textDecoration'} onChange={onChange}
                    />
                </div>
            </div>
            <div className={`more-edit-toggle`} onClick={() => setMoreTypeEditVisible(!moreTypeEditVisible)}>
                {moreTypeEditVisible ? <CgChevronUp/> : <CgChevronDown/>}
                More type options
            </div>
            {
                moreTypeEditVisible ? (
                    <div className='more-edit-container'>
                        <div className='length-edit-container'>
                            <div className='letterSpacing-box'>
                                <Input value={lengthStyles.letterSpacing.value} size="mini" suffix={renderUnitSelect('letterSpacing', letterSpacingUnits)}
                                    onChange={value => updateLengthStyleValue('letterSpacing', value)} 
                                    onBlur={() => updateLengthStyleValueConfirm('letterSpacing')}
                                    onPressEnter={() => updateLengthStyleValueConfirm('letterSpacing')}
                                />
                                <ResetableLabel allAttributes={attributes} label={"Letter spacing"} attributesGroup={"typography"} 
                                    attribute={'letterSpacing'} onChange={onChange}
                                />
                            </div>
                            <div className='textIndent-box'>
                                <Input value={lengthStyles.textIndent.value} size="mini" suffix={renderUnitSelect('textIndent', commonUnits)}
                                    onChange={value => updateLengthStyleValue('textIndent', value)} 
                                    onBlur={() => updateLengthStyleValueConfirm('textIndent')}
                                    onPressEnter={() => updateLengthStyleValueConfirm('textIndent')}
                                />
                                <ResetableLabel allAttributes={attributes} label={"Text indent"} attributesGroup={"typography"} 
                                    attribute={'textIndent'} onChange={onChange}
                                />
                            </div>
                            <div className='columnCount-box'>
                                <div className='columnCount-input'>
                                    <Input value={columnCount} size="mini" onChange={value => setColumnCount(value)} style={{ height: 24 }}
                                        onBlur={() => updateColumnCount()}
                                        onPressEnter={() => updateColumnCount()}
                                    />
                                    <Popover visible={columnEditVisible} content={rendreColumnEdit()} positions={["bottom", "top"]} rePosition={true}
                                        onClickOutside={_ => {
                                            if(!colorPickerAttribute) {
                                                setColumnEditVisible(false)
                                            }
                                        }}
                                    >
                                        <div onClick={() => setColumnEditVisible(!columnEditVisible)}>
                                            <Tooltip text="Open text column properties">
                                                <div><CgMoreAlt style={{ fontSize: 16 }}/></div>
                                            </Tooltip>
                                        </div>
                                    </Popover>
                                    
                                </div>
                                <ResetableLabel allAttributes={attributes} label={"Column count"} attributesGroup={"typography"} 
                                    attribute={'columnCount'} onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className='transform-direction-container'>
                            <div className='transform-options-container'>
                                { renderOptions(transformOptions, 'textTransform') }
                                <ResetableLabel allAttributes={attributes} label={"Campitalize"} attributesGroup={"typography"} 
                                    attribute={'textTransform'} onChange={onChange}
                                />
                            </div>
                            <div className='direction-options-container'>
                                { renderOptions(directionOptions, 'direction') }
                                <ResetableLabel allAttributes={attributes} label={"Direction"} attributesGroup={"typography"} 
                                    attribute={'direction'} onChange={onChange}
                                />
                            </div>
                        </div>
                        { renderAttrSelect('Breaking', 'whiteSpace')}
                        <div className='textShadow-header'>
                            <ResetableLabel allAttributes={attributes} label={"Text shadows"} attributesGroup={"typography"} 
                                attribute={'textShadow'} onChange={attributes => {
                                    setShadows([])
                                    onChange(attributes)
                                }}
                            />
                            <Tooltip text={"Add text shadow"}>
                                <div className={`icon-add ${activeShadowIndex || activeShadowIndex === 0 ? 'hide' : ''}`} onClick={() => addShadow()}>
                                    <CgMathPlus/>
                                </div>
                            </Tooltip>
                        </div>
                        {
                            shadows.length > 0 ? (
                                <ShadowList isBoxShadow={false} shadows={shadows} activeShadowIndex={activeShadowIndex} setActiveShadowIndex={setActiveShadowIndex}
                                    renderShadowInfoStr={renderShadowInfoStr} onChange={newShadows => changeShadows(newShadows)}
                                />
                            ) : null
                        }
                    </div>
                ) : null
            }
        </div>
    )
}
