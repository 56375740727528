
import React, { useEffect } from 'react'
import { Tabs } from 'antd'
const TabPane = Tabs.TabPane;

import './MpReplyEdit.less'
import Page from "bwax-ui/layout/Page"
import BreadcrumbSimple from 'Client/js/breadcrumb/BreadcrumbSimple';

import MpKeywordReply from './MpKeywordReply'
import MpOtherReply from './MpOtherReply'

export default function MpReplyEdit (props) {
    const { facade } = props

    useEffect(() => {
        facade.prepare(["邀请有礼-活动"]);
    });

    function getStoredTabActiveKey () {
        const cacheActiveKey = sessionStorage.getItem('bwax-wxmp-tab-active-key')
        return cacheActiveKey ? cacheActiveKey : "keywordReply"
      }
    
    function storeTabActiveKey (activeKey) {
        sessionStorage.setItem('bwax-wxmp-tab-active-key', activeKey)
    }
    
    return (
        <Page>
            <div className="wxmp-container">
                <div className="wxmp-breadcrumb-container">
                    <BreadcrumbSimple {...{
                        ...props,
                        pathname: props.location.pathname
                    }} />
                </div>
                <div className="wxmp-reply-container">
                    <Tabs animated={false} defaultActiveKey={getStoredTabActiveKey()}
                        onChange={() => storeTabActiveKey()}>
                        <TabPane tab="关键词回复" key="keywordReply">
                            <MpKeywordReply widgetProps={props}/>
                        </TabPane>
                        <TabPane tab="收到消息回复" key="defaultReply">
                            <MpOtherReply widgetProps={props} replySettingType="defaultReply"/>
                        </TabPane>
                        <TabPane tab="被关注回复" key="subscribeReply">
                            <MpOtherReply widgetProps={props} replySettingType="subscribeReply"/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            
        </Page>
        
    )
}