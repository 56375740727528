
/// flow definition todo
import invariant from 'invariant'
import React from 'react'

import { ExpressionParser } from './expression/ExpressionParser'

import { extractExpressions, mapObj } from '../utils'

class Expression {
	constructor({ text, ast, references, position }) {
		this.text = text;
		this.ast = ast;
		this.references = references;
		// each reference is a qName has two properties: value,  path
		this.position = position;
	}
}

// 所有的 data def 都是一个 reference
class Reference {
	constructor({ qName, path, entityName, dataTypeName }) {
		// 它被用在 expression 时的 qName
		this.qName = qName
		// 从当前节点一直往上追溯到的最长路径
		this.path = path

		// 以下可能有也可能没有：
		this.entityName = entityName
		this.dataTypeName = dataTypeName
	}
}

// 从当前节点一直往上追溯到最长路径
function buildLongestPath(qName, node) {
	if (!node) {
		return []
	}
	const varDef = node.dataDefs[qName[0]]

	if (varDef) {
		return [...varDef.path, ...qName.slice(1)]
	} else {
		return buildLongestPath(qName, node.parent)
	}
}



const __parser__ = new ExpressionParser()
function buildExpressions(node, nodeDef) {
	return extractExpressions(
		nodeDef,
		(expression, start, end) => {
			const ast = __parser__.parse(expression)
			const qNames = ExpressionParser.getAllQNames(ast)

			/// apply the qNames with type info:
			const references = qNames.map(qName => {
				const path = buildLongestPath(qName, node)
				return new Reference({ qName, path })
			})

			const ret =  new Expression({
				text: expression,
				ast,
				references,
				position: [start, end],
			})

			return ret
		}
	)
}

function resolveCategory(nodeDef, name, parent) {
	if (nodeDef === null) {
		return 'leaf' /// null should always be leaf
	} else if (Array.isArray(nodeDef)) {
		return 'array'
	} else if (typeof (nodeDef) === 'object') {
		return 'object'
	} else {
		return 'leaf'
	}
}


/// Node 主要用来在遍历之后，存储每一层用到的 expression，以及可能会涉及到的 local dataDefs 的向上引用
export default class Node {
	
	static buildWithTypes(definition, roots, { allEntities, allDataTypes } = {}) {
		
		const dataDefs = mapObj(roots, (value, key) => (
			new Reference({
				path: [key],
				qName: [key],
				entityName: value.entityName
			})
		))

		const node = Node.build(
			"root",
			definition,
			null,
			dataDefs
		)

		node.resolveTypeInfo({ allEntities, allDataTypes })

		return node
	}

	static build(name, nodeDef, parent, dataDefs = {}) {


		const node = new Node({
			name, parent,
			category: resolveCategory(nodeDef, name, parent),
			dataDefs: dataDefs || {},
			nodeDef
		})

		/// 2. 如果是 list function  要做一些处理
		if (parent && parent.name === '__apply__') {

			const { type, def } = parent.category === 'array' ? node.nodeDef : { type: name, def: nodeDef}
			const setUpDataDef = listDataDefPreparers[type]
			/// 如果在它之前已经有过 map ，则不需要处理	
			const { __noMoreListDataDef__ } = parent
			
			if(setUpDataDef && __noMoreListDataDef__ !== true) {
				// only setup data def when necessary
				setUpDataDef(node, def)
			}
			
		}
		// 此时所有的 本地变量应该都已经 ready 了：
		if (typeof (nodeDef) === 'string') {
			// how about the other literal?  the other literal should not include expressions.
			node.expressions = buildExpressions(node, nodeDef)
		}
		
		if(typeof(nodeDef) === 'function' || React.isValidElement(nodeDef)) {

			// 如果是函数或者 React element，不用进行进一步的递归
			// do nothing

		} else {
			// 进入下一层级的递归
			if (Array.isArray(nodeDef)) {
				node.children = nodeDef.map((value, index) => {
					return Node.build(index + '', value, node, {})
				})
			} else if (typeof (nodeDef) === 'object' && nodeDef !== null) {
	
				node.children = Object.keys(nodeDef).map(key => {
					return Node.build(key, nodeDef[key], node, {})
				})
			}	

		}
		
		/// 如果这是一个 __apply__，那么上层，可能是 def： 如 def -> <name> -> __apply__
		/// 那可能可能需要把处理完的 TypeInfo 赋予 <name>, 设定到 def node 上
		if(node.name === '__apply__' && node.__noMoreListDataDef__ !== true) {
			if (node.parent && node.parent.parent) {
				const varNode = node.parent
				const defNode = varNode.parent
				if (defNode.name === "__def__") {
					defNode.setDataDef({ [varNode.name]: node.getDataDef('list') })
				}
			}
		}




		// 如果 parent 是 __def__ 做一些基本处理
		if (parent && parent.name === "__def__" && node.name !== "__for__") {
			/// 在本地 存入 data def
			if (!parent.dataDefs[node.name]) {
				parent.setDataDef({
					[node.name]:
						new Reference({
							path: [],
							qName: [node.name]
						}) /// 如果不存在，则放一个站位
				})
			}
		}


		/// 如果 parent 是 __query__ 做一些基本处理
		if (parent && parent.name === "__query__" && node.name !== "__for__") {
			if (!parent.dataDefs[node.name]) {
				parent.setDataDef({
					[node.name]:
						new Reference({
							path: [node.name],
							qName: [node.name],

							/// entityName: 可能需要 evaluate？
							entityName: node.children.find(n => n.name === "entityName").nodeDef

						})
				})
			}
		}

		return node
	}

	constructor({ name, parent, category, dataDefs, nodeDef }) {
		this.name = name  /// the level name

		this.parent = parent
		this.nodeDef = nodeDef  // 存储原始定义

		this.category = category // "object", "array", "leaf"

		this.dataDefs = dataDefs || {} // all data definitions (only) visible at the level and below:
		/// dataDefs are
		/// { name -> { qName, path, entity }  or [{ qName, path}] }
		/// 其中 path 是必须的，可以为 []
		/// [ { qName, path } ] 表示这个 def 可能代表了多种不同的路径 -> 但类型必须一样，否则的话，也可以是空的 qName, 表示这是一个叶子

		this.expressions = []  // each expression has its text, ast, qName list

		this.children = [] /// children nodes

	}


	// 根据当前自己 data def 的状况，给所有子节点涉及的 reference 加上 TypeInfo
	resolveTypeInfo({ allEntities, allDataTypes }) {

		const dataDefs = this.dataDefs

		const f = (rawPath) => {
			const ret = applyTypeInfo(rawPath, dataDefs, {
				allEntities: allEntities || [],
				allDataTypes: allDataTypes || [],
			})
			return ret
		}


		this.traverse((n) => {
			const allReferences = [
				...Object.values(n.dataDefs),
				...(
					n.getExpressions().reduce((acc, e) => {
						return [...acc, ...e.references]
					}, [])
				)
			]

			/// update the references in place:
			allReferences.forEach(r => {
				const rawPath = r.path
				const path = f(rawPath)
				r.path = path
			})
		})
	}


	getExpressions() {
		return this.expressions
	}

	getAllExpressionsIncluded() {

		const allExpressionBelow = this.children.reduce((acc, child) => {
			return [...acc, ...child.getAllExpressionsIncluded()]
		}, [])

		return [
			...this.expressions,
			...allExpressionBelow
		]
	}

	/// 返回所有从 roots 延伸下来的 path；
	getAllPathsIncluded() {

		const expressions = this.getAllExpressionsIncluded();

		const paths = expressions.reduce((acc, { references }) => {
			return [...acc, ...references.map(({ path }) => path)]
		  }, []
		).filter(l => l.length > 0 && l[0] !== 'NOT_FOUND') // TODO 在后面 NOT_FOUND 应该移除

		return paths;
	}




	toPrintable() {
		const {
			name, category, dataDefs, expressions, children
		} = this

		return {
			name,
			category,
			dataDefs,
			expressions: expressions.map(({ ast, ...other }) => other),
			children: children.map(n => n.toPrintable()),
		}
	}

	setDataDef(defs) {
		this.dataDefs = {
			...this.dataDefs,
			...defs
		}
	}

	getDataDef(name) {
		return this.dataDefs[name]
	}

	getDataDefs() {
		return this.dataDefs
	}


	/// helper functions
	getQuery(queryName) {
		/// go to the top
		let top = this
		while (top.parent) {
			top = top.parent
		}

		const queryNode = top.children.find(n => n.name === "query")
		if (queryNode) {
			return queryNode.children.find(n => n.name === queryName)
		} else {
			return null
		}
	}

	getChild(name) {
		return this.children.find(n => n.name === name)
	}


	traverse(visit) {
		/// traverse the  all tree below:
		visit(this)

		if (this.children && this.children.length > 0) {
			this.children.forEach(c => c.traverse(visit))
		}
	}

}

////// function handlers:
const noOp = () => { }
const commonListDataDef = node => {

	const applyNode = node.parent
	const def = applyNode.getDataDef("list")

	node.setDataDef({
		// value: {
		//   ref: "list", // the list data in the upper level,
		//   path: def.path //
		// },
		value: new Reference({ qName: ["value"], path: def.path }),
		index: new Reference({ qName: ["index"], path: def.path }),
	})
}

const listDataDefPreparers = {
	list: (node, def) => {
		//  需要提前获取当前节点的 expressions:
		const expressions = buildExpressions(node, def)
		const applyNode = node.parent

		if(expressions.length === 1 && expressions[0].references.length === 1) {
			const list = expressions[0].references[0] // it is a reference
			// 把 list 放在 __apply__ NODE 里
			applyNode.setDataDef({ list })
		} else {
			/// do nothing
			applyNode.setDataDef({
				list: new Reference({ qName: ["list"], path: "" })
			})
		}
		
	},

	map: (node) => {
		commonListDataDef(node)
		// no more list data def, because map has already transformed the values

		/// set on the node above the __apply__ node:
		node.parent.__noMoreListDataDef__ = true
	},

	// add placeholders for reduce, count, countBy, find, filter, some, every
	reduce: noOp,

	count: commonListDataDef,
	sum: commonListDataDef,
	find: commonListDataDef,
	filter: commonListDataDef,
	some: commonListDataDef,
	every: commonListDataDef,

	uniq: (node) => {
		commonListDataDef(node)
		// no more list data def, because uniq has already transformed the values
		// only the value that are used to test "uniq" are left.
		
		/// set on the node above the __apply__ node:
		node.parent.__noMoreListDataDef__ = true
	},

	groupBy: (node) => {
		commonListDataDef(node)
		// no more list data def, because groupBY has already transformed the values

		/// set on the node above the __apply__ node:
		node.parent.__noMoreListDataDef__ = true
	},

	
	sort: node => {
		const applyNode = node.parent
		const def = applyNode.getDataDef("list")

		node.setDataDef({
			a: new Reference({ qName: ["a"], path: def.path }),
			b: new Reference({ qName: ["b"], path: def.path }),
		})
	},
}



/////
class TypeInfo {
	constructor({ name, type, key, entityName }) {
		this.name = name  // the name used in qName
		this.type = type  // the DataType of this section
		this.key = key  // the key used to build the GraphQL query and resolve data

		// the below are for the different types of root section
		this.entityName = entityName //
	}
}

function applyTypeInfoToField(path, entity, { allEntities, allDataTypes }) {
	if (!path || path.length === 0) return []

	const fieldName = path[0]

	const { fields = [], virtualFields = [], links = [] } = entity
	const allFields = [...fields, ...virtualFields, ...links]

	const field = fieldName === "id" ? {
		name: "id",
		type: { key: "ID" },
		key: "id"
	} : allFields.find(f => f.name === fieldName)

	invariant(field, `cannot find field ${fieldName} for entity ${entity.name}`)

	const fieldType = allDataTypes.find(dt => dt.key === field.type)

	const section = new TypeInfo({
		name: fieldName,
		type: fieldType,
		key: field.key
	})

	if (field.type === 'Link' && path.length > 1) {
		const entityKey = field.options.entity

		const linkedEntity = allEntities.find(e => e.key === entityKey || e.name === entityKey)

		invariant(linkedEntity, `Linked entity is not found: ${entityKey}`)

		return [section, ...applyTypeInfoToField(
			path.slice(1), linkedEntity, { allEntities, allDataTypes })
		]
	} else {
		return [section]  /// 要不要考虑其他非 Link 也要展开呢？ 应该在 Query Resolver 处展开.
	}
}


function applyTypeInfo(path, roots, { allEntities, allDataTypes }) {
	if (!path || path.length === 0) return []

	// the first section of the path must correspond to a query

	// 有可能部分 path 已经有类型了，为了简化逻辑，仍然转成没有类型的 path 进行处理
	const plainPath = path.map(s => typeof (s) === 'string' ? s : s.name)

	const firstName = plainPath[0]


	if (roots[firstName] === undefined) {
		/// 在 root 中没有定义，说明这个 path 是个简单的读取方式：
		return path
	}

	/// 这一部可能会涉及到去服务器取数据，应该进行抽象。
	/// 如果一个一个取的话，由于要考虑异步，可能会比较复杂：
	/// 最简单的方法是把这个域所有的 entities 定义都提前取出来.

	// 需要考虑不是第一个节点不是 entity 的情况 TODO
	const entityName = roots[firstName].entityName

	const entity = allEntities.find(e => e.name === entityName)

	if(entity === undefined) {
		console.warn(`Entity not found: ${entityName}`);
		return path;
	} else {
		const firstSection = new TypeInfo({
			name: firstName,
			entityName,
			key: firstName,
		})
	
		const remainingPath = plainPath.slice(1)
	
		return [firstSection, ...applyTypeInfoToField(remainingPath, entity, { allEntities, allDataTypes })]
	}
}
