import React from 'react'

// buttons
import BoldButton from './paragraphButtons/BoldButton'
import ItalicButton from './paragraphButtons/ItalicButton'
import UnderlineButton from './paragraphButtons/UnderlineButton'
import SuperscriptButton from './paragraphButtons/SuperscriptButton'
import SubscriptButton from './paragraphButtons/SubscriptButton'
import ColorButton from './paragraphButtons/ColorButton'

import Seperator from './Seperator'
import TextAlignmentButton from './TextAlignmentButton'
import HeadingTwoButton from './HeadingTwoButton'
import HeadingThreeButton from './HeadingThreeButton'
import BlockquoteButton from './BlockquoteButton'
import OrderedListButton from './OrderedListButton'
import UnorderedListButton from './UnorderedListButton'
import CodeButton from './CodeButton'
import HRButton from './HRButton'
import FormatClearButton from './FormatClearButton'
import FormatBrushButton from './FormatBrushButton'
import UndoButton from './UndoButton'
import RedoButton from './RedoButton'
import HyperlinkButton from './HyperlinkButton'
import AIButton from './AIButton'

import ImageAlignButton from './imageButtons/ImageAlignButton'
import RemoveButton from './imageButtons/RemoveButton'

import ImageButton from './ImageButton'
import MediaButton from './MediaButton'

import CaptionButton from './imageButtons/CaptionButton'

import CopyUrlButton from './AudioButtons/CopyUrlButton'
import AudioAlignButton from './AudioButtons/AudioAlignButton'
import BlockTypeButton from './BlockTypeButton'

export default {
  'ai': (props) => <AIButton {...props}></AIButton>,
  
  'bold' : (props) => <BoldButton {...props} />,
  'italic' : (props) => <ItalicButton {...props} />,
  'underline' : (props) => <UnderlineButton {...props} />,
  'sup' : (props) => <SuperscriptButton {...props}/>,
  'sub' : (props) => <SubscriptButton {...props}/>,
  'color': (props) => <ColorButton {...props} />,
  'seperator' : () => <Seperator />,
  'align-left' : (props) => <TextAlignmentButton align={"left"} {...props} />,
  'align-center' : (props) => <TextAlignmentButton align={"center"} {...props} />,
  'align-right' : (props) => <TextAlignmentButton align={"right"} {...props} />,
  'align-justify' : (props) => <TextAlignmentButton align={"justify"} {...props} />,
  'format-clear': (props) => <FormatClearButton {...props}></FormatClearButton>,
  'format-brush': (props) => <FormatBrushButton {...props}></FormatBrushButton>,
  'link': (props) => <HyperlinkButton {...props}></HyperlinkButton>,
  'blockType': (props) => <BlockTypeButton {...props}></BlockTypeButton>,


  'image': (props) => <ImageButton {...props}></ImageButton>,
  'video': (props) => <MediaButton mediaType={'video'} {...props}></MediaButton>,
  'audio': (props) => <MediaButton mediaType={'audio'} {...props}></MediaButton>,

  'h2': (props) => <HeadingTwoButton {...props} />,
  'h3': (props) => <HeadingThreeButton {...props} />,
  'block-quote': (props) => <BlockquoteButton {...props} />,
  'numbered-list': (props) => <OrderedListButton {...props} />,
  'bulleted-list': (props) => <UnorderedListButton {...props} />,
  'code': (props) => <CodeButton {...props} />,
  'hr': (props) => <HRButton {...props} />,
  'undo': (props) => <UndoButton {...props}></UndoButton>,
  'redo': (props) => <RedoButton {...props}></RedoButton>,

  //["left", "center", "right", "link", "remove"]
  'left': (props) => <ImageAlignButton align={"left"} {...props}></ImageAlignButton>,
  'center': (props) => <ImageAlignButton align={"center"} {...props}></ImageAlignButton>,
  'right': (props) => <ImageAlignButton align={"right"} {...props}></ImageAlignButton>,
  'remove': (props) => <RemoveButton {...props}></RemoveButton>,
  'caption': (props) => <CaptionButton {...props}></CaptionButton>,

  // audio
  'copy': (props) => <CopyUrlButton {...props}></CopyUrlButton>,
  'audioLeft': (props) => <AudioAlignButton align={"left"} {...props}></AudioAlignButton>,
  'audioCenter': (props) => <AudioAlignButton align={"center"} {...props}></AudioAlignButton>,
  'audioRight': (props) => <AudioAlignButton align={"right"} {...props}></AudioAlignButton>,
}
