
import BackendItemStrategy from "./BackendItemStrategy";

import { runDataQuery } from 'bwax/query/runClientQuery';
import { toEventHandlerParams } from 'bwax/BwaxExecHelper';

import { unpack } from 'bwax/lang/LangHelper'

import { prepareData, execute } from '../editorUtils';

export default class EventHandlerStrategy extends BackendItemStrategy {


    constructor() {
        super()
    }

    getTestRecordOptions(value, { previewFacade }) {

        const targetEntityName = value["实体名"];
        
        const entity = previewFacade.entities.find(e => e.name == targetEntityName);
        if(!entity) {
            console.warn("Entity not found", targetEntityName);
            return null
        }

        return {
            entityName: "数据更改日志",
            fixedCriteria: {
                "实体KEY": entity.key,
                "日志类型": value["事件类型"],
            },
        }
    }


    async startRunning (code, { value, previewFacade, testRecord }) {
        // 
        const unpacked = unpack(code.compiled);

        const eventType = value["事件类型"];
        const entityName = value["实体名"];

        const journalData = await queryPreparedData(previewFacade.dlc, testRecord.id);

        const recordId = (() => {
            const r = journalData["新增记录"] || journalData["更新后记录"] || {}; // || journalData["删除记录"]
            return r.id
        })();


        const predata = await prepareData(unpacked, recordId, { facade: previewFacade, entityName })

        const params = (() => {

            const entity_dict = previewFacade.entity_dict;
            const data_type_dict = previewFacade.data_type_dict;

            if (eventType == "RecordAdded") {
                // init record: R_食品 -> (Model, Cmd Msg)

                // NEW VERSION
                // init (record, domainEnv): (R_食品, Cmd.DomainEnv) -> (Model, Cmd Msg)

                // 以上是兼容的
                // 没有 params

                return []

            } else if (eventType == "RecordUpdated") {
                // init (updatedRecord, updates): (PR_食品, Updates_of_食品) -> (Model, Cmd Msg) 

                // NEW VERSION:
                // init updates (record, domainEnv): Updates_of_食品 -> (R_食品, Cmd.DomainEnv) -> (Model, Cmd Msg)

                // const record = lang_entry.transform_plain_record(
                //     entity_dict, data_type_dict, entityName, predata["更新后记录"]
                // );
                const updates = lang_entry.transform_updates(
                    previewFacade, entity_dict, data_type_dict, entityName, journalData["更新字段"], journalData["更新前记录"]
                )
                return [updates]

            } else {
                // init deletedRecord: Raw_明星 -> (Model, Cmd Msg)

                // NEW VERSION
                // init deletedRecord (now, domainEnv): Raw_明星 -> (Date, Cmd.DomainEnv) -> (Model, Cmd Msg)
                const param = lang_entry.transform_raw_record(
                    entity_dict, data_type_dict, entityName, journalData["删除记录"]
                )
                return [param]
            }
        })();

        const [n, defs] = unpacked;
        const specifiedParams = toEventHandlerParams(eventType, [n, defs], params, predata);

        const result = await execute(unpacked, { specifiedParams, facade: previewFacade, previewData: code.previewData });

        return {
            type: "one-time-execution",
            needToStop: false,
            data: {
                predata,
                result
            }
        }

    }
}



// query data prepared at backend, given id and paths
async function queryPreparedData(dlc, id) {

    const runQuery = runDataQuery(dlc)

    let queryText = `
        query ($id: RecordJournalId!, $paths: [String]) {
            q: prepareRecordEventData (journalId: $id, paths: $paths) 
        }`

    const result = await runQuery(queryText)({ id, paths: [] })
    const { errors, data } = JSON.parse(result)


    if (data && data.q) {
        return data.q
    } else {
        return undefined
    }

}


