
import { loader } from '@monaco-editor/react';

import { error } from 'antlr4/index'
import { createLexer, validate, createParser } from "bwax/lang/ParserFacade"

import langEntry from 'bwax/ml/lang/lang_entry.bs'

loader.config({
    paths: {
        // vs: "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.31.1/min/vs"
        // vs: "https://unpkg.com/monaco-editor@0.31.1/min/vs"

        // vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.31.1/min/vs"

        vs: "https://cdn.qunfengshe.com/monaco-editor-0.31.1/min/vs"

    }
  });

class BwaxLangState /* implements monaco.languages.IState */ {

    constructor( leadingLines ) {
        this.leadingLines = leadingLines;
    }

    clone() {
        return new BwaxLangState(this.leadingLines)
    }
    equals(other) {
        let al = this.leadingLines;
        let bl = other.leadingLines;
        return (al.length == bl.length) && al.every((v, i) => v == bl[i]);
    }
}

const EOF = -1;

// https://github.com/Microsoft/vscode/blob/master/src/vs/editor/standalone/common/themes.ts#L13
const SCOPES = {
    "number" : [ "NUMBER", "INTEGER", "FLOAT_NUMBER" ],
    "string" : [ "STRING", "CHAR" ],
    "operator": [ 
        "POW", 
        "MUL", "DIV", "MOD", "ADD", "SUB", 
        "GT", "GE", "EQ", "LE", "LT", "NE",
        "AND", "OR", "XOR", "NOT",
        "BIN_OP", "CONS", "APPEND", "PIPE_FROM", "PIPE_TO",
        "COMP_FROM", "COMP_TO",
        "CONSTR_OP",
        "ELLIPSIS", "SHARP", "WAVE"
    ],
    "comment": [ "MultiLineComment", "SingleLineComment"],
    "variable": [ "NAME", "DOT_NAME", "DOT_CAP_NAME" ],
    "type": [ "CAP_NAME", "TRUE", "FALSE" ],
    "keyword": [
        "TYPE", "ALIAS", 
        "IF", "THEN", "ELSE", 
        "CASE", "OF",
        "LET", "REC", "IN", 
        "MODULE", "EXTERNAL", "OPAQUE",
        "SEMICOLON",
        "IMPORT", "EXPOSING", "AS"
    ],
    "error": [ "error" ]
}

class BwaxLangToken /* implements IToken */ {
    constructor(ruleName, tokenText, startIndex) {
        ///
        let scope = "unknown"
        if(ruleName) {
            scope = Object.keys(SCOPES).find(k => {
                const names = SCOPES[k]
                return names.indexOf(ruleName) !== -1
            })
            if(!scope) {
                console.log("Unknown rule name", ruleName, tokenText);
                scope = "unknown"
            }
        } else {
            /// variable ? 这里大部分是 { } [ ] + - 等
            // console.log(ruleName, tokenText)
            if (tokenText == "|" || tokenText == ",") {
                scope = "operator"
            } else if(tokenText == "=" || tokenText == ";" 
                    || tokenText == "->" || tokenText == "\\"
                    || tokenText == ":") {
                scope = "keyword"
            } else {
                scope = "variable"
            }
            
        }
        this.ruleName = ruleName;
        this.scopes = scope;
        this.text = tokenText;
        this.startIndex = startIndex;
    }
}
class BwaxLangLineTokens /* implements ILineTokens */ {
    constructor(tokens, leadingLines) {
        this.endState = new BwaxLangState(leadingLines);
        this.tokens = tokens;
    }
}

class BwaxLangTokensProvider {
    getInitialState() {
        return new BwaxLangState([]);
    }
    tokenize(line, state) {
        // So far we ignore the state, which is not great for performance reasons
        const newLeadingLines = [ ...state.leadingLines, line ];

        const input = line;
        const tokens = tokensForLine(input);
        return new BwaxLangLineTokens(tokens, newLeadingLines);
    }
}

function tokensForLine(input) {
    var errorStartingPoints = []
    class ErrorCollectorListener extends error.ErrorListener {
        syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
            //console.log("TOKEN ERROR", msg);
            errorStartingPoints.push(column)
        }
    }
    const lexer = createLexer(input);
    lexer.removeErrorListeners();
    let errorListener = new ErrorCollectorListener();
    lexer.addErrorListener(errorListener);
    let done = false;
    let myTokens = [];
    do {
        let token = lexer.nextToken();
        if (token == null) {
            done = true
        } else {
            // We exclude EOF
            if (token.type == EOF) {
                done = true;
            } else {
                let tokenTypeName = lexer.symbolicNames[token.type];
                let myToken = new BwaxLangToken(tokenTypeName, token.text, token.column);
                myTokens.push(myToken);
            }
        }
    } while (!done);
    // Add all errors
    for (let e of errorStartingPoints) {
        myTokens.push(new BwaxLangToken("error", "", e));
    }
    myTokens.sort((a, b) => a.startIndex > b.startIndex ? 1 : -1)

    return myTokens;
}

class BwaxLangHoverProvider {

    provideHover (model, position, token) {
        return null;

        let offset = model.getOffsetAt(position);
        let input = model.getValue();

        let typedMeta = langEntry.get_typed_meta (input, offset);
        if (typedMeta) {
            
            let [[text, startIndex, endIndex], typeStr] = typedMeta;

            let startPos = model.getPositionAt(startIndex);
            let endPos = model.getPositionAt(endIndex);

            return {
                range: {
                    startLineNumber: startPos.lineNumber,
                    startColumn: startPos.column,
                    endLineNumber: endPos.lineNumber,
                    endColumn: endPos.column
                },
                contents: [
                    {
                        value: text
                    },
                    {
                        value: typeStr
                    }
                ]
            }
        } else {
            return null
        }
    }

};

////
let mnc = undefined;

loader.init().then(m => {
    
    mnc = m;  // initialize
    m.languages.register({ id: 'bwax' });
    m.languages.setTokensProvider('bwax', new BwaxLangTokensProvider());

    m.languages.setLanguageConfiguration('bwax', {
        comments: {
            lineComment: "--"
        }
    })

    m.languages.registerHoverProvider('bwax', new BwaxLangHoverProvider())

    m.editor.defineTheme('bwax-light', {
        base: 'vs',
        // inherit: true,
        rules: [
            { token: '', background: 'FEFCF8' },
            { token: 'comment', foreground: '93a1a1' },
		    { token: 'number', foreground: '008E00' },
            { token: 'string', foreground: 'df0002' },
            { token: 'variable', foreground: '383838' },
            { token: 'type', foreground: '008E80' },
            { token: 'operator', foreground: '8899AA' },
            { token: 'keyword', foreground: 'c800a4'},
            { token: 'error', foreground: 'ff0000' },
        ],

        colors: {
            "editor.foreground": "#383838",
            "editor.background": "#FEFCF8",
            "editor.selectionBackground": "#EEE8D5",
            "editor.lineHighlightBackground": "#00000012",
            "editorCursor.foreground": "#383838",
            "editorWhitespace.foreground": "#BFBFBF"
          }
    });

    // m.editor.defineTheme('bwax-dark', {
    //     base: 'vs-dark',
    //     inherit: true,
    //     rules: [
    //         { token: '', foreground: 'D4D4D4', background: '1E1E1E' },
    //         { token: 'comment', foreground: '608B4E' },
	// 	    { token: 'number', foreground: 'B5CEA8' },
    //         { token: 'string', foreground: 'CE9178' },
    //         { token: 'variable', foreground: '84C0FA' },
    //         { token: 'type', foreground: '3DC9B0' },
    //         { token: 'operator', foreground: '8899AA' },
    //         { token: 'keyword', foreground: '3987CC'},
    //         { token: 'error', foreground: 'ff0000' },
    //     ]
    // });



})

export function getMonaco () {
    return mnc;
}