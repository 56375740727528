import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';

import './TableView.less'

import Popover from 'bwax-ui/components/legacy/Popover';

import Tooltip from 'bwax-ui/components/Tooltip';

import { InfoCircleOutlined, MoreOutlined } from "@ant-design/icons"

import { MemoTable } from 'Client/js/ui/components/DataTable'

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import { conditionToExpr } from 'Client/js/builders/builderUtils'

import Drawer from 'Client/js/ui/widgets/Drawer';

import { hashCode } from 'bwax/utils'

import RecordFilter from 'Client/js/components/RecordFilter';

import { make as IconButton } from 'Client/re/widgets/IconButtonWidget.bs';
import { make as Input_TableColumnConfig, getStoredHiddenColumns } from 'Client/re/widgets/input/Input_TableColumnConfig.bs';

import ExcelExport from 'Client/js/ui/widgets/ExcelExport'

import { mergeCriteria, buildSorting } from 'bwax/query/resolveAndRunQuery'

import { saveToLocalStorage, getFromLocalStorage } from 'bwax-ui/re/utils/DomUtils.bs'

import useQueriedValueWithoutState from 'bwax-ui/legacy/page/hooks/useQueriedValueWithoutState'

import SimpleFieldEdit from './SimpleFieldEdit'

import { make as BwButton } from 'Client/re/widgets/BwButton.bs'
import ClampedText from 'bwax-ui/components/ClampedText';

// common buttion action builder:
function buildActionButton(dataEntity, contextEntity, options) {

    const {
        allEntities, allDataTypes,
        type,
        condition,
        disabled = false,
        label,
        confirm,
        buttonType = "default",
        ghostButton = false,

        actions,
        reload,
        contextRecordId

    } = options
    //
    if (!condition) {
        return null
    }

    const actionConverters = {
        "UpdateRecord": op => {
            return async () => {

                await actions.updateRecord({
                    entityName: contextEntity.name,
                    id: contextRecordId,
                    formData: (op.updateValue || {})
                });

                await actions.adminMessage({
                    level: "success",
                    text: op.successMessage || '操作成功',
                });

                await reload();

                // 三秒和7秒之后再 reload
                setTimeout(() => {
                    reload();
                }, 3000)

                setTimeout(() => {
                    reload();
                }, 7000)

            }
        }
    }

    const defaultConverter = () => {
        return {
            type: "Log",
            // value: "没有合适的 Action"
            params: {
                dataEntity,
                contextEntity,
                "id": "${id}"
            }
        }
    }

    const getAction = actionConverters[type] || defaultConverter

    const actualConfirm =
        confirm ?
            (typeof (confirm) == "string" ? { title: confirm } : confirm) :
            undefined;

    // condition 不处理吗?

    return {
        type_: "admin::Button",
        label,
        confirm: actualConfirm,
        buttonType,
        ghostButton,
        disabled: disabled ? conditionToExpr("contextRecord", disabled, contextEntity) : false,
        onClick: getAction(options)
    }

}

const actionDefsBuilders = {
    AddRecord: (dataEntity, contextEntity, options) => {

        const { disabled, condition, label } = options;
        if (!condition) {
            return null
        }
        return {
            type_: "admin::AddRecordButton",
            entityName: dataEntity.name,
            contextEntityName: contextEntity.name,
            label,
            condition,
            disabled,
            options
        }
    },
    UpdateRecord: buildActionButton,
    AnyWidget: (_dataEntity, _contextEntity, options) => {
        const { name, params, reload } = options;
        return {
            type_: name,
            params: {
                ...params,
                onSave: () => {
                    reload();
                }
            }
        };
    }
}

const pageSizeOptions = [20, 50, 100, 200];
const defaultPageSize = 20;

//
function getDataDef(
    {
        entityName, dataName, contextEntityName, contextRecordId,
        dataEntity, contextEntity,
        pagination, criteria, sortBy,
        fields, operations,
        allDataTypes, allEntities,
        currentPage = 1,
        allowedActions,
        pullUpLoading
    }) {

    const queryRecord = (entityName != contextEntityName && contextEntityName) ? {
        contextRecord: {
            entityName: contextEntityName,
            type: "findOne",
            criteria: {
                id: contextRecordId
            }
        }
    } : {};


    function getPagination() {
        if (pullUpLoading) {
            /// 如果是上拉加载，则不考虑 currentPage
            return pagination
        } else {
            //
            const { pageSize } = pagination;

            const offset = (currentPage - 1) * pageSize;

            // console.log(pageSize, currentPage);
            if (offset == 0) {
                return pagination
            } else {
                return {
                    pageSize,
                    __after__: offset + ""
                }
            }
        }
    }


    const targetDef = {
        __query__: {
            // if context name
            ...queryRecord,
            [dataName]: {
                entityName,
                type: 'listAll',
                pagination: getPagination(),
                sortBy: sortBy || {
                    创建时间: 'DESC'
                },
                criteria: criteria || {}
                /// TODO 目前，criteria 一定要加上去，才能进行 partialVariables 的 refetch
                /// 未来应该改善。
            },
            __for__: {
                rows: {
                    __apply__: {
                        list: "${data}",
                        map: [
                            "${value.id}",
                            fields.map(f => {
                                // 针对每一个 field， 举个 FieldDisplay 得出所需的值
                                const { valueDef } = getFieldDisplay(f);
                                return valueDef("value", f, { allEntities, allDataTypes })
                            }),
                            Object.keys(operations).map(key => {
                                const op = operations[key]
                                return {
                                    condition: op.condition ? conditionToExpr("value", op.condition, dataEntity) : true,
                                    disabled: op.disabled ? conditionToExpr("value", op.disabled, dataEntity) : false,
                                }
                            })
                        ]
                    }
                },
                totalCount: "${count(data)}",
                actions: allowedActions.map((action => {
                    return {
                        condition: action.condition ? conditionToExpr("contextRecord", action.condition, contextEntity) : true,
                        disabled: action.disabled ? conditionToExpr("contextRecord", action.disabled, contextEntity) : false,
                    }
                }))
            }
        }
    }

    return targetDef;
}


export default function TableView(props) {

    // 在这里，要构建一个完整的 Data Table，包括
    // 1) Table 本身，以及加载更多的功能
    // 2) 筛选器
    // 3) 三个功能按钮
    // 4) ”新增记录“按钮

    const t0 = performance.now();

    const { params, ...env } = props

    const {
        entityName,
        fields, identifyingField,
        showRowNum = true,
        operations,
        actions,
        // topRightButtonDefs,

        tabID,

        allowedActions,

        criteria, sortBy, exportAsExcel,

        contextEntityName, contextRecordId,

        pagination,

        pullUpLoading = false,

        noFilter = false,
        

    } = params;

    const dataName = "data";

    const { allEntities, allDataTypes, renderWidget, baseData, queryTarget } = env;

    const dataEntity = allEntities.find(e => e.name == entityName);
    const contextEntity = allEntities.find(e => e.name === contextEntityName);

    const [currentPage, setCurrentPage] = useState(1);

    const [pageSize, setPageSize] = useState(getStoredPageSize(tabID) || pagination.pageSize || defaultPageSize);


    // the def to load data:
    const dataDef = getDataDef({
        entityName, dataName, contextEntityName, contextRecordId,
        criteria,
        pagination: {
            ...(pagination || {}),
            pageSize
        },
        sortBy,
        fields, operations,
        dataEntity, contextEntity,
        pullUpLoading,
        currentPage,
        allowedActions,
        allEntities, allDataTypes
    });


    // state begin
    const {
        value: data,
        reload,

    } = useQueriedValueWithoutState(dataDef, {
        allDataTypes,
        allEntities,
        baseData,
        roots: {
            "当前用户": {
                entityName: "用户"
            }
        },
        queryTarget,
    })
    // state end

    const [lastData, setLastData] = useState(undefined);

    const shouldReload = useRef(true);
    useEffect(() => {
        // console.log("Should Reload ", data, shouldReload.current)
        if (data !== undefined) {
            // console.log("是否要 reload", shouldReload.current);
            if (shouldReload.current === true) {
                shouldReload.current = false; // 只 reload 一次就好了。

                // Reload 的逻辑，可能需要优化 TODO
                // setTimeout(() => {
                //     reload()
                // }, 4);
            }
            // console.log("Set last data", data);
            setLastData(data);
        } else {
            // /// 遇到了 undefined，这意味着当前是新加载在的数据，不需要 reload
            // shouldReload.current = false
        }

    }, [data])

    const valueRenderer = field => {

        const display = getFieldDisplay(field);

        const linkPattern = (() => {
            const { name, linkPattern } = identifyingField || {}
            if (name === field.name) {
                return linkPattern /// it may include a :id var
            }
            return undefined
        })();

        const configParams = field.config && field.config.displayParams ? field.config.displayParams : {};
        const customParams = (() => {
            if (field.type === 'Image') {
                return {
                    width: 40,
                    height: 40,
                    processor: 'small',
                    ...configParams
                }
            }
            return configParams
        })()

        const Component = display.component;
        return (value, record, index) => {

            // console.log("Render value", value);

            const [id, _values, _ops] = record;
            return <Component value={value} customParams={{
                ...customParams,
                linkPattern: linkPattern && linkPattern.replace(":id", id)
            }} field={field} env={{
                ...env
            }} />
        }
    }

    const getFieldIndex = field => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].name === field.name) {
                return i
            }
        }
        return -1;
    }

    const fixedLeftColumns = identifyingField ? [{
        name: identifyingField.name,
        width: 200,
        fixed: 'left',
        getValue: ([_, record]) => record[getFieldIndex(identifyingField)],
        renderValue: valueRenderer(identifyingField)
    }] : []


    const otherColumns = fields.map((f, index) => {
        // console.log(f);
        let customWidths = {
            "Select": 160,
            "File": 360,
            "RichText": 360,
            "JSON": 360,
            "JSONB": 360,
            "ShortText": 200,
            "Link": 180,
            "Text": 320,
            "Integer": 100,
            "Number": 160,
            "Date": 160,
            "DateTime": 180,
            "Boolean": 140,
            "Image": 140,
            "YQYLTypePosterSetting": 360
        }

        let customAligns = {
            "Number": "right",
            "Integer": "right",
        }

        let config = f.config || {}
        let baseWidth = config.width || customWidths[f.type] || 240

        if(f.multivalued) {
            baseWidth = baseWidth * 1.6;
        }

        let name = f.cname || f.name;
        let desc = f.desc;
        // 有文字又有tip，至少 200 px 吧
        // TODO 更智能的初始化 width
        let width = name && name.length > 5 && (desc && desc != name) & baseWidth < 200 ? 200 : baseWidth;

        return {
            name,
            path: f.name,
            desc,
            width,
            dataType: f.type,
            align: customAligns[f.type] || "left",
            sortable: f.sortable === true,
            renderValue: valueRenderer(f),
            getValue: ([_, record]) => record[index],
            ...(f.updatable && config.editingAllowed ? {
                renderEdit: (value, record, index, closeEdit) => {
                    return (
                        <SimpleFieldEdit
                            data={data}
                            record={record}
                            field={f}
                            fieldsToUse={fields}
                            initialValue={value}
                            closeEdit={closeEdit}
                            contextEntityName={contextEntityName}
                            dataEntity={dataEntity}
                            reload={reload}
                            actions={actions}
                            {...env}
                        />
                    )
                }
            } : {})
        }
    }).filter(c => identifyingField === undefined || identifyingField.name !== c.name)


    let ops = operations || {
        "查看": {
            type: "GotoDetail"
        }
    };

    // actions
    const actionConverters = {
        "GotoDetail": (op, [id, _]) => {
            return async () => {
                let url = "/entities/" + dataEntity.key + "/records/" + id;
                if (identifyingField && identifyingField.linkPattern) {
                    url = identifyingField.linkPattern.replace("\:id", id);
                }

                if (op.tab) {
                    url = url + "/" + op.tab;
                }
                return actions.goTo({ url })
            }
        },
        "UpdateRecord": (op, [id, _]) => {
            return async () => {
                await actions.updateRecord({
                    entityName,
                    id,
                    formData: (op.updateValue || {})
                });

                await actions.adminMessage({
                    level: "success",
                    text: op.successMessage || '操作成功',
                });

                await reload({});
            }
        },
        "DeleteRecord": (op, [id, _]) => {
            return async () => {
                await actions.deleteRecord({
                    entityName,
                    id
                });

                await actions.adminMessage({
                    level: "success",
                    text: op.successMessage || '删除成功',
                });
                await reload({});
            }
        }
    }

    let operationColumn = undefined;

    if (ops && Object.keys(ops).length > 0) {
        // let width = Object.keys(ops).length > 1 ? 200 : 120;
        let width = 128;
        operationColumn = {
            name: '操作',
            width,
            fixed: 'right',
            align: 'center',
            getValue: r => r,
            renderValue: (r) => {

                const [id, values, opConfigs] = r;

                const operationButtons = Object.keys(ops).map((label, index) => {
                    const op = ops[label];

                    // condition 和 disabled 的判断放在 DEF 层面
                    const config = (opConfigs && opConfigs[index]) || {};
                    if (config.condition == false) {
                        return null
                    }

                    const onClick = (actionConverters[op.type] || (_ => (_ => console.log("No Action"))))(op, r);

                    const confirm = op.confirm ? { title: op.confirm } : undefined;

                    return [
                        label,
                        (inMenu = false) => {
                            return (
                                <BwButton
                                    params={{
                                        label, confirm,
                                        buttonType: op.buttonType, ghostButton: op.ghostButton,
                                        color: op.buttonColor,
                                        disabled: config.disabled === true,
                                        onClick, 
                                        inMenu
                                    }}
                                />
                            )
                        }
                    ]

                })

                // 1. 取第一个显示成 Button
                // 2. 其他（如果有的话），则收在“更多”菜单里面

                // const
                const [firstButton, ...otherButtons] = operationButtons.filter(x => !!x);
                
                if (!firstButton) {
                    return null
                } else {

                    const getIcon = () => {
                        if (otherButtons.length > 0) {
                            const buttonMenu = (
                                <div className="moreButtonMenu">
                                    { otherButtons.map(([label, renderer]) => {
                                        return (
                                            <div key={label}>
                                                { renderer(true)}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                            return (
                                <Popover 
                                    positions={["left", "bottom"]}
                                    trigger="hover" 
                                    align="start"
                                    content={buttonMenu}
                                    className="table-operations">
                                    <MoreOutlined style={{ fontSize: 14, marginLeft: 12, cursor: "pointer" }} />
                                </Popover>
                            )
                        } else {
                            return <MoreOutlined type="more" style={{ fontSize: 14, marginLeft: 12, opacity: 0.2 }} />
                        }
                    }

                    return (
                        <div className="table-button-box">
                            {firstButton[1](false)}
                            {getIcon()}
                        </div>
                    )
                }
            }
        }
    }

    let columns = [
        ...fixedLeftColumns,
        ...otherColumns,
        ...(operationColumn ? [operationColumn] : [])
    ]

    const tableColumns = getTableColumns(columns, showRowNum, { currentPage, pageSize })

    const conditionFields = fields.filter(
        f => (
            (f.type === 'ShortText' || f.type === 'Select' || f.type === 'Link' || f.type === 'Text') ||
            ((f.type === 'Boolean' || f.type === 'Date' || f.type === 'DateTime' || f.type === 'Number') && !f.multivalued)
        ) && f.filterable
    );
    const [filter, setFilter] = useState({});
    const [tableConfig, setTableConfig] = useState({
        hiddenColumns: getStoredHiddenColumns(tabID),
        fixedLeftColumns: getStoredFixedLeftColumns(tabID),
        columnWidths: getStoredColumnWidths(tabID)
    });

    useEffect(() => {
        if(filter) {            
            reload({
                [dataName]: {
                    criteria: filter
                }
            });            
        }
        setCurrentPage(1);
    }, [JSON.stringify(filter)])

    const topRightButtons =
        <>
            <Input_TableColumnConfig
                params={{
                    tip: "隐藏数据列",
                    fieldsToUse: fields,
                    tabID
                }}
                value={tableConfig}
                onChange={v => setTableConfig(v)}
                {...env}
            />
            <ExportButton
                entityName={entityName}
                fields={fields}
                criteria={criteria}
                sortBy={sortBy}
                filter={filter}
                exportAsExcel={exportAsExcel}
                env={env}
            />
            <IconButton params={{
                icon: "sync",
                tip: "刷新数据",
                onClick: () => reload()
            }} {...env} />

            <MemoActionButtons
                data={data}
                allowedActions={allowedActions}
                actions={actions}
                dataEntity={dataEntity}
                contextEntity={contextEntity}
                contextRecordId={contextRecordId}
                reload={reload}
                renderWidget={renderWidget}
                allEntities={allEntities}
                allDataTypes={allDataTypes}
            />
        </>

    // console.log("tableConfig >>> ", tableConfig, columnsToShow)

    const t1 = performance.now();

    console.log("TableView", t1 - t0);

    return (
        <div className="admin--table-view">
            <div className="topbar">
                <div className="top-left-bar">
                    {noFilter ? null :
                        <RecordFilter
                            {...{
                                onChange: filter => {
                                    // reload({
                                    //     [dataName]: {
                                    //         criteria: filter
                                    //     }
                                    // });
                                    setFilter(filter);
                                },
                                conditionFields,
                                entityName,
                                contextEntityName,
                                contextRecordId,

                                value: filter,

                                allEntities, allDataTypes
                            }} 
                        />
                    }
                </div>
                <div className="top-right-bar"> {topRightButtons} </div>
            </div>
            <MemoTable
                data={data || lastData}
                dataName={dataName}
                currentPage={currentPage}
                tableConfig={tableConfig}

                tableColumns={tableColumns}
                pagination={pagination}

                getRowKey={(index, record) => {
                    if(record) {
                        const [ id, _values, _ops ] = record;
                        return id
                    } else {
                        return index
                    }
                }}

                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                onPageSizeChange={ps => {
                    shouldReload.current = true; // try reload

                    saveToLocalStorage(`${tabID}.pageSize`, ps + "");

                    // 
                    ReactDOM.unstable_batchedUpdates(() => {
                        setPageSize(ps)
                        setCurrentPage(1);
                    })
                }}


                setTableConfig={config => {
                    setTableConfig(prev => ({
                        ...prev,
                        ...config
                    }));
                    if(config && tabID) {
                        const { fixedLeftColumns, columnWidths } = config;
                        // save local tabID
                        fixedLeftColumns ? saveToLocalStorage(`${tabID}.fixedLeftColumns`, JSON.stringify(fixedLeftColumns)) : null;
                        columnWidths ? saveToLocalStorage(`${tabID}.columnWidths`, JSON.stringify(columnWidths)) : null;
                    }
                }}
                onSort={({ orders }) => {
                    const sortBy = Object.keys(orders).reduce((acc, k) => {
                        const path = tableColumns.find(c => c.name == k).path  // 假定肯定可以找到
                        return {
                            [path]: orders[k],
                            ...acc
                        }
                    }, {});
                    reload({ [dataName]: { sortBy } });
                }}
                onPageChange={p => {
                    shouldReload.current = true; // try reload
                    setCurrentPage(p)
                }}
            />
        </div>
    )
}

const MemoActionButtons = React.memo(({
    data,
    allowedActions,
    actions,
    dataEntity, contextEntity, contextRecordId,
    reload, renderWidget,
    allEntities, allDataTypes
}) => {

    const topRightButtonDefs = [
        ...allowedActions.reduce((acc, allowedAction, index) => {
            const defaultBuilder = () => null
            const builder = actionDefsBuilders[allowedAction.type] || defaultBuilder

            const actionSetting = data ? data.actions[index] : {}

            const result = builder(dataEntity, contextEntity, {
                allEntities,
                allDataTypes,
                actions, // action template
                reload,
                contextRecordId,
                ...(allowedAction.options || allowedAction),
                ...actionSetting,
            })

            /// FLAT MAP
            return [
                ...acc,
                ...(Array.isArray(result) ? result : [result])
            ]
        }, [])
    ].filter(x => !!x);

    return renderWidget(topRightButtonDefs || [])

}, (prev, current) => prev.data == current.data)



function getStoredFixedLeftColumns(tabID) {
    const storedKey = `${tabID}.fixedLeftColumns`
    const fixedLeftColumnsStr = getFromLocalStorage(storedKey)
    if (fixedLeftColumnsStr) {
        return JSON.parse(fixedLeftColumnsStr)
    } else {
        return undefined
    }
}

function getStoredColumnWidths(tabID) {
    const storedKey = `${tabID}.columnWidths`
    const columnWidthsStr = getFromLocalStorage(storedKey)
    if (columnWidthsStr) {
        return JSON.parse(columnWidthsStr)
    } else {
        return undefined
    }
}

function getStoredPageSize(tabID) {
    const storedKey = `${tabID}.pageSize`
    const pageSizeStr = getFromLocalStorage(storedKey)
    if (pageSizeStr) {
        let pageSize = parseInt(pageSizeStr);
        if (!isNaN(pageSize)) {
            return pageSize
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

function getTableColumns(columns, showRowNum, { currentPage, pageSize }) {

    const baseNum = (currentPage - 1) * pageSize;

    const initialColumns = showRowNum ? [{
        title: '#',
        key: '#',
        getValue: (_, index) => <ClampedText style={{ opacity: 0.6, fontSize: 11 }} text={index + 1 + (baseNum || 0)} />,
        width: 48,
        fixed: 'left'
    }] : []

    const tableColumns = columns.map(col => {
        const columnDef = typeof (col) === 'string' ? { name: col } : col

        const { name, desc } = columnDef

        const clampedName = <ClampedText text={name} />;

        return ({
            ...columnDef,
            key: name,
            title: !!desc && desc !== name ? (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    {clampedName}
                    <Tooltip text={desc}>
                        <InfoCircleOutlined style={{ marginLeft: "0.25rem", fontSize: 10, opacity: 0.6 }} />
                    </Tooltip>
                </div>
            ) : clampedName,
        })
    })

    return [
        ...initialColumns,
        ...tableColumns
    ]

}


//// get export button as

function ExportButton({ entityName, criteria = {}, sortBy = {}, fields = [], exportAsExcel, env, filter }) {

    const [drawerVisible, setDrawerVisible] = useState(false);

    if (exportAsExcel.hidden == true) {
        return null
    }

    const excludedColumns = exportAsExcel.excludedColumns || [];

    const actualFieldsToUse = fields.filter(f => {
        const cname = f.cname || f.name;
        return excludedColumns.indexOf(cname) == -1
    })

    const { allEntities, allDataTypes } = env;

    const fieldNames = actualFieldsToUse.map(f => f.name)
    const scene = entityName + "-" + hashCode(JSON.stringify({ entityName, criteria, sortBy, fieldNames }))

    const entity = allEntities.find(e => e.name === entityName)

    // prepare the properties of new Export Job.
    // 主要是为了解决 moment value 的问题
    const additionalFilters = filter ? JSON.parse(JSON.stringify(filter)) : filter;

    const additionalFilterNames = Object.keys(additionalFilters || {});
    const desc = additionalFilterNames.length > 0 ? "筛选条件: " + additionalFilterNames.join("; ") : undefined;                    

    const conditions = mergeCriteria(criteria, additionalFilters);
    const sorting = buildSorting(entity, sortBy);


    return (
        <>
            <IconButton params={{
                icon: "export",
                tip: "导出Excel",
                onClick: () => setDrawerVisible(true)
            }} {...env} />
            <Drawer
                params={{
                    visible: drawerVisible,
                    nohead: true,
                    hide: () => setDrawerVisible(false),

                    content: (
                        <ExcelExport
                           params={{
                                entityName,
                                desc,
                                conditions,
                                sorting,
                                fieldNames,
                                scene,                                                                                            
                            }}
                            allEntities={allEntities}
                            allDataTypes={allDataTypes}

                        />
                    )
                }}
                {...env}
            />
        </>
    )
}
