// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var DomUtils$BwaxAdmin = require("../../../../lib/bwax-ui/re/utils/DomUtils.bs.js");
var JsonUtils$BwaxAdmin = require("../../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var React$1 = require("@monaco-editor/react");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Input_PageContentPlain(Props) {
  var params = Props.params;
  var value = Props.value;
  var onChange = Props.onChange;
  var refresh = params.refresh;
  var editor = React.useRef(undefined);
  var cachingKey = Belt_Option.getWithDefault(Belt_Option.map(DomUtils$BwaxAdmin.$$location, (function (l) {
              return l.pathname;
            })), "/") + "_editing_code_";
  var match = React.useState((function () {
          var passedIn = JSON.stringify(value, null, 2);
          return Belt_Option.getWithDefault(DomUtils$BwaxAdmin.getFromLocalStorage(cachingKey), passedIn);
        }));
  var editorDidMount = function (param, e) {
    editor.current = Caml_option.some(e);
    return e.onDidChangeModelContent((function (param) {
                  var value = e.getValue();
                  var match = JsonUtils$BwaxAdmin.parse(value);
                  if (match !== undefined) {
                    DomUtils$BwaxAdmin.removeFromLocalStorage(cachingKey);
                    return Curry._1(onChange, Caml_option.valFromOption(match));
                  } else {
                    return DomUtils$BwaxAdmin.saveToLocalStorage(cachingKey, value);
                  }
                }));
  };
  var onKeyDown = function ($$event) {
    if ($$event.metaKey === true && $$event.key === "r") {
      $$event.preventDefault();
      console.log("I will refresh");
      Belt_Option.map(refresh, (function (r) {
              return Curry._1(r, /* () */0);
            }));
    }
    return /* () */0;
  };
  return React.createElement("div", {
              className: Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.width(/* `percent */Caml_chrome_debugger.polyVar("percent", [
                              -119887163,
                              100.0
                            ])),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.height(Css.pct(100.0)),
                          /* [] */0
                        ])
                    ])),
              onKeyDown: onKeyDown
            }, React.createElement(React$1.default, {
                  value: match[0],
                  theme: "vs",
                  language: "json",
                  editorDidMount: editorDidMount,
                  options: {
                    fontSize: 13,
                    autoIndent: "brackets"
                  }
                }));
}

var make = Input_PageContentPlain;

exports.make = make;
/* Css Not a pure module */
