// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_SetInt = require("bs-platform/lib/js/belt_SetInt.js");
var Belt_SetString = require("bs-platform/lib/js/belt_SetString.js");
var Plate$BwaxAdmin = require("./plate.bs.js");

function singleton(v) {
  return Belt_SetString.add(Belt_SetString.empty, v);
}

function insert(v, set) {
  return Belt_SetString.add(set, v);
}

function remove(v, set) {
  return Belt_SetString.remove(set, v);
}

function remove_many(vs, set) {
  return Belt_SetString.removeMany(set, Curry._1(Plate$BwaxAdmin.List.to_array, vs));
}

var is_empty = Belt_SetString.isEmpty;

function has(v, set) {
  return Belt_SetString.has(set, v);
}

var subset = Belt_SetString.subset;

function superset(first, second) {
  return Belt_SetString.subset(second, first);
}

var eq = Belt_SetString.eq;

function every(f, set) {
  return Belt_SetString.every(set, f);
}

function some(f, set) {
  return Belt_SetString.some(set, f);
}

var maximum = Belt_SetString.maximum;

var minimum = Belt_SetString.minimum;

var size = Belt_SetString.size;

var union = Belt_SetString.union;

var intersect = Belt_SetString.intersect;

var diff = Belt_SetString.diff;

var to_list = Belt_SetString.toList;

var to_array = Belt_SetString.toArray;

function from_list(l) {
  return Belt_SetString.fromArray(Curry._1(Plate$BwaxAdmin.List.to_array, l));
}

var from_array = Belt_SetString.fromArray;

function foldl(f, init, set) {
  return Belt_SetString.reduce(set, init, f);
}

function map(f, set) {
  return Belt_SetString.reduce(set, Belt_SetString.empty, (function (acc, v) {
                var v$1 = Curry._1(f, v);
                return Belt_SetString.add(acc, v$1);
              }));
}

function filter(f, set) {
  return Belt_SetString.keep(set, f);
}

function partition(f, set) {
  return Belt_SetString.partition(set, f);
}

var $$String = {
  empty: Belt_SetString.empty,
  singleton: singleton,
  insert: insert,
  remove: remove,
  remove_many: remove_many,
  is_empty: is_empty,
  has: has,
  member: has,
  subset: subset,
  superset: superset,
  eq: eq,
  every: every,
  some: some,
  maximum: maximum,
  minimum: minimum,
  size: size,
  union: union,
  intersect: intersect,
  diff: diff,
  to_list: to_list,
  to_array: to_array,
  from_list: from_list,
  from_array: from_array,
  foldl: foldl,
  map: map,
  filter: filter,
  partition: partition
};

function singleton$1(v) {
  return Belt_SetInt.add(Belt_SetInt.empty, v);
}

function insert$1(v, set) {
  return Belt_SetInt.add(set, v);
}

function remove$1(v, set) {
  return Belt_SetInt.remove(set, v);
}

function remove_many$1(vs, set) {
  return Belt_SetInt.removeMany(set, Curry._1(Plate$BwaxAdmin.List.to_array, vs));
}

var is_empty$1 = Belt_SetInt.isEmpty;

function has$1(v, set) {
  return Belt_SetInt.has(set, v);
}

var subset$1 = Belt_SetInt.subset;

function superset$1(first, second) {
  return Belt_SetInt.subset(second, first);
}

var eq$1 = Belt_SetInt.eq;

function every$1(f, set) {
  return Belt_SetInt.every(set, f);
}

function some$1(f, set) {
  return Belt_SetInt.some(set, f);
}

var maximum$1 = Belt_SetInt.maximum;

var minimum$1 = Belt_SetInt.minimum;

var size$1 = Belt_SetInt.size;

var union$1 = Belt_SetInt.union;

var intersect$1 = Belt_SetInt.intersect;

var diff$1 = Belt_SetInt.diff;

var to_list$1 = Belt_SetInt.toList;

var to_array$1 = Belt_SetInt.toArray;

function from_list$1(l) {
  return Belt_SetInt.fromArray(Curry._1(Plate$BwaxAdmin.List.to_array, l));
}

var from_array$1 = Belt_SetInt.fromArray;

function foldl$1(f, init, set) {
  return Belt_SetInt.reduce(set, init, f);
}

function map$1(f, set) {
  return Belt_SetInt.reduce(set, Belt_SetInt.empty, (function (acc, v) {
                var v$1 = Curry._1(f, v);
                return Belt_SetInt.add(acc, v$1);
              }));
}

function filter$1(f, set) {
  return Belt_SetInt.keep(set, f);
}

function partition$1(f, set) {
  return Belt_SetInt.partition(set, f);
}

var Int = {
  empty: Belt_SetInt.empty,
  singleton: singleton$1,
  insert: insert$1,
  remove: remove$1,
  remove_many: remove_many$1,
  is_empty: is_empty$1,
  has: has$1,
  member: has$1,
  subset: subset$1,
  superset: superset$1,
  eq: eq$1,
  every: every$1,
  some: some$1,
  maximum: maximum$1,
  minimum: minimum$1,
  size: size$1,
  union: union$1,
  intersect: intersect$1,
  diff: diff$1,
  to_list: to_list$1,
  to_array: to_array$1,
  from_list: from_list$1,
  from_array: from_array$1,
  foldl: foldl$1,
  map: map$1,
  filter: filter$1,
  partition: partition$1
};

exports.$$String = $$String;
exports.Int = Int;
/* Plate-BwaxAdmin Not a pure module */
