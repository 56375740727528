// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReactElBridge = require("./ReactElBridge");

function create_div(prim, prim$1) {
  return ReactElBridge.create_div(prim, prim$1);
}

function div(className, key, children, param) {
  var props = {
    className: className,
    key: key
  };
  return ReactElBridge.create_div(props, children);
}

exports.create_div = create_div;
exports.div = div;
/* ./ReactElBridge Not a pure module */
