// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Antd$BwaxAdmin = require("../binding/antd/Antd.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Popconfirm = require("bwax-ui/components/Popconfirm");

function toDef(label, buttonType, disabled, onClick, size, confirm, param) {
  return {
          type: "admin::Button",
          params: {
            label: label,
            buttonType: Belt_Option.map(buttonType, (function (type_) {
                    if (type_ >= 324570959) {
                      if (type_ >= 512905955) {
                        if (type_ >= 568403505) {
                          return "dashed";
                        } else {
                          return "danger";
                        }
                      } else if (type_ >= 465819841) {
                        return "default";
                      } else {
                        return "ghost";
                      }
                    } else if (type_ >= 58474434) {
                      return "primary";
                    } else {
                      return "link";
                    }
                  })),
            disabled: disabled,
            onClick: onClick,
            size: Belt_Option.map(size, (function (s) {
                    if (s !== -9768761) {
                      if (s >= 465819841) {
                        return "default";
                      } else {
                        return "large";
                      }
                    } else {
                      return "small";
                    }
                  })),
            confirm: Belt_Option.map(confirm, (function (cf) {
                    return {
                            okText: cf[/* okText */0],
                            cancelText: cf[/* cancelText */1],
                            title: cf[/* title */2],
                            positions: cf[/* positions */3],
                            align: cf[/* align */4]
                          };
                  }))
          }
        };
}

function Button(Props) {
  var params = Props.params;
  var label = Belt_Option.getWithDefault(params.label, "点击");
  var buttonType = Belt_Option.getWithDefault(params.buttonType, "default");
  var disabled = Belt_Option.getWithDefault(params.disabled, false);
  var onClick = Belt_Option.getWithDefault(params.onClick, (function (param) {
          return /* () */0;
        }));
  var style = params.style;
  var size = Belt_Option.getWithDefault(params.size, "default");
  var emptyStyle = { };
  var buttonStyle;
  if (style !== undefined) {
    var s = Caml_option.valFromOption(style);
    buttonStyle = Belt_List.reduce(Belt_List.keepMap(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "margin",
                  s.margin
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "maxWidth",
                      s.maxWidth
                    ],
                    /* [] */0
                  ])
              ]), (function (param) {
                var k = param[0];
                return Belt_Option.map(param[1], (function (v) {
                              return /* tuple */[
                                      k,
                                      v
                                    ];
                            }));
              })), emptyStyle, (function (style, param) {
            return ReactDOMRe.Style.unsafeAddProp(style, param[0], param[1]);
          }));
  } else {
    buttonStyle = emptyStyle;
  }
  var ghost = Belt_Option.getWithDefault(params.ghostButton, false);
  var match = params.confirm;
  if (match !== undefined) {
    var confirm = Caml_option.valFromOption(match);
    var positions = Belt_Option.getWithDefault(confirm.positions, /* array */["top"]);
    var align = Belt_Option.getWithDefault(confirm.align, "center");
    var okText = Belt_Option.getWithDefault(confirm.okText, "确定");
    var cancelText = Belt_Option.getWithDefault(confirm.cancelText, "取消");
    var title = Belt_Option.getWithDefault(confirm.title, "您确定？");
    if (disabled) {
      return React.createElement(Antd$BwaxAdmin.Button.make, {
                  type_: buttonType,
                  disabled: disabled,
                  size: size,
                  style: buttonStyle,
                  ghost: ghost,
                  children: label
                });
    } else {
      return Popconfirm.create({
                  title: title,
                  positions: positions,
                  align: align,
                  okText: okText,
                  cancelText: cancelText,
                  padding: 8,
                  onConfirm: onClick,
                  children: React.createElement("div", {
                        className: "button " + Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Css.display(/* inlineFlex */53323314),
                                /* [] */0
                              ]))
                      }, React.createElement(Antd$BwaxAdmin.Button.make, {
                            type_: buttonType,
                            disabled: disabled,
                            size: size,
                            style: buttonStyle,
                            ghost: ghost,
                            children: label
                          }))
                });
    }
  } else {
    return React.createElement(Antd$BwaxAdmin.Button.make, {
                type_: buttonType,
                disabled: disabled,
                size: size,
                style: buttonStyle,
                onClick: onClick,
                ghost: ghost,
                children: label
              });
  }
}

var make = Button;

exports.toDef = toDef;
exports.make = make;
/* Css Not a pure module */
