
import React from 'react'

import { valueForCode, valueForDisplay } from '../exprToValue';

const attributeSettings = {
    text: {
        label: "Text",
        type: "Text",
        useExpression: true,
    }
}

export default {
    name: "text",
    label: "Text",

    attributes: attributeSettings,

    // icon: <MdTextFields />,

    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {
        const { text } = attributes;

        function renderText() {
            if (text) {
                if (text.$expr) {
                    return <span style={{ opacity: 0.5 }}>{
                        valueForDisplay(text, {
                            attribute: attributeSettings.text
                        })
                    }</span>
                } else {
                    return text
                }
            } else {
                return <span style={{ opacity: 0.5 }}>Please enter some text</span>
            }
        }

        return (
            <div ref={ref} className={className} {...otherProps} style={{
                // ...(children ? {} : { minHeight: 100, minWidth: 150})
                // padding: 12,
            }}>{renderText()}</div>
        )
    }),


    toCode: (attributes, attributesCode) => {
        const { text = "" } = attributes;

        function getText() {
            return valueForCode(text, { attribute: attributeSettings.text })
        }
        return "div " + attributesCode + ` [ text ${getText()} ]`
    }
}