
import React, { useEffect, useState } from 'react';

import PageComponentPreview from './preview/PageComponentPreview';
import PagePreview from './preview/PagePreview';
import AdminPagePreview from './preview/AdminPagePreview';

import './PreviewContainer.less';

import getFacadeForPreview from './preview/getFacadeForPreview';

import { notification } from 'antd';

import HashLoader from "react-spinners/HashLoader";

import { FacadeContextProvider } from 'bwax/util/FacadeContext'
import { isEqual } from 'lodash';
import PreviewSettingButton from './PreviewSettingButton';


import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';


export default function PreviewContainer(props) {

    const [previewOptions, setPreviewOptions] = useState({});

    useEffect(() => {
        const bc = new BroadcastChannel('preview-page-channel');

        bc.postMessage({ messageType: "ask" });

        bc.onmessage = ev => {

            if (ev.data && ev.data.messageType === "preview") {
                console.log("Received", ev.data);
                setPreviewOptions(ev.data)
            }
        }
        return () => {
            bc.close();
        }
    }, [])

    const { runtimeProfile } = previewOptions;

    function renderLoading() {
        return (
            <div className="preview-page" style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <HashLoader color={"#47A9FF"} />
            </div>
        )
    }

    function renderPreview() {
        if (runtimeProfile) {
            return (
                <Preview {...{
                    ...previewOptions,
                    renderLoading,
                }} />
            )
        } else {
            return renderLoading();
        }
    }
    return renderPreview()
}


function Preview(props) {

    // 初始化 facade please
    const { runtimeProfile, dlc: givenDLC, applicationCode, multitenantType, ...others } = props;

    const [testUser, setTestUser] = useState() // [ sessionToken, user ]; 
    const [tenant, setTenant] = useStateWithLocalCache("test-tenant-for-preview");

    const [facadeForPreview, setFacadeForPreview] = useState(undefined);

    const baseDLC = {
        ...givenDLC,
        // sessionToken: "<>"
        // ...(testUser ? { sessionToken: testUser[0]} : {})
    }

    useEffect(() => {
        (async () => {
            console.log(" revload facade");
            setFacadeForPreview(undefined);

            const dlc = {
                ...givenDLC,
                // sessionToken: "<>"
                ...(testUser ? { sessionToken: testUser[0] } : {}),
                ...(multitenantType == "tenant" && tenant ? { tenantCode: tenant.code } : {})
            }

            const newFacade = await getFacadeForPreview({
                runtimeProfile, dlc,
                entities: facadeForPreview && facadeForPreview[1] && facadeForPreview[1].entities,
                dataTypes: facadeForPreview && facadeForPreview[1] && facadeForPreview[1].dataTypes,
                applicationCode,
            });
            setFacadeForPreview([runtimeProfile, newFacade]);
        })()

        // { name: runtimeProfile.name, entityName: runtimeProfile.entityName  }
    }, [JSON.stringify(runtimeProfile), applicationCode, testUser, tenant]);


    const PreviewComp = {
        "page-component": PageComponentPreview,
        "page": PagePreview,
        "admin-page": AdminPagePreview,
    }[runtimeProfile.name];


    const route_to = (s, shouldReplace) => {
        notification.open({
            description: shouldReplace ? "Replace to" : "Route To (simuation)",
            message: <div style={{ wordBreak: "break-all" }}>{s}</div>,
            duration: 1.4
        });
    }

    function renderMain() {
        if (PreviewComp) {
            if (!facadeForPreview || testUser === undefined) {
                return null // loading
            }
            const [currentRuntimeProfile, facade] = facadeForPreview;
            if (isEqual(currentRuntimeProfile, runtimeProfile) && facade && facade.applicationCode == applicationCode) {
                return (
                    <FacadeContextProvider facade={facade}>
                        <PreviewComp {...{ facade, dlc: facade.dlc, runtimeProfile, route_to, ...others }} />
                    </FacadeContextProvider>
                )
            } else {
                return null // loading or switching
            }

        } else {
            return (
                <div style={{ padding: "1rem" }}>
                    没找到 {runtimeProfile.name} 的 Preview Component
                </div>
            )
        }
    }


    return (
        <>
            {renderMain()}
            <PreviewSettingButton {...{
                dlc: baseDLC, testUser, setTestUser, multitenantType,
                tenant, setTenant
            }} />
        </>
    )



}