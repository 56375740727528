
import React, { useEffect, useState, useRef } from 'react'

import isEqual from 'lodash/isEqual'
import { Input, Checkbox, Popover } from '@arco-design/web-react';
import { TbRefresh } from 'react-icons/tb'
import { SketchPicker } from 'react-color';
import { transparentImgUrl, colorRgbRegx, colorHexRegx } from '../StyleForm';
import './GradientEdit.less'

export default function GradientEdit({ activeImageItem, updateBackgroundImage, setColorPickerInPopoverVisible }) {

    const gradientRegx = /gradient\((?:(?:\d+(?:\.\d+)?deg|rad|grad|turn)|(?:circle.*at (?:\d+(?:\.\d+)?% \d+(?:\.\d+)?%))), (.*)\)/
    const colorAndOffsetRegx = /(.*) (\d+)%/
    const [isRepeating, setIsRepeating] = useState(activeImageItem.gradient.startsWith('repeating'))
    const [gradients, setGradients] = useState([]) // { color, offset }
    const [activeGradientKey, setActiveGradientKey] = useState(null)
    const [currentGradient, setCurrentGradient] = useState({ color: 'rgba(0,0,0,1)', offset: '0' })
    const [colorPickerVisible, setColorPickerVisible] = useState(false)

    const gradientBarRef = useRef(null)

    useEffect(() => {
        const matchedResult = activeImageItem.gradient.match(gradientRegx)
        const gradientsArr = matchedResult[1].split(/, /)
        const gradientsObjArr = gradientsArr.reduce((acc, current, currentIndex) => {
            const colorAndOffset = current.match(colorAndOffsetRegx)
            return [
                ...acc, 
                { 
                    gKey: `${currentIndex}-${colorAndOffset[1]}-${colorAndOffset[2]}`, 
                    color: colorAndOffset[1], offset: colorAndOffset[2] 
                }
            ]
        }, [])
        setGradients(gradientsObjArr)
        const initActiveGradientKey = `0-${gradientsObjArr[0].color}-${gradientsObjArr[0].offset}`
        setActiveGradientKey(initActiveGradientKey)
        setCurrentGradient(gradientsObjArr.find(g => g.gKey === initActiveGradientKey))
    }, [])

    function getHorizontalGradient () {
        const colorStr = gradients.reduce((acc, current) => {
            const { color, offset } = current
            return acc + `, ${color} ${offset}%`
        }, "")
        
        return [`linear-gradient(90deg${colorStr})`, `url(${transparentImgUrl})`]
    }

    function updateGradients (newGradients) {
        const newGradientsStr = newGradients.reduce((acc, current, currentIndex) => {
            const { color, offset } = current
            return acc + (currentIndex === newGradients.length - 1 ? `${color} ${offset}%` : `${color} ${offset}%, `)
        }, "")
        const replaceRegx = /gradient\((?:(?:\d+(?:\.\d+)?deg|rad|grad|turn)|(?:circle.*at (?:\d+(?:\.\d+)?% \d+(?:\.\d+)?%))), (.*)\)/
        const originalGradientString = activeImageItem.gradient.match(replaceRegx)[1]

        const newBgImageStr = activeImageItem.gradient.replace(originalGradientString, newGradientsStr)

        updateBackgroundImage(newBgImageStr)
    }

    function addPoint (e) {
        if(gradientBarRef && gradientBarRef.current && typeof(document) !== 'undefined') {
            const rect = gradientBarRef.current.getBoundingClientRect()
            const offset = parseInt((e.clientX - rect.x) / rect.width * 100)
            const prev = gradients.findLastIndex(g => g.offset < offset)
            const next = gradients.findIndex(g => g.offset > offset)
            const step = 100

            const prevMatchedResult = gradients[prev].color.match(colorRgbRegx)
            const nextMatchedResult = gradients[next].color.match(colorRgbRegx)

            /**
             * 在相邻两个颜色之间划分 100 个颜色区域，根据点击位置在这两个颜色之间的宽度百分比选取颜色
             */
            const rStep = (nextMatchedResult[1] - prevMatchedResult[1]) / step
            const gStep = (nextMatchedResult[2] - prevMatchedResult[2]) / step
            const bStep = (nextMatchedResult[3] - prevMatchedResult[3]) / step

            let gradientColorArr = [];
            for(let i = 0; i < step; i++){
                // 计算每一步的 rgb 值
                const r = parseInt(rStep * i + parseInt(prevMatchedResult[1]))
                const g = parseInt(gStep * i + parseInt(prevMatchedResult[2]))
                const b = parseInt(bStep * i + parseInt(prevMatchedResult[3]))
                gradientColorArr.push(`rgba(${r},${g},${b},1)`)
            }

            const prevEl = document.getElementById(`gradient-box${prev}`)
            const nextEl = document.getElementById(`gradient-box${next}`)
            const prevElX = prevEl.getBoundingClientRect().x
            const nextELX = nextEl.getBoundingClientRect().x
            const addedPointOffsetPercent = parseInt((e.clientX - prevElX) / (nextELX - prevElX) * 100)
            const addedGradient = { 
                gKey: `${[prev + 1]}-${gradientColorArr[addedPointOffsetPercent]}-${offset}`, 
                color: gradientColorArr[addedPointOffsetPercent], offset 
            }
            const newGradients = [...gradients, addedGradient].sort((a, b) => a.offset - b.offset)
            setGradients(newGradients)
            setActiveGradientKey(newGradients[prev + 1].gKey)
            setCurrentGradient(addedGradient)
            updateGradients(newGradients)
        }
    }

    function activeGradientDragging(e, gKey) {
        e.stopPropagation()
        setActiveGradientKey(gKey)
        setCurrentGradient(gradients.find(g => g.gKey === gKey))

        if(gradientBarRef && gradientBarRef.current && typeof(document) !== "undefined") {
            let newGradients = [...gradients]
            function mouseMove(e) {
                const rect = gradientBarRef.current.getBoundingClientRect()
                const newOffset = parseInt((e.clientX - rect.x) / rect.width * 100)
                newGradients = gradients.map(g => {
                    if(g.gKey === gKey) {
                        const changedGradient = {
                            ...g,
                            offset: newOffset > 100 ? 100 : (newOffset < 0 ? 0 : newOffset)
                        }
                        setCurrentGradient(changedGradient)
                        return changedGradient
                    } else {
                        return g
                    }
                }).sort((a, b) => a.offset - b.offset)
                setGradients(newGradients)
            }

            function mouseUp() {
                if(!isEqual(newGradients, gradients)) {
                    updateGradients(newGradients)
                }
                document.removeEventListener('mousemove', mouseMove, false);
                document.removeEventListener('mouseup', mouseUp, false);
            }

            document.addEventListener('mousemove', mouseMove, false);
            document.addEventListener('mouseup', mouseUp, false)
        }
    }

    function deleteGradient (e) {
        if(e.key === "Backspace" && gradients.length > 2) {
            const newGradients = gradients.filter(g => g.gKey !== activeGradientKey)
            setGradients(newGradients)
            updateGradients(newGradients)
        }
    }

    function reverseGradients () {
        for (let i = 0; i < parseInt(gradients.length / 2); i++) {
            const tmp = gradients[i].color
            gradients[i].color = gradients[gradients.length - 1 - i].color
            gradients[gradients.length - 1 - i].color = tmp
        }

        updateGradients(gradients)
    }

    function updateGradientColorOrOffset (color, offset) {
        const newGradients = gradients.map(g => {
            if(g.gKey === activeGradientKey) {
                return {
                    ...g,
                    color,
                    offset
                }
            } else {
                return g
            }
        }).sort((a, b) => a.offset - b.offset)
        setGradients(newGradients)
        updateGradients(newGradients)
    }

    function renderGradientColorPicker () {

        function renderColorPicker () {

            function onChangeComplete (color) {
                const { r, g, b, a } = color.rgb
                const c = `rgba(${r},${g},${b},${a})`
                setCurrentGradient({
                    ...currentGradient,
                    color: c
                })
                updateGradientColorOrOffset(c, currentGradient.offset)
            }
        
            return (
                <SketchPicker
                    color={currentGradient.color}
                    onChangeComplete={onChangeComplete} 
                />
            )
        }

        return (
            <Popover content={renderColorPicker()} position="bottom" trigger="click" popupVisible={colorPickerVisible} 
                onVisibleChange={visible => {
                    setColorPickerVisible(visible)
                    setColorPickerInPopoverVisible(visible)
                }}>
                <div className='gradient-color' style={{ 
                     backgroundImage: [`linear-gradient(180deg, ${currentGradient.color} 0%, ${currentGradient.color} 100%)`, `url(${transparentImgUrl})`] 
                }}></div>
            </Popover>
            
        )
    }

    const offsetSuffix = (
        <div>%</div>
    )

    // function getHexColor (color) {
    //     const colorRgbRegx = /^[rR][gG][bB]a?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})(?:, ?\d+(?:\.\d+)?)?\)$/

    //     if(color.match(colorRgbRegx)) {
    //         const matchRgbResult = color.match(colorRgbRegx)
    //         const convertedColor = RGBtoHex({r: parseInt(matchRgbResult[1]), g: parseInt(matchRgbResult[2]), b: parseInt(matchRgbResult[3])})
    //         return `#${convertedColor}`
    //     } else {
    //         return color
    //     }
       
    // }

    function inputGradientColor (color) {
        setCurrentGradient({
            ...currentGradient,
            color
        })
    }

    function inputGradientColorBlur () {
        const { color, offset } = currentGradient

        // const hexColor = getHexColor(color)
        
        // setCurrentGradient({
        //     ...currentGradient,
        //     color: hexColor
        // })

        if(color === "transparent") {
            updateGradientColorOrOffset(color, offset)
        } else if(color.startsWith("rgb")) {
            if(color.match(colorRgbRegx)) {
                const matchRgbResult = color.match(colorRgbRegx)
                const convertedColor = `rgba(${matchRgbResult[1]},${matchRgbResult[2]},${matchRgbResult[3]},${matchRgbResult[3] ? matchRgbResult[3] : 1})`
                updateGradientColorOrOffset(convertedColor, offset)
            }
        } else if(color.startsWith('#')) {
            if(color.match(colorHexRegx)) {
                const matchedResult = color.match(colorHexRegx)
                const colorStr = matchedResult[1]
                let r = 0
                let g = 0
                let b = 0
                if(colorStr.length === 3) {
                    r = parseInt(`0x${colorStr[0]}${colorStr[0]}`)
                    g = parseInt(`0x${colorStr[1]}${colorStr[1]}`)
                    b = parseInt(`0x${colorStr[2]}${colorStr[2]}`)
                } else {
                    r = parseInt(`0x${colorStr[0]}${colorStr[1]}`)
                    g = parseInt(`0x${colorStr[2]}${colorStr[3]}`)
                    b = parseInt(`0x${colorStr[4]}${colorStr[5]}`)
                }

                updateGradientColorOrOffset(`rgba(${r},${g},${b},1)`, offset)
            }
        }
    }

    function inputGradientOffset (offset) {
        setCurrentGradient({
            ...currentGradient,
            offset
        })
    }

    function inputGradientOffsetBlur () {
        const { color, offset } = currentGradient
        const intOffset = parseInt(offset)
        if(intOffset && intOffset >= 0 && intOffset <= 100) {
            updateGradientColorOrOffset(color, intOffset)
        } 
    }

    return (
        <div className='gradient-edit-container'>
            <div className='gradient-bar-container' tabIndex='0' ref={gradientBarRef} onMouseDown={e => addPoint(e)} 
                onKeyDown={e => deleteGradient(e)}
            >
                <div className='gradient-bar-inner' style={{ backgroundImage: getHorizontalGradient() }}></div>
                {
                    gradients.map((g, index)=> {
                        const { gKey } = g
                        const isActive = activeGradientKey === gKey

                        return (
                            <div key={gKey} id={`gradient-box${index}`} className={`gradient-box ${ isActive ? 'active' : '' }`}
                                style={{ backgroundColor: isActive ? g.color : 'white', left: `calc((100% - 10px) / 100 * ${g.offset})` }}
                                onMouseDown={e => activeGradientDragging(e, gKey)} 
                            />
                        )
                    })
                }
            </div>
            <div className='repeat-or-reverse'>
                <div className='repeat'>
                    <Checkbox checked={isRepeating} style={{ paddingLeft: 0, marginRight: 8 }} onChange={checked => {
                        setIsRepeating(checked)
                        if(!checked) {
                            updateBackgroundImage(activeImageItem.gradient.replace('repeating-', ''))
                        } else {
                            updateBackgroundImage('repeating-' + activeImageItem.gradient)
                        }
                    }} />
                    Repeat
                </div>
                <div className='reverse' onClick={() => reverseGradients()}>
                    <TbRefresh/>
                </div>
            </div>
            <div className='color-edit'>
                Color
                <div className='color-input'>
                    <Input value={currentGradient.color} size="mini" addBefore={renderGradientColorPicker()} beforeStyle={{ padding: 0 }}
                        onChange={value => inputGradientColor(value)} onBlur={() => inputGradientColorBlur()} onPressEnter={() => inputGradientColorBlur()}
                    />
                    <Input value={currentGradient.offset} size="mini" suffix={offsetSuffix} style={{ width: '3.5rem', paddingLeft: 0, marginLeft: 4 }}
                        onChange={value => inputGradientOffset(value)} onBlur={() => inputGradientOffsetBlur()} onPressEnter={() => inputGradientOffsetBlur()}
                    />
                </div>
            </div>
        </div>
        
    )
}
