// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var registry = /* [] */0;

var inputRegistry = /* [] */0;

exports.registry = registry;
exports.inputRegistry = inputRegistry;
/* No side effect */
