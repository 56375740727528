


import React from 'react'

export default {
    name: "input",
    label: "文本框",
    // icon: <MdShortText />


    attributes: {
        onInput: {
            type: "StringMsg",
            useExpression: true,
        },
    },

    DesignView: React.forwardRef(({ className, children, attributes, updateBlockAttributes, ...otherProps }, ref) => {

        return (
            <input ref={ref} className={className} {...otherProps} disabled />
        )
    }),

}