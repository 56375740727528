

import React, { useEffect, useState } from 'react'

import './FormManagement.less';

import FormView from './FormView';

import Loading from 'Client/js/components/Loading'

import { BiNotepad } from 'react-icons/bi';
import { Link } from 'react-router-dom'

// 加载当前域和应用中的所有表单
import { Menu, Input, Button, Dropdown } from '@arco-design/web-react';
// import { IconApps, IconBug, IconBulb } from '@arco-design/web-react/icon';

import { IconPlus } from '@arco-design/web-react/icon';

import ExcelToFormModal from './ExcelToFormModal';

const MenuItem = Menu.Item;
// const SubMenu = Menu.SubMenu;
const InputSearch = Input.Search;

export default function FormManagement(props) {

    const { facade, history, match } = props;
    const [forms, setForms] = useState();

    const [searchKey, setSearchKey] = useState();

    const [importModalVisible, setImportModalVisible] = useState();

    async function loadForms() {
        const queryObj = {
            entityName: "表单",
            // query_config, 
            // facade 支持 query_config， 也支持 js 版本的 condition, sort
            fieldPaths: ["名称", "描述", "字段", "加入知识库"],
            pageSize: 1000, offset: 0

        };
        const [result, error] = await facade.list(queryObj, {});
        if (!error && result) {
            setForms(result)
        }
    }

    useEffect(() => {
        // load all the forms;
        (async () => {
            await facade.prepare(["表单导入"]);
            await loadForms();
        })();
    }, []);

    if (forms == undefined) {
        return (
            <Loading />
        )
    }

    function constructPath(name) {
        const params = { ...match.params, name };
        return Object.keys(params).reduce((acc, key) => {
            // remove all the :key?
            const value = params[key] || "";
            return acc.replaceAll(":" + key + "?", value).replaceAll(":" + key, value);
        }, match.path)
    }


    const currentForm = forms.find(f => f.名称 == match.params.name || f.id == match.params.name) || forms[0];

    const selectedKeys = currentForm ? [currentForm.id] : [];

    const filteredForms = searchKey ? forms.filter(form => {
        // 这里可以支持拼音 TODO
        return form.名称 == searchKey
    }) : forms;


    async function onFormAdded (form) {
        await loadForms();
        history.push(constructPath(form.id));
    }

    async function addBlankForm() {
        // 
        function makeNewName() {
            for (let i = 0; ; i++) {
                const nameToTry = "新表单" + (i == 0 ? "" : i);
                if (!forms.find(r => r.名称 === nameToTry)) {
                    return nameToTry
                }
            }
        }
        const name = makeNewName();

        const [result, error] = await facade.add({
            entityName: "表单",
            formData: {
                名称: name,
                字段: []
            },
            fieldPaths: []
        });

        if(!error && result) {
            onFormAdded(result.id)
        }
    }


    return (
        <div className="lc-form-management">
            <div className="form-list-nav">
                <div className="form-list-nav-tools">
                    <InputSearch allowClear placeholder="输入名称"
                        onChange={v => {
                            setSearchKey(v);
                        }}
                        onSearch={v => {
                            // console.log(">> saerch", v);
                        }}
                    />
                    <Dropdown droplist={(
                        <Menu className="lc-dropdown-menu" onClickMenuItem={key => {
                            if (key == "addBlank") {
                                addBlankForm()
                            } else if (key = "importFromExcel") {
                                // 
                                setImportModalVisible(true);
                            }
                        }}>
                            <Menu.Item key='addBlank'>添加空白表单</Menu.Item>
                            <Menu.Item key='importFromExcel'>从 Excel 导入表单</Menu.Item>
                        </Menu>
                    )} trigger="click">
                        <Button size="small" icon={<IconPlus />} type='outline' className="add-form-button"
                            onClick={_ => {
                                // console.log(">>> Add new form");
                            }}
                        />
                    </Dropdown>
                </div>
                <Menu
                    onClickMenuItem={key => {
                        const newPath = constructPath(key);
                        history.push(newPath);
                    }}
                    selectedKeys={selectedKeys}
                >
                    {filteredForms.map(f => {
                        const path = constructPath(f.id);
                        return (
                            <MenuItem key={f.id}>
                                <div className="menu-icon"><BiNotepad /></div>
                                <Link to={path} style={{
                                    display: "inline",
                                }} onClick={e => {
                                    e.preventDefault();
                                }}>
                                    <span style={{ fontSize: 12 }}>{f.名称}</span>
                                </Link>
                            </MenuItem>
                        )
                    })}
                </Menu>
            </div>
            <ExcelToFormModal {...{
                visible: importModalVisible, setVisible: setImportModalVisible, 
                facade, onFormAdded
            }} />
            {currentForm ?
                <FormView form={currentForm} facade={facade} key={currentForm.id}
                    onFormUpdated={updatedForm => {
                        setForms(prev => {
                            return (prev || []).map(f => {
                                if (f.id === updatedForm.id) {
                                    return {
                                        ...f,
                                        ...updatedForm,
                                    }
                                } else {
                                    return f
                                }
                            })

                        })

                    }}
                /> :
                null
            }
        </div>
    )
}
