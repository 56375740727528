// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactHelmetAsync = require("react-helmet-async");
var DictUtils$BwaxAdmin = require("../../../bwax-js/re/utils/DictUtils.bs.js");
var GetImageURL = require("bwax-ui/getImageURL");
var Instructions$BwaxAdmin = require("./Instructions.bs.js");
var NormalizeWidgetDef = require("bwax-ui/legacy/page/normalizeWidgetDef");

var Page = { };

function PageRenderer(Props) {
  Props.theme;
  var setting = Props.setting;
  var pageState = Props.pageState;
  var editingState = Props.editingState;
  var basePrepareActions = Props.basePrepareActions;
  var routeTo = Props.routeTo;
  var produceWidget = Props.produceWidget;
  var initialState = Belt_Option.getWithDefault(setting.state, { });
  var getPageState = function (param) {
    return DictUtils$BwaxAdmin.mergeWith(initialState, pageState);
  };
  var head = setting.head;
  var view = setting.view;
  var modal = setting.modal;
  var layout = setting.layout;
  var match = React.useState((function () {
          return ;
        }));
  var setModalContent = match[1];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setModalKey = match$1[1];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setModalVisible = match$2[1];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setDialog = match$3[1];
  var match$4 = React.useState((function () {
          return ;
        }));
  var setScrollCtnrEl = match$4[1];
  var scrollCtnrEl = match$4[0];
  var modalSetting = {
    modalContent: match[0],
    modalVisible: match$2[0],
    modalKey: match$1[0],
    hideModal: (function (param) {
        ReactDom.unstable_batchedUpdates((function (param) {
                Curry._1(setModalVisible, (function (param) {
                        return false;
                      }));
                Curry._1(setModalKey, (function (param) {
                        return ;
                      }));
                return Curry._1(setModalContent, (function (param) {
                              return ;
                            }));
              }));
        return /* () */0;
      }),
    showModal: (function (key) {
        ReactDom.unstable_batchedUpdates((function (param) {
                Curry._1(setModalVisible, (function (param) {
                        return true;
                      }));
                return Curry._1(setModalKey, (function (param) {
                              return Caml_option.some(key);
                            }));
              }));
        return /* () */0;
      }),
    showModalContent: (function (content) {
        ReactDom.unstable_batchedUpdates((function (param) {
                Curry._1(setModalVisible, (function (param) {
                        return true;
                      }));
                return Curry._1(setModalContent, (function (param) {
                              return Caml_option.some(content);
                            }));
              }));
        return /* () */0;
      })
  };
  var dialogSetting = {
    dialog: match$3[0],
    showDialog: (function (dialog) {
        return Curry._1(setDialog, (function (param) {
                      return Caml_option.some(dialog);
                    }));
      }),
    clearDialog: (function (param) {
        return Curry._1(setDialog, (function (param) {
                      return ;
                    }));
      })
  };
  var prepareActions = function (action) {
    var env = {
      modal: modalSetting,
      renderWidget: renderWidget
    };
    var preparer = Curry._1(basePrepareActions, env);
    return Curry._3(preparer, action, pageState, editingState);
  };
  var renderWidget = function (widget, pc, key) {
    return Curry._3(produceWidget, NormalizeWidgetDef.default(widget), {
                renderWidget: renderWidget,
                prepareActions: prepareActions,
                modal: modalSetting,
                dialogBox: dialogSetting,
                scrollCtnr: {
                  el: scrollCtnrEl,
                  setEl: setScrollCtnrEl
                },
                pc: pc
              }, key);
  };
  var timer = setting.timer;
  var wechat = setting.wechat;
  var redirect = setting.redirect;
  Instructions$BwaxAdmin.useTimer(timer, getPageState, prepareActions);
  Instructions$BwaxAdmin.useRedirect(redirect, routeTo);
  Instructions$BwaxAdmin.useWechat(wechat);
  var renderHead = function (param) {
    var h = Belt_Option.getWithDefault(head, {
          title: undefined,
          favicon: undefined,
          keywords: undefined,
          description: undefined,
          scalable: undefined,
          meta: undefined,
          scripts: undefined,
          noscripts: undefined
        });
    var title = h.title;
    var favicon = h.favicon;
    var keywords = h.keywords;
    var description = h.description;
    var imageURL = GetImageURL.default(favicon);
    var meta = Belt_Option.getWithDefault(h.meta, /* array */[]);
    var scripts = Belt_Option.getWithDefault(h.scripts, /* array */[]);
    var noscripts = Belt_Option.getWithDefault(h.noscripts, /* array */[]);
    var scalable = h.scalable;
    var tmp;
    var exit = 0;
    if (scalable !== undefined && scalable) {
      tmp = React.createElement("meta", {
            content: "width=device-width,initial-scale=1",
            name: "viewport"
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement("meta", {
            content: "width=device-width,initial-scale=1,maximum-scale=1",
            name: "viewport"
          });
    }
    return React.createElement(ReactHelmetAsync.Helmet, {
                children: null
              }, Belt_Option.mapWithDefault(title, null, (function (t) {
                      return React.createElement("title", undefined, t);
                    })), Belt_Option.mapWithDefault(imageURL, null, (function (url) {
                      return React.createElement("link", {
                                  href: url,
                                  rel: "shortcut icon",
                                  type: "image/png"
                                });
                    })), Belt_Option.mapWithDefault(keywords, null, (function (k) {
                      return React.createElement("meta", {
                                  content: k,
                                  name: "keywords"
                                });
                    })), Belt_Option.mapWithDefault(description, null, (function (k) {
                      return React.createElement("meta", {
                                  content: k,
                                  name: "description"
                                });
                    })), tmp, Belt_Array.mapWithIndex(meta, (function (idx, m) {
                      var name = m.name;
                      var content = m.content;
                      return React.createElement("meta", {
                                  key: String(idx),
                                  content: content,
                                  name: name
                                });
                    })), Belt_Array.mapWithIndex(scripts, (function (idx, s) {
                      return React.createElement("script", {
                                  key: "s" + String(idx)
                                }, s);
                    })), Belt_Array.mapWithIndex(noscripts, (function (idx, s) {
                      return React.createElement("noscript", {
                                  key: "s" + String(idx)
                                }, s);
                    })));
  };
  var renderPart = function (w) {
    return Belt_Option.mapWithDefault(w, null, (function (widget) {
                  return renderWidget(widget, undefined, undefined);
                }));
  };
  if (layout !== undefined) {
    var l = Caml_option.valFromOption(layout);
    return React.createElement(React.Fragment, undefined, renderHead(/* () */0), renderPart({
                    type_: "page",
                    params: l
                  }), renderPart(modal));
  } else {
    return React.createElement(React.Fragment, undefined, renderHead(/* () */0), renderPart(view), React.createElement("div", undefined, renderPart(modal)));
  }
}

var make = PageRenderer;

exports.Page = Page;
exports.make = make;
/* react Not a pure module */
