// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../../utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../runtime_common.bs.js");
var Lang_mod_string$BwaxAdmin = require("./lang_mod_string.bs.js");

var module_name = "File";

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        "Cmd." + (name + ": Invalid Arguments")
      ];
}

function unit_to_literal(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" && !(match !== 0 || args[1])) {
        return /* tuple */[
                /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [Curry._1(proc, /* () */0)]),
                /* No_term */0
              ];
      } else {
        return Runtime_common$BwaxAdmin.invalid_args(name);
      }
    } else {
      return Runtime_common$BwaxAdmin.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Caml_chrome_debugger.variant("V_native_fn", 4, [
              1,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function pack_getter(name) {
  return Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                if (param) {
                  var match = param[0][0];
                  if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "File" && !param[1])) {
                    return fail(name);
                  } else {
                    var match$1 = Json$BwaxAdmin.get_value(name, Js_json.decodeString, match[1]);
                    if (match$1 !== undefined) {
                      return Runtime_common$BwaxAdmin.pack_string(match$1);
                    } else {
                      return fail(name);
                    }
                  }
                } else {
                  return fail(name);
                }
              }));
}

var externals_vals_000 = /* tuple */[
  "fileType",
  Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "File" && !param[1])) {
              return fail("fileType");
            } else {
              var image_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "IMAGE",
                  /* [] */0
                ]);
              var image = /* tuple */[
                image_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var doc_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "DOC",
                  /* [] */0
                ]);
              var doc = /* tuple */[
                doc_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var pdf_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "PDF",
                  /* [] */0
                ]);
              var pdf = /* tuple */[
                pdf_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var xls_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "XLS",
                  /* [] */0
                ]);
              var xls = /* tuple */[
                xls_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var ppt_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "PPT",
                  /* [] */0
                ]);
              var ppt = /* tuple */[
                ppt_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var other_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "OTHER",
                  /* [] */0
                ]);
              var other = /* tuple */[
                other_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var maybeContentType = Json$BwaxAdmin.get_value("contentType", Js_json.decodeString, match[1]);
              if (maybeContentType !== undefined) {
                var v = Plate$BwaxAdmin.Str.to_lower(maybeContentType);
                if (Plate$BwaxAdmin.Str.starts_with(v, "image")) {
                  return image;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/pdf")) {
                  return pdf;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.ms-powerpoint") || Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.openxmlformats-officedocument.presentationml.presentation")) {
                  return ppt;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.ms-excel") || Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                  return xls;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/msword") || Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
                  return doc;
                } else {
                  return other;
                }
              } else {
                return other;
              }
            }
          } else {
            return fail("fileType");
          }
        }))
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "title",
      pack_getter("title")
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "url",
          pack_getter("url")
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "path",
              pack_getter("path")
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "toBase64",
                  Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                          if (param) {
                            var match = param[0][0];
                            if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "File" && !param[1])) {
                              return fail("toBase64");
                            } else {
                              return Runtime_common$BwaxAdmin.pack_string(Lang_mod_string$BwaxAdmin.toBase64(JSON.stringify(match[1])));
                            }
                          } else {
                            return fail("toBase64");
                          }
                        }))
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "toBase64URI",
                      Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                              if (param) {
                                var match = param[0][0];
                                if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "File" && !param[1])) {
                                  return fail("toBase64URI");
                                } else {
                                  return Runtime_common$BwaxAdmin.pack_string(Lang_mod_string$BwaxAdmin.toBase64URI(JSON.stringify(match[1])));
                                }
                              } else {
                                return fail("toBase64URI");
                              }
                            }))
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "fromBase64",
                          Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                                  if (param) {
                                    var match = param[0][0];
                                    if (typeof match === "number" || match.tag !== /* V_literal */5) {
                                      return fail("fromBase64");
                                    } else {
                                      var match$1 = match[0];
                                      if (typeof match$1 === "number" || !(match$1.tag === /* String */2 && !param[1])) {
                                        return fail("fromBase64");
                                      } else {
                                        var checkFile = function (json) {
                                          var match = Json$BwaxAdmin.get_value("path", Js_json.decodeString, json);
                                          if (match !== undefined) {
                                            return Caml_option.some(json);
                                          }
                                          
                                        };
                                        var match$2 = Plate$BwaxAdmin.$$Option.and_then(checkFile, Json$BwaxAdmin.parse(Lang_mod_string$BwaxAdmin.fromBase64(match$1[0])));
                                        if (match$2 !== undefined) {
                                          return Runtime_common$BwaxAdmin.pack_just(Runtime_common$BwaxAdmin.pack_file(Caml_option.valFromOption(match$2)));
                                        } else {
                                          return Runtime_common$BwaxAdmin.pack_nothing;
                                        }
                                      }
                                    }
                                  } else {
                                    return fail("fromBase64");
                                  }
                                }))
                        ],
                        /* [] */0
                      ])
                  ])
              ])
          ])
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

function commands_handler(_context, param, _on_trunk_msgs, _on_branch_msgs) {
  return false;
}

var command_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    commands_handler,
    /* [] */0
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\n\nmodule File;\n\n-- helpers\n-- 一些通用的类型，更细致的区分，如 XLS 和 XLSX 就不在这里进行了。\ntype FileType = \n  | Image\n  | DOC\n  | PDF\n  | XLS\n  | PPT\n  | OTHER\n;\n\nfileType: File -> FileType = external;\n\ntitle: File -> String = external;\n\n-- Get the accessible url\nurl: File -> String = external;\n\n-- Get the path addressing file in the store.\npath: File -> String = external;\n\n\n-- base 64 related\ntoBase64: File -> String = external;\n\ntoBase64URI: File -> String = external;\n\nfromBase64: String -> Maybe File = external;\n\n\n\n";

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.unit_to_literal = unit_to_literal;
exports.pack_getter = pack_getter;
exports.externals_vals = externals_vals;
exports.commands_handler = commands_handler;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
