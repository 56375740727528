// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Scanf = require("bs-platform/lib/js/scanf.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Performance$BwaxAdmin = require("../performance.bs.js");
var OreLezerParser = require("bwax/lang/OreLezerParser");

function build_syntax_node(collector, parent_id, code_text, n) {
  var id = Plate$BwaxAdmin.Str.from_int(n.index);
  var name = n.name;
  var start_index = n.from;
  var end_index = n.to;
  var collect_sibling = function (_acc, _n) {
    while(true) {
      var n = _n;
      var acc = _acc;
      var match = n.nextSibling;
      if (match == null) {
        return acc;
      } else {
        _n = match;
        _acc = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            match,
            acc
          ]);
        continue ;
      }
    };
  };
  var match = n.firstChild;
  var children = (match == null) ? /* [] */0 : Plate$BwaxAdmin.List.rev(collect_sibling(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                match,
                /* [] */0
              ]), match));
  if (name === "⚠") {
    var error_node_003 = function (param) {
      return Plate$BwaxAdmin.Str.sub(start_index, end_index - start_index | 0, code_text);
    };
    var error_node = /* record */Caml_chrome_debugger.record([
        "id",
        "parent_id",
        "name",
        "get_text",
        "start_index",
        "end_index",
        "raw",
        "code_text"
      ], [
        id,
        parent_id,
        name,
        error_node_003,
        start_index,
        end_index,
        n,
        code_text
      ]);
    collector[/* errors */0] = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        error_node,
        collector[/* errors */0]
      ]);
    return ;
  } else {
    var partial_arg = id;
    return /* record */Caml_chrome_debugger.record([
              "id",
              "name",
              "get_text",
              "children",
              "start_index",
              "end_index",
              "raw"
            ], [
              id,
              name,
              (function (param) {
                  return Plate$BwaxAdmin.Str.sub(start_index, end_index - start_index | 0, code_text);
                }),
              Plate$BwaxAdmin.List.filter((function (n) {
                        return n[/* name */1] !== "LineComment";
                      }))(Plate$BwaxAdmin.List.keep_map((function (param) {
                          return build_syntax_node(collector, partial_arg, code_text, param);
                        }), children)),
              start_index,
              end_index,
              n
            ]);
  }
}

function get_meta(node) {
  return /* record */Caml_chrome_debugger.record([
            "name",
            "text",
            "start_index",
            "end_index"
          ], [
            node[/* name */1],
            Curry._1(node[/* get_text */2], /* () */0),
            node[/* start_index */4],
            node[/* end_index */5]
          ]);
}

var Convert_exn = Caml_exceptions.create("Lang_parser_lezer-BwaxAdmin.Convert_exn");

function fail(node) {
  throw [
        Convert_exn,
        node[/* name */1],
        Curry._1(node[/* get_text */2], /* () */0),
        get_meta(node)
      ];
}

function first_child(node) {
  var match = Plate$BwaxAdmin.List.head(node[/* children */3]);
  if (match !== undefined) {
    return match;
  } else {
    return fail(node);
  }
}

function to_arith_op(node) {
  var match = node[/* name */1];
  switch (match) {
    case "Add" :
        return /* Add */4;
    case "Div" :
        return /* Div */2;
    case "Mod" :
        return /* Mod */3;
    case "Mul" :
        return /* Mul */1;
    case "Pow" :
        return /* Pow */0;
    case "Sub" :
        return /* Sub */5;
    default:
      return fail(node);
  }
}

function to_compare_op(node) {
  var match = node[/* name */1];
  switch (match) {
    case "Ge" :
        return /* Ge */1;
    case "Gt" :
        return /* Gt */0;
    case "Le" :
        return /* Le */2;
    case "Lt" :
        return /* Lt */3;
    default:
      return fail(node);
  }
}

function to_equality_op(node) {
  var match = node[/* name */1];
  switch (match) {
    case "Eq" :
        return /* Eq */0;
    case "Ne" :
        return /* Ne */1;
    default:
      return fail(node);
  }
}

function to_logic_op(node) {
  var match = node[/* name */1];
  switch (match) {
    case "And" :
        return /* And */0;
    case "Or" :
        return /* Or */1;
    case "Xor" :
        return /* Xor */2;
    default:
      return fail(node);
  }
}

function extract_literal(node) {
  var text = Curry._1(node[/* get_text */2], /* () */0);
  var len = Plate$BwaxAdmin.Str.length(text);
  var match = node[/* name */1];
  switch (match) {
    case "Char" :
        return /* Char */Caml_chrome_debugger.variant("Char", 3, [Plate$BwaxAdmin.Str.get(1, Scanf.unescaped(text))]);
    case "False" :
        return /* Bool */Caml_chrome_debugger.variant("Bool", 4, [false]);
    case "FloatNumber" :
        var match$1 = Plate$BwaxAdmin.Str.to_float(text);
        if (match$1 !== undefined) {
          return /* Float */Caml_chrome_debugger.variant("Float", 1, [match$1]);
        } else {
          return fail(node);
        }
    case "Integer" :
        var match$2 = Plate$BwaxAdmin.Str.to_int(text);
        if (match$2 !== undefined) {
          return /* Int */Caml_chrome_debugger.variant("Int", 0, [match$2]);
        } else {
          return fail(node);
        }
    case "String" :
        return /* String */Caml_chrome_debugger.variant("String", 2, [Scanf.unescaped(Plate$BwaxAdmin.Str.sub(1, len - 2 | 0, text))]);
    case "True" :
        return /* Bool */Caml_chrome_debugger.variant("Bool", 4, [true]);
    default:
      return fail(node);
  }
}

function convert(node) {
  var convert_list = function (l) {
    return Plate$BwaxAdmin.List.keep_map((function (c) {
                  if (Plate$BwaxAdmin.List.contains(Curry._1(c[/* get_text */2], /* () */0), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "(",
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                ")",
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    "[",
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        "]",
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            ",",
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                ";",
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    "~",
                                                    /* [] */0
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ]))) {
                    return ;
                  } else {
                    return convert(c);
                  }
                }), l);
  };
  var collect_binds = function (l) {
    return Plate$BwaxAdmin.List.keep_map((function (c) {
                  var match = c[/* name */1];
                  var match$1 = c[/* children */3];
                  if (match === "RecBind" && match$1) {
                    var match$2 = match$1[1];
                    var name_n = match$1[0];
                    if (match$2) {
                      var match$3 = match$2[1];
                      if (match$3 && !match$3[1]) {
                        return /* tuple */[
                                Curry._1(name_n[/* get_text */2], /* () */0),
                                convert(match$3[0])
                              ];
                      } else {
                        return ;
                      }
                    } else {
                      var name = Curry._1(name_n[/* get_text */2], /* () */0);
                      return /* tuple */[
                              name,
                              /* tuple */[
                                /* Ref */Caml_chrome_debugger.variant("Ref", 16, [name]),
                                get_meta(name_n)
                              ]
                            ];
                    }
                  }
                  
                }), l);
  };
  var convert_apply = function (fn_n, arg_n) {
    return /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
              convert(fn_n),
              convert(arg_n)
            ]);
  };
  var convert_binop = function (op) {
    var match = op[/* name */1];
    switch (match) {
      case "Append" :
          return /* Append */1;
      case "Cons" :
          return /* Cons */0;
      case "Ge" :
      case "Gt" :
      case "Le" :
      case "Lt" :
          return /* Compare */Caml_chrome_debugger.variant("Compare", 2, [to_compare_op(op)]);
      case "Eq" :
      case "Ne" :
          return /* Equality */Caml_chrome_debugger.variant("Equality", 1, [to_equality_op(op)]);
      case "Add" :
      case "Div" :
      case "Mod" :
      case "Mul" :
      case "Pow" :
      case "Sub" :
          return /* Arith */Caml_chrome_debugger.variant("Arith", 0, [to_arith_op(op)]);
      case "And" :
      case "Or" :
      case "xor" :
          return /* Logic */Caml_chrome_debugger.variant("Logic", 3, [to_logic_op(op)]);
      default:
        return fail(node);
    }
  };
  var collect_action_stmts = function (children) {
    return Plate$BwaxAdmin.List.keep_map((function (n) {
                  var match = n[/* name */1];
                  var match$1 = n[/* children */3];
                  switch (match) {
                    case "OneExpr" :
                        if (match$1 && !match$1[1]) {
                          return /* One_expr */Caml_chrome_debugger.variant("One_expr", 2, [convert(match$1[0])]);
                        } else {
                          return ;
                        }
                    case "ValueAssignment" :
                        if (match$1) {
                          var match$2 = match$1[1];
                          if (match$2) {
                            var match$3 = match$2[1];
                            if (match$3 && !match$3[1]) {
                              var name = match$1[0];
                              return /* Value_assignment */Caml_chrome_debugger.variant("Value_assignment", 0, [
                                        /* tuple */[
                                          /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, [Curry._1(name[/* get_text */2], /* () */0)]),
                                          get_meta(name)
                                        ],
                                        convert(match$3[0]),
                                        get_meta(n)
                                      ]);
                            } else {
                              return ;
                            }
                          } else {
                            return ;
                          }
                        } else {
                          return ;
                        }
                    case "ValueExtract" :
                        if (match$1) {
                          var match$4 = match$1[1];
                          if (match$4) {
                            var match$5 = match$4[1];
                            if (match$5 && !match$5[1]) {
                              var name$1 = match$1[0];
                              return /* Value_extract */Caml_chrome_debugger.variant("Value_extract", 1, [
                                        /* tuple */[
                                          /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, [Curry._1(name$1[/* get_text */2], /* () */0)]),
                                          get_meta(name$1)
                                        ],
                                        convert(match$5[0]),
                                        get_meta(n)
                                      ]);
                            } else {
                              return ;
                            }
                          } else {
                            return ;
                          }
                        } else {
                          return ;
                        }
                    default:
                      return ;
                  }
                }), Plate$BwaxAdmin.List.keep_map((function (c) {
                      return Plate$BwaxAdmin.List.head(c[/* children */3]);
                    }), Plate$BwaxAdmin.List.filter((function (c) {
                            return c[/* name */1] === "ActionStmt";
                          }))(children)));
  };
  var match = node[/* name */1];
  var match$1 = node[/* children */3];
  var alt;
  var exit = 0;
  var f_n;
  var g_n;
  var l;
  var op;
  var r;
  switch (match) {
    case "AddOp" :
        if (match$1) {
          var match$2 = match$1[1];
          if (match$2) {
            var match$3 = match$2[1];
            if (match$3 && !match$3[1]) {
              l = match$1[0];
              op = match$2[0];
              r = match$3[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "AppendOp" :
        if (match$1) {
          var match$4 = match$1[1];
          if (match$4) {
            var match$5 = match$4[1];
            if (match$5 && !match$5[1]) {
              l = match$1[0];
              op = match$4[0];
              r = match$5[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "ApplyExpr" :
        if (match$1) {
          var match$6 = match$1[1];
          alt = match$6 && !match$6[1] ? convert_apply(match$1[0], match$6[0]) : fail(node);
        } else {
          alt = fail(node);
        }
        break;
    case "BinOpExpr" :
        if (match$1) {
          var match$7 = match$1[1];
          if (match$7) {
            var match$8 = match$7[1];
            if (match$8 && !match$8[1]) {
              var e = match$7[0];
              var meta = get_meta(e);
              var params_000 = /* tuple */[
                /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, ["l"]),
                meta
              ];
              var params_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, ["r"]),
                    meta
                  ],
                  /* [] */0
                ]);
              var params = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  params_000,
                  params_001
                ]);
              var l_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["l"]);
              var l$1 = /* tuple */[
                l_000,
                meta
              ];
              var r_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["r"]);
              var r$1 = /* tuple */[
                r_000,
                meta
              ];
              var match$9 = e[/* name */1];
              var body_alt;
              switch (match$9) {
                case "CompFrom" :
                    var params_000$1 = /* tuple */[
                      /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, ["x"]),
                      meta
                    ];
                    var params$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        params_000$1,
                        /* [] */0
                      ]);
                    var x_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["x"]);
                    var x = /* tuple */[
                      x_000,
                      meta
                    ];
                    var body_000 = /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                        l$1,
                        /* tuple */[
                          /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                              r$1,
                              x
                            ]),
                          meta
                        ]
                      ]);
                    var body = /* tuple */[
                      body_000,
                      meta
                    ];
                    body_alt = /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
                        params$1,
                        body
                      ]);
                    break;
                case "CompTo" :
                    var params_000$2 = /* tuple */[
                      /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, ["x"]),
                      meta
                    ];
                    var params$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        params_000$2,
                        /* [] */0
                      ]);
                    var x_000$1 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["x"]);
                    var x$1 = /* tuple */[
                      x_000$1,
                      meta
                    ];
                    var body_000$1 = /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                        r$1,
                        /* tuple */[
                          /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                              l$1,
                              x$1
                            ]),
                          meta
                        ]
                      ]);
                    var body$1 = /* tuple */[
                      body_000$1,
                      meta
                    ];
                    body_alt = /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
                        params$2,
                        body$1
                      ]);
                    break;
                case "PipeFrom" :
                    body_alt = /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                        l$1,
                        r$1
                      ]);
                    break;
                case "PipeTo" :
                    body_alt = /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                        r$1,
                        l$1
                      ]);
                    break;
                default:
                  body_alt = /* Binop */Caml_chrome_debugger.variant("Binop", 9, [
                      convert_binop(e),
                      l$1,
                      r$1
                    ]);
              }
              alt = /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
                  params,
                  /* tuple */[
                    body_alt,
                    meta
                  ]
                ]);
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "Bracket" :
        if (match$1) {
          var match$10 = match$1[1];
          if (match$10) {
            var match$11 = match$10[1];
            alt = match$11 && !match$11[1] ? Plate$BwaxAdmin.fst(convert(match$10[0])) : fail(node);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "CallWithCC" :
        if (match$1) {
          var match$12 = match$1[1];
          if (match$12) {
            var match$13 = match$12[1];
            if (match$13) {
              var match$14 = match$13[1];
              alt = match$14 && !match$14[1] ? /* Call_cc */Caml_chrome_debugger.variant("Call_cc", 12, [
                    Curry._1(match$12[0][/* get_text */2], /* () */0),
                    convert(match$14[0])
                  ]) : fail(node);
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "CaseExpr" :
        if (match$1) {
          var match$15 = match$1[1];
          if (match$15) {
            var match$16 = match$15[1];
            if (match$16) {
              var expr = convert(match$15[0]);
              var branches = Plate$BwaxAdmin.List.map((function (n) {
                      var match = n[/* children */3];
                      if (match) {
                        var match$1 = match[1];
                        if (match$1) {
                          var match$2 = match$1[1];
                          if (match$2) {
                            var match$3 = match$2[1];
                            if (match$3 && !match$3[1]) {
                              return /* tuple */[
                                      convert_ptn(match$1[0]),
                                      convert(match$3[0])
                                    ];
                            } else {
                              return fail(n);
                            }
                          } else {
                            return fail(n);
                          }
                        } else {
                          return fail(n);
                        }
                      } else {
                        return fail(n);
                      }
                    }), match$16[1]);
              alt = /* Case */Caml_chrome_debugger.variant("Case", 11, [
                  expr,
                  branches
                ]);
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "CompFromOp" :
        if (match$1) {
          var match$17 = match$1[1];
          if (match$17) {
            var match$18 = match$17[1];
            if (match$18 && !match$18[1]) {
              f_n = match$18[0];
              g_n = match$1[0];
              exit = 1;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "CompToOp" :
        if (match$1) {
          var match$19 = match$1[1];
          if (match$19) {
            var match$20 = match$19[1];
            if (match$20 && !match$20[1]) {
              f_n = match$1[0];
              g_n = match$20[0];
              exit = 1;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "CompareOp" :
        if (match$1) {
          var match$21 = match$1[1];
          if (match$21) {
            var match$22 = match$21[1];
            if (match$22 && !match$22[1]) {
              l = match$1[0];
              op = match$21[0];
              r = match$22[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "ConsOp" :
        if (match$1) {
          var match$23 = match$1[1];
          if (match$23) {
            var match$24 = match$23[1];
            if (match$24 && !match$24[1]) {
              l = match$1[0];
              op = match$23[0];
              r = match$24[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "ConstructorExpr" :
        var cname = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (n) {
                    return Curry._1(n[/* get_text */2], /* () */0);
                  }), match$1));
        alt = /* Constructor */Caml_chrome_debugger.variant("Constructor", 6, [cname]);
        break;
    case "DoExpr" :
        alt = /* Do_expr */Caml_chrome_debugger.variant("Do_expr", 22, [collect_action_stmts(match$1)]);
        break;
    case "EqualityOp" :
        if (match$1) {
          var match$25 = match$1[1];
          if (match$25) {
            var match$26 = match$25[1];
            if (match$26 && !match$26[1]) {
              l = match$1[0];
              op = match$25[0];
              r = match$26[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "External" :
        alt = /* External */1;
        break;
    case "GetterExpr" :
        if (match$1) {
          var match$27 = match$1[1];
          if (match$27) {
            var match$28 = match$27[1];
            alt = match$28 && !match$28[1] ? /* Getter */Caml_chrome_debugger.variant("Getter", 5, [Plate$BwaxAdmin.Str.after(1, Curry._1(match$27[0][/* get_text */2], /* () */0))]) : fail(node);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "IfExpr" :
        if (match$1) {
          var match$29 = match$1[1];
          if (match$29) {
            var match$30 = match$29[1];
            if (match$30) {
              var match$31 = match$30[1];
              if (match$31) {
                var match$32 = match$31[1];
                if (match$32) {
                  var match$33 = match$32[1];
                  alt = match$33 && !match$33[1] ? /* If */Caml_chrome_debugger.variant("If", 10, [
                        convert(match$29[0]),
                        convert(match$31[0]),
                        convert(match$33[0])
                      ]) : fail(node);
                } else {
                  alt = fail(node);
                }
              } else {
                alt = fail(node);
              }
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "Lambda" :
        var match$34 = Plate$BwaxAdmin.List.split_at(Plate$BwaxAdmin.List.length(match$1) - 2 | 0, match$1);
        var match$35 = match$34[1];
        if (match$35) {
          var match$36 = match$35[1];
          if (match$36 && !match$36[1]) {
            var patterns = collect_ptns(match$34[0]);
            var body$2 = convert(match$36[0]);
            alt = /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
                patterns,
                body$2
              ]);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "LetExpr" :
        var match$37 = Plate$BwaxAdmin.List.split_at(Plate$BwaxAdmin.List.length(match$1) - 2 | 0, match$1);
        var match$38 = match$37[1];
        if (match$38) {
          var match$39 = match$38[1];
          if (match$39 && !match$39[1]) {
            var body$3 = convert(match$39[0]);
            var aggr = function (n, defs) {
              var match = n[/* name */1];
              if (match === "Def") {
                return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          convert_def(n),
                          defs
                        ]);
              } else {
                return defs;
              }
            };
            var defs = Plate$BwaxAdmin.List.foldr(aggr, match$37[0], /* [] */0);
            alt = /* Let */Caml_chrome_debugger.variant("Let", 14, [
                defs,
                body$3
              ]);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "List" :
        alt = /* List */Caml_chrome_debugger.variant("List", 1, [convert_list(match$1)]);
        break;
    case "ListComprehension" :
        if (match$1) {
          var match$40 = match$1[1];
          if (match$40) {
            var match$41 = match$40[1];
            alt = match$41 ? /* List_comprehension */Caml_chrome_debugger.variant("List_comprehension", 21, [
                  convert(match$40[0]),
                  collect_action_stmts(match$41[1])
                ]) : fail(node);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "ListRange" :
        var convert_range_start = function (r) {
          var n = Caml_format.caml_int_of_string(Plate$BwaxAdmin.Str.drop_right(2, Curry._1(r[/* get_text */2], /* () */0)));
          return /* tuple */[
                  /* Literal */Caml_chrome_debugger.variant("Literal", 15, [/* Int */Caml_chrome_debugger.variant("Int", 0, [n])]),
                  get_meta(r)
                ];
        };
        if (match$1) {
          var match$42 = match$1[1];
          if (match$42) {
            var match$43 = match$42[1];
            if (match$43) {
              var match$44 = match$43[1];
              if (match$44) {
                var match$45 = match$44[1];
                var range_start = match$44[0];
                var range_start$1 = match$42[0];
                if (match$45) {
                  var match$46 = match$45[1];
                  if (match$46) {
                    var match$47 = match$46[1];
                    if (match$47) {
                      alt = match$47[1] ? fail(node) : /* List_range */Caml_chrome_debugger.variant("List_range", 20, [
                            convert(range_start$1),
                            convert(range_start),
                            convert(match$46[0])
                          ]);
                    } else {
                      var second = convert_range_start(range_start);
                      alt = /* List_range */Caml_chrome_debugger.variant("List_range", 20, [
                          convert(range_start$1),
                          second,
                          convert(match$45[0])
                        ]);
                    }
                  } else {
                    alt = /* List_range */Caml_chrome_debugger.variant("List_range", 20, [
                        convert(range_start$1),
                        undefined,
                        convert(range_start)
                      ]);
                  }
                } else {
                  var first = convert_range_start(range_start$1);
                  alt = /* List_range */Caml_chrome_debugger.variant("List_range", 20, [
                      first,
                      undefined,
                      convert(match$43[0])
                    ]);
                }
              } else {
                alt = fail(node);
              }
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "Literal" :
        alt = match$1 && !match$1[1] ? /* Literal */Caml_chrome_debugger.variant("Literal", 15, [extract_literal(match$1[0])]) : fail(node);
        break;
    case "LogicNotOp" :
        if (match$1) {
          var match$48 = match$1[1];
          alt = match$48 && !match$48[1] ? /* Unop */Caml_chrome_debugger.variant("Unop", 8, [
                /* Not */0,
                convert(match$48[0])
              ]) : fail(node);
        } else {
          alt = fail(node);
        }
        break;
    case "LogicOp" :
        if (match$1) {
          var match$49 = match$1[1];
          if (match$49) {
            var match$50 = match$49[1];
            if (match$50 && !match$50[1]) {
              l = match$1[0];
              op = match$49[0];
              r = match$50[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "MonadBindOp" :
        if (match$1) {
          var match$51 = match$1[1];
          if (match$51) {
            var match$52 = match$51[1];
            if (match$52 && !match$52[1]) {
              var meta$1 = get_meta(node);
              alt = Plate$BwaxAdmin.fst(Lang_eval$BwaxAdmin.build_monad_bind(meta$1, convert(match$1[0]), convert(match$52[0])));
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "MonadNextOp" :
        if (match$1) {
          var match$53 = match$1[1];
          if (match$53) {
            var match$54 = match$53[1];
            if (match$54 && !match$54[1]) {
              var meta$2 = get_meta(node);
              alt = Plate$BwaxAdmin.fst(Lang_eval$BwaxAdmin.build_monad_next(meta$2, convert(match$1[0]), convert(match$54[0])));
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "MulOp" :
        if (match$1) {
          var match$55 = match$1[1];
          if (match$55) {
            var match$56 = match$55[1];
            if (match$56 && !match$56[1]) {
              l = match$1[0];
              op = match$55[0];
              r = match$56[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "PipeFromOp" :
        if (match$1) {
          var match$57 = match$1[1];
          if (match$57) {
            var match$58 = match$57[1];
            alt = match$58 && !match$58[1] ? convert_apply(match$1[0], match$58[0]) : fail(node);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "PipeToOp" :
        if (match$1) {
          var match$59 = match$1[1];
          if (match$59) {
            var match$60 = match$59[1];
            alt = match$60 && !match$60[1] ? convert_apply(match$60[0], match$1[0]) : fail(node);
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "PowOp" :
        if (match$1) {
          var match$61 = match$1[1];
          if (match$61) {
            var match$62 = match$61[1];
            if (match$62 && !match$62[1]) {
              l = match$1[0];
              op = match$61[0];
              r = match$62[0];
              exit = 2;
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "QualRef" :
        var ref_name = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (n) {
                    return Curry._1(n[/* get_text */2], /* () */0);
                  }), match$1));
        alt = /* Ref */Caml_chrome_debugger.variant("Ref", 16, [ref_name]);
        break;
    case "Record" :
        var bind_exprs = collect_binds(match$1);
        alt = /* Record */Caml_chrome_debugger.variant("Record", 2, [bind_exprs]);
        break;
    case "RecordGet" :
        if (match$1) {
          var match$63 = match$1[1];
          alt = match$63 && !match$63[1] ? /* Record_get */Caml_chrome_debugger.variant("Record_get", 4, [
                convert(match$1[0]),
                Plate$BwaxAdmin.Str.after(1, Curry._1(match$63[0][/* get_text */2], /* () */0))
              ]) : fail(node);
        } else {
          alt = fail(node);
        }
        break;
    case "RecordGetOptional" :
        if (match$1) {
          var match$64 = match$1[1];
          alt = match$64 && !match$64[1] ? /* Record_get_optional */Caml_chrome_debugger.variant("Record_get_optional", 19, [
                convert(match$1[0]),
                Plate$BwaxAdmin.Str.after(2, Curry._1(match$64[0][/* get_text */2], /* () */0))
              ]) : fail(node);
        } else {
          alt = fail(node);
        }
        break;
    case "RecordUpdate" :
        if (match$1) {
          var match$65 = match$1[1];
          if (match$65) {
            var match$66 = match$65[1];
            if (match$66) {
              var bind_exprs$1 = collect_binds(match$66[1]);
              alt = /* Record_upd */Caml_chrome_debugger.variant("Record_upd", 3, [
                  Curry._1(match$65[0][/* get_text */2], /* () */0),
                  bind_exprs$1
                ]);
            } else {
              alt = fail(node);
            }
          } else {
            alt = fail(node);
          }
        } else {
          alt = fail(node);
        }
        break;
    case "Ref" :
        alt = match$1 && !match$1[1] ? /* Ref */Caml_chrome_debugger.variant("Ref", 16, [Curry._1(match$1[0][/* get_text */2], /* () */0)]) : fail(node);
        break;
    case "Tuple" :
        alt = /* Tuple */Caml_chrome_debugger.variant("Tuple", 0, [convert_list(match$1)]);
        break;
    case "TypeAnnotExpr" :
        if (match$1) {
          var match$67 = match$1[1];
          alt = match$67 && !match$67[1] ? /* Type_annoted_expr */Caml_chrome_debugger.variant("Type_annoted_expr", 18, [
                convert(match$1[0]),
                convert_type_annot(match$67[0])
              ]) : fail(node);
        } else {
          alt = fail(node);
        }
        break;
    case "Unit" :
        alt = /* Unit */0;
        break;
    case "WoRecordList" :
        alt = match$1 ? /* List_wo_record */Caml_chrome_debugger.variant("List_wo_record", 17, [convert_list(match$1[1])]) : fail(node);
        break;
    default:
      alt = fail(node);
  }
  switch (exit) {
    case 1 :
        var meta$3 = get_meta(node);
        var params_000$3 = /* tuple */[
          /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, ["x"]),
          meta$3
        ];
        var params$3 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
            params_000$3,
            /* [] */0
          ]);
        var x_000$2 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["x"]);
        var x$2 = /* tuple */[
          x_000$2,
          meta$3
        ];
        var body_000$2 = /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
            convert(g_n),
            /* tuple */[
              /* Apply */Caml_chrome_debugger.variant("Apply", 7, [
                  convert(f_n),
                  x$2
                ]),
              meta$3
            ]
          ]);
        var body$4 = /* tuple */[
          body_000$2,
          meta$3
        ];
        alt = /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
            params$3,
            body$4
          ]);
        break;
    case 2 :
        alt = /* Binop */Caml_chrome_debugger.variant("Binop", 9, [
            convert_binop(op),
            convert(l),
            convert(r)
          ]);
        break;
    
  }
  return /* tuple */[
          alt,
          get_meta(node)
        ];
}

function convert_type_decl(n) {
  var i = get_meta(n);
  var match = n[/* children */3];
  if (match) {
    var match$1 = match[1];
    var alt_n = match[0];
    if (match$1) {
      var match$2 = match$1[1];
      if (match$2 && !match$2[1]) {
        var alt_000 = convert_type_decl_alt(alt_n);
        var alt_001 = convert_type_decl(match$2[0]);
        var alt = /* Arrow_type */Caml_chrome_debugger.variant("Arrow_type", 3, [
            alt_000,
            alt_001
          ]);
        return /* tuple */[
                alt,
                i
              ];
      } else {
        return fail(n);
      }
    } else {
      return convert_type_decl_alt(alt_n);
    }
  } else {
    return fail(n);
  }
}

function convert_ptn(_node) {
  while(true) {
    var node = _node;
    var n = first_child(node);
    var info = get_meta(n);
    var match = n[/* name */1];
    var match$1 = n[/* children */3];
    switch (match) {
      case "Literal" :
          if (match$1 && !match$1[1]) {
            return /* tuple */[
                    /* Ptn_literal */Caml_chrome_debugger.variant("Ptn_literal", 3, [extract_literal(match$1[0])]),
                    info
                  ];
          } else {
            return fail(n);
          }
      case "PatternBracket" :
          if (match$1) {
            var match$2 = match$1[1];
            if (match$2) {
              var match$3 = match$2[1];
              if (match$3 && !match$3[1]) {
                _node = match$2[0];
                continue ;
              } else {
                return fail(n);
              }
            } else {
              return fail(n);
            }
          } else {
            return fail(n);
          }
      case "PatternConsList" :
          if (match$1) {
            var match$4 = match$1[1];
            if (match$4) {
              var match$5 = match$4[1];
              if (match$5 && !match$5[1]) {
                var h = convert_ptn(match$1[0]);
                var tl = convert_ptn(match$5[0]);
                return /* tuple */[
                        /* Ptn_cons_list */Caml_chrome_debugger.variant("Ptn_cons_list", 4, [
                            h,
                            tl
                          ]),
                        info
                      ];
              } else {
                return fail(n);
              }
            } else {
              return fail(n);
            }
          } else {
            return fail(n);
          }
      case "PatternConstructor" :
          var tname = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (n) {
                      return Curry._1(n[/* get_text */2], /* () */0);
                    }), Plate$BwaxAdmin.List.keep((function (n) {
                          if (n[/* name */1] === "CapName") {
                            return true;
                          } else {
                            return n[/* name */1] === "DotCapName";
                          }
                        }), match$1)));
          var ptns = collect_ptns(match$1);
          return /* tuple */[
                  /* Ptn_constructor */Caml_chrome_debugger.variant("Ptn_constructor", 1, [
                      tname,
                      ptns
                    ]),
                  info
                ];
      case "PatternNilList" :
          return /* tuple */[
                  /* Ptn_nil_list */2,
                  info
                ];
      case "PatternRecord" :
          var names = Plate$BwaxAdmin.List.foldr((function (c, acc) {
                  if (c[/* name */1] === "Name") {
                    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Curry._1(c[/* get_text */2], /* () */0),
                              acc
                            ]);
                  } else {
                    return acc;
                  }
                }), match$1, /* [] */0);
          return /* tuple */[
                  /* Ptn_record */Caml_chrome_debugger.variant("Ptn_record", 5, [names]),
                  info
                ];
      case "PatternTuple" :
          var ptns$1 = collect_ptns(match$1);
          return /* tuple */[
                  /* Ptn_tuple */Caml_chrome_debugger.variant("Ptn_tuple", 2, [ptns$1]),
                  info
                ];
      case "PatternVar" :
          return /* tuple */[
                  /* Ptn_var */Caml_chrome_debugger.variant("Ptn_var", 0, [Curry._1(n[/* get_text */2], /* () */0)]),
                  info
                ];
      case "Underscore" :
          return /* tuple */[
                  /* Ptn_wildcard */0,
                  info
                ];
      case "Unit" :
          return /* tuple */[
                  /* Ptn_unit */1,
                  info
                ];
      default:
        return fail(n);
    }
  };
}

function collect_ptns(l) {
  return Plate$BwaxAdmin.List.keep_map((function (n) {
                var match = n[/* name */1];
                if (match === "Pattern") {
                  return convert_ptn(n);
                }
                
              }), l);
}

function convert_type_annot(n) {
  var match = n[/* children */3];
  if (match) {
    var match$1 = match[1];
    if (match$1 && !match$1[1]) {
      return convert_type_decl(match$1[0]);
    } else {
      return fail(n);
    }
  } else {
    return fail(n);
  }
}

function convert_def(node) {
  var n = first_child(node);
  var match = n[/* name */1];
  var match$1 = n[/* children */3];
  switch (match) {
    case "FunctionDef" :
        if (match$1) {
          var tl = match$1[1];
          var match$2 = Plate$BwaxAdmin.List.split_at(Plate$BwaxAdmin.List.length(tl) - 2 | 0, tl);
          var match$3 = match$2[1];
          if (match$3) {
            var match$4 = match$3[1];
            if (match$4 && !match$4[1]) {
              var match$5 = collect_ptn_annot_type(match$2[0]);
              return /* tuple */[
                      /* Def_fun */Caml_chrome_debugger.variant("Def_fun", 1, [
                          Curry._1(match$1[0][/* get_text */2], /* () */0),
                          match$5[0],
                          convert(match$4[0]),
                          match$5[1]
                        ]),
                      get_meta(n)
                    ];
            } else {
              return fail(n);
            }
          } else {
            return fail(n);
          }
        } else {
          return fail(n);
        }
    case "OpaqueTypeDef" :
        if (match$1) {
          var match$6 = match$1[1];
          if (match$6) {
            var names = Plate$BwaxAdmin.List.foldr((function (n, acc) {
                    if (n[/* name */1] === "Name") {
                      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Curry._1(n[/* get_text */2], /* () */0),
                                acc
                              ]);
                    } else {
                      return acc;
                    }
                  }), match$6[1], /* [] */0);
            return /* tuple */[
                    /* Def_type_opaque */Caml_chrome_debugger.variant("Def_type_opaque", 4, [
                        Curry._1(match$6[0][/* get_text */2], /* () */0),
                        names
                      ]),
                    get_meta(n)
                  ];
          } else {
            return fail(n);
          }
        } else {
          return fail(n);
        }
    case "TypeAliasDef" :
        if (match$1) {
          var match$7 = match$1[1];
          if (match$7) {
            var match$8 = match$7[1];
            if (match$8) {
              var match$9 = Plate$BwaxAdmin.List.foldr((function (n, param) {
                      var maybe_td = param[1];
                      var vars = param[0];
                      var match = n[/* name */1];
                      switch (match) {
                        case "Name" :
                            return /* tuple */[
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        Curry._1(n[/* get_text */2], /* () */0),
                                        vars
                                      ]),
                                    maybe_td
                                  ];
                        case "TypeDecl" :
                            return /* tuple */[
                                    vars,
                                    convert_type_decl(n)
                                  ];
                        default:
                          return /* tuple */[
                                  vars,
                                  maybe_td
                                ];
                      }
                    }), match$8[1], /* tuple */[
                    /* [] */0,
                    undefined
                  ]);
              var maybe_td = match$9[1];
              if (maybe_td !== undefined) {
                var tname = Curry._1(match$8[0][/* get_text */2], /* () */0);
                return /* tuple */[
                        /* Def_type_alias */Caml_chrome_debugger.variant("Def_type_alias", 3, [
                            tname,
                            match$9[0],
                            maybe_td
                          ]),
                        get_meta(n)
                      ];
              } else {
                return fail(n);
              }
            } else {
              return fail(n);
            }
          } else {
            return fail(n);
          }
        } else {
          return fail(n);
        }
    case "TypeDef" :
        if (match$1) {
          var match$10 = match$1[1];
          if (match$10) {
            var tl$1 = match$10[1];
            var names$1 = Plate$BwaxAdmin.List.foldr((function (n, acc) {
                    if (n[/* name */1] === "Name") {
                      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Curry._1(n[/* get_text */2], /* () */0),
                                acc
                              ]);
                    } else {
                      return acc;
                    }
                  }), tl$1, /* [] */0);
            var constrs = Plate$BwaxAdmin.List.foldr((function (n, acc) {
                    var match = n[/* name */1];
                    if (match === "ConstrDecl") {
                      var ant = convert_type_decl_alt(first_child(n));
                      var match$1 = ant[0];
                      var constr;
                      constr = typeof match$1 === "number" || match$1.tag !== /* Constr_type */1 ? fail(n) : /* tuple */[
                          match$1[0],
                          match$1[1],
                          ant[1]
                        ];
                      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                constr,
                                acc
                              ]);
                    } else {
                      return acc;
                    }
                  }), tl$1, /* [] */0);
            return /* tuple */[
                    /* Def_type */Caml_chrome_debugger.variant("Def_type", 2, [
                        Curry._1(match$10[0][/* get_text */2], /* () */0),
                        names$1,
                        constrs
                      ]),
                    get_meta(n)
                  ];
          } else {
            return fail(n);
          }
        } else {
          return fail(n);
        }
    case "ValueDef" :
        if (match$1) {
          var match$11 = match$1[1];
          if (match$11) {
            var match$12 = match$11[1];
            if (match$12) {
              var match$13 = match$12[1];
              var ptn_n = match$1[0];
              if (match$13) {
                if (match$13[1]) {
                  return fail(n);
                } else {
                  return /* tuple */[
                          /* Def_val */Caml_chrome_debugger.variant("Def_val", 0, [
                              convert_ptn(ptn_n),
                              convert(match$13[0]),
                              convert_type_annot(match$11[0])
                            ]),
                          get_meta(n)
                        ];
                }
              } else {
                return /* tuple */[
                        /* Def_val */Caml_chrome_debugger.variant("Def_val", 0, [
                            convert_ptn(ptn_n),
                            convert(match$12[0]),
                            undefined
                          ]),
                        get_meta(n)
                      ];
              }
            } else {
              return fail(n);
            }
          } else {
            return fail(n);
          }
        } else {
          return fail(n);
        }
    default:
      return fail(n);
  }
}

function collect_ptn_annot_type(l) {
  var aggr = function (n, param) {
    var m_annot_type = param[1];
    var ptns = param[0];
    var _n = n[/* name */1];
    switch (_n) {
      case "Pattern" :
          return /* tuple */[
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      convert_ptn(n),
                      ptns
                    ]),
                  m_annot_type
                ];
      case "TypeAnnot" :
          return /* tuple */[
                  ptns,
                  convert_type_annot(n)
                ];
      default:
        return /* tuple */[
                ptns,
                m_annot_type
              ];
    }
  };
  return Plate$BwaxAdmin.List.foldr(aggr, l, /* tuple */[
              /* [] */0,
              undefined
            ]);
}

function convert_type_decl_alt(n) {
  var i = get_meta(n);
  var collect_field_types = function (nl) {
    return Plate$BwaxAdmin.List.keep_map((function (n) {
                  if (n[/* name */1] === "FieldTypeDecl") {
                    var match = n[/* children */3];
                    if (match) {
                      var match$1 = match[1];
                      if (match$1) {
                        var match$2 = match$1[1];
                        if (match$2) {
                          var match$3 = match$2[1];
                          var td_n = match$2[0];
                          var name_n = match[0];
                          if (match$3) {
                            if (match$3[1]) {
                              return fail(n);
                            } else {
                              return /* tuple */[
                                      Curry._1(name_n[/* get_text */2], /* () */0),
                                      convert_type_decl(td_n),
                                      true
                                    ];
                            }
                          } else {
                            return /* tuple */[
                                    Curry._1(name_n[/* get_text */2], /* () */0),
                                    convert_type_decl(td_n),
                                    false
                                  ];
                          }
                        } else {
                          return fail(n);
                        }
                      } else {
                        return fail(n);
                      }
                    } else {
                      return fail(n);
                    }
                  }
                  
                }), nl);
  };
  var get_decl_alt = function (c) {
    var extract_constr = function (l) {
      var name_part = Plate$BwaxAdmin.List.filter((function (c) {
                if (c[/* name */1] === "CapName") {
                  return true;
                } else {
                  return c[/* name */1] === "DotCapName";
                }
              }))(l);
      var var_part = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.List.drop(Plate$BwaxAdmin.List.length(name_part), l));
      var name = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (n) {
                  return Curry._1(n[/* get_text */2], /* () */0);
                }), name_part));
      var sons = Plate$BwaxAdmin.List.map((function (c) {
              return /* tuple */[
                      get_decl_alt(c),
                      get_meta(c)
                    ];
            }), var_part);
      return /* tuple */[
              name,
              sons
            ];
    };
    var match = c[/* name */1];
    var match$1 = c[/* children */3];
    switch (match) {
      case "Constr0TypeDecl" :
          var type_name = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (n) {
                      return Curry._1(n[/* get_text */2], /* () */0);
                    }), match$1));
          return /* Constr_type */Caml_chrome_debugger.variant("Constr_type", 1, [
                    type_name,
                    /* [] */0
                  ]);
      case "ConstrTypeDecl" :
          var match$2 = extract_constr(match$1);
          return /* Constr_type */Caml_chrome_debugger.variant("Constr_type", 1, [
                    match$2[0],
                    match$2[1]
                  ]);
      case "OpaquedTypeDecl" :
          if (match$1 && !match$1[1]) {
            return /* Opaqued_type */1;
          }
          break;
      case "RecordExtTypeDecl" :
          var sons = Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          param[1]
                        ];
                }), collect_field_types(match$1));
          return /* Record_ext_type */Caml_chrome_debugger.variant("Record_ext_type", 5, [sons]);
      case "RecordTypeDecl" :
          var sons$1 = collect_field_types(match$1);
          return /* Record_type */Caml_chrome_debugger.variant("Record_type", 4, [sons$1]);
      case "RoRecordTypeDecl" :
          if (match$1) {
            var match$3 = match$1[1];
            if (match$3 && !match$3[1]) {
              var n$1 = match$3[0];
              var match$4 = n$1[/* name */1];
              var match$5 = n$1[/* children */3];
              var exit = 0;
              switch (match$4) {
                case "Constr0TypeDecl" :
                    if (match$5 && !match$5[1]) {
                      return /* Ro_record_type */Caml_chrome_debugger.variant("Ro_record_type", 6, [
                                Curry._1(match$5[0][/* get_text */2], /* () */0),
                                /* [] */0
                              ]);
                    } else {
                      exit = 2;
                    }
                    break;
                case "ConstrTypeDecl" :
                    var match$6 = extract_constr(match$5);
                    return /* Ro_record_type */Caml_chrome_debugger.variant("Ro_record_type", 6, [
                              match$6[0],
                              match$6[1]
                            ]);
                case "RecordTypeDecl" :
                    var sons$2 = collect_field_types(match$5);
                    return /* Ro_record_of */Caml_chrome_debugger.variant("Ro_record_of", 7, [sons$2]);
                default:
                  exit = 2;
              }
              if (exit === 2) {
                console.log("Wrong", n$1);
                return fail(n$1);
              }
              
            }
            
          }
          break;
      case "RoVarTypeDecl" :
          if (match$1) {
            var match$7 = match$1[1];
            if (match$7 && !match$7[1]) {
              return /* Ro_var_type */Caml_chrome_debugger.variant("Ro_var_type", 10, [Curry._1(match$7[0][/* get_text */2], /* () */0)]);
            }
            
          }
          break;
      case "TupleTypeDecl" :
          var sons$3 = Plate$BwaxAdmin.List.foldr((function (n, acc) {
                  if (n[/* name */1] === "TypeDecl") {
                    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              convert_type_decl(n),
                              acc
                            ]);
                  } else {
                    return acc;
                  }
                }), match$1, /* [] */0);
          return /* Tuple_type */Caml_chrome_debugger.variant("Tuple_type", 2, [sons$3]);
      case "TypeDeclBracket" :
          if (match$1) {
            var match$8 = match$1[1];
            if (match$8) {
              var match$9 = match$8[1];
              if (match$9 && !match$9[1]) {
                return convert_type_decl(match$8[0])[0];
              }
              
            }
            
          }
          break;
      case "UnitTypeDecl" :
          return /* Unit_type */0;
      case "VarTypeDecl" :
          return /* Var_type */Caml_chrome_debugger.variant("Var_type", 0, [Curry._1(c[/* get_text */2], /* () */0)]);
      case "WoOptionalRecordTypeDecl" :
          if (match$1) {
            var match$10 = match$1[1];
            if (match$10) {
              var match$11 = match$10[1];
              if (match$11 && !match$11[1]) {
                var n$2 = match$11[0];
                var match$12 = n$2[/* name */1];
                var match$13 = n$2[/* children */3];
                var exit$1 = 0;
                switch (match$12) {
                  case "Constr0TypeDecl" :
                      if (match$13 && !match$13[1]) {
                        return /* Wo_optional_record_type */Caml_chrome_debugger.variant("Wo_optional_record_type", 11, [
                                  Curry._1(match$13[0][/* get_text */2], /* () */0),
                                  /* [] */0
                                ]);
                      } else {
                        exit$1 = 2;
                      }
                      break;
                  case "ConstrTypeDecl" :
                      var match$14 = extract_constr(match$13);
                      return /* Wo_optional_record_type */Caml_chrome_debugger.variant("Wo_optional_record_type", 11, [
                                match$14[0],
                                match$14[1]
                              ]);
                  default:
                    exit$1 = 2;
                }
                if (exit$1 === 2) {
                  console.log("Wrong", n$2);
                  return fail(n$2);
                }
                
              }
              
            }
            
          }
          break;
      case "WoRecordTypeDecl" :
          if (match$1) {
            var match$15 = match$1[1];
            if (match$15 && !match$15[1]) {
              var n$3 = match$15[0];
              var match$16 = n$3[/* name */1];
              var match$17 = n$3[/* children */3];
              var exit$2 = 0;
              switch (match$16) {
                case "Constr0TypeDecl" :
                    if (match$17 && !match$17[1]) {
                      return /* Wo_record_type */Caml_chrome_debugger.variant("Wo_record_type", 8, [
                                Curry._1(match$17[0][/* get_text */2], /* () */0),
                                /* [] */0
                              ]);
                    } else {
                      exit$2 = 2;
                    }
                    break;
                case "ConstrTypeDecl" :
                    var match$18 = extract_constr(match$17);
                    return /* Wo_record_type */Caml_chrome_debugger.variant("Wo_record_type", 8, [
                              match$18[0],
                              match$18[1]
                            ]);
                case "RecordTypeDecl" :
                    var sons$4 = collect_field_types(match$17);
                    return /* Wo_record_of */Caml_chrome_debugger.variant("Wo_record_of", 9, [sons$4]);
                default:
                  exit$2 = 2;
              }
              if (exit$2 === 2) {
                console.log("Wrong", n$3);
                return fail(n$3);
              }
              
            }
            
          }
          break;
      default:
        
    }
    console.log("not impl", c[/* name */1], c);
    return fail(n);
  };
  return /* tuple */[
          get_decl_alt(n),
          i
        ];
}

function convert_import(node) {
  var n = first_child(node);
  var match = n[/* name */1];
  var match$1 = n[/* children */3];
  switch (match) {
    case "ImportExposing" :
        var match$2 = Plate$BwaxAdmin.List.foldr((function (cn, param) {
                var met_exposing = param[2];
                var exposed_names = param[1];
                var mod_names = param[0];
                var text = Curry._1(cn[/* get_text */2], /* () */0);
                if (cn[/* name */1] === "exposing") {
                  return /* tuple */[
                          mod_names,
                          exposed_names,
                          true
                        ];
                } else if (text === "," || text === "(" || text === ")" || cn[/* name */1] === "import") {
                  return /* tuple */[
                          mod_names,
                          exposed_names,
                          met_exposing
                        ];
                } else if (met_exposing) {
                  return /* tuple */[
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              text,
                              mod_names
                            ]),
                          exposed_names,
                          met_exposing
                        ];
                } else {
                  var match = cn[/* children */3];
                  var exposed_name;
                  if (match) {
                    var name_n = match[0];
                    exposed_name = match[1] ? /* Exposed_with_variants */Caml_chrome_debugger.variant("Exposed_with_variants", 1, [Curry._1(name_n[/* get_text */2], /* () */0)]) : /* Exposed */Caml_chrome_debugger.variant("Exposed", 0, [Curry._1(name_n[/* get_text */2], /* () */0)]);
                  } else {
                    exposed_name = fail(cn);
                  }
                  return /* tuple */[
                          mod_names,
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                exposed_name,
                                get_meta(cn)
                              ],
                              exposed_names
                            ]),
                          met_exposing
                        ];
                }
              }), match$1, /* tuple */[
              /* [] */0,
              /* [] */0,
              false
            ]);
        var mod_name = Plate$BwaxAdmin.Str.join("", match$2[0]);
        return /* tuple */[
                /* Def_import */Caml_chrome_debugger.variant("Def_import", 5, [/* Import_exposing */Caml_chrome_debugger.variant("Import_exposing", 0, [
                        mod_name,
                        match$2[1]
                      ])]),
                get_meta(n)
              ];
    case "ImportExposingAll" :
        var mod_names = Plate$BwaxAdmin.List.foldr((function (cn, mod_names) {
                if (cn[/* name */1] === "CapName" || cn[/* name */1] === "DotCapName") {
                  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Curry._1(cn[/* get_text */2], /* () */0),
                            mod_names
                          ]);
                } else {
                  return mod_names;
                }
              }), match$1, /* [] */0);
        var mod_name$1 = Plate$BwaxAdmin.Str.join("", mod_names);
        return /* tuple */[
                /* Def_import */Caml_chrome_debugger.variant("Def_import", 5, [/* Import_all */Caml_chrome_debugger.variant("Import_all", 1, [mod_name$1])]),
                get_meta(n)
              ];
    case "ImportName" :
        var match$3 = Plate$BwaxAdmin.List.foldr((function (cn, param) {
                var as_name = param[1];
                var mod_names = param[0];
                var text = Curry._1(cn[/* get_text */2], /* () */0);
                if (text === "," || text === "(" || text === ")" || cn[/* name */1] === "import" || cn[/* name */1] === "as") {
                  return /* tuple */[
                          mod_names,
                          as_name
                        ];
                } else if (as_name !== undefined) {
                  return /* tuple */[
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              text,
                              mod_names
                            ]),
                          as_name
                        ];
                } else {
                  return /* tuple */[
                          mod_names,
                          text
                        ];
                }
              }), match$1, /* tuple */[
              /* [] */0,
              undefined
            ]);
        var as_name = match$3[1];
        var mod_name$2 = Plate$BwaxAdmin.Str.join("", match$3[0]);
        if (as_name !== undefined) {
          return /* tuple */[
                  /* Def_import */Caml_chrome_debugger.variant("Def_import", 5, [/* Import_as */Caml_chrome_debugger.variant("Import_as", 2, [
                          mod_name$2,
                          as_name
                        ])]),
                  get_meta(n)
                ];
        } else {
          return fail(n);
        }
    default:
      return fail(n);
  }
}

function convert_defs(n) {
  var maybe_module_decl_n = Plate$BwaxAdmin.List.find((function (c) {
          return c[/* name */1] === "ModuleDecl";
        }), n[/* children */3]);
  var match = Plate$BwaxAdmin.$$Option.with_default(/* tuple */[
        undefined,
        undefined
      ], Plate$BwaxAdmin.$$Option.map((function (n) {
              var match = Plate$BwaxAdmin.List.foldl((function (param, c) {
                      var met_exposing = param[2];
                      var exposing_part = param[1];
                      var module_names = param[0];
                      if (met_exposing) {
                        return /* tuple */[
                                module_names,
                                Pervasives.$at(exposing_part, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        c,
                                        /* [] */0
                                      ])),
                                met_exposing
                              ];
                      } else if (Curry._1(c[/* get_text */2], /* () */0) === "exposing") {
                        return /* tuple */[
                                module_names,
                                exposing_part,
                                true
                              ];
                      } else if (c[/* name */1] === "CapName" || c[/* name */1] === "DotCapName") {
                        return /* tuple */[
                                Pervasives.$at(module_names, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        c,
                                        /* [] */0
                                      ])),
                                exposing_part,
                                met_exposing
                              ];
                      } else {
                        return /* tuple */[
                                module_names,
                                exposing_part,
                                met_exposing
                              ];
                      }
                    }), /* tuple */[
                    /* [] */0,
                    /* [] */0,
                    false
                  ], n[/* children */3]);
              var exposing_part = match[1];
              var module_name = Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map((function (c) {
                          return Curry._1(c[/* get_text */2], /* () */0);
                        }), match[0]));
              if (exposing_part) {
                var exposed = Plate$BwaxAdmin.List.foldr((function (cn, acc) {
                        if (cn[/* name */1] === "ExposedName") {
                          var match = cn[/* children */3];
                          var exposed_name;
                          if (match) {
                            var name_n = match[0];
                            exposed_name = match[1] ? /* Exposed_with_variants */Caml_chrome_debugger.variant("Exposed_with_variants", 1, [Curry._1(name_n[/* get_text */2], /* () */0)]) : /* Exposed */Caml_chrome_debugger.variant("Exposed", 0, [Curry._1(name_n[/* get_text */2], /* () */0)]);
                          } else {
                            exposed_name = fail(cn);
                          }
                          return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      exposed_name,
                                      get_meta(cn)
                                    ],
                                    acc
                                  ]);
                        } else {
                          return acc;
                        }
                      }), exposing_part, /* [] */0);
                return /* tuple */[
                        module_name,
                        /* tuple */[
                          /* Def_exposing */Caml_chrome_debugger.variant("Def_exposing", 6, [exposed]),
                          get_meta(n)
                        ]
                      ];
              } else {
                return /* tuple */[
                        module_name,
                        undefined
                      ];
              }
            }), maybe_module_decl_n));
  var exposing = match[1];
  var imports = Plate$BwaxAdmin.List.map(convert_import, Plate$BwaxAdmin.List.filter((function (c) {
                return c[/* name */1] === "ImportStmt";
              }))(n[/* children */3]));
  var defs = Plate$BwaxAdmin.List.map(convert_def, Plate$BwaxAdmin.List.filter((function (c) {
                return c[/* name */1] === "Def";
              }))(n[/* children */3]));
  var all_defs = exposing !== undefined ? Pervasives.$at(imports, Pervasives.$at(defs, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                exposing,
                /* [] */0
              ]))) : Pervasives.$at(imports, defs);
  return /* tuple */[
          match[0],
          all_defs
        ];
}

function convert_expr_entry(code_text, tree) {
  var error_collector = /* record */Caml_chrome_debugger.record(["errors"], [0]);
  Performance$BwaxAdmin.now(/* () */0);
  var node = Curry._1(Plate$BwaxAdmin.$$Option.force, build_syntax_node(error_collector, undefined, code_text, tree.topNode));
  Performance$BwaxAdmin.now(/* () */0);
  var match = error_collector[/* errors */0];
  if (match) {
    var h = match[0];
    var length = Plate$BwaxAdmin.Str.length(code_text);
    var s = (h[/* start_index */4] - 10 | 0) < 0 ? 0 : h[/* start_index */4] - 10 | 0;
    var e = (h[/* end_index */5] + 10 | 0) > length ? length : h[/* end_index */5] + 10 | 0;
    console.log("Error occured at", Plate$BwaxAdmin.Str.sub(s, e - s | 0, code_text), Plate$BwaxAdmin.Str.sub(0, length > 50 ? 50 : length, code_text));
    return /* tuple */[
            undefined,
            /* tuple */[
              "Something else should be here",
              h[/* start_index */4],
              h[/* end_index */5]
            ]
          ];
  } else {
    var child_node = Curry._1(Plate$BwaxAdmin.$$Option.force, Plate$BwaxAdmin.List.head(node[/* children */3]));
    try {
      return /* tuple */[
              /* tuple */[
                convert(child_node),
                tree
              ],
              undefined
            ];
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn[0] === Convert_exn) {
        var m = exn[3];
        console.log("Error converting defs", exn[1], exn[2], m);
        return /* tuple */[
                undefined,
                /* tuple */[
                  "The syntax is not known",
                  m[/* start_index */2],
                  m[/* end_index */3]
                ]
              ];
      } else {
        throw exn;
      }
    }
  }
}

function convert_defs_entry(code_text, tree) {
  var error_collector = /* record */Caml_chrome_debugger.record(["errors"], [0]);
  Performance$BwaxAdmin.now(/* () */0);
  var node = Curry._1(Plate$BwaxAdmin.$$Option.force, build_syntax_node(error_collector, undefined, code_text, tree.topNode));
  Performance$BwaxAdmin.now(/* () */0);
  var match = error_collector[/* errors */0];
  if (match) {
    var h = match[0];
    var length = Plate$BwaxAdmin.Str.length(code_text);
    var s = (h[/* start_index */4] - 10 | 0) < 0 ? 0 : h[/* start_index */4] - 10 | 0;
    var e = (h[/* end_index */5] + 10 | 0) > length ? length : h[/* end_index */5] + 10 | 0;
    console.log("Error occured at", Plate$BwaxAdmin.Str.sub(s, e - s | 0, code_text), Plate$BwaxAdmin.Str.sub(0, length > 50 ? 50 : length, code_text));
    return /* tuple */[
            undefined,
            /* tuple */[
              "Something else should be here",
              h[/* start_index */4],
              h[/* end_index */5]
            ]
          ];
  } else {
    try {
      return /* tuple */[
              /* tuple */[
                convert_defs(node),
                tree
              ],
              undefined
            ];
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn[0] === Convert_exn) {
        var m = exn[3];
        console.log("Error converting defs", exn[1], exn[2], m);
        return /* tuple */[
                undefined,
                /* tuple */[
                  "The syntax is not known",
                  m[/* start_index */2],
                  m[/* end_index */3]
                ]
              ];
      } else {
        throw exn;
      }
    }
  }
}

function parse(code_text) {
  var tree = OreLezerParser.parse(code_text);
  return convert_defs_entry(code_text, tree);
}

exports.build_syntax_node = build_syntax_node;
exports.get_meta = get_meta;
exports.Convert_exn = Convert_exn;
exports.fail = fail;
exports.first_child = first_child;
exports.to_arith_op = to_arith_op;
exports.to_compare_op = to_compare_op;
exports.to_equality_op = to_equality_op;
exports.to_logic_op = to_logic_op;
exports.extract_literal = extract_literal;
exports.convert = convert;
exports.collect_ptns = collect_ptns;
exports.collect_ptn_annot_type = collect_ptn_annot_type;
exports.convert_ptn = convert_ptn;
exports.convert_type_decl = convert_type_decl;
exports.convert_type_decl_alt = convert_type_decl_alt;
exports.convert_type_annot = convert_type_annot;
exports.convert_def = convert_def;
exports.convert_import = convert_import;
exports.convert_defs = convert_defs;
exports.convert_expr_entry = convert_expr_entry;
exports.convert_defs_entry = convert_defs_entry;
exports.parse = parse;
/* Scanf Not a pure module */
