
import Editor, {composeDecorators} from 'draft-js-plugins-editor'

import createFocusPlugin from 'draft-js-focus-plugin';

import createImagePlugin from './plugins/image'
import createMediaPlugin from './plugins/media'
import createCodePlugin from './plugins/code'
import createResizeablePlugin from './plugins/resizeable'
import createBlockAlignmentPlugin from './plugins/blockAlignment'

import createInlineStylePlugin from './plugins/inlineStyle'
import createEmptyBlockPlugin from './plugins/emptyBlock'
import createHyperlinkPlugin from './plugins/hyperlink'


import createHRPlugin from './plugins/hr'

import createInlineToolbarPlugin from './plugins/inlineToolbar'
import createToolbarPlugin from './plugins/toolbar'

import createMentionPlugin from './plugins/mention'

export function createPlugins(config) {
  const focusPlugin = createFocusPlugin({
    theme: {
      focused : "focused",
      unfocused: "unfocused"
    },
    ...config
  })
  const resizeablePlugin = createResizeablePlugin({
    horizontal: "relative",
    ...config
  })

  const decorator = composeDecorators(
    resizeablePlugin.decorator,
    focusPlugin.decorator,
  );
  const imagePlugin = createImagePlugin({
    decorator,
    ...config
  })
  const mediaPlugin = createMediaPlugin({
    decorator: focusPlugin.decorator,
    ...config
  })

  const blockAlignmentPlugin = createBlockAlignmentPlugin({
    ...config
  })

  const inlineStylePlugin = createInlineStylePlugin({})
  const emptyBlockPlugin = createEmptyBlockPlugin({})

  const inlineToolbarPlugin = createInlineToolbarPlugin({})
  const toolbarPlugin = createToolbarPlugin({})

  const hrPlugin = createHRPlugin({
    decorator: focusPlugin.decorator,
    ...config
  })

  const hyperLinkPlugin = createHyperlinkPlugin({})

  const codePlugin = createCodePlugin({})

  const mentionPlugin = createMentionPlugin({})

  const plugins = [focusPlugin, resizeablePlugin, emptyBlockPlugin,
    imagePlugin, mediaPlugin, blockAlignmentPlugin, inlineStylePlugin,
    hyperLinkPlugin, inlineToolbarPlugin, toolbarPlugin, hrPlugin, codePlugin,
    mentionPlugin
  ]

  return plugins
}
