

import React, { useEffect, useState } from 'react'

import { BsDot, BsArrowsMove } from 'react-icons/bs'
import { 
    CgCornerDoubleDownRight, CgCornerDoubleDownLeft, CgCornerDoubleLeftDown, CgCornerDoubleRightDown,
    CgArrowDown, CgArrowLeft, CgArrowRight, CgArrowUp
} from 'react-icons/cg';

import { Input, Select } from '@arco-design/web-react';
const Option = Select.Option;

import './PositionEdit.less'

export default function PositionEdit({ attribute, unitsArr, onChange }) {

    const [position, setPosition] = useState({ 
        left: { value: '50', unit: '%' },
        top: { value: '50', unit: '%'}
    })

    const positionRegx = /(\d+(?:\.\d+)?)(px|em|vw|vh|%) (\d+(?:\.\d+)?)(px|em|vw|vh|%)/
    
    useEffect(() => {
        if(attribute) {
            const positionMatchResult = attribute.match(positionRegx) 
            setPosition({
                left: { value: positionMatchResult[1], unit: positionMatchResult[2]},
                top: { value: positionMatchResult[3], unit: positionMatchResult[4]}
            })
        }
    }, [attribute])

    const positionDot = [{
        value: '0% 0%',
        activeIcon: <CgCornerDoubleLeftDown style={{ fontSize: 16 }}/>
    }, {
        value: '50% 0%',
        activeIcon: (
            <div className='position-top'>
                <div> <CgArrowLeft/><BsDot/><CgArrowRight/></div>
               <CgArrowDown/>
            </div>
        )
    }, {
        value: '100% 0%',
        activeIcon: <CgCornerDoubleRightDown style={{ fontSize: 16 }}/>
    }, {
        value: '0% 50%',
        activeIcon: (
            <div className='position-left'>
                <div><CgArrowUp/><BsDot/><CgArrowDown/></div>
                <CgArrowRight/>
            </div>
        )
    }, {
        value: '50% 50%',
        activeIcon: <BsArrowsMove style={{ fontSize: 16 }}/>
    }, {
        value: '100% 50%',
        activeIcon: (
            <div className='position-right'>
                <CgArrowLeft/>
                <div><CgArrowUp/><BsDot/><CgArrowDown/></div>
            </div>
        )
    }, {
        value: '0% 100%',
        activeIcon: <CgCornerDoubleDownRight style={{ fontSize: 16 }}/>
    }, {
        value: '50% 100%',
        activeIcon: (
            <div className='position-bottom'>
                <CgArrowUp/>
                <div><CgArrowLeft/><BsDot/><CgArrowRight/></div>
            </div>
        )
    }, {
        value: '100% 100%',
        activeIcon: <CgCornerDoubleDownLeft style={{ fontSize: 16 }}/>
    }]

    function renderPositionUnitSelect (side) {

        return (
            <Select value={side === 'left' ? position.left.unit : position.top.unit} size={'mini'} arrowIcon={null} className="unit-select" 
                getPopupContainer={node => node} triggerProps={{ autoAlignPopupWidth: false }} 
                onChange={unit => {
                    setPosition({
                        ...position,
                        [side]: {
                            ...position[side],
                            unit
                        }
                    })

                    if(side === 'left') {
                        onChange(`${position.left.value}${unit} ${position.top.value}${position.top.unit}`)
                    } else {
                        onChange(`${position.left.value}${position.left.unit} ${position.top.value}${unit}`)
                    }
                   
                }} 
            >
                {unitsArr.map(p => (
                    <Option key={p} value={p} className='unit-option'>
                        {p.toUpperCase()}
                    </Option>
                ))}
            </Select>
        )
    }

    function inputPosition (side, value) {
        setPosition({
            ...position,
            [side]: {
                ...position[side],
                value
            }
        })

        const valueRegx = /(\d+(?:\.\d+)?)/

        if(value && value.match(valueRegx)) {
            if(side === 'left') {
                onChange(`${value}${position.left.unit} ${position.top.value}${position.top.unit}`)
            } else {
                onChange(`${position.left.value}${position.left.unit} ${value}${position.top.unit}`)
            }
        }
       
    }
    
    return (
        <div className='position-edit-area'>
            <div className='position-edit-dot'>
                {
                    positionDot.map(p => {

                        const dotRegx = /(\d+)% (\d+)%/

                        function isActive () {
                            if(attribute) {
                                const matchResult = attribute.match(positionRegx)
                                if(matchResult) {
                                    const currentLeft = (p.value.match(dotRegx))[1]
                                    const currentTop = (p.value.match(dotRegx))[2]
                                    if(parseFloat(matchResult[1]) === 0) {
                                        if(parseFloat(matchResult[3]) === 0) {
                                            return currentLeft === '0' && currentTop === '0'
                                        } else if(parseFloat(matchResult[3]) <= 50) {
                                            return currentLeft === '0' && currentTop === '50'
                                        } else {
                                            return currentLeft === '0' && currentTop === '100'
                                        }
                                    } else if (parseFloat(matchResult[1]) <= 50) {
                                        if(parseFloat(matchResult[3]) === 0) {
                                            return currentLeft === '50' && currentTop === '0'
                                        } else if(parseFloat(matchResult[3]) <= 50) {
                                            return currentLeft === '50' && currentTop === '50'
                                        } else {
                                            return currentLeft === '50' && currentTop === '100'
                                        }
                                    } else {
                                        if(parseFloat(matchResult[3]) === 0) {
                                            return currentLeft === '100' && currentTop === '0'
                                        } else if(parseFloat(matchResult[3]) <= 50) {
                                            return currentLeft === '100' && currentTop === '50'
                                        } else {
                                            return currentLeft === '100' && currentTop === '100'
                                        }
                                    }
                                } else {
                                    return false
                                }
                            } else {
                                return p.value === "50% 50%"
                            }
                            
                        }

                        return (
                            <div key={p.value} id={p.value} className='single-dot' onClick={e => {
                                e.stopPropagation()
                                onChange(p.value)
                            }}>
                                { isActive() ? p.activeIcon : <BsDot style={{ fontSize: 16 }}/> }
                            </div>
                        )
                    })
                }
            </div>
            <div className='position-input'>
                <div>
                    <Input value={position.left.value} size="mini" suffix={renderPositionUnitSelect('left')} 
                        onChange={value => inputPosition('left', value)} style={{ width: '3rem' }}
                    />
                    <div className='label'>Left</div>
                </div>
                <div>
                    <Input value={position.top.value} size="mini" suffix={renderPositionUnitSelect('top')} 
                        onChange={value => inputPosition('top', value)} style={{ width: '3rem'}}
                    />
                    <div className='label'>Top</div>
                </div>
            </div>
        </div>
    )
}
