import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'


import AdminActions from 'Client/js/ui/actions'

import AdminExt from 'bwax-ext/AdminExt'

import { routeDef } from 'Client/js/adminApp/adminRouteDef'
import DefaultPage from './DefaultPage'

//// add the 
const extensionRoutes = Object.keys(AdminExt).reduce((acc, key) => {
    const { routes = [], menu = [] } = AdminExt[key]

    let definedInRoutes = routes.map(r => {
        return {
            ...r, /// { path, comp }
            extKey: key,
        }
    })
    
    let definedInMenu = menu && menu.items ? menu.items.map( it => {
        return {
            path: it.path,
            comp: it.comp,
            extKey: key
        }
    }) : []

    return [ ...acc, ...definedInRoutes, ...definedInMenu ]

}, [])


function AdminAppRoutesInternal(outerProps) {
    const { location, menuGroups, ...remaining } = outerProps

    // 在所有的 path 前面加上  /app/:appcode     
    // 为了兼容之前的版本， 没有 /app 前缀的路径也默认被识别为 /app/default，在最后一个通配的 route 里面处理
    return (
        <Switch location={location}>
            {
                extensionRoutes.map(r => {
                    const { path, comp, extKey } = r;
                    // 是否应该允许绕开 /ext/:extKey/ ？
                    const extPath = path.startsWith("/") ? path : `/ext/${extKey}/${path}`
                    const appPath = "/app/:appcode" + extPath 

                    return (
                        <Route path={appPath} key={appPath} render={props => {
                            const { history, match } = props

                            const toPassOn = {
                                ...props, // simply passed on all the props
                                ...remaining,
                                history,
                                queryTarget: 'data',
                                customActions: AdminActions,
                                match_: match
                            }

                            const C = comp
                            return <C {...toPassOn }/>

                        }} />
                    )
                })
            }
            {
                Object.keys(routeDef).map(originalPath => {
                    const path = "/app/:appcode" + originalPath;
                    const { Comp } = routeDef[originalPath]
                    return (
                        <Route path={path} key={path} render={props => {
                            const { history, match } = props
                            return (
                                <Comp
                                    location={location}
                                    {
                                    ...{
                                        ...props,
                                        ...remaining,
                                        history,
                                        queryTarget: 'data',
                                        customActions: AdminActions,
                                        match_: match
                                    }
                                    }
                                />
                            )
                        }} />
                    )
                })
            }

            <Route render={props => {            
                return (<DefaultPage {...props} menuGroups={menuGroups} />)
            }} />            
        </Switch>
    )
}


export default function AdminAppRoutes({ location, additional, ...remaining }) {

    const { menuGroups } = additional
    const content = React.useMemo(() => {
        return (
            <AdminAppRoutesInternal {
                ...{
                    location,
                    additional,
                    menuGroups,
                    ...remaining
                }
            }></AdminAppRoutesInternal>
        )
    }, [location, menuGroups ])

    return content
}