

import DOCImg from './images/doc.png'
import PDFImg from './images/pdf.png'
import PPTImg from './images/ppt.png'
import XLSImg from './images/xls.png'
import DefaultImg from './images/doc_default.png'

export const documentIcons = {
    'doc': DOCImg,
    'docx': DOCImg,
    'pdf': PDFImg,
    'ppt': PPTImg,
    'pptx': PPTImg,
    'xls': XLSImg,
    'xlsx': XLSImg,
    'unknown': DefaultImg,
  }
  
  export const commonMimeTypeMap = {
    'application/pdf': 'pdf',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  }
  
  export function downloadDocument (productDocument) {
    var link = document.createElement('a')
    link.href = productDocument.document.downloadUrl
    link.download = productDocument.title
    link.dispatchEvent(new MouseEvent('click'))
  }
  