

import React, { useRef, useState } from 'react'

import { BiCodeAlt } from 'react-icons/bi';

import { Modal } from '@arco-design/web-react';

import ExprCodeEditor from 'Client/js/codeEditor/ExprCodeEditor';

import ExprValueSelect from './ExprValueSelect';

import './ExpressionInput.less'

export default function ExpressionInput({ expectedType, typingEnv, value, onChange, onError, bindCodeTextSetter }) {

    const setCodeTextRef = useRef();

    return (
        <div className={"lc-expression-input"}>
            <div>
                <ExprCodeEditor {...{
                    typingEnv,
                    expectedType,
                    sourceCode: value,
                    onChange, onError,
                    bindSetter: setCodeText => {
                        setCodeTextRef.current = setCodeText;
                        bindCodeTextSetter(setCodeText);
                    }
                }} />
            </div>
            <div className="tool-panel">
                <ExprValueSelect {...{ 
                    typingEnv, 
                    expectedType,
                    onSelect: exprCode => {
                        if(setCodeTextRef.current && exprCode) {
                            setCodeTextRef.current(value ? value + " " + exprCode : exprCode)
                        }
                } }} />
            </div>
        </div>
    )
}


export function ExpressionInputIcon({ expectedType, typingEnv, value = "", onChange, className }) {

    const [visible, setVisible] = useState(false);

    const [ editing, setEditing ] = useState(value);
    const [ error, setError ] = useState();

    const setCodeTextRef = useRef();

    if(!typingEnv) {
        return null
    }

    function renderModal() {
        return (
            <Modal
                title='表达式输入'
                visible={visible}
                className="lc-expression-input-modal"
                okButtonProps={{
                    disabled: !!error || (editing === undefined) || (editing === value)
                }}
                onOk={() => {
                    onChange(editing);
                    setVisible(false)
                }}
                onCancel={() => {
                    // rollback
                    setEditing(value);
                    if(setCodeTextRef.current) {
                        setCodeTextRef.current(value)
                    }

                    setVisible(false)
                }}
            >
                <ExpressionInput {...{
                    typingEnv, value: editing, 
                    onChange: (_, exprCode) => {
                        console.log(">>> Changed", exprCode);
                        setEditing(exprCode);
                        setError();
                    },
                    onError: err => {
                        console.log(">>> er", err);
                        setError(err);
                    },
                    expectedType,

                    bindCodeTextSetter: setCodeText => setCodeTextRef.current = setCodeText,
                }}/>
            </Modal>
        )
    }



    return (
        <div className={"lc-expression-icon" + (className ? " " + className : "") }>
            <BiCodeAlt onClick={_ => setVisible(true)} />
            {renderModal()}
        </div>
    )
}