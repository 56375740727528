

import React from 'react';

export function getItemEntityName(itemType) {
    const names = {
        "page": "页面",
        "page-component": "页面组件",
        "page-fragment": "页面片段",

        "page-redirect": "页面跳转",

        "admin-page": "管理页面",

        "entity": "数据实体",
        "extending-entity": "数据实体",

        "entity-list-page": "数据实体",
        "entity-detail-page": "数据实体",

        "field": "实体字段",
        "virtual-field": "虚拟字段",
        "data-interface": "数据接口",
        "backlink-field": "反向关联字段",

        "event-handler": "事件处理",
        "scheduled-event-handler": "定时事件处理", 

        "general-setting": "通用设定",

        "dependency": "应用依赖",

    };
    return names[itemType]
}

const deletionPrompts = {
    "dependency": item => {
        return <span>你确定要移除对应用“<b>{item.getName()}</b>”的依赖吗？（移除后可能会出现部分不兼容的情况）</span>
    }
}


///
export default function getItemHandler(itemType, facade, domainSettings = {}) {

    function buildHandler(entityName, givenFieldNamesToRead, givenFieldNamesToWrite) {

        const entity = facade.entities.find(e => e.name == entityName);

        const allFields = [...entity.fields, ...entity.virtualFields];

        const fieldNamesToRead = givenFieldNamesToRead || (allFields.map(f => {
            return f.name
        }).filter(name => {
            return ["创建者", "创建时间", "修改时间", "修改者"].indexOf(name) === -1
        }));

        const fieldNamesToWrite = (givenFieldNamesToWrite || fieldNamesToRead).filter(fieldName => {
            const field = allFields.find(f => f.name === fieldName);
            if (field) {
                return field.updatable
            } else {
                // console.warn("Field is not found", fieldName, entityName);
                return false
            }
        });

        async function loadValue(id) {
            // do standard query
            const queryObj = {
                entityName,
                condition: [{ field: "id", op: "eq", value: id }],
                fieldPaths: fieldNamesToRead,
            };
            const [result, error] = await facade.findOne(queryObj, { forceRefreshing: true, processSelectValue: false });
            return [result, error]
        }

        // 
        async function updateValue(id, values) {
            const formData = Object.keys(values).reduce((acc, key) => {
                if (fieldNamesToWrite.indexOf(key) !== -1) {
                    return {
                        ...acc,
                        [key]: values[key],
                    };
                } else {
                    return acc;
                }
            }, {});

            const [result, error] = await facade.update({
                entityName,
                id,
                formData,
                fieldPaths: fieldNamesToRead,
            }, { processSelectValue: false })

            return [ result, error ]
        }

        async function deleteValue (id) {
            const [result, error] = await facade.delete({
                entityName,
                id,
            });
            return [result, error];
        }


        return {
            loadValue, updateValue, deleteValue,
            fieldNamesToRead, fieldNamesToWrite, entity,

            deletionPrompt: deletionPrompts[itemType]
        }
    }

    const specifiedReadAndWriteFieldNames = {
        "page": [["名称", "描述", "代码", "URL路径", "限定域名", "标签", "多租户"]],
        "virtual-field": [["名称", "描述", "代码", "字段类型", "字段选项", "必填", "多值", "缓存", "显示权重", "依赖字段", "内置", "数据实体.名称"]],
        "event-handler": [
            ["名称", "描述", "代码", "实体名", "事件类型", "监听字段", "标签", "暂停", "多租户"],
            ["名称", "描述", "代码", "监听字段", "标签", "暂停"]
        ],
        "data-interface": [["名称", "描述", "代码", "类型", "关联实体.名称",]],
        "entity": [["名称", "KEY", "描述", "帮助", "显示权重", "标签", "公开", "表选项", "外部数据源", "多租户"]],
        "entity-list-page": [["名称", "列表页设置", "多租户"]],
        "entity-detail-page": [["名称", "详情页设置", "多租户"]],
        // "dependency"
    }

    const entityName = getItemEntityName(itemType);
    if(entityName) {
        const [ fieldNamesToRead, fieldNamesToWrite ] = specifiedReadAndWriteFieldNames[itemType] || [];

        // filter out “多租户” if domain doesn't support multitenancy
        function filterMultitenant(fields) {
            if(domainSettings.multitenancy) {
                return fields
            } else {
                return fields && fields.filter(f => f !== "多租户")
            }
        }

        return buildHandler(entityName, filterMultitenant(fieldNamesToRead), filterMultitenant(fieldNamesToWrite));
    } else {
        return {}
    }

}
