import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'


import './tailwind.css';


//styles
// import 'rsuite/dist/rsuite.min.css';
import '../../node_modules/rsuite/styles/index.less';
import '../styles/main.less'



import '../../lib/bwax-ui/baseStyle.less'

//

import { Switch, Route } from 'react-router'

import { notification } from 'antd'

import DesignApp from './designApp/DesignApp'
import AdminApp from './adminApp/AdminApp'

import StudioApp from './studioApp/StudioApp';

import Login from './pages/login/Login'

import NoPermission from './NoPermission'
// import WidgetsPage from 'Client/js/widgetDemos/WidgetsPage'


import Loading from './components/Loading';

import Widgets from "bwax-ui/widgets"
import AdminWidgets from 'Client/js/ui/widgets'

import WidgetFactory from 'bwax-ui/re/legacy/WidgetFactory.bs';

import AdminExt from 'bwax-ext/AdminExt'

import addPrefix from 'bwax-ui/addPrefix'


// import ReactWindowTest from 'Client/js/newTable/ReactWindowTest'

import TestUIKit from "bwax-ui/tests/TestUIKit";

import TestClient from 'Client/js/TestClient';
import PreviewContainer from 'Client/js/designApp/PreviewContainer';


import TestEditor from './TestEditor';
import TestEditorAI from './TestEditorAI'

import TestLanguage from './TestLanguage';
import TestCode from './testPage/TestCode';

import TestTypewriter from './testPage/TestTypewriter';
import TestPrettyPrint from './testPage/TestPrettyPrint';

// const PDFReader = loadable(() => import ('bwax-ui/auxiliary/pdfReader/PDFReader'))

// import PDFReader from 'bwax-ui/lazy/PDFReader';


import RichTextEditorTest from 'bwax-ui/auxiliary/richtext/RichTextEditorTest'
// import PDFReaderTest from 'bwax-ui/auxiliary/pdfReader/PDFReaderTest'
import PDFReader from 'Client/js/components/PDFReader'


const extWidgets = Object.keys(AdminExt).reduce((acc, extKey) => {
    if (AdminExt[extKey].widgets) {

        return {
            ...acc,
            ...addPrefix(`admin::${extKey}`, AdminExt[extKey].widgets)
        }
    } else {
        return acc
    }
}, {})


const allWidgets = {
    ...Widgets,
    ...AdminWidgets,
    ...extWidgets
}

const widgetFactory = {
    produceWidget: WidgetFactory(allWidgets),
    allWidgets
}

import useHistoryStack from 'Client/js/hooks/useHistoryStack'
import { HistoryStackProvider } from './HistoryStackContext'
import TestIcons from './TestIcons';
import TestUI from 'bwax-ui/tests/TestUI';

import TestDnd from 'bwax-ui/tests/TestDnd';


function isBrowserGoogleChrome() {

    if (typeof (window) === 'undefined') {
        return false
    }

    const isChromium = window.chrome
    const winNav = window.navigator
    const vendorName = winNav.vendor
    const isOpera = typeof window.opr !== "undefined"
    const isIEedge = winNav.userAgent.indexOf("Edge") > -1

    return typeof isChromium !== "undefined"
        && isChromium !== null &&
        vendorName === "Google Inc." &&
        isOpera === false && isIEedge === false
}

function ReplaceTo({to, history}) {
    useEffect(() => {
        history.replace(to);
    }, [ to ]);
    return null;
}

function ClientApp(props) {

    const { history } = props;
    const { historyStack } = useHistoryStack(history)

    useEffect(() => {
        if (typeof (window) !== 'undefined' && !isBrowserGoogleChrome()) {
            notification.open({
                message: '请使用谷歌浏览器',
                description: '管理后台只在谷歌浏览器下测试过，请使用谷歌浏览器获得最好的体验。',
                duration: null
            })
        }
    }, [])



    const routeTo = (path, { modal = true } = {}) => {
        if (path.startsWith("http")) {
            window.location.href = path;
        } else {
            history.push(path, { modal })
        }
    }

    return (
        <HistoryStackProvider historyStack={historyStack}>
            <Switch>
                <Route path="/test-client" component={TestClient} />
                <Route path="/test-editor" component={TestEditor} />
                <Route path="/test-editor-ai" component={TestEditorAI} />
                <Route path="/test-style-ui" component={TestUI} />

                {/* <Route path="/test-style-ui" component={TestUI} /> */}

                <Route path="/test-dnd" component={TestDnd} />
                {/* 下面这两个有点重复了 */}
                <Route path="/test-language" component={TestLanguage} />
                <Route path="/test-code" component={TestCode} />

                <Route path="/test-ui-kit" component={TestUIKit} />

                <Route path="/test-typewriter" component={TestTypewriter} />
                <Route path="/test-pretty-print" component={TestPrettyPrint} />

                <Route path="/preview" component={PreviewContainer} />
                <Route path="/icons" component={TestIcons} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/no-permission" component={NoPermission} />
                <Route exact path="/pdf/reader" component={PDFReader} />
                <Route exac path="/editor/test" component={RichTextEditorTest} />
                <Route path="/design/:appcode" render={props => {
                    return (<DesignApp {
                        ...{
                            ...props,
                            widgetFactory,
                            Loading,
                            routeTo,
                        }
                    } />)
                }} />
                <Route path="/design" render={props => {
                    const { history } = props;
                    return <ReplaceTo to="/design/default" history={history} />;
                }}
                />
                <Route path="/studio/:appcode" render={props => {
                    return (<StudioApp {
                        ...{
                            ...props,
                            // widgetFactory,
                            Loading,
                            routeTo,
                        }
                    } />)
                }} />
                <Route path="/studio" render={props => {
                    const { history } = props;
                    return <ReplaceTo to="/studio/default" history={history} />;
                }}
                />                
                <Route path="/app/:appcode" render={props => {
                    // 为了在 match 里获取 app code
                    return (<AdminApp {
                        ...{
                            ...props,
                            widgetFactory,
                            Loading,
                            routeTo,
                        }
                    } />)
                }} />   


                <Route path="/app" render={props => {
                    const { history } = props;
                    return <ReplaceTo to="/app/default" history={history} />;
                }}
                />

                <Route path="/" render={props => {
                    // 为了支持旧版本的 URL
                    // return (<AdminApp {
                    //     ...{
                    //         ...props,
                    //         widgetFactory,
                    //         Loading,
                    //         routeTo,
                    //     }
                    // } />)
                    const { history } = props;
                    return <ReplaceTo to="/app/default" history={history} />;

                }} />
            </Switch>
        </HistoryStackProvider>
    )
}

export default withRouter(ClientApp)
