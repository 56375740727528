

import React from 'react';

const QueryContainer = require("bwax-ui/re/legacy/QueryContainer.bs").make;


export default function DefContainer (props) {
    const { params, ...env } = props;

    const { builder, ...otherParams } = params;
    const { allEntities, allDataTypes } = env;

    const def = builder(otherParams, { allEntities, allDataTypes });

    return <QueryContainer def={def} baseData={{}} {...env} />

}