
import React from 'react'

import {EditorBlock, SelectionState, EditorState} from 'draft-js'

import {identity, pickBy} from 'bwax/list'

import {
  DEFAULT_BLOCK_HTML,
  ALLIGNMENT_SUPPORT_TYPES,
  SUPPORT_STYLES,
  shouldStyleInclude
} from './blockAlignmentHelpers'



class Block extends React.Component {
  render() {
    const {blockProps} = this.props
    return (
      <div className="editor-block" style={blockProps.style}>
        <EditorBlock {...this.props} />
      </div>
    )
  }
}

export default (config = {}) => {

  return {
    blockRendererFn: (block, { getEditorState, setEditorState }) => {
      if (ALLIGNMENT_SUPPORT_TYPES[block.getType()]) {

        const blockData = block.getData().toJS()
        const style = pickBy(blockData, shouldStyleInclude)

        return {
          component: Block,
          editable: true,
          props: {
            style
          }
        }
      } else {
        return null
      }
    }
  };
};
