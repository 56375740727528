// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryContainer$BwaxAdmin = require("../../../../lib/bwax-ui/re/legacy/QueryContainer.bs.js");
var AdminDisplayOptions$BwaxAdmin = require("../../AdminDisplayOptions.bs.js");

function QC_AdminDisplayPage(Props) {
  var params = Props.params;
  var extensions = Props.extensions;
  var theme = Props.theme;
  var loadingComp = Props.loadingComp;
  var toast = Props.toast;
  var $$location = Props.location;
  var history = Props.history;
  var routeTo = Props.routeTo;
  var match__ = Props.match_;
  var queryTarget = Props.queryTarget;
  var widgetFactory = Props.widgetFactory;
  var customActions = Props.customActions;
  var additional = Props.additional;
  var originalBaseData = params.baseData;
  var config = params.config;
  var entityName = params.entityName;
  var maybeId = params.maybeId;
  var name = params.name;
  var tabID = maybeId !== undefined ? "record-" + (String(entityName) + ("-" + (String(name) + ""))) : "list-" + (String(entityName) + ("-" + (String(name) + "")));
  var baseData = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "tabID",
            tabID
          ],
          Curry._1(Plate$BwaxAdmin.List.from_array, Js_dict.entries(originalBaseData))
        ]));
  var maybeTabPage = AdminDisplayOptions$BwaxAdmin.TabPage.decode(config);
  var targetEntityName = Plate$BwaxAdmin.$$Option.with_default(entityName, Plate$BwaxAdmin.$$Option.and_then((function (t) {
              switch (t.tag | 0) {
                case /* RecordList */0 :
                    return t[0];
                case /* RecordEdit */1 :
                    return t[1];
                case /* AnyWidget */2 :
                    return ;
                
              }
            }), maybeTabPage));
  var facade = additional.facade;
  var match = React.useState((function () {
          var entityArr = Curry._1(Plate$BwaxAdmin.List.from_array, facade.entities);
          if (Plate$BwaxAdmin.List.some((function (e) {
                    return e.name === targetEntityName;
                  }), entityArr)) {
            return Plate$BwaxAdmin.List.some((function (e) {
                          return e.name === entityName;
                        }), entityArr);
          } else {
            return false;
          }
        }));
  var setPrepared = match[1];
  React.useEffect((function () {
          if (!Plate$BwaxAdmin.List.some((function (e) {
                    return e.name === targetEntityName;
                  }), Curry._1(Plate$BwaxAdmin.List.from_array, facade.entities))) {
            Curry._1(setPrepared, (function (param) {
                    return false;
                  }));
            facade.prepare(/* array */[
                    targetEntityName,
                    entityName
                  ]).then((function (param) {
                    Curry._1(setPrepared, (function (param) {
                            return true;
                          }));
                    return Promise.resolve(/* () */0);
                  }));
          }
          return ;
        }), /* tuple */[
        entityName,
        targetEntityName
      ]);
  var allEntities = facade.entities;
  var allDataTypes = facade.dataTypes;
  if (match[0]) {
    if (maybeTabPage !== undefined) {
      var tabPage = maybeTabPage;
      var maybeDef = AdminDisplayOptions$BwaxAdmin.TabPage.buildDef(maybeId, entityName, tabPage, allEntities, allDataTypes, queryTarget);
      if (maybeDef !== undefined) {
        return React.createElement(QueryContainer$BwaxAdmin.make, {
                    def: Caml_option.valFromOption(maybeDef),
                    baseData: baseData,
                    allDataTypes: allDataTypes,
                    allEntities: allEntities,
                    extensions: extensions,
                    loadingComp: loadingComp,
                    routeTo: routeTo,
                    history: history,
                    location: $$location,
                    match_: match__,
                    theme: theme,
                    toast: toast,
                    widgetFactory: widgetFactory,
                    queryTarget: queryTarget,
                    customActions: customActions,
                    additional: additional
                  });
      } else {
        console.log("Error occurerd in building tab def from", config);
        return "Error occurred in building tab def";
      }
    } else {
      console.log("Incorrect tab page config", config);
      return "Incorrect tab page config";
    }
  } else {
    return React.createElement(loadingComp, theme);
  }
}

var make = QC_AdminDisplayPage;

exports.make = make;
/* react Not a pure module */
