
import './EntityDataPage.less';

import React from 'react';

import useDataLoader from 'bwax-ui/legacy/store/useDataLoader'
import { runDataQuery } from 'bwax/query/runClientQuery';

import EntityDataTabs from './EntityDataTabs';

import RecordDataPage from './RecordDataPage';

const EntityRecordUtilTabs = require("Client/re/pages/EntityRecordUtilTabs.bs").make;

const AdminDiplayOptions = require("Client/re/AdminDisplayOptions.bs");


export default function EntityDataPage(props) {

    const { id, entity, location, currentUser, loadingComp: Loading, facade } = props;

    // 先尝试 query listPage 和 detialPage
    const { listPage } = useRecordListPage(entity.name);
    const { detailPage } = useRecordDetailPage(entity.name);
    // 
    if (listPage === undefined || detailPage === undefined) {
        return <Loading />
    }

    if (id) {

        const isPlain = currentUser && currentUser.systemRoles.indexOf("Admin") !== -1 && location.search.indexOf("__be_plain__") !== -1;

        if (detailPage && !isPlain) {
            return (
                <RecordDataPage
                    {...props}
                    page={detailPage}
                    pageType="detail"
                />
            )
        }

        // show the detail tabs
        const baseData = {
            id,
            "当前时间": currentUser
        };
        const ado = entity.adminDisplayOptions;
        const tabs = ado && ado.detailTabs ? ado.detailTabs : AdminDiplayOptions.TabPage.defaultDetailTabJsons;

        const tabCount = Object.keys(tabs).length;
        return (
            <div className="entity-data-page--record-details">
                <div className="data-tabs">
                    <EntityDataTabs tabs={tabs} {...{
                        allEntities: facade.entities,
                        allDataTypes: facade.dataTypes,
                        ...props
                    }} />
                </div>
                <div className={"util-tabs" + (tabCount == 0 ? " no-tab" : "")}>
                    <EntityRecordUtilTabs
                        baseData={baseData}
                        entityKey={entity.key}
                        recordId={id}
                        {...{
                            allEntities: facade.entities,
                            allDataTypes: facade.dataTypes,
                            ...props
                        }}
                    />
                </div>
            </div>
        )


    } else {

        const isPlain = currentUser && currentUser.systemRoles.indexOf("Admin") !== -1 && location.search.indexOf("__be_plain__") !== -1;

        if (listPage && !isPlain) {
            return (
                <RecordDataPage
                    {...props}
                    page={listPage}
                    pageType="list"
                />
            )

        }

        const ado = isPlain ? null : entity.adminDisplayOptions
        const tabs = ado && ado.listTabs ? ado.listTabs : AdminDiplayOptions.TabPage.defaultListTabJsons;
        return (
            <EntityDataTabs
                tabs={tabs}
                {...{
                    allEntities: facade.entities,
                    allDataTypes: facade.dataTypes,
                    ...props
                }} />
        )
    }
}

// 
function loadRecordDetailPage(entityName) {
    return async env => {

        let queryText = `query { definition { detailPageSetting (entity: "${entityName}") { compiled } } }`

        const result = await runDataQuery(env)(queryText)()

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.definition ? data.definition.detailPageSetting : null
        }
    }
}

function loadRecordListPage(entityName) {
    return async env => {
        const queryText = `query { definition { listPageSetting (entity: "${entityName}") { compiled } } }`
        const result = await runDataQuery(env)(queryText)()

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.definition ? data.definition.listPageSetting : null
        }
    }
}


export function useRecordListPage(entityName) {

    const key = 'definitions::RecordListPage::' + entityName;
    const { getData, forceLoad } = useDataLoader({ [key]: loadRecordListPage(entityName) })
    const [listPage] = getData(key)

    async function forceReload() {
        let dataDict = await forceLoad({ [key]: loadRecordListPage(entityName) })
        return dataDict[key]
    }

    return { listPage, forceReload }

};

export function useRecordDetailPage(entityName) {

    const key = 'definitions::RecordDetailPage::' + entityName;
    const { getData, forceLoad } = useDataLoader({ [key]: loadRecordDetailPage(entityName) })
    const [detailPage] = getData(key)

    async function forceReload() {
        let dataDict = await forceLoad({ [key]: loadRecordDetailPage(entityName) })
        return dataDict[key]
    }

    return { detailPage, forceReload }

};

