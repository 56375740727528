// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BaseUI$BwaxAdmin = require("./BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Select(Props) {
  var rules = Props.rules;
  var options = Props.options;
  var selected = Props.selected;
  var onChange = Props.onChange;
  var disabled = Props.disabled;
  var placeholder = Props.placeholder;
  var control = Props.control;
  var theme = Props.theme;
  var pc = Props.pc;
  var prs = Props.prs;
  var passedInOptionFocusedRules = rules[/* optionFocusedRules */7];
  var passedInSingleValueRules = rules[/* singleValueRules */5];
  var passedInOptionRules = rules[/* optionRules */4];
  var defaultOptionRules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `padding */Caml_chrome_debugger.polyVar("padding", [
          -797091375,
          /* `rem */Caml_chrome_debugger.polyVar("rem", [
              5691738,
              0.5
            ])
        ]),
      /* [] */0
    ]);
  var containerRules = Belt_List.concat(/* [] */0, rules[/* containerRules */0]);
  var controlRules = Belt_List.concat(Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                  297481091,
                  /* `rem */Caml_chrome_debugger.polyVar("rem", [
                      5691738,
                      0.5
                    ])
                ]),
              /* [] */0
            ]), defaultOptionRules), rules[/* controlRules */1]);
  var menuRules = Belt_List.concat(/* [] */0, rules[/* menuRules */2]);
  var optionListRules = Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
              222776981,
              /* white */-910341463
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* `borderWidth */Caml_chrome_debugger.polyVar("borderWidth", [
                  -690257542,
                  /* `px */Caml_chrome_debugger.polyVar("px", [
                      25096,
                      1
                    ])
                ]),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
                      -690668329,
                      /* N20 */3890060
                    ]),
                  /* [] */0
                ])
            ])
        ]), rules[/* optionListRules */3]);
  var optionRules = function (label, value, isSelected, isDisabled) {
    return Belt_List.concat(defaultOptionRules, Curry._4(passedInOptionRules, label, value, isSelected, isDisabled));
  };
  var singleValueRules = function (label, value) {
    return Belt_List.concat(/* [] */0, Curry._2(passedInSingleValueRules, label, value));
  };
  var controlFocusedRules = Belt_List.concat(/* [] */0, rules[/* controlFocusedRules */6]);
  var optionFocusedRules = function (label, value, isSelected) {
    return Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
                      222776981,
                      /* N20 */3890060
                    ]),
                  /* [] */0
                ]), Curry._3(passedInOptionFocusedRules, label, value, isSelected));
  };
  var match = React.useState((function () {
          return false;
        }));
  var setShowOptionList = match[1];
  var showOptionList = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setSelectIsFocus = match$1[1];
  var match$2;
  if (selected !== undefined) {
    var s = Caml_option.valFromOption(selected);
    match$2 = /* tuple */[
      s.label,
      s.value
    ];
  } else {
    match$2 = /* tuple */[
      "",
      ""
    ];
  }
  var selectedLabel = match$2[0];
  var match$3 = React.useState((function () {
          if (selected !== undefined) {
            var s = Caml_option.valFromOption(selected);
            return Belt_Option.getWithDefault(Belt_Array.getIndexBy(options, (function (option) {
                              return Caml_obj.caml_equal(option, s);
                            })), 0);
          } else {
            return 0;
          }
        }));
  var setFocusIndex = match$3[1];
  var focusIndex = match$3[0];
  var optionRefs = Belt_Array.map(options, (function (param) {
          return React.useRef(null);
        }));
  var containerRef = React.useRef(null);
  var renderMask = function (theme) {
    var toCss = function (r) {
      return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_box */3, /* column */-963948842, /* [] */0);
    };
    var maskClassName = BaseUI$BwaxAdmin.buildClassName("column ctnr", /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* `height */Caml_chrome_debugger.polyVar("height", [
                38537191,
                /* `percent */Caml_chrome_debugger.polyVar("percent", [
                    -119887163,
                    100.0
                  ])
              ]),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* `percent */Caml_chrome_debugger.polyVar("percent", [
                        -119887163,
                        100.0
                      ])
                  ]),
                /* [] */0
              ])
          ]), toCss);
    var content = React.createElement("div", {
          className: maskClassName,
          onClick: (function (param) {
              console.log("Clicked", showOptionList);
              return Curry._1(setShowOptionList, (function (prev) {
                            return !prev;
                          }));
            })
        });
    var maskRawClassName = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            Css.important(Css.position(/* fixed */10615156)),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Css.top(/* zero */-789508312),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Css.bottom(/* zero */-789508312),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Css.left(/* zero */-789508312),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Css.right(/* zero */-789508312),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Css.zIndex(1),
                                /* [] */0
                              ])
                          ])
                      ])
                  ])
              ])
          ]));
    return BaseUI$BwaxAdmin.renderWithClassName(maskRawClassName, content, /* [] */0, theme, /* column */-963948842, "no-key");
  };
  var focusContainer = function (param) {
    var contaienrDom = containerRef.current;
    if (contaienrDom == null) {
      return /* () */0;
    } else {
      return contaienrDom.focus();
    }
  };
  var onKeyDown = function (e) {
    e.stopPropagation();
    var keyCode = e.keyCode;
    if (!showOptionList && keyCode === 40 && !disabled) {
      return Curry._1(setShowOptionList, (function (prev) {
                    return !prev;
                  }));
    } else if (showOptionList) {
      if (keyCode === 38) {
        var getNextValidIndex = function (currentIndex) {
          var match = (currentIndex - 1 | 0) >= 0;
          if (match) {
            return currentIndex - 1 | 0;
          } else {
            return options.length - 1 | 0;
          }
        };
        var nextOption = Belt_Array.getExn(options, getNextValidIndex(focusIndex));
        return Curry._1(setFocusIndex, (function (param) {
                      var match = nextOption.isDisabled;
                      if (match) {
                        return getNextValidIndex(focusIndex - 1 | 0);
                      } else {
                        return getNextValidIndex(focusIndex);
                      }
                    }));
      } else if (keyCode === 40 || keyCode === 9) {
        e.preventDefault();
        var getNextValidIndex$1 = function (currentIndex) {
          return Caml_int32.mod_(currentIndex + 1 | 0, options.length);
        };
        var nextOption$1 = Belt_Array.getExn(options, getNextValidIndex$1(focusIndex));
        Curry._1(setFocusIndex, (function (param) {
                var match = nextOption$1.isDisabled;
                if (match) {
                  return getNextValidIndex$1(focusIndex + 1 | 0);
                } else {
                  return getNextValidIndex$1(focusIndex);
                }
              }));
        var match = nextOption$1.isDisabled;
        var nextValidOption = Belt_Array.getExn(optionRefs, match ? getNextValidIndex$1(focusIndex + 1 | 0) : getNextValidIndex$1(focusIndex)).current;
        if (nextValidOption == null) {
          return /* () */0;
        } else {
          return nextValidOption.focus();
        }
      } else if (keyCode === 13) {
        Curry._1(onChange, Belt_Array.get(options, focusIndex));
        Curry._1(setShowOptionList, (function (prev) {
                return !prev;
              }));
        return focusContainer(/* () */0);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  var toCss = function (r) {
    return BaseUI$BwaxAdmin.ruleToCss(r, theme, /* Item_column */2, pc, prs);
  };
  var containerClassName = BaseUI$BwaxAdmin.buildClassName("select ctnr", containerRules, toCss);
  var onFocus = function (e) {
    return Curry._1(setSelectIsFocus, (function (param) {
                  var match = !disabled;
                  if (match) {
                    return true;
                  } else {
                    return false;
                  }
                }));
  };
  var onBlur = function (e) {
    return Curry._1(setSelectIsFocus, (function (param) {
                  return false;
                }));
  };
  var placeholderRules = selected !== undefined ? /* [] */0 : /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `textColor */Caml_chrome_debugger.polyVar("textColor", [
            -481689226,
            /* lighterText */-314154736
          ]),
        /* [] */0
      ]);
  var controlEls;
  if (control !== undefined) {
    var c = Caml_option.valFromOption(control);
    controlEls = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        (function (param, param$1, param$2, param$3) {
            return c;
          }),
        /* [] */0
      ]);
  } else {
    var partial_arg = selected !== undefined ? selectedLabel : placeholder;
    var partial_arg_000 = /* `textLineClamp */Caml_chrome_debugger.polyVar("textLineClamp", [
        -958002182,
        1
      ]);
    var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000,
        placeholderRules
      ]);
    var partial_arg_000$1 = function (param, param$1, param$2, param$3) {
      return BaseUI$BwaxAdmin.text(partial_arg$1, partial_arg, param, param$1, param$2, param$3);
    };
    var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000$1,
        /* [] */0
      ]);
    var partial_arg_000$2 = /* `width */Caml_chrome_debugger.polyVar("width", [
        -899500538,
        /* fill */-1011102077
      ]);
    var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
            297481091,
            /* `rem */Caml_chrome_debugger.polyVar("rem", [
                5691738,
                0.5
              ])
          ]),
        singleValueRules(selectedLabel, match$2[1])
      ]);
    var partial_arg$3 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000$2,
        partial_arg_001
      ]);
    var partial_arg$4 = /* lighterText */-314154736;
    var partial_arg$5 = /* medium */-20425611;
    controlEls = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxAdmin.paragraph(partial_arg$3, partial_arg$2, param, param$1, param$2, param$3);
          }),
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxAdmin.icon(partial_arg$5, /* ArrowDown */535074475, partial_arg$4, param, param$1, param$2, param$3);
              }),
            /* [] */0
          ])
      ]);
  }
  var tmp;
  if (showOptionList) {
    var partial_arg_000$3 = function (theme, pc, prs, key) {
      var theme$1 = theme;
      var pc$1 = pc;
      var prs$1 = prs;
      return Belt_Array.mapWithIndex(options, (function (index, option) {
                    var match = option.isDisabled;
                    var match$1 = index === focusIndex && !option.isDisabled;
                    var rules = Belt_List.concat(Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* `width */Caml_chrome_debugger.polyVar("width", [
                                    -899500538,
                                    /* fill */-1011102077
                                  ]),
                                optionRules(option.label, option.value, Caml_obj.caml_equal(Caml_option.some(option), selected), option.isDisabled)
                              ]), match ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* notAllowed */939907157,
                                  /* [] */0
                                ]) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* pointer */-786317123,
                                  /* [] */0
                                ])), match$1 ? optionFocusedRules(option.label, option.value, Caml_obj.caml_equal(Caml_option.some(option), selected)) : /* [] */0);
                    var toCss = function (r) {
                      return BaseUI$BwaxAdmin.ruleToCss(r, theme$1, /* Item_text */5, pc$1, prs$1);
                    };
                    var optionClassName = BaseUI$BwaxAdmin.buildClassName("text", rules, toCss);
                    var onClick = function (e) {
                      e.stopPropagation();
                      var match = option.isDisabled;
                      if (match) {
                        return /* () */0;
                      } else {
                        Curry._1(setShowOptionList, (function (prev) {
                                return !prev;
                              }));
                        Curry._1(onChange, Caml_option.some(option));
                        return focusContainer(/* () */0);
                      }
                    };
                    var onMouseOver = function (param) {
                      return Curry._1(setFocusIndex, (function (param) {
                                    return index;
                                  }));
                    };
                    return React.createElement("div", {
                                key: option.label,
                                ref: Belt_Array.getExn(optionRefs, index),
                                className: optionClassName,
                                id: option.label,
                                tabIndex: index + 1 | 0,
                                onKeyDown: onKeyDown,
                                onClick: onClick,
                                onMouseOver: onMouseOver
                              }, option.label);
                  }));
    };
    var partial_arg$6 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000$3,
        /* [] */0
      ]);
    var partial_arg_000$4 = /* `width */Caml_chrome_debugger.polyVar("width", [
        -899500538,
        /* fill */-1011102077
      ]);
    var partial_arg_001$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* scrollY */819475372,
        optionListRules
      ]);
    var partial_arg$7 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000$4,
        partial_arg_001$1
      ]);
    var partial_arg_000$5 = function (param, param$1, param$2, param$3) {
      return BaseUI$BwaxAdmin.column(partial_arg$7, partial_arg$6, param, param$1, param$2, param$3);
    };
    var partial_arg$8 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000$5,
        /* [] */0
      ]);
    var partial_arg_000$6 = /* `width */Caml_chrome_debugger.polyVar("width", [
        -899500538,
        /* fill */-1011102077
      ]);
    var partial_arg$9 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        partial_arg_000$6,
        menuRules
      ]);
    tmp = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `below */Caml_chrome_debugger.polyVar("below", [
            811186865,
            (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxAdmin.column(partial_arg$9, partial_arg$8, param, param$1, param$2, param$3);
              })
          ]),
        /* [] */0
      ]);
  } else {
    tmp = /* [] */0;
  }
  return React.createElement("div", {
              ref: containerRef,
              className: containerClassName,
              tabIndex: 0,
              onKeyDown: onKeyDown,
              onFocus: onFocus,
              onBlur: onBlur
            }, BaseUI$BwaxAdmin.row(Belt_List.concat(Belt_List.concat(Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* `width */Caml_chrome_debugger.polyVar("width", [
                                    -899500538,
                                    /* fill */-1011102077
                                  ]),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* `height */Caml_chrome_debugger.polyVar("height", [
                                        38537191,
                                        /* fill */-1011102077
                                      ]),
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        /* `onClick */Caml_chrome_debugger.polyVar("onClick", [
                                            -560194903,
                                            (function (param) {
                                                if (disabled) {
                                                  return /* () */0;
                                                } else {
                                                  return Curry._1(setShowOptionList, (function (prev) {
                                                                return !prev;
                                                              }));
                                                }
                                              })
                                          ]),
                                        controlRules
                                      ])
                                  ])
                              ]), disabled ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* notAllowed */939907157,
                                  /* [] */0
                                ]) : /* [] */0), match$1[0] ? controlFocusedRules : /* [] */0), tmp), controlEls, theme, pc, prs, "select-control"), showOptionList ? renderMask(theme) : null);
}

var make = Select;

exports.make = make;
/* Css Not a pure module */
