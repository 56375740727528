// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../../../lib/bwax-ui/re/BaseUI.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Display_RolePermission(Props) {
  var params = Props.params;
  var theme = Props.theme;
  var value = Plate$BwaxAdmin.$$Option.with_default({ }, Caml_option.nullable_to_opt(params.value));
  var match = React.useState((function () {
          return ;
        }));
  var setSearchValue = match[1];
  var searchValue = match[0];
  var keyList = $$Array.to_list(Object.keys(value));
  var filterValue = Plate$BwaxAdmin.List.filter((function (key) {
            if (key.includes(Plate$BwaxAdmin.$$Option.with_default("", searchValue))) {
              return true;
            } else {
              var key$1 = key;
              var currentValue = Js_dict.get(value, key$1);
              if (currentValue !== undefined) {
                var ops = $$Array.to_list(Caml_option.valFromOption(currentValue).ops);
                return Plate$BwaxAdmin.List.some((function (op) {
                              return op.includes(Plate$BwaxAdmin.$$Option.with_default("", searchValue));
                            }), ops);
              } else {
                return false;
              }
            }
          }))(keyList);
  var onChange = function (v) {
    return Curry._1(setSearchValue, (function (param) {
                  return v;
                }));
  };
  var getPermissionStr = function (key) {
    var currentValue = Js_dict.get(value, key);
    if (currentValue !== undefined) {
      var ops = Caml_option.valFromOption(currentValue).ops;
      return ops.join(", ");
    } else {
      return "";
    }
  };
  var renderItem = Plate$BwaxAdmin.List.map((function (item) {
          var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* textBold */-1005136686,
              /* [] */0
            ]);
          var partial_arg$1 = " :: " + getPermissionStr(item);
          var partial_arg_000 = function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxAdmin.text(partial_arg, item, param, param$1, param$2, param$3);
          };
          var partial_arg_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              (function (param, param$1, param$2, param$3) {
                  return BaseUI$BwaxAdmin.text(/* [] */0, partial_arg$1, param, param$1, param$2, param$3);
                }),
              /* [] */0
            ]);
          var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              partial_arg_000,
              partial_arg_001
            ]);
          return (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.row(/* [] */0, partial_arg$2, param, param$1, param$2, param$3);
            });
        }), filterValue);
  var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `borderWidth */Caml_chrome_debugger.polyVar("borderWidth", [
          -690257542,
          /* `px */Caml_chrome_debugger.polyVar("px", [
              25096,
              1
            ])
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
              -690668329,
              /* divide */1058156409
            ]),
          /* [] */0
        ])
    ]);
  var partial_arg$1 = BaseUI$BwaxAdmin.inputText(/* record */Caml_chrome_debugger.record(["maxLength"], [20]), searchValue, onChange, "请输入查询关键字");
  return BaseUI$BwaxAdmin.column(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* fill */-1011102077
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                        297481091,
                        /* `rem */Caml_chrome_debugger.polyVar("rem", [
                            5691738,
                            0.5
                          ])
                      ]),
                    /* [] */0
                  ])
              ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                (function (param, param$1, param$2, param$3) {
                    return partial_arg$1(false, partial_arg, param, param$1, param$2, param$3);
                  }),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    (function (param, param$1, param$2, param$3) {
                        return BaseUI$BwaxAdmin.column(/* [] */0, renderItem, param, param$1, param$2, param$3);
                      }),
                    /* [] */0
                  ])
              ]), theme, /* column */-963948842, /* [] */0, "no-key");
}

var List = 0;

var $$Option = 0;

var make = Display_RolePermission;

exports.List = List;
exports.$$Option = $$Option;
exports.make = make;
/* react Not a pure module */
