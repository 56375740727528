import React, { useState } from 'react'

import { Modal } from 'antd'
import RecordColOverflow from 'Client/js/components/RecordColOverflow'

export default function JSONPreview({ params }) {
    const { value } = params    
    const [modalVisible, setModalVisible] = useState(false)
    const JSONContent = JSON.stringify(value, null, 2)
    return (
        <React.Fragment>
            <RecordColOverflow
                onClick={(e) => setModalVisible(true)} >
                <pre>
                    {JSONContent}
                </pre>
            </RecordColOverflow>
            <Modal width={900} footer={null}
                open={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
            >
                <div style={{
                    maxHeight: 600,
                    overflow: 'auto',
                    padding: "10px 0"
                }}>
                    <pre>
                        {JSONContent}
                    </pre>
                </div>
            </Modal>
        </React.Fragment>
    )
}