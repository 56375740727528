

import React from 'react'

import CodePlayground from 'Client/js/codeEditor/CodePlayground';

export default function TestCode() {
    return (
        <CodePlayground />
    )
}
