import React, { useEffect, useState } from 'react'

import './CodeOutline.less';

import { MdChevronRight, MdExpandMore } from 'react-icons/md';

import ClampedText from 'bwax-ui/components/ClampedText';
import { hashCode } from 'bwax/utils';

// handle syntax nodes
function getChildren(node) {
    let children = [];
    const firstChild = node.firstChild;
    if (firstChild) {
        children.push(firstChild);
        let nextChild = firstChild.nextSibling;
        while (nextChild) {
            children.push(nextChild);
            nextChild = nextChild.nextSibling;
        }
    }
    return children
}

function getText(node, codeText) {
    return codeText.substring(node.from, node.to);
}


function transformNode(node, depth, codeText) {

    const baseChildren = getChildren(node);

    let processed = false;
    let rawChildren = baseChildren;
    do {
        processed = false;
        rawChildren = rawChildren.flatMap(c => {
            const shouldLiftUp = [
                "LetExpr", "CaseExpr", "RecordTypeDecl",
            ]
            if (shouldLiftUp.indexOf(c.name) != -1) {
                processed = true;
                return getChildren(c);
            }
            return [c]

        }).flatMap(c => {
            if (depth > 3) {
                // 先这么测试
                return []
            }
            const shouldDrillToFirst = [
                "ImportStmt", "Def", "ConstrTypeDecl", "TypeDecl",
            ];
            if (shouldDrillToFirst.indexOf(c.name) != -1) {
                processed = true;
                return [c.firstChild];
            }
            // ingore child
            const shouldIgnore = [
                "Semicolon",
                "import", "CapName", "exposing", "(", ")", "Ellipsis", "ExposedName", "DotCapName",
                "Pattern", "Name",
                "=", "Record",
                "|", ":", ",",
                "TypeAnnot",
                "type", "alias",
                "Literal", "Tuple",
                "MulOp", "AddOp", "PowOp", "LogicOp", "CompareOp", "EqualityOp",
                "ApplyExpr", "PipeToOp", "PipeFromOp",
                "RecordGet", "RecordGetOptional", "IfExpr",
                "let", "in",
                "case", "Ref", "of", "->",
                "LineComment",
                "{", "}", "List",

                "RoRecordTypeDecl", "Constr0TypeDecl"
            ]

            if (shouldIgnore.indexOf(c.name) != -1) {
                processed = true;
                return []
            }

            return [c]
        })

    } while (processed)


    const noChildren = ["ConstrDecl", "CaseBranch"];

    const children = noChildren.indexOf(node.name) != -1 ? [] : rawChildren.map(c => transformNode(c, depth + 1, codeText));

    function getLabel() {

        const getFirstText = nodeName => {
            const nameChildren = baseChildren.find(c => c.name === nodeName);
            return getText(nameChildren, codeText)
        }

        const getFirstTextAndFollowing = (nodeName, followingName) => {
            function iter (found, remaining) {
                if(found.length > 0) {
                    // get the following name
                    if(remaining.length > 0 && remaining[0].name === followingName) {
                        const [ h, ...rest ] = remaining
                        return iter([...found, h], rest)
                    } else {
                        return found
                    }
                } else {
                    if(remaining.length > 0) {
                        const [ h, ...rest ] = remaining
                        if(h.name === nodeName) {
                            return iter ([h], rest)
                        } else {
                            return iter ([], rest)
                        }
                    } else {
                        return found
                    }
                }
            }
            const list = iter([], baseChildren);
            return list.map(n => getText(n, codeText)).join("");
        }

        const labelGetters = {
            "FunctionDef": _ => getFirstText("Name"),
            "ValueDef": _ => getFirstText("Pattern"),
            "ImportExposingAll": _ => "import " + getFirstTextAndFollowing("CapName", "DotCapName"),
            "ImportExposing": _ => "import " + getFirstText("CapName", "DotCapName"),
            "TypeDef": _ => getFirstText("CapName"),
            "TypeAliasDef": _ => getFirstText("CapName"),
            "CaseBranch": _ => "| " + getFirstText("Pattern"),
            "ConstrDecl": _ => "| " + getText(node, codeText),
            "FieldTypeDecl": _ => getFirstText("Name"),
        }
        const labelGetter = labelGetters[node.name] || (_ => "");
        return labelGetter();
    }

    return {
        type: node.name,
        label: getLabel(),
        children,
        rawNode: node,
    }
}

export default function CodeOutline({ tree, codeText, onClickAt }) {

    // transform the syntax tree to outline true
    // return { type, label, children }
    const { children } = transformNode(tree.topNode, 0, codeText)

    const [selectedNode, setSelectedNode] = useState();

    useEffect(() => {
        setSelectedNode();
    }, [ codeText ]);


    return (
        <div className="code-outline" key={hashCode(codeText)}>
            <div className="children-list">
                {
                    children.map((c, i) => {
                        return (
                            <SyntaxNode {...{
                                key: i, node: c, depth: 0, codeText, selectedNode,
                                onNodeClick: rawNode => {
                                    setSelectedNode(rawNode);
                                    if(rawNode) {
                                        onClickAt(rawNode)
                                    }                                   
                                },
                            }}></SyntaxNode>
                        )
                    })
                }
            </div>
        </div>
    )

}


function SyntaxNode({ node, depth, codeText, onNodeClick, selectedNode }) {

    const { type, label, children = [], rawNode } = node;
    const displayText = () => {
        return label || type;
    }

    const [collapsed, setCollapsed] = useState(
        // true
        depth >= 0 && children.length > 0 ? true : false
    );

    const renderOpIcon = () => {
        if (children.length !== 0) {
            return collapsed ? <MdChevronRight /> : <MdExpandMore />
        } else {
            return null
        }
    }

    const isSelected = selectedNode && selectedNode.from == rawNode.from;

    const emphasizedLabels = ["Msg", "Model", "init", "update", "ready", "dispose", "reInit", "view"];
    const isEmphasized = emphasizedLabels.indexOf(label) !== -1;

    return (
        <div className="syntax-node">
            <div className={"syntax-node-summary " + type.toLowerCase()} 
                style={{ 
                    paddingLeft: depth * 16, 
                    ...(isSelected ? { backgroundColor: "#E2F2FF" } : {}),
                    ...(isEmphasized ? { fontWeight: "bold"} : {}),
                 }}
                onDoubleClick={_ => {
                    console.log(">>> ", rawNode, getText(rawNode, codeText), "\n", JSON.stringify(toJSON(rawNode), null, 2))
                }}
                onClick={_ => {
                    if(isSelected) onNodeClick()
                    else onNodeClick(rawNode)
                }}
                >
                <div className="op-icon" onClick={e => {
                    e.stopPropagation();
                    if (children.length > 0) {
                        setCollapsed(prev => !prev);
                    }
                }}>{renderOpIcon()} </div>
                <ClampedText text={displayText()}/>
            </div>
            <div className="children-list">
                {
                    collapsed ? null : children.map((c, i) => {
                        return (
                            <SyntaxNode {...{
                                key: i, node: c, depth: depth + 1, codeText, onNodeClick, selectedNode
                            }} />
                        )
                    })
                }
            </div>
        </div>
    )

}



// helpers:
function toJSON(node) {

    const baseChildren = getChildren(node);
    const children = baseChildren.map(toJSON);

    return {
        name: node.name,
        children,
    }

}