
import React, { useEffect, useState, useContext } from 'react'
import { Button } from 'antd'

import Popconfirm from 'bwax-ui/components/Popconfirm';
import Message from 'Client/js/ui/Message';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery';

import './MpOtherReply.less'
import CommonReplyEdit from './CommonReplyEdit'

export default function MpOtherReply (props) {

    const { widgetProps, replySettingType } = props

    const { queryTarget, extensions } = widgetProps
    const isYQYLEnabled = (extensions || []).some(e => e.key == "YQYL");

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)
    const queryRunner = {
        'data': runDataQuery({tenantCode, sessionToken, sandbox}),
        'definition': runDefinitionQuery({tenantCode, sessionToken})
    }[queryTarget]

    const [ currentReplySetting, setCurrentReplySetting ] = useState({
        replies: [],
        replyType: 'ReplyAll',
        transferCustomerService: false
    })
    const [ contentType, setContentType ] = useState('message')
    const [ publishFault, setPublishFault ] = useState({})

    useEffect(() => {
        (async () => {
            if(replySettingType === "defaultReply") {
                const wxMpDefaultReplySetting = await loadDefaultReplySetting(queryRunner, isYQYLEnabled)
                const defaultReplySetting = wxMpDefaultReplySetting && wxMpDefaultReplySetting.defaultReplySetting ? wxMpDefaultReplySetting.defaultReplySetting : {}
                setCurrentReplySetting({
                    ...currentReplySetting,
                    ...defaultReplySetting
                })
                const { replies } = defaultReplySetting
                setContentType(replies && replies.filter(x => !!x).some(r => r.type === 'yqylPromotion') ? 'yqylPromotion' : 'message')
            } else {
                const wxMpSubscribeReplySetting = await loadSubscribeReplySetting(queryRunner, isYQYLEnabled)
                const subscribeReplySetting = wxMpSubscribeReplySetting && wxMpSubscribeReplySetting.subscribeReplySetting ? wxMpSubscribeReplySetting.subscribeReplySetting : {}
                setCurrentReplySetting({
                    ...currentReplySetting,
                    ...subscribeReplySetting
                })
                const { replies } = subscribeReplySetting
                setContentType(replies && replies.filter(x => !!x).some(r => r.type === 'yqylPromotion') ? 'yqylPromotion' : 'message')
            }

        })()
    }, [])

    function updateReplySetting(value) {
        setCurrentReplySetting({
            ...currentReplySetting,
            ...value
        })
    }

    function changeContentType (value) {
        setContentType(value)
        setPublishFault({
            ...publishFault,
            'replies': false
        })
    }

    function addReply (type) {
        setPublishFault({
            ...publishFault,
            'replies': false
        })
        addContent(type)
    }

    function addContent (type) {
        const replies = currentReplySetting ? currentReplySetting.replies : []
        const addContent = isYQYLEnabled ? {
            type,
            text: null,
            mpImage: null,
            mpNews: null,
            yqylPromotion: null
        } : {
            type,
            text: null,
            mpImage: null,
            mpNews: null
        }
        setCurrentReplySetting({
            ...currentReplySetting,
            replies: (replies || []).concat(addContent)
        })
    }

    function deleteReplySetting () {
        setCurrentReplySetting({
            replies: [],
            replyType: 'ReplyAll',
            transferCustomerService: false
        })
    }

    function disabled () {
        const { transferCustomerService } = currentReplySetting
        const replies = currentReplySetting ? currentReplySetting.replies : []
        return !( replies.length > 0 || transferCustomerService === true)
    }

    function save () {
        const { transferCustomerService } = currentReplySetting
        const replies = currentReplySetting ? currentReplySetting.replies : []


        if(!(replies.length > 0 || transferCustomerService === true)) {
            Message.warn('请设置回复内容或者转入客服')
            return
        } else if ((!transferCustomerService && replies.length === 0) || replies.some(r => !r[r.type])) {
            const TYPE = {
                'text': 'message',
                'mpImage': 'message',
                'mpNews': 'message',
                'yqylPromotion': 'yqylPromotion'
            }
            setContentType(
                replies.length === 0 ? contentType : 
                replies.some(r => !r[r.type]) ? TYPE[replies.find(r => !r[r.type]).type] : contentType
            )
            setPublishFault({
                ...publishFault,
                'replies': true
            })
            return
        } else {
            (async () => {
                const replies = currentReplySetting.replies ? currentReplySetting.replies : []
                const convertReplies = (replies || []).map(reply => {
                    return isYQYLEnabled ? {
                        ...reply,
                        yqylPromotion: reply.type === 'yqylPromotion' && reply.yqylPromotion ? reply.yqylPromotion.id : null
                    } : {
                        ...reply
                    }
                })
                const convertReplySetting = {
                    ...currentReplySetting,
                    replies: convertReplies
                }
                const result = replySettingType === "defaultReply" ? await saveWxMpDefaultReplySetting(queryRunner, convertReplySetting, isYQYLEnabled) : 
                    await saveWxMpSubscribeReplySetting(queryRunner, convertReplySetting, isYQYLEnabled)
    
                if(result) {
                    Message.success('保存成功')
                }
            })()
        }

    }
    
    return (
        <div className="wxmp-otherReply-container">
            <CommonReplyEdit
                updateReplySetting={value => updateReplySetting(value)}
                currentReplySetting={currentReplySetting}
                showYqyl={isYQYLEnabled}
                contentType={contentType}
                changeContentType={value => changeContentType(value)}
                setPublishFault={value => setPublishFault(value)}
                queryRunner={queryRunner}
                publishFault={publishFault}
                addReply={type => addReply(type)}
                widgetProps={widgetProps}

            />
            <div className="buttons">
                <Button type="primary" onClick={() => save()}>保存</Button>
                <Popconfirm
                    title="删除后，关注该公众号的用户将不再接收该回复，确定删除？"
                    onConfirm={() => deleteReplySetting()}
                    okText="确定"
                    cancelText="取消"
                >
                    <Button disabled={disabled()}>删除回复</Button>
                </Popconfirm>
                
            </div>
        </div>
    )
}

const FragmentReplySetting = isYQYLEnabled => `
    replyType
    transferCustomerService
    replies {
        type
        mpImage
        text
        mpNews
        ${
            isYQYLEnabled ? `
                yqylPromotion {
                    id
                }
            ` : ""
        }
    }
`

const QueryReplySetting = isYQYLEnabled =>`
    query {
        wxMpDefaultReplySetting {
            defaultReplySetting {
                ${FragmentReplySetting(isYQYLEnabled)}
            }
        }
        wxMpSubscribeReplySetting {
            subscribeReplySetting {
                ${FragmentReplySetting(isYQYLEnabled)}
            }
        }
    }
`

async function loadDefaultReplySetting(queryRunner, isYQYLEnabled) {
    const resp = await queryRunner(QueryReplySetting(isYQYLEnabled))()
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data.wxMpDefaultReplySetting
    }
}

async function loadSubscribeReplySetting(queryRunner, isYQYLEnabled) {
    const resp = await queryRunner(QueryReplySetting(isYQYLEnabled))()
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data.wxMpSubscribeReplySetting
    }
}

async function saveWxMpDefaultReplySetting(queryRunner, defaultReplySetting, isYQYLEnabled) {
    const resp = await queryRunner(MutSaveWxMpDefaultReplySetting(isYQYLEnabled))({
        input: {
            defaultReplySetting,
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data
    }
}

const MutSaveWxMpDefaultReplySetting = isYQYLEnabled => `
    mutation SaveWxMpDefaultReplySettingMutation(
        $input: SaveWxMpDefaultReplySettingInput!
    ) {
        saveWxMpDefaultReplySetting(input: $input) {
            defaultReplySetting {
                ${FragmentReplySetting(isYQYLEnabled)}
            }
        }
    }
`

async function saveWxMpSubscribeReplySetting(queryRunner, subscribeReplySetting, isYQYLEnabled) {
    const resp = await queryRunner(MutSaveWxMpSubscribeReplySetting(isYQYLEnabled))({
        input: {
            subscribeReplySetting,
            clientMutationId: `${Date.now()}`
        }
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        console.error(errors);
        return undefined
    } else {
        return data
    }
}

const MutSaveWxMpSubscribeReplySetting = isYQYLEnabled => `
    mutation SaveWxMpSubscribeReplySettingMutation(
        $input: SaveWxMpSubscribeReplySettingInput!
    ) {
        saveWxMpSubscribeReplySetting(input: $input) {
            subscribeReplySetting {
                ${FragmentReplySetting(isYQYLEnabled)}
            }
        }
    }
`
