// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Exec_helper = require("./exec_helper");
var Dict$BwaxAdmin = require("../dict.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_mod_set$BwaxAdmin = require("./mod/builtin/lang_mod_set.bs.js");
var Lang_mod_char$BwaxAdmin = require("./mod/builtin/lang_mod_char.bs.js");
var Lang_mod_dict$BwaxAdmin = require("./mod/builtin/lang_mod_dict.bs.js");
var Lang_mod_file$BwaxAdmin = require("./mod/builtin/lang_mod_file.bs.js");
var Lang_mod_json$BwaxAdmin = require("./mod/builtin/lang_mod_json.bs.js");
var Lang_mod_list$BwaxAdmin = require("./mod/builtin/lang_mod_list.bs.js");
var Command_engine$BwaxAdmin = require("./command_engine.bs.js");
var Lang_mod_array$BwaxAdmin = require("./mod/builtin/lang_mod_array.bs.js");
var Lang_mod_maybe$BwaxAdmin = require("./mod/builtin/lang_mod_maybe.bs.js");
var Runtime_common$BwaxAdmin = require("./mod/runtime_common.bs.js");
var Lang_mod_basics$BwaxAdmin = require("./mod/builtin/lang_mod_basics.bs.js");
var Lang_mod_crypto$BwaxAdmin = require("./mod/builtin/lang_mod_crypto.bs.js");
var Lang_mod_number$BwaxAdmin = require("./mod/builtin/lang_mod_number.bs.js");
var Lang_mod_preset$BwaxAdmin = require("./mod/builtin/lang_mod_preset.bs.js");
var Lang_mod_string$BwaxAdmin = require("./mod/builtin/lang_mod_string.bs.js");
var Runtime_mod_cmd$BwaxAdmin = require("./mod/runtime/runtime_mod_cmd.bs.js");
var Runtime_mod_cmdm$BwaxAdmin = require("./mod/runtime/runtime_mod_cmdm.bs.js");
var Runtime_mod_date$BwaxAdmin = require("./mod/runtime/runtime_mod_date.bs.js");
var Runtime_mod_timer$BwaxAdmin = require("./mod/runtime/runtime_mod_timer.bs.js");
var Runtime_mod_random$BwaxAdmin = require("./mod/runtime/runtime_mod_random.bs.js");

function invalid_args(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        name + ": Invalid Arugment"
      ];
}

function aggregateTasks(prim, prim$1, prim$2, prim$3) {
  Exec_helper.aggregateTasks(prim, prim$1, prim$2, prim$3);
  return /* () */0;
}

function run_command_to_model(command_handlers, context, used_cmds, used_msgs, param, on_trunk_result, on_branch_msgs) {
  var cmd = param[1];
  var m = param[0];
  return Command_engine$BwaxAdmin.execute_command(command_handlers, context, cmd, (function (param) {
                if (param.tag) {
                  return Curry._1(on_trunk_result, /* Error */Caml_chrome_debugger.variant("Error", 1, [param[0]]));
                } else {
                  var msgs = param[0];
                  var initial_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      cmd,
                      used_cmds
                    ]);
                  var initial_002 = Pervasives.$at(msgs, used_msgs);
                  var initial = /* tuple */[
                    m,
                    initial_001,
                    initial_002
                  ];
                  var wrap_task = function (msg, param, cont, on_error) {
                    return run_update_to_model(command_handlers, context, param[1], param[2], /* tuple */[
                                msg,
                                param[0]
                              ], (function (result) {
                                  if (result.tag) {
                                    return Curry._1(on_error, result[0]);
                                  } else {
                                    var match = result[0];
                                    return Curry._1(cont, /* tuple */[
                                                match[0],
                                                match[1],
                                                match[2]
                                              ]);
                                  }
                                }), on_branch_msgs);
                  };
                  if (Plate$BwaxAdmin.List.length(msgs) > 0) {
                    return aggregateTasks(Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(wrap_task, msgs)), initial, (function (result) {
                                  return Curry._1(on_trunk_result, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [result]));
                                }), (function (error) {
                                  return Curry._1(on_trunk_result, /* Error */Caml_chrome_debugger.variant("Error", 1, [error]));
                                }));
                  } else {
                    return Curry._1(on_trunk_result, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [initial]));
                  }
                }
              }), on_branch_msgs);
}

function run_update_to_model(command_handlers, context, used_cmds, used_msgs, param, on_trunk_result, on_branch_msgs) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["update"]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  var result = Lang_eval$BwaxAdmin.apply_value_args(undefined, fn, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          param[0],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              param[1],
              /* [] */0
            ])
        ]), /* No_term */0);
  var match = result[0];
  if (typeof match === "number" || match.tag) {
    return invalid_args("update");
  } else {
    var match$1 = match[0];
    if (match$1) {
      var match$2 = match$1[1];
      if (match$2 && !match$2[1]) {
        return run_command_to_model(command_handlers, context, used_cmds, used_msgs, /* tuple */[
                    match$1[0],
                    match$2[0]
                  ], on_trunk_result, on_branch_msgs);
      } else {
        return invalid_args("update");
      }
    } else {
      return invalid_args("update");
    }
  }
}

function run_update(context, param) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["update"]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  var result = Lang_eval$BwaxAdmin.apply_value_args(undefined, fn, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          param[0],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              param[1],
              /* [] */0
            ])
        ]), /* No_term */0);
  var match = result[0];
  if (typeof match === "number" || match.tag) {
    return invalid_args("update");
  } else {
    var match$1 = match[0];
    if (match$1) {
      var match$2 = match$1[1];
      if (match$2 && !match$2[1]) {
        return /* tuple */[
                match$1[0],
                match$2[0]
              ];
      } else {
        return invalid_args("update");
      }
    } else {
      return invalid_args("update");
    }
  }
}

function is_in_env(context, name) {
  return Dict$BwaxAdmin.$$String.get(name, context[/* env */3]) !== undefined;
}

function has_ready(context) {
  return is_in_env(context, "ready");
}

function has_implemented_ready(context) {
  var match = Dict$BwaxAdmin.$$String.get("ready", context[/* env */3]);
  if (match !== undefined) {
    var match$1 = match[0];
    var match$2 = match$1[0][0];
    if (typeof match$2 === "number" || match$2.tag !== /* V_closure */3) {
      return false;
    } else {
      var match$3 = match$2[0];
      if (match$3) {
        var match$4 = match$3[0][0];
        if (typeof match$4 === "number" || match$4.tag || match$3[1]) {
          return false;
        } else {
          var model_name = match$4[0];
          var _expr = match$2[1];
          while(true) {
            var expr = _expr;
            var match$5 = expr[0];
            if (typeof match$5 === "number") {
              return true;
            } else {
              switch (match$5.tag | 0) {
                case /* Tuple */0 :
                    var match$6 = match$5[0];
                    if (match$6) {
                      var match$7 = match$6[0][0];
                      if (typeof match$7 === "number" || match$7.tag !== /* Ref */16) {
                        return true;
                      } else {
                        var match$8 = match$6[1];
                        if (match$8) {
                          var match$9 = match$8[0][0];
                          if (typeof match$9 === "number" || match$9.tag !== /* Ref */16) {
                            return true;
                          } else {
                            switch (match$9[0]) {
                              case "Cmd.none" :
                              case "none" :
                                  break;
                              default:
                                return true;
                            }
                            if (match$8[1]) {
                              return true;
                            } else {
                              return model_name !== match$7[0];
                            }
                          }
                        } else {
                          return true;
                        }
                      }
                    } else {
                      return true;
                    }
                    break;
                case /* Let */14 :
                    _expr = match$5[1];
                    continue ;
                default:
                  return true;
              }
            }
          };
        }
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
}

function run_ready(context, m) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["ready"]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  var result = Lang_eval$BwaxAdmin.apply_value_args(undefined, fn, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          m,
          /* [] */0
        ]), /* No_term */0);
  var match = result[0];
  if (typeof match === "number" || match.tag) {
    return invalid_args("ready");
  } else {
    var match$1 = match[0];
    if (match$1) {
      var match$2 = match$1[1];
      if (match$2 && !match$2[1]) {
        return /* tuple */[
                match$1[0],
                match$2[0]
              ];
      } else {
        return invalid_args("ready");
      }
    } else {
      return invalid_args("ready");
    }
  }
}

function has_dispose(context) {
  return is_in_env(context, "dispose");
}

function run_dispose(context, m) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["dispose"]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  return Lang_eval$BwaxAdmin.apply_value_args(undefined, fn, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                m,
                /* [] */0
              ]), /* No_term */0);
}

function run_command(command_handlers, context, cmd, on_msgs) {
  return Command_engine$BwaxAdmin.execute_command(command_handlers, context, cmd, on_msgs, on_msgs);
}

function run_view(context, model) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["view"]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  return Lang_eval$BwaxAdmin.apply_value(undefined, fn, model, /* No_term */0);
}

function run_view_with_additional_params(context, model, maybe_view_params) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, ["view"]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  if (maybe_view_params !== undefined) {
    return Lang_eval$BwaxAdmin.apply_value_args(undefined, fn, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  model,
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      maybe_view_params,
                      /* [] */0
                    ])
                ]), /* No_term */0);
  } else {
    return Lang_eval$BwaxAdmin.apply_value(undefined, fn, model, /* No_term */0);
  }
}

function run_specific_view(context, view_name, args, model) {
  var expr_000 = /* Ref */Caml_chrome_debugger.variant("Ref", 16, [view_name]);
  var expr = /* tuple */[
    expr_000,
    /* No_term */0
  ];
  var fn = Lang_eval$BwaxAdmin.evaluate(context[/* env */3], undefined, expr);
  var all_args = Pervasives.$at(args, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          model,
          /* [] */0
        ]));
  return Lang_eval$BwaxAdmin.apply_value_args(undefined, fn, all_args, /* No_term */0);
}

function run_init_to_model(command_handlers, context, params, on_trunk_result, on_branch_msgs) {
  var match = Dict$BwaxAdmin.$$String.get("init", context[/* env */3]);
  var init_func;
  if (match !== undefined) {
    init_func = Plate$BwaxAdmin.fst(match[0]);
  } else {
    throw [
          Runtime_common$BwaxAdmin.Runtime_exn,
          "I can't find `init` function"
        ];
  }
  var result = Lang_eval$BwaxAdmin.apply_value_args(undefined, init_func, params, /* No_term */0);
  var match$1 = result[0];
  if (typeof match$1 === "number" || match$1.tag) {
    return invalid_args("init");
  } else {
    var match$2 = match$1[0];
    if (match$2) {
      var match$3 = match$2[1];
      if (match$3 && !match$3[1]) {
        return run_command_to_model(command_handlers, context, /* [] */0, /* [] */0, /* tuple */[
                    match$2[0],
                    match$3[0]
                  ], on_trunk_result, on_branch_msgs);
      } else {
        return invalid_args("init");
      }
    } else {
      return invalid_args("init");
    }
  }
}

function try_to_run_reInit(command_handlers, context, model, params_value, on_trunk_result, on_branch_msgs) {
  var match = Dict$BwaxAdmin.$$String.get("reInit", context[/* env */3]);
  if (match !== undefined) {
    var reInit_func = Plate$BwaxAdmin.fst(match[0]);
    var result = Lang_eval$BwaxAdmin.apply_value_args(undefined, reInit_func, /* :: */Caml_chrome_debugger.simpleVariant("::", [
            model,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                params_value,
                /* [] */0
              ])
          ]), /* No_term */0);
    var match$1 = result[0];
    if (typeof match$1 === "number" || match$1.tag) {
      return invalid_args("reInit");
    } else {
      var match$2 = match$1[0];
      if (match$2) {
        var match$3 = match$2[1];
        if (match$3 && !match$3[1]) {
          return run_command_to_model(command_handlers, context, /* [] */0, /* [] */0, /* tuple */[
                      match$2[0],
                      match$3[0]
                    ], on_trunk_result, on_branch_msgs);
        } else {
          return invalid_args("reInit");
        }
      } else {
        return invalid_args("reInit");
      }
    }
  } else {
    return /* () */0;
  }
}

function run_reInit(command_handlers, context, model, init_params, on_trunk_result, on_branch_msgs) {
  var match = Dict$BwaxAdmin.$$String.get("reInit", context[/* env */3]);
  var result;
  if (match !== undefined) {
    var reInit_func = Plate$BwaxAdmin.fst(match[0]);
    result = Lang_eval$BwaxAdmin.apply_value_args(undefined, reInit_func, /* :: */Caml_chrome_debugger.simpleVariant("::", [
            model,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                init_params,
                /* [] */0
              ])
          ]), /* No_term */0);
  } else {
    result = /* tuple */[
      /* V_tuple */Caml_chrome_debugger.variant("V_tuple", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
              model,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Runtime_mod_cmd$BwaxAdmin.cmd_none,
                  /* [] */0
                ])
            ])]),
      /* No_term */0
    ];
  }
  var match$1 = result[0];
  if (typeof match$1 !== "number" && !match$1.tag) {
    var match$2 = match$1[0];
    if (match$2) {
      var match$3 = match$2[1];
      if (match$3 && !match$3[1]) {
        return run_command_to_model(command_handlers, context, /* [] */0, /* [] */0, /* tuple */[
                    match$2[0],
                    match$3[0]
                  ], on_trunk_result, on_branch_msgs);
      }
      
    }
    
  }
  console.log("Wrong Result", Lang_eval$BwaxAdmin.string_of_value(result));
  return invalid_args("init");
}

var ms_000 = /* tuple */[
  Lang_mod_preset$BwaxAdmin.src,
  Lang_mod_preset$BwaxAdmin.externals
];

var ms_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      Lang_mod_maybe$BwaxAdmin.src,
      Lang_mod_maybe$BwaxAdmin.externals
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          Lang_mod_basics$BwaxAdmin.src,
          Lang_mod_basics$BwaxAdmin.externals
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              Lang_mod_char$BwaxAdmin.src(/* () */0),
              Lang_mod_char$BwaxAdmin.externals
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  Lang_mod_number$BwaxAdmin.src,
                  Lang_mod_number$BwaxAdmin.externals(/* () */0)
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      Lang_mod_list$BwaxAdmin.src,
                      Lang_mod_list$BwaxAdmin.externals
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          Lang_mod_array$BwaxAdmin.src,
                          Lang_mod_array$BwaxAdmin.externals
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              Lang_mod_dict$BwaxAdmin.src,
                              Lang_mod_dict$BwaxAdmin.externals
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  Lang_mod_set$BwaxAdmin.src,
                                  Lang_mod_set$BwaxAdmin.externals
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      Lang_mod_string$BwaxAdmin.src,
                                      Lang_mod_string$BwaxAdmin.externals
                                    ],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        /* tuple */[
                                          Lang_mod_json$BwaxAdmin.src,
                                          Lang_mod_json$BwaxAdmin.externals
                                        ],
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            /* tuple */[
                                              Lang_mod_file$BwaxAdmin.src,
                                              Lang_mod_file$BwaxAdmin.externals
                                            ],
                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                /* tuple */[
                                                  Lang_mod_crypto$BwaxAdmin.src,
                                                  Lang_mod_crypto$BwaxAdmin.externals
                                                ],
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    /* tuple */[
                                                      Runtime_mod_cmd$BwaxAdmin.src,
                                                      Runtime_mod_cmd$BwaxAdmin.externals
                                                    ],
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        /* tuple */[
                                                          Runtime_mod_cmdm$BwaxAdmin.src,
                                                          Runtime_mod_cmdm$BwaxAdmin.externals
                                                        ],
                                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            /* tuple */[
                                                              Runtime_mod_date$BwaxAdmin.src,
                                                              Runtime_mod_date$BwaxAdmin.externals
                                                            ],
                                                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                /* tuple */[
                                                                  Runtime_mod_timer$BwaxAdmin.src,
                                                                  Runtime_mod_timer$BwaxAdmin.externals
                                                                ],
                                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                    /* tuple */[
                                                                      Runtime_mod_random$BwaxAdmin.src,
                                                                      Runtime_mod_random$BwaxAdmin.externals
                                                                    ],
                                                                    /* [] */0
                                                                  ])
                                                              ])
                                                          ])
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ])
      ])
  ]);

var ms = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    ms_000,
    ms_001
  ]);

var match = Plate$BwaxAdmin.List.unzip(ms);

function is_msg_tagger(_t) {
  while(true) {
    var t = _t;
    if (typeof t === "number" || t.tag) {
      return false;
    } else {
      switch (t[0]) {
        case "Arrow" :
            var match = t[1];
            if (match) {
              var match$1 = match[1];
              if (match$1 && !match$1[1]) {
                _t = match$1[0];
                continue ;
              } else {
                return false;
              }
            } else {
              return false;
            }
        case "Msg" :
            return true;
        default:
          return false;
      }
    }
  };
}

var nt = /* No_term */0;

var common_runtime_modules = match[0];

var common_runtime_externals = match[1];

exports.nt = nt;
exports.invalid_args = invalid_args;
exports.aggregateTasks = aggregateTasks;
exports.run_command_to_model = run_command_to_model;
exports.run_update_to_model = run_update_to_model;
exports.run_update = run_update;
exports.is_in_env = is_in_env;
exports.has_ready = has_ready;
exports.has_implemented_ready = has_implemented_ready;
exports.run_ready = run_ready;
exports.has_dispose = has_dispose;
exports.run_dispose = run_dispose;
exports.run_command = run_command;
exports.run_view = run_view;
exports.run_view_with_additional_params = run_view_with_additional_params;
exports.run_specific_view = run_specific_view;
exports.run_init_to_model = run_init_to_model;
exports.try_to_run_reInit = try_to_run_reInit;
exports.run_reInit = run_reInit;
exports.common_runtime_modules = common_runtime_modules;
exports.common_runtime_externals = common_runtime_externals;
exports.is_msg_tagger = is_msg_tagger;
/* ms Not a pure module */
