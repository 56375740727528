
import React, { useState, useEffect } from 'react'

import GradientEdit from '../components/GradientEdit';
import AngleEdit from '../components/AngleEdit';

export default function LinearGradientEdit({ attributes, activeImageItem, onChange, setColorPickerInPopoverVisible, updateActiveImageItem, activeImageIndex }) {

    const angleRegx = /(\d+(?:\.\d+)?)(deg|rad|grad|turn)/

    const [angle, setAngle] = useState({ value: 180, unit: 'deg' })

    useEffect(() => {
        if(activeImageItem.gradient.match(angleRegx)) {
            const matchedResult = activeImageItem.gradient.match(angleRegx)
            setAngle({
                value: matchedResult[1],
                unit: matchedResult[2]
            })
        }
    }, [activeImageItem])

    function updateBackgroundImage (gradient) {
        const newBgImages = attributes['backgroundImage'].map((image, index) => {
            if(index === activeImageIndex) {
                return gradient 
            } else {
                return image
            }
        })
        updateActiveImageItem({ ...activeImageItem, gradient })
        onChange({ backgroundImage: newBgImages })
    }

    function updateAngle (newAngle) {
        const { value, unit } = newAngle

        if(activeImageItem.gradient.match(angleRegx)) {
            updateBackgroundImage(activeImageItem.gradient.replace(angleRegx, `${value}${unit}`))
        } else {
            updateBackgroundImage(activeImageItem.gradient.replace("gradient(", `gradient(${value}${unit}, `))
        }
    }
    
    return (
        <div className='linear-gradient-container'>
            <AngleEdit angle={angle} onChange={angle => updateAngle(angle)}/>
            <GradientEdit activeImageItem={activeImageItem} updateBackgroundImage={updateBackgroundImage} 
                setColorPickerInPopoverVisible={setColorPickerInPopoverVisible}
            />
        </div>
    )
}
