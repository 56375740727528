import React, { useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom';

import Loading from 'Client/js/components/Loading'

import { VariableSizeList, DynamicSizeList } from 'Client/3rd/react-window'

import Pagination from '../components/Pagination';


import VirtualList from './VirtualList';

import { InboxOutlined } from '@ant-design/icons';

import useResizeObserver from '@react-hook/resize-observer'

import throttle from 'lodash/throttle'

import './table.less'

import getScrollbarSize from './util/getScrollbarSize'

import TableHeader from './TableHeader';
import TableRow from './TableRow';

const TheList = VirtualList
// const TheList = DynamicSizeList

const defaultPage = 1

function buildColsWithTableConfig(columns, tableConfig) {
    const hiddenColumns = tableConfig && tableConfig.hiddenColumns ? tableConfig.hiddenColumns : []
    const fixedLeftColumns = tableConfig && tableConfig.fixedLeftColumns ? tableConfig.fixedLeftColumns : []
    const columnWidths = tableConfig && tableConfig.columnWidths ? tableConfig.columnWidths : {}

    const columnsToShow = columns.filter(c => hiddenColumns.indexOf(c.name) === -1).map(column => {
        return {
            ...column,
            width: columnWidths[column.key || column.title] && !(column.fixed && column.fixed === "right") ?
                columnWidths[column.key || column.title] : column.width // fixed right 的列不跟着 columnWidths 的值改变。
        }
    })

    const defalutLeftCols = columnsToShow.filter(c => c.fixed === 'left')
    const configLeftCols = fixedLeftColumns.reduce((acc, next) => {
        const col = columnsToShow.filter(n => n.fixed !== 'left').find(c => c.key === next)
        return !!col ? [
            ...acc,
            { ...col, fixed: 'left' }
        ] : acc
    }, [])

    const leftCols = [
        ...defalutLeftCols,
        ...configLeftCols
    ]

    const notLeftCols = columnsToShow.filter(c => !leftCols.some(lc => lc.key === c.key))

    //TODO: handle customize column widths

    return [
        ...leftCols,
        ...notLeftCols
    ]

}

const batchUpdates = throttle(ReactDOM.unstable_batchedUpdates, 320);
// const batchUpdates = ReactDOM.unstable_batchedUpdates;

export default function Table(props) {

    const {
        dataSource,
        columns,
        footer,

        width,
        height,
        rowHeight,
        estimatedRowHeight, // 一般是根据 loading 判断的，用于显示空状态是的高度

        onScrollEnd,
        onScrollTop,
        onSort,

        // tableConfig : {
        //     hiddenColumns:  string[]
        //     fixedLeftColumns: string []
        //     columnWidths:  { <column-name>: <width-in-px> }
        // },
        tableConfig,
        onTableConfigChange,

        initializing, initializingBody,

        getRowKey,
        className,
        totalCount,
        pagination,
        virtualized = true,

        style: givenStyle,
    } = props

    const tableRef = useRef(null)
    const tableListRef = useRef(null)
    const fixedLeftTableListRef = useRef(null)
    const fixedRightTableListRef = useRef(null)
    const tableHeaderRef = useRef(null)
    const listInstanceRef = useRef(null)
    const lastScrollTop = useRef(null)
    const lastScrollLeft = useRef(null)

    const tableColumns = buildColsWithTableConfig(columns, tableConfig)

    const getScrollbarExisted = () => {
        const el = tableListRef.current;
        if (el) {
            return {
                horizontal: el.clientWidth != 0 && el.scrollWidth > el.clientWidth,
                vertical: el.clientHeight != 0 && el.scrollHeight > el.clientHeight
            }
        }
        return {
            horizontal: false,
            vertical: false
        }
    }

    const [fixedHeaderHeight, setFixedHeaderHeight] = useState(null)
    const [listCotHeight, setListCotHeight] = useState(undefined)
    const [scrollbarSize, setScrollbarSize] = useState(0)
    const [pingLeft, setPingLeft] = useState(true)
    const [pingRight, setPingRight] = useState(false)
    const [currentPage, setCurrentPage] = useState(defaultPage)
    const [initialPage, setInitialPage] = useState(defaultPage)
    const [hoverRow, setHoverRow] = useState(null)
    const [orders, setOrders] = useState({})
    const [grabbingColumn, setGrabbingColumn] = useState(null)

    const lastScrollBarRef = useRef({
        horizontal: false,
        vertical: false
    });

    const [scrollbarExisted, setScrollbarExisted] = useState(lastScrollBarRef.current);
    function updateScrollbarExisted(scrollbar) {
        lastScrollBarRef.current = scrollbar;
        setScrollbarExisted(scrollbar);
    }

    //set scrollBar size
    useEffect(() => {
        setScrollbarSize(getScrollbarSize())
    }, [])

    //window resize ping right calculate
    function resetScrollbarExisted() {
        if (tableListRef.current) {
            const { clientWidth, scrollLeft, scrollWidth } = tableListRef.current
            setPingRight(scrollLeft + clientWidth === scrollWidth)
        }
        const scrollbar = getScrollbarExisted();
        const last = lastScrollBarRef.current;
        if (last.horizontal != scrollbar.horizontal || last.vertical != scrollbar.vertical) {
            updateScrollbarExisted(scrollbar);
        }
    }

    useResizeObserver(tableListRef, _ => {
        resetScrollbarExisted()
    });

    //Add scroll listener for scroll sync
    useEffect(() => {
        const tableHeader = tableHeaderRef.current
        const tableBody = tableListRef.current
        const fixedLeftTable = fixedLeftTableListRef.current
        const fixedRightTable = fixedRightTableListRef.current

        const handleScrollTop = (e) => {

            const target = e.target
            if (e.currentTarget !== target) {
                return
            }
            const scrollTop = target.scrollTop
            if (target !== tableHeader && lastScrollTop.current !== scrollTop) {
                if (fixedLeftTable && target !== fixedLeftTable) {
                    fixedLeftTable.scrollTop = scrollTop
                }
                if (fixedRightTable && target !== fixedRightTable) {
                    fixedRightTable.scrollTop = scrollTop
                }
                if (tableBody && target !== tableBody) {
                    tableBody.scrollTop = scrollTop
                }
            }
            lastScrollTop.current = scrollTop
        }

        const handleScrollLeft = e => {
            if (e.currentTarget !== e.target) {
                return
            }
            const target = e.target
            const scrollLeft = target.scrollLeft
            if (target.scrollLeft !== lastScrollLeft.current) {
                if (target === tableBody && tableHeader) {
                    tableHeader.scrollLeft = scrollLeft
                } else if (target === tableHeader && tableBody) {
                    tableBody.scrollLeft = scrollLeft
                }
            }
            lastScrollLeft.current = target.scrollLeft
            batchUpdates(() => {
                setPingLeft(scrollLeft === 0)
                setPingRight(scrollLeft + target.clientWidth === target.scrollWidth)
                // }
            })
        }

        const handleBodyScroll = e => {
            handleScrollLeft(e)
            handleScrollTop(e)
        }


        //scroll sync, fixedTableList, TableList
        const addScrollListener = (el, fn) => {
            
            if (el) {
                el.addEventListener('scroll', fn)
            }
        }
        addScrollListener(tableHeader, handleScrollLeft)
        addScrollListener(tableBody, handleBodyScroll)
        addScrollListener(fixedLeftTable, handleScrollTop)
        addScrollListener(fixedRightTable, handleScrollTop)

        return () => {
            const rmScrollListener = (el, fn) => {
                if (el) {
                    el.removeEventListener('scroll', fn)
                }
            }

            rmScrollListener(tableHeader, handleScrollLeft)
            rmScrollListener(tableBody, handleBodyScroll)
            rmScrollListener(fixedLeftTable, handleScrollTop)
            rmScrollListener(fixedRightTable, handleScrollTop)
        }

    }, [initializing])

    function getCurrentScrollPage(visibleStartIndex) {
        if (pagination && pagination.pageSize) {
            const { pageSize } = pagination
            const increasedPage = Math.ceil((visibleStartIndex + 1) / pageSize) - 1
            setCurrentPage(prevPage => {
                const newPage = initialPage + increasedPage
                return newPage !== prevPage ? newPage : prevPage
            })
        }
    }

    function onItemsRendered(itemRendered) {
        const { visibleStartIndex, visibleStopIndex } = itemRendered
        const scrollEnd = visibleStopIndex === dataSource.length - 1
        const scrollTop = visibleStartIndex === 0

        // 暂时不需要了，分页暂时不跟上拉加载结合
        // getCurrentScrollPage(visibleStartIndex)

        if (scrollEnd && onScrollEnd) {
            onScrollEnd(() => {
                //callback
            })
        }
    }

    const fixedLeftColumns = tableColumns.filter(c => c.fixed && c.fixed === 'left')
    const fixedRightColumns = tableColumns.filter(c => c.fixed && c.fixed === 'right')

    const fixedLeftStyle = !pingLeft ? {
        boxShadow: "4px 0px 4px -4px rgba(0, 0, 0, 0.25)"
    } : {}

    const scrollbar = scrollbarExisted;
    const fixedRightStyle = (() => {
        let style = {}

        if (scrollbar.horizontal && !pingRight) {
            style.boxShadow = "-4px 0px 4px -4px rgba(0, 0, 0, .25)"
        }
        style.right = scrollbar.vertical && !initializing ? scrollbarSize : 0

        return style
    })();

    const renderFixedTableHeader = () => {

        const fixedTableHeaderCommonProps = {
            className: "fixed-table-header",
            orders,
            setOrders,
            onSort,
            isFixedHeader: true,
            tableConfig,
            onTableConfigChange,
            originalColumns: columns,
            grabbingColumn,
            setGrabbingColumn,
        }

        return (
            <>
                {
                    fixedLeftColumns.length > 0 ?
                        <div
                            className="table-fixed-left"
                            style={{ ...fixedLeftStyle }}>
                            <TableHeader
                                columns={fixedLeftColumns}
                                style={{
                                    height: fixedHeaderHeight ? fixedHeaderHeight : "auto"
                                }}
                                {...fixedTableHeaderCommonProps} />
                        </div> : null
                }
                {
                    fixedRightColumns.length > 0 ?
                        <div
                            className="table-fixed-right"
                            style={{ ...fixedRightStyle }}>
                            <TableHeader
                                columns={fixedRightColumns}
                                style={{
                                    height: fixedHeaderHeight ? fixedHeaderHeight : "auto"
                                }}
                                {...fixedTableHeaderCommonProps} />
                        </div> : null
                }
            </>
        )
    }

    const listCotRef = useRef(null);

    useResizeObserver(listCotRef, e => {
        const height = Math.round(e.contentRect.height);
        if (listCotHeight != height) {
            setListCotHeight(height)
        }

    });

    useResizeObserver(tableHeaderRef, e => {
        const height = Math.round(e.contentRect.height);
        if (fixedHeaderHeight != height) {
            setFixedHeaderHeight(height);
        }
    })


    return (
        <div
            className={"bwax-table" + (className ? " " + className : "")}
            ref={tableRef}
            style={{ width: width || 'auto', height: height || '100%', ...givenStyle }}>
            <div className="table-header-cot">
                <TableHeader
                    outerRef={tableHeaderRef}
                    columns={tableColumns}
                    originalColumns={columns}
                    className="table-header"
                    style={{ marginRight: scrollbar.vertical ? scrollbarSize : 0 }}
                    orders={orders}
                    setOrders={setOrders}
                    onSort={onSort}
                    tableConfig={tableConfig}
                    onTableConfigChange={onTableConfigChange}
                    grabbingColumn={grabbingColumn}
                    setGrabbingColumn={setGrabbingColumn}
                />
            </div>

            <div className="table-list-cot" ref={listCotRef} style={{
            }} >
                <TableList
                    width="100%"
                    outerRef={tableListRef}
                    instanceRef={listInstanceRef}
                    className="table-list"
                    fixedLeftRef={fixedLeftTableListRef}
                    fixedLeftStyle={fixedLeftStyle}
                    fixedRightRef={fixedRightTableListRef}
                    fixedRightStyle={fixedRightStyle}
                    scrollbarSize={scrollbarSize}
                    virtualized={virtualized}
                    initializing={initializing}
                    tableBody={
                        initializing ?
                            (
                                initializingBody ?
                                    (props) => React.cloneElement(initializingBody, { tableColumns, ...props }) :
                                    TableLoadingBody
                            ) :
                            (dataSource.length > 0 ? TableListBody : getTableListEmptyBody({ 
                                estimatedRowHeight, listCotHeight, pageSize: pagination.pageSize, scrollbar: getScrollbarExisted()
                            }))}
                    columns={tableColumns}
                    dataSource={dataSource}
                    onItemsRendered={onItemsRendered}
                    listCotHeight={listCotHeight}
                    rowHeight={rowHeight}
                    getRowKey={getRowKey}
                    grabbingColumn={grabbingColumn}
                    hoverProps={{
                        onItemOver: (idx) => {
                            if (hoverRow !== idx) {
                                setHoverRow(idx)
                            }
                        },
                        onItemOut: () => setHoverRow(null),
                        currentHoverRow: hoverRow
                    }}
                    // virtualized={virtualized}
                />
            </div>
            {renderFixedTableHeader()}
            {
                <TableFooter
                    footer={footer}
                    initializing={initializing}
                    totalCount={totalCount}
                    pagination={pagination}
                    currentPage={currentPage}
                    onPageChangeInner={(page, pageSize) => {
                        //翻页后将从第一项开始查看

                        /**
                         * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/997
                         * 原本使用 react-window 中的 scrollToItem 函数，不使用 react-window 之后 scrollToItem 是 undefined，故改用原生的 scrollTo（by 威豪 2022.08.24）
                         */

                        // const listInstance = listInstanceRef.current
                        // if (listInstance && typeof (listInstance.scrollToItem) === 'function') {
                        //     listInstance.scrollToItem(0)
                        // }
                        const tableList = tableListRef.current
                        if(tableList && typeof(tableList.scrollTo) === "function") {
                            tableList.scrollTo(0, 0)
                        }
                        setInitialPage(page)
                        setCurrentPage(page)
                    }} />
            }
        </div>
    )
}


const getTableListEmptyBody = ({estimatedRowHeight, listCotHeight, pageSize, scrollbar}) => (props) => {

    // const esitmateLoadingHeight = estimatedRowHeight * (pageSize - 2) + 8; // add horizontal scrollbar height.
    // const height = esitmateLoadingHeight > listCotHeight ? listCotHeight : esitmateLoadingHeight; 

    /**
     * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/987
     * table 为空时，提示信息改为使用内容区高度 listCotHeight（by 威豪 2022-09-19）
     */

    /**
     * 使用 esitmateLoadingHeight 可能是为了解决计算出来的 listCotHeight 不稳定而导致 footer 晃动的问题（by 威豪 2022-09-22）
     */

    /**
     * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/987
     * 使用 esitmateLoadingHeight，footer 晃动的问题依然存在，暂时还是先使用 listCotHeight（by 威豪 2022-10-10）
     */

    return (
        <div className="table-list-empty" style={{ 
                // height: "100%", 
                height: listCotHeight
        }}>
            <div className="table-list-empty-icon">
                <InboxOutlined
                    style={{
                        fontSize: "3rem",
                        opacity: .15
                    }} />
            </div>
            <p className="table-list-empty-desc" style={{
                marginTop: "0.5rem",
                opacity: .35
            }}>暂时没有数据</p>
        </div>
    )
}

const TableLoadingBody = () => {
    return (
        <div className="table-list-empty" style={{ height: "100%" }}>
            <Loading />
        </div>
    )
}


const TableListBody = ({ children, style }) => {

    return (
        <table style={style}>
            <tbody className="table-body">
                {children}
            </tbody>
        </table>
    )
}


const TableList = (props) => {

    const {
        columns,
        dataSource,
        width,
        onItemsRendered = () => { },
        className,
        style,
        rowHeight,

        listCotHeight,
        itemSizeMap,
        outerRef,
        instanceRef,
        tableBody,
        fixedLeftRef,
        fixedRightRef,
        fixedLeftStyle,
        fixedRightStyle,
        scrollbarSize,
        hoverProps,
        grabbingColumn,
        virtualized,

        initializing,

        currentHoverRow,

        getRowKey

    } = props

    const listRef = useRef(null)

    const [editVisibleRowItem, setEditVisibleRowItem] = useState(null)

    const hasRowHeight = rowHeight !== undefined

    const List = (() => {
        // if (virtualized) {
        return hasRowHeight ? VariableSizeList :
            TheList
        // } else {
        // return NormalList
        // }
    })()

    const fixedLeftColumns = columns.filter(c => c.fixed && c.fixed === 'left')
    const fixedRightColumns = columns.filter(c => c.fixed && c.fixed === 'right')

    const tableListHeight = listCotHeight == undefined ? "auto" : listCotHeight;

    const fixedHeight = typeof (tableListHeight) === "number" ?
        (tableListHeight > 0 ? tableListHeight - scrollbarSize : 0) :
        tableListHeight
        ;

    return (
        <List
            ref={e => {
                if (instanceRef) {
                    instanceRef.current = e
                }
                listRef.current = e
            }}
            itemKey={(index, data) => {
                // TODO Van: 不知道为啥给回来的是 undefined
                if (getRowKey) {
                    return getRowKey(index, dataSource && dataSource[index]) || index
                }
                return index;
            }}
            initializing={initializing}
            outerRef={outerRef}
            className={className}
            style={style}
            width={width}
            height={tableListHeight}
            overScanCount={2}
            fixedLeftRef={fixedLeftRef}
            fixedLeftStyle={{
                height: fixedHeight,
                ...fixedLeftStyle
            }}
            fixedRightStyle={{
                height: fixedHeight,
                ...fixedRightStyle
            }}
            fixedHeight={fixedHeight}
            fixedLeftRow={
                <TableRow
                    columns={fixedLeftColumns}
                    dataSource={dataSource}
                    virtualized={virtualized}
                    currentEditVisibleItem={editVisibleRowItem}
                    grabbingColumn={grabbingColumn}
                    {...hoverProps}
                />
            }
            fixedRightRef={fixedRightRef}
            fixedRightRow={
                <TableRow
                    columns={fixedRightColumns}
                    dataSource={dataSource}
                    virtualized={virtualized}
                    currentEditVisibleItem={editVisibleRowItem}
                    grabbingColumn={grabbingColumn}
                    {...hoverProps}
                />
            }
            fixedLeftColumns={fixedLeftColumns}
            fixedRightColumns={fixedRightColumns}
            itemCount={dataSource.length || 0}
            innerElementType={tableBody}
            onItemsRendered={onItemsRendered}
            {...(hasRowHeight ? {
                itemSize: (index) => {
                    if (itemSizeMap && itemSizeMap[index]) {
                        return itemSizeMap[index]
                    }
                    if (rowHeight) {
                        if (typeof rowHeight === 'function') {
                            return rowHeight(index, dataSource[index])
                        }
                        return rowHeight
                    }
                    return {}
                }
            } : {})}
        >
            {
                <TableRow
                    columns={columns}
                    dataSource={dataSource}
                    virtualized={virtualized}
                    grabbingColumn={grabbingColumn}
                    onEditVisibleChange={(visible, index) => {
                        setEditVisibleRowItem(prevItem => {
                            return visible ? index : null
                        })
                    }}
                    currentEditVisibleItem={editVisibleRowItem}
                    currentHoverRow={currentHoverRow}
                    {...hoverProps}
                />
            }
        </List>
    )
}



const TableFooter = (props) => {

    const {
        totalCount,
        footer,
        pagination,
        currentPage,
        onPageChangeInner,
        initializing
    } = props

    const renderPagination = (pagination) => {

        const {
            onPageChange,
        } = pagination

        return (
            <Pagination {...{
                ...pagination,
                totalCount,
                currentPage: pagination.currentPage !== undefined ? pagination.currentPage : currentPage,
                onPageChange: (page, pageSize) => {
                    onPageChange && onPageChange(page, pageSize)
                    onPageChangeInner(page, pageSize)
                }
            }}
            />
        )

    }

    return (
        <div className="table-footer default-table-footer">
            <span className="footer-note">{footer || (initializing ? "正在加载..." : `共 ${totalCount} 行`)}</span>
            {pagination ? renderPagination(pagination) : null}
        </div>
    )
}


