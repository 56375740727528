// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Wx_pay$BwaxAdmin = require("../../utils/wx_pay.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Wx_pay_adhoc$BwaxAdmin = require("../../utils/wx_pay_adhoc.bs.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "WxPay";

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        "Cmd." + (name + ": Invalid Arguments")
      ];
}

function func(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_WxPay_initalAdhocPay",
              args
            ]),
          /* No_term */0
        ];
}

function func$1(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_WxPay_checkLocalRefresh",
              args
            ]),
          /* No_term */0
        ];
}

function func$2(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_WxPay_clearLocalRefresh",
              args
            ]),
          /* No_term */0
        ];
}

function func$3(args) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "Cmd_WxPay_initiatePay",
              args
            ]),
          /* No_term */0
        ];
}

var externals_vals_000 = /* tuple */[
  "initialAdhocPay",
  Runtime_common$BwaxAdmin.pack_func(4, func)
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "checkLocalRefresh",
      Runtime_common$BwaxAdmin.pack_func(3, func$1)
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "clearLocalRefresh",
          Runtime_common$BwaxAdmin.pack_func(1, func$2)
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "initiatePay",
              Runtime_common$BwaxAdmin.pack_func(3, func$3)
            ],
            /* [] */0
          ])
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

function handle_paid(on_paid, cont, state, authcode) {
  try {
    var state_v = Runtime_common$BwaxAdmin.pack_string(state);
    var authcode_v = Runtime_common$BwaxAdmin.pack_maybe(Runtime_common$BwaxAdmin.pack_string, authcode);
    var msg = Lang_eval$BwaxAdmin.apply_value_args(undefined, on_paid, /* :: */Caml_chrome_debugger.simpleVariant("::", [
            state_v,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                authcode_v,
                /* [] */0
              ])
          ]), Runtime_common$BwaxAdmin.nt);
    return Curry._1(cont, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      msg,
                      /* [] */0
                    ])]));
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    return Curry._1(cont, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
  }
}

function handle_error(on_error, cont, error) {
  var error_v = Runtime_common$BwaxAdmin.pack_string(error);
  var msg = Lang_eval$BwaxAdmin.apply_value_args(undefined, on_error, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          error_v,
          /* [] */0
        ]), Runtime_common$BwaxAdmin.nt);
  return Curry._1(cont, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    msg,
                    /* [] */0
                  ])]));
}

function initiate_pay_command(context, param, on_trunk_msgs, on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || !(cmd.tag === /* V_tagged */6 && cmd[0] === "Cmd_WxPay_initiatePay")) {
    return false;
  } else {
    var match = cmd[1];
    if (match) {
      var match$1 = match[0][0];
      if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
        return false;
      } else {
        var match$2 = match$1[0];
        if (typeof match$2 === "number" || match$2.tag !== /* String */2) {
          return false;
        } else {
          var match$3 = match[1];
          if (match$3) {
            var match$4 = match$3[1];
            if (match$4 && !match$4[1]) {
              var on_error = match$4[0];
              var on_initiated = match$3[0];
              console.log("Start wx payment");
              Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
              try {
                Wx_pay$BwaxAdmin.initiate_pay(context[/* query_runner */4], match$2[0], (function (maybeCodeUrl) {
                        try {
                          console.log("Please show code URL", maybeCodeUrl);
                          var v = Runtime_common$BwaxAdmin.pack_maybe(Runtime_common$BwaxAdmin.pack_string, maybeCodeUrl);
                          var msg = Lang_eval$BwaxAdmin.apply_value(undefined, on_initiated, v, Runtime_common$BwaxAdmin.nt);
                          return Curry._1(on_branch_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            msg,
                                            /* [] */0
                                          ])]));
                        }
                        catch (raw_e){
                          var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                          return Curry._1(on_branch_msgs, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
                        }
                      }), (function (param) {
                        return handle_error(on_error, on_branch_msgs, param);
                      }));
              }
              catch (raw_e){
                var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                Curry._1(on_branch_msgs, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
              }
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  }
}

function initiate_adhod_pay_command(context, param, on_trunk_msgs, on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || !(cmd.tag === /* V_tagged */6 && cmd[0] === "Cmd_WxPay_initalAdhocPay")) {
    return false;
  } else {
    var match = cmd[1];
    if (match) {
      var match$1 = match[1];
      if (match$1) {
        var match$2 = match$1[1];
        if (match$2) {
          var match$3 = match$2[1];
          if (match$3 && !match$3[1]) {
            var on_error = match$3[0];
            var on_paid = match$2[0];
            var on_initiated = match$1[0];
            var params = match[0];
            Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
            try {
              var m_title = Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_string, Lang_eval$BwaxAdmin.get_field("title", params));
              var m_field_name = Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_string, Lang_eval$BwaxAdmin.get_field("fieldName", params));
              var m_adhoc_order_id = Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_string, Lang_eval$BwaxAdmin.get_field("adhocOrderId", params));
              var m_cent = Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_int, Lang_eval$BwaxAdmin.get_field("cent", params));
              if (m_title !== undefined) {
                if (m_field_name !== undefined && m_adhoc_order_id !== undefined && m_cent !== undefined) {
                  console.log("Please start payment");
                  Wx_pay_adhoc$BwaxAdmin.initiate_adhoc_pay(context[/* query_runner */4], /* record */Caml_chrome_debugger.record([
                          "title",
                          "field_name",
                          "adhoc_order_id",
                          "cent"
                        ], [
                          m_title,
                          m_field_name,
                          m_adhoc_order_id,
                          m_cent
                        ]), (function (maybeCodeUrl) {
                          console.log("Please show code URL", maybeCodeUrl);
                          var v = Runtime_common$BwaxAdmin.pack_maybe(Runtime_common$BwaxAdmin.pack_string, maybeCodeUrl);
                          var msg = Lang_eval$BwaxAdmin.apply_value(undefined, on_initiated, v, Runtime_common$BwaxAdmin.nt);
                          return Curry._1(on_branch_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            msg,
                                            /* [] */0
                                          ])]));
                        }), (function (param, param$1) {
                          return handle_paid(on_paid, on_branch_msgs, param, param$1);
                        }), (function (param) {
                          return handle_error(on_error, on_branch_msgs, param);
                        }));
                }
                
              }
              
            }
            catch (raw_e){
              var e = Caml_js_exceptions.internalToOCamlException(raw_e);
              Curry._1(on_branch_msgs, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
            }
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

function check_local_refresh_command(context, param, on_trunk_msgs, on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || !(cmd.tag === /* V_tagged */6 && cmd[0] === "Cmd_WxPay_checkLocalRefresh")) {
    return false;
  } else {
    var match = cmd[1];
    if (match) {
      var match$1 = match[1];
      if (match$1) {
        var match$2 = match$1[1];
        if (match$2 && !match$2[1]) {
          var on_error = match$2[0];
          var on_paid = match$1[0];
          var on_refreshing = match[0];
          console.log("Check local refreshing");
          Wx_pay_adhoc$BwaxAdmin.check_local_refresh(context[/* query_runner */4], (function (refreshing) {
                  try {
                    console.log("Return refreshing", refreshing);
                    var msg = Lang_eval$BwaxAdmin.apply_value(undefined, on_refreshing, Runtime_common$BwaxAdmin.pack_bool(refreshing), Runtime_common$BwaxAdmin.nt);
                    return Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      msg,
                                      /* [] */0
                                    ])]));
                  }
                  catch (raw_e){
                    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                    return Curry._1(on_trunk_msgs, /* Error */Caml_chrome_debugger.variant("Error", 1, [e]));
                  }
                }), (function (param, param$1) {
                  return handle_paid(on_paid, on_branch_msgs, param, param$1);
                }), (function (param) {
                  return handle_error(on_error, on_branch_msgs, param);
                }));
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

function clear_local_refresh_command(_context, param, on_trunk_msgs, _on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || !(cmd.tag === /* V_tagged */6 && cmd[0] === "Cmd_WxPay_clearLocalRefresh")) {
    return false;
  } else {
    Wx_pay_adhoc$BwaxAdmin.clear_local_refresh(/* () */0);
    Curry._1(on_trunk_msgs, /* Ok */Caml_chrome_debugger.variant("Ok", 0, [/* [] */0]));
    return true;
  }
}

var command_handlers_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    initiate_adhod_pay_command,
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        check_local_refresh_command,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            clear_local_refresh_command,
            /* [] */0
          ])
      ])
  ]);

var command_handlers = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    initiate_pay_command,
    command_handlers_001
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var no_type = /* No_term */0;

var src = "\n\nmodule WxPay;\n\n--- 微信支付 API\n--- params -> OnCodeUrl -> OnPaid -> OnError -> cmd\ninitialAdhocPay:\n    { title : String, fieldName: String, adhocOrderId: String, cent: Int }\n    -> (Maybe String -> msg)\n    -> (String -> Maybe String -> msg) \n    -> (String -> msg)\n    -> Cmd msg\n  = external\n;\n\n-- IsChecking -> OnPaid -> OnError -> cmd\ncheckLocalRefresh: \n    (Bool -> msg) \n    -> (String -> Maybe String -> msg)\n    -> (String -> msg) \n    -> Cmd msg \n  = external\n;\n\nclearLocalRefresh: () -> Cmd msg = external\n;\n\n-- 用于标准订单的微信支付 API\n-- orderId -> OnCodeUrl -> OnError -> cmd\ninitiatePay: String -> (Maybe String -> msg) -> (String -> msg) -> Cmd msg = external;\n\n";

exports.no_type = no_type;
exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.handle_paid = handle_paid;
exports.handle_error = handle_error;
exports.initiate_pay_command = initiate_pay_command;
exports.initiate_adhod_pay_command = initiate_adhod_pay_command;
exports.check_local_refresh_command = check_local_refresh_command;
exports.clear_local_refresh_command = clear_local_refresh_command;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
