

// the twitter set from https://casesandberg.github.io/react-color/
// export default [
//     '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC',
//     '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'
//   ]
// ;
import { color } from 'bwax-ui';

import dayjs from 'dayjs';

const d3 = require('d3-scale-chromatic');

// re-arrange
const twitter = [
    '#FF6900', '#FCB900', '#0693E3', '#00D084', '#EB144C',
    '#8ED1FC',
    '#7BDCB5', '#ABB8C3', '#F78DA7', '#9900EF'
];

const bwax = ["B50", "O50", "G60", "H50", "P60", "Y50", "R50"].map(color);

export function getColor(name) {
    return color(name)
}

export function getColorScheme(name) {

    // 
    if (name == "bwax") {
        // return the bwax ui color system representing colors
        return bwax
    } else if (name == "twitter") {
        return twitter
    }

    const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
    const v = d3["scheme" + nameCapitalized];
    if (Array.isArray(v)) {
        return v
    } else {
        console.warn("unknown color scheme");
        return twitter
    }

}

const FuncFormaters = {
    "YEARWEEK": (fieldType, value) => {
        // 这是数字一般是 202015 这种；
        // 格式化为 2020w15

        // 格式化微 YY-MM-DD (第一天) 

        const fullYear = Math.floor(value / 100)
        const weekNumber = value % 100;

        // 
        const d = dayjs(fullYear + "-01-01").week(weekNumber);
        return d.format("YY-MM-DD");

    }
}
const TypeFormaters = {

}


export function autoFormatValue(value, fieldSetting) {

    if (fieldSetting) {
        const [fieldName, fieldType, funcName, _funcArgs] = fieldSetting;

        const funcFormater = FuncFormaters[funcName];

        if (funcFormater) {
            return funcFormater(fieldType, value);
        }
        const typeFormater = TypeFormaters[fieldType];
        if (typeFormater) {
            return typeFormater(funcName, value);
        }

        return value
    }


    return value

}



const parseDate = value => dayjs(value, "YYYY-MM-DD");

const FuncAutoFillers = {
    "DATE": (fieldType, data, axisName, defaultOne, autoFill) => {


        if (data.length < 2) {
            // don't fill
            return data
        }
        // 先判断是顺序还是逆序;
        // 日期 aggregate/grouping 后一般是 “YYYY-MM-DD"
        const parse = value => dayjs(value, "YYYY-MM-DD")

        function isAscending() {
            const d1 = parseDate(data[0][axisName]);
            const d2 = parseDate(data[1][axisName]);
            return d1.isBefore(d2);
        }

        // 
        function fillTheData(ascendingData) {

            // 1. fill the gaps
            // reduce 数据，通过检测上一个数据跟这一个数据的距离，补充中间的空隙：
            const gapFilled = ascendingData.reduce((acc, item) => {
                const lastOne = acc.length > 0 ? acc[acc.length - 1] : undefined;
                if (lastOne) {
                    const lastOneDate = parseDate(lastOne[axisName]);
                    const thisOneDate = parseDate(item[axisName]);

                    // 看差多少单位
                    const diff = thisOneDate.diff(lastOneDate, 'day');
                    let toFill = [];
                    // 如果 diff = 1，则不会填充：
                    for (let i = 0; i < diff - 1; i++) {
                        const date = lastOneDate.add(i + 1, "day").format("YYYY-MM-DD");
                        toFill.push({
                            ...defaultOne,
                            [axisName]: date,
                        })
                    }
                    return [
                        ...acc,
                        ...toFill,
                        item
                    ]

                } else {
                    return [item]
                }
            }, [])

            // 2. fill till now
            // 检查最后面的那个跟今天对比，如果小于的话则 fill
            function fillTillNow(ascendingData) {
                if (autoFill.tillNow) {
                    const lastOne = ascendingData[ascendingData.length - 1];
                    const lastOneDate = parseDate(lastOne[axisName]);
                    const today = dayjs();;
                    // 看差多少单位
                    const diff = today.diff(lastOneDate, 'day');
                    let toFill = [];
                    // 如果 diff <= 0，则不会填充：
                    for (let i = 0; i < diff; i++) {
                        const date = lastOneDate.add(i + 1, "day").format("YYYY-MM-DD");
                        toFill.push({
                            ...defaultOne,
                            [axisName]: date,
                        })
                    }
                    return [
                        ...ascendingData,
                        ...toFill,
                    ]
                }
                return ascendingData
            }

            const filledTillNow = fillTillNow(gapFilled);

            // 3. fill for count
            function fillForCount(ascendingData) {
                /// TODO 暂时没实现
                return ascendingData
            }

            return fillForCount(filledTillNow);
        }

        if (isAscending()) {
            return fillTheData(data)
        } else {
            return fillTheData(data.reverse()).reverse()
        }

    }
};

const TypeAutoFillers = {

}

const FuncDefaultValues = {
    "COUNT": 0,
    "COUNT_DISTINCT": 0
};

const TypeDefaultValues = {
    "INT": 0,
    "Float": 0
};

function getDefaultValue(fieldType, funcName) {
    if (FuncDefaultValues[funcName] !== undefined) {
        return FuncDefaultValues[funcName];
    } else if (TypeDefaultValues[fieldType] !== undefined) {
        return TypeDefaultValues[fieldType]
    } else {
        return undefined
    }
}

export function autoFillValues(data, autoFill, axisName, fieldSettings) {

    const axisFieldSetting = fieldSettings[axisName];

    if (autoFill && axisFieldSetting) {
        // first, we should construct the value to be filled:
        const defaultOne = Object.keys(fieldSettings).reduce((acc, key) => {
            if (key == axisName) {
                // axis doesn't need to be here:
                return acc
            } else {
                // get default value, it must be presented in default value:
                const [_fieldName, fieldType, funcName, _args] = fieldSettings[key];
                const defaultValue = getDefaultValue(fieldType, funcName);
                if (defaultValue !== undefined) {
                    return {
                        ...acc,
                        [key]: defaultValue
                    }
                } else {
                    return acc
                }
            }
        }, {})

        const [_fieldName, fieldType, funcName, _funcArgs] = axisFieldSetting;


        const funcFiller = FuncAutoFillers[funcName];
        if (funcFiller) {
            return funcFiller(fieldType, data, axisName, defaultOne, autoFill)
        }
        const typeFiller = TypeAutoFillers[fieldType];
        if (typeFiller) {
            return typeFiller(funcName, data, axisName, defaultOne, autoFill);
        }

        return data;
    }
    return data
}



// 
const FuncSorters = {
    'DATE': (fieldType, data, axisName) => {
        // 要改成 ascending:
        if(data.length <= 1) {
            return data
        }
        return data.sort((a, b) => {
            const aDate = parseDate(a[axisName]);
            const bDate = parseDate(b[axisName]);
            if(aDate.isBefore(bDate)) {
                return -1 
            } else if(bDate.isBefore(aDate)) {
                return 1
            } else {
                return 0
            }
        })
    }
}
const TypeSorters = {

}

export function autoSortValues(data, fieldSetting, axisName) {

    const [_fieldName, fieldType, funcName, _funcArgs] = fieldSetting;
    const funcSorter = FuncSorters[funcName];
    if (funcSorter) {
        return funcSorter(fieldType, data, axisName)
    }
    const typeSorter = TypeSorters[fieldType];
    if (typeSorter) {
        return typeFiller(funcName, data, axisName);
    }

    return data;
}
