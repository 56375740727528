// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../../bwax-js/ml/utils/json.bs.js");
var ClientEnv = require("bwax/clientEnv");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var WxApi$BwaxAdmin = require("../../re/legacy/binding/WxApi.bs.js");
var Backend$BwaxAdmin = require("../../../bwax-js/ml/backend.bs.js");
var DomUtils$BwaxAdmin = require("../../re/utils/DomUtils.bs.js");
var UrlQuery$BwaxAdmin = require("../../re/legacy/binding/UrlQuery.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var qWxMpJsConfig = "\n  query ($jsApiList: [String]!, $url: String!) {\n    wxMpJsConfig( jsApiList: $jsApiList, url: $url)\n  }\n";

function queryWxMpJsConfig(query_runner, url, jsApiList) {
  return Curry._3(query_runner, qWxMpJsConfig, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "url",
                        url
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "jsApiList",
                            jsApiList
                          ],
                          /* [] */0
                        ])
                    ])), undefined).then((function (text) {
                var result = Json$BwaxAdmin.parse(text);
                var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "data",
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "wxMpJsConfig",
                        /* [] */0
                      ])
                  ]);
                return Promise.resolve(Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeObject, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                      return Json$BwaxAdmin.get_by_path(partial_arg, param);
                                    }), result)));
              }));
}

var mut_createWxOrder = "\n  mutation ($input: CreateWxOrderInput!) {\n    createWxOrder(input: $input) {\n      wxOrder {\n        codeUrl\n        mwebUrl\n        jsPayArgs\n      }\n    }\n  }\n";

function createWxOrder(query_runner, orderId, tradeType) {
  var handle_result = function (text) {
    var result = Json$BwaxAdmin.parse(text);
    var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        "data",
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            "createWxOrder",
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "wxOrder",
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "codeUrl",
                    /* [] */0
                  ])
              ])
          ])
      ]);
    var codeUrl = Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                return Json$BwaxAdmin.get_by_path(partial_arg, param);
              }), result));
    var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        "data",
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            "createWxOrder",
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "wxOrder",
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "mwebUrl",
                    /* [] */0
                  ])
              ])
          ])
      ]);
    var mwebUrl = Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                return Json$BwaxAdmin.get_by_path(partial_arg$1, param);
              }), result));
    var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        "data",
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            "createWxOrder",
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "wxOrder",
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "jsPayArgs",
                    /* [] */0
                  ])
              ])
          ])
      ]);
    var jsPayArgs = Plate$BwaxAdmin.$$Option.and_then((function (param) {
            return Json$BwaxAdmin.get_by_path(partial_arg$2, param);
          }), result);
    return Promise.resolve(/* record */Caml_chrome_debugger.record([
                  "codeUrl",
                  "mwebUrl",
                  "jsPayArgs"
                ], [
                  codeUrl,
                  mwebUrl,
                  jsPayArgs
                ]));
  };
  return Curry._3(query_runner, mut_createWxOrder, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "input",
                        {
                          clientMutationId: new Date().getTime().toString(),
                          orderId: orderId,
                          tradeType: tradeType
                        }
                      ],
                      /* [] */0
                    ])), undefined).then(handle_result);
}

function initiate_pay(query_runner, orderId, onPayInitiated, onError) {
  var handle_error = function (error) {
    Backend$BwaxAdmin.add_log(query_runner, /* Error */3, /* :: */Caml_chrome_debugger.simpleVariant("::", [
            error,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                orderId,
                /* [] */0
              ])
          ]));
    return Curry._1(onError, error);
  };
  if (ClientEnv.isWeChat() && ClientEnv.isMobile()) {
    WxApi$BwaxAdmin.ready((function (param) {
            createWxOrder(query_runner, orderId, "JSAPI").then((function (param) {
                    var jsPayArgs = param[/* jsPayArgs */2];
                    if (jsPayArgs !== undefined) {
                      WxApi$BwaxAdmin.chooseWXPay(Caml_option.valFromOption(jsPayArgs), (function (param) {
                              return /* () */0;
                            }));
                    } else {
                      handle_error("微信支付: 未能获取支付参数");
                    }
                    return Promise.resolve(/* () */0);
                  }));
            return /* () */0;
          }));
    WxApi$BwaxAdmin.error((function (param) {
            return handle_error("微信 API 初始化失败\"");
          }));
    if (UrlQuery$BwaxAdmin.href !== undefined) {
      queryWxMpJsConfig(query_runner, UrlQuery$BwaxAdmin.href, /* array */["chooseWXPay"]).then((function (c) {
              if (c !== undefined) {
                Curry._1(onPayInitiated, undefined);
                WxApi$BwaxAdmin.config(Caml_option.valFromOption(c), false);
              } else {
                handle_error("微信 API 配置失败: 未能获取配置信息");
              }
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    } else {
      return handle_error("微信 API 配置失败: 未获取当前地址");
    }
  } else if (ClientEnv.isMobile()) {
    createWxOrder(query_runner, orderId, "MWEB").then((function (param) {
            var mwebUrl = param[/* mwebUrl */1];
            if (DomUtils$BwaxAdmin.$$window !== undefined) {
              if (mwebUrl !== undefined) {
                Curry._1(onPayInitiated, undefined);
                var tmp = Caml_option.valFromOption(DomUtils$BwaxAdmin.$$window).location;
                tmp.href = mwebUrl;
              } else {
                handle_error("微信支付: 未能获取支付 mweb 地址");
              }
            }
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  } else {
    createWxOrder(query_runner, orderId, "NATIVE").then((function (param) {
            var codeUrl = param[/* codeUrl */0];
            if (codeUrl !== undefined) {
              Curry._1(onPayInitiated, codeUrl);
            } else {
              handle_error("微信支付: 未能获取支付二维码");
            }
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  }
}

exports.qWxMpJsConfig = qWxMpJsConfig;
exports.queryWxMpJsConfig = queryWxMpJsConfig;
exports.mut_createWxOrder = mut_createWxOrder;
exports.createWxOrder = createWxOrder;
exports.initiate_pay = initiate_pay;
/* Json-BwaxAdmin Not a pure module */
