


export default function ShowModal(props) {

    return async params => {

        const { modal } = props
        const { key } = params
        if(key && modal && modal.showModal) {
            modal.showModal(key)
        }
    }
}