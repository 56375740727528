


export default function ShowModalContent(props) {

    return async params => {

        const { modal, renderWidget } = props
        const { content } = params

        if(content && modal && modal.showModalContent) {
            const e = renderWidget(content)
            modal.showModalContent(e)
        }
    }
}