//

import {
  EditorState,
  RichUtils,
  Modifier,
  ContentBlock,
  DefaultDraftBlockRenderMap,
  BlockMapBuilder,
  CharacterMetadata,
  genKey,
  SelectionState
} from 'draft-js';
import {
  OrderedMap,
  List,
  Map,
  Repeat
} from 'immutable';

export function getSelectedBlocksMap(editorState, selection) {
  const selectionState = selection ? selection : editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

/**
* Function returns collection of currently selected blocks.
*/
export function getSelectedBlocksList(editorState, selectionState) {
  return getSelectedBlocksMap(editorState, selectionState).toList();
}

/**
* Function returns the first selected block.
*/
export function getSelectedBlock(editorState, selectionState) {
  if (editorState) {
    return getSelectedBlocksList(editorState, selectionState).get(0);
  }
  return undefined;
}

export function getSelectedInlineStyles(editorState) {

  const selection = editorState.getSelection()

  const startOffset = selection.getStartOffset()
  const startKey = selection.getStartKey()

  const endOffset = selection.getEndOffset()
  const endKey = selection.getEndKey()


  const allSelectedStyles = getSelectedBlocksList(editorState).reduce((acc, b) => {

    const blockStart = startKey === b.getKey() ? startOffset : 0
    const blockEnd = endKey === b.getKey() ? endOffset : b.getLength()

    // accumulate the selected block in styles:
    let styles = {}
    for(let i = blockStart; i < blockEnd; i++) {
      const inlineStyles = b.getInlineStyleAt(i)
      inlineStyles.forEach(s => {
        styles[s] = true
      })
    }
    return {
      ...acc,
      ...styles
    }
  }, {})

  return Object.keys(allSelectedStyles)
}

export function mergeBlockData(editorState, selectionState, data, {noRedo} = {}) {
  const newContentState = Modifier.mergeBlockData(
    editorState.getCurrentContent(),
    selectionState || editorState.getSelection(),
    data
  )
  const newEditorState =
    noRedo ?
    EditorState.set(editorState, {currentContent: newContentState}) :
    EditorState.push(editorState, newContentState, 'change-block-data')

  return newEditorState
}

export function setBlockData(editorState, selectionState, data) {
  const newContentState = Modifier.setBlockData(
    editorState.getCurrentContent(),
    selectionState,
    data
  )

  const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data')
  return newEditorState
}

export function mergeCurrentBlockData(editorState, data) {
  const newContentState = Modifier.mergeBlockData(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    data
  )

  const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data')
  return newEditorState
}

export function setCurrentBlockData(editorState, data) {
  const newContentState = Modifier.setBlockData(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    data
  )

  const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data')
  return newEditorState
}





export function insertText(editorState, text) {

  const newContentState = Modifier.insertText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text
  )
  const newEditorState = EditorState.push(editorState, newContentState, "insert-characters")
  return newEditorState
}

export function removeSelectedBlocksStyle(editorState) {
  const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
  if(newContentState) {
    return EditorState.push(editorState, newContentState, 'change-block-type')
  } else {
    return editorState
  }

}

export function insertNewUnstyledBlock(editorState) {
  const newContentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  );
  const newEditorState = EditorState.push(editorState, newContentState, 'split-block');
  return removeSelectedBlocksStyle(newEditorState);
}

/// insert atomic block with data
export function insertAtomicBlockWithData(
  editorState,
  entityKey,
  character,
  data
) {
  /// most of the code is borrowed from draft-js code
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const afterRemoval = Modifier.removeRange(
    contentState,
    selectionState,
    'backward',
  );

  const targetSelection = afterRemoval.getSelectionAfter();
  const afterSplit = Modifier.splitBlock(afterRemoval, targetSelection);
  const insertionTarget = afterSplit.getSelectionAfter();

  const asAtomicBlock = Modifier.setBlockType(
    afterSplit,
    insertionTarget,
    'atomic',
  );

  const charData = CharacterMetadata.create({entity: entityKey});


  const blockKey = genKey()

  const fragmentArray = [
    new ContentBlock({
      key: genKey(),
      type: 'atomic',
      text: character,
      characterList: List(Repeat(charData, character.length)),
      data
    }),
    new ContentBlock({
      key: genKey(),
      type: 'unstyled',
      text: '',
      characterList: List(),
    }),
  ];

  const fragment = BlockMapBuilder.createFromArray(fragmentArray);

  const withAtomicBlock = Modifier.replaceWithFragment(
    asAtomicBlock,
    insertionTarget,
    fragment,
  );

  const newContent = withAtomicBlock.merge({
    selectionBefore: selectionState,
    selectionAfter: withAtomicBlock.getSelectionAfter().set('hasFocus', true),
  });

  return {
    editorState: EditorState.push(editorState, newContent, 'insert-fragment'),
    blockKey: insertionTarget.getStartKey()
  };
}


export function removeBlock(editorState, blockKey) {

  let content = editorState.getCurrentContent();

  const beforeKey = content.getKeyBefore(blockKey);
  const beforeBlock = content.getBlockForKey(beforeKey);

  // Note: if the focused block is the first block then it is reduced to an
  // unstyled block with no character
  if (beforeBlock === undefined) {
    const targetRange = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: 1,
    });
    // change the blocktype and remove the characterList entry with the sticker
    content = Modifier.removeRange(content, targetRange, 'backward');
    content = Modifier.setBlockType(
      content,
      targetRange,
      'unstyled'
    );
    const newState = EditorState.push(editorState, content, 'remove-range');

    // force to new selection
    const newSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: 0,
    });
    return EditorState.forceSelection(newState, newSelection);
  }

  const targetRange = new SelectionState({
    anchorKey: beforeKey,
    anchorOffset: beforeBlock.getLength(),
    focusKey: blockKey,
    focusOffset: 1,
  });

  content = Modifier.removeRange(content, targetRange, 'backward');
  const newState = EditorState.push(editorState, content, 'remove-range');

  // force to new selection
  const newSelection = new SelectionState({
    anchorKey: beforeKey,
    anchorOffset: beforeBlock.getLength(),
    focusKey: beforeKey,
    focusOffset: beforeBlock.getLength(),
  });
  return EditorState.forceSelection(newState, newSelection);
}




//// export
export function getBlockRect(blockKey) {
  //econsole.log(.getBoundingClientRect())

  const nodeList = document.querySelectorAll(`[data-offset-key="${blockKey}-0-0"]`)

  if(nodeList.length > 0) {
    const node = nodeList.item(0)

    return node.getBoundingClientRect()
  }
  return null

}
