// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var DictUtils$BwaxAdmin = require("../../../bwax-js/re/utils/DictUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var DataBook = require("bwax/core/store/DataBook");
var WechatConfig = require("bwax-ui/legacy/page/WechatConfig");

function useTimer(timer, getPageState, prepareActions) {
  var timerDef = timer !== undefined ? JSON.stringify(Caml_option.valFromOption(timer).node.nodeDef) : "";
  React.useEffect((function () {
          if (timer !== undefined) {
            var tm = Caml_option.valFromOption(timer);
            if (DataBook.isNodeHolder(tm)) {
              var theTimer = tm;
              var node = theTimer.node;
              var localData = theTimer.localData;
              var match = node.category;
              var timerNodes = match === "array" ? node.children : /* array */[node];
              var timers = /* record */Caml_chrome_debugger.record(["contents"], [[]]);
              var schedule = function (index, tn) {
                var task = function (param) {
                  var data = DictUtils$BwaxAdmin.mergeWith(localData, Curry._1(getPageState, /* () */0));
                  var value = DataBook.lookupValue(tn, data, /* array */["action"], { });
                  var cond = value.cond;
                  var interval = value.interval;
                  var action = value.action;
                  if (cond) {
                    Curry._2(prepareActions, action, /* () */0);
                  }
                  var t = setTimeout(task, interval);
                  Belt_Array.set(timers[0], index, t);
                  return /* () */0;
                };
                var data = DictUtils$BwaxAdmin.mergeWith(localData, Curry._1(getPageState, /* () */0));
                var value = DataBook.lookupValue(tn, data, /* array */["action"], { });
                var interval = value.interval;
                var delay = value.action;
                return setTimeout(task, Belt_Option.getWithDefault(delay, interval));
              };
              timers[0] = Belt_Array.mapWithIndex(timerNodes, schedule);
              return (function (param) {
                        return Belt_Array.forEach(timers[0], (function (t) {
                                      console.log("Clear timeout", t);
                                      clearTimeout(t);
                                      return /* () */0;
                                    }));
                      });
            } else {
              return ;
            }
          }
          
        }), /* array */[timerDef]);
  return /* () */0;
}

function useRedirect(redirect, routeTo) {
  React.useEffect((function () {
          if (redirect !== undefined) {
            Curry._1(routeTo, Caml_option.valFromOption(redirect).toUrl);
          }
          return ;
        }), /* array */[redirect]);
  return /* () */0;
}

function useWechat(wechat) {
  React.useEffect((function () {
          if (wechat !== undefined) {
            var share = Caml_option.valFromOption(wechat).share;
            WechatConfig.setupWechatShare(share);
          }
          return ;
        }), /* array */[wechat]);
  return /* () */0;
}

exports.useTimer = useTimer;
exports.useRedirect = useRedirect;
exports.useWechat = useWechat;
/* react Not a pure module */
