// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Dict$BwaxAdmin = require("../../../lib/bwax-js/ml/dict.bs.js");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../lib/bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var Lang_typing_print$BwaxAdmin = require("../../../lib/bwax-js/ml/lang/lang_typing_print.bs.js");

function node_with_name(name, node) {
  return /* record */Caml_chrome_debugger.record([
            "id",
            "node_type",
            "input_num",
            "label",
            "name",
            "value_type_category",
            "value_type"
          ], [
            node[/* id */0],
            node[/* node_type */1],
            node[/* input_num */2],
            node[/* label */3],
            name,
            node[/* value_type_category */5],
            node[/* value_type */6]
          ]);
}

function edge(source_id, target_id, target_handle_idx) {
  return /* record */Caml_chrome_debugger.record([
            "source_id",
            "target_id",
            "target_handle_idx"
          ], [
            source_id,
            target_id,
            target_handle_idx
          ]);
}

function get_type_category(t) {
  if (typeof t === "number" || t.tag !== /* Term_record */1) {
    return Lang_typing_print$BwaxAdmin.string_of_term(/* [] */0, t);
  } else {
    return "Record";
  }
}

function check_name_to_visualize(def) {
  var match = def[0];
  switch (match.tag | 0) {
    case /* Def_val */0 :
        var match$1 = match[0][0];
        if (typeof match$1 === "number" || match$1.tag) {
          return ;
        } else {
          return /* tuple */[
                  match$1[0],
                  match[1]
                ];
        }
    case /* Def_fun */1 :
        return /* tuple */[
                match[0],
                /* tuple */[
                  /* Lambda */Caml_chrome_debugger.variant("Lambda", 13, [
                      match[1],
                      match[2]
                    ]),
                  def[1]
                ]
              ];
    default:
      return ;
  }
}

function get_names_to_visualize(param) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (param) {
                    return param[0];
                  }), Plate$BwaxAdmin.List.keep_map(check_name_to_visualize, param[1])));
}

function make_node(t, id, node_type, label, input_num) {
  var value_type_category = get_type_category(t);
  return /* record */Caml_chrome_debugger.record([
            "id",
            "node_type",
            "input_num",
            "label",
            "name",
            "value_type_category",
            "value_type"
          ], [
            id,
            node_type,
            input_num,
            label,
            undefined,
            value_type_category,
            t
          ]);
}

function collect_nodes_and_edges(external_env, env, id, expr) {
  var t = expr[1];
  var alt = expr[0];
  var node = function (param, param$1, param$2, param$3) {
    return make_node(t, param, param$1, param$2, param$3);
  };
  var cn = node(id, /* N_not_supported */11, "", 0);
  var not_supported_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      cn,
      /* [] */0
    ]);
  var not_supported = /* tuple */[
    cn,
    not_supported_001,
    /* [] */0
  ];
  var collect_sub_nodes_and_edges = function (parent_node, sub_exprs) {
    var aggregate = function (param, current_expr) {
      var index = param[0];
      var match = collect_nodes_and_edges(external_env, env, id + ("_" + String(index)), current_expr);
      var current_edge_000 = /* source_id */match[0][/* id */0];
      var current_edge_001 = /* target_id */parent_node[/* id */0];
      var current_edge = /* record */Caml_chrome_debugger.record([
          "source_id",
          "target_id",
          "target_handle_idx"
        ], [
          current_edge_000,
          current_edge_001,
          index
        ]);
      return /* tuple */[
              index + 1 | 0,
              Pervasives.$at(param[1], match[1]),
              Pervasives.$at(param[2], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      current_edge,
                      match[2]
                    ]))
            ];
    };
    var match = Plate$BwaxAdmin.List.foldl(aggregate, /* tuple */[
          0,
          /* [] */0,
          /* [] */0
        ], sub_exprs);
    return /* tuple */[
            match[1],
            match[2]
          ];
  };
  var match;
  var exit = 0;
  if (typeof alt === "number") {
    match = alt === /* Unit */0 ? /* tuple */[
        node(id, /* N_unit */1, "()", 0),
        /* [] */0,
        /* [] */0
      ] : not_supported;
  } else {
    switch (alt.tag | 0) {
      case /* Tuple */0 :
          match = /* tuple */[
            node(id, /* N_tuple */4, "()", 0),
            /* [] */0,
            /* [] */0
          ];
          break;
      case /* List */1 :
          match = /* tuple */[
            node(id, /* N_list */5, "[]", Plate$BwaxAdmin.List.length(alt[0])),
            /* [] */0,
            /* [] */0
          ];
          break;
      case /* Record_get */4 :
          var name = alt[1];
          console.log("Record Get", name);
          var cn$1 = node(id, /* N_record_get */9, name, 1);
          var match$1 = collect_sub_nodes_and_edges(cn$1, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  alt[0],
                  /* [] */0
                ]));
          match = /* tuple */[
            cn$1,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                cn$1,
                match$1[0]
              ]),
            match$1[1]
          ];
          break;
      case /* Apply */7 :
          var get_nested_fn = function (_acc_args, _current_fn) {
            while(true) {
              var current_fn = _current_fn;
              var acc_args = _acc_args;
              var match = current_fn[0];
              if (typeof match === "number" || match.tag !== /* Apply */7) {
                return /* tuple */[
                        current_fn,
                        acc_args
                      ];
              } else {
                _current_fn = match[0];
                _acc_args = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    match[1],
                    acc_args
                  ]);
                continue ;
              }
            };
          };
          var match$2 = get_nested_fn(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  alt[1],
                  /* [] */0
                ]), alt[0]);
          var nested_fn = match$2[0];
          var match$3 = nested_fn[0];
          var args = match$2[1];
          var exit$1 = 0;
          if (typeof match$3 === "number" || match$3.tag !== /* Ref */16) {
            exit$1 = 2;
          } else {
            var cn$2 = node(id, /* N_apply_on_name */7, match$3[0], Plate$BwaxAdmin.List.length(args));
            var match$4 = collect_sub_nodes_and_edges(cn$2, args);
            match = /* tuple */[
              cn$2,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  cn$2,
                  match$4[0]
                ]),
              match$4[1]
            ];
          }
          if (exit$1 === 2) {
            var sub_exprs = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                nested_fn,
                args
              ]);
            var cn$3 = node(id, /* N_apply */6, "", Plate$BwaxAdmin.List.length(sub_exprs));
            var match$5 = collect_sub_nodes_and_edges(cn$3, sub_exprs);
            match = /* tuple */[
              cn$3,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  cn$3,
                  match$5[0]
                ]),
              match$5[1]
            ];
          }
          break;
      case /* Unop */8 :
          var cn$4 = node(id, /* N_unop */3, Lang_eval$BwaxAdmin.string_of_unop(alt[0]), 1);
          var match$6 = collect_sub_nodes_and_edges(cn$4, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  alt[1],
                  /* [] */0
                ]));
          match = /* tuple */[
            cn$4,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                cn$4,
                match$6[0]
              ]),
            match$6[1]
          ];
          break;
      case /* Binop */9 :
          var cn$5 = node(id, /* N_binop */2, Lang_eval$BwaxAdmin.string_of_binop(alt[0]), 2);
          var match$7 = collect_sub_nodes_and_edges(cn$5, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  alt[1],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      alt[2],
                      /* [] */0
                    ])
                ]));
          match = /* tuple */[
            cn$5,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                cn$5,
                match$7[0]
              ]),
            match$7[1]
          ];
          break;
      case /* If */10 :
          var cn$6 = node(id, /* N_if */8, "if", 3);
          var match$8 = collect_sub_nodes_and_edges(cn$6, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  alt[0],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      alt[1],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          alt[2],
                          /* [] */0
                        ])
                    ])
                ]));
          match = /* tuple */[
            cn$6,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                cn$6,
                match$8[0]
              ]),
            match$8[1]
          ];
          break;
      case /* Let */14 :
          var named_exprs = Plate$BwaxAdmin.List.keep_map(check_name_to_visualize, alt[0]);
          var match$9 = Plate$BwaxAdmin.List.foldl((function (param, param$1) {
                  var name = param$1[0];
                  var acc_env = param[0];
                  var match = collect_nodes_and_edges(external_env, acc_env, id + ("_" + name), param$1[1]);
                  var named_node = node_with_name(name, match[0]);
                  var updated_nodes = Plate$BwaxAdmin.List.map((function (n) {
                          if (n[/* id */0] === named_node[/* id */0]) {
                            return named_node;
                          } else {
                            return n;
                          }
                        }), match[1]);
                  return /* tuple */[
                          Dict$BwaxAdmin.$$String.insert(name, named_node, acc_env),
                          Pervasives.$at(param[1], updated_nodes),
                          Pervasives.$at(param[2], match[2])
                        ];
                }), /* tuple */[
                env,
                /* [] */0,
                /* [] */0
              ], named_exprs);
          var match$10 = collect_nodes_and_edges(external_env, match$9[0], id, alt[1]);
          match = /* tuple */[
            match$10[0],
            Pervasives.$at(match$9[1], match$10[1]),
            Pervasives.$at(match$9[2], match$10[2])
          ];
          break;
      case /* Literal */15 :
          var cn$7 = node(id, /* N_literal */0, Lang_eval$BwaxAdmin.string_of_literal(alt[0]), 0);
          match = /* tuple */[
            cn$7,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                cn$7,
                /* [] */0
              ]),
            /* [] */0
          ];
          break;
      case /* Ref */16 :
          var n = alt[0];
          var match$11 = Dict$BwaxAdmin.$$String.get(n, env);
          if (match$11 !== undefined) {
            match = /* tuple */[
              match$11,
              /* [] */0,
              /* [] */0
            ];
          } else {
            var match$12 = Dict$BwaxAdmin.$$String.get(n, external_env);
            if (match$12 !== undefined) {
              var node$1 = match$12;
              match = /* tuple */[
                node$1,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    node$1,
                    /* [] */0
                  ]),
                /* [] */0
              ];
            } else {
              var cn$8 = node_with_name(n, node(id, /* N_outer_ref */10, n, 0));
              match = /* tuple */[
                cn$8,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    cn$8,
                    /* [] */0
                  ]),
                /* [] */0
              ];
            }
          }
          break;
      case /* Record_get_optional */19 :
      case /* List_range */20 :
      case /* List_comprehension */21 :
      case /* Do_expr */22 :
          exit = 1;
          break;
      default:
        match = not_supported;
    }
  }
  if (exit === 1) {
    throw [
          Caml_builtin_exceptions.match_failure,
          /* tuple */[
            "lang_visualize.ml",
            145,
            4
          ]
        ];
  }
  return /* tuple */[
          match[0],
          match[1],
          match[2]
        ];
}

function make_graph(param, target_name) {
  var defs = param[1];
  var target = Plate$BwaxAdmin.List.find((function (param) {
          return param[0] === target_name;
        }), Plate$BwaxAdmin.List.keep_map(check_name_to_visualize, defs));
  var externals = Plate$BwaxAdmin.List.keep_map(check_name_to_visualize, defs);
  var external_env = Plate$BwaxAdmin.List.foldl((function (acc, param) {
          var n = param[0];
          var id = "ref::" + n;
          var cn = node_with_name(n, make_node(param[1][1], id, /* N_outer_ref */10, n, 0));
          return Dict$BwaxAdmin.$$String.insert(n, cn, acc);
        }), Dict$BwaxAdmin.$$String.empty(/* () */0), externals);
  var match;
  if (target !== undefined) {
    var expr = target[1];
    var prefix = target_name + "::";
    var match$1 = collect_nodes_and_edges(external_env, Dict$BwaxAdmin.$$String.empty(/* () */0), prefix + "node", expr);
    var id = prefix + "output";
    var t = expr[1];
    var value_type_category = get_type_category(t);
    var output_node = /* record */Caml_chrome_debugger.record([
        "id",
        "node_type",
        "input_num",
        "label",
        "name",
        "value_type_category",
        "value_type"
      ], [
        id,
        12,
        1,
        "",
        undefined,
        value_type_category,
        t
      ]);
    var output_edge_000 = /* source_id */match$1[0][/* id */0];
    var output_edge = /* record */Caml_chrome_debugger.record([
        "source_id",
        "target_id",
        "target_handle_idx"
      ], [
        output_edge_000,
        id,
        0
      ]);
    match = /* tuple */[
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          output_node,
          match$1[1]
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          output_edge,
          match$1[2]
        ])
    ];
  } else {
    match = /* tuple */[
      /* [] */0,
      /* [] */0
    ];
  }
  return /* tuple */[
          match[0],
          match[1]
        ];
}

function node_type_to_js(param) {
  switch (param) {
    case /* N_literal */0 :
        return "literal";
    case /* N_unit */1 :
        return "unit";
    case /* N_binop */2 :
        return "binop";
    case /* N_unop */3 :
        return "unop";
    case /* N_tuple */4 :
        return "tuple";
    case /* N_list */5 :
        return "list";
    case /* N_apply */6 :
        return "apply";
    case /* N_apply_on_name */7 :
        return "apply_on_name";
    case /* N_if */8 :
        return "if";
    case /* N_record_get */9 :
        return "record_get";
    case /* N_outer_ref */10 :
        return "ref";
    case /* N_not_supported */11 :
        return "unsupported";
    case /* N_output */12 :
        return "output";
    
  }
}

function node_to_js(node) {
  return {
          id: node[/* id */0],
          label: node[/* label */3],
          input_num: node[/* input_num */2],
          name: node[/* name */4],
          value_type: node[/* value_type */6],
          value_type_category: node[/* value_type_category */5],
          node_type: node_type_to_js(node[/* node_type */1])
        };
}

function edge_to_js(edge) {
  return {
          source_id: edge[/* source_id */0],
          target_id: edge[/* target_id */1],
          target_handle_idx: edge[/* target_handle_idx */2]
        };
}

function make_graph_to_js(param, name) {
  var match = make_graph(/* tuple */[
        param[0],
        param[1]
      ], name);
  var ns = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(node_to_js, match[0]));
  var es = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(edge_to_js, match[1]));
  return /* tuple */[
          ns,
          es
        ];
}

exports.node_with_name = node_with_name;
exports.edge = edge;
exports.get_type_category = get_type_category;
exports.check_name_to_visualize = check_name_to_visualize;
exports.get_names_to_visualize = get_names_to_visualize;
exports.make_node = make_node;
exports.collect_nodes_and_edges = collect_nodes_and_edges;
exports.make_graph = make_graph;
exports.node_type_to_js = node_type_to_js;
exports.node_to_js = node_to_js;
exports.edge_to_js = edge_to_js;
exports.make_graph_to_js = make_graph_to_js;
/* Dict-BwaxAdmin Not a pure module */
