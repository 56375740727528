// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function $caret$caret(x, y) {
  return /* Doc_cons */Caml_chrome_debugger.variant("Doc_cons", 0, [
            x,
            y
          ]);
}

function text(s) {
  return /* Doc_text */Caml_chrome_debugger.variant("Doc_text", 1, [s]);
}

function nest(i, doc) {
  var n = i < 0 ? 0 : i;
  return /* Doc_nest */Caml_chrome_debugger.variant("Doc_nest", 2, [
            n,
            doc
          ]);
}

var $$break = /* Doc_break */Caml_chrome_debugger.variant("Doc_break", 3, [" "]);

function break_with(s) {
  return /* Doc_break */Caml_chrome_debugger.variant("Doc_break", 3, [s]);
}

function delimit(s, flat_mode_option) {
  return /* Doc_delimiter */Caml_chrome_debugger.variant("Doc_delimiter", 5, [
            s,
            flat_mode_option
          ]);
}

function group(d) {
  return /* Doc_group */Caml_chrome_debugger.variant("Doc_group", 4, [d]);
}

function is_nil(_param) {
  while(true) {
    var param = _param;
    if (typeof param === "number") {
      if (param === /* Doc_nil */0) {
        return true;
      } else {
        return false;
      }
    } else if (param.tag === /* Doc_group */4) {
      _param = param[0];
      continue ;
    } else {
      return false;
    }
  };
}

function start_with_nest_break(_param) {
  while(true) {
    var param = _param;
    if (typeof param === "number") {
      return false;
    } else {
      switch (param.tag | 0) {
        case /* Doc_nest */2 :
            var tmp = param[1];
            if (typeof tmp === "number" || tmp.tag !== /* Doc_break */3) {
              return false;
            } else {
              return true;
            }
        case /* Doc_cons */0 :
        case /* Doc_group */4 :
            _param = param[0];
            continue ;
        default:
          return false;
      }
    }
  };
}

function start_with_must_break(_param) {
  while(true) {
    var param = _param;
    if (typeof param === "number") {
      if (param === /* Doc_must_break */1) {
        return true;
      } else {
        return false;
      }
    } else {
      switch (param.tag | 0) {
        case /* Doc_cons */0 :
            var first = param[0];
            if (typeof first === "number") {
              if (first !== 0) {
                return true;
              } else {
                _param = first;
                continue ;
              }
            } else {
              _param = first;
              continue ;
            }
        case /* Doc_group */4 :
            return start_with_nest_break(param[0]);
        default:
          return false;
      }
    }
  };
}

function end_with_must_break(_param) {
  while(true) {
    var param = _param;
    if (typeof param === "number") {
      if (param === /* Doc_must_break */1) {
        return true;
      } else {
        return false;
      }
    } else {
      switch (param.tag | 0) {
        case /* Doc_cons */0 :
            var match = param[0];
            if (typeof match === "number") {
              if (match !== 0) {
                return true;
              } else {
                _param = param[1];
                continue ;
              }
            } else {
              _param = param[1];
              continue ;
            }
        case /* Doc_group */4 :
            _param = param[0];
            continue ;
        default:
          return false;
      }
    }
  };
}

function $caret$pipe(x, y) {
  if (typeof x !== "number" && x.tag === /* Doc_break */3 && typeof y !== "number" && y.tag === /* Doc_break */3) {
    var s1 = x[0];
    if (s1 === y[0]) {
      return /* Doc_break */Caml_chrome_debugger.variant("Doc_break", 3, [s1]);
    }
    
  }
  if (is_nil(x)) {
    return y;
  } else if (is_nil(y)) {
    return x;
  } else if (start_with_nest_break(y) || start_with_must_break(y) || end_with_must_break(x)) {
    return /* Doc_cons */Caml_chrome_debugger.variant("Doc_cons", 0, [
              x,
              y
            ]);
  } else {
    return /* Doc_cons */Caml_chrome_debugger.variant("Doc_cons", 0, [
              x,
              /* Doc_cons */Caml_chrome_debugger.variant("Doc_cons", 0, [
                  $$break,
                  y
                ])
            ]);
  }
}

function mode_to_string(param) {
  if (param) {
    return "Break";
  } else {
    return "Flat";
  }
}

function indent(str) {
  var prefix = function (s) {
    return "\n  " + s;
  };
  return Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map(prefix, Plate$BwaxAdmin.Str.split("\n", str)));
}

function indent_by(n, str) {
  var prefix = function (s) {
    return "\n" + (Plate$BwaxAdmin.Str.repeat(n, " ") + s);
  };
  return Plate$BwaxAdmin.Str.join("", Plate$BwaxAdmin.List.map(prefix, Plate$BwaxAdmin.Str.split("\n", str)));
}

function print_simple_doc(param) {
  if (typeof param === "number") {
    return "<nil>\n";
  } else {
    switch (param.tag | 0) {
      case /* S_text */0 :
          return "<text>\n" + (param[0] + ("\n" + print_simple_doc(param[1])));
      case /* S_break */1 :
          return "<break>\n" + (param[0] + ("\n" + print_simple_doc(param[1])));
      case /* S_line */2 :
          return "(" + (String(param[0]) + (")\n" + print_simple_doc(param[1])));
      case /* S_force_line */3 :
          return "(" + (String(param[0]) + (")f\n" + print_simple_doc(param[1])));
      
    }
  }
}

function print_doc(param) {
  if (typeof param === "number") {
    if (param === /* Doc_nil */0) {
      return "nil\n";
    } else {
      return "must_break";
    }
  } else {
    switch (param.tag | 0) {
      case /* Doc_cons */0 :
          return ">>" + (indent(print_doc(param[0])) + ("\n" + (indent(print_doc(param[1])) + "\n<<\n")));
      case /* Doc_text */1 :
          return "text \"" + (param[0] + "\"");
      case /* Doc_nest */2 :
          return "nest " + (String(param[0]) + indent(print_doc(param[1])));
      case /* Doc_break */3 :
          return "break \"" + (param[0] + "\"");
      case /* Doc_group */4 :
          return "group " + indent(print_doc(param[0]));
      case /* Doc_delimiter */5 :
          var option_str = param[1];
          return "delimiter \"" + (param[0] + ("\"" + (
                      option_str !== undefined ? "\"" + (option_str + "\"") : ""
                    )));
      
    }
  }
}

function print_doc_list(z) {
  return Plate$BwaxAdmin.Str.join("\n\n", Plate$BwaxAdmin.List.map((function (param) {
                    return String(param[0]) + (" " + ((
                                param[1] === /* Flat */0 ? "FLAT" : "BREAK"
                              ) + ("\n" + print_doc(param[2]))));
                  }), z));
}

function is_newline(param) {
  if (typeof param === "number") {
    return false;
  } else {
    switch (param.tag | 0) {
      case /* S_line */2 :
      case /* S_force_line */3 :
          return true;
      default:
        return false;
    }
  }
}

function is_after_newline(param) {
  if (typeof param === "number" || param.tag) {
    return false;
  } else {
    return true;
  }
}

function doc_to_string(doc) {
  var layout = function (after_what, _param) {
    while(true) {
      var param = _param;
      if (typeof param === "number") {
        return "";
      } else {
        switch (param.tag | 0) {
          case /* S_text */0 :
              return param[0] + layout(/* After_nothing_special */0, param[1]);
          case /* S_break */1 :
              var doc = param[1];
              var break_text = param[0];
              if (typeof after_what === "number") {
                return break_text + layout(/* After_break */Caml_chrome_debugger.variant("After_break", 1, [break_text]), doc);
              } else if (after_what.tag) {
                if (after_what[0] === break_text) {
                  _param = doc;
                  continue ;
                } else {
                  return break_text + layout(/* After_break */Caml_chrome_debugger.variant("After_break", 1, [break_text]), doc);
                }
              } else {
                _param = doc;
                continue ;
              }
          case /* S_line */2 :
              var n = param[0];
              return "\n" + (Plate$BwaxAdmin.Str.repeat(n, " ") + layout(/* After_newline */Caml_chrome_debugger.variant("After_newline", 0, [n]), param[1]));
          case /* S_force_line */3 :
              var doc$1 = param[1];
              var n$1 = param[0];
              if (is_newline(doc$1) || is_after_newline(after_what)) {
                _param = doc$1;
                continue ;
              } else {
                return "\n" + (Plate$BwaxAdmin.Str.repeat(n$1, " ") + layout(/* After_newline */Caml_chrome_debugger.variant("After_newline", 0, [n$1]), doc$1));
              }
          
        }
      }
    };
  };
  return layout(/* After_nothing_special */0, doc);
}

function fits(_w, _doc_list) {
  while(true) {
    var doc_list = _doc_list;
    var w = _w;
    if (w < 0) {
      return false;
    } else if (doc_list) {
      var z = doc_list[1];
      var h = doc_list[0];
      var s;
      var m = h[1];
      var i = h[0];
      var exit = 0;
      var exit$1 = 0;
      var match = h[2];
      if (typeof match === "number") {
        if (match === /* Doc_nil */0) {
          _doc_list = z;
          continue ;
        } else {
          exit$1 = 3;
        }
      } else {
        switch (match.tag | 0) {
          case /* Doc_cons */0 :
              _doc_list = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    i,
                    m,
                    match[0]
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        i,
                        m,
                        match[1]
                      ],
                      z
                    ])
                ]);
              continue ;
          case /* Doc_text */1 :
              s = match[0];
              break;
          case /* Doc_nest */2 :
              _doc_list = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    i + match[0] | 0,
                    m,
                    match[1]
                  ],
                  z
                ]);
              continue ;
          case /* Doc_group */4 :
              _doc_list = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    i,
                    /* Flat */0,
                    match[0]
                  ],
                  z
                ]);
              continue ;
          default:
            exit$1 = 3;
        }
      }
      if (exit$1 === 3) {
        if (m) {
          var tmp = h[2];
          if (typeof tmp === "number" || tmp.tag === /* Doc_break */3) {
            return true;
          } else {
            exit = 2;
          }
        } else {
          var match$1 = h[2];
          if (typeof match$1 === "number") {
            exit = 2;
          } else if (match$1.tag === /* Doc_break */3) {
            s = match$1[0];
          } else {
            var match$2 = match$1[1];
            if (match$2 !== undefined) {
              _doc_list = z;
              _w = w - Plate$BwaxAdmin.Str.length(match$2) | 0;
              continue ;
            } else {
              exit = 2;
            }
          }
        }
      }
      if (exit === 2) {
        var match$3 = h[2];
        if (typeof match$3 === "number") {
          return false;
        } else {
          s = match$3[0];
        }
      }
      _doc_list = z;
      _w = w - Plate$BwaxAdmin.Str.length(s) | 0;
      continue ;
    } else {
      return true;
    }
  };
}

function format(w, k, _param) {
  while(true) {
    var param = _param;
    if (param) {
      var z = param[1];
      var h = param[0];
      var exit = 0;
      var s;
      var s$1;
      var m = h[1];
      var i = h[0];
      var exit$1 = 0;
      var match = h[2];
      if (typeof match === "number") {
        if (match === /* Doc_nil */0) {
          _param = z;
          continue ;
        } else {
          var nz;
          if (z) {
            var match$1 = z[0];
            nz = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  match$1[0],
                  /* Break */1,
                  match$1[2]
                ],
                z[1]
              ]);
          } else {
            nz = /* [] */0;
          }
          return /* S_force_line */Caml_chrome_debugger.variant("S_force_line", 3, [
                    i,
                    format(w, i, nz)
                  ]);
        }
      } else {
        switch (match.tag | 0) {
          case /* Doc_cons */0 :
              _param = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    i,
                    m,
                    match[0]
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        i,
                        m,
                        match[1]
                      ],
                      z
                    ])
                ]);
              continue ;
          case /* Doc_text */1 :
              s = match[0];
              exit = 1;
              break;
          case /* Doc_nest */2 :
              _param = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    i + match[0] | 0,
                    m,
                    match[1]
                  ],
                  z
                ]);
              continue ;
          case /* Doc_group */4 :
              var x = match[0];
              if (fits(w - k | 0, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          i,
                          /* Flat */0,
                          x
                        ],
                        z
                      ]))) {
                _param = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      i,
                      /* Flat */0,
                      x
                    ],
                    z
                  ]);
                continue ;
              } else {
                _param = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      i,
                      /* Break */1,
                      x
                    ],
                    z
                  ]);
                continue ;
              }
          case /* Doc_break */3 :
          case /* Doc_delimiter */5 :
              exit$1 = 3;
              break;
          
        }
      }
      if (exit$1 === 3) {
        if (m) {
          var tmp = h[2];
          if (typeof tmp !== "number") {
            if (tmp.tag === /* Doc_break */3) {
              return /* S_line */Caml_chrome_debugger.variant("S_line", 2, [
                        i,
                        format(w, i, z)
                      ]);
            } else {
              s$1 = h[2][0];
              exit = 2;
            }
          }
          
        } else {
          var match$2 = h[2];
          if (typeof match$2 !== "number") {
            if (match$2.tag === /* Doc_break */3) {
              s$1 = match$2[0];
              exit = 2;
            } else {
              var match$3 = match$2[1];
              if (match$3 !== undefined) {
                s = match$3;
                exit = 1;
              } else {
                s$1 = h[2][0];
                exit = 2;
              }
            }
          }
          
        }
      }
      switch (exit) {
        case 1 :
            return /* S_text */Caml_chrome_debugger.variant("S_text", 0, [
                      s,
                      format(w, k + Plate$BwaxAdmin.Str.length(s) | 0, z)
                    ]);
        case 2 :
            return /* S_break */Caml_chrome_debugger.variant("S_break", 1, [
                      s$1,
                      format(w, k + Plate$BwaxAdmin.Str.length(s$1) | 0, z)
                    ]);
        
      }
    } else {
      return /* S_nil */0;
    }
  };
}

function pretty(w, doc) {
  return doc_to_string(format(w, 0, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      0,
                      /* Flat */0,
                      /* Doc_group */Caml_chrome_debugger.variant("Doc_group", 4, [doc])
                    ],
                    /* [] */0
                  ])));
}

function prune_nil(_param) {
  while(true) {
    var param = _param;
    if (typeof param === "number") {
      if (param === /* Doc_nil */0) {
        return /* Doc_nil */0;
      } else {
        return /* Doc_must_break */1;
      }
    } else {
      switch (param.tag | 0) {
        case /* Doc_cons */0 :
            var doc = param[1];
            var could_be_nil = param[0];
            if (is_nil(could_be_nil)) {
              _param = doc;
              continue ;
            } else if (is_nil(doc)) {
              _param = could_be_nil;
              continue ;
            } else {
              return /* Doc_cons */Caml_chrome_debugger.variant("Doc_cons", 0, [
                        prune_nil(could_be_nil),
                        prune_nil(doc)
                      ]);
            }
        case /* Doc_text */1 :
            return /* Doc_text */Caml_chrome_debugger.variant("Doc_text", 1, [param[0]]);
        case /* Doc_nest */2 :
            return /* Doc_nest */Caml_chrome_debugger.variant("Doc_nest", 2, [
                      param[0],
                      prune_nil(param[1])
                    ]);
        case /* Doc_break */3 :
            return /* Doc_break */Caml_chrome_debugger.variant("Doc_break", 3, [param[0]]);
        case /* Doc_group */4 :
            return /* Doc_group */Caml_chrome_debugger.variant("Doc_group", 4, [prune_nil(param[0])]);
        case /* Doc_delimiter */5 :
            return /* Doc_delimiter */Caml_chrome_debugger.variant("Doc_delimiter", 5, [
                      param[0],
                      param[1]
                    ]);
        
      }
    }
  };
}

var empty = /* Doc_nil */0;

var must_break = /* Doc_must_break */1;

exports.$caret$caret = $caret$caret;
exports.empty = empty;
exports.text = text;
exports.nest = nest;
exports.$$break = $$break;
exports.break_with = break_with;
exports.delimit = delimit;
exports.group = group;
exports.must_break = must_break;
exports.is_nil = is_nil;
exports.start_with_nest_break = start_with_nest_break;
exports.start_with_must_break = start_with_must_break;
exports.end_with_must_break = end_with_must_break;
exports.$caret$pipe = $caret$pipe;
exports.mode_to_string = mode_to_string;
exports.indent = indent;
exports.indent_by = indent_by;
exports.print_simple_doc = print_simple_doc;
exports.print_doc = print_doc;
exports.print_doc_list = print_doc_list;
exports.is_newline = is_newline;
exports.is_after_newline = is_after_newline;
exports.doc_to_string = doc_to_string;
exports.fits = fits;
exports.format = format;
exports.pretty = pretty;
exports.prune_nil = prune_nil;
/* Plate-BwaxAdmin Not a pure module */
