// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("./utils/json.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxAdmin = require("./plate.bs.js");
var Lang_eval$BwaxAdmin = require("./lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function materialized_fields(entity) {
  return Pervasives.$at(entity[/* fields */2], Plate$BwaxAdmin.List.filter((function (f) {
                      return f[/* materialized */10];
                    }))(entity[/* virtual_fields */3]));
}

function backlink_fields(entity) {
  return Plate$BwaxAdmin.List.filter((function (f) {
                  return f[/* backlink */9];
                }))(entity[/* virtual_fields */3]);
}

function entity_without_fields(entity) {
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "fields",
            "virtual_fields",
            "interfaces"
          ], [
            entity[/* name */0],
            entity[/* key */1],
            0,
            0,
            entity[/* interfaces */4]
          ]);
}

function add_field_to_list(field, fields) {
  var match = Plate$BwaxAdmin.List.find((function (f) {
          return f[/* name */0] === field[/* name */0];
        }), fields);
  if (match !== undefined) {
    return fields;
  } else {
    return /* :: */Caml_chrome_debugger.simpleVariant("::", [
              field,
              fields
            ]);
  }
}

function entity_add_field(field, entity) {
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "fields",
            "virtual_fields",
            "interfaces"
          ], [
            entity[/* name */0],
            entity[/* key */1],
            add_field_to_list(field, entity[/* fields */2]),
            entity[/* virtual_fields */3],
            entity[/* interfaces */4]
          ]);
}

function entity_add_virtual_field(field, entity) {
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "fields",
            "virtual_fields",
            "interfaces"
          ], [
            entity[/* name */0],
            entity[/* key */1],
            entity[/* fields */2],
            add_field_to_list(field, entity[/* virtual_fields */3]),
            entity[/* interfaces */4]
          ]);
}

function entity_add_fields(param, entity) {
  return Plate$BwaxAdmin.List.foldr(entity_add_virtual_field, param[1], Plate$BwaxAdmin.List.foldr(entity_add_field, param[0], entity));
}

function data_type_without_fields(dt) {
  var t = dt[/* trait */2];
  var tmp;
  tmp = typeof t === "number" || t.tag !== /* C_record */1 ? t : /* C_record */Caml_chrome_debugger.variant("C_record", 1, [
        /* [] */0,
        /* [] */0
      ]);
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "trait"
          ], [
            dt[/* name */0],
            dt[/* key */1],
            tmp
          ]);
}

function data_type_add_fields(param, dt) {
  var match = dt[/* trait */2];
  if (typeof match === "number" || match.tag !== /* C_record */1) {
    return dt;
  } else {
    var nfs = Plate$BwaxAdmin.List.foldr(add_field_to_list, param[0], match[0]);
    var nvfs = Plate$BwaxAdmin.List.foldr(add_field_to_list, param[1], match[1]);
    return /* record */Caml_chrome_debugger.record([
              "name",
              "key",
              "trait"
            ], [
              dt[/* name */0],
              dt[/* key */1],
              Caml_chrome_debugger.variant("C_record", 1, [
                  nfs,
                  nvfs
                ])
            ]);
  }
}

function normalize_name(name) {
  return Plate$BwaxAdmin.Str.replace(" ", "_", Plate$BwaxAdmin.Str.replace("-", "_", name));
}

function opaque_entity_type_name(name, app_name) {
  return "E_" + (normalize_name(app_name) + ("__" + normalize_name(name)));
}

var Builder_exn = Caml_exceptions.create("Base_query_types-BwaxAdmin.Builder_exn");

function normalize_field_name(name) {
  var s = Plate$BwaxAdmin.Str.decap(name);
  var match = Plate$BwaxAdmin.Str.uncons(s);
  return normalize_name(match !== undefined && Plate$BwaxAdmin.Str.is_digit(match[0]) ? "_" + s : s);
}

function get_entity(entity_dict, name_or_key) {
  var entities = Plate$BwaxAdmin.List.assoc_vals(entity_dict);
  var find_by_name = function (n) {
    return Plate$BwaxAdmin.List.find((function (param) {
                  return param[/* name */0] === n;
                }), entities);
  };
  var found = Plate$BwaxAdmin.$$Option.otherwise((function (param) {
          var k = name_or_key;
          return Plate$BwaxAdmin.List.find((function (param) {
                        return param[/* key */1] === k;
                      }), entities);
        }), find_by_name(name_or_key));
  if (found !== undefined) {
    return found;
  } else {
    console.log(Curry._1(Plate$BwaxAdmin.List.to_array, entity_dict));
    var msg = "I cannot find `" + (name_or_key + "` entity.");
    throw [
          Builder_exn,
          msg
        ];
  }
}

function get_entity_name(entity_dict, name_or_key) {
  return get_entity(entity_dict, name_or_key)[/* name */0];
}

function try_to_get_data_type(data_type_dict, name_or_key) {
  var data_types = Plate$BwaxAdmin.List.assoc_vals(data_type_dict);
  var find_by_name = function (n) {
    return Plate$BwaxAdmin.List.find((function (param) {
                  return param[/* name */0] === n;
                }), data_types);
  };
  return Plate$BwaxAdmin.$$Option.otherwise((function (param) {
                var k = name_or_key;
                return Plate$BwaxAdmin.List.find((function (param) {
                              return param[/* key */1] === k;
                            }), data_types);
              }), find_by_name(name_or_key));
}

function get_data_type(data_type_dict, name_or_key) {
  var match = try_to_get_data_type(data_type_dict, name_or_key);
  if (match !== undefined) {
    return match;
  } else {
    console.log("Not find data type", name_or_key, Curry._1(Plate$BwaxAdmin.List.to_array, data_type_dict));
    throw [
          Builder_exn,
          "I cannot find `" + (name_or_key + "` data type.")
        ];
  }
}

function get_data_type_name(data_type_dict, name_or_key) {
  return get_data_type(data_type_dict, name_or_key)[/* name */0];
}

function join_field_lines(depth, lines) {
  var indent = Plate$BwaxAdmin.Str.repeat(depth, "  ");
  return "\n" + (indent + ("{ " + (Plate$BwaxAdmin.Str.join("\n" + (indent + ", "), lines) + ("\n" + (indent + "}\n")))));
}

function tname_of(name) {
  var n = normalize_name(name);
  return "E_" + n;
}

function dtname_of(data_type) {
  var n = normalize_name(data_type[/* name */0]);
  return "DT_" + n;
}

function criteria_tname(ename) {
  return "Criteria_" + normalize_name(ename);
}

function search_fields_tname(ename) {
  return "SearchFields_" + normalize_name(ename);
}

function sorting_tname(ename) {
  return "Sorting_" + normalize_name(ename);
}

function add_param_type(ename) {
  return "Adding_" + normalize_name(ename);
}

function update_param_type(ename) {
  return "Updating_" + normalize_name(ename);
}

function id_type(ename) {
  return "ID_" + normalize_name(ename);
}

function input_data_type_name(dtname) {
  return "Input_" + normalize_name(dtname);
}

function plain_entity_tname_of(name) {
  return "PE_" + normalize_name(name);
}

function plain_data_type_tname_of(name) {
  return "PDT_" + normalize_name(name);
}

function updates_of_entity_tname_of(name) {
  return "Updates_of_" + normalize_name(name);
}

function raw_entity_tname_of(name) {
  return "Raw_" + normalize_name(name);
}

function group_tname(ename) {
  return "Group_" + normalize_name(ename);
}

function aggregate_tname(ename) {
  return "Aggregate_" + normalize_name(ename);
}

function find_entity_field(entity, name) {
  var check_field = function (f) {
    return normalize_field_name(f[/* name */0]) === name;
  };
  return /* tuple */[
          Plate$BwaxAdmin.List.find(check_field, entity[/* fields */2]),
          Plate$BwaxAdmin.List.find(check_field, entity[/* virtual_fields */3])
        ];
}

function find_data_type_field(dt, name) {
  var check_field = function (f) {
    return normalize_field_name(f[/* name */0]) === name;
  };
  var match = dt[/* trait */2];
  var match$1;
  match$1 = typeof match === "number" || match.tag !== /* C_record */1 ? /* tuple */[
      /* [] */0,
      /* [] */0
    ] : /* tuple */[
      match[0],
      match[1]
    ];
  return /* tuple */[
          Plate$BwaxAdmin.List.find(check_field, match$1[0]),
          Plate$BwaxAdmin.List.find(check_field, match$1[1])
        ];
}

function js_to_aggregate(js) {
  var field = js.field;
  var func = js.func;
  var params = Curry._1(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.with_default(/* array */[], js.params));
  var alias_name = js.aliasName;
  return /* tuple */[
          field,
          func,
          params,
          alias_name
        ];
}

function js_to_aggregate_list(array) {
  return Plate$BwaxAdmin.List.map(js_to_aggregate, Curry._1(Plate$BwaxAdmin.List.from_array, array));
}

function js_to_grouping(js) {
  var field = js.field;
  var func = js.func;
  var params = Curry._1(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.with_default(/* array */[], js.params));
  var alias_name = js.aliasName;
  return /* tuple */[
          field,
          func,
          params,
          alias_name
        ];
}

function js_to_grouping_list(array) {
  return Plate$BwaxAdmin.List.map(js_to_grouping, Curry._1(Plate$BwaxAdmin.List.from_array, array));
}

function invalid_args(name) {
  throw [
        Builder_exn,
        "Invalid Args:" + name
      ];
}

var any_condition_ops = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "ne",
      /* tuple */[
        "ne",
        /* Single */0
      ]
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "eq",
          /* tuple */[
            "eq",
            /* Single */0
          ]
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "isIn",
              /* tuple */[
                "in",
                /* Plural */1
              ]
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "isNotIn",
                  /* tuple */[
                    "notIn",
                    /* Plural */1
                  ]
                ],
                /* [] */0
              ])
          ])
      ])
  ]);

var number_condition_ops = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "gt",
      /* tuple */[
        "gt",
        /* Single */0
      ]
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "gte",
          /* tuple */[
            "gte",
            /* Single */0
          ]
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "lt",
              /* tuple */[
                "lt",
                /* Single */0
              ]
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "lte",
                  /* tuple */[
                    "lte",
                    /* Single */0
                  ]
                ],
                /* [] */0
              ])
          ])
      ])
  ]);

var string_condition_ops = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "isLike",
      /* tuple */[
        "like",
        /* Single */0
      ]
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "isNotLike",
          /* tuple */[
            "notLike",
            /* Single */0
          ]
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "startsWith",
              /* tuple */[
                "startsWith",
                /* Single */0
              ]
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "endsWith",
                  /* tuple */[
                    "endsWith",
                    /* Single */0
                  ]
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "substring",
                      /* tuple */[
                        "substring",
                        /* Single */0
                      ]
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "regexp",
                          /* tuple */[
                            "regexp",
                            /* Single */0
                          ]
                        ],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              "notRegexp",
                              /* tuple */[
                                "notRegexp",
                                /* Single */0
                              ]
                            ],
                            /* [] */0
                          ])
                      ])
                  ])
              ])
          ])
      ])
  ]);

var plural_condition_ops = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "ne",
      /* tuple */[
        "ne",
        /* Plural */1
      ]
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "eq",
          /* tuple */[
            "eq",
            /* Plural */1
          ]
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "contains",
              /* tuple */[
                "contains",
                /* Single */0
              ]
            ],
            /* [] */0
          ])
      ])
  ]);

function src_ops(st) {
  var partial_arg = Plate$BwaxAdmin.List.map;
  return (function (param) {
      return partial_arg((function (param) {
                    var single_type = st;
                    var op = param;
                    var name = op[0];
                    if (op[1][1]) {
                      return name + (": List " + single_type);
                    } else {
                      return name + (": " + single_type);
                    }
                  }), param);
    });
}

function pack(tname, plural, ops) {
  var prefix = "type alias " + (tname + ((
        plural ? "Plural" : ""
      ) + "Condition ="));
  var fieldLines_001 = src_ops(tname)(ops);
  var fieldLines = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      "exists: Bool",
      fieldLines_001
    ]);
  return prefix + join_field_lines(1, fieldLines);
}

var bool_condition = pack("Bool", false, any_condition_ops);

var int_condition = pack("Int", false, Pervasives.$at(any_condition_ops, number_condition_ops));

var float_condition = pack("Float", false, Pervasives.$at(any_condition_ops, number_condition_ops));

var date_condition = pack("Date", false, Pervasives.$at(any_condition_ops, number_condition_ops));

var string_condition = pack("String", false, Pervasives.$at(any_condition_ops, string_condition_ops));

var json_condition = pack("JSON", false, any_condition_ops);

var bool_plural_condition = pack("Bool", true, plural_condition_ops);

var int_plural_condition = pack("Int", true, plural_condition_ops);

var float_plural_condition = pack("Float", true, plural_condition_ops);

var date_plural_condition = pack("Date", true, plural_condition_ops);

var string_plural_condition = pack("String", true, plural_condition_ops);

var json_plural_condition = pack("JSON", true, plural_condition_ops);

var preset_condition_types_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    int_condition,
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        float_condition,
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            date_condition,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                string_condition,
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    json_condition,
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        bool_plural_condition,
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            int_plural_condition,
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                float_plural_condition,
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    date_plural_condition,
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        string_plural_condition,
                                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                            json_plural_condition,
                                            /* [] */0
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ])
      ])
  ]);

var preset_condition_types = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    bool_condition,
    preset_condition_types_001
  ]);

var all_ops = Pervasives.$at(any_condition_ops, Pervasives.$at(number_condition_ops, Pervasives.$at(string_condition_ops, plural_condition_ops)));

function to_condition(param) {
  var conds = param[1];
  var name = param[0];
  var is_leaf = function (binds) {
    var is_something_not_record = function (name) {
      var match = Plate$BwaxAdmin.List.assoc(name, binds);
      if (match !== undefined) {
        var tmp = match[0];
        if (typeof tmp === "number" || tmp.tag !== /* V_record */2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    return Plate$BwaxAdmin.List.some(is_something_not_record, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "exists",
                  Plate$BwaxAdmin.List.assoc_keys(all_ops)
                ]));
  };
  var match = conds[0];
  if (typeof match === "number") {
    return invalid_args("to_condition: not a record " + Lang_eval$BwaxAdmin.string_of_value(conds));
  } else if (match.tag === /* V_record */2) {
    var binds = match[0];
    if (is_leaf(binds)) {
      return Plate$BwaxAdmin.List.map((function (param) {
                    var v = param[1];
                    var k = param[0];
                    if (k === "exists") {
                      var match = v[0];
                      if (typeof match === "number" || match.tag !== /* V_literal */5) {
                        return invalid_args("to_condition - exists only accepts True / False");
                      } else {
                        var match$1 = match[0];
                        if (typeof match$1 === "number" || match$1.tag !== /* Bool */4) {
                          return invalid_args("to_condition - exists only accepts True / False");
                        } else if (match$1[0]) {
                          return /* tuple */[
                                  name,
                                  "ne",
                                  null
                                ];
                        } else {
                          return /* tuple */[
                                  name,
                                  "eq",
                                  null
                                ];
                        }
                      }
                    } else {
                      var decode_value = function (v) {
                        var match = v[0];
                        if (typeof match !== "number") {
                          switch (match.tag | 0) {
                            case /* V_list */1 :
                                return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map(decode_value, match[0]));
                            case /* V_literal */5 :
                                var match$1 = match[0];
                                if (typeof match$1 !== "number") {
                                  switch (match$1.tag | 0) {
                                    case /* Int */0 :
                                        return match$1[0];
                                    case /* Float */1 :
                                    case /* String */2 :
                                    case /* Bool */4 :
                                        return match$1[0];
                                    default:
                                      
                                  }
                                }
                                break;
                            case /* V_tagged_raw */8 :
                                if (match[0] === "JSON") {
                                  return match[1];
                                }
                                break;
                            case /* V_tagged_number */9 :
                                if (match[0] === "Date") {
                                  return new Date(match[1]).toJSON();
                                }
                                break;
                            case /* V_tagged_string */10 :
                                if (match[0] === "Color") {
                                  return match[1];
                                }
                                break;
                            default:
                              
                          }
                        }
                        return invalid_args("to_condition - not support value " + Lang_eval$BwaxAdmin.string_of_value(v));
                      };
                      var jv = decode_value(v);
                      var match$2 = Plate$BwaxAdmin.List.assoc(k, all_ops);
                      if (match$2 !== undefined) {
                        return /* tuple */[
                                name,
                                match$2[0],
                                jv
                              ];
                      } else {
                        return invalid_args("to_condition: unknown operation " + k);
                      }
                    }
                  }), binds);
    } else {
      var inners = Plate$BwaxAdmin.List.flat_map(to_condition, binds);
      return Plate$BwaxAdmin.List.map((function (param) {
                    return /* tuple */[
                            name + ("." + param[0]),
                            param[1],
                            param[2]
                          ];
                  }), inners);
    }
  } else {
    return invalid_args("to_condition: not a record " + Lang_eval$BwaxAdmin.string_of_value(conds));
  }
}

function extract_record(v) {
  var match = v[0];
  if (typeof match === "number") {
    return Pervasives.invalid_arg("to_criteria: not a record" + Lang_eval$BwaxAdmin.string_of_value(v));
  } else if (match.tag === /* V_record */2) {
    return match[0];
  } else {
    return Pervasives.invalid_arg("to_criteria: not a record" + Lang_eval$BwaxAdmin.string_of_value(v));
  }
}

function to_criteria_value(c) {
  var match = c[0];
  if (typeof match === "number") {
    return ;
  } else {
    switch (match.tag | 0) {
      case /* V_tagged */6 :
          switch (match[0]) {
            case "And" :
                var match$1 = match[1];
                if (match$1) {
                  var match$2 = match$1[0][0];
                  if (typeof match$2 === "number" || !(match$2.tag === /* V_record */2 && !match$1[1])) {
                    return ;
                  } else {
                    var conditions = Plate$BwaxAdmin.List.flat_map(to_condition, match$2[0]);
                    return /* C_and */Caml_chrome_debugger.variant("C_and", 0, [conditions]);
                  }
                } else {
                  return ;
                }
            case "Or" :
                var match$3 = match[1];
                if (match$3) {
                  var match$4 = match$3[0][0];
                  if (typeof match$4 === "number" || !(match$4.tag === /* V_list */1 && !match$3[1])) {
                    return ;
                  } else {
                    var partial_arg = Plate$BwaxAdmin.List.flat_map;
                    var partial_arg$1 = function (param) {
                      return partial_arg(to_condition, param);
                    };
                    var cl = Plate$BwaxAdmin.List.map((function (param) {
                            return Plate$BwaxAdmin.$at$great(extract_record, partial_arg$1, param);
                          }), match$4[0]);
                    return /* C_or */Caml_chrome_debugger.variant("C_or", 1, [cl]);
                  }
                } else {
                  return ;
                }
            default:
              return ;
          }
      case /* V_tagged_raw */8 :
          if (match[0] === "Criteria") {
            return /* C_raw */Caml_chrome_debugger.variant("C_raw", 2, [match[1]]);
          } else {
            return ;
          }
      default:
        return ;
    }
  }
}

function to_criteria(c) {
  var match = to_criteria_value(c);
  if (match !== undefined) {
    return match;
  } else {
    return invalid_args("to_criteria");
  }
}

function to_search_fields(maybe_prefix, v) {
  var match = v[0];
  var fields;
  fields = typeof match === "number" || match.tag !== /* V_record */2 ? /* [] */0 : Plate$BwaxAdmin.List.flat_map((function (param) {
            var v = param[1];
            var k = param[0];
            var match = Lang_eval$BwaxAdmin.as_float(v);
            if (match !== undefined) {
              return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          k,
                          match
                        ],
                        /* [] */0
                      ]);
            } else {
              return to_search_fields(k, v);
            }
          }), match[0]);
  if (maybe_prefix !== undefined) {
    var prefix = maybe_prefix;
    return Plate$BwaxAdmin.List.map((function (param) {
                  return /* tuple */[
                          prefix + ("." + param[0]),
                          param[1]
                        ];
                }), fields);
  } else {
    return fields;
  }
}

function to_search(s) {
  var keyword = Lang_eval$BwaxAdmin.get_string("keyword", s);
  var fields = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (param) {
              return to_search_fields(undefined, param);
            }), Lang_eval$BwaxAdmin.get_field("fields", s)));
  if (keyword !== undefined) {
    return /* tuple */[
            keyword,
            fields
          ];
  } else {
    return invalid_args("to_search: no keyword");
  }
}

function to_sorting_value(s) {
  var to_sorting_field = function (param) {
    var match = param[1][0];
    var name = param[0];
    if (typeof match === "number") {
      return invalid_args("to_sorting");
    } else {
      switch (match.tag | 0) {
        case /* V_record */2 :
            return Plate$BwaxAdmin.List.map((function (param) {
                          return /* tuple */[
                                  name + ("." + param[0]),
                                  param[1]
                                ];
                        }), Plate$BwaxAdmin.List.flat_map(to_sorting_field, match[0]));
        case /* V_tagged */6 :
            if (match[1]) {
              return invalid_args("to_sorting");
            } else {
              return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          name,
                          match[0]
                        ],
                        /* [] */0
                      ]);
            }
        default:
          return invalid_args("to_sorting");
      }
    }
  };
  var match = s[0];
  if (typeof match === "number") {
    return ;
  } else {
    switch (match.tag | 0) {
      case /* V_record */2 :
          return /* S_pairs */Caml_chrome_debugger.variant("S_pairs", 0, [Plate$BwaxAdmin.List.flat_map(to_sorting_field, match[0])]);
      case /* V_tagged_raw */8 :
          if (match[0] === "Sorting") {
            return /* S_raw */Caml_chrome_debugger.variant("S_raw", 1, [match[1]]);
          } else {
            return ;
          }
      default:
        return ;
    }
  }
}

function to_sorting(s) {
  var match = to_sorting_value(s);
  if (match !== undefined) {
    return match;
  } else {
    return invalid_args("to_sorting");
  }
}

function to_pagination(param) {
  var v_offset = param[1];
  var v_page_size = param[0];
  var match;
  if (v_page_size !== undefined) {
    var match$1 = v_page_size[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      match = invalid_args("to_pagination");
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag) {
        match = invalid_args("to_pagination");
      } else {
        var pz = match$2[0];
        if (v_offset !== undefined) {
          var match$3 = v_offset[0];
          if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
            match = invalid_args("to_pagination");
          } else {
            var match$4 = match$3[0];
            match = typeof match$4 === "number" || match$4.tag ? invalid_args("to_pagination") : /* tuple */[
                pz,
                match$4[0]
              ];
          }
        } else {
          match = /* tuple */[
            pz,
            undefined
          ];
        }
      }
    }
  } else if (v_offset !== undefined) {
    var match$5 = v_offset[0];
    if (typeof match$5 === "number" || match$5.tag !== /* V_literal */5) {
      match = invalid_args("to_pagination");
    } else {
      var match$6 = match$5[0];
      match = typeof match$6 === "number" || match$6.tag ? invalid_args("to_pagination") : /* tuple */[
          20,
          match$6[0]
        ];
    }
  } else {
    match = /* tuple */[
      20,
      undefined
    ];
  }
  return /* record */Caml_chrome_debugger.record([
            "page_size",
            "offset"
          ], [
            match[0],
            match[1]
          ]);
}

function pack_cond(param) {
  return Json$BwaxAdmin.json_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "field",
                  Json$BwaxAdmin.json_str(param[0])
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "op",
                      Json$BwaxAdmin.json_str(param[1])
                    ],
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          "value",
                          param[2]
                        ],
                        /* [] */0
                      ])
                  ])
              ]));
}

function pack_criteria_value(c) {
  switch (c.tag | 0) {
    case /* C_and */0 :
        return Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map(Json$BwaxAdmin.json_arr, Plate$BwaxAdmin.List.filter((function (l) {
                                return Plate$BwaxAdmin.List.length(l) > 0;
                              }))(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Plate$BwaxAdmin.List.map(pack_cond, c[0]),
                              /* [] */0
                            ]))));
    case /* C_or */1 :
        return Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map(Json$BwaxAdmin.json_arr, Plate$BwaxAdmin.List.filter((function (l) {
                                return Plate$BwaxAdmin.List.length(l) > 0;
                              }))(Plate$BwaxAdmin.List.map((function (cs) {
                                  return Plate$BwaxAdmin.List.map(pack_cond, cs);
                                }), c[0]))));
    case /* C_raw */2 :
        return c[0];
    
  }
}

function pack_criteria(mc) {
  if (mc !== undefined) {
    return Caml_option.some(pack_criteria_value(mc));
  }
  
}

function pack_search(msc) {
  var pack_search_field = function (param) {
    return Json$BwaxAdmin.json_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "field",
                    Json$BwaxAdmin.json_str(param[0])
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "weight",
                        param[1]
                      ],
                      /* [] */0
                    ])
                ]));
  };
  return Plate$BwaxAdmin.$$Option.map((function (param) {
                return Json$BwaxAdmin.json_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "keyword",
                                Json$BwaxAdmin.json_str(param[0])
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "fields",
                                    Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map(pack_search_field, param[1]))
                                  ],
                                  /* [] */0
                                ])
                            ]));
              }), msc);
}

function pack_sorting_value(s) {
  var pack = function (param) {
    return Json$BwaxAdmin.json_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "field",
                    Json$BwaxAdmin.json_str(param[0])
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "order",
                        Json$BwaxAdmin.json_str(param[1])
                      ],
                      /* [] */0
                    ])
                ]));
  };
  if (s.tag) {
    return s[0];
  } else {
    return Json$BwaxAdmin.json_arr(Plate$BwaxAdmin.List.map(pack, s[0]));
  }
}

function pack_sorting(ms) {
  return Plate$BwaxAdmin.$$Option.map(pack_sorting_value, ms);
}

function join_sorting(m_first, m_second) {
  if (m_first !== undefined) {
    var first = m_first;
    if (m_second !== undefined) {
      var mj = Json$BwaxAdmin.append_json_arr(pack_sorting_value(first), pack_sorting_value(m_second));
      return Plate$BwaxAdmin.$$Option.map((function (j) {
                    return /* S_raw */Caml_chrome_debugger.variant("S_raw", 1, [j]);
                  }), mj);
    } else {
      return first;
    }
  } else if (m_second !== undefined) {
    return m_second;
  } else {
    return ;
  }
}

function join_conds(j2, j1) {
  var arr1 = Plate$BwaxAdmin.$$Option.with_default(/* array */[], Js_json.decodeArray(j1));
  var arr2 = Plate$BwaxAdmin.$$Option.with_default(/* array */[], Js_json.decodeArray(j2));
  return $$Array.append(arr1, arr2);
}

function and_conds(cj1, cj2) {
  var co1 = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Json$BwaxAdmin.decode_list(cj1));
  var co2 = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Json$BwaxAdmin.decode_list(cj2));
  if (Plate$BwaxAdmin.List.length(co1) === 0) {
    return cj2;
  } else if (Plate$BwaxAdmin.List.length(co2) === 0) {
    return cj1;
  } else {
    return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.concat(Plate$BwaxAdmin.List.map((function (co2_el) {
                          return Plate$BwaxAdmin.List.map((function (param) {
                                        return join_conds(co2_el, param);
                                      }), co1);
                        }), co2)));
  }
}

var or_conds = join_conds;

function and_criteria(c1, c2) {
  var cj1 = pack_criteria_value(c1);
  var cj2 = pack_criteria_value(c2);
  return /* C_raw */Caml_chrome_debugger.variant("C_raw", 2, [and_conds(cj1, cj2)]);
}

function entity_for_normalized_name(entity_dict, normalized_name) {
  return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.find((function (param) {
                    return normalize_name(param[0]) === normalized_name;
                  }), entity_dict));
}

function entity_name_for_normalized_name(entity_dict, normalized_name) {
  return Plate$BwaxAdmin.$$Option.map((function (e) {
                return e[/* name */0];
              }), entity_for_normalized_name(entity_dict, normalized_name));
}

function data_type_for_normalized_name(data_type_dict, normalized_name) {
  return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.snd, Plate$BwaxAdmin.List.find((function (param) {
                    return normalize_name(param[0]) === normalized_name;
                  }), data_type_dict));
}

function data_type_name_for_normalized_name(data_type_dict, normalized_name) {
  return Plate$BwaxAdmin.$$Option.map((function (e) {
                return e[/* name */0];
              }), data_type_for_normalized_name(data_type_dict, normalized_name));
}

function check_prefixed_entity_name(entity_dict, target_tname, prefix) {
  if (Plate$BwaxAdmin.Str.starts_with(prefix, target_tname)) {
    var normalized_entity_name = Plate$BwaxAdmin.Str.drop_left(Plate$BwaxAdmin.Str.length(prefix), target_tname);
    return entity_for_normalized_name(entity_dict, normalized_entity_name);
  }
  
}

function check_prefixed_data_type_name(data_type_dict, target_tname, prefix) {
  if (Plate$BwaxAdmin.Str.starts_with(prefix, target_tname)) {
    var normalized_data_type_name = Plate$BwaxAdmin.Str.drop_left(Plate$BwaxAdmin.Str.length(prefix), target_tname);
    return data_type_for_normalized_name(data_type_dict, normalized_data_type_name);
  }
  
}

function to_query_param(params) {
  var match = params[0];
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    return invalid_args("to_query_param");
  } else {
    var binds = match[0];
    var criteria = Plate$BwaxAdmin.$$Option.map(to_criteria, Plate$BwaxAdmin.List.assoc("criteria", binds));
    var sorting = Plate$BwaxAdmin.$$Option.map(to_sorting, Plate$BwaxAdmin.List.assoc("sorting", binds));
    var search = Plate$BwaxAdmin.$$Option.map(to_search, Plate$BwaxAdmin.List.assoc("search", binds));
    var pagination = to_pagination(/* tuple */[
          Plate$BwaxAdmin.List.assoc("pageSize", binds),
          Plate$BwaxAdmin.List.assoc("offset", binds)
        ]);
    return /* tuple */[
            criteria,
            sorting,
            search,
            pagination
          ];
  }
}

function get_linked_opaque_entity_names(entity_dict, data_type_dict) {
  var entities = Plate$BwaxAdmin.List.assoc_vals(entity_dict);
  var data_types = Plate$BwaxAdmin.List.assoc_vals(data_type_dict);
  var collect_opaque_entity_from_dtype = function (acc, dtype) {
    if (typeof dtype === "number" || dtype.tag !== /* Entity_link_opaque */2) {
      return acc;
    } else {
      return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  dtype[0],
                  dtype[1]
                ],
                acc
              ]);
    }
  };
  var collect_opaque_entity_from_data_type = function (acc, data_type) {
    var match = data_type[/* trait */2];
    if (typeof match === "number" || match.tag !== /* C_record */1) {
      return acc;
    } else {
      return Plate$BwaxAdmin.List.foldl((function (acc, f) {
                    return collect_opaque_entity_from_dtype(acc, f[/* dtype */2]);
                  }), acc, Pervasives.$at(match[0], match[1]));
    }
  };
  var collect_opaque_entity_from_entity = function (acc, entity) {
    var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
    return Plate$BwaxAdmin.List.foldl((function (acc, f) {
                  return collect_opaque_entity_from_dtype(acc, f[/* dtype */2]);
                }), acc, all_fields);
  };
  var linked_opaque_entity_names = Plate$BwaxAdmin.List.make_set((function (param) {
          var b = param[1];
          var a = param[0];
          if (Plate$BwaxAdmin.fst(a) === Plate$BwaxAdmin.fst(b)) {
            return Plate$BwaxAdmin.snd(a) === Plate$BwaxAdmin.snd(b);
          } else {
            return false;
          }
        }), Pervasives.$at(Plate$BwaxAdmin.List.foldl(collect_opaque_entity_from_entity, /* [] */0, entities), Plate$BwaxAdmin.List.foldl(collect_opaque_entity_from_data_type, /* [] */0, data_types)));
  return Plate$BwaxAdmin.List.sort((function (param, param$1) {
                if (param[1] < param$1[1]) {
                  return -1;
                } else {
                  return 1;
                }
              }), linked_opaque_entity_names);
}

var date_condition_ops = number_condition_ops;

var default_page_size = 20;

exports.materialized_fields = materialized_fields;
exports.backlink_fields = backlink_fields;
exports.entity_without_fields = entity_without_fields;
exports.add_field_to_list = add_field_to_list;
exports.entity_add_field = entity_add_field;
exports.entity_add_virtual_field = entity_add_virtual_field;
exports.entity_add_fields = entity_add_fields;
exports.data_type_without_fields = data_type_without_fields;
exports.data_type_add_fields = data_type_add_fields;
exports.normalize_name = normalize_name;
exports.opaque_entity_type_name = opaque_entity_type_name;
exports.Builder_exn = Builder_exn;
exports.normalize_field_name = normalize_field_name;
exports.get_entity = get_entity;
exports.get_entity_name = get_entity_name;
exports.try_to_get_data_type = try_to_get_data_type;
exports.get_data_type = get_data_type;
exports.get_data_type_name = get_data_type_name;
exports.join_field_lines = join_field_lines;
exports.tname_of = tname_of;
exports.dtname_of = dtname_of;
exports.criteria_tname = criteria_tname;
exports.search_fields_tname = search_fields_tname;
exports.sorting_tname = sorting_tname;
exports.add_param_type = add_param_type;
exports.update_param_type = update_param_type;
exports.id_type = id_type;
exports.input_data_type_name = input_data_type_name;
exports.plain_entity_tname_of = plain_entity_tname_of;
exports.plain_data_type_tname_of = plain_data_type_tname_of;
exports.updates_of_entity_tname_of = updates_of_entity_tname_of;
exports.raw_entity_tname_of = raw_entity_tname_of;
exports.group_tname = group_tname;
exports.aggregate_tname = aggregate_tname;
exports.find_entity_field = find_entity_field;
exports.find_data_type_field = find_data_type_field;
exports.js_to_aggregate = js_to_aggregate;
exports.js_to_aggregate_list = js_to_aggregate_list;
exports.js_to_grouping = js_to_grouping;
exports.js_to_grouping_list = js_to_grouping_list;
exports.invalid_args = invalid_args;
exports.any_condition_ops = any_condition_ops;
exports.number_condition_ops = number_condition_ops;
exports.date_condition_ops = date_condition_ops;
exports.string_condition_ops = string_condition_ops;
exports.plural_condition_ops = plural_condition_ops;
exports.preset_condition_types = preset_condition_types;
exports.all_ops = all_ops;
exports.to_condition = to_condition;
exports.extract_record = extract_record;
exports.to_criteria_value = to_criteria_value;
exports.to_criteria = to_criteria;
exports.to_search_fields = to_search_fields;
exports.to_search = to_search;
exports.to_sorting_value = to_sorting_value;
exports.to_sorting = to_sorting;
exports.default_page_size = default_page_size;
exports.to_pagination = to_pagination;
exports.pack_cond = pack_cond;
exports.pack_criteria_value = pack_criteria_value;
exports.pack_criteria = pack_criteria;
exports.pack_search = pack_search;
exports.pack_sorting_value = pack_sorting_value;
exports.pack_sorting = pack_sorting;
exports.join_sorting = join_sorting;
exports.join_conds = join_conds;
exports.and_conds = and_conds;
exports.or_conds = or_conds;
exports.and_criteria = and_criteria;
exports.entity_for_normalized_name = entity_for_normalized_name;
exports.entity_name_for_normalized_name = entity_name_for_normalized_name;
exports.data_type_for_normalized_name = data_type_for_normalized_name;
exports.data_type_name_for_normalized_name = data_type_name_for_normalized_name;
exports.check_prefixed_entity_name = check_prefixed_entity_name;
exports.check_prefixed_data_type_name = check_prefixed_data_type_name;
exports.to_query_param = to_query_param;
exports.get_linked_opaque_entity_names = get_linked_opaque_entity_names;
/* bool_condition Not a pure module */
