
/// 暂时放在这里
export default {
    AuthEntityUser: (valueName, _entity, { withLink }) => {
        const q = ([name]) => `\${${valueName}.${name}}`
        const link = withLink ? "/entities/AuthEntityUser/records/" + q`id` : undefined;

        return {
            __switch__: [{
                cond: `\${${valueName}}`,
                value: {
                    type: "admin::AvatarAndLabel",
                    params: {
                        value: q`昵称`,
                        avatar: q`头像`,
                        link,
                        label: {
                            __switch__: [{
                                cond: q`昵称`,
                                value: q`昵称`,
                            }, {
                                cond: true,
                                value: {
                                    __def__: {
                                        简写: {
                                            __transform__: {
                                                value: q`UID`,
                                                type: "Slice",
                                                beginIndex: -6,
                                            }
                                        },
                                        __for__: `未获取昵称 ...\${简写}`
                                    }
                                }
                            }]
                        }
                    }
                }
            }, {
                cond: true,
                value: null
            }]
        }
    },


    PayEntityWxOrder: (valueName) => {
        const q = ([name]) => `\${${valueName}.${name}}`
        return {
            __def__: {
                状态: {
                    __transform__: {
                        value: q`订单状态`,
                        type: 'Dict',
                        dict: {
                            SUCCESS: "支付成功",
                            REFUND: "转入退款",
                            NOTPAY: "未支付",
                            CLOSED: "已关闭",
                            REVOKED: "已撤销（付款码支付）",
                            USERPAYING: "用户支付中（付款码支付）",
                            PAYERROR: "支付失败"
                        }
                    }
                },
                __for__: {
                    type: 'admin::Label',
                    params: {
                        value: `\${状态}`,
                        label: `\${状态}`,
                        color: {
                            __switch__: [
                                {
                                    cond: `\${状态 == "支付成功"}`,
                                    value: 'G60'
                                }, {
                                    cond: true,
                                    value: null
                                }
                            ]
                        }
                    }
                }
            }
        }

    }



}

/*


        if( value === null) {
            return ""
        }

        const { tradeState } = value
        // 状态描述
        const statuses = {
            SUCCESS: "支付成功",
            REFUND: "转入退款",
            NOTPAY: "未支付",
            CLOSED: "已关闭",
            REVOKED: "已撤销（付款码支付）",
            USERPAYING: "用户支付中（付款码支付",
            PAYERROR: "支付失败"
        }
        const style = tradeState === 'SUCCESS' ? {
            color: color('G60')
        } : {}

        return <span style={style}>{statuses[tradeState] || ""}</span>


*/