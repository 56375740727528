import React from 'react';

export default {
  'unstyled': <p />,
  'paragraph': <p />,
  'header-one': <h1 />,
  'header-two': <h2 />,
  'header-three': <h3 />,
  'header-four': <h4 />,
  'header-five': <h5 />,
  'header-six': <h6 />,
  'blockquote': <blockquote />,
  'unordered-list-item': {
    element: <li />,
    nest: <ul />
  },
  'ordered-list-item': {
    element: <li />,
    nest: <ol />
  },
  'atomic': <figure />
};
