// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../plate.bs.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");

function br(str) {
  return "(" + (str + ")");
}

function bracket_if_needed(dts, t) {
  var shortened = Lang_typing_base$BwaxAdmin.shorten(t);
  var str = string_of_term(dts, shortened);
  if (Lang_typing_base$BwaxAdmin.has_child(shortened) && !Lang_typing_base$BwaxAdmin.is_tuple(shortened)) {
    return br(str);
  } else {
    return str;
  }
}

function string_of_binds(dts, binds) {
  return Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                    return param[0] + (": " + string_of_term(dts, param[1]));
                  }), binds));
}

function string_of_record(dts, binds) {
  if (Plate$BwaxAdmin.List.length(binds) > 0) {
    return "{ " + (string_of_binds(dts, binds) + " }");
  } else {
    return "{}";
  }
}

function string_of_binds_tri(dts, binds) {
  return Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                    return param[0] + (": " + (string_of_term(dts, param[1]) + (
                                param[2] ? "!" : ""
                              )));
                  }), binds));
}

function string_of_record_tri(dts, binds) {
  if (Plate$BwaxAdmin.List.length(binds) > 0) {
    return "{ " + (string_of_binds_tri(dts, binds) + " }");
  } else {
    return "{}";
  }
}

function string_of_var(dts, v) {
  var print_i = function (i) {
    if (i === 0) {
      return "";
    } else {
      return String(i);
    }
  };
  var n = v[/* n */1];
  var var_type = v[/* var_type */0];
  var t = v[/* t */2];
  var vstr;
  if (typeof var_type === "number") {
    switch (var_type) {
      case /* Any */0 :
          vstr = "v" + print_i(n);
          break;
      case /* Number */1 :
          vstr = "number" + print_i(n);
          break;
      case /* Appendable */2 :
          vstr = "appendable" + print_i(n);
          break;
      case /* Comparable */3 :
          vstr = "comparable" + print_i(n);
          break;
      case /* Compappend */4 :
          vstr = "compappend" + print_i(n);
          break;
      
    }
  } else {
    switch (var_type.tag | 0) {
      case /* Recordvar */0 :
          vstr = "{ r" + (print_i(n) + (" | " + (string_of_binds(dts, var_type[0]) + " }")));
          break;
      case /* Record_readonly */1 :
          vstr = string_of_record(dts, var_type[1]) + (" # " + (var_type[0] + print_i(n)));
          break;
      case /* Record_readonly_of */2 :
          vstr = string_of_record(dts, var_type[1]) + (" # " + string_of_record_tri(dts, var_type[0]));
          break;
      case /* Record_limited */3 :
          vstr = "< " + Plate$BwaxAdmin.Str.join("; ", Plate$BwaxAdmin.List.map((function (param) {
                      return string_of_term(dts, param);
                    }), var_type[0]));
          break;
      case /* Record_writeonly */4 :
          vstr = "~ " + (var_type[0] + (" " + (Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map((function (param) {
                            return bracket_if_needed(dts, param);
                          }), var_type[1])) + (" " + print_i(n)))));
          break;
      case /* Record_writeonly_of */5 :
          vstr = "~ " + string_of_record_tri(dts, var_type[0]);
          break;
      case /* Any_record_readonly */6 :
          vstr = string_of_record(dts, var_type[1]) + (" # " + (string_of_term(dts, var_type[0]) + (" [" + (print_i(n) + "]"))));
          break;
      case /* Record_writeonly_optional */7 :
          vstr = "~~ " + (var_type[0] + (" " + (Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map((function (param) {
                            return bracket_if_needed(dts, param);
                          }), var_type[1])) + (" " + print_i(n)))));
          break;
      case /* Opaqued */8 :
          vstr = "~";
          break;
      
    }
  }
  var suffix = typeof t === "number" ? (
      t !== 0 ? "" : " of " + string_of_term(dts, t)
    ) : " of " + string_of_term(dts, t);
  return vstr + suffix;
}

function string_of_subset(dts, name) {
  var _a = Plate$BwaxAdmin.List.assoc(name, dts);
  if (_a !== undefined) {
    var match = _a;
    if (typeof match === "number" || match.tag !== /* Alias */1) {
      return ;
    } else {
      var match$1 = match[0];
      if (match$1[0]) {
        return ;
      } else {
        var vt = match$1[1];
        if (typeof vt === "number" || vt.tag !== /* Var */2) {
          return ;
        } else {
          var tmp = vt[0][/* var_type */0];
          if (typeof tmp === "number" || tmp.tag !== /* Record_readonly */1) {
            return ;
          } else {
            return string_of_term(dts, vt);
          }
        }
      }
    }
  }
  
}

function string_of_term(dts, t) {
  var shortened = Lang_typing_base$BwaxAdmin.shorten(t);
  if (typeof shortened === "number") {
    if (shortened === /* No_term */0) {
      return "<NO-TYPE>";
    } else {
      return "<UNKNOWN>";
    }
  } else {
    switch (shortened.tag | 0) {
      case /* Term */0 :
          var l = shortened[1];
          var name = shortened[0];
          if (name === Lang_typing_base$BwaxAdmin.arrow_name) {
            if (l) {
              var match = l[1];
              if (match && !match[1]) {
                var a = l[0];
                var a_str;
                a_str = typeof a === "number" || a.tag || Lang_typing_base$BwaxAdmin.arrow_name !== a[0] ? string_of_term(dts, a) : br(string_of_term(dts, a));
                return a_str + (" -> " + string_of_term(dts, match[0]));
              }
              
            }
            return Plate$BwaxAdmin.Str.join(" -> ", Plate$BwaxAdmin.List.map((function (param) {
                              return bracket_if_needed(dts, param);
                            }), l));
          } else if (name === Lang_typing_base$BwaxAdmin.tuple_name) {
            var inner = Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                        return string_of_term(dts, param);
                      }), l));
            return "( " + (inner + " )");
          } else if (Plate$BwaxAdmin.List.length(l) > 0) {
            return name + (" " + Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map((function (param) {
                                return bracket_if_needed(dts, param);
                              }), l)));
          } else {
            var match$1 = string_of_subset(dts, name);
            if (match$1 !== undefined) {
              return br(name + (" @ " + match$1));
            } else {
              return name;
            }
          }
          break;
      case /* Term_record */1 :
          return string_of_record_tri(dts, shortened[0]);
      case /* Var */2 :
          return string_of_var(dts, shortened[0]);
      
    }
  }
}

function string_of_scheme(dts, param) {
  var t = param[1];
  var vs = param[0];
  if (Plate$BwaxAdmin.List.length(vs) === 0) {
    return string_of_term(dts, t);
  } else {
    return "∀ " + (Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                        return string_of_var(dts, param);
                      }), vs)) + (" || " + string_of_term(dts, t)));
  }
}

function string_of_type_info(dts, param) {
  if (typeof param === "number") {
    if (param === /* Special */0) {
      return "Special";
    } else {
      return "Dummy";
    }
  } else {
    switch (param.tag | 0) {
      case /* Just */0 :
          return "Just: " + string_of_scheme(dts, param[0]);
      case /* Alias */1 :
          return "Alias: " + string_of_scheme(dts, param[0]);
      case /* Opaque */2 :
          return "Opaque";
      
    }
  }
}

function string_of_an_equation(dts, param) {
  return string_of_var(dts, param[0]) + ("  ->  " + string_of_term(dts, param[1]));
}

function string_of_subst(dts, subst) {
  return Plate$BwaxAdmin.Str.join("\n", Plate$BwaxAdmin.List.map((function (param) {
                    return string_of_an_equation(dts, param);
                  }), subst));
}

function pad(indents) {
  return Plate$BwaxAdmin.Str.repeat(indents, "  ");
}

function is_complex_form(t) {
  if (Lang_typing_base$BwaxAdmin.is_tuple(t) || Lang_typing_base$BwaxAdmin.is_arrow(t)) {
    return true;
  } else {
    return Lang_typing_base$BwaxAdmin.is_record(t);
  }
}

function print_term_with_bracket(start_with_same_line, indents, t) {
  var shortened = Lang_typing_base$BwaxAdmin.shorten(t);
  var str = print_term(start_with_same_line, indents, shortened);
  if (Lang_typing_base$BwaxAdmin.has_child(shortened) && !Lang_typing_base$BwaxAdmin.is_tuple(shortened)) {
    return "(" + (str + ")");
  } else {
    return str;
  }
}

function print_arrow(start_with_same_line, indents, ts) {
  if (ts) {
    var match = ts[1];
    if (match && !match[1]) {
      var r = match[0];
      var a = ts[0];
      var print_arg = function (t) {
        if (typeof t !== "number" && !t.tag && Lang_typing_base$BwaxAdmin.arrow_name === t[0]) {
          return br(print_term(true, indents + 1 | 0, t));
        }
        return print_term(true, indents + 1 | 0, t);
      };
      if (is_complex_form(a) || is_complex_form(r)) {
        return (
                start_with_same_line ? "" : "\n" + Plate$BwaxAdmin.Str.repeat(indents + 1 | 0, "  ")
              ) + (print_arg(a) + ("\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + ("-> " + print_term(true, indents, r)))));
      } else {
        return print_arg(a) + (" -> " + print_term(true, indents, r));
      }
    } else {
      return "<shouldn' be here: arrow has only two children>";
    }
  } else {
    return "<shouldn' be here: arrow has only two children>";
  }
}

function print_tuple(start_with_same_line, indents, ts) {
  if (Plate$BwaxAdmin.List.some(is_complex_form, ts)) {
    var prefix = (
      start_with_same_line ? "" : Plate$BwaxAdmin.Str.repeat(indents, "  ")
    ) + "( ";
    var suffix = "\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + ")");
    var partial_arg = indents + 1 | 0;
    var inner = Plate$BwaxAdmin.Str.join("\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + ", "), Plate$BwaxAdmin.List.map((function (param) {
                return print_term(false, partial_arg, param);
              }), ts));
    return prefix + (inner + suffix);
  } else {
    var inner$1 = Plate$BwaxAdmin.Str.join(", ", Plate$BwaxAdmin.List.map((function (param) {
                return print_term(false, indents, param);
              }), ts));
    return "( " + (inner$1 + " )");
  }
}

function print_term(start_with_same_line, indents, t) {
  var shortened = Lang_typing_base$BwaxAdmin.shorten(t);
  if (typeof shortened === "number") {
    if (shortened === /* No_term */0) {
      return "<NO-TYPE>";
    } else {
      return "<UNKNOWN>";
    }
  } else {
    switch (shortened.tag | 0) {
      case /* Term */0 :
          var l = shortened[1];
          var name = shortened[0];
          if (name === Lang_typing_base$BwaxAdmin.arrow_name) {
            return print_arrow(start_with_same_line, indents, l);
          } else if (name === Lang_typing_base$BwaxAdmin.tuple_name) {
            return print_tuple(start_with_same_line, indents, l);
          } else if (Plate$BwaxAdmin.List.length(l) > 0) {
            return name + (" " + Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map((function (param) {
                                return print_term_with_bracket(false, indents, param);
                              }), l)));
          } else {
            return name;
          }
      case /* Term_record */1 :
          return print_record_tri(start_with_same_line, indents, shortened[0]);
      case /* Var */2 :
          return print_var(start_with_same_line, indents, shortened[0]);
      
    }
  }
}

function print_record_tri(start_with_same_line, indents, binds) {
  if (Plate$BwaxAdmin.List.length(binds) > 0) {
    return (
            start_with_same_line ? "" : "\n" + Plate$BwaxAdmin.Str.repeat(indents, "  ")
          ) + ("{ " + (print_binds_tri(indents, binds) + ("\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + "}"))));
  } else {
    return "{}";
  }
}

function print_binds_tri(indents, binds) {
  return Plate$BwaxAdmin.Str.join("\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + ", "), Plate$BwaxAdmin.List.map((function (param) {
                    return param[0] + (": " + (print_term(false, indents + 2 | 0, param[1]) + (
                                param[2] ? "!" : ""
                              )));
                  }), binds));
}

function print_binds(indents, binds) {
  return Plate$BwaxAdmin.Str.join("\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + ", "), Plate$BwaxAdmin.List.map((function (param) {
                    return param[0] + (": " + print_term(false, indents + 2 | 0, param[1]));
                  }), binds));
}

function print_record(start_with_same_line, indents, binds) {
  if (Plate$BwaxAdmin.List.length(binds) > 0) {
    return (
            start_with_same_line ? "" : "\n" + Plate$BwaxAdmin.Str.repeat(indents, "  ")
          ) + ("{ " + (print_binds(indents + 1 | 0, binds) + ("\n" + (Plate$BwaxAdmin.Str.repeat(indents, "  ") + "}"))));
  } else {
    return "{}";
  }
}

function print_var(start_with_same_line, indents, v) {
  var print_i = function (i) {
    if (i === 0) {
      return "";
    } else {
      return String(i);
    }
  };
  var n = v[/* n */1];
  var var_type = v[/* var_type */0];
  var t = v[/* t */2];
  var vstr;
  if (typeof var_type === "number") {
    switch (var_type) {
      case /* Any */0 :
          vstr = "v" + print_i(n);
          break;
      case /* Number */1 :
          vstr = "number" + print_i(n);
          break;
      case /* Appendable */2 :
          vstr = "appendable" + print_i(n);
          break;
      case /* Comparable */3 :
          vstr = "comparable" + print_i(n);
          break;
      case /* Compappend */4 :
          vstr = "compappend" + print_i(n);
          break;
      
    }
  } else {
    switch (var_type.tag | 0) {
      case /* Recordvar */0 :
          vstr = "{ r" + (print_i(n) + (" | " + (print_binds(indents, var_type[0]) + " }")));
          break;
      case /* Record_readonly */1 :
          var binds = var_type[1];
          vstr = (
            Plate$BwaxAdmin.List.length(binds) > 0 ? print_record(start_with_same_line, indents, binds) + " " : ""
          ) + ("# " + var_type[0]);
          break;
      case /* Record_readonly_of */2 :
          var binds$1 = var_type[1];
          vstr = (
            Plate$BwaxAdmin.List.length(binds$1) > 0 ? print_record(false, indents, binds$1) + " " : ""
          ) + ("# " + print_record_tri(start_with_same_line, indents, var_type[0]));
          break;
      case /* Record_limited */3 :
          vstr = "< " + Plate$BwaxAdmin.Str.join("; ", Plate$BwaxAdmin.List.map((function (param) {
                      return print_term(false, indents, param);
                    }), var_type[0]));
          break;
      case /* Record_writeonly */4 :
          var partial_arg = indents + 1 | 0;
          vstr = "~ " + (var_type[0] + (" " + Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map((function (param) {
                          return print_term_with_bracket(true, partial_arg, param);
                        }), var_type[1]))));
          break;
      case /* Record_writeonly_of */5 :
          vstr = "~ " + print_record_tri(true, indents + 1 | 0, var_type[0]);
          break;
      case /* Any_record_readonly */6 :
          vstr = print_record(start_with_same_line, indents, var_type[1]) + (" # " + (print_term(false, indents, var_type[0]) + (" [" + (print_i(n) + "]"))));
          break;
      case /* Record_writeonly_optional */7 :
          var partial_arg$1 = indents + 1 | 0;
          vstr = "~~ " + (var_type[0] + (" " + Plate$BwaxAdmin.Str.join(" ", Plate$BwaxAdmin.List.map((function (param) {
                          return print_term_with_bracket(false, partial_arg$1, param);
                        }), var_type[1]))));
          break;
      case /* Opaqued */8 :
          vstr = "~";
          break;
      
    }
  }
  var suffix = typeof t === "number" ? (
      t !== 0 ? "" : " of " + print_term(start_with_same_line, indents, t)
    ) : " of " + print_term(start_with_same_line, indents, t);
  return vstr + suffix;
}

function print_scheme(indents, param) {
  return print_term(false, indents, param[1]);
}

function print_type_definition(param) {
  var type_info = param[1];
  var name = param[0];
  if (typeof type_info === "number") {
    return "";
  } else {
    switch (type_info.tag | 0) {
      case /* Just */0 :
          return "type " + (name + (" =" + (print_scheme(1, type_info[0]) + ";\n")));
      case /* Alias */1 :
          return "type alias " + (name + (" =" + (print_scheme(1, type_info[0]) + ";\n")));
      case /* Opaque */2 :
          return "type " + (name + " = opaque;");
      
    }
  }
}

function print_func_definition(param) {
  return param[0] + (": " + (print_term(false, 1, param[1]) + " = external;\n"));
}

exports.br = br;
exports.bracket_if_needed = bracket_if_needed;
exports.string_of_binds = string_of_binds;
exports.string_of_record = string_of_record;
exports.string_of_binds_tri = string_of_binds_tri;
exports.string_of_record_tri = string_of_record_tri;
exports.string_of_var = string_of_var;
exports.string_of_subset = string_of_subset;
exports.string_of_term = string_of_term;
exports.string_of_scheme = string_of_scheme;
exports.string_of_type_info = string_of_type_info;
exports.string_of_an_equation = string_of_an_equation;
exports.string_of_subst = string_of_subst;
exports.pad = pad;
exports.is_complex_form = is_complex_form;
exports.print_term_with_bracket = print_term_with_bracket;
exports.print_arrow = print_arrow;
exports.print_tuple = print_tuple;
exports.print_term = print_term;
exports.print_record_tri = print_record_tri;
exports.print_binds_tri = print_binds_tri;
exports.print_binds = print_binds;
exports.print_record = print_record;
exports.print_var = print_var;
exports.print_scheme = print_scheme;
exports.print_type_definition = print_type_definition;
exports.print_func_definition = print_func_definition;
/* Plate-BwaxAdmin Not a pure module */
