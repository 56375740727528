
import React, { useEffect, useState } from 'react'

import { Modal, Button } from 'antd';

import { Icon } from '@ant-design/compatible';

import get from 'lodash/get'

import './SelectNewsMaterialModal.less'

const PAGE_COUNT = 20

export default function SelectNewsMaterialModal (props) {

    const { visible, queryRunner, hideModal, newsSelect } = props

    const [ newsMaterials, setNewsMaterials ] = useState()
    const [ currentNews, setCurrentNews ] = useState()
    const [ hasNextPage, setHasNextPage ] = useState()
    const [ queryTimes, setQueryTimes ] = useState(0)

    useEffect(() => {
        (async () => {
            const news = await loadNews(queryRunner, { first: PAGE_COUNT })
            setNewsMaterials(news)
            setHasNextPage(news.pageInfo.hasNextPage)
            setQueryTimes(queryTimes + 1)
        })()
    }, [])

    function selected (news) {
        setCurrentNews(news.node.mediaId) /// 目前只能设置一篇图文消息
    }

    function onOK () {
        newsSelect(currentNews)
        hideModal()
        setCurrentNews(null)
    }

    function onCancel () {
        setCurrentNews(null)
        hideModal()
    }

    const footer = (
        <div>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type="primary" onClick={() => onOK()} disabled={!currentNews}>确认</Button>
        </div>
    )

    function loadMore (e) {
        const { scrollTop, scrollHeight, clientHeight } = e.target
        if(scrollHeight - (scrollTop + clientHeight) < 30 && hasNextPage ) {
            (async () => {
                const after = queryTimes * PAGE_COUNT
                const news = await loadNews(queryRunner, { first: PAGE_COUNT, after: after.toString() })
                setNewsMaterials({
                    ...newsMaterials,
                    edges: [ ...newsMaterials.edges, ...news.edges ]
                })
                setHasNextPage(news.pageInfo.hasNextPage)
                setQueryTimes(queryTimes + 1)
            })()
        }
    }

    return (
        <div>
            <Modal
                title="选择素材"
                open={visible}
                onCancel={() => onCancel()}
                footer={footer}
                width={960}
            >
                <div className="newsMaterials-content" onScroll={e => loadMore(e)}>
                    {
                        newsMaterials && newsMaterials.edges.length > 0 ? (
                            newsMaterials.edges.map(news => {
                                const imageUrl = get(news, 'node.thumbUrl', '')
                                return (
                                    <div key={news.node.id} onClick={() => selected(news)}>
                                        <img src={imageUrl ? imageUrl.replace('http://mmbiz.qpic.cn', '') : undefined}/>
                                        <div className="wxmp-news-title">
                                            <p>{news.node.title}</p>
                                        </div>
                                        {
                                            currentNews === news.node.mediaId ? (
                                                <div className="selected">
                                                    <Icon type="check"/>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                )
                            })
                        ) : (
                            <span>暂无图文素材</span>
                        )
                    }
                </div>
            </Modal>
        </div>
    )
}

const QueryWxMpMaterials = `
    query (
        $first: Float
        $after: String
    ) {
        listWxMpEntityNewsMaterial(first: $first, after: $after, sort: [{ field: "createdAt", order: DESC }]) {
            count
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    mediaId
                    title
                    digest
                    contentSourceUrl
                    thumbUrl
                    updatedAt
                }
            }
        }
    }
`

async function loadNews(queryRunner, { first, after }) {
    const resp = await queryRunner(QueryWxMpMaterials)({
        first,
        after
    })
    const { errors, data } = JSON.parse(resp)
    if(errors) {
        ////  TODO error handling
        console.error(errors);
        return undefined
    } else {
        return data.listWxMpEntityNewsMaterial
    }
}
