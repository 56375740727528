// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");

var cmp = Lang_eval$BwaxAdmin.compare_value;

var T = Belt_Id.MakeComparable({
      cmp: cmp
    });

function empty(param) {
  return Belt_Set.make(T);
}

function singleton(k) {
  return Belt_Set.add(Belt_Set.make(T), k);
}

function insert(k, set) {
  return Belt_Set.add(set, k);
}

function remove(k, set) {
  return Belt_Set.remove(set, k);
}

function remove_many(ks, set) {
  return Belt_Set.removeMany(set, Curry._1(Plate$BwaxAdmin.List.to_array, ks));
}

var is_empty = Belt_Set.isEmpty;

function member(k, set) {
  return Belt_Set.has(set, k);
}

function get(k, set) {
  return Belt_Set.get(set, k);
}

var size = Belt_Set.size;

function some(f, set) {
  return Belt_Set.some(set, f);
}

function every(f, set) {
  return Belt_Set.every(set, f);
}

var minimum = Belt_Set.minimum;

var maximum = Belt_Set.maximum;

var is_equal = Belt_Set.eq;

var to_list = Belt_Set.toList;

function from_list(l) {
  return Belt_Set.fromArray(Curry._1(Plate$BwaxAdmin.List.to_array, l), T);
}

function foldl(f, acc, set) {
  return Belt_Set.reduce(set, acc, f);
}

function map(f, set) {
  var acc = Belt_Set.make(T);
  return Belt_Set.reduce(set, acc, (function (acc, k) {
                var k$1 = Curry._1(f, k);
                return Belt_Set.add(acc, k$1);
              }));
}

function filter(f, set) {
  return Belt_Set.keep(set, f);
}

function partition(f, set) {
  return Belt_Set.partition(set, f);
}

var union = Belt_Set.union;

var intersect = Belt_Set.intersect;

var diff = Belt_Set.diff;

exports.T = T;
exports.empty = empty;
exports.singleton = singleton;
exports.insert = insert;
exports.remove = remove;
exports.remove_many = remove_many;
exports.is_empty = is_empty;
exports.member = member;
exports.get = get;
exports.size = size;
exports.some = some;
exports.every = every;
exports.minimum = minimum;
exports.maximum = maximum;
exports.is_equal = is_equal;
exports.to_list = to_list;
exports.from_list = from_list;
exports.foldl = foldl;
exports.map = map;
exports.filter = filter;
exports.partition = partition;
exports.union = union;
exports.intersect = intersect;
exports.diff = diff;
/* T Not a pure module */
