

import React, { useState } from 'react';


import { BiX } from 'react-icons/bi';

import { useDrop } from 'react-dnd'

import './StudioEditorArea.less';

import { Button } from 'rsuite';

import { getGlobalEventBus } from './EventBus';

import Modal from 'bwax-ui/ml/widget/impl/misc/Modal';

export default function EditorTabs(props) {

    const { currentItem, openedItems, openItem, setCurrentItem, closeItem, dirtyMarks, saveItem, errors } = props;

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: [
            "entity",
            "field",
            "virtual-field",
            "backlink-field",
            "data-interface",
            "admin-page",
            "page",
            "page-component",
            "page-fragment",
            "event-handler",
            "scheduled-event-handler",
        ],
        collect(monitor) {
            return {
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver()
            }
        },

        drop(item) {
            openItem(item);
        },
    }));


    function isActive(item) {
        return currentItem && (currentItem.itemType === item.itemType && currentItem.data.id === item.data.id)
    }

    const [confirmingClose, setConfirmingClose] = useState();

    function renderConfirmDialog() {

        const item = confirmingClose;

        function renderContent() {
            if (!item) return null;

            const itemName = item.getName();
            const itemKey = item.itemKey();

            const hasError = !!errors[itemKey];

            return (
                <div className="studio-dialog">
                    <div className="dialog-section">
                        <div className="prompt-message">
                            {hasError ?
                                <span>当前的修改有错误，你是否要放弃这次对“<b>{itemName}</b>”的修改，不予保存？</span> :
                                <span>关闭前，你要不要保存这次对“<b>{itemName}</b>”所做的修改？</span>
                            }
                        </div>

                    </div>
                    <div className="dialog-section">
                        <Button appearance="primary" disabled={hasError} onClick={async () => {
                            await saveItem(item);
                            closeItem(item);

                            setConfirmingClose(undefined);
                        }}>
                            保存
                        </Button>
                        <Button onClick={() => {
                            closeItem(item);
                            setConfirmingClose(undefined);
                        }}>
                            不保存
                        </Button>
                    </div>
                    <div className="dialog-section">
                        <Button onClick={() => {
                            setConfirmingClose(undefined);
                        }}>
                            取消
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <Modal {...{
                visible: !!confirmingClose,
                containerStyle: {
                    borderRadius: 4,
                    width: 200,
                },
                modalContent: renderContent()
            }} />
        )
    }

    const renderedNavs = openedItems.map(item => {

        const itemName = item.getName();
        const itemKey = item.itemKey();

        const { multitenantType } = item;

        const active = isActive(item);

        const hasError = !!errors[itemKey];

        // 因为 DefsCodeEditor 在有 error 时，不返回 codeText 等修改
        // 所以 dirty 的定义也包括有 error
        const isDirty = dirtyMarks[itemKey] || hasError;

        return (
            <div key={item.data && item.data.id ? item.data.id : item.itemType + "::" + itemName}
                className={
                    "editor-tab-nav" +
                    (active ? "" : " inactive") +
                    (isDirty ? " dirty" : "") +
                    (hasError ? " has-error" : "")
                }
                onClick={() => {
                    if (!active) {
                        setCurrentItem(item)
                    }
                }}
                onDoubleClick={() => {
                    if (!active) {
                        setCurrentItem(item)
                    }
                    // 
                    getGlobalEventBus().dispatch("expandToItem", { item });

                }}
            >
                {item.icon}
                <div>{itemName}</div>
                {multitenantType == "tenant" ?
                    (<div>
                        (t)
                    </div>) : null
                }
                <div className={"close-tab-btn"} onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isDirty) {
                        setConfirmingClose(item)
                    } else {
                        closeItem(item);
                    }
                }}>
                    <div className="dirty-mark"></div>
                    <BiX />
                </div>
            </div>
        )
    })

    return (
        <>
            <div className={"editor-tabs" + (canDrop && isOver ? " highlighted" : "")} ref={drop}>
                {renderedNavs}
            </div>
            {renderConfirmDialog()}
        </>
    )
}
