

import buildRecordSummary from './buildRecordSummary'

import AdminExt from 'bwax-ext/AdminExt'


export default function buildFieldSummary(field, ref, {
    withLink, /// link pattarn 
    allEntities,
    allDataTypes,
    params
}) {
    const { type } = field

    const builder = getBuilders(field, { allEntities, allDataTypes })[type]
    if (builder) {
        return builder(`${ref}`)
    }

    const customDisplayWidgets = Object.keys(AdminExt).reduce((acc, key) => {
        const ext = AdminExt[key]

        if (ext && ext.dataTypeWidgets && ext.dataTypeWidgets.displays) {
            return {
                ...acc,
                ...ext.dataTypeWidgets.displays
            }
        } else {
            return acc
        }

    }, {})

    const w = customDisplayWidgets[type] || displayWidgets(type)(field, { allEntities, allDataTypes, ref })


    const mergedParams = {
        ...params,
        value: `\${${ref}}`,
        linkPattern: withLink
    }

    const def = typeof (w) === 'string' ? { type: w, params: mergedParams } :
        (
            w.params ?
                { ...w, params: { ...mergedParams, ...w.params } } :
                { ...w, ...mergedParams }
        );

    return def;

}

const predefinedDisplayWidgets = {
    Number: (field, { allEntities, allDataTypes }) => ({
        type: 'admin::display::Number',
        decimals: field.options ? (field.options.decimals || 0) : 0,
    }),
    Boolean: () => ({
        type: 'admin::display::Boolean',
    }),
    DateTime: () => ({
        type: 'admin::display::DateTime',
        format: 'YYYY-MM-DD HH:mm',
    }),
    Date: () => ({
        type: 'admin::display::DateTime',
        format: 'YYYY-MM-DD'
    }),
    Image: () => "admin::display::PicturesWall",
    RichText: () => "admin::display::RichTextPreview",
    File: () => 'admin::display::AttachmentsPreview',
    JSON: () => 'admin::Display_JSON',
    JSONB: () => 'admin::Display_JSON',
    Select: (field) => {
        return {
            type: 'admin::display::Select',
            options: field.options ? field.options.options : []
        }
    },
    QRCode: () => "admin::Display_QRCode",
    /// ... others, TODO 考虑 extension 里的 DataType 的编辑性
    VirtualFieldDef: () => "admin::Display_JSON",
    EventHandlerDef: () => "admin::Display_JSON",


    FieldOptions: (field, { allEntities, allDataTypes, ref }) => {

        // ref must be value.字段选项
        const dataType = "${" + ref.split(".")[0] + ".字段类型}" 
        return {
            type: "admin::display::FieldOptions",
            params: {
                // 硬写进去的
                dataType 
            }
        }
    },

    FilterCondition: (field, { allEntities, allDataTypes }) => {

        const specifiedFields =
            field.options ? field.options.specifiedFields : null;

        let fieldItems = specifiedFields ? specifiedFields.reduce((acc, path) => {
            const split = path.split("as").map(s => s.trim());
            if (split.length == 2) {
                const [path, name] = split;
                return {
                    ...acc,
                    [name]: path
                }
            } else if (split.length == 1) {
                return {
                    ...acc,
                    [path]: path
                }
            } else {
                return acc
            };
        }, {}) : {}

        return {
            type: "admin::Display_FilterCondition",
            params: {
                fieldItems
            }
        }
    },
    AuthTypeRolePermission: (field, { allEntities, allDataTypes }) => {

        return {
            type: "admin::Display_RolePermission"
        }
    }
}

const displayWidgets = type => {
    if (predefinedDisplayWidgets[type]) {
        return predefinedDisplayWidgets[type]
    } else {
        return () => 'admin::display::Plain'
    }
}

const getBuilders = (field, { allEntities, allDataTypes }) => {

    const { options, multivalued } = field

    return {
        Link: valueName => {
            const linkedEntity = allEntities.find(
                e => e.key === options.entity || e.name === options.entity
            )

            if (!linkedEntity) {
                ///
                console.warn(options.entity, "实体不存在或者未暴露出来")
                return undefined;
            }

            return buildRecordSummary(valueName, linkedEntity, multivalued, { allEntities })
        },
    }
}

