
import React from 'react'
import { Input } from 'antd'

import { Icon } from '@ant-design/compatible';

const { TextArea } = Input;

import './WxMp_Text.less'

export default function WxMp_Text (props){

    const { text, textInput, deleteReplies } = props

    return (
        <div className="wxmp-text">
            <label>文本消息</label>
            <TextArea
                style={{ width: 315 }}
                autoSize={{ minRows: 2, maxRows: 8 }}
                value={text}
                onChange={e => textInput(e.target.value)}
            />
            <div onClick={() => deleteReplies()}>
                <Icon type="close" />
            </div>
        </div>
    )
}
