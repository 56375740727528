// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var PageComponent = require("./misc/PageComponent");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_runtime$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_runtime.bs.js");
var Widget_common$BwaxAdmin = require("../../mod/widget/widget_common.bs.js");
var Widget_mod_custom_page_component$BwaxAdmin = require("../../mod/widget/widget_mod_custom_page_component.bs.js");

function pageComponent(prim) {
  return PageComponent.create(prim);
}

function to_el(react_el, param, param$1, param$2, param$3) {
  return react_el;
}

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent) {
  var maybe_view_args = rawContent[3];
  var init_args = rawContent[2];
  var match = rawContent[1];
  var maybe_key = match[2];
  var loading_element = match[1];
  var isLazy = match[0];
  var name = rawContent[0];
  return (function (theme, pc, prs, key) {
      var convert = function (param) {
        var arg = param[0];
        if (typeof arg !== "number") {
          switch (arg.tag | 0) {
            case /* V_tuple */0 :
                return /* tuple */[
                        /* V_tuple */Caml_chrome_debugger.variant("V_tuple", 0, [Plate$BwaxAdmin.List.map(convert, arg[0])]),
                        param[1]
                      ];
            case /* V_list */1 :
                return /* tuple */[
                        /* V_list */Caml_chrome_debugger.variant("V_list", 1, [Plate$BwaxAdmin.List.map(convert, arg[0])]),
                        param[1]
                      ];
            case /* V_record */2 :
                return /* tuple */[
                        /* V_record */Caml_chrome_debugger.variant("V_record", 2, [Plate$BwaxAdmin.List.map((function (param) {
                                    return /* tuple */[
                                            param[0],
                                            convert(param[1])
                                          ];
                                  }), arg[0])]),
                        param[1]
                      ];
            case /* V_tagged */6 :
                if (arg[0] === "Just") {
                  var match = arg[1];
                  if (match && !match[1]) {
                    return /* tuple */[
                            /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                                "Just",
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    convert(match[0]),
                                    /* [] */0
                                  ])
                              ]),
                            param[1]
                          ];
                  }
                  
                }
                break;
            case /* V_raw */7 :
                var match$1 = param[1];
                if (typeof match$1 !== "number" && !match$1.tag && match$1[0] === "Element") {
                  var el = Curry._1(doRender, arg[0]);
                  return Widget_common$BwaxAdmin.element_to_value(/* Rendered */Caml_chrome_debugger.variant("Rendered", 1, [el]));
                }
                break;
            default:
              
          }
        }
        var t = param[1];
        if (Lang_runtime$BwaxAdmin.is_msg_tagger(t)) {
          var f = function (v) {
            Curry._1(onMsg, v);
            return v;
          };
          return /* tuple */[
                  /* V_decorated */Caml_chrome_debugger.variant("V_decorated", 13, [
                      f,
                      /* tuple */[
                        arg,
                        t
                      ]
                    ]),
                  t
                ];
        } else {
          return /* tuple */[
                  arg,
                  t
                ];
        }
      };
      var initParams = convert(init_args);
      var maybeViewParams = Plate$BwaxAdmin.$$Option.map(convert, maybe_view_args);
      var final_key = Plate$BwaxAdmin.$$Option.with_default(key, maybe_key);
      var loading = Plate$BwaxAdmin.$$Option.map((function (e) {
              return Curry._5(doRender, e, theme, pc, prs, key);
            }), loading_element);
      return PageComponent.create({
                  key: final_key,
                  componentKey: final_key,
                  isLazy: isLazy,
                  loading: loading,
                  name: name,
                  initParams: initParams,
                  maybeViewParams: maybeViewParams,
                  get_instance_id: get_instance_id,
                  facade: facade,
                  viewEnv: viewEnv
                });
    });
}

var module_name = Widget_mod_custom_page_component$BwaxAdmin.module_name;

exports.pageComponent = pageComponent;
exports.module_name = module_name;
exports.to_el = to_el;
exports.render_element = render_element;
/* Plate-BwaxAdmin Not a pure module */
