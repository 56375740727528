
import AvatarAndLabel from './AvatarAndLabel'
import Label from './Label'

import JSONPreview from './JSONPreview'

import Card from './Card'
import CardWithIcon from './CardWithIcon'

import ValidationSourcing from './ValidationSourcing'

import Tags from './Tags'
import Form from './Form'

import RecordForm from './RecordForm'

import TableView from './TableView'

import EditForm from './EditForm'
import EditBox from './EditBox'

import AddRecordButton from './AddRecordButtonLegacy'


/// sub-modules
import inputs from './input'

import templates from './templates'
import display from './display'

import containers from './containers'

import design from './design'

//import Button from './Button'

/// RE widgets:
import Button from 'Client/re/widgets/Button.bs'
import BwButton from 'Client/re/widgets/BwButton.bs'

import IconButton from 'Client/re/widgets/IconButtonWidget.bs'

import Layout_Row from 'Client/re/widgets/layout/Layout_Row.bs'
import Layout_TopbarMain from 'Client/re/widgets/layout/Layout_TopbarMain.bs'

import Input_PageContentPlain from 'Client/re/widgets/input/Input_PageContentPlain.bs'

import Input_AdminDisplayOption from 'Client/re/widgets/input/Input_AdminDisplayOption.bs'
import Input_RichTextLite from 'Client/re/widgets/input/Input_RichTextLite.bs'

import Input_TableColumnConfig from 'Client/re/widgets/input/Input_TableColumnConfig.bs'

import Input_JSON from 'Client/re/widgets/input/Input_JSON.bs'
import Input_JSONCode from 'Client/re/widgets/input/Input_JSONCode.bs'


import Input_RolePermission from 'Client/re/widgets/input/Input_RolePermission.bs'

import Display_RecordHistory from 'Client/re/widgets/display/Display_RecordHistory.bs'
import Display_Comment from 'Client/re/widgets/display/Display_Comment.bs'
import Display_RichText from 'Client/re/widgets/display/Display_RichText.bs'

import Display_ExportJob from 'Client/re/widgets/display/Display_ExportJob.bs'

import Display_JSON from 'Client/re/widgets/display/Display_JSON.bs'
import Display_QRCode from 'Client/re/widgets/display/Display_QRCode.bs'


import Display_FilterCondition from 'Client/re/widgets/display/Display_FilterCondition.bs'

import Display_RolePermission from 'Client/re/widgets/display/Display_RolePermission.bs'


import QC_AdminDisplayPage from 'Client/re/widgets/containers/QC_AdminDisplayPage.bs'

import AdminDisplayPage from 'Client/re/widgets/containers/AdminDisplayPage'


import OutdatedPageList from './OutdatedPageList'


const reWidgets = {
    Button,

    BwButton,

    IconButton,

    Layout_Row,
    Layout_TopbarMain,
    Input_PageContentPlain,
    Input_AdminDisplayOption,
    Input_RichTextLite,


    Input_TableColumnConfig,
    
    Input_JSON,
    Input_JSONCode,

    Input_RolePermission,

    Display_RichText,
    Display_Comment,
    Display_RecordHistory,
    Display_JSON,
    Display_QRCode,

    Display_ExportJob,

    Display_FilterCondition,

    Display_RolePermission,

    QC_AdminDisplayPage,

}

const widgets = {
    AvatarAndLabel,
    Label,

    // Button,
    AddRecordButton,

    RecordForm,

    JSONPreview,

    Tags,
    Card,
    CardWithIcon,


    ValidationSourcing,

    Form,
    EditForm,
    EditBox,

    TableView,

    AdminDisplayPage,
    

    OutdatedPageList,

    ...inputs,
    ...templates,
    ...display,
    ...containers,

    ...design
}


/// add prefix - admin
const PREFIX = "admin"
const prefixed = Object.keys(widgets).reduce((acc, key) => {
    return { 
        ...acc,
        [`${PREFIX}::${key}`]: widgets[key]
    }
}, {}) 



const combined = Object.keys(reWidgets).reduce((acc, name) => {

    const widgetName = "admin::" + name
    const widget = reWidgets[name]
    return {
        ...acc,
        [widgetName]: widget.make
    }

}, prefixed)


export default combined