import React, { useEffect, useState } from 'react'
import { Input } from 'antd'

import './WxMp_View.less'

export default function WxMp_View (props) {

    const { url, urlInput, publishFault, validateUrl } = props

    const [urlValue, setUrlValue] = useState(null)
    const [validateError, setValidateError] = useState(null)

    

    useEffect(() => {
        if(url) {
            setUrlValue(url)
        }
    }, [ url ])

    function blurValidate () {
        const errorMessage = validateUrl(urlValue)
        if(errorMessage) {
            setValidateError(errorMessage)
        } else {
            urlInput(urlValue)
        }
    }

    function renderErrorContent (error, message) {
        return error ? (
            <p style={{ color: "#f00", paddingTop: 10 }}>{message}</p>
        ) : null
    } 

    function handleUrlInput (value) {
        setUrlValue(value)
        urlInput(value)

        if(validateError) {
            const errorMessage = validateUrl(value)
            if(!errorMessage) {
                setValidateError(null)
            }
        }
    }

    return (
        <div>
            <div className="wxmp-view" style={publishFault && !url ? {border: '1px solid #f00'} : null}>
                <p>订阅者点击该子菜单会跳到以下链接</p>
                <div>
                    <label>页面地址</label>
                    <Input value={urlValue} style={{ width: 300 }} 
                        onChange={e => handleUrlInput(e.target.value)}
                        onBlur={() => blurValidate()}
                    />
                </div>
                { renderErrorContent(validateError, validateError)}
            </div>
            { renderErrorContent(publishFault && !url, "请设置页面地址") }
        </div>
        
    )
}
