


// http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/383


const navKey = "breadcrumb-nav-key"


export function markNav (nav) {
    if(typeof(sessionStorage) !== 'undefined') {
        sessionStorage.setItem(navKey, JSON.stringify(nav));
    }
}

export function getMarkedNav () {
    if(typeof(sessionStorage) !== 'undefined') {
        const stored = sessionStorage.getItem(navKey);
        return stored ? JSON.parse(stored) : undefined
    }
}

export function saveFullNav (fullNav) {
    if(typeof(document) !== 'undefined') {
         document.fullNav = fullNav
    } 
}

export function getFullNav() {
    if(typeof(document) !== 'undefined') {
        return document.fullNav || []
    } else {
        return []
    }
}