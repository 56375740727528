

import { lookupValue } from 'bwax/core/store/DataBook'

import ActionTasks from './actions'

export default function setUpActionPreparer(
        env,
        customActions, ///
    ) {

    /// TOOD ensure there's no action being overriden
    const allActions = {
        ...customActions,
        ...ActionTasks,
    }

    return (action, pageState, inputState) => {
        
        const NoOpTask = () => (params) => {
            return new Promise((resolve) => {
                console.warn("unsupported action spec", params)
                // alert("unsupport action spec \n" + JSON.stringify(params, null, 2))
                resolve(Date.now())
            })
        }
        const { value, localData, node } = action

        const specList = (
            Array.isArray(value) ?
                value.map((spec, index) => {
                    return {
                        spec,
                        node: node.children[index],
                    }
                }) : [{
                    spec: value,
                    node
                }]
        )

        return async adhocArgs => {

            async function fireActions() {

                let actionOutput = {}
                for (let i = 0; i < specList.length; i++) {

                    const { spec, node } = specList[i]
                    const task = allActions[spec.type]

                    const behavior = (task ? task(env) : NoOpTask())

                    const params = lookupValue(node, {
                        ...localData,
                        ...pageState,
                        input: inputState,
                        actionOutput
                    })

                    // 2. 调用动作行为
                    const result = await behavior({
                        ...(adhocArgs || {}),
                        ...params
                    })

                    const { outputName } = spec
                    const output = (outputName ? { [outputName]: result } : {})

                    /// 更新当前的 actionOutput
                    actionOutput = { ...actionOutput, ...output }
                }
                return actionOutput
            }

            return (await fireActions())

            // fireActions().then(() => {
            //     // fulfillment
            // }, (reason) => {
            //     // rejection
            //     console.error("error occurrred", reason)
            // });
        }
    }
}
