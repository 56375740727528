// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var DomBridge = require("./DomBridge");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function offsetTop(el) {
  return el.offsetTop;
}

function scrollTop(el) {
  return el.scrollTop;
}

function offsetHeight(el) {
  return el.offsetHeight;
}

function offsetWidth(el) {
  return el.offsetWidth;
}

var DomRect = { };

function getBoundingClientRectRaw(el) {
  return el.getBoundingClientRect();
}

function getBoundingClientRect(el) {
  var raw = el.getBoundingClientRect();
  return /* record */Caml_chrome_debugger.record([
            "x",
            "y",
            "width",
            "height",
            "top",
            "right",
            "bottom",
            "left"
          ], [
            raw.x,
            raw.y,
            raw.width,
            raw.height,
            raw.top,
            raw.right,
            raw.bottom,
            raw.left
          ]);
}

function addEventListener(el, name, listener) {
  return el.addEventListener(name, listener);
}

function removeEventListener(el, name, listener) {
  return el.removeEventListener(name, listener);
}

function scrollTo(el, option) {
  return el.scrollTo(option);
}

var $$document = (
  (typeof(document) !== "undefined") ? document : undefined
);

var $$location = (
  (typeof(document) !== "undefined") ? document.location : undefined
);

var $$window = (
  (typeof(window) !== "undefined") ? window : undefined
);

var performanceNow = (
  () => (typeof(performance) !== "undefined") ? performance.now() : 0
);

function saveToLocalStorage(name, value) {
  if ($$window !== undefined) {
    localStorage.setItem(name, value);
    return /* () */0;
  } else {
    return /* () */0;
  }
}

function getFromLocalStorage(name) {
  return Belt_Option.flatMap($$window, (function (param) {
                return Caml_option.null_to_opt(localStorage.getItem(name));
              }));
}

function removeFromLocalStorage(name) {
  if ($$window !== undefined) {
    localStorage.removeItem(name);
    return /* () */0;
  } else {
    return /* () */0;
  }
}

function downloadUrl(prim, prim$1) {
  DomBridge.downloadUrl(prim, prim$1);
  return /* () */0;
}

exports.offsetTop = offsetTop;
exports.scrollTop = scrollTop;
exports.offsetHeight = offsetHeight;
exports.offsetWidth = offsetWidth;
exports.DomRect = DomRect;
exports.getBoundingClientRectRaw = getBoundingClientRectRaw;
exports.getBoundingClientRect = getBoundingClientRect;
exports.addEventListener = addEventListener;
exports.removeEventListener = removeEventListener;
exports.scrollTo = scrollTo;
exports.$$document = $$document;
exports.$$location = $$location;
exports.$$window = $$window;
exports.performanceNow = performanceNow;
exports.saveToLocalStorage = saveToLocalStorage;
exports.getFromLocalStorage = getFromLocalStorage;
exports.removeFromLocalStorage = removeFromLocalStorage;
exports.downloadUrl = downloadUrl;
/* document Not a pure module */
