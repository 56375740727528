import React, { Component } from 'react';
import { RichUtils } from 'draft-js';

export default ({ style, children }) => (
  class InlineStyleButton extends Component {

    toggleStyle = (event) => {
      event.preventDefault();
      this.props.setEditorState(
        RichUtils.toggleInlineStyle(
          this.props.getEditorState(),
          style
        )
      );
    }
    preventBubblingUp = (event) => { event.preventDefault(); }
    // we check if this.props.getEditorstate is undefined first in case the button is rendered before the editor
    styleIsActive = () => this.props.getEditorState && this.props.getEditorState().getCurrentInlineStyle().has(style);

    render() {
      const className = this.styleIsActive() ? " active" : "";
      return (
        <button className={"editor-button" + className} onClick={this.toggleStyle}
          >{children}</button>
      );
    }
  }
);
