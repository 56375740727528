
import React, { useEffect, useContext, useState } from "react";

import loadSandboxDefinitions from 'bwax-ui/legacy/store/loaders/loadSandboxDefinitions'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import InputSelect from 'Client/js/ui/components/InputSelect';

import ApplicationContext from 'bwax/util/ApplicationContext';

export default function BacklinkFieldNameInput (props) {

    const { value, onChange, entityName, hostEntityName } = props;

    const [ entities, setEntities ] = useState([]);

    const dlc = useContext(DataLoaderContext);
    const env = { sessionToken: dlc.sessionToken, sandbox: true };

    const { currentApplication } = useContext(ApplicationContext) || {};

    useEffect(() => {
        (async () => {
            const { allEntities } = await loadSandboxDefinitions(currentApplication.code)(env);
            setEntities(allEntities);

        })()
    }, [ currentApplication.code ]);

    const entity = entities.find(e => e.name === entityName);

    const fieldNames = (() => {
        if(entity) {
            const { fields } = entity;
            // 需要增加判断
            //  对方字段，必须是 link to 当前数据实体（hostEntityName）的
            const fieldNames = fields.filter(
                f => f.unique && f.type === "Link" && (hostEntityName ? f.options && f.options.entity === hostEntityName : true)
            ).map(f => f.name);
            return fieldNames
        } else {
            return []
        }
    })();

    return (
        <InputSelect {...{
            value, onChange, style: { width: "100%" },
            isSearchable: true,
            isClearable: false,

            options: fieldNames,
        }} />
    )


}