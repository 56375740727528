
import React from 'react';

import "./PopoverMenu.less";

import Popover from 'bwax-ui/components/legacy/Popover'

import { MoreOutlined } from '@ant-design/icons';

export default function PopoverMenu ({ items, style }) {

    if(items.length == 0) {
        return null;
    }

    const buttonMenu = (
        <div className="menu-list">
            {items.map(([label, element ]) => {
                return (
                    <div key={label}>
                        { element }
                    </div>
                )
            })}
        </div>
    )
    return (
        <Popover 
            positions={["left"]}
            align="start"
            trigger="hover"
            content={buttonMenu}
            // padding={8}
            className="popover-menu-overlay no-select"
        >
            <MoreOutlined style={{ fontSize: 14, marginLeft: 8, cursor: "pointer", ...style }} />
        </Popover>
    )

    return 

}