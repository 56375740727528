
// const chevrotain = require('chevrotain')

import { createToken, Lexer, CstParser } from 'chevrotain'

const Identifier = createToken({
    name: "Identifier", pattern: /[\u4E00-\u9FA5A-Za-z][\u4E00-\u9FA5A-Za-z0-9_]*/
})

const Dot = createToken({ name: "Dot", pattern: /\./ })

const Comma = createToken({ name: "Comma", pattern: /,/ })

const WhiteSpace = createToken({
    name: "WhiteSpace",
    pattern: /\s+/,
    group: Lexer.SKIPPED
})


// 逻辑操作: AND OR NOT
const BinaryLogicOperator = createToken({
    name: "BinaryLogicOperator",
    pattern: Lexer.NA
})
const And = createToken({
    name: "And",
    pattern: /(and)|(&&)|&/,
    longer_alt: Identifier,
    categories: BinaryLogicOperator,
})
const Or = createToken({
    name: "Or",
    pattern: /(or)|(\|\|)|\|/,
    longer_alt: Identifier,
    categories: BinaryLogicOperator
})

// unary
const UnaryLogicOperator = createToken({
    name: "UnaryLogicOperator",
    pattern: Lexer.NA
})
const Not = createToken({
    name: "Not",
    pattern: /(not)|!/,
    longer_alt: Identifier,
    categories: UnaryLogicOperator
})

// 能产生布尔值的操作，包括 比较、以及专用的一些操作符 IS_NULL, EXISTS 等）
// 这里先定义 比较操作符：
const ComparisonOperator = createToken({
    name: "ComparisonOperator",
    pattern: Lexer.NA
})
const LT = createToken({
    name: "LT",
    pattern: /</,
    categories: ComparisonOperator,
})
const LE = createToken({
    name: "LE",
    pattern: /<=/,
    categories: ComparisonOperator
})
const EQ = createToken({
    name: "EQ",
    pattern: /==|=/,
    categories: ComparisonOperator
})
const GE = createToken({
    name: "GE",
    pattern: />=/,
    categories: ComparisonOperator
})
const GT = createToken({
    name: "GT",
    pattern: />/,
    categories: ComparisonOperator
})
const NE = createToken({
    name: "NE",
    pattern: /!=/,
    categories: ComparisonOperator
})


// 加减操作
const AdditionOperator = createToken({
    name: "AdditionOperator",
    pattern: Lexer.NA
})
const Plus = createToken({
    name: "Plus",
    pattern: /\+/,
    categories: AdditionOperator
})
const Minus = createToken({
    name: "Minus",
    pattern: /-/,
    categories: AdditionOperator
})

// 乘除操作
const MultiplicationOperator = createToken({
    name: "MultiplicationOperator",
    pattern: Lexer.NA
})
const Multi = createToken({
    name: "Multi",
    pattern: /\*/,
    categories: MultiplicationOperator
})
const Div = createToken({
    name: "Div",
    pattern: /\//,
    categories: MultiplicationOperator
})

const Mod = createToken({
    name: "Mod",
    pattern: /%/,
    categories: MultiplicationOperator
})

// 括号
const LParen = createToken({ name: "LParen", pattern: /\(/ })
const RParen = createToken({ name: "RParen", pattern: /\)/ })

// keywords:


// Literals: 应该包括 Number， String， BOOL
const NumberLiteral = createToken({
    name: "NumberLiteral",
    // pattern: /[\+-]?([1-9]\d*|0)(\.\d*)?/
    /// https://github.com/SAP/chevrotain/blob/c6d82b8254460a6f590914ee6a500ac890fdf74c/packages/chevrotain/benchmark_web/parsers/json/json_parser.js
    pattern: /-?(?:0|[1-9]\d*)(?:\.\d+)?(?:[eE][+-]?\d+)?/
})

const StringLiteral = createToken({
    name: "StringLiteral",

    /// https://github.com/SAP/chevrotain/blob/c6d82b8254460a6f590914ee6a500ac890fdf74c/packages/chevrotain/benchmark_web/parsers/json/json_parser.js
    pattern: /"(?:[^\\"]|\\(?:[bfnrtv"\\/]|u[0-9a-fA-F]{4}))*"/

})


const BoolLiteral = createToken({
    name: "BoolLiteral",
    longer_alt: Identifier,
    pattern: /true|false/
})



const allTokens = [
    WhiteSpace,

    // comparison operators: 长的在前面
    LE, GE, EQ, GT, LT, NE, ComparisonOperator,

    // logic operators:
    And, Or, BinaryLogicOperator,
    Not, UnaryLogicOperator,


    /// number literal 要放在 Minus 前面，否则无法判断负数
    /// 但这又引入一个新问题，减法怎么办？
    // TODO!!! 这确实一个大问题，需要考虑
    /// 目前来说，减法通过加入空格解决
    NumberLiteral,

    //
    Plus, Minus, AdditionOperator,



    //
    Multi, Div, Mod, MultiplicationOperator,

    //
    LParen, RParen,

    //
    Dot, Comma,

    //
    BoolLiteral,

    StringLiteral,

    // The Identifier must appear after the keywords because all keywords are valid identifiers.
    Identifier,
    

]


let l = new Lexer(allTokens)

class ExpressionParser extends CstParser {
    constructor(options = {}) {
        super(allTokens, {
            ...options,
            // nodeLocationTracking: "full",
        })

        const $ = this
        $.RULE("qualifiedName", () => {
            $.CONSUME(Identifier)
            $.MANY(() => {
                $.CONSUME(Dot)
                $.CONSUME2(Identifier)
            })
        })

        $.RULE("expression", () => {
            $.SUBRULE($.logicExp)
        })

        // 逻辑操作符
        $.RULE("logicExp", () => {
            // 二元逻辑操作符其实内部也有优先级的 AND > OR， 但暂时不支持
            $.SUBRULE($.boolExp, { LABEL: "lhs" })
            $.MANY(() => {
                $.CONSUME(BinaryLogicOperator)
                $.SUBRULE2($.boolExp, { LABEL: "rhs" })
            })
        })

        $.RULE("boolExp", () => {
            $.OR([
                { ALT: () => $.SUBRULE($.unaryLogicExp) },
                { ALT: () => $.SUBRULE($.comparisonExp) }
            ])
        })

        $.RULE("unaryLogicExp", () => {
            $.CONSUME(UnaryLogicOperator)
            $.SUBRULE($.comparisonExp)
        })

        $.RULE("comparisonExp", () => {
            $.SUBRULE($.additionExp, { LABEL: "lhs" })
            $.OPTION(() => {
                $.CONSUME(ComparisonOperator)
                $.SUBRULE2($.additionExp, { LABEL: "rhs" })
            })
        })

        //
        $.RULE("additionExp", () => {
            $.SUBRULE($.multiplicationExp, { LABEL: "lhs" })
            $.MANY(() => {
                $.CONSUME(AdditionOperator)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.multiplicationExp, { LABEL: "rhs" })
            })
        })


        $.RULE("multiplicationExp", () => {
            $.SUBRULE($.atomicExp, { LABEL: "lhs" })
            $.MANY(() => {
                $.CONSUME(MultiplicationOperator)
                //  the index "2" in SUBRULE2 is needed to identify the unique position in the grammar during runtime
                $.SUBRULE2($.atomicExp, { LABEL: "rhs" })
            })
        })

        $.RULE("atomicExp", () => {
            $.OR([
                { ALT: () => $.SUBRULE($.callExp) },
                { ALT: () => $.SUBRULE($.parenthesisExp) },
                { ALT: () => $.SUBRULE($.qualifiedName) },
                { ALT: () => $.CONSUME(NumberLiteral) },
                { ALT: () => $.CONSUME(BoolLiteral) },
                { ALT: () => $.CONSUME(StringLiteral) },
            ])
        })

        $.RULE("callExp", () => {
            $.CONSUME(Identifier, { LABEL: "func" })
            $.CONSUME(LParen)
            $.OPTION(() => {
                $.SUBRULE($.expression, { LABEL: "args" })
                $.MANY(() => {
                    $.CONSUME(Comma)
                    $.SUBRULE2($.expression, { LABEL: "args" })
                })
            })
            $.CONSUME(RParen)
        })

        $.RULE("parenthesisExp", () => {
            $.CONSUME(LParen)
            $.SUBRULE($.expression)
            $.CONSUME(RParen)
        })

        $.performSelfAnalysis()

    }

    parse(inputText) {

        const lexingResult = l.tokenize(inputText)

        this.input = lexingResult.tokens
        const ret = this.expression()

        // console.log(">>>", inputText, JSON.stringify(prune(summarize(ret)), null, 2))
        return summarize(ret)

    }


    static getAllQNames(ast) {
        const findQNames = (t) => {
            if (t && t.name === "qualifiedName") {
                return [t.children.Identifier.map(o => o.image)]
            } else if (t && t.children) {

                const ret = Object.keys(t.children).reduce((acc, k) => {
                    return [...acc, ...findQNames(t.children[k])]
                }, [])

                return ret

            } else if (Array.isArray(t)) {

                return t.reduce((acc, e) => {
                    return [...acc, ...findQNames(e)]
                }, [])

            } else {
                return []
            }
        }
        return findQNames(ast)
    }

}


function summarize(token) {
    if (!token) return token

    if (token.image) {
        const { startLine, startOffset, endLine, endOffset } = token
        return {
            image: token.image,
            tokenType: token.tokenType.tokenName,
            position: {
                startLine,
                startOffset,
                endLine,
                endOffset
            }
        }
        //return token.image
    } else if (Array.isArray(token)) {
        return token.map(t => summarize(t))
    } else if (typeof (token) === 'object') {
        return Object.keys(token).reduce((acc, k) => {
            return { ...acc, [k]: summarize(token[k]) }
        }, {})
    } else {

        return token
    }
}

module.exports = {
    allTokens,
    ExpressionParser
}

/// test
function doTheTests() {

    function test(exp) {
        const p = new ExpressionParser()
        const parsed = p.parse(exp)
        console.log(exp, JSON.stringify(parsed, null, 2))
    }
    // test(`-1`)
    // test(`10 - 1`)
    //test(`(购买记录.支付订单.订单状态 == "SUCCESS")`)
    //test(`!isNull( 购买记录.问卷调查结果 )`)
    //test(`(购买记录.支付订单.订单状态 == "SUCCESS") && !isNull( 购买记录.问卷调查结果 )`)

}
//doTheTests()


// console.log("##################")
// console.log(JSON.stringify(parsed, null, 2))
// console.log(JSON.stringify(summarize(parsed), null, 2))
