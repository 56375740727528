
import get from 'lodash/get'

import { Base64 } from 'js-base64';

import queryString from 'query-string'

import { isWeChat, isMobile, isIOS } from 'bwax/clientEnv';

export default {
    inc: {
        argsType: {
            v: 'Number'
        },
        returnType: 'Number',
        execute: v => v + 1
    },
    add: {
        argsType: {
            a: 'Number',
            b: 'Number'
        },
        returnType: 'Number',
        execute: (a, b) => a + b
    },
    exists: {
        argsType: {
            v: 'Any'
        },
        returnType: 'Boolean',
        execute: v => !!v
    },
    isNull: {
        argsType: {
            v: 'Any'
        },
        returnType: 'Boolean',
        execute: v => v === null || v === undefined
    },
    isUndefined: {
        argsType: {
            v: 'Any'
        },
        returnType: 'Boolean',
        execute: v => v === undefined
    },
    
    get: {
        argsType: {
            o: 'Any',
            path: 'String',
        },
        returnType: 'Any',
        execute: (o, path) => {
            return get(o, path)
        }
    },
    len: {
        argsType: {
            l: 'Any',
        },
        returnType: 'Number',
        execute: l => {
            if(!l) {
                return 0
            }
            return Array.isArray(l) ? l.length : Object.keys(l).length
        }
    },

    sum: {
        execute: l => {
            if(!l) {
                return 0
            };
            return l.reduce((acc, e) => acc + e, 0);
        }
    },

    /// 下面这个是跟 graphql endpoint 有关系
    count: {
        argsType: {
            l: 'Any',
        },
        returnType: 'Number',
        execute: l => {
            if(!l) {
                return 0
            }
            if(l.count !== undefined) {
                return l.count
            }
            return Array.isArray(l) ? l.length : Object.keys(l).length
        }
    },

    /// log 用来 debug
    log: {
        argsType: {
            value: 'Any',
        },
        returnType: 'Any',
        execute: value => {
            console.log("Log", value)
            return value;
        } 
    },

    /// get with default ， 这两个参数和返回值是同一个类型 'a
    orElse: {
        argsType: {
            value: 'Any',
            defaultValue: 'Any'
        },
        returnType: 'Any',
        execute: (value, defaultValue) => {
            return value === undefined ? defaultValue : value;
        } 
    },
    ifThen: {
        argsTypes: {
            cond: "Boolean",
            then_: "Any",
            else_: "Any",
        },
        returnType: "Any",  // 'a
        execute: (cond, then_, else_) => {
            return cond ? then_ : else_;
        }
    },


    // mask with *
    mask: {
        argsType: {
            value: 'String',
            start: "Number",
            length: "Number",
        },
        returnType: 'String',
        execute: (value, start, length) => {
            if(!value) {
                return ""
            }
            let actualStart = (start + value.length) % value.length
            let actualLength = (() => {
                if(length && length > 0 && (length + actualStart) < value.length) {
                    return length;
                } else {
                    return value.length - actualStart;
                }

            })();
            let actualEnd = actualStart + actualLength

            let pre = value.substring(0, actualStart)
            let middle = ""
            for(let i = 0; i < actualLength; i++) {
                middle += "*"
            }
            let suf = value.substring(actualEnd)
            return pre + middle + suf
        }
    },

    substring: {
        argsType: {
            value: 'String',
            start: "Number",
            length: "Number",
        },
        returnType: 'String',
        execute: (value, start, length) => {
            if(!value) {
                return ""
            }
            let actualStart = (start + value.length) % value.length
            let actualLength = (() => {
                if(length && length > 0 && (length + actualStart) < value.length) {
                    return length;
                } else {
                    return value.length - actualStart;
                }

            })();
            let actualEnd = actualStart + actualLength
            return value.substring(actualStart, actualEnd);
        }
    },

    // date utils::
    currentDate:  {
        execute: () => {
            const dateValue = new Date();
            return dateValue.toJSON();
        }
    },

    afterNow: {
        argsType: {
            value: "Date"
        },
        returnType: "Boolean",
        execute: value => {
            const dateValue = new Date(value);
            if(dateValue !== "Invalid Date") {
                return dateValue.getTime() > Date.now();
            } else {
                return false
            }
        }
    },

    
    secondsLater: {
        argsType: {
            seconds: "Number"
        },
        returnType: "String", // a Date JSON
        execute: seconds => {
            return (new Date(Date.now() + seconds * 1000)).toJSON()
        }
    },

    //// string utils:

    patternTest: {
        argsTypes: {
            value: "String",
            pattern: "String",
        },
        returnType: "Boolean",
        execute: (value, pattern) => {
            let p = new RegExp("^" + pattern + "$");
            return p.test(value)
        }
    },

    join: {
        execute: (list, separator) => {
            if(!list) return "";
            return list.join(separator);
        }
    },

    replace: {
        execute: (tmpl, pattern, value) => {
            return tmpl.replace(pattern, value)
        }
    },


    /// list utils::
    /// two list: a - b;
    subtract: {
        execute: (a, b) => {
            if(a == null || a == undefined) {
                return []
            };
            if(b == null || b == undefined) {
                return a
            }
            return a.filter(
                e => b.indexOf(e) === -1
            )
        }
    },

    head: {
        execute: (list) => {
            if(!list) {
                // null, undefined, or []?
                return undefined
            }
            if(list.length === 0) {
                return undefined  // []
            }
            return list[0]
        }
    },

    inList: {
        execute: (value, list) => {
            return list && Array.isArray(list) && list.some(e => e == value)
        }
    },

    zipAsKeyValueObject: {
        execute: (keyList, valueList) => {
            if (keyList && Array.isArray(keyList) && valueList && Array.isArray(valueList)) {
                return keyList.map((key, index) => {
                    return {
                        key,
                        value: valueList[index]
                    }
                })
            } else {
                return []
            }
        }
    },

    batches: {
        execute: (num, list) => {
            if(list === undefined || list === null || (!Array.isArray(list))) {
                console.warn("batches: list is not a correct list")
                return []
            };
            function iter (acc, remaining) {
                if(remaining.length == 0) {
                    return acc; // stop
                } else if(remaining.length < num) {
                    return [...acc, remaining]; // appending the remaining and stop
                } else {
                    let batch = remaining.slice(0, num);
                    let newAcc = [
                        ...acc,
                        batch,
                    ];
                    let newRemaning = remaining.slice(num);
                    return iter(newAcc, newRemaning)
                }
            }
            return iter([], list)
        }
    },

    /// various formatter:

    formatDate: {
        argsType: {
            value: "Date",  /// or Date JSON
            format: "String",
        },
        returnType: "Boolean",
        execute: (value, format) => {
            const dateFormat = require('dateformat')
            const dateValue = new Date(value);
            if(dateValue != "Invalid Date") {
                return dateFormat(dateValue, format);
            } else {
                return ""
            }
        }       
    },

    // number 有关
    percentage: {
        argsType: {
            value: "Number",
        },
        returnType: "String",
        execute: (value, decimals = 2) => {
            /// 保留 N 位小数
            let scale = Math.pow(10.0, decimals);
            let rounded = Math.round(value * scale * 100) / scale;
            
            // make it a string
            let str = rounded.toFixed(decimals) + "%";
            return str
        }
    },

    round: {
        execute: (value) => {
            if(value === undefined || value === null) {
                return 0;
            }
            return Math.round(value)
        }
    },

    roundBy: {
        execute: (value, decimals = 2) => {
            let v = (value === undefined || value === null) ? 0 : v;
            let scale = Math.pow(10.0, decimals);
            let rounded = Math.round(v * scale) / scale;
            return rounded
        }
    },

    floorBy: {
        execute: (value, by) => {
            /// they must all be integer
            if(typeof(value) == 'number' && typeof(by) == 'number' && by != 0 && value > by) {
                return value - (value % by)
            } else {
                // not number
                return 0
            }
        }
    },


    // str 有关
    urlEncode: {
        execute: (value) => {
            if(!value) {
                return ""
            }
            return encodeURIComponent(value)
        }
    },

    urlDecode: {
        execute: (value) => {
            if(!value) {
                return ""
            }
            return decodeURIComponent(value)
        }
    },


    //
    base64Encode: {
        execute: (value) => {
            return Base64.encode(value)
        }
    },

    base64EncodeURI: {
        execute: (value) => {
            return Base64.encodeURI(value)
        }
    },

    base64Decode: {
        execute: (value) => {
            return Base64.decode(value)
        }
    },

    /// excludeField
    excludeField: {
        execute: (obj, fieldName) => {
            if(obj === null || typeof(obj) !== 'object') {
                return obj
            };
            return Object.keys(obj).reduce((acc, key) => {
                if(key === fieldName) {
                    return acc;
                } else {
                    return {
                        ...acc,
                        [key]: obj[key]
                    }
                } 
            }, {})
        }
    },


    /// http query helpers:
    addQueryParam: {
        execute: (url, name, param) => {
            if(url && name && param) {
                return queryString.stringifyUrl({url, query: {[name]: param}})
            } else {
                return ""
            }
        }
    },

    removeQueryParam: {
        execute: (path, name) => {
            if(path && name) {
                let { url, query } = queryString.parseUrl(path);
                let { [name]: _, ...remaining } = query;
                return queryString.stringifyUrl({url, query:remaining});
            } else {
                return ""
            }
        }
    },



    /// to json
    // 这个主要是用来传 monent value （因为如果不 jsonify 的话，
    // 见 buildExcelExportDef (in admin)
    toJSON: {
        execute: v => {
            if (!v) {
                return v;
            }
            return JSON.parse(JSON.stringify(v))
        }
    }


}

///
// https://wx1bb68dd362ee7b45.h5.xiaoe-tech.com/content_page/eyJ0eXBlIjoiMyIsInJlc291cmNlX3R5cGUiOjI1LCJyZXNvdXJjZV9pZCI6IiIsImFwcF9pZCI6ImFwcHBVTmZJbXY0Mzk0MiIsInByb2R1Y3RfaWQiOiJ0ZXJtXzVlMWM0NTM2ZDY1NThfMlQzNUh2Iiwic2hhcmVfdXNlcl9pZCI6InVfNWMxMDc2ZjE1YWIzN19mWU9TN0MwUXhJIiwic2hhcmVfdHlwZSI6MTAwLCJzY2VuZSI6Ilx1NTIwNlx1NGVhYiJ9?entry=2&entry_type=2001&from=singlemessage&isappinstalled=0

// let s = "eyJ0eXBlIjoiMyIsInJlc291cmNlX3R5cGUiOjI1LCJyZXNvdXJjZV9pZCI6IiIsImFwcF9pZCI6ImFwcHBVTmZJbXY0Mzk0MiIsInByb2R1Y3RfaWQiOiJ0ZXJtXzVlMWM0NTM2ZDY1NThfMlQzNUh2Iiwic2hhcmVfdXNlcl9pZCI6InVfNWMxMDc2ZjE1YWIzN19mWU9TN0MwUXhJIiwic2hhcmVfdHlwZSI6MTAwLCJzY2VuZSI6Ilx1NTIwNlx1NGVhYiJ9"

// let d = Base64.decode(s);

// console.log("D", d);
// console.log("E", Base64.encode(d));
// console.log("EU", Base64.encodeURI(d));
