// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactJsonView = require("react-json-view");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Input_JSON(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var src = (value == null) ? undefined : Caml_option.some(value);
  console.log(">>> input json");
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.overflowX(Css.auto),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.overflowY(Css.auto),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.width(Css.pct(100.0)),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.minHeight(Css.rem(6.0)),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.maxHeight(Css.rem(20.0)),
                          /* [] */0
                        ])
                    ])
                ])
            ])
        ]));
  var onUpdate = function (model) {
    var newValue = model.updated_src;
    Curry._1(onChange, Caml_option.some(newValue));
    return /* () */0;
  };
  return React.createElement("div", {
              className: className
            }, React.createElement(ReactJsonView.default, {
                  name: false,
                  displayObjectSize: false,
                  src: src,
                  theme: "shapeshifter:inverted",
                  collapseStringsAfterLength: 20,
                  groupArraysAfterLength: 20,
                  displayDataTypes: true,
                  indentWidth: 2,
                  onAdd: onUpdate,
                  onEdit: onUpdate,
                  onDelete: onUpdate
                }));
}

var make = Input_JSON;

exports.make = make;
/* Css Not a pure module */
