
import React from 'react'

import expression_input_helper from 'Client/ml/helpers/expression_input_helper.bs';

import { Tree } from '@arco-design/web-react';
const TreeNode = Tree.Node;


// currently it only supports model.

export default function ExprValueSelect({ expectedType, typingEnv, onSelect = _ => {} }) {

    const [tenv, dts] = typingEnv;

    const modelType = expression_input_helper.get_model_type_js(dts);

    const isMsg = expression_input_helper.is_msg_type(expectedType);

    function modelToTreeData({ type, children, name }, prefix, depth) {
        const key = prefix ? prefix + "-" + name : name;
        return {
            title: depth == 0 && name == "model" ? "页面状态" : name,
            key,
            children: children.map(c => {
                return modelToTreeData(c, key, depth + 1)
            })
        }
    }

    function msgTreeData () {
        const msgTaggers = expression_input_helper.get_all_msg_taggers(dts);
        return {
            title: "页面事件",
            key: "Msg",
            children: msgTaggers.map(t => {
                return {
                    title: t, key: "Msg-" + t
                }
            })
        }

    }

    const treeData = [
        isMsg ? msgTreeData(): undefined,
        modelToTreeData(modelType, undefined, 0)
    ].filter(x => !!x);

    const buildExpr = path => {
        const buildForType = (acc, path, parentNode, inMaybe) => {
            // console.log(">> parent type", parentType)
            if(path.length === 0) {
                return acc
            } else {
                const head = path[0];
                const node = parentNode.children.find(c => {
                    return c.name == head
                })

                const isInMaybe = expression_input_helper.is_maybe(parentNode.type) || inMaybe;

                // ignore the type   
                function append() {
                    if(isInMaybe) {
                        return `${acc}?.${head}`
                    } else {
                        return `${acc}.${head}`
                    }
                }

                return buildForType(append(), path.slice(1), node, isInMaybe);

            }
        };

        if(path.length > 1 && path[0] == "model") {
            return buildForType("model", path.slice(1), modelType, false)
        } else if(path.length > 1 && path[0] == "Msg") { 
            return path[1]
        } 
        return ""
    }


    return (
        <Tree 
            size="mini" treeData={treeData}
            onSelect={(selectedKeys, info) => {
                if(selectedKeys.length > 0) {
                    const key = selectedKeys[0];

                    const path = key.split("-");

                    const expr = buildExpr(path);

                    console.log(">> expr", path, expr);

                    onSelect(expr);

                }
            }}
        />
    )
}
