

import React from 'react';

import Toolbar from './plugins/toolbar/Toolbar'

/// defaults

import BasicEditor from './BasicEditor'

import './ArticleEditor.less'

const defaultToolbar = {
    buttons: [
        // 'h1', 
        'h2', 'h3', 'blockquote', 'code-block', 'ordered-list', 'unordered-list', 'hr', 'seperator',
        'image', 'media', 'seperator',
        'undo', 'redo'
    ]
}

const defaultInlineToolbar = {
    buttons: [
        'bold', 'italic', 'underline', 'superscript', 'subscript', 'color', 'seperator',
        'align-left', 'align-center', 'align-right', 'align-justify', 'seperator',
        'format-clear', 'format-brush', 'hyperlink'
    ]
}

export default class ArticleEditor extends React.Component {

    render() {

        const {
            editorState, onChange,
            className, style = {},
            toolbar, inlineToolbar,
            plugins, uploadImage,
            uploadVideo, // uploadVideo api 应该被废弃
            uploadMedia
        } = this.props

        const toolbarConfig = { ...defaultToolbar, ...toolbar }
        const inlineToolbarConfig = { ...defaultInlineToolbar, ...inlineToolbar }


        return (
            <div className={"article-editor" + (className ? " " + className : "")} style={style}>
                <Toolbar config={toolbarConfig} fullLayout={true}
                    getEditorState={() => editorState}
                    setEditorState={onChange}
                    childrenProps={{
                        uploadImage,
                        uploadVideo,
                        uploadMedia
                    }}
                />
                <BasicEditor editorState={editorState}
                    inlineToolbarConfig={inlineToolbarConfig}
                    style={{ height: "100%" }}
                    onChange={onChange}
                    plugins={plugins}
                    uploadImage={uploadImage}
                    uploadVideo={uploadVideo}
                />
            </div>

        )
    }
}
