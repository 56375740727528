

import React, { useState } from 'react'

import './TestDnd.less'

import SortableTree from 'bwax-ui/components/SortableTree';


export default function TestDnd() {


    const [ activeId, setActiveId ] = useState();

    const items = Array(16).fill(1).map((_, index) => "ITEM_" + ("" + index).padStart(3, "0")).map((s, index) => {

        function buidSubItems () {
            if(index > 3) { return }
            return  Array(3).fill(1).map((_, i) => "ITEM_" + (index + "00" + i)).map((s, index) => ({ id: s, label: s }));
        }

        return { id: s, label: s, children: buidSubItems(), hasChild: index < 5 }
    });

    return (
        <div className="lc-test-dnd">
            <SortableTree {...{
                items: items,

                rootClassName: "lc-test-dnd-tree",
                activeId,
                onItemPressed: item => setActiveId(item.id),
                renderItem: (item) => {
                    return (
                        <div className="lc-test-item">
                            { item.label }
                        </div>
                    )
                },
                // 
                getSubItems: item => {
                    return item.children
                },
                loadSubItems: async item => {
                    // 
                    console.log(">>> async load for", item.id)

                    return  Array(3).fill(1).map((_, i) => "" + item.id + i).map((s, index) => ({ id: s, label: s }));

                },
                hasSubItems: item => {                    
                    return item.hasChild;
                },

                // gap: 8,
                color: "green"
            }} />
        </div>
    )
}


