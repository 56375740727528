
import React from 'react'
import {EditorState} from 'draft-js'

export default function RedoButton (props) {
  const {setEditorState, getEditorState} = props
  return (
    <button className={"editor-button"}
        onClick={() => {
          setEditorState(EditorState.redo(getEditorState()))
        }}
        disabled={
          getEditorState().getRedoStack().isEmpty()
        } >
      <i className={'fa fa-repeat'} aria-hidden="true"></i>
    </button>
  )

}
