
import React from 'react';


/// 这里的相互依赖需要改进：TODO
import EntityDataPage from 'Client/js/pages/EntityDataPage';

export default {
    menu: {
        name: "小鹅通集成",
        icon: "build",
        items: [
            {
                name: "资源列表",
                path: "goods",  // 前面会自动加上 /ext/:extKey/
                ////  !TODO 需要更完善的权限管理 
                //isAdminOnly: true
                /// the following are for routes
        
                comp: (props) => {
                    return <EntityDataPage entityKey={"XETEntityGoods"} {...props}/>
                }
    
            },
            {
                name: "订单列表",
                path: "orders",  // 前面会自动加上 /ext/:extKey/
                comp: (props) => {
                    return <EntityDataPage entityKey={"XETEntityOrder"} {...props}/>
                }
            },
            {
                name: "用户列表",
                path: "users",  // 前面会自动加上 /ext/:extKey/
                comp: (props) => {
                    return <EntityDataPage entityKey={"XETEntityUser"} {...props}/>
                }
            }
        ],
    },

    /// 前面会自动加上 "admin::<extKey>::"
    widgets: {
    },

    dataTypeWidgets: {
        inputs: {

        },
        displays: {
        }
    }

}