// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var DomUtils$BwaxAdmin = require("../../../lib/bwax-ui/re/utils/DomUtils.bs.js");
var QrcodePng = require("./images/qrcode.png");
var CanvasApi$BwaxAdmin = require("../../../lib/bwax-ui/re/legacy/binding/CanvasApi.bs.js");
var JsonUtils$BwaxAdmin = require("../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var StringUtils$BwaxAdmin = require("../../../lib/bwax-js/re/utils/StringUtils.bs.js");
var PromiseUtils$BwaxAdmin = require("../../../lib/bwax-js/re/utils/PromiseUtils.bs.js");

var defaultNickNameColor = "#000000";

function fieldValue(value, key, decoder) {
  return Belt_Option.flatMap(Belt_Option.flatMap(value, (function (v) {
                    return JsonUtils$BwaxAdmin.getField(v, key);
                  })), decoder);
}

function pathValue(value, path, decoder) {
  return Belt_Option.flatMap(Belt_Option.flatMap(value, (function (v) {
                    return JsonUtils$BwaxAdmin.getByPath(v, path);
                  })), decoder);
}

function toDomEl(theRef) {
  return Caml_option.nullable_to_opt(theRef.current);
}

var qrCodePlachoderImg = QrcodePng;

function PosterCanvas(Props) {
  var backgroundImage = Props.backgroundImage;
  var avatarOptions = Props.avatarOptions;
  var nicknameOptions = Props.nicknameOptions;
  var qrCodeOptions = Props.qrCodeOptions;
  var editingArea = Props.editingArea;
  var previewAvatar = Props.previewAvatar;
  var previewNickname = Props.previewNickname;
  var onAreaSelected = Props.onAreaSelected;
  var width = Props.width;
  var containerRef = React.useRef(null);
  var canvasWidth = Belt_Option.getWithDefault(width, 420.0);
  var avatarUrl = Belt_Option.getWithDefault(Belt_Option.map(previewAvatar, (function (a) {
              return a.url;
            })), "");
  var match = React.useState((function () {
          return /* tuple */[
                  canvasWidth,
                  canvasWidth * 4.0 / 3.0
                ];
        }));
  var setCanvasSize = match[1];
  var canvasSize = match[0];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setNaturalSize = match$1[1];
  var naturalSize = match$1[0];
  var match$2 = React.useState((function () {
          return ;
        }));
  var setSelectFrom = match$2[1];
  var selectFrom = match$2[0];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setSelectTo = match$3[1];
  var selectTo = match$3[0];
  var match$4 = React.useState((function () {
          return ;
        }));
  var setHoverOn = match$4[1];
  var hoverOn = match$4[0];
  var url = fieldValue(backgroundImage, "url", Js_json.decodeString);
  React.useEffect((function () {
          if (url !== undefined) {
            PromiseUtils$BwaxAdmin.then_(CanvasApi$BwaxAdmin.getImageSize(url), (function (nSize) {
                    var canvasHeight = nSize[1] * canvasWidth / nSize[0];
                    ReactDom.unstable_batchedUpdates((function (param) {
                            Curry._1(setNaturalSize, (function (param) {
                                    return nSize;
                                  }));
                            return Curry._1(setCanvasSize, (function (param) {
                                          return /* tuple */[
                                                  canvasWidth,
                                                  canvasHeight
                                                ];
                                        }));
                          }));
                    return PromiseUtils$BwaxAdmin.resolve(/* () */0);
                  }));
          }
          return ;
        }), /* array */[url]);
  var pointOfEvent = function (e) {
    return Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (el) {
                  var rectX = DomUtils$BwaxAdmin.getBoundingClientRect(el)[/* x */0];
                  var rectY = DomUtils$BwaxAdmin.getBoundingClientRect(el)[/* y */1];
                  var pointX = e.clientX - rectX;
                  var pointY = e.clientY - rectY;
                  return /* tuple */[
                          pointX,
                          pointY
                        ];
                }));
  };
  var toScaled = function (value, dim) {
    return Belt_Option.getWithDefault(Belt_Option.map(naturalSize, (function (ns) {
                      if (dim >= 121) {
                        return value * canvasSize[1] / ns[1];
                      } else {
                        return value * canvasSize[0] / ns[0];
                      }
                    })), value);
  };
  var toNatural = function (value, dim) {
    var raw = Belt_Option.getWithDefault(Belt_Option.map(naturalSize, (function (ns) {
                if (dim >= 121) {
                  return value * ns[1] / canvasSize[1];
                } else {
                  return value * ns[0] / canvasSize[0];
                }
              })), value);
    return Math.round(raw * 100.0) / 100.0;
  };
  var numberValue = function (path) {
    return Belt_Option.getWithDefault(pathValue(avatarOptions, path, Js_json.decodeNumber), 0.0);
  };
  var x = toScaled(numberValue(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "point",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "x",
                  /* [] */0
                ])
            ])), /* x */120);
  var y = toScaled(numberValue(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "point",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "y",
                  /* [] */0
                ])
            ])), /* y */121);
  var dw = toScaled(numberValue(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "dw",
              /* [] */0
            ])), /* x */120);
  var dh = toScaled(numberValue(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "dh",
              /* [] */0
            ])), /* y */121);
  var shape = pathValue(avatarOptions, /* :: */Caml_chrome_debugger.simpleVariant("::", [
          "shape",
          /* [] */0
        ]), Js_json.decodeString);
  var tmp;
  tmp = shape === "Circle" ? Css.pct(50.0) : /* zero */-789508312;
  var className = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.position(/* absolute */-1013592457),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.top(Css.pxFloat(y)),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.left(Css.pxFloat(x)),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.width(Css.pxFloat(dw)),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.height(Css.pxFloat(dh)),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.userSelect(/* none */-922086728),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.borderRadius(tmp),
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Css.backgroundImage(/* `url */Caml_chrome_debugger.polyVar("url", [
                                              5843823,
                                              avatarUrl
                                            ])),
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Css.backgroundSize(/* cover */-899416265),
                                          /* [] */0
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]));
  var avatarImage = naturalSize !== undefined ? React.createElement("div", {
          className: className
        }) : null;
  var numberValue$1 = function (path) {
    return Belt_Option.getWithDefault(pathValue(qrCodeOptions, path, Js_json.decodeNumber), 0.0);
  };
  var x$1 = toScaled(numberValue$1(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "point",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "x",
                  /* [] */0
                ])
            ])), /* x */120);
  var y$1 = toScaled(numberValue$1(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "point",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "y",
                  /* [] */0
                ])
            ])), /* y */121);
  var dw$1 = toScaled(numberValue$1(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "dw",
              /* [] */0
            ])), /* x */120);
  var dh$1 = toScaled(numberValue$1(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "dh",
              /* [] */0
            ])), /* y */121);
  var className$1 = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.position(/* absolute */-1013592457),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.top(Css.pxFloat(y$1)),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.left(Css.pxFloat(x$1)),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.width(Css.pxFloat(dw$1)),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.height(Css.pxFloat(dh$1)),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.backgroundColor(Css.white),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.padding(Css.rem(0.25)),
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Css.userSelect(/* none */-922086728),
                                      /* [] */0
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]));
  var qrCodeImage;
  if (naturalSize !== undefined) {
    var match$5 = fieldValue(qrCodeOptions, "point", (function (x) {
            return Caml_option.some(x);
          }));
    qrCodeImage = match$5 !== undefined ? React.createElement("img", {
            className: className$1,
            src: qrCodePlachoderImg
          }) : null;
  } else {
    qrCodeImage = null;
  }
  var numberValue$2 = function (path) {
    return Belt_Option.getWithDefault(pathValue(nicknameOptions, path, Js_json.decodeNumber), 0.0);
  };
  var x$2 = toScaled(numberValue$2(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "point",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "x",
                  /* [] */0
                ])
            ])), /* x */120);
  var y$2 = toScaled(numberValue$2(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "point",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "y",
                  /* [] */0
                ])
            ])), /* y */121);
  var dw$2 = toScaled(numberValue$2(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "dw",
              /* [] */0
            ])), /* x */120);
  var textSize = toScaled(Belt_Option.getWithDefault(fieldValue(nicknameOptions, "fontSize", Js_json.decodeNumber), 24.0), /* x */120);
  var textColor = StringUtils$BwaxAdmin.after(Belt_Option.getWithDefault(fieldValue(nicknameOptions, "color", Js_json.decodeString), defaultNickNameColor), 1);
  var className$2 = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Css.position(/* absolute */-1013592457),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.top(Css.pxFloat(y$2)),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.left(Css.pxFloat(x$2)),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.width(Css.pxFloat(dw$2)),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.overflow(Css.hidden),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.whiteSpace(/* nowrap */867913355),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.userSelect(/* none */-922086728),
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Css.lineHeight(Css.em(1.0)),
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Css.fontSize(Css.pxFloat(textSize)),
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              Css.color(Css.hex(textColor)),
                                              /* [] */0
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ]));
  var nickname;
  if (naturalSize !== undefined) {
    var point = fieldValue(nicknameOptions, "point", (function (v) {
            return Caml_option.some(v);
          }));
    nickname = point !== undefined ? React.createElement("div", {
            className: className$2
          }, previewNickname) : null;
  } else {
    nickname = null;
  }
  var containerClass = Css.style(Belt_List.concat(Belt_List.keepMap(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Belt_Option.map(url, (function (u) {
                          return Css.backgroundImage(/* `url */Caml_chrome_debugger.polyVar("url", [
                                        5843823,
                                        u
                                      ]));
                        })),
                  /* [] */0
                ]), (function (x) {
                  return x;
                })), /* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.position(/* relative */903134412),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.width(Css.pxFloat(canvasSize[0])),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.height(Css.pxFloat(canvasSize[1])),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.backgroundSize(/* contain */427065300),
                          /* [] */0
                        ])
                    ])
                ])
            ])));
  var currentSelecting;
  if (selectFrom !== undefined && selectTo !== undefined) {
    var match$6 = selectTo;
    var match$7 = selectFrom;
    var fy = match$7[1];
    var fx = match$7[0];
    var className$3 = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            Css.opacity(0.1),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Css.backgroundColor(Css.green),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Css.position(/* absolute */-1013592457),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Css.left(Css.pxFloat(fx)),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Css.top(Css.pxFloat(fy)),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Css.width(Css.pxFloat(match$6[0] - fx)),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Css.height(Css.pxFloat(match$6[1] - fy)),
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        Css.zIndex(2000),
                                        /* [] */0
                                      ])
                                  ])
                              ])
                          ])
                      ])
                  ])
              ])
          ]));
    currentSelecting = React.createElement("div", {
          className: className$3
        });
  } else {
    currentSelecting = null;
  }
  var onMouseDown = function (e) {
    if (editingArea !== undefined) {
      var point = pointOfEvent(e);
      return Curry._1(setSelectFrom, (function (param) {
                    return point;
                  }));
    } else {
      return /* () */0;
    }
  };
  var onMouseLeave = function (param) {
    if (hoverOn !== undefined) {
      return Curry._1(setHoverOn, (function (param) {
                    return ;
                  }));
    } else {
      return /* () */0;
    }
  };
  var onMouseMove = function (e) {
    if (selectFrom !== undefined) {
      var point = pointOfEvent(e);
      return Curry._1(setSelectTo, (function (param) {
                    return point;
                  }));
    } else if (editingArea !== undefined) {
      var point$1 = pointOfEvent(e);
      return Curry._1(setHoverOn, (function (param) {
                    return point$1;
                  }));
    } else {
      return /* () */0;
    }
  };
  var onMouseUp = function (param) {
    var newValue;
    if (selectFrom !== undefined && selectTo !== undefined) {
      var match = selectTo;
      var match$1 = selectFrom;
      var fy = match$1[1];
      var fx = match$1[0];
      newValue = {
        point: {
          x: toNatural(fx, /* x */120),
          y: toNatural(fy, /* y */121)
        },
        dw: toNatural(match[0] - fx, /* x */120),
        dh: toNatural(match[1] - fy, /* y */121)
      };
    } else {
      newValue = undefined;
    }
    if (editingArea !== undefined) {
      if (newValue !== undefined) {
        Curry._1(onAreaSelected, Caml_option.valFromOption(newValue));
      }
      
    }
    Curry._1(setSelectFrom, (function (param) {
            return ;
          }));
    return Curry._1(setSelectTo, (function (param) {
                  return ;
                }));
  };
  var mouseOverTip;
  if (hoverOn !== undefined) {
    var match$8 = hoverOn;
    var className$4 = Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
            Css.position(/* absolute */-1013592457),
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                Css.padding2(Css.zero, Css.rem(0.5)),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    Css.backgroundColor(Css.rgba(0, 0, 0, 0.5)),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        Css.color(Css.white),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Css.zIndex(1000),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Css.userSelect(/* none */-922086728),
                                /* [] */0
                              ])
                          ])
                      ])
                  ])
              ])
          ]));
    var size;
    if (selectFrom !== undefined && selectTo !== undefined) {
      var match$9 = selectTo;
      var match$10 = selectFrom;
      var dw$3 = toNatural(match$9[0] - match$10[0], /* x */120);
      var dh$2 = toNatural(match$9[1] - match$10[1], /* y */121);
      size = " ( " + (String(dw$3) + (" * " + (String(dh$2) + " )")));
    } else {
      size = "";
    }
    var nx = toNatural(match$8[0], /* x */120);
    var ny = toNatural(match$8[1], /* y */121);
    mouseOverTip = React.createElement("div", {
          className: className$4
        }, "" + (String(nx) + (", " + (String(ny) + (" " + (String(size) + ""))))));
  } else {
    mouseOverTip = null;
  }
  return React.createElement("div", {
              ref: containerRef,
              className: containerClass,
              onMouseDown: onMouseDown,
              onMouseLeave: onMouseLeave,
              onMouseMove: onMouseMove,
              onMouseUp: onMouseUp
            }, avatarImage, qrCodeImage, nickname, mouseOverTip, currentSelecting);
}

var defaultNickNameFontSize = 24.0;

var defaultCanvasWidth = 420.0;

var make = PosterCanvas;

exports.defaultNickNameColor = defaultNickNameColor;
exports.defaultNickNameFontSize = defaultNickNameFontSize;
exports.fieldValue = fieldValue;
exports.pathValue = pathValue;
exports.toDomEl = toDomEl;
exports.qrCodePlachoderImg = qrCodePlachoderImg;
exports.defaultCanvasWidth = defaultCanvasWidth;
exports.make = make;
/* qrCodePlachoderImg Not a pure module */
