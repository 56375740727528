// Generated from Bwax.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete generic visitor for a parse tree produced by BwaxParser.

function BwaxVisitor() {
	antlr4.tree.ParseTreeVisitor.call(this);
	return this;
}

BwaxVisitor.prototype = Object.create(antlr4.tree.ParseTreeVisitor.prototype);
BwaxVisitor.prototype.constructor = BwaxVisitor;

// Visit a parse tree produced by BwaxParser#exprs.
BwaxVisitor.prototype.visitExprs = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#defs.
BwaxVisitor.prototype.visitDefs = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#moduleDecl.
BwaxVisitor.prototype.visitModuleDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#importExposing.
BwaxVisitor.prototype.visitImportExposing = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#importExposingAll.
BwaxVisitor.prototype.visitImportExposingAll = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#importName.
BwaxVisitor.prototype.visitImportName = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#exposedName.
BwaxVisitor.prototype.visitExposedName = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#powOp.
BwaxVisitor.prototype.visitPowOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#getterExpr.
BwaxVisitor.prototype.visitGetterExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#binOpExpr.
BwaxVisitor.prototype.visitBinOpExpr = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#callWithCC.
BwaxVisitor.prototype.visitCallWithCC = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#refExp.
BwaxVisitor.prototype.visitRefExp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#consOp.
BwaxVisitor.prototype.visitConsOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#tuple.
BwaxVisitor.prototype.visitTuple = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#lambda.
BwaxVisitor.prototype.visitLambda = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#literalExp.
BwaxVisitor.prototype.visitLiteralExp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#pipeTo.
BwaxVisitor.prototype.visitPipeTo = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#qualRef.
BwaxVisitor.prototype.visitQualRef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#compTo.
BwaxVisitor.prototype.visitCompTo = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#record.
BwaxVisitor.prototype.visitRecord = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#bracket.
BwaxVisitor.prototype.visitBracket = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#let.
BwaxVisitor.prototype.visitLet = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#if.
BwaxVisitor.prototype.visitIf = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#woRecordList.
BwaxVisitor.prototype.visitWoRecordList = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#logicNot.
BwaxVisitor.prototype.visitLogicNot = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#case.
BwaxVisitor.prototype.visitCase = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeAnnoatedExp.
BwaxVisitor.prototype.visitTypeAnnoatedExp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#apply.
BwaxVisitor.prototype.visitApply = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#equalityOp.
BwaxVisitor.prototype.visitEqualityOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#addOp.
BwaxVisitor.prototype.visitAddOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#constructor.
BwaxVisitor.prototype.visitConstructor = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#list.
BwaxVisitor.prototype.visitList = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#compareOp.
BwaxVisitor.prototype.visitCompareOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#compFrom.
BwaxVisitor.prototype.visitCompFrom = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#unit.
BwaxVisitor.prototype.visitUnit = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#external.
BwaxVisitor.prototype.visitExternal = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#recordGet.
BwaxVisitor.prototype.visitRecordGet = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#mulOp.
BwaxVisitor.prototype.visitMulOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#appendOp.
BwaxVisitor.prototype.visitAppendOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#logicOp.
BwaxVisitor.prototype.visitLogicOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#recordUpdate.
BwaxVisitor.prototype.visitRecordUpdate = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#pipeFrom.
BwaxVisitor.prototype.visitPipeFrom = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#binOp.
BwaxVisitor.prototype.visitBinOp = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#caseBranch.
BwaxVisitor.prototype.visitCaseBranch = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#fieldTypeDecl.
BwaxVisitor.prototype.visitFieldTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeDecl.
BwaxVisitor.prototype.visitTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeDeclAlt.
BwaxVisitor.prototype.visitTypeDeclAlt = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#varTypeDecl.
BwaxVisitor.prototype.visitVarTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#roVarTypeDecl.
BwaxVisitor.prototype.visitRoVarTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#unitTypeDecl.
BwaxVisitor.prototype.visitUnitTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#tupleTypeDecl.
BwaxVisitor.prototype.visitTupleTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#recordExtTypeDecl.
BwaxVisitor.prototype.visitRecordExtTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#recordTypeDecl.
BwaxVisitor.prototype.visitRecordTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#roRecordTypeDecl.
BwaxVisitor.prototype.visitRoRecordTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#woOptionalRecordTypeDecl.
BwaxVisitor.prototype.visitWoOptionalRecordTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#woRecordTypeDecl.
BwaxVisitor.prototype.visitWoRecordTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#opaquedTypeDecl.
BwaxVisitor.prototype.visitOpaquedTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeDeclBracket.
BwaxVisitor.prototype.visitTypeDeclBracket = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#constr0TypeDecl.
BwaxVisitor.prototype.visitConstr0TypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#constrTypeDecl.
BwaxVisitor.prototype.visitConstrTypeDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#constrDecl.
BwaxVisitor.prototype.visitConstrDecl = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeAnnot.
BwaxVisitor.prototype.visitTypeAnnot = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#valueDef.
BwaxVisitor.prototype.visitValueDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#functionDef.
BwaxVisitor.prototype.visitFunctionDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeAliasDef.
BwaxVisitor.prototype.visitTypeAliasDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#typeDef.
BwaxVisitor.prototype.visitTypeDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#opaqueTypeDef.
BwaxVisitor.prototype.visitOpaqueTypeDef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#recordPtn.
BwaxVisitor.prototype.visitRecordPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#bracketForPtn.
BwaxVisitor.prototype.visitBracketForPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#tuplePtn.
BwaxVisitor.prototype.visitTuplePtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#consListPtn.
BwaxVisitor.prototype.visitConsListPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#varPtn.
BwaxVisitor.prototype.visitVarPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#wildcardPtn.
BwaxVisitor.prototype.visitWildcardPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#literalPtn.
BwaxVisitor.prototype.visitLiteralPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#nilListPtn.
BwaxVisitor.prototype.visitNilListPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#constructorPtn.
BwaxVisitor.prototype.visitConstructorPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#unitPtn.
BwaxVisitor.prototype.visitUnitPtn = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#varRef.
BwaxVisitor.prototype.visitVarRef = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#charLiteral.
BwaxVisitor.prototype.visitCharLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#stringLiteral.
BwaxVisitor.prototype.visitStringLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#longStringLiteral.
BwaxVisitor.prototype.visitLongStringLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#integerLiteral.
BwaxVisitor.prototype.visitIntegerLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#floatLiteral.
BwaxVisitor.prototype.visitFloatLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#trueLiteral.
BwaxVisitor.prototype.visitTrueLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#falseLiteral.
BwaxVisitor.prototype.visitFalseLiteral = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by BwaxParser#recBind.
BwaxVisitor.prototype.visitRecBind = function(ctx) {
  return this.visitChildren(ctx);
};



exports.BwaxVisitor = BwaxVisitor;