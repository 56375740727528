
import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom'

import { runDefinitionQuery } from 'bwax/query/runClientQuery'

import loadSandboxDefinitions from 'bwax-ui/legacy/store/loaders/loadSandboxDefinitions'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import ApplicationContext from 'bwax/util/ApplicationContext';

import Search from 'Client/ml/ui/components/Search';

import { Tabs } from 'antd';

import Editor from '@monaco-editor/react';

import UrlPattern from 'url-pattern'

const { TabPane } = Tabs;

import lang_entry from 'bwax/ml/lang/lang_entry.bs'

import pageEntry from 'bwax-ui/ml/page_entry.bs'

import { Helmet } from 'react-helmet-async';

import "./PresetCodes.less";

export default function PresetCodes(props) {

    const { match, routeTo } = props;
    const { tab } = match.params;

    const [adtSrcs, setAdtSrcs] = useState({ "loading": "" });
    const [querySrcs, setQuerySrcs] = useState({ "loading": "" });
    const [cmdSrcs, setCmdSrcs] = useState({ "loading": "" });

    const [customSrcs, setCustomSrcs] = useState({ "loading": "" });

    const [fragmentSrcs, setFragmentSrcs] = useState({ "loading": "" });

    const dlc = useContext(DataLoaderContext)
    const { sessionToken } = dlc;


    const { currentApplication } = useContext(ApplicationContext);

    //// load  defiition    
    async function loadDefinitions() {
        const loadedDefinitions = await loadSandboxDefinitions(currentApplication.code)({
            sessionToken, sandbox: true
        })

        const { allEntities, allDataTypes, adminPages, pageComponents } = loadedDefinitions;

        const [ adtSrcs, querySrcs, cmdSrcs] = lang_entry.get_query_srcs_js(allEntities, allDataTypes);
        setAdtSrcs(adtSrcs);
        setQuerySrcs(querySrcs);
        setCmdSrcs(cmdSrcs);

        // get all custom srcs: 

        const pageComponentSrcs = pageEntry.get_page_component_srcs_js(pageComponents);
        const customAdminPageSrcs = pageEntry.get_custom_admin_page_srcs_js(adminPages);

        setCustomSrcs({
            ...pageComponentSrcs,
            ...customAdminPageSrcs,
        })

    }

    async function loadFragments() {

        try {
            const queryText = "query ($condition: [[ListPageFragmentConditionInput]]) { " + 
                " listPageFragment (condition:$condition) { edges { node { id name code { src previewData } } } } }"

            const result = await runDefinitionQuery(dlc)(queryText)({ 
                condition: [ { field: "应用", op: "eq", value: currentApplication.id
            }]});

            const fragmentSrcs = JSON.parse(result).data.listPageFragment.edges.map(e => ({
                name: e.node.name,
                src: e.node.code.src
            })).reduce((acc, current) => {
                return {
                    ...acc,
                    [current.name]: current.src
                }
            }, {});

            setFragmentSrcs(fragmentSrcs);

        } catch (e) {
            console.error(e);
        }



    }

    useEffect(() => {
        loadDefinitions()
        loadFragments();
    }, [ currentApplication ])

    useEffect(() => {
        // update the active:

    }, [querySrcs])

    const tabs = {
        "标准库": lang_entry.runtime_srcs_js,
        "Entities": adtSrcs,
        "Query API": querySrcs,
        "Cmd API": cmdSrcs,
        "UI框架": pageEntry.widget_srcs_js,
        "自定义扩展": customSrcs,
        "页面片段": fragmentSrcs,
    }


    let topActiveKey = Object.keys(tabs)[0];
    for (let label in tabs) {
        const srcs = tabs[label];

        const insideIt = Object.keys(srcs).some(name => {
            return name == tab
        });
        if (insideIt || label == tab) {
            topActiveKey = label;
            break;
        }
    }

    const routeToTab = tab => {
        const pattern = new UrlPattern(match.path)
        const to = pattern.stringify({
            ...match.params,
            tab
        })
        routeTo(to, { modal: false })
    }


    const getTabUrl = tab => {
        const pattern = new UrlPattern(match.path)
        return pattern.stringify({
            ...match.params,
            tab
        })
    }


    return (
        <div className="preset-card-container">
            <Tabs type="card" className="" activeKey={topActiveKey} onChange={key => {
                routeToTab(key)
            }}>
                {
                    Object.keys(tabs).map(label => {
                        const srcs = tabs[label];
                        return (
                            <TabPane tab={label} key={label}>
                                <SrcTabs srcs={srcs} activeKey={tab} getTabUrl={getTabUrl} />
                            </TabPane>
                        )
                    })
                }
            </Tabs>
        </div>
    )
}

// ---
function ReadOnlyEditor({ code }) {

    return (
        <Editor
            language="bwax"
            value={code}
            theme="bwax-light"
            options={{
                fontSize: 11,
                readOnly: true
            }}
        />
    )
}


function SrcTabs({ srcs = {}, activeKey, getTabUrl }) {

    const name = activeKey && srcs[activeKey] ? activeKey : Object.keys(srcs)[0];

    const [keyword, setKeyword] = useState(undefined);

    const [filteredSrcs, setFilteredSrcs] = useState(srcs);

    useEffect(() => {
        if (keyword) {
            const filteredSrcs = Object.keys(srcs).reduce((acc, key) => {
                if (key.indexOf(keyword.trim()) !== -1 || key === name) {
                    return { ...acc, [key]: srcs[key] }
                } else {
                    return acc
                }
            }, {})
            setFilteredSrcs(filteredSrcs);
        } else {
            setFilteredSrcs(srcs);
        }

    }, [keyword, srcs]);


    // 如果超过 20 个，就显示 Input Search
    function renderSearch() {
        return (
            <div className="search-wrapper">
                <Search {...{ width: "100%", onKeywordChange: setKeyword }} />
            </div>
        )
    }


    const code = srcs[name] || ""

    function renderTabCtrl(k) {
        const cn = k == name ? "active" : "";
        return (
            <Link className={"tab-ctrl " + cn} tabIndex={1} key={k} to={getTabUrl(k)}
            >
                {k}
            </Link>
        )
    }

    return (
        <>
            <Helmet>
                <title>{"内置函数 | " + name}</title>
            </Helmet>
            <div className="src-tabs">
                <div className="tab-content">
                    <ReadOnlyEditor code={code} />
                </div>
                <div className="tab-bar">
                    {Object.keys(srcs).length > 20 ? renderSearch() : null}
                    {Object.keys(filteredSrcs).map(renderTabCtrl)}
                </div>
            </div>
        </>
    )
}


