
import MessageUtil from 'Client/js/ui/Message';

/**
 * level - success, error, info, warning, warn, loading,  default is 'info'
 * onClose - another action? // can it be ? TODO
 * text - the message it self
 * duration - auto-dismiss duration. if it is 0, no auto-dismiss. default is 1.5
 */
export default function Message () {
    return async function (params) {
        const { level = 'info', text } = params
        
        const func = MessageUtil[level]
        if(func) {
            func(text)
        }
    }
}