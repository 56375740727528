// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BwColoredRefedButton = require("./BwColoredRefedButton");
var Popconfirm = require("bwax-ui/components/Popconfirm");

function createButton(prim) {
  return BwColoredRefedButton.create(prim);
}

function BwButton(Props) {
  var params = Props.params;
  var label = Belt_Option.getWithDefault(params.label, "点击");
  var buttonType = Belt_Option.getWithDefault(params.buttonType, "default");
  var disabled = Belt_Option.getWithDefault(params.disabled, false);
  var onClick = Belt_Option.getWithDefault(params.onClick, (function (param) {
          return /* () */0;
        }));
  var size = Belt_Option.getWithDefault(params.size, "default");
  var color = params.color;
  var compact = params.compact;
  var inMenu = Belt_Option.getWithDefault(params.inMenu, false);
  var ghost = Belt_Option.getWithDefault(params.ghostButton, false);
  var match = params.confirm;
  if (match !== undefined && !disabled) {
    var confirm = Caml_option.valFromOption(match);
    var positions = Belt_Option.getWithDefault(confirm.positions, /* array */["top"]);
    var align = Belt_Option.getWithDefault(confirm.align, "center");
    var okText = Belt_Option.getWithDefault(confirm.okText, "确定");
    var cancelText = Belt_Option.getWithDefault(confirm.cancelText, "取消");
    var title = Belt_Option.getWithDefault(confirm.title, "您确定？");
    return Popconfirm.create({
                title: title,
                positions: positions,
                align: align,
                okText: okText,
                cancelText: cancelText,
                padding: 8,
                onConfirm: (function (param) {
                    return Curry._1(onClick, /* () */0);
                  }),
                children: BwColoredRefedButton.create({
                      type_: buttonType,
                      disabled: disabled,
                      size: size,
                      ghost: ghost,
                      label: label,
                      color: color,
                      inMenu: inMenu,
                      compact: compact
                    })
              });
  } else {
    return BwColoredRefedButton.create({
                type_: buttonType,
                onClick: onClick,
                disabled: disabled,
                size: size,
                ghost: ghost,
                label: label,
                color: color,
                inMenu: inMenu,
                compact: compact
              });
  }
}

var make = BwButton;

exports.createButton = createButton;
exports.make = make;
/* ./BwColoredRefedButton Not a pure module */
