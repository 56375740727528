
import React from 'react'

import { AiFillEye, AiFillEyeInvisible, AiOutlineDelete } from 'react-icons/ai'

import Popover from 'bwax-ui/components/legacy/Popover'
import Tooltip from 'bwax-ui/components/Tooltip';
import ShadowEdit from './ShadowEdit'

import './ShadowList.less'

export default function ShadowList({ isBoxShadow, shadows, activeShadowIndex, setActiveShadowIndex, renderShadowInfoStr, onChange }) {

    function renderShadowEdit () {

        function changeShadows (newShadow) {
            const newShadows = shadows.map((shadow, index) => {
                if(index === activeShadowIndex) {
                    return newShadow
                } else {
                    return shadow
                }
            })

            onChange(newShadows)
        }

        return (
            <ShadowEdit isBoxShadow={isBoxShadow} shadow={shadows[activeShadowIndex]} onChange={shadow => changeShadows(shadow)}/>
        )
    }

    function toggleShadowVisible (e, hideIndex) {
        e.stopPropagation()

        function getChangedShadows (shadows) {
            return shadows.map((s, idx) => {
                return idx === hideIndex ? {
                    ...s,
                    visible: !s.visible
                } : s
            })
        }

        onChange(getChangedShadows(shadows))
    }

    function removeShadow (e, removeIndex) {
        e.stopPropagation()

        function getChangedShadows (shadows) {
            return shadows.filter((_, idx) => idx !== removeIndex)
        }
        onChange(getChangedShadows(shadows))
    }

    return (
        <div className='shadows-list'>
            {
                shadows.map((shadow, index) => {
                    return (
                        <Popover key={index} content={renderShadowEdit()} positions={["bottom", "top"]} reposition={true} 
                            visible={index === activeShadowIndex} onClickOutside={_ => {
                                    setActiveShadowIndex(null)
                        }}>
                            <div className='shadow-item' style={{ opacity: shadow.visible ? 1 : 0.5 }} onClick={() => {
                                setActiveShadowIndex(index)
                            }}>
                                <div className='shadow-color-display' style={{ backgroundColor: shadow.color }} />
                                {renderShadowInfoStr(index)}
                                <div className={`actions ${activeShadowIndex || activeShadowIndex === 0 ? 'hide' : ''}`}>
                                    <Tooltip text={"Hide"}>
                                        <div className='visible-toggle' onClick={e => toggleShadowVisible(e, index)}>
                                            { shadow.visible ? <AiFillEye /> : <AiFillEyeInvisible/> }
                                        </div>
                                    </Tooltip>
                                    <Tooltip text={"Remove"}>
                                        <div className='remove' onClick={e => removeShadow(e, index)}>
                                            <AiOutlineDelete/>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </Popover>
                    )
                })
            }
        </div>
    )
}
