

function getField(path, dataEntity, allEntities, allDataTypes) {

    function getTheField (currentPath, entity, filterAllowed, sortingAllowed ) {
        const [fname, ...rest] = currentPath;
        if (rest.length === 0) {
            // 最后一节，可以是 virtual field
            let allFields = [ ...entity.fields, ...entity.virtualFields ];
            let field = allFields.find(f => f.name == fname);
            if(field) {
                return {
                    ...field,
                    filterable: filterAllowed && field.filterable,
                    sortable: sortingAllowed && field.sortable,
                }
            } else {
                console.warn("No field found `" + fname + "`", path.join("."))
                return undefined
            }

        } else {
            // dot field can ONLY include materalized virtual field
            // let materalizedFields =
            //     [ 
            //         ...entity.fields, 
            //         ...(entity.virtualFields.filter (
            //             f => f.materalized || f.backLink =
            //         ))
            //     ];

            // dot field 不能是 multivalued

            let dotFields = [ ...entity.fields, ...entity.virtualFields ].filter(
                f => !f.multivalued
            )
            let field = dotFields.find(f => f.name == fname)
            if (!field) {
                console.warn("No field found `" + fname + "`", path.join("."))
                return undefined
            } else {
                return getNextField(rest, field, filterAllowed && field.filterable, sortingAllowed && field.sortable );
            }
        }
    }

    function getNextField(remainingPath, currentField, filterAllowed, sortingAllowed ) {

        if (remainingPath.length === 0) {
            return currentField
        } else {
            // the current field must be a link and reference to some other type;
            if (currentField.type == "Link" && currentField.options.entity) {
                //
                let nameOrKey = currentField.options.entity;
                let targetEntity = allEntities.find(e => e.name == nameOrKey || e.key == nameOrKey);

                if (!targetEntity) {
                    // 
                    console.warn("No entity found `" + nameOrKey + "`", path.join("."))
                    return undefined
                } else {
                    return getTheField(remainingPath, targetEntity, filterAllowed, sortingAllowed )
                }
            } else {
                console.warn("Field is not a link", currentField, path.join("."))
                return undefined
            }
        }
    }

    return getTheField(path, dataEntity, true, true );

}


// { cname?, name, type, options, multivalued, required, desc, key, displayParams, filterable, sortable, defaultValue }

export function getFieldsFromFieldItems (fieldItems, dataEntity, allEntities, allDataTypes, flat = false) {

    return Object.keys(fieldItems).map(cname => {
        let config = fieldItems[cname];
        
        let name = (typeof(config) == "string") ? config : (config.path || cname);
    
        // flat 意味着只有一层，没有往下关联
        let path = flat ? [name] : name.split(".");

        let f = getField(path, dataEntity, allEntities, allDataTypes);

        let directField = path.length == 1;

        if (f) {
            const { 
                type, options, multivalued, required, 
                desc, key, 
                initializable, 
                updatable,
                filterable,
                sortable,
                defaultValue,
                // name,
            } = f;
            return { 
                cname, name, type, options, multivalued, 
                required, desc, key,
                initializable: directField && initializable,
                updatable: directField && updatable,
                filterable,
                sortable,
                defaultValue,
                config: (
                    (typeof(config) == "string") ? {path: config} : config
                )
            };
        } else {
            return undefined
        }
    }).filter(x => !!x);
}


export function conditionToExpr (refName, condition, dataEntity) {

    // normalize, make the condition [[{}]]
    function normalizeCondition () {
        if(!Array.isArray(condition)) {
            // 1. {}
            return [[condition]]
            
        } else if (Array.isArray(condition) && !condition.some(c => Array.isArray(c)) ){
            // 2. [{}]
            return [condition]
        } else {
            return condition
        }
    }
    let normalized = normalizeCondition();

    const allFields = [ ...dataEntity.fields, ...dataEntity.virtualFields, ];

    function buildEquation(refName, n, value) {
        const field = allFields.find(f => f.name == n);
        if(!field) {
            console.error("Cannot find Field", n, refName, value, dataEntity);
        }
        let fieldName = n;            
        if(field.type == "Link") {
            fieldName = n + ".id"
        }
        return buildConditionEquation(refName + "." + fieldName, value);
    }

    // outest, outer, inner
    let outest = normalized.map(e => {

        let outers = e.map(c => {
            let equations = Object.keys(c).map(n => {
                let conditionValue = c[n];        
                let equation = buildEquation(refName, n, conditionValue);
                return equation
        
            });
            return equations.join(" && ");
        })
        // join all the "AND"
        return outers.join("  &&  ");
    })

    let joined = outest.length === 1 ? outest[0] : outest.map(s => "(" + s + ")").join(" || ");

    return "${" + joined + "}";
}


export function buildConditionEquation (target, conditionValue) {

    // 暂时只支持 eq, ne, lt, gt, lte, gte
    let equation = (operator, value) => {
        return target + " " + operator + " " + JSON.stringify(value);
    }

    let equationBuilders = {
        "eq": value => {
            if (value === null) {
                return "isNull(" + target + ")";
            } else {
                return equation("==", value);
            }
        },
        "ne": value => {
            if (value === null) {
                return "!isNull(" + target + ")";
            } else {
                return equation("!=", value);
            }
        },
        "gt": value => equation(">", value),
        "gte": value => equation(">=", value),
        "lte": value => equation("<=", value),
        "lt": value => equation("<", value)
    };

    let buildEquation = (op, value) => {
        let func = equationBuilders[op];
        if (!func) {
            console.error("There is no equation builder for op", op);
            return equationBuilders["eq"](value)
        } else {
            return func(value)
        }
    }

    // test if it is has operation
    // 只要是 object 就当做有 operation
    if (typeof (conditionValue) == 'object' && conditionValue !== null) {
        return Object.keys(conditionValue).map(op => {
            return buildEquation(op, conditionValue[op]);
        }).join(" && ")
    } else {
        return buildEquation("eq", conditionValue);
    }

}