
import React from 'react'

import { getFieldInput } from 'Client/js/builders/input/fieldInputs'

import FieldOptionsInput from "Client/js/ui/widgets/input/FieldOptionsInput";

import { Input } from 'antd'
import { CrontabSamples } from 'Client/js/components/CrontabSamples';

import FieldDefaultValueInput from 'Client/js/ui/widgets/input/FieldDefaultValueInput';
import EntityNameInput from 'Client/js/ui/widgets/input/EntityNameInput';
import BacklinkFieldNameInput from 'Client/js/ui/widgets/input/BacklinkFieldNameInput';

import EventFieldNameInput from  'Client/js/ui/widgets/input/EventFieldNameInput';

export default function FieldInput({
    field, entity, facade, value, onChange, fixedValues = {},

    hostEntityName, // 只用于 backlink field name

}) {

    const { name, key } = field;

    function getInput() {

        // 这里是硬写的。 TODO 根据相关值编辑， 需要有一个更理想的解决方案
        if (name == "字段选项" && key == "options") {
            return (v, onChange, params) => {
                return (
                    <FieldOptionsInput
                        {...{
                            dataType: value.字段类型,
                            value: v, onChange
                        }}
                    />
                )
            }
            // }
        } else if (name == "默认值" && key == "defaultValue") {
            return (v, onChange, params) => (
                <FieldDefaultValueInput
                    params={{
                        ...params,
                        dataType: value.字段类型,
                        dataOptions: value.字段选项,
                        dataMultivalued: value.多值,
                    }}
                    value={v}
                    onChange={onChange}
                />
            );
        } else if (name == "定时设置") {
            return (v, onChange) => {
                return (
                    <div>
                        <Input
                            value={v}
                            onChange={e => onChange(e.target.value)}
                        />
                        <CrontabSamples value={v} />
                    </div>
                )
            }

        // 下面两个用于反向关联字段
        } else if (name == "关联实体名") {
            return (v, onChange) => (
                <EntityNameInput
                    required={true}
                    value={v}
                    onChange={onChange}
                />
            )

        } else if (name == "对方字段名") {

            return (v, onChange) => {
                return (
                    <BacklinkFieldNameInput
                        entityName={value["关联实体名"]}
                        hostEntityName={hostEntityName}
                        value={v}
                        onChange={onChange}
                    />
                )
            }
        
        // 下面两个用于事件处理
        } else if (name === "实体名" && entity.key === "EventHandler") {
            // 只用于事件处理
            return (v, onChange) => {
                return (
                    <EntityNameInput
                        entityFilter={e => e.eventWatched}
                        required={true}
                        value={v}
                        onChange={onChange}
                    />
                )
            }
        } else if(name === "监听字段" && entity.key === "EventHandler") {
            return (v, onChange) => {
                return (
                    <EventFieldNameInput
                        {...{
                            multivalued: true,
                            entityName: value["实体名"],
                            value: v, onChange,
                        }}
                    />
                )
            }        

        } else {
            return getFieldInput(field, "no-use", { allEntities: facade.entities, allDataTypes: facade.dataTypes, dataEntity: entity });

        }
    }

    const v = value[field.name];

    return getInput()(
        v,
        nv => {
            onChange({
                ...value,
                [field.name]: nv
            })
        },
        {
            fixedValue: fixedValues[field.name]
        }
    )

}
