import React, { useState } from 'react';

import getItemHandler from '../getItemHandler';

import Modal from 'bwax-ui/ml/widget/impl/misc/Modal';
import Message from 'Client/js/ui/Message';

import { Button } from 'rsuite';


export default function DeletionDialog({ itemToDelete, setItemToDelete, facade, closeItem, reloadList }) {

    const [isDeleting, setIsDeleting] = useState(false);

    const item = itemToDelete;


    function renderContent() {
        if (!item) return null;


        const { deleteValue, deletionPrompt } = getItemHandler(item.itemType, facade);

        const message = deletionPrompt ? deletionPrompt(item) : (
            <span>你确定要删除“<b>{item.getName()}</b>”吗？</span>
        );

        return (
            <div className="studio-dialog">
                <div className="dialog-section">
                    <div className="prompt-message">
                       { message }
                    </div>
                </div>
                <div className="dialog-section">
                    <Button appearance="primary" color="red" loading={isDeleting} onClick={async () => {
                       
                        setIsDeleting(true);
                        const [_success, error] = await deleteValue(item.data.id);

                        if (error) {
                            Message.error(error);
                        } else {

                            closeItem(item);
                            await reloadList(item.itemType, item.parentItem);

                            Message.success("已删除");

                            setItemToDelete(undefined);
                        }

                        setIsDeleting(false);

                    }}>
                        删除
                    </Button>
                    <Button onClick={() => {
                        setItemToDelete(undefined);
                    }}>
                        取消
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Modal {...{
            visible: !!item,
            containerStyle: {
                borderRadius: 4,
                width: 200,
            },
            modalContent: renderContent()
        }} />
    )
}

