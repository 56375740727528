

import React from 'react';

import Input from 'rsuite/Input';

import "./NumberRangeInput.less";

export default function NumberRangeInput(props) {

    // ~value:(option('a), option('a)), ~onChange: ((option('a), option('a))
    const { value, onChange } = props;
    const [minNum, maxNum] = value || [];


    const toFloat = v => {
        
        if (v == null || v == undefined || v == "") {
            return null
        } else {
            return parseFloat(v);
        }
    }

    const updateMin = e => onChange([toFloat(e), maxNum]);
    const updateMax = e => onChange([minNum, toFloat(e)]);

    function ensureControl(v) {
        return v === undefined || v === null ?  "" : v;
    }

    return (
        <div className="number-range-input">
            <Input {...{
                value: ensureControl(minNum), onChange: updateMin,
                type: "number", className: "left-input",
            }} />
            <span className="range-connector">~</span>
            <Input {...{
                    value: ensureControl(maxNum), onChange: updateMax,
                    type: "number", className: "right-input",
            }} />
        </div>
    )

}
