
import useDataLoader from './useDataLoader'
import loadBaseSettings from "./loaders/loadBaseSettings"

export default function useBaseSettings () {
    const key = `BaseSettings`
    const { getData } = useDataLoader({[key]: loadBaseSettings()})
    const [ baseSettings ] = getData(key)
    return baseSettings
}
