import React, { Component } from 'react'

import { Icon } from '@ant-design/compatible';

import './RecordColOverflow.less'

export default class RecordColOverflow extends Component {
    
    state = {
        showInfoIcon: false
    }

    render() {
        const { showInfoIcon } = this.state

        const setContentRef = (node) => {
            if (node && !this.overflowCol) {
                this.overflowCol = node
                const computedStyle = window.getComputedStyle(node)
                const maxHeight = computedStyle.getPropertyValue('max-height')
                if (node.clientHeight >= 200 && maxHeight !== 'none') {
                    this.setState({ showInfoIcon: true })
                }
            }
        }

        const { className } = this.props;

        return (
            <div
                className={`record-col-overflow ${showInfoIcon ? 'overflow-col-hover' : ''} ${className ? className : ''}`}
                onClick={showInfoIcon ? this.props.onClick : () => { }}>
                <div
                    ref={setContentRef}
                    className="record-col-overflow-cot"
                >
                    {this.props.children}
                </div>
                {
                    showInfoIcon ?
                        <Icon
                            className="overflow-info-circle"
                            type="info-circle"
                            size="small">
                        </Icon> : null
                }
            </div>
        )
    }
}
