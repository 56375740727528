// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../ml/utils/json.bs.js");
var DictUtils$BwaxAdmin = require("./DictUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function isJsonEqual(firstO, secondO) {
  var maybeFirst = Json$BwaxAdmin.parse(JSON.stringify(firstO));
  var maybeSecond = Json$BwaxAdmin.parse(JSON.stringify(secondO));
  if (maybeFirst !== undefined && maybeSecond !== undefined) {
    var match = Js_json.classify(Caml_option.valFromOption(maybeFirst));
    var match$1 = Js_json.classify(Caml_option.valFromOption(maybeSecond));
    if (typeof match === "number") {
      switch (match) {
        case /* JSONFalse */0 :
            if (typeof match$1 === "number") {
              return match$1 === 0;
            } else {
              return false;
            }
        case /* JSONTrue */1 :
            if (typeof match$1 === "number") {
              return match$1 === 1;
            } else {
              return false;
            }
        case /* JSONNull */2 :
            if (typeof match$1 === "number") {
              return match$1 >= 2;
            } else {
              return false;
            }
        
      }
    } else {
      switch (match.tag | 0) {
        case /* JSONString */0 :
            if (typeof match$1 === "number" || match$1.tag) {
              return false;
            } else {
              return match[0] === match$1[0];
            }
        case /* JSONNumber */1 :
            if (typeof match$1 === "number" || match$1.tag !== /* JSONNumber */1) {
              return false;
            } else {
              return match[0] === match$1[0];
            }
        case /* JSONObject */2 :
            if (typeof match$1 === "number" || match$1.tag !== /* JSONObject */2) {
              return false;
            } else {
              return isJsonDictEqual(match[0], match$1[0]);
            }
        case /* JSONArray */3 :
            if (typeof match$1 === "number" || match$1.tag !== /* JSONArray */3) {
              return false;
            } else {
              var y = match$1[0];
              var x = match[0];
              if (x.length === y.length) {
                return Belt_Array.every(Belt_Array.zip(x, y), (function (param) {
                              return isJsonEqual(param[0], param[1]);
                            }));
              } else {
                return false;
              }
            }
        
      }
    }
  }
  console.log("Some json parse error", firstO, secondO, Caml_obj.caml_equal(firstO, secondO));
  return Caml_obj.caml_equal(firstO, secondO);
}

function isJsonDictEqual(x, y) {
  var xKeys = Object.keys(x);
  var yKeys = Object.keys(y);
  if (xKeys.length === yKeys.length && Belt_Array.every(Belt_Array.zip(xKeys, yKeys), (function (param) {
            return param[0] === param[1];
          }))) {
    return Belt_Array.every(Belt_Array.map(xKeys, (function (key) {
                      return /* tuple */[
                              Js_dict.get(x, key),
                              Js_dict.get(y, key)
                            ];
                    })), (function (pair) {
                  var match = pair[0];
                  if (match !== undefined) {
                    var match$1 = pair[1];
                    if (match$1 !== undefined) {
                      return isJsonEqual(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1));
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }));
  } else {
    return false;
  }
}

function decodeArray(json, decoder) {
  return Belt_Option.map(Js_json.decodeArray(json), (function (arry) {
                return Belt_Array.keepMap(arry, decoder);
              }));
}

function decodeList(json, decoder) {
  return Belt_Option.map(decodeArray(json, decoder), Belt_List.fromArray);
}

function decodeStringList(json) {
  return decodeList(json, Js_json.decodeString);
}

function decodeFloatDict(json) {
  return Belt_Option.map(Js_json.decodeObject(json), (function (dict) {
                return Js_dict.fromArray(Belt_Array.keepMap(Js_dict.entries(dict), (function (param) {
                                  var k = param[0];
                                  return Belt_Option.map(Js_json.decodeNumber(param[1]), (function (s) {
                                                return /* tuple */[
                                                        k,
                                                        s
                                                      ];
                                              }));
                                })));
              }));
}

function decodeStringArray(json) {
  return decodeArray(json, Js_json.decodeString);
}

function decodeFromEntry(dict, key, defaultValue, decoder) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, key), decoder), defaultValue);
}

function decodeFromEntryFlat(dict, key, decoder) {
  return Belt_Option.flatMap(Js_dict.get(dict, key), decoder);
}

function decodeRecord(json, recordDecoder) {
  return Belt_Option.flatMap(Js_json.decodeObject(json), (function (dict) {
                return Curry._1(recordDecoder, (function (key, dfv, fieldDecoder) {
                              return decodeFromEntry(dict, key, dfv, fieldDecoder);
                            }));
              }));
}

var Decoder = {
  decodeArray: decodeArray,
  decodeList: decodeList,
  decodeStringList: decodeStringList,
  decodeFloatDict: decodeFloatDict,
  decodeStringArray: decodeStringArray,
  decodeFromEntry: decodeFromEntry,
  decodeFromEntryFlat: decodeFromEntryFlat,
  decodeRecord: decodeRecord
};

function parse(str) {
  try {
    return Caml_option.some(JSON.parse(str));
  }
  catch (exn){
    return ;
  }
}

function getField(json, key) {
  return Belt_Option.flatMap(Js_json.decodeObject(json), (function (dict) {
                return Js_dict.get(dict, key);
              }));
}

function getByIndex(json, idx) {
  return Belt_Option.flatMap(Js_json.decodeArray(json), (function (arr) {
                return Belt_Array.get(arr, idx);
              }));
}

function getFieldValue(json, key, decoder) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(json), (function (dict) {
                    return Js_dict.get(dict, key);
                  })), decoder);
}

var emptyObj = { };

var emptyArr = /* array */[];

function getByPath(base, path) {
  return Belt_List.reduce(path, Caml_option.some(base), (function (acc, name) {
                if (acc !== undefined) {
                  var base = Caml_option.valFromOption(acc);
                  var fieldName = name;
                  var maybeIndex;
                  try {
                    maybeIndex = Caml_format.caml_int_of_string(fieldName);
                  }
                  catch (exn){
                    maybeIndex = undefined;
                  }
                  var triedArrayAccess = Belt_Option.flatMap(maybeIndex, (function (index) {
                          var index$1 = index;
                          return Belt_Option.flatMap(Js_json.decodeArray(base), (function (arr) {
                                        return Belt_Array.get(arr, index$1);
                                      }));
                        }));
                  if (Belt_Option.isSome(triedArrayAccess)) {
                    return triedArrayAccess;
                  } else {
                    var name$1 = fieldName;
                    return Belt_Option.flatMap(Js_json.decodeObject(base), (function (dict) {
                                  return Js_dict.get(dict, name$1);
                                }));
                  }
                }
                
              }));
}

function valueOfPath(maybeJson, path, decode) {
  return Belt_Option.flatMap(Belt_Option.flatMap(maybeJson, (function (j) {
                    return getByPath(j, path);
                  })), Curry.__1(decode));
}

function absorb(maybeJson, target) {
  if (maybeJson !== undefined) {
    return Belt_Option.getWithDefault(Belt_Option.map(Js_json.decodeObject(Caml_option.valFromOption(maybeJson)), (function (dict) {
                      return DictUtils$BwaxAdmin.mergeWith(dict, Belt_Option.getWithDefault(Js_json.decodeObject(target), { }));
                    })), target);
  } else {
    return target;
  }
}

function setField(maybeJson, key, value) {
  var targetDict = Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            key,
            value
          ],
          /* [] */0
        ]));
  if (maybeJson !== undefined) {
    return Belt_Option.getWithDefault(Belt_Option.map(Js_json.decodeObject(Caml_option.valFromOption(maybeJson)), (function (dict) {
                      return DictUtils$BwaxAdmin.mergeWith(dict, targetDict);
                    })), targetDict);
  } else {
    return targetDict;
  }
}

function removeField(maybeJson, key) {
  if (maybeJson !== undefined) {
    return Belt_Option.getWithDefault(Belt_Option.map(Js_json.decodeObject(Caml_option.valFromOption(maybeJson)), (function (dict) {
                      return DictUtils$BwaxAdmin.remove(dict, key);
                    })), emptyObj);
  } else {
    return emptyObj;
  }
}

exports.isJsonEqual = isJsonEqual;
exports.isJsonDictEqual = isJsonDictEqual;
exports.Decoder = Decoder;
exports.parse = parse;
exports.getField = getField;
exports.getByIndex = getByIndex;
exports.getFieldValue = getFieldValue;
exports.emptyObj = emptyObj;
exports.emptyArr = emptyArr;
exports.getByPath = getByPath;
exports.valueOfPath = valueOfPath;
exports.absorb = absorb;
exports.setField = setField;
exports.removeField = removeField;
/* Json-BwaxAdmin Not a pure module */
