
import React, { useState, useEffect, useContext } from 'react'

// import { Layout, Menu } from 'antd'

import { Icon } from '@ant-design/compatible';

import * as MDIcons from 'react-icons/md';

import useDataLoader from "bwax-ui/legacy/store/useDataLoader";

import loadGeneralSetting from 'bwax-ui/legacy/store/loaders/loadGeneralSetting'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import setupPageEnv from 'bwax-ui/page/setupPageEnv';

import { Link } from 'react-router-dom'

import menu_group from 'Client/ml/menu_group.bs'

import evaluateGeneralValue from 'Client/js/adminApp/evaluateGeneralValue';

import { markNav } from 'Client/js/breadcrumb/breadcrumbHelper'
import { inspectPath } from 'Client/js/breadcrumb/BreadcrumbSimple'

import { Menu } from '@arco-design/web-react';
// import { IconApps, IconBug, IconBulb } from '@arco-design/web-react/icon';
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

export default function AdminMenuBar(props) {
    const {
        extensions = [],
        location,
        history,

        currentUser,

        currentApplication,

        tenantCode,

        facade,

        collapsed, toggleCollapsed,

    } = props

    // states
    const dlc = useContext(DataLoaderContext);

    const appCode = currentApplication.code;

    const NAME = "导航栏";
    const key = `definitions::${appCode}::GeneralSetting::${NAME}`;
    const { getData } = useDataLoader({ [key]: loadGeneralSetting(NAME, appCode) })
    const [menuSetting] = getData(key);

    // menu gruops is now (appcode, menugroups);
    // 为了在切换 App 时保持一致
    const [menuGroups, setMenuGroups] = useState(undefined);

    function saveMenuGroups(menuGroups) {
        setMenuGroups(menuGroups)
        if (props.setMenuGroups) {
            props.setMenuGroups(menuGroups);
        }
    }

    function getMenuGroups(customMenuGroups) {

        const extGroups = getCustomExtGroups({ exts: extensions, currentUser, currentApplication, tenantCode })

        const baseMenuGroup = (() => {
            if (!customMenuGroups) {
                return extGroups

            } else {
                const base = customMenuGroups || [];
                return [...base, ...extGroups];

            }
        })();

        function wrapPath(item) {
            const { items, path } = item;
            if (items) {
                return {
                    ...item,
                    items: items.map(wrapPath)
                }
            } else if (path) {
                return {
                    ...item,
                    path: "/app/" + (currentApplication ? currentApplication.code.toLowerCase() : "default") + path
                }
            } else {
                return item
            }
        }

        return [appCode, baseMenuGroup.map(wrapPath)]

    }

    useEffect(() => {

        if (menuSetting === undefined) {
            return
        }

        if (menuSetting === null) {
            saveMenuGroups(getMenuGroups(null));
            return
        }

        (async () => {

            const domainEnv = {
                mobileHost: document.userenv.mobileHosts[0],
                isSandbox: dlc.sandbox,
                protocol: "https",
                tenantCode: dlc.tenantCode,
            }

            const { host, pathname, hash, search } = document.location;
            const webEnv = {
                protocol: "https",
                host,
                currentURLPathL: pathname + hash + search,
                isSandbox: dlc.sandbox,
                isIOS: dlc.userenv.isIOS,
                isWeChat: dlc.userenv.isWeChat,
                isMobile: dlc.userenv.isMobile,
                tenantCode: dlc.tenantCode,
            }

            const [ast, dts, entity_dict, data_type_dict, env, externalNames, ] = await setupPageEnv(menuSetting.compiled, facade)

            const [r, e] = await evaluateGeneralValue(
                { domainEnv, webEnv, dlc, entity_dict, data_type_dict, base_env: env, dts, ast },
            );

            // console.log("Execution uses", end - start, "ms");
            if (e) {
                console.warn(e);
            }
            if (r && !e) {
                let mg = menu_group.build_menu_group(r);
                saveMenuGroups(getMenuGroups(mg))
            }

        })()

    }, [menuSetting])

    const pathname = location.pathname

    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);


    function getSelectedKeys (pathToCheck, menuGroups) {
        if(!menuGroups) return [];
        let selectedKeys = [];
        function iter(item, parentGroups){          
            const { path, items: children } = item;
            const itemsInLine = [ ...parentGroups, item]

            function isMatch() {
                if(pathToCheck.match(/\/app\/([^\/]+)\/entities\//)) {
                    return pathToCheck == path
                } else {
                    return pathToCheck.startsWith(path)
                }
            }
            
            if(isMatch())  {
                const key = itemsInLine.map(t => t.name).join("::");
                selectedKeys.push(key);
            }
            if(children){
                children.forEach(i => iter(i, itemsInLine))
            }
        }
        menuGroups[1].forEach(i => iter(i, []));
        return selectedKeys
    }

    function initializeMenuStatus() {
        if (!menuGroups) return {};
        const inspected = inspectPath(pathname)
        const pathToCheck = inspected ? inspected.pathWithoutTab : pathname;

        const activeGroup = menuGroups[1].find(
            (group) => {
                const { path, items } = group;
                if (path) {
                    return pathToCheck == path
                } else {
                    return items.some(item => pathToCheck == item.path)
                }
            }
        )
        setOpenKeys(activeGroup ? [activeGroup.name] : []);

        setSelectedKeys(getSelectedKeys(pathToCheck, menuGroups));
    }
    //
    useEffect(() => {
        initializeMenuStatus();
    }, [menuGroups]);

    useEffect(() => {
        const inspected = inspectPath(pathname)
        const pathToCheck = inspected ? inspected.pathWithoutTab : pathname;

        setSelectedKeys(getSelectedKeys(pathToCheck, menuGroups));
    }, [pathname])

    // console.log(">>> MDIcons", MDIcons);

    function getIconElement(iconName) {
        const MDIcon = MDIcons["Md" + iconName];
        if (MDIcon) {
            return <span style={{
                display: "inline-flex",
                fontSize: "14px",
                // marginRight: "10px",
                opacity: 0.8,
                alignItems: "center",
                transform: "translateY(2.5px)",
                marginRight: 16
            }}><MDIcon /></span>
        } else {
            return (
            <span style={{
                display: "inline-flex",

                fontSize: "14px",
                // marginRight: "10px",
                opacity: 0.8,
                alignItems: "center",
                transform: "translateY(2.5px)",
                marginRight: 16
            }}>
                <Icon type={iconName} />
            </span>)

        }
    }




    function renderMenuOrGroup(item, parentGroups) {
        const { name, icon = 'folder', items, path } = item;
        const showIcon = parentGroups.length == 0;
        const iconElement = getIconElement(icon);

        const key = [ ...parentGroups, item].map(t => t.name).join("::");
        
        if (path) {
            return (
                <MenuItem key={key}>
                    {showIcon ? iconElement : null}
                    <Link to={path} style={{ 
                            display: "inline",
                            marginLeft: showIcon ? 0 : 10
                        }} onClick={e => {
                        e.preventDefault();
                        // markNav([...parentGroups, item])
                    }}>
                        <span style={{ fontSize: 12 }}>{name}</span>
                    </Link>
                </MenuItem>
            )
        } else {
            return (
                <SubMenu key={key}
                    title={
                        <>
                             {showIcon ? iconElement : null}
                            <span style={{ marginLeft: showIcon ? 0 : 10,  fontSize: 12 }}>{name}</span>
                        </>
                    }
                >
                    {items.map(i => renderMenuOrGroup(i, [...parentGroups, item]))}
                </SubMenu>
            )
        }
    }

    function resolveNavPath(paths) {
        if(menuGroups && menuGroups[1]) {
            const [ navPath , _] = paths.reduce(([acc, items], name) => {
                const item = items.find(i => i.name == name);
                return [[ ...acc, item], item.items || []];
            }, [[], menuGroups[1]])
            return navPath
        } 
        return []
    }

    return (
        <Menu style={{ 
                width: 200, height: "100%", paddingTop: 2,
                borderRight: "0.5px solid rgb(229, 230, 235)"
            }}
            hasCollapseButton {...{
                openKeys, selectedKeys,
                onClickMenuItem: (key) => { 
                    const navPath = resolveNavPath(key.split("::"));                       
                    const target = navPath[navPath.length - 1]; 
                    if(target && target.path) {
                        markNav(navPath);
                        history.push(target.path);

                    }
                },
                onClickSubMenu: (key, openKeys) => { setOpenKeys(openKeys) },
            }}
            collapse={collapsed}
            onCollapseChange={toggleCollapsed}
            
        >
            {menuGroups ? menuGroups[1].map(group => renderMenuOrGroup(group, [])) : null}
        </Menu>
    )
}


function getCustomExtGroups({ tenantCode, exts, currentUser, currentApplication }) {
    const isAdmin = currentUser && currentUser.systemRoles && currentUser.systemRoles.some(r => r === 'Admin')

    // 暂时只在 default app 里显示:
    const isDefaultApp = currentApplication.code.toLowerCase() === "default";


    let enabledExts = [
        tenantCode ? null : "蜂蜡系统",
        tenantCode ? null : "基础设定",
        tenantCode ? null : "用户管理",
        tenantCode ? null : "微信公众号",
        "邀请有礼",
        "小鹅通",
        tenantCode ? null : "短信",
        tenantCode ? null : "OpenAI"
    ].filter(x => !!x);

    const extensions = exts.filter(ext => {
        return enabledExts.indexOf(ext.name) !== -1
    })

    const settingGroup = {
        name: "系统设置",
        icon: 'setting',
        items: extensions.filter(
            ext => {
                return ext.settings && ext.settings.length > 0
            }
        ).map(ext => ({
            name: ext.name,
            path: `/ext/${ext.key}/settings`,
        }))
    }

    // hardcode 一个后台权限，一个选项设置：
    settingGroup.items = [
        tenantCode ? null : {
            name: "选项设置",
            path: "/lookup-values"
        },
        {
            name: "后台权限",
            path: "/ext/Auth/admin-users"
        },
        ...(settingGroup.items)
    ].filter(x => !!x);

    if (isAdmin && isDefaultApp) {
        return [settingGroup]
    } else {
        return []
    }

}

