
import React, { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

import { Transforms } from 'slate'

import { BiMoviePlay } from 'react-icons/bi'
import { MdOutlineAudioFile } from 'react-icons/md'

import {
    Modal,
    Input,
    message,
    Checkbox,
    Upload,
    AntdButton,
    Icon,
    Tooltip
} from 'bwax-ui/auxiliary/uikit'

import Popconfirm from 'bwax-ui/components/Popconfirm'

import "./MediaButton.less"

// import { insertMedia } from '../media/mediaPluginHelpers'
import { isVideo, isAudio, resolveFromURL } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'


export default function MediaButton(props) {

    const { editor, uploadVideo, mediaType } = props

    const [selectMediaVisible, setSelectMediaVisible] = useState(false)
    const [uploadLocalVideo, setUploadLocalVideo] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [mediaUrl, setMediaUrl] = useState('')
    const [localMediaUrl, setLocalMediaUrl] = useState('')
    const [uploadFile, setUploadFile] = useState(null)
    const [progress, setProgress] = useState({ loaded: 0 })
    const [cancelUploadFn, setCancelUploadFn] = useState(null)

    const mediaTypeObj = {
        'video': '视频',
        'audio': '音频'
    }
    const mediaTypeStr = mediaTypeObj[mediaType]

    const mediaFileTypes = mediaType === 'video' ? ['mp4', 'mov'] : ['ogg', 'mp3', 'wav']

    const CANCEL_FUNCTION_MSG = '取消上传'

    function closeModal() { setSelectMediaVisible(false) }

    function cancelModal() {
        if (uploading) {
            const result = window.confirm(`确定离开此页面？将取消上传${mediaTypeStr}！`)
            if (result) {
                cancelUploadFn && cancelUploadFn(CANCEL_FUNCTION_MSG)
                closeModal()
            }
        } else if (localMediaUrl) {
            const result = window.confirm(`确定离开此页面？将清空已上传的${mediaTypeStr}！`)
            if (result) {
                closeModal()
            }
        } else {
            closeModal()
        }
    }

    function clearField() {
        unstable_batchedUpdates(() => {
            setMediaUrl('')
            setLocalMediaUrl('')
            setUploadLocalVideo(false)
            setUploadFile(null)
            setProgress({ loaded: 0 })
            setUploading(false)
        })
    }

    function confirmInsertMedia() {

        if (!mediaUrl.trim() && !uploadLocalVideo) {
            message.warn('播放地址不能为空')
            return
        }

        if (uploadLocalVideo && !localMediaUrl.trim()) {
            message.warn(`没有本地${mediaTypeStr}`)
            return
        }

        const mediaInfo = uploadLocalVideo ? resolveFromURL(localMediaUrl.trim()) : resolveFromURL(mediaUrl.trim())
        
        if (mediaInfo) {
            const { playURL } = mediaInfo
            const mediaType = (() => {
                if (isVideo(playURL)) {
                    return "VIDEO"
                }
                if (isAudio(playURL)) {
                    return "AUDIO"
                }
                return "UNKNOWN"
            })()

            

            if (mediaType !== "UNKNOWN") {
                const atomicNode = {
                    type: isVideo(playURL) ? "video" : "audio",
                    url: mediaInfo.playURL,
                    children: [{ text: '' }]
                }
                const newText = {
                    type: "paragraph",
                    children: [{ text: "" }]
                }

                Transforms.setNodes(editor, atomicNode)
                Transforms.insertNodes(editor, newText)
                closeModal()
            } else {
                message.warn('暂不支持该格式')
            }
        } else {
            message.warn('暂不支持该格式')
        }
    }

    function beforeUpload(file) {

        if (file.size > 100 * 1024 * 1024) {
            message.warn(`${mediaTypeStr}大小不能超过100MB`, 4)
            return false
        }
        const doUpload = uploadVideo
        if (doUpload) {
            unstable_batchedUpdates(() => {
                setUploading(true)
                setUploadFile(file)
            })

            doUpload(
                file,
                (evt) => setProgress({
                    loaded: evt.loaded,
                    total: evt.total
                }),
                c => setCancelUploadFn(() => c),
                (errs, url) => { //upload completed
                    unstable_batchedUpdates(() => {
                        setUploading(false)
                        setCancelUploadFn(null)
                    })
                    if (errs) {
                        message.error('上传失败：' + errs)
                        console.error(errs);
                    } else {
                        setLocalMediaUrl(url)
                    }
                },
                error => {
                    const resetUploadFields = () => {
                        setUploadFile(null)
                        setProgress({ loaded: 0 })
                        setCancelUploadFn(null)
                        setUploading(false)
                    }
                    if (error && error.message) {
                        if (error.message.match(new RegExp(CANCEL_FUNCTION_MSG))) {
                            unstable_batchedUpdates(resetUploadFields)
                        } else {
                            message.error(error.message)
                        }
                    }
                }
            )
        }
        return false
    }

    function renderUploadProgress() {

        if (!uploadFile) {
            return null
        }

        const progressRate = progress.total ? progress.loaded / progress.total : 0
        const progressRatePercent = Math.round((progressRate * 100)) + "%"

        return (
            <div className="media-upload-progress">
                <div className="media-upload-info">
                    <a
                        target="_blank"
                        href={localMediaUrl || '#'} >
                        {uploadFile.name}
                        {
                            cancelUploadFn ?
                                <Popconfirm title={`确定取消上传${mediaTypeStr}?`}
                                    onConfirm={() => cancelUploadFn(CANCEL_FUNCTION_MSG)}
                                    okText="确定"
                                    cancelText="取消">
                                    <span className="cancel-upload-tip">取消上传</span>
                                </Popconfirm> : null
                        }
                    </a>
                    <span className="media-upload-progress-rate">{progressRatePercent}</span>
                </div>
                <div className="media-upload-progress-bar">
                    <div className="upload-progress-bar-total">
                        <div
                            style={{ width: progressRatePercent }}
                            className="upload-progress-bar-loaded"></div>
                    </div>
                </div>
                <div className="media-upload-progress-tip">
                    <span>
                        {
                            progress && progress.loaded !== progress.total ?
                                '上传中...' :
                                uploading && (progress.loaded === progress.total) ?
                                    `${mediaTypeStr}转码中...`
                                    : '上传完成'
                        }
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="media-button-cot">
            <button
                className="editor-button"
                onClick={() => setSelectMediaVisible(true)}>
                {/* <i className="fa fa-film" aria-hidden="true"></i> */}
                {
                    mediaType === 'video' ? (
                        <BiMoviePlay style={{ fontSize: 16 }}/>
                    ) : (
                        <MdOutlineAudioFile style={{ fontSize: 16 }}/>
                    )
                }
                
            </button>
            <Modal
                title={`${mediaTypeStr}选择`}
                visible={selectMediaVisible}
                onOk={confirmInsertMedia}
                onCancel={cancelModal}
                okButtonProps={{ disabled: uploading }}
                afterClose={clearField} >
                <div className="media-input-cot">
                    <Input
                        className="media-url-input"
                        value={mediaUrl}
                        placeholder={`直接输入${mediaTypeStr}的播放地址`}
                        onChange={e => setMediaUrl(e.target.value)} />
                    <Checkbox
                        checked={uploadLocalVideo}
                        className="media-upload-check"
                        onChange={e => setUploadLocalVideo(e.target.checked)}>
                        {`上传本地${mediaTypeStr}`}
                    </Checkbox>
                    <Tooltip text={`${mediaTypeStr}上传大小限制为100MB，当前支持上传的${mediaTypeStr}文件格式为${mediaFileTypes.join("、")}`}>
                        <Icon type="question" style={{ cursor: "pointer" }} />
                    </Tooltip>
                    {
                        uploadLocalVideo ?
                            <div className="media-upload-cot">
                                <Upload
                                    accept={mediaFileTypes.map(t => `.${t}`).join(",")}
                                    disabled={uploading}
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}>
                                    <AntdButton>
                                        <Icon type={uploading ? 'loading' : 'upload'} />
                                        {`点击上传${mediaTypeStr}`}
                                    </AntdButton>
                                </Upload>
                                {renderUploadProgress()}
                            </div> : null
                    }
                </div>
            </Modal>
        </div>
    )
}
