

import React from 'react'


import Tooltip from 'bwax-ui/components/Tooltip';
import { MdInfoOutline } from "react-icons/md"


export default function FieldTip({tip, style}) {

    return tip ? (
        <Tooltip text={tip}>
            <span style={{
                marginLeft: 2, opacity: 0.6, fontSize: 11,
                // display: "inline-flex", alignItems: "center",
                fontWeight: "normal",
                ...style
            }} >
                <MdInfoOutline />
            </span>
        </Tooltip>
    ) : null
}
