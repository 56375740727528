import React, { useEffect, useState } from 'react'

import { SketchPicker, GithubPicker } from 'react-color';

import Popover from 'bwax-ui/components/legacy/Popover';

import { ColorContent } from '../display/ColorDisplay';
import { MdDelete } from 'react-icons/md';

import './ColorInput.less';

export default function ColorInput(props) {
    const { value, onChange, allowedColors } = props

    const [editing, setEditing] = useState(value ? { hex: value } : undefined);


    const noValue = value === undefined || value === null;
    
    useEffect(() => {
        // 如果 value 不等于 editing 的值，则重新设置 editing
        if ((value && !editing) || (editing && value !== editing.hex)) {
            setEditing(value ? { hex: value } : undefined)
        }

    }, [value])

    const pickerProps = {
        color: editing,

        onChangeComplete: c => {
            setEditing(c)
            onChange(c.hex)
        }
    }

    const picker = (allowedColors && allowedColors.length > 0) ? (
        <GithubPicker {...{
            colors: allowedColors, triangle: "hide",
            ...pickerProps
        }} />
    ) : (
        <SketchPicker {...pickerProps} />
    )

    return (
        <div className="admin--color-input">
            <Popover content={picker}>
                <ColorContent color={editing && editing.hex} placeholderText="选择颜色" />
            </Popover>
            {
                noValue ? null : (
                    <div className="remove-icon" onClick={_ => {
                        // set null
                        onChange(null)
                    }}>
                        <MdDelete />
                    </div>
                )
            }
        </div>
    )

}
