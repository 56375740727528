// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function build_menu_group(setting) {
  var convert_item_value = function (iv) {
    if (typeof iv === "number" || iv.tag !== /* V_tagged */6) {
      return ;
    } else {
      switch (iv[0]) {
        case "AdminPage" :
            var match = iv[1];
            if (match) {
              var match$1 = match[0][0];
              if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
                return ;
              } else {
                var match$2 = match$1[0];
                if (typeof match$2 === "number" || !(match$2.tag === /* String */2 && !match[1])) {
                  return ;
                } else {
                  return "/pages/" + (String(match$2[0]) + "");
                }
              }
            } else {
              return ;
            }
        case "Entity" :
            var match$3 = iv[1];
            if (match$3) {
              var match$4 = match$3[0][0];
              if (typeof match$4 === "number" || match$4.tag !== /* V_literal */5) {
                return ;
              } else {
                var match$5 = match$4[0];
                if (typeof match$5 === "number" || !(match$5.tag === /* String */2 && !match$3[1])) {
                  return ;
                } else {
                  return "/entities/" + (String(match$5[0]) + "");
                }
              }
            } else {
              return ;
            }
        case "FormManagement" :
            return "/dataforms";
        case "Url" :
            var match$6 = iv[1];
            if (match$6) {
              var match$7 = match$6[0][0];
              if (typeof match$7 === "number" || match$7.tag !== /* V_literal */5) {
                return ;
              } else {
                var match$8 = match$7[0];
                if (typeof match$8 === "number" || !(match$8.tag === /* String */2 && !match$6[1])) {
                  return ;
                } else {
                  return match$8[0];
                }
              }
            } else {
              return ;
            }
        default:
          return ;
      }
    }
  };
  var convert_item = function (it) {
    var match = it[0];
    if (typeof match === "number" || match.tag !== /* V_tagged */6) {
      return ;
    } else {
      switch (match[0]) {
        case "Group" :
            var match$1 = match[1];
            if (match$1) {
              var match$2 = match$1[0][0];
              if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                return ;
              } else {
                var match$3 = match$2[0];
                if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                  return ;
                } else {
                  var match$4 = match$1[1];
                  if (match$4) {
                    var match$5 = match$4[0][0];
                    if (typeof match$5 === "number" || match$5.tag !== /* V_literal */5) {
                      return ;
                    } else {
                      var match$6 = match$5[0];
                      if (typeof match$6 === "number" || match$6.tag !== /* String */2) {
                        return ;
                      } else {
                        var match$7 = match$4[1];
                        if (match$7) {
                          var match$8 = match$7[0][0];
                          if (typeof match$8 === "number" || !(match$8.tag === /* V_list */1 && !match$7[1])) {
                            return ;
                          } else {
                            var unpack = function (tuple) {
                              var match = tuple[0];
                              if (typeof match === "number" || match.tag) {
                                return ;
                              } else {
                                var match$1 = match[0];
                                if (match$1) {
                                  var match$2 = match$1[0][0];
                                  if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                                    return ;
                                  } else {
                                    var match$3 = match$2[0];
                                    if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                                      return ;
                                    } else {
                                      var match$4 = match$1[1];
                                      if (match$4 && !match$4[1]) {
                                        var k = match$3[0];
                                        return Plate$BwaxAdmin.$$Option.map((function (v) {
                                                      var name = k;
                                                      var path = v;
                                                      return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                    /* tuple */[
                                                                      "name",
                                                                      name
                                                                    ],
                                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                        /* tuple */[
                                                                          "path",
                                                                          path
                                                                        ],
                                                                        /* [] */0
                                                                      ])
                                                                  ]));
                                                    }), convert_item_value(match$4[0][0]));
                                      } else {
                                        return ;
                                      }
                                    }
                                  }
                                } else {
                                  return ;
                                }
                              }
                            };
                            var item_list_json = Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.keep_map(unpack, match$8[0]));
                            return Caml_option.some(Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              /* tuple */[
                                                "name",
                                                match$3[0]
                                              ],
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  /* tuple */[
                                                    "icon",
                                                    match$6[0]
                                                  ],
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      /* tuple */[
                                                        "items",
                                                        item_list_json
                                                      ],
                                                      /* [] */0
                                                    ])
                                                ])
                                            ])));
                          }
                        } else {
                          return ;
                        }
                      }
                    }
                  } else {
                    return ;
                  }
                }
              }
            } else {
              return ;
            }
        case "Item" :
            var match$9 = match[1];
            if (match$9) {
              var match$10 = match$9[0][0];
              if (typeof match$10 === "number" || match$10.tag !== /* V_literal */5) {
                return ;
              } else {
                var match$11 = match$10[0];
                if (typeof match$11 === "number" || match$11.tag !== /* String */2) {
                  return ;
                } else {
                  var match$12 = match$9[1];
                  if (match$12) {
                    var match$13 = match$12[0][0];
                    if (typeof match$13 === "number" || match$13.tag !== /* V_literal */5) {
                      return ;
                    } else {
                      var match$14 = match$13[0];
                      if (typeof match$14 === "number" || match$14.tag !== /* String */2) {
                        return ;
                      } else {
                        var match$15 = match$12[1];
                        if (match$15 && !match$15[1]) {
                          var iconType = match$14[0];
                          var name = match$11[0];
                          var build_item = function (path) {
                            return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          /* tuple */[
                                            "name",
                                            name
                                          ],
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              /* tuple */[
                                                "icon",
                                                iconType
                                              ],
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  /* tuple */[
                                                    "path",
                                                    path
                                                  ],
                                                  /* [] */0
                                                ])
                                            ])
                                        ]));
                          };
                          return Plate$BwaxAdmin.$$Option.map(build_item, convert_item_value(match$15[0][0]));
                        } else {
                          return ;
                        }
                      }
                    }
                  } else {
                    return ;
                  }
                }
              }
            } else {
              return ;
            }
        default:
          return ;
      }
    }
  };
  var alt = setting[0];
  var tmp;
  tmp = typeof alt === "number" || alt.tag !== /* V_list */1 ? /* [] */0 : Plate$BwaxAdmin.List.keep_map(convert_item, alt[0]);
  return Curry._1(Plate$BwaxAdmin.List.to_array, tmp);
}

exports.build_menu_group = build_menu_group;
/* Plate-BwaxAdmin Not a pure module */
