// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../../lib/bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Admin_widget$BwaxAdmin = require("./admin_widget.bs.js");

import './AdminLayout.less'
;

var $$ResizeObserver = { };

function renderWidgetCell(context, count, path, index, widget) {
  var match = count > 0;
  var w = 100.0 / (
    match ? count : 1
  );
  return React.createElement("div", {
              key: String(index),
              className: "widget-cell " + Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.flexGrow(1.0),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.width(Css.pct(w)),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.maxWidth(Css.pct(w)),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.minWidth(Css.pct(w)),
                                  /* [] */0
                                ])
                            ])
                        ])
                    ]))
            }, Admin_widget$BwaxAdmin.render(context, path, 0, false, widget));
}

function renderWidgetBox(context, noTopPadding, path, count, index, widgetBox) {
  var maybeHeight = widgetBox[1];
  var widgets = widgetBox[0];
  var firstOne = index === 0;
  var multiple = Plate$BwaxAdmin.List.length(widgets) > 1;
  var boxPath_000 = "box" + String(index);
  var boxPath = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      boxPath_000,
      path
    ]);
  var h = maybeHeight !== undefined ? Css.height(Css.px(maybeHeight)) : Css.height(Css.auto);
  var match = index === (count - 1 | 0);
  var grow = match ? Caml_option.some(Css.flexGrow(10000.0)) : undefined;
  var match$1 = firstOne && noTopPadding;
  var partial_arg = Plate$BwaxAdmin.List.length(widgets);
  return React.createElement("div", {
              key: Plate$BwaxAdmin.Str.from_int(index),
              className: Css.style(Plate$BwaxAdmin.List.clean(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Caml_option.some(Css.width(Css.pct(100.0))),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Caml_option.some(h),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  grow,
                                  /* [] */0
                                ])
                            ])
                        ]))) + (" widget-box" + ((
                    match$1 ? " no-top-padding" : ""
                  ) + (
                    multiple ? " multiple-widgets " : ""
                  )))
            }, Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (param, param$1) {
                        return renderWidgetCell(context, partial_arg, boxPath, param, param$1);
                      }), widgets)));
}

function renderArray(f, l) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map(f, l));
}

function renderWidgetArray(context, noTopPadding, path, l) {
  var partial_arg = Plate$BwaxAdmin.List.length(l);
  return renderArray((function (param, param$1) {
                return renderWidgetBox(context, noTopPadding, path, partial_arg, param, param$1);
              }), l);
}

function toDomEl(theRef) {
  return Caml_option.nullable_to_opt(theRef.current);
}

function AdminLayout$TwoColumns(Props) {
  var context = Props.context;
  var path = Props.path;
  var noTopPadding = Props.noTopPadding;
  var leftList = Props.leftList;
  var rightList = Props.rightList;
  var leftRef = React.useRef(null);
  var rightRef = React.useRef(null);
  return React.createElement("div", {
              className: "two-columns"
            }, React.createElement("div", {
                  ref: leftRef,
                  className: "left-column"
                }, renderWidgetArray(context, noTopPadding, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "left",
                        path
                      ]), leftList)), React.createElement("div", {
                  ref: rightRef,
                  className: "right-column"
                }, renderWidgetArray(context, noTopPadding, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "right",
                        path
                      ]), rightList)));
}

var TwoColumns = {
  make: AdminLayout$TwoColumns
};

function renderWide(context, noTopPaddingSetting, path, index, wide) {
  var isFirst = index === 0;
  var noTopPadding = isFirst && noTopPaddingSetting;
  var indexStr = String(index);
  if (wide.tag) {
    return React.createElement(AdminLayout$TwoColumns, {
                context: context,
                path: /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "two_" + indexStr,
                    path
                  ]),
                noTopPadding: noTopPadding,
                leftList: wide[0],
                rightList: wide[1],
                key: Plate$BwaxAdmin.Str.from_int(index)
              });
  } else {
    return React.createElement("div", {
                key: Plate$BwaxAdmin.Str.from_int(index),
                className: "one-column"
              }, renderWidgetArray(context, noTopPadding, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "one_" + indexStr,
                      path
                    ]), wide[0]));
  }
}

function AdminLayout$WideAndNarrow(Props) {
  var context = Props.context;
  var wideList = Props.wideList;
  var narrowList = Props.narrowList;
  var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      "wide",
      /* [] */0
    ]);
  var partial_arg$1 = Plate$BwaxAdmin.List.length(narrowList);
  var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      "narrow",
      /* [] */0
    ]);
  return React.createElement("div", {
              className: "admin-layout wide-and-narrow"
            }, React.createElement("div", {
                  className: "wide-column"
                }, Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (param, param$1) {
                            return renderWide(context, true, partial_arg, param, param$1);
                          }), wideList))), React.createElement("div", {
                  className: "narrow-column"
                }, Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.indexed_map((function (param, param$1) {
                            return renderWidgetBox(context, true, partial_arg$2, partial_arg$1, param, param$1);
                          }), narrowList))));
}

var WideAndNarrow = {
  make: AdminLayout$WideAndNarrow
};

function AdminLayout$Full(Props) {
  var context = Props.context;
  var topList = Props.topList;
  var wideList = Props.wideList;
  var narrowList = Props.narrowList;
  var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      "wide",
      /* [] */0
    ]);
  var partial_arg$1 = Plate$BwaxAdmin.List.length(topList) === 0;
  return React.createElement("div", {
              className: "admin-layout admin-layout-full"
            }, React.createElement("div", {
                  className: "top-column"
                }, renderWidgetArray(context, true, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "top",
                        /* [] */0
                      ]), topList)), React.createElement("div", {
                  className: "wide-and-narrow"
                }, React.createElement("div", {
                      className: "wide-column"
                    }, renderArray((function (param, param$1) {
                            return renderWide(context, partial_arg$1, partial_arg, param, param$1);
                          }), wideList)), React.createElement("div", {
                      className: "narrow-column"
                    }, renderWidgetArray(context, Plate$BwaxAdmin.List.length(topList) === 0, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "narrow",
                            /* [] */0
                          ]), narrowList))));
}

var Full = {
  make: AdminLayout$Full
};

function AdminLayout$WidgetAndBar(Props) {
  var context = Props.context;
  var side = Props.side;
  var maybeWidth = Props.maybeWidth;
  var widget = Props.widget;
  var bar = Props.bar;
  var reverse = side ? false : true;
  var match;
  if (maybeWidth !== undefined) {
    var sideWidth = maybeWidth;
    if (sideWidth < 1.0 && sideWidth > 0.0) {
      var withWidth = function (p) {
        var w = p * 100.0;
        return " " + Css.style(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.important(Css.width(Css.pct(w))),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.important(Css.maxWidth(Css.pct(w))),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.important(Css.minWidth(Css.pct(w))),
                              /* [] */0
                            ])
                        ])
                    ]));
      };
      match = /* tuple */[
        withWidth(1.0 - sideWidth),
        withWidth(sideWidth)
      ];
    } else {
      match = /* tuple */[
        "",
        ""
      ];
    }
  } else {
    match = /* tuple */[
      "",
      ""
    ];
  }
  return React.createElement("div", {
              className: "admin-layout admin-layout-widget-and-bar" + (
                reverse ? " reverse" : ""
              )
            }, React.createElement("div", {
                  className: "main-widget" + match[0]
                }, Admin_widget$BwaxAdmin.render(context, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "main",
                        /* [] */0
                      ]), 0, true, widget)), React.createElement("div", {
                  className: "side-bar" + match[1]
                }, renderWidgetArray(context, true, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "bar",
                        /* [] */0
                      ]), bar)));
}

var WidgetAndBar = {
  make: AdminLayout$WidgetAndBar
};

function renderSingleWidget(context, widget) {
  return Admin_widget$BwaxAdmin.render(context, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "one",
                /* [] */0
              ]), 0, true, widget);
}

function AdminLayout$OneWidget(Props) {
  var context = Props.context;
  var widget = Props.widget;
  return React.createElement("div", {
              className: "admin-layout one-widget"
            }, renderSingleWidget(context, widget));
}

var OneWidget = {
  make: AdminLayout$OneWidget
};

var defaultWidgetHeight = 320;

exports.$$ResizeObserver = $$ResizeObserver;
exports.defaultWidgetHeight = defaultWidgetHeight;
exports.renderWidgetCell = renderWidgetCell;
exports.renderWidgetBox = renderWidgetBox;
exports.renderArray = renderArray;
exports.renderWidgetArray = renderWidgetArray;
exports.toDomEl = toDomEl;
exports.TwoColumns = TwoColumns;
exports.renderWide = renderWide;
exports.WideAndNarrow = WideAndNarrow;
exports.Full = Full;
exports.WidgetAndBar = WidgetAndBar;
exports.renderSingleWidget = renderSingleWidget;
exports.OneWidget = OneWidget;
/*  Not a pure module */
