
// 类似于 RecordSelect widget，只不过这个用的是新的基于 bwax facade 的查询方式：
import React, { useState, useEffect, useRef } from 'react'

import InputSelect from 'Client/js/ui/components/InputSelect'
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import { getPlainFieldPaths, transformSingleFieldToDisplay } from '../helpers/getFieldsToUse';

// always single:
export default function RecordSelector(props) {

    const { disabled, dataEntity, recordQuery, facade, onChange } = props;

    // 1. 给定 entity name 或者 query config， 通过 bwax 查询
    //      设定返回字段，使用 RecordSelectSearch 类似的逻辑
    //      如果是 entity name 或者标准 query config，支持 search key word
    // 2. 构建 InputSelect 的 options
    // 3. 提供 load more 支持
    // 4. onChange 返回 id，

    const [fetching, setFetching] = useState(false)
    const [keyword, setKeyword] = useState(null);

    const [queriedData, setQueriedData] = useState(undefined);


    const debouncedKeyword = useDebounce(keyword, 360);

    const __PLACEHOLDER__ = "__PLACEHOLDER__";

    //  mock a link field to display:

    const env =  { allEntities: facade.entities, allDataTypes: facade.dataTypes };
    const mockField = { name: __PLACEHOLDER__, type: "Link", options: { entity: dataEntity.key } }
    const fieldDisplay = getFieldDisplay(mockField);
    const { component : Comp, fieldsToValue } = fieldDisplay;
    function renderValue(record) {
        if (record) {  
            const displayFields = fieldsToValue(mockField, env);

            const valueToUse = transformSingleFieldToDisplay(record, displayFields);

            return (
                <Comp {...{
                        value: valueToUse, customParams: { withLink: false }, field: mockField, env
                }} />
            )
        } else {
            return null
        }
    }

    const fieldPaths = getPlainFieldPaths(
        mockField, { allEntities: facade.entities, allDataTypes: facade.dataTypes }
    ).map(p => p.replace("__PLACEHOLDER__" + ".", ""));


    const isCustomQuery = recordQuery ? facade.isCustomQuery(recordQuery) : false;

    const checkIfSearchable = () => {
        // 如果是 custom query config，则不能 search
        return !isCustomQuery
    }

    const pageSize = 10;

    // query the options
    async function queryData(pageSize) {

        const entityName = dataEntity.name;

        const query_config = recordQuery;

        // 支持两种不同的 query_config:
        const allFieldPaths = [...fieldPaths];

        async function doQuery() {           
            if (isCustomQuery) {
                // do custom query
                const queryObj = {
                    query_config,
                    pageSize,
                    offset,
                    outputFieldPaths: [
                        [entityName, allFieldPaths]
                    ]
                }
                const [result, error] = await facade.customQuery(queryObj, {});

                return [result, error];

            } else {
                // do standard query
                const queryObj = {
                    entityName,
                    query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort

                    // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
                    // condition: buildCondition(filter),
                    // sort: buildSorting(entity, sortBy),

                    // search 会取代 query_config 里面的
                    search: debouncedKeyword && debouncedKeyword.trim().length > 0 ? {
                        keyword: debouncedKeyword,
                        //  fields: searchFields
                    } : undefined,

                    // 其他参数
                    pageSize,
                    offset: 0,
                    fieldPaths: allFieldPaths,

                    queryType: "listAll", // 用于 getQueryVars

                };
                const [result, error] = await facade.listAll(queryObj, {});

                return [result, error]

            }

        }

        setFetching(true);
        const [result, error] = await doQuery();
        setFetching(false);

        if (result) {
            setQueriedData(result);
        }


    }

    useEffect(() => {
        queryData();
    }, [debouncedKeyword])

    function handleFocus() {
        setKeyword("")
        // handleSearch("")
    }


    return (
        <InputSelect style={{ width: "100%" }} {...{
            isMulti: false, // 暂时只支持单个记录
            isDisabled: disabled,
            isSearchable: checkIfSearchable(),
            isClearable: true,

            options: queriedData ? queriedData.data.map(record => {
                return {
                    value: record.id,
                    label: renderValue(record),
                    // ...otherProps,
                }
            }) : [],

            onSearch: v => setKeyword(v),
            onFocus: handleFocus,
            // value: prepareValue(value),
            onChange: value => {
                onChange(value);
            },

            onSelect: v => {
            },

            onMenuScrollToBottom: () => {
                if(queriedData) {
                    if(queriedData.data.length === queriedData.count) {
                        console.log("DO Nothing")
                    } else {
                        queryData(queriedData.data.length + pageSize);
                    }
                }               
            },
            isLoading: fetching,

            isOptionDisabled: (option) => {
                // // use itemInList?
                // // itemInList 这个名字要换掉

                // return itemInList ? itemInList(option) : false;
                return false;

            },



        }} />
    )


}