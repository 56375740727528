
import React from 'react';

import { Input } from 'antd';
const { Search: SearchComp } = Input;

import "./Search.less"

export default function Search (props) {
    // 只是承载一下样式：
    const { onKeywordChange = _ => {}, width } = props;

    return (
        <SearchComp className="admin--search"
            onChange={e => {
                const value = e.target.value;
                onKeywordChange(value ? value.trim() : value);
                // setCurrentPage(1);
            }}
        style={{ width: width || 108, borderRadius: 8 }}
    />
    )
}
