

import React, { useRef, useState, useEffect } from 'react'

import ColoredTag from 'Client/js/ui/components/ColoredTag';

import useResizeObserver from '@react-hook/resize-observer'

export default function FlexTags({ texts = [] }) {

    const ref = useRef(null);

    const lineHeight = 19;
    const [actualHeight, setActualHeight] = useState(lineHeight);
    function handleResize() {
        if (ref && ref.current && ref.current.offsetHeight) {
            setActualHeight(ref.current.offsetHeight)
        }
    }

    useEffect(() => {
        setTimeout(() => handleResize(), 16);
    }, [texts.map(t => t.text).join(";;;")])

    useResizeObserver(ref, _ => {
        handleResize();
    });

    const marginBottom = actualHeight >= lineHeight * 2 ? 6 : 0;
    const containerMarginBottom = 0 - marginBottom;

    return (
        <div ref={ref} className="flex-tags" style={{
            marginBottom: containerMarginBottom
        }}>
            {texts.map(({ text, color = "N20" }, i) => {
                return (
                    <ColoredTag key={i} {...{ color, text, textClamped: true }} style={{
                        marginBottom,
                        alignSelf: "flex-start",
                    }} />
                )
            })}
        </div>
    )
}
