// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Plate$BwaxAdmin = require("./plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var q_add_log = "\n  mutation ($input: AddLogInput!){\n    addLog(input: $input) {\n      clientMutationId\n    }\n  }\n";

function add_log(query_runner, level, data) {
  var level_str;
  switch (level) {
    case /* Debug */0 :
        level_str = "debug";
        break;
    case /* Info */1 :
        level_str = "info";
        break;
    case /* Warn */2 :
        level_str = "warn";
        break;
    case /* Error */3 :
        level_str = "error";
        break;
    
  }
  var input = {
    clientMutationId: new Date(),
    level: level_str,
    data: Curry._1(Plate$BwaxAdmin.List.to_array, data)
  };
  Curry._3(query_runner, q_add_log, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "input",
                  input
                ],
                /* [] */0
              ])), undefined).then((function (text) {
          console.log("Logged:", text);
          return Promise.resolve(/* () */0);
        }));
  return /* () */0;
}

exports.q_add_log = q_add_log;
exports.add_log = add_log;
/* Plate-BwaxAdmin Not a pure module */
