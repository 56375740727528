

import React, { useEffect, useState } from 'react'

import InputSelect from 'Client/js/ui/components/InputSelect';
import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

export default function ExtendingEntityNameInput({ value, onChange, currentApplication, facade }) {


    const [ entities, setEntities ] = useState([]);
    async function queryData() {
        // do standard query
        const condition = mergeCriteria(currentApplication ? { 应用: currentApplication.id } : {}, {});
        const queryObj = {
            entityName: "应用依赖",
            condition,
            pageSize: 1000,
            offset: 0,
            fieldPaths: [
                "依赖",
                "系统应用",
                "公开的实体",
            ],
        };
        const [result, error] = await facade.list(queryObj);

        if(!error && result) {

            const entities = result.filter(x => x.系统应用 || true).flatMap(x => x.公开的实体);
            setEntities(entities);

        }
    }

    useEffect(() => {
        queryData()

    }, [ currentApplication.id ])


    return (
        <InputSelect {...{
            value, onChange,

            isSearchable: true,
            isClearable: false,
            options: entities.map(e => e.name),
        }} />
    )
}
