// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Antd$BwaxAdmin = require("../binding/antd/Antd.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var QueryContainer$BwaxAdmin = require("../../../lib/bwax-ui/re/legacy/QueryContainer.bs.js");
var BuildCommentList = require("Client/js/builders/buildCommentList");
var BuildHistoryList = require("Client/js/builders/buildHistoryList");
var UseViewportWidth = require("bwax-ui/legacy/page/instructions/useViewportWidth");

import './EntityRecordUtilTabs.less'
;

function EntityRecordUtilTabs(Props) {
  var entityKey = Props.entityKey;
  var recordId = Props.recordId;
  var allEntities = Props.allEntities;
  var allDataTypes = Props.allDataTypes;
  var extensions = Props.extensions;
  var baseData = Props.baseData;
  var loadingComp = Props.loadingComp;
  var widgetFactory = Props.widgetFactory;
  var customActions = Props.customActions;
  var history = Props.history;
  var toast = Props.toast;
  var $$location = Props.location;
  var match__ = Props.match_;
  var routeTo = Props.routeTo;
  var theme = Props.theme;
  var queryTarget = Props.queryTarget;
  var additional = Props.additional;
  var match = React.useState((function () {
          var viewportWidth = UseViewportWidth.getViewportWidth();
          if (viewportWidth >= 1400) {
            return "相关讨论";
          }
          
        }));
  var setActiveTab = match[1];
  var activeTab = match[0];
  var tabs = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* record */Caml_chrome_debugger.record([
          "key",
          "icon",
          "name"
        ], [
          "message",
          "message",
          "相关讨论"
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* record */Caml_chrome_debugger.record([
              "key",
              "icon",
              "name"
            ], [
              "history",
              "history",
              "更改历史"
            ]),
          /* [] */0
        ])
    ]);
  var getContentDef = function (tab) {
    switch (tab) {
      case "history" :
          return BuildHistoryList.default(entityKey, recordId);
      case "message" :
          return BuildCommentList.default(entityKey, recordId);
      default:
        return { };
    }
  };
  var isActive = function (t) {
    return activeTab === t[/* name */2];
  };
  var menuItems = Belt_List.toArray(Belt_List.mapWithIndex(tabs, (function (idx, t) {
              var match = isActive(t);
              return React.createElement("div", {
                          key: idx.toString(),
                          className: "menu-item" + (
                            match ? " active" : ""
                          ),
                          onClick: (function (param) {
                              return Curry._1(setActiveTab, (function (param) {
                                            return t[/* name */2];
                                          }));
                            })
                        }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                              type_: t[/* icon */1]
                            }));
            })));
  var contentTabs = Belt_List.toArray(Belt_List.mapWithIndex(tabs, (function (idx, t) {
              var match = isActive(t);
              return React.createElement("div", {
                          key: idx.toString(),
                          className: "tab-content" + (
                            match ? " active" : ""
                          )
                        }, React.createElement(QueryContainer$BwaxAdmin.make, {
                              def: getContentDef(t[/* key */0]),
                              baseData: baseData,
                              allDataTypes: allDataTypes,
                              allEntities: allEntities,
                              extensions: extensions,
                              loadingComp: loadingComp,
                              routeTo: routeTo,
                              history: history,
                              location: $$location,
                              match_: match__,
                              theme: theme,
                              toast: toast,
                              widgetFactory: widgetFactory,
                              queryTarget: queryTarget,
                              customActions: customActions,
                              additional: additional
                            }));
            })));
  var match$1 = activeTab !== undefined;
  return React.createElement("div", {
              className: "entity-record-util-tabs" + (
                match$1 ? " expanded" : ""
              )
            }, React.createElement("div", {
                  className: "menu-bar"
                }, React.createElement("div", {
                      className: "menu-item-list"
                    }, menuItems), React.createElement("div", {
                      className: "collapse-item",
                      onClick: (function (param) {
                          return Curry._1(setActiveTab, (function (param) {
                                        return ;
                                      }));
                        })
                    }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                          type_: "menu-unfold"
                        }))), React.createElement("div", {
                  className: "content-pane"
                }, contentTabs));
}

var make = EntityRecordUtilTabs;

exports.make = make;
/*  Not a pure module */
