
import React, { useState, useEffect } from 'react';
import EntitySelectRoot from './itemRoots/EntitySelectRoot';
import DependencyEntitySelectRoot from './itemRoots/DependencyEntitySelectRoot';
import isEqual from 'lodash/isEqual';

export function DataModelSelectPanel(props) {

    const { facade, renderSelectorNode, itemNode, baseCriteria, multitenantType } = props;

    const selectRoots = [
        [ EntitySelectRoot, {} ],
        [ EntitySelectRoot, { isExtendingEntity: true} ],
        [ DependencyEntitySelectRoot, {} ],
    ]

    return (
        <div className="panel-content">
            { selectRoots.map(([R, props], index) => {
                return (
                    <R {...{
                        key: index,
                        baseCriteria, multitenantType,
                        facade, renderSelectorNode, itemNode, 
                        ...props,
                    }} />
                )
            })}
        </div>
    )
}

export default React.memo(DataModelSelectPanel, (prevProps, nextProps) => {
    return isEqual(prevProps.baseCriteria, nextProps.baseCriteria) && isEqual(prevProps.currentItem, nextProps.currentItem);
});


