// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function parse(str) {
  try {
    return Caml_option.some(JSON.parse(str));
  }
  catch (exn){
    return ;
  }
}

function get_by_path(path, base) {
  return Plate$BwaxAdmin.List.foldl((function (acc, name) {
                var f = function (param) {
                  var field_name = name;
                  var base = param;
                  var access_array = function (index) {
                    return Plate$BwaxAdmin.$$Option.and_then((function (arr) {
                                  try {
                                    return Caml_option.some(Caml_array.caml_array_get(arr, index));
                                  }
                                  catch (exn){
                                    return ;
                                  }
                                }), Js_json.decodeArray(base));
                  };
                  var maybe_index;
                  try {
                    maybe_index = Caml_format.caml_int_of_string(field_name);
                  }
                  catch (exn){
                    maybe_index = undefined;
                  }
                  var tried_array_access = Plate$BwaxAdmin.$$Option.and_then(access_array, maybe_index);
                  if (Curry._1(Plate$BwaxAdmin.$$Option.is_some, tried_array_access)) {
                    return tried_array_access;
                  } else {
                    var name$1 = field_name;
                    return Plate$BwaxAdmin.$$Option.and_then((function (dict) {
                                  return Js_dict.get(dict, name$1);
                                }), Js_json.decodeObject(base));
                  }
                };
                return Plate$BwaxAdmin.$$Option.and_then(f, acc);
              }), Caml_option.some(base), path);
}

function get_value(field_name, decoder, json) {
  return Plate$BwaxAdmin.$$Option.and_then(decoder, get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    field_name,
                    /* [] */0
                  ]), json));
}

function get_field(field_name, json) {
  return get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                field_name,
                /* [] */0
              ]), json);
}

function get_by_index(idx, json) {
  return Plate$BwaxAdmin.$$Option.and_then((function (arr) {
                try {
                  return Caml_option.some(Caml_array.caml_array_get(arr, idx));
                }
                catch (exn){
                  return ;
                }
              }), Js_json.decodeArray(json));
}

function decode_list(json) {
  return Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Js_json.decodeArray(json));
}

function insert_object(k, v, json) {
  var partial_arg = Plate$BwaxAdmin.List.from_array;
  var l = Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (param) {
              return Plate$BwaxAdmin.$at$great(Js_dict.entries, partial_arg, param);
            }), Js_json.decodeObject(json)));
  var match = Plate$BwaxAdmin.List.assoc(k, l);
  return Js_dict.fromList(match !== undefined ? Plate$BwaxAdmin.List.map((function (param) {
                      var key = param[0];
                      if (key === k) {
                        return /* tuple */[
                                key,
                                v
                              ];
                      } else {
                        return /* tuple */[
                                key,
                                param[1]
                              ];
                      }
                    }), l) : Pervasives.$at(l, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        k,
                        v
                      ],
                      /* [] */0
                    ])));
}

function create_object(k, v) {
  return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  k,
                  v
                ],
                /* [] */0
              ]));
}

function create_or_insert(k, v, mj) {
  if (mj !== undefined) {
    return insert_object(k, v, Caml_option.valFromOption(mj));
  } else {
    return create_object(k, v);
  }
}

function delete_from_object(k, mj) {
  if (mj !== undefined) {
    var partial_arg = Plate$BwaxAdmin.List.from_array;
    return Js_dict.fromList(Plate$BwaxAdmin.List.filter((function (param) {
                        return param[0] !== k;
                      }))(Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map((function (param) {
                              return Plate$BwaxAdmin.$at$great(Js_dict.entries, partial_arg, param);
                            }), Js_json.decodeObject(Caml_option.valFromOption(mj))))));
  } else {
    return { };
  }
}

function pretty_print(spaces, j) {
  return JSON.stringify(j, null, spaces);
}

var json_object = Js_dict.fromList;

function json_int(i) {
  return i;
}

function json_str(s) {
  return s;
}

function json_arr(l) {
  return Curry._1(Plate$BwaxAdmin.List.to_array, l);
}

function append_json_arr(aj, bj) {
  var match = Js_json.decodeArray(aj);
  var match$1 = Js_json.decodeArray(bj);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return Caml_option.some($$Array.append(match, match$1));
    } else {
      return Caml_option.some(aj);
    }
  } else if (match$1 !== undefined) {
    return Caml_option.some(bj);
  } else {
    return ;
  }
}

exports.parse = parse;
exports.get_by_path = get_by_path;
exports.get_value = get_value;
exports.get_field = get_field;
exports.get_by_index = get_by_index;
exports.decode_list = decode_list;
exports.insert_object = insert_object;
exports.create_object = create_object;
exports.create_or_insert = create_or_insert;
exports.delete_from_object = delete_from_object;
exports.pretty_print = pretty_print;
exports.json_object = json_object;
exports.json_int = json_int;
exports.json_str = json_str;
exports.json_arr = json_arr;
exports.append_json_arr = append_json_arr;
/* Plate-BwaxAdmin Not a pure module */
