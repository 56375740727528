
import React, { useState, useRef } from 'react';

import ReactDOM from 'react-dom';

import TH from './TH';

import throttle from 'lodash/throttle';

const min_columnWidth = 100 // 列的最小宽度, 若初始宽度小于这个值，则不允许修改宽度。

function getLastUnFixedColIdx(cols) {
    const firstFixedRightCotIdx = cols.findIndex(c => c.fixed && c.fixed === 'right')
    if (firstFixedRightCotIdx === -1) {
        return cols.length - 1
    } else {
        return firstFixedRightCotIdx - 1
    }
}

const batchUpdates = throttle(ReactDOM.unstable_batchedUpdates, 60);

export default function TableHeader (props) {
    const {
        columns,
        originalColumns,
        className,
        style,
        outerRef,
        orders,
        setOrders,
        onSort,
        isFixedHeader,

        tableConfig,
        onTableConfigChange,
        grabbingColumn,
        setGrabbingColumn,
    } = props

    const [headerWidthInvalid, setHeaderWidthInvalid] = useState(false)
    const headerRef = useRef(null)

    const grabbingRef = useRef(null);


    function updateHeaderWidths() {
        const columnWidths = tableConfig && tableConfig.columnWidths ? tableConfig.columnWidths : {}
        let newColumnWidths = {}
        const actualColumns = isFixedHeader ? originalColumns : columns
        actualColumns.map(column => {
            newColumnWidths = {
                ...newColumnWidths,
                [column.key]: grabbingColumn && (column.key === grabbingColumn.key || column.title === grabbingColumn.key) ?
                    grabbingColumn.width : Object.keys(columnWidths).some(k => k === column.key) ? columnWidths[column.key] : column.width
            }
        })

        onTableConfigChange({
            ...tableConfig || {},
            columnWidths: newColumnWidths
        })
    }

    return (
        <div
            ref={outerRef}
            className={className}
            style={{ ...style }}
        >
            <table>
                <thead>
                    <tr style={{
                        display: 'flex', height: style.height || "auto",
                        cursor: headerWidthInvalid && grabbingColumn ? "not-allowed" : grabbingColumn ? "grabbing" : "auto"
                    }}
                        ref={headerRef}
                        onMouseMove={e => {
                            if(grabbingRef.current && grabbingColumn) {
                                // e.preventDefault()
                                const newHeaderWidth = grabbingColumn.width + (e.clientX - grabbingColumn.clientX)  
                                const widthInvalid = newHeaderWidth < min_columnWidth;

                                const clientX = !widthInvalid ? e.clientX : grabbingColumn.clientX;
                                const width = !widthInvalid ? newHeaderWidth : grabbingColumn.width;
                                
                                batchUpdates(() => {
                                    if(widthInvalid !== headerWidthInvalid) {
                                        setHeaderWidthInvalid(widthInvalid)
                                    }
                                    setGrabbingColumn(prev => {                                   
                                        return {
                                            ...prev,
                                            clientX,
                                            width,
                                        }        
                                    })
                                })
                            }                               
                        }}
                        onMouseUp={() => {
                            console.log("Move up", grabbingColumn, headerWidthInvalid);
                            grabbingRef.current = null;
                            if(grabbingColumn) {
                                setGrabbingColumn(null)
                                if(!headerWidthInvalid) {
                                    updateHeaderWidths()
                                };
                            }
                        }}
                        onMouseLeave={(e) => {
                            if(grabbingColumn) {
                                const headerTop = headerRef && headerRef.current && headerRef.current.getBoundingClientRect().top
                                const headerBottom = headerRef && headerRef.current && headerRef.current.getBoundingClientRect().bottom
                                if (e.clientY < headerTop || e.clientY > (headerBottom - 2)) {
                                    setGrabbingColumn(null) //  移出时放弃修改
                                }
                            }
                        }}
                    >
                        {
                            columns.map((col, idx) => {
                                const lastUnFixedColIdx = getLastUnFixedColIdx(columns)
                                const lastUnFixedColStyle = (idx === lastUnFixedColIdx) ? { flexGrow: 1 } : {};

                                const paddingRightStyle =
                                    (col.align == 'right' ? { paddingRight: 24 } : {})

                                const justifyContentMap = {
                                    left: "flex-start",
                                    center: "center",
                                    right: "flex-end"
                                }

                                const thStyle = {
                                    width: grabbingColumn && col.key === grabbingColumn.key ? grabbingColumn.width : (col.width || 'auto'),
                                    flexShrink: 0,
                                    justifyContent: justifyContentMap[col.align],
                                    ...paddingRightStyle,
                                    ...lastUnFixedColStyle
                                }

                                // 它只需要知道自己所应该依赖的 property 就可以了。这样还能使用 Memo 优化：
                                const columnKey = col.key;
                                const width = col.width;

                                const showCtxMenu = originalColumns.some(c => c.key === columnKey && !c.fixed)

                                return (
                                    <TH {...{
                                        key: col.key || idx,
                                        
                                        columnKey,
                                        fixed: col.fixed,
                                        sortable: col.sortable,
                                        title: col.title,
                                        width,                                                                 

                                        orders, setOrders, onSort,

                                        grabbing: grabbingColumn && grabbingColumn.key === columnKey,
                                        grabbingOther: grabbingColumn && grabbingColumn.key === columnKey,
                                        onGrab: (clientX) => {
                                            
                                            grabbingRef.current = columnKey;
                                            setGrabbingColumn({
                                                key: columnKey,
                                                width,
                                                clientX                
                                            })
                                        },

                                        showCtxMenu,

                                        style: thStyle,

                                        // 以下的可能需要整理:
                                        tableConfig, onTableConfigChange
                                        
                                    }} />
                                )
                            })
                        }
                    </tr>
                </thead>
            </table>
        </div>
    )
}