
import React from 'react'
import {EditorState, RichUtils, AtomicBlockUtils, Modifier} from 'draft-js'



export default (config = {}) => {

  return {

  };
};
