import Message from 'Client/js/ui/Message';

import {
    isInvolvingDynamicData, resolveDependenciesAndPrepareData, toDataInterfaceReturnValue, isInvolvingTargetRecord,
} from 'bwax/BwaxExecHelper';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import { runDataQuery } from 'bwax/query/runClientQuery';

import { untag, unpack, tag } from 'bwax/lang/LangHelper'

// a bunch of helper functions shared by different editors;

import { toDataInterfaceParams } from "bwax/BwaxExecHelper";

import queryUser from "bwax/query/queyUser"

export function mergePreviewData(oldPreviewData, newPreviewData) {

    // 仅支持如下字段
    const fieldNames = [
        "tyenv",            // 用于 design 端的整合 fragments 的 tyenv； 以及在其他地方快速检测该代码声明的各个值（和函数）的类型
        "testInputParams",  // 用于记录测试参数
        "externalNames",    // 用于记录以来的外部函数
        "ioTypeMetas",
        /// 用于检测 Page 对 PageFragment 的兼容性
        "usedSigs",
        /// 这个只是目前用于虚拟字段
        "involvingDynamicData"
    ]

    const mergedPreviewData = {
        ...(oldPreviewData || {}),
        ...(newPreviewData || {})
    }
    return Object.keys(mergedPreviewData).reduce((acc, k) => {
        if (fieldNames.indexOf(k) !== -1) {
            return { ...acc, [k]: mergedPreviewData[k] }
        } else {
            return acc
        }
    }, {})

}


// used for page preview
export function setUpPreviewChannel(postPreview) {
    const bc = new BroadcastChannel('preview-page-channel');
    bc.onmessage = ev => {
        if (ev.data && ev.data.messageType == "ask" && postPreview) {
            console.log("I'm asked", ev.data);
            try {
                postPreview(bc)
            } catch (error) {
                console.error(error);
                Message.error("发送预览数据时出错了")
            }
        }
    };
    return bc;
}

export function sendToPreviewChannel(channel, { editing, runtimeProfile, dlc, fragments, testRecordId, applicationCode, multitenantType }) {

    try {
        const { compiled, previewData, styles } = editing;

        const { externalNames = [], testInputParams } = previewData || {};

        function toParamExprs(params) {
            return params ? params.map(p => p.ast) : []
        }

        const inputParams = testInputParams ? untag(testInputParams) : {};
        const initInputParams = inputParams["init"] || []
        const viewInputParams = inputParams["view"] || []

        const initParamExprs = toParamExprs(initInputParams);
        const viewParamExprs = toParamExprs(viewInputParams);

        channel.postMessage({
            messageType: "preview",
            runtimeProfile,
            initParamExprs,
            viewParamExprs,
            compiledStr: compiled,
            styles,
            dlc,
            fragments, externalNames,
            
            testRecordId,

            multitenantType,

            applicationCode,
        })

    } catch (error) {
        console.error(error);
        Message.error("发送预览数据时，出错了")
    }

}



// prepare and execute 

async function extract(unpacked, facade) {
    if (unpacked.length == 5) {
        return unpacked
    } else {
        // 新版本从 facade 里面拿：
        const [n, defs, dts, entityNames] = unpacked;
        await facade.prepare(entityNames);
        return [n, defs, dts, facade.entity_dict, facade.data_type_dict]
    }
}


export async function prepareData(unpacked, recordId, { facade, entityName }) {

    const [n, defs, dts, entity_dict, data_type_dict] = await extract(unpacked, facade);

    const allEntities = facade.entities;
    const entity = allEntities.find(e => e.name == entityName);

    const dlc = facade.dlc;

    const getUser = async userDeps => {

        const [_tree, paths, selectionText, _deps] = userDeps;

        if (!paths || paths.length == 0) {
            return {}
        }
        return await queryUser(dlc, selectionText);
    }

    const getRecord = async (entityName, id, recordDep) => {
        const [_tree, paths, selectionText, _deps] = recordDep;
        if (!paths || paths.length == 0) {
            return {}
        }
        const entity = allEntities.find(e => e.name == entityName);
        const recordData = await queryOne(dlc, entity, id, selectionText);
        return recordData
    }

    const domainEnv = {
        mobileHost: document.userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        protocol: "https",
        tenantCode: dlc.tenantCode,
    }

    const [predata, error] = await resolveDependenciesAndPrepareData([n, defs], {
        targetEntityName: entity && entity.name,
        targetRecordId: recordId,
        getRecord, getUser,
        entity_dict, data_type_dict, domainEnv, dts
    })

    return predata

}


export async function execute(unpacked, { preparedData, specifiedParams, previewData, facade, itemType }) {

    const [n, defs, dts, entity_dict, data_type_dict] = await extract(unpacked, facade);

    const env = lang_entry_slim.evaluate_defs(facade.baseEnv, [n, defs]);

    const dlc = facade.dlc;

    // 基本上 backend 才会跑这个

    function buildParams() {
        if (specifiedParams) {
            return specifiedParams
        } else {
            function toParamExprs(params) {
                return params ? params.map(p => p.ast) : []
            }
            const initParamExprs = toParamExprs(
                previewData.testInputParams && previewData.testInputParams["init"] ? untag(previewData.testInputParams["init"]) : []
            );

            const paramArr = initParamExprs.map(
                p => lang_entry_slim.evaluate(facade.baseEnv, undefined, p)
            );

            // 如果是 backend interface，要经过两次转换才能真正测试出来
            // 1. 见 query_entry.run_custom_mutation, query_command.to_interface_inputs
            // 2. BwaxExecHelper.toDataInterfaceParams
            if(itemType === "data-interface") {

                // 1. 首先要知道当前 data-interface 的 input_types
                const { ioTypeMetas } = previewData;                
                const [ input_types ] = lang_entry_slim.convert_input_output_types(ioTypeMetas);

                // 2. 转换为 json （用于 GraphQL 的）
                const params = lang_entry_slim.array_to_list(paramArr);
                const inputJsons = lang_entry_slim.lang_value_to_interface_input_json(entity_dict, data_type_dict, input_types, params);
                
                // 3. 模拟后端把 jsons 返回
                const finalParams = toDataInterfaceParams(inputJsons, preparedData, untag(ioTypeMetas.initParamTypes));

                return finalParams;

            } else {
        
                function toFinalParams() {
                    // 如果 paramArr 的数量为 0, 则只使用 predata
                    // 如果 paramArr 的数量为 1, 则 paramArr[0] 和 predata
                    // 如果 paramArr 的数量为 2，则用 tuple 把 paramArr 装起来再加上 predata
                    if (paramArr.length === 0) {
                        return [preparedData]
                    } else if (paramArr.length === 1) {
                        return [paramArr[0], preparedData]
                    } else {
                        let param = lang_entry.pack_tuple_from_array(paramArr);
                        return [param, preparedData]
                    }
                };
                return lang_entry_slim.array_to_list(toFinalParams());
            }



        }
    }

    const domainEnv = {
        mobileHost: document.userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        protocol: "https",
    }
    const { viewReturnTypeMetas } = previewData.ioTypeMetas;

    const params = buildParams();


    const queryRunner = facade.queryRunner;
    const queryCache = facade.queryCache;

    return await (new Promise(function (resolve, _reject) {
        lang_entry_slim.execute_init_to_model_with_multiparams(
            queryRunner, queryCache,
            domainEnv,
            entity_dict, data_type_dict, dts, env,
            params,
            // testParams,
            (model, cmds, msgs, error) => {
                if (error) {
                    console.error("执行错误", error);
                    resolve({ error })
                } else {

                    const viewResult = lang_entry_slim.evaluate_view_v2(
                        queryRunner, queryCache,
                        domainEnv,
                        entity_dict, data_type_dict, dts, env, model
                    );
                    const view = toDataInterfaceReturnValue(
                        entity_dict, data_type_dict, viewResult, viewReturnTypeMetas
                    );

                    resolve({ cmds, msgs, view })
                }
            }
        )
    }))
}



// TODO, use data cache as well: 
// query one record
export async function queryOne(dlc, entity, id, selectionText) {
    const runQuery = runDataQuery(dlc)

    let queryText = `
        query { q: list${entity.key} ( condition: [[{ field: "id", op: eq, value: "${id}" }]]) {
            edges { node ${selectionText} }
        }} `

    const result = await runQuery(queryText)()
    const { errors, data } = JSON.parse(result)

    if (data && data.q && data.q.edges && data.q.edges.length > 0) {
        return data.q.edges[0].node
    } else {
        return undefined
    }

}

