// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Renderer = require("bwax-ui/auxiliary/richtext/renderer");

import './Display_RichText.less'
;

function Display_RichText(Props) {
  var params = Props.params;
  var value = params.value;
  if (value !== undefined) {
    var element = Renderer.contentToReact(value);
    return React.createElement("div", {
                className: "admin--rich-text"
              }, element);
  } else {
    return null;
  }
}

var make = Display_RichText;

exports.make = make;
/*  Not a pure module */
