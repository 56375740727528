
import React from 'react'

import './TabBar.less'

import UrlPattern from 'url-pattern'

import { Link } from 'react-router-dom'

export default function TabBar(props) {

    const { match, routeTo, tabs = [], changeTab } = props;

    // console.log("Change Tab", changeTab);

    function getActiveTab() {
        
        const { tab } = match.params
        const hitted = tabs.find(t => t === tab)
        return hitted ? hitted : tabs[0]

    }

    const getLink = tab => {
        const pattern = new UrlPattern(match.path);

        const { id, tab: _, ...otherParams } = match.params;

        const newParams = {
            id: id || "-", // 默认的 id 是 "-"
            tab,
            ...otherParams,
        }
        // 主动删除 '?'
        const to = pattern.stringify(newParams).replace(/\?/g, "");

        return to;
    }

    function updateActiveTab(tab) {
        if(changeTab) {
            // 用于 
            changeTab(tab)
        } else {
            const to = getLink(tab);
            routeTo(to, { modal: false });
        }
    }

    const activeTab = getActiveTab();

    return (
        <div className="admin--tab-bar">
            { tabs.length <= 1 ? null : 
                tabs.map(t => {
                    const isActive = activeTab == t;
                    return (
                        <Link key={t} to={getLink(t)} className={"tab-item" + (isActive ? " active" : "")} onClick={e => {
                            e.preventDefault();
                            if(!isActive) {
                                updateActiveTab(t);
                            }                            
                        }}>
                            { t }
                        </Link>
                    )
                }) 
            }
        </div>
    )


}