// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_mod_json$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/builtin/lang_mod_json.bs.js");
var Widget_common$BwaxAdmin = require("./widget_common.bs.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "AdhocUI";

var src = "\nmodule " + (String(module_name) + ";\n\nimport Element exposing (Rule, Element);\n\n\n-- https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/879\n-- 使用任何 JS 实现并声明的组件，类型安全性要在 JS 端保证\nport: String \n    -> List (Rule msg)\n    -> ~{data: data\n       , slots: List (String, Element msg)\n       , events: List (String, JSON -> msg )\n       }\n    -> Element msg = external;\n\n-- 用于一些不是内置的临时解决方案：\ncb5YearSlides: List (Rule msg) -> data -> { changeShareSetting: Bool -> msg } -> Element msg = external;\n\n");

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function spinner(name) {
  var f = Runtime_common$BwaxAdmin.pack_func(4, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_list */1) {
              return Runtime_common$BwaxAdmin.invalid_args(name);
            } else {
              var match$1 = param[1];
              if (match$1) {
                var match$2 = match$1[1];
                if (match$2) {
                  var match$3 = match$2[1];
                  if (match$3) {
                    var match$4 = match$3[0][0];
                    if (typeof match$4 === "number" || match$4.tag !== /* V_literal */5) {
                      return Runtime_common$BwaxAdmin.invalid_args(name);
                    } else {
                      var match$5 = match$4[0];
                      if (typeof match$5 === "number" || !(match$5.tag === /* Bool */4 && !match$3[1])) {
                        return Runtime_common$BwaxAdmin.invalid_args(name);
                      } else {
                        var rules = Widget_common$BwaxAdmin.raw_to_rules(match[0]);
                        var config = Lang_mod_json$BwaxAdmin.any_value_to_json(/* record */Caml_chrome_debugger.record([
                                "keeping_native",
                                "nothing_to_null",
                                "processors"
                              ], [
                                true,
                                true,
                                0
                              ]), match$1[0]);
                        var color = Widget_common$BwaxAdmin.make_color(match$2[0]);
                        return Widget_common$BwaxAdmin.element_to_value(/* Spinner */Caml_chrome_debugger.variant("Spinner", 20, [
                                      rules,
                                      name,
                                      config,
                                      color,
                                      match$5[0]
                                    ]));
                      }
                    }
                  } else {
                    return Runtime_common$BwaxAdmin.invalid_args(name);
                  }
                } else {
                  return Runtime_common$BwaxAdmin.invalid_args(name);
                }
              } else {
                return Runtime_common$BwaxAdmin.invalid_args(name);
              }
            }
          } else {
            return Runtime_common$BwaxAdmin.invalid_args(name);
          }
        }));
  return /* tuple */[
          name,
          f
        ];
}

var externals_vals_000 = /* tuple */[
  "port",
  Widget_common$BwaxAdmin.pack_element_func(3, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return Runtime_common$BwaxAdmin.invalid_args("port");
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
                return Runtime_common$BwaxAdmin.invalid_args("port");
              } else {
                var match$2 = param[1];
                if (match$2) {
                  var match$3 = match$2[0][0];
                  if (typeof match$3 === "number" || match$3.tag !== /* V_list */1) {
                    return Runtime_common$BwaxAdmin.invalid_args("port");
                  } else {
                    var match$4 = match$2[1];
                    if (match$4) {
                      var match$5 = match$4[0][0];
                      if (typeof match$5 === "number" || !(match$5.tag === /* V_record */2 && !match$4[1])) {
                        return Runtime_common$BwaxAdmin.invalid_args("port");
                      } else {
                        var settings = match$5[0];
                        var rules = Widget_common$BwaxAdmin.raw_to_rules(match$3[0]);
                        var partial_arg = /* record */Caml_chrome_debugger.record([
                            "keeping_native",
                            "nothing_to_null",
                            "processors"
                          ], [
                            false,
                            true,
                            0
                          ]);
                        var data = Plate$BwaxAdmin.$$Option.and_then((function (param) {
                                return Lang_mod_json$BwaxAdmin.any_value_to_json(partial_arg, param);
                              }), Plate$BwaxAdmin.List.assoc("data", settings));
                        var slots = Plate$BwaxAdmin.List.keep_map((function (param) {
                                var match = param[0];
                                if (typeof match === "number" || match.tag) {
                                  return ;
                                } else {
                                  var match$1 = match[0];
                                  if (match$1) {
                                    var match$2 = match$1[0][0];
                                    if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                                      return ;
                                    } else {
                                      var match$3 = match$2[0];
                                      if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                                        return ;
                                      } else {
                                        var match$4 = match$1[1];
                                        if (match$4) {
                                          var match$5 = match$4[0][0];
                                          if (typeof match$5 === "number" || !(match$5.tag === /* V_raw */7 && !match$4[1])) {
                                            return ;
                                          } else {
                                            return /* tuple */[
                                                    match$3[0],
                                                    match$5[0]
                                                  ];
                                          }
                                        } else {
                                          return ;
                                        }
                                      }
                                    }
                                  } else {
                                    return ;
                                  }
                                }
                              }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_list, Plate$BwaxAdmin.List.assoc("slots", settings))));
                        var make_tagger = function (tagger_v) {
                          var tmp = tagger_v[0];
                          if (typeof tmp !== "number") {
                            switch (tmp.tag | 0) {
                              case /* V_closure */3 :
                              case /* V_native_fn */4 :
                                  var tagger_v$1 = tagger_v;
                                  return (function (j) {
                                      var args_000 = /* tuple */[
                                        /* V_tagged_raw */Caml_chrome_debugger.variant("V_tagged_raw", 8, [
                                            "JSON",
                                            j
                                          ]),
                                        Runtime_common$BwaxAdmin.nt
                                      ];
                                      var args = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          args_000,
                                          /* [] */0
                                        ]);
                                      return Lang_eval$BwaxAdmin.apply_value_args(undefined, tagger_v$1, args, Runtime_common$BwaxAdmin.nt);
                                    });
                              default:
                                
                            }
                          }
                          return (function (param) {
                              return tagger_v;
                            });
                        };
                        var taggers = Plate$BwaxAdmin.List.keep_map((function (param) {
                                var match = param[0];
                                if (typeof match === "number" || match.tag) {
                                  return ;
                                } else {
                                  var match$1 = match[0];
                                  if (match$1) {
                                    var match$2 = match$1[0][0];
                                    if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                                      return ;
                                    } else {
                                      var match$3 = match$2[0];
                                      if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                                        return ;
                                      } else {
                                        var match$4 = match$1[1];
                                        if (match$4 && !match$4[1]) {
                                          return /* tuple */[
                                                  match$3[0],
                                                  make_tagger(match$4[0])
                                                ];
                                        } else {
                                          return ;
                                        }
                                      }
                                    }
                                  } else {
                                    return ;
                                  }
                                }
                              }), Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.and_then(Lang_eval$BwaxAdmin.as_list, Plate$BwaxAdmin.List.assoc("events", settings))));
                        return /* Adhoc_port */Caml_chrome_debugger.variant("Adhoc_port", 21, [
                                  match$1[0],
                                  rules,
                                  data,
                                  slots,
                                  taggers
                                ]);
                      }
                    } else {
                      return Runtime_common$BwaxAdmin.invalid_args("port");
                    }
                  }
                } else {
                  return Runtime_common$BwaxAdmin.invalid_args("port");
                }
              }
            }
          } else {
            return Runtime_common$BwaxAdmin.invalid_args("port");
          }
        }))
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "cb5YearSlides",
      Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
              if (param) {
                var match = param[0][0];
                if (typeof match === "number" || match.tag !== /* V_list */1) {
                  return Runtime_common$BwaxAdmin.invalid_args("cb5YearSlides");
                } else {
                  var match$1 = param[1];
                  if (match$1) {
                    var match$2 = match$1[1];
                    if (match$2) {
                      var match$3 = match$2[0][0];
                      if (typeof match$3 === "number" || !(match$3.tag === /* V_record */2 && !match$2[1])) {
                        return Runtime_common$BwaxAdmin.invalid_args("cb5YearSlides");
                      } else {
                        var rules = Widget_common$BwaxAdmin.raw_to_rules(match[0]);
                        var data = Lang_mod_json$BwaxAdmin.any_value_to_json(/* record */Caml_chrome_debugger.record([
                                "keeping_native",
                                "nothing_to_null",
                                "processors"
                              ], [
                                true,
                                true,
                                0
                              ]), match$1[0]);
                        var collect_tagger = function (tagger_v) {
                          return Widget_common$BwaxAdmin.tagger_to_json_callback(Runtime_common$BwaxAdmin.json_to_value, tagger_v);
                        };
                        var taggers = Plate$BwaxAdmin.List.assoc_map(collect_tagger, match$3[0]);
                        return Widget_common$BwaxAdmin.element_to_value(/* Adhoc */Caml_chrome_debugger.variant("Adhoc", 22, [
                                      rules,
                                      "cb5YearSlides",
                                      data,
                                      taggers
                                    ]));
                      }
                    } else {
                      return Runtime_common$BwaxAdmin.invalid_args("cb5YearSlides");
                    }
                  } else {
                    return Runtime_common$BwaxAdmin.invalid_args("cb5YearSlides");
                  }
                }
              } else {
                return Runtime_common$BwaxAdmin.invalid_args("cb5YearSlides");
              }
            }))
    ],
    /* [] */0
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var command_handlers = /* [] */0;

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.spinner = spinner;
exports.externals_vals = externals_vals;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* src Not a pure module */
