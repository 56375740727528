
import React from 'react';
import createInlineStyleButton from './util/createInlineStyleButton';

export default createInlineStyleButton({
  style: 'ITALIC',
  children: (
    <i className={"fa fa-italic"} aria-hidden="true"></i>
  ),
});
