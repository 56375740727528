// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../utils/json.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Lang_eval$BwaxAdmin = require("../lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Defs_remat$BwaxAdmin = require("./defs_remat.bs.js");
var LangHelper = require("bwax/lang/LangHelper");
var Defs_aggregate$BwaxAdmin = require("./defs_aggregate.bs.js");
var Runtime_common$BwaxAdmin = require("../lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxAdmin = require("../base_query_types.bs.js");

function to_list(maybe_array) {
  return Curry._1(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.with_default(/* array */[], maybe_array));
}

function convert_dtype(allDatatypes, data_type_dict, type_key, options) {
  switch (type_key) {
    case "Boolean" :
        return /* Atom_bool */2;
    case "Color" :
        return /* Atom_color */9;
    case "Date" :
    case "DateTime" :
        return /* Atom_date */4;
    case "File" :
        return /* Atom_file */8;
    case "FilterCondition" :
        var match = Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Json$BwaxAdmin.get_field("entity", options));
        if (match !== undefined) {
          return /* Criteria */Caml_chrome_debugger.variant("Criteria", 4, [match]);
        } else {
          return /* Atom_json */5;
        }
    case "Image" :
        return /* Atom_image */7;
    case "Integer" :
        return /* Atom_int */0;
    case "BaseTypeAny" :
    case "FormTypeField" :
    case "FormTypeRecordData" :
    case "JSON" :
    case "JSONB" :
        return /* Atom_json */5;
    case "Link" :
        var match$1 = Json$BwaxAdmin.get_value("entity", Js_json.decodeString, options);
        if (match$1 !== undefined) {
          var name = match$1;
          var match$2 = Json$BwaxAdmin.get_value("isEntityExposed", Js_json.decodeBoolean, options);
          if (match$2 !== undefined && !match$2) {
            var match$3 = Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Json$BwaxAdmin.get_by_path(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        "sourceApp",
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            "name",
                            /* [] */0
                          ])
                      ]), options));
            if (match$3 !== undefined) {
              return /* Entity_link_opaque */Caml_chrome_debugger.variant("Entity_link_opaque", 2, [
                        name,
                        match$3
                      ]);
            } else {
              console.log("ERROR: No app specifed for private entity", name, options);
              throw [
                    Base_query_types$BwaxAdmin.Builder_exn,
                    "Field `" + (String(type_key) + ("` with an private entity `" + (String(name) + "` link, but no app specified.")))
                  ];
            }
          } else {
            return /* Entity_link */Caml_chrome_debugger.variant("Entity_link", 1, [name]);
          }
        } else {
          throw [
                Base_query_types$BwaxAdmin.Builder_exn,
                "Field `" + (String(type_key) + "` has no reference.")
              ];
        }
    case "Number" :
        var match$4 = Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeNumber, Json$BwaxAdmin.get_field("decimals", options));
        if (match$4 === 0.0) {
          return /* Atom_int */0;
        } else {
          return /* Atom_float */1;
        }
    case "RichText" :
        return /* Atom_richtext */6;
    case "Select" :
        var to_option = function (j) {
          var mk = Json$BwaxAdmin.get_value("name", Js_json.decodeString, j);
          var mv = Json$BwaxAdmin.get_value("value", Js_json.decodeString, j);
          if (mk !== undefined && mv !== undefined) {
            return /* tuple */[
                    mk,
                    mv
                  ];
          }
          
        };
        var opts = Plate$BwaxAdmin.List.keep_map(to_option, Plate$BwaxAdmin.$$Option.with_default(/* [] */0, Plate$BwaxAdmin.$$Option.map(Plate$BwaxAdmin.List.from_array, Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeArray, Json$BwaxAdmin.get_field("options", options)))));
        return /* Atom_select */Caml_chrome_debugger.variant("Atom_select", 0, [opts]);
    case "ShortText" :
    case "Text" :
        return /* Atom_string */3;
    default:
      var match$5 = Base_query_types$BwaxAdmin.try_to_get_data_type(data_type_dict, type_key);
      if (match$5 !== undefined) {
        var match$6 = match$5[/* trait */2];
        if (typeof match$6 === "number" || match$6.tag) {
          return /* Custom_type */Caml_chrome_debugger.variant("Custom_type", 3, [type_key]);
        } else {
          switch (match$6[0]) {
            case "JSON" :
            case "JSONB" :
                return /* Atom_json */5;
            default:
              return /* Custom_type */Caml_chrome_debugger.variant("Custom_type", 3, [type_key]);
          }
        }
      } else {
        var rawDataType = Plate$BwaxAdmin.List.find((function (dataType) {
                if (dataType.name === type_key) {
                  return true;
                } else {
                  return dataType.key === type_key;
                }
              }), to_list(allDatatypes));
        if (rawDataType !== undefined) {
          return /* Custom_type */Caml_chrome_debugger.variant("Custom_type", 3, [Caml_option.valFromOption(rawDataType).name]);
        } else {
          console.log("Not found data type for ", type_key);
          return /* Custom_type */Caml_chrome_debugger.variant("Custom_type", 3, [type_key]);
        }
      }
  }
}

function convert_field(allDataTypes, data_type_dict, field) {
  var name = field.name;
  var key = field.key;
  var dtype = convert_dtype(allDataTypes, data_type_dict, field.type, field.options);
  var multivalued = field.multivalued;
  var required = field.required;
  var filterable = field.filterable;
  var sortable = field.sortable;
  var initializable = Plate$BwaxAdmin.$$Option.with_default(false, field.initializable);
  var updatable = Plate$BwaxAdmin.$$Option.with_default(false, field.updatable);
  var backlink = Plate$BwaxAdmin.$$Option.with_default(false, field.backlink);
  var materialized = Plate$BwaxAdmin.$$Option.with_default(false, field.materialized);
  var default_value = field.defaultValue;
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "dtype",
            "required",
            "multivalued",
            "filterable",
            "sortable",
            "initializable",
            "updatable",
            "backlink",
            "materialized",
            "default_value"
          ], [
            name,
            key,
            dtype,
            required,
            multivalued,
            filterable,
            sortable,
            initializable,
            updatable,
            backlink,
            materialized,
            (default_value == null) ? undefined : Caml_option.some(default_value)
          ]);
}

function convert_data_type(allDataTypes, data_type_dict, dataType) {
  var name = dataType.name;
  var key = dataType.key;
  var dt_category = dataType.category;
  var get_type_options = function (typeOptions) {
    var type_key = typeOptions.type;
    var options = typeOptions.options;
    return /* tuple */[
            type_key,
            options
          ];
  };
  var trait;
  switch (dt_category) {
    case "Array" :
        var match = get_type_options(dataType.settings.typeOptions);
        trait = /* C_array */Caml_chrome_debugger.variant("C_array", 2, [
            match[0],
            match[1]
          ]);
        break;
    case "Atom" :
        trait = /* C_atom */0;
        break;
    case "Extend" :
        var match$1 = get_type_options(dataType.settings.typeOptions);
        trait = /* C_extend */Caml_chrome_debugger.variant("C_extend", 0, [
            match$1[0],
            match$1[1]
          ]);
        break;
    case "Record" :
        if (key === "BaseTypeAny") {
          trait = /* C_atom */0;
        } else {
          var fields = Plate$BwaxAdmin.List.map((function (param) {
                  return convert_field(allDataTypes, data_type_dict, param);
                }), to_list(dataType.settings.fields));
          var virtual_fields = Plate$BwaxAdmin.List.map((function (param) {
                  return convert_field(allDataTypes, data_type_dict, param);
                }), to_list(dataType.settings.virtualFields));
          trait = /* C_record */Caml_chrome_debugger.variant("C_record", 1, [
              fields,
              virtual_fields
            ]);
        }
        break;
    case "Tuple" :
        trait = /* C_tuple */1;
        break;
    case "Union" :
        trait = /* C_union */2;
        break;
    default:
      throw [
            Base_query_types$BwaxAdmin.Builder_exn,
            "Not a valid category " + dt_category
          ];
  }
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "trait"
          ], [
            name,
            key,
            trait
          ]);
}

function convert_interface_value_type(param) {
  var typeMeta = param[0];
  var convert_by_term = function (term) {
    var convert_record = function (binds) {
      var al = Plate$BwaxAdmin.List.map((function (param) {
              var match = convert_by_term(param[1]);
              return /* tuple */[
                      param[0],
                      match[0],
                      match[1]
                    ];
            }), binds);
      return /* Intf_record */Caml_chrome_debugger.variant("Intf_record", 2, [al]);
    };
    if (typeof term === "number") {
      return /* tuple */[
              /* Intf_json */4,
              true
            ];
    } else {
      switch (term.tag | 0) {
        case /* Term */0 :
            switch (term[0]) {
              case "Bool" :
                  return /* tuple */[
                          /* Intf_bool */0,
                          true
                        ];
              case "Date" :
                  return /* tuple */[
                          /* Intf_datetime */6,
                          true
                        ];
              case "Dict" :
                  var match = term[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1 && !match$1[1]) {
                      var vkt = Plate$BwaxAdmin.fst(convert_by_term(match[0]));
                      var vvt = Plate$BwaxAdmin.fst(convert_by_term(match$1[0]));
                      return /* tuple */[
                              /* Intf_dict */Caml_chrome_debugger.variant("Intf_dict", 4, [
                                  vkt,
                                  vvt
                                ]),
                              true
                            ];
                    } else {
                      return /* tuple */[
                              /* Intf_json */4,
                              true
                            ];
                    }
                  } else {
                    return /* tuple */[
                            /* Intf_json */4,
                            true
                          ];
                  }
              case "File" :
                  return /* tuple */[
                          /* Intf_file */8,
                          true
                        ];
              case "Float" :
                  return /* tuple */[
                          /* Intf_float */2,
                          true
                        ];
              case "Image" :
                  return /* tuple */[
                          /* Intf_image */7,
                          true
                        ];
              case "Int" :
                  return /* tuple */[
                          /* Intf_int */1,
                          true
                        ];
              case "List" :
                  var match$2 = term[1];
                  if (match$2 && !match$2[1]) {
                    var match$3 = convert_by_term(match$2[0]);
                    return /* tuple */[
                            /* Intf_list */Caml_chrome_debugger.variant("Intf_list", 0, [match$3[0]]),
                            true
                          ];
                  } else {
                    return /* tuple */[
                            /* Intf_json */4,
                            true
                          ];
                  }
              case "Maybe" :
                  var match$4 = term[1];
                  if (match$4 && !match$4[1]) {
                    var match$5 = convert_by_term(match$4[0]);
                    return /* tuple */[
                            /* Intf_maybe */Caml_chrome_debugger.variant("Intf_maybe", 1, [match$5[0]]),
                            false
                          ];
                  } else {
                    return /* tuple */[
                            /* Intf_json */4,
                            true
                          ];
                  }
              case "RichText" :
                  return /* tuple */[
                          /* Intf_richtext */5,
                          true
                        ];
              case "String" :
                  return /* tuple */[
                          /* Intf_string */3,
                          true
                        ];
              case "Tuple" :
                  var vts = Plate$BwaxAdmin.List.map((function (param) {
                          return Plate$BwaxAdmin.$at$great(convert_by_term, Plate$BwaxAdmin.fst, param);
                        }), term[1]);
                  return /* tuple */[
                          /* Intf_tuple */Caml_chrome_debugger.variant("Intf_tuple", 3, [vts]),
                          true
                        ];
              default:
                return /* tuple */[
                        /* Intf_json */4,
                        true
                      ];
            }
        case /* Term_record */1 :
            return /* tuple */[
                    convert_record(Plate$BwaxAdmin.List.map((function (param) {
                                return /* tuple */[
                                        param[0],
                                        param[1]
                                      ];
                              }), term[0])),
                    true
                  ];
        case /* Var */2 :
            var match$6 = term[0][/* var_type */0];
            if (typeof match$6 === "number") {
              if (match$6 === /* Number */1) {
                return /* tuple */[
                        /* Intf_int */1,
                        true
                      ];
              } else {
                return /* tuple */[
                        /* Intf_json */4,
                        true
                      ];
              }
            } else if (match$6.tag) {
              return /* tuple */[
                      /* Intf_json */4,
                      true
                    ];
            } else {
              return /* tuple */[
                      convert_record(match$6[0]),
                      true
                    ];
            }
        
      }
    }
  };
  var convert_by_name = function (name, term) {
    var prefixes_000 = /* tuple */[
      "Entity_",
      (function (x) {
          return /* Intf_entity */Caml_chrome_debugger.variant("Intf_entity", 6, [x]);
        })
    ];
    var prefixes_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "Criteria_",
          (function (x) {
              return /* Intf_criteria */Caml_chrome_debugger.variant("Intf_criteria", 7, [x]);
            })
        ],
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* tuple */[
              "Sorting_",
              (function (x) {
                  return /* Intf_sorting */Caml_chrome_debugger.variant("Intf_sorting", 8, [x]);
                })
            ],
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "Adding_",
                  (function (x) {
                      return /* Intf_adding */Caml_chrome_debugger.variant("Intf_adding", 9, [x]);
                    })
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "Updating_",
                      (function (x) {
                          return /* Intf_updating */Caml_chrome_debugger.variant("Intf_updating", 10, [x]);
                        })
                    ],
                    /* [] */0
                  ])
              ])
          ])
      ]);
    var prefixes = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        prefixes_000,
        prefixes_001
      ]);
    if (name === "Link") {
      var entityName = typeMeta.options.entity;
      return /* Intf_entity */Caml_chrome_debugger.variant("Intf_entity", 6, [entityName]);
    } else {
      var matched = Plate$BwaxAdmin.List.find((function (param) {
              return Plate$BwaxAdmin.Str.starts_with(param[0], name);
            }), prefixes);
      if (matched !== undefined) {
        var match = matched;
        return Curry._1(match[1], Plate$BwaxAdmin.Str.drop_left(Plate$BwaxAdmin.Str.length(match[0]), name));
      } else {
        return Plate$BwaxAdmin.fst(convert_by_term(term));
      }
    }
  };
  var handle_list_or_paged = function (meta, term) {
    var name = meta.type;
    var match = meta.paged;
    var match$1 = meta.multivalued;
    if (match) {
      if (typeof term === "number" || term.tag || term[0] !== "PagedList") {
        return convert_by_name(name, term);
      } else {
        var match$2 = term[1];
        if (match$2 && !match$2[1]) {
          return /* Intf_paged */Caml_chrome_debugger.variant("Intf_paged", 5, [convert_by_name(name, match$2[0])]);
        } else {
          return convert_by_name(name, term);
        }
      }
    } else if (match$1 && !(typeof term === "number" || term.tag || term[0] !== "List")) {
      var match$3 = term[1];
      if (match$3 && !match$3[1]) {
        return /* Intf_list */Caml_chrome_debugger.variant("Intf_list", 0, [convert_by_name(name, match$3[0])]);
      } else {
        return convert_by_name(name, term);
      }
    } else {
      return convert_by_name(name, term);
    }
  };
  var meta = typeMeta;
  var term = param[1];
  var match = meta.required;
  if (match || typeof term === "number" || term.tag || term[0] !== "Maybe") {
    return handle_list_or_paged(meta, term);
  } else {
    var match$1 = term[1];
    if (match$1 && !match$1[1]) {
      return /* Intf_maybe */Caml_chrome_debugger.variant("Intf_maybe", 1, [handle_list_or_paged(meta, match$1[0])]);
    } else {
      return handle_list_or_paged(meta, term);
    }
  }
}

function convert_input_output_types(ioTypeMetas) {
  var initParamTypeMetas = Curry._1(Plate$BwaxAdmin.List.from_array, ioTypeMetas.initParamTypeMetas);
  var initParamTypes = Curry._1(Plate$BwaxAdmin.List.from_array, LangHelper.untag(ioTypeMetas.initParamTypes));
  var viewReturnTypeMetas = Curry._1(Plate$BwaxAdmin.List.from_array, ioTypeMetas.viewReturnTypeMetas);
  var viewReturnTypes = Curry._1(Plate$BwaxAdmin.List.from_array, LangHelper.untag(ioTypeMetas.viewReturnTypes));
  var input_types = Plate$BwaxAdmin.List.map(convert_interface_value_type, Plate$BwaxAdmin.List.combine(initParamTypeMetas, initParamTypes));
  var output_types = Plate$BwaxAdmin.List.map(convert_interface_value_type, Plate$BwaxAdmin.List.combine(viewReturnTypeMetas, viewReturnTypes));
  return /* tuple */[
          input_types,
          output_types
        ];
}

function convert_interface(entity_key, interfaceObj) {
  var name = interfaceObj.name;
  var key = interfaceObj.key;
  var interface_type = interfaceObj.interfaceType === "操作" ? /* Intf_operation */1 : /* Intf_query */0;
  var ioTypeMetas = interfaceObj.ioTypeMetas;
  var match = convert_input_output_types(ioTypeMetas);
  var is_targeting_record = Plate$BwaxAdmin.$$Option.with_default(false, ioTypeMetas.isTargetingRecord);
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "interface_type",
            "input_types",
            "output_types",
            "is_targeting_record",
            "entity_key"
          ], [
            name,
            key,
            interface_type,
            match[0],
            match[1],
            is_targeting_record,
            entity_key
          ]);
}

function convert_entity(allDataTypes, data_type_dict, entity) {
  var name = entity.name;
  var key = entity.key;
  var fields = Plate$BwaxAdmin.List.map((function (param) {
          return convert_field(allDataTypes, data_type_dict, param);
        }), to_list(entity.fields));
  var virtual_fields = Plate$BwaxAdmin.List.map((function (param) {
          return convert_field(allDataTypes, data_type_dict, param);
        }), to_list(entity.virtualFields));
  var raw_interfaces = Curry._1(Plate$BwaxAdmin.List.from_array, entity.interfaces);
  var interfaces = Plate$BwaxAdmin.List.map((function (param) {
          return convert_interface(key, param);
        }), raw_interfaces);
  return /* record */Caml_chrome_debugger.record([
            "name",
            "key",
            "fields",
            "virtual_fields",
            "interfaces"
          ], [
            name,
            key,
            fields,
            virtual_fields,
            interfaces
          ]);
}

function build_definition(allEntities, allDatatypes) {
  var data_type_dict = Plate$BwaxAdmin.List.rev(Plate$BwaxAdmin.List.foldl((function (acc, dataType) {
              var d = convert_data_type(allDatatypes, acc, dataType);
              return /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* tuple */[
                          d[/* name */0],
                          d
                        ],
                        acc
                      ]);
            }), /* [] */0, to_list(allDatatypes)));
  var entity_dict = Plate$BwaxAdmin.List.map((function (e) {
          return /* tuple */[
                  e[/* name */0],
                  e
                ];
        }), Plate$BwaxAdmin.List.map((function (param) {
              return convert_entity(allDatatypes, data_type_dict, param);
            }), to_list(allEntities)));
  return /* tuple */[
          entity_dict,
          data_type_dict
        ];
}

function dtype_to_type(handle_entity_link, handle_entity_link_opaue, handle_custom_type, handle_criteria, dtype) {
  if (typeof dtype === "number") {
    switch (dtype) {
      case /* Atom_int */0 :
          return "Int";
      case /* Atom_float */1 :
          return "Float";
      case /* Atom_bool */2 :
          return "Bool";
      case /* Atom_string */3 :
          return "String";
      case /* Atom_date */4 :
          return "Date";
      case /* Atom_json */5 :
          return "JSON";
      case /* Atom_richtext */6 :
          return "RichText";
      case /* Atom_image */7 :
          return "Image";
      case /* Atom_file */8 :
          return "File";
      case /* Atom_color */9 :
          return "Color";
      
    }
  } else {
    switch (dtype.tag | 0) {
      case /* Atom_select */0 :
          return "String";
      case /* Entity_link */1 :
          return Curry._1(handle_entity_link, dtype[0]);
      case /* Entity_link_opaque */2 :
          return Curry._2(handle_entity_link_opaue, dtype[0], dtype[1]);
      case /* Custom_type */3 :
          return Curry._1(handle_custom_type, dtype[0]);
      case /* Criteria */4 :
          return Curry._1(handle_criteria, dtype[0]);
      
    }
  }
}

var preset_module = "\n-- Types related to the entities.;\n\ntype Criteria a = And a | Or (List a);\n\n-- 下面的 a 通常是各个 Entity 对应的 SearchFields. 这里其实是可以加入更高级的类型定义的，但这个暂时不着急。\ntype alias Search a = { keyword: String, fields: a };\n\ntype SortingDirection = ASC | DESC;\n\ntype UpdateStatus a = Inact | OldValue a | Undefined;  \n    -- Undefined 适用于新增的有初始值的必填字段；或者新增的缓存虚拟字段\n\ntype alias PagedList a =\n  { data: List a \n  , offset: Int\n  , pageSize: Int \n  , count: Int\n  }\n;\n\nandCriteria: Criteria a -> Criteria a -> Criteria a = external;\n\norCriteria: Criteria a -> Criteria a -> Criteria a = external;\n\n\n-- 下面这三个是要为每个 entity 生成一个 external 函数，得到参数实例化后的类型的值\ntype QueryConfig a = opaque;\ntype Condition a = opaque;\n\ntype AggregateConfig a = opaque;\n\n-- type DataForm a = opaque;   -- 用于 update 以及在 add 控件提供 defaultValues 或者 fixedValues\n\ntype UpdatingForm a = opaque; -- 用于在 update 控件中提供 defaulValues 或者 fixedValues\ntype AddingForm a = opaque;   -- 用于在 add 控件中提供 defaulValues 或者 fixedValues\n\n-- 为每个自定义 custom op 都提供一个函数得到这个类型\ntype Operation a = opaque;\n-- 为每个自定义的针对特定记录的 custom op 提供一个函数得到这个类型\n-- 也有默认的 update, add 和 delete 的\ntype RecordOperation a = opaque; \n\n-- 下面是其他 query 相关的 ADT\n\n" + (Plate$BwaxAdmin.Str.join(";\n\n", Pervasives.$at(Base_query_types$BwaxAdmin.preset_condition_types, Pervasives.$at(Defs_aggregate$BwaxAdmin.preset_grouping_types, Defs_aggregate$BwaxAdmin.preset_aggregate_types))) + ";\n\n");

function entity_of_tname(entity_dict, tname) {
  var found = Plate$BwaxAdmin.List.find((function (param) {
          return Base_query_types$BwaxAdmin.tname_of(param[0]) === tname;
        }), entity_dict);
  if (found !== undefined) {
    return found[1];
  } else {
    console.log("not found entity for type", tname, Curry._1(Plate$BwaxAdmin.List.to_array, entity_dict));
    throw [
          Base_query_types$BwaxAdmin.Builder_exn,
          "I can\'t find entity for type `" + (String(tname) + "`.")
        ];
  }
}

function build_criteria_name(entity_dict, name_of_key) {
  return "Criteria ~" + Base_query_types$BwaxAdmin.criteria_tname(Base_query_types$BwaxAdmin.get_entity_name(entity_dict, name_of_key));
}

function is_normal_entity_query(entity_name, interface_type, output_types) {
  if (interface_type || !(output_types && !output_types[1])) {
    return false;
  } else {
    var output_type = output_types[0];
    if (typeof output_type === "number") {
      return false;
    } else {
      switch (output_type.tag | 0) {
        case /* Intf_maybe */1 :
            var match = output_type[0];
            if (typeof match === "number" || match.tag !== /* Intf_paged */5) {
              return false;
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || match$1.tag !== /* Intf_entity */6) {
                return false;
              } else {
                return match$1[0] === entity_name;
              }
            }
        case /* Intf_paged */5 :
            var match$2 = output_type[0];
            if (typeof match$2 === "number" || match$2.tag !== /* Intf_entity */6) {
              return false;
            } else {
              return match$2[0] === entity_name;
            }
        default:
          return false;
      }
    }
  }
}

function is_pagination_input(input_type) {
  if (typeof input_type === "number" || input_type.tag !== /* Intf_record */2) {
    return false;
  } else {
    var fields = input_type[0];
    var al = Plate$BwaxAdmin.List.map((function (param) {
            return /* tuple */[
                    param[0],
                    param[1]
                  ];
          }), fields);
    var pageSize = Plate$BwaxAdmin.List.assoc("pageSize", al);
    var offset = Plate$BwaxAdmin.List.assoc("offset", al);
    var is_int = function (maybe_value_type) {
      if (maybe_value_type !== undefined) {
        var match = maybe_value_type;
        if (typeof match === "number") {
          if (match === /* Intf_int */1) {
            return true;
          } else {
            return false;
          }
        } else if (match.tag === /* Intf_maybe */1) {
          var match$1 = match[0];
          if (typeof match$1 === "number") {
            return match$1 === 1;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    if (Plate$BwaxAdmin.List.length(fields) === 2 && is_int(pageSize)) {
      return is_int(offset);
    } else {
      return false;
    }
  }
}

function get_non_pagination_inputs(input_types) {
  return Plate$BwaxAdmin.List.foldl((function (param, input_type) {
                  var acc = param[1];
                  if (!param[0] && is_pagination_input(input_type)) {
                    return /* tuple */[
                            true,
                            acc
                          ];
                  } else {
                    return /* tuple */[
                            false,
                            Pervasives.$at(acc, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    input_type,
                                    /* [] */0
                                  ]))
                          ];
                  }
                }), /* tuple */[
                false,
                /* [] */0
              ], input_types)[1];
}

function pack_query_config(vs) {
  return /* tuple */[
          /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
              "__QueryConfig__",
              vs
            ]),
          Runtime_common$BwaxAdmin.nt
        ];
}

function invalid_args(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        name + ": Invalid Arguments"
      ];
}

function transform_to_id_criteria(param) {
  var match = param[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return invalid_args("byID");
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
      return invalid_args("byID");
    } else {
      return Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "criteria",
                      Runtime_common$BwaxAdmin.pack_tagged("And", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "id",
                                        Runtime_common$BwaxAdmin.pack_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                /* tuple */[
                                                  "eq",
                                                  Runtime_common$BwaxAdmin.pack_string(match$1[0])
                                                ],
                                                /* [] */0
                                              ]))
                                      ],
                                      /* [] */0
                                    ])),
                              /* [] */0
                            ]))
                    ],
                    /* [] */0
                  ]));
    }
  }
}

function pack_query_data_func(proc_params, name, cmd_name, module_name) {
  return Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1 && !match$1[1]) {
                      var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Curry._1(proc_params, param[0]),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              match[0],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  match$1[0],
                                  /* [] */0
                                ])
                            ])
                        ]);
                      var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                          cmd_name,
                          cmd_val_001
                        ]);
                      return /* tuple */[
                              cmd_val,
                              /* No_term */0
                            ];
                    }
                    
                  }
                  
                }
                return invalid_args(module_name + ("." + name));
              }));
}

function build_query_findOne(param) {
  return pack_query_data_func(Plate$BwaxAdmin.identity, "findOne", "Cmd_Query_findOne", param);
}

function build_query_findByID(param) {
  return pack_query_data_func(transform_to_id_criteria, "findByID", "Cmd_Query_findOne", param);
}

function build_query_list(param) {
  return pack_query_data_func(Plate$BwaxAdmin.identity, "list", "Cmd_Query_list", param);
}

function build_query_listAll(param) {
  return pack_query_data_func(Plate$BwaxAdmin.identity, "listAll", "Cmd_Query_listAll", param);
}

function build_query_count(module_name) {
  return Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      if (match$1[1]) {
                        return invalid_args(module_name + ".count");
                      } else {
                        var tname = Plate$BwaxAdmin.Str.replace("Query_", "E_", module_name);
                        var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [tname])]),
                              /* No_term */0
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                param[0],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match[0],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        match$1[0],
                                        /* [] */0
                                      ])
                                  ])
                              ])
                          ]);
                        var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                            "Cmd_Query_count",
                            cmd_val_001
                          ]);
                        return /* tuple */[
                                cmd_val,
                                /* No_term */0
                              ];
                      }
                    } else {
                      return invalid_args(module_name + ".count");
                    }
                  } else {
                    return invalid_args(module_name + ".count");
                  }
                } else {
                  return invalid_args(module_name + ".count");
                }
              }));
}

function build_query_add(module_name) {
  return Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      if (match$1[1]) {
                        return invalid_args(module_name + ".add");
                      } else {
                        var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            param[0],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                match[0],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match$1[0],
                                    /* [] */0
                                  ])
                              ])
                          ]);
                        var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                            "Cmd_Query_add",
                            cmd_val_001
                          ]);
                        return /* tuple */[
                                cmd_val,
                                /* No_term */0
                              ];
                      }
                    } else {
                      return invalid_args(module_name + ".add");
                    }
                  } else {
                    return invalid_args(module_name + ".add");
                  }
                } else {
                  return invalid_args(module_name + ".add");
                }
              }));
}

function build_query_update(module_name) {
  return Runtime_common$BwaxAdmin.pack_func(4, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      var match$2 = match$1[1];
                      if (match$2) {
                        if (match$2[1]) {
                          return invalid_args(module_name + ".update");
                        } else {
                          var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              param[0],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  match[0],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      match$1[0],
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          match$2[0],
                                          /* [] */0
                                        ])
                                    ])
                                ])
                            ]);
                          var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                              "Cmd_Query_update",
                              cmd_val_001
                            ]);
                          return /* tuple */[
                                  cmd_val,
                                  /* No_term */0
                                ];
                        }
                      } else {
                        return invalid_args(module_name + ".update");
                      }
                    } else {
                      return invalid_args(module_name + ".update");
                    }
                  } else {
                    return invalid_args(module_name + ".update");
                  }
                } else {
                  return invalid_args(module_name + ".update");
                }
              }));
}

function build_query_delete(module_name) {
  return Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      if (match$1[1]) {
                        return invalid_args(module_name + ".delete");
                      } else {
                        var tname = Plate$BwaxAdmin.Str.replace("Query_", "E_", module_name);
                        var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [tname])]),
                              /* No_term */0
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                param[0],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match[0],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        match$1[0],
                                        /* [] */0
                                      ])
                                  ])
                              ])
                          ]);
                        var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                            "Cmd_Query_delete",
                            cmd_val_001
                          ]);
                        return /* tuple */[
                                cmd_val,
                                /* No_term */0
                              ];
                      }
                    } else {
                      return invalid_args(module_name + ".delete");
                    }
                  } else {
                    return invalid_args(module_name + ".delete");
                  }
                } else {
                  return invalid_args(module_name + ".delete");
                }
              }));
}

function build_query_aggregate(module_name) {
  return Runtime_common$BwaxAdmin.pack_func(3, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      if (match$1[1]) {
                        return invalid_args(module_name + ".aggregate");
                      } else {
                        var tname = Plate$BwaxAdmin.Str.replace("Query_", "E_", module_name);
                        var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Runtime_common$BwaxAdmin.pack_string(tname),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                param[0],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match[0],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        match$1[0],
                                        /* [] */0
                                      ])
                                  ])
                              ])
                          ]);
                        var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                            "Cmd_Query_aggregate",
                            cmd_val_001
                          ]);
                        return /* tuple */[
                                cmd_val,
                                /* No_term */0
                              ];
                      }
                    } else {
                      return invalid_args(module_name + ".aggregate");
                    }
                  } else {
                    return invalid_args(module_name + ".aggregate");
                  }
                } else {
                  return invalid_args(module_name + ".aggregate");
                }
              }));
}

function build_custom_interface(module_name, entity_name, $$interface) {
  var interface_type = $$interface[/* interface_type */2];
  var name = $$interface[/* name */0];
  var fname = Plate$BwaxAdmin.Str.decap(name);
  var arity = ((
      Plate$BwaxAdmin.List.length($$interface[/* input_types */3]) > 0 ? 1 : 0
    ) + (
      $$interface[/* is_targeting_record */5] ? 1 : 0
    ) | 0) + 2 | 0;
  return /* tuple */[
          fname,
          Runtime_common$BwaxAdmin.pack_func(arity, (function (args) {
                  var match = Plate$BwaxAdmin.List.rev(args);
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      var cmd_name = interface_type ? "Cmd_Query_custom_operation" : "Cmd_Query_custom_query";
                      var cmd_params_000 = Runtime_common$BwaxAdmin.pack_string(entity_name);
                      var cmd_params_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Runtime_common$BwaxAdmin.pack_string(name),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Runtime_common$BwaxAdmin.pack_list(Plate$BwaxAdmin.List.rev(match$1[1])),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  match$1[0],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      match[0],
                                      /* [] */0
                                    ])
                                ])
                            ])
                        ]);
                      var cmd_params = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          cmd_params_000,
                          cmd_params_001
                        ]);
                      return /* tuple */[
                              /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                                  cmd_name,
                                  cmd_params
                                ]),
                              /* No_term */0
                            ];
                    } else {
                      return invalid_args(module_name + ("." + name));
                    }
                  } else {
                    return invalid_args(module_name + ("." + name));
                  }
                }))
        ];
}

function build_custom_query_config(entity_name, $$interface) {
  var is_targeting_record = $$interface[/* is_targeting_record */5];
  var name = $$interface[/* name */0];
  if (is_normal_entity_query(entity_name, $$interface[/* interface_type */2], $$interface[/* output_types */4])) {
    var non_pagination_inputs = get_non_pagination_inputs($$interface[/* input_types */3]);
    var v;
    if (Plate$BwaxAdmin.List.length(non_pagination_inputs) === 0 && !is_targeting_record) {
      v = pack_query_config(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [entity_name])]),
                Runtime_common$BwaxAdmin.nt
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [name])]),
                    Runtime_common$BwaxAdmin.nt
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        /* V_unit */0,
                        Runtime_common$BwaxAdmin.nt
                      ],
                      /* [] */0
                    ])
                ])
            ]));
    } else {
      var arity = (
        Plate$BwaxAdmin.List.length(non_pagination_inputs) > 0 ? 1 : 0
      ) + (
        is_targeting_record ? 1 : 0
      ) | 0;
      v = Runtime_common$BwaxAdmin.pack_func(arity, (function (param) {
              if (param) {
                var match = param[1];
                var params = param[0];
                if (match) {
                  if (match[1]) {
                    return invalid_args(name);
                  } else {
                    return pack_query_config(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [entity_name])]),
                                    Runtime_common$BwaxAdmin.nt
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [name])]),
                                        Runtime_common$BwaxAdmin.nt
                                      ],
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          params,
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              match[0],
                                              /* [] */0
                                            ])
                                        ])
                                    ])
                                ]));
                  }
                } else {
                  return pack_query_config(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [entity_name])]),
                                  Runtime_common$BwaxAdmin.nt
                                ],
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    /* tuple */[
                                      /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [name])]),
                                      Runtime_common$BwaxAdmin.nt
                                    ],
                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                        params,
                                        /* [] */0
                                      ])
                                  ])
                              ]));
                }
              } else {
                return invalid_args(name);
              }
            }));
    }
    var fname = "to_" + Plate$BwaxAdmin.Str.decap(name);
    return /* tuple */[
            fname,
            v
          ];
  }
  
}

function build_record_op(entity_name, $$interface) {
  var name = $$interface[/* name */0];
  var is_operation = $$interface[/* interface_type */2] ? true : false;
  if (!$$interface[/* is_targeting_record */5] || !is_operation) {
    return ;
  } else {
    var fname = "to_" + Plate$BwaxAdmin.Str.decap(name);
    var value = Plate$BwaxAdmin.List.length($$interface[/* input_types */3]) > 0 ? Runtime_common$BwaxAdmin.pack_func(1, (function (args) {
              return Runtime_common$BwaxAdmin.pack_tuple(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                            Runtime_common$BwaxAdmin.pack_string(entity_name),
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                Runtime_common$BwaxAdmin.pack_string(name),
                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    Runtime_common$BwaxAdmin.pack_list(args),
                                    /* [] */0
                                  ])
                              ])
                          ]));
            })) : Runtime_common$BwaxAdmin.pack_tuple(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              Runtime_common$BwaxAdmin.pack_string(entity_name),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Runtime_common$BwaxAdmin.pack_string(name),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Runtime_common$BwaxAdmin.pack_list(/* [] */0),
                      /* [] */0
                    ])
                ])
            ]));
    return /* tuple */[
            fname,
            value
          ];
  }
}

function pack_params_with_entity_name(module_name, name, packer, entity) {
  var f = Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
          if (param) {
            if (param[1]) {
              return invalid_args(module_name + ("." + name));
            } else {
              return Curry._1(packer, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* tuple */[
                              /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [entity[/* name */0]])]),
                              Runtime_common$BwaxAdmin.nt
                            ],
                            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                param[0],
                                /* [] */0
                              ])
                          ]));
            }
          } else {
            return invalid_args(module_name + ("." + name));
          }
        }));
  return /* tuple */[
          name,
          f
        ];
}

function get_query_module_externals(entity) {
  var name = Base_query_types$BwaxAdmin.normalize_name(entity[/* name */0]);
  var module_name = "Query_" + name;
  var findOne = build_query_findOne(module_name);
  var findByID = build_query_findByID(module_name);
  var list = build_query_list(module_name);
  var add = build_query_add(module_name);
  var update = build_query_update(module_name);
  var $$delete = build_query_delete(module_name);
  var aggregate = build_query_aggregate(module_name);
  var partial_arg = entity[/* name */0];
  var custom_interfaces = Plate$BwaxAdmin.List.map((function (param) {
          return build_custom_interface(module_name, partial_arg, param);
        }), entity[/* interfaces */4]);
  var pack_object_func = function (packer, name) {
    return pack_params_with_entity_name(module_name, name, packer, entity);
  };
  var pack_query_config_byID = function (param) {
    if (param) {
      var match = param[1];
      if (match) {
        if (match[1]) {
          return Runtime_common$BwaxAdmin.invalid_args(module_name + ".byID");
        } else {
          return pack_query_config(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                        param[0],
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            transform_to_id_criteria(match[0]),
                            /* [] */0
                          ])
                      ]));
        }
      } else {
        return Runtime_common$BwaxAdmin.invalid_args(module_name + ".byID");
      }
    } else {
      return Runtime_common$BwaxAdmin.invalid_args(module_name + ".byID");
    }
  };
  var objs_000 = pack_object_func(pack_query_config, "config");
  var objs_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      pack_object_func(pack_query_config_byID, "byID"),
      Plate$BwaxAdmin.List.map((function (param) {
              return pack_object_func(Runtime_common$BwaxAdmin.pack_tuple, param);
            }), /* :: */Caml_chrome_debugger.simpleVariant("::", [
              "aggregateConfig",
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  "condition",
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      "adding",
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          "updating",
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              "form",
                              /* [] */0
                            ])
                        ])
                    ])
                ])
            ]))
    ]);
  var objs = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      objs_000,
      objs_001
    ]);
  var partial_arg$1 = entity[/* name */0];
  var custom_query_configs = Plate$BwaxAdmin.List.keep_map((function (param) {
          return build_custom_query_config(partial_arg$1, param);
        }), entity[/* interfaces */4]);
  var partial_arg$2 = entity[/* name */0];
  var record_ops = Plate$BwaxAdmin.List.keep_map((function (param) {
          return build_record_op(partial_arg$2, param);
        }), entity[/* interfaces */4]);
  return /* tuple */[
          module_name,
          Pervasives.$at(Plate$BwaxAdmin.List.keep_map(Plate$BwaxAdmin.identity, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "findOne",
                        findOne
                      ],
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* tuple */[
                            "findByID",
                            findByID
                          ],
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* tuple */[
                                "list",
                                list
                              ],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* tuple */[
                                    "listAll",
                                    build_query_listAll(module_name)
                                  ],
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      /* tuple */[
                                        "count",
                                        build_query_count(module_name)
                                      ],
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          /* tuple */[
                                            "add",
                                            add
                                          ],
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              /* tuple */[
                                                "update",
                                                update
                                              ],
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  /* tuple */[
                                                    "delete",
                                                    $$delete
                                                  ],
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      /* tuple */[
                                                        "aggregate",
                                                        aggregate
                                                      ],
                                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          Defs_remat$BwaxAdmin.build_remat(module_name, entity),
                                                          /* [] */0
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])), Pervasives.$at(custom_interfaces, Pervasives.$at(objs, Pervasives.$at(custom_query_configs, record_ops))))
        ];
}

function pack_cmd_base_func(proc_params, name, cmd_name, module_name) {
  return Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1 && !match$1[1]) {
                      var cmd_val_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Curry._1(proc_params, param[0]),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              match[0],
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  match$1[0],
                                  /* [] */0
                                ])
                            ])
                        ]);
                      var cmd_val = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                          cmd_name,
                          cmd_val_001
                        ]);
                      return /* tuple */[
                              cmd_val,
                              /* No_term */0
                            ];
                    }
                    
                  }
                  
                }
                return invalid_args(module_name + ("." + name));
              }));
}

function get_query_externals(entity_dict) {
  var critiera_utils_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* tuple */[
        "andCriteria",
        Runtime_common$BwaxAdmin.pack_func(2, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match && !match[1]) {
                    var merged = Base_query_types$BwaxAdmin.and_conds(Base_query_types$BwaxAdmin.pack_criteria_value(Base_query_types$BwaxAdmin.to_criteria(param[0])), Base_query_types$BwaxAdmin.pack_criteria_value(Base_query_types$BwaxAdmin.to_criteria(match[0])));
                    return /* tuple */[
                            /* V_tagged_raw */Caml_chrome_debugger.variant("V_tagged_raw", 8, [
                                "Criteria",
                                merged
                              ]),
                            Runtime_common$BwaxAdmin.nt
                          ];
                  } else {
                    return invalid_args("andCriteria");
                  }
                } else {
                  return invalid_args("andCriteria");
                }
              }))
      ],
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "orCriteria",
            Runtime_common$BwaxAdmin.pack_func(2, (function (param) {
                    if (param) {
                      var match = param[1];
                      if (match && !match[1]) {
                        var merged = Base_query_types$BwaxAdmin.or_conds(Base_query_types$BwaxAdmin.pack_criteria_value(Base_query_types$BwaxAdmin.to_criteria(param[0])), Base_query_types$BwaxAdmin.pack_criteria_value(Base_query_types$BwaxAdmin.to_criteria(match[0])));
                        return /* tuple */[
                                /* V_tagged_raw */Caml_chrome_debugger.variant("V_tagged_raw", 8, [
                                    "Criteria",
                                    merged
                                  ]),
                                Runtime_common$BwaxAdmin.nt
                              ];
                      } else {
                        return invalid_args("orCriteria");
                      }
                    } else {
                      return invalid_args("orCriteria");
                    }
                  }))
          ],
          /* [] */0
        ])
    ]);
  var critiera_utils = /* tuple */[
    undefined,
    critiera_utils_001
  ];
  var func = function (args) {
    return /* tuple */[
            /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                "Cmd_Query_get_current_user",
                args
              ]),
            Runtime_common$BwaxAdmin.nt
          ];
  };
  var func$1 = function (args) {
    return /* tuple */[
            /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                "Cmd_Query_require_current_user",
                args
              ]),
            Runtime_common$BwaxAdmin.nt
          ];
  };
  var func$2 = function (args) {
    return /* tuple */[
            /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                "Cmd_Query_nice_to_have_current_user",
                args
              ]),
            Runtime_common$BwaxAdmin.nt
          ];
  };
  var func$3 = function (args) {
    return /* tuple */[
            /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                "Cmd_Query_requestAuthSession",
                args
              ]),
            Runtime_common$BwaxAdmin.nt
          ];
  };
  var get_current_user_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* tuple */[
        "getCurrentUser",
        Runtime_common$BwaxAdmin.pack_func(2, func)
      ],
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* tuple */[
            "requireCurrentUser",
            Runtime_common$BwaxAdmin.pack_func(2, func$1)
          ],
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* tuple */[
                "requireUser",
                Runtime_common$BwaxAdmin.pack_func(3, (function (args) {
                        return /* tuple */[
                                /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                                    "Cmd_Query_requireUser",
                                    args
                                  ]),
                                Runtime_common$BwaxAdmin.nt
                              ];
                      }))
              ],
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* tuple */[
                    "niceToHaveCurrentUser",
                    Runtime_common$BwaxAdmin.pack_func(2, func$2)
                  ],
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "requestAuthSession",
                        Runtime_common$BwaxAdmin.pack_func(2, func$3)
                      ],
                      /* [] */0
                    ])
                ])
            ])
        ])
    ]);
  var get_current_user = /* tuple */[
    undefined,
    get_current_user_001
  ];
  var query_entity_externals = Plate$BwaxAdmin.List.map(get_query_module_externals, Plate$BwaxAdmin.List.assoc_vals(entity_dict));
  return /* :: */Caml_chrome_debugger.simpleVariant("::", [
            critiera_utils,
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                get_current_user,
                query_entity_externals
              ])
          ]);
}

exports.to_list = to_list;
exports.convert_dtype = convert_dtype;
exports.convert_field = convert_field;
exports.convert_data_type = convert_data_type;
exports.convert_interface_value_type = convert_interface_value_type;
exports.convert_input_output_types = convert_input_output_types;
exports.convert_interface = convert_interface;
exports.convert_entity = convert_entity;
exports.build_definition = build_definition;
exports.dtype_to_type = dtype_to_type;
exports.preset_module = preset_module;
exports.entity_of_tname = entity_of_tname;
exports.build_criteria_name = build_criteria_name;
exports.is_normal_entity_query = is_normal_entity_query;
exports.is_pagination_input = is_pagination_input;
exports.get_non_pagination_inputs = get_non_pagination_inputs;
exports.pack_query_config = pack_query_config;
exports.invalid_args = invalid_args;
exports.transform_to_id_criteria = transform_to_id_criteria;
exports.pack_query_data_func = pack_query_data_func;
exports.build_query_findOne = build_query_findOne;
exports.build_query_findByID = build_query_findByID;
exports.build_query_list = build_query_list;
exports.build_query_listAll = build_query_listAll;
exports.build_query_count = build_query_count;
exports.build_query_add = build_query_add;
exports.build_query_update = build_query_update;
exports.build_query_delete = build_query_delete;
exports.build_query_aggregate = build_query_aggregate;
exports.build_custom_interface = build_custom_interface;
exports.build_custom_query_config = build_custom_query_config;
exports.build_record_op = build_record_op;
exports.pack_params_with_entity_name = pack_params_with_entity_name;
exports.get_query_module_externals = get_query_module_externals;
exports.pack_cmd_base_func = pack_cmd_base_func;
exports.get_query_externals = get_query_externals;
/* preset_module Not a pure module */
