


import React, { useRef, useState, useEffect} from 'react'

import './ShortText.less'

import ColoredTag from 'Client/js/ui/components/ColoredTag';
import FlexTags from 'Client/js/ui/components/FlexTags';

import { displayWithQrIcon } from './TextDisplay';

/**
 * value - it could be 
 *          1) a string
 *          3) an array of string
 * 
 */
export default function ShortText({ params }) {
    const { value, colors = {}, defaultColor, fieldOptions } = params;


    const isSensitive = fieldOptions && fieldOptions.sensitive;

    const maskText = text => {
        // 如果不是敏感信息，不用 mask
        if(!text) return text;
        if(!isSensitive) {
            return displayWithQrIcon(text);
        }
        // 如果大于 11
        // 保留后面四位和前面三位
        if(text.length >= 11) {
            return text.substring(0, 3) + text.slice(-4).padStart(text.length - 3, "*");
        } else if(text.length >= 6) {
            return text.slice(-2).padStart(text.length, "*")
        } else if(text.length >= 3) {
            return text.slice(-1).padStart(text.length, "*")
        } else {
            return "".padStart(text.length, "*")
        }
    }


    function render() {
        if (Array.isArray(value)) {
            const texts = (value || []).filter(x => !!x).map(
                text => ({ text: maskText(text), color: colors[text] || defaultColor})
            );
            return <FlexTags texts={texts} />
        } else {
            if(!value) return null;
            const text = value;
            const color = colors[text] || defaultColor;
            return color ? <ColoredTag {...{ color, text: maskText(text)}} /> : maskText(text)
        }
    }
    return <div className="admin--display-shorttext">{render()}</div>
}
