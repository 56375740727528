
function getBodyTypographStyle(styleKey) {
    if(typeof (window) !== "undefined") {
        return window.getComputedStyle(window.document.body)[styleKey]
    } else {
        const defaultTypographStyle = {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',
            color: 'rgba(0, 0, 0, 0.85)'
        }

        return defaultTypographStyle[styleKey]
    }
}

export const activeStyles = {
    flexChild: {
        lengthAttrs: [{
            attr: 'order', defaultRegx: /0/
        }, {
            attr: 'flex', defaultRegx: /^0 1 auto$/
        }],
        commonAttrs: ['alignSelf']
    },
    layout: {
        lengthAttrs: [{
            attr: 'gap', defaultRegx: /^0[^ ]*( 0[^ ]*)?$/
        }],
        commonAttrs: ['display', 'flexDirection', 'alignItems', 'justifyContent', 'flexWrap', 'alignContent']
    },
    spacing: {
        lengthAttrs: [{
            attr: 'padding', defaultRegx: /^0[^ ]*( 0[^ ]*){0,3}$/
        }, {
            attr: 'margin', defaultRegx: /^0[^ ]*( 0[^ ]*){0,3}$/
        }],
        commonAttrs: []
    },
    size: {
        lengthAttrs: [{
            attr: 'width', defaultRegx: /^[aA]uto$/
        }, {
            attr: 'height', defaultRegx: /^[aA]uto$/
        }, {
            attr: 'maxWidth', defaultRegx: /^none$/
        }, {
            attr: 'minWidth', defaultRegx: /^0[^ ]*$/
        }, {
            attr: 'maxHeight', defaultRegx: /^none$/
        }, {
            attr: 'minHeight', defaultRegx: /^0[^ ]*$/
        }, {
            attr: 'objectPosition', defaultRegx: /^50% 50%$/
        }, {
            attr: 'objectFit', defaultRegx: /^fill$/
        }],
        commonAttrs: ['overflow']
    },
    background: {
        lengthAttrs: [{
            attr: 'backgroundColor', defaultRegx: /^transparent$/
        }, {
            attr: 'backgroundClip', defaultRegx: /^border-box$/
        }],
        commonAttrs: ['backgroundImage', 'backgroundPosition', 'backgroundSize',
            'backgroundRepeat', 'backgroundAttachment'
        ]
    },
    border: {
        lengthAttrs: [{
            attr: 'borderRadius', defaultRegx: /^0[^ ]*( 0[^ ]*){0,3}$/
        }, {
            attr: 'borderTop', defaultRegx: /^0[^ ]* none black$/
        }, {
            attr: 'borderRight', defaultRegx: /^0[^ ]* none black$/
        }, {
            attr: 'borderBottom', defaultRegx: /^0[^ ]* none black$/
        }, {
            attr: 'borderLeft', defaultRegx: /^0[^ ]* none black$/
        }],
        commonAttrs: []
    },
    typography: {
        lengthAttrs: [{
            attr: 'fontWeight', defaultRegx: /^[nN]ormal|400$/
        }, {
            attr: 'fontSize', defaultRegx: new RegExp(`^${getBodyTypographStyle('fontSize')}$`)
        }, {
            attr: 'lineHeight', defaultRegx: new RegExp(`^${getBodyTypographStyle('lineHeight')}$`)
        }, {
            attr: 'color', defaultRegx: new RegExp(`^${getBodyTypographStyle('color')}$`)
        }, {
            attr: 'letterSpacing', defaultRegx: /^[nN]ormal$/
        }, {
            attr: 'textIndent', defaultRegx: /^0[^ ]*$/
        }, {
            attr: 'columnCount', defaultRegx: /^[aA]uto$/
        }, {
            attr: 'whiteSpace', defaultRegx: /^[nN]ormal$/
        }],
        commonAttrs: ['textAlign', 'fontStyle', 'textDecoration', 'textTransform', 'direction', 'textShadow']
    },
    effects: {
        lengthAttrs: [{
            attr: 'opacity', defaultRegx: /^1$/
        }, {
            attr: 'cursor', defaultRegx: /^auto$/
        }],
        commonAttrs: ['boxShadow']
    }
}