
import React from 'react';

import { make as BwColoredButton } from './BwColoredButton.bs';

const BwColoredRefedButton = React.forwardRef((props, ref) => {

    return <BwColoredButton {...{ ...props, domRef: ref }} />

})

export default BwColoredRefedButton;

export function create (props) {
    return <BwColoredRefedButton {...props } />
}
