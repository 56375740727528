import React from 'react'
import { Route, Switch } from "react-router-dom"

import qs from 'query-string';

import AdminActions from 'Client/js/ui/actions'


import ColorPalette from './pages/color/ColorPalette';

import PresetCodes from './pages/PresetCodes'

import buildTestPage from "./builders/buildTestPage";


import EntityDataTabs from 'Client/js/pages/EntityDataTabs';

import CodeSearch from './CodeSearch';

const QueryContainer = require("bwax-ui/re/legacy/QueryContainer.bs").make;

const PublishPage = require("Client/re/pages/PublishPage.bs").make;

const rawPaths = {

    "publish": {
        Comp: (props => {
            const { currentUser } = props;
            const disabled = !currentUser || (currentUser.systemRoles.indexOf("Maintainer") === -1);
            return (
                <div style={{
                    height: "100%", padding: "1rem", backgroundColor: "white"
                }}>
                    <PublishPage disabled={disabled} {...props} />
                </div>
            )
        })
    },
    "applications/:tab?": {
        entityKey: "Application",
        Comp: (props => {
            let tabs = {
                "列表": {
                    type: "RecordList",
                    options: {
                        identifyingField: {
                            name: '应用名',
                            linkPattern: `/app/:id`, 
                            /// the value is the ref name of the field record.
                        },
                        criteria: {},
                        excludedFields: [
                            "应用依赖", "唯一标识", "修改者", "修改时间"
                        ],
                        allowedActions: [
                            {
                                type: "AddRecord",
                                excludedFields: [
                                    // "代码"                                   
                                ]
                            }
                        ]
                    }
                }
            };
            const entity = props.allEntities.find(e => e.key == "Application");
            return <EntityDataTabs entity={entity} tabs={tabs} {...props} />
        })
    },

    "record-detail/:entityKey/records/:id/:tab?": {
        Comp: (props => {
            
            const { match_ } = props;
            const { params } = match_;
            const { id, entityKey } = params;
            const { allEntities } = props;
            const entity = allEntities.find(e => e.key == entityKey || e.name == entityKey);

            const tabs = {
                "基本信息":       {
                    "type": "RecordEdit",
                    "options": {
                      "excludedFields": [
                        "创建者"
                      ],
                      "deletionAllowed": true
                    }
                }
            };

            return (
                <EntityDataTabs
                    tabs={tabs}
                    entity={entity}
                    id={id}
                    { ...props }
                />
            )


        })
    },


    "incompatible": {
        "build": () => {
            return {
                layout: {
                    content: [
                        {
                            type_: "admin::templates::PageContent",
                            content: {
                                type: "admin::OutdatedPageList"
                            } 
                        }
                      
                    ]
                }
            }
        }
    },


    "color": {
        Comp: ColorPalette
    },

    "test-page": {
        build: buildTestPage
    },



    "code-search": {
        Comp: CodeSearch
    },


    "lookups/:tab?": {        
        Comp: (props => {

            const { currentApplication } = props;
            const appId =  currentApplication ? currentApplication.id : null;
            const appValue = {
                应用: appId
            };
            const tabs = {
                "选项设定": {
                    type: "RecordList",
                    options: {
                        criteria: {
                            ...appValue
                        },
                        fieldColumns: {
                            "类别": {
                                "path": "类别",
                                "editingAllowed": true,                            
                            },
                            "值类型": {
                                "path": "值类型",
                                "editingAllowed": true
                            },
                            "多值": {
                                "path": "多值",
                                "editingAllowed": true
                            },
                            "初始选项值": {
                                "path": "初始选项值",
                                "editingAllowed": true
                            }
                        },
                        operations: {},
                        allowedActions: [
                            {
                                type: "AddRecord",
                                excludedFields: [
                                ],
                                defaultValues:{
                                    "多值": true
                                },
                                fixedValues: {
                                    ...appValue
                                }
                            }
                        ]
                    }
                }
            };
            const entity = props.allEntities.find(e => e.key == "LookupValueSetting");
            return <EntityDataTabs entity={entity} tabs={tabs} {...props} />
        })
    },



    // "/design/theme": ThemeEdit,

    
    "code-presets/:tab?": {
        Comp: PresetCodes
    },
}

export const paths = Object.keys(rawPaths).reduce((acc, p) => {
    const config = rawPaths[p];
    if(p.startsWith("/")) {
        return {
            ...acc,
            [p]: config
        }
    } else {
        return {
            ...acc,
            ["/design/:appcode/" + p]: config
        }
    }
}, {});

function DesignAppRoutesInner(outerProps) {

    const { location, widgetFactory, routeTo, currentApplication, ...remaining } = outerProps

    return (
        <Switch location={location}>
            {
                Object.keys(paths).map(path => (
                    <Route key={path} path={path} render={props => {

                        function renderPage() {

                            const { allDataTypes, allEntities, facade } = remaining
                            const { match } = props


                            const toPassOn = {
                                queryTarget: 'definition',
                                widgetFactory,
                                customActions: AdminActions,
                                routeTo,
                                ...remaining,
                                ...props,
                                match_: match,

                                currentApplication,

                                bwax: facade,


                                additional: {
                                    ...(remaining.additional || {}),
                                    facade: facade
                                }
                            }


                            const builder = paths[path]
                            const { Comp } = builder

                            if (Comp) {
                                return <Comp {...toPassOn} />
                            }

                            const data = {
                                currentApplication,
                                ...match.params,
                                ...(qs.parse(location.search))   /// Query 参数有可能覆盖掉 Path 参数
  
                            }

                            let build = builder.build || builder;

                            const def = build(data, { allDataTypes, allEntities })

                            return <QueryContainer
                                def={def}
                                baseData={data}
                                {
                                ...toPassOn
                                }
                                match_={match} />
                        }
                        return <div style={{ flex: 1, overflow: 'hidden' }} className="design-app">
                            {renderPage()}
                        </div>
                    }} />
                ))
            }
        </Switch>
    )
}


export default function DesignAppRoutes(props) {

    const { location } = props

    const content = React.useMemo(() => {
        return <DesignAppRoutesInner {...props} />
    }, [location])

    return content
}
