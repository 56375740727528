// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../../../bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxAdmin = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Runtime_common$BwaxAdmin = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Widget_html_common$BwaxAdmin = require("./widget_html_common.bs.js");

var module_name = "LocalFile";

var src = "\nmodule " + (String(module_name) + ";\n\n-- File name, or file title\nname : LocalFile -> String = external;\n\nsize: LocalFile -> Int = external;\n\n-- \nfileType: LocalFile -> File.FileType = external;\n\n");

function fail(name) {
  throw [
        Lang_eval$BwaxAdmin.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function pack_getter(decoder, pack, name) {
  return Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
                if (param) {
                  var match = param[0][0];
                  if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "LocalFile" && !param[1])) {
                    return fail(name);
                  } else {
                    var match$1 = Json$BwaxAdmin.get_value(name, decoder, match[1]);
                    if (match$1 !== undefined) {
                      return Curry._1(pack, Caml_option.valFromOption(match$1));
                    } else {
                      return fail(name);
                    }
                  }
                } else {
                  return fail(name);
                }
              }));
}

function attribute_msg_func(name) {
  return /* tuple */[
          name,
          Widget_html_common$BwaxAdmin.pack_attribute_func(1, (function (param) {
                  if (param && !param[1]) {
                    return /* Attribute_event_msg */Caml_chrome_debugger.variant("Attribute_event_msg", 4, [
                              name,
                              param[0]
                            ]);
                  } else {
                    return fail(name);
                  }
                }))
        ];
}

function attribute_string_msg_func(name) {
  return /* tuple */[
          name,
          Widget_html_common$BwaxAdmin.pack_attribute_func(1, (function (param) {
                  if (param && !param[1]) {
                    var f = param[0];
                    var handle_event = function (s) {
                      return Lang_eval$BwaxAdmin.apply_value(undefined, f, /* tuple */[
                                  /* V_literal */Caml_chrome_debugger.variant("V_literal", 5, [/* String */Caml_chrome_debugger.variant("String", 2, [s])]),
                                  Runtime_common$BwaxAdmin.nt
                                ], Runtime_common$BwaxAdmin.nt);
                    };
                    return /* Attribute_event_string_msg */Caml_chrome_debugger.variant("Attribute_event_string_msg", 5, [
                              name,
                              handle_event
                            ]);
                  } else {
                    return fail(name);
                  }
                }))
        ];
}

var externals_vals_000 = /* tuple */[
  "fileType",
  Runtime_common$BwaxAdmin.pack_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "LocalFile" && !param[1])) {
              return fail("fileType");
            } else {
              var image_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "IMAGE",
                  /* [] */0
                ]);
              var image = /* tuple */[
                image_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var doc_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "DOC",
                  /* [] */0
                ]);
              var doc = /* tuple */[
                doc_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var pdf_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "PDF",
                  /* [] */0
                ]);
              var pdf = /* tuple */[
                pdf_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var xls_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "XLS",
                  /* [] */0
                ]);
              var xls = /* tuple */[
                xls_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var ppt_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "PPT",
                  /* [] */0
                ]);
              var ppt = /* tuple */[
                ppt_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var other_000 = /* V_tagged */Caml_chrome_debugger.variant("V_tagged", 6, [
                  "OTHER",
                  /* [] */0
                ]);
              var other = /* tuple */[
                other_000,
                Runtime_common$BwaxAdmin.nt
              ];
              var maybeContentType = Json$BwaxAdmin.get_value("type", Js_json.decodeString, match[1]);
              if (maybeContentType !== undefined) {
                var v = Plate$BwaxAdmin.Str.to_lower(maybeContentType);
                if (Plate$BwaxAdmin.Str.starts_with(v, "image")) {
                  return image;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/pdf")) {
                  return pdf;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.ms-powerpoint") || Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.openxmlformats-officedocument.presentationml.presentation")) {
                  return ppt;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.ms-excel") || Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                  return xls;
                } else if (Plate$BwaxAdmin.Str.starts_with(v, "application/msword") || Plate$BwaxAdmin.Str.starts_with(v, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
                  return doc;
                } else {
                  return other;
                }
              } else {
                return other;
              }
            }
          } else {
            return fail("fileType");
          }
        }))
];

var externals_vals_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    /* tuple */[
      "name",
      pack_getter(Js_json.decodeString, Runtime_common$BwaxAdmin.pack_string, "name")
    ],
    /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* tuple */[
          "size",
          pack_getter(Js_json.decodeNumber, (function (param) {
                  return Plate$BwaxAdmin.$at$great((function (prim) {
                                return prim | 0;
                              }), Runtime_common$BwaxAdmin.pack_int, param);
                }), "size")
        ],
        /* [] */0
      ])
  ]);

var externals_vals = /* :: */Caml_chrome_debugger.simpleVariant("::", [
    externals_vals_000,
    externals_vals_001
  ]);

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.pack_getter = pack_getter;
exports.attribute_msg_func = attribute_msg_func;
exports.attribute_string_msg_func = attribute_string_msg_func;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* src Not a pure module */
