
import React, { Suspense, useEffect } from 'react';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary';
import { ExecutionContextProvider } from 'bwax-ui/page/ExecutionContext';
import { DataLoaderProvider } from 'bwax-ui/store/DataLoaderContext'
import { QueryTargetContextProvider } from 'bwax-ui/legacy/store/QueryTargetContext'


import PageEnvContainer from 'bwax-ui/page/PageEnvContainer';

import { DataPromiseProvider } from 'bwax-ui/store/RenderWithData'

import queryString from 'query-string'

import { hashCode } from 'bwax/utils'

import { Helmet } from 'react-helmet-async';

import { isMobile, isWeChat, isIOS, isWxWork } from 'bwax/clientEnv'

const promiseHolder = {};

export default function PagePreview(props) {

    const { initParamExprs, facade, compiledStr, styles, dlc, runtimeProfile, route_to } = props

    const { urlPattern = "/" } = runtimeProfile;

    // 只考虑一个 init paramter
    const paramExpr = initParamExprs[0];
    const params = lang_entry_slim.expr_val_assoc_list_to_js_obj(
        lang_entry_slim.evaluate(facade.baseEnv, undefined, paramExpr)
    );

    // 在这里 loation 应该是根据 page url 和 params 共同仿造出来的    
    // 好像这个不能简单的把所有 params 变成 query
    // 1. 先根据 URL pattern 里面的变量处理完 params;
    // 2. 然后再把剩下的 params 变成 query.                                                                

    const [pathname, remaining] = Object.keys(params).reduce((acc, name) => {
        const [u, r] = acc;
        if (u.indexOf(":" + name) !== -1) {
            const { [name]: value, ...remaining } = r;
            const url = u.replace(":" + name, value);
            return [url, remaining]
        } else {
            return [u, r]
        }
    }, [urlPattern, params])


    const search = queryString.stringifyUrl({
        url: pathname, query: remaining
    }).replace(pathname, "");

    const page = {
        compiled: compiledStr,
        // fragments: getDependedFragments({ externalNames }, fragments)
        styles,
    };

    const { userenv } = dlc;
    const domainEnv = { 
        protocol: "https",
        mobileHost: userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        tenantCode: dlc.tenantCode,
    }
    // const { pathname, search } = history.location;

    const currentURL = pathname + search; // hash 不支持
    const currentURLPath = pathname;
    const webEnv = {
        protocol: "https", 
        host: userenv.mobileHosts[0], 
        isSandbox: dlc.sandbox,
        isIOS: isIOS(), // userenv.isIOS,
        isWeChat: isWeChat(), // userenv.isWeChat,
        isWxWork: isWxWork(), //userenv.isWxWork,
        isMobile: isMobile(), // userenv.isMobile,
        currentURL,
        currentURLPath,
        originalURL: userenv.originalURL,
        tenantCode: dlc.tenantCode,     
    }


    useEffect(() => {
        if(typeof(document) !== 'undefined') {
            document.body.className = "preview-page";
        }

    }, [])

    const viewEnv = { routeTo: route_to, webEnv, domainEnv };

    const envKey = hashCode(compiledStr);

    return (
        <QueryTargetContextProvider queryTarget="data">
            <Helmet>
                <title>{`[页面] ${runtimeProfile.dataName || "<测试页面>"}`}</title>
            </Helmet>
            <ErrorBoundary envKey={envKey}>
                <ExecutionContextProvider designMode={true} routeTo={route_to}>
                    <DataLoaderProvider {...dlc}>
                        <DataPromiseProvider holder={promiseHolder}>
                            <Suspense fallback={null}>
                                <PageEnvContainer {...{
                                    page, params,
                                    facade,
                                    route_to, pathname, search, dlc,
                                    isPreview: true,
                                    viewEnv,                                    
                                }} />
                            </Suspense>
                        </DataPromiseProvider>
                    </DataLoaderProvider>
                </ExecutionContextProvider>
            </ErrorBoundary>
        </QueryTargetContextProvider>
    )


}