import React, { useState, useContext, useEffect } from 'react'
import { Dropdown } from 'antd'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import useDefinitions from 'bwax-ui/legacy/page/hooks/useDefinitions'
import MentionSuggestion from './MentionSuggestion'

export default function MentionSuggestionsPortal(props) {

    const { store, mentionTrigger, offsetKey } = props

    const { allEntities, allDataTypes } = useDefinitions()
    const { tenantCode, endpoints, sessionToken, sandbox } = useContext(DataLoaderContext)

    const [suggestionVisible, setSuggestionVisible] = useState(true)

    const onSuggestionVisibleChange = (visible) => {
        setSuggestionVisible(visible)
    }

    // if(!allEntities || !allDataTypes) {
    //     return <span data-offset-key={offsetKey}>{props.children}</span>
    // }

    return (
        <span data-offset-key={offsetKey}>
            <Dropdown
                contentEditable={false}
                overlay={
                    <MentionSuggestion
                        store={store}
                        mentionTrigger={mentionTrigger}
                        offsetKey={offsetKey}
                        allEntities={allEntities}
                        allDataTypes={allDataTypes}
                        tenantCode={tenantCode}
                        sessionToken={sessionToken}
                        sandbox={sandbox}
                        suggestionVisible={suggestionVisible}
                        onSuggestionVisibleChange={onSuggestionVisibleChange} />
                }
                trigger={[]}
                getPopupContainer={triggerNode => triggerNode ? triggerNode.parentNode : document.body}
                visible={suggestionVisible}>
                <span data-offset-key={offsetKey}>{props.children}</span>
            </Dropdown>
        </span>
    )
}