
import React, { useState, useEffect, useRef } from 'react'

import InputSelect from 'Client/js/ui/components/InputSelect'
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import ReactDOM from 'react-dom'
import isEqual from 'lodash/isEqual'


const NONE = "__none__"
const NULL = "（空）"
const NOTNULL = "（非空）"

export default function RecordSelectSearch(props) {

    const {
        params,                                     /// used to control the input
        value: passedInValue,
        onChange,
        error,
        disabled,           /// input control props
        allEntities,
        allDataTypes,
        evaluate,

    } = props

    const {
        entityName,
        pageSize = 10,
        fieldsToSearch: passedInFieldsToSearch,
        fieldsToReturn = {},
        shouldDisplayEmptyOptions = false,

        required,
        multivalued,
        itemInList,
        fixedValue,
        /// 
        fixedCriteria,

    } = params;


    function preProcess(raw) {
        if (typeof (raw) === 'string') {
            return { id: raw }
        } else {
            return raw
        }
    }
    const value = Array.isArray(passedInValue) ?
        passedInValue.map(preProcess) : preProcess(passedInValue)

    const entity = allEntities.find(e => e.name === entityName)
    /// TODO check entity
    //// initialize the paramemeters
    const fieldsTypesToSearch = ['ShortText', 'Text']
    let fieldsToSearch = passedInFieldsToSearch || [...entity.fields, ...entity.virtualFields].filter(
        f => f.filterable
    ).filter(
        f => !f.multivalued && fieldsTypesToSearch.indexOf(f.type) !== -1
    ).map(f => f.name)
    /// initialization ends

    if(entity.name == "用户") {
        fieldsToSearch = [...fieldsToSearch, "手机号用户.手机号"]
    }


    const buildSearchDef = setUpSearchDefBuilder(
        entity, {
        allEntities, allDataTypes,
        fieldsToSearch, fieldsToReturn,
        fixedCriteria
    })
    const buildFindDef = setUpFindByIdListDefBuilder(
        entity, {
        allEntities, allDataTypes, fieldsToReturn
    })

    /// states start >>>>>
    const [options, setOptions] = useState([])
    const [fetching, setFetching] = useState(false)
    const [keyword, setKeyword] = useState(null)
    const [pageInfo, setPageInfo] = useState({})
    /// states end >>>>>>>

    const debouncedKeyword = useDebounce(keyword, 360)

    const currentQueryIdList = useRef([])
    const currentQueryId = useRef(null)
    const lastScrollTop = useRef(null)
    const hadSelected = useRef(false)

    useEffect(() => {
        //初始化的 id/idList 需要进行查询
        if (keyword === null && hadSelected.current === false) {
            if (Array.isArray(value)) {
                const idList = value.reduce((acc, current) => {
                    if (current && current.id) {
                        return [...acc, current.id]
                    } else {
                        return acc
                    }
                }, [])
                if (!isEqual(idList, currentQueryIdList.current)) {
                    currentQueryIdList.current = idList
                    findByIdList(idList)
                }
            } else if (value && value.id && !(value.id == NULL || value.id == NOTNULL)) {
                if (!isEqual(value.id, currentQueryId.current)) {
                    currentQueryId.current = value.id
                    findByIdList([value.id])
                }
            } else {
                //do nothing
            }
        }
    }, [value])

    useEffect(() => {
        if (debouncedKeyword !== null) {
            handleSearch(debouncedKeyword)
        }
    }, [debouncedKeyword])

    async function findByIdList(idList) {
        setFetching(true)
        const targetDef = buildFindDef()

        const result = await evaluate(targetDef, { idList })

        ReactDOM.unstable_batchedUpdates(() => {
            setFetching(false)
            setOptions(result || [])
        })
    }

    async function handleFocus() {
        setKeyword("")
        // handleSearch("")
    }

    const prepareValue = prepareValueToDisplay({ multivalued, entity, options, required, allEntities, allDataTypes })

    async function handleSearch(keyword, size = pageSize) {

        const targetDef = buildSearchDef(keyword, size)
        setFetching(true)
        const { result, data } = await evaluate(targetDef, {})

        // 应该保证当前选项永远在里面:
        const options = result || []
        const valuePrepared = multivalued ? prepareValue(value) : [prepareValue(value)]

        const allOptions = [
            ...options,
            ...valuePrepared.filter(vp => !options.some(o => o.value === vp.value))
        ]

        ReactDOM.unstable_batchedUpdates(() => {
            setFetching(false)
            setOptions(allOptions)
            setPageInfo(data ? data.pageInfo : {})
        })
    }

    async function handleChange(value) {

        const getRemainReturnFields = (recordId) => {
            const option = options.find(o => o.value === recordId)
            const remainReturnFields = Object.keys(fieldsToReturn || {}).reduce((acc, fieldKey) => {
                if (option && option[fieldKey]) {
                    acc[fieldKey] = option[fieldKey]
                }
                return acc
            }, {})
            return remainReturnFields
        }

        const doChange = v => {
            onChange && onChange(v);
        }

        if (multivalued) {
            doChange(
                value.map(n => ({
                    id: n,
                    ...getRemainReturnFields(n),
                }))
            )
        } else {
            if (value === NONE || value === null) {
                doChange(null)
            } else {
                doChange({
                    id: value,
                    ...getRemainReturnFields(value),
                })
            }

        }
    }

    const emptyOptions = [{
        value: NULL,
        label: NULL
    }, {
        value: NOTNULL,
        label: NOTNULL
    }]


    const selectOptions = multivalued ? options : [
        ...(shouldDisplayEmptyOptions ? emptyOptions : []),
        ...options
    ].filter(o => !!o.value)

    function onMenuScrollToBottom() {

        const { hasNextPage, endCursor } = pageInfo
        if (
            hasNextPage && !fetching
        ) {
            const count = parseInt(endCursor)
            handleSearch(keyword || "", count + pageSize)
        }

    }

    // TODO duplicate:
    const mockField = { type: "Link", options: { entity: entity.key } }
    const fieldDisplay = getFieldDisplay(mockField);

    function renderValue(valueToUse) {
        if (valueToUse) {
            const Comp = fieldDisplay.component;
            return (
                <Comp
                    {...{
                        value: valueToUse, customParams: { withLink: false }, field: mockField,
                        env: { allEntities, allDataTypes }
                    }}
                />
            )
        } else {
            return null
        }
    }

    return (
        <InputSelect style={{ width: "100%" }} {...{
            isMulti: !!multivalued,
            isDisabled: fixedValue !== undefined || disabled,
            isSearchable: true,
            isClearable: !required,

            options: selectOptions.map(option => {
                const { value, label, valueToUse, ...otherProps } = option
                return {
                    value,
                    label: label ? label : renderValue(valueToUse),
                    ...otherProps,
                }
            }),

            onSearch: v => setKeyword(v),
            onFocus: handleFocus,
            value: prepareValue(value),
            onChange: handleChange,

            onSelect: v => {
                hadSelected.current = true
                if (v.value === NONE) {
                    setKeyword("")
                    handleSearch("")
                }
            },

            onMenuScrollToBottom,
            isLoading: fetching,

            isOptionDisabled: (option) => {
                // use itemInList?
                // itemInList 这个名字要换掉

                return itemInList ? itemInList(option) : false;
            
            },



        }} />
    )

}


function prepareValueToDisplay({ multivalued, entity, options, required, allEntities, allDataTypes }) {

    const emptyOptions = [{
        value: NULL,
        label: NULL
    }, {
        value: NOTNULL,
        label: NOTNULL
    }]

    const mockField = { type: "Link", options: { entity: entity.key } }
    const fieldDisplay = getFieldDisplay(mockField);

    function renderValue(valueToUse) {
        if (valueToUse) {
            const Comp = fieldDisplay.component;
            return (
                <Comp {...{
                    value: valueToUse, customParams: { withLink: false }, field: mockField,
                    env: { allEntities, allDataTypes }
                }} />
            );
        } else {
            return null
        }
    }

    return (v => {
        if (multivalued) {
            return (v || []).map(n => {
                const o = options.find(o => o.value === n.id)
                if (o) {
                    const { label, valueToUse } = o
                    return {
                        value: n.id,
                        label: label || renderValue(valueToUse)
                    }
                } else {
                    return {
                        value: n.id,
                    }
                }
            })
        } else {
            const emptyValue = { value: '', label: null };
            if (!v) {
                return emptyValue
            }
            const { id } = v;
            const o = [...options, ...emptyOptions].find(o => o.value === id)

            if (!o) {
                return emptyValue
            }

            const { value, label, valueToUse } = o

            return {
                value,
                label: label || renderValue(valueToUse)
            }
        }
    })
}


export function setUpSearchDefBuilder(entity, {
    allEntities, allDataTypes,
    fieldsToSearch,
    fieldsToReturn = {},
    fixedCriteria = {},
    sortBy
}) {

    return (keyword, pageSize) => {
        return {
            __query__: {
                data: {
                    type: "listAll",
                    entityName: entity.name,
                    pagination: {
                        pageSize,
                    },
                    criteria: (
                        fieldsToSearch && fieldsToSearch.length > 0 ?
                            (
                                fieldsToSearch.map(fieldName => ([
                                    fixedCriteria,
                                    {
                                        [fieldName]: { like: `%${keyword}%` }
                                    }
                                ]))
                            ) :
                            fixedCriteria

                    ),
                    sortBy: sortBy || {
                        创建时间: "DESC"
                    }
                },
                __for__: {
                    data: `\${data}`,
                    result: {
                        __apply__: {
                            list: `\${data}`,
                            map: {
                                value: `\${value.id}`,
                                valueToUse: (() => {
                                    const mockField = { type: "Link", options: { entity: entity.key } }
                                    const fieldDisplay = getFieldDisplay(mockField);
                                    return fieldDisplay.valueDef("value", mockField, { allEntities, allDataTypes })
                                })(),

                                ...fieldsToReturn
                            }
                        }
                    }
                }
            }
        }
    }
}

function setUpFindByIdListDefBuilder(entity, {
    allEntities, allDataTypes,
    fieldsToReturn = {}
}) {

    return () => {
        return {
            __query__: {
                data: {
                    type: "listAll",
                    entityName: entity.name,
                    criteria: {
                        __apply__: {
                            list: "${idList}",
                            map: [
                                {
                                    id: "${value}"
                                }
                            ]
                        }
                    }
                },
                __for__: {
                    __apply__: {
                        list: `\${data}`,
                        map: {
                            value: `\${value.id}`,
                            // display: buildRecordSummary(
                            //     'value', entity, false,
                            //     { allDataTypes, allEntities, withLink: false }
                            // ),
                            valueToUse: (() => {
                                const mockField = { type: "Link", options: { entity: entity.key } }
                                const fieldDisplay = getFieldDisplay(mockField);
                                return fieldDisplay.valueDef("value", mockField, { allEntities, allDataTypes })
                            })(),
                            ...fieldsToReturn
                        }
                    }
                }
            }
        }
    }
}