
import React, { useEffect, useState } from 'react'

import Logo from "./components/ProjectLogo"
import Avatar from "bwax-ui/components/Avatar";
import RichText from "bwax-ui/basic/RichText";

import Button from "bwax-ui/components/Button";

import TextArea from "bwax-ui/components/inputs/TextArea";

import { getStatusColor } from './PipelineProjectList_cb';

import numeral from 'numeral';

import { getFileIcon, getFileType } from "bwax-ui/components/FileIcon";

import { toBase64URI } from 'bwax/ml/lang/mod/builtin/StringHelper';
import { downloadURL } from 'bwax-ui/ml/FrontEndHelper';
import dayjs from 'dayjs';

import { toast } from 'bwax-ui/components/Toast';

export default function PipelineProjectDetail_cb({ data, viewEnv, facade }) {

    const { project: p, currentUserId, currentPLN, currentMemberId } = data;

    const { routeTo } = viewEnv;

    const [totalPLN, setTotalPLN] = useState(p.获得的支持);

    const [documents, setDocuments] = useState([p.bp]);

    const [votes, setVotes] = useState([]);

    const upVotes = votes.filter(v => v.是否支持);
    const downVotes = votes.filter(v => !v.是否支持);

    async function loadDocuments() {
        const [result, error] = await facade.list({
            entityName: "预备项目文档",
            condition: [[{ field: "项目.id", op: "eq", value: p.id }]],
            fieldPaths: ["文件"]
        });
        if (result) {
            setDocuments(prev => ([...prev, ...result.map(r => r.文件)]))
        }
    }

    const voteFieldPaths = ["pln", "是否支持", "会员.姓名", "会员.脱敏名称", "创建者.头像", "创建者.id", "内容", "修改时间"];

    async function loadVotes() {
        const [result, error] = await facade.list({
            entityName: "项目初筛意见",
            condition: [[{ field: "项目.id", op: "eq", value: p.id }]],
            fieldPaths: voteFieldPaths,
            sort: [{ field: "修改时间", order: "DESC" }]
        });
        if (result) {
            setVotes(result);
        }
    }


    useEffect(() => {
        loadDocuments();
        loadVotes();
    }, []);



    function renderSectionTitle(title) {
        return (
            <h2 className="font-medium font-size-16 text-[var(--sand12)]">{title}</h2>
        )
    }


    function renderSection(title, content) {
        return (
            <div className="flex flex-col gap-3">
                {renderSectionTitle(title)}
                {content}
            </div>
        )
    }

    function renderDocuments() {
        return renderSection("项目文档", (
            <div className="flex flex-col gap-2 py-2">
                {documents.map((f, index) => {
                    return (
                        <div key={index + " " + f.title}
                            className="flex gap-3 items-start cursor-pointer hover:underline px-2 py-1"
                            onClick={_ => {
                                const fileType = getFileType(f);
                                if (fileType == "pdf") {
                                    const pdfUrl = "/pdf/" + toBase64URI(JSON.stringify(f));
                                    routeTo(pdfUrl);
                                } else {
                                    downloadURL(f.url);
                                }
                            }}>
                            <div className="mt-1">{getFileIcon(f)}</div>
                            <div className="grow">{f.title}</div>
                        </div>
                    )
                })}
            </div>
        ))
    }


    const myVote = votes.find(v => v.创建者.id == currentUserId);

    const [content, setContent] = useState(myVote ? myVote.内容 : "");

    const isVoting = p.状态.indexOf("筛选中") != -1;

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (isEditing) {
            setContent(myVote ? myVote.内容 : "")
        }
    }, [isEditing]);


    const [isSubmitting, setIsSubmitting] = useState(false)
    async function sendVote(supporting) {
        setIsSubmitting(true)
        if (!myVote) {
            const [r, e] = await facade.add({
                entityName: "项目初筛意见",
                formData: {
                    是否支持: supporting,
                    内容: content,
                    pln: currentPLN,
                    项目: p.id,
                    会员: currentMemberId
                },
                fieldPaths: voteFieldPaths,
            })
            if (r) {
                setVotes(prev => ([r, ...prev]))
                toast({ title: "意见已提交" })
            }
        } else {
            const [r, e] = await facade.update({
                entityName: "项目初筛意见",
                id: myVote.id,
                formData: {
                    是否支持: supporting,
                    内容: content,
                    pln: currentPLN,
                },
                fieldPaths: voteFieldPaths,
            })
            if (r) {
                setVotes(prev => ([r, ...prev.filter(p => p.id !== myVote.id)]))
                toast({ title: "意见已提交" })
                setIsEditing(false)                
            }
        }

        const [r, e] = await facade.customMutation({
            entityName: "预备项目",
            interfaceName: "更新",
            id: p.id,
            outputFieldPaths: [
                ["预备项目", ["获得的支持"]]
            ]
        })
        if(r) {
            setTotalPLN(r.获得的支持);
        }

        setIsSubmitting(false)
    }


    function renderVote(v, showStatus = false) {
        return (
            <div className={"flex flex-row gap-3 py-2 px-2 sm:pr-4"} key={v.id}>
                <Avatar nickName={v.会员.姓名} avatar={v.创建者.头像} size={32} />
                <div className="flex flex-col gap-2 grow">
                    <div className="flex justify-between gap-4">
                        <div className="text-[13px] flex gap-3">
                            <div> {v.会员.脱敏名称}</div>
                            <div className="text-[var(--gray11)]">{dayjs(v.修改时间).format("YY-MM-DD")}</div>
                        </div>
                        <div className="text-[var(--color-10)] text-[14px]" data-color={v.是否支持 ? "orange" : "sand"}>
                            <span className="text-[12px] mr-2">PLN</span>{numeral(v.pln).format("0.0a")}
                        </div>
                    </div>
                    <div className="font-size-15">
                        {v.内容}
                    </div>
                </div>
            </div>
        )
    }

    function renderMyOpinion() {

        if (myVote && !isEditing) {
            return renderSection("我的意见", (
                <div className="flex flex-col gap-3 py-2 pb-6">
                    {renderVote(myVote)}
                    { isVoting ? (
                        <div className="ml-[50px] font-size-13 flex gap-1 items-center hover:text-underline cursor-pointer" onClick={_ => setIsEditing(true)}>
                            <i className="bx bx-edit font-size-16" /><div>修改内容</div>
                        </div>
                    ) : null }
                </div>
            ))
        } else {
            if(!isVoting) return null;
            return renderSection("发表意见", (
                <div className={"flex flex-col gap-5 max-w-lg py-3 pb-6"}>
                    <TextArea color={"indigo"} className="font-size-14 !rounded !px-3 !py-2" styled={true} placeholder="您是否支持这个项目？请告诉我们您的意见"
                        value={content} onChange={setContent}
                    />
                    <div className="flex gap-3">
                        { myVote ? <Button className="grow" size="large" appearance="outline" onPress={_ => setIsEditing(false)}>取消修改</Button> : null }
                        <Button className="grow" size="large" isDisabled={!content || !isVoting} isLoading={isSubmitting} color="sand" onPress={_ => sendVote(false)}>不支持</Button>
                        <Button className="grow" size="large" isDisabled={!content || !isVoting} isLoading={isSubmitting} color="orange" onPress={_ => sendVote(true)}>支持立项</Button>
                    </div>
                </div>
            ))
        }



    }


    function renderVotes(title, theVotes, color) {
        if (theVotes.length == 0) {
            return renderSection((
                <div className="flex gap-2 items-center" data-color={color}>
                    <div>{ title }</div>
                    {/* <div className="text-[13px] font-normal px-3">{theVotes.length} 人, PLN 共 <span className="">{numeral(plnSum).format("0.0a")}</span></div> */}
                </div>
                
            ), (
                <div className="flex flex-col gap-2 py-8 items-center">
                    <div><i className='bx bx-folder-open text-[var(--gray8)] text-[32px]'></i></div>
                    <div className="text-[var(--gray10)] text-[14px]">还没有人{title}</div>
                </div>
            ))
        }

        const plnSum = theVotes.map(v => v.pln).reduce((a, b) => a + b, 0);

        return renderSection((
            <div className="flex gap-2 items-center" data-color={color}>
                <div>{ title }</div>
                <div className="text-[12px] font-normal px-3 text-[var(--gray11)]">{theVotes.length} 人, PLN 共 <span className="">{numeral(plnSum).format("0.0a")}</span></div>
            </div>
            
        ), (
            <div className="flex flex-col gap-2 py-2">
                { theVotes.map(renderVote)}
            </div>
        ))
    }


    function renderUpVotes() {
        return renderVotes("支持立项", upVotes, "orange");
    }

    function renderDownVotes() {
        return renderVotes("不支持立项", downVotes, "sand");
    }


    return (
        <div className="w-full max-w-3xl self-center gap-12 flex flex-col px-6 py-8">
            <div className="flex gap-6 flex-col ">
                {p.logo ? (
                    <Logo logo={p.logo} name={p.名称} size={72}></Logo>
                ) : null}
                <div className="flex gap-4 flex-col sm:flex-row">
                    <div className="grow leading-relaxed flex flex-col gap-2 py-2">
                        <h1 className="font-medium font-size-20">{p.名称}</h1>
                        {
                            p.简介 ? (
                                <>
                                    <div className="text-[var(--color-11)] font-size-15">{p.简介}</div>
                                </>
                            ) : null
                        }
                        <div className="flex flex-wrap gap-2.5 text-[var(--color-10)] font-size-13 py-2">
                            <div className="flex gap-1 items-center">
                                <Avatar nickName={p.上传者.姓名} avatar={p.上传者.用户.头像} size={22}></Avatar>
                                <div>{p.上传者.姓名}</div>
                            </div>
                            {(p.标签 || []).map(t => {
                                return (
                                    <div className="rounded bg-[var(--color-2)] px-2" key={t}>
                                        {t}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="rounded-md flex flex-col px-8 sm:self-start sm:mt-4 items-center border-[1px] pt-4 pb-5 !border-[var(--color-5)] " data-color={getStatusColor(p.状态)} >
                        <div className="font-medium text-[var(--color-10)] mr-1 font-size-28 whitespace-nowrap">
                            <span className="font-size-16 mr-2">PLN</span>{totalPLN ? numeral(totalPLN).format("0.0a") : "--"}
                        </div>
                        <div className="text-[var(--color-10)] font-size-12">
                            获得的支持
                        </div>
                    </div>
                </div>
            </div>
            {p.详情 ? (
                <div className="flex flex-col">
                    {renderSectionTitle("项目介绍")}
                    <RichText className="leading-[1.75] py-4 text-[1rem]" params={{ value: p.详情 }} />
                </div>
            ) : null}
            {renderDocuments()}
            {renderMyOpinion()}
            {renderUpVotes()}
            {renderDownVotes()}
        </div>
    )
}
