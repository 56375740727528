
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

export default createBlockTypeButton({
  blockType: 'ordered-list-item',
  children: (
    <i className={"fa fa-list-ol"} aria-hidden="true"></i>
  ),
});
