
import React from 'react';

import { Modal as AntModal } from 'antd'

import './Modal.less';

export default function Modal (props) {

    const { 
        title, size = "normal", visible, footer, onCancel, children, maskClosable, 
        destroyOnClose = false,
        style,
        className,
    } = props;

    /**
     * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1071
     * 使用 destroyOnClose 是让 Modal 在重新打开时能够滚动到最上方 （by 威豪 2022-11-03）
     */

    return (
        <AntModal
            title={title}
            className={"admin--edit-modal bw-admin-modal" + (className ? " " + className : "")}
            wrapClassName={"bw-ui lc-base"}
            open={visible}
            maskClosable={maskClosable}
            style={{
                minWidth: size == "wide" ? "60rem": "40rem",
                ...style
            }}
            bodyStyle={{
                padding: 0,
                maxHeight: "70vh",
                overflow: "auto"
            }}
            footer={footer}
            onCancel={onCancel}            
            destroyOnClose={destroyOnClose}
        >
            { children }
        </AntModal>
    )
}

Modal.confirm = AntModal.confirm;
