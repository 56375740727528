// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var JsonUtils$BwaxAdmin = require("../../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var React$1 = require("@monaco-editor/react");

function Input_JSONCode(Props) {
  Props.params;
  var value = Props.value;
  var onChange = Props.onChange;
  var editor = React.useRef(undefined);
  var match = React.useState((function () {
          return JSON.stringify(value, null, 2);
        }));
  var editorDidMount = function (param, e) {
    editor.current = Caml_option.some(e);
    return e.onDidChangeModelContent((function (param) {
                  var value = e.getValue();
                  var match = JsonUtils$BwaxAdmin.parse(value);
                  if (match !== undefined) {
                    return Curry._1(onChange, Caml_option.valFromOption(match));
                  } else {
                    return /* () */0;
                  }
                }));
  };
  return React.createElement(React$1.default, {
              value: match[0],
              theme: "vs",
              language: "json",
              height: "40rem",
              editorDidMount: editorDidMount,
              options: {
                fontSize: 12,
                autoIndent: "brackets"
              }
            });
}

var make = Input_JSONCode;

exports.make = make;
/* react Not a pure module */
