// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");

function rename(dict, _t) {
  while(true) {
    var t = _t;
    var do_rename = function (param) {
      return rename(dict, param);
    };
    if (typeof t === "number") {
      if (t === /* No_term */0) {
        return /* No_term */0;
      } else {
        return Lang_typing_base$BwaxAdmin.unexpect("Never instance an unknown");
      }
    } else {
      switch (t.tag | 0) {
        case /* Term */0 :
            return /* Term */Caml_chrome_debugger.variant("Term", 0, [
                      t[0],
                      Plate$BwaxAdmin.List.map(do_rename, t[1])
                    ]);
        case /* Term_record */1 :
            return /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [Plate$BwaxAdmin.List.map((function (param) {
                              return /* tuple */[
                                      param[0],
                                      rename(dict, param[1]),
                                      param[2]
                                    ];
                            }), t[0])]);
        case /* Var */2 :
            var match = t[0];
            var n = match[/* n */1];
            var var_type = match[/* var_type */0];
            var t$1 = match[/* t */2];
            if (typeof t$1 === "number") {
              if (t$1 !== 0) {
                var match$1 = Plate$BwaxAdmin.List.assoc(n, dict);
                if (match$1 !== undefined) {
                  return match$1;
                } else {
                  var new_var_type;
                  if (typeof var_type === "number") {
                    new_var_type = var_type;
                  } else {
                    switch (var_type.tag | 0) {
                      case /* Recordvar */0 :
                          var nal = Plate$BwaxAdmin.List.assoc_map(do_rename, var_type[0]);
                          new_var_type = /* Recordvar */Caml_chrome_debugger.variant("Recordvar", 0, [nal]);
                          break;
                      case /* Record_writeonly */4 :
                          new_var_type = /* Record_writeonly */Caml_chrome_debugger.variant("Record_writeonly", 4, [
                              var_type[0],
                              Plate$BwaxAdmin.List.map(do_rename, var_type[1])
                            ]);
                          break;
                      case /* Record_writeonly_of */5 :
                          new_var_type = /* Record_writeonly_of */Caml_chrome_debugger.variant("Record_writeonly_of", 5, [Plate$BwaxAdmin.List.map((function (param) {
                                      return /* tuple */[
                                              param[0],
                                              rename(dict, param[1]),
                                              param[2]
                                            ];
                                    }), var_type[0])]);
                          break;
                      case /* Any_record_readonly */6 :
                          new_var_type = /* Any_record_readonly */Caml_chrome_debugger.variant("Any_record_readonly", 6, [
                              rename(dict, var_type[0]),
                              Plate$BwaxAdmin.List.assoc_map(do_rename, var_type[1])
                            ]);
                          break;
                      case /* Record_writeonly_optional */7 :
                          new_var_type = /* Record_writeonly_optional */Caml_chrome_debugger.variant("Record_writeonly_optional", 7, [
                              var_type[0],
                              Plate$BwaxAdmin.List.map(do_rename, var_type[1])
                            ]);
                          break;
                      default:
                        new_var_type = var_type;
                    }
                  }
                  return /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
                                "var_type",
                                "n",
                                "t"
                              ], [
                                new_var_type,
                                n,
                                1
                              ])]);
                }
              } else {
                _t = t$1;
                continue ;
              }
            } else {
              _t = t$1;
              continue ;
            }
        
      }
    }
  };
}

function specialize(next_int, acc_dict, $$var) {
  var n = $$var[/* n */1];
  var t = $$var[/* t */2];
  var match = Plate$BwaxAdmin.List.assoc(n, acc_dict);
  if (match !== undefined) {
    return /* tuple */[
            acc_dict,
            match
          ];
  } else {
    var match$1 = inst_var_type(next_int, acc_dict, $$var[/* var_type */0]);
    var nt = /* Var */Caml_chrome_debugger.variant("Var", 2, [/* record */Caml_chrome_debugger.record([
            "var_type",
            "n",
            "t"
          ], [
            match$1[1],
            Curry._1(next_int, /* () */0),
            t
          ])]);
    return /* tuple */[
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  n,
                  nt
                ],
                match$1[0]
              ]),
            nt
          ];
  }
}

function inst_term(next_int, dict, _t) {
  while(true) {
    var t = _t;
    if (typeof t === "number") {
      if (t === /* No_term */0) {
        return /* tuple */[
                dict,
                /* No_term */0
              ];
      } else {
        return Lang_typing_base$BwaxAdmin.unexpect("Never instance an unknown");
      }
    } else {
      switch (t.tag | 0) {
        case /* Term */0 :
            var match = Plate$BwaxAdmin.List.foldl((function (param, t) {
                    var match = inst_term(next_int, param[0], t);
                    return /* tuple */[
                            match[0],
                            Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                    match[1],
                                    /* [] */0
                                  ]))
                          ];
                  }), /* tuple */[
                  dict,
                  /* [] */0
                ], t[1]);
            return /* tuple */[
                    match[0],
                    /* Term */Caml_chrome_debugger.variant("Term", 0, [
                        t[0],
                        match[1]
                      ])
                  ];
        case /* Term_record */1 :
            var match$1 = inst_term_fold_assoc_tri(next_int, dict, t[0]);
            return /* tuple */[
                    match$1[0],
                    /* Term_record */Caml_chrome_debugger.variant("Term_record", 1, [match$1[1]])
                  ];
        case /* Var */2 :
            var vt = t[0];
            var t$1 = vt[/* t */2];
            if (typeof t$1 === "number") {
              if (t$1 !== 0) {
                return specialize(next_int, dict, vt);
              } else {
                _t = t$1;
                continue ;
              }
            } else {
              _t = t$1;
              continue ;
            }
        
      }
    }
  };
}

function inst_var_type(next_int, dict, vt) {
  if (typeof vt === "number") {
    return /* tuple */[
            dict,
            vt
          ];
  } else {
    switch (vt.tag | 0) {
      case /* Recordvar */0 :
          var match = inst_term_fold_assoc(next_int, dict, vt[0]);
          return /* tuple */[
                  match[0],
                  /* Recordvar */Caml_chrome_debugger.variant("Recordvar", 0, [match[1]])
                ];
      case /* Record_readonly */1 :
          var match$1 = inst_term_fold_assoc(next_int, dict, vt[1]);
          return /* tuple */[
                  match$1[0],
                  /* Record_readonly */Caml_chrome_debugger.variant("Record_readonly", 1, [
                      vt[0],
                      match$1[1]
                    ])
                ];
      case /* Record_readonly_of */2 :
          var match$2 = inst_term_fold_assoc_tri(next_int, dict, vt[0]);
          var match$3 = inst_term_fold_assoc(next_int, match$2[0], vt[1]);
          return /* tuple */[
                  match$3[0],
                  /* Record_readonly_of */Caml_chrome_debugger.variant("Record_readonly_of", 2, [
                      match$2[1],
                      match$3[1]
                    ])
                ];
      case /* Record_limited */3 :
          var match$4 = inst_term_fold(next_int, dict, vt[0]);
          return /* tuple */[
                  match$4[0],
                  /* Record_limited */Caml_chrome_debugger.variant("Record_limited", 3, [match$4[1]])
                ];
      case /* Record_writeonly */4 :
          var match$5 = inst_term_fold(next_int, dict, vt[1]);
          return /* tuple */[
                  match$5[0],
                  /* Record_writeonly */Caml_chrome_debugger.variant("Record_writeonly", 4, [
                      vt[0],
                      match$5[1]
                    ])
                ];
      case /* Record_writeonly_of */5 :
          var match$6 = inst_term_fold_assoc_tri(next_int, dict, vt[0]);
          return /* tuple */[
                  match$6[0],
                  /* Record_writeonly_of */Caml_chrome_debugger.variant("Record_writeonly_of", 5, [match$6[1]])
                ];
      case /* Any_record_readonly */6 :
          var match$7 = inst_term_fold_assoc(next_int, dict, vt[1]);
          var match$8 = inst_term(next_int, match$7[0], vt[0]);
          return /* tuple */[
                  match$8[0],
                  /* Any_record_readonly */Caml_chrome_debugger.variant("Any_record_readonly", 6, [
                      match$8[1],
                      match$7[1]
                    ])
                ];
      case /* Record_writeonly_optional */7 :
          var match$9 = inst_term_fold(next_int, dict, vt[1]);
          return /* tuple */[
                  match$9[0],
                  /* Record_writeonly_optional */Caml_chrome_debugger.variant("Record_writeonly_optional", 7, [
                      vt[0],
                      match$9[1]
                    ])
                ];
      default:
        return /* tuple */[
                dict,
                vt
              ];
    }
  }
}

function inst_term_fold(next_int, dict, l) {
  return Plate$BwaxAdmin.List.foldl((function (param, t) {
                var match = inst_term(next_int, param[0], t);
                return /* tuple */[
                        match[0],
                        Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                match[1],
                                /* [] */0
                              ]))
                      ];
              }), /* tuple */[
              dict,
              /* [] */0
            ], l);
}

function inst_term_fold_assoc_tri(next_int, dict, al) {
  return Plate$BwaxAdmin.List.foldl((function (param, param$1) {
                var match = inst_term(next_int, param[0], param$1[1]);
                return /* tuple */[
                        match[0],
                        Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  param$1[0],
                                  match[1],
                                  param$1[2]
                                ],
                                /* [] */0
                              ]))
                      ];
              }), /* tuple */[
              dict,
              /* [] */0
            ], al);
}

function inst_term_fold_assoc(next_int, dict, al) {
  return Plate$BwaxAdmin.List.foldl((function (param, param$1) {
                var match = inst_term(next_int, param[0], param$1[1]);
                return /* tuple */[
                        match[0],
                        Pervasives.$at(param[1], /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                /* tuple */[
                                  param$1[0],
                                  match[1]
                                ],
                                /* [] */0
                              ]))
                      ];
              }), /* tuple */[
              dict,
              /* [] */0
            ], al);
}

function instance(next_int, param) {
  var gvars = param[0];
  if (gvars) {
    var dict = Plate$BwaxAdmin.List.foldl((function (d, v) {
            return specialize(next_int, d, v)[0];
          }), /* [] */0, gvars);
    return rename(dict, param[1]);
  } else {
    return param[1];
  }
}

function instance_with_venv(next_int, svars, param) {
  var gvars = param[0];
  if (gvars) {
    var base_dict = Plate$BwaxAdmin.List.map((function (param) {
            return /* tuple */[
                    param[0][/* n */1],
                    param[1]
                  ];
          }), Plate$BwaxAdmin.List.combine(gvars, svars));
    var dict = Plate$BwaxAdmin.List.foldl((function (d, v) {
            return specialize(next_int, d, v)[0];
          }), base_dict, gvars);
    return rename(dict, param[1]);
  } else {
    return param[1];
  }
}

function resolve_alias(next_int, dts, stop_case, _t) {
  while(true) {
    var t = _t;
    if (typeof t === "number" || t.tag) {
      return t;
    } else {
      var name = t[0];
      var match = Plate$BwaxAdmin.List.assoc(name, dts);
      if (match !== undefined) {
        var match$1 = match;
        if (typeof match$1 === "number" || !(match$1.tag === /* Alias */1 && !Curry._1(stop_case, name))) {
          return t;
        } else {
          var inst = instance_with_venv(next_int, t[1], match$1[0]);
          _t = inst;
          continue ;
        }
      } else {
        return t;
      }
    }
  };
}

exports.rename = rename;
exports.inst_term_fold = inst_term_fold;
exports.inst_term_fold_assoc = inst_term_fold_assoc;
exports.inst_term_fold_assoc_tri = inst_term_fold_assoc_tri;
exports.inst_term = inst_term;
exports.inst_var_type = inst_var_type;
exports.specialize = specialize;
exports.instance = instance;
exports.instance_with_venv = instance_with_venv;
exports.resolve_alias = resolve_alias;
/* Plate-BwaxAdmin Not a pure module */
