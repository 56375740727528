

import { buildAddRecordMutation } from 'bwax/query/QueryBuilder'

import { invalidateCache } from 'bwax-ui/legacy/store/useDataLoader'

export default function AddRecord ({allEntities, allDataTypes, queryRunner }) {

    return async function (params) {

        const { formData, entityName } = params

        const entity = allEntities.find(e => e.name === entityName)

        const { queryText, buildInput, extractResult } = buildAddRecordMutation(
            entity, allEntities, allDataTypes
        )

        // console.log("form data", formData, buildInput(formData))
        /// TODO 检查输入是否合理

        const result = await queryRunner(queryText, {
            input: buildInput(formData)
        })

        /// TODO error handling

        /// invalidate cache if succeeded
        invalidateCache()

        const data = extractResult(result)

        console.log("Added record", data.id)

        return data.id

    }
}