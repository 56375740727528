
import React from 'react'
import dateFormat from 'dateformat'

const dayjs = require('dayjs');

export default function DateTime(props) {
    const { params } = props
    const { format = 'YYYY-MM-DD HH:mm', value} = params

    let fm = v => (v ? dayjs(v).format(format) /*dateFormat(v, format)*/ : "")

    if(Array.isArray(value)) {
        return value.map((v, i) => {
            return fm(v)
        }).join("; ")
    }
    return fm(value)
}

