
import 'regenerator-runtime/runtime'

import Query_entry from 'bwax/ml/query/query_entry.bs';

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import buildSelectionText from 'bwax/query/buildSelectionText'

// utils:
import getImageURL from 'bwax/util/getImageURL';


import { getInstance, setupQueryCache, buildCacheKey, GLOBAL } from 'bwax/store/DataCache';

import { setupDataQueryRunner } from 'bwax/query/runClientQuery';

import { hashCode, guid, } from 'bwax/utils'

import invariant from 'invariant';

import { unpack } from 'bwax/lang/LangHelper'

// This is the entry for most helper functions

// export lang_entry_slim from "bwax/ml/lang/lang_entry_slim";

// []Bwax - 通用 API （使用时要初始化，但初始化不耗费资源，缓存会在 instance 层面上）
// * []数据请求
//     * []list, listAll, findOne
//     * []add, update, delete
//     * []自定义 query
//     * []自定义 mutation
// * []BwaxLang 执行流程
// * []通用视图 View （API 仍然使用 elm-ui 的风格）
//     * []定义 BwaxLang 语法
//     * []生成中间结构


// 有个问题是，数据 API 的请求参数怎么做。也许就直接使用 GraphQL API 的形式吧。不要搞得太复杂了，毕竟，围绕着 BwaxLang 来开展产品，才是更重要的。

// 但这样好像要依赖 entities，也许我要做一些特殊的请求，给定一些 entities ，获取的它相关的所有 entities / datatypes 的功能

export default class Facade {

    constructor({ 
        queryRunner, queryCache, isAdmin = false,  isDesignMode = false,
        dlc,    // DataLoaderContext, it is mandatory now

        gmod,           // Global modules for running Bwax Lang.
        prepareEnv,     // Prepare the BwaxLang's evaluation environment

        // 外部传入的已经可用的 entities, datatypes 
        entities = [], dataTypes = [],

        applicationCode,
 
    }) {
        // local state:
        if(!applicationCode) {
            console.error("Facade: no application code")
        }

        this.instanceID = guid();


        this.entities = entities;
        this.dataTypes = dataTypes;
        const [entity_dict, data_type_dict] = lang_entry_slim.build_definition(entities, dataTypes);
        this.entity_dict = entity_dict;
        this.data_type_dict = data_type_dict;

        this.queryRunner = queryRunner || setupDataQueryRunner(dlc);
        // this.appData = appData || {};
        this.dataCache = getInstance(dlc);
        this.queryCache = queryCache ? queryCache : setupQueryCache(this.dataCache, dlc);

        this.isAdmin = isAdmin;
        this.adminPages = [];

        this.isDesignMode = isDesignMode;

        this.dlc = dlc;
        
        this.gmod = gmod;
        this.prepareEnv = prepareEnv;

        this.baseEnv = undefined;

        this.pageFragments = {}; // <name> -> { name, compiled unpacked }

        this.applicationCode = applicationCode;

    }

    // 初始化
    async init({ adminPages } = {} ) {

        const dataCache = this.dataCache;
        const dlc = this.dlc;
        const runQuery = this.queryRunner;

        const applicationCode = this.applicationCode

        await (async () => {
            if(this.isAdmin) {
                if(adminPages) {
                    this.adminPages = adminPages;
                } else {
                    this.adminPages = await getAdminPages({ applicationCode, runQuery, dataCache, dlc });
                }
            } 
            // page components
            this.pageComponents = await getPageComponents({ applicationCode, runQuery, dataCache, dlc});

        })();
    }

    // 确保 facade instance 有对应的 entity 的 meta info
    async prepare(givenEntityNameOrKeys) {

        // 如果里面有 “验证用户”，那么就同时找用户
        const entityNameOrKeys = (givenEntityNameOrKeys.indexOf("验证用户") != -1 && givenEntityNameOrKeys.indexOf("用户") == -1) ?
            [...givenEntityNameOrKeys, "用户"] : givenEntityNameOrKeys;

        // 同时兼容 name 和 key

        // 检查所需的 entity 是否已经获取
        const preparedNames = this.entities.map(e => e.name);
        const prepareDataTypeNames = this.dataTypes.map(dt => dt.name);

        const missingEntityNames = entityNameOrKeys.filter(
            nameOrKey =>  !this.entities.some(e => e.name == nameOrKey || e.key == nameOrKey)
        );

        const dataCache = this.dataCache;
        const dlc = this.dlc;
        const runQuery = this.queryRunner;

        if (missingEntityNames.length > 0) {
            // 调用 getRelevantEntities 获取相关的 entities, data_types，并渐进地生成 entity_dict, data_type_dict
            
            const [entities, dataTypes] = await getRelevantEntities(
                { 
                    entityNames: missingEntityNames,
                    excludedEntityNames: preparedNames,
                    excludedDataTypeNames: prepareDataTypeNames,
                    appCode: this.applicationCode
                },
                { runQuery, isAdmin: this.isAdmin, dataCache, dlc }
            );

            // 这里虽然不应该有重复的，但也排除一下
            // merge
            this.entities = [
                ...entities,
                ...this.entities.filter(oe => entities.every(e => e.name != oe.name))
            ];
            this.dataTypes = [
                ...dataTypes,
                ...this.dataTypes.filter(odt => dataTypes.every(dt => dt.name != odt.name))
            ];

            const [entity_dict, data_type_dict] = lang_entry_slim.build_definition(entities, dataTypes);

            this.entity_dict = lang_entry_slim.merge_assoc_list(entity_dict, this.entity_dict);
            this.data_type_dict = lang_entry_slim.merge_assoc_list(data_type_dict, this.data_type_dict);
        }

        // 重新生成 baseEnv:
        if(this.gmod && this.prepareEnv) {
            this.baseEnv = this.prepareEnv(
                this.gmod, this.entity_dict, this.data_type_dict, this.pageComponents || [], this.adminPages || []
            );
        } else {
            // 
            // console.warn("Cannot prepare base env", entityNameOrKeys);
        }
    }

    // prepare for all entnties
    async prepareAll() {
        const dataCache = this.dataCache;
        const dlc = this.dlc;
        const runQuery = this.queryRunner;

        const [entities, dataTypes] = await loadApplicationDefinition(
            {  appCode: this.applicationCode },
            { runQuery, dataCache, dlc }
        );

        const [entity_dict, data_type_dict] = lang_entry_slim.build_definition(entities, dataTypes);

        this.entities = entities;
        this.dataTypes = dataTypes;

        this.entity_dict = lang_entry_slim.merge_assoc_list(entity_dict, this.entity_dict);
        this.data_type_dict = lang_entry_slim.merge_assoc_list(data_type_dict, this.data_type_dict);

        if(this.gmod && this.prepareEnv) {
            this.baseEnv = this.prepareEnv(
                this.gmod, this.entity_dict, this.data_type_dict, this.pageComponents || [], this.adminPages || []
            );
        } else {
            // 
            // console.warn("Cannot prepare base env", entityNameOrKeys);
        }
    }



    getEntity(nameOrKey) {
        return (this.entities || []).find(e => e.name == nameOrKey || e.key == nameOrKey);
    }


    async loadPageFragments(names) {

        const missingNames = names.filter(n => this.pageFragments[n] === undefined);

        //
        if(missingNames.length > 0) {
            const dataCache = this.dataCache;
            const dlc = this.dlc;
            const runQuery = this.queryRunner;
            const pageFragments = await getPageFragments(
                names,
                { runQuery, dataCache, dlc, applicationCode: this.applicationCode }
            ); 
    
            for(let pageFragment of pageFragments) {
                if(pageFragment) {
                    this.pageFragments[pageFragment.name] = unpack(pageFragment.compiled)
                }
            }
        }
        return names.map(n => this.pageFragments[n]);

    }


    buildSelection(entityName, fieldPaths = []) {

         // tree to field paths list if necessary
        function toFieldPaths(tree) {
            if (Array.isArray(tree)) {
                // 这里面应该全是 string;
                return tree.length == 0 ? [""] : tree;
            } else {
                // object
                if (Object.keys(tree).length === 0) {
                    return [""];
                } else {
                    return Object.keys(tree).reduce((acc, k) => {
                        const subTree = tree[k];
                        const subPaths = toFieldPaths(subTree);
                        const paths = subPaths.map(s => s === "" ? k : k + "." + s);
                        return [
                            ...acc,
                            ...paths
                        ]
                    }, [])
                }
            }
        }

        const entity = this.entities.find(e => e.name == entityName);
        invariant(entity, "Cannot find entity " + entityName);
        return buildSelectionText(
            entity,
            Array.isArray(fieldPaths) ? fieldPaths: toFieldPaths(fieldPaths),
            { allEntities: this.entities, allDataTypes: this.dataTypes }
        )
    }

    // for query
    // query obj 
    // queryObj -> { entityName: String, condition: [[{}]], sort: [], search, offset, pageSize, fieldPaths }
    // fieldPaths is like [ String ] or Object  
    //       1. [ "名字", "种类", "添加者.昵称"]
    //       2. { 名字: {}, 种类: {}, 添加者: { 昵称: {} }}  -- 在这一种表达形式中，叶子总是 {}
    //
    transformQueryObj(queryType, queryObj) {
        const {
            fieldPaths, outputFieldPaths = [],
            entityName, ...others
        } = queryObj;
        //
        if (fieldPaths) {
            const selection = this.buildSelection(entityName, fieldPaths);
            return {                
                entityName,
                ...others,
                selection,
                queryType
            }
        } else {
            // custom query return value may have different selections for each particle.
            return {
                entityName,
                args: [], // default
                ...others,
                outputSelections: outputFieldPaths.map(
                    ([entityName, ps]) => [entityName, this.buildSelection(entityName, ps)]
                ),
                queryType
            }
        }
    }

    getQueryContext() {
        return Query_entry.to_query_context({
            entity_dict: this.entity_dict,
            data_type_dict: this.data_type_dict,
            queryRunner: this.queryRunner,
            queryCache: this.queryCache,
        })
    }

    runQueries(queries, { forceRefreshing = false, processSelectValue = true, processCompositeData = true, additionalQueryParams = {} } = {}) {
        const context = this.getQueryContext();
        return new Promise((resolve, reject) => {
            Query_entry.run_queries(
                context,
                queries,
                { forceRefreshing, processSelectValue, processCompositeData, additionalQueryParams },
                (rs, error) => {
                    if (error) {
                        reject(error)
                    } else {
                        resolve(rs)
                    }

                }
            );
        })
    }

    async getQueryVars (queryObj) {

        // return condition and sort;
        await this.prepare([queryObj.entityName]);
        return Query_entry.get_query_vars(this.getQueryContext(), queryObj);

    }


    async runSingleQuery(queryType, queryObj, options) {
        await this.prepare([queryObj.entityName]);

        const queries = [this.transformQueryObj(queryType, queryObj)];

        const [r] = await this.runQueries(queries, options)
        // 要把唯一的 r 挑出来，因为只有一个 query
        return r;
    }

    async list(queryObj, options) {        
        return this.runSingleQuery("list", queryObj, options)
    }

    async listAll(queryObj, options) {
        return this.runSingleQuery("listAll", queryObj, options)
    }

    async findOne(queryObj, options) {
        return this.runSingleQuery("findOne", queryObj, options)
    }

    async findById(id, queryObj, options) {
        return this.runSingleQuery("findOne", {
            ...queryObj,
            condition: [[{ field: "id", op: "eq", value: id }]]
        }, options)
    }


    async count(queryObj, options) {
        return this.runSingleQuery("count", queryObj, options)
    }

    async customQuery(queryObj, options) {
        return this.runSingleQuery("custom", queryObj, options)
    }

    async aggregate(queryObj, options) {
        return this.runSingleQuery("aggregate", queryObj, options)
    }

    async getAggregateDataFields(queryObj) {
        await this.prepare([queryObj.entityName]);
        const [ _queryText, _queryVars, dataFields ] = Query_entry.build_aggregate_query(
            this.entity_dict,
            this.data_type_dict,
            queryObj.aggregate_config
        )
        return dataFields
    }


    // 这里的 query objects 则有一个可选的 queryType: "list / listAll / findOne / count"
    async batchQuery(queryObjs) {
        await this.prepare(queryObjs.map(q => q.entityName));
        const rs = await this.runQueries(
            queryObjs.map(queryObj => {
                return this.transformQueryObj(queryObj.queryType, queryObj)
            })
        )
        return rs;
    }

    // the process is an function:  () => a
    runMutation(process) {
        return new Promise((resolve, reject) => {
            process((result, error) => {
                if (error) {
                    reject(error)
                } else {
                    resolve(result)
                }
            })   
        })
    }

    async add(mutationObj, options = {}) {
        await this.prepare([mutationObj.entityName]);
        const { entityName, formData, fieldPaths } = mutationObj;
        const selectionText = this.buildSelection(entityName, fieldPaths);
        const context = this.getQueryContext();
        return await this.runMutation((cont) => {
            Query_entry.run_add_mutation(context, entityName, formData, selectionText, options, cont);
        })        
    }

    async update(mutationObj, options = {}) {
        await this.prepare([mutationObj.entityName]);
        const { entityName, formData, id, fieldPaths } = mutationObj;

        const selectionText = this.buildSelection(entityName, fieldPaths);
        const context = this.getQueryContext();
        return await this.runMutation((cont) => {
            Query_entry.run_update_mutation(context, entityName, formData, id, selectionText, options, cont);
        })

    }

    async delete(mutationObj, options = {}) {
        await this.prepare([mutationObj.entityName]);
        const { entityName, id } = mutationObj;
        const context = this.getQueryContext();

        return await this.runMutation((cont) => {
            Query_entry.run_delete_mutation(context, entityName, id, options, cont);
        }) 
    }

    async customMutation(mutationObj, options = {}) {
        await this.prepare([mutationObj.entityName]);
        const { entityName, interfaceName, id, params, args = [], outputFieldPaths = [] } = mutationObj;

        const outputSelections = outputFieldPaths.map(
            ([entityName, ps]) => [entityName, this.buildSelection(entityName, ps)]
        )
        const context = this.getQueryContext();
        return await this.runMutation((cont) => {
            Query_entry.run_custom_mutation(
                context, entityName, interfaceName, id, 
                args,  // 
                outputSelections, 
                options, 
                cont);
        })  
        // return new Promise((resolve, reject) => {
        //     Query_entry.run_custom_mutation(context, entityName, interfaceName, id, args, outputSelections, (result, error) => {
        //         if (error) {
        //             reject(error)
        //         } else {
        //             resolve(result)
        //         }
        //     });
        // })

    }

    isCustomQuery (query_config) {
        return Query_entry.is_custom_query(query_config);
    }


    async getCurrentUserId() {
        const resultText = await this.queryRunner("query { me { authUser { id } } }");
        const { data, errors } = JSON.parse(resultText);
        return data?.me?.authUser?.id;
    }

    // for bwax lang execution

    /// some utilities:
    toImageURL(image, processor){
        return getImageURL(image, processor);
    }

}


const QueryGetDefinition = `
    query ($appCode: String!) { definition { application (code: $appCode) { entities dataTypes } } }
`

// return entities, dataTypes
async function loadApplicationDefinition({ appCode }, { runQuery, dataCache, dlc }) {

    return withCache(
        async () => {
            const resultText = await runQuery(QueryGetDefinition, { appCode })
            const result = JSON.parse(resultText);
            const { data } = result;
            if (data) {
                const { entities, dataTypes } = data.definition.application;
                return [entities, dataTypes]
        
            } else {
                return [[], []]
            }
        },
        buildCacheKey(
            GLOBAL, 
            "definition-get-entities-" + appCode,
            dlc
        ),
        dataCache
    )
}




const QueryGetRelevantDefinition = `
query ($appCode: String, $entityNames: [String]!, $excludedEntityNames: [String]!, $excludedDataTypeNames: [String]!, $includeAdminDisplayOptions: Boolean ) {
	getRelevantDefinition (appCode: $appCode, entityNames: $entityNames, excludedEntityNames: $excludedEntityNames, excludedDataTypeNames: $excludedDataTypeNames, includeAdminDisplayOptions: $includeAdminDisplayOptions) {
	    dataTypes
        entities
	}
}
`

// return entities, dataTypes
async function getRelevantEntities({ appCode, entityNames, excludedEntityNames, excludedDataTypeNames }, { runQuery, isAdmin, dataCache, dlc }) {

    return withCache(
        async () => {
            const resultText = await runQuery(QueryGetRelevantDefinition, 
                { appCode, entityNames, excludedEntityNames, excludedDataTypeNames, includeAdminDisplayOptions: isAdmin }
            )
            const result = JSON.parse(resultText);
            const { data } = result;
            if (data) {
                const { entities, dataTypes } = data.getRelevantDefinition;
                return [entities, dataTypes]
        
            } else {
                return [[], []]
            }
        },
        buildCacheKey(
            GLOBAL, 
            "definition-get-relevant-entities-" + (appCode ? appCode : "::") + "-" +
                JSON.stringify(entityNames) + "-" + 
                hashCode(JSON.stringify({entityNames, excludedEntityNames, excludedDataTypeNames})),
            dlc
        ),
        dataCache
    )
}

const QueryGetPageFragments = `
query ($names: [String], $applicationCode: String! ) {
    definition {
        application (code: $applicationCode) {
            pageFragments (names: $names) {
                name
                compiled
            }
        }
    }
}
`



const QueryPageComponents = `
query ($applicationCode: String!)  {
    definition{
        application (code: $applicationCode) {
            pageComponents {
                name
                ioTypeMetas
            }
        }
    }
}
`

const QueryAdminPages = `
query ($applicationCode: String!) {
    definition {
        application (code: $applicationCode) {
            adminPages {
                name
                ioTypeMetas
            }
        }
    }
}
`

async function withCache(loadData, cacheKey, dataCache) {
    const value = dataCache.get(cacheKey);
    if(value) {
        return value
    } else {
        const v = await loadData();
        dataCache.set(cacheKey, v);
        return v;
    }
}


async function getPageFragments(names, { runQuery, dataCache, dlc, applicationCode }) {
    return withCache(
        async () => {
            const resultText = await runQuery(QueryGetPageFragments, {names, applicationCode})
            const result = JSON.parse(resultText);
            const { data } = result;
            if (data && data.definition && data.definition.application) {
                return data.definition.application.pageFragments;
            } else {
                // TODO error handling
                return null
            }
        },
        buildCacheKey(GLOBAL, "definition-page-fragments - " + applicationCode + "-" + names.join(","), dlc),
        dataCache
    )
}


async function getPageComponents({ applicationCode, runQuery, dataCache, dlc }) {
    return withCache(
        async () => {
            const resultText = await runQuery(QueryPageComponents, { applicationCode })
            const result = JSON.parse(resultText);
            const { data } = result;

            if (data && data.definition && data.definition.application) {
                return data.definition.application.pageComponents;
            } else {
                // TODO error handling
                return null
            }
        },
        buildCacheKey(GLOBAL, "definition-page-components-" + applicationCode, dlc),
        dataCache
    )
}

async function getAdminPages({ applicationCode, runQuery, dataCache, dlc }) {
    return withCache(
        async () => {
            const resultText = await runQuery(QueryAdminPages, { applicationCode })
            const result = JSON.parse(resultText);
            const { data } = result;
            if (data && data.definition && data.definition.application) {
                return data.definition.application.adminPages;
            } else {
                // TODO error handling
                return null
            }
        },
        buildCacheKey(GLOBAL, "definition-adminPages-" + applicationCode, dlc),
        dataCache        
    )

}



// a couple of helpers:
export function prepare(entityNames, facade) {
    return facade.prepare(entityNames);
}

export function getQueryRunner(facade) {
    return facade.queryRunner
}

export function getQueryCache(facade) {
    return facade.queryCache
}

// return entity_dict, data_type_dict
export function getDefinition(facade) {
    return [ facade.entity_dict, facade.data_type_dict ]
}

export function getBaseEnv (facade) {
    return facade.baseEnv
}






