
// 尝试 visualize  OreLang

import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom'

import lang_visualize from 'Client/ml/helpers/lang_visualize.bs';

import ReactFlow, { Background, MiniMap, Controls, useNodesState, useEdgesState } from 'react-flow-renderer';

import { getLayoutedElements } from '../designApp/pages/diagrams/diagramLayout';

import useResizeObserver from '@react-hook/resize-observer'

import InputSelect from 'Client/js/ui/components/InputSelect';

import './LanguageVis.less'
import ExprNode from './ExprNode';
import ExprEdge from './ExprEdge';

import NodePropertyEdit from './NodePropertyEdit'

import useStateWithLocalCache, { useRefWithLocalCache } from 'bwax-ui/hooks/useStateWithLocalCache';

// 用于 demo
// 保存 nodes 的位置，如果 tree 是一样的，则使用之前保存的位置信息


export default function LanguageVis(props) {

    const { tree, treeId, setPropertyPanelContent, propertyPanelContent } = props;

    const layoutNeededRef = useRef(false);

    const [nodes, setNodes] = useState([]);

    /// node position, node mode
    const [graphSettings, setGraphSettings] = useStateWithLocalCache("local-graph-setting-0-" + treeId, {});
    // const [ graphSettings, setGraphSettings ] = useState({});

    const graphSettingsRef = useRef(graphSettings);
    graphSettingsRef.current = graphSettings;

    // react-flow element:
    const [uiNodes, setUiNodes, onUiNodesChange] = useNodesState([]);
    const [uiEdges, setUiEdges, onUiEdgesChange] = useEdgesState([]);

    const uiNodesRef = useRef(uiNodes);
    uiNodesRef.current = uiNodes;

    // const uiEdgesRef = useRef(uiEdges);
    // uiEdgesRef.current = uiEdges;

    // id -> size;
    const [ nodeSizes, setNodeSizes ] = useState({});


    // 单个 pattern 的 val name，或者 function def 的 name
    const [names, setNames] = useState([]);
    const [selectedName, setSelectedName] = useState(undefined);


    function updateElements(uiNodes, uiEdges, graphSettings) {
        return [
            uiNodes.map(n => {
                const setting = graphSettings[n.id] || {};
                // get all the settings of sources:

                const inputEdges = uiEdges.filter(e => {
                    return e.target === n.id
                });
            
                const inputNodeSettings = inputEdges.reduce((acc, e) => {
                    return {
                        ...acc,
                        [e.source]: graphSettings[e.source]
                    }
                }, {});

                return {
                    ...n,
                    hidden: setting.collapsed,
                
                    ...(setting.position ? { position: setting.position } : {}),

                    data: {
                        ...n.data,
                        setting,

                        inputNodeSettings,
                    }
                }

            }),
            uiEdges.map(e => {
                const sourceNodeSetting = graphSettings[e.source] || {};
                const targetNodeSetting = graphSettings[e.target] || {};
                return {
                    ...e,
                    hidden: sourceNodeSetting.collapsed,
                    data: {
                        ...(e.data || {}),
                        sourceNodeSetting,
                        targetNodeSetting,
                    },
                }
            })
        ];
    }


    // to elements
    function toElements(defName, nodes, edges) {
        const [ ns, es ] = [
            nodes.map(n => ({ 
                id: n.id, type: "exprNode", position: { x: 0, y: 0 },
                data: { 
                    ...n, defName, edges, nodes,                    
                }, 
            })),
            edges.map(e => {                
                const sourceNode = nodes.find(n => n.id === e.source_id);
                const targetNode = edges.find(n => n.id === e.target_id);
                return                 {
                    id: e.source_id + "-" + e.target_id + "-" + e.target_handle_idx,
                    source: e.source_id, sourceHandle: "0",
                    target: e.target_id, targetHandle: e.target_handle_idx + "",
                    type: "expr",
                    data: {
                        sourceNode, targetNode
                    }
                }
            })
        ];
        return updateElements(ns, es, graphSettings);
    }


    useEffect(() => {

        const names = lang_visualize.get_names_to_visualize(tree);

        setNames(names);
        if (names.length > 0) {
            setSelectedName(names[names.length - 1]);
        } else {
            setSelectedName(null)
        }


    }, [tree])

    useEffect(() => {
        if (tree && selectedName) {

            const [nodes, edges] = lang_visualize.make_graph_to_js(tree, selectedName);
            // initialized it is not layed out:
            const [uiNodes, uiEdges] = toElements(selectedName, nodes, edges);

            layoutNeededRef.current = true;

            // setNodeSizes({});
            setNodes(nodes);

            setUiEdges(uiEdges);
            setUiNodes(uiNodes);

        }
    }, [selectedName])


    // id -> size
    // const nodeSizes = Object.keys(graphSettings).reduce((acc, id) => {
    //     const size = graphSettings[id].size;
    //     return size ? { ...acc, [id]: size } : acc;
    // }, {});


    useEffect(() => {

        if (nodes && layoutNeededRef.current && uiNodes.every(n => 
            nodeSizes[n.id] !== undefined || (graphSettings[n.id] && graphSettings[n.id].collapsed)
        )) {

            const sizedUiNodes = uiNodes.map(n => {
                if (nodeSizes[n.id]) {
                    return { ...n, size: nodeSizes[n.id] }
                } else {
                    // 
                    // console.log(">>", n.id, (graphSettings[n.id] && graphSettings[n.id].collapsed))
                    return n;
                }
            });

            if (graphSettings && nodes.every(n => graphSettings[n.id] && graphSettings[n.id].position)) {
                // 有缓存：
                const repositionedUiNodes = sizedUiNodes.map(n => {
                    return {
                        ...n,
                        position: graphSettings[n.id].position,
                    }
                })
                setUiNodes(repositionedUiNodes);

            } else {


                const repositionedUiNodes = getLayoutedElements(sizedUiNodes.filter(n => {
                    return !(graphSettings[n.id] && graphSettings[n.id].collapsed)
                }), uiEdges.filter(e => {
                    return !e.hidden
                }));

                setGraphSettings(prev => {
                    const gs = repositionedUiNodes.reduce((acc, {id, position}) => {
                        const nodeSetting = prev[id] || {};
                        return {
                            ...acc,
                            [id]: { ...nodeSetting, position, size: nodeSizes[id] }
                        }
                    }, prev);
                    return gs;
                });

            }

            layoutNeededRef.current = false;

        }
    }, [ nodeSizes, nodes ])


    useEffect(() => {

        if(layoutNeededRef.current) {
            return;
        }
        const [ ns, es ] = updateElements(uiNodes, uiEdges, graphSettings);

        setUiNodes(ns);
        setUiEdges(es);

    }, [ graphSettings ])

    function SizedExprNode(props) {
        const { id, data } = props;
        const nodeRef = useRef(null);

        useResizeObserver(nodeRef, entry => {
            if (entry.contentRect) {

                const size = { width: entry.contentRect.width, height: entry.contentRect.height };
                setNodeSizes(prev => {
                    return {
                        ...prev,
                        [id]: size
                    }
                });

            }
        });

        return (
            <ExprNode ref={nodeRef} data={data} />
        )
    }

    const nodeTypes = useMemo(() => ({
        "exprNode": (props) => {
            return <SizedExprNode {...props} />
        }
    }), [])

    const edgeTypes = useMemo(() => ({
        expr: (props) => {
            return <ExprEdge {...props} />
        },
    }), [ ]);


    function updateGraphSettings(id, setting) {

        const newGraphSettings = {
            ...graphSettings,
            [id]: setting,
        }

        const [ newUiNodes, newUiEdges ] = updateElements(uiNodes, uiEdges, newGraphSettings);

        setGraphSettings(newGraphSettings);

        setUiNodes(newUiNodes);
        setUiEdges(newUiEdges)
    }


    function renderPropertyEdit() {
        if (propertyPanelContent && propertyPanelContent.type == "language-vis") {

            const { node } = propertyPanelContent;
            return (
                <PropertyPanelPortal>
                    <NodePropertyEdit node={node}
                        value={graphSettings[node.id]}
                        onChange={v => {
                            updateGraphSettings(node.id, v)                        
                        }}
                        graphSettings={graphSettings}
                        updateGraphSettings={updateGraphSettings}                        
                    />
                </PropertyPanelPortal>
            )
        }
        return null
    }


    return (
        <div className={"language-vis" + (layoutNeededRef.current ? " layout-needed" : "")}>
            <div className="vis-toolbar">
                <InputSelect {...{
                    value: selectedName, onChange: setSelectedName,
                    isSearchable: true,
                    isClearable: false,
                    options: names,
                }} />
            </div>
            <ReactFlow
                // connectionLineType={"smoothstep"}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                // snapToGrid={true}
                snapGrid={[8, 8]}
                nodes={uiNodes}
                edges={uiEdges}

                onNodesChange={onUiNodesChange}
                onEdgesChange={onUiEdgesChange}

                selectNodesOnDrag={false}

                onNodeDragStart={(event, node) => {
                    // console.log("Drag start", node.id, node.position);

                }}

                onNodeDrag={(event, node) => {

                    uiNodesRef.current = uiNodesRef.current.map(e => {
                        if (e.id == node.id) {
                            return {
                                ...e,
                                position: node.position
                            }
                        } else {
                            return e
                        }
                    })

                    setGraphSettings(prev => ({
                        ...prev,
                        [node.id]: { 
                            ...prev[node.id],
                            position: node.position 
                        },
                    }));

                }}
                onNodeDragStop={(event, node) => {
                    // console.log("Drag stop", node.id, node.position);
                }}

                onSelectionChange={({ nodes, edges }) => {

                    if (nodes && nodes.length === 1) {
                        const node = nodes[0];
                        // if(propertyPanelContent && propertyPanelContent.node && propertyPanelContent.node.id === node.id) {
                        //     // do nothing
                        //     return 
                        // }
                        setPropertyPanelContent({
                            node,
                            type: "language-vis"
                        });
                    } else {
                        setPropertyPanelContent(null);
                    }

                }}

            >
                <Background color="#aaa" gap={8} />
                <MiniMap
                    nodeStrokeColor={(n) => {
                        if (n.style?.background) return n.style.background;
                        if (n.type === 'input') return '#0041d0';
                        if (n.type === 'output') return '#ff0072';
                        if (n.type === 'default') return '#1a192b';
                        return '#F0F3F5';
                    }}
                    nodeColor={(n) => {
                        if (n.style?.background) return n.style.background;

                        return '#F0F3F5';
                    }}
                    nodeBorderRadius={2}
                    style={{
                        right: "initial",
                        left: "3rem"
                    }}
                />
                <Controls />
            </ReactFlow>
            {renderPropertyEdit()}
        </div>
    )

}

// 

function PropertyPanelPortal(props) {

    const [portal, setPortal] = useState(null);
    useEffect(() => {
        if (typeof (document) !== "undefined") {
            const portal = document.getElementById('property-panel-portal');

            setPortal(portal);

        }
    }, [])
    if (portal) {
        return ReactDOM.createPortal(props.children, portal);
    } else {
        return null
    }
}