import React, { useRef, useEffect, cloneElement } from 'react';

import useResizeObserver from '@react-hook/resize-observer'

/**
 * class DOMRectReadOnly {
    +x: number;
    +y: number;
    +width: number;
    +height: number;
    +top: number;
    +right: number;
    +bottom: number;
    +left: number;
    }
 */

export default function ItemMeasurer (props) {

    const { item, index } = props;

    const nodeRef = useRef(null);

    const lastIndexRef = useRef(undefined);

    function _measureItem (props, isCommitPhase) {
        const {
            direction,
            handleNewMeasurements,
            index,
            size: oldSize,
        } = props;

        const node = nodeRef.current;        

        if (
            node &&
            node.ownerDocument &&
            node.ownerDocument.defaultView &&
            node instanceof node.ownerDocument.defaultView.HTMLElement
        ) {
            const newSize =
                direction === 'horizontal'
                    ? Math.ceil(node.offsetWidth)
                    : Math.ceil(node.offsetHeight);

            // console.log(">>> measured", oldSize , newSize, index, isCommitPhase);
            if (oldSize !== newSize || true) {
                handleNewMeasurements(index, newSize, isCommitPhase);
            }
        }
    }


    useEffect(() => {
        if(nodeRef.current) {
            // console.log("Node Ref ready")
            _measureItem(props, true);
        }

    }, [ nodeRef.current ])

    useEffect(() => {
        if(lastIndexRef.current !== undefined && lastIndexRef.current !== index) {
            // console.log("Index Changed", index, nodeRef.current);
            _measureItem(props, false);
        }
        lastIndexRef.current = index;
    }, [ index ])


    useResizeObserver(nodeRef, e => {
        _measureItem(props, false);
    });

    return cloneElement(item, {
        measureRef: nodeRef,
    });

}

export function withMeasurer(item, key, index, handleNewMeasurements) {

    return (
        <ItemMeasurer {...{
            handleNewMeasurements,
            key, 
            index,
            item,
        }}
        />
    )

}
