

import React, { useState, useEffect, useRef } from 'react'

import Loading from 'Client/js/components/Loading';

import { mergeCriteria } from 'bwax/query/resolveAndRunQuery'

import groupByTags, { buildTagFolderNodes } from '../groupByTag';

import { getGlobalEventBus } from 'Client/js/studioApp/EventBus';

export default function EntitySelectRoot(props) {

    const { 
        facade, renderSelectorNode, itemNode,  baseCriteria, multitenantType,
        isExtendingEntity = false,

    } = props;

    const baseCriteriaRef = useRef();
    baseCriteriaRef.current = baseCriteria;

    const entityName = '数据实体';

    const [queriedData, setQueriedData] = useState(undefined);
    const queriedDataRef = useRef();
    queriedDataRef.current = queriedData;

    const childFieldNames = {
        "field": "字段", "virtual-field": "虚拟字段", "backlink-field": "反向关联字段", "data-interface": "数据接口",
    }

    const fieldPaths = {
        "名称": {},
        "描述": {},
        "标签": {},
        "KEY": {},
        "多租户": {},
        // field:
        "字段": ["名称", "描述", "字段类型", "字段选项", "必填", "多值", "显示权重", "内置", "创建时间"],
        "虚拟字段": ["名称", "描述", "字段类型", "字段选项", "必填", "多值", "显示权重", "创建时间"],
        "反向关联字段": ["名称", "描述", "关联实体名", "多值", "显示权重", "创建时间"],
        "数据接口": ["名称", "描述", "类型", "显示权重", "创建时间"],
        "创建时间": {},
        "公开": {}
    };

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("updateItemData", ({ itemType, itemData }) => {
            // update the current item, opened items, and tell the upper layer:
            if (itemType === "entity") {
                setQueriedData(prev => {
                    return {
                        ...prev,
                        data: prev.data.map(e => e.id === itemData.id ? { ...e, ...itemData } : e)
                    }
                })
            } else {
                const childFieldName = childFieldNames[itemType];
                if (childFieldName) {
                    setQueriedData(prev => {
                        return {
                            ...prev,
                            data: prev.data.map(e => {
                                const childFieldValues = e[childFieldName];
                                if (childFieldValues && Array.isArray(childFieldValues)) {
                                    return {
                                        ...e,
                                        [childFieldName]: childFieldValues.map(e => {
                                            return e.id === itemData.id ? { ...e, ...itemData } : e
                                        })
                                    }
                                }
                                return e;
                            })
                        }
                    })
                } else {
                }
            }
        });
        return () => {
            // console.log("Unsubscribe", currentApplication);
            unsubscribe()
        }
    }, [])

    async function queryData(criteria, options = {}) {
        // do standard query
        const baseCriteria = baseCriteriaRef.current;

        const queryObj = {
            entityName,
            condition: mergeCriteria({ ...baseCriteria, "是否内置实体的扩展": isExtendingEntity }, criteria),
            pageSize: 1000,
            offset: 0,
            fieldPaths,
        };
        const [result, error] = await facade.listAll(queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {

            setQueriedData(prev => {
                if (criteria && criteria.标签 && result && result.data && prev && prev.data) {

                    // 1. 考虑删除的情况，则在原来的 queriedData 里面查找一遍
                    ///   如果原来某个 entity 有这个标签，但现在没有了，则移除
                    ///   这里面也有个可能性，是那个 entity 的标签被删了，但不管怎么样，它在这个标签刷新的 folder 里，应该消失

                    const tag = criteria.标签.contains ? criteria.标签.contains : criteria.标签;

                    const afterRemoval = prev.data.reduce((acc, e) => {
                        if (e.标签 && e.标签.indexOf(tag) !== -1) {
                            const isStillThere = result.data.some(re => re.id == e.id);
                            if (isStillThere) {
                                return [...acc, e]
                            } else {
                                return acc // remove
                            }
                        } else {
                            return [...acc, e]
                        }
                    }, []);

                    const newData = {
                        ...prev,
                        data: [
                            ...afterRemoval.map(e => {
                                const newOne = result.data.find(ne => ne.id === e.id);
                                if (newOne) {
                                    return { ...e, ...newOne }
                                } else {
                                    return e;
                                }
                            }),
                            ...result.data.filter(e => {
                                return afterRemoval.every(oe => oe.id !== e.id)
                            })
                        ]
                    }
                    return newData
                } else {
                    return result;
                }
            })

        }
    }

    // 只更新某 entity 的特定子列表
    async function queryEntityChildren(entityId, childFieldName, options = {}) {
        const queryObj = {
            entityName,
            fieldPaths: {
                [childFieldName]: fieldPaths[childFieldName]
            },
        };
        const [result, error] = await facade.findById(entityId, queryObj, options);

        if (error) {
            // error handling
            console.log("ERROR", error);
        } else {
            // merge to the result: 
            setQueriedData(prev => {
                const newData = {
                    ...prev,
                    data: prev.data.map(e => {
                        if (e.id === entityId) {
                            return {
                                ...e,
                                [childFieldName]: result[childFieldName]
                            }
                        } else {
                            return e
                        }
                    }),
                }
                return newData;
            })

        }
    }


    useEffect(() => {
        queryData({});
    }, [ JSON.stringify(baseCriteria)]);


    async function reloadItemList(itemType, parentItem) {

        console.log(">> Reload Item List", itemType, parentItem);

        if (childFieldNames[itemType]) {
            if (parentItem && parentItem.data && parentItem.data.id) {
                queryEntityChildren(parentItem.data.id, childFieldNames[itemType], { forceRefreshing: true });
            }
        } else if (parentItem && parentItem.itemType === "entity-tag-folder") {

            await queryData({ "标签": { contains: parentItem.getName() } }, { forceRefreshing: true });

        } else if (parentItem && parentItem.itemType === "entities" && isExtendingEntity === false) {
            // reload full list?  我觉得可以 reload full list 但是，不 reload 里面的 fields / virtual-fields, data-interface.
            // 不过现阶段不用做那么细致，
            // 以后加入协作支持的时候，再说吧

            // 如果 parent 是 entity-tag-folder， 只需要刷新对应标签的 entity 就可以了。
            await queryData({}, { forceRefreshing: true });

        } else if (parentItem && parentItem.itemType === "extending-entities" && isExtendingEntity === true) {
                // reload full list?  我觉得可以 reload full list 但是，不 reload 里面的 fields / virtual-fields, data-interface.
                // 不过现阶段不用做那么细致，
                // 以后加入协作支持的时候，再说吧
    
                // 如果 parent 是 entity-tag-folder， 只需要刷新对应标签的 entity 就可以了。
                await queryData({}, { forceRefreshing: true });

        } else if (parentItem) {
            //  do hting
        }
    }

    useEffect(() => {
        const unsubscribe = getGlobalEventBus().on("reloadItemList", ({ itemType, parentItem }) => {
            reloadItemList(itemType, parentItem);
        });
        return () => {
            unsubscribe();
        }
    }, []);


    if (queriedData === undefined) {
        return <Loading />
    }

    const entities = queriedData.data;

    const sorting = mostRecent => (a, b) => {
        // 给 field 排序, 根据 displayWeight 从大到下排列
        const getWeight = f => f["显示权重"] || 0
        const weightOrder = getWeight(b) - getWeight(a);

        if (weightOrder === 0) {
            const getTime = f => (new Date(f["创建时间"])).getTime();
            return mostRecent ? getTime(b) - getTime(a) : getTime(a) - getTime(b);

        } else {
            return weightOrder
        }
    }

    function buildEntityNode(entity) {

        return itemNode({ itemType: "entity", data: entity, multitenantType },
            [
                ...entity["字段"].filter(f => {
                    return [
                        "创建时间", "创建者", "修改时间", "修改者"
                    ].indexOf(f["名称"]) === -1

                }).sort(sorting(false)).map(f =>
                    itemNode({ itemType: "field", data: f, multitenantType })
                ),
                ...entity["虚拟字段"].sort(sorting(false)).map(f => (
                    itemNode({ itemType: "virtual-field", data: f, multitenantType })
                )),
                ...entity["反向关联字段"].sort(sorting(false)).map(f => (
                    itemNode({ itemType: "backlink-field", data: f, multitenantType })
                )),
                ...entity["数据接口"].sort(sorting(false)).map(itf => (
                    itemNode({ itemType: "data-interface", data: itf, multitenantType })
                )),
            ]
        )
    }

    const [taggedEntityGroups, untaggedEntities] = groupByTags(entities.sort(sorting(true)));

    const subNodes = buildTagFolderNodes(taggedEntityGroups, untaggedEntities, "entity-tag-folder", itemNode, buildEntityNode);

    return renderSelectorNode(itemNode({ 
        name: isExtendingEntity ? "扩展实体" : "数据实体", 
        itemType: isExtendingEntity ? "extending-entities" : "entities" 
    }, subNodes), 0)


}
