

import { runDataQuery, runDefinitionQuery } from "bwax/query/runClientQuery"
import resolveAndRunQuery from 'bwax/query/resolveAndRunQuery'


export default function loadQueryData(queries, targetNode,  partialVariables, 
        { allDataTypes, allEntities, queryTarget = 'data' }) {
            
    return async ({tenantCode, endpoints, sessionToken, sandbox}) => {

        const queryRunners = {
            'data': runDataQuery({tenantCode, endpoints, sessionToken, sandbox}),
            'definition': runDefinitionQuery({tenantCode, endpoints, sessionToken, sandbox})
        }

        const queryRunner = queryRunners[queryTarget]
        
        const queryData = await resolveAndRunQuery(
            queries, targetNode, partialVariables, 
            { allDataTypes, allEntities, queryRunner }
        )
        return queryData
    }
}



