import invariant from 'invariant'

import listFunctions from './listFunctions'
import transformers from './transformers'

import get from 'lodash/get'

export default {
    "__apply__": (node, resolveWithLocal) => {
        // its first child is "list"
        const isArrayType = node.category === 'array'
        const getDefNode = n => isArrayType ? n.children.find(c => c.name === 'def') : n

        const rawListValue = resolveWithLocal(getDefNode(node.children[0]), {})

        if (rawListValue === null || rawListValue === undefined) {
            return null
        }

        ///// 这个 list 有可能是一个 connection 数据
        const listValue = (rawListValue.edges) ? (
            rawListValue.edges.map(e => e.node)
        ) : rawListValue

        return node.children.slice(1).reduce((acc, n) => {
            /// apply list function
            const type = node.category === 'array' ? n.nodeDef.type : n.name
            const defNode = getDefNode(n)

            const func = listFunctions[type]

            invariant(func, `func ${type} is not implemented`)
            const ret = func(acc, defNode, resolveWithLocal)
            return ret
        }, listValue)

    },
    "__switch__": (node, resolveWithLocal, data) => {

        /// its children is an array of object, with "cond" and "value",
        let result = null
        /// short circuit
        for (let i = 0; i < node.children.length; i++) {

            const child = node.children[i]

            /// 1. evaluate the condition:
            const condNode = child.children.find(n => n.name === 'cond')

            // console.log("COND", condNode.nodeDef, resolveWithLocal(condNode, {}), data)

            if (resolveWithLocal(condNode, {})) {
                const valueNode = child.children.find(n => n.name === 'value')
                return resolveWithLocal(valueNode, {})
            }

        }
        return result
    },
    "__def__": (node, resolveWithLocal) => {

        const definitionNodes = node.children.filter(c => c.name !== "__for__")

        const definitions = definitionNodes.reduce((acc, n) => {
            return {
                ...acc,
                [n.name]: resolveWithLocal(n, acc)
            }
        }, {})

        const forNode = node.children.find(n => n.name === "__for__")

        invariant(forNode, "a __def__ node must have a '__for__' child")

        const ret = resolveWithLocal(forNode, definitions)

        return ret

    },

    "__transform__": (node, resolveWithLocal) => {

        const { type, value, ...options } = resolveWithLocal(node, {})

        const transformer = transformers[type]

        invariant(transformer, `unsupported transformer type ${type}`)

        return transformer(value, options)
    },

    "__getValue__": (node, resolveWithLocal) => {

        const { target, path, defaultValue } = resolveWithLocal(node, {})

        return get(target, path, defaultValue)
    },

    "__concat__": (node, resolveWithLocal) => {

        const values = resolveWithLocal(node, {})

        const ret =  (values || []).reduce((acc, v) => {
            if(v === null || v === undefined) {
                return acc
            }
            const toAdd = Array.isArray(v) ? v : [v]
            return [
                ...acc,
                ...toAdd
            ]
        }, [])
        return ret
    },

}
