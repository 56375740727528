// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var BaseUI$BwaxAdmin = require("../BaseUI.bs.js");
var ColorTypes$BwaxAdmin = require("../ColorTypes.bs.js");
var Spinners = require("bwax-ui/lazy/Spinners");

var Bridge = { };

function Spinners$ClipLoader(Props) {
  var size = Props.size;
  var color = Props.color;
  var loading = Props.loading;
  var theme = Props.theme;
  var actualColor = BaseUI$BwaxAdmin.themeColor(theme, ColorTypes$BwaxAdmin.toString(color));
  return React.createElement(Spinners.ClipLoader, {
              size: size,
              color: actualColor,
              loading: loading
            });
}

var ClipLoader = {
  Bridge: Bridge,
  make: Spinners$ClipLoader
};

exports.ClipLoader = ClipLoader;
/* react Not a pure module */
