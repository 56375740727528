
import React, { useEffect, useState } from 'react';

import ReactSelect from 'react-select'

import './InputSelect.less';

import { CloseOutlined, DownOutlined } from '@ant-design/icons';

export default function InputSelect (props) {

    const { 
        isSearchable = false, isClearable = true, isMulti = false, isDisabled = false,

        isLoading = false, className,

        options: givenOptions = [], style: givenStyle, size, menuPlacement = "auto", 
        
        // values:
        value: givenValue,  defaultValue,
        
        // events:
        onChange, onFocus, onSearch, onMenuScrollToBottom,
        
        // other hooks:
        isOptionDisabled,
        placeholder = null,

    } = props;

    // 

    const options = (() => {
        if(givenOptions.every(o => typeof(o) === "string" || typeof(o) === "number")) {
            return givenOptions.map(o => ({ label: o, value: o}));
        } else {
            return givenOptions
        }
    })();

    const getValue = (givenValue) => {
        const proc = v => {
            if (v !== undefined && v !== null) {
                return v.value !== undefined ? v :  (options.find(o => o.value == v) || { value: v, label: v } )
            } else {
                return null
            }
        }
        return Array.isArray(givenValue) ? givenValue.map(proc) : proc(givenValue);
    }

    const [ currentValue, setCurrentVallue ] = useState(getValue(givenValue || defaultValue))

    useEffect(() => {
        // 如果是 null
        const bothNull = currentValue === null && givenValue === null;
        const bothUndefined = currentValue === undefined && givenValue === undefined;
        const sameValue = !!currentValue && currentValue.value === givenValue;
        
        if(bothNull || bothUndefined || sameValue) {
            // do nothing
        } else {
            setCurrentVallue(getValue(givenValue))
        }

    }, [ givenValue ])

    useEffect(() => {
        setCurrentVallue(getValue(givenValue));
    }, [ JSON.stringify(options.map(o => o.value))]);



    const makeStyle = style => provided => ({
        ...provided, ...style
    });

    const styles = {
        container: makeStyle({
            ...(givenStyle || {}),
        })
    }


    const indicator = inner => {
        return props => {
            const {innerProps: { ref, ...restInnerProps }} = props;
            return (
                <div className="indicator" ref={ref} {...restInnerProps}>
                    { inner }
                </div>
            )   
        }
    }

    const components = {
        ClearIndicator: indicator(<CloseOutlined />),
        DropdownIndicator: indicator(<DownOutlined />),
        IndicatorSeparator: _ => null,
    }

    const processValue = v => {
        if(Array.isArray(v)) {
            return v.map(e => e.value);
        } else {
            return v && v.value;
        }
    }

    const onInputChange = (v, { action }) => {
        if(action == "input-change") {
            onSearch && onSearch(v);
        }
    }

    const filterOption = onSearch ? () => {
        // 让外部来处理 search
        return true
    } : undefined;

    return (
        <ReactSelect className={"bw-select" + (size ? (" " + size) : "") + (className ? " " + className : "")} 
            classNamePrefix="bw-select" 
            {...{
                options, components,  menuPlacement,

                isClearable, isSearchable, isMulti, isDisabled, isLoading,

                value: currentValue, 
                
                onChange: v => onChange(processValue(v)), 
                
                onInputChange, filterOption,

                onFocus, onMenuScrollToBottom,

                isOptionDisabled, placeholder,

                menuShouldScrollIntoView: true,

                menuPortalTarget: (typeof(document) === "undefined" ? undefined : document.body),

                styles: { 
                    ...styles, 
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                 },
           
                // defaultMenuIsOpen: true,
            }} 
        />
    )

}