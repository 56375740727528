import React, { useState, useEffect, useRef } from 'react'
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd'
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend'

import { Button, Col } from 'antd'

import { PlusOutlined } from '@ant-design/icons';

import { make as IconButton } from "Client/re/components/IconButton.bs"

import { moveTo } from 'bwax/list'
import guid from 'bwax/guid'

import './DraggableInput.less'

export default function DraggableInput(props) {

    const {
        className,
        value,
        onChange,
        multivalued,
        baseInput,
        draggable = true,
        dropItemStyle = {},
        hideSourceOnDrag = true
    } = props

    const buildValuesWithGuid = (values) => {
        return (values || ['']).map(n => ({
            id: guid(),
            v: n
        }))
    }

    const [values, setValues] = useState(() => {
        if (multivalued) {
            return buildValuesWithGuid(value)
        } else {
            return []
        }
    })

    useEffect(() => {

        const isValuesEqual = (propValues, stateValues) => {
            return Array.isArray(propValues) &&
                Array.isArray(stateValues) &&
                propValues.length === stateValues.length &&
                propValues.every((pv, idx) => pv === stateValues[idx])
        }

        if (multivalued && !isValuesEqual(value, values.map(n => n.v))) {
            setValues(buildValuesWithGuid(value))
        }

    }, [value])

    function renderSingleInput(value, onChange) {
        return React.cloneElement(baseInput, {
            value,
            onChange
        })
    }

    function renderMultiInput(values, onChange) {
        const addInput = () => {
            const newValues = [...values, { id: guid(), v: '' }]
            setValues(newValues)
            /**
             * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1203
             * 新增的空值在外部可能会被 filter 掉，所以在新增时不调用 onChange（by 威豪 2023.03.03）
             */
            // onChange(newValues.map(n => n.v))
        }
        const delInput = (id) => {
            const newValues = values.filter(n => n.id !== id)
            setValues(newValues)
            onChange(newValues.map(n => n.v))
        }
        const updateEdit = (changeValue, id) => {
            const newValues = values.map(n => {
                if (n.id === id) {
                    return {
                        ...n,
                        v: changeValue
                    }
                } else {
                    return n
                }
            })
            setValues(newValues)
            onChange(newValues.map(n => n.v))
        }

        const onItemDrop = (dragItem, dropIdx) => {
            const { dragIdx } = dragItem
            if (dragIdx !== dropIdx && dragIdx !== dropIdx - 1) {
                const dragV = values[dragIdx]
                const newValues = moveTo(values, dragV, dragIdx, dropIdx)
                setValues(newValues)
                onChange(newValues.map(n => n.v))
            }
        }

        // console.log("Values", values);

        return (
            <div className={`draggable-input ${className || ''}`}>
                <DndProvider backend={HTML5Backend}>
                    {
                        draggable ?
                            <DropItem
                                onDrop={item => onItemDrop(item, 0)} />
                            : null
                    }
                    <CustomDragLayer />
                    {
                        values.map((n, idx) => {

                            const inputEle = (
                                <InputItem
                                    key={n.id}
                                    itemId={n.id}
                                    value={n.v}
                                    editInput={
                                        renderSingleInput(
                                            n.v,
                                            v => updateEdit(v, n.id)
                                        )
                                    }
                                    delInput={delInput}
                                    draggable={draggable}
                                    dragIdx={idx}
                                    hideSourceOnDrag={hideSourceOnDrag}
                                />
                            )

                            if (draggable) {
                                return (
                                    [
                                        inputEle,
                                        <DropItem
                                            key={`drop_${idx}`}
                                            onDrop={item => onItemDrop(item, idx + 1)}
                                            dropItemStyle={dropItemStyle} />
                                    ]
                                )
                            }
                            return inputEle
                        })
                    }
                </DndProvider>
                <Button
                    icon={<PlusOutlined />}
                    size="small"
                    type="dashed"
                    className="add-input-btn"
                    onClick={addInput}
                />
            </div>
        )
    }

    if (multivalued) {
        return renderMultiInput(values, onChange)
    } else {
        return renderSingleInput(value, onChange)
    }
}

function InputItem(props) {
    const {
        editInput,
        itemId,
        delInput,
        value,

        draggable,
        dragIdx,
        hideSourceOnDrag
    } = props

    const inputItemRef = useRef(null)

    const [isDragging, setIsDragging] = useState(false)

    const closeColumn = (
        <Col className="close-icon-col">
            <IconButton
                icon="minus"
                onClick={() => delInput(itemId)} />
        </Col>
    )


    return (
        <div
            style={{ opacity: isDragging ? 0.3 : 1 }}
            className="draggable-input-item  draggable-input-item-multivalued"
        >
            <div
                ref={inputItemRef}
                className="base-input-col">
                {editInput}
            </div>
            {
                draggable ?
                    <DragItem
                        dragIdx={dragIdx}
                        hideSourceOnDrag={hideSourceOnDrag}
                        inputItemRef={inputItemRef}
                        value={value}
                        setIsDragging={setIsDragging}>
                        {closeColumn}
                    </DragItem>
                    : closeColumn
            }
        </div>
    )
}

const ItemTypes = {
    "DRAGABLE_INPUT": "dragable_input"
}

function DragItem(props) {

    const {
        value,
        inputItemRef,
        dragIdx,
        dragItemStyle,

        setIsDragging,

        hideSourceOnDrag
    } = props

    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.DRAGABLE_INPUT,
        item: {
            dragIdx,
            inputItemRef,
            value
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        })
    })

    useEffect(() => {
        setIsDragging(isDragging)
    }, [ isDragging ])


    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: false });
    }, [])

    return (
        <div
            ref={drag}
            className="drag-item"
            style={{ ...dragItemStyle, cursor: isDragging ? "default" : "move" }} >
            {props.children}
        </div>
    )

}

function DropItem(props) {

    const { onDrop, dropItemStyle } = props

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ItemTypes.DRAGABLE_INPUT,
        drop: onDrop,
        collect: monitor => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }
        }
    })

    const dropStyle = isOver && canDrop ? {
        width: "100%",
        backgroundColor: "#e6f7ff",
        borderColor: "#3e8ecd",
        height: "3rem",
        marginBottom: ".5rem",
        ...dropItemStyle
    } : {
            width: "100%",
            height: "0.25rem"
        }
    return (
        <div ref={drop} style={{ ...dropStyle }}>
            {props.children}
        </div>
    )
}



const CustomDragLayer = () => {

    const layerStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    }

    function getItemWrapperStyles(initialOffset, currentOffset, item) {

        if (!initialOffset || !currentOffset) {
            return {
                display: 'none',
            };
        }

        let { x, y } = currentOffset

        const inputItem = item && item.inputItemRef && item.inputItemRef.current
        if (inputItem) {
            const pn = inputItem.parentNode
            const { x: itemX, width, height } = inputItem.getBoundingClientRect()
            return {
                width: pn ? pn.offsetWidth : "auto",
                height: pn ? pn.offsetHeight : "auto",
                transform: `translate(${itemX}px, ${y}px)`,
                backgroundColor: 'white',
                childWidth: width,
                childHeight: height
            }
        }

        const transform = `translate(${x}px, ${y}px)`;
        return {
            transform,
            WebkitTransform: transform
        };
    }

    const { itemType, isDragging, item, initialOffset, currentOffset, } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    function renderItem({ width }, item) {
        switch (itemType) {
            case ItemTypes.DRAGABLE_INPUT:
                return (
                    <div className="drag-layer-item">
                        <div className="layer-item-input-col" style={{ width }}>
                            {item ? item.value : ""}
                        </div>
                        <div className="layer-item-close-col">
                            <IconButton icon="minus" />
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    if (!isDragging) {
        return null
    }

    //计算customDragLayer所在的位置以及宽高
    const { childWidth, childHeight, ...itemStyles } = getItemWrapperStyles(initialOffset, currentOffset, item)

    return (
        <div style={layerStyles} className="custom-drag-layer">
            <div style={itemStyles}>
                {renderItem({ width: childWidth, height: childHeight }, item)}
            </div>
        </div>
    );
};

