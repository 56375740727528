
import React, { useState } from 'react';

import isEqual from 'lodash/isEqual';
import get from "lodash/get"

import Popover from "bwax-ui/components/legacy/Popover";

function getLastUnFixedColIdx(cols) {
    const firstFixedRightCotIdx = cols.findIndex(c => c.fixed && c.fixed === 'right')
    if (firstFixedRightCotIdx === -1) {
        return cols.length - 1
    } else {
        return firstFixedRightCotIdx - 1
    }
}

const hoverColor = "#fcfdfe"; // "#f9fbfc";
const activeColor = "#f7f9fb"; // "#f5f7f8";

export default function TableRow ({
    index,
    style,
    columns,
    dataSource,
    measureRef,
    onEditVisibleChange,
    grabbingColumn,
    virtualized,

    currentHoverRow,

    onItemOut, onItemOver,

}) {
    const hoverStyle = (currentHoverRow === index) ? { backgroundColor: hoverColor } : {}

    if(dataSource.length == 0 ) {
        console.log("TABLE ROW", dataSource);    
    }

    if(dataSource[index] == undefined) {
        console.log("NOT INDEX", dataSource, index);
    }
    
    return (
        <tr
            ref={measureRef}
            onMouseEnter={() => onItemOver && onItemOver(index)}
            onMouseLeave={() => onItemOut && onItemOut(index)}
            style={{ ...style, display: 'flex', ...hoverStyle}} >
            {
                columns.map((col, idx) => {
                    const rowData = dataSource[index]

                    const lastUnFixedColIdx = getLastUnFixedColIdx(columns)
                    const lastUnFixedColStyle = (idx === lastUnFixedColIdx) ? { flexGrow: 1 } : {}

                    const paddingRightStyle = col.align == 'right' ? { paddingRight: 24 } : {}
                    return (
                        <MemoTD
                            key={col.key || col.title}
                            col={col}
                            colIndex={idx}
                            rowIndex={index}
                            rowData={rowData}
                            onEditVisibleChange={(visible) => onEditVisibleChange(visible, index)}
                            virtualized={virtualized}
                            style={{
                                width: grabbingColumn && (col.key || col.title) === grabbingColumn.key ?
                                    grabbingColumn.width : (col.width || 'auto'),
                                flexShrink: 0,
                                textAlign: col.align || 'left',
                                ...paddingRightStyle,
                                ...lastUnFixedColStyle,
                            }}
                        />
                    )
                })
            }
        </tr>
    )
}


const MemoTD = React.memo((props) => (
    <TD {...props} />
), (prevProps, nextProps) => {

    const getValue = props => {
       
        const {
            rowData,
            rowIndex,
            col,
        } = props

        // 应该只检测变化的 rowData 吧
        // return rowData
        return { rowData, rowIndex }

        // const { getValue, key } = col
        // const getValueF = resolveGetValueF(getValue || key);
        // const rawValue = getValueF(rowData, rowIndex);
        // return rawValue
    }

    const prevValue = getValue(prevProps);
    const nextValue = getValue(nextProps);

    const getWidth = props => {
        return props.col.width
    }
    const prevWidth = getWidth(prevProps);
    const nextWidth = getWidth(nextProps);


    return isEqual(prevValue, nextValue) && prevWidth === nextWidth 
        && isEqual(prevProps.style, nextProps.style);

})

const TD = (props) => {

    const {
        rowData,
        col,
        style,
        rowIndex,
        colIndex,
        onEditVisibleChange
    } = props

    const { getValue, transform, renderValue, render, isEditable, renderEdit } = col

    const [currentVisibleKey, setCurrentVisibleKey] = useState(null)
    const [currentCol, setCurrentCol] = useState(null)

    // copied from the old table:
    const getValueF = resolveGetValueF(getValue || col.key)
    const transformF = resolveTransformF(transform)

    const renderF = (record, index) => {
        const rawValue = getValueF(record, index)
        const value = transformF(rawValue)
        if (render) {
            return render(value, record, index)
        } else if (renderValue) {

            // return <div><div><div>HELLO WORLD DUMMY</div></div></div>
            // return "HELLO WORLD DUMMY"
            // console.log(value, record, index, rawValue);

            return renderValue(value, record, index, rawValue) 
        } else {
            return value 
        }
    }
    /// copy ends.

    const renderE = (record, index) => {
        const value = getValueF(record, index)

        // 兼容没有设置 isEditable 函数的版本：
        const editable = (isEditable && isEditable(record)) || isEditable === undefined;

        if (renderEdit && editable) {
            return renderEdit(
                value,
                record,
                index,
                () => {
                    setCurrentVisibleKey(null)
                }
            )
        } else {
            return null
        }
    }

    const isTDActive = currentVisibleKey === col.key
    const editContent = renderE(rowData, rowIndex)
    const activeStyle = { 
        backgroundColor: activeColor, 
        // backgroundColor: "#f9fbfc",
        cursor: "pointer" 
    }

    const tdComp = (
        <td
            onDoubleClick={() => {
                if (editContent !== null) {
                    setCurrentVisibleKey(col.key)
                    onEditVisibleChange(true)
                    setCurrentCol(colIndex)
                }
            }}
            onMouseEnter={() => {
                if (editContent !== null) {
                    setCurrentCol(colIndex)
                }
            }}
            onMouseLeave={() => {
                if (editContent !== null) {
                    setCurrentCol(null)
                }
            }}
            style={{
                ...style,
                // ...hoverStyle,
                ...(currentCol === colIndex || isTDActive ? activeStyle : {})
            }}
            key={col.key || col.title}>
            {
                renderF(rowData, rowIndex)
            }
        </td>
    )

    //这里进入编辑状态才渲染Popover
    if (editContent !== null && isTDActive) {
        return (
            <Popover
                visible={isTDActive}
                key={col.key || col.title}
                content={editContent}
                className="admin-table-td-popover"
                trigger="click"
                onClickOutside={_ => {
                    console.log("Clicked outside");
                    setCurrentVisibleKey(null)
                    onEditVisibleChange(false)                
                }}>
                {tdComp}
            </Popover>
        )
    }

    return tdComp
}


//// data helper - from old table
const resolveGetValueF = (getValue) => {
    return (record, index) => {
        if (typeof (getValue) === 'string') {
            return get(record, getValue, null)
        } else if (typeof (getValue) === 'function') {
            return getValue(record, index)
        } else {
            return null
        }
    }
}

const resolveTransformF = (transform) => {
    return (value) => {
        if (Array.isArray(transform)) {
            return transform.reduce((acc, t) => t(acc), value)
        } else if (typeof (transform) === 'function') {
            return transform(value)
        } else {
            return value
        }
    }

}
