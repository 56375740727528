import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import uniq from 'lodash/uniq';
import get from 'lodash/get';

import admin_widget_helper from './admin_widget_helper.bs'

function getPathsFromConfig(config = {}, getPathsForFunction) {
    // get all possibles:
    const { compose, editingAllowed, applicable, requiredIf, editable, defaultView  } = config;
    return [ compose, editingAllowed, applicable, requiredIf, editable, defaultView ].filter(x => !!x).map(getPathsForFunction).reduce((acc, current) => {
        return [ ...acc, ...current ];
    }, [])
};


export default function getFieldsToUse(entity, { 
    fieldItems, excludedFields = [], allEntities, allDataTypes,
    getPathsForFunction = () => [],
    entity_dict, data_type_dict, dts,
 } = {}) {

    if (fieldItems) {
        return getFieldsFromFieldItems(fieldItems, { 
            dataEntity: entity, allEntities, allDataTypes, getPathsForFunction,
            entity_dict, data_type_dict, dts
        });
    }

    return (
        [...entity.fields, ...entity.virtualFields].filter(
            f => [...(Array.isArray(excludedFields) ? excludedFields : [])].indexOf(f.name) === -1
        ).sort((a, b) => {
            // 给 field 排序, 根据 displayWeight 从大到下排列
            const getWeight = f => f.displayWeight || 0
            return getWeight(b) - getWeight(a)
        }).map(f => packField(f, { isDirectField: true, allEntities, allDataTypes, getPathsForFunction, dataEntity: entity }))
    )
}

export function getField(path, dataEntity, allEntities, allDataTypes) {

    function getTheField(currentPath, entity, filterAllowed, sortingAllowed) {
        const [fname, ...rest] = currentPath;
        if (rest.length === 0) {
            // 最后一节，可以是 virtual field
            const allFields = [...entity.fields, ...entity.virtualFields];
            const field = allFields.find(f => f.name == fname);
            if (field) {
                return {
                    ...field,
                    filterable: filterAllowed && field.filterable,
                    sortable: sortingAllowed && field.sortable,
                }
            } else {
                console.warn("No field found `" + fname + "`", path.join("."))
                return undefined
            }

        } else {
            const dotFields = [...entity.fields, ...entity.virtualFields].filter(
                f => !f.multivalued
            )
            const field = dotFields.find(f => f.name == fname)
            if (!field) {
                console.warn("No field found `" + fname + "`", path.join("."))
                return undefined
            } else {
                return getNextField(rest, field, filterAllowed && field.filterable, sortingAllowed && field.sortable);
            }
        }
    }

    function getNextField(remainingPath, currentField, filterAllowed, sortingAllowed) {

        if (remainingPath.length === 0) {
            return currentField
        } else {
            // the current field must be a link and reference to some other type;
            if (currentField.type == "Link" && currentField.options.entity) {
                //
                let nameOrKey = currentField.options.entity;
                let targetEntity = allEntities.find(e => e.name == nameOrKey || e.key == nameOrKey);

                if (!targetEntity) {
                    // 
                    console.warn("No entity found `" + nameOrKey + "`", path.join("."))
                    return undefined
                } else {
                    return getTheField(remainingPath, targetEntity, filterAllowed, sortingAllowed)
                }
            } else {
                console.warn("Field is not a link", currentField, path.join("."))
                return undefined
            }
        }
    }
    return getTheField(path, dataEntity, true, true);
}

// { cname, name, desc, config, type, options, multivalued, required, filterable, sortable, defaultValue }
export function packField(f, { dataEntity, config = {}, isDirectField = true, allEntities, allDataTypes, getPathsForFunction }) {
    if(!f) {
        return undefined
    }
    const {
        type, options, multivalued, required,
        desc,
        initializable,
        updatable,
        filterable,
        sortable,
        defaultValue,
    } = f;


    const { fieldsToValue } = getFieldDisplay(f);
    const displayFields = fieldsToValue(f, { allEntities, allDataTypes });

    const path = config.path || config.name || f.name;
    const fieldPaths = getFieldPaths(path, config, displayFields, { getPathsForFunction })

    const firstFieldName = path.split(".")[0];
    const baseFields = [
        [ ...dataEntity.fields, ...dataEntity.virtualFields ].find(f => f.name === firstFieldName)
    ].filter(x => !!x);

    return {
        cname: config.name || f.name,
        config: (
            (typeof (config) == "string") ? { path: config } : config
        ),

        name: f.name,
        path: config.path || f.name ,
        desc,

        type, options, multivalued, required,

        initializable: isDirectField && initializable,
        updatable: isDirectField && updatable,
        filterable,
        sortable,

        defaultValue,

        displayFields,
        fieldPaths,

        baseFields
    };
}

function makeUpField(compose, config, { dataEntity, allEntities, allDataTypes, getPathsForFunction, entity_dict, data_type_dict, dts }) {
    // 这里 config 一定是有值的

    const returnType = admin_widget_helper.get_return_type(compose);

    if(returnType) {
        const typeSetting = admin_widget_helper.to_type_metadata(entity_dict, data_type_dict, dts, returnType);

        const { fieldsToValue } = getFieldDisplay(typeSetting);
        const displayFields = fieldsToValue(typeSetting, { allEntities, allDataTypes });

        const { type, options, multivalued, required } = typeSetting;

        const fieldPaths = getPathsFromConfig({ ...config, compose }, getPathsForFunction);

        // baseFields
        const baseFieldNames = fieldPaths.map(path => path.split(".")[0]).filter(x => x !== "id");
        const baseFields = (uniq(baseFieldNames)).map(name => {
            const field = [ ...dataEntity.fields, ...dataEntity.virtualFields ].find(f => f.name === name );
            return field
        });
        
        return {
            cname: config.name,
            config,
    
            name: undefined,
            path: undefined,
            desc: undefined,

            type, options, multivalued, required,

            initializable: false,
            updatable: false,
            filterable: false ,
            sortable: false,
    
            defaultValue: undefined,
    
            displayFields,
            fieldPaths, 
            
            baseFields,
    
        }
    }

    return undefined;


}


function getFieldsFromFieldItems(fieldItems, { 
    dataEntity, allEntities, allDataTypes, getPathsForFunction,
    entity_dict, data_type_dict, dts
}) {

    return fieldItems.map(config => {
        if(config.compose) {
            //  handle compose 
           return makeUpField(config.compose, config, { 
               allEntities, allDataTypes, getPathsForFunction,
               entity_dict, data_type_dict, dts,
               dataEntity,
            })
        } else {

            const p = (typeof (config) == "string") ? config : (config.path || config.name);

            // flat 意味着只有一层，没有往下关联
            const path = p.split(".");
    
            let f = getField(path, dataEntity, allEntities, allDataTypes);
    
            let isDirectField = path.length == 1;
            return packField(f, { dataEntity, config, isDirectField, allEntities, allDataTypes, getPathsForFunction })

        }



    }).filter(x => !!x);
}


// other helpers:

// 只返回该字段的依赖路径，不考虑任何函数配置
export function getPlainFieldPaths (f, { allEntities, allDataTypes }) {
    const { fieldsToValue } = getFieldDisplay(f);
    const displayFields = fieldsToValue(f, { allEntities, allDataTypes });

    const fieldPaths = getFieldPaths(f.name, {}, displayFields, { }); 
    
    return fieldPaths
}

// 返回配置的字段的依赖路径，包括字段本身，以及相关函数的依赖。
function getFieldPaths (path, config, displayFields, { getPathsForFunction = () => [] }) {
    
    const displayValues = Object.values(displayFields);

    function getPaths() {
        if (displayValues.length == 0) {
            return [path]
        } else {
            return displayValues.map(v => path + "." + v)
        }
    }
    function getFunctionPaths () {
        return getPathsFromConfig(config, getPathsForFunction);

    };

    return [
        ...getPaths(),
        ...getFunctionPaths()
    ]
}


export function transformSingleFieldToDisplay (v, displayFields) {
    if (v && displayFields && Object.keys(displayFields).length > 0) {
        return Object.keys(displayFields).reduce((acc, key) => {
            // const name = admin_widget_helper.normalize_field_name(displayFields[key]);

            const path = displayFields[key].split(".").map(admin_widget_helper.normalize_field_name).join(".");  

            return {
                ...acc,
                [key]: get(v, path)
            }
        }, {})
    } else {
        return v
    }
    
}

export function transformForFieldDisplay(record, fields, { entity_dict, data_type_dict, entityName }) {

    /*
    把字段的内容，转换成 display widget 需要的数据：
    把
        { id: "QXV0aEVudGl0eVVzZXI6NA=="
        , 头像:  { expireTime: null, title: "7e715a2e-8204-4147-99e0-705811f5a3a9.jpg"
                , path: "AliOSS://bwax-public:cb/authentityuser/avatar/7e715a2e-8204-4147-99e0-705811f5a3a9.jpg"
                , size: 5678
                , contentType: "image/ico", …
            }
        , 昵称: "Van"
        }
    转换成：
        {
            id: "QXV0aEVudGl0eVVzZXI6NjM="
            avatar: {expireTime: null, title: ...}
            nickName: "Kevin Sun"
        }
    */

    if (!record) {
        return null
    }

    // 
    return fields.reduce((acc, f) => {

        const displays = f.displayFields;

        function transform(v) {
            return transformSingleFieldToDisplay(v, displays);
        }

        function getValue () {

            const { compose } = f.config || {};
            if( compose ) {
                // 调用函数获取结果：
                
                return admin_widget_helper.apply_value_to_js(
                    compose,
                    admin_widget_helper.transform_record(
                        entity_dict, data_type_dict, entityName, record
                    )
                )

            } else {
                const path = f.path || f.name;
                const normalized_path = path.split(".").map(admin_widget_helper.normalize_field_name).join(".");  

                return get(record, normalized_path);
            }

        }

        const originalValue = getValue();

        const value = f.multivalued 
            ? (originalValue ? originalValue.map(transform) : originalValue) 
            : transform(originalValue);


        return {
            ...acc,
            [f.cname || f.name]: value
        }

    }, { id: record.id })

}


/// build selection text 
export function buildSelectionText(entity, paths, { allEntities, allDataTypes }) {

    // 1. build a tree first
    let tree = {};
    paths.forEach(path => {
        const ps = path.split(".");

        let parent = tree;
        ps.forEach(p => {
            // 如果不存在 p 则 insert
            let current = parent[p];
            if (!current) {
                parent[p] = {};
                current = parent[p];
            }
            parent = current;
        })
    })

    // 2 convert the tree to the actual selection
    function toSelection(tree, fields) {

        return Object.keys(tree).reduce((acc, name) => {
            const prefix = acc.length > 0 ? acc + " " : acc;
            if (name == "id") {
                return prefix + "id"
            }
            
            const field = fields.find(f => f.name == name);

            function getSelection() {

                const subTree = tree[name];
                if (Object.keys(subTree).length == 0) {
                    // 考虑图片和文件吗？
                    if (field.type == "Image" || field.type == "File") {
                        const dataType = allDataTypes.find(dt => dt.key == field.type);
                        const fieldKeys = [...dataType.settings.fields, ...dataType.settings.virtualFields].map(f => f.key);
                        return " { " + fieldKeys.join(" ") + " } "
                    } else {
                        return ""
                    }

                } else {

                    function getFields() {
                        if (field.type == "Link") {
                            const nameOrKey = field.options.entity;
                            const linkedEntity = allEntities.find(e => e.name == nameOrKey || e.key == nameOrKey);
                            return [...linkedEntity.fields, ...linkedEntity.virtualFields];
                        } else {
                            const dataType = allDataTypes.find(dt => dt.key == field.type || dt.name == field.type);
                            return [...dataType.settings.fields, ...dataType.settings.virtualFields];
                        }
                    }
                    return " { " + toSelection(subTree, getFields()) + " } "
                }
            }
            return prefix + field.key + getSelection()
        }, "")
    }

    const fields = [...entity.fields, ...entity.virtualFields];

    const selection = toSelection(tree, fields)

    return selection

}

// transform from result (using fieldKey) to record object (using fieldName)
export function transformToRecordObject(node, entity, { allEntities, allDataTypes }) {

    function transformByFields(obj, fields) {
        if (fields.length === 0 || !obj) {
            return obj
        }

        return Object.keys(obj).reduce((acc, key) => {
            const field = fields.find(f => f.key === key);
            if (!field) {
                return {
                    ...acc,
                    [key]: obj[key] // 这种情况下是 id
                }
            } else {
                // 要考虑关联数据实体，和复合数据类型（不包括 Image 和 File)
                function getNextFields() {
                    if (field.type == "Link") {
                        const nameOrKey = field.options.entity;
                        const linkedEntity = allEntities.find(e => e.name == nameOrKey || e.key == nameOrKey);
                        return [...linkedEntity.fields, ...linkedEntity.virtualFields];
                    } else if (field.type !== "Image" && field.type !== "File") {
                        const dataType = allDataTypes.find(dt => dt.name == field.type || dt.key == field.type);
                        return dataType ? [...(dataType.settings?.fields || []), ...(dataType.settings?.virtualFields || [])] : []
                    } else {
                        return []
                    }
                };
                const value = transformByFields(obj[key], getNextFields());
                return {
                    ...acc,
                    [field.name]: value
                }
            }
        }, {});
    }

    return transformByFields(node, [...entity.fields, ...entity.virtualFields])
}


export function getFunctionDependedFieldPaths(f, { entityName, bwax, dts }) {
    const deps = admin_widget_helper.determine_function_dependencies_as_js(
        bwax.entity_dict, bwax.data_type_dict, dts, entityName, f
    );
    return deps.reduce((acc, { tag, entityName: ename, dep }) => {
        if (tag == "Record" && entityName == ename) {
            const [_, paths] = dep;
            return [...acc, ...paths]
        } else {
            return acc
        }
    }, []);
}


export function getFunctionDeps(f, { entityName, bwax, dts }) {
    const deps = admin_widget_helper.determine_function_dependencies_as_js(
        bwax.entity_dict, bwax.data_type_dict, dts, entityName, f
    );
    return deps
    // return deps.reduce((acc, { tag, entityName: ename, dep }) => {
    //     if (tag == "Record" && entityName == ename) {
    //         const [_, paths] = dep;
    //         return [...acc, ...paths]
    //     } else {
    //         return acc
    //     }
    // }, []);
}

