

import React from 'react'
import { getVisibleSelectionRect } from 'draft-js';

import { getSelectedBlock, getBlockRect } from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

import { COMPONENTS } from '../buttons'

const defaultConfig = {
  buttons: ['bold', 'italic', 'underline', 'subscript','color', 'seperator', 'format-clear', 'hyperlink'],
}

const getRelativeParent = (element) => {
  if (!element) {
    return null;
  }

  const position = window.getComputedStyle(element).getPropertyValue('position');
  if (position !== 'static') {
    return element;
  }

  return getRelativeParent(element.parentElement);
};


export default class InlineToolbar extends React.Component {

  constructor() {
    super()
    this.state = {
      isVisible: false,
      position: undefined,
  
      selection: null,
      selectionRect: null,
      /**
       * If this is set, the toolbar will render this instead of the regular
       * structure and will also be shown when the editor loses focus.
       * @type {Component}
       */
      overrideContent: undefined,
  
      forcedVisible: null,
    }
  }

  componentDidMount() {
    const { listenEditorStateChange } = this.props
    if(listenEditorStateChange) {
      this.listener = listenEditorStateChange( (editorState) => {
        this.onSelectionChanged(editorState.getSelection())
      } )
    }
  }

  componentWillUnmount() {
    if(this.listener) this.listener.remove()
  }

  resolvePosition = (selection) => {
    const relativeParent = getRelativeParent(this.toolbar.parentElement);

    // console.log("RelativeParent:", relativeParent)
    // console.log("toolbarHeight:", this.toolbar.clientHeight)

    // const toolbarHeight = this.toolbar.clientHeight;
    const toolbarHeight = 31
    const triangleHeight = 4
    const lineHeight = 8

    const toolbarWidth = this.toolbar.clientWidth;

    const relativeRect = (relativeParent || document.body).getBoundingClientRect();


    const firstBlock = getSelectedBlock(this.getEditorState())


    const selectionRect = getVisibleSelectionRect(window) ||
      (
        firstBlock.getType() === 'atomic' ?
        (
          (firstBlock && getBlockRect(firstBlock.getKey()))
          || this.state.selectionRect
        ) : (
          this.state.selectionRect
          || (firstBlock && getBlockRect(firstBlock.getKey()))
        )
      )

    /// get the first selected block:

    if (!selectionRect ) return {}

    // console.log("relativeRect: ", relativeParent);
    const selectedToTop = selectionRect.top - relativeRect.top

    const top = relativeParent.scrollTop + selectedToTop
    const left =  (selectionRect.left - relativeRect.left) + (selectionRect.width / 2)

    // console.log("TOP, LEFT", top, left, toolbarWidth, relativeRect.top, selectionRect.top)
    const canShowInlineToolbar = selectedToTop >= (toolbarHeight + triangleHeight)

    // console.log("canShowInlineToolbar: ", canShowInlineToolbar);

    const position = {
      top: canShowInlineToolbar ? (top - toolbarHeight - triangleHeight - lineHeight) : (top + selectionRect.height),
      arrowPlacement: canShowInlineToolbar ? 'bottom' : 'up',
      left: (
        left - toolbarWidth / 2.0 < 0 ?
        toolbarWidth / 2.0 + 2 :
        (left + toolbarWidth / 2.0 > relativeRect.width ?
          relativeRect.width - toolbarWidth / 2.0 - 2 :
          left
        )
      ),
    }

    return {
      position,
      selectionRect,
    }
  }


  onSelectionChanged = (selection) => {
    // need to wait a tick for window.getSelection() to be accurate
    // when focusing editor with already present selection

    setTimeout(() => {

      if (!this.toolbar) return;

      const equalsWithoutFocus = (first, second) => {
        if(first === second) {
          return true
        } else if (first && second) {
          return first.merge({ hasFocus:false }).equals(
            second.merge({ hasFocus:false })
          )
        } else {
          return false
        }
      }

      if(equalsWithoutFocus(this.state.selection, selection)) {
        return
      }

      const {position, selectionRect} = this.resolvePosition(selection)

      if(!position) return

      const shouldClearOverride = !(selection && selection.equals(this.state.selection))

      this.setState({
        position,
        selection,
        overrideContent: shouldClearOverride ? null : this.state.overrideContent,
        selectionRect
      });
    });
  };

  getEditorState = () => {
    return this.props.getEditorState()
  }
  setEditorState = (editorState) => {
    return this.props.setEditorState(editorState)
  }

  isVisible() {

    const { overrideContent, position, forcedVisible } = this.state;


    if(forcedVisible === true) {
      return true
    } else if(forcedVisible === false) {
      return false
    }

    const selection = this.getEditorState().getSelection();

    // console.log("selection: ", selection.toJS());

    if(!selection) return false

    const firstBlock = getSelectedBlock(this.getEditorState())

    const isNotCollapsed = !selection.isCollapsed() || (
      firstBlock && firstBlock.getType() == 'atomic'
    )

    // console.log("Is visible", isNotCollapsed, overrideContent)

    const canLiveWithoutFocus = (overrideContent && overrideContent.persistent)
    // const canLiveWithoutFocus = true

    // console.log(isNotCollapsed, selection.getHasFocus(), canLiveWithoutFocus)

    // const isVisible = isNotCollapsed && (
    //   selection.getHasFocus() || canLiveWithoutFocus);
    const isVisible = isNotCollapsed

    return isVisible
  }

  getStyle() {
    const { position } = this.state;

    // overrideContent could for example contain a text input, hence we always show overrideContent
    // TODO: Test readonly mode and possibly set isVisible to false if the editor is readonly

    // const isVisible = (!selection.isCollapsed() && selection.getHasFocus())
    // const isVisible = (!selection.isCollapsed()) || overrideContent;
    // const isVisible = true

    const style = { ...position };

    if (this.isVisible()) {
      style.visibility = 'visible';
      style.transform = 'translate(-50%) scale(1)';
      style.transition = 'transform 0.15s cubic-bezier(.3,1.2,.2,1)';
    } else {
      style.transform = 'translate(-50%) scale(0)';
      style.visibility = 'hidden';
    }

    return style;
  }

  handleToolbarRef = (node) => {
    this.toolbar = node;
  };

  onOverrideContent = (overrideContent) => {

    this.setState({ overrideContent}, () => {
      const {position, selectionRect} = this.resolvePosition(this.getEditorState().getSelection())

      if(!position) return;

      this.setState({
        position, selectionRect
      })
    });
  }

  getPluginButtons = () => {
    const firstBlock = getSelectedBlock(this.getEditorState())

    const {plugins} = this.props

    return (plugins || []).reduce((acc, current) => {
      // return acc
      if(current && current.buttonsForBlock && firstBlock) {
        return [
          ...acc,
          ...(current.buttonsForBlock(firstBlock, {
            getEditorState: this.getEditorState,
            setEditorState: this.setEditorState
          }) || [])
        ]
      } else {
        return acc
      }
    }, [])

  }

  forceVisible = (visible) => {
    this.setState({
      forcedVisible: visible
    })
  }

  preventBubblingAndDefault = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  render() {

    const {updateSupplementClassNames} = this.props
    const {overrideContent, position} = this.state

    const childrenProps = {
      getEditorState: this.getEditorState,
      setEditorState: this.setEditorState,
      onOverrideContent: this.onOverrideContent,
      updateSupplementClassNames,
      forceInlineToolbarVisible: this.forceVisible,
      ...this.props.childrenProps
    }


    const OC = overrideContent && overrideContent.component ? overrideContent.component : overrideContent

    const className = overrideContent && overrideContent.meta && overrideContent.meta.outerClassName ?
      " " + overrideContent.meta.outerClassName : ""


    const config = {...defaultConfig, ...this.props.config}

    const pluginButtons = this.getPluginButtons()

    const components = (
      (pluginButtons && pluginButtons.length > 0) ? pluginButtons : config.buttons
    ).map(c => {
      if(typeof(c) === 'string') {
        return COMPONENTS[c]
      } else {
        return c
      }
    }).filter(x => !!x)

    return (
      <div className={"inline-toolbar " + `${className} ` + `${position ? position.arrowPlacement : 'bottom'}`}
        style={this.getStyle()}
        ref={this.handleToolbarRef} >
        <div className={"toolbar-content" + className} onClick={e => this.preventBubblingAndDefault(e)}>
          {OC ? <OC {...childrenProps} /> : null}
          <div className="editor-button-group" style={(OC ? {display: "none"} : {})}>
            {components.map((Component, i) => (
              <div key={i} onMouseDown={e => e.preventDefault()}>
                <Component {...childrenProps} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
