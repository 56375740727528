
import React, { useState, useEffect, useContext, useRef } from 'react'
import findLastIndex from 'lodash/findLastIndex'


import { HistoryStackContext } from 'Client/js/HistoryStackContext'
const ModalCot = require('Client/re/components/ModalCot.bs.js').make

export default function useModalView(location, history, { position = {} } = {}) {

    const historyStack = useContext(HistoryStackContext)

    /// states
    const [bgLocation, setBgLocation] = useState(location)
    const isInModalJustNow = useRef(false)

    const isBgLocation = location.pathname === bgLocation.pathname
    const isTargetToModal = !!(location.state && location.state.modal)


    const toShowModalCot = /* history.action === 'PUSH' && */ isTargetToModal // && !isBgLocation
    // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/746
    const modalCotVisible = (toShowModalCot || isInModalJustNow.current) && !isBgLocation

    /// 处理 modal link 的逻辑 
    useEffect(() => {
        
        if (toShowModalCot && !isInModalJustNow.current) {
            isInModalJustNow.current = true
        }

        // 在主 screen 上面 navigate
        if (isTargetToModal === false && !isBgLocation && !isInModalJustNow.current) {
            setBgLocation(location)
        }

        // // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/746
        if(isBgLocation) {
            isInModalJustNow.current = false
        }

    }, [location])


    function routeToBgLocation(bgLocation) {
        const { histories, cursor } = historyStack
        const gotoCursor = findLastIndex(histories, location => location.key === bgLocation.key)

        if(gotoCursor == -1) {
            history.push(bgLocation.pathname);
        } else if (gotoCursor !== cursor) {
            history.go(gotoCursor - cursor)
        }
    }

    function isPrevLocationBgLocation() {
        const { histories } = historyStack
        const currentLocationIdx = findLastIndex(histories, history => history.key === location.key )
        const prevLocation = histories[currentLocationIdx - 1]
        return !!prevLocation && prevLocation.key === bgLocation.key
    }

    const withModalView = (renderWithLocation, sideCollapsed) => {

        return (
            <React.Fragment>
                {renderWithLocation(bgLocation)}
                <ModalCot
                    visible={modalCotVisible}
                    sideCollapsed={sideCollapsed}
                    goBack={_ => history.goBack()}
                    prevLocationIsBgLocation={isPrevLocationBgLocation()}
                    position={position}
                    onClose={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        isInModalJustNow.current = false
                        console.log(">>> rotu", bgLocation)
                        routeToBgLocation(bgLocation)
                        

                        // routeTo(`${bgLocation.pathname}`)
                    }} >
                    {renderWithLocation(location)}
                </ModalCot>
            </React.Fragment>
        )
    }

    return { withModalView }
}