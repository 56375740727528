
import React from 'react'

import ModalLink from 'Client/js/components/ModalLink'
import Tag from 'Client/js/ui/widgets/Tag'

import UrlPattern from 'url-pattern'

import './Plain.less'

import { themeColor } from 'bwax-ui/defaultTheme'

/**
 * value - it could be 
 *          1) a string
 *          2) { text, link }
 *          3) an array of string
 *          4) an array of { text, link }
 * 
 * if they are not this type, render them with <pre> and JSON.stringify.
 * 
 * linkPattern - only when there's no innner link it will make sense
 *          it has a :value 
 * 
 * 
 * 2021-08-12: 这个 widget 承载了太多功能了
 * 
 */
export default function Plain(props) {

    // 增加的 sourceApp 是为了在连接中处理应用跳转的情况
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1063
    const { params, sourceApp } = props

    const { value, linkPattern, colors, defaultColor, forwardTo } = params;

    function isText(value) {
        function isTextValue(v) {
            // is String or { text }
            return typeof (v) == 'string' || !!(v && v.text) || (v && v.isText) 
                || (v && v.id && v.link); // 对于那种没有 text 值的 link
        }

        if (Array.isArray(value)) {
            return value.filter(v => !!v).every(isTextValue);
        } else {
            return isTextValue(value);
        }
    };

    if (isText(value)) {
        function prepare(v) {
            if (!v || typeof (v) !== 'object') {
                return {
                    text: v,
                    link: applyPattern(linkPattern, v)
                }
            }
            const { id, text, link } = v
            return {
                text: text || id,
                link: link || applyPattern(linkPattern, text)
            }
        }

        function render() {
            if (Array.isArray(value)) {
                return (value || []).map((v, i) => {
                    const { text, link } = prepare(v)
                    const rendered = renderText(text);
                    if (!rendered) {
                        return null
                    }
                    if (link) {
                        return <ModalLink key={i} to={link} sourceApp={sourceApp}>{rendered}</ModalLink>
                    } else {
                        return (
                            <div key={i} style={{ display: "inline-block" }}>
                                <Tag key={i} style={{ marginBottom: 8, alignSelf: "flex-start" }}>
                                    {rendered}
                                </Tag>
                            </div>
                        )
                    }

                })
            } else {
                const { text, link } = prepare(value)
                const inner = renderText(text, colors, defaultColor)
                return link ? <ModalLink to={link} sourceApp={sourceApp}>{inner}</ModalLink> : inner
            }
        }
        return <div className="admin--display-plain">{render()}</div>
    } else {
        function prune(value) {
            // 把第一级别的 null 过滤掉      
            function pruneFields(value) {
                if(!value || typeof(value) !== 'object') {
                    return value
                }
                return Object.keys(value).reduce((acc, key) => {
                    if (value[key] === null) {
                        return acc
                    } else {
                        return {
                            ...acc,
                            [key]: value[key]
                        }
                    }
                }, {});
            }
            if (Array.isArray(value)) {
                return value.map(pruneFields);
            } else {
                return pruneFields(value)
            }
        }

        function str(value) {
            try {
                return JSON.stringify(value, null, 2)
            } catch (e) {
                return "<无法显示>"
            }
        }

        if (value) {
            return (
                <pre style={{ overflow: "auto", maxHeight: "10rem", fontSize:10 }}> { str(prune(value))} </pre>
            )
        } else {
            return null
        }
    }
}

function renderText(text, colors, defaultColor) {

    function getColor() {
        if (colors && colors[text]) {
            let customColor = colors[text];
            let rawColor = themeColor(undefined, customColor)
            return rawColor
        } else if (defaultColor) {
            return themeColor(undefined, defaultColor)
        } else {
            return undefined
        }
    }
    if (text === undefined || text === null) {
        return null
    }
    let color = getColor();
    if (color) {
        return <span style={{ color }}>{text}</span>;
    } else {
        return text
    }
}


function applyPattern(linkPattern, value) {
    if (!linkPattern) {
        return null
    }
    try {
        const pattern = new UrlPattern(linkPattern)
        return pattern.stringify({ value })
    } catch (e) {
        console.warn('Unrecognized pattern', linkPattern)
        return null
    }

}
