

import React, { useState } from 'react'

import AdminButton from './Button';
import Modal from 'bwax-ui/components/Modal';
import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton';

import classNames from 'classnames';
import { getFileIcon } from "bwax-ui/components/FileIcon";

import Button, { Pressable } from "bwax-ui/components/Button";

import ClipLoader from 'react-spinners/ClipLoader';
import UploadFile from 'bwax-ui/actions/UploadFile';
import { parseString } from 'cron-parser';


export default function ExtActionButton(props) {

    const { buttonConfig, actionConfig, context, dataEntity, reload } = props;

    const Component = {
        "cb_batchUploadProject": BatchUploadProjectButton,
    }[actionConfig[0]];


    if (Component) {
        return (
            <Component {...props} name={actionConfig[0]} />
        )
    }


    return (
        <>
            <Button {...buttonConfig} onClick={_ => {
                alert("未实现")
            }} />
        </>
    )
}


// harcode

function BatchUploadProjectButton(props) {

    const { buttonConfig, actionConfig, context, dataEntity, reload, name } = props;
    

    const { bwax: facade, dts } = context;

    const [selectedFiles, setSelectedFiles] = useState();

    // "pending", number, "uploaded", "adding", "done",  { error: "error msg"};

    // 暂时只使用了: "pending", "removed", "adding", "done", { error: "error msg"};
    const [fileStatus, setFileStatus] = useState({});

    function updateSelectedFiles(files) {
        if (files) {
            setSelectedFiles(files);
            setFileStatus(files.reduce((acc, _, index) => ({ ...acc, [index]: "pending" }), {}));

        } else {
            setSelectedFiles();
            setFileStatus({})
        }
    }
    function updateFileStatus(index, status) {
        setFileStatus(prev => ({ ...prev, [index]: status }))
    }

    async function uploadDocument(file, index) {
        // 1. upload document and add as a project:

        updateFileStatus(index, "adding");
        const [attachment, errors] = await UploadFile({
            file, uploadFor: name, onUploadProgress: evt => {
                // const { loaded, total } = evt;
                // const ctsPercent = parseInt(loaded / total) * 50;
                // console.log(">>> ", evt, ctsPercent);
                // updateFileStatus(index, evt.total)
            }
        })(facade.dlc);

        if (errors || !attachment) {
            // setError(errors || "上传失败");
            // setUploading(false);
            updateFileStatus(index, { error: "上传失败" })
            return
        }

        // add knowledge document.

        // const [doc, error] = await facade.customMutation({
        //     entityName: "预备项目",
        //     interfaceName: "用文件添加",
        //     args: [attachment],
        //     outputFieldPaths: []
        // });

        const [doc, error] = await addProjectUsingFile(attachment, facade)


        if (error) {
            console.log(">>> er", error);
            updateFileStatus(index, { error: error || "添加文档失败" });
            return
        }
        updateFileStatus(index, "done");        
        return doc;
    }

    function renderStatus(index) {
        const status = fileStatus[index];
        if (!status) {
            return null
        }
        // 暂时只支持 adding 和 done 
        if (status == "adding") {
            return (
                <ClipLoader color="var(--gray10)" size={14} />
            )
        } else if (status == "done") {
            return (
                <i className='bx bxs-check-circle text-[var(--grass9)]' ></i>
            )
        } else if (status == "pending") {
            return (
                <Pressable onPress={_ => updateFileStatus(index, "removed")}>
                    <i className='bx bx-trash cursor-pointer text-[var(--gray11)]'></i>
                </Pressable>

            )
        }
    }



    const isUploading = Object.keys(fileStatus).some(k => fileStatus[k] == "adding");

    const pendingFiles = selectedFiles && selectedFiles.filter((f, index) => fileStatus[index] == "pending")

    const hasFileToAdd = pendingFiles && pendingFiles.length > 0

    const isFinished = Object.keys(fileStatus).some(k => fileStatus[k] == "done") && !hasFileToAdd


    function renderFileList() {
        if (!selectedFiles) return <div />;

        // const files = [  ...selectedFiles, ...selectedFiles, ...selectedFiles, ...selectedFiles, ...selectedFiles, ]
        const files = [...selectedFiles];

        return (
            <>
                <div className="px-3 py-4 flex flex-col gap-1 sm:px-6 grow overflow-auto">
                    {files.map((f, index) => {
                        if (fileStatus[index] == "removed") {
                            return null;
                        }

                        const isDone = fileStatus[index] == "done";
                        return (
                            <div key={index} className={
                                classNames(
                                    "flex gap-2 items-center py-2 sm:py-2 px-4 rounded",
                                    {
                                        // "bg-[var(--grass4)]": isDone,
                                        "hover:bg-[var(--gray4)]": !isDone && !isUploading,
                                    }
                                )
                            }>
                                <div className="flex gap-2 items-center grow">
                                    <div className="font-size-16 pt-0.5">{getFileIcon(f)}</div>
                                    {f.name}
                                </div>
                                <div className="pt-0.25">
                                    {renderStatus(index)}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex justify-end px-4 pt-2 pb-6 sm:px-6 gap-3 items-center">
                    <div className="text-[var(--gray10)]">
                        共 {selectedFiles.filter((f, k) => fileStatus[k] !== "removed").length} 个文件
                    </div>
                    {
                        !isFinished ? (
                            <Button appearance="primary" color="grass" isLoading={isUploading} isDisabled={!hasFileToAdd} onPress={async _ => {                            
                                let documents = [];
                                for(let i = 0; i < selectedFiles.length; i++) {
                                    if (fileStatus[i] == "removed") {
                                        // do nothing
                                    } else {    
                                        const doc = await uploadDocument(selectedFiles[i], i);
                                        if(doc) {
                                           documents.push(doc)
                                        }
                                    }                                    
                                }
                                setTimeout(() => {
                                    reload()
                                }, 600)
                            }}>
                                开始上传
                            </Button>
                        ) : (
                            <Button onPress={_ => {
                                updateSelectedFiles()
                            }}>
                                关闭
                            </Button>
                        )
                    }
                </div>
            </>
        )
    }


    return (
        <>
            <SelectFileButton className={"rounded"} asButton={false} size="small"
                multiple
                accept="application/msword, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pptx"
                onSelectFile={files => {
                    updateSelectedFiles(files);
                }}>
                <AdminButton {...buttonConfig} />
            </SelectFileButton>
            {selectedFiles ?
                <Modal isOpen={selectedFiles} className="max-w-2xl"
                    onOpenChange={open => {
                        if (!open) {
                            updateSelectedFiles()
                        }
                    }}
                >
                    {renderFileList()}
                </Modal> : null
            }
        </>
    )
}


async function addProjectUsingFile(attachment, facade) {

    const currentUserId = await facade.getCurrentUserId();
    if(!currentUserId) {
        return [null, "未找到当前用户"]
    }
    const [member, error] = await facade.findOne({
        entityName: "注册会员", condition: [[ { field: "用户.id", op: "eq", value: currentUserId }]]
    });

    const removeExtension = fileName => {
        if(fileName.indexOf(".") !== -1) {
            const parts = fileName.split(".");
            const last = parts[parts.length - 1];
            if(last.length < 5) {
                return parts.slice(0, parts.length - 1).join(".");
            }
        }
        return fileName
    }
    
    if(member) {
        const [ record, error ] = await facade.add({ 
            entityName: "预备项目", 
            formData: {
                名称: removeExtension(attachment.title),
                bp: attachment,
                状态: "0 筛选中",
                上传者: member.id
            }
        })
        return [record, error];
    }

    return [undefined, error];
}