import { resolveFromURL } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'

export default function convertDraftToSlate(draftValue) {

    if (draftValue) {

        const { contentType, content } = resolveContentType(draftValue);
        if (contentType == "DraftJS") {

            const draftContent = JSON.parse(content);
            console.log(draftContent);
            return doConvert(draftContent);

        }
    }


    return [{
        type: 'paragraph',
        children: [{ text: '<Not a Draft value>' }],
    }]


}


// 在这里实现 conversion ()

function doConvert(draftContent) {
    // 根据 block 的不同类型依次转换
    // https://draftjs.org/docs/api-reference-content-block

    // 具体需要注意的：
    // 1. 有些是连续数个“顶层” block 组成的，需要找到毗邻的一组，作为同一个 slate 中的元素的 children
    ///     unordered-list-item, ordered-list-item, blockquote, code
    // 2. 根据 block 里面的 inlineStyleRanges / entityRanges 要转化为切分成不同的 Leaf text
    // ...


    const { blocks, entityMap } = draftContent;
    // 递归的方式收集 blocks 并一一转换

    const nodes = []
    let cursor = 0;

    const typesToGroup = ["unordered-list-item", "ordered-list-item", "code"];

    while (cursor < blocks.length) {
        const block = blocks[cursor];
        if (typesToGroup.indexOf(block.type) !== -1) {
            // 
            let group = [block];
            while (cursor < blocks.length) {
                cursor += 1;
                const nextBlock = blocks[cursor];
                if (nextBlock && nextBlock.type == block.type) {
                    group.push(nextBlock);
                } else {
                    break
                }
            }
            // 处理成组的 blocks
            if (block.type === "unordered-list-item") {
                nodes.push({
                    type: 'bulleted-list',
                    children: group.map(block => convertOneBlock(block, entityMap)),
                })
            } else if (block.type === "ordered-list-item") {
                nodes.push({
                    type: 'numbered-list',
                    children: group.map(block => convertOneBlock(block, entityMap)),
                })
            } else if (block.type === "code") {
                nodes.push({
                    type: "code",
                    children: group.map(block => convertOneBlock(block, entityMap)),
                })
            }
        } else {
            nodes.push(convertOneBlock(block, entityMap));
            cursor += 1;
        }

    }
    //
    return nodes;

}

function convertOneBlock(block, entityMap) {
    // console.log("block: ", block);
    const { data } = block
    const atomicType = {
        IMAGE: "image",
        VIDEO: "video",
        AUDIO: "audio"
    }
    const blockTypeMapping = {
        "header-five": "h5",
        "header-four": "h4",
        "header-three": "h3",
        "header-two": "h2",
        "header-one": "h1",
        "unordered-list-item": "list-item",
        "ordered-list-item": "list-item",
        "blockquote": "block-quote",
        "hr": "hr",
        "code": "paragraph",
        "atomic": data.type ? atomicType[data.type] : "paragraph"
    }
    const type = blockTypeMapping[block.type] || "paragraph";
    const atomicURL = block.data.src ? block.data.src : ""
    const width = type === "image" ? `${block.data.width}%` : "100%"
    const atomicAlign = type === "image" || type === "audio" ? block.data.align : "center"
    const align = data && data.textAlign ? data.textAlign : null
    const hasLink = !!data.link
    const atomicNode = { 
        type, 
        url: resolveFromURL(atomicURL) && resolveFromURL(atomicURL).playURL, 
        children: [{ text: '' }], width, align: atomicAlign 
    }
    const imageNodeWithLink = hasLink ? {
        type: "link",
        url: data.link,
        children: [atomicNode]
    } : atomicNode

    if(type === "image") {
        return imageNodeWithLink
    } else if (type === "video" || type === "audio") {
        return atomicNode
    } else if (type === "list-item") {
        return { type, children: [{ type: "paragraph", children: convertBlockText(block, entityMap), align }]  }
    } else {
        return { type, children: convertBlockText(block, entityMap), align }
    }
        

}


function convertBlockText(block, entityMap) {
    /// 根据 block 里面的 inlineStyleRanges / entityRanges 要转化为切分成不同的 Leaf text
    const { inlineStyleRanges, entityRanges, data } = block;

    if (Object.keys(inlineStyleRanges).length > 0 || Object.keys(entityRanges).length > 0) {

        function getStylesGroups (text, startIndex) {
            let groups = []; // { start, end, features }

            for (let i = block.text.indexOf(text, startIndex); i < block.text.indexOf(text, startIndex) + text.length; i++) {
                const inlineStyles = inlineStyleRanges.filter(r => i >= r.offset && i < r.offset + r.length);
                const features = inlineStyles.map(s => ({ style: s.style }));

                const lastGroup = groups.length > 0 ? groups[groups.length - 1] : undefined;
                if (lastGroup) {
                    // TODO：需要更严谨的相等性检查：
                    if (JSON.stringify(features) == JSON.stringify(lastGroup.features)) {
                        // 直接把 lastGroup 的 end 加 1
                        groups[groups.length - 1] = { ...lastGroup, end: i + 1 };
                    } else {
                        groups.push({ start: i, end: i + 1, features })
                    }

                } else {
                    groups.push({ start: i, end: i + 1, features })
                }
            }

            return groups
        }
        
        function getConvertedTexts (text, startIndex = 0) {
            const groups = getStylesGroups(text, startIndex)
            return groups.map(g => {

                const { start, end, features } = g;
    
                const attrs = features.reduce((acc, current) => {
                    const { style } = current;
                    if (style) {
    
                        const colorPrefix = "color-";
                        if (style.startsWith(colorPrefix)) {
                            const color = style.substring(colorPrefix.length);
                            return {
                                ...acc,
                                color,
                            }
                        } else {
                            const styleMapping = {
                                "ITALIC": { italic: true },
                                "BOLD": { bold: true },
                                "UNDERLINE": { underline: true },
                                "SUPERSCRIPT": { sup: true },
                                "SUBSCRIPT": { sub: true },
                                // "STRIKETHROUGH": { strikethrough: true }
                            }
    
                            return {
                                ...acc,
                                ...(styleMapping[style] || {}),
                            }
                        }
    
                    } else {
                        return acc;
                    }
                }, {})
    
                return {
                    text: block.text.substring(start, end),
                    ...attrs,
                }
            })
        }

        if(entityRanges.length > 0) {
            // 转换 draft 中的行内 link
            let groupsWithLinks = []

            entityRanges.map((er, index)=> {
                if(index === 0) {
                    groupsWithLinks = groupsWithLinks.concat(getConvertedTexts(block.text.slice(0, er.offset)))
                }
                const searchStartIndex = index > 0 ? entityRanges[index - 1].offset + entityRanges[index - 1].length : 0
                groupsWithLinks.push({
                    type: "link",
                    url: entityMap[er.key] ? entityMap[er.key].data.url : null,
                    children: 
                        getConvertedTexts(block.text.slice(er.offset, er.offset + er.length), searchStartIndex)
                })
                if(index < entityRanges.length - 1) {
                    groupsWithLinks = groupsWithLinks.concat(
                        getConvertedTexts(block.text.slice(er.offset + er.length, entityRanges[index + 1].offset), searchStartIndex)
                    )
                }
            })
            const lastEntityRange = entityRanges[entityRanges.length - 1]

            groupsWithLinks = groupsWithLinks.concat(
                getConvertedTexts(
                    block.text.slice(lastEntityRange.offset + lastEntityRange.length), 
                    entityRanges.length > 1 ? entityRanges[entityRanges.length - 2].offset + entityRanges[entityRanges.length - 2].length : 0
                )
            )
            
            return groupsWithLinks
        } else {
            return getConvertedTexts(block.text)
        }

    } else {
        return [{ text: block.text }]
    }


}



////// end 










export function resolveContentType(str) {
    /// the split the content by the first line
    const lineBreakIndex = str.indexOf('\n')
    if (lineBreakIndex === -1) {
        return ({
            contentType: "HTML",
            content: str
        })
    } else {
        const firstLine = str.substring(0, lineBreakIndex)
        let meta = null
        try {
            meta = JSON.parse(firstLine)
        } catch (e) { }

        if (meta !== null) {
            return {
                ...meta,
                content: str.substring(lineBreakIndex + 1)
            }
        } else {
            return ({
                contentType: "HTML",
                content: str
            })
        }
    }
}
