
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { make as ElementRenderer } from "bwax-ui/ml/widget/ElementRenderer.bs";

import { buildSorting, buildCondition } from 'bwax/query/resolveAndRunQuery'

import Pagination from 'Client/js/components/Pagination'

import './RecordListView.less';

import {
    getFunctionDependedFieldPaths,
} from './helpers/getFieldsToUse';

import admin_widget_helper from './helpers/admin_widget_helper.bs'
import invariant from 'invariant';

import Search from './components/Search';

import { FilterButton } from './RecordTable';

import AdminWidget from './AdminWidget';


export default function RecordListView(props) {

    const { context, query_config, viewFn, config } = props;

    const {
        page_id, dts, route_to, bwax, refreshedAt, refreshPage,
        contextRecordId, contextEntityName,

        onMsg,

    } = context;

    const allEntities = bwax.entities;
    const [entityName, _] = query_config;
    const entity = allEntities.find(e => e.name == entityName);


    const { 
        title, description, pageSizeOptions = [10, 20, 50],
        searchFields,
    } = config;

    const [filter, setFilter] = useState({});
    const [searchKeyword, setSearchKeyword] = useState(undefined);

    const [queriedData, setQueriedData] = useState(undefined);
    const [queryCondition, setQueryCondition] = useState(undefined);

    const [records, setRecords] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const defaultPageSize = pageSizeOptions && pageSizeOptions.length > 0 ? pageSizeOptions[0] : 10;

    const [pageSize, setPageSize] = useState(defaultPageSize);
    // const pageSize = 20;
    const offset = currentPage ? (currentPage - 1) * pageSize : 0;


    const fieldPaths = getFunctionDependedFieldPaths(viewFn, { bwax, entityName, dts });


    const isCustomQuery = bwax.isCustomQuery(query_config);

    // 
    async function queryData(options, callbackGuard) {

        const [entityName] = query_config;

        // 支持两种不同的 query_config:
        const allFieldPaths = [...fieldPaths];

        async function doQuery() {
            if (isCustomQuery) {
                // do custom query
                const queryObj = {
                    query_config,
                    pageSize,
                    offset,
                    outputFieldPaths: [
                        [entityName, allFieldPaths]
                    ]
                }

                const [result, error] = await bwax.customQuery(queryObj, options);


                return [result, error];

            } else {
                // do standard query
                const queryObj = {
                    entityName,
                    query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort

                    // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
                    condition: buildCondition(filter),
                    // sort: buildSorting(entity, sortBy),

                    // search 会取代 query_config 里面的
                    search: searchKeyword && searchKeyword.trim().length > 0 ? {
                        keyword: searchKeyword, fields: searchFields
                    } : undefined,

                    // 其他参数
                    pageSize,
                    offset,
                    fieldPaths: allFieldPaths,

                    queryType: "listAll", // 用于 getQueryVars

                };
                const [result, error] = await bwax.listAll(queryObj, options);

                return [result, error]
            }
        }

        const [result, error] = await doQuery();

        // TODO error handling
        if (result) {
            if (callbackGuard === undefined || callbackGuard()) {
                // callback guard is like what stated here 
                // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
                ReactDOM.unstable_batchedUpdates(() => {
                    setQueriedData(result);

                    setRecords(result.data.map(d => {
                        const recordValue = admin_widget_helper.transform_record(
                            bwax.entity_dict, bwax.data_type_dict, entityName, d
                        )
                        return recordValue
                    }))

                    // setQueryCondition(queryVars.q0Condition || []);
                    // setQuerySort(queryVars.q0Sort || []);
                })
            }
        }

    }

    const searchInput = () => (
        <Search
            onKeywordChange={value => {
                setSearchKeyword(value);
                setCurrentPage(1);
            }}
            style={{ width: 108, borderRadius: 8 }}
        />
    );


    // TODO RecordListView 暂时不支持 Filter
    // const filterButton = () => (
    //     <FilterButton
    //         entityName={entityName} fields={fields} filter={filter}
    //         {...{
    //             entityName, fields, filter, contextRecordId, contextEntityName
    //         }}
    //         applyChange={filter => {
    //             ReactDOM.unstable_batchedUpdates(() => {
    //                 setFilter(filter);
    //                 setCurrentPage(1);
    //             })
    //         }}
    //         env={{ allEntities, allDataTypes }}
    //     />
    // )

    function renderRightButtons(){
        return (
            <>
                {isCustomQuery ? null : searchInput()}
            </>
        )
    }


    useEffect(() => {
        let isActive = true;
        queryData({}, () => {     
            return isActive
        } );
        return () => { isActive = false }
    }, [
        bwax, JSON.stringify(query_config), 
        JSON.stringify(filter),
        // JSON.stringify(sortBy),
        searchKeyword, currentPage, pageSize,
        fieldPaths.join(";"),
        refreshedAt
    ]);

    if (queriedData === undefined) {
        return "Loading..."
    } else if (queriedData === null) {
        return "Not found."
    } else {

        return (
            <AdminWidget {...{
                className: "admin--record-list-view", title, description, renderRightButtons
            }}>
                <div className="record-list">
                    {records.map((d, index) => {
                        const element = admin_widget_helper.apply_value_to_element(viewFn, d);
                        invariant(element, "ViewFn should return an elemnt");

                        return (
                            <div className="record-cell" key={queriedData.data[index].id}>
                                <ElementRenderer
                                    {...{
                                        element,
                                        onMsg
                                    }}
                                />
                            </div>
                        )

                    })}
                </div>
                <div className="record-list-footer">
                    <Pagination {...{
                        pageSize,
                        pageSizeOptions,
                        currentPage,
                        totalCount: queriedData.count,
                        onPageSizeChange: ps => {
                            // shouldReload.current = true; // try reload
                            // saveToLocalStorage(`${tableID}.pageSize`, ps + "");
                            ReactDOM.unstable_batchedUpdates(() => {
                                setPageSize(ps)
                                setCurrentPage(1);
                            })
                        },
                        onPageChange: (page, pageSize) => {
                            setCurrentPage(page)
                        }
                    }}
                    />
                </div>
            </AdminWidget>
        )
    }


}


export function create(props) {
    return <RecordListView {...props} />
}
