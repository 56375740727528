
import React, { useEffect, useState } from 'react'
import { ResetIcon } from '@radix-ui/react-icons'

import Popover from 'bwax-ui/components/legacy/Popover'

import { activeStyles } from '../activeStyles'

import './ResetableLabel.less'

export default React.forwardRef(({ allAttributes, label, attributesGroup, attribute, onChange }, ref) => {

    const [isActive, setIsActive] = useState(false)
    const [resetContentVisible, setResetContentVisible] = useState(false)

    useEffect(() => {
        const { lengthAttrs, commonAttrs } = activeStyles[attributesGroup]
        if(typeof(attribute) === "string") {
            if(!!lengthAttrs.find(la => la.attr === attribute)) {
                const findedLengthAttr = lengthAttrs.find(la => la.attr === attribute)
                if(allAttributes[attribute] || allAttributes[attribute] === 0) {
                    setIsActive(!findedLengthAttr.defaultRegx.exec(allAttributes[attribute]))
                } else if(isActive) {
                    setIsActive(false)
                }
            } else if(!!commonAttrs.find(ca => ca === attribute)) {
                setIsActive(!!allAttributes[attribute])
            }
        } else {
            const allActive = attribute.every(attr => {
                if(!!lengthAttrs.find(la => la.attr === attr)) {
                    const findedLengthAttr = lengthAttrs.find(la => la.attr === attr)
                    if(allAttributes[attr]) {
                        return !findedLengthAttr.defaultRegx.exec(allAttributes[attr])
                    } else if(isActive) {
                        return false
                    }
                } else if(!!commonAttrs.find(ca => ca === attr)) {
                    return !!allAttributes[attr]
                }
            })

            setIsActive(allActive)
        }
        
    }, [allAttributes, attribute])

    const labelEl = (
        <div ref={ref} className={`resetableLabel ${isActive ? 'active' : ''}`} onClick={() => setResetContentVisible(!resetContentVisible)}>
            {label}
        </div>
    )
    
    function renderResetContent () {

        function resetAttribute () {
            if(typeof(attribute) === 'string') {
                onChange({ [attribute]: undefined })
            } else {
                let newAttributesObj = {}
                attribute.map(attr => {
                    newAttributesObj = {
                        ...newAttributesObj,
                        [attr]: undefined
                    }
                })
                onChange(newAttributesObj)
            }
            
            setResetContentVisible(false)
        }

        return (
            <div className='reset-container'>
                <div className='icon-box' onClick={() => resetAttribute()}><ResetIcon/></div>
                <div className='tip'>Resetting will revert to initial value.</div>
            </div>
        )
    }

    return isActive ? (
        <Popover content={renderResetContent()} positions={["bottom", "top"]} reposition={true} align={"start"} 
            visible={resetContentVisible} onClickOutside={_ => setResetContentVisible(false)}
        >
            {labelEl}
        </Popover>
       
    ) : labelEl
})
