
import React from 'react'

import ModalLink from 'Client/js/components/ModalLink'
import Copy from 'Client/js/components/Copy'

import UrlPattern from 'url-pattern'

import './ID.less'

export default function ID(props) {
    const { params } = props
    const { value, linkPattern } = params

    if(!value) {
        return null
    }

    const getPattern = () => {
        if(!linkPattern) {
            return null
        }
        try {
            return new UrlPattern(linkPattern)
        } catch(e) {
            console.warn("Incorrect warning", linkPattern)
            return null
        }
    }

    const pattern = getPattern()
    const link = pattern ? pattern.stringify({id: value}) : null

    return (
        <div className="admin--id">
            <div style={{
                flex: "0 0 70%"
            }}>
                <ModalLink to={link}>
                    ...{value.slice(-10)}
                </ModalLink>
            </div>
            <Copy text={value} />
        </div>
    )

}