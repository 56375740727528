import { ConsoleErrorListener } from 'antlr4/error/ErrorListener';
import React from 'react'

import * as ReactDOMServer from 'react-dom/server';

import Typewriter from 'typewriter-effect';

export default function TestTypewriter() {

    const text = ReactDOMServer.renderToString(
        <span style={{ color: "red", fontSize: 20}}>Hello World</span>
    )

    return (
        <div style={{ padding: "1rem", fontSize: 20 }}>
        <Typewriter
            onInit={(typewriter) => {
                console.log("typewriter", typewriter);

                const originalCursorClass = typewriter.state.elements.cursor.className;

                console.log(">>", originalCursorClass);

                typewriter.typeString(text)
                    .callFunction(() => {
                        console.log('String typed out!');
                    })
                    .pauseFor(2500)
                    .deleteAll()
                    .typeString("I 'am here. ")
                    .pasteString("Where are you?")
                    .callFunction(state => {
                        console.log(">>> state", state);

                        state.elements.cursor.style.display = "none"; 

                        console.log('All strings were deleted');

                    })
                    .start();
            }}
        />
        </div>
    )
}
