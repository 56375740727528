

import React, { useState } from 'react'

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import { Modal } from 'antd';

import { untag } from 'bwax/lang/LangHelper'

export default function IOMetaPanel({ioTypeMetas, unpacked}) {

    // 主要是分析 init 和 view 的类型，得到输入和返回值

    if (!ioTypeMetas || !unpacked) {
        return null
    }

    const [_n, _def, dts] = unpacked;

    const {
        initParamTypes: taggedInitParamTypes,
        initParamTypeMetas,
        viewReturnTypes: taggedViewReturnTypes,
        viewReturnTypeMetas
    } = ioTypeMetas;

    const initParamTypes = untag(taggedInitParamTypes);
    const viewReturnTypes = untag(taggedViewReturnTypes);

    const [metaDetailShown, setMetaDetailShown] = useState(false);

    let detailPanel = (
        <div className="metadata-panel detail">
            <div className="section">
                <div className="label-title">输入类型</div>
                <div>
                    {
                        initParamTypes.map((t, i) => {
                            const typeStr = lang_entry_slim.string_of_term(dts, t);
                            const typeMeta = initParamTypeMetas[i];
                            return (
                                <div key={i} className="metadata-row">
                                    <div className="label-cell">{typeStr}</div>
                                    <div className="detail-cell">
                                        <pre> {JSON.stringify(typeMeta, null, 2)} </pre>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="section">
                <div className="label-title">返回类型</div>
                {
                    viewReturnTypes.map((t, i) => {
                        const typeStr = lang_entry_slim.string_of_term(dts, t);
                        const typeMeta = viewReturnTypeMetas[i];
                        return (
                            <div key={i} className="metadata-row">
                                <div className="label-cell">{typeStr}</div>
                                <div className="detail-cell">
                                    <pre> {JSON.stringify(typeMeta, null, 2)} </pre>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

    return (
        <>
            <div className="metadata-panel" onClick={() => {
                setMetaDetailShown(true)
            }}>
                {
                    initParamTypes && initParamTypes.length > 0 ? 
                        (
                            <div className="section">
                                <div className="label-title">输入类型</div>
                                <div>
                                    {
                                        initParamTypes.map((t, i) => {
                                            const typeStr = lang_entry_slim.string_of_term(dts, t);
                                            return (
                                                <div key={i} className="metadata-row">
                                                    { typeStr}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : null
                }
                <div className="section">
                    <div className="label-title">返回类型</div>
                    <div>
                        {
                            viewReturnTypes.map((t, i) => {
                                const typeStr = lang_entry_slim.string_of_term(dts, t);
                                return (
                                    <div key={i} className="metadata-row">
                                        { typeStr}
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
            <Modal title="输入输出类型" open={metaDetailShown} width={700}
                footer={null}
                onCancel={() => setMetaDetailShown(false)}>
                {detailPanel}
            </Modal>
        </>
    )
}

