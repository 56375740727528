

export default function buildHistoryList(entityKey, recordId) {

    const entityName = "数据更改日志"
    return {
        __query__: {
            data: {
                entityName,
                criteria: {
                    "实体KEY": entityKey,
                    "记录ID": recordId,
                },
                pagination: {
                    pageSize: 1000
                },
                type: "listAll",
                sortBy: {
                    创建时间: "DESC",
                }
            },
            __for__: {
                layout: {
                    styles: [
                        ["padding", "1rem"],
                        ["spacing", "1rem"],
                        "scroll"
                    ],
                    content: {
                        type_: "admin::Display_RecordHistory",
                        entityKey,
                        items: {
                            __apply__: {
                                list: `\${data}`,
                                map: {
                                    userName: `\${value.操作人.昵称}`,
                                    userAvatar: `\${value.操作人.头像}`,
                                    content: `\${value.数据}`,
                                    journalType: `\${value.日志类型}`,
                                    time: `\${value.创建时间}`
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}