import Immutable from 'immutable'

export default (config) => {
  return {
    blockRenderMap: Immutable.Map({
      'code': {
        element: 'pre'
      }
    })
  }
}
