// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Plate$BwaxAdmin = require("../../bwax-js/ml/plate.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_entry$BwaxAdmin = require("../../bwax-js/ml/lang/lang_entry.bs.js");
var Widget_env$BwaxAdmin = require("./widget/widget_env.bs.js");
var Lang_builtin$BwaxAdmin = require("../../bwax-js/ml/lang/lang_builtin.bs.js");
var Page_entry_slim$BwaxAdmin = require("./page_entry_slim.bs.js");
var Widget_mod_custom_admin_page$BwaxAdmin = require("./mod/widget/widget_mod_custom_admin_page.bs.js");
var Widget_mod_custom_page_component$BwaxAdmin = require("./mod/widget/widget_mod_custom_page_component.bs.js");

var match = Widget_env$BwaxAdmin.build_widget_base(/* () */0);

var widget_srcs_js = Js_dict.fromList(match[0]);

function build_page_runtime_modules(param) {
  return Plate$BwaxAdmin.List.map(Lang_entry$BwaxAdmin.parse_and_mock_types, Page_entry_slim$BwaxAdmin.page_runtime_modules);
}

function get_custom_admin_page_srcs_js(adminPages) {
  return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  Widget_mod_custom_admin_page$BwaxAdmin.module_name,
                  Widget_mod_custom_admin_page$BwaxAdmin.build_src(adminPages)
                ],
                /* [] */0
              ]));
}

function get_page_component_srcs_js(pageComponents) {
  return Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  Widget_mod_custom_page_component$BwaxAdmin.module_name,
                  Widget_mod_custom_page_component$BwaxAdmin.build_src(pageComponents)
                ],
                /* [] */0
              ]));
}

function prepare_ui_typing_env(param) {
  var ui_modules = Plate$BwaxAdmin.List.assoc_vals(Plate$BwaxAdmin.fst(Widget_env$BwaxAdmin.build_widget_base(/* () */0)));
  var match = Lang_builtin$BwaxAdmin.build_tenv(param[1], param[0], ui_modules);
  return /* tuple */[
          match[0],
          match[1]
        ];
}

function prepare_page_component_typing_env(param, pageComponents) {
  return Widget_mod_custom_page_component$BwaxAdmin.build_typing_env(/* tuple */[
              param[0],
              param[1]
            ], pageComponents);
}

var prepare_custom_admin_page_typing_env = Widget_mod_custom_admin_page$BwaxAdmin.build_typing_env;

var prepare_eval_env = Page_entry_slim$BwaxAdmin.prepare_eval_env;

var page_runtime_modules = Page_entry_slim$BwaxAdmin.page_runtime_modules;

var page_runtime_externals = Page_entry_slim$BwaxAdmin.page_runtime_externals;

exports.widget_srcs_js = widget_srcs_js;
exports.prepare_eval_env = prepare_eval_env;
exports.page_runtime_modules = page_runtime_modules;
exports.page_runtime_externals = page_runtime_externals;
exports.build_page_runtime_modules = build_page_runtime_modules;
exports.get_custom_admin_page_srcs_js = get_custom_admin_page_srcs_js;
exports.get_page_component_srcs_js = get_page_component_srcs_js;
exports.prepare_ui_typing_env = prepare_ui_typing_env;
exports.prepare_page_component_typing_env = prepare_page_component_typing_env;
exports.prepare_custom_admin_page_typing_env = prepare_custom_admin_page_typing_env;
/* match Not a pure module */
