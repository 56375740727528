
import invariant from 'invariant'

export default function evaluateExpression(
        expressionAst, 
        { Operators, Functions, resolveQNameValue }) {

    /// 主要的插件就是
    /// 1）resolveQNameValue
    /// 2) operators
    /// 3) functions

    const { multiplication, addition, comparison, logic } = Operators

    function liftOnlyLhs(ast, nextEvaluation, otherwise) {
        const lhs = ast.children.lhs
        const rhs = ast.children.rhs
        if (!rhs || rhs.length === 0) {
            // lift - 直接只处理唯一操作数
            return nextEvaluation(lhs[0])
        } else {
            const { lhs, rhs, ...operatorDict } = ast.children
            const operator = Object.values(operatorDict)[0] // only one operator
            return otherwise(lhs, rhs, operator)
        }
    }

    function evalMulticationExp(ast) {
        return liftOnlyLhs(ast, evalAtomicExp, (lhs, rhs, operator) => {
            const result = operator.reduce((acc, op, index) => {
                return multiplication[op.tokenType](
                    acc, evalAtomicExp(rhs[index])
                )
            }, evalAtomicExp(lhs[0]))

            if(typeof(result) === 'number') {
                // TODO 未来可能需要优化
                return Math.round(result * 10000) / 10000  // 最多只有四位小数
            } else {
                return result
            }
            
        })
    }

    function evalAdditionExp(ast) {
        return liftOnlyLhs(ast, evalMulticationExp, (lhs, rhs, operator) => {

            const result = operator.reduce((acc, op, index) => {
                return addition[op.tokenType](
                    acc, evalMulticationExp(rhs[index])
                )
            }, evalMulticationExp(lhs[0]))

            if(typeof(result) === 'number') {
                // TODO 未来可能需要优化
                return Math.round(result * 10000) / 10000  // 最多只有四位小数
            } else {
                return result
            }

        })
    }

    function evalComparisonExp(ast) {
        return liftOnlyLhs(ast, evalAdditionExp, (lhs, rhs, operator) => {

            return comparison[operator[0].tokenType](
                evalAdditionExp(lhs[0]),
                evalAdditionExp(rhs[0])
            )

        })
    }


    function evalLogicExp(ast) {
        return liftOnlyLhs(ast, evalBoolExp, (lhs, rhs, operator) => {

            let result = evalBoolExp(lhs[0]) ///
            let index = 0
            // implement shoirt-circuit
            if (operator[0].tokenType === "And") {
                /// AND
                while (index < rhs.length) {
                    if(result === false) {
                        break
                    }
                    // keep computing
                    result = logic.And(result, evalBoolExp(rhs[index]))
                    index += 1
                }
            } else {
                /// OR
                while (index < rhs.length) {
                    if(result === true) {
                        break
                    }
                    // keep computing
                    result = logic.Or(result, evalBoolExp(rhs[index]))
                    index += 1
                }
            }
            return result
        })
    }

    function evalUnaryLogicExp(ast) {

        const operator = ast.children.UnaryLogicOperator[0]
        const operand = ast.children.comparisonExp[0]

        
        if (operator.tokenType === "Not") {
            return logic['Not'](evalComparisonExp(operand))
        } else {
            invariant(false, `Unsupported unary logic operator ${operator.tokenType}`)
        }
    }


    function evalBoolExp(ast) {

        if (ast.children.unaryLogicExp) {

            return evalUnaryLogicExp(ast.children.unaryLogicExp[0])

        } else if (ast.children.comparisonExp) {

            return evalComparisonExp(ast.children.comparisonExp[0])

        } else {
            invariant(false, "logicExp's only child must either be unaryLogicExp or binaryLogicExp")
        }
    }

    /// evalulate atomic !!!
    function evalAtomicExp(ast) {

        if (ast.children.callExp) {
            return evalCallExp(ast.children.callExp[0])

        } else if (ast.children.parenthesisExp) {
            return evalParenthesisExp(ast.children.parenthesisExp[0])

        } else if (ast.children.qualifiedName) {

            return evalQualifiedName(ast.children.qualifiedName[0])

        } else if (ast.children.NumberLiteral) {

            return evalNumberLiteral(ast.children.NumberLiteral[0])

        } else if (ast.children.BoolLiteral) {
            return evalBoolLiteral(ast.children.BoolLiteral[0])
        } else if (ast.children.StringLiteral) {
            return evalStringLiteral(ast.children.StringLiteral[0])

        } else {
            // TODO to support many more:
            console.warn("unsupported exp", ast)
        }
    }



    function evalParenthesisExp(ast) {
        const expression = ast.children.expression[0]
        return evalLogicExp(expression.children.logicExp[0])
    }


    //// leaf evaluation
    function evalCallExp(ast) {
        const func = ast.children.func[0].image

        const args = (ast.children.args || []).map(arg => evalExp(arg))
        const f = Functions[func]
        invariant(f, "unsupported function " + func)
        return f.execute.apply(null, args)

    }

    function evalQualifiedName(ast) {
        return resolveQNameValue(ast.children.Identifier.map(i => i.image))
    }
    function evalBoolLiteral(ast) {
        return ast.image === "true"
    }
    function evalNumberLiteral(ast) {
        /// TODO 暂时都当做 float 且只保留四位小数（为了 === 能够有用）
        return Math.round(parseFloat(ast.image) * 10000) / 10000
    }
    function evalStringLiteral(ast) {
        /// 掐头去尾
        return ast.image.slice(0, ast.image.length - 1).slice(1)
    }
    /// leaf evaluation ends



    function evalExp(ast) {
        if (ast && ast.children && ast.children.logicExp) {
            return evalLogicExp(ast.children.logicExp[0])
        } else {
            return undefined
        }
    }

    return evalExp(expressionAst)

}




//// ===== test =====
function doTheTests() {
    // const p = new ExpressionParser()

    const mockupValue = {
        "产品记录.已购买人数": 10,
        b: null,
        a: "HELLO"
    }

    const resolveQNameValue = (qName) => {
        const join = qName.join(".")
        return mockupValue[join]
    }

    function test(exp) {
        const ExpressionParser = require('./ExpressionParser').ExpressionParser
        const p = new ExpressionParser()

        const Operators = require('./Operators').jsonOps
        const Functions = require('./Functions').default

        const result = evaluateExpression(p.parse(exp), {
            resolveQNameValue, Operators, Functions
        })
        console.log(result, `\t<=\t ${exp}`)

    }

    // console.log("DO THE TEST>>>")

    // test("!true")
    // test("false")
    // test("true && true && false")
    // test("2 == 2.0")
    // test("产品记录.已购买数 > 10 && 2 == 2.0")
    // test("1 < 2")
    // test("2 > 10")
    // test("true || 2 > 10")
    // test("42")
    // test("1 + 2 + 10")
    // test("1 + 2 * 3 * 6 + 10")
    // test("10 - 9")
    // test("0 - 1")
    // test("10 * 12")
    // test("10 / 2")
    // test("10 / 3 + 3")
    // test("10 / (3 + 2)")
    // test("(产品记录.已购买人数 + 1) * 100")
    // test(`"SUCCESS" != null`)

    // test(`5 % 2`)

    // test("inc(1)")
    // test("add(1 + 100, 2)")
    // test("isNull(a)")
    // test("isNull(b)")
    // test("exists(a)")
    // test("exists(b)")

    // test("round(1.2)");

}

doTheTests()
