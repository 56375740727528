import expression_input_helper from 'Client/ml/helpers/expression_input_helper.bs';
import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';

// here are the helper functions for converting expr and values:

// { $expr, $v }
// 
export default function exprToValue(expr, { prevValue }) {
    if (expr) {
        // 绑定 expression
        if (prevValue !== undefined) {
            if (prevValue && prevValue.$expr) {
                return { ...prevValue, $expr: expr }
            } else {
                // save the old value
                return { $expr: expr, $v: prevValue }
            }
        } else {
            return { $expr: expr }
        }
    } else {
        // 解除 expression 绑定
        if (prevValue && prevValue.$v) {
            return prevValue.$v
        } else {
            // save the old value
            return prevValue
        }
    }
}


export function valueToExpr(value, { attribute } ) {
    if(!attribute) { return value + "" };

    const expectedType = fragment_helper.type_meta_to_term(attribute) // { type options required multivalued ... }

    if (value && value.$expr) {
        return value.$expr
    } else if (value !== undefined) {
        // 根据类型决定要不要处理，比如如果是 Text 或者 ShortText 就应该加上 ("")
        const isString = expression_input_helper.is_string_type(expectedType);
        if (isString) {
            return `\"${value}\"`
        } else {
            return value + ""
        }
    } else {
        return undefined;
    }
}


export function valueForCode(value, { attribute }) {
    if(!attribute) { return value };

    const expectedType = fragment_helper.type_meta_to_term(attribute);

    if(value && value.$expr) {
        return `(${value.$expr})`
    } else if (value !== undefined) {
        // 根据类型决定要不要处理，比如如果是 Text 或者 ShortText 就应该加上 ("")
        const isString = expression_input_helper.is_string_type(expectedType);

        if (isString) {
            return `\"${value}\"`
        } else {
            return value
        }
    } else {
        return undefined;
    }
}


export function valueForInput(value, { attribute }) {
    if(!attribute) { return value };

    // 要根据类型来确定 input 显示什么
    if(value && value.$expr) {
        const expectedType = fragment_helper.type_meta_to_term(attribute)
        const isString = expression_input_helper.is_string_type(expectedType);
        if(isString) {
            return `{{${value.$expr}}}`
        } else {
            return value.$expr
        }
        
        
    } else {
        return value
    }

}


export function valueForDisplay(value, { attribute }) {
    if(!attribute) { return value };

    // 要根据类型来确定 display 显示什么

    // 这里可能要用上 example 之类的：

    if(value && value.$expr) {
        const expectedType = fragment_helper.type_meta_to_term(attribute);
        const isString = expression_input_helper.is_string_type(expectedType);
        if(isString) {
            return `{{${value.$expr}}}`
        } else {
            // -- TOOD for number / boolean and other types
            return 
        }        
    } else {
        return value
    }

}