
import { tag, untag, unpack } from 'bwax/lang/LangHelper';

import page_entry_slim from 'bwax-ui/ml/page_entry_slim.bs'

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import pageRuntimeModules from 'bwax-ui/gen/page_runtime_modules.json';

import backendRuntimeModules from 'bwax/gen/backend_runtime_modules.json';

const gmod = untag(pageRuntimeModules);

const backendGmod = untag(backendRuntimeModules);

export default async function getFacadeForPreview({runtimeProfile, dlc, entities, dataTypes, applicationCode }) {

    const BwaxFacade = require("bwax/facade").default;
    function getFacadeProps() {
        const propsDict = {
            "page": {
                isAdmin: false, gmod, prepareEnv: page_entry_slim.prepare_eval_env
            },
            "page-component": {
                isAdmin: false, gmod, prepareEnv: page_entry_slim.prepare_eval_env
            },
            "page-fragment": {
                isAdmin: false, gmod, prepareEnv: page_entry_slim.prepare_eval_env
            },
            "admin-page": {
                isAdmin: true, gmod, prepareEnv: page_entry_slim.prepare_eval_env
            },
            "general-setting": {
                isAdmin: true, gmod, prepareEnv: page_entry_slim.prepare_eval_env
            },
        }
        return propsDict[runtimeProfile.name] || {
            // default
            gmod: backendGmod,
            prepareEnv: (gmods, entity_dict, data_type_dict, _pageComponents, _adminPages) => {
                return lang_entry_slim.prepare_eval_env(gmods, entity_dict, data_type_dict);
            }
        }
    }

    const facade = new BwaxFacade({
        dlc, entities, dataTypes, ...getFacadeProps(),

        applicationCode
    });
    await facade.init();

    // prepare 一些肯定会用到的 entity
    await facade.prepare(["用户", "验证用户", "选项类别", "选项文本值", "数据记录评论", "数据更改日志", runtimeProfile.entityName].filter(x => !!x))

    return facade;
}