


// import './Page.less'

import React, { useEffect } from 'react'

import color from 'bwax-ui/color'


export default function Page(props) {
    const { children, params, theme } = props

    return (
        <div className="bwax-page bw-ui lc-base" key="bwax-page" style={pageStyle}>
            {children}
        </div>
    )
}

/// default style
const pageStyle = {
    color: color('N80')
}
