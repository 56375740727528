
import React, { useEffect, useState } from 'react';

import useDebounce from "bwax-ui/legacy/page/hooks/useDebounce";

import './RecordFilterInput.less';

import RecordSelect from "Client/js/ui/widgets/input/RecordSelect";
import TextAutoComplete from "Client/js/components/TextAutoComplete";
import CustomDateRangePicker from "Client/re/widgets/input/components/CustomDateRangePicker"
import NumberRangeInput from "Client/re/widgets/input/components/NumberRangeInput";

import { make as IconButton } from "Client/re/components/IconButton.bs"

import Toggle from 'rsuite/Toggle';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import InputSelect from 'Client/js/ui/components/InputSelect';

const __NIL__ = "__NIL__";
const __NULL__ = "（空）";
const __NOTNULL__ = "（非空）";

const cleanUpdefined = obj => {
    return Object.keys(obj || {}).reduce((acc, k) => {
        const iv = obj[k];
        return iv === undefined ? acc : { ...acc, [k]: iv }
    }, {});
}



// conditionFields: [ { cname, name, type, multivalued, options }]
export default function RecordFilterInput(props) {


    // console.log("RecordFilter Input", props);

    const { params, value: givenValue, onChange, allEntities, allDataTypes } = props;

    const { conditionFields, entityName, contextEntityName, contextRecordId } = params;

    // make sure the value is not null / undefined
    const value = givenValue || {};

    const buildDefaultOpByField = field => {
        if (!field) return undefined;
        const mapping = [
            // field type, multivalued, op
            ["ShortText", undefined, "like"],
            ["Text", undefined, "like"],
            ["Link", false, "eq"],
            ["Link", true, "contains"],
            ["Select", false, "eq"],
            ["Select", true, "contains"],
        ]
        const found = mapping.find(([type, multivalued]) =>
            (type === field.type) && (multivalued === undefined || multivalued === field.multivalued)
        );
        return found ? found[2] : undefined
    }


    // states:


    // editing states:
    function getSelectedNames (value) {
        const names = Object.keys(value);
        if (names.length > 0) {
            return names
        } else {
            return conditionFields[0] ? [conditionFields[0].name] : []
        }
    }
    function getSelectedOps (value) {
        const names = Object.keys(value);
        if (names.length > 0) {
            return names.map(name => {
                const op = Object.keys((value[name] || {}))[0];
                return [name, op]
            })
        } else {
            const head = conditionFields[0];
            return head ? [[head.name, buildDefaultOpByField(head)]] : []
        }
    }

    const [changedValue, setChangedValue] = useState(__NIL__);
    const [selectedNames, setSelectedNames] = useState(_ => {
        return getSelectedNames(value)
    });
    const [selectedOps, setSelectedOps] = useState(_ => {
        return getSelectedOps(value);
    });
    ////

    //// 只有一个等 editing 完成 90 毫秒后，才 re-evaluate
    const debouncedValue = useDebounce(changedValue, 90);

    useEffect(() => {
        if(givenValue === undefined || givenValue !== changedValue) {
            setChangedValue(givenValue || __NIL__);
            setSelectedNames(getSelectedNames(givenValue || {}))
            setSelectedOps(getSelectedOps(givenValue || {}))
        }

    }, [ givenValue ]);

    useEffect(() => {
        if(debouncedValue !== __NIL__ ) {
            onChange(debouncedValue);
        }
       
    }, [debouncedValue])

    const changeLocalValue = newValue => {
        /// value can only be "JSON", it cannot have things like { "foo": undefined }
        /// thus we need to eliminate the undefined value
        // 不知道这个还有没意义
        const v = cleanUpdefined(newValue);
        setChangedValue(v);
    }

    const getSelectedOpByFieldName = fieldName => {
        const found = selectedOps.find(([name, _op]) => name === fieldName);
        return found ? found[1] : undefined
    }

    const getConditionValue = name => value[name];
    const removeConditionValue = name => {
        const { [name]: _, ...rest } = value;
        return rest
    }
    const updateConditionValue = (name, nv) => ({ ...value, [name]: nv });

    const addFilterCondition = field => {
        if (getConditionValue(field.name)) {
            //  do nothing
        } else {
            setSelectedNames(prev => [...prev, field.name]);
            setSelectedOps(prev => {
                return [...prev, [field.name, buildDefaultOpByField(field)]]
            })
        }
    }

    const delFilterCondition = name => {
        if (selectedNames.indexOf(name) !== -1) {
            /// 如果只有一个，则不需要移除，保留至少一个.
            if (selectedNames.length > 1) {
                setSelectedNames(prev => prev.filter(n => n !== name));
            }
        }
        const newValue = removeConditionValue(name);
        changeLocalValue(newValue);
    }

    const replaceFilterCondition = (newName, oldName) => {
        if (newName !== oldName) {
            setSelectedNames(prev => {
                return prev.map(n => n === oldName ? newName : n)
            });
            setSelectedOps(prev => {
                return prev.map(([name, op]) => {
                    if (name === oldName) {
                        const field = conditionFields.find(f => f.name === newName);
                        return [newName, buildDefaultOpByField(field)]
                    } else {
                        return [name, op]
                    }
                })
            });
            const newValue = removeConditionValue(oldName);
            changeLocalValue(newValue);
        }
    }

    const changeFilterCondition = (name, value) => {
        const newValue = updateConditionValue(name, value);
        changeLocalValue(newValue);
    }

    const remainingFields = conditionFields.filter(f => selectedNames.indexOf(f.name) === -1);

    const renderEditFilterName = field => {
        const options = remainingFields.map(f => {
            const cname = f.cname || f.name;
            return { value: f.name, label: cname };
        })
        return (
            <InputSelect {...{
                value: field.cname || field.name,
                className: "normal-width",
                isClearable: false,
                onChange: n => replaceFilterCondition(n, field.name),
                options,
            }} />
        )
    }

    const onEditChange = (field, selectedOp, v) => {
        const { type, multivalued, name: fieldName } = field;

        //等于非空 == 不等于空， 不等于非空 == 等于空
        const getOpsBySpecifiedOp = (v, op) => {
            if (op === "eq" && v === __NOTNULL__) {
                return ["ne"];
            } else if (op === "ne" && v === __NOTNULL__) {
                return ["eq"];
            } else {
                return [op];
            }
        }

        const getDefaultOp = v => {
            if (multivalued) {
                if (type === "Select") {
                    return {
                        [__NULL__]: ["eq"],
                        [__NOTNULL__]: ["ne"],
                    }[v] || ["contains"];
                } else {
                    return ["contains"];
                }
            } else {
                if (type === "Date" || type === "DateTime" || type === "Number") {
                    return ["gte", "lte"]
                } else {
                    return (v === __NOTNULL__) ? ["ne"] : ["eq"]
                }
            }
        }

        const getOps = (v => {
            if (selectedOp) {
                if (type === "ShortText" || type === "Text") {
                    return getOpsBySpecifiedOp(v, selectedOp);
                } else if (type === "Link") {
                    return getOpsBySpecifiedOp(v && v.id, selectedOp);
                } else {
                    return [selectedOp]
                }
            } else {
                return getDefaultOp(v);
            }
        });
        

        const ops = getOps(v);

        const procValue = v => {
            const valueHasEmptyId = v && (v.id === __NULL__ || v.id === __NOTNULL__);

            const fst = ops && ops[0];
            if (fst === "like") {
                return v ? `%${v}%` : v
            } else if (fst === "ne" && (v === __NOTNULL__ || valueHasEmptyId)) {
                // 这里逻辑有点奇怪啊：估计是跟上面的 “等于非空 == 不等于空， 不等于非空 == 等于空” 联动
                return null
            } else if (fst === "eq" && (v === __NULL__ || valueHasEmptyId)) {
                return null
            } else {
                return v
            }
        };

        const buildValue = (ops, v) => {
            if (ops.length === 2) {
                return cleanUpdefined({
                    [ops[0]]: v[0],
                    [ops[1]]: v[1],
                });
            } else {
                return cleanUpdefined({ [ops[0]]: v });
            }
        }

        const builtValue = buildValue(ops, procValue(v));

        const valueIsNone = v === undefined || v === "__none__";

        const builtValueIsNull = Object.values(builtValue).every(v => v === null);

        //暂时的处理：fieldType为Date、DateTime、Number并且builtValue是null，或者value是undefined，进行removeCondition的操作
        if (builtValueIsNull && (type === "Date" || type === "DateTime" || type === "Number" || valueIsNone)) {
            changeLocalValue(removeConditionValue(fieldName))
        } else {
            changeFilterCondition(fieldName, builtValue);
        }
    }


    const extractValue = conditionValue => {
        //  { eq: v }  => v 
        //  { like: "%s%"} => "s"
        //  { gte: v1, lte: v2 } => [ v1, v2 ]
        //  { gte: v1 } => [ v1, undefined ]
        //  { lte: v2 } => [ undefined, v2 ]
        const cv = conditionValue || {};
        const keys = Object.keys(cv);
        if (keys.length === 2) {
            return [cv["gte"], cv["lte"]];
        } else if (keys.length === 1) {
            const k = keys[0];
            const v = cv[k];
            if (k === "gte") {
                return [v, undefined]
            } else if (k === "lte") {
                return [undefined, v]
            } else if (k === "eq" && v === null) {
                return __NULL__
            } else if (k === "ne" && v === null) {
                return __NOTNULL__
            } else if (k === "like") {
                return v.substring(1, v.length - 1);
            } else {
                return v
            }
        } else {
            return undefined
        }
    };


    const renderEditFilterValue = field => {

        const onValueChange = v => {
            const selectedOp = getSelectedOpByFieldName(field.name);
            onEditChange(field, selectedOp, v);
        }

        const conditionValue = getConditionValue(field.name);

        const { type, required, name: fieldName, options = {} } = field;

        const theValue = extractValue(conditionValue);
        const op = getSelectedOpByFieldName(fieldName);
        const displayEmptyOption = !required && !(op === "like" || op === "contains" || op === "notContains");

        const valueChanger = {
            value: theValue === null ? __NULL__ : theValue,
            onChange: onValueChange,
        }
        const renderTextEdit = () => (
            <TextAutoComplete {...{
                ...valueChanger,
                params: {
                    entityName, field,
                    showEmptyOptions: displayEmptyOption,
                    showValidationOptions: true,
                    contextEntityName, contextRecordId
                },
                allEntities, allDataTypes
            }} />
        )

        const renderSelectEdit = () => {
            const baseOptions = (options.options || []).map(o => ({ value: o.value, label: o.name }));
            const optionsToSelect = required ? baseOptions : [
                ...baseOptions,
                ...(
                    displayEmptyOption ? [
                        { label: "（空）", value: __NULL__ },
                        { label: "（非空）", value: __NOTNULL__ }
                    ] : []
                )
            ];
            return (
                <InputSelect {...{
                    className: "wider-width", isClearable: false,
                    ...valueChanger,
                    options: optionsToSelect
                }} />
            )
        }

        const renderRecordSelect = () => {
            const processedValue = theValue === __NULL__ || theValue === __NOTNULL__ ? { id: theValue, label: theValue } : theValue;

            const entityNameOrKey = options.entity;
            const entity = allEntities.find(e => e.name === entityNameOrKey || e.key === entityNameOrKey);
            if (entity) {
                return (
                    <RecordSelect {...{
                        value: processedValue,
                        onChange: onValueChange,
                        params: {
                            entityName: entity.name,
                            required: true, // not clearable
                            shouldDisplayEmptyOptions: displayEmptyOption,
                        },
                        allEntities,
                        allDataTypes,
                    }} />
                )
            } else {
                return null;
            }
        }

        const renderBoolEdit = () => {
            const checked = theValue;
            return (
                <div style={{ marginLeft: "1rem", fontSize: 10 }}>
                    <Toggle {...{
                        // size: "sm",
                        checked: !!checked,
                        onChange: onValueChange,
                        checkedChildren: <CheckOutlined />,
                        unCheckedChildren: checked === false ? <CloseOutlined /> : null,
                    }} />
                </div>
            )
        }

        const renderDateTimeEdit = (showTime, format, placeholder) => (
            <CustomDateRangePicker {...{
                value: theValue, onChange: onValueChange,
                showTime, placeholder, format,
            }} />
        )

        const renderNumberEdit = () => (
            <NumberRangeInput {...{
                value: theValue, onChange: onValueChange
            }} />
        )

        switch (type) {
            case "ShortText":
            case "Text":
                return renderTextEdit();
            case "Select":
                return renderSelectEdit();
            case "Link":
                return renderRecordSelect();
            case "Boolean":
                return renderBoolEdit();
            case "Date":
                return renderDateTimeEdit(false, "yyyy-MM-dd", ["开始日期", "结束日期"])
            case "DateTime":
                return renderDateTimeEdit(true, "yyyy-MM-dd HH:mm", ["开始时间", "结束时间"])
            case "Number":
                return renderNumberEdit();
            default:
                return null
        }
    }

    const renderAddButton = () => {
        if (remainingFields.length > 0) {
            const first = remainingFields[0];
            return (
                <IconButton {...{
                    color: "N50",
                    icon: "plus",
                    onClick: _ => addFilterCondition(first)
                }} />
            )
        } else {
            return null;
        }
    }

    const renderEditFilterOp = field => {
        const { type, multivalued } = field;

        const ops = (() => {
            if (type === "Link" || type === "Select") {
                return multivalued ? ["contains", "notContains"] : ["eq", "ne"];
            } else if (type === "ShortText" || type === "Text") {
                return multivalued ? ["like", "contains", "notContains"] : ["like", "eq", "ne"];
            } else {
                return []
            }
        })();

        const condition = getConditionValue(field.name);
        const theValue = extractValue(condition);

        if (ops.length > 0) {
            const labels = {
                eq: "等于", ne: "不等于", like: "包含", contains: "包括", notContains: "不包括"
            };
            const options = ops.map(o => ({ value: o, label: labels[o] }));

            return (
                <InputSelect {...{
                    style: { width: "4.5rem" },
                    isClearable: false,
                    value: getSelectedOpByFieldName(field.name),
                    onChange: op => {
                        onEditChange(field, op, theValue);
                        setSelectedOps(prev => (
                            prev.map(([name, existingOp]) => {
                                if (name === field.name) {
                                    return [name, op]
                                } else {
                                    return [name, existingOp]
                                }
                            })
                        ))
                    },
                    options
                }} />
            )

        } else {
            return null
        }
    }

    const renderField = (name, index) => {
        const field = conditionFields.find(f => f.name === name);
        if (!field) {
            return null
        }
        const { type } = field;
        
        const opInputClassName = (() => {
            switch(type) {
                case "Boolean": 
                    return "bool-input-col"
                case "ShortText":
                case "Text":
                case "Link":
                case "Select":
                    return "op-input-col"
                default:
                    return ""
            }
        })();


        const onlyOne = selectedNames.length === 1;

        const closeButton = (<IconButton {...{
            color: "N50", icon: onlyOne ? "close" : "minus",
            onClick: _ => delFilterCondition(field.name)
        }} />)

        return (
            <div key={index} className="filter-item">
                <div className="name-col"> {renderEditFilterName(field)} </div>
                <div className={opInputClassName}>
                    <div className="op-col"> {renderEditFilterOp(field)} </div>
                    <div className="input-col"> {renderEditFilterValue(field)} </div>
                </div>
                <div className="button-col">
                    {index == selectedNames.length - 1 ? renderAddButton() : null}
                    { (!onlyOne || value[name]) ? closeButton : null }
                </div>
            </div>
        )
    }

    const names = selectedNames.length > 0 ? selectedNames : 
        (conditionFields.length > 0 ? [conditionFields[0].name] : [])
    ;

    return (
        <div className="input--record-filter">
            { names.map((name, index) => renderField(name, index))}
        </div>
    )


    


}