
import React from 'react'


import { themeColor } from 'bwax-ui/defaultTheme'

import './Heading.less'

export default function Heading (props) {

    const { params, theme } = props
    const { text, level = 5, color, maxLine } = params

    const element = {
        1: <h1 />,
        2: <h2 />,
        3: <h3 />,
        4: <h4 />,
        5: <h5 />,
        6: <h6 />
    }[level]

    const style = (() => {
        const textColor = themeColor(theme, color || "text");
        const FONT_LEVEL = {
            1: {
                fontSize: 2.25,
                lineHeight: 3
            },
            2: {
                fontSize: 1.5,
                lineHeight: 2
            },
            3: {
                fontSize: 1.25,
                lineHeight: 1.75
            },
            4: {
                fontSize: 1.125,
                lineHeight: 1.5
            },
            5: {
                fontSize: 1,
                lineHeight: 1.5
            },
            6: {
                fontSize: 0.875,
                lineHeight: 1.25
            }
        }

        const font_size = FONT_LEVEL[level] ? FONT_LEVEL[level].fontSize : 1
        const line_height = FONT_LEVEL[level] ? FONT_LEVEL[level].lineHeight : 1.5

        const ellipsisStyle = maxLine ? {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: `${maxLine}`,
            overflow: 'hidden',
            height: `${maxLine * line_height}rem`
        } : {}

        const cssStyles = {
            color: textColor,
            fontSize: `${font_size}rem`,
            lineHeight: `${line_height}rem`,
            ...ellipsisStyle
        }

        return cssStyles
    })()

    return (
        <element.type 
            className="bw-heading" 
            style={{ ...style }}
        >
            { text }
        </element.type>
    )
}