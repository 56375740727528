// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var CanvasBridge = require("./CanvasBridge");

function getImageSize(prim) {
  return CanvasBridge.getImageSize(prim);
}

function drawImage_bridge(prim, prim$1, prim$2) {
  return CanvasBridge.drawImage(prim, prim$1, prim$2);
}

function drawImage(canvasEl, imageUrl, x, y, width, height, sx, sy, swidth, sheight, param) {
  return CanvasBridge.drawImage(canvasEl, imageUrl, {
              x: x,
              y: y,
              width: width,
              height: height,
              sx: sx,
              sy: sy,
              swidth: swidth,
              sheight: sheight
            });
}

exports.getImageSize = getImageSize;
exports.drawImage_bridge = drawImage_bridge;
exports.drawImage = drawImage;
/* ./CanvasBridge Not a pure module */
