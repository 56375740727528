
import addPrefix from 'bwax-ui/addPrefix'

import Message from './Message'
import Prompt from './Prompt'
import BridgeAction from './BridgeAction'


export default addPrefix('admin', {
    Message,
    Prompt,
    BridgeAction,


})