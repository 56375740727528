
import React from 'react';


import ClampedText from 'bwax-ui/components/ClampedText';

export function renderHead(title, description, renderTopRightButtons, shouldDisplay) {
    
    if (title || renderTopRightButtons) {
        return (
            <div className="widget-head topbar" style={
                !shouldDisplay ? { display: "none" } : {}
            }>
                
                <div className="widget-title">
                    <span className="title-line">{ title ? <ClampedText text={title} /> : null }</span>
                    {description ? <span className="description-line">{description}</span> : null}
                </div>
                <div className="top-right-bar"> { renderTopRightButtons ? renderTopRightButtons(): null } </div>
            </div>
        )
    }
    return null
}

export default function AdminWidget ({ className, title, description, rightButtons, renderRightButtons, shouldDisplayHead = true, children}) {

    const withHead = !!(title || rightButtons);

    return (
        <div className={"admin-widget" + (withHead ? " with-head" : "") + (className ? " " + className : "")}>
            { renderHead(title, description, renderRightButtons, shouldDisplayHead) }
            { children }
        </div>
    )

}