
import useDataLoader from "bwax-ui/legacy/store/useDataLoader";

import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery'

export default function useExtensions(queryData = "data") {

    /// 同一个页面不可能出现即是 sandbox 又是 production 的数据
    const key = `extensions-${queryData}-` 

    const { getData } = useDataLoader({ [key]: loadExtensions(queryData, ) })
    const [ data ] = getData(key)

    if(data === undefined) {
        return {} // 还是 undefined ?
    }

    const {  extensions, lookupValueSettings } = data

    return { extensions, lookupValueSettings };
}

export function loadExtensions(queryTarget = "data") {

    return async (env) => {

        let runner = queryTarget === "data" ? runDataQuery : runDefinitionQuery;

        const result = await runner(env)(QueryText)()

        const { extensions } = JSON.parse(result).data.definition;

        const lookupValueSettings = extensions.reduce((acc, c) => {
            if(c.name == '自定义') {
                return [...acc, ...(c.lookupValueSettings || [])]
            }
            return acc;
        }, []);

        return { extensions, lookupValueSettings };
    }
}

const QueryText = `
query  {
    definition {
        extensions {
            name
            key
            lookupValueSettings
            settings
        }
    }
}  
`