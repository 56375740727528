/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import React, { useState } from 'react'
import Palette from 'bwax-ui/color/Palette'
import lightOrDark from 'bwax-ui/color/lightOrDark'

import { Input, Radio } from 'antd'

import tinycolor from 'tinycolor2'

import './ColorPalette.less'

import { copy } from "bwax-ui/ml/FrontEndHelper"

const plt = new Palette()

function renderBaseColor(color, key) {
    const { name, enName, code } = color
    const hexColor = plt.getColor(name)
    const height = 136
    const width = 120
    const innerSize = width
    return (
        <div key={key} style={{
            height, width,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            //justifyContent: "space-between",
            backgroundColor: "white", margin: 8,
        }}>
            <div style={{
                height: innerSize, width: innerSize, backgroundColor: hexColor,
            }}>
            </div>
            <div style={{ padding: '8px 0' }}>{name} / {enName} / {code}</div>
        </div>
    )
}

export default function ColorPalette(props) {

    const [demoColor, setDemoColor] = useState('O50')
    const [lightenRate, setLightenRate] = useState(10)
    const [darkenRate, setDarkenRate] = useState(15)
    const [desatRate, setDesatRate] = useState(30)

    const [moreLightenRate, setMoreLightenRate] = useState(20)

    const [colorType, setColorType] = useState('hsl')

    const renderColorBands = (baseColor, key) => {

        const { prefix, hsv } = baseColor

        let derivedColorNames = []
        for (let i = 1; i <= 10; i++) {
            derivedColorNames.push(prefix + i + '0')
        }

        return (
            <div key={key} style={{
                display: "flex", flexDirection: "column"
            }}>
                {renderBaseColor(baseColor)}
                {
                    derivedColorNames.map((cn, i) => {
                        const color = plt.getColor(cn)
                        const lightText = "#FFFFFF"
                        const darkText = plt.getColor('N80')
                        return (
                            <div className={'derived-color'}
                                key={i}
                                style={{
                                    height: 30, width: 120,
                                    margin: '0 8px',
                                    backgroundColor: color,
                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    cursor: "pointer",
                                }}
                            >
                                {
                                    <div className={'color-name'}
                                        onClick={() => {
                                            copy(color);
                                            setDemoColor(cn)
                                        }}
                                        style={{
                                            color: lightOrDark(color) === 'light' ? darkText : lightText
                                        }}
                                    >{cn} / {color}</div>
                                }
                            </div>
                        )

                    })
                }
            </div>
        )
    }

    const rawColor = plt.getColor(demoColor)

    const lightenColor = rawColor ? tinycolor(rawColor).lighten(lightenRate || 10).toString() : null
    const brightenColor = rawColor ? tinycolor(rawColor).brighten(lightenRate || 10).toString() : null
    const darkenColor = rawColor ? tinycolor(rawColor).darken(darkenRate || 15).toString() : null

    const desatColor = rawColor ? tinycolor(lightenColor).desaturate(desatRate).toString() : null

    const moreLightenColor = rawColor ? tinycolor(rawColor).lighten(moreLightenRate || 30).toString() : null

    function display(color) {
        const c = tinycolor(color)
        if (colorType === 'hsb') {
            return c.toHsvString()
        } else if (colorType === 'hsl') {
            return c.toHslString()
        } else {
            return c.toHexString()
        }

    }

    function getFontColor(color) {
        return lightOrDark(color) === 'light' ? plt.getColor('N80') : '#fff'
    }


    return (
        <div className='admin--color-palette'>
            <div className={'color-palette'} style={{
                display: "flex", flexWrap: "nowrap",
                overflow: 'scroll',
            }}>
                {
                    plt.getBaseColors().map(renderColorBands)
                }
            </div>
            <div className='manipulation-demo'>
                <div className='controls'>
                    <div>
                        <div className='label'>颜色</div>
                        <Input placeholder="输入颜色" value={demoColor} onChange={
                            e => setDemoColor(e.target.value)
                        } />
                    </div>
                    <div>
                        <div className='label'>变亮比例</div>
                        <Input placeholder="输入变亮比例" value={lightenRate} onChange={
                            e => setLightenRate(parseInt(e.target.value || '0'))
                        } />
                    </div>
                    <div>
                        <div className='label'>变暗比例</div>
                        <Input placeholder="输入变暗比例" value={darkenRate} onChange={
                            e => setDarkenRate(parseInt(e.target.value || '0'))
                        } />
                    </div>
                    <div>
                        <div className='label'>降低饱和度比例</div>
                        <Input placeholder="输入降低饱和度比例" value={desatRate} onChange={
                            e => setDesatRate(parseInt(e.target.value || '0'))
                        } />
                    </div>
                    <div>
                        <div className='label'>更亮比例</div>
                        <Input placeholder="输入更亮比例" value={moreLightenRate} onChange={
                            e => setMoreLightenRate(parseInt(e.target.value) || '0')
                        } />
                    </div>
                    <div>
                        <div className='label'>选择显示方式</div>
                        <Radio.Group onChange={e => setColorType(e.target.value)} value={colorType}>
                            <Radio.Button value="hsb">HSB</Radio.Button>
                            <Radio.Button value="hsl">HSL</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                <div className='demo'>
                    <div className='label'>原色、lighten、brighten、darken</div>
                    <div className='demo-value'>
                        <div style={{
                            backgroundColor: rawColor || 'none',
                            color: getFontColor(rawColor)
                        }}>
                            {display(rawColor)}
                        </div>
                        <div style={{
                            backgroundColor: lightenColor || 'none',
                            color: getFontColor(lightenColor)
                        }}>
                            {display(lightenColor)}
                        </div>
                        <div style={{
                            backgroundColor: brightenColor || 'none',
                            color: getFontColor(brightenColor)
                        }}>
                            {display(brightenColor)}
                        </div>
                        <div style={{
                            backgroundColor: darkenColor || 'none',
                            color: getFontColor(darkenColor)
                        }}>
                            {display(darkenColor)}
                        </div>
                        <div style={{
                            backgroundColor: moreLightenColor || 'none',
                            color: getFontColor(moreLightenColor)
                        }}>
                            {display(moreLightenColor)}
                        </div>
                    </div>
                </div>
                <div className='demo'>
                    <div className='label'>lighten/brighten 的按钮，以及 desaturated 状态  </div>
                    <div className='demo-value'>
                        <button css={css`
                                background-color: ${rawColor};
                                &:hover {
                                    background-color: ${lightenColor};
                                }
                                &:active {
                                    background-color: ${darkenColor};
                                }
                            `}>
                        </button>
                        <button css={css`
                                background-color: ${rawColor};
                                &:hover {
                                    background-color: ${brightenColor};
                                }
                                &:active {
                                    background-color: ${darkenColor};
                                }
                            `}>
                        </button>
                        <button css={css`
                                background-color: ${desatColor};
                            `}>
                        </button>
                        <button css={css`
                                background-color: ${moreLightenColor};
                            `}>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}