

import React from 'react'

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import FormData from './FormData';

import Modal from 'Client/ml/ui/components/Modal';
import Button from 'Client/ml/ui/components/Button';

import { IconDelete } from '@arco-design/web-react/icon';

import IconButton from 'Client/js/components/IconButton';
import Message from 'Client/js/ui/Message';

import "./FormDataEdit.less";

// it is a modal
export default function FormDataEdit(props) {

    const { form, visible, setVisible, facade, onUpdated, record, usedValues, onDeleted } = props;

    const fieldsToUse = form.字段;
    const allKeys = fieldsToUse.map(f => f.name);

    const initialValues = fieldsToUse.reduce((acc, f) => {
        return {
            ...acc,
            [f.name]: record[f.key]
        }
    }, {});

    const fixedValues = {};
    
    // --- 初始化 Editing
    const {
        // errors,
        validated,
        editing,
        // lastSaved,
        dirtyValues,
        updateEditing,
        // markSaved,
        rollback,
        // clearEditing,
        reset
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, {}, [], "no-key", false)

    const involvedEditKeys = fieldsToUse.filter(
        /// TODO 关于 fixedValue 的处理，应该做一个更好的抽象：
        /// 目前，fixedValue 在如下四个地方皆有处理：
        ///     1. 外部检测 dirtyValue
        ///     2. useEditingValue
        f => fixedValues[f.name] === undefined
    ).map(f => f.name)

    const hasDirtyValue = involvedEditKeys.some(key => {
        return dirtyValues[key] !== undefined
    });

    const cancelButton = {
        key: "cancel",
        label: (hasDirtyValue ? "撤销" : "关闭"),
        onClick: _ => {
            if (hasDirtyValue) {
                rollback(involvedEditKeys);
            } else {
                setVisible(false);
            }
        }
    };

    const confirmButton = {
        key: "confirm",
        label: "保存",
        buttonType: "primary",
        disabled: !hasDirtyValue,
        onClick: async _ => {
            if (hasDirtyValue) {
                const formData = fieldsToUse.reduce((acc, f) => {
                    const { name, key } = f
                    const v = dirtyValues[name] // -- || initialValues[name];
                    return {
                        ...acc,
                        ...(v !== undefined ? { [key]: v } : {})
                    } 
                }, {});
                const mutationObj = {
                    entityName: "表单数据记录",
                    formData: {
                        表单: form.id,
                        数据: formData
                    },
                    id: record.key,
                    fieldPaths: []
                }

                const [result, error] = await facade.update(mutationObj, {
                    // additionalQueryParams: {
                    //     formId: form.id
                    // }
                });
                if(onUpdated) { onUpdated() }
                setVisible(false);
            }
        }
    }

    const editButton = (
        <IconButton {...{
            icon: <IconDelete />,
            confirm: {
                title: "确定要删除?"
            },
            onClick: async _ => {
                const [result, error] = await facade.delete({
                    entityName: "表单数据记录",
                    id: record.key
                })
                if (!error) {
                    Message.info("已删除")
                    if(onDeleted) {
                        onDeleted()
                    };
                    setVisible(false)
                }
            }
        }} />
    )

    const footer = (
        <div style={{ display: "flex", justifyContent: "space-between"}}>
            <div>
                { editButton }
            </div>
            <div className="button-group"> 
                {[cancelButton, confirmButton].map(params => <Button {...params} />) }
            </div>
        </div>

    )

    return (
        <Modal visible={visible} title="编辑数据" footer={footer} destroyOnClose={true}
            className="lc-form-data-edit-modal"
            onCancel={() => {
                // 如果 dirty 的话，需要提示：
                if (hasDirtyValue) {
                    Modal.confirm({
                        title: '您有未保存的内容',
                        content: '确定要关闭吗？',
                        okText: '关闭',
                        cancelText: '等等',
                        className: "admin--edit-modal-confirm",
                        onOk: () => {
                            rollback(involvedEditKeys);
                            setVisible(false);
                        }
                    });
                } else {
                    setVisible(false)
                }
            }}>
            <div className="lc-form-data-edit">
                <FormData {...{
                    form, editing, updateEditing, validated,
                    initialValues, fixedValues, facade,
                    usedValues,
                }} />
            </div>
        </Modal>
    )
}


