// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../../../lib/bwax-js/ml/utils/json.bs.js");
var Plate$BwaxAdmin = require("../../../../lib/bwax-js/ml/plate.bs.js");
var BaseUI$BwaxAdmin = require("../../../../lib/bwax-ui/re/BaseUI.bs.js");
var JsonUtils$BwaxAdmin = require("../../../../lib/bwax-js/re/utils/JsonUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

function Input_RolePermission(Props) {
  Props.params;
  var value = Props.value;
  var onChange = Props.onChange;
  var allEntities = Props.allEntities;
  var theme = Props.theme;
  var match = React.useState((function () {
          return ;
        }));
  var setSelectedEntity = match[1];
  var selectedEntity = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setSelectedPermission = match$1[1];
  var currentEntity = Plate$BwaxAdmin.List.find((function (e) {
          return e.name === selectedEntity;
        }), Curry._1(Plate$BwaxAdmin.List.from_array, allEntities));
  var interfaces = currentEntity !== undefined ? Plate$BwaxAdmin.List.map((function ($$interface) {
            return /* record */Caml_chrome_debugger.record([
                      "value",
                      "label"
                    ], [
                      $$interface.name,
                      $$interface.name
                    ]);
          }), Curry._1(Plate$BwaxAdmin.List.from_array, Caml_option.valFromOption(currentEntity).interfaces)) : /* [] */0;
  var permissions_000 = /* record */Caml_chrome_debugger.record([
      "value",
      "label"
    ], [
      "*",
      "*"
    ]);
  var permissions_001 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* record */Caml_chrome_debugger.record([
          "value",
          "label"
        ], [
          "单个查询",
          "单个查询"
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* record */Caml_chrome_debugger.record([
              "value",
              "label"
            ], [
              "列表查询",
              "列表查询"
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* record */Caml_chrome_debugger.record([
                  "value",
                  "label"
                ], [
                  "添加",
                  "添加"
                ]),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* record */Caml_chrome_debugger.record([
                      "value",
                      "label"
                    ], [
                      "修改",
                      "修改"
                    ]),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* record */Caml_chrome_debugger.record([
                          "value",
                          "label"
                        ], [
                          "删除",
                          "删除"
                        ]),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* record */Caml_chrome_debugger.record([
                              "value",
                              "label"
                            ], [
                              "导出",
                              "导出"
                            ]),
                          interfaces
                        ])
                    ])
                ])
            ])
        ])
    ]);
  var permissions = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      permissions_000,
      permissions_001
    ]);
  var covertEntitiesList = Plate$BwaxAdmin.List.map((function (entity) {
          return entity.name;
        }), Curry._1(Plate$BwaxAdmin.List.from_array, allEntities));
  var renderEntities = Plate$BwaxAdmin.List.map((function (entity) {
          var match = Json$BwaxAdmin.get_field(entity, value);
          var match$1 = entity === selectedEntity;
          var partial_arg = Plate$BwaxAdmin.List.append(match !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
                        222776981,
                        /* divide */1058156409
                      ]),
                    /* [] */0
                  ]) : /* [] */0, Plate$BwaxAdmin.List.append(match$1 ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* `backgroundColor */Caml_chrome_debugger.polyVar("backgroundColor", [
                            222776981,
                            /* N40 */3890506
                          ]),
                        /* [] */0
                      ]) : /* [] */0, /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* `width */Caml_chrome_debugger.polyVar("width", [
                          -899500538,
                          /* fill */-1011102077
                        ]),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* `padding */Caml_chrome_debugger.polyVar("padding", [
                              -797091375,
                              /* `rem */Caml_chrome_debugger.polyVar("rem", [
                                  5691738,
                                  0.5
                                ])
                            ]),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* pointer */-786317123,
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  /* `onClick */Caml_chrome_debugger.polyVar("onClick", [
                                      -560194903,
                                      (function (param) {
                                          Curry._1(setSelectedEntity, (function (param) {
                                                  return entity;
                                                }));
                                          return Curry._1(setSelectedPermission, (function (param) {
                                                        var ops = JsonUtils$BwaxAdmin.valueOfPath(Caml_option.some(value), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                entity,
                                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                    "ops",
                                                                    /* [] */0
                                                                  ])
                                                              ]), Js_json.decodeArray);
                                                        if (ops !== undefined) {
                                                          return Curry._1(Plate$BwaxAdmin.List.from_array, $$Array.map((function (op) {
                                                                            return Plate$BwaxAdmin.$$Option.with_default("", Js_json.decodeString(op));
                                                                          }), ops));
                                                        } else {
                                                          return /* [] */0;
                                                        }
                                                      }));
                                        })
                                    ]),
                                  /* [] */0
                                ])
                            ])
                        ])
                    ])));
          return (function (param, param$1, param$2, param$3) {
              return BaseUI$BwaxAdmin.text(partial_arg, entity, param, param$1, param$2, param$3);
            });
        }), Plate$BwaxAdmin.List.append(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              "*",
              /* [] */0
            ]), Plate$BwaxAdmin.List.rev(covertEntitiesList)));
  var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `width */Caml_chrome_debugger.polyVar("width", [
          -899500538,
          /* fill */-1011102077
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `height */Caml_chrome_debugger.polyVar("height", [
              38537191,
              /* fill */-1011102077
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* scrollY */819475372,
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
                      -690668329,
                      /* divide */1058156409
                    ]),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* `borderWidthEach */Caml_chrome_debugger.polyVar("borderWidthEach", [
                          -34049893,
                          /* record */Caml_chrome_debugger.record([
                              "top",
                              "right",
                              "bottom",
                              "left"
                            ], [
                              /* zero */-789508312,
                              /* `px */Caml_chrome_debugger.polyVar("px", [
                                  25096,
                                  1
                                ]),
                              /* zero */-789508312,
                              /* zero */-789508312
                            ])
                        ]),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                              297481091,
                              /* `rem */Caml_chrome_debugger.polyVar("rem", [
                                  5691738,
                                  0.5
                                ])
                            ]),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              /* `padding */Caml_chrome_debugger.polyVar("padding", [
                                  -797091375,
                                  /* `rem */Caml_chrome_debugger.polyVar("rem", [
                                      5691738,
                                      1.0
                                    ])
                                ]),
                              /* [] */0
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]);
  var tmp;
  if (selectedEntity !== undefined) {
    var config_001 = /* selected */match$1[0];
    var config_002 = function (permissions) {
      var permissions$1 = permissions;
      Curry._1(setSelectedPermission, (function (param) {
              return permissions$1;
            }));
      var tmp;
      if (Plate$BwaxAdmin.List.length(permissions$1) === 0) {
        tmp = selectedEntity !== undefined ? Json$BwaxAdmin.delete_from_object(selectedEntity, Caml_option.some(value)) : value;
      } else {
        var entityStr = Plate$BwaxAdmin.$$Option.with_default("", selectedEntity);
        var match = Json$BwaxAdmin.get_field(entityStr, value);
        if (match !== undefined) {
          var updatedEntity = Json$BwaxAdmin.create_or_insert("ops", Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (prim) {
                          return prim;
                        }), permissions$1)), Caml_option.some(Caml_option.valFromOption(match)));
          tmp = Json$BwaxAdmin.create_or_insert(entityStr, updatedEntity, Caml_option.some(value));
        } else {
          var updatedEntity$1 = Json$BwaxAdmin.create_or_insert("ops", Curry._1(Plate$BwaxAdmin.List.to_array, Plate$BwaxAdmin.List.map((function (prim) {
                          return prim;
                        }), permissions$1)), undefined);
          tmp = Json$BwaxAdmin.create_or_insert(entityStr, updatedEntity$1, Caml_option.some(value));
        }
      }
      return Curry._1(onChange, tmp);
    };
    var config_005 = /* color *//* B60 */3294204;
    var config = /* record */Caml_chrome_debugger.record([
        "align",
        "selected",
        "onChange",
        "options",
        "disabled",
        "color"
      ], [
        -963948842,
        config_001,
        config_002,
        permissions,
        false,
        config_005
      ]);
    var partial_arg$1 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `width */Caml_chrome_debugger.polyVar("width", [
            -899500538,
            /* fill */-1011102077
          ]),
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* `padding */Caml_chrome_debugger.polyVar("padding", [
                -797091375,
                /* `rem */Caml_chrome_debugger.polyVar("rem", [
                    5691738,
                    0.5
                  ])
              ]),
            /* [] */0
          ])
      ]);
    var partial_arg$2 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        /* `width */Caml_chrome_debugger.polyVar("width", [
            -899500538,
            /* fill */-1011102077
          ]),
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            /* `spacing */Caml_chrome_debugger.polyVar("spacing", [
                297481091,
                /* zero */-789508312
              ]),
            /* [] */0
          ])
      ]);
    tmp = (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxAdmin.inputCheckboxes(partial_arg$2, partial_arg$1, config, param, param$1, param$2, param$3);
      });
  } else {
    tmp = BaseUI$BwaxAdmin.empty;
  }
  var partial_arg$3 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      tmp,
      /* [] */0
    ]);
  var partial_arg$4 = /* :: */Caml_chrome_debugger.simpleVariant("::", [
      /* `width */Caml_chrome_debugger.polyVar("width", [
          -899500538,
          /* fill */-1011102077
        ]),
      /* :: */Caml_chrome_debugger.simpleVariant("::", [
          /* `height */Caml_chrome_debugger.polyVar("height", [
              38537191,
              /* fill */-1011102077
            ]),
          /* :: */Caml_chrome_debugger.simpleVariant("::", [
              /* scrollY */819475372,
              /* [] */0
            ])
        ])
    ]);
  return BaseUI$BwaxAdmin.row(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* `width */Caml_chrome_debugger.polyVar("width", [
                    -899500538,
                    /* fill */-1011102077
                  ]),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* `height */Caml_chrome_debugger.polyVar("height", [
                        38537191,
                        /* `rem */Caml_chrome_debugger.polyVar("rem", [
                            5691738,
                            30.0
                          ])
                      ]),
                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                        /* `borderWidth */Caml_chrome_debugger.polyVar("borderWidth", [
                            -690257542,
                            /* `px */Caml_chrome_debugger.polyVar("px", [
                                25096,
                                1
                              ])
                          ]),
                        /* :: */Caml_chrome_debugger.simpleVariant("::", [
                            /* `borderColor */Caml_chrome_debugger.polyVar("borderColor", [
                                -690668329,
                                /* divide */1058156409
                              ]),
                            /* [] */0
                          ])
                      ])
                  ])
              ]), /* :: */Caml_chrome_debugger.simpleVariant("::", [
                (function (param, param$1, param$2, param$3) {
                    return BaseUI$BwaxAdmin.column(partial_arg, renderEntities, param, param$1, param$2, param$3);
                  }),
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    (function (param, param$1, param$2, param$3) {
                        return BaseUI$BwaxAdmin.column(partial_arg$4, partial_arg$3, param, param$1, param$2, param$3);
                      }),
                    /* [] */0
                  ])
              ]), theme, /* column */-963948842, /* [] */0, "no-key");
}

var List = 0;

var $$Option = 0;

var make = Input_RolePermission;

exports.List = List;
exports.$$Option = $$Option;
exports.make = make;
/* react Not a pure module */
