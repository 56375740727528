// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Dict$BwaxAdmin = require("../dict.bs.js");
var Plate$BwaxAdmin = require("../plate.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxAdmin = require("./lang_eval.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Lang_typing$BwaxAdmin = require("./lang_typing.bs.js");
var Performance$BwaxAdmin = require("../performance.bs.js");
var Lang_typing_base$BwaxAdmin = require("./lang_typing_base.bs.js");
var Lang_parser_lezer$BwaxAdmin = require("./lang_parser_lezer.bs.js");

function type_defs_and_acc(base_dts, base_tenv, param) {
  var n = param[0];
  var next_int = Lang_typing_base$BwaxAdmin.make_counter(/* () */0);
  var match;
  try {
    match = Lang_typing$BwaxAdmin.type_defs(next_int, base_tenv, base_dts, Dict$BwaxAdmin.$$String.empty(/* () */0), /* [] */0, /* tuple */[
          n,
          param[1]
        ]);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.log("Error occurs when typing:", n, e);
    throw e;
  }
  var full_dts = Pervasives.$at(match[1], base_dts);
  return /* tuple */[
          n,
          full_dts,
          match[0],
          match[3]
        ];
}

function parse_and_type_defs(base_dts, base_tenv, m_src) {
  var match = Plate$BwaxAdmin.fst(Lang_parser_lezer$BwaxAdmin.parse(m_src));
  if (match !== undefined) {
    return type_defs_and_acc(base_dts, base_tenv, match[0]);
  } else {
    console.log("Failed parsing", m_src);
    throw [
          Plate$BwaxAdmin.Unexpected,
          "Not parsed"
        ];
  }
}

function build_tenv(base_dts, base_tenv, raw_modules) {
  var match = Plate$BwaxAdmin.List.foldr((function (module_src, param) {
          var acc_env = param[0];
          var match = parse_and_type_defs(param[1], acc_env, module_src);
          var next_typed_modules_000 = /* tuple */[
            match[0],
            match[3]
          ];
          var next_typed_modules_001 = param[2];
          var next_typed_modules = /* :: */Caml_chrome_debugger.simpleVariant("::", [
              next_typed_modules_000,
              next_typed_modules_001
            ]);
          var next_env = Dict$BwaxAdmin.$$String.union(match[2], acc_env);
          return /* tuple */[
                  next_env,
                  match[1],
                  next_typed_modules
                ];
        }), Plate$BwaxAdmin.List.rev(raw_modules), /* tuple */[
        base_tenv,
        base_dts,
        /* [] */0
      ]);
  return /* tuple */[
          match[0],
          match[1],
          Plate$BwaxAdmin.List.rev(match[2])
        ];
}

function build_external_env(module_name, externals) {
  return Dict$BwaxAdmin.$$String.from_list(Plate$BwaxAdmin.List.assoc_map((function (prim) {
                    return /* record */Caml_chrome_debugger.record(["contents"], [prim]);
                  }), Plate$BwaxAdmin.List.map((function (param) {
                        var k = param[0];
                        var nk = module_name !== undefined ? module_name + ("." + k) : k;
                        return /* tuple */[
                                nk,
                                /* tuple */[
                                  param[1],
                                  undefined
                                ]
                              ];
                      }), externals)));
}

function build_env(base_env, modules, externals) {
  var external_env = Plate$BwaxAdmin.List.foldl((function (acc_env, param) {
          return Dict$BwaxAdmin.$$String.union(build_external_env(param[0], param[1]), acc_env);
        }), base_env, externals);
  var iter = function (acc_env, param) {
    return Lang_eval$BwaxAdmin.evaluate_defs(acc_env, /* tuple */[
                  param[0],
                  param[1]
                ])[0];
  };
  return Plate$BwaxAdmin.List.foldl(iter, external_env, modules);
}

function build_gtenv(modules) {
  var t0 = Performance$BwaxAdmin.now(/* () */0);
  var match = build_tenv(Lang_typing_base$BwaxAdmin.builtin_dts, Dict$BwaxAdmin.$$String.empty(/* () */0), modules);
  var t1 = Performance$BwaxAdmin.now(/* () */0);
  console.log("GTENV used", t1 - t0);
  return /* tuple */[
          match[0],
          match[1],
          match[2]
        ];
}

function build_genv(modules, externals) {
  var t0 = Performance$BwaxAdmin.now(/* () */0);
  var env = build_env(Dict$BwaxAdmin.$$String.empty(/* () */0), modules, externals);
  var t1 = Performance$BwaxAdmin.now(/* () */0);
  console.log("GENV used::", t1 - t0);
  return env;
}

exports.type_defs_and_acc = type_defs_and_acc;
exports.parse_and_type_defs = parse_and_type_defs;
exports.build_tenv = build_tenv;
exports.build_external_env = build_external_env;
exports.build_env = build_env;
exports.build_gtenv = build_gtenv;
exports.build_genv = build_genv;
/* Dict-BwaxAdmin Not a pure module */
