import React, { useState, useContext, useEffect, Component } from 'react'

import { Icon } from '@ant-design/compatible';
import { Button, Input } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import File from './File'
import Image from './Image'
import Text from './Text'
import { make as IconButton } from 'Client/re/widgets/IconButtonWidget.bs';
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery'
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile'
import { moveTo, drop, replace } from 'bwax/list'
import isEqual from "lodash/isEqual"

import "./WxInput.less"

export default function WxInput(props) {

    const { value, onChange, field, params, dataEntity, attachmentTypes, maxCount } = props

    const [attachments, setAttachments] = useState(value || [])
    const [isChoosing, setIsChoosing] = useState(false)
    const [currentAttachment, setCurrentAttachment] = useState(null)
    const [editIndex, setEditIndex] = useState(null)
    const [linkInfo, setLinkInfo] = useState({})

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)

    useEffect(() => {
        if(value !== attachments) {
            setAttachments(value || []);
        }

    }, [ value ] )


    useEffect(() => {
        if (currentAttachment) {
            if(currentAttachment.type === "link") {
                setCurrentAttachment({
                    ...currentAttachment,
                    link: {
                        ...currentAttachment.link,
                        ...linkInfo
                    }
                })
            } else if (currentAttachment.type === "news") {
                setCurrentAttachment({
                    ...currentAttachment,
                    news: {
                        ...currentAttachment.news,
                        ...linkInfo
                    }
                })
            }
            
        }
    }, [linkInfo])

    function renderAttachmentTypeChoose() {
        return (
            <div className='attachment-choose'>
                {
                    attachmentTypes.map(attachment => {
                        return (
                            <Button key={attachment.label} onClick={() => setCurrentAttachment({ type: attachment.value })}>
                                {attachment.label}
                            </Button>
                        )
                    })
                }
            </div>
        )
    }

    function renderAttachmentInput(Component, field, dataEntity, attachment, attachmentIndex) {
        const { options } = field

        const { type, text, image, link, news, video, file } = attachment ? attachment : currentAttachment

        function getAttachmentValue() {

            switch (type) {
                case "image":
                    return image;
                case "file":
                    return file;
                case "link":
                    return link && link.pic ? link.pic : null;
                case "news": 
                    return news && news.picurl ? news.picurl : null;
                case "text":
                    return text
                case "video":
                    return video
                default:
                    return null;
            }
        }

        function updateAttachmentLink(key, value) {

            setCurrentAttachment(type === "link" ? {
                ...currentAttachment,
                link: {
                    ...currentAttachment.link,
                    [key]: value
                }
            } : {
                ...currentAttachment,
                news: {
                    ...currentAttachment.news,
                    [key]: value
                }
            })
        }

        async function uploadFavIcon(file, title, desc) {
            const queryRunner = runDataQuery_a({ tenantCode, sessionToken, sandbox })

            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: [
                    (dataEntity && dataEntity.key) ? dataEntity.key : 'no-entity',
                    field.key
                ].join('-'),
            })

            if (!result) {
                /// TODO error handling
                console.error("上传出错")
            } else {
                const { id, ...changeAttachment } = result
                setLinkInfo(type === "link" ? {
                    title, desc, pic: changeAttachment
                } : {
                    title, desctiption: desc, picurl: changeAttachment.url
                })
            }
        }

        function updateLinkURL(value) {
            setCurrentAttachment(type === "link" ? {
                ...currentAttachment,
                link: {
                    ...currentAttachment.link,
                    url: value,
                }
            } : {
                ...currentAttachment,
                news: {
                    ...currentAttachment.news,
                    url: value,
                }
            })

            if (value.startsWith('http')) {
                // fetch url and get title, favicon, description and then update link or news
                fetch("/external-site?url=" + encodeURIComponent(value)).then(res => res.text()).then(text => {
                    const titleRegx = /<title.*>(.*)<\/title>/
                    const descRegx = /<meta name="description" content="(.*)"(?: \/)?>/ // 有些有 /> 有些没有
                    const favIconRegx = /(<link.*rel="(?:shortcut )?icon" type="image\/.*".*\/>)/
                    // const favIconRegx = /<link rel="(?:shortcut )?icon" (?:type="image\/.*" )?href="(.*)" \/>/
                    // const favIconRegx = /<link rel="shortcut icon" type="image\/png" href="(.*)" \/>/
                    let title = ""
                    let desc = ""

                    if (titleRegx.test(text)) {
                        title = text.match(titleRegx)[1]
                    }

                    if (descRegx.test(text)) {
                        desc = text.match(descRegx)[1]
                    }

                    if (favIconRegx.test(text)) {
                        const favIconContent = text.match(favIconRegx)[1]
                        const favIconUrlRegx = /<link.*href="([^"]*)".*(?:\/)?>/
                        const faviconUrl = favIconContent.match(favIconUrlRegx)[1]
                        fetch(faviconUrl).then((response) => {
                            return response.blob();
                        }).then(blob => {
                            uploadFavIcon(blob, title, desc)
                        });
                    } else {
                        if(currentAttachment) {
                            if(type === "link" && currentAttachment.link && currentAttachment.link.pic) {
                                setCurrentAttachment({
                                    ...currentAttachment,
                                    link: {
                                        url: value,
                                        title, desc
                                    }
                                })
                            } else if (type === "news" && currentAttachment.news && currentAttachment.news.picurl) {
                                setCurrentAttachment({
                                    ...currentAttachment,
                                    news: {
                                        url: value,
                                        title, desctiption: desc
                                    }
                                })
                            }
                        }
                    }

                    setLinkInfo(type === "link" ? {
                        title, desc
                    } : {
                        title, description: desc
                    })
                });

            }
        }

        function updateAttachments (newAttachment) {
            setCurrentAttachment(null)
            setIsChoosing(false)
            setLinkInfo({})

            if (editIndex || editIndex === 0 || attachmentIndex || attachmentIndex === 0) {
                const updateIndex = editIndex || attachmentIndex
                const newAttachments = replace(attachments, newAttachment, updateIndex)

                setEditIndex(null)
                setAttachments(newAttachments)
                onChange(newAttachments)
                
            } else {
                setAttachments([...attachments, newAttachment])
                onChange([...attachments, newAttachment])
            }
        }

        return type === "link" || type === "news" ? (
            <div className='link-input'>
                <div>
                    <span>链接</span>
                    { type === "link" ?
                        <Input maxLength={2048} value={link ? link.url : ""} onChange={e => updateLinkURL(e.target.value)} /> :
                        <Input value={news ? news.url : ""} onChange={e => updateLinkURL(e.target.value)} />
                    }
                </div>
                <div>
                    <span>标题</span>
                    { type === "link" ?
                        <Input maxLength={64} value={link ? link.title : ""} onChange={e => updateAttachmentLink('title', e.target.value)} /> :
                        <Input value={news ? news.title : ""} onChange={e => updateAttachmentLink('title', e.target.value)} />
                    }
                </div>
                <div>
                    <span>描述</span>
                    { type === "link" ?
                        <Input maxLength={512} value={link ? link.desc : ""} onChange={e => updateAttachmentLink('desc', e.target.value)} /> : 
                        <Input value={news ? news.description : ""} onChange={e => updateAttachmentLink('description', e.target.value)} />
                    }
                    
                </div>
                <div>
                    <span>封面图</span>
                    <Image
                        params={{
                            ...params,
                            multivalued: false,
                            uploadFor: [
                                (dataEntity && dataEntity.key) ? dataEntity.key : 'no-entity',
                                field.key
                            ].join('-'),
                            isPublic: true
                        }}
                        value={getAttachmentValue()}
                        onChange={v => {
                            setCurrentAttachment(type === "link" ? {
                                ...currentAttachment,
                                link: {
                                    ...currentAttachment.link,
                                    pic: v
                                }
                            } : {
                                ...currentAttachment,
                                news: {
                                    ...currentAttachment.news,
                                    picurl: v.url
                                }
                            })
                        }}
                    />
                </div>
            </div>
        ) : type === "text" ? (
            <Component
                params={{
                    ...params,
                    multivalued: false
                }}
                value={getAttachmentValue()}
                onChange={value => {
                    setCurrentAttachment({
                        ...currentAttachment,
                        text: value
                    })
                }}
            />
        ) : (
            <Component
                params={{
                    ...params,
                    multivalued: false,
                    uploadFor: [
                        (dataEntity && dataEntity.key) ? dataEntity.key : 'no-entity',
                        field.key
                    ].join('-'),
                    isPublic: true
                }}
                value={getAttachmentValue()}
                onChange={value => {
                    if(value) {
                        // add attachment
                        const newAttachment = type === "image" ? {
                            type: "image",
                            image: value
                        } : type === "file" ? {
                            type: "file",
                            file: value
                        } : type === "text" ? {
                            type: "text",
                            text: value
                        } : {
                            type: "video",
                            video: value
                        }
    
                        updateAttachments(newAttachment)
                    } else {
                        // delete attachment value
                        if(attachmentIndex || attachmentIndex === 0) {
                            const newAttachments = replace(attachments, {type}, attachmentIndex)

                            setAttachments(newAttachments)
                        } else {
                            console.log("上传失败。。。");
                        }
                        
                    }
                }}
            />
        )
    }

    function closeCurrentAttachmentEdit() {
        setIsChoosing(false)
        setCurrentAttachment(null)

        if (editIndex || editIndex === 0) {
            setEditIndex(null)
        }

    }

    function deleteAttachment(index) {
        const newAttachments = drop(attachments, index)

        setAttachments(newAttachments)
        onChange(newAttachments)
    }

    function isValid() {
        const { type, link, news, text, video } = currentAttachment
        // console.log("currentAttachment: ", currentAttachment, fileAndStatus);
        return (type === "link" && !!(link && link.title && link.url)) || (type === "text" && !!text) ||
            (type === 'news' && !!(news && news.url)) || (type === "video" && video && video.mediaId)
    }

    function confirm() {
        if (isValid()) {
            
            if (editIndex || editIndex === 0) {
                const newAttachments = replace(attachments, currentAttachment, editIndex)

                setEditIndex(null)
                setAttachments(newAttachments)
                onChange(newAttachments)
            } else {
                setAttachments([...attachments, currentAttachment])
                onChange([...attachments, currentAttachment])
            }

            setCurrentAttachment(null)
            setIsChoosing(false)
            setLinkInfo({})
        }
    }

    function renderAttachmentAdd() {
        const { type, video } = currentAttachment
        return (
            <div key={type} className={`add-attachment-box attachment-edit ${type === "link" || type === "news" ? "link-edit" : ""}`}>
                {
                    type === "image" ? (
                        renderAttachmentInput(Image, field, dataEntity)
                    ) : type === "link" || type === "news" ? (
                        renderAttachmentInput(null, field, dataEntity)
                    ) : type === "text" ? (
                        renderAttachmentInput(Text, field, dataEntity)
                    ) : (
                        renderAttachmentInput(File, field, dataEntity)
                    )
                }
                <div className='action-box'>
                    {
                        type === "link" || type === "news" || type === "text" ? (
                            <div className='confirm' style={!isValid() ? { cursor: "auto", opacity: 0.15 } : {}} onClick={() => confirm()}>
                                <Icon type="check" />
                            </div>
                        ) : null
                    }
                    <IconButton params={{
                        icon: "close",
                        onClick: () => closeCurrentAttachmentEdit()
                    }} />
                </div>

            </div>
        )
    }

    function editAttachment(index) {
        setIsChoosing(false)
        setCurrentAttachment(attachments[index])
        setEditIndex(index)
    }

    function handleDrop(item, toIndex) {
        const fromIndex = attachments.findIndex(a => isEqual(a, item))
        const actualAttachment = attachments.find(a => isEqual(a, item))
        const newAttachments = moveTo(attachments, actualAttachment, fromIndex, toIndex)
        setAttachments(newAttachments)
        onChange(newAttachments)
    }

    return (
        <div className='wx-input-container'>
            <DndProvider backend={HTML5Backend} >
            {
                attachments.length > 0 ?    
                    <AttachmentDrop onDrop={item => handleDrop(item, 0)}/> : null
            }
            {
                attachments.map((attachment, index) => {
                    return editIndex === index ? (
                        renderAttachmentAdd()
                    ) : [ 
                        <DragAttachment 
                            key={index}
                            index={index}
                            attachment={attachment}
                            renderAttachmentInput={Component => renderAttachmentInput(Component, field, dataEntity, attachment, index)}
                            editAttachment={index => editAttachment(index)}
                            deleteAttachment={index => deleteAttachment(index)}
                        />,
                        index !== attachments.length - 1 ? 
                            <AttachmentDrop key={`drop_${index}`} onDrop={item => handleDrop(item, index + 1)}/> : null
                    ]
                })
            }
            {
                attachments.length > 0 ?    
                    <AttachmentDrop onDrop={item => handleDrop(item, attachments.length)}/> : null
            }
            </DndProvider>
            {
                maxCount && attachments.length < maxCount ? (
                    isChoosing ? (
                        !currentAttachment ? (
                            renderAttachmentTypeChoose()
                        ) : renderAttachmentAdd()
                    ) : (
                        <div className={`add-attachment`} onClick={() => setIsChoosing(!isChoosing)}>
                            <Icon
                                type="plus"
                                className="add-icon"
                            />
                        </div>
                    )
                ) : null
            }
        </div>
    )
}

function DragAttachment(props) {

    const { index, attachment, renderAttachmentInput, editAttachment, deleteAttachment } = props

    const { type, link, news, text } = attachment
    const Component = type === "image" ? Image : File

    const [{ isDragging }, drag] = useDrag({
        type: "DragAttachment",
        item: attachment,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    })

    const draggingStyle = isDragging ? { display: 'none' } : {}

    return (
        <div className='attachment-item' ref={drag}
            style={draggingStyle}
        >
            {
                type === "link" || type === "news" ? (
                    <div className='link'>
                        <div className='title'>{link && link.title || news && news.title}</div>
                        <div className='content'>
                            <div>{link && link.desc || news && news.description}</div>
                            {
                                link && link.pic || news && news.picurl? (
                                    <img src={link && link.pic.url || news && news.picurl} />
                                ) : (
                                    <div className='dummy-img'>
                                        <Icon type="link" />
                                    </div>
                                )
                            }

                        </div>
                    </div>
                ) : type === "text" ? (
                    <div className='wx-text'>{text}</div>
                ) : renderAttachmentInput(Component)

            }
            <div className='action-box'>
                {
                    type === "link" || type === "news" || type === "text" ? (
                        <div className='edit' onClick={() => editAttachment(index)}>
                            <Icon type="edit" />
                        </div>
                    ) : null
                }
                <IconButton params={{
                    icon: "close",
                    onClick: () => deleteAttachment(index)
                }} />
                
            </div>
        </div>
    )
}

function AttachmentDrop(props) {

    const { onDrop } = props

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: "DragAttachment",
        drop: (item) => onDrop(item),
        collect: monitor => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }
        }
    })

    const droppingStyle = canDrop && isOver ? {
        backgroundColor: "#e6f7ff",
        borderColor: "#3e8ecd",
        margin: "0.5rem 0",
        width: "100%", height: "4rem"
    } : { width: "100%", height: "1rem" }

    return (
        <div
            ref={drop}
            style={{
                ...droppingStyle,
                position: 'relative'
            }}>
        </div>
    )
} 
