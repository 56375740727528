import React from 'react';

import MenuEdit from './pages/MenuEdit';
import ReplyEditPage from './pages/ReplyEditPage';
import MpReplyEdit from './pages/MpReplyEdit'

export default {


    /// 除了 menu 之外，自定义的 routes ，前面会自动加上 /ext/:extKey 
    routes: [{
        path: "menu",  // 前面会自动加上 /ext/:extKey/
        comp: props => {

            const { 
                extensions, 
                additional, queryTarget, routeTo, location, facade
            } = props;

            return (
                <MenuEdit 
                    {...{ extensions, facade
                        , additional, queryTarget, routeTo, location 
                    }} 
                />
            )

        }
    }, {
        path: "reply",  // 前面会自动加上 /ext/:extKey/
                
        ////  !TODO 加上权限管理 

        comp: props => {
            const { 
                extensions, 
                additional, queryTarget, routeTo, location, facade
            } = props;

            return (
                <MpReplyEdit 
                    {...{ extensions, facade
                        , additional, queryTarget, routeTo, location 
                    }} 
                />
            )
        }
    }, {
        path: "edit/:id?",
        
        comp: (props) => {
            const { 
                extensions,  history,
                additional, queryTarget, routeTo, location, facade, match_
            } = props;

            return (
                <ReplyEditPage 
                    {...{ extensions, facade
                        , additional, queryTarget, routeTo, location, history, match_
                    }} 
                />
            )

        }
    }],

    widgets: {
        MenuEdit,
        ReplyEditPage,
        MpReplyEdit
    }
}