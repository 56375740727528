
import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs';

import IconButton from 'Client/js/components/IconButton';
import { IconExport } from '@arco-design/web-react/icon';

import Drawer from 'rsuite/Drawer';
import Button from 'Client/ml/ui/components/Button';

import './ExportFormToExcel.less';
import Message from 'Client/js/ui/Message';
import AttachmentView from 'Client/js/components/AttachmentView';


const PROGRESSING = "导出中";
const FAILED = "失败";
const PENDING = "待处理";
const COMPLETED = "已完成";


export default function ExportFormToExcel(props) {

    const { form, facade } = props;

    const entityName = "表单导出";

    const [ exports, setExports ] = useState();
    
    async function loadExports(options) {
        const queryObj = {
            entityName,
            // query_config, 
            // facade 支持 query_config， 也支持 js 版本的 condition, sort
            condition: [{
                field: "表单", op: "eq", value: form.id
            }],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            fieldPaths: ["数据文件", "表单", "状态", "进度", "失败原因", "导出数据条数", "创建时间"],
            pageSize: 1000, offset: 0

        };
        const [result, error] = await facade.list(queryObj, options);
        if (!error && result) {
            setExports(result);

            if(result.some(r => r.状态 == PROGRESSING)) {
                setTimeout(() => {
                    loadExports({forceRefreshing: true });
                }, 200)
            }           
            
        }
    }

    useEffect(() => {
        loadExports();
    }, [])

    // 
    async function createNewExport () {
        const [result, error ] = await facade.add({
            entityName,
            formData: {
                表单: form.id
            },
            fieldPaths: []
        });
        if(!error) {
            loadExports({forceRefreshing: true });

        } else {
            // TODO error hanlding
            Message.error("出错了")
        }
    }

    function renderJobTitle(job) {
        if(job.数据文件) {
            return <AttachmentView readOnly={true} attachment={job.数据文件} />
        } else {
            const statuses = {
                [PROGRESSING]: `正在导出: ${job.进度}%`,
                [COMPLETED]: "已完成",
                [FAILED]: `失败: ${job.失败原因}`,
                [PENDING]: "正在等待导出"
            }
            return statuses[job.状态] || ""
        }
    }

    function renderJobInfo(job) {
        return (
            <div className="job-info" key={job.id}>
                <div className="created-at">
                    { dayjs(job.创建时间).format("MM-DD HH:mm") }
                </div>
                { job.导出数据条数 !== undefined && job.导出数据条数 !== null ? (
                    <div className="record-count">
                        共 {job.导出数据条数} 条记录
                    </div>
                ) : null}                
            </div>
        )
    }


    return (
        <div className="lc-export-form-to-excel">
            <div className="toolbar">
                <Button buttonType="primary" label="导出数据" onClick={_ => {
                    // create a new export 
                    createNewExport();

                }} />
            </div>
            <div className="job-list">
                { (exports || []).map(job => {

                    return (
                        <div className="job-summary" key={job.id}>
                            <div className="job-title">
                                { renderJobTitle(job) }
                            </div>
                            { renderJobInfo(job) }
                        </div>
                    )


                })}
            </div>
        </div>
    )
}


export function ExportFormToExcelButton (props) {

    const { form, facade } = props;

    const [ visible, setVisible ] = useState(false);

    return (
        <>
            <IconButton {...{
                icon: <IconExport />,
                onClick: _ => {

                    setVisible(true);            
                }
            }} />
            <Drawer 
                open={visible}
                size="xs"
                onClose={_ => {
                    setVisible(false)
                }}
            >
                <ExportFormToExcel 
                    {...{
                        form, facade,
                    }}
                />
            </Drawer>
        </>
    )


}


