// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Plate$BwaxAdmin = require("../../../lib/bwax-js/ml/plate.bs.js");

function get_function_name(lang_node) {
  var match = lang_node[/* name */0];
  switch (match) {
    case "Def" :
        return Plate$BwaxAdmin.$$Option.and_then(get_function_name, Plate$BwaxAdmin.List.head(lang_node[/* children */5]));
    case "FunctionDef" :
        return Plate$BwaxAdmin.$$Option.map((function (c) {
                      return c[/* src */1];
                    }), Plate$BwaxAdmin.List.head(lang_node[/* children */5]));
    default:
      return ;
  }
}

function is_function_with_name(name, lang_node) {
  var match = lang_node[/* name */0];
  switch (match) {
    case "Def" :
        return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (param) {
                          return is_function_with_name(name, param);
                        }), Plate$BwaxAdmin.List.head(lang_node[/* children */5])));
    case "FunctionDef" :
        return Plate$BwaxAdmin.$$Option.with_default(false, Plate$BwaxAdmin.$$Option.map((function (c) {
                          return c[/* src */1] === name;
                        }), Plate$BwaxAdmin.List.head(lang_node[/* children */5])));
    default:
      return false;
  }
}

function get_func_by_name(name, lang_tree) {
  return Plate$BwaxAdmin.List.find((function (c) {
                return Caml_obj.caml_equal(get_function_name(c), name);
              }), Plate$BwaxAdmin.List.keep_map((function (c) {
                    if (c[/* name */0] === "Def") {
                      return Plate$BwaxAdmin.List.head(c[/* children */5]);
                    }
                    
                  }), lang_tree[/* children */5]));
}

exports.get_function_name = get_function_name;
exports.is_function_with_name = is_function_with_name;
exports.get_func_by_name = get_func_by_name;
/* Plate-BwaxAdmin Not a pure module */
