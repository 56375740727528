
import React, { useContext } from 'react'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import './SandboxIndicator.less'

export default function SandboxIndicator () {
    const { sandbox } = useContext(DataLoaderContext)

    if(sandbox) {
        return (
            <div className='sandbox-indicator'>
                当前处于沙箱模式
                {/* ，页面中显示的测试数据，可能会被定期清理 */}
            </div>
        )
    } 
    return null  

}