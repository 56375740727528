import { SelectionState } from 'draft-js';
import createDecorator from './createDecorator'

import { mergeBlockData } from '../../util/EditorUtil'

const store = {
  getEditorRef: undefined,
  getReadOnly: undefined,
  getEditorState: undefined,
  setEditorState: undefined,
};

const createSetResizeData = (contentBlock, { getEditorState, setEditorState }) => (data) => {


  const editorState = getEditorState();
  const selectionState = SelectionState.createEmpty(contentBlock.getKey())

  /// FIXME: this is coupled with the ImagePlugin:
  setEditorState(
    mergeBlockData(
      editorState,
      selectionState,
      {
        width: data.width
      }
    )
  )

};

export default (config) => ({
  initialize: ({ getEditorRef, getReadOnly, getEditorState, setEditorState }) => {
    store.getReadOnly = getReadOnly;
    store.getEditorRef = getEditorRef;
    store.getEditorState = getEditorState;
    store.setEditorState = setEditorState;
  },
  decorator: createDecorator({ config, store }),
  blockRendererFn: (contentBlock, { getEditorState, setEditorState }) => {
    const entityKey = contentBlock.getEntityAt(0);
    const contentState = getEditorState().getCurrentContent();
    const resizeData = entityKey ? contentState.getEntity(entityKey).data : {};
    return {
      props: {
        resizeData,
        setResizeData: createSetResizeData(contentBlock, { getEditorState, setEditorState }),
      },
    };
  }
});
