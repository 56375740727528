import React from 'react';

import Editor from 'draft-js-plugins-editor'

import guid from 'bwax/guid'

import { createPlugins } from './initPlugins.js'

import initConverters from './initConverters'

import handlePastedText from './handlePastedText'
import handleKeyCommand from './handleKeyCommand'
import handleReturn from './handleReturn'

/// some helper function from plugins
import InlineToolbar from './plugins/inlineToolbar/InlineToolbar'

const { htmlToDraftContent } = initConverters()


const defaultPlugins = createPlugins({})

export default class BasicEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            availableToDrop: false,

            supplementClassNames: []

        }
        this.setEditorRef = ref => this.editor = ref;

        this.editorStateListeners = [] // [{callback: function, id: <guid>}]

        this.plugins = defaultPlugins
    }

    render() {
        const {
            editorState, 
            onChange, 
            inlineToolbarConfig,
            placeholder,
            uploadImage,
            /// the following props may be removed
            style, onFocus, onBlur,
            className, plugins = this.plugins,
            titleConfig,
            
        } = this.props


        const onDragOver = (e) => {
            e.preventDefault()
            e.dataTransfer.dropEffect = "copy"
            if (this.state.availableToDrop === false) {
                this.setState({ availableToDrop: true })
            }
        }
        const onDragLeave = (e) => {
            this.setState({ availableToDrop: false })
        }

        const onDrop = (e) => {
            e.preventDefault()
            this.setState({ availableToDrop: false })
        }

        const updateSupplementClassNames = (className, enable) => {
            const { supplementClassNames } = this.state
            const newList = (
                enable ? (
                    supplementClassNames.indexOf(className) === -1 ?
                        [...supplementClassNames, className] : supplementClassNames
                ) : supplementClassNames.filter(n => n !== className)
            )
            this.setState({
                supplementClassNames: newList
            })
        }

        const listenEditorStateChange = (onEditorStateChange) => {
            // set the hook:
            const id = guid()
            const listener = {
                id,
                callback: onEditorStateChange,
                remove: () => {
                    this.editorStateListeners = this.editorStateListeners.filter(
                        l => l.id !== id
                    )
                }
            }
            this.editorStateListeners.push(listener)
            return listener
        }

        const onStateChange = (editorState) => {
            onChange(editorState)
    
            this.editorStateListeners.forEach(
                l => l.callback(editorState)
            )
    
        }

        return (
            <div className={
                "editor rich-media rich-text" +
                (this.state.availableToDrop ? " is-dropzone-hover" : "") +
                (className ? " " + className : "") +
                (" " + this.state.supplementClassNames.join(" "))
            }
                style={style}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onClick={() => this.editor.focus()}
            >
                {
                    titleConfig ? titleConfig : null
                }
                <Editor editorState={editorState}
                    onChange={onStateChange}
                    uploadImage={uploadImage}
                    handleReturn={handleReturn({
                        setEditorState: onStateChange
                    })}
                    placeholder={placeholder || ""}
                    handleKeyCommand={handleKeyCommand({
                        setEditorState: onStateChange,
                        handler: null
                    })}
                    handlePastedText={handlePastedText(
                        this.editor,
                        htmlToDraftContent,
                        {
                            setEditorState: onStateChange,
                            getEditorState: () => editorState
                        }
                    )}
                    plugins={plugins}
                    ref={this.setEditorRef}
                    {
                    ...{ onFocus, onBlur }
                    }
                >
                </Editor>
                {
                    inlineToolbarConfig === false ? null :
                        <InlineToolbar config={inlineToolbarConfig}
                            listenEditorStateChange={listenEditorStateChange}
                            updateSupplementClassNames={updateSupplementClassNames}
                            plugins={plugins}
                            getEditorState={() => editorState}
                            setEditorState={onStateChange}
                            childrenProps={{ uploadImage }} />
                }

                <div style={{ clear: "both" }}></div>
            </div>
        )
    }


}
