// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Antd$BwaxAdmin = require("../binding/antd/Antd.bs.js");
var Color$BwaxAdmin = require("../Color.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");
var Tooltip = require("bwax-ui/components/Tooltip");
var Popconfirm = require("bwax-ui/components/Popconfirm");

function IconButton(Props) {
  var icon = Props.icon;
  var match = Props.size;
  var size = match !== undefined ? match : /* medium */-20425611;
  var className = Props.className;
  var onClick = Props.onClick;
  var color = Props.color;
  var match$1 = Props.disabled;
  var disabled = match$1 !== undefined ? match$1 : false;
  var confirm = Props.confirm;
  var tip = Props.tip;
  var sizeName = Props.sizeName;
  var actualSize;
  if (sizeName !== undefined) {
    switch (sizeName) {
      case "large" :
          actualSize = /* large */-272944197;
          break;
      case "medium" :
          actualSize = /* medium */-20425611;
          break;
      case "small" :
          actualSize = /* small */-9768761;
          break;
      default:
        actualSize = size;
    }
  } else {
    actualSize = size;
  }
  var sz = actualSize !== -20425611 ? (
      actualSize >= -9768761 ? 1.6 : 2.5
    ) : 2.0;
  var opacityValue = disabled ? 0.4 : 0.8;
  var cursorType = disabled ? /* default */465819841 : /* pointer */-786317123;
  var fontSizeValue = actualSize !== -20425611 ? (
      actualSize >= -9768761 ? Css.em(0.8) : Css.em(1.25)
    ) : Css.em(1.0);
  var configuredStyle = Belt_List.keepMap(/* :: */Caml_chrome_debugger.simpleVariant("::", [
          Belt_Option.map(color, (function (c) {
                  return Css.color(Color$BwaxAdmin.getCssColor(c));
                })),
          /* [] */0
        ]), (function (x) {
          return x;
        }));
  var button = Css.style(Belt_List.concat(/* :: */Caml_chrome_debugger.simpleVariant("::", [
              Css.borderRadius(Css.pct(50.0)),
              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                  Css.display(/* flex */-1010954439),
                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                      Css.justifyContent(/* center */98248149),
                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                          Css.alignItems(/* center */98248149),
                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                              Css.height(Css.em(sz)),
                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                  Css.width(Css.em(sz)),
                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                      Css.cursor(cursorType),
                                      /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                          Css.opacity(opacityValue),
                                          /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                              Css.fontSize(fontSizeValue),
                                              /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                  Css.selector("&:hover", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                          Css.backgroundColor(Color$BwaxAdmin.getCssColor("N20")),
                                                          /* [] */0
                                                        ])),
                                                  /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                      Css.selector("&:active", /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                              Css.backgroundColor(Color$BwaxAdmin.getCssColor("N30")),
                                                              /* [] */0
                                                            ])),
                                                      /* [] */0
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ]), configuredStyle));
  var theClassName = "icon-button " + (button + (
      className !== undefined ? " " + className : ""
    ));
  var renderWithConfirm = function (confirm) {
    var positions = Belt_Option.getWithDefault(confirm.positions, /* array */["top"]);
    var align = Belt_Option.getWithDefault(confirm.align, "center");
    var okText = Belt_Option.getWithDefault(confirm.okText, "确定");
    var cancelText = Belt_Option.getWithDefault(confirm.cancelText, "取消");
    var title = Belt_Option.getWithDefault(confirm.title, "您确定？");
    return Popconfirm.create({
                title: title,
                positions: positions,
                align: align,
                okText: okText,
                cancelText: cancelText,
                padding: 8,
                onConfirm: onClick,
                children: React.createElement("div", {
                      className: theClassName
                    }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                          type_: icon
                        }))
              });
  };
  var inner = confirm !== undefined ? renderWithConfirm(Caml_option.valFromOption(confirm)) : (
      onClick !== undefined ? React.createElement("div", {
              className: theClassName,
              onClick: onClick
            }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                  type_: icon
                })) : React.createElement("div", {
              className: theClassName
            }, React.createElement(Antd$BwaxAdmin.Icon.make, {
                  type_: icon
                }))
    );
  if (tip !== undefined) {
    return Tooltip.create({
                text: Caml_option.valFromOption(tip),
                children: inner
              });
  } else {
    return inner;
  }
}

var make = IconButton;

exports.make = make;
/* Css Not a pure module */
