

import React, { useEffect, useState, useRef } from 'react'

import './PreviewSettingButton.less'

import { DragHandleDots2Icon } from '@radix-ui/react-icons'

import { Button, Popover } from '@arco-design/web-react'

import Avatar from 'bwax-ui/components/Avatar'

import TenantSelect from './TenantSelect';

import { runDataQuery } from 'bwax/query/runClientQuery'

import LoginQrCode from '../pages/login/LoginQrCode';
import loadCurrentUser from 'bwax-ui/auth/loadCurrentUser'

import setTokenCookie from 'bwax-ui/setTokenCookie'


import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

export default function PreviewSettingButton({ testUser, setTestUser, multitenantType, tenant, setTenant }) {


    const [startingPosition, setStartingPosition] = useState();

    const [baseOffset, setBaseOffset] = useStateWithLocalCache ("lc-preview-setting-button", {x: 0, y:0 });
    const [offset, setOffset] = useState(baseOffset)


    const startingPositionRef = useRef();
    startingPositionRef.current = startingPosition;
    const baseOffsetRef = useRef();
    baseOffsetRef.current = baseOffset;
    const offsetRef = useRef();
    offsetRef.current = offset;

    useEffect(() => {

        function onMouseMove (e) {
            const startingPosition = startingPositionRef.current;
            const baseOffset = baseOffsetRef.current;
            if(startingPositionRef.current) {
                const { x, y } = e;

                const moveX = x - startingPosition.x;
                const moveY = y - startingPosition.y;
                const offset = { x: baseOffset.x + moveX, y: baseOffset.y + moveY };
                setOffset(offset);
            }
        }

        function onMouseUp () {
            if(startingPositionRef.current) {
                setStartingPosition();
                setBaseOffset(offsetRef.current);
            }
        }

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        return (() => {
            document.removeEventListener("mouseove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        });

    });


    return (
        <div className="lc-preview-setting-button" style={{
            "--offset-x": offset.x + "px",
            "--offset-y": offset.y + "px",
        }}>
            <DragHandleDots2Icon style={{ cursor: startingPosition ? "grabbing" : "grab" }}
                onMouseDown={e => {
                    setStartingPosition({ x: e.pageX, y: e.pageY });
                }}
            />
            {multitenantType == "tenant" ?
                (
                    <TenantSelect {...{ tenant, setTenant }} />
                ) : null
            }
            <LoginUserForTesting {...{ testUser, setTestUser, tenant, multitenantType }} />
        </div>
    )
}

function LoginUserForTesting({ testUser, setTestUser, tenant, multitenantType }) {

    useEffect(() => {
        // 如果 tenant 改了，就要改用户
        if (multitenantType == "tenant" && !tenant) {
            setTestUser();
            return
        }

        setTestUser();
        (async () => {
            const u = await loadCurrentUser({ withToken: true })({ tenantCode: multitenantType == "tenant" ? tenant && tenant.code : undefined, sandbox: true });
            setTestUser(u);
        })();

    }, [tenant]);

    const [loginPanelVisible, setLoginPanelVisible] = useState(false);

    // for login
    const renderLoginPanel = () => {
        // only for qr code now:
        const sandbox = true;
        const tenantCode = tenant ? tenant.code : undefined;
        const runQuery = runDataQuery({ sandbox, tenantCode });

        function afterLogin(accessToken, authUser) {
            setLoginPanelVisible(false);
            setTokenCookie(accessToken, { sandbox, tenantCode });
            setTestUser([accessToken, authUser]);
        }

        return (
            <LoginQrCode afterLogin={afterLogin} runQuery={runQuery} {...{ sandbox, tenantCode }} />
        )
    }
    return (
        <Popover
            trigger='click'
            // title='Title'
            popupVisible={loginPanelVisible}
            className={"lc-preview-setting-login-popover"}
            style={{ paddingRight: "0.25rem" }}
            onVisibleChange={loginPanelVisible => {
                setLoginPanelVisible(loginPanelVisible)
            }}
            content={
                renderLoginPanel()
            }
        >
            <div className="test-user">
                {testUser && testUser[1] ?
                    <Avatar {...{
                        avatar: testUser[1].avatar, nickName: testUser[1].nickName, size: 24
                    }} /> :
                    <div className="login-button">登录</div>
                }
            </div>
        </Popover>
    )
}



// 一个是登录当前环境的页面
// 一个是选择租户：如果需要选择租户，那么要求当前用户登录 sandbox 作为平台用户

