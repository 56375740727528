import React, { useState, useEffect, useContext } from 'react'

// draft
import { deserializeContent, ArticleEditor, isContentEqual, serializeContent } from 'bwax-ui/auxiliary/richtext/editor'
import { contentToPlain } from 'bwax-ui/auxiliary/richtext/renderer'

// slate
import SlateEditor from 'bwax-ui/auxiliary/richtext_slate/editor/SlateEditor';
import buttonComponents from 'bwax-ui/auxiliary/richtext_slate/editor/buttons/buttonsLegacy';

import resolveContentType from 'bwax-ui/auxiliary/richtext/resolveContentType'


import { deserializeV2, serialize } from 'bwax-ui/richtext/serializeRichText';

import './RichText.less'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';


export default function RichText(props) {

    // 区分是 Slate 版本还是 Draft 版本
    // 沿用之前的版本
    // 如果是 null 或者 undefined 则使用 Slate

    const { onChange, value, params } = props
    const { uploadFor } = params

    const { tenantCode, sessionToken, sandbox } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ tenantCode, sessionToken, sandbox })

    async function uploadImage(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor
        })

        if (!result) {
            /// TODO error handling
            Message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }

    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor,
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                Message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            onError(error)
        }
    }


    if (value) {
        const { contentType } = resolveContentType(value);

        if (contentType === "DraftJS") {
            return (
                <RichText_Draft {...{
                    onChange, value, uploadImage, uploadVideo
                }} />
            )
        }
    }

    // 默认是 Slate
    return (
        <RichText_Slate {...{
            value, onChange, uploadImage, uploadVideo
        }} />
    )

}



const emptyContent = [{
    type: 'paragraph',
    children: [{ text: '' }],
}]

const serializedEmptyContent = serialize(emptyContent);

function RichText_Slate(props) {

    const { value, onChange, uploadImage, uploadVideo } = props

    const [[editing, version], setEditing] = useState(_ => deserializeV2(value))

    useEffect(() => {
        if (value !== serialize(editing, version)) {
            setEditing(deserializeV2(value));            
        }
    }, [value])

    return (
        <SlateEditor {...{
            uploadImage, uploadVideo,
            value: editing,
            className: "admin--richtext-input",

            version,

            buttonComponents,
            onChange: nv => {
                setEditing([nv, version === undefined ? 2 : version]);
                const serialized = serialize(nv, version);

                const isEmpty = !value && (serialized == serializedEmptyContent);
                const changed = serialized !== value;
                if(!isEmpty && changed) {
                    onChange(serialized);
                }

            }
        }} />
    )
}



function RichText_Draft(props) {
    const { onChange, value, uploadImage, uploadVideo } = props

    const [editorState, setEditorState] = useState(deserializeContent(value || ''))

    useEffect(() => {
        if (!isContentEqual(editorState, deserializeContent(value))) {
            setEditorState(deserializeContent(value))
        }
    }, [value])

    const inlineToolbarConfig = {
        buttons: [
            'h2', 'h3', 'bold', 'italic', 'underline',
            'seperator', 'format-clear', 'hyperlink',
        ],
    }

    return (
        <ArticleEditor
            inlineToolbarConfig={inlineToolbarConfig}
            className="admin--input--richtext bordered-input"
            uploadImage={uploadImage}
            uploadVideo={uploadVideo}
            style={{ minHeight: 200, width: "100%" }}
            onChange={editorState => {
                const serialized = serializeContent(editorState)
                setEditorState(editorState)
                if (contentToPlain(serialized).length === 0 && (value === undefined || value === null)) {
                    /// 一开始的状态，不调用 onChange
                } else {
                    onChange(serializeContent(editorState))
                }
            }}
            editorState={editorState}
        />
    )
}
