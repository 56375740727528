
// Table.js 不对传过来的数据分页，它只假定传来的 data 肯定是该页的
// 这个组件做一个简单的本地分页管理

import React, { useState } from 'react';

import Table from './Table';

export default function LocalPagedTable (props) {

    const { columns, dataSource = [], pagination = { }, style } = props;
    
    const totalCount = dataSource.length;

    const pageSize = pagination.pageSize || 20;

    const [ currentPage, setCurrentPage ] = useState(0);

    const data = dataSource.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

    return (
        <Table dataSource={data} columns={columns} totalCount={totalCount} style={style} pagination={{
            pageSize, onPageChange: page => setCurrentPage(page - 1)
        }}></Table>
    )


}