

import React, { useEffect, useRef, useState } from 'react'

import './FieldForm.less';

import ClampedText from 'bwax-ui/components/ClampedText';
import FieldTip from 'Client/js/components/FieldTip';

import useResizeObserver from '@react-hook/resize-observer'

const ItemLabel = React.forwardRef(({ name, tip, width, parentWidth, recalculate}, ref) => {

    // 判断 width 与 parentWidth 36% 的大小，如果比 36% 大，则使用 36%（避免 label 在容器宽度较小的时候仍然保持宽度不换行）
    const [actualWidth, setActualWidth] = useState(null)
    const [shouldRecalculate, setShouldRecalculate] = useState(false)

    useEffect(() => {
        if(parentWidth) {
            if(width > parentWidth * 0.36) {
                setActualWidth(parentWidth * 0.36)
                setShouldRecalculate(true)
            } else {
                if(width !== actualWidth) {
                    setActualWidth(width)
                } else if(shouldRecalculate) {
                    setActualWidth(undefined)
                    recalculate()
                    setShouldRecalculate(false)
                }
                
            }
            
        }
    }, [ parentWidth ])

    return (
        <div className="item-label" ref={ref} style={ width > 0 ? { width: actualWidth } : {}}>            
            <div className="label-wrapper">
                {/* {name} */}
                <ClampedText lines={2} text={name} />
            </div>
            <FieldTip tip={tip}/>
        </div>
    )
})

function FieldGroup(props) {
    // 把 field-group 放进来
    
    // 1. 先让他自动渲染
    // 2. 收集每个 ItemLabel 的实际长度（
    // 3. 收集完后，使用最长的那个 label，来设置全部 label 框的宽度； 并且相对应的修改 value 框的宽度
    const { itemList, width, noLabel, layout } = props

    const fieldGroupRef = useRef(null)
    const itemLabelRefs = useRef({})

    const [contentWidth, setContentWidth] = useState(null)
    const [shouldRecalculate, setShouldRecalculate] = useState(true)
    const [maxWidth, setMaxWidth] = useState(0)


    function findMaxWidth () {
        let maxWidth = 0
        if(itemLabelRefs && itemLabelRefs.current) {
            maxWidth = Object.values(itemLabelRefs.current).reduce((prev, current) => {
                // 进入“编辑”状态(display:none)后再回来，boundingClientRect.width 会变成 0，所以使用 style.width
                const styleWidthRegx = /^(\d+(\.\d+)?)px$/
                const styleWidth = current && current.style && current.style.width.match(styleWidthRegx) ? current.style.width.match(styleWidthRegx)[1] : 0 
                // 使用 boundingClientRect 保留实际长度的小数点
                const actualWidth = current && current.getBoundingClientRect().width > 0 ? current.getBoundingClientRect().width : styleWidth 
                return actualWidth > prev ? actualWidth : prev 
            }, 0)
        }

        return maxWidth
    }

    useEffect(() => {
        if(shouldRecalculate) {
            const maxWidth = findMaxWidth()
            setMaxWidth(maxWidth)
            setShouldRecalculate(false)
        }
    }, [ shouldRecalculate ])

    useResizeObserver(fieldGroupRef, e => {
        setContentWidth(e.target.getBoundingClientRect().width)
    })

    // const contentWidth = fieldGroupRef && fieldGroupRef.current && fieldGroupRef.current.getBoundingClientRect().width

    // 只有一个 itemList 时，value 使用 100% 计算宽度会显得太长，所以用 80% 计算（by 威豪）
    // const valueMaxWidth = `calc(${itemLists.length > 1 ? "100%" : "80%"} - ${findMaxWidth()}px)` 

    // 还是要使用 100% 更合适一点 (2023-01-31 van)
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1174
    const valueMaxWidth = layout == "horizontal" ? `calc(100% - ${findMaxWidth()}px)` : "100%";;

    const labelMaxWitdh = layout == "horizontal" ? maxWidth : "100%";;

    return (
        <div className="field-group" style={{
            width,
            maxWidth: width,
            minWidth: width
        }} ref={fieldGroupRef}>
            {
                itemList.map(item => {
                    const { name, tip, value } = item;
                    return (
                        <div className="field-item" key={name}>
                            {noLabel ? null : 
                                <ItemLabel 
                                    name={name} tip={tip} width={labelMaxWitdh} parentWidth={contentWidth} recalculate={() => setShouldRecalculate(true)}
                                    ref={item => itemLabelRefs.current[name] = item} 
                                />
                            }
                            <div className="item-value" style={{ maxWidth: valueMaxWidth }}>
                                { value }
                            </div>
                        </div>
                    )

                })
            }
        </div>
    )
    
}



// multi column form:
export default function FieldForm(props) {

    const { layout = "horizontal", noLabel, style = {}, width, itemLists } = props

    return (
        <div className={
            "admin--field-form layout-" + layout + (noLabel ? " no-label" : "")
        } style={style}>
            {
                itemLists.length > 0 ? itemLists.map((l, index) => {
                    return (
                        <FieldGroup key={index} itemList={l} width={width} noLabel={noLabel} layout={layout}/>
                    )
                }) : null
            }
        </div>
    )
}
