
import { SelectionState, EditorState } from 'draft-js'

import { mergeBlockData, insertAtomicBlockWithData } from 'bwax-ui/auxiliary/richtext/util/EditorUtil'

import getImageURL from 'bwax-ui/getImageURL'

export const linkStyle = {
    display: "inline-block",
    width: "100%"
}

export function imageBlockStyle({ align, width }) {
    let blockStyle = { padding: "2px 0" }
    if (align === 'center') {
        blockStyle = {
            ...blockStyle,
            margin: "8px auto"
        }
    } else if (align === 'left') {
        blockStyle = {
            ...blockStyle,
            marginRight: "4px",
            float: "left"
        }
    } else if (align === 'right') {
        blockStyle = {
            ...blockStyle,
            marginLeft: "4px",
            float: "right"
        }
    }

    if (width) {
        blockStyle.width = isNaN(width) ? width : (width + "%")
    }
    return blockStyle
}


export function imageStyle() {
    return {
        verticalAlign: "middle",
        width: "100%"
    }
}

export function processImageURL(urls) {
    function processForDataBlob(url) {
        if(!url) {
            return url
        }
        const isObjectURL = url.match(/^blob:.+/) !== null
        if (isObjectURL) {
            return url
        } else {
            return getImageURL({ url })
        }
    }
    if(urls && Array.isArray(urls)) {
        return urls.map(processForDataBlob);
    } else {
        return processForDataBlob(urls)
    }

}


function createImage(editorState, selection, { src, ...extraData }) {

    const targetSelection = selection ? selection : editorState.getCurrentContent().getSelectionAfter()

    const forcedSelection = EditorState.forceSelection(
        editorState,
        targetSelection
    )

    const {
        editorState: afterBlockInserted,
        blockKey
    } = insertAtomicBlockWithData(
        forcedSelection, null, ' ',
        {
            align: 'center',
            width: 100,
            type: "IMAGE",
            src,
            ...extraData
        }
    )

    return {
        editorState: afterBlockInserted,
        blockKey
    }
}


export function insertImage(f, selection, uploadImage, { setEditorState, getEditorState }) {

    const reader = new FileReader()
    const Url = typeof (window) !== 'undefined' ? (window.URL || window.webkitURL) : null

    reader.onload = (evt) => {
        /// show the local image  
        const localSrc = Url ? Url.createObjectURL(f) : evt.target.result

        const { editorState, blockKey } = createImage(
            getEditorState(), selection, { src: localSrc, uploading: !!uploadImage }
        )

        setEditorState(editorState)

        if (uploadImage) {

            uploadImage(f, (err, url) => {
                const contentState = getEditorState().getCurrentContent()
                const block = contentState.getBlockForKey(blockKey)
                if (block) {
                    setEditorState(
                        mergeBlockData(
                            getEditorState(),
                            SelectionState.createEmpty(blockKey),
                            {
                                src: url,
                                uploading: false
                            }
                            // {
                            //   noRedo: true
                            // }
                        )
                    )
                }
            })
        }

    }
    reader.readAsDataURL(f)
}
