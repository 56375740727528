

import React, { useEffect, useState, useRef } from 'react'

import './FormView.less'

import { Menu, Dropdown, Input, Tag, Popconfirm } from '@arco-design/web-react';

import { IconCheck, IconCopy, IconMoreVertical, IconLaunch, IconCaretDown } from '@arco-design/web-react/icon';

import IconButton from 'Client/js/components/IconButton';
import Loading from 'Client/js/components/Loading';

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import Button from 'Client/ml/ui/components/Button';

import FormDataAdd from './FormDataAdd';
import FormDataEdit from './FormDataEdit';

import LightTable from 'Client/js/components/LightTable';

import { FieldManagementModal } from './FieldManagement';

import uniq from 'lodash/uniq';

import useResizeObserver from '@react-hook/resize-observer'
import Message from 'Client/js/ui/Message';
import { ExportFormToExcelButton } from './ExportFormToExcel';
import ClampedText from 'bwax-ui/components/ClampedText';

export default function FormView(props) {

    const { form, facade, onFormUpdated } = props;

    const [formData, setFormData] = useState();

    const [recordToAdd, setRecordToAdd] = useState();
    const [recordToEdit, setRecordToEdit] = useState();

    async function loadFormData() {
        const queryObj = {
            entityName: "表单数据记录",
            // query_config, 
            // facade 支持 query_config， 也支持 js 版本的 condition, sort
            condition: [{
                field: "表单", op: "eq", value: form.id
            }],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            fieldPaths: ["数据", "表单"],
            pageSize: 1000, offset: 0

        };
        const [result, error] = await facade.listAll(queryObj, {
            additionalQueryParams: {
                formId: form.id
            }
        });
        if (!error && result) {
            setFormData(result);
        }
    }

    useEffect(() => {
        // load all the forms;
        loadFormData()
    }, [form.id]);

    const ref = useRef();
    const [height, setHeight] = useState();

    useResizeObserver(ref, ({ contentRect }) => {
        setHeight(contentRect.height);
    })

    const baseColumns = form.字段.map(field => {
        return {
            title: field.name,
            dataIndex: field.key,
            type: field.type,
            typeOptions: field.typeOptions,

            fixed: field.isKeyField ? "left" : undefined,

            render: (value, record, index) => {
                const display = getFieldDisplay(field);
                const configParams = field.config && field.config.displayParams ? field.config.displayParams : {};
                const customParams = (() => {
                    if (field.type === 'Image') {
                        return {
                            width: 40,
                            height: 40,
                            processor: 'small',
                            ...configParams
                        }
                    } else if(field.type === "Text") {
                        return {
                            maxLines: 2
                        }
                    }
                    return configParams
                })()

                const Component = display.component;
                return (
                    <Component
                        value={value}
                        customParams={{
                            ...customParams,
                        }}
                        field={field}
                        env={{
                            // ...env, onMsg
                        }}
                    // recordValue={record[1]} // [_, rawValue]
                    />
                )
            }
        }
    });
    // 

    const columns = [
        ...baseColumns,
        {
            title: "",
            dataIndex: "op",
            width: 48,
            fixed: "right",
            render: (_, record) => {
                return (
                    <div className="operation-box">
                        {/* <IconButton {...{
                            icon: <IconCopy />,
                            onClick: _ => {
                                const initialValues = form.字段.reduce((acc, f) => {
                                    return {
                                        ...acc,
                                        [f.name]: record[f.key]
                                    }
                                }, {});
                                setRecordToAdd(initialValues)
                            }
                        }} /> */}
                        <IconButton {...{
                            icon: <IconLaunch />,
                            onClick: _ => {
                                setRecordToEdit(record)
                            }
                        }} />       
                    </div>
                )
            }
        }
    ]


    const data = formData && formData.data.map(r => {
        return {
            key: r.id,
            ...r.数据
        }
    });


    // usedValues
    // 只支持 ShortText 
    const usedValues = data ? form.字段.filter(f => {
        return f.type === "ShortText"
    }).reduce((acc, f) => {
        const allValues = data.map(d => d[f.key]).filter(x => !!x);
        const flattened = f.multivalued ? allValues.flatMap(x => x) : allValues;
        return {
            ...acc,
            [f.name]: uniq(flattened)
        }

    }, {}) : {};


    async function updateForm(formData, message) {
        // 1. save
        const [result, error] = await facade.update({
            entityName: "表单",
            formData,
            id: form.id,
            fieldPaths: []
        })

        if (!error) {
            onFormUpdated({
                ...formData,
                id: form.id
            })
            if(message) {
                Message.success(message);
            }
        }
    }


    return (
        <>
            <div className="lc-form-view" ref={ref}>
                <div className="form-view-toolbar">
                    <div className="left">
                        <FormTitle form={form} facade={facade}
                            {...{ onFormUpdated }}
                        ></FormTitle>
                        <Popconfirm
                            onOk={() => {
                                if (form.加入知识库) {
                                    updateForm({ 加入知识库: false })
                                } else {
                                    updateForm({ 加入知识库: true })
                                }
                            }}
                            title={form.加入知识库 ? "要将表单从知识库移除？" : "要将这个表单加入知识库？"}
                            content={form.加入知识库 ? "这不会影响表单和数据本身，只是它们不再被当做知识库的一部分" : "表单的数据会自动录入成为知识库的内容"}
                            okText={"确定"}
                            cancelText={"取消"}
                        >
                            <div>
                                <Tag checkable checked={form.加入知识库} color={"green"} 
                                    className={'toggle-knowledge-btn' + (form.加入知识库 ? "" : " inactive")}>
                                    {form.加入知识库 ?
                                        (
                                            <>
                                                <IconCheck></IconCheck>
                                                <span>已加入知识库</span>
                                            </>
                                        ) :
                                        (
                                            <>
                                                <span>加入知识库</span>
                                            </>
                                        )

                                    }
                                </Tag>
                            </div>
                        </Popconfirm>
                    </div>
                    <div className="right">
                        <ExportFormToExcelButton form={form} facade={facade} />
                        <Button buttonType='primary' label="添加数据" onClick={_ => {
                            setRecordToAdd({})
                        }}></Button>
                    </div>
                </div>
                <LightTable columns={columns} data={data}

                    totalCount={formData ? formData.count : undefined}
                    scroll={{ y: height ? height - 110 : undefined }}
                    noDataElement={
                        data === undefined ?
                            <div style={{ width: "100%", height: "300px" }}>
                                <Loading />
                            </div> : <div style={{ width: "100%", height: "300px" }}></div>
                    }
                    onRow={(record, index) => {
                        return {
                            onDoubleClick: a => {
                                setRecordToEdit(record)
                            }
                        }
                    }}
                />
            </div>
            {recordToAdd ?
                (
                    <FormDataAdd form={form} visible={true} setVisible={visible => {
                        if (!visible) {
                            setRecordToAdd()
                        }
                    }}
                        initialValues={recordToAdd}
                        facade={facade}
                        onAdded={_ => {
                            loadFormData();
                        }}
                        usedValues={usedValues}
                    />
                ) : null
            }
            {recordToEdit ?
                (
                    <FormDataEdit form={form} visible={true} setVisible={visible => {
                        if (!visible) {
                            setRecordToEdit();
                        }
                    }}
                        facade={facade}
                        record={recordToEdit}
                        onUpdated={_ => {
                            loadFormData();
                        }}
                        onDeleted={_ => {
                            loadFormData();
                        }}
                        usedValues={usedValues}
                    />
                ) : null
            }
        </>
    )
}


function FormTitle(props) {

    const { form, facade, onFormUpdated } = props;

    const [isNameEditing, setIsNameEditing] = useState(false);

    const [editingName, setEditingName] = useState(form.名称);

    const [fieldManagementVisible, setFieldManagementVisible] = useState(false);

    const droplist = (
        <Menu className="lc-dropdown-menu" onClickMenuItem={key => {
            if (key == "editName") {
                setIsNameEditing(true)
            } else if (key = "editFields") {
                setFieldManagementVisible(true)
            }
        }}>
            <Menu.Item key='editName'>修改名称</Menu.Item>
            <Menu.Item key='editFields'>编辑字段</Menu.Item>
        </Menu>
    );

    const saveName = async () => {
        if (editingName != form.名称) {
            // check if save necessary

            // 1. save
            const [result, error] = await facade.update({
                entityName: "表单",
                formData: {
                    名称: editingName
                },
                id: form.id,
                fieldPaths: []
            })

            if (!error) {
                onFormUpdated({
                    名称: editingName,
                    id: form.id
                })
                setEditingName(editingName)
            }
            // 2.
        }
        setIsNameEditing(false)
    }

    return (
        <>
            <div className="form-title">
                {
                    isNameEditing ? (
                        <div className="title-input">
                            <Input value={editingName} autoFocus={true}
                                onChange={v => {
                                    setEditingName(v);
                                }}
                                onBlur={_ => {
                                    // reset 
                                    setEditingName(form.名称);
                                    setIsNameEditing(false)
                                }}
                                suffix={<IconCheck onClick={_ => {
                                    saveName()
                                }} />}
                                onPressEnter={_ => {
                                    saveName();
                                }}
                            />
                        </div>
                    ) : (
                        <Dropdown droplist={droplist} trigger="click">
                            <div className="title-display" onDoubleClick={_ => {
                                setIsNameEditing(true)
                            }}>
                                <ClampedText text={form.名称} />
                                <IconCaretDown />
                            </div>
                        </Dropdown>
                    )
                }
            </div>
            {
                fieldManagementVisible ? (
                    <FieldManagementModal {...{
                        visible: fieldManagementVisible,
                        setVisible: setFieldManagementVisible,
                        form,
                        onFormUpdated,
                        facade
                    }} />
                ) : null
            }
        </>
    )

}

