

import React from 'react'

import {
    CgDisplayFullwidth, CgDisplayFlex, CgDisplaySpacing, CgFontSpacing
} from 'react-icons/cg';
import { AiFillEyeInvisible } from 'react-icons/ai'

import Tooltip from 'bwax-ui/components/Tooltip';
import ResetableLabel from '../components/ResetableLabel';

import FlexAttributes from './FlexAttributes'

export default function Display({ attributes, blockNode, onChange }) {

    function isDisabled(value) {
        if (blockNode && typeof (window) !== 'undefined') {
            const parentDisplay = window.getComputedStyle(blockNode.parentNode)["display"];
            return parentDisplay === "flex" && (value === "inline-block" || value === "inline")
        } else {
            return false
        }
    }

    const options = [{
        value: "block", icon: <CgDisplayFullwidth />, tooltip: "Block elements start on a new line and take up the full available width.",
        disabled: isDisabled('block')
    }, {
        value: "flex", icon: <CgDisplayFlex />, tooltip: "Flex lays out its child elements on a horizontal or vertical axis.",
        disabled: isDisabled('flex'),
        subAttrs: <FlexAttributes attributes={attributes} onChange={attribute => onChange(attribute)}/>
    }, {
        value: "inline-block", icon: <CgDisplaySpacing />, disabled: isDisabled('inline-block'),
        tooltip: isDisabled('inline-block') ? "Inline-block doesn’t affect flex children." :
            "Inline-block behaves like inline, but accepts width and height properties."
    }, {
        value: "inline", icon: <CgFontSpacing style={{ fontSize: 16 }}/>, disabled: isDisabled('inline'),
        tooltip: isDisabled('inline') ? "Inline doesn’t affect flex children." :
            "Inline is the default for text content. The text’s font size and line height determine its size."
    }, {
        value: "none", icon: <AiFillEyeInvisible />, tooltip: "None hides elements.",
        disabled: isDisabled('none')
    }]

    function renderSubAttrs () {
        const activeOption = options.find(o => o.value === attributes['display'])
        return activeOption && activeOption.subAttrs ? activeOption.subAttrs : null
    }

    return (
        <div className='display-styles-container'>
            <div className='style-with-options'>
                <ResetableLabel allAttributes={attributes} label={"Display"} attributesGroup="layout" attribute={'display'} onChange={onChange}/>
                <div className='options'>
                    {
                        options.map((op, index) => {
                            const { value, icon, tooltip, disabled } = op
                            const isActive = Object.keys(attributes).some(k => k === "display") && attributes['display'] === value
                            return (
                                <Tooltip key={index} text={tooltip} className="limited-width-tooltip">
                                    <div className={`option-box ${isActive ? 'active' : (disabled ? 'disabled' : '')}`}
                                        onClick={() => !disabled ? onChange({"display": value }) : null }
                                    >
                                        {icon}
                                    </div>
                                </Tooltip>
                            )
                        })
                    }
                </div>
            </div>
            { renderSubAttrs() }
        </div>
    )

}
