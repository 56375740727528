

import React from 'react'

const ApplicationContext = React.createContext();

export default ApplicationContext;

export const ApplicationContextProvider = props => {

    const { children, applications, currentApplication, mode = "app" /* or design */ } = props

    return (
        <ApplicationContext.Provider value={{
            applications, currentApplication, mode
        }}>
            { children }
        </ApplicationContext.Provider>
    );
}
