
import invariant from 'invariant'

import { runDataQuery } from "./runClientQuery"

import buildSelectionTree from './buildSelectionTree'
import printQuerySelection from './printQuerySelection'

const defaultQueryType = "findOne"

const defaultPageSize = 50 // by default the page size is 50

const pu = " "


/// if the query has "excluded", then it should be remove:
function excludeQueries(queries) {
    return Object.keys(queries).reduce((acc, name) => {
        const query = queries[name]
        if(query.excluded == true) {
            return acc;
        } else {
            return {
                ...acc,
                [name]: query
            }
        }
    }, {})
}

export function buildSorting(entity, varValue) {
    // const resolveInputKey = name => {
    //     if (name === "id") return "id"
    //     const inputField = entity.fields.find(f => f.name === name)
    //     invariant(inputField, `实体[${entity.name}]里没有字段[${name}]`)

    //     if (inputField.type === "Link" && inputField.multivalued !== true) {
    //         return inputField.key + "Id"
    //     } else {
    //         return inputField.key
    //     }
    // }

    return Object.keys(varValue).map(key => {
        const order = varValue[key]
        return {
            field: key,
            order
        }
    })
}

export function buildCondition(varValue) {
    function normalize(varValue) {
        if(Array.isArray(varValue) && varValue.every(v => Array.isArray(v))) {
            return varValue
        } else if(Array.isArray(varValue)) {
            return [ varValue ]
        } else {
            return [[ varValue ]]
        }
    }

    const varArrays = normalize(varValue);
    // [[{}]]
    
    return varArrays.map(vs => vs.map(
            // vs: [{}] 
            v => Object.keys(v).map(
                (n) => {
                    const resolveInputValue = original => {
                        if (original === undefined) {
                            console.warn("Param", n, "is undefined")
                            return original
                        }
                        if (original === null) {
                            return original
                        }
                        if (n === "id") {
                            return original.id || original
                        }
                        // if (inputField.type === 'Link') {
                        //     return original.id || original
                        // } else {
                        //     return original
                        // }
                        // TODO 暂时硬转
                        return original.id || original
                    }
                    
                    const conditionOpTypes = [
                        "ne", "eq", "in", "notIn", 
                        "gt", "gte", "lt", "lte", "between", "notBetween",
                        "like", "notLike", "startsWith", "endsWith", "substring", "regexp", "notRegexp",
                        "contains", "notContains"
                    ]

                    const getOpAndValue = (rawValue) => {
                        
                        if(rawValue === null ) {
                            return [{op: "eq", value: null}]
                        }

                        const hasOp = typeof(rawValue) === "object" && Object.keys(rawValue).find(
                            n => conditionOpTypes.indexOf(n) !== -1
                        )

                        // 假设是一个纯粹的值， 没有 op
                        if(!hasOp) {
                            return [{op: "eq", value: resolveInputValue(rawValue) }]
                        }

                        // 假设里面有 op 值, 如 { lt: "" }
                        return Object.keys(rawValue).map(n => {
                            const isExistingOp = conditionOpTypes.indexOf(n) !== -1
                            if(isExistingOp) {
                                return {
                                    op: n,
                                    value: resolveInputValue(rawValue[n])
                                }
                            } else {
                                return {
                                    op: 'eq',
                                    value: resolveInputValue(rawValue[n])
                                }
                            }
                        }) 
                    }
                    
                    if(v[n] === undefined) {
                        return undefined
                    }

                    const opAndValues = getOpAndValue(v[n])

                    return opAndValues.map(oav => {
                        return {
                            field: n,
                            ...oav
                        }
                    })
                }
            ).filter(x => x !== undefined).reduce((acc, arr) =>[...acc, ...arr], [])
            // [[[]]]
        ).reduce((acc, arr) => [...acc, ...arr], []) //flatten
    ).filter(a => {
        return Array.isArray(a) && a.length !== 0
    });
}

export function mergeCriteria (fixed, criteria) {
    const fixedCondition = buildCondition(fixed);


    const pc = criteria
    if(pc !== undefined) {
        // (A || B) & (C || D) => (A && C) || (A && D) || (B && C) || (C && D)
        let builtPC =  buildCondition(pc)

        // 要考虑有个 condition 为 空的情况：
        if(fixedCondition.length === 0) {
            return builtPC
        } else if(builtPC.length === 0) {
            return fixedCondition
        }
        return fixedCondition.reduce((acc, c) => {
            // it is like  (A && C) || (A && D);
            const merged = builtPC.map(b => {
                return [ ...c, ...b ]
            });
            return [
                ...acc,
                ...merged
            ]
        }, [])
    } else {
        return fixedCondition
    }

}


function queryIdAndVars(queries, allEntities) {
    const queryIds = Object.keys(queries).reduce((acc, name, index) => {
        return { ...acc, [name]: "q" + index }
    }, {})

    const vars = Object.keys(queries).reduce((acc, name) => {

        const { entityName, criteria, sortBy, pagination } = queries[name]

        const qId = queryIds[name]

        const entity = allEntities.find(e => e.name === entityName)
        
        invariant(entity, "The entity with name `" + entityName + "` is not found.");

        const entityKey = entity.key

        const conditionVar = criteria ? {
            name: `${qId}Condition`,
            as: "condition",
            type: `[[List${entityKey}ConditionInput]]`
        } : null

        const sortVar = sortBy ? {
            name: `${qId}Sort`,
            as: "sort",
            type: `[List${entityKey}SortInput]`
        } : null

        /// pargination vars:
        const firstVar = {
            name: `${qId}First`,
            as: "first",
            type: "Float"
        }

        const afterVar = (pagination && pagination.__after__) ? {
            name: `${qId}After`,
            as: "after",
            type: "String"
        } : null


        const vars = [conditionVar, sortVar, firstVar, afterVar].filter(e => e !== null)

        return {
            ...acc,
            [name]: vars
        }
    }, {})

    return { queryIds, vars }

}

function toQueryText(queries, allEntities, printSelection) {

    const { vars, queryIds } = queryIdAndVars(queries, allEntities)

    const varTexts = Object.keys(vars).map(key => {
        return vars[key].map(
            ({ name, type }) => `$${name}: ${type}`
        ).join(", ")
    }).filter(t => t.trim().length > 0)

    const varsText = varTexts.length === 0 ? "" : `(${varTexts.join(", ")})`

    const textOfQueries = Object.keys(queries).reduce((acc, name) => {

        const query = queries[name]

        const queryType = query.type;
        
        // only support entity queries for now
        const entity = allEntities.find(e => e.name === query.entityName)

        const varsText = vars[name].map(
            ({ name, as }) => `${as}: $${name}`
        ).join(", ")

        const qId = queryIds[name]

        if(queryType === "count") {
            // only count
            return (
                acc + "\n" +
                pu + `${qId}:list${entity.key}` + `(${varsText})` + " {\n" +
                pu + pu + "count\n" +
                pu + "} \n"
            )
        } else if (queryType === "listAll") {
            // data, count and pageinfo
            return (
                acc + "\n" +
                pu + `${qId}:list${entity.key}` + `(${varsText})` + " {\n" +
                pu + pu + "count\n" +
                pu + pu + "pageInfo { startCursor, endCursor, hasNextPage, hasPreviousPage }\n" +
                pu + pu + "edges {\n" +
                pu + pu + pu + "cursor\n" +
                pu + pu + pu + "node {\n" +
                pu + pu + pu + pu + "id" +
                printSelection(name, 4) + "\n" +
                pu + pu + pu + "}\n" +
                pu + pu + "}\n" +
                pu + "} \n"
            )
        } else {
            /// list or find-one;
            return (
                acc + "\n" +
                pu + `${qId}:list${entity.key}` + `(${varsText})` + " {\n" +
                pu + pu + "edges {\n" +
                pu + pu + pu + "node {\n" +
                pu + pu + pu + pu + "id" +
                printSelection(name, 4) + "\n" +
                pu + pu + pu + "}\n" +
                pu + pu + "}\n" +
                pu + "} \n"
            )
        }

    }, "")
    return (
        "query" + (varsText.length > 1 ? " " + varsText : "") + " {" +
        textOfQueries + "\n" +
        "}"
    )
}

export function prepareVariables(allQueries, allEntities, partialVariables = {}) {

    /// exclude query if query.excluded == true
    const queries = excludeQueries(allQueries)

    const { vars } = queryIdAndVars(queries, allEntities)

    const queryVariables = Object.keys(queries).reduce((acc, queryName) => {
        if (!vars[queryName]) { return acc }

        const getQueryVars = (queryVarDefs) => {
            /// return an object that contains all the variable values for this query
            // TODO currently only support entity query
            const entity = allEntities.find(e => e.name === queries[queryName].entityName)

            return queryVarDefs.reduce((acc, varDef) => {
                const { name, as } = varDef

                const buildQueryVars = QueryVarBuildersForEntity(entity)[as]

                invariant(buildQueryVars, `unsupported var: ${as}`)

                const variable = buildQueryVars(queries[queryName], partialVariables[queryName])

                return {
                    ...acc, [name]: variable
                }
            }, {})
        }

        const queryVars = getQueryVars(vars[queryName])
        return {
            ...acc,
            [queryName]: queryVars
        }
    }, {})



    const variables = Object.keys(queryVariables).reduce((acc, key) => {
        return {
            ...acc,
            ...(queryVariables[key])
        }
    }, {})

    return variables
}

function extractData({ data, errors }, queries, allEntities) {

    if(data === undefined) {
        return {}
    }

    const { queryIds } = queryIdAndVars(queries, allEntities)

    return Object.keys(queryIds).reduce((acc, name) => {

        const query = queries[name]
        // obtained from list
        
        const queryData = data[queryIds[name]]
        const dataToReturn = (queryType) =>  {
            if(queryType == "listAll") {
                return queryData;
            } else if(queryType == "list") {
                return queryData;
            } else if(queryType == "findOne") {
                const nodeData = queryData.edges.map(e => e.node)
                return nodeData.length > 0 ? nodeData[0] : null;
            } else {
                /// count;
                return queryData.count;
            }
        }

        if(queryData == null) {
            console.log("query returned null", name)
        } 

        const pageInfo = queryData.pageInfo;
        return {
            ...acc,
            [name]: {
                queryId: queryIds[name],
                data: dataToReturn(query.type || defaultQueryType),
                pageInfo,
            } /// found or undefined
        }
    }, {})

}

//// query var builder:
const QueryVarBuildersForEntity = (entity) => {

    return {
        "sort": (queryValue, partialVariables = {}) => {

            
            const partialSort = partialVariables.sortBy || {}

            const querySort = queryValue["sortBy"] || {}

            const merged = Object.keys(querySort).reduce((acc, k) => {
                if (k in partialSort) {
                    return acc 
                } else {
                    return {
                        ...acc, 
                        [k]: querySort[k]
                    }
                }
            }, partialSort);

            return buildSorting(entity, merged);

        },
        "condition": (queryValue, partialVariables = {}) => {

            const varValue = queryValue["criteria"]
        
            /// criteria: var can be [[]], [] or simply a object
            ///     - when it is [[]] means the top level op is OR
            //      - when it is [] means the top level op is AND
            //      - when it is an object, there's no boolean op

            /// make it normalized      
        
            return mergeCriteria(varValue, partialVariables.criteria);

        },
        "first": (queryValue, partialVariables = {}) => {
            ///
            /// default page size
            const { type, pagination } = queryValue

            let actualPageSize = (pagination && pagination.pageSize) ? pagination.pageSize : defaultPageSize;
            const sizeToFetch = {
                listAll: actualPageSize,
                list: actualPageSize,
                findOne: 1,
                count: 0,
            }

            const pageSize = sizeToFetch[type || defaultQueryType]

            const first = partialVariables.first || pageSize

            return first
        },

        "after": (queryValue, partialVariables = {}) => {
            const { pagination } = queryValue
            const after = partialVariables.after || pagination.__after__
            return after
        },
    }
} ///

export function printQuery(allQueries, targetNode, { allEntities, allDataTypes }) {

    /// exclude query if query.excluded == true
    const queries = excludeQueries(allQueries)

    
    const selectionTree = buildSelectionTree({
        expressions: targetNode.getAllExpressionsIncluded(),
        allDataTypes,
        queries,
    })

    return toQueryText(
        queries,
        allEntities,
        (queryName, depth) => {
            const subTree = selectionTree[queryName]
            if(subTree === undefined) {
                // console.warn('No selections for', queryName)
            }

            return printQuerySelection(subTree, depth)
        }
    )
}

export default async function resolveAndRunQuery(allQueries, targetNode, partialVariables, {
    allDataTypes, allEntities, queryRunner
}) {
    /// exclude query if query.excluded == true
    const queries = excludeQueries(allQueries)
    if(Object.keys(queries).length === 0) {
        return {}

    } else {
        const variables = prepareVariables(queries, allEntities, partialVariables)
        const queryText = printQuery(queries, targetNode, { allDataTypes, allEntities})

        const __runQuery__ = queryRunner || runDataQuery()
    
        const result = await __runQuery__(queryText)(variables)
        const queryData = extractData(JSON.parse(result), queries, allEntities)
    
        return Object.keys(queryData).reduce((acc, name) => {
            return {
                ...acc,
                [name]: queryData[name].data
            }
        }, {})

    }


}


