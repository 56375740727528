import React, { useState, useEffect } from 'react'

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import { hashCode } from 'bwax/utils';

import queryString from 'query-string'

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary';
import { ExecutionContextProvider } from 'bwax-ui/page/ExecutionContext';
import { DataLoaderProvider } from 'bwax-ui/store/DataLoaderContext'
import { QueryTargetContextProvider } from 'bwax-ui/legacy/store/QueryTargetContext'

import AdminPageContainer from 'Client/ml/AdminPageContainer';

import { Helmet } from 'react-helmet-async';

export default function AdminPagePreview(props) {

    const { initParamExprs, facade, compiledStr, dlc, runtimeProfile, route_to, testRecordId } = props

    const params = lang_entry_slim.expr_val_assoc_list_to_js_obj(
        lang_entry_slim.evaluate(facade.baseEnv, undefined, initParamExprs[0])
    );

    const [tab, setTab] = useState();

    useEffect(() => {
        if(typeof(document) !== 'undefined') {
            document.body.className = "bw-admin";
        }

    }, [])
    
    const urlPattern = "/_test_/:tab";

    const [pathname, remaining] = Object.keys(params).reduce((acc, name) => {
        const [u, r] = acc;
        if (u.indexOf(":" + name) !== -1) {
            const { [name]: value, ...remaining } = r;
            const url = u.replace(":" + name, value);
            return [url, remaining]
        } else {
            return [u, r]
        }
    }, [urlPattern, params])


    const search = queryString.stringifyUrl({
        url: pathname, query: remaining
    }).replace(pathname, "");


    const { userenv } = dlc;
    const domainEnv = { 
        protocol: "https",
        mobileHost: userenv.mobileHosts[0],
        isSandbox: dlc.sandbox,
        tenantCode: dlc.tenantCode,
    }

    const currentURL = pathname + search; // hash 不支持
    const currentURLPath = pathname;
    const webEnv = {
        protocol: "https", 
        host: userenv.mobileHosts[0], 
        isSandbox: dlc.sandbox,
        isIOS: userenv.isIOS,
        isWeChat: userenv.isWeChat,
        isWxWork: userenv.isWxWork,
        isMobile: userenv.isMobile,
        currentURL,
        currentURLPath,
        originalURL: userenv.originalURL,  
        tenantCode: dlc.tenantCode,      
    }

    const pageID = hashCode(compiledStr);


    const renderAdmin = () => (
        <AdminPageContainer {
            ...{
                routeTo: route_to, facade,
                match: {
                    params: {
                        pageName: "_testing_",
                        tab,
                        ...params
                    },
                    path: urlPattern
                },
                compiled: compiledStr,
                location: {
                    pathname,
                    search,
                },

                domainEnv, webEnv,

                // changeTab
                changeTab: tab => {
                    setTab(tab)
                },

                entityName: runtimeProfile.entityName,
                recordId: testRecordId,


                pageName: pageID,

                key: pageID,
            }
        } />
    );

    return (
        <QueryTargetContextProvider queryTarget="data">
        <Helmet>
            <title>{`[管理页面] ${runtimeProfile.dataName || "<测试页面>"}`}</title>
        </Helmet>
        <ErrorBoundary envKey={pageID}>
            <ExecutionContextProvider designMode={true} routeTo={route_to}>
                <DataLoaderProvider {...dlc}>
                    { renderAdmin() }
                </DataLoaderProvider>
            </ExecutionContextProvider>
        </ErrorBoundary>
    </QueryTargetContextProvider>
    )
}
