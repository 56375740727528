
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';


export default createBlockTypeButton({
  blockType: 'header-two',
  children: (
    "H2"
  ),
});
