
import 'core-js/es'
import 'regenerator-runtime/runtime'

import "isomorphic-fetch";

import Cookies from 'js-cookie'

import React from 'react'
import ReactDOM from 'react-dom/client'

// import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router'

import ClientApp from './ClientApp'
import PreviewContainer from './designApp/PreviewContainer'

import { DataLoaderProvider } from 'bwax-ui/store/DataLoaderContext';

import { HelmetProvider } from 'react-helmet-async';

import { isMobile, isWeChat, isIOS } from 'bwax/clientEnv'
import getTokenCookieName from 'bwax/query/getTokenCookieName';

const appData = document.data || {}

const pathname = window.location.pathname;
const isSandBox = pathname.startsWith('/sandbox');

// 
const tenantCode = (() => {
    // 可能是根据域名，也可能是根据 pathname，
    // 如果要根据域名的话，可能要在服务器端就确定好：

    // 这里根据 pathname 来
    
    const pattern = /^(\/sandbox)?\/-\/([^\/]+)/;

    const matched = pathname.match(pattern);
    
    if(matched && matched[2]) {
        return matched[2].toLowerCase();
    }
})();




// / only used for DEV
// / TODO: need better solution
if (Object.keys(document.userenv || {}).length === 0) {
    console.log("No userenv yet, add testing mobileHosts")
    document.userenv = {
        // mobileHosts: ["dev.cb.tikicrowd.com"],

        // mobileHosts: ["translate.simplifyai.cn"],
        mobileHosts: ["simplifyai.cn"],

        deviceId: "admin-test",
        domainKey: "dev", // 在 dev 状态下这个无所谓
    };

    // 测试 cb
    // document.userenv = {
    //     mobileHosts: ["cb.qunfengshe.com"],        
    //     deviceId: "admin-test",
    //     domainKey: "cb", // 在 dev 状态下这个无所谓
    // };

    document.dev = true;
}

const tokenCookieName = getTokenCookieName({ tenantCode, sandbox: isSandBox })
const sessionToken = Cookies.get(tokenCookieName);

const basename = (isSandBox ? "/sandbox" : "") + (tenantCode ? `/-/${tenantCode}` : "");

const app = (
    <HelmetProvider>
        <BrowserRouter {...(basename ? { basename } : {})}>
            <Switch>
                <Route path="/preview" component={PreviewContainer} />
                <Route path="/" render={_ => {
                    return (
                        <DataLoaderProvider
                            appData={appData}
                            sandbox={isSandBox}
                            sessionToken={sessionToken}
                            tenantCode={tenantCode}
                            pageInits={{}}
                            userenv={{
                                mobileHosts: document.userenv.mobileHosts,
                                isMobile: isMobile(),
                                isWeChat: isWeChat(),
                                isIOS: isIOS(),
                                currentUrl: document.location.href,
                            }}
                            {...document.userenv}
                        >
                            <ClientApp />
                        </DataLoaderProvider>
                    )
                }} />
            </Switch>
        </BrowserRouter>
    </HelmetProvider>
)

const rootElement = document.getElementById("app");

const root = ReactDOM.createRoot(rootElement);
root.render(app);


// if(isDev) {
//     console.log("Dev, don't hydrate")
//     const root = ReactDOM.unstable_createRoot(rootElement);
//     root.render(app);
// } else {
//     const root = ReactDOM.unstable_createRoot(rootElement, { hydrate: true });
//     console.log("Hydrate the app")
//     root.render(app);
//     // console.log("Render App");
//     // ReactDOM.renner(app. document.getElementById('app'));
// }
