// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxAdmin = require("../../../bwax-js/ml/utils/json.bs.js");
var ClientEnv = require("bwax/clientEnv");
var Plate$BwaxAdmin = require("../../../bwax-js/ml/plate.bs.js");
var DomUtils$BwaxAdmin = require("../../re/utils/DomUtils.bs.js");
var Caml_chrome_debugger = require("bs-platform/lib/js/caml_chrome_debugger.js");

var mut_createAlipayOrder = "\n  mutation ($input: CreateAlipayOrderInput!) {\n    createAlipayOrder(input: $input) {\n      alipayOrder {\n        payFormHtml\n      }\n    }\n  }\n";

function createAlipayOrder(query_runner, orderId, extended_params, quitUrl, returnUrl, productCode) {
  var handle_result = function (text) {
    var result = Json$BwaxAdmin.parse(text);
    var partial_arg = /* :: */Caml_chrome_debugger.simpleVariant("::", [
        "data",
        /* :: */Caml_chrome_debugger.simpleVariant("::", [
            "createAlipayOrder",
            /* :: */Caml_chrome_debugger.simpleVariant("::", [
                "alipayOrder",
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    "payFormHtml",
                    /* [] */0
                  ])
              ])
          ])
      ]);
    return Promise.resolve(Plate$BwaxAdmin.$$Option.and_then(Js_json.decodeString, Plate$BwaxAdmin.$$Option.and_then((function (param) {
                          return Json$BwaxAdmin.get_by_path(partial_arg, param);
                        }), result)));
  };
  var match = extended_params[/* hbfq */0];
  var extendParams;
  if (match !== undefined) {
    var match$1 = match;
    extendParams = Caml_option.some(Json$BwaxAdmin.json_object(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "hb_fq_num",
                  String(match$1[0])
                ],
                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "hb_fq_seller_percent",
                      String(match$1[1])
                    ],
                    /* [] */0
                  ])
              ])));
  } else {
    extendParams = undefined;
  }
  return Curry._3(query_runner, mut_createAlipayOrder, Js_dict.fromList(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                      /* tuple */[
                        "input",
                        {
                          clientMutationId: new Date().getTime().toString(),
                          orderId: orderId,
                          quitUrl: quitUrl,
                          returnUrl: returnUrl,
                          extendParams: extendParams,
                          productCode: productCode
                        }
                      ],
                      /* [] */0
                    ])), undefined).then(handle_result);
}

function initiate_pay(query_runner, orderId, extended_params, onPayInitiated, onError) {
  var currentUrl = Plate$BwaxAdmin.$$Option.map((function (l) {
          return l.href;
        }), DomUtils$BwaxAdmin.$$location);
  if (currentUrl !== undefined) {
    var url = Caml_option.valFromOption(currentUrl);
    var productCode = ClientEnv.isMobile() ? "QUICK_WAP_WAY" : "FAST_INSTANT_TRADE_PAY";
    createAlipayOrder(query_runner, orderId, extended_params, url, url, productCode).then((function (payFormHtml) {
            Curry._1(onPayInitiated, payFormHtml);
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  } else {
    return Curry._1(onError, "未能获取当前页面地址");
  }
}

exports.mut_createAlipayOrder = mut_createAlipayOrder;
exports.createAlipayOrder = createAlipayOrder;
exports.initiate_pay = initiate_pay;
/* Json-BwaxAdmin Not a pure module */
