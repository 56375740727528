import React, { useState, useEffect, useContext } from 'react'

import Loading from 'Client/js/components/Loading';

import { FacadeContextProvider } from 'bwax/util/FacadeContext'
import { ApplicationContextProvider } from 'bwax/util/ApplicationContext'

//components
import {
    Layout,
} from 'antd'

import { Icon } from '@ant-design/compatible';

const { Sider } = Layout
import DesignAppRoutes from './DesignAppRoutes'
import DesignSideBar from './DesignSideBar'

import { QueryTargetContextProvider } from 'bwax-ui/legacy/store/QueryTargetContext'

import './DesignApp.less'

//utils

import { make as PublishButton } from 'Client/re/PublishButton.bs';

import useModalView from 'Client/js/hooks/useModalView'
import useCurrentUser from 'bwax-ui/auth/useCurrentUser';


import { Helmet } from 'react-helmet-async';

import "bwax/ml/graph.bs"

import useBaseSettings from 'bwax-ui/legacy/store/useBaseSettings';

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import DesignTopBar from './DesignTopBar';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext';

import { setupDefinitionQueryRunner } from 'bwax/query/runClientQuery';

const collapseKey = "design-app-menu-collapsed";
function getLocalItem(key) {
    if (typeof (localStorage) !== 'undefined') {
        return localStorage.getItem(key)
    } else {
        return undefined
    }
}
function setLocalItem(key, value) {
    if (typeof (localStorage) !== 'undefined') {
        return localStorage.setItem(key, value);
    }
}

const v = getLocalItem(collapseKey);
const storedCollapsed = v ? JSON.parse(v) : false;


export default function DesignApp(props) {

    const { history, location, widgetFactory, match } = props

    // states begins..
    const { currentUser } = useCurrentUser({ queryTarget: "definition" });

    const baseSettings = useBaseSettings();
    // load extension from sandbox:

    const baseDlc = useContext(DataLoaderContext);
    const dlc = {
        ...baseDlc,
        isDesignMode: true,
    }


    // The single facade use for design
    const [facade, setFacade] = useState(undefined);
    const [ applications, setApplications ] = useState([]);

    useEffect(() => {
        const BwaxFacade = require("bwax/facade").default;
        const queryRunner = setupDefinitionQueryRunner(dlc);
        (async () => {
            const facade = new BwaxFacade({
                queryRunner, dlc,
                isDesignMode: true,

                applicationCode: "Design",
            });
            await facade.init();
            await facade.prepareAll()


            const queryObj = {
                entityName: "应用",
                // condition: mergeCriteria(baseCriteria, criteria),
                pageSize: 1000,
                offset: 0,
                fieldPaths: [ "应用名", "代号" ],
            };
            const [result, error] = await facade.listAll(queryObj, {});

            // TODO error handling
            const applications = result.data.map(app => {
                return {
                    name: app["应用名"],
                    code: app["代号"],
                    id: app.id,
                }
            });

            setFacade(facade);
            setApplications(applications);

        })()
    }, []);

    // states ends...

    // effects:
    useEffect(() => {
        /// check user login at interval, TODO DDD
        checkUser()
    }, [currentUser])


    function checkUser() {
        const isNotLoggedIn = currentUser === null
        const noAccess = currentUser && !currentUser.systemRoles.some(r => {
            return r === "Designer"
        })
        if (isNotLoggedIn) {
            history.replace(addQueryParam('rt', location.pathname, '/login'))
        }
        if (noAccess) {
            history.replace("/no-permission")
        }
    }

    function renderBody() {
        if (!facade) {
            return <Loading></Loading>
        }

        return (
            <DesignAppBody {...{
                currentUser, match, widgetFactory, baseSettings,
                history, location, applications, facade
            }} />
        )
    }

    return (
        <QueryTargetContextProvider queryTarget="definition">
            <Helmet><title>设计后台</title></Helmet>
            {renderBody()}
        </QueryTargetContextProvider>
    )

}


function DesignAppBody({ currentUser, match, widgetFactory, history, location, baseSettings, applications, facade }) {


    const appcode = match.params.appcode;

    const currentApplication = applications.find(app => app.code.toLowerCase() === (appcode || "default").toLowerCase());

    const [collapsed, setCollapsed] = useState(storedCollapsed);

    const { withModalView } = useModalView(location, history);

    
    function toggle() {
        setCollapsed(prev => {
            const collapsed = !prev;
            setLocalItem(collapseKey, JSON.stringify(collapsed));
            return collapsed
        })
    }

    const routeTo = (path, { modal = true } = {}) => {
        function wrapPath(path) {
            if (!path.startsWith("/design") && !path.startsWith("http")) {
                return "/design/" + (currentApplication ? currentApplication.code.toLowerCase() : "default") + path
            } else {
                return path
            }
        }
        history.push(wrapPath(path), { modal })
    }

    return (
        <div className='design-app full-height'>
            <Layout>
                <FacadeContextProvider facade={facade}>
                <ApplicationContextProvider
                    applications={applications}
                    currentApplication={currentApplication}
                    mode="design"
                >
                    <Sider
                        trigger={null}
                        collapsed={collapsed}
                        collapsedWidth={64}
                        className='cb-app-sider'
                        width={160}
                    >
                        <DesignTopBar
                            {...{
                                currentUser, baseSettings, history, location,
                                currentApplication, applications,
                                compact: true
                            }}
                        />
                        <div style={{ 
                            overflow: 'auto', 
                            // height: "calc(100% - 44px)", marginTop: 4, 
                            paddingBottom: 50 
                        }}>
                            <DesignSideBar {...{ history, applications, currentApplication }} />
                        </div>
                        <div className="trigger-box-div">
                            <div className="trigger-box">
                                <Icon
                                    className="trigger"
                                    type={collapsed ? 'menu-unfold' : 'menu-fold'}
                                    onClick={toggle}
                                />
                            </div>
                        </div>
                    </Sider>
                    {
                        withModalView(
                            location => (
                                <DesignAppRoutes
                                    widgetFactory={widgetFactory}
                                    location={location}
                                    allDataTypes={facade.dataTypes}
                                    allEntities={facade.entities}
                                    routeTo={routeTo}
                                    loadingComp={Loading}
                                    currentUser={currentUser}
                                    currentApplication={currentApplication}
                                    facade={facade}
                                />
                            ),
                            collapsed
                        )
                    }
                    {
                        currentUser && currentUser.systemRoles.indexOf("Maintainer") !== -1 ?
                            <PublishButton routeTo={routeTo} /> : null
                    }
                </ApplicationContextProvider>
                </FacadeContextProvider>
            </Layout>
        </div>
    )
}



