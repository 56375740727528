


export default class BaseStrategy  {

    constructor() {
    }

    dispose() {
        // do nothing
    }

    isRunnable () {
        return false
    }

    deriveChangesFromPreviewData (value, codeFieldName, previewData) {
        return {}
    }

    getTestRecordOptions () {
        return null;
    }

    async startRunning () {
        return { type: "noop", needToStop: false, data: {} }
    }

    stopRunning (runningData) {
        // do nothing
        
    }

}