



import { buildAddRecordMutation } from '../../../../bwax-js/query/QueryBuilder'

import { invalidateCache } from 'bwax-ui/legacy/store/useDataLoader'

export default function AddRecord ({allEntities, allDataTypes, queryRunner }) {

    return async function (params) {

        const { formData, entityName } = params

        const entity = allEntities.find(e => e.name === entityName)

        const { queryText, buildInput, extractResult } = buildAddRecordMutation(
            entity, allEntities, allDataTypes, {
                queryDepth: 1
            }
        )

        /// TODO 检查输入是否合理

        // return "!22"

        const result = await queryRunner(queryText, {
            input: buildInput(formData)
        })

        /// TODO error handling

        /// invalidate cache if succeeded
        invalidateCache()

        const data = extractResult(result)
        
        console.log("Returning Data", data)

        return data

    }
}