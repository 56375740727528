
import React, { useEffect, useState, useContext } from 'react'

import { evaluateWithQuery } from 'bwax'
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import QueryTargetContext from 'bwax-ui/legacy/store/QueryTargetContext';

// 对于 ref:
//  有两种模式，一种是从 formValues 查，用于添加框，或者可能会修改对应关联字段的编辑框； 
//  另一种是从当前 id 开始查，用于不修改对应关联字段的编辑框
//  如果 formValues 不存在当前的 ref 而且传入了 id， 则使用后一种模式：
export default function ValidationSourcing(props) {

    const { params, allEntities, allDataTypes, dlc } = props;

    const queryTarget = useContext(QueryTargetContext) || "data";

    const { validations, recordId, entityName, updateValidationValues, formValues } = params;

    const entity = allEntities.find(e => e.name == entityName);

    const [cachedValues, setCachedValues] = useState({});

    // validation 可能是 { ref: <关联字段路径>, lookup: <共同选项引用>, list: <可选值数据>, .... }
    const allRefs = validations.filter(([v, _]) => v.ref).map(([v, field]) => [v.ref, field]);

    const allLookups = validations.filter(([v, _]) => v.lookup).map(([v, field]) => [v.lookup, field]);

    // for refs:
    // { <fieldName> : <refs> }
    const allFieldNames = allRefs.reduce((acc, refAndField ) => {
        const [ ref, _ ] = refAndField;
        const path = ref.split(".");
        if (path.length > 0) {
            const fieldName = path[0];
            const refs = acc[fieldName] ? [ ...acc[fieldName], refAndField ] : [ refAndField ];
            return {
                ...acc,
                [fieldName]: refs
            }
        } else {
            return acc
        }
    }, {})

    function getFieldValue(fieldName) {
        // get value from validated
        return formValues[fieldName];
    }

    const editingFieldValues = Object.keys(allFieldNames).map(getFieldValue);

    const { tenantCode, sessionToken, sandbox } = dlc || useContext(DataLoaderContext);

    const evaluate = (def, baseData) => {
        return evaluateWithQuery(
            def,
            baseData,
            {
                allDataTypes, allEntities, queryTarget, 
                sessionToken, tenantCode, sandbox
            }
        )
    }

    // 这里处理 ref 关联字段路径
    useEffect(() => {

        // 判断 fieldNames 对应的值有没有变化：
        // 如果有变化，则收集对应的新值：
        // 如果是 undefined，也收集，因为那个时候就用 id 模式来查
        const changedFields = Object.keys(allFieldNames).reduce((acc, fieldName) => {
            const value = getFieldValue(fieldName);
            const cached = cachedValues[fieldName];
            if (value != cached || value === undefined) {
                // 找到 field
                const field = [ ...entity.fields, ...entity.virtualFields ].find(f => f.name == fieldName);
                if(field && field.type == "Link" && field.options && field.options.entity) {
                    return {
                        ...acc,
                        [fieldName]: [field, value, allFieldNames[fieldName]] // (field, value, refs)
                    }
                } else {
                    return acc
                }

            } else {
                return acc
            }
        }, {})

        // 对于所有的 changed fields，查询相关的 refs 
        Object.keys(changedFields).forEach(async fieldName => {
            const [ field, value, refs ] = changedFields[fieldName];  

            if(value !== undefined && value !== null) {
                const def = buildQueryDefFromFieldValue(field, value, refs, { allEntities });
                const result = await evaluate(def, { });

                updateValidationValues(result);
            } else if (recordId !== undefined) {

                const def = buildQueryDefFromRecordId(recordId, entityName, refs);
                const result = await evaluate(def, { });

                updateValidationValues(result);

            } else {
                console.log("Oops");
            }

        })

        // cache the last one
        setCachedValues(formValues);

    }, [JSON.stringify(editingFieldValues)]);

    // 这里处理 lookup (数据字典) 的 validation
    useEffect(() => {
        (async () => {
            const def = buildLookupQuery(allLookups);
            const result = await evaluate(def, { });
            updateValidationValues(result);

        })()

    }, [])

    return null
}

function buildLookupQuery (lookups) {

    const queries = lookups.reduce((acc, [lookup, field]) => {
        return {
            ...acc,
            [field.name]: {
                type: "findOne",
                entityName: "选项文本值",
                criteria: {
                    "类别.名称": lookup
                }
            }
        }
    }, {})

    const fors = lookups.reduce((acc, [_, field]) => {
        return {
            ...acc,
            [field.name]: `\${${field.name}.值}`
        }
    }, {})

    return {
        __query__: {
            ...queries,
            __for__: fors
        }
    }
    
}


function buildQueryDefFromFieldValue(field, value, refs, { allEntities }) {
    // 
    const nameOrKey = field.options.entity;
    const linkedEntity = allEntities.find(e => e.name == nameOrKey || e.key == nameOrKey);
    // linked entity

    const target = "data";
    const refsDict = refs.reduce((acc, [ref, field]) => {
        const path = ref.split(".");
        if(path.length > 0) {
            const [_, ...tail] = path;
            return {
                ...acc,
                [field.name]: "${" + ([ target, ...tail].join(".")) + "}"
            }
        } else {
            return acc
        }

    }, {});

    return {
        __query__: {
            data: {
                type: "findOne",
                entityName: linkedEntity.name,
                criteria: {
                    id: value
                }
            },
            __for__: refsDict
        }
    }
}


function buildQueryDefFromRecordId(recordId, entityName, refs) {

    const refsDict = refs.reduce((acc, [ref, field]) => {
        return {
                ...acc,
                [field.name]: "${data." + ref + "}"
            }
    }, {});

    return {
        __query__: {
            data: {
                type: "findOne",
                entityName,
                criteria: {
                    id: recordId
                }
            },
            __for__: refsDict
        }
    }
}