
import React, { useEffect, useState } from 'react'

import {
    CgCompress, CgArrowsShrinkH, CgStories, CgMoreAlt, CgChevronDown, CgChevronUp, CgClose, 
} from 'react-icons/cg';
import {
    AlignLeftIcon, AlignCenterHorizontallyIcon, AlignRightIcon, AlignTopIcon, AlignCenterVerticallyIcon, AlignBottomIcon,
    StretchHorizontallyIcon, StretchVerticallyIcon, AlignBaselineIcon
} from '@radix-ui/react-icons'
import { Input, InputNumber, Select } from '@arco-design/web-react';
const Option = Select.Option;

import Tooltip from 'bwax-ui/components/Tooltip';
import ResetableLabel from '../components/ResetableLabel';

import './FlexChild.less'

export default function FlexChild({ attributes, blockNode, onChange }) {

    const sizing = [{
        value: "0 1 auto", icon: <CgCompress style={{ fontSize: 16 }}/>, tooltip: "Shrink if needed"
    }, {
        value: "1 1 0%", icon: <CgArrowsShrinkH style={{ fontSize: 16 }}/>, tooltip: "Grow if possible"
    }, {
        value: "0 0 auto", icon: <CgStories style={{ fontSize: 16 }}/>, tooltip: "Don't shrink or grow"
    }]

    const [customizeSizeVisible, setCustomizeSizeVisible] = useState(false)
    const [grow, setGrow] = useState(null)
    const [shrink, setShrink] = useState(null)
    const [basis, setBasis] = useState(null)
    const [basisUnit, setBasisUnit] = useState(null)
    const [alignmentEditVisible, setAlignmentEditVisible] = useState(false)
    const [customizeOrderVisible, setCustomizeOrderVisible] = useState(false)
    const [order, setOrder] = useState(0)

    const flexRegx = /^(\d) (\d) ((\d+(?:\.\d+)?)(px|em|rem|ch|vw|vh|%)|auto)$/
    useEffect(() => {
        if(attributes['flex'] && attributes['flex'].match(flexRegx)) {
            const flexMatchResult = attributes['flex'].match(flexRegx)
            setGrow(flexMatchResult[1])
            setShrink(flexMatchResult[2])
            if(flexMatchResult[4]) {
                // flexBasis is not auto
                setBasis(flexMatchResult[4])
                setBasisUnit(flexMatchResult[5])
            } else {
                setBasis('Auto')
                setBasisUnit('-')
            }

            if(!sizing.find(s => s.value === attributes['flex'])) {
                setCustomizeSizeVisible(true)
            }
        } else {
            setGrow(0)
            setShrink(1)
            setBasis('Auto')
            setBasisUnit('-')
        }

        if(attributes['alignSelf'] || attributes['order'] || attributes['order'] === 0) {
            setAlignmentEditVisible(true)

            if(attributes['order']) {
                setOrder(attributes['order'])
                if(attributes['order'] !== '-1' && attributes['order'] !== 1 && attributes['order'] !== 0) {
                    setCustomizeOrderVisible(true)
                }
            }
        }
    }, [ attributes ])

    function inputGrow (value) {
        setGrow(value)
        onChange({ flex: `${value} ${shrink} ${basis === 'Auto' ? 'auto' : basis}${basisUnit === "-" ? '' : basisUnit}`})
    }

    function inputShrink (value) {
        setShrink(value)
        onChange({ flex: `${grow} ${value} ${basis === 'Auto' ? 'auto' : basis}${basisUnit === "-" ? '' : basisUnit}`})
    }

    const basisUnits = ['px', 'em', 'rem', 'ch', 'vw', 'vh', '%', 'auto']

    function selectBasisUnit (value) {
        if(value === 'auto') {
            setBasisUnit('-')
            setBasis('Auto')
            onChange({ flex: `${grow} ${shrink} auto`})
        } else {
            setBasis(value)
            if(basis === 'Auto') {
                setBasis(0)
                onChange({ flex: `${grow} ${shrink} ${0}${value}`})
            } else {
                onChange({ flex: `${grow} ${shrink} ${basis}${value}`})
            }
        }
        
    }

    const basisUnitSelect = (
        <Select value={basisUnit} size={'mini'} className="unit-select" arrowIcon={null} triggerProps={{ autoAlignPopupWidth: false }} 
            onChange={value => selectBasisUnit(value)}
        >
            {basisUnits.map(option => (
                <Option key={option} value={option} className='unit-option'>
                    {option.toUpperCase()}
                </Option>
            ))}
        </Select>
    )

    const basisRegx = /^(\d+(?:\.\d+)?)|auto$/

    function inputBasis (value) {
        setBasis(value)
        if(value.match(basisRegx)) {
            onChange({ flex: `${grow} ${shrink} ${value}${value === "auto" ? '' : (basisUnit === '-' ? 'px' : basisUnit)}`})
        }
    }

    function inputBasisBlur () {
        if(!basis.match(basisRegx)) {
            setBasis('Auto')
            setBasisUnit('-')
            onChange({ flex: `${grow} ${shrink} auto`})
        }
    }

    const alignSelf = blockNode && blockNode.parentNode.style.flexDirection === "column" ? [{
        value: "auto", icon: <CgClose/>, tooltip: "Auto"
    }, {
        value: "flex-start", icon: <AlignLeftIcon/>, tooltip: "Left"
    }, {
        value: "center", icon: <AlignCenterHorizontallyIcon/>, tooltip: "Center"
    }, {
        value: "flex-end", icon: <AlignRightIcon/>, tooltip: "Right"
    }, {
        value: "stretch", icon: <StretchHorizontallyIcon/>, tooltip: "Stretch"
    }, {
        value: "baseline", icon: <AlignBaselineIcon/>, tooltip: "Baseline"
    }] : [{
        value: "auto", icon: <CgClose/>, tooltip: "Auto"
    }, {
        value: "flex-start", icon: <AlignTopIcon/>, tooltip: "Top"
    }, {
        value: "center", icon: <AlignCenterVerticallyIcon/>, tooltip: "Center"
    }, {
        value: "flex-end", icon: <AlignBottomIcon/>, tooltip: "Bottom"
    }, {
        value: "stretch", icon: <StretchVerticallyIcon/>, tooltip: "Stretch"
    }, {
        value: "baseline", icon: <AlignBaselineIcon/>, tooltip: "Baseline"
    }]

    const orders = [{
        value: 0, icon: <CgClose/>, tooltip: "Don't change"
    }, {
        value: "-1", text: "Frist", tooltip: "Make first"
    }, {
        value: 1, text: "Last", tooltip: "Make last"
    }]

    return (
        <div className='flex-child-container'>
            <div className='sizing-options'>
                <ResetableLabel allAttributes={attributes} label={"Sizing"} attributesGroup={"flexChild"} attribute={'flex'} onChange={onChange}/>
                <div className='options'>
                    {
                        sizing.map((op, index) => {
                            const { value, icon, tooltip } = op
                            const isActive = !customizeSizeVisible && Object.keys(attributes).some(k => k === "flex") && attributes['flex'] === value
                            
                            return (
                                <Tooltip key={index} text={tooltip}>
                                    <div className={`option-box ${isActive ? 'active' : ''}`}
                                        onClick={() => {
                                            onChange({ flex: value })
                                            setCustomizeSizeVisible(false)
                                        }}
                                    >
                                        {icon}
                                    </div>
                                </Tooltip>
                            )
                        })
                    }
                    <Tooltip text={"Customize grow and shrink behavior"}>
                        <div className={`option-box ${customizeSizeVisible ? 'active' : ''}`}
                            onClick={() => setCustomizeSizeVisible(!customizeSizeVisible)}
                        >
                            <CgMoreAlt style={{ fontSize: 16 }}/>
                        </div>
                    </Tooltip>
                </div>
                {
                    customizeSizeVisible ? (
                        <div className='customize-size-container'>
                            <InputNumber className='input' size="mini" hideControl value={grow} onChange={value => inputGrow(value)} min={0}/>
                            <div className='label'>Grow</div>
                            <InputNumber className='input' size="mini" hideControl value={shrink} onChange={value => inputShrink(value)} min={0} />
                            <div className='label'>Shrink</div>
                            <Input className='input' size="mini" value={basis} suffix={basisUnitSelect} onChange={value => inputBasis(value)} onBlur={() => inputBasisBlur()}/>
                            <div className='label'>Basis</div>
                        </div>
                    ) : null
                }
            </div>
            <div className={`alignment-edit-toggle`} onClick={() => setAlignmentEditVisible(!alignmentEditVisible)}>
                {alignmentEditVisible ? <CgChevronUp/> : <CgChevronDown/>}
                Alignment and order
            </div>
            {
                alignmentEditVisible? (
                    <div className='alignment-edit'>
                        <div className='style-with-options'>
                            <ResetableLabel allAttributes={attributes} label={"Align"} attributesGroup={"flexChild"} attribute={'alignSelf'} onChange={onChange}/>
                            <div className='options'>
                                {
                                    alignSelf.map((op, index) => {
                                        const { value, icon, tooltip } = op
                                        const isActive = Object.keys(attributes).some(k => k === 'alignSelf') && attributes['alignSelf'] === value
                                        return (
                                            <Tooltip key={index} text={tooltip}>
                                                <div className={`option-box ${isActive ? 'active' : ''}`}
                                                    onClick={() => onChange({ 'alignSelf': value })}
                                                >
                                                    {icon}
                                                </div>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='style-with-options'>
                            <ResetableLabel allAttributes={attributes} label={"Order"} attributesGroup={"flexChild"} attribute={'order'} onChange={onChange}/>
                            <div className='options'>
                                {
                                    orders.map((op, index) => {
                                        const { value, icon, text, tooltip } = op
                                        const isActive = !customizeOrderVisible && Object.keys(attributes).some(k => k === 'order') && attributes['order'] === value
                                        return (
                                            <Tooltip key={index} text={tooltip}>
                                                <div className={`option-box ${isActive ? 'active' : ''}`}
                                                    onClick={() => {
                                                        onChange({ 'order': value })
                                                        setOrder(value)
                                                        setCustomizeOrderVisible(false)
                                                    }}
                                                >
                                                    {icon ? icon : text}
                                                </div>
                                            </Tooltip>
                                        )
                                    })
                                }
                                <Tooltip text={"Customize order"}>
                                    <div className={`option-box ${customizeOrderVisible ? 'active' : ''}`}
                                        onClick={() => setCustomizeOrderVisible(!customizeOrderVisible)}
                                    >
                                        <CgMoreAlt style={{ fontSize: 16 }}/>
                                    </div>
                                </Tooltip>
                            </div>
                            {
                                customizeOrderVisible ? (
                                    <div className='customize-order-container'>
                                        <InputNumber className='input' size="mini" hideControl value={order} min={0}
                                            onChange={value => {
                                                setOrder(value)
                                                onChange({ order: value })
                                            }}
                                        />
                                        <div className='label'>Order</div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}
